import React, { Suspense, useContext } from 'react';

import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { AppleDevices, DeviceModelOs, DevicePossessionType } from '@v2/feature/device/device.interface';
import { DeviceProtection } from '@v2/feature/device/features/device-cards/components/device-protection.component';
import { SecurityDetailsInformation } from '@v2/feature/device/features/device-cards/components/security-information.component';
import { NoAppliedPolicies } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { EnrollDevice } from '@v2/feature/device/features/enrollment-device/components/enroll-existing-device.component';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { checkScopes } from '@/lib/scopes';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { MDMABLE_DEVICE_TYPES } from '@/v2/feature/device/device.util';
import { NotEnrollledRisk } from '@/v2/feature/device/features/device-cards/components/not-enrollled-risk.component';
import { EnrollmentStatus } from '@/v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { themeColors } from '@/v2/styles/colors.styles';

interface DeviceDetailsCardsProps {
  readonly devicePossession: DevicePossessionDto;
  readonly activeTransit: DeviceTransitDto | null;
  readonly loading: boolean;
  readonly refreshDevice: () => Promise<void>;
  readonly enrollPath: string;
  appliedPolicies: AppliedDevicePoliciesDto;
  appliedZeltPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  appliedZeltPoliciesForMobile: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}
export const DeviceMdmSecurityComponent = ({
  devicePossession,
  activeTransit,
  loading,
  refreshDevice,
  enrollPath,
  appliedPolicies,
  appliedZeltPolicies,
  appliedZeltPoliciesForMobile,
}: DeviceDetailsCardsProps): React.JSX.Element => {
  const isDevicePossessionActive = Boolean(devicePossession && devicePossession.startDate && !devicePossession.endDate);
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasAdminOrEnrolScopes = checkScopes(globalState.user, ['devices.enroll', 'devices:all'], scopesContext);

  const getProperPolicy = () => {
    if (devicePossession.device && devicePossession.device.inHouseMdm) {
      if (['ios', 'ipados'].includes(devicePossession.device.os ?? '')) {
        return appliedZeltPoliciesForMobile ? appliedZeltPoliciesForMobile : NoAppliedPolicies;
      } else if (['macos'].includes(devicePossession.device.os ?? '')) {
        return appliedZeltPolicies ? appliedZeltPolicies : NoAppliedPolicies;
      }
    } else if (appliedPolicies) {
      return appliedPolicies;
    }
    return NoAppliedPolicies;
  };

  return (
    <>
      {devicePossession.device && !activeTransit && <SecurityDetailsInformation devicePossession={devicePossession} />}

      {Boolean(
        isDevicePossessionActive &&
          devicePossession &&
          devicePossession.device &&
          devicePossession?.device?.type &&
          MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type) &&
          (devicePossession.device.enrollmentStatus === 'enrolled' ||
            devicePossession.device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED)
      ) && (
        <SettingsSubsectionContent
          loadingAll={loading}
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Suspense
                      fallback={
                        <SkeletonLoader
                          variant="rectangular"
                          width="31%"
                          height="100%"
                          sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                        />
                      }
                    >
                      <DeviceProtection devicePossession={devicePossession} appliedPolicies={getProperPolicy()} />
                    </Suspense>
                  ),
                },
              ],
            },
          ]}
        />
      )}

      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Component,
                hidden: !hasAdminOrEnrolScopes,
                value: (
                  <>
                    {isDevicePossessionActive &&
                      devicePossession &&
                      devicePossession.device &&
                      devicePossession?.device?.type &&
                      MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type) &&
                      !(
                        devicePossession.device.enrollmentStatus === 'enrolled' ||
                        devicePossession.device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
                      ) &&
                      !activeTransit &&
                      devicePossession.possessionType !== DevicePossessionType.ZeltStorage &&
                      devicePossession.device.os &&
                      (AppleDevices.includes(devicePossession.device?.os?.toLowerCase() as DeviceModelOs) ? (
                        <EnrollDevice devicePossession={devicePossession} refreshAppData={refreshDevice} />
                      ) : (
                        <NotEnrollledRisk enrollPath={enrollPath} devicePossession={devicePossession} />
                      ))}
                  </>
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
};
