import React, { Suspense, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { buttonBoxSx, fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DeferEnrollmentDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userPension: UserPensionDto;
  readonly onClose?: () => Promise<void> | void;
  readonly refresh: () => Promise<void>;
}

export const DeferEnrollmentDrawer = ({
  isOpen,
  setIsOpen,
  userPension,
  refresh,
  onClose,
}: DeferEnrollmentDrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <DeferEnrollmentDrawerContent setIsOpen={setIsOpen} userPension={userPension} refreshData={refresh} />
    </Suspense>
  </DrawerModal>
);

interface DeferEnrollmentFormData {
  enrollmentDate: string | null;
}

interface DeferEnrollmentDrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userPension: UserPensionDto;
  refreshData: () => Promise<void>;
}

const DeferEnrollmentDrawerContent = ({ setIsOpen, userPension, refreshData }: DeferEnrollmentDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();

  const tomorrow = new LocalDate();
  tomorrow.getDate().setDate(tomorrow.getDate().getDate() + 1);

  const userStartDate = getCachedUserById(userPension.userId)?.startDate ?? new Date();
  const lastAvailableEnrollmentDate = new LocalDate(userStartDate);
  lastAvailableEnrollmentDate.getDate().setMonth(lastAvailableEnrollmentDate.getDate().getMonth() + 3);

  const formik = useFormik<DeferEnrollmentFormData>({
    initialValues: {
      enrollmentDate: tomorrow.toDateString(),
    },
    validationSchema: Yup.object({
      enrollmentDate: Yup.string().test(dateFieldTest).required('Enrolment date is required'),
    }),
    onSubmit: async ({ enrollmentDate }: DeferEnrollmentFormData) => {
      if (!enrollmentDate) {
        showMessage('A valid date must be provided.', 'error');
        return;
      }
      try {
        setLoading(true);
        await PensionAPI.deferEmployeeEnrolment(userPension.userId, enrollmentDate);
        await refreshData();
        setIsOpen(false);
      } catch (error) {
        console.error(error);
        showMessage(`Could not defer enrollment. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>Deferral</Typography>

        <Typography sx={themeFonts.caption}>
          You can choose to postpone (or defer) employee enrolment into a pension scheme for up to three months.
          Employee will be automatically enrolled on the selected deferred date.
        </Typography>

        <Typography sx={{ ...themeFonts.caption, my: spacing.my20 }}>
          Employee will be notified of this postponement. Read more about it{' '}
          <a
            href="https://www.thepensionsregulator.gov.uk/en/employers/new-employers/im-an-employer-who-has-to-provide-a-pension/work-out-who-to-put-into-a-pension/postponement"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Typography>

        <Box sx={{ mb: spacing.mb20 }}>
          <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>Employee</Typography>
          <UserCell userId={userPension.userId} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
        </Box>

        <Box sx={fieldSx}>
          <DatePickerComponent
            name="enrollmentDate"
            label="Enrollment date"
            inputFormat="DD/MM/YYYY"
            value={formik.values.enrollmentDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('enrollmentDate', value);
              }
            }}
            error={!!formik.errors.enrollmentDate && Boolean(formik.touched.enrollmentDate)}
            helperText={formik.errors.enrollmentDate && Boolean(formik.touched.enrollmentDate)}
            disablePast
            minDate={tomorrow.getDate()}
            maxDate={lastAvailableEnrollmentDate.getDate()}
          />
        </Box>

        <Box sx={buttonBoxSx}>
          <LoaderButton name="Save" loading={loading} sizeVariant="small" colorVariant="primary" fullWidth />
        </Box>
      </Form>
    </FormikProvider>
  );
};
