import axios from 'axios';

import {
  NotificationSystemName,
  ProfileNotifications,
} from '@/v2/feature/notification/notification-settings/notification.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ProfileNotificationAPI {
  static async getProfileNotifications(): Promise<ProfileNotifications[]> {
    return await (await axios.get(`/apiv2/company/profile-notifications`)).data;
  }

  static async updateNotificationSettings(record: Partial<ProfileNotifications>): Promise<void> {
    await axios.patch(`/apiv2/company/profile-notifications/settings`, { record });
  }

  static async toggleNotificationStatus(
    notificationId: number,
    notificationName: NotificationSystemName,
    newStatus: boolean
  ): Promise<void> {
    await axios.put(`/apiv2/company/profile-notifications/toggle-status`, {
      notificationId,
      notificationName,
      newStatus,
    });
  }

  static async testProfileNotification(record: ProfileNotifications): Promise<void> {
    await axios.post(`/apiv2/company/profile-notifications/send-a-test`, { record });
  }

  static async createNewProfileNotification(record: ProfileNotifications): Promise<void> {
    await axios.post(`/apiv2/company/profile-notifications`, { record });
  }

  static async deleteCustomProfileNotification(notificationId: number): Promise<void> {
    await axios.delete(`/apiv2/company/profile-notifications/${notificationId}`);
  }
}

export class NotificationEndpoints {
  static getProfileNotifications(): Endpoint<ProfileNotifications[]> {
    return {
      url: '/apiv2/company/profile-notifications',
    };
  }
}
