import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { detailTermSx, detailValueSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

export const AppCalendarConfiguration = (): JSX.Element => {
  const fieldLabel = 'Domain for calendar';
  const [edit, setEdit] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [domainList, setDomainList] = useState<string[]>([]);
  const [currentDomain, setCurrentDomain] = useState<string>('');
  const [updatedDomain, setUpdatedDomain] = useState<string>('');

  const refreshCalendarDomainName = useCallback(async () => {
    const config = await AppIntegrationAPI.getCalendarDomainConfig();
    setDomainList(config.list);
    setCurrentDomain(config?.current);
    setUpdatedDomain(config?.current);
  }, []);

  useEffect(() => {
    refreshCalendarDomainName();
  }, [refreshCalendarDomainName]);

  const domainNameOptions: readonly OptionObject[] = useMemo(
    () =>
      domainList.map((eachOption) => {
        return {
          value: eachOption,
          label: eachOption,
        };
      }),
    [domainList]
  );

  const saveDomainNameForCalendarConfig = async () => {
    try {
      setLoading(true);
      if (updatedDomain)
        AppIntegrationAPI.saveCalendarDomainConfig(updatedDomain).then((config) => {
          if (config.current) setCurrentDomain(config.current);
        });
      showMessage('Successfully saved calendar domain!', 'success');
      setEdit(false);
    } catch (error) {
      showMessage(`Failed to save calendar domain: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
      refreshCalendarDomainName();
    }
  };

  return (
    <Box sx={{ width: '550px', mt: spacing.m60 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>Calendar configuration</Typography>
        <IconButton sx={{ ...tableIconButtonSx, display: `${edit ? 'none' : ''}` }} onClick={() => setEdit(true)}>
          <EditIcon {...actionIconSize} />
        </IconButton>
      </Box>
      {!edit ? (
        <Box component="dl" sx={{ definitionListSx }}>
          <Box sx={{ ...definitionSx, mt: '30px', display: 'flex', alignItems: 'center' }}>
            <Typography component="dt" sx={definitionTermSx}>
              {fieldLabel}
            </Typography>
            <Typography component="dd" sx={definitionValueSx}>
              {currentDomain ?? 'None'}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', mt: '30px', alignItems: 'center' }} component="section">
            <Box sx={{ minWidth: '190px', maxWidth: '190px' }}>
              <Typography sx={detailTermSx}> {fieldLabel}</Typography>
            </Box>
            <Box sx={detailValueSx}>
              <SelectComponent
                label=""
                name=""
                options={domainNameOptions}
                value={updatedDomain}
                onChange={(e) => {
                  setUpdatedDomain(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Stack sx={{ flexFlow: 'row', my: spacing.m50, gap: spacing.g10 }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="secondary"
              disabled={false}
              onClick={() => setEdit(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              disabled={!updatedDomain || updatedDomain === currentDomain || !domainList || loading}
              onClick={() => saveDomainNameForCalendarConfig()}
            >
              Save
            </ButtonComponent>
          </Stack>
        </>
      )}
    </Box>
  );
};
