import { Snackbar, Box, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { ReactComponent as InfoBlack } from '@/images/side-bar-icons/InfoBlack.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly inUse: boolean;
  readonly message: string;
  readonly maxWidth?: string;
}

export const PersistentNotification = ({ inUse, message, maxWidth = '500px' }: Props) => {
  return (
    <Snackbar
      open={inUse}
      onClose={undefined}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MuiAlert
        sx={{
          width: '100%',
          backgroundColor: themeColors.black,
          borderRadius: radius.br10,
          boxShadow: 'none',
          maxWidth,
        }}
        elevation={10}
        variant="filled"
        icon={false}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: spacing.g10 }}>
          <InfoBlack style={{ fill: themeColors.white }} width={15} height={15} />
          <Typography
            sx={{
              ...themeFonts.caption,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              gap: spacing.g10,
              color: themeColors.white,
            }}
          >
            {message}
          </Typography>
        </Box>
      </MuiAlert>
    </Snackbar>
  );
};
