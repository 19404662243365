import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown, AbsenceLengthUnit } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import { BalanceAdjustmentsDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/balance-adjustments-drawer.component';
import { AbsenceDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import { OffboardingAbsenceAddToPayrollDrawer } from '@v2/feature/offboarding/offboarding-process/absence/offboarding-absence-add-to-payroll-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';

import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Action } from '@/images/fields/Action.svg';

type UserPolicyData = Pick<AbsencePolicyDto, 'id' | 'name' | 'inPayrollOffboarding' | 'payCode'> & {
  balance: string;
  balanceInMinutes: number | null;
};

interface TableProps {
  readonly userId: number;
  readonly userBalanceStats: UserBalanceDetailedStatsDto | undefined | null;
  readonly userPolicies: AbsencePolicyDto[];
  readonly userLeaveDate: string;
  readonly refreshState: () => Promise<void>;
  readonly refreshFutureRequests: () => Promise<void>;
}

export const OffboardingAbsenceBalancesTable = ({
  userId,
  userBalanceStats,
  userPolicies,
  userLeaveDate,
  refreshState,
  refreshFutureRequests,
}: TableProps) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();

  const [selectedPolicyId, setSelectedPolicyId] = useState<number | null>(null);
  const [isBookingOpen, setIsBookingOpen] = useState<boolean>(false);
  const [isAddAdjustmentOpen, setIsAddAdjustmentOpen] = useState<boolean>(false);
  const [isAddToPayrollOpen, setIsAddToPayrollOpen] = useState<boolean>(false);

  const hasPayrollManagerScope = useMemo(() => {
    return hasScopes(['payroll:manager'], getScopesContext({ userId }));
  }, [userId, hasScopes, getScopesContext]);

  const policyBalance: AbsenceBreakdown | null = useMemo(() => {
    if (!selectedPolicyId || !userBalanceStats) return null;

    return userBalanceStats[selectedPolicyId] ?? null;
  }, [selectedPolicyId, userBalanceStats]);

  const selectedPolicy: AbsencePolicyDto | null = useMemo(() => {
    if (!selectedPolicyId || !userPolicies) return null;
    return userPolicies.find((p) => p.id === selectedPolicyId) ?? null;
  }, [userPolicies, selectedPolicyId]);

  const payrollPoliciesSet = useMemo(() => {
    if (!userPolicies) return new Set<number>();

    const payrollPoliciesIds = userPolicies.filter((p) => p.inPayrollOffboarding).map((p) => p.id);
    return new Set(payrollPoliciesIds);
  }, [userPolicies]);

  const userPoliciesData = useMemo(() => {
    if (!userPolicies || !userBalanceStats) return [];

    return userPolicies
      .map((policy) => {
        if (isUnlimitedPolicy(policy)) return null;

        const balance = userBalanceStats[policy.id];
        if (!balance) return null;

        const showBalanceInDays = Boolean(balance.lengthUnit === AbsenceLengthUnit.Day && balance.isOnRegularSchedule);
        const balanceValue = showBalanceInDays ? balance.currentBalanceInDays ?? 0 : balance.currentBalance ?? 0;
        const balanceString = showBalanceInDays
          ? polyglot.t('OffboardingAbsence.numDays', {
              smart_count: balanceValue,
            })
          : convertMinutesToClockHours(balanceValue, polyglot);

        return {
          id: policy.id,
          name: policy.name,
          inPayrollOffboarding: policy.inPayrollOffboarding,
          payCode: policy.payCode,
          balance: balanceString,
          balanceInMinutes: balance.currentBalance,
        };
      })
      .filter(Boolean) as UserPolicyData[];
  }, [userPolicies, userBalanceStats, polyglot]);

  const columnData = useMemo<ColumnDef<UserPolicyData, UserPolicyData>[]>(
    () => [
      {
        header: () => polyglot.t('OffboardingAbsence.policy'),
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name ?? ''),
        cell: ({ row }) => {
          return (
            <Box>
              <Typography variant="caption">{row.original.name}</Typography>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('OffboardingAbsence.accruedBalance'),
        accessorFn: (row) => row,
        id: 'balance',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.balanceInMinutes ?? 0),
        cell: ({ row }) => {
          return (
            <Box>
              <Typography variant="caption">{row.original.balance}</Typography>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('OffboardingAbsence.payCode'),
        accessorFn: (row) => row,
        id: 'payCode',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.payCode ?? ''),
        cell: ({ row }) => {
          return (
            <Box>
              {row.original.payCode ? <Typography variant="caption">{row.original.payCode}</Typography> : <EmptyCell />}
            </Box>
          );
        },
      },
      {
        header: () => '',
        id: 'actions',
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <StyledMenuComponent
                options={[
                  {
                    handler: () => {
                      setSelectedPolicyId(row.original.id);
                      setIsAddToPayrollOpen(true);
                    },
                    label: polyglot.t('OffboardingAbsence.addToPayroll'),
                    hidden: !hasPayrollManagerScope || !payrollPoliciesSet.has(row.original.id),
                  },
                  {
                    handler: () => {
                      setSelectedPolicyId(row.original.id);
                      setIsBookingOpen(true);
                    },
                    label: polyglot.t('OffboardingAbsence.bookAbsence'),
                  },
                  {
                    handler: () => {
                      setSelectedPolicyId(row.original.id);
                      setIsAddAdjustmentOpen(true);
                    },
                    label: polyglot.t('OffboardingAbsence.addAdjustment'),
                  },
                ]}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <Action {...iconSize} />,
                }}
              />
            </Box>
          );
        },
      },
    ],
    [hasPayrollManagerScope, payrollPoliciesSet, polyglot]
  );

  return userPoliciesData.length > 0 ? (
    <Box sx={{ width: '100%' }}>
      <Typography variant="caption">
        {polyglot.t('OffboardingAbsence.remainingBalanceAtTermination', {
          date: new LocalDate(userLeaveDate).toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }),
        })}
      </Typography>
      <Typography variant="caption" sx={{ mb: spacing.s2 }}>
        {polyglot.t('OffboardingAbsence.remainingBalanceActionsDesc')}
      </Typography>

      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable<UserPolicyData> rowData={userPoliciesData ?? []} columnData={columnData} hidePagination />
      </Box>

      <AbsenceDrawer
        isOpen={isBookingOpen}
        userId={userId}
        policyId={selectedPolicyId ?? undefined}
        onClose={() => {
          setIsBookingOpen(false);
          setSelectedPolicyId(null);
        }}
        refresh={async () => {
          await Promise.all([refreshState(), refreshFutureRequests()]);
        }}
        setIsAbsenceDrawerOpen={setIsBookingOpen}
        afterClose={() => {
          setSelectedPolicyId(null);
        }}
      />

      {policyBalance && selectedPolicy && (
        <BalanceAdjustmentsDrawer
          isOpen={isAddAdjustmentOpen}
          setIsOpen={setIsAddAdjustmentOpen}
          userId={userId}
          absencePolicy={selectedPolicy}
          policyBreakdown={policyBalance}
          refresh={refreshState}
          isOnRegularSchedule={!!policyBalance?.isOnRegularSchedule}
          currentAverageWorkDayLength={policyBalance?.currentAverageWorkDayLength ?? 480}
          afterClose={() => {
            setSelectedPolicyId(null);
          }}
        />
      )}

      <OffboardingAbsenceAddToPayrollDrawer
        isOpen={isAddToPayrollOpen}
        setIsOpen={setIsAddToPayrollOpen}
        userId={userId}
        absencePolicy={selectedPolicy}
        refresh={refreshState}
        afterClose={() => {
          setSelectedPolicyId(null);
        }}
      />
    </Box>
  ) : (
    <Box>
      <Typography variant="caption">{polyglot.t('OffboardingAbsence.noCurrentBalance')}</Typography>
    </Box>
  );
};
