import { useEffect, useRef, useState } from 'react';

type DelayLoadProps = {
  delay: number;
  children: React.ReactNode;
};

/**
 * Displays the child component after `delay` ms have elapsed after the component was loaded
 * ```
 * <DelayLoad delay={100}>
 *  <ChildComponent ... />
 * </DelayLoad>
 * ```
 */
export const DelayLoad = ({ delay, children }: DelayLoadProps) => {
  const displayTime = useRef(Date.now() + delay);
  const [showComponent, setShowComponent] = useState(delay <= 0);

  useEffect(() => {
    if (showComponent) return;
    const t = setTimeout(() => setShowComponent(true), displayTime.current - Date.now());
    return () => clearTimeout(t);
  }, [showComponent]);

  return showComponent ? <>{children}</> : <span style={{ visibility: 'hidden' }}>{children}</span>;
};
