import React, { useState } from 'react';

import { Box, Divider } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { CompanyPayrollType } from '@v2/feature/payroll/payroll.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';

import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { Typography } from '@/v2/components/typography/typography.component';

interface PayrunReopenConfirmationDrawerProps {
  readonly payrollType: CompanyPayrollType;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onConfirm: () => Promise<void>;
}

export const PayrunReopenConfirmationDrawer = ({
  payrollType,
  isOpen,
  setIsOpen,
  onConfirm,
}: PayrunReopenConfirmationDrawerProps) => {
  const [loading, setIsLoading] = useState<boolean>(false);

  const reopenPayRun = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">Reopening payrun</Typography>

        <Typography variant="caption">
          Before you reopen this payrun please review the warnings and ensure you have taken the necessary actions
          before running this period in Zelt again.
        </Typography>

        {payrollType === CompanyPayrollType.UKPayroll && (
          <>
            <Box sx={{ display: 'flex', gap: spacing.gap10 }}>
              <Box>
                <MistakeIcon {...iconSize} />
              </Box>
              <Box>
                <Typography variant="title4" sx={{ mb: spacing.mb5 }}>
                  HMRC submission
                </Typography>
                <Typography variant="caption">
                  HMRC can confuse multiple payrun submissions so please keep an eye on your Government Gateway account
                  for any error alerts.
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: spacing.m20 }} />
          </>
        )}

        <Box sx={{ display: 'flex', gap: spacing.gap10 }}>
          <Box>
            <MistakeIcon {...iconSize} />
          </Box>
          <Box>
            <Typography variant="title4" sx={{ mb: spacing.mb5 }}>
              Accounting journal
            </Typography>
            <Typography variant="caption">
              You will need to login to your accounting software and delete the accounting journal for the current pay
              period.
            </Typography>
          </Box>
        </Box>

        {payrollType === CompanyPayrollType.UKPayroll && (
          <>
            <Divider sx={{ my: spacing.m20, width: 1 }} />

            <Box sx={{ display: 'flex', gap: spacing.gap10 }}>
              <Box>
                <MistakeIcon {...iconSize} />
              </Box>
              <Box>
                <Typography variant="title4" sx={{ mb: spacing.mb5 }}>
                  Pension
                </Typography>
                <Typography variant="caption">
                  Please log into your pension provider platform and delete the latest contribution submission before
                  re-running the payrun in Zelt.
                </Typography>
              </Box>
            </Box>
          </>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Reopen this payrun"
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            onClick={reopenPayRun}
            fullWidth
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
