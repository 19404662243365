import { AbsencePolicyTenureAllocation } from '@v2/feature/absence/absence.dto';
import { DefaultApprovalRule } from '@v2/feature/approval-rule/approval-rule.interface';
import {
  CustomBenefitAllowanceType,
  CustomBenefitCategory,
  CustomBenefitType,
  UserCustomBenefitRequestType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { DomainTypes } from '@v2/feature/dashboard/interfaces/dashboard.interface';
import { PaymentStatus } from '@v2/feature/payments/payments.dto';
import { capitalize } from 'lodash';
import Polyglot from 'node-polyglot';

import { AbsenceStatus, AccrualCliff } from '@/v2/feature/absence/absence.interface';
import { DocumentStatus } from '@/v2/feature/documents/documents.interface';
import { UserFamilyMemberType } from '@/v2/feature/user/features/user-forms/user-family/user-family.interface';
import { UserLifecycleStatuses } from '@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface';

const transformString = (string: string, toLower = true) => {
  if (!string) return '';
  // Replace all white spaces and ':' with ''
  const aux = string.replace(/\s/g, '').replaceAll(':', '');
  return toLower ? aux.toLowerCase() : aux;
};

export const translateUserFamilyMemberType = (type: UserFamilyMemberType, polyglot: Polyglot) => {
  if (polyglot.has(`UserFamilyMemberType.${type}`)) return polyglot.t(`UserFamilyMemberType.${type}`);
  return type;
};

export const translatePermissionCategory = (category: string, polyglot: Polyglot) => {
  if (polyglot.has(`PermissionCategory.${transformString(category)}`))
    return polyglot.t(`PermissionCategory.${transformString(category)}`);
  return category;
};

export const translatePermissionCategoryDesc = (desc: string, polyglot: Polyglot) => {
  if (polyglot.has(`PermissionCategoryDesc.${transformString(desc)}`))
    return polyglot.t(`PermissionCategoryDesc.${transformString(desc)}`);
  return desc;
};

export const translateCustomField = (field: string, polyglot: Polyglot) => {
  if (polyglot.has(`CustomField.${transformString(field)}`)) return polyglot.t(`CustomField.${transformString(field)}`);
  return field;
};

export const translateCustomFieldCategory = (category: string, polyglot: Polyglot) => {
  if (polyglot.has(`CustomFieldCategory.${transformString(category)}`)) {
    return polyglot.t(`CustomFieldCategory.${transformString(category)}`);
  }
  return category;
};
export const translateAbsenceStatuses = (
  status: AbsenceStatus | 'autoapproved' | 'deletionrequested' | 'deleted',
  polyglot: Polyglot
) => {
  if (polyglot.has(`AbsenceStatus.${transformString(status)}`))
    return polyglot.t(`AbsenceStatus.${transformString(status)}`);
  return status;
};
export const translategetGenderOptions = (gender: string, polyglot: Polyglot) => {
  if (polyglot.has(`getGenderOptions.${transformString(gender)}`))
    return polyglot.t(`getGenderOptions.${transformString(gender)}`);
  return gender;
};

export const translategetEmploymentTypeOptions = (type: string, polyglot: Polyglot) => {
  if (polyglot.has(`getEmploymentTypeOptions.${transformString(type)}`))
    return polyglot.t(`getEmploymentTypeOptions.${transformString(type)}`);
  return type;
};
export const translategetContractTypeOptions = (type: string, polyglot: Polyglot) => {
  if (polyglot.has(`getContractTypeOptions.${transformString(type)}`))
    return polyglot.t(`getContractTypeOptions.${transformString(type)}`);
  return type;
};

export const translateUserProfileLifecycleCard = (status: UserLifecycleStatuses | string, polyglot: Polyglot) => {
  if (polyglot.has(`UserLifecycleStatuses.${transformString(status)}`))
    return polyglot.t(`UserLifecycleStatuses.${transformString(status)}`);
  return status;
};
export const translategetAllChangeTypeOptions = (option: string, polyglot: Polyglot) => {
  if (polyglot.has(`AllChangeTypeOptions.${transformString(option)}`))
    return polyglot.t(`AllChangeTypeOptions.${transformString(option)}`);
  return option;
};
export const translatePaySchedule = (schedule: string, polyglot: Polyglot) => {
  if (polyglot.has(`PaySchedules.${transformString(schedule)}`))
    return polyglot.t(`PaySchedules.${transformString(schedule)}`);
  return schedule;
};
export const translateequityTypeOptions = (option: string, polyglot: Polyglot) => {
  if (polyglot.has(`equityTypeOptions.${transformString(option)}`))
    return polyglot.t(`equityTypeOptions.${transformString(option)}`);
  return option;
};
export const translateDocumentStatus = (status: DocumentStatus, polyglot: Polyglot) => {
  if (polyglot.has(`DocumentStatus.${transformString(status)}`))
    return polyglot.t(`DocumentStatus.${transformString(status)}`);
  return status;
};
export const translateColumnsOptions = (col: string, polyglot: Polyglot) => {
  if (polyglot.has(`ColumnsOptions.${transformString(col)}`))
    return capitalize(polyglot.t(`ColumnsOptions.${transformString(col)}`));
  return col;
};

export const translatePeopleDirectoryPage = (col: string, polyglot: Polyglot) => {
  if (polyglot.has(`PeopleDirectoryPage.${transformString(col)}`))
    return capitalize(polyglot.t(`PeopleDirectoryPage.${transformString(col)}`));
  return col;
};

export const translatePageFilters = (option: any, polyglot: Polyglot) => {
  if (typeof option?.value === 'number') return polyglot.t(option.label); //if type string, assume it is a custom value
  const filter = option.value;
  if (polyglot.has(`PageFilters.${transformString(filter)}`))
    return capitalize(polyglot.t(`PageFilters.${transformString(filter)}`));

  if (polyglot.has(filter)) return polyglot.t(filter);
  return filter;
};

export const translatePageFilterSet = (name: any, polyglot: Polyglot) => {
  if (polyglot.has(`PageFilters.${transformString(name)}`))
    return capitalize(polyglot.t(`PageFilters.${transformString(name)}`));
  return name;
};

export const translateCustomBenefitCategory = (category: CustomBenefitCategory, polyglot: Polyglot): string => {
  if (polyglot.has(`BenefitModule.CustomBenefitCategory.${transformString(category)}`))
    return capitalize(polyglot.t(`BenefitModule.CustomBenefitCategory.${transformString(category)}`));
  return category;
};

export const translateCustomBenefitType = (type: CustomBenefitType, polyglot: Polyglot): string => {
  if (polyglot.has(`BenefitModule.CustomBenefitType.${transformString(type)}`))
    return capitalize(polyglot.t(`BenefitModule.CustomBenefitType.${transformString(type)}`));
  return type;
};

export const translateCustomBenefitAllowanceType = (type: CustomBenefitAllowanceType, polyglot: Polyglot): string => {
  if (polyglot.has(`BenefitModule.CustomBenefitAllowanceType.${transformString(type)}`))
    return capitalize(polyglot.t(`BenefitModule.CustomBenefitAllowanceType.${transformString(type)}`));
  return type;
};

export const translateDomainTypes = (type: DomainTypes | 'timeph', polyglot: Polyglot): string => {
  if (polyglot.has(`BenefitModule.DomainTypes.${transformString(type)}`))
    return polyglot.t(`BenefitModule.DomainTypes.${transformString(type)}`);
  return type;
};

export const translateUserInsuranceDependants = (dependants: UserInsuranceDependants, polyglot: Polyglot): string => {
  if (polyglot.has(`BenefitModule.UserInsuranceDependants.${transformString(dependants)}`))
    return polyglot.t(`BenefitModule.UserInsuranceDependants.${transformString(dependants)}`);
  return dependants;
};

export const translatePaymentStatus = (status: PaymentStatus, polyglot: Polyglot): string => {
  if (polyglot.has(`PaymentStatus.${transformString(status)}`))
    return polyglot.t(`PaymentStatus.${transformString(status)}`);
  return status;
};

export const translateAbsencePolicyTenureAllocation = (
  tenureAllocation: AbsencePolicyTenureAllocation,
  polyglot: Polyglot
): string => {
  if (polyglot.has(`AbsenceModule.AbsencePolicyTenureAllocation.${transformString(tenureAllocation)}`))
    return polyglot.t(`AbsenceModule.AbsencePolicyTenureAllocation.${transformString(tenureAllocation)}`);
  return tenureAllocation;
};

export const translateAbsencePolicyTenureAllocationDescription = (
  tenureAllocation: AbsencePolicyTenureAllocation,
  polyglot: Polyglot
): string => {
  if (polyglot.has(`AbsenceModule.AbsencePolicyTenureAllocationDescription.${transformString(tenureAllocation)}`))
    return polyglot.t(`AbsenceModule.AbsencePolicyTenureAllocationDescription.${transformString(tenureAllocation)}`);
  return tenureAllocation;
};

export const translateAbsencePolicyAccrualCliff = (accrualCliff: AccrualCliff, polyglot: Polyglot): string => {
  if (polyglot.has(`AbsenceModule.AccrualCliff.${transformString(accrualCliff)}`))
    return polyglot.t(`AbsenceModule.AccrualCliff.${transformString(accrualCliff)}`);
  return accrualCliff;
};

export const translateUserCustomBenefitRequestType = (
  type: UserCustomBenefitRequestType,
  polyglot: Polyglot
): string => {
  if (polyglot.has(`BenefitModule.UserCustomBenefitRequestType.${transformString(type)}`))
    return polyglot.t(`BenefitModule.UserCustomBenefitRequestType.${transformString(type)}`);
  return type;
};

export const translateJSMonthNo = (month: number, polyglot: Polyglot): string => {
  const monthStr = String(month);
  if (polyglot.has(`getMonthOptionsByJSMonthNo.${transformString(monthStr)}`))
    return polyglot.t(`getMonthOptionsByJSMonthNo.${transformString(monthStr)}`);
  return monthStr;
};

export const translateDefaultApprovalRule = (name: string, polyglot: Polyglot): string => {
  switch (name) {
    case DefaultApprovalRule.AutoApprove:
      return polyglot.t('ApprovalRuleModule.DefaultApprovalRuleDescription.autoapprove');
    case DefaultApprovalRule.Admin:
      return polyglot.t('ApprovalRuleModule.DefaultApprovalRuleDescription.domainAdmin');
    case DefaultApprovalRule.Manager:
      return polyglot.t('ApprovalRuleModule.DefaultApprovalRuleDescription.manager');
    case DefaultApprovalRule.ManagerPlusManager:
      return polyglot.t('ApprovalRuleModule.DefaultApprovalRuleDescription.managerAndManagersManager');
    default:
      return name;
  }
};

export const translateManagerPermissionGroupReportsLevel = (level: number, polyglot: Polyglot): string => {
  if (polyglot.has(`ManagerPermissionGroup.Levels.${level}`))
    return polyglot.t(`ManagerPermissionGroup.Levels.${level}`);
  return level.toString();
};
