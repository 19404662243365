import { Box, SxProps, Theme } from '@mui/material';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as BackBtn } from '@/images/fields/Left.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { themeColors } from '@/v2/styles/colors.styles';

interface StepperHeaderProps {
  content: JSX.Element;
  contentSx?: SxProps<Theme>;
  showStepBack?: boolean;
  stepBackAction?: () => Promise<void> | void;
  stepBackSx?: SxProps<Theme>;
  showBack?: boolean;
  backAction?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void;
  backSx?: SxProps<Theme>;
  disableClose?: boolean;
}

export const StepperHeader = ({
  content,
  contentSx,
  showStepBack = false,
  stepBackAction,
  stepBackSx,
  showBack = false,
  backAction,
  backSx,
  disableClose,
}: StepperHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Box sx={stepBackSx}>
        {showStepBack && (
          <IconButton colorVariant="secondary" sizeVariant="small" onClick={stepBackAction}>
            <BackBtn />
          </IconButton>
        )}
      </Box>

      <Box sx={contentSx}>{content}</Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', ...backSx }}>
        {showBack && (
          <IconButton colorVariant="secondary" sizeVariant="small" onClick={backAction} disabled={disableClose}>
            <Close stroke={themeColors.DarkGrey} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
