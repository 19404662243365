import { useCallback, useMemo } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as BulkUpload } from '@/images/documents/BulkUpload.svg';
import { ReactComponent as DocumentBig } from '@/images/documents/DocumentBig.svg';
import { ReactComponent as Megaphone } from '@/images/documents/Megaphone.svg';
import { ReactComponent as Upload } from '@/images/fields/Upload.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { DocumentExport, ZeltDocumentType } from '@/lib/documents';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

interface NewDocumentButtonProps {
  onUploadClick: () => void;
  onRequestClick: () => void;
  onBulkUploadClick?: () => void;
  onTemplateClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  isAdmin?: boolean;
  readonly documentTypes?: readonly Pick<
    ZeltDocumentType,
    'value' | 'label' | 'needsVerification' | 'canUserAdd' | 'category'
  >[];
}

export const NewDocumentButton = ({
  onUploadClick,
  onRequestClick,
  onBulkUploadClick,
  onTemplateClick,
  isAdmin,
  documentTypes,
}: NewDocumentButtonProps) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();

  const exportDataForDownload = useCallback(
    (data: DocumentExport[]): string => {
      const documentTypeHash = documentTypes
        ? Object.fromEntries(documentTypes.map(({ value, label }) => [value, label]))
        : {};
      const headers = [
        { label: 'Name', key: 'Name' },
        { label: 'Type', key: 'Type' },
        { label: 'Upload', key: 'Upload' },
        { label: 'Uploaded By', key: 'Uploaded By' },
        { label: 'Belongs to', key: 'Belongs to' },
        { label: 'Status', key: 'Status' },
        { label: 'Signatories', key: 'Signatories' },
      ];

      const rows = data.map((d) => {
        return {
          Name: d.documentName,
          Type: documentTypeHash[d.type]?.toString() ?? 'Other',
          Upload: d.uploadDate,
          'Uploaded By': d.uploadedBy,
          'Belongs to': d.belongsTo,
          Status: d.status,
          Signatories: d.signatories.map((s) => getCachedUserById(s)?.displayName).join(' / '),
        };
      });

      return csvContent(headers, rows);
    },
    [getCachedUserById, documentTypes]
  );

  const csvContent = (headers: { label: string; key: string }[], rows: any[]): string => {
    const csvHeaders = headers.map((header) => header.label).join(',');
    const csvRows = rows.map((row) => headers.map((header) => row[header.key]).join(',')).join('\n');

    return `${csvHeaders}\n${csvRows}`;
  };
  const handleDownload = useCallback(async () => {
    try {
      const documentDataForExport = await DocumentAPI.listCompanyDocumentsForExport();
      const csvContent = exportDataForDownload(documentDataForExport);

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents-export.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(polyglot.t('DocumentExportButton.errorMessages.export'), error.message);
    }
  }, [exportDataForDownload, polyglot]);

  const newDocumentButtonOptions = useMemo(() => {
    const optionsAvailable = [];
    if (onUploadClick)
      optionsAvailable.push({
        icon: <Upload {...iconSize} />,
        handler: () => onUploadClick(),
        label: polyglot.t('NewDocumentButton.upload'),
        disabled: false,
      });
    if (onRequestClick && isAdmin)
      optionsAvailable.push({
        icon: <Megaphone {...iconSize} />,
        handler: () => onRequestClick(),
        label: polyglot.t('NewDocumentButton.request'),
        disabled: false,
      });
    if (onBulkUploadClick && isAdmin)
      optionsAvailable.push({
        icon: <BulkUpload {...iconSize} />,
        handler: () => onBulkUploadClick(),
        label: polyglot.t('NewDocumentButton.bulkUpload'),
        disabled: false,
      });
    if (onTemplateClick && isAdmin)
      optionsAvailable.push({
        icon: <DocumentBig {...iconSize} />,
        handler: () => onTemplateClick(),
        label: polyglot.t('NewDocumentButton.template'),
        disabled: false,
      });
    if (documentTypes && isAdmin)
      optionsAvailable.push({
        icon: <Export {...iconSize} />,
        handler: () => handleDownload(),
        label: polyglot.t('General.export'),
        disabled: false,
      });
    return optionsAvailable;
  }, [
    polyglot,
    onUploadClick,
    onRequestClick,
    isAdmin,
    onBulkUploadClick,
    onTemplateClick,
    handleDownload,
    documentTypes,
  ]);

  return (
    <>
      <StyledMenuComponent
        options={newDocumentButtonOptions}
        actionButtonDetails={{
          type: 'button',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: polyglot.t('NewDocumentButton.new'),
          icon: <ArrowDown {...iconSize} />,
          iconPosition: 'end',
        }}
      />
    </>
  );
};
