import axios from 'axios';

import { ReviewAnswer, UpsertReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewAnswerAPI {
  static async saveAnswersAsDraft(
    reviewAnswer: UpsertReviewAnswer[],
    entryId: string,
    cycleId: string,
    revieweeId: number
  ): Promise<void> {
    return (await axios.post(`/apiv2/review-answer/draft/${entryId}/${cycleId}/${revieweeId}`, reviewAnswer)).data;
  }

  static async submitAnswers(
    reviewAnswer: UpsertReviewAnswer[],
    entryId: string,
    cycleId: string,
    revieweeId: number
  ): Promise<void> {
    return (await axios.post(`/apiv2/review-answer/submit/${entryId}/${cycleId}/${revieweeId}`, reviewAnswer)).data;
  }

  static async getAnswer(entryId: string, cycleId: string): Promise<ReviewAnswer[]> {
    return (await axios.get(`/apiv2/review-answer/${entryId}/${cycleId}`)).data;
  }
}

export class ReviewAnswerEndpoints {
  static getAnswer(entryId: string, cycleId: string): Endpoint<ReviewAnswer[]> {
    return {
      url: `/apiv2/review-answer/${entryId}/${cycleId}`,
    };
  }
}
