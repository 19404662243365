import React, { Suspense, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { RefinancingAPI } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api';
import { DeviceOrderWithCompanyDetails } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { buttonBoxSx, fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@v2/styles/colors.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { BillingEndpoints } from '@/api-client/billing.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface RefinancePlanCreateAccountDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly order: DeviceOrderWithCompanyDetails;
  readonly refresh: () => Promise<void>;
}

export const RefinancePlanCreateAccountDrawer = ({
  isOpen,
  setIsOpen,
  order,
  refresh,
}: RefinancePlanCreateAccountDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <RefinancePlanCreateAccountDrawerContent order={order} refresh={refresh} />
      </Suspense>
    </DrawerModal>
  );
};

interface RefinancePlanCreateAccountDrawerContentProps {
  readonly order: DeviceOrderWithCompanyDetails;
  readonly refresh: () => Promise<void>;
}

const RefinancePlanCreateAccountDrawerContent = ({ order, refresh }: RefinancePlanCreateAccountDrawerContentProps) => {
  const { data: billingInfo } = useApiClient(BillingEndpoints.getBillingInfo());
  const [showMessage] = useMessage();
  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState<boolean>(false);

  const createRepaymentAccount = useCallback(
    async (companyId: number, values: { billingPhone: string }) => {
      try {
        setIsCreateAccountLoading(true);
        await RefinancingAPI.createCompanyRepaymentAccount(companyId, values);

        showMessage('Account successfully created.', 'success');
        await refresh();
      } catch (error) {
        showMessage(`Could not create repayment account. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setIsCreateAccountLoading(false);
      }
    },
    [refresh, showMessage]
  );

  const formik = useFormik<{ billingPhone: string }>({
    initialValues: {
      billingPhone: billingInfo?.billingContact?.phoneNumber ?? '',
    },
    validationSchema: yup.object({
      billingPhone: yup.string().required('Billing phone is required.'),
    }),
    onSubmit: async (values: { billingPhone: string }) => {
      if (order.refinancing?.accountId) {
        showMessage('An account has already been created for this company.', 'error');
        return;
      }

      await createRepaymentAccount(order.companyId, values);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>Create repayment account</Typography>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Billing Phone"
            name="billingPhone"
            value={formik.values.billingPhone}
            onChange={formik.handleChange}
            error={formik.touched.billingPhone && Boolean(formik.errors.billingPhone)}
            helperText={(formik.touched.billingPhone && formik.errors.billingPhone) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={buttonBoxSx}>
          <LoaderButton
            sizeVariant="large"
            colorVariant="primary"
            name="Create"
            loading={isCreateAccountLoading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
