import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { tableIconButtonSx, tableIconErrorButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { LocalDate } from '@v2/util/local-date';

import { BillingInvoiceAPI } from '@/api-client/billing-invoice.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as ExternalLink } from '@/images/icons/external-link-alt-solid.svg';
import { ReactComponent as Plus } from '@/images/icons/plus-solid.svg';
import { ReactComponent as CleanCircle } from '@/images/side-bar-icons/CleanCircle.svg';
import { ReactComponent as Envelope } from '@/images/side-bar-icons/EnvelopeSimple.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Invoice, InvoiceStatus } from '@/models/invoice.model';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SuperAdminBillingInvoiceTableHelper } from '@/v2/feature/super-admin/features/super-admin-billing/helper/super-admin-billing-invoices-table-helper';
import { themeColors } from '@/v2/styles/colors.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly pageConfig: PageConfig;
}

export function SuperAdminBillingInvoicesPage({ pageConfig }: Props) {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElInvoice, setAnchorElInvoice] = useState<HTMLButtonElement | null>(null);
  const [invoiceFailedPaymentToDelete, setInvoiceFailedPaymentToDelete] = useState<{
    companyId: number;
    companyName: string;
    invoiceId: string;
    failedPaymentId: number;
    startDate: string;
    endDate: string;
  } | null>(null);

  const [invoiceToDelete, setInvoiceToDelete] = useState<{
    companyId: number;
    companyName: string;
    invoiceId: string;
    startDate: string;
    endDate: string;
  } | null>(null);

  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchInvoices = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string }) => {
      try {
        setIsLoadingInvoices(true);
        const { invoices, pagination: paginationInfo } = await BillingInvoiceAPI.getAllInvoices(params);
        setInvoices(invoices || []);

        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingInvoices(false);
      } catch (error) {
        showMessage(`Something went wrong fetching invoices. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const refresh = useCallback(async () => {
    await fetchInvoices({
      page: pageIndex.toString(),
      pageSize: DEFAULT_PAGE_SIZE.toString(),
      searchQuery,
    });
  }, [fetchInvoices, pageIndex, searchQuery]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handleDownloadClick = useCallback(
    async (invoice: Invoice) => {
      try {
        const PDFBlob = await BillingInvoiceAPI.downloadInvoiceAsSuperAdmin({
          companyId: invoice.companyId,
          id: invoice.id,
        });

        const file = new Blob([PDFBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        let link = document.createElement('a');
        link.download = `${invoice.id}-${new Date()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (e) {
        console.error('::Download error', e);
        showMessage(`Failed to download invoice. ${nestErrorMessage(e)}`, 'error');
      }
    },
    [showMessage]
  );

  const handleDeleteInvoiceFailedBillingPayment = useCallback(
    async (companyId: number, invoiceId: string, failedPaymentId: number) => {
      if (!invoiceId) return;
      try {
        await BillingInvoiceAPI.deleteFailiedBillingPaymentAsSuperAdmin(companyId, invoiceId, failedPaymentId);

        await refresh();
      } catch (error) {
        showMessage(`Could not delete failed payment. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refresh, showMessage]
  );

  const handleDeleteInvoice = useCallback(
    async (companyId: number, invoiceId: string) => {
      if (!invoiceId) return;
      try {
        await BillingInvoiceAPI.deleteInvoiceAsSuperAdmin(companyId, invoiceId);

        await refresh();
      } catch (error) {
        showMessage(`Could not delete invoice. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refresh, showMessage]
  );

  const generateExternalInvoice = useCallback(
    async (invoiceId: string) => {
      try {
        await BillingInvoiceAPI.generateExternalInvoiceAsSuperAdmin(invoiceId);

        await refresh();
      } catch (error) {
        console.error('Generate invoice error', error);
        showMessage(`Failed to generate external invoice. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refresh, showMessage]
  );

  const sendUnpaidInvoiceReminder = useCallback(
    async (companyId: number, invoiceId: string) => {
      try {
        await BillingInvoiceAPI.sendEmailReminderOfUnpaidInvoiceAsSuperAdmin(companyId, invoiceId);
        showMessage('Email sent.', 'success');
      } catch (error) {
        showMessage(`Action failed. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const invoiceTableColumns = useMemo<ColumnDef<Invoice, unknown>[]>(
    () => [
      SuperAdminBillingInvoiceTableHelper.getInvoiceIdColumn({
        header: 'Invoice Id',
        id: 'id',
        size: 145,
      }),
      SuperAdminBillingInvoiceTableHelper.getCompanyNameColumn({
        header: 'Company',
        id: 'companyName',
        size: 145,
      }),

      SuperAdminBillingInvoiceTableHelper.getCompanyIdColumn({
        header: 'Company ID',
        id: 'companyId',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getPeriodStartDateColumn({
        header: 'Period Start',
        id: 'periodStart',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getPeriodEndDateColumn({
        header: 'Period End',
        id: 'periodEnd',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getInvoiceDateColumn({
        header: 'Invoice Date',
        id: 'invoiceDate',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getInvoiceStatusColumn({
        header: 'Status',
        id: 'status',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getNoOfSubscriptionColumn({
        header: 'Total Subscriptions',
        id: 'totalSubscriptions',
        size: 100,
      }),

      SuperAdminBillingInvoiceTableHelper.getTotalAmountColumn({
        header: 'Total Amount',
        id: 'totalAmount',
        size: 100,
      }),

      {
        header: () => 'Action',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.id ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
              <StyledTooltip id="download-invoice" title="Download invoice" placement="top">
                <IconButton
                  key={`download-${original.id}`}
                  onClick={async () => {
                    await handleDownloadClick(original);
                  }}
                  sx={tableIconButtonSx}
                >
                  <DownloadIcon {...iconSize} />
                </IconButton>
              </StyledTooltip>

              {original.externalId ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <a
                    href={`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${original.externalId}`}
                    target="_blank"
                    rel="noreferrer"
                    key={`redirect-${original.externalId}`}
                  >
                    <StyledTooltip id="external-invoice" title="External invoice" placement="top">
                      <IconButton key={`redirect-${original.id}`} sx={tableIconButtonSx}>
                        <ExternalLink {...iconSize} />
                      </IconButton>
                    </StyledTooltip>
                  </a>
                </Box>
              ) : (
                <StyledTooltip id="external-invoice" title="Push to Xero" placement="top">
                  <IconButton
                    sx={{ ...tableIconButtonSx, bgcolor: themeColors.lightBlue }}
                    key={`generate-${original.id}`}
                    onClick={async () => {
                      await generateExternalInvoice(original.id);
                    }}
                  >
                    <Plus {...iconSize} />
                  </IconButton>
                </StyledTooltip>
              )}

              {original.status !== InvoiceStatus.Paid && (
                <StyledTooltip id="reminder-email" title="Send reminder" placement="top">
                  <IconButton
                    key={`reminder-email-${original.id}`}
                    onClick={async () => {
                      await sendUnpaidInvoiceReminder(original.companyId, original.id);
                    }}
                    sx={tableIconButtonSx}
                  >
                    <Envelope {...iconSize} />
                  </IconButton>
                </StyledTooltip>
              )}
              {original.billingFailedPayment?.id && (
                <StyledTooltip
                  id="delete-failed-payment"
                  title="Delete invoice failed payment"
                  placement="top"
                  sx={{ ml: '10px' }}
                >
                  <IconButton
                    key={`delete-payment-${original.id}`}
                    onClick={(event) => {
                      if (!original.billingFailedPayment?.id) return;
                      setInvoiceFailedPaymentToDelete({
                        companyId: original.companyId,
                        companyName: original.company.name,
                        invoiceId: original.id,
                        failedPaymentId: original.billingFailedPayment.id,
                        startDate: original.billingPeriodStartDate,
                        endDate: original.billingPeriodEndDate,
                      });
                      setAnchorEl(event.currentTarget);
                    }}
                    color="error"
                    sx={tableIconErrorButtonSx}
                  >
                    <CleanCircle {...iconSize} />
                  </IconButton>
                </StyledTooltip>
              )}

              {original.status !== InvoiceStatus.Paid && (
                <StyledTooltip id="delete-invoice" title="Delete unpaid invoice" placement="top" sx={{ ml: '10px' }}>
                  <IconButton
                    key={`delete-invoice-${original.id}`}
                    onClick={(event) => {
                      setInvoiceToDelete({
                        companyId: original.companyId,
                        companyName: original.company.name,
                        invoiceId: original.id,
                        startDate: original.billingPeriodStartDate,
                        endDate: original.billingPeriodEndDate,
                      });
                      setAnchorElInvoice(event.currentTarget);
                    }}
                    color="error"
                    sx={tableIconErrorButtonSx}
                  >
                    <Trash {...iconSize} />
                  </IconButton>
                </StyledTooltip>
              )}
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 220,
      },
    ],
    [generateExternalInvoice, handleDownloadClick, sendUnpaidInvoiceReminder]
  );

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={
          <Typography variant="title2" sx={{ width: '100%' }}>
            Billing
          </Typography>
        }
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={isLoadingInvoices} secondLevel>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              ...spacing.mb20,
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <TableSearch
              style={{ width: '20em' }}
              query={searchQuery}
              handleChange={(e) => {
                setSearchQuery(e.target.value?.trim() ?? '');
                // Resets pagination index once we perform a new search
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
              placeholder="Enter company id or name..."
            />
          </Box>

          {!isLoadingInvoices && (
            <>
              <BasicServerTable<Invoice>
                rowData={[...invoices]}
                columnData={invoiceTableColumns}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                setPagination={setPagination}
                totalPages={totalPages}
                totalItems={totalItems}
              />
            </>
          )}

          {anchorEl && invoiceFailedPaymentToDelete && (
            <NotificationModal
              isOpen={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
                setInvoiceFailedPaymentToDelete(null);
              }}
              anchorEl={anchorEl}
              takeAction={async () => {
                if (!invoiceFailedPaymentToDelete) return;
                await handleDeleteInvoiceFailedBillingPayment(
                  invoiceFailedPaymentToDelete.companyId,
                  invoiceFailedPaymentToDelete.invoiceId,
                  invoiceFailedPaymentToDelete.failedPaymentId
                );
                setInvoiceFailedPaymentToDelete(null);
                setAnchorEl(null);
              }}
              message={
                <Box sx={{ mb: '10px' }}>
                  <Typography variant="title4" sx={{ mb: '5px' }}>
                    Delete billing failed payment?
                  </Typography>
                  <Typography variant="captionSmall">
                    Company: <b>{invoiceFailedPaymentToDelete.companyName}</b>
                  </Typography>
                  <Typography variant="captionSmall">
                    Invoice ID: <b>{invoiceFailedPaymentToDelete.invoiceId}</b>
                  </Typography>
                  <Typography variant="captionSmall">
                    Invoice period:{' '}
                    <b>
                      {new LocalDate(invoiceFailedPaymentToDelete.startDate).toLocaleDateString()} -{' '}
                      {new LocalDate(invoiceFailedPaymentToDelete.endDate).toLocaleDateString()}
                    </b>
                  </Typography>
                </Box>
              }
              callToAction="Delete"
            />
          )}

          {anchorElInvoice && invoiceToDelete && (
            <NotificationModal
              isOpen={Boolean(anchorElInvoice)}
              onClose={() => {
                setAnchorEl(null);
                setInvoiceFailedPaymentToDelete(null);
              }}
              anchorEl={anchorEl}
              takeAction={async () => {
                if (!invoiceToDelete) return;
                await handleDeleteInvoice(invoiceToDelete.companyId, invoiceToDelete.invoiceId);
                setInvoiceToDelete(null);
                setAnchorElInvoice(null);
              }}
              message={
                <Box sx={{ mb: '10px' }}>
                  <Typography variant="title4" sx={{ mb: '5px' }}>
                    Delete invoice?
                  </Typography>
                  <Typography variant="captionSmall">
                    Company: <b>{invoiceToDelete.companyName}</b>
                  </Typography>
                  <Typography variant="captionSmall">
                    Invoice ID: <b>{invoiceToDelete.invoiceId}</b>
                  </Typography>
                  <Typography variant="captionSmall">
                    Invoice period:{' '}
                    <b>
                      {new LocalDate(invoiceToDelete.startDate).toLocaleDateString()} -{' '}
                      {new LocalDate(invoiceToDelete.endDate).toLocaleDateString()}
                    </b>
                  </Typography>
                </Box>
              }
              callToAction="Delete"
            />
          )}
        </>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
}
