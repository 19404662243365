import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DeviceInfoDto } from '@v2/feature/device/features/enrollment-device/device-info.dto';
import { EnrollmentStatus, InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { pusherClient } from '@v2/infrastructure/pusher/pusher.hook';
import { saveAs } from 'file-saver';
import { Channel } from 'pusher-js';
import { generatePath, useHistory } from 'react-router-dom';

import { MessageType } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DEVICE_DETAILS_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DevicePossessionType } from '@/v2/feature/device/device.interface';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { buttonSettingSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { settingCardSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface EnrollExistingDeviceByZeltProps {
  devicePossession: DevicePossessionDto;
  refreshAppData: () => Promise<void>;
}

const GetKeyValue = ({
  key1,
  value1,
  key2,
  value2,
}: {
  key1: string;
  value1: string;
  key2: string;
  value2: string;
}) => {
  return (
    <>
      <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <Typography sx={{ ...themeFonts.caption }}>{key1}: </Typography>
        <Typography sx={{ ...themeFonts.title4 }}>{value1}</Typography>
      </Box>
      <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <Typography sx={{ ...themeFonts.caption }}>{key2}: </Typography>
        <Typography sx={{ ...themeFonts.title4 }}>{value2}</Typography>
      </Box>
    </>
  );
};

export const EnrollDevice = ({ devicePossession, refreshAppData }: EnrollExistingDeviceByZeltProps) => {
  const [showingState, setShowingState] = useState<string>('enrolling');
  const compDeviceInfo = useRef<DeviceInfoDto | null>(null);
  const compEnrollmentStatus = useRef<EnrollmentStatus | null>(null);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const routerHistory = useHistory();
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const environmentPrefix = `${isProduction ? 'prod' : 'test'}`;
  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        if (devicePossession.possessionType !== DevicePossessionType.User) {
          setShowingState('inventorydevice');
          return;
        }
        if (devicePossession.device?.isDep) {
          setShowingState('depdevice');
          return;
        }
      } catch (error) {
        showMessage(`Could not retrieve enrollment data. ${nestErrorMessage(error)}`, 'error');
      }
    })();

    const enrollmentStatusHandler = (data: { message: string; type: MessageType }) => {
      if (data.message) {
        compEnrollmentStatus.current = EnrollmentStatus[data.message as keyof typeof EnrollmentStatus];
        if (compDeviceInfo.current && compEnrollmentStatus.current === EnrollmentStatus.ENROLMENT_FINISHED) {
          setShowingState('enrolled');
          return;
        }
        if (compEnrollmentStatus.current !== EnrollmentStatus.ENROLMENT_FINISHED) {
          setShowingState('failed');
          return;
        }
        return;
      }
    };
    const deviceInfoStatusHandler = (data: { message: string; type: MessageType }) => {
      if (data.message) {
        compDeviceInfo.current = JSON.parse(data.message);
        if (
          compDeviceInfo.current &&
          compEnrollmentStatus.current &&
          compEnrollmentStatus.current === EnrollmentStatus.ENROLMENT_FINISHED
        ) {
          setShowingState('enrolled');
          return;
        }
        if (
          compEnrollmentStatus.current &&
          compEnrollmentStatus.current !== EnrollmentStatus.ENROLMENT_FINISHED &&
          !compDeviceInfo.current
        ) {
          setShowingState('failed');
          return;
        }
        return;
      }
    };

    const standardChannel: Channel = pusherClient.subscribe(
      `mdm-${environmentPrefix}-device-id-${devicePossession.deviceId}`
    );
    standardChannel.bind(`device-info`, deviceInfoStatusHandler);
    standardChannel.bind(`enrollment-status`, enrollmentStatusHandler);
  }, [devicePossession, showMessage, environmentPrefix]);

  const downloadEnrollmentProfile = useCallback(async () => {
    try {
      const profile = await InHouseMdmAPI.getEnrolmentProfile(devicePossession.deviceId);
      const blob = new Blob([Buffer.from(profile, 'base64')], {
        type: 'application/x-apple-aspen-config',
      });
      saveAs(blob, 'enrollment.mobileconfig');
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, 'error');
      }
    }
  }, [devicePossession.deviceId, showMessage]);

  const getCurrentState = () => {
    switch (showingState) {
      case 'enrolling':
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
              <Mistake width={20} height={20} fill={themeColors.GreyMiddle} />
              <Typography sx={themeFonts.title2}>Device not enrolled</Typography>
            </Box>

            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              Download Zelt MDM and secure your sensitive data, recover lost devices and configure settings. See your
              device status live on Zelt and manage it remotely when needed.
            </Typography>

            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              Click download and install the configuration profile (enrollment.mobileconfig) to start the enrolment.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'left',
                ...spacing.mt20,
                gap: spacing.g5,
                width: { xs: '100%', sm: '50%', md: '50%', lg: '50%' },
              }}
            >
              <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={downloadEnrollmentProfile} fullWidth>
                Download
              </ButtonComponent>
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                fullWidth
                onClick={() => window.open('https://zelt.app/blog/protect-your-device-with-zelt-mdm', '_blank')}
              >
                Learn more
              </ButtonComponent>
            </Box>
          </>
        );

      case 'enrolled':
        return (
          <>
            <Typography sx={{ ...themeFonts.title2, ...spacing.mt40 }}>All done!</Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m5 }}>
              Your device has been successfully enrolled. You can sit back and enjoy your device securely. To see and
              manage this device go to Devices app.
            </Typography>

            {compDeviceInfo.current ? (
              <GetKeyValue
                key1="Device model"
                value1={compDeviceInfo.current.modelName}
                key2="Serial number"
                value2={compDeviceInfo.current.serialNumber}
              />
            ) : (
              <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                  Couldn't fetch the enrollment status.
                </Typography>
              </Box>
            )}
            <Box sx={{ ...buttonSettingSx, maxWidth: '35%', width: 'auto', mt: spacing.m30 }}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                onClick={async () => {
                  await refreshAppData();
                  routerHistory.push(
                    generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                  );
                }}
              >
                Complete
              </ButtonComponent>
            </Box>
          </>
        );

      case 'failed':
        return (
          <>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Try again</Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m5 }}>
              Looks like the profile installation was not successful. Please try again.
            </Typography>

            <Box sx={{ ...buttonSettingSx, maxWidth: '35%', width: 'auto', mt: spacing.m30 }}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth={true}
                onClick={downloadEnrollmentProfile}
              >
                Download
              </ButtonComponent>
            </Box>
          </>
        );
      case 'inventorydevice':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ ...themeFonts.title3 }}>Use Zelt MDM </Typography>{' '}
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              Before enrolling this device into MDM, please ensure it is assigned to a user.
            </Typography>
          </Box>
        );
      case 'depdevice':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ ...themeFonts.title3 }}>Use Zelt MDM </Typography>{' '}
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              Your device is eligible to automated-enrolment, wipe it or follow the instruction:
              <p>Open the Terminal app on the required computer and perform the following steps:</p>
              <ol>
                <li>Enter the command:</li>
                <pre>
                  <code>sudo profiles renew -type enrollment</code>
                </pre>
                <li>Press Return.</li>
                <li>Enter the password for the local account and press Return.</li>
              </ol>
              <p>For macOS 14 or later:</p>
              <ol>
                <li>Click 'Enroll' on the Remote Management screen.</li>
                <li>Enter the password for the local account and click 'Enroll'.</li>
                <li>A status of 'Enrollment Complete' will appear on the Remote Management screen.</li>
                <li>Click 'Quit'.</li>
              </ol>
              <p>For macOS 13 or earlier:</p>
              <ol>
                <li>
                  Click 'Details' on the Device Enrolment notification banner in the upper right-hand corner of your
                  screen.
                </li>
                <li>
                  If the banner is not immediately visible, click on the date in the upper right-hand corner of your Mac
                  to display recent notifications, including the Device Enrollment banner.
                </li>
                <li>Follow the prompts to install the required profiles.</li>
              </ol>
              <p>
                Enable 'Find My' or sign in to iCloud on the computer after enrolment to share its Activation Lock
                bypass code with Zelt.
              </p>
              <p>
                Remember: If you enabled FileVault before enrolment and want to store FileVault keys in Zelt, you must
                escrow a new FileVault recovery key.
              </p>
            </Typography>
          </Box>
        );
      //this case should never occur
      default:
        return <>Something went wrong</>;
    }
  };
  return (
    <Box sx={settingCardSx}>
      {getCurrentState()}
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.TECH_PRO}
        messageSuffix="proGeneric"
      />
    </Box>
  );
};
