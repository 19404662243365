import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { OptionObject, SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { DowngradeViability } from '@v2/feature/company/company-settings/company-settings.interface';
import { formatMoney, formatPercentage } from '@v2/feature/payments/utils/money.util';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import { BillingSubscriptionAPI } from '@/api-client/billing-subscription.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Pro } from '@/images/Pro.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SubscriptionBill } from '@/models/invoice.model';
import {
  CompanyBillingSubscription,
  CompanySubscriptionStats,
  FreeProductPlans,
  getProductPlanIdToName,
  PaidProductPlans,
  Plan,
  Product,
  SubscriptionBillingStats,
} from '@/models/subscription.model';

const getProductOption = (
  productName: string,
  proPricePerUser: number,
  descriptionFree: string,
  descriptionPro: string,
  discountRate: number,
  disableDowngrade = false
) => [
  {
    label: (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', height: '35px' }}>
        <Typography sx={themeFonts.caption}>{productName}</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, ml: spacing.m5 }}>Free</Typography>
      </Box>
    ),
    value: Plan.FREE,
    description: descriptionFree,
    disabled: disableDowngrade,
  },
  {
    label: (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', height: '35px' }}>
        <Typography sx={themeFonts.caption}>{productName}</Typography>
        <Box sx={{ display: 'flex' }}>
          <Pro width={40} height={40} />
        </Box>
        <Typography sx={themeFonts.caption}>{formatMoney({ amount: proPricePerUser * (1 - discountRate) })}</Typography>

        {discountRate > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Red }}>
              {formatPercentage(Math.round(discountRate * 100), 0)} discount
            </Typography>
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Red,
                textDecoration: 'line-through',
              }}
            >
              {formatMoney({ amount: proPricePerUser })}
            </Typography>
          </Box>
        )}
      </Box>
    ),
    value: Plan.PRO,
    description: descriptionPro,
  },
];

const getProductOptions = (
  productsCostsPerUser: {
    peoplePaidPlanCostPerUser: number;
    moneyPaidPlanCostPerUser: number;
    appsPaidPlanCostPerUser: number;
    devicesPaidPlanCostPerUser: number;
  },
  downgradeViability: Record<string, DowngradeViability>,
  productDiscount: { [key in Product]: number }
): {
  peopleOptions: OptionObject[];
  moneyOptions: OptionObject[];
  appsOptions: OptionObject[];
  devicesOptions: OptionObject[];
} => {
  const {
    peoplePaidPlanCostPerUser,
    moneyPaidPlanCostPerUser,
    appsPaidPlanCostPerUser,
    devicesPaidPlanCostPerUser,
  } = productsCostsPerUser;

  const peopleOptions = getProductOption(
    'People',
    peoplePaidPlanCostPerUser,
    'Invite up to 9 employees.',
    'Unlimited departments and sites.',
    productDiscount[Product.PEOPLE],
    downgradeViability['PEOPLE']?.disableDowngrade
  );

  const moneyOptions = getProductOption(
    'Money',
    moneyPaidPlanCostPerUser,
    'Add up to 9 employees to payroll.',
    'Unlimited employees on payroll.',
    productDiscount[Product.MONEY],
    downgradeViability['MONEY']?.disableDowngrade
  );
  const appsOptions = getProductOption(
    'Apps',
    appsPaidPlanCostPerUser,
    'Connect up to 5 apps.',
    'Unlimited apps.',
    productDiscount[Product.APPS],
    downgradeViability['APPS']?.disableDowngrade
  );
  const devicesOptions = getProductOption(
    'Devices',
    devicesPaidPlanCostPerUser,
    'Basic logistics, replacement service and device configurations.',
    'Advanced logistics, replacement service and device configurations.',
    productDiscount[Product.DEVICES],
    downgradeViability['DEVICES']?.disableDowngrade
  );

  return { peopleOptions, moneyOptions, appsOptions, devicesOptions };
};

interface UpdateSubscriptionDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly subscriptions: CompanyBillingSubscription[];
  readonly subscriptionsStats: CompanySubscriptionStats | null;
  readonly subscriptionsBillingStats: SubscriptionBillingStats | null;
  readonly downgradeViability: Record<string, DowngradeViability>;
  readonly refresh: () => Promise<void>;
}

export const UpdateSubscriptionDrawer = ({
  isOpen,
  setIsOpen,
  subscriptions,
  subscriptionsStats,
  subscriptionsBillingStats,
  downgradeViability,
  refresh,
}: UpdateSubscriptionDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <UpdateSubscriptionDrawerContent
        subscriptions={subscriptions}
        subscriptionsStats={subscriptionsStats}
        downgradeViability={downgradeViability}
        subscriptionsBillingStats={subscriptionsBillingStats}
        refresh={refresh}
      />
    </DrawerModal>
  );
};

interface SubscriptionOptionsForm {
  people: Plan;
  money: Plan;
  apps: Plan;
  devices: Plan;
}

const detectUpgradeAndDowngrades = (
  currentPeoplePlan: CompanyBillingSubscription | undefined,
  currentMoneyPlan: CompanyBillingSubscription | undefined,
  currentAppsPlan: CompanyBillingSubscription | undefined,
  currentDevicesPlan: CompanyBillingSubscription | undefined,
  formValues: SubscriptionOptionsForm,
  polyglot: Polyglot
): {
  downgrades: { id: number; productPlanId: FreeProductPlans; plan: string }[];
  upgrades: { productPlanId: PaidProductPlans; plan: string }[];
} => {
  const downgrades = [];
  const upgrades = [];
  if (currentPeoplePlan?.productPlan.planId !== formValues.people) {
    currentPeoplePlan && currentPeoplePlan.productPlan.planId > formValues.people
      ? downgrades.push({
          id: currentPeoplePlan.subscriptionId,
          productPlanId: currentPeoplePlan.productPlan.id,
          plan: getProductPlanIdToName(polyglot)[currentPeoplePlan.productPlan.id],
        })
      : upgrades.push({
          productPlanId: PaidProductPlans.PEOPLE_PRO,
          plan: getProductPlanIdToName(polyglot)[PaidProductPlans.PEOPLE_PRO],
        });
  }

  if (currentMoneyPlan?.productPlan.planId !== formValues.money) {
    currentMoneyPlan && currentMoneyPlan.productPlan.planId > formValues.money
      ? downgrades.push({
          id: currentMoneyPlan.subscriptionId,
          productPlanId: currentMoneyPlan.productPlan.id,
          plan: getProductPlanIdToName(polyglot)[currentMoneyPlan.productPlan.id],
        })
      : upgrades.push({
          productPlanId: PaidProductPlans.MONEY_PRO,
          plan: getProductPlanIdToName(polyglot)[PaidProductPlans.MONEY_PRO],
        });
  }

  if (currentAppsPlan?.productPlan.planId !== formValues.apps) {
    currentAppsPlan && currentAppsPlan.productPlan.planId > formValues.apps
      ? downgrades.push({
          id: currentAppsPlan.subscriptionId,
          productPlanId: currentAppsPlan.productPlan.id,
          plan: getProductPlanIdToName(polyglot)[currentAppsPlan.productPlan.id],
        })
      : upgrades.push({
          productPlanId: PaidProductPlans.APPS_PRO,
          plan: getProductPlanIdToName(polyglot)[PaidProductPlans.APPS_PRO],
        });
  }

  if (currentDevicesPlan?.productPlan.planId !== formValues.devices) {
    currentDevicesPlan && currentDevicesPlan.productPlan.planId > formValues.devices
      ? downgrades.push({
          id: currentDevicesPlan.subscriptionId,
          productPlanId: currentDevicesPlan.productPlan.id,
          plan: getProductPlanIdToName(polyglot)[currentDevicesPlan.productPlan.id],
        })
      : upgrades.push({
          productPlanId: PaidProductPlans.DEVICES_PRO,
          plan: getProductPlanIdToName(polyglot)[PaidProductPlans.DEVICES_PRO],
        });
  }

  return { downgrades, upgrades };
};

const extractDiscountRate = (totalCost: number, totalDiscount: number, noOfActiveUsers: number): number => {
  if (totalCost === 0 || totalDiscount === 0 || noOfActiveUsers === 0) return 0;

  return totalDiscount / totalCost;
};

const extractDiscountFromPlan = (peopleSubscriptionBills: SubscriptionBill[]): number => {
  const { totalCost, totalDiscount, noOfActiveUsers } = peopleSubscriptionBills.reduce(
    (acc, bill) => {
      acc.totalCost += bill.subscriptionCost;
      acc.totalDiscount += bill.totalDiscount ?? 0;
      acc.noOfActiveUsers = Math.max(acc.noOfActiveUsers, bill.noOfActiveUsers);
      return acc;
    },
    {
      totalCost: 0,
      totalDiscount: 0,
      noOfActiveUsers: 0,
    }
  );

  return extractDiscountRate(totalCost, totalDiscount, noOfActiveUsers);
};

const getDiscountPercentagesPerProduct = (
  billingStats: SubscriptionBillingStats | null
): { [key in Product]: number } => {
  if (!billingStats) return { [Product.PEOPLE]: 0, [Product.MONEY]: 0, [Product.APPS]: 0, [Product.DEVICES]: 0 };

  const peopleSubscriptionBills = billingStats[Product.PEOPLE].filter(
    (subscription) => subscription.subscriptionCost > 0
  );
  const peopleDiscount = extractDiscountFromPlan(peopleSubscriptionBills);

  const moneySubscriptionBills = billingStats[Product.MONEY].filter(
    (subscription) => subscription.subscriptionCost > 0
  );
  const moneyDiscount = extractDiscountFromPlan(moneySubscriptionBills);

  const appsSubscriptionBills = billingStats[Product.APPS].filter((subscription) => subscription.subscriptionCost > 0);
  const appsDiscount = extractDiscountFromPlan(appsSubscriptionBills);

  const devicesSubscriptionBills = billingStats[Product.DEVICES].filter(
    (subscription) => subscription.subscriptionCost > 0
  );
  const devicesDiscount = extractDiscountFromPlan(devicesSubscriptionBills);

  return {
    [Product.PEOPLE]: peopleDiscount,
    [Product.MONEY]: moneyDiscount,
    [Product.APPS]: appsDiscount,
    [Product.DEVICES]: devicesDiscount,
  };
};

const getProPlansCostPerUser = (
  subscriptionsStats: CompanySubscriptionStats | null
): {
  peoplePaidPlanCostPerUser: number;
  moneyPaidPlanCostPerUser: number;
  appsPaidPlanCostPerUser: number;
  devicesPaidPlanCostPerUser: number;
} => {
  const peoplePaidPlanCostPerUser =
    subscriptionsStats?.productPlanPricing[PaidProductPlans.PEOPLE_PRO].costPerUserPerMonth ?? 0;
  const moneyPaidPlanCostPerUser =
    subscriptionsStats?.productPlanPricing[PaidProductPlans.MONEY_PRO].costPerUserPerMonth ?? 0;
  const appsPaidPlanCostPerUser =
    subscriptionsStats?.productPlanPricing[PaidProductPlans.APPS_PRO].costPerUserPerMonth ?? 0;
  const devicesPaidPlanCostPerUser =
    subscriptionsStats?.productPlanPricing[PaidProductPlans.DEVICES_PRO].costPerUserPerMonth ?? 0;

  return { peoplePaidPlanCostPerUser, moneyPaidPlanCostPerUser, appsPaidPlanCostPerUser, devicesPaidPlanCostPerUser };
};

interface UpdateSubscriptionDrawerContentProps {
  readonly subscriptions: CompanyBillingSubscription[];
  readonly subscriptionsStats: CompanySubscriptionStats | null;
  readonly subscriptionsBillingStats: SubscriptionBillingStats | null;
  readonly downgradeViability: Record<string, DowngradeViability>;
  readonly refresh: () => Promise<void>;
}

const UpdateSubscriptionDrawerContent = ({
  subscriptions,
  subscriptionsStats,
  subscriptionsBillingStats,
  downgradeViability,
  refresh,
}: UpdateSubscriptionDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const currentPeoplePlan = subscriptions.find((plan) => plan.productPlan.productId === Product.PEOPLE);
  const currentMoneyPlan = subscriptions.find((plan) => plan.productPlan.productId === Product.MONEY);
  const currentAppsPlan = subscriptions.find((plan) => plan.productPlan.productId === Product.APPS);
  const currentDevicesPlan = subscriptions.find((plan) => plan.productPlan.productId === Product.DEVICES);

  const currentActiveUsers = subscriptionsStats?.companyStats.noOfActiveUsers ?? 0;
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const formik = useFormik<SubscriptionOptionsForm>({
    initialValues: {
      people: currentPeoplePlan?.productPlan.planId ?? Plan.FREE,
      money: currentMoneyPlan?.productPlan.planId ?? Plan.FREE,
      apps: currentAppsPlan?.productPlan.planId ?? Plan.FREE,
      devices: currentDevicesPlan?.productPlan.planId ?? Plan.FREE,
    },
    validationSchema: yup.object({
      people: yup.number().required('People module is required'),
      money: yup.number().required('Money module is required'),
      apps: yup.number().required('Apps module is required'),
      devices: yup.number().required('Devices module is required'),
    }),
    onSubmit: async (formData: SubscriptionOptionsForm) => {
      const { downgrades, upgrades } = detectUpgradeAndDowngrades(
        currentPeoplePlan,
        currentMoneyPlan,
        currentAppsPlan,
        currentDevicesPlan,
        formData,
        polyglot
      );
      if (!downgrades.length && !upgrades.length) return;

      try {
        setLoading(true);
        if (downgrades.length > 0)
          await Promise.all(
            downgrades.map((downgradePayload) => BillingSubscriptionAPI.downgradeSubscription(downgradePayload))
          );

        let checkoutSession;
        if (upgrades.length > 0) {
          checkoutSession = await BillingSubscriptionAPI.upgradeSubscription(upgrades);
        }

        if (checkoutSession?.url) {
          // Take customer through checkout flow
          return window.location.replace(checkoutSession.url);
        }
        showMessage('Plan updated successfully', 'success');

        await refresh();
      } catch (error) {
        showMessage(`Failed to update plan. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const productsCostsPerUser = getProPlansCostPerUser(subscriptionsStats);
  const productsDiscounts = getDiscountPercentagesPerProduct(subscriptionsBillingStats);
  const { peopleOptions, moneyOptions, appsOptions, devicesOptions } = getProductOptions(
    productsCostsPerUser,
    downgradeViability,
    productsDiscounts
  );

  const costPerUser = useMemo(() => {
    const { people, money, apps, devices } = formik.values;
    const {
      peoplePaidPlanCostPerUser,
      moneyPaidPlanCostPerUser,
      appsPaidPlanCostPerUser,
      devicesPaidPlanCostPerUser,
    } = productsCostsPerUser;

    const peopleCost = people === Plan.PRO ? peoplePaidPlanCostPerUser : 0;
    const moneyCost = money === Plan.PRO ? moneyPaidPlanCostPerUser : 0;
    const appsCost = apps === Plan.PRO ? appsPaidPlanCostPerUser : 0;
    const devicesCost = devices === Plan.PRO ? devicesPaidPlanCostPerUser : 0;

    return peopleCost + moneyCost + appsCost + devicesCost;
  }, [formik.values, productsCostsPerUser]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>Your subscription</Typography>

        <Box sx={fieldSx}>
          <SelectComponent
            name="people"
            label="Module"
            options={peopleOptions}
            value={formik.values.people}
            compareValue={formik.values.people}
            error={!!formik.errors.people && formik.touched.people}
            helperText={(formik.touched.people && formik.errors.people) as string}
            onChange={formik.handleChange}
          />
        </Box>

        <Box sx={fieldSx}>
          <SelectComponent
            name="money"
            label="Module"
            options={moneyOptions}
            value={formik.values.money}
            compareValue={formik.values.money}
            error={!!formik.errors.money && formik.touched.money}
            helperText={(formik.touched.money && formik.errors.money) as string}
            onChange={formik.handleChange}
          />
        </Box>

        <Box sx={fieldSx}>
          <SelectComponent
            name="apps"
            label="Module"
            options={appsOptions}
            value={formik.values.apps}
            compareValue={formik.values.apps}
            error={!!formik.errors.apps && formik.touched.apps}
            helperText={(formik.touched.apps && formik.errors.apps) as string}
            onChange={formik.handleChange}
          />
        </Box>

        <Box sx={fieldSx}>
          <SelectComponent
            name="devices"
            label="Module"
            options={devicesOptions}
            value={formik.values.devices}
            compareValue={formik.values.devices}
            error={!!formik.errors.devices && formik.touched.devices}
            helperText={(formik.touched.devices && formik.errors.devices) as string}
            onChange={formik.handleChange}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15, mt: spacing.m30 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={themeFonts.caption}>Subscription fee per user</Typography>
            <Typography sx={themeFonts.title4}>{formatMoney({ amount: costPerUser })}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={themeFonts.caption}>Active users</Typography>
            <Typography sx={themeFonts.title4}>{`${currentActiveUsers} user${
              currentActiveUsers === 1 ? '' : 's'
            }`}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={themeFonts.caption}>Total (excl. VAT)</Typography>
            <Typography sx={themeFonts.title4}>{formatMoney({ amount: currentActiveUsers * costPerUser })}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={themeFonts.caption}>VAT</Typography>
            <Typography sx={themeFonts.title4}>
              {formatMoney({ amount: currentActiveUsers * costPerUser * 0.2 })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={themeFonts.caption}>Total (incl. VAT)</Typography>
            <Typography sx={themeFonts.title4}>
              {formatMoney({ amount: currentActiveUsers * costPerUser * 1.2 })}
            </Typography>
          </Box>
        </Box>

        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mt: spacing.m20, mb: spacing.m20 }}>
          You will be redirected to make this payment for the remainder of the current pay period. Subscription will
          activate once the payment is complete.
        </Typography>

        <Box>
          <LoaderButton
            name="Save"
            loading={loading}
            disabled={!formik.isValid}
            sizeVariant="small"
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
