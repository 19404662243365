import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';

import { ReactComponent as Edit } from '@/images/side-bar-icons/Edit.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { Divider } from '@/v2/components/divider.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { styledChipSx } from '@/v2/styles/chip.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: 14, height: 14 };

export type ActionSelectionItemInfo = {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  required?: boolean;
};

export const ActionSelectionItemActionButton = ({ children, ...props }: IconButtonProps) => (
  <IconButton
    sx={{
      ...secondaryCTABtn,
      backgroundColor: '#ffffffc8',
      p: 0,
      height: '30px',
      width: '30px',
    }}
    {...props}
  >
    {children}
  </IconButton>
);

export const ActionSelectionItemAddButton = ({ ...props }: IconButtonProps) => (
  <ActionSelectionItemActionButton {...props}>
    <Plus {...iconSize} />
  </ActionSelectionItemActionButton>
);

export const ActionSelectionItemEditButton = ({ ...props }: IconButtonProps) => (
  <ActionSelectionItemActionButton {...props}>
    <Edit {...iconSize} />
  </ActionSelectionItemActionButton>
);

export const ActionSelectionItemDeleteButton = ({ ...props }: IconButtonProps) => (
  <ActionSelectionItemActionButton {...props}>
    <Trash {...iconSize} />
  </ActionSelectionItemActionButton>
);

export type ActionSelectionItemProps = {
  step: ActionSelectionItemInfo;
  onAddClick?: React.MouseEventHandler<HTMLButtonElement> | false | null;
  onDeleteClick?: React.MouseEventHandler<HTMLButtonElement> | false | null;
  addButtonIcon?: 'plus' | 'edit';
  chips?: React.ReactNode[];
  warning?: string;
  sx?: SxProps<Theme>;
};

export const ActionSelectionItem = ({
  chips,
  step,
  addButtonIcon = 'plus',
  onAddClick,
  onDeleteClick,
  sx,
  warning,
}: ActionSelectionItemProps) => {
  const { Icon, title, description, required } = step;
  const displayedChips = useMemo(() => chips?.filter(Boolean) ?? [], [chips]);

  return (
    <Stack sx={{ flexFlow: 'row', alignItems: 'flex-start', gap: spacing.g15, ...sx }}>
      {Icon && <Icon style={{ flexShrink: 0 }} />}
      <Stack>
        <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
          <Typography variant="title4">{title}</Typography>
          {required && (
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Required
            </Typography>
          )}
        </Stack>
        <Typography variant="caption">{description}</Typography>
        {displayedChips.length > 0 && (
          <Stack sx={{ flexFlow: 'row', flexWrap: 'wrap', alignItems: 'center', mt: spacing.mt10, gap: spacing.g5 }}>
            {displayedChips.map((content) => (
              <Box key={typeof content === 'string' ? content : null} sx={styledChipSx}>
                {content}
              </Box>
            ))}
          </Stack>
        )}
        {!!warning && (
          <Box sx={{ mt: spacing.mt10 }}>
            <Typography variant="caption" sx={{ color: themeColors.RedDark }}>
              {warning}
            </Typography>
          </Box>
        )}
      </Stack>
      <Stack sx={{ flexFlow: 'row', alignItems: 'center', ml: 'auto' }}>
        {onAddClick && (
          <ActionSelectionItemActionButton onClick={onAddClick}>
            {
              {
                edit: <Edit {...iconSize} />,
                plus: <Plus {...iconSize} />,
              }[addButtonIcon]
            }
          </ActionSelectionItemActionButton>
        )}
        {onDeleteClick && (
          <ActionSelectionItemActionButton onClick={onDeleteClick}>
            <Trash {...iconSize} />
          </ActionSelectionItemActionButton>
        )}
      </Stack>
    </Stack>
  );
};

type ActionSelectionGroupComponentProps = {
  title: string;
  actions: JSX.Element[];
  emptyActionsMessage?: string;
};

export const ActionSelectionGroupComponent = ({
  title,
  actions,
  emptyActionsMessage,
}: ActionSelectionGroupComponentProps) => {
  return (
    <Stack sx={{ maxWidth: '440px', flex: 1, ml: 'auto', overflowY: 'auto' }}>
      <Stack
        sx={{
          p: spacing.p30,
          mb: spacing.mb30,
          borderRadius: radius.br15,
          backgroundColor: themeColors.Background,
          height: 'fit-content',
        }}
      >
        <Typography variant="title2">{title}</Typography>
        <ActionSelectionItemList actions={actions} emptyActionsMessage={emptyActionsMessage} />
      </Stack>
    </Stack>
  );
};

type ActionSelectionItemListProps = {
  actions: JSX.Element[];
  emptyActionsMessage?: string;
};

export const ActionSelectionItemList = ({ actions, emptyActionsMessage }: ActionSelectionItemListProps) => {
  return (
    <Stack
      sx={{
        mt: spacing.mt10,
        overflow: 'auto',
        // this pr+mr combination creates a gap between the boxes and the scrollbar
        pr: '16px',
        mr: '-16px',
        '&::-webkit-scrollbar': { width: '3px' },
      }}
    >
      {actions.map((step, idx) => (
        <React.Fragment key={null}>
          {idx > 0 && <Divider />}
          {step}
        </React.Fragment>
      ))}
      {actions.length === 0 && emptyActionsMessage && <Typography variant="caption">{emptyActionsMessage}</Typography>}
    </Stack>
  );
};
