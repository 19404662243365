import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl, RouteScopesHas } from '@/component/widgets/Scopes';
import {
  REVIEWS_TEAM_ONGOING_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_ROUTE,
  REVIEWS_TEAM_ONGOING_SETUP_ROUTE,
  REVIEWS_TEAM_ROUTE,
} from '@/lib/routes';
import { ReviewCycleCreationTeamRouter } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cylce-creation-router/team/review-cycle-creation-team.router';
import { ReviewCycleDetailTeamRouter } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-router/team/review-cycle-detail-team.router';
import { ReviewCycleOngoingListingPage } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-ongoing/review-cycle-ongoing-listing/review-cycle-ongoing-listing.page';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';

export const ReviewsTeamRouter = () => {
  return (
    <Switch>
      <RouteScopesControl
        scopes={['reviews:manager']}
        path={REVIEWS_TEAM_ONGOING_SETUP_ROUTE}
        component={ReviewCycleCreationTeamRouter}
      />
      <RouteScopesControl
        scopes={['reviews:manager']}
        path={REVIEWS_TEAM_ONGOING_DETAIL_ROUTE}
        component={ReviewCycleDetailTeamRouter}
      />

      <RouteScopesHas scopes={['reviews:manager']} path={REVIEWS_TEAM_ONGOING_ROUTE} exact>
        <ReviewCycleOngoingListingPage reach={ReachType.Team} />
      </RouteScopesHas>

      <Redirect from={REVIEWS_TEAM_ROUTE} to={REVIEWS_TEAM_ONGOING_ROUTE} />
    </Switch>
  );
};
