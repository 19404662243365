import { useContext, useMemo } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl, RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { PAYMENT_COMPANY_ROUTE, PAYMENT_ME_ROUTE, PAYMENT_TEAM_ROUTE } from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';
import { SelectBankForm } from '@/v2/feature/payments/features/make-payment/components/select-bank-form-step.component';
import { CreatePaymentPage } from '@/v2/feature/payments/features/make-payment/pages/create-payment.page';
import { PaymentContextProvider } from '@/v2/feature/payments/features/make-payment/payment.context';
import { AuxPage } from '@/v2/feature/payments/pages/auxiliary.page';
import { PaymentsCompanyRouter } from '@/v2/feature/payments/payments.company.router';
import { PaymentsMeRouter } from '@/v2/feature/payments/payments.me.router';
import { PaymentsTeamRouter } from '@/v2/feature/payments/payments.team.router';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const PAYMENTS_ROUTE = '/money/company/payments';
export const PAYMENTS_SELECT_BANK_ROUTE = '/money/company/payments/overview/select-bank';
export const PAYMENTS_AUX_ROUTE = '/money/company/payments/overview/aux/:invoiceId';
export const PAYMENTS_CREATE_ROUTE = '/money/company/payments/overview/create';

export const PaymentsRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  const { user } = globalState;
  const _currentUserIsAdmin = hasScopes(['payments:all'], getScopesContext(user));

  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(globalState.user.userId), {
    suspense: false,
  });

  const isManager = useMemo(() => {
    const nonTerminatedDirectReports =
      userSummaryWithReports?.directReports?.filter(
        (d) => d.userEvent && d.userEvent.status && ['Employed', 'Hired'].includes(d.userEvent.status)
      )?.length ?? 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);

  return (
    <PaymentContextProvider payments={[]}>
      <Switch>
        <RouteScopesControl context={scopesContext} scopes={['payments:all']} path={PAYMENTS_AUX_ROUTE}>
          <AuxPage />
        </RouteScopesControl>

        <RouteScopesControl context={scopesContext} scopes={['payments:all']} path={PAYMENTS_SELECT_BANK_ROUTE}>
          <SelectBankForm />
        </RouteScopesControl>

        <RouteScopesControl context={scopesContext} scopes={['payments:all']} path={PAYMENTS_CREATE_ROUTE}>
          <CreatePaymentPage />
        </RouteScopesControl>

        <RouteScopesControl context={scopesContext} scopes={['payments:all']} path={PAYMENT_COMPANY_ROUTE}>
          <PaymentsCompanyRouter />
        </RouteScopesControl>

        <RouteScopesControl context={scopesContext} scopes={['payments']} path={PAYMENT_ME_ROUTE}>
          <PaymentsMeRouter />
        </RouteScopesControl>

        {isManager && (
          <RouteScopesHas scopes={['payments:manager']} path={PAYMENT_TEAM_ROUTE}>
            <PaymentsTeamRouter isManager={isManager} />
          </RouteScopesHas>
        )}

        {canAccessScopes(globalState.user, ['payments:all']) && <Redirect to={PAYMENT_COMPANY_ROUTE} />}
      </Switch>
    </PaymentContextProvider>
  );
};
