import axios from 'axios';

import {
  CreateRequestForm,
  CreateRequestFormTemplate,
  RequestForm,
  RequestFormStatus,
  RequestFormTemplate,
  RequestFormTemplateSummary,
} from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class RequestFormsAPI {
  static async canRequestBeCreatedOnPlan(): Promise<boolean> {
    return (await axios.get('/apiv2/requests/form/template/allowed')).data;
  }
  static async createFormTemplate(data: CreateRequestFormTemplate): Promise<RequestFormTemplate> {
    return (await axios.post('/apiv2/requests/form/template', data)).data;
  }

  static async updateFormTemplate(data: RequestFormTemplate): Promise<RequestFormTemplate> {
    return (await axios.patch('/apiv2/requests/form/template', data)).data;
  }

  static async deleteFormTemplate(id: number): Promise<void> {
    return axios.delete(`/apiv2/requests/form/template/${id}`);
  }

  static async submitForm(data: CreateRequestForm): Promise<RequestForm> {
    return (await axios.post('/apiv2/requests/form/submitted', data)).data;
  }

  static async setSubmittedFormStatus(
    id: number,
    data: {
      status: RequestFormStatus;
      rejectionNotes?: string;
    }
  ): Promise<void> {
    await axios.patch(`/apiv2/requests/form/submitted/${id}/status`, data);
  }

  static async deleteSubmittedForm(id: number): Promise<void> {
    return axios.delete(`/apiv2/requests/form/submitted/${id}`);
  }
}

export class RequestFormsEndpoints {
  static getFormTemplates(): Endpoint<RequestFormTemplate[]> {
    return {
      url: '/apiv2/requests/form/template',
    };
  }

  static getFormsAvailableForMe(): Endpoint<RequestFormTemplateSummary[]> {
    return {
      url: '/apiv2/requests/form/template/member/me',
    };
  }

  static getSubmittedById(id: number): Endpoint<RequestForm | null> {
    return {
      url: `/apiv2/requests/form/submitted/${id}`,
    };
  }
}
