import { ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from './forms/button.component';

import MaterialModal from '@/component/widgets/ModalV2';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

interface ConfirmModalProps {
  readonly title: string;
  readonly isOpen: boolean;
  readonly message: string | ReactNode;
  readonly onClose: () => void;
  readonly takeAction: () => void;
  readonly callToAction: string;
  readonly callToActionButtonType?: 'info' | 'error' | 'primary';
  readonly loading?: boolean;
  readonly type: 'info' | 'removal';
  readonly secondaryButton?: string;
  readonly isMainActionDisabled?: boolean;
}

export const ConfirmModal = ({
  title,
  isOpen,
  message,
  onClose,
  takeAction,
  callToAction,
  callToActionButtonType = 'error',
  secondaryButton,
  isMainActionDisabled = false,
  loading = false,
}: ConfirmModalProps) => {
  const { polyglot } = usePolyglot();

  useEscapeKey(() => onClose());

  return (
    <MaterialModal isOpen={isOpen} onClose={onClose}>
      <Stack sx={{ padding: spacing.p5 }}>
        <Stack sx={{ gap: spacing.g10 }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }} id="modal-headline">
            {title}
          </Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{message}</Typography>
        </Stack>
        <Stack sx={{ flexFlow: 'row', mt: spacing.m50, gap: spacing.g10, justifyContent: 'center' }}>
          <LoaderButton
            name={callToAction}
            loading={loading}
            onClick={() => takeAction()}
            disabled={isMainActionDisabled}
            sizeVariant="medium"
            colorVariant={callToActionButtonType === 'error' ? 'danger' : 'primary'}
          />
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => onClose()} disabled={loading}>
            {secondaryButton || polyglot.t('General.cancel')}
          </ButtonComponent>
        </Stack>
      </Stack>
    </MaterialModal>
  );
};
