import { Box, Stack, Typography } from '@mui/material';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { SecurityAuditReportTable } from './security-audit-report-table.component';

import { CompanyEndpoints } from '@/api-client/company.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SecurityAuditTrailReport = () => {
  const { data: auditTrailRows, isValidating: loadingAuditTrail } = useApiClient(
    CompanyEndpoints.getCompanyAuditTrail(),
    {
      suspense: false,
    }
  );
  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, pl: spacing.pad40, inset: 0, background: themeColors.white }}>
      <Stack sx={{ overflow: 'hidden auto', pb: spacing.p30 }}>
        <Stack sx={{ gap: spacing.g30 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'left',
            }}
          >
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Audit Trail</Typography>
          </Box>
          <Box>
            <SecurityAuditReportTable
              auditTrailRows={auditTrailRows}
              loadingAuditTrail={loadingAuditTrail}
              activityLog={false}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
