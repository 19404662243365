import React, { Suspense, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { OptionObject, SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CustomBenefitDto,
  UpdateCustomBenefitApprovalDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

const getValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    approvalRuleId: yup
      .number()
      .integer(polyglot.t('ValidationMessages.validValue'))
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
}

export const EditCustomBenefitApprovalDrawer = ({
  isOpen,
  setIsOpen,
  customBenefit,
  approvalRules,
  refresh,
  refreshApprovalRules,
}: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditCustomBenefitApprovalDrawerContent
        setIsOpen={setIsOpen}
        refresh={refresh}
        approvalRules={approvalRules}
        customBenefit={customBenefit}
        refreshApprovalRules={refreshApprovalRules}
      />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refresh: () => Promise<void>;
  readonly refreshApprovalRules: () => Promise<void>;
}

export const EditCustomBenefitApprovalDrawerContent = ({
  setIsOpen,
  refresh,
  approvalRules,
  customBenefit,
  refreshApprovalRules,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const approvalRuleOptions: OptionObject[] = useMemo(
    () => approvalRules.map((rule) => ({ value: rule.id, label: rule.name })),
    [approvalRules]
  );

  const formik = useFormik<UpdateCustomBenefitApprovalDto>({
    initialValues: {
      approvalRuleId: customBenefit.approvalRuleId,
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const update: UpdateCustomBenefitApprovalDto = {
          approvalRuleId: values.approvalRuleId,
        };
        await CustomBenefitAPI.updateCustomBenefitApproval(customBenefit.id, update);
        showMessage(polyglot.t('BenefitModule.benefitUpdated'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.editBenefit')}</Typography>

        <SelectComponent
          name="approvalRuleId"
          label={polyglot.t('BenefitModule.approvalRule')}
          options={approvalRuleOptions}
          value={formik.values.approvalRuleId}
          onChange={formik.handleChange}
          compareValue={formik.values.approvalRuleId}
          error={!!formik.errors.approvalRuleId && formik.touched.approvalRuleId}
          helperText={formik.touched.approvalRuleId && (formik.errors.approvalRuleId as string)}
          // editList={{ // TODO: edited rules not supported yet - uncomment once supported
          //   isHidden: !hasScopes(['company.settings:all']),
          //   handler: () => {
          //     setIsApprovalDrawerOpen(true);
          //   },
          // }}
        />

        <ApprovalRuleDrawer
          isOpen={isApprovalDrawerOpen}
          setIsOpen={setIsApprovalDrawerOpen}
          approvalRule={null}
          afterClose={() => {
            setIsApprovalDrawerOpen(false);
          }}
          refresh={async () => {
            if (refreshApprovalRules) await refreshApprovalRules();
          }}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
