import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { CompanyPayrollType } from '@v2/feature/payroll/payroll.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { generatePath, useHistory } from 'react-router-dom';

import { CompanyEndpoints } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  PAYROLL_COMPANY_NEW_PAYROLL_ROUTE,
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE,
} from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledRadioGroup } from '@/v2/components/theme-components/styled-radio-group.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { PayrollContext } from '@/v2/feature/payroll/payroll-company.router';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { isUKCountryCode } from '@/v2/infrastructure/country/country.util';
import { SelectOption } from '@/v2/infrastructure/forms/forms.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort } from '@/v2/util/array.util';

type EntitySelectionViewProps = {
  creatingPayroll?: boolean;
  companyEntities: CompanyUnitDto[];
  onEntitySelected: (entityId: number) => void;
};

const EntitySelectionView = ({ companyEntities, creatingPayroll, onEntitySelected }: EntitySelectionViewProps) => {
  const { polyglot } = usePolyglot();
  const [selectedEntityId, setSelectedEntityId] = useState(0);
  const routerHistory = useHistory();

  const { data: companyPayrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });

  const entityOptions = useMemo(() => {
    if (!companyEntities || !companyPayrolls) return null;
    return [...companyEntities]
      .sort((a, b) => caseInsensitiveSort(a, b, (item) => item.legalName))
      .map<SelectOption>((e) => ({
        label: e.legalName,
        value: e.id,
        disabled: companyPayrolls.items.some((p) => p.entity?.id === e.id),
      }));
  }, [companyEntities, companyPayrolls]);

  const goToCompanyEntities = useCallback(() => {
    routerHistory.push(SETTINGS_COMPANY_ROUTE);
  }, [routerHistory]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('CompanyPayroll.setupPayroll')}</Typography>

      {companyEntities.length > 0 && (
        <Typography variant="caption">{polyglot.t('CompanyPayroll.whichEntityForPayroll')}</Typography>
      )}

      {companyEntities.length > 0 && (
        <StyledRadioGroup
          name="entity"
          options={entityOptions ?? []}
          selectedValue={selectedEntityId}
          labelPlacement="end"
          onChange={(e) => setSelectedEntityId(Number(e.target.value))}
          disabled={creatingPayroll}
          radioSx={{
            borderBottom: 0,
            justifyContent: 'normal',
            paddingY: 0,
          }}
          sx={{ mb: spacing.mb10 }}
        />
      )}

      {companyEntities.length === 0 && (
        <Typography variant="caption">{polyglot.t('CompanyPayroll.noEntities')}</Typography>
      )}

      {companyEntities.length > 0 ? (
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            disabled={!selectedEntityId}
            loading={creatingPayroll}
            onClick={async () => {
              onEntitySelected(selectedEntityId);
            }}
          >
            {polyglot.t('General.continue')}
          </ButtonComponent>
        </Box>
      ) : (
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => goToCompanyEntities()}>
          {polyglot.t('PayrollCompanyCreate.createNewEntity')}
        </ButtonComponent>
      )}
    </Box>
  );
};

type ReportPayrollToHMRCViewProps = {
  creatingPayroll?: boolean;
  onHMRCFilingSelected: (isZeltFiling: boolean) => void;
};

const ReportPayrollToHMRCView = ({ creatingPayroll, onHMRCFilingSelected }: ReportPayrollToHMRCViewProps) => {
  const { polyglot } = usePolyglot();
  const [filingMode, setFilingMode] = useState('');

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('CompanyPayroll.shouldReportPayrollToHMRC')}</Typography>
      <StyledRadioGroup
        options={[
          {
            label: polyglot.t('CompanyPayroll.reportPayrollToHMRCNo'),
            value: 'external-hmrc-filing',
          },
          {
            label: polyglot.t('CompanyPayroll.reportPayrollToHMRCYes'),
            value: 'zelt-hmrc-filing',
          },
        ]}
        selectedValue={filingMode}
        labelPlacement="end"
        onChange={(e) => setFilingMode(e.target.value)}
        disabled={creatingPayroll}
        radioSx={{
          borderBottom: 0,
          justifyContent: 'normal',
          paddingY: 0,
        }}
      />

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
          disabled={!filingMode}
          loading={creatingPayroll}
          onClick={() => {
            onHMRCFilingSelected(filingMode === 'zelt-hmrc-filing');
          }}
        >
          {polyglot.t('General.continue')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export const SetupPayrollDrawer = () => {
  const { polyglot } = usePolyglot();
  const selectedEntityId = useRef(0);
  const [stage, setStage] = useState<'entity-selection' | 'hmrc-filing'>('entity-selection');
  const [creatingPayroll, setCreatingPayroll] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const { refreshPayrollState } = useContext(PayrollContext);

  const { data: companyEntities } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });

  const createPayroll = useCallback(
    async (entityId: number, payrollType: CompanyPayrollType) => {
      try {
        setCreatingPayroll(true);
        const newPayroll = await PayrollExternalApi.createCompanyPayroll(entityId, payrollType);
        await refreshPayrollState();
        return newPayroll;
      } catch (error) {
        showMessage(
          polyglot.t('PayrollCompanyCreate.payrollCouldNotBeCreated', { reason: nestErrorMessage(error) }),
          'error'
        );
        return null;
      } finally {
        setCreatingPayroll(false);
      }
    },
    [refreshPayrollState, showMessage, polyglot]
  );

  const createUKPayroll = useCallback(
    async (entityId: number) => {
      const newPayroll = await createPayroll(entityId, CompanyPayrollType.UKPayroll);
      if (newPayroll) {
        // once we've created the UK payroll, navigate to the HMRC setup page
        routerHistory.push(generatePath(PAYROLL_COMPANY_NEW_PAYROLL_ROUTE, { payrollId: newPayroll.id }));
      }
    },
    [createPayroll, routerHistory]
  );

  const createGlobalPayroll = useCallback(
    async (entityId: number) => {
      const newPayroll = await createPayroll(entityId, CompanyPayrollType.GlobalPayroll);
      if (newPayroll) {
        // once we've created the Global payroll, navigate to the settings page
        routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId: newPayroll.id }));
      }
    },
    [createPayroll, routerHistory]
  );

  return (
    <Stack sx={{ width: 340, p: '20px 30px', ...drawerContentSx }}>
      {stage === 'entity-selection' && companyEntities && (
        <EntitySelectionView
          companyEntities={companyEntities}
          creatingPayroll={creatingPayroll}
          onEntitySelected={(entityId) => {
            const entity = companyEntities.find((e) => e.id === entityId);
            if (!entity?.address) return;
            selectedEntityId.current = entityId;
            if (isUKCountryCode(entity.address.countryCode)) {
              setStage('hmrc-filing');
              return;
            }
            createGlobalPayroll(selectedEntityId.current);
          }}
        />
      )}
      {stage === 'hmrc-filing' && (
        <ReportPayrollToHMRCView
          creatingPayroll={creatingPayroll}
          onHMRCFilingSelected={(isZeltFilingToHMRC) => {
            if (isZeltFilingToHMRC) {
              createUKPayroll(selectedEntityId.current);
              return;
            }
            createGlobalPayroll(selectedEntityId.current);
          }}
        />
      )}
    </Stack>
  );
};
