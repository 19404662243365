import { useCallback, useMemo } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { CompanyDepartmentWithMemberDetailsDto } from '@/models/company-department.model';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface DepartmentListingTableProps {
  readonly departments: CompanyDepartmentWithMemberDetailsDto[];
  readonly loading: boolean;
  readonly setIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setSelectedDepartment: React.Dispatch<
    React.SetStateAction<CompanyDepartmentWithMemberDetailsDto | undefined>
  >;
}

export const DepartmentListingTable = ({
  departments,
  loading,
  setIsViewOpen,
  setIsFormOpen,
  setSelectedDepartment,
}: DepartmentListingTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const tableColumns = useMemo<
    ColumnDef<CompanyDepartmentWithMemberDetailsDto, CompanyDepartmentWithMemberDetailsDto>[]
  >(() => {
    return [
      {
        header: () => polyglot.t('DepartmentListingTable.departmentNameLabel'),
        accessorFn: (row) => row,
        id: 'displayName',
        maxSize: 200,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return original && <div>{original.name}</div>;
        },
      },
      {
        header: () => polyglot.t('DepartmentListingTable.employees'),
        accessorFn: (row) => row,
        id: 'employees',
        maxSize: 350,
        minSize: 100,
        enableSorting: false,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => Number(item.users?.length)),
        cell: ({ row: { original } }) => <div>{original.users ? <>{original.users.length}</> : <EmptyCell />}</div>,
      },
      {
        header: () => '',
        id: 'action',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: spacing.g5 }}>
            <IconButton
              title="edit"
              onClick={(event) => {
                setSelectedDepartment(original);
                setIsFormOpen(true);
                event.stopPropagation();
              }}
              sx={tableIconButtonSx}
            >
              <Edit {...iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ];
  }, [setSelectedDepartment, setIsFormOpen, polyglot]);

  const handleRowClick = useCallback(
    (row: Row<CompanyDepartmentWithMemberDetailsDto>) => {
      setSelectedDepartment(row.original);
      setIsViewOpen(true);
    },
    [setSelectedDepartment, setIsViewOpen]
  );

  return (
    <BasicTable<CompanyDepartmentWithMemberDetailsDto>
      rowData={departments}
      columnData={tableColumns}
      loading={loading}
      initialSort={[{ id: 'displayName', desc: true }]}
      rowClick={handleRowClick}
    />
  );
};
