import { useCallback } from 'react';

import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownArrow } from '@/images/fields/DownArrow.svg';
import { ReactComponent as UpArrow } from '@/images/fields/UpArrow.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowLeft } from '@/images/side-bar-icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '@/images/side-bar-icons/ArrowRight.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { OptionProps } from '@/v2/components/theme-components/styled-menu.component';
import { SurveyQuestionBankAPI } from '@/v2/feature/growth/surveys/api-client/survey-question-bank.api';
import { SurveyTemplateAPI } from '@/v2/feature/growth/surveys/api-client/survey-template.api';
import { SurveyQuestionBank } from '@/v2/feature/growth/surveys/interfaces/survey-question-bank.interface';
import { SurveyTemplate, SurveyTemplateById } from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { iconSize } from '@/v2/styles/menu.styles';

export const useSurveyTemplateQuestionOptions = (
  question: SurveyQuestionBank,
  setQuestion: React.Dispatch<React.SetStateAction<SurveyQuestionBank | null>>,
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>,
  template: SurveyTemplate,
  sectionId: string | null,
  refreshTemplate: KeyedMutator<SurveyTemplateById> | undefined,
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>,
  hasSections: boolean
): readonly OptionProps[] => {
  const [showMessage] = useMessage();
  const { id: templateId } = template;

  const editHandler = useCallback(() => {
    setQuestion(question);
    setIsQuestionModalOpen(true);
  }, [question, setQuestion, setIsQuestionModalOpen]);

  const deleteHandler = useCallback(async () => {
    try {
      await SurveyQuestionBankAPI.removeQuestionFromTemplate({
        questionId: question.id,
        sectionId: sectionId,
        templateId,
      });
      showMessage('Successfully deleted', 'success');
      await refreshTemplate?.();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    }
  }, [question.id, sectionId, templateId, showMessage, refreshTemplate]);

  const currentOrder = template.order ? [...template.order] : [];
  const findQuestionIndex = () => currentOrder.findIndex((o) => o.type === 'question' && o.id === question.id);

  const isQuestionFirst = () => findQuestionIndex() === 0;
  const isQuestionLast = () => findQuestionIndex() === currentOrder.length - 1;

  const moveSection = async (direction: 'up' | 'down') => {
    const sectionIndex = findQuestionIndex();
    if (direction === 'up' && sectionIndex > 0) {
      [currentOrder[sectionIndex - 1], currentOrder[sectionIndex]] = [
        currentOrder[sectionIndex],
        currentOrder[sectionIndex - 1],
      ];
    } else if (direction === 'down' && sectionIndex < currentOrder.length - 1) {
      [currentOrder[sectionIndex], currentOrder[sectionIndex + 1]] = [
        currentOrder[sectionIndex + 1],
        currentOrder[sectionIndex],
      ];
    }
    await SurveyTemplateAPI.updateOrderSurveyTemplate({ templateId, order: currentOrder });
    await refreshTemplate?.();
  };

  return [
    ...(question.companyId
      ? [{ icon: <Edit {...iconSize} />, handler: editHandler, label: 'Edit', disabled: false }]
      : []),
    { icon: <Trash {...iconSize} />, handler: deleteHandler, label: 'Delete', disabled: false },
    ...(!sectionId
      ? [
          {
            icon: <UpArrow {...iconSize} />,
            handler: async () => {
              try {
                if (!template || !template.order || isQuestionFirst()) return;
                await moveSection('up');
                showMessage('Successfully moved the question up', 'success');
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move up',
            disabled: isQuestionFirst(),
          },
          {
            icon: <DownArrow {...iconSize} />,
            handler: async () => {
              try {
                if (!template || !template.order || isQuestionLast()) return;
                await moveSection('down');
                showMessage('Successfully moved the question down', 'success');
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move down',
            disabled: isQuestionLast(),
          },
          {
            icon: <ArrowRight {...iconSize} />,
            handler: async () => {
              try {
                setIsMoveToSectionOpen(true);
                setSelectedQuestionId(question.id);
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move inside section',
            disabled: !hasSections,
          },
        ]
      : [
          {
            icon: <ArrowLeft {...iconSize} />,
            handler: async () => {
              try {
                if (!template || !template.order) return;
                await SurveyTemplateAPI.moveQuestionOutsideSection({
                  templateId,
                  sectionId,
                  questionId: question.id,
                });
                await refreshTemplate?.();

                showMessage('Successfully moved the outside the section', 'success');
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move outside section',
            disabled: false,
          },
        ]),
  ];
};
