import {
  REVIEWS_COMPANY_ONGOING_ROUTE,
  REVIEWS_ME_INVITE_ROUTE,
  REVIEWS_ME_RESULT_ROUTE,
  REVIEWS_ME_ROUTE,
  REVIEWS_TEAM_ONGOING_ROUTE,
} from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const GROWTH_REVIEWS_ONGOING_ROUTES: RoleRoutes = {
  company: REVIEWS_COMPANY_ONGOING_ROUTE,
  team: REVIEWS_TEAM_ONGOING_ROUTE,
};

export const GROWTH_REVIEWS_ME_ROUTES: RoleRoutes = {
  me: REVIEWS_ME_ROUTE,
};

export const GROWTH_REVIEWS_INVITE_ROUTES: RoleRoutes = {
  me: REVIEWS_ME_INVITE_ROUTE,
};

export const GROWTH_REVIEWS_INVITE_SCOPES: RoleScopes = {
  me: ['reviews'],
};

export const GROWTH_REVIEWS_RESULT_ROUTES: RoleRoutes = {
  me: REVIEWS_ME_RESULT_ROUTE,
};

export const GROWTH_REVIEWS_RESULT_SCOPES: RoleScopes = {
  me: ['reviews'],
};

export const GROWTH_REVIEWS_ONGOING_SCOPES: RoleScopes = {
  company: ['reviews:all'] as Scope[],
  team: ['reviews:manager'],
  me: ['reviews'],
};
