import React, { useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { UserAvatar, UserAvatarSize } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MultiUserAvatar = ({
  userIds,
  showLimit = 4,
  textVariant = 'caption',
  textColor = 'DarkGrey',
  avatarSize = 'xxsmall',
}: {
  userIds: number[];
  showLimit?: number;
  textVariant?: TypographyVariant;
  textColor?: keyof typeof themeColors;
  avatarSize?: UserAvatarSize;
}) => {
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const avatars = useMemo(() => {
    const avatars: React.JSX.Element[] = [];
    for (let index = 0; index < showLimit; index += 1) {
      const userId = userIds[index];
      avatars.push(<UserAvatar key={userId} userId={userId} size={avatarSize} showName={false} />);
    }
    return avatars;
  }, [avatarSize, showLimit, userIds]);

  const remainingUsers = useMemo(() => {
    const names: string[] = [];
    for (let index = showLimit; index < userIds.length; index += 1) {
      const user = getCachedUserById(userIds[index]);
      if (user) names.push(polyglot.t(user.displayName ?? `${user.firstName} ${user.lastName}`));
    }

    return names;
  }, [polyglot, getCachedUserById, userIds, showLimit]);

  return (
    <div style={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
      {avatars}
      {remainingUsers.length > 0 && (
        <StyledTooltip
          title={
            <Stack gap="4px">
              {remainingUsers.map((userName) => (
                <Typography variant="captionSmall" color="GreyLight" key={userName}>
                  {userName}
                </Typography>
              ))}
            </Stack>
          }
          placement="top"
        >
          <Box>
            <Typography variant={textVariant} color={textColor}>{` +${remainingUsers.length}`}</Typography>
          </Box>
        </StyledTooltip>
      )}
    </div>
  );
};
