import { useContext } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';

import { EventsAPI } from '@/api-client/events.api';
import { UserEventDto } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { UserEventSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserLifecycleStatuses } from '@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  getAllChangeTypeOptions,
  getAllDefaultTerminationChangeReasonOptions,
  getAllEmployedOptions,
  getAllLeaveOptions,
  getOtherOption,
  getRehiredOption,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { LifecycleFormState } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { LocalDate } from '@/v2/util/local-date';

interface Props {
  readonly initialValues?: UserEventDto;
  readonly allEntries?: readonly UserEventDto[];
  readonly userId: number;
  readonly loadInitialData: () => Promise<void>;
  readonly refreshLifecycle: () => Promise<void>;
  readonly rowModalMode: string;
  readonly handleSubmit: (userId: number) => void;
  readonly setFormCurrentlyEditing: (beingEdited: boolean) => void;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly loading: boolean;
  readonly onClose: () => void;
  readonly formState: LifecycleFormState | null;
}

const eventStatusOptions: OptionObject[] = [
  { value: 'Employed', label: 'Employed' },
  { value: 'Leave', label: 'Leave' },
  { value: 'Terminated', label: 'Terminated' },
];

export const UserProfileLifecycleForm = ({
  initialValues,
  allEntries,
  userId,
  loadInitialData,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  refreshLifecycle,
  formState,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const EFFECTIVE_END_DATE_DISABLE_FOR_STATUSES = ['Employed', 'Terminated'];
  const HIDE_STATUS_FOR_FORM_STATE = ['Terminate', 'Leave'];
  const STANDARD_SAVE_CANCEL_BUTTONS = ['Rehire', 'Leave'];
  const DISABLED_CHANGE_TYPES = ['Imported', 'imported'];

  const [showMessage] = useMessage();
  const defaultBlankModalValues: UserEventDto = {
    id: 0,
    userId: 0,
    companyId: 0,
    createdBy: 0,
    updatedBy: 0,
    effectiveDate: new LocalDate().toDateString(),
    status: undefined,
    changeReason: '',
    note: null,
    effectiveEndDate: null,
  };

  const getFormTitle = () => {
    if (formState === 'Terminate') return formState;
    if (formState === 'Leave') return polyglot.t('UserProfileLifecycleForm.addLeave');
    if (formState === 'Rehire') return polyglot.t('UserProfileLifecycleForm.rehire');
    return 'Add lifecycle';
  };

  const getEffectiveDateLabel = () => {
    if (formState === 'Terminate') return polyglot.t('UserProfileLifecycleForm.terminationDate');
    if (formState === 'Leave' || formState === 'Rehire') return polyglot.t('UserProfileLifecycleForm.startDate');
    return polyglot.t('UserProfileLifecycleForm.takesEffectOn');
  };

  const getReasonBasedOnFormState = () => {
    if (formState === 'Terminate' || (initialValues && initialValues.status === 'Terminated'))
      return getAllDefaultTerminationChangeReasonOptions(polyglot);
    if (formState === 'Leave') return [...getAllLeaveOptions(polyglot), getOtherOption(polyglot)];
    if (formState === 'Rehire') return [getRehiredOption(polyglot)];
    if (initialValues && initialValues.status === 'Employed') return getAllEmployedOptions(polyglot);
    return getAllChangeTypeOptions(polyglot);
  };

  const getSuccessMessageBasedOnFormState = () => {
    if (formState === 'Terminate') return polyglot.t('UserProfileLifecycleForm.successMessages.terminate');
    if (formState === 'Leave') return polyglot.t('UserProfileLifecycleForm.successMessages.addLeave');
    if (formState === 'Rehire') return polyglot.t('UserProfileLifecycleForm.successMessages.rehire');
    return rowModalMode === 'add'
      ? polyglot.t('UserProfileLifecycleForm.successMessages.add')
      : polyglot.t('UserProfileLifecycleForm.successMessages.update');
  };

  const getEmployedEventEffectiveDate = (): string | undefined => {
    return allEntries?.find((eachEvent) => eachEvent.status === UserLifecycleStatuses.Employed)?.effectiveDate;
  };

  const getTerminatedEventEffectiveDate = (): string | undefined => {
    return allEntries?.find((eachEvent) => eachEvent.status === UserLifecycleStatuses.Terminated)?.effectiveDate;
  };

  const oneDayBeforeTerminatedEvent = (): Date | undefined => {
    if (formState === 'Rehire') return undefined;
    let terminatedEffectiveDate = getTerminatedEventEffectiveDate();
    if (!terminatedEffectiveDate) return undefined;
    const terminatedEffectiveDateObj = new LocalDate(terminatedEffectiveDate).getDate();
    return new Date(terminatedEffectiveDateObj.setDate(terminatedEffectiveDateObj.getDate() - 1));
  };

  const oneDayAfterEmployedEvent = (): Date | undefined => {
    const employedEffectiveDate = getEmployedEventEffectiveDate();
    if (!employedEffectiveDate) return undefined;

    const employedEffectiveDateObj = new LocalDate(employedEffectiveDate).getDate();
    return new Date(employedEffectiveDateObj.setDate(employedEffectiveDateObj.getDate() + 1));
  };

  const formik = useFormik({
    initialValues: initialValues ?? defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: UserEventSchema,
    onSubmit: async (values: UserEventDto) => {
      try {
        setLoading(true);
        const modalUserEventBody = {
          ...values,
          userId: userId,
          createdBy: state.user.userId,
          updatedBy: state.user.userId,
          companyId: state.user.company.companyId,
        };

        if (rowModalMode === 'add') {
          await EventsAPI.createUserEventEntry(userId, modalUserEventBody);
        } else if (rowModalMode === 'edit') {
          await EventsAPI.updateUserEventEntry(userId, modalUserEventBody);
          if (formState === 'Rehire') await EventsAPI.additionalStepsForRehire(userId);
        }
        showMessage(getSuccessMessageBasedOnFormState(), 'success');
        await loadInitialData();
        formik.resetForm();
        setIsModalOpen(false);
        await refreshLifecycle();
      } catch (error) {
        showMessage(
          `${polyglot.t('UserProfileLifecycleForm.errorMessages.encounter')}: ${nestErrorMessage(error)}; ${polyglot.t(
            'UserProfileLifecycleForm.errorMessages.pleaseTryAgain'
          )}`,
          'error'
        );
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {rowModalMode === 'add' ? (
          <Typography variant="title2">{getFormTitle()}</Typography>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{formState ? getFormTitle() : 'Edit lifecycle'}</Typography>
          </Box>
        )}

        {formState && !HIDE_STATUS_FOR_FORM_STATE.includes(formState) && (
          <SelectComponent
            name="status"
            label={polyglot.t('UserProfileLifecycleForm.status')}
            disabled={formState === 'Rehire' || !formState}
            options={eventStatusOptions}
            value={formik.values.status}
            compareValue={formik.values.status}
            error={!!formik.errors.status && formik.touched.status}
            onChange={formik.handleChange}
            helperText={formik.errors.status && formik.touched.status}
          />
        )}

        <DatePickerComponent
          inputFormat="DD/MM/YYYY"
          value={formik.values.effectiveDate ?? null}
          onChange={(value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue('effectiveDate', value);
            }
          }}
          name="effectiveDate"
          label={getEffectiveDateLabel()}
          error={!!formik.errors.effectiveDate && formik.touched.effectiveDate}
          helperText={formik.errors.effectiveDate && formik.touched.effectiveDate}
          minDate={
            initialValues && initialValues.status === UserLifecycleStatuses.Terminated
              ? oneDayAfterEmployedEvent()
              : undefined
          }
          maxDate={
            initialValues && initialValues.status === UserLifecycleStatuses.Employed
              ? oneDayBeforeTerminatedEvent()
              : undefined
          }
        />

        {formik.values.status && !EFFECTIVE_END_DATE_DISABLE_FOR_STATUSES.includes(formik.values.status) && (
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.effectiveEndDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('effectiveEndDate', value);
              }
            }}
            name="effectiveEndDate"
            label={polyglot.t('UserProfileLifecycleForm.effectiveEndDate')}
            error={!!formik.errors.effectiveEndDate && formik.touched.effectiveEndDate}
            helperText={formik.errors.effectiveEndDate && formik.touched.effectiveEndDate}
          />
        )}

        <SelectComponent
          name="changeReason"
          label={polyglot.t('UserProfileLifecycleForm.changeType')}
          options={
            formState || (initialValues && initialValues.status)
              ? getReasonBasedOnFormState()
              : getAllChangeTypeOptions(polyglot)
          }
          value={formik.values.changeReason}
          compareValue={formik.values.changeReason}
          error={!!formik.errors.changeReason && formik.touched.changeReason}
          onChange={formik.handleChange}
          helperText={formik.errors.changeReason && formik.touched.changeReason}
          disabled={DISABLED_CHANGE_TYPES.includes(formik.values.changeReason)}
        />

        {formState === 'Terminate' && (
          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name={polyglot.t('UserProfileLifecycleForm.saveClose')}
              loading={loading}
              fullWidth
              sizeVariant="medium"
              colorVariant="primary"
              disabled={!formik.values.status || !formik.values.effectiveDate || !formik.values.changeReason}
            />

            <ButtonComponent
              fullWidth
              sizeVariant="medium"
              colorVariant="primary"
              disabled={!formik.values.status || !formik.values.effectiveDate || !formik.values.changeReason}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {polyglot.t('UserProfileLifecycleForm.offboard')}
            </ButtonComponent>
          </Box>
        )}

        {((formState && STANDARD_SAVE_CANCEL_BUTTONS.includes(formState)) || !formState) && (
          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
              {polyglot.t('General.cancel')}
            </ButtonComponent>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
              disabled={!formik.values.status || !formik.values.effectiveDate || !formik.values.changeReason}
            />
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};
