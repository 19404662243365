import { useCallback, useEffect, useState } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { UnfinishedAttendanceShiftDrawer } from '@/v2/feature/attendance/components/unfinished-attendance-shift-drawer.component';
import { AttendanceShiftAPI } from '@/v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api';
import { AttendanceShiftDto } from '@/v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.dto';
import { UnfinishedAttendanceShiftsTodos } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const ShiftActionItem = ({
  shiftActionRow,
  refresh,
  afterClose,
}: {
  shiftActionRow: UnfinishedAttendanceShiftsTodos;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<AttendanceShiftDto | null>(null);
  const [showMessage] = useMessage();

  const getShiftById = useCallback(async () => {
    try {
      const shift = await AttendanceShiftAPI.getShiftById(Number(shiftActionRow.id), Number(shiftActionRow.userId));
      setSelectedShift(shift);
      setIsOpen(true);
    } catch (error) {
      console.error(error);
      showMessage(
        polyglot.t('ShiftActionItem.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
      setSelectedShift(null);
    }
  }, [shiftActionRow.id, shiftActionRow.userId, showMessage, polyglot]);

  useEffect(() => {
    getShiftById();
  }, [getShiftById]);

  return (
    <div>
      {selectedShift && (
        <UnfinishedAttendanceShiftDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          shift={selectedShift}
          afterClose={() => {
            if (afterClose) afterClose();
            setSelectedShift(null);
          }}
          refresh={refresh}
        />
      )}
    </div>
  );
};
