import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';

import { BillingInvoiceAPI } from '@/api-client/billing-invoice.api';
import { BillingTimelineAPI } from '@/api-client/billing-timeline';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as Checked } from '@/images/side-bar-icons/Ok.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BillingTimeline, BillingTimelineTypes } from '@/models/billing.model';
import {
  BillingCurrency,
  CompanySubscriptionDetails,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

const getLogType = (log: BillingTimeline): string => {
  switch (log.type) {
    case BillingTimelineTypes.PaymentMethod:
      return 'Payment method added';
    case BillingTimelineTypes.Subscription:
      if (log.metadata?.status === 'paid')
        return 'Subscription upgrade' + (log.metadata.planName ? `: ${log.metadata.planName ?? ''}` : '');
      if (log.metadata?.status === 'subscription-cancelled')
        return 'Subscription cancelled' + (log.metadata.planName ? `: ${log.metadata.planName ?? ''}` : '');
      return 'Subscription update';
    case BillingTimelineTypes.Payment:
      if (log.metadata?.status === 'payment-created') return 'Payment created successfully';
      return 'Invoice';
  }
};

const getLogStatus = (log: BillingTimeline): ReactNode => {
  if (![BillingTimelineTypes.Payment, BillingTimelineTypes.Subscription].includes(log.type)) return <EmptyCell />;

  if (log.metadata?.status === 'paid')
    return (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <Checked {...iconSize} style={{ fill: themeColors.Green }} />
        <Typography sx={themeFonts.caption}>Paid</Typography>
      </Box>
    );
  if (log.metadata?.status === 'error')
    return (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <Rejected {...iconSize} fill={themeColors.Red} />
        <Typography sx={themeFonts.caption}>Declined</Typography>
      </Box>
    );

  return <EmptyCell />;
};

interface Props {
  subscriptionDetailsForCompany: CompanySubscriptionDetails | null;
}

export const ActivityLog = ({ subscriptionDetailsForCompany }: Props) => {
  const [billingTimelineLogs, setBillingTimelineLogs] = useState<BillingTimeline[]>([]);
  const [showMessage] = useMessage();
  const currency = subscriptionDetailsForCompany?.companySubscription?.currency ?? BillingCurrency.GBP;

  const fetchActivityLogs = useCallback(async () => {
    try {
      const logs = await BillingTimelineAPI.getBillingTimeline();
      setBillingTimelineLogs(
        logs.filter((log) => log.metadata?.status !== 'payment-created' && log.metadata?.invoiceId)
      );
    } catch (error) {
      showMessage(`Could not fetch activity logs. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  useEffect(() => {
    fetchActivityLogs();
  }, [fetchActivityLogs]);

  const handleDownloadClick = useCallback(
    async (invoiceId: string) => {
      try {
        const PDFBlob = await BillingInvoiceAPI.downloadInvoice(invoiceId);

        const file = new Blob([PDFBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.download = `${invoiceId}-${new Date()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (error) {
        console.error('::Download error', error);
        showMessage(`Failed to download invoice. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const columns = useMemo<ColumnDef<BillingTimeline, BillingTimeline>[]>(
    () => [
      {
        header: () => 'Type',
        accessorFn: (row: BillingTimeline) => row,
        id: 'type',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<BillingTimeline> }) => {
          return <Typography sx={themeFonts.caption}>{getLogType(original)}</Typography>;
        },
      },
      {
        header: () => 'Date',
        accessorFn: (row: BillingTimeline) => row,
        id: 'createdAt',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<BillingTimeline> }) => {
          return <Typography sx={themeFonts.caption}>{new Date(original.createdAt).toLocaleString()}</Typography>;
        },
      },
      {
        header: () => 'Amount',
        accessorFn: (row: BillingTimeline) => row,
        id: 'amount',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<BillingTimeline> }) => {
          return original.metadata?.amount ? (
            <Typography sx={themeFonts.caption}>
              {formatMoney({
                locale: 'en-US',
                amount: original.metadata.amount,
                currency: original.metadata?.currency ?? currency,
              })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Status',
        accessorFn: (row: BillingTimeline) => row,
        id: 'status',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<BillingTimeline> }) => {
          return <Typography sx={themeFonts.caption}>{getLogStatus(original)}</Typography>;
        },
      },
      {
        header: () => 'Invoice number',
        accessorFn: (row: BillingTimeline) => row,
        id: 'invoiceNumber',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<BillingTimeline> }) => {
          return original.metadata?.invoiceId ? (
            <Typography sx={themeFonts.caption}>{original.metadata?.invoiceId}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        id: 'action-buttons',
        header: () => '',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 40,
        cell: ({ row: { original } }) => {
          return (
            <Stack flexDirection="row" justifyContent="flex-end" gap="5px" whiteSpace="nowrap">
              {original.metadata?.invoiceId && (
                <IconButton
                  sx={tableIconButtonSx}
                  onClick={() =>
                    original.metadata?.invoiceId ? handleDownloadClick(original.metadata.invoiceId!) : undefined
                  }
                  title="Download"
                >
                  <DownloadIcon {...iconSize} />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
    ],
    [currency, handleDownloadClick]
  );

  return billingTimelineLogs.length > 0 ? (
    <>
      <Box sx={{ ...spacing.px40, mt: spacing.m20 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Recent Activity</Typography>
      </Box>

      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable rowData={[...billingTimelineLogs]} columnData={columns} />
      </Box>
    </>
  ) : null;
};
