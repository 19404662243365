import React, { useState } from 'react';

import { Box, Modal } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const ModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  borderRadius: '20px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  pt: 4,
  pb: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

interface DeleteEmployeePensionModalProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userId: number;
  readonly refresh: () => Promise<void>;
}

// TODO: @polyglot-later-staffology-pension
export const DeleteEmployeePensionModal = ({ isOpen, setIsOpen, userId, refresh }: DeleteEmployeePensionModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage] = useMessage();

  const deletePension = async (): Promise<void> => {
    try {
      await PensionAPI.deleteEmployeePension(userId);
      setIsLoading(true);
      showMessage('Pension record successfully removed.', 'success');
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={ModalStyle}>
        <Typography variant="title2">Delete employee pension</Typography>

        <Typography variant="caption">
          This option should only be used when an employee has been added to a pension in error.
        </Typography>
        <Typography variant="caption">
          If the employee is leaving a pension scheme click <b>'Leave pension'</b>
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: spacing.mt40 }}>
          <LoaderButton
            name="Delete"
            sizeVariant="medium"
            colorVariant="danger"
            type="button"
            onClick={deletePension}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  );
};
