import { useCallback } from 'react';

import { Stack, Typography } from '@mui/material';
import { EditableUserPayrollDto, UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { EditUserPayrollNonUK } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll-non-uk.page';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { CloseButton } from '@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type OnboardingPayrollProps = {
  userId: number;
  userPayroll: UserPayrollDto | null;
  payrollSchema: PayrollSchema;
  onClose: () => void;
};

export const OnboardingPayrollNonUK = ({ userId, userPayroll, payrollSchema, onClose }: OnboardingPayrollProps) => {
  const { polyglot } = usePolyglot();
  const savePayrollRecord = useCallback(
    async (update: EditableUserPayrollDto) => {
      try {
        await PayrollAPI.updateUserPayrollRecord(userId, update);
        return true;
      } catch (error) {
        return false;
      }
    },
    [userId]
  );

  return (
    <Stack sx={{ flex: 1 }}>
      <Stack sx={{ flex: 0, mx: 'auto', width: '500px', maxWidth: '90vw', mt: spacing.mt40 }}>
        <Typography
          sx={{
            ...themeFonts.title2,
            color: themeColors.DarkGrey,
          }}
        >
          {polyglot.t('OnboardingByUser.payrollOnboarding')}
        </Typography>
        <CloseButton onClick={onClose} />
      </Stack>
      <Stack sx={{ flex: 1, overflowY: 'auto' }}>
        <Stack sx={{ mx: 'auto', width: '500px', maxWidth: '90vw', my: spacing.my40, gap: spacing.g30 }}>
          <EditUserPayrollNonUK
            payrollSchema={payrollSchema}
            remotePayrollValues={userPayroll?.remotePayrollValues}
            savePayrollRecord={savePayrollRecord}
            close={onClose}
            isUserUpdating={false}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
