import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsencePolicyAllowanceType, CreateAbsencePolicy } from '@v2/feature/absence/absence.interface';
import { COLOR_OPTIONS } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { getAllowancesUnits } from '@v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { COLOR_PICKER_COLORS } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';

type FormData = Pick<AbsencePolicyDto, 'name' | 'color' | 'allowance'> & {
  allowanceUnits: 'hour' | 'day';
};

interface NewTimePolicyDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const NewTimePolicyDrawer = ({ isOpen, setIsOpen, refresh }: NewTimePolicyDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: FormData) => {
      const policyData: CreateAbsencePolicy = {
        name: values.name,
        color: values.color,
        allowance: values.allowance === null ? null : Number(values.allowance),
        allowanceType:
          values.allowance === null
            ? AbsencePolicyAllowanceType.Unlimited
            : values.allowanceUnits === 'hour'
            ? AbsencePolicyAllowanceType.LimitedHourly
            : AbsencePolicyAllowanceType.Limited,
      };

      try {
        setLoading(true);
        await AbsenceAPI.createAbsencePolicy(policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('AbsenceDrawerPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, showMessage]
  );

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
      color: COLOR_PICKER_COLORS[Math.floor(Math.random() * COLOR_PICKER_COLORS.length)],
      allowance: null,
      allowanceUnits: 'day',
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t('NewTimePolicyDrawer.errorMessages.display')),
      color: yup.string().required(polyglot.t('NewTimePolicyDrawer.errorMessages.color')),
      allowance: yup.number().nullable().notRequired(),
      allowanceUnits: yup
        .string()
        .oneOf(['day', 'hour'])
        .required(polyglot.t('NewTimePolicyDrawer.errorMessages.units')),
    }),
    onSubmit,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('NewTimePolicyDrawer.newPolicy')}</Typography>

          <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
            <Box sx={{ width: '60px' }}>
              <SelectComponent
                name="color"
                label={polyglot.t('NewTimePolicyGeneralStep.color')}
                options={COLOR_OPTIONS}
                value={formik.values.color}
                onChange={formik.handleChange}
                compareValue={formik.values.color}
                error={!!formik.errors.color && formik.touched.color}
                helperText={formik.touched.color && (formik.errors.color as string)}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextfieldComponent
                name="name"
                label={polyglot.t('NewTimePolicyGeneralStep.fullName')}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && (formik.errors.name as string)}
                endAdornment="none"
                fullWidth
              />
            </Box>
          </Box>

          <Box>
            <Typography variant="captionSmall" sx={{ mb: spacing.m5 }}>
              {polyglot.t('NewTimePolicyDrawer.allowanceType')}
            </Typography>
            <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
              <ButtonComponent
                onClick={() => {
                  formik.setFieldValue('allowance', null);
                }}
                sizeVariant="filter"
                colorVariant={formik.values.allowance === null ? 'active' : 'secondary'}
              >
                {polyglot.t('NewTimePolicyDrawer.unlimited')}
              </ButtonComponent>

              <ButtonComponent
                onClick={() => {
                  formik.setFieldValue('allowance', 25);
                }}
                sizeVariant="filter"
                colorVariant={formik.values.allowance !== null ? 'active' : 'secondary'}
              >
                {polyglot.t('NewTimePolicyDrawer.limited')}
              </ButtonComponent>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: spacing.g10 }}>
            <SelectComponent
              name="allowanceUnits"
              label={polyglot.t('AbsencePolicyAllowanceSection.unit')}
              options={getAllowancesUnits(polyglot)}
              value={formik.values.allowanceUnits}
              onChange={formik.handleChange}
            />
            {formik.values.allowance !== null && (
              <TextfieldComponent
                label={polyglot.t('AbsencePolicyAllowanceSection.allowance')}
                name="allowance"
                value={formik.values.allowance}
                onChange={(e) => {
                  const value = e.target.value;
                  const numberRegex = /^\d*(.)?[0,5]?$/g;
                  if (value.match(numberRegex)) formik.setFieldValue('allowance', value);
                }}
                // type="number"
                fullWidth
                size="small"
                endAdornment="none"
              />
            )}
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
          <UpgradeToProModal
            isOpen={upgradeModalOpen}
            setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
            planName={PlanNames.PEOPLE_PRO}
            messageSuffix="proGeneric"
          />
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
