import React, { useState } from 'react';

import { Box } from '@mui/material';
import { FieldCard } from '@v2/components/field-card.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import {
  getAccrualBreakdownValue,
  getAccrualRemainingValue,
  getBookedFromBreakdown,
  getCurrentBalanceFromBreakdown,
  getTotalAllowanceBreakdown,
  getUsedFromBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { AllowanceDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/allowance-drawer.component';
import { BookedAllowanceDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/booked-allowance-drawer.component';
import { UsedAllowanceDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/used-allowance-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

interface PolicyBreakdownProps {
  readonly isCurrentYear: boolean;
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
  readonly userId: number;
  readonly refresh: (policyId: number | 'all') => Promise<void>;
}

export const PolicyBreakdown = ({
  isCurrentYear,
  userId,
  refresh,
  absencePolicy,
  userBalanceDetailedStats,
}: PolicyBreakdownProps) => {
  const { polyglot } = usePolyglot();
  const [isAllowanceDrawerOpen, setIsAllowanceDrawerOpen] = useState<boolean>(false);
  const [isUsedDrawerOpen, setIsUsedDrawerOpen] = useState<boolean>(false);
  const [isBookedDrawerOpen, setIsBookedDrawerOpen] = useState<boolean>(false);
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];

  const accruedSoFar = isCurrentYear
    ? getAccrualBreakdownValue(absencePolicy, policyBreakdown, 'total', polyglot)
    : null;
  const accruedRemaining = isCurrentYear ? getAccrualRemainingValue(absencePolicy, policyBreakdown, polyglot) : null;

  const totalAllowance = getTotalAllowanceBreakdown(absencePolicy, policyBreakdown, polyglot);
  const used = getUsedFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const booked = getBookedFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const balance = getCurrentBalanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);

  const isUnlimited = isUnlimitedPolicy(absencePolicy);

  const boxSx = accruedSoFar ? { height: '88px' } : {};

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="title3" sx={{ mb: spacing.m10 }}>
        {absencePolicy.name}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <FieldCard
          name={polyglot.t('AllowanceDrawer.allowance')}
          value={totalAllowance ?? polyglot.t('AllowanceDrawer.unlimited')}
          subValue={accruedSoFar ? polyglot.t('AllowanceDrawer.valueAccrued', { value: accruedSoFar }) : undefined}
          action={isUnlimited ? undefined : () => setIsAllowanceDrawerOpen(true)}
          color="secondary"
          sx={boxSx}
        />
        <Typography variant="caption">–</Typography>
        <FieldCard
          name={polyglot.t('AllowanceDrawer.used')}
          value={used}
          action={isUnlimited ? undefined : () => setIsUsedDrawerOpen(true)}
          color="warning"
          sx={boxSx}
        />
        <Typography variant="caption">–</Typography>
        <FieldCard
          name={polyglot.t('AllowanceDrawer.booked')}
          value={booked}
          action={isUnlimited ? undefined : () => setIsBookedDrawerOpen(true)}
          color="warning"
          sx={boxSx}
        />
        <Typography variant="caption">=</Typography>
        <FieldCard
          name={polyglot.t('AllowanceDrawer.remaining')}
          value={balance ?? polyglot.t('AllowanceDrawer.unlimited')}
          subValue={
            accruedRemaining ? polyglot.t('AllowanceDrawer.valueAvailable', { value: accruedRemaining }) : undefined
          }
          color="success"
          sx={boxSx}
        />
      </Box>

      {isAllowanceDrawerOpen && (
        <AllowanceDrawer
          isOpen={isAllowanceDrawerOpen}
          setIsOpen={setIsAllowanceDrawerOpen}
          absencePolicy={absencePolicy}
          userBalanceDetailedStats={userBalanceDetailedStats}
          refresh={refresh}
          userId={userId}
        />
      )}

      {isUsedDrawerOpen && (
        <UsedAllowanceDrawer
          isOpen={isUsedDrawerOpen}
          setIsOpen={setIsUsedDrawerOpen}
          absencePolicy={absencePolicy}
          userBalanceDetailedStats={userBalanceDetailedStats}
        />
      )}
      {isBookedDrawerOpen && (
        <BookedAllowanceDrawer
          isOpen={isBookedDrawerOpen}
          setIsOpen={setIsBookedDrawerOpen}
          absencePolicy={absencePolicy}
          userBalanceDetailedStats={userBalanceDetailedStats}
        />
      )}
    </Box>
  );
};
