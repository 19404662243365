import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { IconButton } from '@v2/components/forms/icon-button.component';
import { TimePickerComponent } from '@v2/components/forms/time-picker.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { AbsenceAPI, AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsenceDto, AbsencePolicyAttachmentType, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import {
  convertMinutesToClockHours,
  getNextDateFromBEStringDate,
  isHourlyPolicy,
  syncStartAndEndHourWithTheSameDate,
} from '@v2/feature/absence/absence.util';
import {
  AfternoonOnly,
  FullDay,
  fullOrAfternoonOnlyValues,
  fullOrHalfDayValues,
  fullOrMorningOnlyValues,
  MorningOnly,
  OtherLength,
} from '@v2/feature/calendar/calendar.interface';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';

import { UploadInput } from '@/component/forms/UploadInput';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Subtract.svg';
import { nestErrorMessage } from '@/lib/errors';
import { checkIsManagerOrAdmin } from '@/lib/scopes';
import { AbsenceEditableFields, AbsenceRequestAndBalanceDetails, CreateMultipleAbsence } from '@/models/absence.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AbsenceOverlapDisplay } from '@/v2/feature/absence/components/absence-overlap-display.component';
import { AbsenceImportDto } from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { SideAccentedTextLabel } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/side-accented-text-label.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest, isValidISODateTimeString, isValidTimeString } from '@/v2/infrastructure/date/date-format.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface FormData {
  id?: string;
  noOfEmployees: 'single' | 'multiple';
  membersRule: UserSelectFiltersOptions;
  customRule: string | null;
  userIds: number[];
  policyId: number | undefined;
  multipleDays: boolean;
  start: string;
  startHour: string | null;
  startHourTimestamp: Date | null;
  end: string | null;
  endHour: string | null;
  endHourTimestamp: Date | null;
  startFullOrHalfDay: string;
  endFullOrHalfDay: string;
  notes: string | null;
  isHourlyLoggingPolicy: boolean;
  attachment: string | null;
}

interface AbsenceDrawerPageProps {
  userId?: number;
  absence?: AbsenceDto;
  readonly refresh?: (policyId: number) => Promise<void> | void;
  readonly closePage: () => void;
  readonly reach?: 'company' | 'team';
  usedForDataImport?: boolean;
  readonly importHandler?: (values: AbsenceImportDto, absencePolicy: AbsencePolicyDto | undefined) => void;
  readonly policyId?: number;
}

function validateGetBalance(values: FormData): boolean {
  if (!values.isHourlyLoggingPolicy && values.startFullOrHalfDay === 'otherLength') {
    if (values.startFullOrHalfDay !== 'otherLength') {
      return false;
    }
    if (!values.startHour || !values.endHour) {
      return false;
    }
  }

  // if not single absence or no policy / user / startDate is selected, don't check balance
  if (
    values.noOfEmployees !== 'single' ||
    values.userIds.length !== 1 ||
    !values.policyId ||
    !values.start ||
    // if single daily absence of type multiple days and no end date, don't check balance
    (!values.isHourlyLoggingPolicy && values.multipleDays && !values.end) ||
    // if hourly absence and no start/end hour is selected, don't check balance
    (values.isHourlyLoggingPolicy && (!values.startHour || !values.endHour)) ||
    // if hourly && not valid hours selected/typed
    (values.isHourlyLoggingPolicy &&
      (!values.startHour ||
        !values.endHour ||
        !isValidISODateTimeString(values.endHour) ||
        !isValidISODateTimeString(values.endHour))) ||
    // if hourly && endHour <= startHour
    (values.isHourlyLoggingPolicy && (!values.startHour || !values.endHour || values.startHour >= values.endHour))
  )
    return false;

  return true;
}

const getHalfDayOptions = (
  values: Pick<FormData, 'isHourlyLoggingPolicy' | 'startFullOrHalfDay' | 'endFullOrHalfDay' | 'end'>,
  polyglot: Polyglot
): { morningOnly: boolean; afternoonOnly: boolean } => {
  const morningOnly =
    !values.isHourlyLoggingPolicy &&
    Boolean(
      (!values.end && values.startFullOrHalfDay === MorningOnly(polyglot).value) ||
        (values.end && values.endFullOrHalfDay === MorningOnly(polyglot).value)
    );
  const afternoonOnly = !values.isHourlyLoggingPolicy && values.startFullOrHalfDay === AfternoonOnly(polyglot).value;

  return { morningOnly, afternoonOnly };
};

const isSingleAbsence = (abs: Pick<FormData, 'multipleDays' | 'startFullOrHalfDay' | 'startHour' | 'endHour'>) =>
  Boolean(!abs.multipleDays || abs.startFullOrHalfDay === 'otherLength' || abs.startHour || abs.endHour);

function getStartAndEndHours(
  values: FormData,
  polyglot: Polyglot
):
  | {
      startHour: string;
      endHour: string;
      startHourTimestamp: Date;
      endHourTimestamp: Date;
    }
  | {
      startHour: null;
      endHour: null;
      startHourTimestamp: null;
      endHourTimestamp: null;
    } {
  if (values.isHourlyLoggingPolicy || values.startFullOrHalfDay === 'otherLength')
    return syncStartAndEndHourWithTheSameDate(values, polyglot);

  return { startHour: null, endHour: null, startHourTimestamp: null, endHourTimestamp: null };
}

const absenceValidationSchema = (absencePolicy: AbsencePolicyDto | null, polyglot: Polyglot) =>
  yup.object({
    userIds: yup
      .array(
        yup
          .number()
          .typeError(polyglot.t('ValidationMessages.validValue'))
          .integer(polyglot.t('ValidationMessages.validValue'))
          .required(polyglot.t('AbsenceDrawerPage.errorMessages.userRequired') + '456')
      )
      .min(1, polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('AbsenceDrawerPage.errorMessages.userRequired') + '123'),
    policyId: yup.number().integer().required(polyglot.t('AbsenceDrawerPage.errorMessages.policyRequired')),
    start: yup.string().test(dateFieldTest).required(polyglot.t('AbsenceDrawerPage.errorMessages.startDateRequired')),
    end: yup.string().test(dateFieldTest).nullable().notRequired(),
    startFullOrHalfDay: yup.string().required(polyglot.t('AbsenceDrawerPage.errorMessages.fullOrHalfRequired')),
    endFullOrHalfDay: yup.string().notRequired(),
    notes: yup.string().max(255, polyglot.t('AbsenceDrawerPage.errorMessages.maxChars')).nullable().notRequired(),
    isHourlyLoggingPolicy: yup.boolean().notRequired(),
    attachment:
      absencePolicy?.attachmentType && absencePolicy.attachmentType === AbsencePolicyAttachmentType.Required
        ? yup
            .string()
            .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.attachFile'))
            .required(polyglot.t('AbsenceDrawerPage.errorMessages.attachFile'))
        : yup.string().nullable().notRequired(),
    startHour: yup.date().when('isHourlyLoggingPolicy', {
      is: true,
      then: yup
        .date()
        .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
        .required(polyglot.t('AbsenceDrawerPage.errorMessages.startHourRequired')),
      otherwise: yup.date().when('startFullOrHalfDay', {
        is: (val: string) => val === 'otherLength',
        then: yup
          .date()
          .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
          .required(polyglot.t('AbsenceDrawerPage.errorMessages.startHourRequired')),
        otherwise: yup.date().nullable().notRequired(),
      }),
    }),
    // startHourTimestamp: yup.date().when('isHourlyLoggingPolicy', {
    //   is: true,
    //   then: yup
    //     .date()
    //     .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
    //     .required(polyglot.t('AbsenceDrawerPage.errorMessages.startHourRequired')),
    //   otherwise: yup.date().when('startFullOrHalfDay', {
    //     is: (val: string) => val === 'otherLength',
    //     then: yup
    //       .date()
    //       .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
    //       .required(polyglot.t('AbsenceDrawerPage.errorMessages.startHourRequired')),
    //     otherwise: yup.date().nullable().notRequired(),
    //   }),
    // }),
    endHour: yup.date().when('isHourlyLoggingPolicy', {
      is: true,
      then: yup.date().when('startHour', {
        is: (startHour: Date | undefined | null) => {
          if (!startHour) return false;
          return startHour.toString() !== 'Invalid Date';
        },
        then: yup
          .date()
          .min(yup.ref('startHour'), polyglot.t('AbsenceDrawerPage.errorMessages.endHourInvalid'))
          .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
          .required(polyglot.t('AbsenceDrawerPage.errorMessages.endHourRequired')),
        otherwise: yup
          .date()
          .typeError('Please select a valid time value')
          .required(polyglot.t('AbsenceDrawerPage.errorMessages.endHourRequired')),
      }),
      otherwise: yup.date().when('startFullOrHalfDay', {
        is: (val: string) => val === 'otherLength',
        then: yup
          .date()
          .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
          .required(polyglot.t('AbsenceDrawerPage.errorMessages.startHourRequired')),
        otherwise: yup.date().nullable().notRequired(),
      }),
    }),
    // endHourTimestamp: yup.date().when('isHourlyLoggingPolicy', {
    //   is: true,
    //   then: yup.date().when('startHour', {
    //     is: (startHour: Date | undefined | null) => {
    //       if (!startHour) return false;
    //       return startHour.toString() !== 'Invalid Date';
    //     },
    //     then: yup
    //       .date()
    //       .min(yup.ref('startHourTimestamp'), polyglot.t('AbsenceDrawerPage.errorMessages.endHourInvalid'))
    //       .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
    //       .required(polyglot.t('AbsenceDrawerPage.errorMessages.endHourRequired')),
    //     otherwise: yup
    //       .date()
    //       .typeError('Please select a valid time value')
    //       .required(polyglot.t('AbsenceDrawerPage.errorMessages.endHourRequired')),
    //   }),
    //   otherwise: yup.date().when('startFullOrHalfDay', {
    //     is: (val: string) => val === 'otherLength',
    //     then: yup
    //       .date()
    //       .typeError(polyglot.t('AbsenceDrawerPage.errorMessages.timeValueInvalid'))
    //       .required(polyglot.t('AbsenceDrawerPage.errorMessages.startHourRequired')),
    //     otherwise: yup.date().nullable().notRequired(),
    //   }),
    // }),
  });

export const AbsenceDrawerPage = ({
  userId = undefined,
  absence = undefined,
  refresh,
  closePage,
  reach = 'company',
  usedForDataImport,
  importHandler,
  policyId,
}: AbsenceDrawerPageProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);

  const { hasScopes } = useScopes();
  const isAdmin = hasScopes(['absence:all']);
  const isManager = checkIsManagerOrAdmin(state.user, ['absence:manager']);

  const { data: absencePolicies } = useApiClient(
    userId
      ? AbsenceEndpoints.getUserAbsencePolicies(userId)
      : absence
      ? AbsenceEndpoints.getUserAbsencePolicies(absence.userId)
      : isAdmin
      ? AbsenceEndpoints.getAbsencePolicies()
      : isManager
      ? AbsenceEndpoints.getTeamAbsencePolicies()
      : null,
    { suspense: false }
  );

  const [receiptFilename, setReceiptFilename] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers({ refresh: true });

  const user = useMemo(() => {
    const currentUserId = absence?.userId ?? userId;
    return currentUserId ? getCachedUserById(currentUserId) : null;
  }, [absence?.userId, userId, getCachedUserById]);

  const [selectedPolicy, setSelectedPolicy] = useState<AbsencePolicyDto | null>(null);

  const getStartFullOrHalfDayType = useCallback(
    (absence?: AbsenceDto): string => {
      if (absence?.startHour || absence?.endHour) return OtherLength(polyglot).value;

      if (absence?.end) {
        // Multiple days value
        if (absence.afternoonOnly) return AfternoonOnly(polyglot).value;
        return FullDay(polyglot).value;
      }

      if (absence?.morningOnly) return MorningOnly(polyglot).value;
      if (absence?.afternoonOnly) return AfternoonOnly(polyglot).value;

      return FullDay(polyglot).value;
    },
    [polyglot]
  );

  const getEndFullOrHalfDayType = useCallback(
    (absence?: AbsenceDto): string => {
      if (absence?.startHour || absence?.endHour) return OtherLength(polyglot).value;

      if (!absence?.end) return FullDay(polyglot).value;

      // Multiple days value
      if (absence.morningOnly) return MorningOnly(polyglot).value;
      return FullDay(polyglot).value;
    },
    [polyglot]
  );

  const initialValues: FormData = useMemo(
    () => ({
      noOfEmployees: 'single',
      membersRule: UserSelectFiltersOptions.None,
      customRule: null,
      userIds: userId ? [userId] : absence?.userId ? [absence.userId] : [],
      policyId: absence?.policyId ?? policyId ?? undefined,
      multipleDays: !!absence?.end && !absence?.startHour && !absence?.endHour,
      start: absence?.start ? absence.start : new LocalDate().toDateString(),
      end: absence?.end ? absence.end : null,
      startFullOrHalfDay: getStartFullOrHalfDayType(absence),
      endFullOrHalfDay: getEndFullOrHalfDayType(absence),
      notes: absence?.notes ?? '',
      id: absence?.id ?? undefined,
      isHourlyLoggingPolicy: isHourlyPolicy(
        absencePolicies?.find((p) => {
          const pId = absence?.policyId ?? policyId;
          return p.id === pId;
        })
      ),
      startHour: absence?.startHour ?? null,
      endHour: absence?.endHour ?? null,
      startHourTimestamp: absence?.startHourTimestamp ?? null,
      endHourTimestamp: absence?.endHourTimestamp ?? null,
      attachment: absence?.attachment ?? null,
    }),
    [userId, absence, policyId, getStartFullOrHalfDayType, getEndFullOrHalfDayType, absencePolicies]
  );

  const formik = useFormik<FormData>({
    initialValues,
    enableReinitialize: true,
    validationSchema: absenceValidationSchema(selectedPolicy, polyglot),
    onSubmit: async (values): Promise<void> => {
      if (values.userIds.length === 0) {
        showMessage(polyglot.t('AbsenceDrawerPage.errorMessages.userRequired'), 'error');
        return;
      }
      if (!values.policyId) {
        showMessage(polyglot.t('AbsenceDrawerPage.errorMessages.policyRequired'), 'error');
        return;
      }

      try {
        setIsSubmitting(true);
        const { morningOnly, afternoonOnly } = getHalfDayOptions(values, polyglot);
        const { startHour, endHour, startHourTimestamp, endHourTimestamp } = getStartAndEndHours(values, polyglot);
        const isSingleAbs = isSingleAbsence(values);

        const commonPayload: Omit<AbsenceEditableFields, 'userId'> = {
          policyId: values.policyId,
          start: values.start,
          end: isSingleAbs ? null : values.end ?? null,
          morningOnly,
          afternoonOnly,
          startHour,
          endHour,
          startHourTimestamp,
          endHourTimestamp,
          notes: values.notes ?? null,
          attachment: values.attachment ?? null,
        };

        if (usedForDataImport) {
          const policy = absencePolicies?.find((p) => p.id === values.policyId);
          const start = values.startHour ?? values.start;
          const end =
            isSingleAbs && values.start && values.endHour
              ? `${values.start.slice(0, 10)}T${values.endHour.slice(11, 16)}`
              : values.endHour ?? values.end ?? null;

          importHandler?.(
            ({
              ...commonPayload,
              policyName: policy?.name ?? '',
              userId: values.userIds[0],
              morningOnly: morningOnly ? 'Yes' : 'No',
              afternoonOnly: afternoonOnly ? 'Yes' : 'No',
              start,
              end,
              id: values.id,
              startHourTimestamp: null,
              endHourTimestamp: null,
            } as unknown) as AbsenceImportDto,
            policy
          );
        } else if (absence?.absenceId) {
          const updatePayload: AbsenceEditableFields = {
            ...commonPayload,
            userId: values.userIds[0],
          };
          await AbsenceAPI.updateAbsenceRecord(absence.absenceId, updatePayload);
          showMessage(polyglot.t('AbsenceDrawerPage.successMessages.update'), 'success');

          if (refresh) await refresh(commonPayload.policyId);
          closePage();
        } else {
          const createPayload: CreateMultipleAbsence = {
            ...commonPayload,
            userIds: values.userIds,
            membersRule:
              values.noOfEmployees === 'single' ? UserSelectFiltersOptions.SelectSpecific : values.membersRule,
            customRule: values.noOfEmployees === 'single' ? null : values.customRule,
          };
          const response = await AbsenceAPI.addMultipleAbsenceRecord(createPayload);

          if (response.success && response.method === 'direct') {
            if (response.noOfCreatedAbsences === 0)
              showMessage(polyglot.t('AbsenceDrawerPage.errorMessages.noAbsenceHasBeenCreated'), 'error');
            else if (response.noOfCreatedAbsences === 1 && createPayload.userIds.length === 1)
              showMessage(polyglot.t('AbsenceDrawerPage.successMessages.register'), 'success');
            else showMessage(polyglot.t('AbsenceDrawerPage.successMessages.registerMultiple'), 'success');
          } else if (response.success && response.method === 'queue') {
            showMessage(polyglot.t('AbsenceDrawerPage.successMessages.absencesQueued'), 'success');
          }

          if (response.noOfCreatedAbsences !== 0) {
            if (refresh) await refresh(commonPayload.policyId);
            closePage();
          }
        }
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceDrawerPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const policy = absencePolicies?.find((p) => p.id === formik.values.policyId);
    setSelectedPolicy(policy ?? null);
  }, [absencePolicies, formik.values.policyId]);

  const [
    requestAndRemainingBalanceDetails,
    setRequestAndRemainingBalanceDetails,
  ] = useState<AbsenceRequestAndBalanceDetails | null>(null);

  const getRequestAndRemainingBalanceDetails = useDebouncedCallback(async (values: FormData) => {
    const isValid = validateGetBalance(values);
    if (!values.policyId || !isValid) {
      setRequestAndRemainingBalanceDetails(null);
      return;
    }

    try {
      const { morningOnly, afternoonOnly } = getHalfDayOptions(values, polyglot);
      const { startHour, endHour, startHourTimestamp, endHourTimestamp } = getStartAndEndHours(values, polyglot);
      const isSingleAbs = isSingleAbsence(values);

      const balanceImpactPayload: CreateMultipleAbsence = {
        userIds: values.userIds,
        policyId: values.policyId,
        start: values.start,
        end: isSingleAbs ? null : values.end ?? null,
        notes: values.notes,
        attachment: values.attachment,
        morningOnly,
        afternoonOnly,
        startHour,
        endHour,
        startHourTimestamp,
        endHourTimestamp,

        membersRule: UserSelectFiltersOptions.SelectSpecific,
        customRule: null,
      };
      const data = await AbsenceAPI.getNewAbsenceBalanceImpactDetails(balanceImpactPayload);
      setRequestAndRemainingBalanceDetails(data ?? null);
    } catch (error) {
      setRequestAndRemainingBalanceDetails(null);
    }
  }, 200); //[formik.errors, formik.values, polyglot]);

  const isActiveMemberOfSelectedPolicy = useMemo(() => {
    return Boolean(
      formik.values.policyId && absencePolicies && absencePolicies.some((p) => p.id === formik.values.policyId)
    );
  }, [formik.values.policyId, absencePolicies]);

  const showAttachmentSection = useMemo(
    () =>
      Boolean(
        formik.values.policyId &&
          absencePolicies &&
          absencePolicies.find((p) => p.id === formik.values.policyId)?.attachmentType
      ),
    [formik.values.policyId, absencePolicies]
  );

  useEffect(() => {
    if (isActiveMemberOfSelectedPolicy) getRequestAndRemainingBalanceDetails.callback(formik.values);
  }, [getRequestAndRemainingBalanceDetails, isActiveMemberOfSelectedPolicy, formik.values]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {absence ? polyglot.t('AbsenceDrawerPage.edit') : polyglot.t('AbsenceDrawerPage.newRequest')}
        </Typography>

        <SelectComponent
          name="policyId"
          label={polyglot.t('AbsenceDrawerPage.policyId')}
          options={(absencePolicies ?? []).map((policy) => ({
            label: policy.name,
            value: policy.id,
          }))}
          value={formik.values.policyId}
          compareValue={formik.values.policyId}
          onChange={(e) => {
            formik.handleChange(e);
            const policyId = e.target.value;
            const policy = absencePolicies?.find((p) => p.id === policyId);
            const isHourly = isHourlyPolicy(policy);
            formik.setFieldValue('isHourlyLoggingPolicy', isHourly);
            formik.setFieldValue('userIds', absence ? [absence.userId] : userId ? [userId] : []);
            if (isHourly) {
              formik.setFieldValue('endFullOrHalfDay', false);
              formik.setFieldValue('startFullOrHalfDay', false);
              formik.setFieldValue('multipleDays', false);
              formik.setFieldValue('end', null);
            } else {
              formik.setFieldValue('startHour', null);
              formik.setFieldValue('endHour', null);
              formik.setFieldValue('startHourTimestamp', null);
              formik.setFieldValue('endHourTimestamp', null);
            }
          }}
          error={Boolean(
            (!!formik.errors.policyId && formik.touched.policyId) ||
              (!isActiveMemberOfSelectedPolicy && formik.values.policyId)
          )}
          helperText={
            (formik.touched.policyId && (formik.errors.policyId as string)) ||
            (!isActiveMemberOfSelectedPolicy && formik.values.policyId
              ? 'Not an active member of this policy anymore'
              : '')
          }
        />

        {!userId && !absence && isManager && (
          <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
            <ButtonComponent
              sizeVariant="filter"
              key="single-employees"
              colorVariant={formik.values.noOfEmployees !== 'multiple' ? 'active' : 'secondary'}
              onClick={() => {
                formik.setFieldValue('noOfEmployees', 'single');
              }}
            >
              {polyglot.t('AbsenceDrawerPage.single')}
            </ButtonComponent>

            <ButtonComponent
              key="multiple-employees"
              sizeVariant="filter"
              colorVariant={formik.values.noOfEmployees === 'multiple' ? 'active' : 'secondary'}
              onClick={() => {
                formik.setFieldValue('noOfEmployees', 'multiple');
              }}
            >
              {polyglot.t('AbsenceDrawerPage.multipleEmployees')}
            </ButtonComponent>
          </Box>
        )}

        {!userId && !absence && formik.values.noOfEmployees === 'single' && (
          <SingleUserSelect
            name="userIds"
            options={reach}
            allValidUserIds={selectedPolicy?.selectedMembersIds ?? []}
            onChange={(_, x) => {
              formik.setFieldValue('userIds', x?.value ? [Number(x.value)] : []);
            }}
            value={formik.values.userIds[0]}
            label={polyglot.t('AbsenceDrawerPage.employee')}
            error={formik.touched.userIds && Boolean(formik.errors.userIds)}
            helperText={formik.touched.userIds && (formik.errors.userIds as string)}
            disabled={!selectedPolicy}
          />
        )}
        {!userId && !absence && formik.values.noOfEmployees === 'multiple' && (
          <UserSelect
            label={polyglot.t('EditPolicyMembersDrawerContent.whoSelect')}
            selectedLabel={polyglot.t('EditPolicyMembersDrawerContent.selectedMembers')}
            value={formik.values.userIds}
            onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
              formik.setFieldValue('userIds', userIds);
              setValidationMessage('');
              formik.setFieldValue('membersRule', filterValue ?? UserSelectFiltersOptions.None);
              formik.setFieldValue('customRule', customRule ?? '');
            }}
            fieldSx={{ ...spacing.mb20 }}
            error={!!validationMessage}
            helperText={!!validationMessage && validationMessage}
            initialFilterValue={UserSelectFiltersOptions.None}
            ruleString={formik.values.customRule}
            allValidUserIds={selectedPolicy?.selectedMembersIds ?? []}
            excludeEveryone
            excludeCustomRule
            disabled={!selectedPolicy}
          />
        )}

        {!userId && absence && (
          <Box>
            <Typography variant="captionSmall" color="Grey">
              {polyglot.t('AbsenceDrawerPage.employee')}
            </Typography>
            <Box sx={{ mt: spacing.m5, display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
              <UserAvatar userId={absence.userId} size="xxsmall" />
              <Typography variant="title4">
                {user ? polyglot.t(user.displayName || `${user.firstName} ${user.lastName}`) : ''}
              </Typography>
            </Box>
          </Box>
        )}

        {selectedPolicy?.instructions?.length && (
          <SideAccentedTextLabel text={selectedPolicy.instructions} borderColor={selectedPolicy?.color} />
        )}

        {!formik.values.isHourlyLoggingPolicy && (
          <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
            <ButtonComponent
              sizeVariant="filter"
              key="unlimited-allowance"
              colorVariant={!formik.values.multipleDays ? 'active' : 'secondary'}
              onClick={() => {
                formik.setFieldValue('multipleDays', false);
                formik.setFieldValue('end', null);
              }}
            >
              {polyglot.t('AbsenceDrawerPage.oneDay')}
            </ButtonComponent>

            <ButtonComponent
              key="limited-allowance"
              sizeVariant="filter"
              colorVariant={formik.values.multipleDays ? 'active' : 'secondary'}
              onClick={() => {
                formik.setFieldValue('multipleDays', true);
                formik.setFieldValue('end', getNextDateFromBEStringDate(formik.values.start));
                if (formik.values.startFullOrHalfDay === 'otherLength')
                  formik.setFieldValue('startFullOrHalfDay', 'full');
              }}
            >
              {polyglot.t('AbsenceDrawerPage.multipleDays')}
            </ButtonComponent>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: spacing.g20 }}>
          <Box sx={{ width: '100%' }}>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.start ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('start', value);

                  // if multiple dates and end date is not set or it is set before start date, set it to start date + 1
                  if (formik.values.multipleDays && (!formik.values.end || formik.values.end < value)) {
                    formik.setFieldValue('end', getNextDateFromBEStringDate(value));
                  }
                }
              }}
              name="start"
              label={
                formik.values.isHourlyLoggingPolicy
                  ? polyglot.t('AbsenceDrawerPage.date')
                  : polyglot.t('AbsenceDrawerPage.startDate')
              }
              error={!!formik.errors.start && Boolean(formik.touched.start)}
              helperText={formik.errors.start && Boolean(formik.touched.start)}
            />
          </Box>

          {!formik.values.isHourlyLoggingPolicy && (
            <Box sx={{ width: '100%' }}>
              <SelectComponent
                name="startFullOrHalfDay"
                label={polyglot.t('AbsenceDrawerPage.length')}
                options={
                  formik.values.multipleDays ? fullOrAfternoonOnlyValues(polyglot) : fullOrHalfDayValues(polyglot)
                }
                value={formik.values.startFullOrHalfDay}
                compareValue={formik.values.startFullOrHalfDay}
                error={!!formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay}
                onChange={formik.handleChange}
                helperText={formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay}
              />
            </Box>
          )}
        </Box>

        {!formik.values.isHourlyLoggingPolicy && formik.values.multipleDays && (
          <Box sx={{ display: 'flex', gap: spacing.g20 }}>
            <Box sx={{ width: '100%' }}>
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                value={formik.values.end ?? null}
                onChange={(value: string) => {
                  if (dayjs(value).isValid()) {
                    formik.setFieldValue('end', value);
                  }
                }}
                name="end"
                label={polyglot.t('AbsenceDrawerPage.end')}
                error={!!formik.errors.end && Boolean(formik.touched.end)}
                helperText={formik.errors.end && Boolean(formik.touched.end)}
              />
            </Box>

            <Box sx={{ width: '100%' }}>
              <SelectComponent
                name="endFullOrHalfDay"
                label={polyglot.t('AbsenceDrawerPage.endFullOrHalfDay')}
                options={formik.values.multipleDays ? fullOrMorningOnlyValues(polyglot) : fullOrHalfDayValues(polyglot)}
                value={formik.values.endFullOrHalfDay}
                compareValue={formik.values.endFullOrHalfDay}
                onChange={formik.handleChange}
                error={!!formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay}
                helperText={formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay}
              />
            </Box>
          </Box>
        )}

        {(formik.values.isHourlyLoggingPolicy || formik.values.startFullOrHalfDay === 'otherLength') && (
          <Box sx={{ display: 'flex', gap: spacing.g10 }}>
            <TimePickerComponent
              textFieldKey="startHour"
              label={polyglot.t('AbsenceDrawerPage.startHour')}
              value={formik.values.startHour}
              onChange={(event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${formik.values.start}T${time}:00`);
                  formik.setFieldValue('startHour', date.toFullString());
                  formik.setFieldValue('startHourTimestamp', date.getDate());
                }
              }}
              fullWidth
              error={!!formik.errors.startHour && formik.touched.startHour}
              helperText={formik.touched.startHour ? (formik.errors.startHour as string) : undefined}
            />

            <TimePickerComponent
              textFieldKey="endHour"
              label={polyglot.t('AbsenceDrawerPage.endHour')}
              value={formik.values.endHour}
              onChange={(event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${formik.values.start}T${time}:00`);
                  formik.setFieldValue('endHour', date.toFullString());
                  formik.setFieldValue('endHourTimestamp', date.getDate());
                }
              }}
              fullWidth
              error={!!formik.errors.endHour && formik.touched.endHour}
              helperText={formik.touched.endHour ? (formik.errors.endHour as string) : undefined}
            />
          </Box>
        )}

        <TextfieldComponent
          name="notes"
          label={polyglot.t('AbsenceDrawerPage.notes')}
          value={formik.values.notes}
          type="text"
          onChange={formik.handleChange}
          error={formik.touched.notes && Boolean(formik.errors.notes)}
          helperText={formik.touched.notes && (formik.errors.notes as string)}
          clearText={() => formik.setFieldValue('notes', '')}
        />

        {showAttachmentSection && (
          <Box>
            <Typography variant="captionSmall">Attachment</Typography>
            {formik.values.attachment && receiptFilename ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DocumentIcon {...iconSize} />
                  <Box
                    component="span"
                    sx={{ ...themeFonts.title4, marginLeft: spacing.m10, color: themeColors.DarkGrey }}
                  >
                    {receiptFilename}
                  </Box>
                </Box>
                <IconButton
                  sizeVariant="small"
                  colorVariant="secondary"
                  onClick={() => formik.setFieldValue('attachment', null)}
                >
                  <TrashIcon {...iconSize} />
                </IconButton>
              </Box>
            ) : (
              <UploadInput<{ uuid: string; fileName: string }>
                displayText={formik.touched.attachment ? formik.errors.attachment : undefined}
                displayTextColor="RedDark"
                displayTextVariant="captionSmall"
                onChange={(resp) => {
                  formik.setFieldValue('attachment', resp?.uuid);
                  let filename = resp?.fileName;
                  filename = filename?.substring(filename?.lastIndexOf('/') + 1);
                  setReceiptFilename(filename ?? '');
                }}
              />
            )}
          </Box>
        )}

        {requestAndRemainingBalanceDetails && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              transition: 'all 0.9s ease',
              transitionProperty: 'opacity, margin-bottom',
              opacity: requestAndRemainingBalanceDetails ? 1 : 0,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
              <Typography variant="caption">{polyglot.t('AbsenceModule.thisRequest')}</Typography>
              <Typography variant="title4">
                {requestAndRemainingBalanceDetails.unit === 'minute'
                  ? convertMinutesToClockHours(requestAndRemainingBalanceDetails.requestValue, polyglot)
                  : polyglot.t('General.noOfDays', {
                      smart_count: requestAndRemainingBalanceDetails.requestValue,
                    })}
              </Typography>
            </Box>
            {requestAndRemainingBalanceDetails.remainingBalance !== null && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                <Typography variant="title4">{polyglot.t('AbsenceModule.remainingBalance')}</Typography>
                <Typography
                  variant="title4"
                  color={requestAndRemainingBalanceDetails.remainingBalance < 0 ? 'RedDark' : 'DarkGrey'}
                >
                  {requestAndRemainingBalanceDetails.unit === 'minute'
                    ? convertMinutesToClockHours(requestAndRemainingBalanceDetails.remainingBalance, polyglot)
                    : polyglot.t('General.noOfDays', {
                        smart_count: requestAndRemainingBalanceDetails.remainingBalance,
                      })}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {formik.values.userIds && (
          <AbsenceOverlapDisplay
            absenceStart={formik.values.start}
            absenceEnd={formik.values.end ?? null}
            absenceId={absence?.absenceId || null}
            showCalendarLink
            userIds={formik.values.userIds}
            isViewMode={false}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          {absence && (
            <ButtonComponent colorVariant="secondary" sizeVariant="medium" fullWidth onClick={closePage}>
              {polyglot.t('General.cancel')}
            </ButtonComponent>
          )}
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            loading={isSubmitting}
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
