import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { ReactComponent as NotUploadedDocIcon } from '@/images/documents/MissingDoc.svg';
import { ReactComponent as UploadedDocIcon } from '@/images/documents/UploadedDoc.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { getDocumentTypeLabel, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { IconTextCell } from '@/v2/components/table/icon-text-cell.component';
import { sortDate, sortString } from '@/v2/components/table/table-sorting.util';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { CloseButton } from '@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component';
import { DocumentUploadDrawer } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-documents/document-upload-drawer.page';
import { filterUserOnboardingDocuments } from '@/v2/feature/user-onboarding/user-onboarding.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
export const OnboardingDocumentsPage = () => {
  const { polyglot } = usePolyglot();
  const userId = useUserIdParam();
  const [documents, setDocuments] = useState<{
    items: ZeltDocument[];
    types: ZeltDocumentType[];
  }>();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [documentToUpload, setDocumentToUpload] = useState<ZeltDocument | null>(null);

  const fetchDocuments = useCallback(async () => {
    try {
      const [userDocuments, documentTypes] = await Promise.all([
        DocumentAPI.listUserDocuments(userId),
        DocumentAPI.listDocumentTypes(),
      ]);
      const onboardingDocuments = filterUserOnboardingDocuments(userDocuments).sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
      setDocuments({
        items: onboardingDocuments,
        types: documentTypes,
      });
    } catch (e) {
      showMessage(
        polyglot.t('OnboardingByUser.errors.documentRetrieveFailed', { errorMessage: nestErrorMessage(e) }),
        'error'
      );
      setDocuments(undefined);
    }
  }, [showMessage, userId, polyglot]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const columnData = useMemo<ColumnDef<ZeltDocument, ZeltDocument>[]>(() => {
    const getDocumentUploadDate = (doc: ZeltDocument) => doc.attachments?.[0]?.createdAt;
    return [
      {
        id: 'name',
        header: () => polyglot.t('OnboardingByUser.name'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (c) => {
          const doc = c.getValue();
          const hasUploadedFile = !!doc.attachments?.[0];
          return (
            <IconTextCell
              icon={
                hasUploadedFile ? (
                  <UploadedDocIcon width={24} height={30} />
                ) : (
                  <NotUploadedDocIcon width={24} height={30} />
                )
              }
              text={doc.name}
              textSx={hasUploadedFile ? themeFonts.caption : themeFonts.title4}
            />
          );
        },
        size: 100,
      },
      {
        id: 'type',
        header: () => polyglot.t('OnboardingByUser.type'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type),
        cell: (c) => (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {documents?.types && getDocumentTypeLabel(c.getValue(), documents.types)}
          </Typography>
        ),
        size: 60,
      },
      {
        id: 'upload',
        header: () => polyglot.t('General.upload'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => getDocumentUploadDate(item)),
        cell: (c) => {
          const documentDate = getDocumentUploadDate(c.getValue());
          return documentDate ? <DateLabelComponent date={documentDate} /> : <EmptyCell />;
        },
        size: 60,
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (c) => {
          const doc = c.getValue();
          const hasUploadedFile = !!doc.attachments?.[0];
          return hasUploadedFile ? (
            <IconButton
              onClick={() => setDocumentToUpload(doc)}
              disabled={!!documentToUpload}
              title={polyglot.t('General.edit')}
              sx={{ ...tableIconButtonSx, display: 'flex', ml: 'auto' }}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <ButtonComponent
              onClick={() => setDocumentToUpload(doc)}
              disabled={!!documentToUpload}
              sizeVariant="small"
              colorVariant="primary"
              style={{ marginLeft: 'auto' }}
            >
              {polyglot.t('General.upload')}
            </ButtonComponent>
          );
        },
        size: 80,
      },
    ];
  }, [polyglot, documentToUpload, documents]);

  const returnToOnboardingSummary = useCallback(() => {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
  }, [routerHistory, userId]);

  const allDocumentsUploaded = !!documents?.items.every((d) => !!d.attachments?.[0]);

  return (
    <>
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ flex: 0, mx: 'auto', width: '800px', maxWidth: '90vw', mt: spacing.mt40 }}>
          <Typography
            sx={{
              ...themeFonts.title2,
              color: themeColors.DarkGrey,
            }}
          >
            {polyglot.t('OnboardingByUser.uploadDocuments')}
          </Typography>
          <CloseButton onClick={() => returnToOnboardingSummary()} />
        </Stack>
        <Stack sx={{ flex: 1, overflowY: 'auto', mt: spacing.mt30 }}>
          <Stack sx={{ mx: 'auto', width: '800px', maxWidth: '90vw' }}>
            {documents && <BasicTable rowData={documents.items} columnData={columnData} hidePagination />}
            {allDocumentsUploaded && (
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                onClick={() => returnToOnboardingSummary()}
                style={{ marginTop: '40px' }}
              >
                {polyglot.t('General.complete')}
              </ButtonComponent>
            )}
          </Stack>
        </Stack>
      </Stack>
      {documents && (
        <DocumentUploadDrawer
          document={documentToUpload}
          documentTypes={documents.types}
          close={async (fileUuid?: string) => {
            setDocumentToUpload(null);
            if (fileUuid) {
              // if we uploaded a new file, refresh the set of documents
              await fetchDocuments();
            }
          }}
        />
      )}
    </>
  );
};
