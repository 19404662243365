import { useCallback, useMemo, useState } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { AppCreateUserParams } from '@/v2/feature/app-integration/app-integration.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

export type EditAppAccountProps = {
  app: { stub: string; name: string };
  user: CachedUser;
  defaultScheduleOffset: number;
  onFinish: () => void;
};

export const EditAppAccount = ({ app, defaultScheduleOffset, user, onFinish }: EditAppAccountProps) => {
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [showMessage] = useMessage();
  const addDays = useCallback((date: string, days: number): string => {
    const ms = new LocalDate(date).getDate().getTime() + days * (24 * 60 * 60e3);
    return new LocalDate(new Date(ms)).toDateString();
  }, []);

  const maxDate = useCallback((date1: string, date2: string) => (date1 < date2 ? date2 : date1), []);

  const { startDate } = user;

  const formik = useFormik({
    initialValues: {
      activationDate: maxDate(
        startDate ? addDays(startDate, defaultScheduleOffset) : todaysDateShortISOString(),
        todaysDateShortISOString()
      ),
    },
    onSubmit: async (values) => {
      setCreatingAccount(true);
      let params: AppCreateUserParams | undefined;
      const actionDate = values.activationDate === todaysDateShortISOString() ? undefined : values.activationDate;
      try {
        await AppIntegrationAPI.createAppUser(app.stub, user.userId, params, actionDate);
        onFinish();
      } catch (error) {
        showMessage(`Failed to set up account. ${nestErrorMessage(error)}`, 'error');
        setCreatingAccount(false);
      }
    },
  });

  const schedulePresets = useMemo(
    () => ({
      'Start day': 0,
      'Day before start': -1,
      'Week before start': -7,
    }),
    []
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <Typography sx={{ ...themeFonts.title2, mb: spacing.mb10 }}>Create account</Typography>
        <Stack sx={{ gap: spacing.g10 }}>
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
            <img src={`/app-icons-v2/images/${app.stub}.png`} style={{ maxWidth: '20px', maxHeight: '20px' }} alt="" />
            <Typography sx={themeFonts.caption}>{app.name}</Typography>
          </Stack>
          {startDate && (
            <Stack sx={{ flexFlow: 'row', gap: spacing.g10, flexWrap: 'wrap' }}>
              {Object.entries(schedulePresets).map(([label, offset]) => {
                const buttonDate = addDays(startDate, offset);
                if (buttonDate < todaysDateShortISOString()) return <></>;
                return (
                  <Button
                    key={label}
                    sx={formik.values.activationDate === buttonDate ? activeTabsFilterBtnSx : tabsFilterBtnSx}
                    disabled={creatingAccount}
                    onClick={() => {
                      formik.setFieldValue('activationDate', buttonDate);
                    }}
                  >
                    {label}
                  </Button>
                );
              })}
            </Stack>
          )}
          <DatePickerComponent
            name="activationDate"
            label="Activation date"
            onChange={(value) => formik.setFieldValue('activationDate', value)}
            value={formik.values.activationDate}
            disabled={creatingAccount}
            shortcuts
            disablePast
          />
          <LoaderButton
            style={{ marginTop: '40px' }}
            loading={creatingAccount}
            name="Add"
            fullWidth
            colorVariant="primary"
            sizeVariant="large"
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
