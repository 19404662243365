import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import {
  InviteMessageOptions,
  ReminderFrequencyOptions,
  ReminderFrequencyValue,
  ReviewCycle,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const CycleCreationNotificationsModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <CycleCreationNotificationsContent reviewCycle={reviewCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

const useNotificationForm = (
  reviewCycle: ReviewCycle | null | undefined,
  onClose: () => void,
  refresh: () => Promise<void>
) => {
  const [showMessage] = useMessage();

  return useFormik<Pick<ReviewCycle, 'notificationSettings'>>({
    initialValues: {
      notificationSettings: reviewCycle?.notificationSettings || {
        reminderFrequency: ReminderFrequencyValue.None,
      },
    },
    validationSchema: yup.object({
      notificationSettings: yup.object({
        reminderFrequency: yup
          .string()
          .oneOf([ReminderFrequencyValue.Daily, ReminderFrequencyValue.EveryMonday, ReminderFrequencyValue.None])
          .nullable()
          .required('Reminder is required'),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (reviewCycle) {
          await ReviewCycleAPI.updateReviewCycle({
            ...reviewCycle,
            notificationSettings: {
              reminderFrequency: values.notificationSettings.reminderFrequency as ReminderFrequencyValue,
            },
          });
          showMessage('Successfully update the cycle', 'success');
          await refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });
};

export const CycleCreationNotificationsContent = ({
  reviewCycle,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const formik = useNotificationForm(reviewCycle, onClose, refresh);
  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Notifications</Typography>

        <SelectComponent
          name="notificationSettings.reminderFrequency"
          label="Reminder"
          options={ReminderFrequencyOptions}
          value={formik.values.notificationSettings.reminderFrequency}
          compareValue={formik.values.notificationSettings.reminderFrequency}
          error={
            !!formik.errors.notificationSettings?.reminderFrequency &&
            formik.touched.notificationSettings?.reminderFrequency
          }
          onChange={formik.handleChange}
          helperText={
            formik.touched.notificationSettings?.reminderFrequency &&
            Boolean(formik.errors.notificationSettings?.reminderFrequency)
          }
        />

        <SelectComponent
          disabled={true}
          name="message"
          label="Invite message"
          options={InviteMessageOptions}
          value={'default'}
          compareValue={'default'}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
