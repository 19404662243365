import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import useMessage from '@/hooks/notification.hook';
import { ChartColumnSingle } from '@/v2/components/charts/chart-column-single.component';
import { ChartDonut } from '@/v2/components/charts/chart-donut.component';
import { chartBoxSx, childChartSx, parentChartSx } from '@/v2/components/charts/styles.layout';
import { AnalyticsAPI } from '@/v2/feature/analytics/analytics.api';
import {
  GenderOverTimeDto,
  HeadcountByDepartmentDto,
  HeadcountOverTimeDto,
  HiresFiresOverMonthDto,
} from '@/v2/feature/analytics/analytics.dto';
import { AnalyticsDataSeries, QuartileProperties } from '@/v2/feature/analytics/analytics.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface PieChartProps {
  series: number[];
  labels: string[];
}

export const AnalyticsInsightsPeoplePage = () => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [chartProps, setChartProps] = useState<HeadcountOverTimeDto>({
    series: [],
    category: [],
  });

  const [genderOverTime, setGenderOverTime] = useState<GenderOverTimeDto>({
    series: [],
    labels: [],
    totalSeries: [],
    totalLabels: [],
  });

  const [hiresAndFiresMonth, setHiresAndFiresMonth] = useState<HiresFiresOverMonthDto>({
    series: [
      {
        name: 'Joiners',
        data: [],
        tooltip: [],
      },
      {
        name: 'Leavers',
        data: [],
        tooltip: [],
      },
    ],
    months: [],
    categories: [],
    data: [],
  });
  const [headcountDeptChartProps, setHeadcountDeptChartProps] = useState<PieChartProps>({
    series: [],
    labels: [],
  });

  const fetchChartStats = useCallback(async () => {
    //TODO - migrate salary charts to new salary endpoints
    try {
      setLoading(true);
      const [genderOverTime, headcountOverTime, headcountByDepartment, hiresAndFiresOverMonth] = await Promise.all([
        AnalyticsAPI.findGenderOverTime(),
        AnalyticsAPI.getHeadcountOverTime(),
        AnalyticsAPI.findHeadcountByDepartment(),
        AnalyticsAPI.getHiresAndFiresLastMonth(),
      ]);
      setGenderOverTime(genderOverTime);
      setHiresAndFiresMonth(hiresAndFiresOverMonth);
      let series: number[] = [];
      let labels: string[] = [];
      headcountByDepartment.forEach((item: HeadcountByDepartmentDto) => {
        series.push(item.count);
        labels.push(item.department.name);
      });
      setHeadcountDeptChartProps({ series, labels });

      setChartProps(headcountOverTime);
    } catch (err) {
      console.error('err', err);
      showMessage(polyglot.t('AnalyticsInsightsPeoplePage.errorMessages.badRequest'), 'error');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchChartStats();
  }, [fetchChartStats]);

  const customTooltip = ({ seriesIndex, dataPointIndex }: { seriesIndex: any; dataPointIndex: any }) => {
    const yValue: { [x: string]: string[] } = hiresAndFiresMonth?.series[seriesIndex]?.tooltip[dataPointIndex];
    return `<div class='arrow_box'>${Object.values(yValue)?.toString().replaceAll(',', ' <br/>')}</div>`;
  };

  useEffect(() => {
    trackPage('People insights');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTranslatedCategoriesForLeaversJoinersChart = (polyglot: Polyglot, categories?: string[] | undefined) => {
    return categories ? categories.map((category) => polyglot.t(`LeaversJoinersChart.${category}`)) : [];
  };

  const getTranslatedSeriesNamesForLeaversJoinersChart = (
    polyglot: Polyglot,
    leaversJoiners?: QuartileProperties | undefined
  ) => {
    return leaversJoiners?.series
      ? leaversJoiners.series.map((eachSeries) => {
          return { ...eachSeries, name: polyglot.t(`LeaversJoinersChart.${eachSeries.name}`) };
        })
      : [];
  };

  const getMonthAbbreviationsForXAxis = (polyglot: Polyglot, months?: string[] | undefined): string[] => {
    return months ? months.map((month) => polyglot.t(`getMonthAbbreviatedOptions.${month}`)) : [];
  };

  const getTranslatedSeriesNamesForGenderOverTimeChart = (
    polyglot: Polyglot,
    genderOverTimeSeries?: AnalyticsDataSeries[] | undefined
  ) => {
    return genderOverTimeSeries
      ? genderOverTimeSeries.map((eachSeries) => {
          return { ...eachSeries, name: polyglot.t(`GenderOverTimeChart.${eachSeries.name}`) };
        })
      : [];
  };

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('AnalyticsInsightsPeoplePage.insights')}
          </Typography>
        }
      />
      <ContentWrapper loading={loading}>
        <Box>
          <Typography sx={{ ...themeFonts.title2, ...spacing.mb20, color: themeColors.DarkGrey }}>
            {polyglot.t('AnalyticsInsightsPeoplePage.headcount')}
          </Typography>
        </Box>
        <Box sx={chartBoxSx}>
          <Box sx={parentChartSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.lastMonths')}
            </Typography>
            <ChartColumnSingle
              series={chartProps.series}
              categories={getMonthAbbreviationsForXAxis(polyglot, chartProps.category)}
              stacked
            />
          </Box>
          <Box sx={childChartSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.byDep')}
            </Typography>
            <ChartDonut series={headcountDeptChartProps?.series} labels={headcountDeptChartProps?.labels} />
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{ ...themeFonts.title2, ...spacing.mb20, marginTop: spacing.m60, color: themeColors.DarkGrey }}
          >
            {polyglot.t('AnalyticsInsightsPeoplePage.diversity')}
          </Typography>
        </Box>
        <Box sx={chartBoxSx}>
          <Box sx={parentChartSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.gender')}
            </Typography>
            <ChartColumnSingle
              series={getTranslatedSeriesNamesForGenderOverTimeChart(polyglot, genderOverTime.series)}
              categories={getMonthAbbreviationsForXAxis(polyglot, genderOverTime.labels)}
              stacked
              max={100}
              percentage
              min={0}
              tickAmount={5}
            />
          </Box>
          <Box sx={childChartSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.nationality')}
            </Typography>
            <ChartDonut series={genderOverTime.totalSeries} labels={genderOverTime.totalLabels} />
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{ ...themeFonts.title2, ...spacing.mb20, marginTop: spacing.m60, color: themeColors.DarkGrey }}
          >
            {polyglot.t('AnalyticsInsightsPeoplePage.joinersLeavers')}
          </Typography>
        </Box>
        <Box sx={chartBoxSx}>
          <Box sx={parentChartSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.lastMonths')}
            </Typography>
            <ChartColumnSingle
              series={getTranslatedSeriesNamesForLeaversJoinersChart(polyglot, hiresAndFiresMonth)}
              categories={getMonthAbbreviationsForXAxis(polyglot, hiresAndFiresMonth.months)}
              customTooltip={customTooltip}
            />
          </Box>
          <Box sx={childChartSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.lastMonths')}
            </Typography>
            <ChartDonut
              series={hiresAndFiresMonth.data}
              labels={getTranslatedCategoriesForLeaversJoinersChart(polyglot, hiresAndFiresMonth.categories)}
            />
          </Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
