import { useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { IDCheckAPI } from '../id-check.api';
import { IndividualCheck } from '../id-check.interface';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  buttonBoxSx,
  drawerContentSx,
  fieldSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@/v2/styles/spacing.styles';

export const AccountInformationSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee Id is required'),
});

interface Props {
  readonly initialValues: IndividualCheck;
  readonly onClose: () => void;
}

export const EditIndividualCheckForm = ({ initialValues, onClose }: Props): JSX.Element => {
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<IndividualCheck>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      price: Yup.number().required('Price is required'),
    }),
    onSubmit: async (values) => {
      // if (!userBasicsUpdate) return;
      setLoading(true);
      try {
        // TODO return patched data
        await IDCheckAPI.updateIndividualCheck(values);
        onClose();
        // onSubmit(values);
        showMessage('Successfully updated Individual check', 'success');
      } catch (error) {
        // TODO we should improve how we handle halidation error messages
        showMessage(`Failed to update individual check: ${nestErrorMessage(error)}; Please try again.`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box>
          <Typography variant="title2">Price for {formik.values.id}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="price"
                label={'Price'}
                value={formik.values.price}
                onChange={formik.handleChange}
                type="text"
                error={formik.touched.price && !!formik.errors.price}
                helperText={(formik.touched.price && formik.errors.price) ?? ' '}
              />
            </Box>
          </Box>
          <Box sx={buttonBoxSx}>
            <LoaderButton
              name={'Update'}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
