import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { DocumentAPI } from '@v2/feature/documents/document.api';
import { BufferData, PreviewPayload } from '@v2/feature/documents/documents.interface';
import { DocPreviewer } from '@v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { getDocumentTypeLabel, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import {
  USER_ONBOARDING_ABOUT,
  USER_ONBOARDING_DOCUMENTS,
  USER_ONBOARDING_PAYROLL,
  USER_ONBOARDING_TASKS,
} from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TeamSlide, TeamSlideCard } from '@/v2/components/team-slide.component';
import { ProfileModal } from '@/v2/components/theme-components/profile-modal.component';
import { TaskDto } from '@/v2/feature/task/task.dto';
import { ContractObject, SignatoryType } from '@/v2/feature/templates/templates.interface';
import { UserProfileBar } from '@/v2/feature/user/components/user-profile-bar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserLifecycleStatuses } from '@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface';
import { OnboardingContracts } from '@/v2/feature/user-onboarding/onboarding-by-user/components/onboarding-contracts/onboarding-contracts.component';
import { OnboardingActionCard } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component';
import { OnboardingUserStats } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-user-stats.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type OnboardingOverviewProps = {
  companyName: string;
  progressPercent: number;
  taskCount: number;
  requiredSteps: OnboardingStep[];
  missingFields: OnboardingMissingFields;
  documents: ZeltDocument[];
  documentTypes: ZeltDocumentType[];
  pendingContracts: ZeltDocument[];
  incompleteTasks: TaskDto[];
  checklistNames: string[];
  startDate?: string;
  sx?: SxProps<Theme>;
  userId: number;
};

export const OnboardingOverview = ({
  companyName,
  progressPercent,
  requiredSteps,
  missingFields,
  documents,
  documentTypes,
  pendingContracts,
  incompleteTasks,
  checklistNames,
  startDate,
  sx,
  taskCount,
  userId,
}: OnboardingOverviewProps) => {
  const { polyglot, useInitLanguage } = usePolyglot();
  const [showMessage] = useMessage();
  useInitLanguage();
  const router = useHistory();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const { trackPage } = useJune();
  const [contractToSign, setContractToSign] = useState<ContractObject | null>(null);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedUserId, setSetSelectedUserId] = useState<number | null>(null);

  // for document preview
  const [selectedDocBuffer, setSelectedDocBuffer] = useState<BufferData | null>();
  const [selectedDocName, setSelectedDocName] = useState('');
  const [selectedDocContentType, setSelectedDocContentType] = useState<string>('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [loadingStates, setLoadingStates] = useState<Record<number, boolean>>({});

  const cards = useMemo(
    () =>
      [...companyUsers]
        .filter((u) => u.userEvent?.status === UserLifecycleStatuses.Employed)
        // deliberately display the users in a random order
        .sort(() => 0.5 - Math.random())
        .map<TeamSlideCard>((user) => {
          return {
            user,
            cardProps: {
              subTitle: user.role?.jobTitle,
            },
            action: () => {
              setIsOpen(true);
              setSetSelectedUserId(user.userId);
            },
          };
        }),
    [companyUsers]
  );

  const missingDocuments = useMemo(() => documents.filter((d) => !d.canAllEmployeesSee && !d.attachments?.length), [
    documents,
  ]);

  const pendingContractForStep = useCallback(
    (step: number): ZeltDocument | undefined => {
      switch (step) {
        case 1:
          return pendingContracts?.length > 0 && pendingContracts[0] ? pendingContracts[0] : undefined;
        case 2:
          return pendingContracts?.length > 0 && pendingContracts[1] ? pendingContracts[1] : undefined;
        case 3:
          return pendingContracts?.length > 0 && pendingContracts[2] ? pendingContracts[2] : undefined;
        case 4:
          return pendingContracts?.length > 0 && pendingContracts[3] ? pendingContracts[3] : undefined;
        case 5:
          return pendingContracts?.length > 0 && pendingContracts[4] ? pendingContracts[4] : undefined;
      }
    },
    [pendingContracts]
  );

  const handleContractSignAction = useCallback(
    (step: number) => {
      const pc = pendingContractForStep(step);
      if (pc && pc.contract) {
        setContractToSign(pc.contract);
        setOpenMissingFieldContractModal(true);
      }
    },
    [pendingContractForStep, setContractToSign]
  );

  const pendingContractRequiresAdditional = useCallback(
    (step: number): boolean => {
      const pc = pendingContractForStep(step);
      const requiresSignaturesFromAdditional =
        pc?.contract &&
        pc.contract.signatoriesRequired &&
        pc.contract.signatoriesRequired.includes(SignatoryType.additional);

      return Boolean(requiresSignaturesFromAdditional && !pc?.contract?.companySignatureTimestamp);
    },
    [pendingContractForStep]
  );

  const pendingContractIsFinalised = useCallback(
    (step: number): boolean => {
      const pc = pendingContractForStep(step);
      const contractIsMissingInformation = pc?.contract?.hasMissingPersonalFields || pc?.contract?.hasMissingWorkFields;
      const requiresSignaturesFromRecipient =
        pc?.contract &&
        pc.contract.signatoriesRequired &&
        pc.contract.signatoriesRequired.includes(SignatoryType.recipient);

      return (
        (!requiresSignaturesFromRecipient ||
          !!(pc?.contract && pc?.contract.recipientSignatureTimestamp && pc?.contract.recipient)) &&
        !contractIsMissingInformation
      );
    },
    [pendingContractForStep]
  );

  const noSignaturesButMissingInfo = useCallback(
    (step: number) => {
      const pc = pendingContractForStep(step);
      const contractIsMissingInformation = pc?.contract?.hasMissingPersonalFields || pc?.contract?.hasMissingWorkFields;
      const requiresSignaturesFromRecipient =
        pc?.contract &&
        pc.contract.signatoriesRequired &&
        pc.contract.signatoriesRequired.includes(SignatoryType.recipient);
      return !requiresSignaturesFromRecipient && contractIsMissingInformation;
    },
    [pendingContractForStep]
  );

  const getContractChipsForStep = useCallback(
    (step: number): ReactNode[] | undefined => {
      const pc = pendingContractForStep(step);
      if (pc) return [pc.name];
      return undefined;
    },
    [pendingContractForStep]
  );

  const handlePreviewClick = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        const fileUuidFromAttachment =
          zeltDocument.attachments && zeltDocument.attachments[0] && zeltDocument.attachments[0]?.fileUuid;
        const finalUuid = zeltDocument.fileUuid ?? fileUuidFromAttachment;

        if (!finalUuid) return;
        await DocumentAPI.previewViaUuid(finalUuid).then(async ({ contentType, file }: PreviewPayload) => {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        });
      } catch (e) {
        console.error('::URL Download error', e);
        showMessage(polyglot.t('DocumentTable.errorMessages.preview', { errorMessage: nestErrorMessage(e) }), 'error');
      }
    },
    [polyglot, showMessage]
  );

  const getOnboardingActionCardForContractByStep = useCallback(
    (step: number) => {
      const contractDoc = pendingContractForStep(step);
      return (
        <OnboardingActionCard
          label={polyglot.t('OnboardingOverview.contract')}
          completed={pendingContractIsFinalised(step)}
          chips={getContractChipsForStep(step)}
          actionButton={
            contractDoc && !pendingContractIsFinalised(step) ? (
              <ButtonComponent
                onClick={() => handleContractSignAction(step)}
                sizeVariant="small"
                colorVariant="primary"
                fullWidth
              >
                {noSignaturesButMissingInfo(step)
                  ? polyglot.t('DocumentTable.fillMissingFields')
                  : polyglot.t('OnboardingOverview.sign')}
              </ButtonComponent>
            ) : undefined
          }
          completedButton={
            contractDoc && !pendingContractRequiresAdditional(step) ? (
              <ButtonComponent
                onClick={async () => {
                  try {
                    setLoadingStates((prevLoadingStates) => ({
                      ...prevLoadingStates,
                      [step]: true,
                    }));
                    await handlePreviewClick(contractDoc);
                  } finally {
                    setLoadingStates((prevLoadingStates) => ({
                      ...prevLoadingStates,
                      [step]: false,
                    }));
                  }
                }}
                sizeVariant="small"
                colorVariant="secondary"
                fullWidth
                loading={loadingStates[step] ?? false}
              >
                View
              </ButtonComponent>
            ) : undefined
          }
        />
      );
    },
    [
      getContractChipsForStep,
      handleContractSignAction,
      handlePreviewClick,
      loadingStates,
      noSignaturesButMissingInfo,
      pendingContractForStep,
      pendingContractIsFinalised,
      pendingContractRequiresAdditional,
      polyglot,
    ]
  );

  const actionCards = useMemo(() => {
    return requiredSteps.map((step) =>
      ({
        basic: () => (
          <OnboardingActionCard
            label={polyglot.t('OnboardingOverview.completeProfile')}
            completed={missingFields.basic.length === 0}
            onStartClick={() => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId }))}
            showEdit={missingFields.basic.length === 0}
            onEditClick={() => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId }))}
          />
        ),
        benefits: () => <OnboardingActionCard label={polyglot.t('OnboardingOverview.selectBenefits')} />,
        documents: () => (
          <OnboardingActionCard
            label={polyglot.t('OnboardingOverview.uploadDocuments')}
            completed={missingDocuments.length === 0}
            onStartClick={() => router.push(generatePath(USER_ONBOARDING_DOCUMENTS, { userId }))}
            chips={missingDocuments.map((d) => getDocumentTypeLabel(d, documentTypes))}
          />
        ),
        payroll: () => (
          <OnboardingActionCard
            label={polyglot.t('OnboardingOverview.completePayroll')}
            completed={missingFields.payroll.length === 0}
            onStartClick={() => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId }))}
            showEdit={missingFields.payroll.length === 0}
            onEditClick={() => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId }))}
          />
        ),
        tasks: () => (
          <OnboardingActionCard
            label={polyglot.t('OnboardingOverview.tasks')}
            completed={incompleteTasks.length === 0}
            onStartClick={() => router.push(generatePath(USER_ONBOARDING_TASKS, { userId }))}
            chips={checklistNames}
          />
        ),
        contracts: () => getOnboardingActionCardForContractByStep(1),
        contracts_step2: () => getOnboardingActionCardForContractByStep(2),
        contracts_step3: () => getOnboardingActionCardForContractByStep(3),
        contracts_step4: () => getOnboardingActionCardForContractByStep(4),
        contracts_step5: () => getOnboardingActionCardForContractByStep(5),
      }[step]())
    );
  }, [
    getOnboardingActionCardForContractByStep,
    checklistNames,
    polyglot,
    documentTypes,
    incompleteTasks.length,
    missingDocuments,
    missingFields.basic.length,
    missingFields.payroll.length,
    requiredSteps,
    router,
    userId,
  ]);

  useEffect(() => {
    trackPage('User onboarding');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{ flexFlow: 'row', gap: '140px', ...sx }}>
      <Stack sx={{ flex: '0 0', maxWidth: '470px' }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingOverview.getStarted')}
        </Typography>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, textAlign: 'justify', color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingOverview.welcomeMessage', { companyName: companyName })}
        </Typography>
        {cards && <TeamSlide cards={cards} title="Your colleagues" sx={{ mt: spacing.mt40 }} width={470} />}
      </Stack>
      <Stack sx={{ flex: 1, maxWidth: '1000px' }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingOverview.onboardingTasks')}
        </Typography>
        <OnboardingUserStats
          progressPercent={progressPercent}
          startDate={startDate}
          pendingTaskCount={taskCount}
          sx={{ mt: spacing.mt20, mb: spacing.mb5 }}
        />
        <Stack sx={{ mt: spacing.m25 }}>
          {actionCards.map((card, idx) => (
            <React.Fragment key={`action-card-${idx}`}>
              {idx > 0 && <div style={{ height: 1, backgroundColor: themeColors.lightGrey }} />}
              {card}
            </React.Fragment>
          ))}
        </Stack>

        {selectedDocBuffer && openPreviewModal && (
          <DocPreviewer
            fileBuffer={selectedDocBuffer}
            contentType={selectedDocContentType}
            visible
            onClose={() => setOpenPreviewModal(false)}
            title={selectedDocName}
          />
        )}

        <ProfileModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          {selectedUserId ? <UserProfileBar userId={selectedUserId} /> : undefined}
        </ProfileModal>

        {contractToSign && (
          <OnboardingContracts
            selectedContract={contractToSign}
            setOpenModal={setOpenMissingFieldContractModal}
            openModal={openMissingFieldContractModal}
          />
        )}
      </Stack>
    </Stack>
  );
};
