import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, PaginationState, Row } from '@tanstack/react-table';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortDate, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OffboardingDrawer } from '@/v2/feature/offboarding/components/offboarding-drawer.component';
import { OffboardingAPI } from '@/v2/feature/offboarding/offboarding.api';
import {
  OffboardingProgressInterface,
  ProgressStatus,
  TimeSegment,
} from '@/v2/feature/offboarding/offboarding.interface';
import { getStatusToIcon } from '@/v2/feature/offboarding/offboarding.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const OffboardingPage = () => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { trackPage } = useJune();
  const routerHistory = useHistory();
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>(TimeSegment.All);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [offboardingModalOpen, setOffboardingModalOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<OffboardingProgressInterface[] | undefined>(undefined);
  const [showMessage] = useMessage();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [totalPages, setTotalpages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    trackPage('Offboarding admin page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffboardingUsers = useCallback(async () => {
    setUserLoading(true);
    try {
      const { users, pagination: paginationInfo } = await OffboardingAPI.getOffboardingListing({
        page: pageIndex.toString(),
        pageSize: pageSize.toString(),
        searchQuery: searchInput,
        filter: filterString,
      });
      const { totalPages, totalCount } = paginationInfo;
      setTotalpages(totalPages);
      setTotalItems(totalCount);
      setUsers(users);
    } catch (error) {
      showMessage(
        polyglot.t('OffboardingPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setUserLoading(false);
    }
  }, [polyglot, pageIndex, pageSize, searchInput, filterString, showMessage]);

  useEffect(() => {
    getOffboardingUsers();
  }, [getOffboardingUsers]);

  const currentUserColumn = useMemo<ColumnDef<OffboardingProgressInterface, OffboardingProgressInterface>[]>(
    () => [
      {
        header: () => polyglot.t('OffboardingPage.name'),
        accessorFn: (row) => row,
        id: 'firstName',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => (original.userId ? <UserCell userId={original.userId} /> : <EmptyCell />),
        maxSize: 180,
        minSize: 180,
      },
      {
        header: () => polyglot.t('OffboardingPage.offboardedBy'),
        accessorFn: (row) => row,
        id: 'offboardedBy',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => (item.offboardedBy ? getCachedUserById(item.offboardedBy)?.displayName : '')),
        cell: ({ row: { original } }) =>
          original.offboardedBy ? <UserCell userId={original.offboardedBy} /> : <EmptyCell />,
        maxSize: 180,
        minSize: 180,
      },
      {
        header: () => polyglot.t('OffboardingPage.terminationDate'),
        accessorFn: (row) => row,
        id: 'terminationDate',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => new Date(item?.terminationDate)),
        cell: ({ row: { original } }) =>
          original.terminationDate ? (
            <Box>
              <Typography variant="caption">{getDateString(original.terminationDate)}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
        maxSize: 150,
        minSize: 100,
      },
      {
        header: () => polyglot.t('OffboardingPage.deactivationDate'),
        accessorFn: (row) => row,
        id: 'deactivationDate',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.deactivationDate && new Date(item.deactivationDate)),
        cell: ({ row: { original } }) => (
          <Box>
            {original.deactivationDate ? (
              <Typography variant="caption">{getDateString(original.deactivationDate)}</Typography>
            ) : (
              getStatusToIcon(ProgressStatus.Missing)
            )}
          </Box>
        ),
        maxSize: 150,
        minSize: 100,
      },
    ],
    [polyglot, getCachedUserById]
  );

  const handleRowClick = useCallback(
    (row: Row<OffboardingProgressInterface>) => {
      routerHistory.push({ pathname: generatePath(USER_OFFBOARDING_ROUTE, { userId: row.original.userId }) });
    },
    [routerHistory]
  );

  const debouncedFilter = useDebouncedCallback((filterString: string) => {
    try {
      setFilterString(filterString);
      // Resets pagination index once a new filter is applied
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage(polyglot.t('OffboardingPage.errorMessages.filter'), 'error');
    }
  }, 500);

  const handleFilter = useCallback((value: string) => debouncedFilter.callback(value), [debouncedFilter]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  return (
    <RootStyle>
      <TopHeader
        title={polyglot.t('OffboardingPage.offboarding')}
        actions={
          <ButtonComponent onClick={() => setOffboardingModalOpen(true)} sizeVariant="small" colorVariant="primary">
            {polyglot.t('OffboardingPage.offboard')}
          </ButtonComponent>
        }
        showAction={true}
      />
      <ContentWrapper loading={false} sx={{}}>
        <Box>
          <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
            <TabFilterButtons
              filters={[
                { name: TimeSegment.All, value: TimeSegment.All },
                { name: TimeSegment.Upcoming, value: TimeSegment.Upcoming },
                { name: TimeSegment.Past, value: TimeSegment.Past },
              ]}
              filterValue={filterString}
              setFilterValue={handleFilter}
            />
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value?.trim() ?? '');
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
            />
          </Box>

          <Box sx={{ ...spacing.mt20 }}>
            <BasicServerTable
              rowData={users ? [...users] : []}
              columnData={currentUserColumn}
              loading={userLoading}
              rowClick={handleRowClick}
              pagination={pagination}
              setPagination={setPagination}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          </Box>
        </Box>

        <OffboardingDrawer userId={undefined} isOpen={offboardingModalOpen} setIsOpen={setOffboardingModalOpen} />
      </ContentWrapper>
    </RootStyle>
  );
};
