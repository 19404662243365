import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/Action.svg';
import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ParticipantsAddReviewerModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-add-reviewer-modal.component';
import { ParticipantsDeleteRevieweeModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-delete-reviewee-modal.component';
import { ParticipantsRemoveReviewerModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-remove-reviewer-modal.component';
import {
  ReviewCycle,
  ReviewParticipants,
  ReviewerTypes,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { iconSize } from '@/v2/styles/menu.styles';

interface IParticipantTableProps {
  readonly reviewCycle: ReviewCycle | null | undefined;
  readonly reviewParticipants: ReviewParticipants[] | null | undefined;
  readonly reviewParticipantsLoading: boolean | undefined;
  readonly refresh: () => Promise<void>;
  readonly isEditable: boolean;
}

interface ActionMenuProps {
  original: ReviewParticipants;
  setIsReviewerAddOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedReviewee: React.Dispatch<React.SetStateAction<ReviewParticipants | undefined>>;
  setIsReviewerDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteParticipants: (selectionModel: number[]) => Promise<void>;
}
export const ParticipantsSharedTable = ({
  reviewCycle,
  reviewParticipants,
  reviewParticipantsLoading,
  refresh,
  isEditable,
}: IParticipantTableProps) => {
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [isRevieweeDeleteOpen, setIsRevieweeDeleteOpen] = useState<boolean>(false);
  const [isReviewerAddOpen, setIsReviewerAddOpen] = useState<boolean>(false);
  const [isReviewerDeleteOpen, setIsReviewerDeleteOpen] = useState<boolean>(false);
  const [selectedReviewee, setSelectedReviewee] = useState<ReviewParticipants | undefined>(undefined);

  const [showMessage] = useMessage();
  const showAction = useMemo(
    () =>
      reviewCycle &&
      (reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer) ||
        reviewCycle.reviewerSelect?.includes(ReviewerTypes.Upward) ||
        reviewCycle.reviewerSelect?.includes(ReviewerTypes.Manager)) &&
      isEditable,
    [reviewCycle, isEditable]
  );
  const deleteParticipants = useCallback(
    async (selectionModel: number[]) => {
      try {
        if (reviewCycle && [CycleState.Draft, CycleState.Scheduled].includes(reviewCycle.state)) {
          await ReviewCycleAPI.deleteRevieweesToPreOngoing(reviewCycle.id, selectionModel);
          setSelectionModel([]);
          showMessage('Successfully deleted the participants', 'success');
          await refresh();
        }

        if (reviewCycle?.state === CycleState.Ongoing) {
          setIsRevieweeDeleteOpen(true);
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [reviewCycle, refresh, setSelectionModel, showMessage]
  );

  const tableColumns = useMemo<ColumnDef<ReviewParticipants, ReviewParticipants>[]>(
    () => [
      ...(isEditable
        ? [
            {
              id: 'select',
              enableSorting: false,
              minSize: 15,
              maxSize: 15,
              header: () => {
                const displayedSelfId = new Set(reviewParticipants?.map(({ selfId }) => selfId));
                const allSelected =
                  selectionModel.length > 0 &&
                  selectionModel.length === displayedSelfId.size &&
                  selectionModel.every((id) => displayedSelfId.has(id));
                return (
                  <Box sx={{ display: 'initial' }} onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name={'allSelected'}
                      checked={allSelected}
                      value="allSelceted"
                      onChange={(_, checked) => {
                        setSelectionModel(checked ? [...displayedSelfId] : []);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }: { row: Row<ReviewParticipants> }) => (
                <Box onClick={(e) => e.stopPropagation()}>
                  <CheckboxComponent
                    label={undefined}
                    name={original.selfId?.toString()}
                    checked={selectionModel.includes(original.selfId)}
                    value={original.selfId?.toString()}
                    onChange={() => {
                      let finalArray = [];
                      if (selectionModel?.includes(original.selfId)) {
                        finalArray = selectionModel.filter((sm) => sm !== original.selfId);
                      } else finalArray = [...selectionModel, original.selfId];
                      setSelectionModel(finalArray);
                    }}
                  />
                </Box>
              ),
            },
          ]
        : []),
      {
        header: () => 'Participants',
        accessorFn: (row) => row,
        id: 'participants',
        enableSorting: false,

        cell: ({
          row: {
            original: { selfId },
          },
        }) => (selfId ? <UserCell userId={selfId} key={selfId} /> : <EmptyCell />),
        minSize: 160,
        maxSize: 220,
      },
      ...(reviewCycle && reviewCycle.reviewerSelect?.includes(ReviewerTypes.Manager)
        ? [
            {
              header: () => 'Manager',
              accessorFn: (row: Row<ReviewParticipants>) => row,
              id: 'managerId',
              enableSorting: false,

              cell: ({
                row: {
                  original: { managerIds },
                },
              }: {
                row: Row<ReviewParticipants>;
              }) => (managerIds ? <MultiUserAvatar userIds={managerIds} showLimit={3} /> : <EmptyCell />),
              minSize: 140,
              maxSize: 200,
            },
          ]
        : []),
      ...(reviewCycle && reviewCycle.reviewerSelect?.includes(ReviewerTypes.Upward)
        ? [
            {
              header: () => 'Upwards',
              accessorFn: (row: Row<ReviewParticipants>) => row,
              id: 'upwards',
              enableSorting: false,

              cell: ({
                row: {
                  original: { upwardIds },
                },
              }: {
                row: Row<ReviewParticipants>;
              }) => (upwardIds ? <MultiUserAvatar userIds={upwardIds} showLimit={3} /> : <EmptyCell />),
              minSize: 140,
              maxSize: 200,
            },
          ]
        : []),
      ...(reviewCycle && reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer)
        ? [
            {
              header: () => 'Peers',
              accessorFn: (row: Row<ReviewParticipants>) => row,
              id: 'peers',
              enableSorting: false,

              cell: ({
                row: {
                  original: { peerIds },
                },
              }: {
                row: Row<ReviewParticipants>;
              }) =>
                Boolean(reviewCycle?.cycleSettings?.allowRevieweesToInvitePeers) ? (
                  <div>Nominated by participant</div>
                ) : peerIds ? (
                  <MultiUserAvatar userIds={peerIds} showLimit={3} />
                ) : (
                  <EmptyCell />
                ),
              minSize: 140,
              maxSize: 200,
            },
          ]
        : []),

      ...(showAction
        ? [
            {
              id: 'actions',
              header: () => '',
              accessorFn: (row: Row<ReviewParticipants>) => row,
              enableSorting: false,
              cell: ({ row: { original } }: { row: Row<ReviewParticipants> }) =>
                original.isEditable ? (
                  <ActionMenu
                    original={original}
                    setIsReviewerAddOpen={setIsReviewerAddOpen}
                    setSelectedReviewee={setSelectedReviewee}
                    setIsReviewerDeleteOpen={setIsReviewerDeleteOpen}
                    deleteParticipants={deleteParticipants}
                  />
                ) : (
                  <></>
                ),
            },
          ]
        : []),
    ],
    [
      reviewCycle,
      reviewParticipants,
      selectionModel,
      setSelectionModel,
      showAction,
      setIsReviewerAddOpen,
      setSelectedReviewee,
      setIsReviewerDeleteOpen,
      isEditable,
      deleteParticipants,
    ]
  );

  if (!reviewCycle) return <></>;

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <StyledMenuComponent
          options={[
            {
              handler: async () => deleteParticipants(selectionModel),
              label: 'Remove participants',
              disabled: false,
            },
          ]}
          sx={{
            visibility: isEditable && selectionModel.length > 0 ? 'visible' : 'hidden',
            opacity: selectionModel.length > 0 ? 1 : 0,
            transition: 'opacity 0.3s ease, visibility 0.3s ease',
          }}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Actions',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />
      </Box>
      <BasicTable rowData={reviewParticipants ?? []} columnData={tableColumns} loading={reviewParticipantsLoading} />

      <ParticipantsDeleteRevieweeModal
        reviewCycle={reviewCycle}
        toDeleteRevieweeIds={selectionModel}
        isOpen={isRevieweeDeleteOpen}
        setIsOpen={setIsRevieweeDeleteOpen}
        onClose={() => {
          setIsRevieweeDeleteOpen(false);
          setSelectionModel([]);
        }}
        refresh={refresh}
      />

      <ParticipantsAddReviewerModal
        reviewCycle={reviewCycle}
        selectedReviewee={selectedReviewee}
        isOpen={isReviewerAddOpen}
        setIsOpen={setIsReviewerAddOpen}
        onClose={() => setIsReviewerAddOpen(false)}
        refresh={refresh}
      />

      <ParticipantsRemoveReviewerModal
        reviewCycle={reviewCycle}
        selectedReviewee={selectedReviewee}
        isOpen={isReviewerDeleteOpen}
        setIsOpen={setIsReviewerDeleteOpen}
        onClose={() => setIsReviewerDeleteOpen(false)}
        refresh={refresh}
      />
    </Box>
  );
};

const ActionMenu = ({
  original,
  setIsReviewerAddOpen,
  setSelectedReviewee,
  setIsReviewerDeleteOpen,
  deleteParticipants,
}: ActionMenuProps) => {
  const getActionsOptions = useCallback(() => {
    const menuOptions = [
      {
        icon: <Trash {...iconSize} />,
        handler: async () => {
          await deleteParticipants([original.selfId]);
        },
        label: 'Remove participant',
        disabled: false,
      },
      {
        icon: <Plus {...iconSize} />,
        handler: () => {
          setIsReviewerAddOpen(true);
          setSelectedReviewee(original);
        },
        label: 'Add reviewers',
        disabled: false,
      },
    ];
    if (
      (original.peerIds && original.peerIds.length > 0) ||
      (original.managerIds && original.managerIds.length > 0) ||
      (original.upwardIds && original.upwardIds.length > 0)
    ) {
      menuOptions.push({
        icon: <Trash {...iconSize} />,
        handler: () => {
          setIsReviewerDeleteOpen(true);
          setSelectedReviewee(original);
        },
        label: 'Remove reviewers',
        disabled: false,
      });
    }

    return menuOptions;
  }, [setIsReviewerAddOpen, setSelectedReviewee, setIsReviewerDeleteOpen, original, deleteParticipants]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <StyledMenuComponent
        options={getActionsOptions()}
        actionButtonDetails={{
          type: 'iconButton',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: 'actions',
          icon: <ActionsSmall {...iconSize} />,
        }}
      />
    </div>
  );
};
