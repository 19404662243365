import { Box, Stack } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { spacing } from '@/v2/styles/spacing.styles';

type GeneralPageProps = {
  initialValues?: { name: string; description: string };
  onNext: (values: { name: string; description: string }) => void;
};

export const GeneralPage = ({ initialValues, onNext }: GeneralPageProps) => {
  const { polyglot } = usePolyglot();

  const formik = useFormik({
    initialValues: {
      name: initialValues?.name ?? '',
      description: initialValues?.description ?? '',
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t('GeneralPage.nameRequired')),
      description: yup.string().notRequired(),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      onNext(values);
    },
  });

  return (
    <Stack sx={{ flex: 1, alignItems: 'center', width: '100%' }}>
      <FormikProvider value={formik}>
        <Form>
          <Stack sx={{ width: '450px', gap: spacing.g30 }}>
            <Typography variant="title2">{polyglot.t('GeneralPage.general')}</Typography>
            <TextfieldComponent
              name="name"
              label={polyglot.t('GeneralPage.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('name', '')}
              maxLength={255}
              autoFocus
              helperText={formik.submitCount > 0 && formik.errors.name}
              error={formik.submitCount > 0 && !!formik.errors.name}
            />
            <TextfieldComponent
              name="description"
              label={polyglot.t('GeneralPage.description')}
              multiline
              maxLength={1000}
              value={formik.values.description}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('description', '')}
              helperText={formik.submitCount > 0 && formik.errors.description}
              error={formik.submitCount > 0 && !!formik.errors.description}
            />
          </Stack>
          <Box sx={{ mt: spacing.mt40 }}>
            {formik.isValid && (
              <ButtonComponent sizeVariant="large" colorVariant="primary" fullWidth>
                {polyglot.t('General.continue')}
              </ButtonComponent>
            )}
          </Box>
        </Form>
      </FormikProvider>
    </Stack>
  );
};
