import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormDto } from '@v2/feature/custom-fields/custom-profile-fields.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserEmergencyContactDto } from '@/v2/feature/user/features/user-forms/user-emergency-contact/user-emergency-contact.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { spacing } from '@/v2/styles/spacing.styles';

type UserEmergencyContactSectionProps = {
  form: CustomProfileFormDto | undefined;
  onNext: (contact: Partial<UserEmergencyContactDto>) => void;
  sx?: SxProps<Theme>;
  emergencyValues: Partial<UserEmergencyContactDto> | null;
  userId: number;
};

type EmergencyFormDefaultVisibility = {
  emergencyName: boolean;
  emergencyNumber: boolean;
  emergencyRelationship: boolean;
};

const UserEmergencyContactSchema = (visibility: EmergencyFormDefaultVisibility, polyglot: Polyglot) =>
  Yup.object().shape({
    emergencyName: visibility.emergencyName
      ? Yup.string().trim().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().notRequired(),
    emergencyNumber: visibility.emergencyNumber
      ? Yup.string().trim().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().notRequired(),
    emergencyRelationship: visibility.emergencyRelationship
      ? Yup.string().trim().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().notRequired(),
  });

export const UserEmergencyContactSection = ({
  onNext,
  sx,
  emergencyValues,
  userId,
  form,
}: UserEmergencyContactSectionProps) => {
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();

  const defaultFieldsVisibility: EmergencyFormDefaultVisibility = useMemo(() => {
    if (!form)
      return {
        emergencyName: false,
        emergencyNumber: false,
        emergencyRelationship: false,
      };

    const emergencyNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'user.emergencyName');
    const emergencyNumberField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'user.emergencyNumber');
    const emergencyRelationshipField = form.fields?.find(
      (f) => f.isDefault && f.fieldStub === 'user.emergencyRelationship'
    );

    return {
      emergencyName: Boolean(emergencyNameField && !emergencyNameField.isHidden),
      emergencyNumber: Boolean(emergencyNumberField && !emergencyNumberField.isHidden),
      emergencyRelationship: Boolean(emergencyRelationshipField && !emergencyRelationshipField.isHidden),
    };
  }, [form]);

  const initialValues = useMemo(() => {
    const values: Partial<UserEmergencyContactDto> = {};
    if (defaultFieldsVisibility.emergencyName) values.emergencyName = emergencyValues?.emergencyName ?? '';
    if (defaultFieldsVisibility.emergencyNumber) values.emergencyNumber = emergencyValues?.emergencyNumber ?? '';
    if (defaultFieldsVisibility.emergencyRelationship)
      values.emergencyRelationship = emergencyValues?.emergencyRelationship ?? '';
    values.customUpdates = emergencyValues?.customUpdates ?? [];

    return values;
  }, [defaultFieldsVisibility, emergencyValues]);

  const formik = useFormik<Partial<UserEmergencyContactDto>>({
    initialValues: initialValues,
    validationSchema: UserEmergencyContactSchema(defaultFieldsVisibility, polyglot),
    onSubmit: async (values) => {
      setSaving(true);
      try {
        await UserAPI.patchUserEmergencyContactInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.emergencyContactNotSaved', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('EmergencyContactForm.emergencyContact')}</Typography>

          {defaultFieldsVisibility.emergencyName && (
            <TextfieldComponent
              name="emergencyName"
              label={polyglot.t('EmergencyContactForm.fullName')}
              value={formik.values.emergencyName}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('emergencyName', '')}
              helperText={hasSubmitted && formik.errors.emergencyName}
              error={hasSubmitted && !!formik.errors.emergencyName}
              autoFocus
            />
          )}

          {defaultFieldsVisibility.emergencyNumber && (
            <TextfieldComponent
              name="emergencyNumber"
              label={polyglot.t('EmergencyContactForm.phone')}
              type="tel"
              value={formik.values.emergencyNumber}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('emergencyNumber', '')}
              helperText={hasSubmitted && formik.errors.emergencyNumber}
              error={hasSubmitted && !!formik.errors.emergencyNumber}
            />
          )}

          {defaultFieldsVisibility.emergencyRelationship && (
            <TextfieldComponent
              name="emergencyRelationship"
              label={polyglot.t('EmergencyContactForm.relationship')}
              value={formik.values.emergencyRelationship}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('emergencyRelationship', '')}
              helperText={hasSubmitted && formik.errors.emergencyRelationship}
              error={hasSubmitted && !!formik.errors.emergencyRelationship}
            />
          )}

          <CustomFieldComponents
            values={formik.values.customUpdates ?? []}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            disabled={saving}
            fieldSx={{}}
          />

          <LoaderButton
            name={polyglot.t('General.continue')}
            sizeVariant="large"
            colorVariant="primary"
            loading={saving}
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
