import React from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface ButtonNameLoaderProps {
  name: string;
  loading: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
  sx?: SxProps<Theme>;
}

export const ButtonNameLoader = ({ name, loading, color = 'inherit', sx = {} }: ButtonNameLoaderProps): JSX.Element => {
  return loading ? (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress color={color} size={25} />
    </Box>
  ) : (
    <Typography align="center" variant="button" sx={{ ...sx, textTransform: 'none' }}>
      {name}
    </Typography>
  );
};
