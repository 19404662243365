import { useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MissingFieldsDetail } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { EquityForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-equity-form.component';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshData?: () => Promise<void>;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingEquity = ({ userId, refreshData, missingFieldsForCategory }: Props) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Stack sx={{ flexFlow: 'column' }}>
        <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
          <Typography sx={themeFonts.caption}>{polyglot.t('MissingEquity.info')}</Typography>
          <IconButton onClick={() => setIsModalOpen(true)} sx={{ ...secondaryCTABtn, p: '7px', ml: 'auto' }}>
            <Plus width="16px" height="16px" />
          </IconButton>
        </Stack>
        {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      </Stack>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        {refreshData ? (
          <EquityForm
            initialValues={undefined}
            userId={userId}
            rowModalMode={'add'}
            handleSubmit={() => refreshData?.()}
            onClose={() => setIsModalOpen(false)}
            setFormCurrentlyEditing={() => {}}
            setIsModalOpen={setIsModalOpen}
            loading={loading}
            setLoading={setLoading}
            refreshUserEquityData={() => refreshData?.()}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DrawerModal>
    </>
  );
};
