import React from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { BenefitsQuoteData } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { Typography } from '@/v2/components/typography/typography.component';

interface InsuranceSetupQuoteStepProps {
  readonly name: string;
  readonly onNext: () => void;
  readonly setQuoteData: React.Dispatch<React.SetStateAction<BenefitsQuoteData>>;
}

export const InsuranceSetupQuoteStep = ({ name, onNext, setQuoteData }: InsuranceSetupQuoteStepProps) => {
  const { polyglot } = usePolyglot();

  const formik = useFormik<BenefitsQuoteData>({
    initialValues: {
      outPatientTreatment: false,
      therapies: false,
      mentalHealth: false,
      dentalCare: false,
      opticalCare: false,
      travelCover: false,
      additionalNotes: null,
      displayName: name,
      selectedUsers: [],
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      outPatientTreatment: yup.boolean().notRequired(),
      therapies: yup.boolean().notRequired(),
      mentalHealth: yup.boolean().notRequired(),
      dentalCare: yup.boolean().notRequired(),
      opticalCare: yup.boolean().notRequired(),
      travelCover: yup.boolean().notRequired(),
      displayName: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      additionalNotes: yup.string().nullable().notRequired(),
    }),
    onSubmit: (values: BenefitsQuoteData) => {
      setQuoteData(values);
      onNext();
    },
  });

  const isContinueEnabled = (): boolean => {
    return (
      formik.values.outPatientTreatment ||
      formik.values.therapies ||
      formik.values.mentalHealth ||
      formik.values.dentalCare ||
      formik.values.opticalCare ||
      formik.values.travelCover
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('BenefitModule.requestAQuote')}
          </Typography>

          <Typography variant="caption" sx={{ mt: spacing.mt20, mb: spacing.mb30, color: themeColors.DarkGrey }}>
            {polyglot.t('BenefitModule.requestAQuoteDesc')}
          </Typography>

          <Box sx={{ mb: spacing.mb20 }}>
            <CheckboxComponent
              name="outPatientTreatment"
              label={polyglot.t('BenefitModule.outPatientTreatment')}
              checked={formik.values.outPatientTreatment}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ mb: spacing.mb20 }}>
            <CheckboxComponent
              name="therapies"
              label={polyglot.t('BenefitModule.therapies')}
              checked={formik.values.therapies}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ mb: spacing.mb20 }}>
            <CheckboxComponent
              name="mentalHealth"
              label={polyglot.t('BenefitModule.mentalHealth')}
              checked={formik.values.mentalHealth}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ mb: spacing.mb20 }}>
            <CheckboxComponent
              name="dentalCare"
              label={polyglot.t('BenefitModule.dentalCare')}
              checked={formik.values.dentalCare}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ mb: spacing.mb20 }}>
            <CheckboxComponent
              name="opticalCare"
              label={polyglot.t('BenefitModule.opticalCare')}
              checked={formik.values.opticalCare}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ mb: spacing.mb20 }}>
            <CheckboxComponent
              name="travelCover"
              label={polyglot.t('BenefitModule.travelCover')}
              checked={formik.values.travelCover}
              onChange={formik.handleChange}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="additionalNotes"
              label={polyglot.t('BenefitModule.additionalNotes')}
              value={formik.values.additionalNotes}
              onChange={formik.handleChange}
            />
          </Box>

          <Box sx={{ ...buttonBoxSx, mt: spacing.mt40 }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              type="submit"
              fullWidth
              disabled={!isContinueEnabled()}
            >
              {polyglot.t('General.continue')}
            </ButtonComponent>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
