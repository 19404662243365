import axios from 'axios';

import {
  OrderPreferenceUpdate,
  RemoveFromSection,
  MoveToSection,
  BankCountInterface,
} from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import {
  SurveyTemplateCreation,
  SurveyTemplate,
  SurveyTemplateUpdate,
  SurveyTemplateById,
} from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class SurveyTemplateAPI {
  static async createSurveyTemplate(templateBody: SurveyTemplateCreation): Promise<SurveyTemplate> {
    return (await axios.post('/apiv2/survey-template', templateBody)).data;
  }

  static async updateSurveyTemplate(updateBody: SurveyTemplateUpdate): Promise<SurveyTemplate> {
    return (await axios.patch('/apiv2/survey-template', updateBody)).data;
  }

  static async updateOrderSurveyTemplate(updateBody: OrderPreferenceUpdate): Promise<SurveyTemplate> {
    return (await axios.patch('/apiv2/survey-template/order', updateBody)).data;
  }

  static async moveQuestionOutsideSection(updateBody: RemoveFromSection): Promise<SurveyTemplate> {
    return (await axios.patch(`/apiv2/survey-template/remove-from-section`, updateBody)).data;
  }

  static async moveQuestionInsideSection(updateBody: MoveToSection): Promise<SurveyTemplate> {
    return (await axios.patch(`/apiv2/survey-template/add-to-section`, updateBody)).data;
  }

  static async getSurveyTemplatebyId(templateId: string): Promise<SurveyTemplate> {
    return (await axios.get(`/apiv2/survey-template/${templateId}`)).data;
  }

  static async deleteTemplatebyId(templateId: string): Promise<void> {
    await axios.delete(`/apiv2/survey-template/${templateId}`);
  }

  static async addQuestionsToTemplate(questionBankIds: string[], templateId: string): Promise<any> {
    return (await axios.post(`/apiv2/survey-template/add-questions/${templateId}`, questionBankIds)).data;
  }
}

export class SurveyTemplateEndpoints {
  static getSurveyTemplatesCompanyId(search?: string): Endpoint<SurveyTemplate[]> {
    const urlParams = new URLSearchParams({
      ...(search && { search }),
    });
    return {
      url: `/apiv2/survey-template?${urlParams.toString()}`,
    };
  }

  static getBankCount(): Endpoint<BankCountInterface> {
    return {
      url: '/apiv2/survey-template/bank-count',
    };
  }

  static getSurveyTemplateStructurebyId(templateId: string): Endpoint<SurveyTemplateById> {
    return {
      url: `/apiv2/survey-template/${templateId}`,
    };
  }
}
