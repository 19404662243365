import { useContext } from 'react';

import { Box, Stack } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory, useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import trialExpiredBannerImage from '@/images/trial/trial-expired-banner.png';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { performLogout } from '@/v2/feature/auth/auth.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const TrialExpiredLayer = () => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const swrConfig = useSWRConfig();
  const routerLocation = useLocation();
  const BILLING_ROUTE = '/settings/billing';
  const currentlyInBilling = routerLocation.pathname.includes(BILLING_ROUTE);

  const [globalState, dispatch] = useContext(GlobalContext);

  const { hasScopes } = useScopes();
  const scopesContext = { userId: globalState.user.userId };
  const isAdminUser = hasScopes(['company.settings:all'], scopesContext);

  const hasTrialExpired = globalState.trialMode.lapsed;

  const redirectToBilling = () => {
    history.push(BILLING_ROUTE);
  };

  // Don't render if trial is still active OR redirected to billing
  if (!hasTrialExpired || currentlyInBilling) {
    return null;
  }

  const LogoutButton = () => (
    <ButtonComponent
      sizeVariant="medium"
      colorVariant="secondary"
      onClick={() => performLogout(history, dispatch, swrConfig)}
    >
      {polyglot.t('UserAvatarMenu.logout')}
    </ButtonComponent>
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '32px',
          width: '50vw',
          height: '45vh',
          textAlign: 'left',
          backgroundImage: `url(${trialExpiredBannerImage})`,
          backgroundSize: '130%',
          backgroundPosition: '100% -50%',
        }}
      >
        <Stack
          flexDirection="column"
          alignItems="left"
          justifyContent="left"
          sx={{ mt: spacing.mt30, gap: spacing.g20 }}
        >
          <Typography variant="title2">{polyglot.t('TrialExpired.title')}</Typography>

          {isAdminUser ? (
            <>
              <Typography variant="bodyLarge">{polyglot.t('TrialExpired.adminSuffix')}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'left',
                  justifyItems: 'left',
                  justifyContent: 'flex-start',
                  mt: spacing.mt20,
                  gap: spacing.g20,
                }}
              >
                <ButtonComponent sizeVariant="medium" colorVariant="primary" onClick={redirectToBilling}>
                  {polyglot.t('TrialExpired.upgradeButton')}
                </ButtonComponent>
                <LogoutButton />
              </Box>
            </>
          ) : (
            <Stack flexDirection="column" justifyContent="center">
              <Typography variant="bodyLarge">{polyglot.t('TrialExpired.nonAdminSuffix')}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'left',
                  justifyItems: 'left',
                  justifyContent: 'flex-start',
                  mt: spacing.mt20,
                  gap: spacing.g20,
                }}
              >
                <LogoutButton />
              </Box>
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
