import { Dispatch, SetStateAction, useState } from 'react';

import { FormControlLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

// import useMessage from '@/hooks/notification.hook';
import { CalendarEndpoints } from '../../calendar.api';
import { iCalLinkMode, iCalLinkModeEnum } from '../../calendar.interface';

import { OptionObject } from '@/v2/components/forms/autocomplete.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { fieldSx, titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ICalLinkDrawerContentProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly filterString: string;
}

const ICAL_LINK_HELPER_TEXT = 'Copy and paste this link into your calendar. Check support for more guidance.';

export const ICalLinkDrawer = ({ isOpen, setIsOpen, filterString }: ICalLinkDrawerContentProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ICalLinkDrawerContent filterString={filterString} />
    </DrawerModal>
  );
};

interface iCalLinkDrawerContentProps {
  readonly filterString: string;
}

export const ICalLinkDrawerContent = ({ filterString }: iCalLinkDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  // const [showMessage] = useMessage();
  const [linkMode, setLinkMode] = useState<iCalLinkMode>(iCalLinkModeEnum.ALL);
  const { data: icsLinkAddressHash, isValidating: loadingLink } = useApiClient(
    CalendarEndpoints.getCalendarIcsAddressHash(linkMode, linkMode === iCalLinkModeEnum.ALL ? undefined : filterString),
    {
      suspense: false,
    }
  );

  const linkModeOptions: OptionObject[] = [
    { value: iCalLinkModeEnum.ALL, label: 'Subscribe to all events' },
    { value: iCalLinkModeEnum.FILTERED, label: 'Subscribe to the currently filtered view' },
  ];

  return (
    <Box>
      <Typography sx={titleSx}>{polyglot.t('CalendarPage.iCalLink')}</Typography>
      <Typography sx={themeFonts.caption}>{polyglot.t('iCalLinkDrawer.body')}</Typography>
      <Box sx={{ ...fieldSx, display: 'flex', gap: spacing.g10, mb: spacing.m20, mt: spacing.mt20 }}>
        <RadioGroup
          name="ical-link-mode"
          onChange={(event) => {
            if (!event.target.value) return;
            setLinkMode(event.target.value as iCalLinkMode);
          }}
        >
          {linkModeOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              labelPlacement="end"
              value={option.value}
              checked={linkMode ? linkMode === option.value : false}
              control={<StyledRadio />}
              label={<Typography sx={themeFonts.title4}>{option.label}</Typography>}
              sx={{ mb: spacing.m5 }}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
        {loadingLink ? (
          <SkeletonLoader
            variant="rectangular"
            width="100%"
            height="5vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: spacing.mt15 }}
          />
        ) : (
          <Stack direction="column">
            <TextfieldComponent
              label={polyglot.t('iCalLinkDrawer.link')}
              value={icsLinkAddressHash ?? ''}
              endAdornment="copy"
              disabled
            />
            <Typography sx={{ ...themeFonts.captionSmall, mt: spacing.mt10 }}>{ICAL_LINK_HELPER_TEXT}</Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
};
