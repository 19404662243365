import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  ...themeFonts.caption,
  color: themeColors.Grey,
  marginTop: spacing.m5,
  // error helper text
  '& .Mui-error': {
    color: themeColors.darkRed,
  },
}));
