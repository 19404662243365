import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Link, Stack } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { ReactComponent as ZincIcon } from '@/images/side-bar-icons/zinc.svg';
import { nestErrorMessage } from '@/lib/errors';
import { CurrentUser } from '@/models';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GeneralisedRemoveConfirmDrawer } from '@/v2/feature/app-integration/features/app-details/components/generalised-remove-confirm-drawer-drawer.component';
import { IDCheckAPI } from '@/v2/feature/id-check/id-check.api';
import {
  IdCheckPackage,
  IndividualCheck,
  PackageDetail,
  customIdCheckPackages,
} from '@/v2/feature/id-check/id-check.interface';
import {
  ZINC_CHECKS_LINK,
  ZINC_TERMS_LINK,
} from '@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component';
import { OnboardingEndpoints } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { underlinedLinkLight } from '@/v2/styles/buttons.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatAsGBPCurrency } from '@/v2/util/string-format.util';

interface IdCheckPackageDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly currentUser: CurrentUser;
  readonly idCheckPackage: IdCheckPackage | null;
  readonly individualChecks: IndividualCheck[];
  readonly refresh: () => Promise<void>;
}

export const NewIdCheckPackageDrawer = ({
  isOpen,
  setIsOpen,
  currentUser,
  idCheckPackage,
  individualChecks,
  refresh,
}: IdCheckPackageDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);

  const { data: packageCurrentlyUsedInOnboarding } = useApiClient(
    idCheckPackage?.id
      ? OnboardingEndpoints.isIdCheckPackageCurrentlyUsedInOnboarding(idCheckPackage.id)
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const formik = useFormik<Partial<IdCheckPackage>>({
    initialValues: {
      name: idCheckPackage ? idCheckPackage.name : '',
      price: idCheckPackage ? idCheckPackage.price : 0,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        .required(polyglot.t('IdCheckPackage.errorMessages.missingName'))
        .min(2, polyglot.t('IdCheckPackage.errorMessages.missingName')),
      packageDetails: yup
        .object()
        .required()
        .required(polyglot.t('IdCheckPackage.errorMessages.missingPackageDetails')),
    }),
    onSubmit: async (values: Partial<IdCheckPackage>) => {
      setLoading(true);
      const { name, packageDetails } = values;
      if (!name || !packageDetails) {
        showMessage('Please fill in all fields', 'error');
        setLoading(false);
        return;
      }
      try {
        if (idCheckPackage?.id) {
          await IDCheckAPI.updatePackage(idCheckPackage.id, { name, packageDetails });
          showMessage('Successfully updated Id check package', 'success');
        } else {
          await IDCheckAPI.createPackage({ name, packageDetails });
          showMessage('Successfully created new Id check package', 'success');
        }
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Encountered an error while trying to save package: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const deletePackage = useCallback(async () => {
    try {
      setLoading(true);
      if (idCheckPackage) await IDCheckAPI.deletePackageById(idCheckPackage.id);
      await refresh();
      setIsOpen(false);
      showMessage('Successfully deleted Id check package', 'success');
    } catch (error) {
      showMessage(`Could not delete Id check package. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [idCheckPackage, refresh, setIsOpen, showMessage]);

  const isDefaultIdCheckPackage = useMemo(() => {
    if (idCheckPackage && !idCheckPackage.companyId && !idCheckPackage.createdBy) return true;
    return false;
  }, [idCheckPackage]);

  const MemoizedCheckBoxesForIndividualChecks = ({
    usesCustomPackage,
    individualChecks,
    polyglot,
  }: {
    usesCustomPackage: boolean;
    individualChecks: IndividualCheck[];
    polyglot: Polyglot;
  }) => {
    const [packageDetailsSet, setPackageDetailsSet] = useState(
      new Set(Object.keys(idCheckPackage?.packageDetails ?? []).map((eachCheck) => eachCheck))
    );
    const checkBoxesForIndividualChecks = useMemo(() => {
      return individualChecks
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((eachCheck) => {
          const checkIsCustom = eachCheck.id in customIdCheckPackages;
          const eachLabelFromId = checkIsCustom
            ? customIdCheckPackages[eachCheck.id]
            : polyglot.t(`IdCheckPackage.id.${eachCheck.id}`);
          const labelWithPrice = `${eachLabelFromId} (${formatAsGBPCurrency(eachCheck.price, 2)})`;
          const isChecked = packageDetailsSet.has(eachCheck.id);

          const handleCheckboxChange = () => {
            setPackageDetailsSet((prevSet) => {
              const newSet = new Set(prevSet);
              if (prevSet.has(eachCheck.id)) {
                newSet.delete(eachCheck.id);
              } else {
                newSet.add(eachCheck.id);
              }
              return newSet;
            });
          };

          return (
            <div key={eachCheck.id}>
              <CheckboxComponent
                sx={{ mb: spacing.mb15 }}
                label={labelWithPrice}
                value={eachCheck.id}
                name={eachCheck.id}
                checked={isChecked}
                disabled={isDefaultIdCheckPackage || usesCustomPackage || checkIsCustom}
                onChange={handleCheckboxChange}
              />
            </div>
          );
        });
    }, [individualChecks, packageDetailsSet, polyglot, usesCustomPackage]);

    const convertSetToObject = useMemo(() => {
      return [...packageDetailsSet].reduce((acc: PackageDetail, eachCheck) => {
        acc[eachCheck] = { enabled: true };
        return acc;
      }, {});
    }, [packageDetailsSet]);

    useEffect(() => {
      let price = 0;
      individualChecks.forEach((eachCheck) => {
        if (packageDetailsSet.has(eachCheck.id)) {
          price += eachCheck.price;
        }
      });
      formik.setFieldValue('price', price);
    }, [individualChecks, packageDetailsSet]);

    useEffect(() => {
      formik.setFieldValue('packageDetails', convertSetToObject);
    }, [convertSetToObject, packageDetailsSet]);

    return <>{checkBoxesForIndividualChecks}</>;
  };

  const usesCustomPackage =
    idCheckPackage?.packageDetails && Object.keys(idCheckPackage.packageDetails)?.length > 0
      ? Object.keys(idCheckPackage.packageDetails).includes('customPackage')
      : false;

  const anyPackagesSelected = formik.values.packageDetails
    ? Object.keys(formik.values.packageDetails).length > 0
    : false;

  const excludingVatText =
    formik?.values?.price && formik?.values?.price >= 0 ? ` (${polyglot.t('IdCheckPackage.excludingVAT')})` : '';

  const PACKAGE_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t('IdCheckPackageConfirmRemove.normalConfirmMessage');
    if (!packageCurrentlyUsedInOnboarding) {
      return defaultMessage;
    } else if (packageCurrentlyUsedInOnboarding) {
      return polyglot.t('IdCheckPackageConfirmRemove.packageUsedInOnboardingConfirmMessage');
    }
    return defaultMessage;
  }, [packageCurrentlyUsedInOnboarding, polyglot]);

  return (
    <FormikProvider value={formik}>
      <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Box sx={drawerContentSx}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="title2">
              {polyglot.t(
                !idCheckPackage
                  ? 'IdCheckPackage.newPackage'
                  : isDefaultIdCheckPackage
                  ? 'IdCheckPackage.existingPackage'
                  : 'IdCheckPackage.editPackage'
              )}
            </Typography>
            {/* only company created Id check packages can be deleted */}
            {!isDefaultIdCheckPackage && !usesCustomPackage && idCheckPackage && (
              <ScopesControl scopes={['user.onboard:all']} context={{ userId: currentUser?.userId }}>
                <IconButton sx={tableIconButtonSx} onClick={() => setIsRemovalModalOpen(true)}>
                  <Trash {...iconSize} />
                </IconButton>
              </ScopesControl>
            )}
          </Box>
          {idCheckPackage ? (
            <Typography variant="title3">{idCheckPackage?.name}</Typography>
          ) : (
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="name"
                label={polyglot.t('IdCheckPackage.name')}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && !!formik.errors.name}
                helperText={(formik.touched.name && formik.errors.name) ?? ' '}
              />
            </Box>
          )}

          <Box sx={fieldSx}>
            <Typography variant="title3">
              {polyglot.t(!idCheckPackage ? 'IdCheckPackage.selectChecks' : 'IdCheckPackage.existingChecks')}
            </Typography>
            <Typography variant="caption" sx={{ mt: spacing.mt10 }}>
              {polyglot.t('EditIdVerify.poweredBy')} <ZincIcon {...iconSize} /> {polyglot.t('EditIdVerify.zincMex')}
            </Typography>
            <Box sx={{ mt: spacing.mt20 }}>
              {MemoizedCheckBoxesForIndividualChecks({ individualChecks, usesCustomPackage, polyglot })}
            </Box>
          </Box>

          <Box sx={{ ...buttonBoxDrawerSx, mt: spacing.mt30 }}>
            <Stack direction="column">
              <Typography variant="title3">
                {polyglot.t('IdCheckPackage.totalPrice')}:{' '}
                {usesCustomPackage && idCheckPackage
                  ? formatAsGBPCurrency(idCheckPackage.price, 2)
                  : formatAsGBPCurrency(
                      formik?.values?.price && formik?.values?.price >= 0 ? formik.values.price : 0,
                      2
                    )}
                {excludingVatText}
              </Typography>
              <Stack direction="row" sx={{ gap: spacing.g10 }}>
                <Link sx={{ ...underlinedLinkLight }} target="_blank" href={ZINC_TERMS_LINK}>
                  {polyglot.t('EditIdVerify.zinc')}
                </Link>
                <Link sx={{ ...underlinedLinkLight }} target="_blank" href={ZINC_CHECKS_LINK}>
                  {polyglot.t('EditIdVerify.zincChecksInfo')}
                </Link>
              </Stack>
            </Stack>
          </Box>

          {!isDefaultIdCheckPackage && (
            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                type="submit"
                sizeVariant="medium"
                colorVariant="primary"
                onClick={() => formik.handleSubmit()}
                disabled={loading || !formik.isValid || !anyPackagesSelected || usesCustomPackage}
                fullWidth
              >
                {polyglot.t('General.save')}
              </ButtonComponent>
            </Box>
          )}
        </Box>
      </DrawerModal>

      <GeneralisedRemoveConfirmDrawer
        isOpen={isRemovalModalOpen}
        setIsOpen={setIsRemovalModalOpen}
        drawerTitle={polyglot.t('IdCheckPackageConfirmRemove.title')}
        confirmationMessage={PACKAGE_IN_USE_CONFIRMATION_MESSAGE}
        onClose={() => setIsRemovalModalOpen(false)}
        onConfirm={async () => {
          if (idCheckPackage?.id) deletePackage();
        }}
      />
    </FormikProvider>
  );
};
