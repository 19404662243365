import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  INVOICES_ME_EDIT_INVOICE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  INVOICES_ME_OVERVIEW_ROUTE,
  INVOICES_ME_ROUTE,
} from '@/lib/routes';
import { InvoicesPage } from '@/v2/feature/payments/pages/invoice.page';
import { NewInvoicePage } from '@/v2/feature/payments/pages/new-invoice.page';

export const InvoiceMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  return (
    <Switch>
      <RouteScopesControl context={scopesContext} scopes={['invoices']} path={INVOICES_ME_OVERVIEW_ROUTE}>
        <InvoicesPage reach="me" />
      </RouteScopesControl>

      <RouteScopesControl context={scopesContext} scopes={['invoices']} path={INVOICES_ME_NEW_INVOICE_ROUTE}>
        <NewInvoicePage preselectedEmployee={globalState.user.userId} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['invoices']} context={scopesContext} path={INVOICES_ME_EDIT_INVOICE_ROUTE} exact>
        <NewInvoicePage reach="user" preselectedEmployee={globalState.user.userId} editMode={true} />
      </RouteScopesControl>

      <Redirect to={INVOICES_ME_OVERVIEW_ROUTE} from={INVOICES_ME_ROUTE} />
    </Switch>
  );
};
