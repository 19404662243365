/* eslint-disable filenames/match-regex */
import { useCallback, useMemo, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { nestErrorMessage } from '@/lib/errors';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  AccountingFieldPaycodeMapping,
  CompanyPayrollAccountingConfig,
  NominalCodeDto,
} from '@/v2/feature/payroll/payroll.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type AccountingCodeItemProps = {
  payCode: string;
  nominalCodes?: NominalCodeDto[] | null; // codes imported from Xero
  reimportNominalCodes: () => Promise<void>;
  accountingConfig: CompanyPayrollAccountingConfig;
  accountingField?: AccountingFieldPaycodeMapping;
  setAccountingField: (value: AccountingFieldPaycodeMapping) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};
export const AccountingCodeItem = ({
  payCode,
  nominalCodes,
  reimportNominalCodes,
  accountingConfig,
  accountingField,
  setAccountingField,
  autoFocus,
  disabled,
  sx,
}: AccountingCodeItemProps) => {
  const [isReloadingCodes, setIsReloadingCodes] = useState(false);
  const [showMessage] = useMessage();

  const nominalCodeSelectOptions = useMemo(() => {
    return nominalCodes
      ?.sort((a, b) => a.code.localeCompare(b.code))
      .map<OptionObject>(({ code, name }) => ({
        label: `${code} - ${name}`,
        value: code,
      }));
  }, [nominalCodes]);

  const updateAccountingFieldValue = useCallback(
    (value: Partial<AccountingFieldPaycodeMapping>) => {
      setAccountingField({
        payCode,
        code: accountingField?.code ?? '',
        nominalName: accountingField?.nominalName ?? '',
        description: accountingField?.description ?? '',
        ...value,
      });
    },
    [accountingField, payCode, setAccountingField]
  );

  const reloadAccountingCodes = useCallback(async () => {
    setIsReloadingCodes(true);
    try {
      await reimportNominalCodes();
      showMessage('Accounting codes refreshed', 'info');
    } catch (error) {
      showMessage(`Failed to refresh accounting codes. ${nestErrorMessage(error)}`, 'error');
    }
    setIsReloadingCodes(false);
  }, [reimportNominalCodes, showMessage]);

  if (nominalCodes && nominalCodeSelectOptions && accountingConfig.organisation) {
    const nominalCodeMatch = accountingField && nominalCodes.find((n) => n.code === accountingField.code);
    const exactMatch = !!nominalCodeMatch && nominalCodeMatch.name === accountingField.nominalName;
    return (
      <Stack sx={{ gap: '20px' }}>
        <Stack sx={{ flexFlow: 'row', alignItems: 'flex-end', gap: '20px' }}>
          <SelectComponent
            name="accounting-code-select"
            value={(exactMatch && nominalCodeMatch?.code) || ''}
            onChange={(e) => {
              const selectedCode = nominalCodes.find((n) => n.code === e.target.value);
              if (!selectedCode) return;
              updateAccountingFieldValue({
                code: selectedCode.code,
                nominalName: selectedCode.name,
                description: selectedCode.description,
              });
            }}
            label={'Accounting code'}
            options={nominalCodeSelectOptions}
            disabled={disabled || isReloadingCodes}
          />
          <LoaderButton
            loading={isReloadingCodes}
            disabled={disabled}
            onClick={() => reloadAccountingCodes()}
            sizeVariant="medium"
            colorVariant="primary"
            style={{ width: 'fit-content' }}
            title="Reload Xero accounting codes"
          >
            <Reload style={{ float: 'left' }} />
          </LoaderButton>
        </Stack>
        {
          /* if the saved accounting field does not exactly match the Xero nominal code, show the saved field underneath */
          !exactMatch && accountingField && (
            <Stack sx={{ gap: spacing.g5, mt: spacing.mt10 }}>
              <Typography sx={{ ...themeFonts.title4 }}>{`Current accounting code`}</Typography>
              <Typography
                sx={{ ...themeFonts.caption }}
              >{`${accountingField.code} - ${accountingField.nominalName}`}</Typography>
            </Stack>
          )
        }
      </Stack>
    );
  }

  return (
    <Stack sx={{ gap: spacing.g15, ...sx }}>
      <TextfieldComponent
        name="accounting-code-value"
        label="Accounting code"
        value={accountingField?.code ?? ''}
        disabled={disabled}
        onChange={(e) => {
          updateAccountingFieldValue({ code: e.target.value });
        }}
        clearText={() => updateAccountingFieldValue({ code: '' })}
        autoFocus={autoFocus}
        autoComplete="off"
        placeholder="000"
        endAdornment="none"
      />
      <TextfieldComponent
        name="accounting-code-name"
        label="Accounting code name"
        value={accountingField?.nominalName ?? ''}
        disabled={disabled || !accountingField?.code}
        onChange={(e) => updateAccountingFieldValue({ nominalName: e.target.value })}
        clearText={() => updateAccountingFieldValue({ nominalName: '' })}
        autoComplete="off"
      />
    </Stack>
  );
};
