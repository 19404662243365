import { NO_VAT_VALUE } from '@v2/feature/payments/expenses.util';
import { ContractorInvoiceLineItem } from '@v2/feature/payments/payments.interface';
import { fixedNumber } from '@v2/util/number.util';

export const isItemsArrayValid = (items: ContractorInvoiceLineItem[]): boolean =>
  !items.find((item) => !item.amount || item.amount <= 0);

// export const getValuesForEachLineItem = (
//   lineItem: Partial<ContractorInvoiceLineItem>
// ): { grossForLineItem: number; amountForLineItem: number; taxForLineItem: number } => {
//   let grossForLineItem = 0;
//   let amountForLineItem = 0;
//   let taxForLineItem = 0;
//   const { amount = 0, gross = 0, taxRate = 0 } = lineItem;
//   const multiplier = taxRate ? 1 + taxRate / 100 : 1;
//   if (!taxRate || taxRate === 0) {
//     amountForLineItem += gross;
//   } else {
//     amountForLineItem += amount;
//   }
// };

export const calculateGrossAmountForInvoice = (
  amount: number,
  taxRate: number | null
): { gross: number; taxAmount: number } => {
  if (!taxRate || taxRate === NO_VAT_VALUE) return { gross: amount, taxAmount: 0 };
  if (taxRate && amount) {
    const multiplier = taxRate ? 1 + taxRate / 100 : 1;
    const totalGross = fixedNumber(multiplier ? amount * multiplier : amount, 2);
    const totalTaxAmount = fixedNumber(totalGross - amount, 2);

    return { gross: totalGross, taxAmount: totalTaxAmount };
  }
  return { gross: amount, taxAmount: 0 };
};

export const getInvoiceTotalsForIndividualLineItem = (
  amount: number,
  taxRate: number
): { totalGross: number; totalAmount: number; totalTaxAmount: number } => {
  const totalAmount = amount;
  const { gross: totalGross, taxAmount: totalTaxAmount } = calculateGrossAmountForInvoice(amount, taxRate);
  return {
    totalGross,
    totalAmount,
    totalTaxAmount,
  };
};

export const isTaxIncluded = (taxRate: number | null) => {
  return taxRate === NO_VAT_VALUE ? false : true;
};

export const getInvoiceTotalsBasedOnLineItems = (
  lineItems: Partial<ContractorInvoiceLineItem>[]
): {
  totalGross: number;
  totalAmount: number;
  totalTaxAmount: number;
} => {
  let totalGross = 0;
  let totalAmount = 0;
  let totalTaxAmount = 0;
  for (const eachLineItem of lineItems) {
    const { amount = 0, taxRate = 0 } = eachLineItem;
    const {
      totalGross: grossForLineItem,
      totalAmount: amountForLineItem,
      totalTaxAmount: taxAmountForLineItem,
    } = getInvoiceTotalsForIndividualLineItem(amount, taxRate ?? 0);
    totalGross += grossForLineItem;
    totalAmount += amountForLineItem;
    totalTaxAmount += taxAmountForLineItem;
  }

  return {
    totalGross,
    totalAmount,
    totalTaxAmount,
  };
};

export const getFinalGrossForLineItemInvoice = (lineItems: Partial<ContractorInvoiceLineItem>[]) => {
  let finalAmount = 0;
  for (const eachLineItem of lineItems) {
    const { amount = 0, taxRate = 0 } = eachLineItem;
    const { gross: totalGross } = calculateGrossAmountForInvoice(amount, taxRate);
    finalAmount += totalGross;
  }
  return finalAmount;
};
