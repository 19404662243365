import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PolicyCarryOverFormData } from '@v2/feature/absence/absence.interface';
import { getMonthOptions } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface LocalFormData extends PolicyCarryOverFormData {
  readonly isLimited: boolean;
  readonly isExpiring: boolean;
}

interface PolicyCarryOverEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyCarryOverEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: PolicyCarryOverEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: LocalFormData) => {
      const policyData: PolicyCarryOverFormData = {
        carryOverEnabled: true,
        carryOverLimit: values.isLimited ? Number(values.carryOverLimit) : null,
        carryOverExpirationMonth: values.isExpiring ? values.carryOverExpirationMonth : null,
        carryOverExpirationDay: values.carryOverExpirationMonth ? Number(values.carryOverExpirationDay) : null,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyCarryOver(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage]
  );

  const formik = useFormik<LocalFormData>({
    initialValues: {
      carryOverEnabled: true,
      isLimited: absencePolicy.carryOverLimit !== null,
      isExpiring: Boolean(absencePolicy?.carryOverExpirationDay && absencePolicy?.carryOverExpirationMonth),
      carryOverLimit: absencePolicy.carryOverEnabled ? absencePolicy.carryOverLimit : null,
      carryOverExpirationMonth: absencePolicy.carryOverEnabled ? absencePolicy.carryOverExpirationMonth : null,
      carryOverExpirationDay: absencePolicy.carryOverEnabled ? absencePolicy.carryOverExpirationDay : null,
    },
    validationSchema: yup.object({
      carryOverEnabled: yup.boolean().required(polyglot.t('PolicyCarryOverEditDrawer.selectValidField')),
      carryOverLimit: yup.number().when('isLimited', {
        is: true,
        then: (schema) =>
          schema
            .typeError(polyglot.t('PolicyCarryOverEditDrawer.selectValidField'))
            .min(0, polyglot.t('PolicyCarryOverEditDrawer.selectValidField'))
            .required('ValidationMessages.requiredField'),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
      carryOverExpirationMonth: yup.number().when('isExpiring', {
        is: true,
        then: yup
          .number()
          .min(1, polyglot.t('getLimitedAllowanceValidationSchema.valueSelect'))
          .max(12, polyglot.t('getLimitedAllowanceValidationSchema.valueSelect'))
          .integer(polyglot.t('getLimitedAllowanceValidationSchema.valueSelect'))
          .typeError(polyglot.t('getLimitedAllowanceValidationSchema.valueSelect'))
          .required(polyglot.t('getLimitedAllowanceValidationSchema.carryOverExpiration')),
        otherwise: yup.number().nullable().notRequired(),
      }),
      carryOverExpirationDay: yup
        .number()
        .nullable()
        .when('isExpiring', {
          is: true,
          then: yup
            .number()
            .min(1, polyglot.t('getLimitedAllowanceValidationSchema.value'))
            .max(31, polyglot.t('getLimitedAllowanceValidationSchema.value'))
            .integer(polyglot.t('getLimitedAllowanceValidationSchema.value'))
            .typeError(polyglot.t('getLimitedAllowanceValidationSchema.value'))
            .when('carryOverExpirationMonth', (carryOverExpirationMonth, schema) => {
              const daysInMonth = new Date(new Date().getFullYear(), carryOverExpirationMonth, 0).getDate();
              return schema.max(
                daysInMonth,
                polyglot.t('getLimitedAllowanceValidationSchema.monthsDays', { daysInMonth })
              );
            })
            .required(polyglot.t('getLimitedAllowanceValidationSchema.carryOverExpirationDay')),
          otherwise: yup.number().nullable().notRequired(),
        }),
    }),
    onSubmit,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('PolicyCarryOverEditDrawer.carryOver')}</Typography>

          <Typography variant="caption">{polyglot.t('PolicyCarryOverEditDrawer.carryOverDesc')}</Typography>

          <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
            <ButtonComponent
              onClick={() => {
                formik.setFieldValue('isLimited', false);
                formik.setFieldValue('carryOverLimit', null);
              }}
              colorVariant={!formik.values.isLimited ? 'active' : 'secondary'}
              sizeVariant="filter"
            >
              {polyglot.t('PolicyCarryOverEditDrawer.unlimited')}
            </ButtonComponent>

            <ButtonComponent
              onClick={() => {
                formik.setFieldValue('isLimited', true);
                formik.setFieldValue('carryOverLimit', 8);
              }}
              sizeVariant="filter"
              colorVariant={formik.values.isLimited ? 'active' : 'secondary'}
            >
              {polyglot.t('PolicyCarryOverEditDrawer.limited')}
            </ButtonComponent>
          </Box>

          {formik.values.isLimited && (
            <TextfieldComponent
              name="carryOverLimit"
              label={polyglot.t('PolicyCarryOverEditDrawer.carryOverLimit')}
              value={formik.values.carryOverLimit}
              onChange={formik.handleChange}
              error={formik.touched.carryOverLimit && !!formik.errors.carryOverLimit}
              helperText={(formik.touched.carryOverLimit && formik.errors.carryOverLimit) ?? ' '}
              endAdornment="none"
            />
          )}

          <Box>
            <CheckboxComponent
              label={polyglot.t('PolicyCarryOverEditDrawer.expiry')}
              name="isExpiring"
              checked={formik.values.isExpiring}
              onChange={(e, checked) => {
                formik.handleChange(e);
                if (!checked) {
                  formik.setFieldValue('carryOverExpirationDay', null);
                  formik.setFieldValue('carryOverExpirationMonth', null);
                }
              }}
            />
            <Typography variant="captionSmall" sx={{ ml: spacing.m25, color: themeColors.Grey }}>
              {polyglot.t('PolicyCarryOverEditDrawer.expiryDesc')}
            </Typography>
          </Box>

          {formik.values.isExpiring && (
            <Box sx={{ display: 'flex', gap: spacing.g10 }}>
              <TextfieldComponent
                name="carryOverExpirationDay"
                label={polyglot.t('PolicyCarryOverEditDrawer.day')}
                value={formik.values.carryOverExpirationDay}
                onChange={formik.handleChange}
                error={formik.touched.carryOverExpirationDay && !!formik.errors.carryOverExpirationDay}
                helperText={(formik.touched.carryOverExpirationDay && formik.errors.carryOverExpirationDay) ?? ' '}
                endAdornment="none"
              />
              <SelectComponent
                name="carryOverExpirationMonth"
                label={polyglot.t('PolicyCarryOverEditDrawer.month')}
                options={getMonthOptions(polyglot)}
                value={formik.values.carryOverExpirationMonth}
                onChange={formik.handleChange}
                compareValue={formik.values.carryOverExpirationMonth}
                error={!!formik.errors.carryOverExpirationMonth && !!formik.touched.carryOverExpirationMonth}
                helperText={
                  formik.touched.carryOverExpirationMonth && (formik.errors.carryOverExpirationMonth as string)
                }
              />
            </Box>
          )}

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
