import { Box, Button, Typography } from '@mui/material';
import { linkButtonSx } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Info } from '@/images/auth/Info.svg';
import { SETTINGS_BILLING_ROUTE } from '@/lib/routes';

export const PaymentFailedBanner = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: themeColors.Red,
        ...themeFonts.caption,
        color: themeColors.black,
        display: 'flex',
        justifyContent: 'center',
        gap: spacing.g5,
        paddingY: spacing.p10,
        position: 'absolute',
        zIndex: 5,
        alignItems: 'center',
        opacity: '60%',
      }}
    >
      <Info fill="#000" {...iconSize} />
      <Typography sx={themeFonts.caption}>There are outstanding invoices in your account. Please</Typography>
      <Button
        onClick={() => history.push(SETTINGS_BILLING_ROUTE)}
        sx={{ ...themeFonts.caption, ...linkButtonSx, color: themeColors.black }}
      >
        make payment
      </Button>
      <Typography sx={themeFonts.caption}>to avoid deactivation.</Typography>
    </Box>
  );
};
