import { DocumentsCompanyRouter } from '@v2/feature/documents/documents.company.router';
import { DocumentsMeRouter } from '@v2/feature/documents/documents.me.router';
import { Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { DOCUMENTS_COMPANY_DOMAIN_ROUTE, DOCUMENTS_ME_DOMAIN_ROUTE, DOCUMENTS_TEAM_DOMAIN_ROUTE } from '@/lib/routes';
import { DocumentsScopes } from '@/v2/feature/documents/documents.scopes';
import { DocumentsTeamRouter } from '@/v2/feature/documents/documents.team.router';

export const DocumentsRouter = (): JSX.Element => {
  return (
    <Switch>
      <RouteScopesHas scopes={DocumentsScopes.VIEW_COMPANY_DOCUMENTS} path={DOCUMENTS_COMPANY_DOMAIN_ROUTE}>
        <DocumentsCompanyRouter />
      </RouteScopesHas>

      <RouteScopesHas scopes={DocumentsScopes.VIEW_TEAM_DOCUMENTS} path={DOCUMENTS_TEAM_DOMAIN_ROUTE}>
        <DocumentsTeamRouter />
      </RouteScopesHas>

      <RouteScopesHas scopes={DocumentsScopes.VIEW_MY_DOCUMENTS} path={DOCUMENTS_ME_DOMAIN_ROUTE}>
        <DocumentsMeRouter />
      </RouteScopesHas>
    </Switch>
  );
};
