import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { PermissionGroupMemberRemoverList } from '@/v2/feature/permission-group/components/permission-group-member-remover-list.component';
import { convertUserSummariesToIds } from '@/v2/feature/permission-group/components/permission-group-targets-edit-drawer.component';
import { PermissionGroupAPI } from '@/v2/feature/permission-group/permission-group.api';
import {
  CreatePermissionGroupDto,
  PermissionDto,
  PermissionGroupDto,
  PermissionGroupMemberUserDto,
} from '@/v2/feature/permission-group/permission-group.dto';
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES,
  PermissionCategory,
} from '@/v2/feature/permission-group/permission-group.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { drawerContentSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { translatePermissionCategory, translatePermissionCategoryDesc } from '@/v2/infrastructure/i18n/translate.util';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface PermissionGroupEditFormData {
  name: string;
  description: string;
  members: any[];
  userListForMembershipDeletion: number[];
}

const PermissionGroupEditFormSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    name: Yup.string().required(polyglot.t('PermissionGroupEditDrawerPage.errorMessages.nameRequired')),
    description: Yup.string().required(polyglot.t('PermissionGroupEditDrawerPage.errorMessages.groupDescRequired')),
  });

interface PermissionGroupEditDrawerPageProps {
  readonly refreshPermissionGroup: () => Promise<void>;
  readonly selectedPermission?: PermissionGroupDto | null;
  readonly permissionGroupMembers?: PermissionGroupMemberUserDto[];
  readonly selectedPermissionCategory?: PermissionCategory | null;
  readonly closePage: () => void;
  readonly mode: PERMISSION_GROUP_EDIT_DRAWER_MODES;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly permissionGroupName?: string;
  readonly additionalScopesToEdit?: readonly PermissionDto[];
}

export const PermissionGroupEditDrawerPage = ({
  refreshPermissionGroup,
  selectedPermission: permissionGroup,
  permissionGroupMembers,
  selectedPermissionCategory,
  closePage,
  mode = PERMISSION_GROUP_EDIT_DRAWER_MODES.member,
  setLoading,
  permissionGroupName,
  additionalScopesToEdit,
}: PermissionGroupEditDrawerPageProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const isAdminPermissionGroup = permissionGroupName === 'Admin';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const [updatedScopeState, setUpdatedScopeState] = useState<Record<string, boolean> | undefined>(undefined);
  const [originalScopeState, setOriginalScopeState] = useState<Record<string, boolean>>({});
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [userListForMembershipDeletion, setUserListForMembershipDeletion] = useState<
    number[] | PermissionGroupMemberUserDto[]
  >([]);
  const [selectedInclusions, setSelectedInclusions] = useState<number[] | PermissionGroupMemberUserDto[]>(
    permissionGroup?.inclusions ?? []
  );
  const [selectedExclusions, setSelectedExclusions] = useState<number[] | PermissionGroupMemberUserDto[]>(
    permissionGroup?.exclusions ?? []
  );

  const currentMembers = useMemo(() => permissionGroupMembers?.map((m) => m.userId) ?? [], [permissionGroupMembers]);
  const appsError = useMemo(() => {
    if (updatedScopeState && selectedPermissionCategory?.name === 'Apps') {
      if (!updatedScopeState['apps'] && updatedScopeState['apps.connect']) return true;
    }
    return false;
  }, [updatedScopeState, selectedPermissionCategory?.name]);

  const MODES_FOR_TITLE = [PERMISSION_GROUP_EDIT_DRAWER_MODES.general, PERMISSION_GROUP_EDIT_DRAWER_MODES.add];
  const VIEW_ONLY_MODE = mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.view;

  const usersNotInMemberList = useMemo(
    () =>
      companyUsers
        .filter((u) => !currentMembers.includes(u.userId))
        .map((u) => {
          return {
            label: u.displayName ?? '',
            value: u.userId,
          };
        }),
    [companyUsers, currentMembers]
  );

  const initialValues: PermissionGroupEditFormData = useMemo(
    () => ({
      name: [PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) ? '' : permissionGroup?.name ?? '',
      description: [PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) ? '' : permissionGroup?.description ?? '',
      members: [],
      userListForMembershipDeletion: [],
    }),
    [permissionGroup, mode]
  );

  const updatePermissionGroupMembers = useCallback(
    async (members: number[], name: string, description: string): Promise<void> => {
      if (!permissionGroup) return;
      try {
        if (isAdminPermissionGroup) await PermissionGroupAPI.updatePermissionGroupMembers(permissionGroup.id, members);
        else
          await PermissionGroupAPI.updatePermissionGroupDetails(permissionGroup.id, {
            members,
            name,
            description,
            inclusions: convertUserSummariesToIds(selectedInclusions),
            exclusions: convertUserSummariesToIds(selectedExclusions),
            customExclRule: permissionGroup.customExclRule,
            targetsExclRule: permissionGroup.targetsExclRule,
          });
        showMessage(polyglot.t('PermissionGroupEditDrawerPage.successMessages.membersUpdate'), 'success');
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [polyglot, permissionGroup, isAdminPermissionGroup, showMessage, selectedInclusions, selectedExclusions]
  );

  const createPermissionGroup = useCallback(
    async (name: string, description: string, members: number[]): Promise<void> => {
      if (!name) return;
      try {
        const newPermissionGroupPayload: CreatePermissionGroupDto = {
          name,
          description,
          members,
          scopes: [],
          editMembers: true,
          inclusions: convertUserSummariesToIds(selectedInclusions),
          exclusions: convertUserSummariesToIds(selectedExclusions),
        };
        await PermissionGroupAPI.createPermissionGroup(newPermissionGroupPayload);
        showMessage(polyglot.t('PermissionGroupEditDrawerPage.successMessages.groupCreated'), 'success');
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [showMessage, polyglot, selectedInclusions, selectedExclusions]
  );

  const enablePermissionScope = useCallback(
    async (scopeList: string[]): Promise<void> => {
      if (!permissionGroup) return;

      try {
        if (scopeList.length > 0) {
          const enableScopePromises = scopeList.map((eachScope) => {
            return PermissionGroupAPI.enablePermissionScope(permissionGroup.id, eachScope);
          });
          await Promise.all(enableScopePromises);
          showMessage(polyglot.t('PermissionGroupEditDrawerPage.successMessages.scopeEnabled'), 'success');
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [showMessage, permissionGroup, polyglot]
  );

  const disablePermissionScope = useCallback(
    async (scopeList: string[]): Promise<void> => {
      if (!permissionGroup) return;

      try {
        if (scopeList.length > 0) {
          const disableScopePromises = scopeList.map((eachScope) => {
            return PermissionGroupAPI.disablePermissionScope(permissionGroup.id, eachScope);
          });
          await Promise.all(disableScopePromises);
          showMessage(polyglot.t('PermissionGroupEditDrawerPage.successMessages.scopeDisabled'), 'success');
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [showMessage, permissionGroup, polyglot]
  );

  const formik = useFormik<PermissionGroupEditFormData>({
    initialValues,
    validationSchema: PermissionGroupEditFormSchema(polyglot),
    onSubmit: async (_values): Promise<void> => {
      if (!updatedScopeState) return;
      try {
        setIsSubmitting(true);
        setLoading(true);
        if (
          mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.member ||
          mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.general ||
          mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.target
        ) {
          if (permissionGroup && permissionGroup.id) {
            const updatedMemberList = [
              ...currentMembers.filter(
                (m: number) => !convertUserSummariesToIds(userListForMembershipDeletion).includes(m)
              ),
              ...formik.values.members,
            ];
            await updatePermissionGroupMembers(updatedMemberList, formik.values.name, formik.values.description);
            await refreshPermissionGroup();
          }
        }
        if (mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.scope) {
          const scopesToDisable: string[] = [];
          const scopesToEnable: string[] = [];
          // eslint-disable-next-line array-callback-return
          Object.keys(updatedScopeState).map(async (eachKey) => {
            if (updatedScopeState[eachKey] !== originalScopeState[eachKey]) {
              if (!updatedScopeState[eachKey]) scopesToDisable.push(eachKey);
              else if (updatedScopeState[eachKey]) scopesToEnable.push(eachKey);
            }
          });
          await Promise.all([disablePermissionScope(scopesToDisable), enablePermissionScope(scopesToEnable)]);
          await refreshPermissionGroup();
        }
        if (mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.add) {
          await createPermissionGroup(formik.values.name, formik.values.description, formik.values.members);
        }
        closePage();
      } catch (error) {
        showMessage(
          `${polyglot.t('PermissionGroupEditDrawerPage.errorMessages.update')} ${
            PERMISSION_GROUP_EDIT_DRAWER_MODES.member
              ? polyglot.t('PermissionGroupEditDrawerPage.errorMessages.members')
              : polyglot.t('PermissionGroupEditDrawerPage.errorMessages.group')
          }: ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setIsSubmitting(false);
        setLoading(false);
      }
    },
  });

  const getDrawerTitle = (currentMode: PERMISSION_GROUP_EDIT_DRAWER_MODES) => {
    if (currentMode === PERMISSION_GROUP_EDIT_DRAWER_MODES.member)
      return polyglot.t('PermissionGroupEditDrawerPage.editGroup');
    if (currentMode === PERMISSION_GROUP_EDIT_DRAWER_MODES.add)
      return polyglot.t('PermissionGroupEditDrawerPage.addGroup');
    else return '';
  };

  useEffect(() => {
    formik.setFieldValue('userListForMembershipDeletion', userListForMembershipDeletion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListForMembershipDeletion]);

  const scopesToEdit = useMemo(() => {
    return selectedPermissionCategory?.subcategories[0]?.permissions;
  }, [selectedPermissionCategory]);

  useEffect(() => {
    const scopeMap: Record<string, boolean> = {};
    for (const eachPermission of selectedPermissionCategory?.subcategories[0]?.permissions ?? []) {
      scopeMap[eachPermission.scope] = !!eachPermission?.permission_group_scope?.enabled;
    }

    for (const eachPermission of additionalScopesToEdit ?? []) {
      scopeMap[eachPermission.scope] = !!eachPermission?.permission_group_scope?.enabled;
    }

    setUpdatedScopeState(scopeMap);
    setOriginalScopeState(Object.assign({}, scopeMap));
  }, [scopesToEdit, additionalScopesToEdit, selectedPermissionCategory?.subcategories]);

  const isAdditionalScopesListVisible = useMemo((): boolean => {
    if (isAdminPermissionGroup || !additionalScopesToEdit || additionalScopesToEdit.length === 0) return false;

    if (selectedPermissionCategory?.name === 'Administration')
      return Boolean(updatedScopeState && updatedScopeState['user.onboard:all']);

    if (selectedPermissionCategory?.name === 'Payroll')
      return Boolean(updatedScopeState && updatedScopeState['payroll:all']);

    return true;
  }, [isAdminPermissionGroup, additionalScopesToEdit, selectedPermissionCategory, updatedScopeState]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Stack>
          {MODES_FOR_TITLE.includes(mode) ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
              <Typography sx={{ ...themeFonts.title2 }}>{getDrawerTitle(mode)}</Typography>
              {PERMISSION_GROUP_EDIT_DRAWER_MODES.member === mode && (
                <Typography sx={{ ...themeFonts.caption }}>
                  {isAdminPermissionGroup
                    ? polyglot.t('PermissionGroupEditDrawerPage.defaultGroupDesc')
                    : polyglot.t('PermissionGroupEditDrawerPage.customGroupDesc')}
                </Typography>
              )}
            </Box>
          ) : (
            <Typography sx={{ ...themeFonts.title2 }}>
              {translatePermissionCategory(selectedPermissionCategory ? selectedPermissionCategory.name : '', polyglot)}
            </Typography>
          )}
          {mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.general && (
            <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('PermissionGroups.editGeneral')}</Typography>
          )}
          {mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.target && (
            <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('PermissionGroups.editTargets')}</Typography>
          )}
          {mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.member && (
            <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('PermissionGroups.editMembers')}</Typography>
          )}
        </Stack>
        {!isAdminPermissionGroup &&
          [PERMISSION_GROUP_EDIT_DRAWER_MODES.general, PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
              <Box sx={fieldSx}>
                <TextfieldComponent
                  name="name"
                  label={polyglot.t('PermissionGroupEditDrawerPage.nameLabel')}
                  value={formik.values.name}
                  type="text"
                  onChange={formik.handleChange}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={(formik.touched.name && formik.errors.name) ?? ' '}
                  clearText={() => formik.setFieldValue('name', '')}
                />
              </Box>
              <Box sx={fieldSx}>
                <TextfieldComponent
                  name="description"
                  label={polyglot.t('PermissionGroupEditDrawerPage.descLabel')}
                  value={formik.values.description}
                  type="text"
                  onChange={formik.handleChange}
                  error={formik.touched.description && !!formik.errors.description}
                  helperText={(formik.touched.description && formik.errors.description) ?? ' '}
                  clearText={() => formik.setFieldValue('description', '')}
                />
              </Box>
            </Box>
          )}
        {[PERMISSION_GROUP_EDIT_DRAWER_MODES.member, PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <UserSelect
                label={polyglot.t('PermissionGroupEditDrawerPage.whoSelect')}
                selectedLabel="Selected members"
                value={formik.values.members}
                onChange={(userIds: number[]) => {
                  formik.setFieldValue('members', userIds);
                }}
                userOptions={usersNotInMemberList}
                fieldSx={{ ...spacing.mb20 }}
              />
            </Box>
            <PermissionGroupMemberRemoverList
              setUserListForMembershipDeletion={setUserListForMembershipDeletion}
              userListForMembershipDeletion={convertUserSummariesToIds(userListForMembershipDeletion)}
              existingMemberList={currentMembers}
            />
          </Box>
        )}
        {[PERMISSION_GROUP_EDIT_DRAWER_MODES.target].includes(mode) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <UserSelect
                label={polyglot.t('PermissionGroupListingTable.targets.include')}
                selectedLabel={polyglot.t('PermissionGroupListingTable.targets.includedTargets')}
                value={convertUserSummariesToIds(selectedInclusions)}
                onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
                  setSelectedInclusions([...userIds]);
                  if (filterValue === UserSelectFiltersOptions.Everyone) {
                    setSelectedInclusions([]);
                    formik.setFieldValue('inclusions', []);
                  } else {
                    setSelectedInclusions([...userIds]);
                    formik.setFieldValue('inclusions', [...userIds]);
                  }
                  formik.setFieldValue('targetsRule', filterValue);
                  formik.setFieldValue('customRule', customRule);
                }}
                initialFilterValue={
                  !selectedInclusions || selectedInclusions.length === 0
                    ? UserSelectFiltersOptions.Everyone
                    : UserSelectFiltersOptions.SelectSpecific
                }
                fieldSx={{ ...spacing.mb20 }}
                excludeCustomRule={true}
                allowEmpty={true}
              />
            </Box>
            <br />
            <Box sx={fieldSx}>
              <UserSelect
                label={polyglot.t('PermissionGroupListingTable.targets.exclude')}
                selectedLabel={polyglot.t('PermissionGroupListingTable.targets.excludedTargets')}
                value={convertUserSummariesToIds(selectedExclusions)}
                onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
                  if (filterValue === UserSelectFiltersOptions.None) {
                    setSelectedExclusions([]);
                    formik.setFieldValue('exclusions', []);
                  } else {
                    setSelectedExclusions([...userIds]);
                    formik.setFieldValue('exclusions', [...userIds]);
                  }
                  formik.setFieldValue('targetsExclRule', filterValue);
                  formik.setFieldValue('customExclRule', customRule);
                }}
                initialFilterValue={
                  !selectedExclusions || selectedExclusions.length === 0
                    ? UserSelectFiltersOptions.None
                    : UserSelectFiltersOptions.SelectSpecific
                }
                fieldSx={{ ...spacing.mb20 }}
                excludeEveryone={true}
                excludeCustomRule={true}
                excludeNone={false}
                allowEmpty={true}
              />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {[PERMISSION_GROUP_EDIT_DRAWER_MODES.scope, PERMISSION_GROUP_EDIT_DRAWER_MODES.view].includes(mode) &&
            scopesToEdit?.map((eachPermission, idx) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: spacing.g20,
                  width: '100%',
                  py: spacing.m15,
                  borderBottom: idx === scopesToEdit.length - 1 ? 'none' : borders.light,
                }}
              >
                <Box sx={{ maxWidth: '90%' }}>
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                    {translatePermissionCategoryDesc(eachPermission.name, polyglot)}
                  </Typography>
                </Box>

                <Box>
                  <CheckboxComponent
                    label={undefined}
                    name={eachPermission.name}
                    checked={updatedScopeState ? updatedScopeState[eachPermission.scope] : false}
                    disabled={VIEW_ONLY_MODE}
                    onChange={() => {
                      setUpdatedScopeState((prevState) => ({
                        ...prevState,
                        [eachPermission.scope]: prevState ? !prevState[eachPermission.scope] : true,
                      }));
                    }}
                  />
                </Box>
              </Box>
            ))}
        </Box>
        {additionalScopesToEdit && isAdditionalScopesListVisible && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={themeFonts.title4}>
                {polyglot.t('PermissionGroupEditDrawerPage.additionalScopeReco')}
              </Typography>
              <Box>
                <CheckboxComponent
                  label={undefined}
                  checked={
                    additionalScopesToEdit.filter(
                      (permission) => updatedScopeState && updatedScopeState[permission.scope]
                    ).length === additionalScopesToEdit.length
                  }
                  disabled={VIEW_ONLY_MODE}
                  onChange={() => {
                    const areAllChecked =
                      additionalScopesToEdit.filter(
                        (permission) => updatedScopeState && updatedScopeState[permission.scope]
                      ).length === additionalScopesToEdit.length;

                    for (const permission of additionalScopesToEdit) {
                      setUpdatedScopeState((prevState) => ({
                        ...prevState,
                        [permission.scope]: !areAllChecked,
                      }));
                    }
                  }}
                />
              </Box>
            </Box>
            {additionalScopesToEdit.map((eachPermission, idx) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: spacing.g20,
                  width: '100%',
                  py: spacing.m15,
                  borderBottom: idx === additionalScopesToEdit.length - 1 ? 'none' : borders.light,
                }}
              >
                <Box sx={{ maxWidth: '90%' }}>
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                    {eachPermission.name}
                  </Typography>
                </Box>

                <Box>
                  <CheckboxComponent
                    label={undefined}
                    name={eachPermission.name}
                    checked={updatedScopeState ? updatedScopeState[eachPermission.scope] : false}
                    disabled={VIEW_ONLY_MODE}
                    onChange={() => {
                      setUpdatedScopeState((prevState) => ({
                        ...prevState,
                        [eachPermission.scope]: prevState ? !prevState[eachPermission.scope] : true,
                      }));
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {appsError && (
          <FormHelperText
            sx={{ margin: 0, padding: 0, ...themeFonts.caption, color: themeColors.RedDark }}
            id="component-error-text"
          >
            {polyglot.t('PermissionGroupEditDrawerPage.appScopeDesc')}
          </FormHelperText>
        )}
        {!VIEW_ONLY_MODE && (
          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton fullWidth type="submit" loading={isSubmitting} sizeVariant="medium" colorVariant="primary">
              {polyglot.t('General.save')}
            </LoaderButton>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};
