import { AbsenceEndpoints } from '@/v2/feature/absence/absence.api';
import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import { AttendanceDto, AttendanceScheduleDto } from '@/v2/feature/attendance/attendance.dto';
import { getWeekInterval } from '@/v2/feature/attendance/attendance.util';
import { WeekCalendar } from '@/v2/feature/attendance/components/week-calendar.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const AttendanceUserWeekCalendar = ({
  userId,
  year,
  weekNo,
  userAttendanceSchedule,
  weekAttendances,
  refreshWeekAttendances,
}: {
  userId: number;
  year: number;
  weekNo: number;
  userAttendanceSchedule: AttendanceScheduleDto | undefined | null;
  weekAttendances: AttendanceDto[];
  refreshWeekAttendances: () => Promise<void>;
}) => {
  const { start, end } = getWeekInterval(year, weekNo);

  const { data: userAbsences } = useApiClient<readonly AbsenceDto[], Error>(
    AbsenceEndpoints.fetchUserAllowedAbsencesByDateRange({ start, end }, userId),
    { suspense: false }
  );

  return (
    <WeekCalendar
      userId={userId}
      data={weekAttendances}
      attendanceSchedule={userAttendanceSchedule}
      weekNo={weekNo}
      year={year}
      refreshRequests={async () => {
        await refreshWeekAttendances();
      }}
      userAbsences={userAbsences ?? []}
    />
  );
};
