import { useCallback, useContext, useEffect, useState } from 'react';

import { Box, FormControl, IconButton, Typography } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import {
  DeliveryMethod,
  DeviceContractLength,
  DevicePossessionType,
  OrderDeliveryCountry,
} from '@v2/feature/device/device.interface';
import {
  DeliveryCountryOptions,
  DeliveryMethodsOptions,
  DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS,
  DeviceOwnershipOptions,
  DevicePurchaseOption,
  DeviceRentOption,
  getFullPriceDetails,
  getRentalPaymentDetails,
} from '@v2/feature/device/device.util';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { LocalDate } from '@v2/util/local-date';
import { formatAddress } from '@v2/util/user-data.util';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { SiteAPI } from '@/api-client/site.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DeviceDeliveryDetails, DeviceModelDto, OrderDeviceDto } from '@/v2/feature/device/device.dto';
import { getModelImage } from '@/v2/feature/device/device.util';
import { ZeltMdmUpgradeToPro } from '@/v2/feature/device/features/devices-settings/features/zelt-mdm/components/zelt-mdm-upgrade-to-pro.component';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { fieldSx, titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserAPI } from '@/v2/feature/user/user.api';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { StyledFormCheckbox } from '@/v2/styles/checkbox.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: 14, height: 14 } as const;

const getDeviceDetails = (deviceModel: DeviceModelDto, close: () => void) => {
  return (
    <Box>
      <Typography sx={{ ...themeFonts.title4 }}>Device</Typography>
      <Box sx={{ display: 'flex', width: '100%', mt: spacing.m10, justifyContent: 'space-between' }}>
        <Box sx={{ width: '20%', alignItems: 'center' }}>
          <Box>{getModelImage(deviceModel?.type, deviceModel?.modelName, { width: '60px', height: 'auto' })}</Box>
        </Box>
        <Box sx={{ width: '55%', display: 'inline-block' }}>
          <Typography sx={{ ...themeFonts.title4, mb: spacing.m10, color: themeColors.DarkGrey }}>
            {deviceModel.modelName}
          </Typography>
          <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey, mb: spacing.m5 }}>
            Version:{deviceModel?.modelVersion}
          </Typography>
          <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey, mb: spacing.m5 }}>
            Size:{deviceModel?.screenSize}"
          </Typography>
          <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey, mb: spacing.m5 }}>
            Storage:{deviceModel?.storage}GB
          </Typography>
          <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey, mb: spacing.m5 }}>
            RAM:{deviceModel?.ram}GB
          </Typography>
          <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey, mb: spacing.m5 }}>
            GPU:{deviceModel?.gpuCores}-cores
          </Typography>
        </Box>
        <Box sx={{ width: '15%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => {
              close();
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Trash {...iconSize} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export const OrderDeviceSchema = Yup.object().shape({
  deliveryMethod: Yup.string().required(),
  contractLength: Yup.number().required('Contract length is required'),
  deviceModelId: Yup.number().required('Device model is required'),
  possessionType: Yup.string().required(),
  possessionId: Yup.number().required('Deliver to is required'),
  delivery: Yup.object().shape({
    address: Yup.string().required('Delivery Address is required'),
    date: Yup.string().test(dateFieldTest).required('Delivery date is required'),
  }),
  phoneNumber: Yup.string().nullable().required('Phone number length is required'),
  isPurchase: Yup.boolean().required(),
});

interface OrderDevicesProps {
  deviceModel: DeviceModelDto;
  reach: string;
  possessionId?: number;
  close: (orderComplete: boolean) => Promise<void>;
}

export const OrderDevicesForm = ({ deviceModel, close, reach, possessionId }: OrderDevicesProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [termsAcceptedError, setTermsAcceptedError] = useState<boolean>(false);

  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: globalState.user.userId });
  // const canRentDevices = globalState.user.currentPlans.DEVICES.pro; // OLD LOGIC
  const [showMessage] = useMessage();
  const hasDeviceAll = hasScopes(['devices:all']);
  const hasDevicesPriceScope = hasScopes(['devices.price:read', 'devices:all'], scopesContext);
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);
  const [canRentDevices, setCanRentDevices] = useState<boolean>(false);

  useEffect(() => {
    const checkIfDeviceOrderIsAllowed = async () => {
      try {
        await DeviceAPI.deviceOrderAllowed();
        setCanRentDevices(true);
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setCanRentDevices(false);
        }
      }
    };
    checkIfDeviceOrderIsAllowed();
  }, []);

  const formik = useFormik<OrderDeviceDto>({
    initialValues: {
      deliveryMethod: DeliveryMethod.ShippingToEmployee,
      contractLength: DeviceContractLength.Months36,
      deviceModelId: deviceModel.id,
      possessionType: DevicePossessionType.User,
      possessionId: reach !== 'me' ? possessionId : globalState.user.userId,
      phoneNumber: phone,
      delivery: {
        address,
        date: new LocalDate(dayjs().add(deviceModel.deliveryDays, 'day').toDate()).toDateString(),
        country: OrderDeliveryCountry.UK,
      },
      isPurchase: false,
    },
    validationSchema: OrderDeviceSchema,
    onSubmit: async (values) => {
      try {
        if (!values.possessionId) {
          showMessage('No owner selected', 'error');
          return;
        }
        setLoading(true);
        const delivery = { ...values.delivery };
        const possessionDetails = {
          possessionType: values.possessionType,
          possessionId: values.possessionId,
        };
        await DeviceAPI.orderDevice(
          deviceModel.id,
          possessionDetails,
          delivery as DeviceDeliveryDetails,
          values.contractLength,
          values.phoneNumber,
          values.isPurchase
        );
        showMessage('Device successfully ordered.', 'success');
        await close(true);
      } catch (error) {
        const defaultError = 'Device could not be ordered. Please try again.';
        showMessage(nestErrorMessage(error) || defaultError, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchUserAddress = useCallback(
    async (assignedUserId: number): Promise<string> => {
      let addressLine = '';
      try {
        const userAddress = await UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          addressLine = formatAddress(effectiveAddress);
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }

      return addressLine;
    },
    [showMessage]
  );

  const fetchUserPhone = useCallback(
    async (assignedUserId: number): Promise<string> => {
      try {
        const userPersonalInfo = await UserAPI.getUserPersonalInfo(assignedUserId);
        return userPersonalInfo?.phone ?? '';
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }

      return '';
    },
    [showMessage]
  );

  const getSites = useCallback(async () => {
    try {
      const sites = await SiteAPI.listSites();
      setSitesOptions(
        sites.map((site) => {
          return { value: site.id, label: site.name, address: site.address ?? '' };
        })
      );
    } catch (error) {
      showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  const setUserDetails = useCallback(
    async (userId: number) => {
      const userAddress = await fetchUserAddress(userId);
      setAddress(userAddress);

      const userPhone = await fetchUserPhone(userId);
      setPhone(userPhone);
    },
    [fetchUserAddress, fetchUserPhone]
  );

  useEffect(() => {
    (async () => {
      await getSites();
      if (reach === 'me') {
        await setUserDetails(globalState.user.userId);
      } else if (possessionId) {
        await setUserDetails(possessionId);
      }
      if (!hasDeviceAll) setTermsAccepted(true);
    })();
  }, [setUserDetails, reach, hasDeviceAll, getSites, possessionId, globalState.user.userId]);

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsAccepted]);

  return (
    <FormikProvider value={formik}>
      <Form>
        {showUpgrade ? (
          <ZeltMdmUpgradeToPro />
        ) : (
          <Box>
            <Typography sx={titleSx}>{hasDeviceAll ? 'Order device' : 'Request Device'}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
              {reach !== 'me' && (
                <>
                  <Box sx={fieldSx}>
                    <SelectComponent
                      name="deliveryMethod"
                      label="Delivery method"
                      // Hide the handover option here
                      options={DeliveryMethodsOptions.filter((o) => o.value !== DeliveryMethod.Handover)}
                      value={formik.values.deliveryMethod}
                      compareValue={formik.values.deliveryMethod}
                      error={!!formik.errors.deliveryMethod && formik.touched.deliveryMethod}
                      onChange={(e) => {
                        formik.handleChange(e);

                        formik.setFieldValue(
                          'possessionType',
                          e.target.value === DeliveryMethod.ShippingToSite
                            ? DevicePossessionType.CompanySite
                            : DevicePossessionType.User
                        );

                        formik.setFieldValue('possessionId', undefined);
                        formik.setFieldValue('delivery.address', '');
                        formik.setFieldValue('phoneNumber', '');
                      }}
                      helperText={(formik.touched.deliveryMethod && formik.errors.deliveryMethod) as string}
                      disabled={true}
                    />
                  </Box>

                  <Box sx={fieldSx}>
                    {[DeliveryMethod.ShippingToEmployee, DeliveryMethod.Handover].includes(
                      formik.values.deliveryMethod
                    ) ? (
                      <SingleUserSelect
                        name="possessionId"
                        options="company"
                        onChange={async (_, option) => {
                          const userId = option?.value;
                          await formik.setFieldValue('possessionId', userId ?? null);
                          if (userId) {
                            const [address, phoneNumber] = await Promise.all([
                              fetchUserAddress(Number(userId)),
                              fetchUserPhone(Number(userId)),
                            ]);

                            await formik.setFieldValue('delivery.address', address);
                            await formik.setFieldValue('phoneNumber', phoneNumber);
                          }
                        }}
                        disabled={!!possessionId}
                        value={formik.values.possessionId}
                        label="Assigned user"
                        error={formik.touched.possessionId && Boolean(formik.errors.possessionId)}
                        helperText={(formik.touched.possessionId && formik.errors.possessionId) as string}
                      />
                    ) : (
                      <SelectComponent
                        name="possessionId"
                        label="Site"
                        options={sitesOptions}
                        value={formik.values.possessionId}
                        compareValue={formik.values.possessionId}
                        error={!!formik.errors.possessionId && formik.touched.possessionId}
                        helperText={(formik.touched.possessionId && formik.errors.possessionId) as string}
                        onChange={async (e) => {
                          await formik.handleChange(e);
                          const siteAddress = sitesOptions.find((s) => s.value === e.target.value)?.address ?? '';
                          await formik.setFieldValue('delivery.address', siteAddress);
                        }}
                      />
                    )}
                  </Box>
                </>
              )}

              <Box sx={fieldSx}>
                <FormControl size="small" fullWidth>
                  <DatePickerComponent
                    inputFormat="DD/MM/YYYY"
                    value={formik.values.delivery.date ?? dayjs()}
                    onChange={(value) => {
                      if (dayjs(value).isValid()) {
                        formik.setFieldValue('delivery.date', value);
                      }
                    }}
                    name="delivery.date"
                    label="Delivery date"
                    error={!!formik.touched.delivery?.date && !!formik.errors.delivery?.date}
                    helperText={(formik.touched.delivery?.date && formik.errors.delivery?.date) as string}
                    disablePast
                    minDate={dayjs().add(
                      formik.values.delivery?.country === OrderDeliveryCountry.UK
                        ? deviceModel.deliveryDays ?? 7
                        : deviceModel.deliveryDaysEu ?? 14,
                      'day'
                    )}
                  />
                </FormControl>
              </Box>

              <Box sx={fieldSx}>
                <TextfieldComponent
                  name="delivery.address"
                  label="Delivery address"
                  value={formik.values.delivery.address || address}
                  type="text"
                  onChange={formik.handleChange}
                  error={formik.touched.delivery?.address && !!formik.errors.delivery?.address}
                  helperText={(formik.touched.delivery?.address && formik.errors.delivery?.address) as string}
                  clearText={() => formik.setFieldValue('delivery.address', '')}
                />
              </Box>

              <Box sx={fieldSx}>
                <SelectComponent
                  name="delivery.country"
                  label="Delivery Country"
                  options={DeliveryCountryOptions}
                  value={formik.values.delivery.country ?? OrderDeliveryCountry.UK}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const deliveryDate = new Date();
                    if (e.target.value === OrderDeliveryCountry.EU) {
                      deliveryDate.setDate(deliveryDate.getDate() + (deviceModel.deliveryDaysEu ?? 14));
                      formik.setFieldValue('delivery.date', dayjs(deliveryDate));
                    } else {
                      deliveryDate.setDate(deliveryDate.getDate() + (deviceModel.deliveryDays ?? 7));
                      formik.setFieldValue('delivery.date', dayjs(deliveryDate));
                    }
                  }}
                  error={formik.touched.delivery?.country && !!formik.errors.delivery?.country}
                  helperText={(formik.touched.delivery?.country && formik.errors.delivery?.country) as string}
                />
              </Box>

              <Box sx={fieldSx}>
                <TextfieldComponent
                  name="phoneNumber"
                  label="Phone Number"
                  value={formik.values.phoneNumber ?? phone}
                  type="text"
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
                  helperText={(formik.touched.phoneNumber && formik.errors.phoneNumber) ?? ' '}
                  clearText={() => formik.setFieldValue('phoneNumber', '')}
                />
              </Box>

              {deviceModel.fullPrice && (
                <Box sx={fieldSx}>
                  <SelectComponent
                    name="deviceOwnership"
                    label="Checkout option"
                    options={DeviceOwnershipOptions}
                    value={formik.values.isPurchase ? DevicePurchaseOption.value : DeviceRentOption.value}
                    onChange={(e) => {
                      const ownershipChoice = e?.target.value;
                      formik.setFieldValue('isPurchase', ownershipChoice === 'purchase');
                    }}
                  />
                </Box>
              )}

              {!formik.values.isPurchase && (
                <Box sx={fieldSx}>
                  <SelectComponent
                    name="contractLength"
                    label="Contract Length"
                    compareValue={formik.values.contractLength}
                    options={DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS}
                    value={formik.values.contractLength}
                    onChange={formik.handleChange}
                  />
                </Box>
              )}

              {deviceModel && <Box sx={fieldSx}>{getDeviceDetails(deviceModel, () => close(false))}</Box>}
              {deviceModel && hasDevicesPriceScope && (
                <Box sx={fieldSx}>
                  {formik.values.isPurchase
                    ? getFullPriceDetails(deviceModel, formik.values.delivery.country)
                    : getRentalPaymentDetails(
                        deviceModel,
                        formik.values.contractLength,
                        formik.values.delivery.country
                      )}
                </Box>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
                {!formik.values.isPurchase && hasDeviceAll && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'flex-start', gap: spacing.g10, cursor: 'pointer' }}
                    onClick={() => {
                      if (termsAccepted) {
                        setTermsAccepted(false);
                      } else {
                        setTermsAccepted(true);
                        setTermsAcceptedError(false);
                      }
                    }}
                  >
                    <StyledFormCheckbox
                      sx={{
                        '&label.MuiFormControlLabel-root': {
                          display: 'flex',
                          alignItems: 'flex-start !important',
                        },
                      }}
                      name="terms"
                      checked={termsAccepted}
                    />
                    <Typography sx={{ ...themeFonts.caption }}>
                      Agree with the Framework Agreement for the Rental of Devices.{' '}
                      <a
                        style={{ textDecoration: 'none', color: themeColors.ZeltYellow }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://zelt.app/legal-devices-framework-agreement-summary/"
                      >
                        Read more here.
                      </a>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  opacity: termsAcceptedError ? 1 : 0,
                  ...themeFonts.caption,
                  color: themeColors.RedDark,
                  mb: spacing.m10,
                  transition: 'opacity 0.3s',
                }}
              >
                Please agree to the terms & conditions
              </Typography>

              <LoaderButton
                name={hasDeviceAll ? 'Order' : 'Request'}
                loading={loading}
                fullWidth
                type="button"
                onClick={() => {
                  if (!termsAccepted && !formik.values.isPurchase) setTermsAcceptedError(true);
                  else if (
                    (!canRentDevices && !formik.values.isPurchase) ||
                    (!globalState.user.hasPaymentMethodOnFile && hasDeviceAll)
                  )
                    setShowUpgrade(true);
                  else {
                    formik.handleSubmit();
                  }
                }}
                colorVariant="primary"
                sizeVariant="medium"
              />
            </Box>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};
