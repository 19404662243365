import { useCallback, useEffect, useState } from 'react';

import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';

import { ShowMessage } from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  APPS_COMPANY_OVERVIEW_ROUTE,
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE,
  APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE,
  APP_INTEGRATION_DETAILS_GROUPS_ROUTE,
  APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE,
  APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_CANDIDATES_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_EMPLOYMENTS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_GROUPS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_INSIGHTS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { CurrentUser, ScopeContext } from '@/models';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  EmploymentDetail,
  GroupMembership,
  InstalledAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub, CountryListing } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { useLocalStorage } from '@/v2/util/use-local-storage.util';

export const REVOKE_ACCESS_LIST = [401, 404, 500];

export type ErrorHandler = (err: { response: { data: { statusCode: number } } }) => Promise<void>;

export function useAppDetails(appStub: AppIntegrationStub, handleAppAPIError: ErrorHandler, inCompanyView = true) {
  const routerHistory = useHistory();

  const [app, setApp] = useState<InstalledAppDto | undefined>(undefined);
  const IntegrationUsersPath = generatePath(
    inCompanyView
      ? APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE
      : APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
    { appStub }
  );
  const IntegrationAboutPath = generatePath(
    inCompanyView ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
    { appStub }
  );

  useEffect(() => {
    async function fetchAppDetails() {
      try {
        const app = await AppDetailsAPI.getIntegrationData(appStub);
        setApp(app);

        if (app?.authorised) {
          routerHistory.push(IntegrationUsersPath);
        }
        if (!app?.authorised) {
          routerHistory.push(IntegrationAboutPath);
        }
      } catch (err) {
        await handleAppAPIError(err);
      }
    }

    fetchAppDetails();
  }, [appStub, routerHistory, IntegrationUsersPath, IntegrationAboutPath, handleAppAPIError]);

  return { app, setApp };
}

export function useFetchEmployments(appStub: AppIntegrationStub, showMessage: ShowMessage, app?: InstalledAppDto) {
  const [employmentList, setEmploymentList] = useState<readonly EmploymentDetail[]>([]);
  const [countriesForEmployment, setCountriesForEmployment] = useState<CountryListing[]>([]);
  const [loadingEmployments, setLoadingEmployments] = useState<boolean>(false);

  const fetchEmployments = useCallback(async () => {
    try {
      if (!app || !app.authorised || !app.allowsEmploymentManagement) return;
      setLoadingEmployments(true);
      const employmentData = await AppIntegrationAPI.getEmployments(appStub);
      setEmploymentList(employmentData?.employments ?? []);
      setCountriesForEmployment(employmentData?.countriesForEmployment ?? []);
    } catch (error) {
      setEmploymentList([]);
      showMessage(`Encountered an error: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoadingEmployments(false);
    }
  }, [app, appStub, showMessage]);

  useEffect(() => {
    fetchEmployments();
  }, [app, appStub, fetchEmployments, showMessage]);

  return { employmentList, countriesForEmployment, loadingEmployments, fetchEmployments };
}

export function useFetchUsers(
  appStub: AppIntegrationStub,
  showMessage: ShowMessage,
  handleAppAPIError: ErrorHandler,
  app?: InstalledAppDto,
  setAPIError?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const routerHistory = useHistory();
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [users, setUsers] = useState<readonly AppIntegrationUserDto[]>([]);
  const [notEnrolledUsers, setNotEnrolledUsers] = useState<readonly AppIntegrationUserDto[]>([]);
  const [_pendingTeamAppList, setPendingTeamAppList] = useLocalStorage<number[]>('pendingTeamAppList', []);

  const fetchUsers = useCallback(
    async (_stub?: string) => {
      try {
        if (!app || !app.authorised) return;
        setLoadingUsers(true);
        const { users, notEnrolledUsers } = await AppIntegrationAPI.listAppUsers(appStub);
        setUsers(users);
        setNotEnrolledUsers(notEnrolledUsers);
        setPendingTeamAppList([]);
        setAPIError?.(false);
      } catch (error) {
        if (error?.response?.data.statusCode === 400) await handleAppAPIError(error);
        if (error?.response?.data.statusCode === 429) {
          showMessage(`Error while trying to list app users: ${nestErrorMessage(error)}`, 'error');
        }
        if ([401, 403].includes(error?.response?.data.statusCode)) routerHistory.push(APPS_COMPANY_OVERVIEW_ROUTE);
      } finally {
        setLoadingUsers(false);
      }
    },
    [app, appStub, handleAppAPIError, routerHistory, setAPIError, setPendingTeamAppList, showMessage]
  );

  useEffect(() => {
    fetchUsers();
  }, [app, appStub, fetchUsers, handleAppAPIError, setPendingTeamAppList, showMessage]);

  return { loadingUsers, users, notEnrolledUsers, fetchUsers };
}

export function useFetchGroups(
  appStub: AppIntegrationStub,
  showMessage: ShowMessage,
  app?: InstalledAppDto,
  currentUser?: CurrentUser
) {
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

  const [groupMembershipList, setGroupMembershipList] = useLocalStorage<GroupMembership[]>(
    `${appStub}-appGroupMemberships-${currentUser?.company?.companyId}`,
    []
  );
  const fetchGroups = useCallback(async () => {
    try {
      if (!app || !app.authorised || !app.allowsGroupManagement) return;
      setLoadingGroups(true);
      const groupData = await AppIntegrationAPI.getGroupMemberships(appStub);
      setGroupMembershipList(groupData);
    } catch (error) {
      showMessage(`Failed to fetch group data: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoadingGroups(false);
    }
  }, [app, appStub, setGroupMembershipList, showMessage]);

  useEffect(() => {
    fetchGroups();
  }, [appStub, fetchGroups]);

  return { loadingGroups, groupMembershipList, fetchGroups };
}

export function useFetchCandidates(
  appStub: AppIntegrationStub,
  showMessage: ShowMessage,
  handleAppAPIError: ErrorHandler,
  app?: InstalledAppDto
) {
  const [candidateList, setCandidateList] = useState<readonly AppIntegrationUserDto[] | undefined>(undefined);
  const [importedCandidateList, setImportedCandidateList] = useState<readonly AppIntegrationUserDto[] | undefined>(
    undefined
  );
  const [loadingCandidates, setLoadingCandidates] = useState<boolean>(false);

  const fetchCandidates = useCallback(async () => {
    try {
      if (!app || !app.authorised || !app.allowsCandidateManagement) return;
      setLoadingCandidates(true);
      const candidateData = await AppIntegrationAPI.getCandidateList(appStub);
      setCandidateList(candidateData?.notEnrolledUsers ?? []);
      const onboardedCandidates =
        candidateData?.users
          ?.filter((c) => c.userStatus === 'Onboarded')
          .map((c) => {
            return { ...c, userStatus: 'Onboarded in Zelt' };
          }) ?? [];
      setImportedCandidateList(onboardedCandidates);
    } catch (error) {
      setCandidateList([]);
      setImportedCandidateList([]);
      if (error?.response?.data.statusCode === 400) await handleAppAPIError(error);
      if (error?.response?.data.statusCode === 429) {
        showMessage(`Error while trying to fetch candidates: ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setLoadingCandidates(false);
    }
  }, [app, appStub, handleAppAPIError, showMessage]);

  useEffect(() => {
    fetchCandidates();
  }, [appStub, fetchCandidates]);

  return { candidateList, importedCandidateList, loadingCandidates, fetchCandidates };
}

export const TabFilter = [
  { name: 'All', value: 'all' },
  { name: 'Active', value: 'Active' },
  { name: 'Invited', value: 'Invited' },
  { name: 'Imported', value: 'Imported' },
  { name: 'Suspended', value: 'Suspended' },
  { name: 'No access', value: 'No access' },
];

export const EmploymentsTabFilter = [
  { name: 'All', value: 'all' },
  { name: 'Draft', value: 'Draft' },
  { name: 'Active', value: 'Active' },
  { name: 'Onboarded in Zelt', value: 'Onboarded in Zelt' },
  { name: 'No access', value: 'No access' },
];

export const AppStatusTabFilter = (polyglot: Polyglot) => {
  return [
    { name: polyglot.t('AppTeamAccessPage.statuses.All'), value: 'all' },
    { name: polyglot.t('AppTeamAccessPage.statuses.Active'), value: 'Active' },
    { name: polyglot.t('AppTeamAccessPage.statuses.Invited'), value: 'Invited' },
    { name: polyglot.t('AppTeamAccessPage.statuses.Imported'), value: 'Imported' },
    { name: polyglot.t('AppTeamAccessPage.statuses.Suspended'), value: 'Suspended' },
    { name: polyglot.t('AppTeamAccessPage.statuses.Noaccess'), value: 'No access' },
  ];
};

export const getAppPageConfig = (
  polyglot: Polyglot,
  currentUser: CurrentUser,
  context: ScopeContext,
  appStub: AppIntegrationStub,
  app: InstalledAppDto | undefined,
  settingsHide: boolean,
  inCompanyView = true
): readonly NavConfigItem[] => {
  let menu = [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('AppTeamAccessPage.title'),
          stub: 'team-access',
          path: generatePath(
            inCompanyView
              ? APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE
              : APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context) || !app?.authorised,
          hasChildren: false,
        },
        {
          title: polyglot.t('AppExternalNotEnrolledPage.title'),
          stub: 'external',
          path: generatePath(
            inCompanyView
              ? APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE
              : APP_INTEGRATION_PERSONAL_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context) || !app?.authorised,
          hasChildren: false,
        },
        {
          title: polyglot.t('AppGroupManagementPage.title'),
          stub: 'groups',
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_GROUPS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_GROUPS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context) || !app?.authorised || !app?.allowsGroupManagement,
          hasChildren: false,
        },
        {
          title: polyglot.t('AppCandidateManagementPage.title'),
          stub: 'candidates',
          path: generatePath(
            inCompanyView
              ? APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE
              : APP_INTEGRATION_PERSONAL_DETAILS_CANDIDATES_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context) || !app?.authorised || !app?.allowsCandidateManagement,
          hasChildren: false,
        },
        {
          title: app?.customTabMapping?.['employments']
            ? app?.customTabMapping?.['employments']
            : polyglot.t('AppEmploymentManagementPage.title'),
          stub: 'employments',
          path: generatePath(
            inCompanyView
              ? APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE
              : APP_INTEGRATION_PERSONAL_DETAILS_EMPLOYMENTS_ROUTE,
            { appStub }
          ),
          isHidden:
            !checkScopes(currentUser, ['apps'], context) || !app?.authorised || !app?.allowsEmploymentManagement,
          hasChildren: false,
        },
        {
          title: polyglot.t('AppInsightsPage.title'),
          stub: 'insights',
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_INSIGHTS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context) || !app?.authorised || !app?.hasKPIs,
          hasChildren: false,
        },
        {
          title: polyglot.t('AppAboutPage.title'),
          stub: 'about',
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('AppUserSettingPage.title'),
          stub: 'settings',
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_SETTINGS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_SETTINGS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ['apps'], context) || settingsHide,
          hasChildren: false,
        },
      ],
    },
  ];

  return menu as NavConfigItem[];
};
