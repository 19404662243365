/* eslint-disable filenames/match-regex */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { CreateUserContract } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { UserRoleImportValues } from '@/models/user-role.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { OptionObj, OptionsProps } from '@/v2/components/forms/user-select/single-user-select.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AttendanceEndpoints } from '@/v2/feature/attendance/attendance.api';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { EntityImportErrorDto, EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import { ImportedUserTableCell } from '@/v2/feature/entity-import/wizard/components/user/imported-user-table-cell.component';
import { UserImportResultsTabs } from '@/v2/feature/entity-import/wizard/components/user/user-import-results-tabs.component';
import { validatePeopleImport2 } from '@/v2/feature/entity-import/wizard/entity-import-validator.util';
import { JobPosition } from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { JobPositionEndpoints } from '@/v2/feature/job-position/job-position.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import {
  UserImport2Dto,
  UserImportResult2Dto,
  UserImportTableFormatter,
} from '@/v2/feature/user/features/user-import/user-import.dto';
import { UserImportLifecycleForm } from '@/v2/feature/user/features/user-profile/details/components/user-import-lifecycle-form.component';
import { AboutForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-about-form.component';
import { AddressForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component';
import { BankForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-form.component';
import { BasicInformationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-form.component';
import { CompensationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component';
import { ContractForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-contract-form.component';
import { CustomSectionForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component';
import { EmergencyContactForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-form.component';
import { FamilyForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-family-form.component';
import { PersonalInformationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-form.component';
import { RoleForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { getCountryByCode } from '@/v2/infrastructure/country/country.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconCrossButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { camelCaseToTitleCase } from '@/v2/util/string.util';

type Props = {
  result: UserImportResult2Dto;
  onUpdateAndRevalidate: (updatedRecords: EntityImportValidationResultDto<UserImport2Dto>) => void;
  completeImport: (selectionModel?: string[], users?: UserImport2Dto[]) => void;
  loading: boolean;
};

const UNIMPORTED_USER_ID = 0;

export const UserImportResultTable = ({ result, completeImport, onUpdateAndRevalidate, loading }: Props) => {
  const { polyglot } = usePolyglot();

  const [selectedUsers] = useState(new Set<UserImport2Dto>());
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [viewErrorUsers, setViewErrorUsers] = useState(false);

  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const { data: attendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const [currentFormForEditing, setCurrentFormForEditing] = useState(<></>);
  const { data: customProfileForms } = useApiClient(CustomProfileFormEndpoints.listForms(), { suspense: false });

  const { data: deptsData, mutate: refreshDept } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });
  const departments = useMemo<readonly OptionObj[]>(() => {
    return deptsData?.map((dept) => ({ label: dept.name, value: dept.id })) ?? [];
  }, [deptsData]);

  const { data: siteData, mutate: refreshSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const sites = useMemo<readonly OptionObj[]>(() => {
    return siteData?.map((site) => ({ label: site.name, value: site.id })) ?? [];
  }, [siteData]);

  const { data: jobPositionData, mutate: refreshJobPosition } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false,
  });

  const nextPossibleId = useMemo(() => {
    if (!jobPositionData) return '';
    const lastJobPosition = jobPositionData?.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = lastJobPosition[jobPositionData.length - 1]?.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1000000).toString();
    }
  }, [jobPositionData]);

  const DEFAULT_NEW_JOB_POSITION = useMemo(() => {
    return {
      id: undefined,
      internalCode: nextPossibleId,
      title: '',
      description: '',
    };
  }, [nextPossibleId]);

  const jobPositions: OptionObj[] = useMemo(() => {
    return (
      jobPositionData?.map((eachJobPosition: JobPosition) => ({
        label: eachJobPosition.title,
        value: eachJobPosition.id as number,
      })) ?? []
    );
  }, [jobPositionData]);

  const { nonTerminatedCachedUsers: companyUsers } = useCachedUsers({ refresh: false });
  const managers = useMemo<OptionsProps[]>(
    () => companyUsers.map((rec) => ({ label: rec.displayName, value: rec.userId, ...rec })),
    [companyUsers]
  );

  const UserImportTableFormatters = useMemo(
    () => ({
      Role: {
        departmentId: {
          header: 'Department',
          rawCellValue: (item) => {
            if (!item?.departmentId) return '';
            return deptsData?.find((d) => d.id === item.departmentId)?.name;
          },
        },
        siteId: {
          header: 'Site',
          rawCellValue: (item) => {
            if (!item?.siteId) return '';
            return siteData?.find((s) => s.id === item.siteId)?.name;
          },
        },
      } as UserImportTableFormatter<UserRoleImportValues>,
      Contract: {
        entityId: {
          header: 'Entity',
          rawCellValue: (item) => {
            if (!item?.entityId) return '';
            return generalSettings?.entities.find((e) => e.id === item.entityId)?.legalName;
          },
        },
        publicHolidays: {
          header: 'Public Holidays',
          rawCellValue: (item) => {
            if (!item?.publicHolidays) return '';
            return getCountryByCode(item.publicHolidays)?.name;
          },
        },
        attendanceScheduleId: {
          header: 'Attendance Schedule',
          rawCellValue: (item) => {
            if (!item?.attendanceScheduleId) return '';
            return attendanceSchedules?.find((s) => s.id === item.attendanceScheduleId)?.name;
          },
        },
      } as UserImportTableFormatter<CreateUserContract>,
    }),
    [attendanceSchedules, deptsData, generalSettings?.entities, siteData]
  );

  const userIdentifierColumn = useCallback(() => {
    const userIdentColumn: ColumnDef<EntityImportErrorDto<UserImport2Dto>, EntityImportErrorDto<UserImport2Dto>> = {
      header: () => 'User',
      accessorFn: (row) => row,
      id: 'user-import-identifier',
      enableSorting: false,
      cell: (info) => {
        return info.row.original.entity.default.Basic.values.emailAddress ?? '(Missing user ID)';
      },
    };
    return userIdentColumn;
  }, []);

  const buildDefaultFormColumns = useCallback(
    (userImport: UserImport2Dto, sectionName: keyof UserImport2Dto['default']) => {
      const section = userImport.default[sectionName];
      const values = section.values;
      if (!values) return [];
      const fields = Object.keys(values).filter((fieldName) => fieldName !== 'customUpdates');
      const userIdColumn = 'emailAddress' in values ? [] : [userIdentifierColumn()];
      return [
        ...userIdColumn,
        ...fields.map<ColumnDef<EntityImportErrorDto<UserImport2Dto>, EntityImportErrorDto<UserImport2Dto>>>(
          (fieldName) => ({
            header: () =>
              // @ts-ignore
              UserImportTableFormatters[sectionName]?.[fieldName]?.header ?? camelCaseToTitleCase(fieldName),
            accessorFn: (row) => row,
            id: fieldName,
            enableSorting: false,
            cell: (info) => {
              const data = info.row.original.entity.default[sectionName]?.values;
              const field = fieldName as keyof typeof data;
              const value = // @ts-ignore
                UserImportTableFormatters[sectionName]?.[fieldName]?.rawCellValue?.(data) ?? `${data?.[field] ?? ''}`;
              return (
                <ImportedUserTableCell
                  rawCellValue={value}
                  rowErrors={info.row.original.errors}
                  fieldKey={info.column.id}
                />
              );
            },
            maxSize: 150,
            minSize: 150,
          })
        ),
        ...(values.customUpdates ?? []).map<
          ColumnDef<EntityImportErrorDto<UserImport2Dto>, EntityImportErrorDto<UserImport2Dto>>
        >((customField) => ({
          header: () => camelCaseToTitleCase(customField.field.fieldName),
          accessorFn: (row) => row,
          id: customField.fieldId,
          enableSorting: false,
          cell: (info) => {
            const data = info.row.original.entity.default[sectionName].values?.customUpdates;
            const field = data?.find((f) => f.fieldId === customField.fieldId);
            return (
              <ImportedUserTableCell
                rawCellValue={`${field?.value ?? ''}`}
                rowErrors={info.row.original.errors}
                fieldKey={info.column.id}
              />
            );
          },
          maxSize: 150,
          minSize: 150,
        })),
        {
          header: () => '',
          accessorFn: (row) => row,
          id: 'actions',
          enableSorting: false,
          cell: (info) => {
            if (sectionName === 'Basic') {
              // don't allow basic info to be cleared
              return <></>;
            }
            return (
              <IconButton
                sx={{ ...iconCrossButtonSx, backgroundColor: themeColors.white, fill: themeColors.DarkGrey }}
                title={`Clear ${sectionName.toLowerCase()} information for ${
                  info.row.original.entity.default.Basic.values.emailAddress
                }`}
                onClick={async (e) => {
                  e.stopPropagation(); // don't allow the button click to propagate to a row click
                  info.row.original.entity.default[sectionName].values = null;
                  const updated = await validatePeopleImport2(
                    result!.errors.map((e) => e.entity),
                    polyglot
                  );
                  onUpdateAndRevalidate(updated);
                }}
              >
                <Close width={16} height={16} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
              </IconButton>
            );
          },
          maxSize: 50,
          minSize: 50,
        } as ColumnDef<EntityImportErrorDto<UserImport2Dto>, EntityImportErrorDto<UserImport2Dto>>,
      ];
    },
    [UserImportTableFormatters, onUpdateAndRevalidate, result, userIdentifierColumn, polyglot]
  );

  const buildCustomFormColumns = useCallback(
    (tab: string, record: UserImport2Dto['custom'][number]) => {
      return [
        userIdentifierColumn(),
        ...record.fields.map<ColumnDef<EntityImportErrorDto<UserImport2Dto>, EntityImportErrorDto<UserImport2Dto>>>(
          (customField) => ({
            header: () => customField.field.fieldName,
            accessorFn: (row) => row,
            id: customField.fieldId,
            enableSorting: false,
            cell: (info) => {
              const data = info.row.original.entity.custom.find(({ tabName }) => tabName === tab);
              const field = data?.fields.find((f) => f.fieldId === customField.fieldId);
              return (
                <ImportedUserTableCell
                  rawCellValue={`${field?.value ?? ''}`}
                  rowErrors={info.row.original.errors}
                  fieldKey={info.column.id}
                />
              );
            },
            maxSize: 150,
            minSize: 150,
          })
        ),
      ];
    },
    [userIdentifierColumn]
  );

  const tabsAndColumns = useMemo(() => {
    if (!result.errors || result.errors.length === 0) return [];

    const tabsAndColumns: {
      tabName: string;
      formId: string | null;
      columns: ColumnDef<EntityImportErrorDto<UserImport2Dto>, EntityImportErrorDto<UserImport2Dto>>[];
    }[] = [];
    const completedTabs = new Set<string>();
    for (const [key, { tabName }] of Object.entries(result.errors[0].entity.default)) {
      // find an entity with some values for this field
      const sectionName = key as keyof UserImport2Dto['default'];
      const entityWithValues = result.errors.find((e) => e.entity.default[sectionName].values);
      if (entityWithValues) {
        const columns = buildDefaultFormColumns(entityWithValues.entity, sectionName);
        tabsAndColumns.push({ tabName, formId: null, columns });
        completedTabs.add(tabName);
      }
    }

    // add tabs for all the custom sections
    for (const { entity } of result.errors) {
      entity.custom.forEach((c) => {
        if (completedTabs.has(c.tabName)) return;
        const columns = buildCustomFormColumns(c.tabName, c);
        tabsAndColumns.push({ tabName: c.tabName, formId: c.formId, columns });
        completedTabs.add(c.tabName);
      });
    }

    return tabsAndColumns;
  }, [buildCustomFormColumns, buildDefaultFormColumns, result.errors]);

  const editEntity = useCallback(
    (tab: string, entity: UserImport2Dto) => {
      let currentForm: React.JSX.Element;

      async function closeFormAndRevalidate() {
        const updated = await validatePeopleImport2(
          result!.errors.map((e) => e.entity),
          polyglot
        );
        onUpdateAndRevalidate(updated);
        setIsOpen(false);
      }

      switch (tab) {
        case 'Basic':
          currentForm = (
            <BasicInformationForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Basic.values}
              onSubmit={(_values) => {}}
              usedForDataImport
              importHandler={async (values) => {
                entity.default.Basic.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              handleRefresh={() => {}}
            />
          );
          break;

        case 'Details':
          currentForm = (
            <PersonalInformationForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Details.values!}
              onSubmit={(_values) => {}}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Details.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
            />
          );
          break;

        case 'Address':
          currentForm = (
            <AddressForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Address.values!}
              onSubmit={() => {}}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Address.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              handleRefresh={() => {}}
            />
          );
          break;

        case 'Lifecycle':
          currentForm = (
            <UserImportLifecycleForm
              initialValues={entity.default.Lifecycle.values!}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Lifecycle.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
            />
          );
          break;

        case 'Role':
          currentForm = (
            <RoleForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Role.values!}
              onSubmit={() => {}}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Role.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              departments={departments}
              managers={managers}
              sites={sites}
              jobPositions={jobPositions}
              onRefresh={() => {}}
              refreshSites={refreshSites}
              refreshDept={refreshDept}
              refreshJobPosition={refreshJobPosition}
              defaultJobPosition={DEFAULT_NEW_JOB_POSITION}
            />
          );
          break;

        case 'Salary':
          currentForm = (
            <CompensationForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Salary.values!}
              handleSubmit={(_) => {}}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Salary.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              getUserCompensation={async () => {}}
              rowModalMode={'add'}
              setFormCurrentlyEditing={() => {}}
              setIsModalOpen={() => {}}
              setLoading={() => {}}
              loading={false}
            />
          );
          break;

        case 'Bank account':
          currentForm = (
            <BankForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.BankAccount.values!}
              handleSubmit={(_) => {}}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.BankAccount.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              defaultName={''}
              getUserBankAccounts={async () => {}}
              rowModalMode={'add'}
              setFormCurrentlyEditing={() => {}}
              setIsModalOpen={() => {}}
              setLoading={() => {}}
              loading={false}
            />
          );
          break;

        case 'Family':
          currentForm = (
            <FamilyForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Family.values!}
              usedForDataImport={true}
              onSubmit={async (values) => {
                entity.default.Family.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              userFamilyMembers={[]}
              refreshMembers={async () => {}}
              handleRefresh={() => {}}
            />
          );
          break;

        case 'Contract':
          currentForm = (
            <ContractForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Contract.values}
              handleSubmit={(_) => {}}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Contract.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              rowModalMode={'add'}
              setFormCurrentlyEditing={() => {}}
              setIsModalOpen={() => {}}
              setLoading={() => {}}
              loading={false}
              loadInitialData={async () => {}}
            />
          );
          break;

        case 'About':
          currentForm = (
            <AboutForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.About.values!}
              usedForDataImport
              onSubmit={async (values) => {
                entity.default.About.values = values;
                await closeFormAndRevalidate();
              }}
              onClose={() => setIsOpen(false)}
              handleRefresh={() => {}}
            />
          );
          break;

        case 'Emergency contact':
          currentForm = (
            <EmergencyContactForm
              userId={UNIMPORTED_USER_ID}
              initialValues={entity.default.Emergency.values!}
              usedForDataImport={true}
              importHandler={async (values) => {
                entity.default.Emergency.values = values;
                await closeFormAndRevalidate();
              }}
              onSubmit={() => {}}
              onClose={() => setIsOpen(false)}
            />
          );
          break;

        default: {
          const formId = tabsAndColumns.find((t) => t.tabName === tab)?.formId;
          const customForm = entity.custom.find((f) => f.formId === formId);
          const form = customProfileForms?.find((f) => f.formId === formId);
          if (!formId || !form?.fields) return;
          currentForm = (
            <CustomSectionForm
              userId={UNIMPORTED_USER_ID}
              form={form}
              fields={
                customForm?.fields ??
                form.fields.map<UserCustomDataDto>((f) => ({
                  field: f,
                  fieldId: f.fieldId,
                  formId: f.formId,
                  value: '',
                }))
              }
              onSubmit={(_values) => {}}
              usedForDataImport
              importHandler={async (values) => {
                if (customForm) {
                  customForm.fields = values;
                } else {
                  entity.custom.push({
                    tabName: tab,
                    formId,
                    fields: values,
                  });
                }
                const updated = await validatePeopleImport2(
                  result!.errors.map((e) => e.entity),
                  polyglot
                );
                onUpdateAndRevalidate(updated);
                setIsOpen(false);
              }}
              onClose={() => setIsOpen(false)}
            />
          );
          break;
        }
      }
      setCurrentFormForEditing(currentForm);
      setIsOpen(true);
    },
    [
      polyglot,
      customProfileForms,
      departments,
      jobPositions,
      managers,
      onUpdateAndRevalidate,
      refreshJobPosition,
      refreshDept,
      refreshSites,
      result,
      sites,
      tabsAndColumns,
      DEFAULT_NEW_JOB_POSITION,
    ]
  );

  const { entriesWithErrors, errorCountByTab } = useMemo(() => {
    const errorCountByTab: Record<string, number> = {};
    const entriesWithErrors: EntityImportErrorDto<UserImport2Dto>[] = [];
    for (const e of result.errors) {
      e.errors.map((e) => (errorCountByTab[e.domain] = (errorCountByTab[e.domain] ?? 0) + 1));
      if (e.errors.length) {
        entriesWithErrors.push(e);
      }
    }
    return { entriesWithErrors, errorCountByTab };
  }, [result.errors]);

  useEffect(() => {
    if (entriesWithErrors.length === 0 && viewErrorUsers) {
      // turn off the filter when all the errors have been resolved
      setViewErrorUsers(false);
    }
  }, [entriesWithErrors.length, viewErrorUsers]);

  return (
    <>
      {entriesWithErrors.length > 0 && (
        <Stack sx={{ gap: spacing.g15, mb: '30px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <Mistake {...iconSize} fill={themeColors.Red} />
            <Typography variant="title4">{polyglot.t('UserImportResultTable.usersHaveProblems')}</Typography>
          </Box>
          <CheckboxComponent
            label={polyglot.t('UserImportResultTable.showImportedWithErrors')}
            name="viewErrorUsers"
            checked={viewErrorUsers}
            onChange={(_, checked) => setViewErrorUsers(checked)}
          ></CheckboxComponent>
        </Stack>
      )}

      <UserImportResultsTabs
        tabs={tabsAndColumns.map((t) => t.tabName)}
        currentTab={tabsAndColumns[tabIndex]?.tabName}
        onChange={(tab) => setTabIndex(tabsAndColumns.findIndex((t) => t.tabName === tab))}
        errorCountByTab={errorCountByTab}
      />
      <Box
        sx={{
          pt: spacing.p25,
          flex: 1,
          overflow: 'auto',
          borderTop: '1px solid',
          borderColor: themeColors.GreyLight,
        }}
      >
        <BasicTable
          rowData={viewErrorUsers ? entriesWithErrors : [...result.errors]}
          columnData={tabsAndColumns[tabIndex]?.columns ?? []}
          rowClick={(row) => {
            editEntity(tabsAndColumns[tabIndex].tabName, row.original.entity);
          }}
          loading={loading}
        />
      </Box>
      <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => {}}>
        {currentFormForEditing}
      </DrawerModal>

      {entriesWithErrors.length === 0 && (
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          style={{ marginTop: '30px' }}
          disabled={loading}
          onClick={() => {
            const usersToImport = selectedUsers.size ? [...selectedUsers] : result.errors.map(({ entity }) => entity);
            completeImport([], usersToImport);
          }}
        >
          Start import...
        </ButtonComponent>
      )}
    </>
  );
};
