import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { DevicePolicyType } from '@v2/feature/device/device.interface';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as CheckCircle } from '@/images/side-bar-icons/CheckCircle.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, DEVICES_ME_OVERVIEW_DEVICE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { AppliedDevicePoliciesDto, DevicePolicyDto, DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DevicePossessionType, UnmatchedExternalDevice } from '@/v2/feature/device/device.interface';
import { NoAppliedPolicies } from '@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { buttonSettingSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { settingCardSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ProtectDeviceContentProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  devicePossession: DevicePossessionDto;
}
const iconSize = { width: 14, height: 14 } as const;

const GetPolicyLabel = ({ policy }: { policy: DevicePolicyDto }) => {
  return (
    <Box sx={{ mt: spacing.m10, display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
      <Box>
        <CheckCircle {...iconSize} />
      </Box>
      <Box>
        <Typography sx={{ ...themeFonts.caption }}>{policy.description}</Typography>
      </Box>
    </Box>
  );
};

const GetKeyValue = ({
  key1,
  value1,
  key2,
  value2,
}: {
  key1: string;
  value1: string;
  key2: string;
  value2: string;
}) => {
  return (
    <>
      <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <Typography sx={{ ...themeFonts.caption }}>{key1}: </Typography>
        <Typography sx={{ ...themeFonts.title4 }}>{value1}</Typography>
      </Box>
      <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <Typography sx={{ ...themeFonts.caption }}>{key2}: </Typography>
        <Typography sx={{ ...themeFonts.title4 }}>{value2}</Typography>
      </Box>
    </>
  );
};

export const ProtectDevice = ({ setProgress, devicePossession }: ProtectDeviceContentProps) => {
  const [credentials, setCredentials] = useState<{ readonly username: string; readonly password: string } | null>(null);
  const [value, setValue] = useState<number | undefined>(undefined);
  const [showingState, setShowingState] = useState<string>('credentials');
  const [unmatchedDevices, setUnmatchedDevices] = useState<readonly UnmatchedExternalDevice[]>([]);
  const [isDeviceMatchingSaved, setIsDeviceMatchingSaved] = useState<boolean>(false);
  const [appliedPolicies, setAppliedPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [passCodePolicies, setPassCodePolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [iCloudCodePolicies, setICloudPolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [encryptionPolicies, setEncryptionPolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [locationPolicies, setLocationPolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [updatesPolicies, setUpdatesPolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [firewallPolicies, setFirewallPolicies] = useState<readonly DevicePolicyDto[]>([]);

  const routerHistory = useHistory();
  const location = useLocation();

  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        if (devicePossession.possessionType !== DevicePossessionType.User) {
          setShowingState('inventorydevice');
          return;
        }
        const userDetails = await DeviceAPI.getDeviceAssignedExternalUserCredentials(devicePossession.possessionId);
        setCredentials(userDetails);
      } catch (error) {
        showMessage(`Could not retrieve user credentials. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [devicePossession, showMessage]);

  const getUnmatchedDevices = async () => {
    setShowingState('unmatchedloading');
    try {
      const [unmatchedDevicesResponse, appliedPolicies, policies] = await Promise.all([
        DeviceAPI.getUnmatchedExternalDevicesForPossession(devicePossession.id),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
        DeviceAPI.getAvailableCompanyDevicePolicies(),
      ]);
      setUnmatchedDevices(unmatchedDevicesResponse.devices);
      setIsDeviceMatchingSaved(unmatchedDevicesResponse.deviceMatchingSaved);
      setAppliedPolicies(Boolean(appliedPolicies) ? appliedPolicies : NoAppliedPolicies);

      setPassCodePolicies(policies.filter((policy) => policy.type === DevicePolicyType.passcode));
      setEncryptionPolicies(policies.filter((policy) => policy.type === DevicePolicyType.encryption));
      setLocationPolicies(policies.filter((policy) => policy.type === DevicePolicyType.location));
      setUpdatesPolicies(policies.filter((policy) => policy.type === DevicePolicyType.updates));
      setICloudPolicies(policies.filter((policy) => policy.type === DevicePolicyType.icloud));
      setFirewallPolicies(policies.filter((policy) => policy.type === DevicePolicyType.firewall));

      if (!unmatchedDevicesResponse.deviceMatchingSaved) {
        if (unmatchedDevicesResponse.devices?.length && unmatchedDevicesResponse.devices.length > 1) {
          DeviceAPI.notifyDeviceEnrolledByHexnode(devicePossession.deviceId);
          setShowingState('unmatchedsuccess');
        } else setShowingState('unmatchedfailed');
      } else {
        setTimeout(async () => {
          setShowingState('unmatchedsuccess');
          await setProgress(100);
        }, 2000);
      }
    } catch (error) {
      setUnmatchedDevices([]);
      setShowingState('unmatchedfailed');
      if (error?.response.status !== 404) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
      }
    }
  };

  const getCurrentState = () => {
    switch (showingState) {
      case 'credentials':
        return (
          <>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Establish connection</Typography>
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              Connect your device to Zelt in order to apply company security configurations to it. Download the device
              management profile and use the following credentials to activate it.
            </Typography>
            {credentials ? (
              <GetKeyValue
                key1="Username"
                value1={credentials.username}
                key2="Password"
                value2={credentials.password}
              />
            ) : (
              <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                  Couldn't fetch the credentials.
                </Typography>
              </Box>
            )}

            <Box sx={{ ...buttonSettingSx, maxWidth: '70%', width: 'auto', ...spacing.mt40 }}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="secondary"
                fullWidth
                onClick={() =>
                  routerHistory.push(
                    generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                  )
                }
              >
                Skip
              </ButtonComponent>

              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth={true}
                onClick={async () => {
                  window.open('https://zelt.hexnodemdm.com/enroll/', '_blank');
                  setShowingState('downloaded');
                }}
              >
                Download
              </ButtonComponent>
            </Box>
          </>
        );

      case 'downloaded':
        return (
          <>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Establish connection</Typography>
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              Connect your device to Zelt in order to apply company security configurations to it. Download the device
              management profile and use the following credentials to activate it.
            </Typography>
            {credentials ? (
              <GetKeyValue
                key1="Username"
                value1={credentials.username}
                key2="Password"
                value2={credentials.password}
              />
            ) : (
              <Box sx={{ mt: spacing.m10, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                  Couldn't fetch the credentials.
                </Typography>
              </Box>
            )}

            <Box sx={{ ...buttonSettingSx, maxWidth: '70%', width: 'auto', ...spacing.mt40 }}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="secondary"
                fullWidth
                onClick={() =>
                  routerHistory.push(
                    generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                  )
                }
              >
                Skip
              </ButtonComponent>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth={true}
                onClick={async () => {
                  await getUnmatchedDevices();
                }}
              >
                Continue
              </ButtonComponent>
            </Box>
          </>
        );

      case 'unmatchedloading':
        return (
          <Box>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              Device syncing <CircularProgress size={14} />
            </Typography>
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              This may take a few moments
            </Typography>
          </Box>
        );

      case 'unmatchedfailed':
        return (
          <Box>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Device syncing failed</Typography>
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              We couldn't find your device just yet. Sometimes it can take a few seconds to show up in our servers.
            </Typography>
            <Box sx={{ ...buttonSettingSx, maxWidth: '70%', width: 'auto', ...spacing.mt40 }}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="secondary"
                fullWidth={true}
                onClick={async () => setShowingState('credentials')}
              >
                Go back
              </ButtonComponent>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth={true}
                onClick={async () => {
                  await getUnmatchedDevices();
                }}
              >
                Continue
              </ButtonComponent>
            </Box>
          </Box>
        );

      case 'unmatchedsuccess':
        return (
          unmatchedDevices.length > 0 && (
            <>
              <Box>
                <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}></Typography>
                <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
                  Your device has been successfully enroled. You can sit back and enjoy your device securely. To see and
                  manage this device go to Devices app.
                </Typography>
              </Box>

              <Box>
                {unmatchedDevices.length > 1 ? (
                  <FormControl component="fieldset" sx={{ px: 2, width: '100%' }}>
                    <RadioGroup aria-label="unmatched-device" name="radio-buttons-group">
                      {unmatchedDevices.map((device) => (
                        <FormControlLabel
                          value={device.id}
                          onChange={(e) => {
                            // @ts-ignore
                            setValue(Number(e.target.value));
                          }}
                          control={
                            <Radio
                              checked={(isDeviceMatchingSaved && unmatchedDevices.length === 1) || value === device.id}
                              disabled={isDeviceMatchingSaved && unmatchedDevices.length === 1}
                            />
                          }
                          sx={{ ':hover': { bgcolor: '#f3f3f3' }, borderRadius: 1, p: 1, m: 0, width: '100%' }}
                          label={
                            <GetKeyValue
                              key1="Device model"
                              value1={device.modelName}
                              key2="Platform"
                              value2={device.platform}
                            />
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : (
                  unmatchedDevices.map((device) => (
                    <GetKeyValue
                      key1="Device model"
                      value1={device.modelName}
                      key2="Platform"
                      value2={device.platform}
                    />
                  ))
                )}
              </Box>

              {Boolean(appliedPolicies) && (
                <Box sx={{ ...spacing.mt40 }}>
                  <Typography sx={{ ...themeFonts.title2, mb: spacing.m10, color: themeColors.DarkGrey }}>
                    Policies applied to your device
                  </Typography>
                  {appliedPolicies[DevicePolicyType.passcode]
                    .filter((policyId) => policyId !== 0)
                    .map((policyId) => {
                      const policy = passCodePolicies.find((p) => p.id === policyId);
                      return policy ? <GetPolicyLabel policy={policy} /> : null;
                    })}
                  {appliedPolicies[DevicePolicyType.icloud]
                    .filter((policyId) => policyId !== 0)
                    .map((policyId) => {
                      const policy = iCloudCodePolicies.find((p) => p.id === policyId);
                      return policy ? <GetPolicyLabel policy={policy} /> : null;
                    })}
                  {appliedPolicies[DevicePolicyType.encryption]
                    .filter((policyId) => policyId !== 0)
                    .map((policyId) => {
                      const policy = encryptionPolicies.find((p) => p.id === policyId);
                      return policy ? <GetPolicyLabel policy={policy} /> : null;
                    })}
                  {appliedPolicies[DevicePolicyType.location]
                    .filter((policyId) => policyId !== 0)
                    .map((policyId) => {
                      const policy = locationPolicies.find((p) => p.id === policyId);
                      return policy ? <GetPolicyLabel policy={policy} /> : null;
                    })}
                  {appliedPolicies[DevicePolicyType.updates]
                    .filter((policyId) => policyId !== 0)
                    .map((policyId) => {
                      const policy = updatesPolicies.find((p) => p.id === policyId);
                      return policy ? <GetPolicyLabel policy={policy} /> : null;
                    })}
                  {appliedPolicies[DevicePolicyType.firewall]
                    .filter((policyId) => policyId !== 0)
                    .map((policyId) => {
                      const policy = firewallPolicies.find((p) => p.id === policyId);
                      return policy ? <GetPolicyLabel policy={policy} /> : null;
                    })}
                </Box>
              )}

              <Box sx={{ ...buttonSettingSx, maxWidth: '35%', width: 'auto', ...spacing.mt40 }}>
                <ButtonComponent
                  sizeVariant="medium"
                  colorVariant="primary"
                  fullWidth={true}
                  onClick={async () => {
                    if (value) {
                      await DeviceAPI.matchDeviceLocalIdWithDeviceExternalId(devicePossession.id, value);
                    } else {
                      routerHistory.push(
                        generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                      );
                    }
                  }}
                >
                  Complete
                </ButtonComponent>
              </Box>
            </>
          )
        );

      case 'inventorydevice':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              You have to assign a user to this device before being able to enrol it to MDM.
            </Typography>
            <Box>
              <ButtonComponent
                sizeVariant="small"
                colorVariant="primary"
                onClick={() => {
                  const path = location.pathname.includes('company')
                    ? DEVICES_COMPANY_DEVICE_DETAILS_ROUTE
                    : DEVICES_ME_OVERVIEW_DEVICE_ROUTE;

                  routerHistory.push(generatePath(path, { devicePossessionId: devicePossession.id }));
                }}
              >
                See device
              </ButtonComponent>
            </Box>
          </Box>
        );
      //this case should never occur
      default:
        return <>Something went wrong</>;
    }
  };

  return <Box sx={settingCardSx}>{getCurrentState()}</Box>;
};
