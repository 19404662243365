import { timeSince } from '@/lib/moment.lib';
import { Map, Marker, Overlay, ZoomControl } from 'pigeon-maps';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

export interface MapMarker {
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly name?: string | null;
  readonly time?: Date | string | null;
  readonly owner?: string;
}

interface Props {
  readonly markers: readonly MapMarker[];
  readonly zoom?: number;
  readonly zoomControl?: boolean;
  readonly className?: string;
}

const PigeonMap = ({ markers, zoomControl = false, zoom = 6 }: Props): JSX.Element => {
  const [overlay, setOverlay] = useState<MapMarker | null>({
    latitude: 0,
    longitude: 0,
    name: '',
    owner: '',
    time: new Date(),
  });

  const noOfValidMarkers = markers.filter((marker) => marker && marker.latitude && marker.longitude).length;
  const latitudeCenter = noOfValidMarkers
    ? markers.reduce((acc, m) => {
        if (m && m.latitude) {
          acc = acc + m.latitude;
        }
        return acc;
      }, 0) / noOfValidMarkers
    : 51.5072;
  const longitudeCenter = noOfValidMarkers
    ? markers.reduce((acc, m) => {
        if (m && m.longitude) {
          acc = acc + m.longitude;
        }
        return acc;
      }, 0) / noOfValidMarkers
    : 0.1276;

  return (
    <Map defaultCenter={[latitudeCenter, longitudeCenter]} defaultZoom={zoom} onClick={() => setOverlay(null)}>
      {zoomControl && <ZoomControl />}
      {markers.map((marker, index: number) =>
        marker && marker.longitude && marker.latitude ? (
          <Marker
            key={index}
            width={40}
            anchor={[marker.latitude, marker.longitude]}
            onClick={() => setOverlay(marker)}
          />
        ) : (
          <></>
        )
      )}
      {overlay && overlay.latitude && overlay.longitude && (
        <Overlay anchor={[overlay.latitude, overlay.longitude]} offset={[108, overlay.owner ? 120 : 100]}>
          <Box
            sx={{
              bgcolor: 'white',
              width: '100%',
              p: 2,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
            }}
            className="bg-white w-60 flex flex-col rounded-md p-2 shadow-xl"
          >
            {overlay && overlay.owner && (
              <Typography variant="body2" fontWeight="bold">
                {overlay.owner}
              </Typography>
            )}
            {overlay && overlay.name && <Typography variant="body2">{overlay.name}</Typography>}
            {overlay && overlay.time && (
              <Typography variant="body2">Last seen: {timeSince(new Date(overlay.time))}</Typography>
            )}
          </Box>
          <Box
            sx={{
              width: 0,
              height: 0,
              mx: 'auto',
              px: 0.1,
              borderTop: '10px solid white',
              borderRight: '10px solid transparent',
              borderLeft: '10px solid transparent',
            }}
          />
        </Overlay>
      )}
    </Map>
  );
};

export default PigeonMap;
