import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Link, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { sortString } from '@v2/components/table/table-sorting.util';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSection,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AppDetailsEndpoints } from '@v2/feature/app-integration/features/app-details/app-details.api';
import { AuthEndpoints } from '@v2/feature/auth/auth.api';
import { DeveloperHubClientGenerateDrawer } from '@v2/feature/security/security-settings/components/developer-hub-client-generate-drawer.component';
import { SSOConfigureDrawer } from '@v2/feature/security/security-settings/components/sso-configure-drawer.component';
import {
  NonSensitiveOauthClientConfiguration,
  SSOApp,
  SSOAppsEnum,
} from '@v2/feature/security/security-settings/security.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize, iconSize } from '@v2/styles/table.styles';
import { truncateWithEllipses } from '@v2/util/string.util';

import { OauthClientAPI, OauthClientEndpoints } from '@/api-client/oauth-client.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as External } from '@/images/icons/external-link-arrow.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_SECURITY_AUDIT_TRAIL_REPORT } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export const SecuritySettingPage = () => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();

  const { data: mfaEnabled, isLoading: loadingMFa } = useApiClient(AuthEndpoints.mfaCheck(), {
    suspense: false,
  });

  const { data: ssoState, isLoading: loadingSSO, mutate: refreshSSO } = useApiClient(
    AppDetailsEndpoints.getSSOMetadata(),
    {
      suspense: false,
    }
  );

  const [edit, setEdit] = useState<boolean>(false);
  const [editSSO, setEditSSO] = useState<boolean>(false);

  const [generateDrawer, setGenerateDrawer] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<NonSensitiveOauthClientConfiguration | undefined>(undefined);

  useEffect(() => {
    trackPage('Company security settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSSOState = useCallback(async () => {
    if (refreshSSO) await refreshSSO();
  }, [refreshSSO]);

  const { certificate, apikey, enabledEntry, ssoProvider } = useMemo(() => {
    const gwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'google-workspace');
    const ssoGwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-google-workspace');
    const ssoAzureAdEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-azure-ad');
    const ssoOktaEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-okta');
    const enabledEntry = ssoState?.find((eachEntry) => eachEntry.state.enabled);

    const apikey = ssoOktaEntry?.state?.apikey ?? '';
    const ssoProvider = enabledEntry ? SSOAppsEnum[enabledEntry.app as SSOApp] : 'None';
    let certificate = '';
    if (gwEntry) certificate = gwEntry?.state?.certificate;
    if (ssoGwEntry) certificate = ssoGwEntry?.state?.certificate;
    if (ssoAzureAdEntry) certificate = ssoAzureAdEntry?.state?.certificate;

    return { certificate, apikey, enabledEntry, ssoProvider };
  }, [ssoState]);

  const secondConfigTitle = useMemo(() => {
    if (
      enabledEntry &&
      (enabledEntry.app === 'sso-google-workspace' || enabledEntry.app === 'sso-azure-ad') &&
      certificate?.length > 0
    )
      return 'IdP Metadata';
    else if (enabledEntry && enabledEntry.app === 'sso-okta' && apikey?.length > 0) return 'API token';
    else return '';
  }, [enabledEntry, certificate, apikey]);

  const secondConfigValue = useMemo(() => {
    if (
      enabledEntry &&
      (enabledEntry.app === 'sso-google-workspace' || enabledEntry.app === 'sso-azure-ad') &&
      certificate?.length > 0
    )
      return certificate;
    else if (enabledEntry && enabledEntry.app === 'sso-okta' && apikey?.length > 0) return apikey;
    else return '';
  }, [enabledEntry, certificate, apikey]);

  const developerSettingsSection: SettingsSection | null = useMemo(
    () => ({
      title: polyglot.t('DeveloperHub.title'),
      buttons: [
        <ButtonComponent
          colorVariant="secondary"
          sizeVariant="small"
          onClick={() => {
            setSelectedClient(undefined);
            setGenerateDrawer(true);
          }}
        >
          {polyglot.t('DeveloperHub.generate')}
        </ButtonComponent>,
      ],
      items: [
        {
          type: SectionItemType.TextLine,
          value: polyglot.t('DeveloperHub.subtitle'),
        },
        {
          type: SectionItemType.Component,
          value: (
            <OAuthClientConfigTable
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              generateDrawer={generateDrawer}
              setGenerateDrawer={setGenerateDrawer}
            />
          ),
        },
      ],
    }),
    [generateDrawer, polyglot, selectedClient]
  );

  return (
    <ContentWrapper loading={loadingSSO || loadingMFa}>
      <SettingsSectionContent title={polyglot.t('SettingsRouterPageConfig.security')}>
        <SettingsSubsectionContent
          sections={[
            {
              title: polyglot.t('SecurityMFA.multiFactorAuthentication'),
              onEdit: () => setEdit(true),
              editButtonSx: { display: edit ? 'none' : '' },
              editButtonDisabled: true,
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: polyglot.t('SecurityMFA.configureMultiFactor'),
                },
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('SecurityMFA.enablemfa'),
                  value: mfaEnabled ? 'On' : 'Off',
                },
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('SecurityMFA.authenticationMethod'),
                  value: polyglot.t('SecurityMFA.authenticationMethodValue'),
                },
              ],
            },
            {
              title: polyglot.t('SSOMetadata.singleSignOn'),
              onEdit: () => setEditSSO(true),
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: polyglot.t('SSOMetadata.connectYourCompany'),
                },
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('SSOMetadata.ssoProvider'),
                  value: ssoProvider,
                },
                {
                  type: SectionItemType.Pair,
                  label: secondConfigTitle,
                  value: secondConfigValue,
                },
              ],
            },
            {
              title: polyglot.t('SecurityAuditReportSection.auditTrail'),
              buttons: [
                <ButtonComponent sizeVariant="small" colorVariant="secondary">
                  <Link
                    sx={{ textDecoration: 'none', color: 'black' }}
                    target="_blank"
                    rel="noreferrer"
                    href={SETTINGS_SECURITY_AUDIT_TRAIL_REPORT}
                  >
                    {polyglot.t('SecurityAuditReportSection.view')}
                  </Link>
                </ButtonComponent>,
              ],
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: polyglot.t('SecurityAuditReportSection.trackChanges'),
                },
              ],
            },
            ...(developerSettingsSection ? [developerSettingsSection] : []),
          ]}
        />
      </SettingsSectionContent>

      <SSOConfigureDrawer
        isOpen={editSSO}
        setIsOpen={setEditSSO}
        onClose={() => setEditSSO(false)}
        mfaEnabled={!!mfaEnabled}
        ssoState={ssoState ?? []}
        onUpdate={async () => {
          await refreshSSOState();
        }}
      />
    </ContentWrapper>
  );
};

interface TableProps {
  readonly selectedClient: NonSensitiveOauthClientConfiguration | undefined;
  readonly setSelectedClient: React.Dispatch<React.SetStateAction<NonSensitiveOauthClientConfiguration | undefined>>;
  readonly generateDrawer: boolean;
  readonly setGenerateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const OAuthClientConfigTable = ({
  selectedClient,
  setSelectedClient,
  generateDrawer,
  setGenerateDrawer,
}: TableProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: oauthClientConfiguration, mutate: refreshClientConfig } = useApiClient(
    OauthClientEndpoints.getAllClientsForCompany(),
    {
      suspense: false,
    }
  );
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const deleteSpecificClient = async () => {
    try {
      if (!selectedClient || !selectedClient.id) return;
      await OauthClientAPI.deleteSpecificClient(selectedClient?.id);
      setIsRemovalModalOpen(false);
      setAnchorEl(null);
      refreshClientConfig!();
      showMessage(polyglot.t('DeveloperHub.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('DeveloperHubClientGenerateDrawer.errorMessages.delete', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const oauthClientColumns = useMemo<
    ColumnDef<NonSensitiveOauthClientConfiguration, NonSensitiveOauthClientConfiguration>[]
  >(() => {
    const initiateCodeFlow = (client: NonSensitiveOauthClientConfiguration) => {
      const PARTNER_API_AUTHORISE_CODE_FLOW_ROUTE =
        '/partner-api/authorize?response_type=code&client_id=:clientId&state=:state&redirect_uri=:redirectUri';
      const codeFlowPath = PARTNER_API_AUTHORISE_CODE_FLOW_ROUTE.replace(':clientId', client.clientId)
        .replace(':state', 'testState123')
        .replace(':redirectUri', client.redirectionUri);
      window.open(codeFlowPath, '_blank');
    };

    const copyClientId = (client: NonSensitiveOauthClientConfiguration) => {
      navigator.clipboard.writeText(client.clientId);
      showMessage(polyglot.t('DeveloperHub.successMessages.copyClientId'), 'success');
    };
    return [
      {
        id: 'name',
        maxSize: 80,
        header: () => polyglot.t('DeveloperHub.clientTable.name'),
        accessorFn: (row) => row.name,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name, { sensitivity: 'base' }),
        cell: ({ row }) => <>{row.original.name}</>,
      },
      // {
      //   id: 'description',
      //   header: () => polyglot.t('DeveloperHub.clientTable.description'),
      //   accessorFn: (row) => row.description,
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortString(a, b, (item) => item.description, { sensitivity: 'base' }),
      //   cell: ({ row }) => <>{row.original.description}</>,
      // },
      // {
      //   id: 'scopes',
      //   maxSize: 120,
      //   header: () => polyglot.t('DeveloperHub.clientTable.scopes'),
      //   accessorFn: (row) => row.scopes.join(', '),
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortString(a, b, (item) => item?.scopes.join(', '), { sensitivity: 'base' }),
      //   cell: ({ row }) => <>{row.original.scopes ? row.original.scopes.join(', ') : ''}</>,
      // },
      {
        id: 'redirection-uri',
        maxSize: 80,
        header: () => polyglot.t('DeveloperHub.clientTable.redirectionUri'),
        accessorFn: (row) => row.redirectionUri,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.redirectionUri, { sensitivity: 'base' }),
        cell: ({ row }) => (
          <>
            {row.original.redirectionUri ? truncateWithEllipses(row.original.redirectionUri, 10) : <EmptyCell />}
            {row.original.redirectionUri ? (
              <IconButton
                title={polyglot.t('DeveloperHub.clientTable.copyRedirectionUri')}
                onClick={() => {
                  navigator.clipboard.writeText(row.original.redirectionUri);
                }}
                sx={tableIconButtonSx}
              >
                <Copy {...actionIconSize} />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        id: 'published',
        maxSize: 20,
        header: () => polyglot.t('DeveloperHub.clientTable.published'),
        accessorFn: (row) => row.published,
        enableSorting: true,
        cell: ({ row }) => <>{row.original.published ? 'Yes' : 'No'}</>,
      },
      // {
      //   id: 'created-at',
      //   header: () => polyglot.t('DeveloperHub.clientTable.createdAt'),
      //   accessorFn: (row) => row.createdAt,
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortString(a, b, (item) => item.createdAt, { sensitivity: 'base' }),
      //   cell: ({ row }) => <>{row.original.createdAt}</>,
      // },
      // {
      //   id: 'updated-at',
      //   header: () => polyglot.t('DeveloperHub.clientTable.updatedAt'),
      //   accessorFn: (row) => row.updatedAt,
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortString(a, b, (item) => item.updatedAt, { sensitivity: 'base' }),
      //   cell: ({ row }) => <>{row.original.updatedAt}</>,
      // },
      {
        id: 'actions',
        header: () => '',
        maxSize: 150,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row }) => {
          const clientApp = row.original;
          return (
            <Stack sx={{ flexFlow: 'row', justifyContent: 'flex-end', gap: spacing.g5 }}>
              <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => initiateCodeFlow(clientApp)}>
                <External {...iconSize} />
                Code flow
              </ButtonComponent>
              <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => copyClientId(clientApp)}>
                <Copy {...iconSize} />
                Client ID
              </ButtonComponent>
              <IconButton
                disabled={clientApp.published}
                title={polyglot.t('DeveloperHub.clientTable.editClient')}
                onClick={() => {
                  setSelectedClient(clientApp);
                  setGenerateDrawer(true);
                }}
                sx={tableIconButtonSx}
              >
                <EditIcon {...actionIconSize} />
              </IconButton>
              <IconButton
                title={polyglot.t('DeveloperHub.clientTable.deleteClient')}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setSelectedClient(clientApp);
                  setIsRemovalModalOpen(true);
                }}
                sx={tableIconButtonSx}
              >
                <Trash {...actionIconSize} />
              </IconButton>
            </Stack>
          );
        },
      },
    ];
  }, [polyglot, setGenerateDrawer, setSelectedClient, showMessage]);

  return (
    <Box>
      {oauthClientConfiguration ? (
        <BasicTable<NonSensitiveOauthClientConfiguration>
          rowData={oauthClientConfiguration}
          columnData={oauthClientColumns}
        />
      ) : null}

      {refreshClientConfig && generateDrawer && (
        <DeveloperHubClientGenerateDrawer
          isOpen={generateDrawer}
          setIsOpen={setGenerateDrawer}
          onClose={() => setGenerateDrawer(false)}
          onUpdate={() => {
            refreshClientConfig();
          }}
          existingClient={selectedClient ?? undefined}
        />
      )}

      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => setIsRemovalModalOpen(false)}
        anchorEl={anchorEl}
        takeAction={async () => {
          await deleteSpecificClient();
        }}
        message={polyglot.t('DeveloperHub.clientTable.deleteConfirm')}
        callToAction={polyglot.t('General.yes')}
      />
    </Box>
  );
};
