import { Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

import type { AlertColor } from '@mui/material/Alert';

import { ReactComponent as AlertOkay } from '@/images/side-bar-icons/AlertOkay.svg';
import { ReactComponent as AlertWaiting } from '@/images/side-bar-icons/AlertWaiting.svg';
import { ReactComponent as CleanCircle } from '@/images/side-bar-icons/CleanCircle.svg';
import { ReactComponent as InfoBlack } from '@/images/side-bar-icons/InfoBlack.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface ShowMessageProps {
  readonly message: string;
  readonly type: AlertColor;
  readonly show: boolean;
}

interface SnackBarMessageProps {
  readonly showMessage: ShowMessageProps;
  readonly handleClose: (event: unknown, reason?: SnackbarCloseReason) => void;
}

const getAlertColor = (type: string) => {
  switch (type) {
    case 'success':
      return themeColors.green;
    case 'error':
      return themeColors.red;
    case 'info':
      return themeColors.black;
    case 'warning':
      return themeColors.orange;
    default:
      return themeColors.DarkGrey;
  }
};

const getIcon = (type: string) => {
  switch (type) {
    case 'success':
      return <AlertOkay style={{ fill: themeColors.white }} />;
    case 'error':
      return <CleanCircle style={{ fill: themeColors.white }} />;
    case 'info':
      return <InfoBlack style={{ fill: themeColors.white }} />;
    default:
      return <AlertWaiting />;
  }
};

export const SnackBarMessage = ({ showMessage, handleClose }: SnackBarMessageProps) => (
  <Snackbar
    open={showMessage.show}
    autoHideDuration={60000}
    message={showMessage.message}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <MuiAlert
      sx={{
        width: '100%',
        backgroundColor: getAlertColor(showMessage.type),
        borderRadius: radius.br10,
        boxShadow: 'none',
      }}
      elevation={10}
      variant="filled"
      onClose={handleClose}
      icon={false}
    >
      <Typography
        sx={{
          ...themeFonts.caption,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          gap: spacing.g10,
          color: themeColors.white,
        }}
      >
        {getIcon(showMessage.type)}
        {showMessage.message}
      </Typography>
    </MuiAlert>
  </Snackbar>
);
