import { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { keyBy } from 'lodash';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { IconContentActionCard } from '@/v2/components/theme-components/icon-content-action-card.component';
import { DeviceOrderDto } from '@/v2/feature/device/device.dto';
import { getModelImage, getOrderStatus } from '@/v2/feature/device/device.util';
import { DeviceOrderDrawer } from '@/v2/feature/device/features/devices-company/components/device-order-drawer.component';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MyDevicesOrders = ({
  deviceOrders,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshDevicesData,
}: {
  deviceOrders: readonly DeviceOrderDto[];
  refreshDevicesData?: () => Promise<void>;
}) => {
  const [sitesById, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedDeviceOrder, setSelectedDeviceOrder] = useState<DeviceOrderDto | undefined>(undefined);
  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesById(keyBy(sites, 'id'));
      } catch (error) {
        showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  return (
    <Box>
      <Typography sx={themeFonts.title2}>Order</Typography>

      <Box sx={{ ...spacing.mt20 }}>
        {deviceOrders.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
            {deviceOrders.map((d, idx) => (
              <IconContentActionCard
                iconMedia={
                  d.deviceModel &&
                  getModelImage(d.deviceModel.type, d.deviceModel.modelName, {
                    width: '150px',
                    height: 'auto',
                  })
                }
                title={d.deviceModel?.modelName}
                content={<Box sx={{ mt: spacing.m5 }}>{getOrderStatus(d.status)}</Box>}
                action={
                  <Button
                    sx={secondaryCTABtn}
                    onClick={() => {
                      setSelectedDeviceOrder(d);
                      setIsDrawerOpen(true);
                    }}
                  >
                    Details
                  </Button>
                }
                showDivider={idx < deviceOrders.length - 1}
              />
            ))}

            {selectedDeviceOrder && (
              <DeviceOrderDrawer
                setIsOpen={setIsDrawerOpen}
                isOpen={isDrawerOpen}
                onClose={() => {
                  setIsDrawerOpen(false);
                  setSelectedDeviceOrder(undefined);
                }}
                deviceOrder={selectedDeviceOrder}
                refresh={async () => {
                  setIsDrawerOpen(false);
                  setSelectedDeviceOrder(undefined);
                  await refreshDevicesData?.();
                }}
                sitesById={sitesById}
                reach={'me'}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>No orders to show</Typography>
        )}
      </Box>
    </Box>
  );
};
