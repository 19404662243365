import React, { Suspense, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { MultipleSelectCheckbox, MultiSelectOption } from '@v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import {
  InsuranceAddonsMultipliersDto,
  InsurancePolicyDto,
  UserInsuranceDto,
} from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import {
  calculateAnnualValueOfTheBenefitForCurrentTaxYear,
  getAdditionalMembersOptions,
  getInsuranceMultiplierValueByDependantsValue,
} from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface AddDependantsToInsuranceForm {
  additionalMonthlyPremium: number;
  employeeCoversAdditionalPremium: boolean;
  dependants: UserInsuranceDependants;
  dependantsList: number[];
}

interface InsuranceAddDependantsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userInsurance: UserInsuranceDto;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const InsuranceAddDependantsDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  insurancePolicy,
  refresh,
  onClose,
}: InsuranceAddDependantsDrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(UserEndpoints.getUserFamilyMembers(userInsurance.userId), {
    suspense: false,
  });

  const { polyglot } = usePolyglot();
  const [insuranceAddonsMultipliers, setInsuranceAddonsMultipliers] = useState<InsuranceAddonsMultipliersDto | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const insuranceAddonsMultipliers = await InsuranceAPI.getInsuranceAddonsMultipliersByPolicyId(
          insurancePolicy.id
        );
        if (!insuranceAddonsMultipliers) {
          showMessage(polyglot.t('BenefitModule.ErrorMessages.couldNotFindPolicyAddOns'), 'error');
          return;
        }
        setInsuranceAddonsMultipliers(insuranceAddonsMultipliers);
      } catch (error) {
        showMessage(
          polyglot.t('BenefitModule.ErrorMessages.couldNotGetAddOnsMultipliers', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [polyglot, showMessage, insurancePolicy.id]);

  return !loading && insuranceAddonsMultipliers ? (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <InsuranceAddDependantsDrawerContent
          userInsurance={userInsurance}
          insurancePolicy={insurancePolicy}
          insuranceAddonsMultipliers={insuranceAddonsMultipliers}
          userFamilyMembers={userFamilyMembers ?? []}
          refresh={refresh}
          onClose={onClose}
        />
      </Suspense>
    </DrawerModal>
  ) : null;
};

interface InsuranceAddDependantsDrawerContentProps {
  readonly userInsurance: UserInsuranceDto;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly insuranceAddonsMultipliers: InsuranceAddonsMultipliersDto;
  readonly userFamilyMembers: readonly UserFamilyMemberDto[];
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

const InsuranceAddDependantsDrawerContent = ({
  userInsurance,
  insurancePolicy,
  insuranceAddonsMultipliers,
  userFamilyMembers,
  refresh,
  onClose,
}: InsuranceAddDependantsDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const isEditForm = !!userInsurance?.dependants;

  const formik = useFormik<AddDependantsToInsuranceForm>({
    initialValues: {
      dependants: userInsurance?.dependants ?? UserInsuranceDependants.SpousePartner,
      additionalMonthlyPremium:
        userInsurance?.dependantsMonthlyPremium ??
        (userInsurance.monthlyPremium
          ? getInsuranceMultiplierValueByDependantsValue(
              userInsurance.monthlyPremium,
              isEditForm ? userInsurance.dependants! : UserInsuranceDependants.SpousePartner,
              insuranceAddonsMultipliers
            )
          : 0),
      employeeCoversAdditionalPremium: isEditForm
        ? !!userInsurance.dependantsMonthlyPremium &&
          userInsurance.dependantsMonthlyPremium === userInsurance.dependantsMonthlyContribution
        : true,
      dependantsList: isEditForm ? userInsurance.dependantsList ?? [] : [],
    },
    validationSchema: yup.object({
      additionalMonthlyPremium: yup.number().required(polyglot.t('ValidationMessages.requiredField')),
      employeeCoversAdditionalPremium: yup.boolean().required(polyglot.t('ValidationMessages.requiredField')),
      dependants: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      dependantsList: yup
        .array()
        .of(yup.number().integer())
        .min(1, polyglot.t('ValidationMessages.select1Value'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: (values: AddDependantsToInsuranceForm) => addDependants(userInsurance.userId, values, insurancePolicy.id),
  });

  const selectedDependantsList: MultiSelectOption<number>[] = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];

    return formik.values.dependantsList
      .map((id) => {
        const familyMember = userFamilyMembers.find((m) => m.id === id);

        return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
      })
      .filter(Boolean) as MultiSelectOption<number>[];
  }, [formik.values.dependantsList, userFamilyMembers]);

  const dependantsListOptions: MultiSelectOption<number>[] = useMemo(() => {
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (
        userFamilyMembers
          ?.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type))
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (
        userFamilyMembers
          ?.filter((m) => m.type === UserFamilyMemberType.Child)
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    return userFamilyMembers?.map((m) => ({ value: m.id, label: m.name })) ?? [];
  }, [userFamilyMembers, formik.values.dependants]);

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDependants = async (
    userId: number,
    values: AddDependantsToInsuranceForm,
    insurancePolicyId: number
  ): Promise<void> => {
    try {
      setLoading(true);
      await InsuranceAPI.addDependantsToUserPolicy(userId, insurancePolicyId, {
        dependants: values.dependants!,
        dependantsList: values.dependantsList,
        dependantsMonthlyPremium: values.additionalMonthlyPremium,
        employeeCoversAdditionalPremium: values.employeeCoversAdditionalPremium,
      });

      await refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <>
          <Typography sx={titleSx}>
            {isEditForm ? polyglot.t('BenefitModule.updateDependants') : polyglot.t('BenefitModule.addDependants')}
          </Typography>

          <Box sx={{ mb: spacing.mb30 }}>
            <UserCell userId={userInsurance.userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
          </Box>

          <Box sx={fieldSx}>
            <SelectComponent
              name="dependants"
              label={polyglot.t('BenefitModule.additionalMembers')}
              options={getAdditionalMembersOptions(polyglot)}
              value={formik.values.dependants}
              compareValue={formik.values.dependants}
              error={!!formik.errors.dependants && formik.touched.dependants}
              onChange={(e) => {
                formik.handleChange(e);
                const value = e.target.value as UserInsuranceDependants;
                const newAdditionalMonthlyPremium = userInsurance.monthlyPremium
                  ? getInsuranceMultiplierValueByDependantsValue(
                      userInsurance.monthlyPremium,
                      value,
                      insuranceAddonsMultipliers
                    )
                  : 0;
                formik.setFieldValue('additionalMonthlyPremium', newAdditionalMonthlyPremium);
                formik.setFieldValue('dependantsList', []);
              }}
              helperText={formik.errors.dependants && formik.touched.dependants}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="additionalMonthlyPremium"
              label={polyglot.t('BenefitModule.additionalMonthlyPremium')}
              value={formik.values.additionalMonthlyPremium}
              error={formik.touched.additionalMonthlyPremium && Boolean(formik.errors.additionalMonthlyPremium)}
              helperText={(formik.touched.additionalMonthlyPremium && formik.errors.additionalMonthlyPremium) as string}
              disabled
            />
          </Box>

          <Box sx={{ mb: spacing.mb30 }}>
            <CheckboxComponent
              name="employeeCoversAdditionalPremium"
              label={polyglot.t('BenefitModule.employeeCoversAdditionalPremium')}
              checked={formik.values.employeeCoversAdditionalPremium}
              onChange={formik.handleChange}
            />
          </Box>

          {userInsurance.monthlyPremium && (
            <Box sx={fieldSx}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.grey, mb: spacing.mb5 }}>
                {polyglot.t('BenefitModule.employeeMonthlyPremium')}
              </Typography>
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                {formatMoney({
                  amount:
                    userInsurance.monthlyPremium +
                    getInsuranceMultiplierValueByDependantsValue(
                      userInsurance.monthlyPremium,
                      formik.values.dependants,
                      insuranceAddonsMultipliers
                    ),
                })}
              </Typography>
            </Box>
          )}

          {userInsurance.monthlyPremium && (
            <Box sx={fieldSx}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.grey, mb: spacing.mb5 }}>
                {polyglot.t('BenefitModule.employeeMonthlyContribution')}
              </Typography>
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                {formatMoney({
                  amount:
                    (userInsurance.monthlyContribution ?? 0) +
                    (formik.values.employeeCoversAdditionalPremium
                      ? getInsuranceMultiplierValueByDependantsValue(
                          userInsurance.monthlyPremium,
                          formik.values.dependants,
                          insuranceAddonsMultipliers
                        )
                      : 0),
                })}
              </Typography>
            </Box>
          )}

          <Box sx={fieldSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.grey, mb: spacing.mb5 }}>
              {polyglot.t('BenefitModule.annualValueOfBenefit')}
            </Typography>
            {userInsurance.startDate && userInsurance.monthlyPremium && (
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                {formatMoney({
                  amount: calculateAnnualValueOfTheBenefitForCurrentTaxYear(
                    new Date(userInsurance.startDate),
                    userInsurance.monthlyPremium,
                    userInsurance.monthlyContribution ?? 0,
                    Number(formik.values.additionalMonthlyPremium),
                    formik.values.employeeCoversAdditionalPremium ? Number(formik.values.additionalMonthlyPremium) : 0
                  ),
                })}
              </Typography>
            )}
          </Box>

          {userFamilyMembers.length > 0 && formik.values.dependants && (
            <Typography sx={{ ...themeFonts.title4, mb: spacing.m10, mt: spacing.m10 }}>
              {polyglot.t('BenefitModule.dependantsDetails')}
            </Typography>
          )}
          {formik.values.dependants && dependantsListOptions.length === 0 && (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Red, mb: spacing.m10 }}>
              {polyglot.t('BenefitModule.noFamilyMembersFound')}
            </Typography>
          )}

          {userFamilyMembers.length > 0 && formik.values.dependants && (
            <Box sx={{ ...fieldSx, mb: spacing.m30 }}>
              <MultipleSelectCheckbox<number>
                label={polyglot.t('BenefitModule.additionalMembersList')}
                id="dependantsList"
                limitTags={-1}
                options={dependantsListOptions}
                value={selectedDependantsList}
                onChange={(_, values) => {
                  const updatedOptionList = values.map(({ value }) => value);
                  formik.setFieldValue('dependantsList', updatedOptionList);
                }}
                isOptionEqualToValue={(x, y) => x.value === y.value}
                getOptionLabel={({ label }: { label: string }): string => label}
              />
            </Box>
          )}

          <Box sx={buttonBoxSx}>
            <LoaderButton
              name={isEditForm ? polyglot.t('General.update') : polyglot.t('General.add')}
              loading={loading}
              sizeVariant="small"
              colorVariant="primary"
              fullWidth
            />
          </Box>
        </>
      </Form>
    </FormikProvider>
  );
};
