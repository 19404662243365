import React, { useState } from 'react';

import { Box, Modal } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { LeavingEmployeeStates } from '@v2/feature/benefits/subfeature/pension/pension.interface';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';

const ModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '20px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  pt: 4,
  pb: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

interface LeavePensionModalProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userId: number;
  readonly userPension: UserPensionDto;
  readonly refresh: () => Promise<void>;
}

// TODO: @polyglot-later-staffology-pension
export const LeavePensionModal = ({ isOpen, setIsOpen, userId, userPension, refresh }: LeavePensionModalProps) => {
  const referenceDate =
    userPension.startDate && new LocalDate().toDateString() < userPension.startDate
      ? userPension.startDate
      : new LocalDate().toDateString();
  const oneMonthAfterStartDate = userPension.startDate ? new LocalDate(userPension.startDate) : new LocalDate();
  oneMonthAfterStartDate.getDate().setMonth(oneMonthAfterStartDate.getDate().getMonth() + 1);
  const hasMoreThanOneMonthInPension = oneMonthAfterStartDate.toDateString() < referenceDate;
  const reason = hasMoreThanOneMonthInPension ? LeavingEmployeeStates.CeasedMembership : LeavingEmployeeStates.OptOut;

  const [loading, setLoading] = useState<boolean>(false);
  const [moreThanOneMonthInPension, setMoreThanOneMonthInPension] = useState<boolean>(hasMoreThanOneMonthInPension);
  const [leaveDate, setLeaveDate] = useState<string>(referenceDate);
  const [isLeavingReason, setIsLeavingReason] = useState<LeavingEmployeeStates>(reason);

  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionAllScope = hasScopes(['pension:all'], scopesContext);

  const [showMessage] = useMessage();

  const leavePension = async (): Promise<void> => {
    try {
      setLoading(true);
      await PensionAPI.optOutPensionEmployees(userId, {
        leaveDate: leaveDate,
        employeeState: isLeavingReason,
      });
      showMessage('Successfully removed from workplace pension.', 'success');
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateDate = (leaveDate: string) => {
    setLeaveDate(leaveDate);

    const oneMonthAfterStartDate = userPension.startDate ? new LocalDate(userPension.startDate) : new LocalDate();
    oneMonthAfterStartDate.getDate().setMonth(oneMonthAfterStartDate.getDate().getMonth() + 1);
    const moreThanOneMonthInPension = oneMonthAfterStartDate.toDateString() < leaveDate;
    const reason = moreThanOneMonthInPension ? LeavingEmployeeStates.CeasedMembership : LeavingEmployeeStates.OptOut;

    setIsLeavingReason(reason);
    setMoreThanOneMonthInPension(moreThanOneMonthInPension);
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={ModalStyle}>
        <Typography variant="title2">Leaving pension</Typography>

        {hasPensionAllScope && (
          <Typography variant="caption">
            By performing this action on behalf of the employee you confirm that they have expressed will to leave this
            pension scheme. A record of this action will be kept for your records. Note that your Pension Provider may
            still require this employee to notify them of the decision.
          </Typography>
        )}

        <DatePickerComponent
          inputFormat="DD/MM/YYYY"
          value={leaveDate}
          minDate={userPension.startDate}
          onChange={(value) => {
            if (dayjs(value).isValid()) {
              updateDate(value);
            }
          }}
          name="leaveDate"
          label="Leave date"
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: spacing.mt40 }}>
          <LoaderButton
            name={moreThanOneMonthInPension ? 'Leave Pension' : 'Opt Out'}
            sizeVariant="medium"
            colorVariant="danger"
            onClick={leavePension}
            loading={loading}
            type="button"
          />
        </Box>
      </Box>
    </Modal>
  );
};
