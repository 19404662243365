import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CompanyPayroll, TaxYear } from '@shared/modules/payroll/payroll.types';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as UploadIcon } from '@/images/icons/Upload.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SideMenuHeader } from '@/v2/feature/payroll/components/side-menu-header.component';
import { formatPayrunPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { GlobalPayrollTable } from '@/v2/feature/payroll/features/payroll-global/components/global-payroll-table.component';
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints,
} from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { ImportPayrunDrawer } from '@/v2/feature/payroll/features/payroll-global/global-payrun-flow/import-payrun-drawer.component';
import { PayrunHeader } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header.component';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { navigateToPayrunsView } from '@/v2/feature/payroll/payroll-router.util';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { CompanyPayrollType, PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type GlobalPayrunPageProps = {
  payroll: CompanyPayroll;
  payrollCount: number;
  taxYear: TaxYear;
  payPeriod: PayPeriod;
  period: number;
};

export const GlobalPayrunPage = ({ payroll, taxYear, payPeriod, period }: GlobalPayrunPageProps) => {
  const routerHistory = useHistory();
  const [isFinalising, setIsFinalising] = useState(false);
  const [showMessage] = useMessage();
  const [showImportDrawer, setShowImportDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { mutate: refreshPayrollM } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), {
    suspense: false,
    revalidateOnMount: false,
  });

  const { data: payrun, mutate: refreshPayrunM, isValidating: isRefreshingPayrun } = useApiClient(
    GlobalPayrollEndpoints.getPayrun(payroll.id, taxYear, payPeriod, period),
    {
      suspense: false,
    }
  );

  const refreshPayroll = useCallback(async () => {
    if (refreshPayrollM) await refreshPayrollM();
  }, [refreshPayrollM]);

  const refreshPayrun = useCallback(async () => {
    if (refreshPayrunM) await refreshPayrunM();
  }, [refreshPayrunM]);

  const lockPayrun = useCallback(async () => {
    setIsFinalising(true);
    try {
      await GlobalPayrollAPI.finalisePayrun(payroll.id, taxYear, payPeriod, period);
      await refreshPayroll();
      navigateToPayrunsView(routerHistory, 'replace', payroll.id, payrun?.id);
    } catch (error) {
      showMessage(error.message, 'error');
      setIsFinalising(false);
    }
  }, [payroll.id, taxYear, payPeriod, period, refreshPayroll, routerHistory, payrun?.id, showMessage]);

  const entityName = payroll.entity?.legalName ?? '';

  return (
    <Stack sx={{ flex: 1, flexFlow: 'row', alignItems: 'top', gap: spacing.g60, overflow: 'hidden' }}>
      <SideMenuHeader
        heading={formatPayrunPeriod({
          payPeriod,
          period,
          taxYear,
          taxYearStart: payroll.schedule?.taxYearStart ?? '01-01',
        })}
        caption={entityName}
        onBackClick={() => {
          navigateToPayrunsView(routerHistory, 'replace', payroll.id, payrun?.id);
        }}
        sx={{ mt: '20px', ml: '20px' }}
      />
      <Stack sx={{ flex: 1, overflowY: 'auto' }}>
        {payrun && (
          <Stack sx={{ gap: spacing.g20, mr: spacing.mr40 }}>
            <PayrunHeader
              payrollId={payroll.id}
              payrollType={CompanyPayrollType.GlobalPayroll}
              entityName={entityName}
              state={payrun.state as PayrunStates}
              onReopenPayrun={async () => {
                await Promise.all([refreshPayroll(), refreshPayrun()]);
              }}
              sx={{ position: 'sticky', top: 0, zIndex: 2, background: themeColors.white, py: spacing.p15 }}
            />
            <Box
              sx={{
                height: '100%',
                flexGrow: 1,
                paddingBottom: '40px',
                overflowY: 'auto',
              }}
            >
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="title2">Review and edit pay for employees</Typography>
                <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: spacing.g10 }}>
                  {payrun.state === 'DRAFT' && (
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="secondary"
                      startIcon={<UploadIcon {...iconSize} />}
                      onClick={() => setShowImportDrawer(true)}
                      disabled={showImportDrawer || isFinalising}
                    >
                      Import
                    </ButtonComponent>
                  )}
                  <a
                    style={{ color: 'unset', textDecoration: 'unset' }}
                    href={GlobalPayrollEndpoints.exportPayrun(payroll.id, taxYear, payPeriod, period).url}
                  >
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="secondary"
                      startIcon={<DownloadIcon {...iconSize} />}
                      disabled={showImportDrawer || isFinalising}
                    >
                      Export
                    </ButtonComponent>
                  </a>
                </Stack>
              </Stack>
              <Box sx={{ my: spacing.m10 }}>
                <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
              </Box>
              <GlobalPayrollTable
                payrun={payrun}
                searchQuery={searchQuery}
                allowEdit={payrun.state === 'DRAFT'}
                refreshPayrun={refreshPayrun}
              />
              {payrun.state === 'DRAFT' && !isRefreshingPayrun && (
                <Stack sx={{ mt: spacing.m10, mb: spacing.m30, alignItems: 'flex-end' }}>
                  <LoaderButton
                    type="button"
                    name="Lock payrun"
                    loading={isFinalising}
                    onClick={async () => {
                      await lockPayrun();
                    }}
                    sizeVariant="large"
                    colorVariant="primary"
                    fullWidth
                    style={{ width: 'fit-content' }}
                  />
                </Stack>
              )}
            </Box>
          </Stack>
        )}
      </Stack>
      {payrun && (
        <ImportPayrunDrawer
          isOpen={showImportDrawer}
          setIsOpen={setShowImportDrawer}
          payrun={payrun}
          onImportSuccess={async () => {
            await refreshPayrun();
            setShowImportDrawer(false);
            showMessage('Payrun updated', 'success');
          }}
          onUploadFailed={() => {
            setShowImportDrawer(false);
            showMessage('File upload failed.', 'error');
          }}
        />
      )}
    </Stack>
  );
};
