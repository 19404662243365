import { Box, SwipeableDrawer } from '@mui/material';

import { SalarySummaryPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/salary-summary-page.component';
import { ExternalCustomPayCode } from '@/v2/feature/payroll/payroll-external.dto';
import { PayRunEntryDto } from '@/v2/feature/payroll/payroll.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  userId?: number;
  payrunEntry?: PayRunEntryDto;
  customPayCodes?: ExternalCustomPayCode[];
}

export const SalarySummaryDrawer = ({
  onOpen = () => {},
  onClose = () => {},
  userId,
  payrunEntry,
  customPayCodes,
}: Props): JSX.Element => {
  const { getCachedUserById } = useCachedUsers();
  const user = userId && getCachedUserById(userId);

  return (
    <SwipeableDrawer anchor="right" open={!!user} onClose={onClose} onOpen={onOpen}>
      {user && payrunEntry && (
        <Box sx={{ px: spacing.px40, py: spacing.p30, width: '440px', boxSizing: 'border-box', maxWidth: '100vw' }}>
          <SalarySummaryPage user={user} payrunEntry={payrunEntry} customPayCodes={customPayCodes} />
        </Box>
      )}
    </SwipeableDrawer>
  );
};
