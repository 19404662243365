import { useCallback, useEffect, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge, Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';

import { CompanyDepartmentAPI } from '@/api-client/company-department.api';
import { CompensationAPI } from '@/api-client/compensation.api';
import { ContractsAPI } from '@/api-client/contracts.api';
import { SiteAPI } from '@/api-client/site.api';
import { UserRoleAPI } from '@/api-client/user-role.api';
import useMessage from '@/hooks/notification.hook';
import cover from '@/images/illustration_devices.png';
import { ReactComponent as Delete } from '@/images/side-bar-icons/Delete.svg';
import { nestErrorMessage } from '@/lib/errors';
import { UserBasicsInfo } from '@/models';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { sortDate, sortString } from '@/v2/components/table/table-sorting.util';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { UserCompensationDto } from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { UserContractDto } from '@/v2/feature/user/features/user-forms/user-contract/user-contract.dto';
import { UserRoleDto } from '@/v2/feature/user/features/user-forms/user-role/user-role.dto';
import { displayUserName } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const RECORD_TABS = [{ value: 'Contract' }, { value: 'Role' }, { value: 'Compensation' }] as const;

export const HelperCompanyRecordsRouter = ({
  company,
}: {
  company: SuperAdminCompanyInfo | undefined;
}): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const params = useParams<{ readonly companyId: string }>();
  const companyId = Number(params.companyId);

  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('Contract');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [companyContracts, setCompanyContracts] = useState<UserContractDto[]>([]);
  const [companyRoles, setCompanyRoles] = useState<UserRoleDto[]>([]);
  const [companyDepartments, setCompanyDepartments] = useState<CompanyDepartmentDto[]>([]);
  const [companySites, setCompanySites] = useState<SiteDto[]>([]);
  const [companyCompensation, setCompanyCompensation] = useState<UserCompensationDto[]>([]);
  const [companyUserMap, setCompanyUserMap] = useState<Record<number, UserBasicsInfo>>({});

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const [contracts, roles, compensation, departments, sites, usersForCompany] = await Promise.all([
        ContractsAPI.getContractsByCompanyId(companyId),
        UserRoleAPI.getRolesByCompanyId(companyId),
        CompensationAPI.getCompensationByCompanyId(companyId),
        CompanyDepartmentAPI.getCompanyDepartments(),
        SiteAPI.listSites(),
        UserAPI.getUserSuperadminBasicByCompany(companyId),
      ]);
      setCompanyContracts(contracts);
      setCompanyRoles(roles);
      setCompanyDepartments(departments);
      setCompanySites(sites);
      setCompanyCompensation(compensation);
      resetSelectionModel();
      const usersMap = usersForCompany.reduce((map: Record<number, UserBasicsInfo>, user) => {
        map[user.userId] = user;
        return map;
      }, {});
      setCompanyUserMap(usersMap);
    } catch (error) {
      showMessage(`Error loading company records: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, companyId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const resetSelectionModel = () => {
    setSelectionModel([]);
  };

  const apiMap = {
    Contract: ContractsAPI.bulkDeleteContractsByIds,
    Role: UserRoleAPI.bulkDeleteRolesByIds,
    Compensation: CompensationAPI.bulkDeleteCompensationByIds,
  };

  const deleteCompanyRecords = async () => {
    const apiFunction = apiMap[currentTab as keyof typeof apiMap];
    if (apiFunction) {
      try {
        await apiFunction(companyId, selectionModel);
        showMessage(`Successfully deleted ${selectionModel.length} ${currentTab} records`, 'success');
        resetSelectionModel();
        refresh();
      } catch (error) {
        showMessage(`Error deleting records: ${nestErrorMessage(error)}`, 'error');
      }
    } else {
      showMessage('Deletion not yet available', 'warning');
    }
    setIsRemovalModalOpen(false);
    setAnchorEl(null);
  };

  const confirmTriggerDeleteCompanyRecords = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setIsRemovalModalOpen(true);
  };

  const tabRecordCount = (tabValue: string) => {
    const recordMap = {
      Contract: companyContracts?.length,
      Role: companyRoles?.length,
      Compensation: companyCompensation?.length,
    };
    return recordMap[tabValue as keyof typeof recordMap] ?? '';
  };

  const contractColumns = useMemo<ColumnDef<UserContractDto, UserContractDto>[]>(
    () => [
      {
        enableSorting: false,
        id: 'selectCell',
        minSize: 20,
        maxSize: 20,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <CheckboxComponent
              id={original.id?.toString()}
              label={undefined}
              name="selectedRecord"
              checked={selectionModel.includes(Number(original.id))}
              onChange={() => {
                let finalSelection: number[] = [];
                if (original.id) {
                  if (selectionModel.find((recordId) => recordId === original.id)) {
                    finalSelection = selectionModel.filter((recordId) => recordId !== original.id);
                  } else finalSelection = [...selectionModel, original.id];
                  setSelectionModel(finalSelection);
                }
              }}
            />
          );
        },
        header: () => {
          return (
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{
                display: selectionModel.length > 0 ? '' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CheckboxComponent
                id={'deselectAll'}
                label={undefined}
                name="selectedRecord"
                checked={selectionModel?.length > 0}
                onChange={() => {
                  resetSelectionModel();
                }}
              />
            </Box>
          );
        },
      },
      {
        header: () => 'Employee name',
        id: 'employeeName',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => {
            return item.userId ? companyUserMap[item.userId]?.displayName : '';
          }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => original.userId && <div>{companyUserMap[original.userId]?.displayName}</div>,
      },
      {
        header: () => 'User ID',
        id: 'userId',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 30,
        cell: ({ row: { original } }) => <div>{original.userId ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Effective date',
        id: 'effectiveDate',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.effectiveDate),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.effectiveDate ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Employment Contract',
        id: 'contract',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => (item.contract ? item.contract : '')),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.contract ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Worker type',
        id: 'type',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => (item.type ? item.type : '')),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.type ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'FTE% (schedule)',
        id: 'fte',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.attendanceSchedule?.ftePercent ?? <EmptyCell />}</div>,
      },
      {
        headerName: 'Holiday calendar',
        id: 'holidayCalendar',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.publicHolidays ?? <EmptyCell />}</div>,
      },
      {
        headerName: 'Entity',
        id: 'entity',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.entityId ?? <EmptyCell />}</div>,
      },
      {
        headerName: 'Change reason',
        id: 'changeReason',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.changeReason ?? <EmptyCell />}</div>,
      },
    ],
    [companyUserMap, selectionModel]
  );

  const roleColumns = useMemo<ColumnDef<UserRoleDto, UserRoleDto>[]>(
    () => [
      {
        accessorFn: (row) => row,
        id: 'selectCell',
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        cell: ({ row: { original } }) => {
          return (
            <Box key={original.id} onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                label={undefined}
                name="selectedRecord"
                checked={selectionModel.includes(Number(original.id))}
                onChange={() => {
                  let finalSelection: number[] = [];
                  if (original) {
                    if (selectionModel.find((recordId) => recordId === original.id)) {
                      finalSelection = selectionModel.filter((recordId) => recordId !== original.id);
                    } else finalSelection = [...selectionModel, original.id];
                    setSelectionModel(finalSelection);
                  }
                }}
              />
            </Box>
          );
        },
        header: () => {
          return (
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{
                display: selectionModel.length > 0 ? '' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CheckboxComponent
                id={'deselectAll'}
                label={undefined}
                name="selectedRecord"
                checked={selectionModel?.length > 0}
                onChange={() => {
                  resetSelectionModel();
                }}
              />
            </Box>
          );
        },
      },
      {
        header: () => 'Employee name',
        id: 'employeeName',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => {
            return item.userId ? companyUserMap[item.userId]?.displayName : '';
          }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => original.userId && <div>{companyUserMap[original.userId]?.displayName}</div>,
      },
      {
        header: () => 'User ID',
        id: 'userId',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 30,
        cell: ({ row: { original } }) => <div>{original.userId ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Effective date',
        id: 'effectiveDate',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.effectiveDate),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => (
          <div>{original?.effectiveDate?.toString().split('T')[0] ?? <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'Job title',
        id: 'jobTitle',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => (item.jobTitle ? item.jobTitle : '')),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.jobTitle ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Department',
        accessorFn: (row) => row,
        id: 'department',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { departmentId },
          },
        }) => <div>{departmentId ? companyDepartments.find((d) => d.id === departmentId)?.name : ''}</div>,
      },
      {
        header: () => 'Site',
        accessorFn: (row) => row,
        id: 'site',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { siteId },
          },
        }) => (
          <div style={{ textTransform: 'capitalize' }}>
            {siteId ? companySites.find((s) => s.id === siteId)?.name : ''}
          </div>
        ),
      },
      {
        header: () => 'Reports to',
        accessorFn: (row) => row.managerId,
        id: 'reportsTo',
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { managerId },
          },
        }) => {
          const reportsTo = managerId ? companyUserMap[managerId] : undefined;
          return (
            <div>
              {reportsTo ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <UserAvatar userId={reportsTo.userId} size="xxsmall" />
                  <Typography sx={{ ...themeFonts.caption, marginLeft: spacing.m10 }}>
                    {displayUserName(reportsTo)}
                  </Typography>
                </Box>
              ) : (
                '-'
              )}
            </div>
          );
        },
      },
    ],
    [companyDepartments, companySites, companyUserMap, selectionModel]
  );

  const compensationColumns = useMemo<ColumnDef<UserCompensationDto, UserCompensationDto>[]>(
    () => [
      {
        enableSorting: false,
        id: 'selectCell',
        minSize: 20,
        maxSize: 20,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <CheckboxComponent
              id={original?.id?.toString() ?? '0'}
              label={undefined}
              name="selectedRecord"
              checked={selectionModel.includes(Number(original?.id) ?? false)}
              onChange={() => {
                let finalSelection: number[] = [];
                if (original && original.id) {
                  if (selectionModel.find((recordId) => recordId === original.id)) {
                    finalSelection = selectionModel.filter((recordId) => recordId !== original.id);
                  } else finalSelection = [...selectionModel, original.id];
                  setSelectionModel(finalSelection);
                }
              }}
            />
          );
        },
        header: () => {
          return (
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{
                display: selectionModel.length > 0 ? '' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CheckboxComponent
                id={'deselectAll'}
                label={undefined}
                name="selectedRecord"
                checked={selectionModel?.length > 0}
                onChange={() => {
                  resetSelectionModel();
                }}
              />
            </Box>
          );
        },
      },
      {
        header: () => 'Employee name',
        id: 'employeeName',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => {
            return item.userId ? companyUserMap[item.userId]?.displayName : '';
          }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => original.userId && <div>{companyUserMap[original.userId]?.displayName}</div>,
      },
      {
        header: () => 'User ID',
        id: 'userId',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 30,
        cell: ({ row: { original } }) => <div>{original.userId ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Effective date',
        id: 'effectiveDate',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.effectiveDate),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.effectiveDate ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Pay schedule',
        id: 'paySchedule',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => (item.paySchedule ? item.paySchedule : '')),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.paySchedule ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Salary basis',
        id: 'salaryBasis',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => (item.salaryBasis ? item.salaryBasis : '')),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.salaryBasis ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Rate',
        id: 'rate',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.rate ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Prorated Rate',
        id: 'proratedRate',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.proratedRate ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Units',
        id: 'units',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.units ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Salary per annum',
        id: 'salaryPerAnnum',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original?.salaryPerPaySchedule ?? <EmptyCell />}</div>,
      },
    ],
    [companyUserMap, selectionModel]
  );

  const currentTabColumns = useMemo(() => {
    switch (currentTab) {
      case 'Contract':
        return contractColumns;
      case 'Role':
        return roleColumns;
      case 'Compensation':
        return compensationColumns;
      default:
        return [];
    }
  }, [compensationColumns, contractColumns, currentTab, roleColumns]);

  const currentTabData = useMemo(() => {
    switch (currentTab) {
      case 'Contract':
        return companyContracts;
      case 'Role':
        return companyRoles;
      case 'Compensation':
        return companyCompensation;
      default:
        return [];
    }
  }, [companyCompensation, companyContracts, companyRoles, currentTab]);

  const switchTab = (tabValue: string) => {
    resetSelectionModel();
    setCurrentTab(tabValue);
  };

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE}>
        <BackofficeRootStyle>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                Helper page - Records | {company?.name ? company.name : ''} [ {companyId} ]
              </Typography>
            }
            showBack
            backPath={generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })}
          />
          <ContentWrapper loading={loading}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  alignItems: 'left',
                }}
              >
                <Tabs
                  value={currentTab}
                  scrollButtons="auto"
                  variant="scrollable"
                  allowScrollButtonsMobile
                  onChange={(e, value) => switchTab(value)}
                >
                  {RECORD_TABS.map((tab) => (
                    <Tab
                      key={tab.value}
                      disableRipple
                      label={
                        <span
                          style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            paddingRight:
                              // @ts-ignore
                              currentTabData && currentTabData[tab.value.toLowerCase()]?.length > 0 ? 10 : 0,
                          }}
                        >
                          {tab.value}
                          {<Box sx={{ ml: spacing.ml10 }}> ({tabRecordCount(tab.value)})</Box>}
                          {/* @ts-ignore */}
                          {currentTabData && currentTabData[tab.value.toLowerCase()]?.length > 0 && (
                            <Badge
                              badgeContent={
                                // @ts-ignore
                                currentTabData && currentTabData[tab.value.toLowerCase()]?.length > 0
                                  ? // @ts-ignore
                                    currentTabData[tab.value.toLowerCase()].length
                                  : ''
                              }
                              color="primary"
                              sx={{ ml: 2 }}
                            />
                          )}
                        </span>
                      }
                      value={tab.value}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mr: 4,
                }}
              >
                <IconButton color="secondary" onClick={() => refresh()}>
                  <RefreshIcon />
                </IconButton>
                <IconButton
                  sx={{ display: selectionModel?.length > 0 ? '' : 'none' }}
                  onClick={(e) => confirmTriggerDeleteCompanyRecords(e)}
                >
                  <Delete />{' '}
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                    {selectionModel?.length ? `(${selectionModel?.length})` : ''}
                  </Typography>
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ mt: spacing.mt20 }}>
              {currentTabData && currentTab && currentTab === 'Contract' && (
                <BasicTable<UserContractDto>
                  rowData={(currentTabData as UserContractDto[]) ?? []}
                  columnData={currentTabColumns as ColumnDef<UserContractDto, UserContractDto>[]}
                  loading={loading}
                  initialSort={[{ id: 'employeeName', desc: true }]}
                />
              )}
              {currentTabData && currentTab && currentTab === 'Role' && (
                <BasicTable<UserRoleDto>
                  rowData={(currentTabData as UserRoleDto[]) ?? []}
                  columnData={currentTabColumns as ColumnDef<UserRoleDto, UserRoleDto>[]}
                  loading={loading}
                  initialSort={[{ id: 'employeeName', desc: true }]}
                />
              )}
              {currentTabData && currentTab && currentTab === 'Compensation' && (
                <BasicTable<UserCompensationDto>
                  rowData={(currentTabData as UserCompensationDto[]) ?? []}
                  columnData={currentTabColumns as ColumnDef<UserCompensationDto, UserCompensationDto>[]}
                  loading={loading}
                  initialSort={[{ id: 'employeeName', desc: true }]}
                />
              )}
            </Box>
            {!currentTabData && (
              <EmptyStateComponent
                header={!currentTabData ? 'No tab selected' : 'No data'}
                subheader={
                  !currentTab
                    ? 'Please select a tab from above in order to see relevant company records'
                    : 'No company records'
                }
                cover={cover}
              />
            )}
            <NotificationModal
              isOpen={isRemovalModalOpen}
              onClose={() => setIsRemovalModalOpen(false)}
              anchorEl={anchorEl}
              takeAction={async () => {
                deleteCompanyRecords();
              }}
              message={`Are you sure you want to delete the ${selectionModel.length} selected ${currentTab} records?`}
              callToAction="Yes"
            />
          </ContentWrapper>
        </BackofficeRootStyle>
      </Route>
    </Switch>
  );
};
