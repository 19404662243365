import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { useLocation } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  PaymentSettingEditMode,
  PaymentSettingsNewTypeDrawer,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component';
import { DeletePaymentTypeDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-delete-drawer.component';
import {
  PaymentSettingsPageSection,
  DescriptionLine,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import {
  PaymentTypeSettingAccountingAppConfig,
  PaymentTypeSettings,
  isExpenseCheck,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

interface PageProps {
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly refresh: KeyedMutator<PaymentTypeSettings>;
  readonly refreshAllPaymentSettings: KeyedMutator<PaymentTypeSettingAccountingAppConfig>;
}

export const PaymentTypeGeneralSettingsPage = ({
  paymentTypeSetting,
  refresh,
  refreshAllPaymentSettings,
}: PageProps) => {
  const { polyglot } = usePolyglot();
  const { pathname } = useLocation();
  const isExpense = isExpenseCheck(pathname);
  const type = isExpense ? 'expense' : 'invoices';

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<PaymentSettingEditMode | undefined>(undefined);

  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [typeForEdit, setTypeForEdit] = useState<PaymentTypeSettings | undefined>(undefined);

  const { payrolled, requireAttachment } = paymentTypeSetting;

  const handleEditForName = () => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setEditMode('name');
  };

  const handleEdit = (mode: PaymentSettingEditMode) => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setEditMode(mode);
  };

  return (
    <PaymentSettingsPageSection title={polyglot.t('General.general')} onEdit={handleEditForName}>
      <DescriptionLine field={polyglot.t('General.name')} value={paymentTypeSetting.name} />
      <DescriptionLine
        field={polyglot.t('PaymentSettingsPage.requiresAttachment')}
        value={polyglot.t(requireAttachment ? 'General.yes' : 'General.no')}
      />
      {isExpense && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: spacing.mt20 }}>
            <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
              {polyglot.t('PaymentSettingsPage.paymentHeader')}
            </Typography>
            <IconButton key="edit" sx={tableIconButtonSx} onClick={() => handleEdit('payment')}>
              <EditIcon {...actionIconSize} />
            </IconButton>
          </Box>
          <DescriptionLine
            field={polyglot.t('PaymentSettingsPage.subtitles.method')}
            value={polyglot.t(payrolled ? 'PaymentSettingsPage.methodPayroll' : 'PaymentSettingsPage.methodDirect')}
          />
        </>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: spacing.mt20 }}>
        <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
          {polyglot.t('PaymentSettingsPage.deleteHeader')} {type}
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption">
          {polyglot.t('PaymentSettingsPage.deleteSubtitle', { settingType: type })}
          <ButtonComponent
            sizeVariant="small"
            colorVariant="text"
            onClick={() => {
              setIsDelete(true);
            }}
            style={{
              margin: 0,
              padding: 0,
              color: themeColors.RedDark,
              display: 'inline',
              textDecoration: 'underline',
            }}
          >
            {polyglot.t('PaymentSettingsPage.deleteHere')}
          </ButtonComponent>
        </Typography>
      </Box>
      {paymentTypeSetting && (
        <DeletePaymentTypeDrawer
          isOpen={isDelete}
          setIsOpen={setIsDelete}
          paymentTypeSetting={paymentTypeSetting}
          refreshAllPaymentSettings={refreshAllPaymentSettings}
        />
      )}

      {isEditOpen && (
        <PaymentSettingsNewTypeDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          refreshIndividualSetting={refresh}
          typeForEdit={typeForEdit}
          editMode={editMode}
        />
      )}
    </PaymentSettingsPageSection>
  );
};
