import { useCallback, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ClickBig } from '@/images/onboarding-intro/ClickBig.svg';
import { ReactComponent as NotiBig } from '@/images/onboarding-intro/NotiBig.svg';
import { ReactComponent as RocketBig } from '@/images/onboarding-intro/RocketBig.svg';
import { ReactComponent as TimeAwayBig } from '@/images/onboarding-intro/TimeAwayBig.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  IconContentActionCardProps,
  IntroCardGroup,
} from '@/v2/components/theme-components/icon-content-action-card.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { goToEditTemplate } from '@/v2/feature/onboarding/onboarding.util';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type OnboardingIntroProps = {
  loading?: boolean;
  refresh?: () => void;
};

export const OnboardingIntro = ({ loading, refresh }: OnboardingIntroProps) => {
  const { polyglot } = usePolyglot();
  const OnboardingIntroCards: IconContentActionCardProps[] = [
    {
      title: polyglot.t('OnboardingIntro.moreConsistent'),
      content: polyglot.t('OnboardingIntro.moreConsistentDesc'),
      iconMedia: <RocketBig />,
    },
    {
      title: polyglot.t('OnboardingIntro.moreAutomated'),
      content: polyglot.t('OnboardingIntro.moreAutomatedDesc'),
      iconMedia: <ClickBig />,
    },
    {
      title: polyglot.t('OnboardingIntro.RightTime'),
      content: polyglot.t('OnboardingIntro.RightTimeDesc'),
      iconMedia: <TimeAwayBig />,
    },
    {
      title: polyglot.t('OnboardingIntro.automaticReminders'),
      content: polyglot.t('OnboardingIntro.automaticRemindersDesc'),
      iconMedia: <NotiBig />,
    },
  ];
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [initialisingOnboarding, setInitialisingOnboarding] = useState(false);

  const setupOnboarding = useCallback(async () => {
    setInitialisingOnboarding(true);
    try {
      const firstTemplate = await OnboardingAPI.createOnboardingTemplate({
        name: 'Onboarding flow',
        template: {
          basic: true,
        },
      });
      goToEditTemplate(routerHistory, firstTemplate.templateId, 'first-template');
      refresh?.();
    } catch (error) {
      showMessage(
        polyglot.t('OnboardingIntro.errorMessages.initialise', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
      setInitialisingOnboarding(false);
    }
  }, [polyglot, refresh, routerHistory, showMessage]);

  const header = (
    <TopHeader
      title={
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingIntro.onboarding')}
        </Typography>
      }
    />
  );

  if (loading) {
    return <RootStyle>{header}</RootStyle>;
  }

  return (
    <RootStyle>
      {header}
      <ContentWrapper border={false} sx={{ paddingBottom: 0, pt: 0 }}>
        <Box sx={{ height: '100%', ...spacing.px40 }}>
          <Box
            sx={{
              flex: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Stack sx={{ maxWidth: '600px' }}>
              <Typography sx={themeFonts.caption}>{polyglot.t('OnboardingIntro.onboardingDesc')}</Typography>
            </Stack>
            <LoaderButton
              name={polyglot.t('General.start')}
              loading={initialisingOnboarding}
              style={{ flexShrink: 0, marginTop: '30px' }}
              colorVariant="primary"
              sizeVariant="large"
              onClick={() => {
                setupOnboarding();
              }}
            />
            <IntroCardGroup cards={OnboardingIntroCards} sx={{ mt: 'auto' }} />
          </Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
