import { useState } from 'react';

import { Row } from '@tanstack/react-table';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { PermissionCategoryListingTable } from '@/v2/feature/permission-group/components/permission-category-listing-table.component';
import { PermissionGroupEditDrawerPage } from '@/v2/feature/permission-group/components/permission-group-edit-drawer.component';
import { PermissionGroupMemberUserDto } from '@/v2/feature/permission-group/permission-group.dto';
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES,
  PermissionCategory,
  PermissionGroupPageProps,
} from '@/v2/feature/permission-group/permission-group.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const EditPermissionGroupPermissionsPage = ({
  permissionGroup,
  refresh,
  additionalScopes,
  permissionCategories,
}: PermissionGroupPageProps) => {
  const { polyglot } = usePolyglot();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [permissionGroupDrawerMode, setPermissionGroupDrawerMode] = useState<PERMISSION_GROUP_EDIT_DRAWER_MODES>(
    PERMISSION_GROUP_EDIT_DRAWER_MODES.member
  );
  const [selectedPermissionCategory, setSelectedPermissionCategory] = useState<PermissionCategory | null>(null);

  const openDrawerInEditScopesMode = (permissionCategory: PermissionCategory) => {
    setSelectedPermissionCategory(permissionCategory);
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.scope);
    setEditDrawerOpen(true);
  };

  const openDrawerInViewScopesMode = (permissionCategoryRow: Row<PermissionCategory>) => {
    setSelectedPermissionCategory(permissionCategoryRow.original);
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.view);
    setEditDrawerOpen(true);
  };

  return (
    <SettingsSectionContent title={polyglot.t('PermissionGroups.router.permissions')}>
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <PermissionCategoryListingTable
                    permissionCategories={permissionCategories}
                    defaultPermission={permissionGroup ? permissionGroup.isDefault : false}
                    loading={isLoading}
                    openDrawerInEditMode={openDrawerInEditScopesMode}
                    openDrawerInViewMode={openDrawerInViewScopesMode}
                    permissionGroupName={permissionGroup?.name ?? ''}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <DrawerModal isOpen={editDrawerOpen} setIsOpen={setEditDrawerOpen}>
        <PermissionGroupEditDrawerPage
          refreshPermissionGroup={refresh}
          selectedPermission={permissionGroup}
          selectedPermissionCategory={selectedPermissionCategory}
          permissionGroupMembers={(permissionGroup?.members as PermissionGroupMemberUserDto[]) ?? []}
          closePage={async () => {
            setEditDrawerOpen(false);
            await refresh();
          }}
          mode={permissionGroupDrawerMode}
          setLoading={setIsLoading}
          permissionGroupName={permissionGroup?.name ?? ''}
          additionalScopesToEdit={
            additionalScopes && selectedPermissionCategory?.name
              ? additionalScopes[selectedPermissionCategory.name]
              : undefined
          }
        />
      </DrawerModal>
    </SettingsSectionContent>
  );
};
