import { Fragment, useContext } from 'react';

import { Box } from '@mui/material';

import { GlobalContext } from '@/GlobalState';
import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import '@/v2/feature/dashboard/features/sections/user-profile-widget/dashboard-user-profile.scss';
import { spacing } from '@/v2/styles/spacing.styles';
export const DashboardUserProfileLoader = () => {
  const [globalState] = useContext(GlobalContext);
  const user = globalState.user;
  return (
    <WidgetLayout readOnly={false} size="small" isLoadingState={true}>
      <Fragment>
        <Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: spacing.p10,
            }}
          >
            <div className="upl-wave"></div>
            <Typography
              variant="title2"
              sx={{
                marginTop: spacing.m15,
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </div>
        </Box>

        <Box sx={{ mt: spacing.m5, width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '40%' }}>
            <div className="upl-loader-container">
              <div className="upl-progress" style={{ width: '50%' }}></div>
            </div>
          </div>
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
