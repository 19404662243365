import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AllowanceRoundingType, PolicyRoundingFormData } from '@v2/feature/absence/absence.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyRoundingEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyRoundingEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: PolicyRoundingEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: PolicyRoundingFormData) => {
      const policyData: PolicyRoundingFormData = {
        allowanceRoundingType: values.allowanceRoundingType,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyRounding(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );

  const formik = useFormik<PolicyRoundingFormData>({
    initialValues: {
      allowanceRoundingType: absencePolicy.allowanceRoundingType,
    },
    validationSchema: yup.object({
      allowanceRoundingType: yup
        .string()
        .oneOf(Object.values(AllowanceRoundingType))
        .required('Please select a valid option.'),
    }),
    onSubmit,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsenceUtil.rounding')}</Typography>

          <Typography variant="caption">{polyglot.t('AbsenceUtil.roundingDesc')}</Typography>

          <Box>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                name="prorating-allocation-group"
                onChange={(event) => {
                  formik.setFieldValue('allowanceRoundingType', event.target.value as AllowanceRoundingType);
                }}
                sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <FormControlLabel
                  key="no-rounding"
                  labelPlacement="end"
                  value={AllowanceRoundingType.NoRounding}
                  checked={formik.values.allowanceRoundingType === AllowanceRoundingType.NoRounding}
                  control={<StyledRadio />}
                  label={<Typography variant="title4">{polyglot.t('AbsenceUtil.noRounding')}</Typography>}
                />
                <FormControlLabel
                  key="up-to-half"
                  labelPlacement="end"
                  value={AllowanceRoundingType.UpToHalfDay}
                  checked={formik.values.allowanceRoundingType === AllowanceRoundingType.UpToHalfDay}
                  control={<StyledRadio />}
                  label={<Typography variant="title4">{polyglot.t('AbsenceUtil.upToNearestHalf')}</Typography>}
                />
                <FormControlLabel
                  key="up-to-full"
                  labelPlacement="end"
                  value={AllowanceRoundingType.UpToFullDay}
                  checked={formik.values.allowanceRoundingType === AllowanceRoundingType.UpToFullDay}
                  control={<StyledRadio />}
                  label={<Typography variant="title4">{polyglot.t('AbsenceUtil.upToNearestFull')}</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
