import React, { useContext } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewAPI } from '@/v2/feature/growth/reviews/api-client/review.api';
import { ReviewResult } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { ReviewComment } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const ResultsOverviewCommentModal = ({
  isOpen,
  setIsOpen,
  onClose,
  results,
  refreshResults,
  isPublic,
  selectedComment,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  results: ReviewResult | null | undefined;
  refreshResults: KeyedMutator<ReviewResult> | undefined;
  isPublic: boolean;
  selectedComment: ReviewComment | undefined;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ResultsOverviewCommentContent
        results={results}
        refreshResults={refreshResults}
        onClose={onClose}
        isPublic={isPublic}
        selectedComment={selectedComment}
      />
    </DrawerModal>
  );
};

const useCommentForm = (
  results: ReviewResult | null | undefined,
  selectedComment: ReviewComment | undefined,
  refreshResults: KeyedMutator<ReviewResult> | undefined,
  onClose: () => void,
  isPublic: boolean
) => {
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);

  const formik = useFormik({
    initialValues: {
      comment: selectedComment ? selectedComment.comment : '',
    },
    validationSchema: yup.object({
      comment: yup.string().notRequired(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (results) {
          await ReviewAPI.upsertComment(results.reviewId, results.cycleId, {
            id: selectedComment ? selectedComment.id : v4(),
            comment: values.comment,
            isPublic: selectedComment ? selectedComment.isPublic : isPublic,
            commentBy: selectedComment ? selectedComment.commentBy : globalState.user.userId,
          });
          showMessage('Successfully update the comment', 'success');
        }

        await refreshResults?.();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};

const ResultsOverviewCommentContent = ({
  results,
  refreshResults,
  onClose,
  isPublic,
  selectedComment,
}: {
  results: ReviewResult | null | undefined;
  refreshResults: KeyedMutator<ReviewResult> | undefined;
  onClose: () => void;
  isPublic: boolean;
  selectedComment: ReviewComment | undefined;
}) => {
  const { polyglot } = usePolyglot();

  const formik = useCommentForm(results, selectedComment, refreshResults, onClose, isPublic);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Add {isPublic ? 'public' : 'private'} comment</Typography>
        <RichTextField
          label="Comment text"
          value={formik.values.comment}
          onChange={(value: string) => formik.setFieldValue('comment', value)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
