import * as yup from 'yup';

import { DeviceType } from '@/v2/feature/device/device.interface';

export const deviceImportValidationSchema = yup.object({
  type: yup.string().oneOf(Object.values(DeviceType)),
  workEmail: yup.string().when('inUseBy', {
    is: 'email',
    then: (schema) => schema.required('Work email is required if in use type is email'),
  }),
  site: yup.string().when('inUseBy', {
    is: 'site',
    then: (schema) => schema.required('Site is required if in use type is site'),
  }),
});
