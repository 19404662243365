import React from 'react';

import Box from '@mui/material/Box';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
interface SideAccentedTextLabelProps {
  text: string;
  borderColor?: string;
}

export const SideAccentedTextLabel: React.FC<SideAccentedTextLabelProps> = ({ text, borderColor = '#FFC107' }) => {
  return (
    <Box
      sx={{
        ...themeFonts.caption,
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderLeft: `5px solid ${borderColor}`,
        color: themeColors.black,
      }}
    >
      {text}
    </Box>
  );
};
