function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveMargin({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      margin: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      margin: pxToRem(md),
    },
    '@media (min-width:900px)': {
      margin: pxToRem(lg),
    },
  };
}

function responsiveMarginLeft({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      marginLeft: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      marginLeft: pxToRem(md),
    },
    '@media (min-width:900px)': {
      marginLeft: pxToRem(lg),
    },
  };
}

function responsiveMarginRight({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      marginRight: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      marginRight: pxToRem(md),
    },
    '@media (min-width:900px)': {
      marginRight: pxToRem(lg),
    },
  };
}

function responsiveMarginTop({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      marginTop: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      marginTop: pxToRem(md),
    },
    '@media (min-width:900px)': {
      marginTop: pxToRem(lg),
    },
  };
}

function responsiveMarginBottom({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      marginBottom: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      marginBottom: pxToRem(md),
    },
    '@media (min-width:900px)': {
      marginBottom: pxToRem(lg),
    },
  };
}

function responsiveMarginX({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      marginRight: pxToRem(sm),
      marginLeft: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      marginRight: pxToRem(md),
      marginLeft: pxToRem(md),
    },
    '@media (min-width:900px)': {
      marginRight: pxToRem(lg),
      marginLeft: pxToRem(lg),
    },
  };
}

function responsiveMarginY({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      marginTop: pxToRem(sm),
      marginBottom: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      marginTop: pxToRem(md),
      marginBottom: pxToRem(md),
    },
    '@media (min-width:900px)': {
      marginTop: pxToRem(lg),
      marginBottom: pxToRem(lg),
    },
  };
}

function responsivePadding({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      padding: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      padding: pxToRem(md),
    },
    '@media (min-width:900px)': {
      padding: pxToRem(lg),
    },
  };
}

function responsivePaddingLeft({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      paddingLeft: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      paddingLeft: pxToRem(md),
    },
    '@media (min-width:900px)': {
      paddingLeft: pxToRem(lg),
    },
  };
}

function responsivePaddingRight({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      paddingRight: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      paddingRight: pxToRem(md),
    },
    '@media (min-width:900px)': {
      paddingRight: pxToRem(lg),
    },
  };
}

function responsivePaddingTop({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      paddingTop: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      paddingTop: pxToRem(md),
    },
    '@media (min-width:900px)': {
      paddingTop: pxToRem(lg),
    },
  };
}

function responsivePaddingBottom({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      paddingBottom: `${sm}px`,
    },
    '@media (max-width:900px)': {
      paddingBottom: `${md}px`,
    },
    '@media (min-width:900px)': {
      paddingBottom: `${lg}px`,
    },
  };
}

function responsivePaddingX({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      paddingRight: pxToRem(sm),
      paddingLeft: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      paddingRight: pxToRem(md),
      paddingLeft: pxToRem(md),
    },
    '@media (min-width:900px)': {
      paddingRight: pxToRem(lg),
      paddingLeft: pxToRem(lg),
    },
  };
}

function responsivePaddingY({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      paddingTop: pxToRem(sm),
      paddingBottom: pxToRem(sm),
    },
    '@media (min-width:600px) and (max-width:900px)': {
      paddingTop: pxToRem(md),
      paddingBottom: pxToRem(md),
    },
    '@media (min-width:900px)': {
      paddingTop: pxToRem(lg),
      paddingBottom: pxToRem(lg),
    },
  };
}

function responsiveGap({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (max-width:600px)': {
      gap: `${sm}px`,
    },
    '@media (min-width:600px) and (max-width:900px)': {
      gap: `${md}px`,
    },
    '@media (min-width:900px)': {
      gap: `${lg}px`,
    },
  };
}

const SPACING_UNIT = 8;

export const spacing = {
  // UNIT BASED
  xs: `${Math.round(SPACING_UNIT / 4)}px`, // 2px
  sm: `${Math.round(SPACING_UNIT / 2)}px`, // 4px
  s1: `${SPACING_UNIT}px`, // 8px
  s2: `${SPACING_UNIT * 2}px`, // 16px
  s3: `${SPACING_UNIT * 3}px`, // 24px
  s4: `${SPACING_UNIT * 4}px`, // 32px
  s5: `${SPACING_UNIT * 5}px`, // 40px
  s6: `${SPACING_UNIT * 6}px`, // 48px
  s7: `${SPACING_UNIT * 7}px`, // 56px
  s8: `${SPACING_UNIT * 8}px`, // 64px
  s9: `${SPACING_UNIT * 9}px`, // 72px
  s10: `${SPACING_UNIT * 10}px`, // 80px

  // MARGIN
  m1: '1px',
  m2: '2px',
  m3: '3px',
  m4: '4px',
  m5: '5px',
  m8: '8px',
  m10: '10px',
  m12: '12px',
  m15: '15px',
  m16: '16px',
  m20: '20px',
  m24: '24px',
  m25: '25px',
  m30: '30px',
  m35: '35px',
  m40: '40px',
  m45: '45px',
  m48: '48px',
  m50: '50px',
  m55: '55px',
  m60: '60px',
  m75: '75px',
  m80: '80px',
  m100: '100px',
  // RESPONSIVE MARGIN
  ml5: responsiveMarginLeft({ sm: 5, md: 5, lg: 5 }),
  ml10: responsiveMarginLeft({ sm: 5, md: 10, lg: 10 }),
  ml20: responsiveMarginLeft({ sm: 10, md: 15, lg: 20 }),
  ml25: responsiveMarginLeft({ sm: 20, md: 20, lg: 25 }),
  ml30: responsiveMarginLeft({ sm: 15, md: 25, lg: 30 }),
  ml40: responsiveMarginLeft({ sm: 20, md: 20, lg: 40 }),
  ml50: responsiveMarginLeft({ sm: 25, md: 25, lg: 50 }),
  mr10: responsiveMarginRight({ sm: 5, md: 10, lg: 10 }),
  mr20: responsiveMarginRight({ sm: 10, md: 15, lg: 20 }),
  mr40: responsiveMarginRight({ sm: 20, md: 20, lg: 40 }),
  mr50: responsiveMarginRight({ sm: 25, md: 25, lg: 50 }),
  mt10: responsiveMarginTop({ sm: 5, md: 10, lg: 10 }),
  mt15: responsiveMarginTop({ sm: 5, md: 15, lg: 15 }),
  mt20: responsiveMarginTop({ sm: 10, md: 15, lg: 20 }),
  mt30: responsiveMarginTop({ sm: 15, md: 25, lg: 30 }),
  mt40: responsiveMarginTop({ sm: 20, md: 20, lg: 40 }),
  mb5: responsiveMarginBottom({ sm: 5, md: 5, lg: 5 }),
  mb10: responsiveMarginBottom({ sm: 5, md: 10, lg: 10 }),
  mb15: responsiveMarginBottom({ sm: 5, md: 15, lg: 15 }),
  mb20: responsiveMarginBottom({ sm: 10, md: 15, lg: 20 }),
  mb30: responsiveMarginBottom({ sm: 15, md: 25, lg: 30 }),
  mb40: responsiveMarginBottom({ sm: 20, md: 20, lg: 40 }),
  my20: responsiveMarginY({ sm: 10, md: 15, lg: 20 }),
  my40: responsiveMarginY({ sm: 20, md: 20, lg: 40 }),
  mx20: responsiveMarginX({ sm: 10, md: 15, lg: 20 }),
  mx16: responsiveMarginX({ sm: 16, md: 16, lg: 16 }),
  mx40: responsiveMarginX({ sm: 20, md: 20, lg: 40 }),
  mx50: responsiveMarginX({ sm: 25, md: 25, lg: 50 }),
  rm10: responsiveMargin({ sm: 5, md: 10, lg: 10 }),
  rm20: responsiveMargin({ sm: 10, md: 15, lg: 20 }),
  rm30: responsiveMargin({ sm: 15, md: 25, lg: 30 }),
  rm40: responsiveMargin({ sm: 20, md: 20, lg: 40 }),
  // GAP
  g1: '1px',
  g2: '2px',
  g3: '3px',
  g4: '4px',
  g5: '5px',
  g8: '8px',
  g10: '10px',
  g12: '12px',
  g15: '15px',
  g16: '16px',
  g20: '20px',
  g25: '25px',
  g24: '24px',
  g30: '30px',
  g32: '32px',
  g35: '35px',
  g40: '40px',
  g48: '48px',
  g50: '50px',
  g60: '60px',
  g80: '80px',
  // RESPONSIVE GAP
  gap5: responsiveGap({ sm: 5, md: 5, lg: 5 }),
  gap10: responsiveGap({ sm: 5, md: 10, lg: 10 }),
  gap15: responsiveGap({ sm: 5, md: 10, lg: 15 }),
  gap20: responsiveGap({ sm: 10, md: 15, lg: 20 }),
  gap30: responsiveGap({ sm: 15, md: 25, lg: 30 }),
  gap40: responsiveGap({ sm: 10, md: 20, lg: 40 }),
  gap60: responsiveGap({ sm: 15, md: 30, lg: 60 }),
  gap80: responsiveGap({ sm: 20, md: 40, lg: 80 }),
  // PADDING
  p1: '1px',
  p2: '2px',
  p3: '3px',
  p4: '4px',
  p5: '5px',
  p6: '6px',
  p7: '7px',
  p8: '8px',
  p10: '10px',
  p12: '12px',
  p15: '15px',
  p16: '16px',
  p20: '20px',
  p24: '24px',
  p25: '25px',
  p30: '30px',
  p35: '35px',
  p40: '40px',
  p60: '60px',
  p80: '80px',
  // RESPONSIVE PADDING
  pad20: responsivePadding({ sm: 10, md: 15, lg: 20 }),
  pad40: responsivePadding({ sm: 20, md: 20, lg: 40 }),
  pl20: responsivePaddingLeft({ sm: 10, md: 15, lg: 20 }),
  pl40: responsivePaddingLeft({ sm: 20, md: 20, lg: 40 }),
  pr20: responsivePaddingRight({ sm: 10, md: 15, lg: 20 }),
  pr40: responsivePaddingRight({ sm: 20, md: 20, lg: 40 }),
  pt20: responsivePaddingTop({ sm: 10, md: 15, lg: 20 }),
  pt40: responsivePaddingTop({ sm: 20, md: 20, lg: 40 }),
  pb20: responsivePaddingBottom({ sm: 10, md: 15, lg: 20 }),
  pb40: responsivePaddingBottom({ sm: 20, md: 20, lg: 40 }),
  px16: responsivePaddingX({ sm: 16, md: 16, lg: 16 }),
  px20: responsivePaddingX({ sm: 10, md: 15, lg: 20 }),
  px30: responsivePaddingX({ sm: 15, md: 20, lg: 30 }),
  px40: responsivePaddingX({ sm: 20, md: 20, lg: 40 }),
  py20: responsivePaddingY({ sm: 10, md: 15, lg: 20 }),
  py40: responsivePaddingY({ sm: 20, md: 20, lg: 40 }),
  px50: responsivePaddingX({ sm: 25, md: 25, lg: 50 }),
};
