import React, { ChangeEvent, CSSProperties, useCallback } from 'react';

import { styled } from '@mui/material';
import { DEBOUNCE_TIME_MS } from '@v2/feature/documents/documents.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useDebouncedCallback } from 'use-debounce';

import Search from '@/images/side-bar-icons/Search.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

const StyleSearch = styled('input')(() => ({
  width: '60px',
  height: '26px',
  backgroundColor: themeColors.Background,
  borderRadius: radius.br15,
  backgroundImage: ` url(${Search})`,
  backgroundPosition: '10px center',
  backgroundSize: '15px 15px',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexShrink: 0,
  paddingLeft: 28,
  paddingRight: 0,
  margin: '1px', // needed for the focus:outline to render correctly
  alignItems: 'center',
  border: 'none',
  ...themeFonts.caption,
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    border: 'none',
    outline: 'none',
  },
  '&:active': {
    border: 'none',
    outline: 'none',
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    color: themeColors.Grey,
    fill: themeColors.Grey,
    boxShadow: 'none',
    '&::placeholder': {
      color: themeColors.Grey,
    },
  },
  '&:focus': {
    backgroundColor: themeColors.white,
    width: '260px',
    outline: `1px solid ${themeColors.Grey} !important`,
    border: 'none',
    transition: 'width 0.8s, transform 2s',
    '&::placeholder': {
      opacity: 0,
    },
  },
  '&:not(:focus)': {
    transition: 'width 0.4s',
  },
  '&::placeholder': {
    ...themeFonts.caption,
    border: 'none',
  },
}));

interface props {
  readonly style?: CSSProperties;
  readonly query?: string;
  readonly placeholder?: string;
  readonly handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  readonly disabled?: boolean;
  readonly handleBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  readonly debounceSearchWait?: number;
}

export const TableSearch = ({
  disabled,
  query,
  handleChange,
  placeholder,
  style,
  handleBlur,
  debounceSearchWait = DEBOUNCE_TIME_MS,
}: props) => {
  const { polyglot } = usePolyglot();

  // flag to help keep focus in the search box when the content is deleted
  const hasFocus = React.useRef(false);
  const inputRef = React.createRef<HTMLInputElement>();

  const clearFocusIfEmpty = useCallback(() => {
    if (inputRef.current?.value === '') {
      inputRef.current.blur();
    }
  }, [inputRef]);

  useEscapeKey(clearFocusIfEmpty);

  const debouncedSearch = useDebouncedCallback((value) => {
    handleChange(!value ? '' : value);
  }, debounceSearchWait);

  return (
    <StyleSearch
      ref={inputRef}
      autoFocus={!!query?.length || hasFocus.current}
      spellCheck={false}
      defaultValue={query}
      onChange={debouncedSearch.callback}
      placeholder={
        placeholder || (polyglot.has('TableSearch.placeholder') ? polyglot.t('TableSearch.placeholder') : 'Search')
      }
      style={{
        width: query?.length ? '260px' : style?.width,
        ...style,
      }}
      onFocus={() => (hasFocus.current = true)}
      onBlur={(e) => {
        hasFocus.current = false;
        if (handleBlur) handleBlur(e);
      }}
      disabled={disabled}
    />
  );
};
