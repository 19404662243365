import { useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { parentCardSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { CompanySubscriptionsTable } from '@/v2/feature/billing/components/v2-company-subscriptions-table.component';
import { V2UpdateCompanySubscriptionDrawer } from '@/v2/feature/billing/components/v2-update-company-subscription-drawer.component';
import {
  CompanySubscriptionDetails,
  SubscriptionType,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SubscriptionSectionProps {
  subscriptionDetailsForCompany: CompanySubscriptionDetails | null;
  refresh: () => Promise<void>;
}

export const CompanySubscriptionSection = ({ subscriptionDetailsForCompany, refresh }: SubscriptionSectionProps) => {
  const { polyglot } = usePolyglot();
  const isInTrialMode = subscriptionDetailsForCompany?.companySubscription?.type === SubscriptionType.TRIAL;
  const trialDateEnding =
    isInTrialMode && subscriptionDetailsForCompany?.companySubscription?.endDate
      ? new Date(subscriptionDetailsForCompany?.companySubscription?.endDate)
      : null;
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState<boolean>(false);

  return (
    <Box sx={parentCardSx}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {polyglot.t('SubscriptionSection.subscription')}
        </Typography>

        {!isUpdateDrawerOpen && (
          <IconButton title="Update subscription" sx={tableIconButtonSx} onClick={() => setIsUpdateDrawerOpen(true)}>
            <EditIcon {...actionIconSize} />
          </IconButton>
        )}
      </Box>

      <Typography sx={{ my: spacing.m10, ...themeFonts.caption, color: themeColors.DarkGrey }}>
        {polyglot.t('SubscriptionSection.description')}
      </Typography>

      {isInTrialMode && trialDateEnding ? (
        <Typography sx={{ my: spacing.m10, ...themeFonts.title4, color: themeColors.DarkGrey }}>
          {polyglot.t('SubscriptionSection.trialMessage')}
        </Typography>
      ) : (
        <CompanySubscriptionsTable subscriptionDetailsForCompany={subscriptionDetailsForCompany} />
      )}

      {isUpdateDrawerOpen && subscriptionDetailsForCompany && (
        <V2UpdateCompanySubscriptionDrawer
          isOpen={isUpdateDrawerOpen}
          setIsDrawerOpen={setIsUpdateDrawerOpen}
          companySubscriptionDetails={subscriptionDetailsForCompany}
          refresh={refresh}
        />
      )}
    </Box>
  );
};
