import React, { useState } from 'react';

import { Typography } from '@v2/components/typography/typography.component';
import { DeleteHolidayCalendarDrawer } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/delete-holiday-calendar-drawer.component';
import { HolidayCalendarGeneralDrawer } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-calendar-general-drawer.component';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

interface SectionProps {
  readonly holidayCalendar: HolidayCalendarDto;
  readonly refreshAll: () => Promise<void>;
}

export const HolidayCalendarGeneralSection = ({ holidayCalendar, refreshAll }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <SettingsSectionContent title={polyglot.t('General.general')} onEdit={() => setIsOpen(true)}>
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('General.name'),
                value: holidayCalendar.name,
              },
            ],
          },
          {
            title: polyglot.t('HolidayCalendarModule.deleteCalendar'),
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption">
                    {polyglot.t('HolidayCalendarModule.deleteCalendarDesc')}{' '}
                    <span
                      style={{ color: themeColors.Red, textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        setIsDeleteOpen(true);
                      }}
                    >
                      {polyglot.t('General.here')}
                    </span>
                    .
                  </Typography>
                ),
              },
            ],
          },
        ]}
      />

      <DeleteHolidayCalendarDrawer
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        holidayCalendar={holidayCalendar}
        refresh={refreshAll}
      />

      <HolidayCalendarGeneralDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        holidayCalendar={holidayCalendar}
        refresh={refreshAll}
      />
    </SettingsSectionContent>
  );
};
