import { Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BasketBig } from '@/images/app-intro/BasketBig.svg';
import { ReactComponent as BenefitsBig } from '@/images/app-intro/BenefitsBig.svg';
import { ReactComponent as Centre } from '@/images/app-intro/Centre.svg';
import { ReactComponent as NotiBig } from '@/images/app-intro/NotiBig.svg';
import { ReactComponent as OnboardingBig } from '@/images/app-intro/OnboardingBig.svg';
import { APP_STORE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  IconContentActionCardProps,
  IntroCardGroup,
} from '@/v2/components/theme-components/icon-content-action-card.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const APPS_EMPTY_STATE_FEATURE_LIST: IconContentActionCardProps[] = [
  {
    iconMedia: <Centre width={40} height={40} />,
    title: 'Central access control',
    content:
      'Create and remove accounts and modify permissions in real-time and or schedule future changes with one click.',
  },
  {
    iconMedia: <BasketBig width={40} height={40} />,
    title: 'Remove unused accounts',
    content:
      'Identify accounts that do not belong to an employee or show no recent activity. Integrate via API and delete accounts in apps programmatically.',
  },
  {
    iconMedia: <OnboardingBig width={40} height={40} />,
    title: 'On and offboard',
    content: 'Select when and which apps should be ready for your new joiner. ',
  },
  {
    iconMedia: <BenefitsBig width={40} height={40} />,
    title: 'Data-driven company data',
    content: 'Extract KPIs from your workplace tools to understand the usage better. ',
  },
  {
    iconMedia: <NotiBig width={40} height={40} />,
    title: 'Self-service access',
    content:
      'Your team can request access to any connected app. App owners just need to approve and Zelt creates the account.',
  },
];

export const AppIntroduction = () => {
  const routerHistory = useHistory();
  return (
    <>
      <Stack sx={{ maxWidth: '600px' }}>
        <Typography sx={{ ...themeFonts.caption }}>
          Get more value out of your favourite workplace tools by connecting them to Zelt. Manage access centrally,
          streamline access requests and understand how your team is using your apps.
        </Typography>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          style={{ marginTop: spacing.m30 }}
          onClick={() => routerHistory.push(APP_STORE_ROUTE)}
        >
          Connect
        </ButtonComponent>
      </Stack>
      <IntroCardGroup cards={APPS_EMPTY_STATE_FEATURE_LIST} sx={{ mt: 'auto' }} />
    </>
  );
};
