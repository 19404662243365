import React from 'react';

import { Box } from '@mui/material';

import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { popularCurrencyShortOptions } from '@/v2/infrastructure/currency/currency.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { getSelectProps } from '@/v2/styles/menu.styles';

interface CurrencyTextFieldProps {
  name: string;
  label: string;
  value: number | string | null;
  onChange: (value: number | string | null) => void;
  selectedCurrency: string;
  onCurrencyChange: (value: string) => void;
  error?: boolean;
  helperText?: string;
  disableCurrencySelector?: boolean;
}

export const CurrencyTextField: React.FC<CurrencyTextFieldProps> = ({
  name,
  label,
  value,
  onChange,
  selectedCurrency,
  onCurrencyChange,
  error,
  helperText,
  disableCurrencySelector = false,
  ...props
}) => {
  const handleCurrencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onCurrencyChange(event.target.value as string);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'flex-end',
          width: '100%',
          boxSizing: 'border-box',
          p: 0,
          m: 0,
        }}
      >
        <Box sx={{ flex: '1 1 auto', boxSizing: 'border-box', p: 0, m: 0 }}>
          <TextfieldComponent
            {...props}
            fullWidth
            value={value === 0 ? '' : value}
            variant="standard"
            type="number"
            onChange={handleAmountChange}
            label={label}
            name={name}
            error={error}
            endAdornment="none"
            sx={{
              '& div.MuiInputBase-root.MuiInput-root': {
                borderBottomRightRadius: '0px !important',
                borderTopRightRadius: '0px !important',
              },
            }}
          />
        </Box>
        <Box sx={{ flex: '0 0 75px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            disabled={disableCurrencySelector}
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            sx={{
              borderLeft: `1px solid ${themeColors.GreyMiddle}`,
              boxSizing: 'border-box',
              '& div.MuiInputBase-root.MuiInput-root': {
                borderBottomLeftRadius: '0px !important',
                borderTopLeftRadius: '0px !important',
              },
            }}
            variant="standard"
            error={error}
            size="small"
            SelectProps={{ ...getSelectProps }}
            options={popularCurrencyShortOptions}
            name="currency"
          />
        </Box>
      </Box>
      {error && helperText && (
        <Typography variant="captionSmall" color="RedDark">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
