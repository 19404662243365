import Polyglot from 'node-polyglot';

export type PresetApprover =
  | `manager:${number}` // the nth-level manager of the requester (manager:1 = direct manager, manager:2 = manager's manager, etc)
  | 'admin' // any admin
  | 'everyone';

export type ApproverGroupType = 'anyof'; // any of the listed approvers can approve the request
// | 'allof'; // all of the listed approvers must approve the request

export type ApproverGroup = {
  type: ApproverGroupType;
  autoApprove: boolean;
  presets: PresetApprover[]; // manager, admin, etc
  userIds: number[]; // specific user ids
};

export function presetApproverToString(preset: PresetApprover, polyglot: Polyglot) {
  if (preset === 'admin') return polyglot.t('ApproverSelectComponent.Admin');
  if (preset === 'manager:1') return polyglot.t('ApproverSelectComponent.Manager');
  if (preset === 'manager:2') return polyglot.t('ApproverSelectComponent.ManagersManager');
  if (preset === 'everyone') return polyglot.t('ApproverSelectComponent.Everyone');
  return preset;
}
