import React, { FC } from 'react';

import { BooleanSelector } from '@v2/feature/reports/reports-advanced/filters-components/boolean-selector.component';
import { DateonlySelector } from '@v2/feature/reports/reports-advanced/filters-components/dateonly-selector.component';
import { IntOptionSelector } from '@v2/feature/reports/reports-advanced/filters-components/int-option-selector.component';
import { NotImplemented } from '@v2/feature/reports/reports-advanced/filters-components/not-implemented.component';
import { NumberSelector } from '@v2/feature/reports/reports-advanced/filters-components/number-selector.component';
import { StringOptionSelector } from '@v2/feature/reports/reports-advanced/filters-components/string-option-selector.component';
import { StringSelector } from '@v2/feature/reports/reports-advanced/filters-components/string-selector.component';
import { FilterTypeOption, ReportSQLOperator } from '@v2/feature/reports/reports.interface';

export type FilterValueSelectorProps = {
  readonly label: string;
  readonly filterType: FilterTypeOption;
  readonly operator: ReportSQLOperator;
  readonly filterOptions?: readonly { value: string | number; label: string }[] | undefined;
  readonly value: string | number | string[] | number[];
  readonly setValue: React.Dispatch<React.SetStateAction<string | number | string[] | number[]>>;
};

export const FilterValueSelector = ({ filterType, ...props }: FilterValueSelectorProps) => {
  const SelectedComponent = SelectorByFilterType[filterType] ? SelectorByFilterType[filterType]() : NotImplemented;

  return <SelectedComponent {...props} />;
};

const SelectorByFilterType: Record<FilterTypeOption, () => FC<Omit<FilterValueSelectorProps, 'filterType'>>> = {
  [FilterTypeOption.number]: () => NumberSelector,
  [FilterTypeOption.string]: () => StringSelector,
  [FilterTypeOption.stringOption]: () => StringOptionSelector,
  [FilterTypeOption.intOption]: () => IntOptionSelector,
  [FilterTypeOption.dateonly]: () => DateonlySelector,
  [FilterTypeOption.boolean]: () => BooleanSelector,

  [FilterTypeOption.datetime]: () => NotImplemented,
  [FilterTypeOption.time]: () => NotImplemented,
};
