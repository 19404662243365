import { useCallback, useState } from 'react';

import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { PolicyApprovalEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-approval-edit-drawer.component';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface AbsencePolicyGeneralSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const AbsencePolicyApprovalSection = ({ absencePolicy, refresh }: AbsencePolicyGeneralSectionProps) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), {
    suspense: false,
  });

  const refreshApprovalRules = useCallback(async () => {
    if (refreshRules) await refreshRules();
  }, [refreshRules]);

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyApprovalSection.approval')}
      onEdit={() => setIsEditDrawerOpen(true)}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyApprovalSection.approval'),
                value: absencePolicy?.backoffApprovalRule?.name ?? 'N/A',
                hidden: !absencePolicy.backoffApprovalRule,
              },
              {
                type: SectionItemType.TextLine,
                value: polyglot.t('AbsencePolicyApprovalSection.approvalDesc'),
                hidden:
                  absencePolicy?.backoffApprovalRule?.name === 'Auto approve' ||
                  absencePolicy.updateApprovalNotRequired,
              },
            ],
          },
        ]}
      />

      <PolicyApprovalEditDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
        approvalRules={approvalRules ?? []}
        refreshApprovalRules={refreshApprovalRules}
      />
    </SettingsSectionContent>
  );
};
