import { Box, Link } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { underlinedLinkLight } from '@/v2/styles/buttons.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const NotionInstallInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        a) Go to settings & members.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        b) Go to Identity & provisioning.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        c) Generate a SCIM token in SCIM provisioning and copy the API key.
      </Typography>
    </Box>
  );
};

export const NotionInfoCard = () => {
  return (
    <Typography variant="caption">
      <Link sx={underlinedLinkLight} href="https://www.notion.so/pricing">
        Enterprise plan
      </Link>{' '}
      required to use Notion API.{' '}
      <Link sx={underlinedLinkLight} href="https://www.notion.so/startups">
        Notion for startups
      </Link>{' '}
      offers $1000 free credit.
    </Typography>
  );
};
