import { useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { getDefaultBenefitImageByCategory } from '@v2/feature/benefits/benefits.util';
import { BenefitOverviewItem } from '@v2/feature/benefits/components/benefit-overview-item.component';
import { NewBenefitDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { InsuranceType } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getLogoByProviderName } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateCustomBenefitCategory } from '@v2/infrastructure/i18n/translate.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { sortBy } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';

import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as InsuranceBig } from '@/images/benefits/Insurance.svg';
import {
  DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE,
  INSURANCE_COMPANY_OVERVIEW_ROUTE,
  PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE,
} from '@/lib/routes';
import {
  CustomBenefitCategory,
  CustomBenefitDto,
} from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';

interface BenefitsCompanyOverviewRouterProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
  readonly refreshAllBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export const BenefitsCompanyOverviewSection = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshAllBenefits,
  loading,
}: BenefitsCompanyOverviewRouterProps) => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasInsuranceAllScope = hasScopes(['insurance:all']);

  return (
    <RootStyle>
      <ContentWrapper loading={loading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, width: '600px' }}>
          {sortBy(pensionSchemes, ['displayName']).map((pensionScheme, index) => (
            <BenefitOverviewItem
              key={`pension-${index}`}
              title={pensionScheme.displayName}
              descriptionItems={[
                PensionProvidersValueToLabel[pensionScheme.providerName] ?? pensionScheme.providerName,
                polyglot.t('PensionModule.noOfWorkerGroups', { smart_count: pensionScheme.workerGroups.length }),
              ]}
              icon={getPensionLogoByProviderName(pensionScheme.providerName, 40)}
              onClick={() =>
                routerHistory.push(
                  generatePath(PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE, { pensionSchemeId: pensionScheme.id })
                )
              }
            />
          ))}

          {hasInsuranceAllScope && insuranceQuote?.policy ? (
            <BenefitOverviewItem
              key="insurance"
              title={insuranceQuote.policy.providerName}
              descriptionItems={[
                insuranceQuote.policy.type === InsuranceType.Health
                  ? translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot)
                  : insuranceQuote.policy.type,
                polyglot.t('BenefitModule.noOfMembers', { smart_count: insuranceQuote.noOfPolicyMembers ?? 0 }),
              ]}
              icon={getLogoByProviderName(insuranceQuote.policy.providerName, 40)}
              onClick={() => routerHistory.push(INSURANCE_COMPANY_OVERVIEW_ROUTE)}
            />
          ) : insuranceQuote && hasInsuranceAllScope ? (
            <BenefitOverviewItem
              key="insurance"
              title={polyglot.t('BenefitModule.quoteRequested')}
              descriptionItems={[polyglot.t('BenefitModule.quotePending')]}
              icon={<InsuranceBig style={{ width: '40px', height: '40px', borderRadius: '50%' }} />}
              onClick={() => routerHistory.push(INSURANCE_COMPANY_OVERVIEW_ROUTE)}
            />
          ) : null}

          {sortBy(customBenefits, ['name'])?.map((benefit, index) => (
            <BenefitOverviewItem
              key={`benefit-${index}`}
              title={benefit.name}
              descriptionItems={[
                benefit.type,
                `${benefit.includedUserIds.length} employee${benefit.includedUserIds.length === 1 ? '' : 's'}`,
              ]}
              icon={
                benefit?.url ? (
                  <img src={benefit.url} width={40} height={40} alt="benefit-logo" />
                ) : (
                  getDefaultBenefitImageByCategory(benefit.category, '50px', 'auto')
                )
              }
              onClick={() =>
                routerHistory.push(generatePath(DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE, { benefitId: benefit.id }))
              }
            />
          ))}

          {pensionSchemes.length === 0 && !insuranceQuote && (customBenefits ?? []).length === 0 && (
            <Box>
              <Typography variant="caption">No benefit has been set up yet.</Typography>

              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {polyglot.t('BenefitModule.newBenefit')}
              </ButtonComponent>

              <NewBenefitDrawer isOpen={isOpen} setIsOpen={setIsOpen} refresh={refreshAllBenefits} />
            </Box>
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
