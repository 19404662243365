import { initializeApp } from 'firebase/app';
import {
  SAMLAuthProvider,
  getAuth,
  signInWithPopup,
  UserCredential,
  OAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  AuthProvider,
} from 'firebase/auth';

import { SSOCheck } from '@/v2/feature/auth/features/auth-login/auth-login.page';

export const isProduction = process.env.REACT_APP_ENV === 'production';

//TODO - export this configuration to secrets
const rubikFirebaseConfig = {
  apiKey: 'AIzaSyDV3xN31jsrcRfnTNLEImzsH885o1M0GaY',
  authDomain: 'noble-descent-379309.firebaseapp.com',
  projectId: 'noble-descent-379309',
  storageBucket: 'noble-descent-379309.appspot.com',
  messagingSenderId: '958432800127',
  appId: '1:958432800127:web:45eb0ee18330e162773e4a',
};

const productionFirebaseConfig = {
  apiKey: 'AIzaSyDAEwqkbXJFq7mJq-_3wll0kCn5yd8JFY0',
  authDomain: 'zelt-production.firebaseapp.com',
  projectId: 'zelt-production',
  storageBucket: 'zelt-production.appspot.com',
  messagingSenderId: '387778103327',
  appId: '1:387778103327:web:7cb0474de3104277fb2d2a',
  measurementId: 'G-YNNDE0QC8V',
};

const app = initializeApp(isProduction ? productionFirebaseConfig : rubikFirebaseConfig);
const auth = getAuth(app);

export enum SignInFlow {
  Popup,
  Redirect,
}

const signInWithAuthProvider = async (
  authProvider: AuthProvider,
  flow: SignInFlow
): Promise<UserCredential | undefined> => {
  try {
    switch (flow) {
      case SignInFlow.Popup:
        return signInWithPopup(auth, authProvider);
      case SignInFlow.Redirect:
        let result = await getRedirectResult(auth);
        if (result) {
          return result;
        }
        await signInWithRedirect(auth, authProvider);
        return undefined;
      default:
        return undefined;
    }
  } catch (error) {
    console.error('Encountered an error during the signInWithAuthProvider process: ', error);
  }
};

export const signinWithGoogle = async (
  userEmail: string,
  ssoCheckResult: SSOCheck,
  flow: SignInFlow = SignInFlow.Popup
): Promise<UserCredential | undefined> => {
  if (ssoCheckResult?.app && ssoCheckResult?.companyId && ssoCheckResult?.enabled) {
    const companySAMLProvider = new SAMLAuthProvider(`saml.company-${ssoCheckResult.companyId}.${ssoCheckResult.app}`);
    companySAMLProvider.setCustomParameters({
      prompt: 'select_account',
      login_hint: userEmail,
    });
    return signInWithAuthProvider(companySAMLProvider, flow);
  }
  throw new Error(`Unable to find matching SSO provider: ${JSON.stringify(ssoCheckResult)}`);
};

export const signinWithAzureAD = async (
  userEmail: string,
  ssoCheckResult: SSOCheck,
  flow: SignInFlow = SignInFlow.Popup
): Promise<UserCredential | undefined> => {
  if (ssoCheckResult?.app && ssoCheckResult?.companyId && ssoCheckResult?.enabled) {
    const companySAMLProvider = new SAMLAuthProvider(`saml.company-${ssoCheckResult.companyId}.${ssoCheckResult.app}`);
    companySAMLProvider.setCustomParameters({
      prompt: 'select_account',
      login_hint: userEmail,
    });
    return signInWithAuthProvider(companySAMLProvider, flow);
  }
  throw new Error(`Unable to find matching SSO provider: ${JSON.stringify(ssoCheckResult)}`);
};

export const signinWithOkta = async (
  userEmail: string,
  ssoCheckResult: SSOCheck,
  flow: SignInFlow = SignInFlow.Popup
): Promise<UserCredential | undefined> => {
  if (ssoCheckResult?.app && ssoCheckResult?.companyId && ssoCheckResult?.enabled) {
    const companySAMLProvider = new OAuthProvider(`oidc.company-${ssoCheckResult.companyId}.${ssoCheckResult.app}`);
    companySAMLProvider.setCustomParameters({
      prompt: 'select_account',
      login_hint: userEmail,
    });
    return signInWithAuthProvider(companySAMLProvider, flow);
  }
  throw new Error(`Unable to find matching SSO provider: ${JSON.stringify(ssoCheckResult)}`);
};
