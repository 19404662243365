import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';
import { spacing } from '@v2/styles/spacing.styles';

export const EmptyStateBox = ({
  title,
  subtitle,
  buttonTitle,
  onClick,
}: {
  title: string;
  subtitle: string;
  buttonTitle?: string;
  onClick?: () => void;
}) => {
  return (
    <Box
      style={{
        backgroundColor: themeColors.Background,
        padding: '16px',
        borderRadius: radius.br8,
        maxWidth: '600px',
        minHeight: '50%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g16,
        }}
      >
        <Typography variant="title3">{title}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      {onClick && (
        <Box sx={{ mt: 'auto' }}>
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={onClick}>
            {buttonTitle}
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};
