import React, { useState } from 'react';

import { CardHeader, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { CreateChecklistDto } from '@v2/feature/task/subfeature/checklist/checklist.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

export const AddChecklistTask = (): JSX.Element => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [checklist, setChecklist] = useState<CreateChecklistDto | null>(null);
  const [showMessage] = useMessage();

  const saveChecklist = async (): Promise<void> => {
    if (!checklist) return;

    setLoading(true);
    try {
      const result = await TaskAPI.addChecklist(checklist);

      showMessage(polyglot.t('AddChecklistTask.successMessages.add'), 'success');
      routerHistory.push(generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: result.id }));
    } catch (error) {
      showMessage(`${polyglot.t('AddChecklistTask.errorMessages.create')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('AddChecklistTask.tasks')}
          </Typography>
        }
      />
      <ContentWrapper loading={loading}>
        <Card>
          <CardHeader
            title={polyglot.t('AddChecklistTask.checklistInfo')}
            action={
              <Button
                variant="contained"
                onClick={async () => {
                  await saveChecklist();
                }}
                disabled={!checklist || !checklist.name}
              >
                {polyglot.t('AddChecklistTask.newChecklist')}
              </Button>
            }
          />
          <CardContent>
            <div style={{ width: '40%' }}>
              <TextField
                id="checklist-name"
                label={polyglot.t('AddChecklistTask.checklistName')}
                value={checklist ? checklist.name : ''}
                fullWidth
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setChecklist({ ...checklist, name: event.target.value })
                }
              />
            </div>
          </CardContent>
        </Card>
      </ContentWrapper>
    </RootStyle>
  );
};
