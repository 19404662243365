import { Dispatch, SetStateAction } from 'react';

import { KeyedMutator } from 'swr';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { AppIntegrationUserDto } from '@/v2/feature/app-integration/app-integration.dto';
import {
  AppIntegrationStub,
  APP_ACTION_DRAWER_MODES,
  CountryListing,
  CurrentlyDelayedJobs,
} from '@/v2/feature/app-integration/app-integration.interface';
import { AppActionsDrawerPage } from '@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.page';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';

interface AppActionsDrawerProps {
  readonly appStub: AppIntegrationStub;
  readonly countriesForEmployment?: CountryListing[];
  readonly usersWithoutAccess: readonly AppIntegrationUserDto[];
  readonly usersWithAccess?: readonly AppIntegrationUserDto[];
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly preselectedUser?: AppIntegrationUserDto;
  readonly refreshApp: VoidFunction;
  refreshDelayedActions?: KeyedMutator<CurrentlyDelayedJobs<CreateAppQueue>> | undefined;
  readonly markTeamUserPending?: (userId: number) => void;
  readonly setIsAppActionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedActiveUserLogin?: string;
  readonly setSelectedActiveUserLogin?: Dispatch<SetStateAction<string | undefined>>;
  readonly mode?: APP_ACTION_DRAWER_MODES;
  readonly directoryMode?: 'team' | 'external' | 'employments' | 'candidates';
}

export const AppActionsDrawer = ({
  appStub,
  countriesForEmployment,
  usersWithoutAccess,
  usersWithAccess,
  isOpen,
  onClose,
  preselectedUser,
  refreshApp,
  refreshDelayedActions,
  markTeamUserPending,
  setIsAppActionsDrawerOpen,
  selectedActiveUserLogin,
  setSelectedActiveUserLogin,
  mode,
  directoryMode = 'team',
}: AppActionsDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsAppActionsDrawerOpen}>
    <AppActionsDrawerPage
      appStub={appStub}
      countriesForEmployment={countriesForEmployment}
      usersWithoutAccess={usersWithoutAccess}
      usersWithAccess={usersWithAccess}
      refreshApp={refreshApp}
      refreshDelayedActions={refreshDelayedActions}
      markTeamUserPending={markTeamUserPending}
      selectedActiveUserLogin={selectedActiveUserLogin}
      setSelectedActiveUserLogin={setSelectedActiveUserLogin}
      closePage={() => onClose()}
      mode={mode}
      preselectedUser={preselectedUser}
      directoryMode={directoryMode}
    />
  </DrawerModal>
);
