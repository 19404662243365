import { Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReferAndEarnModal } from '@/v2/feature/app-layout/features/sidebar/components/refer-and-earn-modal.component';
import { NavConfigItem, SubItemsProps } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { MainMenuSection } from '@/v2/feature/app-layout/features/v2/main-menu/main-menu.section';
import { MenuActionType, RoleTypes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import {
  decomposePath,
  findChildByPath,
  findSubItemByStub,
  findSubMenuByPath,
  getSecondMenuItems,
  menuReducer,
} from '@/v2/feature/app-layout/features/v2/menu/menu.util';
import { SecondMenuSection } from '@/v2/feature/app-layout/features/v2/second-menu/second-menu.section';
import { getRolesByDomain, getSidebarConfig } from '@/v2/feature/app-layout/features/v2/sidebar-config.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { showSecondMenuBar } from '@/v2/util/app-layout.util';

const initialState = {
  showSecondMenu: false,
  activeParent: undefined,
  activeSubMenuParent: undefined,
  activeRole: undefined,
};

export const MenuSection = () => {
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;

  const { polyglot, useInitLanguage } = usePolyglot();
  useInitLanguage();

  const [state, dispatch] = useReducer(menuReducer, initialState);
  const { showSecondMenu, activeParent, activeSubMenuParent, activeRole } = state;
  const sideBarConfig = getSidebarConfig(user, activeRole, polyglot, !!globalState.showHiddenFeatures);

  const roles = useMemo(() => (activeParent ? getRolesByDomain(activeParent, user) : ['me']), [activeParent, user]);
  const { hasScopes } = useScopes();
  const scopesContext = { userId: globalState.user.userId };
  const isAdminUser = hasScopes(['company.settings:all'], scopesContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (routerLocation.pathname.includes('refer')) setIsOpen(true);
  }, [routerLocation.pathname]);

  function shouldShowSecondMenu(
    domain: string,
    activeParent: string | undefined,
    routedSecondMenuItems: NavConfigItem | undefined,
    pathame: string
  ) {
    return (
      routedSecondMenuItems &&
      routedSecondMenuItems.hasChildren &&
      (domain === routedSecondMenuItems.stub || activeParent === routedSecondMenuItems.stub) &&
      showSecondMenuBar(pathame)
    );
  }

  const routeToLocationOrDefined = useCallback(
    (pathname: string, path: string) => {
      const updatedPath = pathname.includes(path) ? pathname : path;
      // only push the new route if it has changed so we don't lose any
      // state passed in the router.
      if (routerHistory.location.pathname !== updatedPath) {
        routerHistory.push(updatedPath);
      }
    },
    [routerHistory]
  );

  const handleFindPathChild = useCallback(
    (findPathChild: SubItemsProps) => {
      if (findPathChild.hasChildren && findPathChild.subMenu) {
        const firstChild = findPathChild.subMenu.find((a) => a.path && !a.isHidden);
        if (firstChild?.path) {
          routeToLocationOrDefined(routerLocation.pathname, firstChild.path);
        }
      } else if (findPathChild.path) {
        routeToLocationOrDefined(routerLocation.pathname, findPathChild.path);
      }
    },
    [routeToLocationOrDefined, routerLocation.pathname]
  );

  const handleNoFindPathChild = useCallback(
    (routedSecondMenuItems: NavConfigItem | undefined) => {
      const firstChild = findChildByPath(routedSecondMenuItems?.subItems);
      if (firstChild && firstChild.path && !firstChild.hasChildren) {
        routeToLocationOrDefined(routerLocation.pathname, firstChild.path);
      } else {
        const firstSubMenu = findSubMenuByPath(routedSecondMenuItems?.subItems);
        if (firstSubMenu?.path) {
          routeToLocationOrDefined(routerLocation.pathname, firstSubMenu.path);
        }
      }
    },
    [routeToLocationOrDefined, routerLocation.pathname]
  );

  const handleDomainNotEqualActiveParent = useCallback(
    (routedSecondMenuItems: NavConfigItem | undefined) => {
      if (routedSecondMenuItems && routedSecondMenuItems.path && !routedSecondMenuItems.hasChildren) {
        routeToLocationOrDefined(routerLocation.pathname, routedSecondMenuItems.path);
      } else {
        const decomposed = decomposePath(routerLocation.pathname);
        const { subDomain } = decomposed;
        const findPathChild = findSubItemByStub(routedSecondMenuItems?.subItems, subDomain);
        if (findPathChild && !findPathChild.isHidden) {
          handleFindPathChild(findPathChild);
        } else {
          handleNoFindPathChild(routedSecondMenuItems);
        }
      }
    },
    [routerLocation.pathname, routeToLocationOrDefined, handleFindPathChild, handleNoFindPathChild]
  );

  const navigateToFirstChildOrParent = useCallback(
    (parent: SubItemsProps | undefined, subMenu: string, routedSecondMenuItems: NavConfigItem | undefined) => {
      if (parent && parent.hasChildren && parent.subMenu && !parent.isHidden) {
        const child = findSubItemByStub(parent.subMenu, subMenu) || findChildByPath(parent.subMenu);
        if (child && child.path && !child.isHidden) {
          routeToLocationOrDefined(routerLocation.pathname, child.path);
        } else {
          const firstChild = parent.subMenu.find((a) => a.path && !a.isHidden);
          if (firstChild && firstChild.path) {
            routeToLocationOrDefined(routerLocation.pathname, firstChild.path);
          }
        }
      } else if (parent && parent.path && !parent.isHidden) {
        routeToLocationOrDefined(routerLocation.pathname, parent.path);
      } else {
        const firstParent = findChildByPath(routedSecondMenuItems?.subItems);
        if (firstParent && firstParent.path) {
          routeToLocationOrDefined(routerLocation.pathname, firstParent.path);
        } else {
          const firstSubMenu = findSubMenuByPath(routedSecondMenuItems?.subItems);
          if (firstSubMenu && firstSubMenu.path) routeToLocationOrDefined(routerLocation.pathname, firstSubMenu.path);
        }
      }
    },
    [routerLocation.pathname, routeToLocationOrDefined]
  );

  const handleNavigation = useCallback(
    (domain: string, role: string, subDomain: string, subMenu: string, activeParent: string | undefined) => {
      const routedSecondMenuItems = getSecondMenuItems(role as RoleTypes, domain, user, polyglot);
      const parent = findSubItemByStub(routedSecondMenuItems?.subItems, subDomain);
      if (activeParent) {
        if (domain !== activeParent) {
          handleDomainNotEqualActiveParent(routedSecondMenuItems);
        } else if (role !== activeRole) {
          navigateToFirstChildOrParent(parent, subMenu, routedSecondMenuItems);
        }
      } else {
        if (role !== activeRole) {
          navigateToFirstChildOrParent(parent, subMenu, routedSecondMenuItems);
        }
      }
    },
    [activeRole, handleDomainNotEqualActiveParent, navigateToFirstChildOrParent, user, polyglot]
  );

  const findMenuByPath = (config: NavConfigItem | undefined, subDomain: string, subMenu: string) => {
    if (!config?.subItems) return null;

    for (let item of config.subItems) {
      if (item.stub !== subDomain) continue;

      if (!item.hasChildren) return { parent: item.stub, submenu: null };

      // If the item has children and a submenu, find the submenu item
      if (item.hasChildren && item.subMenu) {
        for (let subItem of item.subMenu) {
          if (subItem.stub === subMenu) {
            return { parent: item.stub, submenu: subItem.stub };
          }
        }
      }
    }

    return null;
  };

  const resetMenuState = useCallback(
    (domain: string, role: string, subDomain: string, subMenu: string, activeParent: string | undefined) => {
      dispatch({
        type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
        payload: false,
      });

      const rolesForDomain = getRolesByDomain(domain, user);
      const defaultRole = rolesForDomain.includes(role) ? role : rolesForDomain[0];

      dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: defaultRole as RoleTypes });

      if (domain) {
        handleNavigation(domain, role, subDomain, subMenu, activeParent);
      }
    },
    [handleNavigation, user]
  );

  useEffect(() => {
    const decomposed = decomposePath(routerLocation.pathname);
    const { domain, role, subDomain, subMenu } = decomposed;
    dispatch({
      type: MenuActionType.SET_ACTIVE_PARENT,
      payload: domain,
    });

    const rolesForDomain = getRolesByDomain(domain, user);
    const defaultRole = rolesForDomain.includes(role) ? role : rolesForDomain[0];
    const routedSecondMenuItems = getSecondMenuItems(defaultRole as RoleTypes, domain, user, polyglot);
    const shouldShow = shouldShowSecondMenu(domain, activeParent, routedSecondMenuItems, routerLocation.pathname);
    if (shouldShow) {
      dispatch({
        type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
        payload: true,
      });
      dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: defaultRole as RoleTypes });
      handleNavigation(domain, defaultRole, subDomain, subMenu, activeParent);
      const activeMenu = findMenuByPath(routedSecondMenuItems, subDomain, subMenu);
      dispatch({ type: MenuActionType.SET_ACTIVE_SUBMENU_PARENT, payload: activeMenu ? activeMenu.parent : undefined });
    } else if (showSecondMenuBar(routerLocation.pathname)) {
      resetMenuState(domain, defaultRole, subDomain, subMenu, activeParent);
    } else {
      dispatch({
        type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
        payload: false,
      });
    }
  }, [
    polyglot,
    routerLocation.pathname,
    activeRole,
    routerHistory,
    activeParent,
    handleNavigation,
    user,
    resetMenuState,
  ]);

  return (
    <Fragment>
      <MainMenuSection
        setShowSecondMenu={(val: boolean) =>
          dispatch({
            type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
            payload: val,
          })
        }
        setActiveParent={(val: string | undefined) =>
          dispatch({
            type: MenuActionType.SET_ACTIVE_PARENT,
            payload: val,
          })
        }
        activeParent={activeParent}
        sideBarConfig={sideBarConfig}
        setActiveRole={(role) => dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: role as RoleTypes })}
        user={user}
      />

      {showSecondMenu && (
        <SecondMenuSection
          activeParent={activeParent}
          setActiveRole={(role) => dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: role as RoleTypes })}
          activeRole={activeRole}
          setActiveSubMenuParent={(sunMenuParent: string | undefined) =>
            dispatch({ type: MenuActionType.SET_ACTIVE_SUBMENU_PARENT, payload: sunMenuParent })
          }
          activeSubMenuParent={activeSubMenuParent}
          roles={roles}
          setShowSecondMenu={(val: boolean) =>
            dispatch({
              type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
              payload: val,
            })
          }
        />
      )}

      <ReferAndEarnModal isAdminUser={isAdminUser} isOpen={isOpen} setIsOpen={setIsOpen} user={user} />
    </Fragment>
  );
};
