import { AttendanceShiftDto } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.dto';
import { getDayPreviousFromDateString } from '@v2/feature/dashboard/features/sections/user-shift/user-shift.util';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import { LocalDate } from '@v2/util/local-date';
import axios from 'axios';

export class AttendanceShiftAPI {
  static async startTodaysShift(
    longitude: number | null,
    latitude: number | null,
    isYesterday: boolean
  ): Promise<void> {
    const today = new LocalDate();

    await axios.post('/apiv2/attendance-shifts/start', {
      logDate: isYesterday ? getDayPreviousFromDateString(today.toDateString()) : today.toDateString(),
      startHour: today.toFullString(),
      startHourTimestamp: today.getDate(),
      longitude,
      latitude,
    });
  }

  static async endTodaysShift(longitude: number | null, latitude: number | null, isYesterday: boolean): Promise<void> {
    const today = new LocalDate();

    await axios.post('/apiv2/attendance-shifts/end', {
      logDate: isYesterday ? getDayPreviousFromDateString(today.toDateString()) : today.toDateString(),
      endHour: today.toFullString(),
      endHourTimestamp: today.getDate(),
      longitude,
      latitude,
    });
  }

  static async startBreak(longitude: number | null, latitude: number | null): Promise<void> {
    const today = new LocalDate();

    await axios.post('/apiv2/attendance-shifts/start-break', {
      logDate: today.toDateString(),
      startHour: today.toFullString(),
      startHourTimestamp: today.getDate(),
      longitude,
      latitude,
    });
  }

  static async endBreak(longitude: number | null, latitude: number | null): Promise<void> {
    const today = new LocalDate();

    await axios.post('/apiv2/attendance-shifts/end-break', {
      logDate: today.toDateString(),
      endHour: today.toFullString(),
      endHourTimestamp: today.getDate(),
      longitude,
      latitude,
    });
  }

  static async getShiftById(id: number, userId: number): Promise<AttendanceShiftDto | null> {
    return (await axios.get(`/apiv2/attendance-shifts/${id}/${userId}/by-user`)).data;
  }

  static async endUnfinishedAttendanceShiftById(
    userId: number,
    logDate: string,
    endHour: string,
    endHourTimestamp: Date,
    longitude: number | null,
    latitude: number | null
  ): Promise<void> {
    await axios.post(`/apiv2/attendance-shifts/unfinished/${userId}/end`, {
      logDate,
      endHour,
      endHourTimestamp,
      longitude,
      latitude,
    });
  }
}

export class AttendanceShiftEndpoints {
  static getTodaysShiftByUser(userId: number): Endpoint<AttendanceShiftDto | null> {
    const today = new LocalDate().toDateString();
    return {
      url: `/apiv2/attendance-shifts/date/${today}/users/${userId}`,
    };
  }
}
