import React, { useCallback, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { IconEmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { DeviceTransitDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType } from '@v2/feature/device/device.interface';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminDeviceTransitDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-transit-drawer.component';
import { spacing } from '@v2/styles/spacing.styles';

import { getModelImage, getTransitStatus } from '@/v2/feature/device/device.util';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface DevicesTransitsTableProps {
  readonly userNames: { [userId: number]: string };
  readonly companiesNames: { [companyId: number]: string };
  readonly deviceTransits: readonly DeviceTransitDto[];
  readonly refresh: () => Promise<void>;
  readonly sites: { [siteId: number]: SiteDto };
}

export const DevicesTransitsTableSuperAdmin = ({
  userNames,
  companiesNames,
  deviceTransits,
  refresh,
  sites,
}: DevicesTransitsTableProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedDeviceTransit, setSelectedDeviceTransit] = useState<DeviceTransitDto | undefined>(undefined);
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState('');

  const filteredDevices = useMemo(() => {
    return deviceTransits.filter((deviceTransit) => {
      const receiverName =
        deviceTransit.receiver?.possessionType === DevicePossessionType.User
          ? getCachedUserById(deviceTransit.receiver.possessionId)?.displayName
          : undefined;
      const senderName =
        deviceTransit.sender?.possessionType === DevicePossessionType.User
          ? getCachedUserById(deviceTransit.sender.possessionId)?.displayName
          : undefined;
      const modelName = deviceTransit.device?.modelName;

      return (
        receiverName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        senderName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        modelName?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
  }, [deviceTransits, searchInput, getCachedUserById]);

  const getUserName = useCallback(
    (assignedUserId: number): string | undefined => {
      const user = getCachedUserById(assignedUserId);
      return user?.displayName;
    },
    [getCachedUserById]
  );

  const columnData = useMemo<ColumnDef<DeviceTransitDto, DeviceTransitDto>[]>(
    () => [
      {
        header: () => 'Device',
        accessorFn: (row) => row,
        id: 'modelName',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          return deviceTransit.device?.modelName ? (
            <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
              {deviceTransit &&
                getModelImage(deviceTransit?.device?.type, deviceTransit?.device?.modelName, {
                  width: '30px',
                  height: 'auto',
                })}
              {deviceTransit.device.modelName}
            </Box>
          ) : (
            <IconEmptyCell />
          );
        },
        size: 100,
      },
      {
        header: () => 'Serial number',
        accessorFn: (row) => row,
        id: 'serialNumber',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          return deviceTransit?.device?.serialNumber ? (
            <Box>{deviceTransit.device.serialNumber}</Box>
          ) : (
            <IconEmptyCell />
          );
        },
        size: 100,
      },
      {
        header: () => 'Receiver',
        accessorFn: (row) => row,
        id: 'receiverId',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          if (
            deviceTransit.receiver?.possessionType === DevicePossessionType.User &&
            deviceTransit.receiver?.possessionId
          ) {
            const userName = getUserName(deviceTransit.receiver.possessionId);
            return userName ? (
              <Typography
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                <UserAvatar userId={deviceTransit.receiver.possessionId} size="xxsmall" />
                {userName}
              </Typography>
            ) : (
              <IconEmptyCell />
            );
          }

          if (deviceTransit.receiver?.possessionType === DevicePossessionType.CompanySite)
            return <Box>Company Site</Box>;
          if (deviceTransit.receiver?.possessionType === DevicePossessionType.ZeltStorage)
            return <Box>Zelt Storage</Box>;

          if (deviceTransit.receiver?.possessionType === DevicePossessionType.ZeltStock) return <Box>Zelt Stock</Box>;

          return <Box>Unknown</Box>;
        },
        size: 100,
      },
      {
        header: () => 'Receiver address',
        accessorFn: (row) => row,
        id: 'receiver',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          return deviceTransit.receiver?.deliveryAddress ? (
            <Box>{deviceTransit.receiver.deliveryAddress}</Box>
          ) : (
            <IconEmptyCell />
          );
        },
        size: 140,
      },
      {
        header: () => 'Sender',
        accessorFn: (row) => row,
        id: 'senderId',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          if (deviceTransit.sender?.possessionType === DevicePossessionType.User && deviceTransit.sender?.possessionId)
            return deviceTransit.sender.possessionId ? (
              <Box>
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                  }}
                >
                  <UserAvatar userId={deviceTransit.sender.possessionId} size="xxsmall" />
                  {getUserName(deviceTransit.sender.possessionId)}
                </Typography>
              </Box>
            ) : (
              'Not assigned'
            );
          if (deviceTransit.sender?.possessionType === DevicePossessionType.CompanySite) return <Box>Company Site</Box>;
          if (deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStorage) return <Box>Zelt Storage</Box>;
          if (deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStock) return <Box>Zelt Stock</Box>;

          return <Box>Unknown</Box>;
        },
        size: 100,
      },
      {
        header: () => 'Sender address',
        accessorFn: (row) => row,
        id: 'sender',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          return deviceTransit.senderAddress ? <Box>{deviceTransit.senderAddress}</Box> : <IconEmptyCell />;
        },
        size: 140,
      },

      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          return <>{getTransitStatus(deviceTransit.status)}</>;
        },
        size: 80,
      },
      {
        header: () => 'Notes',
        accessorFn: (row) => row,
        id: 'notes',
        enableSorting: false,
        cell: (info: CellContext<DeviceTransitDto, DeviceTransitDto>) => {
          const deviceTransit: DeviceTransitDto = info.getValue();
          return deviceTransit.notes ? <Box>{deviceTransit.notes}</Box> : <IconEmptyCell />;
        },
        size: 150,
      },
    ],
    [getUserName]
  );

  return (
    <>
      {deviceTransits.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20 }}>
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
      )}
      <BasicTable<DeviceTransitDto>
        rowData={filteredDevices}
        columnData={columnData}
        rowClick={(row) => {
          setSelectedDeviceTransit(row.original);
          setIsDrawerOpen(true);
        }}
      />

      {selectedDeviceTransit && (
        <SuperAdminDeviceTransitDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          deviceTransit={selectedDeviceTransit}
          setDeviceTransit={setSelectedDeviceTransit}
          companyNames={companiesNames}
          userNames={userNames}
          refresh={refresh}
          sites={sites}
        />
      )}
    </>
  );
};
