import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus } from '@v2/feature/device/device.interface';
import { DeviceOrderStatusToProgressScore, getModelImage } from '@v2/feature/device/device.util';
import { DevicePossessionInformationCard } from '@v2/feature/device/features/device-cards/components/device-possession-information-card.component';
import { DeviceTechSpecsCard } from '@v2/feature/device/features/device-cards/components/device-tech-specs-card.component';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
  DEVICES_COMPANY_OVERVIEW_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDERS_ROUTE,
} from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { ThemeSlider } from '@/v2/styles/slider.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface DeviceOrderDetailsPageProps {
  readonly loading: boolean;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refreshOrders: () => Promise<void>;
}

export const DeviceOrderDetailsPage = ({
  loading,
  setLoading,
  refreshOrders,
}: DeviceOrderDetailsPageProps): React.JSX.Element => {
  const params = useParams<{ readonly orderId: string }>();
  const orderId = Number(params.orderId);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [deviceOrder, setDeviceOrder] = useState<DeviceOrderDto | undefined>(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [showMessage] = useMessage();
  const history = useHistory();
  const location = useLocation();

  const refreshLocalOrder = useCallback(async () => {
    try {
      setLoading(true);
      const deviceOrder = await DeviceAPI.getDeviceOrderDetails(orderId);
      setDeviceOrder(deviceOrder);
      setProgress(DeviceOrderStatusToProgressScore[deviceOrder.status] ?? 0);
    } catch (error) {
      showMessage(`Could not retrieve device order details. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [setLoading, showMessage, orderId]);

  useEffect(() => {
    refreshLocalOrder();
  }, [refreshLocalOrder]);

  const cancelOrder = async (orderId: number): Promise<void> => {
    let orderCancelled = false;
    try {
      const updatedOrder = await DeviceAPI.cancelDeviceOrder(orderId);
      setDeviceOrder(updatedOrder);
      orderCancelled = true;
      showMessage('Order cancelled', 'success');
      await refreshOrders();
    } catch (error) {
      if (!orderCancelled) showMessage(`Could not cancel order. ${nestErrorMessage(error)}`, 'error');
      else showMessage(`Could not refresh orders list. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const deleteOrder = async (orderId: number): Promise<void> => {
    try {
      setIsDeleting(true);
      await DeviceAPI.deleteDeviceOrder(orderId);
      showMessage('Order deleted', 'success');
      await refreshOrders();
      if (location.pathname.includes(DEVICES_ME_ORDERS_ROUTE)) history.push(DEVICES_ME_DIRECTORY_ROUTE);
      else history.push(DEVICES_COMPANY_OVERVIEW_ROUTE);
    } catch (error) {
      showMessage(`Could not delete order. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDeleting(false);
    }
  };

  const isPendingOrAccepted =
    deviceOrder && [DeviceOrderStatus.Placed, DeviceOrderStatus.Accepted].includes(deviceOrder.status);
  const isCancelledOrRejected =
    deviceOrder?.status && [DeviceOrderStatus.Cancelled, DeviceOrderStatus.Rejected].includes(deviceOrder.status);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Order details</Typography>}
        actions={
          <ScopesControl scopes={['devices']} context={scopesContext}>
            {isPendingOrAccepted && (
              <ButtonComponent
                fullWidth
                sizeVariant="small"
                colorVariant="primary"
                onClick={async () => {
                  await cancelOrder(deviceOrder.id);
                }}
              >
                Cancel order
              </ButtonComponent>
            )}
            {isCancelledOrRejected && (
              <LoaderButton
                name="Delete Order"
                colorVariant="danger"
                sizeVariant="small"
                loading={isDeleting}
                onClick={async () => {
                  await deleteOrder(deviceOrder.id);
                }}
              />
            )}

            {deviceOrder?.status === DeviceOrderStatus.Shipping && (
              <ButtonComponent
                fullWidth
                colorVariant="primary"
                sizeVariant="small"
                onClick={() =>
                  location.pathname.includes(DEVICES_ME_ORDERS_ROUTE)
                    ? history.push(generatePath(DEVICES_ME_ORDER_ACTIVATION_ROUTE, { orderId: deviceOrder.id }))
                    : history.push(generatePath(DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE, { orderId: deviceOrder.id }))
                }
              >
                Confirm Delivery
              </ButtonComponent>
            )}
          </ScopesControl>
        }
        showAction={
          deviceOrder &&
          (deviceOrder?.status === DeviceOrderStatus.Shipping ||
            [DeviceOrderStatus.Cancelled, DeviceOrderStatus.Rejected].includes(deviceOrder?.status) ||
            isPendingOrAccepted)
        }
        showBack={true}
      />
      <ContentWrapper loading={loading}>
        {deviceOrder?.deviceModel ? (
          <Box>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="title4">
                  {deviceOrder.status === DeviceOrderStatus.Cancelled
                    ? 'Order cancelled'
                    : deviceOrder.status === DeviceOrderStatus.Rejected
                    ? 'Order rejected'
                    : deviceOrder.status === DeviceOrderStatus.Requested
                    ? 'Order requested'
                    : 'Order placed'}
                </Typography>
                <Typography
                  variant="title4"
                  color={
                    ![DeviceOrderStatus.Accepted, DeviceOrderStatus.Shipping, DeviceOrderStatus.Delivered].includes(
                      deviceOrder.status
                    )
                      ? 'Grey'
                      : undefined
                  }
                >
                  Order accepted
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    ![DeviceOrderStatus.Shipping, DeviceOrderStatus.Delivered].includes(deviceOrder.status)
                      ? 'Grey'
                      : undefined
                  }
                >
                  Shipping
                </Typography>
                <Typography
                  variant="caption"
                  color={deviceOrder.status !== DeviceOrderStatus.Delivered ? 'Grey' : undefined}
                >
                  Delivered
                </Typography>
              </Box>

              <ThemeSlider
                defaultValue={progress}
                value={progress}
                aria-label="Small"
                marks={[{ value: 0 }, { value: 35 }, { value: 68 }, { value: 100 }]}
              />
            </Box>
            {/* overview detail */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                maxWidth: '60%',
                justifyContent: 'space-between',
                width: '100%',
                ...spacing.mt40,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '35%',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Box sx={{ width: '200px', height: 'auto' }}>
                  {getModelImage(deviceOrder.deviceModel.type, deviceOrder.deviceModel.modelName, {
                    width: '200px',
                    height: 'auto',
                  }) ?? (
                    <SkeletonLoader
                      variant="rectangular"
                      width="200px"
                      height="100%"
                      sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                    />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '65%',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                {deviceOrder?.possession && (
                  <DevicePossessionInformationCard devicePossession={deviceOrder.possession} />
                )}
                {deviceOrder?.deviceModel && (
                  <DeviceTechSpecsCard deviceModel={deviceOrder.deviceModel} device={deviceOrder.device} />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>Order details could not be found.</Box>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
