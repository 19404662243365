import { ListItemIcon, ListItemText } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as Settings } from '@/images/fields/Settings.svg';
import { ReactComponent as SettingsActive } from '@/images/side-bar-icons/SettingsFilled.svg';
import {
  SETTINGS_BILLING_ROUTE,
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_NOTIFICATIONS_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_SITES_ROUTE,
} from '@/lib/routes';
import { activeSubStyle, ListItemStyle } from '@/v2/styles/list-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

const SETTINGS_ROUTES = [
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_SITES_ROUTE,
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_BILLING_ROUTE,
  SETTINGS_NOTIFICATIONS_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  '/settings/permission-groups',
  '/settings/import',
];
export const CompanyUserHeader = ({ headerName, redirectPath }: { headerName: string; redirectPath: string }) => {
  const routerHistory = useHistory();
  const { pathname } = useLocation();

  return (
    <ListItemStyle
      key={headerName}
      onClick={() => routerHistory.push(redirectPath)}
      sx={{
        ...(SETTINGS_ROUTES.includes(pathname) && activeSubStyle),
        width: '100%',
        mb: spacing.mb10,
      }}
    >
      <ListItemIcon sx={{ marginRight: spacing.m10, minWidth: '16px' }}>
        {SETTINGS_ROUTES.includes(pathname) ? <SettingsActive /> : <Settings />}
      </ListItemIcon>
      <ListItemText disableTypography primary={truncateWithEllipses(headerName)} />
    </ListItemStyle>
  );
};
