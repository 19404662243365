import React, { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OffboardingState } from '@v2/feature/offboarding/offboarding-process/offboarding-process.page';
import { TaskFormModal } from '@v2/feature/task/components/task-add-modal/task-form-modal.component';
import { TaskLaunchModal } from '@v2/feature/task/components/task-launch/task-launch-modal.component';
import { PersonalTask } from '@v2/feature/task/pages/personal/components/personal-task.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath } from 'react-router-dom';

import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';

interface PageProps {
  readonly userId: number;
  readonly offboardingState: OffboardingState;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
}

export const OffboardingTasksPage = ({ userId, offboardingState, refresh, loading }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState<boolean>(false);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('OffboardingPage.tasks')}</Typography>}
        showBack
        backPath={generatePath(USER_OFFBOARDING_ROUTE, { userId })}
      />
      {offboardingState.tasksData.tasksNo > 0 ? (
        <PersonalTask
          userId={userId}
          isTaskFormModalOpen={isTaskFormModalOpen}
          setIsTaskFormModalOpen={setIsTaskFormModalOpen}
          reach="userProfile"
          loading={loading}
          forOffboarding={true}
          refreshOffboarding={refresh}
        />
      ) : (
        <EmptyStateOffboardingTasks
          userId={userId}
          isTaskFormModalOpen={isTaskFormModalOpen}
          setIsTaskFormModalOpen={setIsTaskFormModalOpen}
          refresh={refresh}
          loading={loading}
        />
      )}
    </RootStyle>
  );
};

const EmptyStateOffboardingTasks = ({
  userId,
  isTaskFormModalOpen,
  setIsTaskFormModalOpen,
  refresh,
  loading,
}: {
  readonly userId: number;
  readonly setIsTaskFormModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly isTaskFormModalOpen: boolean;
  readonly loading?: boolean;
  readonly refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [isLaunchChecklistModalOpen, setIsLaunchChecklistModalOpen] = useState(false);

  return (
    <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
      <Typography variant="caption">{polyglot.t('OffboardingTasks.selectChecklist')}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1, mt: spacing.s2 }}>
        <ButtonComponent
          colorVariant="secondary"
          sizeVariant="large"
          onClick={() => {
            setIsTaskFormModalOpen(true);
          }}
        >
          {polyglot.t('OffboardingTasks.addTask')}
        </ButtonComponent>
        <ButtonComponent
          colorVariant="primary"
          sizeVariant="large"
          onClick={() => {
            setIsLaunchChecklistModalOpen(true);
          }}
        >
          {polyglot.t('OffboardingTasks.add')}
        </ButtonComponent>
      </Box>

      <TaskFormModal
        isOpen={isTaskFormModalOpen}
        setIsOpen={setIsTaskFormModalOpen}
        userId={userId}
        formData={null}
        refresh={refresh}
      />

      <TaskLaunchModal
        isOpen={isLaunchChecklistModalOpen}
        setIsOpen={setIsLaunchChecklistModalOpen}
        onClose={() => setIsLaunchChecklistModalOpen(false)}
        refresh={refresh}
        assignUserId={userId}
        reach="user"
      />
    </ContentWrapper>
  );
};
