import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { TableEmptyState } from '@v2/components/empty-state.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { EmployeeMemberships } from '@v2/feature/benefits/subfeature/pension/components/employee-memberships.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto, UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { useUserProfileConfig } from '@v2/feature/user/features/user-profile/user-profile.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PensionEmptyState } from '@/v2/feature/benefits/subfeature/pension/components/pension-empty-state.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';

interface PensionSummaryPageProps {
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly userPensionState: readonly [readonly UserPensionDto[], Dispatch<SetStateAction<readonly UserPensionDto[]>>];
  readonly loadingParent: boolean;
}

export const PensionSummaryPage = ({
  pensionSchemes,
  loadingParent,
  userPensionState,
}: PensionSummaryPageProps): JSX.Element => {
  const [state] = useContext(GlobalContext);
  const { userOverviewConfig, loading } = useUserProfileConfig(state.user.userId);
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const { polyglot } = usePolyglot();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userPensions, setUserPensions] = userPensionState;

  const refreshMemberships = useCallback(async () => {
    setIsLoading(true);
    if (!pensionSchemes) return setIsLoading(false);

    try {
      if (!userOverviewConfig.companyInPayroll) return;
      const memberships = await PensionAPI.getEmployeeMemberships();
      setUserPensions(memberships);
    } catch (error) {
      showMessage(
        polyglot.t('PensionModule.ErrorMessages.couldNotReadEmployeesMemberships', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  }, [polyglot, showMessage, pensionSchemes, userOverviewConfig.companyInPayroll, setUserPensions]);

  useEffect(() => {
    refreshMemberships();
  }, [refreshMemberships]);

  useEffect(() => {
    trackPage('Pension company summary');
  }, [trackPage]);

  return (
    <ContentWrapper loading={loading || isLoading || loadingParent}>
      {!pensionSchemes || pensionSchemes.length === 0 ? (
        <PensionEmptyState />
      ) : (
        <Box>
          <EmployeeMemberships
            userPensions={userPensions}
            companyPensions={pensionSchemes}
            refreshMemberships={refreshMemberships}
          />
          {!userPensions ||
            (userPensions.length === 0 && (
              <Box>
                <TableEmptyState title={polyglot.t('PensionModule.noEmployeesInPayroll')} />
              </Box>
            ))}
        </Box>
      )}
    </ContentWrapper>
  );
};
