import React, { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  isLoanBenefit,
  isUsingOpeningBalance,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitDto;
}

export const ViewUserCustomBenefitDrawer = ({ isOpen, setIsOpen, userBenefit }: DrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(UserEndpoints.getUserFamilyMembers(userBenefit.userId), {
    suspense: false,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ViewUserCustomBenefitDrawerContent userBenefit={userBenefit} userFamilyMembers={userFamilyMembers ?? []} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefit: UserCustomBenefitDto;
  readonly userFamilyMembers: UserFamilyMemberDto[];
}

export const ViewUserCustomBenefitDrawerContent = ({
  userBenefit,
  userFamilyMembers,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const hasOpeningBalance = isUsingOpeningBalance(userBenefit.customBenefit?.type);

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: spacing.g5, justifyContent: 'space-between' }}>
        <Typography variant="title2">
          {userBenefit.customBenefit?.name ?? polyglot.t('BenefitModule.userBenefit')}
        </Typography>
      </Box>
      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
          {polyglot.t('General.employee')}
        </Typography>
        <UserCell userId={userBenefit.userId} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
      </Box>
      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.effectiveDate')}
        </Typography>
        <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
          {userBenefit.effectiveDate}
        </Typography>
      </Box>

      {hasOpeningBalance && (
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('BenefitModule.openingBalance')}
          </Typography>
          <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
            {formatMoney({ amount: userBenefit.openingBalance ?? 0, asDecimal: true })}
          </Typography>
        </Box>
      )}

      {isLoanBenefit(userBenefit.customBenefit?.type) && (
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('BenefitModule.numberOfInstallments')}
          </Typography>
          <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
            {userBenefit.numberOfInstallments}
          </Typography>
        </Box>
      )}

      {!hasOpeningBalance && (
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('BenefitModule.employerContribution')}
          </Typography>
          <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
            {formatMoney({
              amount: userBenefit.employerContribution ?? 0,
              asDecimal: true,
            })}
          </Typography>
        </Box>
      )}

      {!hasOpeningBalance && (
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('BenefitModule.employeeContribution')}
          </Typography>
          <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
            {formatMoney({
              amount: userBenefit.employeeContribution ?? 0,
              asDecimal: true,
            })}
          </Typography>
        </Box>
      )}

      {userFamilyMembers.length > 0 && (
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('BenefitModule.additionalMembers')}
          </Typography>
          {userFamilyMembers.map((member) => (
            <Typography key={member.id} variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
              {member.name}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};
