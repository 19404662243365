import React from 'react';

import { Box, Stack, styled } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips,
} from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewQuestionBank } from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { QuestionType, QuestionTypeMap } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';
import { stripHtml } from '@/v2/util/string.util';

export const ReviewQuestionBankViewModal = ({
  isOpen,
  setIsOpen,
  setIsEditOpen,
  onClose,
  question,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  question: ReviewQuestionBank | null;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ReviewQuestionBankViewContent question={question} setIsEditOpen={setIsEditOpen} />
    </DrawerModal>
  );
};

const ReviewQuestionBankViewContent = ({
  question,
  setIsEditOpen,
}: {
  question: ReviewQuestionBank | null;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g24,
        }}
      >
        <Typography variant="title2">Question</Typography>

        {question?.reviewerSelect && (
          <ViewItem
            title="Who should respond to this question?"
            value={
              <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g4 }}>
                {question?.reviewerSelect
                  .sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b])
                  .map((r) => {
                    const hideVisibility =
                      r !== ReviewerTypes.Self &&
                      ((r === ReviewerTypes.Manager && question?.visibilitySettings?.hideManagerResult) ||
                        (r === ReviewerTypes.Upward && question?.visibilitySettings?.hideUpwardResult) ||
                        (r === ReviewerTypes.Peer && question?.visibilitySettings?.hidePeerResult));

                    return getReviewerTypesChips(hideVisibility)[r];
                  })}
              </div>
            }
          />
        )}
        {question?.questionMain && <ViewItem title="Question text" value={stripHtml(question?.questionMain)} />}
        {question?.questionSelf && <ViewItem title="Question Self" value={stripHtml(question?.questionSelf)} />}
        {question?.factor && <ViewItem title="Factor" value={question?.factor} />}
        {question?.type && <ViewItem title="Type" value={QuestionTypeMap[question.type as QuestionType]} />}
        {question && (
          <ViewItem
            title="Created by"
            value={
              question.companyId === null ? (
                <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                  <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" aria-label="Payroll logo" />
                  Zelt
                </Stack>
              ) : question?.createdBy ? (
                <UserCell userId={question.createdBy} />
              ) : (
                <EmptyCell />
              )
            }
          />
        )}
        {question?.createdAt && (
          <ViewItem
            title="Created on"
            value={new LocalDate(question.createdAt)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          />
        )}
      </Box>

      {question && question.companyId !== null && (
        <Box>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            onClick={() => setIsEditOpen(true)}
            aria-label="Edit question"
          >
            Edit
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};

const ViewItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.g8};
`;

const ViewItem = ({ title, value }: { title: string; value: JSX.Element | string }) => {
  return (
    <ViewItemContainer>
      <Typography variant="caption" color="Grey">
        {title}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </ViewItemContainer>
  );
};
