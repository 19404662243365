import { useContext } from 'react';

import { Route, Switch } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { DOCUMENTS_ME_ROUTE, TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE } from '@/lib/routes';
import { UserDocumentsPage } from '@/v2/feature/documents/document-me/pages/user-documents.page';
import { TemplateEditorPage } from '@/v2/feature/templates/pages/template-editor.page';

export const DocumentsMeRouter = (): JSX.Element => {
  const [state] = useContext(GlobalContext);
  const { userId } = state.user;

  return (
    <Switch>
      <Route path={TEMPLATE_CONTRACT_SIGN_ROUTE} exact>
        <TemplateEditorPage mode={'readonly'} action={'sign'} />
      </Route>

      <Route path={TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE} exact>
        <TemplateEditorPage mode={'readonly'} action={'company_sign'} />
      </Route>

      <Route path={DOCUMENTS_ME_ROUTE} exact>
        <UserDocumentsPage userId={userId} userName={`${state.user.firstName} ${state.user.lastName}`} />
      </Route>
    </Switch>
  );
};
