import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { InsurancePolicyDocuments } from '@v2/feature/benefits/subfeature/insurance/components/insurance-policy-documents.component';
import { InsuranceProviderDetails } from '@v2/feature/benefits/subfeature/insurance/components/insurance-provider-details.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { UserInsurancePolicyStatus } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE, USER_BENEFITS_ROUTE } from '@/lib/routes';

interface InsuranceMeOverviewPageProps {
  readonly userId: number;
  readonly userInsurance: UserInsuranceDto | null;
  readonly refresh: () => Promise<void>;
  readonly loading: boolean;
}

export const InsuranceMeOverviewPage = ({ userId, userInsurance, refresh, loading }: InsuranceMeOverviewPageProps) => {
  const [showMessage] = useMessage();
  const location = useLocation();

  const [isOptOutLoading, setIsOptOutLoading] = useState<boolean>(false);

  const optOut = useCallback(async (): Promise<void> => {
    if (!userInsurance || userInsurance.status !== UserInsurancePolicyStatus.Active) return;
    try {
      setIsOptOutLoading(true);
      await InsuranceAPI.optOutFromInsurancePolicy(userInsurance.userId, userInsurance.policyId);
      await refresh();
    } catch (error) {
      showMessage(`Something went wrong. Could not opt out. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsOptOutLoading(false);
    }
  }, [refresh, userInsurance, showMessage]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Health insurance</Typography>}
        showAction
        actions={
          userInsurance?.status === UserInsurancePolicyStatus.Active ? (
            <ScopesControl scopes={['insurance']} context={{ userId }}>
              <LoaderButton
                name="Opt out"
                loading={isOptOutLoading}
                sizeVariant="small"
                colorVariant="secondary"
                onClick={optOut}
              />
            </ScopesControl>
          ) : null
        }
        showBack
        backPath={
          matchPath(location.pathname, USER_BENEFITS_ROUTE)
            ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId })
            : BENEFITS_ME_ROUTE
        }
      />

      <ContentWrapper loading={loading}>
        {userInsurance?.status === UserInsurancePolicyStatus.PendingOptOut && (
          <Typography variant="title3" color="Grey">
            Pending Opt Out
          </Typography>
        )}
        {userInsurance?.policy && (
          <Box sx={{ mb: spacing.mb40 }}>
            <InsuranceProviderDetails insurancePolicy={userInsurance?.policy} userInsurance={userInsurance} />
          </Box>
        )}
        {userInsurance?.policy?.uploadedDocuments && userInsurance.policy.uploadedDocuments.length > 0 && (
          <Box sx={{ mt: spacing.mt40 }}>
            <InsurancePolicyDocuments insurancePolicy={userInsurance?.policy} />
          </Box>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
