import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/lib/ReadonlyArray';

import useMessage from '@/hooks/notification.hook';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import {
  BulkActionModeLabelMap,
  BulkUploadDocumentActionsMode,
  ConfirmBulkDrawerActionProps,
} from '@/v2/feature/documents/documents.interface';
import { DocumentType, getDocumentTypeListBasedOnAudience } from '@/v2/feature/documents/documents.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

export const DocumentBulkUploadActionsFormDrawer = ({
  selectedDocuments,
  confirmAction,
  mode,
  isOpen,
  setIsOpen,
  documentTypes,
}: {
  readonly selectedDocuments: string[];
  readonly confirmAction: ({ mode, owner, type }: ConfirmBulkDrawerActionProps) => void;
  readonly mode: BulkUploadDocumentActionsMode;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly documentTypes: readonly ZeltDocumentType[];
}) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { cachedUsers } = useCachedUsers();
  const [newOwner, setNewOwner] = useState<number | null>(null);
  const [newDocumentType, setNewDocumentType] = useState<DocumentType | null>(null);

  // should show all users (even terminated users), as some companies need to assign bulk documents to ex-employees
  const belongsToUserOptions = pipe(
    cachedUsers,
    RA.map((u) => ({ value: u.userId, label: u.displayName, ...u } as const))
  );

  const documentTypeOptions = useMemo(() => {
    return getDocumentTypeListBasedOnAudience(documentTypes, true, 'personal');
  }, [documentTypes]);

  const handleButtonClick = async () => {
    try {
      setLoading(true);
      if (mode === BulkUploadDocumentActionsMode.ASSIGN_OWNER && newOwner) {
        confirmAction({ mode, owner: newOwner });
      }
      if (mode === BulkUploadDocumentActionsMode.ASSIGN_TYPE && newDocumentType) {
        confirmAction({ mode, type: newDocumentType });
      }
      if (mode === BulkUploadDocumentActionsMode.DELETE) {
        confirmAction({ mode });
      }
    } catch (error) {
      showMessage(`Could not action on selected documents. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          {selectedDocuments?.length > 0 && (
            <Typography variant="title2">{selectedDocuments?.length} documents</Typography>
          )}
          {mode === BulkUploadDocumentActionsMode.ASSIGN_OWNER && (
            <SingleUserSelect
              name="singleownership"
              options={belongsToUserOptions}
              onChange={(_, value) => {
                if (value) {
                  setNewOwner(value.userId);
                } else {
                  setNewOwner(null);
                }
              }}
              value={newOwner}
              label="Document owner"
              error={false}
              helperText={false}
            />
          )}
          {mode === BulkUploadDocumentActionsMode.ASSIGN_TYPE && (
            <AutocompleteComponent
              name="documentType"
              label={polyglot.t('DocumentFormModal.documentType')}
              options={documentTypeOptions}
              value={newDocumentType}
              // @ts-ignore
              onChange={(_, x: DocumentType) => {
                if (x) {
                  setNewDocumentType(x);
                }
              }}
              compareValue={newDocumentType?.value ?? ''}
            />
          )}
        </Box>

        <Box sx={spacing.mt40}>
          <LoaderButton
            loading={loading}
            colorVariant="primary"
            sizeVariant="medium"
            fullWidth
            onClick={handleButtonClick}
            name={BulkActionModeLabelMap[mode]}
          />
        </Box>
      </div>
    </DrawerModal>
  );
};
