import React from 'react';

import { Link } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { underlinedLinkLight } from '@/v2/styles/buttons.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const LastpassInfoCard = (): React.JSX.Element => (
  <Typography variant="caption" sx={{ mt: '10px' }}>
    <Link sx={underlinedLinkLight} href="https://www.lastpass.com/pricing">
      Enterprise plan
    </Link>
    {' required to use LastPass API.'}
  </Typography>
);

export const LastpassProvHashInstructions = (): React.JSX.Element => (
  <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
    {'You can create a provisioning hash in your '}
    <Link sx={underlinedLinkLight} target="_blank" href="https://admin.lastpass.com/advanced/enterpriseApi">
      Lastpass API settings
    </Link>
  </Typography>
);

export const LastpassAccountIdInstructions = (): React.JSX.Element => (
  <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
    {'Your account ID can be found in your Lastpass company '}
    <Link sx={underlinedLinkLight} target="_blank" href="https://admin.lastpass.com/dashboard">
      Dashboard
    </Link>
  </Typography>
);
