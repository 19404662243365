import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { ZeltMdmOsUpdatesPolicyViewer } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/viewers/zelt-mdm-os-updates-policy-viewer.component';
import { NoAppliedPolicies } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
} from '@/v2/feature/device/device.dto';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const OsUpdatesZeltMdmPage = () => {
  const [showMessage] = useMessage();

  //structured data for table
  const [
    configurablePolicies,
    setConfigurablePolicies,
  ] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>();

  const getDeviceSettingsByZeltMdm = useCallback(async () => {
    const appliedPoliciesWithConfigurableFeature = await DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature();
    setConfigurablePolicies(appliedPoliciesWithConfigurableFeature);
  }, []);

  const updatePolicies = async (configurablePolicies?: ConfigurablePoliciesDto) => {
    try {
      await DeviceAPI.updateCompanyDeviceAppliedPoliciesByZeltMdm(NoAppliedPolicies, false, configurablePolicies);
      showMessage('Successfully updated settings', 'success');
      await getDeviceSettingsByZeltMdm();
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };

  useEffect(() => {
    getDeviceSettingsByZeltMdm();
  }, [getDeviceSettingsByZeltMdm]);

  useEffect(() => {
    getDeviceSettingsByZeltMdm();
  }, [getDeviceSettingsByZeltMdm]);
  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">OS updates settings</Typography>} />
      <ContentWrapper loading={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: spacing.g35,
          }}
        >
          <ZeltMdmOsUpdatesPolicyViewer
            updatePolicies={updatePolicies}
            configurablePoliciesSelected={configurablePolicies}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
