import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { getDateFormation } from '@v2/feature/dashboard/dashboard.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ClockIcon } from '@/images/side-bar-icons/TimeAway.svg';
import { ShiftActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/shift-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import {
  UnfinishedAttendanceShiftsBigWidget,
  UnfinishedAttendanceShiftsTodos,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

export const UnfinishedAttendanceShiftTodo = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: UnfinishedAttendanceShiftsBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<UnfinishedAttendanceShiftsTodos | undefined>(undefined);

  return (
    <Box>
      {todo.shifts.map((attendanceShift: UnfinishedAttendanceShiftsTodos, idx: number) => {
        const title = `${getCachedUserById(attendanceShift.userId)?.displayName}: ${getDateFormation(
          attendanceShift.logDate
        )}`;

        return (
          <TodoCard
            key={`attendance-${attendanceShift.userId}-${attendanceShift.logDate}`}
            icon={<ClockIcon {...iconSize} />}
            title={
              <Typography
                variant="caption"
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {polyglot.t('UnfinishedAttendanceShiftTodo.unfinishedFrom', { title: title })}
              </Typography>
            }
            actionOnclick={() => {
              setSelectedRow(attendanceShift);
            }}
            showBorder={!(setLastChild && todo.shifts.length === idx + 1)}
            userAvatar={<UserAvatar userId={attendanceShift.userId} size="xxxsmall" />}
          />
        );
      })}

      {selectedRow && (
        <ShiftActionItem
          shiftActionRow={selectedRow as UnfinishedAttendanceShiftsTodos}
          refresh={refreshTodos}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
