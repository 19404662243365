import axios from 'axios';

type StaffologyDocumentLink = {
  pdfUrl: string;
};

export class UKPayrollAPI {
  static async getP60DocumentForUser(userId: number, taxYear: number): Promise<StaffologyDocumentLink> {
    return (await axios.get<StaffologyDocumentLink>(`/apiv2/users/${userId}/payroll/year-end-document/${taxYear}`))
      .data;
  }
}
