import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userInsurance: UserInsuranceDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const InsuranceRemoveFromPolicyDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  refresh,
  onClose,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <InsuranceRemoveFromPolicyDrawerContent userInsurance={userInsurance} onClose={onClose} refresh={refresh} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userInsurance: UserInsuranceDto;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}

const InsuranceRemoveFromPolicyDrawerContent = ({ userInsurance, onClose, refresh }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>(new LocalDate().toDateString());
  const [showMessage] = useMessage();

  const removeFromPolicy = useCallback(async () => {
    try {
      setLoading(true);
      await InsuranceAPI.removeUserFromPolicy(userInsurance.userId, userInsurance.policyId, endDate);
      await refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setLoading(false);
  }, [showMessage, polyglot, refresh, userInsurance, onClose, endDate]);

  return (
    <Box>
      <Typography variant="title2">Remove from insurance</Typography>

      <Box sx={{ mb: '10px', mt: '20px' }}>
        <Typography variant="captionSmall">Employee</Typography>
        <UserCell userId={userInsurance.userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
      </Box>

      <Box sx={{ mb: '30px' }}>
        <DatePickerComponent
          name="endDate"
          label="End date"
          onChange={(value) => {
            setEndDate(value);
          }}
          value={endDate}
        />
      </Box>

      <Box sx={buttonBoxSx}>
        <LoaderButton
          name="Remove"
          loading={loading}
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
          onClick={removeFromPolicy}
        />
      </Box>
    </Box>
  );
};
