import { useMemo } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { sortNumeric } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { DeviceTransitDto } from '@v2/feature/device/device.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

export const SuperAdminDeviceDetailsTransits = ({ deviceId }: { deviceId: number }) => {
  const {
    data: deviceTransits,
    isLoading /* mutate: refresh */,
  } = useApiClient(DeviceEndpoints.getTransitsByDeviceIdAsSuperAdmin(deviceId), { suspense: true });

  // const refreshTransits = useCallback(async () => {
  //   if (refresh) refresh();
  // }, [refresh]);

  const columns = useMemo<ColumnDef<DeviceTransitDto, DeviceTransitDto>[]>(() => {
    return [
      {
        id: 'id',
        header: 'ID',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.id}</Typography>
          </Box>
        ),
      },
      {
        id: 'companyId',
        header: 'Company ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.companyId}</Typography>
          </Box>
        ),
      },
      {
        id: 'deviceId',
        header: 'Device ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.deviceId}</Typography>
          </Box>
        ),
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.status}</Typography>
          </Box>
        ),
      },
      {
        id: 'createdBy',
        header: 'Created by',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.createdBy}</Typography>
          </Box>
        ),
      },
      {
        id: 'senderId',
        header: 'Sender P ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.senderId}</Typography>
          </Box>
        ),
      },
      {
        id: 'receiverId',
        header: 'Receiver P ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.receiverId}</Typography>
          </Box>
        ),
      },
      {
        id: 'deliveryAddress',
        header: 'Delivery address',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.deliveryAddress ? (
              <Typography variant="caption">{original.deliveryAddress}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'notes',
        header: 'Notes',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>{original.notes ? <Typography variant="caption">{original.notes}</Typography> : <EmptyCell />}</Box>
        ),
      },
      {
        id: 'createdAt',
        header: 'Created at',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{new Date(original.createdAt).toLocaleString()}</Typography>
          </Box>
        ),
      },
      {
        id: 'updatedAt',
        header: 'Updated at',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{new Date(original.updatedAt).toLocaleString()}</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  return (
    <Box>
      <Typography variant="title3">Device transits</Typography>

      <Box sx={{ mt: '5px' }}>
        <BasicTable<DeviceTransitDto>
          rowData={deviceTransits ?? []}
          columnData={columns}
          loading={isLoading}
          hidePagination
          // rowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};
