import { useCallback } from 'react';

import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownArrow } from '@/images/fields/DownArrow.svg';
import { ReactComponent as UpArrow } from '@/images/fields/UpArrow.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowLeft } from '@/images/side-bar-icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '@/images/side-bar-icons/ArrowRight.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { OptionProps } from '@/v2/components/theme-components/styled-menu.component';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyQuestionAPI } from '@/v2/feature/growth/surveys/api-client/survey-question.api';
import { SurveyCycle, SurveyCycleById } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { iconSize } from '@/v2/styles/menu.styles';

export const useSurveyCycleQuestionOptions = (
  question: SurveyQuestion,
  setQuestion: React.Dispatch<React.SetStateAction<SurveyQuestion | null>>,
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>,
  cycle: SurveyCycle,
  refreshSurveyStructure: KeyedMutator<SurveyCycleById> | undefined,
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>,
  hasSections: boolean
): readonly OptionProps[] => {
  const { id: cycleId } = cycle;
  const [showMessage] = useMessage();

  const editHandler = useCallback(() => {
    setQuestion(question);
    setIsQuestionModalOpen(true);
  }, [question, setQuestion, setIsQuestionModalOpen]);

  const deleteHandler = useCallback(async () => {
    try {
      await SurveyQuestionAPI.deleteSurveyQuestion(question.id, cycleId);
      showMessage('Successfully deleted', 'success');
      await refreshSurveyStructure?.();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    }
  }, [question.id, cycleId, showMessage, refreshSurveyStructure]);

  const currentOrder = cycle.order ? [...cycle.order] : [];
  const findQuestionIndex = () => currentOrder.findIndex((o) => o.type === 'question' && o.id === question.id);

  const isQuestionFirst = () => findQuestionIndex() === 0;
  const isQuestionLast = () => findQuestionIndex() === currentOrder.length - 1;

  const moveSection = async (direction: 'up' | 'down') => {
    const sectionIndex = findQuestionIndex();
    if (direction === 'up' && sectionIndex > 0) {
      [currentOrder[sectionIndex - 1], currentOrder[sectionIndex]] = [
        currentOrder[sectionIndex],
        currentOrder[sectionIndex - 1],
      ];
    } else if (direction === 'down' && sectionIndex < currentOrder.length - 1) {
      [currentOrder[sectionIndex], currentOrder[sectionIndex + 1]] = [
        currentOrder[sectionIndex + 1],
        currentOrder[sectionIndex],
      ];
    }
    await SurveyCycleAPI.updateOrderSurveyCycle({ cycleId: cycleId, order: currentOrder });
    await refreshSurveyStructure?.();
  };

  return [
    { icon: <Edit {...iconSize} />, handler: editHandler, label: 'Edit', disabled: false },
    { icon: <Trash {...iconSize} />, handler: deleteHandler, label: 'Delete', disabled: false },
    ...(!question?.sectionId
      ? [
          {
            icon: <UpArrow {...iconSize} />,
            handler: async () => {
              try {
                if (!cycle || !cycle.order || isQuestionFirst()) return;
                await moveSection('up');
                showMessage('Successfully moved the question up', 'success');
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move up',
            disabled: isQuestionFirst(),
          },
          {
            icon: <DownArrow {...iconSize} />,
            handler: async () => {
              try {
                if (!cycle || !cycle.order || isQuestionLast()) return;
                await moveSection('down');
                showMessage('Successfully moved the question down', 'success');
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move down',
            disabled: isQuestionLast(),
          },
          {
            icon: <ArrowRight {...iconSize} />,
            handler: async () => {
              try {
                setIsMoveToSectionOpen(true);
                setSelectedQuestionId(question.id);
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move inside section',
            disabled: !hasSections,
          },
        ]
      : [
          {
            icon: <ArrowLeft {...iconSize} />,
            handler: async () => {
              try {
                if (!cycle || !question.sectionId) return;
                await SurveyCycleAPI.moveQuestionOutsideSection({
                  cycleId,
                  sectionId: question.sectionId,
                  questionId: question.id,
                });
                await refreshSurveyStructure?.();

                showMessage('Successfully moved the outside the section', 'success');
              } catch (error) {
                showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
              }
            },
            label: 'Move outside section',
            disabled: false,
          },
        ]),
  ];
};
