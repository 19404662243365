import { useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type AppIconsChipProps = {
  stubs: AppIntegrationStub[];
  size?: string | number;
};

export const AppIconsList = ({ stubs, size = '20px' }: AppIconsChipProps) => {
  const [apps, setApps] = useState<InstalledAppDto[]>();

  useEffect(() => {
    AppIntegrationAPI.availableApps().then(setApps);
  }, []);

  const images = useMemo(() => {
    const appName = (stub: string) => apps?.find((a) => a.stub === stub)?.name ?? '';
    return stubs?.map((stub) => (
      <img
        key={stub}
        src={`/app-icons-v2/images/${stub}.png`}
        style={{ maxHeight: size, maxWidth: size }}
        alt=""
        title={appName(stub)}
      />
    ));
  }, [apps, stubs, size]);

  return <Stack sx={{ flexFlow: 'row', alignItems: 'center', flexWrap: 'wrap', gap: spacing.g5 }}>{images}</Stack>;
};
