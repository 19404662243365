import axios from 'axios';

import {
  GrowthScale,
  GrowthScaleCreate,
  GrowthScaleUpdate,
} from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class GrowthScaleAPI {
  static async createScale(scaleBody: GrowthScaleCreate): Promise<GrowthScale> {
    return (await axios.post('/apiv2/growth-scale', scaleBody)).data;
  }

  static async updateScale(scaleBody: GrowthScaleUpdate): Promise<void> {
    await axios.patch('/apiv2/growth-scale', scaleBody);
  }
}
export class GrowthScaleEndpoints {
  static getGrowthScalesByCompanyId(): Endpoint<GrowthScale[]> {
    return {
      url: '/apiv2/growth-scale',
    };
  }
}
