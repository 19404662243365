import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { BenefitsQuoteData } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { Typography } from '@/v2/components/typography/typography.component';

interface InsuranceSetupEmployeesStepProps {
  readonly onNext: () => void;
  readonly quoteData: BenefitsQuoteData;
  readonly setQuoteData: React.Dispatch<React.SetStateAction<BenefitsQuoteData>>;
}

export const InsuranceSetupEmployeesStep = ({ onNext, quoteData, setQuoteData }: InsuranceSetupEmployeesStepProps) => {
  const { polyglot } = usePolyglot();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [validationMessage, setValidationMessage] = useState<string>('');
  const companyUserIds = useMemo(() => companyUsers.map((u) => u.userId), [companyUsers]);

  const [selectedOption, setSelectedOption] = useState<UserSelectFiltersOptions>(
    companyUserIds.length === quoteData.selectedUsers.length
      ? UserSelectFiltersOptions.Everyone
      : quoteData.selectedUsers.length > 0
      ? UserSelectFiltersOptions.SelectSpecific
      : UserSelectFiltersOptions.None
  );
  const formik = useFormik<{ selectedUsers: number[] }>({
    initialValues: {
      selectedUsers: quoteData.selectedUsers,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      selectedUsers: yup.array().nullable().notRequired(),
    }),
    onSubmit: ({ selectedUsers }: { selectedUsers: number[] }) => {
      if (selectedOption === UserSelectFiltersOptions.None)
        setValidationMessage(polyglot.t('BenefitModule.pleaseSelectOption'));
      else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
        setValidationMessage(polyglot.t('BenefitModule.pleaseSelectAtLeastOneUser'));
      else {
        setQuoteData((prev) => ({ ...prev, selectedUsers }));
        onNext();
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('General.employees')}
          </Typography>

          <Typography variant="caption" sx={{ mt: spacing.mt20, mb: spacing.mb30, color: themeColors.DarkGrey }}>
            {polyglot.t('BenefitModule.selectEmployeesToCoverLong')}
          </Typography>

          <Box sx={fieldSx}>
            <UserSelect
              label={polyglot.t('BenefitModule.whoShouldBeSelected')}
              selectedLabel={polyglot.t('BenefitModule.selectedEmployees')}
              value={formik.values.selectedUsers}
              onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions) => {
                if (filterValue === UserSelectFiltersOptions.Everyone) {
                  formik.setFieldValue('selectedUsers', companyUserIds);
                } else {
                  formik.setFieldValue('selectedUsers', userIds);
                }
                setValidationMessage('');
                setSelectedOption(filterValue ?? UserSelectFiltersOptions.None);
              }}
              fieldSx={{ ...spacing.mb20 }}
              error={!!validationMessage}
              helperText={!!validationMessage && validationMessage}
              initialFilterValue={UserSelectFiltersOptions.None}
            />
          </Box>

          <Box sx={{ ...buttonBoxSx, mt: spacing.mt40 }}>
            <ButtonComponent sizeVariant="medium" colorVariant="primary" type="submit" fullWidth>
              {polyglot.t('General.continue')}
            </ButtonComponent>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
