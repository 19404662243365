import { useContext } from 'react';

import { CalendarCompanyRouter } from '@v2/feature/calendar/calendar-company.router';
import { Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { TIME_COMPANY_CALENDAR_ROUTE, TIME_ME_CALENDAR_ROUTE, TIME_TEAM_CALENDAR_ROUTE } from '@/lib/routes';
import { CalendarMeRouter } from '@/v2/feature/calendar/calendar-me.router';
import { CalendarTeamRouter } from '@/v2/feature/calendar/calendar-team.router';

export const CalendarRouter = (): JSX.Element => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return (
    <Switch>
      <RouteScopesControl scopes={['absence']} path={[TIME_COMPANY_CALENDAR_ROUTE]} context={scopesContext}>
        <CalendarCompanyRouter />
      </RouteScopesControl>

      <RouteScopesControl scopes={['absence']} path={[TIME_TEAM_CALENDAR_ROUTE]} context={scopesContext}>
        <CalendarTeamRouter />
      </RouteScopesControl>

      <RouteScopesControl scopes={['absence']} path={[TIME_ME_CALENDAR_ROUTE]} context={scopesContext}>
        <CalendarMeRouter />
      </RouteScopesControl>
    </Switch>
  );
};
