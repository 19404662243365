import { useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import type { BasicInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  BasicInformationForm,
  BasicInformationSchema,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-form.component';
import { isDataEmpty } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { UserAPI } from '@/v2/feature/user/user.api';
import { MissingField } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SCOPE = 'user.basicInfo' as const;
const iconSize = { width: 14, height: 14 } as const;

const skeletonHeight = calculateSkeletonHeight(BasicInformationSchema);

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: BasicInformationValues) => void;
  readonly missingFields?: MissingField[] | null;
}

export const BasicInformationCard = ({ userId, onSubmit, missingFields }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [data, setData] = useState<BasicInformationValues | undefined>(undefined);
  const [isEmpty, setEmpty] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUserBasic = useCallback(async () => {
    try {
      setLoading(true);
      const data = await UserAPI.getUserBasicInfo(userId);
      setEmpty(isDataEmpty(data, data.customUpdates));
      setData(data);
    } catch (error) {
      showMessage(polyglot.t('BasicInformationCard.errorMessages.load'), 'error');
    } finally {
      setLoading(false);
    }
  }, [polyglot, showMessage, userId]);

  useEffect(() => {
    getUserBasic();
  }, [getUserBasic]);

  return loading || !data ? (
    <SkeletonLoader
      variant="rectangular"
      height={skeletonHeight}
      sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
    />
  ) : (
    <ProfileCard
      fieldStubs={['emailAddress', 'firstName', 'lastName', 'middleName', 'displayName']}
      customUpdates={data?.customUpdates}
      sx={cardSx}
    >
      <Box component="header" sx={cardHeaderSx}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('BasicInformationCard.basic')}
          </Typography>
          <ScopesControl scopes={['user.basicInfo:read']} context={scopesContext}>
            {missingFields && !!missingFields.find((rec) => rec.cardId === CustomProfileFormType.Basic) && (
              <Box sx={hazardMissingField}>
                <Hazard {...hazardSize} />
              </Box>
            )}
          </ScopesControl>
        </Box>
        <ScopesControl scopes={[SCOPE]} context={scopesContext}>
          <IconButton onClick={() => setIsOpen(true)} title="Edit" sx={tableIconButtonSx}>
            {isEmpty ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
          </IconButton>
        </ScopesControl>
      </Box>
      <Box component="section">
        {isEmpty ? (
          <MissingInformationLabel />
        ) : (
          <Box component="dl" sx={definitionListSx}>
            <FieldStructure
              fieldTitle={polyglot.t('BasicInformationCard.emailAddress')}
              fieldValue={
                data?.emailAddress && {
                  isLink: true,
                  label: data.emailAddress,
                  icon: <Copy {...iconSize} />,
                  onClick: () => navigator.clipboard.writeText(data.emailAddress),
                }
              }
              fieldStub="emailAddress"
            />
            <FieldStructure
              fieldTitle={polyglot.t('BasicInformationCard.firstName')}
              fieldValue={polyglot.t(data?.firstName ?? '')}
              fieldStub="firstName"
            />
            <FieldStructure
              fieldTitle={polyglot.t('BasicInformationCard.lastName')}
              fieldValue={polyglot.t(data?.lastName ?? '')}
              fieldStub="lastName"
            />
            <FieldStructure
              fieldTitle={polyglot.t('BasicInformationCard.middleName')}
              fieldValue={polyglot.t(data?.middleName ?? '')}
              fieldStub="middleName"
            />
            <FieldStructure
              fieldTitle={polyglot.t('BasicInformationCard.displayName')}
              fieldValue={polyglot.t(data && (data.displayName ?? `${data.firstName} ${data.lastName}`))}
              fieldStub="displayName"
            />

            {sortCustomFields(data.customUpdates).map(
              (f) =>
                !f.field.isHidden && (
                  <FieldStructure
                    key={f.field.fieldId}
                    fieldTitle={f.field.fieldName}
                    fieldValue={polyglot.t(f.value ?? '')}
                  />
                )
            )}
          </Box>
        )}
      </Box>
      {data && (
        <ScopesControl scopes={[SCOPE]} context={scopesContext}>
          <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <BasicInformationForm
              userId={userId}
              initialValues={data}
              onSubmit={(values) => {
                setData(values);
                setIsOpen(false);
                onSubmit(values);
              }}
              onClose={() => setIsOpen(false)}
              handleRefresh={() => getUserBasic()}
            />
          </DrawerModal>
        </ScopesControl>
      )}
    </ProfileCard>
  );
};
