import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { PublicHolidaysAPI } from '@v2/feature/absence/public-holidays.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import Polyglot from 'node-polyglot';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';

export const AbsenceDrawerPHButtons = ({
  absence,
  refresh,
}: {
  readonly absence: AbsenceDto;
  readonly refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
  const [isLoadingSecondary, setIsLoadingSecondary] = useState(false);

  const handleApproval = useCallback(
    async (
      absence: Pick<AbsenceDto, 'userId' | 'isPublicHoliday' | 'deletionRequestId'>,
      status: AbsenceStatus
    ): Promise<void> => {
      if (!absence.deletionRequestId) return;
      try {
        await PublicHolidaysAPI.handleUserDeletionApprovalByRequestId(
          absence.userId,
          absence.deletionRequestId,
          status
        );
        await refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), 'error');
      }
    },
    [refresh, polyglot, showMessage]
  );

  const handleForceApproval = useCallback(
    async (
      absence: Pick<AbsenceDto, 'userId' | 'isPublicHoliday' | 'deletionRequestId'>,
      status: AbsenceStatus
    ): Promise<void> => {
      if (!absence.deletionRequestId) return;
      try {
        await PublicHolidaysAPI.handleUserDeletionForceApprovalByRequestId(
          absence.userId,
          absence.deletionRequestId,
          status
        );
        await refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), 'error');
      }
    },
    [showMessage, polyglot, refresh]
  );

  const deleteCancellation = useCallback(
    async (absence: Pick<AbsenceDto, 'userId' | 'isPublicHoliday' | 'deletionRequestId'>): Promise<void> => {
      if (!absence.deletionRequestId) return;
      try {
        await PublicHolidaysAPI.deletePHDeletionRequest(absence.userId, absence.deletionRequestId);
        await refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), 'error');
      }
    },
    [showMessage, polyglot, refresh]
  );

  const { primaryAction, allOtherActions } = useMemo(() => {
    const allActions = getActionButtons(absence, handleApproval, handleForceApproval, deleteCancellation, polyglot);

    // get first primary action available (the array should contain only one primary action that is not hidden)
    const primaryAction = allActions.find((a) => a.variant === 'primary');

    return {
      primaryAction,
      allOtherActions: primaryAction ? allActions.filter((a) => a.id !== primaryAction.id) : allActions,
    };
  }, [absence, polyglot, handleApproval, handleForceApproval, deleteCancellation]);

  return !primaryAction && allOtherActions.length === 0 ? null : (
    <Box sx={buttonBoxDrawerSx}>
      {/* IF ONLY 2 SECONDARY ACTIONS */}
      {!primaryAction && allOtherActions.length === 2 ? (
        <>
          <LoaderButton
            sizeVariant="medium"
            loading={isLoadingSecondary}
            colorVariant="secondary"
            onClick={async () => {
              setIsLoadingSecondary(true);
              await allOtherActions[0].handler();
              setIsLoadingSecondary(false);
            }}
            name={allOtherActions[0].label}
            fullWidth
          />
          <LoaderButton
            sizeVariant="medium"
            loading={isLoadingSecondary}
            colorVariant="secondary"
            onClick={async () => {
              setIsLoadingSecondary(true);
              await allOtherActions[1].handler();
              setIsLoadingSecondary(false);
            }}
            name={allOtherActions[1].label}
            fullWidth
          />
        </>
      ) : //   IF MORE THAN 2 ACTIONS IN TOTAL (including one primary)
      allOtherActions.length > 1 ? (
        <Box sx={{ width: '100%' }}>
          <StyledMenuComponent
            options={allOtherActions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'medium',
              title: polyglot.t('General.actions'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          />
        </Box>
      ) : // IF ONLY ONE SECONDARY ACTION
      allOtherActions.length === 1 ? (
        <LoaderButton
          sizeVariant="medium"
          loading={isLoadingSecondary}
          colorVariant="secondary"
          onClick={async () => {
            setIsLoadingSecondary(true);
            await allOtherActions[0].handler();
            setIsLoadingSecondary(false);
          }}
          name={allOtherActions[0].label}
          fullWidth
        />
      ) : null}

      {primaryAction && (
        <LoaderButton
          sizeVariant="medium"
          loading={isLoadingPrimary}
          colorVariant="primary"
          onClick={async () => {
            setIsLoadingPrimary(true);
            await primaryAction.handler();
            setIsLoadingPrimary(false);
          }}
          name={primaryAction.label}
          fullWidth
        />
      )}
    </Box>
  );
};

enum AbsenceActionVariant {
  primary = 'primary',
  secondary = 'secondary',
}

const getActionButtons = (
  absence: AbsenceDto,
  handleApproval: (
    absence: Pick<AbsenceDto, 'userId' | 'isPublicHoliday' | 'deletionRequestId'>,
    status: AbsenceStatus
  ) => Promise<void>,
  handleForceApproval: (
    absence: Pick<AbsenceDto, 'userId' | 'isPublicHoliday' | 'deletionRequestId'>,
    status: AbsenceStatus
  ) => Promise<void>,
  deleteCancellation: (absence: Pick<AbsenceDto, 'userId' | 'isPublicHoliday' | 'deletionRequestId'>) => Promise<void>,
  polyglot: Polyglot
): {
  id: string;
  label: string;
  handler: (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void | Promise<void>;
  hidden: boolean;
  variant: AbsenceActionVariant;
  icon?: React.JSX.Element;
}[] => {
  return [
    /* PRIMARY BUTTONS */
    // APPROVE
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.approve'),
      handler: async () => {
        await handleApproval(absence, AbsenceStatus.Approved);
      },
      hidden: !handleApproval || !absence.canApprove,
      variant: AbsenceActionVariant.primary,
      icon: <Chose {...iconSize} />,
      id: 'approve',
    },
    // FORCE APPROVE
    {
      label: polyglot.t('AbsenceTableActions.forceApprove'),
      handler: async () => {
        await handleForceApproval(absence, AbsenceStatus.Approved);
      },
      hidden: !handleForceApproval || !absence.canForceApprove,
      variant: AbsenceActionVariant.primary,
      icon: <Chose {...iconSize} />,
      id: 'forceApprove',
    },

    /* SECONDARY BUTTONS */
    // FORCE REJECT
    {
      label: polyglot.t('AbsenceTableActions.forceReject'),
      handler: async () => {
        await handleForceApproval(absence, AbsenceStatus.Rejected);
      },
      hidden: !handleForceApproval || !absence.canForceReject,
      variant: AbsenceActionVariant.secondary,
      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
      id: 'forceReject',
    },
    // REJECT
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.reject'),
      handler: async () => {
        await handleApproval(absence, AbsenceStatus.Rejected);
      },
      hidden: !handleApproval || !absence.canReject,
      variant: AbsenceActionVariant.secondary,
      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
      id: 'reject',
    },
    // DELETE
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.deleteCancellation'),
      handler: async () => {
        await deleteCancellation(absence);
      },
      hidden: !deleteCancellation || !absence.canDeletePHCancellation,
      variant: AbsenceActionVariant.secondary,
      icon: <Trash {...iconSize} />,
      id: 'delete',
    },
  ].filter((btn) => !btn.hidden);
};
