import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { ValidationError } from '@v2/infrastructure/api-error/api-error.interface';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { AbsenceEndpoints } from '@/v2/feature/absence/absence.api';
import { AbsenceDto, AbsencePolicyDto } from '@/v2/feature/absence/absence.dto';
import { AbsenceDrawer } from '@/v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import {
  AbsenceImportDto,
  AbsenceImportResultDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import { ImportedAbsenceTableCell } from '@/v2/feature/entity-import/wizard/components/absence/imported-absence-table-cell.component';
import { validateAbsenceImport } from '@/v2/feature/entity-import/wizard/entity-import-validator.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { ddMMYYYYToIsoDateString, isoDateAndTimeFormat } from '@/v2/infrastructure/date/date-format.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  result?: AbsenceImportResultDto;
  onUpdateAndRevalidate: (updatedRecords: EntityImportValidationResultDto<AbsenceImportDto>) => void;
  completeImport: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const AbsenceImportResultTable = ({
  result,
  completeImport,
  onUpdateAndRevalidate,
  loading,
  setLoading,
}: Props) => {
  const {
    data: absencePolicies,
    mutate: refreshAbsencePolicies,
  } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });

  const [showMessage] = useMessage();
  const [rowBeingEdited, setRowBeingEdited] = useState<AbsenceDto>();
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const [isOpen, setIsOpen] = useState(false);

  const { rowErrors, rowData } = useMemo((): {
    rowData: AbsenceImportDto[];
    rowErrors: ValidationError[][];
  } => {
    if (!result?.errors.some((e) => e.entity)) return { rowErrors: [], rowData: [] };
    // if (!result?.errors.some((e) => e.errors.length > 0)) return { rowErrors: [], rowData: [] };

    const rowErrorsInit = result.errors.map((record) => record.errors);

    const rowDataInit = result.errors.flatMap((record) => record.entity);

    const rowsWithDataAndErrors: { data: AbsenceImportDto; error: ValidationError[] }[] = [];
    for (let i = 0; i < Math.max(rowErrorsInit.length, rowDataInit.length); i++) {
      rowsWithDataAndErrors.push({ data: rowDataInit[i], error: rowErrorsInit[i] ?? [] });
    }

    rowsWithDataAndErrors.sort((a, b) => b.error.length - a.error.length);

    const rowErrors = rowsWithDataAndErrors.map((a) => a.error);
    const rowData = rowsWithDataAndErrors.map((a) => a.data);
    return { rowData, rowErrors };
  }, [result]);

  // // need to bring rows with errors on top of the table =>
  // const { rowErrors, rowData } = useMemo(() => {
  //   const weightMap = new Map<number, number>();
  //   for (let index = 0; index < rowErrorsInit.length; index++) weightMap.set(index, rowErrorsInit[index].length);
  //
  //   // const sortFn = (a, b) => weightMap.get(a) - b;
  //
  //   // rowErrorsInit.sort(sortFn);
  //   // rowDataInit.sort(sortFn);
  //   return { rowErrors: rowErrorsInit, rowData: rowDataInit };
  // }, [rowErrorsInit, rowDataInit]);

  const notifyPendingErrors = useCallback(() => {
    showMessage('We found some issues in your formatting. Please correct the mistakes and try again', 'info');
  }, [showMessage]);

  const notifyDataValid = useCallback(() => {
    showMessage('Your imported data looks good! Continue to finish import.', 'success');
  }, [showMessage]);

  const refreshPolicies = useCallback(async () => {
    if (refreshAbsencePolicies) {
      await refreshAbsencePolicies();
    }
  }, [refreshAbsencePolicies]);

  const hasErrors = useMemo(() => {
    return rowErrors.some((errArr) => errArr.length > 0);
  }, [rowErrors]);

  useEffect(() => {
    if (hasErrors) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, hasErrors]);

  const getErrorForRow = useCallback(
    (index: number) => {
      return rowErrors ? rowErrors[index] : [];
    },
    [rowErrors]
  );

  const absenceColumns = useMemo<ColumnDef<AbsenceImportDto, AbsenceImportDto>[]>(() => {
    return [
      {
        header: () => 'First name',
        accessorFn: (row) => row,
        id: 'firstName',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="firstName"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Last name',
        accessorFn: (row) => row,
        id: 'lastName',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="lastName"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Work Email',
        accessorFn: (row) => row,
        id: 'workEmail',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="workEmail"
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
      {
        header: () => 'Policy name',
        accessorFn: (row) => row,
        id: 'policyName',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="policyName"
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
      {
        header: () => 'Start',
        accessorFn: (row) => row,
        id: 'start',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="start"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'End',
        accessorFn: (row) => row,
        id: 'end',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="end"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Start hour',
        accessorFn: (row) => row,
        id: 'startHour',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="startHour"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'End hour',
        accessorFn: (row) => row,
        id: 'endHour',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="endHour"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Afternoon only',
        accessorFn: (row) => row,
        id: 'afternoonOnly',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="afternoonOnly"
            />
          );
        },
        maxSize: 80,
        minSize: 80,
      },
      {
        header: () => 'Morning only',
        accessorFn: (row) => row,
        id: 'morningOnly',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="morningOnly"
            />
          );
        },
        maxSize: 80,
        minSize: 80,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="status"
            />
          );
        },
      },
      {
        header: () => 'Notes',
        accessorFn: (row) => row,
        id: 'notes',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="notes"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Validation',
        accessorFn: (row) => row,
        id: 'validation',
        enableSorting: false,
        cell: (info: CellContext<AbsenceImportDto, AbsenceImportDto>) => {
          return (
            <ImportedAbsenceTableCell
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="validation"
              statusColumn={(rowErrors && rowErrors[info.row.index]?.length) ?? 0}
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
    ];
  }, [getErrorForRow, rowErrors]);

  const absenceMappingForValues = useCallback(
    (values: AbsenceImportDto, allValues: AbsenceImportDto[]) => {
      const foundIndex = allValues.findIndex((record) => record.id === values.id);
      const olderRecord = allValues[foundIndex];
      let mappedValues: AbsenceImportDto;

      const ddMMYYYStartValue = () => {
        if (!values.start) return '';
        if (isoDateAndTimeFormat.test(values.start)) return values.start;
        if (values.start.includes('T')) return values.start.split('T')[0];
        return ddMMYYYYToIsoDateString(values.start) ?? '';
      };

      const ddMMYYYEndValue = (): string => {
        if (!values.end) return '';
        if (isoDateAndTimeFormat.test(values.end)) return values.end;

        if (values.end.includes('T')) return values.end.split('T')[0];

        return ddMMYYYYToIsoDateString(values.end) ?? '';
      };

      const ddMMYYYValueForStart = ddMMYYYStartValue();
      const ddMMYYYValueForEnd = ddMMYYYEndValue();

      const policy = absencePolicies?.find((p) => p.id === values.policyId);
      mappedValues = {
        ...values,
        firstName: olderRecord.firstName,
        lastName: olderRecord.lastName,
        workEmail: olderRecord.workEmail,
        policyName: policy?.fullName ?? olderRecord.policyName,
        start: values.start && ddMMYYYValueForStart ? ddMMYYYValueForStart : '',
        end: values.end && ddMMYYYValueForEnd ? ddMMYYYValueForEnd : '',
        status: olderRecord.status,
        notes: olderRecord.notes,
        afternoonOnly: values.afternoonOnly === 'Yes',
        morningOnly: values.morningOnly === 'Yes',
      };
      return { mappedValues, foundIndex };
    },
    [absencePolicies]
  );

  const handleRowClick = (row: Row<AbsenceImportDto>) => {
    if (rowData)
      setRowBeingEdited((absenceMappingForValues(row.original, rowData).mappedValues as unknown) as AbsenceDto);
    setIsOpen(true);
  };

  const currentFormForEditing = useMemo(() => {
    const updateDatasetWithRecord = (
      values: AbsenceImportDto,
      policy: AbsencePolicyDto | undefined
    ): { updatedRecords: AbsenceImportDto[]; foundIndex: number } => {
      if (!rowData) return { updatedRecords: [], foundIndex: -1 };
      const updatedRow = { ...values };
      if (policy) updatedRow.policyName = policy.name;
      // required as CSV headers are not in line with DB columns
      const { mappedValues, foundIndex } = absenceMappingForValues(updatedRow, rowData);
      if (foundIndex < 0) return { updatedRecords: rowData, foundIndex: -1 };
      const updatedRecords = rowData ? [...rowData] : [];
      if (foundIndex >= 0) updatedRecords[foundIndex] = mappedValues;
      return { updatedRecords, foundIndex };
    };

    const importHandler = async (values: AbsenceImportDto, policy: AbsencePolicyDto | undefined) => {
      setLoading(true);
      const { updatedRecords, foundIndex } = updateDatasetWithRecord(values, policy);
      const validationResult = await validateAbsenceImport(
        updatedRecords as AbsenceImportDto[],
        allUsersIncludingTerminated,
        absencePolicies ?? [],
        foundIndex
      );
      onUpdateAndRevalidate(validationResult);
      setIsOpen(false);
      setLoading(false);
    };

    return (
      <AbsenceDrawer
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setRowBeingEdited(undefined);
        }}
        refresh={refreshPolicies}
        absence={rowBeingEdited}
        userId={rowBeingEdited?.userId}
        setIsAbsenceDrawerOpen={setIsOpen}
        usedForDataImport={true}
        importHandler={async (values: AbsenceImportDto, policy: AbsencePolicyDto | undefined) => {
          await importHandler(values, policy);
        }}
      />
    );
  }, [
    isOpen,
    refreshPolicies,
    rowBeingEdited,
    rowData,
    absenceMappingForValues,
    setLoading,
    allUsersIncludingTerminated,
    absencePolicies,
    onUpdateAndRevalidate,
  ]);

  const totalPendingErrors = useMemo(() => {
    return rowErrors?.flat()?.length ?? 0;
  }, [rowErrors]);

  return (
    <>
      <Box
        sx={{
          pt: spacing.p25,
          flex: 1,
          overflow: 'auto',
          borderTop: '1px solid',
          borderColor: themeColors.GreyLight,
        }}
      >
        <BasicTable rowData={rowData ?? []} columnData={absenceColumns} rowClick={handleRowClick} loading={loading} />
      </Box>
      <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {currentFormForEditing}
      </DrawerModal>
      <ButtonComponent
        sizeVariant="medium"
        colorVariant="primary"
        style={{ marginTop: '30px' }}
        disabled={loading}
        onClick={() => {
          if (hasErrors) {
            notifyPendingErrors();
          } else {
            completeImport();
          }
        }}
      >
        {totalPendingErrors ? 'Try again' : 'Continue'}
      </ButtonComponent>
    </>
  );
};
