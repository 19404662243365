import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsenceSettingsDto } from '@v2/feature/absence/absence.dto';
import { UpdateAbsenceSettings } from '@v2/feature/absence/absence.interface';
import { getPublicHolidayCalendarOptions, NoPublicHolidayCalendarOption } from '@v2/feature/absence/absence.util';
import { getOverlapsRuleOptions } from '@v2/feature/absence/company/policies/pages/absence-company-settings-general.page';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absenceSettings: AbsenceSettingsDto;
  readonly refresh: () => Promise<void>;
}

export const AbsenceSettingsEditDrawer = ({ isOpen, setIsOpen, absenceSettings, refresh }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);

  const formik = useFormik<UpdateAbsenceSettings>({
    initialValues: {
      holidayCountry:
        getPublicHolidayCalendarOptions().find((option) => option.value === absenceSettings.holidayCountry)?.value ??
        NoPublicHolidayCalendarOption.value,
      preventOwnOverlaps: absenceSettings.preventOwnOverlaps,
      showOverlap: absenceSettings.showOverlap,
      overlapsRule: absenceSettings.overlapsRule,
    },
    validationSchema: yup.object({
      holidayCountry: yup.string().required(polyglot.t('AbsenceGeneralSettings.holidayCalendarIsRequired')),
      showOverlap: yup.boolean().nullable().notRequired(),
      overlapsRule: yup.string().required(polyglot.t('AbsenceGeneralSettings.overlapsRuleRequired')),
    }),
    onSubmit: async (values: UpdateAbsenceSettings) => {
      try {
        setLoading(true);
        const update: UpdateAbsenceSettings = {
          holidayCountry: values.holidayCountry,
          preventOwnOverlaps: values.preventOwnOverlaps,
          showOverlap: values.showOverlap,
          overlapsRule: values.overlapsRule,
        };
        await AbsenceAPI.updateCompanyAbsenceSettings(update);
        showMessage(polyglot.t('AbsenceGeneralSettings.settingsUpdatedSuccessfully'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('AbsenceGeneralSettings.errorMessage', { nestError: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsenceCompanySettingsGeneralPage.other')}</Typography>

          <SelectComponent
            name="holidayCountry"
            label={polyglot.t('AbsenceGeneralSettings.defaultHolidayCountry')}
            options={getPublicHolidayCalendarOptions()}
            value={formik.values.holidayCountry}
            compareValue={formik.values.holidayCountry}
            error={!!formik.errors.holidayCountry && formik.touched.holidayCountry}
            onChange={formik.handleChange}
            helperText={formik.touched.holidayCountry && Boolean(formik.errors.holidayCountry)}
          />

          <Typography variant="title4">{polyglot.t('AbsenceGeneralSettings.requestingAbsence')}</Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="caption">{polyglot.t('AbsenceGeneralSettings.preventOwnOverlaps')}</Typography>
              <Typography variant="captionSmall" color="Grey">
                {polyglot.t('AbsenceGeneralSettings.preventOwnOverlapsDesc')}
              </Typography>
            </Box>
            <SwitchComponent
              checked={formik.values.preventOwnOverlaps}
              name="prevent-own-overlaps"
              onChange={(_e, enabled) => {
                formik.setFieldValue('preventOwnOverlaps', enabled);
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="caption">{polyglot.t('AbsenceGeneralSettings.overlapSummary')}</Typography>
              <Typography variant="captionSmall" color="Grey">
                {polyglot.t('AbsenceGeneralSettings.overlapSummaryDesc')}
              </Typography>
            </Box>
            <SwitchComponent
              checked={formik.values.showOverlap}
              name="show-overlap"
              onChange={(_e, enabled) => {
                formik.setFieldValue('showOverlap', enabled);
              }}
            />
          </Box>

          {formik.values.showOverlap && (
            <SelectComponent
              name="overlapsRule"
              label={polyglot.t('AbsenceGeneralSettings.showOverlapWith')}
              options={getOverlapsRuleOptions(polyglot)}
              value={formik.values.overlapsRule}
              error={!!formik.errors.overlapsRule && formik.touched.overlapsRule}
              onChange={formik.handleChange}
              helperText={formik.touched.overlapsRule && Boolean(formik.errors.overlapsRule)}
            />
          )}

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
