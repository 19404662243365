import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { ChipComponent } from '@v2/components/chip/chip.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { QuestionType } from '@v2/feature/growth/reviews/interfaces/review-question.interface';
import { GridDisplayBarV2 } from '@v2/feature/growth/shared/components/grid-display-bar.component';
import { SurveyQuestionGridLine } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-question-grid-line.component';
import {
  SurveyResultAnswerResponse,
  SurveyResultByQuestion,
  SurveyResultImpact,
  SurveyResultQuestionResponse,
} from '@v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Dictionary } from 'lodash';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
  readonly loading?: boolean;
  readonly widthPercentage?: number;
  readonly questionItem: SurveyResultByQuestion | null;
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly impactResult: SurveyResultImpact | undefined;
  readonly impactFilter: string;
  readonly measureByFilter: 'avg' | 'nps' | 'positive';
  readonly gridsXs: number[];
}

export const SurveyQuestionDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  questionItem,
  growthFactors,
  loading = false,
  widthPercentage = 95,
  impactResult,
  impactFilter,
  measureByFilter,
  gridsXs,
}: DrawerProps) => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      loading={loading}
      widthPercentage={widthPercentage}
    >
      {questionItem ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g48 }}>
          <Box>
            <Typography variant="title2">Question</Typography>
            <SurveyQuestionGridLine
              item={questionItem}
              growthFactors={growthFactors}
              impactResult={impactResult}
              impactFilter={impactFilter}
              measureByFilter={measureByFilter}
              gridsXs={gridsXs}
            />
          </Box>

          <Box>
            <Typography variant="title2">Answers</Typography>
            {questionItem.answersLowerThanThreshold ? (
              <Typography variant="caption" color="Grey" sx={{ mt: spacing.m16 }}>
                Not sufficient data to show results.
              </Typography>
            ) : (
              questionItem.answers &&
              questionItem.answers.map((a) => <SurveyAnswerComponent answer={a} question={questionItem.question} />)
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};

const SurveyAnswerComponent = ({
  answer,
  question,
}: {
  answer: SurveyResultAnswerResponse;
  question: SurveyResultQuestionResponse | null;
}) => {
  const scaleLabel = useMemo(() => {
    return answer.answer && question
      ? `${question.scaleConfig?.points?.[answer.answer]} (${
          question.scaleConfig?.value?.[answer.answer] || 0
        } out of ${Math.max(...Object.values(question.scaleConfig?.value || []).map(Number))})`
      : 'NA';
  }, [answer.answer, question]);

  if (!question) return <></>;
  return (
    <GridDisplayBarV2
      cells={[
        {
          content: (
            <Box sx={{ display: 'flex', width: '85%', flexDirection: 'column', gap: spacing.g8 }}>
              {answer.comment ? (
                <Typography variant="caption" dangerouslySetInnerHTML={{ __html: answer.comment ?? '' }} />
              ) : (
                <Typography variant="caption" color="Grey">
                  No comment provided
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
                <ChipComponent
                  name={
                    answer.participantId ? (
                      <UserCell
                        userId={answer.participantId}
                        nameVariant="caption"
                        nameSx={{ color: themeColors.Grey }}
                      />
                    ) : (
                      <Typography variant="caption" color="Grey">
                        Author hidden
                      </Typography>
                    )
                  }
                  backgroundColor="white"
                  border="background"
                />
              </Box>
            </Box>
          ),
          gridXs: 8,
        },
        {
          content: answer.answer ? (
            <>
              {question.type === QuestionType.ScaleQuestion ? (
                <Typography variant="caption" color="DarkGrey">
                  {scaleLabel}
                </Typography>
              ) : question.type === QuestionType.SingleAnswer ? (
                <Typography variant="caption" color="DarkGrey">
                  {answer ? question.choiceOptions?.[Number(answer.answer)] ?? 'NA' : 'NA'}
                </Typography>
              ) : question.type === QuestionType.MultipleAnswer ? (
                <Typography variant="caption" color="DarkGrey">
                  {answer && question.choiceOptions
                    ? answer.answer
                        .split(',')
                        .map((choice) => question.choiceOptions[Number(choice)])
                        .join(', ') ?? 'NA'
                    : 'NA'}
                </Typography>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          ),
          gridXs: 4,
          sx: { display: 'flex', justifyContent: 'flex-start' },
        },
      ]}
    />
  );
};
