import { useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';

import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PayrunUserHeader } from '@/v2/feature/payroll/components/payrun-user-header.component';
import { PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

type EditEmployerCostProps = {
  user: CachedUser;
  payrunEntry: PayRunEntryDto;
  payrunClosed: boolean;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
};

export const EditEmployerCostPage = ({ user, payrunEntry, payrunClosed, saveIncomeUpdates }: EditEmployerCostProps) => {
  const [savingUpdate, setSavingUpdate] = useState(false);

  const initialValues = useMemo(() => {
    return {
      income: payrunEntry.totals.additions,
      employerNi: payrunEntry.totals.employerNi,
      employerPension: payrunEntry.totals.employerPensionContribution,
    };
  }, [payrunEntry]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const setIfChanged = (key: 'employerNi' | 'employerPension') =>
        values[key] !== formik.initialValues[key] ? values[key] : undefined;

      const update: PayrunEntryIncomeUpdateDto = {
        id: payrunEntry.id,
        userId: user.userId,
        deductions: {
          employerNi: setIfChanged('employerNi'),
          employerPension: setIfChanged('employerPension'),
          paylines: [],
        },
      };
      setSavingUpdate(true);
      await saveIncomeUpdates([update]);
      setSavingUpdate(false);
    },
  });

  return (
    <>
      <Typography sx={{ ...themeFonts.title2 }}>Edit employer costs</Typography>
      <PayrunUserHeader user={user} sx={{ mt: spacing.m10 }} />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Stack sx={{ flex: 1, mt: spacing.m30, gap: spacing.g30 }}>
            <MoneyTextfieldComponent
              name="income"
              label="Income"
              value={formik.values.income}
              onChange={() => {}}
              disabled
              allowNegative
              emptyIsZero
            />
            <MoneyTextfieldComponent
              name="employerNi"
              label="Employer NI"
              value={formik.values.employerNi}
              onChange={(value) => formik.setFieldValue('employerNi', value)}
              disabled={savingUpdate || payrunClosed}
              allowNegative
              emptyIsZero
            />
            <MoneyTextfieldComponent
              name="employerPension"
              label="Employer pension"
              value={formik.values.employerPension}
              onChange={(value) => formik.setFieldValue('employerPension', value)}
              disabled={savingUpdate || payrunClosed}
              allowNegative
              emptyIsZero
            />
          </Stack>
          {payrunClosed && (
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m30 }}>
              Employer costs cannot be changed because the payrun is closed.
            </Typography>
          )}
          <Stack sx={{ mt: spacing.m30, flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ ...themeFonts.title4 }}>Total cost</Typography>
            <Typography sx={{ ...themeFonts.title4 }}>{formatCurrency(payrunEntry.totals.totalCost)}</Typography>
          </Stack>
          {!payrunClosed && (
            <LoaderButton
              fullWidth
              loading={savingUpdate}
              name="Save"
              type="submit"
              sizeVariant="large"
              colorVariant="primary"
              style={{ marginTop: spacing.m30 }}
            />
          )}
        </Form>
      </FormikProvider>
    </>
  );
};
