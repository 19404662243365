import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface EditPolicyMembersDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const EditPolicyMembersDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: EditPolicyMembersDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <EditPolicyMembersDrawerContent absencePolicy={absencePolicy} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface EditPolicyMembersDrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const EditPolicyMembersDrawerContent = ({
  absencePolicy,
  refresh,
  setIsOpen,
}: EditPolicyMembersDrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState<UserSelectFiltersOptions>(
    absencePolicy.membersRule ?? UserSelectFiltersOptions.None
  );
  const [customRule, setCustomRule] = useState<string>(absencePolicy.customRule ?? '');
  const [selectedUsers, setSelectedUsers] = useState<number[]>(absencePolicy.selectedMembersIds ?? []);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (membersRule: UserSelectFiltersOptions, customRule: string | null, selectedMembersIds: number[]) => {
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyMembers(absencePolicy.id, {
          membersRule,
          customRule,
          selectedMembersIds,
        });

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          `${polyglot.t('EditPolicyMembersDrawerContent.errorMessages.updateFailure')}. ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [absencePolicy.id, refresh, setIsOpen, showMessage, polyglot]
  );

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('EditPolicyMembersDrawerContent.editMembers')}</Typography>

      <UserSelect
        label={polyglot.t('EditPolicyMembersDrawerContent.whoSelect')}
        selectedLabel="Selected employees"
        value={selectedUsers}
        onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
          setSelectedUsers([...userIds]);
          setValidationMessage('');
          setSelectedOption(filterValue ?? UserSelectFiltersOptions.None);
          setCustomRule(customRule ?? '');
        }}
        fieldSx={{ ...spacing.mb20 }}
        error={!!validationMessage}
        helperText={!!validationMessage && validationMessage}
        initialFilterValue={absencePolicy.membersRule ?? UserSelectFiltersOptions.None}
        ruleString={customRule}
      />

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          name={polyglot.t('General.save')}
          type="button"
          onClick={async () => {
            if (selectedOption === UserSelectFiltersOptions.None)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOption'));
            else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOneUser'));
            else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.enterCustomRule'));
            else {
              const membersRule = selectedOption ?? UserSelectFiltersOptions.Everyone;
              const selectedCustomRule = selectedOption === UserSelectFiltersOptions.CustomRule ? customRule : null;
              await onSubmit(membersRule, selectedCustomRule, selectedUsers);
            }
          }}
          loading={loading}
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
        />
      </Box>
    </Box>
  );
};
