import { Dispatch, SetStateAction, Suspense, useCallback, useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { TabFilterButtons } from '@v2/components/tab-filter-buttons.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { TabFilter } from '@v2/feature/reports/features/create-report/people/people-report-type.component';
import { DateType, ReportConfig } from '@v2/feature/reports/reports.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';

export type ReportDates = Pick<ReportConfig, 'dateType' | 'start' | 'end'>;

interface UpdateReportDatesDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly reportDates: ReportDates;
  readonly action: (dates: ReportDates) => Promise<void>;
}

export const UpdateReportDatesDrawer = ({ isOpen, setIsOpen, reportDates, action }: UpdateReportDatesDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box>
          <UpdateReportDatesDrawerContent setIsOpen={setIsOpen} reportDates={reportDates} action={action} />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface UpdateReportDatesDrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly reportDates: ReportDates;
  readonly action: (dates: ReportDates) => Promise<void>;
}

const UpdateReportDatesDrawerContent = ({ setIsOpen, reportDates, action }: UpdateReportDatesDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [reportUpdate, setReportUpdate] = useState<{
    dateType: DateType | undefined;
    start: string | undefined;
    end: string | undefined;
  }>({
    dateType: reportDates.dateType,
    start: reportDates.start,
    end: reportDates.end,
  });
  const [filterString, setFilterString] = useState<string>(reportDates.dateType ?? '');

  const executeAction = useCallback(
    async (datesUpdate: { dateType: DateType | undefined; start: string | undefined; end: string | undefined }) => {
      setLoading(true);
      await action(datesUpdate);
      setIsOpen(false);
      setLoading(false);
    },
    [action, setIsOpen]
  );

  return (
    <Box>
      <Typography sx={titleSx}>{polyglot.t('UpdateReportDatesDrawerContent.edit')}</Typography>

      <Box sx={{ mt: spacing.m10 }}>
        <TabFilterButtons
          filters={TabFilter(polyglot)}
          setFilterValue={setFilterString}
          filterValue={filterString}
          onFilterChange={({ filterValue }) => {
            if (filterValue === DateType.Last30days) {
              setReportUpdate((prev) => ({
                ...prev,
                start: new LocalDate(subDays(new Date(), 30)).toDateString(),
                end: new LocalDate().toDateString(),
                dateType: DateType.Last30days,
              }));
            } else if (filterValue === DateType.Last7days) {
              setReportUpdate((prev) => ({
                ...prev,
                start: new LocalDate(subDays(new Date(), 7)).toDateString(),
                end: new LocalDate().toDateString(),
                dateType: DateType.Last7days,
              }));
            } else {
              setReportUpdate((prev) => ({
                ...prev,
                dateType: DateType.SelectDates,
              }));
            }

            setFilterString(filterValue);
          }}
        />
      </Box>

      {filterString === DateType.SelectDates && (
        <Box sx={{ ...spacing.mt20 }}>
          <Box sx={fieldSx}>
            <FormControl size="small" fullWidth>
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                value={reportUpdate.start}
                onChange={(value) => {
                  if (dayjs(value).isValid()) {
                    setReportUpdate((prev) => ({ ...prev, start: value }));
                  }
                }}
                name="startDate"
                label={polyglot.t('UpdateReportDatesDrawerContent.startDate')}
              />
            </FormControl>
          </Box>

          <Box sx={fieldSx}>
            <FormControl size="small" fullWidth>
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                value={reportUpdate.end}
                onChange={(value) => {
                  if (dayjs(value).isValid()) {
                    setReportUpdate((prev) => ({ ...prev, end: value }));
                  }
                }}
                name="endDate"
                label={polyglot.t('UpdateReportDatesDrawerContent.endDate')}
              />
            </FormControl>
          </Box>
        </Box>
      )}

      <Box sx={{ ...buttonBoxSx, mt: spacing.m40 }}>
        <LoaderButton
          name={polyglot.t('General.save')}
          onClick={async () => executeAction(reportUpdate)}
          loading={loading}
          sizeVariant="large"
          colorVariant="primary"
          fullWidth
        />
      </Box>
    </Box>
  );
};
