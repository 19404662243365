import React, { useState } from 'react';

import { Box, Dialog, DialogContent, FormControl, Stack } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  definitionListSx,
  drawerContentSx,
} from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { OauthClientAPI } from '@/api-client/oauth-client.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import {
  NonSensitiveOauthClientConfiguration,
  SensitiveOauthClientConfiguration,
} from '@/v2/feature/security/security-settings/security.interface';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { iconSize } from '@/v2/styles/menu.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface NewClientInformationDialogProps {
  clientInfo: SensitiveOauthClientConfiguration;
  visible: boolean;
  onClose: () => void;
}

export const NewClientInformationDialog = ({
  clientInfo,
  visible,
  onClose,
}: NewClientInformationDialogProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  // const domain = window.location.origin;

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      sx={{ margin: 'auto', height: '300px', overflowX: 'hidden', width: '680px', borderRadius: '20px' }}
      PaperProps={{ sx: { height: '300px', overflowX: 'hidden', width: '680px', borderRadius: '20px' } }}
      maxWidth="md"
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: '100%',
            height: '100%',
          }}
        >
          <Stack direction="column">
            <Box component="dl" sx={definitionListSx}>
              {clientInfo?.id && (
                <FieldStructure
                  fieldTitle={polyglot.t('DeveloperHub.appId')}
                  fieldValue={
                    clientInfo?.id && {
                      isLink: true,
                      label: truncateWithEllipses(clientInfo.id, 50),
                      icon: <Copy {...iconSize} />,
                      onClick: () => navigator.clipboard.writeText(clientInfo.id),
                    }
                  }
                  fieldStub="id"
                />
              )}
            </Box>
            <Box component="dl" sx={definitionListSx}>
              {clientInfo?.clientId && (
                <FieldStructure
                  fieldTitle={polyglot.t('DeveloperHub.clientId')}
                  fieldValue={
                    clientInfo?.id && {
                      isLink: true,
                      label: truncateWithEllipses(clientInfo.clientId, 50),
                      icon: <Copy {...iconSize} />,
                      onClick: () => navigator.clipboard.writeText(clientInfo.clientId),
                    }
                  }
                  fieldStub="clientId"
                />
              )}
            </Box>
            <Box component="dl" sx={definitionListSx}>
              {clientInfo?.clientSecret && (
                <FieldStructure
                  fieldTitle={polyglot.t('DeveloperHub.clientSecret')}
                  fieldValue={
                    clientInfo?.clientSecret && {
                      isLink: true,
                      label: truncateWithEllipses(clientInfo.clientSecret, 50),
                      icon: <Copy {...iconSize} />,
                      onClick: () => navigator.clipboard.writeText(clientInfo.clientSecret),
                    }
                  }
                  fieldStub="clientSecret"
                />
              )}
            </Box>
            <ButtonComponent fullWidth colorVariant="primary" sizeVariant="medium" onClick={() => onClose()}>
              {polyglot.t('General.close')}
            </ButtonComponent>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface DeveloperHubClientGenerateDrawerProps {
  isOpen: boolean;
  readonly existingClient: NonSensitiveOauthClientConfiguration | undefined;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly onUpdate: () => void;
}

export const DeveloperHubClientGenerateDrawer = ({
  isOpen,
  existingClient,
  setIsOpen,
  onClose,
  onUpdate,
}: DeveloperHubClientGenerateDrawerProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<SensitiveOauthClientConfiguration | undefined>(undefined);

  const formik = useFormik<Partial<NonSensitiveOauthClientConfiguration>>({
    initialValues: {
      name: undefined,
      description: undefined,
      redirectionUri: undefined,
      // logo: undefined,
      scopes: [],
      ...existingClient,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      redirectionUri: Yup.string().nullable().required('Required'),
      scopes: Yup.array().nullable().required('Required'),
    }),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      console.info('££££ values: ', values);
      // handleSubmit(values);
    },
  });

  const generateClient = async () => {
    try {
      const { name, description, scopes, redirectionUri } = formik.values;
      if (!name || !description || !scopes || !redirectionUri) return;
      const newClient = await OauthClientAPI.createNewClient(name, description, redirectionUri, scopes);
      if (newClient) {
        setClientInfo(newClient);
        setDialogOpen(true);
      }
    } catch (error) {
      showMessage(
        polyglot.t('DeveloperHubClientGenerateDrawer.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const updateClient = async () => {
    try {
      const { clientId, id, name, description, scopes, redirectionUri } = formik.values;
      if (!clientId || !id || !name || !description || !scopes || !redirectionUri) return;
      await OauthClientAPI.updateExistingClient(clientId, id, name, description, redirectionUri, scopes);
      showMessage(polyglot.t('DeveloperHubClientGenerateDrawer.successMessages.update'), 'success');
      onUpdate();
      onClose();
    } catch (error) {
      showMessage(
        polyglot.t('DeveloperHubClientGenerateDrawer.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('DeveloperHubClientGenerateDrawer.title')}</Typography>
          <Typography variant="caption">
            {polyglot.t('DeveloperHubClientGenerateDrawer.subtitle')}
            <b>{polyglot.t('DeveloperHubClientGenerateDrawer.once')}</b>.
          </Typography>

          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="name"
              label={polyglot.t('DeveloperHub.clientTable.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && (formik.errors.name as string)}
              endAdornment="none"
              fullWidth
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="description"
              label={polyglot.t('DeveloperHub.clientTable.description')}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && (formik.errors.description as string)}
              endAdornment="none"
              fullWidth
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="redirectionUri"
              label={polyglot.t('DeveloperHub.clientTable.redirectionUri')}
              value={formik.values.redirectionUri}
              onChange={formik.handleChange}
              error={formik.touched.redirectionUri && !!formik.errors.redirectionUri}
              helperText={formik.touched.redirectionUri && (formik.errors.redirectionUri as string)}
              endAdornment="none"
              fullWidth
            />
          </FormControl>
          {/* scopes disabled for now */}
          {/* <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                <MultipleSelectCheckbox
                  id="scope-select"
                  limitTags={5}
                  options={integrationScopeListOptions}
                  value={selectedScopes ?? []}
                  onChange={(_, values) => {
                    const scopeValues = values.map((eachScope) => eachScope.value);
                    formik.setFieldValue('scopes', scopeValues, true);
                  }}
                  isOptionEqualToValue={(x, y) => x.value === y.value}
                  getOptionLabel={({ label }: { label: string }): string => label}
                  label="Scopes"
                />
              </FormControl> */}
          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent
              fullWidth
              colorVariant={existingClient ? 'secondary' : 'primary'}
              sizeVariant="medium"
              onClick={async () => {
                if (!existingClient) await generateClient();
                else await updateClient();
              }}
              disabled={!formik.isValid}
            >
              {polyglot.t(existingClient ? 'DeveloperHub.updateClient' : 'DeveloperHub.generate')}
            </ButtonComponent>
          </Box>

          {clientInfo && (
            <NewClientInformationDialog
              clientInfo={clientInfo}
              visible={dialogOpen}
              onClose={() => {
                setDialogOpen(false);
                onClose();
                onUpdate();
              }}
            />
          )}
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
