import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';

import { CalculationMethodMonth1, CalculationMethodStandard } from '@/lib/payroll';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { definitionListSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { booleanValues } from '@/v2/util/array.util';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { toTitleCase } from '@/v2/util/string.util';

type UserProfileTaxDetailsUKProps = {
  payrollId: string;
  payrollValues: UKPayrollValues;
};

export const UserProfileTaxDetailsUK = ({ payrollValues, payrollId }: UserProfileTaxDetailsUKProps): JSX.Element => {
  const formatStudentLoans = useCallback((record: UKPayrollValues) => {
    const combinedLoans = [];
    if (record.studentLoan) combinedLoans.push(`${record.studentLoan}`);
    if (record.postgradLoan) combinedLoans.push(`Postgraduate`);
    return combinedLoans.length > 0 ? combinedLoans.join(', ') : undefined;
  }, []);

  const combinedStudentLoanAndPostgradLoan = useMemo(() => payrollValues && formatStudentLoans(payrollValues), [
    payrollValues,
    formatStudentLoans,
  ]);

  const formatDirectorshipStint = useCallback((record: UKPayrollValues) => {
    let directorshipDates;
    if (record?.directorFrom && record?.directorTo)
      directorshipDates = `(${dateAPItoDisplay(record?.directorFrom)} - ${dateAPItoDisplay(record?.directorTo)})`;
    if (record?.directorFrom && !record?.directorTo)
      directorshipDates = `(${dateAPItoDisplay(record?.directorFrom)} - ?)`;
    return directorshipDates;
  }, []);

  const directorshipStint = useMemo(() => payrollValues && formatDirectorshipStint(payrollValues), [
    payrollValues,
    formatDirectorshipStint,
  ]);

  return (
    <Box component="dl" sx={definitionListSx}>
      {payrollId && <FieldStructure fieldTitle="Payroll ID" fieldValue={payrollId} />}
      {payrollValues.taxCode && <FieldStructure fieldTitle="Tax code" fieldValue={payrollValues.taxCode} />}
      {booleanValues.has(payrollValues.week1Month1) && (
        <FieldStructure
          fieldTitle="Calculation method"
          fieldValue={payrollValues.week1Month1 ? CalculationMethodMonth1 : CalculationMethodStandard}
        />
      )}
      {payrollValues.starterDeclaration && (
        <FieldStructure fieldTitle="Starter declaration" fieldValue={payrollValues.starterDeclaration} />
      )}
      {payrollValues.niTable && <FieldStructure fieldTitle="NI category" fieldValue={payrollValues.niTable} />}
      {payrollValues.niNumber && <FieldStructure fieldTitle="NI number" fieldValue={payrollValues.niNumber} />}
      {combinedStudentLoanAndPostgradLoan && combinedStudentLoanAndPostgradLoan !== 'None' && (
        <FieldStructure fieldTitle="Student Loan" fieldValue={combinedStudentLoanAndPostgradLoan} />
      )}
      {payrollValues.legalGender && (
        <FieldStructure fieldTitle="Legal gender" fieldValue={toTitleCase(payrollValues.legalGender)} />
      )}
      {payrollValues.isDirector && <FieldStructure fieldTitle="Directorship" fieldValue={`Yes ${directorshipStint}`} />}
    </Box>
  );
};
