import { useState } from 'react';

import { Box, Button, IconButton, Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import Polyglot from 'node-polyglot';
import { v4 } from 'uuid';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CustomProfileFormAPI } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import {
  CustomProfileFieldDto,
  CustomProfileFieldType,
  CustomProfileFormDto,
} from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { OptionObjectKey } from '@/v2/feature/custom-fields/custom-profile-fields.interface';
import { sortSelectOptions } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { cleanPayCodeValue } from '@/v2/feature/payroll/features/payroll-global/global-payroll.util';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface DrawerProps {
  form: CustomProfileFormDto;
  fieldType: CustomProfileFieldType;
  selectedField: CustomProfileFieldDto | undefined;
  onClose: () => Promise<void>;
}

const getFieldByType = (
  fieldType: CustomProfileFieldType,
  field: CustomProfileFieldDto,
  setField: React.Dispatch<React.SetStateAction<CustomProfileFieldDto>>,
  options: OptionObjectKey[],
  setOptions: React.Dispatch<React.SetStateAction<OptionObjectKey[]>>,
  polyglot: Polyglot
) => {
  switch (fieldType) {
    case 'Input':
    case 'Number':
    case 'Date picker':
    case 'Date input':
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          <TextfieldComponent
            value={field.fieldName}
            label={polyglot.t('getFieldByType.label', { type: field.fieldType })}
            onChange={(e) => {
              const allFieldsUpdate = { ...field, fieldName: e.target.value };
              setField(allFieldsUpdate);
            }}
            autoFocus
          />
        </Box>
      );

    case 'Select':
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g40 }}>
          <TextfieldComponent
            value={field.fieldName}
            label={polyglot.t('getFieldByType.label', { type: field.fieldType })}
            onChange={(e) => {
              const allFieldsUpdate = { ...field, fieldName: e.target.value };
              setField(allFieldsUpdate);
            }}
            autoFocus
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g40 }}>
            {sortSelectOptions(options).map((o) => (
              <Box key={o.key} sx={{ display: 'flex', alignItems: 'center', gap: spacing.g20 }}>
                <TextfieldComponent
                  value={o.value}
                  label={o.key}
                  onChange={(e) => {
                    const currentOpt: OptionObjectKey = { label: e.target.value, value: e.target.value, key: o.key };
                    const opts: OptionObjectKey[] = sortSelectOptions(
                      options.filter((opt) => opt.key !== o.key).concat(currentOpt)
                    );
                    const allFieldsUpdate = { ...field, options: { allOptions: opts } };
                    setField(allFieldsUpdate);
                    setOptions(opts);
                  }}
                />

                <IconButton
                  sx={tableIconButtonSx}
                  onClick={() => {
                    const opts: OptionObjectKey[] = sortSelectOptions(options.filter((opt) => opt.key !== o.key));
                    const allFieldsUpdate = { ...field, options: { allOptions: [...opts] } };
                    setField(allFieldsUpdate);
                    setOptions([...opts]);
                  }}
                >
                  <Trash {...iconSize} />
                </IconButton>
              </Box>
            ))}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              sx={secondaryCTABtn}
              onClick={() =>
                setOptions([
                  ...options,
                  { label: 'Next Option', value: 'Next Option', key: `Answer ${options.length + 1}` },
                ])
              }
            >
              <Plus {...iconSize} /> &nbsp;{polyglot.t('getFieldByType.addAnswer')}
            </Button>
          </Box>
        </Box>
      );
  }
};

export const CustomFieldDrawer = ({ form, fieldType, selectedField, onClose }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [linkToPayroll, setLinkToPayroll] = useState(!!selectedField?.paycode);

  const [loading, setLoading] = useState<boolean>(false);
  const [field, setField] = useState<CustomProfileFieldDto>(
    selectedField ?? {
      fieldType: fieldType,
      isRequired: false,
      fieldName: '',
      fieldId: v4(),
      formId: form.formId,
      companyId: form.companyId,
      placeholder: '',
      defaultValue: '',
      isHidden: false,
      isDefault: false,
      fieldStub: null,
      isEditable: true,
      options: {
        allOptions: [],
      },
      paycode: null,
      displayOrder: null,
    }
  );
  const [showMessage] = useMessage();
  const [options, setOptions] = useState<OptionObjectKey[]>(
    selectedField && selectedField?.options
      ? (selectedField?.options?.allOptions as OptionObjectKey[])
      : [
          { label: polyglot.t('CustomFieldDrawer.optionA'), value: 'Option A', key: 'Answer 1' },
          { label: 'Option B', value: polyglot.t('CustomFieldDrawer.optionA'), key: 'Answer 2' },
        ]
  );

  const saveField = async () => {
    setLoading(true);
    try {
      await CustomProfileFormAPI.addFields({
        ...field,
        paycode: (linkToPayroll && field.paycode) || null,
        options: field.fieldType === 'Select' ? field.options : { allOptions: [] },
      });
      showMessage(
        `${
          selectedField
            ? polyglot.t('CustomFieldDrawer.successMessages.updated')
            : polyglot.t('CustomFieldDrawer.successMessages.added')
        } ${polyglot.t('CustomFieldDrawer.successMessages.addedField')}`,
        'success'
      );
      onClose();
    } catch (error) {
      showMessage(`${polyglot.t('CustomFieldDrawer.errorMessages.notAdded')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteField = async () => {
    try {
      if (selectedField) {
        await CustomProfileFormAPI.deleteField(selectedField?.fieldId);
        showMessage(`${polyglot.t('CustomFieldDrawer.successMessages.deleted')}`, 'success');
      }
      onClose();
    } catch (error) {
      showMessage(`${polyglot.t('CustomFieldDrawer.errorMessages.notAdded')}. ${nestErrorMessage(error)}`, 'error');
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Box>
        <Typography variant="title2" sx={{ textTransform: selectedField ? 'inherit' : 'capitalize' }}>
          {selectedField ? selectedField.fieldName : polyglot.t('CustomFieldDrawer.addField')}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CheckboxComponent
          label={polyglot.t('CustomFieldDrawer.hideField')}
          name="hidden"
          checked={field.isHidden}
          disabled={field.isRequired}
          value="hidden"
          onChange={(_, checked) => {
            const allFieldsUpdate = { ...field, isHidden: checked };
            setField(allFieldsUpdate);
          }}
        />
        <CheckboxComponent
          label={polyglot.t('CustomFieldDrawer.requiredField')}
          name="required"
          checked={field.isRequired}
          disabled={field.isHidden}
          value="required"
          onChange={(_, checked) => {
            const allFieldsUpdate = { ...field, isRequired: checked };
            setField(allFieldsUpdate);
          }}
        />
      </Box>

      <Box>{!field.isDefault && getFieldByType(fieldType, field, setField, options, setOptions, polyglot)}</Box>

      {form.formName === 'salary' && fieldType === 'Number' && (
        // allow custom salary number fields to link to pay codes
        <Stack sx={{ gap: spacing.g3 }}>
          <CheckboxComponent
            label={polyglot.t('CustomFieldDrawer.linkFieldValueToPaycode')}
            name="linkToPayroll"
            checked={linkToPayroll}
            onChange={(_, checked) => setLinkToPayroll(checked)}
          />
          {linkToPayroll && (
            <TextfieldComponent
              name="paycode"
              label={polyglot.t('CustomFieldDrawer.paycode')}
              value={field.paycode ?? ''}
              onChange={(e) => setField({ ...field, paycode: cleanPayCodeValue(e.target.value) || null })}
            />
          )}
        </Stack>
      )}

      <Box sx={buttonBoxDrawerSx}>
        {selectedField && !field.isDefault && (
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={deleteField} fullWidth={true}>
            {polyglot.t('CustomFieldDrawer.deleteField')}
          </ButtonComponent>
        )}
        <LoaderButton
          sizeVariant="medium"
          colorVariant="primary"
          onClick={saveField}
          name={polyglot.t('General.save')}
          loading={loading}
          fullWidth={true}
        />
      </Box>
    </Box>
  );
};
