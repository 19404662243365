import React from 'react';

import { Box } from '@mui/material';
import { PaginationState } from '@tanstack/react-table';

import { ListView } from '@/v2/components/list-view/list-view.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface WeeklyListSectionComponentProps {
  readonly searchInput: string;
  readonly setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  readonly setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
  readonly pagination?: PaginationState;
  readonly totalPages?: number;
  readonly onPageChange?: (pageIndex: number, pageSize: number) => void;
  readonly loading: boolean;
  readonly weekList: {
    id: string;
    name: string;
    details: string;
  }[];
  readonly handleWeekClick: (weekAndYear: string) => void;
  readonly selectedWeekAndYear: string | undefined;
  readonly totalItems: number;
}

export const AttendanceWeeklyListSection = ({
  searchInput,
  setSearchInput,
  setPagination,
  pagination,
  totalPages,
  onPageChange,
  loading,
  weekList,
  handleWeekClick,
  selectedWeekAndYear,
  totalItems,
}: WeeklyListSectionComponentProps) => {
  return (
    <Box sx={spacing.pt20}>
      <Box sx={{ ...spacing.px20, pb: spacing.p10 }}>
        <TableSearch
          query={searchInput}
          style={{ width: '90%' }}
          handleChange={(e) => {
            setSearchInput(e.target.value?.trim() ?? '');
            if (pagination && setPagination) setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
          }}
        />
      </Box>
      <ListView
        list={weekList}
        pagination={
          pagination && onPageChange
            ? {
                pageSize: pagination.pageSize,
                pageIndex: pagination.pageIndex,
                total: totalPages ?? 1,
                totalItems: totalItems,
                onPageChange: (pageIndex, pageSize) => onPageChange(pageIndex, pageSize),
              }
            : undefined
        }
        clickAction={(weekNo) => handleWeekClick(weekNo as string)}
        loading={loading}
        hideCicularLoader={true}
        selected={selectedWeekAndYear}
      />
    </Box>
  );
};
