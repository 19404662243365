import { useContext, useMemo } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, DEVICES_ME_DEVICE_SECURITY_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { IconContentActionCard } from '@/v2/components/theme-components/icon-content-action-card.component';
import {
  AppliedDevicePoliciesDto,
  DevicePossessionDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
} from '@/v2/feature/device/device.dto';
import { getModelImage, getRiskStatus } from '@/v2/feature/device/device.util';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MyDevicesActive = ({
  devicePossessions,
  appliedZeltPolicies,
  appliedZeltMobilePolicies,
  appliedHexPolicies,
  userId,
}: {
  devicePossessions: readonly DevicePossessionDto[];
  appliedZeltPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature;
  appliedZeltMobilePolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature;
  appliedHexPolicies: AppliedDevicePoliciesDto;
  userId: number;
}) => {
  const [globalState] = useContext(GlobalContext);
  const currentUserId = globalState.user.userId;

  const hasDevicesAll = useMemo(
    () => checkScopes(globalState.user, ['devices:all'], { userId: globalState.user.userId }),
    [globalState.user]
  );
  // Use this when we'll have the devices team view
  // const hasDevicesManager = useMemo(() => checkScope(globalState.user, 'devices:manager', { userId }), [
  //   globalState.user,
  //   userId,
  // ]);

  const routerHistory = useHistory();
  return (
    <Box>
      <Typography sx={themeFonts.title2}>Active</Typography>
      <Box sx={{ ...spacing.mt20 }}>
        {devicePossessions.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
            {devicePossessions.map((d, idx) => (
              <IconContentActionCard
                iconMedia={
                  d &&
                  getModelImage(d?.device?.type, d?.device?.modelName, {
                    width: '150px',
                    height: 'auto',
                  })
                }
                title={d.device?.modelName}
                content={
                  <Box sx={{ mt: spacing.m5, display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                    {d.device?.lastCheckIn && (
                      <Typography sx={themeFonts.caption}>
                        Last active about {formatDistanceToNowStrict(new Date(d.device.lastCheckIn))} ago
                      </Typography>
                    )}

                    {d.device?.type && !d.device.mdmable && (
                      <Typography sx={themeFonts.caption}>{d.device?.type}</Typography>
                    )}
                    {d.device?.serialNumber && (
                      <Typography sx={themeFonts.caption}>{d.device?.serialNumber}</Typography>
                    )}
                    <Typography sx={themeFonts.caption}>
                      {getRiskStatus(d, appliedZeltPolicies, appliedZeltMobilePolicies, appliedHexPolicies)}
                    </Typography>
                  </Box>
                }
                action={
                  userId === currentUserId || hasDevicesAll ? (
                    <Button
                      sx={secondaryCTABtn}
                      onClick={() => {
                        if (userId === currentUserId)
                          routerHistory.push(
                            generatePath(DEVICES_ME_DEVICE_SECURITY_ROUTE, { devicePossessionId: d.id })
                          );
                        // else if (hasDevicesManager)
                        //   routerHistory.push(
                        //     generatePath(DEVICES_TEAM_DEVICE_DETAILS_OVERVIEW_ROUTE, { devicePossessionId: d.id })
                        //   );
                        else if (hasDevicesAll)
                          routerHistory.push(
                            generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, { devicePossessionId: d.id })
                          );
                      }}
                    >
                      Details
                    </Button>
                  ) : null
                }
                showDivider={idx < devicePossessions.length - 1}
              />
            ))}
          </Box>
        ) : (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            There are no active possessions at the moment
          </Typography>
        )}
      </Box>
    </Box>
  );
};
