import { InstalledAppDto } from '@v2/feature/app-integration/app-integration.dto';
import {
  AppConfigurationDto,
  AppCredentialDto,
} from '@v2/feature/app-integration/features/app-details/app-details.dto';
import axios from 'axios';

import {
  AppIntegrationStub,
  AppKPI,
  SSOMetadata,
  SSOState,
} from '@/v2/feature/app-integration/app-integration.interface';
import { OnboardingAutomaticConfiguration } from '@/v2/feature/app-integration/features/app-details/components/app-details-automatic-onboarding-configuration.component';
import { OAuthConfigurationForBasicAuthApp } from '@/v2/feature/app-integration/features/app-details/components/app-details-oauth-with-basic.component';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class AppDetailsAPI {
  static getIntegrationData = async (appStub: string): Promise<InstalledAppDto> => {
    return (await axios.get(`/apiv2/apps/${appStub}`)).data;
  };

  static saveOrUpdateIntegrationCredentials = async (appStub: string, credentials: AppCredentialDto) => {
    await axios.post(`/apiv2/apps/${appStub}/credentials/upsert`, { credentials });
  };

  static testBasicAuthCredentials = async (
    appStub: string,
    credentials: AppCredentialDto
  ): Promise<boolean | Record<string, unknown> | undefined> => {
    return (await axios.post(`/apiv2/apps/${appStub}/test-basic-auth`, { credentials })).data;
  };

  static saveIntegrationConfiguration = async (appStub: string, configuration: AppConfigurationDto) => {
    await axios.post(`/apiv2/apps/${appStub}/credentials/configuration`, configuration);
  };

  static generateCredentials = async (appStub: string) => {
    await axios.post(`/apiv2/apps/${appStub}/credentials/generate-saml-credentials`);
  };

  static fetchConfig = async (appStub: string) => {
    return (await axios.get(`/apiv2/apps/${appStub}/credentials/saml-certificate`)).data;
  };

  static getSSOSamlMetadata = async (appStub: string): Promise<SSOMetadata> => {
    return (await axios.get(`/apiv2/apps/${appStub}/credentials/saml-metadata`)).data;
  };

  static getSSOMetadata = async (): Promise<SSOState[]> => {
    return (await axios.get(`/apiv2/apps/credentials/sso-metadata`)).data;
  };

  static getOnboardingAutomaticConfiguration = async (appStub: string): Promise<OnboardingAutomaticConfiguration> => {
    return (await axios.get(`/apiv2/apps/${appStub}/onboarding/configuration`)).data;
  };

  static updateOnboardingTemplateSelectionForWebhook = async (
    appStub: string,
    payload: { selected: number; enabled: boolean }
  ): Promise<OnboardingAutomaticConfiguration> => {
    return (await axios.patch(`/apiv2/apps/${appStub}/onboarding/configuration`, payload)).data;
  };

  static saveSSOSamlMetadata = async (
    appStub: AppIntegrationStub,
    payload: { sso_saml_metadata: string; disable_mfa: boolean }
  ) => {
    await axios.post(`/apiv2/apps/${appStub}/credentials/saml-metadata/upsert`, payload);
  };

  static saveSSOktaMetadata = async (payload: { sso_oidc_metadata: string; disable_mfa: boolean }) => {
    await axios.post(`/apiv2/apps/sso-okta/credentials/oidc-metadata/upsert`, payload);
  };

  static disableSSOOkta = async () => {
    await axios.post(`/apiv2/apps/sso-okta/credentials/oidc/disable`);
  };

  static disableSSOGW = async () => {
    await axios.post(`/apiv2/apps/sso-google-workspace/credentials/saml/disable`);
  };

  static disableSSOSaml = async (appStub: AppIntegrationStub) => {
    await axios.post(`/apiv2/apps/${appStub}/credentials/saml/disable`);
  };

  static superAdminDisableSSOOkta = async (companyId: number) => {
    await axios.post(`/apiv2/apps/sso-okta/credentials/oidc/superadmin/disable/${companyId.toString()}`);
  };

  static superadminDisableSSOGW = async (companyId: number) => {
    await axios.post(`/apiv2/apps/sso-google-workspace/credentials/saml/superadmin/disable/${companyId.toString()}`);
  };

  static superAdminDisableSSOAzureAdSaml = async (companyId: number) => {
    await axios.post(`/apiv2/apps/sso-azure-ad/credentials/saml/superadmin/disable/${companyId.toString()}`);
  };

  static getKPIsForApp = async (appStub: string): Promise<AppKPI> => {
    return (await axios.get(`/apiv2/apps/kpi/${appStub}`))?.data;
  };

  static oauthConfigForBasicAuthApp = async (appStub: string): Promise<OAuthConfigurationForBasicAuthApp> => {
    return (await axios.get(`/apiv2/apps/${appStub}-oauth/instance/configuration`)).data;
  };
}

export class AppDetailsEndpoints {
  static getAppDetails(appStub: string): Endpoint<InstalledAppDto> {
    return {
      url: `/apiv2/apps/${appStub}`,
    };
  }

  static getOnboardingConfiguration(appStub: string): Endpoint<OnboardingAutomaticConfiguration> {
    return {
      url: `/apiv2/apps/${appStub}/onboarding/configuration`,
    };
  }

  static getSSOMetadata(): Endpoint<SSOState[]> {
    return {
      url: '/apiv2/apps/credentials/sso-metadata',
    };
  }
}
