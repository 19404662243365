import React, { useState } from 'react';

import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { ReactComponent as EyeSlash } from '@/images/side-bar-icons/EyeSlash.svg';

type RevealableColumnHeaderProps = {
  label: string;
  onVisibilityChange(visible: boolean): void;
  visible?: boolean;
};

export const RevealableColumnHeader = ({ label, onVisibilityChange, visible }: RevealableColumnHeaderProps) => {
  return (
    <div style={{ display: 'inline-flex', height: '16px' }}>
      {label}
      <div
        style={{ marginLeft: '4px' }}
        onClick={(e) => {
          onVisibilityChange(!visible);
          // prevent the click from initiating a sort on the column
          e.stopPropagation();
        }}
      >
        {visible ? <Eye /> : <EyeSlash style={{ marginTop: '-2px' }} />}
      </div>
    </div>
  );
};

type RevealableCellProps = {
  children: string | null | JSX.Element;
  mask?: string | JSX.Element;
  visible?: boolean;
  revealOnHover?: boolean;
};

export const RevealableCell = ({ mask = '*******', revealOnHover, visible, children }: RevealableCellProps) => {
  const [hovered, setHovered] = useState(false);
  if (visible) {
    return <>{children}</>;
  }
  return (
    <span onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {revealOnHover && hovered ? children : mask}
    </span>
  );
};
