import React, { useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { AccountInformationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-account-info-form.component';
import { EmergencyContactSchema } from '@/v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-form.component';
import { AccountInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';

const SCOPE = 'user.lifecycle:all' as const;
const iconSize = { width: 14, height: 14 } as const;

const skeletonHeight = calculateSkeletonHeight(EmergencyContactSchema);

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: AccountInformationValues) => void;
}

export const AccountInformationCard = ({ userId, onSubmit }: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasLifecycleScope = hasScopes(['user.lifecycle:read', 'user.lifecycle:manager'], scopesContext);

  const [dataO, setDataO] = useState<O.Option<AccountInformationValues>>(() => O.none);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [data, userLifecycle] = await Promise.all([
          UserAPI.getUserAccountInfo(userId),
          hasLifecycleScope ? UserAPI.getUserLifecycleInfo(userId) : undefined,
        ]);
        setDataO(
          O.some({
            ...data,
            employeeId: userLifecycle?.employeeId ?? undefined,
          })
        );
      } catch (error) {
        showMessage(polyglot.t('AccountInformationCard.errorMessages.load'), 'error');
      }
    })();
  }, [polyglot, hasLifecycleScope, showMessage, userId]);

  return pipe(
    dataO,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      (data) => {
        return (
          <Box sx={cardSx}>
            {data && (
              <>
                <Box component="header" sx={cardHeaderSx}>
                  <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                    {polyglot.t('AccountInformationCard.accountInfo')}{' '}
                  </Typography>
                  <ScopesControl scopes={[SCOPE]} context={scopesContext}>
                    <IconButton onClick={() => setIsOpen(true)} title="Edit" sx={tableIconButtonSx}>
                      <Edit {...iconSize} />
                    </IconButton>
                  </ScopesControl>
                </Box>
                <Box component="section">
                  <Box component="dl" sx={definitionListSx}>
                    {data?.employeeId && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>{polyglot.t('AccountInformationCard.employeeId')}</Typography>
                        <Typography sx={definitionValueSx}>{data.employeeId ?? ''}</Typography>
                      </Box>
                    )}
                    {data?.accountStatus && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>{polyglot.t('AccountInformationCard.status')}</Typography>
                        <Typography sx={definitionValueSx}>{data.accountStatus}</Typography>
                      </Box>
                    )}
                    {typeof data?.mfaType !== 'undefined' && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>{polyglot.t('AccountInformationCard.mfaStatus')}</Typography>
                        <Typography sx={definitionValueSx}>
                          {data.mfaType
                            ? polyglot.t('AccountInformationCard.enabled')
                            : polyglot.t('AccountInformationCard.disabled')}
                        </Typography>
                      </Box>
                    )}
                    {data?.activationDate && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>
                          {polyglot.t('AccountInformationCard.activationDate')}
                        </Typography>
                        <DateLabelComponent date={data.activationDate} titleSx={definitionValueSx} />
                      </Box>
                    )}
                    {data?.deactivationDate && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>
                          {polyglot.t('AccountInformationCard.deactivationDate')}
                        </Typography>
                        <DateLabelComponent date={data.deactivationDate} titleSx={definitionValueSx} />
                      </Box>
                    )}
                    {data?.createdAt && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>{polyglot.t('AccountInformationCard.createdAt')}</Typography>
                        <DateLabelComponent date={data.createdAt} titleSx={definitionValueSx} />
                      </Box>
                    )}
                    {data?.updatedAt && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>{polyglot.t('AccountInformationCard.updatedAt')}</Typography>
                        <DateLabelComponent date={data.updatedAt} titleSx={definitionValueSx} />
                      </Box>
                    )}
                    {data?.lastTimeOnline && (
                      <Box sx={definitionSx}>
                        <Typography sx={definitionTermSx}>
                          {polyglot.t('AccountInformationCard.lastTimeOnline')}
                        </Typography>
                        <DateLabelComponent
                          date={data.lastTimeOnline}
                          titleSx={definitionValueSx}
                          dateformat="d MMM yyyy, h:mm a"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
                <AccountInformationForm
                  userId={userId}
                  initialValues={data}
                  onSubmit={(values) => {
                    setDataO(O.some(values));
                    setIsOpen(false);
                    onSubmit(values);
                  }}
                  onClose={() => setIsOpen(false)}
                />
              </DrawerModal>
            </ScopesControl>
          </Box>
        );
      }
    )
  );
};
