import { TimeDrawerContent } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-drawer-content.component';
import { AbsenceTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const TimeActionItem = ({
  timeActionRow,
  refresh,
  afterClose,
}: {
  timeActionRow: Pick<AbsenceTodo, 'id' | 'userId'>;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  return (
    <TimeDrawerContent
      absenceId={timeActionRow.id}
      userId={timeActionRow.userId}
      refresh={refresh}
      afterClose={afterClose}
    />
  );
};
