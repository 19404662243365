import { useMemo, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import {
  AttachmentComponent,
  attachmentComponentValueToString,
  stringToAttachmentComponentValue,
} from '@/v2/components/forms/attachment.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  CreateRequestForm,
  CreateRequestFormTemplate,
  RequestFormComponentItem,
  RequestFormStatus,
  RequestFormTemplate,
} from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsAPI } from '@/v2/feature/requests/request-forms.api';
import { spacing } from '@/v2/styles/spacing.styles';

type RequestTemplateComponentProps = {
  component: RequestFormComponentItem;
  disabled?: boolean;
  formik?: ReturnType<typeof useFormik>;
};

export function RequestTemplateComponent({ component, disabled, formik }: RequestTemplateComponentProps): JSX.Element {
  const hasSubmitted = !!formik?.submitCount;

  switch (component.type) {
    case 'text':
      return (
        <RichTextField
          disabled={disabled}
          label={component.label}
          onChange={(value: string) => {
            formik?.setFieldValue(component.id, value, true);
          }}
          value={formik?.values[component.id] ?? ''}
          helperText={hasSubmitted && (formik.errors[component.id] as string)}
          error={hasSubmitted && !!formik.errors[component.id]}
        />
      );
    case 'select':
      return (
        <SelectComponent
          disabled={disabled}
          label={component.label}
          name={component.id}
          onChange={formik?.handleChange}
          value={formik?.values[component.id] ?? null}
          options={component.options.map((o) => ({ label: o, value: o }))}
          helperText={hasSubmitted && (formik.errors[component.id] as string)}
          error={hasSubmitted && !!formik.errors[component.id]}
        />
      );
    case 'date':
      return (
        <DatePickerComponent
          disabled={disabled}
          label={component.label}
          name={component.id}
          onChange={(value) => formik?.setFieldValue(component.id, value)}
          value={formik?.values[component.id] ?? null}
          helperText={hasSubmitted && (formik.errors[component.id] as string)}
          error={hasSubmitted && !!formik.errors[component.id]}
          sx={{ width: '100%' }}
        />
      );
    case 'attachment':
      return (
        <AttachmentComponent
          variant="caption"
          disabled={disabled}
          label={component.label}
          name={component.id}
          // all form values are stored as strings so we must convert to an object
          value={stringToAttachmentComponentValue(formik?.values[component.id])}
          onChange={(value) => formik?.setFieldValue(component.id, attachmentComponentValueToString(value))}
          helperText={hasSubmitted && (formik.errors[component.id] as string)}
          error={hasSubmitted && !!formik.errors[component.id]}
        />
      );
    default:
      return <></>;
  }
}

type RequestFormProps = {
  readonly template: CreateRequestFormTemplate | RequestFormTemplate;
  readonly previewMode?: boolean;
  readonly onSubmit?: (requiresApproval: boolean) => void;
  readonly onClose?: () => void;
  readonly sx?: SxProps<Theme>;
  readonly approvalRules: ApprovalRuleDto[];
};

export const RequestFormComponent = ({
  template,
  previewMode,
  sx,
  onClose,
  onSubmit,
  approvalRules,
}: RequestFormProps) => {
  const { polyglot } = usePolyglot();

  const [submitting, setSubmitting] = useState(false);

  const showApprovalSection = useMemo(() => {
    const approvalRule = approvalRules.find((a) => a.id === template.approvalRuleId);
    return approvalRule ? (
      <Stack gap="5px">
        <Typography variant="captionSmall">{polyglot.t('ApprovalRuleModule.approvalRule')}</Typography>
        <Typography variant="caption">{approvalRule.name}</Typography>
      </Stack>
    ) : null;
  }, [template.approvalRuleId, polyglot, approvalRules]);

  const [initialValues, validationSchema] = useMemo(() => {
    const values: Record<string, string> = {};
    const schema: Record<string, any> = {};
    for (const item of template.layout.items) {
      values[item.id] = '';
      if (item.required) {
        schema[item.id] = yup.string().required('Required field');
      }
    }
    return [values, yup.object(schema)];
  }, [template.layout.items]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if ('id' in template) {
        const data: CreateRequestForm = {
          formTemplateId: template.id,
          values,
        };
        setSubmitting(true);
        try {
          const submittedForm = await RequestFormsAPI.submitForm(data);
          onSubmit?.(submittedForm.status === RequestFormStatus.Pending);
        } catch (error) {
          return;
        } finally {
          setSubmitting(false);
        }
      }
      onClose?.();
    },
  });

  return (
    <Stack sx={{ overflow: 'auto', ...sx }}>
      <FormikProvider value={formik}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: spacing.s2,
              }}
            >
              <Typography variant="title2">{template.name}</Typography>
              {!previewMode && (
                <LoaderButton
                  loading={submitting}
                  sizeVariant="small"
                  colorVariant="primary"
                  name={polyglot.t('General.submit')}
                />
              )}
            </Box>

            {template.description && (
              <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
                {template.description}
              </Typography>
            )}
          </Stack>
          {template.layout.items.map((item) => (
            <RequestTemplateComponent
              key={item.id}
              component={item}
              formik={formik}
              // attachment components must be disabled in preview mode to prevent file uploads
              disabled={submitting || (previewMode && item.type === 'attachment')}
            />
          ))}
          {showApprovalSection}
        </Form>
      </FormikProvider>
    </Stack>
  );
};
