import { AccountStatus } from '@/lib/users';

export const canInviteAgainStatuses: AccountStatus[] = [AccountStatus.Invited, AccountStatus.InvitedToOnboard];

export function getUserFullName({ firstName, lastName }: { firstName: string; lastName: string }): string {
  return `${firstName} ${lastName}`;
}

export const filterStringToObject = (currentFilterString: string) => {
  const defaultFilterString: { [id: string]: string[] } = {};
  if (currentFilterString.length > 0) {
    const andString = currentFilterString.split('&');
    if (andString.length > 0) {
      andString.forEach((item) => {
        const [key, values] = item.split('=');
        if (key && values) defaultFilterString[key] = values.split(',');
      });
    }
  }
  return defaultFilterString;
};
