import { ReactNode } from 'react';

import { CustomBenefitCategory } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';

import Asset from '@/images/benefits/assets.png';
import Educational from '@/images/benefits/educational.webp';
import Entertainment from '@/images/benefits/entertainment.webp';
import Health from '@/images/benefits/health.webp';
import Loans from '@/images/benefits/loans.png';
import Medical from '@/images/benefits/medical.png';
import Mileage from '@/images/benefits/mileage.webp';
import Pension from '@/images/benefits/pension.webp';
import Telephone from '@/images/benefits/telephone.webp';
import Travel from '@/images/benefits/travel.webp';
import Vehicles from '@/images/benefits/vehicles.webp';
import Vouchers from '@/images/benefits/vouchers.webp';

export const getDefaultBenefitImageByCategory = (
  benefitCategory: CustomBenefitCategory | undefined,
  width = '100%',
  height = 'auto'
): ReactNode => {
  const size = { width, height };
  switch (benefitCategory) {
    case CustomBenefitCategory.Asset:
      return <img alt="Asset" src={Asset} {...size} loading="lazy" />;
    case CustomBenefitCategory.Educational:
      return <img alt="Educational" src={Educational} {...size} loading="lazy" />;
    case CustomBenefitCategory.Entertainment:
      return <img alt="Entertainment" src={Entertainment} {...size} loading="lazy" />;
    case CustomBenefitCategory.Health:
      return <img alt="Health" src={Health} {...size} loading="lazy" />;
    case CustomBenefitCategory.Loans:
      return <img alt="Loans" src={Loans} {...size} loading="lazy" />;
    case CustomBenefitCategory.Medical:
      return <img alt="Medical" src={Medical} {...size} loading="lazy" />;
    case CustomBenefitCategory.Mileage:
      return <img alt="Mileage" src={Mileage} {...size} loading="lazy" />;
    case CustomBenefitCategory.Pension:
      return <img alt="Pension" src={Pension} {...size} loading="lazy" />;
    case CustomBenefitCategory.Telephone:
      return <img alt="Telephone" src={Telephone} {...size} loading="lazy" />;
    case CustomBenefitCategory.Travel:
      return <img alt="Travel" src={Travel} {...size} loading="lazy" />;
    case CustomBenefitCategory.Vehicles:
      return <img alt="Vehicles" src={Vehicles} {...size} loading="lazy" />;
    case CustomBenefitCategory.Vouchers:
      return <img alt="Vouchers" src={Vouchers} {...size} loading="lazy" />;
    default:
      return <img alt="Benefit" src={Pension} {...size} loading="lazy" />;
  }
};
