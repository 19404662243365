import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { sortDate, sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { AppIntegrationAPI } from '@v2/feature/app-integration/app-integration.api';
import { AppInstanceDto } from '@v2/feature/app-integration/app-integration.dto';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { IIncludable, TableColumn } from '@/v2/feature/super-admin/components/helper/table-helper';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type IncludeableAppInstaceDto = IIncludable & AppInstanceDto;

export const SuperAdminAppsPage = (): React.JSX.Element => {
  const [apps, setApps] = useState<AppInstanceDto[]>([]);
  const [isLoadingApps, setIsLoadingApps] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchApps = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string }) => {
      try {
        setIsLoadingApps(true);

        const { apps, pagination: paginationInfo } = await AppIntegrationAPI.getInstalledAppsAsSuperadmin(params);

        setApps(apps || []);
        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingApps(false);
      } catch (error) {
        showMessage(`Something went wrong fetching users. ${nestErrorMessage(error)}`, 'error');
        setIsLoadingApps(false);
      }
    },
    [showMessage]
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    (async () => {
      await fetchApps({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
      });
    })();
  }, [pageIndex, searchQuery, fetchApps]);

  const appsColumn = useMemo<ColumnDef<IncludeableAppInstaceDto, unknown>[]>(
    () => [
      new TableColumn<AppInstanceDto>().define({
        header: 'Company ID',
        id: 'companyId',
        size: 140,
        fieldName: 'companyId',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.companyId),
      }),
      new TableColumn<AppInstanceDto>().define({
        header: 'Company name',
        id: 'company.name',
        size: 140,
        fieldName: 'company.name',
        parseRow: (row: IncludeableAppInstaceDto) => row.company?.name ?? '-',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.company?.name),
      }),

      new TableColumn<AppInstanceDto>().define({
        header: 'App',
        id: 'app.appId',
        size: 140,
        fieldName: 'app.appId',
        parseRow: (row: IncludeableAppInstaceDto) => row.app?.name ?? '-',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.app?.name),
      }),

      new TableColumn<AppInstanceDto>().define({
        header: 'Date Created',
        id: 'createdAt',
        size: 140,
        fieldName: 'createdAt',
        parseRow: (row: IncludeableAppInstaceDto) =>
          `${row.createdAt ? new Date(row.createdAt).toLocaleString().split(',')[0] : '-'}`,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt),
      }),

      new TableColumn<AppInstanceDto>().define({
        header: 'App Owner',
        id: 'appOwner.userId',
        size: 140,
        fieldName: 'appOwner.userId',
        parseRow: (row: IncludeableAppInstaceDto) =>
          `${
            row?.appOwner && row.appOwner.firstName && row.appOwner.lastName
              ? `${row.appOwner.firstName} ${row.appOwner.lastName}`
              : '-'
          } (${row.appOwnerId})`,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(
            a,
            b,
            (item) =>
              `${
                item?.appOwner && item.appOwner.firstName && item.appOwner.lastName
                  ? `${item.appOwner.firstName} ${item.appOwner.lastName}`
                  : '-'
              } (${item.appOwnerId})`
          ),
      }),
    ],
    []
  );

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Apps</Typography>}
        views={<></>}
      />

      <ContentWrapper loading={isLoadingApps}>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              ...spacing.mb20,
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <TableSearch
              style={{ width: '22em' }}
              query={searchQuery}
              handleChange={(e) => {
                setSearchQuery(e.target.value?.trim() ?? '');
                // Resets pagination index once we perform a new search
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
              placeholder="Enter company name, app or owner name..."
            />
          </Box>

          {!isLoadingApps && (
            <>
              <BasicServerTable<IIncludable & AppInstanceDto>
                rowData={apps}
                columnData={appsColumn}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                setPagination={setPagination}
                totalPages={totalPages}
                totalItems={totalItems}
              />
            </>
          )}
        </>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
