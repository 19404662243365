import { useState } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { spacing } from '@v2/styles/spacing.styles';
import { useParams } from 'react-router-dom';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

export const AuxPage = () => {
  const [isPayLoading, setIsPayLoading] = useState<boolean>(false);
  const [isSimulatePaymentLoading, setIsSimulatePaymentLoading] = useState<boolean>(false);
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [showMessage] = useMessage();

  return (
    <ContentWrapper loading={false}>
      <Box sx={{ display: 'flex', gap: spacing.gap60 }}>
        <LoaderButton
          name="Temporary pay button"
          onClick={async () => {
            try {
              setIsPayLoading(true);
              await ContractorInvoiceAPI.payInvoice(invoiceId);
              showMessage('Success', 'success');
            } catch (error) {
              showMessage(`Error. ${nestErrorMessage(error)}`, 'error');
            } finally {
              setIsPayLoading(false);
            }
          }}
          loading={isPayLoading}
          colorVariant="primary"
          sizeVariant="large"
        />

        <LoaderButton
          name="Simulate payment request"
          colorVariant="secondary"
          sizeVariant="large"
          onClick={async () => {
            try {
              setIsSimulatePaymentLoading(true);
              await ContractorInvoiceAPI.simulatePaymentResponse(invoiceId);
              showMessage('Success', 'success');
            } catch (error) {
              showMessage(`Error. ${nestErrorMessage(error)}`, 'error');
            } finally {
              setIsSimulatePaymentLoading(false);
            }
          }}
          loading={isSimulatePaymentLoading}
        />
      </Box>
    </ContentWrapper>
  );
};
