import { useCallback, useEffect, useState } from 'react';

import { Box, Menu, MenuItem, MenuProps, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Filter } from '@/images/new-theme-icon/Filter.svg';
import { ReactComponent as ArrowLeft } from '@/images/side-bar-icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '@/images/side-bar-icons/ArrowRight.svg';
import { ReactComponent as CheckedCheckbox } from '@/images/side-bar-icons/CheckedCheckbox.svg';
import { ReactComponent as EmptyCheckbox } from '@/images/side-bar-icons/EmptyCheckbox.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 20px 0px rgba(13, 13, 14, 0.1)',
    textAlign: 'left',
    borderRadius: radius.br10,
    paddingRight: spacing.p10,
    '& .MuiMenu-list': {
      ...spacing.pad20,
      display: 'flex',
      flexDirection: 'column',
      gap: spacing.g10,
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      padding: '0px',
      minHeight: '20px',
      ...themeFonts.caption,
      '&:hover': {
        backgroundColor: 'transparent',
        color: themeColors.Grey,
        fill: themeColors.Grey,
      },
    },
  },
}));

const iconSize = { width: 14, height: 14 } as const;

export type FilterTypesProps = { [key: string]: { label: string; value: string | number }[] };

interface Props {
  filterTypes: FilterTypesProps;
  setFilterString: (filter: string) => void;
  filterString: string;
  dynamicFilterNames?: boolean;
  showSelectedFilters?: boolean;
  onClose?: () => Promise<void>;
}

export const TableFilter = ({
  filterTypes,
  setFilterString,
  filterString,
  dynamicFilterNames = false,
  showSelectedFilters = true,
  onClose = undefined,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterObject, setFilterObject] = useState<{ [key: string]: string[] }>({});
  const [filter, setFilter] = useState<string | null>(null);

  const filterStringToObject = useCallback(() => {
    const defaultFilterString: { [id: string]: string[] } = {};
    if (filterString.length > 0) {
      const andString = filterString.split('&');
      if (andString.length > 0) {
        andString.forEach((item) => {
          const [key, values] = item.split('=');
          if (key && values) defaultFilterString[key] = values.split(',');
        });
      }
    }
    setFilterObject(defaultFilterString);
  }, [filterString]);

  useEffect(() => {
    filterStringToObject();
  }, [filterStringToObject]);

  const handleFilterSelect = (value: string) => {
    let updatedValues;
    if (filter) {
      const deselectValue = filterObject[filter] && filterObject[filter]?.length && filterObject[filter].indexOf(value);
      if (deselectValue >= 0) {
        updatedValues = filterObject[filter].filter((item) => item !== value);
        if (filterObject[filter].length <= 1) delete filterObject[filter];
      } else {
        updatedValues = filterObject[filter]?.length ? [...filterObject[filter], value] : [value];
      }
      const newFilterOptions = { ...filterObject, [filter]: updatedValues };
      setFilterObject(newFilterOptions);
      let newQueryString = '';

      Object.keys(newFilterOptions).forEach((key, index) => {
        const optionsSelected = newFilterOptions[key].toString();
        if (optionsSelected.length > 0) {
          if (index === 0) newQueryString += `${key}=${optionsSelected}`;
          else newQueryString += `&${key}=${optionsSelected}`;
        }
      });
      setFilterString(newQueryString);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCloseFilters = (filter: string, value: string) => {
    let updatedValues;
    const deselectValue = filterObject[filter] && filterObject[filter]?.length && filterObject[filter].indexOf(value);
    if (deselectValue >= 0) {
      updatedValues = filterObject[filter].filter((item) => item !== value);
      if (filterObject[filter].length <= 1) delete filterObject[filter];
    } else {
      updatedValues = filterObject[filter]?.length ? [...filterObject[filter], value] : [value];
    }
    const newFilterOptions = { ...filterObject, [filter]: updatedValues };
    setFilterObject(newFilterOptions);
    let newQueryString = '';

    Object.keys(newFilterOptions).forEach((key, index) => {
      const optionsSelected = newFilterOptions[key].toString();
      if (optionsSelected.length > 0) {
        if (index === 0) newQueryString += `${key}=${optionsSelected}`;
        else newQueryString += `&${key}=${optionsSelected}`;
      }
    });
    setFilterString(newQueryString);
  };
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5, flexWrap: 'wrap' }}>
        <ButtonComponent
          sizeVariant="filter"
          colorVariant={Object.keys(filterObject).length > 0 ? 'active' : 'secondary'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={<Filter {...iconSize} />}
        >
          Filter
        </ButtonComponent>

        {showSelectedFilters &&
          Object.keys(filterObject)?.map((filterObj) =>
            filterObject[filterObj]?.map((subFilterName) => {
              return (
                <ButtonComponent
                  key={subFilterName}
                  sizeVariant="filter"
                  style={{ textTransform: 'capitalize' }}
                  colorVariant="secondary"
                  endIcon={<Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />}
                  onClick={async () => {
                    handleCloseFilters(filterObj, subFilterName);
                  }}
                >
                  {(filterTypes[filterObj] &&
                    filterTypes[filterObj]?.find((typeObj) => typeObj.value.toString() === subFilterName)?.label) ?? (
                    <Skeleton width={80} animation="wave" sx={{ backgroundColor: themeColors.Background }} />
                  )}
                </ButtonComponent>
              );
            })
          )}
      </Box>

      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': 'btnMenuOptions',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={async () => {
          setAnchorEl(null);
          setFilter(null);
          if (onClose) await onClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {!filter &&
          Object.keys(filterTypes).map((filter) => (
            <MenuItem
              key={filter}
              onClick={() => setFilter(filter)}
              disableRipple
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: spacing.g10 }}
            >
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                {dynamicFilterNames ? filter.split('-').pop() : filter}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                {filterObject[filter]?.length > 0 && (
                  <Typography
                    sx={{
                      ...themeFonts.caption,
                      color: themeColors.DarkGrey,
                    }}
                  >
                    {filterObject[filter]?.length}
                  </Typography>
                )}

                <ArrowRight {...iconSize} style={{ fill: themeColors.Grey, color: themeColors.Grey }} />
              </Box>
            </MenuItem>
          ))}

        {filter && (
          <MenuItem onClick={() => setFilter(null)} disableRipple>
            <Box sx={{ ...themeFonts.caption, fill: themeColors.Grey }}>
              <ArrowLeft {...iconSize} />
            </Box>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {dynamicFilterNames ? filter.split('-').pop() : filter}
            </Typography>
          </MenuItem>
        )}

        {filter &&
          filterTypes[filter]?.map((subFilter) => (
            <MenuItem
              key={subFilter.value}
              value={subFilter.value}
              onClick={(e) => {
                handleFilterSelect(subFilter.value.toString());
                e.stopPropagation();
              }}
              disableRipple
              sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}
            >
              {filterObject[filter] && filterObject[filter]?.indexOf(subFilter.value.toString()) !== -1 ? (
                <CheckedCheckbox {...iconSize} />
              ) : (
                <EmptyCheckbox {...iconSize} />
              )}
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{subFilter.label}</Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
};
