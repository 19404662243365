import axios from 'axios';

import { SurveyQuestion, SurveyQuestionUpsert } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';

export class SurveyQuestionAPI {
  static async createAndCloneQuestionInCycle(questionBody: SurveyQuestionUpsert): Promise<SurveyQuestion> {
    return (await axios.post('/apiv2/survey-question', questionBody)).data;
  }

  static async updateQuestionInCycle(questionBody: SurveyQuestionUpsert): Promise<void> {
    return (await axios.patch('/apiv2/survey-question', questionBody)).data;
  }

  static async deleteSurveyQuestion(questionId: string, cycleId: string): Promise<void> {
    await axios.delete(`/apiv2/survey-question/${questionId}/${cycleId}`);
  }
}
