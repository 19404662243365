import { Box, Typography } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SSOOktaDomainToolTipInstructions = () => {
  return (
    <Box>
      <Typography sx={{ ...themeFonts.caption, mb: spacing.m5, color: themeColors.white }}>
        To find your Okta domain (also called an Okta URL):
      </Typography>
      <Typography sx={{ ...themeFonts.caption, mb: spacing.m5, color: themeColors.white }}>
        1. Sign in to your Okta organization with your administrator account.
      </Typography>
      <Typography sx={{ ...themeFonts.caption, mb: spacing.m5, color: themeColors.white }}>
        2.Look for the Okta domain in the upper-right corner menu, just below your name and email address.
      </Typography>
    </Box>
  );
};
