import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import {
  InsurancePolicyDto,
  UpdateInsurancePolicyGeneralDto,
} from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

const validationSchema = (polyglot: Polyglot) =>
  yup.object({
    displayName: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const EditInsuranceGeneralDrawer = ({ isOpen, setIsOpen, insurancePolicy, refresh }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditInsuranceGeneralDrawerContent setIsOpen={setIsOpen} refresh={refresh} insurancePolicy={insurancePolicy} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const EditInsuranceGeneralDrawerContent = ({ setIsOpen, refresh, insurancePolicy }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<UpdateInsurancePolicyGeneralDto>({
    initialValues: {
      displayName: insurancePolicy.displayName,
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await InsuranceAPI.updateHealthInsurancePolicyGeneral(values);
        showMessage(polyglot.t('BenefitModule.benefitUpdated'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.editBenefit')}</Typography>

        <TextfieldComponent
          name="displayName"
          label={polyglot.t('General.name')}
          value={formik.values.displayName}
          onChange={formik.handleChange}
          clearText={() => formik.setFieldValue('name', '')}
          helperText={formik.errors.displayName}
          error={!!formik.errors.displayName}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
