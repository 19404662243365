import Bull from 'bull';
import { format, parseISO } from 'date-fns';

import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import { removeTZ, timeAgo } from '@/v2/util/date-format.util';

export const tsFormat = 'MMMM do, hha';

export const getStartingTimeForJob = (row: Bull.Job<CreateAppQueue>) => {
  return row.processedOn
    ? timeAgo(removeTZ(new Date(row.processedOn).toISOString()))
    : row.opts.delay
    ? removeTZ(new Date(row.timestamp + row.opts.delay).toISOString())
    : '';
};

export const getScheduledTimeForAppAccessDetailDrawer = (row: Bull.Job<CreateAppQueue>): string | undefined => {
  const scheduledDate = row.processedOn
    ? timeAgo(removeTZ(new Date(row.processedOn).toISOString()))
    : row.opts.delay
    ? removeTZ(new Date(row.timestamp + row.opts.delay).toISOString())
    : undefined;

  return scheduledDate ? format(parseISO(scheduledDate), tsFormat) : undefined;
};
