import { useState } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import { MdmMigrationDto, MdmMigratorAPI } from '@v2/feature/user/features/mdm-migration/mdm-migrator.api';

import useMessage from '@/hooks/notification.hook';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

export const SuperAdminMdmMigratorPage = (): JSX.Element => {
  const [companyId, setCompanyId] = useState<string>();
  const [devices, setDevices] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  function isValidCSV(input: string | undefined): boolean {
    if (!input) {
      showMessage('DeviceIds cannot be empty', 'error');
      return false;
    }
    const csvRegex = /^\d+(,\d+)*$/;
    let result = csvRegex.test(input);
    if (!result) {
      showMessage('DeviceIds have to be number separated by commas', 'error');
      return false;
    }
    return true;
  }
  function isValidCompanyId(companyId: string | undefined) {
    if (isNaN(Number(companyId))) {
      showMessage('CompanyId has to be number', 'error');
      return false;
    }
    return true;
  }
  const triggerMdmImportImport = async () => {
    setLoading(true);
    try {
      if (isValidCompanyId(companyId) && isValidCSV(devices)) {
        showMessage('Successfully triggered MDM import', 'success');
        let deviceIds = devices?.split(',').map((num) => Number(num));
        if (!deviceIds) {
          deviceIds = [];
        }
        const mdmMigrationDto: MdmMigrationDto = { companyId: Number(companyId), deviceIds: deviceIds };
        await MdmMigratorAPI.startMigration(mdmMigrationDto);
      }
    } catch (error) {
      showMessage(`Encountered an error while trying to start MDM migration: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const syncMobileDevices = async () => {
    setLoading(true);
    try {
      await MdmMigratorAPI.startMigrationForMobileDevices();
    } catch (error) {
      showMessage(
        `Encountered an error while trying to start MDM migration fro mobile devices: ${JSON.stringify(error)}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };
  const syncAllDevicesPolicies = async () => {
    setLoading(true);
    try {
      showMessage('Successfully triggered MDM devices policies', 'success');
      await MdmMigratorAPI.startPoliciesSyncForAllDevices();
    } catch (error) {
      showMessage(`Encountered an error while trying to sync policies: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <BackofficeRootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            Start MDM migration process
          </Typography>
        }
        views={<></>}
      />
      <ContentWrapper>
        <Stack sx={{ width: '500px', gap: '20px' }}>
          <TextfieldComponent
            label={'Company ID for migration'}
            name={'companyId'}
            defaultValue="Input company ID"
            value={companyId}
            onChange={(e) => {
              setCompanyId(e.target.value);
            }}
            clearText={() => setCompanyId('')}
            disabled={loading}
            endAdornment="none"
          />
          <TextfieldComponent
            label="Device IDs for migration"
            helperText="Device ids separated by commas"
            multiline
            value={devices}
            onChange={(e) => {
              setDevices(e.target.value);
            }}
            clearText={() => setDevices('')}
            disabled={loading}
          />
          <LoaderButton
            name={'Start migration'}
            loading={loading}
            onClick={() => triggerMdmImportImport()}
            disabled={!companyId || loading}
            sizeVariant="large"
            colorVariant="primary"
          />
          <Divider />
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            Sync policies for all devices
          </Typography>
          <LoaderButton
            name={'Sync policies'}
            loading={loading}
            onClick={() => syncAllDevicesPolicies()}
            disabled={loading}
            sizeVariant="large"
            colorVariant="primary"
          />
          <Divider />
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Sync mobile devices</Typography>
          <LoaderButton
            name={'Sync mobile devices'}
            loading={loading}
            onClick={() => syncMobileDevices()}
            disabled={loading}
            sizeVariant="large"
            colorVariant="primary"
          />
        </Stack>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
