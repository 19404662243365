import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { SETTINGS_CALENDAR_ROUTE } from '@/lib/routes';
import { CalendarSettingsPage } from '@/v2/feature/calendar/features/settings/calendar-settings.page';

export const CalendarSettingRouter = () => {
  return (
    <Switch>
      <RouteScopesHas scopes={['calendar:all']} path={SETTINGS_CALENDAR_ROUTE}>
        <CalendarSettingsPage />
      </RouteScopesHas>

      <Redirect from={SETTINGS_CALENDAR_ROUTE} to={SETTINGS_CALENDAR_ROUTE} exact />
    </Switch>
  );
};
