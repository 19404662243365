import { Company } from '@/models/company.model';
import { SubscriptionBill } from '@/models/invoice.model';
import Polyglot from 'node-polyglot';

export type CompanyBillingSubscription = {
  productPlan: { id: AllProductPlans; productId: number; planId: Plan };
  subscriptionId: number;
};

export enum FreeProductPlans {
  PEOPLE_FREE = 1,
  MONEY_FREE = 2,
  APPS_FREE = 3,
  DEVICES_FREE = 4,
}

// Numbers corresponds to table <product_plan>
export enum PaidProductPlans {
  PEOPLE_PRO = 5,
  MONEY_PRO = 6,
  APPS_PRO = 7,
  DEVICES_PRO = 8,
}

export type AllProductPlans = FreeProductPlans & PaidProductPlans;

export const FreePlanList = [
  FreeProductPlans.PEOPLE_FREE,
  FreeProductPlans.MONEY_FREE,
  FreeProductPlans.APPS_FREE,
  FreeProductPlans.DEVICES_FREE,
];

export const FreeToProPlanMap: Record<number, number> = {
  [FreeProductPlans.PEOPLE_FREE]: PaidProductPlans.PEOPLE_PRO,
  [FreeProductPlans.MONEY_FREE]: PaidProductPlans.MONEY_PRO,
  [FreeProductPlans.APPS_FREE]: PaidProductPlans.APPS_PRO,
  [FreeProductPlans.DEVICES_FREE]: PaidProductPlans.DEVICES_PRO,
};

export const ProductPlanTypes: Record<string, Record<string, number>> = {
  People: {
    Free: FreeProductPlans.PEOPLE_FREE,
    Pro: PaidProductPlans.PEOPLE_PRO,
  },
  Money: {
    Free: FreeProductPlans.MONEY_FREE,
    Pro: PaidProductPlans.MONEY_PRO,
  },
  Apps: {
    Free: FreeProductPlans.APPS_FREE,
    Pro: PaidProductPlans.APPS_PRO,
  },
  Devices: {
    Free: FreeProductPlans.DEVICES_FREE,
    Pro: PaidProductPlans.DEVICES_PRO,
  },
};

export type Period = { startDate: Date; endDate: Date };

export type SubscriptionAutoBillerJob = {
  company: Company;
  billingCycle: Period;
};

export type SubscriptionChargeData = {
  customerStripeId: string;
  invoiceId: string;
  totalAmount: number;
};

export type SubscriptionChargeJobData = {
  company: Company;
  chargeData: SubscriptionChargeData;
};

export type CompanySubscriptionStats = {
  restriction: Record<number, { Free: Record<string, number> }>;
  productPricing: Record<number, { Pro: { costPerUserPerMonth: number } }>; // Maps product id to pricing
  productPlanPricing: Record<number, { costPerUserPerMonth: number }>; // Maps productPlanId to pricing
  companyStats: { noOfActiveUsers: number; noOfRentedDevices: number };
};

export enum Product {
  PEOPLE = 1,
  MONEY = 2,
  APPS = 3,
  DEVICES = 4,
}

export enum Plan {
  FREE = 1,
  PRO = 2,
}

export const ProductPlanMapToLabel = (polyglot: Polyglot): Record<number, string> => ({
  [Product.PEOPLE]: polyglot.t('ProductPlanMapToLabel.people'),
  [Product.MONEY]: polyglot.t('ProductPlanMapToLabel.money'),
  [Product.APPS]: polyglot.t('ProductPlanMapToLabel.apps'),
  [Product.DEVICES]: polyglot.t('ProductPlanMapToLabel.devices'),
});

export type BillingContact = {
  id: number;
  invoiceEmail: string | null;
  phoneNumber: string | null;
  companyId: number;
  userId: number;
  user?: {
    userId: number;
    firstName: string;
    lastName: string;
    avatarHash: string;
  };
};

export type PaymentMethod = {
  id: number;
  companyId: number;
  type: string;
  card: {
    brand: string;
    last4: string;
    exp_year: number;
    exp_month: number;
  };
  billingAddress: {
    city: string;
    line1: string;
    line2: string;
    state: string | null;
    country: string;
    postal_code: string;
  };
};

export const EMPTY_PAYMENT_METHOD = {
  id: -Infinity,
  companyId: -Infinity,
  type: '',
  card: {
    brand: '',
    last4: '',
    exp_year: -Infinity,
    exp_month: -Infinity,
  },
  billingAddress: {
    city: '',
    line1: '',
    line2: '',
    state: '',
    country: '',
    postal_code: '',
  },
};

export type BillingInformation = {
  billingContact?: BillingContact;
  paymentMethods: PaymentMethod[];
};

export type SubscriptionBillingStats = { [key in Product]: SubscriptionBill[] };

export const getProductPlanIdToName = (polyglot: Polyglot) => {
  return {
    [FreeProductPlans.PEOPLE_FREE]: polyglot.t('ProductPlanIdToName.peopleFree'),
    [FreeProductPlans.MONEY_FREE]: polyglot.t('ProductPlanIdToName.moneyFree'),
    [FreeProductPlans.APPS_FREE]: polyglot.t('ProductPlanIdToName.appsFree'),
    [FreeProductPlans.DEVICES_FREE]: polyglot.t('ProductPlanIdToName.devicesFree'),
    [PaidProductPlans.PEOPLE_PRO]: polyglot.t('ProductPlanIdToName.peoplePro'),
    [PaidProductPlans.MONEY_PRO]: polyglot.t('ProductPlanIdToName.moneyPro'),
    [PaidProductPlans.APPS_PRO]: polyglot.t('ProductPlanIdToName.appsPro'),
    [PaidProductPlans.DEVICES_PRO]: polyglot.t('ProductPlanIdToName.devicesPro'),
  };
};
