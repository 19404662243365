import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Fade, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import Amazon from '@/images/referral/amazon.png';
import Stars from '@/images/referral/Stars.png';
import { DASHBOARD_ROUTE } from '@/lib/routes';
import { CurrentUser } from '@/models/user.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const submissionUrl =
  'https://api.hsforms.com/submissions/v3/integration/submit/20337724/1d10c235-511c-43cc-a56e-940e228f16c1';

const ReferSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Name is required'),
  email: Yup.string().nullable().required('Email is required'),
});

const ReferralSuccessState = ({ resetForm }: { resetForm: () => void }) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ ...spacing.px20, py: spacing.p30 }}>
      <Box sx={{ height: '150px', display: 'flex', justifyContent: 'flex-start' }}>
        <img src={Stars} alt="star-illustration" width="auto" />
      </Box>

      <Typography sx={{ ...themeFonts.title2, mt: spacing.m30 }}>
        {polyglot.t('ReferralSuccessState.congratulations')}
      </Typography>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.m10 }}>
        {polyglot.t('ReferralSuccessState.successFull')}
      </Typography>

      <Box sx={spacing.mt40}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
          onClick={() => {
            resetForm();
          }}
        >
          {polyglot.t('ReferralSuccessState.referAgain')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

const Reward = () => {
  const { polyglot } = usePolyglot();
  return (
    <Box>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>{polyglot.t('Reward.1000')}</Typography>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
        {polyglot.t('Reward.referFriend')}{' '}
      </Typography>

      <Box sx={{ mt: spacing.m30, display: 'flex', gap: spacing.g5, justifyContent: 'center' }}>
        <img src={Amazon} height={175} width="auto" alt="amazon-reward" />
      </Box>
    </Box>
  );
};

export const ReferAndEarnModal = ({
  isOpen,
  setIsOpen,
  isAdminUser,
  user,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isAdminUser: boolean;
  user: CurrentUser;
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessState, setShowSuccessState] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: { name: null, email: null },
    enableReinitialize: true,
    validationSchema: ReferSchema,
    onSubmit: async (values) => {
      try {
        setShowSuccessState(false);
        setLoading(true);
        const data = {
          fields: [
            {
              objectTypeId: '0-1',
              name: 'email',
              value: values.email,
            },
            {
              objectTypeId: '0-1',
              name: 'company',
              value: values.name,
            },
            {
              objectTypeId: '0-1',
              name: 'referred_by_email_address',
              value: user.emailAddress,
            },
            {
              objectTypeId: '0-1',
              name: 'referred_by_name',
              value: `${user.firstName} ${user.lastName}`,
            },
            {
              objectTypeId: '0-1',
              name: 'referral_type',
              value: isAdminUser ? 'Customer Admin' : 'Customer Employee',
            },
          ],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: 'I agree to allow Zelt Company to store and process my personal data.',
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 999,
                  text: 'I agree to receive marketing communications from Example Company.',
                },
              ],
            },
          },
        };

        const res = await fetch(submissionUrl, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        });

        if (res.status === 200) {
          setShowSuccessState(true);
        } else {
          showMessage(polyglot.t('ReferAndEarnModal.errorMessages.refer'), 'error');
          setShowSuccessState(false);
        }
      } catch (error) {
        showMessage(polyglot.t('ReferAndEarnModal.errorMessages.refer'), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const resetForm = () => {
    formik.resetForm();
    setShowSuccessState(false);
  };
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      sx={{ pt: 0, ...spacing.px20, pb: spacing.p30 }}
      onClose={() => {
        formik.resetForm();
        routerHistory.push(DASHBOARD_ROUTE);
        setShowSuccessState(false);
        setIsOpen(false);
      }}
    >
      {showSuccessState ? (
        <ReferralSuccessState resetForm={resetForm} />
      ) : (
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Fade in={true} timeout={1000}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    ...spacing.pl20,
                  }}
                >
                  <Typography sx={{ ...themeFonts.title1, ...spacing.mt40 }}>
                    {polyglot.t('ReferAndEarnModal.referEarn')}
                  </Typography>
                  <img src={Stars} alt="star-illustration" width="auto" height={160} style={{ marginTop: '-20px' }} />
                </Box>

                <Box sx={{ ...spacing.px20 }}>
                  <Box sx={{ mt: spacing.m30 }}>
                    <Reward />
                  </Box>

                  <Typography sx={{ ...themeFonts.title2, ...spacing.mt40, color: themeColors.DarkGrey }}>
                    {polyglot.t('ReferAndEarnModal.whoToRefer')}
                  </Typography>
                  <Box sx={{ ...fieldSx, mt: spacing.m30 }}>
                    <TextfieldComponent
                      name="name"
                      label={polyglot.t('ReferAndEarnModal.name')}
                      value={formik.values.name}
                      type="text"
                      onChange={formik.handleChange}
                      error={formik.touched.name && !!formik.errors.name}
                      helperText={((formik.touched.name && formik.errors.name) as string) ?? (' ' as string)}
                      clearText={() => formik.setFieldValue('name', '')}
                    />
                  </Box>
                  <Box sx={fieldSx}>
                    <TextfieldComponent
                      name="email"
                      label={polyglot.t('ReferAndEarnModal.email')}
                      value={formik.values.email}
                      type="text"
                      onChange={formik.handleChange}
                      error={formik.touched.email && !!formik.errors.email}
                      helperText={((formik.touched.email && formik.errors.email) as string) ?? (' ' as string)}
                      clearText={() => formik.setFieldValue('email', '')}
                    />
                  </Box>
                  <Box sx={spacing.mt40}>
                    <LoaderButton
                      name={polyglot.t('ReferAndEarnModal.referNow')}
                      loading={loading}
                      fullWidth={true}
                      sizeVariant="medium"
                      colorVariant="primary"
                    />
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Form>
        </FormikProvider>
      )}
    </DrawerModal>
  );
};
