import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IconButton, Stack } from '@mui/material';
import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { CurrentUser, UserBasicsInfo } from '@/models';
import { StepperComponent } from '@/v2/components/stepper.component';
import { CustomProfileFormAPI } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { CustomProfileFormDto, UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { UserAboutDto } from '@/v2/feature/user/features/user-forms/user-about/user-about.dto';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { UserAddressDto } from '@/v2/feature/user/features/user-forms/user-address/user-address.dto';
import { UserBankAccountAPI } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import { UserBankAccountDto } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import { UserEmergencyContactDto } from '@/v2/feature/user/features/user-forms/user-emergency-contact/user-emergency-contact.dto';
import { UserPersonalInfoDto } from '@/v2/feature/user/features/user-forms/user-personal-info/user-personal-info.dto';
import { UserAPI } from '@/v2/feature/user/user.api';
import { UserAboutSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-about.section';
import { UserAddressSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-address.section';
import { UserBankAccountSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-bank-account.section';
import { UserBasicInfoSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-basic-info.section';
import { UserCustomSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-custom.section';
import { UserEmergencyContactSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-emergency-contact.section';
import { UserPersonalInfoSection } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-personal-info.section';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort } from '@/v2/util/array.util';

export const OnboardingStepperDefaultSteps = [
  'Basic info',
  'Personal',
  'Bank',
  'Address',
  'Emergency',
  'About',
] as const;

export const iconSize = { width: 14, height: 14 } as const;

type OnboardingState = {
  basicInfo: UserBasicsInfo;
  personalInfo: UserPersonalInfoDto;
  bankAccount: UserBankAccountDto | null;
  homeAddress: UserAddressDto | null;
  emergencyContact: Partial<UserEmergencyContactDto> | null;
  about: UserAboutDto;
  customSections: {
    form: CustomProfileFormDto;
    values: UserCustomDataDto[];
  }[];
  forms: CustomProfileFormDto[];
};

interface OnboardingBasicFlowProps {}

type StepRenderFunctionType = { [key: string]: () => JSX.Element | null };

const checkScopesForOnboardingFlow = (currentUser: CurrentUser) => {
  const { userId } = currentUser;
  const hasBankingScope = checkScopes(currentUser, ['user.bankAccount'], { userId });
  const hasAddressScope = checkScopes(currentUser, ['user.address'], { userId });
  const hasEmergencyContactScope = checkScopes(currentUser, ['user.emergencyContact'], { userId });
  const hasAboutScope = checkScopes(currentUser, ['user.about'], { userId });
  return { hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope };
};

const removeStepsFromArray = (steps: string[], stepsToRemove: Set<string>) => {
  return steps.filter((step) => !stepsToRemove.has(step));
};

const getStepsToRemove = (
  hasBankingScope: boolean,
  hasAddressScope: boolean,
  hasEmergencyContactScope: boolean,
  hasAboutScope: boolean
) => {
  const stepsToRemove = new Set<string>();

  if (!hasBankingScope) {
    stepsToRemove.add('Bank');
  }

  if (!hasAddressScope) {
    stepsToRemove.add('Address');
  }

  if (!hasEmergencyContactScope) {
    stepsToRemove.add('Emergency');
  }

  if (!hasAboutScope) {
    stepsToRemove.add('About');
  }

  return stepsToRemove;
};

export const OnboardingBasicFlow = (_: OnboardingBasicFlowProps) => {
  const userId = useUserIdParam();
  const [globalState] = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState<string>('Basic info');
  const [onboardingState, setOnboardingState] = useState<OnboardingState>();
  const { hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope } = checkScopesForOnboardingFlow(
    globalState.user
  );

  const routerHistory = useHistory();

  const OnboardingStepperSteps = useMemo(() => {
    let initialSteps = [
      ...OnboardingStepperDefaultSteps,
      ...(onboardingState?.customSections.map(({ form }) => form.formName) ?? []),
    ];
    const stepsToRemove = getStepsToRemove(hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope);

    return removeStepsFromArray(initialSteps, stepsToRemove);
  }, [hasAboutScope, hasAddressScope, hasBankingScope, hasEmergencyContactScope, onboardingState?.customSections]);

  const isFirstStep = useCallback(() => {
    return OnboardingStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep, OnboardingStepperSteps]);

  const handleNext = useCallback(() => {
    const currentStepIdx = OnboardingStepperSteps.indexOf(activeStep);
    if (currentStepIdx >= OnboardingStepperSteps.length - 1) {
      routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
      return;
    }
    setActiveStep(OnboardingStepperSteps[currentStepIdx + 1]);
  }, [activeStep, routerHistory, userId, OnboardingStepperSteps]);

  const handleBack = useCallback(() => {
    setActiveStep((currentStep: string) => {
      const currentStepIdx = OnboardingStepperSteps.indexOf(currentStep);
      return OnboardingStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, [OnboardingStepperSteps]);

  const getOnboardingInfo = useCallback(async () => {
    if (!Number.isInteger(userId)) {
      return;
    }
    const [
      basicInfo,
      personalInfo,
      bankAccount,
      homeAddress,
      emergencyContact,
      about,
      forms,
      formValues,
    ] = await Promise.all([
      UserAPI.getUserBasicInfo(userId),
      UserAPI.getUserPersonalInfo(userId),
      hasBankingScope ? UserBankAccountAPI.findCurrentByUserId(userId) : null,
      hasAddressScope ? UserAddressAPI.findCurrentByUserId(userId) : null,
      hasEmergencyContactScope ? UserAPI.getUserEmergencyContact(userId) : null,
      UserAPI.getUserAbout(userId),
      CustomProfileFormAPI.listForms(),
      CustomProfileFormAPI.getFormValues(userId),
    ]);

    const customFormsWithRequiredFields = forms
      .filter((f) => !f.isDefault && f.fields?.length && f.fields.some((field) => field.isRequired))
      .sort((a, b) => caseInsensitiveSort(a, b, (item) => item.formName));

    const customSections: { form: CustomProfileFormDto; values: UserCustomDataDto[] }[] = [];
    for (const customForm of customFormsWithRequiredFields) {
      customSections.push({
        form: customForm,
        values: formValues.filter((value) => value.formId === customForm.formId),
      });
    }
    setOnboardingState({
      basicInfo,
      personalInfo,
      bankAccount,
      homeAddress,
      emergencyContact,
      about,
      customSections,
      forms,
    });
  }, [hasAddressScope, hasBankingScope, hasEmergencyContactScope, userId]);

  useEffect(() => {
    getOnboardingInfo();
  }, [getOnboardingInfo]);

  const stepRenderFunctions: StepRenderFunctionType = useMemo(() => {
    if (!onboardingState) {
      return {};
    }

    const defaultSection = {
      'Basic info': () => (
        <UserBasicInfoSection
          basicInfo={onboardingState.basicInfo}
          form={onboardingState.forms?.find((f) => f.formTab === ProfileTab.Personal && f.formName === 'basic')}
          onNext={(basicInfo) => {
            onboardingState.basicInfo = { ...(onboardingState.basicInfo ?? {}), ...basicInfo };
            handleNext();
          }}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
          userId={userId}
        />
      ),
      Personal: () => (
        <UserPersonalInfoSection
          form={onboardingState.forms?.find((f) => f.formTab === ProfileTab.Personal && f.formName === 'details')}
          onNext={(personalInfo) => {
            onboardingState.personalInfo = { ...(onboardingState.personalInfo ?? {}), ...personalInfo };
            handleNext();
          }}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
          userId={userId}
          personalInfo={onboardingState.personalInfo}
        />
      ),
      Bank: () => (
        <UserBankAccountSection
          form={onboardingState.forms?.find(
            (f) => f.formTab === ProfileTab.Compensation && f.formName === 'bank-account'
          )}
          defaultHolderName={`${onboardingState.basicInfo.firstName} ${onboardingState.basicInfo.lastName}`}
          onNext={(bankAccount) => {
            onboardingState.bankAccount = bankAccount;
            handleNext();
          }}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
          userId={userId}
          bankAccountValues={onboardingState.bankAccount}
        />
      ),
      Address: () => (
        <UserAddressSection
          form={onboardingState.forms?.find((f) => f.formTab === ProfileTab.Contact && f.formName === 'address')}
          onNext={(address) => {
            onboardingState.homeAddress = address;
            handleNext();
          }}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
          userId={userId}
          values={onboardingState.homeAddress}
        />
      ),

      Emergency: () => (
        <UserEmergencyContactSection
          form={onboardingState.forms?.find((f) => f.formTab === ProfileTab.Contact && f.formName === 'emergency')}
          onNext={(contact) => {
            onboardingState.emergencyContact = { ...(onboardingState?.emergencyContact ?? {}), ...contact };
            handleNext();
          }}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
          userId={userId}
          emergencyValues={onboardingState.emergencyContact}
        />
      ),

      About: () => (
        <UserAboutSection
          form={onboardingState.forms?.find((f) => f.formTab === ProfileTab.Personal && f.formName === 'about')}
          onNext={(about) => {
            onboardingState.about = { ...(onboardingState.about ?? {}), ...about };
            handleNext();
          }}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
          userId={userId}
          aboutValues={onboardingState.about}
        />
      ),
    };

    let customSectionsSteps = onboardingState.customSections.reduce((acc, section) => {
      acc[section.form.formName] = () => (
        <UserCustomSection
          key={section.form.formName}
          onNext={(values) => {
            section.values = values;
            handleNext();
          }}
          form={section.form}
          fields={section.values}
          userId={userId}
          sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
        />
      );
      return acc;
    }, {} as StepRenderFunctionType);

    return { ...defaultSection, ...customSectionsSteps } ?? {};
  }, [handleNext, onboardingState, userId]);

  const renderStep = (step: string) => {
    const renderFunction = stepRenderFunctions[step];
    return renderFunction ? renderFunction() : null;
  };

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30 }}>
      <Stack sx={{ flexFlow: 'row', width: '70%', mx: 'auto' }}>
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', left: spacing.m50 }}
          onClick={handleBack}
          disabled={isFirstStep()}
        >
          <Back {...iconSize} />
        </IconButton>
        <StepperComponent sx={{ flex: 1 }} steps={OnboardingStepperSteps} activeStep={activeStep} />
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', right: spacing.m50 }}
          onClick={() => routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }))}
        >
          <Close {...iconSize} stroke={themeColors.DarkGrey} />
        </IconButton>
      </Stack>
      <Stack sx={{ overflow: 'hidden auto', pb: spacing.p30 }}>{renderStep(activeStep)}</Stack>
    </Stack>
  );
};
