import { useContext, useMemo, useState } from 'react';

import { GlobalContext } from '@/GlobalState';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { CycleCreationVisibilitySettingsModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-visibility-settings/components/cycle-creation-visibility-settings-modal.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReviewCycleCreationVisibilitySettingsPage = ({
  cycleId,
  reach,
}: {
  cycleId: string;
  reach: ReachType;
}) => {
  const { data: reviewCycle, mutate: refreshCycle, isLoading: reviewCycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, reach),
    {
      suspense: false,
    }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['reviews:all']);
  const isManager = canAccessScopes(user, ['reviews:manager']);

  const showPeerSetting =
    !reviewCycle?.cycleSettings.allowRevieweesToInvitePeers &&
    Boolean(reviewCycle?.reviewerSelect?.includes(ReviewerTypes.Peer));

  const isEditable = useMemo(
    () =>
      Boolean(
        reviewCycle &&
          (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled) &&
          Boolean(isAdmin || (isManager && reviewCycle.owner === user.userId))
      ),
    [reviewCycle, isAdmin, isManager, user]
  );

  return (
    <SettingsSectionContent
      title={
        reviewCycle ? (
          <TitleStatusComponent reviewCycle={reviewCycle} reachType={reach} />
        ) : (
          <SkeletonLoader variant="text" />
        )
      }
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={reviewCycleLoading}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Visibility',
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.Pair,
                label: 'Require manager to release feedback',
                value: reviewCycle?.visibilitySettings?.allowManagerToReleaseFeedback ? 'Yes' : 'No',
              },
              ...(showPeerSetting
                ? [
                    {
                      type: SectionItemType.Pair,
                      label: "Hide peer's name",
                      value: reviewCycle?.visibilitySettings?.hidePeerAuthor ? 'Yes' : 'No',
                    },
                  ]
                : []),
            ],
          },
        ]}
      />

      <CycleCreationVisibilitySettingsModal
        reviewCycle={reviewCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refreshCycle?.();
        }}
        showPeerSetting={showPeerSetting}
      />
    </SettingsSectionContent>
  );
};
