import React, { useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Action } from '@/images/fields/Action.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SurveyTemplateAPI, SurveyTemplateEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-template.api';
import { UpsertSurveyTemplateModal } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-list/components/upsert-survey-template.component-modal';
import { SurveyTemplate } from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const SurveyTemplateListPage = () => {
  const routerHistory = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const {
    data: templates,
    mutate: refreshTemplates,
    isLoading: templatesLoading,
  } = useApiClient(SurveyTemplateEndpoints.getSurveyTemplatesCompanyId(searchInput), { suspense: false });

  const deleteAction = useCallback(
    async (templateId: string) => {
      try {
        await SurveyTemplateAPI.deleteTemplatebyId(templateId);
        showMessage('Succesfully deleted the item', 'success');
        refreshTemplates?.();
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    },
    [refreshTemplates, showMessage, polyglot]
  );

  const getTemplateActionsOptions = useCallback(
    (original: SurveyTemplate) => {
      const menuOptions = [
        {
          icon: <Edit {...iconSize} />,
          handler: () => {
            routerHistory.push(
              generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE, {
                templateId: original?.id,
              })
            );
          },
          label: 'Edit item',
          disabled: false,
        },
        {
          icon: <Trash {...iconSize} />,
          handler: () => {
            deleteAction(original.id);
          },
          label: 'Delete item',
          disabled: false,
        },
      ];
      return menuOptions;
    },
    [deleteAction, routerHistory]
  );

  const tableColumns = useMemo<ColumnDef<SurveyTemplate, SurveyTemplate>[]>(
    () => [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: false,

        cell: ({
          row: {
            original: { name },
          },
        }) => (name ? <div>{name} </div> : <EmptyCell />),
        minSize: 180,
        maxSize: 350,
      },
      {
        header: () => 'Created by',
        accessorFn: (row) => row,
        id: 'createdby',
        enableSorting: false,

        cell: ({
          row: {
            original: { createdBy, companyId },
          },
        }) => (
          <div>
            {companyId === null ? (
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" />
                Zelt
              </Stack>
            ) : createdBy ? (
              <UserCell userId={createdBy} />
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
        minSize: 90,
        maxSize: 120,
      },
      {
        header: () => 'Created on',
        accessorFn: (row) => row,
        id: 'createdAt',
        enableSorting: false,
        minSize: 50,
        maxSize: 120,

        cell: ({
          row: {
            original: { createdAt },
          },
        }) =>
          createdAt ? (
            <div>
              {new LocalDate(createdAt)
                .getDate()
                .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
            </div>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,

        cell: ({ row: { original } }) =>
          original.companyId && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledMenuComponent
                options={getTemplateActionsOptions(original)}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'light',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <Action {...iconSize} />,
                }}
              />
            </Box>
          ),
        minSize: 50,
        maxSize: 100,
      },
    ],
    [getTemplateActionsOptions]
  );

  const handleRowClick = useCallback(
    (row: Row<SurveyTemplate>) => {
      routerHistory.push(
        generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE, {
          templateId: row.original?.id,
        })
      );
    },
    [routerHistory]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Templates</Typography>}
        showAction={true}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsOpen(true)}>
            New template
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={false}>
        <Box sx={{ mb: spacing.m16 }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>

        <BasicTable<SurveyTemplate>
          rowData={templates ?? []}
          columnData={tableColumns}
          loading={templatesLoading}
          rowClick={handleRowClick}
        />

        <UpsertSurveyTemplateModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          template={undefined}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
