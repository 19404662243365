import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProps, FormikProvider, useFormik } from 'formik';

import { NotificationAPI } from '@/api-client/index.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ApproverSelectComponent } from '@/v2/components/approver-select/approver-select.component';
import { ApproverGroup, PresetApprover } from '@/v2/components/approver-select/approver-select.interface';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { MultipleSelectCheckbox } from '@/v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { OptionObj } from '@/v2/components/user-select-type/user-select.component';
import { CombinedAllFields } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { NotificationStatus } from '@/v2/feature/notification/notification-settings/features/components/notifications-setting-table.component';
import { DItem } from '@/v2/feature/notification/notification-settings/features/notification-setting.page';
import {
  ATTENDANCE_NOTIFICATIONS,
  DAYS_OF_THE_WEEK_OPTIONS,
  DOMAINS_WITH_BEFORE,
  DefaultNotifications,
  EMPLOYEE_TRIGGER_EVENTS,
  NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK,
  NOTIFICATIONS_TO_HIDE_SPECIFIC,
  NotificationForTable,
  NotificationSendToHumanValue,
  NotificationSettingsForEvent,
  NotificationSystemName,
  NotificationWhen,
  ProfileNotifications,
  REMINDER_SCHEDULE_OPTIONS,
  USER_NOTIFICATIONS_LIST,
  WhenFilterEnum,
  WhenFilterOptions,
  DEVICE_NOTIFICATIONS,
} from '@/v2/feature/notification/notification-settings/notification.interface';
import { addDefaultUserDateFields } from '@/v2/feature/notification/notification.util';
import { ProfileNotificationAPI } from '@/v2/feature/notification/profile-notification.api';
import { HOUR_OF_THE_DAY_OPTIONS } from '@/v2/feature/user/features/user-notifications/user-notifications.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

interface NotificationsSettingsEditFormDrawerProps {
  notificationToEdit: NotificationForTable;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allSlackChannels: readonly DItem[];
  preSelectedEmailRecipients?: string;
  slackConnected: boolean;
  refreshNotificationData: () => void;
  allDateFields: CombinedAllFields | null | undefined;
}

export const NotificationsSettingEditFormDrawer = ({
  notificationToEdit,
  setIsOpen,
  allSlackChannels,
  slackConnected,
  refreshNotificationData,
  allDateFields,
}: NotificationsSettingsEditFormDrawerProps) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>(
    notificationToEdit.systemName &&
      DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) &&
      notificationToEdit?.daysBefore &&
      notificationToEdit.daysBefore > 0
      ? WhenFilterEnum.BEFORE
      : WhenFilterEnum.IMMEDIATE
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSubmitForUserNotifications = async (values: NotificationForTable) => {
    const { emailEnabled, slackEnabled, sendToEditValue, cronHour, slackChannels } = values;
    const slackChannelValues = slackChannels?.map((d) => d.value);
    const specificUserList = sendToEditValue?.userIds?.length > 0 ? sendToEditValue?.userIds : [];
    const payload: NotificationSettingsForEvent = {
      email: emailEnabled,
      slack: slackChannelValues && slackChannelValues?.length > 0 && slackEnabled ? slackEnabled : false,
      emails: specificUserList,
      specificUsers: specificUserList,
      sendToEveryone: sendToEditValue?.userIds?.length <= 0 && sendToEditValue.presets.includes('everyone'),
      cronHour: cronHour ?? 0,
      slackChannels: slackChannelValues ?? [],
    };
    if (notificationToEdit.systemName)
      await NotificationAPI.updateNotificationSettings(notificationToEdit.systemName, payload);
  };

  const handleSubmitForProfileNotifications = async (values: NotificationForTable) => {
    const {
      id,
      systemName,
      emailEnabled,
      sendToEditValue,
      createdAt,
      slackChannels,
      notificationText,
      employeeTriggerEvent,
      name,
      when,
    } = values;
    const isOnboardingReminder =
      name === DefaultNotifications.onboardingReminder || systemName === NotificationSystemName.securityDigest;
    const isProfileNotification = systemName === NotificationSystemName.profileNotification;
    const [fieldId, formId] = isProfileNotification ? (employeeTriggerEvent ?? '')?.split('/') : [];
    const slackChannelValues = slackChannels?.map((d) => d.value);
    const payload: Partial<ProfileNotifications> = {
      id,
      domain: systemName,
      sendAdmin: sendToEditValue?.presets?.includes('admin'),
      sendManager: sendToEditValue?.presets?.includes('manager:1'),
      sendSpecificUsers: sendToEditValue?.userIds?.length > 0 ? true : false,
      email: emailEnabled,
      specificUserEmails: sendToEditValue?.userIds,
      createdAt,
      daysBefore:
        isOnboardingReminder && when === NotificationWhen.ONCE
          ? null
          : isOnboardingReminder && [NotificationWhen.WEEKLY, NotificationWhen.DAILY].includes(when as NotificationWhen)
          ? 0
          : filterValue === WhenFilterEnum.BEFORE
          ? values.daysBefore
          : 0,
      updatedAt: new Date(),
      cronHour: isProfileNotification ? values?.cronHour : undefined,
      slack: isProfileNotification ? values?.slackEnabled : undefined,
      slackChannels: isProfileNotification ? slackChannelValues : [],
      notificationText: isProfileNotification ? notificationText : undefined,
      fieldId: isProfileNotification ? fieldId : undefined,
      formId: isProfileNotification ? formId : undefined,
      name: isProfileNotification || isOnboardingReminder ? name : undefined,
      sendSlackBot: isProfileNotification ? values?.sendSlackBot : undefined,
      weeklyDay:
        isOnboardingReminder && when === NotificationWhen.WEEKLY
          ? 1
          : isOnboardingReminder && when !== NotificationWhen.WEEKLY
          ? null
          : undefined,
    };
    await ProfileNotificationAPI.updateNotificationSettings(payload);
  };

  const handleSubmitForAttendanceNotifications = async (values: NotificationForTable) => {
    const { id, systemName, emailEnabled, sendToEditValue, createdAt, weeklyDay } = values;

    const payload: Partial<ProfileNotifications> = {
      id,
      domain: systemName,
      sendAdmin: sendToEditValue?.presets?.includes('admin'),
      sendManager: sendToEditValue?.presets?.includes('manager:1'),
      sendSpecificUsers: sendToEditValue?.userIds?.length > 0 ? true : false,
      email: emailEnabled,
      specificUserEmails: sendToEditValue?.userIds,
      weeklyDay,
      createdAt,
      updatedAt: new Date(),
    };
    await ProfileNotificationAPI.updateNotificationSettings(payload);
  };

  const handleSubmit = async (values: NotificationForTable) => {
    try {
      setLoading(true);
      if (notificationToEdit.systemName && USER_NOTIFICATIONS_LIST.includes(notificationToEdit.systemName)) {
        await handleSubmitForUserNotifications(values);
      } else if (notificationToEdit.systemName && ATTENDANCE_NOTIFICATIONS.includes(notificationToEdit.systemName)) {
        await handleSubmitForAttendanceNotifications(values);
      } else if (notificationToEdit.systemName && DEVICE_NOTIFICATIONS.includes(notificationToEdit.systemName)) {
        await handleSubmitForAttendanceNotifications(values);
      } else {
        await handleSubmitForProfileNotifications(values);
      }
      setTimeout(() => {
        refreshNotificationData();
        setIsOpen(false);
        formik.resetForm();
        showMessage(
          polyglot.t('NotificationsSettingEditFormDrawer.successMessages.update', { name: notificationToEdit.name }),
          'success'
        );
        setLoading(false);
      }, 1000);
    } catch (e) {
      showMessage(polyglot.t('NotificationsSettingsEdit.errorMessages.cannotbeSaved'), 'error');
      setLoading(false);
    }
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
    if (event) setAnchorEl(event.currentTarget);
    setIsConfirmModalOpen(true);
  };

  const handleCustomProfileNotificationDelete = async (notificationId: number) => {
    try {
      setLoading(true);
      await ProfileNotificationAPI.deleteCustomProfileNotification(notificationId);
      setTimeout(() => {
        refreshNotificationData();
        setIsOpen(false);
        formik.resetForm();
        showMessage(polyglot.t('NotificationsSettingsEdit.successMessages.delete'), 'success');
        setLoading(false);
      }, 1000);
    } catch (e) {
      showMessage(polyglot.t('NotificationsSettingsEdit.errorMessages.delete'), 'error');
      setLoading(false);
    }
  };

  const employeeTriggerEventFormAndField = notificationToEdit?.employeeTriggerEvent?.split('/');
  let triggerField: string | undefined;
  let triggerFormId: string | undefined;

  if (Array.isArray(employeeTriggerEventFormAndField) && employeeTriggerEventFormAndField.length === 2) {
    [triggerField, triggerFormId] = employeeTriggerEventFormAndField;
  }

  const isDefaultEmployeeDateProfileNotification =
    triggerField && (!triggerFormId || triggerFormId === 'null') ? EMPLOYEE_TRIGGER_EVENTS.has(triggerField) : false;

  const formik = useFormik({
    initialValues: {
      ...notificationToEdit,
      ...(isDefaultEmployeeDateProfileNotification ? { employeeTriggerEvent: triggerField } : {}),
    },
    enableReinitialize: true,
    onSubmit: async (values: NotificationForTable) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    setFilterValue(
      notificationToEdit.systemName &&
        DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) &&
        notificationToEdit?.daysBefore &&
        notificationToEdit.daysBefore > 0
        ? WhenFilterEnum.BEFORE
        : WhenFilterEnum.IMMEDIATE
    );
  }, [notificationToEdit]);

  const toggleNotificationStatus = async (skipMessage = false) => {
    try {
      if (!skipMessage) setLoading(true);
      if (notificationToEdit.systemName) {
        if (USER_NOTIFICATIONS_LIST.includes(notificationToEdit.systemName)) {
          await NotificationAPI.toggleNotificationStatus(notificationToEdit.systemName, !notificationToEdit.status);
        } else {
          if (notificationToEdit.id)
            await ProfileNotificationAPI.toggleNotificationStatus(
              notificationToEdit.id,
              notificationToEdit.systemName,
              !notificationToEdit.status
            );
        }
      }
      refreshNotificationData();
      setIsOpen(false);
      formik.resetForm();
      if (!skipMessage)
        showMessage(
          notificationToEdit.status
            ? polyglot.t('NotificationsSettingEditFormDrawer.successMessages.disable', {
                name: notificationToEdit.name,
              })
            : polyglot.t('NotificationsSettingEditFormDrawer.successMessages.enable', {
                name: notificationToEdit.name,
              }),
          'success'
        );
    } catch (error) {
      showMessage(
        polyglot.t('NotificationsSettingEditFormDrawer.errorMessages.toggle', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    } finally {
      if (!skipMessage) setLoading(false);
    }
  };

  const saveAndEnable = async () => {
    try {
      await handleSubmit(formik.values);
      await toggleNotificationStatus(true);
    } catch (error) {
      showMessage(
        polyglot.t('NotificationsSettingEditFormDrawer.errorMessages.save', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    }
  };

  const handleCronHourChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('cronHour', (event.target as HTMLInputElement)?.value);
  };

  const handleWeeklyDayChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('weeklyDay', (event.target as HTMLInputElement)?.value);
  };

  const handleReminderScheduleChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('when', (event.target as HTMLInputElement)?.value);
  };

  const isCustomProfileNotification = formik.values.systemName === NotificationSystemName.profileNotification;

  const handleEmployeeTriggerEvent = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('employeeTriggerEvent', (event.target as HTMLInputElement)?.value);
  };

  const allDateFieldOptions: OptionObj[] = useMemo(() => {
    const result: OptionObj[] = [];
    if (allDateFields && allDateFields.dataFieldsCustom) {
      for (const eachCustom of allDateFields.dataFieldsCustom) {
        result.push({
          value: `${eachCustom.fieldId}/${eachCustom.formId}`,
          label: eachCustom.fieldName,
        });
      }
    }
    // default employee fields;
    return addDefaultUserDateFields(result);
  }, [allDateFields]);

  const sendToAdmin = formik.values?.sendToEditValue?.presets?.includes('admin');
  const sendToManager = formik.values?.sendToEditValue?.presets?.includes('manager:1');

  const suffixLabelForSlackBot = useMemo(() => {
    const botRecipients = [];
    if (sendToAdmin) botRecipients.push('Admin');
    if (sendToManager) botRecipients.push('Manager');
    return botRecipients.length > 0 ? ` to ${botRecipients.join(', ')}` : '';
  }, [sendToAdmin, sendToManager]);

  const shouldHideEveryone = (formik: FormikProps<NotificationForTable>) => {
    if (formik.values.systemName === NotificationSystemName.profileNotification) return true;
    if (
      formik.values.systemName &&
      NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) &&
      formik.values.sendToEditValue.userIds?.length === 0
    )
      return false;
    return true;
  };

  const getHidePresets = (formik: FormikProps<NotificationForTable>): PresetApprover[] => {
    if (
      formik.values.systemName &&
      formik.values.systemName !== NotificationSystemName.profileNotification &&
      NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName)
    ) {
      return ['admin', 'manager:1'];
    } else if (formik.values.systemName && NotificationSystemName.securityDigest === formik.values.systemName) {
      return ['manager:1'];
    } else if (formik.values.systemName && NotificationSystemName.userSecurityDigest === formik.values.systemName) {
      return ['manager:1', 'everyone', 'admin'];
    } else {
      return ['everyone'];
    }
  };

  const shouldHideSpecific = (formik: FormikProps<NotificationForTable>) => {
    // human readable conditions - start
    const EVERYONE_NOT_ALREADY_SELECTED_AND_NOTIFICATION_CAN_USE_SLACK =
      formik.values.systemName &&
      NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) &&
      !formik.values.sendToEditValue.presets.includes('everyone');
    const NOTIFICATION_DOES_NOT_USE_SLACK_AND_NOT_ATTENDANCE_NOTIFICATION =
      formik.values.systemName &&
      !NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) &&
      !ATTENDANCE_NOTIFICATIONS.includes(formik.values.systemName);
    // human readable conditions - end

    if (formik.values.systemName && NOTIFICATIONS_TO_HIDE_SPECIFIC.includes(formik.values.systemName)) return true;

    if (
      EVERYONE_NOT_ALREADY_SELECTED_AND_NOTIFICATION_CAN_USE_SLACK ||
      NOTIFICATION_DOES_NOT_USE_SLACK_AND_NOT_ATTENDANCE_NOTIFICATION
    )
      return false;
    return true;
  };

  const isOnboardingReminder = formik.values.name === DefaultNotifications.onboardingReminder;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: spacing.g20 }}>
          <Typography sx={{ ...themeFonts.title2 }}>
            {polyglot.t('NotificationSettingsPage.editNotification')}
          </Typography>
          {notificationToEdit.id && notificationToEdit.systemName === NotificationSystemName.profileNotification && (
            <IconButton
              sx={{ ...iconButtonSx }}
              onClick={(event) => confirmDelete(event)}
              title="Remove"
              disabled={loading}
            >
              <TrashIcon {...actionIconSize} />
            </IconButton>
          )}
        </Box>
        {!isCustomProfileNotification && (
          <Box
            sx={{
              ...spacing.mt40,
              display: 'flex',
              flexDirection: 'row',
              gap: spacing.g20,
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('NotificationsSettingEditFormDrawer.notificationName')}
            </Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('NotificationsSettingEditFormDrawer.status')}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            mt: isCustomProfileNotification ? spacing.mt40 : spacing.m5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isCustomProfileNotification ? (
            <Stack direction={'column'}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.mb5 }}>
                {polyglot.t('NotificationsSettingEditFormDrawer.notificationName')}
              </Typography>
              <TextfieldComponent
                label=""
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                type="text"
                clearText={() => formik.setFieldValue('name', '')}
                sx={{ minWidth: '270px' }}
              />
            </Stack>
          ) : (
            <Typography sx={{ ...themeFonts.title4 }}>{notificationToEdit.name}</Typography>
          )}
          {!isCustomProfileNotification ? (
            <Typography sx={{ ...themeFonts.caption }}>
              <NotificationStatus status={notificationToEdit?.status} />
            </Typography>
          ) : (
            <Stack direction={'column'}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.mb5 }}>
                {polyglot.t('NotificationsSettingEditFormDrawer.status')}
              </Typography>
              <Typography sx={{ ...themeFonts.caption }}>
                <NotificationStatus status={notificationToEdit?.status} />
              </Typography>
            </Stack>
          )}
        </Box>
        {isCustomProfileNotification && (
          <Box
            sx={{
              ...spacing.mt15,
              display: 'flex',
              flexDirection: 'row',
              gap: spacing.g20,
              justifyContent: 'space-between',
            }}
          >
            <TextfieldComponent
              label={polyglot.t('NotificationsSettingEditFormDrawer.notificationText')}
              name="notificationText"
              value={formik.values.notificationText}
              onChange={formik.handleChange}
              error={formik.touched.notificationText && !!formik.errors.notificationText}
              helperText={formik.touched.notificationText && formik.errors.notificationText}
              type="text"
              clearText={() => formik.setFieldValue('notificationText', '')}
            />
          </Box>
        )}
        {isCustomProfileNotification && (
          <Box sx={{ mt: spacing.mt15 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('NotificationsSettingsEdit.employeeTriggerEvent')}
            </Typography>
            <SelectComponent
              name="employeeTriggerEvent"
              label={''}
              options={allDateFieldOptions}
              value={formik.values.employeeTriggerEvent}
              compareValue={formik.values.employeeTriggerEvent}
              error={undefined}
              onChange={handleEmployeeTriggerEvent}
              helperText={undefined}
              fullWidth
            />
          </Box>
        )}
        <Stack display={'flex'} direction={'column'} sx={{ ...spacing.mt20 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.mb5 }}>
            {polyglot.t('NotificationsSettingEditFormDrawer.sendTo')}
          </Typography>
          {isOnboardingReminder || NotificationSystemName.userSecurityDigest === formik.values.systemName ? (
            <Typography sx={{ ...themeFonts.title4, mb: spacing.mb20 }}>
              {NotificationSendToHumanValue.allEmployees}
            </Typography>
          ) : (
            <ApproverSelectComponent
              hideEveryone={shouldHideEveryone(formik)}
              hidePresets={getHidePresets(formik)}
              hideSpecific={shouldHideSpecific(formik)}
              hideAutoApprove
              value={formik.values.sendToEditValue}
              userModalTitle={polyglot.t('NotificationsSettingEditFormDrawer.userModalTitle')}
              onChange={(value: ApproverGroup) => {
                formik.setFieldValue('sendToEditValue', value);
              }}
              sx={{ mb: spacing.mb20 }}
            />
          )}
          {notificationToEdit.systemName &&
            !(
              ATTENDANCE_NOTIFICATIONS.includes(notificationToEdit.systemName) ||
              DEVICE_NOTIFICATIONS.includes(notificationToEdit.systemName)
            ) && (
              <Stack sx={{ mt: spacing.m5 }} gap={spacing.g10}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>When</Typography>
                {isOnboardingReminder ? (
                  <SelectComponent
                    name="reminderSchedule"
                    label={''}
                    options={REMINDER_SCHEDULE_OPTIONS}
                    value={formik.values.when}
                    compareValue={formik.values.reminderSchedule}
                    error={undefined}
                    onChange={handleReminderScheduleChange}
                    helperText={undefined}
                    fullWidth
                  />
                ) : (
                  <>
                    <TabFilterButtons
                      disabled={
                        notificationToEdit?.systemName && !DOMAINS_WITH_BEFORE.includes(notificationToEdit?.systemName)
                      }
                      filters={WhenFilterOptions(polyglot)}
                      setFilterValue={setFilterValue}
                      filterValue={filterValue}
                      onFilterChange={({ filterValue }) => {
                        setFilterValue(filterValue);
                        formik.setFieldValue('when', filterValue);
                      }}
                    />
                    {filterValue === WhenFilterEnum.BEFORE && (
                      <TextfieldComponent
                        name="daysBefore"
                        label={polyglot.t('NotificationsSettingEditFormDrawer.daysBefore')}
                        value={formik.values.daysBefore}
                        type="number"
                        onChange={formik.handleChange}
                        error={formik.touched.daysBefore && !!formik.errors.daysBefore}
                        helperText={(formik.touched.daysBefore && formik.errors.daysBefore) ?? ' '}
                        clearText={() => formik.setFieldValue('daysBefore', '')}
                      />
                    )}
                  </>
                )}
              </Stack>
            )}

          {notificationToEdit.systemName &&
            (ATTENDANCE_NOTIFICATIONS.includes(notificationToEdit.systemName) ||
              DEVICE_NOTIFICATIONS.includes(notificationToEdit.systemName)) && (
              <Box>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                  {polyglot.t('NotificationsSettingsEdit.dayOfTheWeek')}
                </Typography>
                <SelectComponent
                  name="week"
                  label={''}
                  options={DAYS_OF_THE_WEEK_OPTIONS}
                  value={formik.values.weeklyDay}
                  compareValue={formik.values.weeklyDay}
                  error={undefined}
                  onChange={handleWeeklyDayChange}
                  helperText={undefined}
                  fullWidth
                />
              </Box>
            )}
          <Stack sx={{ mt: spacing.mt20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('NotificationsSettingEditFormDrawer.channel')}
            </Typography>
            {notificationToEdit && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: spacing.mt10 }}>
                <CheckboxComponent
                  label={'Email'}
                  name={'emailEnabledSetting'}
                  checked={formik.values.emailEnabled}
                  value={formik.values.emailEnabled}
                  onChange={(e, checked) => {
                    formik.setFieldValue('emailEnabled', checked);
                  }}
                />
              </Box>
            )}
            {isCustomProfileNotification && (sendToAdmin || sendToManager) && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: spacing.mt20 }}>
                <CheckboxComponent
                  label={polyglot.t('NotificationsSettingEditFormDrawer.slackBotSettings', {
                    suffix: suffixLabelForSlackBot,
                  })}
                  name={'slackBotSetting'}
                  checked={formik.values.sendSlackBot}
                  value={formik.values.sendSlackBot}
                  onChange={(e, checked) => {
                    formik.setFieldValue('sendSlackBot', checked);
                    formik.setFieldValue(
                      'slackEnabled',
                      (formik.values.slackChannels && formik.values.slackChannels?.length > 0) || checked
                    );
                  }}
                />
              </Box>
            )}
            {notificationToEdit &&
              slackConnected &&
              notificationToEdit.systemName &&
              NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(notificationToEdit.systemName) && (
                <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: spacing.mt20 }}>
                  <CheckboxComponent
                    label={polyglot.t('NotificationsSettingsEdit.slackChannels')}
                    name={'slackEnabledSetting'}
                    checked={!!(formik.values.slackChannels && formik.values.slackChannels?.length > 0)}
                    value={formik.values.slackEnabled}
                    onChange={(e, checked) => {
                      formik.setFieldValue('slackEnabled', checked);
                      const updatedValues =
                        checked && formik.values.slackChannels ? [...formik.values.slackChannels] : [];
                      formik.setFieldValue('slackChannels', updatedValues);
                    }}
                  />
                </Box>
              )}
          </Stack>
          {notificationToEdit && slackConnected && formik.values.slackEnabled && (
            <Box sx={{ mt: spacing.mt20 }}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                {polyglot.t('NotificationsSettingsEdit.slackChannels')}
              </Typography>
              <MultipleSelectCheckbox<string>
                label={''}
                id={'slackChannel'}
                limitTags={-1}
                options={allSlackChannels}
                value={formik.values.slackChannels ?? []}
                onChange={(e, values) => {
                  formik.setFieldValue('slackChannels', values);
                }}
                isOptionEqualToValue={(x, y) => x.value === y.value}
                getOptionLabel={({ label }: { label: string }): string => label}
              />
            </Box>
          )}
          {notificationToEdit &&
            formik.values &&
            notificationToEdit.systemName &&
            NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(notificationToEdit.systemName) && (
              <Box sx={{ mt: spacing.mt20 }}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                  {polyglot.t('NotificationsSettingsEdit.hourToSendReminders')}
                </Typography>
                <SelectComponent
                  name="cronHour"
                  label={''}
                  options={HOUR_OF_THE_DAY_OPTIONS}
                  value={formik.values.cronHour}
                  compareValue={formik.values.cronHour}
                  error={undefined}
                  onChange={handleCronHourChange}
                  helperText={undefined}
                  fullWidth
                />
              </Box>
            )}
        </Stack>
        {formik.values.status === true ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: spacing.g10,
              width: '100%',
              ...spacing.mt20,
            }}
          >
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="medium" // disable enabling notification if slack and email are both disabled
              disabled={loading || isOnboardingReminder} // cannot disable onboardingReminder - can only change schedule
              fullWidth
              onClick={() => toggleNotificationStatus(false)}
            >
              {polyglot.t('NotificationsSettingEditFormDrawer.disable')}
            </ButtonComponent>
            <LoaderButton
              name={polyglot.t('General.save')}
              fullWidth
              disabled={loading}
              loading={loading}
              colorVariant="primary"
              sizeVariant="medium"
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: spacing.g10,
              width: '100%',
              ...spacing.mt20,
            }}
          >
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="medium"
              disabled={loading}
              fullWidth
              onClick={saveAndEnable}
            >
              {polyglot.t('General.saveAndEnable')}
            </ButtonComponent>
          </Box>
        )}
        <NotificationModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
          }}
          anchorEl={anchorEl}
          takeAction={() => {
            if (notificationToEdit && notificationToEdit.id) {
              setIsConfirmModalOpen(false);
              handleCustomProfileNotificationDelete(notificationToEdit.id);
            } else showMessage('No department selected', 'error');
          }}
          message={polyglot.t('NotificationsSettingEditFormDrawer.confirmDeleteMessage')}
          callToAction="Yes"
        />
      </Form>
    </FormikProvider>
  );
};
