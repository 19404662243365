import { useCallback, useMemo, useState } from 'react';

import { ColumnDef, Row } from '@tanstack/react-table';
import { EmptyStateBox } from '@v2/components/empty-state-box.component';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReviewEntryEndpoints } from '@/v2/feature/growth/reviews/api-client/review-entry.api';
import { ReviewEntriesPersonalStartModal } from '@/v2/feature/growth/reviews/features/review-personal/review-entry/components/review-entries-personal-start-modal.component';
import {
  getEntryStatus,
  getReviewDueDate,
  getReviewType,
} from '@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util';
import { ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';

export const ReviewPersonalEntries = ({ userId }: { userId: number }) => {
  const { data: userEntries, isValidating: validationgUserEntries, isLoading: loadingUserEntries } = useApiClient(
    ReviewEntryEndpoints.getUserEntries(userId),
    {
      suspense: false,
    }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<ReviewEntry | undefined>(undefined);

  const tableColumns = useMemo<ColumnDef<ReviewEntry, ReviewEntry>[]>(
    () => [
      {
        header: () => 'Cycle name',
        id: 'cycle.name',
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>{original.cycle?.displayName || original.cycle?.internalName || <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'Reviewee',
        id: 'revieweeId',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { revieweeId },
          },
        }) => (revieweeId ? <div>{<UserCell userId={revieweeId} key={revieweeId} />}</div> : <EmptyCell />),
      },
      {
        header: () => 'Review type',
        id: 'type',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (original ? <div>{getReviewType(original)}</div> : <EmptyCell />),
      },
      {
        header: () => 'Due date',
        id: 'duedate',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>
            {getReviewDueDate(original)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          </div>
        ),
      },
      {
        header: () => 'Status',
        id: 'state',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{getEntryStatus()[original.entryState]}</div>,
      },
    ],
    []
  );

  const handleRowClick = useCallback((row: Row<ReviewEntry>) => {
    if (row.original.cycle?.state !== CycleState.Completed) {
      setIsOpen(true);
      setSelectedEntry(row.original);
    }
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Invites</Typography>} />
      <ContentWrapper loading={loadingUserEntries}>
        {!loadingUserEntries && userEntries && userEntries.length === 0 ? (
          <EmptyStateBox
            title="Your invites"
            subtitle="Here you will find invitations to performance reviews for yourself or your colleagues. At the moment you have no invites pending."
          />
        ) : (
          <BasicTable
            rowData={userEntries ? [...userEntries] : []}
            columnData={tableColumns}
            loading={validationgUserEntries}
            hidePagination={true}
            rowClick={handleRowClick}
          />
        )}

        <ReviewEntriesPersonalStartModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          afterClose={() => setSelectedEntry(undefined)}
          selectedEntry={selectedEntry}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
