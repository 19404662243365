import React, { useState } from 'react';

import { Box } from '@mui/system';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
// @ts-expect-error
import TranslationsImportCsvTemplate from '@v2/feature/entity-import/wizard/template-samples/zelt-translations-import-template.csv';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';

const TRANSLATIONS_IMPORT_CSV_ENDPOINT = '/apiv2/companies/translations/import/csv';

interface CompanyTranslationsCsvUploadDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const CompanyTranslationsCsvUploadDrawer = ({
  isOpen,
  setIsOpen,
  refresh,
}: CompanyTranslationsCsvUploadDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <CompanyTranslationsCsvUploadDrawerContent refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface CompanyTranslationsCsvUploadDrawerContentProps {
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyTranslationsCsvUploadDrawerContent = ({
  refresh,
  setIsOpen,
}: CompanyTranslationsCsvUploadDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [uploadResponse, setUploadResponse] = useState<
    | {
        imported: boolean;
        importedCount?: number;
        invalidEntries?: number;
      }
    | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const handleUpload = async (
    result:
      | {
          imported: boolean;
          importedCount?: number;
          invalidEntries?: number;
        }
      | undefined
  ) => {
    setLoading(true);
    try {
      setUploadResponse(result);

      await refresh();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
    setLoading(false);
  };

  return (
    <Box>
      <Typography variant="title2" sx={{ mb: spacing.m40 }}>
        Translations CSV Upload
      </Typography>

      {!uploadResponse && !loading && (
        <Box width="100%">
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            Please use{' '}
            <a href={TranslationsImportCsvTemplate} rel="noreferrer" style={{ color: themeColors.DarkGrey }}>
              this template
            </a>{' '}
            for your CSV.
          </Typography>

          <UploadInput<{
            imported: boolean;
            importedCount?: number;
            invalidEntries?: number;
          }>
            onChange={async (response) => await handleUpload(response)}
            endpoint={TRANSLATIONS_IMPORT_CSV_ENDPOINT}
          />
        </Box>
      )}

      {uploadResponse && !uploadResponse.imported && uploadResponse.invalidEntries! > 0 && (
        <Typography variant="caption">
          There have been found {uploadResponse.invalidEntries} invalid entries. Please adjust your csv and try again
        </Typography>
      )}

      {uploadResponse?.imported && uploadResponse.importedCount! >= 0 && (
        <Typography variant="caption">
          {uploadResponse.importedCount} entr{uploadResponse.importedCount === 1 ? 'y has' : 'ies have'} been
          successfully imported. You can close the drawer now.
        </Typography>
      )}

      <Box sx={{ ...buttonBoxSx, mt: spacing.m40, display: 'flex', gap: spacing.g10 }}>
        {uploadResponse?.imported && (
          <ButtonComponent
            sizeVariant="large"
            colorVariant="secondary"
            onClick={() => setIsOpen(false)}
            type="button"
            fullWidth
          >
            {polyglot.t('General.close')}
          </ButtonComponent>
        )}
        {uploadResponse && !uploadResponse?.imported && (
          <ButtonComponent
            sizeVariant="large"
            colorVariant="secondary"
            onClick={() => setUploadResponse(undefined)}
            type="button"
            fullWidth
          >
            Try again
          </ButtonComponent>
        )}
      </Box>
    </Box>
  );
};
