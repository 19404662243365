import type { SystemStyleObject } from '@mui/system';

import { spacing } from '@/v2/styles/spacing.styles';

export const chartBoxSx: SystemStyleObject = {
  display: { sm: 'block', md: 'block', lg: 'flex' },
  gap: { sm: spacing.g20, md: spacing.g40, lg: spacing.g80 },
  width: '100%',
};

export const oneChartSx: SystemStyleObject = {
  width: { sm: '100%', md: '100%', lg: '100%' },
};

export const parentChartSx: SystemStyleObject = {
  width: { sm: '100%', md: '100%', lg: '65%' },
};

export const childChartSx: SystemStyleObject = {
  width: { sm: '100%', md: '100%', lg: '35%' },
};

export const equalChartSx: SystemStyleObject = {
  width: { sm: '100%', md: '100%', lg: '50%' },
};
