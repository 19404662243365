import { useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { ReactComponent as NextBtn } from '@/images/side-bar-icons/NextBtn.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  AboutForm,
  AboutSchema,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-about-form.component';
import {
  AboutValues,
  getDietaryOptions,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { isDataEmpty } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { UserAPI } from '@/v2/feature/user/user.api';
import { MissingField } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SCOPE = 'user.about' as const;
const iconSize = { width: 14, height: 14 } as const;
const skeletonHeight = calculateSkeletonHeight(AboutSchema);

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: AboutValues) => void;
  readonly missingFields?: MissingField[] | null;
}

export const AboutCard = ({ userId, onSubmit, missingFields }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [dataO, setDataO] = useState<O.Option<AboutValues>>(() => O.none);
  const [isEmpty, setEmpty] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await UserAPI.getUserAbout(userId);
        setDataO(O.some(data));

        setEmpty(isDataEmpty(data, data.customUpdates));
      } catch (error) {
        showMessage(polyglot.t('AboutCard.errorMessages.load'), 'error');
      }
    })();
  }, [polyglot, showMessage, userId, isOpen]);

  const getDomain = (url: string) => {
    if (url.includes('http')) return url;
    return `https://${url}`;
  };

  return pipe(
    dataO,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      (data) => (
        <ProfileCard
          customUpdates={data.customUpdates}
          fieldStubs={['about', 'hobbies', 'social', 'foodPreferences', 'dietaryRestrictions']}
          sx={cardSx}
        >
          <Box component="header" sx={cardHeaderSx}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <Typography variant="h3" sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                {polyglot.t('AboutCard.other')}
              </Typography>

              <ScopesControl scopes={['user.about:read']} context={scopesContext}>
                {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.About) && (
                  <Box sx={hazardMissingField}>
                    <Hazard {...hazardSize} />
                  </Box>
                )}
              </ScopesControl>
            </Box>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton onClick={() => setIsOpen(true)} title="Edit" sx={tableIconButtonSx}>
                {isEmpty ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
              </IconButton>
            </ScopesControl>
          </Box>
          <Box component="section">
            {isEmpty ? (
              <MissingInformationLabel />
            ) : (
              <Box component="dl" sx={definitionListSx}>
                <FieldStructure fieldTitle={polyglot.t('AboutCard.about')} fieldValue={data.about} fieldStub="about" />
                <FieldStructure
                  fieldTitle={polyglot.t('AboutCard.hobbies')}
                  fieldValue={data.hobbies}
                  fieldStub="hobbies"
                />
                {data?.social && (
                  <FieldStructure
                    fieldTitle={polyglot.t('AboutCard.social')}
                    fieldValue={{
                      isLink: true,
                      label: polyglot.t('AboutCard.showProfile'),
                      icon: <NextBtn {...iconSize} />,
                      onClick: () => {
                        if (data?.social) window.open(getDomain(data?.social), '_blank');
                      },
                    }}
                    fieldStub="social"
                  />
                )}
                <FieldStructure
                  fieldTitle={polyglot.t('AboutCard.foodPreferences')}
                  fieldValue={data.foodPreferences}
                  fieldStub="foodPreferences"
                />
                <FieldStructure
                  fieldTitle={polyglot.t('AboutCard.dietaryRestrictions')}
                  fieldValue={
                    data.dietaryRestrictions &&
                    (() => {
                      const restrictionsList = data.dietaryRestrictions.trim().split(/\s*,\s*/);
                      return getDietaryOptions(polyglot)
                        .filter((option) => restrictionsList.includes(option.value))
                        .map(({ label }) => label)
                        .join(', ');
                    })()
                  }
                  fieldStub="dietaryRestrictions"
                />

                {sortCustomFields(data.customUpdates).map(
                  (f) =>
                    !f.field.isHidden && (
                      <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                    )
                )}
              </Box>
            )}
          </Box>
          <ScopesControl scopes={[SCOPE]} context={scopesContext}>
            <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
              <AboutForm
                userId={userId}
                initialValues={data}
                onSubmit={(values) => {
                  setDataO(O.some(values));
                  setIsOpen(false);
                  onSubmit(values);
                }}
                onClose={() => setIsOpen(false)}
                handleRefresh={() => {}}
              />
            </DrawerModal>
          </ScopesControl>
        </ProfileCard>
      )
    )
  );
};
