import { Suspense, useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DeviceAPI } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DeviceOrderDto,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { DeviceOrderStatus, DevicePossessionType, DeviceTransitStatus } from '@v2/feature/device/device.interface';
import { NoAppliedPolicies } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { MyDevicesActive } from '@/v2/feature/device/features/my-devices/components/my-devices-active.component';
import { MyDevicesEmptyOverview } from '@/v2/feature/device/features/my-devices/components/my-devices-empty-overview.component';
import { MyDevicesOrders } from '@/v2/feature/device/features/my-devices/components/my-devices-orders.component';
import { MyDevicesReturns } from '@/v2/feature/device/features/my-devices/components/my-devices-returns.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserProfileDevicesPage = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [devicePossessions, setDevicePossessions] = useState<readonly DevicePossessionDto[]>([]);
  const [deviceTransits, setDeviceTransits] = useState<readonly DeviceTransitDto[]>([]);
  const [deviceOrders, setDeviceOrders] = useState<readonly DeviceOrderDto[]>([]);
  const params = useParams<{ userId: string }>();
  const userId = Number(params.userId);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(
    NoAppliedPolicies
  );
  const [
    appliedZeltMobilePolicies,
    setAppliedZeltMobilePolicies,
  ] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(NoAppliedPolicies);
  const [appliedHexPolicies, setAppliedHexPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [showMessage] = useMessage();

  const refreshLocalDevices = useCallback(async () => {
    try {
      setLoading(true);
      const [
        devicePossessions,
        deviceTransits,
        deviceOrders,
        zeltPolicies,
        zeltMobilePolicies,
        hexPolicies,
      ] = await Promise.all([
        DeviceAPI.getActiveDevicePossessionsByUserId(userId),
        DeviceAPI.getUserInTransitDevices(userId),
        DeviceAPI.getUserDeviceOrders(userId),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile(),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
      ]);
      setDevicePossessions(devicePossessions);
      setDeviceTransits(deviceTransits);
      setAppliedZeltPolicies(zeltPolicies);
      setAppliedZeltMobilePolicies(zeltMobilePolicies);
      setAppliedHexPolicies(hexPolicies);
      setDeviceOrders(deviceOrders.filter((deviceOrder) => deviceOrder.status !== DeviceOrderStatus.Delivered));
    } catch (error) {
      showMessage('Could not load user devices list', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId, showMessage]);

  useEffect(() => {
    refreshLocalDevices();
  }, [refreshLocalDevices]);

  const notifiableDeviceOrders = deviceOrders.some((deviceOrder) =>
    [
      DeviceOrderStatus.Requested,
      DeviceOrderStatus.Placed,
      DeviceOrderStatus.Accepted,
      DeviceOrderStatus.Shipping,
    ].includes(deviceOrder.status)
  );

  const validShippingOrders = deviceOrders.filter((d) => d.status !== DeviceOrderStatus.Cancelled);
  const nonOrderTransits = deviceTransits.filter((deviceTransit) => {
    return (
      (deviceTransit.status === DeviceTransitStatus.Pending || deviceTransit.status === DeviceTransitStatus.Shipping) &&
      deviceTransit.sender?.possessionType !== DevicePossessionType.ZeltStock
    );
  });

  const notifiableDeviceTransits = Boolean(nonOrderTransits?.length > 0);
  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2 }}>Devices</Typography>} />
      <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="83%"
              height="100%"
              sx={{ borderRadius: '10px', backgroundColor: themeColors.Background, ...spacing.px40 }}
            />
          }
        >
          {devicePossessions.length > 0 || notifiableDeviceOrders || notifiableDeviceTransits ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
                justifyContent: 'space-between',
                gap: spacing.g60,
              }}
            >
              <Box sx={{ width: '100%', maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '70%', xl: '70%' } }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: spacing.g60,
                  }}
                >
                  {validShippingOrders?.length > 0 && (
                    <MyDevicesOrders deviceOrders={validShippingOrders} refreshDevicesData={refreshLocalDevices} />
                  )}
                  {nonOrderTransits?.length > 0 && (
                    <MyDevicesReturns
                      deviceTransits={nonOrderTransits}
                      refreshDevicesData={refreshLocalDevices}
                      userId={userId}
                    />
                  )}
                  {devicePossessions?.length > 0 && (
                    <MyDevicesActive
                      devicePossessions={devicePossessions}
                      appliedZeltPolicies={appliedZeltPolicies}
                      appliedZeltMobilePolicies={appliedZeltMobilePolicies}
                      appliedHexPolicies={appliedHexPolicies}
                      userId={userId}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={spacing.px40}>
              <MyDevicesEmptyOverview
                refresh={async () => {
                  await refreshLocalDevices();
                }}
              />
            </Box>
          )}
        </Suspense>
      </ContentWrapper>
    </RootStyle>
  );
};
