import {
  InsuranceAddonsMultipliersDto,
  InsurancePolicyDto,
  InsuranceQuoteDto,
  ManageUserInsuranceDto,
  UpdateInsurancePolicyGeneralDto,
  UserInsuranceDto,
} from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import {
  BenefitsQuoteData,
  UploadedInsuranceDocumentData,
  UpsertInsurancePolicy,
  UserInsuranceDependants,
} from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class InsuranceAPI {
  static async getInsuranceQuote(): Promise<InsuranceQuoteDto> {
    return (await axios.get('/apiv2/insurance-quotes')).data;
  }

  static async requestInsuranceQuote(benefitsQuoteData: BenefitsQuoteData): Promise<InsuranceQuoteDto> {
    return (await axios.post('/apiv2/insurance-quotes', benefitsQuoteData)).data;
  }

  static async updateQuoteMembers(quoteId: number, members: number[]): Promise<InsuranceQuoteDto> {
    return (await axios.patch(`/apiv2/insurance-quotes/${quoteId}/members`, members)).data;
  }

  static async getAllInsuranceQuotesAsSuperAdmin(): Promise<InsuranceQuoteDto[]> {
    return (await axios.get('/apiv2/insurance-quotes/superadmin')).data;
  }

  static async getAllUserInsuranceRecordsAsSuperAdmin(insurancePolicyId: number): Promise<UserInsuranceDto[]> {
    return (await axios.get(`/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users`)).data;
  }

  static async addUserToInsurancePolicyAsSuperAdmin(insurancePolicyId: number, userId: number): Promise<void> {
    await axios.post(`/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users/${userId}/opt-in`);
  }

  static async removeUserFromInsurancePolicyAsSuperAdmin(insurancePolicyId: number, userId: number): Promise<void> {
    await axios.post(`/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users/${userId}/opt-out`);
  }

  static async confirmUserDependantsAsSuperAdmin(insurancePolicyId: number, userId: number): Promise<void> {
    await axios.post(
      `/apiv2/user-insurance/superadmin/policies/${insurancePolicyId}/users/${userId}/confirm-dependants`
    );
  }

  static async deleteInsuranceQuoteByIdAsSuperAdmin(quoteId: number): Promise<void> {
    return (await axios.delete(`/apiv2/insurance-quotes/${quoteId}/superadmin`)).data;
  }

  static async createPolicyForInsuranceAsSuperAdmin(
    insuranceQuoteId: number,
    data: UpsertInsurancePolicy
  ): Promise<void> {
    await axios.post(`/apiv2/insurance-policies/superadmin/quotes/${insuranceQuoteId}/create-policy`, data);
  }

  static async createEmptyUserInsuranceAsSuperAdmin(
    companyId: number,
    userId: number,
    policyId: number
  ): Promise<void> {
    await axios.post(`/apiv2/user-insurance/superadmin/companies/${companyId}/policies/${policyId}/users/${userId}`);
  }

  static async updateUserInsuranceAsSuperAdmin(
    policyId: number,
    userId: number,
    update: ManageUserInsuranceDto
  ): Promise<void> {
    await axios.patch(`/apiv2/user-insurance/superadmin/policies/${policyId}/users/${userId}`, update);
  }

  static async uploadPolicyDocumentsAsSuperAdmin(
    insurancePolicyId: number,
    data: UploadedInsuranceDocumentData[]
  ): Promise<void> {
    await axios.post(`/apiv2/insurance-policies/superadmin/policies/${insurancePolicyId}/documents/upload`, data);
  }

  static async updatePolicyForInsuranceAsSuperAdmin(
    insurancePolicyId: number,
    data: UpsertInsurancePolicy
  ): Promise<void> {
    await axios.put(`/apiv2/insurance-policies/superadmin/${insurancePolicyId}/update-policy`, data);
  }

  static async updateHealthInsurancePolicyGeneral(data: UpdateInsurancePolicyGeneralDto): Promise<void> {
    await axios.patch(`/apiv2/insurance-policies/general`, data);
  }

  static async deleteInsurancePolicyByIdAsSuperAdmin(policyId: number): Promise<void> {
    return (await axios.delete(`/apiv2/insurance-policies/superadmin/${policyId}`)).data;
  }

  static async getAllInsurancePoliciesAsSuperAdmin(): Promise<InsurancePolicyDto[]> {
    return (await axios.get('/apiv2/insurance-policies/superadmin')).data;
  }

  static async getInsurancePolicyDetailsAsSuperAdmin(
    policyId: number,
    includeDocuments = false
  ): Promise<InsurancePolicyDto> {
    return (await axios.get(`/apiv2/insurance-policies/superadmin/${policyId}?includeDocuments=${includeDocuments}`))
      .data;
  }

  static async getInsurancePolicyById(policyId: number, includeDocuments = false): Promise<InsurancePolicyDto> {
    return (await axios.get(`/apiv2/insurance-policies/${policyId}?includeDocuments=${includeDocuments}`)).data;
  }

  static async getUserInsurancePolicyById(userId: number, includePolicyDetails = false): Promise<UserInsuranceDto> {
    return (
      await axios.get(`/apiv2/user-insurance/policies/users/${userId}?includePolicyDetails=${includePolicyDetails}`)
    ).data;
  }

  static async getAllInsuranceByUserId(userId: number): Promise<UserInsuranceDto[]> {
    return (await axios.get(`/apiv2/user-insurance/${userId}`)).data;
  }

  static async addUsersToPolicy(policyId: number, data: { userIds: number[]; startDate: string }): Promise<void> {
    await axios.post(`/apiv2/user-insurance/policies/${policyId}/users`, data);
  }

  static async addUserToPolicy(
    userId: number,
    policyId: number,
    data: { startDate: Date; monthlyEmployeePremium: number; monthlyEmployeeContribution: number }
  ): Promise<void> {
    await axios.post(`/apiv2/user-insurance/policies/${policyId}/users/${userId}`, data);
  }

  static async optOutFromInsurancePolicy(userId: number, policyId: number): Promise<void> {
    await axios.post(`/apiv2/user-insurance/policies/${policyId}/users/${userId}/opt-out`);
  }

  static async updateUserPolicyPremiums(
    userId: number,
    policyId: number,
    data: { startDate: string; monthlyEmployeePremium: number; monthlyEmployeeContribution: number }
  ): Promise<void> {
    await axios.put(`/apiv2/user-insurance/policies/${policyId}/users/${userId}`, data);
  }

  static async addDependantsToUserPolicy(
    userId: number,
    policyId: number,
    data: {
      dependants: UserInsuranceDependants;
      dependantsMonthlyPremium: number;
      employeeCoversAdditionalPremium: boolean;
      dependantsList: number[];
    }
  ): Promise<void> {
    await axios.post(`/apiv2/user-insurance/policies/${policyId}/users/${userId}/dependants`, data);
  }

  static async removeUserFromPolicy(userId: number, policyId: number, endDate: string): Promise<void> {
    await axios.delete(`/apiv2/user-insurance/policies/${policyId}/users/${userId}?endDate=${endDate}`);
  }

  static async createPMI(quoteId: number): Promise<void> {
    await axios.post(`/apiv2/insurance-quotes/superadmin/${quoteId}/create-pmi`);
  }

  static async getInsuranceAddonsMultipliers(): Promise<InsuranceAddonsMultipliersDto[]> {
    return (await axios.get('/apiv2/insurance-addons-multipliers/superadmin')).data;
  }

  static async getInsuranceAddonsMultipliersByPolicyId(policyId: number): Promise<InsuranceAddonsMultipliersDto> {
    return (await axios.get(`/apiv2/insurance-addons-multipliers/policies/${policyId}`)).data;
  }

  static async createExistingInsurance(data: any): Promise<number> {
    return (await axios.post(`/apiv2/todo/create-existing`, data)).data;
  }
}

export class InsuranceEndpoints {
  static getInsuranceQuote(): Endpoint<InsuranceQuoteDto> {
    return {
      url: '/apiv2/insurance-quotes',
    };
  }

  static getAllUsersInsurancePolicyById(policyId: number): Endpoint<UserInsuranceDto[]> {
    return {
      url: `/apiv2/user-insurance/policies/${policyId}`,
    };
  }
}
