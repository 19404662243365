import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly insuranceQuote: InsuranceQuoteDto;
  readonly refresh: () => Promise<void>;
}

export const EditInsuranceQuoteMembersDrawer = ({ isOpen, setIsOpen, insuranceQuote, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <EditInsuranceMembersDrawerContent insuranceQuote={insuranceQuote} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly insuranceQuote: InsuranceQuoteDto;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const EditInsuranceMembersDrawerContent = ({
  insuranceQuote,
  refresh,
  setIsOpen,
}: DrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState<UserSelectFiltersOptions>(UserSelectFiltersOptions.None);
  const [customRule, setCustomRule] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<number[]>(insuranceQuote.includedUserIds ?? []);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (includedUserIds: number[]) => {
      try {
        setLoading(true);
        await InsuranceAPI.updateQuoteMembers(insuranceQuote.id, includedUserIds);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [polyglot, insuranceQuote.id, refresh, setIsOpen, showMessage]
  );

  return (
    <Box>
      <Typography sx={titleSx}>{polyglot.t('EditPolicyMembersDrawerContent.editMembers')}</Typography>

      <Box sx={fieldSx}>
        <UserSelect
          label={polyglot.t('EditPolicyMembersDrawerContent.whoSelect')}
          selectedLabel={polyglot.t('EditPolicyMembersDrawerContent.selectedMembers')}
          value={selectedUsers}
          onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
            setSelectedUsers([...userIds]);
            setValidationMessage('');
            setSelectedOption(filterValue ?? UserSelectFiltersOptions.None);
            setCustomRule(customRule ?? '');
          }}
          fieldSx={{ ...spacing.mb20 }}
          error={!!validationMessage}
          helperText={!!validationMessage && validationMessage}
          initialFilterValue={UserSelectFiltersOptions.None}
          ruleString={customRule}
        />
      </Box>

      <Box sx={{ mt: spacing.m20 }}>
        <LoaderButton
          name={polyglot.t('General.save')}
          type="button"
          onClick={async () => {
            if (selectedOption === UserSelectFiltersOptions.None)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOption'));
            else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOneUser'));
            else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.enterCustomRule'));
            else {
              await onSubmit(selectedUsers);
            }
          }}
          loading={loading}
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
        />
      </Box>
    </Box>
  );
};
