import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormDto } from '@v2/feature/custom-fields/custom-profile-fields.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CountrySelect } from '@/v2/components/country-select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TypeableDateComponent } from '@/v2/components/forms/typeable-date.component';
import { GenderSelect } from '@/v2/components/gender-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  UpdateUserPersonalInfoDto,
  UserPersonalInfoDto,
} from '@/v2/feature/user/features/user-forms/user-personal-info/user-personal-info.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { PersonalInformationGender } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';
import { spacing } from '@/v2/styles/spacing.styles';

type UserPersonalInfoSectionProps = {
  form: CustomProfileFormDto | undefined;
  onNext: (values: Partial<UpdateUserPersonalInfoDto>) => void;
  sx?: SxProps<Theme>;
  userId: number;
  personalInfo: UserPersonalInfoDto;
};

type PersonalFormDefaultVisibility = {
  dob: boolean;
  nationality: boolean;
  gender: boolean;
  passportNumber: boolean;
  personalEmail: boolean;
  phone: boolean;
};

const PersonalInfoSchema = (visibility: PersonalFormDefaultVisibility, polyglot: Polyglot) => {
  return Yup.object().shape({
    dob: visibility.dob
      ? Yup.string().test(dateFieldTest).nullable().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().nullable().notRequired(),
    nationality: visibility.nationality
      ? Yup.string().nullable().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().nullable().notRequired(),
    gender: visibility.gender
      ? Yup.string().nullable().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().nullable().notRequired(),
    passportNumber: Yup.string().nullable().notRequired(),
    personalEmail: visibility.personalEmail
      ? Yup.string()
          .email(polyglot.t('OnboardingByUser.validations.validValue'))
          .required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().nullable().notRequired(),
    phone: Yup.string().nullable().notRequired(),
  });
};

export const UserPersonalInfoSection = ({ onNext, sx, userId, personalInfo, form }: UserPersonalInfoSectionProps) => {
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();

  const defaultFieldsVisibility: PersonalFormDefaultVisibility = useMemo(() => {
    if (!form)
      return {
        dob: false,
        nationality: false,
        gender: false,
        passportNumber: false,
        personalEmail: false,
        phone: false,
      };

    const dobField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'dob');
    const nationalityField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'nationality');
    const genderField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'gender');
    const passportNumberField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'passportNumber');
    const personalEmailField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'personalEmail');
    const phoneField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'phone');

    return {
      dob: Boolean(dobField && !dobField.isHidden),
      nationality: Boolean(nationalityField && !nationalityField.isHidden),
      gender: Boolean(genderField && !genderField.isHidden),
      passportNumber: Boolean(passportNumberField && !passportNumberField.isHidden),
      personalEmail: Boolean(personalEmailField && !personalEmailField.isHidden),
      phone: Boolean(phoneField && !phoneField.isHidden),
    };
  }, [form]);

  const initialValues = useMemo(() => {
    const values: Partial<UpdateUserPersonalInfoDto> = {};
    if (defaultFieldsVisibility.dob) values.dob = personalInfo.dob ?? '';
    if (defaultFieldsVisibility.gender) values.gender = (personalInfo.gender ?? '') as PersonalInformationGender;
    if (defaultFieldsVisibility.nationality) values.nationality = personalInfo.nationality ?? '';
    if (defaultFieldsVisibility.passportNumber) values.passportNumber = personalInfo.passportNumber ?? '';
    if (defaultFieldsVisibility.personalEmail) values.personalEmail = personalInfo.personalEmail ?? '';
    if (defaultFieldsVisibility.phone) values.phone = personalInfo.phone ?? '';
    values.customUpdates = personalInfo.customUpdates ?? [];

    return values;
  }, [defaultFieldsVisibility, personalInfo]);

  const formik = useFormik<Partial<UpdateUserPersonalInfoDto>>({
    initialValues,
    validationSchema: PersonalInfoSchema(defaultFieldsVisibility, polyglot),
    onSubmit: async (values) => {
      setSaving(true);
      try {
        await UserAPI.patchUserPersonalInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.basicInfoNotSaved', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('OnboardingByUser.personalInfo')}</Typography>

          {defaultFieldsVisibility.dob && (
            <TypeableDateComponent
              name="dob"
              label={polyglot.t('OnboardingByUser.dob')}
              value={formik.values.dob}
              onChange={(value) => formik.setFieldValue('dob', value)}
              disabled={saving}
              error={hasSubmitted && !!formik.errors.dob}
              helperText={hasSubmitted && formik.errors.dob}
            />
          )}

          {defaultFieldsVisibility.nationality && (
            <CountrySelect
              name="nationality"
              label={polyglot.t('OnboardingByUser.nationality')}
              value={formik.values.nationality}
              onChange={(value) => formik.setFieldValue('nationality', value)}
              disabled={saving}
              error={hasSubmitted && !!formik.errors.nationality}
              helperText={hasSubmitted && formik.errors.nationality}
            />
          )}

          {defaultFieldsVisibility.gender && (
            <GenderSelect
              name="gender"
              value={formik.values.gender}
              onChange={(value) => formik.setFieldValue('gender', value)}
              disabled={saving}
              error={hasSubmitted && !!formik.errors.gender}
              helperText={hasSubmitted && formik.errors.gender}
            />
          )}

          {defaultFieldsVisibility.passportNumber && (
            <TextfieldComponent
              name="passportNumber"
              label={polyglot.t('OnboardingByUser.passportNumber')}
              value={formik.values.passportNumber}
              onChange={formik.handleChange}
              disabled={saving}
              clearText={() => formik.setFieldValue('passportNumber', '')}
              helperText={hasSubmitted && formik.errors.passportNumber}
              error={hasSubmitted && !!formik.errors.passportNumber}
            />
          )}

          {defaultFieldsVisibility.personalEmail && (
            <TextfieldComponent
              name="personalEmail"
              label={polyglot.t('OnboardingByUser.personalEmail')}
              type="email"
              value={formik.values.personalEmail}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('personalEmail', '')}
              helperText={hasSubmitted && formik.errors.personalEmail}
              error={hasSubmitted && !!formik.errors.personalEmail}
            />
          )}

          {defaultFieldsVisibility.phone && (
            <TextfieldComponent
              name="phone"
              label={polyglot.t('OnboardingByUser.phone')}
              type="tel"
              value={formik.values.phone}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('phone', '')}
              helperText={hasSubmitted && formik.errors.phone}
              error={hasSubmitted && !!formik.errors.phone}
            />
          )}

          <CustomFieldComponents
            values={formik.values.customUpdates ?? []}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            disabled={saving}
            fieldSx={{}}
          />

          <LoaderButton
            name={polyglot.t('General.continue')}
            sizeVariant="large"
            colorVariant="primary"
            loading={saving}
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
