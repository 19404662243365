import { Box, SwipeableDrawer, Typography } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface StudentLoanInfoProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}
export function StudentLoanInfo({ open, setOpen }: StudentLoanInfoProps): JSX.Element {
  return (
    <SwipeableDrawer anchor="right" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <Box
        sx={{
          width: 500,
          px: 6,
          height: '100%',
          verticalAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Types of Student Loan</Typography>
          <Typography sx={{ ...themeFonts.caption, mt: spacing.mt40 }}>
            You have a <b>Plan 1</b> if any of the following apply:
          </Typography>
          <Box marginY={2} paddingLeft={3}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in England or Wales and started your course before 1 September 2012.
            </Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in Northern Ireland when you started your course.
            </Typography>
          </Box>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            You have a <b>Plan 2</b> if:
          </Typography>
          <Box marginY={1} paddingLeft={3}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in England or Wales and started your course on or after 1 September 2012.
            </Typography>
          </Box>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            You have a <b>Plan 4</b> if:
          </Typography>
          <Box marginY={1} paddingLeft={3}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in Scotland and applied through the Students Award Agency Scotland (SAAS) when you started
              your course.
            </Typography>
          </Box>
          <Typography sx={{ ...themeFonts.caption, mt: spacing.mt40, color: themeColors.DarkGrey }}>
            You have a Postgraduate Loan if any of the following apply:
          </Typography>
          <Box marginY={1} paddingLeft={3}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in England and started your Postgraduate Master's course on or after 1 August 2016.
            </Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in Wales and started your Postgraduate Master's course on or after 1 August 2017.
            </Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              ● You lived in England or Wales and started your Postgraduate Doctoral course on or after 1 August 2018
            </Typography>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
