import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ConfigurableAutomaticOsUpdate, DevicePolicyDto } from '@v2/feature/device/device.dto';
import { SupportedDeviceType } from '@v2/feature/device/device.interface';
import { FieldValueComponent } from '@v2/feature/device/device.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';

export const OsUpdatesPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  isApple,
  isWindows,
  configurationPayloadOption,
  policy,
  allPolicies,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  policyName: string;
  isApple: boolean;
  isWindows: boolean;
  configurationPayloadOption?: ConfigurableAutomaticOsUpdate | null | undefined;
  policy?: number[] | null;
  allPolicies?: DevicePolicyDto[] | null;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPolicyDto, setSelectedPolicyDto] = useState<DevicePolicyDto | undefined>(undefined);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      if (allPolicies && allPolicies?.length > 0 && policy) {
        const supportedDeviceType = isWindows ? SupportedDeviceType.windows : SupportedDeviceType.android;

        const selectedPolicy = allPolicies
          .filter((p) => p.supportedDeviceType.toString() === supportedDeviceType.toString())
          .find((p) => policy.includes(p.id));

        setSelectedPolicyDto(selectedPolicy);
      }
    } catch (error) {
      showMessage('Something went wrong. Could not load the company policies.', 'error');
    } finally {
      setLoading(false);
    }
  }, [allPolicies, isWindows, policy, showMessage]);

  useEffect(() => {
    if (!isApple) {
      refresh();
    }
  }, [isApple, refresh]);

  const getInstallActionType = (action: string | undefined) => {
    switch (action) {
      case 'Default':
        return 'Download or install the update, depending on the current state';
      case 'InstallASAP':
        return 'Download the software update, install immediately and trigger the restart countdown notification';
      case 'InstallForceRestart':
        return 'Perform the Default action, and then force a restart';
      default:
        return 'Unknown action';
    }
  };

  if (isApple && configurationPayloadOption) {
    return (
      <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
        <Box>
          <Typography variant="title2">{policyName}</Typography>

          <Box
            component="dl"
            sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
          >
            <FieldValueComponent
              title={polyglot.t(`InHouseMdmPolicyViewDrawer.automaticUpdates`)}
              value={
                <Typography variant="title4">
                  {configurationPayloadOption.automaticOsUpdate ? 'Active' : 'Inactive'}
                </Typography>
              }
            />
            <FieldValueComponent
              title={polyglot.t(`InHouseMdmPolicyViewDrawer.osUpdateAction`)}
              value={getInstallActionType(configurationPayloadOption.installAction)}
            />
          </Box>
        </Box>
      </DrawerModal>
    );
  } else {
    return (
      <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose} loading={loading}>
        <Box>
          <Typography variant="title2">{policyName}</Typography>

          <Box
            component="dl"
            sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
          >
            {selectedPolicyDto && <Typography variant="caption">{selectedPolicyDto.description}</Typography>}
          </Box>
        </Box>
      </DrawerModal>
    );
  }
};
