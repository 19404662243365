import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as DeleteIcon } from '@/images/side-bar-icons/Trash.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PayrunUserHeader } from '@/v2/feature/payroll/components/payrun-user-header.component';
import { GlobalPayLineTypeMenu } from '@/v2/feature/payroll/features/payroll-global/components/global-payline-type-menu.component';
import { GlobalPayrollEndpoints } from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from '@/v2/feature/payroll/payroll-external.interface';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { setFocusToInput } from '@/v2/util/element.util';

type EditPayrunEntryProps = {
  payrollId: number;
  user: CachedUser;
  payrunEntry: GlobalPayrunEntry;
  payrunClosed: boolean;
  savePaylineUpdates: (update: GlobalPayrunEntryUpdate) => Promise<boolean>;
};

const EditPayrunEntry = ({ user, payrunEntry, payrollId, payrunClosed, savePaylineUpdates }: EditPayrunEntryProps) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const [focusedTextEditId, setFocusedTextEditId] = useState('');
  const [orderedPaylines, setOrderedPaylines] = useState<GlobalPayline[]>([]);
  const [paylinesToAdd, setPaylinesToAdd] = useState<Set<UUID>>(new Set());
  const [paylinesToDelete, setPaylinesToDelete] = useState<Set<UUID>>(new Set());

  const { data: payrollPaycodes } = useApiClient(GlobalPayrollEndpoints.getPayrollPaycodes(payrollId), {
    suspense: false,
  });

  const paycodesByCode = useMemo(() => {
    return new Map(payrollPaycodes?.paycodes.map((p) => [p.code, p]));
  }, [payrollPaycodes?.paycodes]);

  useEffect(() => {
    if (paycodesByCode.size === 0) return;
    const sortedPaylines = [...payrunEntry.paylines].sort(
      (a, b) => (paycodesByCode.get(a.code)?.order ?? 0) - (paycodesByCode.get(b.code)?.order ?? 0)
    );
    setOrderedPaylines(sortedPaylines);
  }, [paycodesByCode, payrunEntry.paylines]);

  useEffect(() => {
    if (!focusedTextEditId) return;
    setFocusToInput(focusedTextEditId);
  }, [focusedTextEditId]);

  const updatePayline = useCallback((id: string, values: Pick<GlobalPayline, 'description' | 'amount'>) => {
    // when we change a payline amount or description, treat it as deleting the old payline and adding a new payline
    setPaylinesToAdd((added) => new Set([...added, id]));
    setPaylinesToDelete((removed) => new Set([...removed, id]));

    setOrderedPaylines((paylines) => {
      const idx = paylines.findIndex((payline) => payline.id === id);
      paylines[idx] = { ...paylines[idx], ...values };
      return [...paylines];
    });
  }, []);

  const addPayline = useCallback(
    ({ code, name }: GlobalPaycode) => {
      const id = uuidv4();
      setOrderedPaylines((paylines) => {
        let idx = paylines.findIndex((p, idx, arr) => p.code !== code && arr[idx - 1]?.code === code);
        if (idx < 0) idx = paylines.length;
        const newPayline: GlobalPayline = {
          id,
          userId: user.userId,
          amount: 0,
          code,
          description: name,
          calculated: false,
        };
        const result = [...paylines];
        result.splice(idx, 0, newPayline);
        return result;
      });
      setPaylinesToAdd((ids) => new Set([...ids, id]));
      return id;
    },
    [user.userId]
  );

  const removePayline = useCallback(
    (id: string) => {
      const payline = orderedPaylines.find((p) => p.id === id);
      if (!payline) return;
      if (payline.calculated) return;
      // remove from added paylines
      setPaylinesToAdd((added) => new Set([...added].filter((id) => id !== payline.id)));
      // mark as deleted
      setPaylinesToDelete((removed) => new Set([...removed, id]));
      setOrderedPaylines(orderedPaylines.filter((p) => p !== payline));
    },
    [orderedPaylines]
  );

  const savePaylines = useCallback(async () => {
    setSavingUpdate(true);
    try {
      await savePaylineUpdates({
        added: orderedPaylines.filter((p) => paylinesToAdd.has(p.id)),
        deleted: [...paylinesToDelete],
      });
    } catch {
      setSavingUpdate(false);
    }
  }, [orderedPaylines, paylinesToAdd, paylinesToDelete, savePaylineUpdates]);

  const isRequiredPayline = useCallback(
    (payline: GlobalPayline) => {
      return !!paycodesByCode.get(payline.code)?.required;
    },
    [paycodesByCode]
  );

  const overridenValues = useMemo(() => {
    const calcCodes = orderedPaylines.filter((p) => p.calculated).map((p) => p.code);
    const manualCodes = orderedPaylines.filter((p) => !p.calculated).map((p) => p.code);
    // return the set of codes that have both manual and calculated paylines
    return new Set(calcCodes.filter((code) => manualCodes.includes(code)));
  }, [orderedPaylines]);

  return (
    <>
      <Typography variant="title2">Edit pay</Typography>
      <PayrunUserHeader user={user} sx={{ mt: spacing.m10 }} />
      <Stack flex={1} sx={{ gap: spacing.g20, mt: spacing.m30, overflowY: 'auto' }}>
        {orderedPaylines.map((payline) => {
          const { id, code, description, amount } = payline;
          return (
            <React.Fragment key={id}>
              {payline.calculated && !overridenValues.has(code) && (
                <Stack>
                  <Typography variant="title4">{description}</Typography>
                  <MoneyTextfieldComponent
                    id={id}
                    name={id}
                    label={''}
                    value={amount}
                    readonly
                    emptyIsZero
                    allowNegative
                    sx={{ mt: spacing.m5 }}
                  />
                </Stack>
              )}
              {!payline.calculated && (
                <Stack>
                  <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="title4">{code}</Typography>
                    {(!isRequiredPayline(payline) || !payline.calculated) && (
                      <IconButton sizeVariant="small" colorVariant="secondary" onClick={() => removePayline(id)}>
                        <DeleteIcon {...iconSize} />
                      </IconButton>
                    )}
                  </Stack>
                  <MoneyTextfieldComponent
                    id={id}
                    name={id}
                    label="Amount"
                    value={amount}
                    onChange={(amount) => typeof amount === 'number' && updatePayline(id, { amount, description })}
                    disabled={savingUpdate || payrunClosed}
                    emptyIsZero
                    clearToZero
                    allowNegative
                    sx={{ mt: spacing.m5 }}
                  />
                  <TextfieldComponent
                    name={`${id}-description`}
                    label="Description"
                    value={description}
                    maxLength={MAX_PAYLINE_DESCRIPTION_LENGTH}
                    onChange={(e) => updatePayline(id, { amount, description: e.target.value })}
                    clearText={() => updatePayline(id, { amount, description: '' })}
                    disabled={savingUpdate || payrunClosed}
                    sx={{ mt: spacing.m5 }}
                  />
                </Stack>
              )}
            </React.Fragment>
          );
        })}
      </Stack>
      {payrunClosed && (
        <Typography variant="caption" sx={{ mt: spacing.m30 }}>
          Pay items cannot be changed because the payrun is closed.
        </Typography>
      )}
      {!payrunClosed && payrollPaycodes && (
        <GlobalPayLineTypeMenu
          payCodes={payrollPaycodes.paycodes}
          disabled={savingUpdate}
          onMenuItemClick={(payCode) => {
            const newEntryId = addPayline(payCode);
            // set focus to the newly added item
            setFocusedTextEditId(newEntryId);
          }}
          sx={{ mt: spacing.m30 }}
        />
      )}
      {!payrunClosed && (
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            fullWidth
            loading={savingUpdate}
            onClick={savePaylines}
            name="Save"
            type="button"
            sizeVariant="medium"
            colorVariant="primary"
            style={{ marginTop: spacing.m30 }}
          />
        </Box>
      )}
    </>
  );
};

interface Props {
  onClose?: () => void;
  payrollId: number;
  payrunEntry?: GlobalPayrunEntry | null;
  payrunClosed: boolean;
  savePaylineUpdates: (update: GlobalPayrunEntryUpdate) => Promise<boolean>;
}

export const EditPayrunEntryDrawer = ({
  onClose = () => {},
  payrollId,
  payrunEntry,
  payrunClosed,
  savePaylineUpdates,
}: Props): React.JSX.Element => {
  const { getCachedUserById } = useCachedUsers();
  const user = payrunEntry && getCachedUserById(payrunEntry.userId);

  return (
    <DrawerModal isOpen={!!user} setIsOpen={() => {}} onClose={onClose}>
      {user && payrunEntry ? (
        <EditPayrunEntry
          payrollId={payrollId}
          user={user}
          payrunEntry={payrunEntry}
          payrunClosed={payrunClosed}
          savePaylineUpdates={async (update) => {
            const result = await savePaylineUpdates(update);
            if (result) setImmediate(onClose);
            return result;
          }}
        />
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};
