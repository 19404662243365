import { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { OptionObject } from '@v2/components/forms/select.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceStatus } from '@v2/feature/attendance/attendance.interface';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import {
  ErrorComponent,
  LoadingTableComponent,
} from '@v2/feature/reports/features/create-report/sections/util-sections.component';
import { ReportsAPI } from '@v2/feature/reports/reports.api';
import {
  AttendanceDomainReportFilters,
  CreateReport,
  FormDataInterface,
  ReportConfig,
  ReportEntity,
} from '@v2/feature/reports/reports.interface';
import { AttendanceReportColumnsOptions } from '@v2/feature/reports/util/attendance-report.util';
import { exportReportCSVOldReports } from '@v2/feature/reports/util/report.util';
import { UserAPI } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { CompanyEndpoints } from '@/api-client/company.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export const AttendanceReportResults = ({
  formData,
  setFormData,
}: {
  formData: FormDataInterface;
  setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
}) => {
  const { polyglot } = usePolyglot();

  const { data: attendanceSchedules } = useApiClient<AttendanceScheduleDto[], Error>(
    AttendanceEndpoints.getAttendanceSchedules(),
    { suspense: false }
  );
  const { data: companyEntities } = useApiClient(CompanyEndpoints.getCompanyEntities(), {
    suspense: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>(polyglot.t('AttendanceReportResults.new'));
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState<string>('');
  const [filteredData, setFilteredData] = useState<Record<string, string | number>[] | undefined>(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    AttendanceReportColumnsOptions(polyglot).map((o) => o.value)
  );

  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [reportError, setReportError] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData?.filter(
        (data) =>
          !searchInput ||
          (data?.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  }, [searchInput, filteredData]);

  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);

  const getFilteredData = useCallback(async () => {
    setReportLoading(true);
    try {
      const paramObj: ReportConfig = {
        domain: formData.domain,
        entities: [ReportEntity.Attendance],
        type: formData.type,
        filters: filterString,
        columns: { [ReportEntity.Attendance]: selectedColumns },
      };

      let filteredTimeRequests = await ReportsAPI.generateAttendanceCreatedReport(paramObj);
      setReportError(false);
      setFilteredData(filteredTimeRequests[ReportEntity.Attendance]);
    } catch (error) {
      setReportError(true);
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setReportLoading(false);
    }
  }, [selectedColumns, filterString, formData, showMessage]);

  useEffect(() => {
    getFilteredData();
  }, [getFilteredData]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const createReport: CreateReport = {
        domain: formData.domain,
        type: formData.type,
        entities: [ReportEntity.Attendance],
        filters: filterString,
        columns: { [ReportEntity.Attendance]: selectedColumns },
        fileName: reportName,
      };
      await ReportsAPI.saveReport(createReport);
      setFormData({ ...formData, filters: filterString, columns: { [ReportEntity.Attendance]: selectedColumns } });
      showMessage(polyglot.t('AttendanceReportResults.successMessages.save'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const getFilterOptions = useCallback(async () => {
    const statusesOptions: OptionObject[] = [
      { label: polyglot.t('AttendanceReportResults.approved'), value: AttendanceStatus.Approved },
      { label: polyglot.t('AttendanceReportResults.submit'), value: AttendanceStatus.Submitted },
      { label: polyglot.t('AttendanceReportResults.inProgress'), value: AttendanceStatus.InProgress },
      { label: polyglot.t('AttendanceReportResults.reject'), value: AttendanceStatus.Rejected },
    ];
    const schedulesOptions = (attendanceSchedules ?? []).map((s) => {
      return { label: s.name, value: s.id };
    });
    const entitiesOptions = (companyEntities ?? []).map((ce) => ({ label: ce.legalName, value: ce.id }));

    let filters = {};
    const FILTERS = AttendanceDomainReportFilters(schedulesOptions, statusesOptions, entitiesOptions, polyglot);
    for (const filterObj of FILTERS) {
      let filter = filterObj.name;
      filters = { ...filters, [`${filterObj.domain}-${filterObj.field}-${filter}`]: filterObj.options };
    }
    setFilterTypes(filters);
  }, [polyglot, attendanceSchedules, companyEntities]);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const exportCsv = async () => {
    try {
      exportReportCSVOldReports(reportName, filteredData ? { [ReportEntity.Attendance]: filteredData } : undefined);
    } catch (e) {
      showMessage(polyglot.t('AttendanceReportResults.errorMessages.download'), 'error');
    }
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      {reportLoading && <LoadingTableComponent />}
      {!reportLoading && reportError && <ErrorComponent />}
      {!reportLoading && filteredData && (
        <Box sx={{ width: '80%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10, minHeight: '60px' }}>
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={
                state.user.features?.report?.tooltip?.saveReportName
                  ? undefined
                  : {
                      open: true,
                      title: (
                        <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                            {polyglot.t('AttendanceReportResults.type')}
                          </Typography>
                          <Button
                            onClick={async () => {
                              const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                                'report',
                                'tooltip',
                                'saveReportName',
                                true
                              );
                              dispatch({
                                type: GlobalStateActions.UPDATE_USER,
                                payload: updatedGlobalUser,
                              });
                            }}
                            sx={{ ...themeFonts.caption, color: themeColors.Grey, textTransform: 'none' }}
                          >
                            {polyglot.t('AttendanceReportResults.okay')}
                          </Button>
                        </Stack>
                      ),
                    }
              }
            />
          </Box>

          <Box sx={{ display: 'flex', ...spacing.mt20, gap: spacing.g5 }}>
            <ColumnsDrawer
              columnsOptions={AttendanceReportColumnsOptions(polyglot)}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
            <FiltersDrawer
              filtersOptions={[{ filters: filterTypes }]}
              selectedFilters={filterString}
              setSelectedFilters={setFilterString}
              encodedFilterNames
            />
            <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
          </Box>

          <Box sx={{ ...spacing.mt40 }}>
            {filteredAndSearchedData && (
              <ResultTableOldReports filteredData={filteredAndSearchedData} loading={false} />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: spacing.g10,
              ...spacing.mt40,
              width: '50%',
            }}
          >
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name="Save"
              loading={loading}
              fullWidth
              onClick={handleSave}
            />
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={exportCsv}
              startIcon={<Export {...iconSize} />}
            >
              {polyglot.t('AttendanceReportResults.downloadCsv')}
            </ButtonComponent>
          </Box>
        </Box>
      )}

      {/*{!reportLoading && filteredData && filteredData.length === 0 && !reportError && !filterString && (*/}
      {/*  <NoDataComponent />*/}
      {/*)}*/}
    </Box>
  );
};
