import React, { useCallback, useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { MultiUserAvatar } from '@v2/components/theme-components/multi-user-avatar.component';
import { ApproversList } from '@v2/feature/approval-rule/components/approvers-list.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { getLinkToUploadedFile } from '@/api-client/routes';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DocumentIcon } from '@/images/documents/PinnedBlack.svg';
import { ReactComponent as PendingIcon } from '@/images/side-bar-icons/Clock.svg';
import { ReactComponent as RejectedIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as ApprovedIcon } from '@/images/side-bar-icons/ok-green.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ApprovalButtonSet } from '@/v2/components/approval-button-set.component';
import { stringToAttachmentComponentValue } from '@/v2/components/forms/attachment.component';
import { InlineLink } from '@/v2/components/inline-link.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { RequestFormTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { RequestForm, RequestFormStatus } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsAPI, RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatMediumDate } from '@/v2/util/date-format.util';

const RequestFormActionItemDrawerContent = ({
  submittedForm,
  close,
}: {
  submittedForm: RequestForm;
  close: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();

  const statusComponent = useCallback(
    (status: RequestFormStatus) => {
      return {
        pending: () => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
            <PendingIcon {...iconSize} fill={themeColors.Grey} />
            <Typography variant="caption" color="Grey">
              {polyglot.t('RequestFormActionItemDrawerContent.pending')}
            </Typography>
          </Stack>
        ),
        approved: () => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
            <ApprovedIcon {...iconSize} fill={themeColors.GreenMiddle} />
            <Typography variant="caption">{polyglot.t('RequestFormActionItemDrawerContent.approved')}</Typography>
          </Stack>
        ),
        rejected: () => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
            <RejectedIcon {...iconSize} fill={themeColors.RedDark} />
            <Typography variant="caption">{polyglot.t('RequestFormActionItemDrawerContent.rejected')}</Typography>
          </Stack>
        ),
      }[status]();
    },
    [polyglot]
  );

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{submittedForm.name}</Typography>
      <Stack gap={spacing.g3}>
        <Typography variant="caption" color="Grey">
          {polyglot.t('RequestFormActionItemDrawerContent.employee')}
        </Typography>
        <UserCell userId={submittedForm.userId} />
      </Stack>
      <Stack sx={{ gap: spacing.g15 }}>
        {submittedForm.layout.items.map((item) => {
          const formValue = submittedForm.values[item.id];

          return (
            <Stack key={item.id} gap={spacing.g3}>
              <Typography variant="caption" color="Grey">
                {item.label}
                {item.required && ` (Required)`}
              </Typography>
              {item.type === 'attachment' &&
                (() => {
                  const value = stringToAttachmentComponentValue(formValue as string);
                  if (!value) return ' ';
                  return (
                    <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
                      {value.type === 'file' && <DocumentIcon {...iconSize} />}
                      {(value.type === 'link' || value.type === 'file') && (
                        <InlineLink
                          href={value.type === 'link' ? value.name : getLinkToUploadedFile(value.uuid)}
                          title={value.name}
                          style={{
                            ...themeFonts.title4,
                            color: themeColors.DarkGrey,
                            textDecoration: 'underline',
                          }}
                          openInNewWindow
                        />
                      )}
                    </Stack>
                  );
                })()}
              {item.type === 'date' && (
                <Typography variant="title4">{`${formValue ? formatMediumDate(formValue as string) : ''}`}</Typography>
              )}
              {item.type === 'select' && <Typography variant="title4">{`${formValue ?? ''}`}</Typography>}
              {item.type === 'text' && (
                <Typography variant="title4" dangerouslySetInnerHTML={{ __html: (formValue as string) ?? '' }} />
              )}
            </Stack>
          );
        })}
      </Stack>
      <Stack gap={spacing.g3}>
        <Typography variant="caption" color="Grey">
          {polyglot.t('RequestFormActionItemDrawerContent.status')}
        </Typography>
        {statusComponent(submittedForm.status)}
      </Stack>
      {submittedForm.approvedByIds && submittedForm.approvedByIds.length > 0 && (
        <Stack gap={spacing.g3}>
          <Typography variant="caption" color="Grey">
            {polyglot.t('General.approvedBy')}
          </Typography>
          <MultiUserAvatar userIds={submittedForm.approvedByIds} />
        </Stack>
      )}

      <ApproversList approverSteps={submittedForm.approverSteps} labelVariant="caption" />

      {submittedForm.status === RequestFormStatus.Pending && (
        <ApprovalButtonSet
          sx={buttonBoxDrawerSx}
          requestForm={submittedForm}
          onApprove={async () => {
            await RequestFormsAPI.setSubmittedFormStatus(submittedForm.id, {
              status: RequestFormStatus.Approved,
            }).then(
              () => {
                showMessage(polyglot.t('RequestFormActionItemDrawerContent.successMessages.approve'), 'success');
                close();
              },
              (error) =>
                showMessage(
                  polyglot.t('RequestFormActionItemDrawerContent.errorMessages.approve', {
                    errorMessage: nestErrorMessage(error),
                  }),
                  'error'
                )
            );
          }}
          onReject={async (rejectionNotes) => {
            await RequestFormsAPI.setSubmittedFormStatus(submittedForm.id, {
              status: RequestFormStatus.Rejected,
              rejectionNotes,
            }).then(
              () => {
                showMessage(polyglot.t('RequestFormActionItemDrawerContent.successMessages.reject'), 'success');
                close();
              },
              (error) => showMessage(`Request rejection failed. ${nestErrorMessage(error)}`, 'error')
            );
          }}
          onDelete={async () => {
            await RequestFormsAPI.deleteSubmittedForm(submittedForm.id).then(
              () => {
                showMessage(polyglot.t('RequestFormActionItemDrawerContent.successMessages.delete'), 'success');
                close();
              },
              (error) =>
                showMessage(
                  polyglot.t('RequestFormActionItemDrawerContent.errorMessages.delete', {
                    errorMessage: nestErrorMessage(error),
                  }),
                  'error'
                )
            );
          }}
        />
      )}
    </Box>
  );
};

export const RequestFormActionItem = ({
  requestFormRow,
  refresh,
  afterClose,
}: {
  requestFormRow: RequestFormTodo;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const [isOpen, setIsOpen] = useState(false);
  const [showMessage] = useMessage();

  const { data: submittedForm, error } = useApiClient(RequestFormsEndpoints.getSubmittedById(requestFormRow.id), {
    suspense: false,
  });

  useEffect(() => {
    if (submittedForm) {
      setIsOpen(true);
    } else if (error) {
      showMessage(
        polyglot.t('RequestFormActionItemDrawerContent.errorMessages.load', {
          errorMessage: error.data?.message ?? error.statusText,
        }),
        'error'
      );
    }
  }, [polyglot, error, showMessage, submittedForm]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      {submittedForm ? (
        <RequestFormActionItemDrawerContent
          submittedForm={submittedForm}
          close={async () => {
            setIsOpen(false);
            await refresh();
          }}
        />
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};
