import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { REVIEWS_COMPANY_ONGOING_ROUTE, REVIEWS_TEAM_ONGOING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReachType, ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const GeneralDuplicateModal = ({
  reviewCycle,
  reach,
  isOpen,
  setIsOpen,
  onClose,
}: {
  reviewCycle: ReviewCycle | undefined;
  reach: ReachType;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <GeneralDuplicateModalContent reviewCycle={reviewCycle} onClose={onClose} reach={reach} />
    </DrawerModal>
  );
};

const GeneralDuplicateModalContent = ({
  reviewCycle,
  reach,
  onClose,
}: {
  reviewCycle: ReviewCycle | undefined;
  reach: ReachType;
  onClose: () => void;
}) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const handleDuplicateCycle = async () => {
    try {
      const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_ROUTE : REVIEWS_TEAM_ONGOING_ROUTE;

      if (!reviewCycle) {
        showMessage('Cycle does not exist', 'error');
        return;
      }
      await ReviewCycleAPI.duplicateCycle(reviewCycle.id);
      showMessage('Successfully duplicate the cycle', 'success');
      routerHistory.push(reachRoute);
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(nestErrorMessage(error), 'error');
      }
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Duplicate cycle</Typography>
      <Typography variant="caption" color="Grey">
        All cycle settings will be duplicated into a new draft cycle.
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent onClick={onClose} sizeVariant="medium" colorVariant="secondary" fullWidth>
          Cancel
        </ButtonComponent>
        <ButtonComponent onClick={handleDuplicateCycle} sizeVariant="medium" colorVariant="primary" fullWidth>
          Duplicate
        </ButtonComponent>
      </Box>

      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.GROWTH_PRO}
        messageSuffix="proGeneric"
      />
    </Box>
  );
};
