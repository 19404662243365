import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { OnboardingContractConfig } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { TemplateAPI } from '@/api-client/templates.api';
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { ContractTemplate } from '@/v2/feature/templates/templates.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const validationSchema = yup.object().shape({
  templateId: yup.string().required('Select a contract template'),
});

type EditContractsProps = {
  contract?: OnboardingContractConfig;
  onSave: (contract: OnboardingContractConfig) => void;
};

export const EditContracts = ({ contract, onSave }: EditContractsProps) => {
  const { polyglot } = usePolyglot();
  const [contractTemplates, setContractTemplates] = useState<ContractTemplate[]>();
  const routerHistory = useHistory();
  const refreshContractTemplates = useCallback(async () => {
    TemplateAPI.getTemplates().then(setContractTemplates);
  }, []);

  const formik = useFormik({
    initialValues: {
      templateId: contract?.templateId ?? '',
    },
    validationSchema,
    onSubmit(values) {
      const selectedContract = contractTemplates?.find((t) => t.id === values.templateId);
      if (!selectedContract) return;
      onSave({
        templateId: selectedContract.id,
        templateName: selectedContract.name,
      });
    },
  });

  useEffect(() => {
    refreshContractTemplates();
  }, [refreshContractTemplates]);

  const templateOptions = useMemo(() => {
    return contractTemplates
      ?.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
      .map((t) => ({
        label: t.name,
        value: t.id,
      }));
  }, [contractTemplates]);

  return (
    <>
      <Typography sx={themeFonts.title2}>{polyglot.t('EditContracts.select')}</Typography>
      {!templateOptions && <LoadingSpinner />}
      {templateOptions && templateOptions.length > 0 && (
        <>
          <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
            {polyglot.t('EditContracts.chooseJoiner')}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ mt: spacing.mt30, gap: spacing.g20 }}>
              <SelectComponent
                name="templateId"
                label={polyglot.t('EditContracts.template')}
                options={templateOptions}
                onChange={formik.handleChange}
                value={formik.values.templateId ?? null}
                error={formik.touched.templateId && Boolean(formik.errors.templateId)}
                helperText={formik.touched.templateId && formik.errors.templateId}
              />
            </Stack>

            <ButtonComponent
              type="submit"
              fullWidth
              style={{ marginTop: '40px' }}
              colorVariant="primary"
              sizeVariant="large"
            >
              {polyglot.t('General.save')}
            </ButtonComponent>
          </form>
        </>
      )}
      {templateOptions && templateOptions.length === 0 && (
        <>
          <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
            {polyglot.t('EditContracts.noTemplate')}
          </Typography>
          <ButtonComponent
            type="button"
            onClick={() => routerHistory.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE)}
            fullWidth
            colorVariant="primary"
            sizeVariant="large"
            style={{ marginTop: '40px' }}
          >
            {polyglot.t('EditContracts.goToDocuments')}
          </ButtonComponent>
        </>
      )}
    </>
  );
};
