import React, { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmFirewallPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-firewall-policy-editor.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  FirewallOptions,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmFirewallSecurityPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const getPolicy = (): FirewallOptions => {
    return {
      EnableFirewall: configurablePoliciesSelected?.configurableFirewallPolicy?.EnableFirewall ?? false,
      BlockAllIncoming: configurablePoliciesSelected?.configurableFirewallPolicy?.BlockAllIncoming ?? false,
      EnableStealthMode: configurablePoliciesSelected?.configurableFirewallPolicy?.EnableStealthMode ?? false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FILE_VAULT_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: FirewallOptions = getPolicy();

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Firewall</Typography>
          <IconButton
            onClick={() => {
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Enable Firewall
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.EnableFirewall
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.EnableFirewall ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Block all incoming
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.BlockAllIncoming
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.BlockAllIncoming ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Enable stealth mode
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.EnableStealthMode
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.EnableStealthMode ? 'Enabled' : 'Not set'}
          </Typography>
        </Box>
      </Box>

      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmFirewallPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
