import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { OptionObjectKey } from '@/v2/feature/custom-fields/custom-profile-fields.interface';

export const sortCustomFields = (customFields: UserCustomDataDto[]) => {
  return customFields.sort((a, b) =>
    a.field.fieldName.localeCompare(b.field.fieldName, undefined, { sensitivity: 'base' })
  );
};

export const sortSelectOptions = (customFieldOptions: OptionObjectKey[]) => {
  // each option key is defined as `Answer n` - this is a poor choice because
  // the key is not easily sortable and there were no uniqueness checks.
  const sortedOptions = [...customFieldOptions].sort((a, b) => {
    return Number(a.key?.match(/\d+$/)?.[0] ?? '0') - Number(b.key?.match(/\d+$/)?.[0] ?? '0');
  });

  // always re-key the options to force them to be unique
  for (let i = 0; i < sortedOptions.length; i++) {
    sortedOptions[i] = { ...sortedOptions[i], key: `Answer ${i + 1}` };
  }

  return sortedOptions;
};
