import { SxProps, Theme } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const secondaryLink: SxProps<Theme> = {
  ...themeFonts.caption,
  color: themeColors.Grey,
  textDecoration: 'none',
  transition: 'color ease-in 0.15s',
  width: 'fit-content',
  '&:hover': {
    color: themeColors.DarkGrey,
    textDecoration: 'none',
  },
};
