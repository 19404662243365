import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

import { IdCheckPackage, IdCheckPackageCreate, IndividualCheck } from '@/v2/feature/id-check/id-check.interface';

export class IDCheckAPI {
  static async updateIndividualCheck(data: IndividualCheck): Promise<void> {
    await axios.patch(`/apiv2/individual-checks/${data.id}`, data);
  }

  static async updatePackage(id: number, data: IdCheckPackageCreate): Promise<void> {
    await axios.patch(`/apiv2/id-check-package/${id}`, data);
  }

  static async createPackage(data: IdCheckPackageCreate): Promise<void> {
    await axios.post(`/apiv2/id-check-package`, data);
  }

  static async deletePackageById(packageId: number): Promise<void> {
    await axios.delete(`/apiv2/id-check-package/${packageId}`);
  }
}

export class IdCheckEndpoints {
  static getIdCheckPackages(): Endpoint<IdCheckPackage[]> {
    return {
      url: `/apiv2/id-check-package`,
    };
  }
  static getIndividualChecks(): Endpoint<IndividualCheck[]> {
    return {
      url: `/apiv2/individual-checks`,
    };
  }
}
