import { SyntheticEvent } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { InstalledAppDto } from '../app-integration.dto';

import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
interface Props {
  app: InstalledAppDto;
  avatarImg?: string | undefined;
  readonly stub?: AppIntegrationStub | string;
  readonly title: string;
  handleClick: () => void;
  expired?: boolean;
  expiredAction: (app: InstalledAppDto, event: SyntheticEvent<Element, Event>) => void;
  action?: JSX.Element;
}
export const AppButton = ({
  app,
  avatarImg,
  stub,
  title,
  handleClick,
  expired = false,
  action,
  expiredAction,
}: Props) => {
  return (
    <Box>
      <Box
        onClick={() => handleClick()}
        sx={{
          cursor: 'pointer',
          borderRadius: radius.br20,
          border: `1px solid ${themeColors.Background}`,
          p: spacing.p25,
          width: '60px',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src={avatarImg} width={60} alt={stub} />
      </Box>
      <Typography
        sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, marginTop: spacing.m10, textAlign: 'center' }}
      >
        {title}
      </Typography>
      {expired && (
        <Stack direction="column">
          <Box
            sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: 'auto', justifyContent: 'center' }}
          >
            <Mistake {...iconSize} />
            <Typography sx={{ ...themeFonts.title5, ml: spacing.ml5 }}>Expired</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              margin: 'auto',
              justifyContent: 'center',
              mt: '5px',
            }}
          >
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="small"
              onClick={(event) => expiredAction(app, event)}
            >
              Renew
            </ButtonComponent>
          </Box>
        </Stack>
      )}
      {!!action && !expired && action}
    </Box>
  );
};
