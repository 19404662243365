import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { borders } from '@/v2/styles/borders.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SingleAnswerQuestionReadonly = ({
  questionText,
  options,
  hasComment,
  isCommentRequired,
}: {
  questionText: string;
  options: Record<string, string>;
  hasComment: boolean;
  isCommentRequired: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <Typography variant="caption" dangerouslySetInnerHTML={{ __html: questionText ?? '' }}></Typography>

      <FormControl component="fieldset" sx={{ width: '100%', margin: 0 }}>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="answer" aria-disabled={true}>
          {options &&
            Object.keys(options).map((a) => (
              <FormControlLabel
                key={a}
                labelPlacement="end"
                value={a}
                disabled={true}
                checked={false}
                control={<StyledRadio />}
                label={<Typography variant="caption">{options[+a]}</Typography>}
                sx={{
                  py: spacing.p10,
                  margin: 0,
                  gap: spacing.g10,
                  px: 0,
                  '& span.MuiButtonBase-root.MuiRadio-root': {
                    padding: 0,
                  },
                }}
              />
            ))}
        </RadioGroup>
      </FormControl>

      {hasComment && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.g8,
            pt: spacing.p16,
            borderTop: `${borders.background}`,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Add comment
            </Typography>
            <Typography variant="caption" color="Grey">
              {isCommentRequired ? 'Required' : 'Optional'}
            </Typography>
          </Box>
          <RichTextField value={null} disabled={true} />
        </Box>
      )}
    </Box>
  );
};
