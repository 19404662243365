import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Badge,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ViewQueueToCleanUpSummaryDrawer } from '@v2/feature/monitoring/component/view-queue-to-clean-up-summary-drawer.component';
import Bull from 'bull';
import { Route, Switch } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import cover from '@/images/illustration_devices.png';
import { ReactComponent as Delete } from '@/images/side-bar-icons/Delete.svg';
import { SUPER_ADMIN_MONITORING_ROUTE } from '@/lib/routes';
import { DailyExternalPayrollSyncJob } from '@/models/company.model';
import { SubscriptionAutoBillerJob, SubscriptionChargeJobData } from '@/models/subscription.model';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { MonitoringAPI } from '@/v2/feature/monitoring/monitoring.api';
import { QueueStatus, RegenerateCalendarOrSyncCompanyEventsJob } from '@/v2/feature/monitoring/monitoring.interface';
import { getStartingTimeForJob } from '@/v2/feature/monitoring/monitoring.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { timeAgo } from '@/v2/util/date-format.util';

export const QUEUE_TABS = [
  { value: 'Delayed' },
  { value: 'Active' },
  { value: 'Completed' },
  { value: 'Failed' },
  { value: 'Waiting' },
] as const;

export enum MonitoringQueue {
  GoogleCalendar = 'google-calendar',
  OutlookCalendar = 'outlook-calendar',
  AppActions = 'app-actions',
  AppEmails = 'app-emails',
  PayrunEntries = 'payrun-entries',
  Billing = 'billing',
  BillPayment = 'bill-payment',
  Payroll = 'payroll',
  ReviewCycle = 'review_cycle',
  SurveyCycle = 'survey_cycle',
  DeviceMDM = 'device-mdm',
  DeviceNotifications = 'device-notifications',
  DeviceMDMSync = 'mdm-device-sync',
  ImportAttendances = 'attendance-import',
  ImportUsers = 'user-import',
  ImportAbsences = 'absence-import',
}

const QUEUES = [
  { value: MonitoringQueue.GoogleCalendar },
  { value: MonitoringQueue.OutlookCalendar },
  { value: MonitoringQueue.AppActions },
  { value: MonitoringQueue.AppEmails },
  { value: MonitoringQueue.PayrunEntries },
  { value: MonitoringQueue.Billing },
  { value: MonitoringQueue.BillPayment },
  { value: MonitoringQueue.Payroll },
  { value: MonitoringQueue.ReviewCycle },
  { value: MonitoringQueue.SurveyCycle },
  { value: MonitoringQueue.DeviceMDM },
  { value: MonitoringQueue.DeviceNotifications },
  { value: MonitoringQueue.DeviceMDMSync },
  { value: MonitoringQueue.ImportAttendances },
  { value: MonitoringQueue.ImportUsers },
  { value: MonitoringQueue.ImportAbsences },
] as const;

const QUEUES_SUPPORTING_DELETION = ['google-calendar', 'outlook-calendar'];

export const MonitoringPage = (): JSX.Element => {
  const [showMessage] = useMessage();
  const [currentQueue, setCurrentQueue] = useState<MonitoringQueue | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState<string>('Active');
  const { getCachedUserById } = useCachedUsers();
  const [isViewQueueToCleanUpSummaryDrawerOpen, setIsViewQueueToCleanUpSummaryDrawerOpen] = useState<boolean>(false);
  const [queuesToCleanUp, setQueuesToCleanUp] = useState<Map<string, number>>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [jobToRemove, setJobToRemove] = useState<Bull.Job | null>(null);

  const [loading, setLoading] = useState(true);
  const [localRefreshing, setLocalRefreshing] = useState(false);
  const [currentQueueData, setCurrentQueueData] = useState<
    | QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>
    | QueueStatus<SubscriptionAutoBillerJob>
    | QueueStatus<DailyExternalPayrollSyncJob>
    | QueueStatus<SubscriptionChargeJobData>
    | QueueStatus<Record<any, unknown>>
    | QueueStatus<unknown>
    | undefined
  >(undefined);

  type QueueApiFunction<T> = () => Promise<QueueStatus<T>>;

  interface QueueApiMap<T> {
    [key: string]: QueueApiFunction<T>;
  }

  const fetchQueueData = useCallback(
    async (disableLoaders = false) => {
      const queueApiMap: QueueApiMap<any> = {
        [MonitoringQueue.GoogleCalendar]: MonitoringAPI.getGoogleCalendarQueueDetails,
        [MonitoringQueue.ImportAbsences]: MonitoringAPI.getAbsenceImportQueueDetails,
        [MonitoringQueue.OutlookCalendar]: MonitoringAPI.getOutlookCalendarQueueDetails,
        [MonitoringQueue.PayrunEntries]: MonitoringAPI.getPayrunEntriesQueueDetails,
        [MonitoringQueue.AppActions]: MonitoringAPI.getAppActionsQueueDetails,
        [MonitoringQueue.AppEmails]: MonitoringAPI.getAppEmailsQueueDetails,
        [MonitoringQueue.Billing]: MonitoringAPI.getBillingQueueDetails,
        [MonitoringQueue.BillPayment]: MonitoringAPI.getBillPaymentQueueDetails,
        [MonitoringQueue.Payroll]: MonitoringAPI.getPayrollQueueDetails,
        [MonitoringQueue.ReviewCycle]: MonitoringAPI.getReviewQueueDetails,
        [MonitoringQueue.SurveyCycle]: MonitoringAPI.getSurveyQueueDetails,
        [MonitoringQueue.DeviceMDM]: MonitoringAPI.getDevicesMDMQueueDetails,
        [MonitoringQueue.DeviceNotifications]: MonitoringAPI.getDevicesNotificationsQueueDetails,
        [MonitoringQueue.DeviceMDMSync]: MonitoringAPI.getInHouseMdmDeviceSyncQueueDetails,
        [MonitoringQueue.ImportAttendances]: MonitoringAPI.getAttendanceImportQueueDetails,
        [MonitoringQueue.ImportUsers]: MonitoringAPI.getUsersImportQueueDetails,
      };

      try {
        if (!disableLoaders) setLoading(true);
        else setLocalRefreshing(true);
        if (!currentQueue) return;
        const apiFunction = queueApiMap[currentQueue];
        if (apiFunction) {
          const selectedQueueData = await apiFunction();
          setCurrentQueueData(selectedQueueData);
        } else {
          setCurrentQueueData(undefined);
        }
      } catch (error) {
        showMessage('Could not load queue data', 'error');
      } finally {
        if (!disableLoaders) setLoading(false);
        else setLocalRefreshing(false);
      }
    },
    [currentQueue, showMessage]
  );

  useEffect(() => {
    (async () => {
      fetchQueueData();
    })();
  }, [fetchQueueData]);

  const getCompanyForRow = useCallback(
    (row: Bull.Job) => {
      if (currentQueue === 'payrun-entries') {
        return row?.data?.p45Payloads[0]?.companyId;
      } else if (row?.data?.companyId) {
        return row?.data?.companyId;
      } else if (row?.data?.company) {
        return `${row?.data?.company?.name}(id: ${row?.data?.company?.companyId})`;
      } else return '';
    },
    [currentQueue]
  );

  const getNameForUser = useCallback(
    (row: Bull.Job) => {
      if (currentQueue === 'payrun-entries' && row?.data?.p45Payloads.length > 0) {
        return `${getCachedUserById(row?.data?.p45Payloads[0]?.userId)?.displayName} (${
          row?.data?.p45Payloads[0]?.userId
        })`;
      } else if (currentQueue !== 'payrun-entries' && row?.data.appUserId) {
        return row?.data?.appUserId;
      } else if (currentQueue !== 'payrun-entries' && row?.data?.userId) {
        return getCachedUserById(row?.data?.userId)?.displayName + ` (${row?.data?.userId})`;
      } else return '';
    },
    [currentQueue, getCachedUserById]
  );

  const confirmTriggerDeleteJobFromQueue = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, job: Bull.Job) => {
    setJobToRemove(job);
    setAnchorEl(event.currentTarget);
    setIsRemovalModalOpen(true);
  };

  const deleteJobFromQueue = async () => {
    if (jobToRemove && currentQueue) {
      setLoading(true);
      const updatedQueueStatus = await MonitoringAPI.deleteJobFromCalendarQueue(currentQueue, jobToRemove.id);
      setCurrentQueueData(updatedQueueStatus);
      setLoading(false);
    }
    setJobToRemove(null);
    setIsRemovalModalOpen(false);
    setAnchorEl(null);
  };

  const tableColumns = useMemo<ColumnDef<Bull.Job, Bull.Job>[]>(
    () => [
      {
        header: () => 'jobId',
        id: 'id',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.id ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Job Name',
        id: 'name',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{original.name ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Company',
        id: 'company',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{getCompanyForRow(original) ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'User',
        id: 'user',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{getNameForUser(original) ?? <EmptyCell />}</div>,
      },
      {
        header: () => `${currentTab !== 'Delayed' ? 'Started' : 'Starting on'}`,
        id: 'timeAgo',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => (
          <div>
            {original.processedOn ? (
              timeAgo(new Date(original.processedOn).toISOString())
            ) : original.opts.delay && original.opts.delay > 0 ? (
              `${getStartingTimeForJob(original)} UTC`
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
      },
      {
        header: () => 'Time taken',
        id: 'duration',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => (
          <div>
            {original.finishedOn && original.processedOn ? (
              `${((original.finishedOn - original.processedOn) / 1000).toFixed(2)} seconds`
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
      },
      {
        header: () => 'attempts',
        id: 'attemptsMade',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => (
          <div>{original.attemptsMade === 0 ? 1 : original.attemptsMade ?? <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'returnValue',
        id: 'returnValue',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) =>
          (
            <Tooltip
              title={
                original.returnvalue
                  ? JSON.stringify(original.returnvalue, null, 2)
                  : JSON.stringify(original.data, null, 2)
              }
              placement="bottom-start"
            >
              <Box>{original.returnvalue ? JSON.stringify(original.returnvalue) : JSON.stringify(original.data)}</Box>
            </Tooltip>
          ) ?? <EmptyCell />,
      },
      {
        header: () => 'failedReason',
        id: 'failedReason',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => <div>{JSON.stringify(original.failedReason) ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'action',
        id: 'action',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => (
          <IconButton
            color="error"
            sx={{ display: currentQueue && QUEUES_SUPPORTING_DELETION.includes(currentQueue) ? '' : 'none' }}
            onClick={(event) => {
              confirmTriggerDeleteJobFromQueue(event, original);
            }}
          >
            <Delete />
          </IconButton>
        ),
      },
    ],
    [currentQueue, currentTab, getCompanyForRow, getNameForUser]
  );

  const currentTabData = useMemo(() => {
    switch (currentTab) {
      case 'Active':
        return (currentQueueData?.active as unknown) as Bull.Job<Record<any, unknown>>[];
      case 'Completed':
        return (currentQueueData?.completed as unknown) as Bull.Job<Record<any, unknown>>[];
      case 'Failed':
        return (currentQueueData?.failed as unknown) as Bull.Job<Record<any, unknown>>[];
      case 'Waiting':
        return (currentQueueData?.waiting as unknown) as Bull.Job<Record<any, unknown>>[];
      case 'Delayed':
        return (currentQueueData?.delayed as unknown) as Bull.Job<Record<any, unknown>>[];
      default:
        return [];
    }
  }, [currentQueueData, currentTab]);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_MONITORING_ROUTE}>
        <BackofficeRootStyle>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Monitoring Dashboard</Typography>
            }
          />
          <ContentWrapper loading={loading}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  alignItems: 'left',
                }}
              >
                <FormControl disabled={loading}>
                  <TextField
                    select
                    label={currentQueue ? 'Selected queue' : 'Please select a queue'}
                    size="small"
                    sx={{ minWidth: '250px' }}
                    value={currentQueue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setCurrentQueue((event.target as HTMLInputElement).value as MonitoringQueue)
                    }
                  >
                    {QUEUES.map((queue) => (
                      <MenuItem key={queue.value} value={queue.value}>
                        {queue.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mr: 4,
                }}
              >
                <IconButton color="secondary" onClick={() => fetchQueueData(true)}>
                  <RefreshIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={async () => {
                    const map = await MonitoringAPI.getNumberOfJobsToCleanUp();
                    setQueuesToCleanUp(map);
                    setIsViewQueueToCleanUpSummaryDrawerOpen(true);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={(e, value) => setCurrentTab(value)}
              >
                {QUEUE_TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    disableRipple
                    label={
                      <span
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          paddingRight:
                            // @ts-ignore
                            currentQueueData && currentQueueData[tab.value.toLowerCase()].length > 0 ? 10 : 0,
                        }}
                      >
                        {tab.value}
                        {/* @ts-ignore */}
                        {currentQueueData && currentQueueData[tab.value.toLowerCase()].length > 0 && (
                          <Badge
                            badgeContent={
                              // @ts-ignore
                              currentQueueData && currentQueueData[tab.value.toLowerCase()].length > 0
                                ? // @ts-ignore
                                  currentQueueData[tab.value.toLowerCase()].length
                                : ''
                            }
                            color="primary"
                            sx={{ ml: 2 }}
                          />
                        )}
                      </span>
                    }
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </Box>
            {currentQueueData && currentTab && (
              <BasicTable
                rowData={currentTabData ?? []}
                columnData={tableColumns}
                loading={localRefreshing ? localRefreshing : loading}
              />
            )}
            {!currentQueueData && (
              <EmptyStateComponent
                header={!currentQueue ? 'No queue selected' : 'No queue data'}
                subheader={
                  !currentQueue
                    ? 'Please select a queue from above in order to see job data'
                    : 'This queue / state does not have any job data'
                }
                cover={cover}
              />
            )}
            <NotificationModal
              isOpen={isRemovalModalOpen}
              onClose={() => setIsRemovalModalOpen(false)}
              anchorEl={anchorEl}
              takeAction={async () => {
                deleteJobFromQueue();
              }}
              message={`Are you sure you want to delete this job from the queue?`}
              callToAction="Yes"
            />
            <ViewQueueToCleanUpSummaryDrawer
              isOpen={isViewQueueToCleanUpSummaryDrawerOpen}
              setIsOpen={setIsViewQueueToCleanUpSummaryDrawerOpen}
              onClose={async () => {
                setIsViewQueueToCleanUpSummaryDrawerOpen(false);
              }}
              queues={queuesToCleanUp}
            />
          </ContentWrapper>
        </BackofficeRootStyle>
      </Route>
    </Switch>
  );
};
