import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendancePayrollPenaltiesDrawer } from '@v2/feature/attendance/settings/components/attendance-payroll-penalties-drawer.component';
import { AttendancePenaltyRuleCell } from '@v2/feature/attendance/settings/components/attendance-penalty-rule-cell.component';
import { AttendancePenaltyEndpoints } from '@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.api';
import { AttendancePenaltyDto } from '@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface PenaltiesReadOnlySectionProps {
  readonly schedule: AttendanceScheduleDto;
}

export const AttendancePayrollPenaltiesSubSection = ({ schedule }: PenaltiesReadOnlySectionProps) => {
  const {
    data: attendancePenaltyRules,
    mutate: refreshRules,
    isLoading: isLoadingPenalties,
  } = useApiClient(AttendancePenaltyEndpoints.getAttendanceSchedulePenaltyRules(schedule.id), { suspense: false });

  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);

  const refreshPenalties = useCallback(async () => {
    if (refreshRules) await refreshRules();
  }, [refreshRules]);

  const filteredPenaltyRules = useMemo(() => {
    if (!attendancePenaltyRules) return [];
    if (schedule.isFlexible) {
      return attendancePenaltyRules.filter((f) => f.name.includes('General'));
    }
    return attendancePenaltyRules;
  }, [attendancePenaltyRules, schedule.isFlexible]);

  const enabledPenalties = useMemo(() => filteredPenaltyRules?.filter((p) => p.enabled) ?? [], [filteredPenaltyRules]);

  return (
    <SettingsSubsectionContent
      sections={[
        {
          title: polyglot.t('AttendanceModule.penalties'),
          onEdit: () => {
            setEdit(true);
          },
          loading: isLoadingPenalties,
          headerWidth: '720px',
          contentWidth: '720px',
          items: [
            {
              type: SectionItemType.Component,
              value: (
                <PayrollPenaltiesTable
                  enabledPenalties={enabledPenalties}
                  edit={edit}
                  setEdit={setEdit}
                  schedule={schedule}
                  attendancePenaltyRules={attendancePenaltyRules ?? []}
                  refreshPenalties={refreshPenalties}
                />
              ),
            },
          ],
        },
      ]}
    />
  );
};

interface TableProps {
  readonly enabledPenalties: AttendancePenaltyDto[];
  readonly edit: boolean;
  readonly setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  readonly schedule: AttendanceScheduleDto;
  readonly attendancePenaltyRules: AttendancePenaltyDto[];
  readonly refreshPenalties: () => Promise<void>;
}

const PayrollPenaltiesTable = ({
  enabledPenalties,
  edit,
  setEdit,
  schedule,
  attendancePenaltyRules,
  refreshPenalties,
}: TableProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Box>
      <Box key="titles" sx={{ display: 'flex', gap: '5px' }}>
        <Typography variant="captionSmall" color="Grey" sx={{ width: '200px' }}>
          {polyglot.t('AttendanceModule.penalty')}
        </Typography>
        <Typography variant="captionSmall" color="Grey" sx={{ width: '125px' }}>
          {polyglot.t('AttendanceModule.Penalties.penalty1')}
        </Typography>
        <Typography variant="captionSmall" color="Grey" sx={{ width: '125px' }}>
          {polyglot.t('AttendanceModule.Penalties.penalty2')}
        </Typography>
        <Typography variant="captionSmall" color="Grey" sx={{ width: '125px' }}>
          {polyglot.t('AttendanceModule.Penalties.penalty3')}
        </Typography>
        <Typography variant="captionSmall" color="Grey" sx={{ width: '125px' }}>
          {polyglot.t('AttendanceModule.Penalties.penalty4')}
        </Typography>
      </Box>
      {enabledPenalties.map((p) => (
        <Box key={p.id} sx={{ display: 'flex', gap: '5px' }}>
          <Typography variant="caption" sx={{ width: '200px' }}>
            {p.name}
          </Typography>
          <AttendancePenaltyRuleCell width="125px" percent={p.multiplier1} />
          <AttendancePenaltyRuleCell width="125px" percent={p.multiplier2} />
          <AttendancePenaltyRuleCell width="125px" percent={p.multiplier3} />
          <AttendancePenaltyRuleCell width="125px" percent={p.multiplier4} />
        </Box>
      ))}

      <AttendancePayrollPenaltiesDrawer
        isOpen={edit}
        setIsOpen={setEdit}
        schedule={schedule}
        allPenalties={attendancePenaltyRules}
        refresh={refreshPenalties}
      />
    </Box>
  );
};
