import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { PaginationState } from '@tanstack/react-table';
import { Content2Columns } from '@v2/feature/app-layout/features/main-content/layouts/components/content-2-columns.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { addMonths, addWeeks, getMonth, getWeek, subMonths, subWeeks } from 'date-fns';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AttendanceAPI } from '@/v2/feature/attendance/attendance.api';
import { UserAttendanceTableAndHeaderData } from '@/v2/feature/attendance/attendance.dto';
import { UserAttendancesDetail } from '@/v2/feature/attendance/company/sections/user-attendances-detail.section';
import { AttendanceViewTopHeaderActions } from '@/v2/feature/attendance/components/attendance-view-top-header-actions.component';
import { ListSectionComponent } from '@/v2/feature/attendance/components/list-section.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserAPI } from '@/v2/feature/user/user.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { LocalDate } from '@/v2/util/local-date';

interface AttendanceCompanyViewProps {
  readonly view: 'company' | 'team' | 'user';
}

const buildFilterString = (type: 'Month' | 'Week', date: Date) =>
  `type=${type}&date=${new LocalDate(date).toDateString()}`;

export const AttendanceCompanyView = ({ view }: AttendanceCompanyViewProps) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById, loaded } = useCachedUsers();
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [attendanceRequests, setAttendanceRequests] = useState<{ userId: number; count: number }[] | undefined>(
    undefined
  );
  const [selectedUser, setSelectedUser] = useState<number | undefined>(undefined);
  const [selectedUserAttendances, setSelectedUserAttendances] = useState<UserAttendanceTableAndHeaderData | undefined>(
    undefined
  );
  const [state, dispatch] = useContext(GlobalContext);

  const [rangeType, setRangeType] = useState<'Month' | 'Week'>(
    state.user.features?.attendance?.table?.rangeType ?? 'Month'
  );
  const [selectedDate, setSelectedDate] = useState<Date>(() => new LocalDate().getDate());
  const [buttonTitle, setButtonTitle] = useState<string>(() =>
    rangeType === 'Month'
      ? new LocalDate().toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
      : polyglot.t('UserAttendancePage.weekNum', { weekNum: getWeek(new Date()) })
  );
  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState<string>(buildFilterString(rangeType, new LocalDate().getDate()));
  const [showMessage] = useMessage();

  const getUserAttendances = useCallback(async () => {
    const { pageIndex, pageSize } = pagination;
    setLoading(true);
    setUserLoading(true);
    setSelectedUser(undefined);
    setSelectedUserAttendances(undefined);

    try {
      const requestsResponse = await AttendanceAPI.findAllAttendancePerUserId(
        pageIndex,
        pageSize,
        searchInput,
        filterString
      );

      setTotalPages(requestsResponse.totalPages);
      setTotalItems(requestsResponse.totalItems);
      setAttendanceRequests(requestsResponse.items);

      if (!requestsResponse?.items?.length) {
        return;
      }

      const userAttendances = await AttendanceAPI.getAttendanceTableByUserId(
        requestsResponse.items[0].userId,
        filterString
      );

      setSelectedUser(requestsResponse.items[0].userId);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotFetchUserAttendances', { nestError: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setUserLoading(false);
      setLoading(false);
    }
  }, [pagination, filterString, searchInput, showMessage, polyglot]);

  useEffect(() => {
    getUserAttendances();
  }, [getUserAttendances]);

  const listUsers = useMemo(() => {
    return attendanceRequests
      ? attendanceRequests.map((n) => {
          return {
            id: n.userId,
            name: getCachedUserById(n.userId)?.displayName ?? '',
            details: polyglot.t('AttendanceDomain.noOfPending', { smart_count: n.count }),
            avatar: <UserAvatar userId={n.userId} />,
            showAlert: n.count > 0,
            alertColor: n.count > 0 ? themeColors.Orange : 'none',
          };
        })
      : [];
  }, [attendanceRequests, getCachedUserById, polyglot]);

  const onPageChange = (pageIndex: number, pageSize: number) => {
    setPagination({ pageIndex, pageSize });
  };

  const handleUserAttendance = async (userId: number) => {
    setUserLoading(true);
    try {
      setSelectedUser(userId);
      const userAttendances = await AttendanceAPI.getAttendanceTableByUserId(userId, filterString);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotFetchUserAttendanceDetails', {
          nestError: nestErrorMessage(error),
        }),
        'error'
      );
      setSelectedUser(undefined);
    } finally {
      setUserLoading(false);
    }
  };

  const refreshListUsers = async () => {
    const { pageIndex, pageSize } = pagination;
    setLoading(true);
    setSelectedUserAttendances(undefined);

    try {
      const requestsResponse = await AttendanceAPI.findAllAttendancePerUserId(
        pageIndex,
        pageSize,
        searchInput,
        filterString
      );

      setTotalPages(requestsResponse.totalPages);
      setAttendanceRequests(requestsResponse.items);
      if (!requestsResponse?.items?.length) {
        return;
      }

      const user =
        selectedUser && requestsResponse.items.some((u) => u.userId === selectedUser)
          ? selectedUser
          : requestsResponse.items[0].userId;

      setUserLoading(true);
      const userAttendances = await AttendanceAPI.getAttendanceTableByUserId(user, filterString);

      setSelectedUser(user);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotFetchUserAttendances', { nestError: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
      setUserLoading(false);
    }
  };

  const navigateDate = (operation: 'add' | 'sub') => {
    try {
      const isWeek = rangeType === 'Week';
      const operationFn = operation === 'add' ? (isWeek ? addWeeks : addMonths) : isWeek ? subWeeks : subMonths;
      const updatedSelectedDate: Date = operationFn(selectedDate, 1);
      const title = isWeek
        ? polyglot.t('UserAttendancePage.weekNum', { weekNum: getWeek(updatedSelectedDate) })
        : new LocalDate(updatedSelectedDate).toLocaleDateString(undefined, {
            month: 'short',
            year: 'numeric',
          });
      setSelectedDate(updatedSelectedDate);
      setButtonTitle(title);
      setFilterString(buildFilterString(rangeType, updatedSelectedDate));
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.somethingWentWrong', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const navigateBack = () => navigateDate('sub');
  const navigateNext = () => navigateDate('add');

  const handleRange = async (type: 'Month' | 'Week') => {
    const updatedButtonTitle =
      type === 'Month'
        ? new LocalDate(selectedDate).toLocaleDateString(undefined, { month: 'short', year: 'numeric' })
        : polyglot.t('UserAttendancePage.weekNum', { weekNum: getWeek(selectedDate) });

    setRangeType(type);
    setButtonTitle(updatedButtonTitle);
    setFilterString(buildFilterString(type, selectedDate));
    const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('attendance', 'table', 'rangeType', type);
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: updatedGlobalUser,
    });
  };

  const recenterToday = () => {
    const todayDate = new LocalDate().getDate();
    if (rangeType === 'Month') {
      setSelectedDate(todayDate);
      setButtonTitle(() => new LocalDate(todayDate).toLocaleDateString(undefined, { month: 'short', year: 'numeric' }));
      setFilterString(buildFilterString(rangeType, todayDate));
    } else {
      setSelectedDate(todayDate);
      const weekTitle = polyglot.t('UserAttendancePage.weekNum', { weekNum: getWeek(todayDate) });
      setButtonTitle(weekTitle);
      setFilterString(buildFilterString(rangeType, todayDate));
    }
  };

  const showToday = useMemo(() => {
    const todayDate = new LocalDate().getDate();
    return getWeek(selectedDate) !== getWeek(todayDate) || getMonth(selectedDate) !== getMonth(todayDate);
  }, [selectedDate]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('AttendanceDomain.Attendance')}</Typography>}
        actions={
          <AttendanceViewTopHeaderActions
            handleRange={handleRange}
            rangeType={rangeType}
            navigateBack={navigateBack}
            navigateNext={navigateNext}
            buttonTitle={buttonTitle}
            recenterToday={recenterToday}
            showToday={showToday}
          />
        }
        showAction
      />

      <ContentWrapper sx={{ pt: 0 }}>
        <Content2Columns
          column1={
            <ListSectionComponent
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              setPagination={setPagination}
              listUsers={listUsers}
              pagination={pagination}
              totalPages={totalPages}
              totalItems={totalItems}
              onPageChange={onPageChange}
              handleUserAttendance={handleUserAttendance}
              loading={loading || !loaded}
              selectedUser={selectedUser}
            />
          }
          column2={
            <UserAttendancesDetail
              selectedUserAttendances={selectedUserAttendances}
              selectedUser={selectedUser}
              loading={loading || userLoading}
              view={view}
              refresh={refreshListUsers}
              rangeType={rangeType}
              selectedDate={selectedDate}
            />
          }
          borderLeft
        />
      </ContentWrapper>
    </RootStyle>
  );
};
