import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const ParticipantsDeleteRevieweeModal = ({
  reviewCycle,
  toDeleteRevieweeIds,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | undefined;
  toDeleteRevieweeIds: number[];
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ParticipantsDeleteRevieweeContent
        reviewCycle={reviewCycle}
        onClose={onClose}
        refresh={refresh}
        toDeleteRevieweeIds={toDeleteRevieweeIds}
      />
    </DrawerModal>
  );
};

const ParticipantsDeleteRevieweeContent = ({
  reviewCycle,
  onClose,
  refresh,
  toDeleteRevieweeIds,
}: {
  reviewCycle: ReviewCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  toDeleteRevieweeIds: number[];
}) => {
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteReviewees = async () => {
    try {
      setLoading(true);
      if (reviewCycle) {
        await ReviewCycleAPI.deleteReviewees(reviewCycle.id, toDeleteRevieweeIds);
        showMessage('Successfully deleted the participants', 'success');
      }
      await refresh();
      onClose();
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Delete participants</Typography>

      <Typography variant="caption" color="Grey">
        This will permanently delete all participant reviews and any associated feedback from reviewers. Are you sure?
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          type="button"
          onClick={handleDeleteReviewees}
          sizeVariant="medium"
          colorVariant="primary"
          name="Remove"
          loading={loading}
          fullWidth
        />
      </Box>
    </Box>
  );
};
