import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteScopesControl, RouteScopesHas } from '@/component/widgets/Scopes';
import {
  SETTINGS_DOCUMENTS_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATES_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE,
  SETTINGS_DOCUMENTS_TYPES_ROUTE,
} from '@/lib/routes';
import { DocumentsSettingsPage } from '@/v2/feature/documents/documents-settings.page';
import { TemplateEditorPage } from '@/v2/feature/templates/pages/template-editor.page';
import { TemplatesPage } from '@/v2/feature/templates/pages/templates.page';

export const DocumentSettingsRouter = () => {
  return (
    <Switch>
      <Route path={SETTINGS_DOCUMENTS_TYPES_ROUTE} exact>
        <DocumentsSettingsPage />
      </Route>

      <RouteScopesHas scopes={['templates']} path={SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE} exact>
        <TemplateEditorPage mode={'readonly'} action={'update_sign_send'} />
      </RouteScopesHas>

      <RouteScopesHas scopes={['templates']} path={SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE} exact>
        <TemplateEditorPage mode={'readonly'} action={'send'} />
      </RouteScopesHas>

      <RouteScopesHas scopes={['templates']} path={SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE} exact>
        <TemplateEditorPage mode={'edit'} />
      </RouteScopesHas>

      <RouteScopesHas scopes={['templates']} path={SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE} exact>
        <TemplateEditorPage mode={'readonly'} action={'preview'} />
      </RouteScopesHas>

      <RouteScopesHas scopes={['templates']} path={SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE} exact>
        <TemplateEditorPage mode={'editForRecipient'} action={'update_before_sending'} />
      </RouteScopesHas>

      <RouteScopesControl scopes={['templates']} path={SETTINGS_DOCUMENTS_TEMPLATES_ROUTE} exact>
        <TemplatesPage />
      </RouteScopesControl>

      <Redirect from={SETTINGS_DOCUMENTS_ROUTE} to={SETTINGS_DOCUMENTS_TYPES_ROUTE} exact />
    </Switch>
  );
};
