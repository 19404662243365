import React from 'react';

import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { isUsingPlaceholderHMRCData } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { ExternalEmployerDto } from '@/v2/feature/payroll/payroll-external.dto';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollHMRCReadonlyStateProps = {
  employer: ExternalEmployerDto;
  onEditClick: () => void;
};

export const PayrollHMRCReadonlyState = ({ employer, onEditClick }: PayrollHMRCReadonlyStateProps) => {
  const { officeNumber, payeReference, accountsOfficeReference } = employer.hmrcDetails;
  const { senderId: govGatewayID, contact } = employer.rtiSubmissionSettings;
  const usingPlaceholderData = isUsingPlaceholderHMRCData({ kind: 'employer', ...employer.hmrcDetails });
  const entries = {
    'PAYE reference': (
      <>
        <span>{officeNumber}</span>
        <span style={{ margin: `0 3px` }}>/</span>
        <span>{payeReference}</span>
      </>
    ),
    'Accounts office reference': accountsOfficeReference,
    'Government gateway ID': govGatewayID,
    'Payroll notifications': contact ? `${contact.firstName} ${contact.lastName} ${contact.email}` : '',
  };
  return (
    <>
      <PayrollSettingSectionHeader showEditButton onEditClick={onEditClick}>
        HMRC connection
      </PayrollSettingSectionHeader>
      <Box
        sx={{
          display: 'inline-grid',
          gridTemplateColumns: '1fr 3fr',
          rowGap: spacing.g10,
          columnGap: spacing.g20,
        }}
      >
        {Object.entries(entries).map(([label, value], idx) => (
          <React.Fragment key={`payroll-setting-${idx}`}>
            <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
              {label}
            </Typography>
            <Typography variant="title4">{usingPlaceholderData ? '-' : value}</Typography>
          </React.Fragment>
        ))}
      </Box>
      {usingPlaceholderData && (
        <Typography variant="caption" sx={{ mt: spacing.mt20 }}>
          Company is not registered with HMRC or has no HMRC information available
        </Typography>
      )}
    </>
  );
};
