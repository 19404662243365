import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactorFormModal } from '@/v2/feature/growth/growth-factor/components/growth-factor-form-modal.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { GrowthScaleEndpoints } from '@/v2/feature/growth/growth-scale/growth-scale.api';
import { GrowthScale } from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { OptionTypeQuestion } from '@/v2/feature/growth/reviews/features/components/option-type-question.component';
import { ScaleConfig } from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { AnswerTypes, QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { CommonSurveyCycleAndBankQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SurveyQuestionContentInterface {
  id: string;
  questionText: string;
  type: string;
  factor: string | null;
  choiceOptions: Record<number, string>;
  hasComment: boolean;
  isCommentRequired: boolean;
  scaleConfig: ScaleConfig | null;
}

const multipleAnswerTypes = [QuestionType.SingleAnswer, QuestionType.MultipleAnswer];
const getQuestionSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    type: Yup.string().oneOf(Object.values(QuestionType), polyglot.t('ValidationMessages.validValue')).required(),
    factor: Yup.string().nullable().notRequired(),
    scaleType: Yup.string().nullable().notRequired(),
    questionText: Yup.string().required(polyglot.t('FormsQuestionModal.errorMessages.questionStatementRequired')),
    hasComment: Yup.boolean().notRequired(),
    isCommentRequired: Yup.boolean().notRequired(),
    scaleConfig: Yup.object()
      .nullable()
      .when('type', {
        is: (val: QuestionType) => val === QuestionType.ScaleQuestion,
        then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

export const SurveyQuestionModalContent = ({
  onClose,
  question,
  refresh,
  onSubmit,
}: {
  onClose: () => void;
  question: CommonSurveyCycleAndBankQuestion | null;
  refresh: () => Promise<void>;
  onSubmit: (values: CommonSurveyCycleAndBankQuestion) => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: allScales } = useApiClient(GrowthScaleEndpoints.getGrowthScalesByCompanyId(), {
    suspense: false,
  });
  const { data: allGrowthFactors, mutate: refreshFactors } = useApiClient(
    GrowthFactorEndpoints.getGrowthFactorsByCompanyId(),
    {
      suspense: false,
    }
  );

  const [isFactorOpen, setIsFactorOpen] = useState<boolean>(false);

  const [answerOpts, setAnswerOpts] = useState<Record<number, string>>(question ? question.choiceOptions : {});
  const [answerOptsError, setAnswerOptsError] = useState<string | null>(null);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: question ?? {
      id: '',
      questionText: '',
      hasComment: false,
      isCommentRequired: false,
      type: QuestionType.OpenEnded,
      choiceOptions: {},
      factor: null,
      scaleConfig: null,
    },
    enableReinitialize: true,
    validationSchema: getQuestionSchema(polyglot),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (multipleAnswerTypes.includes(values.type as QuestionType) && Object.keys(answerOpts).length < 2) {
          setAnswerOptsError(polyglot.t('FormsQuestionModal.errorMessages.twoOptRequired'));
          return;
        }

        const questionValues = {
          ...values,
          choiceOptions: answerOpts,
          factor: values.factor !== 'none' ? values.factor : null,
        };
        await onSubmit(questionValues);
        formik.resetForm();
        onClose();
        await refresh();
        showMessage('Successfully added', 'success');
      } catch (error) {
        showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            gap: spacing.g24,
          }}
        >
          <Box>
            <Typography variant="title2">{question ? 'Edit question' : 'New question'}</Typography>
            <QuestionFormFields
              allScales={allScales}
              allGrowthFactors={allGrowthFactors}
              setAnswerOpts={setAnswerOpts}
              answerOptsError={answerOptsError}
              answerOpts={answerOpts}
              setAnswerOptsError={setAnswerOptsError}
              setIsFactorOpen={setIsFactorOpen}
            />
          </Box>

          <Box>
            <LoaderButton
              name={question ? 'Update question' : 'Add question'}
              loading={loading}
              sizeVariant="medium"
              colorVariant="primary"
              fullWidth
            />
          </Box>

          <GrowthFactorFormModal
            growthFactor={undefined}
            setIsOpen={setIsFactorOpen}
            isOpen={isFactorOpen}
            onClose={() => {
              setIsFactorOpen(false);
            }}
            refresh={async () => {
              await refreshFactors?.();
            }}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const QuestionFormFields: React.FC<{
  allScales: GrowthScale[] | null | undefined;
  allGrowthFactors: GrowthFactor[] | null | undefined;
  setAnswerOpts: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  answerOptsError: string | null;
  answerOpts: Record<number, string>;
  setAnswerOptsError: React.Dispatch<React.SetStateAction<string | null>>;
  setIsFactorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  allScales,
  allGrowthFactors,
  setAnswerOpts,
  answerOptsError,
  answerOpts,
  setAnswerOptsError,
  setIsFactorOpen,
}) => {
  const { polyglot } = usePolyglot();
  const { values, setFieldValue, handleChange, errors, touched } = useFormikContext<SurveyQuestionContentInterface>();

  const FactorLabelValueArray = useMemo(() => {
    if (!allGrowthFactors) return [];

    const sanitizedGrowth = allGrowthFactors.map((factor) => {
      return {
        label: factor.name,
        value: factor.name,
        icon: (
          <div
            style={{
              backgroundColor: factor.color ?? themeColors.Grey,
              width: '8px',
              height: '8px',
              borderRadius: radius.br25,
            }}
          />
        ),
      };
    });

    return [{ label: 'None', value: 'none' }, ...sanitizedGrowth];
  }, [allGrowthFactors]);

  const allScalesLabelValueArray = useMemo(() => {
    if (!allScales) return [];

    return allScales.map((scale) => {
      const valueArray = Object.keys(scale.value);
      const min = Math.min(...valueArray.map(Number)) || 0;
      const max = Math.max(...valueArray.map(Number)) || 0;

      return {
        label: scale.type,
        value: scale.id,
        description: scale.points ? `${scale.points?.[min]} → ${scale.points?.[max]}` : '',
      };
    });
  }, [allScales]);

  const generateType = (type: string, hasComment: boolean) => {
    if (hasComment) return `${type}+comment`;
    else return type;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.g16,
        paddingTop: spacing.p24,
      }}
    >
      <TextfieldComponent
        multiline
        minRows={2}
        name="questionText"
        label={polyglot.t('FormsQuestionModal.questionText')}
        value={values.questionText}
        onChange={handleChange}
        endAdornment="none"
        error={touched.questionText && Boolean(errors.questionText)}
        helperText={(touched.questionText && errors.questionText) ?? ' '}
      />

      <SelectComponent
        name="factor"
        label="Factor"
        options={FactorLabelValueArray}
        value={values.factor ?? 'none'}
        compareValue={values.factor ?? 'none'}
        error={!!errors.factor && touched.factor}
        onChange={(e) => {
          setFieldValue('factor', e.target.value);
        }}
        helperText={errors.factor && touched.factor}
        editList={{
          isHidden: false,
          handler: () => {
            setIsFactorOpen(true);
          },
        }}
      />

      <SelectComponent
        name="type"
        label={polyglot.t('FormsQuestionModal.type')}
        options={AnswerTypes(polyglot)}
        value={generateType(values.type, values.hasComment)}
        compareValue={values.type}
        error={!!errors.type && touched.type}
        onChange={(e) => {
          const value = e.target.value;
          const parts = value.split('+');
          const type = parts[0];
          const comment = parts[1];
          setFieldValue('type', type);
          setFieldValue('hasComment', Boolean(comment));
          setFieldValue('isCommentRequired', false);
        }}
        helperText={errors.type && touched.type}
      />

      {values.hasComment && (
        <CheckboxComponent
          label="Make comment required"
          name="isCommentRequired"
          disabled={!values.hasComment}
          checked={values.isCommentRequired}
          onChange={(e, checked) => {
            setFieldValue('isCommentRequired', checked);
          }}
        />
      )}

      {multipleAnswerTypes.includes(values.type as QuestionType) && (
        <OptionTypeQuestion
          setOptions={setAnswerOpts}
          options={answerOpts}
          answerOptsError={answerOptsError}
          setAnswerOptsError={setAnswerOptsError}
        />
      )}

      {values.type && values.type.includes(QuestionType.ScaleQuestion) && (
        <SelectComponent
          name="scaleType"
          label="Scale type"
          options={allScalesLabelValueArray}
          value={values.scaleConfig?.id}
          compareValue={values.scaleConfig?.id}
          error={!!errors.scaleConfig && touched.scaleConfig}
          onChange={(e) => {
            const selectedId = e.target.value;
            const selectedScale = allScales?.find((scale) => scale.id === selectedId);
            if (selectedScale)
              setFieldValue('scaleConfig', {
                id: selectedScale.id,
                type: selectedScale.type,
                points: selectedScale.points,
                value: selectedScale.value,
                sentiments: selectedScale.sentiments,
              });
          }}
          helperText={errors.scaleConfig && touched.scaleConfig}
        />
      )}
    </Box>
  );
};
