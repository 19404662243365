import React, { useMemo, useState } from 'react';

import { Autocomplete, Box, Paper, Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { useProfileFields } from '@/hooks/profile-fields.hook';
import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { nestErrorMessage } from '@/lib/errors';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TypeableDateComponent } from '@/v2/components/forms/typeable-date.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UpdateUserPersonalInfoDto } from '@/v2/feature/user/features/user-forms/user-personal-info/user-personal-info.dto';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  getGenderOptions,
  PERSONAL_INFORMATION_GENDERS,
  PersonalInformationValues,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { getCountriesForNationality } from '@/v2/infrastructure/country/country.util';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { StyledMenuItem } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: '14px', height: '14px' } as const;

const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map((r) =>
    pipe(
      r.options ?? [],
      RA.map((x) => ({ ...x, group: r.label }))
    )
  ),
  RA.flatten
);

const getPersonalInformationSchemaValues = (polyglot: Polyglot) => {
  return {
    dob: Yup.string().test(dateFieldTest).nullable().notRequired(),
    gender: Yup.string().nullable(),
    nationality: Yup.string().nullable().notRequired(),
    passportNumber: Yup.string().nullable().notRequired(),
    personalEmail: Yup.string()
      .nullable()
      .email(polyglot.t('PersonalInformationCard.errorMessages.emailInvalid'))
      .required(polyglot.t('PersonalInformationCard.errorMessages.emailRquired')),
    phone: Yup.string().nullable().notRequired(),
  };
};

export const PersonalInformationSchema = (polyglot: Polyglot) =>
  Yup.object().shape(getPersonalInformationSchemaValues(polyglot));

export const PersonalInformationSchemaForImport = (polyglot: Polyglot) =>
  Yup.object().shape({
    ...getPersonalInformationSchemaValues(polyglot),
    personalEmail: Yup.string().nullable().email().notRequired(),
    gender: Yup.string()
      .oneOf([...PERSONAL_INFORMATION_GENDERS])
      .nullable()
      .notRequired(),
  });

interface Props {
  readonly initialValues: PersonalInformationValues;
  readonly userId: number;
  readonly onSubmit: (_: PersonalInformationValues) => void;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: PersonalInformationValues) => void;
  showEmployee?: boolean;
  customSchema?: Partial<typeof getPersonalInformationSchemaValues>;
}

export const PersonalInformationForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
  showEmployee = false,
  customSchema,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { getDefaultField } = useProfileFields();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ...getPersonalInformationSchemaValues(polyglot),
      ...customSchema,
    });
  }, [polyglot, customSchema]);

  const formik = useFormik<PersonalInformationValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          // TODO return patched data
          const _result = await UserAPI.patchUserPersonalInfo(userId, {
            ...values,
          } as UpdateUserPersonalInfoDto);
          onSubmit({ ...values });
          showMessage(polyglot.t('PersonalInformationForm.successMessages.update'), 'success');
        } else {
          importHandler?.({ ...values });
        }
      } catch (error) {
        showMessage(
          polyglot.t('PersonalInformationForm.errorMessages.save', { msg: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PersonalInformationForm.edit')}</Typography>
        {showEmployee && (
          <Stack sx={{ gap: spacing.g5 }}>
            <Typography variant="captionSmall">{polyglot.t('PersonalInformationForm.employee')}</Typography>
            <UserCell userId={userId} nameVariant="title4" />
          </Stack>
        )}
        <ProfileField fieldStub="dob">
          <TypeableDateComponent
            name="dob"
            label={polyglot.t('PersonalInformationForm.dob')}
            value={formik.values.dob}
            onChange={(value) => formik.setFieldValue('dob', value)}
            error={hasSubmitted && !!formik.errors.dob}
            helperText={hasSubmitted && formik.errors.dob}
            disabled={loading}
            required={getDefaultField('dob')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="gender">
          <AutocompleteComponent
            name="gender"
            label={polyglot.t('PersonalInformationForm.gender')}
            options={getGenderOptions(polyglot)}
            value={getGenderOptions(polyglot).find(
              ({ value }: { value: number | string }) => value === formik.values.gender
            )}
            compareValue={formik.values.gender ?? ''}
            // @ts-ignore
            onChange={(_, x) => formik.setFieldValue('gender', x?.value ?? null)}
            error={formik.touched.gender && Boolean(formik.errors.gender)}
            helperText={formik.touched.gender && formik.errors.gender}
            required={getDefaultField('gender')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="nationality">
          <Autocomplete
            fullWidth
            options={countryOptions}
            getOptionLabel={(option) => option.label}
            value={countryOptions.find(({ value }) => value === formik.values.nationality)}
            renderOption={(props, option) => {
              return option.value === formik.values.nationality ? (
                <StyledMenuItem {...props}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography variant="title4">{option.label}</Typography>
                    <ChoseIcon {...iconSize} />
                  </Box>
                </StyledMenuItem>
              ) : (
                <StyledMenuItem {...props}>
                  <Typography variant="caption">{option.label}</Typography>
                </StyledMenuItem>
              );
            }}
            groupBy={(option) => option.group}
            onChange={(_, x) => formik.setFieldValue('nationality', x?.value ?? null)}
            renderInput={(params) => (
              <StyledAutoTextfield
                {...params}
                variant="standard"
                size="small"
                InputLabelProps={{ shrink: true }}
                label={polyglot.t('PersonalInformationForm.nationality')}
                name="nationality"
                error={!!formik.errors.nationality && formik.touched.nationality}
                helperText={formik.errors.nationality && formik.touched.nationality}
                required={getDefaultField('nationality')?.isRequired}
              />
            )}
            popupIcon={<ArrowDownACIcon />}
            clearIcon={<ClearIcon />}
            PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
          />
        </ProfileField>
        <ProfileField fieldStub="passportNumber">
          <TextfieldComponent
            name="passportNumber"
            label={polyglot.t('PersonalInformationForm.passport')}
            value={formik.values.passportNumber}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.passportNumber && !!formik.errors.passportNumber}
            helperText={(formik.touched.passportNumber && formik.errors.passportNumber) ?? ' '}
            clearText={() => formik.setFieldValue('passportNumber', '')}
            required={getDefaultField('passportNumber')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="personalEmail">
          <TextfieldComponent
            name="personalEmail"
            label={polyglot.t('PersonalInformationForm.personalEmail')}
            value={formik.values.personalEmail}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.personalEmail && !!formik.errors.personalEmail}
            helperText={(formik.touched.personalEmail && formik.errors.personalEmail) ?? ' '}
            clearText={() => formik.setFieldValue('personalEmail', '')}
            required={getDefaultField('personalEmail')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="phone">
          <TextfieldComponent
            name="phone"
            label={polyglot.t('PersonalInformationForm.phone')}
            value={formik.values.phone}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.phone && !!formik.errors.phone}
            helperText={(formik.touched.phone && formik.errors.phone) ?? ' '}
            clearText={() => formik.setFieldValue('phone', '')}
            required={getDefaultField('phone')?.isRequired}
          />
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
