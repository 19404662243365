import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { PermissionGroupAPI } from '@v2/feature/permission-group/permission-group.api';
import { PermissionGroupDto, PermissionGroupMemberUserDto } from '@v2/feature/permission-group/permission-group.dto';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface EditPermissionGroupTargetsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly permissionGroup: PermissionGroupDto;
  readonly refresh: () => Promise<void>;
}

export const convertUserSummariesToIds = (userSummaries: PermissionGroupMemberUserDto[] | number[]) => {
  let permissionGroupMemberIds: number[];
  if (!userSummaries || userSummaries.length === 0) permissionGroupMemberIds = [];
  else if (Number.isInteger(userSummaries[0])) permissionGroupMemberIds = userSummaries as number[];
  else permissionGroupMemberIds = (userSummaries as PermissionGroupMemberUserDto[]).map((m) => m.userId);

  return permissionGroupMemberIds;
};

export const EditPermissionGroupTargetsDrawer = ({
  isOpen,
  setIsOpen,
  permissionGroup,
  refresh,
}: EditPermissionGroupTargetsDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <EditPermissionGroupTargetsDrawerContent
        permissionGroup={permissionGroup}
        refresh={refresh}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface EditPermissionGroupTargetsDrawerContentProps {
  readonly permissionGroup: PermissionGroupDto;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const EditPermissionGroupTargetsDrawerContent = ({
  permissionGroup,
  refresh,
  setIsOpen,
}: EditPermissionGroupTargetsDrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState<UserSelectFiltersOptions>(
    permissionGroup.targetsExclRule ?? UserSelectFiltersOptions.None
  );
  const [customRule, setCustomRule] = useState<string>(permissionGroup.customExclRule ?? '');
  const [selectedUsers, setSelectedUsers] = useState<number[] | PermissionGroupMemberUserDto[]>(
    permissionGroup.exclusions ?? []
  );
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (
      membersRule: UserSelectFiltersOptions,
      customRule: string | null,
      selectedMembersIds: number[] | PermissionGroupMemberUserDto[]
    ) => {
      try {
        setLoading(true);

        await PermissionGroupAPI.updatePermissionGroupDetails(permissionGroup.id, {
          name: permissionGroup.name,
          description: permissionGroup.description,
          targetsExclRule: membersRule,
          customExclRule: customRule,
          members: convertUserSummariesToIds(permissionGroup.members),
          exclusions: selectedMembersIds,
          inclusions: [],
        });

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          `${polyglot.t('EditPolicyMembersDrawerContent.errorMessages.updateFailure')}. ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [
      permissionGroup.id,
      refresh,
      setIsOpen,
      showMessage,
      polyglot,
      permissionGroup.name,
      permissionGroup.members,
      permissionGroup.description,
    ]
  );

  return (
    <Box>
      <Typography sx={titleSx}>Edit exclusions</Typography>
      <Box sx={fieldSx}>
        <UserSelect
          label={polyglot.t('EditPolicyMembersDrawerContent.whoSelect')}
          selectedLabel="Selected employees"
          value={convertUserSummariesToIds(selectedUsers)}
          onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
            setSelectedUsers([...userIds]);
            setValidationMessage('');
            setSelectedOption(filterValue ?? UserSelectFiltersOptions.None);
            setCustomRule(customRule ?? '');
          }}
          fieldSx={{ ...spacing.mb20 }}
          error={!!validationMessage}
          helperText={!!validationMessage && validationMessage}
          initialFilterValue={permissionGroup.targetsExclRule ?? UserSelectFiltersOptions.None}
          ruleString={customRule}
          excludeEveryone={true}
          excludeCustomRule={true}
          allowEmpty={true}
        />
      </Box>
      <Box sx={{ mt: spacing.m20 }}>
        <LoaderButton
          name={polyglot.t('General.save')}
          type="button"
          onClick={async () => {
            if (selectedOption === UserSelectFiltersOptions.None)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOption'));
            // else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
            //   setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOneUser'));
            else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.enterCustomRule'));
            else {
              const membersRule = selectedOption ?? UserSelectFiltersOptions.Everyone;
              const selectedCustomRule = selectedOption === UserSelectFiltersOptions.CustomRule ? customRule : null;
              await onSubmit(membersRule, selectedCustomRule, selectedUsers);
            }
          }}
          loading={loading}
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
        />
      </Box>
    </Box>
  );
};
