import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { PAYMENTS_ROUTE } from '@/v2/feature/payments/payments.router';
import { navigateToPayrunsView } from '@/v2/feature/payroll/payroll-router.util';
import { PayRunDto } from '@/v2/feature/payroll/payroll.dto';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunCompleteProps = {
  payrun: Pick<PayRunDto, 'payrollId' | 'id'>;
  sx?: SxProps<Theme>;
};

export const PayrunCompleteSection = ({ payrun, sx }: PayrunCompleteProps) => {
  const routerHistory = useHistory();
  return (
    <Stack sx={{ gap: spacing.g10, mr: spacing.mr40, mb: spacing.m30, ...sx }}>
      <Typography sx={themeFonts.title2}>Payrun completed</Typography>
      <Typography sx={themeFonts.caption}>Do you want to make payroll payments now?</Typography>
      <Stack flexDirection="row" sx={{ gap: spacing.g20, mt: spacing.mt20 }}>
        <ButtonComponent
          onClick={() => {
            // navigate back to payruns list
            navigateToPayrunsView(routerHistory, 'replace', payrun.payrollId, payrun.id);
          }}
          sizeVariant="medium"
          colorVariant="secondary"
        >
          Later
        </ButtonComponent>
        <ButtonComponent
          onClick={() => {
            // navigate to payments
            routerHistory.replace(PAYMENTS_ROUTE);
          }}
          sizeVariant="medium"
          colorVariant="primary"
        >
          Pay now
        </ButtonComponent>
      </Stack>
    </Stack>
  );
};
