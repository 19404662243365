import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  MaxInactivity,
  MinimumComplexChars,
  MinimumLength,
  ZeltPolicyPayloadIdentifier,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  PasswordOption,
} from '@/v2/feature/device/device.dto';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmPasscodeSecurityPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [maxPINAgeInDaysHelper, setMaxPINAgeInDaysHelper] = useState<string>('');
  const [pinHistoryHelper, setPinHistoryHelper] = useState<string>('');
  const [allowSimpleHelper, setAllowSimpleHelper] = useState<boolean>(false);

  const setPasswordHelperValue = (passwordOption: PasswordOption | undefined) => {
    if (!passwordOption) {
      return;
    }
    if (passwordOption.pinHistory) {
      setPinHistoryHelper(passwordOption.pinHistory.toString());
    }
    if (passwordOption.maxPINAgeInDays) {
      setMaxPINAgeInDaysHelper(passwordOption.maxPINAgeInDays.toString());
    }
    if (passwordOption.allowSimple !== undefined) {
      setAllowSimpleHelper(!passwordOption.allowSimple);
    }
  };
  const getPolicy = (): PasswordOption => {
    return {
      minLength: configurablePoliciesSelected?.configurablePasscode?.minLength ?? 11,
      maxInactivity: configurablePoliciesSelected?.configurablePasscode?.maxInactivity ?? 0,
      minComplexChars: configurablePoliciesSelected?.configurablePasscode?.minComplexChars ?? 0,
      maxPINAgeInDays: configurablePoliciesSelected?.configurablePasscode?.maxPINAgeInDays ?? 0,
      pinHistory: configurablePoliciesSelected?.configurablePasscode?.pinHistory ?? 0,
      requireAlphanumeric: configurablePoliciesSelected?.configurablePasscode?.requireAlphanumeric ?? false,
      allowSimple: configurablePoliciesSelected?.configurablePasscode?.allowSimple ?? true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.PASSWORD_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: PasswordOption = getPolicy();
  function analyzePolicyBeforeSave(policy: PasswordOption) {
    if (
      policy.minLength === 0 &&
      policy.maxInactivity === 0 &&
      policy.minComplexChars === 0 &&
      policy.maxPINAgeInDays === 0 &&
      policy.pinHistory === 0 &&
      policy.pinHistory === 0 &&
      !policy.requireAlphanumeric &&
      policy.allowSimple
    ) {
      return undefined;
    }
    return {
      minLength: policy.minLength,
      ...(policy.maxInactivity !== 0 && { maxInactivity: policy.maxInactivity }),
      ...(policy.minComplexChars !== 0 && { minComplexChars: policy.minComplexChars }),
      ...(policy.pinHistory !== 0 && { pinHistory: policy.pinHistory }),
      ...(policy.maxPINAgeInDays !== 0 && { maxPINAgeInDays: policy.maxPINAgeInDays }),
      allowSimple: policy.allowSimple,
      requireAlphanumeric: policy.requireAlphanumeric,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.PASSWORD_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }
  const formik = useFormik<PasswordOption>({
    initialValues: initialValues,
    onSubmit: async (passwordForm: PasswordOption) => {
      const configurablePolicy = analyzePolicyBeforeSave(passwordForm);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurablePasscode: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    const policy = getPolicy();
    formik.validateForm();
    setPasswordHelperValue(policy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="title2" sx={spacing.mb20}>
        Passcode
      </Typography>
      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          <Box sx={fieldSx}>
            <SelectComponent
              name="minLength"
              label="Minimum length"
              options={MinimumLength}
              value={formik.values.minLength}
              onChange={(e) => {
                const minLength = e?.target.value;
                formik.setFieldValue('minLength', minLength);
              }}
            />
          </Box>
          <Box sx={fieldSx}>
            <SelectComponent
              name="minComplexChar"
              label="Minimum complex characters"
              options={MinimumComplexChars}
              value={formik.values.minComplexChars}
              onChange={(e) => {
                const minComplexChars = e?.target.value;
                formik.setFieldValue('minComplexChars', minComplexChars);
              }}
            />
          </Box>
          <CheckboxComponent
            label="Require alphanumeric passcode"
            name="requireAlphanumeric"
            description="Require both numbers and letters. If disabled, numbers only is accepted "
            checked={formik.values.requireAlphanumeric}
            onChange={(e, checked) => {
              formik.setFieldValue('requireAlphanumeric', checked);
            }}
          />
          <CheckboxComponent
            label="Enforce strong passcode"
            name="allowSimple"
            description="Passcodes containing guessable sequences like 123 or ABCD will not be allowed. At least 1 complex char"
            checked={allowSimpleHelper}
            onChange={(e, checked) => {
              setAllowSimpleHelper(checked);
              formik.setFieldValue('allowSimple', !checked);
            }}
          />
          <Box sx={fieldSx}>
            <TextfieldComponent
              label="Maximumim passcode age (days)."
              name="number"
              type="number"
              value={maxPINAgeInDaysHelper}
              minValue={0}
              maxValue={720}
              validateNumber={true}
              tooltip={
                'The number of days for which the passcode can remain unchanged. After this number of days, the user is forced to change the passcode before the device is unlocked. Range 1-720'
              }
              onChange={(e) => {
                let maxPINAgeInDays = e?.target.value;
                if (maxPINAgeInDays === '0') {
                  setMaxPINAgeInDaysHelper('');
                } else {
                  setMaxPINAgeInDaysHelper(maxPINAgeInDays);
                }
                formik.setFieldValue('maxPINAgeInDays', Number(maxPINAgeInDays));
              }}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              label="Maximum history kept"
              name="minPinHistory"
              type="number"
              value={pinHistoryHelper}
              minValue={0}
              maxValue={50}
              validateNumber={true}
              tooltip={
                'This value defines N, where the new passcode must be unique within the last N entries in the passcode history. Range 1-50'
              }
              onChange={(e) => {
                let pinHistory = e?.target.value;
                if (pinHistory === '0') {
                  setPinHistoryHelper('');
                } else {
                  setPinHistoryHelper(pinHistory);
                }
                formik.setFieldValue('pinHistory', Number(pinHistory));
              }}
            />
          </Box>

          <Box sx={fieldSx}>
            <SelectComponent
              name="maxInactivity"
              label="Autolock after"
              options={MaxInactivity}
              value={formik.values.maxInactivity}
              onChange={(e) => {
                const maxInactivity = e?.target.value;
                formik.setFieldValue('maxInactivity', maxInactivity);
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
