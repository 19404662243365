import { useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceSettingsDto } from '@v2/feature/attendance/attendance.dto';
import { EditAttendanceSettingsDrawer } from '@v2/feature/attendance/company/components/edit-attendance-settings-drawer.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { RootStyle } from '@v2/styles/root.styles';
import {
  detailSx,
  detailTermSx,
  detailValueSx,
  parentContainerSx,
  responsiveParentCardSx,
  smallParentCardSx,
} from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import Polyglot from 'node-polyglot';
import { useLocation } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ATTENDANCE_COMPANY_ROUTE } from '@/lib/routes';

const getFieldToDay = (field: string, polyglot: Polyglot): string => {
  switch (field) {
    case 'workingMonday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Monday');
    case 'workingTuesday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Tuesday');
    case 'workingWednesday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Wednesday');
    case 'workingThursday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Thursday');
    case 'workingFriday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Friday');
    case 'workingSaturday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Saturday');
    case 'workingSunday':
      return polyglot.t('AttendanceCompanySettingsOtherPage.Sunday');
    default:
      return field;
  }
};

export const AttendanceCompanySettingsOtherPage = () => {
  const { polyglot } = usePolyglot();

  const { data: attendanceSettings, isLoading, mutate: refreshAttendanceSettings } = useApiClient<
    AttendanceSettingsDto,
    Error
  >(AttendanceEndpoints.getCompanyAttendanceSettings(), {
    suspense: false,
  });

  const routerLocation = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const refresh = useCallback(async () => {
    if (refreshAttendanceSettings) await refreshAttendanceSettings();
  }, [refreshAttendanceSettings]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <>
            <Typography sx={themeFonts.title2}>{polyglot.t('SettingsRouterPageConfig.other')}</Typography>
            {!edit && (
              <IconButton sx={tableIconButtonSx} onClick={() => setEdit((prev) => !prev)}>
                <EditIcon {...actionIconSize} />
              </IconButton>
            )}
          </>
        }
        showBack={routerLocation.pathname.includes(ATTENDANCE_COMPANY_ROUTE)}
        backPath={ATTENDANCE_COMPANY_ROUTE}
      />
      <ContentWrapper loading={loading || isLoading}>
        <Box sx={parentContainerSx}>
          <Box sx={responsiveParentCardSx}>
            {attendanceSettings && (
              <Box sx={{ mt: spacing.m10 }}>
                <Box sx={{ ...detailSx, mb: spacing.m10 }} key="earlyCap">
                  <Typography
                    sx={{
                      ...detailTermSx,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {polyglot.t('AttendanceCompanySettingsOtherPage.WeeklyHours')}
                  </Typography>
                  <Typography sx={detailValueSx}>
                    {convertMinutesToClockHours(attendanceSettings.weeklyMinutes, polyglot)}
                  </Typography>
                </Box>

                <Box sx={{ ...detailSx, mb: spacing.m10 }} key="earlyCap">
                  <Typography
                    sx={{
                      ...detailTermSx,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {polyglot.t('AttendanceCompanySettingsOtherPage.WorkingDays')}
                  </Typography>
                  <Typography sx={detailValueSx}>
                    {([
                      'workingMonday',
                      'workingTuesday',
                      'workingWednesday',
                      'workingThursday',
                      'workingFriday',
                      'workingSaturday',
                      'workingSunday',
                    ] as (keyof AttendanceSettingsDto)[])
                      .filter((field) => attendanceSettings[field])
                      .map((field: string) => getFieldToDay(field, polyglot))
                      .join(', ')}
                  </Typography>
                </Box>
                <EditAttendanceSettingsDrawer
                  isOpen={edit}
                  setIsOpen={setEdit}
                  attendanceSettings={attendanceSettings}
                  refresh={refresh}
                />
              </Box>
            )}
          </Box>
          <Box sx={smallParentCardSx}></Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
