import React, { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DIRECTORY_ROUTE, DEVICES_ME_DIRECTORY_ROUTE, SETTINGS_BILLING_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DeviceOrderDto } from '@/v2/feature/device/device.dto';
import { DeviceOrderStatus } from '@/v2/feature/device/device.interface';
import {
  FieldValueComponent,
  getDeviceOwnerByDevicePossessionTable,
  getModelImage,
  getOrderStatus,
  getTechSpecs,
} from '@/v2/feature/device/device.util';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DeviceOrderDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  deviceOrder,
  refresh,
  sitesById,
  reach,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  deviceOrder: DeviceOrderDto | null;
  refresh: () => Promise<void>;
  sitesById: Record<number, SiteDto>;
  reach: string;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [globalState, dispatch] = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeliveredLoading, setIsDeliveredLoading] = useState<boolean>(false);
  const { userId } = globalState.user;

  const hasDevicesAllScope = checkScopes(globalState.user, ['devices:all'], { userId });
  const isRequested = DeviceOrderStatus.Requested === deviceOrder?.status;
  const routerHistory = useHistory();
  const yesterday = new LocalDate();
  yesterday.getDate().setDate(yesterday.getDate().getDate() - 1);
  yesterday.getDate().setHours(23);
  const deleteOrder = async (orderId: number): Promise<void> => {
    let orderDeleted = false;
    try {
      setLoading(true);
      await DeviceAPI.deleteDeviceOrder(orderId);
      orderDeleted = true;
      showMessage('Order deleted', 'success');
      routerHistory.push(reach === 'company' ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE);
    } catch (error) {
      if (!orderDeleted) showMessage(`Could not delete order. ${nestErrorMessage(error)}`, 'error');
      else showMessage(`Could not refresh orders list. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const approveOrder = async (orderId: number): Promise<void> => {
    let isOrderApprove = false;
    try {
      setLoading(true);
      await DeviceAPI.approveDeviceOrder(orderId);
      showMessage('Order approved.', 'success');
      isOrderApprove = true;
      const alertsForDevices = await DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await refresh();
    } catch (error) {
      if (isOrderApprove) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not approve order. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const rejectOrder = async (orderId: number): Promise<void> => {
    let wasOrderRejected = false;
    try {
      setLoading(true);
      await DeviceAPI.rejectDeviceOrder(orderId);
      showMessage('Order rejected', 'success');
      wasOrderRejected = true;
      const alertsForDevices = await DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await refresh();
    } catch (error) {
      if (wasOrderRejected) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not reject order. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelOrder = async (orderId: number): Promise<void> => {
    let orderCancelled = false;
    try {
      await DeviceAPI.cancelDeviceOrder(orderId);
      orderCancelled = true;
      showMessage('Order cancelled', 'success');
      await refresh();
    } catch (error) {
      if (!orderCancelled) showMessage(`Could not cancel order. ${nestErrorMessage(error)}`, 'error');
      else showMessage(`Could not refresh orders list. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const setOrderAsDelivered = async () => {
    const deliveryDate =
      deviceOrder?.deliveryDate && yesterday.toDateString() < deviceOrder.deliveryDate
        ? deviceOrder.deliveryDate
        : new LocalDate().toDateString();
    try {
      setIsDeliveredLoading(true);

      if (!deliveryDate) {
        showMessage('Please confirm the delivery date', 'error');
        return;
      }
      if (!deviceOrder?.deviceId) {
        showMessage('Could not set the device as delivered as no device is assigned to this order.', 'error');
        return;
      }
      await DeviceAPI.confirmDeviceOrderDelivery(deviceOrder.id, deliveryDate);
      await refresh();
      showMessage('Order status updated.', 'success');
    } catch (error) {
      showMessage(`Could not update device order status. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDeliveredLoading(false);
    }
  };

  const allTechSpecs = deviceOrder?.deviceModel ? getTechSpecs(deviceOrder.deviceModel) : [];

  const getDomain = (url: string) => {
    if (url.includes('http')) return url;
    return `https://${url}`;
  };

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{deviceOrder?.deviceModel?.modelName ?? 'Order'}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {getModelImage(deviceOrder?.deviceModel?.type, deviceOrder?.deviceModel?.modelName, {
            width: '180px',
            height: 'auto',
          })}
        </Box>

        <Box component="section" sx={drawerContentSx}>
          <Box component="dl" sx={{ display: 'flex', flexDirection: 'column', margin: 0, padding: 0, gap: spacing.s2 }}>
            {deviceOrder?.status && (
              <FieldValueComponent
                title="Order status"
                value={<Typography variant="caption">{getOrderStatus(deviceOrder.status)}</Typography>}
              />
            )}

            {deviceOrder?.possession && (
              <FieldValueComponent
                title="Employee"
                value={getDeviceOwnerByDevicePossessionTable(
                  deviceOrder!.possession,
                  sitesById[deviceOrder.possessionId],
                  polyglot,
                  getCachedUserById(deviceOrder?.possession?.possessionId!)?.displayName
                )}
              />
            )}

            {deviceOrder?.price && deviceOrder.price > 0 && (
              <FieldValueComponent
                title="Price"
                value={<Typography variant="title4">£{(deviceOrder?.price).toFixed(2)}</Typography>}
              />
            )}

            {deviceOrder?.contractLength && (
              <FieldValueComponent
                title="Contract"
                value={<Typography variant="title4">{deviceOrder?.contractLength} months</Typography>}
              />
            )}

            {deviceOrder?.deliveryAddress && (
              <FieldValueComponent title="Address" value={deviceOrder?.deliveryAddress} />
            )}

            {deviceOrder?.deliveryDate && (
              <FieldValueComponent
                title="Delivery date"
                value={<Typography variant="title4">{getDateString(deviceOrder?.deliveryDate)}</Typography>}
              />
            )}

            {deviceOrder?.deviceModel && allTechSpecs.length > 0 && (
              <FieldValueComponent
                title="Tech specs"
                value={
                  <Box>
                    {allTechSpecs.map((t, index) => (
                      <Typography key={index} variant="title4">
                        {t}
                      </Typography>
                    ))}
                  </Box>
                }
              />
            )}
          </Box>

          {deviceOrder && (
            <Box sx={buttonBoxDrawerSx}>
              {[DeviceOrderStatus.Cancelled, DeviceOrderStatus.Rejected].includes(deviceOrder?.status) && (
                <LoaderButton
                  key="delete-order"
                  name="Delete order"
                  loading={loading}
                  fullWidth
                  colorVariant="secondary"
                  sizeVariant="medium"
                  onClick={async () => await deleteOrder(deviceOrder?.id)}
                />
              )}

              {[DeviceOrderStatus.Placed].includes(deviceOrder?.status) && (
                <LoaderButton
                  key="cancel-placed-order"
                  name="Cancel order"
                  loading={loading}
                  fullWidth
                  colorVariant="secondary"
                  sizeVariant="medium"
                  onClick={async () => await cancelOrder(deviceOrder?.id)}
                />
              )}

              {hasDevicesAllScope && isRequested && (
                <LoaderButton
                  key="reject-order"
                  name="Reject order"
                  loading={loading}
                  fullWidth
                  colorVariant="secondary"
                  sizeVariant="medium"
                  onClick={async () => {
                    await rejectOrder(deviceOrder?.id);
                  }}
                />
              )}

              {!hasDevicesAllScope && isRequested && (
                <LoaderButton
                  key="cancel-requested-order"
                  name="Cancel order"
                  loading={loading}
                  fullWidth
                  colorVariant="secondary"
                  sizeVariant="medium"
                  onClick={async () => await cancelOrder(deviceOrder?.id)}
                />
              )}

              {hasDevicesAllScope && isRequested && (
                <>
                  {!globalState.user.hasPaymentMethodOnFile ? (
                    <StyledTooltip
                      title={
                        <>
                          <Typography variant="caption" sx={{ ...themeFonts.tooltip }}>
                            To proceed with this order <br /> Please add billing information. <br />
                          </Typography>
                          <ButtonComponent
                            sizeVariant="small"
                            colorVariant="tooltip"
                            onClick={() => routerHistory.push(generatePath(SETTINGS_BILLING_ROUTE))}
                          >
                            Go to settings
                          </ButtonComponent>
                        </>
                      }
                      children={
                        <span style={{ width: '100%' }}>
                          <LoaderButton
                            key="approve-no-payment-method"
                            name="Approve"
                            loading={loading}
                            fullWidth={true}
                            onClick={async () => await approveOrder(deviceOrder?.id)}
                            disabled={true}
                            colorVariant="primary"
                            sizeVariant="medium"
                          />
                        </span>
                      }
                    />
                  ) : (
                    <LoaderButton
                      key="approve"
                      name="Approve"
                      loading={loading}
                      fullWidth={true}
                      onClick={async () => await approveOrder(deviceOrder?.id)}
                      colorVariant="primary"
                      sizeVariant="medium"
                    />
                  )}
                </>
              )}

              {[DeviceOrderStatus.Shipping].includes(deviceOrder?.status) && Boolean(deviceOrder?.trackingLink) && (
                <ButtonComponent
                  key="track-order"
                  fullWidth
                  sizeVariant="medium"
                  colorVariant="secondary"
                  onClick={() => window.open(getDomain(deviceOrder.trackingLink as string), '_blank')}
                >
                  Track order
                </ButtonComponent>
              )}

              {[DeviceOrderStatus.Shipping].includes(deviceOrder?.status) && (
                <LoaderButton
                  key="confirm-delivery"
                  name="Confirm delivery"
                  loading={isDeliveredLoading}
                  fullWidth
                  colorVariant="secondary"
                  sizeVariant="medium"
                  onClick={async () => await setOrderAsDelivered()}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </DrawerModal>
  );
};
