import { OneOffPaymentDto } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class PayItemAPI {
  static async updateUpcomingOneOffPayItem(
    oneOffPaymentId: string,
    userId: number,
    update: Pick<OneOffPaymentDto, 'amount' | 'multiplier'>
  ): Promise<void> {
    await axios.patch(`/apiv2/payroll/pay-items/users/${userId}/upcoming/one-off/${oneOffPaymentId}`, update);
  }

  static async createUpcomingPayItem(
    userId: number,
    data: Pick<OneOffPaymentDto, 'amount' | 'multiplier'>
  ): Promise<void> {
    await axios.post(`/apiv2/payroll/pay-items/users/${userId}/upcoming/one-off`, data);
  }

  static async deleteUpcomingPayItem(userId: number, oneOffPaymentId: string): Promise<void> {
    await axios.delete(`/apiv2/payroll/pay-items/users/${userId}/upcoming/one-off/${oneOffPaymentId}`);
  }
}

export class PayItemEndpoints {
  static getUserOneOffPayments(
    userId: number
  ): Endpoint<{
    upcoming: OneOffPaymentDto[];
    history: OneOffPaymentDto[];
  }> {
    return {
      url: `/apiv2/payroll/pay-items/users/${userId}/one-off-payments`,
    };
  }

  static getUserOneOffPaymentsByAttendance(userId: number, attendanceId: number): Endpoint<OneOffPaymentDto[]> {
    return {
      url: `/apiv2/payroll/pay-items/users/${userId}/attendances/${attendanceId}`,
    };
  }

  static getUserOneOffPaymentsByAbsence(userId: number, absenceId: number): Endpoint<OneOffPaymentDto[]> {
    return {
      url: `/apiv2/payroll/pay-items/users/${userId}/absences/${absenceId}`,
    };
  }
}
