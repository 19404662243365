import { BillingCurrency } from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';

export type BillingTimeline = {
  message: string;
  type: BillingTimelineTypes;
  createdAt: string;
  status: BillingTimelineStatus;
  metadata?: CompanyTimelineMetadata;
};

export interface CompanyTimelineMetadata {
  status: 'paid' | 'error' | 'payment-method-added' | 'subscription-cancelled' | 'payment-created';
  amount?: number;
  invoiceId?: string;
  planName?: string;
  currency?: BillingCurrency;
}

export enum BillingTimelineTypes {
  PaymentMethod = 'payment_method',
  Payment = 'payment',
  Subscription = 'subscription',
}

export enum BillingTimelineStatus {
  Success = 'Success',
  Error = 'Error',
}

export type FailedPayment = {
  id: number;
  companyId: number;
  invoiceId: string;
};
