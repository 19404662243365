import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { SmallCircle } from '@v2/feature/absence/subfeatures/settings/components/draggable-absence-policies.component';
import { borders } from '@v2/styles/borders.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface BenefitOverviewItemProps {
  readonly title: string;
  readonly descriptionItems: readonly string[];
  readonly icon: ReactNode;
  readonly onClick?: () => void;
}

export const BenefitOverviewItem = ({ title, descriptionItems, icon, onClick }: BenefitOverviewItemProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: spacing.g5,
        bgcolor: themeColors.Background,
        padding: spacing.g15,
        borderRadius: '15px',
        borderBottom: borders.light,
        '&:hover': {
          transform: 'scale(1.025)',
        },
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={onClick}
    >
      <Box sx={{ mr: spacing.m10 }}>{icon}</Box>
      <Box>
        <Typography sx={themeFonts.title4}>{title}</Typography>

        <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center', mt: spacing.m5 }}>
          {descriptionItems.map((item, index) => (
            <>
              <Typography sx={themeFonts.caption}>{item}</Typography>
              {index < descriptionItems.length - 1 && <SmallCircle />}
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
