import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { CreateOneOffPayItem } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { PayItemAPI } from '@v2/feature/payroll/features/pay-item/pay-item.api';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { LocalDate } from '@v2/util/local-date';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import Polyglot from 'node-polyglot';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userId: number;
  readonly refresh: () => Promise<void>;
}

export function getNewPayItemValidationSchema(polyglot: Polyglot) {
  return yup.object({
    date: yup.string().test(dateFieldTest).required(polyglot.t('ValidationMessages.requiredField')),
    amount: yup
      .number()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    multiplier: yup
      .number()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    payCode: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    description: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
  });
}

export const AddNewPayItemDrawer = ({ isOpen, setIsOpen, userId, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AddNewPayItemDrawerContent userId={userId} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userId: number;
}

export const AddNewPayItemDrawerContent = ({ userId, refresh, setIsOpen }: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: CreateOneOffPayItem) => {
      const data: CreateOneOffPayItem = {
        amount: Number(values.amount),
        multiplier: Number(values.multiplier),
        date: values.date,
        description: values.description,
        payCode: values.payCode,
      };
      try {
        setLoading(true);
        await PayItemAPI.createUpcomingPayItem(userId, data);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [refresh, setIsOpen, showMessage, userId, polyglot]
  );

  const formik = useFormik<CreateOneOffPayItem>({
    initialValues: {
      date: new LocalDate().toDateString(),
      amount: 0,
      multiplier: 1,
      description: '',
      payCode: '',
    },
    validationSchema: getNewPayItemValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PayItemModule.addOneOffPayItem')}</Typography>

        <DatePickerComponent
          name="date"
          label={polyglot.t('General.date')}
          onChange={(value) => {
            formik.setFieldValue('date', value);
          }}
          value={formik.values.date}
          error={formik.touched.date && !!formik.errors.date}
          helperText={formik.touched.date && formik.errors.date}
        />

        <TextfieldComponent
          name="payCode"
          label={polyglot.t('PayItemModule.payCode')}
          value={formik.values.payCode}
          onChange={formik.handleChange}
          error={formik.touched.payCode && !!formik.errors.payCode}
          helperText={(formik.touched.payCode && formik.errors.payCode) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="amount"
          label={polyglot.t('PayItemModule.amount')}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && !!formik.errors.amount}
          helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="description"
          label={polyglot.t('General.description')}
          multiline
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && !!formik.errors.description}
          helperText={(formik.touched.description && formik.errors.description) ?? ' '}
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.add')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
