import { useCallback } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CreateCustomBenefitDto,
  CustomBenefitCategory,
  CustomBenefitType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateCustomBenefitCategory } from '@v2/infrastructure/i18n/translate.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';

export const InsuranceSetupExploreStep = ({ name, onNext }: { readonly name: string; readonly onNext: () => void }) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const createExistingInsurance = useCallback(async () => {
    try {
      const data: CreateCustomBenefitDto = {
        type: CustomBenefitType.Recurring,
        category: CustomBenefitCategory.Health,
        name,
        description: null,
        includedUserIds: [],
        benefitLink: null,
      };
      const id = await CustomBenefitAPI.createCustomBenefit(data);
      history.push(
        generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          id,
          category: CustomBenefitCategory.Health,
          productType: 'custom',
        })
      );
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, name, showMessage, history]);

  return (
    <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
      <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
        {translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot)}
      </Typography>

      <Typography variant="caption" sx={{ mt: spacing.mt20, mb: spacing.mb30, color: themeColors.DarkGrey }}>
        {polyglot.t('BenefitModule.businessHealthInsuranceDesc')}
      </Typography>

      <Box sx={{ ...buttonBoxSx, mt: spacing.mt40, display: 'flex', flexDirection: 'column' }}>
        <ButtonComponent sizeVariant="medium" colorVariant="primary" onClick={onNext} fullWidth>
          {polyglot.t('BenefitModule.newInsuranceWithZelt')}
        </ButtonComponent>

        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={async () => await createExistingInsurance()}
          fullWidth
        >
          {polyglot.t('BenefitModule.addExisting')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
