import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Skeleton } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { format } from 'date-fns';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as WaitingEmpty } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AttendanceAPI, AttendanceEndpoints } from '@/v2/feature/attendance/attendance.api';
import { AttendanceDto, AttendanceScheduleDto, AttendanceTypeDto } from '@/v2/feature/attendance/attendance.dto';
import { AttendanceStatus, ScheduleTrackingType } from '@/v2/feature/attendance/attendance.interface';
import { getAttendanceStatusIcon } from '@/v2/feature/attendance/attendance.util';
import { EditTrackTimeDrawerContent } from '@/v2/feature/attendance/company/components/track-time-drawer.component';
import { LabelValueViewItem } from '@/v2/feature/dashboard/features/sections/user-attendance/components/label-value-view-item.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const RegularAttendanceContent = ({
  userId,
  selectedDate,
  refreshWidgetData,
  setIsViewOpen,
}: {
  userId: number;
  selectedDate: string | null;
  refreshWidgetData?: () => Promise<void>;
  setIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { polyglot } = usePolyglot();

  const { data: attendance, isLoading: loadingAttendance } = useApiClient<AttendanceDto, Error>(
    AttendanceEndpoints.getAttendanceByUserIdAndLogDate(userId, new LocalDate(selectedDate).toDateString()),
    { suspense: false }
  );

  // TODO: @attendance - => ?? Or should the attendanceSchedule come from above?
  //  also attendance is already requested once above in the tree. should we not send any request actually?
  //  we use logDate here, should we get the schedule by logDate??
  const { data: attendanceSchedule, isLoading: loadingSchedule } = useApiClient<AttendanceScheduleDto, Error>(
    attendance?.id && userId
      ? AttendanceEndpoints.getUserAttendanceScheduleByAttendanceId(userId, attendance?.id)
      : userId
      ? AttendanceEndpoints.getUserAttendanceSchedule(userId)
      : null,
    { suspense: false }
  );

  const [userAttendance, setUserAttendance] = useState<AttendanceDto | undefined>(undefined);
  const [isTrackTimeOpen, setIsTrackTimeOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const getUserAttendanceData = useCallback(async () => {
    setLoading(true);
    try {
      if (attendanceSchedule?.trackingType !== ScheduleTrackingType.ClockInClockOut) {
        const userAttendanceByDate = await AttendanceAPI.getAttendanceByUserIdAndLogDate(
          userId,
          new LocalDate(selectedDate).toDateString()
        );
        setUserAttendance(userAttendanceByDate);
      }
    } catch (error) {
      showMessage(
        polyglot.t('RegularAttendanceContent.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [polyglot, attendanceSchedule?.trackingType, selectedDate, userId, showMessage]);

  useEffect(() => {
    getUserAttendanceData();
  }, [getUserAttendanceData]);

  return (
    <Box sx={drawerContentSx}>
      {loading || loadingAttendance || loadingSchedule ? (
        <RegularDrawer />
      ) : !isTrackTimeOpen ? (
        <Box sx={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('getUserAttendanceData.attendance')}</Typography>
          {/* This difference is created to avoid unnecessary api call */}
          {userAttendance && attendanceSchedule ? (
            <ViewPendingAttendance
              userAttendance={userAttendance}
              selectedDate={selectedDate}
              setIsTrackTimeOpen={setIsTrackTimeOpen}
              attendanceSchedule={attendanceSchedule}
            />
          ) : (
            <ViewNewAttendance selectedDate={selectedDate} setIsTrackTimeOpen={setIsTrackTimeOpen} />
          )}
        </Box>
      ) : (
        <EditTrackTimeDrawerContent
          refresh={async () => {
            await refreshWidgetData?.();
            setIsViewOpen(false);
          }}
          userId={userId}
          setIsOpen={setIsViewOpen}
          request={userAttendance}
          view="user"
          logDate={userAttendance?.logDate ?? new LocalDate(selectedDate).toDateString()}
          userSchedule={attendanceSchedule}
        />
      )}
    </Box>
  );
};

const ViewPendingAttendance = ({
  userAttendance,
  selectedDate,
  setIsTrackTimeOpen,
  attendanceSchedule,
}: {
  userAttendance: AttendanceDto;
  selectedDate: string | null;
  setIsTrackTimeOpen: (value: React.SetStateAction<boolean>) => void;
  attendanceSchedule: AttendanceScheduleDto;
}) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const hasAttendanceScopeForUser = hasScopes(['attendance'], { userId: userAttendance.userId });
  const hasManagerAttendanceScopeForUser = hasScopes(['attendance:manager'], { userId: userAttendance.userId });

  const attendanceTypesAllowedById = useMemo(
    () =>
      attendanceSchedule?.attendanceTypesAllowed?.reduce((response, type) => {
        response[type.id] = type;
        return response;
      }, {} as { [typeId: number]: AttendanceTypeDto }) ?? ({} as { [typeId: number]: AttendanceTypeDto }),
    [attendanceSchedule]
  );

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, marginTop: spacing.m30 }}>
        <LabelValueViewItem
          label={polyglot.t('ViewPendingAttendance.status')}
          value={
            userAttendance ? (
              getAttendanceStatusIcon(userAttendance?.status, false, polyglot)
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                <WaitingEmpty {...iconSize} />
                <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                  {polyglot.t('ViewPendingAttendance.notSubmitted')}
                </Typography>
              </Box>
            )
          }
        />
        <LabelValueViewItem
          label={polyglot.t('ViewPendingAttendance.status')}
          value={`${format(new LocalDate(selectedDate ?? new Date()).getDate(), 'd MMM yyyy')}`}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        <Typography variant="title4" sx={{ marginTop: spacing.m30 }}>
          {polyglot.t('ViewPendingAttendance.hoursLogged')}
        </Typography>

        {userAttendance &&
          userAttendance.attendanceEntries.map((entry, index) => (
            <LabelValueViewItem
              key={`entry-${index}`}
              label={attendanceTypesAllowedById[entry.typeId]?.name ?? 'N/A'}
              value={`${entry.startHour.slice(11, 16)} - ${entry.endHour.slice(11, 16)}`}
            />
          ))}
      </Box>

      {hasManagerAttendanceScopeForUser ||
        (hasAttendanceScopeForUser &&
          userAttendance.status === AttendanceStatus.InProgress &&
          attendanceSchedule?.trackingType !== ScheduleTrackingType.ClockInClockOut && (
            <Box sx={spacing.mt40}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="secondary"
                fullWidth
                onClick={() => {
                  setIsTrackTimeOpen(true);
                }}
              >
                {polyglot.t('General.edit')}
              </ButtonComponent>
            </Box>
          ))}
    </Fragment>
  );
};

const ViewNewAttendance = ({
  selectedDate,
  setIsTrackTimeOpen,
}: {
  selectedDate: string | null;
  setIsTrackTimeOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={drawerContentSx}>
      <LabelValueViewItem
        label={polyglot.t('ViewNewAttendance.status')}
        value={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <WaitingEmpty {...iconSize} />
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('ViewNewAttendance.notSubmitted')}
            </Typography>
          </Box>
        }
      />
      <LabelValueViewItem
        label={polyglot.t('ViewNewAttendance.date')}
        value={`${format(new LocalDate(selectedDate ?? new Date()).getDate(), 'd MMM yyyy')}`}
      />
      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
          onClick={() => {
            setIsTrackTimeOpen(true);
          }}
        >
          {polyglot.t('ViewNewAttendance.logHours')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

const RegularDrawer = () => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('ViewNewAttendance.attendance')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, marginTop: spacing.m30 }}>
        {[0, 1].map((num) => (
          <Skeleton height={20} key={`${num}-isx`} width="100%" style={{ background: themeColors.Background }} />
        ))}
      </Box>
    </Box>
  );
};
