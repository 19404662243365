import { forwardRef, useState } from 'react';

import { Box, TextField } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

const iconSize = { width: '14px', height: '14px' } as const;

export interface OptionObject {
  readonly label: string;
  readonly value: string | number;
}

interface SelectComponentProps {
  readonly name: string;
  readonly label: string;
  readonly value: string | number | undefined;
  readonly onChange: (emoji: EmojiClickData, event: MouseEvent) => void;
  readonly error?: boolean | undefined;
  readonly helperText?: string | boolean | undefined;
  readonly disabled?: boolean;
  readonly required?: boolean;
}
export const StyledEmojiPicker = forwardRef<typeof TextField, SelectComponentProps>(
  ({ name, label, value, onChange, error, helperText, required = false, disabled = false }, ref) => {
    const [openPicker, setOpenPicker] = useState<boolean>(false);
    return (
      <ClickAwayListener onClickAway={() => setOpenPicker(false)}>
        <Box sx={{ position: 'relative' }}>
          <StyledTextfield
            onClick={() => setOpenPicker(!openPicker)}
            inputRef={ref}
            variant="standard"
            label={label}
            size="small"
            fullWidth
            disabled={disabled}
            name={name}
            value={value}
            error={error}
            helperText={helperText}
            required={required}
            InputProps={{ endAdornment: <ArrowDownACIcon {...iconSize} />, readOnly: true }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box sx={{ position: 'absolute', zIndex: 2, left: '1px' }}>
            {openPicker && <EmojiPicker onEmojiClick={(emoji, e) => onChange(emoji, e)} width={320} />}
          </Box>
        </Box>
      </ClickAwayListener>
    );
  }
);
