import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormDto } from '@v2/feature/custom-fields/custom-profile-fields.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DietaryRestrictionsSelect } from '@/v2/components/dietary-select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserAboutDto } from '@/v2/feature/user/features/user-forms/user-about/user-about.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { spacing } from '@/v2/styles/spacing.styles';

type UserAboutSectionProps = {
  form: CustomProfileFormDto | undefined;
  onNext: (contact: Partial<UserAboutDto>) => void;
  sx?: SxProps<Theme>;
  aboutValues: UserAboutDto;
  userId: number;
};

type AboutFormDefaultVisibility = {
  about: boolean;
  hobbies: boolean;
  social: boolean;
  foodPreferences: boolean;
  dietaryRestrictions: boolean;
};

const UserAboutSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    about: Yup.string().nullable().optional(),
    hobbies: Yup.string().nullable().optional(),
    social: Yup.string()
      .nullable()
      .matches(/http[s]:\/\/\S+/, polyglot.t('OnboardingByUser.validations.validProfileURL'))
      .optional(),
    foodPreferences: Yup.string().nullable().optional(),
    dietaryRestrictions: Yup.string().nullable().optional(),
  });

export const UserAboutSection = ({ onNext, sx, aboutValues, userId, form }: UserAboutSectionProps) => {
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();

  const defaultFieldsVisibility: AboutFormDefaultVisibility = useMemo(() => {
    if (!form)
      return {
        about: false,
        hobbies: false,
        social: false,
        foodPreferences: false,
        dietaryRestrictions: false,
      };

    const aboutField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'about');
    const hobbiesField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'hobbies');
    const socialField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'social');
    const foodPreferencesField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'foodPreferences');
    const dietaryRestrictionsField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'dietaryRestrictions');

    return {
      about: Boolean(aboutField && !aboutField.isHidden),
      hobbies: Boolean(hobbiesField && !hobbiesField.isHidden),
      social: Boolean(socialField && !socialField.isHidden),
      foodPreferences: Boolean(foodPreferencesField && !foodPreferencesField.isHidden),
      dietaryRestrictions: Boolean(dietaryRestrictionsField && !dietaryRestrictionsField.isHidden),
    };
  }, [form]);

  const initialValues = useMemo(() => {
    const values: Partial<UserAboutDto> = {};
    if (defaultFieldsVisibility.about) values.about = aboutValues.about ?? '';
    if (defaultFieldsVisibility.hobbies) values.hobbies = aboutValues.hobbies ?? '';
    if (defaultFieldsVisibility.social) values.social = aboutValues.social ?? '';
    if (defaultFieldsVisibility.foodPreferences) values.foodPreferences = aboutValues.foodPreferences ?? '';
    if (defaultFieldsVisibility.dietaryRestrictions) values.dietaryRestrictions = aboutValues.dietaryRestrictions ?? '';

    values.customUpdates = aboutValues.customUpdates ?? [];

    return values;
  }, [defaultFieldsVisibility, aboutValues]);

  const formik = useFormik<Partial<UserAboutDto>>({
    initialValues,
    validationSchema: UserAboutSchema(polyglot),
    onSubmit: async (values) => {
      setSaving(true);
      try {
        await UserAPI.patchUserAboutInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.emergencyContactNotSaved', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('OnboardingByUser.aboutYou')}</Typography>

          {defaultFieldsVisibility.about && (
            <TextfieldComponent
              name="about"
              label={polyglot.t('OnboardingByUser.tellUsAboutYou')}
              value={formik.values.about}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('about', '')}
              helperText={hasSubmitted && formik.errors.about}
              error={hasSubmitted && !!formik.errors.about}
              autoFocus
            />
          )}

          {defaultFieldsVisibility.hobbies && (
            <TextfieldComponent
              name="hobbies"
              label={polyglot.t('OnboardingByUser.hobbies')}
              value={formik.values.hobbies}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('hobbies', '')}
              helperText={hasSubmitted && formik.errors.hobbies}
              error={hasSubmitted && !!formik.errors.hobbies}
            />
          )}

          {defaultFieldsVisibility.social && (
            <TextfieldComponent
              name="social"
              label={polyglot.t('OnboardingByUser.LinkedIn')}
              value={formik.values.social}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('social', '')}
              helperText={hasSubmitted && formik.errors.social}
              error={hasSubmitted && !!formik.errors.social}
            />
          )}

          {defaultFieldsVisibility.foodPreferences && (
            <TextfieldComponent
              name="foodPreferences"
              label={polyglot.t('OnboardingByUser.favouriteFoods')}
              value={formik.values.foodPreferences}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('foodPreferences', '')}
              helperText={hasSubmitted && formik.errors.foodPreferences}
              error={hasSubmitted && !!formik.errors.foodPreferences}
            />
          )}

          {defaultFieldsVisibility.dietaryRestrictions && (
            <DietaryRestrictionsSelect
              name="dietaryRestrictions"
              value={formik.values.dietaryRestrictions}
              disabled={saving}
              onChange={(_, value) => formik.setFieldValue('dietaryRestrictions', value)}
            />
          )}

          <CustomFieldComponents
            values={formik.values.customUpdates ?? []}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            disabled={saving}
            fieldSx={{}}
          />
          <LoaderButton
            name={polyglot.t('General.continue')}
            sizeVariant="large"
            colorVariant="primary"
            loading={saving}
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
