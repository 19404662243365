import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MenuItem, Stack, SxProps, Theme } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import axios from 'axios';

import { FILE_UPLOAD_ENDPOINT } from '@/api-client/routes';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Document } from '@/images/fields/Document.svg';
import { ReactComponent as UploadDocIcon } from '@/images/icons/AddDocument.svg';
import { ReactComponent as LinkIcon } from '@/images/icons/link.svg';
import { ReactComponent as SpinIcon } from '@/images/side-bar-icons/Spin.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ActionSelectionItemDeleteButton } from '@/v2/components/action-selection-group.component';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { StyledMenu } from '@/v2/components/theme-components/styled-menu.component';
import { Typography, TypographyProps } from '@/v2/components/typography/typography.component';
import { DocumentEndpoints } from '@/v2/feature/documents/document.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { StyledFormHelperText } from '@/v2/styles/form-helper-text.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type AttachmentComponentValue =
  | {
      type: 'link';
      name: string;
    }
  | {
      type: 'file';
      name: string;
      size: number;
      uuid: string;
    }
  | {
      type: 'document';
      name: string;
      id: number;
    };

export function stringToAttachmentComponentValue(s: string) {
  try {
    return JSON.parse(s) as AttachmentComponentValue;
  } catch {
    return null;
  }
}

export function attachmentComponentValueToString(value: AttachmentComponentValue | null) {
  return value ? JSON.stringify(value) : '';
}

type AttachmentComponentProps = Pick<TypographyProps, 'variant' | 'className'> & {
  label: string;
  name?: string;
  value?: AttachmentComponentValue | null;
  onChange?: (value: AttachmentComponentValue | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
  textSx?: SxProps<Theme> | undefined;
  showSelectExisting?: boolean;
};

export const AttachmentComponent = ({
  sx,
  disabled,
  helperText,
  error,
  onChange,
  name,
  label,
  value,
  showSelectExisting = false,
  ...props
}: AttachmentComponentProps) => {
  const { polyglot } = usePolyglot();
  const { data: allCompanyDocuments } = useApiClient(
    DocumentEndpoints.getDocumentsByCategory({ category: 'Company Documents' }, showSelectExisting),
    {
      suspense: false,
    }
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileIsUploading, setFileIsUploading] = useState<File | null>(null);
  const [linkUrl, setLinkURL] = useState('');
  const [docId, setDocId] = useState<number | undefined>(undefined);
  const [menuMode, setMenuMode] = useState<'options' | 'url-entry' | 'select-existing'>('options');
  const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
  const menuAnchorElement = useRef<HTMLDivElement | null>(null);
  const [showMessage] = useMessage();

  const docOptions = useMemo(() => {
    return (
      allCompanyDocuments?.map((d) => {
        return { label: d.name, value: d.id };
      }) ?? []
    );
  }, [allCompanyDocuments]);

  let style = { ...props.textSx };
  if (disabled) style = { ...style, color: themeColors.Grey };

  const closeMenu = useCallback(() => setMenuAnchor(null), []);

  const updateValue = useCallback(
    (newValue: AttachmentComponentValue | null) => {
      onChange?.(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (!menuAnchor) {
      // reset the menu when it closes
      setLinkURL('');
      setMenuMode('options');
      setDocId(undefined);
    }
  }, [menuAnchor]);

  const uploadFile = useCallback(
    async (file: File) => {
      let newValue: AttachmentComponentValue | null = null;
      setFileIsUploading(file);
      try {
        const formData = new FormData();
        formData.append('file', file);
        const uploadedFile = (
          await axios.post(FILE_UPLOAD_ENDPOINT, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).data;
        newValue = {
          type: 'file',
          name: file.name,
          size: file.size,
          uuid: uploadedFile.uuid,
        };
      } catch (error) {
        showMessage(`${polyglot.t('AttachmentComponent.errorMessages.upload')}. ${nestErrorMessage(error)}`, 'warning');
      }
      setFileIsUploading(null);
      updateValue(newValue);
    },
    [showMessage, updateValue, polyglot]
  );

  const linkIsAValidURL = /^https?:\/\/\S+/.test(linkUrl);

  return (
    <Stack sx={sx}>
      <Stack
        ref={disabled ? undefined : menuAnchorElement}
        onClick={() => setMenuAnchor(menuAnchorElement.current)}
        sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10, cursor: disabled ? 'default' : 'pointer' }}
      >
        <LinkIcon stroke={disabled ? themeColors.Grey : themeColors.DarkGrey} {...iconSize} />
        <Typography {...props} sx={style}>
          {label}
        </Typography>
        <input
          ref={fileInputRef}
          type="file"
          name={name}
          disabled={disabled}
          onChange={(e) => {
            if (disabled) return;
            const file = e.target.files?.[0];
            if (!file) return;
            uploadFile(file);
          }}
          style={{ display: 'none' }}
        />
      </Stack>
      <Stack>
        {fileIsUploading && (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', p: '2px 10px 0' }}>
            <Typography variant="title4" sx={{ color: disabled ? themeColors.Grey : undefined }}>
              {fileIsUploading.name}
            </Typography>
            <SpinIcon height="16px" style={{ marginLeft: 'auto' }} />
          </Stack>
        )}
      </Stack>
      <Stack>
        {value && (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', p: '2px 10px 0' }}>
            <Typography variant="title4" sx={{ color: disabled ? themeColors.Grey : undefined }}>
              {value.name}
            </Typography>
            <ActionSelectionItemDeleteButton
              disabled={disabled}
              onClick={() => updateValue(null)}
              sx={{ ml: 'auto' }}
            />
          </Stack>
        )}
      </Stack>
      <StyledFormHelperText error={error}>{helperText}</StyledFormHelperText>
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'menu',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={closeMenu}
      >
        {menuMode === 'options' && [
          <MenuItem
            key={0}
            onClick={() => {
              fileInputRef.current?.click();
              closeMenu();
            }}
          >
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: '8px' }}>
              <UploadDocIcon fill={themeColors.DarkGrey} {...iconSize} />
              {polyglot.t('AttachmentComponent.upload')}
            </Stack>
          </MenuItem>,
          <MenuItem key={1} onClick={() => setMenuMode('url-entry')}>
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: '8px', mt: '10px' }}>
              <LinkIcon stroke={themeColors.DarkGrey} {...iconSize} />
              {polyglot.t('AttachmentComponent.attach')}
            </Stack>
          </MenuItem>,
          ...(showSelectExisting
            ? [
                <MenuItem key={2} onClick={() => setMenuMode('select-existing')}>
                  <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: '8px', mt: '10px' }}>
                    <Document {...iconSize} />
                    Select existing
                  </Stack>
                </MenuItem>,
              ]
            : []),
        ]}
        {menuMode === 'url-entry' && (
          <MenuItem>
            <TextfieldComponent
              label={polyglot.t('AttachmentComponent.enter')}
              placeholder="https://"
              type="url"
              value={linkUrl}
              onChange={(e) => {
                setLinkURL(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (linkIsAValidURL) {
                    updateValue({ type: 'link', name: linkUrl });
                  }
                  closeMenu();
                  // stop the enter key press from submitting the form
                  e.stopPropagation();
                }
              }}
              endAdornment="clear-text"
              clearText={() => setMenuMode('options')}
              helperText={!!linkUrl && !linkIsAValidURL ? polyglot.t('AttachmentComponent.invalid') : ''}
              error={!!linkUrl && !linkIsAValidURL}
              sx={{ width: '400px' }}
            />
          </MenuItem>
        )}
        {menuMode === 'select-existing' && (
          <MenuItem>
            <AutocompleteComponent
              name="selectDocument"
              label="Select document"
              fullWidth
              options={docOptions}
              value={docId}
              sx={{ width: '350px' }}
              // @ts-ignore
              onChange={(_, x: OptionObject) => {
                setDocId(x.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const docObj = docOptions.find((d) => d.value === docId);
                  if (docObj) {
                    updateValue({ type: 'document', id: docObj?.value, name: docObj?.label });
                  }
                  closeMenu();
                  // stop the enter key press from submitting the form
                  e.stopPropagation();
                }
              }}
              compareValue={docId ?? ''}
            />
          </MenuItem>
        )}
      </StyledMenu>
    </Stack>
  );
};
