import { SxProps, Theme } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';

const primaryBasic = {
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  backgroundColor: themeColors.ZeltYellow,
  fill: themeColors.DarkGrey,
  textTransform: 'inherit',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: themeColors.YellowHover,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    boxShadow: 'none',
    backgroundColor: themeColors.YellowPress,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: themeColors.DisabledYellow,
    fill: themeColors.DarkGrey,
    color: themeColors.DarkGrey,
    cursor: 'not-allowed',
  },
};
/** @deprecated */
export const secondaryBasic = {
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  backgroundColor: themeColors.Background,
  textTransform: 'inherit',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    boxShadow: 'none',
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    boxShadow: 'none',
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    color: themeColors.Grey,
    fill: themeColors.Grey,
    boxShadow: 'none',
  },
};

/** @deprecated */
export const primarySmallBtn: SxProps<Theme> = {
  minWidth: 'max-content',
  height: '40px',
  padding: '10px 15px',
  borderRadius: radius.br10,
  ...primaryBasic,
};

/** @deprecated */
export const primaryTableSmallBtn: SxProps<Theme> = {
  minWidth: '40px',
  height: '30px',
  ...primaryBasic,
  ...themeFonts.caption,
  borderRadius: radius.br8,
};

/** @deprecated */
export const secondarySmallBtn: SxProps<Theme> = {
  minWidth: 'max-content',
  padding: '10px 15px',
  height: '40px',
  borderRadius: radius.br10,
  ...secondaryBasic,
};

/** @deprecated */
export const secondaryCTABtn: SxProps<Theme> = {
  minWidth: 'fit-content',
  padding: '5px 10px',
  height: '30px',
  ...secondaryBasic,
  borderRadius: radius.br8,
};
/** @deprecated */
export const secondaryTableSmallBtn: SxProps<Theme> = {
  minWidth: 'fit-content',
  height: '30px',
  padding: '5px 8px',
  ...secondaryBasic,
};
/** @deprecated */
export const secondaryTextSmallBtn: SxProps<Theme> = {
  p: 0,
  minWidth: '40px',
  height: '20px',
  ...themeFonts.caption,
  color: themeColors.Grey,
  fill: themeColors.Grey,
  backgroundColor: themeColors.white,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
  },
  '&:active': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
  },
  '&:focus': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.Background,
  },
};
/** @deprecated */
export const secondaryLargeBtn: SxProps<Theme> = {
  height: '50px',
  ...secondaryBasic,
  borderRadius: radius.br10,
  '@media (min-width:600px)': {
    '&:not(.MuiButton-fullWidth)': {
      width: '190px',
    },
  },
};
/** @deprecated */
export const secondaryRoundMenuButton: SxProps<Theme> = {
  minWidth: 'fit-content',
  padding: '5px 10px',
  height: '30px',
  borderRadius: radius.br15,
  ...secondaryBasic,
};

/** @deprecated */
export const errorSmallBtn: SxProps<Theme> = {
  minWidth: 'max-content',
  padding: '10px 15px',
  height: '40px',
  ...themeFonts.caption,
  color: themeColors.white,
  fill: themeColors.ZeltYellow,
  backgroundColor: themeColors.Red,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br10,
  '&:hover': {
    backgroundColor: themeColors.hoverRed,
    boxShadow: 'none',
    color: themeColors.white,
    fill: themeColors.white,
  },
  '&:target': {
    backgroundColor: themeColors.pressRed,
    boxShadow: 'none',
    color: themeColors.white,
    fill: themeColors.white,
  },
  '&:disabled': {
    backgroundColor: themeColors.lightRed,
    color: themeColors.white,
    fill: themeColors.white,
    boxShadow: 'none',
  },
};

/** @deprecated */
export const successSmallBtn: SxProps<Theme> = {
  ...themeFonts.caption,
  minWidth: 'max-content',
  height: '40px',
  padding: '10px 15px',
  borderRadius: radius.br10,
  color: themeColors.white,
  fill: themeColors.white,
  textTransform: 'inherit',
  boxShadow: 'none',
  backgroundColor: themeColors.Green,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: themeColors.ZeltSuccess,
    color: themeColors.white,
    fill: themeColors.white,
  },
  '&:target': {
    boxShadow: 'none',
    backgroundColor: themeColors.GreenMiddle,
    color: themeColors.white,
    fill: themeColors.white,
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: themeColors.GreenDisabled,
    fill: themeColors.white,
    color: themeColors.white,
    cursor: 'not-allowed',
  },
};
/** @deprecated */
export const successLargeBtn: SxProps<Theme> = {
  ...themeFonts.caption,
  minWidth: 'max-content',
  height: '50px',
  padding: '10px 15px',
  borderRadius: radius.br10,
  '@media (min-width:600px)': {
    '&:not(.MuiButton-fullWidth)': {
      width: '190px',
    },
  },
  color: themeColors.white,
  fill: themeColors.white,
  textTransform: 'inherit',
  boxShadow: 'none',
  backgroundColor: themeColors.Green,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: themeColors.ZeltSuccess,
    color: themeColors.white,
    fill: themeColors.white,
  },
  '&:target': {
    boxShadow: 'none',
    backgroundColor: themeColors.GreenMiddle,
    color: themeColors.white,
    fill: themeColors.white,
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: themeColors.GreenDisabled,
    fill: themeColors.white,
    color: themeColors.white,
    cursor: 'not-allowed',
  },
};
/** @deprecated */
export const successCTABtn: SxProps<Theme> = {
  minWidth: 'fit-content',
  padding: '5px 8px',
  height: '30px',
  border: 'none',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  backgroundColor: themeColors.Green,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br8,
  '&:hover': {
    backgroundColor: themeColors.middleGrey,
    boxShadow: 'none',
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.pressGrey,
    boxShadow: 'none',
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.lightGrey,
    color: themeColors.Grey,
    fill: themeColors.Grey,
    boxShadow: 'none',
  },
};

/** @deprecated */
export const whiteSmallBtn: SxProps<Theme> = {
  ...themeFonts.caption,
  minWidth: 'max-content',
  height: '40px',
  padding: '10px 15px',
  borderRadius: radius.br10,
  color: themeColors.black,
  fill: themeColors.black,
  textTransform: 'inherit',
  boxShadow: 'none',
  backgroundColor: themeColors.white,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: themeColors.GreyHover,
    color: themeColors.black,
    fill: themeColors.black,
  },
  '&:target': {
    boxShadow: 'none',
    backgroundColor: themeColors.GreyPress,
    color: themeColors.black,
    fill: themeColors.black,
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: themeColors.DisabledGrey,
    fill: themeColors.Grey,
    color: themeColors.Grey,
    cursor: 'not-allowed',
  },
};
/** @deprecated */
export const errorTextSmallBtn: SxProps<Theme> = {
  p: 0,
  minWidth: '40px',
  height: '20px',
  ...themeFonts.caption,
  color: themeColors.red,
  fill: themeColors.red,
  backgroundColor: themeColors.white,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br8,
  '&:hover': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.red,
  },
  '&:target': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.pressRed,
  },
  '&:active': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.red,
  },
  '&:focus': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.red,
  },
  '&:disabled': {
    backgroundColor: themeColors.white,
    boxShadow: 'none',
    color: themeColors.lightRed,
  },
};
/** @deprecated */
export const tabsFilterBtnSx: SxProps<Theme> = {
  padding: '5px 10px',
  height: '30px',
  minWidth: '30px',
  border: 'none',
  borderRadius: radius.br15,
  boxSizing: 'border-box',
  ...secondaryBasic,
  '&:active': {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: 'none',
  },
};
/** @deprecated */
export const activeTabsFilterBtnSx: SxProps<Theme> = {
  padding: '5px 10px',
  height: '30px',
  minWidth: '30px',
  ...themeFonts.caption,
  color: themeColors.Background,
  fill: themeColors.Background,
  borderRadius: radius.br15,
  backgroundColor: themeColors.DarkGrey,
  textTransform: 'inherit',
  boxShadow: 'none',
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: 'none',
  },
  '&:target': {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: themeColors.DarkGrey,

    color: themeColors.white,
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: themeColors.DarkGrey,

    color: themeColors.white,
    boxShadow: 'none',
  },
};
/** @deprecated */
export const dangerLargeBtn: SxProps<Theme> = {
  height: '50px',
  ...themeFonts.caption,
  color: themeColors.white,
  backgroundColor: themeColors.darkRed,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br10,
  '&:hover': {
    backgroundColor: themeColors.hoverRed,
    color: themeColors.white,
    boxShadow: 'none',
  },
  '&:target': {
    backgroundColor: themeColors.pressRed,
    color: themeColors.white,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: themeColors.lightRed,
    color: themeColors.white,
    boxShadow: 'none',
  },
  '@media (min-width:600px)': {
    '&:not(.MuiButton-fullWidth)': {
      width: '190px',
    },
  },
};
/** @deprecated */
export const loginHeaderBtn: SxProps<Theme> = {
  minWidth: '40px',
  height: '30px',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.lightGrey,
  backgroundColor: themeColors.Background,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: themeColors.TableHover,
  },
};

/** @deprecated */
export const activefilteredSmallBtn: SxProps<Theme> = {
  padding: '5px 10px',
  height: '30px',
  color: themeColors.Background,
  fill: themeColors.Background,
  borderRadius: radius.br15,
  backgroundColor: themeColors.DarkGrey,
  textTransform: 'inherit',
  boxShadow: 'none',
  ...themeFonts.caption,
  '&:hover': {
    backgroundColor: themeColors.Grey,
    boxShadow: 'none',
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    boxShadow: 'none',
    fill: themeColors.Background,
  },
  '&:disabled': {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.Background,
    fill: themeColors.Background,
    boxShadow: 'none',
  },
};
/** @deprecated */
export const tabCountSx: SxProps<Theme> = {
  borderRadius: radius.br15,
  minWidth: '16px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  ...themeFonts.caption,
};
/** @deprecated */
export const activeTabCount: SxProps<Theme> = {
  borderRadius: radius.br15,
  minWidth: '16px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  ...themeFonts.caption,
  color: themeColors.Background,
};
/** @deprecated */
export const underlinedLinkLight: SxProps<Theme> = {
  ...themeFonts.caption,
  color: themeColors.Grey,
  textTransform: 'inherit',
  textDecorationColor: themeColors.Grey,
  '&:hover': {
    color: themeColors.DarkGrey,
  },
  '&:target': {
    color: themeColors.pressGrey,
    textDecoration: 'none',
  },
};
/** @deprecated */
export const underlinedLinkDark: SxProps<Theme> = {
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  textTransform: 'inherit',
  textDecorationColor: themeColors.DarkGrey,
  '&:hover': {
    color: themeColors.Grey,
  },
  '&:target': {
    color: themeColors.pressGrey,
    textDecoration: 'none',
  },
};
/** @deprecated */
export const linkButtonTitle3Sx: SxProps<Theme> = {
  ...themeFonts.title4,
  background: 'none',
  border: 'none',
  color: 'blue',
  textDecoration: 'underline',
  cursor: 'pointer',
  p: 0,
  m: 0,
  minWidth: '40px',
  height: '20px',
  fill: themeColors.DarkGrey,
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br8,
};
/** @deprecated */
export const linkButtonSx: SxProps<Theme> = {
  background: 'none',
  border: 'none',
  textDecoration: 'underline',
  cursor: 'pointer',
  p: 0,
  m: 0,
  minWidth: '40px',
  height: '20px',
  textTransform: 'inherit',
  boxShadow: 'none',
  borderRadius: radius.br8,
};
/** @deprecated */
export const linkTextSx: SxProps<Theme> = {
  color: themeColors.DarkGrey,
  textTransform: 'inherit',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: themeColors.BrownHover,
  },
  '&:target': {
    color: themeColors.BrownHover,
    textDecoration: 'none',
  },
};
