import React, { useEffect, useRef, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import {
  AccountingJournalState,
  AccountingJournalStates,
  PayrunProcessStepState,
  PayrunProcessStepStates,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { formatAccountingJournal } from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.util';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { getCSVRows, toCsv } from '@v2/util/csv.util';
import { CSVLink } from 'react-csv';
import { generatePath } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from '@/lib/routes';
import { PayrunProcessingItem } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component';
import { AccountingConfirmDoneDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/accounting/accounting-confirm-done-drawer.component';

interface AccountingJournalProps {
  readonly payRun: PayRunDto;
  readonly accountingJournalState: AccountingJournalState;
  readonly setAccountingJournalState: React.Dispatch<React.SetStateAction<AccountingJournalState>>;
  sx?: SxProps<Theme>;
}

export const AccountingJournal = ({
  payRun,
  accountingJournalState,
  setAccountingJournalState,
  sx,
}: AccountingJournalProps): JSX.Element => {
  const [accordionState, setAccordionState] = useState<PayrunProcessStepState>(PayrunProcessStepStates.pending);
  const [isAccountingAppInstalled, setIsAccountingAppInstalled] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [openConfirmMarkAsSent, setOpenConfirmMarkAsSent] = useState(false);
  const [failureMessage, setFailureMessate] = useState('');

  const csvRef = useRef<any>();
  const [csvFile, setCSVFile] = useState<{ readonly name: string; readonly data: unknown }>({
    name: 'default.csv',
    data: [],
  });
  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunAccountingDetails = await PayrollLocalApi.getPayrunAccountingRecord(payRun.id);
        setAccountingJournalState(payRunAccountingDetails.state);

        // If SUBMITTED or MARKED AS SENT - return with Success state
        if (
          [AccountingJournalStates.submitted, AccountingJournalStates.markedAsSent].includes(
            payRunAccountingDetails.state
          )
        ) {
          setAccountingJournalState(payRunAccountingDetails.state);
          setAccordionState(PayrunProcessStepStates.success);
          return;
        }

        // If NOT SUBMITTED and Accounting App NOT INSTALLED - return with install app notification
        if (!payRunAccountingDetails?.isAccountingAppInstalled || !payRunAccountingDetails.isAccountingAppLinked) {
          setIsAccountingAppInstalled(false);
          setAccountingJournalState(payRunAccountingDetails.state);
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }

        // If NOT SUBMITTED and accounting app INSTALLED - submit the accounting journal then update state according the response
        const updatedPayrunAccountingDetails = await PayrollLocalApi.submitAccountingJournal(payRun.id);
        setAccountingJournalState(updatedPayrunAccountingDetails.state);
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setFailureMessate(nestErrorMessage(error));
        setAccordionState(PayrunProcessStepStates.failure);
        showMessage(`Could not submit accounting journal. ${nestErrorMessage(error)}`, 'error');
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payRun, showMessage]);

  const submitJournal = async () => {
    try {
      setAccordionState(PayrunProcessStepStates.pending);

      if (!isAccountingAppInstalled) {
        // Check first if the accounting app has been installed
        const payRunAccountingDetails = await PayrollLocalApi.getPayrunAccountingRecord(payRun.id);
        if (!payRunAccountingDetails.isAccountingAppInstalled) {
          setIsAccountingAppInstalled(false);
          setAccountingJournalState(payRunAccountingDetails.state);
          setAccordionState(PayrunProcessStepStates.warning);
          return; // Accounting app still not installed - no submission
        }
      }

      const updatedPayrunAccountingDetails = await PayrollLocalApi.submitAccountingJournal(payRun.id);
      setAccountingJournalState(updatedPayrunAccountingDetails.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      setFailureMessate(nestErrorMessage(error));
      showMessage(`Error: Could not submit accounting journal. ${nestErrorMessage(error)}`, 'error');
      setAccordionState(PayrunProcessStepStates.failure);
    }
  };

  useEffect(() => {
    if (csvFile?.data && csvFile.name !== 'default.csv' && csvRef.current && csvRef.current.link) {
      // @ts-ignore
      csvRef.current.link.click();
    }
  }, [csvFile]);

  const downloadJournal = async (): Promise<void> => {
    setIsDownloading(true);
    try {
      const journalData = await PayrollLocalApi.getPayrunExternalAccountingData(payRun.id);
      const formattedData = formatAccountingJournal(journalData);

      const data = toCsv(getCSVRows(formattedData));
      setCSVFile({ name: `payrun-${payRun.taxYear}-Month-${payRun.period}-accounting.csv`, data });
      showMessage('Journal downloaded', 'success');
    } catch (error) {
      showMessage(`Could not download journal data. ${nestErrorMessage(error)}`, 'error');
    }
    setIsDownloading(false);
  };

  return (
    <>
      <PayrunProcessingItem
        title="Accounting"
        key="accounting"
        description={
          {
            pending: 'Publishing payrun journal...',
            failure: `Accounting journal submission failed. ${failureMessage}`,
            success:
              accountingJournalState === AccountingJournalStates.markedAsSent
                ? 'Accounting journal is marked as sent.'
                : 'Accounting journal updated.',
            warning: 'There is no accounting app connected to this payroll.',
          }[accordionState]
        }
        buttons={[
          {
            style: 'primary',
            label: 'Connect Accounting App',
            show: accordionState === PayrunProcessStepStates.warning,
            onClick: () =>
              window.open(
                generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId: payRun.payrollId }),
                '_blank'
              ),
          },
          {
            style: 'primary',
            label: 'Retry',
            show: accordionState === PayrunProcessStepStates.failure,
            onClick: () => submitJournal(),
          },
          {
            style: 'secondary',
            label: 'Mark as sent',
            show:
              accordionState === PayrunProcessStepStates.failure || accordionState === PayrunProcessStepStates.warning,
            onClick: () => setOpenConfirmMarkAsSent(true),
          },
          {
            style: 'secondary',
            label: 'Download',
            show: true,
            loading: isDownloading,
            onClick: () => downloadJournal(),
          },
        ]}
        success={
          accordionState === PayrunProcessStepStates.pending
            ? undefined
            : accordionState === PayrunProcessStepStates.success
        }
        sx={sx}
      />
      <CSVLink
        ref={csvRef}
        filename={csvFile.name}
        data={Array.isArray(csvFile.data) || typeof csvFile.data === 'string' ? csvFile.data : []}
      />
      <AccountingConfirmDoneDrawer
        open={openConfirmMarkAsSent}
        onClose={() => setOpenConfirmMarkAsSent(false)}
        markAsSent={async () => {
          const updatedPayrunAccountingRecord = await PayrollLocalApi.markAccountingJournalAsSent(payRun.id);
          setAccountingJournalState(updatedPayrunAccountingRecord.state);
          setAccordionState(PayrunProcessStepStates.success);
        }}
      />
    </>
  );
};
