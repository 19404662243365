import React from 'react';

import { Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ArrowUp } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowUpSelect.svg';
import { secondaryTextSmallBtn } from '@/v2/styles/buttons.styles';

const iconSize = { width: 14, height: 14 } as const;
export const ShowHideButton = ({
  showDetails,
  setShowDetails,
  showTitle,
  hideTitle,
}: {
  readonly showDetails: boolean;
  readonly setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  readonly showTitle?: string;
  readonly hideTitle?: string;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <>
      {showDetails ? (
        <Typography
          sx={{ ...secondaryTextSmallBtn, display: 'flex', alignItems: 'center' }}
          onClick={() => setShowDetails(false)}
        >
          {hideTitle ?? polyglot.t('ShowHideButton.hideHistory')} <ArrowDown {...iconSize} />
        </Typography>
      ) : (
        <Typography
          title={polyglot.t('ShowHideButton.showDetails')}
          sx={{ ...secondaryTextSmallBtn, display: 'flex', alignItems: 'center' }}
          onClick={() => setShowDetails(true)}
        >
          {showTitle ?? polyglot.t('ShowHideButton.showHistory')} <ArrowUp {...iconSize} />
        </Typography>
      )}
    </>
  );
};
