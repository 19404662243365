import { User, UserOverviewConfig } from '@/models';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';

export enum EmploymentTypeEnum {
  Employee = 'Employee',
  Contractor = 'Contractor',
}
export interface CreateUserOnboarding extends Omit<Partial<User>, 'accountType'> {
  templateId?: number;
  appSource?: AppIntegrationStub;
  externalId?: string;
}

export interface UpdateUserOnboarding extends Partial<User> {
  userId: number;
}

export interface PasswordSetResult {
  userId: number;
}
export interface OnboardingStepProp {
  handleNext: () => void;
  handleBack?: () => void;
  userOverviewConfig?: UserOverviewConfig;
}

//list of apps excluded by Polina from onboarding & offboarding
export const MANUALLY_EXCLUDED_BOARDING_APPS = ['slack', 'monday', 'xero'];

export interface AppUsedInOnboardingResult {
  users: number;
  templates: number;
  used: boolean;
}
