import { Box } from '@mui/material';
import { Dictionary } from 'lodash';

import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips,
} from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import {
  QuestionType,
  QuestionTypeMap,
  ReviewQuestion,
} from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { stripHtml } from '@/v2/util/string.util';

export const QuestionBuilder = ({
  questionOptions,
  question,
  growthFactors,
  showAction,
}: {
  questionOptions: readonly OptionProps[];
  question: Pick<
    ReviewQuestion,
    | 'companyId'
    | 'questionMain'
    | 'questionSelf'
    | 'reviewerSelect'
    | 'hasComment'
    | 'createdBy'
    | 'factor'
    | 'type'
    | 'visibilitySettings'
    | 'scaleConfig'
  >;
  growthFactors: Dictionary<GrowthFactor>;
  showAction: boolean;
}) => {
  return (
    <Box
      sx={{
        bgcolor: themeColors.white,
        p: spacing.p16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${themeColors.GreyLight}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g8,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing.g4,
          }}
        >
          <Typography variant="title4">
            {question.questionMain && question.questionMain.length > 0
              ? stripHtml(question.questionMain)
              : stripHtml(question.questionSelf)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing.g4,
          }}
        >
          {question.factor && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.g4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: growthFactors[question.factor]?.color ?? themeColors.Grey,
                      height: '8px',
                      width: '8px',
                      borderRadius: radius.br25,
                    }}
                  />
                  <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                    {question.factor}
                  </Typography>
                </Box>
              }
            />
          )}
          {question.type && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Typography variant="caption" color="Grey">
                  {QuestionTypeMap[question.type as QuestionType]}
                </Typography>
              }
            />
          )}

          {question.type && question.type === 'scaleQuestion' && question.scaleConfig && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                  {question.scaleConfig.type}
                </Typography>
              }
            />
          )}

          <Typography variant="caption" sx={{ color: themeColors.Grey, gap: spacing.g4, display: 'flex' }}>
            {question.reviewerSelect
              ? question.reviewerSelect
                  .sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b])
                  .map((r) => {
                    const hideVisibility =
                      r !== ReviewerTypes.Self &&
                      ((r === ReviewerTypes.Manager && question.visibilitySettings.hideManagerResult) ||
                        (r === ReviewerTypes.Upward && question.visibilitySettings.hideUpwardResult) ||
                        (r === ReviewerTypes.Peer && question.visibilitySettings.hidePeerResult));

                    return getReviewerTypesChips(hideVisibility)[r];
                  })
              : ''}
          </Typography>

          {question.hasComment && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                  Comment
                </Typography>
              }
            />
          )}
        </Box>
      </Box>

      {showAction && (
        <StyledMenuComponent
          options={questionOptions}
          actionButtonDetails={{
            type: 'iconButton',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'actions',
            icon: <ActionsSmall {...iconSize} />,
          }}
        />
      )}
    </Box>
  );
};
