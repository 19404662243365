import { Box } from '@mui/material';

import { ViewerItem } from './payment-details-drawer.component';
import { ContractorInvoiceLineItem, ExpenseLineItem } from '../payments.interface';

import { Typography } from '@/v2/components/typography/typography.component';
import { borders } from '@/v2/styles/borders.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface LineItemInDrawerProps {
  item: Partial<ExpenseLineItem> | Partial<ContractorInvoiceLineItem>;
  index: number;
  currency: string;
}

export const LineItemInDrawerComponent: React.FC<LineItemInDrawerProps> = ({ item, index, currency }) => {
  const accountingCodeDescription =
    item.accountingCodeDescription && item.accountingCodeDescription?.length > 0
      ? ` - ${truncateWithEllipses(item.accountingCodeDescription, 25)}`
      : '';
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: borders.background,
        pb: '10px',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="captionSmall" color="Grey" sx={{ mb: '5px' }}>
          {`Item ${index + 1}`}
        </Typography>
        <Typography variant="caption">{item?.type?.name ?? ''}</Typography>
        {item?.accountingCode ? (
          <ViewerItem title={'Accounting code'} value={`${item.accountingCode}${accountingCodeDescription}`} />
        ) : (
          <></>
        )}
        <ViewerItem title={'Gross (Inc.Tax)'} value={formatCurrency(item.gross, undefined, currency) ?? ''} />
      </Box>
    </Box>
  );
};
