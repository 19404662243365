import React, { useMemo } from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@v2/components/typography/typography.component';

import { useProfileFields } from '@/hooks/profile-fields.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoCircleBlack.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortBoolean, sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollSettingsPayCodesTableProps = {
  payCodes?: GlobalPaycode[] | null;
  onEditPayCodeClick: (paycode: GlobalPaycode) => void;
};

export const GlobalPayrollPayCodesTable = ({ payCodes, onEditPayCodeClick }: PayrollSettingsPayCodesTableProps) => {
  const sortedPaycodes = useMemo(() => payCodes?.sort((a, b) => a.order - b.order), [payCodes]);

  const { getCustomFieldsForForm } = useProfileFields();
  const salaryFieldNamesByPaycode = useMemo(() => {
    const salaryCustomFields = getCustomFieldsForForm('salary');
    return new Map(
      salaryCustomFields
        .filter((f) => f.paycode)
        .map((f, _, arr) => [
          f.paycode as string,
          arr.filter(({ paycode }) => paycode === f.paycode).map((field) => field.fieldName),
        ])
    );
  }, [getCustomFieldsForForm]);

  const columns = useMemo<ColumnDef<GlobalPaycode, GlobalPaycode>[]>(
    () => [
      {
        id: 'code',
        header: () => 'Code',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.code),
        cell: (c) => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption">{c.row.original.code}</Typography>
          </Box>
        ),
      },
      {
        id: 'name',
        header: () => 'Name',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name, { sensitivity: 'base' }),
        cell: (c) => (
          <Box sx={{ width: '250px' }}>
            <Typography variant="caption" sx={{ width: '100%', wordBreak: 'break-word', whiteSpace: 'normal' }}>
              {c.row.original.name}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'required',
        header: () => 'Required',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.required, true),
        cell: (c) => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption">{c.row.original.required ? 'Required' : 'Optional'}</Typography>
          </Box>
        ),
      },
      {
        id: 'credit',
        header: () => 'Credit',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.credit, { sensitivity: 'base' }),
        cell: (c) => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption" sx={{ width: '100%' }}>
              {c.row.original.credit}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'debit',
        header: () => 'Debit',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.debit, { sensitivity: 'base' }),
        cell: (c) => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption" sx={{ width: '100%' }}>
              {c.row.original.debit}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'salary-field',
        header: () => 'Salary field',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => salaryFieldNamesByPaycode.has(item.code)),
        cell: (c) => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption" sx={{ width: '100%' }}>
              {(salaryFieldNamesByPaycode.get(c.row.original.code) ?? []).join(', ')}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'default-value',
        header: () => 'Default value',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => !!item.formula),
        cell: (c) => (
          <Box sx={{ width: '100%', display: 'flex', gap: spacing.s1, alignItems: 'center' }}>
            {c.row.original.formula ? (
              <>
                <Typography variant="caption">Yes</Typography>
                <StyledTooltip
                  title={<div style={{ whiteSpace: 'pre' }}>{c.row.original.formula}</div>}
                  placement="top"
                  slotProps={{ tooltip: { sx: { maxWidth: 'fit-content' } } }}
                >
                  <InfoIcon {...iconSize} tabIndex={0} />
                </StyledTooltip>
              </>
            ) : (
              <Typography variant="caption">No</Typography>
            )}
          </Box>
        ),
      },
      {
        id: 'payrun-order',
        header: () => 'Payrun display order',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.order),
        cell: (c) => (
          <Box sx={{ width: '100%' }}>
            <Typography variant="caption" sx={{ width: '100%' }}>
              {c.row.original.order}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (c) => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.s1, justifyContent: 'flex-end' }}>
            <IconButton
              sx={tableIconButtonSx}
              title={`Edit ${c.row.original.code}`}
              onClick={() => onEditPayCodeClick(c.row.original)}
            >
              <Edit {...iconSize} />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [onEditPayCodeClick, salaryFieldNamesByPaycode]
  );

  return <BasicTable loading={!payCodes} rowData={sortedPaycodes ?? []} columnData={columns} hidePagination />;
};
