import { Dispatch, SetStateAction } from 'react';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PaymentFormModal } from '@/v2/feature/payments/features/make-payment/components/payment-form-modal.component';
import { PaymentsAPI } from '@/v2/feature/payments/payments.api';
import { PaymentDto, SinglePaymentRequestDto } from '@/v2/feature/payments/payments.dto';

interface CreatePaymentDialogProps {
  readonly onChange: () => void;
  readonly setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly selectedPayment: PaymentDto | undefined;
}

export function PaymentForDialogSection({
  onChange,
  setIsModalOpen,
  selectedPayment,
}: CreatePaymentDialogProps): JSX.Element {
  const [showMessage] = useMessage();

  const handleChange = async (request: SinglePaymentRequestDto) => {
    try {
      if (selectedPayment) await PaymentsAPI.updatePayment({ ...request, institutionId: 'none' }, selectedPayment.id);
      else await PaymentsAPI.createPayment({ ...request, institutionId: 'none' });
      setIsModalOpen(false);
      onChange();
    } catch (e) {
      showMessage(nestErrorMessage(e), 'error');
    }
  };

  return <PaymentFormModal onSubmit={handleChange} selectedPayment={selectedPayment} setIsModalOpen={setIsModalOpen} />;
}
