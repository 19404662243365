import React from 'react';

import { Box, SwipeableDrawer } from '@mui/material';

import { EditDeductionsPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-deductions-page.component';
import { EditEmployerCostPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-employer-cost-page.component';
import { EditIncomePage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-income-page.component';
import { PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { StaffologyPayCode } from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly onOpen?: () => void;
  onClose?: () => void;
  userId?: number;
  payCodes?: StaffologyPayCode[] | null;
  payrunEntry?: PayRunEntryDto;
  payrunClosed: boolean;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
  Content: typeof EditIncomePage | typeof EditDeductionsPage | typeof EditEmployerCostPage;
}

export const EditPayrunEntryDrawer = ({
  onOpen = () => {},
  onClose = () => {},
  userId,
  payCodes,
  payrunEntry,
  payrunClosed,
  saveIncomeUpdates,
  Content,
}: Props): React.JSX.Element => {
  const { getCachedUserById } = useCachedUsers();
  const user = userId && getCachedUserById(userId);

  return (
    <SwipeableDrawer anchor="right" open={!!user} onClose={onClose} onOpen={onOpen}>
      {user && payrunEntry && payCodes && (
        <Box sx={{ px: spacing.px40, py: spacing.p30, width: '440px', boxSizing: 'border-box', maxWidth: '100vw' }}>
          <Content
            user={user}
            payrunEntry={payrunEntry}
            payCodes={payCodes}
            payrunClosed={payrunClosed}
            saveIncomeUpdates={async (updates) => {
              const result = await saveIncomeUpdates(updates);
              if (result) setImmediate(onClose);
              return result;
            }}
          />
        </Box>
      )}
    </SwipeableDrawer>
  );
};
