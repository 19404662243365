import { generatePath, useHistory } from 'react-router-dom';

import { PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE } from '@/lib/routes';

export const navigateToPayrunsView = (
  routerHistory: ReturnType<typeof useHistory>,
  mode: 'push' | 'replace',
  payrollId: number,
  payrunId?: number
) => {
  const state = { payrunId };
  routerHistory[mode](generatePath(PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE, { payrollId }), state);
};
