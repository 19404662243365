import { useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MissingFieldsDetail } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import {
  AddressForm,
  AddressValues,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshData?: () => Promise<void>;
  updateDomainForMissingFields?: (values: AddressValues) => void;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingAddressFormForPersonalContractSigning = ({
  userId,
  refreshData,
  updateDomainForMissingFields,
  missingFieldsForCategory,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
        <Typography sx={themeFonts.caption}>
          {polyglot.t('MissingAddressFormForPersonalContractSigning.homeAddress')}
        </Typography>
        <IconButton onClick={() => setIsModalOpen(true)} sx={{ ...secondaryCTABtn, p: '7px', ml: 'auto' }}>
          <Plus width="16px" height="16px" />
        </IconButton>
      </Stack>
      {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        <AddressForm
          userId={userId}
          handleRefresh={() => refreshData?.()}
          onClose={() => setIsModalOpen(false)}
          usedForDataImport={true}
          importHandler={async (values: AddressValues) => {
            await updateDomainForMissingFields?.(values);
            await refreshData?.();
          }}
          showEmployee
        />
      </DrawerModal>
    </>
  );
};
