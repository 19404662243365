import React, { useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { PersonalSettingsProps } from '@/v2/feature/user/user.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settings: PersonalSettingsProps | null | undefined;
  refreshSettings: KeyedMutator<PersonalSettingsProps> | undefined;
}

const UserPersonalSettingsSchema = Yup.object().shape({
  showBirthday: Yup.boolean().required('Required field'),
});
export const UserPrivacySettingDrawer = ({ isOpen, setIsOpen, settings, refreshSettings }: Props) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <UserPrivacySettingContent settings={settings} setIsOpen={setIsOpen} refreshSettings={refreshSettings} />
    </DrawerModal>
  );
};

interface ContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settings: PersonalSettingsProps | null | undefined;
  refreshSettings: KeyedMutator<PersonalSettingsProps> | undefined;
}

const UserPrivacySettingContent = ({ setIsOpen, settings, refreshSettings }: ContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: { showBirthday: settings ? settings.showBirthday : true },
    enableReinitialize: true,
    validationSchema: UserPersonalSettingsSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await UserAPI.patchUserPersonalSettings(values);
        showMessage(polyglot.t('UserPrivacySettingContent.successMessages.update'), 'success');
        await refreshSettings?.();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('UserPrivacySettingContent.errorMessages.encounter', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
          <Typography variant="title2">{polyglot.t('UserPrivacySettingContent.privacysettings')}</Typography>
          <Typography variant="caption" sx={{ color: themeColors.Grey }}>
            {polyglot.t('UserPrivacySettingContent.description')}
          </Typography>
        </Box>

        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            aria-labelledby="show-birthday"
            name="show-birthday"
            onChange={(event) => {
              formik.setFieldValue('showBirthday', event.target.value === 'true');
            }}
          >
            <FormControlLabel
              key="false"
              labelPlacement="end"
              value={false}
              checked={!formik.values.showBirthday}
              control={<StyledRadio />}
              label={
                <Box>
                  <Typography variant="title4">
                    {polyglot.t('UserPrivacySettingContent.hidemybirthdayfromcalendar')}
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              key="true"
              labelPlacement="end"
              value={true}
              checked={formik.values.showBirthday}
              control={<StyledRadio />}
              label={
                <Box>
                  <Typography variant="title4">
                    {polyglot.t('UserPrivacySettingContent.showmybirthdayincalendar')}
                  </Typography>
                </Box>
              }
              sx={{ my: spacing.m10 }}
            />
          </RadioGroup>
        </FormControl>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
