import { useState } from 'react';

import { Typography } from '@mui/material';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { generatePath } from 'react-router-dom';

import useScopes from '@/hooks/scopes.hook';
import { USER_BENEFITS_OVERVIEW_ROUTE } from '@/lib/routes';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PensionMe } from '@/v2/feature/benefits/subfeature/pension/features/pension-me/components/pension-me.components';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly userId: number;
  readonly employeePension: UserPensionDto;
  readonly onEmployeeUpdate: () => Promise<void>;
  readonly loading: boolean;
}

export const UserProfilePension = ({ userId, employeePension, onEmployeeUpdate, loading }: Props): JSX.Element => {
  const [isEnrolDrawerOpen, setIsEnrolDrawerOpen] = useState<boolean>(false);

  const { hasScopes } = useScopes();
  const hasPensionScope = hasScopes(['pension'], { userId });

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2 }}>Pension</Typography>}
        settingsAction={() => setIsEnrolDrawerOpen(true)}
        settingsHide={!hasPensionScope}
        showBack
        backPath={generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId })}
      />
      <PensionMe
        userId={userId}
        employeePension={employeePension}
        onEmployeeUpdate={onEmployeeUpdate}
        isEnrolDrawerOpen={isEnrolDrawerOpen}
        setIsEnrolDrawerOpen={setIsEnrolDrawerOpen}
        loading={loading}
      />
    </RootStyle>
  );
};
