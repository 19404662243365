import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MissingFieldsDetail } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { CompensationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshPayroll?: () => Promise<void>;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingCompensation = ({ userId, refreshPayroll, missingFieldsForCategory }: Props) => {
  const { polyglot } = usePolyglot();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Stack sx={{ flexFlow: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Typography sx={themeFonts.caption}>{polyglot.t('MissingCompensation.compensation')}</Typography>
          <IconButton colorVariant="secondary" sizeVariant="small" onClick={() => setIsModalOpen(true)}>
            <Plus width="14px" height="14px" />
          </IconButton>
        </Box>
        {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      </Stack>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        <CompensationForm
          initialValues={null}
          userId={userId}
          getUserCompensation={() => Promise.resolve()}
          rowModalMode="add"
          handleSubmit={() => refreshPayroll?.()}
          setFormCurrentlyEditing={() => {}}
          setIsModalOpen={setIsModalOpen}
          loading={loading}
          setLoading={setLoading}
          onClose={() => setIsModalOpen(false)}
          showEmployee
        />
      </DrawerModal>
    </>
  );
};
