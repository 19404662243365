import { useContext, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, DEVICES_ME_DEVICE_SECURITY_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { IconContentActionCard } from '@/v2/components/theme-components/icon-content-action-card.component';
import { DeviceTransitDto } from '@/v2/feature/device/device.dto';
import { getModelImage, getTransitStatus } from '@/v2/feature/device/device.util';
import { DeviceReturnDrawer } from '@/v2/feature/device/features/devices-company/components/device-return-drawer.component';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MyDevicesReturns = ({
  deviceTransits,
  refreshDevicesData,
  userId,
}: {
  deviceTransits: DeviceTransitDto[];
  refreshDevicesData: () => Promise<void>;
  userId: number;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedDeviceTransit, setSelectedDeviceTransit] = useState<DeviceTransitDto | undefined>(undefined);
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const currentUserId = globalState.user.userId;

  const hasDevicesAll = useMemo(
    () => checkScopes(globalState.user, ['devices:all'], { userId: globalState.user.userId }),
    [globalState.user]
  );
  // Use this when we'll have the devices team view
  // const hasDevicesManager = useMemo(() => checkScope(globalState.user, 'devices:manager', { userId }), [
  //   globalState.user,
  //   userId,
  // ]);

  return (
    <Box>
      <Typography sx={themeFonts.title2}>Return</Typography>

      <Box sx={{ ...spacing.mt20 }}>
        {deviceTransits.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
            {deviceTransits.map((d, idx) => (
              <IconContentActionCard
                iconMedia={
                  d &&
                  getModelImage(d?.device?.type, d?.device?.modelName, {
                    width: '150px',
                    height: 'auto',
                  })
                }
                title={d.device?.type}
                content={
                  <Box sx={{ mt: spacing.m5, display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                    {d.device?.modelName && <Typography sx={themeFonts.caption}>{d.device?.modelName}</Typography>}
                    <Typography sx={themeFonts.caption}>{getTransitStatus(d.status)}</Typography>
                  </Box>
                }
                action={
                  <Button
                    sx={secondaryCTABtn}
                    onClick={() => {
                      setSelectedDeviceTransit(d);
                      setIsDrawerOpen(true);
                    }}
                  >
                    Details
                  </Button>
                }
                showDivider={idx < deviceTransits.length - 1}
              />
            ))}
          </Box>
        ) : (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            There are no active returns or handovers at the moment
          </Typography>
        )}
      </Box>

      {selectedDeviceTransit && (
        <DrawerModal
          setIsOpen={setIsDrawerOpen}
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedDeviceTransit(undefined);
          }}
          showExpand
          expandAction={
            currentUserId === userId || hasDevicesAll
              ? () => {
                  if (currentUserId === userId)
                    routerHistory.push(
                      generatePath(DEVICES_ME_DEVICE_SECURITY_ROUTE, {
                        devicePossessionId: selectedDeviceTransit.senderId,
                      })
                    );
                  // else if (hasDevicesManager) redirect to devices team view details
                  else if (hasDevicesAll) {
                    generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, {
                      devicePossessionId: selectedDeviceTransit.senderId,
                    });
                  }
                }
              : undefined
          }
        >
          <DeviceReturnDrawer
            selectedDeviceTransit={selectedDeviceTransit}
            refresh={async () => {
              setIsDrawerOpen(false);
              setSelectedDeviceTransit(undefined);
              await refreshDevicesData();
            }}
            reach="me"
          />
        </DrawerModal>
      )}
    </Box>
  );
};
