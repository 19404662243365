import React, { useContext } from 'react';

import { DeviceCompanyRouter } from '@v2/feature/device/device-company.router';
import { DevicesMeRouter } from '@v2/feature/device/devices-me.router';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  DEVICES_ACTIVATION_ROUTE,
  DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE,
  DEVICES_COMPANY_ROUTE,
  DEVICES_ME_ORDERS_ACTIVATION_ROUTE,
  DEVICES_ME_ROUTE,
  TECH_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';

export const DeviceRouter = (): JSX.Element => {
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  return (
    <Switch>
      <RouteScopesControl scopes={['devices']} context={scopesContext} path={DEVICES_ACTIVATION_ROUTE}>
        {checkScopes(globalState.user, ['devices:all']) ? (
          <Redirect to={DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE} />
        ) : (
          <Redirect to={DEVICES_ME_ORDERS_ACTIVATION_ROUTE} />
        )}
      </RouteScopesControl>
      <RouteScopesControl
        scopes={['devices:all', 'devices.enroll']}
        context={scopesContext}
        path={DEVICES_COMPANY_ROUTE}
      >
        <DeviceCompanyRouter />
      </RouteScopesControl>
      <RouteScopesControl scopes={['devices', 'devices.enroll']} context={scopesContext} path={DEVICES_ME_ROUTE}>
        <DevicesMeRouter />
      </RouteScopesControl>
      <RouteScopesControl scopes={['devices']} context={scopesContext} path={TECH_ROUTE}>
        <Redirect to={DEVICES_ME_ROUTE} />
      </RouteScopesControl>
    </Switch>
  );
};
