import { useEffect, useMemo, useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { getAllUsersCurrentRecordsEntitiesNames } from '@v2/feature/reports/util/people-report.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';
import Polyglot from 'node-polyglot';

import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import {
  DateType,
  FormDataInterface,
  reportTypeArray,
  ReportTypePeopleSelection,
} from '@/v2/feature/reports/reports.interface';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const TabFilter = (polyglot: Polyglot) => {
  return [
    { name: polyglot.t('TabFilter.thirtyDays'), value: DateType.Last30days },
    { name: polyglot.t('TabFilter.sevenDays'), value: DateType.Last7days },
    { name: polyglot.t('TabFilter.select'), value: DateType.SelectDates },
  ];
};

export const PeopleReportType = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  formData: FormDataInterface;
}) => {
  const { polyglot } = usePolyglot();

  const [filterString, setFilterString] = useState<string>(DateType.Last30days);

  const { hasScopes } = useScopes();

  const CurrentRecordsEntities = useMemo(() => {
    return getAllUsersCurrentRecordsEntitiesNames(hasScopes);
  }, [hasScopes]);

  // When loading the type selection page, initialize entities with the values of CurrentRecords report type
  useEffect(() => {
    if (formData.type === ReportTypePeopleSelection.CurrentRecords)
      setFormData((prevForm) => ({ ...prevForm, entities: CurrentRecordsEntities }));
  }, [setFormData, CurrentRecordsEntities, formData.type]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography sx={themeFonts.title2}>{polyglot.t('PeopleReportType.select')}</Typography>

        <FormControl sx={{ width: '100%', ...spacing.mt20 }}>
          <RadioGroup
            value={formData.type}
            onChange={(event) => {
              const type = event.target.value as ReportTypePeopleSelection;

              setFormData((prevForm) => ({
                ...prevForm,
                type,
                entities: type === ReportTypePeopleSelection.ChangeReport ? [] : CurrentRecordsEntities,
              }));
            }}
            sx={{ flexFlow: 'column' }}
          >
            {reportTypeArray(polyglot).map((d, idx) => (
              <FormControlLabel
                key={idx}
                labelPlacement="start"
                value={d.name}
                control={<StyledRadio />}
                disabled={false}
                label={
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                    <Typography sx={{ ...themeFonts.caption }}>{d.label}</Typography>
                    <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>{d.desc}</Typography>
                  </Box>
                }
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: idx < reportTypeArray(polyglot).length - 1 ? borders.background : 'none',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {formData.type === ReportTypePeopleSelection.ChangeReport && (
          <Box sx={{ mt: spacing.m10 }}>
            <TabFilterButtons
              filters={TabFilter(polyglot)}
              setFilterValue={setFilterString}
              filterValue={filterString}
              onFilterChange={({ filterValue }) => {
                if (filterValue === DateType.Last30days) {
                  setFormData({
                    ...formData,
                    start: new LocalDate(subDays(new Date(), 30)).toDateString(),
                    end: new LocalDate().toDateString(),
                    dateType: DateType.Last30days,
                  });
                } else if (filterValue === DateType.Last7days) {
                  setFormData({
                    ...formData,
                    start: new LocalDate(subDays(new Date(), 7)).toDateString(),
                    end: new LocalDate().toDateString(),
                    dateType: DateType.Last7days,
                  });
                } else {
                  setFormData({
                    ...formData,
                    dateType: DateType.SelectDates,
                  });
                }

                setFilterString(filterValue);
              }}
            />
          </Box>
        )}

        {filterString === DateType.SelectDates && formData.type === ReportTypePeopleSelection.ChangeReport && (
          <Box sx={{ ...spacing.mt20 }}>
            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formData.start}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      setFormData({ ...formData, start: value });
                    }
                  }}
                  name="startDate"
                  label={polyglot.t('PeopleReportType.startDate')}
                />
              </FormControl>
            </Box>

            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formData.end}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      setFormData({ ...formData, end: value });
                    }
                  }}
                  name="endDate"
                  label={polyglot.t('PeopleReportType.endDate')}
                />
              </FormControl>
            </Box>
          </Box>
        )}

        <ButtonComponent
          sizeVariant="large"
          colorVariant="primary"
          style={{ marginTop: spacing.m25 }}
          onClick={onNext}
          fullWidth
        >
          {polyglot.t('General.next')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
