import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { StepperComponent } from '@v2/components/stepper.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceActivationStepActivation } from '@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-activation.component';
import { DeviceActivationStepCharger } from '@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-charger.component';
import { DeviceActivationStepCredentials } from '@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-credentials.component';
import { DeviceActivationStepPackaging } from '@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-packaging.component';
import { DeviceActivationStepTLC } from '@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-tlc.component';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

enum ActivateDeviceSteps {
  Activation = 'Activation',
  Credentials = 'Credentials',
  Packaging = 'Packaging',
  Charger = 'Charger',
  TLC = 'TLC',
}

const ACTIVATE_DEVICE_STEPS = [
  ActivateDeviceSteps.Activation,
  ActivateDeviceSteps.Credentials,
  ActivateDeviceSteps.Packaging,
  ActivateDeviceSteps.Charger,
  ActivateDeviceSteps.TLC,
];

export const ConfirmDeviceOrderDeliveryStepper = () => {
  const params = useParams<{ readonly orderId: string }>();
  const orderId = Number(params.orderId);
  const [showMessage] = useMessage();
  const [deviceOrder, setDeviceOrder] = useState<DeviceOrderDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [step, setStep] = useState<ActivateDeviceSteps>(ActivateDeviceSteps.Activation);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const deviceOrder = await DeviceAPI.getDeviceOrderDetails(orderId);
        setDeviceOrder(deviceOrder);
      } catch (error) {
        showMessage(`Could not load device order. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [orderId, showMessage]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Device activation</Typography>}
      />

      <ContentWrapper loading={loading}>
        <Box sx={{ width: '100%' }}>
          <StepperComponent steps={ACTIVATE_DEVICE_STEPS} activeStep={step} />
        </Box>

        {deviceOrder && <Box>{getStepContent(deviceOrder, step, setStep)}</Box>}
      </ContentWrapper>
    </RootStyle>
  );
};

const getStepContent = (
  deviceOrder: DeviceOrderDto,
  step: ActivateDeviceSteps,
  setStep: Dispatch<SetStateAction<ActivateDeviceSteps>>
) => {
  switch (step) {
    case ActivateDeviceSteps.Activation:
      return (
        <DeviceActivationStepActivation
          deviceOrder={deviceOrder}
          goToNextStep={() => setStep(ActivateDeviceSteps.Credentials)}
        />
      );

    case ActivateDeviceSteps.Credentials:
      return (
        <DeviceActivationStepCredentials
          deviceOrder={deviceOrder}
          goToNextStep={() => setStep(ActivateDeviceSteps.Packaging)}
        />
      );

    case ActivateDeviceSteps.Packaging:
      return <DeviceActivationStepPackaging goToNextStep={() => setStep(ActivateDeviceSteps.Charger)} />;

    case ActivateDeviceSteps.Charger:
      return <DeviceActivationStepCharger goToNextStep={() => setStep(ActivateDeviceSteps.TLC)} />;

    case ActivateDeviceSteps.TLC:
      return <DeviceActivationStepTLC deviceOrder={deviceOrder} />;

    default:
      return (
        <DeviceActivationStepActivation
          deviceOrder={deviceOrder}
          goToNextStep={() => setStep(ActivateDeviceSteps.Credentials)}
        />
      );
  }
};
