import React from 'react';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  href: string;
  openInNewWindow?: boolean;
  style?: React.CSSProperties;
  title: string;
};

export const InlineLink = ({ href, title, openInNewWindow, style }: Props) => {
  const resultStyle: React.CSSProperties = {
    ...themeFonts.caption,
    color: themeColors.ZeltYellow,
    textDecoration: 'none',
    ...style,
  };
  return openInNewWindow ? (
    <a href={href} target="_blank" rel="noopener noreferrer" style={resultStyle}>
      {title}
    </a>
  ) : (
    <a href={href} style={resultStyle}>
      {title}
    </a>
  );
};
