import { PolicyPayrollEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-payroll-edit-drawer.component';
import { KeyedMutator } from 'swr';

import { AbsencePolicyDto } from '@/v2/feature/absence/absence.dto';
import { AttendanceScheduleDto } from '@/v2/feature/attendance/attendance.dto';
import { AttendanceScheduleEditPayrollDrawer } from '@/v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-payroll-drawer.component';
import { EditCustomBenefitPaymentDrawer } from '@/v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-payment-drawer.component';
import { PaymentSettingsNewTypeDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component';
import { PaymentTypeSettingAccountingAppConfig } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  integration: PayrollIntegration | null;
  refreshAbsences: KeyedMutator<AbsencePolicyDto[]> | undefined;
  refreshAttendances: KeyedMutator<AttendanceScheduleDto[]> | undefined;
  refreshBenefits: () => Promise<void>;
  refreshExpenses: KeyedMutator<PaymentTypeSettingAccountingAppConfig> | undefined;
};

export const PayrollIntegrationSettingsEditDrawer = ({
  isOpen,
  setIsOpen,
  integration,
  refreshAbsences,
  refreshAttendances,
  refreshBenefits,
  refreshExpenses,
}: Props) => {
  return (
    <>
      {integration?.kind === 'absence' && (
        <PolicyPayrollEditDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          absencePolicy={integration.absencePolicy}
          refresh={async () => {
            await refreshAbsences?.();
          }}
        />
      )}
      {integration?.kind === 'attendance' && (
        <AttendanceScheduleEditPayrollDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          attendanceSchedule={integration.attendanceSchedule}
          refresh={async () => {
            await refreshAttendances?.();
          }}
        />
      )}
      {integration?.kind === 'benefit' && (
        <EditCustomBenefitPaymentDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          customBenefit={integration.benefit}
          refresh={refreshBenefits}
        />
      )}
      {integration?.kind === 'expense' && (
        <PaymentSettingsNewTypeDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          typeForEdit={integration.expense}
          editMode="payment"
          refreshAllSettings={refreshExpenses}
        />
      )}
    </>
  );
};
