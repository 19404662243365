import React, { forwardRef } from 'react';

import {
  Autocomplete,
  Box,
  FilterOptionsState,
  ListSubheader,
  Popper,
  Typography,
  UseAutocompleteProps,
  autocompleteClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const LISTBOX_PADDING = 8; // px

export interface OptionObj {
  readonly value: number | string | 'public-org'; // userid or everyone
  readonly label: string;
}

interface Props {
  readonly id: string;
  readonly limitTags?: number;
  readonly options: readonly OptionObj[];
  readonly value: OptionObj[];
  readonly onChange: UseAutocompleteProps<OptionObj, true, undefined, undefined>['onChange'];
  readonly isOptionEqualToValue: (x: OptionObj, y: OptionObj) => boolean;
  readonly getOptionLabel: (option: OptionObj) => string;
  readonly label?: string;
  readonly placeholder?: string;
  readonly error?: boolean;
  readonly required?: boolean;
  readonly helperText?: React.ReactNode;
}
const iconSize = { width: '14px', height: '14px' } as const;
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  const user = dataSet[1];
  const description = () => {
    if (
      user?.role?.jobTitle &&
      user?.role?.jobTitle?.length > 0 &&
      user?.role?.site?.name &&
      user?.role?.site?.name?.length > 0
    ) {
      return [user?.role?.jobTitle, user?.role?.site?.name].join(', ');
    } else {
      if (user?.role?.jobTitle && user?.role?.jobTitle?.length > 0) return user?.role?.jobTitle;
      else return user?.role?.site?.name;
    }
  };
  return (
    <Box component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Box key={user.value} sx={{ display: 'block', minHeight: 20, paddingY: spacing.p5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {user.value !== 'public-org' && <UserAvatar userId={user.value!} size="xxsmall" />}
          <Box sx={{ marginLeft: spacing.m10, minHeight: 20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{user.label}</Typography>
          </Box>
        </Box>

        <Box sx={{ marginLeft: spacing.m30, display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              ...themeFonts.captionSmall,
              marginTop: spacing.m5,
              color: themeColors.Grey,
              textTransform: 'capitalize',
            }}
          >
            {description()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 60 : 60;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});
export const MultiUserSelect = forwardRef(
  (
    {
      id,
      limitTags,
      options,
      value,
      onChange,
      isOptionEqualToValue,
      getOptionLabel,
      label,
      placeholder,
      error,
      helperText,
      required,
    }: Props,
    ref
  ) => {
    return (
      <Autocomplete
        id={id}
        ref={ref}
        multiple
        fullWidth
        limitTags={limitTags}
        value={value}
        options={options}
        disableCloseOnSelect={true}
        onChange={onChange}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <StyledAutoTextfield
            {...params}
            label={label}
            fullWidth
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            name={id}
            error={error}
            helperText={helperText}
            required={required}
          />
        )}
        renderOption={(props, option, state) => {
          return [props, option, state.inputValue] as React.ReactNode;
        }}
        renderTags={(options) => {
          return (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {options.map((v) =>
                v.value === 'public-org' ? (
                  <Typography sx={{ ...themeFonts.title3, color: themeColors.DarkGrey }}>Everyone</Typography>
                ) : (
                  <UserAvatar key={v.value} userId={+v.value} tooltip tooltipText={v.label} size="xxsmall" />
                )
              )}
            </Box>
          );
        }}
        ListboxComponent={ListboxComponent}
        PopperComponent={StyledPopper}
        popupIcon={<ArrowDownACIcon {...iconSize} />}
        clearIcon={<ClearIcon {...iconSize} />}
        filterOptions={(options: OptionObj[], state: FilterOptionsState<OptionObj>) => {
          return options.filter((o) => o.label?.toLowerCase().includes(state.inputValue?.toLowerCase()));
        }}
      />
    );
  }
);
