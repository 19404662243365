import Polyglot from 'node-polyglot';

import { OptionObject } from '@/v2/components/forms/select.component';
import { AppIntegrationUserDto } from '@/v2/feature/app-integration/app-integration.dto';
import { MFAType } from '@/v2/feature/auth/auth.dto';
import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { DeviceOrderDto, DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';

export function displayUserName(u: Pick<UserSummaryDto, 'displayName' | 'firstName' | 'lastName'>): string {
  return u.displayName ? u.displayName : `${u.firstName} ${u.lastName}`;
}

export interface BasicInformationValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly middleName?: string | null;
  readonly fullName?: string;
  readonly displayName?: string;
  readonly emailAddress: string;
  readonly customUpdates: UserCustomDataDto[];
}

export const PERSONAL_INFORMATION_GENDERS = ['male', 'female', 'non-binary'] as const;
export type PersonalInformationGender = typeof PERSONAL_INFORMATION_GENDERS[number];

export const getGenderOptions = (polyglot: Polyglot) => {
  return [
    { value: 'male', label: polyglot.t('getGenderOptions.male') },
    { value: 'female', label: polyglot.t('getGenderOptions.female') },
    { value: 'non-binary', label: polyglot.t('getGenderOptions.nonBinary') },
  ];
};

export const ABOUT_INFORMATION_DIETARY = [
  'dairy-free',
  'diabetes',
  'gluten-free',
  'keto',
  'kosher',
  'lactose-intolerant',
  'nut-allergies',
  'pescatarian',
  'vegan',
  'vegetarian',
] as const;
type Dietary = {
  value: string;
  label: string;
};

export const getDietaryOptions = (polyglot: Polyglot) => {
  return [
    { value: 'dairy-free', label: polyglot.t('getDietaroyOptions.dairyFree') },
    { value: 'diabetes', label: polyglot.t('getDietaroyOptions.diabetes') },
    { value: 'gluten-free', label: polyglot.t('getDietaroyOptions.glutenFree') },
    { value: 'keto', label: polyglot.t('getDietaroyOptions.keto') },
    { value: 'kosher', label: polyglot.t('getDietaroyOptions.kosher') },
    { value: 'lactose-intolerant', label: polyglot.t('getDietaroyOptions.lactoseIntolerant') },
    { value: 'nut-allergies', label: polyglot.t('getDietaroyOptions.nutAllergies') },
    { value: 'pescatarian', label: polyglot.t('getDietaroyOptions.pescatarian') },
    { value: 'vegan', label: polyglot.t('getDietaroyOptions.vegan') },
    { value: 'vegetarian', label: polyglot.t('getDietaroyOptions.vegetarian') },
  ] as Dietary[];
};
type DietaryOptionsReturnType = ReturnType<typeof getDietaryOptions>;
export type DietaryOption = DietaryOptionsReturnType[number]['value'];

export enum DietaryOptionsStatuses {
  DAIRYFREE = 'dairy-free',
  DIABETES = 'diabetes',
  GLUTENFREE = 'gluten-free',
  KETO = 'keto',
  KOSHER = 'kosher',
  LACTOSEINTOLERANT = 'lactose-intolerant',
  NUTALLERGIES = 'nut-allergies',
  PESCATARIAN = 'pescatarian',
  VEAGN = 'vegan',
  VEGETARIAN = 'vegetarian',
}

export interface PersonalInformationValues {
  readonly dob?: string | null;
  readonly gender?: PersonalInformationGender | null;
  readonly nationality?: string | null;
  readonly personalEmail?: string | null;
  readonly phone?: string | null;
  readonly passportNumber?: string | null;
  readonly customUpdates: UserCustomDataDto[];
}

const getNewEmployeeOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'new employee', label: polyglot.t('getNewEmployeeOption.label') };
};
const getResignationOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'resignation', label: polyglot.t('getResignationOption.label') };
};
const getPerformanceOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'performance', label: polyglot.t('getPerformanceOption.label') };
};
const getLocationOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'location', label: polyglot.t('getLocationOption.label') };
};
const getSalaryOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'salary', label: polyglot.t('getSalaryOption.label') };
};
const getCultureOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'culture', label: polyglot.t('getCultureOption.label') };
};
const getCareerProgressionOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'career progression', label: polyglot.t('getCareerProgressionOption.label') };
};
const getPersonalOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'personal', label: polyglot.t('getPersonalOption.label') };
};
const getMisconductOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'misconduct', label: polyglot.t('getMisconductOption.label') };
};
const getContractEndedOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'contract-ended', label: polyglot.t('getContractEndedOption.label') };
};
const getRedundancyOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'redundancy', label: polyglot.t('getRedundancyOption.label') };
};
const getNoticeGivenOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'notice given', label: polyglot.t('getNoticeGivenOption.label') };
};
const getRetirementOption = (polyglot: Polyglot): OptionObject => {
  return { value: 'retirement', label: polyglot.t('getRetirementOption.label') };
};

const getDidNotMeetExpectationOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'did not meet expectations',
    label: polyglot.t('getDidNotMeetExpectationOption.label'),
  };
};
const getSabbaticalOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'sabbatical',
    label: polyglot.t('getSabbaticalOption.label'),
  };
};

const getParentalLeaveOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'parental leave',
    label: polyglot.t('getParentalLeaveOption.label'),
  };
};

const getGardenLeaveOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'garden leave',
    label: polyglot.t('getGardenLeaveOption.label'),
  };
};

const getImportedOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'Imported',
    label: polyglot.t('getImportedOption.label'),
  };
};

export const getRehiredOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'rehired',
    label: polyglot.t('getRehiredOption.label'),
  };
};

export const getOtherOption = (polyglot: Polyglot): OptionObject => {
  return {
    value: 'other',
    label: polyglot.t('getOtherOption.label'),
  };
};

export const getAllEmployedOptions = (polyglot: Polyglot): OptionObject[] => {
  return [getNewEmployeeOption(polyglot), getRehiredOption(polyglot), getImportedOption(polyglot)];
};
export const getAllLeaveOptions = (polyglot: Polyglot): OptionObject[] => {
  return [getSabbaticalOption(polyglot), getParentalLeaveOption(polyglot), getGardenLeaveOption(polyglot)];
};
export const getAllDefaultTerminationChangeReasonOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    getResignationOption(polyglot),
    getRedundancyOption(polyglot),
    getNoticeGivenOption(polyglot),
    getDidNotMeetExpectationOption(polyglot),
    getRetirementOption(polyglot),
    getOtherOption(polyglot),
    getPerformanceOption(polyglot),
    getLocationOption(polyglot),
    getSalaryOption(polyglot),
    getCultureOption(polyglot),
    getCareerProgressionOption(polyglot),
    getPersonalOption(polyglot),
    getMisconductOption(polyglot),
    getContractEndedOption(polyglot),
  ];
};

export const getAllowancesUnits = (polyglot: Polyglot): OptionObject[] => {
  return [
    { value: 'day', label: polyglot.t('AllowanceType.days') },
    { value: 'hour', label: polyglot.t('AllowanceType.hours') },
  ];
};

export const getAllChangeTypeOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    ...getAllEmployedOptions(polyglot),
    ...getAllLeaveOptions(polyglot),
    ...getAllDefaultTerminationChangeReasonOptions(polyglot),
  ];
};

export const getRoleLastChangeTypeOptions = (polyglot: Polyglot) => {
  return [
    getNewEmployeeOption(polyglot),
    { value: 'promotion', label: polyglot.t('getRoleLastChangeTypeOptions.promotion') },
    { value: 'resignation', label: polyglot.t('getRoleLastChangeTypeOptions.resignation') },
    { value: 'lateral move', label: polyglot.t('getRoleLastChangeTypeOptions.lateralMove') },
    { value: 'rotation', label: polyglot.t('getRoleLastChangeTypeOptions.rotation') },
    { value: 'demotion', label: polyglot.t('getRoleLastChangeTypeOptions.demotion') },
    { value: 'transfer', label: polyglot.t('getRoleLastChangeTypeOptions.transfer') },
    { value: 'manager change', label: polyglot.t('getRoleLastChangeTypeOptions.managerChange') },
    { value: 'reorganisation', label: polyglot.t('getRoleLastChangeTypeOptions.reorganisation') },
    { value: 'correction', label: polyglot.t('getRoleLastChangeTypeOptions.correction') },
    getRehiredOption(polyglot),
    { value: 'other', label: polyglot.t('getRoleLastChangeTypeOptions.other') },
  ];
};

export interface AboutValues {
  readonly about: string | null;
  readonly hobbies: string | null;
  readonly social: string | null;
  readonly foodPreferences: string | null;
  readonly dietaryRestrictions: string | null;
  readonly customUpdates: UserCustomDataDto[];
}

export interface EmergencyContactValues {
  readonly emergencyName?: string | null;
  readonly emergencyRelationship?: string | null;
  readonly emergencyNumber?: string | null;
  readonly customUpdates?: UserCustomDataDto[];
}

export interface UserImportLifecycleValues {
  readonly startDate: string;
  readonly leaveDate?: string;
  readonly changeReason: string;
  readonly customUpdates: UserCustomDataDto[];
}

export interface FamilyValues {
  readonly maritalStatus: MaritalStatus | null;
  readonly customUpdates: UserCustomDataDto[];
}

export interface WorkContactValues {
  readonly workMobile: string | null;
  readonly workPhone: string | null;
  readonly customUpdates: UserCustomDataDto[];
}

export const RIGHT_TO_WORK_DOCUMENT_TYPES = ['proofOfAddress', 'passportOrId', 'workPermitVisa'] as const;
export type RightToWorkDocumentType = typeof RIGHT_TO_WORK_DOCUMENT_TYPES[number];

export const getRightToWorkDocumentTypeOptions = (polyglot: Polyglot) => {
  return [
    { value: 'proofOfAddress', label: polyglot.t('getRightToWorkDocumentTypeOptions.proofOfAddress') },
    { value: 'passportOrId', label: polyglot.t('getRightToWorkDocumentTypeOptions.passportOrId') },
    { value: 'workPermitVisa', label: polyglot.t('getRightToWorkDocumentTypeOptions.workPermitVisa') },
  ];
};

export interface RightToWorkValues {
  readonly id?: number;
  readonly rightToWorkChecked: boolean | null;
  readonly rightToWorkCheckDate: string | null;
  readonly rightToWorkIsLimited: boolean | null;
  readonly rightToWorkExpiryDate: string | null;
  readonly rightToWorkDocumentTypes: RightToWorkDocumentType | null;
  readonly rightToWorkDocuments: string | null;
  readonly customUpdates?: UserCustomDataDto[];
}

export interface AccountInformationValues {
  readonly accountStatus: string;
  readonly activationDate?: string | null;
  readonly deactivationDate?: string | null;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly updatedBy: string;
  readonly lastTimeOnline?: Date | null;
  readonly mfaType?: MFAType | null;
  employeeId?: string;
}

export enum MaritalStatus {
  Married = 'married',
  Single = 'single',
  Partnership = 'civil-partnership',
  PreferNotToSay = 'prefer-not-to-say',
  Divorced = 'divorced',
  Widowed = 'widowed',
}

export const FAMILY_INFORMATION_MARITAL = Object.values(MaritalStatus);

export const getMaritalOptions = (polyglot: Polyglot) => {
  return [
    { value: MaritalStatus.Single, label: polyglot.t('getMaritalOptions.single') },
    { value: MaritalStatus.Married, label: polyglot.t('getMaritalOptions.married') },
    { value: MaritalStatus.Partnership, label: polyglot.t('getMaritalOptions.civilPartnership') },
    { value: MaritalStatus.Divorced, label: polyglot.t('getMaritalOptions.divorced') },
    { value: MaritalStatus.Widowed, label: polyglot.t('getMaritalOptions.widowed') },
    { value: MaritalStatus.PreferNotToSay, label: polyglot.t('getMaritalOptions.preferNotToSay') },
  ];
};

export interface UserDeletionChecks {
  assignedDevices: DevicePossessionDto[];
  activeDeviceOrders: DeviceOrderDto[];
  appAccounts: AppIntegrationUserDto[];
  payrollPayrunMembership: { inPayroll: boolean; inPayrun: boolean };
  isBillingContact: boolean;
  hasPayments: boolean;
}

export interface UserDeactivationChecks {
  assignedDevices: DevicePossessionDto[];
  activeDeviceOrders: DeviceOrderDto[];
}

export const PARTIALLY_COMPLETED_HELPER_TEXT =
  'You can preview preliminary results of some checks directly in Zinc. Once all checks are completed a report will be saved in Documents in Zelt';

export enum IdCheckStatusEnum {
  PENDING = 'pending',
  PARTIALLY_COMPLETED = 'partially-completed',
  COMPLETED = 'completed',
  UNKNOWN = 'unknown',
  CANCELLED = 'cancelled',
  ACTION_REQUIRED = 'action-required',
  REVIEWED = 'reviewed',
}

export const ID_CHECK_COMPLETED_STATES = new Set([
  IdCheckStatusEnum.COMPLETED,
  IdCheckStatusEnum.REVIEWED,
  IdCheckStatusEnum.ACTION_REQUIRED,
]);

export const BASE64_PDF_PREFIX = 'data:application/octet-stream;base64,';

export const MANUAL_RTW_CHECK = 'manual-rtw-check';
