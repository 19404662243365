import { useCallback, useContext } from 'react';

import { Menu, MenuItem, MenuProps, styled } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { GlobalContext } from '@/GlobalState';
import { USER_DETAILS_ROUTE } from '@/lib/routes';
import { performLogout } from '@/v2/feature/auth/auth.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  anchorEl?: HTMLElement | null;
  open: boolean;
  userId: number;
  onClose?(): void;
};

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 20px 0px rgba(13, 13, 14, 0.1)',
    textAlign: 'left',
    borderRadius: radius.br10,

    '& .MuiMenu-list': {
      ...spacing.pad20,
      gap: '50px',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      padding: '0px',
      minHeight: '20px',
      gap: spacing.g10,
      ...themeFonts.caption,
      fill: themeColors.DarkGrey,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        color: themeColors.Grey,
        fill: themeColors.Grey,
      },
    },
  },
}));

export const UserAvatarMenu = ({ anchorEl, open, onClose, userId }: Props) => {
  const { polyglot } = usePolyglot();
  const swrConfig = useSWRConfig();
  const routerHistory = useHistory();

  const goToUserProfile = useCallback(() => {
    (async () => {
      routerHistory.push(generatePath(USER_DETAILS_ROUTE, { userId }));
    })();
  }, [routerHistory, userId]);

  const [, dispatch] = useContext(GlobalContext);

  const closeMenu = useCallback(() => onClose?.(), [onClose]);

  const options = [
    { label: polyglot.t('UserAvatarMenu.profile'), handler: () => goToUserProfile(), disabled: false },
    {
      label: polyglot.t('UserAvatarMenu.logout'),
      handler: () => performLogout(routerHistory, dispatch, swrConfig),
      disabled: false,
    },
  ];

  return (
    <StyledMenu
      id={`btnMenuOptions`}
      MenuListProps={{
        'aria-labelledby': 'btnMenuOptions',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
      onClick={closeMenu}
      anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      transformOrigin={{ horizontal: 'left', vertical: 'center' }}
      disableAutoFocusItem
    >
      {options &&
        options.map((option, i) => (
          <MenuItem
            key={option.label}
            id={`btn${option.label}_${i}`}
            onClick={(e) => {
              option.handler();
              onClose?.();
              e.stopPropagation();
            }}
            disableRipple
            disabled={option?.disabled || false}
            sx={{ marginTop: i > 0 ? '10px' : 0 }}
          >
            {option.label}
          </MenuItem>
        ))}
    </StyledMenu>
  );
};
