import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PolicyApprovalFormData } from '@v2/feature/absence/absence.interface';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyApprovalEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refresh: () => Promise<void>;
  readonly refreshApprovalRules?: () => Promise<void>;
}

const getApprovalRulesOptions = (approvalRules: readonly { id: number; name: string }[]) =>
  approvalRules.map((rule) => ({
    label: rule.name,
    value: rule.id,
  }));

export const PolicyApprovalEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  approvalRules,
  refresh,
  refreshApprovalRules,
}: PolicyApprovalEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();

  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);

  const onSubmit = useCallback(
    async (values: PolicyApprovalFormData) => {
      const policyData: PolicyApprovalFormData = {
        backoffApprovalRuleId: values.backoffApprovalRuleId,
        updateApprovalNotRequired: values.updateApprovalNotRequired,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyApproval(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceDrawerPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );

  const formik = useFormik<PolicyApprovalFormData>({
    initialValues: {
      backoffApprovalRuleId: absencePolicy.backoffApprovalRuleId,
      updateApprovalNotRequired: absencePolicy.updateApprovalNotRequired,
    },
    validationSchema: yup.object({
      backoffApprovalRuleId: yup
        .number()
        .integer(polyglot.t('PolicyApprovalEditDrawer.selectValidField'))
        .typeError(polyglot.t('PolicyApprovalEditDrawer.selectValidField'))
        .required(polyglot.t('PolicyApprovalEditDrawer.selectValidField')),
      updateApprovalNotRequired: yup.boolean().required(polyglot.t('PolicyApprovalEditDrawer.requiredField')),
    }),
    onSubmit,
  });

  const autoApproveRule = useMemo(() => approvalRules.find((rule) => rule.name === 'Auto approve'), [approvalRules]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('PolicyApprovalEditDrawer.editApproval')}</Typography>
          <SelectComponent
            name="backoffApprovalRuleId"
            label={polyglot.t('EditPolicyApprovalDrawer.backoffApprovalRuleId')}
            options={getApprovalRulesOptions(approvalRules)}
            value={formik.values.backoffApprovalRuleId}
            onChange={formik.handleChange}
            compareValue={formik.values.backoffApprovalRuleId}
            error={!!formik.errors.backoffApprovalRuleId && formik.touched.backoffApprovalRuleId}
            helperText={formik.touched.backoffApprovalRuleId && (formik.errors.backoffApprovalRuleId as string)}
            editList={{
              isHidden: !hasScopes(['company.settings:all']),
              handler: () => {
                setIsApprovalDrawerOpen(true);
              },
            }}
          />
          {formik.values.backoffApprovalRuleId !== autoApproveRule?.id && (
            <CheckboxComponent
              label={polyglot.t('EditPolicyApprovalDrawer.updateApprovalNotRequired')}
              name="updateApprovalNotRequired"
              checked={!formik.values.updateApprovalNotRequired}
              onChange={(_, checked) => {
                formik.setFieldValue('updateApprovalNotRequired', !checked);
              }}
            />
          )}
          <ApprovalRuleDrawer
            isOpen={isApprovalDrawerOpen}
            setIsOpen={setIsApprovalDrawerOpen}
            approvalRule={null}
            afterClose={() => {
              setIsApprovalDrawerOpen(false);
            }}
            refresh={async () => {
              if (refreshApprovalRules) await refreshApprovalRules();
            }}
          />
          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
