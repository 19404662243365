import { Box } from '@mui/material';
import { addDays } from 'date-fns';
import { capitalize } from 'lodash';

import { ReactComponent as Inprogress } from '@/images/fields/Inprogress.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { EntryState, SurveyEntry } from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { borders } from '@/v2/styles/borders.styles';
import { styledChipSx } from '@/v2/styles/chip.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const getSurveyDueDate = (entry: SurveyEntry): LocalDate => {
  return entry.cycle && entry.cycle?.timelineSettings && entry.cycle?.timelineSettings?.startDate
    ? new LocalDate(
        addDays(new Date(entry.cycle?.timelineSettings?.startDate), entry.cycle?.timelineSettings?.surveyOpenFor)
      )
    : new LocalDate();
};

export const getEntryStatus = () => {
  return {
    [EntryState.NotStarted]: getStatusChip('Not started', <Waiting {...iconSize} style={{ fill: themeColors.Grey }} />),
    [EntryState.Draft]: getStatusChip('In progress', <Inprogress {...iconSize} />),
    [EntryState.Submitted]: getStatusChip(
      EntryState.Submitted,
      <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
    ),
  };
};

export const getStatusChip = (label: string, icon: JSX.Element) => (
  <Box
    sx={{
      ...styledChipSx,
      background: themeColors.white,
      border: borders.background,
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: spacing.g4,
    }}
  >
    {icon}
    <Typography variant="caption" color="Grey">
      {capitalize(label)}
    </Typography>
  </Box>
);
