import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { getActionDate } from '@v2/feature/offboarding/offboarding.util';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { isPast } from 'date-fns';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { isToday } from '@/lib/date-time.lib';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import {
  OptionObj,
  OptionsProps,
  SingleUserSelect,
  UserIdOption,
} from '@/v2/components/forms/user-select/single-user-select.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationAPI, AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppCreateUserParams,
  AppIntegrationUserDto,
  AppIntegrationUserEmailDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APP_ACTION_DRAWER_MODES,
  APP_ACTION_WHEN_OPTIONS,
  AppActionWhenTabFilter,
  AppActionWhenValueEnum,
  AppIntegrationStub,
  APPS_NOT_REQUIRING_REFRESH_POST_ACTION,
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION,
  appStubToName,
  CountryListing,
  CurrentlyDelayedJobs,
  REFRESH_DELAY_APP_USER_LIST,
} from '@/v2/feature/app-integration/app-integration.interface';
import { JumpCloudNewUserParams } from '@/v2/feature/app-integration/apps/jumpcloud/components/jumpcloud-new-user-params-modal.component';
import {
  APPS_NEEDING_EMAIL_FOR_DELETION,
  APPS_WITHOUT_DELETE_OPTION,
  emailOptionsForUserCreation,
  getActionNameForApp,
  getEmailAddressForMissingId,
  hasActiveEmailForAccount,
} from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import {
  buttonBoxSx,
  fieldSx,
  titleSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { ContractorTypes } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { StyledFormCheckbox } from '@/v2/styles/checkbox.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface FormData {
  readonly userId?: number | string;
  readonly countryForEmployment?: string;
  readonly workspaceTransferThenDelete?: boolean;
  readonly transferDataSourceUser?: number | string;
  transferDataTargetUserOptionObj?: UserIdOption;
  readonly transferDataTargetUser?: number;
  readonly whenAction?: string;
  readonly actionDate?: string;
  readonly emailSelectorRequired?: boolean;
  readonly selectedEmailForUserCreation?: string;
}

type AppStub = 'jumpcloud' | 'monday' | 'google-workspace' | 'github';

interface AppActionsDrawerPageProps {
  appStub: AppStub | AppIntegrationStub;
  preselectedUser?: AppIntegrationUserDto;
  readonly countriesForEmployment?: CountryListing[];
  readonly usersWithoutAccess: readonly AppIntegrationUserDto[];
  readonly usersWithAccess?: readonly AppIntegrationUserDto[];
  readonly selectedActiveUserLogin?: string;
  readonly setSelectedActiveUserLogin?: Dispatch<SetStateAction<string | undefined>>;
  readonly refreshApp: VoidFunction;
  refreshDelayedActions?: KeyedMutator<CurrentlyDelayedJobs<CreateAppQueue>> | undefined;
  readonly markTeamUserPending?: (userId: number) => void;
  readonly closePage: () => void;
  readonly mode?: APP_ACTION_DRAWER_MODES;
  readonly directoryMode?: 'team' | 'external' | 'employments' | 'candidates';
}

export const AppActionsDrawerPage = ({
  appStub,
  selectedActiveUserLogin,
  setSelectedActiveUserLogin,
  usersWithoutAccess,
  usersWithAccess,
  preselectedUser = undefined,
  countriesForEmployment,
  refreshApp,
  refreshDelayedActions,
  markTeamUserPending,
  directoryMode = 'team',
  closePage,
  mode = APP_ACTION_DRAWER_MODES.create,
}: AppActionsDrawerPageProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userId, setUserId] = useState<string | number | undefined>(undefined);
  const today = new LocalDate().toDateString();
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const [noPersonalEmailSoUserCreationDisabled, setNoPersonalEmailSoUserCreationDisabled] = useState(false);
  const [employmentUserStartDateInThePast, setEmploymentUserStartDateInThePast] = useState(false);
  const usersWithoutAccessOptions = useMemo<OptionsProps[]>(
    () =>
      nonTerminatedCachedUsers
        .filter((user) => usersWithoutAccess.some((u) => u.userId === user.userId))
        .map((u) => {
          return { label: u.displayName, value: u.userId, ...u };
        }),
    [nonTerminatedCachedUsers, usersWithoutAccess]
  );
  const usersWithAccessOptions = useMemo<OptionsProps[]>(
    () =>
      usersWithAccess && usersWithAccess.length > 0
        ? nonTerminatedCachedUsers
            .filter((user) => usersWithAccess.some((u) => u.userId === user.userId && u.userId !== userId))
            .map((u) => {
              return { label: u.displayName, value: u.userId, ...u };
            })
        : [],
    [nonTerminatedCachedUsers, userId, usersWithAccess]
  );
  const externalUserAccessOptions = useMemo<OptionObj[]>(
    () =>
      usersWithAccess && usersWithAccess.length > 0
        ? (usersWithAccess
            .filter((eachUser) => eachUser.id !== preselectedUser?.id)
            .map((u) => {
              return { label: u.displayName, value: u.id };
            }) as OptionObj[])
        : [],
    [preselectedUser?.id, usersWithAccess]
  );
  const contractorAndEORUserOptions = useMemo<OptionObj[]>(
    () =>
      nonTerminatedCachedUsers && nonTerminatedCachedUsers.length > 0
        ? nonTerminatedCachedUsers
            .filter(
              (eachUser) =>
                eachUser.userContract?.type &&
                [ContractorTypes.CONTRACTOR, ContractorTypes.EOR].includes(
                  eachUser.userContract.type as ContractorTypes
                ) &&
                usersWithoutAccess.some((u) => u.userId === eachUser.userId && u.userId !== userId)
            )
            .map((u) => {
              return { label: u.displayName, value: u.userId, ...u, disabled: true };
            })
        : [],
    [nonTerminatedCachedUsers, userId, usersWithoutAccess]
  );

  const countriesForEmploymentOptions = useMemo(() => {
    return countriesForEmployment?.map((eachCountry) => {
      return { label: eachCountry.name, value: eachCountry.code };
    }) as OptionObj[];
  }, [countriesForEmployment]);

  const [showMessage] = useMessage();
  const [refreshAt, setRefreshAt] = useState<number | null>(null);
  const [currentlySelectedUserEmails, setCurrentlySelectedUserEmails] = useState<{ primary: string; email: string }[]>(
    []
  );
  const [createUserWithParams, setCreateUserWithParams] = useState<((p: AppCreateUserParams) => void) | null>(null);
  const { data: companyDomainForEmail } = useApiClient(AppIntegrationEndpoints.getCompanyDomainForApp(appStub), {
    suspense: false,
  });
  const { data: personalEmailForUser, isValidating } = useApiClient(
    preselectedUser?.userId
      ? AppIntegrationEndpoints.getPersonalEmailForAppsByUserId(+preselectedUser?.userId, appStub)
      : { url: undefined },
    {
      suspense: false,
    }
  );
  const [plannedNewWorkEmail, setPlannedNewWorkEmail] = useState<string>('<new_work_email>');

  const appsNeedingCreateParams: AppStub | AppIntegrationStub[] = ['jumpcloud'];

  useEffect(() => {
    const usernameForNewAccount =
      preselectedUser && preselectedUser.userId
        ? getCachedUserById(Number(preselectedUser?.userId))?.emailAddress?.split('@')[0]
        : '<no_user_id>';
    setPlannedNewWorkEmail(`${usernameForNewAccount}@${companyDomainForEmail}`);
  }, [preselectedUser, getCachedUserById, companyDomainForEmail]);

  useEffect(() => {
    if (!refreshAt) return;
    const delay = Math.max(refreshAt - Date.now(), 0);
    setTimeout(() => {
      setRefreshAt(null);
      refreshApp();
    }, delay);
  }, [refreshApp, refreshAt]);

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  function refreshAppData(delay = REFRESH_DELAY_APP_USER_LIST) {
    // actions are now asyncly queued before being performed so just pause
    // for a few seconds before refreshing the page to allow some time for
    // the action to complete.
    setRefreshAt(Date.now() + delay);
  }

  const emailSelectorRequired = useMemo(
    () => mode === APP_ACTION_DRAWER_MODES.create && APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub),
    [mode, appStub]
  );

  const selectedEmailOptions = useMemo(() => {
    return preselectedUser && !isValidating
      ? emailOptionsForUserCreation(appStub, preselectedUser, personalEmailForUser ?? '', nonTerminatedCachedUsers)
      : undefined;
  }, [appStub, isValidating, nonTerminatedCachedUsers, personalEmailForUser, preselectedUser]);

  const initialValues: FormData = {
    userId: userId || undefined,
    whenAction: 'now',
    countryForEmployment: undefined,
    workspaceTransferThenDelete: false,
    transferDataSourceUser: userId || undefined,
    transferDataTargetUserOptionObj: undefined,
    transferDataTargetUser: undefined,
    actionDate: new LocalDate().toDateString(),
    emailSelectorRequired: emailSelectorRequired,
    selectedEmailForUserCreation:
      emailSelectorRequired && selectedEmailOptions ? (selectedEmailOptions[0]?.value as string) : undefined,
  };
  const validationSchema = yup.object({
    userId: yup.mixed().nullable().required('User is required'),
    actionDate: yup.string().test(dateFieldTest).nullable().notRequired(),
    countryForEmployment:
      mode === APP_ACTION_DRAWER_MODES.createEmployment
        ? yup.string().nullable().required('Country is required to create employment')
        : yup.string().nullable().notRequired(),
    workspaceTransferThenDelete: yup.boolean().nullable().notRequired(),
    selectedEmailForUserCreation: yup
      .string()
      .notRequired()
      .when('emailSelectorRequired', {
        is: true,
        then: yup.string().nullable().required('Selected email is required'),
      }),
    transferDataTargetUser: yup.string().notRequired().when('workspaceTransferThenDelete', {
      is: true,
      then: yup.string().nullable().required(),
    }),
  });

  const doAssignUser = useCallback(
    async (userToBeAssigned: number): Promise<void> => {
      try {
        if (selectedActiveUserLogin && userToBeAssigned) {
          await AppIntegrationAPI.assignUser(appStub, userToBeAssigned, selectedActiveUserLogin);
          if (setSelectedActiveUserLogin) setSelectedActiveUserLogin(undefined);
          showMessage('Account assigned successfully', 'success');
          if (!APPS_NOT_REQUIRING_REFRESH_POST_ACTION.includes(appStub)) refreshApp();
        }
      } catch (error: any) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [selectedActiveUserLogin, appStub, setSelectedActiveUserLogin, showMessage, refreshApp]
  );

  const unassignUser = useCallback(
    async ({ emailObj }: { emailObj: AppIntegrationUserEmailDto }): Promise<void> => {
      try {
        await AppIntegrationAPI.unassignUser(
          appStub,
          (preselectedUser && (preselectedUser.userId as number)) ?? 0,
          emailObj.email
        );
        showMessage('Account unassigned successfully', 'success');
        refreshApp();
        if (preselectedUser && preselectedUser.userId && markTeamUserPending)
          markTeamUserPending(Number(preselectedUser.userId));
      } catch (error: any) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [appStub, preselectedUser, showMessage, refreshApp, markTeamUserPending]
  );

  async function doSuspendUser(user: AppIntegrationUserDto, values: FormData): Promise<void> {
    try {
      await AppIntegrationAPI.suspendAppUser(
        appStub,
        String(user?.id),
        user?.userId ? Number(user?.userId) : 0,
        getActionDate(values.actionDate)
      );
      if (refreshDelayedActions) refreshDelayedActions();
      showMessage('Suspension initiated', 'success');
      if (user.userId && markTeamUserPending) markTeamUserPending(Number(user.userId));
    } catch (error: any) {
      if (error?.response.data.error === `Higher tier of ${appStub} app needed.`) {
        showMessage(
          'In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.',
          'error'
        );
      } else {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    }
  }

  async function doDeleteUser(user: AppIntegrationUserDto, values: FormData): Promise<void> {
    try {
      if (user.isAdmin) {
        showMessage(
          "You can't delete an Admin user! To be able to delete this user you should first downgrade this user to a non-admin role.",
          'error'
        );

        return;
      }
      let appUserId = undefined;
      if (appStub === 'github') {
        //if github user is already assigned but deletion is required, we need to unassign first, then delete user
        const githubEmail = hasActiveEmailForAccount(user);
        if (githubEmail) await unassignUser({ emailObj: githubEmail });
        appUserId = githubEmail?.email;
      }
      if (!appUserId) appUserId = user.login ?? user.id;
      if (!appUserId) appUserId = getEmailAddressForMissingId(user);
      if (appUserId && formik.values.userId)
        await AppIntegrationAPI.deleteAppUser(
          appStub,
          String(appUserId) === String(formik.values.userId) &&
            APPS_NEEDING_EMAIL_FOR_DELETION.includes(appStub) &&
            preselectedUser &&
            preselectedUser.primaryEmail
            ? preselectedUser.primaryEmail
            : String(appUserId),
          String(appUserId) === String(formik.values.userId) ? 0 : Number(formik.values.userId),
          getActionDate(values.actionDate)
        );
      if (refreshDelayedActions) refreshDelayedActions();
      showMessage(
        `User deletion ${
          !values.actionDate || isToday(new LocalDate(values.actionDate).getDate()) ? 'initiated' : 'scheduled'
        }`,
        'success'
      );
      if (formik.values.userId && markTeamUserPending) markTeamUserPending(Number(formik.values.userId));
    } catch (error: any) {
      if (error?.response?.data?.error === 'Higher tier needed.') {
        showMessage(
          'In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.',
          'error'
        );
      } else if (error?.response?.data?.error?.includes('Super Admin')) {
        showMessage('This user is a Super Admin. Can not delete a Super Admin.', 'error');
      } else {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    }
  }

  async function doTransferDataAndDeleteUser(user: AppIntegrationUserDto, values: FormData): Promise<void> {
    try {
      await AppIntegrationAPI.dataTransferThenDeleteForAppUser(
        appStub,
        values.userId ?? 0,
        String(values.transferDataSourceUser),
        String(values.transferDataTargetUser),
        new LocalDate(values.actionDate).toDateString()
      );
      showMessage('User data transfer and deletion initiated', 'success');
      if (user.userId && markTeamUserPending) markTeamUserPending(Number(user.userId));
    } catch (error: any) {
      if (error?.response.data.error === `Higher tier of ${appStub} app needed.`) {
        showMessage(
          'In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.',
          'error'
        );
      } else {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    }
  }

  const getParamsForAppUserCreation = (values: FormData) => {
    if (emailSelectorRequired) return { selectedEmail: values.selectedEmailForUserCreation };
    return undefined;
  };

  const formik = useFormik<FormData>({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      try {
        setIsSubmitting(true);
        if (!values.userId || !values.actionDate) throw new Error('userId and action date are required');
        if (mode === APP_ACTION_DRAWER_MODES.create)
          await createUser(new LocalDate(values.actionDate).toDateString(), getParamsForAppUserCreation(values));
        if (mode === APP_ACTION_DRAWER_MODES.createEmployment && values.countryForEmployment)
          await createEmployment(+values.userId, values.countryForEmployment);
        if (mode === APP_ACTION_DRAWER_MODES.assign) await doAssignUser(+values.userId);
        if (mode === APP_ACTION_DRAWER_MODES.suspend && preselectedUser && values)
          await doSuspendUser(preselectedUser, values);
        if (
          mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete &&
          preselectedUser &&
          formik.values.workspaceTransferThenDelete &&
          formik.values.transferDataTargetUser
        ) {
          await doTransferDataAndDeleteUser(preselectedUser, values);
        }
        if (
          mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete &&
          preselectedUser &&
          !formik.values.workspaceTransferThenDelete
        ) {
          await doDeleteUser(preselectedUser, values);
        }
        if (mode === APP_ACTION_DRAWER_MODES.delete && preselectedUser) {
          await doDeleteUser(preselectedUser, values);
        }
        closePage();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const helperTextForUserCreation = useMemo(() => {
    if (emailSelectorRequired) {
      return personalEmailForUser ? (
        <Typography sx={{ ...themeFonts.caption, mb: spacing.mb30, fontStyle: 'italic' }}>
          {`Account details and login instructions will be sent to ${
            APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub)
              ? formik.values.selectedEmailForUserCreation
              : ' the selected email'
          }  once the account is created`}
        </Typography>
      ) : (
        <Typography sx={{ ...themeFonts.caption, mb: spacing.mb30, fontStyle: 'italic' }}>
          {`No personal email exists for this user; ${appStubToName[appStub]} account cannot be provisioned without personal email.`}
        </Typography>
      );
    }
  }, [appStub, emailSelectorRequired, formik.values.selectedEmailForUserCreation, personalEmailForUser]);

  useEffect(() => {
    // if app is Google or Microsoft365 email selector should be disabled for account creation
    // and should default to personal email
    if (APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub)) {
      const personalEmailIndex = selectedEmailOptions?.findIndex((email) =>
        (email?.label as string)?.toLowerCase()?.includes('personal')
      );
      const hasPersonalEmail = personalEmailIndex && personalEmailIndex >= 0;

      if (personalEmailForUser) formik.setFieldValue('selectedEmailForUserCreation', personalEmailForUser);
      else if (hasPersonalEmail && selectedEmailOptions && selectedEmailOptions?.length > 0)
        formik.setFieldValue('selectedEmailForUserCreation', selectedEmailOptions[personalEmailIndex]?.value);

      setNoPersonalEmailSoUserCreationDisabled(personalEmailForUser ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStub, selectedEmailOptions, personalEmailForUser]);

  const emailSelectorForUserCreation = useMemo(() => {
    if (emailSelectorRequired) {
      return (
        <>
          {!APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub) && (
            <Box
              sx={{
                ...fieldSx,
                display: 'flex-start',
                flexDirection: 'column',
                gap: spacing.g10,
                alignItems: 'left',
              }}
            >
              <SelectComponent
                name="emailSelector"
                label="Invite email"
                options={selectedEmailOptions ?? []}
                value={formik.values.selectedEmailForUserCreation}
                error={!!formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation}
                onChange={(e) => {
                  formik.setFieldValue('selectedEmailForUserCreation', e.target.value, true);
                }}
                helperText={formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation}
              />
            </Box>
          )}
          <Box sx={fieldSx}>
            <TextfieldComponent
              label="Creating email address"
              name="emailAddressForNewAccount"
              disabled={true}
              value={plannedNewWorkEmail}
            />
          </Box>
        </>
      );
    } else return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStub, selectedEmailOptions, mode, formik.values.selectedEmailForUserCreation, plannedNewWorkEmail]);

  useEffect(() => {
    if (preselectedUser && preselectedUser.userId) {
      setUserId(isNaN(+preselectedUser.userId) ? preselectedUser.userId : +preselectedUser.userId);
    } else if (preselectedUser && preselectedUser.id) {
      setUserId(isNaN(+preselectedUser.id) ? preselectedUser.id : +preselectedUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedUser]);

  useEffect(() => {
    setTimeout(() => formik.setFieldValue('userId', userId, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  async function createUser(date?: string, createUserParams?: AppCreateUserParams): Promise<void> {
    if (appStub === 'monday') {
      showMessage('This feature requires SCIM and SSO to be enabled.', 'error');
      return;
    }
    if (appsNeedingCreateParams.includes(appStub) && !createUserParams) {
      const jumpCloudUserCreation = new Promise((resolve, _reject) => {
        const closeModalAndCreateUser = async (params: AppCreateUserParams) => {
          setCreateUserWithParams(null);
          await createUser(date, params);
          resolve('Jumpcloud user created');
        };
        setCreateUserWithParams(() => closeModalAndCreateUser);
      });
      await jumpCloudUserCreation;
      return;
    }
    try {
      await AppIntegrationAPI.createAppUser(
        appStub,
        formik.values.userId as number,
        createUserParams,
        date ? getActionDate(date) : undefined
      );
      if (formik.values.userId && !isNaN(+formik.values.userId) && markTeamUserPending)
        markTeamUserPending(+formik.values.userId);
      if (refreshDelayedActions) refreshDelayedActions();
      refreshAppData(500);
      showMessage('Creation initiated', 'success');
    } catch (error: any) {
      if (error.response?.data?.message) {
        showMessage(nestErrorMessage(error), 'error');
      } else {
        showMessage(`Oops, something happened. Please try again: ${JSON.stringify(error)}`, 'error');
      }
    }
  }

  async function createEmployment(userId: number, country: string) {
    try {
      await AppIntegrationAPI.createEmployment(appStub, userId, country);
      if (userId && !isNaN(userId) && markTeamUserPending) markTeamUserPending(userId);
      showMessage('Employment successfully created', 'success');
      refreshApp();
    } catch (error: any) {
      if (error.response?.data?.message) {
        showMessage(nestErrorMessage(error), 'error');
      } else {
        showMessage(`Oops, something happened. Please try again: ${JSON.stringify(error)}`, 'error');
      }
    }
  }

  const getDrawerTitle = (currentMode: APP_ACTION_DRAWER_MODES) => {
    if (currentMode === APP_ACTION_DRAWER_MODES.create)
      return polyglot.t(userId ? 'AppActionsDrawerPage.giveAccessToUser' : 'AppActionsDrawerPage.addUser');
    if (currentMode === APP_ACTION_DRAWER_MODES.assign) return polyglot.t('AppActionsDrawerPage.assignToTeamMember');
    if (currentMode === APP_ACTION_DRAWER_MODES.createEmployment)
      return polyglot.t('AppActionsDrawerPage.createEmployment');
    if (currentMode === APP_ACTION_DRAWER_MODES.delete && APPS_WITHOUT_DELETE_OPTION.includes(appStub))
      return polyglot.t('AppActionsDrawerPage.removeFromOrganisation', {
        userName: preselectedUser?.displayName,
      });
    if (currentMode === APP_ACTION_DRAWER_MODES.delete && !APPS_WITHOUT_DELETE_OPTION.includes(appStub))
      return polyglot.t('AppActionsDrawerPage.deleteAccount', {
        userName: preselectedUser?.displayName,
      });
    if (currentMode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete)
      return polyglot.t('AppActionsDrawerPage.deleteAccount', {
        userName: preselectedUser?.displayName,
      });
    if (currentMode === APP_ACTION_DRAWER_MODES.suspend)
      return (
        getActionNameForApp(
          'Suspend',
          appStub,
          polyglot.t('AppActionsDrawerPage.suspendUser', {
            userName: preselectedUser?.displayName,
          })
        ) ??
        polyglot.t('AppActionsDrawerPage.suspendUser', {
          userName: preselectedUser?.displayName,
        })
      );
    else return '';
  };

  const getDrawerConfirmButtonText = (currentMode: APP_ACTION_DRAWER_MODES) => {
    if (currentMode === APP_ACTION_DRAWER_MODES.create) return 'Add';
    if (currentMode === APP_ACTION_DRAWER_MODES.assign) return 'Assign';
    if (currentMode === APP_ACTION_DRAWER_MODES.suspend) return getActionNameForApp('Suspend', appStub) ?? 'Suspend';
    if (currentMode === APP_ACTION_DRAWER_MODES.delete) return 'Remove';
    if (currentMode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete) return 'Delete';
    if (currentMode === APP_ACTION_DRAWER_MODES.createEmployment) return 'Create';
    else return '';
  };

  const getUserSelectionOption = (
    currentMode: APP_ACTION_DRAWER_MODES,
    directoryMode: string
  ): OptionsProps[] | OptionObj[] => {
    if (
      [APP_ACTION_DRAWER_MODES.suspend, APP_ACTION_DRAWER_MODES.assign].includes(currentMode) &&
      directoryMode === 'team'
    )
      return usersWithoutAccessOptions;
    if (
      [APP_ACTION_DRAWER_MODES.suspend, APP_ACTION_DRAWER_MODES.delete].includes(currentMode) &&
      directoryMode === 'external'
    )
      return externalUserAccessOptions;
    if ([APP_ACTION_DRAWER_MODES.assign].includes(currentMode) && directoryMode === 'external')
      return usersWithoutAccessOptions;

    if ([APP_ACTION_DRAWER_MODES.createEmployment].includes(currentMode) && directoryMode === 'employments') {
      // need to only return a list of Contractor and EOR in the company
      return contractorAndEORUserOptions;
    }
    return usersWithoutAccessOptions;
  };

  const titleSxTheme = useMemo(() => {
    if (emailSelectorRequired) {
      return { ...themeFonts.title2 };
    }
    return titleSx;
  }, [emailSelectorRequired]);

  const setWhenActionValue = useCallback(
    (value: string) => {
      formik.setFieldValue('whenAction', value);
    },
    [formik]
  );

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Typography sx={titleSxTheme}>{getDrawerTitle(mode)}</Typography>
          {helperTextForUserCreation}
          {![
            APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete,
            APP_ACTION_DRAWER_MODES.suspend,
            APP_ACTION_DRAWER_MODES.delete,
          ].includes(mode) && (
            <Box sx={fieldSx}>
              <SingleUserSelect
                name="userId"
                options={getUserSelectionOption(mode, directoryMode) as OptionsProps[]}
                disabled={mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete}
                onChange={(_, x) => {
                  const data = x as { value: number | undefined } | undefined;
                  const currentlySelectedUser = data?.value ? getCachedUserById(data?.value) : undefined;
                  formik.setFieldValue('userId', data?.value ?? null);
                  setCurrentlySelectedUserEmails(
                    currentlySelectedUser && currentlySelectedUser.emailAddress
                      ? [{ primary: currentlySelectedUser.emailAddress, email: currentlySelectedUser.emailAddress }]
                      : []
                  );
                  if (mode === APP_ACTION_DRAWER_MODES.createEmployment && currentlySelectedUser?.startDate) {
                    setEmploymentUserStartDateInThePast(isPast(new Date(currentlySelectedUser.startDate)));
                  }
                }}
                value={formik.values.userId ? Number(formik.values.userId) : undefined}
                label={
                  mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete
                    ? 'Being deleted'
                    : mode === APP_ACTION_DRAWER_MODES.createEmployment
                    ? 'Contractor / EOR'
                    : 'Employee'
                }
                error={Boolean(formik.errors.userId)}
                helperText={formik.errors.userId}
              />
            </Box>
          )}
          {mode === APP_ACTION_DRAWER_MODES.createEmployment && employmentUserStartDateInThePast && (
            <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Red }}>
              Cannot create employment if user's start date is in the past
            </Typography>
          )}

          {directoryMode === 'employments' && APP_ACTION_DRAWER_MODES.createEmployment === mode && (
            <SelectComponent
              name="countryForEmployment"
              label="Country for Employment creation"
              options={countriesForEmploymentOptions}
              value={formik.values.countryForEmployment}
              error={!!formik.errors.countryForEmployment && formik.touched.countryForEmployment}
              onChange={(e) => {
                formik.setFieldValue('countryForEmployment', e.target.value);
              }}
              helperText={formik.errors.countryForEmployment && formik.touched.countryForEmployment}
            />
          )}

          {emailSelectorForUserCreation}

          {[APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete].includes(mode) && (
            <>
              <Box sx={fieldSx}>
                <SelectComponent
                  name="whenAction"
                  label="When"
                  options={APP_ACTION_WHEN_OPTIONS}
                  value={formik.values.whenAction}
                  error={!!formik.errors.whenAction && formik.touched.whenAction}
                  onChange={(e) => {
                    const newVal = e.target.value;
                    if (newVal === 'now') formik.setFieldValue('actionDate', today);
                    else formik.setFieldValue('actionDate', new LocalDate(tomorrow).toDateString());
                    formik.handleChange(e);
                  }}
                  helperText={formik.errors.whenAction && formik.touched.whenAction}
                />
              </Box>
              {formik.values.whenAction === 'later' && (
                <Box sx={fieldSx}>
                  <DatePickerComponent
                    inputFormat="DD/MM/YYYY"
                    value={formik.values.actionDate ?? null}
                    onChange={(value) => {
                      if (dayjs(value).isValid()) {
                        formik.setFieldValue('actionDate', value);
                      }
                    }}
                    name="actionDate"
                    minDate={tomorrow}
                    label="Deletion date"
                    error={!!formik.errors.actionDate && formik.touched.actionDate}
                    helperText={formik.errors.actionDate && formik.touched.actionDate}
                  />
                </Box>
              )}
            </>
          )}

          {[APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete].includes(mode) && (
            <Box sx={fieldSx}>
              {(usersWithAccessOptions?.length > 0 || externalUserAccessOptions?.length > 0) && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', mb: spacing.m15 }}>
                  <StyledFormCheckbox
                    name="workspaceTransferThenDelete"
                    checked={formik.values.workspaceTransferThenDelete}
                    onChange={() => {
                      formik.setFieldValue(
                        'workspaceTransferThenDelete',
                        !formik.values.workspaceTransferThenDelete,
                        true
                      );
                    }}
                  />
                  <Typography sx={{ ...themeFonts.caption, ml: spacing.m10 }}>
                    Select if you would like to transfer this user's files to another Google Workspace account
                  </Typography>
                </Box>
              )}

              {formik.values.workspaceTransferThenDelete &&
                usersWithAccessOptions &&
                usersWithAccessOptions.length > 0 &&
                directoryMode === 'team' && (
                  <SingleUserSelect
                    name="transferDataTargetUser"
                    options={usersWithAccessOptions}
                    value={formik.values.transferDataTargetUserOptionObj?.value}
                    onChange={(_, x) => {
                      const data = x as { value: number | undefined } | undefined;
                      const foundAppTargetUser =
                        usersWithAccess && usersWithAccess.length > 0 && data && data.value
                          ? usersWithAccess.find((u) => u.userId === data.value)
                          : null;
                      formik.setFieldValue('transferDataTargetUserOptionObj', data, true);
                      formik.setFieldValue('transferDataTargetUser', foundAppTargetUser?.id ?? null, true);
                      formik.setFieldTouched('transferDataTargetUser', true, true);
                      if (preselectedUser) formik.setFieldValue('transferDataSourceUser', preselectedUser.id, true);
                      setTimeout(() => formik.setFieldTouched('transferDataTargetUser', true));
                    }}
                    label="Select Google Workspace account"
                    error={Boolean(formik.errors.userId)}
                    helperText={formik.errors.userId}
                  />
                )}
              {formik.values.workspaceTransferThenDelete &&
                externalUserAccessOptions?.length > 0 &&
                directoryMode === 'external' && (
                  <SelectComponent
                    name="transferDataTargetUser"
                    label="Select Google Workspace account"
                    options={externalUserAccessOptions}
                    value={formik.values.transferDataTargetUserOptionObj?.value}
                    error={
                      !!formik.errors.transferDataTargetUserOptionObj && formik.touched.transferDataTargetUserOptionObj
                    }
                    onChange={(e) => {
                      const data = e?.target;
                      const foundAppTargetUser =
                        usersWithAccess && usersWithAccess.length > 0 && data && data.value
                          ? usersWithAccess.find((u) => u.id === data.value)
                          : null;
                      formik.setFieldValue('transferDataTargetUserOptionObj', data.value);
                      formik.setFieldValue('transferDataTargetUser', foundAppTargetUser?.id ?? null);
                      formik.setFieldTouched('transferDataTargetUser', true, true);
                      if (preselectedUser) {
                        formik.setFieldValue('transferDataSourceUser', preselectedUser.id);
                        formik.setFieldValue('userId', preselectedUser.id); //as this is only dealing with external users
                      }
                      setTimeout(() => formik.setFieldTouched('transferDataTargetUser', true));
                    }}
                    helperText={formik.errors.transferDataTargetUser && formik.touched.transferDataTargetUser}
                  />
                )}
            </Box>
          )}

          {![
            APP_ACTION_DRAWER_MODES.createEmployment,
            APP_ACTION_DRAWER_MODES.assign,
            APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete,
          ].includes(mode) && (
            <Box sx={fieldSx}>
              <TabFilterButtons
                filters={AppActionWhenTabFilter}
                setFilterValue={setWhenActionValue}
                filterValue={formik.values.whenAction ?? AppActionWhenValueEnum.now}
                onFilterChange={({ filterValue }) => {
                  if (filterValue === AppActionWhenValueEnum.now) formik.setFieldValue('actionDate', today);
                  else formik.setFieldValue('actionDate', new LocalDate(tomorrow).toDateString());
                }}
              />
            </Box>
          )}

          {![APP_ACTION_DRAWER_MODES.assign, APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete].includes(
            mode
          ) &&
            formik.values.whenAction === 'later' && (
              <Box sx={fieldSx}>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.actionDate ?? null}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      formik.setFieldValue('actionDate', value);
                    }
                  }}
                  name="actionDate"
                  minDate={tomorrow}
                  label="Action date"
                  error={!!formik.errors.actionDate && formik.touched.actionDate}
                  helperText={formik.errors.actionDate && formik.touched.actionDate}
                />
              </Box>
            )}

          <Box sx={buttonBoxSx}>
            <LoaderButton
              id={`btnConfirmAction_${userId}`}
              fullWidth
              type="submit"
              loading={isSubmitting}
              sizeVariant="medium"
              colorVariant="primary"
              disabled={
                isSubmitting ||
                !formik.isValid ||
                (mode === APP_ACTION_DRAWER_MODES.create && noPersonalEmailSoUserCreationDisabled) ||
                (mode === APP_ACTION_DRAWER_MODES.createEmployment && employmentUserStartDateInThePast)
              }
              // should only check no personal email flag if app drawer mode is user creation
            >
              {getDrawerConfirmButtonText(mode)}
            </LoaderButton>
          </Box>
        </Form>
      </FormikProvider>
      {createUserWithParams && appStub === 'jumpcloud' && (
        <JumpCloudNewUserParams
          onComplete={createUserWithParams}
          onClose={() => setCreateUserWithParams(null)}
          username={currentlySelectedUserEmails.find((e) => e.primary)?.email.split('@')[0]}
        />
      )}
    </>
  );
};
