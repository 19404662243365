import { useContext, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmAppsPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-apps-policy-editor.component';
import { ZeltMdmLoginItemsPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-login-items-policy-editor.component';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  DevicePolicyDto,
  LoginItemsOptions,
  ZeltManagedApp,
  ZeltManagedApps,
} from '@/v2/feature/device/device.dto';
import { DevicePolicyZeltType } from '@/v2/feature/device/device.interface';

export const ZeltMdmAppsPolicyViewer = ({
  companyPolicies,
  updatePolicies,
  configurablePoliciesSelected,
}: {
  companyPolicies: DevicePolicyDto[];
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [globalState] = useContext(GlobalContext);
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const [isOpenLoginItemsEditor, setIsOpenLoginItemsEditor] = useState<boolean>(false);
  const initialValues: ZeltManagedApps = {
    apps: companyPolicies
      .filter((element) => element.type === DevicePolicyZeltType.apps && element.isInhouseMdm)
      .filter((element) => !(element.externalId === 9 && globalState.user.company.companyId !== 618))
      .filter((element) => !(element.externalId === 18 && globalState.user.company.companyId !== 877))
      .map((app) => {
        const zeltManagedApp: ZeltManagedApp = {
          id: app.id,
          selected: configurablePoliciesSelected?.configurableApps?.includes(app.id) ?? false,
          name: app.name,
          externalId: app.externalId,
        };
        return zeltManagedApp;
      }),
  };

  const loginItemsPolicy = (): LoginItemsOptions => {
    return {
      autoLaunchedApplicationDictionaryManaged:
        configurablePoliciesSelected?.configurableLoginItems?.autoLaunchedApplicationDictionaryManaged !== undefined
          ? configurablePoliciesSelected?.configurableLoginItems?.autoLaunchedApplicationDictionaryManaged
          : [],
    };
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Managed apps</Typography>
          <IconButton
            onClick={() => {
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>

        {initialValues.apps.map((app) => (
          <Box
            sx={{
              mt: spacing.m5,
              display: 'grid',
              gridTemplateColumns: 'calc(25%) calc(75%)',
              color: themeColors.DarkGrey,
            }}
          >
            <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
              {app.name}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                ...(app.selected
                  ? { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }
                  : { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }),
              }}
            >
              {app.selected ? 'Enabled' : 'Not set'}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Open at login</Typography>
          <IconButton
            onClick={() => {
              setIsOpenLoginItemsEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Applications:
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(loginItemsPolicy().autoLaunchedApplicationDictionaryManaged!.length > 0
                ? { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }
                : { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }),
            }}
          >
            {loginItemsPolicy().autoLaunchedApplicationDictionaryManaged!.length > 0
              ? loginItemsPolicy()
                  .autoLaunchedApplicationDictionaryManaged!.map((e) => e.Path)
                  .join(', ')
              : 'Not set'}
          </Typography>
        </Box>
      </Box>
      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmAppsPolicyEditor
          companyPolicies={companyPolicies}
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
      <DrawerModal isOpen={isOpenLoginItemsEditor} setIsOpen={setIsOpenLoginItemsEditor}>
        <ZeltMdmLoginItemsPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
