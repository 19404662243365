import React, { useLayoutEffect, useMemo, useState } from 'react';

import { Box, Skeleton, SxProps, Theme, Typography } from '@mui/material';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowData,
  SortingState,
  Table as TanstackTable,
  useReactTable,
} from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Desc } from '@/images/side-bar-icons/ArrowDownSmall.svg';
import { ReactComponent as Asc } from '@/images/side-bar-icons/ArrowUpSmall.svg';
import { ReactComponent as SortIcon } from '@/images/side-bar-icons/SortIcon.svg';
import { PaginationDetail } from '@/v2/components/table/pagination-detail.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import '@/v2/components/table/styles/basic-table.scss';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import {
  iconSize,
  Table,
  TableBody,
  TableData,
  TableFootRow,
  TableHead,
  TableHeading,
  TableHeadRow,
  TableRow,
} from '@/v2/styles/table.styles';

export const BasicTable = <TData extends RowData>({
  rowData,
  columnData,
  loading,
  disabled,
  initialSort,
  rowClick,
  hidePagination = false,
  initialPageSize,
  noDataMessage,
  hiddenColumns,
  fixedLastColumn = true,
  fixedFirstColumn = true,
  style,
  customRowStyle,
  paginationReset = false,
  maxUnpaginatedRows = 100,
  setTable,
  showFooter = false,
  paginationSx,
  externalTdAndThPadding,
}: {
  rowData: TData[];
  columnData: ColumnDef<TData, TData>[];
  loading?: boolean;
  disabled?: boolean;
  initialSort?: SortingState;
  rowClick?: (row: Row<TData>) => void;
  hidePagination?: boolean;
  initialPageSize?: number;
  noDataMessage?: React.JSX.Element | string;
  hiddenColumns?: string[];
  fixedLastColumn?: boolean;
  fixedFirstColumn?: boolean;
  style?: React.CSSProperties;
  customRowStyle?: (row: Row<TData>) => React.CSSProperties | false | null | undefined;
  paginationReset?: boolean;
  maxUnpaginatedRows?: number | 'unlimited';
  setTable?: React.Dispatch<React.SetStateAction<TanstackTable<TData> | undefined>>;
  showFooter?: boolean;
  paginationSx?: SxProps<Theme>;
  externalTdAndThPadding?: string;
}): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  // force pagination if the data exceeds maxUnpaginatedRows
  const usePagination = !hidePagination || (maxUnpaginatedRows !== 'unlimited' && rowData.length > maxUnpaginatedRows);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: initialPageSize ?? DEFAULT_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: rowData,
    columns: columnData.filter((column) => column.id && !hiddenColumns?.includes(column.id)),
    initialState: {
      sorting: initialSort,
    },
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: usePagination ? getPaginationRowModel() : undefined,
    autoResetPageIndex: paginationReset, // should only be turned true when data reset. If otherwise, on each button click the pagination resets
    // we need to set manualPagination:true when we switch from pagination to unpaginated or the table
    // doesn't show all of the unpaginated data.
    manualPagination: !usePagination,
  });

  if (setTable) setTable(table);

  useLayoutEffect(() => {
    // when the table row count changes, reset the page index back to the start.
    // if we don't do this, it's possible for the current page index to be past
    // the end of the new data and the table will be displayed empty.
    table.setPageIndex(0);
  }, [rowData.length, table]);

  return (
    <>
      <div style={{ overflowX: 'auto', maxHeight: 'inherit', width: '100%', ...style }}>
        <Table>
          <TableHead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers
                  // .filter((a) => !hiddenColumns?.includes(a.id))
                  .map((header, idx, arr) => {
                    const cellStyle: React.CSSProperties = {};
                    return (
                      <TableHeading
                        key={header.id}
                        maxWidth={header.column.columnDef.maxSize || 100}
                        minWidth={header.column.columnDef.minSize}
                        style={{ ...cellStyle }}
                        fixedFirstColumn={idx === 0 && fixedFirstColumn}
                        fixedLastColumn={idx === arr.length - 1 && fixedLastColumn}
                        className="tableHeading"
                        externalTdAndThPadding={externalTdAndThPadding}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            style={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <span style={{ color: themeColors.Grey, ...themeFonts.caption }}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </span>

                            <div className="sortIcon">
                              {(header.column.columnDef.enableSorting &&
                                {
                                  asc: <Asc width={12} height={12} style={{ color: themeColors.DarkGrey }} />,
                                  desc: <Desc width={12} height={12} style={{ color: themeColors.DarkGrey }} />,
                                }[header.column.getIsSorted() as string]) ?? <SortIcon {...iconSize} />}
                            </div>
                          </div>
                        )}
                      </TableHeading>
                    );
                  })}
              </TableHeadRow>
            ))}
          </TableHead>
          {loading && (
            <TableBody style={{ height: '100%', overflowY: 'auto' }}>
              {[1, 2, 3, 4].map((row) => {
                const tableColumns = table.getAllColumns();
                return (
                  <TableRow key={row}>
                    {(tableColumns.length > 0 ? tableColumns : [1, 2, 3, 4]).map((cell, i) => (
                      <TableData key={i} maxWidth={150} minWidth={100}>
                        <Skeleton variant="text" sx={{ backgroundColor: themeColors.Background }} />
                      </TableData>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
          {rowData.length > 0 && !loading && (
            <TableBody>
              {table.getRowModel()?.rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => !disabled && rowClick?.(row)}
                  style={{
                    cursor: !disabled && rowClick ? 'pointer' : 'default',
                  }}
                >
                  {row.getVisibleCells().map((cell, idx, arr) => {
                    const cellStyle: React.CSSProperties = {};
                    return (
                      <TableData
                        key={idx}
                        maxWidth={cell.column.columnDef.maxSize || 100}
                        minWidth={cell.column.columnDef.minSize}
                        cellStyle={{ ...cellStyle, ...customRowStyle?.(row) }}
                        meta={cell.column?.columnDef?.meta as string}
                        fixedFirstColumn={idx === 0 && fixedFirstColumn}
                        fixedLastColumn={idx === arr.length - 1 && fixedLastColumn}
                        externalTdAndThPadding={externalTdAndThPadding}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableData>
                    );
                  })}
                </TableRow>
              ))}
              {showFooter &&
                table.getFooterGroups().map((footerGroup) => (
                  <TableFootRow key={footerGroup.id}>
                    {footerGroup.headers.map((header) => {
                      const cellStyle: React.CSSProperties = {};
                      return (
                        <TableData
                          key={header.id}
                          maxWidth={header.column.columnDef.maxSize || 100}
                          minWidth={header.column.columnDef.minSize}
                          cellStyle={{ ...themeFonts.title4, ...cellStyle }}
                        >
                          {flexRender(header.column.columnDef.footer, header.getContext())}
                        </TableData>
                      );
                    })}
                  </TableFootRow>
                ))}
            </TableBody>
          )}
        </Table>
        {rowData.length < 1 && !loading && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              marginTop: spacing.m10,
              height: '50px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {noDataMessage ??
                (polyglot.has('BasicServerTable.countNotFindAnything')
                  ? polyglot.t('BasicServerTable.countNotFindAnything')
                  : "Sorry, we couldn't find anything...")}
            </Typography>
          </Box>
        )}
      </div>

      {usePagination && (
        <PaginationDetail
          totalPageCount={table.getPageCount()}
          totalRecordCount={rowData?.length}
          current={Math.min(table.getPageCount(), table.getState().pagination.pageIndex + 1)}
          onNextAction={() => {
            table.nextPage();
          }}
          nextDisabled={!table.getCanNextPage()}
          onPreviousAction={() => {
            table.previousPage();
          }}
          previousDisabled={!table.getCanPreviousPage()}
          paginationState={table.getState().pagination}
          setPaginationState={(pageSize: number, pageIndex: number) => {
            table.setPagination({ pageSize, pageIndex: pageIndex - 1 });
          }}
          handleState={(page: number) => {
            const pageState = page ? page - 1 : 0;
            table.setPageIndex(pageState);
          }}
          paginationSx={paginationSx}
        />
      )}
    </>
  );
};
