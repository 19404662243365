import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmActivationLockPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-activation-lock-policy-editor.component';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  ActivationLock,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmActivationLockPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const getActivationLockValues = (): ActivationLock => {
    return {
      ActivationLockAllowedWhileSupervised:
        configurablePoliciesSelected?.configurableActivationLock?.ActivationLockAllowedWhileSupervised ?? false,
    };
  };

  const initialActivationLockValues: ActivationLock = getActivationLockValues();

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Activation lock</Typography>
          <IconButton
            onClick={() => {
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Activation lock
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialActivationLockValues.ActivationLockAllowedWhileSupervised
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialActivationLockValues.ActivationLockAllowedWhileSupervised ? 'Enabled' : 'Disabled'}
          </Typography>
        </Box>
      </Box>

      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmActivationLockPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
