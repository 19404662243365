import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { buttonBoxSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

type AbsenceEditable = Pick<
  AbsenceDto,
  | 'totalLength'
  | 'workdayCount'
  | 'year2021'
  | 'year2022'
  | 'year2023'
  | 'year2024'
  | 'year2025'
  | 'year2026'
  | 'potentialCarryOver'
>;

export const HelperAbsenceEditDrawer = ({
  isOpen,
  setIsOpen,
  absence,
  refreshAbsences,
  closePage,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly absence: AbsenceDto;
  readonly refreshAbsences: () => Promise<void>;
  readonly closePage: () => void;
}): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();

  const initialValues: AbsenceEditable = useMemo(
    () => ({
      totalLength: absence.totalLength,
      workdayCount: absence.workdayCount ?? 0,
      year2021: absence.year2021,
      year2022: absence.year2022,
      year2023: absence.year2023,
      year2024: absence.year2024,
      year2025: absence.year2025,
      year2026: absence.year2026,
      potentialCarryOver: absence.potentialCarryOver,
    }),
    [absence]
  );

  const formik = useFormik<AbsenceEditable>({
    initialValues,
    validationSchema: Yup.object({
      totalLength: Yup.number().required('Field required'),
      workdayCount: Yup.number().integer().required('Field required'),
      year2021: Yup.number().required('Field required'),
      year2022: Yup.number().required('Field required'),
      year2023: Yup.number().required('Field required'),
      year2024: Yup.number().required('Field required'),
      year2025: Yup.number().required('Field required'),
      year2026: Yup.number().required('Field required'),
      potentialCarryOver: Yup.number().required('Field required'),
    }),
    onSubmit: async (values: AbsenceEditable): Promise<void> => {
      try {
        const toUpdate = {
          totalLength: Number(values.totalLength),
          workdayCount: Number(values.workdayCount),
          year2021: Number(values.year2021),
          year2022: Number(values.year2022),
          year2023: Number(values.year2023),
          year2024: Number(values.year2024),
          potentialCarryOver: Number(values.potentialCarryOver),
        };

        await AbsenceAPI.updateAbsenceByIdAsSuperAdmin(absence.absenceId, toUpdate);
        showMessage('Absence updated.', 'success');
        await refreshAbsences();
        closePage();
      } catch (error) {
        showMessage(`Something went wrong ${nestErrorMessage(error)}`, 'error');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Typography variant="title2">Edit absence</Typography>

          <Typography variant="title4">AbsenceId: {absence.absenceId}</Typography>

          <Typography variant="title4">UserId: {absence.userId}</Typography>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="totalLength"
              label="totalLength"
              value={formik.values.totalLength}
              onChange={formik.handleChange}
              error={formik.touched.totalLength && Boolean(formik.errors.totalLength)}
              helperText={formik.touched.totalLength && formik.errors.totalLength}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="workdayCount"
              label="workdayCount"
              value={formik.values.workdayCount}
              onChange={formik.handleChange}
              error={formik.touched.workdayCount && Boolean(formik.errors.workdayCount)}
              helperText={formik.touched.workdayCount && formik.errors.workdayCount}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="year2021"
              label="year2021"
              value={formik.values.year2021}
              onChange={formik.handleChange}
              error={formik.touched.year2021 && Boolean(formik.errors.year2021)}
              helperText={formik.touched.year2021 && formik.errors.year2021}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="year2022"
              label="year2022"
              value={formik.values.year2022}
              onChange={formik.handleChange}
              error={formik.touched.year2022 && Boolean(formik.errors.year2022)}
              helperText={formik.touched.year2022 && formik.errors.year2022}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="year2023"
              label="year2023"
              value={formik.values.year2023}
              onChange={formik.handleChange}
              error={formik.touched.year2023 && Boolean(formik.errors.year2023)}
              helperText={formik.touched.year2023 && formik.errors.year2023}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="year2024"
              label="year2024"
              value={formik.values.year2024}
              onChange={formik.handleChange}
              error={formik.touched.year2024 && Boolean(formik.errors.year2024)}
              helperText={formik.touched.year2024 && formik.errors.year2024}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="year2025"
              label="year2025"
              value={formik.values.year2025}
              onChange={formik.handleChange}
              error={formik.touched.year2025 && Boolean(formik.errors.year2025)}
              helperText={formik.touched.year2025 && formik.errors.year2025}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="year2026"
              label="year2026"
              value={formik.values.year2026}
              onChange={formik.handleChange}
              error={formik.touched.year2026 && Boolean(formik.errors.year2026)}
              helperText={formik.touched.year2026 && formik.errors.year2026}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="potentialCarryOver"
              label="potentialCarryOver"
              value={formik.values.potentialCarryOver}
              onChange={formik.handleChange}
              error={formik.touched.potentialCarryOver && Boolean(formik.errors.potentialCarryOver)}
              helperText={formik.touched.potentialCarryOver && formik.errors.potentialCarryOver}
            />
          </Box>

          <Box sx={{ ...buttonBoxSx, mt: '10px' }}>
            <LoaderButton name="Save" loading={isSubmitting} fullWidth sizeVariant="medium" colorVariant="primary" />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
