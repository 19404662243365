import React, { useState } from 'react';

import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { getAverageNumberOfWorkingDaysPerScheduleWeek } from '@v2/feature/attendance/attendance-schedule.util';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceScheduleEditScheduleSettingsDrawer } from '@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-schedule-settings-drawer.component';
import { AttendanceSchedulePatternSubSection } from '@v2/feature/attendance/settings/components/attendance-schedule-pattern-subsection.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE } from '@/lib/routes';

interface SectionProps {
  readonly schedule: AttendanceScheduleDto;
  readonly refreshSchedule: () => Promise<void>;
  readonly refreshAllSchedules: () => Promise<void>;
}

export const AttendanceSettingsScheduleSection = ({ schedule, refreshSchedule, refreshAllSchedules }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const [edit, setEdit] = useState(false);

  const averageNumberOfWorkingDaysPerSchedule = getAverageNumberOfWorkingDaysPerScheduleWeek(schedule);

  return (
    <SettingsSectionContent
      title={polyglot.t('AttendanceDomain.schedule')}
      onEdit={() => {
        setEdit(true);
      }}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('General.type'),
                value: schedule.isFlexible
                  ? polyglot.t('ScheduleScheduleForm.flexible')
                  : polyglot.t('ScheduleScheduleForm.definedHours'),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('EditFTEEquivalentDrawer.WorkingHours'),
                value: polyglot.t('EditFTEEquivalentDrawer.avgHoursPerWeek', {
                  noOfHours: convertMinutesToClockHours(schedule.totalTime, polyglot),
                  smart_count: schedule.noOfWeeks,
                }),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('EditFTEEquivalentDrawer.FullTimeHours'),
                value: polyglot.t('EditFTEEquivalentDrawer.avgHoursPerWeek', {
                  noOfHours: convertMinutesToClockHours(schedule.fteEquivalent, polyglot),
                  smart_count: 1,
                }),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('EditFTEEquivalentDrawer.WorkingDays'),
                value: polyglot.t('EditFTEEquivalentDrawer.avgDaysPerWeek', {
                  noOfDays: averageNumberOfWorkingDaysPerSchedule,
                  smart_count: schedule.noOfWeeks,
                }),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('EditFTEEquivalentDrawer.FullTimeDays'),
                value: polyglot.t('EditFTEEquivalentDrawer.avgDaysPerWeek', {
                  noOfDays: schedule.fteEquivalentInDays,
                  smart_count: 1,
                }),
              },
            ],
          },
          {
            title: polyglot.t('AttendanceDomain.pattern'),
            onEdit: () => {
              history.push(generatePath(SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE, { scheduleId: schedule.id }));
            },
            items: [
              {
                type: SectionItemType.Component,
                value: <AttendanceSchedulePatternSubSection schedule={schedule} />,
              },
            ],
          },
        ]}
      />

      <AttendanceScheduleEditScheduleSettingsDrawer
        isOpen={edit}
        setIsOpen={setEdit}
        attendanceSchedule={schedule}
        refresh={async () => {
          await Promise.all([refreshSchedule(), refreshAllSchedules()]);
        }}
      />

      <AttendanceScheduleEditScheduleSettingsDrawer
        isOpen={edit}
        setIsOpen={setEdit}
        attendanceSchedule={schedule}
        refresh={async () => {
          await Promise.all([refreshSchedule(), refreshAllSchedules()]);
        }}
      />
    </SettingsSectionContent>
  );
};
