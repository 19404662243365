import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitMembersDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <EditCustomBenefitMembersDrawerContent customBenefit={customBenefit} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly customBenefit: CustomBenefitDto;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitMembersDrawerContent = ({
  customBenefit,
  refresh,
  setIsOpen,
}: DrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState<UserSelectFiltersOptions>(UserSelectFiltersOptions.None);
  const [customRule, setCustomRule] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<number[]>(customBenefit.includedUserIds ?? []);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (includedUserIds: number[]) => {
      try {
        setLoading(true);
        await CustomBenefitAPI.updateCustomBenefitMembers(customBenefit.id, { includedUserIds });
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
    [customBenefit.id, refresh, setIsOpen, showMessage]
  );

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('EditPolicyMembersDrawerContent.editMembers')}</Typography>

      <UserSelect
        label={polyglot.t('EditPolicyMembersDrawerContent.whoSelect')}
        selectedLabel="Selected employees"
        value={selectedUsers}
        onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
          setSelectedUsers([...userIds]);
          setValidationMessage('');
          setSelectedOption(filterValue ?? UserSelectFiltersOptions.None);
          setCustomRule(customRule ?? '');
        }}
        fieldSx={{ ...spacing.mb20 }}
        error={!!validationMessage}
        helperText={!!validationMessage && validationMessage}
        initialFilterValue={UserSelectFiltersOptions.None}
        ruleString={customRule}
        allowEmpty
        excludeCustomRule
      />
      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          name={polyglot.t('General.save')}
          type="button"
          onClick={async () => {
            if (selectedOption === UserSelectFiltersOptions.None)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOption'));
            // else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
            //   setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOneUser'));
            else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.enterCustomRule'));
            else {
              await onSubmit(selectedUsers);
            }
          }}
          loading={loading}
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
        />
      </Box>
    </Box>
  );
};
