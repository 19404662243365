import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ChangeOwnerUserForm } from '@v2/feature/device/components/device-details/change-owner-user-form.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { ChangeOwnerDeviceTransitDataDto, DevicePossessionDto } from '@v2/feature/device/device.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from '@/lib/routes';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { ChangeOwnerCompanyInventoryForm } from '@/v2/feature/device/components/device-details/change-owner-company-inventory-form.component';
import { ChangeOwnerZeltStorageForm } from '@/v2/feature/device/components/device-details/change-owner-zelt-storage-form.component';
import { DevicePossessionType } from '@/v2/feature/device/device.interface';

interface ChangeOwnerDrawerProps {
  readonly isOpen: boolean;
  readonly onClose: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
}

const TabFilter = [
  { name: 'Employee', value: 'Employee' },
  { name: 'Zelt storage', value: DevicePossessionType.ZeltStorage },
  { name: 'Company inventory', value: DevicePossessionType.CompanySite },
];

export const ChangeOwnerDrawer = ({
  isOpen,
  onClose,
  devicePossession,
  setIsOpen,
}: ChangeOwnerDrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <ChangeOwnerDrawerContent devicePossession={devicePossession} closePage={onClose} />
  </DrawerModal>
);

interface ChangeOwnerDrawerContentProps {
  readonly devicePossession: DevicePossessionDto;
  readonly closePage: () => Promise<void>;
}

const ChangeOwnerDrawerContent = ({ devicePossession, closePage }: ChangeOwnerDrawerContentProps) => {
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const [filterValue, setFilterValue] = useState<string>('Employee');
  const routerHistory = useHistory();

  const changeOwner = async (currentDevicePossessionId: number, transitData: ChangeOwnerDeviceTransitDataDto) => {
    let isChangeOwnerRequested = false;
    try {
      await DeviceAPI.changeDeviceOwnerTransitRequest(currentDevicePossessionId, transitData);
      isChangeOwnerRequested = true;
      showMessage('Owner change has been initiated successfully', 'success');

      routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await closePage();
    } catch (error) {
      if (isChangeOwnerRequested) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not initiate owner change. ${nestErrorMessage(error)}`, 'error');
      }
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Change owner</Typography>
      <TabFilterButtons
        filters={TabFilter}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
        onFilterChange={({ filterValue }) => {
          setFilterValue(filterValue);
        }}
      />
      {filterValue === 'Employee' && (
        <ChangeOwnerUserForm currentDevicePossession={devicePossession} changeOwner={changeOwner} />
      )}

      {filterValue === DevicePossessionType.ZeltStorage && (
        <ChangeOwnerZeltStorageForm devicePossession={devicePossession} closePage={closePage} />
      )}

      {filterValue === DevicePossessionType.CompanySite && (
        <ChangeOwnerCompanyInventoryForm devicePossession={devicePossession} closePage={closePage} />
      )}
    </Box>
  );
};
