import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { SettingsReadOnlyLine } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component';
import { CustomDepActionsDrawer } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/custom-dep-actions-drawer.component';
import { useDepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook';
import { DepWizardHelper } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-wizard-helper';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { SETTINGS_DEVICES_DEP_ROUTE, SETTINGS_DEVICES_STORE_ROUTE } from '@/lib/routes';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceSettings } from '@/v2/feature/device/features/devices-settings/device-settings.interface';
import { DeviceSettingsAPI } from '@/v2/feature/device/features/devices-settings/devices-settings.api';
import { EnrolmentSettingsDrawer } from '@/v2/feature/device/features/devices-settings/features/general/components/enrolment-settings-drawer.component';
import { definitionSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SettingsCard = ({
  title,
  subCopy,
  onEdit,
  disabled,
  detail,
}: {
  title: string;
  subCopy: string;
  onEdit: () => void;
  disabled: boolean;
  detail?: JSX.Element;
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={themeFonts.title2}>{title}</Typography>
        <IconButton sx={tableIconButtonSx} onClick={onEdit} disabled={disabled}>
          <Edit {...iconSize} fill={themeColors.DarkGrey} />
        </IconButton>
      </Box>

      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>{subCopy}</Typography>

      {detail}
    </Box>
  );
};
export const DevicesGeneralPage = () => {
  const routerHistory = useHistory();
  const [isEnrollmentOpen, setIsEnrollmentOpen] = useState<boolean>(false);
  const [isCustomDepOpen, setIsCustomDepOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [generalSettings, setGeneralSettings] = useState<DeviceSettings | undefined>(undefined);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const mdmServerName = globalState.user.company.companyId + globalState.user.company.name;
  const details = useDepAccountDetails(mdmServerName);
  const getGeneralSettings = useCallback(async () => {
    try {
      setLoading(true);
      const settings = await DeviceSettingsAPI.getCompanyDeviceSettings();
      setGeneralSettings(settings);
    } catch (error) {
      showMessage('Could not load devices settings', 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    getGeneralSettings();
  }, [getGeneralSettings]);

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>} />
      <ContentWrapper loading={isLoading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: { xs: '100%', sm: '100%', md: '85%', lg: '70%', xl: '70%' },
            width: '100%',
            gap: spacing.g50,
          }}
        >
          <SettingsCard
            title="Auto enrolment"
            subCopy="Automated device enrolment streamlines the onboarding of company owned devices. Connect your Apple Business Manager to automatically enrol your devices in Zelt."
            onEdit={() =>
              details.depAccountDetails?.access_token_expiry
                ? setIsCustomDepOpen(true)
                : routerHistory.push(SETTINGS_DEVICES_DEP_ROUTE)
            }
            disabled={false}
            detail={
              details.depAccountDetails ? (
                <Box sx={{ ...definitionSx, mt: spacing.m15, display: 'flex', flexDirection: 'column' }}>
                  <SettingsReadOnlyLine field="Organisation" value={details.depAccountDetails?.org_name ?? 'N/A'} />
                  <SettingsReadOnlyLine
                    field="MDM Server name"
                    value={details.depAccountDetails?.server_name ?? 'N/A'}
                  />
                  <SettingsReadOnlyLine
                    field="Renew by"
                    value={
                      details.depAccountDetails?.access_token_expiry
                        ? DepWizardHelper.formatDate(details.depAccountDetails?.access_token_expiry)
                        : 'N/A'
                    }
                  />
                </Box>
              ) : undefined
            }
          />
          <SettingsCard
            title="Store settings"
            subCopy="Configure the range of Zelt devices and accessories that your employees can see and order"
            disabled={false}
            onEdit={() => routerHistory.push(SETTINGS_DEVICES_STORE_ROUTE)}
          />
        </Box>

        <DrawerModal isOpen={isEnrollmentOpen} setIsOpen={setIsEnrollmentOpen}>
          <EnrolmentSettingsDrawer
            generalSettings={generalSettings}
            onClose={async () => {
              setIsEnrollmentOpen(false);
              await getGeneralSettings();
            }}
          />
        </DrawerModal>

        <DrawerModal isOpen={isCustomDepOpen} setIsOpen={setIsCustomDepOpen}>
          <CustomDepActionsDrawer
            mdmServerName={mdmServerName}
            depAccountDetailsPar={details.depAccountDetails}
            onClose={async () => {
              details.refreshDepAccountDetails();
              setIsCustomDepOpen(false);
            }}
            onRefresh={async () => {
              details.refreshDepAccountDetails();
            }}
          />
        </DrawerModal>
      </ContentWrapper>
    </RootStyle>
  );
};
