import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { CategoryFilters } from '@v2/components/table/category-filters.component';
import { EmptyCell, IconEmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ChangeOwnerDrawer } from '@v2/feature/device/components/device-details/change-owner-drawer.component';
import { DeviceInventoryReassignDrawer } from '@v2/feature/device/components/devices-list-overview/device-inventory-reassign-drawer.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { DeviceOwnership, DevicePossessionType } from '@v2/feature/device/device.interface';
import {
  DeviceTypeFilterOptions,
  getDeviceOwnerByDevicePossession,
  getDeviceOwnerByDevicePossessionTable,
  getModelImage,
  getRiskStatus,
  MDMABLE_DEVICE_TYPES,
} from '@v2/feature/device/device.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { filterStringToObject } from '@v2/feature/user/user.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { keyBy } from 'lodash';
import { useLocation } from 'react-router-dom';

import { OrderActionsDrawer } from '../device-details/order-actions-drawer.component';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { ReactComponent as EnvelopeSimple } from '@/images/side-bar-icons/EnvelopeSimple.svg';
import { ReactComponent as Person } from '@/images/side-bar-icons/Person.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from '@/lib/routes';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { sortDate, sortString } from '@/v2/components/table/table-sorting.util';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface DevicesTableProps {
  readonly devicePossessions: readonly DevicePossessionDto[];
  readonly deviceTransits: readonly DeviceTransitDto[];
  readonly rowClick?: (devicePossessionDto: DevicePossessionDto) => void;
  readonly showAssignedTo?: boolean;
  readonly actionButton?: ReactNode;
  readonly showNotes?: boolean;
  readonly showOwners?: boolean;
  readonly showSecurity?: boolean;
  readonly showLastActive?: boolean;
  readonly showSearch?: boolean;
  readonly showDefaultActions?: boolean;
  readonly refresh: () => Promise<void>;
  readonly deviceLocation: 'inventory' | 'user';
  readonly checkedDeviceIds?: number[];
  readonly onDeviceCheckChange?: (id: number, checked: boolean) => void;
  readonly appliedHexPolicies?: AppliedDevicePoliciesDto;
  readonly appliedZeltPolicies?: AppliedDevicePoliciesZeltDtoWithConfigurableFeature;
  readonly appliedMobileZeltPolicies?: AppliedDevicePoliciesZeltDtoWithConfigurableFeature;
}

const iconSize = { width: 14, height: 14 } as const;

const DeviceFilterTypes = {
  type: DeviceTypeFilterOptions,
};

export const DevicesSelectTable = ({
  devicePossessions,
  deviceTransits,
  rowClick,
  actionButton,
  showAssignedTo = false,
  showNotes = false,
  showOwners = false,
  showSecurity = false,
  showLastActive = false,
  showSearch = false,
  showDefaultActions = false,
  refresh,
  deviceLocation = 'user',
  checkedDeviceIds,
  onDeviceCheckChange,
  appliedHexPolicies,
  appliedZeltPolicies,
  appliedMobileZeltPolicies,
}: DevicesTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [isAssignDeviceDrawerOpen, setIsAssignDeviceDrawerOpen] = useState<boolean>(false);
  const [isChangeOwnerDrawerOpen, setIsChangeOwnerDrawerOpen] = useState<boolean>(false);
  const [devicePossessionToBeAssigned, setDevicePossessionToBeAssigned] = useState<DevicePossessionDto | null>(null);
  const [devicePossessionToBeChanged, setDevicePossessionToBeChanged] = useState<DevicePossessionDto | null>(null);
  const [devicePossessionToBeReturned, setDevicePossessionToBeReturned] = useState<DevicePossessionDto | null>(null);
  const [isOrderActionsDrawerOpen, setIsOrderActionsDrawerOpen] = useState<boolean>(false);

  const { getCachedUserById } = useCachedUsers();

  const [filterString, setFilterString] = useState<string>('');
  const [searchInput, setSearchInput] = useState('');

  const [sitesById, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const [showMessage] = useMessage();
  const { pathname } = useLocation();

  const transitsByDeviceId = keyBy(deviceTransits, 'deviceId');

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesById(keyBy(sites, 'id'));
      } catch (error) {
        showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  const filteredDevices = useMemo(() => {
    let filteredDevicePossessions = devicePossessions.filter((devicePossession) => {
      const ownerName = getDeviceOwnerByDevicePossession(
        devicePossession,
        sitesById[devicePossession.possessionId],
        getCachedUserById
      );
      const modelName = devicePossession.device?.modelName;
      const serialNumber = devicePossession.device?.serialNumber ?? undefined;

      return (
        ownerName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        modelName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        serialNumber?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });

    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions.type) {
        filteredDevicePossessions = filteredDevicePossessions.filter(
          (devicePossession) =>
            devicePossession.device?.type && filterOptions.type.includes(devicePossession.device.type)
        );
      }
    }
    return filteredDevicePossessions;
  }, [devicePossessions, searchInput, filterString, getCachedUserById, sitesById]);

  const getUserName = useCallback(
    (assignedUserId: number): string | undefined => {
      const user = getCachedUserById(assignedUserId);
      return polyglot.t(user?.displayName ?? '');
    },
    [getCachedUserById, polyglot]
  );

  const columnData = useMemo<ColumnDef<DevicePossessionDto, DevicePossessionDto>[]>(() => {
    const getDeviceActionsOptions = (
      devicePossession: DevicePossessionDto,
      transits: { [deviceId: number]: DeviceTransitDto }
    ) => {
      return [
        {
          icon: <Person {...iconSize} />,
          handler: () => {
            setDevicePossessionToBeChanged(devicePossession);
            setIsChangeOwnerDrawerOpen(true);
          },
          label: 'Change owner',
          hidden: devicePossession.possessionType !== DevicePossessionType.User,
          disabled: Boolean(devicePossession.deviceId && transits[devicePossession.deviceId]),
        },
        {
          icon: <EnvelopeSimple {...iconSize} />,
          handler: async () => {
            try {
              if (devicePossession && devicePossession.id) await DeviceAPI.sendEnrollmentRequest(devicePossession.id);
              showMessage('Notification was successfully sent.', 'success');
            } catch (error) {
              showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
            }
          },
          label: 'Ask to enrol',
          hidden: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User || devicePossession.device?.enrollmentStatus
          ),
          disabled: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User || devicePossession.device?.enrollmentStatus
          ),
        },
        {
          icon: <Back {...iconSize} />,
          handler: () => {
            setDevicePossessionToBeReturned(devicePossession);
            setIsOrderActionsDrawerOpen(true);
          },
          label: 'Order action',
          hidden:
            !devicePossession.device ||
            // ZeltStorage device with ownership non-rental should not be able to be cancelled
            devicePossession.device?.ownership === DeviceOwnership.Company ||
            devicePossession.possessionType === DevicePossessionType.ZeltStorage,
          disabled: Boolean(devicePossession.deviceId && transits[devicePossession.deviceId]),
        },
      ];
    };
    return [
      ...(checkedDeviceIds
        ? [
            {
              id: 'checkedDevice',
              enableSorting: false,
              cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
                const { deviceId } = info.row.original;
                return (
                  <CheckboxComponent
                    label=""
                    checked={checkedDeviceIds.includes(deviceId)}
                    onChange={(_, checked) => onDeviceCheckChange?.(deviceId, checked)}
                  />
                );
              },
            },
          ]
        : []),
      {
        header: () => 'Device',
        accessorFn: (row) => row,
        id: 'modelName',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.device?.modelName),
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const devicePossession: DevicePossessionDto = info.getValue();
          return devicePossession.device?.modelName ? (
            <Box
              onClick={() => rowClick?.(devicePossession)}
              sx={{ cursor: 'pointer', display: 'flex', gap: spacing.g10, alignItems: 'center' }}
            >
              <Box>
                {devicePossession &&
                  getModelImage(devicePossession.device.type, devicePossession.device.modelName, {
                    width: '30px',
                    height: 'auto',
                  })}
              </Box>

              <div>{devicePossession.device.modelName}</div>
            </Box>
          ) : (
            <Box onClick={() => rowClick?.(devicePossession)} sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}>
              <IconEmptyCell />
            </Box>
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Device name',
        accessorFn: (row) => row,
        id: 'deviceName',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.device?.deviceName),
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const devicePossession: DevicePossessionDto = info.getValue();
          return devicePossession.device?.deviceName ? (
            <Box>{devicePossession.device.deviceName}</Box>
          ) : (
            <IconEmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Serial number',
        accessorFn: (row) => row,
        id: 'serialNumber',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.device?.serialNumber),
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const devicePossession: DevicePossessionDto = info.getValue();
          return devicePossession.device?.serialNumber ? (
            <Box>{devicePossession.device.serialNumber}</Box>
          ) : (
            <IconEmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      ...(showAssignedTo
        ? ([
            {
              header: () => (deviceLocation === 'inventory' ? 'Location' : 'Used by'),
              accessorFn: (row: DevicePossessionDto) => row,
              id: 'id',
              enableSorting: false,
              cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
                const devicePossession: DevicePossessionDto = info.getValue();
                if (devicePossession.possessionType === DevicePossessionType.User && devicePossession.possessionId)
                  return (
                    <Box
                      sx={{
                        ...themeFonts.caption,
                        display: 'flex',
                        alignItems: 'center',
                        gap: spacing.m5,
                      }}
                    >
                      <UserAvatar userId={devicePossession.possessionId} size="xxsmall" />
                      <div>{getUserName(devicePossession.possessionId)}</div>
                    </Box>
                  );
                return (
                  <Box>
                    {getDeviceOwnerByDevicePossessionTable(
                      devicePossession,
                      sitesById[devicePossession.possessionId],
                      polyglot,
                      getUserName(devicePossession.possessionId)
                    )}
                  </Box>
                );
              },
              maxSize: 200,
              minSize: 140,
            },
          ] as ColumnDef<DevicePossessionDto, DevicePossessionDto>[])
        : []),
      ...(showSecurity && (appliedZeltPolicies || appliedHexPolicies || appliedMobileZeltPolicies)
        ? ([
            {
              header: () => 'Security',
              accessorFn: (row) => row,
              id: 'security',
              enableSorting: false,
              cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
                const devicePossession: DevicePossessionDto = info.getValue();
                if (!devicePossession.device?.type || !MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type))
                  return <EmptyCell />;

                return getRiskStatus(
                  devicePossession,
                  appliedZeltPolicies,
                  appliedMobileZeltPolicies,
                  appliedHexPolicies
                );
              },
              maxSize: 180,
              minSize: 120,
            },
          ] as ColumnDef<DevicePossessionDto, DevicePossessionDto>[])
        : []),
      ...(showLastActive
        ? ([
            {
              header: () => 'Last active',
              accessorFn: (row) => row,
              id: 'lastActive',
              enableSorting: true,
              sortingFn: (a, b) => sortDate(a, b, (item) => item?.device?.lastCheckIn),
              cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
                const devicePossession: DevicePossessionDto = info.getValue();
                if (!devicePossession.device?.type || !MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type))
                  return <EmptyCell />;

                const lastCheckIn = devicePossession.device?.lastCheckIn;
                return lastCheckIn ? <Box>{getDateString(lastCheckIn)}</Box> : <IconEmptyCell />;
              },
              maxSize: 180,
              minSize: 120,
            },
          ] as ColumnDef<DevicePossessionDto, DevicePossessionDto>[])
        : []),
      ...(showOwners
        ? ([
            {
              header: () => 'Owner',
              accessorFn: (row) => row,
              id: 'ownership',
              enableSorting: true,
              sortingFn: (a, b) => sortString(a, b, (item) => item?.device?.ownership),
              cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
                const devicePossession: DevicePossessionDto = info.getValue();
                return devicePossession.device ? <Box>{devicePossession.device.ownership}</Box> : <IconEmptyCell />;
              },
              maxSize: 180,
              minSize: 120,
            },
          ] as ColumnDef<DevicePossessionDto, DevicePossessionDto>[])
        : []),
      ...(showNotes
        ? ([
            {
              header: () => 'Notes',
              accessorFn: (row: DevicePossessionDto) => row,
              id: 'notes',
              enableSorting: true,
              sortingFn: (a, b) => sortString(a, b, (item) => item?.device?.customerNotes),
              cell: ({ row: { original } }: { row: Row<DevicePossessionDto> }) => {
                return (
                  <Typography
                    variant="caption"
                    dangerouslySetInnerHTML={{ __html: original?.device?.customerNotes ?? '' }}
                    truncateHtml
                  />
                );
              },
              maxSize: 180,
              minSize: 120,
            },
          ] as ColumnDef<DevicePossessionDto, DevicePossessionDto>[])
        : []),
      ...(showDefaultActions
        ? [
            {
              header: () => '',
              accessorFn: (row: DevicePossessionDto) => row,
              id: 'actions',
              enableSorting: false,
              cell: ({ row: { original } }: { row: Row<DevicePossessionDto> }) => {
                return (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <StyledMenuComponent
                      options={getDeviceActionsOptions(original, transitsByDeviceId)}
                      actionButtonDetails={{
                        type: 'iconButton',
                        colorVariant: 'secondary',
                        sizeVariant: 'small',
                        title: 'actions',
                        icon: <ActionsSmall {...iconSize} />,
                      }}
                    />
                  </Box>
                );
              },
              maxSize: 50,
              minSize: 50,
            },
          ]
        : []),
    ];
  }, [
    checkedDeviceIds,
    showAssignedTo,
    showSecurity,
    appliedZeltPolicies,
    appliedHexPolicies,
    appliedMobileZeltPolicies,
    showLastActive,
    showOwners,
    showNotes,
    showDefaultActions,
    showMessage,
    onDeviceCheckChange,
    rowClick,
    deviceLocation,
    getUserName,
    sitesById,
    polyglot,
    transitsByDeviceId,
  ]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: spacing.g5 }}>
          {showSearch && devicePossessions.length > 0 && (
            <>
              <CategoryFilters
                filterTypes={DeviceFilterTypes}
                setFilterString={setFilterString}
                filterString={filterString}
              />
              <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
            </>
          )}
        </Box>
        {actionButton && pathname.includes(DEVICES_COMPANY_DIRECTORY_ROUTE) && <>{actionButton}</>}
      </Box>

      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable<DevicePossessionDto> rowData={filteredDevices} columnData={columnData} hidePagination />
      </Box>

      {devicePossessionToBeReturned && (
        <OrderActionsDrawer
          devicePossession={devicePossessionToBeReturned}
          isOpen={isOrderActionsDrawerOpen}
          setIsOpen={setIsOrderActionsDrawerOpen}
          onClose={async () => {
            await refresh();
            setDevicePossessionToBeReturned(null);
            setIsOrderActionsDrawerOpen(false);
          }}
        />
      )}

      {devicePossessionToBeChanged && (
        <ChangeOwnerDrawer
          isOpen={isChangeOwnerDrawerOpen}
          setIsOpen={setIsChangeOwnerDrawerOpen}
          onClose={async () => {
            await refresh();
            setDevicePossessionToBeChanged(null);
          }}
          devicePossession={devicePossessionToBeChanged}
        />
      )}
      {devicePossessionToBeAssigned && (
        <DeviceInventoryReassignDrawer
          isOpen={isAssignDeviceDrawerOpen}
          setIsOpen={setIsAssignDeviceDrawerOpen}
          onClose={async () => {
            await refresh();
            setDevicePossessionToBeAssigned(null);
          }}
          devicePossession={devicePossessionToBeAssigned}
        />
      )}
    </>
  );
};
