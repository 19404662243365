import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

import { AnalyticsAPI } from '@/api-client/index.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Analytics } from '@/models';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { neutralColors, themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

const chartColors = ['#567BC5', '#85a7ff', '#f1629d', '#F4701D', '#704C48', '#5e385f'];

const getLineChartOptions = (categories: readonly string[]): ApexOptions =>
  ({
    colors: chartColors,
    chart: {
      type: 'line',
      toolbar: {
        show: true,
      },
    },
    tooltip: {
      enabled: true,
      marker: {
        show: true,
      },
      theme: 'dark',
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    markers: {
      size: 3,
    },
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      tickAmount: 4,
    },
    yaxis: { forceNiceScale: true, min: 0 },
  } as const);

const getBarChartOptions = (categories: readonly string[]): ApexOptions => {
  return {
    colors: chartColors,
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    tooltip: {
      theme: 'dark',
      marker: {
        show: true,
      },
    },
    markers: {
      size: 5,
    },
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      tickAmount: 4,
    },
    yaxis: {
      forceNiceScale: true,
      showForNullSeries: false,
    },
  };
};

interface State {
  dates: string[];
  registeredCompanies: number[];
  registeredUsers: number[];
  activeUsers: number[];
  dac: number[];
  wac: number[];
  mac: number[];
  dau: number[];
  wau: number[];
  mau: number[];
  activeDevices: number[];
  activeApps: number[];
}

const initialState: State = {
  dates: [],
  registeredCompanies: [],
  registeredUsers: [],
  activeUsers: [],
  dac: [],
  wac: [],
  mac: [],
  dau: [],
  wau: [],
  mau: [],
  activeDevices: [],
  activeApps: [],
};

export const SuperAdminOverviewPage = (): JSX.Element => {
  const [analytics, setAnalytics] = useState<readonly Analytics[]>([]);
  const [dates, setDates] = useState<readonly string[]>([]);
  const [registeredCompanies, setRegisteredCompanies] = useState<readonly number[]>([]);
  const [dac, setDac] = useState<readonly number[]>([]);
  const [wac, setWac] = useState<readonly number[]>([]);
  const [mac, setMac] = useState<readonly number[]>([]);
  const [registeredUsers, setRegisteredUsers] = useState<readonly number[]>([]);
  const [activeUsers, setActiveUsers] = useState<readonly number[]>([]);
  const [dau, setDau] = useState<readonly number[]>([]);
  const [wau, setWau] = useState<readonly number[]>([]);
  const [mau, setMau] = useState<readonly number[]>([]);
  const [activeDevices, setActiveDevices] = useState<readonly number[]>([]);
  const [activeApps, setActiveApps] = useState<readonly number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const analytics = await AnalyticsAPI.getLatestAnalyticsRecord();

        const data = analytics.reduceRight((acc, record) => {
          acc.dates.push(new Date(record.createdAt).toLocaleString().split(',')[0]);
          acc.registeredCompanies.push(record.registeredCompanies);
          acc.registeredUsers.push(record.registeredUsers);
          acc.activeUsers.push(record.activeUsers);
          acc.dac.push(record.dac);
          acc.wac.push(record.wac);
          acc.mac.push(record.mac);
          acc.dau.push(record.dau);
          acc.wau.push(record.wau);
          acc.mau.push(record.mau);
          acc.activeDevices.push(record.activeDevices);
          acc.activeApps.push(record.activeApps);
          return acc;
        }, initialState);

        setDates(data.dates);
        setRegisteredCompanies(data.registeredCompanies);
        setRegisteredUsers(data.registeredUsers);
        setActiveUsers(data.activeUsers);
        setDac(data.dac);
        setWac(data.wac);
        setMac(data.mac);
        setDau(data.dau);
        setWau(data.wau);
        setMau(data.mau);
        setActiveDevices(data.activeDevices);
        setActiveApps(data.activeApps);

        setAnalytics(analytics);
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [showMessage]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Statistics</Typography>}
        views={<></>}
      />
      <ContentWrapper loading={loading}>
        {analytics.length > 0 && (
          <Box>
            <Box sx={{ borderTopWidth: 8, p: 2, borderColor: '#92c5fd' }}>
              <Typography variant="h6" sx={{ color: neutralColors.n6 }}>
                Registered Users
              </Typography>
              <Chart
                options={getBarChartOptions(dates)}
                series={[{ data: registeredUsers, name: 'Registered Users' }] as ApexOptions['series']}
                type="bar"
                width="100%"
                height="250px"
              />
            </Box>
            <Box sx={{ borderTopWidth: 8, p: 2, borderColor: '#92c5fd' }}>
              <Typography variant="h6" sx={{ color: neutralColors.n6 }}>
                Users Activity Over Time
              </Typography>
              <Chart
                options={getLineChartOptions(dates)}
                series={
                  [
                    {
                      data: activeUsers,
                      name: 'Active Users',
                      // value: 'activeUsers',
                    },
                    { data: dau, name: 'Daily Active Users' },
                    { data: wau, name: 'Weekly Active Users' },
                    { data: mau, name: 'Monthly Active Users' },
                  ] as ApexOptions['series']
                }
                type="line"
                width="100%"
                height="250px"
              />
            </Box>
            <Box sx={{ borderTopWidth: 8, p: 2, borderColor: '#92c5fd' }}>
              <Typography variant="h6" sx={{ color: neutralColors.n6 }}>
                Companies Over Time
              </Typography>
              <Chart
                options={getLineChartOptions(dates)}
                series={
                  [
                    {
                      data: registeredCompanies,
                      name: 'Registered Companies',
                    },
                    { data: dac, name: 'Daily Active Companies' },
                    { data: wac, name: 'Weekly Active Companies' },
                    { data: mac, name: 'Monthly Active Companies' },
                  ] as ApexOptions['series']
                }
                type="line"
                width="100%"
                height="250px"
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ borderTopWidth: 8, p: 2, borderColor: '#92c5fd', width: '49.5%' }}>
                <Typography variant="h6" sx={{ color: neutralColors.n6 }}>
                  Total Active Apps Over Time
                </Typography>
                <Chart
                  options={getBarChartOptions(dates)}
                  series={[{ data: activeApps, name: 'Active Apps' }] as ApexOptions['series']}
                  type="bar"
                  stacked={false}
                  width="100%"
                  height="250px"
                />
              </Box>
              <Box sx={{ borderTopWidth: 8, p: 2, borderColor: '#92c5fd', width: '49.5%' }}>
                <Typography variant="h6" sx={{ color: neutralColors.n6 }}>
                  Total Active Devices Over Time
                </Typography>
                <Chart
                  options={getBarChartOptions(dates)}
                  series={[{ data: activeDevices, name: 'Active Devices' }] as ApexOptions['series']}
                  type="bar"
                  stacked={false}
                  width="100%"
                  height="250px"
                />
              </Box>
            </Box>
          </Box>
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
