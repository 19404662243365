import { useCallback, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReportDeleteDrawer } from '@v2/feature/reports/reports-advanced/components/report-delete-drawer.component';
import { ReportTable } from '@v2/feature/reports/reports-advanced/components/report-table.component';
import { ReportsAPI } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { ReportResponse, SelectedColumnsRequest, SelectedFiltersRequest } from '@v2/feature/reports/reports.interface';
import { exportReportCSV } from '@v2/feature/reports/util/report.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE } from '@/lib/routes';

interface Props {
  readonly report: ReportDto | null;
  readonly isLoading: boolean;
  readonly reportResponse: ReportResponse | null;
  readonly selectedColumns: SelectedColumnsRequest;
  readonly selectedFilters: SelectedFiltersRequest;
  readonly refreshReportsList: () => Promise<void>;
  readonly refreshReport: () => Promise<void>;
}

export const ReportViewResult = ({
  report,
  isLoading,
  reportResponse,
  selectedColumns,
  selectedFilters,
  refreshReportsList,
  refreshReport,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();

  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>(report?.name ?? 'New report');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const saveNewReport = useCallback(
    async (name: string, columns: SelectedColumnsRequest, filters: SelectedFiltersRequest) => {
      if (!name) {
        showMessage('Please type a valid name before saving the report.', 'error');
        return;
      }
      try {
        setIsSaving(true);

        await ReportsAPI.saveAdvancedReport({ name, columns, filters });
        await refreshReportsList();

        history.push(REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setIsSaving(false);
    },
    [refreshReportsList, polyglot, showMessage, history]
  );

  const updateReport = useCallback(
    async (reportId: number, name: string, columns: SelectedColumnsRequest, filters: SelectedFiltersRequest) => {
      if (!name) {
        showMessage('Please type a valid name before saving the report.', 'error');
        return;
      }

      try {
        setIsSaving(true);

        await ReportsAPI.updateAdvancedReport(reportId, { name, columns, filters });
        await refreshReportsList();

        showMessage('Successfully updated report.', 'success');
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setIsSaving(false);
    },
    [refreshReportsList, polyglot, showMessage]
  );

  const exportCsv = useCallback(async () => {
    if (!reportResponse) return;

    try {
      exportReportCSV(reportName, reportResponse);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, reportName, reportResponse, showMessage]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <TopHeader
        title={
          <EditableTitle
            variant="title2"
            value={reportName}
            onChange={(value) => {
              setReportName(value);
            }}
            maxLength={64}
          />
        }
        showAction
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="small"
              onClick={async () => {
                setIsRefreshing(true);
                await refreshReport();
                setIsRefreshing(false);
              }}
              loading={isRefreshing}
            >
              <RefreshIcon sx={{ width: '18px', height: '18px' }} />
            </ButtonComponent>

            <StyledMenuComponent
              options={[
                {
                  icon: <Export {...iconSize} />,
                  handler: exportCsv,
                  label: polyglot.t('General.exportAsCSV'),
                },
                {
                  icon: <Copy {...iconSize} />,
                  handler: async () => {
                    await saveNewReport(reportName, selectedColumns, selectedFilters);
                  },
                  label: 'Save as new report',
                  hidden: !report,
                },
                {
                  icon: <Trash {...iconSize} />,
                  handler: () => {
                    setIsDeleteDrawerOpen(true);
                  },
                  label: polyglot.t('General.delete'),
                  disabled: false,
                  hidden: !report,
                },
              ]}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: polyglot.t('General.actions'),
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
              }}
            />

            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={async () => {
                if (report) await updateReport(report.id, reportName, selectedColumns, selectedFilters);
                else await saveNewReport(reportName, selectedColumns, selectedFilters);
              }}
              loading={isSaving}
            >
              {report ? 'Update' : 'Save'}
            </ButtonComponent>
          </Box>
        }
      />
      <ContentWrapper
        loading={isLoading}
        hideFooter
        loaderSx={{ minHeight: 'inherit' }}
        sx={{ paddingBottom: 0 }}
        noHorizontalPadding
        border={false}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ReportTable reportResponse={reportResponse} />
        </Box>

        {report && (
          <ReportDeleteDrawer
            isOpen={isDeleteDrawerOpen}
            setIsOpen={setIsDeleteDrawerOpen}
            report={report}
            refresh={refreshReportsList}
          />
        )}
      </ContentWrapper>
    </Box>
  );
};
