import React, { Suspense, useEffect, useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormDto } from '@v2/feature/custom-fields/custom-profile-fields.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { UserBasicsInfo } from '@/models/user.model';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { ProfilePicture } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/components/profile-picture.component';
import { spacing } from '@/v2/styles/spacing.styles';

type UserBasicInfoSectionProps = {
  userId: number;
  basicInfo: Partial<UserBasicsInfo>;
  form: CustomProfileFormDto | undefined;
  onNext: (basicInfo: Partial<UserBasicsInfo>) => void;
  sx?: SxProps<Theme>;
};

type BasicFormDefaultVisibility = {
  firstName: boolean;
  lastName: boolean;
  middleName: boolean;
  displayName: boolean;
};

const BasicInfoSchema = (visibility: BasicFormDefaultVisibility, polyglot: Polyglot) =>
  Yup.object().shape({
    firstName: visibility.firstName
      ? Yup.string().trim().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().notRequired(),
    lastName: visibility.lastName
      ? Yup.string().trim().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().notRequired(),
    middleName: Yup.string().nullable().notRequired(),
    displayName: visibility.displayName
      ? Yup.string().trim().required(polyglot.t('OnboardingByUser.validations.requiredField'))
      : Yup.string().notRequired(),
  });

export const UserBasicInfoSection = ({ basicInfo, onNext, sx, userId, form }: UserBasicInfoSectionProps) => {
  const { polyglot } = usePolyglot();
  const autoPopulateDisplayName = React.useMemo(() => !basicInfo.displayName, [basicInfo.displayName]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();

  const defaultFieldsVisibility: BasicFormDefaultVisibility = useMemo(() => {
    if (!form)
      return {
        firstName: false,
        lastName: false,
        middleName: false,
        displayName: false,
      };

    const firstNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'firstName');
    const lastNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'lastName');
    const middleNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'middleName');
    const displayNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'displayName');

    return {
      firstName: Boolean(firstNameField && !firstNameField.isHidden),
      lastName: Boolean(lastNameField && !lastNameField.isHidden),
      middleName: Boolean(middleNameField && !middleNameField.isHidden),
      displayName: Boolean(displayNameField && !displayNameField.isHidden),
    };
  }, [form]);

  const initialValues = useMemo(() => {
    const values: Partial<UserBasicsInfo> = {};
    if (defaultFieldsVisibility.firstName) values.firstName = basicInfo.firstName ?? '';
    if (defaultFieldsVisibility.lastName) values.lastName = basicInfo.lastName;
    if (defaultFieldsVisibility.middleName) values.middleName = basicInfo.middleName ?? '';
    if (defaultFieldsVisibility.displayName) values.displayName = basicInfo.displayName ?? '';
    values.customUpdates = basicInfo.customUpdates ?? [];

    return values;
  }, [defaultFieldsVisibility, basicInfo]);

  const formik = useFormik({
    initialValues,
    validationSchema: BasicInfoSchema(defaultFieldsVisibility, polyglot),
    onSubmit: async (values) => {
      setSaving(true);
      try {
        await UserAPI.patchUserBasicInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.basicInfoNotSaved', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  useEffect(() => {
    if (!autoPopulateDisplayName || !formik.values.firstName || !formik.values.lastName) return;

    const newDisplayName = `${formik.values.firstName.trim()} ${formik.values.lastName.trim()}`;
    if (newDisplayName === formik.values.displayName) return;

    formik.setFieldValue('displayName', `${formik.values.firstName} ${formik.values.lastName}`);
  }, [autoPopulateDisplayName, formik]);

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('OnboardingByUser.basicInfo')}</Typography>
          <Suspense fallback={<></>}>
            <ProfilePicture userId={userId} disabled={saving} />
          </Suspense>

          {defaultFieldsVisibility.firstName && (
            <TextfieldComponent
              name="firstName"
              label={polyglot.t('OnboardingByUser.firstName')}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              disabled={saving}
              clearText={() => formik.setFieldValue('firstName', '')}
              helperText={hasSubmitted && formik.errors.firstName}
              error={hasSubmitted && !!formik.errors.firstName}
              autoFocus
            />
          )}

          {defaultFieldsVisibility.lastName && (
            <TextfieldComponent
              name="lastName"
              label={polyglot.t('OnboardingByUser.lastName')}
              value={formik.values.lastName}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('lastName', '')}
              helperText={hasSubmitted && formik.errors.lastName}
              error={hasSubmitted && !!formik.errors.lastName}
            />
          )}

          {defaultFieldsVisibility.middleName && (
            <TextfieldComponent
              name="middleName"
              label={polyglot.t('OnboardingByUser.middleName')}
              value={formik.values.middleName}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('middleName', '')}
              helperText={hasSubmitted && formik.errors.middleName}
              error={hasSubmitted && !!formik.errors.middleName}
            />
          )}

          {defaultFieldsVisibility.displayName && (
            <TextfieldComponent
              name="displayName"
              label={polyglot.t('OnboardingByUser.displayName')}
              value={formik.values.displayName}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('displayName', '')}
              helperText={hasSubmitted && formik.errors.displayName}
              error={hasSubmitted && !!formik.errors.displayName}
            />
          )}

          <CustomFieldComponents
            values={formik.values.customUpdates ?? []}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            disabled={saving}
            fieldSx={{}}
          />

          <LoaderButton
            name={polyglot.t('General.continue')}
            loading={saving}
            sizeVariant="large"
            colorVariant="primary"
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
