import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory, useLocation } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_MONEY_EXPENSES_ROUTE, SETTINGS_MONEY_INVOICES_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  PaymentCategoryEnum,
  PaymentTypeSettingAccountingAppConfig,
  PaymentTypeSettings,
  isExpenseCheck,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { PaymentTypeSettingsAPI } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly refreshAllPaymentSettings: KeyedMutator<PaymentTypeSettingAccountingAppConfig>;
}

export const DeletePaymentTypeDrawer = ({
  isOpen,
  setIsOpen,
  paymentTypeSetting,
  refreshAllPaymentSettings,
}: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <DeletePaymentTypeDrawerContent
        paymentTypeSetting={paymentTypeSetting}
        setIsOpen={setIsOpen}
        refreshAllPaymentSettings={refreshAllPaymentSettings}
      />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refreshAllPaymentSettings: KeyedMutator<PaymentTypeSettingAccountingAppConfig>;
}

export const DeletePaymentTypeDrawerContent = ({
  paymentTypeSetting,
  setIsOpen,
  refreshAllPaymentSettings,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDelete, setIsDelete] = useState(false);
  const { pathname } = useLocation();
  const isExpense = isExpenseCheck(pathname);
  const history = useHistory();

  const removePaymentType = async () => {
    if (paymentTypeSetting && paymentTypeSetting.id) {
      try {
        setIsDelete(true);
        await PaymentTypeSettingsAPI.delete(paymentTypeSetting.id);
        showMessage(polyglot.t('PaymentSettingsNewTypeDrawer.successMessages.deleteType'), 'success');
        refreshAllPaymentSettings?.();
        history.push(
          paymentTypeSetting.type === PaymentCategoryEnum.EXPENSE
            ? SETTINGS_MONEY_EXPENSES_ROUTE
            : SETTINGS_MONEY_INVOICES_ROUTE
        );
      } catch (error) {
        showMessage(`Failed to delete ${paymentTypeSetting.type} type: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setIsOpen(false);
        setIsDelete(false);
      }
    }
  };

  return (
    <Box>
      <Typography variant="title2" sx={{ mb: spacing.m30 }}>
        {paymentTypeSetting.name}
      </Typography>

      <Typography variant="caption">
        {polyglot.t('PaymentSettingsPage.deleteConfirm', { settingType: isExpense ? 'expense' : 'invoice' })}
      </Typography>

      <Box sx={{ ...spacing.mt30, display: 'flex', gap: '10px' }}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.delete')}
          sizeVariant="medium"
          colorVariant="danger"
          loading={isDelete}
          onClick={removePaymentType}
          fullWidth
        />
      </Box>
    </Box>
  );
};
