import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { useHistory } from 'react-router-dom';

import { LOGIN_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

type AuthLoginButtonProps = {
  readonly sx?: SxProps<Theme>;
};

export const AuthLoginButton = ({ sx }: AuthLoginButtonProps) => {
  const history = useHistory();

  return (
    <Stack direction="row" alignItems="center" sx={{ ...sx }}>
      <ButtonComponent
        type="button"
        sizeVariant="medium"
        colorVariant="primary"
        onClick={() => history.push(LOGIN_ROUTE)}
      >
        Log in
      </ButtonComponent>
      {window.outerWidth > 400 && (
        <Typography variant="caption" color="DarkGrey" sx={{ ml: '0.5em' }}>
          if you already have an account
        </Typography>
      )}
    </Stack>
  );
};
