import { Fragment } from 'react';

import { Collapse, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as NewArrowDown } from '@/images/side-bar-icons/NewArrowDown.svg';
import { ReactComponent as NewArrowUp } from '@/images/side-bar-icons/NewArrowUp.svg';
import { getActiveSub } from '@/v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util';
import { SubItemsProps, SubMenuProps } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { activeSubItemStyle, NavSubItemStyle, SubItemStyle } from '@/v2/styles/list-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: '12px', height: '12px' } as const;

export const renderSingleItem = (
  list: SubItemsProps,
  action: (list: SubItemsProps) => void,
  pathname: string,
  index: number
) => {
  if (list.isHidden) return null;

  return (
    <Link
      key={`${list.stub}-${index}-link`}
      to={list.path ?? ''}
      component={RouterLink}
      style={{ textDecoration: 'none' }}
    >
      <NavSubItemStyle
        key={list.stub}
        sx={{ ...(list.path && getActiveSub(pathname, list.path) && activeSubItemStyle) }}
        onClick={() => {
          action(list);
        }}
        className={list.path && getActiveSub(pathname, list.path) ? 'no-hover-effect' : 'hover-effect'}
      >
        <SubItemStyle
          sx={{ ...(list.path && getActiveSub(pathname, list.path) ? themeFonts.title4 : themeFonts.caption) }}
        >
          {list.title}
        </SubItemStyle>
      </NavSubItemStyle>
    </Link>
  );
};

export const renderSubMenu = (
  list: SubItemsProps,
  handleParentClick: (list: SubItemsProps) => void,
  pathname: string,
  activeSubMenuParent: string | undefined,
  handleSubmenuClick: (sm: SubMenuProps) => void,
  index: number
) => {
  if (!list.subMenu || list.isHidden || !list.hasChildren) return null;
  return (
    <Fragment key={`${list.stub}-${index}-subitem`}>
      <NavSubItemStyle
        key={`${list.stub}-${index}-subitem`}
        onClick={() => handleParentClick(list)}
        className={list.path && getActiveSub(pathname, list.path) ? 'no-hover-effect' : 'hover-effect'}
      >
        <SubItemStyle>{list.title}</SubItemStyle>
        {activeSubMenuParent === list.stub ? <NewArrowUp {...iconSize} /> : <NewArrowDown {...iconSize} />}
      </NavSubItemStyle>
      <Collapse
        key={`${list.stub}-${index}-collapse`}
        in={activeSubMenuParent === list.stub}
        timeout="auto"
        unmountOnExit
      >
        {list.subMenu.map(
          (sm, idx) =>
            !sm.isHidden && (
              <Link
                to={sm.path ?? ''}
                component={RouterLink}
                style={{ textDecoration: 'none' }}
                key={`${list.stub}-${idx}-link`}
              >
                <NavSubItemStyle
                  key={sm.stub}
                  sx={{
                    paddingLeft: spacing.p10,
                    ...(sm.path && getActiveSub(pathname, sm.path) && activeSubItemStyle),
                  }}
                  onClick={() => handleSubmenuClick(sm)}
                  className={sm.path && getActiveSub(pathname, sm.path) ? 'no-hover-effect' : 'hover-effect'}
                >
                  <SubItemStyle
                    sx={{
                      ...(sm.path && getActiveSub(pathname, sm.path) ? themeFonts.title4 : themeFonts.caption),
                    }}
                  >
                    {sm.title}
                  </SubItemStyle>
                </NavSubItemStyle>
              </Link>
            )
        )}
      </Collapse>
    </Fragment>
  );
};
