import React, { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useState } from 'react';

import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto, ExternalUserCredentialsDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType } from '@v2/feature/device/device.interface';
import { DeviceActivationSection } from '@v2/feature/device/features/activation-modal/components/device-activation-section.component';

import useMessage from '@/hooks/notification.hook';
import { themeColors } from '@/v2/styles/colors.styles';

interface DeviceCredentialsDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
}

export const DeviceCredentialsDrawer = ({ isOpen, setIsOpen, devicePossession }: DeviceCredentialsDrawerProps) => {
  const [credentials, setCredentials] = useState<ExternalUserCredentialsDto | undefined>(undefined);

  const [showMessage] = useMessage();

  const getDeviceAccountCredentials = useCallback(async () => {
    try {
      if (devicePossession.possessionType !== DevicePossessionType.User) {
        showMessage('This device is not assigned to a user.', 'error');
        return;
      }
      const credentials = await DeviceAPI.getDeviceAssignedExternalUserCredentials(devicePossession.possessionId);
      setCredentials(credentials);
    } catch (error) {
      showMessage('Could not retrieve the credentials.', 'error');
    }
  }, [showMessage, devicePossession.possessionType, devicePossession.possessionId]);

  useEffect(() => {
    getDeviceAccountCredentials();
  }, [getDeviceAccountCredentials]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <DeviceActivationSection credentials={credentials} />
      </Suspense>
    </DrawerModal>
  );
};
