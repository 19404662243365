import { Route, Switch } from 'react-router-dom';

import { TIME_ME_CALENDAR_ROUTE } from '@/lib/routes';
import { CalendarPage } from '@/v2/feature/calendar/features/calendar.page';

export const CalendarMeRouter = () => {
  return (
    <Switch>
      <Route path={[TIME_ME_CALENDAR_ROUTE]}>
        <CalendarPage view="me" />
      </Route>
    </Switch>
  );
};
