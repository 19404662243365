import { useState } from 'react';

import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { JobPositionSettingTable } from '@/v2/feature/job-position/job-position-settings/features/components/job-position-table.component';
import { JobPositionEndpoints } from '@/v2/feature/job-position/job-position.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';

export const JobPositionSettingPage = () => {
  const [postSave, setPostSave] = useState<boolean>(false);

  const {
    data: jobPositions,
    isValidating: loadingJobPositions,
    mutate: refreshJobPositions,
  } = useApiClient(JobPositionEndpoints.listJobPositions(), { suspense: false });

  const refreshJobPositionData = async () => {
    if (!refreshJobPositions) return;
    setPostSave(false);
    await refreshJobPositions();
    setPostSave(true);
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Job positions</Typography>} />
      <ContentWrapper loading={false} secondLevel={true}>
        <Box>
          <JobPositionSettingTable
            jobPositions={jobPositions ?? []}
            refreshJobPositions={refreshJobPositionData}
            loading={!postSave ? loadingJobPositions || loadingJobPositions : false}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
