import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PayrollEmploymentAllowanceDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/employer-allowance-settings/payroll-employer-allowance-drawer.component';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { primarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

interface PayrollEmploymentAllowanceProps {
  readonly payrollId: number;
  readonly disabled?: boolean;
  readonly setOtherEdit: (_: boolean) => void;
  readonly setShowEditButton: React.Dispatch<React.SetStateAction<boolean>>;
  readonly empAllowanceDrawerOpen: boolean;
  readonly setEmpAllowanceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PayrollEmploymentAllowance = ({
  payrollId,
  disabled,
  setOtherEdit,
  setShowEditButton,
  empAllowanceDrawerOpen,
  setEmpAllowanceDrawerOpen,
}: PayrollEmploymentAllowanceProps): JSX.Element => {
  const [employmentAllowance, setEmploymentAllowance] = useState<EmploymentAllowanceStatus>();
  const [showMessage] = useMessage();

  const refreshEmploymentAllowanceStatus = useCallback(async () => {
    try {
      const employmentAllowance = await PayrollAPI.getEmploymentAllowance(payrollId);
      setEmploymentAllowance(employmentAllowance);
      setShowEditButton(!!employmentAllowance?.current);
    } catch (error) {
      showMessage(`Failed to retrieve Employer Allowance. ${nestErrorMessage(error)}`, 'error');
    }
  }, [payrollId, setShowEditButton, showMessage]);

  useEffect(() => {
    if (!empAllowanceDrawerOpen) {
      refreshEmploymentAllowanceStatus();
    }
  }, [refreshEmploymentAllowanceStatus, empAllowanceDrawerOpen]);

  if (!employmentAllowance) {
    return <></>;
  }

  return (
    <Box>
      <Stack sx={{ gap: spacing.g10 }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
          Employment Allowance allows{' '}
          <a
            href="https://www.gov.uk/claim-employment-allowance"
            target="_blank"
            rel="noreferrer"
            style={{ color: themeColors.DarkGrey }}
          >
            eligible employers
          </a>{' '}
          to reduce their annual National&nbsp;Insurance liability by up to{' '}
          {formatCurrency(employmentAllowance.maxAllowance, { wholeNumber: true })}.
        </Typography>

        {employmentAllowance.current ? (
          <>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              Allowance: {formatCurrency(employmentAllowance.current.allowance)}
            </Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              Remaining: {formatCurrency(employmentAllowance.current.allowance - employmentAllowance.current.claimed)}
            </Typography>
          </>
        ) : (
          <>
            {!employmentAllowance.canRequest && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                Employment allowance can only be requested after PAYE details have been configured.
              </Typography>
            )}
            <Button
              sx={{ ...primarySmallBtn, width: '140px' }}
              disabled={disabled || !employmentAllowance.canRequest}
              onClick={() => setEmpAllowanceDrawerOpen(true)}
            >
              Request
            </Button>
          </>
        )}
      </Stack>
      <PayrollEmploymentAllowanceDrawer
        payrollId={payrollId}
        isOpen={empAllowanceDrawerOpen}
        setIsOpen={() => {
          setEmpAllowanceDrawerOpen(false);
          setOtherEdit(false);
        }}
      />
    </Box>
  );
};
