import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@v2/components/typography/typography.component';
import { AppIntegrationEndpoints } from '@v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserEmailDto,
  InstalledAppDto,
  UserAppDto,
} from '@v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub, AppsWithLogins } from '@v2/feature/app-integration/app-integration.interface';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { tsFormat } from '@v2/feature/monitoring/monitoring.util';
import { OffboardingState } from '@v2/feature/offboarding/offboarding-process/offboarding-process.page';
import { OffboardingAPI } from '@v2/feature/offboarding/offboarding.api';
import { getUserAppDtoForInstalledAppViaScheduledAction } from '@v2/feature/offboarding/offboarding.util';
import { UserAPI } from '@v2/feature/user/user.api';
import { MANUALLY_EXCLUDED_BOARDING_APPS } from '@v2/feature/user-onboarding/by-admin/interface/onboarding.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { format } from 'date-fns';
import Polyglot from 'node-polyglot';
import { generatePath } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as RejectedGrey } from '@/images/side-bar-icons/Rejected-grey.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { nestErrorMessage } from '@/lib/errors';
import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { DeactivateAppsDrawer } from '@/v2/feature/offboarding/components/deactivate-apps-drawer.component';
import { RootStyle } from '@/v2/styles/root.styles';

const OffboardingAppIcon = ({ stub, size = 60 }: { stub: string; size?: number }) => {
  return (
    <Box sx={{ width: `${size}px`, height: `${size}px` }}>
      <img src={`/app-icons-v2/images/${stub}.png`} width={`${size}px`} alt={stub} style={{ borderRadius: '50%' }} />
    </Box>
  );
};

const getStatusIcon = (status: string | null | undefined, polyglot: Polyglot) => {
  switch (status) {
    case polyglot.t('OffboardingApps.active'):
      return <OkGreen style={{ fill: themeColors.Green }} />;
    case polyglot.t('OffboardingApps.noAccess'):
      return <RejectedGrey />;
    case polyglot.t('OffboardingApps.suspended'):
      return <RejectedGrey />;
    case polyglot.t('OffboardingApps.invited'):
      return <Waiting style={{ fill: themeColors.DarkGrey }} />;
  }
};

interface PageProps {
  readonly userId: number;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
  readonly offboardingState: OffboardingState;
}

export const OffboardingAppsPage = ({ userId, offboardingState, refresh, loading }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [companyApps, setCompanyApps] = useState<InstalledAppDto[]>([]);
  const [userApps, setUserApps] = useState<UserAppDto[]>([]);
  const [selectedUserApp, setSelectedUserApp] = useState<UserAppDto | undefined>(undefined);
  const [existingDeactivationDate, setExistingDeactivationDate] = useState<string | undefined>(undefined);
  const {
    data: delayedActions,
    mutate: refreshDelayedActions,
  } = useApiClient(AppIntegrationEndpoints.getDelayedAppActionsByUserId(userId), { suspense: false });

  const [showMessage] = useMessage();

  const getUserApps = useCallback(async () => {
    try {
      setIsLoading(true);
      // TODO: these 3 requests should be 1 single offboarding requests that should return only the data needed here
      const [apps, allUserApps, userAccount] = await Promise.all([
        OffboardingAPI.getOffboardingCompanyApps(),
        OffboardingAPI.getOffboardingAppsForUser(userId),
        UserAPI.getUserAccountInfo(userId),
      ]);

      const zeltApp = {
        stub: 'zelt' as AppIntegrationStub,
        userId,
        userStatus: userAccount?.deactivationDate
          ? `Account will be deactivated on ${format(
              new LocalDate(userAccount.deactivationDate).getDate(),
              tsFormat
            )} UTC`
          : userAccount?.accountStatus,
      };

      const filteredUserApps = allUserApps.filter((app) => !MANUALLY_EXCLUDED_BOARDING_APPS.includes(app.stub));
      const companyAppStubs = apps.map((a) => a.stub);
      const userAppStubsWithDelayedActions: UserAppDto[] = [];
      const delayedAppActions = delayedActions?.delayed ?? [];

      for (const eachDelayedAction of delayedAppActions) {
        if (companyAppStubs.includes(eachDelayedAction.data.appStub)) {
          const installedAppWithDelayedActionForUser = apps.find((app) => app.stub === eachDelayedAction.data.appStub);
          if (installedAppWithDelayedActionForUser) {
            userAppStubsWithDelayedActions.push(
              getUserAppDtoForInstalledAppViaScheduledAction(installedAppWithDelayedActionForUser, eachDelayedAction)
            );
          }
        }
      }

      const delayedActionStubs = new Set(userAppStubsWithDelayedActions.map((app) => app.stub));

      const uniqueFilteredUserApps = filteredUserApps.filter((app) => !delayedActionStubs.has(app.stub));

      setCompanyApps(apps);
      setUserApps([...uniqueFilteredUserApps, ...userAppStubsWithDelayedActions, zeltApp]);

      if (userAccount?.deactivationDate) setExistingDeactivationDate(userAccount?.deactivationDate);
      setIsLoading(false);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  }, [userId, delayedActions, showMessage]);

  useEffect(() => {
    getUserApps();
  }, [getUserApps]);

  const delayedActionsByStubForCurrentUser = useMemo(() => {
    const appStubsForUser = userApps.map((a) => a.stub);
    const relevantDelayedActions = delayedActions?.delayed?.filter((d) => appStubsForUser.includes(d.data.appStub));
    return relevantDelayedActions
      ? new Map(relevantDelayedActions.map((delayedAction) => [delayedAction.data.appStub, delayedAction]))
      : undefined;
  }, [delayedActions?.delayed, userApps]);

  const selectedAppIsZelt = useMemo(() => {
    return selectedUserApp?.stub === 'zelt';
  }, [selectedUserApp?.stub]);

  const columnData = useMemo<ColumnDef<UserAppDto, UserAppDto>[]>(
    () => [
      {
        header: () => polyglot.t('OffboardingApps.apps'),
        id: 'appName',
        size: 120,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const app = companyApps?.find((companyApp) => companyApp.stub === original.stub) as InstalledAppDto;
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
              <OffboardingAppIcon stub={original.stub} size={20} />
              <Typography variant="caption">{original.stub === 'zelt' ? 'Zelt' : app?.name}</Typography>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('OffboardingApps.userStatus'),
        id: 'userStatus',
        size: 120,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.stub === 'zelt' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
              {getStatusIcon(original.userStatus, polyglot)}
              <Typography variant="caption">{original.userStatus}</Typography>
            </Box>
          ) : (
            <div>
              {AppsWithLogins.includes(original.stub) ? (
                <Box>
                  {original?.emails && original?.emails.length > 0 ? (
                    original?.emails?.map((emailObj: AppIntegrationUserEmailDto) => {
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                          {getStatusIcon(emailObj.status, polyglot)}
                          <Typography variant="caption">{emailObj.status}</Typography>
                        </Box>
                      );
                    })
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                      {getStatusIcon(original.userStatus, polyglot)}
                      <Typography variant="caption">{original.userStatus}</Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  {original?.emails ? (
                    original?.emails?.map((emailObj: AppIntegrationUserEmailDto) => {
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                          {getStatusIcon(emailObj.status, polyglot)}
                          <Typography variant="caption">{emailObj.status}</Typography>
                        </Box>
                      );
                    })
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                      {getStatusIcon(original.userStatus, polyglot)}
                      <Typography variant="caption">{original.userStatus}</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </div>
          );
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return !original?.scheduledAction ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setSelectedUserApp(original);
                  setIsOpen(true);
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            </Box>
          ) : (
            <></>
          );
        },
        maxSize: 150,
        minSize: 100,
      },
    ],
    [polyglot, companyApps]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('OffboardingPage.apps')}</Typography>}
        showBack
        backPath={generatePath(USER_OFFBOARDING_ROUTE, { userId })}
      />
      <ContentWrapper loading={loading}>
        <Box sx={{ maxWidth: '600px', width: '100%' }}>
          <Typography variant="caption">{polyglot.t('OffboardingApps.deactivateDesc')}</Typography>

          <Box sx={{ mt: spacing.s2 }}>
            <BasicTable rowData={userApps} columnData={columnData} hidePagination loading={isLoading} />
          </Box>

          {selectedUserApp && (
            <DeactivateAppsDrawer
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              userId={userId}
              userApp={selectedUserApp}
              userLeaveDate={offboardingState.leaveDate}
              onClose={() => setIsOpen(false)}
              refresh={async () => {
                await Promise.all([refresh(), getUserApps()]);
                await refreshDelayedActions?.();
                setIsOpen(false);
              }}
              existingDelayedAction={
                !selectedAppIsZelt && selectedUserApp && delayedActionsByStubForCurrentUser
                  ? delayedActionsByStubForCurrentUser.get(selectedUserApp.stub as AppIntegrationStub)
                  : undefined
              }
              existingDeactivationDate={
                selectedAppIsZelt && existingDeactivationDate ? existingDeactivationDate : undefined
              }
            />
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
