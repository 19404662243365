import { useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import { ListItemIcon } from '@mui/material';
import Lottie from 'lottie-react';

import SupportLoop from '@/animations/Support/Support_loop_30x30.json';
import SupportLoopFill from '@/animations/Support/Support_loop_FILL_30x30.json';
import { Typography } from '@/v2/components/typography/typography.component';
import { NavItemStyle } from '@/v2/styles/list-button.styles';

const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
`;

export const HelpHeader = ({
  headerName,
  onClick,
  isOpen,
}: {
  headerName: string;
  onClick: () => void;
  isOpen: boolean;
}) => {
  const lottieRef1 = useRef<any>(null);
  const lottieRef2 = useRef<any>(null);

  useEffect(() => {
    lottieRef1.current?.setSpeed(1.3);
    lottieRef2.current?.setSpeed(1.3);
  }, []);
  return (
    <NavItemStyle
      onClick={onClick}
      key="Support"
      onMouseOver={() => {
        lottieRef1.current?.play();
        lottieRef2.current?.play();
      }}
      onMouseEnter={() => {
        lottieRef1.current?.play();
        lottieRef2.current?.play();
      }}
      onMouseLeave={() => {
        lottieRef1.current?.stop();
        lottieRef2.current?.stop();
      }}
    >
      <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
        <LottieContainer
          style={{
            display: isOpen ? 'flex' : 'none',
          }}
        >
          <Lottie
            lottieRef={lottieRef2}
            animationData={SupportLoopFill}
            loop={true}
            autoplay={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </LottieContainer>

        <LottieContainer
          style={{
            display: isOpen ? 'none' : 'flex',
          }}
        >
          <Lottie
            lottieRef={lottieRef1}
            animationData={SupportLoop}
            loop={true}
            autoplay={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </LottieContainer>
      </ListItemIcon>
      <Typography variant={isOpen ? 'title5' : 'captionSmall'}>{headerName}</Typography>
    </NavItemStyle>
  );
};
