import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { EditableUserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { SchemaValues } from '@/v2/infrastructure/forms/remote/formik-helper.util';
import { RemoteFormBuilder } from '@/v2/infrastructure/forms/remote/remote-form-builder.component';
import { LocalDate } from '@/v2/util/local-date';

interface EditUserPayrollNonUKProps {
  readonly payrollId?: string;
  readonly payrollSchema: PayrollSchema;
  readonly remotePayrollValues?: NonUKPayrollValues | null;
  readonly effectiveDate?: string;
  readonly changeReason?: string | null;
  readonly savePayrollRecord: (record: EditableUserPayrollDto) => Promise<boolean>;
  readonly close: () => void;
  readonly isUserUpdating: boolean;
  readonly requireReason?: boolean;
  readonly userPayrollId?: number;
}

export const EditUserPayrollNonUK = ({
  payrollId,
  payrollSchema,
  remotePayrollValues,
  isUserUpdating,
  close,
  savePayrollRecord,
  changeReason: initialChangeReason,
  effectiveDate,
  requireReason,
  userPayrollId,
}: EditUserPayrollNonUKProps) => {
  const { polyglot } = usePolyglot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeReason, setChangeReason] = useState<string | null>(initialChangeReason ?? null);
  const [changeReasonError, setChangeReasonError] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (values: SchemaValues) => {
      const changeReasonValid = !requireReason || !!changeReason;
      setChangeReasonError(!changeReasonValid);
      if (!changeReasonValid) return;

      setIsSubmitting(true);

      const saved = await savePayrollRecord({
        payrollId,
        countryCode: payrollSchema.countryCode,
        payrollValues: null,
        remotePayrollValues: values,
        effectiveDate: effectiveDate || new LocalDate().toDateString(),
        changeReason,
        userPayrollId,
      });
      if (!saved) {
        setIsSubmitting(false);
        return;
      }

      close();
    },
    [
      changeReason,
      close,
      effectiveDate,
      payrollId,
      payrollSchema.countryCode,
      requireReason,
      savePayrollRecord,
      userPayrollId,
    ]
  );

  return (
    <RemoteFormBuilder
      onSubmit={onSubmit}
      schema={payrollSchema.schema}
      values={remotePayrollValues}
      style={drawerContentSx}
    >
      <Box sx={drawerContentSx}>
        {requireReason && (
          <TextfieldComponent
            label={polyglot.t('OnboardingByUser.changeReason')}
            name="changeReason"
            value={changeReason ?? ''}
            onChange={(e) => setChangeReason(e.target.value)}
            clearText={() => setChangeReason('')}
            error={changeReasonError}
            helperText={changeReasonError ? polyglot.t('OnboardingByUser.validations.requiredField') : ' '}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="secondary"
            fullWidth
            onClick={() => close()}
            disabled={isUserUpdating}
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={isSubmitting}
            fullWidth
            disabled={isUserUpdating}
            colorVariant="primary"
            sizeVariant="medium"
          />
        </Box>
      </Box>
    </RemoteFormBuilder>
  );
};
