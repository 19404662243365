import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';

import { CompanyAPI } from '@/api-client/index.api';
import { UploadProfilePicture } from '@/component/forms/UploadProfilePicture';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';

interface CompanySettingsGeneralEditProps {
  readonly generalSettings: GeneralSettingsDto | null;
  readonly refreshSettings: KeyedMutator<GeneralSettingsDto> | undefined;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
}

const validationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup.string().required(polyglot.t('CompanySettingsGeneralEdit.errorMessages.displayNameRequired')),
  });
export const CompanySettingsDetailsDrawer = ({
  generalSettings,
  refreshSettings,
  setEditMode,
  editMode,
}: CompanySettingsGeneralEditProps) => {
  return (
    <DrawerModal isOpen={editMode} setIsOpen={setEditMode}>
      <CompanySettingsDetailsDrawerContent
        generalSettings={generalSettings}
        refreshSettings={refreshSettings}
        setEditMode={setEditMode}
      />
    </DrawerModal>
  );
};

interface CompanySettingsDetailsDrawerContentProps {
  readonly generalSettings: GeneralSettingsDto | null;
  readonly refreshSettings: KeyedMutator<GeneralSettingsDto> | undefined;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanySettingsDetailsDrawerContent = ({
  generalSettings,
  refreshSettings,
  setEditMode,
}: CompanySettingsDetailsDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [localAvatar, setLocalAvatar] = useState<string>();

  useEffect(() => {
    setLocalAvatar(state.user.company.avatar);
  }, [state.user.company.avatar, setLocalAvatar]);

  const formik = useFormik({
    initialValues: {
      name: generalSettings?.name || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema(polyglot),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const update = {
          name: values.name,
          showWallpaper: generalSettings?.showWallpaper,
        };

        await CompanyAPI.updateGeneralSettings(update);
        const res = await AuthAPI.getAuthMe(false);
        await refreshSettings?.();
        setEditMode(false);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: res.user,
        });
        showMessage(polyglot.t('CompanySettingsGeneralEdit.successMessages.update'), 'success');
      } catch (error) {
        showMessage(
          `${polyglot.t('CompanySettingsGeneralEdit.errorMessages.update')}: ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('CompanySettingsGeneralEdit.editCompany')}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Typography variant="captionSmall" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('CompanySettingsGeneralEdit.companyLogo')}
          </Typography>

          <UploadProfilePicture
            disabled={false}
            value={localAvatar}
            onChange={(tempAvatar) => {
              setLocalAvatar(tempAvatar);
            }}
            url={CompanyAPI.getCompanyUploadURL()}
            userId={null}
          />
        </Box>

        <TextfieldComponent
          label={polyglot.t('CompanySettingsGeneralEdit.displayName')}
          name="name"
          value={formik.values.name}
          type="string"
          onChange={formik.handleChange}
          error={formik.touched.name && !!formik.errors.name}
          helperText={(formik.touched.name && formik.errors.name) ?? ' '}
          clearText={() => formik.setFieldValue('name', '')}
          autoFocus
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="secondary"
            disabled={loading}
            onClick={() => setEditMode(false)}
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            type="submit"
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            disabled={loading}
            loading={loading}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
