import { Dispatch, SetStateAction, Suspense } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { HolidaysProratingRule, ProRatedAbsenceAllowanceBreakdownSegment } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, isHourlyPolicy } from '@v2/feature/absence/absence.util';
import {
  getHolidaysProratingRuleLong,
  getPublicHolidayAllowanceFromBreakdown,
  isoDateToLocale,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { Chip } from '@v2/feature/absence/me/policies/policy-breakdown/components/prorated-allowance-details-drawer.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { round2Digits } from '@v2/util/number.util';

interface PublicHolidayAllowanceDetailsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

export const PublicHolidayAllowanceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats,
}: PublicHolidayAllowanceDetailsDrawerProps) => {
  const { polyglot } = usePolyglot();
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box>
          <Typography variant="title2">{polyglot.t('AllowanceDrawer.publicHolidayAllowance')}</Typography>
          <PublicHolidayAllowanceDetailsDrawerContent
            userBalanceDetailedStats={userBalanceDetailedStats}
            absencePolicy={absencePolicy}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface PublicHolidayAllowanceDetailsDrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

const PublicHolidayAllowanceDetailsDrawerContent = ({
  absencePolicy,
  userBalanceDetailedStats,
}: PublicHolidayAllowanceDetailsDrawerContentProps) => (
  <Box sx={{ mt: spacing.m20 }}>
    <CalculationSection absencePolicy={absencePolicy} userBalanceDetailedStats={userBalanceDetailedStats} />

    <DetailsSection absencePolicy={absencePolicy} userBalanceDetailedStats={userBalanceDetailedStats} />
  </Box>
);

const CalculationSection = ({
  absencePolicy,
  userBalanceDetailedStats,
}: PublicHolidayAllowanceDetailsDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  if (!breakdown) return null;

  const publicHolidayAllowance = getPublicHolidayAllowanceFromBreakdown(absencePolicy, breakdown, polyglot);

  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    const firstOfJan = new Date();
    firstOfJan.setMonth(0, 1);
    const thirtyFirstOfDec = new Date();
    thirtyFirstOfDec.setMonth(11, 31);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        {breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
          return (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: spacing.g5,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <ChipLine
                key={index}
                absencePolicy={absencePolicy}
                userBalanceDetailedStats={userBalanceDetailedStats}
                segment={segment}
              />
            </Box>
          );
        })}

        <Divider />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Typography variant="title4">{publicHolidayAllowance}</Typography>
        </Box>
      </Box>
    );
  }
  // No prorating
  return null;
};

export const DetailsSection = ({
  absencePolicy,
  userBalanceDetailedStats,
}: PublicHolidayAllowanceDetailsDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  const proratingRule = getHolidaysProratingRuleLong(absencePolicy, polyglot);

  if (!breakdown) return null;
  return (
    <Box sx={{ mt: spacing.m20 }}>
      <Typography variant="title3">{polyglot.t('AllowanceDrawer.details')}</Typography>

      <Typography variant="caption" sx={{ mb: spacing.m10 }}>
        {proratingRule}
      </Typography>

      {absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriod && (
        <Typography variant="caption" sx={{ mb: spacing.m10 }}>
          {polyglot.t('AllowanceDrawer.allInPeriodShortDesc')}
        </Typography>
      )}

      <Typography variant="caption" sx={{ mb: spacing.m20 }}>
        {polyglot.t('AllowanceDrawer.companyCalendarRunsFromTo', {
          startDate: isoDateToLocale(breakdown.currentCycle[0]),
          endDate: isoDateToLocale(breakdown.currentCycle[1]),
        })}
      </Typography>

      <DetailsSegments absencePolicy={absencePolicy} userBalanceDetailedStats={userBalanceDetailedStats} />
    </Box>
  );
};

export const DetailsSegments = ({
  absencePolicy,
  userBalanceDetailedStats,
}: PublicHolidayAllowanceDetailsDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];

  if (!breakdown) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        {breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
          const percentageInYear = round2Digits((segment.daysInSegment / segment.daysInCycle) * 100);
          const segmentStart = isoDateToLocale(segment.segmentStart);
          const segmentEnd = isoDateToLocale(segment.segmentEnd);

          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="title4" sx={{ mb: spacing.m5 }}>
                {polyglot.t('AllowanceDrawer.contractNo', { contractNo: index + 1 })}
              </Typography>

              <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                {polyglot.t('AllowanceDrawer.effectiveFromTo', {
                  start: segmentStart,
                  end: segmentEnd,
                  holidayCalendar: segment.holidayCalendar.toUpperCase(),
                })}
              </Typography>

              {/* TODO: @polyglot-later */}
              {absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated ? (
                <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                  In {breakdown.currentHolidayYear} there were{' '}
                  <Chip color={themeColors.FreshGreen} value={`${segment.publicHolidaysInYear} days`} /> of public
                  holiday in {segment.holidayCalendar === 'uk' ? 'the ' : ''}
                  {segment.holidayCalendar.toUpperCase()}
                </Typography>
              ) : (
                <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                  In this period there were{' '}
                  <Chip
                    color={themeColors.FreshGreen}
                    value={`${segment.publicHolidaysInSegment} day${segment.publicHolidaysInSegment === 1 ? '' : 's'}`}
                  />{' '}
                  of public holiday.
                </Typography>
              )}

              {/* TODO: @polyglot-later */}
              {absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated &&
                absencePolicy.proratingStartDate && (
                  <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                    This contract is effective from {segmentStart} to {segmentEnd}, lasting {segment.daysInSegment}{' '}
                    days. ({segment.daysInSegment}/{segment.daysInCycle} ={' '}
                    <Chip color={themeColors.ZeltYellow} value={`${percentageInYear}%`} />)
                  </Typography>
                )}

              {/* TODO: @polyglot-later */}
              {absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated &&
                absencePolicy.proratingFte && (
                  <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                    Your work pattern is {segment.userNoOfWorkingDays} days out of{' '}
                    {segment.fteEquivalentNoOfWorkingDays} days per week. Therefore your prorating percentage is{' '}
                    <Chip color={themeColors.Violet} value={`${round2Digits(segment.fteByWorkingDaysInWeek * 100)}%`} />
                    )
                  </Typography>
                )}

              {/* TODO: @polyglot-later */}
              {((absencePolicy.holidaysProratingRule !== HolidaysProratingRule.AllInPeriod &&
                !breakdown.isOnRegularSchedule) ||
                (isHourly && absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)) && (
                <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                  Average workday length is{' '}
                  <Chip
                    color={themeColors.Orange}
                    value={convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot)}
                  />
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    );
  }

  return null;
};

const ChipLine = ({
  absencePolicy,
  userBalanceDetailedStats,
  segment,
}: {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
  readonly segment: ProRatedAbsenceAllowanceBreakdownSegment;
}) => {
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy(absencePolicy);

  if (
    [HolidaysProratingRule.AllInPeriod, HolidaysProratingRule.AllInCycle].includes(absencePolicy.holidaysProratingRule)
  )
    return (
      <>
        <Chip
          color={themeColors.FreshGreen}
          value={polyglot.t('AllowanceDrawer.noOfDays', { smart_count: segment.publicHolidaysInSegment })}
        />
        <Box>=</Box>
        <Typography variant="title4" sx={{ whiteSpace: 'nowrap' }}>
          {isHourly ||
          !userBalanceDetailedStats[absencePolicy.id]?.isOnRegularSchedule ||
          !userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength
            ? convertMinutesToClockHours(segment.segmentPublicHolidaysValue, polyglot)
            : polyglot.t('AllowanceDrawer.noOfDays', {
                smart_count:
                  round2Digits(
                    segment.segmentPublicHolidaysValue /
                      userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength!
                  ) ?? 0,
              })}
        </Typography>
      </>
    );

  const timesAverageWorkingHours =
    isHourly ||
    !userBalanceDetailedStats[absencePolicy.id]?.isOnRegularSchedule ||
    !userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength ? (
      <>
        <Box>X</Box>
        <Chip color={themeColors.Orange} value={convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot)} />
      </>
    ) : null;

  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated) {
    return (
      <>
        <Chip
          color={themeColors.FreshGreen}
          value={polyglot.t('AllowanceDrawer.noOfDays', { smart_count: segment.publicHolidaysInSegment })}
        />
        <Box>X</Box>
        <Chip
          color={themeColors.ZeltYellow}
          value={polyglot.t('AllowanceDrawer.percentage', {
            percentage: round2Digits(segment.fteByWorkingDaysInWeek * 100) ?? 0,
          })}
        />
        {timesAverageWorkingHours}
        <Box>=</Box>
        <Typography variant="title4" sx={{ whiteSpace: 'nowrap' }}>
          {isHourly ||
          !userBalanceDetailedStats[absencePolicy.id]?.isOnRegularSchedule ||
          !userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength!
            ? convertMinutesToClockHours(segment.segmentPublicHolidaysValue, polyglot)
            : polyglot.t('AllowanceDrawer.noOfDays', {
                smart_count:
                  round2Digits(
                    segment.segmentPublicHolidaysValue /
                      userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength!
                  ) ?? 0,
              })}
        </Typography>
      </>
    );
  }

  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated) {
    const percentageInYear = round2Digits((segment.daysInSegment / segment.daysInCycle) * 100) ?? 0;
    return (
      <>
        <Chip
          color={themeColors.FreshGreen}
          value={polyglot.t('AllowanceDrawer.noOfDays', {
            smart_count: segment.publicHolidaysInYear,
          })}
        />
        {absencePolicy.proratingStartDate && <Box>X</Box>}
        {absencePolicy.proratingStartDate && (
          <Chip
            color={themeColors.ZeltYellow}
            value={polyglot.t('AllowanceDrawer.percentage', { percentage: percentageInYear })}
          />
        )}
        {absencePolicy.proratingFte && <Box>X</Box>}
        {absencePolicy.proratingFte && (
          <Chip
            color={themeColors.Violet}
            value={polyglot.t('AllowanceDrawer.percentage', {
              percentage: round2Digits(segment.fteByWorkingDaysInWeek * 100) ?? 0,
            })}
          />
        )}
        {timesAverageWorkingHours}
        <Box>=</Box>
        <Typography variant="title4" sx={{ whiteSpace: 'nowrap' }}>
          {isHourly ||
          !userBalanceDetailedStats[absencePolicy.id]?.isOnRegularSchedule ||
          !userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength
            ? convertMinutesToClockHours(segment.segmentPublicHolidaysValue, polyglot)
            : polyglot.t('AllowanceDrawer.noOfDays', {
                smart_count:
                  round2Digits(
                    (segment.segmentPublicHolidaysValue ?? 0) /
                      userBalanceDetailedStats[absencePolicy.id]?.currentAverageWorkDayLength!
                  ) ?? 0,
              })}
        </Typography>
      </>
    );
  }

  return null;
};
