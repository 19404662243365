import React from 'react';

import { SettingsMembersList } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-members-list.component';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface SectionProps {
  readonly schedule: AttendanceScheduleDto;
}

export const AttendanceSettingsMembersSection = ({ schedule }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const { data: scheduleMembers, isLoading } = useApiClient(AttendanceEndpoints.getScheduleMembers(schedule.id), {
    suspense: false,
  });

  return (
    <SettingsSectionContent loading={isLoading} title={polyglot.t('General.members')}>
      {scheduleMembers && scheduleMembers.length > 0 ? (
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: polyglot.t('AttendanceScheduleMembersSection.desc'),
                },
                {
                  type: SectionItemType.Component,
                  value: <SettingsMembersList members={scheduleMembers ?? []} />,
                },
              ],
            },
          ]}
        />
      ) : (
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('AttendanceScheduleMembersSection.employees'),
                  value: polyglot.t('AttendanceScheduleMembersSection.noMembers'),
                },
                {
                  type: SectionItemType.Pair,
                  label: '',
                  value: polyglot.t('AttendanceScheduleMembersSection.desc'),
                },
              ],
            },
          ]}
        />
      )}
    </SettingsSectionContent>
  );
};
