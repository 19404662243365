import { useMemo } from 'react';

import { CustomProfileFormEndpoints } from '@v2/feature/custom-fields/custom-profile-fields.api';
import { PeopleChangeUpdateReport } from '@v2/feature/reports/features/update-report/people/people-change-update-report.component';
import { PeopleCurrentUpdateReport } from '@v2/feature/reports/features/update-report/people/people-current-update-report.component';
import { ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { DataDomain, ReportTypePeopleSelection } from '@v2/feature/reports/reports.interface';
import { includeCustomFieldsColumns } from '@v2/feature/reports/util/people-report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

export const PeopleReportSection = ({
  report,
  loading,
  refreshReport,
}: {
  report: ReportDto;
  loading: boolean;
  refreshReport: () => Promise<void>;
}) => {
  const { data: fields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), { suspense: false });
  const { data: additionalColumns } = useApiClient(ReportsEndpoints.getPeopleReportsAdditionalColumnsOptions(), {
    suspense: false,
  });

  const customFields = useMemo(() => {
    if (!report || report.config.domain !== DataDomain.People) return {};
    return includeCustomFieldsColumns(report.config.entities, fields?.dataFieldsCustom ?? []);
  }, [fields?.dataFieldsCustom, report]);
  if (!customFields || !additionalColumns) return null;

  return report.config.type === ReportTypePeopleSelection.CurrentRecords &&
    report.config.domain === DataDomain.People ? (
    <PeopleCurrentUpdateReport
      report={report}
      loading={loading}
      customFields={customFields}
      additionalColumns={additionalColumns ?? []}
    />
  ) : (
    <PeopleChangeUpdateReport
      report={report}
      loading={loading}
      customFields={customFields}
      additionalColumns={additionalColumns ?? []}
      refreshReport={refreshReport}
    />
  );
};
