import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  ChecklistDto,
  CreateChecklistDto,
  UpdateChecklistDto,
} from '@v2/feature/task/subfeature/checklist/checklist.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledEmojiPicker } from '@/v2/components/theme-components/styled-emoji-picker.component';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { UpgradeToProModal, PlanNames } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  checklist: ChecklistDto | null;
  onClose: () => void;
}

const ChecklistSchema = Yup.object().shape({
  bgImg: Yup.string().optional().nullable(),
  name: Yup.string().nullable().notRequired(),
});

const defaultBlankModalValues: CreateChecklistDto = {
  bgImg: undefined,
  name: null,
};
export const ChecklistFormDrawer = ({ checklist, onClose }: Props) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const routerHistory = useHistory();

  const initialValues = {
    bgImg: checklist?.bgImg ?? defaultBlankModalValues.bgImg,
    name: checklist?.name ?? defaultBlankModalValues.name,
  };

  const addChecklist = async (values: CreateChecklistDto) => {
    try {
      setLoading(true);
      const newChecklist = await TaskAPI.addChecklist(values);
      routerHistory.push(generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: newChecklist.id }), {
        openNewTaskModal: true,
      });
      onClose();
      showMessage('Checklist successfully added.', 'success');
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(`Checklist could not be created. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateChecklist = async (id: number, values: UpdateChecklistDto) => {
    setLoading(true);
    await TaskAPI.updateChecklist(id, values);
    onClose();
    try {
      showMessage('Checklist successfully update.', 'success');
    } catch (error) {
      showMessage(`Checklist could not be update. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ChecklistSchema,
    onSubmit: async (values) => {
      if (checklist?.id) await updateChecklist(checklist.id, values);
      else await addChecklist(values);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {checklist?.id ? polyglot.t('ChecklistsPage.edit') : polyglot.t('ChecklistsPage.new')}
        </Typography>

        <Box sx={{ display: 'flex', gap: spacing.g10 }}>
          <Box sx={{ width: '20%' }}>
            <StyledEmojiPicker
              name="bgImg"
              label="Mark"
              value={formik.values.bgImg}
              onChange={(emoji) => {
                formik.setFieldValue('bgImg', emoji.emoji);
              }}
            />
          </Box>
          <Box sx={{ width: '80%' }}>
            <TextfieldComponent
              name="name"
              label="Name"
              value={formik.values.name}
              type="text"
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={(formik.touched.name && formik.errors.name) ?? ' '}
              clearText={() => formik.setFieldValue('name', '')}
            />
          </Box>
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          {checklist?.id && (
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
              Cancel
            </ButtonComponent>
          )}
          <LoaderButton
            name={checklist?.id ? polyglot.t('General.update') : polyglot.t('General.add')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen: React.SetStateAction<boolean>) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.PEOPLE_PRO}
          messageSuffix="proGeneric"
        />
      </Form>
    </FormikProvider>
  );
};
