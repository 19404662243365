import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { identity } from 'fp-ts/lib/function';
import Polyglot from 'node-polyglot';

import { UserAddressSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { UserAddressDto } from '@/v2/feature/user/features/user-forms/user-address/user-address.dto';
import {
  CardStructure,
  FieldStructure,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { AddressForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component';
import { buildColumnsForCustomFields } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { MissingField } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

const SCOPE = 'user.address' as const;
const iconSize = { width: 14, height: 14 } as const;
const skeletonHeight = (polyglot: Polyglot) => calculateSkeletonHeight(UserAddressSchema(polyglot));

interface Props {
  readonly userId: number;
  readonly onSubmit: () => void;
  readonly missingFields?: MissingField[] | null;
}

export const AddressCard = ({ userId, onSubmit, missingFields }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [data, setData] = useState<EntriesAndEffectiveRecord<UserAddressDto>>();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<UserAddressDto>();
  const [isOpen, setIsOpen] = useState(false);

  const getUserAddress = useCallback(async () => {
    try {
      const userAddress = await UserAddressAPI.findByUserId(userId);
      setData(userAddress);
      if (userAddress?.entries && userAddress?.entries?.length < 1) setShowDetails(false);
    } catch (error) {
      showMessage(polyglot.t('AddressCard.errorMessages.load'), 'error');
    }
  }, [polyglot, showMessage, userId]);

  useEffect(() => {
    getUserAddress();
  }, [getUserAddress]);

  const columnData = useMemo<ColumnDef<UserAddressDto, UserAddressDto>[]>(
    () => [
      {
        header: () => polyglot.t('AddressCard.addressLine1'),
        accessorFn: identity,
        id: 'addressLine1',
        enableSorting: false,
        size: 80,
        cell: ({
          row: {
            original: { addressLine1 },
          },
        }) => <div>{addressLine1 ?? ''}</div>,
      },
      {
        header: () => polyglot.t('AddressCard.addressLine2'),
        accessorFn: identity,
        id: 'addressLine2',
        size: 120,
        enableSorting: false,
        cell: ({
          row: {
            original: { addressLine2 },
          },
        }) => <div>{addressLine2 ?? ''}</div>,
      },
      {
        header: () => polyglot.t('AddressCard.city'),
        accessorFn: identity,
        id: 'city',
        size: 120,
        enableSorting: false,
        cell: ({
          row: {
            original: { city },
          },
        }) => <div>{city ?? ''}</div>,
      },
      {
        header: () => polyglot.t('AddressCard.country'),
        accessorFn: identity,
        id: 'country',
        enableSorting: false,
        size: 120,
        cell: ({
          row: {
            original: { country },
          },
        }) => <div>{country ?? ''}</div>,
      },
      {
        header: () => polyglot.t('AddressCard.postcode'),
        accessorFn: identity,
        id: 'postcode',
        size: 120,
        enableSorting: false,
        cell: ({
          row: {
            original: { postcode },
          },
        }) => <div>{postcode ?? ''}</div>,
      },
      ...buildColumnsForCustomFields(data?.entries, { size: 120 }),
      {
        header: () => {
          polyglot.t('AddressCard.effectiveDate');
        },
        accessorFn: identity,
        id: 'effectiveDate',
        size: 120,
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },
      {
        accessorFn: identity,
        id: 'actions',
        header: () => '',
        size: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton
                title={polyglot.t('AddressCard.edit')}
                onClick={() => {
                  setIsOpen(true);
                  setFormMode(original);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </ScopesControl>
          </div>
        ),
      },
    ],
    [polyglot, data?.entries, scopesContext]
  );

  const handleSubmit = useCallback(() => {
    setFormMode(undefined);
    setIsOpen(false);
    onSubmit();
  }, [onSubmit]);

  return !data ? (
    <SkeletonLoader
      variant="rectangular"
      height={skeletonHeight(polyglot)}
      sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
    />
  ) : (
    <CardStructure
      cardTitle={polyglot.t('AddressCard.title')}
      cardScope={[SCOPE]}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      userId={userId}
      addAction={() => {
        setIsOpen(true);
        setFormMode(undefined);
      }}
      showMissingInfo={!data?.effectiveRecord}
      cardFieldCustomUpdates={data.effectiveRecord?.customUpdates}
      cardFieldStubs={[
        'address.addressLine1',
        'address.addressLine2',
        'address.city',
        'address.postcode',
        'address.country',
      ]}
      cardFieldDetails={
        data?.effectiveRecord && (
          <Box component="dl" sx={definitionListSx}>
            <FieldStructure
              fieldTitle={polyglot.t('AddressCard.addressLine1')}
              fieldValue={data.effectiveRecord.addressLine1}
              fieldStub="address.addressLine1"
            />
            <FieldStructure
              fieldTitle={polyglot.t('AddressCard.addressLine2')}
              fieldValue={data.effectiveRecord.addressLine2}
              fieldStub="address.addressLine2"
            />
            <FieldStructure fieldTitle="City" fieldValue={data.effectiveRecord.city} fieldStub="address.city" />
            <FieldStructure
              fieldTitle={polyglot.t('AddressCard.postcode')}
              fieldValue={data.effectiveRecord.postcode}
              fieldStub="address.postcode"
            />
            <FieldStructure
              fieldTitle={polyglot.t('AddressCard.country')}
              fieldValue={data.effectiveRecord.country}
              fieldStub="address.country"
            />
            {sortCustomFields(data.effectiveRecord.customUpdates).map(
              (f) =>
                !f.field.isHidden && (
                  <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                )
            )}
          </Box>
        )
      }
      drawerDetails={
        <ScopesControl scopes={[SCOPE]} context={scopesContext}>
          <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <AddressForm
              userId={userId}
              initialValues={formMode}
              onSubmit={handleSubmit}
              onClose={() => setIsOpen(false)}
              handleRefresh={() => getUserAddress()}
            />
          </DrawerModal>
        </ScopesControl>
      }
      addButtonTitle={polyglot.t('AddressCard.newAddress')}
      tableRowData={[...data?.entries]}
      tableColumn={columnData}
      editAction={() => {
        setIsOpen(true);
        setFormMode(data?.effectiveRecord);
      }}
      missingInfoIcon={
        <ScopesControl scopes={['user:all']} context={scopesContext}>
          {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.Address) && (
            <Box sx={hazardMissingField}>
              <Hazard {...hazardSize} />
            </Box>
          )}
        </ScopesControl>
      }
    />
  );
};
