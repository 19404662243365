import { Suspense, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ContractorOverviewCard } from '@v2/feature/payments/features/accounting-transaction/components/contractor-overview-card.component';
import { useHistory, useLocation } from 'react-router-dom';

import { PAYMENT_ME_NEW_EXPENSE_ROUTE, PAYMENT_ME_NEW_INVOICE_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { NewPaymentButton } from '@/v2/feature/payments/pages/components/new-payment-button.component';
import {
  PaymentsTransactionsTable,
  PaymentsTransactionsTableSkeleton,
} from '@/v2/feature/payments/pages/payments-list.page';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export function PaymentsTeamPage(): JSX.Element {
  const routerLocation = useLocation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const routerHistory = useHistory();

  const showOverview = false;
  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Payments</Typography>}
        showBack={routerLocation.pathname.includes('/settings')}
        showAction={true}
        actions={
          <NewPaymentButton
            onExpenseClick={() => routerHistory.push(PAYMENT_ME_NEW_EXPENSE_ROUTE)}
            onInvoiceClick={() => routerHistory.push(PAYMENT_ME_NEW_INVOICE_ROUTE)}
          />
        }
      />

      <ContentWrapper loading={loading} sx={spacing.pt20}>
        {showOverview && (
          <Box sx={{ ml: spacing.ml40, mt: spacing.mt20, mb: spacing.mb20 }}>
            <ContractorOverviewCard />
          </Box>
        )}

        <Suspense
          fallback={
            <>
              <PaymentsTransactionsTableSkeleton />
            </>
          }
        >
          <PaymentsTransactionsTable
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setLoading={setLoading}
            setShowEmpty={setShowEmpty}
            showEmpty={showEmpty}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
            mode={'payment'}
            reach={'team'}
          />
        </Suspense>
      </ContentWrapper>
    </RootStyle>
  );
}
