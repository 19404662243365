import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';
import { ApproversList } from '@v2/feature/approval-rule/components/approvers-list.component';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import {
  calculatePreTaxAmount,
  getExpenseTotalsBasedOnLineItems,
  getExpenseTypeOptions,
  getTaxRateForTotalSection,
  getTaxRateFromSelectedOption,
  NO_VAT_VALUE,
} from '@v2/feature/payments/expenses.util';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { ExpenseAPI } from '@/api-client/expense.api';
import { UploadInput } from '@/component/forms/UploadInput';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Subtract.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE,
  EXPENSES_COMPANY_OVERVIEW_ROUTE,
  EXPENSES_ME_OVERVIEW_ROUTE,
  EXPENSES_ME_ROUTE,
  EXPENSES_TEAM_OVERVIEW_ROUTE,
  USER_EXPENSES_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { Divider } from '@/v2/components/divider.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CurrencyTextField } from '@/v2/components/forms/currency-text-field.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { PreviewPayload } from '@/v2/feature/documents/documents.interface';
import { ExpenseLineItemDrawer } from '@/v2/feature/payments/pages/components/expense-line-item.drawer.component';
import {
  CreateExpense,
  DEFAULT_CURRENCY,
  Expense,
  ExpenseLineItem,
  ExpenseStatus,
  TaxRateOptions,
} from '@/v2/feature/payments/payments.interface';
import { PaymentSettingsNewTypeDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { truncateWithEllipses } from '@/v2/util/string.util';

export const NewExpensePage = ({
  preselectedEmployee,
  fromUserProfile = false,
  reach = 'company',
  userBenefit,
  maxAmount,
  editMode = false,
}: {
  preselectedEmployee?: number;
  fromUserProfile?: boolean;
  reach?: 'user' | 'team' | 'company';
  userBenefit?: UserCustomBenefitDto;
  maxAmount?: number | undefined | null;
  editMode?: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { getScopesContext, hasScopes } = useScopes();
  const [submitting, setSubmitting] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [approversSets, setApproversSets] = useState<ApproverStep[]>([]);
  const [receiptFilename, setReceiptFilename] = useState<string | null>(null);
  const { data, mutate: refreshExpensePaymentTypeSettingsData } = useApiClient(
    PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId(),
    {
      suspense: false,
    }
  );

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;

  // in edit path
  const expenseToEdit = useMemo(
    () => (routerHistory.location.state as { expenseToEdit: Expense })?.expenseToEdit || null,
    [routerHistory]
  );

  const currentUserIsExpenseAdmin = hasScopes(
    ['expenses:all', 'expenses:manager'],
    getScopesContext({ userId: user?.userId })
  );
  const currentUserIsManagerOfExpenseOwner = expenseToEdit?.from
    ? checkScopes(user, ['expenses:manager'], { userId: expenseToEdit.from })
    : false;

  // if expense to edit
  if (
    editMode &&
    expenseToEdit &&
    expenseToEdit.from !== user.userId &&
    !currentUserIsExpenseAdmin &&
    !currentUserIsManagerOfExpenseOwner
  ) {
    routerHistory.push({
      pathname: generatePath(EXPENSES_ME_ROUTE),
    });
  }

  const [typeDrawerOpen, setIsTypeDrawerOpen] = useState<boolean>(false);
  // const [multipleLineItems, setMultipleLineItems] = useState<boolean>(expenseToEdit?.lineItems?.length > 1 ?? false);
  const isExpenseAdmin = checkScopes(user, ['expenses:all'], { userId: user.userId });
  const [addingLineItemDrawerOpen, setAddingLineItemDrawerOpen] = useState<boolean>(false);
  const [editingLineItemIndex, setEditingLineItemIndex] = useState<number | undefined>(undefined);

  const benefitPaymentTypeIds = useMemo(
    () => userBenefit?.customBenefit?.paymentTypes?.map((paymentType) => paymentType.id),
    [userBenefit]
  );

  const existingTypeId = useMemo(() => {
    if (editMode && expenseToEdit?.typeId) return expenseToEdit.typeId;
    if (!editMode && benefitPaymentTypeIds && benefitPaymentTypeIds.length === 1) return benefitPaymentTypeIds[0];
    return null;
  }, [benefitPaymentTypeIds, editMode, expenseToEdit]);

  const fetchAttachmentDetails = useCallback(async () => {
    if (editMode && expenseToEdit?.attachment) {
      await DocumentAPI.previewViaUuid(expenseToEdit?.attachment).then(async ({ filename }: PreviewPayload) => {
        setReceiptFilename(filename);
      });
    }
  }, [editMode, expenseToEdit?.attachment]);

  const redirectDraftExpenseToEditModeBasedOnReach = useCallback(
    (draftExpense: Expense) => {
      switch (reach) {
        case 'user':
          routerHistory.push(generatePath(EXPENSE_ME_EDIT_EXPENSE_ROUTE, { id: draftExpense.id }));
          break;
        case 'team':
          routerHistory.push(generatePath(EXPENSE_TEAM_EDIT_EXPENSE_ROUTE, { id: draftExpense.id }));
          break;
        case 'company':
          routerHistory.push(generatePath(EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE, { id: draftExpense.id }));
          break;
      }
    },
    [reach, routerHistory]
  );

  useEffect(() => {
    fetchAttachmentDetails();
  }, [editMode, expenseToEdit, fetchAttachmentDetails]);

  const { totalAmount: initialValueForAmount, totalTaxAmount: initialValueForTaxAmount } =
    expenseToEdit && expenseToEdit.lineItems
      ? getExpenseTotalsBasedOnLineItems(expenseToEdit.lineItems)
      : { totalAmount: 0, totalTaxAmount: 0 };

  const firstNonEmptyTaxRateOption = expenseToEdit
    ? expenseToEdit?.lineItems?.find((item) => item.taxRate && ![NO_VAT_VALUE, 0].includes(item.taxRate))?.taxRate ??
      NO_VAT_VALUE
    : NO_VAT_VALUE;

  const formik = useFormik<
    Omit<CreateExpense, 'from'> & { from: number | undefined; taxAmount: number | undefined } & {
      currency: CurrencyShort | null;
    }
  >({
    initialValues: {
      typeId: existingTypeId,
      from: expenseToEdit?.from ?? preselectedEmployee ?? undefined,
      status: expenseToEdit?.status ?? ExpenseStatus.Pending,
      date: expenseToEdit?.date ?? new LocalDate().toDateString(),
      gross: expenseToEdit?.gross ?? 0,
      taxRate: firstNonEmptyTaxRateOption,
      taxAmount: expenseToEdit ? initialValueForTaxAmount : 0, // only calculated for display - not sent for creation
      amount: expenseToEdit ? initialValueForAmount : 0,
      isTaxIncluded: true,
      notes: expenseToEdit?.notes ?? null,
      currency:
        expenseToEdit?.currency ?? preselectedEmployee === user.userId
          ? user.currency ?? DEFAULT_CURRENCY
          : DEFAULT_CURRENCY,
      customBenefitId: expenseToEdit?.customBenefitId ?? userBenefit?.customBenefitId ?? null,
      userCustomBenefitId: expenseToEdit?.userCustomBenefitId ?? userBenefit?.id ?? null,
      attachment: expenseToEdit?.attachment ?? null,
      lineItems: expenseToEdit?.lineItems ?? [],
    },
    enableReinitialize: editMode,
    validationSchema: yup.object({
      // TODO: missing validation for typeId, etc
      isTaxIncluded: yup.boolean(),
      from: yup
        .number()
        .integer(polyglot.t('ValidationMessages.validValue'))
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      taxRate: yup.number().nullable().required(polyglot.t('ValidationMessages.requiredField')),
      attachment: yup
        .string()
        .nullable()
        .when('typeId', {
          is: (typeId: number) => {
            const selectedExpenseType = expenseTypes?.find((type) => type.id === typeId);
            return selectedExpenseType?.requireAttachment === true;
          },
          then: yup.string().nullable().required('Receipt is required'),
          otherwise: yup.string().nullable(),
        }),
      currency: yup.string().required('Currency is required'),
      gross:
        maxAmount || maxAmount === 0
          ? yup
              .number()
              .nullable()
              .typeError(polyglot.t('ValidationMessages.validValue'))
              .min(0.01, polyglot.t('ValidationMessages.greaterThan', { num: 1 }))
              .max(maxAmount, polyglot.t('ValidationMessages.lessOrEqual', { num: maxAmount }))
              .required(polyglot.t('ValidationMessages.requiredField'))
          : yup
              .number()
              .nullable()
              .min(0.01, polyglot.t('ValidationMessages.greaterThan', { num: 1 }))
              .typeError(polyglot.t('ValidationMessages.validValue'))
              .required(polyglot.t('ValidationMessages.requiredField')),
      notes: yup
        .string()
        .nullable()
        .max(255, polyglot.t('ValidationMessages.maxLength', { num: 255 }))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const {
          date,
          isTaxIncluded,
          gross,
          taxRate,
          amount,
          from,
          typeId,
          notes,
          status,
          currency,
          attachment,
          lineItems,
        } = values;

        if (!amount || !date || !typeId || !from) return;

        const inDraftMode = status === ExpenseStatus.Draft && savingDraft;

        const expenseData: CreateExpense = {
          date,
          isTaxIncluded,
          notes: notes ?? null,
          from,
          amount: Number(amount),
          gross: Number(gross),
          taxRate: isTaxIncluded ? getTaxRateFromSelectedOption(taxRate) : null,
          currency,
          typeId: +typeId,
          attachment,
          status: inDraftMode ? ExpenseStatus.Draft : ExpenseStatus.Pending,
          customBenefitId: userBenefit?.customBenefitId ?? null,
          userCustomBenefitId: userBenefit?.id ?? null,
          lineItems,
        };

        let createdExpense;
        if (editMode && expenseToEdit?.id) {
          await ExpenseAPI.updateExpense(expenseToEdit?.id, expenseData as Expense);
        } else {
          createdExpense = await ExpenseAPI.createExpense(expenseData);
        }

        if (!inDraftMode) {
          showMessage(polyglot.t('NewExpensePage.successMessages.expenseCreation'), 'success');
          routerHistory.push(
            reach === 'team'
              ? EXPENSES_TEAM_OVERVIEW_ROUTE
              : fromUserProfile && preselectedEmployee
              ? generatePath(USER_EXPENSES_ROUTE, { userId: preselectedEmployee })
              : preselectedEmployee
              ? EXPENSES_ME_OVERVIEW_ROUTE
              : EXPENSES_COMPANY_OVERVIEW_ROUTE
          );
        } else {
          // if expense was created in draft mode, we need to redirect to edit mode, after initially saving as draft
          showMessage(polyglot.t('NewExpensePage.successMessages.expenseEdit'), 'success');
          if (createdExpense?.id && !editMode) {
            redirectDraftExpenseToEditModeBasedOnReach(createdExpense);
          }
        }
      } catch (error) {
        showMessage(
          polyglot.t(
            editMode ? 'NewExpensePage.errorMessages.expenseEdit' : 'NewExpensePage.errorMessages.expenseCreation',
            { errorMessage: nestErrorMessage(error) }
          ),
          'error'
        );
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  const updateAmountsBasedOnGrossAndRateForSingleItemExpense = useCallback(
    (gross: number, taxRate: number | null, lineItems: Partial<ExpenseLineItem>[]) => {
      let { preTax, taxAmount } = gross ? calculatePreTaxAmount(gross, taxRate) : { preTax: 0, taxAmount: 0 };
      const lineItemsToUpdate = lineItems ? [...lineItems] : [];
      if (lineItemsToUpdate.length > 0) {
        lineItemsToUpdate[0] = {
          ...lineItems[0],
          amount: Number(preTax),
          isTaxIncluded: taxAmount > 0,
        };
      } else {
        // no line items yet - need to insert new one
        lineItemsToUpdate.push({
          amount: Number(preTax),
          isTaxIncluded: taxAmount > 0,
        });
      }
      formik.setFieldValue('lineItems', lineItemsToUpdate);
      formik.setFieldValue('amount', Number(preTax));
      formik.setFieldValue('taxAmount', taxAmount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.taxRate, formik.values.gross]
  );

  useEffect(() => {
    const fetchApprovalList = async () => {
      if (formik.values.from && formik.values.typeId) {
        const list = await ExpenseAPI.getApprovalListForNewExpense(formik.values.from, formik.values.typeId);
        setApproversSets(list);
      } else {
        setApproversSets([]);
      }
    };
    fetchApprovalList();
  }, [formik.values.from, formik.values.typeId]);

  const expenseTypes = useMemo(() => {
    if (data?.settings) {
      if (!preselectedEmployee && !formik.values.from) return data?.settings;
      if (preselectedEmployee)
        return data.settings.filter((setting) => setting?.selectedMembersIds?.includes(preselectedEmployee));
      if (formik.values.from)
        return data.settings.filter((setting) =>
          formik.values.from ? setting?.selectedMembersIds?.includes(formik.values.from) : true
        );
    } else {
      return [];
    }
  }, [data, formik.values.from, preselectedEmployee]);

  const expenseTypeOptions: OptionObject[] = useMemo(
    () => getExpenseTypeOptions(expenseTypes ?? [], benefitPaymentTypeIds),
    [expenseTypes, benefitPaymentTypeIds]
  );

  const selectedExpenseType = useMemo(() => {
    return formik.values.typeId ? expenseTypes?.find((type) => type.id === formik.values.typeId) : null;
  }, [expenseTypes, formik.values.typeId]);

  const selectedTypeHasAccountingCode = useMemo(() => {
    if (formik.values.typeId) {
      return selectedExpenseType?.accountingCodeConfig && selectedExpenseType?.accountingCodeConfig?.length >= 1;
    }
  }, [formik.values.typeId, selectedExpenseType?.accountingCodeConfig]);

  const accountingCodeOptions = useMemo(() => {
    if (formik.values.typeId && selectedExpenseType) {
      return selectedTypeHasAccountingCode
        ? selectedExpenseType.accountingCodeConfig?.map((eachOption) => {
            return { label: eachOption.accountingCodeDescription, value: eachOption.accountingCode };
          })
        : [];
    }
  }, [formik.values.typeId, selectedExpenseType, selectedTypeHasAccountingCode]);

  const handleEditLineItem = (index: number) => {
    // setMultipleLineItems(true);
    setEditingLineItemIndex(index);
    setAddingLineItemDrawerOpen(true);
  };

  const handleAddLineItems = () => {
    // setMultipleLineItems(true);
    setEditingLineItemIndex(undefined);
    setAddingLineItemDrawerOpen(true);
  };

  const updateLineItems = useCallback(
    (updatedValues: Partial<ExpenseLineItem>[]) => {
      formik.setFieldValue('lineItems', updatedValues);
      const { totalGross, totalAmount, totalTaxAmount } = getExpenseTotalsBasedOnLineItems(updatedValues);
      formik.setFieldValue('gross', totalGross);
      formik.setFieldValue('amount', totalAmount);
      formik.setFieldValue('taxAmount', totalTaxAmount);
    },
    [formik]
  );

  const handleDeleteLineItem = useCallback(
    (index: number) => {
      const updatedLineItems = [...formik.values.lineItems];
      updatedLineItems.splice(index, 1);
      updateLineItems(updatedLineItems);
    },
    [formik.values.lineItems, updateLineItems]
  );

  const handleSaveDraft = useCallback(() => {
    setSavingDraft(true);
    formik.setFieldValue('status', ExpenseStatus.Draft);
    formik.handleSubmit();
  }, [formik]);

  const handleSubmit = useCallback(() => {
    setSavingDraft(false);
    formik.handleSubmit();
  }, [formik]);

  const columns = useMemo<ColumnDef<Partial<ExpenseLineItem>, Partial<ExpenseLineItem>>[]>(() => {
    return [
      {
        header: () => polyglot.t('NewExpensePage.accountingCode'),
        accessorFn: (row) => row,
        id: 'accountingCodeDescription',
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">
              {original.accountingCodeDescription
                ? truncateWithEllipses(original.accountingCodeDescription, 15)
                : polyglot.t('NewExpensePage.noConfiguredCode')}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('NewExpensePage.amount'),
        accessorFn: (row) => row,
        id: 'amount',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.amount ? (
            <Typography variant="caption">
              {formatCurrency(original.amount, undefined, formik.values.currency ?? DEFAULT_CURRENCY)}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('NewInvoicePage.taxRate'),
        accessorFn: (row) => row,
        id: 'taxRate',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.taxRate ? (
            <Typography variant="caption">{getTaxRateForTotalSection(original.taxRate, false)}</Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('NewExpensePage.taxAmount'),
        accessorFn: (row) => row,
        id: 'taxAmount',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.gross && original.amount && original.isTaxIncluded ? (
            <Typography variant="caption">
              {formatCurrency(original.gross - original.amount, undefined, formik.values.currency ?? DEFAULT_CURRENCY)}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('NewInvoicePage.gross'),
        accessorFn: (row) => row,
        id: 'gross',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.gross ? (
            <Typography variant="caption">
              {' '}
              {formatCurrency(original.gross, undefined, formik.values.currency ?? DEFAULT_CURRENCY)}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: ({ row }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: spacing.g5 }}>
            <StyledMenuComponent
              options={[
                {
                  icon: <TrashIcon {...iconSize} />,
                  label: 'Delete',
                  handler: () => {
                    handleDeleteLineItem(row.index);
                  },
                },
                {
                  icon: <EditIcon {...iconSize} />,
                  label: 'Edit',
                  handler: () => {
                    handleEditLineItem(row.index);
                  },
                },
              ]}
              actionButtonDetails={{
                type: 'iconButton',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'actions',
                icon: <ActionsSmall {...iconSize} />,
              }}
            />
          </Box>
        ),
      },
    ];
  }, [formik.values.currency, handleDeleteLineItem, polyglot]);

  const multipleLineItems = useMemo(() => Boolean(formik.values?.lineItems?.length > 1), [
    formik.values?.lineItems?.length,
  ]);
  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2">
            {polyglot.t(editMode ? 'NewExpensePage.titleEdit' : 'NewExpensePage.title')}
          </Typography>
        }
        showBack
        sx={{ width: '618px', mx: 'auto' }}
      />
      <Box sx={{ width: '100%', overflowY: 'auto', mx: 'auto' }}>
        <Box sx={{ width: '618px', mx: 'auto' }}>
          <ContentWrapper
            noHorizontalPadding
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '618px',
              px: '9px',
              pt: '2px',
            }}
            loading={false}
          >
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Box sx={{ width: '618px', mx: 'auto', mt: spacing.mt20, minHeight: '50vh', overflowY: 'auto' }}>
                <FormikProvider value={formik}>
                  <Form>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
                      {userBenefit?.customBenefit && (
                        <Box sx={{ mb: '20px' }}>
                          <Typography variant="title4">
                            {userBenefit.customBenefit.name
                              ? userBenefit.customBenefit.name
                              : polyglot.t('BenefitModule.benefit')}
                          </Typography>

                          {(maxAmount || maxAmount === 0) && (
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                              <Typography variant="caption">Remaining allowance:</Typography>
                              <Typography variant="title4">
                                {formatMoney({
                                  amount: maxAmount,
                                  asDecimal: true,
                                })}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                      {!preselectedEmployee && (
                        <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                          <SingleUserSelect
                            name="from"
                            options={reach}
                            value={formik.values.from}
                            onChange={async (_, x: unknown) => {
                              const userId = (x as { value: number })?.value ?? null;
                              await formik.setFieldValue('from', userId);
                            }}
                            label={polyglot.t('NewExpensePage.employee')}
                            error={formik.touched.from && Boolean(formik.errors.from)}
                            helperText={formik.touched.from && formik.errors.from}
                            disabled={!!preselectedEmployee}
                          />
                        </FormControl>
                      )}
                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <DatePickerComponent
                          inputFormat="DD/MM/YYYY"
                          value={formik.values.date ?? null}
                          onChange={(value) => {
                            if (dayjs(value).isValid()) {
                              formik.setFieldValue('date', value);
                            }
                          }}
                          name="date"
                          label={polyglot.t('NewExpensePage.date')}
                          error={formik.touched.date && Boolean(formik.errors.date)}
                          helperText={(formik.touched.date && formik.errors.date) as string}
                        />
                      </FormControl>

                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <SelectComponent
                          name="typeId"
                          label={polyglot.t('NewExpensePage.expenseType')}
                          options={expenseTypeOptions}
                          value={formik.values.typeId}
                          onChange={(e) => {
                            formik.setFieldValue('typeId', e.target.value);
                          }}
                          editList={
                            !userBenefit
                              ? {
                                  handler: () => setIsTypeDrawerOpen(true),
                                  isHidden: !isExpenseAdmin,
                                }
                              : undefined
                          }
                        />
                      </FormControl>

                      {multipleLineItems ? (
                        <BasicTable<Partial<ExpenseLineItem>>
                          rowData={formik.values.lineItems}
                          columnData={columns}
                          loading={false}
                          hidePagination
                        />
                      ) : (
                        <></>
                      )}

                      {selectedTypeHasAccountingCode && !multipleLineItems ? (
                        <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                          <SelectComponent
                            name="accountingCode"
                            label={polyglot.t('NewExpensePage.accountingCode')}
                            options={accountingCodeOptions ?? []}
                            value={
                              formik.values.lineItems?.length > 0 && formik.values.lineItems[0]
                                ? formik.values.lineItems[0].accountingCode
                                : null
                            }
                            onChange={(e) => {
                              const matchingType = selectedExpenseType?.accountingCodeConfig?.find(
                                (o) => o.accountingCode === e.target.value
                              );
                              const lineItemsToUpdate = formik.values.lineItems ? [...formik.values.lineItems] : [];
                              if (lineItemsToUpdate.length > 0) {
                                lineItemsToUpdate[0] = {
                                  ...formik.values.lineItems[0],
                                  accountingCode: e.target.value,
                                  accountingCodeDescription: matchingType?.accountingCodeDescription ?? '',
                                };
                              } else {
                                // no line items yet - need to insert new one
                                lineItemsToUpdate.push({
                                  accountingCode: e.target.value,
                                  accountingCodeDescription: matchingType?.accountingCodeDescription ?? '',
                                });
                              }
                              formik.setFieldValue('lineItems', lineItemsToUpdate);
                              formik.setFieldValue('accountingCode', e.target.value);
                            }}
                          />
                        </FormControl>
                      ) : (
                        <></>
                      )}
                      {!multipleLineItems && (
                        <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                          <Typography variant="captionSmall" sx={{ color: themeColors.grey }}>
                            {polyglot.t('ContractorInvoiceModal.amount')}
                          </Typography>
                          <CurrencyTextField
                            name="gross"
                            label=""
                            value={formik.values.gross}
                            onChange={(value) => {
                              const lineItemsToUpdate = formik.values.lineItems ? [...formik.values.lineItems] : [];
                              if (lineItemsToUpdate.length > 0) {
                                lineItemsToUpdate[0] = {
                                  ...formik.values.lineItems[0],
                                  gross: value ? +value : 0,
                                };
                              } else {
                                // no line items yet - need to insert new one
                                lineItemsToUpdate.push({
                                  gross: value ? +value : 0,
                                });
                              }
                              formik.setFieldValue('lineItems', lineItemsToUpdate, true);
                              formik.setFieldValue('gross', value, true);
                              updateAmountsBasedOnGrossAndRateForSingleItemExpense(
                                value ? +value : 0,
                                formik.values.taxRate,
                                lineItemsToUpdate
                              );
                            }}
                            selectedCurrency={formik.values.currency ?? DEFAULT_CURRENCY}
                            onCurrencyChange={(newCurrency) => {
                              formik.setFieldValue('currency', newCurrency);
                            }}
                            error={formik.touched.gross && Boolean(formik.errors.gross)}
                            helperText={(formik.touched.gross && formik.errors.gross) as string}
                          />
                        </FormControl>
                      )}

                      {formik.values.isTaxIncluded && !multipleLineItems && (
                        <>
                          <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                            <Typography variant="captionSmall" sx={{ color: themeColors.grey }}>
                              {polyglot.t('NewInvoicePage.taxRate')}
                            </Typography>
                            <SelectComponent
                              label=""
                              name="taxRate"
                              options={TaxRateOptions}
                              value={formik.values.taxRate}
                              onChange={(e) => {
                                const lineItemsToUpdate = formik.values.lineItems ? [...formik.values.lineItems] : [];
                                if (lineItemsToUpdate.length > 0) {
                                  lineItemsToUpdate[0] = {
                                    ...formik.values.lineItems[0],
                                    taxRate: e.target.value,
                                  };
                                } else {
                                  // no line items yet - need to insert new one
                                  lineItemsToUpdate.push({
                                    taxRate: e.target.value,
                                  });
                                }
                                formik.setFieldValue('lineItems', lineItemsToUpdate, true);
                                formik.setFieldValue('taxRate', e.target.value, true);
                                updateAmountsBasedOnGrossAndRateForSingleItemExpense(
                                  formik.values.gross,
                                  e.target.value,
                                  lineItemsToUpdate
                                );
                              }}
                              error={formik.touched.taxRate && Boolean(formik.errors.taxRate)}
                              helperText={(formik.touched.taxRate && formik.errors.taxRate) as string}
                            />
                          </FormControl>
                        </>
                      )}
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="secondary"
                        onClick={() => handleAddLineItems()}
                        startIcon={<Plus {...iconSize} />}
                        style={{ borderRadius: '30px' }}
                      >
                        {polyglot.t('NewExpensePage.addLineItems')}
                      </ButtonComponent>

                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <TextfieldComponent
                          name="notes"
                          label={polyglot.t('NewExpensePage.notes')}
                          value={formik.values.notes}
                          type="text"
                          onChange={formik.handleChange}
                          error={formik.touched.notes && Boolean(formik.errors.notes)}
                          helperText={(formik.touched.notes && formik.errors.notes) as string}
                          clearText={() => formik.setFieldValue('notes', '')}
                          multiline
                          minRows={1}
                        />
                      </FormControl>

                      <Stack direction="column">
                        <Typography variant="captionSmall">{polyglot.t('NewExpensePage.receipt')}</Typography>
                        {formik.errors.attachment && (
                          <Typography variant="captionSmall" color="RedDark">
                            {formik.errors.attachment}
                          </Typography>
                        )}
                        {formik.values.attachment && receiptFilename ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'space-between',
                              mt: spacing.mt20,
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <DocumentIcon {...iconSize} />
                              <Box
                                component="span"
                                sx={{ ...themeFonts.title4, marginLeft: spacing.m10, color: themeColors.DarkGrey }}
                              >
                                {receiptFilename}
                              </Box>
                            </Box>
                            <IconButton
                              sizeVariant="small"
                              colorVariant="secondary"
                              onClick={() => formik.setFieldValue('attachment', null)}
                            >
                              <TrashIcon {...iconSize} />
                            </IconButton>
                          </Box>
                        ) : (
                          <UploadInput<{ uuid: string; fileName: string }>
                            onChange={(resp) => {
                              formik.setFieldValue('attachment', resp?.uuid);
                              let filename = resp?.fileName;
                              filename = filename?.substring(filename?.lastIndexOf('/') + 1);
                              setReceiptFilename(filename ?? '');
                            }}
                          />
                        )}
                      </Stack>

                      <Divider />

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Stack direction="column">
                          <Typography variant="title4">{polyglot.t('NewInvoicePage.amount')}</Typography>
                          <Stack direction="row" sx={{ height: '20px' }}>
                            <Typography variant="caption">
                              {polyglot.t('NewInvoicePage.taxAmount')}
                              {!multipleLineItems ? getTaxRateForTotalSection(formik.values.taxRate) : ''}
                            </Typography>
                          </Stack>
                          <Typography variant="title4">{polyglot.t('NewInvoicePage.gross')}</Typography>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: 'flex-end' }}>
                          <Typography variant="title4">
                            {formatCurrency(
                              formik.values.amount ? (formik.values.amount as number) : 0,
                              undefined,
                              formik.values.currency ?? DEFAULT_CURRENCY
                            )}
                          </Typography>
                          <Typography variant="caption">
                            {expenseToEdit
                              ? formatCurrency(
                                  (formik.values.gross ? formik.values.gross : 0) -
                                    (formik.values.amount ? formik.values.amount : 0),
                                  undefined,
                                  formik.values.currency ?? DEFAULT_CURRENCY
                                )
                              : formatCurrency(
                                  formik.values.taxRate ? formik.values.taxAmount : 0,
                                  undefined,
                                  formik.values.currency ?? DEFAULT_CURRENCY
                                )}
                          </Typography>
                          <Typography variant="title4">
                            {formatCurrency(
                              formik.values.gross ?? 0,
                              undefined,
                              formik.values.currency ?? DEFAULT_CURRENCY
                            )}
                          </Typography>
                        </Stack>
                      </Box>

                      <ApproversList approverSteps={approversSets} sx={{ mt: '20px' }} />

                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          mt: spacing.mt10,
                          gap: spacing.g5,
                        }}
                      >
                        {((expenseToEdit && expenseToEdit.status === ExpenseStatus.Draft) || !expenseToEdit) && (
                          <ButtonComponent
                            fullWidth
                            sizeVariant="medium"
                            colorVariant="secondary"
                            onClick={() => handleSaveDraft()}
                            loading={submitting}
                          >
                            {polyglot.t('OnboardingUserInvite.saveDraft')}
                          </ButtonComponent>
                        )}
                        <ButtonComponent
                          fullWidth
                          loading={submitting}
                          colorVariant="primary"
                          sizeVariant="medium"
                          onClick={() => handleSubmit()}
                        >
                          {polyglot.t('General.submit')}
                        </ButtonComponent>
                      </Box>
                    </Box>
                  </Form>
                </FormikProvider>
              </Box>
              {typeDrawerOpen && (
                <PaymentSettingsNewTypeDrawer
                  isOpen={typeDrawerOpen}
                  setIsOpen={setIsTypeDrawerOpen}
                  refreshAllSettings={refreshExpensePaymentTypeSettingsData}
                  typeForEdit={undefined}
                />
              )}
              {
                <ExpenseLineItemDrawer
                  isOpen={addingLineItemDrawerOpen}
                  setIsOpen={setAddingLineItemDrawerOpen}
                  updateLineItems={updateLineItems}
                  existingLineItems={formik.values.lineItems}
                  accountingCodeOptions={accountingCodeOptions ?? []}
                  indexToEdit={editingLineItemIndex}
                />
              }
            </Box>
          </ContentWrapper>
        </Box>
      </Box>
    </RootStyle>
  );
};
