import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';

interface Props {
  title: string;
  maxWidth: number;
  cell: JSX.Element;
}

/**
 * title x (fontsize - letterSpacing) // approx
 */

export const TooltipCell = ({ title, maxWidth, cell }: Props) => {
  const getCellResults = () => {
    if (title.length * 10 > maxWidth + 20) return <StyledTooltip title={title} children={cell} />;
    else {
      return <>{cell}</>;
    }
  };
  return <>{getCellResults()}</>;
};
