import { useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { SurveyResultsFilters } from '@v2/feature/growth/surveys/features/components/survey-results-filters.component';
import { SurveyResultsByFactor } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-results-by-factor.component';
import { SurveyResultsByQuestion } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-results-by-question.component';
import {
  SurveyCycle,
  SurveyCycleResult,
  SurveyResultAnswerResponse,
  SurveyResultQuestionResponse,
} from '@v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { jsPDF } from 'jspdf';
import { Dictionary } from 'lodash';

import { Action, GlobalContext, GlobalContextState } from '@/GlobalState';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { iconSize } from '@/v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { TitleStatusComponent } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';
import { captureExcel } from '@/v2/util/export-reports.util';
import { LocalDate } from '@/v2/util/local-date';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';
export const MeasureFilterToLabel = {
  nps: 'NPS',
  positive: 'Positive sentiment',
  avg: 'Average',
};

export const SurveyCycleDetailResultsPage = ({
  surveyCycle,
  cycleLoading,
  growthFactors,
  surveyResult,
  loadingResult,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
}: {
  readonly surveyCycle: SurveyCycle;
  readonly cycleLoading: boolean;
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly surveyResult: SurveyCycleResult | undefined | null;
  readonly loadingResult: boolean | undefined;
  readonly filtersAndTypesOptions: any;
  readonly setFilterString: React.Dispatch<React.SetStateAction<string>>;
  readonly filterString: string;
}) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { getCachedUserById } = useCachedUsers();

  const reportName = useMemo(() => `${surveyCycle.internalName}-result`, [surveyCycle.internalName]);
  const { user } = state;
  const company_name = user?.company?.name ?? 'Company';
  const generateExcel = () => {
    if (!surveyResult || !surveyCycle) return;
    const { resultByQuestion, resultByFactor, impactResult } = surveyResult;
    const uniqueFactors: Set<string> = new Set();
    (resultByQuestion || []).forEach((item) => {
      const impacts = impactResult ? impactResult[item.question.id] || {} : {};
      Object.keys(impacts).forEach((factor) => {
        uniqueFactors.add(factor);
      });
    });

    const factorsArray = Array.from(uniqueFactors);

    const header = [
      'Type',
      'Text',
      'Question Factor',
      'Average Score',
      'NPS Score',
      'Positive Sentiment',
      'Neutral Sentiment',
      'Negative Sentiment',
      ...factorsArray.map((factor) => `Impact (${factor})`),
    ];

    const data = [];
    data.push(header);
    (resultByQuestion || []).forEach((item) => {
      const row = [];
      row.push('Question');
      row.push(replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name }));
      row.push(item.question.factor);
      row.push(item.normPercent ? (item.normPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.npsPercent ? (item.npsPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.positivePercent ? (item.positivePercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.neutralPercent ? (item.neutralPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.negativePercent ? (item.negativePercent / 100).toFixed(2) : (0).toFixed(2));

      const impacts = impactResult ? impactResult[item.question.id] || null : null;
      factorsArray.forEach((factor) => {
        const impact = impacts ? impacts[factor] : null;
        const coefficient = impact && typeof impact.coefficient === 'number' ? impact.coefficient.toFixed(2) : '–';
        row.push(coefficient);
      });

      data.push(row);
    });

    (resultByFactor || []).forEach((item) => {
      const row = [];
      row.push('Factor');
      row.push(item.factor);
      row.push('–');
      row.push(item.normPercent ? (item.normPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.npsPercent ? (item.npsPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.positivePercent ? (item.positivePercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.neutralPercent ? (item.neutralPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.negativePercent ? (item.negativePercent / 100).toFixed(2) : (0).toFixed(2));

      factorsArray.forEach(() => {
        row.push('–');
      });

      data.push(row);
    });
    captureExcel(data, reportName);
  };
  const generatePdfReport = (
    surveyResult: SurveyCycleResult | null | undefined,
    surveyCycle: SurveyCycle,
    reportName: string
  ) => {
    if (!surveyResult) return;

    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFont('Times-Roman', 'bold');

    const startDate = surveyCycle.timelineSettings?.startDate
      ? new LocalDate(surveyCycle.timelineSettings.startDate).toLocaleDateString()
      : new LocalDate().toLocaleDateString();

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 14;
    const maxWidth = pageWidth - margin * 2;
    const lineHeight = 8;
    const textLineHeight = 6;
    let yPosition = 22;

    const checkAndAddPage = () => {
      if (yPosition + lineHeight > pageHeight - margin) {
        doc.addPage();
        yPosition = margin + lineHeight;
      }
    };

    // Title
    doc.setFontSize(18);
    doc.text(`${surveyCycle.displayName}`, margin, yPosition);
    yPosition += textLineHeight;

    // Survey cycle details
    doc.setFont('Times-Roman', 'normal');
    doc.setFontSize(12);
    doc.text(`Start Date: ${startDate}`, margin, yPosition);
    yPosition += textLineHeight * 2;

    if (
      surveyResult.resultByQuestion &&
      surveyResult.resultByQuestion.length === 0 &&
      surveyResult.resultByFactor.length === 0
    ) {
      yPosition += textLineHeight;
      doc.setFont('Times-Roman', 'normal');
      doc.text(`Not sufficient data to show results.`, margin, yPosition);
    }

    if (surveyResult.resultByQuestion && surveyResult.resultByQuestion.length > 0) {
      // Results by Question
      doc.setFont('Times-Roman', 'bold');
      doc.setFontSize(14);
      doc.text('Results by Question', margin, yPosition);
      yPosition += 4;
      doc.setLineWidth(1);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += lineHeight / 2;
      surveyResult.resultByQuestion.forEach((questionObject, idx) => {
        yPosition += lineHeight;
        const { questionText, type } = questionObject.question;
        const questionStrippedHtml = replaceParamsInQuestionText(stripHtml(questionText), { company_name });
        doc.setFont('Times-Roman', 'bold');
        doc.setFontSize(12);
        const splitQuestionText = doc.splitTextToSize(`${questionStrippedHtml}`, maxWidth);

        splitQuestionText.forEach((line: string) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });

        if (questionObject.answersLowerThanThreshold) {
          yPosition += 2;
          doc.setFont('Times-Roman', 'normal');
          doc.text(`Not sufficient data to show results.`, margin, yPosition);
          yPosition += textLineHeight;
          yPosition += 2;
          yPosition += lineHeight / 2;
          checkAndAddPage();
          return;
        }
        yPosition += textLineHeight / 2;
        questionObject.answers.forEach((answer) => {
          const { comment } = answer;
          const username = !answer.participantId
            ? 'Author hidden'
            : getCachedUserById(answer.participantId)?.displayName || 'NA';
          const commentText = comment ? stripHtml(comment) : '';
          const answerLabel =
            type === QuestionType.OpenEnded ? commentText : getAnswerLabel(questionObject.question, answer);

          doc.setFont('Times-Roman', 'normal');
          doc.setFontSize(12);
          const answerText = `[${username}] ${answerLabel}`;
          const splitAnswerText = doc.splitTextToSize(answerText, maxWidth);

          splitAnswerText.forEach((line: string) => {
            checkAndAddPage();
            doc.text(line, margin, yPosition);
            yPosition += textLineHeight;
          });

          if (commentText && type !== QuestionType.OpenEnded) {
            doc.setFont('Times-Italic', 'italic'); // Set font to italic
            const splitCommentText = doc.splitTextToSize(`[Comment]: ${commentText}`, maxWidth);
            splitCommentText.forEach((line: string) => {
              checkAndAddPage();
              doc.text(line, margin, yPosition);
              yPosition += textLineHeight;
            });
            doc.setFont('Times-Roman', 'normal'); // Revert to normal font
          }

          yPosition += 2;
        });

        yPosition += lineHeight / 2;

        checkAndAddPage();
        if (idx !== surveyResult.resultByQuestion.length - 1) {
          doc.setLineWidth(0.5);
          doc.setDrawColor(0);
          doc.line(margin, yPosition, pageWidth - margin, yPosition);
          yPosition += lineHeight / 2;
        }
      });
    }

    // Results by Factor
    if (surveyResult.resultByFactor.length > 0) {
      // Start new page for results by factor
      doc.addPage();
      yPosition = margin;
      yPosition += textLineHeight;

      doc.setFont('Times-Roman', 'bold');
      doc.setFontSize(14);
      doc.text('Results by Factor', margin, yPosition);
      yPosition += 4;
      doc.setLineWidth(1);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += lineHeight / 2;
      surveyResult.resultByFactor.forEach((factor, idx) => {
        yPosition += lineHeight / 2;
        doc.setFont('Times-Roman', 'bold');
        doc.setFontSize(12);
        doc.text(`Factor: ${factor.factor}`, margin, yPosition);
        yPosition += textLineHeight;

        doc.setFont('Times-Roman', 'normal');
        doc.text(`Average Score: ${factor.normPercent}%`, margin, yPosition);
        yPosition += textLineHeight;

        doc.text(`NPS Score: ${factor.npsPercent}%`, margin, yPosition);
        yPosition += textLineHeight;

        doc.text(`Positive Score: ${factor.positivePercent}%`, margin, yPosition);
        yPosition += textLineHeight;

        doc.text(`Number of Questions: ${factor.noOfQuestions}`, margin, yPosition);
        yPosition += textLineHeight;

        checkAndAddPage();
        yPosition += lineHeight / 2;

        if (idx !== surveyResult.resultByFactor.length - 1) {
          doc.setLineWidth(0.5);
          doc.setDrawColor(0);
          doc.line(margin, yPosition, pageWidth - margin, yPosition);
          yPosition += lineHeight / 2;
        }
      });
    }

    doc.save(`${reportName}.pdf`);
  };

  const getAnswerLabel = (question: SurveyResultQuestionResponse, answer: SurveyResultAnswerResponse) => {
    if (!answer?.answer) return '';
    if (question.type === QuestionType.ScaleQuestion)
      return `${question.scaleConfig?.points?.[answer.answer]} (${
        question.scaleConfig?.value?.[answer.answer] || 0
      } out of ${Math.max(...Object.values(question.scaleConfig?.value || []).map(Number))})`;

    if (question.type === QuestionType.MultipleAnswer)
      return answer.answer
        .split(',')
        .map((choice) => question.choiceOptions[Number(choice)])
        .join(', ');

    if (question.type === QuestionType.SingleAnswer) return question.choiceOptions?.[Number(answer.answer)] || 'NA';

    return '';
  };
  return (
    <SettingsSectionContent
      title={<TitleStatusComponent surveyCycle={surveyCycle} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      headerWidth="100%"
      loading={cycleLoading || loadingResult}
      buttons={[
        <StyledMenuComponent
          options={[
            {
              handler: generateExcel,
              label: 'Excel report',
            },
            {
              handler: () => generatePdfReport(surveyResult, surveyCycle, reportName),
              label: 'PDF report',
            },
          ]}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Export',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />,
      ]}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Results',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <SurveyResultList
                    surveyResult={surveyResult!}
                    growthFactors={growthFactors}
                    state={state}
                    dispatch={dispatch}
                    filtersAndTypesOptions={filtersAndTypesOptions || []}
                    setFilterString={setFilterString}
                    filterString={filterString}
                    surveyCycle={surveyCycle}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </SettingsSectionContent>
  );
};

interface SurveyResultListProps {
  readonly surveyResult: SurveyCycleResult;
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly state: GlobalContextState;
  dispatch: React.Dispatch<Action>;
  readonly filtersAndTypesOptions: any;
  readonly setFilterString: React.Dispatch<React.SetStateAction<string>>;
  readonly filterString: string;
  readonly surveyCycle: SurveyCycle;
}

const SurveyResultList = ({
  surveyResult,
  growthFactors,
  state,
  dispatch,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
  surveyCycle,
}: SurveyResultListProps) => {
  const [showByFilter, setShowByFilter] = useState<'Question' | 'Factor'>(
    (state.user.features?.surveys?.results?.showBy as 'Question' | 'Factor') || 'Question'
  );
  const [measureByFilter, setMeasureByFilter] = useState<'nps' | 'positive' | 'avg'>(
    (state.user.features?.surveys?.results?.measuredBy as 'nps' | 'positive' | 'avg') || 'nps'
  );

  const existingFactors = useMemo(() => {
    const factorsArray = surveyResult?.resultByQuestion.map((r) => r.question.factor).filter(Boolean) as string[];
    return [...new Set(factorsArray)];
  }, [surveyResult]);

  const [impactFilter, setImpactFilter] = useState<string>(existingFactors[0] || '');

  useEffect(() => {
    setImpactFilter(existingFactors[0] ?? '');
  }, [existingFactors]);

  return (
    <Box>
      <SurveyResultsFilters
        existingFactors={existingFactors}
        showByFilter={showByFilter}
        setShowByFilter={setShowByFilter}
        measureByFilter={measureByFilter}
        setMeasureByFilter={setMeasureByFilter}
        impactFilter={impactFilter}
        setImpactFilter={setImpactFilter}
        dispatch={dispatch}
        filtersAndTypesOptions={filtersAndTypesOptions || []}
        setFilterString={setFilterString}
        filterString={filterString}
        surveyCycle={surveyCycle}
      />

      <div>
        {showByFilter === 'Factor' ? (
          <SurveyResultsByFactor
            resultByFactor={surveyResult.resultByFactor}
            growthFactors={growthFactors}
            measureByFilter={measureByFilter}
          />
        ) : showByFilter === 'Question' ? (
          <SurveyResultsByQuestion
            resultByQuestion={surveyResult.resultByQuestion}
            impactResult={surveyResult.impactResult}
            growthFactors={growthFactors}
            impactFilter={impactFilter}
            measureByFilter={measureByFilter}
          />
        ) : null}
      </div>
    </Box>
  );
};
