import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const CONFIRMATION = 'Add carry over as adjustment to all members';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto | undefined | null;
  readonly companyId: number;
  readonly carryOverYear: number;
  readonly refresh: () => Promise<void>;
}

export const HelperAbsenceCarryOverCalculationDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  companyId,
  carryOverYear,
  refresh,
}: DrawerProps) => {
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState<string>('');

  const calculateCarryOver = useCallback(
    async (confirmationText: string) => {
      if (!absencePolicy) return;
      if (confirmationText !== CONFIRMATION) return;

      try {
        setLoading(true);
        await AbsenceAPI.calculateCompanyPolicyCarryOverByYearAsSuperAdmin(companyId, carryOverYear, absencePolicy.id);
        showMessage('Success', 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        console.error(error);
        showMessage(`Could not calculate carry over. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
    [companyId, carryOverYear, showMessage, refresh, absencePolicy, setIsOpen]
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={() => setConfirmation('')}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">Confirm Carry Over Calculation</Typography>

        <Box>
          <Typography variant="captionSmall">Policy</Typography>
          <Typography variant="title4">
            {absencePolicy?.fullName ?? absencePolicy?.name ?? 'Absence policy'} [Policy ID:{' '}
            {absencePolicy?.id ?? 'N/A'}]
          </Typography>
        </Box>

        <Box>
          <Typography variant="captionSmall">Year</Typography>
          <Typography variant="title4">{carryOverYear}</Typography>
        </Box>

        <Typography variant="caption">
          You are going to calculate carry over and add adjustments where necessary to all the members of this policy
        </Typography>

        <Typography variant="caption">To continue please type "{CONFIRMATION}" in the box bellow</Typography>

        <TextfieldComponent
          name="confirmation"
          label="Confirmation"
          value={confirmation}
          onChange={(e) => {
            setConfirmation(e.target.value ?? '');
          }}
          error={Boolean(confirmation && confirmation !== CONFIRMATION)}
          helperText={confirmation && confirmation !== CONFIRMATION ? 'Please confirm action' : ''}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" fullWidth>
            Cancel
          </ButtonComponent>
          <LoaderButton
            sizeVariant="medium"
            loading={loading}
            colorVariant="danger"
            name="Calculate Carry Over"
            onClick={async () => {
              await calculateCarryOver(confirmation);
            }}
            fullWidth
            disabled={confirmation !== CONFIRMATION}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
