import { Suspense } from 'react';

import { Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceModelDto, DeviceOrderDto } from '@v2/feature/device/device.dto';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminDeviceOrdersTable } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-orders-table.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { BackofficeRootStyle } from '@v2/styles/root.styles';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface SuperAdminDeviceOrdersPageProps {
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly deviceModels: readonly DeviceModelDto[];
  readonly pageConfig: PageConfig;
  readonly sites: { [siteId: number]: SiteDto };
  readonly deviceOrders: readonly DeviceOrderDto[];
  readonly refreshDeviceOrders: () => Promise<void>;
}

export const SuperAdminDeviceOrdersPage = ({
  users,
  companies,
  deviceModels,
  pageConfig,
  sites,
  deviceOrders,
  refreshDeviceOrders,
}: SuperAdminDeviceOrdersPageProps): JSX.Element => {
  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>}
        views={<></>}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={false} secondLevel>
        {Boolean(deviceOrders && deviceOrders.length > 0) ? (
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="83%"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            }
          >
            <SuperAdminDeviceOrdersTable
              deviceOrders={deviceOrders}
              users={users}
              companies={companies}
              refresh={refreshDeviceOrders}
              deviceModels={deviceModels}
              sites={sites}
            />
          </Suspense>
        ) : (
          <Typography variant="subtitle1">No device orders.</Typography>
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
