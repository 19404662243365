import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Apps } from '@/images/dashboard-icons/Apps.svg';
import { AppActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/app-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { AppRequestTodo, PendingRequestsBigWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

export const AppRequestBigTodo = ({
  todo,
  currentUserId,
  setLastChild,
}: {
  todo: PendingRequestsBigWidget;
  currentUserId: number;
  setLastChild: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<AppRequestTodo | undefined>(undefined);

  return (
    <Box>
      {todo.apps.map((a, idx) => {
        const title =
          currentUserId === a.userId
            ? polyglot.t('AppRequestBigTodo.appRequest', { appName: a.appName })
            : polyglot.t('AppRequestBigTodo.appRequested', {
                appName: a.appName,
                user: getCachedUserById(a.userId)?.displayName,
              });

        return (
          <TodoCard
            key={`-${idx}-${a.userId}`}
            icon={<Apps {...iconSize} />}
            title={
              <Typography
                variant="caption"
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {title}
              </Typography>
            }
            actionOnclick={() => setSelectedRow(a)}
            showBorder={!(setLastChild && todo.apps.length === idx + 1)}
            userAvatar={<UserAvatar userId={a.userId} size="xxxsmall" />}
          />
        );
      })}

      {selectedRow && (
        <AppActionItem appActionRow={selectedRow as AppRequestTodo} afterClose={() => setSelectedRow(undefined)} />
      )}
    </Box>
  );
};
