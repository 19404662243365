import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { todaysDateShortISOString } from '@v2/util/date-format.util';

import { EventsAPI } from '@/api-client/events.api';
import { UserEventDto } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { nestErrorMessage } from '@/lib/errors';
import { UserLifecycleInfo } from '@/models';
import { DateLabelComponent, getDateString } from '@/v2/components/forms/date-label.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useProfileFormUpdate } from '@/v2/feature/user/context/user-profile.context';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import { UserLifecycleStatuses } from '@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import { ShowHideButton } from '@/v2/feature/user/features/user-profile/details/components/show-hide-button.component';
import {
  cardHeaderSx,
  cardMaxWidth,
  definitionListSx,
  definitionValueSx,
  hazardSize,
  showTableSx,
  tablecardSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserProfileLifecycleForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-lifecycle-form.component';
import { LifecycleFormState } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import {
  translategetAllChangeTypeOptions,
  translateUserProfileLifecycleCard,
} from '@/v2/infrastructure/i18n/translate.util';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
import { sleep } from '@/v2/util/time.util';

const defaultBlankModalValues: UserEventDto = {
  id: 0,
  userId: 0,
  companyId: 0,
  createdBy: 0,
  updatedBy: 0,
  effectiveDate: todaysDateShortISOString(),
  status: undefined,
  changeReason: '',
  note: null,
  effectiveEndDate: null,
};

const SCOPE = 'user.lifecycle:all' as const;

export const UserProfileLifecycleCard = ({
  userId,
  handleSubmit = () => {},
  missingFields,
}: UserProfileCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<EntriesAndEffectiveRecord<UserEventDto>>();
  const [userLifeCycle, setUserLifeCycle] = useState<UserLifecycleInfo>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [rowModalMode, setRowModalMode] = useState<string>('add');
  const [showMessage] = useMessage();
  const [modalInitialValues, setModalInitialValues] = useState<UserEventDto>(defaultBlankModalValues);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [formState, setFormState] = useState<LifecycleFormState | null>(null);

  const setFormCurrentlyEditing = useProfileFormUpdate();
  const { getCachedUserById } = useCachedUsers();

  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasLifecycleScope = hasScopes(['user.lifecycle:read', 'user.lifecycle:manager'], scopesContext);
  // const s = getAllChangeTypeOptions(polyglot);
  const loadInitialData = useCallback(async (): Promise<void> => {
    if (!userId) return;
    setLoading(true);
    try {
      const [userEvents, userLifecycle] = await Promise.all([
        EventsAPI.getAllUserEvents(userId),
        hasLifecycleScope ? UserAPI.getUserLifecycleInfo(userId) : undefined,
      ]);
      setData(userEvents);
      setUserLifeCycle(userLifecycle);
    } catch (error) {
      showMessage(`${polyglot.t('UserProfileLifecycleCard.errorMessages.error')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [polyglot, hasLifecycleScope, showMessage, userId]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const editLifeCycleRow = useCallback((rowData: UserEventDto) => {
    setModalInitialValues({ ...rowData });
    setRowModalMode('edit');
    setFormState(null);
    setIsModalOpen(true);
  }, []);

  const getCurrentEmployedEvent = (): UserEventDto | undefined => {
    const allEntries = data?.entries;
    return allEntries?.find((eachEntry) => eachEntry.status === UserLifecycleStatuses.Employed);
  };

  const addLeaveForUser = () => {
    setModalInitialValues({ ...defaultBlankModalValues, status: UserLifecycleStatuses.Leave });
    setFormState('Leave');
    setRowModalMode('add');
    setIsModalOpen(true);
  };

  const rehireUser = () => {
    const currentEmployedEvent = getCurrentEmployedEvent();
    setModalInitialValues({
      ...(currentEmployedEvent ?? defaultBlankModalValues),
      status: UserLifecycleStatuses.Employed,
      changeReason: 'rehired',
    });
    setFormState('Rehire');
    setRowModalMode('edit');
    setIsModalOpen(true);
  };

  const getMenuItemsBasedOnCurrentlyEffectiveStatus = (): ReactElement[] => {
    const ADD_LEAVE_MENU_OPTION = (
      <Button key="add-leave" onClick={() => addLeaveForUser()} sx={secondaryCTABtn}>
        {polyglot.t('UserProfileLifecycleCard.newLeave')}
      </Button>
    );
    const REHIRE_MENU_OPTION = (
      <Button key="rehire" onClick={() => rehireUser()} sx={secondaryCTABtn}>
        {polyglot.t('UserProfileLifecycleCard.rehire')}
      </Button>
    );

    if (data?.effectiveRecord?.status === UserLifecycleStatuses.Employed) {
      return [ADD_LEAVE_MENU_OPTION];
    }
    if (data?.effectiveRecord?.status === UserLifecycleStatuses.Terminated) {
      return [REHIRE_MENU_OPTION];
    }
    return [];
  };

  const refreshLifecycle = async () => {
    if (hasLifecycleScope) {
      await sleep(5000);
      setUserLifeCycle(await UserAPI.getUserLifecycleInfo(userId));
    }
  };

  const columnData = useMemo<ColumnDef<UserEventDto, UserEventDto>[]>(
    () => [
      {
        header: () => polyglot.t('UserProfileLifecycleCard.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>{translateUserProfileLifecycleCard(original.status ? original.status : '', polyglot)}</div>
        ),
      },
      {
        header: () => polyglot.t('UserProfileLifecycleCard.eventStartDate'),
        accessorFn: (row) => row,
        id: 'eventStartDate',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return <div>{original.effectiveDate ? getDateString(original.effectiveDate) : ''}</div>;
        },
      },
      {
        header: () => polyglot.t('UserProfileLifecycleCard.changeType'),
        accessorFn: (row) => row,
        id: 'changeReason',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div style={{ textTransform: 'capitalize' }}>
            {translategetAllChangeTypeOptions(original.changeReason ? original.changeReason : '', polyglot)}
          </div>
        ),
      },
      {
        header: () => polyglot.t('UserProfileLifecycleCard.changeBy'),
        accessorFn: (row) => row,
        id: 'changeBy',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>
            {original.updatedBy ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UserAvatar userId={original.updatedBy} size="xxsmall" />
                <Typography sx={{ ...themeFonts.caption, marginLeft: spacing.m10, color: themeColors.DarkGrey }}>
                  {getCachedUserById(original.updatedBy)?.displayName}
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {original.status !== UserLifecycleStatuses.Hired && (
              <ScopesControl scopes={[SCOPE]} context={scopesContext}>
                <IconButton
                  title={polyglot.t('UserProfileLifecycleCard.edit')}
                  onClick={() => {
                    editLifeCycleRow(original);
                  }}
                  sx={tableIconButtonSx}
                >
                  <Edit {...iconSize} />
                </IconButton>
              </ScopesControl>
            )}
          </div>
        ),
      },
    ],
    [polyglot, scopesContext, editLifeCycleRow, getCachedUserById]
  );

  return (
    <Box sx={tablecardSx}>
      <Box sx={{ ...cardMaxWidth }}>
        <Box component="header" sx={cardHeaderSx}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
            <Typography variant="h3" sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {polyglot.t('UserProfileLifecycleCard.lifecycle')}
            </Typography>
            <ScopesControl scopes={['user:all']} context={scopesContext}>
              {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.Lifecycle) && (
                <Box sx={hazardMissingField}>
                  <Hazard {...hazardSize} />
                </Box>
              )}
            </ScopesControl>
          </Box>

          {
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                {getMenuItemsBasedOnCurrentlyEffectiveStatus()}
              </Box>
            </ScopesControl>
          }
        </Box>
        <Box component="section">
          {!data?.effectiveRecord ? (
            <MissingInformationLabel />
          ) : (
            <Box>
              {
                <Box component="dl" sx={definitionListSx}>
                  {data?.effectiveRecord?.status && (
                    <FieldStructure
                      fieldTitle={polyglot.t('UserProfileLifecycleCard.status')}
                      fieldValue={translateUserProfileLifecycleCard(data?.effectiveRecord?.status ?? '', polyglot)}
                    />
                  )}
                  {userLifeCycle?.startDate && (
                    <FieldStructure
                      fieldTitle={polyglot.t('UserProfileLifecycleCard.startDate')}
                      fieldValue={<DateLabelComponent date={userLifeCycle?.startDate} titleSx={definitionValueSx} />}
                    />
                  )}
                  {userLifeCycle?.leaveDate && (
                    <FieldStructure
                      fieldTitle={polyglot.t('UserProfileLifecycleCard.endDate')}
                      fieldValue={<DateLabelComponent date={userLifeCycle?.leaveDate} titleSx={definitionValueSx} />}
                    />
                  )}
                </Box>
              }
            </Box>
          )}
        </Box>
      </Box>
      {data?.effectiveRecord && (
        <>
          <Box>
            <ShowHideButton showDetails={showDetails} setShowDetails={setShowDetails} />
          </Box>

          {showDetails && data && (
            <Box sx={showTableSx}>
              <BasicTable rowData={[...data.entries]} columnData={columnData} />
            </Box>
          )}
        </>
      )}
      <ScopesControl scopes={[SCOPE]} context={scopesContext}>
        <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <UserProfileLifecycleForm
            initialValues={modalInitialValues}
            allEntries={data?.entries}
            userId={userId}
            loadInitialData={loadInitialData}
            rowModalMode={rowModalMode}
            handleSubmit={handleSubmit}
            setFormCurrentlyEditing={setFormCurrentlyEditing}
            setIsModalOpen={setIsModalOpen}
            setLoading={setLoading}
            loading={loading}
            onClose={() => {
              setIsModalOpen(false);
            }}
            refreshLifecycle={refreshLifecycle}
            formState={formState}
          />
        </DrawerModal>
      </ScopesControl>
    </Box>
  );
};
