import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { DraggableAbsencePolicies } from '@v2/feature/absence/subfeatures/settings/components/draggable-absence-policies.component';
import { NewTimePolicyDrawer } from '@v2/feature/absence/subfeatures/settings/components/new-time-policy-drawer.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { RootStyle } from '@v2/styles/root.styles';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface AbsenceCompanySettingsPoliciesPageProps {
  readonly absencePolicies: readonly AbsencePolicyDto[];
  readonly archivedPolicies: readonly AbsencePolicyDto[];
  readonly refreshPolicies: () => Promise<void>;
  readonly isLoading: boolean | undefined;
}

export const AbsenceCompanySettingsPoliciesPage = ({
  absencePolicies,
  archivedPolicies,
  refreshPolicies,
  isLoading,
}: AbsenceCompanySettingsPoliciesPageProps) => {
  const { polyglot } = usePolyglot();

  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), { suspense: false });
  const { data: entities } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });
  const { data: approvalRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const allPolicies = useMemo(() => [...absencePolicies, ...archivedPolicies], [absencePolicies, archivedPolicies]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey, ml: '9px' }}>
            {polyglot.t('AbsenceCompanySettingsPoliciesPage.policies')}
          </Typography>
        }
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            style={{ marginRight: '9px' }}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            {polyglot.t('AbsenceCompanySettingsPoliciesPage.new')}
          </ButtonComponent>
        }
        showAction
        sx={{ width: '618px', mx: 'auto' }}
      />
      <Box sx={{ width: '100%', overflowY: 'auto', mx: 'auto' }}>
        <Box sx={{ width: '618px', mx: 'auto' }}>
          <ContentWrapper
            noHorizontalPadding
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '600px',
              px: '9px',
              pt: '2px',
            }}
            loading={isLoading}
          >
            {allPolicies.length > 0 && (
              <DraggableAbsencePolicies
                absencePolicies={allPolicies}
                sites={sites ?? []}
                departments={departments ?? []}
                entities={entities ?? []}
                approvalRules={approvalRules ?? []}
              />
            )}

            {isDrawerOpen && (
              <NewTimePolicyDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} refresh={refreshPolicies} />
            )}
          </ContentWrapper>
        </Box>
      </Box>
    </RootStyle>
  );
};
