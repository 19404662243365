import { useContext, useMemo } from 'react';

import { GlobalContext } from '@/GlobalState';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SCRollingTimelineView } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/components/rolling/sc-rolling-timeline-view.component';
import { SCScheduledTimelineView } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/components/scheduled/sc-scheduled-timeline-view.component';
import { TitleStatusComponent } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const getDayOrDays = (days: number): string => (days > 1 ? 'days' : 'day');

export const SCUpsertTimelinePage = ({ cycleId, reach }: { cycleId: string; reach: ReachType }) => {
  const { data: surveyCycle, mutate: refreshCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, reach),
    {
      suspense: false,
    }
  );
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['surveys:all']);
  const isManager = canAccessScopes(user, ['surveys:manager']);

  const isEditable = useMemo(
    () =>
      Boolean(
        surveyCycle &&
          surveyCycle.state !== CycleState.Completed &&
          Boolean(isAdmin || (isManager && surveyCycle.owner === user.userId))
      ),
    [surveyCycle, isAdmin, isManager, user]
  );

  if (!surveyCycle) return <></>;

  return (
    <SettingsSectionContent
      title={surveyCycle ? <TitleStatusComponent surveyCycle={surveyCycle} /> : 'Timeline'}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={cycleLoading}
    >
      {surveyCycle.type === CycleType.Scheduled && (
        <SCScheduledTimelineView surveyCycle={surveyCycle} isEditable={isEditable} refreshCycle={refreshCycle} />
      )}

      {surveyCycle.type === CycleType.Rolling && (
        <SCRollingTimelineView surveyCycle={surveyCycle} isEditable={isEditable} refreshCycle={refreshCycle} />
      )}
    </SettingsSectionContent>
  );
};
