import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Box, Menu, MenuItem, MenuProps, styled, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Lottie from 'lottie-react';
import { useHistory } from 'react-router-dom';

import NewAction from '@/animations/new-action.json';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as AbsenceIcon } from '@/images/attendance/ClockBig.svg';
import { ReactComponent as DocumentIcon } from '@/images/documents/DocumentBig.svg';
import { ReactComponent as Payment } from '@/images/side-bar-icons/Bill.svg';
import { ReactComponent as OffboardingIcon } from '@/images/side-bar-icons/Offboarding.svg';
import { ReactComponent as OnboardingIcon } from '@/images/side-bar-icons/Onboarding.svg';
import { ReactComponent as RequestIcon } from '@/images/side-bar-icons/Request.svg';
import { ReactComponent as TaskIcon } from '@/images/task/TaskBig.svg';
import { CreateZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import {
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  REQUESTS_CHOOSE_REQUEST_ROUTE,
} from '@/lib/routes';
import { CurrentUser } from '@/models';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { AbsenceDrawer } from '@/v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import { DocumentFormModal } from '@/v2/feature/documents/components/document-form-modal.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { DocumentAction } from '@/v2/feature/documents/documents.interface';
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal,
} from '@/v2/feature/documents/documents.util';
import { OffboardingDrawer } from '@/v2/feature/offboarding/components/offboarding-drawer.component';
import { OnboardNewEmployee } from '@/v2/feature/onboarding/components/onboard-new-employee.component';
import { RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { TaskFormModal } from '@/v2/feature/task/components/task-add-modal/task-form-modal.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useUserProfileConfig } from '@/v2/feature/user/features/user-profile/user-profile.hook';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 20px 0px rgba(13, 13, 14, 0.1)',
    textAlign: 'left',
    borderRadius: radius.br10,

    '& .MuiMenu-list': {
      ...spacing.pad20,
      gap: '50px',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      padding: '0px',
      minHeight: '20px',
      gap: spacing.g10,
      ...themeFonts.caption,
      fill: themeColors.DarkGrey,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        color: themeColors.Grey,
        fill: themeColors.Grey,
      },
    },
  },
}));

const initialState = initialiseState([]);

export const QuickAction = ({ user }: { user: CurrentUser }) => {
  const { polyglot } = usePolyglot();
  const { refreshCachedUsers } = useCachedUsers({ refresh: true });
  const [offboardingModalOpen, setOffboardingModalOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = useState<'absence' | 'document' | 'task' | null>(null);
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [documentFormMode, setDocumentFormMode] = useState<DocumentAction>('add');
  const [isOnboardNewUserOpen, setOnboardNewUserOpen] = useState(false);
  const [filterValue, setFilterValue] = useState<string>('');
  const { userOverviewConfig } = useUserProfileConfig(user.userId);
  const { hasScopes, getScopesContext } = useScopes();
  const routerHistory = useHistory();
  const {
    data: requestForms,
    mutate: refreshRequestForms,
  } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), { suspense: false });
  const [isPlaying, setIsPlaying] = useState(false);
  const lottieRef = useRef<any>(null);

  useEffect(() => {
    const currentLottie = lottieRef.current;
    if (isPlaying) {
      currentLottie?.play();
    } else {
      currentLottie?.stop();
    }
  }, [isPlaying]);

  useEffect(() => {
    lottieRef.current?.setSpeed(1.1);
  }, []);

  const [showMessage] = useMessage();

  const currentUserIsDocumentAdmin = useMemo(
    () => hasScopes(['documents:all', 'documents:manager'], getScopesContext(user)),
    [getScopesContext, hasScopes, user]
  );

  const refreshQuickActionState = useCallback(() => {
    refreshRequestForms?.();
  }, [refreshRequestForms]);

  const closeDrawer = useCallback(() => {
    setOpenDrawer(null);
  }, []);

  const handleUploadDocument = useCallback(() => {
    documentDispatch({ kind: 'add_document', owner: { label: userOverviewConfig.name, value: user.userId } });
    setDocumentFormMode('add');
    setFilterValue(
      getDocumentParentCategory(
        'me',
        'add',
        undefined,
        currentUserIsDocumentAdmin,
        documentState.types as ZeltDocumentType[]
      )
    );
    setOpenDrawer('document');
  }, [currentUserIsDocumentAdmin, documentState.types, user.userId, userOverviewConfig.name]);

  const completeSave = async () => {
    try {
      let documentObject: CreateZeltDocument = {
        name: documentState.name || '',
        type: documentState.type?.value ?? '',
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid,
        note: documentState.note,
        expirySettings: documentState?.expirySettings ?? { expiryDate: '', documentExpires: false },
      };
      if (documentState.id && documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t('QuickAction.successMessages.update'), 'success');
      } else if (documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.createDocument(documentObject);
        showMessage(polyglot.t('QuickAction.successMessages.save'), 'success');
      }
    } catch (error) {
      showMessage(
        polyglot.t('QuickAction.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const options = useMemo(() => {
    return [
      {
        label: polyglot.t('QuickAction.onboard'),
        icon: OnboardingIcon,
        handler: () => setOnboardNewUserOpen(true),
        disabled: false,
        hidden: !hasScopes(['user.onboard:all'], getScopesContext(user)),
      },
      {
        label: polyglot.t('QuickAction.offboard'),
        icon: OffboardingIcon,
        handler: () => setOffboardingModalOpen(true),
        disabled: false,
        hidden: !hasScopes(['user.onboard:all'], getScopesContext(user)),
      },
      {
        label: polyglot.t('QuickAction.absence'),
        icon: AbsenceIcon,
        handler: () => setOpenDrawer('absence'),
        disabled: false,
      },
      {
        label: polyglot.t('QuickAction.document'),
        icon: DocumentIcon,
        handler: () => handleUploadDocument(),
        disabled: false,
      },
      {
        label: polyglot.t('QuickAction.task'),
        icon: TaskIcon,
        handler: () => setOpenDrawer('task'),
        disabled: false,
      },
      // {
      //   label: polyglot.t('QuickAction.survey'),
      //   icon: SurveyIcon,
      //   handler: () => routerHistory.push(SURVEYS_COMPANY_ONGOING_NEW_SURVEYS_ROUTE),
      //   disabled: false,
      //   hidden: !hasScopes(['reviews:all'], getScopesContext(user)),
      // },
      // {
      //   label: polyglot.t('QuickAction.review'),
      //   icon: ReviewIcon,
      //   handler: () => routerHistory.push(REVIEWS_COMPANY_ONGOING_NEW_CYCLE_ROUTE),
      //   disabled: false,
      //   hidden: !hasScopes(['reviews:all'], getScopesContext(user)),
      // },
      {
        label: polyglot.t('QuickAction.request'),
        icon: RequestIcon,
        handler: () => routerHistory.push(REQUESTS_CHOOSE_REQUEST_ROUTE),
        disabled: false,
        hidden: !requestForms?.length,
      },
      {
        label: polyglot.t('QuickAction.expense'),
        icon: Payment,
        handler: () => routerHistory.push(EXPENSE_ME_NEW_EXPENSE_ROUTE),
        disabled: false,
        hidden: !hasScopes(['expenses'], getScopesContext(user)),
      },
      {
        label: polyglot.t('QuickAction.invoice'),
        icon: Payment,
        handler: () => routerHistory.push(INVOICES_ME_NEW_INVOICE_ROUTE),
        disabled: false,
        hidden: !hasScopes(['invoices'], getScopesContext(user)),
      },
    ].filter((item) => !item.hidden);
  }, [polyglot, getScopesContext, handleUploadDocument, hasScopes, requestForms, routerHistory, user]);

  return (
    <>
      <Box
        sx={{ height: '60px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={(event) => {
          refreshQuickActionState();
          setAnchorEl(event.currentTarget);
          event.stopPropagation();
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            sizeVariant="circle"
            colorVariant="primary"
            onMouseEnter={() => setIsPlaying(true)}
            onMouseLeave={() => setIsPlaying(false)}
          >
            <Lottie
              lottieRef={lottieRef}
              animationData={NewAction}
              loop={true}
              autoplay={false}
              style={{
                display: 'flex',
                height: '20px',
                width: '20px',
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <StyledMenu
        id={`btnMenuOptions`}
        MenuListProps={{
          'aria-labelledby': 'btnMenuOptions',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        open={open}
        onClose={async () => {
          setAnchorEl(null);
        }}
      >
        {
          <MenuItem disableRipple sx={{ cursor: 'default', mb: '15px' }}>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              {polyglot.t('QuickAction.quickActions')}
            </Typography>
          </MenuItem>
        }
        {options.map(({ label, handler, disabled, icon: Icon }, i) => (
          <MenuItem
            key={label}
            id={`btn${label}_${i}`}
            onClick={(e) => {
              handler();
              setAnchorEl(null);
              e.stopPropagation();
            }}
            disableRipple
            disabled={disabled}
            sx={{ marginTop: '10px' }}
          >
            <Icon {...iconSize} />
            {label}
          </MenuItem>
        ))}
      </StyledMenu>

      <AbsenceDrawer
        isOpen={openDrawer === 'absence'}
        onClose={closeDrawer}
        userId={user.userId}
        setIsAbsenceDrawerOpen={(value) => setOpenDrawer(value ? 'absence' : null)}
      />

      <DocumentFormModal
        mode={documentFormMode}
        state={documentState}
        currentUserIsAdmin={currentUserIsDocumentAdmin}
        dispatch={documentDispatch}
        newDocumentTypes={documentState.types as ZeltDocumentType[]}
        onClose={closeDrawer}
        onSubmit={completeSave}
        setOpen={(value) => setOpenDrawer(value ? 'document' : null)}
        open={openDrawer === 'document'}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      />

      <TaskFormModal
        isOpen={openDrawer === 'task'}
        setIsOpen={(value) => setOpenDrawer(value ? 'task' : null)}
        onClose={closeDrawer}
        userId={user.userId}
        formData={null}
      />

      <OnboardNewEmployee
        isOpen={isOnboardNewUserOpen}
        setIsOpen={setOnboardNewUserOpen}
        onClose={async () => {
          await refreshCachedUsers();
        }}
        initialValues={undefined}
        onDraftUserCreated={() => setOnboardNewUserOpen(false)}
        onUserCreated={() => setOnboardNewUserOpen(false)}
      />

      <ScopesControl scopes={['user.onboard:all']}>
        <OffboardingDrawer userId={undefined} isOpen={offboardingModalOpen} setIsOpen={setOffboardingModalOpen} />
      </ScopesControl>
    </>
  );
};
