import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { AppStatusTabFilter } from '@v2/feature/app-integration/app-router.util';
import { AppGroupManagementDrawer } from '@v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { useHistory, useLocation } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import cover from '@/images/app-empty.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { getIconForAppStatus } from '@/v2/feature/app-integration/app-integration-detailed-personal.router';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  AppIntegrationUserEmailDto,
  GroupMembership,
  InstalledAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APP_ACTION_DRAWER_MODES,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationStub,
  AppIntegrationUserStatusEnum,
  AppsWithLogins,
} from '@/v2/feature/app-integration/app-integration.interface';
import {
  getScheduledActionDateString,
  hasScheduledAction,
  userHasDelayedAppAction,
} from '@/v2/feature/app-integration/app-integration.util';
import { getGroupListForUser } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { AppAccessDetailDrawer } from '@/v2/feature/app-integration/features/app-details/components/app-access-detail-drawer.component';
import { AppDetailConfigurationModal } from '@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component';
import { AppDetailsActionButton } from '@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component';
import { AppDetailsTable } from '@/v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { AppActionsDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section';
import { AppRequestAPI, AppRequestEndpoints } from '@/v2/feature/app-integration/features/app-request/app-request.api';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { primarySmallBtn, secondarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useLocalStorage } from '@/v2/util/use-local-storage.util';

interface AppTeamAccessProps {
  readonly teamAccessUserList: readonly AppIntegrationUserDto[];
  readonly externalUserList: readonly AppIntegrationUserDto[];
  readonly groupMemberships?: readonly GroupMembership[];
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
  readonly loading: boolean;
  readonly error: boolean;
  readonly onAppChange: (_: string) => void;
  readonly setApp?: React.Dispatch<React.SetStateAction<InstalledAppDto | undefined>>;
  readonly hasAppsAllOrAppOwner: boolean;
}

export const AppTeamAccessPage = ({
  teamAccessUserList,
  externalUserList,
  groupMemberships,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  setApp,
  hasAppsAllOrAppOwner,
}: AppTeamAccessProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const routerLocation = useLocation<{ needToRefreshDirectory: true; app: InstalledAppDto }>();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('all');
  const [filteredTeamAccessList, setFilteredTeamAccessList] = useState<readonly AppIntegrationUserDto[]>(
    teamAccessUserList
  );
  const [preselectedUser, setPreselectedUser] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [pendingTeamAppList, setPendingTeamAppList] = useLocalStorage<number[]>('pendingTeamAppList', []);
  const [actionsDrawerMode, setActionsDrawerMode] = useState<APP_ACTION_DRAWER_MODES | undefined>(undefined);

  const [openAccessDetailModal, setOpenAccessDetailModal] = useState<boolean>(false);
  const [userForAccessDetail, setUserForAccessDetail] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [scheduledActionDate, setScheduledActionDate] = useState<string | undefined>(undefined);

  const [paginationReset, setPaginationReset] = useState<boolean>(false);

  // const pagination = useMemo(() => {
  //   if (filterString?.length === 0 || filterString === 'all') {
  //     return {
  //       pageIndex: pageIndex ?? 0,
  //       pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
  //     };
  //   } else
  //     return {
  //       pageIndex: 0,
  //       pageSize: DEFAULT_PAGE_SIZE,
  //     };
  // }, [filterString, pageIndex, pageSize]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState<APP_GROUP_MANAGEMENT_DRAWER_MODES | undefined>(undefined);

  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false,
    }
  );

  const { data: pendingRequests, mutate: refreshAppRequests } = useApiClient(
    AppRequestEndpoints.getAppRequestsForCompany('Pending'),
    {
      suspense: false,
    }
  );

  const checkForAnyMatchingPendingRequests = useCallback(async () => {
    for (const eachUser of teamAccessUserList) {
      const matchingPendingRequest = pendingRequests?.find(
        (eachRequest) =>
          eachRequest.requestedFor === eachUser.userId &&
          eachRequest.requestInfo.appStub === appStub &&
          eachUser.userStatus === 'Active'
      );
      if (matchingPendingRequest && matchingPendingRequest.id) {
        await AppRequestAPI.completePendingAppRequest(matchingPendingRequest.id);
        refreshAppRequests!();
        showMessage('Found user matching pending app acccess request; marked request complete!', 'success');
      }
    }
  }, [teamAccessUserList, pendingRequests, appStub, refreshAppRequests, showMessage]);

  useEffect(() => {
    checkForAnyMatchingPendingRequests();
  }, [teamAccessUserList, pendingRequests, checkForAnyMatchingPendingRequests]);

  useEffect(() => {
    if (routerLocation.state && routerLocation.state.app && routerLocation.state.needToRefreshDirectory) {
      const stubToRefresh = app?.stub;
      routerHistory.replace({});
      // if state has updated app instance - update global instance of app
      if (routerLocation.state.app && setApp) setApp(routerLocation.state.app);
      if (stubToRefresh) onAppChange(stubToRefresh);
    }
  }, [app, app?.stub, onAppChange, routerHistory, routerLocation.state, setApp]);

  useEffect(() => {
    setFilteredTeamAccessList(
      filterString === 'all'
        ? teamAccessUserList
        : teamAccessUserList.filter(
            (eachUser) =>
              eachUser.userStatus === filterString ||
              (eachUser.emails && eachUser.emails.some((eachEmail) => eachEmail.status === filterString))
          )
    );
  }, [teamAccessUserList, filterString]);

  useEffect(() => {
    setFilteredTeamAccessList(
      searchInput.length === 0
        ? teamAccessUserList
        : teamAccessUserList.filter(
            (eachUser) =>
              eachUser.displayName && eachUser.displayName?.toLowerCase().includes(searchInput.toLowerCase())
          )
    );
  }, [teamAccessUserList, searchInput]);

  const markTeamUserPending = useCallback(
    (userId: number) => {
      setPendingTeamAppList((prevList: number[]) => [...prevList, userId]);
    },
    [setPendingTeamAppList]
  );

  const userInPendingList = useCallback(
    (userId: number): boolean => {
      return pendingTeamAppList.length > 0 && pendingTeamAppList.includes(userId);
    },
    [pendingTeamAppList]
  );

  const statusColumn = useCallback(
    (polyglot: Polyglot, original: AppIntegrationUserDto) => {
      if (AppsWithLogins.includes(appStub) && original && original.emails && original?.emails?.length > 0) {
        return original?.emails?.map((emailObj: AppIntegrationUserEmailDto) => (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              key={`${original.userId}-status-${emailObj.email}`}
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.userId &&
                getIconForAppStatus(polyglot, userInPendingList(+original.userId) ? 'Pending' : emailObj.status)}
            </Typography>
          </Box>
        ));
      } else if (
        delayedActions &&
        original?.userId &&
        userHasDelayedAppAction(delayedActions, Number(original.userId), appStub)
      ) {
        // Delayed actions block
        return (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography
                key={`${original.userId}-status`}
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original.userId && getIconForAppStatus(polyglot, 'Scheduled')}
              </Typography>
            </Box>
          </Box>
        );
      } else if (
        delayedActions &&
        original?.userId &&
        !userHasDelayedAppAction(delayedActions, Number(original.userId), appStub) &&
        original?.emails &&
        original.emails.length > 0
      ) {
        return original?.emails?.map((emailObj: AppIntegrationUserEmailDto) => (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              key={`${original.userId}-status`}
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.userId &&
                getIconForAppStatus(polyglot, userInPendingList(+original.userId) ? 'Pending' : emailObj.status)}
            </Typography>
          </Box>
        ));
      } else {
        // Fallback status
        return (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              key={`${original.userId}-status`}
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.userId &&
                getIconForAppStatus(polyglot, userInPendingList(+original.userId) ? 'Pending' : original.userStatus)}
            </Typography>
          </Box>
        );
      }
    },
    [appStub, delayedActions, userInPendingList]
  );

  const teamAccessColumns = useMemo<ColumnDef<AppIntegrationUserDto, AppIntegrationUserDto>[]>(() => {
    return [
      {
        header: () => polyglot.t('AppTeamAccessPage.columns.name'),
        id: 'displayName',
        maxSize: 200,
        minSize: 140,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const user = original && original.userId ? getCachedUserById(+original.userId) : undefined;
          return user ? (
            <Typography
              key={`${original.userId}-name`}
              sx={{
                ...themeFonts.caption,
                color: themeColors.DarkGrey,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              <UserAvatar userId={user.userId} size="xxsmall" />
              <div>{polyglot.t(user.displayName ?? '') || 'N/A'}</div>
            </Typography>
          ) : (
            <></>
          );
        },
      },
      {
        header: () => polyglot.t('AppAccessDetailsDrawer.login'),
        id: 'primaryEmail',
        maxSize: 200,
        minSize: 140,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original?.emails ? (
              original?.emails?.map((emailObj: AppIntegrationUserEmailDto) => (
                <Typography
                  key={`${original.userId}-email`}
                  sx={{
                    ...themeFonts.caption,
                    color: themeColors.DarkGrey,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m15,
                    mb: '15px',
                    mt: '15px',
                  }}
                >
                  {emailObj.email}
                </Typography>
              ))
            ) : (
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                  mb: '15px',
                }}
              >
                {original.primaryEmail}
              </Typography>
            )}
          </Box>
        ),
      },
      ...(app?.allowsGroupManagement
        ? [
            {
              header: () => 'Groups',
              id: 'groupMemberships',
              size: 160,
              accessorFn: (row: any) => row,
              enableSorting: false,
              hide: appStub === 'github',
              cell: ({ row: { original } }: { row: { original: AppIntegrationUserDto } }) => (
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    color: themeColors.DarkGrey,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                  }}
                >
                  {groupMemberships && original.primaryEmail
                    ? getGroupListForUser(original.primaryEmail, groupMemberships)
                    : ''}
                </Typography>
              ),
            },
          ]
        : []),
      {
        header: () => polyglot.t('AppTeamAccessPage.columns.status'),
        id: 'userStatus',
        maxSize: 160,
        minSize: 110,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => statusColumn(polyglot, original),
      },
      {
        header: () => '',
        id: 'action',
        maxSize: 80,
        minSize: 80,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) =>
          original.userId && userInPendingList(+original.userId) ? (
            <></>
          ) : (
            <AppDetailsActionButton
              setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
              setGroupManagementDrawerOpen={setIsDrawerOpen}
              setGroupManagementDrawerMode={setDrawerMode}
              markTeamUserPending={markTeamUserPending}
              refresh={onAppChange}
              appStub={appStub}
              user={original}
              delayedActions={delayedActions ?? { delayed: [] }}
              refreshDelayedActions={refreshDelayedActions}
              table="team"
              setPreselectedUser={setPreselectedUser}
              setActionsDrawerMode={setActionsDrawerMode}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
              error={error}
            />
          ),
      },
    ];
  }, [
    polyglot,
    app?.allowsGroupManagement,
    appStub,
    getCachedUserById,
    groupMemberships,
    statusColumn,
    userInPendingList,
    markTeamUserPending,
    onAppChange,
    delayedActions,
    refreshDelayedActions,
    hasAppsAllOrAppOwner,
    error,
  ]);

  const activeTeamMembers = (teamList: readonly AppIntegrationUserDto[]): AppIntegrationUserDto[] => {
    return teamList?.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) =>
          [AppIntegrationUserStatusEnum.Active, AppIntegrationUserStatusEnum.Invited].includes(
            eachEmail.status as AppIntegrationUserStatusEnum
          )
        ) || eachUser.userStatus === AppIntegrationUserStatusEnum.Active
    );
  };

  const teamMembersWithNoAccess = (teamList: readonly AppIntegrationUserDto[]): AppIntegrationUserDto[] => {
    return teamList.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) =>
          [AppIntegrationUserStatusEnum.NoAccess].includes(eachEmail.status as AppIntegrationUserStatusEnum)
        ) || eachUser.userStatus === AppIntegrationUserStatusEnum.NoAccess
    );
  };

  const addUsersToGroup = () => {
    setIsDrawerOpen(true);
    setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.add);
  };

  const handleRowClicked = useCallback(
    (row: Row<AppIntegrationUserDto>) => {
      setOpenAccessDetailModal(true);
      setUserForAccessDetail(row.original);
      const matchingAction =
        row.original?.userId && !Number.isNaN(Number(row.original?.userId))
          ? hasScheduledAction(delayedActions, Number(row.original?.userId))
          : undefined;
      setScheduledActionDate(matchingAction ? getScheduledActionDateString(matchingAction) : undefined);
    },
    [delayedActions]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<AppNameHeader title={polyglot.t('AppTeamAccessPage.title')} app={app} />}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => addUsersToGroup()}>
            New account
          </ButtonComponent>
        }
        showAction={hasAppsAllOrAppOwner && app?.allowsGroupManagement}
      />
      <ContentWrapper loading={loading ?? false}>
        <Box>
          {appStub && appStub === 'github' && error && app?.authorised && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                mt: spacing.mt40,
              }}
            >
              Please add your organisation name to finish integration setup
              <Button sx={{ ...primarySmallBtn, ...spacing.mt40 }} onClick={() => setShowModal(true)}>
                Add organisation
              </Button>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'left',
            }}
          >
            {!error && (
              <>
                <TabFilterButtons
                  filters={AppStatusTabFilter(polyglot)}
                  setFilterValue={setFilterString}
                  filterValue={filterString}
                  hasSearch
                  onFilterChange={({ filterValue, searchInput }) => {
                    setFilterString(filterValue);
                    if (searchInput) {
                      setSearchInput(searchInput);
                      setPaginationReset(true);
                    }
                  }}
                  handleBlur={() => setPaginationReset(false)}
                />
                {hasAppsAllOrAppOwner && (
                  <Button
                    sx={secondarySmallBtn}
                    startIcon={<Reload {...iconSize} />}
                    onClick={() => onAppChange(appStub)}
                  >
                    {polyglot.t('AppTeamAccessPage.actions.refresh')}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          {!error && (
            <AppDetailsTable
              column={teamAccessColumns}
              row={filteredTeamAccessList ?? []}
              loading={loading ?? false}
              paginationReset={paginationReset}
              handleRowClicked={handleRowClicked}
            />
          )}
          {error && !app?.authorised && (
            <EmptyStateComponent
              header="Authorize this app"
              subheader="Zelt allows you to manage your whole toolstack from one place."
              detail="To start using your app connect your account in Settings first."
              cover={cover}
            />
          )}
        </Box>
        <AppDetailConfigurationModal
          key={appStub}
          open={showModal}
          handleClose={() => setShowModal(false)}
          app={app}
          appStub={appStub}
          refresh={() => onAppChange(appStub)}
        />
        <AppActionsDrawer
          appStub={appStub}
          usersWithoutAccess={teamAccessUserList}
          usersWithAccess={activeTeamMembers(teamAccessUserList)}
          isOpen={isAppActionsDrawerOpen}
          onClose={() => setIsAppActionsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          refreshDelayedActions={refreshDelayedActions}
          markTeamUserPending={markTeamUserPending}
          setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
          preselectedUser={preselectedUser}
          mode={actionsDrawerMode}
          directoryMode={'team'}
        />
        <AppGroupManagementDrawer
          appStub={appStub}
          selectedGroupMembership={undefined}
          externalUserList={externalUserList}
          usersWithoutAccess={teamMembersWithNoAccess(teamAccessUserList)}
          usersWithAccess={activeTeamMembers(teamAccessUserList)}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          setIsDrawerOpen={setIsDrawerOpen}
          mode={drawerMode}
          existingUser={preselectedUser}
          groupMembers={{}}
          groupList={groupMemberships}
        />
        {userForAccessDetail && app?.name && (
          <AppAccessDetailDrawer
            isOpen={openAccessDetailModal}
            setIsOpen={setOpenAccessDetailModal}
            appUserDetail={userForAccessDetail}
            appName={app?.name}
            appStatusValue={statusColumn(polyglot, userForAccessDetail)}
            scheduledActionDate={scheduledActionDate}
            appActionButtons={
              <AppDetailsActionButton
                setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
                setGroupManagementDrawerOpen={setIsDrawerOpen}
                setGroupManagementDrawerMode={setDrawerMode}
                markTeamUserPending={markTeamUserPending}
                refresh={onAppChange}
                appStub={appStub}
                user={userForAccessDetail}
                delayedActions={delayedActions ?? { delayed: [] }}
                refreshDelayedActions={refreshDelayedActions}
                table="team"
                setPreselectedUser={setPreselectedUser}
                setActionsDrawerMode={setActionsDrawerMode}
                hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
                error={error}
                appAccessDetailsDrawerMode={true}
              />
            }
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
