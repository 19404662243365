import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import useScopes from '@/hooks/scopes.hook';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PensionMe } from '@/v2/feature/benefits/subfeature/pension/features/pension-me/components/pension-me.components';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { useState } from 'react';

import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { BENEFITS_ME_ROUTE } from '@/lib/routes';

interface Props {
  readonly userId: number;
  readonly employeePension: UserPensionDto | null;
  readonly onEmployeeUpdate: () => Promise<void>;
  readonly loading: boolean;
}

export const UserPensionPage = ({ userId, employeePension, onEmployeeUpdate, loading }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const [isEnrolDrawerOpen, setIsEnrolDrawerOpen] = useState<boolean>(false);

  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionScope = hasScopes(['pension'], scopesContext);

  return (
    <>
      <TopHeader
        title={
          routerLocation.pathname.includes('/settings') ? (
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {polyglot.t('UserPensionPage.settings')}
            </Typography>
          ) : (
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {polyglot.t('UserPensionPage.pension')}
            </Typography>
          )
        }
        showBack
        backPath={BENEFITS_ME_ROUTE}
        settingsAction={() => setIsEnrolDrawerOpen(true)}
        settingsHide={!hasPensionScope}
      />
      <PensionMe
        userId={userId}
        employeePension={employeePension}
        onEmployeeUpdate={onEmployeeUpdate}
        isEnrolDrawerOpen={isEnrolDrawerOpen}
        setIsEnrolDrawerOpen={setIsEnrolDrawerOpen}
        loading={loading}
      />
    </>
  );
};
