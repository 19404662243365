import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import cover from '@/images/app-empty.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { nestErrorMessage } from '@/lib/errors';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  Dictionary,
  GoogleGroupMember,
  GroupMember,
  GroupMembership,
  InstalledAppDto,
  JiraGroupMember,
  Microsoft365GroupMember,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationStub,
} from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailConfigurationModal } from '@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component';
import { AppDetailsTable } from '@/v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { AppGroupManagementDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section';
import { AppRequestAPI, AppRequestEndpoints } from '@/v2/feature/app-integration/features/app-request/app-request.api';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { primarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { useLocalStorage } from '@/v2/util/use-local-storage.util';

interface AppGroupManagementProps {
  readonly teamAccessUserList: readonly AppIntegrationUserDto[];
  readonly externalUserList: readonly AppIntegrationUserDto[];
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
  readonly error: boolean;
  readonly onAppChange: (_: string) => void;
  readonly hasAppsAllOrAppOwner: boolean;
}

export const AppGroupManagementPage = ({
  teamAccessUserList,
  externalUserList,
  appStub,
  app,
  error,
  onAppChange,
  hasAppsAllOrAppOwner,
}: AppGroupManagementProps) => {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation<{ needToRefreshDirectory: true; app: { stub: string } }>();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [groupForEditing, setGroupForEditing] = useState<GroupMembership | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [groupsTabInError, setGroupsInTabError] = useState(false);

  const [drawerMode, setDrawerMode] = useState<APP_GROUP_MANAGEMENT_DRAWER_MODES | undefined>(undefined);
  const [groupMembers, setGroupMembers] = useState<Record<string, GroupMember> | undefined>(undefined);
  const [groupMemberships, setGroupMemberships] = useLocalStorage<GroupMembership[]>(
    `${appStub}-appGroupMemberships-${currentUser?.company?.companyId}`,
    []
  );

  const { data: pendingRequests, mutate: refreshAppRequests } = useApiClient(
    AppRequestEndpoints.getAppRequestsForCompany('Pending'),
    {
      suspense: false,
    }
  );

  const checkForAnyMatchingPendingRequests = useCallback(async () => {
    for (const eachUser of teamAccessUserList) {
      const matchingPendingRequest = pendingRequests?.find(
        (eachRequest) =>
          eachRequest.requestedFor === eachUser.userId &&
          eachRequest.requestInfo.appStub === appStub &&
          eachUser.userStatus === 'Active'
      );
      if (matchingPendingRequest && matchingPendingRequest.id) {
        await AppRequestAPI.completePendingAppRequest(matchingPendingRequest.id);
        refreshAppRequests!();
        showMessage('Found user matching pending app acccess request; marked request complete!', 'success');
      }
    }
  }, [teamAccessUserList, pendingRequests, appStub, refreshAppRequests, showMessage]);

  useEffect(() => {
    checkForAnyMatchingPendingRequests();
  }, [teamAccessUserList, pendingRequests, checkForAnyMatchingPendingRequests]);

  useEffect(() => {
    if (routerLocation.state && routerLocation.state.app && routerLocation.state.needToRefreshDirectory) {
      const stubToRefresh = app?.stub;
      routerHistory.replace({});
      if (stubToRefresh) onAppChange(stubToRefresh);
    }
  }, [app?.stub, onAppChange, routerHistory, routerLocation.state]);

  const filteredGroupMembers = useCallback(
    (groupDetails: Dictionary<GroupMember>): GroupMember[] => {
      if (appStub === 'google-workspace')
        return Object.values(groupDetails).filter((eachMember) => (eachMember as GoogleGroupMember).email);
      if (appStub === 'jira')
        return Object.values(groupDetails).filter(
          (eachMember) => (eachMember as JiraGroupMember).accountType === 'atlassian'
        );
      if (appStub === 'microsoft365')
        return Object.values(groupDetails).filter((eachMember) => eachMember as Microsoft365GroupMember);
      return [];
    },
    [appStub]
  );

  const groupManagementColumns = useMemo<ColumnDef<GroupMembership, GroupMembership>[]>(
    () => [
      {
        header: () => 'Group',
        id: 'name',
        size: 300,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {`${original?.name}` || 'N/A'}
            </Typography>
          );
        },
      },
      {
        header: () => 'Email',
        id: 'email',
        size: 232,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box sx={{ mt: '15px', mb: '15px' }}>
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
                mt: '5px',
                mb: '5px',
              }}
            >
              {original?.email ?? ''}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => 'Members',
        id: 'directMemberCount',
        size: 160,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original?.members ? filteredGroupMembers(original.members).length : 0}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => '',
        id: 'action',
        size: 50,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {hasAppsAllOrAppOwner && (
              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setIsDrawerOpen(true);
                  setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.edit);
                  setGroupMembers(original.members);
                  setGroupForEditing(original);
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            )}
          </div>
        ),
      },
    ],
    [filteredGroupMembers, hasAppsAllOrAppOwner]
  );

  const activeTeamMembers = (teamList: readonly AppIntegrationUserDto[]): AppIntegrationUserDto[] => {
    return teamList?.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) => ['Active', 'Invited'].includes(eachEmail.status)) ||
        eachUser.userStatus === 'Active'
    );
  };

  const teamMembersWithNoAccess = (teamList: readonly AppIntegrationUserDto[]): AppIntegrationUserDto[] => {
    return teamList.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) => ['No acess'].includes(eachEmail.status)) ||
        eachUser.userStatus === 'No access'
    );
  };

  const addUsersToGroup = () => {
    setIsDrawerOpen(true);
    setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.add);
  };

  const fetchData = useCallback(
    async (loading = true) => {
      if (app?.authorised && app?.allowsGroupManagement) {
        try {
          if (loading) setLoading(true);
          const groupData = await AppIntegrationAPI.getGroupMemberships(appStub);
          setGroupMemberships(groupData);
          setGroupsInTabError(false);
        } catch (error) {
          setGroupsInTabError(true);
          showMessage(`Failed to fetch group data: ${nestErrorMessage(error)}`, 'error');
        } finally {
          if (loading) setLoading(false);
        }
      }
    },
    [app?.allowsGroupManagement, app?.authorised, appStub, setGroupMemberships, showMessage]
  );

  useEffect(() => {
    // if no cached data exists in localStorage, fetch new data with loader visible, otherwise silently refresh data
    fetchData(!groupMemberships || groupMemberships.length === 0 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.allowsGroupManagement, app?.authorised, appStub]);

  return (
    <RootStyle>
      <TopHeader
        title={<AppNameHeader title={polyglot.t('AppGroupManagementPage.title')} app={app} />}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => addUsersToGroup()}>
            New account
          </ButtonComponent>
        }
        showAction={hasAppsAllOrAppOwner && app?.allowsGroupManagement}
      />
      <ContentWrapper loading={loading ?? false}>
        {appStub && appStub === 'github' && error && !app?.configuration && app?.authorised && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              mt: spacing.mt40,
            }}
          >
            Please add your organisation name to finish integration setup
            <Button sx={{ ...primarySmallBtn, ...spacing.mt40 }} onClick={() => setShowModal(true)}>
              Add organisation
            </Button>
          </Box>
        )}
        <Box>
          {!error && !groupsTabInError && (
            <AppDetailsTable
              column={groupManagementColumns}
              row={groupMemberships ?? []}
              loading={loading ?? false}
              hidePagination
            />
          )}
          {error && !app?.authorised && (
            <EmptyStateComponent
              header="Authorize this app"
              subheader="Zelt allows you to manage your whole toolstack from one place."
              detail="To start using your app connect your account in Settings first."
              cover={cover}
            />
          )}
          {groupsTabInError && app?.authorised && (
            <EmptyStateComponent
              header="Re-authorize this app"
              subheader="Zelt now allows you to manage your Google Groups"
              detail="Please reconnect Zelt to your Google Account, as additional permissions are needed in order to manage Google Groups from Zelt"
              cover={cover}
            />
          )}
        </Box>
        <AppDetailConfigurationModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          app={app}
          appStub={appStub}
          refresh={() => onAppChange(appStub)}
        />
        <AppGroupManagementDrawer
          appStub={appStub}
          selectedGroupMembership={groupForEditing}
          externalUserList={externalUserList}
          usersWithoutAccess={teamMembersWithNoAccess(teamAccessUserList)}
          usersWithAccess={activeTeamMembers(teamAccessUserList)}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          setIsDrawerOpen={setIsDrawerOpen}
          mode={drawerMode}
          groupMembers={groupMembers}
          groupList={groupMemberships}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
