import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { UserRoleAPI } from '@/api-client/user-role.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';

export interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userId: number;
  readonly refresh: () => Promise<void>;
}

export const OffboardingManagerChangeDrawer = ({ isOpen, setIsOpen, userId, refresh }: DrawerProps) => {
  const history = useHistory();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);

  const saveNewManager = useCallback(
    async (values: { newManagerId: number | null }) => {
      if (!values.newManagerId) return;
      try {
        setIsLoading(true);
        await UserRoleAPI.setNewManagerOnOnboardingCurrentUser(userId, Number(values.newManagerId));

        await refresh();
        setIsLoading(false);
        history.push(generatePath(USER_OFFBOARDING_ROUTE, { userId }));
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    },
    [refresh, history, showMessage, polyglot, userId]
  );

  const formik = useFormik<{ newManagerId: number | null }>({
    initialValues: {
      newManagerId: null,
    },
    validationSchema: yup.object({
      newManagerId: yup
        .number()
        .integer(polyglot.t('ValidationMessages.validValue'))
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: saveNewManager,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      afterClose={() => {
        setIsLoading(false);
        formik.setFieldValue('newManagerId', null);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('OffboardingPage.selectNewManager')}</Typography>

          <Typography variant="caption">{polyglot.t('OffboardingPage.selectNewManagerDescription')}</Typography>

          <SingleUserSelect
            name="newManagerId"
            options="company"
            onChange={(_, x) => formik.setFieldValue('newManagerId', x?.value ? Number(x.value) : null)}
            value={formik.values.newManagerId}
            label={polyglot.t('OffboardingPage.selectNewManager')}
            error={formik.touched.newManagerId && Boolean(formik.errors.newManagerId)}
            helperText={formik.touched.newManagerId && (formik.errors.newManagerId as string)}
          />

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name={polyglot.t('General.save')}
              sizeVariant="medium"
              colorVariant="primary"
              fullWidth
              loading={isLoading}
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
