import { useCallback, useMemo } from 'react';

import { AccountStatus } from '@/lib/users';
import { UserOverviewConfig } from '@/models';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

const defaultConfig: UserOverviewConfig & { default: boolean } = {
  name: '',
  emailAddress: '',
  startDate: '',
  leaveDate: null,
  accountStatus: AccountStatus.Created,
  contractType: '',
  inPayroll: false,
  inGlobalPayroll: false,
  inGlobalPayrollPast: false,
  inPension: false,
  inInsurance: false,
  inCustomBenefits: false,
  companyInPayroll: false,
  companyInPension: false,
  default: true,
  currency: null,
};

export const useUserProfileConfig = (
  userId: number
): {
  userOverviewConfig: UserOverviewConfig & { default: boolean };
  refreshUserOverviewConfig: () => Promise<void>;
  loading: boolean;
} => {
  const { getCachedUserById } = useCachedUsers();
  const { data, mutate, isValidating } = useApiClient(UserEndpoints.getUserOverviewConfig(userId), { suspense: false });

  const defaultOverviewConfig = useMemo((): UserOverviewConfig & { default: boolean } => {
    const userData = getCachedUserById(userId);
    if (userData)
      return {
        startDate: userData.startDate,
        accountStatus: userData.accountStatus,
        emailAddress: userData.emailAddress,
        name: `${userData.firstName} ${userData.lastName}`,
        inPayroll: false,
        inGlobalPayroll: false,
        inGlobalPayrollPast: false,
        inPension: false,
        inInsurance: false,
        inCustomBenefits: false,
        companyInPayroll: false,
        companyInPension: false,
        default: false,
        currency: userData.userContract?.entity?.currency ?? null,
      } as UserOverviewConfig & { default: boolean };

    return defaultConfig;
  }, [getCachedUserById, userId]);

  const refreshUserOverviewConfig = useCallback(async () => {
    if (mutate) await mutate();
  }, [mutate]);

  return {
    userOverviewConfig: data ? { ...data, default: false } : defaultOverviewConfig,
    refreshUserOverviewConfig,
    loading: isValidating,
  };
};
