import React, { useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { keyBy } from 'lodash';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { JobLevelEndpoints } from '@/v2/feature/job-level/job-level.api';
import { JobPositionAddEditFormDrawer } from '@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { tableSecondaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface JobPositionSettingTableProps {
  jobPositions: JobPosition[];
  refreshJobPositions: () => Promise<void>;
  loading: boolean;
}

export const JobPositionSettingTable = ({
  jobPositions,
  refreshJobPositions,
  loading,
}: JobPositionSettingTableProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const { data: jobLevels } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });
  const [tableData, setTableData] = useState<JobPositionForTable[]>([]);
  const [notificationToEdit, setJobPositionToEdit] = useState<JobPositionForTable | undefined>(undefined);
  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState<string>('');

  const jobsLookup = useMemo(() => {
    const groupMap = new Map();
    jobLevels?.forEach((item) => {
      if (!groupMap.has(item.trackName)) {
        groupMap.set(item.trackName, { counter: 1, options: [] });
      }
      const group = groupMap.get(item.trackName);
      group.options.push({
        value: item.levelId,
        label: `${item.trackName
          .split(' ')
          .map((word) => word[0])
          .join('')}
       ${group.counter}`,
      });
      group.counter += 1;
    });
    return keyBy(
      Array.from(groupMap.values()).flatMap((group) => group.options),
      'value'
    );
  }, [jobLevels]);

  const nextPossibleId = useMemo(() => {
    const lastJobPosition = jobPositions.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = lastJobPosition[jobPositions.length - 1]?.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      // if not a valid number that can be incremented - generate random number for internal code
      // can be overridden by user if they want to
      return Math.floor(Math.random() * 1000000).toString();
    }
  }, [jobPositions]);

  const DEFAULT_NEW_JOB_POSITION: JobPositionForTable = {
    id: undefined,
    internalCode: nextPossibleId,
    title: '',
    description: '',
    levelId: undefined,
  };

  useEffect(() => {
    const filteredData = [...jobPositions]
      .filter((n) => {
        const s = searchInput.toLowerCase();
        return (
          n.title?.toLowerCase().includes(s) ||
          n.description?.toLowerCase().includes(s) ||
          n.internalCode.toLowerCase().includes(s)
        );
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    // Set the combined data to the tableData state
    setTableData(filteredData);
  }, [searchInput, nonTerminatedCachedUsers, jobPositions]);

  const onEditClick = (jobPosition: JobPositionForTable) => {
    setJobPositionToEdit(jobPosition);
    setEditDrawerOpen(true);
  };

  const onNewJobPositionClick = () => {
    setJobPositionToEdit(DEFAULT_NEW_JOB_POSITION);
    setEditDrawerOpen(true);
  };

  const columnData = useMemo<ColumnDef<JobPositionForTable, JobPositionForTable>[]>(() => {
    return [
      {
        header: () => 'Internal code',
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        maxSize: 50,
        minSize: 50,
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return <div>{info.getValue().internalCode}</div>;
        },
      },
      {
        header: () => 'Level code',
        accessorFn: (row) => row,
        id: 'levelId',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.levelId),
        maxSize: 50,
        minSize: 50,
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return <div>{info.getValue().levelId ? jobsLookup[info.getValue().levelId as number]?.label : '-'}</div>;
        },
      },
      {
        header: () => 'Job title',
        accessorFn: (row) => row,
        id: 'title',
        maxSize: 100,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.title),
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return <>{info.getValue()?.title}</>;
        },
      },
      {
        header: () => 'Description',
        accessorFn: (row) => row,
        id: 'description',
        maxSize: 120,
        minSize: 120,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.description),
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return (
            <>{info.getValue()?.description ? truncateWithEllipses(info.getValue()?.description ?? '', 50) : ''}</>
          );
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        maxSize: 10,
        minSize: 10,
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return (
            <>
              <IconButton
                sx={tableSecondaryIconButtonSx}
                onClick={(e) => {
                  onEditClick(info.getValue());
                  e.stopPropagation();
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            </>
          );
        },
      },
    ];
  }, [jobsLookup]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ width: '250px', minWidth: '250px' }}
          />
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => onNewJobPositionClick()}>
            {polyglot.t('JobPositionSettingsPage.newPosition')}
          </ButtonComponent>
        </Box>
      </Box>
      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable rowData={[...tableData]} columnData={columnData} loading={loading} />
      </Box>
      {notificationToEdit && (
        <JobPositionAddEditFormDrawer
          isOpen={editDrawerOpen}
          setIsOpen={setEditDrawerOpen}
          jobPositionToEdit={notificationToEdit}
          onClose={() => {
            setEditDrawerOpen(false);
            setJobPositionToEdit(undefined);
          }}
          refreshJobPositions={refreshJobPositions}
        />
      )}
    </>
  );
};
