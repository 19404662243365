import React, { Suspense, useCallback, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  CompanyTranslationDto,
  EditableCompanyTranslationDto,
  SupportedTranslationLanguage,
} from '@v2/feature/company/company-translation/company-translation.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconButtonSx } from '@v2/styles/icon-button.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { iconSize } from '@v2/styles/table.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

interface TranslationDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly translation: CompanyTranslationDto | null;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const TranslationDrawer = ({ isOpen, setIsOpen, translation, refresh, onClose }: TranslationDrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <TranslationDrawerContent setIsOpen={setIsOpen} translation={translation} refresh={refresh} />
    </Suspense>
  </DrawerModal>
);

interface TranslationDrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly translation: CompanyTranslationDto | null;
  readonly refresh: () => Promise<void>;
}

const TranslationDrawerContent = ({ setIsOpen, translation, refresh }: TranslationDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const formik = useFormik<EditableCompanyTranslationDto>({
    initialValues: {
      en: translation?.en ?? '',
      it: translation?.it ?? '',
      ar: translation?.ar ?? '',
      de: translation?.de ?? '',
      fr: translation?.fr ?? '',
      es: translation?.es ?? '',
      pt: translation?.pt ?? '',
    },
    validationSchema: Yup.object({
      en: Yup.string().nullable().notRequired(),
      it: Yup.string().nullable().notRequired(),
      ar: Yup.string().nullable().notRequired(),
      de: Yup.string().nullable().notRequired(),
      fr: Yup.string().nullable().notRequired(),
      es: Yup.string().nullable().notRequired(),
      pt: Yup.string().nullable().notRequired(),
    }),
    onSubmit: async (values: EditableCompanyTranslationDto) => {
      const providedValues = Object.keys(values).filter((key) => !!values[key as SupportedTranslationLanguage]);
      if (providedValues.length < 2) {
        showMessage('At least 2 values should be provided.', 'error');
        return;
      }

      const data: EditableCompanyTranslationDto = {
        en: values.en ? values.en : null,
        it: values.it ? values.it : null,
        ar: values.ar ? values.ar : null,
        es: values.es ? values.es : null,
        pt: values.pt ? values.pt : null,
        de: values.de ? values.de : null,
        fr: values.fr ? values.fr : null,
      };

      try {
        setLoading(true);
        if (translation?.id) await CompanyAPI.updateCompanyTranslation(translation.id, data);
        else await CompanyAPI.createCompanyTranslation(data);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteTranslation = useCallback(async () => {
    if (!translation?.id) return;
    try {
      await CompanyAPI.deleteCompanyTranslation(translation.id);
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  }, [translation?.id, showMessage, setIsOpen, refresh]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('SettingsRouterPageConfig.translations')}</Typography>

        {translation?.id && (
          <IconButton title={polyglot.t('General.delete')} onClick={deleteTranslation} sx={iconButtonSx}>
            <Trash {...iconSize} />
          </IconButton>
        )}

        <TextfieldComponent
          name="en"
          label="EN"
          value={formik.values.en}
          onChange={formik.handleChange}
          error={formik.touched.en && !!formik.errors.en}
          helperText={(formik.touched.en && formik.errors.en) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="ar"
          label="AR"
          value={formik.values.ar}
          onChange={formik.handleChange}
          error={formik.touched.ar && !!formik.errors.ar}
          helperText={(formik.touched.ar && formik.errors.ar) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="de"
          label="DE"
          value={formik.values.de}
          onChange={formik.handleChange}
          error={formik.touched.de && !!formik.errors.de}
          helperText={(formik.touched.de && formik.errors.de) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="es"
          label="ES"
          value={formik.values.es}
          onChange={formik.handleChange}
          error={formik.touched.es && !!formik.errors.es}
          helperText={(formik.touched.es && formik.errors.es) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="fr"
          label="FR"
          value={formik.values.fr}
          onChange={formik.handleChange}
          error={formik.touched.fr && !!formik.errors.fr}
          helperText={(formik.touched.fr && formik.errors.fr) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="it"
          label="IT"
          value={formik.values.it}
          onChange={formik.handleChange}
          error={formik.touched.it && !!formik.errors.it}
          helperText={(formik.touched.it && formik.errors.it) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="pt"
          label="PT"
          value={formik.values.pt}
          onChange={formik.handleChange}
          error={formik.touched.pt && !!formik.errors.pt}
          helperText={(formik.touched.pt && formik.errors.pt) ?? ' '}
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            colorVariant="primary"
            sizeVariant="medium"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
