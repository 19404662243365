import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { spacing } from '@/v2/styles/spacing.styles';

export const JumpcloudApiKeyInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        a) Log into your jumpcloud.com account.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        b) Click on your avatar (picture icon) in the top right corner.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        c) Select the My API key option from the resulting menu (this requires you to have admin permissions).
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        d) If a token is already generated, clicking the same will copy it, if not, click the Generate a new API key
        button
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        e) Once copied paste the same token above.
      </Typography>
    </Box>
  );
};
