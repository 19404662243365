import { Box, Button, Typography } from '@mui/material';
import { primarySmallBtn } from '@v2/styles/buttons.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface DeviceActivationStepPackagingProps {
  readonly goToNextStep: () => void;
}

export const DeviceActivationStepPackaging = ({ goToNextStep }: DeviceActivationStepPackagingProps) => {
  return (
    <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', gap: spacing.gap20 }}>
        <Typography sx={themeFonts.title2}>Keep the original packaging</Typography>
        <Typography sx={themeFonts.caption}>
          Please keep the original packaging for your device. When it’s time to say Goodbye to your hardware - you will
          be asked to return it in the original box.
        </Typography>
        <Box>
          <Button sx={primarySmallBtn} onClick={goToNextStep}>
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
