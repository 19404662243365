import { Fragment, useContext, useMemo } from 'react';

import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import {
  SURVEYS_TEAM_ONGOING_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE,
} from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { GrowthForbiddenAccess } from '@/v2/feature/growth/shared/components/growth-forbidden-access.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SCUpsertGeneralPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/sc-upsert-general.page';
import { SCUpsertLaunchPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.page';
import { SurveyCycleCreationParticipantsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/sc-upsert-participants.page';
import { SCUpsertTimelinePage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/sc-upsert-timeline.page';
import { SurveyCycleCreationNotificationsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/survey-cycle-creation-notifications.page';
import { SurveyCycleCreationQuestionPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/survey-cycle-creation-question.page';
import { SurveyCycle, SURVEYS_MANAGER_SCOPE } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const getPageConfig = (
  cycleId: string,
  surveyCycle: SurveyCycle | null | undefined,
  polyglot: Polyglot
): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('CycleSettingsRouter.general'),
          stub: 'general',
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE, { cycleId }),
        },
        {
          title: 'Questions',
          stub: 'question',
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE, { cycleId }),
        },
        {
          title: 'Participants',
          stub: 'participants',
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE, { cycleId }),
        },
        {
          title: 'Timeline',
          stub: 'timeline',
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE, { cycleId }),
        },
        {
          title: 'Notifications',
          stub: 'notifications',
          path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE, { cycleId }),
        },
        ...(surveyCycle?.state === CycleState.Draft || surveyCycle?.state === CycleState.Scheduled
          ? [
              {
                title: 'Start survey',
                stub: 'launch',
                path: generatePath(SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE, { cycleId }),
              },
            ]
          : []),
      ],
    },
  ] as NavConfigItem[];
};

export const SurveyCycleCreationTeamRouter = () => {
  const { polyglot } = usePolyglot();
  const params = useParams<{ cycleId: string }>();
  const cycleId = params.cycleId;

  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['surveys:all']);
  const isManager = canAccessScopes(user, ['surveys:manager']);

  const { data: surveyCycle, mutate: refreshSurveyCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, ReachType.Team),
    {
      suspense: false,
    }
  );

  const refresh = async () => {
    await refreshSurveyCycle?.();
  };

  const settingPathVisibility = useMemo(
    () => surveyCycle && Boolean(isAdmin || (isManager && surveyCycle.owner === user.userId)),
    [surveyCycle, isManager, isAdmin, user]
  );

  return (
    <Fragment>
      <DomainSideMenuContent
        title={'Surveys'}
        subtitle={polyglot.t('OngoingReviewList.reviews')}
        pageConfig={getPageConfig(cycleId, surveyCycle, polyglot)}
        backPath={SURVEYS_TEAM_ONGOING_ROUTE}
        showBack
        type="Domain"
      />
      {settingPathVisibility ? (
        <Switch>
          <RouteScopesControl scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE}>
            <SCUpsertGeneralPage
              surveyCycle={surveyCycle}
              refresh={refresh}
              cycleLoading={cycleLoading}
              reach={ReachType.Team}
            />
          </RouteScopesControl>
          <RouteScopesControl scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE}>
            <SurveyCycleCreationQuestionPage cycleId={cycleId} />
          </RouteScopesControl>
          <RouteScopesControl scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE}>
            <SurveyCycleCreationParticipantsPage cycleId={cycleId} reach={ReachType.Team} />
          </RouteScopesControl>
          <RouteScopesControl scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE}>
            <SCUpsertTimelinePage cycleId={cycleId} reach={ReachType.Team} />
          </RouteScopesControl>
          <RouteScopesControl scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE}>
            <SurveyCycleCreationNotificationsPage surveyCycle={surveyCycle} refresh={refresh} />
          </RouteScopesControl>
          <RouteScopesControl scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE}>
            <SCUpsertLaunchPage cycleId={cycleId} reach={ReachType.Team} />
          </RouteScopesControl>
          <Redirect to={SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE} exact />
        </Switch>
      ) : (
        <div style={{ width: '100%', margin: spacing.s6 }}>
          <GrowthForbiddenAccess />
        </div>
      )}
    </Fragment>
  );
};
