import { Dispatch, SetStateAction, useContext } from 'react';

import { Box, IconButton, Skeleton, Typography } from '@mui/material';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Minus } from '@/images/side-bar-icons/Minus.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DeviceModelDto } from '@/v2/feature/device/device.dto';
import { getModelImage } from '@/v2/feature/device/device.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: 14, height: 14 } as const;

interface DevicePreviewProps {
  deviceModel: DeviceModelDto;
  cart: DeviceModelDto[];
  setCart: Dispatch<SetStateAction<DeviceModelDto[]>>;
}

export const DevicePreviewComponent = ({ deviceModel, cart, setCart }: DevicePreviewProps) => {
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: globalState.user.userId });
  const hasDevicesPriceScope = hasScopes(['devices.price:read', 'devices:all'], scopesContext);

  const {
    type,
    modelName,
    modelVersion,
    screenSize,
    storage,
    gpuCores,
    cpuCores,
    price36,
    deliveryDays,
    ram,
    fullPrice,
  } = deviceModel;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '200px',
        paddingX: spacing.p10,
        maxHeight: '450px',
        height: '100%',
      }}
    >
      <Box>
        <Box sx={{ height: 'auto', width: 'inherit' }}>
          {getModelImage(type, modelName, {
            width: 'auto',
            height: '120px',
          })}
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          <Typography sx={{ ...themeFonts.title4 }}>{modelName}</Typography>
          <Box sx={{ marginTop: spacing.m10 }}>
            {modelVersion && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
                Version: {modelVersion}
              </Typography>
            )}
            {screenSize && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
                Size: {screenSize}"
              </Typography>
            )}
            {storage && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
                Storage: {storage}GB
              </Typography>
            )}
            {ram && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
                RAM: {ram}GB
              </Typography>
            )}
            {gpuCores && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
                GPU: {gpuCores}-cores
              </Typography>
            )}
            {cpuCores && (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
                CPU: {cpuCores}-cores
              </Typography>
            )}
          </Box>

          {hasDevicesPriceScope && (
            <Box sx={{ marginTop: spacing.m5, display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, width: '55%' }}>
                Monthly Rent
              </Typography>
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, width: '45%' }}>
                from £{price36}
              </Typography>
            </Box>
          )}

          {hasDevicesPriceScope && fullPrice && (
            <Box sx={{ marginTop: spacing.m5, display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, width: '55%' }}>
                Purchase price
              </Typography>
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, width: '45%' }}>
                from £{fullPrice}
              </Typography>
            </Box>
          )}

          <Box sx={{ marginTop: spacing.m5, display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, width: '55%' }}>
              Delivery time
            </Typography>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, width: '45%' }}>
              {deliveryDays} Day{deliveryDays > 1 ? 's' : ''}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', ...spacing.mt20, verticalAlign: 'baseline' }}>
        {cart.length > 0 && cart.filter((item) => item.id === deviceModel.id)?.length > 0 ? (
          <Box sx={{ display: 'flex', gap: spacing.m15, alignItems: 'center' }}>
            <IconButton onClick={() => setCart([])} title="Edit" sx={tableIconButtonSx}>
              <Minus {...iconSize} />
            </IconButton>
            <Typography>1</Typography>
            <IconButton disabled title="Edit" sx={tableIconButtonSx}>
              <Plus {...iconSize} />
            </IconButton>
          </Box>
        ) : (
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setCart([deviceModel])}>
            Add to order
          </ButtonComponent>
        )}
      </Box>
    </Box>
  );
};

export const DevicePreviewSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '220px', paddingX: spacing.p10 }}>
      <Box sx={{ height: '140px' }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={210}
          height={120}
          sx={{ backgroundColor: themeColors.Background }}
        />
      </Box>
      <Box sx={{ ...spacing.mt20 }}>
        <Skeleton animation="wave" sx={{ backgroundColor: themeColors.Background }} />
        <Box sx={{ marginTop: spacing.m10 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
            <Skeleton animation="wave" sx={{ backgroundColor: themeColors.Background }} />
          </Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
            <Skeleton animation="wave" sx={{ backgroundColor: themeColors.Background }} />
          </Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m2 }}>
            <Skeleton animation="wave" sx={{ backgroundColor: themeColors.Background }} />
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', ...spacing.mt20 }}>
        <Skeleton animation="wave" sx={{ backgroundColor: themeColors.Background }} />
      </Box>
    </Box>
  );
};
