import { Dispatch, SetStateAction } from 'react';

import { Stack } from '@mui/material';

import { PayrollHMRCReadonlyState } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-hmrc-readonly.component';
import { PayrollHMRCConnectState } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-connect.component';
import { PayrollSettingHMRCEdit } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-edit.component';
import { ExternalEmployerDto } from '@/v2/feature/payroll/payroll-external.dto';

interface PayrollSettingHmrcProps {
  payrollId: number;
  employer: ExternalEmployerDto;
  readonly setEdit: Dispatch<SetStateAction<boolean>>;
  readonly edit: boolean;
  readonly disable: boolean;
  refreshPayroll: () => Promise<void>;
  onConnectClick: () => void;
}

export const PayrollSettingHmrcSetting = ({
  payrollId,
  employer,
  setEdit,
  edit,
  refreshPayroll,
  onConnectClick,
}: PayrollSettingHmrcProps): JSX.Element => {
  const hasHMRCDetails = !!employer.hmrcDetails.officeNumber;
  return (
    <Stack>
      {!hasHMRCDetails && <PayrollHMRCConnectState onConnectClick={() => onConnectClick()} />}
      {hasHMRCDetails && !edit && <PayrollHMRCReadonlyState employer={employer} onEditClick={() => setEdit(true)} />}
      {edit && (
        <PayrollSettingHMRCEdit
          title="HMRC connection"
          payrollId={payrollId}
          payroll={employer}
          onClose={() => {
            refreshPayroll();
            setEdit(false);
          }}
        />
      )}
    </Stack>
  );
};
