import { ReactNode, useMemo } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { borders } from '@v2/styles/borders.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { radius } from '@v2/styles/radius.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Comment } from '@/images/growth/Comment.svg';
import { ReactComponent as SmileHappy } from '@/images/growth/Smile_Happy.svg';

export type GrowthChipOptions = {
  text: string | number;
  // use smile for Smiley, comment for <Comment/>, color code as string for Color Circle, or icon as ReactNode
  icon?: 'smile' | 'comment' | string | ReactNode;
  iconPosition?: 'start' | 'end';
};

interface GrowthChipProps {
  options: GrowthChipOptions;
}

export const GrowthChip = ({ options }: GrowthChipProps) => {
  const icon = useMemo(() => {
    if (!options.icon) return null;

    if (typeof options.icon === 'string') {
      if (options.icon === 'smile') return <SmileHappy {...iconSize} />;
      if (options.icon === 'comment') return <Comment {...iconSize} />;

      return <GrowthCircle color={options.icon} />;
    }

    return options.icon;
  }, [options.icon]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        border: borders.background,
        borderRadius: radius.br15,
        px: spacing.p8,
      }}
    >
      {icon && options.iconPosition !== 'end' && <Box>{icon}</Box>}
      <Typography variant="caption" color="Grey">
        {options.text}
      </Typography>
      {icon && options.iconPosition === 'end' && <Box>{icon}</Box>}
    </Box>
  );
};

export const GrowthCircle = ({ color, size = '8px' }: { color: string; size?: string }) => (
  <Box sx={{ borderRadius: '15px', width: size, height: size, bgcolor: color }} />
);
