import React, { useCallback, useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRulesList } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rules-list.component';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';

export const ApprovalRuleSettingsPage = (): React.JSX.Element => {
  const {
    data: approvalRules,
    isLoading,
    mutate: refreshApprovalRules,
  } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });

  const { polyglot } = usePolyglot();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    if (refreshApprovalRules) await refreshApprovalRules();
  }, [refreshApprovalRules]);

  return (
    <ContentWrapper loading={isLoading}>
      <SettingsSectionContent
        title={polyglot.t('ApprovalRuleModule.approvalRules')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            {polyglot.t('ApprovalRuleModule.newRule')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: <ApprovalRulesList approvalRules={approvalRules ?? []} refresh={refresh} />,
                },
              ],
            },
          ]}
        />

        <ApprovalRuleDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} approvalRule={null} refresh={refresh} />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};
