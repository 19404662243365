import { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { ContractAPI } from '@/api-client/contract.api';
import { GlobalContext } from '@/GlobalState';
import { TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getDetailByDomain } from '@/v2/feature/dashboard/dashboard.util';
import { ContractTodo, DomainTypes } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { MissingPersonalEmployeeFieldsModal } from '@/v2/feature/templates/components/missing-personal-employee-fields-modal.component';
import { MissingTemplateFieldModal } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { RequiredContractFields, SignatoryType } from '@/v2/feature/templates/templates.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

export const ContractActionItem = ({
  selectedRow,
  afterClose,
  isModalOpen,
}: {
  selectedRow: ContractTodo;
  afterClose: () => void;
  isModalOpen: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const routerHistory = useHistory();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(isModalOpen);
  const [missingFields, setMissingFields] = useState<RequiredContractFields>({});
  const [openMissingEmployeeFieldModal, setOpenMissingEmployeeFieldModal] = useState(false);
  const [openMissingAdditionalSignatoryModal, setOpenMissingAdditionalSignatoryModal] = useState(false);

  const companySignatureRequired = (row: ContractTodo) => {
    return (
      row?.contract?.id &&
      row.contract.signatoriesRequired &&
      row.contract.signatoriesRequired.length > 0 &&
      row.contract.signatoriesRequired.includes(SignatoryType.additional) &&
      row.contract?.companySignatory === currentUser?.userId &&
      !row.contract?.companySignatureTimestamp
    );
  };

  const recipientSignatureRequired = (row: ContractTodo) => {
    return (
      row?.contract?.id &&
      row.contract.signatoriesRequired &&
      row.contract.signatoriesRequired.length > 0 &&
      row.contract.signatoriesRequired.includes(SignatoryType.recipient) &&
      row.contract?.recipient === currentUser?.userId &&
      !row.contract?.recipientSignatureTimestamp
    );
  };

  const refreshMissingFieldsForEmployee = useCallback(async (contractId: string) => {
    const missingFieldData = await ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFields(missingFieldData);
  }, []);

  const handleContractSignAction = async (row: ContractTodo) => {
    if (recipientSignatureRequired(row)) {
      if (Object.values(missingFields)?.some((v) => v === true)) {
        setOpenMissingEmployeeFieldModal(true);
      } else {
        routerHistory.push(
          generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
            templateId: row.contract?.templateId as string,
            contractId: row.contract?.id as string,
            userId: row.contract?.recipient as number,
          })
        );
      }
    } else if (companySignatureRequired(row)) {
      const stateToPassThrough = {
        companySignatoryUserId: row.contract?.companySignatory as number,
      };
      routerHistory.push(
        generatePath(TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, {
          templateId: row.contract?.templateId as string,
          contractId: row.contract?.id as string,
          userId: row.contract?.companySignatory as number,
        }),
        stateToPassThrough
      );
    }
  };

  useEffect(() => {
    if (selectedRow?.contract?.id && recipientSignatureRequired(selectedRow))
      refreshMissingFieldsForEmployee(selectedRow?.contract?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DrawerModal isOpen={isEditOpen} setIsOpen={setIsEditOpen} afterClose={afterClose}>
        <div>
          <Typography variant="title2"> {polyglot.t('ContractActionItem.signContract')}</Typography>
          <Typography variant="caption" sx={{ marginTop: spacing.m10 }}>
            {getDetailByDomain(selectedRow, getCachedUserById, currentUser, polyglot)}
          </Typography>
          <Box sx={spacing.mt20}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              fullWidth
              onClick={() => handleContractSignAction(selectedRow)}
            >
              {polyglot.t('ContractActionItem.viewAndSign')}
            </ButtonComponent>
          </Box>
        </div>
      </DrawerModal>

      {selectedRow &&
        selectedRow?.type === DomainTypes.Contracts &&
        openMissingEmployeeFieldModal &&
        missingFields &&
        recipientSignatureRequired(selectedRow as ContractTodo) && (
          <MissingPersonalEmployeeFieldsModal
            open={openMissingEmployeeFieldModal}
            setOpen={setOpenMissingEmployeeFieldModal}
            templateId={(selectedRow as ContractTodo).contract?.templateId}
            contractId={(selectedRow as ContractTodo).contract?.id}
            missingFields={missingFields}
            contractRecipientId={(selectedRow as ContractTodo).contract?.recipient}
            refreshMissingFields={refreshMissingFieldsForEmployee}
          />
        )}

      {selectedRow &&
        selectedRow?.type === DomainTypes.Contracts &&
        openMissingAdditionalSignatoryModal &&
        missingFields &&
        companySignatureRequired(selectedRow as ContractTodo) && (
          <MissingTemplateFieldModal
            open={openMissingAdditionalSignatoryModal}
            setOpen={setOpenMissingAdditionalSignatoryModal}
            templateId={(selectedRow as ContractTodo)?.contract?.templateId}
            missingFields={missingFields}
            contractRecipientId={(selectedRow as ContractTodo)?.contract?.recipient}
            companySignatoryUserId={(selectedRow as ContractTodo)?.contract?.companySignatory}
          />
        )}
    </>
  );
};
