import React, { CSSProperties } from 'react';

import { themeColors } from '@/v2/styles/colors.styles';

type Props = {
  color?: CSSProperties['backgroundColor'];
  style?: React.CSSProperties;
  className?: string;
};

export const Divider = ({ color = themeColors.GreyLight, style, className }: Props) => {
  return <div className={className} style={{ height: '1px', flexShrink: 0, backgroundColor: color, ...style }} />;
};
