import Box from '@mui/material/Box';
import { InfoCard } from '@v2/feature/absence/components/info-card.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import { SubscriptionBill } from '@/models/invoice.model';
import { CompanySubscriptionStats, Product, SubscriptionBillingStats } from '@/models/subscription.model';

interface BillingOverviewProps {
  readonly subscriptionStats: CompanySubscriptionStats;
  readonly subscriptionsBillingStats: SubscriptionBillingStats | null;
}

const getSingleProductSubscriptionsCost = (subscriptionBills: SubscriptionBill[]) => {
  return subscriptionBills.reduce((acc, subscription) => {
    acc += subscription.subscriptionCost - subscription.totalDiscount;
    return acc;
  }, 0);
};

export const BillingOverview = ({ subscriptionStats, subscriptionsBillingStats }: BillingOverviewProps) => {
  const { polyglot } = usePolyglot();

  const nextInvoiceCost = subscriptionsBillingStats
    ? Object.keys(subscriptionsBillingStats).reduce((acc, product) => {
        acc += getSingleProductSubscriptionsCost(subscriptionsBillingStats[Number(product) as Product]);
        return acc;
      }, 0)
    : 0;

  const activeUsers = subscriptionStats.companyStats.noOfActiveUsers;
  const rentedDevices = subscriptionStats.companyStats.noOfRentedDevices;
  const activeAccountsText = `${activeUsers} user${activeUsers === 1 ? '' : 's'}`;

  const today = new Date();
  const nextInvoiceDate = new Date(today.getFullYear(), today.getMonth() + 1, 1).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const rentedDevicesText = `${rentedDevices} device${rentedDevices === 1 ? '' : 's'}`;
  return (
    <Box sx={{ display: 'flex', gap: spacing.gap60 }}>
      {nextInvoiceCost > 0 && (
        <InfoCard title={polyglot.t('BillingOverview.nextInvoiceDate')} value={nextInvoiceDate} />
      )}
      {nextInvoiceCost > 0 && (
        <InfoCard title={polyglot.t('BillingOverview.amount')} value={formatMoney({ amount: nextInvoiceCost })} />
      )}
      <InfoCard title={polyglot.t('BillingOverview.active')} value={activeAccountsText} />
      {rentedDevices > 0 && <InfoCard title={polyglot.t('BillingOverview.rented')} value={rentedDevicesText} />}
    </Box>
  );
};
