import { useContext, useEffect, useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PersonalTask } from '@/v2/feature/task/pages/personal/components/personal-task.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface TasksUserPageProps {
  readonly userId: number;
}

export const TasksUserPage = ({ userId }: TasksUserPageProps): JSX.Element => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const params: { userId: string } = useParams();
  const targetUserId = Number(params.userId);
  const { getScopesContext, hasScopes } = useScopes();
  const hasTaskManagerScopes = hasScopes(['task:manager'], getScopesContext(user));
  const hasTaskAdminScopes = hasScopes(['task:all'], getScopesContext(user));

  const isManagerOfTargetUserId = useMemo(() => {
    return hasTaskManagerScopes && user.reports.includes(targetUserId);
  }, [hasTaskManagerScopes, targetUserId, user.reports]);

  const { polyglot } = usePolyglot();
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState<boolean>(false);
  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Personal tasks');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('TasksUserPage.tasks')}
          </Typography>
        }
        showAction={hasTaskAdminScopes || isManagerOfTargetUserId}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsTaskFormModalOpen(true)}>
            {polyglot.t('PersonalTask.new')}
          </ButtonComponent>
        }
      />
      <PersonalTask
        userId={userId}
        setIsTaskFormModalOpen={setIsTaskFormModalOpen}
        isTaskFormModalOpen={isTaskFormModalOpen}
        reach="me"
      />
    </RootStyle>
  );
};
