import { useMemo, useState } from 'react';

import { Box } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import {
  isCurrentDateTimeLessThanOrEqualToSpecified,
  isTimingCorrectlySet,
} from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/rc-upsert-launch.util';
import { getDayOrDays } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/rc-upsert-timeline.page';
import { ReviewCycleLaunch } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

interface ILaunchScheduled {
  readonly reviewCycleLaunchDetails: ReviewCycleLaunch;
  readonly cycleId: string;
  readonly goBack: () => void;
  readonly showFlags: { showSelf: boolean; showUpward: boolean; showPeer: boolean; showManager: boolean };
}

export const LaunchScheduled = ({ reviewCycleLaunchDetails, cycleId, goBack, showFlags }: ILaunchScheduled) => {
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { timelineSettings } = reviewCycleLaunchDetails;

  const showActionButtons = useMemo(
    () =>
      reviewCycleLaunchDetails &&
      reviewCycleLaunchDetails.state === CycleState.Draft &&
      reviewCycleLaunchDetails.totalQuestions > 0 &&
      reviewCycleLaunchDetails.revieweeId.length > 0 &&
      isTimingCorrectlySet(reviewCycleLaunchDetails.timelineSettings, reviewCycleLaunchDetails.reviewerSelect),
    [reviewCycleLaunchDetails]
  );

  const isTodayOrFuture = useMemo(() => {
    return reviewCycleLaunchDetails && reviewCycleLaunchDetails.timelineSettings.startDate
      ? isCurrentDateTimeLessThanOrEqualToSpecified(reviewCycleLaunchDetails.timelineSettings) &&
          reviewCycleLaunchDetails.state === CycleState.Draft
      : false;
  }, [reviewCycleLaunchDetails]);

  const isStartNow = useMemo(
    () => Boolean(reviewCycleLaunchDetails && reviewCycleLaunchDetails.timelineSettings.startNow),
    [reviewCycleLaunchDetails]
  );

  const launchReviewCycle = async () => {
    try {
      setLoading(true);
      await ReviewCycleAPI.launchReviewCycle(cycleId);
      goBack();
      showMessage('Successfully launched the cycle', 'success');
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <SettingsSubsectionContent
      sections={[
        {
          contentWidth: '100%',
          headerWidth: '100%',
          title: 'Start review cycle',
          items: [
            {
              type: SectionItemType.Pair,
              label: 'Number of questions',
              value: (
                <Typography
                  variant="title4"
                  color={reviewCycleLaunchDetails.totalQuestions > 0 ? 'DarkGrey' : 'RedDark'}
                >
                  {reviewCycleLaunchDetails.totalQuestions ?? 'Please add some questions'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Participants',
              value: (
                <Typography
                  variant="title4"
                  color={reviewCycleLaunchDetails.participantCount > 0 ? 'DarkGrey' : 'RedDark'}
                >
                  {reviewCycleLaunchDetails.participantCount || 'Please add some participants'}
                </Typography>
              ),
            },
            ...(isStartNow
              ? []
              : [
                  {
                    type: SectionItemType.Pair,
                    label: 'Start date',
                    value: reviewCycleLaunchDetails.timelineSettings?.startDate ? (
                      <Typography variant="title4" color={isTodayOrFuture ? 'DarkGrey' : 'RedDark'}>
                        {new LocalDate(reviewCycleLaunchDetails.timelineSettings?.startDate)
                          .getDate()
                          .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
                      </Typography>
                    ) : (
                      'NA'
                    ),
                  },
                  {
                    type: SectionItemType.Pair,
                    label: 'Start time',
                    value: reviewCycleLaunchDetails.timelineSettings?.startTime ? (
                      <Typography variant="title4" color={isTodayOrFuture ? 'DarkGrey' : 'RedDark'}>
                        {reviewCycleLaunchDetails.timelineSettings?.startTime}
                        {reviewCycleLaunchDetails.timelineSettings?.startTimeMeridiem}
                      </Typography>
                    ) : (
                      'NA'
                    ),
                  },
                ]),
            ...(showFlags.showSelf
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Self review deadline',
                    value: timelineSettings?.selfReviewDeadline ? (
                      `${timelineSettings?.selfReviewDeadline} ${getDayOrDays(
                        timelineSettings?.selfReviewDeadline
                      )} after start`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(showFlags.showUpward
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Upward review deadline',
                    value: timelineSettings?.upwardReviewDeadline ? (
                      `${timelineSettings?.upwardReviewDeadline} ${getDayOrDays(
                        timelineSettings?.upwardReviewDeadline
                      )} after start`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(showFlags.showPeer
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Peer review deadline',
                    value: timelineSettings?.peerReviewDeadline ? (
                      `${timelineSettings?.peerReviewDeadline} ${getDayOrDays(
                        timelineSettings?.peerReviewDeadline
                      )} after start`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(showFlags.showManager
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Manager review deadline',
                    value: timelineSettings?.managerReviewDeadline ? (
                      `${timelineSettings?.managerReviewDeadline} ${getDayOrDays(
                        timelineSettings?.managerReviewDeadline
                      )} after start`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              type: SectionItemType.Component,
              value: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g4, mt: spacing.m8 }}>
                  <div>
                    <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => goBack()}>
                      Save and close
                    </ButtonComponent>
                  </div>
                  {showActionButtons && (
                    <div>
                      {isStartNow || isTodayOrFuture ? (
                        <LoaderButton
                          sizeVariant="small"
                          colorVariant="primary"
                          onClick={launchReviewCycle}
                          loading={loading}
                          disabled={loading}
                        >
                          {isStartNow ? 'Start review cycle' : 'Schedule review cycle'}
                        </LoaderButton>
                      ) : null}
                    </div>
                  )}
                </Box>
              ),
              hidden: Boolean(
                reviewCycleLaunchDetails &&
                  reviewCycleLaunchDetails.state &&
                  (reviewCycleLaunchDetails.state === CycleState.Ongoing ||
                    reviewCycleLaunchDetails.state === CycleState.Completed)
              ),
            },
          ],
        },
      ]}
    />
  );
};
