import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI, AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { CreateOneOffPayItem } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';

import { getNewPayItemValidationSchema } from '@/component/dashboard/userDetails/pay-items/components/add-new-pay-item-drawer.component';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

export interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto | null;
  readonly refresh: () => Promise<void>;
  readonly afterClose: () => void;
}

export const OffboardingAbsenceAddToPayrollDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  refresh,
  afterClose,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      {absencePolicy ? (
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="90%"
              height="90vh"
              sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
            />
          }
        >
          <OffboardingAbsenceAddToPayrollDrawerContent
            userId={userId}
            absencePolicy={absencePolicy}
            refresh={refresh}
            setIsOpen={setIsOpen}
          />
        </Suspense>
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto | null;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OffboardingAbsenceAddToPayrollDrawerContent = ({
  userId,
  absencePolicy,
  refresh,
  setIsOpen,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { data: oneOffPaymentToBeCreated } = useApiClient(
    absencePolicy ? AbsenceEndpoints.getUserAbsenceOneOffPaymentToBeCreated(userId, absencePolicy.id) : null,
    {
      suspense: true,
    }
  );

  const [loading, setLoading] = useState<boolean>(false);

  const addToPayroll = useCallback(
    async (values: CreateOneOffPayItem) => {
      if (!absencePolicy) return;
      try {
        setLoading(true);
        const payItem: CreateOneOffPayItem = {
          date: values.date,
          amount: Number(values.amount),
          multiplier: 1,
          description: values.description,
          payCode: values.payCode,
        };

        await AbsenceAPI.addLeaverPolicyBalanceToPayroll(userId, absencePolicy.id, payItem);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setLoading(false);
    },
    [absencePolicy, userId, showMessage, polyglot, refresh, setIsOpen]
  );

  const formik = useFormik<CreateOneOffPayItem>({
    initialValues: {
      date: oneOffPaymentToBeCreated?.date ?? new LocalDate().toDateString(),
      amount: oneOffPaymentToBeCreated?.amount ?? 0,
      multiplier: oneOffPaymentToBeCreated?.multiplier ?? 1,
      description: oneOffPaymentToBeCreated?.description ?? '',
      payCode: oneOffPaymentToBeCreated?.payCode ?? '',
    },
    validationSchema: getNewPayItemValidationSchema(polyglot),
    onSubmit: addToPayroll,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PayItemModule.addOneOffPayItem')}</Typography>

        <DatePickerComponent
          name="date"
          label={polyglot.t('General.date')}
          onChange={(value) => {
            formik.setFieldValue('date', value);
          }}
          value={formik.values.date}
          error={formik.touched.date && !!formik.errors.date}
          helperText={formik.touched.date && formik.errors.date}
        />

        <TextfieldComponent
          name="payCode"
          label={polyglot.t('PayItemModule.payCode')}
          value={formik.values.payCode}
          onChange={formik.handleChange}
          error={formik.touched.payCode && !!formik.errors.payCode}
          helperText={(formik.touched.payCode && formik.errors.payCode) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="amount"
          label={polyglot.t('PayItemModule.amount')}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && !!formik.errors.amount}
          helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="description"
          label={polyglot.t('General.description')}
          multiline
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && !!formik.errors.description}
          helperText={(formik.touched.description && formik.errors.description) ?? ' '}
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.add')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
