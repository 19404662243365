import axios from 'axios';

import {
  ReviewSectionBank,
  ReviewSectionBankCreate,
  ReviewSectionBankUpdate,
} from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';

export class ReviewSectionBankAPI {
  static async createReviewSectionInBank(sectionBody: ReviewSectionBankCreate): Promise<ReviewSectionBank> {
    return (await axios.post('/apiv2/review-section-bank', sectionBody)).data;
  }

  static async updateReviewSectionInBank(sectionBody: ReviewSectionBankUpdate): Promise<void> {
    await axios.patch('/apiv2/review-section-bank', sectionBody);
  }

  static async deleteSectionById(templateId: string, sectionId: string): Promise<void> {
    await axios.delete(`/apiv2/review-section-bank/${templateId}/${sectionId}`);
  }
}
