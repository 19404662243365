import React from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { SuperAdminInsurancePoliciesRouter } from '@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-policies.router';
import { SuperAdminInsuranceQuotesPage } from '@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-quotes.page';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  SUPER_ADMIN_INSURANCE_POLICIES_ROUTE,
  SUPER_ADMIN_INSURANCE_QUOTES_ROUTE,
  SUPER_ADMIN_INSURANCE_ROUTE,
} from '@/lib/routes';

const PAGE_CONFIG = {
  header: {
    tabs: [
      { label: 'Quotes', value: SUPER_ADMIN_INSURANCE_QUOTES_ROUTE, scopes: ['insurance'] },
      { label: 'Policies', value: SUPER_ADMIN_INSURANCE_POLICIES_ROUTE, scopes: ['insurance'] },
    ],
  },
} as const;

interface SuperAdminDevicesRouterProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const SuperAdminInsuranceRouter = ({ companies, users }: SuperAdminDevicesRouterProps): JSX.Element => {
  return (
    <Switch>
      <Route path={SUPER_ADMIN_INSURANCE_QUOTES_ROUTE}>
        <SuperAdminInsuranceQuotesPage companies={companies} users={users} pageConfig={PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_INSURANCE_POLICIES_ROUTE}>
        <SuperAdminInsurancePoliciesRouter companies={companies} users={users} pageConfig={PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_INSURANCE_ROUTE}>
        <Redirect to={SUPER_ADMIN_INSURANCE_QUOTES_ROUTE} />
      </Route>
    </Switch>
  );
};
