import { forwardRef, HTMLAttributes } from 'react';

import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Paper,
  PaperProps,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { ReactComponent as Edit } from '@/images/fields/Edit.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledMenuItem } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: '14px', height: '14px' } as const;

export interface OptionObject<T = string | number> {
  readonly label: string;
  readonly value: T;
  readonly disabled?: boolean;
  readonly handler?: () => void;
  readonly isHidden?: boolean;
}

export interface EditListOption {
  readonly handler: () => void;
  readonly isHidden: boolean;
}

type Multiple = boolean | undefined;
type DisableClearable = true;
type FreeSolo = boolean | undefined;

type CustomAutocompleteProps = Omit<
  AutocompleteProps<OptionObject, Multiple, DisableClearable, FreeSolo>,
  'name' | 'label' | 'renderInput'
>;

export interface AutocompleteOptionObj {
  readonly value: number | string;
  readonly label: string;
}

export interface AutocompleteComponentProps extends CustomAutocompleteProps {
  readonly name: string;
  readonly label?: React.ReactNode;
  readonly placeholder?: string;
  readonly value: any;
  readonly compareValue?: any;
  readonly error?: boolean;
  readonly helperText?: React.ReactNode;
  readonly required?: boolean;
  readonly freeSolo?: boolean;
  readonly multiple?: boolean;
  readonly editList?: EditListOption;
}

interface CustomPaperProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  editList?: EditListOption;
}

const FooterComponent = ({ editList }: { editList: EditListOption }) => (
  <Box
    sx={{ padding: spacing.m10, borderTop: '1px solid', borderColor: 'divider', cursor: 'pointer' }}
    onMouseDown={(event) => {
      event.preventDefault();
    }}
    onClick={() => {
      editList.handler?.();
    }}
  >
    <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
      <Edit {...iconSize} />
      <Typography
        sx={{
          ...themeFonts.caption,
          color: themeColors.Grey,
        }}
      >
        Edit list
      </Typography>
    </Stack>
  </Box>
);

const CustomPaperComponent: React.FC<CustomPaperProps> = ({ children, sx, editList, ...rest }) => (
  <Paper {...rest} sx={{ ...sx, ...StyledAuto, paddingBottom: '0 !important', zIndex: 1 }}>
    {children}
    {editList && !editList.isHidden && <FooterComponent editList={editList} />}
  </Paper>
);

export const AutocompleteComponent = forwardRef<typeof Autocomplete, AutocompleteComponentProps>(
  (
    {
      name,
      label,
      options,
      value,
      compareValue,
      error,
      helperText,
      required,
      freeSolo,
      placeholder,
      editList,
      ...autoCompleteProps
    },
    ref
  ) => {
    const PaperComponentWithEditList: React.FC<PaperProps & { editList?: OptionObject }> = (paperProps) => (
      <CustomPaperComponent {...paperProps} editList={editList} />
    );

    return (
      <Autocomplete
        ref={ref}
        {...autoCompleteProps}
        options={options}
        InputLabelProps={{ shrink: true }}
        // @ts-ignore
        getOptionLabel={(option: OptionObject) => option.label}
        value={value}
        freeSolo={freeSolo as FreeSolo}
        renderOption={(props, option) => {
          return (
            <StyledMenuItem
              {...props}
              sx={{
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                  width: '100%',
                  color: themeColors.DarkGrey,
                  ...(option.value !== compareValue ? themeFonts.caption : themeFonts.title4),
                }}
              >
                {option.label}
              </Box>
              {option.value === compareValue && <ChoseIcon {...iconSize} />}
            </StyledMenuItem>
          );
        }}
        renderInput={(params) => (
          <StyledAutoTextfield
            {...params}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="standard"
            placeholder={placeholder}
            size="small"
            label={label}
            name={name}
            error={error}
            helperText={helperText}
            required={required}
          />
        )}
        PaperComponent={PaperComponentWithEditList}
        popupIcon={<ArrowDownACIcon {...iconSize} />}
        clearIcon={<ClearIcon {...iconSize} />}
      />
    );
  }
);
