// Scopes are composed the following way:
// {entity(.prop)?}.{action?}:{filter?}

// Scope Action:
// (none) -> READ, WRITE
// :read -> READ only

// Scope Filter:
// (none) -> Only entities that a user own
// :all -> All users
// :manager -> Only managers for that user

export type ScopeRoot =
  | 'calendar'
  | 'payments'
  | 'expenses'
  | 'invoices'
  | 'sites'
  | 'documents'
  | 'reports'
  | 'payroll'
  | 'pension'
  | 'insurance'
  | 'reviews'
  | 'surveys'
  | 'task'
  | 'absence'
  | 'attendance'
  | 'billing'
  | 'templates'
  | DeviceScopeRoot
  | CompanyScopeRoot
  | UserScopeRoot
  | AppScopeRoot;

type CompanyScopeRoot = `company${CompanyProperties}`;
type CompanyProperties = '' | '.settings';

type UserScopeRoot = `user${UserProperties}`;
type UserProperties =
  | ''
  | '.onboard'
  | '.basicInfo'
  | '.personalInfo'
  | '.about'
  | '.emergencyContact'
  | '.workContact'
  | '.family'
  | '.personalContact'
  | '.personalIdentification'
  | '.contract'
  | '.bankAccount'
  | '.accountInfo'
  | '.rightWork'
  | '.event'
  | '.lifecycle'
  | '.address'
  | '.role'
  | '.equity'
  | '.compensation';

type DeviceScopeRoot = `devices${DevicesProperties}`;
type DevicesProperties = '' | '.store' | '.price' | '.enroll';

type AppScopeRoot = `apps${AppsProperties}`;
type AppsProperties = '' | '.connect';

export enum ScopeActionEnum {
  ReadWrite = '',
  ReadOnly = ':read',
  WriteOnly = ':write',
}

export type ScopeAction = ScopeActionEnum.ReadWrite | ScopeActionEnum.ReadOnly | ScopeActionEnum.WriteOnly;

export enum ScopeFilterEnum {
  Owner = '',
  Manager = ':manager',
  All = ':all',
}

export type ScopeFilter = ScopeFilterEnum.Owner | ScopeFilterEnum.Manager | ScopeFilterEnum.All;

export type Scope = `${ScopeRoot}${ScopeAction}${ScopeFilter}`;

export interface ScopeContext {
  userId: number;
}

export interface ParsedScope {
  entity: ScopeRoot;
  action: ScopeActionEnum;
  filter: ScopeFilterEnum;
}

export interface ExtendedScope {
  scope: Scope;
  exclusions?: number[];
  inclusions?: number[];
}
