import React from 'react';

import { v4 } from 'uuid';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ReviewQuestionBankAPI } from '@/v2/feature/growth/reviews/api-client/review-question-bank.api';
import { QuestionModalContent } from '@/v2/feature/growth/reviews/features/components/question-modal-content.component';
import {
  CommonCycleAndBankQuestion,
  ReviewQuestionBank,
} from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { ReviewSectionBank } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';

export const ReviewTemplateQuestionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  question,
  refresh,
  section,
  templateId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  question: ReviewQuestionBank | null;
  refresh: () => Promise<void>;
  section?: ReviewSectionBank | null;
  templateId: string;
}) => {
  const onSubmit = async (values: CommonCycleAndBankQuestion) => {
    if (question?.id) {
      //update question
      const questionValues = {
        ...question,
        ...values,
        sectionId: section?.id ?? null,
        templateId,
      };
      await ReviewQuestionBankAPI.updateReviewQuestionInBank(questionValues);
    } else {
      const questionValues = {
        ...values,
        id: v4(),
        sectionId: section?.id ?? null,
        templateId,
      };
      await ReviewQuestionBankAPI.createReviewQuestionInBank(questionValues);
    }
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <QuestionModalContent onClose={onClose} question={question} refresh={refresh} onSubmit={onSubmit} />
    </DrawerModal>
  );
};
