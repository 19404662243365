import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { FilterTypesProps } from '@v2/components/table/category-filters.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { StyledTabsComponent } from '@v2/components/theme-components/styled-tabs.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import {
  ReportDates,
  UpdateReportDatesDrawer,
} from '@v2/feature/reports/features/update-report/people/update-report-dates-drawer.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { DateType, ReportCustomFields, ReportEntity, UpdateReportOld } from '@v2/feature/reports/reports.interface';
import {
  getAllSelectedColumnsFromUserReportColumnsOptions,
  getFilterColumnsPeopleChangeReport,
  getPeopleReportFilters,
} from '@v2/feature/reports/util/people-report.util';
import { exportReportCSVOldReports } from '@v2/feature/reports/util/report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Calendar } from '@/images/side-bar-icons/Calendar.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';

export const PeopleChangeUpdateReport = ({
  report,
  loading,
  customFields,
  additionalColumns,
  refreshReport,
}: {
  report: ReportDto;
  loading: boolean;
  customFields: ReportCustomFields;
  additionalColumns: {
    label: string;
    value: string;
    entity: ReportEntity;
  }[];
  refreshReport: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false,
  });
  const { hasScopes } = useScopes();
  const [filteredData, setFilteredData] = useState<
    | {
        [key: string]: Record<string, string | number>[];
      }
    | undefined
  >(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );

  const [filterTypes, setFilterTypes] = useState<FilterTypesProps>({});
  const [filterString, setFilterString] = useState<string>(report?.config?.filters ?? '');
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState<boolean>(true);
  const [reportName, setReportName] = useState<string>(report?.name ?? '');
  const [currentTab, setCurrentTab] = useState<string>(
    report.config?.entities && report.config.entities[0] ? report.config?.entities[0] : ''
  );
  const [isEditDatesOpen, setIsEditDatesOpen] = useState<boolean>(false);

  const reportDates = useMemo(() => {
    if (!report) return '';

    if (report.config.dateType === DateType.Last30days) return polyglot.t('PeopleChangeUpdateReport.thirtyDays');
    if (report.config.dateType === DateType.Last7days) return polyglot.t('PeopleChangeUpdateReport.sevenDays');

    if (report.config.start && report.config.end)
      return `${new LocalDate(report.config.start).toLocaleDateString()} - ${new LocalDate(
        report.config.end
      ).toLocaleDateString()}`;
  }, [report, polyglot]);

  const tabs = useMemo(
    () =>
      report.config?.entities?.map((entity) => ({
        label: entity as string,
        value: entity as string,
      })) ?? [],
    [report.config?.entities]
  );
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');

  const userEntities = useMemo(() => report.config.entities, [report.config?.entities]);

  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: string[];
  }>({});

  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState<string[]>(
    userEntities && userEntities.length > 0 ? selectedColumns[userEntities[0]] ?? [] : []
  );

  useEffect(() => {
    // Set the first tab for the first time, then each time the tabs change,
    // if the currentTab is defined and in the list of tabs keep it, otherwise pick the first tab from list
    // This is done because the data is loaded on every filter change => tabs are updated on each filter change based on the data from the response
    // And each time the tabs are updated, a currentTab change is attempted, but we don't want a tab reset at each data read
    // Had to do that because of the way we update the selected columns for each subdomain. At each tab change, the settings for the selected columns get changed,
    // resulting in the load data request to be called, resulting in tabs reset
    setCurrentTab((prev) => (prev && tabs.some((t) => t.value === prev) ? prev : tabs[0]?.value ?? ''));
  }, [tabs]);

  useEffect(() => {
    const selectedCols = report.config?.columns
      ? report.config.columns
      : getAllSelectedColumnsFromUserReportColumnsOptions(
          userEntities,
          customFields,
          hasScopes,
          additionalColumns,
          polyglot
        );

    setSelectedColumns(selectedCols);
    setSelectedColumnsStrings(
      currentTab
        ? (selectedCols as {
            [key: string]: string[];
          })[currentTab] ?? []
        : []
    );
    setAllDataLoaded(true);
  }, [polyglot, currentTab, hasScopes, report.config?.columns, userEntities, customFields, additionalColumns]);

  useEffect(() => {
    if (!allDataLoaded) return;
    setSelectedColumns((prev) => ({ ...prev, [currentTab]: selectedColumnsStrings }));
  }, [allDataLoaded, currentTab, selectedColumnsStrings]);

  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);

  const getReportData = useCallback(
    async (
      formData: ReportDto,
      selectedColumns: {
        [key: string]: string[];
      },
      filterString: string
    ) => {
      setReportsLoading(true);
      try {
        let filteredUsers = await ReportsAPI.generatePeopleCreatedChangeReport({
          ...formData.config,
          filters: filterString,
          columns: selectedColumns,
        });
        setFilteredData(filteredUsers ?? []);
      } catch (error) {
        showMessage(
          `${polyglot.t('PeopleChangeUpdateReport.errorMessages.load')}. ${nestErrorMessage(error)}`,
          'error'
        );
      }
      setReportsLoading(false);
    },
    [polyglot, showMessage]
  );

  useEffect(() => {
    if (report && allDataLoaded) getReportData(report, selectedColumns, filterString);
  }, [allDataLoaded, getReportData, report, filterString, selectedColumns]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData && currentTab && filteredData[currentTab]
        ? filteredData[currentTab]?.filter(
            (data) =>
              !searchInput ||
              (data?.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
          )
        : undefined
    );
  }, [currentTab, searchInput, filteredData]);

  const handleSave = useCallback(async () => {
    try {
      if (report) {
        const paramObj: UpdateReportOld = {
          filters: filterString,
          columns: selectedColumns,
          fileName: reportName ?? report.name,
        };
        await ReportsAPI.patchReport(report.id, paramObj);
      }
      showMessage(polyglot.t('PeopleChangeUpdateReport.successMessages.update'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  }, [polyglot, showMessage, report, reportName, routerHistory, filterString, selectedColumns]);

  const exportCsv = useCallback(() => {
    try {
      exportReportCSVOldReports(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t('PeopleChangeUpdateReport.errorMessages.download'), 'error');
    }
  }, [polyglot, showMessage, reportName, filteredData]);

  const updateReportDates = useCallback(
    async (datesUpdate: ReportDates) => {
      try {
        if (datesUpdate.dateType && datesUpdate.start && datesUpdate.end) {
          // @ts-ignore  For some reason my IDE shows an error saying dateType can't be undefined, even if the if from above makes it clear it can't be undefined
          await ReportsAPI.patchReportDates(report.id, datesUpdate!);
          showMessage(polyglot.t('PeopleChangeUpdateReport.successMessages.update'), 'success');
          await refreshReport();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [polyglot, report.id, refreshReport, showMessage]
  );

  return (
    <RootStyle>
      <TopHeader
        showBack
        title={
          reportName ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
              {reportDates && <Typography sx={themeFonts.caption}>{reportDates}</Typography>}
              <EditableTitle
                variant="title2"
                value={reportName}
                onChange={(value) => {
                  setReportName(value);
                }}
                maxLength={50}
                editButtonTooltip={undefined}
              />
            </Box>
          ) : (
            <></>
          )
        }
        showAction
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <ButtonComponent
              sizeVariant="small"
              colorVariant="secondary"
              startIcon={<Calendar {...iconSize} />}
              onClick={() => setIsEditDatesOpen(true)}
            >
              {polyglot.t('PeopleChangeUpdateReport.edit')}
            </ButtonComponent>
            <ButtonComponent
              sizeVariant="small"
              colorVariant="secondary"
              startIcon={<Export {...iconSize} />}
              onClick={exportCsv}
            >
              {polyglot.t('PeopleChangeUpdateReport.downloadCsv')}
            </ButtonComponent>
            <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={handleSave}>
              {polyglot.t('General.save')}
            </ButtonComponent>

            <UpdateReportDatesDrawer
              isOpen={isEditDatesOpen}
              setIsOpen={setIsEditDatesOpen}
              reportDates={{ dateType: report.config.dateType, start: report.config.start, end: report.config.end }}
              action={updateReportDates}
            />
          </Box>
        }
      />
      <ContentWrapper loading={loading} sx={{ pt: 0 }}>
        <Box sx={{ mt: spacing.m20, display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
          <ColumnsDrawer
            columnsOptions={getFilterColumnsPeopleChangeReport(
              currentTab,
              userEntities,
              customFields,
              hasScopes,
              additionalColumns,
              polyglot
            )}
            selectedColumns={selectedColumnsStrings}
            setSelectedColumns={setSelectedColumnsStrings}
          />
          <FiltersDrawer
            filtersOptions={[{ filters: filterTypes }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            encodedFilterNames
          />
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
        <Box>
          <Box sx={{ ...spacing.mt20 }}>
            {tabs ? (
              <StyledTabsComponent tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            ) : (
              <Skeleton variant="text" sx={{ backgroundColor: themeColors.Background }} />
            )}
          </Box>

          <Box sx={{ ...spacing.mt20 }}>
            <ResultTableOldReports filteredData={filteredAndSearchedData ?? []} loading={reportLoading} />
          </Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
