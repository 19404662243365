import { Box } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const EmptyStateJobLevel = ({ addNewTrack }: { addNewTrack: () => void }) => {
  const { polyglot } = usePolyglot();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, maxWidth: '18rem' }}>
      <Typography variant="title2">{polyglot.t('EmptyStateJobLevel.firstTrack')}</Typography>
      <Typography variant="caption">{polyglot.t('EmptyStateJobLevel.emptyMessage')}</Typography>

      <ButtonComponent
        sizeVariant="small"
        colorVariant="primary"
        type="button"
        onClick={addNewTrack}
        style={{ marginTop: spacing.m10 }}
      >
        {polyglot.t('EmptyStateJobLevel.newTrack')}
      </ButtonComponent>
    </Box>
  );
};
