import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import type { BasicInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

import useMessage from '@/hooks/notification.hook';
import { useProfileFields } from '@/hooks/profile-fields.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserAPI } from '@/v2/feature/user/user.api';

export const BasicInformationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  middleName: Yup.string().optional().nullable(),
  displayName: Yup.string().notRequired(),
  emailAddress: Yup.string().email('Enter a valid email').required('Email is required'),
});

interface Props {
  readonly userId: number;
  readonly initialValues?: BasicInformationValues;
  readonly onSubmit?: (_: BasicInformationValues) => void;
  readonly onClose: () => void;
  readonly handleRefresh: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: BasicInformationValues) => void;
}

export const BasicInformationForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  handleRefresh,
  usedForDataImport = false,
  importHandler = () => {},
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();

  const { hasScopes } = useScopes();
  const hasBasicAll = hasScopes(['user.basicInfo:all'], { userId: userId });
  const [loading, setLoading] = useState<boolean>(false);
  const { getDefaultField } = useProfileFields();

  const onFormikSubmit = useCallback(
    async (values: BasicInformationValues) => {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          // TODO return patched data
          const _result = await UserAPI.patchUserBasicInfo(userId, values);
          onSubmit?.({ ...values });
          handleRefresh();
          showMessage(polyglot.t('BasicInformationForm.successMessages.update'), 'success');
        } else {
          importHandler?.({ ...values });
        }
      } catch (error) {
        // TODO we should improve how we handle halidation error messages
        const err = error as { response: { data: { error: string } } };
        if (
          err?.response?.data?.error?.includes('Key (email_address)') &&
          err?.response?.data?.error?.includes('already exists')
        ) {
          showMessage(polyglot.t('BasicInformationForm.errorMessages.update'), 'error');
        } else {
          showMessage(polyglot.t('BasicInformationForm.errorMessages.save', { msg: nestErrorMessage(error) }), 'error');
        }
      } finally {
        setLoading(false);
      }
    },
    [importHandler, onSubmit, polyglot, showMessage, usedForDataImport, userId, handleRefresh]
  );

  const formik = useFormik<BasicInformationValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      displayName: '',
      customUpdates: [],
      emailAddress: '',
      ...initialValues,
    },
    enableReinitialize: true,
    validationSchema: BasicInformationSchema,
    onSubmit: onFormikSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Edit basic</Typography>

        <ProfileField fieldStub="emailAddress">
          <TextfieldComponent
            name="emailAddress"
            label={polyglot.t('BasicInformationForm.emailAddress')}
            value={formik.values.emailAddress}
            type="text"
            disabled={!hasBasicAll}
            onChange={formik.handleChange}
            error={formik.touched.emailAddress && !!formik.errors.emailAddress}
            helperText={(formik.touched.emailAddress && formik.errors.emailAddress) ?? ' '}
            clearText={() => formik.setFieldValue('emailAddress', '')}
            required={getDefaultField('emailAddress')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="firstName">
          <TextfieldComponent
            name="firstName"
            label={polyglot.t('BasicInformationForm.firstName')}
            value={formik.values.firstName}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.firstName && !!formik.errors.firstName}
            helperText={(formik.touched.firstName && formik.errors.firstName) ?? ' '}
            clearText={() => formik.setFieldValue('firstName', '')}
            required={getDefaultField('firstName')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="lastName">
          <TextfieldComponent
            name="lastName"
            label={polyglot.t('BasicInformationForm.lastName')}
            value={formik.values.lastName}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.lastName && !!formik.errors.lastName}
            helperText={(formik.touched.lastName && formik.errors.lastName) ?? ' '}
            clearText={() => formik.setFieldValue('lastName', '')}
            required={getDefaultField('lastName')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="middleName">
          <TextfieldComponent
            name="middleName"
            label={polyglot.t('BasicInformationForm.middleName')}
            value={formik.values.middleName}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.middleName && !!formik.errors.middleName}
            helperText={(formik.touched.middleName && formik.errors.middleName) ?? ' '}
            clearText={() => formik.setFieldValue('middleName', '')}
            required={getDefaultField('middleName')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="displayName">
          <TextfieldComponent
            name="displayName"
            label={polyglot.t('BasicInformationForm.displayName')}
            value={formik.values.displayName}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.displayName && !!formik.errors.displayName}
            helperText={(formik.touched.displayName && formik.errors.displayName) ?? ' '}
            clearText={() => formik.setFieldValue('displayName', '')}
            required={getDefaultField('displayName')?.isRequired}
          />
        </ProfileField>
        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
