import { Box, SxProps } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';
import { spacing } from '@v2/styles/spacing.styles';

type CardColor = 'secondary' | 'warning' | 'success';

const chipBgColorMapping: Record<CardColor, string> = {
  secondary: themeColors.Background,
  warning: themeColors.PastelYellow,
  success: themeColors.PastelGreen,
};
const chipColorMapping: Record<CardColor, string> = {
  secondary: themeColors.DarkGrey,
  warning: themeColors.RedDark,
  success: themeColors.DarkGreen,
};

interface FieldCardProps {
  name: string;
  value: string;
  subValue?: string;
  action?: () => void;
  color: CardColor;
  sx?: SxProps;
}

export const FieldCard = ({ name, value, subValue, action, color, sx = {} }: FieldCardProps) => {
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        height: '64px',
        minWidth: '115px',
        borderRadius: '10px',
        p: spacing.p10,
        border: `1px solid ${themeColors.GreyMiddle}`,
        cursor: action ? 'pointer' : undefined,
        ':hover': {
          bgcolor: action ? themeColors.TableHover : 'initial',
        },
        ...sx,
      }}
      onClick={action}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5, width: '100%' }}>
        <Typography
          variant="captionSmall"
          sx={{
            paddingX: '3px',
            paddingY: '1px',
            borderRadius: radius.br8,
            width: 'fit-content',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            backgroundColor: chipBgColorMapping[color],
            color: chipColorMapping[color],
          }}
        >
          {name}
        </Typography>
        <Typography variant="title3">{value}</Typography>
        {subValue && <Typography variant="caption">{subValue}</Typography>}
      </Box>
    </Box>
  );
};
