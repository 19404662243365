import Polyglot from 'node-polyglot';

import { ApproverGroup, PresetApprover } from '@/v2/components/approver-select/approver-select.interface';
import { OptionObj } from '@/v2/components/user-select-type/user-select.component';
import { DItem } from '@/v2/feature/notification/notification-settings/features/notification-setting.page';
import {
  ATTENDANCE_NOTIFICATIONS,
  DOCUMENT_NOTIFICATIONS,
  DOMAINS_WITH_BEFORE,
  DefaultNotifications,
  EMPLOYEE_INFO_NOTIFICATIONS,
  NotificationCategory,
  NotificationChannel,
  NotificationForTable,
  NotificationHumanName,
  NotificationSendTo,
  NotificationSystemName,
  NotificationWhen,
  ProfileNotifications,
  UserNotifications,
  DEVICE_NOTIFICATIONS,
} from '@/v2/feature/notification/notification-settings/notification.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';

export const getRecieverList = (p: ProfileNotifications): string => {
  const recieversList = [];
  if (p.sendAdmin) recieversList.push('Admin');
  if (p.sendManager) recieversList.push('Manager');

  return recieversList.length > 0 ? recieversList.toString().replaceAll(',', ', ') : 'NA';
};

export const getCategoryForProfileNotification = (
  polyglot: Polyglot,
  domain: NotificationSystemName
): NotificationCategory => {
  const polyglotPrefix = `NotificationSettingTable.category.`;
  let finalKey;
  if (EMPLOYEE_INFO_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.EMPLOYEE_INFORMATION}`;
    return polyglot.t(finalKey) as NotificationCategory;
  } else if (DOCUMENT_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.DOCUMENTS}`;
    return polyglot.t(finalKey) as NotificationCategory;
  } else if (ATTENDANCE_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.ATTENDANCE}`;
    return polyglot.t(finalKey) as NotificationCategory;
  } else if (DEVICE_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.DEVICES}`;
    return polyglot.t(finalKey) as NotificationCategory;
  } else {
    finalKey = `${polyglotPrefix}${NotificationCategory.EMPLOYEE_INFORMATION}`;
    return polyglot.t(finalKey) as NotificationCategory;
  }
};

const getWhenForProfileNotification = (
  polyglot: Polyglot,
  domain: NotificationSystemName,
  daysBefore: number | null,
  name?: string,
  weeklyDay?: number | null
): string => {
  let polyglotPrefix = 'NotificationSettingTable.whenColumnValue.';
  let beforeWhenFilter = `${polyglotPrefix}daysBefore`;
  let polyglotDaysBeforeWhen = polyglot.t(beforeWhenFilter);
  if (
    (name &&
      (name as DefaultNotifications) === DefaultNotifications.onboardingReminder &&
      domain === NotificationSystemName.onboarding) ||
    domain === NotificationSystemName.securityDigest
  ) {
    // handle cases for onboarding reminder notification
    return daysBefore === null && daysBefore !== 0
      ? polyglot.t(`${polyglotPrefix}${NotificationWhen.ONCE}`)
      : daysBefore === 0 && weeklyDay === null
      ? polyglot.t(`${polyglotPrefix}${NotificationWhen.DAILY}`)
      : daysBefore === 0 && weeklyDay && weeklyDay >= 0
      ? polyglot.t(`${polyglotPrefix}${NotificationWhen.WEEKLY}`)
      : '';
  }
  return DOMAINS_WITH_BEFORE.includes(domain as NotificationSystemName) && daysBefore && daysBefore > 0
    ? `${daysBefore} ${polyglotDaysBeforeWhen}`
    : polyglot.t(`${polyglotPrefix}${NotificationWhen.IMMEDIATE}`);
};

const getSendToAndApproverGroupForMapping = (
  polyglot: Polyglot,
  name: string | undefined, // required for onboarding reminders
  sendAdmin: boolean,
  sendManager: boolean,
  sendSpecificUsers: boolean,
  specificUserEmails: readonly number[],
  cachedUsers: readonly CachedUser[]
): [string, ApproverGroup] => {
  const sendToValue = [];
  const sendToPolyglotPrefix = 'NotificationSettingTable.sendToColumnValue.';
  const sendToApproverPresetValue: PresetApprover[] = [];

  if (sendAdmin) {
    sendToValue.push(NotificationSendTo.ADMIN);
    sendToApproverPresetValue.push('admin');
  }
  if (sendManager) {
    sendToValue.push(NotificationSendTo.MANAGER);
    sendToApproverPresetValue.push('manager:1');
  }
  if (sendSpecificUsers) sendToValue.push(NotificationSendTo.SPECIFIC);

  const presets: PresetApprover[] = sendToValue.includes(NotificationSendTo.EVERYONE)
    ? ['everyone']
    : sendToApproverPresetValue;
  const userIds = sendToValue.includes(NotificationSendTo.SPECIFIC)
    ? cachedUsers.filter((user) => specificUserEmails?.includes(user.userId)).map((user) => user.userId)
    : [];

  const APPROVER_GROUP_FOR_NOTIFICATION: ApproverGroup = {
    type: 'anyof',
    autoApprove: false,
    presets: presets,
    userIds: userIds,
  };
  const sendTo =
    name &&
    ((name as DefaultNotifications) === DefaultNotifications.onboardingReminder || name === 'User Security Digest')
      ? polyglot.t(`${sendToPolyglotPrefix}allEmployees`)
      : sendToValue.map((v) => polyglot.t(`${sendToPolyglotPrefix}${v}`)).join(', ');

  return [sendTo, APPROVER_GROUP_FOR_NOTIFICATION];
};

export const mapUserNotificationsToNotificationForTable = (
  polyglot: Polyglot,
  userNotifications: UserNotifications,
  cachedUsers: readonly CachedUser[],
  slackChannels?: readonly DItem[]
): NotificationForTable[] => {
  const notificationTableFormat: NotificationForTable[] = [];
  const emailChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.EMAIL}`);
  const slackChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.SLACK_CHANNEL}`);
  for (const type in userNotifications) {
    if (Object.prototype.hasOwnProperty.call(userNotifications, type)) {
      const notificationEvent = userNotifications[type as keyof UserNotifications];
      const slackChannelValue: string[] = [];
      const { enabled, settings } = notificationEvent;
      const channelValue = [];
      if (settings?.email) channelValue.push(emailChannel);
      if (settings?.slack) {
        channelValue.push(slackChannel);
        if (settings?.slackChannels?.length > 0) {
          settings.slackChannels.forEach((slackChannel) => {
            const channel = slackChannels?.find((c) => c.value === slackChannel);
            if (channel) {
              slackChannelValue.push(`#${channel.label}`);
            }
          });
        }
      }
      const sendToPolyglotPrefix = 'NotificationSettingTable.sendToColumnValue.';
      const hasSlackChannels = settings?.slack && slackChannelValue?.length > 0;
      const selectedSlackChannels = slackChannels?.filter((c) => settings?.slackChannels?.includes(c.value));
      const sendToValue = [];
      if (settings?.emails && settings?.emails?.length > 0) sendToValue.push(NotificationSendTo.SPECIFIC);
      if (!settings?.emails || settings?.emails?.length === 0) sendToValue.push(NotificationSendTo.EVERYONE);
      const APPROVER_GROUP_FOR_NOTIFICATION: ApproverGroup = {
        type: 'anyof',
        autoApprove: false,
        presets: enabled && sendToValue.includes(NotificationSendTo.EVERYONE) ? ['everyone'] : [],
        userIds:
          enabled && sendToValue.includes(NotificationSendTo.SPECIFIC)
            ? cachedUsers.filter((user) => settings?.emails?.includes(user.userId)).map((user) => user.userId)
            : [],
      };

      let polyglotWhenPrefix = 'NotificationSettingTable.whenColumnValue.';

      const tableEntry: NotificationForTable = {
        type: polyglot.t(`NotificationSettingTable.category.${NotificationCategory.PEOPLE}`) as NotificationCategory,
        name: polyglot.t(`NotificationSettingTable.name.${type as keyof typeof NotificationHumanName}`),
        systemName: NotificationSystemName[type as keyof typeof NotificationSystemName],
        status: enabled,
        sendTo: sendToValue.map((v) => polyglot.t(`${sendToPolyglotPrefix}${v}`)).join(', '),
        sendToEditValue: APPROVER_GROUP_FOR_NOTIFICATION,
        when: polyglot.t(`${polyglotWhenPrefix}${NotificationWhen.IMMEDIATE}`), // defaults to immediate for all user notifications
        cronHour: settings?.cronHour,
        emailEnabled: settings?.email,
        slackEnabled: settings?.slack,
        slackChannels: selectedSlackChannels ?? [],
        channel:
          channelValue?.length > 0
            ? channelValue
                .join(', ')
                .concat(hasSlackChannels ? ' (' : '')
                .concat(`${hasSlackChannels ? slackChannelValue.join(', ') : ''}`)
                .concat(hasSlackChannels ? ')' : '')
            : '',
      };

      notificationTableFormat.push(tableEntry);
    }
  }

  return notificationTableFormat;
};

const convertNameToNotificationKey = (value?: string): string => {
  if (!value) return '';
  const words = value.split(' ');
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  return camelCaseWords.join('');
};

export const mapProfileNotificationsToNotificationForTable = (
  polyglot: Polyglot,
  profileNotifications: ProfileNotifications[],
  cachedUsers: readonly CachedUser[],
  slackChannels?: readonly DItem[]
): NotificationForTable[] => {
  const notificationTableFormat: NotificationForTable[] = [];
  const emailChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.EMAIL}`);
  const slackBot = polyglot.t(`NotificationSettingTable.channelColumnValue.SlackBot`);
  const slackChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.SLACK_CHANNEL}`);
  const defaultTypes = profileNotifications.filter((p) => p.domain !== 'profileNotification');
  for (const profileNotification of defaultTypes) {
    const {
      id,
      domain,
      name,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      email,
      slack,
      slackChannels,
      specificUserEmails,
      createdAt,
      daysBefore,
      weeklyDay,
    } = profileNotification;

    const channelValue: string[] = [];
    if (email) channelValue.push(emailChannel);
    if (slack) channelValue.push(slackChannel);
    if (slackChannels?.length > 0) {
      const slackChannelNames = slackChannels.map((slackChannel) => `#${slackChannel}`);
      channelValue.push(`Slack (${slackChannelNames.join(', ')})`);
    }

    const [sendToValue, APPROVER_GROUP_FOR_NOTIFICATION] = getSendToAndApproverGroupForMapping(
      polyglot,
      name,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      specificUserEmails,
      cachedUsers
    );

    const typeForProfileNotification = getCategoryForProfileNotification(polyglot, domain as NotificationSystemName);

    const polyglotPrefix = `NotificationSettingTable.name.`;
    const tableEntry: NotificationForTable = {
      id,
      type: typeForProfileNotification,
      name:
        domain in NotificationHumanName
          ? polyglot.t(`${polyglotPrefix}${domain as keyof typeof NotificationHumanName}`)
          : name && convertNameToNotificationKey(name) in DefaultNotifications
          ? polyglot.t(`${polyglotPrefix}${convertNameToNotificationKey(name)}`)
          : '',
      systemName: domain as NotificationSystemName,
      status: slack || email,
      sendTo: sendToValue,
      sendToEditValue: APPROVER_GROUP_FOR_NOTIFICATION,
      when: getWhenForProfileNotification(polyglot, domain as NotificationSystemName, daysBefore, name, weeklyDay),
      channel: channelValue.join(', '),
      emailEnabled: email,
      slackEnabled: false,
      slackChannels: [],
      createdAt,
      daysBefore,
      weeklyDay,
    };

    notificationTableFormat.push(tableEntry);
  }

  const customTypes = profileNotifications.filter((p) => p.domain === 'profileNotification');
  for (const profileNotification of customTypes) {
    const {
      id,
      domain,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      email,
      slack,
      specificUserEmails,
      createdAt,
      daysBefore,
      name,
      cronHour,
      fieldId,
      formId,
      notificationText,
      sendSlackBot,
    } = profileNotification;

    const channelValue = [];
    const slackChannelValue: string[] = [];
    if (email) channelValue.push(emailChannel);
    if (slack && sendSlackBot) channelValue.push(slackBot);
    if (slack) {
      if (!sendSlackBot) channelValue.push(slackChannel);
      if (profileNotification.slackChannels?.length > 0) {
        profileNotification.slackChannels.forEach((slackChannel) => {
          const channel = slackChannels?.find((c) => c.value === slackChannel);
          if (channel) {
            slackChannelValue.push(`#${channel.label}`);
          }
        });
      }
    }
    const hasSlackChannels = slack && slackChannelValue?.length > 0;
    const selectedSlackChannels = slackChannels?.filter((c) => profileNotification?.slackChannels?.includes(c.value));

    const [sendToValue, APPROVER_GROUP_FOR_NOTIFICATION] = getSendToAndApproverGroupForMapping(
      polyglot,
      undefined,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      specificUserEmails,
      cachedUsers
    );

    const typeForProfileNotification = NotificationCategory.PROFILE;

    const tableEntry: NotificationForTable = {
      id,
      type: typeForProfileNotification,
      name: name as NotificationHumanName,
      systemName: domain as NotificationSystemName,
      status: slack || email,
      sendTo: sendToValue,
      sendToEditValue: APPROVER_GROUP_FOR_NOTIFICATION,
      when: getWhenForProfileNotification(polyglot, domain as NotificationSystemName, daysBefore),
      emailEnabled: email,
      notificationText,
      slackEnabled: slack,
      slackChannels: selectedSlackChannels ?? [],
      channel:
        channelValue?.length > 0
          ? channelValue
              .join(', ')
              .concat(hasSlackChannels ? ' (' : '')
              .concat(`${hasSlackChannels ? slackChannelValue.join(', ') : ''}`)
              .concat(hasSlackChannels ? ')' : '')
          : '',
      createdAt,
      daysBefore,
      cronHour,
      employeeTriggerEvent: `${fieldId}/${formId}`,
      sendSlackBot,
    };

    notificationTableFormat.push(tableEntry);
  }

  return notificationTableFormat;
};

export const addDefaultUserDateFields = (existingOptions: OptionObj[]) => {
  const result = [...existingOptions];
  // default employee fields;
  result.push({ value: 'dob', label: 'Birthday (anniversary of)' });
  result.push({ value: 'startDate', label: 'Start date (anniversary of)' });
  result.push({ value: 'leaveDate', label: 'Leave date (anniversary of)' });
  return result;
};
