import ReactApexChart from 'react-apexcharts';

import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import './apex-charts.styles.css';

export interface ChartDonutProps {
  series: number[];
  labels: string[];
  percentage?: boolean;
}

export function ChartDonut({ series, labels, percentage = false }: ChartDonutProps) {
  const chartOptions = {
    chart: {
      fontFamily: 'Inter, sans-serif !important',
      toolbar: {
        show: false,
      },
      offsetY: 5,
      width: '100%',
    },
    dataLabels: {
      enabled: false,
    },
    labels: labels,
    stroke: { show: false },
    legend: {
      show: true,
      horizontalAlign: 'right' as 'left' | 'center' | 'right',
      position: 'right' as 'top' | 'right' | 'bottom' | 'left',
      fontFamily: 'Inter, sans-serif !important',
      fontWeight: 400,
      fontSize: '12px',
      labels: { colors: [themeColors.DarkGrey] },
      offsetY: -20,
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 16,
        customHTML: undefined,
        onClick: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val}`,
      },
      style: { fontFamily: 'Inter, sans-serif !important', fontWeight: 400, fontSize: '12px' },
      custom: function ({ series, seriesIndex, w }: { series: any; seriesIndex: any; w: any }) {
        var data = w?.globals?.seriesNames[seriesIndex] ?? '';
        return `<div class="arrow_box">${series[seriesIndex]}${percentage ? '%' : ` ${data}`}</div>`;
      },
    },
    plotOptions: { pie: { customScale: 0.9, offsetY: 20, donut: { size: '75%' } } },
    show: true,
    label: 'Total',
    colors: [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    ...themeFonts.caption,
  };

  return <ReactApexChart type="donut" series={series} options={chartOptions} height="250px" />;
}
