import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { RequestFormComponent } from '@/v2/feature/requests/features/request-forms/components/request-form.component';
import { CreateRequestFormTemplate } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type PreviewPageProps = {
  readonly template: CreateRequestFormTemplate;
  readonly saving?: boolean;
  readonly onNext: () => void;
  readonly approvalRules: ApprovalRuleDto[];
};

export const PreviewPage = ({ template, saving, onNext, approvalRules }: PreviewPageProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Stack sx={{ flex: 1, alignItems: 'center', width: '100%' }}>
      <Stack sx={{ width: '500px', gap: spacing.g30 }}>
        <Typography variant="title2">{polyglot.t('PreviewPage.preview')}</Typography>
        <Typography variant="caption">{polyglot.t('PreviewPage.previewDesc')}</Typography>

        <Stack sx={{ mt: spacing.mt20 }}>
          <RequestFormComponent
            template={template}
            previewMode
            sx={{
              p: '30px 50px',
              m: '-30px -50px',
              border: '1px solid #00000020',
              borderRadius: '12px',
            }}
            approvalRules={approvalRules}
          />
        </Stack>
        <Box sx={{ mt: spacing.mt40 }}>
          <LoaderButton
            sizeVariant="large"
            colorVariant="primary"
            type="button"
            fullWidth
            loading={saving}
            onClick={() => onNext()}
          >
            {polyglot.t('PreviewPage.finish')}
          </LoaderButton>
        </Box>
      </Stack>
    </Stack>
  );
};
