import { AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import { hhmmTimeFormat, isoDateFormat } from '@v2/infrastructure/date/date-format.util';
import * as yup from 'yup';

export const getAttendanceImportValidationSchema = (attendanceTypes: readonly AttendanceTypeDto[]) => {
  const allowedAttendanceTypes = attendanceTypes.map((aT) => aT.name);

  return yup.object({
    userId: yup.number().required('Assigned User is required'),
    attendanceType: yup
      .string()
      .oneOf(
        allowedAttendanceTypes,
        `Allowed values for Attendance Type are ${allowedAttendanceTypes.map((v) => `"${v}"`).join(', ')}.`
      )
      .required('Attendance Type is required'),
    logDate: yup.string().matches(isoDateFormat, 'Enter a valid date value (YYYY-MM-DD)').required('Date is required'),
    start: yup.string().matches(hhmmTimeFormat, 'Enter a valid date (HH:MM)').required('Start hour is required'),
    end: yup
      .string()
      .matches(hhmmTimeFormat, 'Enter a valid date (HH:MM)')
      .test('startBeforeEnd', 'End hour is before Start hour', function (value: string | undefined): boolean {
        if (!value) return true;
        const { start } = this.parent;
        return start < value;
      })
      .required('End hour is required'),
  });
};
