import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { TableSearch } from '@v2/components/table/table-search.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import {
  DeviceConditionValueLabelOptions,
  DeviceOSValueLabelOptions,
  DeviceShopFilter,
  DeviceTypeFilterOptions,
} from '@v2/feature/device/device.util';
import { filterStringToObject } from '@v2/feature/user/user.util';
import { spacing } from '@v2/styles/spacing.styles';

import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceEndpoints } from '@/v2/feature/device/device.api';
import { DeviceModelDto } from '@/v2/feature/device/device.dto';
import {
  DevicePreviewComponent,
  DevicePreviewSkeleton,
} from '@/v2/feature/device/features/devices-company/components/device-preview.component';
import { OrderDevicesForm } from '@/v2/feature/device/features/devices-company/components/order-devices-form.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { primarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface DevicesOrdersPageProps {
  readonly loading: boolean;
  readonly refresh: () => void;
  readonly reach: 'company' | 'me';
}

//we need to keep swr calls separate from the main content for better loading and ux
const GetDeviceList = ({
  cart,
  setCart,
}: {
  cart: DeviceModelDto[];
  setCart: Dispatch<SetStateAction<DeviceModelDto[]>>;
}) => {
  const { data: availableDeviceModels } = useApiClient(DeviceEndpoints.getDeviceModelsByCompanyId());
  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState<string>('');
  const [filterTypes, setFilterTypes] = useState({});

  const getRams = useCallback(() => {
    let filteredDeviceModels = availableDeviceModels ?? [];
    const nonNullRamValues: number[] = filteredDeviceModels
      .map((row) => row.ram)
      .filter((value): value is number => value !== null);
    const uniqueRamValues = Array.from(new Set(nonNullRamValues));
    uniqueRamValues.sort((a, b) => a - b);
    const ramValueLabelOptions = uniqueRamValues.map((value) => ({
      label: `${value}GB`,
      value: value,
    }));
    return ramValueLabelOptions;
  }, [availableDeviceModels]);

  const getStorage = useCallback(() => {
    let filteredDeviceModels = availableDeviceModels ?? [];
    const nonNullValues: number[] = filteredDeviceModels
      .map((row) => row.storage)
      .filter((value): value is number => value !== null);
    const uniqueValues = Array.from(new Set(nonNullValues));
    uniqueValues.sort((a, b) => a - b);
    const storageValueLabelOptions = uniqueValues.map((value) => ({
      label: `${value}GB`,
      value: value,
    }));
    return storageValueLabelOptions;
  }, [availableDeviceModels]);

  const getManufacturer = useCallback(() => {
    let filteredDeviceModels = availableDeviceModels ?? [];
    const nonNullValues: string[] = filteredDeviceModels
      .map((row) => row.manufacturer)
      .filter((value): value is string => value !== null);
    const uniqueValues = Array.from(new Set(nonNullValues));
    uniqueValues.sort((a, b) => a.localeCompare(b));
    const manufacturerValueLabelOptions = uniqueValues.map((value) => ({
      label: `${value}`,
      value: value.toLowerCase(),
    }));
    return manufacturerValueLabelOptions;
  }, [availableDeviceModels]);

  const getFilterOptions = useCallback(async () => {
    const FILTERS = [
      DeviceShopFilter.Manufacturer,
      DeviceShopFilter.Type,
      DeviceShopFilter.RAM,
      DeviceShopFilter.Storage,
      DeviceShopFilter.OS,
      DeviceShopFilter.Condition,
    ];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case DeviceShopFilter.Manufacturer:
          filters = { ...filters, [filter]: getManufacturer() };
          break;
        case DeviceShopFilter.Type:
          filters = { ...filters, [filter]: DeviceTypeFilterOptions };
          break;
        case DeviceShopFilter.RAM:
          filters = { ...filters, [filter]: getRams() };
          break;
        case DeviceShopFilter.Storage:
          filters = { ...filters, [filter]: getStorage() };
          break;
        case DeviceShopFilter.OS:
          filters = { ...filters, [filter]: DeviceOSValueLabelOptions };
          break;
        case DeviceShopFilter.Condition:
          filters = { ...filters, [filter]: DeviceConditionValueLabelOptions };
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }, [getRams, getStorage, getManufacturer]);

  const tableData = useMemo(() => {
    let filteredDeviceModels = availableDeviceModels ?? [];
    if (availableDeviceModels && searchInput) {
      filteredDeviceModels = filteredDeviceModels.filter((deviceModel) => {
        const modelName = deviceModel.modelName;
        const id = deviceModel.id;

        return (
          modelName?.toLowerCase().includes(searchInput.toLowerCase()) || String(id).includes(searchInput.toLowerCase())
        );
      });
    }

    if (availableDeviceModels && filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case DeviceShopFilter.Manufacturer: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.manufacturer && filterOptions[key]?.includes(model.manufacturer.toLowerCase())
              );
              break;
            }
            case DeviceShopFilter.Type: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.type && filterOptions[key]?.includes(model.type)
              );
              break;
            }
            case DeviceShopFilter.RAM: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.ram && filterOptions[key]?.includes(String(model.ram))
              );
              break;
            }
            case DeviceShopFilter.Storage: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.storage && filterOptions[key]?.includes(String(model.storage))
              );
              break;
            }
            case DeviceShopFilter.OS: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.os && filterOptions[key]?.includes(model.os.toLowerCase())
              );
              break;
            }
            case DeviceShopFilter.Condition: {
              // TODO: Add filter condition here when Refurbished devices will be available
              break;
            }
            default:
              break;
          }
        }
      }
    }

    return filteredDeviceModels;
  }, [availableDeviceModels, filterString, searchInput]);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  return (
    <Box>
      {availableDeviceModels && availableDeviceModels.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            gap: spacing.g5,
          }}
        >
          {filterTypes && (
            <CategoryFilters filterTypes={filterTypes} setFilterString={setFilterString} filterString={filterString} />
          )}
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value)} />
        </Box>
      )}
      <Grid
        container
        columnGap={{ xl: '40px', lg: '40px', md: '30px', sm: '20px', xs: '30px' }}
        rowGap={{ xl: '40px', lg: '40px', md: '30px', sm: '20px', xs: '30px' }}
        sx={{ marginTop: spacing.m30 }}
      >
        {tableData &&
          tableData.map((device) => (
            <Grid key={device.id} item xl={2} lg={3} md={4} sm={6} xs={8}>
              <DevicePreviewComponent deviceModel={device} cart={cart} setCart={setCart} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export const DeviceStorePage = ({ loading, refresh, reach }: DevicesOrdersPageProps) => {
  const [cart, setCart] = useState<DeviceModelDto[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Devices store');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>}
        actions={
          <Button sx={primarySmallBtn} onClick={() => setIsOpen(true)}>
            Cart : {cart.length}
          </Button>
        }
        showAction={cart.length > 0}
      />
      <ContentWrapper loading={loading}>
        <DeviceStoreView
          cartState={[cart, setCart]}
          orderFormOpen={isOpen}
          reach={reach}
          onClose={async () => {
            await refresh();
            setIsOpen(false);
            setCart([]);
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};

interface DeviceStoreViewProps {
  readonly reach: 'company' | 'me';
  readonly cartState: [DeviceModelDto[], Dispatch<SetStateAction<DeviceModelDto[]>>];
  readonly orderFormOpen: boolean;
  readonly onClose: (orderComplete: boolean) => Promise<void>;
  readonly possessionId?: number;
  readonly address?: string;
  sx?: SxProps<Theme>;
}

export const DeviceStoreView = ({
  cartState,
  orderFormOpen,
  reach,
  onClose,
  possessionId,
  sx,
}: DeviceStoreViewProps) => {
  const [cart, setCart] = cartState;

  return (
    <Box sx={sx}>
      <Suspense
        fallback={
          <Grid container spacing={{ lg: '40px', sm: '20px' }}>
            {[1, 2, 3, 4, 5, 6, 7].map((n) => (
              <Grid key={n} item xl={2} lg={3} md={4} sm={6} xs={8}>
                <DevicePreviewSkeleton />
              </Grid>
            ))}
          </Grid>
        }
      >
        <GetDeviceList cart={cart} setCart={setCart} />
      </Suspense>

      {cart[0] && (
        <DrawerModal isOpen={orderFormOpen} setIsOpen={() => onClose(false)}>
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="90%"
                height="90vh"
                sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
              />
            }
          >
            <OrderDevicesForm deviceModel={cart[0]} close={onClose} reach={reach} possessionId={possessionId} />
          </Suspense>
        </DrawerModal>
      )}
    </Box>
  );
};
