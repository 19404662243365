import { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';

import { UploadInput } from '@/component/forms/UploadInput';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { spacing } from '@/v2/styles/spacing.styles';

export type PayrunImportResultProblem = { row: number; employeeId: string | null; description: string };

export type PayrunImportResult = {
  id: string;
  problems: PayrunImportResultProblem[];
};

interface ImportIncomeDrawerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  payrun: GlobalPayrun;
  onImportSuccess: () => void;
  onUploadFailed: () => void;
}

export const ImportPayrunDrawer = ({
  isOpen,
  setIsOpen,
  payrun,
  onImportSuccess,
  onUploadFailed,
}: ImportIncomeDrawerProps) => {
  const { payrollId, taxYear, payPeriod, period } = payrun;
  const [problems, setProblems] = useState<PayrunImportResultProblem[]>([]);
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">Import payrun</Typography>

        <Typography variant="caption">Import income and deductions for the current payrun in bulk</Typography>

        <UploadInput<PayrunImportResult>
          endpoint={`/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/import`}
          onChange={(response, file, status) => {
            setProblems([]);
            if (response?.problems.length) {
              setProblems(response.problems);
              return;
            }
            if (response?.id) {
              onImportSuccess();
              return;
            }
            if (status === 'error_upload') {
              onUploadFailed();
              return;
            }
          }}
        />

        {problems.length > 0 && (
          <Stack sx={{ mt: spacing.s1 }}>
            <Typography variant="caption" sx={{ mb: spacing.m10 }}>
              There were problems found in the import file:
            </Typography>
            {problems
              .sort((a, b) => a.row - b.row)
              .map((problem, idx) => (
                <Typography key={idx} variant="caption">
                  {`Entry ${problem.row}: ${problem.description}`}
                </Typography>
              ))}
          </Stack>
        )}
      </Box>
    </DrawerModal>
  );
};
