import { Redirect, Switch } from 'react-router-dom';

import { PaymentsListPage } from './pages/payments-list.page';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { PAYMENT_COMPANY_OVERVIEW_ROUTE, PAYMENT_COMPANY_ROUTE } from '@/lib/routes';
// import { NewExpensePage } from '@/v2/feature/payments/pages/new-expense.page';
// import { NewInvoicePage } from '@/v2/feature/payments/pages/new-invoice.page';

export const PaymentsCompanyRouter = (): JSX.Element => (
  <Switch>
    <RouteScopesControl scopes={['payments:all']} path={PAYMENT_COMPANY_OVERVIEW_ROUTE} exact>
      <PaymentsListPage />
    </RouteScopesControl>
    {/* <RouteScopesControl scopes={['payments:all']} path={PAYMENT_COMPANY_NEW_INVOICE_ROUTE} exact>
      <NewInvoicePage />
    </RouteScopesControl>
    <RouteScopesControl scopes={['payments:all']} path={PAYMENT_COMPANY_NEW_EXPENSE_ROUTE} exact>
      <NewExpensePage />
    </RouteScopesControl> */}
    <Redirect from={PAYMENT_COMPANY_ROUTE} to={PAYMENT_COMPANY_OVERVIEW_ROUTE} exact />
  </Switch>
);
