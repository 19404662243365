import { Box, Typography } from '@mui/material';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType } from '@v2/feature/device/device.interface';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { themeFonts } from '@v2/styles/fonts.styles';

import {
  deviceListSx,
  deviceSx,
  deviceTermSx,
  devicetitleSx,
  deviceValueSx,
} from '@/v2/feature/device/features/device-cards/components/devices-styles.layout';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface DevicePossessionInformationCardProps {
  readonly devicePossession: DevicePossessionDto;
}

export const DevicePossessionInformationCard = ({ devicePossession }: DevicePossessionInformationCardProps) => {
  const { getCachedUserById } = useCachedUsers();

  switch (devicePossession.possessionType) {
    case DevicePossessionType.User:
      const user = getCachedUserById(devicePossession.possessionId);
      return (
        <Box component="header" sx={{ width: '100%' }}>
          <Typography sx={devicetitleSx}>In use by</Typography>
          <Box component="dl" sx={deviceListSx}>
            {user && (
              <Box sx={deviceSx}>
                <Typography component="dt" variant="body2" sx={deviceTermSx}>
                  Employee
                </Typography>
                <Typography component="dd" variant="body2" sx={deviceValueSx}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar userId={user.userId} size="xxsmall" />
                    <Typography sx={{ ...themeFonts.title4, marginLeft: spacing.m10 }}>{user.displayName}</Typography>
                  </Box>
                </Typography>
              </Box>
            )}

            {user?.role?.department?.name && (
              <Box sx={deviceSx}>
                <Typography component="dt" variant="body2" sx={deviceTermSx}>
                  Department
                </Typography>
                <Typography component="dd" variant="body2" sx={deviceValueSx}>
                  {user?.role.department.name}
                </Typography>
              </Box>
            )}
            {user?.role?.jobTitle && (
              <Box sx={deviceSx}>
                <Typography component="dt" variant="body2" sx={deviceTermSx}>
                  Role
                </Typography>
                <Typography component="dd" variant="body2" sx={deviceValueSx}>
                  {user?.role.jobTitle}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      );

    case DevicePossessionType.CompanySite:
      return (
        <Box component="header" sx={{ width: '100%' }}>
          <Typography sx={devicetitleSx}>In use by</Typography>
          <Box component="section">
            <Box component="dl" sx={deviceListSx}>
              <Box sx={deviceSx}>
                <Typography component="dt" variant="body2" sx={deviceTermSx}>
                  Owner
                </Typography>
                <Typography component="dd" variant="body2" sx={deviceValueSx}>
                  Company Site
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    case DevicePossessionType.ZeltStorage:
      return (
        <Box component="header" sx={{ width: '100%' }}>
          <Typography sx={devicetitleSx}>In use by</Typography>
          <Box component="section">
            <Box component="dl" sx={deviceListSx}>
              <Box sx={deviceSx}>
                <Typography component="dt" variant="body2" sx={deviceTermSx}>
                  Owner
                </Typography>
                <Typography component="dd" variant="body2" sx={deviceValueSx}>
                  Zelt Storage
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    case DevicePossessionType.ZeltStock:
      return (
        <Box component="header" sx={{ width: '100%' }}>
          <Typography sx={devicetitleSx}>In use by</Typography>
          <Box component="section">
            <Box component="dl" sx={deviceListSx}>
              <Box sx={deviceSx}>
                <Typography component="dt" variant="body2" sx={deviceTermSx}>
                  Owner
                </Typography>
                <Typography component="dd" variant="body2" sx={deviceValueSx}>
                  Zelt Stock
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    default:
      // This case should never happen
      return (
        <Box component="header" sx={{ width: '100%' }}>
          <Typography sx={devicetitleSx}>In use by</Typography>
          <Box component="section">
            <Typography sx={{ ...themeFonts.title4, mb: 2 }}>Possession Information not available.</Typography>
          </Box>
        </Box>
      );
  }
};
