import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { OneOffPaymentDto } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { PayItemAPI } from '@v2/feature/payroll/features/pay-item/pay-item.api';
import { round2Digits } from '@v2/util/number.util';
import { tableIconButtonSx, tableWhiteIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { ScopesControl } from '@/component/widgets/Scopes';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { AbsenceViewDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page';
import { TrackTimeDrawer } from '@v2/feature/attendance/company/components/track-time-drawer.component';
import { ViewUserCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/view-user-custom-benefit-drawer.component';
import { Expense } from '@/v2/feature/payments/payments.interface';
import { ExpenseAPI } from '@/api-client/expense.api';
import { ExpenseModal } from '@/v2/feature/payments/pages/components/expense-modal.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly oneOffPayment: OneOffPaymentDto | null;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
  readonly showDelete?: boolean;
}

export const ViewUpcomingPayItemDrawer = ({
  isOpen,
  setIsOpen,
  oneOffPayment,
  refresh,
  onClose,
  showDelete = false,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      {oneOffPayment ? (
        <ViewUpcomingPayItemDrawerContent
          oneOffPayment={oneOffPayment}
          refresh={refresh}
          setIsOpen={setIsOpen}
          onClose={onClose}
          showDelete={showDelete}
        />
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly oneOffPayment: OneOffPaymentDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly showDelete: boolean;
}

export const ViewUpcomingPayItemDrawerContent = ({
  oneOffPayment,
  refresh,
  setIsOpen,
  onClose,
  showDelete,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isAbsenceOpen, setIsAbsenceOpen] = useState(false);
  const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);
  const [isBenefitOpen, setIsBenefitOpen] = useState(false);
  const [isExpenseOpen, setIsExpenseOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<Expense | undefined>(undefined);

  const finalAmount = useMemo(() => {
    return round2Digits(Number(oneOffPayment.amount) * Number(oneOffPayment.multiplier));
  }, [oneOffPayment.amount, oneOffPayment.multiplier]);

  const deleteOneOffPayment = useCallback(async () => {
    if (!showDelete || !oneOffPayment.id) {
      // TODO: @polyglot-later
      showMessage('This payment cannot be deleted.', 'error');
      return;
    }
    try {
      await PayItemAPI.deleteUpcomingPayItem(oneOffPayment.userId, oneOffPayment.id);

      await refresh();
      onClose();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [showDelete, oneOffPayment, refresh, setIsOpen, showMessage, polyglot, onClose]);

  const handleExpenseClick = async (oneOffPayment: OneOffPaymentDto) => {
    if (oneOffPayment.id) {
      const expenseObject = await ExpenseAPI.getExpenseForOneOffPaymentId(oneOffPayment.userId, oneOffPayment.id);
      setSelectedExpense(expenseObject);
      setIsExpenseOpen(true);
    }
  };

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* TODO: @polyglot-later */}
        <Typography variant="title2">Pay item</Typography>
        {showDelete && (
          <ScopesControl scopes={['payroll:all']} context={{ userId: oneOffPayment.userId }}>
            <IconButton sx={tableIconButtonSx} onClick={deleteOneOffPayment}>
              <Trash {...iconSize} />
            </IconButton>
          </ScopesControl>
        )}
      </Box>
      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.employee')}
        </Typography>
        <UserCell userId={oneOffPayment.userId} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
      </Box>

      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.date')}
        </Typography>
        <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
          {oneOffPayment.date}
        </Typography>
      </Box>

      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.description')}
        </Typography>
        <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
          {oneOffPayment.description}
        </Typography>
      </Box>

      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('PayItemModule.payCode')}
        </Typography>
        <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
          {oneOffPayment.payCode}
        </Typography>
      </Box>

      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('PayItemModule.totalAmount')}
        </Typography>
        <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
          {finalAmount}
        </Typography>
      </Box>

      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('PayItemModule.amount')}
        </Typography>
        <Typography variant="caption" color="DarkGrey">
          {oneOffPayment.amount}
        </Typography>
      </Box>

      <Box sx={{ my: spacing.m20 }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('PayItemModule.payCodeMultiplier')}
        </Typography>
        <Typography variant="caption" color="DarkGrey">
          {oneOffPayment.multiplier}
        </Typography>
      </Box>

      {(oneOffPayment.autogenerated || oneOffPayment.createdBy) && (
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('PayItemModule.source')}
          </Typography>
          {oneOffPayment.autogenerated && oneOffPayment.absenceId ? (
            <ViewSourceDrawer
              name={polyglot.t('PayItemModule.autogeneratedFromAbsence')}
              onClick={() => setIsAbsenceOpen(true)}
            />
          ) : oneOffPayment.autogenerated && oneOffPayment.attendanceId ? (
            <ViewSourceDrawer
              name={polyglot.t('PayItemModule.autogeneratedFromAttendance')}
              onClick={() => setIsAttendanceOpen(true)}
            />
          ) : oneOffPayment.autogenerated && oneOffPayment.userCustomBenefitPaymentId ? (
            <ViewSourceDrawer
              name={polyglot.t('PayItemModule.autogeneratedFromBenefit')}
              onClick={() => setIsBenefitOpen(true)}
            />
          ) : oneOffPayment.autogenerated && oneOffPayment.expenseId ? (
            <ViewSourceDrawer
              name={polyglot.t('PayItemModule.autogeneratedFromExpense')}
              onClick={() => handleExpenseClick(oneOffPayment)}
            />
          ) : oneOffPayment.createdBy ? (
            <UserCell userId={oneOffPayment.createdBy} />
          ) : null}
        </Box>
      )}

      {isAbsenceOpen && oneOffPayment.absence && (
        <AbsenceViewDrawer
          isOpen={isAbsenceOpen}
          setIsAbsenceDrawerViewOpen={setIsAbsenceOpen}
          absence={oneOffPayment.absence}
          showCalendarLink={false}
          refresh={refresh}
        />
      )}

      {/* TODO: @attendance - here we use id, for absence we use absence object - should do the same always - ideally use id everywhere and only ask for object if the drawer is opened */}
      {isAttendanceOpen && oneOffPayment.attendanceId && (
        <TrackTimeDrawer
          isOpen={isAttendanceOpen}
          setIsOpen={setIsAttendanceOpen}
          refresh={async () => {}}
          attendanceId={oneOffPayment.attendanceId}
          userId={oneOffPayment.userId}
          view="company"
        />
      )}

      {isBenefitOpen && oneOffPayment.userCustomBenefit && (
        <ViewUserCustomBenefitDrawer
          isOpen={isBenefitOpen}
          setIsOpen={setIsBenefitOpen}
          userBenefit={oneOffPayment.userCustomBenefit}
        />
      )}

      {isExpenseOpen && selectedExpense && (
        <ExpenseModal
          isOpen={isExpenseOpen}
          setIsOpen={setIsExpenseOpen}
          selectedExpense={selectedExpense}
          onClose={() => setSelectedExpense(undefined)}
          onActionPerformed={async () => {}}
          currentUserIsAdmin={false}
        />
      )}
    </Box>
  );
};

const ViewSourceDrawer = ({ name, onClick }: { name: string; onClick: () => void }) => (
  <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
    <Typography variant="caption" color="DarkGrey">
      {name}
    </Typography>
    <IconButton sx={tableWhiteIconButtonSx} onClick={onClick} title="View">
      <Eye {...iconSize} />
    </IconButton>
  </Box>
);
