import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import {
  EXPENSES_TEAM_OVERVIEW_ROUTE,
  EXPENSES_TEAM_ROUTE,
  EXPENSES_TEAM_NEW_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE,
} from '@/lib/routes';
import { ExpensePage } from '@/v2/feature/expense/expense.page';
import { NewExpensePage } from '@/v2/feature/payments/pages/new-expense.page';

export const ExpenseTeamRouter = ({ isManager }: { readonly isManager: boolean }) => {
  return (
    <Switch>
      {isManager && (
        <RouteScopesHas scopes={['expenses:manager']} path={EXPENSES_TEAM_OVERVIEW_ROUTE} exact>
          <ExpensePage reach="team" />
        </RouteScopesHas>
      )}
      {isManager && (
        <RouteScopesHas scopes={['expenses:manager']} path={EXPENSES_TEAM_NEW_EXPENSE_ROUTE} exact>
          <NewExpensePage reach="team" />
        </RouteScopesHas>
      )}
      <RouteScopesHas scopes={['expenses:manager']} path={EXPENSE_TEAM_EDIT_EXPENSE_ROUTE}>
        <NewExpensePage reach="team" editMode={true} />
      </RouteScopesHas>
      <Redirect from={EXPENSES_TEAM_ROUTE} to={EXPENSES_TEAM_OVERVIEW_ROUTE} exact />
    </Switch>
  );
};
