import axios from 'axios';

import { JobLevel } from '@/v2/feature/job-level/job-level.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class JobLevelAPI {
  static async createJobTrack(
    jobLTrack: Pick<JobLevel, 'trackName' | 'levelName' | 'levelDescription'>
  ): Promise<void> {
    await axios.post('/apiv2/job-level/track', jobLTrack);
  }

  static async createJobLevel(jobLevel: Pick<JobLevel, 'trackName' | 'levelName' | 'levelDescription'>): Promise<void> {
    await axios.post('/apiv2/job-level/level', jobLevel);
  }

  static async updateJobLevel(
    jobLevel: Pick<JobLevel, 'levelId' | 'trackName' | 'levelName' | 'levelDescription'>
  ): Promise<void> {
    await axios.patch('/apiv2/job-level/level', jobLevel);
  }

  static async deleteJobLevel(levelId: number): Promise<void> {
    await axios.delete(`/apiv2/job-level/level/${levelId}`);
  }
}

export class JobLevelEndpoints {
  static countJobLevel(): Endpoint<{ count: number }> {
    return {
      url: '/apiv2/job-level/count',
    };
  }

  static listJobLevel(): Endpoint<JobLevel[]> {
    return {
      url: '/apiv2/job-level',
    };
  }
}
