import React, { ReactNode, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, InputAdornment } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import {
  EmployeeAeAssessment,
  PensionSchemeDto,
  UserPensionDto,
} from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { StaffologyEmployeeStates } from '@v2/feature/benefits/subfeature/pension/pension.interface';
import {
  getPensionLogoByProviderName,
  getPensionProviderFromUserPensionAndDefaultSchema,
  getWorkerGroupFromUserPensionAndDefaultSchema,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';

// TODO: @polyglot-later-staffology-pension
const STATE_OPTIONS = [
  // {
  //   value: StaffologyEmployeeStates.Automatic,
  //   label: 'Automatic',
  //   description: 'Assess the employee as normal.',
  // },
  {
    value: StaffologyEmployeeStates.Enrol,
    label: 'Enrol',
    description: 'Force the Enrolment in an AE Pension. State then reverts to Automatic',
  },
  {
    value: StaffologyEmployeeStates.OptOut,
    label: 'Opt Out',
    description:
      'The employee has chosen to opt-out of the Auto Enrolment process within 1 calendar month of Automatic Enrolment.',
  },
  {
    value: StaffologyEmployeeStates.OptIn,
    label: 'Opt In',
    description: 'The employee has chosen to opt-in to an Auto Enrolment pension.',
  },
  {
    value: StaffologyEmployeeStates.VoluntaryJoiner,
    label: 'Voluntary Joiner',
    description: 'A normally excluded employee has chosen to join an Auto Enrolment pension.',
  },
  {
    value: StaffologyEmployeeStates.ContractualPension,
    label: 'Contractual Pension',
    description: 'The employee is to be enrolled in an AE compatible pension under condition of contract.',
  },
  {
    value: StaffologyEmployeeStates.CeasedMembership,
    label: 'Ceased Membership',
    description:
      'The employee has chosen to leave the Auto Enrolment pension scheme after 1 calendar month of Auto Enrolment.',
  },
  {
    value: StaffologyEmployeeStates.Leaver,
    label: 'Leaver',
    description: 'The employee is leaving / has left employment.',
  },
  {
    value: StaffologyEmployeeStates.Excluded,
    label: 'Excluded',
    description: 'The employee is excluded from Auto Enrolment assessment.',
  },
];

const ASSESSMENTS_STATUS_OPTIONS = [
  { value: 'Eligible', label: 'Eligible Jobholder' },
  { value: 'NonEligible', label: 'Non-eligible Jobholder' },
  { value: 'Entitled', label: 'Entitled Worker' },
];

interface AddToPensionRetrospectivelyDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userPension: UserPensionDto;
  readonly pensionSchemes: PensionSchemeDto[];
  readonly lastAssessment: EmployeeAeAssessment | undefined;
  readonly refresh: () => Promise<void>;
}

export const AddToPensionRetrospectivelyDrawer = ({
  isOpen,
  setIsOpen,
  userPension,
  pensionSchemes,
  lastAssessment,
  refresh,
}: AddToPensionRetrospectivelyDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <AddToPensionRetrospectivelyDrawerContent
          userPension={userPension}
          pensionSchemes={pensionSchemes}
          lastAssessment={lastAssessment}
          refresh={refresh}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface EnrollToPensionRetrospectivelyForm {
  pensionProvider: string;
  workerGroupId: string;
  state: string;
  assessmentStatus: string;
  startDate: string;
  employerContribution: number;
  employeeContribution: number;
}

interface AddToPensionRetrospectivelyDrawerContentProps {
  readonly userPension: UserPensionDto;
  readonly pensionSchemes: PensionSchemeDto[];
  readonly lastAssessment: EmployeeAeAssessment | undefined;
  readonly refresh: () => Promise<void>;
}

const AddToPensionRetrospectivelyDrawerContent = ({
  userPension,
  pensionSchemes,
  lastAssessment,
  refresh,
}: AddToPensionRetrospectivelyDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState(false);
  const [availablePensionSchemes, setAvailablePensionSchemes] = useState<
    { value: string; label: string; icon: ReactNode }[]
  >([]);
  const [workerGroupOptions, setWorkerGroupOptions] = useState<{ value: string; label: string }[]>([]);
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userPension.userId);

  const [showMessage] = useMessage();

  const defaultPensionScheme = useMemo(() => pensionSchemes.find((pensionScheme) => pensionScheme.isDefault) ?? null, [
    pensionSchemes,
  ]);
  const defaultWorkerGroup = useMemo(() => {
    return defaultPensionScheme?.workerGroups
      ? defaultPensionScheme.workerGroups.find((wG) => wG.externalId === defaultPensionScheme.defaultWorkerGroup) ??
          null
      : null;
  }, [defaultPensionScheme]);

  const getWorkerGroupOptionsByProviderId = useCallback(
    (providerId: string) => {
      const provider = pensionSchemes.find((p) => p.externalId === providerId);
      return (
        provider?.workerGroups.map((wG) => ({
          label: wG.name,
          value: wG.externalId,
        })) ?? []
      );
    },
    [pensionSchemes]
  );

  const setPensionSchemesAndWorkerGroups = useCallback(() => {
    const availablePensionSchemes = pensionSchemes.map((pensionScheme) => {
      const providerLabel = PensionProvidersValueToLabel[pensionScheme.providerName] ?? pensionScheme.providerName;
      const label = pensionScheme.displayName ? `${providerLabel} (${pensionScheme.displayName})` : providerLabel;
      return {
        // TODO: should use local id instead of external id - but for this we should update defaultWorkerGroup to be the local id in DB
        value: pensionScheme.externalId,
        label,
        icon: getPensionLogoByProviderName(pensionScheme.providerName),
      };
    });
    setAvailablePensionSchemes(availablePensionSchemes);

    setWorkerGroupOptions(
      // if userProvider is set, get provider workerGroups
      userPension?.pensionProviderId && pensionSchemes.length > 0
        ? getWorkerGroupOptionsByProviderId(userPension.pensionProviderId)
        : // else if default provider found get default provider's worker groups
        defaultPensionScheme?.workerGroups
        ? getWorkerGroupOptionsByProviderId(defaultPensionScheme.externalId)
        : // else if userPension workerGroupId and workerGroupName are set, use them (probably this should never happen)
        userPension?.workerGroupId && userPension.workerGroupName
        ? [{ value: userPension.workerGroupId, label: userPension.workerGroupName }]
        : []
    );
  }, [
    pensionSchemes,
    userPension.pensionProviderId,
    userPension.workerGroupId,
    userPension.workerGroupName,
    getWorkerGroupOptionsByProviderId,
    defaultPensionScheme?.workerGroups,
    defaultPensionScheme?.externalId,
  ]);

  useEffect(() => {
    setPensionSchemesAndWorkerGroups();
  }, [setPensionSchemesAndWorkerGroups]);

  const formik = useFormik<EnrollToPensionRetrospectivelyForm>({
    initialValues: {
      pensionProvider: getPensionProviderFromUserPensionAndDefaultSchema(userPension, defaultPensionScheme),
      workerGroupId: getWorkerGroupFromUserPensionAndDefaultSchema(userPension, defaultWorkerGroup),
      state: lastAssessment?.employeeState ?? '',
      assessmentStatus: lastAssessment?.status ?? '',
      startDate: user?.startDate ? user.startDate : new LocalDate().toDateString(),
      employerContribution: 3,
      employeeContribution: 5,
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      workerGroupId: Yup.string()
        .min(1, polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      state: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      assessmentStatus: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      startDate: Yup.string().test(dateFieldTest).required(polyglot.t('ValidationMessages.requiredField')),
      employerContribution: Yup.number()
        .min(3, 'Minimum allowed employer contribution is 3%.')
        .typeError('ValidationMessages.validValue')
        .required(polyglot.t('ValidationMessages.requiredField')),
      employeeContribution: Yup.number()
        .min(3, 'Minimum allowed employee contribution is 3%.')
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values: EnrollToPensionRetrospectivelyForm) => addToPensionRetrospectively(values),
  });

  const addToPensionRetrospectively = useCallback(
    async (data: EnrollToPensionRetrospectivelyForm) => {
      const createPensionData = {
        pensionId: data.pensionProvider,
        workerGroupId: data.workerGroupId,
        employeeState: data.state,
        aeStatusAtJoining: data.assessmentStatus,
        startDate: data.startDate,
        employerContribution: Number(data.employerContribution),
        employeeContribution: Number(data.employeeContribution),
      };

      if (data.employerContribution + data.employeeContribution < 8) {
        showMessage(polyglot.t('PensionModule.contributionsMinimum8Percent'), 'error');
        return;
      }

      setLoading(true);
      try {
        await PensionAPI.createEmployeePension(userPension.userId, createPensionData, true);
        await refresh();
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [polyglot, userPension.userId, showMessage, refresh]
  );

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Enrol to pension retrospectively</Typography>

        <SelectComponent
          name="pensionProvider"
          label={polyglot.t('PensionModule.pensionProvider')}
          options={availablePensionSchemes}
          value={formik.values.pensionProvider}
          compareValue={formik.values.pensionProvider}
          onChange={(event) => {
            formik.handleChange(event);

            const providerId = event.target.value;
            formik.setFieldValue('workerGroupId', '');
            setWorkerGroupOptions(getWorkerGroupOptionsByProviderId(providerId));
            formik.setFieldValue('workerGroupId', '');
            formik.setFieldValue('employerContribution', '');
            formik.setFieldValue('employeeContribution', '');
          }}
          error={!!formik.errors.pensionProvider && formik.touched.pensionProvider}
          helperText={formik.touched.pensionProvider && (formik.errors.pensionProvider as string)}
        />

        <SelectComponent
          name="workerGroupId"
          label={polyglot.t('PensionModule.workerGroup')}
          options={workerGroupOptions}
          value={formik.values.workerGroupId}
          compareValue={formik.values.workerGroupId}
          onChange={(e) => {
            formik.handleChange(e);
            const workerGroupId = e.target.value;
            const provider = pensionSchemes.find((p) => p.externalId === formik.values.pensionProvider);
            const workerGroup = provider?.workerGroups.find((wG) => wG.externalId === workerGroupId);

            formik.setFieldValue('employerContribution', workerGroup?.employerContribution ?? '');
            formik.setFieldValue('employeeContribution', workerGroup?.employeeContribution ?? '');
          }}
          error={!!formik.errors.workerGroupId && formik.touched.workerGroupId}
          helperText={(formik.touched.workerGroupId && formik.errors.workerGroupId) as string}
        />

        <SelectComponent
          name="state"
          label={polyglot.t('PensionModule.state')}
          options={STATE_OPTIONS}
          value={formik.values.state}
          compareValue={formik.values.state}
          onChange={formik.handleChange}
          error={!!formik.errors.state && formik.touched.state}
          helperText={(formik.touched.state && formik.errors.state) as string}
        />

        <SelectComponent
          name="assessmentStatus"
          label={polyglot.t('PensionModule.assessmentStatus')}
          options={ASSESSMENTS_STATUS_OPTIONS}
          value={formik.values.assessmentStatus}
          compareValue={formik.values.assessmentStatus}
          onChange={formik.handleChange}
          error={!!formik.errors.assessmentStatus && formik.touched.assessmentStatus}
          helperText={(formik.touched.assessmentStatus && formik.errors.assessmentStatus) as string}
        />

        <DatePickerComponent
          name="startDate"
          label={polyglot.t('General.startDate')}
          inputFormat="DD/MM/YYYY"
          value={formik.values.startDate}
          onChange={(value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue('startDate', value);
            }
          }}
          error={Boolean(formik.touched.startDate && formik.errors.startDate)}
          helperText={(formik.touched.startDate && formik.errors.startDate) as string}
          disableFuture
        />

        <Box sx={{ display: 'flex', gap: spacing.gap20 }}>
          <TextfieldComponent
            name="employerContribution"
            label={polyglot.t('BenefitModule.employerContribution')}
            value={formik.values.employerContribution}
            onChange={formik.handleChange}
            error={formik.touched.employerContribution && !!formik.errors.employerContribution}
            helperText={(formik.touched.employerContribution && formik.errors.employerContribution) as string}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
          <TextfieldComponent
            name="employeeContribution"
            label={polyglot.t('BenefitModule.employeeContribution')}
            value={formik.values.employeeContribution}
            onChange={formik.handleChange}
            error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
            helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) as string}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
