import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ONBOARDING_NEW_USER_ROUTE, SETTINGS_ONBOARDING_ROUTE } from '@/lib/routes';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationStub,
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION,
} from '@/v2/feature/app-integration/app-integration.interface';
import { getAppOwnerId } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import {
  APPS_SUPPORTING_CANDIDATE_MANAGEMENT,
  APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT,
} from '@/v2/feature/app-integration/features/app-details/pages/app-employment-management.page';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';

const getNewEmployeeSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    firstName: Yup.string().trim().required(polyglot.t('OnboardNewEmployee.errorMessages.nameRequired')),
    lastName: Yup.string().trim().required(polyglot.t('OnboardNewEmployee.errorMessages.lastNameRequired')),
    emailAddress: Yup.string()
      .trim()
      .email(polyglot.t('OnboardNewEmployee.errorMessages.emailInvalid'))
      .required(polyglot.t('OnboardNewEmployee.errorMessages.emailRequired')),
    personalEmail: Yup.string()
      .trim()
      .email(polyglot.t('OnboardNewEmployee.errorMessages.emailInvalid'))
      .required(polyglot.t('OnboardNewEmployee.errorMessages.emailRequired')),
    startDate: Yup.string()
      .test(dateFieldTest)
      .required(polyglot.t('OnboardNewEmployee.errorMessages.startDateRequired')),
    templateId: Yup.number().integer().optional(),
  });

export type NewEmployeeInitialValues = {
  userId: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  startDate?: string;
  templateId?: number;
};

type OnboardNewEmployeeProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => Promise<void> | void;
  initialValues?: NewEmployeeInitialValues;
  onDraftUserCreated: () => void;
  onUserCreated?: () => void;
  sx?: SxProps<Theme>;
  mode?: 'onboard' | 'import'; // the same onboard modal will be re-used for importing candidates within supported apps
  appStub?: AppIntegrationStub;
  externalId?: string;
};

export const OnboardNewEmployee = ({
  isOpen,
  setIsOpen,
  onClose,
  initialValues,
  onDraftUserCreated,
  onUserCreated,
  mode = 'onboard',
  appStub,
  externalId,
}: OnboardNewEmployeeProps) => {
  const { polyglot } = usePolyglot();

  const [templates, setTemplates] = useState<OnboardingTemplate[]>([]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const draftOnly = useRef(false);
  const routerHistory = useHistory();
  const { refreshCachedUsers, getCachedUserById } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();

  useEffect(() => {
    OnboardingAPI.getOnboardingTemplates().then(setTemplates);
  }, []);

  const appSource = useMemo(() => {
    if (appStub && APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT.includes(appStub)) return appStub;
    if (appStub && APPS_SUPPORTING_CANDIDATE_MANAGEMENT.includes(appStub)) return appStub;
    return undefined;
  }, [appStub]);

  const formik = useFormik({
    initialValues: {
      userId: 0,
      firstName: '',
      lastName: '',
      emailAddress: '',
      personalEmail: '',
      startDate: undefined,
      templateId: undefined as number | undefined,
      ...initialValues,
    },
    enableReinitialize: initialValues ? true : false,
    validateOnMount: true,
    validationSchema: getNewEmployeeSchema(polyglot),
    onSubmit: async ({ userId, ...values }) => {
      setSaving(true);
      try {
        let newUserId: number;
        if (userId) {
          newUserId = await OnboardingAPI.updateUserOnboarding(
            {
              ...values,
              userId,
              displayName: `${values.firstName} ${values.lastName}`,
            },
            refreshCachedUsers
          );
        } else {
          newUserId = await OnboardingAPI.createUserOnboarding(
            {
              ...values,
              appSource,
              externalId,
              // type: EmploymentTypeEnum.Employee,
              // selectedEmail: hasEmailSelectorForUserCreation(formik.values.templateId)
              //   ? formik.values.emailAddress
              //   : undefined,
            },
            refreshCachedUsers
          );
          if (mode === 'import' && appStub && newUserId && externalId)
            await AppIntegrationAPI.createIdMatchingForNewCandidate(appStub, newUserId, externalId);
        }
        if (draftOnly.current) {
          onDraftUserCreated();
          return;
        }

        routerHistory.push(ONBOARDING_NEW_USER_ROUTE, { newUserId });
        onUserCreated?.();
      } catch (error) {
        showMessage(
          polyglot.t('OnboardNewEmployee.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  useEffect(() => {
    // if we only have one onboarding template, auto-set the templateId value
    if (templates.length === 1 && formik.values.templateId !== templates[0].templateId) {
      formik.setFieldValue('templateId', templates[0].templateId, true);
    }
  }, [formik, templates]);

  const saveDraft = useCallback(() => {
    draftOnly.current = true;
    formik.submitForm();
  }, [formik]);

  const onboardUser = useCallback(() => {
    draftOnly.current = false;
    formik.submitForm();
  }, [formik]);

  const templateOptions = useMemo(() => {
    return [
      ...templates
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
        .map<OptionObj>((t) => ({ label: t.name, value: t.templateId })),
    ];
  }, [templates]);

  const hasEmailSelectorForUserCreation = (templateId?: number) => {
    const templateDetail = templates.find((t) => t.templateId === templateId);
    return mode === 'onboard' &&
      templateId &&
      templateDetail &&
      templateDetail?.template?.apps?.stubs?.some((stub) => APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(stub))
      ? templateDetail
      : undefined;
  };

  const validateEmailDomainForAppOnboarding = async (templateId = formik.values.templateId) => {
    const selectedTemplateDetail = hasEmailSelectorForUserCreation(templateId);
    const appEmailValidation = [];
    if (!selectedTemplateDetail || !selectedTemplateDetail?.template?.apps?.stubs) return true;

    let workEmailDomain;
    let appOwnerEmailDomain;

    for (const eachStub of selectedTemplateDetail?.template?.apps?.stubs) {
      if (APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(eachStub)) {
        const stub = eachStub as AppIntegrationStub;
        const appOwnerId = await getAppOwnerId(stub);
        const appOwner = getCachedUserById(appOwnerId);
        if (!appOwner) {
          appEmailValidation.push(false);
          return;
        }
        const appOwnerEmail = appOwner.emailAddress;
        appOwnerEmailDomain = appOwnerEmail.split('@')[1];
        workEmailDomain = formik.values.emailAddress.split('@')[1];
        appEmailValidation.push(appOwnerEmailDomain === workEmailDomain);
      }
    }

    const workEmailDomainValidated = appEmailValidation.every((each) => each === true);
    if (!workEmailDomainValidated) {
      const errorMessage = polyglot.t('OnboardNewEmployee.errorMessages.domainsDoNotMatch', {
        workEmailDomain: workEmailDomain,
        appOwnerEmailDomain: appOwnerEmailDomain,
      });
      formik.setFieldError('emailAddress', errorMessage);
    } else {
      formik.setFieldError('emailAddress', undefined);
    }
  };

  const hasSubmitted = formik.submitCount > 0;

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Typography variant="title2">
            {mode === 'onboard'
              ? polyglot.t('OnboardNewEmployee.add')
              : polyglot.t('OnboardNewEmployee.onboardCandidate')}
          </Typography>

          <TextfieldComponent
            name="firstName"
            label={polyglot.t('OnboardNewEmployee.firstName')}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            disabled={saving}
            clearText={() => formik.setFieldValue('firstName', '')}
            helperText={hasSubmitted && formik.errors.firstName}
            error={hasSubmitted && !!formik.errors.firstName}
            autoFocus
          />

          <TextfieldComponent
            name="lastName"
            label={polyglot.t('OnboardNewEmployee.lastName')}
            value={formik.values.lastName}
            disabled={saving}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('lastName', '')}
            helperText={hasSubmitted && formik.errors.lastName}
            error={hasSubmitted && !!formik.errors.lastName}
          />

          <TextfieldComponent
            name="emailAddress"
            label={polyglot.t('OnboardNewEmployee.emailAddress')}
            type="email"
            value={formik.values.emailAddress}
            disabled={saving}
            onChange={formik.handleChange}
            onBlur={() => validateEmailDomainForAppOnboarding()}
            clearText={() => formik.setFieldValue('emailAddress', '')}
            helperText={
              (hasSubmitted || hasEmailSelectorForUserCreation(formik.values.templateId)) && formik.errors.emailAddress
            }
            error={
              (hasSubmitted || hasEmailSelectorForUserCreation(formik.values.templateId)) &&
              !!formik.errors.emailAddress
            }
          />

          <TextfieldComponent
            name="personalEmail"
            label={polyglot.t('OnboardNewEmployee.personalEmail')}
            type="email"
            value={formik.values.personalEmail}
            disabled={saving}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('personalEmail', '')}
            helperText={hasSubmitted && formik.errors.personalEmail}
            error={hasSubmitted && !!formik.errors.personalEmail}
          />

          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.startDate ?? null}
            onChange={(value) => {
              formik.setFieldValue('startDate', value);
            }}
            name="startDate"
            label={polyglot.t('OnboardNewEmployee.startDate')}
            disabled={saving}
            helperText={hasSubmitted && formik.errors.startDate}
            error={hasSubmitted && !!formik.errors.startDate}
          />

          <AutocompleteComponent
            name="templateId"
            label={polyglot.t('OnboardNewEmployee.templateId')}
            options={templateOptions}
            value={
              templateOptions.find(({ value }: { value: number | string }) => value === formik.values.templateId) ||
              null
            }
            compareValue={formik.values.templateId}
            // @ts-ignore
            onChange={(_, x: OptionObj) => {
              formik.setFieldValue('templateId', x?.value ?? null);
            }}
            error={hasSubmitted && !!formik.errors.templateId}
            helperText={hasSubmitted && formik.errors.templateId}
            editList={{
              handler: () => routerHistory.push(SETTINGS_ONBOARDING_ROUTE),
              isHidden: !hasScopes(['user.onboard:all'], { userId: user.userId }),
            }}
          />

          <Box sx={buttonBoxDrawerSx}>
            {!formik.values.templateId && (
              <LoaderButton
                name={polyglot.t('OnboardNewEmployee.save')}
                type="button"
                disabled={saving}
                onClick={saveDraft}
                loading={saving && draftOnly.current}
                colorVariant="secondary"
                sizeVariant="medium"
                fullWidth
              />
            )}
            <LoaderButton
              name={polyglot.t('OnboardNewEmployee.onboard')}
              type="button"
              disabled={saving || !formik.values.templateId || !formik.isValid}
              onClick={onboardUser}
              loading={saving && !draftOnly.current}
              colorVariant="primary"
              sizeVariant="medium"
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
