import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { FormikErrors } from 'formik';

import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { MultipleSelectCheckbox, MultiSelectOption } from '@/v2/components/forms/multiple-select-checkbox.component';
import { OptionObj, SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  UserToBeAdded,
} from '@/v2/feature/app-integration/app-integration.interface';
import { AppGroupManagementFormData } from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.page';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const today = new Date();

interface Props {
  readonly mode: APP_GROUP_MANAGEMENT_DRAWER_MODES;
  userListForProcessing: UserToBeAdded[];
  userOptionsForAdd: OptionObj[];
  groupOptionList: MultiSelectOption<string>[];
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<AppGroupManagementFormData>>;
}

export const AppGroupManagementMemberAddList = ({
  mode,
  userListForProcessing,
  userOptionsForAdd,
  groupOptionList,
  formikSetFieldValue,
}: Props): JSX.Element => {
  const { nonTerminatedCachedUsers } = useCachedUsers();

  const usersList = nonTerminatedCachedUsers
    .filter((user) => userOptionsForAdd.some((u) => u.value === user.userId))
    .map((u) => {
      return { label: u.displayName, value: u.userId, ...u };
    });
  return (
    <>
      {mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add &&
        userListForProcessing &&
        userListForProcessing.length > 0 &&
        userListForProcessing.map((eachUser, index) => (
          <>
            <Box sx={fieldSx}>
              <SingleUserSelect
                name="userListForProcessing[index].selectedUser"
                options={usersList}
                onChange={(_, x) => {
                  const listForUpdating = [...userListForProcessing];
                  if (!x)
                    listForUpdating[index] = {
                      ...userListForProcessing[index],
                      userId: undefined,
                      selectedUser: undefined,
                    };
                  else {
                    listForUpdating[index] = {
                      ...userListForProcessing[index],
                      userId: +(x as OptionObj).value,
                      selectedUser: x as OptionObj,
                    };
                  }
                  formikSetFieldValue('userListForProcessing', listForUpdating, true);
                }}
                value={eachUser.userId}
                label="Employee"
                error={undefined}
                helperText={undefined}
              />
            </Box>
            <Box sx={fieldSx}>
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                value={userListForProcessing[index].dateOfActivation}
                onChange={(value) => {
                  if (dayjs(value).isValid()) {
                    const listForUpdating = [...userListForProcessing];
                    listForUpdating[index] = {
                      ...userListForProcessing[index],
                      dateOfActivation: value,
                    };
                    formikSetFieldValue('userListForProcessing', listForUpdating, true);
                  }
                }}
                name="actionDate"
                minDate={today}
                label="Activation date"
              />
            </Box>
            <Box sx={fieldSx}>
              <MultipleSelectCheckbox
                id="group-select"
                limitTags={5}
                options={groupOptionList}
                value={userListForProcessing[index].groupList ?? []}
                onChange={(_, values) => {
                  const listForUpdating = [...userListForProcessing];
                  listForUpdating[index] = {
                    ...userListForProcessing[index],
                    groupList: values,
                  };
                  formikSetFieldValue('userListForProcessing', listForUpdating, true);
                }}
                isOptionEqualToValue={(x, y) => x.value === y.value}
                getOptionLabel={({ label }: { label: string }): string => label}
                label="Groups (optional)"
              />
            </Box>
          </>
        ))}
    </>
  );
};
