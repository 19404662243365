import { DocumentsCompanyPage } from '@v2/feature/documents/documents-company.page';
import { Route, Switch } from 'react-router-dom';

import { DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE, DOCUMENTS_COMPANY_ROUTE } from '@/lib/routes';
import { BulkUploadProvider } from '@/v2/feature/documents/bulk-upload-documents.context';
import { DocumentBulkUploadFlow } from '@/v2/feature/documents/components/document-bulk-upload-flow.component';

export const DocumentsCompanyRouter = (): JSX.Element => (
  <Switch>
    <Route path={DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE} exact>
      <BulkUploadProvider>
        <DocumentBulkUploadFlow />
      </BulkUploadProvider>
    </Route>
    <Route path={DOCUMENTS_COMPANY_ROUTE} exact>
      <DocumentsCompanyPage reach="company" />
    </Route>
  </Switch>
);
