import React, { useMemo, useState } from 'react';

import { Autocomplete, Box, FormControl, IconButton, Paper, Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/lib/ReadonlyArray';

import { UserAddressSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import {
  CreateUserAddressDto,
  UserAddressDto,
} from '@/v2/feature/user/features/user-forms/user-address/user-address.dto';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { getCountriesForNationality } from '@/v2/infrastructure/country/country.util';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { StyledMenuItem } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export type AddressValues = Partial<UserAddressDto>;

interface Props {
  readonly userId: number;
  readonly initialValues?: AddressValues;
  readonly onSubmit?: () => void;
  readonly onClose: () => void;
  readonly handleRefresh: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: CreateUserAddressDto) => void;
  showEmployee?: boolean;
}

const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map((r) =>
    pipe(
      r.options ?? [],
      RA.map((x) => ({ ...x, group: r.label }))
    )
  ),
  RA.flatten
);
const iconSize = { width: '14px', height: '14px' } as const;

export const AddressForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  handleRefresh,
  usedForDataImport = false,
  importHandler = () => {},
  showEmployee = false,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const mode = initialValues ? 'edit' : 'add';
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);

  const validationSchema = useMemo(() => UserAddressSchema(polyglot), [polyglot]);

  const formik = useFormik<UserAddressDto | CreateUserAddressDto>({
    initialValues: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      postcode: '',
      effectiveDate: new LocalDate().toDateString(),
      customUpdates: [],
      ...initialValues,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!values.effectiveDate || !values.country) {
        return;
      }
      try {
        setLoading(true);

        if (!usedForDataImport) {
          if ('userAddressId' in values) {
            await UserAddressAPI.update(userId, values);
            onSubmit?.();
            handleRefresh();
          } else {
            await UserAddressAPI.create(userId, values);
            onSubmit?.();
            handleRefresh();
          }
          showMessage(polyglot.t('AddressForm.successMessages.update'), 'success');
        } else {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage(polyglot.t('AddressForm.errorMessages.save', { msg: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteAddress = async () => {
    if (!('userAddressId' in formik.values)) return;
    try {
      setLoading(true);
      await UserAddressAPI.delete(userId, formik.values.userAddressId);
      handleRefresh();
      onSubmit?.();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('AddressForm.errorMessages.saveTryAgain', { msg: nestErrorMessage(error) }), 'error');
    } finally {
      setIsRemovalModalOpen(false);
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {mode === 'add' ? (
          <Typography variant="title2">{polyglot.t('AddressForm.new')}</Typography>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{polyglot.t('AddressForm.edit')}</Typography>
            <IconButton
              sx={iconCTAButtonSx}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setIsRemovalModalOpen(true);
              }}
            >
              <TrashIcon {...iconSize} />
            </IconButton>
          </Box>
        )}
        {showEmployee && (
          <Stack sx={{ gap: spacing.g5 }}>
            <Typography variant="captionSmall">{polyglot.t('AddressForm.employee')}</Typography>
            <UserCell userId={userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
          </Stack>
        )}
        <ProfileField fieldStub="address.effectiveDate">
          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.effectiveDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('effectiveDate', value);
                }
              }}
              name="effectiveDate"
              label={polyglot.t('AddressForm.effectiveDate')}
              error={formik.touched.effectiveDate && !!formik.errors.effectiveDate}
              helperText={(formik.touched.effectiveDate && formik.errors.effectiveDate) as string}
            />
          </FormControl>
        </ProfileField>
        <ProfileField fieldStub="address.addressLine1">
          <TextfieldComponent
            name="addressLine1"
            label={polyglot.t('AddressForm.addressLine1')}
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            error={formik.touched.addressLine1 && !!formik.errors.addressLine1}
            helperText={(formik.touched.addressLine1 && formik.errors.addressLine1) as string}
            clearText={() => formik.setFieldValue('addressLine1', '')}
            maxLength={30}
            autoFocus
          />
        </ProfileField>
        <ProfileField fieldStub="address.addressLine2">
          <TextfieldComponent
            name="addressLine2"
            label={polyglot.t('AddressForm.addressLine2')}
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            error={formik.touched.addressLine2 && !!formik.errors.addressLine2}
            helperText={(formik.touched.addressLine2 && formik.errors.addressLine2) as string}
            clearText={() => formik.setFieldValue('addressLine2', '')}
            maxLength={30}
          />
        </ProfileField>
        <ProfileField fieldStub="address.city">
          <TextfieldComponent
            name="city"
            label={polyglot.t('AddressForm.city')}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && !!formik.errors.city}
            helperText={(formik.touched.city && formik.errors.city) as string}
            clearText={() => formik.setFieldValue('city', '')}
            maxLength={30}
          />
        </ProfileField>
        <ProfileField fieldStub="address.postcode">
          <TextfieldComponent
            name="postcode"
            label={polyglot.t('AddressForm.postCode')}
            value={formik.values.postcode}
            onChange={formik.handleChange}
            error={formik.touched.postcode && !!formik.errors.postcode}
            helperText={(formik.touched.postcode && formik.errors.postcode) as string}
            clearText={() => formik.setFieldValue('postcode', '')}
            maxLength={20}
          />
        </ProfileField>
        <ProfileField fieldStub="address.country">
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={countryOptions}
              getOptionLabel={(option) => option.label}
              value={countryOptions.find(({ value }) => value === formik.values.country) ?? null}
              renderOption={(props, option) => {
                return option.value === formik.values.country ? (
                  <StyledMenuItem {...props}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Typography variant="title4">{option.label}</Typography>
                      <ChoseIcon {...iconSize} />
                    </Box>
                  </StyledMenuItem>
                ) : (
                  <StyledMenuItem {...props}>
                    <Typography variant="caption">{option.label}</Typography>
                  </StyledMenuItem>
                );
              }}
              groupBy={(option) => option.group}
              onChange={(_, x) => formik.setFieldValue('country', x?.value ?? '')}
              renderInput={(params) => (
                <StyledAutoTextfield
                  {...params}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  label={polyglot.t('AddressForm.country')}
                  name="country"
                  error={formik.touched.country && !!formik.errors.country}
                  helperText={(formik.touched.country && formik.errors.country) as string}
                />
              )}
              PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
              popupIcon={<ArrowDownACIcon />}
              clearIcon={<ClearIcon />}
            />
          </FormControl>
        </ProfileField>
        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
          rowModalMode={mode}
          formName={CustomProfileFormType.Address}
        />
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth={true}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>

        <NotificationModal
          isOpen={isRemovalModalOpen}
          onClose={() => setIsRemovalModalOpen(false)}
          anchorEl={anchorEl}
          takeAction={deleteAddress}
          message={polyglot.t('AddressForm.confirmDelete')}
          callToAction={polyglot.t('AddressForm.callToAction')}
        />
      </Form>
    </FormikProvider>
  );
};
