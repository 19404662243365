import React from 'react';

import { Box } from '@mui/material';

import { ReactComponent as Colomn } from '@/images/attendance/Colomn.svg';
import { ReactComponent as List } from '@/images/attendance/List.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const buttonStyles = {
  paddingX: spacing.p10,
  paddingY: spacing.p5,
  borderRadius: radius.br20,
  height: 'auto',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const SplitSwitchButton = ({
  listViewAction,
  columnViewAction,
  listActive,
  columnActive,
}: {
  listViewAction: () => Promise<void> | void;
  columnViewAction: () => Promise<void> | void;
  listActive: boolean;
  columnActive: boolean;
}) => {
  return (
    <Box
      sx={{
        width: '60px',
        height: '30px',
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SwitchButton isActive={columnActive} color={themeColors.DarkGrey} action={columnViewAction}>
        <Colomn {...iconSize} stroke={columnActive ? themeColors.white : themeColors.DarkGrey} />
      </SwitchButton>

      <SwitchButton isActive={listActive} color={themeColors.DarkGrey} action={listViewAction}>
        <List {...iconSize} fill={listActive ? themeColors.white : themeColors.DarkGrey} />
      </SwitchButton>
    </Box>
  );
};

export const SwitchButton = ({
  isActive,
  color,
  action,
  children,
}: {
  isActive: boolean;
  color: string;
  action: () => Promise<void> | void;
  children: React.JSX.Element;
}) => {
  return (
    <Box
      className={isActive ? 'switch-no-hover' : 'switch-hover'}
      sx={{
        ...buttonStyles,
        color: isActive ? themeColors.white : themeColors.DarkGrey,
        background: isActive ? color : 'none',
        transition: 'all 0.2s ease-in-out',
      }}
      onClick={action}
    >
      {children}
    </Box>
  );
};
