import axios from 'axios';

import { PaginationInterface, StrapiFaqData, StrapiGuideData } from '@/v2/feature/strapi-help/strapi.interface';

export class StrapiAPI {
  static async listFaqs(
    domain?: string,
    query?: string,
    page?: number,
    pageSize?: number
  ): Promise<{ data: StrapiFaqData[]; meta: PaginationInterface }> {
    const baseURL = '/apiv2/strapi/faqs';
    const url = new URL(baseURL, window.location.origin);

    // If domain is provided, append it to the search parameters.
    if (domain) {
      url.searchParams.append('filters[$and][0][tags][slug][$eq]', domain);
    }

    // If query is provided, append the relevant parameters.
    if (query) {
      const orFilter = `filters[$and][1][$or]`;
      url.searchParams.append(`${orFilter}[0][Question][$contains]`, query);
      url.searchParams.append(`${orFilter}[1][Answer][$contains]`, query);
    }

    if (page && pageSize) {
      url.searchParams.append('pagination[page]', page.toString());
      url.searchParams.append('pagination[pageSize]', pageSize.toString());
    }
    // url.searchParams.append('populate', 'tags.slug');
    // url.searchParams.append('populate', 'Media.name');

    try {
      const response = await axios.get<{ data: StrapiFaqData[]; meta: PaginationInterface }>(url.toString());
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async listGuides(
    domain?: string,
    query?: string,
    page?: number,
    pageSize?: number
  ): Promise<{ data: StrapiGuideData[]; meta: PaginationInterface }> {
    const baseURL = '/apiv2/strapi/guides';
    const url = new URL(baseURL, window.location.origin);

    if (domain) {
      url.searchParams.append('filters[$and][0][tags][slug][$eq]', domain);
    }
    if (query) {
      const orFilter = `filters[$and][1][$or]`;
      url.searchParams.append(`${orFilter}[0][Title][$contains]`, query);
      url.searchParams.append(`${orFilter}[1][Description][$contains]`, query);
    }

    if (page && pageSize) {
      url.searchParams.append('pagination[page]', page.toString());
      url.searchParams.append('pagination[pageSize]', pageSize.toString());
    }
    // url.searchParams.append('populate', 'Step');
    // url.searchParams.append('populate', 'Step.Screenshot');
    // url.searchParams.append('populate', 'tags');

    try {
      const response = await axios.get<{ data: StrapiGuideData[]; meta: PaginationInterface }>(url.toString());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
