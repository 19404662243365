import { useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { EditableUserPayrollDto, UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  getNewEmployeeTaxCodeNoP45,
  getNewEmployeeTaxCodeWithP45,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { LegalGender } from '@/v2/feature/payroll/features/payroll-uk/user-payroll-settings/user-payroll-settings.interface';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { CloseButton } from '@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component';
import { LegalGenderSelect } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/legal-gender.component';
import { NINOEntry } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/nino-entry.component';
import { P45Values } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/p45-values.component';
import { StarterDeclarationChoice } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/starter-declaration.component';
import {
  StudentLoanConfig,
  StudentLoansOptions,
} from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/student-loans-options.component';
import { getApiErrorMessage } from '@/v2/infrastructure/api-error/api-error.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

type OnboardingPayrollProps = {
  userId: number;
  userPayroll: UserPayrollDto | null;
  onClose: () => void;
};

export const OnboardingPayrollUK = ({ userId, onClose }: OnboardingPayrollProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [confirmedInfo, setConfirmedInfo] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const [leaveDate, setLeaveDate] = useState('');
  const [p45TaxCode, setP45TaxCode] = useState('');

  const user = getCachedUserById(userId);

  const formik = useFormik({
    initialValues: {
      hasNino: undefined as boolean | undefined,
      isP45Filled: undefined as boolean | undefined,
      legalGender: undefined as LegalGender | undefined,
      niNumber: undefined as string | null | undefined,
      taxCode: '0T',
      week1Month1: false,
      openingPreviousGross: undefined as number | undefined,
      openingPreviousTax: undefined as number | undefined,
      starterDeclaration: undefined as StarterDeclarationLetter | undefined,
      studentLoans: undefined as StudentLoanConfig | null | undefined,
    },
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const update: EditableUserPayrollDto = {
          payrollValues: {
            starterDeclaration: values.starterDeclaration!,
            isP45Filled: values.isP45Filled!,
            niNumber: values.niNumber!,
            legalGender: values.legalGender!,
            studentLoan: values.studentLoans?.studentLoanPlan ?? 'None',
            postgradLoan: values.studentLoans?.postgradLoan ?? false,
            isDirector: false,
            niAlternativeMethod: null,
            openingNotNewStarter: false,
            niTable: 'A',
            week1Month1: values.week1Month1,
            taxCode: values.taxCode,
            openingPreviousGross: values.openingPreviousGross,
            openingPreviousTax: values.openingPreviousTax,
          },
          countryCode: 'GB',
          effectiveDate: todaysDateShortISOString(),
          changeReason: null,
        };
        await PayrollAPI.updateUserPayrollRecord(userId, update);
        onClose();
      } catch (e) {
        setSubmitting(false);
        showMessage(
          polyglot.t('OnboardingByUser.errors.payrollNotSaved', { errorMessage: getApiErrorMessage(e) }),
          'error'
        );
      }
    },
  });

  const { isP45Filled, legalGender, starterDeclaration, taxCode, studentLoans, niNumber } = formik.values;

  const newEmployee = useMemo(() => {
    // get the tax-code, w1/m1 and declaration based on P45 settings
    if (!isP45Filled) {
      if (!starterDeclaration) {
        return;
      }
      return getNewEmployeeTaxCodeNoP45(starterDeclaration!);
    }
    const leave = new Date(leaveDate);
    const start = new Date(user?.startDate ?? '');
    if (Number.isNaN(leave.getDate()) || Number.isNaN(start.getDate())) {
      return;
    }
    return getNewEmployeeTaxCodeWithP45(start, leave, p45TaxCode);
  }, [isP45Filled, leaveDate, p45TaxCode, starterDeclaration, user?.startDate]);

  if (newEmployee) {
    (Object.keys(newEmployee) as (keyof typeof newEmployee)[]).forEach((field) => {
      if (formik.values[field] !== newEmployee[field]) {
        formik.setFieldValue(field, newEmployee[field]);
      }
    });
  }

  const canSubmit =
    [isP45Filled, legalGender, starterDeclaration, studentLoans, niNumber].every((value) => value !== undefined) &&
    (!isP45Filled || (!!leaveDate && !!taxCode)) &&
    (niNumber === null || !!niNumber) &&
    (studentLoans === null ||
      (studentLoans && (studentLoans.postgradLoan || studentLoans.studentLoanPlan !== 'None'))) &&
    confirmedInfo;

  return (
    <Stack sx={{ flex: 1 }}>
      <Stack sx={{ flex: 0, mx: 'auto', width: '400px', maxWidth: '90vw', mt: spacing.mt40 }}>
        <Typography
          sx={{
            ...themeFonts.title2,
            color: themeColors.DarkGrey,
          }}
        >
          {polyglot.t('OnboardingByUser.payrollOnboarding')}
        </Typography>
        <CloseButton onClick={onClose} />
      </Stack>
      <Stack sx={{ flex: 1, overflowY: 'auto' }}>
        <Stack sx={{ mx: 'auto', width: '400px', maxWidth: '90vw', gap: spacing.g30 }}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Stack sx={{ my: spacing.g30, gap: spacing.g30 }}>
                <P45Values
                  userId={userId}
                  disabled={submitting}
                  haveP45={formik.values.isP45Filled}
                  setHaveP45={(value) => formik.setFieldValue('isP45Filled', value)}
                  leaveDate={leaveDate}
                  setLeaveDate={setLeaveDate}
                  taxCode={p45TaxCode}
                  setTaxCode={(value) => setP45TaxCode(value)}
                  totalPay={formik.values.openingPreviousGross}
                  setTotalPay={(value) => formik.setFieldValue('openingPreviousGross', value)}
                  taxPaid={formik.values.openingPreviousTax}
                  setTaxPaid={(value) => formik.setFieldValue('openingPreviousTax', value)}
                />
                <LegalGenderSelect
                  gender={formik.values.legalGender}
                  setGender={(value) => formik.setFieldValue('legalGender', value)}
                  disabled={submitting}
                />
                <NINOEntry
                  niNumber={formik.values.niNumber}
                  setNiNumber={(value) => formik.setFieldValue('niNumber', value)}
                  disabled={submitting}
                />
                {
                  // hide starter declaration if the value is determined from P45
                  starterDeclaration && !!newEmployee && isP45Filled ? (
                    <></>
                  ) : (
                    <StarterDeclarationChoice
                      declaration={formik.values.starterDeclaration}
                      setDeclaration={(value) => formik.setFieldValue('starterDeclaration', value)}
                      disabled={submitting}
                    />
                  )
                }
                <StudentLoansOptions
                  setStudentLoans={(studentLoans) => {
                    formik.setFieldValue('studentLoans', studentLoans);
                  }}
                  value={formik.values.studentLoans}
                  disabled={submitting}
                />

                <CheckboxComponent
                  name="confirmInfo"
                  label={polyglot.t('OnboardingByUser.confirmInfo')}
                  checked={confirmedInfo}
                  onChange={(_, checked) => setConfirmedInfo(checked)}
                  sx={{ mt: spacing.mt20 }}
                  disabled={submitting}
                />
                <LoaderButton
                  name={polyglot.t('General.save')}
                  fullWidth
                  loading={submitting}
                  disabled={!canSubmit}
                  sizeVariant="large"
                  colorVariant="primary"
                />
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Stack>
    </Stack>
  );
};
