import { Box, FormControl } from '@mui/material';

import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { borders } from '@/v2/styles/borders.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MultipleChoiceQuestionReadonly = ({
  questionText,
  options,
  hasComment,
  isCommentRequired,
}: {
  questionText: string;
  options: Record<string, string>;
  hasComment: boolean;
  isCommentRequired: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <Typography variant="caption" dangerouslySetInnerHTML={{ __html: questionText ?? '' }}></Typography>

      <FormControl sx={{ width: '100%', margin: 0 }} error={false} disabled={false}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          {options &&
            Object.keys(options)?.map((a) => (
              <CheckboxComponent name={a} label={options[+a]} disabled={false} checked={false} />
            ))}
        </Box>
      </FormControl>

      {hasComment && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.g8,
            pt: spacing.p16,
            borderTop: `${borders.background}`,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Add comment
            </Typography>
            <Typography variant="caption" color="Grey">
              {isCommentRequired ? 'Required' : 'Optional'}
            </Typography>
          </Box>
          <RichTextField value={null} disabled={true} />
        </Box>
      )}
    </Box>
  );
};
