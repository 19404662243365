import React, { PropsWithChildren, useMemo } from 'react';

import { Box, Button, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { ColumnDef, Row, RowData } from '@tanstack/react-table';
import { OptionProps, StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ScopesControl } from '@/component/widgets/Scopes';
import { useProfileFields } from '@/hooks/profile-fields.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { Scope } from '@/models';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import { ShowHideButton } from '@/v2/feature/user/features/user-profile/details/components/show-hide-button.component';
import {
  cardHeaderSx,
  cardMaxWidth,
  definitionSx,
  definitionTermSx,
  definitionValueSx,
  showTableSx,
  tablecardSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { primarySmallBtn, secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CardStructure = <TData extends RowData>({
  cardTitle,
  cardScope,
  showHistoryButton = true,
  showEditButton = true,
  showDetails,
  setShowDetails,
  userId,
  addActionMenuOptions,
  addAction,
  editAction,
  newAction,
  showMissingInfo,
  cardFieldCustomUpdates,
  cardFieldStubs,
  cardFieldDetails,
  drawerDetails,
  addButtonTitle,
  newButtonTitle,
  tableSelect,
  tableRowData,
  tableColumn,
  missingInfoIcon,
  emptyText,
  tableRowClickHandler,
}: {
  cardTitle: string;
  cardScope: readonly Scope[];
  showHistoryButton?: boolean;
  showEditButton?: boolean;
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  addActionMenuOptions?: readonly OptionProps[];
  addAction?: () => void | undefined;
  editAction?: () => void | undefined;
  newAction?: () => void | undefined;
  showMissingInfo: boolean;
  cardFieldCustomUpdates?: UserCustomDataDto[];
  cardFieldStubs?: string[];
  cardFieldDetails: JSX.Element | undefined;
  drawerDetails: JSX.Element;
  addButtonTitle?: string;
  newButtonTitle?: string;
  tableSelect?: JSX.Element;
  tableRowData: TData[];
  tableColumn: ColumnDef<TData, TData>[];
  missingInfoIcon?: JSX.Element;
  emptyText?: string;
  tableRowClickHandler?: ((row: Row<TData>) => void) | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  return (
    <ProfileCard fieldStubs={cardFieldStubs ?? []} customUpdates={cardFieldCustomUpdates} sx={tablecardSx}>
      {/* Details */}
      <Box sx={{ ...cardMaxWidth }}>
        <Box component="header" sx={cardHeaderSx}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
            <Typography variant="title2">{cardTitle}</Typography>
            {missingInfoIcon}
          </Box>
          {
            <ScopesControl scopes={cardScope} context={scopesContext}>
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                {showEditButton && !showMissingInfo && !showDetails && editAction && (
                  <IconButton onClick={editAction} title="Edit" sx={tableIconButtonSx}>
                    <Edit {...iconSize} />
                  </IconButton>
                )}

                {!addActionMenuOptions && addAction && (
                  <Button onClick={addAction} sx={secondaryCTABtn}>
                    {addButtonTitle}
                  </Button>
                )}

                {addActionMenuOptions && (
                  <StyledMenuComponent
                    actionButtonDetails={{
                      type: 'button',
                      colorVariant: 'secondary',
                      sizeVariant: 'small',
                      title: polyglot.t('General.actions'),
                      icon: <ArrowDown {...iconSize} />,
                      iconPosition: 'end',
                    }}
                    options={addActionMenuOptions}
                  />
                )}
              </Box>
            </ScopesControl>
          }
        </Box>
        <Box component="section">
          {emptyText ? (
            <Typography variant="caption" color="Grey">
              {emptyText}
            </Typography>
          ) : showMissingInfo ? (
            <MissingInformationLabel />
          ) : (
            cardFieldDetails
          )}
        </Box>
        {newAction ? (
          <Box component="section" sx={{ mt: spacing.mt20 }}>
            <Button onClick={newAction} sx={primarySmallBtn}>
              {newButtonTitle}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {/* Table */}
      {!showMissingInfo && (
        <Box>
          <Box>{showHistoryButton && <ShowHideButton showDetails={showDetails} setShowDetails={setShowDetails} />}</Box>
          {showDetails && (
            <Box sx={showTableSx}>
              {tableSelect}
              <BasicTable rowData={tableRowData} columnData={tableColumn} rowClick={tableRowClickHandler} />
            </Box>
          )}
        </Box>
      )}
      {/* Drawer */}
      {drawerDetails}
    </ProfileCard>
  );
};

type FieldValueLink = {
  isLink: true;
  label: string;
  icon: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
};

interface FieldStructureProps {
  fieldTitle: string;
  fieldValue: string | number | React.JSX.Element | FieldValueLink | null | undefined;
  fieldStub?: string;
}

export const FieldStructure = ({ fieldTitle, fieldValue, fieldStub }: FieldStructureProps) => {
  const { getDefaultField, loading } = useProfileFields();
  const fieldData = useMemo(() => (fieldStub ? getDefaultField(fieldStub) : undefined), [getDefaultField, fieldStub]);

  if ((fieldValue ?? '') === '') {
    // if the value is null, undefined or a blank string, don't show the entry
    return null;
  }

  // if getDefaultField(fieldStub) returns undefined is because the field may be a customField, should not hide fields if etDefaultField(fieldStub) is undefined.
  // hide only if getDefaultField(fieldStub).isHidden is true. For this reason the default hidden fields should be returned by the api, but make sure the field values are not returned
  // same in ProfileField
  if (fieldStub && (loading || fieldData?.isHidden)) return null;

  return (
    <Box sx={definitionSx}>
      <Typography variant="caption" sx={definitionTermSx}>
        {fieldTitle}
      </Typography>
      {fieldValue && typeof fieldValue === 'object' && 'isLink' in fieldValue ? (
        <Stack
          sx={{
            width: '100%',
            gap: spacing.g10,
            flexFlow: 'row',
            alignItems: 'baseline',
            cursor: 'pointer',
            fill: themeColors.DarkGrey,
            '&:hover': {
              color: themeColors.Grey,
              fill: themeColors.Grey,
              cursor: 'pointer',
            },
          }}
          onClick={() => fieldValue.onClick()}
        >
          <Typography variant="title4" sx={{ ...definitionValueSx, fill: 'inherit' }}>
            {fieldValue.label}
          </Typography>
          <Stack sx={{ fill: 'inherit' }}>{fieldValue.icon}</Stack>
        </Stack>
      ) : (
        <Typography variant="title4" sx={definitionValueSx}>
          {fieldValue}
        </Typography>
      )}
    </Box>
  );
};

type BasicCardProps = PropsWithChildren<{
  customUpdates?: UserCustomDataDto[];
  fieldStubs: string[];
  sx: SxProps<Theme>;
}>;

export const ProfileCard = ({ customUpdates, fieldStubs, children, sx }: BasicCardProps) => {
  const { getDefaultField } = useProfileFields();

  const allDefaultFieldsHidden = useMemo(() => {
    if (!fieldStubs.length) return false;
    return fieldStubs.every((fieldStub) => getDefaultField(fieldStub)?.isHidden);
  }, [fieldStubs, getDefaultField]);

  const allCustomFieldsHidden = useMemo(() => {
    if (!customUpdates?.length) return true;
    return customUpdates.every(({ field }) => field.isHidden);
  }, [customUpdates]);

  const isCardHidden = allDefaultFieldsHidden && allCustomFieldsHidden;

  return isCardHidden ? <></> : <Box sx={sx}>{children}</Box>;
};
