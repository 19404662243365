import React from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { GrowthFactorFormContent } from '@/v2/feature/growth/growth-factor/components/growth-factor-form-content.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';

export const GrowthFactorFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  growthFactor,
  refresh,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  growthFactor: GrowthFactor | undefined;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} sx={{ p: 0 }}>
      <GrowthFactorFormContent onClose={onClose} growthFactor={growthFactor} refresh={refresh} />
    </DrawerModal>
  );
};
