import { useState } from 'react';

import { KeyedMutator } from 'swr';

import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { SCRollingTimelineModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/components/rolling/sc-rolling-timeline-modal.component';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface ISCRollingTimelineView {
  readonly surveyCycle: SurveyCycle;
  readonly isEditable: boolean;
  readonly refreshCycle: KeyedMutator<SurveyCycle>;
}
export const SCRollingTimelineView = ({ surveyCycle, isEditable, refreshCycle }: ISCRollingTimelineView) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { polyglot } = usePolyglot();
  return (
    <>
      <SettingsSubsectionContent
        sections={[
          {
            title: 'Timeline',
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption" sx={{ pb: spacing.s2 }}>
                    Timeline defines the deadline for each newly invited participant. After the deadline, their
                    individual invite will expire.
                  </Typography>
                ),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('CycleSettingTimelineSection.surveyOpenFor'),
                value: `${surveyCycle?.timelineSettings?.surveyOpenFor ?? '7'} days`,
              },
            ],
          },
        ]}
      />

      <SCRollingTimelineModal
        surveyCycle={surveyCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refreshCycle?.();
        }}
      />
    </>
  );
};
