import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { lastDayOfMonth } from 'date-fns';

import { BillingSubscriptionAPI } from '@/api-client/billing-subscription.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  getBillToEndOfMonth,
  getNoOfActiveDaysForBilling,
} from '@/v2/feature/billing/components/update-company-subscription.util';
import { ViewerItem } from '@/v2/feature/payments/components/payment-details-drawer.component';
import { formatMoney } from '@/v2/feature/payments/utils/money.util';
import {
  BillingCurrency,
  CompanySubscriptionDetails,
  SeatsMethod,
  VatSetting,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { getVatTaxAmount } from '@/v2/util/tax.util';

interface UpdateCompanySubscriptionModalProps {
  readonly isOpen: boolean;
  readonly setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  readonly companySubscriptionDetails: CompanySubscriptionDetails;
  readonly refresh: () => Promise<void>;
}

export const V2UpdateCompanySubscriptionDrawer = ({
  isOpen,
  setIsDrawerOpen,
  companySubscriptionDetails,
  refresh,
}: UpdateCompanySubscriptionModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [selectedModulesForUpgrade, setSelectedModulesForUpgrade] = useState<number[]>([]);
  const { companySubscription } = companySubscriptionDetails;
  const [showMessage] = useMessage();
  const [isLoading, setLoading] = useState(false);
  const { data: availableUpgrades, isLoading: loadingUpgradeModules } = useApiClient(
    CompanyEndpoints.getAvailableModulesForUpgrade(),
    {
      suspense: false,
    }
  );

  useEffect(() => {
    if (availableUpgrades) {
      const coreModule = availableUpgrades.find((module) => module.name === 'Core');
      if (coreModule) {
        setSelectedModulesForUpgrade((prevSelected) => [...prevSelected, coreModule.id]);
      }
    }
  }, [availableUpgrades]);

  const daysChargedForThisMonth = useMemo(() => {
    return getNoOfActiveDaysForBilling();
  }, []);

  const daysInMonth = useMemo(() => {
    return lastDayOfMonth(new Date()).getDate();
  }, []);

  const proratedPricePerSeat = useMemo(() => {
    if (!availableUpgrades) return 0;
    return selectedModulesForUpgrade.reduce((acc, eachModuleId) => {
      const matchingPlan = availableUpgrades.find((eachPlan) => eachPlan.id === eachModuleId);
      const currency = companySubscription?.currency ?? BillingCurrency.GBP;
      const costForPlan = matchingPlan && matchingPlan.cost ? matchingPlan.cost[currency] : 0;
      const costForPlanToEndofMonth = getBillToEndOfMonth(costForPlan, false);
      return acc + costForPlanToEndofMonth;
    }, 0);
  }, [availableUpgrades, companySubscription?.currency, selectedModulesForUpgrade]);

  const listPricePricePerSeat = useMemo(() => {
    if (!availableUpgrades) return 0;
    return selectedModulesForUpgrade.reduce((acc, eachModuleId) => {
      const matchingPlan = availableUpgrades.find((eachPlan) => eachPlan.id === eachModuleId);
      const currency = companySubscription?.currency ?? BillingCurrency.GBP;
      const costForPlan = matchingPlan && matchingPlan.cost ? matchingPlan.cost[currency] : 0;
      return acc + costForPlan;
    }, 0);
  }, [availableUpgrades, companySubscription?.currency, selectedModulesForUpgrade]);

  const listPriceTotalCostExclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return listPricePricePerSeat * companySubscription?.seatMultiplier;
  }, [companySubscription, listPricePricePerSeat]);

  const listPriceTotalVat = useMemo(() => {
    if (!companySubscription) return 0;
    const vatRate = getVatTaxAmount(companySubscription.currency, companySubscription.vatSetting ?? VatSetting.NO_VAT);
    return listPriceTotalCostExclusiveOfVat * vatRate;
  }, [companySubscription, listPriceTotalCostExclusiveOfVat]);

  const listPriceTotalCostInclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return (listPriceTotalCostExclusiveOfVat + listPriceTotalVat).toFixed(2);
  }, [companySubscription, listPriceTotalCostExclusiveOfVat, listPriceTotalVat]);

  const proratedTotalCostExclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return proratedPricePerSeat * companySubscription?.seatMultiplier;
  }, [companySubscription, proratedPricePerSeat]);

  const proratedTotalVat = useMemo(() => {
    if (!companySubscription) return 0;
    const vatRate = getVatTaxAmount(companySubscription.currency, companySubscription.vatSetting ?? VatSetting.NO_VAT);
    return proratedTotalCostExclusiveOfVat * vatRate;
  }, [companySubscription, proratedTotalCostExclusiveOfVat]);

  const proratedTotalCostInclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return (proratedTotalCostExclusiveOfVat + proratedTotalVat).toFixed(2);
  }, [companySubscription, proratedTotalCostExclusiveOfVat, proratedTotalVat]);

  const additionalVatDetails = useMemo(() => {
    if (companySubscription?.vatSetting === VatSetting.NO_VAT || companySubscription?.currency === BillingCurrency.USD)
      return undefined;
    return ` (${companySubscription?.vatSetting})`;
  }, [companySubscription]);

  const upgradePossible = availableUpgrades && availableUpgrades?.length > 0;

  const handleUpgrade = async () => {
    try {
      console.debug('Handle upgrade here');
      setLoading(true);
      // if (downgrades.length > 0)
      //   await Promise.all(
      //     downgrades.map((downgradePayload) => BillingSubscriptionAPI.downgradeSubscription(downgradePayload))
      //   );

      let checkoutSession;
      if (selectedModulesForUpgrade.length > 0) {
        const selectedModules: { productPlanId: number }[] = [];
        for (const eachPlanId of selectedModulesForUpgrade) {
          selectedModules.push({ productPlanId: eachPlanId });
        }
        checkoutSession = await BillingSubscriptionAPI.upgradeSubscriptionV2(selectedModules);
      }

      if (checkoutSession?.url) {
        // Take customer through checkout flow
        return window.location.replace(checkoutSession.url);
      }
      showMessage('Plan updated successfully', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Failed to update plan. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsDrawerOpen}>
      <Box sx={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
          <Typography variant="title2"> {polyglot.t('SubscriptionsTable.yourSubscription')}</Typography>
          <Typography variant="caption" color="Grey">
            {polyglot.t('SubscriptionsTable.subscriptionSubheader')}
          </Typography>
        </Box>
        <Typography variant="caption">
          {upgradePossible ? polyglot.t('SubscriptionsTable.addModule') : <></>}
          {!loadingUpgradeModules && upgradePossible ? (
            (availableUpgrades ?? []).map((eachPlan) => {
              const isSelected = selectedModulesForUpgrade.includes(eachPlan.id);
              const isCoreModule = eachPlan.name === 'Core';
              return (
                <Box
                  key={eachPlan.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: spacing.g5,
                  }}
                >
                  <CheckboxComponent
                    sx={{ mt: spacing.mt10 }}
                    name="moduleSelectionForSubscription"
                    label={eachPlan.name}
                    checked={isSelected}
                    onChange={() => {
                      const updatedList = [...selectedModulesForUpgrade];
                      if (selectedModulesForUpgrade.includes(eachPlan.id)) {
                        setSelectedModulesForUpgrade(updatedList.filter((p) => p !== eachPlan.id));
                      } else {
                        updatedList.push(eachPlan.id);
                        setSelectedModulesForUpgrade(updatedList);
                      }
                    }}
                    disabled={isCoreModule}
                  />
                  {isSelected && (
                    <Typography variant="caption">
                      {formatMoney({
                        amount: eachPlan.cost[companySubscription?.currency ?? BillingCurrency.GBP],
                        currency: companySubscription?.currency ?? BillingCurrency.GBP,
                      })}
                    </Typography>
                  )}
                </Box>
              );
            })
          ) : (
            <Typography variant="caption">You are already subscribed to all available modules</Typography>
          )}
        </Typography>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15, mb: spacing.mb20 }}>
          {selectedModulesForUpgrade?.length > 0 && (
            <>
              <ViewerItem
                title={polyglot.t('SubscriptionsTable.pricePerSeat')}
                value={`${formatMoney({
                  amount: listPricePricePerSeat,
                  currency: companySubscription?.currency ?? BillingCurrency.GBP,
                })} / month`}
              />
              {/* <ViewerItem
                title={polyglot.t('SubscriptionsTable.pricePerSeat')}
                value={formatMoney({
                  amount: cumulativePricePerSeat,
                  currency: companySubscription?.currency ?? BillingCurrency.GBP,
                })}
              /> */}
              <ViewerItem
                title={polyglot.t(
                  companySubscription?.seatsMethod === SeatsMethod.ACTIVE
                    ? 'BillingOverview.active'
                    : 'BillingOverview.fixed'
                )}
                value={(companySubscription?.seatMultiplier ?? 0).toString()}
              />
              <ViewerItem
                title={polyglot.t('SubscriptionsTable.subscriptionsTotal')}
                value={formatMoney({
                  amount: listPriceTotalCostExclusiveOfVat,
                  currency: companySubscription?.currency ?? BillingCurrency.GBP,
                })}
              />
              <ViewerItem
                title={`${polyglot.t('SubscriptionsTable.VAT')}${additionalVatDetails ? additionalVatDetails : ''}`}
                value={
                  additionalVatDetails
                    ? formatMoney({
                        amount: listPriceTotalVat,
                        currency: companySubscription?.currency ?? BillingCurrency.GBP,
                      })
                    : 'None'
                }
              />
              <ViewerItem
                title={polyglot.t('SubscriptionsTable.totalInclVat')}
                value={formatMoney({
                  amount: listPriceTotalCostInclusiveOfVat,
                  currency: companySubscription?.currency ?? BillingCurrency.GBP,
                })}
              />
              <Divider />
              <ViewerItem
                title={polyglot.t('SubscriptionsTable.daysChargedForThisMonth')}
                value={`${daysChargedForThisMonth} / ${daysInMonth}`}
              />
              <ViewerItem
                title={polyglot.t('SubscriptionsTable.dueNow')}
                value={formatMoney({
                  amount: proratedTotalCostInclusiveOfVat,
                  currency: companySubscription?.currency ?? BillingCurrency.GBP,
                })}
              />
            </>
          )}
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            loading={isLoading || loadingUpgradeModules}
            colorVariant="primary"
            disabled={!availableUpgrades || availableUpgrades.length === 0}
            fullWidth
            onClick={handleUpgrade}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
