import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';

import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
} from '@/lib/routes';
import { AutocompleteComponent, OptionObject } from '@/v2/components/forms/autocomplete.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { AppTenant } from '@/v2/feature/app-integration/app-integration.dto';
import { AppDetailsEndpoints } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import {
  PaymentCategoryEnum,
  PaymentTypeSettingAccountingAppConfig,
  PaymentTypeSettings,
  PaymentTypeSettingsUpsert,
  PayrolledOptionsEnum,
  SyncToExternalOptionList,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import {
  PaymentTypeSettingsAPI,
  PaymentTypeSettingsEndpoints,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { caseInsensitiveSort } from '@/v2/util/array.util';

export type PaymentSettingEditMode = 'name' | 'payment' | 'accounting' | 'sync';

interface PaymentSettingsNewTypeDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refreshIndividualSetting?: KeyedMutator<PaymentTypeSettings> | undefined;
  readonly refreshAllSettings?: KeyedMutator<PaymentTypeSettingAccountingAppConfig> | undefined;
  readonly typeForEdit: PaymentTypeSettings | undefined;
  readonly codeConfigIndex?: number;
  readonly editMode?: PaymentSettingEditMode;
  readonly typeCategory?: PaymentCategoryEnum;
  readonly redirectToSettings?: boolean; // redirect to settings after new type creation
}

export const PaymentSettingsNewTypeDrawer = ({
  isOpen,
  setIsOpen,
  codeConfigIndex,
  refreshIndividualSetting,
  refreshAllSettings,
  typeForEdit,
  editMode,
  typeCategory = PaymentCategoryEnum.EXPENSE,
  redirectToSettings = false,
}: PaymentSettingsNewTypeDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <PaymentSettingsNewTypeDrawerContent
        refreshIndividualSetting={refreshIndividualSetting}
        refreshAllSettings={refreshAllSettings}
        setIsOpen={setIsOpen}
        typeForEdit={typeForEdit}
        editMode={editMode}
        typeCategory={typeCategory}
        redirectToSettings={redirectToSettings}
        codeConfigIndex={codeConfigIndex}
      />
    </DrawerModal>
  );
};

interface PaymentSettingsNewTypeDrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refreshIndividualSetting?: KeyedMutator<PaymentTypeSettings> | undefined;
  readonly refreshAllSettings?: KeyedMutator<PaymentTypeSettingAccountingAppConfig> | undefined;
  readonly typeForEdit: PaymentTypeSettings | undefined;
  readonly editMode?: PaymentSettingEditMode;
  readonly typeCategory?: PaymentCategoryEnum;
  readonly codeConfigIndex?: number;
  readonly redirectToSettings?: boolean;
}

export const PaymentSettingsNewTypeDrawerContent = ({
  refreshAllSettings,
  refreshIndividualSetting,
  setIsOpen,
  codeConfigIndex,
  typeForEdit,
  editMode,
  typeCategory = PaymentCategoryEnum.EXPENSE,
  redirectToSettings = false,
}: PaymentSettingsNewTypeDrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const history = useHistory();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const scopesContext = getScopesContext({ userId: user.userId });
  const hasAppsScope = hasScopes(['apps'], scopesContext);
  const { data: accountingAppConfigured } = useApiClient(PaymentTypeSettingsEndpoints.accountingAppConfigured(), {
    suspense: false,
  });
  const accountingDefaultApp = 'xero';
  const isExpense = typeCategory === PaymentCategoryEnum.EXPENSE;

  const { data: accountingApp } = useApiClient(
    hasAppsScope ? AppDetailsEndpoints.getAppDetails(accountingDefaultApp) : { url: undefined },
    {
      suspense: false,
    }
  );

  const { data: accountingCodes } = useApiClient(PaymentTypeSettingsEndpoints.getAccountingCodesFromProvider(), {
    suspense: false,
  });
  const accountingCodeOptions: readonly OptionObject[] = useMemo(() => {
    return accountingCodes && accountingCodes.length > 0
      ? accountingCodes.map((ac) => ({
          label: `${ac.code} (${ac.name})`,
          value: +ac.code,
        }))
      : [];
  }, [accountingCodes]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const editingGeneral = typeForEdit && editMode && editMode === 'name';
  const editingPayment = typeForEdit && editMode && editMode === 'payment';
  const editingAccounting = typeForEdit && editMode && editMode === 'accounting';
  const editingSync = typeForEdit && editMode && editMode === 'sync';

  const onSubmit = useCallback(
    async (values: PaymentTypeSettingsUpsert) => {
      try {
        if (!values.name || !values.type) return;
        setLoading(true);
        if (!typeForEdit?.id) {
          const payload: PaymentTypeSettingsUpsert = {
            ...values,
            syncToExternal: values.syncToExternal ?? 'manual',
            paycode: values.payrolled ? values.paycode : null,
            accountingCode: !values.payrolled && values.accountingCode ? +values.accountingCode : null,
            accountingCodeDescription:
              !values.payrolled && values.accountingCodeDescription ? values.accountingCodeDescription : '',
            provider:
              accountingAppConfigured && accountingApp?.active && accountingApp?.authorised
                ? accountingDefaultApp
                : null,
          };
          // no id so create new type
          const newTypeId = await PaymentTypeSettingsAPI.create(payload);
          showMessage(polyglot.t('PaymentSettingsNewTypeDrawer.successMessages.addNewType'), 'success');
          if (redirectToSettings) {
            history.push(
              generatePath(
                isExpense
                  ? SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE
                  : SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
                {
                  id: newTypeId,
                }
              )
            );
          }
        } else {
          if (editingAccounting) {
            const originalValues = {
              ...typeForEdit,
              syncToExternal: values?.syncToExternal ?? 'manual',
              accountingCodeConfig: typeForEdit.accountingCodeConfig,
              provider:
                accountingAppConfigured && accountingApp?.active && accountingApp?.authorised
                  ? accountingDefaultApp
                  : null,
            };

            if (
              codeConfigIndex !== null &&
              codeConfigIndex !== undefined &&
              codeConfigIndex >= 0 &&
              originalValues.accountingCodeConfig
            ) {
              // need to update accounting code / description
              const updatedAccountingCodeConfig = [...originalValues.accountingCodeConfig];
              updatedAccountingCodeConfig[codeConfigIndex] = {
                accountingCode: values.accountingCode ?? 0,
                accountingCodeDescription: values.accountingCodeDescription ?? '',
              };
              originalValues.accountingCodeConfig = updatedAccountingCodeConfig;
            }
            // insert new accounting code into existing list
            else if (codeConfigIndex === null || codeConfigIndex === undefined) {
              if (!originalValues.accountingCodeConfig) {
                originalValues.accountingCodeConfig = [];
              }
              originalValues.accountingCodeConfig.push({
                accountingCode: values.accountingCode ?? 0,
                accountingCodeDescription: values.accountingCodeDescription ?? '',
              });
            }

            await PaymentTypeSettingsAPI.updateAccounting(typeForEdit.id, originalValues);
          } else if (editingSync) {
            const originalValues = {
              ...typeForEdit,
              syncToExternal: values?.syncToExternal ?? 'manual',
              externalSyncOrganisation: values?.externalSyncOrganisation ?? null,
              provider:
                accountingAppConfigured && accountingApp?.active && accountingApp?.authorised
                  ? accountingDefaultApp
                  : null,
            };
            await PaymentTypeSettingsAPI.updateSyncSetting(typeForEdit.id, originalValues);
          } else if (editingGeneral) {
            await PaymentTypeSettingsAPI.updateGeneral(typeForEdit.id, {
              name: values.name,
              requireAttachment: values.requireAttachment,
            });
          } else {
            // id exists, so edit existing type
            await PaymentTypeSettingsAPI.update(typeForEdit.id, {
              ...values,
              paycode: values.payrolled ? values.paycode : null,
              approvalRuleId: values.approvalRuleId,
            });
          }

          showMessage(polyglot.t('PaymentSettingsNewTypeDrawer.successMessages.updateType'), 'success');
        }
        setIsOpen(false);
        refreshAllSettings?.();
        refreshIndividualSetting?.();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('PaymentSettingsNewTypeDrawer.errorMessages.updateType', {
              errorMessage: nestErrorMessage(error),
            }),
            'error'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [
      typeForEdit,
      setIsOpen,
      refreshAllSettings,
      refreshIndividualSetting,
      accountingAppConfigured,
      accountingApp?.active,
      accountingApp?.authorised,
      showMessage,
      polyglot,
      redirectToSettings,
      history,
      isExpense,
      editingAccounting,
      editingSync,
      editingGeneral,
      codeConfigIndex,
    ]
  );

  const getAccountingCodeValue = (
    typeForEdit: PaymentTypeSettings | undefined,
    codeConfigIndex: number | null | undefined
  ): number | null => {
    if (
      codeConfigIndex !== null &&
      codeConfigIndex !== undefined &&
      codeConfigIndex >= 0 &&
      typeForEdit?.accountingCodeConfig &&
      typeForEdit.accountingCodeConfig[codeConfigIndex].accountingCode !== undefined
    ) {
      return typeForEdit.accountingCodeConfig[codeConfigIndex].accountingCode;
    }
    return null;
  };

  const getAccountingCodeDescription = (
    typeForEdit: PaymentTypeSettings | undefined,
    codeConfigIndex: number | null | undefined
  ): string => {
    if (
      codeConfigIndex !== null &&
      codeConfigIndex !== undefined &&
      codeConfigIndex >= 0 &&
      typeForEdit?.accountingCodeConfig &&
      typeForEdit.accountingCodeConfig[codeConfigIndex].accountingCodeDescription !== undefined
    ) {
      return typeForEdit.accountingCodeConfig[codeConfigIndex].accountingCodeDescription;
    }
    return '';
  };

  const formik = useFormik<Omit<PaymentTypeSettings, 'id'>>({
    initialValues: {
      name: typeForEdit?.name ?? '',
      accountingCode: getAccountingCodeValue(typeForEdit, codeConfigIndex),
      accountingCodeDescription: getAccountingCodeDescription(typeForEdit, codeConfigIndex),
      accountingCodeConfig: typeForEdit?.accountingCodeConfig ?? [],
      type: typeForEdit?.type ?? typeCategory,
      provider: null,
      payrolled: !!typeForEdit?.payrolled,
      paycode: typeForEdit?.paycode ?? null,
      syncToExternal: typeForEdit?.syncToExternal ?? 'manual',
      approvalRuleId: typeForEdit?.approvalRuleId ?? null,
      membersRule: typeForEdit?.membersRule ?? UserSelectFiltersOptions.None,
      customRule: null,
      externalSyncOrganisation: typeForEdit?.externalSyncOrganisation ?? null,
      requireAttachment: typeForEdit?.requireAttachment ?? false,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: typeForEdit ? yup.string() : yup.string().required(polyglot.t('PolicyGeneralEditDrawer.fieldIsRequired')),
    }),
    onSubmit,
  });

  const confirmDelete = async () => {
    if (typeForEdit?.id) {
      try {
        await PaymentTypeSettingsAPI.delete(typeForEdit.id);
        showMessage(polyglot.t('PaymentSettingsNewTypeDrawer.successMessages.deleteType'), 'success');
        refreshAllSettings?.();
      } catch (error) {
        showMessage(`Could not deleted the benefit. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setAnchorEl(null);
        setIsOpen(false);
        setIsDelete(false);
      }
    }
  };

  const tenantOptions = useMemo(() => {
    if (!accountingApp || !accountingApp?.tenants || accountingApp?.tenants?.length === 0) return null;
    return [...accountingApp.tenants]
      .sort((a, b) => caseInsensitiveSort(a, b, (item) => item.tenantName))
      .map<OptionObject>((e: AppTenant) => ({
        label: e.tenantName,
        value: e.tenantId,
      }));
  }, [accountingApp]);

  const titleForDrawer = useMemo(() => {
    if ((codeConfigIndex === undefined || codeConfigIndex === null) && typeForEdit && editingAccounting) {
      return polyglot.t('PaymentSettingsNewTypeDrawer.newAccountingCode');
    }
    if (editingSync) return polyglot.t('PaymentSettingsNewTypeDrawer.editSyncSettings');
    return polyglot.t(typeForEdit ? 'PaymentSettingsNewTypeDrawer.editTitle' : 'PaymentSettingsNewTypeDrawer.title');
  }, [codeConfigIndex, editingAccounting, editingSync, polyglot, typeForEdit]);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{titleForDrawer}</Typography>

        {(editingGeneral || !typeForEdit) && (
          <TextfieldComponent
            name="name"
            label={polyglot.t('PaymentSettingsPage.tableColumns.name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && (formik.errors.name as string)}
            endAdornment="none"
            fullWidth
          />
        )}

        {(editingGeneral || !typeForEdit) && formik.values.type === PaymentCategoryEnum.EXPENSE && (
          <CheckboxComponent
            name="requiresAttachment"
            label={polyglot.t('NewTimePolicyGeneralStep.requiresAttachment')}
            checked={formik.values.requireAttachment}
            onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) => {
              formik.setFieldValue('requireAttachment', checked);
            }}
          />
        )}

        {editingSync && !loading && accountingAppConfigured && tenantOptions && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.sm }}>
            <Typography variant="captionSmall">
              {polyglot.t('PaymentSettingsNewTypeDrawer.syncingOrganisation')}
            </Typography>
            <RadioGroup
              name="external-sync-organisation-group"
              onChange={(event) => {
                if (!event.target.value) return;
                const matchingTenant = (accountingApp?.tenants ?? []).find((t) => t.tenantId === event.target.value);
                if (!matchingTenant) return;
                const { tenantId, tenantName } = matchingTenant;
                formik.setFieldValue('externalSyncOrganisation', {
                  id: tenantId,
                  name: tenantName,
                });
              }}
            >
              {tenantOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  labelPlacement="end"
                  value={option.value}
                  checked={
                    formik.values.externalSyncOrganisation
                      ? formik.values.externalSyncOrganisation?.id === option.value
                      : false
                  }
                  control={<StyledRadio />}
                  label={<Typography variant="title4">{option.label}</Typography>}
                  sx={{ mb: spacing.m5 }}
                />
              ))}
            </RadioGroup>
          </Box>
        )}

        {editingAccounting && accountingAppConfigured ? (
          <AutocompleteComponent
            name="accountingCode"
            label={polyglot.t('PaymentSettingsPage.tableColumns.accountingCode')}
            options={accountingCodeOptions}
            value={
              accountingCodeOptions.find(
                ({ value }: { value: number | string }) => value === formik.values.accountingCode
              ) || null
            }
            compareValue={formik.values.accountingCode}
            // @ts-ignore
            onChange={(_, x: OptionObject) => {
              if (!x) return;
              const matchingCode = accountingCodes?.find((ac) => Number(ac.code) === Number(x.value));
              formik.setFieldValue('accountingCode', +x.value);
              formik.setFieldValue('accountingCodeDescription', matchingCode?.name);
            }}
            fullWidth
          />
        ) : editingAccounting ? (
          <TextfieldComponent
            name="accountingCode"
            label={polyglot.t('PaymentSettingsPage.tableColumns.accountingCode')}
            value={formik.values.accountingCode}
            onChange={formik.handleChange}
            error={formik.touched.accountingCode && !!formik.errors.accountingCode}
            helperText={formik.touched.accountingCode && (formik.errors.accountingCode as string)}
            endAdornment="none"
            type="number"
            fullWidth
          />
        ) : null}

        {editingAccounting && (
          <TextfieldComponent
            name="accountingCodeDescription"
            label={polyglot.t('PaymentSettingsPage.tableColumns.accountingCodeDescription')}
            value={formik.values.accountingCodeDescription}
            onChange={formik.handleChange}
            error={formik.touched.accountingCodeDescription && !!formik.errors.accountingCodeDescription}
            helperText={formik.touched.accountingCodeDescription && (formik.errors.accountingCodeDescription as string)}
            endAdornment="none"
            fullWidth
          />
        )}

        {editingSync && accountingAppConfigured && (
          <SelectComponent
            name="syncToExternal"
            label={polyglot.t('ContractorInvoiceModal.syncMethod')}
            options={SyncToExternalOptionList}
            value={formik.values.syncToExternal}
            onChange={(event) => {
              if (!event.target.value) return;
              formik.setFieldValue('syncToExternal', event.target.value);
            }}
          />
        )}

        {formik.values.type === PaymentCategoryEnum.EXPENSE && editingPayment && (
          <Box>
            <Box sx={{ mb: spacing.s2 }}>
              <Typography variant="captionSmall" color="Grey">
                {polyglot.t('General.name')}
              </Typography>
              <Typography variant="caption">{typeForEdit.name}</Typography>
            </Box>
            <CheckboxComponent
              name="payrolled"
              label={<Typography variant="caption">{PayrolledOptionsEnum.PayViaPayroll}</Typography>}
              checked={!!formik.values.payrolled}
              onChange={formik.handleChange}
            />
          </Box>
        )}

        {formik.values.payrolled && editingPayment && (
          <TextfieldComponent
            name="paycode"
            label={polyglot.t('PayItemModule.payCode')}
            value={formik.values.paycode}
            onChange={formik.handleChange}
            error={formik.touched.paycode && !!formik.errors.paycode}
            helperText={(formik.touched.paycode && formik.errors.paycode) ?? ' '}
            endAdornment="none"
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
          />
        </Box>

        <NotificationModal
          isOpen={isDelete}
          onClose={() => setIsDelete(false)}
          anchorEl={anchorEl}
          takeAction={confirmDelete}
          message="Are you sure you want to delete this payment setting type?"
          callToAction="Yes"
        />
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.MONEY_PRO}
          messageSuffix="proGeneric"
        />
      </Form>
    </FormikProvider>
  );
};
