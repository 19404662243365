import { ReactComponent as Zelt } from '@/images/zelt-text.svg';

export const PayrollLogoZelt = (props: React.CSSProperties) => {
  return (
    <Zelt
      style={{
        borderRadius: '50%',
        backgroundColor: '#333',
        fill: 'white',
        height: '30px',
        width: '30px',
        padding: '5px',
        ...props,
      }}
    />
  );
};
