import { RootStyle } from '@v2/styles/root.styles';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { PayItemEndpoints } from '@v2/feature/payroll/features/pay-item/pay-item.api';
import { UpcomingPayItemsTable } from '@/component/dashboard/userDetails/pay-items/components/upcoming-pay-items-table.component';
import { useCallback } from 'react';

interface PageProps {
  readonly userId: number;
}

export const UserPayItemsPage = ({ userId }: PageProps) => {
  const {
    data: userOneOffPayments,
    mutate: refreshPI,
    isLoading: loading,
  } = useApiClient(PayItemEndpoints.getUserOneOffPayments(userId), { suspense: false });

  const { polyglot } = usePolyglot();

  const refreshUpcoming = useCallback(async () => {
    if (refreshPI) await refreshPI();
  }, [refreshPI]);

  const combinedOneOffPayments = [];

  if (userOneOffPayments?.upcoming && userOneOffPayments?.upcoming.length > 0) {
    combinedOneOffPayments.push(
      ...userOneOffPayments?.upcoming.map((p) => {
        return { ...p, paidImpliedStatus: false };
      })
    );
  }
  if (userOneOffPayments?.history && userOneOffPayments?.history.length > 0) {
    combinedOneOffPayments.push(
      ...userOneOffPayments?.history.map((p) => {
        return { ...p, paidImpliedStatus: true };
      })
    );
  }

  // TODO: @polyglot-later
  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">{polyglot.t('PayItemModule.payItems')}</Typography>} />
      <ContentWrapper sx={{ mt: spacing.m20 }} loading={loading}>
        <UpcomingPayItemsTable
          title=""
          userId={userId}
          oneOffPayments={combinedOneOffPayments.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )}
          refresh={refreshUpcoming}
          showAdd
          showEdit
        />

        {/* Show all one off payments (historic and upcoming) in one table - on Chris' advice */}
        {/* 
        <UpcomingPayItemsTable
          title="History"
          userId={userId}
          oneOffPayments={userOneOffPayments?.history ?? []}
          refresh={refreshUpcoming}
        /> */}
      </ContentWrapper>
    </RootStyle>
  );
};
