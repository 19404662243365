import React, { useState } from 'react';

import { ButtonComponent } from '@v2/components/forms/button.component';
import { HolidayDrawer } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-drawer.component';
import { HolidaysTable } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holidays-table.component';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface SectionProps {
  readonly holidayCalendar: HolidayCalendarDto;
  readonly refresh: () => Promise<void>;
}

export const HolidayCalendarHolidaysSection = ({ holidayCalendar, refresh }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SettingsSectionContent
      title={polyglot.t('HolidayCalendarModule.calendar')}
      headerWidth="100%"
      contentWidth="100%"
      buttons={[
        <ButtonComponent
          sizeVariant="small"
          colorVariant="primary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {polyglot.t('HolidayCalendarModule.newHoliday')}
        </ButtonComponent>,
      ]}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <HolidaysTable
                    calendarId={holidayCalendar.id}
                    holidays={holidayCalendar.holidays ?? []}
                    refresh={refresh}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <HolidayDrawer isOpen={isOpen} setIsOpen={setIsOpen} calendarId={holidayCalendar.id} refresh={refresh} />
    </SettingsSectionContent>
  );
};
