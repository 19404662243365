import React from 'react';

import { Box } from '@mui/material';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { Typography } from '@v2/components/typography/typography.component';
import { themeColors } from '@v2/styles/colors.styles';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ActivationLock,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
} from '@/v2/feature/device/device.dto';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmActivationLockPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): ActivationLock => {
    return {
      ActivationLockAllowedWhileSupervised:
        configurablePoliciesSelected?.configurableActivationLock?.ActivationLockAllowedWhileSupervised ?? false,
    };
  };
  const initialValues: ActivationLock = getPolicy();

  function analyzePolicyBeforeSave(policy: ActivationLock): ActivationLock {
    return { ActivationLockAllowedWhileSupervised: policy.ActivationLockAllowedWhileSupervised };
  }

  const formik = useFormik<ActivationLock>({
    initialValues,
    onSubmit: async (form: ActivationLock) => {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableActivationLock: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  return (
    <>
      <Box>
        <Typography variant="title2">Activation lock</Typography>
        <FormikProvider value={formik}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap80, mt: '20px' }}>
            <Typography variant="caption">Activation lock</Typography>
            <SwitchComponent
              checked={formik.values.ActivationLockAllowedWhileSupervised}
              name="automaticOsUpdate"
              sx={{ marginLeft: 'auto' }}
              onChange={async (_e, enabled) => {
                formik.setFieldValue('ActivationLockAllowedWhileSupervised', enabled);
              }}
            />
          </Box>
          <Box sx={{ width: '80%' }}>
            <Typography variant="captionSmall" sx={{ color: themeColors.Grey, my: spacing.m5 }}>
              Enable the collection of code to bypass Activation Lock on enrolled devices. To reliably collect the
              bypass code, ask device owners to disable “Find My” and then enable it again in the settings of their
              device.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: '30px' }}>
            <ButtonComponent
              onClick={() => formik.handleSubmit()}
              fullWidth
              colorVariant="primary"
              sizeVariant="medium"
            >
              Save
            </ButtonComponent>
          </Box>
        </FormikProvider>
      </Box>
    </>
  );
};
