import { useCallback, useMemo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { ColumnDef } from '@tanstack/react-table';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { isSameCountryCode } from '@/v2/infrastructure/country/country.util';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

const iconSize = { width: 14, height: 14 } as const;
const identity = <T,>(row: T) => row;

const PAYROLL_ALL_SCOPE = ['payroll:all'] as const;

type RemoteTaxTableItem = Pick<
  UserPayrollDto,
  'changeReason' | 'effectiveDate' | 'payrollId' | 'updatedAt' | 'updatedBy'
> & {
  remotePayrollValues: RemotePayrollValues;
  record: UserPayrollDto;
};

type TaxRemoteTableProps = {
  payroll: EntriesAndEffectiveRecord<UserPayrollDto> | null | undefined;
  userId: number;
  onEditRowClick: (record: UserPayrollDto) => void;
  countryCode?: string;
};

type TaxRemoteTableResult = [
  RemoteTaxTableItem[] | undefined,
  ColumnDef<RemoteTaxTableItem, RemoteTaxTableItem>[] | undefined
];

export const useTaxRemoteTable = ({
  onEditRowClick,
  payroll,
  userId,
  countryCode,
}: TaxRemoteTableProps): TaxRemoteTableResult => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const filteredEntries = useMemo(() => {
    if (!countryCode) return payroll?.entries;
    return payroll?.entries.filter((e) => e.countryCode === countryCode);
  }, [countryCode, payroll?.entries]);

  const generateTableColumnsFromEntries = useCallback(
    (entries: readonly UserPayrollDto[]) => {
      const columns = new Map<string, ColumnDef<RemoteTaxTableItem, RemoteTaxTableItem>>();

      for (const entry of entries) {
        if (!entry.remotePayrollValues) continue;
        if (countryCode && !isSameCountryCode(entry.countryCode, countryCode)) continue;
        for (const key in entry.remotePayrollValues) {
          if (columns.has(key)) continue;
          if (!/string|number|boolean/.test(typeof entry.remotePayrollValues[key])) continue;

          columns.set(key, {
            header: () => key,
            accessorFn: identity,
            id: key,
            enableSorting: false,
            size: 80,
            cell: ({ row: { original } }) => {
              return (
                <Typography
                  title={original.remotePayrollValues?.[key]?.toString()}
                  sx={{ ...themeFonts.caption, whiteSpace: 'nowrap' }}
                >
                  {original.remotePayrollValues?.[key]?.toString()}
                </Typography>
              );
            },
          });
        }
      }

      return columns.values();
    },
    [countryCode]
  );

  const columnData = useMemo<ColumnDef<RemoteTaxTableItem, RemoteTaxTableItem>[]>(
    () => [
      {
        header: () => 'Payroll ID',
        accessorFn: identity,
        id: 'payroll-id',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.payrollId}</div>,
      },
      ...(filteredEntries ? generateTableColumnsFromEntries(filteredEntries) : []),
      {
        header: () => 'Last edited by',
        accessorFn: identity,
        id: 'last-edit-user',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) =>
          original.updatedBy ? <UserAvatar userId={original.updatedBy} size="xxsmall" /> : <EmptyCell />,
      },
      {
        header: () => 'Last edited on',
        accessorFn: identity,
        id: 'last-edit-date',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <DateLabelComponent date={original.updatedAt} />,
      },
      {
        header: () => 'Reason',
        accessorFn: identity,
        id: 'change-reason',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <Typography title={original.changeReason ?? ''} sx={{ ...themeFonts.caption, whiteSpace: 'nowrap' }}>
            {original.changeReason}
          </Typography>
        ),
      },
      {
        header: () => 'Effective date',
        accessorFn: identity,
        id: 'effective-date',
        enableSorting: false,
        size: 80,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },
      {
        header: () => '',
        accessorFn: identity,
        id: 'actions',
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => (
          <ScopesControl scopes={PAYROLL_ALL_SCOPE} context={scopesContext}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                title="edit"
                onClick={() => {
                  onEditRowClick(original.record);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </div>
          </ScopesControl>
        ),
      },
    ],
    [filteredEntries, generateTableColumnsFromEntries, onEditRowClick, scopesContext]
  );

  const tableRowData = useMemo(
    () =>
      (filteredEntries ?? []).reduce((result, entry) => {
        const { remotePayrollValues, changeReason, effectiveDate, payrollId, updatedAt, updatedBy } = entry;
        if (remotePayrollValues) {
          result.push({
            record: entry,
            remotePayrollValues,
            changeReason,
            effectiveDate,
            payrollId,
            updatedAt,
            updatedBy,
          });
        }
        return result;
      }, [] as RemoteTaxTableItem[]),
    [filteredEntries]
  );

  if (!payroll) return [undefined, undefined];

  return [tableRowData, columnData];
};
