import { useContext, useMemo } from 'react';

import { Redirect, Switch, useLocation } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  BENEFITS_COMPANY_ROUTE,
  BENEFITS_ME_ROUTE,
  EXPENSES_COMPANY_ROUTE,
  EXPENSES_ME_ROUTE,
  EXPENSES_TEAM_ROUTE,
  INVOICES_COMPANY_ROUTE,
  INVOICES_ME_ROUTE,
  INVOICES_TEAM_ROUTE,
  MONEY_ROUTE,
  PAYMENT_COMPANY_ROUTE,
  PAYMENT_ME_ROUTE,
  PAYMENT_TEAM_ROUTE,
  PAYROLL_COMPANY_ROUTE,
  PAYROLL_ME_ROUTE,
} from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';
import { BenefitsRouter } from '@/v2/feature/benefits/benefits.router';
import { ExpenseRouter } from '@/v2/feature/expense/expense.router';
import { InvoiceRouter } from '@/v2/feature/invoice/invoice.router';
import { PaymentsRouter } from '@/v2/feature/payments/payments.router';
import { PayrollRouter } from '@/v2/feature/payroll/payroll.router';

export const MoneyRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext(user);
  const location = useLocation();

  const hasPayrollAllScope = useMemo(() => hasScopes(['payroll:all']), [hasScopes]);
  return (
    <Switch>
      <RouteScopesControl
        scopes={['payroll:read']}
        context={scopesContext}
        path={[PAYROLL_COMPANY_ROUTE, PAYROLL_ME_ROUTE]}
        component={PayrollRouter}
      />

      <RouteScopesControl
        scopes={['payments']}
        path={[PAYMENT_COMPANY_ROUTE, PAYMENT_ME_ROUTE, PAYMENT_TEAM_ROUTE]}
        context={scopesContext}
        component={PaymentsRouter}
      />

      <RouteScopesControl
        scopes={['invoices']}
        path={[INVOICES_COMPANY_ROUTE, INVOICES_ME_ROUTE, INVOICES_TEAM_ROUTE]}
        context={scopesContext}
        component={InvoiceRouter}
      />

      <RouteScopesControl
        scopes={['expenses']}
        path={[EXPENSES_COMPANY_ROUTE, EXPENSES_ME_ROUTE, EXPENSES_TEAM_ROUTE]}
        context={scopesContext}
        component={ExpenseRouter}
      />
      {/* BENEFIT */}
      {!user.navigation?.benefits?.isHidden && (
        <RouteScopesControl
          scopes={['insurance', 'pension']}
          context={scopesContext}
          path={[BENEFITS_ME_ROUTE, BENEFITS_COMPANY_ROUTE]}
          component={BenefitsRouter}
        />
      )}

      {(hasPayrollAllScope || (hasPayrollAllScope && !location.pathname?.includes('/me'))) && (
        <RouteScopesControl scopes={['payroll:read']} context={scopesContext} path={MONEY_ROUTE}>
          <Redirect to={PAYROLL_COMPANY_ROUTE} />
        </RouteScopesControl>
      )}
      {Boolean(user.contractType === 'Employee') && !Boolean(user?.navigation?.salary?.isHidden) && (
        <RouteScopesControl scopes={['payroll:read']} context={scopesContext} path={MONEY_ROUTE}>
          <Redirect to={PAYROLL_ME_ROUTE} />
        </RouteScopesControl>
      )}
      {/* REDIRECTS */}
      {canAccessScopes(user, ['expenses:all']) && <Redirect from={MONEY_ROUTE} to={EXPENSES_COMPANY_ROUTE} />}
      {canAccessScopes(user, ['expenses:manager']) && <Redirect from={MONEY_ROUTE} to={EXPENSES_TEAM_ROUTE} />}
      {canAccessScopes(user, ['expenses']) && <Redirect from={MONEY_ROUTE} to={EXPENSES_ME_ROUTE} />}
      {canAccessScopes(user, ['invoices:all']) && <Redirect from={MONEY_ROUTE} to={INVOICES_COMPANY_ROUTE} />}
      {canAccessScopes(user, ['invoices:manager']) && <Redirect from={MONEY_ROUTE} to={INVOICES_TEAM_ROUTE} />}
      {canAccessScopes(user, ['invoices']) && <Redirect from={MONEY_ROUTE} to={INVOICES_ME_ROUTE} />}
      {canAccessScopes(user, ['payments:all']) && <Redirect from={MONEY_ROUTE} to={PAYMENT_COMPANY_ROUTE} />}
      {canAccessScopes(user, ['payments:manager']) && <Redirect from={MONEY_ROUTE} to={PAYMENT_TEAM_ROUTE} />}
      {canAccessScopes(user, ['payments']) && <Redirect from={MONEY_ROUTE} to={PAYMENT_ME_ROUTE} />}
    </Switch>
  );
};
