import { useEffect, useState } from 'react';

import { ExpenseModal } from '@v2/feature/payments/pages/components/expense-modal.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { ExpenseEndpoints } from '@/api-client/expense.api';
import { ExpenseRequestTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const ExpenseRequestActionItem = ({
  expenseRequestTodo,
  refresh,
  afterClose,
}: {
  readonly expenseRequestTodo: ExpenseRequestTodo | undefined;
  readonly refresh: () => Promise<void>;
  readonly afterClose: () => void;
}) => {
  const { data: expense } = useApiClient(
    expenseRequestTodo ? ExpenseEndpoints.getExpensesById(expenseRequestTodo.id, expenseRequestTodo.userId) : null,
    { suspense: false }
  );

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsViewOpen(Boolean(expenseRequestTodo));
  }, [expenseRequestTodo]);

  return (
    <ExpenseModal
      isOpen={isViewOpen}
      setIsOpen={setIsViewOpen}
      selectedExpense={expense}
      onActionPerformed={refresh}
      currentUserIsAdmin={false}
      onClose={() => {
        setIsViewOpen(false);
      }}
      afterClose={afterClose}
    />
  );
};
