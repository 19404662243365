import React from 'react';

import { Stack, SxProps, Theme } from '@mui/material';

import { ReactComponent as Completed } from '@/images/side-bar-icons/ok-green.svg';
import {
  ReactComponent as Draft,
  ReactComponent as InProgress,
  ReactComponent as PartiallyCompleted,
} from '@/images/side-bar-icons/WaitingFull.svg';
import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';
import { formatPayrunState } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunStatusProps = {
  state: PayrunStates;
  variant?: TypographyVariant;
  iconSize?: number;
  color?: string;
  sx?: SxProps<Theme>;
};

export const PayrunStatus = ({ state, sx, color, iconSize = 16, variant = 'caption' }: PayrunStatusProps) => {
  const { polyglot } = usePolyglot();
  const iconHW = { height: iconSize, width: iconSize };
  const icon = {
    [PayrunStates.draft]: () => <Draft {...iconHW} />,
    [PayrunStates.inProgress]: () => <InProgress {...iconHW} />,
    [PayrunStates.partiallyCompleted]: () => <PartiallyCompleted {...iconHW} />,
    [PayrunStates.completed]: () => <Completed {...iconHW} style={{ fill: themeColors.Green }} />,
  }[state]();

  return (
    <Stack sx={{ flexFlow: 'row', gap: spacing.g5, alignItems: 'center', ...sx }}>
      {icon}
      <Typography variant={variant} sx={{ color }}>
        {formatPayrunState(state, polyglot)}
      </Typography>
    </Stack>
  );
};
