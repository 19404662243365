import { Box, Link, Typography } from '@mui/material';

import { secondaryLink } from '@/v2/styles/links.styles';

const AuthFooterLink = ({ href, title }: { href: string; title: string }) => (
  <Typography sx={{ mx: 1 }}>
    <Link href={href} target="_blank" rel="noopener noreferrer" sx={secondaryLink}>
      {title}
    </Link>
  </Typography>
);

export const AuthFooter = () => {
  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'start' }}>
        <AuthFooterLink href="mailto:support@zelt.app" title="Contact us" />
        <AuthFooterLink href="https://zelt.app/legal/privacy-policy/" title="Privacy" />
        <AuthFooterLink href="https://zelt.app/legal/terms/" title="Terms" />
        <AuthFooterLink href="https://zelt.app/legal/" title="Legal" />
      </Box>
    </Box>
  );
};
