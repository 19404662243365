import { Fragment, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { isBefore, isToday } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { TIME_ME_CALENDAR_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { getDatesBetweenMonths, getTodayAndDateAfter30Days } from '@/v2/feature/dashboard/dashboard.util';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { UserCalendarEmptyState } from '@/v2/feature/dashboard/features/sections/user-calendar/components/user-calendar-empty-state.component';
import { UserCalendarView } from '@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-calendar-view.component';
import { UserEventCard } from '@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-event-card.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import {
  AbsenceProps,
  MappedEventsProps,
} from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.interface';
import {
  getCategorizedData,
  getCurrentDateAndFutureEvents,
  getFilteredData,
} from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util';
import { TimeDrawerContent } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-drawer-content.component';
import { CalendarWidgetReach } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserCalendarVersionBig = ({
  calendarBig,
  readOnly = false,
  refreshWidgetData,
}: {
  calendarBig: CalendarWidgetReach;
  readOnly?: boolean;
  refreshWidgetData?: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const today = new LocalDate().getDate();

  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const [selectedAbsence, setSelectedAbsence] = useState<AbsenceProps | undefined>(undefined);

  const weekDaysFromMonToSun = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const signedInUser = getCachedUserById(state.user.userId);
  const routerHistory = useHistory();

  const filteredData = useMemo(() => {
    const remainingMonthRange = getTodayAndDateAfter30Days();
    return getFilteredData(
      remainingMonthRange,
      nonTerminatedCachedUsers,
      calendarBig.view,
      signedInUser,
      calendarBig?.time?.requests,
      calendarBig?.fellowReportees
    );
  }, [
    calendarBig?.time.requests,
    nonTerminatedCachedUsers,
    calendarBig?.view,
    signedInUser,
    calendarBig?.fellowReportees,
  ]);

  const structuredData = useMemo(() => {
    const weekRange = getTodayAndDateAfter30Days();
    const datesArray = getDatesBetweenMonths(weekRange[0], weekRange[1]);
    let eventData: MappedEventsProps[] = [];
    datesArray.map((d) => {
      const currentDate = new LocalDate(d).getDate();
      return getCategorizedData(eventData, filteredData, currentDate);
    });
    return eventData ?? [];
  }, [filteredData]);

  const todayAndFutureEvents = useMemo(() => {
    const todayDate = new LocalDate().getDate();
    return getCurrentDateAndFutureEvents(structuredData, todayDate);
  }, [structuredData]);

  const calendarData = useMemo(() => {
    const getDaysInMonth = (year: any, month: any) => {
      return new Date(year, month + 1, 0).getDate();
    };

    const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth());
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
    const result = [];
    let week = [];
    // Calculate the index of the first day of the week (Monday = 1, Sunday = 0)
    const startDayIndex = firstDay === 0 ? 6 : firstDay - 1;
    // Add empty cells for days before the first day of the month
    for (let day = 0; day < startDayIndex; day++) {
      week.push(<td key={`empty-${day}`} className="cw-table-data" />);
    }
    // Generate calendar cells for each day in the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new LocalDate(new Date(today.getFullYear(), today.getMonth(), day)).getDate();
      const isBeforeToday = isBefore(currentDate, today);
      // const eventCount =
      //   todayAndFutureEvents?.filter((t) => isSameDay(new LocalDate(t.currentDate).getDate(), currentDate)).length ?? 0;
      week.push(
        <td key={`day-${day}`} className="cw-table-data">
          <Box
            key={`${day}-${day}-day`}
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="title4"
              sx={{
                color: isToday(currentDate)
                  ? themeColors.white
                  : isBeforeToday
                  ? themeColors.Grey
                  : themeColors.DarkGrey,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: isToday(currentDate) ? themeColors.DarkGrey : 'transparent',
                borderRadius: radius.br20,
                width: '25px',
                height: '25px',
              }}
            >
              {day}
            </Typography>
          </Box>
        </td>
      );

      // Start a new row for each Sunday (index 0)
      if ((startDayIndex + day) % 7 === 0) {
        result.push(<tr key={`week-${result.length}`}>{week}</tr>);
        week = [];
      }
    }

    if (week.length > 0) {
      result.push(<tr key={`week-${result.length}`}>{week}</tr>);
    }

    return result;
  }, [today]);

  const hasEvents = Boolean(todayAndFutureEvents && todayAndFutureEvents.length > 0);

  return (
    <WidgetLayout readOnly={readOnly} size="big">
      <Fragment>
        <div
          className="cs-calendar-grid"
          style={{
            pointerEvents: readOnly ? 'none' : 'inherit',
          }}
        >
          <UserCalendarView
            calendarData={calendarData}
            weekDaysFromMonToSun={weekDaysFromMonToSun}
            headerAction={() => routerHistory.push(TIME_ME_CALENDAR_ROUTE)}
          />

          {hasEvents ? (
            <div
              style={{
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: hasEvents ? 'space-between' : 'inherit',
              }}
            >
              <div className="cw-event-list">
                <Typography variant="title4">{polyglot.t('UserCalendarVersionBig.upcoming')}</Typography>
              </div>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {[...todayAndFutureEvents].slice(0, 6).map((event, idx) => (
                    <UserEventCard
                      event={event}
                      type={event.type}
                      key={`calendar-big${idx}`}
                      setSelectedAbsence={setSelectedAbsence}
                    />
                  ))}
                </Box>

                {todayAndFutureEvents && todayAndFutureEvents.length > 6 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      mt: spacing.m3,
                    }}
                  >
                    <div style={{ padding: spacing.p5 }}>
                      <a
                        href={TIME_ME_CALENDAR_ROUTE}
                        rel="noopener noreferrer"
                        style={{ color: themeColors.DarkGrey, textDecoration: 'none', ...themeFonts.caption }}
                      >
                        {polyglot.t('UserCalendarVersionBig.more', { smart_count: todayAndFutureEvents.length - 6 })}
                      </a>
                    </div>
                  </Box>
                )}
              </Box>
            </div>
          ) : (
            <div
              style={{
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className="cw-event-list">
                <Typography variant="title4">{polyglot.t('UserCalendarVersionBig.upcoming')}</Typography>
              </div>
              <Box sx={{ height: '100%', verticalAlign: 'middle' }}>
                <UserCalendarEmptyState />
              </Box>
            </div>
          )}
        </div>

        {selectedAbsence && selectedAbsence.absenceId && (
          <TimeDrawerContent
            absenceId={selectedAbsence.absenceId}
            userId={selectedAbsence.userId}
            refresh={async () => {
              if (refreshWidgetData) await refreshWidgetData();
            }}
            afterClose={() => {
              setSelectedAbsence(undefined);
            }}
          />
        )}
      </Fragment>
    </WidgetLayout>
  );
};
