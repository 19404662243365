import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Loader } from '@/v2/components/loader.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { MFA_CODE_LENGTH } from '@/v2/feature/auth/auth.dto';
import { MFACodeEntry } from '@/v2/feature/auth/components/auth-mfa-code-entry.component';
import { ResendAuthCodeLink } from '@/v2/feature/user/features/mfa/resend-auth-code-link.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type EnableMFAProps = {
  isOpen: boolean;
  onClose(): void;
};

export const EnableMFAPage = ({ isOpen, onClose }: EnableMFAProps) => {
  const { polyglot } = usePolyglot();

  const [submittingCode, setSubmittingCode] = useState(false);
  const [codeRequest, setCodeRequest] = React.useState<'needs-request' | 'requesting' | 'code-sent'>('needs-request');
  const [errorMsg, setErrorMsg] = useState('');
  const code = useRef('');

  const checkCodeAndEnableMFA = useCallback(
    (code: string) => {
      if (code.length !== MFA_CODE_LENGTH) {
        return;
      }
      (async () => {
        setSubmittingCode(true);
        try {
          await AuthAPI.updateMFA({ enableMFACode: code });
          onClose();
        } catch (err) {
          if ((err as any).response?.status === 401) {
            setErrorMsg(polyglot.t('EnableMFAPage.errorMessages.code'));
          } else {
            setErrorMsg((err as Error).toString());
          }
        } finally {
          setSubmittingCode(false);
        }
      })();
    },
    [polyglot, onClose]
  );

  useEffect(() => {
    if (!isOpen) {
      // reset codeSent state when the page is closed
      setCodeRequest('needs-request');
      return;
    }
    if (codeRequest !== 'needs-request') {
      // we have requested (or are requesting) a code
      return;
    }
    setCodeRequest('requesting');
    AuthAPI.requestMFAEnableCode().then(
      () => {
        setCodeRequest('code-sent');
      },
      (err) => {
        setErrorMsg((err as Error).toString());
      }
    );
  }, [codeRequest, isOpen]);

  if (codeRequest !== 'code-sent') {
    return <Loader loading>{polyglot.t('EnableMFAPage.requestingCode')}</Loader>;
  }

  const onSubmitHandler = (e: React.FormEvent) => {
    checkCodeAndEnableMFA(code.current);
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Stack sx={{ mb: '30px' }}>
        <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('EnableMFAPage.enterCode')}</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
          {polyglot.t('EnableMFAPage.codeMessage', { code: MFA_CODE_LENGTH })}
        </Typography>
      </Stack>
      <MFACodeEntry
        disabled={submittingCode}
        error={!!errorMsg}
        helperText={errorMsg}
        onCodeComplete={(value) => {
          code.current = value;
        }}
        sx={{ mb: '40px' }}
      />
      <LoaderButton
        sizeVariant="medium"
        colorVariant="primary"
        loading={submittingCode}
        name={polyglot.t('General.continue')}
        fullWidth
      />
      <ResendAuthCodeLink sx={{ mt: '30px' }} />
    </form>
  );
};
