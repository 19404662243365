import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitDto;
}

export const LoanBorrowedDrawer = ({ isOpen, setIsOpen, userBenefit }: DrawerProps) => {
  return userBenefit.openingBalance === null ? null : (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <LoanBorrowedDrawerContent userBenefit={userBenefit} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefit: UserCustomBenefitDto;
}

export const LoanBorrowedDrawerContent = ({ userBenefit }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Box>
      <Typography variant="title2">{polyglot.t('BenefitModule.borrowed')}</Typography>
      <Box key="headers" sx={{ mt: '30px', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.date')}
        </Typography>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.amount')}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="caption">
          {new LocalDate(userBenefit.effectiveDate).toLocaleDateString(undefined, {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })}
        </Typography>
        <Typography variant="title4">
          {formatMoney({ amount: userBenefit.openingBalance ?? 0, asDecimal: true })}
        </Typography>
      </Box>
    </Box>
  );
};
