import { useCallback } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { BulkFileUploadInput } from '@/v2/components/upload/bulk-file-upload.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const iconSize = { width: 14, height: 14 } as const;

export const DocumentBulkUploadFlow = () => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const returnToDocumentsCompanyPage = useCallback(() => {
    routerHistory.goBack();
  }, [routerHistory]);

  // const existingDocumentsForBulkUpload = useMemo(() => {
  //   return bulkUploadState?.bulkDocuments?.length > 0
  //     ? bulkUploadState?.bulkDocuments.map((doc) => doc.file?.file)
  //     : [];
  // }, [bulkUploadState?.bulkDocuments]);

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <Stack sx={{ flexFlow: 'row', width: '70%', mx: 'auto' }}>
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', left: spacing.m50 }}
          onClick={() => returnToDocumentsCompanyPage()}
        >
          <Back {...iconSize} />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          overflow: 'hidden auto',
          pb: spacing.p30,
          width: '1000px',
          maxWidth: '90vw',
          mx: 'auto',
          mt: spacing.mt40,
        }}
      >
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {polyglot.t('DocumentBulkUploadFlow.title')}
        </Typography>
        <BulkFileUploadInput
          onComplete={(success: number) => {
            if (success > 0) showMessage(`Successfully uploaded ${success} documents`, 'success');
          }}
          finishBulkUploadSession={() => {
            returnToDocumentsCompanyPage();
          }}
          skipUpload={true}
          onChange={async (_: any, file: File | undefined) => {
            try {
              if (!file) return;
            } catch (error) {
              showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.parsingCsv'), 'error');
              console.error(':::: ERROR PARSING FILES :::::', error);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
