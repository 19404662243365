import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { ManageEmployeeMembershipDrawer } from '@v2/feature/benefits/subfeature/pension/components/manage-employee-membership-drawer.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto, UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { PayrollAPI } from '@v2/feature/payroll/payroll.api';
import { useUserProfileConfig } from '@v2/feature/user/features/user-profile/user-profile.hook';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { formatCurrency } from '@v2/util/currency-format.util';
import { dateAPItoDisplay } from '@v2/util/date-format.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PayrunEntry } from '@/models';
import { themeColors } from '@/v2/styles/colors.styles';

interface PastContributionProps {
  readonly payruns: readonly PayrunEntry[];
}

const PastContributionsList = ({ payruns }: PastContributionProps) => {
  const currentUserColumn = useMemo<ColumnDef<PayrunEntry, PayrunEntry>[]>(
    () => [
      {
        header: () => 'Past contributions',
        id: 'paymentDate',
        enableSorting: false,
        size: 100,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div>{dateAPItoDisplay(original?.metadata?.paymentDate) ?? <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'Tax year',
        id: 'taxYear',
        enableSorting: false,
        size: 100,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <div>{original?.metadata?.taxYear ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Period',
        id: 'period',
        enableSorting: false,
        size: 100,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <div>{original?.metadata?.period ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Gross',
        id: 'gross',
        enableSorting: false,
        size: 100,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <div>{formatCurrency(original?.metadata?.gross) ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Employee',
        id: 'employee',
        enableSorting: false,
        size: 100,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div>{formatCurrency(original?.metadata?.employeePensionContribution) ?? <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'Employer',
        id: 'employer',
        enableSorting: false,
        size: 100,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div>{formatCurrency(original?.metadata?.employerPensionContribution) ?? <EmptyCell />}</div>
        ),
      },
      {
        header: () => 'Total Contributions',
        id: 'contributions',
        size: 100,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div>
            {formatCurrency(
              original?.metadata?.employeePensionContribution + original?.metadata?.employerPensionContribution
            ) ?? <EmptyCell />}
          </div>
        ),
      },
    ],
    []
  );
  return <BasicTable rowData={[...payruns]} columnData={currentUserColumn} />;
};

interface Props {
  readonly userId: number;
  readonly employeePension: UserPensionDto | null;
  onEmployeeUpdate: () => Promise<void>;
  isEnrolDrawerOpen: boolean;
  setIsEnrolDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly loading: boolean;
}

export const PensionMe = ({
  userId,
  employeePension,
  onEmployeeUpdate,
  isEnrolDrawerOpen,
  setIsEnrolDrawerOpen,
  loading,
}: Props): JSX.Element => {
  const [payruns, setPayruns] = useState<readonly PayrunEntry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { hasScopes, getScopesContext } = useScopes();
  const [state] = useContext(GlobalContext);
  const hasPensionAllScope = hasScopes(['pension:all'], getScopesContext(state.user));
  const hasPensionWriteScope = hasScopes(['pension'], getScopesContext(state.user));
  const [employeeMembership, setEmployeeMembership] = useState<UserPensionDto | null>(null);
  const [pensionSchemes, setPensionSchemes] = useState<PensionSchemeDto[]>([]);
  const { userOverviewConfig } = useUserProfileConfig(userId);

  const [showMessage] = useMessage();

  const refresh = useCallback(async () => {
    setIsLoading(true);
    try {
      const membership = await PensionAPI.getEmployeePension(userId);
      setEmployeeMembership(membership ?? null);

      if (!userOverviewConfig.companyInPension || !userOverviewConfig.inPension) {
        return;
      }
      const payruns = await PayrollAPI.getUserPayruns(userId);
      setPayruns(payruns);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [userId, userOverviewConfig.companyInPension, userOverviewConfig.inPension, showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        if (hasPensionAllScope) {
          const pensionSchemes = await PensionAPI.getCompanyPensionSchemes();
          setPensionSchemes(pensionSchemes);
        }
      } catch (error) {
        showMessage(`Could not get pension schemes. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [hasPensionAllScope, showMessage]);

  const payrunsAvailable = payruns && Boolean(payruns.length);

  return (
    <ContentWrapper loading={isLoading || loading}>
      {isEnrolDrawerOpen && employeeMembership && (
        <ManageEmployeeMembershipDrawer
          isOpen={isEnrolDrawerOpen}
          setIsOpen={setIsEnrolDrawerOpen}
          userPension={employeeMembership}
          setUserPension={setEmployeeMembership}
          pensionSchemes={pensionSchemes}
          refresh={async () => {
            await Promise.all([refresh(), onEmployeeUpdate()]);
          }}
        />
      )}

      <Box sx={{ display: 'flex', gap: spacing.gap40 }}>
        {userOverviewConfig.inPension && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap5, mr: spacing.mr20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Pension Scheme</Typography>
            {employeePension?.pensionProviderName ? (
              <Box sx={{ display: 'flex', gap: spacing.gap5, alignItems: 'center' }}>
                <Box>{getPensionLogoByProviderName(employeePension.pensionProviderName, 30)}</Box>
                <Typography sx={{ ...themeFonts.title2, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}>
                  {PensionProvidersValueToLabel[employeePension.pensionProviderName] ??
                    employeePension.pensionProviderName}
                </Typography>
              </Box>
            ) : (
              <EmptyCell />
            )}
          </Box>
        )}

        {userOverviewConfig.inPension && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap5, mx: spacing.mx20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Employee contribution</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {employeePension?.employeeContribution ?? '??'}%
            </Typography>
          </Box>
        )}

        {userOverviewConfig.inPension && hasPensionWriteScope && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap5, ml: spacing.ml20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Employer contribution</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {employeePension?.employerContribution ?? '??'}%
            </Typography>
          </Box>
        )}
      </Box>

      {userOverviewConfig.companyInPension && payrunsAvailable && (
        <Box sx={{ mt: spacing.mt40 }}>
          <PastContributionsList payruns={payruns} />
        </Box>
      )}
    </ContentWrapper>
  );
};
