import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { getDefaultBenefitImageByCategory } from '@v2/feature/benefits/benefits.util';
import { DeleteCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/delete-custom-benefit-drawer.component';
import { EditCustomBenefitBalanceDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-balance-drawer.component';
import { EditCustomBenefitGeneralDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-general-drawer.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import {
  CustomBenefitCategory,
  CustomBenefitDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import {
  isAllowanceBenefit,
  isRecurringBenefit,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateCustomBenefitAllowanceType } from '@v2/infrastructure/i18n/translate.util';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';

interface PageProps {
  readonly category: CustomBenefitCategory;
  readonly customBenefit: CustomBenefitDto;
  readonly refreshBenefit: () => Promise<void>;
}

export const CustomBenefitSettingsGeneralPage = ({ category, customBenefit, refreshBenefit }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isEditBalanceOpen, setIsEditBalanceOpen] = useState<boolean>(false);

  const isRecurring = isRecurringBenefit(customBenefit.type);
  const isAllowance = isAllowanceBenefit(customBenefit.type);

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('General.general')}
      onEdit={() => {
        setIsEditOpen(true);
      }}
    >
      <DescriptionLine field={polyglot.t('General.name')} value={customBenefit.name} />
      {customBenefit.description && (
        <DescriptionLine field={polyglot.t('General.description')} value={customBenefit.description} />
      )}
      {customBenefit.type && <DescriptionLine field={polyglot.t('General.type')} value={customBenefit.type} />}

      <DescriptionLine field={polyglot.t('General.category')} value={customBenefit.category ?? category} />
      {customBenefit.benefitLink && (
        <DescriptionLine field={polyglot.t('BenefitModule.benefitLink')} value={customBenefit.benefitLink} />
      )}

      <DescriptionLine
        field={polyglot.t('BenefitModule.logo')}
        value={
          <Box sx={{ display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
            {customBenefit?.logoUuid && customBenefit?.url ? (
              <img src={customBenefit?.url} width={16} height={16} alt="benefit-logo" />
            ) : (
              getDefaultBenefitImageByCategory(customBenefit.category, '25px', 'auto')
            )}
          </Box>
        }
      />

      {(isRecurring || isAllowance) && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
          <Typography variant="title3">{isRecurring ? 'Contributions' : 'Allowance'}</Typography>
          <IconButton key="edit" sx={tableIconButtonSx} onClick={() => setIsEditBalanceOpen(true)}>
            <EditIcon {...actionIconSize} />
          </IconButton>
        </Box>
      )}
      {isRecurring && (
        <DescriptionLine
          field={polyglot.t('BenefitModule.employerContribution')}
          value={
            customBenefit.defaultEmployerContribution ? (
              formatMoney({ amount: customBenefit.defaultEmployerContribution, asDecimal: true })
            ) : (
              <EmptyCell />
            )
          }
        />
      )}
      {isRecurring && (
        <DescriptionLine
          field={polyglot.t('BenefitModule.employeeContribution')}
          value={
            customBenefit.defaultEmployeeContribution ? (
              formatMoney({ amount: customBenefit.defaultEmployeeContribution, asDecimal: true })
            ) : (
              <EmptyCell />
            )
          }
        />
      )}
      {isAllowance && (
        <DescriptionLine
          field={polyglot.t('BenefitModule.standardAllowance')}
          value={
            customBenefit.defaultOpeningBalance ? (
              formatMoney({ amount: customBenefit.defaultOpeningBalance, asDecimal: true })
            ) : (
              <EmptyCell />
            )
          }
        />
      )}
      {isAllowance && (
        <DescriptionLine
          field={polyglot.t('BenefitModule.allowanceType')}
          value={
            customBenefit.allowanceType ? (
              translateCustomBenefitAllowanceType(customBenefit.allowanceType, polyglot)
            ) : (
              <EmptyCell />
            )
          }
        />
      )}

      <Box sx={{ mt: '30px' }}>
        <ButtonComponent
          sizeVariant="small"
          colorVariant="text"
          onClick={() => setIsDeleteOpen(true)}
          style={{ margin: 0, padding: 0, color: themeColors.RedDark }}
        >
          {polyglot.t('BenefitModule.deleteBenefit')}
        </ButtonComponent>
      </Box>

      <EditCustomBenefitGeneralDrawer
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        customBenefit={customBenefit}
        refresh={refreshBenefit}
      />

      <DeleteCustomBenefitDrawer isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} customBenefit={customBenefit} />

      {(isRecurring || isAllowance) && (
        <EditCustomBenefitBalanceDrawer
          isOpen={isEditBalanceOpen}
          setIsOpen={setIsEditBalanceOpen}
          customBenefit={customBenefit}
          refresh={refreshBenefit}
        />
      )}
    </BenefitsSettingsPageSection>
  );
};
