import { Redirect, Route, Switch } from 'react-router-dom';

import { APIMonitoringDashboard } from './pages/partner-api-monitoring.page';

import { SUPER_ADMIN_PARTNER_API_ROUTE } from '@/lib/routes';

export const SuperAdminPartnerAPIMonitoringRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route path={SUPER_ADMIN_PARTNER_API_ROUTE}>
        <APIMonitoringDashboard />
      </Route>

      <Redirect to={SUPER_ADMIN_PARTNER_API_ROUTE} />
    </Switch>
  );
};
