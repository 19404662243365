import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import {
  BasicBankAccountDetails,
  PaymentCategory,
  PaymentDto,
  PaymentInstitutionDto,
  PaymentType,
  SinglePaymentRequestDto,
} from '@/v2/feature/payments/payments.dto';

interface PaymentContextStateValue {
  readonly payments?: readonly PaymentDto[];
  readonly requests: readonly SinglePaymentRequestDto[];
  readonly payerDetails?: BasicBankAccountDetails;
  readonly institution?: PaymentInstitutionDto;
  readonly executionDateTime?: Date;
}

const defaultRequest: SinglePaymentRequestDto = {
  reference: '',
  amount: 0,
  category: '' as PaymentCategory,
  type: '' as PaymentType,
  currency: 'GBP',
} as const;

const defaultContext: PaymentContextStateValue = {
  requests: [defaultRequest],
} as const;

const defaultState: PaymentContextState = [defaultContext, (_: SetStateAction<PaymentContextStateValue>) => {}];

type PaymentContextState = [PaymentContextStateValue, Dispatch<SetStateAction<PaymentContextStateValue>>];

const PaymentContext = createContext<PaymentContextState>(defaultState);

export const usePaymentContext = () => {
  const contextState = useContext(PaymentContext);
  if (contextState === null) {
    throw new Error('useMakePaymentContext must be used within a MakePaymentContextProvider tag');
  }
  return contextState;
};

export const getRequestFromPayment = (payment: PaymentDto): SinglePaymentRequestDto => ({
  reference: payment.reference,
  amount: payment.amount,
  category: payment.category,
  type: payment.type,
  userId: payment.userId,
  currency: payment.currency,
});

interface PaymentContextProviderProps {
  readonly payments?: readonly PaymentDto[];
  readonly children: ReactNode;
}

export const PaymentContextProvider = ({ children, payments }: PaymentContextProviderProps): JSX.Element => {
  const ctx = payments
    ? { payments, requests: payments.map((payment) => getRequestFromPayment(payment)) }
    : defaultContext;
  const [state, setState] = useState<PaymentContextStateValue>(ctx);
  return <PaymentContext.Provider value={[state, setState]}>{children}</PaymentContext.Provider>;
};
