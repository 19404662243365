import axios from 'axios';

import { ZeltDocumentType } from '@/lib/documents';
import { DocumentTypeForTable } from '@/v2/feature/documents/documents-settings.page';

export class DocumentTypeAPI {
  static async createNewCustomDocumentType(documentType: ZeltDocumentType): Promise<void> {
    await axios.post('/apiv2/documents/custom-types', documentType);
  }

  static async updateCustomDocumentType(documentType: ZeltDocumentType): Promise<void> {
    await axios.patch(`/apiv2/documents/custom-types/${documentType.id}`, documentType);
  }

  static async getAllDocumentTypesForSettings(): Promise<ZeltDocumentType[]> {
    return (await axios.get('/apiv2/documents/custom-types/settings')).data;
  }

  static async hideCustomDocumentType(documentType: DocumentTypeForTable): Promise<void> {
    await axios.patch(`/apiv2/documents/custom-types/${documentType.id}/hide`);
  }

  static async showCustomDocumentType(documentType: DocumentTypeForTable): Promise<void> {
    await axios.patch(`/apiv2/documents/custom-types/${documentType.id}/show`);
  }

  static async deleteCustomDocumentType(documentTypeId: number): Promise<void> {
    await axios.delete(`/apiv2/documents/custom-types/${documentTypeId}`);
  }
}
