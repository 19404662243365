import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  UserBenefitRequest,
  UserCustomBenefitRequestType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE } from '@/lib/routes';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userId: number;
  readonly userBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const BenefitOptOutDrawer = ({ isOpen, setIsOpen, userId, userBenefit, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <BenefitOptOutDrawerContent userId={userId} userBenefit={userBenefit} setIsOpen={setIsOpen} refresh={refresh} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userId: number;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const BenefitOptOutDrawerContent = ({ userId, setIsOpen, userBenefit, refresh }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const location = useLocation();

  const isMePage = useMemo(() => location.pathname.includes('/me/'), [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (values: UserBenefitRequest) => {
      try {
        setIsLoading(true);
        const { optedOut } = await CustomBenefitAPI.requestUserBenefitOptOut(
          userId,
          userBenefit.customBenefitId,
          userBenefit.id,
          values
        );

        if (optedOut)
          history.push(isMePage ? BENEFITS_ME_ROUTE : generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId }));
        else await refresh();

        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setIsLoading(false);
    },
    [history, isMePage, userId, userBenefit.customBenefitId, userBenefit.id, refresh, setIsOpen, showMessage, polyglot]
  );

  const formik = useFormik<UserBenefitRequest>({
    initialValues: {
      type: UserCustomBenefitRequestType.OptOut,
      effectiveDate: new LocalDate().toDateString(),
      notes: '',
      amount: null,
      numberOfInstallments: null,
      employerContribution: null,
      employeeContribution: null,
      dependants: null,
      dependantsList: null,
    },
    validationSchema: yup.object({
      type: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      effectiveDate: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      notes: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography variant="title2">{polyglot.t('BenefitModule.optOut')}</Typography>

        <Typography variant="caption" sx={{ mt: '20px' }}>
          {polyglot.t('BenefitModule.wantToOptOut')}
        </Typography>

        <Box sx={{ ...fieldSx, mt: '20px' }}>
          <TextfieldComponent
            name="notes"
            label={polyglot.t('General.notes')}
            value={formik.values.notes}
            onChange={formik.handleChange}
            error={formik.touched.notes && !!formik.errors.notes}
            helperText={(formik.touched.notes && formik.errors.notes) ?? ' '}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: '5px', mt: '20px' }}>
          <LoaderButton
            name={polyglot.t('General.cancel')}
            sizeVariant="medium"
            colorVariant="secondary"
            onClick={() => setIsOpen(false)}
            loading={false}
            fullWidth
          />
          <LoaderButton
            name={polyglot.t('BenefitModule.optOut')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={isLoading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
