import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DevicePolicyDto } from '@v2/feature/device/device.dto';
import { SupportedDeviceType } from '@v2/feature/device/device.interface';
import { FieldValueComponent } from '@v2/feature/device/device.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ConfigurationPayloadOption } from '@/domain/in-house-mdm/dto/command.event';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as GreenOkay } from '@/images/side-bar-icons/ok-green.svg';

export const EncryptionPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  configurationPayloadOption,
  policy,
  filevaultKey,
  isApple,
  isWindows,
  allPolicies,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  policyName: string;
  configurationPayloadOption?: ConfigurationPayloadOption | null;
  policy?: number[] | null;
  filevaultKey?: string | undefined | null;
  isApple: boolean;
  isWindows: boolean;
  allPolicies?: DevicePolicyDto[] | null;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPolicyDto, setSelectedPolicyDto] = useState<DevicePolicyDto | undefined>(undefined);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      if (allPolicies && allPolicies?.length > 0 && policy) {
        const supportedDeviceType = isWindows ? SupportedDeviceType.windows : SupportedDeviceType.android;

        const selectedPolicy = allPolicies
          .filter((p) => p.supportedDeviceType.toString() === supportedDeviceType.toString())
          .find((p) => policy.includes(p.id));

        setSelectedPolicyDto(selectedPolicy);
      }
    } catch (error) {
      showMessage('Something went wrong. Could not load the company policies.', 'error');
    } finally {
      setLoading(false);
    }
  }, [allPolicies, isWindows, policy, showMessage]);

  useEffect(() => {
    if (!isApple && policy) {
      refresh();
    }
  }, [refresh, policy, isApple]);

  if (isApple) {
    if (!configurationPayloadOption) return null;

    const entries = Object.entries(configurationPayloadOption);
    const payloadIdentifierEntry = entries.filter(([key]) => key === 'PayloadIdentifier');
    const otherEntries = entries.filter(([key]) => key !== 'PayloadIdentifier');

    return (
      <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
        <Box>
          <Typography variant="title2">{policyName}</Typography>

          <Box
            component="dl"
            sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
          >
            {otherEntries.map(([key, value]) => (
              <FieldValueComponent
                key={key}
                title={polyglot.t(`InHouseMdmPolicyViewDrawer.${key}`)}
                value={<Typography variant="title4">{String(value)}</Typography>}
              />
            ))}
            {payloadIdentifierEntry.map(([key, value]) => (
              <FieldValueComponent
                key={key}
                title={polyglot.t(`Payload identifier`)}
                value={<Typography variant="title4">{String(value)}</Typography>}
              />
            ))}
            {filevaultKey ? (
              <Box>
                <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', mt: spacing.m10 }}>
                  <GreenOkay width={15} fill={themeColors.Green} height={15} />
                  <Typography variant={'caption'} sx={{ ...themeFonts.caption, color: themeColors.black }}>
                    FileVault key saved
                  </Typography>
                </Box>
                <Box sx={{ mt: spacing.m15 }}>
                  <TextfieldComponent type="password" value={filevaultKey} autoFocus />
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', mt: spacing.m20 }}>
                <Mistake width={15} height={15} />
                <Typography variant={'caption'} sx={{ ...themeFonts.caption, color: themeColors.Red }}>
                  FileVault key missing
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DrawerModal>
    );
  } else {
    return (
      <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose} loading={loading}>
        <Box>
          <Typography variant="title2">{policyName}</Typography>

          <Box
            component="dl"
            sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
          >
            {selectedPolicyDto && <Typography variant="caption">{selectedPolicyDto.description}</Typography>}
          </Box>
        </Box>
      </DrawerModal>
    );
  }
};
