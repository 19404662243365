import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { UserContractEndpoints } from '@/api-client/contracts.api';
import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MissingFieldsDetail } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { ContractForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-contract-form.component';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshPayroll?: () => Promise<void>;
  readonly onlyAdd?: boolean;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingContract = ({ userId, refreshPayroll, onlyAdd, missingFieldsForCategory }: Props) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: contract, isLoading } = useApiClient(
    onlyAdd ? null : UserContractEndpoints.findCurrentByUserId(userId),
    {
      suspense: false,
    }
  );

  return (
    <>
      <Stack sx={{ flexFlow: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Typography sx={themeFonts.caption}>{polyglot.t('MissingContract.contractInfo')}</Typography>
          <IconButton onClick={() => setIsModalOpen(true)} colorVariant="secondary" sizeVariant="small">
            <Plus width="14px" height="14px" />
          </IconButton>
        </Box>
        {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      </Stack>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        {!isLoading ? (
          <ContractForm
            initialValues={contract}
            userId={userId}
            rowModalMode={onlyAdd || !contract ? 'add' : 'edit'}
            loadInitialData={() => Promise.resolve()}
            handleSubmit={() => refreshPayroll?.()}
            onClose={() => setIsModalOpen(false)}
            setFormCurrentlyEditing={() => {}}
            setIsModalOpen={setIsModalOpen}
            loading={loading}
            setLoading={setLoading}
            showEmployee
          />
        ) : (
          <LoadingSpinner />
        )}
      </DrawerModal>
    </>
  );
};
