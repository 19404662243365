import { useEffect, useRef, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DeliveryMethod, DevicePossessionType } from '@/v2/feature/device/device.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

export const DeviceDeliverySchema = Yup.object().shape({
  deliveryDate: Yup.string().test(dateFieldTest).required('Choose a delivery date'),
  destination: Yup.string().trim().required('Choose a destination region'),
  address: Yup.string().trim().required('Enter a delivery address'),
});

type OnboardingDeviceShippingPageProps = {
  user: CachedUser;
  devicePossession: DevicePossessionDto;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  onFinish?: () => void;
};

export const DeviceShippingPage = ({
  devicePossession,
  onBackClick,
  onCloseClick,
  onFinish,
  user,
}: OnboardingDeviceShippingPageProps) => {
  const addressFetched = useRef(false);
  const [assigningDevice, setAssigningDevice] = useState(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      deliveryDate: user.startDate ?? todaysDateShortISOString(),
      destination: 'UK' as 'UK' | 'EU',
      address: '',
    },
    validationSchema: DeviceDeliverySchema,
    onSubmit: async (values) => {
      try {
        setAssigningDevice(true);
        await DeviceAPI.reassignInventoryDevicePossession(
          devicePossession.id,
          {
            possessionId: user.userId,
            possessionType: DevicePossessionType.User,
          },
          {
            date: values.deliveryDate,
            deliveryAddress: values.address,
            deliveryMethod: DeliveryMethod.ShippingToEmployee,
          }
        );
        onFinish?.();
      } catch (error) {
        showMessage(`Failed to assign device to user. ${nestErrorMessage(error)}`, 'error');
        setAssigningDevice(false);
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    if (addressFetched.current) return;
    addressFetched.current = true;
    UserAddressAPI.findCurrentByUserId(user.userId).then((address) => {
      if (!address) return;
      formik.setFieldValue(
        'address',
        [address.addressLine1, address.addressLine2, address.city, address.postcode, address.country]
          .filter(Boolean)
          .join(', ')
      );
    });
  }, [formik, user.userId]);

  useEscapeKey(onCloseClick);

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <IconButton
        sx={{ ...iconButtonSx, flex: 0, position: 'absolute', left: spacing.m50 }}
        onClick={onBackClick}
        disabled={assigningDevice}
      >
        <Back {...iconSize} />
      </IconButton>
      <IconButton
        sx={{ ...iconButtonSx, flex: 0, position: 'absolute', right: spacing.m50 }}
        onClick={onCloseClick}
        disabled={assigningDevice}
      >
        <Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
      </IconButton>
      <Stack sx={{ width: 'min(400px,90vw)', mx: 'auto' }}>
        <FormikProvider value={formik}>
          <Form>
            <Typography sx={{ ...themeFonts.title2, mb: spacing.mb10 }}>Shipping the device</Typography>
            <Typography sx={{ ...themeFonts.caption, mb: spacing.mb30 }}>
              When and how should this device be shipped?
            </Typography>
            <Stack sx={{ overflow: 'auto' }}>
              <Stack sx={{ gap: spacing.g30 }}>
                <DatePickerComponent
                  name="deliveryDate"
                  label="Delivery date"
                  onChange={(value) => formik.setFieldValue('deliveryDate', value, true)}
                  minDate={todaysDateShortISOString()}
                  value={formik.values.deliveryDate}
                />
                <SelectComponent
                  name="destination"
                  label="Destination region"
                  options={[
                    { label: 'United Kingdom', value: 'UK' },
                    { label: 'European Union', value: 'EU' },
                  ]}
                  value={formik.values.destination}
                  onChange={formik.handleChange}
                />
                <TextfieldComponent
                  name="address"
                  label="Delivery address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  autoFocus
                />
                {formik.isValid && (
                  <LoaderButton
                    name="Order"
                    loading={assigningDevice}
                    style={{ marginTop: '30px' }}
                    colorVariant="primary"
                    sizeVariant="large"
                  />
                )}
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Stack>
    </Stack>
  );
};
