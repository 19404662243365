import axios from 'axios';

import {
  MoveToSectionCycle,
  OrderPreferenceCycleUpdate,
  ReachType,
  RemindReviewers,
  ReminderType,
  RemoveFromSectionCycle,
  ReviewCycle,
  ReviewCycleById,
  ReviewCycleCreate,
  ReviewCycleLaunch,
  ReviewCycleUpdate,
  ReviewParticipants,
  TimelineSettings,
  UpsertReviewers,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SelectedFiltersRequest } from '@/v2/feature/reports/reports.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewCycleAPI {
  static async createReviewCycle(reviewCycleBody: ReviewCycleCreate): Promise<ReviewCycle> {
    return (await axios.post(`/apiv2/review-cycle`, reviewCycleBody)).data;
  }

  static async updateReviewCycle(reviewCycleBody: ReviewCycleUpdate): Promise<void> {
    return (await axios.patch(`/apiv2/review-cycle`, reviewCycleBody)).data;
  }

  static async launchReviewCycle(cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/review-cycle/${cycleId}/launch`)).data;
  }

  static async activateReviewCycle(cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/review-cycle/${cycleId}/activate`)).data;
  }

  static async sendReminderByCycleId(type: ReminderType, cycleId: string, reach: ReachType): Promise<void> {
    return (await axios.post(`/apiv2/review-cycle/${cycleId}/remind?type=${type}&reach=${reach}`)).data;
  }

  static async sendReminderByRevieweeIds(type: ReminderType, remindBody: RemindReviewers): Promise<void> {
    return (await axios.post(`/apiv2/review-cycle/remind?type=${type}`, remindBody)).data;
  }

  // addReviewees add participants and entries in an pre-ongoing cycle
  static async addRevieweesToPreOngoing(cycleId: string, toAddRevieweeIds: number[]) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/add-reviewees`, toAddRevieweeIds)).data;
  }

  // addReviewers add participants and entries in an pre-ongoing cycle
  static async addReviewersToPreOngoing(cycleId: string, participants: Omit<ReviewParticipants, 'isEditable'>) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/add-reviewers`, participants)).data;
  }

  // removeReviewers add participants and entries in an pre-ongoing cycle
  static async removeReviewersToPreOngoing(cycleId: string, participants: Omit<ReviewParticipants, 'isEditable'>) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/delete-reviewers`, participants)).data;
  }

  // addReviewees add participants and entries in an ongoing cycle
  static async addRevieweesToOngoing(cycleId: string, toAddRevieweeIds: number[]) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/ongoing/add-reviewees`, toAddRevieweeIds)).data;
  }

  // deleteReviewees removes participants in an pre-ongoing cycle
  static async deleteRevieweesToPreOngoing(cycleId: string, toDeleteRevieweeIds: number[]) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/delete-reviewees`, toDeleteRevieweeIds)).data;
  }

  // deleteReviewees deletes participants and entries in an ongoing cycle
  static async deleteReviewees(cycleId: string, toDeleteRevieweeIds: number[]) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/ongoing/delete-reviewees`, toDeleteRevieweeIds)).data;
  }

  // addReviwers add reviewers and creates entries in an ongoing cycle
  static async addReviewers(cycleId: string, reviewers: UpsertReviewers) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/add-reviewers`, reviewers)).data;
  }

  // removeReviewers deletes reviewers and deletes entries in an ongoing cycle
  static async removeReviewers(cycleId: string, reviewers: UpsertReviewers) {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/remove-reviewers`, reviewers)).data;
  }

  static async cloneTemplateInCycle(templateId: string, cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/review-cycle/clone-template/${templateId}/${cycleId}`)).data;
  }

  static async cloneQuestionsInCycle(questionBankIds: string[], cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/review-cycle/clone-questions/${cycleId}`, questionBankIds)).data;
  }

  static async updateOrderReviewCycle(updateBody: OrderPreferenceCycleUpdate): Promise<ReviewCycle> {
    return (await axios.patch('/apiv2/review-cycle/order', updateBody)).data;
  }

  static async updateTimelinelinesReviewCycle(cycleId: string, timelineSettings: TimelineSettings): Promise<void> {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/timeline-settings`, timelineSettings)).data;
  }

  static async moveQuestionOutsideSection(updateBody: RemoveFromSectionCycle): Promise<ReviewCycle> {
    return (await axios.patch(`/apiv2/review-cycle/remove-from-section`, updateBody)).data;
  }

  static async moveQuestionInsideSection(updateBody: MoveToSectionCycle): Promise<ReviewCycle> {
    return (await axios.patch(`/apiv2/review-cycle/add-to-section`, updateBody)).data;
  }

  static async finaliseCycle(cycleId: string): Promise<ReviewCycle> {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/finalise`)).data;
  }

  static async reopenCycle(cycleId: string): Promise<ReviewCycle> {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/reopen`)).data;
  }

  static async duplicateCycle(cycleId: string): Promise<ReviewCycle> {
    return (await axios.post(`/apiv2/review-cycle/${cycleId}/duplicate`)).data;
  }

  static async deleteCycle(cycleId: string): Promise<ReviewCycle> {
    return (await axios.delete(`/apiv2/review-cycle/${cycleId}`)).data;
  }

  static async updateStateOfRollingReview(cycleId: string): Promise<ReviewCycle> {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/rolling-state`)).data;
  }

  static async updateEnrolmentTrigger(cycleId: string, filter: SelectedFiltersRequest): Promise<ReviewCycle> {
    return (await axios.patch(`/apiv2/review-cycle/${cycleId}/enrolment-trigger`, filter)).data;
  }

  static async generateRollingReviewParticipants(
    selectedFilters: SelectedFiltersRequest,
    reach: ReachType
  ): Promise<{ userId: number; displayName: string; jobTitle: string }[]> {
    return (await axios.post(`/apiv2/review-cycle/generate/rolling-particpants?reach=${reach}`, { selectedFilters }))
      .data;
  }
}

export class ReviewCycleEndpoints {
  static getEventSourceUrlForReviewCycleLaunch(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-reviewCycleLaunch`,
    };
  }

  static getEventSourceUrlForReviewCycleFinalise(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-finaliseCycle`,
    };
  }
  static getEventSourceUrlForAddRevieweesInCycle(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-addRevieweesInCycle`,
    };
  }
  static getEventSourceUrlForAddReviewersInCycle(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-addReviewersInCycle`,
    };
  }
  static getReviewParticipantsByCycleId(cycleId: string, reach: ReachType): Endpoint<ReviewParticipants[]> {
    return {
      url: `/apiv2/review-cycle/participants/${cycleId}?reach=${reach}`,
    };
  }

  static getReviewCycleById(cycleId: string, reach: ReachType): Endpoint<ReviewCycle> {
    return {
      url: `/apiv2/review-cycle/${cycleId}?reach=${reach}`,
    };
  }

  static getUserCycleById(cycleId: string, userId: number): Endpoint<ReviewCycle> {
    return {
      url: `/apiv2/review-cycle/user/${cycleId}/${userId}`,
    };
  }

  static getReviewLaunchDetailsByCycleId(cycleId: string): Endpoint<ReviewCycleLaunch> {
    return {
      url: `/apiv2/review-cycle/${cycleId}/launch`,
    };
  }

  static getReviewCycleStructureById(cycleId: string): Endpoint<ReviewCycleById> {
    return {
      url: `/apiv2/review-cycle/structure/${cycleId}`,
    };
  }

  static getOngoingCycles(reach: ReachType, search: string, filterString: string): Endpoint<ReviewCycle[]> {
    const urlParams = new URLSearchParams({
      reach,
      ...(search && { search }),
      ...(filterString && { filterString }),
    });
    return {
      url: `/apiv2/review-cycle/ongoing?${urlParams.toString()}`,
    };
  }
}
