import axios from 'axios';

import { DailyExternalPayrollSyncJob } from '@/models/company.model';
import { SubscriptionAutoBillerJob, SubscriptionChargeJobData } from '@/models/subscription.model';
import {
  CreateAppQueue,
  QueueStatus,
  RegenerateCalendarOrSyncCompanyEventsJob,
} from '@/v2/feature/monitoring/monitoring.interface';

export class MonitoringAPI {
  static async getGoogleCalendarQueueDetails(): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return (await axios.get('/apiv2/google-calendar/queues')).data;
  }

  static async getAbsenceImportQueueDetails(): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return (await axios.get('/apiv2/absence-queue/status')).data;
  }

  static async getOutlookCalendarQueueDetails(): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return (await axios.get('/apiv2/outlook-calendar/queues')).data;
  }

  static async getPayrunEntriesQueueDetails(): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return (await axios.get('/apiv2/payroll/payruns/queues')).data;
  }

  static async getAppActionsQueueDetails(): Promise<QueueStatus<CreateAppQueue>> {
    return (await axios.get('/apiv2/apps/actions/queues')).data;
  }

  static async getInHouseMdmDeviceSyncQueueDetails(): Promise<QueueStatus<unknown>> {
    return (await axios.get('/apiv2/apps/mdm/device-sync/queues')).data;
  }

  static async cleanInHouseMdmQueues(queueName: string): Promise<QueueStatus<unknown>> {
    return (await axios.get(`/apiv2/apps/mdm/clean/queue/${queueName}`)).data;
  }

  static async getNumberOfJobsToCleanUp(): Promise<Map<string, number>> {
    return (await axios.get('/apiv2/apps/mdm/clean/queues/review')).data;
  }

  static async getAppEmailsQueueDetails(): Promise<QueueStatus<CreateAppQueue>> {
    return (await axios.get('/apiv2/apps/emails/queues')).data;
  }

  static async getBillingQueueDetails(): Promise<QueueStatus<SubscriptionAutoBillerJob>> {
    return (await axios.get('/apiv2/billing/queues')).data;
  }

  static async getReviewQueueDetails(): Promise<QueueStatus<unknown>> {
    return (await axios.get('/apiv2/review-cycle/queues')).data;
  }

  static async getSurveyQueueDetails(): Promise<QueueStatus<unknown>> {
    return (await axios.get('/apiv2/survey-cycle/queues')).data;
  }

  static async getPayrollQueueDetails(): Promise<QueueStatus<DailyExternalPayrollSyncJob>> {
    return (await axios.get('/apiv2/companies/queues')).data;
  }

  static async getBillPaymentQueueDetails(): Promise<QueueStatus<SubscriptionChargeJobData>> {
    return await (await axios.get('/apiv2/bill-payment/queues')).data;
  }

  static async getDevicesMDMQueueDetails(): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return await (await axios.get('/apiv2/devices/queues/mdm-queue')).data;
  }

  static async getDevicesNotificationsQueueDetails(): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return await (await axios.get('/apiv2/devices/queues/notifications-queue')).data;
  }

  static async getAttendanceImportQueueDetails(): Promise<QueueStatus<unknown>> {
    return (await axios.get('/apiv2/attendances/import/queues/import-queue')).data;
  }

  static async getUsersImportQueueDetails(): Promise<QueueStatus<unknown>> {
    return (await axios.get('/apiv2/users/import/queues/import-queue')).data;
  }

  static async deleteJobFromCalendarQueue(
    queueName: string,
    jobId: string | number
  ): Promise<QueueStatus<RegenerateCalendarOrSyncCompanyEventsJob>> {
    return await (await axios.patch(`/apiv2/${queueName}/queues/delete/job/${jobId}`)).data;
  }
}
