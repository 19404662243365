import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewTemplateEndpoints } from '@/v2/feature/growth/reviews/api-client/review-template.api';
import { ReviewTemplate } from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import { TemplateBlock } from '@/v2/feature/growth/surveys/features/components/template-block.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const CycleCreationTemplateModal = ({
  isTemplateModalOpen,
  setIsTemplateModalOpen,
  cycleId,
  refresh,
}: {
  isTemplateModalOpen: boolean;
  setIsTemplateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cycleId: string;
  refresh: () => Promise<void>;
}) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const {
    data: templates,
    isLoading: templatesLoading,
  } = useApiClient(ReviewTemplateEndpoints.getReviewTemplatesCompanyId(searchInput), { suspense: false });
  return (
    <DrawerModal
      isOpen={isTemplateModalOpen}
      setIsOpen={setIsTemplateModalOpen}
      onClose={() => {
        setIsTemplateModalOpen(false);
      }}
      widthPercentage={95}
      sx={{
        px: 0,
      }}
      loading={templatesLoading}
    >
      <CycleSetupTemplateContent
        templates={templates}
        setIsTemplateModalOpen={setIsTemplateModalOpen}
        cycleId={cycleId}
        refresh={refresh}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
      />
    </DrawerModal>
  );
};

const TemplateLibraryHeader = ({
  searchInput,
  setSearchInput,
}: {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <Box sx={{ px: spacing.px16, mb: spacing.m24 }}>
    <Typography variant="title3">Template</Typography>
    <TableSearch
      style={{ width: '100%', boxSizing: 'border-box', marginTop: spacing.m24 }}
      query={searchInput}
      handleChange={(e) => setSearchInput(e.target.value)}
    />
  </Box>
);

const CycleSetupTemplateContent = ({
  templates,
  setIsTemplateModalOpen,
  cycleId,
  refresh,
  setSearchInput,
  searchInput,
}: {
  templates: ReviewTemplate[] | null | undefined;
  setIsTemplateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cycleId: string;
  refresh: () => Promise<void>;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  searchInput: string;
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cloneTemplate = useCallback(
    async (templateId: string | undefined) => {
      if (!templateId) {
        showMessage('Please select a template.', 'warning');
        return;
      }
      try {
        setIsLoading(true);
        await ReviewCycleAPI.cloneTemplateInCycle(templateId, cycleId);
        await refresh?.();
        setIsTemplateModalOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }

      setIsLoading(false);
    },
    [cycleId, refresh, setIsTemplateModalOpen, polyglot, showMessage]
  );

  if (!templates) return <></>;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <TemplateLibraryHeader searchInput={searchInput} setSearchInput={setSearchInput} />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          px: spacing.px16,
          boxSizing: 'border-box',
          m: -spacing.p8,
        }}
      >
        {templates.map((template) => (
          <Box key={template.id} sx={{ width: ['100%', '50%', '25%', '25%'], boxSizing: 'border-box', p: spacing.p8 }}>
            <TemplateBlock template={template} action={() => setActiveId(template.id)} active={activeId} />
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: '24px',
          right: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: '16px',
          boxSizing: 'border-box',
        }}
      >
        <ButtonComponent
          colorVariant="primary"
          sizeVariant="medium"
          disabled={!activeId}
          onClick={async () => {
            await cloneTemplate(activeId);
          }}
          loading={isLoading}
        >
          Add template
        </ButtonComponent>
      </Box>
    </Box>
  );
};
