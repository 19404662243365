import React, { useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { ScheduleApprovalFormData, ScheduleTrackingType } from '@v2/feature/attendance/attendance.interface';
import { getScheduleApprovalValidationSchema } from '@v2/feature/attendance/attendance.util';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import { Typography } from '@/v2/components/typography/typography.component';

const getApprovalRulesOptions = (approvalRules: readonly { id: number; name: string }[]) =>
  approvalRules.map((rule) => ({
    label: rule.name,
    value: rule.id,
  }));

interface ScheduleApprovalFormProps {
  readonly attendanceSchedule: ScheduleApprovalFormData & Pick<AttendanceScheduleDto, 'trackingType'>;
  readonly onSubmit: (values: ScheduleApprovalFormData) => void | Promise<void>;
  readonly buttonName: string;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
  readonly loading: boolean;
}

export const ScheduleApprovalForm = ({
  attendanceSchedule,
  approvalRules,
  refreshApprovalRules,
  onSubmit,
  buttonName,
  loading,
}: ScheduleApprovalFormProps) => {
  const { polyglot } = usePolyglot();

  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState<boolean>(false);

  const formik = useFormik<ScheduleApprovalFormData>({
    initialValues: {
      approvalRuleId: attendanceSchedule.approvalRuleId,
    },
    validationSchema: getScheduleApprovalValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="caption">{polyglot.t('ScheduleApprovalForm.approverNotified')}</Typography>

        {attendanceSchedule.trackingType === ScheduleTrackingType.None && (
          <Typography variant="caption">{polyglot.t('ScheduleApprovalForm.notRequired')}</Typography>
        )}

        <Box sx={fieldSx}>
          <SelectComponent
            name="approvalRuleId"
            label={polyglot.t('ScheduleApprovalForm.approvalRuleId')}
            options={getApprovalRulesOptions(approvalRules)}
            value={formik.values.approvalRuleId ?? ''}
            onChange={formik.handleChange}
            error={!!formik.errors.approvalRuleId && formik.touched.approvalRuleId}
            helperText={(formik.touched.approvalRuleId && formik.errors.approvalRuleId) as string}
            // editList={{ // TODO: edited rules not supported yet - uncomment once supported
            //   isHidden: !hasScopes(['company.settings:all']),
            //   handler: () => {
            //     setIsApprovalDrawerOpen(true);
            //   },
            // }}
          />
        </Box>

        <ApprovalRuleDrawer
          isOpen={isApprovalDrawerOpen}
          setIsOpen={setIsApprovalDrawerOpen}
          approvalRule={null}
          afterClose={() => {
            setIsApprovalDrawerOpen(false);
          }}
          refresh={async () => {
            if (refreshApprovalRules) await refreshApprovalRules();
          }}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name={buttonName} loading={loading} fullWidth sizeVariant="medium" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
