import React, { useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Scope } from '@/models';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { CardStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { useTaxRemoteTable } from '@/v2/feature/user/features/user-profile/details/components/tax-remote-table.component';
import { useTaxUKTable } from '@/v2/feature/user/features/user-profile/details/components/tax-uk-table.component';
import { UserProfileTaxDetailsRemote } from '@/v2/feature/user/features/user-profile/details/components/user-profile-tax-details-remote.component';
import { UserProfileTaxDetailsUK } from '@/v2/feature/user/features/user-profile/details/components/user-profile-tax-details-uk.component';
import { isSameCountryCode, isUKCountryCode } from '@/v2/infrastructure/country/country.util';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

type TaxCardStructureProps = {
  addAction?: () => void;
  addButtonTitle: string;
  cardScope: readonly Scope[];
  drawerDetails: JSX.Element;
  editAction: () => void;
  missingInfoIcon: JSX.Element;
  onEditRowClick: (record: UserPayrollDto) => void;
  payroll: EntriesAndEffectiveRecord<UserPayrollDto> | null | undefined;
  payrollCountry: string;
  contractCountry: string;
  showDetailsState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  userId: number;
};

export const TaxCardStructure = ({
  addAction,
  addButtonTitle,
  cardScope,
  drawerDetails,
  editAction,
  missingInfoIcon,
  onEditRowClick,
  payroll,
  payrollCountry,
  contractCountry,
  showDetailsState: [showDetails, setShowDetails],
  userId,
}: TaxCardStructureProps) => {
  const { polyglot } = usePolyglot();

  const [countryCode, setCountryCode] = useState<string>(payroll?.effectiveRecord?.countryCode || 'GB');

  const ukTable = useTaxUKTable({
    payroll,
    userId,
    onEditRowClick,
  });

  const nonUKTable = useTaxRemoteTable({
    payroll,
    userId,
    onEditRowClick,
    countryCode,
  });

  const effectiveRecord = payroll?.effectiveRecord;
  const [tableRowData, tableColumnData] = (isUKCountryCode(countryCode) ? ukTable : nonUKTable) as typeof ukTable;

  const tableSelect = useMemo(() => {
    const countryCodes = new Set(payroll?.entries.map((e) => e.countryCode));
    if (countryCodes.size <= 1) return undefined;
    return (
      <SelectComponent
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        name={'payroll-select'}
        label={polyglot.t('TaxCardStructure.jurisdiction')}
        options={[...countryCodes]
          .reduce<{ label: string; value: string }[]>((res, code) => {
            const label = getCountryNameForCode(code);
            if (label) res.push({ label, value: code });
            return res;
          }, [])
          .sort((a, b) => a.label.localeCompare(b.label))}
        sx={{ width: 'fit-content', minWidth: '250px', ml: '40px', mb: spacing.mb20 }}
      />
    );
  }, [polyglot, payroll?.entries, countryCode]);

  const payrollMatchesContractCountry = isSameCountryCode(payrollCountry, contractCountry);

  return (
    <CardStructure
      cardTitle={polyglot.t('TaxCardStructure.title')}
      cardScope={cardScope}
      showHistoryButton
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      userId={userId}
      showMissingInfo={!effectiveRecord}
      addButtonTitle={addButtonTitle}
      addAction={addAction}
      cardFieldDetails={
        <>
          {!payrollMatchesContractCountry && (
            <Typography sx={{ ...themeFonts.caption, mb: spacing.mb15 }}>
              {polyglot.t('TaxCardStructure.currentNotApplicable')}
            </Typography>
          )}
          {payrollMatchesContractCountry && (
            <>
              {effectiveRecord && effectiveRecord.payrollValues && (
                <UserProfileTaxDetailsUK
                  payrollId={effectiveRecord.payrollId}
                  payrollValues={effectiveRecord.payrollValues}
                />
              )}
              {effectiveRecord && effectiveRecord.remotePayrollValues && (
                <UserProfileTaxDetailsRemote
                  payrollId={effectiveRecord?.payrollId}
                  remotePayrollValues={effectiveRecord.remotePayrollValues}
                  countryCode={effectiveRecord.countryCode}
                />
              )}
            </>
          )}
        </>
      }
      drawerDetails={drawerDetails}
      editAction={payrollMatchesContractCountry ? editAction : undefined}
      tableSelect={tableSelect}
      tableRowData={tableRowData ?? []}
      tableColumn={tableColumnData ?? []}
      missingInfoIcon={missingInfoIcon}
    />
  );
};
