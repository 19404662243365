import { useContext, useMemo } from 'react';

import { Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { SURVEYS_COMPANY_ROUTE, SURVEYS_ME_ROUTE, SURVEYS_TEAM_ROUTE } from '@/lib/routes';
import { SurveysCompanyRouter } from '@/v2/feature/growth/surveys/surveys-company.router';
import { SurveysMeRouter } from '@/v2/feature/growth/surveys/surveys-me.router';
import { SurveysTeamRouter } from '@/v2/feature/growth/surveys/surveys-team.router';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const SurveysRouter = () => {
  const [state] = useContext(GlobalContext);
  const { user } = state;

  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false,
  });
  const isManager = useMemo(() => {
    const nonTerminatedDirectReports =
      userSummaryWithReports?.directReports?.filter(
        (d) => d.userEvent && d.userEvent.status && ['Employed', 'Hired'].includes(d.userEvent.status)
      )?.length ?? 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  return (
    <Switch>
      <RouteScopesHas scopes={['surveys:all']} path={SURVEYS_COMPANY_ROUTE}>
        <SurveysCompanyRouter />
      </RouteScopesHas>

      {isManager && (
        <RouteScopesHas scopes={['surveys:manager']} path={SURVEYS_TEAM_ROUTE}>
          <SurveysTeamRouter />
        </RouteScopesHas>
      )}

      <RouteScopesHas scopes={['surveys']} path={SURVEYS_ME_ROUTE}>
        <SurveysMeRouter />
      </RouteScopesHas>
    </Switch>
  );
};
