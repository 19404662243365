// export const OffboardingDevicesPage = ({ userId, refresh, loading }: PageProps) => {
//   const { polyglot } = usePolyglot();
//
//   return (
//     <RootStyle>
//       <TopHeader
//         title={<Typography variant="title2">{polyglot.t('OffboardingPage.devices')}</Typography>}
//         showBack
//         backPath={generatePath(USER_OFFBOARDING_ROUTE, { userId })}
//       />
//       <ContentWrapper loading={loading}>
//         <OffboardingDevices userId={userId} refreshOffboardingState={refresh} />
//       </ContentWrapper>
//     </RootStyle>
//   );
// };

import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortBoolean, sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OffboardingManagerChangeDrawer } from '@v2/feature/offboarding/offboarding-process/manager-change/offboarding-manager-change.drawer.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { UserRoleDto } from '@v2/feature/user/features/user-forms/user-role/user-role.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { generatePath } from 'react-router-dom';

import { UserRoleEndpoints } from '@/api-client/user-role.api';
import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';

type TableEntity = Pick<UserRoleDto, 'id' | 'userId' | 'effectiveDate'> & {
  userDisplayName: string;
  isEffective: boolean;
  isFuture: boolean;
};

interface PageProps {
  readonly userId: number;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
}

export const OffboardingManagerChangePage = ({ userId, refresh, loading }: PageProps) => {
  const { data: userRolesData, isLoading } = useApiClient(UserRoleEndpoints.getOffboardingUserRoleChanges(userId), {
    suspense: false,
  });

  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState<string>('');
  const [isManagerSelectDrawerOpen, setIsManagerSelectDrawerOpen] = useState<boolean>(false);

  const userRoles = useMemo(() => {
    if (!userRolesData) return [];

    const roles: TableEntity[] = [];
    for (const userRole of userRolesData.effectiveRoles) {
      const user = getCachedUserById(userRole.userId!);
      roles.push({ ...userRole, userDisplayName: user?.displayName ?? '', isEffective: true, isFuture: false });
    }

    for (const userRole of userRolesData.futureRoles) {
      const user = getCachedUserById(userRole.userId!);
      roles.push({ ...userRole, userDisplayName: user?.displayName ?? '', isEffective: false, isFuture: true });
    }

    return roles;
  }, [userRolesData, getCachedUserById]);

  const filteredUserRoles = useMemo(() => {
    if (!searchInput) return userRoles;

    const search = searchInput.toLowerCase();
    return userRoles.filter((role) => role.userDisplayName.toLowerCase().includes(search));
  }, [searchInput, userRoles]);

  const tableColumns = useMemo<ColumnDef<TableEntity, TableEntity>[]>(
    () => [
      {
        header: () => polyglot.t('General.employee'),
        accessorFn: (row: any) => row,
        id: 'userId',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.userDisplayName),
        cell: (info: CellContext<any, any>) => {
          const userRole = info.getValue();
          return (
            <Box>
              <UserAvatar userId={userRole.userId} size="xxsmall" showName />
            </Box>
          );
        },
        maxSize: 200,
        minSize: 140,
      },
      {
        header: () => polyglot.t('General.effectiveDate'),
        accessorFn: (row) => row,
        id: 'effectiveDate',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.effectiveDate),
        cell: (info: CellContext<any, any>) => {
          const userRole = info.getValue();
          const date = new LocalDate(userRole.effectiveDate).toLocaleDateString();
          return (
            <Box>
              <Typography variant="caption">{date}</Typography>
            </Box>
          );
        },
        maxSize: 180,
        minSize: 150,
      },
      {
        header: () => polyglot.t('General.actions'),
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isEffective),
        cell: (info: CellContext<any, any>) => {
          const userRole = info.getValue();
          return (
            <Box>
              {userRole.isEffective ? (
                <Typography variant="caption">A new role will be created</Typography>
              ) : (
                <Typography variant="caption">The role will be updated</Typography>
              )}
            </Box>
          );
        },
        maxSize: 180,
        minSize: 150,
      },
    ],
    [polyglot]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('OffboardingPage.managerChange')}</Typography>}
        showBack
        backPath={generatePath(USER_OFFBOARDING_ROUTE, { userId })}
      />
      <ContentWrapper loading={loading}>
        <Box sx={{ maxWidth: '600px', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />

            <ButtonComponent
              sizeVariant="small"
              colorVariant="primary"
              onClick={() => {
                setIsManagerSelectDrawerOpen(true);
              }}
            >
              {polyglot.t('OffboardingPage.selectNewManager')}
            </ButtonComponent>
          </Box>
          <Box sx={{ ...spacing.mt20 }}>
            <BasicTable<TableEntity> rowData={filteredUserRoles} columnData={tableColumns} loading={isLoading} />
          </Box>

          <OffboardingManagerChangeDrawer
            isOpen={isManagerSelectDrawerOpen}
            setIsOpen={setIsManagerSelectDrawerOpen}
            userId={userId}
            refresh={refresh}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
