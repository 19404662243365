import { useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { getIconForAppStatus } from '../../../app-integration-detailed-personal.router';

import useMessage from '@/hooks/notification.hook';
import cover from '@/images/app-empty.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { AppIntegrationUserDto, InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { APP_ACTION_DRAWER_MODES, AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import {
  externalUserHasDelayedAppAction,
  getTooltipTitleForExternalScheduledStatus,
} from '@/v2/feature/app-integration/app-integration.util';
import { AppDetailsEndpoints } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { AppDetailConfigurationModal } from '@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component';
import { AppDetailsActionButton } from '@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component';
import { AppDetailsTable } from '@/v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { AppActionsDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OnboardNewEmployee } from '@/v2/feature/onboarding/components/onboard-new-employee.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { secondarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface AppCandidateManagementProps {
  readonly candidateList: readonly AppIntegrationUserDto[] | undefined;
  readonly importedCandidateList: readonly AppIntegrationUserDto[];
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
  readonly loading: boolean;
  readonly error: boolean;
  readonly onAppChange: (_: string) => void;
  readonly hasAppsAllOrAppOwner: boolean;
  readonly refreshCandidates: () => void;
}

export const CandidateManagementTabFilter = [
  { name: 'All', value: 'all' },
  { name: 'Hired', value: 'Hired' },
  { name: 'Onboarded in Zelt', value: 'Onboarded in Zelt' },
];

export const AppCandidateManagementPage = ({
  candidateList,
  importedCandidateList,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  hasAppsAllOrAppOwner,
  refreshCandidates,
}: AppCandidateManagementProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('all');
  const [filteredCandidateList, setFilteredCandidateList] = useState<AppIntegrationUserDto[]>([]);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [selectedActiveUserLogin, setSelectedActiveUserLogin] = useState<string | undefined>(undefined);
  const [preselectedUser, setPreselectedUser] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [actionsDrawerMode, setActionsDrawerMode] = useState<APP_ACTION_DRAWER_MODES | undefined>(undefined);
  const [onboardUserDrawerOpen, setOnboardUserDrawerOpen] = useState<boolean>(false);
  const { data: candidateOnboardingTemplate } = useApiClient(AppDetailsEndpoints.getOnboardingConfiguration(appStub), {
    suspense: false,
  });

  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false,
    }
  );

  const hiredAndImportedCandidates = useMemo(() => {
    const listToAdd = candidateList ?? [];
    return listToAdd.concat(importedCandidateList);
  }, [candidateList, importedCandidateList]);

  useEffect(() => {
    setFilteredCandidateList(
      searchInput.length === 0
        ? hiredAndImportedCandidates
        : hiredAndImportedCandidates.filter(
            (eachUser) =>
              eachUser?.name?.fullName && eachUser?.name?.fullName?.toLowerCase().includes(searchInput.toLowerCase())
          )
    );
  }, [filterString, searchInput, hiredAndImportedCandidates]);

  useEffect(() => {
    setFilteredCandidateList(
      filterString === 'all'
        ? hiredAndImportedCandidates
        : hiredAndImportedCandidates.filter((eachUser) => eachUser.userStatus === filterString)
    );
  }, [filterString, hiredAndImportedCandidates]);

  const candidateColumn = useMemo<ColumnDef<AppIntegrationUserDto, AppIntegrationUserDto>[]>(
    () => [
      {
        header: () => 'Candidate Name',
        id: 'fullName',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Typography
            sx={{
              ...themeFonts.caption,
              display: 'flex',
              alignItems: 'center',
              gap: spacing.m5,
              color: themeColors.DarkGrey,
            }}
          >
            {(original.name && original.name.fullName) ?? 'N/A'}
          </Typography>
        ),
      },
      {
        header: () => 'Status',
        id: 'userStatus',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <StyledTooltip
            title={getTooltipTitleForExternalScheduledStatus(
              delayedActions,
              original.primaryEmail,
              original.id ? String(original.id) : ''
            )}
          >
            <Box sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                  color: themeColors.DarkGrey,
                }}
              >
                {original.userStatus &&
                !externalUserHasDelayedAppAction(
                  delayedActions,
                  original.primaryEmail,
                  original.id ? String(original.id) : '0'
                )
                  ? getIconForAppStatus(polyglot, original.userStatus)
                  : ''}

                {original.userStatus &&
                delayedActions &&
                externalUserHasDelayedAppAction(
                  delayedActions,
                  original.primaryEmail,
                  original.id ? String(original.id) : '0'
                )
                  ? getIconForAppStatus(polyglot, 'Scheduled')
                  : ''}
              </Typography>
            </Box>
          </StyledTooltip>
        ),
      },
      {
        header: () => '',
        id: 'action',
        maxSize: 80,
        minSize: 80,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <AppDetailsActionButton
            setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
            setSelectedActiveUserLogin={setSelectedActiveUserLogin}
            setGroupManagementDrawerOpen={() => {}}
            setGroupManagementDrawerMode={() => {}}
            refresh={onAppChange}
            appStub={appStub}
            user={original}
            delayedActions={delayedActions ?? { delayed: [] }}
            refreshDelayedActions={refreshDelayedActions}
            table="candidates"
            hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
            setPreselectedUser={setPreselectedUser}
            setActionsDrawerMode={setActionsDrawerMode}
            error={error}
            setOnboardUserDrawerOpen={setOnboardUserDrawerOpen}
          />
        ),
      },
    ],
    [delayedActions, polyglot, onAppChange, appStub, refreshDelayedActions, hasAppsAllOrAppOwner, error]
  );

  const mapToUserForOnboarding = (
    user: AppIntegrationUserDto
  ): {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress?: string;
    personalEmail: string | null;
    jobTitle: string;
    phone: string;
    templateId?: number;
  } => {
    const nameParts = user?.name?.fullName?.split(' ')?.filter(Boolean);
    if (!nameParts || nameParts?.length === 0) {
      showMessage(`Unable to import user without name details. Please update candidate and try again`, 'error');
    }
    return {
      userId: 0,
      firstName: nameParts ? nameParts[0] : '',
      lastName: nameParts ? nameParts[1] : '',
      emailAddress: '',
      personalEmail: user.primaryEmail ?? '',
      jobTitle: user?.jobTitle ?? '',
      phone: user?.phone ?? '',
      templateId:
        candidateOnboardingTemplate && candidateOnboardingTemplate?.selected
          ? candidateOnboardingTemplate.selected
          : undefined,
    };
  };

  return (
    <RootStyle>
      <TopHeader title={<AppNameHeader title={polyglot.t('AppCandidateManagementPage.title')} app={app} />} />
      <ContentWrapper loading={false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'left',
          }}
        >
          {!error && (
            <>
              <TabFilterButtons
                filters={CandidateManagementTabFilter}
                setFilterValue={setFilterString}
                filterValue={filterString}
                hasSearch
                onFilterChange={({ searchInput }) => {
                  setSearchInput(searchInput);
                }}
              />
              {hasAppsAllOrAppOwner && (
                <Button
                  sx={secondarySmallBtn}
                  startIcon={<Reload {...iconSize} />}
                  onClick={() => refreshCandidates?.()}
                  disabled={loading}
                >
                  Refresh
                </Button>
              )}
            </>
          )}
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          <AppDetailsTable
            column={candidateColumn}
            row={[...filteredCandidateList]}
            loading={loading}
            hidePagination={true}
          />
          {error && !app?.authorised && (
            <EmptyStateComponent
              header="Authorize this app"
              subheader="Zelt allows you to manage your whole toolstack from one place."
              detail="To start using your app connect your account in Settings first."
              cover={cover}
            />
          )}
        </Box>
        <AppDetailConfigurationModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          app={app}
          appStub={appStub}
          refresh={() => onAppChange(appStub)}
        />
        <OnboardNewEmployee
          isOpen={onboardUserDrawerOpen}
          setIsOpen={setOnboardUserDrawerOpen}
          initialValues={
            preselectedUser
              ? mapToUserForOnboarding(preselectedUser)
              : {
                  userId: 0,
                }
          }
          onDraftUserCreated={() => setOnboardUserDrawerOpen(false)}
          mode="import"
          appStub={appStub}
          externalId={preselectedUser ? String(preselectedUser.id) : undefined}
          onUserCreated={() => {
            refreshCandidates?.();
          }}
        />
        <AppActionsDrawer
          appStub={appStub}
          usersWithoutAccess={candidateList ? [...candidateList] : []}
          usersWithAccess={candidateList ? [...candidateList] : []}
          isOpen={isAppActionsDrawerOpen}
          onClose={() => setIsAppActionsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          refreshDelayedActions={refreshDelayedActions}
          setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
          preselectedUser={preselectedUser}
          mode={actionsDrawerMode}
          selectedActiveUserLogin={selectedActiveUserLogin}
          setSelectedActiveUserLogin={setSelectedActiveUserLogin}
          directoryMode={'external'}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
