import { Box, Typography } from '@mui/material';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TransactionStatus } from '@/v2/feature/payments/payments.dto';
import { PAYMENTS_ROUTE } from '@/v2/feature/payments/payments.router';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

function PaymentIconLiner({ icon, title, subtitle }: { icon: JSX.Element; title: string; subtitle: string }) {
  return (
    <Box sx={{ maxWidth: '400px' }}>
      <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
        {icon}
        <Typography sx={{ ...themeFonts.title2 }}>{title}</Typography>
      </Box>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.m10 }}>{subtitle}</Typography>
    </Box>
  );
}

export const PaymentResultStep = (): JSX.Element => {
  const routerHistory = useHistory();
  const transactionStatus = localStorage.getItem('transactionStatus');

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ maxWidth: '400px' }}>
        {transactionStatus === TransactionStatus.COMPLETED && (
          <PaymentIconLiner
            title="Payment completed"
            subtitle="You can now safely close this page"
            icon={<OkGreen width={30} height={30} style={{ fill: themeColors.Green }} />}
          />
        )}
        {transactionStatus === TransactionStatus.PENDING && (
          <PaymentIconLiner
            title="Payment in progress"
            subtitle="You can now safely close this page"
            icon={<Waiting width={30} height={30} style={{ fill: themeColors.Grey }} />}
          />
        )}
        {transactionStatus === TransactionStatus.FAILED && (
          <PaymentIconLiner
            title="Payment failed"
            subtitle="Payment could not be processed"
            icon={<MistakeIcon width={30} height={30} />}
          />
        )}

        <Box sx={{ mt: spacing.m30 }}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="secondary"
            onClick={() => routerHistory.push(PAYMENTS_ROUTE)}
          >
            Close
          </ButtonComponent>
        </Box>
      </Box>
    </Box>
  );
};
