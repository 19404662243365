import { Box, Typography } from '@mui/material';
import { LoadingStateComponent } from '@v2/components/table/loading-state.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Mistake } from '@/images/reports/Mistake.svg';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export const ErrorComponent = () => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  return (
    <Box sx={{ maxWidth: '400px', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
        <Mistake />
        <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>{polyglot.t('ErrorComponent.fail')}</Typography>
      </Box>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mb: spacing.m30 }}>
        {polyglot.t('ErrorComponent.sorry')}
      </Typography>
      <ButtonComponent
        sizeVariant="medium"
        colorVariant="secondary"
        fullWidth
        onClick={() => routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW)}
      >
        {polyglot.t('ErrorComponent.close')}{' '}
      </ButtonComponent>
    </Box>
  );
};

export const LoadingTableComponent = () => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ width: '80%' }}>
      <Typography sx={themeFonts.title2}>{polyglot.t('LoadingTableComponent.results')}</Typography>
      <Box sx={{ ...spacing.mt20 }}>
        {[1, 2, 3, 4].map((a) => (
          <LoadingStateComponent showDivider={a !== 4} key={a} />
        ))}
      </Box>
    </Box>
  );
};

export const NoDataComponent = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  return (
    <Box sx={{ maxWidth: '400px', width: '100%' }}>
      <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>{polyglot.t('NoDataComponent.report')}</Typography>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mb: spacing.m30 }}>
        {polyglot.t('NoDataComponent.sorry')}
      </Typography>
      <ButtonComponent
        sizeVariant="medium"
        colorVariant="secondary"
        fullWidth
        onClick={() => routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW)}
      >
        {polyglot.t('NoDataComponent.close')}
      </ButtonComponent>
    </Box>
  );
};
