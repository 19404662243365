import { CompanyPayroll, UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';

import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';

function canAddToGlobalPayroll(u: UserPayrollForTableDto, payroll: CompanyPayroll): boolean {
  return (
    !u.inPayroll &&
    // user must have a contract linked to an entity associated with a payroll
    !!u.entityId &&
    payroll.entity.id === u.entityId
    // the pay period of the payroll must match the pay schedule of the user - commented for now as the requirement is 'should allow to "add" anyone without full profile'
    // (@see https://linear.app/zelt/issue/ZELT-3876/missing-fields-small-corrections)
    // payroll.schedule?.payPeriod === u.userCompensation.paySchedule
  );
}

function canAddToUKPayroll(u: UserPayrollForTableDto, payroll: CompanyPayroll): boolean {
  return (
    !u.inPayroll &&
    !!u.userPayroll &&
    !hasPayrollMissingFields(u) &&
    compensationCurrencyIsGBP(u) &&
    // user must have a contract linked to an entity associated with a payroll
    payroll.entity.id === u.entityId &&
    // the pay period of the payroll must match the pay schedule of the user
    payroll.schedule?.payPeriod === u.userCompensation.paySchedule
  );
}

export function canAddToPayroll(u: UserPayrollForTableDto, payroll: CompanyPayroll): boolean {
  if (!payroll.employer) return canAddToGlobalPayroll(u, payroll);

  // uk-payroll
  return canAddToUKPayroll(u, payroll);
}

export function canRemoveFromPayroll(u: UserPayrollForTableDto, payroll: CompanyPayroll): boolean {
  if (!u.inPayroll) return false;
  const finalisedPayrunIds = new Set(payroll.payruns.filter((p) => p.state !== PayrunStates.draft).map(({ id }) => id));
  const userIsInFinalisedPayrun = u.payruns.some(({ payrunId }) => finalisedPayrunIds.has(payrunId));
  if (userIsInFinalisedPayrun) {
    // we can't remove users who have been included in finalised payruns
    return false;
  }
  return true;
}

export function hasPayrollMissingFields(u: UserPayrollForTableDto) {
  return !!u.missingFields?.payroll?.length;
}

export function compensationCurrencyIsGBP(u: UserPayrollForTableDto) {
  return u.userCompensation?.currency === 'GBP';
}
