import React, { useEffect, useMemo, useState } from 'react';

import { OnboardingContractConfig, OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { TemplateAPI } from '@/api-client/templates.api';
import { ReactComponent as ContractIcon } from '@/images/onboarding-step-icons/ContractBig.svg';
import { Scope } from '@/models';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { EditContracts } from '@/v2/feature/onboarding/onboarding-items/contracts/edit-contracts.component';
import { ContractTemplate } from '@/v2/feature/templates/templates.interface';
import { TemplatesScopes } from '@/v2/feature/templates/templates.scopes';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  checkOnboardingItemScope: (scope: readonly Scope[]) => boolean;
  onDelete?: () => void;
  setDrawerOpen: (open: boolean) => void;
  setDrawerView: (view: JSX.Element) => void;
  step: number;
};

export const ContractOnboardingItem = ({
  template,
  checkOnboardingItemScope,
  onDelete,
  setDrawerOpen,
  setDrawerView,
  step,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [contractTemplates, setContractTemplates] = useState<ContractTemplate[]>();
  const canAddTemplate = checkOnboardingItemScope(TemplatesScopes.LIST_CONTRACT_TEMPLATES);

  // this memo needs to refresh any time any of the contract step data changes
  const templateContractData = useMemo(() => {
    switch (step) {
      case 1:
        return template.template.contracts;
      case 2:
        return template.template.contracts_step2;
      case 3:
        return template.template.contracts_step3;
      case 4:
        return template.template.contracts_step4;
      case 5:
        return template.template.contracts_step5;
      default:
        return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    template.template,
    template.template.contracts,
    template.template.contracts_step2,
    template.template.contracts_step3,
    template.template.contracts_step4,
    template.template.contracts_step5,
    step,
  ]);

  const updateTemplateContractData = (updatedContracts: OnboardingContractConfig) => {
    if (step === 1) template.template.contracts = updatedContracts;
    if (step === 2) template.template.contracts_step2 = updatedContracts;
    if (step === 3) template.template.contracts_step3 = updatedContracts;
    if (step === 4) template.template.contracts_step4 = updatedContracts;
    if (step === 5) template.template.contracts_step5 = updatedContracts;
  };

  useEffect(() => {
    if (!templateContractData) return;
    if (!canAddTemplate) return;
    TemplateAPI.getTemplates().then(setContractTemplates);
  }, [canAddTemplate, template.template.contracts, templateContractData]);

  const [name, templateMissing] = useMemo(() => {
    const { templateId, templateName } = templateContractData ?? {};
    if (!templateId) return [undefined, undefined];
    if (!contractTemplates) return [templateName, false];
    const selectedTemplate = contractTemplates.find((t) => t.id === templateId);
    return [selectedTemplate?.name ?? templateName, !selectedTemplate];
  }, [contractTemplates, templateContractData]);

  return (
    <ActionSelectionItem
      key={step}
      step={{
        Icon: ContractIcon,
        title: polyglot.t('ContractOnboardingItem.title'),
        description: polyglot.t('ContractOnboardingItem.desc'),
      }}
      chips={[name ?? '']}
      addButtonIcon={templateContractData ? 'edit' : 'plus'}
      onAddClick={
        canAddTemplate &&
        (() => {
          setDrawerView(
            <EditContracts
              contract={templateContractData}
              onSave={(contracts) => {
                updateTemplateContractData(contracts);
                setDrawerOpen(false);
              }}
            />
          );
          setDrawerOpen(true);
        })
      }
      onDeleteClick={
        step !== 1
          ? template.template[`contracts_step${step}`] && onDelete
          : step === 1
          ? template.template.contracts && onDelete
          : undefined
      }
      sx={{
        py: spacing.py20,
      }}
      warning={templateMissing ? polyglot.t('ContractOnboardingItem.notFound') : undefined}
    />
  );
};
