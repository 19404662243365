import React, { useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { sortDate, sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsenceDto, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsencePolicyAllowanceType, AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, isHourlyPolicy } from '@v2/feature/absence/absence.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import Polyglot from 'node-polyglot';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { ReactComponent as WaitingEmpty } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { ReactComponent as WaitingEmptyRed } from '@/images/side-bar-icons/WaitingEmptyRed.svg';
import { nestErrorMessage } from '@/lib/errors';

const getStatusIcon = ({
  status,
  absence,
  polyglot,
}: {
  status: AbsenceStatus;
  absence: AbsenceDto;
  polyglot: Polyglot;
}) => {
  switch (status) {
    case AbsenceStatus.Pending:
      return (
        <Box sx={{ color: themeColors.Grey, gap: '5px', display: 'flex', alignItems: 'center' }}>
          <WaitingEmpty {...iconSize} />
          <Typography variant="caption" color="Grey">
            {polyglot.t('OffboardingAbsence.pending')}
          </Typography>
        </Box>
      );

    case AbsenceStatus.Approved:
      return absence.cancellationRequested ? (
        <Box
          sx={{
            color: themeColors.Red,
            gap: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WaitingEmptyRed {...iconSize} />
          <Typography variant="caption" color="Red">
            {polyglot.t('OffboardingAbsence.pendingCancellation')}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            gap: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          <Typography variant="caption">{polyglot.t('OffboardingAbsence.approved')}</Typography>
        </Box>
      );

    case AbsenceStatus.Rejected:
      return (
        <Box
          sx={{
            gap: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Rejected {...iconSize} fill={themeColors.Red} />
          <Typography variant="caption">{polyglot.t('OffboardingAbsence.rejected')}</Typography>
        </Box>
      );
  }
};

interface TableProps {
  readonly absencesAfterLeaveDate: AbsenceDto[];
  readonly refreshAll: () => Promise<void>;
}

export const OffboardingFutureAbsencesTable = ({ absencesAfterLeaveDate, refreshAll }: TableProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [selectedAbsence, setSelectedAbsence] = useState<AbsenceDto | undefined>(undefined);

  const deleteAbsence = useCallback(
    async (absenceId: number): Promise<void> => {
      try {
        await AbsenceAPI.deleteAbsenceRecord(absenceId);
        showMessage(polyglot.t('OffboardingAbsence.successMessages.deleted'), 'success');
        await refreshAll();

        setAnchorEl(null);
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [polyglot, showMessage, refreshAll]
  );

  const columnData = useMemo<ColumnDef<AbsenceDto, AbsenceDto>[]>(
    () => [
      {
        header: () => polyglot.t('OffboardingAbsence.period'),
        accessorFn: (row) => row,
        id: 'start',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.start),
        cell: ({ row }) => {
          const { original } = row;
          const isAM = original.morningOnly;
          const isPM = original.afternoonOnly;
          const startDateSuffixEl = isPM ? (
            <span>{polyglot.t('OffboardingAbsence.pm')}</span>
          ) : isAM && !original.end ? (
            <span>{polyglot.t('OffboardingAbsence.am')}</span>
          ) : null;
          const startDateEl = <time>{new LocalDate(original.start).toLocaleDateString()}</time>;
          return (
            <Box sx={{ whiteSpace: 'pre', display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption">
                {startDateEl}
                {startDateSuffixEl}
                {original.end && (
                  <>
                    {' - '}
                    <time>{new LocalDate(original.end).toLocaleDateString()}</time>
                    {isAM ? <span>{polyglot.t('OffboardingAbsence.am')}</span> : null}
                  </>
                )}
              </Typography>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('OffboardingAbsence.totalLength'),
        id: 'totalLength',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => Number(item.totalLength)),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          if (row.original.policy?.allowanceType === AbsencePolicyAllowanceType.UnlimitedHourly) {
            const startHour = row.original.startHourTimestamp
              ? new Date(row.original.startHourTimestamp)
                  .toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .toUpperCase()
              : undefined;
            const endHour = row.original.endHourTimestamp
              ? new Date(row.original.endHourTimestamp)
                  .toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .toUpperCase()
              : undefined;

            return startHour && endHour ? (
              <Typography variant="caption">{`${startHour} - ${endHour}`}</Typography>
            ) : (
              <EmptyCell />
            );
          }

          const totalLength = row.original.totalLength ?? 0;
          return (
            <Typography variant="caption">
              {polyglot.t('OffboardingAbsence.numHours', {
                smart_count: convertMinutesToClockHours(totalLength, polyglot),
              })}
            </Typography>
          );
        },
      },
      {
        header: () => polyglot.t('AbsenceTable.workdayCount'),
        id: 'workdayCount',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => (item.workdayCount ? Number(item.workdayCount) : 0)),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          const isHourly = isHourlyPolicy(row.original.policy as Pick<AbsencePolicyDto, 'allowanceType'>);
          if (isHourly) return <EmptyCell />;

          return (
            <Typography variant="caption">
              {row.original.workdayCount} day{row.original.workdayCount === 1 ? '' : 's'}
            </Typography>
          );
        },
      },
      {
        header: () => polyglot.t('OffboardingAbsence.policyId'),
        accessorFn: (row) => row,
        id: 'policyId',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.policy?.name),
        cell: ({ row }) =>
          row.original.policy?.name ? (
            <Box>
              <Typography variant="caption">{row.original.policy.name}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('OffboardingAbsence.status'),
        accessorFn: (row: AbsenceDto) => row,
        id: 'status',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item: AbsenceDto) => item.status),
        cell: ({ row }) => (
          <Box>
            <Typography variant="caption">
              {getStatusIcon({
                status: row.original.status,
                absence: row.original,
                polyglot,
              })}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('OffboardingAbsence.approvedBy'),
        accessorFn: (row) => row,
        id: 'approvedByIds',
        cell: ({ row }) => {
          const approvers = row.original.approvedByIds
            .map((userId) => {
              const name = getCachedUserById(userId)?.displayName;
              return name ? polyglot.t(name) : null;
            })
            .filter(Boolean);
          return approvers.length > 0 ? (
            <Box>
              <Typography variant="caption">{approvers.join(', ')}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => '',
        id: 'actions',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <IconButton
                sx={tableIconButtonSx}
                onClick={async (e) => {
                  setSelectedAbsence(original);
                  setAnchorEl(e.currentTarget);
                  e.stopPropagation();
                }}
              >
                <Trash {...iconSize} />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [polyglot, getCachedUserById]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mt: spacing.m60 }}>
        <Typography variant="title4">{polyglot.t('OffboardingAbsence.approvedRequests')}</Typography>

        <Box sx={{ ...spacing.mt20 }}>
          <BasicTable rowData={absencesAfterLeaveDate ?? []} columnData={columnData} hidePagination />
        </Box>
      </Box>

      <NotificationModal
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (!selectedAbsence) return;
          await deleteAbsence(selectedAbsence.absenceId);
          setSelectedAbsence(undefined);
          setAnchorEl(null);
        }}
        message={polyglot.t('OffboardingAbsence.confirmDelete')}
        callToAction={polyglot.t('General.yes')}
      />
    </Box>
  );
};
