import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { IconButton } from '@v2/components/forms/icon-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DocumentAPI } from '@v2/feature/documents/document.api';
import { BufferData, PreviewPayload } from '@v2/feature/documents/documents.interface';
import { DocPreviewer } from '@v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { nestErrorMessage } from '@/lib/errors';

export interface DocumentAttachment {
  filename: string;
}

export const AttachmentInDrawer = <T extends { attachment: string | null }>({
  entityWithAttachment,
  fileLabel,
  customHandlePreview,
  customDownload,
}: {
  readonly fileLabel?: string;
  readonly entityWithAttachment: T | null | undefined;
  readonly customHandlePreview?: () => void;
  readonly customDownload?: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [documentAttachment, setDocumentAttachment] = useState<DocumentAttachment | undefined>(undefined);
  // for attachment preview
  const [attachmentBuffer, setAttachmentBuffer] = useState<BufferData | undefined>();
  const [selectedAttachmentName, setSelectedAttachmentName] = useState('');
  const [selectedDocContentType, setSelectedDocContentType] = useState<string>('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  useEffect(() => {
    const fetchAttachment = async () => {
      try {
        const receiptData = entityWithAttachment?.attachment
          ? await DocumentAPI.getDocumentAttachmentByUuid(entityWithAttachment.attachment)
          : undefined;
        setDocumentAttachment(receiptData);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    };
    fetchAttachment();
  }, [showMessage, polyglot, entityWithAttachment?.attachment]);

  const handleDownloadClick = useCallback(async () => {
    try {
      if (customDownload) {
        await customDownload();
        return;
      }

      if (!entityWithAttachment || !entityWithAttachment.attachment || !documentAttachment) {
        console.error('Invalid receipt attachment');
        return;
      }

      const fileBlob = await DocumentAPI.downloadBlobViaUuid(entityWithAttachment.attachment);
      const fileURL = URL.createObjectURL(fileBlob);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = documentAttachment.filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error('::Download error', e);
      showMessage(`Failed to download attachment. ${nestErrorMessage(e)}`, 'error');
    }
  }, [customDownload, entityWithAttachment, documentAttachment, showMessage]);

  const handlePreviewClick = useCallback(async () => {
    try {
      if (!entityWithAttachment || !entityWithAttachment.attachment || entityWithAttachment.attachment?.length === 0) {
        console.error('Invalid receipt attachment');
        return;
      }

      await DocumentAPI.previewViaUuid(entityWithAttachment.attachment).then(
        async ({ contentType, file }: PreviewPayload) => {
          setSelectedAttachmentName('');
          setAttachmentBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        }
      );
    } catch (e) {
      console.error('::URL Download error', e);
      showMessage(`Failed to preview attachment: ${nestErrorMessage(e)}`, 'error');
    }
  }, [entityWithAttachment, showMessage]);

  return (
    <Box>
      <Typography variant="captionSmall" color="Grey">
        {fileLabel ?? polyglot.t('DocumentModule.attachment')}
      </Typography>
      <Box sx={{ display: 'flex', gap: spacing.gap10, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DocumentIcon {...iconSize} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
          <Typography variant={documentAttachment ? 'title4' : 'caption'}>
            {documentAttachment ? documentAttachment?.filename : 'No attachment'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap5 }}>
          {documentAttachment && (
            <IconButton sizeVariant="small" colorVariant="secondary" onClick={() => handleDownloadClick()}>
              <DownloadIcon {...iconSize} />
            </IconButton>
          )}
          <IconButton
            sizeVariant="small"
            colorVariant="secondary"
            onClick={async () => (customHandlePreview ? customHandlePreview() : await handlePreviewClick())}
          >
            <Eye {...iconSize} />
          </IconButton>
        </Box>
      </Box>

      {openPreviewModal && (
        <DocPreviewer
          fileBuffer={attachmentBuffer}
          contentType={selectedDocContentType}
          visible
          onClose={() => setOpenPreviewModal(false)}
          title={selectedAttachmentName}
        />
      )}
    </Box>
  );
};
