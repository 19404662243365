import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CountryCode, CustomCountryEnum } from '@/v2/infrastructure/country/country.interface';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { UserLifecycleStatuses } from '@/models';
import Polyglot from 'node-polyglot';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';

export interface UserEventDto {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  userId: number;
  companyId: number;
  createdBy: number;
  updatedBy: number;
  effectiveDate: string;
  status?: UserLifecycleStatuses;
  changeReason: string;
  note: string | null;
  effectiveEndDate: string | null;
}

export type CreateUserEventDto = Omit<UserEventDto, 'id' | 'createdAt' | 'updatedAt'>;

export type UpdateUserEventDto = Omit<UserEventDto, 'createdAt' | 'updatedAt'>;

export interface UserEquity {
  id: number;
  companyId: number;
  userId: number;
  createdBy: number;
  updatedBy: number;
  amount: number;
  currency: string;
  unitPrice: number;
  vestingStart?: string;
  vestingPeriod?: number;
  vestingCliff?: number;
  createdAt?: Date;
  updatedAt?: Date;
  grantDate?: string;
  type: string;
  reason: string;
  updatedByName?: string;
  totalAmount?: number;
}

export type CreateUserEquity = Omit<UserEquity, 'id' | 'createdAt' | 'updatedAt'>;

export type UpdateUserEquity = Omit<UserEquity, 'createdAt' | 'updatedAt'>;

export interface UserContract {
  id: number;
  companyId: number;
  userId: number;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: number;
  effectiveDate: string;
  contract?: UserContractKind | null;
  type: string;
  ftePercent: number;
  publicHolidays: CountryCode | CustomCountryEnum.code | null;
  holidayCalendarId: string | null;
  holidayCalendar?: Partial<HolidayCalendarDto> | null;
  changeReason: string;
  noticePeriodLength: number | null;
  noticePeriodUnit: TimeUnitType | null;
  probationPeriodLength: number | null;
  probationPeriodUnit: TimeUnitType | null;
  entityId: number | null;
  attendanceScheduleId: number | null;
  attendanceSchedule?: AttendanceScheduleDto | null;
  entity: CompanyUnitDto | null;
  contractEndDate: string | null;
  customUpdates: UserCustomDataDto[];
}

type ContractArray = { value: string; label: string }[];

export const UserContractKinds = (polyglot: Polyglot): ContractArray => {
  return [
    { value: 'Full-time', label: polyglot.t('UserContractKinds.fullTime') },
    { value: 'Part-time', label: polyglot.t('UserContractKinds.partTime') },
    { value: 'Shifts', label: polyglot.t('UserContractKinds.shifts') },
  ];
};

export type UserContractKind = ContractArray[number]['value'];

export const UserContractTypes = (polyglot: Polyglot): ContractArray => {
  return [
    {
      value: 'Contractor',
      label: polyglot.t('UserContractTypes.contractor'),
    },
    {
      value: 'Employee',
      label: polyglot.t('UserContractTypes.employee'),
    },
    {
      value: 'Consultant',
      label: polyglot.t('UserContractTypes.consultant'),
    },
    {
      value: 'EOR',
      label: polyglot.t('UserContractTypes.eor'),
    },
  ];
};

export type UserContractType = ContractArray[number]['value'];
export const TimeUnitTypes = (polyglot: Polyglot) => {
  return [
    {
      value: 'Days',
      label: 'Days',
    },
    {
      value: 'Weeks',
      label: 'Weeks',
    },
    {
      value: 'Months',
      label: 'Months',
    },
  ];
};

export type TimeUnitType = ContractArray[number]['value'];

export interface CreateUserContract {
  effectiveDate: string;
  contract?: UserContractKind | null;
  type: string;
  ftePercent: number;
  publicHolidays?: CountryCode | CustomCountryEnum.code | null;
  holidayCalendarId?: string | null;
  changeReason?: string;
  noticePeriodLength?: number | null;
  noticePeriodUnit?: TimeUnitType | null;
  probationPeriodLength?: number | null;
  probationPeriodUnit?: TimeUnitType | null;
  entityId: number | null;
  attendanceScheduleId: number | null;
  contractEndDate: string | null;
  customUpdates: UserCustomDataDto[];
}

export interface UpdateUserContract extends CreateUserContract {
  id: number;
}
