import axios from 'axios';

export class BillingContactAPI {
  // To get Billing contact use BillingAPI.getBillingInformation()
  static async updateContact(billingContactInfo: {
    id: number;
    userId: number;
    invoiceEmail?: string | null;
    phoneNumber: string;
  }): Promise<void> {
    return (await axios.put('/apiv2/billing/contact', billingContactInfo)).data;
  }

  static async createContact(userId: number, invoiceEmail: string | null, phoneNumber: string): Promise<void> {
    return (await axios.post('/apiv2/billing/contact', { userId, invoiceEmail, phoneNumber })).data;
  }
}
