import React, { useCallback, useMemo, useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as UploadIcon } from '@/images/icons/Upload.svg';
import { ReactComponent as Note } from '@/images/side-bar-icons/MistakeOrange.svg';
import { nestErrorMessage } from '@/lib/errors';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ImportIncomeDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/import-income-drawer.component';
import { PayrunButtons } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-buttons.component';
import { PayrunDeductionsTable } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-deductions-table.component';
import { PayrunEmployerCostTable } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-employer-cost-table.component';
import { PayrunIncomeTable } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-income-table.component';
import { PayrunSalaryTabs } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-salary-tabs.component';
import { PayrunSummaryTable } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-summary-table.component';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayrollLocalEndpoints } from '@/v2/feature/payroll/payroll-local.api';
import { PayRunDto, PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const PayrunSalaryTabsLabels = ['Summary', 'Income', 'Deductions', 'Employer cost'] as const;
type PayrunSalaryTabsLabel = typeof PayrunSalaryTabsLabels[number];

type PayrunSalariesSectionProps = {
  hideButtons?: boolean;
  hideTitle?: boolean;
  localPayRun: PayRunDto;
  entries: PayRunEntryDto[];
  previousEntries: PayRunEntryDto[];
  onBack?: () => void;
  onNext?: () => void;
  refreshPayrun?: () => Promise<void>;
};

export const PayrunSalariesSection = ({
  entries,
  previousEntries,
  hideButtons,
  hideTitle,
  localPayRun,
  onBack,
  onNext,
  refreshPayrun,
}: PayrunSalariesSectionProps) => {
  const [searchInput, setSearchInput] = useState('');
  const [tab, setTab] = useState<PayrunSalaryTabsLabel>('Summary');
  const [importIncomeDrawerOpen, setImportIncomeDrawerOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const payrunClosed = localPayRun.state !== PayrunStates.draft;

  const { data: payCodes } = useApiClient(PayrollLocalEndpoints.getPayrunPayCodes(localPayRun.id), {
    suspense: false,
  });

  const [filteredPayrunEntries, filteredPreviousPayrunEntries] = useMemo(() => {
    const query = searchInput.trim().toLocaleLowerCase();
    const filterFn = (entry: PayRunEntryDto) => entry?.employee?.name?.toLowerCase().includes(query);
    return query ? [entries.filter(filterFn), previousEntries.filter(filterFn)] : [entries, previousEntries];
  }, [entries, previousEntries, searchInput]);

  const saveIncomeUpdates = useCallback(
    async (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => {
      let success = false;
      try {
        const { payrollId, taxYear, payPeriod, period } = localPayRun;
        success = (await PayrollExternalApi.updateIncome(payrollId, taxYear, payPeriod, period, incomeUpdates), true);
        await refreshPayrun?.();
        showMessage('Payrun updated.', 'success');
      } catch (error) {
        showMessage(`Payrun update failed. ${nestErrorMessage(error)}`, 'error');
      }
      return success;
    },
    [localPayRun, refreshPayrun, showMessage]
  );

  return (
    <>
      {!hideTitle && (
        <>
          <Typography sx={{ ...themeFonts.title2, mr: spacing.mr40, color: themeColors.DarkGrey }}>
            Review and edit pay for employees
          </Typography>
          <Stack flexDirection="row" alignItems="center" sx={{ gap: spacing.g5, mt: spacing.m10, mr: spacing.mr40 }}>
            <Note style={{ width: 14, height: 14 }} />
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              Please make sure all salaries are correct before clicking "Continue"
            </Typography>
          </Stack>
        </>
      )}
      <PayrunSalaryTabs
        tabs={PayrunSalaryTabsLabels}
        currentTab={tab}
        onChange={(tab) => setTab(tab as PayrunSalaryTabsLabel)}
        sx={{ mt: spacing.mt40 }}
      />
      <Stack sx={{ flexFlow: 'row', pr: spacing.pr40, my: spacing.my20, justifyContent: 'space-between' }}>
        <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value)} />
        {(tab === 'Income' || tab === 'Deductions') && !payrunClosed && (
          <Button
            sx={secondaryCTABtn}
            startIcon={<UploadIcon {...iconSize} />}
            onClick={() => setImportIncomeDrawerOpen(true)}
          >
            Import
          </Button>
        )}
      </Stack>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {tab === 'Summary' && (
          <PayrunSummaryTable
            localPayRun={localPayRun}
            entries={filteredPayrunEntries}
            previousEntries={filteredPreviousPayrunEntries}
          />
        )}
        {tab === 'Income' && (
          <PayrunIncomeTable
            payCodes={payCodes}
            entries={filteredPayrunEntries}
            previousEntries={filteredPreviousPayrunEntries}
            payrunClosed={payrunClosed}
            saveIncomeUpdates={saveIncomeUpdates}
          />
        )}
        {tab === 'Deductions' && (
          <PayrunDeductionsTable
            payCodes={payCodes}
            entries={filteredPayrunEntries}
            previousEntries={filteredPreviousPayrunEntries}
            payrunClosed={payrunClosed}
            saveIncomeUpdates={saveIncomeUpdates}
          />
        )}
        {tab === 'Employer cost' && (
          <PayrunEmployerCostTable
            payCodes={payCodes}
            entries={filteredPayrunEntries}
            previousEntries={filteredPreviousPayrunEntries}
            payrunClosed={payrunClosed}
            saveIncomeUpdates={saveIncomeUpdates}
          />
        )}
      </Box>
      {!hideButtons && (
        <PayrunButtons
          primaryButtonLabel="Continue"
          showBack
          onBackClick={onBack}
          onPrimaryClick={onNext}
          sx={{ mt: spacing.m10, mx: spacing.mx40, mb: spacing.m30, justifyContent: 'flex-end' }}
        />
      )}
      <ImportIncomeDrawer
        isOpen={importIncomeDrawerOpen}
        setIsOpen={setImportIncomeDrawerOpen}
        onClose={() => setImportIncomeDrawerOpen(false)}
        entries={entries}
        payCodes={payCodes}
        saveIncomeUpdates={saveIncomeUpdates}
      />
    </>
  );
};
