import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { JobLevelEndpoints } from '@/v2/feature/job-level/job-level.api';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { JobPositionAPI } from '@/v2/feature/job-position/job-position.api';
import {
  drawerContentSx,
  editDeleteHeaderSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

// Import other necessary dependencies and styles

interface JobPositionEditFormDrawerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  jobPositionToEdit: JobPositionForTable;
  onClose: () => void;
  refreshJobPositions: () => Promise<void>;
}

export const JobPositionAddEditFormDrawer = ({
  isOpen,
  setIsOpen,
  jobPositionToEdit,
  onClose,
  refreshJobPositions,
}: JobPositionEditFormDrawerProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [positionToDelete, setPositionToDelete] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isEditMode = !!jobPositionToEdit.id;
  const { data: jobLevels } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });

  const jobsLookup = useMemo(() => {
    const groupMap = new Map();
    jobLevels?.forEach((item) => {
      if (!groupMap.has(item.trackName)) {
        groupMap.set(item.trackName, { counter: 1, options: [] });
      }
      const group = groupMap.get(item.trackName);
      group.options.push({
        value: item.levelId,
        label: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <Typography variant="caption">
              {item.trackName
                .split(' ')
                .map((word) => word[0])
                .join('')}
              {group.counter}
            </Typography>
            <Typography variant="caption">{item.levelName}</Typography>
          </Box>
        ),
        description: `${item.trackName}`,
      });
      group.counter += 1;
    });
    const flattenedArray = Array.from(groupMap.values())
      .flatMap((group) => group.options)
      .map((option) => ({
        ...option,
        description: `${option.description}`,
      }));

    return flattenedArray;
  }, [jobLevels]);

  const handleSubmit = async (values: JobPosition) => {
    try {
      setLoading(true);
      if (isEditMode) {
        await JobPositionAPI.updateJobPosition(values.id!, values);
      } else {
        await JobPositionAPI.createJobPosition(values);
      }
      await refreshJobPositions();
      onClose();
      showMessage(
        isEditMode
          ? polyglot.t('PositionManagementDrawerPage.successMessages.updatePosition')
          : polyglot.t('PositionManagementDrawerPage.successMessages.addedPosition'),
        'success'
      );
    } catch (error) {
      showMessage(
        `Encountered an error while trying to ${isEditMode ? 'update' : 'create'} job position: ${nestErrorMessage(
          error
        )}`,
        'error'
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { ...jobPositionToEdit },
    validationSchema: Yup.object().shape({
      internalCode: Yup.string().required('Required'),
      title: Yup.string().required('Required'),
      description: Yup.string().nullable().notRequired(),
      levelId: Yup.number().nullable().notRequired(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const deletePosition = async () => {
    try {
      setLoading(true);
      if (positionToDelete) await JobPositionAPI.deleteJobPosition(positionToDelete);
      else {
        throw new Error(polyglot.t('PositionManagementDrawerPage.errorMessages.noPositionToDelete'));
      }
      showMessage(polyglot.t('PositionManagementDrawerPage.successMessages.deletePosition'), 'success');
      await refreshJobPositions();
    } catch (error) {
      showMessage(
        `${polyglot.t('PositionManagementDrawerPage.errorMessages.deletePosition')}: ${nestErrorMessage(error)}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, positionId: number) => {
    if (event) setAnchorEl(event.currentTarget);
    setPositionToDelete(positionId);
    setIsConfirmModalOpen(true);
  };

  const drawerTitle = isEditMode
    ? polyglot.t('JobPositionSettingsPage.editPosition')
    : polyglot.t('JobPositionSettingsPage.newPosition');

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Box sx={{ ...editDeleteHeaderSx, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{drawerTitle}</Typography>
            {isEditMode && (
              <>
                <IconButton
                  sizeVariant="small"
                  colorVariant="secondary"
                  onClick={(event) => {
                    if (jobPositionToEdit && jobPositionToEdit.id) confirmDelete(event, jobPositionToEdit.id);
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </>
            )}
          </Box>

          <TextfieldComponent
            label="Job title (Required)"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && !!formik.errors.title}
            helperText={formik.touched.title && formik.errors.title}
            type="text"
            required
            clearText={() => formik.setFieldValue('title', '')}
          />

          <TextfieldComponent
            label="Internal code"
            name="internalCode"
            value={formik.values.internalCode}
            onChange={formik.handleChange}
            error={formik.touched.internalCode && !!formik.errors.internalCode}
            helperText={formik.touched.internalCode && formik.errors.internalCode}
            type="text"
            required
          />

          <TextfieldComponent
            multiline
            label="Job description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            type="text"
            clearText={() => formik.setFieldValue('description', '')}
          />

          <SelectComponent
            name="levelId"
            label="Level"
            options={jobsLookup}
            value={formik.values.levelId}
            compareValue={formik.values.levelId}
            error={!!formik.errors.levelId && formik.touched.levelId}
            onChange={formik.handleChange}
            helperText={formik.errors.levelId && formik.touched.levelId}
          />

          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent colorVariant="primary" sizeVariant="medium" disabled={loading} fullWidth type="submit">
              {polyglot.t('General.save')}
            </ButtonComponent>
          </Box>

          <NotificationModal
            isOpen={isConfirmModalOpen}
            onClose={() => {
              setIsConfirmModalOpen(false);
            }}
            anchorEl={anchorEl}
            takeAction={async () => {
              if (positionToDelete) {
                setIsConfirmModalOpen(false);
                onClose();
                await deletePosition();
              } else showMessage(polyglot.t('PositionManagementDrawerPage.errorMessages.noPositionSelected'), 'error');
            }}
            message={polyglot.t('PositionManagementDrawerPage.deletePositionMessage')}
            callToAction="Yes"
          />
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
