import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceSettingsNewScheduleDrawer } from '@v2/feature/attendance/settings/components/attendance-settings-new-schedule-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { borders } from '@v2/styles/borders.styles';
import { secondaryCTABtn } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { responsiveParentCardSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { ATTENDANCE_COMPANY_ROUTE, SETTINGS_ATTENDANCE_SCHEDULE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

interface AttendanceCompanySettingsAttendancePageProps {
  readonly attendanceSchedules: readonly AttendanceScheduleDto[];
  readonly refreshSchedules: () => Promise<void>;
}

export const AttendanceCompanySettingsAttendanceSchedulesPage = ({
  attendanceSchedules,
  refreshSchedules,
}: AttendanceCompanySettingsAttendancePageProps) => {
  const { polyglot } = usePolyglot();

  const routerLocation = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={themeFonts.title2}>{polyglot.t('SettingsRouterPageConfig.schedules')}</Typography>}
        showBack={routerLocation.pathname.includes(ATTENDANCE_COMPANY_ROUTE)}
        backPath={ATTENDANCE_COMPANY_ROUTE}
        showAction
        actions={
          <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => setIsOpen(true)}>
            {polyglot.t('AttendanceCompanySettingsAttendanceSchedulesPage.new')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={loading}>
        <Box sx={{ maxWidth: 600 }}>
          {attendanceSchedules && attendanceSchedules.length > 0 && (
            <Box sx={responsiveParentCardSx}>
              <Box sx={{ mt: spacing.m10 }}>
                {attendanceSchedules.map((schedule, index) => (
                  <ScheduleCard schedule={schedule} index={index} key={index} />
                ))}
              </Box>
            </Box>
          )}
        </Box>

        <AttendanceSettingsNewScheduleDrawer isOpen={isOpen} setIsOpen={setIsOpen} refresh={refreshSchedules} />
      </ContentWrapper>
    </RootStyle>
  );
};

const ScheduleCard = ({ schedule, index }: { schedule: AttendanceScheduleDto; index: number }) => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();

  const goToSchedule = useCallback(() => {
    routerHistory.push(generatePath(SETTINGS_ATTENDANCE_SCHEDULE_ROUTE, { scheduleId: schedule.id }));
  }, [routerHistory, schedule.id]);

  return (
    <Box
      key={index}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: spacing.g10,
        // cursor: 'move',
        cursor: 'pointer',
        backgroundColor: themeColors.white,
        borderBottom: borders.light,
      }}
      onDoubleClick={goToSchedule}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          py: '20px',
          gap: spacing.g10,
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Typography sx={themeFonts.title4}>{schedule.name}</Typography>
        </Box>
        <Box sx={{}}>
          <Button sx={secondaryCTABtn} onClick={goToSchedule}>
            {polyglot.t('AttendanceCompanySettingsAttendanceSchedulesPage.details')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
