import React from 'react';

import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { borders } from '@v2/styles/borders.styles';
import { themeColors } from '@v2/styles/colors.styles';

interface ChipWithIconProps {
  name: string | JSX.Element;
  icon?: JSX.Element;
  onClick?: () => void;
  textColor?: keyof typeof themeColors;
  backgroundColor?: keyof typeof themeColors;
  border?: keyof typeof borders;
  textVariant?: TypographyVariant;
}

export const ChipComponentWithIcon = ({
  name,
  icon,
  onClick,
  textColor = 'Grey',
  backgroundColor = 'Background',
  border,
  textVariant = 'caption',
}: ChipWithIconProps) => {
  const chipStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '2px 10px',
    borderRadius: '25px',
    backgroundColor: themeColors[backgroundColor],
    cursor: onClick ? 'pointer' : 'default',
    border: border ? borders[border] : undefined,
    gap: '8px',
  };

  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div style={chipStyle} onClick={handleClick}>
      {icon && <div style={iconStyle}>{icon}</div>}
      <Typography variant={textVariant} color={textColor}>
        {name}
      </Typography>
    </div>
  );
};
