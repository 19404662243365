import { ConfigurationPayloadOption, DevicePossessionDto } from '@v2/feature/device/device.dto';

export enum DeviceType {
  Laptop = 'Laptop',
  Desktop = 'Desktop',
  Phone = 'Phone',
  Tablet = 'Tablet',
  Monitor = 'Monitor',
  Headset = 'Headset',
  Mouse = 'Mouse',
  Keyboard = 'Keyboard',
  Other = 'Other',
}

export enum ReturnDeviceReason {
  Damage = 'Damage',
  CancelContract = 'CancelContract',
  ReturnToCompanySite = 'ReturnToCompanySite',
  SendToZeltStorage = 'SendToZeltStorage',
}

export enum PasswordPolicyLevel {
  MODERATE = 'MODERATE',
  STRICT = 'STRICT',
  BASIC = 'BASIC',
  NONE = 'NONE',
}

export interface DeviceApplication {
  id: number | string;
  name: string;
  version: string;
  status: string;
  blacklisted: boolean;
  managed: boolean;
  size: number;
}

export interface DevicePolicy {
  id: number;
  name: string;
  description: string;
  version: number;
}

export interface DeviceExternalMatching {
  readonly [localId: number]: number;
}

export enum DeviceOwnership {
  Rental = 'Rental',
  CompanyPurchase = 'CompanyPurchase',
  Company = 'Company',
}

export enum DevicePolicyType {
  passcode = 'passcode',
  encryption = 'encryption',
  location = 'location',
  updates = 'updates',
  apps = 'apps',
  icloud = 'icloud',
  firewall = 'firewall',
}

export enum SupportedDeviceType {
  android = 'android',
  windows = 'windows',
  apple = 'apple',
}

export enum DevicePolicyZeltType {
  passcode = 'passcode',
  encryption = 'encryption',
  icloud = 'icloud',
  firewall = 'firewall',
  apps = 'apps',
}

export enum DevicePolicyNameByZelt {
  passcode = 'Passcode',
  encryption = 'Encryption',
  icloud = 'Restrict iCloud',
  firewall = 'Firewall',
  apps = 'Managed Apps',
}

export enum DevicePolicyConfigurableNameByZelt {
  passcode = 'Passcode',
  encryption = 'Encryption',
  icloud = 'Restrict iCloud',
  firewall = 'Firewall',
  apps = 'Managed Apps',
}

export interface UnmatchedExternalDevice {
  readonly id: number;
  readonly modelName: string;
  readonly platform: string;
}

export interface DeviceProtectionRating {
  readonly name: string;
  readonly rating: string;
  readonly color: string;

  readonly [key: string]: string;
}

export enum DevicePossessionType {
  CompanySite = 'CompanySite',
  User = 'User',
  ZeltStorage = 'ZeltStorage',
  ZeltStock = 'ZeltStock',
}

export const DevicePossessionTypeOptions = [
  { label: 'CompanySite', value: DevicePossessionType.CompanySite },
  { label: 'User', value: DevicePossessionType.User },
  { label: 'ZeltStorage', value: DevicePossessionType.ZeltStorage },
  { label: 'ZeltStock', value: DevicePossessionType.ZeltStock },
];

export enum DeviceTransitStatus {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Shipping = 'Shipping',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
}

export enum DeviceOrderStatus {
  Requested = 'Requested',
  Placed = 'Placed',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Shipping = 'Shipping',
  Delivered = 'Delivered',
}

export interface DeviceLocation {
  readonly name: string | null;
  readonly latitude: string | null;
  readonly longitude: string | null;
  readonly time: string | null;
  readonly locationTrackingDisabled: boolean;
}

export interface DeviceProtectionStatus {
  score: number;
  maxScore: number;
  detailed: {
    enrolled: boolean;
    supervised: boolean;
    passwordProtected: boolean;
    encryptionEnabled: boolean;
    latestOSVersion: boolean;
    recentlyActive: boolean;
    recentlyScanned: boolean;
  };
}

export enum DeliveryMethod {
  ShippingToEmployee = 'shippingToEmployee',
  ShippingToSite = 'shippingToSite',
  Handover = 'handover',
  AssignDepToUser = 'assignDepToUser',
}

export interface DeliveryMethodDetails {
  deliveryMethod: DeliveryMethod;
  deliveryAddress: string | undefined;
  date: string | null;
  deviceAccountName?: string | null;
}

export enum OrderDeliveryCountry {
  UK = 'UK',
  EU = 'EU',
}

export interface DevicePossessionDetails {
  possessionType: DevicePossessionType;
  possessionId: number;
}

export type SuperAdminDevicePossessionUpdate = Pick<DevicePossessionDto, 'startDate' | 'endDate' | 'deliveryAddress'>;

export enum DeviceContractLength {
  Months36 = 36,
  Months24 = 24,
  Months12 = 12,
  Months6 = 6,
}

export enum DeviceModelOs {
  MacOS = 'macos',
  // eslint-disable-next-line unicorn/prevent-abbreviations
  iOS = 'ios',
  // eslint-disable-next-line unicorn/prevent-abbreviations
  iPadOS = 'ipados',
  Android = 'android',
  Windows = 'windows',
  Linux = 'linux',
  Other = 'other',
  None = 'none',
}

export const AppleDevices = [DeviceModelOs.MacOS, DeviceModelOs.iOS, DeviceModelOs.iPadOS];

export enum DeviceInformationTypes {
  OSVersion = 'OS Version',
  Storage = 'Storage',
  LastActive = 'Last active',
  Mdm = 'MDM',
}

export enum MDMConfigurationTypes {
  Passcode = 'Passcode',
  Encryption = 'Encryption',
  iCloudSync = 'iCloud sync',
  Firewall = 'Firewall',
  Apps = 'Managed Apps',
  ActivationLock = 'Activation Lock',
}

export interface ZeltMDMSetting {
  type: DevicePolicyZeltType;
  name: DevicePolicyNameByZelt;
  value: number[];
  policyStatus: boolean;
  policyName: string[];
  restriction: boolean;
  configurablePolicy?: ConfigurationPayloadOption;
}

export enum SecurityStatus {
  None = 'None',
  NotEnrolled = 'Not enrolled',
  Alert = 'Alert',
  Good = 'Good',
}

export enum EnrolmentType {
  NONE = 'NONE',
  ABM = 'ABM',
  ABM_DEP = 'ABM_DEP',
  OPEN_ENROLMENT = 'OPEN_ENROLMENT',
}

export enum ConfigurablePoliciesByZeltMdm {
  configurablePasscode = 'configurablePasscode',
  configurableICloudRestriction = 'configurableICloudRestriction',
  configurableAdditionalPasswordPolicy = 'configurableAdditionalPasswordPolicy',
  configurableEncryptionPolicy = 'configurableEncryptionPolicy',
  configurableFirewallPolicy = 'configurableFirewallPolicy',
  configurableScreenSettingPolicy = 'configurableScreenSettingPolicy',
  configurableBrowsing = 'configurableBrowsing',
  configurableSecurity = 'configurableSecurity',
  configurableApps = 'configurableApps',
}

export interface AssignDeviceToABM {
  deviceId: number;
  serialNumber: string;
  depTokenName: string;
  depDeviceUsername: string;
}

export interface NestedMapping {
  [key: string]: string;
}

export interface DeviceMapping {
  [key: string]: string | NestedMapping;
}

export enum ConfigurableDeviceStatus {
  order = 'order',
  shipping = 'shipping',
  inUse = 'in-use',
  inventory = 'inventory',
}
