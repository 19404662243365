import React from 'react';

import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { LegalGender } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';

type LegalGenderProps = {
  gender?: LegalGender;
  setGender: (gender: LegalGender) => void;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
};

export const LegalGenderSelect = ({ gender, setGender, disabled, helperText, error }: LegalGenderProps) => {
  const { polyglot } = usePolyglot();
  return (
    <>
      <Stack>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingByUser.stateLegalGender')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            value={gender || ''}
            onChange={(event) => {
              setGender(event.target.value as LegalGender);
            }}
            sx={{ flexFlow: 'row' }}
          >
            <FormControlLabel
              labelPlacement="end"
              value={'male'}
              control={<StyledRadio />}
              label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('OnboardingByUser.male')}</Typography>}
              disabled={disabled}
              sx={{ width: 200 }}
            />
            <FormControlLabel
              labelPlacement="end"
              value={'female'}
              control={<StyledRadio />}
              disabled={disabled}
              label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('OnboardingByUser.female')}</Typography>}
            />
          </RadioGroup>
        </FormControl>
        <div style={{ display: 'relative' }}>
          <Typography sx={{ position: 'absolute', ...themeFonts.caption, color: error ? themeColors.red : undefined }}>
            {helperText}
          </Typography>
        </div>
      </Stack>
    </>
  );
};
