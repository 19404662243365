import React from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { PayrunHeaderMenu } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header-menu.component';
import { PayrunStatus } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component';
import { navigateToPayrunsView } from '@/v2/feature/payroll/payroll-router.util';
import { CompanyPayrollType, PayrunStates } from '@/v2/feature/payroll/payroll.interface';

type PayrunHeaderProps = {
  payrollId: number;
  payrollType: CompanyPayrollType;
  entityName: string;
  state: PayrunStates;
  onReopenPayrun?: () => Promise<void>;
  sx?: SxProps<Theme>;
};

export const PayrunHeader = ({ payrollId, payrollType, state, onReopenPayrun, sx }: PayrunHeaderProps) => {
  const routerHistory = useHistory();
  const isClosed = state !== PayrunStates.draft;

  return (
    <Stack flexDirection="row" alignItems="center" sx={sx}>
      <PayrunStatus state={state} />
      <PayrunHeaderMenu
        payrollType={payrollType}
        canReopenPayrun={isClosed}
        canDeletePayrun={!isClosed}
        onReopenPayrun={onReopenPayrun}
        onDeletePayrun={() => {
          // navigate back to the payruns list on delete
          navigateToPayrunsView(routerHistory, 'replace', payrollId);
        }}
        sx={{ ml: 'auto' }}
      />
    </Stack>
  );
};
