import React, { Suspense, useState } from 'react';

import { Box, InputAdornment } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PensionWorkerGroupDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { EarningsType } from '@v2/feature/benefits/subfeature/pension/pension.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PensionAPI } from '@/v2/feature/benefits/subfeature/pension/pension.api';

interface ManageWorkerGroupDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly pensionSchemeId: number;
  readonly refresh: () => Promise<void>;
  readonly workerGroup: PensionWorkerGroupDto | null;
}

export const ManageWorkerGroupDrawer = ({
  isOpen,
  setIsOpen,
  pensionSchemeId,
  refresh,
  workerGroup,
}: ManageWorkerGroupDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <ManageWorkerGroupDrawerContent
          setIsOpen={setIsOpen}
          pensionSchemeId={pensionSchemeId}
          refresh={refresh}
          workerGroup={workerGroup}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface ManageWorkerGroupFormData {
  name: string | undefined;
  employerContribution: string;
  employeeContribution: string;
  customThreshold: EarningsType;
  lowerLimit: number;
  upperLimit: number;
}

interface ManageWorkerGroupDrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly pensionSchemeId: number;
  readonly refresh: () => Promise<void>;
  readonly workerGroup: PensionWorkerGroupDto | null;
}

const ManageWorkerGroupDrawerContent = ({
  setIsOpen,
  pensionSchemeId,
  refresh,
  workerGroup,
}: ManageWorkerGroupDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElSave, setAnchorElSave] = React.useState<HTMLButtonElement | null>(null);
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const DefaultUpperLimit = 50270;
  const DefaultLowerLimit = 6240;
  const UnbandedUpperLimit = 1000000;
  const earningsLimitOptions = [
    { value: EarningsType.qualifying, label: EarningsType.qualifying },
    { value: EarningsType.custom, label: EarningsType.custom },
    { value: EarningsType.unbanded, label: EarningsType.unbanded },
  ];

  const formik = useFormik<ManageWorkerGroupFormData>({
    initialValues: {
      name: workerGroup?.name ?? undefined,
      employerContribution: String(workerGroup?.employerContribution ?? 3),
      employeeContribution: String(workerGroup?.employeeContribution ?? 5),
      customThreshold:
        workerGroup && (workerGroup.upperLimit !== DefaultUpperLimit || workerGroup.lowerLimit !== DefaultLowerLimit)
          ? workerGroup.upperLimit === UnbandedUpperLimit
            ? EarningsType.unbanded
            : EarningsType.custom
          : EarningsType.qualifying,
      lowerLimit: workerGroup?.lowerLimit ?? DefaultLowerLimit,
      upperLimit: workerGroup?.upperLimit ?? DefaultUpperLimit,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      employerContribution: Yup.number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      employeeContribution: Yup.number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      customThreshold: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      lowerLimit: Yup.number()
        .min(0)
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      upperLimit: Yup.number()
        .min(0)
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    enableReinitialize: true,
    onSubmit: async (values: ManageWorkerGroupFormData) => {
      if (!values.employeeContribution || !values.employerContribution) {
        showMessage(polyglot.t('PensionModule.contributionsMinimum8Percent'), 'error');
        return;
      }

      const employerContribution = Number.parseFloat(values.employerContribution);
      const employeeContribution = Number.parseFloat(values.employeeContribution);
      if (employeeContribution < 0 || employerContribution < 0 || employeeContribution + employerContribution < 8) {
        showMessage(polyglot.t('PensionModule.contributionsMinimum8Percent'), 'error');
        return;
      }

      await (workerGroup ? updateWorkerGroup(values) : addWorkerGroup(values));
    },
  });

  const addWorkerGroup = async (data: ManageWorkerGroupFormData): Promise<void> => {
    try {
      if (!data.name) return;
      const employerContribution = Number.parseFloat(data.employerContribution);
      const employeeContribution = Number.parseFloat(data.employeeContribution);
      setLoading(true);
      await PensionAPI.addWorkerGroup(pensionSchemeId, {
        customThreshold: data.customThreshold !== EarningsType.qualifying,
        name: data.name,
        upperLimit: data.upperLimit,
        lowerLimit: data.lowerLimit,
        employeeContribution,
        employerContribution,
      });
      showMessage(polyglot.t('PensionModule.workerGroupAdded'), 'success');
      setIsOpen(false);
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateWorkerGroup = async (data: ManageWorkerGroupFormData): Promise<void> => {
    if (!workerGroup?.id || !data.name) return;
    const employerContribution = Number.parseFloat(data.employerContribution);
    const employeeContribution = Number.parseFloat(data.employeeContribution);
    try {
      setLoading(true);
      await PensionAPI.updateWorkerGroup(pensionSchemeId, workerGroup.id, {
        name: data.name,
        customThreshold: data.customThreshold !== EarningsType.qualifying,
        upperLimit: data.upperLimit,
        lowerLimit: data.lowerLimit,
        employeeContribution,
        employerContribution,
      });
      showMessage(polyglot.t('PensionModule.workerGroupUpdated'), 'success');
      setIsOpen(false);
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteWorkerGroup = async (): Promise<void> => {
    if (!workerGroup?.id) return;
    try {
      setIsDeleteLoading(true);
      await PensionAPI.deleteWorkerGroup(pensionSchemeId, workerGroup.id);
      showMessage(polyglot.t('PensionModule.workerGroupDeleted'), 'success');
      setIsOpen(false);
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {workerGroup ? polyglot.t('PensionModule.updateWorkerGroup') : polyglot.t('PensionModule.addWorkerGroup')}
        </Typography>

        <TextfieldComponent
          name="name"
          label={polyglot.t('PensionModule.groupName')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && !!formik.errors.name}
          helperText={(formik.touched.name && formik.errors.name) as string}
        />
        <Box sx={{ display: 'flex', gap: spacing.gap20 }}>
          <TextfieldComponent
            name="employerContribution"
            label={polyglot.t('BenefitModule.employerContribution')}
            value={formik.values.employerContribution}
            onChange={formik.handleChange}
            error={formik.touched.employerContribution && !!formik.errors.employerContribution}
            helperText={(formik.touched.employerContribution && formik.errors.employerContribution) as string}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
          <TextfieldComponent
            name="employeeContribution"
            label={polyglot.t('BenefitModule.employeeContribution')}
            value={formik.values.employeeContribution}
            onChange={formik.handleChange}
            error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
            helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) as string}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </Box>

        <SelectComponent
          name="customThreshold"
          label={polyglot.t('PensionModule.earningLimits')}
          options={earningsLimitOptions}
          value={formik.values.customThreshold}
          compareValue={formik.values.customThreshold}
          onChange={(e) => {
            formik.handleChange(e);
            if (e.target.value === EarningsType.qualifying) {
              formik.setFieldValue('upperLimit', DefaultUpperLimit);
              formik.setFieldValue('lowerLimit', DefaultLowerLimit);
            }
            if (e.target.value === EarningsType.unbanded) {
              formik.setFieldValue('upperLimit', UnbandedUpperLimit);
            }
          }}
          error={!!formik.errors.customThreshold && formik.touched.customThreshold}
          helperText={(formik.touched.customThreshold && formik.errors.customThreshold) as string}
        />

        <Box sx={{ display: 'flex', gap: spacing.gap20 }}>
          <TextfieldComponent
            name="lowerLimit"
            label={polyglot.t('PensionModule.lowerLimit')}
            value={formik.values.lowerLimit}
            onChange={(e) => {
              const value =
                e.target.value === '' ? '' : !Number.isNaN(e.target.value) ? Number(e.target.value) : undefined;
              if (value !== '' && !value) return;
              formik.setFieldValue('lowerLimit', value);
              if (value !== DefaultUpperLimit) {
                formik.setFieldValue('customThreshold', EarningsType.custom);
              }
            }}
            error={formik.touched.lowerLimit && !!formik.errors.lowerLimit}
            helperText={(formik.touched.lowerLimit && formik.errors.lowerLimit) as string}
            InputProps={{
              startAdornment: <InputAdornment position="start">£</InputAdornment>,
            }}
          />
          {formik.values.customThreshold !== EarningsType.unbanded && (
            <TextfieldComponent
              name="upperLimit"
              label={polyglot.t('PensionModule.upperLimit')}
              value={formik.values.upperLimit}
              onChange={(e) => {
                const value =
                  e.target.value === '' ? '' : !Number.isNaN(e.target.value) ? Number(e.target.value) : undefined;
                if (value !== '' && !value) return;
                formik.setFieldValue('upperLimit', value);
                if (value !== DefaultLowerLimit) {
                  formik.setFieldValue('customThreshold', EarningsType.custom);
                }
              }}
              error={formik.touched.upperLimit && !!formik.errors.upperLimit}
              helperText={(formik.touched.upperLimit && formik.errors.upperLimit) as string}
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
              }}
            />
          )}
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          {workerGroup && (
            <>
              <LoaderButton
                name={polyglot.t('General.delete')}
                disabled={loading}
                loading={isDeleteLoading}
                sizeVariant="medium"
                colorVariant="secondary"
                color="error"
                type="button"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                fullWidth
              />
              <NotificationModal
                isOpen={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                takeAction={async () => {
                  await deleteWorkerGroup();
                  setAnchorEl(null);
                }}
                message={polyglot.t('PensionModule.deleteWorkerGroup')}
                callToAction={polyglot.t('General.yes')}
              />
            </>
          )}
          <LoaderButton
            name={workerGroup ? polyglot.t('General.save') : polyglot.t('General.create')}
            type="button"
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
            onClick={(event) => {
              setAnchorElSave(event.currentTarget);
            }}
            disabled={isDeleteLoading}
          />

          <NotificationModal
            isOpen={Boolean(anchorElSave)}
            onClose={() => setAnchorElSave(null)}
            anchorEl={anchorElSave}
            takeAction={formik.handleSubmit}
            message={
              workerGroup
                ? polyglot.t('PensionModule.updateAllEnrolledEmployees')
                : polyglot.t('PensionModule.createNewWorkerGroup')
            }
            callToAction={polyglot.t('General.yes')}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
