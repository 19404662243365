import { PAYMENT_COMPANY_OVERVIEW_ROUTE, PAYMENT_TEAM_OVERVIEW_ROUTE } from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const MONEY_PAYMENTS_OVERVIEW_ROUTES: RoleRoutes = {
  company: PAYMENT_COMPANY_OVERVIEW_ROUTE,
  // me: PAYMENT_ME_OVERVIEW_ROUTE,
  team: PAYMENT_TEAM_OVERVIEW_ROUTE,
};

export const MONEY_PAYMENTS_SCOPES: RoleScopes = {
  company: ['payments:all'] as Scope[],
  team: ['payments:manager'],
  // me: ['payments'],
};
