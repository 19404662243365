import React, { Suspense } from 'react';

import { Box, Grid } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { PaymentsEndpoints } from '@v2/feature/payments/payments.api';
import { getPaymentStatus } from '@v2/feature/payments/payments.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { formatCurrency } from '@v2/util/currency-format.util';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly transactionId: number;
}

export const PaymentsGroupDrawer = ({ isOpen, setIsOpen, transactionId }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <PaymentsGroupDrawerContent transactionId={transactionId} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly transactionId: number;
}

const PaymentsGroupDrawerContent = ({ transactionId }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const { data: payments } = useApiClient(PaymentsEndpoints.getPaymentsByTransactionId(transactionId));

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Payments</Typography>

      {payments && (
        <Grid container spacing="8px">
          <Grid item xs={3}>
            <Typography variant="captionSmall" color="Grey">
              Type
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="captionSmall" color="Grey">
              Reference
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="captionSmall" color="Grey">
              Status
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="captionSmall" color="Grey">
              Amount
            </Typography>
          </Grid>

          {payments.map((payment) => (
            <>
              {/*{payment.userId ? (*/}
              {/*  <Box>*/}
              {/*    <UserCell userId={payment.userId} nameVariant="caption" />*/}
              {/*  </Box>*/}
              {/*) : (*/}
              {/*  <Typography variant="caption">HMRC</Typography>*/}
              {/*)}*/}

              <Grid item xs={3}>
                <Typography variant="caption">{payment.type}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" sx={{ textOverflow: 'ellipsis' }}>
                  {payment.reference}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {getPaymentStatus(polyglot, payment, themeFonts.caption)}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption">{formatCurrency(payment.amount, {}, payment.currency)}</Typography>
              </Grid>
            </>
          ))}

          <>
            <Grid item xs={10} />

            <Grid item xs={2}>
              <Typography variant="title4">
                {formatCurrency(
                  payments.reduce((total, p) => total + p.amount, 0),
                  {},
                  payments[0].currency
                )}
              </Typography>
            </Grid>
          </>
        </Grid>
      )}
    </Box>
  );
};
