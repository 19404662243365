import { useCallback, useContext, useEffect, useState } from 'react';

import { Button, IconButton, Stack, Typography } from '@mui/material';
import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ReactComponent as PersonalIcon } from '@/images/onboarding-step-icons/PersonalBig.svg';
import { ReactComponent as EmploymentIcon } from '@/images/onboarding-step-icons/ProfiBig.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ONBOARDING_NEW_USER_ROUTE, SETTINGS_ONBOARDING_FLOWS_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { Scope } from '@/models';
import {
  ActionSelectionGroupComponent,
  ActionSelectionItem,
  ActionSelectionItemList,
} from '@/v2/components/action-selection-group.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { EditableTitle } from '@/v2/components/forms/editable-title.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { AppsOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/apps/apps.component';
import { BenefitsOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/benefits.component';
import { ContractOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/contracts/contract.component';
import { DevicesOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/devices/devices.component';
import { DocumentsOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/documents/documents.component';
import { IDVerifyOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.component';
import { PayrollOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/payroll/payroll.component';
import { TasksOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/tasks/tasks.component';
import { TrainingOnboardingItem } from '@/v2/feature/onboarding/onboarding-items/training.component';
import { OnboardingTemplateEditState } from '@/v2/feature/onboarding/onboarding.util';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

export const iconSize = { width: 14, height: 14 } as const;

export const OnboardingTemplateEdit = () => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const params = useParams<{ templateId: string }>();
  const mode = useLocation<OnboardingTemplateEditState>().state?.mode;
  const [template, setTemplate] = useState<OnboardingTemplate>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [drawerView, setDrawerView] = useState<JSX.Element>();
  const [renameHintDismissed, setRenameHintDismissed] = useState(false);

  const loadTemplate = useCallback(async () => {
    const id = Number(params.templateId);
    if (!Number.isInteger(id)) {
      throw new Error(`Invalid template id: ${params.templateId}`);
    }
    const templates = await OnboardingAPI.getOnboardingTemplates();
    const template = templates.find((t) => t.templateId === id);
    if (!template) {
      routerHistory.push(SETTINGS_ONBOARDING_FLOWS_ROUTE);
      return;
    }
    setTemplate(template);
  }, [params.templateId, routerHistory]);

  useEffect(() => {
    loadTemplate();
  }, [loadTemplate]);

  const closeTemplateEditing = useCallback(() => routerHistory.goBack(), [routerHistory]);

  useEscapeKey(closeTemplateEditing);

  const checkOnboardingItemScope = useCallback(
    (scopes: readonly Scope[]) => {
      return checkScopes(currentUser, scopes);
    },
    [currentUser]
  );

  if (!template) {
    return (
      <RootStyle>
        <LoadingSpinner />
      </RootStyle>
    );
  }

  const [
    contractsItem_step1,
    contractsItem_step2,
    contractsItem_step3,
    contractsItem_step4,
    contractsItem_step5,
    tasksItem,
    documentsItem,
    idverify,
    devices,
    apps,
    payroll,
    benefits,
    training,
  ] = [
    <ContractOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.contracts, { ...template }))}
      checkOnboardingItemScope={checkOnboardingItemScope}
      step={1}
    />,
    <ContractOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() =>
        setTemplate((template) => template && (delete template?.template.contracts_step2, { ...template }))
      }
      checkOnboardingItemScope={checkOnboardingItemScope}
      step={2}
    />,
    <ContractOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() =>
        setTemplate((template) => template && (delete template?.template.contracts_step3, { ...template }))
      }
      checkOnboardingItemScope={checkOnboardingItemScope}
      step={3}
    />,
    <ContractOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() =>
        setTemplate((template) => template && (delete template?.template.contracts_step4, { ...template }))
      }
      checkOnboardingItemScope={checkOnboardingItemScope}
      step={4}
    />,
    <ContractOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() =>
        setTemplate((template) => template && (delete template?.template.contracts_step5, { ...template }))
      }
      checkOnboardingItemScope={checkOnboardingItemScope}
      step={5}
    />,
    <TasksOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.tasks, { ...template }))}
      checkOnboardingItemScope={checkOnboardingItemScope}
    />,
    <DocumentsOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.documents, { ...template }))}
      checkOnboardingItemScope={checkOnboardingItemScope}
    />,
    <IDVerifyOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.idverify, { ...template }))}
    />,
    <DevicesOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.device, { ...template }))}
    />,
    <AppsOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.apps, { ...template }))}
      checkOnboardingItemScope={checkOnboardingItemScope}
    />,
    <PayrollOnboardingItem
      template={template}
      setDrawerOpen={setDrawerOpen}
      setDrawerView={setDrawerView}
      onDelete={() => setTemplate((template) => template && (delete template?.template.payroll, { ...template }))}
    />,
    <BenefitsOnboardingItem template={template} />,
    <TrainingOnboardingItem template={template} />,
  ];

  const unassignedSteps: JSX.Element[] = [];
  if (!template.template.contracts) {
    unassignedSteps.push(contractsItem_step1);
  }
  if (template.template.contracts && !template.template.contracts_step2) {
    unassignedSteps.push(contractsItem_step2);
  }
  if (template.template.contracts_step2 && !template.template.contracts_step3) {
    unassignedSteps.push(contractsItem_step3);
  }
  if (template.template.contracts_step3 && !template.template.contracts_step4) {
    unassignedSteps.push(contractsItem_step4);
  }
  if (template.template.contracts_step4 && !template.template.contracts_step5) {
    unassignedSteps.push(contractsItem_step5);
  }
  if (!template.template.tasks) {
    unassignedSteps.push(tasksItem);
  }
  if (!template.template.documents) {
    unassignedSteps.push(documentsItem);
  }
  if (!template.template.device) {
    unassignedSteps.push(devices);
  }
  if (!template.template.apps) {
    unassignedSteps.push(apps);
  }
  if (!template.template.payroll) {
    unassignedSteps.push(payroll);
  }
  if (!template.template.idverify) {
    unassignedSteps.push(idverify);
  }
  unassignedSteps.push(benefits, training);

  const assignedSteps: JSX.Element[] = [];
  if (template.template.contracts) {
    assignedSteps.push(contractsItem_step1);
  }
  if (template.template.contracts_step2) {
    assignedSteps.push(contractsItem_step2);
  }
  if (template.template.contracts_step3) {
    assignedSteps.push(contractsItem_step3);
  }
  if (template.template.contracts_step4) {
    assignedSteps.push(contractsItem_step4);
  }
  if (template.template.contracts_step5) {
    assignedSteps.push(contractsItem_step5);
  }

  if (template.template.tasks) {
    assignedSteps.push(tasksItem);
  }
  if (template.template.documents) {
    assignedSteps.push(documentsItem);
  }
  if (template.template.device) {
    assignedSteps.push(devices);
  }
  if (template.template.apps) {
    assignedSteps.push(apps);
  }
  if (template.template.payroll) {
    assignedSteps.push(payroll);
  }
  if (template.template.idverify) {
    assignedSteps.push(idverify);
  }

  return (
    <RootStyle>
      <Stack
        sx={{
          position: 'absolute',
          background: themeColors.white,
          zIndex: 10,
          inset: 0,
          overflow: 'auto',
        }}
      >
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', top: spacing.g20, right: spacing.g20 }}
          onClick={closeTemplateEditing}
        >
          <Close {...iconSize} stroke={themeColors.DarkGrey} />
        </IconButton>

        <Stack sx={{ flexFlow: 'row', pt: spacing.p30, px: spacing.p30, overflow: 'hidden' }}>
          <ActionSelectionGroupComponent
            title={polyglot.t('OnboardingTemplateEdit.addSteps')}
            actions={unassignedSteps}
            emptyActionsMessage={polyglot.t('OnboardingTemplateEdit.addStepsMex')}
          />
          <Stack sx={{ p: spacing.p30, borderRadius: radius.br15, maxWidth: '380px', flex: 1, mr: 'auto' }}>
            <EditableTitle
              variant="title2"
              sx={{ marginTop: '-10px' }}
              value={template.name}
              onChange={(value) => {
                setTemplate({ ...template, name: value });
              }}
              maxLength={50}
              editButtonTooltip={
                mode === 'first-template' && !renameHintDismissed
                  ? {
                      open: true,
                      title: (
                        <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                            {polyglot.t('OnboardingTemplateEdit.rename')}
                          </Typography>
                          <Button
                            onClick={() => setRenameHintDismissed(true)}
                            sx={{ ...themeFonts.caption, color: themeColors.Grey, textTransform: 'none' }}
                          >
                            {polyglot.t('OnboardingTemplateEdit.okay')}
                          </Button>
                        </Stack>
                      ),
                    }
                  : undefined
              }
            />
            <ActionSelectionItemList
              actions={[
                <ActionSelectionItem
                  step={{
                    Icon: PersonalIcon,
                    title: polyglot.t('OnboardingTemplateEdit.personalInfo'),
                    description: polyglot.t('OnboardingTemplateEdit.personalInfoDesc'),
                    required: true,
                  }}
                  sx={{ py: spacing.py20 }}
                />,
                <ActionSelectionItem
                  step={{
                    Icon: EmploymentIcon,
                    title: polyglot.t('OnboardingTemplateEdit.employmentInfo'),
                    description: polyglot.t('OnboardingTemplateEdit.employmentInfoDesc'),
                    required: true,
                  }}
                  sx={{ py: spacing.py20 }}
                />,
                ...assignedSteps,
              ]}
            />
            <ButtonComponent
              fullWidth
              type="button"
              onClick={async () => {
                try {
                  await OnboardingAPI.updateOnboardingTemplate(template);
                  // if this is the first template, switch to adding an employee
                  // otherwise just return to the list of templates
                  if (mode === 'first-template') {
                    routerHistory.push(ONBOARDING_NEW_USER_ROUTE, { templateId: template.templateId });
                  } else {
                    closeTemplateEditing();
                  }
                } catch (error) {
                  showMessage(
                    polyglot.t('OnboardingTemplateEdit.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
                    'error'
                  );
                }
              }}
              colorVariant="primary"
              sizeVariant="large"
              style={{ flexShrink: 0, marginTop: '20px' }}
            >
              {' '}
              {mode === 'first-template' ? polyglot.t('General.continue') : polyglot.t('General.save')}
            </ButtonComponent>
          </Stack>
        </Stack>
        <DrawerModal isOpen={isDrawerOpen} setIsOpen={setDrawerOpen}>
          {drawerView ?? <></>}
        </DrawerModal>
      </Stack>
    </RootStyle>
  );
};
