import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import {
  AccordionComponent,
  AccordionComponentDetails,
  AccordionComponentSummary,
} from '@v2/components/table/accordion.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { FixedSizeList as List } from 'react-window';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { translatePageFilters, translatePageFilterSet } from '@/v2/infrastructure/i18n/translate.util';

export interface FiltersOption {
  [filterName: string]: { label: string; value: string | number | boolean; type?: 'radio' | 'checkbox' }[];
}

const countSelectedFilters = (selectedFilters: string, filtersOptions: { name?: string; filters: FiltersOption }[]) => {
  let count = 0;

  const filterOptionsStrings = filtersOptions.flatMap((fO) => Object.keys(fO.filters));

  const singleFilters = selectedFilters.split('&');
  for (const singleFilter of singleFilters) {
    if (!singleFilter) continue;

    const [filter, values] = singleFilter.split('=');
    if (!filterOptionsStrings.includes(filter)) continue;

    count += values.split(',').length;
  }

  return count;
};

interface FiltersDrawerProps {
  readonly filtersOptions: { name?: string; filters: FiltersOption }[]; // eg: filtersOptions = [{ name: "People", filters: { gender: [ { label: "Male", value: "male" }, { label: "Female", value: "female" }] } }]
  readonly selectedFilters: string; // eg: selectedFilters = "Role-departmentId-Department=267,268&Role-siteId-Site=41,40"
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<string>>;
  readonly fullWidth?: boolean;
  // encoded filters are of type: '<domain>-<fieldName>-<Filter name>' eg: 'info-accountStatus-Account Status'. The name displayed should be AccountStatus
  readonly encodedFilterNames?: boolean;
  readonly onApply?: (selectedFilters: string) => Promise<void> | void;
  readonly name?: string;
  readonly hideClearAll?: boolean;
}

const RenderRow = ({ index, style, data }: { index: number; style: React.CSSProperties; data: any }) => {
  const { filtersSet, filter, localSelectedFilters, handleFilterChange, translatePageFilters, polyglot } = data;
  const filterOption = filtersSet.filters[filter][index];

  return (
    <div style={style} key={index}>
      <AccordionComponentDetails>
        {filterOption.type === 'radio' ? (
          <FormControlLabel
            key="prorata"
            labelPlacement="end"
            value={filterOption.value}
            checked={Boolean(
              localSelectedFilters[filter] && localSelectedFilters[filter].includes(String(filterOption.value))
            )}
            control={<StyledRadio disableRipple />}
            label={
              <Typography
                variant="caption"
                onClick={(e) => {
                  handleFilterChange(filter, String(filterOption.value), 'radio');
                  e.stopPropagation();
                }}
              >
                {filterOption.label}
              </Typography>
            }
            onClick={() => handleFilterChange(filter, String(filterOption.value), 'radio')}
          />
        ) : (
          <CheckboxComponent
            label={translatePageFilters(filterOption, polyglot)}
            name={filterOption.label}
            checked={Boolean(
              localSelectedFilters[filter] && localSelectedFilters[filter].includes(String(filterOption.value))
            )}
            onChange={() => handleFilterChange(filter, String(filterOption.value), 'checkbox')}
          />
        )}
      </AccordionComponentDetails>
    </div>
  );
};

const calculateListHeight = (itemCount: number, itemSize: number, maxHeight: number) => {
  const totalHeight = itemCount * itemSize;
  return totalHeight > maxHeight ? window.innerHeight - maxHeight : totalHeight;
};

export const FiltersDrawer = ({
  filtersOptions,
  selectedFilters,
  setSelectedFilters,
  fullWidth = false,
  encodedFilterNames = false,
  name,
  onApply,
  hideClearAll = false,
}: FiltersDrawerProps) => {
  const { polyglot } = usePolyglot();
  const componentName = name ?? polyglot.t('FiltersDrawer.filters');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const noOfAppliedFilters = useMemo(() => {
    if (!selectedFilters) return 0;

    return countSelectedFilters(selectedFilters, filtersOptions);
    // Count no of appearances of '&' and ','
    // const matches = selectedFilters.match(/[,&]/g);
    //
    // // If no appearances of '&' and ',', if '-' exists in the filterString, 1 filter is selected (eg: "site=12")
    // if (!matches) return selectedFilters.includes('=') ? 1 : 0;
    //
    // // No of filters is sum(appearances of '&' and ',') + 1
    // return matches.length + 1;
  }, [filtersOptions, selectedFilters]);

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto' }}>
      <FiltersSideDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        filtersOptions={filtersOptions}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        encodedFilterNames={encodedFilterNames}
        onApply={onApply}
        name={componentName}
        hideClearAll={hideClearAll}
      />

      <ButtonComponent
        onClick={() => {
          setIsDrawerOpen(true);
        }}
        sizeVariant="filter"
        style={{ textTransform: 'capitalize' }}
        colorVariant={noOfAppliedFilters === 0 ? 'secondary' : 'active'}
        fullWidth={fullWidth}
      >
        {noOfAppliedFilters === 0 ? (
          <Typography variant="caption">{componentName}</Typography>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <Typography variant="caption" color="white">
              {componentName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                minWidth: '16px',
                height: '16px',
                bgcolor: 'white',
              }}
            >
              <Typography variant="caption" color="black" sx={{ mx: '2px' }}>
                {noOfAppliedFilters}
              </Typography>
            </Box>
          </Box>
        )}
      </ButtonComponent>
    </Box>
  );
};

interface FiltersSideDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly filtersOptions: { name?: string; filters: FiltersOption }[];
  readonly selectedFilters: string;
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<string>>;
  readonly encodedFilterNames: boolean;
  readonly onApply?: (selectedFilters: string) => Promise<void> | void;
  readonly name: string;
  readonly hideClearAll: boolean;
}

const FiltersSideDrawer = ({
  isOpen,
  setIsOpen,
  filtersOptions,
  selectedFilters,
  setSelectedFilters,
  encodedFilterNames,
  onApply,
  name,
  hideClearAll,
}: FiltersSideDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <FiltersSideDrawerContent
          filtersOptions={filtersOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setIsOpen={setIsOpen}
          encodedFilterNames={encodedFilterNames}
          onApply={onApply}
          name={name}
          hideClearAll={hideClearAll}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface FiltersSideDrawerContentProps {
  readonly filtersOptions: { name?: string; filters: FiltersOption }[];
  readonly selectedFilters: string;
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<string>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly encodedFilterNames: boolean;
  readonly name: string;
  readonly onApply?: (selectedFilters: string) => Promise<void> | void;
  readonly hideClearAll: boolean;
}

const FiltersSideDrawerContent = ({
  filtersOptions,
  selectedFilters,
  setSelectedFilters,
  setIsOpen,
  encodedFilterNames,
  onApply,
  name,
  hideClearAll,
}: FiltersSideDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [localSelectedFilters, setLocalSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [expandedFilter, setExpandedFilter] = useState<string>('');
  const [filteredFilterOptions, setFilteredFilterOptions] = useState<
    {
      name?: string;
      filters: FiltersOption;
    }[]
  >([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (!searchInput) {
      setFilteredFilterOptions([...filtersOptions]);
    } else {
      const filteredOptions: {
        name?: string;
        filters: FiltersOption;
      }[] = [];
      const searchString = searchInput.toLowerCase();
      for (const setOfFilters of filtersOptions) {
        const filters: FiltersOption = {};
        for (const key of Object.keys(setOfFilters.filters)) {
          if (key.toLowerCase().includes(searchString)) filters[key] = setOfFilters.filters[key];
        }

        if (Object.keys(filters).length > 0) filteredOptions.push({ name: setOfFilters.name, filters });
      }
      setFilteredFilterOptions(filteredOptions);
    }
  }, [searchInput, filtersOptions]);

  const filterStringToObject = useCallback(() => {
    const defaultFilterString: { [id: string]: string[] } = {};
    if (selectedFilters.length > 0) {
      const andString = selectedFilters.split('&');
      if (andString.length > 0) {
        andString.forEach((item) => {
          const [key, values] = item.split('=');
          if (key && values) defaultFilterString[key] = values.split(',');
        });
      }
    }
    setLocalSelectedFilters(defaultFilterString);
  }, [selectedFilters]);

  useEffect(() => {
    filterStringToObject();
  }, [filterStringToObject]);

  const expandFilter = useCallback(
    (filterName: string) => (event: React.SyntheticEvent, newExpanded: boolean): void => {
      setExpandedFilter(newExpanded ? filterName : '');
    },
    []
  );

  const handleFilterChange = useCallback((filter: string, filterValue: string, type?: 'checkbox' | 'radio') => {
    let updatedValues;
    if (!filter) return;

    setLocalSelectedFilters((prevFilters) => {
      const isAlreadyIncluded =
        prevFilters[filter] && prevFilters[filter]?.length && prevFilters[filter].includes(filterValue);

      // Radio case
      if (type === 'radio') {
        if (isAlreadyIncluded) {
          return { ...prevFilters };
        } else {
          updatedValues = [filterValue];
        }
        return { ...prevFilters, [filter]: updatedValues };
      }

      // Checkbox case
      if (isAlreadyIncluded) {
        updatedValues = prevFilters[filter].filter((option) => option !== filterValue);
        if (prevFilters[filter].length <= 1) delete prevFilters[filter];
      } else {
        updatedValues = prevFilters[filter]?.length > 0 ? [...prevFilters[filter], filterValue] : [filterValue];
      }

      return { ...prevFilters, [filter]: updatedValues };
    });
  }, []);

  const applyFiltersSelections = useCallback(
    async (localSelectedFilters: { [key: string]: string[] }) => {
      let newQueryString = '';

      Object.keys(localSelectedFilters).forEach((key, index) => {
        const optionsSelected = localSelectedFilters[key].toString();
        if (optionsSelected.length > 0) {
          if (index === 0) newQueryString += `${key}=${optionsSelected}`;
          else newQueryString += `&${key}=${optionsSelected}`;
        }
      });
      setSelectedFilters(newQueryString);

      if (onApply) onApply(newQueryString);
      setIsOpen(false);
    },
    [setSelectedFilters, onApply, setIsOpen]
  );

  return (
    <Box sx={drawerContentSx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="title2">{name}</Typography>

        {!hideClearAll && Object.keys(localSelectedFilters).length > 0 && (
          <Box sx={{ mt: spacing.m10 }}>
            <ButtonComponent onClick={() => setLocalSelectedFilters({})} sizeVariant="link" colorVariant="text">
              {polyglot.t('FiltersDrawer.clearAll')}
            </ButtonComponent>
          </Box>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(!e.target.value ? '' : e.target.value);
          }}
          style={{
            width: '350px',
            minWidth: '350px',
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g5,
          overflowY: 'auto',
          height: '65vh',
        }}
      >
        {filteredFilterOptions.map((filtersSet, indexSet) => (
          <Box key={indexSet} sx={{ mb: spacing.m10 }}>
            {filtersSet.name && (
              <Typography variant="caption" color="Grey">
                {filtersSet.name}
              </Typography>
            )}

            {Object.keys(filtersSet.filters).map((filter) =>
              filtersSet.filters[filter].length > 0 ? (
                <Box key={filter}>
                  <AccordionComponent expanded={expandedFilter === filter} onChange={expandFilter(filter)}>
                    <AccordionComponentSummary
                      aria-controls={`${filter}-content`}
                      id={`${filter}-header`}
                      sx={{ bgcolor: expandedFilter === filter ? themeColors.Background : themeColors.white }}
                    >
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="caption">
                          {translatePageFilterSet(encodedFilterNames ? filter.split('-').pop() : filter, polyglot)}
                        </Typography>

                        {localSelectedFilters[filter] && localSelectedFilters[filter].length > 0 ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              bgcolor: 'black',
                              borderRadius: '15px',
                              minWidth: '16px',
                              height: '16px',
                            }}
                          >
                            <Typography variant="caption" color="white">
                              {localSelectedFilters[filter].length}
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </AccordionComponentSummary>
                    <List
                      height={calculateListHeight(filtersSet.filters[filter].length, 35, 450)}
                      itemCount={filtersSet.filters[filter].length}
                      itemSize={35}
                      width={'100%'}
                      itemData={{
                        filtersSet,
                        filter,
                        localSelectedFilters,
                        handleFilterChange,
                        translatePageFilters,
                        polyglot,
                      }}
                    >
                      {RenderRow}
                    </List>
                  </AccordionComponent>
                </Box>
              ) : null
            )}
          </Box>
        ))}
      </Box>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          fullWidth
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
          onClick={async () => {
            await applyFiltersSelections(localSelectedFilters);
          }}
        >
          {polyglot.t('FiltersDrawer.apply')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
