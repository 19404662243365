import React, { useContext, useMemo } from 'react';

import { Avatar, Box, Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as CompletedAction } from '@/images/side-bar-icons/CheckCircle.svg';
import { ReactComponent as ActionCardIcon } from '@/images/side-bar-icons/WaitingFull.svg';
import { ButtonColorVariant, ButtonComponent } from '@/v2/components/forms/button.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { styledChipSx } from '@/v2/styles/chip.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface CustomChip {
  text: string;
  type?: 'warning' | 'error' | 'success';
}

type ChipContent = React.ReactNode | CustomChip;

type OffboardingActionCardProps = {
  chips?: ChipContent[];
  iconState?: 'done' | 'pending' | 'todo';
  disabled?: boolean;
  label: string;
  description?: string;
  owner?: number | 'company';
  actionButton?: React.ReactNode;
  requiresAdminAction?: boolean;
  buttonLabel?: string;
  onActionClick?: React.MouseEventHandler<HTMLButtonElement> | null | false;
  showEdit?: boolean;
  sx?: SxProps<Theme>;
  buttonColor?: ButtonColorVariant;
  editBtnColor?: ButtonColorVariant;
};

const getIconForCard = (iconState: 'done' | 'pending' | 'todo', requiresAdminAction: boolean) => {
  if (iconState === 'done')
    return <CompletedAction style={{ height: 16, width: 16, flexShrink: 0, fill: themeColors.green }} />;
  if (iconState === 'pending')
    return <ActionCardIcon style={{ height: 16, width: 16, flexShrink: 0, fill: themeColors.YellowHover }} />;

  if (requiresAdminAction)
    return <ActionCardIcon style={{ height: 16, width: 16, flexShrink: 0, fill: themeColors.red }} />;
  return <ActionCardIcon style={{ height: 16, width: 16, flexShrink: 0 }} />;
};

export const OffboardingActionCard = ({
  chips,
  iconState = 'todo',
  disabled,
  label,
  description,
  owner,
  onActionClick,
  buttonLabel,
  buttonColor = 'primary',
  actionButton,
  requiresAdminAction = false,
  sx,
}: OffboardingActionCardProps) => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);

  return (
    <Stack sx={{ flexFlow: 'row', gap: spacing.g10, py: spacing.p15, alignItems: 'center', ...sx }}>
      {getIconForCard(iconState, requiresAdminAction)}
      <Box sx={{ display: 'flex', gap: spacing.s2 }}>
        <Typography variant="title4" sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </Typography>
        {description && (
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            {description}
          </Typography>
        )}
      </Box>

      <ChipsList chips={chips ?? []} />

      <Stack sx={{ flexFlow: 'row', alignItems: 'center', ml: 'auto', gap: spacing.g20 }}>
        {owner === 'company' && <Avatar src={globalState.user.company.avatar} sx={{ width: 20, height: 20 }} />}
        {typeof owner === 'number' && <UserAvatar userId={owner} size="xxsmall" />}
        {(actionButton || onActionClick) && !disabled && (
          <Stack sx={{ width: '100px', flexShrink: 0 }}>
            {actionButton ??
              (onActionClick && (
                <ButtonComponent onClick={onActionClick} sizeVariant="small" colorVariant={buttonColor} fullWidth>
                  {buttonLabel ?? polyglot.t('OnboardingByUser.start')}
                </ButtonComponent>
              ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const ChipsList = ({ chips }: { chips: ChipContent[] }) => {
  const displayedChips = useMemo(() => chips?.filter(Boolean) ?? [], [chips]);

  return displayedChips.length > 0 ? (
    <Stack sx={{ flexFlow: 'row', flexWrap: 'wrap', gap: spacing.g4 }}>
      {chips.map((chip, index) => (
        <ChipRender key={index} boxKey={index} chip={chip} />
      ))}
    </Stack>
  ) : null;
};

const ChipRender = ({ boxKey, chip }: { boxKey: string | number; chip: ChipContent }) => {
  return (
    <React.Fragment key={boxKey}>
      {typeof chip === 'string' || React.isValidElement(chip) ? (
        <Box key={boxKey} sx={styledChipSx}>
          {chip}
        </Box>
      ) : (
        <Box
          key={boxKey}
          sx={{
            ...styledChipSx,
            color:
              (chip as CustomChip).type === 'error'
                ? themeColors.Red
                : (chip as CustomChip).type === 'warning'
                ? themeColors.Orange
                : (chip as CustomChip).type === 'success'
                ? themeColors.Green
                : themeColors.black,
          }}
        >
          {(chip as CustomChip).text}
        </Box>
      )}
    </React.Fragment>
  );
};
