import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { addMonths } from 'date-fns';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DevicePossessionType, ReturnDeviceReason } from '@/v2/feature/device/device.interface';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatAddress } from '@/v2/util/user-data.util';

const TabFilter = [
  { name: 'Report damage', value: ReturnDeviceReason.Damage },
  { name: 'End contract', value: ReturnDeviceReason.CancelContract },
];

interface OrderActionsDrawerProps {
  readonly isOpen: boolean;
  readonly onClose: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
}

export const OrderActionsDrawer = ({ isOpen, onClose, devicePossession, setIsOpen }: OrderActionsDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <OrderActionsDrawerContent devicePossession={devicePossession} closePage={onClose} />
    </DrawerModal>
  );
};

const OrderActionsDrawerContent = ({
  devicePossession,
  closePage,
}: {
  readonly devicePossession: DevicePossessionDto;
  closePage: () => Promise<void>;
}) => {
  const [showMessage] = useMessage();
  const [filterValue, setFilterValue] = useState<string>(ReturnDeviceReason.Damage);
  const [notes, setNotes] = useState<string>('');
  const [senderAddress, setSenderAddress] = useState<string>('');
  const contractEndDate =
    devicePossession?.startDate && devicePossession?.device?.contractLength
      ? addMonths(new Date(devicePossession?.startDate), devicePossession?.device?.contractLength)
      : new Date();

  const [state, dispatch] = useContext(GlobalContext);
  const initiateDeviceReturn = async () => {
    let isReturnInitiated = false;
    try {
      await DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        filterValue as ReturnDeviceReason,
        senderAddress,
        'Zelt HQ', // Return to stock
        null,
        notes
      );
      if (
        devicePossession.possessionType === DevicePossessionType.ZeltStorage &&
        (filterValue as ReturnDeviceReason) === ReturnDeviceReason.CancelContract
      ) {
        showMessage('Contract cancellation successfully initiated.', 'info');
      } else {
        showMessage('Successfully requested a return', 'success');
      }
      isReturnInitiated = true;

      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      closePage();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not initiate return device flow. ${nestErrorMessage(error)}`, 'error');
      }
    }
  };

  const fetchUserAddress = useCallback(
    async (assignedUserId: number): Promise<void> => {
      try {
        const userAddress = await UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Order actions</Typography>

      <TabFilterButtons
        filters={TabFilter}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
        onFilterChange={({ filterValue }) => {
          setFilterValue(filterValue);
        }}
      />

      {filterValue === ReturnDeviceReason.Damage && (
        <Box>
          <Typography variant="caption">
            If the device has been damaged please provide details below and we will contact you to arrange a
            replacement. Note that additional charges may apply.{' '}
          </Typography>

          <Box sx={{ mt: spacing.m20 }}>
            <TextfieldComponent
              multiline
              name="notes"
              label=""
              placeholder="Please provide details"
              value={notes}
              type="text"
              onChange={(e) => setNotes(e.target.value)}
              endAdornment="none"
            />
          </Box>
        </Box>
      )}

      {filterValue === ReturnDeviceReason.CancelContract && (
        <Typography variant="caption">
          Your minimum period ends on {getDateString(contractEndDate)}. Note that Cancellation Charge may apply, as
          defined in the{' '}
          <a
            style={{
              ...themeFonts.caption,
              color: themeColors.DarkGrey,
              textTransform: 'inherit',
              textDecorationColor: themeColors.DarkGrey,
            }}
            target="_blank"
            rel="noreferrer"
            href="https://zelt.app/legal/daas-terms/"
          >
            Devices Terms.
          </a>
        </Typography>
      )}

      {[ReturnDeviceReason.Damage, ReturnDeviceReason.CancelContract].includes(filterValue as ReturnDeviceReason) && (
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            onClick={async () => initiateDeviceReturn()}
          >
            {filterValue === ReturnDeviceReason.Damage ? 'Submit' : 'End contract and arrange return'}
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};
