import { ColumnDef, Row } from '@tanstack/react-table';

import { BasicTable } from '@/v2/components/table/basic-table.component';
// import { BasicServerTable } from '@/v2/components/table/server-side-table.component';

interface AppDetailsTableProps<T> {
  // readonly appDetails?: InstalledAppDto;
  readonly column: ColumnDef<T, T>[];
  readonly row: readonly T[];
  readonly loading: boolean;
  readonly hidePagination?: boolean;
  readonly paginationReset?: boolean;
  readonly handleRowClicked?: (row: Row<T>) => void;
}
export const AppDetailsTable = <T,>({
  column,
  row,
  loading,
  hidePagination = false,
  paginationReset = false,
  handleRowClicked = () => {},
}: AppDetailsTableProps<T>): JSX.Element => {
  return (
    <BasicTable
      rowData={[...row]}
      columnData={column}
      loading={loading}
      hidePagination={hidePagination}
      paginationReset={paginationReset}
      rowClick={handleRowClicked}
    />
  );
  // STOP using server table because we are not paginating on server side, but instead sending all app user details in one call, and paginating only on frontend
  // if (appDetails && appDetails?.internalExternalUserSplit && pagination && setPagination && sorting && setSorting) {
  //   return (
  //     <BasicServerTable
  //       rowData={[...row]}
  //       // @ts-ignore
  //       columnData={column}
  //       sorting={sorting}
  //       setSorting={setSorting}
  //       pagination={pagination}
  //       setPagination={setPagination}
  //       totalPages={totalPages}
  //
  //       loading={loading}
  //     />
  //   );
  // } else {
};
