import Polyglot from 'node-polyglot';

import { OptionObject } from '@/v2/components/forms/select.component';
import { MissingField } from '@/v2/feature/user/user.interface';

export interface UserProfilePageProps {
  readonly userId: number;
  readonly onUpdate: () => Promise<void>;
  readonly missingFields?: MissingField[] | null;
}

export type LifecycleFormState = 'Terminate' | 'Rehire' | 'Leave';

export enum ContractorTypes {
  CONTRACTOR = 'Contractor',
  EOR = 'EOR',
}

export const getContractTypeOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    { value: 'Full-time', label: polyglot.t('getContractTypeOptions.fullTime') },
    { value: 'Part-time', label: polyglot.t('getContractTypeOptions.partTime') },
    { value: 'Shifts', label: polyglot.t('getContractTypeOptions.shifts') },
  ];
};
export const getEmploymentTypeOptions = (polyglot: Polyglot): readonly OptionObject[] => {
  return [
    { value: 'Employee', label: polyglot.t('getEmploymentTypeOptions.employee') },
    { value: ContractorTypes.CONTRACTOR, label: polyglot.t('getEmploymentTypeOptions.contractor') },
    { value: 'Consultant', label: polyglot.t('getEmploymentTypeOptions.consultant') },
    { value: ContractorTypes.EOR, label: polyglot.t('getEmploymentTypeOptions.eor') },
  ];
};

export const getUnitTypeOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    { value: 'Months', label: polyglot.t('getUnitTypeOptions.months') },
    { value: 'Weeks', label: polyglot.t('getUnitTypeOptions.weeks') },
    { value: 'Days', label: polyglot.t('getUnitTypeOptions.days') },
  ];
};

export const EMPTY_CHECK = {
  requestId: '0',
  statusCode: 0,
  status: 'unknown',
  packageId: '',
  packageName: '',
  packageDescription: '',
  checks: [],
};
