import { Box } from '@mui/material';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { UpdateAnswer } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/review-answer-submission.page';
import { GrowthEntry, GrowthUpsertAnswer } from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { spacing } from '@/v2/styles/spacing.styles';

export const OpenEndedQuestion = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
}: {
  question: SurveyQuestion;
  answerArray: GrowthUpsertAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: GrowthEntry) => void;
  entry: GrowthEntry | undefined;
}) => {
  const currentAnswer = answerArray?.find((ans) => ans.questionId === question.id)?.comment || '';

  if (!question || !entry) return <></>;

  const handleInputChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: null, comment: value }, entry);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <RichTextField
        key={question.id}
        value={currentAnswer}
        onChange={(value: string) => {
          handleInputChange(value);
        }}
      />
    </Box>
  );
};
