import React from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ParentalControlsContentFilterOptions } from '@v2/feature/device/device.dto';
import { spacing } from '@v2/styles/spacing.styles';

export const SafariBrowsingPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  configurationPayloadOption,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  configurationPayloadOption?: ParentalControlsContentFilterOptions | null;
}) => {
  const whiteListAllowed =
    (configurationPayloadOption?.filterWhitelist && configurationPayloadOption?.filterWhitelist.length > 0) ?? false;
  const blackListAllowed =
    (configurationPayloadOption?.filterBlacklist && configurationPayloadOption?.filterBlacklist.length > 0) ?? false;
  const renderList = (title: string, items: string[], isAllowed: boolean) => (
    <Box
      sx={{
        padding: '16px',
        gap: '16px',
        borderRadius: '8px',
        border: '1px solid #F1EEEA',
        backgroundColor: isAllowed ? '#FFFFFF' : '#F8F8F8',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="title4">{title}</Typography>
      {isAllowed ? (
        items.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography variant="caption">{item}</Typography>
          </Box>
        ))
      ) : (
        <Typography variant="caption">{'No websites'}</Typography>
      )}
    </Box>
  );

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
      <Box>
        <Typography variant="title2">{'Safari browsing policy'}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}>
          {renderList('Allowed websites', configurationPayloadOption?.filterWhitelist || [], whiteListAllowed)}
          {renderList('Restricted websites', configurationPayloadOption?.filterBlacklist || [], blackListAllowed)}
        </Box>
      </Box>
    </DrawerModal>
  );
};
