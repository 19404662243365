import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE,
  REVIEWS_COMPANY_ONGOING_ROUTE,
  REVIEWS_COMPANY_ONGOING_SETUP_ROUTE,
  REVIEWS_COMPANY_ROUTE,
} from '@/lib/routes';
import { ReviewCycleCreationCompanyRouter } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cylce-creation-router/company/review-cycle-creation-company.router';
import { ReviewCycleDetailCompanyRouter } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-router/company/review-cycle-detail-company.router';
import { ReviewCycleOngoingListingPage } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-ongoing/review-cycle-ongoing-listing/review-cycle-ongoing-listing.page';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';

export const ReviewsCompanyRouter = (): JSX.Element => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);

  return (
    <Switch>
      <RouteScopesControl
        scopes={['reviews:all']}
        path={REVIEWS_COMPANY_ONGOING_SETUP_ROUTE}
        component={ReviewCycleCreationCompanyRouter}
      />
      <RouteScopesControl
        scopes={['reviews:all']}
        path={REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE}
        component={ReviewCycleDetailCompanyRouter}
      />

      <RouteScopesControl scopes={['reviews:all']} context={context} path={REVIEWS_COMPANY_ONGOING_ROUTE} exact>
        <ReviewCycleOngoingListingPage reach={ReachType.Company} />
      </RouteScopesControl>

      <Redirect from={REVIEWS_COMPANY_ROUTE} to={REVIEWS_COMPANY_ONGOING_ROUTE} />
    </Switch>
  );
};
