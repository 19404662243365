import { Box, Typography } from '@mui/material';

import { definitionListSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const MissingInformationLabel = () => {
  const { polyglot } = usePolyglot();

  return (
    <Box component="dl" sx={definitionListSx}>
      <Typography component="dt" sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
        {polyglot.t('MissingInformationLabel.text')}
      </Typography>
    </Box>
  );
};
