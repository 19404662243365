import { UserAPI } from '@/v2/feature/user/user.api';
import { nestErrorMessage } from '@/lib/errors';

export enum AccountStatus {
  Created = 'Created',
  Invited = 'Invited',
  Active = 'Active',
  Deactivated = 'Deactivated',
  InvitedToOnboard = 'Invited to Onboard',
}

type InviteModes = 'invite' | 'onboard';

export async function inviteUser(userId: number, showMessage: Function, mode: InviteModes = 'invite'): Promise<void> {
  try {
    const result = await UserAPI.inviteUser(userId, mode);
    if (result.success && result.emailAddresses)
      showMessage(`Invitation was sent to ${result.emailAddresses.join(', ')}`, 'success');
    if (result.success) showMessage(`Invitation sent. ${result.message}`, 'success');
    else showMessage(result.message, 'error');
  } catch (e) {
    showMessage(`Failed to send email. ${nestErrorMessage(e)}`, 'error');
  }
}
