import { useEffect, useState } from 'react';

import { Alert, Box, Stack, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { Link, generatePath, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { LOGIN_ROUTE, REGISTER_ORGANIZATION } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { InlineLink } from '@/v2/components/inline-link.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const pipeDriveInfoMessage = 'In order to install Pipedrive app, you need to signup / login into Zelt application.';

interface FormProps {
  readonly workEmail: string;
}

const validationSchema = yup.object({
  workEmail: yup.string().email('Enter a valid email address').required('Email is required'),
});

export const AuthSignupPage = (): JSX.Element => {
  const routerHistory = useHistory();
  const searchParams = useLocation().search;

  const [infoMessage, setInfoMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(searchParams);
    if (queryParams.get('info') === 'pipedrive-install') {
      setInfoMessage(pipeDriveInfoMessage);
    }
  }, [searchParams]);

  const formik = useFormik<FormProps>({
    initialValues: {
      workEmail: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (formData: FormProps) => {
      setIsLoading(true);

      routerHistory.push(
        generatePath(REGISTER_ORGANIZATION, {
          email: formData.workEmail,
        })
      );
      setIsLoading(false);
      setError('');
    },
  });
  return (
    <AuthLayout title="Get started with Zelt" submit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        {infoMessage && <Alert severity="info">{infoMessage}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        <Stack spacing={spacing.m25} sx={{ mt: 1 }}>
          <TextfieldComponent
            label="Work email"
            name="workEmail"
            value={formik.values.workEmail}
            onChange={formik.handleChange}
            error={formik.touched.workEmail && Boolean(formik.errors.workEmail)}
            helperText={formik.touched.workEmail && formik.errors.workEmail}
            fullWidth
            size="small"
            endAdornment="none"
            autoFocus
          />
          <Box sx={{ mt: spacing.m30, width: { xs: '50%', sm: '100%', md: '100%', lg: '100%' } }}>
            <LoaderButton
              type="submit"
              name="Next"
              fullWidth
              loading={isLoading}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>

          <Typography sx={{ ...themeFonts.caption }}>
            Already have an account? &nbsp;
            <Link to={LOGIN_ROUTE}>Login</Link>
          </Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mt: spacing.m30 }}>
            {'By continuing, you are agreeing to our '}
            <InlineLink href="https://zelt.app/legal/privacy-policy/" title="Privacy Policy" openInNewWindow />
            {' and '}
            <InlineLink href="https://zelt.app/legal/terms/" title="Terms and Conditions" openInNewWindow />
          </Typography>
        </Stack>
      </FormikProvider>
    </AuthLayout>
  );
};
