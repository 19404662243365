import { useEffect, useState } from 'react';

import useMessage from '@/hooks/notification.hook';
import { ConfirmModal } from '@/v2/components/confirm-modal.component';
import { useUserProfileConfig } from '@/v2/feature/user/features/user-profile/user-profile.hook';
import { UserProfileActionAPI } from '@/v2/feature/user/features/user-profile-action/user-profile-action.api';
import { UserProfileActionModes } from '@/v2/feature/user/features/user-profile-action/user-profile-action.interface';

export interface Props {
  userId: number;
  deactivationDate: Date | null;
  actionMode: UserProfileActionModes;
  setActionMode: (value: UserProfileActionModes) => void;
  refresh: () => void;
}

export const UserProfileActionModal = ({
  userId,
  deactivationDate,
  actionMode,
  setActionMode,
  refresh,
}: Props): JSX.Element => {
  const [showMessage] = useMessage();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [altAction, setAltAction] = useState<string>('');
  const [confirmModalTitle, setConfirmModalTitle] = useState<string>('');
  const { userOverviewConfig } = useUserProfileConfig(userId);

  useEffect(() => {
    switch (actionMode) {
      case 'cancelDeactivate':
        setIsConfirmModalOpen(true);
        setAltAction('cancel');
        setConfirmModalTitle('Cancel Deactivation');
        break;
      case 'reactivate':
        setIsConfirmModalOpen(true);
        setAltAction('reactivate');
        setConfirmModalTitle('Reactivate User');
        break;
    }
  }, [actionMode]);

  const triggerCancelDeactivate = async () => {
    if (deactivationDate) await UserProfileActionAPI.cancelDeactivation(userId);
    showMessage('Successfully cancelled deactivation for user.', 'success');
    refresh();
    setIsConfirmModalOpen(false);
  };

  const triggerReactivate = async () => {
    if (deactivationDate || userOverviewConfig.accountStatus === 'Deactivated') {
      await UserProfileActionAPI.reactivateUser(userId);
      showMessage('Successfully re-activated user.', 'success');
      refresh();
    } else {
      showMessage(
        'Failed to re-activate user as they are neither in a deactivated state nor do they have a deactivation date',
        'warning'
      );
    }
    setIsConfirmModalOpen(false);
  };

  const confirmModalTakeAction = () => {
    if (altAction === 'cancel') triggerCancelDeactivate();
    else if (altAction === 'reactivate') triggerReactivate();
  };

  const confirmModalMessage =
    'This action will revert employee’s status to Active and they will be able to access their account as usual. Are you sure?';

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setActionMode(undefined);
          setIsConfirmModalOpen(false);
        }}
        takeAction={confirmModalTakeAction}
        title={confirmModalTitle}
        callToAction={confirmModalTitle}
        callToActionButtonType="primary"
        message={confirmModalMessage}
        type="info"
      />
    </>
  );
};
