import { IconButton, Stack } from '@mui/material';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

type PayrollSettingSectionHeaderProps = {
  children: string;
  editButtonHint?: string;
  showEditButton?: boolean;
  onEditClick?: () => void;
};

export const PayrollSettingSectionHeader = ({
  children,
  editButtonHint,
  showEditButton,
  onEditClick,
}: PayrollSettingSectionHeaderProps) => {
  return (
    <Stack sx={{ flexFlow: 'row', alignItems: 'center', mb: spacing.m10, gap: spacing.g10 }}>
      <Typography variant="title2">{children}</Typography>
      {showEditButton && (
        <IconButton title={editButtonHint} sx={{ ...tableIconButtonSx, ml: 'auto' }} onClick={() => onEditClick?.()}>
          <EditIcon {...actionIconSize} />
        </IconButton>
      )}
    </Stack>
  );
};
