import React, { useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';

import { UserLifecycleSchemaForImport } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { drawerContentSx, titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserImportLifecycleValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

interface Props {
  readonly initialValues: UserImportLifecycleValues;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: UserImportLifecycleValues) => void;
}

export const UserImportLifecycleForm = ({
  initialValues,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<UserImportLifecycleValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserLifecycleSchemaForImport,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (usedForDataImport) {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage(polyglot.t('UserImportLifecycleForm.errorMessages.save'), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="h1" sx={titleSx}>
          {polyglot.t('UserImportLifecycleForm.edit')}
        </Typography>
        <FormControl size="small" fullWidth>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.startDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('startDate', value);
              } else {
                formik.setFieldValue('startDate', null);
              }
            }}
            name="startDate"
            label={polyglot.t('UserImportLifecycleForm.startDate')}
            error={!!formik.errors.startDate && formik.touched.startDate}
            helperText={formik.errors.startDate && formik.touched.startDate}
          />
        </FormControl>

        <FormControl size="small" fullWidth>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.leaveDate ?? null}
            returnNullOnChange={true}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('leaveDate', value);
              } else if (value === '' || !value) {
                formik.setFieldValue('leaveDate', null);
              }
            }}
            name="leaveDate"
            label={polyglot.t('UserImportLifecycleForm.leaveDate')}
            error={!!formik.errors.leaveDate && formik.touched.leaveDate}
            helperText={formik.errors.leaveDate && formik.touched.leaveDate}
          />
        </FormControl>

        <TextfieldComponent
          name="changeReason"
          label={polyglot.t('UserImportLifecycleForm.changeReason')}
          value={formik.values.changeReason}
          onChange={formik.handleChange}
          error={formik.touched.changeReason && !!formik.errors.changeReason}
          helperText={(formik.touched.changeReason && formik.errors.changeReason) ?? ' '}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={onClose} fullWidth>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
