import { useEffect } from 'react';

import { CreateCustomDepWizard } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CreateCustomDepWizardPage = () => {
  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Upload custom dep token');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <ContentWrapper noHeader={true} border={false} sx={{ paddingTop: spacing.p30, ...spacing.px40 }}>
        <CreateCustomDepWizard />
      </ContentWrapper>
    </RootStyle>
  );
};
