import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { AbsenceAdjustmentImportDrawer } from '@v2/feature/entity-import/wizard/components/absence-adjustments/absence-adjustment-import-drawer.component';
import { ImportedAbsenceAdjustmentTableCell } from '@v2/feature/entity-import/wizard/components/absence-adjustments/imported-absence-adjustment-table-cell.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { ValidationError } from '@v2/infrastructure/api-error/api-error.interface';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { AbsenceEndpoints } from '@/v2/feature/absence/absence.api';
import {
  AbsenceAdjustmentImportDto,
  AbsenceAdjustmentImportResultDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import { validateAbsenceAdjustmentsImport } from '@/v2/feature/entity-import/wizard/entity-import-validator.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  result?: AbsenceAdjustmentImportResultDto;
  onUpdateAndRevalidate: (updatedRecords: EntityImportValidationResultDto<AbsenceAdjustmentImportDto>) => void;
  completeImport: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const AbsenceAdjustmentsImportResultTable = ({
  result,
  completeImport,
  onUpdateAndRevalidate,
  loading,
  setLoading,
}: Props) => {
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });

  const [showMessage] = useMessage();
  const [rowBeingEdited, setRowBeingEdited] = useState<AbsenceAdjustmentImportDto | null>(null);
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const [isOpen, setIsOpen] = useState(false);

  const { rowErrors, rowData } = useMemo((): {
    rowData: AbsenceAdjustmentImportDto[];
    rowErrors: ValidationError[][];
  } => {
    if (!result?.errors.some((e) => e.entity)) return { rowErrors: [], rowData: [] };
    // if (!result?.errors.some((e) => e.errors.length > 0)) return { rowErrors: [], rowData: [] };

    const rowErrorsInit = result.errors.map((record) => record.errors);

    const rowDataInit = result.errors.flatMap((record) => record.entity);

    const rowsWithDataAndErrors: { data: AbsenceAdjustmentImportDto; error: ValidationError[] }[] = [];
    for (let i = 0; i < Math.max(rowErrorsInit.length, rowDataInit.length); i++) {
      rowsWithDataAndErrors.push({ data: rowDataInit[i], error: rowErrorsInit[i] ?? [] });
    }

    rowsWithDataAndErrors.sort((a, b) => b.error.length - a.error.length);

    const rowErrors = rowsWithDataAndErrors.map((a) => a.error);
    const rowData = rowsWithDataAndErrors.map((a) => a.data);
    return { rowData, rowErrors };
  }, [result]);

  const notifyPendingErrors = useCallback(() => {
    showMessage('We found some issues in your formatting. Please correct the mistakes and try again', 'info');
  }, [showMessage]);

  const notifyDataValid = useCallback(() => {
    showMessage('Your imported data looks good! Continue to finish import.', 'success');
  }, [showMessage]);

  const hasErrors = useMemo(() => {
    return rowErrors.some((errArr) => errArr.length > 0);
  }, [rowErrors]);

  useEffect(() => {
    if (hasErrors) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, hasErrors]);

  const getErrorForRow = useCallback(
    (index: number) => {
      return rowErrors ? rowErrors[index] : [];
    },
    [rowErrors]
  );

  const columns = useMemo<ColumnDef<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>[]>(() => {
    return [
      {
        header: () => 'First name',
        accessorFn: (row) => row,
        id: 'firstName',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="firstName"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Last name',
        accessorFn: (row) => row,
        id: 'lastName',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="lastName"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Work Email',
        accessorFn: (row) => row,
        id: 'workEmail',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="workEmail"
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
      {
        header: () => 'Policy name',
        accessorFn: (row) => row,
        id: 'policyName',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="policyName"
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
      {
        header: () => 'Effective year',
        accessorFn: (row) => row,
        id: 'effectiveYear',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="effectiveYear"
            />
          );
        },
      },
      {
        header: () => 'Adjustment',
        accessorFn: (row) => row,
        id: 'adjustment',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="adjustment"
            />
          );
        },
      },
      {
        header: () => 'Unit',
        accessorFn: (row) => row,
        id: 'unit',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="unit"
            />
          );
        },
      },
      {
        header: () => 'Note',
        accessorFn: (row) => row,
        id: 'note',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="note"
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Validation',
        accessorFn: (row) => row,
        id: 'validation',
        enableSorting: false,
        cell: (info: CellContext<AbsenceAdjustmentImportDto, AbsenceAdjustmentImportDto>) => {
          return (
            <ImportedAbsenceAdjustmentTableCell
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey="validation"
              statusColumn={(rowErrors && rowErrors[info.row.index]?.length) ?? 0}
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
    ];
  }, [getErrorForRow, rowErrors]);

  const absenceAdjustmentsMappingForValues = useCallback(
    (values: AbsenceAdjustmentImportDto, allValues: AbsenceAdjustmentImportDto[]) => {
      const foundIndex = allValues.findIndex((record) => record.id === values.id);

      return { mappedValues: values, foundIndex };
    },
    []
  );

  const handleRowClick = (row: Row<AbsenceAdjustmentImportDto>) => {
    if (rowData) setRowBeingEdited(absenceAdjustmentsMappingForValues(row.original, rowData).mappedValues);
    setIsOpen(true);
  };

  const currentFormForEditing = useMemo(() => {
    const updateDatasetWithRecord = (
      values: AbsenceAdjustmentImportDto
    ): { updatedRecords: AbsenceAdjustmentImportDto[]; foundIndex: number } => {
      if (!rowData) return { updatedRecords: [], foundIndex: -1 };
      const updatedRow = { ...values };
      // required as CSV headers are not in line with DB columns
      const { mappedValues, foundIndex } = absenceAdjustmentsMappingForValues(updatedRow, rowData);
      if (foundIndex < 0) return { updatedRecords: rowData, foundIndex: -1 };
      const updatedRecords = rowData ? [...rowData] : [];
      if (foundIndex >= 0) updatedRecords[foundIndex] = mappedValues;
      return { updatedRecords, foundIndex };
    };

    const importHandler = async (values: AbsenceAdjustmentImportDto) => {
      setLoading(true);
      const { updatedRecords, foundIndex } = updateDatasetWithRecord(values);
      const validationResult = await validateAbsenceAdjustmentsImport(
        updatedRecords,
        allUsersIncludingTerminated,
        absencePolicies ?? [],
        foundIndex
      );
      onUpdateAndRevalidate(validationResult);
      setIsOpen(false);
      setLoading(false);
    };

    return (
      <AbsenceAdjustmentImportDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
          setRowBeingEdited(null);
        }}
        entity={rowBeingEdited}
        absencePolicies={absencePolicies ?? []}
        importHandler={async (values: AbsenceAdjustmentImportDto) => {
          await importHandler(values);
        }}
      />
    );
  }, [
    isOpen,
    rowBeingEdited,
    rowData,
    absenceAdjustmentsMappingForValues,
    setLoading,
    allUsersIncludingTerminated,
    absencePolicies,
    onUpdateAndRevalidate,
  ]);

  const totalPendingErrors = useMemo(() => {
    return rowErrors?.flat()?.length ?? 0;
  }, [rowErrors]);

  return (
    <>
      <Box
        sx={{
          pt: spacing.p25,
          flex: 1,
          overflow: 'auto',
          borderTop: '1px solid',
          borderColor: themeColors.GreyLight,
        }}
      >
        <BasicTable rowData={rowData ?? []} columnData={columns} rowClick={handleRowClick} loading={loading} />
      </Box>
      <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {currentFormForEditing}
      </DrawerModal>
      <ButtonComponent
        sizeVariant="medium"
        colorVariant="primary"
        style={{ marginTop: '30px' }}
        disabled={loading}
        onClick={() => {
          if (hasErrors) {
            notifyPendingErrors();
          } else {
            completeImport();
          }
        }}
      >
        {totalPendingErrors ? 'Try again' : 'Continue'}
      </ButtonComponent>
    </>
  );
};
