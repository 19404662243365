import { TaxYearStart } from '@shared/modules/payroll/payroll.types';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import Polyglot from 'node-polyglot';

import { PayrollStatusLabel } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { SalaryBasisEnum } from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

export const formatPayrunState = (state: PayrunStates, polyglot: Polyglot): string => {
  return {
    [PayrunStates.draft]: () => polyglot.t('PayrunStates.draft'),
    [PayrunStates.inProgress]: () => polyglot.t('PayrunStates.inProgress'),
    [PayrunStates.partiallyCompleted]: () => polyglot.t('PayrunStates.partiallyComplete'),
    [PayrunStates.completed]: () => polyglot.t('PayrunStates.completed'),
  }[state]();
};

export const formatPayPeriod = (payPeriod: PayPeriod, polyglot: Polyglot): string => {
  return {
    Monthly: () => polyglot.t('PayPeriod.monthly'),
    Weekly: () => polyglot.t('PayPeriod.weekly'),
  }[payPeriod]();
};

export const formatPayPeriodIncome = (payPeriod: PayPeriod, polyglot: Polyglot): string => {
  return {
    Monthly: () => polyglot.t('PayrunTable.monthlyIncome'),
    Weekly: () => polyglot.t('PayrunTable.weeklyIncome'),
  }[payPeriod]();
};

export const formatUserPayrollStatus = (status: PayrollStatusLabel, polyglot: Polyglot): string => {
  return {
    [PayrollStatusLabel.Current]: () => polyglot.t('PayrunUserStates.current'),
    [PayrollStatusLabel.NewJoiner]: () => polyglot.t('PayrunUserStates.newJoiner'),
    [PayrollStatusLabel.Leaver]: () => polyglot.t('PayrunUserStates.leaver'),
    [PayrollStatusLabel.NotInPayroll]: () => polyglot.t('PayrunUserStates.notInPayroll'),
  }[status]();
};

export const formatSalaryBasis = (salaryBasis: SalaryBasisEnum | undefined, polyglot: Polyglot): string => {
  if (!salaryBasis) return '';
  return {
    Annual: () => polyglot.t('SalaryBasis.annual'),
    Monthly: () => polyglot.t('SalaryBasis.monthly'),
    Daily: () => polyglot.t('SalaryBasis.daily'),
    Hourly: () => polyglot.t('SalaryBasis.hourly'),
  }[salaryBasis]();
};

export function getTaxYearStartDate(taxYearStart: TaxYearStart, date: string): Date {
  const [_, yyyy, mm_dd] = date.match(/^(\d\d\d\d)-(\d\d-\d\d)/)!;
  if (mm_dd < taxYearStart) {
    return new Date(`${Number(yyyy) - 1}-${taxYearStart}`);
  }
  return new Date(`${yyyy}-${taxYearStart}`);
}

export function formatPayrunPeriod(
  payrun:
    | { payPeriod: PayPeriod; period: number; taxYear: string; taxYearStart?: TaxYearStart }
    | { payPeriod: PayPeriod; firstPaymentDate: string; taxYearStart?: TaxYearStart },
  locales?: string | string[]
): string {
  const taxYearStart = payrun.taxYearStart ?? '04-06';
  if ('period' in payrun) {
    const year = payrun.taxYear.slice(4);
    if (payrun.payPeriod === 'Weekly') {
      // 'W5 / 2000'
      return `W${payrun.period} / ${year}`;
    }
    const startOfTaxYear = new Date(`${year}-${taxYearStart}`);
    // 'January 2000'
    return addMonths(startOfTaxYear, payrun.period - 1).toLocaleString(locales, {
      month: 'long',
      year: 'numeric',
    });
  }

  if (payrun.payPeriod === 'Weekly') {
    const endDate = new Date(payrun.firstPaymentDate);
    const startOfTaxYear = getTaxYearStartDate(taxYearStart, payrun.firstPaymentDate);
    // For weekly payrolls, W1 starts in the week that includes taxYearStart
    const offsetToW1Monday = (startOfTaxYear.getDay() + 6) % 7;
    const weekNo = differenceInWeeks(endDate, addDays(startOfTaxYear, -offsetToW1Monday)) + 1;
    // 'W5 / 2000'
    return `W${weekNo} / ${startOfTaxYear.getFullYear()}`;
  }
  // 'January 2000'
  return new Date(payrun.firstPaymentDate).toLocaleString(locales, {
    month: 'long',
    year: 'numeric',
  });
}

export const formatDueDateMessage = (paymentDate: string, polyglot: Polyglot): [string, boolean] => {
  const days = differenceInDays(Date.parse(paymentDate), Date.parse(todaysDateShortISOString()));
  if (days === 1) {
    return [polyglot.t('DueDates.tomorrow'), false];
  }
  if (days > 0) {
    return [polyglot.t('DueDates.dueInDays', { smart_count: days }), false];
  }
  if (days < 0) {
    return [polyglot.t('DueDates.overdueByDays', { smart_count: -days }), true];
  }
  // days === 0
  return [polyglot.t('DueDates.today'), false];
};
