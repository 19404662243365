import { IconButton, Typography } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Settings } from '@/images/fields/Settings.svg';
import { SETTINGS_MONEY_SALARY_ROUTE } from '@/lib/routes';
import { StyledTooltip, StyledTooltipStyleProps } from '@/v2/components/theme-components/styled-tooltip.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';

type PayrollTopHeaderProps = {
  readonly settingsTooltip?: StyledTooltipStyleProps;
  readonly title?: string;
  readonly payrolls?: CompanyPayroll[];
  readonly showBack?: boolean;
  readonly backPath?: string;
  readonly showSettingsMenu?: boolean;
};

export const PayrollTopHeader = ({
  settingsTooltip,
  title,
  payrolls,
  showBack,
  backPath,
  showSettingsMenu = true,
}: PayrollTopHeaderProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  return (
    <TopHeader
      title={
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {title ?? polyglot.t('CompanyPayroll.salary')}
        </Typography>
      }
      showBack={showBack}
      backPath={backPath}
      showAction={!!payrolls && showSettingsMenu}
      actions={
        <StyledTooltip {...settingsTooltip}>
          <IconButton
            onClick={() => routerHistory.push(SETTINGS_MONEY_SALARY_ROUTE)}
            sx={iconCTAButtonSx}
            title={polyglot.t('CompanyPayroll.settings')}
          >
            <Settings {...iconSize} />
          </IconButton>
        </StyledTooltip>
      }
    />
  );
};
