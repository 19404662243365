import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { sortDate, sortString } from '@v2/components/table/table-sorting.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { GeneralisedRemoveConfirmDrawer } from '../../app-integration/features/app-details/components/generalised-remove-confirm-drawer-drawer.component';
import { OnboardingEndpoints } from '../../user-onboarding/by-admin/api-client/onboarding.api';

import { TemplateAPI } from '@/api-client/templates.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Cancel } from '@/images/app-icons/Close.svg';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoTransparent.svg';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { IIncludable, TableColumn } from '@/v2/feature/super-admin/components/helper/table-helper';
import { ContractModal } from '@/v2/feature/templates/components/contract-modal.component';
import { MissingTemplateFieldModal } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { RenameTemplateModal } from '@/v2/feature/templates/components/rename-template-modal.component';
import { TemplateModal } from '@/v2/feature/templates/components/template-modal.component';
import {
  ContractTemplate,
  RequiredContractFields,
  VerifyTemplateResult,
} from '@/v2/feature/templates/templates.interface';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { localFormat } from '@/v2/util/date-format.util';

// const ALLOWED_TEMPLATE_TYPES = ['Contracts'];

type BasicTableColumnType = ColumnDef<IncludableContractTemplate, IncludableContractTemplate>[];
type IncludableContractTemplate = IIncludable & ContractTemplate;

export const TemplatesPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [templates, setTemplates] = useState<readonly ContractTemplate[]>([]);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openContractModal, setOpenContractModal] = useState<boolean>(false);
  const [openRenameTemplateModal, setOpenRenameTemplateModal] = useState<boolean>(false);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<RequiredContractFields>();
  const [contractRecipientUserId, setContractRecipientUserId] = useState<number>();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState<number>();
  const [selectedTemplate, setSelectedTemplate] = useState<ContractTemplate>();
  const [loading, setLoading] = useState<boolean>(true);
  const [documentTypes, setDocumentTypes] = useState<ZeltDocumentType[]>([]);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const { data: contractTemplateCurrentlyUsedInOnboarding } = useApiClient(
    selectedTemplate
      ? OnboardingEndpoints.isContractTemplateCurrentlyUsedInOnboarding(selectedTemplate?.id)
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const [_state, dispatch] = useContext(GlobalContext);
  // const { user } = state;
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const routerHistory = useHistory();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const refreshAuthUser = useCallback(async () => {
    const response = await AuthAPI.getAuthMe(false);
    const authUser = response?.user ?? null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser,
    });
  }, [dispatch]);

  const fetchTemplates = useCallback(
    async (params?: { searchQuery: string }) => {
      try {
        setLoading(true);
        const templates = await TemplateAPI.getTemplates(params);
        setTemplates(templates || []);
        setLoading(false);
        refreshAuthUser();
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setLoading(false);
      }
    },
    [polyglot, refreshAuthUser, showMessage]
  );

  const deleteTemplate = useCallback(
    async (params: { templateId: string }) => {
      try {
        await TemplateAPI.deleteTemplate(params);
        showMessage(polyglot.t('TemplatesPage.successMessages.delete'), 'success');
        await fetchTemplates();
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.delete', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setLoading(false);
      }
    },
    [polyglot, showMessage, fetchTemplates]
  );

  const duplicateTemplate = useCallback(
    async (params: { templateId: string }) => {
      // OLD RESTRICTION
      // if (user?.restrictions?.PEOPLE?.disableDocTemplateCreation) setUpgradeModalOpen(true);
      // else {
      try {
        await TemplateAPI.duplicateTemplate({ id: params.templateId });
        showMessage(polyglot.t('TemplatesPage.successMessages.duplicate'), 'success');
        await fetchTemplates();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('TemplatesPage.errorMessages.duplicate', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      }
      // }
    },
    [polyglot, showMessage, fetchTemplates]
  );

  const onMissingField = (params: {
    templateVerificationResult: VerifyTemplateResult;
    contractRecipientUserId: number;
  }) => {
    const { contractRecipientUserId, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };

  const refreshMissingFieldsForTemplate = useCallback(
    async (templateId: string, contractRecipientUserId: number, companySignatoryUserId: number | undefined) => {
      try {
        const templateResult = await TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId,
          companySignatoryUserId,
        });

        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.verify', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  useEffect(() => {
    (async () => {
      try {
        const documentTypes = await DocumentAPI.listDocumentTypes();
        // Stop filtering to ONLY allowed template types - instead show ALL visible document types
        // setDocumentTypes(documentTypes.filter((d) => ALLOWED_TEMPLATE_TYPES.includes(d.category)));
        setDocumentTypes(documentTypes);

        await fetchTemplates({ searchQuery });
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [polyglot, showMessage, fetchTemplates, searchQuery]);

  // Need to refresh auth user / restrictions so that we restrict free user for template creation correctly
  useEffect(() => {
    refreshAuthUser();
  }, [refreshAuthUser]);

  const tableColums = useMemo<ColumnDef<IncludableContractTemplate, unknown>[]>(
    () => [
      new TableColumn<ContractTemplate>().define({
        header: polyglot.t('TemplatesPage.name'),
        id: 'name',
        minSize: 300,
        fieldName: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
      }),

      new TableColumn<ContractTemplate>().define({
        header: polyglot.t('TemplatesPage.type'),
        id: 'type',
        size: 90,
        fieldName: 'type',
        formatter: (type: string | null) => documentTypes.find((d) => d.value === type)?.label,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => documentTypes.find((d) => d.value === item.type)?.label ?? ''),
      }),

      new TableColumn<ContractTemplate>().define({
        header: polyglot.t('TemplatesPage.dateCreated'),
        id: 'dateCreated',
        size: 80,
        fieldName: 'createdAt',
        formatter: (createdAt: string) => localFormat('dd MMM yyyy', new Date(createdAt)),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt),
      }),

      new TableColumn<ContractTemplate>().define({
        header: polyglot.t('TemplatesPage.createdBy'),
        id: 'createdBy',
        size: 120,
        fieldName: 'user',
        parseRow: (row: IncludableContractTemplate) => <UserCell userId={row.user.userId} />,
        enableSorting: false,
      }),

      new TableColumn<ContractTemplate>().define({
        header: '',
        id: 'actions',
        size: 140,
        fieldName: '',
        enableSorting: false,
        parseRow: (row: IncludableContractTemplate) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', gap: 1 }}>
            <StyledMenuComponent
              options={[
                {
                  icon: <Edit {...iconSize} />,
                  handler: () => {
                    routerHistory.push(
                      generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, {
                        templateId: row.id,
                      }),
                      { returnPath: generatePath(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE) }
                    );
                  },
                  label: polyglot.t('TemplatesPage.edit'),
                  disabled: false,
                },

                {
                  icon: <InfoIcon {...iconSize} />,
                  handler: () => {
                    setSelectedTemplate(row as ContractTemplate);
                    setOpenRenameTemplateModal(true);
                  },
                  label: polyglot.t('TemplatesPage.rename'),
                  disabled: false,
                },

                {
                  icon: <Copy {...iconSize} />,
                  handler: () => {
                    duplicateTemplate({ templateId: row.id });
                  },
                  label: polyglot.t('TemplatesPage.duplicate'),
                  disabled: false,
                },

                {
                  icon: <Cancel {...iconSize} />,
                  handler: () => {
                    // deleteTemplate({ templateId: row.id });
                    setSelectedTemplate(row);
                    setIsRemovalModalOpen(true);
                  },
                  label: polyglot.t('TemplatesPage.delete'),
                  disabled: false,
                },
              ]}
              actionButtonDetails={{
                type: 'iconButton',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'actions',
                icon: <ActionsSmall {...iconSize} />,
              }}
            />

            {/* <Button
              key={row.id}
              onClick={() => {
                setSelectedTemplate(row as ContractTemplate);
                setOpenContractModal(true);
              }}
              sx={{ ...primaryTableSmallBtn, minWidth: 'fit-content' }}
            >
              {polyglot.t('TemplatesPage.use')}
            </Button> */}
          </Box>
        ),
      }),
    ],
    [polyglot, routerHistory, documentTypes, duplicateTemplate]
  );

  const CONTRACT_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t('DocumentTemplateConfirmRemove.normalConfirmMessage');
    if (!contractTemplateCurrentlyUsedInOnboarding) {
      return defaultMessage;
    } else if (contractTemplateCurrentlyUsedInOnboarding) {
      return polyglot.t('DocumentTemplateConfirmRemove.templateUsedInOnboardingConfirmMessage');
    }
    return defaultMessage;
  }, [contractTemplateCurrentlyUsedInOnboarding, polyglot]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Templates</Typography>}
      />
      <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <TableSearch
              query={searchQuery}
              handleChange={(e) => setSearchQuery(e.target.value?.trim() ?? '')}
              placeholder={polyglot.t('TemplatesPage.search')}
            />
          </Box>

          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => {
              // if (user?.restrictions?.PEOPLE?.disableDocTemplateCreation) setUpgradeModalOpen(true);
              // else
              setOpenTemplateModal(true);
            }}
          >
            {polyglot.t('TemplatesPage.newTemplate')}
          </ButtonComponent>
        </Box>

        <Box sx={{ ...spacing.mt20 }}>
          <BasicTable<IncludableContractTemplate>
            rowData={templates as IncludableContractTemplate[]}
            columnData={(tableColums as unknown) as BasicTableColumnType}
          />
        </Box>

        {openTemplateModal && (
          <TemplateModal open={openTemplateModal} setOpen={setOpenTemplateModal} documentTypes={documentTypes} />
        )}

        {selectedTemplate && openContractModal && (
          <ContractModal
            open={openContractModal}
            setOpen={setOpenContractModal}
            contractTemplate={selectedTemplate}
            onMissingField={onMissingField}
          />
        )}

        {selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && (
          <MissingTemplateFieldModal
            open={openMissingFieldContractModal}
            setOpen={setOpenMissingFieldContractModal}
            templateId={selectedTemplate.id}
            missingFields={missingFields}
            contractRecipientId={contractRecipientUserId}
            companySignatoryUserId={contractCompanySignatoryUserId}
            refreshMissingFields={refreshMissingFieldsForTemplate}
          />
        )}

        {selectedTemplate && openRenameTemplateModal && (
          <RenameTemplateModal
            documentTypes={documentTypes}
            open={openRenameTemplateModal}
            setOpen={setOpenRenameTemplateModal}
            template={selectedTemplate}
            onUpdate={fetchTemplates}
          />
        )}

        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.PEOPLE_PRO}
          messageSuffix="proGeneric"
        />

        <GeneralisedRemoveConfirmDrawer
          isOpen={isRemovalModalOpen}
          setIsOpen={setIsRemovalModalOpen}
          drawerTitle={polyglot.t('DocumentTemplateConfirmRemove.title')}
          confirmationMessage={CONTRACT_IN_USE_CONFIRMATION_MESSAGE}
          onClose={() => setIsRemovalModalOpen(false)}
          onConfirm={async () => {
            if (selectedTemplate?.id) deleteTemplate({ templateId: selectedTemplate?.id });
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
