import Polyglot from 'node-polyglot';

export const AttendanceReportColumnsOptions = (polyglot: Polyglot) => {
  return [
    { label: polyglot.t('AttendanceReportColumnsOptions.employeeName'), value: 'employeeName' },
    { label: polyglot.t('AttendanceReportColumnsOptions.entity'), value: 'entity' },
    { label: polyglot.t('AttendanceReportColumnsOptions.role'), value: 'role' },
    { label: polyglot.t('AttendanceReportColumnsOptions.reportsTo'), value: 'reportsTo' },
    { label: polyglot.t('AttendanceReportColumnsOptions.schedule'), value: 'schedule' },
    { label: polyglot.t('AttendanceReportColumnsOptions.logDate'), value: 'logDate' },
    { label: polyglot.t('AttendanceReportColumnsOptions.status'), value: 'status' },
    { label: polyglot.t('AttendanceReportColumnsOptions.regularPeriod'), value: 'regularPeriod' },
    { label: polyglot.t('AttendanceReportColumnsOptions.regularLength'), value: 'regularLength' },
    { label: polyglot.t('AttendanceReportColumnsOptions.breakPeriod'), value: 'breakPeriod' },
    { label: polyglot.t('AttendanceReportColumnsOptions.breakLength'), value: 'breakLength' },
    { label: polyglot.t('AttendanceReportColumnsOptions.overtimePeriod'), value: 'overtimePeriod' },
    { label: polyglot.t('AttendanceReportColumnsOptions.overtimeLength'), value: 'overtimeLength' },
    { label: polyglot.t('AttendanceReportColumnsOptions.startLatitude'), value: 'startLatitude' },
    { label: polyglot.t('AttendanceReportColumnsOptions.startLongitude'), value: 'startLongitude' },
    { label: polyglot.t('AttendanceReportColumnsOptions.endLatitude'), value: 'endLatitude' },
    { label: polyglot.t('AttendanceReportColumnsOptions.endLongitude'), value: 'endLongitude' },
    { label: polyglot.t('AttendanceReportColumnsOptions.notes'), value: 'notes' },
  ];
};
