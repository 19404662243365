import React, { useContext, useMemo } from 'react';

import { Avatar, Box, Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as CompletedAction } from '@/images/side-bar-icons/CheckCircle.svg';
import { ReactComponent as ActionCardIcon } from '@/images/side-bar-icons/WaitingFull.svg';
import { ButtonColorVariant, ButtonComponent } from '@/v2/components/forms/button.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { styledChipSx } from '@/v2/styles/chip.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface CustomChip {
  text: string;
  type: 'warning' | 'error' | 'success';
}

type ChipContent = React.ReactNode | CustomChip;

type OnboardingActionCardProps = {
  chips?: ChipContent[];
  completed?: boolean;
  pending?: boolean;
  disabled?: boolean;
  label: string;
  owner?: number | 'company';
  actionButton?: React.ReactNode;
  completedButton?: React.ReactNode;
  requiresAdminAction?: boolean;
  startButtonLabel?: string;
  editButtonLabel?: string;
  onStartClick?: React.MouseEventHandler<HTMLButtonElement> | null | false;
  showEdit?: boolean;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement> | null | false;
  sx?: SxProps<Theme>;
  startBtnColor?: ButtonColorVariant;
  editBtnColor?: ButtonColorVariant;
};

const getIconForCard = (completed: boolean | undefined, pending: boolean | undefined, requiresAdminAction: boolean) => {
  if (completed) return <CompletedAction style={{ height: 16, width: 16, flexShrink: 0, fill: themeColors.green }} />;
  if (pending)
    return <ActionCardIcon style={{ height: 16, width: 16, flexShrink: 0, fill: themeColors.YellowHover }} />;

  if (requiresAdminAction)
    return <ActionCardIcon style={{ height: 16, width: 16, flexShrink: 0, fill: themeColors.red }} />;
  return <ActionCardIcon style={{ height: 16, width: 16, flexShrink: 0 }} />;
};

export const OnboardingActionCard = ({
  chips,
  completed,
  pending,
  disabled,
  label,
  owner,
  onStartClick,
  startButtonLabel,
  editButtonLabel,
  actionButton,
  completedButton,
  requiresAdminAction = false,
  showEdit = false,
  onEditClick,
  startBtnColor = 'primary',
  editBtnColor = 'secondary',
  sx,
}: OnboardingActionCardProps) => {
  const { polyglot } = usePolyglot();
  const displayedChips = useMemo(() => chips?.filter(Boolean) ?? [], [chips]);
  const [globalState] = useContext(GlobalContext);

  return (
    <Stack sx={{ flexFlow: 'row', gap: spacing.g10, py: spacing.p15, alignItems: 'center', ...sx }}>
      {getIconForCard(completed, pending, requiresAdminAction)}
      <Typography variant="title4" color="DarkGrey" sx={{ whiteSpace: 'nowrap' }}>
        {label}
      </Typography>
      {!!chips?.length && (
        <Stack sx={{ flexFlow: 'row', flexWrap: 'wrap', gap: spacing.g4 }}>
          {displayedChips.map((chip, index) => (
            <React.Fragment key={index}>
              {typeof chip === 'string' || React.isValidElement(chip) ? (
                <Box sx={styledChipSx}>{chip}</Box>
              ) : (
                <Box sx={{ ...styledChipSx, color: themeColors.Red }}>{(chip as CustomChip).text}</Box>
              )}
            </React.Fragment>
          ))}
        </Stack>
      )}
      <Stack sx={{ flexFlow: 'row', alignItems: 'center', ml: 'auto', gap: spacing.g20 }}>
        {owner === 'company' && <Avatar src={globalState.user.company.avatar} sx={{ width: 20, height: 20 }} />}
        {typeof owner === 'number' && <UserAvatar userId={owner} size="xxsmall" />}
        <Stack sx={{ width: '100px', flexShrink: 0 }}>
          {!completed && !disabled && actionButton}
          {!completed && !disabled && !actionButton && onStartClick && (
            <ButtonComponent onClick={onStartClick} sizeVariant="small" colorVariant={startBtnColor} fullWidth>
              {startButtonLabel ?? polyglot.t('OnboardingByUser.start')}
            </ButtonComponent>
          )}
          {completed && !disabled && completedButton}
          {completed && showEdit && onEditClick && (
            <ButtonComponent onClick={onEditClick} sizeVariant="small" colorVariant={editBtnColor} fullWidth>
              {editButtonLabel ?? polyglot.t('OnboardingByUser.edit')}
            </ButtonComponent>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
