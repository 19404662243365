import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Skeleton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as HMRC } from '@/images/side-bar-icons/hmrc.svg';
import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PaymentsEndpoints } from '@/v2/feature/payments/payments.api';
import {
  PaymentInstitutionDto,
  PaymentResponseDto,
  SinglePaymentAccountInfo,
  SinglePaymentRequestDto,
} from '@/v2/feature/payments/payments.dto';
import { PAYMENTS_ROUTE } from '@/v2/feature/payments/payments.router';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserBankAccountAPI } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import { ShowHideButton } from '@/v2/feature/user/features/user-profile/details/components/show-hide-button.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface PaymentYapilyConnectProps {
  institution?: PaymentInstitutionDto;
  requests: readonly SinglePaymentRequestDto[];
  onSubmit: (groupPayments: boolean, groupPaymentsReference: string) => Promise<PaymentResponseDto>;
  onChange: () => void;
  setUnsafeClose: React.Dispatch<React.SetStateAction<boolean>>;
}

function openInNewTab(url: string) {
  const win = window.open(url, '');
  if (win) win.focus();
}

function PaymentRequestsDetails({ requests }: { requests: readonly SinglePaymentRequestDto[] }) {
  const { getCachedUserById } = useCachedUsers();
  const [bankRequest, setBankRequest] = useState<SinglePaymentAccountInfo[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const setBankInfoInRequest = useCallback(async () => {
    setLoading(true);

    try {
      const userIds = [...new Set(requests.map((r) => r.userId).filter(Boolean))] as number[];
      const accountsMapping = await UserBankAccountAPI.findCurrentByUserIds(userIds);

      const allRequests = requests.map((r) => ({
        ...r,
        accountNumber: r.userId ? accountsMapping[r.userId]?.accountNumber ?? null : null,
        sortCode: r.userId ? accountsMapping[r.userId]?.sortCode ?? null : null,
      }));

      setBankRequest(allRequests);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }

    setLoading(false);
  }, [polyglot, requests, showMessage]);

  useEffect(() => {
    setBankInfoInRequest();
  }, [setBankInfoInRequest]);

  const columns = useMemo<ColumnDef<SinglePaymentAccountInfo, SinglePaymentAccountInfo>[]>(
    () => [
      {
        header: () => 'Beneficiary',
        id: 'userId',
        maxSize: 150,
        minSize: 80,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const user = original?.userId ? getCachedUserById(original.userId) : undefined;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10, cursor: 'pointer' }}>
              {original.userId ? (
                <Box sx={{ display: 'block' }}>
                  <Box
                    sx={{
                      ...themeFonts.caption,
                      display: 'flex',
                      alignItems: 'center',
                      gap: spacing.m5,
                    }}
                  >
                    <UserAvatar userId={original?.userId} size="xxsmall" />
                    <Box sx={{ display: 'block' }}>
                      <Typography variant="caption">{user?.displayName}</Typography>
                      {original.accountNumber && (
                        <Typography variant="captionSmall" color="Grey">
                          {original.accountNumber}・{original.sortCode}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Typography
                  variant="caption"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                  }}
                >
                  <HMRC width={20} height={20} />
                  {'HMRC'}
                </Typography>
              )}
            </Box>
          );
        },
      },
      {
        header: () => 'Reference',
        id: 'reference',
        maxSize: 100,
        minSize: 50,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <>{original.reference}</>,
      },
      {
        header: () => 'Amount',
        id: 'amount',
        maxSize: 100,
        minSize: 50,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {formatMoney({
              amount: original.amount,
              currency: original.currency as CurrencyShort,
            })}
          </Box>
        ),
      },
    ],
    [getCachedUserById]
  );

  return (
    <BasicTable
      rowData={bankRequest ? [...bankRequest] : []}
      columnData={columns}
      hidePagination={true}
      loading={loading}
    />
  );
}

function PaymentFailed({ tryAgain, error }: { tryAgain: () => void; error: string | undefined }) {
  return (
    <Box sx={{ maxWidth: '400px' }}>
      <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
        <MistakeIcon width={30} height={30} />
        <Typography variant="title2">Payment failed</Typography>
      </Box>
      <Typography variant="caption" sx={{ mt: spacing.m10 }}>
        The bank responded with the following error:{' '}
        {error?.replaceAll('We can help you on https://docs.yapily.com/support', '')}
      </Typography>

      <Box sx={{ mt: spacing.m30 }}>
        <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={() => tryAgain()}>
          Restart
        </ButtonComponent>
      </Box>
    </Box>
  );
}

function PaymentYapilyConnectContent({
  institution,
  requests,
  onSubmit,
  onChange,
  setUnsafeClose,
}: PaymentYapilyConnectProps) {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const routerHistory = useHistory();

  const [checkTransactionId, setCheckTransactionId] = useState<number | null>(null);
  const { data: transactionStatus } = useApiClient(
    checkTransactionId ? PaymentsEndpoints.getTransactionStatus(checkTransactionId) : null,
    {
      refreshInterval: 2000,
    }
  );

  const showGroupPaymentsButton = useMemo(() => {
    // show group payments only if all the payments are user payments, and all of them are assigned to the same user in the same currency
    //  (all the payments have the same userId and currency as the first payment, and the userId is defined)
    return (
      requests.length > 1 &&
      requests.every(
        (request) =>
          request.userId && request.userId === requests[0].userId && request.currency === requests[0].currency
      )
    );
  }, [requests]);

  const tryAgain = () => {
    setError(undefined);
    routerHistory.push(PAYMENTS_ROUTE);
  };

  useEffect(() => {
    if (transactionStatus) {
      setCheckTransactionId(null);
      localStorage.setItem('transactionStatus', transactionStatus);
      onChange();
    } else {
      localStorage.removeItem('transactionStatus');
    }
  }, [transactionStatus, onChange]);

  const totalAmountByCurrency = requests.reduce((prev, request) => {
    const currency = (request.currency ?? 'Unknown') as CurrencyShort;
    if (!prev[currency]) prev[currency] = 0;

    prev[currency] += request.amount;
    return prev;
  }, {} as Record<CurrencyShort, number>);

  const formik = useFormik<{ groupPayments: boolean; groupPaymentReference: string }>({
    initialValues: {
      groupPayments: false,
      groupPaymentReference: 'Group payment',
    },
    validationSchema: yup.object({
      groupPayments: yup
        .boolean()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      groupPaymentReference: yup
        .string()
        .trim()
        .min(6, 'Minimum 6 characters required')
        .max(16, 'Maximum 16 characters allowed')
        .when('groupPayments', {
          is: true,
          then: (schema) =>
            schema
              .matches(/^[A-Za-z0-9\s]+$/, 'Only alphanumeric characters and spaces are allowed')
              .required(polyglot.t('ValidationMessages.requiredField')),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    onSubmit: async ({ groupPayments, groupPaymentReference }) => {
      try {
        setLoading(true);
        const response = await onSubmit(groupPayments, groupPaymentReference);
        if (response) {
          setUnsafeClose(false);
          openInNewTab(response.authorisationUrl);
          setCheckTransactionId(response.transactionId ?? null);
        }
      } catch (error) {
        setError(nestErrorMessage(error));
        setLoading(false);
        setUnsafeClose(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        {error && <PaymentFailed error={error} tryAgain={tryAgain} />}
        {!error && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="title2" sx={{ mb: '20px' }}>
              Confirm details
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                width: '100%',
                gap: spacing.g40,
              }}
            >
              <Box sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' } }}>
                <Typography variant="caption">
                  To easily set up payments from your bank in Zelt, we are about to securely re-direct you to your bank
                  where you will be asked to confirm the payment via Yapily Connect, an FCA regulated payment initiation
                  provider for Zelt. Yapily Connect will share these details with your bank, where you will then be
                  asked to confirm the following payment setup:
                </Typography>
                <Typography variant="caption" sx={{ mt: spacing.m10 }}>
                  This consent request is a one-off, you will not receive additional requests once completed.
                </Typography>

                <Box sx={{ mt: spacing.m10 }}>
                  <ShowHideButton
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    showTitle="About Yapily Connect"
                    hideTitle="About Yapily Connect"
                  />

                  {showDetails && (
                    <Box>
                      <Typography variant="title4" sx={{ mt: spacing.m30 }}>
                        Data Sharing
                      </Typography>
                      <Typography variant="caption" sx={{ mt: spacing.m10 }}>
                        Yapily Connect will retrieve bank data needed to facilitate this payment based on your request
                        and provide this information to Zelt.
                      </Typography>
                      <Typography variant="title4" sx={{ mt: spacing.m30 }}>
                        Secure Connection
                      </Typography>
                      <Typography variant="caption" sx={{ mt: spacing.m10 }}>
                        Data is securely accessed in read-only format and only for the purposes of this payment request.
                        This request is a one off, you will not receive any other requests from Yapily Connect for this
                        payment.
                      </Typography>
                      <Typography variant="title4" sx={{ mt: spacing.m30 }}>
                        FCA Authorisation
                      </Typography>
                      <Typography variant="caption" sx={{ mt: spacing.m10 }}>
                        Yapily Connect Ltd is authorised and regulated by the Financial Conduct Authority under the
                        Payment Service Regulations 2017 [827001] for the provision of Account Information and Payment
                        Initiation services.
                      </Typography>
                      <Typography variant="caption" sx={{ mt: spacing.m10 }}>
                        Please read{' '}
                        <a
                          href="https://yapi.ly/MwMo"
                          style={{ textDecoration: 'none', color: themeColors.ZeltYellow }}
                        >
                          Yapily Connect PIS - Terms & Conditions
                        </a>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' } }}>
                <PaymentRequestsDetails requests={requests} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    mt: spacing.m20,
                  }}
                >
                  <Typography variant="title4">Total Amount</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    {Object.keys(totalAmountByCurrency).map((currency) => (
                      <Typography variant="caption" key={currency} sx={{ pr: spacing.p5 }}>
                        {formatMoney({
                          amount: totalAmountByCurrency[currency as CurrencyShort],
                          currency: currency as CurrencyShort,
                        })}
                      </Typography>
                    ))}
                  </Box>
                </Box>

                {showGroupPaymentsButton && (
                  <Box sx={{ mt: '20px' }}>
                    <CheckboxComponent
                      label="Group payments"
                      name="groupPayments"
                      checked={formik.values.groupPayments}
                      onChange={formik.handleChange}
                    />
                  </Box>
                )}

                {showGroupPaymentsButton && formik.values.groupPayments && (
                  <Box sx={{ mt: '10px' }}>
                    <TextfieldComponent
                      name="groupPaymentReference"
                      label="Reference"
                      value={formik.values.groupPaymentReference}
                      onChange={formik.handleChange}
                      error={formik.touched.groupPaymentReference && !!formik.errors.groupPaymentReference}
                      helperText={(formik.touched.groupPaymentReference && formik.errors.groupPaymentReference) ?? ' '}
                    />
                  </Box>
                )}

                <Box sx={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
                  <LoaderButton
                    name={`Send payments to ${institution?.name}`}
                    loading={loading}
                    type="submit"
                    sizeVariant="medium"
                    colorVariant="primary"
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
}

function PaymentYapilyConnectContentSkeleton() {
  return (
    <>
      <Skeleton sx={{ backgroundColor: themeColors.Background }} />
      <Skeleton sx={{ backgroundColor: themeColors.Background }} />
      <Skeleton sx={{ backgroundColor: themeColors.Background }} />
    </>
  );
}

export function PaymentYapilyConnect(props: PaymentYapilyConnectProps) {
  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ maxWidth: '850px' }}>
        <Suspense fallback={<PaymentYapilyConnectContentSkeleton />}>
          <PaymentYapilyConnectContent {...props} />
        </Suspense>
      </Box>
    </Box>
  );
}
