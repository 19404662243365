import { ReactNode, useEffect, useState } from 'react';

import '@/v2/feature/dashboard/dashboard.scss';

export const WidgetLayout = ({
  children,
  readOnly,
  size,
  isLoadingState = false,
}: {
  children: ReactNode;
  readOnly: boolean;
  size?: 'big' | 'small';
  isLoadingState?: boolean;
}) => {
  const [blur, setBlur] = useState<string>('0px');

  useEffect(() => {
    if (isLoadingState) return;
    let currentBlur = 0;
    const interval = setInterval(() => {
      setBlur(`${currentBlur}px`);
      currentBlur++;
      if (currentBlur > 15) clearInterval(interval);
    }, 33); // Adjust the interval time as needed

    return () => clearInterval(interval);
  }, [isLoadingState]);

  return (
    <div
      style={{
        backdropFilter: `blur(${blur})`,
        WebkitBackdropFilter: `blur(${blur})`,
        transition: 'backdropFilter 0.1s ease',
      }}
      className={`base-structure ${size === 'big' ? 'large-width' : 'small-width'} ${readOnly ? 'read-structure' : ''}`}
    >
      <div className="widget-root" style={{ pointerEvents: readOnly ? 'none' : 'inherit' }}>
        {children}
      </div>
    </div>
  );
};
