import { Fragment, useContext } from 'react';

import { ApprovalRuleSettingsPage } from '@v2/feature/approval-rule/approval-rule-settings/approval-rule-settings.page';
import { AttendanceSettingsRouter } from '@v2/feature/attendance/settings/attendance-settings.router';
import { IdCheckSettingsPage } from '@v2/feature/id-check/id-check-settings.page';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  ENTITY_IMPORT_V2_ROUTE,
  SETTINGS_APPROVAL_RULES_ROUTE,
  SETTINGS_ATTENDANCE_OTHER_ROUTE,
  SETTINGS_ATTENDANCE_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
  SETTINGS_BENEFITS_ROUTE,
  SETTINGS_BILLING_ROUTE,
  SETTINGS_CALENDAR_ROUTE,
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_CUSTOMISATION_ROUTE,
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_DEVICES_ANDROID_MDM_ROUTE,
  SETTINGS_DEVICES_GENERAL_ROUTE,
  SETTINGS_DEVICES_MDM_ROUTE,
  SETTINGS_DEVICES_ROUTE,
  SETTINGS_DEVICES_ZELT_ROUTE,
  SETTINGS_DOCUMENTS_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATES_ROUTE,
  SETTINGS_DOCUMENTS_TYPES_ROUTE,
  SETTINGS_GROWTH_FACTORS_ROUTE,
  SETTINGS_GROWTH_REVIEWS_QUESTIONS_ROUTE,
  SETTINGS_GROWTH_REVIEWS_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE,
  SETTINGS_GROWTH_SCALES_ROUTE,
  SETTINGS_GROWTH_SURVEYS_QUESTIONS_ROUTE,
  SETTINGS_GROWTH_SURVEYS_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE,
  SETTINGS_ID_CHECK_PACKAGES_ROUTE,
  SETTINGS_JOB_LEVELS_ROUTE,
  SETTINGS_JOB_POSITIONS_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
  SETTINGS_MONEY_EXPENSES_ROUTE,
  SETTINGS_MONEY_INVOICES_ROUTE,
  SETTINGS_MONEY_ROUTE,
  SETTINGS_MONEY_SALARY_ROUTE,
  SETTINGS_NOTIFICATIONS_ROUTE,
  SETTINGS_ONBOARDING_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_ROUTE,
  SETTINGS_ORG_STRUCTURE_ROUTE,
  SETTINGS_PERMISSION_GROUPS_ROUTE,
  SETTINGS_PERSONAL_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_PROFILE_TAB_ROUTE,
  SETTINGS_REQUEST_FORMS_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_SITES_ROUTE,
  SETTINGS_TASK_CHECKLIST_ROUTE,
  SETTINGS_TASK_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE,
  SETTINGS_TIME_OTHER_ROUTE,
  SETTINGS_TIME_POLICIES_ROUTE,
  SETTINGS_TIME_ROUTE,
  SETTINGS_TRANSLATIONS_ROUTE,
  SETTINGS_WALLPAPER_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { CurrentUser, ScopeContext } from '@/models';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { AbsenceSettingsRouter } from '@/v2/feature/absence/absence-settings.router';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { BillingSettingsPage } from '@/v2/feature/billing/billing-settings/features/billing-settings.page';
import { CalendarSettingRouter } from '@/v2/feature/calendar/calendar-setting.router';
import { CompanySettingsPage } from '@/v2/feature/company/company-settings/features/company-settings.page';
import { OrgStructureSettingPage } from '@/v2/feature/company/company-settings/features/components/org-structure-settings/org-structure-settings.page';
import { CompanyTranslationPage } from '@/v2/feature/company/company-translation/company-translation.page';
import { CompanyWallpaperPage } from '@/v2/feature/company/company-wallpaper/company-wallpaper.page';
import { CustomProfileFieldsPage } from '@/v2/feature/custom-fields/pages/company-fields.page';
import { CustomProfileSectionsPage } from '@/v2/feature/custom-fields/pages/custom-profile-fields.page';
import { DepartmentSettingsPage } from '@/v2/feature/department/department-settings/features/department-settings.page';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';
import { DevicesSettingsRouter } from '@/v2/feature/device/features/devices-settings/devices-settings.router';
import { DocumentSettingsRouter } from '@/v2/feature/documents/document-settings.router';
import { EntityImportRouter } from '@/v2/feature/entity-import/entity-import.router';
import { GrowthFactorPage } from '@/v2/feature/growth/growth-factor/growth-factor.page';
import { GrowthScalePage } from '@/v2/feature/growth/growth-scale/growth-scale.page';
import { ReviewSettingsRouter } from '@/v2/feature/growth/reviews/review-settings.router';
import { SurveySettingsRouter } from '@/v2/feature/growth/surveys/survey-settings.router';
import { JobLevelSettingsPage } from '@/v2/feature/job-level/features/job-level-settings.page';
import { JobPositionSettingPage } from '@/v2/feature/job-position/job-position-settings/job-position-setting.page';
import { NotificationSettingRouter } from '@/v2/feature/notification/notification-settings/notification-setting.router';
import { OnboardingSettingsRouter } from '@/v2/feature/onboarding/onboarding-settings.router';
import { MoneySettingsRouter } from '@/v2/feature/payroll/features/payroll-settings/money-settings.router';
import { PermissionGroupRouter } from '@/v2/feature/permission-group/permission-group.router';
import { RequestFormsRouter } from '@/v2/feature/requests/features/request-forms/request-forms.router';
import { SecuritySettingRouter } from '@/v2/feature/security/security-settings/security-setting.router';
import { SiteSettingsPage } from '@/v2/feature/site/site-settings/features/site-settings.page';
import { TaskSettingRouter } from '@/v2/feature/task/task-setting.router';
import { UserSettings } from '@/v2/feature/user/features/user-settings/user-settings.page';
import { NestedRouter } from '@/v2/infrastructure/router/router.util';

export const getSettingPageConfig = (
  polyglot: Polyglot,
  currentUser: CurrentUser,
  context: ScopeContext
): readonly NavConfigItem[] => {
  let menu = [
    {
      title: polyglot.t('SettingsRouterPageConfig.personal'),
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.overview'),
          stub: 'personal',
          path: SETTINGS_PERSONAL_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
      ],
    },
    {
      title: polyglot.t('SettingsRouterPageConfig.organisation'),
      stub: 'organisation',
      isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.general'),
          stub: 'general',
          path: undefined,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: polyglot.t('SettingsRouterPageConfig.company'),
              stub: 'company',
              path: SETTINGS_COMPANY_ROUTE,
              isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.orgStructure'),
              stub: 'org-structure',
              path: SETTINGS_ORG_STRUCTURE_ROUTE,
              isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
              hasChildren: false,
            },
          ],
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.customisation'),
          stub: 'customisation',
          path: SETTINGS_CUSTOMISATION_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: polyglot.t('SettingsRouterPageConfig.profileFields'),
              stub: 'profileFields',
              path: SETTINGS_PROFILE_ROUTE,
              isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.approvalRules'),
              stub: 'approval-rules',
              path: SETTINGS_APPROVAL_RULES_ROUTE,
              isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.translations'),
              stub: 'translations',
              path: SETTINGS_TRANSLATIONS_ROUTE,
              isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.wallpaper'),
              stub: 'wallpaper',
              path: SETTINGS_WALLPAPER_ROUTE,
              isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
              hasChildren: false,
            },
          ],
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.security'),
          stub: 'security',
          path: SETTINGS_SECURITY_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.permissions'),
          stub: 'permissions',
          path: SETTINGS_PERMISSION_GROUPS_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.notifications'),
          stub: 'notifications',
          path: SETTINGS_NOTIFICATIONS_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.import'),
          stub: 'import',
          path: ENTITY_IMPORT_V2_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.billing'),
          stub: 'billing',
          path: SETTINGS_BILLING_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: false,
        },
      ],
    },
    {
      title: polyglot.t('getSidebarConfig.people'),
      stub: 'people',
      path: undefined,
      isHidden: !checkScopes(
        currentUser,
        ['company.settings:all', 'user.onboard:all', 'task:all', 'templates', 'documents:all'],
        context
      ),
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.requestForms'),
          stub: 'request-forms',
          path: SETTINGS_REQUEST_FORMS_ROUTE,
          isHidden: !checkScopes(currentUser, ['company.settings:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.onboardingFlows'),
          stub: 'onboarding',
          path: SETTINGS_ONBOARDING_FLOWS_ROUTE,
          isHidden: !checkScopes(currentUser, ['user.onboard:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.taskChecklists'),
          stub: 'tasks',
          path: SETTINGS_TASK_CHECKLIST_ROUTE,
          isHidden: !checkScopes(currentUser, ['task:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.documents'),
          stub: 'documents',
          path: undefined,
          isHidden: !checkScopes(currentUser, ['templates', 'documents:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: polyglot.t('SettingsRouterPageConfig.templates'),
              stub: 'templates',
              path: SETTINGS_DOCUMENTS_TEMPLATES_ROUTE,
              isHidden: !checkScopes(currentUser, ['templates'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.types'),
              stub: 'types',
              path: SETTINGS_DOCUMENTS_TYPES_ROUTE,
              isHidden: !checkScopes(currentUser, ['documents:all'], context),
              hasChildren: false,
            },
          ],
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.idCheckPackages'),
          stub: 'id-checks',
          path: SETTINGS_ID_CHECK_PACKAGES_ROUTE,
          isHidden: !checkScopes(currentUser, ['user.onboard:all'], context),
          hasChildren: false,
        },
      ],
    },
    {
      title: polyglot.t('getSidebarConfig.time'),
      stub: 'time',
      path: undefined,
      isHidden: !checkScopes(currentUser, ['calendar:all', 'absence:all', 'attendance:all'], context),
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.calendar'),
          stub: 'calendar',
          path: SETTINGS_CALENDAR_ROUTE,
          isHidden: !checkScopes(currentUser, ['calendar:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.time'),
          stub: 'time',
          path: undefined,
          isHidden: !checkScopes(currentUser, ['absence:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: polyglot.t('SettingsRouterPageConfig.policies'),
              stub: 'policies',
              path: SETTINGS_TIME_POLICIES_ROUTE,
              isHidden: !checkScopes(currentUser, ['absence:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.other'),
              stub: 'other',
              path: SETTINGS_TIME_OTHER_ROUTE,
              isHidden: !checkScopes(currentUser, ['absence:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.publicHolidays'),
              stub: 'holiday-calendars',
              path: SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE,
              isHidden: !checkScopes(currentUser, ['absence:all'], context),
              hasChildren: false,
            },
          ],
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.attendance'),
          stub: 'attendance',
          path: undefined,
          isHidden: !checkScopes(currentUser, ['attendance:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: polyglot.t('SettingsRouterPageConfig.schedules'),
              stub: 'schedules',
              path: SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
              isHidden: !checkScopes(currentUser, ['attendance:all'], context),
              hasChildren: false,
            },
            {
              title: polyglot.t('SettingsRouterPageConfig.other'),
              stub: 'other',
              path: SETTINGS_ATTENDANCE_OTHER_ROUTE,
              isHidden: !checkScopes(currentUser, ['attendance:all'], context),
              hasChildren: false,
            },
          ],
        },
      ],
    },
    {
      title: polyglot.t('SettingsRouterPageConfig.growth'),
      stub: 'growth',
      path: undefined,
      isHidden: !checkScopes(currentUser, ['reviews:all', 'surveys:all'], context),
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.reviewsForms'),
          stub: 'reviews',
          path: undefined,
          isHidden: !checkScopes(currentUser, ['reviews:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: 'Template',
              stub: 'templates',
              path: SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE,
              isHidden: !checkScopes(currentUser, ['reviews:all'], context),
              hasChildren: false,
            },
            {
              title: 'Question library',
              stub: 'questions',
              path: SETTINGS_GROWTH_REVIEWS_QUESTIONS_ROUTE,
              isHidden: !checkScopes(currentUser, ['reviews:all'], context),
              hasChildren: false,
            },
          ],
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.surveysForms'),
          stub: 'surveys',
          path: undefined,
          isHidden: !checkScopes(currentUser, ['surveys:all'], context),
          hasChildren: true,
          subMenu: [
            {
              title: 'Template',
              stub: 'templates',
              path: SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE,
              isHidden: !checkScopes(currentUser, ['surveys:all'], context),
              hasChildren: false,
            },
            {
              title: 'Question library',
              stub: 'questions',
              path: SETTINGS_GROWTH_SURVEYS_QUESTIONS_ROUTE,
              isHidden: !checkScopes(currentUser, ['surveys:all'], context),
              hasChildren: false,
            },
          ],
        },
        {
          title: 'Scales',
          stub: 'scales',
          path: SETTINGS_GROWTH_SCALES_ROUTE,
          isHidden: !checkScopes(currentUser, ['reviews:all'], context),
          hasChildren: false,
        },
        {
          title: 'Factors',
          stub: 'factor',
          path: SETTINGS_GROWTH_FACTORS_ROUTE,
          isHidden: !checkScopes(currentUser, ['reviews:all'], context),
          hasChildren: false,
        },
      ],
    },
    {
      title: polyglot.t('SettingsRouterPageConfig.money'),
      stub: 'money',
      path: SETTINGS_MONEY_ROUTE,
      isHidden: !checkScopes(
        currentUser,
        ['payroll:all', 'insurance:all', 'pension:all', 'payments:all', 'expenses:all', 'invoices:all'],
        context
      ),
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.salary'),
          stub: 'salary',
          path: SETTINGS_MONEY_SALARY_ROUTE,
          isHidden: !checkScopes(currentUser, ['payroll:all'], context),
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.invoices'),
          stub: 'invoices',
          path: SETTINGS_MONEY_INVOICES_ROUTE,
          isHidden: !checkScopes(currentUser, ['invoices:all'], context),
          // currently hidden till rest of expenses work is done
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.expenses'),
          stub: 'expenses',
          path: SETTINGS_MONEY_EXPENSES_ROUTE,
          isHidden: !checkScopes(currentUser, ['expenses:all'], context),
          // currently hidden till rest of expenses work is done
          hasChildren: false,
        },
        {
          title: polyglot.t('SettingsRouterPageConfig.benefits'),
          stub: 'benefits',
          path: SETTINGS_BENEFITS_ROUTE,
          isHidden: !checkScopes(currentUser, ['insurance:all', 'pension:all'], context),
          hasChildren: false,
        },
      ],
    },
    {
      title: polyglot.t('getSidebarConfig.tech'),
      stub: 'tech',
      path: undefined,
      isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('SettingsRouterPageConfig.devices'),
          stub: 'devices',
          path: undefined,
          isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
          hasChildren: true,
          subMenu: [
            {
              title: polyglot.t('SettingsRouterPageConfig.general'),
              stub: 'general',
              path: SETTINGS_DEVICES_GENERAL_ROUTE,
              isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
              hasChildren: false,
            },
            {
              title: 'MacOS',
              stub: 'zelt',
              path: SETTINGS_DEVICES_ZELT_ROUTE,
              isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
              hasChildren: false,
            },
            {
              title: 'iOS and iPadOS',
              stub: 'ios',
              path: SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
              isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
              hasChildren: false,
            },
            {
              title: 'Microsoft',
              stub: 'mdm',
              path: SETTINGS_DEVICES_MDM_ROUTE,
              isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
              hasChildren: false,
            },
            {
              title: 'Android',
              stub: 'mdm-android',
              path: SETTINGS_DEVICES_ANDROID_MDM_ROUTE,
              isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS, context),
              hasChildren: false,
            },
          ],
        },
      ],
    },
  ];

  return menu as NavConfigItem[];
};

// const SettingsWithCustomSideMenu = [
//   { path: SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE, exact: false },
//   { path: SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE, exact: false },
//   { path: SETTINGS_BENEFITS_DETAILS_ROUTE, exact: false },
//   { path: SETTINGS_TIME_POLICY_DETAILS_ROUTE, exact: false },
//   { path: SETTINGS_ATTENDANCE_SCHEDULE_ROUTE, exact: false },
//   { path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, exact: false },
//   { path: SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE, exact: false },
//   { path: SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE, exact: false },
//   { path: SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE, exact: false },
//   { path: SETTINGS_MOBILE_DEVICES_ZELT_ROUTE, exact: false },
//   { path: SETTINGS_DEVICES_ZELT_ROUTE, exact: false },
// ] as const;

export const SettingsRouter = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: currentUser.userId });

  return (
    <Fragment>
      <DomainSideMenuContent
        pageConfig={getSettingPageConfig(polyglot, currentUser, scopesContext)}
        title="Settings"
        type="Domain"
      />
      <Switch>
        <RouteScopesControl scopes={['user:read:all']} path={SETTINGS_PERSONAL_ROUTE}>
          <UserSettings />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_DEPARTMENTS_ROUTE}>
          <DepartmentSettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_SITES_ROUTE}>
          <SiteSettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_JOB_POSITIONS_ROUTE}>
          <JobPositionSettingPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_JOB_LEVELS_ROUTE}>
          <JobLevelSettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_ORG_STRUCTURE_ROUTE}>
          <OrgStructureSettingPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_TRANSLATIONS_ROUTE}>
          <CompanyTranslationPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_NOTIFICATIONS_ROUTE}>
          <NotificationSettingRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_BILLING_ROUTE}>
          <BillingSettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_APPROVAL_RULES_ROUTE}>
          <ApprovalRuleSettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_SECURITY_ROUTE}>
          <SecuritySettingRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_COMPANY_ROUTE} exact>
          <CompanySettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_WALLPAPER_ROUTE} exact>
          <CompanyWallpaperPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_PROFILE_ROUTE} exact>
          <CustomProfileSectionsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_PROFILE_TAB_ROUTE} exact>
          <CustomProfileFieldsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_ROUTE}>
          <AbsenceSettingsRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_PERMISSION_GROUPS_ROUTE}>
          <PermissionGroupRouter />
        </RouteScopesControl>

        <RouteScopesControl
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ROUTE}
          context={scopesContext}
        >
          <DevicesSettingsRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_ROUTE}>
          <AttendanceSettingsRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['user.onboard:all']} path={SETTINGS_ONBOARDING_ROUTE}>
          <OnboardingSettingsRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['user.onboard:all']} path={SETTINGS_ID_CHECK_PACKAGES_ROUTE}>
          <IdCheckSettingsPage />
        </RouteScopesControl>

        <RouteScopesControl scopes={['task:all']} path={SETTINGS_TASK_ROUTE}>
          <TaskSettingRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['calendar:all']} path={SETTINGS_CALENDAR_ROUTE}>
          <CalendarSettingRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['templates']} path={SETTINGS_DOCUMENTS_ROUTE}>
          <DocumentSettingsRouter />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={['reviews:all', 'surveys:all']}
          path={SETTINGS_GROWTH_REVIEWS_ROUTE}
          context={scopesContext}
        >
          <ReviewSettingsRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['surveys:all']} path={SETTINGS_GROWTH_SURVEYS_ROUTE} context={scopesContext}>
          <SurveySettingsRouter />
        </RouteScopesControl>

        <RouteScopesControl scopes={['surveys:all']} path={SETTINGS_GROWTH_SCALES_ROUTE} context={scopesContext}>
          <GrowthScalePage />
        </RouteScopesControl>

        <RouteScopesControl
          scopes={['reviews:all', 'surveys:all']}
          path={SETTINGS_GROWTH_FACTORS_ROUTE}
          context={scopesContext}
        >
          <GrowthFactorPage />
        </RouteScopesControl>

        <RouteScopesControl
          scopes={['insurance:all', 'pension:all', 'payments:all', 'payroll:all', 'expenses:all', 'invoices:all']}
          path={SETTINGS_MONEY_ROUTE}
          context={scopesContext}
        >
          <MoneySettingsRouter context={scopesContext} />
        </RouteScopesControl>

        <Redirect from={SETTINGS_ROUTE} to={SETTINGS_PERSONAL_ROUTE} exact />
        <NestedRouter>
          <EntityImportRouter />
          <RequestFormsRouter />
        </NestedRouter>
      </Switch>
    </Fragment>
  );
};
