import { Dispatch, SetStateAction } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CalendarBig } from '@/images/payments-intro/CalendarBig.svg';
import { ReactComponent as PaymentBig } from '@/images/payments-intro/PaymentBig.svg';
import { ReactComponent as SmileBig } from '@/images/payments-intro/SmileBig.svg';
import { ReactComponent as WalletBig } from '@/images/payments-intro/WalletBig.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  IconContentActionCardProps,
  IntroCardGroup,
} from '@/v2/components/theme-components/icon-content-action-card.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface PaymentEmptyStateProps {
  readonly setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const emptyStates: IconContentActionCardProps[] = [
  {
    title: 'Bulk payments',
    content:
      'Bulk payments help you pay the whole team in one go, in just a few clicks. Subject to your bank supporting this feature.',
    iconMedia: <PaymentBig />,
  },
  {
    title: 'Salary payments',
    content:
      'Automate your payroll even further, taking salary payments off your shoulders. Pay via Zelt and salary will reach your account before you say “Pay Day”.',
    iconMedia: <WalletBig />,
  },
  {
    title: 'Now or Later',
    content: 'Spend time when you have it. Run your payroll now and schedule payments to go out on the Pay Day. ',
    iconMedia: <CalendarBig />,
  },
  {
    title: 'Simple to use',
    content:
      'Payments app is easy to use by both admins and team members. Whether you are running payroll, paying taxes or a contractor invoice. ',
    iconMedia: <SmileBig />,
  },
];

export function PaymentEmptyState({ setIsModalOpen }: PaymentEmptyStateProps): JSX.Element {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ maxWidth: '600px' }}>
        <Typography sx={{ ...themeFonts.caption }}>
          Make secure payments using Open Banking directly from Zelt in a few clicks. Zelt supports 10+ most popular
          banks.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: spacing.g10,
            width: '70%',
            marginTop: spacing.m30,
          }}
        >
          <ButtonComponent sizeVariant="medium" colorVariant="primary" onClick={() => setIsModalOpen(true)}>
            New payment
          </ButtonComponent>
        </Box>
      </Box>

      <Box sx={spacing.mt40}>{<IntroCardGroup cards={emptyStates} />}</Box>
    </Box>
  );
}
