import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CompanyPayroll, CompanyPayrollsDto } from '@shared/modules/payroll/payroll.types';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortDate, sortNumeric } from '@v2/components/table/table-sorting.util';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayrollAPI } from '@v2/feature/payroll/payroll.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { PayrunStates } from '@v2/feature/payroll/payroll.interface';
import { HelperPayrunDetailsPage } from '@v2/feature/super-admin/features/helper-dashboard/helper-payrun-details.page';
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { secondarySmallBtn } from '@v2/styles/buttons.styles';
import { neutralColors, themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

export const HelperCompanyPayrunsRouter = ({
  company,
}: {
  company: SuperAdminCompanyInfo | undefined;
}): JSX.Element => {
  const routerHistory = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(true);
  const [allPayruns, setAllPayruns] = useState<readonly PayRunDto[]>([]);
  const [showMessage] = useMessage();
  const params = useParams<{ readonly companyId: string }>();
  const companyId = Number(params.companyId);
  const [companyPayrolls, setCompanyPayrolls] = useState<CompanyPayrollsDto | null>(null);
  const [payroll, setPayroll] = useState<CompanyPayroll | null>(null);

  const filteredPayruns = useMemo(() => {
    return allPayruns.filter((payrun) => {
      return (
        payrun.state.toLowerCase().includes(searchInput.toLowerCase()) ||
        String(payrun.period).includes(searchInput) ||
        new Date(payrun.startDate).toLocaleDateString().includes(searchInput) ||
        new Date(payrun.endDate).toLocaleDateString().includes(searchInput)
      );
    });
  }, [searchInput, allPayruns]);

  useEffect(() => {
    (async () => {
      if (!companyId) return;
      try {
        setLoading(true);
        const companyPayrolls = await PayrollAPI.getCompanyPayrollsAsSuperAdmin(companyId);
        setCompanyPayrolls(companyPayrolls);

        if (companyPayrolls.items && companyPayrolls.items[0]) setPayroll(companyPayrolls.items[0]);
      } catch (error) {
        showMessage(`Error loading payruns: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [showMessage, companyId]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (payroll) {
          const payruns = await PayrollLocalApi.getPayRunsAsSuperAdmin(companyId, payroll.id);
          setAllPayruns(payruns);
        }
      } catch (error) {
        showMessage(`Error loading payruns: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [showMessage, companyId, payroll]);

  const getColorByPayrunState = (state: PayrunStates): string => {
    switch (state) {
      case PayrunStates.draft:
        return neutralColors.n7;
      case PayrunStates.inProgress:
        return '#ada61a';
      case PayrunStates.partiallyCompleted:
        return '#23ad51';
      case PayrunStates.completed:
        return '#23ad51';
      default:
        return '#F00';
    }
  };

  const columns = useMemo<ColumnDef<PayRunDto, PayRunDto>[]>(
    () => [
      {
        header: () => 'Tax year',
        id: 'taxYear',
        maxSize: 100,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{original.taxYear}</Typography>,
      },
      {
        header: () => 'Pay period',
        id: 'payPeriod',
        maxSize: 100,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{original.payPeriod}</Typography>,
      },
      {
        header: () => 'Period',
        id: 'period',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.period),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{original.period}</Typography>,
      },
      {
        header: () => 'State',
        id: 'state',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={{ ...themeFonts.title4, color: getColorByPayrunState(original.state) }}>
            {original.state}
          </Typography>
        ),
      },
      {
        header: () => 'Start date',
        id: 'startDate',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.startDate),
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{new Date(original.startDate).toLocaleDateString()}</Typography>
        ),
      },
      {
        header: () => 'End date',
        id: 'endDate',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.endDate),
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{new Date(original.endDate).toLocaleDateString()}</Typography>
        ),
      },
      {
        header: () => 'Payment date',
        id: 'paymentDate',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.paymentDate),
        cell: ({ row: { original } }) =>
          original.paymentDate ? (
            <Typography sx={themeFonts.caption}>{new Date(original.paymentDate).toLocaleDateString()}</Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Employees',
        id: 'employeeCount',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.employeeCount),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{original.employeeCount}</Typography>,
      },
      {
        header: () => 'Gross',
        id: 'gross',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.gross),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{formatMoney({ amount: original.gross })}</Typography>
        ),
      },
      {
        header: () => 'Total cost',
        id: 'totalCost',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalCost),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{formatMoney({ amount: original.totalCost })}</Typography>
        ),
      },
    ],
    []
  );

  const handleRowClick = useCallback(
    ({ original }: Row<PayRunDto>) => {
      routerHistory.push(
        generatePath(SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE, { companyId, payrunId: original.id })
      );
    },
    [companyId, routerHistory]
  );

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE}>
        <HelperPayrunDetailsPage company={company} />
      </Route>

      <Route path={SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE}>
        <Box>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                Helper page - Payruns | {company?.name ? company.name : ''} [ {companyId} ]
              </Typography>
            }
            showBack
            backPath={generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })}
          />
          <ContentWrapper loading={loading} sx={{}}>
            <Box sx={{ ...spacing.px40, display: 'flex', gap: spacing.g20, mb: spacing.m20 }}>
              {companyPayrolls &&
                companyPayrolls.items?.map((i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderRadius: 2,
                      width: '300px',
                      bgcolor: payroll?.id === i.id ? '#aaa' : '#ddd',
                      p: spacing.p10,
                    }}
                  >
                    <Typography sx={themeFonts.title4}>{i.id}</Typography>
                    {i.entity?.legalName && <Typography sx={themeFonts.caption}>{i.entity.legalName}</Typography>}
                    <Box>
                      <Button onClick={() => setPayroll(i)} disabled={payroll?.id === i.id} sx={secondarySmallBtn}>
                        Select
                      </Button>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box sx={spacing.px40}>
              <TableSearch
                query={searchInput}
                handleChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ mt: spacing.mt20 }}>
              <BasicTable<PayRunDto>
                rowData={[...filteredPayruns]}
                columnData={columns}
                loading={loading}
                rowClick={handleRowClick}
                hidePagination
              />
            </Box>
          </ContentWrapper>
        </Box>
      </Route>
    </Switch>
  );
};
