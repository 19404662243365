import { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { capitalize } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
} from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { EmptyStateBox } from '@/v2/components/empty-state-box.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReviewEndpoints } from '@/v2/feature/growth/reviews/api-client/review.api';
import { getStatus } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewUserResultList } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { GrowthForbiddenAccess } from '@/v2/feature/growth/shared/components/growth-forbidden-access.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const ReviewUserResultPage = ({ targetUserId }: { targetUserId: number }) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const {
    data: resultList,
    isValidating: validatingResultList,
    isLoading: loadingResultList,
    error: resultError,
  } = useApiClient(ReviewEndpoints.getUserResultList(targetUserId, searchInput), {
    suspense: false,
  });

  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const hasReviewsManagerScopes = hasScopes(['reviews:manager'], getScopesContext({ userId: targetUserId }));
  const hasReviewsAdminScopes = hasScopes(['reviews:all'], getScopesContext(user));

  const routerHistory = useHistory();

  const tableColumns = useMemo<ColumnDef<ReviewUserResultList, ReviewUserResultList>[]>(
    () => [
      {
        header: () => 'Cycle name',
        id: 'name',
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { name },
          },
        }) => (name ? <div>{name}</div> : <EmptyCell />),
      },
      {
        header: () => 'Cycle status',
        accessorFn: (row) => row,
        id: 'state',
        enableSorting: false,

        cell: ({
          row: {
            original: { state },
          },
        }) => (state ? getStatus()[state] : <EmptyCell />),
        minSize: 90,
        maxSize: 120,
      },

      {
        header: () => 'Start date',
        id: 'startDate',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { startDate },
          },
        }) => (
          <div>
            {new LocalDate(startDate)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          </div>
        ),
      },
      {
        header: () => 'Type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,

        cell: ({
          row: {
            original: { type },
          },
        }) => (type ? capitalize(type) : <EmptyCell />),
        minSize: 90,
        maxSize: 120,
      },
      {
        header: () => 'Result',
        accessorFn: (row) => row,
        id: 'isResultShared',
        enableSorting: false,

        cell: ({
          row: {
            original: { isResultShared },
          },
        }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChipComponent name={isResultShared ? 'Shared' : 'Not shared'} border="background" />
          </div>
        ),
        minSize: 90,
        maxSize: 120,
      },
    ],
    []
  );

  const handleRowClick = useCallback(
    (row: Row<ReviewUserResultList>) => {
      if (hasReviewsManagerScopes || hasReviewsAdminScopes) {
        const route = hasReviewsAdminScopes
          ? REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE
          : REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE;
        routerHistory.push(generatePath(route, { cycleId: row.original.id, revieweeId: targetUserId }));
      }
    },
    [routerHistory, targetUserId, hasReviewsAdminScopes, hasReviewsManagerScopes]
  );

  if (!resultList && resultError && resultError.status === 403) {
    return (
      <div style={{ width: '100%', margin: spacing.s6 }}>
        <GrowthForbiddenAccess />
      </div>
    );
  }

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Reviews</Typography>} />
      <ContentWrapper loading={false}>
        {!loadingResultList && resultList && resultList.length === 0 && !searchInput ? (
          <EmptyStateBox
            title="Your results"
            subtitle="Once completed and shared, your performance results will be published here. There are no results for you, yet."
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s2 }}>
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <BasicTable
              rowData={resultList ? [...resultList] : []}
              columnData={tableColumns}
              loading={validatingResultList}
              hidePagination={true}
              rowClick={handleRowClick}
            />
          </Box>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
