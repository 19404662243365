import React from 'react';

import { InsuranceAddonsMultipliersDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import Polyglot from 'node-polyglot';

import AvivaLogo from '@/images/vendors/AvivaSquareLogo.svg';
import AXALogo from '@/images/vendors/AXASquareLogo.svg';
import BupaLogo from '@/images/vendors/BupaSquareLogo.svg';
import VitalityLogo from '@/images/vendors/VitalitySquareLogo.svg';
import { LocalDate } from '@/v2/util/local-date';

export const getAdditionalMembersOptions = (polyglot: Polyglot) => [
  { label: polyglot.t('UserInsuranceDependants.SpousePartner'), value: UserInsuranceDependants.SpousePartner },
  { label: polyglot.t('UserInsuranceDependants.Children'), value: UserInsuranceDependants.Children },
  { label: polyglot.t('UserInsuranceDependants.Family'), value: UserInsuranceDependants.Family },
];

function monthDiff(d1: Date, d2: Date): number {
  // Get the difference in years
  const years = d2.getFullYear() - d1.getFullYear();

  // Get the difference in months
  let months = d2.getMonth() - d1.getMonth();

  // Return the total number of full months
  return years * 12 + months;
}

export function getLogoByProviderName(providerName: string, width = 20): any {
  if (providerName.toLowerCase() === 'axa') {
    return <img src={AXALogo} alt="AXA" width={width} />;
  }
  if (providerName.toLowerCase() === 'vitality') {
    return <img src={VitalityLogo} alt="Vitality" width={width} />;
  }
  if (providerName.toLowerCase() === 'aviva') {
    return <img src={AvivaLogo} alt="Aviva" width={width} />;
  }
  if (providerName.toLowerCase() === 'bupa') {
    return <img src={BupaLogo} alt="Bupa" width={width} />;
  }

  return <img src={AvivaLogo} alt="Aviva" width={width} />;
}

export function getInsuranceMultiplierValueByDependantsValue(
  employeeMonthlyPremium: number,
  additionalMembersValue: UserInsuranceDependants,
  insuranceAddonsMultipliers: InsuranceAddonsMultipliersDto
): number {
  if (additionalMembersValue === UserInsuranceDependants.SpousePartner)
    return Number.parseFloat(
      (employeeMonthlyPremium * insuranceAddonsMultipliers.spousePartner - employeeMonthlyPremium).toFixed(2)
    );

  if (additionalMembersValue === UserInsuranceDependants.Children)
    return Number.parseFloat(
      (employeeMonthlyPremium * insuranceAddonsMultipliers.children - employeeMonthlyPremium).toFixed(2)
    );

  if (additionalMembersValue === UserInsuranceDependants.Family)
    return Number.parseFloat(
      (employeeMonthlyPremium * insuranceAddonsMultipliers.family - employeeMonthlyPremium).toFixed(2)
    );

  return Number.parseFloat(employeeMonthlyPremium.toFixed(2));
}

export function calculateAnnualValueOfTheBenefitForCurrentTaxYear(
  date: Date | string,
  employeeMonthlyPremium: number,
  employeeMonthlyContribution: number | null,
  dependantsMonthlyPremium: number | null,
  dependantsMonthlyContribution: number | null
): number {
  const startDate = new LocalDate(date).getDate();
  const currentYear = startDate.getFullYear();
  const endOfTaxYear = new Date(`1 April ${currentYear}`);
  if (endOfTaxYear.getTime() <= startDate.getTime()) endOfTaxYear.setFullYear(currentYear + 1);

  const fullMonthDifference = monthDiff(startDate, endOfTaxYear);

  const totalMonthlyPremiumPaidByEmployer =
    employeeMonthlyPremium -
    (employeeMonthlyContribution ?? 0) +
    (dependantsMonthlyPremium ? dependantsMonthlyPremium - (dependantsMonthlyContribution ?? 0) : 0);

  return Number.parseFloat((totalMonthlyPremiumPaidByEmployer * fullMonthDifference).toFixed(2));
}
