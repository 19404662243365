import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { EmploymentsTabFilter } from '../../../app-router.util';

import useMessage from '@/hooks/notification.hook';
import cover from '@/images/app-empty.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  EmploymentDetail,
  EmploymentSummary,
  InstalledAppDto,
  RemoteEmploymentDetail,
  RemoteEmploymentSummary,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APP_ACTION_DRAWER_MODES,
  AppIntegrationStub,
  CountryListing,
} from '@/v2/feature/app-integration/app-integration.interface';
import { DeelUser } from '@/v2/feature/app-integration/apps/deel/deel.dto';
import { OysterEmploymentDetail } from '@/v2/feature/app-integration/apps/oysterhr/oysterhr.dto';
import { AppDetailsEndpoints } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { AppDetailConfigurationModal } from '@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component';
import { AppDetailsActionButton } from '@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component';
import { AppDetailsTable } from '@/v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { AppActionsDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OnboardNewEmployee } from '@/v2/feature/onboarding/components/onboard-new-employee.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { primarySmallBtn, secondarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { toTitleCase } from '@/v2/util/string.util';

export const APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT = ['remote', 'deel', 'oysterhr'];
export const APPS_SUPPORTING_CANDIDATE_MANAGEMENT = ['teamtailor'];

interface AppEmploymentManagementProps {
  readonly teamAccessUserList: readonly AppIntegrationUserDto[];
  readonly countriesForEmployment: CountryListing[];
  readonly employmentList: readonly EmploymentDetail[];
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
  readonly loading: boolean;
  readonly error: boolean;
  readonly onAppChange: (_: string) => void;
  readonly hasAppsAllOrAppOwner: boolean;
}

export const AppEmploymentManagementPage = ({
  teamAccessUserList,
  countriesForEmployment,
  employmentList,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  hasAppsAllOrAppOwner,
}: AppEmploymentManagementProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [selectedActiveUserLogin, setSelectedActiveUserLogin] = useState<string | undefined>(undefined);
  const [preselectedUser, setPreselectedUser] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [employeeForOnboarding, setEmployeeForOnboarding] = useState<EmploymentSummary | undefined>(undefined);
  const [actionsDrawerMode, setActionsDrawerMode] = useState<APP_ACTION_DRAWER_MODES | undefined>(undefined);
  const [onboardUserDrawerOpen, setOnboardUserDrawerOpen] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('all');
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredEmploymentList, setFilteredEmploymentList] = useState<readonly EmploymentDetail[]>(employmentList);
  const { data: candidateOnboardingTemplate } = useApiClient(AppDetailsEndpoints.getOnboardingConfiguration(appStub), {
    suspense: false,
  });

  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false,
    }
  );

  const teamEmailList = useMemo(() => {
    return teamAccessUserList.flatMap((eachUser: AppIntegrationUserDto) =>
      eachUser && eachUser?.emails && eachUser?.emails?.length > 0
        ? [eachUser?.emails[0].email, eachUser.emails[0]?.personalEmail]
        : []
    );
  }, [teamAccessUserList]);

  useEffect(() => {
    setFilteredEmploymentList(
      filterString === 'all' ? employmentList : employmentList.filter((eachUser) => eachUser.status === filterString)
    );
  }, [employmentList, filterString]);

  useEffect(() => {
    setFilteredEmploymentList(
      searchInput.length === 0
        ? employmentList
        : employmentList.filter(
            (eachUser) =>
              eachUser.displayName && eachUser.displayName?.toLowerCase().includes(searchInput.toLowerCase())
          )
    );
  }, [searchInput, employmentList]);

  const createEmployment = () => {
    setActionsDrawerMode(APP_ACTION_DRAWER_MODES.createEmployment);
    setIsAppActionsDrawerOpen(true);
  };

  const getDisplayNameForEmployment = useCallback(
    (employment: EmploymentDetail) => {
      if (appStub === 'remote') return (employment as RemoteEmploymentDetail)?.full_name;
      if (appStub === 'deel') return (employment as DeelUser)?.displayName;
      if (appStub === 'oysterhr') return (employment as OysterEmploymentDetail)?.displayName;
      return 'N/A';
    },
    [appStub]
  );

  const getTitleForEmployment = useCallback(
    (employment: EmploymentSummary) => {
      if (appStub === 'remote') return (employment as RemoteEmploymentSummary)?.job_title;
      if (appStub === 'deel') return (employment as DeelUser)?.title;
      if (appStub === 'oysterhr') return (employment as OysterEmploymentDetail)?.role;
      return 'N/A';
    },
    [appStub]
  );

  const getEmailForEmployment = useCallback(
    (employment: EmploymentSummary) => {
      if (appStub === 'remote') return (employment as RemoteEmploymentDetail)?.personal_email;
      if (appStub === 'deel') return (employment as DeelUser)?.primaryEmail;
      if (appStub === 'oysterhr') return (employment as OysterEmploymentDetail)?.primaryEmail;
      return '';
    },
    [appStub]
  );

  const getStartDateForEmployment = useCallback(
    (employment: EmploymentSummary) => {
      if (appStub === 'remote') return (employment as RemoteEmploymentDetail)?.provisional_start_date;
      if (appStub === 'deel') return (employment as DeelUser)?.startDate;
      if (appStub === 'oysterhr') return (employment as OysterEmploymentDetail)?.startDate;
      return undefined;
    },
    [appStub]
  );

  const getEmploymentType = useCallback(
    (employment: EmploymentSummary) => {
      if (appStub === 'remote') return toTitleCase((employment as RemoteEmploymentDetail)?.type);
      if (appStub === 'deel') return ((employment as DeelUser)?.userType).toUpperCase();
      if (appStub === 'oysterhr') return (employment as OysterEmploymentDetail)?.userType;
      return undefined;
    },
    [appStub]
  );

  const employmentColumn = useMemo<ColumnDef<EmploymentDetail, EmploymentDetail>[]>(
    () => [
      {
        header: () => 'Account Name',
        id: 'fullName',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Typography
            sx={{
              ...themeFonts.caption,
              display: 'flex',
              alignItems: 'center',
              gap: spacing.m5,
              color: themeColors.DarkGrey,
            }}
          >
            {getDisplayNameForEmployment(original)}
          </Typography>
        ),
      },
      ...(appStub === ('remote' as AppIntegrationStub)
        ? [
            {
              header: () => 'Country',
              id: 'country',
              maxSize: 180,
              minSize: 100,
              accessorFn: (row: { original: RemoteEmploymentDetail }) => row,
              enableSorting: false,
              cell: ({ row: { original } }: { row: { original: EmploymentDetail } }) => (
                <Box>
                  {appStub === 'remote' && (
                    <Typography
                      sx={{
                        ...themeFonts.caption,
                        display: 'flex',
                        alignItems: 'center',
                        gap: spacing.m15,
                        color: themeColors.DarkGrey,
                      }}
                    >
                      {(original as RemoteEmploymentDetail)?.country?.name || 'N/A'}
                    </Typography>
                  )}
                </Box>
              ),
            },
          ]
        : []),
      {
        header: () => 'Type',
        id: 'type',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m15,
                color: themeColors.DarkGrey,
              }}
            >
              {getEmploymentType(original as EmploymentSummary) ?? 'N/A'}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => `${app?.name} Status`,
        id: 'status',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m15,
                color: themeColors.DarkGrey,
              }}
            >
              {original?.status ? original.status : 'N/A'}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => '',
        id: 'action',
        maxSize: 80,
        minSize: 80,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <AppDetailsActionButton
            setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
            setSelectedActiveUserLogin={setSelectedActiveUserLogin}
            setGroupManagementDrawerOpen={() => {}}
            setGroupManagementDrawerMode={() => {}}
            refresh={onAppChange}
            appStub={appStub}
            alreadyImported={teamEmailList.includes(getEmailForEmployment(original))}
            employment={original}
            delayedActions={delayedActions ?? { delayed: [] }}
            refreshDelayedActions={refreshDelayedActions}
            table="employments"
            hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
            setPreselectedUser={setPreselectedUser}
            setEmployeeForOnboarding={setEmployeeForOnboarding}
            setActionsDrawerMode={setActionsDrawerMode}
            error={error}
            setOnboardUserDrawerOpen={setOnboardUserDrawerOpen}
          />
        ),
      },
    ],
    [
      appStub,
      getDisplayNameForEmployment,
      getEmploymentType,
      app?.name,
      onAppChange,
      teamEmailList,
      getEmailForEmployment,
      delayedActions,
      refreshDelayedActions,
      hasAppsAllOrAppOwner,
      error,
    ]
  );

  const teamListWithoutExistingEmployments = useMemo(() => {
    const employmentEmailList = employmentList.map((b) => getEmailForEmployment(b));
    return teamAccessUserList.filter(
      (teamUser) =>
        teamUser &&
        teamUser?.emails &&
        teamUser?.emails[0]?.email &&
        !employmentEmailList.includes(teamUser?.emails[0]?.email)
    );
  }, [employmentList, getEmailForEmployment, teamAccessUserList]);

  const mapEmploymentToUserForOnboarding = (
    user: EmploymentSummary
  ): {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    personalEmail: string;
    jobTitle: string;
    phone: string;
    startDate?: string;
    templateId?: number;
  } => {
    const nameParts = user?.displayName?.split(' ')?.filter(Boolean);
    if (!nameParts || nameParts?.length === 0) {
      showMessage(`Unable to import user without name details. Please update candidate and try again`, 'error');
    }
    return {
      userId: 0,
      firstName: nameParts ? nameParts[0] : '',
      lastName: nameParts ? nameParts[1] : '',
      emailAddress: '',
      personalEmail: getEmailForEmployment(user) ?? '',
      jobTitle: getTitleForEmployment(user) ?? '',
      phone: '',
      startDate: getStartDateForEmployment(user) ?? undefined,
      templateId:
        candidateOnboardingTemplate && candidateOnboardingTemplate?.selected
          ? candidateOnboardingTemplate.selected
          : undefined,
    };
  };

  const getInitialValuesForOnboarding = () => {
    if (APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT.includes(appStub) && employeeForOnboarding)
      return mapEmploymentToUserForOnboarding(employeeForOnboarding);
    return { userId: 0, personalEmail: null };
  };

  const getExternalIdForOnboarding = () => {
    if (APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT.includes(appStub) && employeeForOnboarding)
      return employeeForOnboarding?.id ? String(employeeForOnboarding?.id) : undefined;
    return undefined;
  };

  return (
    <RootStyle>
      <TopHeader title={<AppNameHeader title={polyglot.t('AppEmploymentManagementPage.title')} app={app} />} />
      <ContentWrapper loading={loading ?? false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'left',
          }}
        >
          {!error && (
            <>
              <TabFilterButtons
                filters={EmploymentsTabFilter}
                setFilterValue={setFilterString}
                filterValue={filterString}
                hasSearch
                onFilterChange={({ filterValue, searchInput }) => {
                  setFilterString(filterValue);
                  setSearchInput(searchInput);
                }}
              />
              {hasAppsAllOrAppOwner && (
                <Box sx={{ marginLeft: 'auto' }}>
                  <Button
                    sx={{ ...secondarySmallBtn }}
                    startIcon={<Reload {...iconSize} />}
                    onClick={() => onAppChange(appStub)}
                  >
                    Refresh
                  </Button>
                  {app?.allowsEmploymentCreation && (
                    <Button
                      sx={{ ...primarySmallBtn, ml: spacing.m10 }}
                      variant="contained"
                      onClick={() => createEmployment()}
                    >
                      Create employment
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
        <Box sx={spacing.mt20}>
          <AppDetailsTable column={employmentColumn} row={filteredEmploymentList ?? []} loading={loading ?? false} />
          {error && !app?.authorised && (
            <EmptyStateComponent
              header="Authorize this app"
              subheader="Zelt allows you to manage your whole toolstack from one place."
              detail="To start using your app connect your account in Settings first."
              cover={cover}
            />
          )}
        </Box>
        <AppDetailConfigurationModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          app={app}
          appStub={appStub}
          refresh={() => onAppChange(appStub)}
        />
        <OnboardNewEmployee
          isOpen={onboardUserDrawerOpen}
          setIsOpen={setOnboardUserDrawerOpen}
          initialValues={getInitialValuesForOnboarding()}
          onDraftUserCreated={() => setOnboardUserDrawerOpen(false)}
          mode="onboard"
          appStub={appStub}
          externalId={getExternalIdForOnboarding()}
        />
        <AppActionsDrawer
          appStub={appStub}
          countriesForEmployment={countriesForEmployment}
          usersWithoutAccess={teamListWithoutExistingEmployments}
          usersWithAccess={(employmentList as unknown) as AppIntegrationUserDto[]}
          isOpen={isAppActionsDrawerOpen}
          onClose={() => setIsAppActionsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          refreshDelayedActions={refreshDelayedActions}
          setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
          preselectedUser={preselectedUser}
          mode={actionsDrawerMode}
          selectedActiveUserLogin={selectedActiveUserLogin}
          setSelectedActiveUserLogin={setSelectedActiveUserLogin}
          directoryMode={'employments'}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
