import React from 'react';

import { Box, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { TwoPreviewRepaymentPlanDto } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.dto';
import {
  TwoRepaymentPlanInstalment,
  TwoRepaymentPlanTaxSubtotal,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface RefinancingPlanPreviewDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly preview: TwoPreviewRepaymentPlanDto;
}

export const RefinancingPlanPreviewDrawer = ({ isOpen, setIsOpen, preview }: RefinancingPlanPreviewDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap20 }}>
        <Typography sx={titleSx}>Preview</Typography>

        <Box>
          <Typography sx={themeFonts.title4}>Terms</Typography>
          <Typography sx={themeFonts.caption}>
            Period: <b>{preview.terms.period}</b>
          </Typography>
          <Typography sx={themeFonts.caption}>
            No of periods: <b>{preview.terms.number_of_periods}</b>
          </Typography>
        </Box>
        <Box>
          <Typography sx={themeFonts.title4}>Tax subtotals</Typography>
          {preview.tax_subtotals.map((tax: TwoRepaymentPlanTaxSubtotal) => (
            <Box>
              <Typography sx={themeFonts.caption}>
                Taxable amount = <b>{formatMoney({ amount: tax.taxable_amount })}</b>
              </Typography>
              <Typography sx={themeFonts.caption}>
                Tax rate ={' '}
                <b>
                  {tax.tax_rate} ({tax.tax_rate * 100}%)
                </b>
              </Typography>
              <Typography sx={themeFonts.caption}>
                VAT = <b>{formatMoney({ amount: tax.tax_amount })}</b>
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap10, mb: spacing.m30 }}>
          <Typography sx={themeFonts.title4}>Instalments</Typography>
          {preview.instalments.map((instalment: TwoRepaymentPlanInstalment, index) => (
            <Box sx={{ display: 'flex', gap: spacing.g10 }}>
              <Typography sx={themeFonts.caption}>{index})</Typography>
              <Box>
                <Typography sx={themeFonts.caption}>
                  Repayment amount: <b>{formatMoney({ amount: instalment.repayment_amount })}</b>
                </Typography>
                <Typography sx={themeFonts.caption}>
                  Invoice issue date: <b>{instalment.invoice_issue_date}</b>
                </Typography>
                <Box>
                  <Typography sx={themeFonts.caption}>Tax subtotals:</Typography>
                  {instalment.tax_subtotals.map((tax: TwoRepaymentPlanTaxSubtotal) => (
                    <Box sx={{ ml: spacing.m20, pb: spacing.m5 }}>
                      <Typography sx={themeFonts.caption}>
                        Taxable amount = <b>{formatMoney({ amount: tax.taxable_amount })}</b>
                      </Typography>
                      <Typography sx={themeFonts.caption}>
                        Tax rate ={' '}
                        <b>
                          {tax.tax_rate} ({tax.tax_rate * 100}%)
                        </b>
                      </Typography>
                      <Typography sx={themeFonts.caption}>
                        VAT = <b>{formatMoney({ amount: tax.tax_amount })}</b>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box sx={buttonBoxSx}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="secondary"
            name="OK"
            onClick={() => setIsOpen(false)}
            fullWidth
          >
            OK
          </ButtonComponent>
        </Box>
      </Box>
    </DrawerModal>
  );
};
