import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { UserCell } from '@/v2/components/table/user-cell.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunUserHeaderProps = {
  user: CachedUser;
  sx: SxProps<Theme>;
};

export const PayrunUserHeader = ({ user, sx }: PayrunUserHeaderProps) => {
  return (
    <Stack sx={{ gap: spacing.g5, ...sx }}>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>{user.role?.jobTitle}</Typography>
      <UserCell userId={user.userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
    </Stack>
  );
};
