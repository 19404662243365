import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class ApprovalRuleAPI {
  static async addApprovalRule(data: any): Promise<void> {
    await axios.post('/apiv2/approval-rules', data);
  }

  static async updateApprovalRule(approvalRuleId: number, data: any): Promise<void> {
    await axios.patch(`/apiv2/approval-rules/${approvalRuleId}`, data);
  }

  static async deleteApprovalRule(approvalRuleId: number): Promise<void> {
    await axios.delete(`/apiv2/approval-rules/${approvalRuleId}`);
  }
}

export class ApprovalRuleEndpoints {
  static getApprovalRules(showAll = false): Endpoint<ApprovalRuleDto[]> {
    return {
      url: `/apiv2/approval-rules?showAll=${showAll}`,
    };
  }
}
