import { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DeviceAPI } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DeviceOrderDto,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { DeviceOrderStatus, DevicePossessionType, DeviceTransitStatus } from '@v2/feature/device/device.interface';
import { AddExistingDeviceDrawer } from '@v2/feature/device/features/devices-company/components/add-existing-device-drawer.component';
import { MyDevicesEmptyOverview } from '@v2/feature/device/features/my-devices/components/my-devices-empty-overview.component';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { NoAppliedPolicies } from '@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { MyDevicesActive } from '@/v2/feature/device/features/my-devices/components/my-devices-active.component';
import { MyDevicesOrders } from '@/v2/feature/device/features/my-devices/components/my-devices-orders.component';
import { MyDevicesReturns } from '@/v2/feature/device/features/my-devices/components/my-devices-returns.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface MyDevicesOverviewPageProps {
  readonly deviceTransits: readonly DeviceTransitDto[];
  readonly deviceOrders: readonly DeviceOrderDto[];
  readonly refreshDevicesData: () => Promise<void>;
}

export const MyDevicesOverviewPage = ({
  deviceTransits,
  deviceOrders,
  refreshDevicesData,
}: MyDevicesOverviewPageProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddExistingOpen, setIsAddExistingOpen] = useState<boolean>(false);
  const [devicePossessions, setDevicePossessions] = useState<readonly DevicePossessionDto[]>([]);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(
    NoAppliedPolicies
  );
  const [
    appliedZeltMobilePolicies,
    setAppliedZeltMobilePolicies,
  ] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(NoAppliedPolicies);
  const [appliedHexPolicies, setAppliedHexPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [showMessage] = useMessage();

  const hasDevicesEnrollScope = hasScopes(['devices.enroll', 'devices:all'], scopesContext);

  const refreshLocalDevices = useCallback(async () => {
    try {
      setLoading(true);
      const [devicePossessions, zeltPolicies, zeltMobilePolicies, hexPolicies] = await Promise.all([
        DeviceAPI.getActiveDevicePossessionsByUserId(userId),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile(),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
      ]);
      setDevicePossessions(devicePossessions);
      setAppliedZeltPolicies(zeltPolicies);
      setAppliedZeltMobilePolicies(zeltMobilePolicies);
      setAppliedHexPolicies(hexPolicies);
    } catch (error) {
      showMessage('Could not load user devices list', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId, showMessage]);

  useEffect(() => {
    refreshLocalDevices();
  }, [refreshLocalDevices]);

  const notifiableDeviceOrders = deviceOrders.some((deviceOrder) =>
    [
      DeviceOrderStatus.Requested,
      DeviceOrderStatus.Placed,
      DeviceOrderStatus.Accepted,
      DeviceOrderStatus.Shipping,
    ].includes(deviceOrder.status)
  );

  const validShippingOrders = deviceOrders.filter((d) => d.status !== DeviceOrderStatus.Cancelled);
  const nonOrderTransits = deviceTransits.filter((deviceTransit) => {
    return (
      (deviceTransit.status === DeviceTransitStatus.Pending || deviceTransit.status === DeviceTransitStatus.Shipping) &&
      deviceTransit.sender?.possessionType !== DevicePossessionType.ZeltStock
    );
  });
  const notifiableDeviceTransits = Boolean(nonOrderTransits?.length > 0);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>}
        showAction={devicePossessions?.length > 0 || notifiableDeviceOrders || notifiableDeviceTransits}
        actions={
          <ScopesControl scopes={['devices.enroll', 'devices:all']} context={scopesContext}>
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="small"
              onClick={() => setIsAddExistingOpen(true)}
              disabled={!hasDevicesEnrollScope}
            >
              Add existing
            </ButtonComponent>
            {isAddExistingOpen && (
              <AddExistingDeviceDrawer
                isOpen={isAddExistingOpen}
                setIsOpen={setIsAddExistingOpen}
                refresh={async () => {
                  await refreshDevicesData();
                  await refreshLocalDevices();
                  setIsAddExistingOpen(false);
                }}
              />
            )}
          </ScopesControl>
        }
      />
      <ContentWrapper loading={loading} sx={{ pt: spacing.p30 }}>
        {devicePossessions?.length > 0 || notifiableDeviceOrders || notifiableDeviceTransits ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
              justifyContent: 'space-between',
              gap: spacing.g60,
            }}
          >
            <Box sx={{ width: '100%', maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '70%', xl: '70%' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: spacing.g60,
                }}
              >
                {validShippingOrders?.length > 0 && (
                  <MyDevicesOrders deviceOrders={validShippingOrders} refreshDevicesData={refreshDevicesData} />
                )}
                {nonOrderTransits?.length > 0 && (
                  <MyDevicesReturns
                    deviceTransits={nonOrderTransits}
                    refreshDevicesData={refreshDevicesData}
                    userId={userId}
                  />
                )}
                {devicePossessions?.length > 0 && (
                  <MyDevicesActive
                    devicePossessions={devicePossessions}
                    appliedZeltPolicies={appliedZeltPolicies}
                    appliedZeltMobilePolicies={appliedZeltMobilePolicies}
                    appliedHexPolicies={appliedHexPolicies}
                    userId={userId}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <MyDevicesEmptyOverview
            refresh={async () => {
              await refreshDevicesData();
              await refreshLocalDevices();
              setIsAddExistingOpen(false);
            }}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
