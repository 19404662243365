import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import { PaymentFailedBanner } from '@v2/feature/app-layout/components/payment-failed-banner.component';
import { TestModeBanner } from '@v2/feature/app-layout/components/test-mode-banner.component';
import { MainRouter } from '@v2/feature/app-layout/features/main-content/main.router';
import { CachedUsersProvider } from '@v2/feature/user/context/cached-users.context';
import { useSetLocalTimeHeaderInterceptor } from '@v2/infrastructure/api-client/api-client-request-interceptor.hook';
import { useApiErrorInterceptor } from '@v2/infrastructure/api-error/api-error-interceptor.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import axios from 'axios';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { MessageWrapper } from '@/component/widgets/Message';
import { GlobalStateProvider } from '@/GlobalState';
import { LOGIN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX, USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { AccountStatus } from '@/lib/users';
import { CurrentUser } from '@/models';
import { MenuSection } from '@/v2/feature/app-layout/features/v2/menu/menu.section';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthMeUser, TEMPLATE_CONTRACT_COMPANY_SIGN_PREFIX } from '@/v2/feature/auth/auth.interface';
import { TrialExpiredLayer } from '@/v2/feature/trial/components/trial-expired-layer.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { ErrorNotificationProvider } from '@/v2/infrastructure/error-notification-provider.context';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { showSidebar } from '@/v2/util/app-layout.util';

export const LayoutRootStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const DashboardLayout = (): React.JSX.Element => {
  const { replacePhrasesOnly, setUserLanguage } = usePolyglot();

  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const { identifyUser } = useJune();

  const [user, setUser] = useState<CurrentUser | null>(null);
  const [rawUserResponse, setRawUserResponse] = useState<AuthMeUser | null>(null);
  const [showHiddenFeatures, setShowHiddenFeatures] = useState<boolean | null>(null); // This must remain initiated with null, if true or false it means the /me request received the response
  const [paymentFailedFeature, setPaymentFailedFeature] = useState<boolean | null>(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  useApiErrorInterceptor();
  useSetLocalTimeHeaderInterceptor();

  const userIdFromUserContractSigningPath = useMemo((): number | null => {
    const currentPath = routerHistory?.location?.pathname;
    const isContractSigningPath = Boolean(currentPath?.includes(TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX));
    const userSigningContract = Number(currentPath.substring(currentPath.lastIndexOf('/') + 1));
    if (!isContractSigningPath || !userSigningContract) return null;

    return userSigningContract;
  }, [routerHistory?.location?.pathname]);

  const isUserSigningContract = useCallback(
    (userId: number) => {
      if (!userId) return false;
      return userIdFromUserContractSigningPath === userId;
    },
    [userIdFromUserContractSigningPath]
  );

  // split up fetching of user into its own callback - all other side effects can happen separately
  const fetchUser = useCallback(async () => {
    try {
      const response = await AuthAPI.getAuthMe(false);
      setRawUserResponse(response);
      Sentry.setUser({
        id: `${response.user.userId}`,
        companyId: response.user.company.companyId,
      });
    } catch (error) {
      const redirectUri =
        routerLocation?.pathname && routerLocation.pathname?.length > 1
          ? `${routerLocation.pathname}${routerLocation.search}`
          : null;

      if (error.response && error.response.status === 401) {
        let loginRouteWithRedirect = LOGIN_ROUTE;
        let redirectIsContractSigningLink = false;
        let contractUserPwdSet;
        if (redirectUri) {
          loginRouteWithRedirect = `${LOGIN_ROUTE}?redirect=${encodeURIComponent(redirectUri)}`;
          redirectIsContractSigningLink =
            redirectUri.includes(TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX) ||
            redirectUri.includes(TEMPLATE_CONTRACT_COMPANY_SIGN_PREFIX);
          if (redirectIsContractSigningLink) {
            const contractIdRedirectParams = redirectUri.split('/');
            const contractId = contractIdRedirectParams[contractIdRedirectParams.length - 2];
            if (contractId) {
              contractUserPwdSet = await AuthAPI.isContractRecipientPasswordSet(contractId);
            }
          }
        }
        routerHistory.push(
          loginRouteWithRedirect,
          redirectIsContractSigningLink
            ? { contractRecipientHasSetPassword: contractUserPwdSet, contractSigningRedirect: true }
            : { contractSigningRedirect: false }
        );
      } else {
        throw error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!rawUserResponse) return;
    const response = rawUserResponse;
    if (
      response.user.accountStatus === AccountStatus.InvitedToOnboard &&
      !isUserSigningContract(response?.user?.userId)
    ) {
      routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId: response.user.userId }));
      return;
    }

    const showHiddenFeatures = response.showHiddenFeatures;
    const isSuperAdmin = Boolean(response && response.isSuperAdmin) ?? false;
    setUser(response.user);
    setIsSuperAdmin(isSuperAdmin);
    // this should be set last for all the routes to work
    setShowHiddenFeatures(showHiddenFeatures);
    setPaymentFailedFeature(response.hasFailedPayment);
  }, [isUserSigningContract, rawUserResponse, routerHistory]);

  useEffect(() => {
    (async () => {
      if (rawUserResponse) {
        await replacePhrasesOnly();
        setUserLanguage(rawUserResponse.user.language);
      }
    })();
  }, [rawUserResponse, replacePhrasesOnly, setUserLanguage]);

  useEffect(() => {
    if (user) identifyUser({ user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // these are front loaded to decrease wait times across the app
    UserAPI.fetchUserCache(true, false);
  });

  return (
    <LayoutRootStyle>
      {paymentFailedFeature && <PaymentFailedBanner />}
      {user && (
        <GlobalStateProvider
          initialState={{
            user,
            loggedOut: true,
            showHiddenFeatures,
            message: [],
            paymentFailed: false,
            inspectorMode: false,
            drawerState: false,
            isTestModeEnabled: false,
            trialMode: { enabled: false, lapsed: false },
            alerts: {},
            showSettingBar: false,
          }}
        >
          <ErrorNotificationProvider axios={axios}>
            <CachedUsersProvider>
              <TestModeBanner />
              <TrialExpiredLayer />
              {showSidebar(routerLocation?.pathname) && <MenuSection />}
              {showHiddenFeatures !== null && rawUserResponse ? (
                <MainRouter isSuperAdmin={isSuperAdmin} userAPIResponse={rawUserResponse} />
              ) : null}
            </CachedUsersProvider>
            <MessageWrapper />
          </ErrorNotificationProvider>
        </GlobalStateProvider>
      )}
    </LayoutRootStyle>
  );
};
