import { OptionObject } from '@/v2/components/forms/select.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { AccountingOrganisation } from '@/v2/feature/payroll/payroll.interface';

export enum PaymentCategoryEnum {
  EXPENSE = 'Expense',
  INVOICE = 'Invoice',
}

export const isExpenseCheck = (path: string) => path.includes('expenses');

export const PaymentCategoryOptions = Object.values(PaymentCategoryEnum).map((category) => ({
  value: category,
  label: category,
}));

export type SyncToExternalType = 'on_approval' | 'manual';

export interface PaymentTypeSettingsApprovalUpdate {
  approvalRuleId: number | null;
}

export interface AccountingCodeConfig {
  accountingCode: number;
  accountingCodeDescription: string;
}

export interface PaymentTypeSettings {
  id: number;
  companyId?: number;
  type: PaymentCategoryEnum;
  name: string;
  accountingCode: number | null;
  accountingCodeDescription: string;
  accountingCodeConfig: AccountingCodeConfig[] | null;
  provider: string | null;
  payrolled: boolean | null;
  paycode: string | null;
  syncToExternal: SyncToExternalType;
  externalSyncOrganisation: AccountingOrganisation | null;
  approvalRuleId: number | null;
  membersRule: UserSelectFiltersOptions;
  requireAttachment: boolean;
  selectedMembersIds?: number[];
  viaBenefit?: number[];
  customRule: string | null;
  createdBy?: number;
  updatedBy?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export type PaymentTypeSettingsUpsert = Omit<PaymentTypeSettings, 'id'>;

export type AccountingSettingUpdate = Pick<
  PaymentTypeSettings,
  'accountingCode' | 'accountingCodeDescription' | 'accountingCodeConfig'
>;

export type GeneralSettingUpdate = Pick<PaymentTypeSettings, 'name' | 'requireAttachment'>;

export type SyncSettingUpdate = Pick<PaymentTypeSettings, 'externalSyncOrganisation' | 'syncToExternal'>;

export type PaymentTypeMembersFormData = Pick<PaymentTypeSettings, 'membersRule' | 'customRule' | 'selectedMembersIds'>;

export interface PaymentTypeSettingAccountingAppConfig {
  settings: PaymentTypeSettings[];
  accountingAppConfigured: boolean;
}

export interface PaymentTypeApprovalSettingsForUpdate {
  idForExpense: number | null;
  expenseApprovalRuleId: number | null;
  idForInvoice: number | null;
  invoiceApprovalRuleId: number | null;
}

export interface ExternalAccountingCode {
  description: string;
  code: string;
  name: string;
}

export enum PayrolledOptionsEnum {
  PayViaPayments = 'Pay via payments',
  PayViaPayroll = 'Pay via payroll',
}

export enum SyncToExternalOptionsEnum {
  on_approval = 'On approval',
  manual = 'Manual',
}

export const SyncToExternalOptionList: OptionObject[] = [
  { label: SyncToExternalOptionsEnum.on_approval, value: 'on_approval' },
  { label: SyncToExternalOptionsEnum.manual, value: 'manual' },
];
