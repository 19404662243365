import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import {
  EditableUserFamilyMemberDto,
  UserFamilyMemberDto,
} from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserAPI } from '@v2/feature/user/user.api';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { translateUserFamilyMemberType } from '@/v2/infrastructure/i18n/translate.util';

interface AddUserFamilyMemberDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly member: UserFamilyMemberDto | null;
  readonly userId: number;
  readonly type: UserFamilyMemberType;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}

export const AddUserFamilyMemberDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  member,
  onClose,
  type,
  refresh,
}: AddUserFamilyMemberDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <AddUserFamilyMemberDrawerContent
        userId={userId}
        type={type}
        refresh={refresh}
        setIsOpen={setIsOpen}
        member={member}
      />
    </DrawerModal>
  );
};

interface AddUserFamilyMemberDrawerContentProps {
  readonly userId: number;
  readonly type: UserFamilyMemberType;
  readonly member: UserFamilyMemberDto | null;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddUserFamilyMemberDrawerContent = ({
  type,
  refresh,
  member,
  userId,
  setIsOpen,
}: AddUserFamilyMemberDrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const upsertMember = useCallback(
    async (values: EditableUserFamilyMemberDto) => {
      try {
        setLoading(true);
        if (member)
          await UserAPI.updateUserFamilyMemberById(userId, member.id, {
            type: values.type,
            name: values.name,
            dob: values.dob ? values.dob : null,
            gender: values.gender ? values.gender : null,
          });
        else
          await UserAPI.addUserFamilyMember(userId, {
            type: values.type,
            name: values.name,
            dob: values.dob ? values.dob : null,
            gender: values.gender ? values.gender : null,
          });

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          `${polyglot.t('AddUserFamilyMemberDrawer.errorMessages.badRequest')}. ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, userId, member, refresh, setIsOpen, showMessage]
  );

  const formik = useFormik<EditableUserFamilyMemberDto>({
    initialValues: {
      type,
      name: member?.name ?? '',
      dob: member?.dob ?? '',
      gender: member?.gender ?? '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(polyglot.t('AddUserFamilyMemberDrawer.errorMessages.memberRequired')),
      name: Yup.string().required(polyglot.t('AddUserFamilyMemberDrawer.errorMessages.nameRequired')),
      dob: Yup.string().test(dateFieldTest).nullable().notRequired(),
      gender: Yup.string().nullable().notRequired(),
    }),
    onSubmit: upsertMember,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>
          {polyglot.t('AddUserFamilyMemberDrawer.details', { type: translateUserFamilyMemberType(type, polyglot) })}
        </Typography>

        <Box sx={fieldSx}>
          <TextfieldComponent
            name="name"
            label={polyglot.t('AddUserFamilyMemberDrawer.name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={!!formik.errors.name && formik.touched.name}
            helperText={formik.touched.name && (formik.errors.name as string)}
          />
        </Box>

        <Box sx={fieldSx}>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.dob ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) formik.setFieldValue('dob', value);
              else formik.setFieldValue('dob', '');
            }}
            disableFuture
            name="dob"
            label={polyglot.t('AddUserFamilyMemberDrawer.dateOfBirth')}
            error={!!formik.errors.dob && formik.touched.dob}
            helperText={(formik.touched.dob && formik.errors.dob) as string}
          />
        </Box>

        <Box sx={fieldSx}>
          <SelectComponent
            name="gender"
            label={polyglot.t('AddUserFamilyMemberDrawer.gender')}
            options={[
              { label: polyglot.t('AddUserFamilyMemberDrawer.male'), value: 'male' },
              { label: polyglot.t('AddUserFamilyMemberDrawer.female'), value: 'female' },
              { label: polyglot.t('AddUserFamilyMemberDrawer.preferNotToSay'), value: 'preferNotToSay' },
            ]}
            value={formik.values.gender}
            onChange={formik.handleChange}
            compareValue={formik.values.gender}
            error={!!formik.errors.gender && formik.touched.gender}
            helperText={formik.touched.gender && (formik.errors.gender as string)}
          />
        </Box>

        <Box sx={{ mt: spacing.m40 }}>
          <LoaderButton
            name={member ? polyglot.t('General.save') : polyglot.t('General.add')}
            loading={loading}
            fullWidth
            sizeVariant="large"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
