// eslint-disable-next-line filenames/match-regex
export const extractFilters = (filterString: string): { [key: string]: Array<string> } | null => {
  const filters = filterString.split('&');

  if (!filters.length) return null;

  let finalFilter: { [key: string]: Array<string> } = { userStatuses: [], accountTypes: [] };

  const STATUS_FILTER = 'Status=';
  const ROLE_FILTER = 'Role=';

  for (const filter of filters) {
    if (filter.startsWith(STATUS_FILTER)) {
      finalFilter.userStatuses = filter.split(STATUS_FILTER)[1].split(',');
    }

    if (filter.startsWith(ROLE_FILTER)) {
      finalFilter.accountTypes = filter.split(ROLE_FILTER)[1].split(',');
    }
  }

  return finalFilter;
};
