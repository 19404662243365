import React from 'react';

import { Box, IconButton } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { SmallCircle } from '@v2/feature/absence/subfeatures/settings/components/draggable-absence-policies.component';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableWhiteIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as ProfiBig } from '@/images/benefits/ProfiBig.svg';
import { ReactComponent as NewWindow } from '@/images/new-theme-icon/NewWindow.svg';

const LOGO_SIZE = '40px';

interface ComponentProps {
  readonly userCustomBenefit: UserCustomBenefitDto;
}

export const UserBenefitOverviewTitle = ({ userCustomBenefit }: ComponentProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {userCustomBenefit?.customBenefit?.url ? (
          <img
            src={userCustomBenefit.customBenefit.url}
            style={{ width: LOGO_SIZE, height: LOGO_SIZE, borderRadius: '50%' }}
            alt="benefit-logo"
          />
        ) : (
          <ProfiBig style={{ width: LOGO_SIZE, height: LOGO_SIZE, borderRadius: '50%' }} />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: '10px' }}>
        <Typography variant="title2">
          {userCustomBenefit?.customBenefit?.name ?? polyglot.t('BenefitModule.benefit')}
        </Typography>
        <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
          <Typography variant="caption">{userCustomBenefit?.customBenefit?.category}</Typography>
          {userCustomBenefit?.customBenefit?.description && <SmallCircle />}
          {userCustomBenefit?.customBenefit?.description && (
            <Typography
              variant="caption"
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {userCustomBenefit.customBenefit.description}
            </Typography>
          )}
          {userCustomBenefit?.customBenefit?.benefitLink && <SmallCircle />}
          {userCustomBenefit?.customBenefit?.benefitLink && (
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
              <Typography variant="caption">{polyglot.t('BenefitModule.loginLink')}</Typography>
              <IconButton
                sx={{ ...tableWhiteIconButtonSx, p: 0 }}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(userCustomBenefit?.customBenefit?.benefitLink!, '_blank', 'noopener noreferrer');
                }}
              >
                <NewWindow {...iconSize} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
