import React, { useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthScale } from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@/v2/styles/spacing.styles';

interface GrowthTypeTable {
  points: string;
  value: number;
  sentiments: string;
}
export const GrowthScaleViewModal = ({
  isOpen,
  setIsOpen,
  growthScale,
  onClose,
  afterClose,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly growthScale: GrowthScale | undefined;
  readonly onClose: () => void;
  readonly afterClose: () => void;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose} sx={{ px: 0 }}>
      <GrowthScaleViewContent growthScale={growthScale} />
    </DrawerModal>
  );
};

const GrowthScaleViewContent = ({ growthScale }: { readonly growthScale: GrowthScale | undefined }) => {
  const transformedData: GrowthTypeTable[] = growthScale
    ? Object.keys(growthScale.points).map((key) => ({
        points: growthScale.points[key],
        value: growthScale.value[key],
        sentiments: growthScale.sentiments[key],
      }))
    : [];

  const tableColumns = useMemo<ColumnDef<GrowthTypeTable, GrowthTypeTable>[]>(
    () => [
      {
        header: () => 'Points',
        accessorFn: (row) => row,
        id: 'point',
        enableSorting: false,

        cell: ({
          row: {
            original: { points },
          },
        }) => (points ? <div>{points} </div> : <EmptyCell />),
        minSize: 170,
        maxSize: 200,
      },
      {
        header: () => 'Values',
        accessorFn: (row) => row,
        id: 'value',
        enableSorting: false,

        cell: ({
          row: {
            original: { value },
          },
        }) => (value ? <div>{value} </div> : <EmptyCell />),
        minSize: 30,
        maxSize: 40,
      },

      {
        header: () => 'Sentiments',
        accessorFn: (row) => row,
        id: 'sentiments',
        enableSorting: false,

        cell: ({
          row: {
            original: { sentiments },
          },
        }) => (sentiments ? <div>{sentiments} </div> : <EmptyCell />),
        minSize: 60,
        maxSize: 80,
      },
    ],
    []
  );

  if (!growthScale)
    return (
      <Box sx={{ ...drawerContentSx, px: spacing.s2 }}>
        <Typography variant="title2">Scale</Typography>
        <Typography variant="caption" color="Grey">
          Scale information not found
        </Typography>
      </Box>
    );
  return (
    <Box style={drawerContentSx}>
      <Typography variant="title2" sx={{ px: spacing.s2 }}>
        {growthScale.type}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1, px: spacing.s2 }}>
        <Typography variant="title4">Created by</Typography>
        {growthScale.companyId === null ? (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
            <PayrollLogoZelt height="1em" width="1em" padding=".25em" />
            <Typography variant="caption">Zelt</Typography>
          </Stack>
        ) : growthScale.createdBy ? (
          <UserCell userId={growthScale.createdBy} />
        ) : (
          <EmptyCell />
        )}
      </Box>

      <Box sx={{ marginTop: spacing.s3 }}>
        <BasicTable rowData={transformedData ?? []} columnData={tableColumns} hidePagination style={{ padding: 0 }} />
      </Box>
    </Box>
  );
};
