import { useCallback, useEffect, useState } from 'react';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { parentCardSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';

import { BillingFailedPaymentAPI } from '@/api-client/billing-failed-payment.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ErrorIcon } from '@/images/side-bar-icons/Mistake.svg';
import { nestErrorMessage } from '@/lib/errors';
import { FailedPayment } from '@/models/billing.model';
import { IconContentActionCard } from '@/v2/components/theme-components/icon-content-action-card.component';
import { primarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const FailedPaymentAlert = () => {
  const [failedPayments, setFailedPayments] = useState<FailedPayment[]>([]);

  const [showMessage] = useMessage();
  const [isRetryingPayments, setIsRetryingPayments] = useState(false);

  const fetchFailedPayments = useCallback(async () => {
    try {
      const failedPayments = await BillingFailedPaymentAPI.getFailedPayments();
      setFailedPayments(failedPayments);
    } catch (error) {
      showMessage(`Could not fetch the list of failed payments. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  useEffect(() => {
    fetchFailedPayments();
  }, [fetchFailedPayments]);

  const retryPayment = async (params: { failedPaymentId: number; invoiceId: string }) => {
    try {
      setIsRetryingPayments(true);

      const { failedPaymentId, invoiceId } = params;
      await BillingFailedPaymentAPI.retryFailedPayment({ failedPaymentId });
      const remainingFailedPayments = await BillingFailedPaymentAPI.getFailedPayments();
      setFailedPayments(remainingFailedPayments);

      showMessage(`Charge successful for invoice No. ${invoiceId}`, 'success');

      setIsRetryingPayments(false);
    } catch (error) {
      const defaultErrorMessage = 'Failed to retry payment';
      showMessage(`${nestErrorMessage(error) || defaultErrorMessage}`, 'error');
      setIsRetryingPayments(false);
    }
  };

  return failedPayments.length > 0 ? (
    <Box sx={{ ...spacing.px40, ...parentCardSx, pb: spacing.m40 }}>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Alerts</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'left',
          }}
        >
          {failedPayments.map((failedPayment) => (
            <Box sx={{ mt: 2 }}>
              <IconContentActionCard
                title={`Payment for Invoice No. ${failedPayment.invoiceId} failed`}
                content="The automated payment for your subscription has failed. Please try again."
                iconMedia={<ErrorIcon {...iconSize} fill={themeColors.Red} />}
                action={
                  <Button
                    sx={{ ...primarySmallBtn, width: '100px' }}
                    fullWidth
                    variant="contained"
                    onClick={async () =>
                      await retryPayment({ failedPaymentId: failedPayment.id, invoiceId: failedPayment.invoiceId })
                    }
                    disabled={isRetryingPayments}
                  >
                    Retry
                  </Button>
                }
                showDivider={true}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  ) : null;
};
