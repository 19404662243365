import { Redirect, Switch } from 'react-router-dom';

import { SelectBankForm } from './features/make-payment/components/select-bank-form-step.component';
import { CreatePaymentPage } from './features/make-payment/pages/create-payment.page';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import {
  PAYMENTS_TEAM_CREATE_ROUTE,
  PAYMENTS_TEAM_SELECT_BANK_ROUTE,
  PAYMENT_TEAM_NEW_INVOICE_ROUTE,
  PAYMENT_TEAM_OVERVIEW_ROUTE,
  PAYMENT_TEAM_ROUTE,
} from '@/lib/routes';
import { NewInvoicePage } from '@/v2/feature/payments/pages/new-invoice.page';
import { PaymentsTeamPage } from '@/v2/feature/payments/pages/payments.team.page';

export const PaymentsTeamRouter = ({ isManager }: { readonly isManager: boolean }) => {
  return (
    <Switch>
      {isManager && (
        <RouteScopesHas scopes={['payments:manager']} path={PAYMENT_TEAM_OVERVIEW_ROUTE} exact>
          <PaymentsTeamPage />
        </RouteScopesHas>
      )}
      {isManager && (
        <RouteScopesHas scopes={['payments:manager']} path={PAYMENTS_TEAM_SELECT_BANK_ROUTE}>
          <SelectBankForm />
        </RouteScopesHas>
      )}

      {isManager && (
        <RouteScopesHas scopes={['payments:manager']} path={PAYMENTS_TEAM_CREATE_ROUTE}>
          <CreatePaymentPage />
        </RouteScopesHas>
      )}

      {isManager && (
        <RouteScopesHas scopes={['payments:manager']} path={PAYMENT_TEAM_NEW_INVOICE_ROUTE} exact>
          <NewInvoicePage />
        </RouteScopesHas>
      )}

      <Redirect to={PAYMENT_TEAM_OVERVIEW_ROUTE} from={PAYMENT_TEAM_ROUTE} />
    </Switch>
  );
};
