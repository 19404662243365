// TODO move to a different location as it's overloaded for both company and team views
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { PaginationState } from '@tanstack/react-table';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsenceDto, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { AbsenceEmpty } from '@v2/feature/absence/company/policies/pages/absence-empty.page';
import { AbsenceDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import { AbsenceViewDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page';
import { AbsenceTable, getInitialFilterString } from '@v2/feature/absence/sections/absence-table.section';
import { AbsenceUserFilterFeaturesUpdater } from '@v2/feature/absence/subfeatures/settings/components/absence-user-filter-feature-updater.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Reject.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { iconSize } from '@/v2/styles/menu.styles';

interface Props {
  readonly absencePolicies: readonly AbsencePolicyDto[];
  readonly reach: 'company' | 'team';
  readonly absencePoliciesLoading: boolean;
}

export const AbsenceRequestsPage = ({ absencePolicies, reach, absencePoliciesLoading }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const { hasScopes, getScopesContext } = useScopes();

  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { user } = globalState;
  const context = getScopesContext(user);

  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState<boolean>(false);
  const [isViewAbsenceDrawerOpen, setIsViewAbsenceDrawerOpen] = useState<boolean>(false);
  const [absences, setAbsences] = useState<readonly AbsenceDto[]>([]);
  const [drawerAbsence, setDrawerAbsence] = useState<AbsenceDto | undefined>(undefined);
  const [viewAbsence, setViewAbsence] = useState<AbsenceDto | undefined>(undefined);

  const { trackPage } = useJune();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showMessage] = useMessage();
  const isAdmin = reach === 'company' && hasScopes(['absence:all'], context);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  const initialFilterString = useMemo(
    () =>
      getInitialFilterString(
        (isAdmin
          ? user?.features?.timeAway?.requestsTable?.companyFilters
          : user?.features?.timeAway?.requestsTable?.teamFilters) ?? 'Calendar=current',
        absencePolicies
      ),
    [user, absencePolicies, isAdmin]
  );

  const [filterString, setFilterString] = useState<string>(initialFilterString);
  const [searchInput, setSearchInput] = useState<string>('');

  const setAbsenceRecordsFromServer = useCallback(async () => {
    try {
      const absencesResponse = isAdmin
        ? await AbsenceAPI.fetchAllAbsences(pagination.pageIndex, pagination.pageSize, filterString, searchInput)
        : await AbsenceAPI.fetchManagerTreeAbsences(
            pagination.pageIndex,
            pagination.pageSize,
            filterString,
            searchInput
          );

      setAbsences(absencesResponse.items);
      setTotalPages(absencesResponse.totalPages);
      setTotalItems(absencesResponse.totalItems);
      setDrawerAbsence(undefined);
      setIsViewAbsenceDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceRequestsPage.errorMessages.fetch', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, isAdmin, pagination.pageIndex, pagination.pageSize, filterString, searchInput, showMessage]);

  const removeRequestHelper = useCallback(
    async (absence: AbsenceDto) => {
      if (absence?.absenceId) {
        try {
          await AbsenceAPI.deleteAbsenceRecord(absence.absenceId);
          showMessage(polyglot.t('AbsenceRequestsPage.successMessages.remove'), 'success');
          await setAbsenceRecordsFromServer();
        } catch (error) {
          showMessage(
            polyglot.t('AbsenceRequestsPage.errorMessages.remove', { nestErrorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      }
    },
    [polyglot, setAbsenceRecordsFromServer, showMessage]
  );

  const decideAbsence = useCallback(
    async (absenceId: number, decision: AbsenceStatus, rejectionNotes?: string): Promise<AbsenceDto | undefined> => {
      let updatedAbsence = undefined;

      try {
        if (decision === AbsenceStatus.Approved) {
          const updatedAbsences = await AbsenceAPI.approveAllAbsences([absenceId]);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t('AbsenceRequestsPage.successMessages.approve'), 'success');
        } else {
          const updatedAbsences = await AbsenceAPI.rejectAllAbsences([absenceId], rejectionNotes);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t('AbsenceRequestsPage.successMessages.reject'), 'success');
        }
        await setAbsenceRecordsFromServer();
        const alertsForAbsences = await AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences },
        });
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceRequestsPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
      return updatedAbsence;
    },
    [polyglot, globalDispatch, setAbsenceRecordsFromServer, showMessage, user.userId]
  );

  const handleApprove = useCallback((a: AbsenceDto) => decideAbsence(a.absenceId, AbsenceStatus.Approved), [
    decideAbsence,
  ]);

  const handleReject = useCallback(
    (a: AbsenceDto, rejectionNotes?: string) => decideAbsence(a.absenceId, AbsenceStatus.Rejected, rejectionNotes),
    [decideAbsence]
  );

  const handleRejectCancellation = useCallback(
    async (a: AbsenceDto) => {
      try {
        await AbsenceAPI.rejectTimeCancellationRequests([a.absenceId], a.userId);
        showMessage(polyglot.t('AbsenceRequestsPage.successMessages.rejectCancellation'), 'success');
        await setAbsenceRecordsFromServer();
        const alertsForAbsences = await AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences },
        });
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceRequestsPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage, setAbsenceRecordsFromServer, user.userId, globalDispatch]
  );

  const onForceApproval = useCallback(
    async (a: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => {
      try {
        await AbsenceAPI.forceApprovalByAbsencesIds([a.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved
              ? 'AbsenceRequestsPage.successMessages.approve'
              : 'AbsenceRequestsPage.successMessages.reject'
          ),
          'success'
        );
        await setAbsenceRecordsFromServer();
        const alertsForAbsences = await AbsenceAPI.getAlerts(user.userId);
        globalDispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { absences: alertsForAbsences },
        });
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceRequestsPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage, setAbsenceRecordsFromServer, user.userId, globalDispatch]
  );

  const handleRowClicked = useCallback((original: AbsenceDto) => {
    setViewAbsence(original);
    setIsViewAbsenceDrawerOpen(true);
  }, []);

  useEffect(() => {
    (async function () {
      setLoadingData(true);
      try {
        await setAbsenceRecordsFromServer();
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceRequestsPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoadingData(false);
      }
    })();
  }, [polyglot, showMessage, setAbsenceRecordsFromServer]);

  const handleApproveAll = async () => {
    try {
      await AbsenceAPI.approveAllAbsences(selectionModel);
      setSelectionModel([]);
      await setAbsenceRecordsFromServer();
      showMessage(polyglot.t('AbsenceRequestsPage.successMessages.approveAll'), 'success');
    } catch (error) {
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const handleRejectAll = async () => {
    try {
      await AbsenceAPI.rejectAllAbsences(selectionModel);
      setSelectionModel([]);
      await setAbsenceRecordsFromServer();
      showMessage(polyglot.t('AbsenceRequestsPage.successMessages.rejectAll'), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceRequestsPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const getAbsenceActions = () => {
    return [
      {
        icon: <Chose {...iconSize} />,
        handler: () => handleApproveAll(),
        label: polyglot.t('AbsenceRequestsPage.approveAll'),
        disabled: false,
      },
      {
        icon: <Cancel {...iconSize} />,
        handler: () => handleRejectAll(),
        label: polyglot.t('AbsenceRequestsPage.rejectAll'),
        disabled: false,
      },
    ];
  };

  useEffect(() => {
    trackPage('Time Company requests page');
  }, [trackPage]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2" color="DarkGrey">
            {polyglot.t('AbsenceRequestsPage.requests')}
          </Typography>
        }
        showAction={true} // true only if it has scope
        actions={
          <ScopesControl scopes={['absence']} context={context}>
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={() => {
                setIsAbsenceDrawerOpen(true);
                setDrawerAbsence(undefined);
              }}
            >
              {polyglot.t('AbsenceRequestsPage.newRequest')}
            </ButtonComponent>
          </ScopesControl>
        }
      />
      <ContentWrapper
        loading={absencePoliciesLoading}
        sx={absencePolicies && absencePolicies.length > 0 ? spacing.pt20 : { height: '100%' }}
      >
        {absencePolicies && absencePolicies.length > 0 ? (
          <Box>
            <AbsenceTable
              onRowClick={handleRowClicked}
              rows={absences}
              showUserColumn
              loading={loadingData}
              onEdit={(absence: AbsenceDto) => {
                setDrawerAbsence(absence);
                setIsAbsenceDrawerOpen(true);
              }}
              onDelete={removeRequestHelper}
              onApprove={handleApprove}
              onReject={handleReject}
              onRejectCancellation={handleRejectCancellation}
              onForceApproval={onForceApproval}
              newRequestButton={
                selectionModel.length > 0 && (
                  <StyledMenuComponent
                    options={getAbsenceActions()}
                    actionButtonDetails={{
                      type: 'button',
                      colorVariant: 'secondary',
                      sizeVariant: 'small',
                      title: polyglot.t('AbsenceRequestsPage.actions'),
                      icon: <ArrowDown {...iconSize} />,
                      iconPosition: 'end',
                    }}
                  />
                )
              }
              setSelectionModel={setSelectionModel}
              selectionModel={selectionModel}
              pagination={pagination}
              setPagination={setPagination}
              totalPages={totalPages}
              totalItems={totalItems}
              searchInput={searchInput}
              setDebouncedSearchInput={(value: string) => {
                setSearchInput(!value ? '' : value);
                // Resets pagination index once we perform a new search
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
              filterString={filterString}
              setFilterString={setFilterString}
              view={reach}
              userId={undefined}
            />
            {isAbsenceDrawerOpen && (
              <AbsenceDrawer
                isOpen={isAbsenceDrawerOpen}
                onClose={() => {
                  setIsAbsenceDrawerOpen(false);
                  setDrawerAbsence(undefined);
                }}
                refresh={setAbsenceRecordsFromServer}
                absence={drawerAbsence}
                setIsAbsenceDrawerOpen={setIsAbsenceDrawerOpen}
                reach={reach}
              />
            )}

            {viewAbsence && isViewAbsenceDrawerOpen && !isAbsenceDrawerOpen && (
              <AbsenceViewDrawer
                isOpen={isViewAbsenceDrawerOpen}
                absence={viewAbsence}
                setAbsence={setViewAbsence}
                afterClose={() => setViewAbsence(undefined)}
                setIsAbsenceDrawerViewOpen={setIsViewAbsenceDrawerOpen}
                handleApprove={handleApprove}
                handleReject={handleReject}
                onDelete={removeRequestHelper}
                onRejectCancellation={handleRejectCancellation}
                onForceApproval={onForceApproval}
                onEdit={(absence: AbsenceDto) => {
                  setDrawerAbsence(absence);
                  setIsAbsenceDrawerOpen(true);
                  setIsViewAbsenceDrawerOpen(false);
                }}
                refresh={setAbsenceRecordsFromServer}
                showCalendarLink
              />
            )}
          </Box>
        ) : (
          <AbsenceEmpty />
        )}
        <AbsenceUserFilterFeaturesUpdater
          filterString={filterString}
          initialFilterString={initialFilterString}
          globalDispatch={globalDispatch}
          view={isAdmin ? 'company' : 'team'}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
