import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { HelperAbsencePolicyDrawer } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';

export const HelperAbsencePoliciesTable = ({
  absencePolicies,
  isLoadingPolicies,
  users,
}: {
  readonly absencePolicies: AbsencePolicyDto[];
  readonly isLoadingPolicies: boolean;
  readonly users: readonly UserDetailsSuperAdminDto[];
}) => {
  const [isPolicyDrawerOpen, setIsPolicyDrawerOpen] = useState<boolean>(false);
  const [selectedPolicyToShowInDrawer, setSelectedPolicyToShowInDrawer] = useState<AbsencePolicyDto | null>(null);

  const policyTableColumns = useMemo<ColumnDef<AbsencePolicyDto, AbsencePolicyDto>[]>(
    () => [
      {
        header: () => 'ID',
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.id}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.name}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'Full name',
        accessorFn: (row) => row,
        id: 'fullName',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.fullName}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'CarryOverEnabled',
        accessorFn: (row) => row,
        id: 'carryOverEnabled',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.carryOverEnabled ? 'True' : 'False'}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'CarryOverLimit',
        accessorFn: (row) => row,
        id: 'CarryOverLimit',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            {original.carryOverEnabled ? (
              <Typography variant="caption">{original.carryOverLimit}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        header: () => 'CarryOverExpiration',
        accessorFn: (row) => row,
        id: 'carryOverExpirationDay',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            {original.carryOverEnabled ? (
              <Typography variant="caption">{`${original.carryOverExpirationDay}/${original.carryOverExpirationMonth}`}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const handleRowClick = useCallback((row: Row<AbsencePolicyDto>) => {
    setSelectedPolicyToShowInDrawer(row.original);
    setIsPolicyDrawerOpen(true);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="title3">Absence policies</Typography>
      <Box sx={{ width: '100%', height: '200px', maxHeight: '200px', overflow: 'auto' }}>
        <BasicTable<AbsencePolicyDto>
          rowData={absencePolicies ?? []}
          columnData={policyTableColumns}
          loading={isLoadingPolicies}
          hidePagination
          rowClick={handleRowClick}
          noDataMessage="Sorry, we couldn't find anything..."
        />

        <HelperAbsencePolicyDrawer
          isOpen={isPolicyDrawerOpen}
          setIsOpen={setIsPolicyDrawerOpen}
          absencePolicy={selectedPolicyToShowInDrawer}
          onClose={() => setSelectedPolicyToShowInDrawer(null)}
          users={users}
        />
      </Box>
    </Box>
  );
};
