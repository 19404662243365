import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledTextfield = styled(TextField)(() => ({
  //this class hides background if form is preselected
  'input:-webkit-autofill,input:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s',
  },
  // these 2 classes hide the password default eye in MS Edge
  'input[type="password"]::-ms-reveal': {
    display: 'none',
  },
  'input[type="password"]::-ms-clear': {
    display: 'none',
  },
  // this hides default time picker icon on chromium based browsers
  'input[type="time"]::-webkit-calendar-picker-indicator': {
    background: 'none',
    display: 'none',
  },
  '&.MuiTextField-root': {
    //normal placeholder label
    '& label.MuiInputLabel-root.Mui-error': {
      color: `${themeColors.Grey} !important`,
      ...themeFonts.caption,
    },
    // label for untoched fields
    '& label.MuiFormLabel-root.MuiInputLabel-root': {
      color: `${themeColors.Grey} !important`,
      ...themeFonts.caption,
    },
    //shrunk placeholder on top
    '& label.MuiInputLabel-shrink.MuiInputLabel-sizeSmall': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.captionSmall,
      fontWeight: '400 !important',
      transform: 'none',
      marginTop: '-1px',
    },
    //typed in value
    '& input.MuiInputBase-input.MuiInput-input': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.title4,
      padding: 0,
      '&.MuiInputBase-inputAdornedEnd': {
        paddingRight: 12,
      },
      '&[value=""]': {
        fontWeight: 300, // lighter placeholder text
      },
    },
    //
    '& div.MuiInputBase-root.MuiInput-root': {
      color: `${themeColors.DarkGrey} !important`,
      boxSizing: 'border-box',
      ...themeFonts.title4,
      background: themeColors.Background,
      minHeight: '40px',
      borderRadius: '10px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${themeColors.Background} !important`,

      ':hover:not(.Mui-disabled):not(.Mui-readOnly)': {
        background: themeColors.GreyHover,
      },
      '&.Mui-focused': {
        border: `1px solid ${themeColors.GreyMiddle}!important`,
      },
    },

    '& .Mui-readOnly': {
      cursor: 'default',
    },

    '& div.MuiInputBase-root.MuiInput-root.Mui-error': {
      border: `1px solid ${themeColors.Red} !important`,
    },

    //underlines variations
    // 1. normal state (non active)
    '.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '.MuiInput-underline:not(.Mui-disabled):hover:before': {
      borderBottom: 'none',
    },
    // 2. normal state (active)
    '.MuiInput-underline.Mui-focused:after': {
      borderBottom: 'none',
    },
    // 2. normal state (active:error)
    '.MuiInput-underline.Mui-error:after': {
      borderBottom: 'none',
    },
    // normal helper text
    '& p.MuiFormHelperText-root': {
      ...themeFonts.captionSmall,
      color: themeColors.Grey,
      marginTop: spacing.m5,
    },
    // error helper text
    '& p.MuiFormHelperText-root.Mui-error': {
      color: themeColors.RedDark,
    },
    //input adornment buttons
    '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium': {
      padding: 0,
      position: 'absolute',
      right: '10px',
      marginRight: 0,
    },
    // hides background of selects auto selection
    '& div.MuiSelect-select': {
      backgroundColor: 'transparent',
    },
    // select value font
    '& div.MuiSelect-select.MuiInputBase-input.MuiInput-input': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.title4,
      padding: 0,
    },
    '& svg.MuiSelect-icon.MuiSelect-iconStandard': {
      fill: `${themeColors.Grey} !important`,
      top: '40%',
      right: '10px',
    },
    // endorment component alignment
    '& div.MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 5px)',
      display: 'flex',
      alignItems: 'center',
    },
    //clear indictaor autocomplete
    '& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      visibility: 'visible',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    //hidden label field
    '& .MuiInputBase-hiddenLabel': {
      paddingTop: '0px !important',
    },
  },
  '& div.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-standard.MuiInputAdornment-sizeSmall': {
    fill: themeColors.Grey,
    marginRight: '10px',
  },
  '& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
    fill: themeColors.Grey,
    marginRight: '10px',
  },
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
    display: 'none',
  },

  '& span.notranslate': {
    display: 'none',
  },

  textarea: {
    whiteSpace: 'inherit',
    overflow: 'inherit !important',
    '&::placeholder': {
      ...themeFonts.caption,
    },
  },
}));
