import React, { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OffboardingDrawer } from '@v2/feature/offboarding/components/offboarding-drawer.component';
import { SelectChecklistDrawer } from '@v2/feature/offboarding/components/select-checklist-drawer.component';
import { OffboardingProgressionBar } from '@v2/feature/offboarding/offboarding-process/components/offboarding-progression-bar.component';
import { OffboardingActionCard } from '@v2/feature/offboarding/offboarding-process/offboarding-action-card.component';
import { TaskFormModal } from '@v2/feature/task/components/task-add-modal/task-form-modal.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import { generatePath, useHistory } from 'react-router-dom';

import { UserEventDto } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import {
  OFFBOARDING_ROUTE,
  USER_DETAILS_PROFILE_ROUTE,
  USER_OFFBOARDING_ABSENCE_ROUTE,
  USER_OFFBOARDING_APPS_ROUTE,
  USER_OFFBOARDING_DEVICES_ROUTE,
  USER_OFFBOARDING_MANAGER_CHANGE_ROUTE,
  USER_OFFBOARDING_TASKS_ROUTE,
} from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export enum OffboardingStepStatus {
  TODO,
  PENDING,
  COMPLETE,
}

export type OffboardingState = {
  leaveDate: string | null;
  userEvent: UserEventDto | null;
  tasks: OffboardingStepStatus;
  absence: OffboardingStepStatus;
  apps: OffboardingStepStatus;
  devices: OffboardingStepStatus;
  managerChange: OffboardingStepStatus;

  tasksData: { tasksNo: number; pendingTasksNo: number };
  appsData: { activeAppsNo: number; scheduledToDeactivationNo: number; nonScheduledToDeactivationNo: number };
  devicesData: {
    devicePossessionAllTimeNo: number;
    activeDevicesNo: number;
    requestedReturnNo: number;
    nonRequestedReturnNo: number;
    activeOrdersNo: number;
  };
  absenceData: {
    policyMembershipsNo: number;
    remainingBalancePoliciesNo: number;
    remainingBalancePayrollPoliciesNo: number;
  };
  managerChangeData: {
    usersNeedingChangeNo: number;
    managerChangeRequiredNo: number;
    wasOrIsManager: boolean;
  };
};

function getIconState(state: OffboardingStepStatus): 'todo' | 'pending' | 'done' {
  if (state === OffboardingStepStatus.COMPLETE) return 'done';
  if (state === OffboardingStepStatus.PENDING) return 'pending';
  return 'todo';
}

interface PageProps {
  readonly userId: number;
  readonly offboardingState: OffboardingState;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
}

export const OffboardingProcessPage = ({ userId, offboardingState, refresh, loading }: PageProps) => {
  const history = useHistory();
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { getScopesContext, hasScopes } = useScopes();

  const [isEditLeaveDateOpen, setIsEditLeaveDateOpen] = useState<boolean>(false);
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState<boolean>(false);
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState<boolean>(false);
  const offboardingUser = useMemo(() => getCachedUserById(userId), [userId, getCachedUserById]);

  const showAbsences = useMemo(() => (offboardingState.absenceData?.policyMembershipsNo ?? 0) > 0, [offboardingState]);
  const showDevices = useMemo(() => (offboardingState.devicesData?.devicePossessionAllTimeNo ?? 0) > 0, [
    offboardingState,
  ]);
  const showReportingLine = useMemo(() => !!offboardingState.managerChangeData?.wasOrIsManager, [offboardingState]);

  const goToAbsences = useCallback(() => {
    history.push(generatePath(USER_OFFBOARDING_ABSENCE_ROUTE, { userId }));
  }, [userId, history]);

  const {
    hasUserLifecycleManagerScope,
    hasTaskManagerScope,
    hasAbsenceManagerScope,
    hasAppsManagerScope,
    hasDevicesManagerScope,
    hasRoleManagerScope,
  } = useMemo(() => {
    const scopeContext = getScopesContext({ userId });

    const hasUserLifecycleManagerScope = hasScopes(['user.lifecycle:manager'], scopeContext);
    const hasTaskManagerScope = hasScopes(['task:manager'], scopeContext);
    const hasAbsenceManagerScope = hasScopes(['absence:manager'], scopeContext);
    const hasAppsManagerScope = hasScopes(['apps:manager'], scopeContext);
    const hasDevicesManagerScope = hasScopes(['devices:manager'], scopeContext);
    const hasRoleManagerScope = hasScopes(['user.role:manager'], scopeContext);

    return {
      hasUserLifecycleManagerScope,
      hasTaskManagerScope,
      hasAbsenceManagerScope,
      hasAppsManagerScope,
      hasDevicesManagerScope,
      hasRoleManagerScope,
    };
  }, [hasScopes, getScopesContext, userId]);

  return (
    <RootStyle>
      <TopHeader
        title={
          offboardingUser
            ? polyglot.t('OffboardingPage.offboardingUser', { name: offboardingUser.displayName })
            : polyglot.t('OffboardingPage.offboarding')
        }
        showBack
        backPath={OFFBOARDING_ROUTE}
        showAction
        actions={
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="small"
            onClick={() => {
              history.push(generatePath(USER_DETAILS_PROFILE_ROUTE, { userId }));
            }}
          >
            View profile
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={loading} sx={{ width: '600px' }}>
        <OffboardingProgressionBar state={offboardingState} />
        <Stack sx={{ mt: spacing.s3 }}>
          <OffboardingActionCard
            label={polyglot.t('OffboardingPage.terminationDate')}
            iconState={offboardingState.leaveDate ? 'done' : 'todo'}
            buttonLabel={polyglot.t('General.edit')}
            onActionClick={
              hasUserLifecycleManagerScope
                ? () => {
                    setIsEditLeaveDateOpen(true);
                  }
                : undefined
            }
            buttonColor="secondary"
            chips={
              offboardingState.leaveDate
                ? [
                    {
                      text: new LocalDate(offboardingState.leaveDate).toLocaleDateString(undefined, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      }),
                    },
                  ]
                : []
            }
          />
          <div style={{ height: 1, backgroundColor: themeColors.lightGrey }} />
          <OffboardingActionCard
            label={polyglot.t('OffboardingPage.tasks')}
            description={polyglot.t('OffboardingPage.addTasks')}
            iconState={getIconState(offboardingState.tasks)}
            actionButton={
              hasTaskManagerScope && offboardingState.tasksData.tasksNo === 0 ? (
                <StyledMenuComponent
                  options={[
                    {
                      handler: () => {
                        setIsTaskDrawerOpen(true);
                      },
                      label: polyglot.t('OffboardingPage.newTask'),
                    },
                    {
                      handler: () => {
                        setIsChecklistDrawerOpen(true);
                      },
                      label: polyglot.t('OffboardingPage.checklist'),
                    },
                  ]}
                  actionButtonDetails={{
                    type: 'button',
                    colorVariant: 'secondary',
                    sizeVariant: 'small',
                    title: polyglot.t('General.actions'),
                    icon: <ArrowDown {...iconSize} />,
                    iconPosition: 'end',
                    fullWidth: true,
                  }}
                />
              ) : undefined
            }
            buttonLabel={polyglot.t('General.view')}
            buttonColor="secondary"
            onActionClick={
              hasTaskManagerScope
                ? () => {
                    history.push(generatePath(USER_OFFBOARDING_TASKS_ROUTE, { userId }));
                  }
                : undefined
            }
          />
          {showAbsences && <div style={{ height: 1, backgroundColor: themeColors.lightGrey }} />}
          {showAbsences && (
            <OffboardingActionCard
              label={polyglot.t('OffboardingPage.absence')}
              description={polyglot.t('OffboardingPage.reviewAccruedAbsence')}
              iconState={getIconState(offboardingState.absence)}
              buttonLabel={polyglot.t('General.view')}
              buttonColor="secondary"
              onActionClick={hasAbsenceManagerScope ? goToAbsences : undefined}
            />
          )}
          <div style={{ height: 1, backgroundColor: themeColors.lightGrey }} />
          <OffboardingActionCard
            label={polyglot.t('OffboardingPage.apps')}
            description={polyglot.t('OffboardingPage.scheduleDeactivation')}
            iconState={getIconState(offboardingState.apps)}
            buttonLabel={polyglot.t('General.view')}
            buttonColor="secondary"
            onActionClick={
              hasAppsManagerScope
                ? () => {
                    history.push(generatePath(USER_OFFBOARDING_APPS_ROUTE, { userId }));
                  }
                : undefined
            }
            chips={
              offboardingState.appsData.activeAppsNo
                ? [
                    {
                      text: polyglot.t('OffboardingPage.noOfAccounts', {
                        smart_count: offboardingState.appsData.activeAppsNo,
                      }),
                    },
                  ]
                : []
            }
          />
          {showDevices && <div style={{ height: 1, backgroundColor: themeColors.lightGrey }} />}
          {showDevices && (
            <OffboardingActionCard
              label={polyglot.t('OffboardingPage.devices')}
              description={polyglot.t('OffboardingPage.wipeAndReturnDevices')}
              iconState={getIconState(offboardingState.devices)}
              buttonLabel={polyglot.t('General.view')}
              buttonColor="secondary"
              onActionClick={
                hasDevicesManagerScope &&
                (offboardingState.devicesData.activeDevicesNo > 0 || offboardingState.devicesData.activeOrdersNo > 0)
                  ? () => {
                      history.push(generatePath(USER_OFFBOARDING_DEVICES_ROUTE, { userId }));
                    }
                  : undefined
              }
              chips={
                offboardingState.devicesData.activeDevicesNo > 0 || offboardingState.devicesData.activeOrdersNo > 0
                  ? [
                      {
                        text: polyglot.t('OffboardingPage.noOfDevices', {
                          smart_count:
                            offboardingState.devicesData.activeDevicesNo + offboardingState.devicesData.activeOrdersNo,
                        }),
                      },
                    ]
                  : []
              }
            />
          )}
          {showDevices && <div style={{ height: 1, backgroundColor: themeColors.lightGrey }} />}
          {showReportingLine && (
            <OffboardingActionCard
              label={polyglot.t('OffboardingPage.managerChange')}
              description={polyglot.t('OffboardingPage.managerChangeDescription')}
              iconState={getIconState(offboardingState.managerChange)}
              buttonLabel={polyglot.t('General.view')}
              buttonColor="secondary"
              onActionClick={
                hasRoleManagerScope && offboardingState.managerChangeData.usersNeedingChangeNo > 0
                  ? () => {
                      history.push(generatePath(USER_OFFBOARDING_MANAGER_CHANGE_ROUTE, { userId }));
                    }
                  : undefined
              }
              chips={
                offboardingState.managerChangeData.usersNeedingChangeNo > 0
                  ? [
                      {
                        text: polyglot.t('OffboardingPage.noOfEmployees', {
                          smart_count: offboardingState.managerChangeData.usersNeedingChangeNo,
                        }),
                      },
                    ]
                  : []
              }
            />
          )}
        </Stack>

        <OffboardingDrawer
          isOpen={isEditLeaveDateOpen}
          setIsOpen={setIsEditLeaveDateOpen}
          userId={userId}
          terminationUserEvent={offboardingState.userEvent}
          pushToOffboarding={false}
          refresh={refresh}
        />

        <SelectChecklistDrawer
          isOpen={isChecklistDrawerOpen}
          setIsOpen={setIsChecklistDrawerOpen}
          userId={userId}
          refresh={refresh}
        />

        <TaskFormModal
          isOpen={isTaskDrawerOpen}
          setIsOpen={setIsTaskDrawerOpen}
          userId={userId}
          formData={null}
          refresh={refresh}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
