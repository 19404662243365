import { useHistory, useParams } from 'react-router-dom';

export function useUserIdParam() {
  const routerHistory = useHistory();
  const { userId: userIdParam } = useParams<{ readonly userId: string }>();
  const userId = Number(userIdParam);

  if (!Number.isInteger(userId) || userId <= 0) {
    routerHistory.push('/');
    return 0;
  }

  return userId;
}
