import { useCallback, useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { OptionObject } from '@v2/components/forms/select.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceStatus } from '@v2/feature/attendance/attendance.interface';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import { ReportsAPI } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { AttendanceDomainReportFilters, ReportEntity, UpdateReportOld } from '@v2/feature/reports/reports.interface';
import { AttendanceReportColumnsOptions } from '@v2/feature/reports/util/attendance-report.util';
import { exportReportCSVOldReports, extractAllColumnsFromColumnsConfig } from '@v2/feature/reports/util/report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { primarySmallBtn, secondarySmallBtn } from '@v2/styles/buttons.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { CompanyEndpoints } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';

export const AttendanceReportSection = ({ report, loading }: { report: ReportDto; loading: boolean }) => {
  const { polyglot } = usePolyglot();

  const { data: attendanceSchedules } = useApiClient<AttendanceScheduleDto[], Error>(
    AttendanceEndpoints.getAttendanceSchedules(),
    {
      suspense: false,
    }
  );
  const { data: companyEntities } = useApiClient(CompanyEndpoints.getCompanyEntities(), {
    suspense: false,
  });
  const [filteredData, setFilteredData] = useState<Record<string, string | number>[] | undefined>(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState<string>(report?.config?.filters ?? '');
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    report?.config?.columns ? extractAllColumnsFromColumnsConfig(report.config.columns) : []
  );
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState<boolean>(true);
  const [reportName, setReportName] = useState<string>(report?.name ?? '');

  const setFilterOptions = useCallback(async () => {
    const statusesOptions: OptionObject[] = [
      { label: polyglot.t('AttendanceReportSection.approved'), value: AttendanceStatus.Approved },
      { label: polyglot.t('AttendanceReportSection.submit'), value: AttendanceStatus.Submitted },
      { label: polyglot.t('AttendanceReportSection.inProgress'), value: AttendanceStatus.InProgress },
      { label: polyglot.t('AttendanceReportSection.rejected'), value: AttendanceStatus.Rejected },
    ];
    const schedulesOptions = (attendanceSchedules ?? []).map((s) => {
      return { label: s.name, value: s.id };
    });
    const entitiesOptions = (companyEntities ?? []).map((ce) => ({ label: ce.legalName, value: ce.id }));

    let filters = {};
    const FILTERS = AttendanceDomainReportFilters(schedulesOptions, statusesOptions, entitiesOptions, polyglot);
    for (const filterObj of FILTERS) {
      let filter = filterObj.name;
      filters = { ...filters, [`${filterObj.domain}-${filterObj.field}-${filter}`]: filterObj.options };
    }
    setFilterTypes(filters);
  }, [polyglot, attendanceSchedules, companyEntities]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setFilterOptions();
  }, [setFilterOptions]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData?.filter(
        (data) =>
          !searchInput ||
          (data?.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  }, [searchInput, filteredData]);

  const getReportData = useCallback(
    async (report: ReportDto) => {
      setReportsLoading(true);
      try {
        const paramObj = {
          ...report.config,
          filters: filterString,
          columns: { [ReportEntity.Attendance]: selectedColumns },
        };

        const filteredTimeRequests = await ReportsAPI.generateAttendanceCreatedReport(paramObj);
        setFilteredData(filteredTimeRequests[ReportEntity.Attendance]);
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setReportsLoading(false);
      }
    },
    [selectedColumns, filterString, showMessage]
  );

  useEffect(() => {
    if (report) getReportData(report);
  }, [getReportData, report]);

  const handleSave = async () => {
    try {
      if (report) {
        const paramObj: UpdateReportOld = {
          filters: filterString,
          columns: { [ReportEntity.Attendance]: selectedColumns },
          fileName: reportName ?? report.name,
        };
        await ReportsAPI.patchReport(report.id, paramObj);
      }
      showMessage(polyglot.t('AttendanceReportSection.successMessages.update'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };

  const exportCsv = async () => {
    try {
      exportReportCSVOldReports(reportName, filteredData ? { [ReportEntity.Attendance]: filteredData } : undefined);
    } catch (e) {
      showMessage(polyglot.t('AttendanceReportSection.errorMessages.download'), 'error');
    }
  };

  return (
    <RootStyle>
      <TopHeader
        showBack
        title={
          reportName ? (
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={undefined}
            />
          ) : (
            <></>
          )
        }
        showAction
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <Button fullWidth sx={secondarySmallBtn} startIcon={<Export {...iconSize} />} onClick={exportCsv}>
              {polyglot.t('AttendanceReportSection.downloadCsv')}
            </Button>
            <Button sx={primarySmallBtn} onClick={handleSave}>
              {polyglot.t('General.save')}
            </Button>
          </Box>
        }
      />
      <ContentWrapper loading={loading} sx={{ pt: 0 }}>
        <Box sx={{ display: 'flex', mt: spacing.m20, alignItems: 'center', gap: spacing.g5 }}>
          <ColumnsDrawer
            columnsOptions={AttendanceReportColumnsOptions(polyglot)}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
          <FiltersDrawer
            filtersOptions={[{ filters: filterTypes }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            encodedFilterNames
          />
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
        <Box sx={{ mt: spacing.m20 }}>
          <ResultTableOldReports filteredData={filteredAndSearchedData ?? []} loading={reportLoading} />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
