import { useEffect, useMemo, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import {
  AttendanceImportDto,
  AttendanceImportResultDto,
} from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledRadioGroup } from '@/v2/components/theme-components/styled-radio-group.component';
import {
  AbsenceAdjustmentImportDto,
  AbsenceImportDto,
  AbsenceImportResultDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsEndpoints } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { CustomProfileFieldDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import {
  DeviceImportDto,
  DevicesImportResultDto,
} from '@/v2/feature/device/features/devices-import/devices-import.dto';
import { EntityImportErrorDto, EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import {
  EntityImportWizardData,
  EntityImportWizardSource,
  EntityImportWizardSourceOptions,
} from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { PersistentNotification } from '@/v2/feature/entity-import/wizard/components/user/persistent-notification.component';
import {
  convertPeopleCSVToUserImport2,
  validateAbsenceAdjustmentsImport,
  validateAbsenceImport,
  validateAttendanceImport,
  validateDeviceImport,
  validatePeopleImport2,
} from '@/v2/feature/entity-import/wizard/entity-import-validator.util';
import { ImportState } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
// @ts-expect-error
import AttendanceImportCsvTemplate from '@/v2/feature/entity-import/wizard/template-samples/zelt-attendance-import-template.csv';
// @ts-expect-error
import DeviceImportCsvTemplate from '@/v2/feature/entity-import/wizard/template-samples/zelt-devices-import-template.csv';
// @ts-expect-error
import AbsenceAdjustmentsImportCsvTemplate from '@/v2/feature/entity-import/wizard/template-samples/zelt-timeadjustments-import.template.csv';
// @ts-expect-error
import AbsenceImportCsvTemplate from '@/v2/feature/entity-import/wizard/template-samples/zelt-timeaway-import-template.csv';
import { getCodeForCountryName } from '@/v2/feature/payments/payments.util';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import {
  UserImport2Dto,
  UserImportDto,
  UserImportResultDto,
} from '@/v2/feature/user/features/user-import/user-import.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort } from '@/v2/util/array.util';
import { parseCsvBuffer } from '@/v2/util/csv.util';
import { LocalDate } from '@/v2/util/local-date';
import { arrayBufferToString } from '@/v2/util/string.util';

type DataSourceChoiceSectionProps = {
  importState: ImportState;
  onNext: (
    source: EntityImportWizardSource,
    result: EntityImportValidationResultDto<
      | UserImportDto
      | UserImport2Dto
      | AbsenceImportDto
      | DeviceImportDto
      | AttendanceImportDto
      | AbsenceAdjustmentImportDto
    >
  ) => void;
  sx?: SxProps<Theme>;
  entities: CompanyUnitDto[];
  sites: SiteDto[];
  departments: CompanyDepartmentDto[];
  attendanceSchedules: AttendanceScheduleDto[];
  getCustomFieldsForForm: (formName: string) => CustomProfileFieldDto[];
};

export const DataSourceChoiceSection = ({
  importState,
  onNext,
  sx,
  entities,
  sites,
  departments,
  attendanceSchedules,
  getCustomFieldsForForm,
}: DataSourceChoiceSectionProps) => {
  const { polyglot } = usePolyglot();

  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });
  const { data: attendanceTypes } = useApiClient(AttendanceEndpoints.getCompanyAttendanceTypes(), { suspense: false });
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const V1_PAYROLL_IMPORT_FLOW = '/settings/import/wizard/payroll/';
  const V1_USER_GOOGLE_IMPORT_FLOW = '/settings/import/wizard/users/google';
  const azureAppStub = 'azure-ad' as AppIntegrationStub;
  const googleAppStub = 'google-workspace' as AppIntegrationStub;
  const fallbackDomain = EntityImportWizardData.Users;
  const [azureAdLoading, setAzureAdLoading] = useState(false);
  const [newTemplateFile, setNewTemplateFile] = useState(false);
  const [selectedUserProfileFormIds, setSelectedUserProfileFormIds] = useState(new Set<string>());
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const { data: azureAppDetails } = useApiClient(AppDetailsEndpoints.getAppDetails(azureAppStub), {
    suspense: false,
  });
  const { data: googleAppDetails } = useApiClient(AppDetailsEndpoints.getAppDetails(googleAppStub), {
    suspense: false,
  });
  const { data: customProfileForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false,
  });

  const sortedProfileForms = useMemo(() => {
    // known tab names in display order
    const knownTabs = [ProfileTab.Personal, ProfileTab.Work, ProfileTab.Compensation, ProfileTab.Contact];
    return customProfileForms?.slice().sort((a, b) => {
      const knownTabIndexA = knownTabs.indexOf(a.formTab);
      const knownTabIndexB = knownTabs.indexOf(b.formTab);
      if (knownTabIndexA === -1 && knownTabIndexB === -1) {
        return caseInsensitiveSort(a, b, (x) => x.formTab);
      }
      return (
        // sort by tab order first
        knownTabIndexA - knownTabIndexB ||
        // then by any display order values (null display orders are put last)
        (a.displayOrder ?? 1e9) - (b.displayOrder ?? 1e9) ||
        // if the tabs and display orders are the same, order by form creation date
        a.createdAt.localeCompare(b.createdAt)
      );
    });
  }, [customProfileForms]);

  const DataSourceChoiceSchema = Yup.object().shape({
    dataSourceChoice: Yup.string()
      .oneOf(EntityImportWizardSourceOptions[importState.domain ?? fallbackDomain].map((o) => o.value) as string[])
      .required(polyglot.t('DataSourceChoiceSection.errorMessages.pleaseSelect')),
    dataSourceCsv: Yup.string().when('dataSourceChoice', {
      is: EntityImportWizardSource.CSV,
      then: Yup.string().required(polyglot.t('DataSourceChoiceSection.errorMessages.mustUploadCsv')),
    }),
  });

  const handleAzureAdUserImportSourceSelection = async () => {
    if (azureAppDetails?.authorised) {
      try {
        setAzureAdLoading(true);
        const userListForImport = await AppIntegrationAPI.getUserListForImport(azureAppStub);
        const mappedEntries: EntityImportErrorDto<UserImportDto>[] = userListForImport.map((entry) => ({
          id: entry.id,
          entity: {
            ...entry,
            startDate: entry.startDate ? new LocalDate(entry.startDate).toDateString() : null,
            country: entry.country ? getCodeForCountryName(entry.country) : undefined,
          },
          errors: [],
        }));
        // result?.errors.map((error) => (error.id = v4()));
        if (userListForImport && formik.values.dataSourceChoice) {
          onNext(formik.values.dataSourceChoice, { errors: mappedEntries });
        }
      } catch (error) {
        showMessage(
          `${polyglot.t('DataSourceChoiceSection.errorMessages.fetchUsers')}. ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setAzureAdLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      dataSourceChoice: importState?.source,
      dataSourceCsv: undefined,
    },
    validateOnMount: true,
    validationSchema: DataSourceChoiceSchema,
    onSubmit: async (values) => {
      if (values.dataSourceChoice) {
        setSaving(true);
        if (values.dataSourceChoice === EntityImportWizardSource.AzureAD) {
          await handleAzureAdUserImportSourceSelection();
        }
        try {
        } catch (error) {
          showMessage(
            `${polyglot.t('DataSourceChoiceSection.errorMessages.importUsers')}. ${nestErrorMessage(error)}`,
            'error'
          );
          setSaving(false);
        }
      }
    },
  });

  const handleFileUpload = (
    result:
      | EntityImportValidationResultDto<
          | UserImportDto
          | UserImport2Dto
          | AbsenceImportDto
          | DeviceImportDto
          | AttendanceImportDto
          | AbsenceAdjustmentImportDto
        >
      | undefined
  ) => {
    result?.errors.map((error) => (error.id = v4()));
    if (result && formik.values.dataSourceChoice) {
      onNext(formik.values.dataSourceChoice, result);
    }
  };

  const dataSourceChoiceOptions = useMemo(() => {
    return EntityImportWizardSourceOptions[importState.domain ?? fallbackDomain]
      .filter((o) => {
        let appStatus;
        if (importState.domain === EntityImportWizardData.Users) {
          // for the external app sources check if authorised
          if (o.value === EntityImportWizardSource.AzureAD) appStatus = azureAppDetails?.authorised;
          else if (o.value === EntityImportWizardSource.Google) appStatus = googleAppDetails?.authorised;
          else if (o.value === EntityImportWizardSource.CSV) appStatus = true;
        } else {
          // for all other non app related data sources no option should be disabled
          appStatus = true;
        }
        return appStatus === true;
      })
      .map((o) => {
        return {
          ...o,
        };
      });
  }, [azureAppDetails?.authorised, fallbackDomain, googleAppDetails?.authorised, importState.domain]);

  useEffect(() => {
    // if the user unchecks the "new template" option, clear the list of selected forms
    if (!newTemplateFile) {
      setSelectedUserProfileFormIds(new Set());
    }
  }, [newTemplateFile]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <PersistentNotification
          inUse={azureAdLoading}
          message={polyglot.t('handleAzureAdUserImportSourceSelection.message')}
        />
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('DataSourceChoiceSection.selectSource')}</Typography>
          <StyledRadioGroup
            name="dataSourceChoice"
            options={dataSourceChoiceOptions}
            selectedValue={formik.values.dataSourceChoice}
            onChange={(o) => {
              const currentDataSourceChoice = o.currentTarget.value;
              formik.setFieldValue('dataSourceChoice', currentDataSourceChoice);
              if (
                importState.domain === EntityImportWizardData.Payroll &&
                [
                  EntityImportWizardSource.CSV,
                  EntityImportWizardSource.Xero,
                  EntityImportWizardSource.Quickbooks,
                ].includes(currentDataSourceChoice as EntityImportWizardSource)
              ) {
                // temporary stub for re-using old v1 import flow for Payroll flows
                routerHistory.push(V1_PAYROLL_IMPORT_FLOW.concat(currentDataSourceChoice));
              } else if (
                importState.domain === EntityImportWizardData.Users &&
                currentDataSourceChoice === EntityImportWizardSource.Google
              ) {
                routerHistory.push(V1_USER_GOOGLE_IMPORT_FLOW);
              } else if (
                importState.domain === EntityImportWizardData.Users &&
                currentDataSourceChoice === EntityImportWizardSource.AzureAD
              ) {
                if (!azureAppDetails?.authorised)
                  showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.connectAzure'), 'error');
              }
            }}
          />
          {formik.values.dataSourceChoice === EntityImportWizardSource.CSV &&
            importState.domain === EntityImportWizardData.Absences && (
              <Box sx={{ mt: '-40px' }}>
                <Typography variant="caption">
                  {polyglot.t('DataSourceChoiceSection.useTemplate1')}{' '}
                  <a href={AbsenceImportCsvTemplate} rel="noreferrer" style={{ color: themeColors.DarkGrey }}>
                    {polyglot.t('DataSourceChoiceSection.useTemplate2')}
                  </a>{' '}
                  {polyglot.t('DataSourceChoiceSection.useTemplate3')}
                </Typography>
                <UploadInput<AbsenceImportResultDto>
                  skipUpload={true}
                  onChange={async (_: any, file: File | undefined) => {
                    try {
                      if (!file) return;
                      const arrayBuffer = await file.arrayBuffer();
                      const csvBuffer = await arrayBufferToString(arrayBuffer);
                      const parsedCsv = parseCsvBuffer(csvBuffer);
                      const validationResult = await validateAbsenceImport(
                        (parsedCsv as unknown) as AbsenceImportDto[],
                        allUsersIncludingTerminated,
                        absencePolicies ?? []
                      );

                      handleFileUpload(validationResult);
                    } catch (error) {
                      showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.parsingCsv'), 'error');
                      console.error(':::: ERROR PARSING FILE :::::', error);
                    }
                  }}
                />
              </Box>
            )}
          {formik.values.dataSourceChoice === EntityImportWizardSource.CSV &&
            importState.domain === EntityImportWizardData.AbsenceAdjustments && (
              <Box sx={{ mt: '-40px' }}>
                <Typography variant="caption">
                  {polyglot.t('DataSourceChoiceSection.useTemplate1')}{' '}
                  <a
                    href={AbsenceAdjustmentsImportCsvTemplate}
                    rel="noreferrer"
                    style={{ color: themeColors.DarkGrey }}
                  >
                    {polyglot.t('DataSourceChoiceSection.useTemplate2')}
                  </a>{' '}
                  {polyglot.t('DataSourceChoiceSection.useTemplate3')}
                </Typography>
                <UploadInput<AbsenceImportResultDto>
                  skipUpload={true}
                  onChange={async (_: any, file: File | undefined) => {
                    try {
                      if (!file) return;
                      const arrayBuffer = await file.arrayBuffer();
                      const csvBuffer = await arrayBufferToString(arrayBuffer);
                      const parsedCsv = parseCsvBuffer(csvBuffer);
                      const validationResult = await validateAbsenceAdjustmentsImport(
                        (parsedCsv as unknown) as AbsenceAdjustmentImportDto[],
                        allUsersIncludingTerminated,
                        absencePolicies ?? []
                      );

                      handleFileUpload(validationResult);
                    } catch (error) {
                      showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.parsingCsv'), 'error');
                      console.error(':::: ERROR PARSING FILE :::::', error);
                    }
                  }}
                />
              </Box>
            )}
          {formik.values.dataSourceChoice === EntityImportWizardSource.CSV &&
            importState.domain === EntityImportWizardData.Attendances && (
              <Box sx={{ mt: '-40px' }}>
                <Typography variant="caption">
                  {polyglot.t('DataSourceChoiceSection.useTemplate1')}{' '}
                  <a href={AttendanceImportCsvTemplate} rel="noreferrer" style={{ color: themeColors.DarkGrey }}>
                    {polyglot.t('DataSourceChoiceSection.useTemplate2')}
                  </a>{' '}
                  {polyglot.t('DataSourceChoiceSection.useTemplate3')}
                </Typography>
                <UploadInput<AttendanceImportResultDto>
                  skipUpload={true}
                  onChange={async (_: any, file: File | undefined) => {
                    try {
                      if (!file) return;
                      const arrayBuffer = await file.arrayBuffer();
                      const csvBuffer = await arrayBufferToString(arrayBuffer);
                      const parsedCsv = parseCsvBuffer(csvBuffer);
                      const validationResult = await validateAttendanceImport(
                        (parsedCsv as unknown) as AttendanceImportDto[],
                        allUsersIncludingTerminated,
                        attendanceTypes ?? []
                      );

                      handleFileUpload(validationResult);
                    } catch (error) {
                      showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.parsingCsv'), 'warning');

                      console.error(':::: ERROR PARSING FILE :::::', error);
                    }
                  }}
                />
              </Box>
            )}
          {formik.values.dataSourceChoice === EntityImportWizardSource.CSV &&
            importState.domain === EntityImportWizardData.Users &&
            sortedProfileForms && (
              <Stack sx={{ mt: '-40px', gap: spacing.g15 }}>
                <CheckboxComponent
                  label={'Create a new CSV template file'}
                  name={'existing-file'}
                  checked={newTemplateFile}
                  onChange={(e, checked) => setNewTemplateFile(checked)}
                />
                {newTemplateFile && (
                  <>
                    <Typography variant="caption">Choose the data you would like to include in the import</Typography>
                    <Stack sx={{ gap: spacing.g15 }}>
                      {Object.entries(groupBy(sortedProfileForms, 'formTab')).map(([tab, forms]) => (
                        <Box key={tab}>
                          <CheckboxComponent
                            label={tab}
                            name={tab}
                            checked={forms.some(({ formId }) => selectedUserProfileFormIds.has(formId))}
                            onChange={(e, checked) => {
                              const ids = new Set(selectedUserProfileFormIds);
                              forms.forEach(({ formId }) => ids[checked ? 'add' : 'delete'](formId));
                              setSelectedUserProfileFormIds(ids);
                            }}
                            labelSx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                          />
                          <Stack sx={{ mt: spacing.m5, gap: spacing.g5 }}>
                            {forms.map(({ formName, formId }) => (
                              <CheckboxComponent
                                key={formId}
                                label={formName}
                                name={formId}
                                checked={selectedUserProfileFormIds.has(formId)}
                                onChange={(e, checked) => {
                                  const ids = new Set(selectedUserProfileFormIds);
                                  ids[checked ? 'add' : 'delete'](formId);
                                  setSelectedUserProfileFormIds(ids);
                                }}
                                labelSx={{ textTransform: 'capitalize' }}
                                sx={{ marginLeft: spacing.ml10 }}
                              />
                            ))}
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                    {selectedUserProfileFormIds.size > 0 && (
                      <Stack sx={{ mt: spacing.mt10 }}>
                        <Typography variant="caption">
                          1. Download{' '}
                          <a
                            href={`/apiv2/users/import/zelt-user-import-template.csv?sections=${[
                              ...selectedUserProfileFormIds,
                            ].join(',')}`}
                            rel="noreferrer"
                            style={{ color: themeColors.DarkGrey }}
                          >
                            this CSV template
                          </a>{' '}
                          file.
                        </Typography>
                        <Typography variant="caption">2. Edit the file and enter the data to be imported.</Typography>
                        <Typography variant="caption">3. Save the file and upload it here:</Typography>
                      </Stack>
                    )}
                  </>
                )}
                {(!newTemplateFile || selectedUserProfileFormIds.size > 0) && (
                  <UploadInput<UserImportResultDto>
                    skipUpload={true}
                    onChange={async (_: any, file: File | undefined) => {
                      try {
                        if (!file) return;
                        const arrayBuffer = await file.arrayBuffer();
                        const csvBuffer = await arrayBufferToString(arrayBuffer);
                        const parsedCsv = parseCsvBuffer(csvBuffer);
                        const userImport = convertPeopleCSVToUserImport2(
                          parsedCsv,
                          allUsersIncludingTerminated,
                          departments,
                          sites,
                          entities,
                          attendanceSchedules,
                          sortedProfileForms,
                          getCustomFieldsForForm,
                          polyglot
                        );
                        const validationResult = await validatePeopleImport2(userImport, polyglot);
                        handleFileUpload(validationResult);
                      } catch (error) {
                        showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.parsingCsv'), 'error');
                        console.error(':::: ERROR PARSING FILE :::::', error);
                      }
                    }}
                  />
                )}
              </Stack>
            )}
          {formik.values.dataSourceChoice === EntityImportWizardSource.CSV &&
            importState.domain === EntityImportWizardData.Devices && (
              <Box sx={{ mt: '-40px' }}>
                <Typography variant="caption">
                  {polyglot.t('DataSourceChoiceSection.useTemplate1')}{' '}
                  <a href={DeviceImportCsvTemplate} rel="noreferrer" style={{ color: themeColors.DarkGrey }}>
                    {polyglot.t('DataSourceChoiceSection.useTemplate2')}
                  </a>{' '}
                  {polyglot.t('DataSourceChoiceSection.useTemplate3')}
                </Typography>
                <UploadInput<DevicesImportResultDto>
                  skipUpload={true}
                  onChange={async (_: any, file: File | undefined) => {
                    try {
                      if (!file) return;
                      const arrayBuffer = await file.arrayBuffer();
                      const csvBuffer = await arrayBufferToString(arrayBuffer);
                      const parsedCsv = parseCsvBuffer(csvBuffer);
                      const validationResult = await validateDeviceImport((parsedCsv as unknown) as DeviceImportDto[]);
                      handleFileUpload(validationResult);
                    } catch (error) {
                      showMessage(polyglot.t('DataSourceChoiceSection.errorMessages.parsingCsv'), 'error');
                      console.error(':::: ERROR PARSING FILE :::::', error);
                    }
                  }}
                />
              </Box>
            )}
          <LoaderButton
            name={polyglot.t('DataSourceChoiceSection.continue')}
            loading={saving}
            colorVariant="primary"
            sizeVariant="large"
            disabled={!formik.isValid}
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
