import { Box, IconButton, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  setOptions: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  options: Record<number, string>;
  answerOptsError: string | null;
  setAnswerOptsError: React.Dispatch<React.SetStateAction<string | null>>;
}

export const OptionTypeQuestion = ({ setOptions, options, answerOptsError, setAnswerOptsError }: Props) => {
  const { polyglot } = usePolyglot();

  const optionValues: string[] = Object.values(options).length > 0 ? Object.values(options) : ['', ''];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16 }}>
      <>
        {optionValues.map((opt, idx) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
              <TextfieldComponent
                value={opt}
                placeholder={polyglot.t('OptionTypeQuestion.pleaseAdd')}
                onChange={(e) => {
                  const currentOpt = e.target.value;
                  optionValues[idx] = currentOpt;
                  setOptions({ ...optionValues });
                }}
                clearText={() => {
                  optionValues[idx] = '';
                  setOptions({ ...optionValues });
                }}
              />

              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setOptions({ ...optionValues.filter((_, idy) => idy !== idx) });
                }}
              >
                <Trash {...iconSize} />
              </IconButton>
            </Box>
          );
        })}
      </>

      <Box sx={{ display: 'block' }}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={() => {
            setOptions({ ...[...optionValues, ''] });
            setAnswerOptsError(null);
          }}
        >
          {polyglot.t('OptionTypeQuestion.addAnswer')}
        </ButtonComponent>

        {answerOptsError && (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.darkRed, mt: spacing.m10 }}>
            {answerOptsError}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
