import { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DevicePossessionType, ReturnDeviceReason } from '@/v2/feature/device/device.interface';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { formatAddress } from '@/v2/util/user-data.util';

export const ChangeOwnerZeltStorageForm = ({
  devicePossession,
  closePage,
}: {
  devicePossession: DevicePossessionDto;
  closePage: () => Promise<void>;
}) => {
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const [senderAddress, setSenderAddress] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initiateDeviceReturn = async () => {
    let isReturnInitiated = false;
    setIsSubmitting(true);
    try {
      await DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        ReturnDeviceReason.SendToZeltStorage,
        senderAddress,
        'Zelt HQ',
        null,
        undefined
      );

      showMessage('Successfully requested a return', 'success');

      isReturnInitiated = true;

      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await closePage();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not initiate return device flow. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchUserAddress = useCallback(
    async (assignedUserId: number): Promise<void> => {
      try {
        const userAddress = await UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="caption">
        We will reach out to you to arrange a pickup shortly. Please ask the current owner to remove all personal files.
        You can initiate a wipe of this device later.{' '}
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          name="Save"
          colorVariant="primary"
          sizeVariant="medium"
          fullWidth
          loading={isSubmitting}
          onClick={async () => await initiateDeviceReturn()}
        />
      </Box>
    </Box>
  );
};
