import { Suspense, useContext } from 'react';

import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@v2/styles/colors.styles';
import { Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE, SETTINGS_MONEY_ROUTE } from '@/lib/routes';
import { InvoiceSettingDetailRouter } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/invoice-setting-detail/invoice-setting-detail.router';

export const InvoiceTypeSettingsDetailsRouter = () => {
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId: currentUser.userId });

  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  return (
    <Switch>
      <RouteScopesControl
        context={context}
        scopes={['invoices:all', 'payments:all']}
        path={SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE}
      >
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="90%"
              height="90vh"
              sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
            />
          }
        >
          <InvoiceSettingDetailRouter id={id} />
        </Suspense>
      </RouteScopesControl>

      <Redirect to={SETTINGS_MONEY_ROUTE} />
    </Switch>
  );
};
