import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';

export const TimePlannerEmptyState = () => {
  const { polyglot } = usePolyglot();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        verticalAlign: 'center',
        height: '100%',
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: themeColors.Grey,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {polyglot.t('TimePlannerEmptyState.noPolicies')}
      </Typography>
    </Box>
  );
};
