import React, { useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DepWizardHelper } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-wizard-helper';
import { DepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import {
  conditionalSx,
  definitionSx,
  drawerContentSx,
} from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_DEVICES_DEP_ROUTE } from '@/lib/routes';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { InHouseMdmAPI } from '@/v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CustomDepActionsDrawer = ({
  mdmServerName,
  depAccountDetailsPar,
  onClose,
  onRefresh,
}: {
  mdmServerName: string;
  depAccountDetailsPar: DepAccountDetails | undefined;
  onClose: () => Promise<void>;
  onRefresh: () => Promise<void>;
}) => {
  const routerHistory = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [depAccountDetails] = useState<DepAccountDetails | undefined>(depAccountDetailsPar);
  const [showMessage] = useMessage();
  const [isDeleteDepTokenDrawerOpen, setIsDeleteDepTokenDrawerOpen] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>('');
  const [isAutoSync, setAutoSync] = useState<boolean>(depAccountDetails?.isAutoSyncEnabled ?? false);
  const handleRenew = async () => {
    try {
      setLoading(true);
      routerHistory.push(SETTINGS_DEVICES_DEP_ROUTE);
      await onClose();
    } catch (error) {
      showMessage(`Could not renew auto enrolment token.${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isDeleteDepTokenDrawerOpen ? (
        <Box sx={drawerContentSx}>
          <Typography variant="title2">Disconnect Auto enrolment</Typography>
          <Box>
            <Box sx={{ ...definitionSx, mt: spacing.m10, display: 'flex', flexDirection: 'column' }}>
              <Typography variant={'caption'}>
                If you disconnect token from the MDM, Zelt can no longer access your Apple Business Manager account for
                Auto-Enrolment. Please note:
                <ul>
                  <li>Devices that are presently enroled will not be impacted</li>
                  <li>Devices that are not enroled will not be enrolled automatically</li>
                </ul>
              </Typography>
              <Box sx={{ ...spacing.mt40 }}>
                <TextfieldComponent
                  name="confirm"
                  label=""
                  placeholder="Type “Confirm” here to disconnect device"
                  fullWidth
                  value={confirmationText}
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmationText(event.target.value)}
                  clearText={() => setConfirmationText('')}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name="Confirm"
              fullWidth
              loading={isLoading}
              sizeVariant="medium"
              colorVariant="primary"
              disabled={confirmationText !== 'Confirm'}
              onClick={async () => {
                try {
                  if (mdmServerName) {
                    setLoading(true);
                    await InHouseMdmAPI.deleteDepPublicKey(mdmServerName);
                  }
                  showMessage('Action launched successfully', 'success');
                  setIsDeleteDepTokenDrawerOpen(false);
                  onClose();
                } catch (error) {
                  showMessage('The launch of the action failed', 'error');
                } finally {
                  setLoading(false);
                }
              }}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={drawerContentSx}>
            <Typography variant="title2">Auto enrolment</Typography>
            <Box>
              <Box sx={{ ...definitionSx, mt: spacing.m15, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                  Organisation
                </Typography>
                <Typography variant="title4" sx={{ color: themeColors.black }}>
                  {depAccountDetails?.org_name ?? 'N/A'}
                </Typography>
                <Typography variant="caption" sx={{ color: themeColors.Grey, mt: spacing.m10 }}>
                  MDM Server name
                </Typography>
                <Typography variant="title4" sx={{ color: themeColors.black }}>
                  {depAccountDetails?.server_name ?? 'N/A'}
                </Typography>
                <Typography variant="caption" sx={{ color: themeColors.Grey, mt: spacing.m10 }}>
                  Renew by
                </Typography>
                <Typography variant="title4" sx={{ color: themeColors.black }}>
                  {depAccountDetails?.access_token_expiry
                    ? DepWizardHelper.formatDate(depAccountDetails?.access_token_expiry)
                    : 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="title3">ABM devices</Typography>
              <Box sx={{ ...definitionSx, mt: spacing.m15, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" sx={{ color: themeColors.Grey, mt: spacing.m10 }}>
                  Here, you can synchronize your inventory with Apple Business Manager manually, ensuring that you have
                  the latest devices assigned to your token. All new devices will be automatically created in the
                  'inventory' subsection of devices, keeping your device list organized and up to date
                </Typography>
              </Box>
              <Box sx={buttonBoxDrawerSx}>
                <ButtonComponent
                  colorVariant="primary"
                  sizeVariant="small"
                  disabled={isLoading}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await InHouseMdmAPI.syncDepDevices(mdmServerName);
                      showMessage('Action launched successfully', 'success');
                    } catch (error) {
                      showMessage('The launch of the action failed', 'error');
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  <RefreshIcon sx={{ ...iconSize }} />
                  Sync with ABM
                </ButtonComponent>
              </Box>
              <Box sx={conditionalSx}>
                <CheckboxComponent
                  name="autoSync"
                  label="Auto sync with ABM"
                  checked={isAutoSync}
                  onChange={async (e, checked) => {
                    try {
                      setLoading(true);
                      setAutoSync(checked);
                      await InHouseMdmAPI.setAutoSyncDepDevice(mdmServerName, checked);
                      await onRefresh();
                      showMessage('Action launched successfully', 'success');
                    } catch (error) {
                      showMessage('The launch of the action failed', 'error');
                    } finally {
                      setLoading(false);
                    }
                  }}
                />
              </Box>
            </Box>
            <Box sx={buttonBoxDrawerSx}>
              <LoaderButton
                name="Renew"
                fullWidth
                loading={isLoading}
                sizeVariant="medium"
                colorVariant="primary"
                onClick={() => handleRenew()}
              />
              <LoaderButton
                name="Disconnect"
                fullWidth
                loading={isLoading}
                sizeVariant="medium"
                colorVariant="secondary"
                onClick={() => setIsDeleteDepTokenDrawerOpen(true)}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
