import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { ScheduleTrackingSettingsFormData, ScheduleTrackingType } from '@v2/feature/attendance/attendance.interface';
import { ScheduleSettingsForm } from '@v2/feature/attendance/company/components/form/schedule-settings-form.component';
import { SiteDto } from '@v2/feature/site/site.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface AttendanceScheduleEditSettingsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
  readonly sites: readonly SiteDto[];
}

export const AttendanceScheduleEditSettingsDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh,
  sites,
}: AttendanceScheduleEditSettingsDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AttendanceScheduleEditSettingsDrawerContent
        attendanceSchedule={attendanceSchedule}
        refresh={refresh}
        setIsOpen={setIsOpen}
        sites={sites}
      />
    </DrawerModal>
  );
};

interface AttendanceScheduleEditSettingsDrawerContentProps {
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly sites: readonly SiteDto[];
}

const AttendanceScheduleEditSettingsDrawerContent = ({
  attendanceSchedule,
  refresh,
  setIsOpen,
  sites,
}: AttendanceScheduleEditSettingsDrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const attendanceTypesAllowedIds = useMemo(() => attendanceSchedule.attendanceTypesAllowed.map((r) => r.id), [
    attendanceSchedule,
  ]);

  const onSubmit = useCallback(
    async (values: ScheduleTrackingSettingsFormData) => {
      try {
        setLoading(true);
        const useGeolocation =
          values.trackingType === ScheduleTrackingType.ClockInClockOut ? Boolean(values.useGeolocation) : false;
        const restrictByGeolocation = Boolean(useGeolocation && values.restrictByGeolocation);

        const allowedWorkSites = restrictByGeolocation ? values.allowedWorkSites : [];
        const geolocationEmployeeSite = restrictByGeolocation ? values.geolocationEmployeeSite : false;
        const geolocationDistance = restrictByGeolocation ? values.geolocationDistance : null;
        const clockInEarlyCapMinutes =
          values.trackingType === ScheduleTrackingType.ClockInClockOut ? Number(values.clockInEarlyCapMinutes) : null;

        const update: ScheduleTrackingSettingsFormData = {
          trackingType: values.trackingType,
          attendanceTypesAllowedIds: values.attendanceTypesAllowedIds,
          timesheetType: values.timesheetType,
          useGeolocation,
          restrictByGeolocation,
          geolocationDistance,
          geolocationEmployeeSite,
          allowedWorkSites,
          clockInEarlyCapMinutes,
        };
        await AttendanceAPI.updateAttendanceScheduleTrackingSettings(attendanceSchedule.id, update);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AttendanceScheduleEditSettingsDrawer.errorMessages.update', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [attendanceSchedule.id, showMessage, refresh, setIsOpen, polyglot]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', height: '100%' }}>
      <Typography variant="title2">{polyglot.t('AttendanceSchedule.tracking')}</Typography>
      <ScheduleSettingsForm
        attendanceSchedule={{ ...attendanceSchedule, attendanceTypesAllowedIds }}
        onSubmit={onSubmit}
        loading={loading}
        buttonName={polyglot.t('General.save')}
        sites={sites}
      />
    </Box>
  );
};
