import { useCallback, useContext, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { RejectDrawer } from '@v2/components/reject-drawer.component';
import { canApproveOrRejectRequest } from '@v2/feature/approval-rule/approval-rule.util';
import { Expense, ExpenseStatus } from '@v2/feature/payments/payments.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx, buttonBoxSx } from '@v2/styles/settings.styles';

import { ExpenseAPI } from '@/api-client/expense.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';

interface ExpenseApprovalButtonsProps {
  readonly selectedExpense: Expense;
  readonly onClose: () => void;
  readonly onActionPerformed: () => Promise<void>;
}

export const ExpenseApprovalButtons = ({
  selectedExpense,
  onClose,
  onActionPerformed,
}: ExpenseApprovalButtonsProps) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);

  const { user } = state;

  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState<boolean>(false);
  const [isForceRejectDrawerOpen, setIsForceRejectDrawerOpen] = useState<boolean>(false);

  const [isUpdatingApproval, setIsUpdatingApproval] = useState<boolean>(false);

  const canApproveOrReject = !!selectedExpense && canApproveOrRejectRequest(selectedExpense, user.userId);
  const canManageExpenses = hasScopes(['expenses:all'], getScopesContext({ userId: selectedExpense?.from }));

  const handleApproveOrReject = useCallback(
    async (expenseId: string, from: number, status: ExpenseStatus, notes?: string) => {
      try {
        setIsUpdatingApproval(true);

        await ExpenseAPI.updateExpenseApproval(expenseId, from, { status, approvalNotes: notes ?? null });
        showMessage(polyglot.t('ExpenseModal.successMessages.update', { status }), 'success');

        onClose();
        await onActionPerformed();
      } catch (e) {
        showMessage(polyglot.t('ExpenseModal.errorMessages.update', { errorMessage: nestErrorMessage(e) }), 'error');
      } finally {
        setIsUpdatingApproval(false);
      }
    },
    [onClose, onActionPerformed, showMessage, polyglot]
  );

  const handleForceApproveOrReject = useCallback(
    async (expenseId: string, from: number, status: ExpenseStatus, notes?: string) => {
      try {
        setIsUpdatingApproval(true);

        await ExpenseAPI.updateExpenseForceApproval(expenseId, from, { status, approvalNotes: notes ?? null });
        showMessage(polyglot.t('ExpenseModal.successMessages.update', { status }), 'success');

        setIsUpdatingApproval(false);

        onClose();
        await onActionPerformed();
      } catch (e) {
        showMessage(polyglot.t('ExpenseModal.errorMessages.update', { errorMessage: nestErrorMessage(e) }), 'error');
      } finally {
        setIsUpdatingApproval(false);
      }
    },
    [onClose, onActionPerformed, showMessage, polyglot]
  );

  const canReject =
    selectedExpense?.status !== ExpenseStatus.Rejected && selectedExpense?.status !== ExpenseStatus.Draft;

  return (
    <Box sx={buttonBoxDrawerSx}>
      {canManageExpenses && !canApproveOrReject ? (
        <Box sx={{ ...buttonBoxSx, width: '100%' }}>
          {canReject && (
            <ButtonComponent
              disabled={isUpdatingApproval}
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={async () => {
                setIsForceRejectDrawerOpen(true);
              }}
            >
              {polyglot.t('General.forceReject')}
            </ButtonComponent>
          )}
          {selectedExpense?.status === ExpenseStatus.Pending && (
            <ButtonComponent
              disabled={isUpdatingApproval}
              sizeVariant="medium"
              colorVariant="secondary"
              fullWidth
              loading={isUpdatingApproval}
              onClick={async () => {
                await handleForceApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Approved);
              }}
            >
              {polyglot.t('General.forceApprove')}
            </ButtonComponent>
          )}
        </Box>
      ) : canApproveOrReject ? (
        <Box sx={{ ...buttonBoxSx, width: '100%' }}>
          {canReject && (
            <ButtonComponent
              disabled={isUpdatingApproval}
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={async () => {
                setIsRejectDrawerOpen(true);
              }}
            >
              {polyglot.t('General.reject')}
            </ButtonComponent>
          )}
          {selectedExpense?.status === ExpenseStatus.Pending && (
            <ButtonComponent
              disabled={isUpdatingApproval}
              sizeVariant="medium"
              colorVariant="primary"
              fullWidth
              loading={isUpdatingApproval}
              onClick={async () => {
                await handleApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Approved);
              }}
            >
              {polyglot.t('General.approve')}
            </ButtonComponent>
          )}
        </Box>
      ) : null}
      {selectedExpense && (
        <RejectDrawer
          isOpen={isRejectDrawerOpen}
          setIsOpen={setIsRejectDrawerOpen}
          onReject={async (notes) => {
            await handleApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Rejected, notes);
          }}
        />
      )}
      {selectedExpense && (
        <RejectDrawer
          isOpen={isForceRejectDrawerOpen}
          setIsOpen={setIsForceRejectDrawerOpen}
          onReject={async (notes) => {
            await handleForceApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Rejected, notes);
          }}
        />
      )}
    </Box>
  );
};
