import React, { useState } from 'react';

import { Box } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface CommonQuestionProps {
  id: string;
  questionText: string;
  factor: string | null;
}

interface FilterTypesProps {
  [key: string]: {
    label: string;
    value: string;
  }[];
}

interface GrowthQuestionLibraryContentProps {
  questions: CommonQuestionProps[] | null | undefined;
  setIsQuestionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: (selectedQuestionIds: Set<string>) => Promise<void>;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  searchInput: string;
  allGrowthFactors: GrowthFactor[] | null | undefined;
  setFilterString: React.Dispatch<React.SetStateAction<string>>;
  filterString: string;
  factorFilterTypes: FilterTypesProps | undefined;
  questionsLoading: boolean;
}
export const GrowthQuestionLibraryContent: React.FC<GrowthQuestionLibraryContentProps> = ({
  questions,
  setIsQuestionsModalOpen,
  action,
  setSearchInput,
  searchInput,
  allGrowthFactors,
  setFilterString,
  filterString,
  factorFilterTypes,
  questionsLoading,
}) => {
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<Set<string>>(new Set());

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedQuestionIds(new Set(questions?.map(({ id }) => id)));
    } else {
      setSelectedQuestionIds(new Set());
    }
  };

  const handleSelectQuestion = (id: string, checked: boolean) => {
    const newSelectedQuestionIds = new Set(selectedQuestionIds);
    if (checked) {
      newSelectedQuestionIds.add(id);
    } else {
      newSelectedQuestionIds.delete(id);
    }
    setSelectedQuestionIds(newSelectedQuestionIds);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <QuestionLibraryHeader
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setFilterString={setFilterString}
        filterString={filterString}
        factorFilterTypes={factorFilterTypes}
      />
      <Box
        sx={{
          mt: spacing.m24,
          px: spacing.px16,
          py: spacing.p12,
          display: 'flex',
          alignItems: 'center',
          gap: spacing.g12,
        }}
      >
        <CheckboxComponent
          labelPlacement="end"
          checked={selectedQuestionIds.size > 0 && selectedQuestionIds.size === questions?.length}
          disabled={questions?.length === 0}
          onChange={(_, checked) => handleSelectAll(checked)}
          sx={{ width: '100%' }}
        />
        <Typography variant="caption" sx={{ color: themeColors.Grey }}>
          All questions
        </Typography>
      </Box>

      {questionsLoading ? (
        <LoadingState />
      ) : questions ? (
        <Box sx={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
          {questions.map((question, idx) => (
            <QuestionRow
              key={question.id}
              question={question}
              allGrowthFactors={allGrowthFactors}
              selectedQuestionIds={selectedQuestionIds}
              handleSelectQuestion={handleSelectQuestion}
              isFirstRow={idx === 0}
            />
          ))}
        </Box>
      ) : (
        <Typography variant="caption" sx={spacing.px16}>
          No questions found
        </Typography>
      )}

      <Box
        sx={{
          boxSizing: 'border-box',
          position: 'sticky',
          bottom: '24px',
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: '16px',
        }}
      >
        <ButtonComponent
          colorVariant="primary"
          sizeVariant="medium"
          disabled={selectedQuestionIds.size === 0}
          onClick={async () => {
            if (selectedQuestionIds) await action(selectedQuestionIds);
            setIsQuestionsModalOpen(false);
          }}
        >
          Add questions
        </ButtonComponent>
      </Box>
    </Box>
  );
};

const QuestionRow = ({
  question,
  allGrowthFactors,
  selectedQuestionIds,
  handleSelectQuestion,
  isFirstRow,
}: {
  question: CommonQuestionProps;
  allGrowthFactors: GrowthFactor[] | null | undefined;
  selectedQuestionIds: Set<string>;
  handleSelectQuestion: (id: string, checked: boolean) => void;
  isFirstRow: boolean;
}) => {
  const factorColor = allGrowthFactors?.find((factor) => factor.name === question.factor)?.color ?? themeColors.Grey;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
        gap: spacing.g12,
        py: spacing.p12,
        borderTop: isFirstRow ? `1px solid ${themeColors.lightGrey}` : 'none',
        borderBottom: `1px solid ${themeColors.lightGrey}`,
        borderCollapse: 'collapse',
        px: spacing.px16,
        '&:hover': {
          backgroundColor: themeColors.TableHover,
        },
      }}
    >
      <Box sx={{ width: '80%', display: 'flex', gap: spacing.g12, alignItems: 'center' }}>
        <CheckboxComponent
          role="checkbox"
          id="question-check"
          checked={selectedQuestionIds.has(question.id)}
          onChange={(_, checked) => handleSelectQuestion(question.id, checked)}
          sx={{ mr: 'auto' }}
          label={question.questionText}
        />
      </Box>
      {question.factor && (
        <Box sx={{ width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: spacing.g4 }}>
          <div style={{ backgroundColor: factorColor, width: '8px', height: '8px', borderRadius: '32px' }} />
          <Typography variant="caption">{question.factor}</Typography>
        </Box>
      )}
    </Box>
  );
};

const QuestionLibraryHeader = ({
  searchInput,
  setSearchInput,
  setFilterString,
  filterString,
  factorFilterTypes,
}: {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setFilterString: React.Dispatch<React.SetStateAction<string>>;
  filterString: string;
  factorFilterTypes: FilterTypesProps | undefined;
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', px: spacing.px16, gap: spacing.g16 }}>
    <Typography variant="title3">Question library</Typography>
    <TableSearch
      style={{ width: '100%', boxSizing: 'border-box', marginTop: spacing.m24 }}
      query={searchInput}
      handleChange={(e) => setSearchInput(e.target.value)}
    />

    {factorFilterTypes && (
      <Box>
        <CategoryFilters
          filterTypes={factorFilterTypes}
          setFilterString={setFilterString}
          filterString={filterString}
        />
      </Box>
    )}
  </Box>
);

const LoadingState = () => {
  return (
    <Box
      sx={{
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7].map((idx) => {
        return (
          <Box
            key={idx}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              boxSizing: 'border-box',
              gap: spacing.g12,
              py: spacing.p12,
              borderTop: idx === 0 ? `1px solid ${themeColors.lightGrey}` : 'none',
              borderBottom: `1px solid ${themeColors.lightGrey}`,
              borderCollapse: 'collapse',
              px: spacing.px16,
              '&:hover': {
                backgroundColor: themeColors.TableHover,
              },
            }}
          >
            <Box sx={{ width: '80%', display: 'flex', gap: spacing.g12, alignItems: 'center', height: '20px' }}>
              <SkeletonLoader
                variant="rectangular"
                width="90%"
                height="90vh"
                sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
