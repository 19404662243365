import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { capitalize } from 'lodash';

import { Action, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  AnonymityType,
  ComparedBy,
  MeasureBy,
  ShowBy,
  SurveyCycle,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MeasureFilterToLabel = {
  [MeasureBy.NPS]: 'NPS',
  [MeasureBy.Positive]: 'Positive sentiment',
  [MeasureBy.Average]: 'Average',
};
export const SurveyCycleDetailHeatmapFilters = ({
  setShowByFilter,
  dispatch,
  setFilter,
  setMeasureByFilter,
  showByFilter,
  measureByFilter,
  filter,
  surveyCycle,
}: {
  setShowByFilter: React.Dispatch<React.SetStateAction<ShowBy>>;
  dispatch: React.Dispatch<Action>;
  setFilter: React.Dispatch<React.SetStateAction<ComparedBy>>;
  setMeasureByFilter: React.Dispatch<React.SetStateAction<MeasureBy>>;
  showByFilter: ShowBy;
  measureByFilter: MeasureBy;
  filter: ComparedBy;
  surveyCycle: SurveyCycle;
}) => {
  const [showMessage] = useMessage();

  const setShowByHandler = useCallback(
    async (showValue: ShowBy) => {
      try {
        setShowByFilter(showValue);
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('surveys', 'heatMap', 'showBy', showValue);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, setShowByFilter, showMessage]
  );

  const getActions = useMemo(
    () => [
      { handler: async () => await setShowByHandler(ShowBy.Questions), label: capitalize(ShowBy.Questions) },
      { handler: async () => await setShowByHandler(ShowBy.Factor), label: capitalize(ShowBy.Factor) },
    ],
    [setShowByHandler]
  );

  const setFilterHandler = useCallback(
    async (comparedValue: ComparedBy) => {
      try {
        setFilter(comparedValue);
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'surveys',
          'heatMap',
          'comparedBy',
          comparedValue
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, setFilter, showMessage]
  );

  const getFilters = useMemo(
    () => [
      {
        handler: async () => await setFilterHandler(ComparedBy.Site),
        label: capitalize(ComparedBy.Site),
        disabled: false,
      },
      {
        handler: async () => await setFilterHandler(ComparedBy.Department),
        label: capitalize(ComparedBy.Department),
        disabled: false,
      },
      {
        handler: async () => await setFilterHandler(ComparedBy.Gender),
        label: capitalize(ComparedBy.Gender),
        disabled: false,
      },
      {
        handler: async () => await setFilterHandler(ComparedBy.Tenure),
        label: capitalize(ComparedBy.Tenure),
        disabled: false,
      },
    ],
    [setFilterHandler]
  );

  const setMeasuredByHandler = useCallback(
    async (measureValue: MeasureBy) => {
      try {
        setMeasureByFilter(measureValue);
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('surveys', 'heatMap', 'measuredBy', measureValue);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, setMeasureByFilter, showMessage]
  );

  const measuredByOptions = useMemo(
    () => [
      {
        handler: async () => await setMeasuredByHandler(MeasureBy.NPS),
        label: MeasureBy.NPS,
      },
      {
        handler: async () => await setMeasuredByHandler(MeasureBy.Positive),
        label: MeasureBy.Positive,
      },
      {
        handler: async () => await setMeasuredByHandler(MeasureBy.Average),
        label: MeasureBy.Average,
      },
    ],
    [setMeasuredByHandler]
  );

  const shouldShouldComparedBy = useMemo(() => {
    return (
      (Boolean(surveyCycle?.visibilitySettings) && !Boolean(surveyCycle.visibilitySettings?.anonymiseAnswers)) ||
      (surveyCycle.visibilitySettings?.anonymiseAnswers &&
        surveyCycle.visibilitySettings?.anonymityType === AnonymityType.Semi)
    );
  }, [surveyCycle.visibilitySettings]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Typography variant="caption" color="Grey">
          Show by
        </Typography>
        <StyledMenuComponent
          options={getActions}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: capitalize(showByFilter),
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
            style: { borderRadius: radius.br25 },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Typography variant="caption" color="Grey">
          Measured by
        </Typography>
        <StyledMenuComponent
          options={measuredByOptions}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: MeasureFilterToLabel[measureByFilter],
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
            style: { borderRadius: radius.br25 },
          }}
        />
      </Box>

      {shouldShouldComparedBy && (
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Typography variant="caption" color="Grey">
            Compared by
          </Typography>
          <StyledMenuComponent
            options={getFilters}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: capitalize(filter),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              style: { borderRadius: radius.br25 },
            }}
          />
        </Box>
      )}
    </Box>
  );
};
