import { UpsertReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { UpsertSurveyAnswer } from '@/v2/feature/growth/surveys/interfaces/survey-answer.interface';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { SurveySection } from '@/v2/feature/growth/surveys/interfaces/survey-section.interface';

export type SurveyEntry = {
  createdAt: Date;
  updatedAt: Date;
  id: string;
  cycleId: string;
  participantId: number;
  entryState: EntryState;
  cycle?: SurveyCycle;
};

export type SurveyEntryUpdate = Pick<SurveyEntry, 'id' | 'cycleId' | 'participantId' | 'entryState'>;
// export type SurveyEntryUpdateAndPeerInvite = { entry: SurveyEntryUpdate; reviewersToAdd: UpsertReviewers };
export type EntryDetail = {
  cycle: SurveyCycle;
  entry: SurveyEntry;
  questions: SurveyQuestion[];
  sections: SurveySection[];
};

export enum EntryState {
  NotStarted = 'not_started',
  Draft = 'draft',
  Submitted = 'submitted',
}

export type GrowthEntry = ReviewEntry | SurveyEntry;
export type GrowthQuestion = ReviewQuestion | SurveyQuestion;
export type GrowthUpsertAnswer = UpsertReviewAnswer | UpsertSurveyAnswer;
export type UserMetaDataType = {
  siteId: number;
  siteName: string;
  departmentId: number;
  departmentName: string;
  startDate: string;
  dob: string;
  gender: string;
};
