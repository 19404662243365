import { useEffect } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  WifiOptions,
} from '@/v2/feature/device/device.dto';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmWifiPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): WifiOptions => {
    return {
      AutoJoin: configurablePoliciesSelected?.configurableWifi?.AutoJoin ?? false,
      EncryptionType: configurablePoliciesSelected?.configurableWifi?.EncryptionType ?? 'None',
      HIDDEN_NETWORK: configurablePoliciesSelected?.configurableWifi?.HIDDEN_NETWORK ?? false,
      SSID_STR: configurablePoliciesSelected?.configurableWifi?.SSID_STR ?? '',
      Password: configurablePoliciesSelected?.configurableWifi?.Password ?? '',
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.WIFI_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };
  const encryptionTypes = [
    { label: 'Not set', value: 'None' },
    { label: 'WEP', value: 'WEP' },
    { label: 'WPA', value: 'WPA' },
    { label: 'WPA2', value: 'WPA2' },
    { label: 'WPA3', value: 'WPA3' },
  ];
  const initialValues: WifiOptions = getPolicy();

  function analyzePolicyBeforeSave(policy: WifiOptions) {
    if (
      !policy.AutoJoin &&
      !policy.HIDDEN_NETWORK &&
      policy.EncryptionType === 'None' &&
      policy.SSID_STR === '' &&
      policy.Password === ''
    ) {
      return undefined;
    }
    return {
      AutoJoin: policy.AutoJoin,
      EncryptionType: policy.EncryptionType,
      HIDDEN_NETWORK: policy.HIDDEN_NETWORK,
      SSID_STR: policy.SSID_STR,
      Password: policy.Password,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.WIFI_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }

  const formik = useFormik<WifiOptions>({
    initialValues,
    onSubmit: async (form: WifiOptions) => {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableWifi: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="title2">WiFi</Typography>

      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20, mt: '20px' }}>
          <TextfieldComponent
            label="Network name - SSID"
            value={formik.values.SSID_STR}
            type="text"
            onChange={(e) => {
              const updatedValue = e.currentTarget.value;
              formik.setFieldValue('SSID_STR', updatedValue);
            }}
            clearText={() => formik.setFieldValue('SSID_STR', '')}
          />
          <CheckboxComponent
            label="Automatically join the network"
            name="AutoJoin"
            checked={formik.values.AutoJoin}
            onChange={(e, checked) => {
              formik.setFieldValue('AutoJoin', checked);
            }}
          />
          <SelectComponent
            name="EncryptionType"
            label="Security versions"
            options={encryptionTypes}
            value={formik.values.EncryptionType}
            onChange={(e) => {
              const updatedValue = e.target.value;
              formik.setFieldValue('EncryptionType', updatedValue);
              if (updatedValue === 'None') {
                formik.setFieldValue('Password', '');
              }
            }}
          />
        </Box>
        {formik.values.EncryptionType !== 'None' && (
          <Box sx={{ display: 'flex', width: '100%', marginTop: '10px' }}>
            <TextfieldComponent
              label="Password"
              value={formik.values.Password}
              type="password"
              onChange={(e) => {
                const updatedValue = e.currentTarget.value;
                formik.setFieldValue('Password', updatedValue);
              }}
              endAdornment="none"
              clearText={() => formik.setFieldValue('Password', '')}
            />
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: '30px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
