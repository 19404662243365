import { Stack } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { PayrollOtherSettingsSection } from '@v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-other-settings-section.component';

import { PayrollDeleteSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-delete-section.component';
import { PayrollGeneralSettingsSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-general-settings-section.component';
import { PayrollScheduleSettingsSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-schedule-settings-section.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
  readonly refreshPayroll: () => Promise<void>;
}

export const PayrollCompanySettingsGeneral = ({ payroll, refreshPayroll }: Props): JSX.Element => {
  return (
    <Stack sx={{ maxWidth: '650px', gap: spacing.g40, my: spacing.my20 }}>
      <PayrollGeneralSettingsSection payroll={payroll} refreshPayroll={refreshPayroll} />
      <PayrollScheduleSettingsSection payroll={payroll} refreshPayroll={refreshPayroll} />
      <PayrollOtherSettingsSection payroll={payroll} refreshPayroll={refreshPayroll} />
      <PayrollDeleteSection payroll={payroll} refreshPayroll={refreshPayroll} />
    </Stack>
  );
};
