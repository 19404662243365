import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';

export const emojiOptions = [
  {
    label: '✈️',
    value: ':airplane:',
  },
  {
    label: '👋',
    value: ':wave:',
  },
  {
    label: '🏆',
    value: ':trophy:',
  },
  {
    label: '🎯',
    value: ':dart:',
  },
  {
    label: '🚀',
    value: ':rocket:',
  },
  {
    label: '❗',
    value: ':exclamation:',
  },
];

export const everyoneOption = { label: 'Everyone', value: 'public-org' } as OptionObj;
