import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AttendanceAPI, AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { ScheduleWeeksPattern } from '@v2/feature/attendance/attendance.interface';
import { getScheduleValidationSchemaV2, getTheDateOfFirstMondayInYear } from '@v2/feature/attendance/attendance.util';
import { AttendancePatternScheduleStartDateDrawer } from '@v2/feature/attendance/settings/components/attendance-pattern-schedule-start-date-drawer.component';
import { AttendanceScheduleWeekPatternForm } from '@v2/feature/attendance/settings/components/attendance-schedule-week-pattern-form.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE } from '@/lib/routes';
import { RootStyle } from '@/v2/styles/root.styles';

export const AttendanceSettingsSchedulePatternPage = () => {
  const params = useParams<{ scheduleId: string }>();
  const scheduleId = Number(params.scheduleId);
  const [isSetDateOpen, setIsSetDateOpen] = useState(false);

  const {
    data: schedule,
    mutate: refreshAttendanceSchedule,
    isLoading,
  } = useApiClient(AttendanceEndpoints.getAttendanceScheduleById(scheduleId), { suspense: false });

  const history = useHistory();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [patternError, setPatternError] = useState(false);

  const refreshSchedule = useCallback(async () => {
    if (refreshAttendanceSchedule) await refreshAttendanceSchedule();
  }, [refreshAttendanceSchedule]);

  const formik = useFormik<ScheduleWeeksPattern>({
    initialValues: {
      monday: schedule?.monday ?? [],
      tuesday: schedule?.tuesday ?? [],
      wednesday: schedule?.wednesday ?? [],
      thursday: schedule?.thursday ?? [],
      friday: schedule?.friday ?? [],
      saturday: schedule?.saturday ?? [],
      sunday: schedule?.sunday ?? [],
      noOfWeeks: schedule?.noOfWeeks ?? 0,
      startDateOfFirstWeek: schedule?.startDateOfFirstWeek ?? getTheDateOfFirstMondayInYear(),
    },
    validationSchema: getScheduleValidationSchemaV2(polyglot),
    onSubmit: async (values: ScheduleWeeksPattern) => {
      if (!schedule) return;

      setLoading(true);
      try {
        await AttendanceAPI.updateAttendanceSchedulePattern(schedule.id, values);
        await refreshSchedule();
        history.push(generatePath(SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, { scheduleId: schedule.id }));
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setLoading(false);
    },
  });

  const weeksNumbers = useMemo(() => {
    if (!formik.values.noOfWeeks) return [];
    const weeks: number[] = [];
    for (let i = 0; i < formik.values.noOfWeeks; i += 1) weeks.push(i);
    return weeks;
  }, [formik.values.noOfWeeks]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Box>
            <Typography variant="title2">{polyglot.t('AttendanceModule.defineSchedulePatternTitle')}</Typography>
            <Typography variant="bodyLarge">{schedule?.name ?? ''}</Typography>
          </Box>
        }
        showBack
        backPath={generatePath(SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, { scheduleId })}
      />
      <ContentWrapper loading={isLoading} sx={spacing.pt20}>
        {schedule && (
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', gap: '40px', mb: '20px' }}>
                {weeksNumbers.map((weekIndex) => {
                  return (
                    <AttendanceScheduleWeekPatternForm
                      formik={formik}
                      weekNo={weekIndex}
                      schedule={schedule}
                      noOfWeeks={weeksNumbers.length}
                    />
                  );
                })}
              </Box>

              {patternError && (
                <Typography variant="captionSmall" sx={{ mb: '10px' }} color="Red">
                  {polyglot.t('AttendanceCompanySettingsAttendanceSchedulesPage.invalidPatternMessage')}
                </Typography>
              )}

              <Box sx={{ maxWidth: '200px' }}>
                <LoaderButton
                  sizeVariant="medium"
                  loading={loading}
                  colorVariant="primary"
                  name={formik.values.noOfWeeks > 1 ? polyglot.t('General.continue') : polyglot.t('General.save')}
                  fullWidth
                  onClick={() => {
                    let invalidRecords = false;
                    let allWeeksAreEmpty = false;

                    if (!allRecordsAreValid(formik.values)) invalidRecords = true;

                    const emptyWeeks = getEmptyWeeksIndexes(formik.values);
                    if (emptyWeeks.length >= formik.values.noOfWeeks) allWeeksAreEmpty = true;

                    if (allWeeksAreEmpty || invalidRecords) {
                      setPatternError(true);
                      return;
                    } else {
                      setPatternError(false);
                    }
                    if (formik.values.noOfWeeks > 1) setIsSetDateOpen(true);
                    else formik.handleSubmit();
                  }}
                />
              </Box>

              <AttendancePatternScheduleStartDateDrawer
                isOpen={isSetDateOpen}
                setIsOpen={setIsSetDateOpen}
                formik={formik}
                loading={loading}
              />
            </Form>
          </FormikProvider>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};

const getEmptyWeeksIndexes = (data: ScheduleWeeksPattern): number[] => {
  const emptyWeeks: number[] = [];
  for (let i = 0; i < data.noOfWeeks; i += 1) {
    if (
      (!data.monday[i]?.from || !data.monday[i]?.to) &&
      (!data.tuesday[i]?.from || !data.tuesday[i]?.to) &&
      (!data.wednesday[i]?.from || !data.wednesday[i]?.to) &&
      (!data.thursday[i]?.from || !data.thursday[i]?.to) &&
      (!data.friday[i]?.from || !data.friday[i]?.to) &&
      (!data.saturday[i]?.from || !data.saturday[i]?.to) &&
      (!data.sunday[i]?.from || !data.sunday[i]?.to)
    ) {
      emptyWeeks.push(i);
    }
  }
  return emptyWeeks;
};

const allRecordsAreValid = (data: ScheduleWeeksPattern): boolean => {
  for (let i = 0; i < data.noOfWeeks; i += 1) {
    if (
      (data.monday[i] && (!data.monday[i]?.from || !data.monday[i]?.to)) ||
      (data.tuesday[i] && (!data.tuesday[i]?.from || !data.tuesday[i]?.to)) ||
      (data.wednesday[i] && (!data.wednesday[i]?.from || !data.wednesday[i]?.to)) ||
      (data.thursday[i] && (!data.thursday[i]?.from || !data.thursday[i]?.to)) ||
      (data.friday[i] && (!data.friday[i]?.from || !data.friday[i]?.to)) ||
      (data.saturday[i] && (!data.saturday[i]?.from || !data.saturday[i]?.to)) ||
      (data.sunday[i] && (!data.sunday[i]?.from || !data.sunday[i]?.to))
    )
      return false;
  }
  return true;
};
