import { ZeltDocument } from '@/lib/documents';

export const isDefined = (value: unknown) => value !== undefined && value !== null && value !== '';
export type RecordWithEffectiveDate<T> = T & { effectiveDate?: Date | string };

// stop filtering by a hardcoded list of acceptable onboarding documents
export function filterUserOnboardingDocuments(documents: ZeltDocument[]): ZeltDocument[] {
  return documents.filter((d) => !d.canAllEmployeesSee && !d.contractId);
}

export function filterPendingOnboardingContracts(documents: ZeltDocument[]): ZeltDocument[] {
  return documents.filter((d) => !d.canAllEmployeesSee && d.contractId);
}
