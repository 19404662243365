/* eslint-disable filenames/match-regex */
import { Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import moment from 'moment';

import { CompanyPlanInfo, SubscriptionPlanInfo } from '@/models/company.model';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

const DATE_FORMAT = 'DD/MM/YYYY';

export enum ProductTypes {
  'PEOPLE' = 'People',
  'APPS' = 'Apps',
  'DEVICES' = 'Devices',
  'MONEY' = 'Money',
}

export const getPlanFromSubscriptions = (
  productType: ProductTypes,
  subscriptions: SubscriptionPlanInfo[]
): SubscriptionPlanInfo | undefined => {
  return subscriptions.find((subscription) => subscription?.planName?.startsWith(productType));
};

export const groupSubscriptionDiscounts = (subscription: SubscriptionPlanInfo | undefined): string[] => {
  if (!subscription) return [];
  return subscription.discounts.map((discount) => discount.description);
};

export class SuperAdminBillingPlanTableHelper {
  static getProductPlanColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
    productType: ProductTypes;
  }): ColumnDef<CompanyPlanInfo> {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions },
        },
      }) => (
        <div>
          {subscriptions.length ? (
            getPlanFromSubscriptions(productType, subscriptions)?.planName?.split(' ')[1]
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDateColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
    productType: ProductTypes;
  }): ColumnDef<CompanyPlanInfo> {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions },
        },
      }) => (
        <div>
          {subscriptions.length ? (
            moment(getPlanFromSubscriptions(productType, subscriptions)?.activationDate as Date).format(DATE_FORMAT)
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
    productType: ProductTypes;
  }): ColumnDef<CompanyPlanInfo> {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions },
        },
      }) =>
        subscriptions.length ? (
          <div>
            {groupSubscriptionDiscounts(
              getPlanFromSubscriptions(productType, subscriptions) as SubscriptionPlanInfo
            ).map((discount) => (
              <Typography sx={themeFonts.caption} width="100%">
                {discount}
              </Typography>
            ))}
          </div>
        ) : (
          <EmptyCell />
        ),
      size,
    };
  }

  static getPriceColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
    productType: ProductTypes;
  }): ColumnDef<CompanyPlanInfo> {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions },
        },
      }) => (
        <div>
          {subscriptions.length ? (
            getPlanFromSubscriptions(productType, subscriptions)?.cost
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }
}
