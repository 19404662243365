import { FormControlLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { OnboardingTemplateDevice } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type EditDevicesProps = {
  config: OnboardingTemplateDevice | undefined;
  onSave: (config: OnboardingTemplateDevice) => void;
};

export const EditDevices = (props: EditDevicesProps) => {
  const { polyglot } = usePolyglot();
  const { onSave, config } = props;

  const formik = useFormik({
    initialValues: {
      chooser: config?.chooser || 'admin',
      filter: config?.filter || null,
    },
    onSubmit: (values) => {
      onSave(values);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={themeFonts.title2}>{polyglot.t('EditDevices.title')}</Typography>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>{polyglot.t('EditDevices.desc')}</Typography>

        <Stack sx={{ mt: spacing.mt30, gap: spacing.g20 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(event) => {
              formik.setFieldValue('chooser', event.target.value);
            }}
          >
            <FormControlLabel
              labelPlacement="end"
              value={'admin'}
              checked={formik.values.chooser === 'admin'}
              control={<StyledRadio />}
              label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('EditDevices.adminOrManager')}</Typography>}
            />
            <FormControlLabel
              labelPlacement="end"
              value={'new-joiner'}
              checked={formik.values.chooser === 'new-joiner'}
              control={<StyledRadio />}
              label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('EditDevices.new')}</Typography>}
            />
          </RadioGroup>
        </Stack>

        {formik.values.chooser && (
          <ButtonComponent fullWidth style={{ marginTop: '40px' }} colorVariant="primary" sizeVariant="large">
            {polyglot.t('General.save')}
          </ButtonComponent>
        )}
      </Form>
    </FormikProvider>
  );
};
