import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { primarySmallBtn } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import {
  DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDERS_ROUTE,
} from '@/lib/routes';

interface MyDevicesActivationPageProps {
  loading: boolean;
  readonly deviceOrders: readonly DeviceOrderDto[];
}

export const DevicesActivationPage = ({ deviceOrders, loading }: MyDevicesActivationPageProps) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices Activation</Typography>}
      />
      <ContentWrapper loading={loading}>
        <Box>
          {deviceOrders.length > 0 ? (
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              We found multiple in transit devices. Select the device that you would like to activate please.
            </Typography>
          ) : (
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              We could not find any incoming devices. Perhaps someone else already confirmed the delivery of the device.
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap20, mt: spacing.mt20, width: 0.3 }}>
            {deviceOrders.map((order) => (
              <Card>
                <CardContent>
                  {order.id && <Typography sx={themeFonts.caption}>Order Id {order.id}</Typography>}
                  {order.device?.modelName && <Typography sx={themeFonts.caption}>{order.device.modelName}</Typography>}
                  {order.device?.serialNumber && (
                    <Typography sx={themeFonts.caption}>Serial Number: {order.device.serialNumber}</Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    sx={primarySmallBtn}
                    onClick={() => {
                      if (location.pathname.includes(DEVICES_ME_ORDERS_ROUTE))
                        history.push(generatePath(DEVICES_ME_ORDER_ACTIVATION_ROUTE, { orderId: order.id }));
                      else history.push(generatePath(DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE, { orderId: order.id }));
                    }}
                  >
                    Activate Device
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
