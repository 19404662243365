import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { sortNumeric } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { SuperAdminOrderRefinancingCancellationDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-order-refinancing-cancellation-drawer.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

export const SuperAdminDeviceDetailsOrders = ({
  deviceId,
  refreshDeviceDetails,
}: {
  deviceId: number;
  refreshDeviceDetails: () => Promise<void>;
}) => {
  const {
    data: deviceOrders,
    isLoading,
    mutate: refresh,
  } = useApiClient(DeviceEndpoints.getOrdersByDeviceIdAsSuperAdmin(deviceId), { suspense: true });

  const [selectedOrder, setSelectedOrder] = useState<DeviceOrderDto | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const refreshOrders = useCallback(async () => {
    if (refresh) refresh();
  }, [refresh]);

  const columns = useMemo<ColumnDef<DeviceOrderDto, DeviceOrderDto>[]>(() => {
    return [
      {
        id: 'id',
        header: 'ID',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.id}</Typography>
          </Box>
        ),
      },
      {
        id: 'companyId',
        header: 'Company ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.companyId}</Typography>
          </Box>
        ),
      },
      {
        id: 'deviceId',
        header: 'Device ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.deviceId}</Typography>
          </Box>
        ),
      },
      {
        id: 'deviceModelId',
        header: 'Device model ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.deviceModelId}</Typography>
          </Box>
        ),
      },
      {
        id: 'possessionId',
        header: 'Possession ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.possessionId}</Typography>
          </Box>
        ),
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.status}</Typography>
          </Box>
        ),
      },
      {
        id: 'deliveryDate',
        header: 'Delivery date',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.deliveryDate ? <Typography variant="caption">{original.deliveryDate}</Typography> : <EmptyCell />}
          </Box>
        ),
      },
      {
        id: 'deliveryAddress',
        header: 'Delivery address',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.deliveryAddress ? (
              <Typography variant="caption">{original.deliveryAddress}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'deliveryCountry',
        header: 'Delivery country',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.deliveryCountry ? (
              <Typography variant="caption">{original.deliveryCountry}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'createdBy',
        header: 'Created by',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.createdBy}</Typography>
          </Box>
        ),
      },
      {
        id: 'price',
        header: 'Price',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>{original.price ? <Typography variant="caption">{original.price}</Typography> : <EmptyCell />}</Box>
        ),
      },
      {
        id: 'isPurchase',
        header: 'Is purchase',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.isPurchase ? 'Yes' : 'No'}</Typography>
          </Box>
        ),
      },
      {
        id: 'contractLength',
        header: 'Contract length',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.contractLength ? (
              <Typography variant="caption">{original.contractLength}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'phoneNumber',
        header: 'Phone number',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.phoneNumber ? <Typography variant="caption">{original.phoneNumber}</Typography> : <EmptyCell />}
          </Box>
        ),
      },
      {
        id: 'trackingLink',
        header: 'Tracking link',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.trackingLink ? <Typography variant="caption">{original.trackingLink}</Typography> : <EmptyCell />}
          </Box>
        ),
      },
      {
        id: 'externallyInvoiced',
        header: 'Externally invoiced',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.externallyInvoiced ? (
              <Typography variant="caption">{original.externallyInvoiced}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'eligibleForRefinance',
        header: 'Eligible for refinance',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.eligibleForRefinance ? 'Yes' : 'No'}</Typography>
          </Box>
        ),
      },
      {
        id: 'createdAt',
        header: 'Created at',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{new Date(original.createdAt).toLocaleString()}</Typography>
          </Box>
        ),
      },
      {
        id: 'updatedAt',
        header: 'Updated at',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{new Date(original.updatedAt).toLocaleString()}</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  const handleRowClick = useCallback((row: Row<DeviceOrderDto>) => {
    setSelectedOrder(row.original);
    setIsOpen(true);
  }, []);

  return (
    <Box>
      <Typography variant="title3">Device orders</Typography>

      <Box sx={{ mt: '5px' }}>
        <BasicTable<DeviceOrderDto>
          rowData={deviceOrders ?? []}
          columnData={columns}
          loading={isLoading}
          hidePagination
          rowClick={handleRowClick}
        />

        <SuperAdminOrderRefinancingCancellationDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          deviceOrder={selectedOrder}
          refreshOrders={refreshOrders}
          refreshDeviceDetails={refreshDeviceDetails}
        />
      </Box>
    </Box>
  );
};
