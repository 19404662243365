import React, { Fragment } from 'react';

import { Box } from '@mui/material';
import Polyglot from 'node-polyglot';
import { Redirect, Switch, generatePath, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE,
} from '@/lib/routes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { SurveyTemplateEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-template.api';
import { SurveyTemplateBuilderPage } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/pages/survey-template-builder.page';
import { SurveyTemplateGeneralPage } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/pages/survey-template-general.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPageConfig = (templateId: string, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'General',
          stub: 'general',
          path: generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE, { templateId }),
        },
        {
          title: 'Questions',
          stub: 'questions',
          path: generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE, { templateId }),
        },
      ],
    },
  ] as NavConfigItem[];
};

export const SurveyTemplateOverviewPage = () => {
  const params = useParams<{ templateId: string }>();
  const templateId = params.templateId;
  const { polyglot } = usePolyglot();

  const {
    data: templateObject,
    mutate: refreshTemplate,
    isLoading: templateLoading,
  } = useApiClient(SurveyTemplateEndpoints.getSurveyTemplateStructurebyId(templateId), { suspense: false });
  return (
    <Fragment>
      <DomainSideMenuContent
        title="Templates"
        subtitle={''}
        pageConfig={getPageConfig(templateId, polyglot)}
        backPath={SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper loading={templateLoading} noHorizontalPadding>
        <Box className="main-title" sx={{ p: spacing.p16 }}>
          <Typography variant="title2">
            {templateObject && templateObject.template ? templateObject.template.name : 'Template'}
          </Typography>
        </Box>
        <Switch>
          <RouteScopesControl scopes={['surveys:all']} path={SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE}>
            <SurveyTemplateGeneralPage templateObject={templateObject} refreshTemplate={refreshTemplate} />
          </RouteScopesControl>
          <RouteScopesControl scopes={['surveys:all']} path={SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE}>
            <SurveyTemplateBuilderPage templateObject={templateObject} refreshTemplate={refreshTemplate} />
          </RouteScopesControl>

          <Redirect to={SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE} />
        </Switch>
      </ContentWrapper>
    </Fragment>
  );
};
