import React, { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';

import { CompanyAPI } from '@/api-client/company.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UploadPublicImage } from '@/v2/components/uploadPublicImage/upload-public-image.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface CompanySettingsCustomisationDetailsDrawerProps {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  readonly generalSettings: GeneralSettingsDto;
  readonly refreshSettings: KeyedMutator<GeneralSettingsDto> | undefined;
}

const validationSchema = () =>
  yup.object({
    showWallpaper: yup.boolean().required(),
  });
export const CompanySettingsCustomisationDetailsDrawer = ({
  setEditMode,
  editMode,
  generalSettings,
  refreshSettings,
}: CompanySettingsCustomisationDetailsDrawerProps) => {
  return (
    <DrawerModal isOpen={editMode} setIsOpen={setEditMode}>
      <CompanySettingsCustomisationDetailsContent
        setEditMode={setEditMode}
        generalSettings={generalSettings}
        refreshSettings={refreshSettings}
      />
    </DrawerModal>
  );
};

const CompanySettingsCustomisationDetailsContent = ({
  setEditMode,
  generalSettings,
  refreshSettings,
}: {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly generalSettings: GeneralSettingsDto;
  readonly refreshSettings: KeyedMutator<GeneralSettingsDto> | undefined;
}) => {
  const [localAvatar, setLocalAvatar] = useState<string>(generalSettings?.wallpaper ?? '');
  const [loading, setLoading] = useState<boolean>(false);
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);

  const formik = useFormik({
    initialValues: {
      showWallpaper: Boolean(generalSettings?.showWallpaper) || false,
    },
    enableReinitialize: true,
    validationSchema: validationSchema(),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const update = {
          name: generalSettings.name,
          showWallpaper: values?.showWallpaper,
        };

        await CompanyAPI.updateGeneralSettings(update);
        const res = await AuthAPI.getAuthMe(false);
        await refreshSettings?.();
        setEditMode(false);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: res.user,
        });
        showMessage(polyglot.t('CompanySettingsGeneralEdit.successMessages.update'), 'success');
      } catch (error) {
        showMessage(
          `${polyglot.t('CompanySettingsGeneralEdit.errorMessages.update')}: ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {polyglot.t('CompanySettingsCustomisationDetailsContent.customisation')}
        </Typography>
        <Typography variant="caption">{polyglot.t('CompanySettingsCustomisationDetailsContent.upload')}</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <SwitchComponent
            checked={formik.values.showWallpaper}
            name="showWallpaper"
            onChange={async (_e, enabled) => {
              formik.setFieldValue('showWallpaper', enabled);
            }}
          />
          <Typography variant="caption">
            {polyglot.t('CompanySettingsCustomisationDetailsContent.displayCustom')}
          </Typography>
        </Box>

        <UploadPublicImage
          disabled={false}
          value={localAvatar}
          onChange={(tempAvatar) => {
            setLocalAvatar(tempAvatar ?? '');
          }}
          url={CompanyAPI.getCompanyUploadWallpaperURL()}
          refresh={async () => {
            await refreshSettings?.();
          }}
          width="100%"
          handleDelete={async () => {
            try {
              await CompanyAPI.deleteCompanyWallpaper();
              setLocalAvatar('');
            } catch (error) {
              showMessage(
                `${polyglot.t('CompanySettingsGeneralEdit.errorMessages.delete')}: ${nestErrorMessage(error)}`,
                'error'
              );
            }
          }}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="secondary"
            disabled={loading}
            onClick={() => setEditMode(false)}
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            type="submit"
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            disabled={loading}
            loading={loading}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
