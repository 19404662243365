export function isPaycodeForAdditions(paycode: GlobalPaycode): boolean {
  return paycode.credit === 'Employee';
}

export function isPaycodeForDeductions(paycode: GlobalPaycode): boolean {
  return !isPaycodeForAdditions(paycode) && paycode.debit === 'Employee';
}

export function isPaycodeForOtherEmployerPayments(paycode: GlobalPaycode): boolean {
  return (
    !isPaycodeForAdditions(paycode) &&
    !isPaycodeForDeductions(paycode) &&
    paycode.debit === 'Employer' &&
    paycode.credit !== 'Employee'
  );
}
