import { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { formatBytes, formatDuration, IPreviewProps } from 'react-dropzone-uploader';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Subtract.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize = { width: 12, height: 12 } as const;

export const CustomPreview = ({
  fileWithMeta: { remove, restart },
  meta: { name = '', percent = 0, size = 0, status, duration },
  isUpload,
  canRestart,
}: IPreviewProps) => {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [title, setTitle] = useState(`${name || '?'}, ${formatBytes(size)}`);

  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(percent);
        setBuffer(10);
      }
    };

    if (duration) setTitle(`${title}, ${formatDuration(duration)}`);
    if (status === 'error_upload_params' || status === 'exception_upload' || status === 'error_upload') {
      setTitle(`${title} (upload failed)`);
    }
    if (status === 'aborted') setTitle(`${title} (cancelled)`);

    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 55,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {status === 'done' ? (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DocumentIcon {...iconSize} />
              <Box component="span" sx={{ ...themeFonts.title4, marginLeft: spacing.m10, color: themeColors.DarkGrey }}>
                {title}
              </Box>
            </Box>

            <IconButton sx={tableIconButtonSx} onClick={remove} title="Remove">
              <TrashIcon {...iconSize} />
            </IconButton>
          </Box>
        ) : !['error_upload_params', 'exception_upload', 'error_upload', 'aborted', 'ready'].includes(status) ||
          status === 'preparing' ||
          status === 'uploading' ? (
          <Box sx={{ display: 'block', width: '100%' }}>
            {isUpload && (
              <Box sx={{ paddingBottom: spacing.p15 }}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Loading...</Typography>
              </Box>
            )}
            {isUpload && (
              <LinearProgress
                variant="determinate"
                value={status === 'headers_received' ? 100 : percent}
                valueBuffer={buffer}
                sx={{
                  height: 4,
                  width: '100%',
                  mr: 2,
                  backgroundColor: themeColors.TableHover,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: themeColors.ZeltYellow,
                  },
                }}
              />
            )}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.darkRed }}>Download error</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={tableIconButtonSx} onClick={remove}>
                <Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
              </IconButton>

              {canRestart && (
                <IconButton sx={{ ...tableIconButtonSx, marginLeft: spacing.m5 }} onClick={restart}>
                  <Reload {...iconSize} />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
