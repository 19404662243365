import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewSection } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';
import { CycleState, CycleType, OrderPreference } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SelectedFiltersRequest } from '@/v2/feature/reports/reports.interface';
import { themeColors } from '@/v2/styles/colors.styles';

export type ReviewCycle = {
  companyId: number;
  internalName: string;
  displayName: string;
  type: CycleType;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  owner: number;
  reviewerSelect: ReviewerTypes[];
  revieweeId: number[];
  order: OrderPreference[];
  state: CycleState;
  cycleSettings: CycleSettings;
  visibilitySettings: VisibilitySettings;
  peerReviewerIds?: { [key: number]: number[] };
  upwardReviewerIds?: { [key: number]: number[] };
  managerReviewerIds?: { [key: number]: number[] };
  timelineSettings: TimelineSettings;
  notificationSettings: NotificationSettings;
  enrolmentTriggerFilters?: SelectedFiltersRequest | null;
};

export type ReviewCycleUpdate = Pick<
  ReviewCycle,
  | 'id'
  | 'internalName'
  | 'displayName'
  | 'revieweeId'
  | 'cycleSettings'
  | 'peerReviewerIds'
  | 'upwardReviewerIds'
  | 'managerReviewerIds'
  | 'visibilitySettings'
  | 'timelineSettings'
  | 'notificationSettings'
  | 'type'
  | 'enrolmentTriggerFilters'
>;
export type ReviewCycleCreate = Pick<ReviewCycle, 'id' | 'internalName' | 'displayName' | 'type'>;
export type ReviewCycleLaunch = Pick<
  ReviewCycle,
  | 'id'
  | 'internalName'
  | 'revieweeId'
  | 'reviewerSelect'
  | 'timelineSettings'
  | 'state'
  | 'type'
  | 'enrolmentTriggerFilters'
> & {
  totalQuestions: number;
  participantCount: number;
};
export type ReviewCycleById = {
  cycle: ReviewCycle;
  sections: ReviewSection[];
  questions: ReviewQuestion[];
};

export type OrderPreferenceCycleUpdate = {
  order: OrderPreference[];
  cycleId: string;
};

export type RemoveFromSectionCycle = {
  cycleId: string;
  questionId: string;
  sectionId: string;
};

export type MoveToSectionCycle = {
  cycleId: string;
  questionId: string;
  sectionId: string;
};

export type ReviewParticipants = {
  selfId: number;
  peerIds: number[];
  upwardIds: number[];
  managerIds: number[];
  isEditable: boolean;
};

export type ReviewParticipationObject = { percent: number | null; overdue: boolean; show: boolean };

export interface CycleSettings {
  allowRevieweesToInvitePeers: boolean;
}

export type VisibilitySettings = {
  allowManagerToReleaseFeedback: boolean;
  hidePeerAuthor: boolean;
};

export type TimelineSettings = {
  startNow: boolean;
  startDate: string | null;
  startTime: string | null;
  startTimeMeridiem: string | null;
  selfReviewDeadline: number | null;
  upwardReviewDeadline: number | null;
  peerReviewDeadline: number | null;
  managerReviewDeadline: number | null;
};

export type NotificationSettings = {
  reminderFrequency: ReminderFrequencyValue;
};

export type UpsertReviewers = {
  reviewers: { reviewerType: ReviewerTypes; ids: number[] }[];
  revieweeId: number;
};

export type RemindReviewers = {
  cycleId: string;
  revieweeIds: number[];
};

export enum ReminderFrequencyValue {
  Daily = 'daily',
  EveryMonday = 'everyMonday',
  None = 'none',
}

export enum ReviewerTypes {
  Peer = 'Peer',
  Self = 'Self',
  Manager = 'Manager',
  Upward = 'Upward',
}

export type ReviewerTypesColors = {
  [key in ReviewerTypes]: keyof typeof themeColors;
};

export enum ReminderFrequencyLabel {
  Daily = 'Daily',
  EveryMonday = 'Weekly on Monday',
  None = 'None',
}

export enum ReminderType {
  All = 'all',
  Overdue = 'overdue',
}

export enum ReachType {
  Team = 'team',
  Company = 'company',
  Me = 'me',
}

export const ReminderFrequencyOptions = [
  {
    value: ReminderFrequencyValue.Daily,
    label: ReminderFrequencyLabel.Daily,
  },
  {
    value: ReminderFrequencyValue.EveryMonday,
    label: ReminderFrequencyLabel.EveryMonday,
  },
  {
    value: ReminderFrequencyValue.None,
    label: ReminderFrequencyLabel.None,
  },
];

export const ReminderFrequencyDict = {
  [ReminderFrequencyValue.Daily]: ReminderFrequencyLabel.Daily,
  [ReminderFrequencyValue.EveryMonday]: ReminderFrequencyLabel.EveryMonday,
  [ReminderFrequencyValue.None]: ReminderFrequencyLabel.None,
};

export const InviteMessageOptions = [
  {
    value: 'default',
    label: 'Use default message',
  },
];

export type BinaryProgress = { complete: boolean; show: boolean; overdue: boolean };
export type PolyProgress = { total: number; complete: number; show: boolean; overdue: boolean };

export interface ReviewStatus {
  self: BinaryProgress;
  peer: PolyProgress;
  upward: PolyProgress;
  manager: PolyProgress;
}

export interface EditReviewers {
  peerIds: number[];
  upwardIds: number[];
  managerIds: number[];
}
