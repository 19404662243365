import { useCallback, useEffect, useState } from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PayrunPensionStates } from '@v2/feature/benefits/subfeature/pension/pension.interface';
import {
  AccountingJournalStates,
  DownloadPayslipsStates,
  HMRCSubmissionStates,
  PayrunPaymentsStates,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { PayrunStates } from '@v2/feature/payroll/payroll.interface';
import { primarySmallBtn } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const PENDING = 0;
const DONE = 1;
const ERROR = -1;

type EnrichmentStep =
  | 'setInProgress'
  | 'enrichEntries'
  | 'enrichPayslips'
  | 'enrichFPS'
  | 'enrichPension'
  | 'enrichAccounting'
  | 'enrichPayments'
  | 'enrichStates';

interface States {
  readonly setInProgress: number;
  readonly enrichEntries: number;
  readonly enrichPayslips: number;
  readonly enrichFPS: number;
  readonly enrichPension: number;
  readonly enrichAccounting: number;
  readonly enrichPayments: number;
  readonly enrichStates: number;
}

export const HelperPayrunDetailsPage = ({ company }: { company: SuperAdminCompanyInfo | undefined }): JSX.Element => {
  const [payrun, setPayrun] = useState<PayRunDto | null>(null);
  const [loading, setLoading] = useState(true);
  const params = useParams<{ companyId: string; payrunId: string }>();
  const companyId = Number(params.companyId);
  const payrunId = Number(params.payrunId);
  const [showMessage] = useMessage();
  const [currentUserId, setCurrentUserId] = useState<number>(0);

  const [states, setStates] = useState<States>({
    setInProgress: PENDING,
    enrichEntries: PENDING,
    enrichPayslips: PENDING,
    enrichFPS: PENDING,
    enrichPension: PENDING,
    enrichAccounting: PENDING,
    enrichPayments: PENDING,
    enrichStates: PENDING,
  });

  const refreshPayrun = useCallback(async () => {
    try {
      setLoading(true);
      const payrun = await PayrollLocalApi.getLocalPayRunByIdAsSuperAdmin(companyId, payrunId);
      setPayrun(payrun);
    } catch (error) {
      showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, companyId, payrunId]);

  useEffect(() => {
    refreshPayrun();
  }, [refreshPayrun]);

  const setInProgress = useCallback(
    async (companyId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-in-progress/${companyId}/${payrunId}`);
        setStates((prev) => ({ ...prev, setInProgress: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, setInProgress: ERROR }));
      }
    },
    [payrunId, refreshPayrun, showMessage]
  );

  const enrichPayrunEntries = useCallback(
    async (companyId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-entries/${companyId}/${payrunId}`);
        setStates((prev) => ({ ...prev, enrichEntries: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichEntries: ERROR }));
      }
    },
    [payrunId, refreshPayrun, showMessage]
  );

  const enrichPayrunPayslips = useCallback(
    async (companyId: number, currentUserId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-payslips/${companyId}/${payrunId}`, { currentUserId });
        setStates((prev) => ({ ...prev, enrichPayslips: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichPayslips: ERROR }));
      }
    },
    [payrunId, refreshPayrun, showMessage]
  );

  const enrichPayrunFPS = useCallback(
    async (companyId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-fps/${companyId}/${payrunId}`);
        setStates((prev) => ({ ...prev, enrichFPS: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichFPS: ERROR }));
      }
    },
    [payrunId, refreshPayrun, showMessage]
  );

  const enrichPayrunPension = useCallback(
    async (companyId: number, pensionSchemeId: number) => {
      if (!pensionSchemeId) return showMessage('Pension scheme is not selected', 'error');
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-pension/${companyId}/${payrunId}/${pensionSchemeId}`);
        setStates((prev) => ({ ...prev, enrichPension: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichPension: ERROR }));
      }
    },
    [showMessage, payrunId, refreshPayrun]
  );

  const enrichPayrunAccounting = useCallback(
    async (companyId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-accounting/${companyId}/${payrunId}`);
        setStates((prev) => ({ ...prev, enrichAccounting: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichAccounting: ERROR }));
      }
    },
    [showMessage, payrunId, refreshPayrun]
  );

  const enrichPayrunPayments = useCallback(
    async (companyId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-payments/${companyId}/${payrunId}`);
        setStates((prev) => ({ ...prev, enrichPayments: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichPayments: ERROR }));
      }
    },
    [showMessage, payrunId, refreshPayrun]
  );

  const enrichPayrunStates = useCallback(
    async (companyId: number) => {
      try {
        await axios.post(`/apiv2/payroll/payruns/enrich/payrun-state/${companyId}/${payrunId}`);
        setStates((prev) => ({ ...prev, enrichStates: DONE }));
        await refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, 'error');
        setStates((prev) => ({ ...prev, enrichStates: ERROR }));
      }
    },
    [showMessage, payrunId, refreshPayrun]
  );

  const getButtonColorBasedOnState = (field: EnrichmentStep) => {
    switch (states[field]) {
      case ERROR: {
        return 'error';
      }
      case DONE: {
        return 'success';
      }
      default: {
        return 'primary';
      }
    }
  };

  return (
    <>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            Helper page - Payrun details | {company?.name ? company.name : ''} [ {companyId} ]
          </Typography>
        }
        showBack
      />
      <ContentWrapper loading={loading}>
        {payrun && (
          <Typography sx={titleSx}>
            {payrun.payPeriod} {payrun.period} {payrun.taxYear}
          </Typography>
        )}
        <Typography sx={themeFonts.title2}>Payrun states</Typography>
        {payrun && (
          <Box
            sx={{
              display: 'flex',
              gap: spacing.g30,
              mt: spacing.m10,
              overflowX: 'auto',
              minHeight: '100px',
              pb: spacing.m5,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between' }}>
              <Typography>id</Typography>
              <Typography>taxYear</Typography>
              <Typography>payPeriod</Typography>
              <Typography>period</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography>{payrun.id}</Typography>
              <Typography>{payrun.taxYear}</Typography>
              <Typography>{payrun.payPeriod}</Typography>
              <Typography>{payrun.period}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderLeft: '1px solid black',
                pl: 1,
              }}
            >
              <Typography sx={themeFonts.caption}>Payslips</Typography>
              <Typography
                sx={themeFonts.title4}
                color={payrun.payrunPayslips?.state === DownloadPayslipsStates.downloaded ? '#23ad51' : '#ada61a'}
              >
                {payrun.payrunPayslips?.state ?? 'N/A'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderLeft: '1px solid black',
                pl: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={themeFonts.caption}>FPS Submission</Typography>
              <Typography
                sx={themeFonts.title4}
                color={
                  payrun.payrunFPS?.state &&
                  [HMRCSubmissionStates.submitted, HMRCSubmissionStates.markedAsSent].includes(payrun.payrunFPS?.state)
                    ? '#23ad51'
                    : '#ada61a'
                }
              >
                {payrun.payrunFPS?.state ?? 'N/A'}
              </Typography>
            </Box>
            {payrun.payrunPensions &&
              payrun.payrunPensions.map((payrunPension, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderLeft: '1px solid black',
                    pl: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                    <Typography sx={{ ...themeFonts.caption, whiteSpace: 'nowrap' }}>Pension Submission</Typography>
                    <Typography sx={themeFonts.caption}>{payrunPension.pensionScheme?.providerName ?? ''}</Typography>
                  </Box>
                  <Typography
                    sx={themeFonts.title4}
                    color={
                      payrunPension.state &&
                      [PayrunPensionStates.submitted, PayrunPensionStates.markedAsSent].includes(payrunPension.state)
                        ? '#23ad51'
                        : '#ada61a'
                    }
                  >
                    {payrunPension?.state ?? 'N/A'}
                  </Typography>
                </Box>
              ))}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderLeft: '1px solid black',
                pl: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={themeFonts.caption}>Accounting Submission</Typography>
              <Typography
                sx={themeFonts.title4}
                color={
                  payrun.payrunAccounting?.state &&
                  [AccountingJournalStates.submitted, AccountingJournalStates.markedAsSent].includes(
                    payrun.payrunAccounting?.state
                  )
                    ? '#23ad51'
                    : '#ada61a'
                }
              >
                {payrun.payrunAccounting?.state ?? 'N/A'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderLeft: '1px solid black',
                pl: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={themeFonts.caption}>Payments</Typography>
              <Typography
                sx={themeFonts.title4}
                color={payrun.payrunPayments?.state === PayrunPaymentsStates.created ? '#23ad51' : '#ada61a'}
              >
                {payrun.payrunPayments?.state ?? 'N/A'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderLeft: '1px solid black',
                pl: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={themeFonts.caption}>Payrun State</Typography>
              <Typography sx={themeFonts.title4} color={payrun.state === PayrunStates.completed ? '#23ad51' : '#000'}>
                {payrun.state}
              </Typography>
            </Box>
          </Box>
        )}
        <Typography sx={{ ...themeFonts.title2, mt: spacing.m30, mb: spacing.m10 }}>Payrun actions</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <Typography sx={{ mr: 4 }}>
                Company <b>{companyId}</b>
              </Typography>
              <Typography sx={{ mr: 4 }}>
                Payrun <b>{payrunId}</b>
              </Typography>
            </Box>
            <TextField
              id="outlined-name"
              label="Current User Id"
              name="currentUserId"
              value={currentUserId}
              onChange={(e) => setCurrentUserId(Number(e.target.value))}
              size="small"
            />
          </Box>
          <Box sx={{ display: 'flex', my: 3, gap: 1, overflowX: 'auto', pb: spacing.m5 }}>
            <Button
              sx={primarySmallBtn}
              onClick={() => setInProgress(companyId)}
              size="small"
              color={getButtonColorBasedOnState('setInProgress')}
            >
              <Typography sx={themeFonts.caption}>Set In Progress</Typography>
            </Button>
            <Button
              sx={primarySmallBtn}
              onClick={() => enrichPayrunEntries(companyId)}
              size="small"
              color={getButtonColorBasedOnState('enrichEntries')}
            >
              <Typography sx={themeFonts.caption}>Enrich Entries</Typography>
            </Button>
            <Button
              sx={primarySmallBtn}
              onClick={() => enrichPayrunPayslips(companyId, currentUserId)}
              size="small"
              color={getButtonColorBasedOnState('enrichPayslips')}
              disabled={currentUserId === 0}
            >
              <Typography sx={themeFonts.caption}>Enrich Payslips</Typography>
            </Button>
            <Button
              sx={primarySmallBtn}
              onClick={() => enrichPayrunFPS(companyId)}
              size="small"
              color={getButtonColorBasedOnState('enrichFPS')}
            >
              <Typography sx={themeFonts.caption}>Enrich FPS</Typography>
            </Button>
            {payrun?.payrunPensions &&
              payrun.payrunPensions.map((payrunPension, index) => (
                <Button
                  key={index}
                  sx={primarySmallBtn}
                  onClick={() => enrichPayrunPension(companyId, payrunPension.pensionSchemeId)}
                  size="small"
                  color={getButtonColorBasedOnState('enrichPension')}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={themeFonts.caption}>Enrich Pension</Typography>
                    <Typography sx={themeFonts.caption}>({payrunPension.pensionScheme?.providerName ?? ''})</Typography>
                  </Box>
                </Button>
              ))}
            <Button
              sx={primarySmallBtn}
              onClick={() => enrichPayrunAccounting(companyId)}
              size="small"
              color={getButtonColorBasedOnState('enrichAccounting')}
            >
              <Typography sx={themeFonts.caption}>Enrich Accounting</Typography>
            </Button>
            <Button
              sx={primarySmallBtn}
              onClick={() => enrichPayrunPayments(companyId)}
              size="small"
              color={getButtonColorBasedOnState('enrichPayments')}
            >
              <Typography sx={themeFonts.caption}>Enrich Payments</Typography>
            </Button>
            <Button
              sx={primarySmallBtn}
              onClick={() => enrichPayrunStates(companyId)}
              size="small"
              color={getButtonColorBasedOnState('enrichStates')}
            >
              <Typography sx={themeFonts.caption}>Enrich State</Typography>
            </Button>
          </Box>
        </Box>
      </ContentWrapper>
    </>
  );
};
