import { useCallback, useMemo, useState } from 'react';

import { Stack, SwipeableDrawer } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { ColumnDef } from '@tanstack/react-table';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { formatPayPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { SetupPayrollDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/set-up-payroll-drawer.component';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort, filterByTextSearch } from '@/v2/util/array.util';

export const PayrollCompanySettingsPayrollList = () => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState('');
  const routerHistory = useHistory();
  const [showPayrollSetupDrawer, setShowPayrollSetupDrawer] = useState(false);

  const { data: payrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });

  const columns = useMemo<ColumnDef<CompanyPayroll, CompanyPayroll>[]>(() => {
    return [
      {
        id: 'payroll-type',
        header: () => polyglot.t('PayrollSettingsPayrollTable.payroll'),
        accessorFn: (row: CompanyPayroll) => row,
        enableSorting: false,
        cell() {
          return (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
              <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" />
              <Typography variant="caption">Zelt</Typography>
            </Stack>
          );
        },
      },
      {
        id: 'entity-name',
        header: () => polyglot.t('PayrollSettingsPayrollTable.entity'),
        accessorFn: (row: CompanyPayroll) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.entity.legalName, { sensitivity: 'base' }),
        cell: ({ row }) => <Typography variant="caption">{row.original.entity.legalName}</Typography>,
      },
      {
        id: 'country',
        header: () => polyglot.t('PayrollSettingsPayrollTable.country'),
        accessorFn: (row: CompanyPayroll) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getCountryNameForCode(item.entity.address?.countryCode) ?? ''),
        cell: ({ row }) => (
          <Typography variant="caption">
            {getCountryNameForCode(row.original.entity.address?.countryCode) ?? ''}
          </Typography>
        ),
      },
      {
        id: 'schedule',
        header: () => polyglot.t('PayrollSettingsPayrollTable.schedule'),
        accessorFn: (row: CompanyPayroll) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.schedule?.payPeriod ?? ''),
        cell: ({ row }) => (
          <Typography variant="caption">
            {row.original.schedule ? formatPayPeriod(row.original.schedule.payPeriod, polyglot) : ''}
          </Typography>
        ),
      },
    ];
  }, [polyglot]);

  const sortedPayrolls = useMemo(
    () => payrolls?.items.sort((a, b) => caseInsensitiveSort(a, b, (entry) => entry.entity.legalName)) ?? [],
    [payrolls]
  );

  const filteredPayrolls = useMemo(
    () => filterByTextSearch(searchInput, sortedPayrolls, (payroll) => [payroll.entity.legalName]),
    [searchInput, sortedPayrolls]
  );

  const goToPayrollSettings = useCallback(
    (payrollId: number) => {
      routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId }));
    },
    [routerHistory]
  );

  const launchNewPayroll = useCallback(() => {
    setShowPayrollSetupDrawer(true);
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('CompanyPayroll.salary')}</Typography>}
        showAction
        actions={
          <ButtonComponent onClick={() => launchNewPayroll()} sizeVariant="small" colorVariant="primary">
            {polyglot.t('PayrollSettings.addPayroll')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={payrolls === undefined}>
        {filteredPayrolls && (
          <Stack sx={{ gap: spacing.s1 }}>
            <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value)} />
            <BasicTable
              rowData={filteredPayrolls}
              columnData={columns}
              rowClick={(row) => goToPayrollSettings(row.original.id)}
            />
          </Stack>
        )}
      </ContentWrapper>
      <SwipeableDrawer
        anchor="right"
        open={showPayrollSetupDrawer}
        onOpen={() => {}}
        onClose={() => setShowPayrollSetupDrawer(false)}
      >
        {showPayrollSetupDrawer && <SetupPayrollDrawer />}
      </SwipeableDrawer>
    </RootStyle>
  );
};
