import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { useEventSource } from '@/hooks/event-source-hook';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PayrollAPI, PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { formatCurrency } from '@/v2/util/currency-format.util';

type PayrollEmploymentAllowancePageProps = {
  payrollId: number;
  close: () => void;
};

const PayrollEmploymentAllowancePage = ({ payrollId, close }: PayrollEmploymentAllowancePageProps) => {
  const [employmentAllowance, setEmploymentAllowance] = useState<EmploymentAllowanceStatus>();
  const [initialEAValue, setInitialEAValue] = useState<number | null>();
  const [adjustedEAValue, setAdjustedEAValue] = useState<number>();
  const [progressMsg, setProgressMsg] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [adjustingAllowance, setAdjustingAllowance] = useState(false);
  const [showMessage] = useMessage();

  useEventSource<EARequestUpdate>(PayrollEndpoints.requestEmploymentAllowanceProgress(payrollId).url, {
    onMessage: (data) => {
      setProgressMsg(data.msg);
      setRequesting(true);
      if (data.state === 'failed') {
        setRequesting(false);
        showMessage(data.msg, 'error');
      }
      if (data.state === 'success') {
        setRequesting(false);
        showMessage('Employment allowance has been enabled', 'success');
        close();
      }
    },
    onError: (error) => {
      setProgressMsg(`Failed to send request. ${(error as Error).message}`);
      setRequesting(false);
    },
  });

  const requestEmploymentAllowance = useCallback(
    (allowance: number) => {
      setRequesting(true);
      setProgressMsg('');
      // this is a long API call where the progress is returned using server-sent-events and a local event source
      PayrollAPI.requestEmploymentAllowance(payrollId, allowance);
    },
    [payrollId]
  );

  const refreshEmploymentAllowanceStatus = useCallback(async () => {
    PayrollAPI.getEmploymentAllowance(payrollId).then(setEmploymentAllowance, (err) =>
      showMessage(`Failed to retrieve Employer Allowance. ${nestErrorMessage(err)}`, 'error')
    );
  }, [payrollId, showMessage]);

  const adjustEmploymentAllowance = useCallback(
    async (newAllowance: number) => {
      setAdjustingAllowance(true);
      try {
        await PayrollAPI.adjustEmploymentAllowance(payrollId, newAllowance);
        refreshEmploymentAllowanceStatus();
        showMessage(`Employer Allowance updated.`, 'success');
        close();
      } catch (err) {
        showMessage(`Employer Allowance adjustment failed. ${nestErrorMessage(err)}`, 'error');
        setAdjustingAllowance(false);
      }
    },
    [payrollId, close, refreshEmploymentAllowanceStatus, showMessage]
  );

  useEffect(() => {
    refreshEmploymentAllowanceStatus();
  }, [refreshEmploymentAllowanceStatus]);

  useEffect(() => {
    if (!employmentAllowance) return;
    setInitialEAValue(employmentAllowance.maxAllowance);
    setAdjustedEAValue(employmentAllowance.current?.allowance);
  }, [employmentAllowance]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Employment Allowance</Typography>
      {!employmentAllowance && <LoadingSpinner />}
      {employmentAllowance?.current && (
        <Box sx={drawerContentSx}>
          <Typography variant="caption">
            Current Allowance: {formatCurrency(employmentAllowance.current.allowance)}
          </Typography>

          <TextfieldComponent
            label="New allowance"
            name="new-allowance"
            onChange={(e) => {
              const n = e.target.value.match(/^£\d+/)?.[0]?.slice(1, 5);
              setAdjustedEAValue(n ? Math.min(Number(n), employmentAllowance.maxAllowance) : undefined);
            }}
            value={`£${adjustedEAValue ?? ''}`}
            autoFocus
            disabled={adjustingAllowance}
          />

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name="Adjust allowance"
              loading={adjustingAllowance}
              onClick={() => adjustedEAValue && adjustEmploymentAllowance(adjustedEAValue)}
              sizeVariant="large"
              colorVariant="primary"
              fullWidth
            />
          </Box>
        </Box>
      )}
      {employmentAllowance && !employmentAllowance.current && (
        <Box sx={drawerContentSx}>
          <Typography variant="caption">
            Zelt will submit a new Employer Payment Summary to HMRC indicating your eligibility to receive Employment
            Allowance up to the amount entered below (maximum{' '}
            {formatCurrency(employmentAllowance.maxAllowance, { wholeNumber: true })}).
          </Typography>
          <Typography variant="caption">
            Once accepted, the allowance will reduce Class 1 National Insurance contributions starting from the next pay
            run.
          </Typography>
          {requesting ? (
            <>
              <LoadingSpinner />
              <Typography variant="caption">{progressMsg}</Typography>
            </>
          ) : (
            <Box sx={drawerContentSx}>
              <TextfieldComponent
                label={'Allowance'}
                name="allowance"
                onChange={(e) => {
                  const n = e.target.value.match(/^£\d+/)?.[0]?.slice(1, 5);
                  setInitialEAValue(n ? Math.min(Number(n), employmentAllowance.maxAllowance) : null);
                }}
                value={`£${initialEAValue ?? ''}`}
                autoFocus
              />

              <Box sx={buttonBoxDrawerSx}>
                <ButtonComponent
                  disabled={!initialEAValue}
                  onClick={() => initialEAValue && requestEmploymentAllowance(initialEAValue)}
                  sizeVariant="medium"
                  colorVariant="primary"
                  fullWidth
                >
                  Submit request
                </ButtonComponent>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

type Props = {
  payrollId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PayrollEmploymentAllowanceDrawer = ({ payrollId, isOpen, setIsOpen }: Props): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      {isOpen ? <PayrollEmploymentAllowancePage payrollId={payrollId} close={() => setIsOpen(false)} /> : <></>}
    </DrawerModal>
  );
};
