import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as PayrollIcon } from '@/images/onboarding-step-icons/WalletBig.svg';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { EditPayroll } from '@/v2/feature/onboarding/onboarding-items/payroll/edit-payroll.component';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  setDrawerOpen: (open: boolean) => void;
  setDrawerView: (view: JSX.Element) => void;
  onDelete?: () => void;
};

export const PayrollOnboardingItem = ({ template, setDrawerOpen, setDrawerView, onDelete }: Props) => {
  const { polyglot } = usePolyglot();
  return (
    <ActionSelectionItem
      step={{
        Icon: PayrollIcon,
        title: polyglot.t('PayrollOnboardingItem.title'),
        description: polyglot.t('PayrollOnboardingItem.desc'),
      }}
      addButtonIcon={template.template.payroll ? 'edit' : 'plus'}
      onAddClick={
        template.template.payroll
          ? undefined
          : () => {
              setDrawerView(
                <EditPayroll
                  onSave={() => {
                    template.template.payroll = { enabled: true };
                    setDrawerOpen(false);
                  }}
                />
              );
              setDrawerOpen(true);
            }
      }
      onDeleteClick={template.template.payroll && onDelete}
      sx={{
        py: spacing.py20,
      }}
    />
  );
};
