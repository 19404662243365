import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { JobLevelAPI } from '@/v2/feature/job-level/job-level.api';
import { JobLevel } from '@/v2/feature/job-level/job-level.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const TrackFormModal = ({
  setOpenTrack,
  openTrack,
  refresh,
  onClose,
}: {
  setOpenTrack: React.Dispatch<React.SetStateAction<boolean>>;
  openTrack: boolean;
  refresh: KeyedMutator<JobLevel[]> | undefined;
  onClose: () => void;
}) => {
  return (
    <DrawerModal isOpen={openTrack} setIsOpen={setOpenTrack}>
      <TrackFormContent initialValue={undefined} refresh={refresh} onClose={onClose} />
    </DrawerModal>
  );
};

interface IntialTrackValue {
  trackName: string;
}
const TrackFormContent = ({
  initialValue,
  refresh,
  onClose,
}: {
  initialValue: IntialTrackValue | undefined;
  refresh: KeyedMutator<JobLevel[]> | undefined;
  onClose: () => void;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: { trackName: initialValue ? initialValue.trackName : '' },
    enableReinitialize: true,
    validationSchema: Yup.object({
      trackName: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.trackName) {
          const jobTrackObj = {
            trackName: values.trackName,
            levelName: polyglot.t('TrackFormContent.placeholder'),
            levelDescription: polyglot.t('TrackFormContent.pleaseWriteDesc'),
          };
          await JobLevelAPI.createJobTrack(jobTrackObj);
        }
        await refresh?.();
        onClose();
        showMessage(polyglot.t('TrackFormContent.successMessage.successfulCreation'), 'success');
      } catch (error) {
        showMessage(
          polyglot.t('TrackFormContent.errorMessage.trackActionFailed', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography variant="title2">{polyglot.t('TrackFormContent.newTrack')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', ...spacing.mt20 }}>
          <TextfieldComponent
            name="trackName"
            label={polyglot.t('TrackFormContent.name')}
            value={formik.values.trackName}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.trackName && !!formik.errors.trackName}
            helperText={(formik.touched.trackName && formik.errors.trackName) ?? ' '}
            clearText={() => formik.setFieldValue('trackName', '')}
          />

          <Box sx={{ ...spacing.mt30 }}>
            <LoaderButton loading={loading} sizeVariant="medium" colorVariant="primary" fullWidth type="submit">
              {polyglot.t('TrackFormContent.save')}
            </LoaderButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
