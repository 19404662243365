import axios from 'axios';

import {
  SurveyQuestionBank,
  SurveyQuestionBankRemoveIds,
  SurveyQuestionBankUpsert,
} from '@/v2/feature/growth/surveys/interfaces/survey-question-bank.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class SurveyQuestionBankAPI {
  static async createSurveyQuestionInBank(questionBody: SurveyQuestionBankUpsert): Promise<SurveyQuestionBank> {
    return (await axios.post('/apiv2/survey-question-bank', questionBody)).data;
  }

  static async removeQuestionFromTemplate(ids: SurveyQuestionBankRemoveIds): Promise<void> {
    await axios.post('/apiv2/survey-question-bank/remove-questions', ids);
  }

  static async updateSurveyQuestionInBank(questionBody: SurveyQuestionBankUpsert): Promise<void> {
    await axios.patch('/apiv2/survey-question-bank', questionBody);
  }
}

export class SurveyQuestionBankEndpoints {
  static getSurveyQuestionFromBank(search?: string, filterString?: string): Endpoint<SurveyQuestionBank[]> {
    const urlParams = new URLSearchParams({
      ...(search && { search }),
      ...(filterString && { filterString }),
    });
    return {
      url: `/apiv2/survey-question-bank?${urlParams.toString()}`,
    };
  }
}
