import React, { useCallback, useEffect, useState } from 'react';

import { Box, SwipeableDrawer } from '@mui/material';

import { GlobalPayrollSalarySummaryPage } from './global-payroll-salary-summary-page.component';

import useMessage from '@/hooks/notification.hook';
import { GlobalPayrollAPI } from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { UserPayslipSummaryDto } from '@/v2/feature/payroll/payroll.dto';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly payslip: UserPayslipSummaryDto | null;
  readonly providedPayrunEntry?: GlobalPayrunEntry | null;
  readonly providedPayrun?: GlobalPayrun | null;
  readonly providedUser?: CachedUser | null;
  readonly handleEditAction?: (entry: GlobalPayrunEntry) => void;
  readonly payrun?: GlobalPayrun;
}

export const GlobalPayrollSalarySummaryDrawer = ({
  onOpen = () => {},
  onClose = () => {},
  payslip,
  providedPayrunEntry,
  providedPayrun,
  providedUser,
  handleEditAction,
}: Props): React.JSX.Element => {
  const [payrun, setPayrun] = useState<GlobalPayrun | null>(null);
  const [showMessage] = useMessage();

  const refreshPayrun = useCallback(async () => {
    if (!payslip && !providedPayrunEntry) {
      setPayrun(null);
      return;
    }

    let payrun;
    if (payslip) {
      const { payrollId, taxYear, payPeriod, period, userId } = payslip;
      payrun = await GlobalPayrollAPI.getPayrunForUserId(payrollId, taxYear, payPeriod, period, userId);
    } else if (providedPayrun) {
      payrun = providedPayrun;
    }
    let payrunEntry =
      providedPayrunEntry ?? (payslip && payslip?.userId && payrun?.entries.find((e) => e.userId === payslip?.userId));
    if (!payrunEntry) {
      showMessage('Failed to find payrun entry', 'error');
    }
    setPayrun(payrun ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payslip, providedPayrunEntry, showMessage]);

  useEffect(() => {
    refreshPayrun();
  }, [refreshPayrun]);

  const { getCachedUserById } = useCachedUsers();
  const user = providedUser ?? (payslip?.userId && getCachedUserById(payslip?.userId));
  const payrunEntry =
    providedPayrunEntry ?? (payslip && payslip?.userId && payrun?.entries.find((e) => e.userId === payslip?.userId));

  return (
    <SwipeableDrawer anchor="right" open={!!user} onClose={onClose} onOpen={onOpen}>
      {user && payrun && payrunEntry && (
        <Box sx={{ px: spacing.px40, py: spacing.p30, width: '440px', boxSizing: 'border-box', maxWidth: '100vw' }}>
          <GlobalPayrollSalarySummaryPage
            user={user}
            payrunEntry={payrunEntry}
            globalPaycodes={payrun.paycodes}
            payslip={payslip}
            handleEditAction={handleEditAction}
            payrun={payrun}
          />
        </Box>
      )}
    </SwipeableDrawer>
  );
};
