import React, { useCallback, useMemo, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import {
  ReportResponse,
  ReportResponseEntry,
  SelectedColumnsRequest,
  SelectedFiltersRequest,
} from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

export const ReportsTestPage = () => {
  const { polyglot } = usePolyglot();
  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getTestReportsFiltersOptions(), {
    suspense: false,
  });
  const [showMessage] = useMessage();

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumnsRequest>({});

  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersRequest>({});
  const [reportResponse, setReportResponse] = useState<{
    table?: any;
    query?: (string | undefined)[];
    result?: ReportResponse;
    customFieldsFilteredUsers?: number[];
    customFieldsData?: Record<number, any>;
  }>({});

  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const generateReport = useCallback(async () => {
    try {
      setIsGenerating(true);
      const response = await ReportsAPI.generateTestReport(selectedColumns, selectedFilters);
      setReportResponse(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
    setIsGenerating(false);
  }, [selectedColumns, selectedFilters, showMessage]);

  const columns = useMemo<ColumnDef<ReportResponseEntry, ReportResponseEntry>[]>(() => {
    if (!reportResponse?.result?.data || !reportResponse.result.data[0]) return [];

    const firstRow: ReportResponseEntry = reportResponse.result.data[0];

    const columnsList: (ColumnDef<ReportResponseEntry, ReportResponseEntry> & { order: number })[] = Object.keys(
      firstRow
    ).map((key) => ({
      id: key,
      size: 120,
      enableSorting: true,
      sortingFn: (a, b) =>
        firstRow[key]?.type === 'number'
          ? sortNumeric(a, b, (item) => (item[key].raw as number) ?? 0)
          : sortString(a, b, (item) => (item[key].raw as string) ?? ''),
      accessorFn: (row) => row,
      header: () => reportResponse.result?.header?.labels[key] ?? key.split('__').join('->'),
      cell: ({ row: { original } }) => {
        if (!original[key] || original[key].value === null || original[key].value === undefined) return '';
        return <Typography variant="captionSmall">{original[key].value}</Typography>;
      },
      order: reportResponse.result?.header?.order[key] ?? 0,
    }));

    columnsList.sort((a, b) => a.order - b.order);
    return columnsList as ColumnDef<ReportResponseEntry, ReportResponseEntry>[];
  }, [reportResponse]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography variant="title3">{polyglot.t('ReportsPage.reports')}</Typography>
            <Typography variant="title3" sx={{ color: themeColors.Grey }}>
              (TEST PAGE)
            </Typography>
          </Box>
        }
        actions={
          <ButtonComponent
            fullWidth
            colorVariant="secondary"
            sizeVariant="small"
            onClick={generateReport}
            loading={isGenerating}
          >
            Generate report
          </ButtonComponent>
        }
        showAction
      />
      <ContentWrapper loading={isLoading} sx={{ ...spacing.pt20 }}>
        {filtersAndColumns && (
          <Box sx={{ display: 'flex' }}>
            TODO: Columns and Filters list is retrieved. Implement new columns and filters selection here
          </Box>
        )}

        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mt: '15px', width: '50%' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 1 }}>
              <Typography variant="title3">Selected columns</Typography>
              <ButtonComponent sizeVariant="small" colorVariant="text" onClick={() => setSelectedColumns({})}>
                <Typography variant="caption" color="Grey">
                  clear
                </Typography>
              </ButtonComponent>
            </Box>
            <Box sx={{ overflowY: 'auto', height: '200px' }}>
              <pre style={{ fontSize: '14px' }}>{JSON.stringify(selectedColumns, null, 2)}</pre>
            </Box>
          </Box>
          <Box sx={{ mt: '20px', width: '50%' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 1 }}>
              <Typography variant="title3">Selected filters</Typography>
              <ButtonComponent sizeVariant="small" colorVariant="text" onClick={() => setSelectedFilters({})}>
                <Typography variant="caption" color="Grey">
                  clear
                </Typography>
              </ButtonComponent>
            </Box>

            <Box sx={{ overflowY: 'auto', height: '200px' }}>
              <pre style={{ fontSize: '14px' }}>{JSON.stringify(selectedFilters, null, 2)}</pre>
            </Box>
          </Box>
        </Box>

        <Typography variant="title3" sx={{ mt: '15px' }}>
          RESULTS
        </Typography>
        <BasicTable<ReportResponseEntry>
          rowData={[...(reportResponse?.result?.data ?? [])]}
          columnData={columns}
          fixedLastColumn={false}
        />

        <Typography variant="title3" sx={{ mt: '15px' }}>
          GENERATED TABLES TREE
        </Typography>
        {reportResponse?.table ? (
          <TreeView>
            <TableTree table={reportResponse.table} level={1} />
          </TreeView>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey, my: '10px' }}>
              No report has been generated yet...
            </Typography>
          </Box>
        )}

        <Typography variant="title3" sx={{ mt: '25px' }}>
          RESPONSE JSON DATA
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title4">RESULT</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre style={{ fontSize: '14px' }}>{JSON.stringify(reportResponse?.result ?? {}, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title4">QUERIES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!!reportResponse?.query && reportResponse?.query?.length > 0
              ? reportResponse.query.map((query, index) => (
                  <Box key={index} sx={{ mb: '10px' }}>
                    <Typography variant="title5" sx={{ mb: '5px' }}>
                      {index === 0
                        ? 'USER QUERY'
                        : index === 1
                        ? 'CUSTOM FIELDS DATA QUERY'
                        : 'CUSTOM FIELDS FILTER QUERY'}
                    </Typography>
                    <code style={{ fontSize: '14px' }}>{JSON.stringify(query ?? {}, null, 2)}</code>
                  </Box>
                ))
              : null}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title4">TABLE</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre style={{ fontSize: '14px' }}>{JSON.stringify(reportResponse?.table ?? {}, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="title4">CUSTOM FIELDS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mb: '10px' }}>
              <Typography variant="title5" sx={{ mb: '5px' }}>
                FILTERED USERS BY CUSTOM FIELDS
              </Typography>
              <pre style={{ fontSize: '14px' }}>
                {JSON.stringify(reportResponse?.customFieldsFilteredUsers ?? {}, null, 2)}
              </pre>
            </Box>
            <Box>
              <Typography variant="title5" sx={{ mb: '5px' }}>
                CUSTOM FIELDS BY USER ID
              </Typography>
              <pre style={{ fontSize: '14px' }}>{JSON.stringify(reportResponse?.customFieldsData ?? {}, null, 2)}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>
      </ContentWrapper>
    </RootStyle>
  );
};

const LevelColor: Record<number, string> = {
  1: '#44ae4f',
  2: '#347dAF',
  3: '#7d8028',
};

const TableTree = ({
  table,
  level,
}: {
  table: {
    tableName: string;
    stub: string;
    include?: any[];
    attributes?: { value: string; forcePushed?: boolean }[];
    filters?: { columnName: string; op: string; value: string | number | string[] | number[] }[];
  };
  level: number;
}) => (
  <TreeItem
    nodeId={table.stub ?? 'root'}
    collapseIcon={<ExpandMoreIcon />}
    expandIcon={<ChevronRightIcon />}
    label={
      <Box>
        <Typography variant="title4" sx={{ mb: 2 }}>
          {table.tableName}
        </Typography>

        {table.attributes && table.attributes.length > 0 && (
          <Accordion sx={{ bgcolor: '#dedede' }}>
            <AccordionSummary sx={{ minHeight: '15px', maxHeight: '15px' }} expandIcon={<ExpandMoreIcon />}>
              <Typography variant="title5">attributes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {table.attributes.map((att, index) => (
                <Typography key={index} variant="caption">
                  {att.value}
                  {att.forcePushed ? ' (forcePushed)' : ''}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        )}

        {table.filters && table.filters.length > 0 && (
          <Accordion sx={{ bgcolor: '#dedede' }}>
            <AccordionSummary
              sx={{ minHeight: '15px', maxHeight: '15px' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="title5">filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {table.filters.map((filter, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                  <Typography variant="caption">{filter.columnName}</Typography>
                  <Typography variant="caption">{filter.op}</Typography>
                  <Typography variant="caption">{JSON.stringify(filter.value)}</Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    }
    sx={{ bgcolor: LevelColor[level], p: 2, m: 2, borderRadius: '15px' }}
  >
    {table?.include && table.include?.length > 0
      ? table.include.map((child, index) => <TableTree key={index} table={child} level={level + 1} />)
      : null}
  </TreeItem>
);
