import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

import {
  CreateUserBankAccountDto,
  UpdateUserBankAccountDto,
  UserBankAccountDto,
} from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';

export class UserBankAccountAPI {
  static async findByUserId(
    userId: number
  ): Promise<{ effectiveRecord: UserBankAccountDto; entries: UserBankAccountDto[] }> {
    return (await axios.get(`/apiv2/users/${userId}/bank-accounts`)).data;
  }

  static async findCurrentByUserIds(
    userIds: number[]
  ): Promise<
    // TODO: @NOW___PAYMENTS - add userId here
    Record<number, Pick<UserBankAccountDto, 'accountName' | 'accountNumber' | 'sortCode' | 'country' | 'currency'>>
  > {
    return (await axios.get(`/apiv2/users/bank-accounts/current?userIds=${userIds.join(',')}`)).data;
  }

  static async findCurrentByUserId(userId: number): Promise<UserBankAccountDto | null> {
    return (await axios.get(`/apiv2/users/${userId}/bank-accounts/current`)).data || null;
  }

  static async update(userId: number, id: number, update: UpdateUserBankAccountDto): Promise<UserBankAccountDto> {
    return (await axios.patch(`/apiv2/users/${userId}/bank-accounts/${id}`, update)).data;
  }

  static async create(userId: number, create: CreateUserBankAccountDto): Promise<UserBankAccountDto> {
    return (await axios.post(`/apiv2/users/${userId}/bank-accounts`, create)).data;
  }

  static async deleteById(userId: number, accountId: number): Promise<void> {
    return axios.delete(`/apiv2/users/${userId}/bank-accounts/${accountId}`);
  }
}

export class UserBankAccountEndpoints {
  static findCurrentByUserId(userId: number | null): Endpoint<UserBankAccountDto> | null {
    return userId ? { url: `/apiv2/users/${userId}/bank-accounts/current` } : null;
  }
}
