import { useMemo } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ShowFlags } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';

export const useTimelineForm = (
  reviewCycle: ReviewCycle | null | undefined,
  onClose: () => void,
  refresh: () => Promise<void>,
  showFlags: ShowFlags
) => {
  const [showMessage] = useMessage();
  const { showSelf, showUpward, showPeer, showManager } = showFlags;

  const validationSchema = useMemo(() => {
    return yup.object({
      timelineSettings: yup.object({
        startNow: yup.boolean().required(),
        startDate: yup
          .string()
          .nullable()
          .when('startNow', {
            is: false,
            then: yup.string().required('Start date is required'),
            otherwise: yup.string().nullable(),
          }),
        startTime: yup
          .string()
          .nullable()
          .when('startNow', {
            is: false,
            then: yup.string().required('Start time is required'),
            otherwise: yup.string().nullable(),
          }),
        startTimeMeridiem: yup
          .string()
          .nullable()
          .when('startNow', {
            is: false,
            then: yup.string().required('Start time meridiem is required'),
            otherwise: yup.string().nullable(),
          }),
        selfReviewDeadline: showSelf
          ? yup.number().nullable().required('Self review deadline is required')
          : yup.number().nullable(),
        upwardReviewDeadline: showUpward
          ? yup.number().nullable().required('Upward review deadline is required')
          : yup.number().nullable(),
        peerReviewDeadline: showPeer
          ? yup.number().nullable().required('Peer review deadline is required')
          : yup.number().nullable(),
        managerReviewDeadline: showManager
          ? yup.number().nullable().required('Manager review deadline is required')
          : yup.number().nullable(),
      }),
    });
  }, [showSelf, showUpward, showPeer, showManager]);

  const formik = useFormik({
    initialValues: {
      timelineSettings: reviewCycle?.timelineSettings || {
        startNow: true,
        startDate: null,
        startTime: '9',
        startTimeMeridiem: 'AM',
        selfReviewDeadline: 7,
        upwardReviewDeadline: 7,
        peerReviewDeadline: 7,
        managerReviewDeadline: 14,
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (reviewCycle) {
          await ReviewCycleAPI.updateTimelinelinesReviewCycle(reviewCycle.id, {
            ...values.timelineSettings,
          });
          const successMessage =
            reviewCycle.state === CycleState.Draft
              ? 'Successfully update the cycle'
              : 'Please wait, the update is in the queue.';
          showMessage(successMessage, 'success');
          await refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};
