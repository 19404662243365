import { useContext } from 'react';

import { CompanyConfig } from '@shared/modules/company/company.types';
import { BenefitsOverviewPage } from '@v2/feature/benefits/benefits-company-overview.page';
import { InsuranceCompanyRouter } from '@v2/feature/benefits/subfeature/insurance/insurance-company.router';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionCompanyRouter } from '@v2/feature/benefits/subfeature/pension/pension-company.router';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { RootStyle } from '@v2/styles/root.styles';
import { Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  BENEFITS_COMPANY_ROUTE,
  CUSTOM_BENEFIT_COMPANY_ROUTE,
  INSURANCE_COMPANY_ROUTE,
  PENSION_COMPANY_ROUTE,
} from '@/lib/routes';
import { CustomBenefitCompanyRouter } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit-company.router';
import { CustomBenefitDto } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';

interface BenefitsCompanyRouterProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
  readonly refreshInsuranceQuote: () => Promise<void>;
  readonly refreshAllBenefits: () => Promise<void>;
  readonly companyConfig: CompanyConfig;
  readonly loading: boolean;
}

export const BenefitsCompanyRouter = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshInsuranceQuote,
  refreshAllBenefits,
  companyConfig,
  loading,
}: BenefitsCompanyRouterProps) => {
  const [state] = useContext(GlobalContext);
  const userId = state.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  return (
    <RootStyle>
      <Switch>
        {companyConfig.inPayroll && (
          <RouteScopesControl scopes={['pension:all']} path={PENSION_COMPANY_ROUTE}>
            <PensionCompanyRouter pensionSchemes={pensionSchemes} loading={loading} />
          </RouteScopesControl>
        )}

        <RouteScopesControl scopes={['insurance:all']} path={CUSTOM_BENEFIT_COMPANY_ROUTE}>
          <CustomBenefitCompanyRouter loading={loading} customBenefits={customBenefits} />
        </RouteScopesControl>

        <RouteScopesControl scopes={['insurance:all']} context={scopesContext} path={INSURANCE_COMPANY_ROUTE}>
          <InsuranceCompanyRouter
            insuranceQuote={insuranceQuote}
            refreshInsuranceQuote={refreshInsuranceQuote}
            loading={loading}
          />
        </RouteScopesControl>

        <RouteScopesControl scopes={['insurance:all', 'pension:all']} path={BENEFITS_COMPANY_ROUTE}>
          <BenefitsOverviewPage
            pensionSchemes={pensionSchemes}
            customBenefits={customBenefits}
            insuranceQuote={insuranceQuote}
            refreshAllBenefits={refreshAllBenefits}
            loading={loading}
          />
        </RouteScopesControl>
      </Switch>
    </RootStyle>
  );
};
