import React from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { WifiOptions } from '@v2/feature/device/device.dto';
import { FieldValueComponent } from '@v2/feature/device/device.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as GreenOkay } from '@/images/side-bar-icons/ok-green.svg';

export const WifiPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  configurationPayloadOption,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  policyName: string;
  configurationPayloadOption?: WifiOptions | null;
}) => {
  const { polyglot } = usePolyglot();

  if (!configurationPayloadOption) return null;

  const entries = Object.entries(configurationPayloadOption);
  const payloadIdentifierEntry = entries.filter(([key]) => key === 'PayloadIdentifier');
  const passwordEntry = entries.find(([key]) => key === 'Password');
  const otherEntries = entries.filter(([key]) => !['PayloadIdentifier', 'Password'].includes(key));

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
      <Box>
        <Typography variant="title2">{policyName}</Typography>

        <Box
          component="dl"
          sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
        >
          {otherEntries.map(([key, value]) => (
            <FieldValueComponent
              key={key}
              title={polyglot.t(`InHouseMdmPolicyViewDrawer.${key}`)}
              value={<Typography variant="title4">{String(value)}</Typography>}
            />
          ))}
          {payloadIdentifierEntry.map(([key, value]) => (
            <FieldValueComponent
              key={key}
              title={polyglot.t(`Payload identifier`)}
              value={<Typography variant="title4">{String(value)}</Typography>}
            />
          ))}
          {passwordEntry ? (
            <Box>
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', mt: spacing.m10 }}>
                <GreenOkay width={15} fill={themeColors.Green} height={15} />
                <Typography variant={'caption'} sx={{ ...themeFonts.caption, color: themeColors.black }}>
                  WiFi password
                </Typography>
              </Box>
              <Box sx={{ mt: spacing.m15 }}>
                <TextfieldComponent type="password" value={passwordEntry} autoFocus />
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', mt: spacing.m20 }}>
              <Mistake width={15} height={15} />
              <Typography variant={'caption'} sx={{ ...themeFonts.caption, color: themeColors.Red }}>
                No WiFi password
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </DrawerModal>
  );
};
