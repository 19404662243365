import axios from 'axios';

import {
  MoveToSectionCycle,
  OrderPreferenceCycleUpdate,
  ReachType,
  RemoveFromSectionCycle,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import {
  SurveyCycle,
  SurveyCycleById,
  SurveyCycleCreate,
  SurveyCycleLaunch,
  SurveyCycleResult,
  SurveyCycleUpdate,
  SurveyHeatmap,
  SurveyInsights,
  SurveyParticipation,
  SurveyTimelineSettings,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { SelectedFiltersRequest } from '@/v2/feature/reports/reports.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class SurveyCycleAPI {
  static async createSurveyCycle(surveyCycleBody: SurveyCycleCreate): Promise<SurveyCycle> {
    return (await axios.post(`/apiv2/survey-cycle`, surveyCycleBody)).data;
  }

  static async duplicateCycle(cycleId: string): Promise<SurveyCycle> {
    return (await axios.post(`/apiv2/survey-cycle/${cycleId}/duplicate`)).data;
  }

  static async deleteCycle(cycleId: string): Promise<SurveyCycle> {
    return (await axios.delete(`/apiv2/survey-cycle/${cycleId}`)).data;
  }

  static async updateSurveyCycle(surveyCycleBody: SurveyCycleUpdate): Promise<void> {
    return (await axios.patch(`/apiv2/survey-cycle`, surveyCycleBody)).data;
  }

  static async launchSurveyCycle(cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/survey-cycle/${cycleId}/launch`)).data;
  }

  static async cloneTemplateInCycle(templateId: string, cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/survey-cycle/clone-template/${templateId}/${cycleId}`)).data;
  }

  static async cloneQuestionsInCycle(questionBankIds: string[], cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/survey-cycle/clone-questions/${cycleId}`, questionBankIds)).data;
  }

  static async updateOrderSurveyCycle(updateBody: OrderPreferenceCycleUpdate): Promise<SurveyCycle> {
    return (await axios.patch('/apiv2/survey-cycle/order', updateBody)).data;
  }

  static async moveQuestionOutsideSection(updateBody: RemoveFromSectionCycle): Promise<SurveyCycle> {
    return (await axios.patch(`/apiv2/survey-cycle/remove-from-section`, updateBody)).data;
  }

  static async moveQuestionInsideSection(updateBody: MoveToSectionCycle): Promise<SurveyCycle> {
    return (await axios.patch(`/apiv2/survey-cycle/add-to-section`, updateBody)).data;
  }

  static async addParticipants(cycleId: string, toAddParticipantIds: number[]) {
    return (await axios.patch(`/apiv2/survey-cycle/${cycleId}/add-participants`, toAddParticipantIds)).data;
  }

  static async updateStateOfRollingSurvey(cycleId: string): Promise<SurveyCycle> {
    return (await axios.patch(`/apiv2/survey-cycle/${cycleId}/rolling-state`)).data;
  }

  static async updateTimelinelinesSurveyCycle(
    cycleId: string,
    timelineSettings: SurveyTimelineSettings
  ): Promise<void> {
    return (await axios.patch(`/apiv2/survey-cycle/${cycleId}/timeline-settings`, timelineSettings)).data;
  }

  static async reopenCycle(cycleId: string, extendFor: number): Promise<SurveyCycle> {
    return (await axios.patch(`/apiv2/survey-cycle/${cycleId}/reopen`, { extendFor })).data;
  }

  // deleteParticipants and entries in an ongoing cycle
  static async deleteParticipants(cycleId: string, participantsToDelete: number[]) {
    return (await axios.patch(`/apiv2/survey-cycle/${cycleId}/delete-participants`, participantsToDelete)).data;
  }

  static async generateRollingSurveyParticipants(
    selectedFilters: SelectedFiltersRequest,
    reach: ReachType
  ): Promise<{ userId: number; displayName: string; jobTitle: string }[]> {
    return (await axios.post(`/apiv2/survey-cycle/generate/rolling-particpants?reach=${reach}`, { selectedFilters }))
      .data;
  }

  static async updateEnrolmentTrigger(cycleId: string, filter: SelectedFiltersRequest): Promise<SurveyCycle> {
    return (await axios.patch(`/apiv2/survey-cycle/${cycleId}/enrolment-trigger`, filter)).data;
  }

  static async activateSurveyCycle(cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/survey-cycle/${cycleId}/activate`)).data;
  }
}

export class SurveyCycleEndpoints {
  static getEventSourceUrlForSurveyCycleLaunch(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-surveyCycleLaunch`,
    };
  }
  static getEventSourceUrlForAddParticipantsInCycle(channelName: string): { url: string } {
    return {
      url: `/apiv2/push-events/${channelName}-addParticipantsInCycle`,
    };
  }

  static getSurveyCycleById(cycleId: string, reach: ReachType): Endpoint<SurveyCycle> {
    return {
      url: `/apiv2/survey-cycle/${cycleId}?reach=${reach}`,
    };
  }

  static getSurveyCycleResult(cycleId: string, reach: ReachType, filterString: string): Endpoint<SurveyCycleResult> {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/result?reach=${reach}&${filterString}`,
    };
  }

  static getSurveyCycleInsights(cycleId: string, reach: ReachType): Endpoint<SurveyInsights> {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/insights?reach=${reach}`,
    };
  }

  static getSurveyCycleHeatmap(cycleId: string, filter: string, reach: ReachType): Endpoint<SurveyHeatmap[]> {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/heatmap?filter=${filter}&reach=${reach}`,
    };
  }

  static getSurveyParticipantsByCycleId(cycleId: string, reach: ReachType): Endpoint<number[]> {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/participants?reach=${reach}`,
    };
  }

  static getSurveyParticipationByCycleId(cycleId: string): Endpoint<SurveyParticipation> {
    return {
      url: `/apiv2/survey-cycle/participation/${cycleId}`,
    };
  }

  static getSurveyLaunchDetailsByCycleId(cycleId: string): Endpoint<SurveyCycleLaunch> {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/launch`,
    };
  }

  static getSurveyCycleStructureById(cycleId: string): Endpoint<SurveyCycleById> {
    return {
      url: `/apiv2/survey-cycle/structure/${cycleId}`,
    };
  }

  static getOngoingCycles(reach: ReachType, search: string, filterString: string): Endpoint<SurveyCycle[]> {
    const urlParams = new URLSearchParams({
      reach,
      ...(search && { search }),
      ...(filterString && { filterString }),
    });
    return {
      url: `/apiv2/survey-cycle/ongoing?${urlParams.toString()}`,
    };
  }
}
