import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { sortDate, sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { UserAccountTypes, UserAccountTypesConfig } from '@v2/feature/user/user.interface';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { useDebouncedCallback } from 'use-debounce';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { AccountStatus } from '@/lib/users';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableFilter } from '@/v2/components/table/table-filter.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { IIncludable, TableColumn } from '@/v2/feature/super-admin/components/helper/table-helper';
import { UserAPI } from '@/v2/feature/user/user.api';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const formatDate = (date: string) => moment(date).format('DD/MM/YYYY');

interface UserDetails {
  readonly userId: number;
  readonly companyName: string;
  readonly name: string;
  readonly email: string;
  readonly personalEmail: string;
  readonly lastTimeOnline: Date | null;
  readonly totalActions: number;
  readonly role: string;
  readonly createdAt: Date;
  readonly leaveDate: Date | null;
  readonly startDate: Date | null;
  readonly accountStatus: AccountStatus;
  readonly userStatus: string;
}

const getUserRoleLabel = (accountType: UserAccountTypesConfig): UserAccountTypes => {
  switch (true) {
    case accountType.Admin: {
      return UserAccountTypes.Admin;
    }
    case accountType.ProfileOwner: {
      return UserAccountTypes.ProfileOwner;
    }
    case accountType.OtherProfiles: {
      return UserAccountTypes.OtherProfiles;
    }
    case accountType.Manager: {
      return UserAccountTypes.Manager;
    }
    default: {
      return UserAccountTypes.OtherProfiles;
    }
  }
};

const formatUsers = (users: readonly UserDetailsSuperAdminDto[]): UserDetails[] =>
  users.map((user) => ({
    userId: user.userId,
    companyName: user.company.name,
    name: `${user.firstName} ${user.lastName}`,
    email: user.emailAddress,
    personalEmail: user.personalEmail ?? '-',
    accountStatus: user.accountStatus ?? '-',
    userStatus: user.userEvents[0]?.status ?? '-',
    lastTimeOnline: user.lastTimeOnline,
    totalActions: user.totalActions ?? 0,
    role: getUserRoleLabel(user.accountType),
    startDate: user.startDate,
    leaveDate: user.leaveDate,
    createdAt: user.createdAt,
  }));

export const SuperAdminUsersPage = (): JSX.Element => {
  const [users, setUsers] = useState<readonly UserDetailsSuperAdminDto[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [filterString, setFilterString] = useState<string>('Status=Employed,Hired');
  const [filterTypes, setFilterTypes] = useState({});

  const getFilterOptions = useCallback(async () => {
    const USER_STATUSES = [
      { label: 'Employed', value: 'Employed' },
      { label: 'Hired', value: 'Hired' },
      { label: 'Terminated', value: 'Terminated' },
      { label: 'Leave', value: 'Leave' },
    ];

    const USER_ROLES = [
      { label: UserAccountTypes.Admin, value: 'Admin' },
      { label: UserAccountTypes.ProfileOwner, value: 'ProfileOwner' },
      { label: UserAccountTypes.OtherProfiles, value: 'OtherProfiles' },
      { label: UserAccountTypes.Manager, value: 'Manager' },
    ];

    const FILTERS = ['Status', 'Role'];

    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case 'Status':
          filters = {
            ...filters,
            [filter]: USER_STATUSES,
          };
          break;

        case 'Role':
          filters = {
            ...filters,
            [filter]: USER_ROLES,
          };
          break;
        default:
          break;
      }
    });

    setFilterTypes(filters);
  }, []);

  const debouncedFilter = useDebouncedCallback(async (filterString: string) => {
    try {
      setFilterString(filterString);
      // Resets pagination index once a new filter is applied
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage('Failed to handle filter', 'error');
    }
  }, 500);

  const handleFilter = useCallback((event: string) => debouncedFilter.callback(event), [debouncedFilter]);

  const fetchUsers = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string; filter?: string }) => {
      try {
        setIsLoadingUsers(true);
        const { users, pagination: paginationInfo } = await UserAPI.getAllUsersAsSuperAdmin(params);

        setUsers(users || []);
        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingUsers(false);
      } catch (error) {
        showMessage(`Something went wrong fetching users. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const fetchAllUsersForExport = useCallback(async () => {
    try {
      const { users } = await UserAPI.getAllUsersAsSuperAdmin({
        page: '1',
        pageSize: '50',
        searchQuery: '',
        shouldLimitResult: 'false',
      });

      return (formatUsers(users) as unknown) as Datas;
    } catch (error) {
      console.error('Something went wrong fetching users for export', error.message);
      return [];
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUsers({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
        filter: filterString,
      });

      getFilterOptions();
    })();
  }, [pageIndex, searchQuery, fetchUsers, getFilterOptions, filterString]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const formattedUsers = useMemo(() => formatUsers(users), [users]);

  const usersColumn = useMemo<ColumnDef<IIncludable & UserDetails, unknown>[]>(
    () => [
      new TableColumn<UserDetails>().define({
        header: 'User Id',
        id: 'userId',
        size: 140,
        fieldName: 'userId',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.userId),
      }),

      new TableColumn<UserDetails>().define({
        header: 'User Name',
        id: 'name',
        size: 170,
        fieldName: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Company',
        id: 'companyName',
        size: 150,
        fieldName: 'companyName',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.companyName),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Email',
        id: 'email',
        size: 150,
        fieldName: 'email',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.email),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Account Status',
        id: 'accountStatus',
        size: 140,
        fieldName: 'accountStatus',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.accountStatus),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Last Time Online',
        id: 'lastTimeOnline',
        size: 140,
        fieldName: 'lastTimeOnline',
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.lastTimeOnline),
      }),

      new TableColumn<UserDetails>().define({
        header: 'User Status',
        id: 'userStatus',
        size: 140,
        fieldName: 'userStatus',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.userStatus),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Personal Email',
        id: 'personalEmail',
        size: 140,
        fieldName: 'personalEmail',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.personalEmail),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Role',
        id: 'role',
        size: 140,
        fieldName: 'role',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.role),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Total Actions',
        id: 'totalActions',
        size: 140,
        fieldName: 'totalActions',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalActions),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Start Date',
        id: 'startDate',
        size: 140,
        fieldName: 'startDate',
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.startDate),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Leave Date',
        id: 'leaveDate',
        size: 140,
        fieldName: 'leaveDate',
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.leaveDate),
      }),

      new TableColumn<UserDetails>().define({
        header: 'Date Created',
        id: 'createdAt',
        size: 140,
        fieldName: 'createdAt',
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt),
      }),
    ],
    []
  );

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Users</Typography>}
        views={<></>}
      />
      <ContentWrapper loading={isLoadingUsers}>
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                ...spacing.mb20,
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <TableSearch
                style={{ width: '20em' }}
                query={searchQuery}
                handleChange={(e) => {
                  setSearchQuery(e.target.value?.trim() ?? '');
                  setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                }}
                placeholder="Enter id, name, email or company name"
                debounceSearchWait={500}
              />

              {filterTypes && (
                <TableFilter filterTypes={filterTypes} setFilterString={handleFilter} filterString={filterString} />
              )}
            </Box>

            <CsvDownloader filename="users" separator="," datas={fetchAllUsersForExport}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g5,
                  ...secondaryCTABtn,
                  cursor: 'pointer',
                  height: '20px',
                }}
              >
                <Export {...iconSize} />{' '}
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Export</Typography>
              </Box>
            </CsvDownloader>
          </Box>
          {!isLoadingUsers && (
            <>
              <BasicServerTable<IIncludable & UserDetails>
                rowData={[...formattedUsers]}
                columnData={usersColumn}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                setPagination={setPagination}
                totalPages={totalPages}
                totalItems={totalItems}
              />
            </>
          )}
        </>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
