import { Suspense } from 'react';

import { Typography } from '@mui/material';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';

import { UserOverviewConfig } from '@/models';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PersonalPayroll } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/personal-payroll.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface UserProfilePayrollPageProps {
  readonly userId: number;
  readonly userOverviewConfig: UserOverviewConfig;
}

export const UserProfilePayroll = ({ userId, userOverviewConfig }: UserProfilePayrollPageProps): JSX.Element => {
  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2 }}>Payroll</Typography>} />
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <PersonalPayroll userId={userId} inPayroll={userOverviewConfig.inPayroll} />
      </Suspense>
    </RootStyle>
  );
};
