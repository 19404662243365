import React, { useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from '@/lib/routes';
import { AutocompleteComponent, OptionObject } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ContractTemplate } from '@/v2/feature/templates/templates.interface';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly templateList: readonly ContractTemplate[];
  readonly setSelectedTemplate: (template?: ContractTemplate) => void;
}

export const SelectTemplateForContractModal = ({
  setOpen,
  open,
  templateList,
  setSelectedTemplate,
}: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const routerHistory = useHistory();
  const scopesContext = { userId: user.userId };

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  const templateOptions: OptionObject[] = useMemo(() => {
    let options: OptionObject<string | number>[] = [];

    if (templateList) {
      options = templateList.map((t) => ({
        label: t.name,
        value: t.id,
      }));
    }

    return options;
  }, [templateList]);

  const useSmartTemplateForDocument = () => {
    const matchingTemplate = templateList.find((t) => t.id === selectedTemplateId);
    if (matchingTemplate) setSelectedTemplate(matchingTemplate);
  };

  return (
    <DrawerModal
      isOpen={open}
      setIsOpen={setOpen}
      onClose={() => {
        setSelectedTemplate(undefined);
      }}
    >
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('ContractModal.template')}</Typography>
        <Typography variant="caption">{polyglot.t('ContractModal.documentFromSmartTemplate')}</Typography>

        <AutocompleteComponent
          name="selectedTemplateId"
          label={polyglot.t('ContractModal.templateSelection')}
          options={templateOptions}
          fullWidth
          value={templateOptions.find(({ value }: { value: number | string }) => value === selectedTemplateId) || null}
          compareValue={selectedTemplateId}
          // @ts-ignore
          onChange={(_, x: OptionObj) => {
            setSelectedTemplateId(x ? x.value : undefined);
          }}
          editList={{
            handler: () => routerHistory.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE),
            isHidden: !hasScopes(['templates', 'documents:all'], scopesContext),
          }}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            disabled={!selectedTemplateId}
            onClick={useSmartTemplateForDocument}
          >
            {polyglot.t('ContractModal.useTemplateForDocument')}{' '}
          </ButtonComponent>
        </Box>
      </Box>
    </DrawerModal>
  );
};
