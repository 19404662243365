import { useCallback, useContext, useEffect, useState } from 'react';

import { BenefitsUserOverviewPage } from '@v2/feature/benefits/benefits-user-overview.page';
import { CustomBenefitUserRouter } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit-user.router';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { InsuranceUserRouter } from '@v2/feature/benefits/subfeature/insurance/insurance-user.router';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsuranceQuoteDto, UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionMeRouter } from '@v2/feature/benefits/subfeature/pension/pension-me.router';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { useUserProfileConfig } from '@v2/feature/user/features/user-profile/user-profile.hook';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@v2/styles/root.styles';
import { Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BENEFITS_ME_ROUTE, CUSTOM_BENEFIT_ME_ROUTE, INSURANCE_ME_ROUTE, PENSION_ME_ROUTE } from '@/lib/routes';

interface BenefitsMeRouterProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly loading: boolean;
}

export const BenefitsMeRouter = ({ insuranceQuote, loading }: BenefitsMeRouterProps) => {
  const [state] = useContext(GlobalContext);
  const userId = state.user.userId;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionOwnerScope = hasScopes(['pension'], scopesContext);
  const hasInsuranceOwnerScope = hasScopes(['insurance'], scopesContext);

  const { data: userBenefits, isLoading: loadingCustomBenefits, mutate: refreshUserCBenefits } = useApiClient(
    hasInsuranceOwnerScope ? CustomBenefitEndpoints.allEffectiveUserBenefitsByUserId(userId) : null,
    {
      suspense: false,
    }
  );

  const [loadingPension, setLoadingPension] = useState<boolean>(false);
  const [loadingInsurance, setLoadingInsurance] = useState<boolean>(false);

  const { userOverviewConfig, refreshUserOverviewConfig } = useUserProfileConfig(userId);

  const [employeePension, setEmployeePension] = useState<UserPensionDto | null>(null);
  const [userInsurance, setUserInsurance] = useState<UserInsuranceDto | null>(null);
  const [showMessage] = useMessage();

  const fetchUserInsurance = useCallback(async () => {
    if (!hasInsuranceOwnerScope) return;
    try {
      if (insuranceQuote?.policyId) {
        setLoadingInsurance(true);
        const userInsurance = await InsuranceAPI.getUserInsurancePolicyById(state.user.userId, true);
        setUserInsurance(userInsurance);
      }
    } catch (error) {
      showMessage(`Could not get insurance policy details. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoadingInsurance(false);
    }
  }, [hasInsuranceOwnerScope, insuranceQuote?.policyId, state.user.userId, showMessage]);

  const autoEnrollmentCallback = useCallback(async () => {
    if (!hasPensionOwnerScope) return;
    try {
      setLoadingPension(true);
      const [employeePension] = await Promise.all([
        PensionAPI.getEmployeePension(userId),
        await refreshUserOverviewConfig(),
      ]);

      setEmployeePension(employeePension ?? null);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoadingPension(false);
    }
  }, [hasPensionOwnerScope, userId, refreshUserOverviewConfig, showMessage]);

  const refreshCustomBenefits = useCallback(async () => {
    if (refreshUserCBenefits) await refreshUserCBenefits();
  }, [refreshUserCBenefits]);

  useEffect(() => {
    if (userOverviewConfig?.inPension) {
      autoEnrollmentCallback();
    }
  }, [autoEnrollmentCallback, userOverviewConfig?.inPension]);

  useEffect(() => {
    fetchUserInsurance();
  }, [fetchUserInsurance]);

  return (
    <RootStyle>
      <Switch>
        {userOverviewConfig.inCustomBenefits && (
          <RouteScopesControl scopes={['insurance']} context={scopesContext} path={CUSTOM_BENEFIT_ME_ROUTE}>
            <CustomBenefitUserRouter
              userId={userId}
              userBenefits={userBenefits ?? []}
              loading={!!loadingCustomBenefits}
              refreshCustomBenefits={refreshCustomBenefits}
            />
          </RouteScopesControl>
        )}

        <RouteScopesControl scopes={['insurance']} context={scopesContext} path={INSURANCE_ME_ROUTE}>
          <InsuranceUserRouter
            userId={userId}
            userInsurance={userInsurance}
            refresh={fetchUserInsurance}
            loading={loadingInsurance || loading}
          />
        </RouteScopesControl>

        {userOverviewConfig?.inPension && (
          <RouteScopesControl scopes={['pension']} context={scopesContext} path={PENSION_ME_ROUTE}>
            <PensionMeRouter
              userId={userId}
              userPension={employeePension}
              refreshUserPension={autoEnrollmentCallback}
              loading={loadingPension || loading}
            />
          </RouteScopesControl>
        )}

        <RouteScopesControl scopes={['insurance', 'pension']} context={scopesContext} path={BENEFITS_ME_ROUTE}>
          <BenefitsUserOverviewPage
            userId={userId}
            userPension={employeePension}
            userInsurance={userInsurance}
            userBenefits={userBenefits ?? []}
            userOverviewConfig={userOverviewConfig}
            loading={loadingInsurance || loadingPension || loading || !!loadingCustomBenefits}
          />
        </RouteScopesControl>
      </Switch>
    </RootStyle>
  );
};
