import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SCParticipantDeleteModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-delete-modal.component';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

interface ISCParticipantsSharedTable {
  readonly surveyCycle: SurveyCycle | null | undefined;
  readonly surveyParticipants: number[] | null | undefined;
  readonly surveyParticipantsLoading: boolean | undefined;
  readonly refresh: () => Promise<void>;
  readonly isEditable: boolean;
}
export const SCParticipantsSharedTable = ({
  surveyCycle,
  surveyParticipants,
  surveyParticipantsLoading,
  refresh,
  isEditable,
}: ISCParticipantsSharedTable) => {
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [isRevieweeDeleteOpen, setIsRevieweeDeleteOpen] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();

  const deleteParticipants = async () => {
    try {
      if (surveyCycle && [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle.state)) {
        const updatedReviewees = surveyCycle.participantIds?.filter(
          (participant) => !selectionModel.includes(participant)
        );

        await SurveyCycleAPI.updateSurveyCycle({
          ...surveyCycle,
          participantIds: updatedReviewees,
        });
        setSelectionModel([]);
        showMessage('Successfully deleted the participants', 'success');
        await refresh();
      }

      if (surveyCycle?.state === CycleState.Ongoing) {
        setIsRevieweeDeleteOpen(true);
      }
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };

  const tableColumns = useMemo<ColumnDef<number, number>[]>(
    () => [
      ...(isEditable
        ? [
            {
              id: 'select',
              enableSorting: false,
              minSize: 1,
              maxSize: 1,
              header: () => {
                const displayedSelfId = new Set(surveyParticipants?.map((eachId) => eachId));
                const allSelected =
                  selectionModel.length > 0 &&
                  selectionModel.length === displayedSelfId.size &&
                  selectionModel.every((id) => displayedSelfId.has(id));
                return (
                  <Box sx={{ display: 'initial' }} onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name={'allSelected'}
                      checked={allSelected}
                      value="allSelceted"
                      onChange={(_, checked) => {
                        setSelectionModel(checked ? [...displayedSelfId] : []);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }: { row: Row<number> }) => (
                <Box onClick={(e) => e.stopPropagation()}>
                  <CheckboxComponent
                    label={undefined}
                    name={original.toString()}
                    checked={selectionModel.includes(original)}
                    value={original.toString()}
                    onChange={() => {
                      let finalArray = [];
                      if (selectionModel?.includes(original)) {
                        finalArray = selectionModel.filter((sm) => sm !== original);
                      } else finalArray = [...selectionModel, original];
                      setSelectionModel(finalArray);
                    }}
                  />
                </Box>
              ),
            },
          ]
        : []),
      {
        header: () => 'Participant',
        accessorFn: (row) => row,
        id: 'participant',
        enableSorting: false,

        cell: ({ row: { original } }) => (original ? <UserCell userId={original} /> : <EmptyCell />),
        minSize: 90,
        maxSize: 90,
      },
      {
        header: () => 'Email',
        accessorFn: (row) => row,
        id: 'email',
        enableSorting: false,

        cell: ({ row: { original } }) => {
          const cachedUser = original ? getCachedUserById(original) : undefined;
          return original && cachedUser && cachedUser?.emailAddress ? (
            <Typography variant="caption">{cachedUser?.emailAddress}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        minSize: 90,
        maxSize: 90,
      },
      {
        header: () => 'Job title',
        accessorFn: (row) => row,
        id: 'jobTitle',
        enableSorting: false,

        cell: ({ row: { original } }) => {
          const cachedUser = original ? getCachedUserById(original) : undefined;
          return original && cachedUser && cachedUser?.role?.jobTitle ? (
            <Typography variant="caption">{cachedUser?.role?.jobTitle}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        minSize: 90,
        maxSize: 90,
      },
      {
        header: () => 'Department',
        accessorFn: (row) => row,
        id: 'department',
        enableSorting: false,

        cell: ({ row: { original } }) => {
          const cachedUser = original ? getCachedUserById(original) : undefined;
          return original && cachedUser && cachedUser?.role?.department?.name ? (
            <Typography variant="caption">{cachedUser?.role?.department?.name}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        minSize: 90,
        maxSize: 90,
      },
    ],
    [surveyParticipants, selectionModel, setSelectionModel, getCachedUserById, isEditable]
  );

  if (!surveyCycle) return <>Survey cycle not found</>;

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <StyledMenuComponent
          options={[
            {
              handler: async () => deleteParticipants(),
              label: 'Remove participants',
              disabled: false,
            },
          ]}
          sx={{
            visibility: isEditable && selectionModel.length > 0 ? 'visible' : 'hidden',
            opacity: selectionModel.length > 0 ? 1 : 0,
            transition: 'opacity 0.3s ease, visibility 0.3s ease',
          }}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Actions',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />
      </Box>
      <BasicTable rowData={surveyParticipants ?? []} columnData={tableColumns} loading={surveyParticipantsLoading} />

      <SCParticipantDeleteModal
        surveyCycle={surveyCycle}
        toDeleteParticipantIds={selectionModel}
        isOpen={isRevieweeDeleteOpen}
        setIsOpen={setIsRevieweeDeleteOpen}
        onClose={() => {
          setIsRevieweeDeleteOpen(false);
          setSelectionModel([]);
        }}
        refresh={refresh}
      />
    </Box>
  );
};
