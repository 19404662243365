import React from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ScheduleGeneralFormData } from '@v2/feature/attendance/attendance.interface';
import { getScheduleGeneralValidationSchema } from '@v2/feature/attendance/attendance.util';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

interface ScheduleGeneralFormProps {
  readonly attendanceSchedule: ScheduleGeneralFormData;
  readonly onSubmit: (values: ScheduleGeneralFormData) => void | Promise<void>;
  readonly buttonName: string;
  readonly loading: boolean;
}

export const ScheduleGeneralForm = ({
  attendanceSchedule,
  onSubmit,
  buttonName,
  loading,
}: ScheduleGeneralFormProps) => {
  const { polyglot } = usePolyglot();

  const formik = useFormik<ScheduleGeneralFormData>({
    initialValues: {
      name: attendanceSchedule.name,
    },
    validationSchema: getScheduleGeneralValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={fieldSx}>
          <TextfieldComponent
            name="name"
            label={polyglot.t('ScheduleGeneralForm.name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={(formik.touched.name && formik.errors.name) ?? ' '}
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name={buttonName} loading={loading} fullWidth sizeVariant="medium" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
