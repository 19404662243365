import React, { useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortDate, sortNumeric, sortString } from '@v2/components/table/table-sorting.util';

import { ReactComponent as Action } from '@/images/fields/Action.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { GrowthFactorFormModal } from '@/v2/feature/growth/growth-factor/components/growth-factor-form-modal.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const GrowthFactorPage = () => {
  const { data: allGrowthFactors, isLoading, mutate: refreshFactors } = useApiClient(
    GrowthFactorEndpoints.getGrowthFactorsByCompanyId(),
    {
      suspense: false,
    }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFactor, setSelectedFactor] = useState<GrowthFactor | undefined>(undefined);
  const [searchInput, setSearchInput] = useState('');

  const filteredFactors = useMemo(() => {
    if (!searchInput || !allGrowthFactors) return allGrowthFactors ?? [];

    const search = searchInput.toLowerCase();

    return allGrowthFactors.filter((f) => f.name.toLowerCase().includes(search));
  }, [searchInput, allGrowthFactors]);

  const getGrowthFactorActionsOptions = useCallback((original: GrowthFactor) => {
    return [
      {
        icon: <Edit {...iconSize} />,
        handler: () => {
          setSelectedFactor(original);
          setIsOpen(true);
        },
        label: 'Edit',
        disabled: false,
      },
    ];
  }, []);

  const tableColumns = useMemo<ColumnDef<GrowthFactor, GrowthFactor>[]>(
    () => [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) =>
          original.name ? <Typography variant="caption">{original.name}</Typography> : <EmptyCell />,
        // minSize: 120,
        // maxSize: 150,
      },
      {
        header: () => 'Color',
        accessorFn: (row) => row,
        id: 'color',
        minSize: 120,
        maxSize: 150,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.color ? (
            <div
              style={{
                backgroundColor: original.color ?? themeColors.Grey,
                width: '10px',
                height: '10px',
                borderRadius: radius.br25,
              }}
            />
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Created by',
        accessorFn: (row) => row,
        id: 'createdBy',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => (item.companyId ? item.createdBy ?? 0 : 0)),
        cell: ({ row: { original } }) => (
          <div>
            {!original.companyId ? (
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" />
                <Typography variant="caption">Zelt</Typography>
              </Stack>
            ) : original.createdBy ? (
              <UserCell userId={original.createdBy} />
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Created on',
        accessorFn: (row) => row,
        id: 'createdAt',
        minSize: 50,
        maxSize: 120,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt),
        cell: ({ row: { original } }) =>
          original.createdAt ? (
            <Typography variant="caption">
              {new LocalDate(original.createdAt)
                .getDate()
                .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.companyId !== null && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledMenuComponent
                options={getGrowthFactorActionsOptions(original)}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'light',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <Action {...iconSize} />,
                }}
              />
            </Box>
          ),
        minSize: 50,
        maxSize: 100,
      },
    ],
    [getGrowthFactorActionsOptions]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Factors</Typography>}
        showAction={true}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsOpen(true)}>
            New factor
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={false}>
        <Box sx={{ mb: spacing.s1 }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
        <BasicTable rowData={filteredFactors} columnData={tableColumns} loading={isLoading} />
        <GrowthFactorFormModal
          growthFactor={selectedFactor}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setSelectedFactor(undefined);
          }}
          refresh={async () => {
            await refreshFactors?.();
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
