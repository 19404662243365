export const booleanValues = new Set([true, false]);

export const stringifiedBooleanValues = new Set(['true', 'false']);

export const zeroValues = new Set([0]);

export function sum<T>(entries: T[], getValue: (entry: T) => number | undefined) {
  return entries.reduce((total, entry) => total + (getValue(entry) ?? 0), 0);
}

const caseInsensitiveCollator = new Intl.Collator(undefined, { sensitivity: 'base' });

export function caseInsensitiveFind<T>(
  array: readonly T[],
  findValue: string,
  getValue: (entry: T) => string
): T | undefined {
  return array.find((item) => caseInsensitiveCollator.compare(getValue(item), findValue) === 0);
}

export function caseInsensitiveSort<T>(a: T, b: T, getValue: (entry: T) => string): number {
  return caseInsensitiveCollator.compare(getValue(a), getValue(b));
}

export function clamp<T>(array: readonly T[], index: number): T {
  if (index < 0) return array[0];
  if (index >= array.length) return array[array.length - 1];
  return array[index];
}

export function filterByTextSearch<T>(
  search: string | null | undefined,
  array: T[],
  getTextValues: (item: T) => (string | null | undefined)[]
): T[] {
  if (!search) {
    return array.slice();
  }
  const lowerSearch = search.toLocaleLowerCase();
  return array.filter((item) => {
    // extract the strings from this item that could be a match
    const texts = getTextValues(item);

    // search the strings for a match
    return texts.some((text) => typeof text === 'string' && text.toLocaleLowerCase().includes(lowerSearch));
  });
}
