import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { AutocompleteComponent } from '@v2/components/forms/autocomplete.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { capitalize } from 'lodash';

import { CompanyDepartmentDto } from '@/models/company-department.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

// TODO: @polyglot-later
export const CustomUserModal = ({
  isCustomOpen,
  setCustomIsOpen,
  label,
  onChange,
  customRuleOptions,
  onClose,
  setCustomRuleTypeString,
  sites,
  departments,
  entities,
  shouldSelectUsers = false,
}: {
  isCustomOpen: boolean;
  setCustomIsOpen: Dispatch<SetStateAction<boolean>>;
  label?: string;
  onChange: (userIds: number[], customRule?: string) => void;
  customRuleOptions: OptionObj[];
  onClose: () => void;
  setCustomRuleTypeString: React.Dispatch<React.SetStateAction<string | undefined>>;
  sites: SiteDto[];
  departments: CompanyDepartmentDto[];
  entities: CompanyUnitDto[];
  shouldSelectUsers?: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedValue, setSelectedValue] = useState<string | ''>('');
  const [customRule, setCustomRule] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<OptionObj[]>([]);
  const { nonTerminatedCachedUsers } = useCachedUsers();

  const filteredUsers = useMemo(() => {
    let filteredUsersList = nonTerminatedCachedUsers;
    if (selectedValue === 'site') {
      filteredUsersList = filteredUsersList.filter(
        (u) => u.role?.site?.id && selectedOption.some((o) => o.value === u.role?.site?.id)
      );
    }
    if (selectedValue === 'department') {
      filteredUsersList = filteredUsersList.filter(
        (u) => u.role?.department?.id && selectedOption.some((o) => o.value === u.role?.department?.id)
      );
    }
    if (selectedValue === 'entity') {
      filteredUsersList = filteredUsersList.filter(
        (u) => u.userContract?.entityId && selectedOption.some((o) => o.value === u.userContract?.entityId)
      );
    }

    return filteredUsersList;
  }, [selectedValue, nonTerminatedCachedUsers, selectedOption]);

  const options = useMemo(() => {
    if (selectedValue === 'department') {
      return departments.map((d) => ({ label: polyglot.t(d.name), value: d.id }));
    }
    if (selectedValue === 'site') {
      return sites.map((s) => ({ label: polyglot.t(s.name), value: s.id }));
    }
    if (selectedValue === 'entity') {
      return entities.map((e) => ({ label: polyglot.t(e.legalName), value: e.id }));
    }
    return [];
  }, [polyglot, departments, entities, selectedValue, sites]);

  return (
    <DrawerModal isOpen={isCustomOpen} setIsOpen={setCustomIsOpen} onClose={onClose}>
      <Box>
        <Typography variant="title2">{label ?? polyglot.t('General.users')}</Typography>

        <Box sx={{ marginTop: spacing.m30 }}>
          <Typography variant="title2">{polyglot.t('CustomUserModal.rule')}</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g30, ...spacing.mt20 }}>
          <SelectComponent
            label={polyglot.t('CustomUserModal.condition')}
            name="condition"
            options={customRuleOptions}
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.target.value);
              setSelectedOption([]);
            }}
          />

          {selectedValue && (
            <AutocompleteComponent
              multiple
              label={capitalize(selectedValue)}
              disableCloseOnSelect
              name={selectedValue}
              filterSelectedOptions
              value={selectedOption}
              compareValue={selectedOption}
              options={options ?? []}
              onChange={(_, e) => {
                const value = e as OptionObj[];

                setSelectedOption(value);
                setCustomRule(`${selectedValue}=${value.map((v) => v.value).join(',')}`);
                setCustomRuleTypeString(`${selectedValue} ${value.map((v) => v.label).join(', ') || ''}`);
              }}
              isOptionEqualToValue={(x, y) => x.value === y.value}
              renderTags={(options) => {
                return options.map((o) => o.label).join(', ');
              }}
            />
          )}

          {selectedValue && selectedOption && (
            <Typography variant="title4">
              {polyglot.t('CustomUserModal.selectedFrom', {
                source: capitalize(selectedValue),
                source2: selectedOption.map((o) => o.label).join(', ') || '',
              })}
            </Typography>
          )}
        </Box>

        {shouldSelectUsers && selectedValue && selectedOption && filteredUsers.length === 0 && (
          <Typography variant="caption" color="RedDark" sx={{ mt: spacing.m10 }}>
            {polyglot.t('CustomUserModal.noMatch')}
          </Typography>
        )}

        <Box sx={{ display: 'flex', gap: spacing.g10, ...spacing.mt40 }}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="secondary"
            fullWidth
            onClick={() => setCustomIsOpen(false)}
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
            onClick={() => {
              onChange(
                filteredUsers.map((u) => u.userId),
                customRule
              );
              setCustomIsOpen(false);
            }}
            disabled={shouldSelectUsers && filteredUsers.length === 0}
          >
            {polyglot.t('General.save')}
          </ButtonComponent>
        </Box>
      </Box>
    </DrawerModal>
  );
};
