import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { useProfileFields } from '@/hooks/profile-fields.hook';
import { DietaryRestrictionsSelect } from '@/v2/components/dietary-select.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { AboutValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';

export const AboutSchema = Yup.object().shape({
  about: Yup.string().optional().nullable(),
  hobbies: Yup.string().optional().nullable(),
  social: Yup.string().optional().nullable(),
  foodPreferences: Yup.string().optional().nullable(),
  dietaryRestrictions: Yup.string().nullable().notRequired(),
});

interface Props {
  readonly initialValues: AboutValues;
  readonly userId: number;
  readonly usedForDataImport?: boolean;
  readonly onSubmit?: (_: AboutValues) => void;
  readonly onClose: () => void;
  readonly handleRefresh: () => void;
}

export const AboutForm = ({
  initialValues,
  userId,
  usedForDataImport,
  onSubmit,
  onClose,
  handleRefresh,
}: Props): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { getDefaultField } = useProfileFields();

  const formik = useFormik<AboutValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: AboutSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          await UserAPI.patchUserAboutInfo(userId, values);
          showMessage(polyglot.t('AboutForm.successMessages.update'), 'success');
        }
        onSubmit?.({ ...values });
        handleRefresh();
        showMessage(polyglot.t('AboutForm.successMessages.update'), 'success');
      } catch (error) {
        showMessage(polyglot.t('AboutForm.errorMessages.save'), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('AboutForm.edit')}</Typography>
        <ProfileField fieldStub="about">
          <TextfieldComponent
            multiline
            name="about"
            label={polyglot.t('AboutForm.about')}
            value={formik.values.about}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.about && !!formik.errors.about}
            helperText={(formik.touched.about && formik.errors.about) ?? ' '}
            endAdornment="none"
            disabled={loading}
            required={getDefaultField('about')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="hobbies">
          <TextfieldComponent
            name="hobbies"
            label={polyglot.t('AboutForm.hobbies')}
            value={formik.values.hobbies}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.hobbies && !!formik.errors.hobbies}
            helperText={(formik.touched.hobbies && formik.errors.hobbies) ?? ' '}
            clearText={() => formik.setFieldValue('hobbies', '')}
            disabled={loading}
            required={getDefaultField('hobbies')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="social">
          <TextfieldComponent
            name="social"
            label={polyglot.t('AboutForm.social')}
            value={formik.values.social}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.social && !!formik.errors.social}
            helperText={(formik.touched.social && formik.errors.social) ?? ' '}
            clearText={() => formik.setFieldValue('social', '')}
            disabled={loading}
            required={getDefaultField('social')?.isRequired}
          />
        </ProfileField>
        <ProfileField fieldStub="foodPreferences">
          <TextfieldComponent
            name="foodPreferences"
            label={polyglot.t('AboutForm.foodPreferences')}
            value={formik.values.foodPreferences}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.foodPreferences && !!formik.errors.foodPreferences}
            helperText={(formik.touched.foodPreferences && formik.errors.foodPreferences) ?? ' '}
            clearText={() => formik.setFieldValue('foodPreferences', '')}
            disabled={loading}
            required={getDefaultField('foodPreferences')?.isRequired}
          />
        </ProfileField>

        <ProfileField fieldStub="dietaryRestrictions">
          <DietaryRestrictionsSelect
            name="dietaryRestrictions"
            value={formik.values.dietaryRestrictions}
            onChange={(_, value) => formik.setFieldValue('dietaryRestrictions', value)}
            disabled={loading}
            required={getDefaultField('dietaryRestrictions')?.isRequired}
          />
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
          disabled={loading}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose} disabled={loading}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
