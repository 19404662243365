import { Dispatch, SetStateAction, Suspense } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { convertMinutesToClockHours, isHourlyPolicy, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import {
  getAllowanceProratingRuleLongDesc,
  getAnnualBalanceFromBreakdown,
  getProratedAllowanceFromBreakdown,
  isoDateToLocale,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { round2Digits } from '@v2/util/number.util';

interface ProratedAllowanceDetailsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

export const ProratedAllowanceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats,
}: ProratedAllowanceDetailsDrawerProps) => {
  const { polyglot } = usePolyglot();
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box>
          <Typography variant="title2">{polyglot.t('AllowanceDrawer.proratedAllowance')}</Typography>
          <ProratedAllowanceDetailsDrawerContent
            userBalanceDetailedStats={userBalanceDetailedStats}
            absencePolicy={absencePolicy}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface ProratedAllowanceDetailsDrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

const ProratedAllowanceDetailsDrawerContent = ({
  absencePolicy,
  userBalanceDetailedStats,
}: ProratedAllowanceDetailsDrawerContentProps) => (
  <Box sx={{ mt: spacing.m20 }}>
    <CalculationSection absencePolicy={absencePolicy} userBalanceDetailedStats={userBalanceDetailedStats} />

    <DetailsSection absencePolicy={absencePolicy} userBalanceDetailedStats={userBalanceDetailedStats} />
  </Box>
);

export const Chip = ({ color, value }: { color: string; value: string | number }) => (
  <span
    style={{
      whiteSpace: 'nowrap',
      backgroundColor: color,
      padding: `${spacing.p5} ${spacing.p10} ${spacing.p5} ${spacing.p10}`,
      borderRadius: '15px',
    }}
    className="caption"
  >
    {value}
  </span>
);

interface ChipLineProps {
  showTimesAvgDayInHours: boolean;
  showFtePercent: boolean;
  showYearPercent: boolean;
  allowance: string;
  yearPercent: string;
  ftePercent: string;
  avgHoursPerDay: string;
  result: string;
}

const ChipLine = ({
  allowance,
  yearPercent,
  ftePercent,
  avgHoursPerDay,
  result,
  showTimesAvgDayInHours,
  showFtePercent,
  showYearPercent,
}: ChipLineProps) => (
  <Box sx={{ width: '100%', display: 'flex', gap: spacing.g5, alignItems: 'center', justifyContent: 'space-between' }}>
    {showTimesAvgDayInHours && showYearPercent && <Box>(</Box>}
    <Chip color={themeColors.FreshGreen} value={allowance} />
    {showYearPercent && <Box>X</Box>}
    {showYearPercent && <Chip color={themeColors.ZeltYellow} value={yearPercent} />}
    {showFtePercent && <Box>X</Box>}
    {showFtePercent && <Chip color={themeColors.Violet} value={ftePercent} />}
    {showTimesAvgDayInHours && <Box>)</Box>}
    {showTimesAvgDayInHours && <Box>X</Box>}
    {showTimesAvgDayInHours && showYearPercent && <Chip color={themeColors.Orange} value={avgHoursPerDay} />}
    <Box>=</Box>
    <Typography variant="title4" sx={{ whiteSpace: 'nowrap' }}>
      {result}
    </Typography>
  </Box>
);

interface CalculationSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

const CalculationSection = ({ absencePolicy, userBalanceDetailedStats }: CalculationSectionProps) => {
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy(absencePolicy);
  const breakdown = userBalanceDetailedStats[absencePolicy.id];

  if (!breakdown) return null;
  const showAvgWorkDay = !isHourly && (!breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength);

  const policyAllowance = getAnnualBalanceFromBreakdown(absencePolicy, breakdown, polyglot);
  const proratedBasicAllowance = getProratedAllowanceFromBreakdown(absencePolicy, breakdown, polyglot);

  // If prorating by startDate || prorating by startDate & ftePercent
  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    const firstOfJan = new Date();
    firstOfJan.setMonth(0, 1);
    const thirtyFirstOfDec = new Date();
    thirtyFirstOfDec.setMonth(11, 31);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        {breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
          const yearPercent = round2Digits((segment.daysInSegment / segment.daysInCycle) * 100); //

          const segmentValue =
            breakdown.isOnRegularSchedule && !isHourly && breakdown.currentAverageWorkDayLength
              ? polyglot.t('AllowanceDrawer.noOfDays', {
                  smart_count: round2Digits(segment.segmentValue / breakdown.currentAverageWorkDayLength) ?? 0,
                })
              : convertMinutesToClockHours(segment.segmentValue, polyglot);

          return (
            <ChipLine
              key={index}
              showYearPercent={absencePolicy.proratingStartDate || absencePolicy.proratingFte}
              showTimesAvgDayInHours={showAvgWorkDay}
              showFtePercent={absencePolicy.proratingFte}
              allowance={policyAllowance}
              yearPercent={`${yearPercent}%`}
              ftePercent={`${round2Digits(segment.fteByWorkingDaysInWeek * 100)}%`}
              avgHoursPerDay={convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot)}
              result={segmentValue}
            />
          );
        })}

        <Divider />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Typography variant="title4">{proratedBasicAllowance}</Typography>
        </Box>
      </Box>
    );
  }

  // If no prorating - drawer not shown
  return null;
};

interface DetailsSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

export const DetailsSection = ({ absencePolicy, userBalanceDetailedStats }: DetailsSectionProps) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  const proratingRule = getAllowanceProratingRuleLongDesc(absencePolicy, polyglot);

  const policyAllowance = getAnnualBalanceFromBreakdown(absencePolicy, breakdown, polyglot);
  const isHourly = isHourlyPolicy(absencePolicy);
  const isLimited = !isUnlimitedPolicy(absencePolicy);

  if (!breakdown) return null;

  const tenureValue = breakdown.tenure.tenureValueInUnits;

  return (
    <Box sx={{ mt: spacing.m20 }}>
      <Typography variant="title3">{polyglot.t('AllowanceDrawer.details')}</Typography>

      {/* TODO: @polyglot-later */}
      <Typography variant="caption" sx={{ mb: spacing.m10 }}>
        Policy allowance for the whole year is <Chip color={themeColors.FreshGreen} value={policyAllowance} />
      </Typography>

      {isLimited && tenureValue > 0 && (
        <Typography variant="caption" sx={{ mb: spacing.m10 }}>
          This includes tenure-based adjustment of +{tenureValue} {isHourly ? 'hour' : 'day'}
          {tenureValue === 1 ? '' : 's'}.
        </Typography>
      )}

      <Typography variant="caption" sx={{ mb: spacing.m10 }}>
        {polyglot.t('AllowanceDrawer.companyCalendarRunsFromTo', {
          startDate: isoDateToLocale(breakdown.currentCycle[0]),
          endDate: isoDateToLocale(breakdown.currentCycle[1]),
        })}
      </Typography>

      <Typography variant="caption" sx={{ mb: spacing.m20 }}>
        {proratingRule}
      </Typography>

      <DetailsSegments absencePolicy={absencePolicy} userBalanceDetailedStats={userBalanceDetailedStats} />
    </Box>
  );
};

export const DetailsSegments = ({ absencePolicy, userBalanceDetailedStats }: DetailsSectionProps) => {
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy(absencePolicy);
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  if (!breakdown) return null;

  const showAvgWorkDay = !isHourly && !breakdown.isOnRegularSchedule;

  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        {breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
          const segmentStart = isoDateToLocale(segment.segmentStart);
          const segmentEnd = isoDateToLocale(segment.segmentEnd);

          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="title4" sx={{ mb: spacing.m5 }}>
                {polyglot.t('AllowanceDrawer.contractNo', { contractNo: index + 1 })}
              </Typography>
              {/* TODO: @polyglot-later */}
              <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                This contract is effective from {segmentStart} to {segmentEnd}, lasting {segment.daysInSegment} days. (
                {segment.daysInSegment}/{segment.daysInCycle} ={' '}
                <span>
                  <Chip
                    color={themeColors.ZeltYellow}
                    value={`${round2Digits((segment.daysInSegment / segment.daysInCycle) * 100)}%`}
                    //
                  />
                </span>
                )
              </Typography>

              {/* TODO: @polyglot-later */}
              {absencePolicy.proratingFte && (
                <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                  Your work pattern is {segment.userNoOfWorkingDays} days out of {segment.fteEquivalentNoOfWorkingDays}{' '}
                  days per week. Therefore your prorating percentage is{' '}
                  {<Chip color={themeColors.Violet} value={`${round2Digits(segment.fteByWorkingDaysInWeek * 100)}%`} />}
                </Typography>
              )}

              {/* TODO: @polyglot-later */}
              {showAvgWorkDay && (
                <Typography variant="caption" sx={{ mb: spacing.m10 }}>
                  Average workday length is{' '}
                  <Chip
                    color={themeColors.Orange}
                    value={convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot)}
                  />
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    );
  }

  // NO PRORATING OF ANY TYPE
  return null;
};
