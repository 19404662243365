import { useState } from 'react';

import { Box } from '@mui/material';
import { RichTextField } from '@v2/components/forms/rich-text/rich-text-field.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto } from '@v2/feature/device/device.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface EnrolledDeviceEditDetailsDrawerContentProps {
  readonly device: DeviceDto;
  readonly refresh: () => Promise<void>;
}
interface EnrolledDeviceEditDetailsForm {
  deviceName: string | null;
  customerNotes: string | null;
}
export const EnrolledDeviceEditDetailsDrawerContent = ({
  device,
  refresh,
}: EnrolledDeviceEditDetailsDrawerContentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const initialValues: EnrolledDeviceEditDetailsForm = {
    deviceName: device.deviceName,
    customerNotes: device.customerNotes,
  };

  const validationSchema = yup.object({
    deviceName: yup.string().nullable().notRequired(),
    customerNotes: yup.string().nullable().notRequired(),
  });

  const updateDeviceTechSpecs = async (deviceUpdate: EnrolledDeviceEditDetailsForm) => {
    try {
      setLoading(true);
      const update: EnrolledDeviceEditDetailsForm = {
        deviceName: deviceUpdate.deviceName,
        customerNotes: deviceUpdate.customerNotes,
      };
      await DeviceAPI.updateEnrolledDeviceDetailsForm(device.id, update);
      showMessage('Device details successfully updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<EnrolledDeviceEditDetailsForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values: EnrolledDeviceEditDetailsForm) => {
      await updateDeviceTechSpecs(values);
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Edit</Typography>

        <TextfieldComponent
          label="Device Name"
          name="deviceName"
          value={formik.values.deviceName}
          onChange={formik.handleChange}
          error={formik.touched.deviceName && Boolean(formik.errors.deviceName)}
          helperText={formik.touched.deviceName && formik.errors.deviceName}
          fullWidth
          size="small"
          endAdornment="none"
        />
        <RichTextField
          label={'Notes'}
          value={formik.values.customerNotes}
          onChange={(value: string) => {
            if (value === '<p></p>') {
              formik.setFieldValue('customerNotes', null, true);
            } else {
              formik.setFieldValue('customerNotes', value, true);
            }
          }}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name="Save" loading={loading} fullWidth colorVariant="primary" sizeVariant="medium" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
