import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { GridDisplayBarV2 } from '@v2/feature/growth/shared/components/grid-display-bar.component';
import { SurveyQuestionGridLine } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-question-grid-line.component';
import { SurveyQuestionDrawer } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-question-drawer.component';
import {
  SurveyResultByQuestion,
  SurveyResultImpact,
} from '@v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { Dictionary } from 'lodash';

interface SurveyResultsByQuestionProps {
  readonly resultByQuestion: SurveyResultByQuestion[];
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly impactResult: SurveyResultImpact | undefined;
  readonly measureByFilter: 'nps' | 'positive' | 'avg';
  readonly impactFilter: string;
  readonly emptyStateMessage?: string;
}

export const SurveyResultsByQuestion = ({
  resultByQuestion,
  impactResult,
  growthFactors,
  measureByFilter,
  impactFilter,
  emptyStateMessage = 'No questions available.',
}: SurveyResultsByQuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<SurveyResultByQuestion | null>(null);

  const showImpact = useMemo(() => {
    return Boolean(impactResult && Object.keys(impactResult).length > 0);
  }, [impactResult]);

  const gridsXs = useMemo(() => {
    if (!showImpact) return [6, 4];
    return [6, 4, 2];
  }, [showImpact]);

  return (
    <Box>
      <GridDisplayBarV2
        key="header-by-question"
        sx={{ mt: '16px' }}
        cells={[
          {
            content: (
              <Typography variant="caption" color="Grey">
                Question
              </Typography>
            ),
            gridXs: gridsXs[0],
          },
          {
            content: (
              <Typography variant="caption" color="Grey">
                Score
              </Typography>
            ),
            gridXs: gridsXs[1],
          },
          ...(showImpact
            ? [
                {
                  content: (
                    <Typography variant="caption" color="Grey">
                      Impact
                    </Typography>
                  ),
                  gridXs: gridsXs[2],
                },
              ]
            : []),
        ]}
      />

      {resultByQuestion.length > 0 ? (
        resultByQuestion.map((item) => {
          return (
            <SurveyQuestionGridLine
              key={item.question.id}
              item={item}
              growthFactors={growthFactors}
              impactResult={impactResult}
              impactFilter={impactFilter}
              measureByFilter={measureByFilter}
              gridsXs={gridsXs}
              setIsOpen={setIsOpen}
              setSelectedQuestion={setSelectedQuestion}
            />
          );
        })
      ) : (
        <EmptyState emptyStateMessage={emptyStateMessage} />
      )}

      <SurveyQuestionDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setSelectedQuestion(null);
        }}
        growthFactors={growthFactors}
        questionItem={selectedQuestion}
        impactResult={impactResult}
        impactFilter={impactFilter}
        measureByFilter={measureByFilter}
        gridsXs={gridsXs}
      />
    </Box>
  );
};

const EmptyState = ({ emptyStateMessage }: { emptyStateMessage: string }) => {
  return (
    <GridDisplayBarV2
      key="empty-state"
      cells={[
        {
          content: (
            <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
              <Typography variant="caption" color="Grey">
                {emptyStateMessage}
              </Typography>
            </Box>
          ),
          gridXs: 12,
        },
      ]}
    />
  );
};
