import { Box, Stack } from '@mui/material';
import { ChipComponent } from '@v2/components/chip/chip.component';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { borders } from '@v2/styles/borders.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';

export enum ContentItemType {
  text = 'text',
  chip = 'chip',
  divider = 'divider',
}

export type ContentItemText = {
  readonly name: string;
  readonly type: ContentItemType.text;
  readonly textColor: keyof typeof themeColors;
  readonly textVariant: TypographyVariant;
};

export type ContentItemDivider = {
  readonly type: ContentItemType.divider;
  readonly textColor: keyof typeof themeColors;
};

export type ContentItem = ContentItemChip | ContentItemText | ContentItemDivider;

export type ContentItemChip = {
  readonly name: string;
  readonly type: ContentItemType.chip;
  readonly textColor: keyof typeof themeColors;
  readonly backgroundColor: keyof typeof themeColors;
  readonly borderColor: keyof typeof themeColors;
};

interface ComponentProps {
  readonly title: string;
  readonly description?: string | null;
  readonly onClick?: () => void;
  readonly contentItemsSets: ContentItem[][];
  readonly disableHoverState?: boolean;
  readonly hoverBgColor?: keyof typeof themeColors;
  readonly disabled?: boolean;
}

export const ItemCard = ({
  title,
  description,
  onClick,
  contentItemsSets,
  disabled = false,
  disableHoverState = false,
  hoverBgColor = 'Background',
}: ComponentProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Stack
      gap="8px"
      sx={{
        borderRadius: radius.br10,
        border: borders.background,
        padding: '16px',
        cursor: onClick && !disableHoverState && !disabled ? 'pointer' : 'default',
        ':hover': disableHoverState || disabled ? undefined : { bgcolor: themeColors[hoverBgColor] },
        opacity: disabled ? 0.5 : 1,
        bgcolor: disabled ? themeColors.GreyHover : undefined,
      }}
      onClick={disabled ? undefined : onClick}
    >
      <Typography variant="title4">{title}</Typography>

      <Typography variant="caption" sx={description ? {} : { fontStyle: 'italic', color: themeColors.Grey }}>
        {description ?? polyglot.t('ItemCard.noDescription')}
      </Typography>

      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', flexWrap: 'wrap' }}>
        {contentItemsSets[0].map((item, index) => {
          switch (item.type) {
            case ContentItemType.chip: {
              return (
                <ChipComponent
                  key={`chip-${index}`}
                  name={item.name}
                  textColor={item.textColor}
                  backgroundColor={item.backgroundColor}
                  border="background"
                />
              );
            }
            case ContentItemType.text: {
              return (
                <Typography key={`text-${index}`} color={item.textColor} variant={item.textVariant}>
                  {item.name}
                </Typography>
              );
            }
            case ContentItemType.divider:
              return (
                <Typography color={item.textColor} variant="caption">
                  |
                </Typography>
              );
            default:
              return null;
          }
        })}
      </Box>
    </Stack>
  );
};
