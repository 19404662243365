import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface TodoCardProps {
  readonly icon: JSX.Element;
  readonly title: string;
  readonly countTitle: string;
  readonly actionOnclick?: () => void;
}
export const TodoCardVersionSmall = ({ icon, title, actionOnclick, countTitle }: TodoCardProps) => {
  return (
    <Box
      sx={{
        paddingX: spacing.p5,
        paddingY: spacing.p3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
      }}
      onClick={actionOnclick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
          <Box>{icon}</Box>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            {title}
          </Typography>
        </Box>

        <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
          {countTitle}
        </Typography>
      </Box>
    </Box>
  );
};
