import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  INVOICES_COMPANY_NEW_INVOICE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  INVOICES_TEAM_NEW_INVOICE_ROUTE,
  USER_INVOICES_NEW_INVOICE_ROUTE,
} from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ContractorInvoiceTable } from '@/v2/feature/payments/pages/components/contractor-invoice-table.component';
import { ContractorInvoice } from '@/v2/feature/payments/payments.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export function InvoicesPage({
  reach = 'company',
  userId,
}: {
  reach?: 'me' | 'team' | 'company';
  userId?: number;
}): React.JSX.Element {
  const { polyglot } = usePolyglot();
  const [loadingInvoices, setIsLoadingInvoices] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<ContractorInvoice[]>([]);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const [state] = useContext(GlobalContext);

  const fetchInvoices = useCallback(async () => {
    try {
      setIsLoadingInvoices(true);
      const apiInvoices = userId
        ? await ContractorInvoiceAPI.getSpecificUserInvoices(userId)
        : reach === 'company'
        ? await ContractorInvoiceAPI.getCompanyInvoices()
        : reach === 'team'
        ? await ContractorInvoiceAPI.getTeamInvoices()
        : await ContractorInvoiceAPI.getSpecificUserInvoices(state.user.userId);
      setInvoices(apiInvoices);
    } catch (error) {
      showMessage(`Failed to fetch invoices. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoadingInvoices(false);
    }
  }, [reach, showMessage, state.user.userId, userId]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices, reach]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('getSidebarConfig.invoices')}
          </Typography>
        }
        showAction={true}
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() =>
              userId
                ? routerHistory.push(generatePath(USER_INVOICES_NEW_INVOICE_ROUTE, { userId }))
                : reach === 'me'
                ? routerHistory.push(INVOICES_ME_NEW_INVOICE_ROUTE)
                : reach === 'team'
                ? routerHistory.push(INVOICES_TEAM_NEW_INVOICE_ROUTE)
                : reach === 'company'
                ? routerHistory.push(INVOICES_COMPANY_NEW_INVOICE_ROUTE)
                : routerHistory.push(INVOICES_COMPANY_NEW_INVOICE_ROUTE)
            }
          >
            {polyglot.t('PaymentListActions.newInvoice')}
          </ButtonComponent>
        }
      />

      <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
        <Box>
          <ContractorInvoiceTable
            invoices={invoices}
            onActionPerformed={fetchInvoices}
            reach={reach}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
            loadingInvoices={loadingInvoices}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
}
