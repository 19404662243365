import React, { useCallback } from 'react';

import { Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { themeColors } from '@v2/styles/colors.styles';

import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency, FormatCurrencyOptions, isZero } from '@/v2/util/currency-format.util';

export const CurrencyWithDiff = ({
  currentValue,
  previousValue,
  dimIfZero,
  showZeroDiff,
  formatOptions,
  currency,
}: {
  currentValue?: number;
  previousValue?: number;
  dimIfZero?: boolean;
  showZeroDiff?: boolean;
  formatOptions?: FormatCurrencyOptions;
  currency?: CurrencyShort;
}) => {
  const { polyglot } = usePolyglot();
  const diffFromPrevious = (currentValue ?? 0) - (previousValue ?? 0);

  const currentValueSpan = useCallback(
    (dim?: boolean) => (
      <span style={{ opacity: dim ? 0.3 : undefined }}>{formatCurrency(currentValue, formatOptions, currency)}</span>
    ),
    [currentValue, formatOptions, currency]
  );

  const getDiffColor = useCallback((diff: number): keyof typeof themeColors | undefined => {
    if (diff && Math.abs(diff) < 1) {
      return 'Grey'; // small diffs are shown in grey
    }
    if (diff > 0) return 'green';
    if (diff < 0) return 'red';
    return undefined; // use default color
  }, []);

  if (typeof previousValue === 'undefined') {
    return currentValueSpan(dimIfZero && isZero(currentValue));
  }

  if (isZero(diffFromPrevious) && !showZeroDiff) {
    return currentValueSpan(dimIfZero && isZero(currentValue));
  }

  return (
    <Stack sx={{ flexFlow: 'row', gap: spacing.g5, alignItems: 'center' }}>
      {currentValueSpan(false)}
      <Typography
        variant="captionSmall"
        color={getDiffColor(diffFromPrevious)}
        title={polyglot.t('PayrunTable.previousValue', { value: formatCurrency(previousValue, formatOptions) })}
      >
        {diffFromPrevious > 0 ? '+' : ''}
        {diffFromPrevious.toFixed(2).replace('.00', '')}
      </Typography>
    </Stack>
  );
};

type ValueWithDiffProps<T> = {
  current: T;
  previous?: T;
  getValue: (item: T) => number | undefined;
  dimIfZero?: boolean;
  showZeroDiff?: boolean;
  currency?: CurrencyShort;
};

export const ValueWithDiff = <T,>({
  current,
  previous,
  showZeroDiff,
  dimIfZero,
  getValue,
  currency,
}: ValueWithDiffProps<T>): React.JSX.Element => {
  return (
    <CurrencyWithDiff
      currentValue={getValue(current)}
      previousValue={previous && getValue(previous)}
      showZeroDiff={showZeroDiff}
      dimIfZero={dimIfZero}
      currency={currency}
    />
  );
};
