import { useCallback, useEffect, useMemo, useState } from 'react';

import { Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { primarySmallBtn } from '@v2/styles/buttons.styles';

import { BillingDiscountAPI } from '@/api-client/billing-discount.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Discount } from '@/models/discount.model';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SuperAdminBillingDiscountAddForm } from '@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-discount-add-form.component';
import { SuperAdminBillingDiscountEditForm } from '@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-discount-edit-form.component';
import { SuperAdminBillingDiscountTableHelper } from '@/v2/feature/super-admin/features/super-admin-billing/helper/super-admin-billing-discounts-table-helper';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly pageConfig: PageConfig;
}

export function SuperAdminBillingDiscountsPage({ pageConfig }: Props) {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState<boolean>(false);
  const [selectedDiscountToEdit, setDiscountToEdit] = useState<Discount>();
  const [showMessage] = useMessage();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchDiscounts = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string }) => {
      try {
        setIsLoadingDiscounts(true);
        const { discounts, pagination: paginationInfo } = await BillingDiscountAPI.getAllDiscounts(params);
        setDiscounts(discounts || []);
        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingDiscounts(false);
      } catch (error) {
        showMessage(`Something went wrong fetching discounts. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const refresh = async () => {
    try {
      await fetchDiscounts();
      setIsEditModalOpen(false);
      setIsAddModalOpen(false);
    } catch (error) {
      showMessage(`Failed to refresh. ${nestErrorMessage(error)}`, 'error');
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDiscounts({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
      });
    })();
  }, [pageIndex, searchQuery, fetchDiscounts]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handleEditButtonClick = (selectedDiscount: Discount) => {
    setDiscountToEdit(selectedDiscount);
    setIsEditModalOpen(true);
  };

  const discountTableColumns = useMemo<ColumnDef<Discount, unknown>[]>(
    () => [
      SuperAdminBillingDiscountTableHelper.getDiscountNameColumn({
        header: 'Discount Name',
        id: 'discountName',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountIdColumn({
        header: 'Discount Id',
        id: 'discountId',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountTypeColumn({
        header: 'Discount Type',
        id: 'discountType',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountAmountColumn({
        header: 'Discount Amount',
        id: 'discountAmount',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountStartDateColumn({
        header: 'Start Date',
        id: 'discountStartDate',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountEndDateColumn({
        header: 'End Date',
        id: 'discountEndDate',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountCreatedAtColumn({
        header: 'Created At',
        id: 'discountCreatedAt',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountUpdatedAtColumn({
        header: 'Updated At',
        id: 'discountUpdated',
        size: 145,
      }),

      SuperAdminBillingDiscountTableHelper.getDiscountUpdatedByColumn({
        header: 'Updated By',
        id: 'discountUpdatedBy',
        size: 145,
      }),

      {
        header: () => 'Action',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.id ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                key={original.id}
                onClick={() => {
                  handleEditButtonClick(original);
                }}
                sx={{
                  minWidth: 10,
                  padding: 1,
                  maxWidth: 40,
                  borderRadius: 1,
                  backgroundColor: themeColors.lightGrey,
                  color: themeColors.DarkGrey,
                }}
              >
                <Edit sx={{ height: 20, width: '20px' }} />
              </Button>
            </Box>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          ),
        size: 220,
      },
    ],
    []
  );

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Billing</Typography>}
        actions={
          <Button sx={primarySmallBtn} onClick={() => setIsAddModalOpen(true)}>
            Add discount
          </Button>
        }
        showAction={true}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={isLoadingDiscounts} secondLevel>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              ...spacing.mb20,
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <TableSearch
              style={{ width: '16em' }}
              query={searchQuery}
              handleChange={(e) => {
                setSearchQuery(e.target.value?.trim() ?? '');
                // Resets pagination index once we perform a new search
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
              placeholder="Enter discount name..."
            />
          </Box>

          {!isLoadingDiscounts && (
            <>
              <BasicServerTable
                rowData={[...discounts]}
                columnData={discountTableColumns}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                setPagination={setPagination}
                totalPages={totalPages}
                totalItems={totalItems}
              />

              {isAddModalOpen && (
                <DrawerModal isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen}>
                  <SuperAdminBillingDiscountAddForm onSave={refresh}></SuperAdminBillingDiscountAddForm>
                </DrawerModal>
              )}

              {isEditModalOpen && (
                <DrawerModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen}>
                  <SuperAdminBillingDiscountEditForm
                    discount={selectedDiscountToEdit}
                    onSave={refresh}
                  ></SuperAdminBillingDiscountEditForm>
                </DrawerModal>
              )}
            </>
          )}
        </>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
}
