import { useMemo, useState } from 'react';

import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { CustomProfileFormDto, UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import {
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { CustomSectionForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component';
import { MissingField } from '@/v2/feature/user/user.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SCOPE = 'user.about' as const;
const iconSize = { width: 14, height: 14 } as const;
const skeletonHeight = '20px';

interface Props {
  readonly userId: number;
  readonly form: CustomProfileFormDto;
  readonly fields: UserCustomDataDto[];
  readonly onSubmit: (values: UserCustomDataDto[]) => void;
  readonly missingFields?: MissingField[] | null;
  sx?: SxProps;
}

export const CustomSectionCard = ({ userId, form, fields, onSubmit, missingFields, sx }: Props): JSX.Element => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [isOpen, setIsOpen] = useState(false);

  // if there's no fields defined, hide the section completely
  if (fields.length === 0) return <></>;

  const isEmpty = fields.every((f) => !f.value);

  return pipe(
    O.some(fields),
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      () => (
        <ProfileCard customUpdates={fields} fieldStubs={[]} sx={{ ...cardSx, ...sx }}>
          <Box component="header" sx={cardHeaderSx}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <Typography variant="h3" sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                {form.formName}
              </Typography>

              <ScopesControl scopes={['user.about:read']} context={scopesContext}>
                {missingFields?.find((rec) => rec.cardId === form.formId) && (
                  <Box sx={hazardMissingField}>
                    <Hazard {...hazardSize} />
                  </Box>
                )}
              </ScopesControl>
            </Box>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton onClick={() => setIsOpen(true)} title="Edit" sx={tableIconButtonSx}>
                {isEmpty ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
              </IconButton>
            </ScopesControl>
          </Box>
          <Box component="section">
            {isEmpty ? (
              <MissingInformationLabel />
            ) : (
              <Box component="dl" sx={definitionListSx}>
                {sortCustomFields(fields).map((f) => (
                  <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                ))}
              </Box>
            )}
          </Box>
          <ScopesControl scopes={[SCOPE]} context={scopesContext}>
            <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
              <CustomSectionForm
                userId={userId}
                form={form}
                fields={fields}
                onSubmit={(values) => {
                  setIsOpen(false);
                  onSubmit(values);
                }}
                onClose={() => setIsOpen(false)}
              />
            </DrawerModal>
          </ScopesControl>
        </ProfileCard>
      )
    )
  );
};

type CustomSectionsProps = {
  readonly userId: number;
  readonly formTab: ProfileTab;
  readonly onSubmit: (values: UserCustomDataDto[]) => void;
  readonly missingFields?: MissingField[] | null;
  sx?: SxProps;
};

export const CustomSections = ({ userId, formTab, onSubmit, missingFields, sx }: CustomSectionsProps): JSX.Element => {
  const { data: forms, mutate: refreshForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false,
  });
  const { data: formValues, mutate: refreshValues } = useApiClient(CustomProfileFormEndpoints.getFormValues(userId), {
    suspense: false,
  });

  const customSections = useMemo(
    () =>
      forms
        ?.filter((f) => f.formTab === formTab && !f.isDefault)
        .sort((a, b) => a.formName.localeCompare(b.formName, undefined, { sensitivity: 'base' })),
    [formTab, forms]
  );

  return (
    <>
      {customSections?.map((form) => (
        <CustomSectionCard
          key={form.formId}
          userId={userId}
          form={form}
          fields={formValues?.filter((f) => f.formId === form.formId) ?? []}
          onSubmit={(values) => {
            refreshForms?.();
            refreshValues?.(values);
            onSubmit(values);
          }}
          missingFields={missingFields}
          sx={sx}
        />
      ))}
    </>
  );
};
