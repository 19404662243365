import axios from 'axios';

import {
  DeviceImportDto,
  DevicesImportResultDto,
} from '@/v2/feature/device/features/devices-import/devices-import.dto';

export class DeviceImportAPI {
  static async importViaCsv(devices: readonly DeviceImportDto[]): Promise<DevicesImportResultDto> {
    return (await axios.post('/apiv2/import-entity/devices/csv-v2', devices)).data;
  }
}
