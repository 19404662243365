import { useState } from 'react';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ParticipantsAddRevieweeModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-add-reviewee-modal.component';
import { ParticipantsEmptyState } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-empty-state.component';
import { ParticipantsSharedTable } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/shared/participants-shared-table.component';
import {
  ReachType,
  ReviewCycle,
  ReviewParticipants,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { spacing } from '@/v2/styles/spacing.styles';

interface ICycleCreationScheduledView {
  readonly reviewCycle: ReviewCycle;
  readonly isEditable: boolean;
  readonly refresh: () => Promise<void>;
  readonly reviewParticipants: ReviewParticipants[] | null | undefined;
  readonly reviewParticipantsLoading: boolean | undefined;
  readonly reach: ReachType;
}
export const ParticipantsScheduledView = ({
  reviewCycle,
  isEditable,
  refresh,
  reviewParticipants,
  reviewParticipantsLoading,
  reach,
}: ICycleCreationScheduledView) => {
  const [isRevieweeAddOpen, setIsRevieweeAddOpen] = useState<boolean>(false);

  return (
    <>
      {reviewCycle && reviewCycle.revieweeId && reviewCycle.revieweeId?.length > 0 ? (
        <>
          <SettingsSubsectionContent
            sections={[
              {
                contentWidth: '100%',
                headerWidth: '100%',
                title: 'Participants',
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: 'Reviewer select',
                    value: reviewCycle?.reviewerSelect ? reviewCycle.reviewerSelect.join(', ') : 'NA',
                  },
                  {
                    type: SectionItemType.Pair,
                    label: 'Participants',
                    value: reviewCycle?.revieweeId
                      ? `${reviewCycle?.revieweeId.length} - ${
                          reviewCycle?.revieweeId.length > 1 ? 'participants' : 'participant'
                        }`
                      : 'No participant was selected',
                  },
                  {
                    type: SectionItemType.Component,
                    value: isEditable ? (
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="primary"
                        onClick={() => setIsRevieweeAddOpen(true)}
                        style={{ marginTop: spacing.m16 }}
                      >
                        Add participants
                      </ButtonComponent>
                    ) : null,
                  },
                ],
              },
            ]}
          />

          <SettingsSubsectionContent
            sections={[
              {
                contentWidth: '100%',
                headerWidth: '100%',
                title:
                  reviewCycle?.state === CycleState.Completed || reviewCycle?.state === CycleState.Ongoing
                    ? 'Participants part of this cycle'
                    : 'Participants who will be invited',
                items: [
                  {
                    type: SectionItemType.Component,
                    value: (
                      <ParticipantsSharedTable
                        reviewCycle={reviewCycle}
                        reviewParticipants={reviewParticipants}
                        reviewParticipantsLoading={reviewParticipantsLoading}
                        refresh={refresh}
                        isEditable={isEditable}
                      />
                    ),
                  },
                ],
              },
            ]}
          />
        </>
      ) : (
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              headerWidth: '100%',
              title: 'Participants',
              items: [
                {
                  type: SectionItemType.Component,
                  value: <ParticipantsEmptyState setIsOpen={setIsRevieweeAddOpen} />,
                },
              ],
            },
          ]}
        />
      )}

      <ParticipantsAddRevieweeModal
        reviewCycle={reviewCycle}
        isOpen={isRevieweeAddOpen}
        setIsOpen={setIsRevieweeAddOpen}
        onClose={() => setIsRevieweeAddOpen(false)}
        refresh={refresh}
        reach={reach}
      />
    </>
  );
};
