import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { spacing } from '@/v2/styles/spacing.styles';

export const MondayApiKeyInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        a) Log into your monday.com account.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        b) Click on your avatar (picture icon) in the bottom left corner.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        c) Select Admin from the resulting menu (this requires you to have admin permissions).
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        d) Go to the API section.
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        e) Generate a “<b>API v2 Token</b>”
      </Typography>
      <Typography variant="caption" color="Grey" sx={{ mb: spacing.m5 }}>
        e) Copy your token.
      </Typography>
    </Box>
  );
};
