import { useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { NotificationsSettingEditFormDrawer } from './notifications-setting-edit-form-drawer.component';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Cancel.svg';
import { ReactComponent as CheckCircle } from '@/images/side-bar-icons/CheckCircle.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DRAWER_MODES } from '@/v2/feature/company/company-settings/company-settings.interface';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { NotificationsSettingAddFormDrawer } from '@/v2/feature/notification/notification-settings/features/components/notifications-setting-add-form-drawer.component';
import { DItem } from '@/v2/feature/notification/notification-settings/features/notification-setting.page';
import {
  NotificationForTable,
  ProfileNotifications,
  UserNotifications,
} from '@/v2/feature/notification/notification-settings/notification.interface';
import {
  mapProfileNotificationsToNotificationForTable,
  mapUserNotificationsToNotificationForTable,
} from '@/v2/feature/notification/notification.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableSecondaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface NotificationSettingTableProps {
  userNotifications?: UserNotifications;
  profileNotifications?: ProfileNotifications[];
  slackChannels?: readonly DItem[];
  slackConnected: boolean;
  refreshNotificationData: () => void;
  loading: boolean;
}

export const NotificationStatus = ({ status }: { status: boolean }): JSX.Element => {
  const { polyglot } = usePolyglot();

  return status ? (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <CheckCircle {...iconSize} style={{ fill: themeColors.Green }} />
      <Typography sx={{ ...themeFonts.caption, ml: spacing.m10 }}>{polyglot.t('NotificationStatus.on')}</Typography>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Cancel {...iconSize} style={{ fill: themeColors.Grey }} />
      <Typography sx={{ ...themeFonts.caption, ml: spacing.m10, color: themeColors.Grey }}>
        {polyglot.t('NotificationStatus.off')}
      </Typography>
    </Box>
  );
};

const DEFAULT_NEW_NOTIFICATION: NotificationForTable = {
  type: undefined,
  name: undefined,
  systemName: undefined,
  status: false,
  sendTo: '',
  sendToEditValue: {
    type: 'anyof',
    autoApprove: false,
    presets: [],
    userIds: [],
  },
  when: '',
  channel: '',
  emailEnabled: false,
  slackEnabled: false,
};

export const NotificationSettingTable = ({
  userNotifications,
  profileNotifications,
  slackChannels,
  slackConnected,
  refreshNotificationData,
  loading,
}: NotificationSettingTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [tableData, setTableData] = useState<NotificationForTable[]>([]);
  const [notificationToEdit, setNotificationToEdit] = useState<NotificationForTable | undefined>(undefined);
  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState<string>('');
  const [drawerMode, setDrawerMode] = useState<DRAWER_MODES>(DRAWER_MODES.edit);
  const { data: allDateFields } = useApiClient(CustomProfileFormEndpoints.listAllDateFields(), {
    suspense: false,
  });

  useEffect(() => {
    const userNotificationData = userNotifications
      ? mapUserNotificationsToNotificationForTable(polyglot, userNotifications, nonTerminatedCachedUsers, slackChannels)
      : [];
    const profileNotificationData = profileNotifications
      ? mapProfileNotificationsToNotificationForTable(
          polyglot,
          profileNotifications,
          nonTerminatedCachedUsers,
          slackChannels
        )
      : [];

    // Combine both sources into a single array and filter based on search input
    const filteredData = [...userNotificationData, ...profileNotificationData]
      .filter((n) => {
        const s = searchInput.toLowerCase();
        return (
          n.type?.toLowerCase().includes(s) || n.name?.toLowerCase().includes(s) || n.channel?.toLowerCase().includes(s)
        );
      })
      .sort((a, b) => {
        const typeComparison = (a.type ?? '').localeCompare(b.type ?? '');
        if (typeComparison !== 0) {
          return typeComparison;
        }
        return (a.id ?? 0) - (b.id ?? 0);
      });

    // Set the combined data to the tableData state
    setTableData(filteredData);
  }, [userNotifications, profileNotifications, slackChannels, searchInput, nonTerminatedCachedUsers, polyglot]);

  const onEditClick = (notification: NotificationForTable) => {
    setDrawerMode(DRAWER_MODES.edit);
    setNotificationToEdit(notification);
    setEditDrawerOpen(true);
  };

  const onNewNotificationClick = () => {
    setDrawerMode(DRAWER_MODES.add);
    setNotificationToEdit(DEFAULT_NEW_NOTIFICATION);
    setEditDrawerOpen(true);
  };

  const columnData = useMemo<ColumnDef<NotificationForTable, NotificationForTable>[]>(() => {
    return [
      {
        header: () => polyglot.t('NotificationSettingTable.type'),
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type),
        maxSize: 50,
        minSize: 50,
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return <div>{info.getValue().type}</div>;
        },
      },
      {
        header: () => polyglot.t('NotificationSettingTable.notification'),
        accessorFn: (row) => row,
        id: 'notification',
        maxSize: 100,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return <>{info.getValue()?.name}</>;
        },
      },
      {
        header: () => polyglot.t('NotificationSettingTable.status'),
        accessorFn: (row) => row,
        id: 'status',
        maxSize: 60,
        minSize: 60,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.status.toString()),
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return <NotificationStatus status={info.getValue()?.status} />;
        },
      },
      {
        header: () => polyglot.t('NotificationSettingTable.sendTo'),
        accessorFn: (row) => row,
        id: 'sendTo',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.sendTo),
        maxSize: 100,
        minSize: 100,
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return info.getValue()?.status ? <>{truncateWithEllipses(info.getValue()?.sendTo, 18)}</> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('NotificationSettingTable.when'),
        accessorFn: (row) => row,
        id: 'when',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.when),
        maxSize: 70,
        minSize: 70,
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return info.getValue()?.status ? <>{info.getValue()?.when}</> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('NotificationSettingTable.channel'),
        accessorFn: (row) => row,
        id: 'channel',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.channel),
        maxSize: 100,
        minSize: 100,
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return info.getValue()?.status ? <>{truncateWithEllipses(info.getValue()?.channel, 25)}</> : <EmptyCell />;
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        maxSize: 10,
        minSize: 10,
        cell: (info: CellContext<NotificationForTable, NotificationForTable>) => {
          return (
            <>
              <IconButton
                sx={tableSecondaryIconButtonSx}
                onClick={(e) => {
                  onEditClick(info.getValue());
                  e.stopPropagation();
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            </>
          );
        },
      },
    ];
  }, [polyglot]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ width: '250px', minWidth: '250px' }}
          />
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => onNewNotificationClick()}>
            {polyglot.t('NotificationSettingsPage.newNotification')}
          </ButtonComponent>
        </Box>
      </Box>
      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable rowData={[...tableData]} columnData={columnData} loading={loading} hidePagination />
      </Box>
      {notificationToEdit && (
        <DrawerModal
          isOpen={editDrawerOpen}
          setIsOpen={setEditDrawerOpen}
          onClose={() => {
            setEditDrawerOpen(false);
          }}
        >
          <>
            {drawerMode === DRAWER_MODES.edit && (
              <NotificationsSettingEditFormDrawer
                notificationToEdit={notificationToEdit}
                setIsOpen={setEditDrawerOpen}
                slackConnected={slackConnected}
                allSlackChannels={slackChannels ?? []}
                refreshNotificationData={refreshNotificationData}
                allDateFields={allDateFields}
              />
            )}
            {drawerMode === DRAWER_MODES.add && (
              <NotificationsSettingAddFormDrawer
                notificationToEdit={notificationToEdit}
                setIsOpen={setEditDrawerOpen}
                slackConnected={slackConnected}
                allSlackChannels={slackChannels ?? []}
                refreshNotificationData={refreshNotificationData}
                allDateFields={allDateFields}
              />
            )}
          </>
        </DrawerModal>
      )}
    </>
  );
};
