import React, { useEffect, useMemo, useState } from 'react';

import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { FilterValueSelectorProps } from '@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component';
import { NotImplemented } from '@v2/feature/reports/reports-advanced/filters-components/not-implemented.component';
import { ReportSQLOperator } from '@v2/feature/reports/reports.interface';

export const StringSelector = ({ operator, value, setValue, label }: Omit<FilterValueSelectorProps, 'filterType'>) => {
  const StringOperatorsWithNoExtraField = useMemo(() => {
    return new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);

  const StringOperatorsWithTextField = useMemo(() => {
    return new Set([ReportSQLOperator.eq, ReportSQLOperator.startsWith, ReportSQLOperator.endsWith]);
  }, []);

  return StringOperatorsWithTextField.has(operator) ? (
    <TextfieldComponent
      value={value}
      name="value"
      label={label}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      endAdornment="none"
    />
  ) : operator === ReportSQLOperator.contains ? (
    <ArrayStringsPicker value={value} setValue={setValue} label={label} />
  ) : StringOperatorsWithNoExtraField.has(operator) ? null : (
    <NotImplemented />
  );
};

const ArrayStringsPicker = ({
  value,
  setValue,
  label,
}: Pick<FilterValueSelectorProps, 'value' | 'setValue' | 'label'>) => {
  const [localValue, setLocalValue] = useState<string>(Array.isArray(value) ? value.join(',') : '');

  useEffect(() => {
    const newValue = localValue
      .split(',')
      .filter(Boolean)
      .map((n) => n.trim());
    setValue(newValue);
  }, [localValue, setValue]);

  return (
    <TextfieldComponent
      value={localValue}
      name="value"
      label={label}
      onChange={(e) => {
        setLocalValue(e.target.value);
      }}
      endAdornment="none"
      helperText="eg.: text1,text2,text3"
    />
  );
};
