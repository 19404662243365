import { useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { DashboardAPI } from '@/v2/feature/dashboard/dashboard.api';
import { getDateFormation, getDayMonthFormation } from '@/v2/feature/dashboard/dashboard.util';
import { GifSelector } from '@/v2/feature/dashboard/features/sections/user-calendar/components/gifs-search.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { EventProps } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.interface';
import { UserNotificationType } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserEventDetailDrawer = ({
  selectedEvent,
  isOpen,
  setSelectedEvent,
  setIsOpen,
}: {
  selectedEvent: EventProps | undefined;
  isOpen: boolean;
  readonly setSelectedEvent: React.Dispatch<React.SetStateAction<EventProps | undefined>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [noteString, setNoteString] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedGIF, setSelectedGIF] = useState<string | null>(null);

  const handleButtonClick = async () => {
    try {
      setLoading(true);
      if (!noteString) setError(true);

      if (selectedEvent && noteString) {
        const body = {
          userId: selectedEvent?.userId,
          type: selectedEvent?.type as UserNotificationType,
          note: noteString,
          gifSrc: selectedGIF,
        };
        await DashboardAPI.sendWishes(body);
        setIsOpen(false);
        showMessage(polyglot.t('UserEventDetailDrawer.successMessages.send'), 'success');
      }
    } catch (error) {
      showMessage(
        polyglot.t('UserEventDetailDrawer.errorMessages.send', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const differenceInCalendarYears = (date1: Date, date2: Date) => {
    return date1.getFullYear() - date2.getFullYear();
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      afterClose={() => {
        setSelectedEvent(undefined);
        setNoteString(null);
        setSelectedGIF(null);
      }}
    >
      <Box sx={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          <Typography variant="title2">{selectedEvent?.type}</Typography>
          <ViewItem
            label={polyglot.t('UserEventDetailDrawer.employee')}
            value={
              selectedEvent?.userId ? (
                <UserCell
                  avatarSize="xxxsmall"
                  userId={selectedEvent?.userId}
                  sx={{ gap: spacing.g5 }}
                  nameSx={{ ...themeFonts.title4 }}
                />
              ) : (
                '-'
              )
            }
          />

          {selectedEvent && (
            <ViewItem
              label={polyglot.t('UserEventDetailDrawer.date')}
              value={
                selectedEvent?.type?.toLowerCase() === 'birthday'
                  ? getDayMonthFormation(selectedEvent.startDate)
                  : getDateFormation(selectedEvent.startDate, selectedEvent.endDate) ?? '-'
              }
            />
          )}

          {selectedEvent?.type?.toLowerCase()?.includes('anniversary') && (
            <ViewItem
              label={polyglot.t('UserEventDetailDrawer.lengthService')}
              value={
                `${differenceInCalendarYears(
                  new LocalDate().getDate(),
                  new LocalDate(selectedEvent.startDate).getDate()
                )} ${
                  differenceInCalendarYears(
                    new LocalDate().getDate(),
                    new LocalDate(selectedEvent.startDate).getDate()
                  ) > 1
                    ? 'years'
                    : 'year'
                }` ?? '-'
              }
            />
          )}
        </Box>

        <Typography variant="title2">{polyglot.t('UserEventDetailDrawer.sendANote')}</Typography>

        {selectedGIF ? (
          <div style={{ position: 'relative' }}>
            <button
              className="event-short-button"
              style={{
                top: -5,
                position: 'absolute',
                right: -10,
                background: themeColors.white,
                fill: themeColors.DarkGrey,
                zIndex: 2,
              }}
              onClick={() => setSelectedGIF(null)}
            >
              <TrashIcon />
            </button>
            <img key={'1234'} src={selectedGIF} alt="GIF" width="360px" height="320px" />
          </div>
        ) : (
          <GifSelector type={selectedEvent?.type ?? 'Congratulations'} setSelectedGIF={setSelectedGIF} />
        )}

        <FormControl error={error} fullWidth>
          <TextfieldComponent
            multiline
            name="note"
            label={polyglot.t('UserEventDetailDrawer.note')}
            value={noteString}
            type="text"
            onChange={(e) => {
              setNoteString(e.target.value);
              setError(false);
            }}
            endAdornment="none"
          />

          {error && (
            <Typography variant="caption" color="darkRed" sx={{ marginTop: spacing.m10 }}>
              {polyglot.t('UserEventDetailDrawer.pleaseAddAnote')}
            </Typography>
          )}
        </FormControl>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            loading={loading}
            colorVariant="secondary"
            sizeVariant="large"
            fullWidth
            onClick={handleButtonClick}
            name={polyglot.t('UserEventDetailDrawer.sendCongrats')}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};

const ViewItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g4 }}>
      <Typography variant="captionSmall" color="Grey">
        {label}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};
