import { useMemo } from 'react';

import { Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Mistake } from '@/images/reports/Mistake.svg';
import { ReactComponent as CheckCircle } from '@/images/side-bar-icons/CheckCircle.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { ZeltDocument } from '@/lib/documents';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { DocumentStatus } from '@/v2/feature/documents/documents.interface';
import { allPartiesHaveSignedContract } from '@/v2/feature/documents/documents.util';
import { ContractObject, SignatoryType } from '@/v2/feature/templates/templates.interface';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { translateDocumentStatus } from '@/v2/infrastructure/i18n/translate.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface Props {
  readonly document: ZeltDocument;
}

const iconSize = { width: 14, height: 14 } as const;

// Function to check if a document's expiryDate is within the next 7 days
export const documentExpiringSoon = (document: ZeltDocument): boolean => {
  const sevenDays = 7 * 24 * 60 * 60 * 1000;
  if (!document?.expirySettings?.expiryDate) return false;
  const expiryDate = new Date(document?.expirySettings?.expiryDate);
  const today = new Date();
  return expiryDate.getTime() - today.getTime() <= sevenDays;
};

// Function to check if a document's expiry date is already in the past
export const documentExpired = (document: ZeltDocument): boolean => {
  if (!document?.expirySettings?.expiryDate) return false;
  const expiryDate = new Date(document?.expirySettings?.expiryDate);
  const today = new Date();
  return expiryDate.getTime() < today.getTime();
};

const getTooltipTextForPartiallySignedContract = (
  document: ZeltDocument,
  companySignatoryUser: CachedUser | undefined,
  recipientUser: CachedUser | undefined
): string => {
  const { contract } = document;
  const signedParties = [];
  const pendingSignatories = [];

  const requiredCompanySignature =
    contract?.signatoriesRequired &&
    contract?.signatoriesRequired?.length > 0 &&
    contract.signatoriesRequired.includes(SignatoryType.additional);

  const requiredRecipientSignature =
    contract?.signatoriesRequired &&
    contract?.signatoriesRequired?.length > 0 &&
    contract.signatoriesRequired.includes(SignatoryType.recipient);

  if (requiredCompanySignature && contract?.companySignatureTimestamp && companySignatoryUser)
    signedParties.push(companySignatoryUser?.displayName);

  if (requiredRecipientSignature && contract?.recipientSignatureTimestamp && recipientUser)
    signedParties.push(recipientUser?.displayName);

  if (requiredCompanySignature && !contract?.companySignatureTimestamp && companySignatoryUser)
    pendingSignatories.push(companySignatoryUser?.displayName);

  if (requiredRecipientSignature && !contract?.recipientSignatureTimestamp && recipientUser)
    pendingSignatories.push(recipientUser?.displayName);

  if (pendingSignatories.length === 0) return '';

  let tooltipText = signedParties.length > 0 ? `Signed by:  ${signedParties.join(', ')}; ` : '';
  tooltipText += pendingSignatories.length > 0 ? `Pending signature: ${pendingSignatories.join(', ')};` : '';

  return tooltipText.replace(/;$/, '').trim();
};

const getSignedStatus = (document: ZeltDocument): string => {
  const { contract } = document;
  const fileUuidFromAttachment = document.attachments && document.attachments[0] && document.attachments[0]?.fileUuid;
  const finalUuid = document.fileUuid ?? fileUuidFromAttachment;
  const contractFinalised = !!(contract?.id && finalUuid);
  const signatories = [];
  const partiesSigned = [];

  if (contract && contract.signatoriesRequired && contract.signatoriesRequired?.length > 0) {
    if (contract.signatoriesRequired.includes(SignatoryType.additional) && contract.companySignatory) {
      signatories.push(contract.companySignatory);
      if (contract.companySignatureTimestamp) {
        partiesSigned.push(contract.companySignatory);
      }
    }

    if (contract.signatoriesRequired.includes(SignatoryType.recipient) && contract.recipient) {
      signatories.push(contract.recipient);
      if (contract.recipientSignatureTimestamp) {
        partiesSigned.push(contract.recipient);
      }
    }
  }

  const noPendingSignatures = partiesSigned.length === signatories.length;

  const missingInformation = contract?.hasMissingPersonalFields || contract?.hasMissingWorkFields;

  return contract && contract.id
    ? noPendingSignatures && (!missingInformation || contractFinalised)
      ? DocumentStatus.Signed
      : noPendingSignatures && missingInformation && !contractFinalised
      ? DocumentStatus.MissingInformation
      : `${partiesSigned.length}/${signatories.length} Signed`
    : DocumentStatus.Uploaded;
};

export const getDocumentStatus = (document: ZeltDocument, contract?: ContractObject) => {
  let status: DocumentStatus = DocumentStatus.Pending;

  const fileUuidFromAttachment = document.attachments && document.attachments[0] && document.attachments[0]?.fileUuid;
  const finalUuid = document.fileUuid ?? fileUuidFromAttachment;
  if (finalUuid) status = DocumentStatus.Uploaded;
  if (document.contractId) {
    if (contract?.companySignatureTimestamp && contract?.recipientSignatureTimestamp) {
      status = DocumentStatus.Signed;
    } else if (
      (!contract?.companySignatureTimestamp && contract?.recipientSignatureTimestamp) ||
      (contract?.companySignatureTimestamp && !contract?.recipientSignatureTimestamp)
    ) {
      status = getSignedStatus(document) as DocumentStatus;
    } else if (!contract?.companySignatureTimestamp && !contract?.recipientSignatureTimestamp) {
      status = getSignedStatus(document) as DocumentStatus;
    }
  }
  if (documentExpiringSoon(document)) status = DocumentStatus.expiringSoon;
  if (documentExpired(document)) status = DocumentStatus.expired;
  return status;
};

export const DisplayDocumentStatus = ({ document }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const { contract } = document;
  const hideTooltip =
    !document.contractId ||
    !!(document.contractId && allPartiesHaveSignedContract(document)) ||
    !!(
      document.contractId &&
      document.contract &&
      document.contract.signatoriesRequired &&
      document.contract.signatoriesRequired.length === 0
    );
  const documentStatus = useMemo(() => {
    return getDocumentStatus(document, contract);
  }, [contract, document]);
  if (documentStatus === DocumentStatus.Pending)
    return (
      <Typography
        sx={{
          ...themeFonts.caption,
          color: themeColors.Grey,
          gap: '5px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Waiting {...iconSize} style={{ fill: themeColors.GreyMiddle }} />{' '}
        {translateDocumentStatus(documentStatus, polyglot)}
      </Typography>
    );
  else
    return (
      <StyledTooltip
        disabled={hideTooltip}
        title={
          (contract?.companySignatory && !contract?.companySignatureTimestamp) ||
          !contract?.recipientSignatureTimestamp ? (
            <Typography sx={{ ...themeFonts.tooltip }}>
              {getTooltipTextForPartiallySignedContract(
                document,
                contract?.companySignatory ? getCachedUserById(contract?.companySignatory) : undefined,
                contract?.recipient ? getCachedUserById(contract?.recipient) : undefined
              )}
            </Typography>
          ) : undefined
        }
      >
        <Typography
          sx={{
            ...themeFonts.caption,
            gap: '5px',
            display: 'flex',
            alignItems: 'center',
            color: [DocumentStatus.expiringSoon, DocumentStatus.expired].includes(documentStatus)
              ? themeColors.Red
              : themeColors.Grey,
          }}
        >
          {documentStatus === DocumentStatus.Uploaded && (
            <CheckCircle {...iconSize} style={{ fill: themeColors.Green }} />
          )}
          {[DocumentStatus.expiringSoon, DocumentStatus.expired].includes(documentStatus) && <Mistake {...iconSize} />}
          {translateDocumentStatus(documentStatus, polyglot)}
        </Typography>
      </StyledTooltip>
    );
};
