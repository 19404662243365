import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const SCParticipantDeleteModal = ({
  surveyCycle,
  toDeleteParticipantIds,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  surveyCycle: SurveyCycle | undefined;
  toDeleteParticipantIds: number[];
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCParticipantDeleteContent
        surveyCycle={surveyCycle}
        onClose={onClose}
        refresh={refresh}
        toDeleteParticipantIds={toDeleteParticipantIds}
      />
    </DrawerModal>
  );
};

const SCParticipantDeleteContent = ({
  surveyCycle,
  onClose,
  refresh,
  toDeleteParticipantIds,
}: {
  surveyCycle: SurveyCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  toDeleteParticipantIds: number[];
}) => {
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteParticipants = async () => {
    try {
      setLoading(true);
      if (surveyCycle) {
        await SurveyCycleAPI.deleteParticipants(surveyCycle.id, toDeleteParticipantIds);
        showMessage('Successfully deleted the participants', 'success');
      }
      await refresh();
      onClose();
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Delete participants</Typography>

      <Typography variant="caption" color="Grey">
        This will permanently delete selected participant surveys. Are you sure?
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          type="button"
          onClick={handleDeleteParticipants}
          sizeVariant="medium"
          colorVariant="primary"
          name="Remove"
          loading={loading}
          fullWidth
        />
      </Box>
    </Box>
  );
};
