import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { TranslationDrawer } from '@v2/feature/company/company-translation/company-translation-drawer.component';
import { TranslationTable } from '@v2/feature/company/company-translation/company-translation-table.component';
import { CompanyTranslationDto } from '@v2/feature/company/company-translation/company-translation.dto';
import { CompanyTranslationsCsvUploadDrawer } from '@v2/feature/company/company-translation/company-translations-csv-upload-drawer.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { CompanyEndpoints } from '@/api-client/company.api';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const CompanyTranslationPage = (): JSX.Element => {
  const { data: translations, mutate: refresh, isLoading } = useApiClient(CompanyEndpoints.getCompanyTranslations(), {
    suspense: false,
  });

  const { polyglot } = usePolyglot();

  const refreshTranslations = useCallback(async () => {
    if (refresh) await refresh();
  }, [refresh]);

  return (
    <ContentWrapper loading={isLoading}>
      <SettingsSectionContent title={polyglot.t('SettingsRouterPageConfig.translations')} contentWidth="100%">
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.TextLine,
                  value:
                    'If your company functions in multiple languages and you would like to translate information entered by yourself and other employees, you can add translations for specific words or phrases here. Note that Zelt always stores what is entered by employees as is, and that translations are only applied afterwards into the language set in the personal account settings.',
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <TranslationsTable translations={translations ?? []} refreshTranslations={refreshTranslations} />
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};

const TranslationsTable = ({
  translations,
  refreshTranslations,
}: {
  translations: CompanyTranslationDto[];
  refreshTranslations: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState<string>('');
  const [isImportOpen, setIsImportOpen] = useState<boolean>(false);
  const [isAddNewOpen, setIsAddNewOpen] = useState<boolean>(false);

  const filteredTranslations = useMemo(() => {
    if (!translations) return [];
    if (!searchInput) return translations;

    const search = searchInput.toLowerCase();
    return translations.filter(
      (w) =>
        w.en?.toLowerCase().startsWith(search) ||
        w.it?.toLowerCase().startsWith(search) ||
        w.ar?.toLowerCase().startsWith(search) ||
        w.es?.toLowerCase().startsWith(search) ||
        w.de?.toLowerCase().startsWith(search) ||
        w.pt?.toLowerCase().startsWith(search) ||
        w.fr?.toLowerCase().startsWith(search)
    );
  }, [translations, searchInput]);
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...spacing.pt20 }}>
        <Box>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: spacing.g10 }}>
          <ButtonComponent
            onClick={() => {
              setIsImportOpen(true);
            }}
            sizeVariant="small"
            colorVariant="secondary"
          >
            {polyglot.t('General.import')}
          </ButtonComponent>

          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => {
              setIsAddNewOpen(true);
            }}
          >
            {polyglot.t('General.add')}
          </ButtonComponent>
        </Box>
      </Box>

      <TranslationTable translations={filteredTranslations} refreshTable={refreshTranslations} />

      <TranslationDrawer
        isOpen={isAddNewOpen}
        setIsOpen={setIsAddNewOpen}
        translation={null}
        onClose={() => {}}
        refresh={refreshTranslations}
      />

      {isImportOpen && (
        <CompanyTranslationsCsvUploadDrawer
          isOpen={isImportOpen}
          setIsOpen={setIsImportOpen}
          refresh={refreshTranslations}
        />
      )}
    </Box>
  );
};
