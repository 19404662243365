import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';

type ChartProgressType = {
  readonly progress: number;
  readonly color: keyof typeof themeColors;
  readonly size: number;
  readonly thickness: number;
  readonly title?: string;
  readonly titleVariant?: TypographyVariant;
  readonly titleColor?: keyof typeof themeColors;
  readonly noDataTitle?: string;
  readonly noData?: boolean;
};
export const ChartProgress = ({
  progress,
  color,
  size,
  thickness,
  title,
  titleVariant = 'title2',
  titleColor = 'DarkGrey',
  noDataTitle = 'No data',
  noData,
}: ChartProgressType) => {
  return noData ? (
    <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="caption" color="Grey">
        {noDataTitle}
      </Typography>
    </Box>
  ) : (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: themeColors.Background,
          }}
          size={size}
          thickness={thickness}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          sx={{
            color: themeColors[color],
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size}
          thickness={thickness}
          value={progress}
        />
      </Box>

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: size,
          width: size,
        }}
      >
        <Typography variant={titleVariant} color={titleColor}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
