import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box } from '@mui/material';
import { ColumnDef, PaginationState, Row, SortingState } from '@tanstack/react-table';
import { HiddenColumnSelector } from '@v2/components/table/hidden-column-selector.component';
import { PaymentDetailsDrawer } from '@v2/feature/payments/components/payment-details-drawer.component';
import { PayrollLocalEndpoints } from '@v2/feature/payroll/payroll-local.api';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as HMRC } from '@/images/side-bar-icons/hmrc.svg';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as ArrowRight } from '@/images/side-bar-icons/Next.svg';
import { nestErrorMessage } from '@/lib/errors';
import { PAYMENTS_TEAM_SELECT_BANK_ROUTE, USER_PERSONAL_TAB } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { TabFilterButtons, TabFilterItem } from '@/v2/components/tab-filter-buttons.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { BasicServerTable } from '@/v2/components/table/server-side-table.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { getRequestFromPayment, usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { PaymentForDialogSection } from '@/v2/feature/payments/features/make-payment/sections/payment-form-dialog.section';
import { PaymentEmptyState } from '@/v2/feature/payments/pages/components/payment-empty-state.component';
import { PaymentsAPI, PaymentsEndpoints } from '@/v2/feature/payments/payments.api';
import { PaymentDto, PaymentStatus } from '@/v2/feature/payments/payments.dto';
import { ContractorInvoice, EntryTypeForPaymentTable, Expense } from '@/v2/feature/payments/payments.interface';
import { PAYMENTS_SELECT_BANK_ROUTE } from '@/v2/feature/payments/payments.router';
import { allPaymentColumns, getPaymentStatus } from '@/v2/feature/payments/payments.util';
import { getContractorInvoiceStatusComponent } from '@/v2/feature/payments/utils/get-contractor-invoice-status.util';
import { SelectDeselectRows } from '@/v2/feature/task/components/task-table/select-deselect-rows.component';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { formatMediumDate } from '@/v2/util/date-format.util';

interface TransactionsTableProps {
  readonly isModalOpen: boolean;
  readonly setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setLoading: Dispatch<SetStateAction<boolean>>;
  readonly setShowEmpty: Dispatch<SetStateAction<boolean>>;
  readonly showEmpty: boolean;
  readonly setSelectionModel: Dispatch<SetStateAction<number[]>>;
  readonly selectionModel: number[];
  readonly mode: 'payment' | 'invoice' | 'expense';
  readonly reach: 'team' | 'company';
}

const PaymentTabFilter = (polyglot: Polyglot): TabFilterItem<PaymentStatus>[] => {
  return [
    { name: polyglot.t('PaymentStatusFilter.outstanding'), value: PaymentStatus.Outstanding },
    { name: polyglot.t('PaymentStatusFilter.all'), value: PaymentStatus.All },
    { name: polyglot.t('PaymentStatusFilter.paid'), value: PaymentStatus.Paid },
  ];
};

const GetTooltipText = ({ alertsObj }: { alertsObj: PaymentDto }) => {
  const routerHistory = useHistory();
  // TODO: @polyglot-later
  let tooltipTitle = [];

  if (alertsObj.bankAccountMissing) {
    tooltipTitle.push('Bank account');
  }
  if (alertsObj.userAddressMissing) {
    tooltipTitle.push('Address');
  }
  if (alertsObj.referenceMissing) {
    tooltipTitle.push('Reference');
  }

  return (
    <Box>
      <Typography variant="captionSmall" color="Grey" sx={{ padding: 0, margin: 0 }}>
        Missing info:
      </Typography>
      {tooltipTitle.map((s, i) => (
        <Typography variant="captionSmall" color="Grey" key={i} sx={{ padding: 0, margin: 0 }}>
          - {s}
        </Typography>
      ))}

      {(alertsObj.bankAccountMissing || alertsObj.userAddressMissing) && (
        <ButtonComponent
          sizeVariant="small"
          colorVariant="tooltip"
          onClick={() => routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: alertsObj?.userId ?? '' }))}
        >
          Complete info <ArrowRight width={12} height={12} style={{ fill: themeColors.Grey }} />
        </ButtonComponent>
      )}
    </Box>
  );
};

export type PaymentWithPayrollData = PaymentDto & {
  payroll?: { id: number; entityId: number; entity: { legalName: string | null } };
  typeForPaymentTable?: EntryTypeForPaymentTable;
  rawContractorInvoice?: ContractorInvoice;
  rawExpense?: Expense;
};

export function PaymentsTransactionsTable({
  isModalOpen,
  setIsModalOpen,
  setLoading,
  setShowEmpty,
  showEmpty,
  setSelectionModel,
  selectionModel,
  mode, // should break this up into 3 different tables for expense / payment / invoice, after demo
  reach = 'company',
}: TransactionsTableProps): React.JSX.Element {
  const { polyglot } = usePolyglot();
  const [, setState] = usePaymentContext();
  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const { getScopesContext, hasScopes } = useScopes();
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const currentUserIsAdmin = hasScopes(['payments:all'], getScopesContext(user));

  const [paginationState, setPaginationState] = useState<PaginationState>({ pageIndex: 1, pageSize: 100 });
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [tableState, setTableState] = useState<{ filterValue: PaymentStatus; searchInput: string }>({
    filterValue: PaymentStatus.Outstanding,
    searchInput: '',
  });
  const { filterValue, searchInput } = tableState;
  const { data: payments, mutate: refreshPayments } = useApiClient(
    reach === 'company'
      ? PaymentsEndpoints.findByCompanyId(paginationState, sortingState[0], filterValue, searchInput)
      : reach === 'team'
      ? PaymentsEndpoints.teamFindByCompanyId(paginationState, sortingState[0], filterValue, searchInput)
      : { url: '' }
  );
  const { data: payrunPaymentToPayrollMapping } = useApiClient(
    currentUserIsAdmin ? PayrollLocalEndpoints.getPayrunPaymentToPayrollIdMapping() : { url: '' }
  );
  const [showMessage] = useMessage();
  const location = useLocation();
  const [paymentAlerts, setPaymentAlerts] = useState<Alert<PaymentDto>>();
  const [selectedPayment, setSelectedPayment] = useState<PaymentDto | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const updateTableState = useCallback((newState: Partial<typeof tableState>) => {
    setTableState((prevState) => {
      // if the state-change can result in a different set of results, reset the pagination
      if ('filterValue' in newState || 'searchInput' in newState) {
        setPaginationState((p) => ({ ...p, pageIndex: 1 }));
      }
      return { ...prevState, ...newState };
    });
  }, []);

  useEffect(() => {
    setHiddenColumns(state.user.features?.payment?.table?.hiddenColumns ?? []);
  }, [state.user.features?.payment?.table?.hiddenColumns]);

  const refresh = useCallback(async () => {
    setLoading(true);
    setShowEmpty(true);
    refreshPayments!();
    const paymentsAlerts = await PaymentsAPI.getAlerts(dispatch);
    setPaymentAlerts(paymentsAlerts);
    setLoading(false);
    setShowEmpty(false);
  }, [setLoading, setShowEmpty, refreshPayments, dispatch]);

  useEffect(() => {
    if (refreshPayments && location.pathname) refreshPayments();
  }, [refreshPayments, location.pathname]);

  const filteredPayments = useMemo(() => {
    let paymentsWithPayrollData: PaymentWithPayrollData[];
    if (!payments || !payments.items) return [];
    let filteredPayments: PaymentDto[] = payments.items.map((p) => {
      return { ...p, username: p.userId ? getCachedUserById(p?.userId)?.displayName : '' };
    });
    // for some reason, items sorted in ascending order appear in reverse order in the table
    // so we manually reverse them here so they end up displayed in the correct order.
    if (sortingState[0]?.desc === false) {
      filteredPayments = filteredPayments.reverse();
    }

    paymentsWithPayrollData =
      filteredPayments?.map((p) => {
        if (payrunPaymentToPayrollMapping && payrunPaymentToPayrollMapping[p.id]) {
          return {
            ...p,
            payroll: payrunPaymentToPayrollMapping[p.id]?.payroll,
          };
        }
        return p;
      }) ?? [];

    return paymentsWithPayrollData;
  }, [getCachedUserById, payments, payrunPaymentToPayrollMapping, sortingState]);

  const selectablePaymentsOnPage = useMemo(() => {
    // only unpaid payments can be selected
    return filteredPayments.filter((payment) => payment.status !== PaymentStatus.Paid);
  }, [filteredPayments]);

  const refreshTableData = useCallback(async () => {
    setLoading(true);
    setShowEmpty(true);
    await refreshPayments!();
    setLoading(false);
    setShowEmpty(false);
  }, [setLoading, setShowEmpty, refreshPayments]);

  const gotoSelectBankRoute = useCallback(() => {
    routerHistory.push(reach === 'company' ? PAYMENTS_SELECT_BANK_ROUTE : PAYMENTS_TEAM_SELECT_BANK_ROUTE);
  }, [routerHistory, reach]);

  const columns = useMemo<ColumnDef<PaymentWithPayrollData, PaymentWithPayrollData>[]>(() => {
    const nonInvoiceActionsColumn = (original: PaymentWithPayrollData) => {
      const alertsObj = paymentAlerts?.entries.find((p) => p.id === original.id);
      return ![PaymentStatus.Paid, PaymentStatus.Processing].includes(original.status as PaymentStatus) ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            gap: spacing.g5,
            visibility: selectionModel.length < 1 ? 'visible' : 'hidden',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {!alertsObj?.bankAccountMissing && !alertsObj?.userAddressMissing && !alertsObj?.referenceMissing && (
            <ButtonComponent
              sizeVariant="small"
              colorVariant="primary"
              onClick={(e) => {
                e.stopPropagation();
                setState(() => {
                  return {
                    payments: [original],
                    requests: [getRequestFromPayment(original)],
                  };
                });
                return gotoSelectBankRoute();
              }}
            >
              {polyglot.t('PaymentListActions.pay')}
            </ButtonComponent>
          )}
        </Box>
      ) : (
        <></>
      );
    };

    const typeColumn: ColumnDef<PaymentWithPayrollData, PaymentWithPayrollData> = {
      header: () => polyglot.t('PaymentTableHeaders.type'),
      id: 'category',
      minSize: 100,
      enableSorting: true,
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => <>{original.category}</>,
    };

    const paymentDateColumn: ColumnDef<PaymentWithPayrollData, PaymentWithPayrollData> = {
      header: () => polyglot.t('PaymentTableHeaders.paymentDate'),
      id: 'createdAt',
      minSize: 60,
      enableSorting: true,
      accessorFn: (row) => row,
      cell: ({ row: { original } }) =>
        original.transaction?.updatedAt ? (
          <DateLabelComponent date={original.transaction?.updatedAt} duration={true} />
        ) : (
          ''
        ),
    };

    return [
      {
        id: 'select',
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        header: () => {
          const selectablePaymentIds = new Set(selectablePaymentsOnPage.filter((p) => p.reference).map(({ id }) => id));
          const allSelected =
            selectionModel.length > 0 &&
            selectionModel.length === selectablePaymentIds.size &&
            selectionModel.every((id) => selectablePaymentIds.has(id));
          return (
            <Box onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                label={undefined}
                name="allSelected"
                checked={allSelected}
                value="allSelected"
                onChange={(_, checked) => {
                  setSelectionModel(checked ? [...selectablePaymentIds] : []);
                }}
              />
            </Box>
          );
        },
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
            <Box onClick={(e) => e.stopPropagation()}>
              {original.status !== PaymentStatus.Paid ? (
                <CheckboxComponent
                  label={undefined}
                  name={original.userId?.toString() ?? ''}
                  checked={selectionModel.includes(original.id)}
                  value={original.userId?.toString() ?? ''}
                  onChange={() => {
                    let finalArray: number[];
                    if (selectionModel?.includes(original.id)) {
                      finalArray = selectionModel.filter((sm) => sm !== original.id);
                    } else finalArray = [...selectionModel, original.id];
                    setSelectionModel(finalArray);
                  }}
                  disabled={!original.reference}
                />
              ) : (
                <></>
              )}
            </Box>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('PaymentTableHeaders.beneficiary'),
        id: 'name',
        minSize: 150,
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const alertsObj = paymentAlerts?.entries.find((p) => p.id === original.id);
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10, cursor: 'pointer' }}>
              {original.userId ? (
                <UserCell
                  userId={original.userId}
                  endAdornment={
                    alertsObj &&
                    (alertsObj.bankAccountMissing || alertsObj.userAddressMissing || alertsObj.referenceMissing) && (
                      <StyledTooltip title={<GetTooltipText alertsObj={alertsObj} />}>
                        <Mistake {...iconSize} />
                      </StyledTooltip>
                    )
                  }
                />
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                  <HMRC width={20} height={20} />
                  <Typography variant="caption">HMRC</Typography>
                </Box>
              )}
            </Box>
          );
        },
      },
      ...(mode === 'expense' ? [] : [typeColumn]),
      {
        header: () => polyglot.t('PaymentTableHeaders.reference'),
        id: 'reference',
        minSize: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <>{original.reference}</>,
      },
      {
        header: () =>
          mode === 'expense' ? polyglot.t('PaymentTableHeaders.date') : polyglot.t('PaymentTableHeaders.dueDate'),
        id: 'dueDate',
        minSize: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const alertsObj = paymentAlerts?.entries.find((p) => p.id === original.id);
          return mode === 'expense' && original.dueDate ? (
            formatMediumDate(original.dueDate)
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
              {original.dueDate && (
                <DateLabelComponent
                  date={original.dueDate}
                  duration={true}
                  titleSx={{
                    color:
                      alertsObj &&
                      alertsObj.paymentOverdue &&
                      ![PaymentStatus.MarkPaid, PaymentStatus.Paid].includes(original.status as PaymentStatus)
                        ? themeColors.red
                        : themeColors.DarkGrey,
                    ...themeFonts.caption,
                  }}
                />
              )}
            </Box>
          );
        },
      },
      ...(mode === 'expense' ? [] : [paymentDateColumn]),
      {
        header: () => polyglot.t('PaymentTableHeaders.status'),
        id: 'status',
        minSize: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          let statusComponent = <></>;

          if (original.rawExpense && original?.typeForPaymentTable === EntryTypeForPaymentTable.EXPENSE) {
            statusComponent = (
              <>{getContractorInvoiceStatusComponent(original.rawExpense.status, { ...themeFonts.caption })}</>
            );
          } else if (original?.typeForPaymentTable !== EntryTypeForPaymentTable.INVOICE) {
            statusComponent = (
              <StyledTooltip
                title={
                  original.status === PaymentStatus.Failed
                    ? original.message || ''
                    : original.status === PaymentStatus.Unknown
                    ? 'Exited the flow or authorisation failed. Check with your bank before retrying or mark as paid.'
                    : ''
                }
              >
                {getPaymentStatus(polyglot, original, { ...themeFonts.caption })}
              </StyledTooltip>
            );
          } else if (
            original.rawContractorInvoice &&
            original?.typeForPaymentTable === EntryTypeForPaymentTable.INVOICE
          ) {
            statusComponent = (
              <>
                {getContractorInvoiceStatusComponent(original.rawContractorInvoice.status, { ...themeFonts.caption })}
              </>
            );
          }
          return statusComponent;
        },
      },
      {
        header: () => polyglot.t('PaymentTableHeaders.amount'),
        id: 'amount',
        minSize: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div style={{ paddingRight: '10px' }}>{formatCurrency(original.amount, undefined, original.currency)}</div>
        ),
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        maxSize: 80,
        minSize: 60,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return nonInvoiceActionsColumn(original);
        },
      },
    ];
  }, [
    mode,
    paymentAlerts?.entries,
    selectionModel,
    setState,
    gotoSelectBankRoute,
    selectablePaymentsOnPage,
    setSelectionModel,
    polyglot,
  ]);

  const handMarkPaid = async () => {
    try {
      await PaymentsAPI.markAsPaid(selectionModel);
      showMessage('Marked as paid successfully', 'success');
      setSelectionModel([]);
      await refresh();
    } catch (e) {
      console.error(e);
      showMessage(nestErrorMessage(e), 'error');
    }
  };

  const handlePay = () => {
    const toBePaid = selectionModel
      .map((id) => payments?.items.find((payment) => payment.id === id))
      .filter(Boolean) as PaymentDto[];

    if (!toBePaid.length) {
      setState(({ requests }) => {
        return {
          payments: [],
          requests,
        };
      });
      return gotoSelectBankRoute();
    }
    if (toBePaid && toBePaid.length > 0) {
      setState(() => {
        return {
          payments: toBePaid,
          requests: toBePaid.map((payment) => getRequestFromPayment(payment)),
        };
      });
      return gotoSelectBankRoute();
    }
  };

  const getPaymentsAllActionsOptions = () => {
    return [
      {
        icon: <Chose {...iconSize} />,
        handler: () => handMarkPaid(),
        label: 'Mark as paid',
        disabled: false,
      },
      // Bulk delete is no longer required
      // {
      //   icon: <Trash {...iconSize} />,
      //   handler: () => handleDelete(),
      //   label: 'Delete',
      //   disabled: false,
      // },
    ];
  };

  useEffect(() => {
    (async () => {
      try {
        const paymentsAlerts = await PaymentsAPI.getAlerts(dispatch);
        setPaymentAlerts(paymentsAlerts);
      } catch (error) {
        showMessage('Could not load payments alerts.', 'error');
      }
    })();
  }, [showMessage, dispatch]);

  const handleRowClick = (row: Row<PaymentWithPayrollData>) => {
    setIsOpen(true);
    setSelectedPayment(row.original);
  };

  const validateSelectedPayments = () => {
    const paymentsIdWithMissingData =
      paymentAlerts &&
      paymentAlerts.entries
        .filter((payment) => payment.userAddressMissing || payment.bankAccountMissing || payment.referenceMissing)
        .map((item) => item.id);
    return !(selectionModel.filter((s) => paymentsIdWithMissingData?.includes(s)).length > 0);
  };

  return (
    <Box sx={{ height: '100%' }}>
      {showEmpty ? (
        <PaymentEmptyState setIsModalOpen={setIsModalOpen} />
      ) : (
        <>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: spacing.g5 }}>
                  <TabFilterButtons<PaymentStatus>
                    filters={PaymentTabFilter(polyglot)}
                    setFilterValue={(filterValue) => {
                      updateTableState({ filterValue });
                      if (filterValue !== PaymentStatus.Outstanding) setSelectionModel([]);
                    }}
                    filterValue={filterValue}
                    hasSearch
                    onFilterChange={({ filterValue, searchInput }) => {
                      updateTableState({ filterValue, searchInput });
                    }}
                  />
                  <HiddenColumnSelector
                    options={allPaymentColumns}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    columnAction={{ domain: 'payment', subDomain: 'table', feature: 'hiddenColumns' }}
                  />
                </Box>
              </Box>

              {selectionModel.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'end', gap: spacing.g5, marginLeft: spacing.ml10 }}>
                  <SelectDeselectRows
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                    rows={selectablePaymentsOnPage}
                    hideSelectAll
                  />
                  <StyledMenuComponent
                    options={getPaymentsAllActionsOptions()}
                    actionButtonDetails={{
                      type: 'button',
                      colorVariant: 'secondary',
                      sizeVariant: 'small',
                      title: 'Actions',
                      icon: <ArrowDown {...iconSize} />,
                      iconPosition: 'end',
                    }}
                  />
                  {validateSelectedPayments() && (
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="primary"
                      onClick={() => handlePay()}
                      disabled={selectionModel.length < 1}
                    >
                      Pay
                    </ButtonComponent>
                  )}
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ ...spacing.mt20 }}>
            <BasicServerTable<PaymentWithPayrollData>
              rowData={filteredPayments}
              columnData={columns}
              rowClick={handleRowClick}
              pagination={paginationState}
              setPagination={setPaginationState}
              totalPages={payments?.totalPages}
              totalItems={payments?.totalItems}
              sorting={sortingState}
              setSorting={setSortingState}
              hiddenColumns={[...hiddenColumns, ...(filterValue !== PaymentStatus.Outstanding ? ['select'] : [])]}
            />
          </Box>
        </>
      )}
      {isModalOpen && (
        <DrawerModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedPayment(undefined);
          }}
        >
          <PaymentForDialogSection
            selectedPayment={selectedPayment}
            onChange={async () => {
              await refresh();
              setSelectedPayment(undefined);
            }}
            setIsModalOpen={setIsModalOpen}
          />
        </DrawerModal>
      )}
      {selectedPayment && (
        <PaymentDetailsDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          payment={selectedPayment}
          refresh={async () => {
            await refresh();
            await refreshTableData();
          }}
          paymentAlerts={paymentAlerts}
          onClose={() => {
            setIsOpen(false);
            setSelectedPayment(undefined);
          }}
        />
      )}
    </Box>
  );
}

export const PaymentsTransactionsTableSkeleton = () => (
  <Box
    sx={{
      marginTop: spacing.m30,
      width: '100%',
      textAlign: 'center',
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        gap: spacing.g5,
      }}
    >
      <SkeletonLoader
        variant="rectangular"
        width="100px"
        height="20px"
        sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
      />
      <SkeletonLoader
        variant="rectangular"
        width="100px"
        height="20px"
        sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
      />
    </div>
    <Box sx={spacing.mt20}>
      <BasicTable rowData={[]} columnData={[]} loading={true} />
    </Box>
  </Box>
);

export function PaymentsListPage(): React.JSX.Element {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('getSidebarConfig.payments')}</Typography>}
        showBack={routerLocation.pathname.includes('/settings')}
        showAction={!showEmpty && selectionModel.length < 1}
      />

      <ContentWrapper loading={loading} sx={{ ...spacing.pt20, paddingBottom: 0 }} border={!showEmpty}>
        <Suspense
          fallback={
            <>
              <PaymentsTransactionsTableSkeleton />
            </>
          }
        >
          <PaymentsTransactionsTable
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setLoading={setLoading}
            setShowEmpty={setShowEmpty}
            showEmpty={showEmpty}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
            mode={'payment'}
            reach={'company'}
          />
        </Suspense>
      </ContentWrapper>
    </RootStyle>
  );
}
