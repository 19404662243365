import * as React from 'react';

import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';

export interface StyledTooltipProps extends Omit<TooltipProps, 'title'> {
  title?: TooltipProps['title'];
  disabled?: boolean;
}

export type StyledTooltipStyleProps = Omit<StyledTooltipProps, 'children'>;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.DarkGrey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.DarkGrey,
    ...themeFonts.captionSmall,
    color: themeColors.white,
    padding: '10px',
    borderRadius: radius.br5,
  },
}));

export const StyledTooltip: React.FunctionComponent<StyledTooltipProps> = (props) => {
  const { children, title, disabled, ...tooltipProps } = props;
  return (
    <BootstrapTooltip
      {...tooltipProps}
      // Zero-length title strings are never displayed.
      title={!title || disabled ? '' : title}
    >
      {children}
    </BootstrapTooltip>
  );
};
