import React, { useContext, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { getTaskStatus } from '@v2/feature/task/components/task.util';
import { TaskStatuses } from '@v2/feature/task/task.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { urlify } from '@v2/util/string.util';
import { useLocation, useParams } from 'react-router-dom';

import { getLinkToUploadedFile } from '@/api-client/routes';
import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DocumentIcon } from '@/images/documents/PinnedBlack.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { User } from '@/models';
import { stringToAttachmentComponentValue } from '@/v2/components/forms/attachment.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { InlineLink } from '@/v2/components/inline-link.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { previewFileInNewTab } from '@/v2/feature/documents/documents.util';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

export interface TaskAndChecklistItemCommon {
  id?: number;
  name: string;
  description?: string | null;
  assignedUserId?: number | null;
  assignedUser?: Pick<User, 'userId' | 'firstName' | 'lastName'>;
  requestedForId?: number | null;
  requestedFor?: Pick<User, 'userId' | 'firstName' | 'lastName'>;
  dueDate?: string | null;
  completedOn?: string | null;
  status?: TaskStatuses;
  attachmentValues?: string;
}

interface TaskViewModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => Promise<void> | void;
  afterClose?: () => Promise<void> | void;
  task: TaskAndChecklistItemCommon | null;
  action?: Function;
  deleteTask?: (selectedTasks: number[]) => Promise<void>;
  editTask?: () => void;
  onboardingClose?: (taskCompleted?: boolean) => Promise<void>;
}

export const TaskViewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  task,
  action,
  deleteTask,
  editTask,
  onboardingClose,
}: TaskViewModalProps): React.ReactElement => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { pathname } = useLocation();
  const inOnboarding = pathname.includes('onboarding');
  const params: { userId: string } = useParams();
  const onboardingUserId: number = inOnboarding ? Number(params.userId) : 0;
  const taskBelongsToOnboardingUser = inOnboarding && task?.assignedUserId === onboardingUserId;
  const [showMessage] = useMessage();

  const handleStatusChange = async () => {
    if (!task?.id || !action || !task?.assignedUserId) return;
    const statusChange = task.status === TaskStatuses.COMPLETE ? TaskStatuses.INCOMPLETE : TaskStatuses.COMPLETE;

    setLoading(true);
    await action(task.id, task.assignedUserId, statusChange);

    onClose();
    if (taskBelongsToOnboardingUser && statusChange === TaskStatuses.COMPLETE) {
      if (onboardingClose) await onboardingClose(true);
    } else {
      if (onboardingClose) await onboardingClose(false);
    }
    setLoading(false);
  };

  const getLinktoUploadDocument = async (id: number) => {
    try {
      await DocumentAPI.downloadViaId(id).then(({ url }: { url: string }) => {
        previewFileInNewTab(url);
      });
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    }
  };

  const tasksOptions = useMemo(() => {
    return [
      ...(deleteTask
        ? [
            {
              icon: <Trash {...iconSize} />,
              handler: async () => {
                if (task?.id) await deleteTask([task.id]);
              },
              label: polyglot.t('TaskViewModal.delete'),
              disabled: false,
            },
          ]
        : []),
      ...(editTask
        ? [
            {
              icon: <Edit {...iconSize} />,
              handler: () => editTask(),
              label: polyglot.t('TaskViewModal.edit'),
              disabled: false,
            },
          ]
        : []),
    ];
  }, [polyglot, deleteTask, editTask, task?.id]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose}>
      <Box sx={drawerContentSx}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="title2">{task?.name ?? 'Task'}</Typography>
        </Box>

        {task?.description && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
              {polyglot.t('TaskViewModal.description')}
            </Typography>
            <Typography variant="caption" dangerouslySetInnerHTML={{ __html: urlify(task.description) ?? '' }} />
          </Box>
        )}

        {task?.dueDate && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="captionSmall" color="Grey">
              {polyglot.t('TaskViewModal.dueDate')}
            </Typography>
            <Box
              sx={{
                ...{ ...themeFonts.title4, color: themeColors.DarkGrey },
                display: 'flex',
                alignItem: 'center',
                gap: spacing.g10,
              }}
            >
              {getDateString(task.dueDate)}
            </Box>
          </Box>
        )}

        {task?.completedOn && task.status === TaskStatuses.COMPLETE && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
              {polyglot.t('TaskViewModal.completedOn')}
            </Typography>
            <Box
              sx={{
                ...{ ...themeFonts.title4, color: themeColors.DarkGrey },
                display: 'flex',
                alignItem: 'center',
                gap: spacing.g10,
              }}
            >
              {getDateString(task.completedOn)}
            </Box>
          </Box>
        )}

        {task?.assignedUserId && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
              {polyglot.t('TaskViewModal.assignedTo')}
            </Typography>
            <Box sx={{ mt: spacing.m5, display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
              <UserAvatar userId={task.assignedUserId} showName nameVariant="title4" size="xxsmall" />
            </Box>
          </Box>
        )}

        {task?.requestedForId && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="captionSmall" color="Grey">
              {polyglot.t('TaskViewModal.requestedFor')}
            </Typography>
            <Box sx={{ mt: spacing.m5, display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
              <UserAvatar userId={task.requestedForId} showName nameVariant="title4" size="xxsmall" />
            </Box>
          </Box>
        )}

        {task?.status && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="captionSmall" color="Grey">
              {polyglot.t('TaskViewModal.status')}
            </Typography>
            <Typography variant="caption">{getTaskStatus(task, polyglot)}</Typography>
          </Box>
        )}

        {task?.attachmentValues &&
          (() => {
            const value = stringToAttachmentComponentValue(task.attachmentValues as string);
            if (!value) return ' ';
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                  {polyglot.t('TaskViewModal.attachment')}
                </Typography>
                <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
                  {value.type === 'file' && <DocumentIcon {...iconSize} />}
                  {value.type === 'link' || value.type === 'file' ? (
                    <InlineLink
                      href={value.type === 'link' ? value.name : getLinkToUploadedFile(value.uuid)}
                      title={value.name}
                      style={{
                        ...themeFonts.title4,
                        color: themeColors.DarkGrey,
                        textDecoration: 'underline',
                      }}
                      openInNewWindow
                    />
                  ) : (
                    <ButtonComponent
                      colorVariant="textUnderline"
                      sizeVariant="small"
                      onClick={async () => {
                        await getLinktoUploadDocument(value.id);
                      }}
                    >
                      {value.name}
                    </ButtonComponent>
                  )}
                </Stack>
              </Box>
            );
          })()}

        {task?.status === TaskStatuses.INCOMPLETE ? (
          <Box sx={buttonBoxDrawerSx}>
            {task?.assignedUserId === user.userId || taskBelongsToOnboardingUser ? (
              <>
                <ScopesControl
                  scopes={['task:all', 'task:manager']}
                  context={task?.assignedUserId ? { userId: task?.assignedUserId } : undefined}
                >
                  {tasksOptions.length > 0 && (
                    <Box sx={{ width: '100%' }}>
                      <StyledMenuComponent
                        options={tasksOptions}
                        actionButtonDetails={{
                          type: 'button',
                          colorVariant: 'secondary',
                          sizeVariant: 'medium',
                          title: polyglot.t('TaskViewModal.actions'),
                          icon: <ArrowDown {...iconSize} />,
                          iconPosition: 'end',
                          fullWidth: true,
                        }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                      />
                    </Box>
                  )}
                </ScopesControl>
                {taskBelongsToOnboardingUser ? (
                  <>
                    <LoaderButton
                      name={polyglot.t('TaskViewModal.done')}
                      loading={loading}
                      sizeVariant="medium"
                      colorVariant="primary"
                      fullWidth={true}
                      type="button"
                      onClick={handleStatusChange}
                    />
                  </>
                ) : (
                  <ScopesControl
                    scopes={['task']}
                    context={task?.assignedUserId ? { userId: task?.assignedUserId } : undefined}
                  >
                    <LoaderButton
                      name={polyglot.t('TaskViewModal.done')}
                      loading={loading}
                      sizeVariant="medium"
                      colorVariant="primary"
                      fullWidth={true}
                      type="button"
                      onClick={handleStatusChange}
                    />
                  </ScopesControl>
                )}
              </>
            ) : (
              <>
                <ScopesControl
                  scopes={['task:all', 'task:manager']}
                  context={task?.assignedUserId ? { userId: task?.assignedUserId } : undefined}
                >
                  {deleteTask && (
                    <ButtonComponent
                      fullWidth
                      onClick={async () => {
                        setIsDeleting(true);
                        if (task.id) await deleteTask([task.id]);
                        setIsDeleting(false);
                      }}
                      sizeVariant="medium"
                      colorVariant="danger"
                      loading={isDeleting}
                    >
                      {polyglot.t('General.delete')}
                    </ButtonComponent>
                  )}
                </ScopesControl>

                <ScopesControl
                  scopes={['task:all', 'task:manager']}
                  context={task?.assignedUserId ? { userId: task?.assignedUserId } : undefined}
                >
                  {editTask && (
                    <ButtonComponent fullWidth onClick={() => editTask()} sizeVariant="medium" colorVariant="secondary">
                      {polyglot.t('General.edit')}
                    </ButtonComponent>
                  )}
                </ScopesControl>
              </>
            )}
          </Box>
        ) : (
          <ScopesControl
            scopes={['task:all', 'task:manager']}
            context={task?.assignedUserId ? { userId: task?.assignedUserId } : undefined}
          >
            {task?.id && (
              <Box sx={buttonBoxDrawerSx}>
                {deleteTask && (
                  <ButtonComponent
                    fullWidth
                    onClick={async () => {
                      await deleteTask([task.id!]);
                    }}
                    sizeVariant="medium"
                    colorVariant="secondary"
                  >
                    {polyglot.t('General.delete')}
                  </ButtonComponent>
                )}

                {editTask && (
                  <ButtonComponent fullWidth onClick={() => editTask()} sizeVariant="medium" colorVariant="secondary">
                    {polyglot.t('General.edit')}
                  </ButtonComponent>
                )}
              </Box>
            )}
          </ScopesControl>
        )}
      </Box>
    </DrawerModal>
  );
};
