import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { ColumnDef } from '@tanstack/react-table';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PermissionGroupEditDrawerPage } from '@/v2/feature/permission-group/components/permission-group-edit-drawer.component';
import { PermissionGroupDto, PermissionGroupMemberUserDto } from '@/v2/feature/permission-group/permission-group.dto';
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES,
  PermissionGroupPageProps,
} from '@/v2/feature/permission-group/permission-group.interface';
import { DEFAULT_GROUPS } from '@/v2/feature/permission-group/permission-group.util';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const EditPermissionGroupMembersPage = ({ permissionGroup, refresh, setLoading }: PermissionGroupPageProps) => {
  const { polyglot } = usePolyglot();

  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup?.name) : false;

  const openEditDrawer = () => {
    setEditDrawerOpen(true);
  };

  return (
    <SettingsSectionContent
      title={polyglot.t('PermissionGroups.router.members')}
      onEdit={!isDefault ? async () => openEditDrawer() : undefined}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Component,
                value: <PermissionsMembersTable permissionGroup={permissionGroup} />,
              },
            ],
          },
        ]}
      />

      <DrawerModal isOpen={editDrawerOpen} setIsOpen={setEditDrawerOpen}>
        <PermissionGroupEditDrawerPage
          refreshPermissionGroup={refresh}
          selectedPermission={permissionGroup}
          permissionGroupMembers={(permissionGroup?.members as PermissionGroupMemberUserDto[]) ?? []}
          closePage={async () => {
            setEditDrawerOpen(false);
            await refresh();
          }}
          mode={PERMISSION_GROUP_EDIT_DRAWER_MODES.member}
          setLoading={setLoading}
          permissionGroupName={permissionGroup?.name ?? ''}
        />
      </DrawerModal>
    </SettingsSectionContent>
  );
};

const PermissionsMembersTable = ({ permissionGroup }: { permissionGroup: PermissionGroupDto }) => {
  const { polyglot } = usePolyglot();

  const [searchInput, setSearchInput] = useState<string>('');

  const filteredUsers = useMemo(() => {
    if (!searchInput) return [...(permissionGroup.members as PermissionGroupMemberUserDto[])];

    const search = searchInput.toLowerCase();
    return [
      ...(permissionGroup.members as PermissionGroupMemberUserDto[]).filter((u) =>
        (u.firstName + u.lastName).toLowerCase().includes(search)
      ),
    ];
  }, [permissionGroup.members, searchInput]);

  const tableColumns = useMemo<ColumnDef<PermissionGroupMemberUserDto, PermissionGroupMemberUserDto>[]>(
    () => [
      {
        header: () => (
          <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
            {polyglot.t('AbsencePolicyMembersSection.name')}
          </Typography>
        ),
        accessorFn: (row) => row,
        id: 'displayName',
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, firstName, lastName },
          },
        }) => (
          <Box key={userId} sx={{ display: 'flex', gap: spacing.g10 }}>
            <UserAvatar userId={userId} size="xsmall" />
            <Typography variant="caption">{firstName + ' ' + lastName}</Typography>
          </Box>
        ),
      },
    ],
    [polyglot]
  );

  return (
    <Box>
      <TableSearch
        query={searchInput}
        handleChange={(e) => setSearchInput(e.target.value)}
        style={{ width: '572px' }}
      />

      {filteredUsers.length > 0 && (
        <Box sx={{ width: '600px' }}>
          <BasicTable<PermissionGroupMemberUserDto>
            rowData={filteredUsers}
            columnData={tableColumns}
            initialSort={[{ id: 'firstName', desc: true }]}
            fixedLastColumn={false}
          />
        </Box>
      )}
    </Box>
  );
};
