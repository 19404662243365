import { useCallback, useEffect, useState } from 'react';

import { keyBy, merge, values } from 'lodash';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import {
  SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE,
  SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE,
  SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE,
  SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE,
  SETTINGS_PERMISSION_GROUP_ROUTE,
  SETTINGS_PERMISSION_GROUPS_ROUTE,
} from '@/lib/routes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { EditPermissionGroupGeneralPage } from '@/v2/feature/permission-group/pages/edit-permission-group-general.page';
import { EditPermissionGroupMembersPage } from '@/v2/feature/permission-group/pages/edit-permission-group-members.page';
import { EditPermissionGroupPermissionsPage } from '@/v2/feature/permission-group/pages/edit-permission-group-permissions.page';
import { EditPermissionGroupTargetsPage } from '@/v2/feature/permission-group/pages/edit-permission-group-targets.page';
import { PermissionGroupAPI } from '@/v2/feature/permission-group/permission-group.api';
import { PermissionDto, PermissionGroupDto } from '@/v2/feature/permission-group/permission-group.dto';
import { PermissionCategory } from '@/v2/feature/permission-group/permission-group.interface';
import {
  DEFAULT_GROUPS,
  getAdditionalScopesFromCategories,
  getScopeCategories,
} from '@/v2/feature/permission-group/permission-group.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const getPageConfig = (
  permissionGroupId: number,
  polyglot: Polyglot,
  isDefault: boolean,
  isAdmin: boolean,
  permissionGroup: PermissionGroupDto | null
): readonly NavConfigItem[] => {
  const navItems = [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('PermissionGroups.router.general'),
          stub: 'general',
          path: generatePath(SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE, { permissionGroupId }),
        },
        {
          title: polyglot.t('PermissionGroups.router.permissions'),
          stub: 'permissions',
          path: generatePath(SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE, { permissionGroupId }),
        },
        {
          title: polyglot.t('PermissionGroups.router.members'),
          stub: 'members',
          path: generatePath(SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE, { permissionGroupId }),
        },
      ],
    },
  ] as NavConfigItem[];

  if (permissionGroup && !isDefault && !isAdmin) {
    navItems[0].subItems?.push({
      title: polyglot.t('PermissionGroups.router.targets'),
      stub: 'targets',
      path: generatePath(SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE, { permissionGroupId }),
    } as NavConfigItem);
  }

  return navItems;
};

export function PermissionGroupEditRouter() {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const params = useParams<{ permissionGroupId: string }>();
  const permissionGroupId = Number(params.permissionGroupId);
  const [loading, setLoading] = useState(true);
  const [permissionGroup, setPermissionGroup] = useState<PermissionGroupDto | null>(null);
  const [additionalScopes, setAdditionalScopes] = useState<{
    [permissionGroupName: string]: PermissionDto[];
  }>({ Administration: [] });
  const [permissionCategories, setPermissionCategories] = useState<PermissionCategory[]>([]);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup?.name) : false;
  const isAdmin = permissionGroup?.name === 'Admin';

  const loadPermissionGroup = useCallback(
    async (id: number): Promise<void> => {
      setLoading(true);
      try {
        const permissionGroup = await PermissionGroupAPI.getPermissionGroup(id);
        setPermissionGroup(permissionGroup);

        let permissions = permissionGroup.permissions;

        if (!permissionGroup.isDefault) {
          let allPermissions = await PermissionGroupAPI.listPermissions();
          allPermissions = allPermissions.filter((permission) => !permission.scope.includes(':manager'));
          permissions = values(merge(keyBy(allPermissions, 'scope'), keyBy(permissionGroup.permissions, 'scope')));
        }
        const categoryWithPermissions = getScopeCategories(permissions);
        setPermissionCategories(categoryWithPermissions);

        const additionalScopes = getAdditionalScopesFromCategories(permissions);
        setAdditionalScopes(additionalScopes);

        setLoading(false);
      } catch (error) {
        showMessage('Permission group info could not be loaded.', 'error');
      } finally {
        setLoading(false);
      }
    },
    [showMessage]
  );

  useEffect(() => {
    if (!permissionGroup) loadPermissionGroup(permissionGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionGroup]);

  const refresh = useCallback(async () => {
    loadPermissionGroup(permissionGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DomainSideMenuContent
        title={permissionGroup?.name ?? ''}
        subtitle={polyglot.t('PermissionGroupListingTable.permissionGroup')}
        pageConfig={getPageConfig(permissionGroupId, polyglot, isDefault, isAdmin, permissionGroup)}
        backPath={SETTINGS_PERMISSION_GROUPS_ROUTE}
        showBack
      />
      <ContentWrapper loading={loading}>
        {permissionGroup && (
          <Switch>
            <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE} exact>
              <EditPermissionGroupGeneralPage
                permissionGroup={permissionGroup}
                refresh={refresh}
                additionalScopes={additionalScopes}
                permissionCategories={permissionCategories}
                loading={loading}
                setLoading={setLoading}
              />
            </RouteScopesControl>
            <RouteScopesControl
              scopes={['company.settings:all']}
              path={SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE}
              exact
            >
              <EditPermissionGroupPermissionsPage
                permissionGroup={permissionGroup}
                refresh={refresh}
                additionalScopes={additionalScopes}
                permissionCategories={permissionCategories}
                loading={loading}
                setLoading={setLoading}
              />
            </RouteScopesControl>
            <RouteScopesControl
              scopes={['company.settings:all']}
              path={SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE}
              exact
            >
              <EditPermissionGroupMembersPage
                permissionGroup={permissionGroup}
                refresh={refresh}
                additionalScopes={additionalScopes}
                permissionCategories={permissionCategories}
                loading={loading}
                setLoading={setLoading}
              />
            </RouteScopesControl>
            {permissionGroup && !isDefault && !isAdmin && (
              <RouteScopesControl
                scopes={['company.settings:all']}
                path={SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE}
                exact
              >
                <EditPermissionGroupTargetsPage
                  permissionGroup={permissionGroup}
                  refresh={refresh}
                  additionalScopes={additionalScopes}
                  permissionCategories={permissionCategories}
                  loading={loading}
                  setLoading={setLoading}
                />
              </RouteScopesControl>
            )}

            <Redirect to={SETTINGS_PERMISSION_GROUP_ROUTE} exact />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
}
