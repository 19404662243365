import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { OnboardingAppState, OnboardingUserState } from '@shared/modules/onboarding/onboarding';
import { ColumnDef } from '@tanstack/react-table';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { nestErrorMessage } from '@/lib/errors';
import { APP_INTEGRATION_DETAILS_ROUTE, ONBOARDING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoadingSpinner, SmallLoader } from '@/v2/components/loader.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import {
  EditAppAccount,
  EditAppAccountProps,
} from '@/v2/feature/onboarding/onboarding-apps/edit-app-account-schedule.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

export const OnboardingAppsPage = () => {
  const [onboarding, setOnboarding] = useState<OnboardingUserState | null>();
  const [drawerProps, setDrawerProps] = useState<Omit<EditAppAccountProps, 'onFinish'> | null>(null);

  const { getCachedUserById } = useCachedUsers();
  const params = useParams<{ userId: string }>();
  const userId = Number(params.userId);
  const user = userId && getCachedUserById(userId);
  const routerHistory = useHistory();
  const updatingApps = useRef(new Set<string>()).current;
  const [showMessage] = useMessage();

  const refreshAppState = useCallback(() => {
    if (!userId) return;
    OnboardingAPI.getOnboardingUser(userId).then(
      (state) => {
        updatingApps.clear();
        setOnboarding(state);
      },
      (err) => showMessage(`Failed to retrieve onboarding apps. ${nestErrorMessage(err)}`, 'error')
    );
  }, [showMessage, updatingApps, userId]);

  useEffect(() => {
    // refresh the app state on load and every time the drawer closes
    if (!drawerProps) {
      refreshAppState();
    }
  }, [drawerProps, refreshAppState]);

  const columns = useMemo<ColumnDef<OnboardingAppState, OnboardingAppState>[]>(() => {
    return [
      {
        id: 'apps',
        header: () => 'Apps',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (c) => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
            <img
              src={`/app-icons-v2/images/${c.row.original.stub}.png`}
              style={{ maxWidth: '20px', maxHeight: '20px' }}
              alt=""
            />
            <Typography sx={themeFonts.caption}>{c.row.original.name}</Typography>
          </Stack>
        ),
      },
      {
        id: 'status',
        header: () => 'Status',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.accountStatus),
        cell: (c) => (
          <SmallLoader
            loading={updatingApps.has(c.row.original.stub)}
            spinnerSx={{ placeContent: 'flex-start', m: 0 }}
            size="20px"
          >
            <Typography sx={{ ...themeFonts.caption }}>{c.row.original.accountStatus ?? 'No account'}</Typography>
          </SmallLoader>
        ),
        maxSize: 50,
      },
      {
        id: 'actions',
        header: undefined,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: (c) => {
          const appStub = c.row.original.stub;
          if (updatingApps.has(appStub)) {
            return <></>;
          }
          if (!c.row.original.installed && 0)
            return (
              <StyledTooltip
                title={
                  <Stack sx={{ gap: spacing.g20, m: spacing.m10 }}>
                    <Typography
                      sx={{ ...themeFonts.caption, color: themeColors.white, display: 'flex', alignItems: 'end' }}
                    >
                      <img
                        src={`/app-icons-v2/images/${c.row.original.stub}.png`}
                        style={{ maxWidth: '20px', maxHeight: '20px', margin: '4px 10px 0 0' }}
                        alt=""
                      />
                      App is not connected to Zelt
                    </Typography>
                    <ScopesControl scopes={AppScopes.VIEW_APP_INTEGRATION}>
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="tooltip"
                        onClick={() => routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_ROUTE, { appStub }))}
                      >
                        Connect {c.row.original.name}...
                      </ButtonComponent>
                    </ScopesControl>
                  </Stack>
                }
                sx={{ ml: 'auto' }}
              >
                <Mistake {...iconSize} style={{ display: 'flex', marginLeft: 'auto' }} />
              </StyledTooltip>
            );
          if (!!c.row.original.accountStatus) {
            // user has an account
            return <></>;
          }
          return (
            <ScopesControl scopes={AppScopes.CREATE_APP_USER}>
              <ButtonComponent
                style={{ marginLeft: 'auto' }}
                sizeVariant="small"
                colorVariant="primary"
                disabled={!!drawerProps}
                onClick={() => {
                  if (!user || !onboarding?.template.apps) return;
                  setDrawerProps({
                    app: c.row.original,
                    user,
                    defaultScheduleOffset: onboarding.template.apps.scheduleOffsetFromStartDate,
                  });
                }}
              >
                Set up
              </ButtonComponent>
            </ScopesControl>
          );
        },
        maxSize: 20,
      },
    ];
  }, [drawerProps, onboarding?.template.apps, routerHistory, updatingApps, user]);

  const close = useCallback(() => routerHistory.goBack(), [routerHistory]);
  const rows = useMemo(() => {
    if (!onboarding?.state?.apps?.items) return null;
    return [...onboarding.state.apps.items].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );
  }, [onboarding?.state?.apps?.items]);

  useEscapeKey(close);

  if (!user) {
    routerHistory.replace(ONBOARDING_ROUTE);
    return <></>;
  }

  return (
    <Stack
      sx={{ background: themeColors.white, flex: 1, inset: 0, overflow: 'hidden', position: 'absolute', zIndex: 10 }}
    >
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, ml: 'max(0px, calc(50vw - 500px))' }}>Create app accounts</Typography>
        }
        showBack
      />
      <Stack sx={{ width: 'min(90vw,800px)', mx: 'auto', mt: '100px', overflow: 'hidden' }}>
        <Typography sx={{ ...themeFonts.caption, mb: spacing.mb20 }}>
          Activate accounts now or schedule them for later
        </Typography>
        {!rows && <LoadingSpinner />}
        {rows && (
          <Stack sx={{ overflow: 'auto', pb: spacing.pb20 }}>
            <BasicTable rowData={rows} columnData={columns} loading={!rows} hidePagination={true} />
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              style={{ width: '400px', marginTop: '40px', flexShrink: 0 }}
              fullWidth
              onClick={close}
            >
              Complete
            </ButtonComponent>
          </Stack>
        )}
      </Stack>
      <DrawerModal isOpen={!!drawerProps} setIsOpen={() => setDrawerProps(null)}>
        <>
          {drawerProps && (
            <EditAppAccount
              {...drawerProps}
              onFinish={() => {
                updatingApps.add(drawerProps.app.stub);
                setDrawerProps(null);
              }}
            />
          )}
        </>
      </DrawerModal>
    </Stack>
  );
};
