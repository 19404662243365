import { useContext, useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import {
  EntitiesInterface,
  FormDataInterface,
  ReportEntity,
  ReportTypePeopleSelection,
  UserEntitiesArray,
} from '@v2/feature/reports/reports.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { borders } from '@v2/styles/borders.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export const ReportEntities = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  formData: FormDataInterface;
}) => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredEntities, setFilteredEntities] = useState<EntitiesInterface[]>(UserEntitiesArray);

  useEffect(() => {
    if (searchInput.length > 0) {
      setFilteredEntities(UserEntitiesArray.filter((e) => e.name.toLowerCase().includes(searchInput.toLowerCase())));
    } else {
      setFilteredEntities(UserEntitiesArray);
    }
  }, [searchInput]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography sx={{ ...themeFonts.title2, mb: spacing.m30 }}>{polyglot.t('ReportEntities.select')}</Typography>
        <TableSearch
          style={{ width: '90%' }}
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />

        {formData.type === ReportTypePeopleSelection.ChangeReport ? (
          <FormControl sx={{ width: '100%', ...spacing.mt20 }}>
            <FormGroup sx={{ flexFlow: 'column' }}>
              <FormControlLabel
                key="all"
                labelPlacement="start"
                value={'all'}
                control={
                  <CheckboxComponent
                    checked={
                      formData.entities.length ===
                      filteredEntities.filter(
                        (u) =>
                          u.type.includes(ReportTypePeopleSelection.ChangeReport) && hasScopes(u.scope, scopesContext)
                      ).length
                    }
                    name="all"
                    onChange={(e, checked) => {
                      const value = filteredEntities
                        .filter(
                          (u) =>
                            u.type.includes(ReportTypePeopleSelection.ChangeReport) && hasScopes(u.scope, scopesContext)
                        )
                        .map((f) => f.name);
                      if (checked) {
                        setFormData({ ...formData, entities: [...value] });
                      } else {
                        setFormData({ ...formData, entities: [] });
                      }
                    }}
                    label={undefined}
                  />
                }
                disabled={false}
                label={
                  <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>All entities</Typography>
                }
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: borders.background,
                }}
              />
              {filteredEntities
                .filter((u) => u.type.includes(ReportTypePeopleSelection.ChangeReport))
                .map((d, idx) => (
                  <ScopesControl key={`-${idx}-${d}`} scopes={d.scope} context={scopesContext}>
                    <FormControlLabel
                      key={`-${d}-${idx}`}
                      labelPlacement="start"
                      value={d.name}
                      control={
                        <CheckboxComponent
                          checked={formData.entities.includes(d.name)}
                          name={d.name}
                          onChange={(e, checked) => {
                            const target = e.target as HTMLInputElement;
                            const value = target.name as ReportEntity;
                            if (checked) {
                              setFormData((prev) => ({ ...prev, entities: [...formData.entities, value] }));
                            } else {
                              setFormData((prev) => ({ ...prev, entities: prev.entities.filter((s) => s !== value) }));
                            }
                          }}
                          label={undefined}
                        />
                      }
                      disabled={false}
                      label={<Typography sx={{ ...themeFonts.caption }}>{d.name}</Typography>}
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                        margin: 0,
                        paddingY: spacing.p15,
                        borderBottom: idx < UserEntitiesArray.length - 1 ? borders.background : 'none',
                      }}
                    />
                  </ScopesControl>
                ))}
            </FormGroup>
          </FormControl>
        ) : (
          <FormControl sx={{ width: '100%', ...spacing.mt20 }}>
            <FormGroup sx={{ flexFlow: 'column' }}>
              <FormControlLabel
                key="all"
                labelPlacement="start"
                value={'all'}
                control={
                  <CheckboxComponent
                    checked={
                      formData.entities.length ===
                      filteredEntities.filter(
                        (u) =>
                          u.type.includes(ReportTypePeopleSelection.CurrentRecords) && hasScopes(u.scope, scopesContext)
                      ).length
                    }
                    name="all"
                    onChange={(e, checked) => {
                      const value = filteredEntities
                        .filter(
                          (u) =>
                            u.type.includes(ReportTypePeopleSelection.CurrentRecords) &&
                            hasScopes(u.scope, scopesContext)
                        )
                        .map((f) => f.name);
                      if (checked) {
                        setFormData({ ...formData, entities: [...value] });
                      } else {
                        setFormData({ ...formData, entities: [] });
                      }
                    }}
                    label={undefined}
                  />
                }
                disabled={false}
                label={
                  <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>All entities</Typography>
                }
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: borders.background,
                }}
              />
              {filteredEntities
                .filter((u) => u.type.includes(ReportTypePeopleSelection.CurrentRecords))
                .map((d, idx) => (
                  <ScopesControl key={`-${idx}-${d}`} scopes={d.scope} context={scopesContext}>
                    <FormControlLabel
                      key={`-${d}-${idx}`}
                      labelPlacement="start"
                      value={d.name}
                      control={
                        <CheckboxComponent
                          checked={formData.entities.includes(d.name)}
                          name={d.name}
                          onChange={(e, checked) => {
                            const target = e.target as HTMLInputElement;
                            const value = target.name as ReportEntity;
                            if (checked) {
                              setFormData({ ...formData, entities: [...formData.entities, value] });
                            } else {
                              const newArray = formData.entities.filter((s) => s !== value);
                              setFormData({ ...formData, entities: newArray });
                            }
                          }}
                          label={undefined}
                        />
                      }
                      disabled={false}
                      label={<Typography sx={{ ...themeFonts.caption }}>{d.name}</Typography>}
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                        margin: 0,
                        paddingY: spacing.p15,
                        borderBottom: idx < UserEntitiesArray.length - 1 ? borders.background : 'none',
                      }}
                    />
                  </ScopesControl>
                ))}
            </FormGroup>
          </FormControl>
        )}

        <ButtonComponent
          style={{ marginTop: spacing.m25 }}
          sizeVariant="large"
          colorVariant="primary"
          onClick={onNext}
          fullWidth
          disabled={formData.entities.length < 1}
        >
          {polyglot.t('General.next')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
