import React, { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { HelperAbsencePolicyMembersDrawer } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-members-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { themeColors } from '@v2/styles/colors.styles';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto | undefined | null;
  readonly onClose: () => void;
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const HelperAbsencePolicyDrawer = ({ isOpen, setIsOpen, absencePolicy, onClose, users }: DrawerProps) => {
  const [isMembersOpen, setIsMembersOpen] = useState(false);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      {absencePolicy ? (
        <Box>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', mb: '20px' }}>
            <Box sx={{ width: '20px', height: '20px', borderRadius: '50%', bgcolor: absencePolicy.color }}></Box>
            <Typography variant="title2">{absencePolicy.name}</Typography>
          </Box>

          <HelperDrawerField label="Name" value={absencePolicy.name} />
          <HelperDrawerField label="Full name" value={absencePolicy.fullName ?? '-'} />
          <HelperDrawerField
            label="Allowance"
            value={absencePolicy.allowance == null ? 'Unlimited' : absencePolicy.allowance}
          />
          <HelperDrawerField label="Allowance type" value={absencePolicy.allowanceType} />
          <HelperDrawerField label="Is public" value={absencePolicy.isPublic ? 'True' : 'False'} />
          <HelperDrawerField
            label="Cycle start (Day/Month)"
            value={`${absencePolicy.cycleStartDay}/${absencePolicy.cycleStartMonth}`}
          />
          <HelperDrawerField label="Prorating FTE" value={absencePolicy.proratingFte ? 'True' : 'False'} />
          <HelperDrawerField label="Prorating StartDate" value={absencePolicy.proratingStartDate ? 'True' : 'False'} />
          <HelperDrawerField label="Allowance rounding type" value={absencePolicy.allowanceRoundingType} />
          <HelperDrawerField label="Accrual unit" value={absencePolicy.accrualUnit ?? '-'} />
          <HelperDrawerField label="No of accrual unit per period" value={absencePolicy.accrualUnitsNo ?? '-'} />
          <HelperDrawerField label="Accrual upfront" value={absencePolicy.accrualUnit ? 'Enabled' : 'Disabled'} />
          <HelperDrawerField label="Accrual cliff" value={absencePolicy.accrualCliff ?? '-'} />
          <HelperDrawerField label="Allowance type" value={absencePolicy.allowanceType} />
          <HelperDrawerField label="Carry-over enabled" value={absencePolicy.carryOverEnabled ? 'True' : 'False'} />
          {absencePolicy.carryOverEnabled && (
            <HelperDrawerField label="Carry-over limit" value={absencePolicy.carryOverLimit ?? '-'} />
          )}
          {absencePolicy.carryOverEnabled && (
            <HelperDrawerField label="Carry-over expiration day" value={absencePolicy.carryOverExpirationDay ?? '-'} />
          )}
          {absencePolicy.carryOverEnabled && (
            <HelperDrawerField
              label="Carry-over expiration month"
              value={absencePolicy.carryOverExpirationMonth ?? '-'}
            />
          )}
          <HelperDrawerField label="Members rule" value={absencePolicy.membersRule} />
          <HelperDrawerField
            label="Selected members ids"
            value={absencePolicy.selectedMembersIds ? 'See here' : '-'}
            action={
              absencePolicy.selectedMembersIds
                ? () => {
                    setIsMembersOpen(true);
                  }
                : undefined
            }
          />
          <HelperDrawerField label="Custom rule" value={absencePolicy.customRule ?? '-'} />
          {absencePolicy.backoffApprovalRule && (
            <HelperDrawerField label="Approval rule" value={absencePolicy.backoffApprovalRule.name ?? '-'} />
          )}
          <HelperDrawerField
            label="Update approval not required"
            value={absencePolicy.updateApprovalNotRequired ? 'True' : 'False'}
          />

          <HelperDrawerField
            label="Public holiday on top"
            value={absencePolicy.publicHolidayOnTop ? 'True' : 'False'}
          />
          <HelperDrawerField
            label="Public holiday autobook"
            value={absencePolicy.publicHolidayAutobook ? 'True' : 'False'}
          />
          <HelperDrawerField label="Holidays prorating rule" value={absencePolicy.holidaysProratingRule} />

          <HelperDrawerField label="Requests to payroll" value={absencePolicy.inPayrollRequests ? 'True' : 'False'} />
          <HelperDrawerField
            label="Offboarding balance to payroll"
            value={absencePolicy.inPayrollOffboarding ? 'True' : 'False'}
          />
          <HelperDrawerField label="Pay code" value={absencePolicy.payCode ?? '-'} />
          <HelperDrawerField label="Pay code multiplier" value={absencePolicy.payCodeMultiplier ?? '-'} />

          <HelperDrawerField label="Tenure enabled" value={absencePolicy.tenureEnabled ? 'True' : 'False'} />
          {absencePolicy.tenureEnabled && (
            <HelperDrawerField label="Tenure allocation" value={absencePolicy.tenureAllocation ?? '-'} />
          )}
          {absencePolicy.tenureEnabled && (
            <HelperDrawerField
              label="Tenure settings"
              value={
                absencePolicy.tenureAdditionSettings
                  ? JSON.stringify(absencePolicy.tenureAdditionSettings, null, 2)
                  : '-'
              }
            />
          )}

          <HelperDrawerField
            label="Notifications enabled"
            value={absencePolicy.notificationsEnabled ? 'True' : 'False'}
          />
          {absencePolicy.notificationsEnabled && (
            <HelperDrawerField
              label="Reminders settings"
              value={absencePolicy.remindersSettings ? JSON.stringify(absencePolicy.remindersSettings, null, 2) : '-'}
            />
          )}

          <HelperAbsencePolicyMembersDrawer
            isOpen={isMembersOpen}
            setIsOpen={setIsMembersOpen}
            membersIds={absencePolicy.selectedMembersIds ?? []}
            users={users}
          />
        </Box>
      ) : (
        <SkeletonLoader variant="rectangular" width="90%" height="90vh" sx={{ borderRadius: '10px' }} />
      )}
    </DrawerModal>
  );
};

export const HelperDrawerField = ({
  label,
  value,
  action,
}: {
  label: string;
  value: string | number;
  action?: () => void;
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      width: '100%',
      alignItems: 'start',
      py: '2px',
      px: '5px',
      '&:hover': { bgcolor: themeColors.Background, borderRadius: '15px' },
    }}
  >
    <Typography variant="caption">{label}</Typography>
    <Typography
      variant="title4"
      sx={{
        color: action ? 'blue' : 'black',
        textDecoration: action ? 'underline' : 'none',
        cursor: action ? 'pointer' : 'default',
      }}
      onClick={action}
    >
      {value}
    </Typography>
  </Box>
);
