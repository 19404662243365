import { Dispatch } from 'react';

import { ColumnSort, PaginationState } from '@tanstack/react-table';
import { Alert } from '@v2/infrastructure/alert/alert.interface';
import axios from 'axios';

import { Action, GlobalStateActions } from '@/GlobalState';
import {
  BasicBankAccountDetails,
  PaymentDto,
  PaymentInstitutionDto,
  PaymentResponseDto,
  PaymentStatus,
  SinglePaymentRequestDto,
  TransactionStatus,
} from '@/v2/feature/payments/payments.dto';
import { TransactionPaymentRequest } from '@/v2/feature/payments/payments.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import { PaginatedResponse } from '@/v2/util/pagination.util';

export class PaymentsEndpoints {
  static getTransactionStatus(transactionId: number): Endpoint<TransactionStatus> {
    return {
      url: `/apiv2/payments/transactions/${transactionId}/status`,
    };
  }

  static getTransactionPaymentRequest(transactionId: number): Endpoint<TransactionPaymentRequest> {
    if (!transactionId) return { url: '' };
    return {
      url: `/apiv2/payments/transactions/${transactionId}/payment-request`,
    };
  }

  static getPaymentsByTransactionId(transactionId: number): Endpoint<PaymentDto[]> {
    return {
      url: `/apiv2/payments/transactions/${transactionId}/group-payments`,
    };
  }

  static findByCompanyId(
    { pageIndex, pageSize }: PaginationState,
    sortField?: ColumnSort,
    filter = PaymentStatus.All,
    search = ''
  ): Endpoint<PaginatedResponse<PaymentDto> | null> {
    const sort = sortField?.id ?? '';
    const sortDesc = sortField?.desc ?? '';
    const params = new URLSearchParams({
      page: `${pageIndex}`,
      pageSize: `${pageSize}`,
      sort,
      sortDesc: `${sortDesc}`,
      filter,
      search,
    });
    return {
      url: `/apiv2/payments?${params.toString()}`,
    };
  }

  static teamFindByCompanyId(
    { pageIndex, pageSize }: PaginationState,
    sortField?: ColumnSort,
    filter = PaymentStatus.All,
    search = ''
  ): Endpoint<PaginatedResponse<PaymentDto> | null> {
    const sort = sortField?.id ?? '';
    const sortDesc = sortField?.desc ?? '';
    const params = new URLSearchParams({
      page: `${pageIndex}`,
      pageSize: `${pageSize}`,
      sort,
      sortDesc: `${sortDesc}`,
      filter,
      search,
    });
    return {
      url: `/apiv2/payments/team?${params.toString()}`,
    };
  }

  static getPaymentInstitution(): Endpoint<readonly PaymentInstitutionDto[]> {
    return {
      url: '/apiv2/payments/institutions',
    };
  }
}

export class PaymentsAPI {
  static async createPayment(payment: SinglePaymentRequestDto): Promise<PaymentDto> {
    return (await axios.post(`/apiv2/payments/single`, { payment })).data;
  }

  static async updatePayment(payment: SinglePaymentRequestDto, paymentId: number): Promise<void> {
    await axios.patch(`/apiv2/payments/single/${paymentId}`, { payment });
  }

  static async initSinglePaymentRequest(
    institutionId: string,
    payment: SinglePaymentRequestDto
  ): Promise<PaymentResponseDto> {
    return (await axios.post(`/apiv2/payments/single/init`, { institutionId, payment })).data;
  }

  static async executeSinglePaymentRequest(
    institutionId: string,
    paymentId: number,
    executionDateTime: Date | undefined,
    payerDetails: BasicBankAccountDetails | undefined
  ): Promise<PaymentResponseDto> {
    return (
      await axios.post(`/apiv2/payments/single/${paymentId}`, {
        institutionId,
        executionDateTime,
        payerDetails,
      })
    ).data;
  }

  static async executeBulkPaymentRequest(
    institutionId: string,
    paymentIds: number[],
    executionDateTime: Date | undefined,
    payerDetails: BasicBankAccountDetails | undefined,
    groupPayments: boolean,
    groupPaymentsReference: string // needed only if groupPayments = true
  ): Promise<PaymentResponseDto> {
    return (
      await axios.post(`/apiv2/payments/bulk?groupPayments=${groupPayments}&reference=${groupPaymentsReference}`, {
        institutionId,
        paymentIds,
        executionDateTime,
        payerDetails,
      })
    ).data;
  }

  static async deletePayments(paymentIds: number[]): Promise<void> {
    await axios.post(`/apiv2/payments/bulk/delete`, { paymentIds });
  }

  static async rejectUnderlyingExpense(paymentId: number): Promise<void> {
    await axios.post(`/apiv2/payments/single/reject-underlying-expense/${paymentId}`);
  }

  static async voidUnderlyingInvoice(paymentId: number): Promise<void> {
    await axios.post(`/apiv2/payments/single/void-underlying-invoice/${paymentId}`);
  }

  static async markAsPaid(paymentIds: number[]): Promise<void> {
    await axios.post(`/apiv2/payments/bulk/mark-paid`, { paymentIds });
  }

  static async getUserReceivablesAccountPayments(userId: number): Promise<any[]> {
    return (await axios.get(`/apiv2/payments/account-statements/receivables/users/${userId}`)).data;
  }

  static async getAlerts(dispatch: Dispatch<Action> | undefined = undefined): Promise<Alert<PaymentDto>> {
    const paymentsAlerts = (await axios.get(`/apiv2/payments/alerts`)).data;
    if (dispatch) {
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: {
          payments: paymentsAlerts,
        },
      });
    }

    return paymentsAlerts;
  }

  static async refreshPaymentTransactionByPaymentId(paymentId: number, userId: number | null): Promise<void> {
    await axios.post(`/apiv2/payments/${paymentId}/refresh-transaction`, { userId });
  }
}
