import { useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import { EmptyStateBox } from '@v2/components/empty-state-box.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { getDefaultBenefitImageByCategory } from '@v2/feature/benefits/benefits.util';
import { BenefitOverviewItem } from '@v2/feature/benefits/components/benefit-overview-item.component';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { getLogoByProviderName } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import {
  BENEFITS_ME_ROUTE,
  CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  INSURANCE_ME_OVERVIEW_ROUTE,
  PENSION_ME_SUMMARY_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_BENEFITS_PENSION_SUMMARY_ROUTE,
} from '@/lib/routes';
import { UserOverviewConfig } from '@/models';

interface BenefitsCompanyOverviewRouterProps {
  readonly userId: number;
  readonly userPension: UserPensionDto | null;
  readonly userInsurance: UserInsuranceDto | null;
  readonly userBenefits: UserCustomBenefitDto[] | null;
  readonly userOverviewConfig: UserOverviewConfig;
  readonly loading: boolean;
}

export const BenefitsUserOverviewPage = ({
  userId,
  userPension,
  userInsurance,
  userBenefits,
  userOverviewConfig,
  loading,
}: BenefitsCompanyOverviewRouterProps) => {
  const routerHistory = useHistory();
  const location = useLocation();
  const [state] = useContext(GlobalContext);

  const isMePage = useMemo(() => {
    return location.pathname.startsWith(BENEFITS_ME_ROUTE);
  }, [location]);

  const showForCurrentUser = useMemo(() => userId === state.user.userId, [state.user.userId, userId]);

  return (
    <RootStyle>
      <TopHeader title="Overview" />

      <ContentWrapper loading={loading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, width: '600px' }}>
          {userOverviewConfig.inPension && userPension && userPension.pensionProviderName && (
            <BenefitOverviewItem
              key="pension"
              title={'Pension scheme'}
              descriptionItems={[
                PensionProvidersValueToLabel[userPension.pensionProviderName] ?? userPension.pensionProviderName,
              ]}
              icon={getPensionLogoByProviderName(userPension.pensionProviderName, 40)}
              onClick={() => {
                routerHistory.push(
                  matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)
                    ? generatePath(USER_BENEFITS_PENSION_SUMMARY_ROUTE, { userId })
                    : PENSION_ME_SUMMARY_ROUTE
                );
              }}
            />
          )}

          {userOverviewConfig.inInsurance && userInsurance?.policy && (
            <BenefitOverviewItem
              key="insurance"
              title={userInsurance.policy.providerName}
              descriptionItems={['Health Insurance']}
              icon={getLogoByProviderName(userInsurance.policy.providerName, 40)}
              onClick={() => {
                routerHistory.push(
                  matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)
                    ? generatePath(USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE, { userId })
                    : INSURANCE_ME_OVERVIEW_ROUTE
                );
              }}
            />
          )}

          {userOverviewConfig.inCustomBenefits &&
            userBenefits?.map((benefit, index) => (
              <BenefitOverviewItem
                key={`benefit-${index}`}
                title={benefit.customBenefit?.name ?? 'Custom benefit'}
                descriptionItems={['Benefit']}
                icon={
                  benefit?.customBenefit?.url ? (
                    <img src={benefit.customBenefit.url} width={40} height={40} alt="benefit-logo" />
                  ) : (
                    getDefaultBenefitImageByCategory(benefit?.customBenefit?.category, '50px', 'auto')
                  )
                }
                onClick={() => {
                  if (matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)) {
                    routerHistory.push(
                      generatePath(USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE, {
                        userId,
                        id: benefit.customBenefitId,
                      })
                    );
                  } else
                    routerHistory.push(generatePath(CUSTOM_BENEFIT_OVERVIEW_ROUTE, { id: benefit.customBenefitId }));
                }}
              />
            ))}

          {!userOverviewConfig.inPension && !userOverviewConfig.inInsurance && !userOverviewConfig.inCustomBenefits && (
            <EmptyStateBox
              title={isMePage || showForCurrentUser ? 'Your benefits' : 'User benefits'}
              subtitle={
                isMePage || showForCurrentUser
                  ? 'You will see your benefits here. At the moment there is nothing to show'
                  : "You will see employee's benefits here. At the moment there is nothing to show"
              }
            />
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
