import React from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { KeyedMutator } from 'swr';

import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CompanySettingsDetailsComponent } from '@/v2/feature/company/company-settings/features/components/company-settings/company-details/company-settings-details.component';
import { CompanySettingsEntityTable } from '@/v2/feature/company/company-settings/features/components/company-settings/entity-details/company-settings-entity-table.component';

interface CompanySettingsGeneralProps {
  readonly generalSettings: GeneralSettingsDto | null;
  readonly refreshSettings?: KeyedMutator<GeneralSettingsDto>;
}

export const CompanySettingsGeneral = ({
  generalSettings,
  refreshSettings,
}: CompanySettingsGeneralProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  return (
    <SettingsSectionContent title={polyglot.t('CompanySettingsHeader.company')} contentWidth="100%">
      <CompanySettingsDetailsComponent generalSettings={generalSettings} refreshSettings={refreshSettings} />
      {generalSettings && (
        <CompanySettingsEntityTable entities={generalSettings.entities} refreshEntities={refreshSettings} />
      )}
    </SettingsSectionContent>
  );
};
