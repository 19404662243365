import React, { useMemo, useState } from 'react';

import { Theme, SxProps, Box } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortString, sortNumeric } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import {
  getUnitValue,
  getOptionalPayCodesInUse,
  calcPaycodeTotalForPayrunEntry,
  getSalaryAmount,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { EditIncomePage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-income-page.component';
import { EditPayrunEntryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-payrun-entry-drawer.component';
import {
  CurrencyWithDiff,
  ValueWithDiff,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component';
import { PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { StaffologyPayCode } from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { sum } from '@/v2/util/array.util';

type PayrunIncomeTableProps = {
  entries: PayRunEntryDto[];
  previousEntries: PayRunEntryDto[];
  payCodes?: StaffologyPayCode[] | null;
  payrunClosed: boolean;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
  sx?: SxProps<Theme>;
};

export const PayrunIncomeTable = ({
  entries,
  previousEntries,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
  sx,
}: PayrunIncomeTableProps) => {
  const [editingIncome, setEditingIncome] = useState<PayRunEntryDto>();

  const optionalAdditionsInUse = useMemo(() => getOptionalPayCodesInUse(payCodes ?? [], entries, false), [
    entries,
    payCodes,
  ]);

  const [incomeTotals, prevIncomeTotals] = useMemo(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => ({
      salary: sum(entries.map(getSalaryAmount), (value) => value),
      additions: sum(entries, (e) => e.totals.additions),
    });
    return [
      calculateTotals(entries),
      (previousEntries.length ? calculateTotals(previousEntries) : {}) as Record<
        keyof ReturnType<typeof calculateTotals>,
        number | undefined
      >,
    ];
  }, [entries, previousEntries]);

  const [optionalAdditionsTotals, prevOptionalAdditionsTotals] = useMemo<
    [Record<string, number>, Record<string, number | undefined>]
  >(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => {
      const totals: Record<string, number> = {};
      for (const { code } of optionalAdditionsInUse) {
        totals[code] = sum(entries, (e) => calcPaycodeTotalForPayrunEntry(e, code));
      }
      return totals;
    };
    return [calculateTotals(entries), previousEntries.length ? calculateTotals(previousEntries) : {}];
  }, [entries, optionalAdditionsInUse, previousEntries]);

  const columnData = useMemo<ColumnDef<PayRunEntryDto, PayRunEntryDto>[]>(() => {
    const previousById = new Map<string, PayRunEntryDto>(previousEntries.map((item) => [item.employee.id, item]));
    const getPreviousPayrunEntry = (context: CellContext<PayRunEntryDto, PayRunEntryDto>) => {
      return previousById.get(context.getValue().employee.id);
    };
    // returns a zero column-size value if all entries are zero/undefined for a given payrun entry field
    const hideColumnIfNoValues = (visibleSize: number, getValue: (item: PayRunEntryDto) => number | undefined) => {
      return entries.some(getValue) || previousEntries.some(getValue) ? visibleSize : 0;
    };

    const columns: ColumnDef<PayRunEntryDto, PayRunEntryDto>[] = [
      {
        id: 'employee',
        header: () => 'Employee',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.employee.name),
        cell: (c) => <UserCell userId={c.row.original.userId} />,
        footer: () => 'Total',
        size: 100,
      },
      {
        id: 'salary',
        header: () => 'Salary',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => getSalaryAmount(item)),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => getSalaryAmount(item)}
          />
        ),
        footer: () => <CurrencyWithDiff currentValue={incomeTotals.salary} previousValue={prevIncomeTotals.salary} />,
        size: 60,
      },
      {
        id: 'salary-basis',
        header: () => 'Salary basis',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.payOptions.basis),
        cell: (c) => c.getValue().payOptions.basis,
        size: 80,
      },
      {
        id: 'units',
        header: () => <span title="Only applicable for employees with Hourly or Daily rate">Units</span>,
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => getUnitValue(item)),
        cell: (c) => getUnitValue(c.getValue()).toFixed(2).replace('.00', ''),
        size: 50,
      },
      {
        id: 'rate',
        header: () => 'Rate',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.payOptions.payAmount),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.payOptions.payAmount}
          />
        ),
        size: hideColumnIfNoValues(20, (e) => e.payOptions.payAmount - e.totals.basicPay),
      },
      ...optionalAdditionsInUse
        .sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }))
        .map<ColumnDef<PayRunEntryDto, PayRunEntryDto>>(({ code, title }) => ({
          id: code,
          header: () => title,
          accessorFn: (row) => row,
          enableSorting: true,
          sortingFn: (a, b) => sortNumeric(a, b, (item) => calcPaycodeTotalForPayrunEntry(item, code)),
          cell: (c) => (
            <ValueWithDiff
              current={c.getValue()}
              previous={getPreviousPayrunEntry(c)}
              getValue={(item) => calcPaycodeTotalForPayrunEntry(item, code)}
              dimIfZero
            />
          ),
          footer: () => (
            <CurrencyWithDiff
              currentValue={optionalAdditionsTotals[code]}
              previousValue={prevOptionalAdditionsTotals[code]}
            />
          ),
          size: 90,
        })),
      {
        id: 'total-income',
        header: () => 'Total income',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totals.additions),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={getPreviousPayrunEntry(c)}
            getValue={(item) => item.totals.additions}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={incomeTotals.additions} previousValue={prevIncomeTotals.additions} />
        ),
        size: 60,
      },
    ];

    // only include columns with non-zero size
    return columns.filter((column) => column.size);
  }, [
    entries,
    incomeTotals,
    optionalAdditionsInUse,
    optionalAdditionsTotals,
    previousEntries,
    prevIncomeTotals,
    prevOptionalAdditionsTotals,
  ]);

  return (
    <Box sx={sx}>
      <BasicTable
        rowData={entries}
        columnData={columnData}
        hidePagination
        showFooter
        rowClick={(row) => setEditingIncome(row.original)}
      />
      <EditPayrunEntryDrawer
        userId={editingIncome?.userId}
        payrunEntry={editingIncome}
        payCodes={payCodes}
        payrunClosed={payrunClosed}
        saveIncomeUpdates={saveIncomeUpdates}
        onClose={() => setEditingIncome(undefined)}
        Content={EditIncomePage}
      />
    </Box>
  );
};
