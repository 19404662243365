import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import {
  ReviewProgress,
  ReviewResultNavigation,
  RadarChartByCategory,
  ReviewParticipation,
  ReviewResult,
} from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewResultEndpoints {
  static getReviewOverviewResultByReviewee(revieweeId: string, cycleId: string): Endpoint<ReviewResult> {
    return {
      url: `/apiv2/review-result/overview/${revieweeId}/${cycleId}`,
    };
  }

  static getRadarChartByReviewee(revieweeId: string, cycleId: string): Endpoint<RadarChartByCategory> {
    return {
      url: `/apiv2/review-result/radar/${revieweeId}/${cycleId}`,
    };
  }

  static getReviewProgressByCycleId(cycleId: string, reach: ReachType): Endpoint<ReviewProgress[]> {
    return {
      url: `/apiv2/review-result/progress/${cycleId}?reach=${reach}`,
    };
  }

  static getReviewParticipationByCycleId(cycleId: string, reach: ReachType): Endpoint<ReviewParticipation> {
    return {
      url: `/apiv2/review-result/participation/${cycleId}?reach=${reach}`,
    };
  }

  static getReviewResultNavigation(cycleId: string): Endpoint<ReviewResultNavigation> {
    return {
      url: `/apiv2/review-result/navigation/${cycleId}`,
    };
  }
}
