import React from 'react';

import { Box, IconButton, Link } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomDEPTokenSteps } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component';
import { DepWizardDto } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { saveAs } from 'file-saver';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArchiveIcon } from '@/images/side-bar-icons/Download.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const DownloadPublicMdmKeyComponent = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<DepWizardDto>>;
  formData: DepWizardDto;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography variant="title2">Download Token Public Key</Typography>
        <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m20 }}>
          Download this file with Zelt MDM details. You will need to upload it into your Apple business manager in the
          next step.
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: spacing.m30 }}>
          <Link
            href="#"
            onClick={async () => {
              try {
                const profile = await InHouseMdmAPI.getDepPublicKey(formData.mdmServerName);
                const blob = new Blob([Buffer.from(profile, 'binary')], {
                  type: 'application/application/x-pem-file',
                });
                saveAs(blob, 'Zelt-MDM-Token-Public-Key.pem');
              } catch (error) {
                showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, 'error');
              }
            }}
            underline="none"
            color="inherit"
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <IconButton
              onClick={async () => {
                try {
                  const profile = await InHouseMdmAPI.getDepPublicKey(formData.mdmServerName);
                  const blob = new Blob([Buffer.from(profile, 'binary')], {
                    type: 'application/application/x-pem-file',
                  });
                  saveAs(blob, 'Zelt-MDM-Token-Public-Key.pem');
                } catch (error) {
                  showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, 'error');
                }
              }}
              title="Zelt-MDM-Token-Public-Key.pem"
            >
              <ArchiveIcon {...iconSize} />
            </IconButton>
            <Typography variant="caption">Zelt-MDM-Token-Public-Key.pem</Typography>
          </Link>
        </Box>

        <ButtonComponent
          sizeVariant="large"
          colorVariant="primary"
          style={{ marginTop: spacing.m25 }}
          onClick={() => {
            formData.type = CustomDEPTokenSteps.Download_key;
            setFormData(formData);
            onNext();
          }}
          fullWidth
        >
          {polyglot.t('General.next')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
