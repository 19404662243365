import { FormikErrors } from 'formik';
import { useEffect } from 'react';

type FormikLike = {
  errors: FormikErrors<unknown>;
  isSubmitting: boolean;
};

/**
 * Implements a hook to scroll to a formik-controlled field when a
 * form is submitted and formik detects an error during validation
 */
export function useScrollToFormError(formik: FormikLike) {
  useEffect(() => {
    if (!formik.isSubmitting) return;
    for (const fieldName in formik.errors) {
      // assume the name of the element matches the formik name
      const el = document.getElementsByName(fieldName);
      if (el?.[0]) {
        el[0].scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
        return;
      }
    }
  }, [formik.errors, formik.isSubmitting]);
}
