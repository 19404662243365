import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltCalendarHoliday } from '@v2/feature/calendar/features/calendar.page';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CalendarPublicHolidayDrawerContent } from '@/v2/feature/calendar/features/components/calendar-public-holiday-drawer-content.component';
import { DARK_CONTRAST_COLOR } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CalendarPublicHoliday = React.memo(
  ({
    publicHoliday,
    activeView,
    userId,
  }: {
    publicHoliday: ZeltCalendarHoliday;
    readonly activeView: 'Month' | 'Week';
    userId: number;
  }) => {
    const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

    return (
      <>
        <Box
          sx={{ display: publicHoliday ? 'flex' : 'none', width: '100%', justifyContent: 'center', height: '40px' }}
          onClick={() => {
            setIsViewOpen(true);
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: publicHoliday.color,
              display: 'flex',
              width: '100%',
              overflow: 'hidden',
              position: 'relative',
              boxSizing: 'border-box',
              '&:hover::before': {
                cursor: 'pointer',
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                zIndex: 1,
              },
              '& > *': {
                position: 'relative',
                zIndex: 1,
              },
            }}
          >
            <Box
              sx={{
                display: activeView === 'Week' ? 'flex' : 'none',
                alignItems: 'center',
                gap: spacing.g5,
                px: spacing.p5,
              }}
            >
              {publicHoliday && (
                <Box>
                  {publicHoliday.date && (
                    <Typography
                      variant="captionSmall"
                      color={DARK_CONTRAST_COLOR.includes(publicHoliday.color) ? 'white' : 'DarkGrey'}
                    >
                      All day
                    </Typography>
                  )}
                  <Typography
                    variant="captionSmall"
                    color={DARK_CONTRAST_COLOR.includes(publicHoliday.color) ? 'white' : 'Grey'}
                    sx={{
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: activeView === 'Week' ? '140px' : '40px',
                    }}
                  >
                    {publicHoliday.name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <DrawerModal isOpen={isViewOpen} setIsOpen={setIsViewOpen}>
          <CalendarPublicHolidayDrawerContent publicHoliday={publicHoliday} userId={userId} />
        </DrawerModal>
      </>
    );
  }
);
