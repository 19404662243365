import React from 'react';

import { Grid, SxProps, Theme } from '@mui/material';

import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const GridDisplayBarV2 = ({
  cells,
  onClick,
  sx = {},
}: {
  readonly sx?: SxProps<Theme>;
  readonly cells: {
    content: React.JSX.Element | null;
    sx?: SxProps<Theme>;
    gridXs?: number;
  }[];
  readonly onClick?: () => void;
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingY: spacing.p16,
        borderBottom: borders.background,
        '&:hover': {
          background: onClick ? themeColors.TableHover : 'none',
        },
        cursor: onClick ? 'pointer' : 'default',
        ...sx,
      }}
      alignItems="center"
      onClick={onClick}
    >
      {cells.map((cell, index) => (
        <Grid key={index} item xs={cell.gridXs} sx={cell.sx ?? {}}>
          {cell.content ?? null}
        </Grid>
      ))}
    </Grid>
  );
};
