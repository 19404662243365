import Box from '@mui/material/Box';
import { InfoCard } from '@v2/feature/absence/components/info-card.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import {
  CompanySubscriptionDetails,
  SeatsMethod,
  SubscriptionType,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';

interface BillingOverviewProps {
  readonly subscriptionDetailsForCompany: CompanySubscriptionDetails | null;
}

export const CompanySubscriptionBillingOverview = ({ subscriptionDetailsForCompany }: BillingOverviewProps) => {
  const { polyglot } = usePolyglot();

  const nextInvoiceCost = subscriptionDetailsForCompany?.billResult?.totalCost ?? 0;

  const isInTrialMode = subscriptionDetailsForCompany?.companySubscription?.type === SubscriptionType.TRIAL;
  const trialDateEnding =
    isInTrialMode && subscriptionDetailsForCompany?.companySubscription?.endDate
      ? new Date(subscriptionDetailsForCompany?.companySubscription?.endDate)
      : null;

  const activeUsers = subscriptionDetailsForCompany?.companySubscription?.seatMultiplier;
  const rentedDevices = subscriptionDetailsForCompany?.companySubscription?.billingMetric?.noOfRentedDevices ?? 0;
  const activeAccountsText = `${activeUsers}`;

  const today = new Date();
  const nextInvoiceDate = new Date(today.getFullYear(), today.getMonth() + 1, 1).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const rentedDevicesText = `${rentedDevices} device${rentedDevices === 1 ? '' : 's'}`;
  return (
    <Box sx={{ display: 'flex', gap: spacing.gap60 }}>
      {isInTrialMode && trialDateEnding ? (
        <InfoCard title={polyglot.t('BillingOverview.trialEnding')} value={dateAPItoDisplay(trialDateEnding)} />
      ) : (
        <>
          {nextInvoiceCost > 0 && (
            <InfoCard title={polyglot.t('BillingOverview.nextInvoiceDate')} value={nextInvoiceDate} />
          )}
          {nextInvoiceCost > 0 && (
            <InfoCard
              title={polyglot.t('BillingOverview.amount')}
              value={formatMoney({
                locale: 'en-US',
                amount: nextInvoiceCost,
                currency: subscriptionDetailsForCompany?.companySubscription?.currency,
              })}
            />
          )}
          <InfoCard
            title={polyglot.t(
              subscriptionDetailsForCompany?.companySubscription?.seatsMethod === SeatsMethod.ACTIVE
                ? 'BillingOverview.active'
                : 'BillingOverview.fixed'
            )}
            value={activeAccountsText}
          />
          {rentedDevices > 0 && <InfoCard title={polyglot.t('BillingOverview.rented')} value={rentedDevicesText} />}
        </>
      )}
    </Box>
  );
};
