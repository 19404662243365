import axios from 'axios';
import { SlackChannel } from '@v2/feature/notification/notification-settings/features/notification-setting.page';

export class SlackAPI {
  static async getSlackChannels(): Promise<SlackChannel[]> {
    const res = await axios.get(`/apiv2/apps/slack/channels`);
    return res.data;
  }

  static async importChannels(): Promise<void> {
    await axios.post(`/apiv2/apps/slack/channels/import`);
  }
}
