import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UserShiftHandler } from '@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import _, { keyBy } from 'lodash';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { KeyedMutator } from 'swr';
import { v4 } from 'uuid';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as LargeSize } from '@/images/dashboard-icons/LargeSize.svg';
import { ReactComponent as Minus } from '@/images/dashboard-icons/Minus.svg';
import { ReactComponent as Plus } from '@/images/dashboard-icons/Plus.svg';
import { ReactComponent as SmallSize } from '@/images/dashboard-icons/SmallSize.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { DashboardLayoutLoader } from '@/v2/feature/dashboard/components/dashboard-layout-loader.component';
import { DashboardAPI, DashboardEndpoints } from '@/v2/feature/dashboard/dashboard.api';
import '@/v2/feature/dashboard/dashboard.scss';
import { editGridLayout, getStringArrayInOrder } from '@/v2/feature/dashboard/dashboard.util';
import '@/v2/feature/dashboard/features/dashboard-edit-mode.scss';
import { UserAttendance } from '@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.section';
import { UserCalendarVersionBig } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-big.section';
import { UserCalendarVersionSmall } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-small.section';
import { DashboardUserProfile } from '@/v2/feature/dashboard/features/sections/user-profile-widget/dashborad-user-profile.section';
import { UserTimePlannerBig } from '@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-big.section';
import { UserTimePlannerSmall } from '@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-small.section';
import { UserTodosVersionBig } from '@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-big.section';
import { UserTodosVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-small.section';
import {
  AddWidgetConfigurationByType,
  AddWidgetType,
  AllWidgetConfiguration,
  AttendanceWidget,
  CalendarWidgetBigWidget,
  CalendarWidgetSmallWidget,
  ProfileWidget,
  ShiftWidget,
  TimePlannerBigWidget,
  TimePlannerSmallWidget,
  TodoWidgetBig,
  TodoWidgetSmall,
  WidgetConfiguration,
  WidgetTypes,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// Wrap the GridLayout component with WidthProvider to handle responsive layouts
const ResponsiveGridLayout = WidthProvider(Responsive);

interface Item {
  content: JSX.Element;
  type: WidgetTypes;
}

export interface WidgetLayout {
  x: number;
  y: number;
  w: number;
  h: number;
  i: string;
  static: boolean;
  isBounded: boolean;
  isResizable: boolean;
}

export const DashboardEditMode = ({
  setEdit,
  refreshWidgetData,
  userConfig,
  refreshConfig,
  configLoading,
  configValidating,
}: {
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  refreshWidgetData?: () => Promise<void>;
  userConfig: WidgetConfiguration | null | undefined;
  refreshConfig: KeyedMutator<WidgetConfiguration> | undefined;
  configLoading: boolean | undefined;
  configValidating: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const [showDebounce, setShowDebounce] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowDebounce(true);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      onClick={async (e) => {
        setEdit(false);
        await refreshWidgetData?.();
        e.stopPropagation();
      }}
      style={{
        transition: 'all 0.3s ease',
        transitionProperty: 'opacity, top',
        opacity: showDebounce ? 1 : 0,
        top: showDebounce ? 0 : 500,
        position: 'absolute',
        left: 0,
        background: '#2F2F2F66',
        width: '100%',
        height: '100vh',
        zIndex: 3,
        overflowY: 'auto',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
      }}
    >
      <div
        style={{
          marginTop: '20px',
          position: 'relative',
        }}
      >
        <Suspense
          fallback={
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div className="grid-width" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant="title2" sx={{ color: themeColors.white, marginLeft: '20px' }}>
                  {polyglot.t('DashboardEditMode.edit')}
                </Typography>
              </div>
              <DashboardLayoutLoader userConfig={userConfig} />
            </div>
          }
        >
          <DashboardEditLayout
            userConfig={userConfig}
            refreshConfig={refreshConfig}
            configLoading={configLoading}
            configValidating={configValidating}
          />
        </Suspense>
      </div>
    </div>
  );
};

const DashboardEditLayout = ({
  userConfig,
  refreshConfig,
  configLoading,
  configValidating,
}: {
  userConfig: WidgetConfiguration | null | undefined;
  refreshConfig: KeyedMutator<WidgetConfiguration> | undefined;
  configLoading: boolean | undefined;
  configValidating: boolean;
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const { data: userWidget, mutate: refreshAllWidgetData } = useApiClient(DashboardEndpoints.getAllWidgets());
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const refreshWidgetData = useCallback(async () => {
    if (refreshAllWidgetData) await refreshAllWidgetData();
  }, [refreshAllWidgetData]);

  const { order, preference } = userConfig ?? {};

  const { data: allWidgetData } = userWidget ?? {};

  const allWidgetDataLookup = useMemo(() => {
    return keyBy(allWidgetData, 'type');
  }, [allWidgetData]);

  let editPreferenceIdLookupKey = useMemo(() => {
    return keyBy(preference, 'id');
  }, [preference]);

  const addPreferenceIdLookup: any = useMemo(() => {
    return keyBy(AddWidgetConfigurationByType, 'type');
  }, []);

  const allPreferenceIdLookup: any = useMemo(() => {
    return keyBy(AllWidgetConfiguration, 'type');
  }, []);

  const [globalState] = useContext(GlobalContext);
  const user = globalState.user;

  const initialRows: Item[] = useMemo(
    () => [
      ...(allWidgetDataLookup[WidgetTypes.USER_PROFILE_SMALL]
        ? [
            {
              type: WidgetTypes.USER_PROFILE_SMALL,
              content: (
                <DashboardUserProfile
                  user={user}
                  widgetData={(allWidgetDataLookup[WidgetTypes.USER_PROFILE_SMALL] as ProfileWidget)?.profile}
                  readOnly={true}
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_TODOS_BIG]
        ? [
            {
              type: WidgetTypes.USER_TODOS_BIG,
              content: (
                <UserTodosVersionBig
                  todos={(allWidgetDataLookup[WidgetTypes.USER_TODOS_BIG] as TodoWidgetBig)?.todosBig}
                  refreshtodoList={refreshWidgetData}
                  readOnly={true}
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_TODOS_SMALL]
        ? [
            {
              type: WidgetTypes.USER_TODOS_SMALL,
              content: (
                <UserTodosVersionSmall
                  todos={(allWidgetDataLookup[WidgetTypes.USER_TODOS_SMALL] as TodoWidgetSmall)?.todosSmall}
                  readOnly={true}
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_CALENDAR_BIG]
        ? [
            {
              type: WidgetTypes.USER_CALENDAR_BIG,
              content: (
                <UserCalendarVersionBig
                  calendarBig={
                    (allWidgetDataLookup[WidgetTypes.USER_CALENDAR_BIG] as CalendarWidgetBigWidget)?.calendarBig
                  }
                  readOnly={true}
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL]
        ? [
            {
              type: WidgetTypes.USER_CALENDAR_SMALL,
              content: (
                <UserCalendarVersionSmall
                  calendarSmall={
                    (allWidgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL] as CalendarWidgetSmallWidget)?.calendarSmall
                  }
                  readOnly={true}
                />
              ),
            },
          ]
        : []),

      ...(allWidgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG]
        ? [
            {
              type: WidgetTypes.USER_ATTENDANCE_BIG,
              content: (
                <UserAttendance
                  attendance={(allWidgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG] as AttendanceWidget)?.attendance}
                  readOnly={true}
                  refreshWidgetData={refreshWidgetData}
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]
        ? [
            {
              type: WidgetTypes.USER_SHIFT_SMALL,
              content: (
                <UserShiftHandler
                  attendanceSchedule={
                    (allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.attendanceSchedule
                  }
                  shift={(allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.todayShift}
                  userSite={(allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.userSite}
                  currentWeekAttendance={
                    (allWidgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.currentWeekAttendance
                  }
                  refresh={refreshWidgetData}
                  mode="widget"
                  readOnly
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL]
        ? [
            {
              type: WidgetTypes.USER_TIME_PLANNER_SMALL,
              content: (
                <UserTimePlannerSmall
                  userBalance={
                    (allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL] as TimePlannerSmallWidget)?.balancesSmall
                  }
                  readOnly
                />
              ),
            },
          ]
        : []),
      ...(allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG]
        ? [
            {
              type: WidgetTypes.USER_TIME_PLANNER_BIG,
              content: (
                <UserTimePlannerBig
                  userBalance={
                    (allWidgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG] as TimePlannerBigWidget)?.balancesBig
                  }
                  readOnly
                />
              ),
            },
          ]
        : []),
    ],
    [allWidgetDataLookup, user, refreshWidgetData]
  );

  const [editLayouts, setEditLayouts] = useState({ lg: editGridLayout(order, editPreferenceIdLookupKey) });
  const [editPreferenceIdLookup, setEditPreferenceIdLookup] = useState(editPreferenceIdLookupKey);
  const generateAddLayout = useCallback(() => {
    let x = 0;
    let y = 0;
    let countY = 0;
    return (AddWidgetType as string[]).map((widget) => {
      const widgetDetails = addPreferenceIdLookup[widget];
      const w = widgetDetails?.size === 'small' ? 4 : 8;

      if (x + w > 12) {
        x = 0;
        y += countY;
        countY = 0;
      }
      const layout = {
        x: x,
        y: y,
        w: w,
        h: 1,
        i: widget,
        static: widgetDetails.isStatic,
        isBounded: true,
        isResizable: false,
      };

      x += w;
      countY += w;

      return layout;
    });
  }, [addPreferenceIdLookup]);

  const addLayouts = useMemo(() => {
    return { lg: generateAddLayout() };
  }, [generateAddLayout]);

  const onLayoutChange = useCallback(
    async (currentLayout: Layout[]) => {
      try {
        setIsUpdating(true);
        const newOrder = getStringArrayInOrder(currentLayout);
        const newPrefence = Object.keys(editPreferenceIdLookup).map((key) => {
          return { ...editPreferenceIdLookup[key] };
        });
        const update = { order: newOrder, preference: newPrefence };
        setEditLayouts({ lg: editGridLayout(newOrder, keyBy(newPrefence, 'id')) });
        await DashboardAPI.updateWidgetSettings(update);
        refreshConfig?.();
      } catch (error) {
        showMessage(
          polyglot.t('DashboardEditLayout.errorMessages.update', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setIsUpdating(false);
      }
    },
    [polyglot, editPreferenceIdLookup, refreshConfig, showMessage]
  );

  const generateEditDOM = () => {
    return _.map(editLayouts.lg, function (l, i) {
      const widget = editPreferenceIdLookup[l.i];
      const type = widget.type;
      const component = initialRows.find((row) => row.type === type)?.content;
      return (
        <div
          key={l.i}
          id={`edit-widget-${i}`}
          style={{
            position: 'relative',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: -10,
                zIndex: 2,
              }}
            >
              <button
                className="widget-short-button"
                style={{
                  background: themeColors.white,
                  visibility: widget.isResizable ? 'visible' : 'hidden',
                }}
                disabled={configLoading || configValidating || isUpdating}
                onClick={() => {
                  if (type.includes('BIG')) {
                    editPreferenceIdLookup[l.i] = { ...widget, ...allPreferenceIdLookup[type.replace('BIG', 'SMALL')] };
                    setEditPreferenceIdLookup(editPreferenceIdLookup);
                    const newLayout = { lg: editGridLayout(order, editPreferenceIdLookup) };
                    setEditLayouts(newLayout);
                  }
                  if (type.includes('SMALL')) {
                    editPreferenceIdLookup[l.i] = { ...widget, ...allPreferenceIdLookup[type.replace('SMALL', 'BIG')] };
                    setEditPreferenceIdLookup(editPreferenceIdLookup);
                    const newLayout = { lg: editGridLayout(order, editPreferenceIdLookup) };
                    setEditLayouts(newLayout);
                  }
                }}
              >
                {type.includes('BIG') ? (
                  <SmallSize {...iconSize} fill={themeColors.DarkGrey} />
                ) : (
                  <LargeSize {...iconSize} fill={themeColors.DarkGrey} />
                )}
              </button>

              <button
                className="widget-short-button"
                style={{
                  background: themeColors.white,
                  visibility: widget.isDeletable ? 'visible' : 'hidden',
                  marginTop: spacing.m10,
                }}
                disabled={configLoading || configValidating || isUpdating}
                onClick={() => {
                  const newEditPreferenceIdLookup = { ...editPreferenceIdLookup };
                  delete newEditPreferenceIdLookup[l.i];
                  setEditPreferenceIdLookup(newEditPreferenceIdLookup);

                  const newLayout = editLayouts.lg.filter((item) => item.i !== l.i);
                  setEditLayouts({ lg: newLayout });
                }}
              >
                <Minus {...iconSize} />
              </button>
            </div>
            {component}
          </div>
        </div>
      );
    });
  };

  const generateAddDOM = () => {
    return _.map(addLayouts.lg, function (l, i) {
      const widget = addPreferenceIdLookup[l.i];
      const component = initialRows.find((row) => row.type === widget.type)?.content;
      return (
        <div
          key={l.i}
          id={`add-widget-${i}`}
          style={{
            position: 'relative',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <button
              className="widget-short-button"
              style={{
                position: 'absolute',
                visibility: widget.isResizable ? 'visible' : 'hidden',
                top: 0,
                right: -10,
                background: themeColors.Green,
                fill: themeColors.white,
                zIndex: 2,
              }}
              disabled={configLoading || configValidating || isUpdating}
              onClick={async () => {
                const widgetToAdd = { id: v4(), ...widget };
                const widgetLayout: WidgetLayout = {
                  x: 0,
                  y: Math.max(...editLayouts.lg.map((layout) => layout.y)) + 1,
                  w: widgetToAdd?.size === 'small' ? 4 : 8,
                  h: 1,
                  i: widgetToAdd.id,
                  static: widgetToAdd.isStatic as boolean,
                  isBounded: true,
                  isResizable: false,
                };

                setEditPreferenceIdLookup((prevLookup) => ({
                  ...prevLookup,
                  [widgetToAdd.id]: {
                    id: widgetToAdd.id,
                    type: widgetToAdd.type,
                    size: widget?.size,
                    isStatic: widget.isStatic,
                    isResizable: widget.isResizable,
                    isDeletable: widget.isDeletable,
                  },
                }));

                setEditLayouts((prevLayouts) => ({
                  ...prevLayouts,
                  lg: [...prevLayouts.lg, widgetLayout],
                }));
              }}
            >
              <Plus {...iconSize} fill={themeColors.white} />
            </button>
            {component}
          </div>
        </div>
      );
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="grid-width" style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant="title2" sx={{ color: themeColors.white, marginLeft: '20px' }}>
          {polyglot.t('DashboardEditLayout.edit')}
        </Typography>
      </div>
      <ResponsiveGridLayout
        className="layout grid-width"
        layouts={editLayouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
        measureBeforeMount={true}
        rowHeight={280}
        useCSSTransforms={false}
        isDroppable={true}
        onLayoutChange={onLayoutChange}
        margin={[30, 30]}
      >
        {generateEditDOM()}
      </ResponsiveGridLayout>

      <div className="grid-width" style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant="title2" sx={{ color: themeColors.white, marginLeft: '20px' }}>
          {polyglot.t('DashboardEditLayout.add')}
        </Typography>
      </div>
      <ResponsiveGridLayout
        className="layout grid-width"
        layouts={{ lg: generateAddLayout() }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
        measureBeforeMount={false}
        rowHeight={280}
        isDraggable={false}
        isResizable={false}
        useCSSTransforms={true}
        preventCollision={true}
        margin={[30, 30]}
      >
        {generateAddDOM()}
      </ResponsiveGridLayout>
    </div>
  );
};
