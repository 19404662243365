import React from 'react';

import { TextFieldProps, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik/dist/types';

import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type FormikLike = {
  values: Record<string, unknown>;
  touched: Record<string, FormikTouched<unknown>>;
  errors: Record<string, FormikErrors<unknown>>;
  handleChange: (e: React.ChangeEvent) => void;
  setFieldValue: (name: string, value: any, shouldValidate?: boolean | undefined) => void;
};

type PayrollSettingProps = TextFieldProps & {
  name: string;
  tooltip?: string | JSX.Element;
  formik: FormikLike;
  mode?: 'text' | 'label' | 'date' | 'custom' | OptionObject[];
  customFormat?: (value: string, formik: FormikLike) => string;
  dateRange?: { min?: string; max?: string };
  component?: React.ReactNode;
};

export const PayrollSetting = ({
  name,
  label,
  formik,
  tooltip,
  type = 'text',
  autoComplete = 'off',
  autoFocus,
  disabled,
  mode = 'text',
  customFormat,
  dateRange,
  component,
}: PayrollSettingProps) => (
  <>
    <StyledTooltip title={tooltip || ''}>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.m15 }}>{label}</Typography>
    </StyledTooltip>
    {mode === 'label' && (
      <Typography sx={{ ...themeFonts.title4, mt: spacing.m15, whiteSpace: 'nowrap' }}>
        {(customFormat?.(formik.values[name] as string, formik) ?? (formik.values[name] || '')) as string}
      </Typography>
    )}
    {mode === 'text' && (
      <TextfieldComponent
        name={name}
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={formik.handleChange}
        clearText={() => formik.setFieldValue(name, '')}
        value={formik.values[name] || ''}
        error={!!formik.touched[name] && !!formik.errors[name]}
        helperText={formik.touched[name] && formik.errors[name]?.toString()}
        sx={{ mt: spacing.m15 }}
      />
    )}
    {mode === 'date' && (
      <DatePickerComponent
        name={name}
        disabled={disabled}
        onChange={(newValue) => {
          formik.setFieldValue(name, newValue);
        }}
        minDate={dateRange?.min}
        maxDate={dateRange?.max}
        value={formik.values[name] as string}
        error={!!formik.touched[name] && !!formik.errors[name]}
        helperText={formik.touched[name] && formik.errors[name]?.toString()}
        sx={{ mt: spacing.m15 }}
      />
    )}
    {mode === 'custom' && component}
    {typeof mode !== 'string' && (
      <SelectComponent
        name={name}
        options={mode}
        value={formik.values[name] ? String(formik.values[name]) : ''}
        onChange={formik.handleChange}
        disabled={disabled}
        sx={{ mt: spacing.m15 }}
      />
    )}
  </>
);
