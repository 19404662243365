import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { UserProfileBar } from '@v2/feature/user/components/user-profile-bar.component';
import { UserAPI } from '@v2/feature/user/user.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import * as d3 from 'd3';
import { OrgChart as D3OrgChart } from 'd3-org-chart';
import { jsPDF } from 'jspdf';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as CleanCircle } from '@/images/side-bar-icons/CleanCircle.svg';
import { nestErrorMessage } from '@/lib/errors';
import { USER_PERSONAL_TAB } from '@/lib/routes';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SwitchButton } from '@/v2/components/switch-button.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { FilterTypesProps } from '@/v2/components/table/table-filter.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ProfileModal } from '@/v2/components/theme-components/profile-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { getEmploymentTypeOptions } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { OrgChartProps } from '@/v2/feature/user/pages/components/people-org-view.component';
import { filterStringToObject } from '@/v2/feature/user/user.util';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';
import { isDefined } from '@/v2/util/string.util';

export const OrgChartComponent = ({
  data,
  departments,
  generalSettings,
}: {
  data: OrgChartProps[];
  departments: CompanyDepartmentDto[] | null | undefined;
  generalSettings: GeneralSettingsDto | null | undefined;
}): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [globalState, dispatch] = useContext(GlobalContext);
  const { user: currentUser } = globalState;

  const [error, setError] = useState(null);
  const routerHistory = useHistory();
  const [chart, setChart] = useState<any>(null);
  const d3Container = useRef(null);

  const [selectedUserId, setSetSelectedUserId] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');

  const currentFilterString = isDefined(currentUser.features?.user?.orgChart?.orgView)
    ? currentUser.features?.user?.orgChart?.orgView
    : 'vertical';

  const [showMode, setShowMode] = useState<string>(currentFilterString ?? 'vertical');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  useEffect(() => {
    const scripts = [
      'https://d3js.org/d3.v7.min.js',
      'https://cdn.jsdelivr.net/npm/d3-org-chart@3.1.0',
      'https://cdn.jsdelivr.net/npm/d3-flextree@2.1.2/build/d3-flextree.js',
    ];

    scripts.forEach((src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    });
  }, []);

  const getInitials = (name?: string) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    if (nameParts.length === 1) {
      return (name.length >= 2 ? name.substring(0, 2) : name[0]).toUpperCase();
    }

    return `${nameParts[0][0] ?? 'N'}${nameParts[1][0] ?? 'A'}`.toUpperCase();
  };

  function stringToColor(avatarName?: string): string {
    if (!avatarName) return 'white';
    let hash = 0;
    let i;
    for (i = 0; i < avatarName.length; i += 1) {
      hash = avatarName.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return `${color}5c`;
  }

  const hasCycleErrors = (orgchartData: { tags: string }[]) => {
    return orgchartData.some((eachUser) => ['cycle_error_root', 'cycle_error_reportee'].includes(eachUser.tags));
  };

  const filterByDepartment = (users: OrgChartProps[], validValues: string[]) => {
    return users.filter((user: any) => user.areaId && validValues.includes(user.areaId?.toString()));
  };

  const filterByEntity = (users: OrgChartProps[], validValues: string[]) => {
    return users.filter((user: any) => user.entity && validValues.includes(user.entity?.toString()));
  };

  const filterByWorkerType = (users: OrgChartProps[], validValues: string[]) => {
    return users.filter((user: any) => user.workerType && validValues.includes(user.workerType?.toString()));
  };

  const filterByName = (users: OrgChartProps[], query: string) => {
    return users.filter((user: any) => user.name && user.name.toLowerCase().includes(query.toLowerCase()));
  };

  const getFiltereData = (
    orgchartData: OrgChartProps[],
    filterString: string,
    searchInput: string
  ): Set<number> | undefined => {
    let data = orgchartData;

    if (filterString && data) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case 'Department': {
              data = filterByDepartment(data, filterOptions[key]);
              break;
            }

            case 'Entity': {
              data = filterByEntity(data, filterOptions[key]);
              break;
            }

            case 'Worker type': {
              data = filterByWorkerType(data, filterOptions[key]);
              break;
            }

            default:
              break;
          }
        }
      }
    }

    if (searchInput && data) {
      data = filterByName(data, searchInput);
    }

    return data ? new Set(data.map((d) => Number(d.id))) : undefined;
  };

  function filterChart(searchInput: string, filterString: string) {
    chart.clearHighlighting();
    let data = chart.data();

    const filteredIds = getFiltereData(data, filterString, searchInput);

    if (!filteredIds) return;
    data.forEach((d: any) => (d._expanded = false));

    data.forEach((d: any) => {
      if ((filterString !== '' || searchInput !== '') && filteredIds.has(d.id)) {
        d._highlighted = true;
        d._expanded = true;
      }
    });

    chart.data(data).expandLevel(2).render().fit();
  }

  function highlightMe() {
    setFilterString('');
    setSearchInput('');
    chart.clearHighlighting();

    const data = chart.data();

    data.forEach((d: any) => (d._expanded = false));

    data.forEach((d: any) => {
      if (currentUser && d.id === currentUser.userId) {
        d._highlighted = true;
        d._expanded = true;
      }
    });

    chart.data(data).expandLevel(2).render().fit();
  }

  useLayoutEffect(() => {
    try {
      const newChart = new D3OrgChart();
      if (d3Container.current) {
        newChart
          .nodeHeight((_) => 85 + 25)
          .nodeWidth((_) => 220 + 2)
          .childrenMargin((_) => 50)
          .compactMarginBetween((_) => 35)
          .compactMarginPair((_) => 30)
          .neightbourMargin((_) => 20)
          .nodeUpdate(function (d: any) {
            // Disable default highlight behavior by modifying the node's rect stroke
            const node = d3.select(`[data-id='${d.id}']`).select('.node-rect');
            node.attr('stroke', 'none');
          })
          .nodeContent(function (d: any): string {
            const color = '#FFFFFF';
            const imageDiffVert = 15 + 2;
            const avatar =
              d.data.avatar && d.data.avatar !== ''
                ? `<img src='${
                    d.data.avatar
                  }' style='margin-left:${20}px;border-radius:100px;width:40px;height:40px;' />`
                : `<div style='margin-left:${20}px;border-radius:100px;width:40px;height:40px;background-color: ${stringToColor(
                    d.data?.name
                  )};'>
                 <span style='width:100%;height:100%;display:flex;justify-content:center;align-items:center;font-size:20px;font-weight: 500; color: #fff; text-transform: uppercase; font-family: Inter, sans-serif; letter-spacing: -0.5px; line-height: 30px;'>
                  ${d.data.name ? getInitials(d.data.name) : 'NA'}
                 </span>
               </div>`;
            const userDetail =
              (d.data?.positionName && d.data?.positionName !== '') || (d.data?.office && d.data?.office !== '')
                ? `${d.data?.positionName || '–'}, ${d.data?.office || '–'}`
                : '';
            return `
          <div style='width:${
            d.width
          }px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px;'>
                  <div style="font-family: 'Inter', sans-serif;background-color:${color};  margin-left:-1px;width:${d.width - 2}px;height:${d.height - imageDiffVert}px;border-radius:10px;border:${d.data._highlighted ? `4px solid ${themeColors.ZeltYellow}` : '1px solid #E4E2E9'};">
                      <div style='background-color:${color};margin-top:${-imageDiffVert - 20}px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;' ></div>
                      <div style='margin-top:${-imageDiffVert - 20}px;'>  ${avatar}</div>
                      <div style='font-size:15px;color:#08011E;margin-left:20px;margin-top:10px'> ${d.data.name} </div>
                      <div style='color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;'> ${userDetail} </div>
                  </div>
              </div>`;
          })
          .onNodeClick((d) => {
            const data = (d as unknown) as number;
            if (data !== 999999) {
              setIsOpen(true);
              setSetSelectedUserId(data);
            }
          })
          .compact(showMode === 'vertical')
          .container(d3Container.current)
          .data(data)
          .render()
          .fit();

        setChart(newChart);
      }
    } catch (error) {
      console.error('Encountered an error while trying to render the orgchart: ', error);
      setError(error);
    }
  }, [polyglot, data, showMode]);

  async function downloadPdf() {
    const pdf = new jsPDF();
    if (chart) {
      chart.exportImg({
        full: true,
        save: false,
        onLoad: (base64: string) => {
          const img = new Image();
          img.src = base64;
          img.onload = function () {
            pdf.addImage(img, 'JPEG', 5, 5, 595 / 3, ((img.height / img.width) * 595) / 3);
            pdf.save('organizational-chart.pdf');
          };
        },
      });
    }
  }

  async function downloadCsv() {
    const allFiles = await UserAPI.exportReportById();
    const csvData = new Blob([allFiles], { type: 'text/csv;charset=utf-8;' });

    let csvURL;
    const fileName = `users-${new LocalDate().toDateString()}.csv`;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    const tempLink = document.createElement('a');
    if (typeof csvURL === 'string') {
      tempLink.href = csvURL;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
    } else {
      showMessage(polyglot.t('OrgChartComponent.errorMessages.export'), 'error');
    }
  }

  const FilterTypes: FilterTypesProps = {
    ...(departments && departments?.length > 0
      ? {
          [polyglot.t('OrgChartComponent.departmentFilter')]: departments.map((d) => ({
            value: d.id,
            label: polyglot.t(d.name),
          })),
        }
      : {}),
    ...(generalSettings?.entities
      ? {
          [polyglot.t('OrgChartComponent.entityFilter')]: generalSettings?.entities.map((entity) => {
            return { label: polyglot.t(entity.legalName), value: entity.id.toString() };
          }),
        }
      : {}),

    [polyglot.t('OrgChartComponent.workerTypeFilter')]: getEmploymentTypeOptions(polyglot) as {
      readonly label: string;
      readonly value: number;
    }[],
  };

  const getDownloadActions = () => {
    return [
      {
        handler: async () => await downloadPdf(),
        label: 'PDF',
        disabled: false,
      },
      {
        handler: () => chart?.exportImg({ full: true }),
        label: 'PNG',
        disabled: false,
      },
      {
        handler: async () => await downloadCsv(),
        label: 'CSV',
        disabled: false,
      },
    ];
  };

  const viewOptions = [
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
  ];

  const updateUserFeaturesOrgView = useCallback(
    async (mode: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('user', 'orgChart', 'orgView', mode);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: spacing.p16 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
              filterChart(e.target.value, filterString);
            }}
          />
          <CategoryFilters
            filterTypes={FilterTypes}
            setFilterString={(filterString: string) => {
              setFilterString(filterString);
              filterChart(searchInput, filterString);
            }}
            filterString={filterString}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
          <StyledMenuComponent
            options={getDownloadActions()}
            sx={{ borderRadius: radius.br20 }}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: polyglot.t('General.export'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingY: spacing.p16,
          borderTop: borders.light,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
          <ButtonComponent
            sizeVariant="small"
            colorVariant={'secondary'}
            onClick={highlightMe}
            style={{ borderRadius: radius.br16, padding: `${spacing.p4}px 0px` }}
          >
            Find me
          </ButtonComponent>

          <ButtonComponent
            sizeVariant="small"
            colorVariant={'secondary'}
            onClick={() => {
              if (chart) {
                chart.expandAll().fit();
              }
            }}
            style={{ borderRadius: radius.br16, padding: `${spacing.p4}px 0px` }}
          >
            Expand all
          </ButtonComponent>

          <ButtonComponent
            sizeVariant="small"
            colorVariant={'secondary'}
            onClick={() => {
              if (chart) {
                chart.collapseAll().fit();
              }
            }}
            style={{ borderRadius: radius.br16, padding: `${spacing.p4}px 0px` }}
          >
            Collapse all
          </ButtonComponent>
        </Box>
        <SwitchButton
          selectedValue={showMode}
          color={themeColors.DarkGrey}
          action={(value: string) => {
            if (chart) {
              chart
                .compact(value === 'vertical')
                .render()
                .fit();
              setShowMode(value);
              updateUserFeaturesOrgView(value);
            }
          }}
          options={viewOptions}
        />
      </Box>

      <Snackbar
        open={
          (data?.length > 0 && !error && hasCycleErrors(data)) ||
          (data && data?.length === 0 && !error) ||
          Boolean(error)
        }
        onClose={undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiAlert
          sx={{
            width: '100%',
            backgroundColor: themeColors.Red,
            borderRadius: radius.br10,
            boxShadow: 'none',
            maxWidth: '600px',
          }}
          elevation={10}
          variant="filled"
          icon={false}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: spacing.g10 }}>
            <Box sx={{ mt: spacing.m3 }}>
              <CleanCircle style={{ fill: themeColors.white }} width={16} height={16} />
            </Box>
            {data?.length > 0 && !error && hasCycleErrors(data) && (
              <Typography
                variant="caption"
                color="white"
                sx={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g10,
                }}
              >
                {polyglot.t('OrgChartComponent.resolve')}
              </Typography>
            )}

            {data && data?.length === 0 && !error && (
              <Typography
                variant="caption"
                color="white"
                sx={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g10,
                }}
              >
                {polyglot.t('OrgChartComponent.noData')}
              </Typography>
            )}

            {error && (
              <Typography
                variant="caption"
                color="white"
                sx={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g10,
                }}
              >
                {polyglot.t('OrgChartComponent.toView')}
              </Typography>
            )}
          </Box>
        </MuiAlert>
      </Snackbar>

      <div ref={d3Container}></div>

      <ProfileModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
          setSetSelectedUserId(null);
        }}
        openProfile={() => {
          if (selectedUserId) routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: selectedUserId }));
        }}
        openInNewWindow={() => {
          if (selectedUserId) window.open(generatePath(USER_PERSONAL_TAB, { userId: selectedUserId }), '_blank');
        }}
      >
        {selectedUserId ? <UserProfileBar userId={selectedUserId} /> : undefined}
      </ProfileModal>
    </Box>
  );
};
