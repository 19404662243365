import { Box, SxProps, Theme, Typography } from '@mui/material';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { ReactComponent as XeroLogo } from '@/images/xero_logo.svg';
import { ReactComponent as XeroLogoDisabled } from '@/images/xero_logo_disabled.svg';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { ContractorInvoiceStatus } from '@/v2/feature/payments/payments.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const getContractorInvoiceStatusComponent = (
  status: string,
  statusSx: SxProps<Theme>,
  accountingAppConfigured = false,
  accountingAppSyncStatus = false
) => {
  const xeroLogoComponent =
    accountingAppSyncStatus === true ? (
      <StyledTooltip title="Synced to Xero">
        <XeroLogo {...iconSize} />
      </StyledTooltip>
    ) : (
      <StyledTooltip title="Not synced to Xero">
        <XeroLogoDisabled {...iconSize} />
      </StyledTooltip>
    );
  switch (status) {
    case ContractorInvoiceStatus.Pending:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          {accountingAppConfigured && xeroLogoComponent}
          <Waiting {...iconSize} style={{ fill: themeColors.middleGrey }} />
          <Typography sx={{ color: themeColors.Grey, ...statusSx }}>{ContractorInvoiceStatus.Pending}</Typography>
        </Box>
      );
    case ContractorInvoiceStatus.Approved:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          {accountingAppConfigured && xeroLogoComponent}
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          <Typography sx={statusSx}>{ContractorInvoiceStatus.Approved}</Typography>
        </Box>
      );
    case ContractorInvoiceStatus.Rejected:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          {accountingAppConfigured && xeroLogoComponent}
          <Rejected {...iconSize} fill={themeColors.Red} />
          <Typography sx={statusSx}>{ContractorInvoiceStatus.Rejected}</Typography>
        </Box>
      );
    case ContractorInvoiceStatus.Voided:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          {accountingAppConfigured && xeroLogoComponent}
          <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
          <Typography sx={statusSx}>{ContractorInvoiceStatus.Voided}</Typography>
        </Box>
      );
    case ContractorInvoiceStatus.Draft:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <EditIcon {...iconSize} />
          <Typography sx={statusSx}>{ContractorInvoiceStatus.Draft}</Typography>
        </Box>
      );
    default:
      return <></>;
  }
};
