import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { capitalize } from 'lodash';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { isTimingCorrectlySet } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/rc-upsert-launch.util';
import { getDayOrDays } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/rc-upsert-timeline.page';
import { countFilters } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewCycleLaunch } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { spacing } from '@/v2/styles/spacing.styles';

interface ILaunchRolling {
  readonly reviewCycleLaunchDetails: ReviewCycleLaunch;
  readonly cycleId: string;
  readonly goBack: () => void;
  readonly showFlags: { showSelf: boolean; showUpward: boolean; showPeer: boolean; showManager: boolean };
}
export const LaunchRolling = ({ reviewCycleLaunchDetails, cycleId, goBack, showFlags }: ILaunchRolling) => {
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { timelineSettings } = reviewCycleLaunchDetails;

  const showActionButtons = useMemo(
    () =>
      reviewCycleLaunchDetails &&
      reviewCycleLaunchDetails.state === CycleState.Draft &&
      reviewCycleLaunchDetails.totalQuestions > 0 &&
      reviewCycleLaunchDetails.enrolmentTriggerFilters &&
      Object.keys(reviewCycleLaunchDetails.enrolmentTriggerFilters).length > 0 &&
      isTimingCorrectlySet(reviewCycleLaunchDetails.timelineSettings, reviewCycleLaunchDetails.reviewerSelect),
    [reviewCycleLaunchDetails]
  );

  const activateReviewCycle = async () => {
    try {
      setLoading(true);
      await ReviewCycleAPI.activateReviewCycle(cycleId);
      goBack();
      showMessage('Successfully launched the cycle', 'success');
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const hasEnrolmentTrigger = useMemo(
    () =>
      Boolean(
        reviewCycleLaunchDetails.enrolmentTriggerFilters &&
          reviewCycleLaunchDetails.enrolmentTriggerFilters !== null &&
          Object.keys(reviewCycleLaunchDetails.enrolmentTriggerFilters).length > 0
      ),
    [reviewCycleLaunchDetails]
  );

  return (
    <SettingsSubsectionContent
      sections={[
        {
          contentWidth: '100%',
          headerWidth: '100%',
          title: 'Start review cycle',
          items: [
            {
              type: SectionItemType.Pair,
              label: 'Review type',
              value: <Typography variant="title4">{capitalize(reviewCycleLaunchDetails.type)}</Typography>,
            },
            {
              type: SectionItemType.Pair,
              label: 'Number of questions',
              value: (
                <Typography
                  variant="title4"
                  color={reviewCycleLaunchDetails.totalQuestions > 0 ? 'DarkGrey' : 'RedDark'}
                >
                  {reviewCycleLaunchDetails.totalQuestions || 'Please add some questions'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Roles',
              value: (
                <Typography variant="title4">
                  {reviewCycleLaunchDetails.reviewerSelect?.toString()?.replaceAll(',', ', ') ||
                    'Please add some questions to determine roles'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Currently qualifying',
              value: (
                <Typography variant="title4">
                  {hasEnrolmentTrigger
                    ? reviewCycleLaunchDetails.participantCount > 0
                      ? `${reviewCycleLaunchDetails.participantCount} participant(s)`
                      : 'No participants qualify the enrolment trigger'
                    : 'Please add participant(s) by creating an enrolment trigger'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Filters',
              value: (
                <Typography variant="title4" color={hasEnrolmentTrigger ? 'DarkGrey' : 'RedDark'}>
                  {hasEnrolmentTrigger
                    ? `${countFilters(reviewCycleLaunchDetails.enrolmentTriggerFilters)} filter(s)`
                    : 'Please create an enrolment trigger'}
                </Typography>
              ),
            },

            ...(showFlags.showSelf
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Self review deadline',
                    value: timelineSettings?.selfReviewDeadline ? (
                      `${timelineSettings?.selfReviewDeadline} ${getDayOrDays(
                        timelineSettings?.selfReviewDeadline
                      )} after enrolment date`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(showFlags.showUpward
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Upward review deadline',
                    value: timelineSettings?.upwardReviewDeadline ? (
                      `${timelineSettings?.upwardReviewDeadline} ${getDayOrDays(
                        timelineSettings?.upwardReviewDeadline
                      )} after enrolment date`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(showFlags.showPeer
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Peer review deadline',
                    value: timelineSettings?.peerReviewDeadline ? (
                      `${timelineSettings?.peerReviewDeadline} ${getDayOrDays(
                        timelineSettings?.peerReviewDeadline
                      )} after enrolment date`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(showFlags.showManager
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: 'Manager review deadline',
                    value: timelineSettings?.managerReviewDeadline ? (
                      `${timelineSettings?.managerReviewDeadline} ${getDayOrDays(
                        timelineSettings?.managerReviewDeadline
                      )} after enrolment date`
                    ) : (
                      <Typography variant="title4" color="RedDark">
                        Please provide a suitable deadline
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              type: SectionItemType.Component,
              value: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g4, mt: spacing.m8 }}>
                  <div>
                    <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => goBack()}>
                      Save and close
                    </ButtonComponent>
                  </div>
                  {showActionButtons && (
                    <LoaderButton
                      sizeVariant="small"
                      colorVariant="primary"
                      onClick={activateReviewCycle}
                      loading={loading}
                      disabled={loading}
                    >
                      Activate cycle
                    </LoaderButton>
                  )}
                </Box>
              ),
              hidden: Boolean(
                reviewCycleLaunchDetails &&
                  reviewCycleLaunchDetails.state &&
                  reviewCycleLaunchDetails.state !== CycleState.Draft
              ),
            },
          ],
        },
      ]}
    />
  );
};
