import { CompanyConfigDto } from '@v2/feature/company-config/company-config.dto';
import axios from 'axios';

import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class CompanyConfigAPI {
  static async disableOrEnableTestModeAsSuperAdmin(companyId: number): Promise<void> {
    await axios.patch(`/apiv2/company-config/superadmin/${companyId}/test-mode/state-change`);
  }

  static async disableOrEnableTestMode(): Promise<void> {
    await axios.patch(`/apiv2/company-config/test-mode/state-change`);
  }
}

export class CompanyConfigEndpoints {
  static getCompanyConfig(): Endpoint<CompanyConfigDto> {
    return {
      url: '/apiv2/company-config',
    };
  }
}
