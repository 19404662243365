import { useMemo } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { CompanyPayroll, PayRunSchedule } from '@shared/modules/payroll/payroll.types';
import { useHistory } from 'react-router-dom';

import { PAYROLL_COMPANY_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { SideMenuHeader } from '@/v2/feature/payroll/components/side-menu-header.component';
import { formatPayrunPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrunStatus } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component';
import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunListItemSecondLine = { state: PayrunStates; employeeCount: number } | { upcoming: boolean };

const PayrunListItem = ({
  schedule,
  secondLine,
  selected,
  disabled,
  onClick,
}: {
  schedule: PayRunSchedule;
  secondLine: PayrunListItemSecondLine;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  const { polyglot } = usePolyglot();
  const color = selected ? undefined : themeColors.Grey;
  return (
    <Stack
      sx={{
        p: spacing.p10,
        gap: spacing.g1,
        borderRadius: radius.br10,
        ...(!disabled && {
          cursor: 'pointer',
          ':hover': { backgroundColor: themeColors.GreyHover },
          transition: 'background-color .2s linear',
        }),
        background: selected ? themeColors.Background : null,
        minWidth: '220px',
      }}
      onClick={() => !disabled && onClick?.()}
    >
      <Typography variant={selected ? 'title4' : 'caption'}>
        {formatPayrunPeriod(schedule, polyglot.locale())}
      </Typography>
      <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5, color }}>
        {'state' in secondLine && (
          <>
            <PayrunStatus variant="captionSmall" iconSize={13} color="inherit" state={secondLine.state} />
            <Typography variant="captionSmall" sx={{ color: 'inherit' }}>
              &bull;
            </Typography>
            <Typography variant="captionSmall" sx={{ color: 'inherit' }}>
              {polyglot.t('CompanyPayroll.noOfEmployees', secondLine.employeeCount)}
            </Typography>
          </>
        )}
        {'upcoming' in secondLine && (
          <>
            <Typography variant="captionSmall" sx={{ color: 'inherit' }}>
              {polyglot.t('CompanyPayroll.upcoming')}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};

type PayrunListProps = {
  payroll: CompanyPayroll;
  selectedPayrunId?: number | 'next-payrun';
  onPayrunSelected?: (payrunId: number | 'next-payrun') => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const PayrunListSideMenu = ({ disabled, payroll, onPayrunSelected, selectedPayrunId, sx }: PayrunListProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const sortedPayruns = useMemo(() => {
    const payruns = payroll.employer ? payroll.payruns : payroll.globalPayruns;
    return payruns.slice().sort((a, b) => b.taxYear.localeCompare(a.taxYear) || b.period - a.period);
  }, [payroll]);

  return (
    <Stack sx={sx}>
      <SideMenuHeader
        heading={payroll.displayName}
        caption={polyglot.t('CompanyPayroll.allPayruns')}
        onBackClick={() => routerHistory.replace(PAYROLL_COMPANY_ROUTE)}
        sx={{ mt: spacing.mt20 }}
      />
      <Stack sx={{ overflow: 'hidden auto' }}>
        <Stack sx={{ my: spacing.my20, gap: spacing.g2 }}>
          {payroll.nextPayRun && !payroll.currentPayRun && (
            // this is shown when there are no currently open payruns
            <PayrunListItem
              schedule={payroll.nextPayRun}
              secondLine={{ upcoming: true }}
              selected={selectedPayrunId === 'next-payrun'}
              disabled={disabled}
              onClick={() => onPayrunSelected?.('next-payrun')}
            />
          )}
          {sortedPayruns.map((payrun) => (
            <PayrunListItem
              key={payrun.id}
              schedule={payrun}
              secondLine={{
                state: payrun.state as PayrunStates,
                employeeCount: payrun.employeeCount,
              }}
              selected={payrun.id === selectedPayrunId}
              disabled={disabled}
              onClick={() => onPayrunSelected?.(payrun.id)}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
