import { Dispatch, SetStateAction, useMemo } from 'react';

import { Box, InputAdornment } from '@mui/material';
import { Form, FormikProvider } from 'formik';

import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TimeTextField } from '@/v2/components/forms/time-text-field.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { useSCTimelineForm } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/hook/use-sc-timeline-form.hook';
import { SurveyCycle, SurveyFrequencyEnum } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SCScheduledTimelineModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  surveyCycle: SurveyCycle | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCScheduledTimelineContent surveyCycle={surveyCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

const SCScheduledTimelineContent = ({
  surveyCycle,
  onClose,
  refresh,
}: {
  surveyCycle: SurveyCycle | null | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const formik = useSCTimelineForm(surveyCycle, onClose, refresh);
  const Views = [
    { name: 'Start now', value: 'true' },
    { name: 'Schedule for later', value: 'false' },
  ];

  const surveyFrequencyOptions = useMemo(() => {
    return Object.entries(SurveyFrequencyEnum).map(([value, label]) => ({
      value,
      label,
    }));
  }, []) as OptionObject[];

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Timeline</Typography>

        <Box sx={{ mt: spacing.m5, mb: spacing.m10 }}>
          <TabFilterButtons
            filters={Views}
            setFilterValue={(value: string) => {
              if (value === 'true') {
                formik.setFieldValue('timelineSettings.startDate', null);
                formik.setFieldValue('timelineSettings.startTime', null);
                formik.setFieldValue('timelineSettings.startTimeMeridiem', null);
              }

              formik.setFieldValue('timelineSettings.startNow', value === 'true');
            }}
            filterValue={formik.values.timelineSettings.startNow.toString()}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
          <TextfieldComponent
            name="timelineSettings.surveyOpenFor"
            label={polyglot.t('SurveyParticipantDrawer.surveyOpenFor')}
            value={formik.values.timelineSettings.surveyOpenFor}
            onChange={formik.handleChange}
            error={formik.touched.timelineSettings?.surveyOpenFor && !!formik.errors.timelineSettings?.surveyOpenFor}
            helperText={
              (formik.touched.timelineSettings?.surveyOpenFor && formik.errors.timelineSettings?.surveyOpenFor) ?? ' '
            }
            endAdornment={
              <InputAdornment position="end">
                <Typography variant="caption">days</Typography>
              </InputAdornment>
            }
            type="number"
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
          <SelectComponent
            name="timelineSettings.surveyFrequency"
            label={polyglot.t('SurveyParticipantDrawer.surveyFrequency')}
            options={surveyFrequencyOptions}
            value={formik.values.timelineSettings.surveyFrequency}
            onChange={formik.handleChange}
            compareValue={formik.values.timelineSettings.surveyFrequency}
            disabled
          />
        </Box>

        {!formik.values.timelineSettings.startNow && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
            <DatePickerComponent
              name="timelineSettings.startDate"
              label="Start date"
              sx={{ width: '100%' }}
              disablePast
              value={formik.values.timelineSettings.startDate}
              onChange={(date) => formik.setFieldValue('timelineSettings.startDate', date)}
            />
          </Box>
        )}

        {!formik.values.timelineSettings.startNow && (
          <TimeTextField
            name="timelineSettings.startTime"
            label="Start time (GMT)"
            value={formik.values.timelineSettings.startTime}
            onChange={(value) => {
              formik.setFieldValue('timelineSettings.startTime', value);
            }}
            selectedMeridiem={formik.values.timelineSettings.startTimeMeridiem}
            sx={{ width: '100%' }}
            onMeridiemChange={(newMeridiem) => {
              formik.setFieldValue('timelineSettings.startTimeMeridiem', newMeridiem);
            }}
            error={formik.touched.timelineSettings?.startTime && Boolean(formik.errors.timelineSettings?.startTime)}
            helperText={
              (formik.touched.timelineSettings?.startTime && formik.errors.timelineSettings?.startTime) as string
            }
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
