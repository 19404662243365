import { useCallback } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { FormikErrors } from 'formik';

import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { MultipleSelectCheckbox, MultiSelectOption } from '@/v2/components/forms/multiple-select-checkbox.component';
import {
  AppIntegrationUserDto,
  Dictionary,
  GoogleGroupMember,
  GroupId,
  GroupMember,
  JiraGroupMember,
  Microsoft365GroupMember,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  AppIntegrationStub,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
} from '@/v2/feature/app-integration/app-integration.interface';
import {
  AppGroupManagementFormData,
  AppStub,
} from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.page';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  appStub: AppStub | AppIntegrationStub;
  existingUser?: AppIntegrationUserDto;
  readonly mode: APP_GROUP_MANAGEMENT_DRAWER_MODES;
  setUserListForMembershipDeletion: React.Dispatch<React.SetStateAction<GroupMember[]>>;
  userListForMembershipDeletion: GroupMember[];
  matchingUserForEmailOrId: (identifier: string) => AppIntegrationUserDto | undefined;
  readonly groupMembers: Record<string, GroupMember>;
  readonly listForMemberDeletion: string[];
  groupOptionList: MultiSelectOption<string>[];
  readonly existingUserGroupList: MultiSelectOption<GroupId>[];
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<AppGroupManagementFormData>>;
}

export const AppGroupManagementMemberEditList = ({
  appStub,
  existingUser,
  mode,
  setUserListForMembershipDeletion,
  userListForMembershipDeletion,
  matchingUserForEmailOrId,
  groupMembers,
  listForMemberDeletion,
  groupOptionList,
  existingUserGroupList,
  formikSetFieldValue,
}: Props): JSX.Element => {
  const filteredGroupMembers = useCallback(
    (groupDetails: Dictionary<GroupMember>, markedForDeletionList: string[]): GroupMember[] => {
      if (appStub === 'google-workspace')
        return Object.values(groupDetails).filter(
          (eachMember) =>
            (eachMember as GoogleGroupMember).email &&
            !markedForDeletionList.includes((eachMember as GoogleGroupMember).email)
        );
      if (appStub === 'jira')
        return Object.values(groupDetails).filter(
          (eachMember) =>
            (eachMember as JiraGroupMember).accountType === 'atlassian' &&
            !markedForDeletionList.includes((eachMember as JiraGroupMember).accountId)
        );
      if (appStub === 'microsoft365')
        return Object.values(groupDetails).filter(
          (eachMember) => !markedForDeletionList.includes((eachMember as Microsoft365GroupMember).id)
        );
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appStub]
  );
  return (
    <>
      {mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit &&
        groupMembers &&
        filteredGroupMembers(groupMembers, listForMemberDeletion).map((member) => {
          let identifier;
          if (appStub === 'google-workspace') {
            identifier = (member as GoogleGroupMember).email;
          }
          if (appStub === 'jira') {
            identifier = (member as JiraGroupMember).accountId;
          }
          if (appStub === 'microsoft365') {
            identifier = (member as Microsoft365GroupMember).userPrincipalName;
          }
          const matchingUser = matchingUserForEmailOrId(identifier ?? '');
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '35px' }}>
              <Box>
                <Typography
                  sx={{
                    ...themeFonts.title4,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                    mb: '10px',
                    color: themeColors.DarkGrey,
                  }}
                >
                  {matchingUser?.userId && <UserAvatar userId={+matchingUser.userId} size="xxsmall" />}
                  {matchingUser?.displayName}
                </Typography>
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                    mb: '10px',
                    color: themeColors.DarkGrey,
                  }}
                >
                  {identifier}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  sx={tableIconButtonSx}
                  onClick={() => {
                    setUserListForMembershipDeletion([...userListForMembershipDeletion, member]);
                  }}
                >
                  <Trash {...iconSize} />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      {mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit &&
        groupMembers &&
        filteredGroupMembers(groupMembers, listForMemberDeletion).length === 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '35px' }}>
            <Box>
              <Typography
                sx={{
                  ...themeFonts.title4,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                  mb: '10px',
                  color: themeColors.DarkGrey,
                }}
              >
                {mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && listForMemberDeletion.length > 0 && (
                  <>No members left to remove</>
                )}
                {mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && listForMemberDeletion.length === 0 && (
                  <>No members</>
                )}
              </Typography>
            </Box>
          </Box>
        )}
      {mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup && existingUser && (
        <>
          <Box sx={fieldSx}>{existingUser?.displayName}</Box>
          <Box sx={fieldSx}>
            <MultipleSelectCheckbox
              id="group-select"
              limitTags={5}
              options={groupOptionList}
              value={existingUserGroupList ?? []}
              onChange={(_, values) => {
                formikSetFieldValue('existingUser', existingUser, true);
                formikSetFieldValue('existingUserGroupList', values, true);
              }}
              isOptionEqualToValue={(x, y) => x.value === y.value}
              getOptionLabel={({ label }: { label: string }): string => label}
              label="Groups"
            />
          </Box>
        </>
      )}
    </>
  );
};
