import React, { useState, useEffect, useMemo } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Card, CardContent, CardHeader, IconButton, Box, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import { OauthClientEndpoints } from '@/api-client/oauth-client.api';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { StatusGroupedRequests } from '@/v2/feature/super-admin/features/super-admin-partner-api-monitoring/partner-api.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface APIStats {
  totalCalls: number;
  authorisedCalls: number;
  unauthorisedCalls: number;
  missingCompanyContextCalls: number;
  rateLimitedCalls: number;
  topAPIs: { name: string; callCount: number }[];
  callsByTime: { time: string; calls: number }[];
  topClients: { clientId: string; callCount: number }[];
  earliestCallDate: string;
}
interface TopClientData {
  clientId: string;
  callCount: number;
}

interface TopApiEndpointData {
  name: string;
  callCount: number;
}

const processAPIData = (data: StatusGroupedRequests): APIStats => {
  const authorisedCalls = Object.values(data.authorised).flat().length;
  const unauthorisedCalls = Object.values(data.unauthorised).flat().length;
  const missingCompanyContextCalls = Object.values(data.missingCompanyContext).flat().length;
  const rateLimitedCalls = Object.values(data.rateLimited).flat().length;
  const totalCalls = authorisedCalls + unauthorisedCalls + missingCompanyContextCalls + rateLimitedCalls;

  const allCalls = [
    ...Object.values(data.authorised).flat(),
    ...Object.values(data.unauthorised).flat(),
    ...Object.values(data.missingCompanyContext).flat(),
    ...Object.values(data.rateLimited).flat(),
  ];

  const apiCounts = allCalls.reduce((acc, call) => {
    const key = `${call.method} ${call.url}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const topAPIs = Object.entries(apiCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([name, callCount]) => ({ name, callCount }));

  const callsByTime = allCalls.reduce((acc, call) => {
    const hour = new Date(call.timestamp).getHours();
    const timeSlot = `${hour.toString().padStart(2, '0')}:00`;
    acc[timeSlot] = (acc[timeSlot] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const sortedCallsByTime = Object.entries(callsByTime)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([time, calls]) => ({ time, calls }));

  const clientCounts = allCalls.reduce((acc, call) => {
    const clientId = call?.clientId ?? 'unknown';
    const clientName = call?.clientName ?? null;
    const companyId = call?.companyId ?? null;
    const maskedToken = call?.token ?? null;
    const key = [clientId, clientName, companyId, maskedToken].filter(Boolean).join(' || ');
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const topClients = Object.entries(clientCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([clientId, callCount]) => ({ clientId, callCount }));

  // Find the earliest call timestamp
  const earliestCallTimestamp = allCalls.reduce((earliest, call) => {
    const callTime = new Date(call.timestamp).getTime();
    return callTime < earliest ? callTime : earliest;
  }, Number.MAX_VALUE);

  const earliestCallDate = new Date(earliestCallTimestamp).toString();

  return {
    totalCalls,
    authorisedCalls,
    unauthorisedCalls,
    missingCompanyContextCalls,
    rateLimitedCalls,
    topAPIs,
    callsByTime: sortedCallsByTime,
    topClients,
    earliestCallDate,
  };
};

export const APIMonitoringDashboard: React.FC = () => {
  const [data, setData] = useState<APIStats | null>(null);
  const [loading, setLoading] = useState(true);
  const { data: requestData, isValidating: loadingData, mutate: refreshRequestData } = useApiClient(
    OauthClientEndpoints.getGroupedStats(),
    {
      suspense: false,
    }
  );

  useEffect(() => {
    try {
      setLoading(true);
      const processedData = requestData ? processAPIData(requestData) : null;
      setData(processedData);
    } catch (error) {
      console.error('Error fetching API usage data:', error);
    } finally {
      setLoading(false);
    }
  }, [requestData]);

  const callsChartOptions = useMemo<ApexOptions>(() => {
    return {
      chart: {
        id: 'api-calls-chart',
        type: 'bar',
      },
      xaxis: {
        categories: data
          ? ['Authorised Calls', 'Unauthorised Calls', 'Missing Context', 'Rate Limited', 'Total Calls']
          : [],
      },
      yaxis: {
        title: {
          text: 'Number of Calls',
        },
      },
      colors: ['#4CAF50', '#FF5722', '#FFC107', '#2196F3', '#9C27B0'],
      title: {
        text: 'API Calls Summary',
        align: 'center',
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      },
    };
  }, [data]);

  const callsChartSeries = useMemo(() => {
    return [
      {
        name: 'API Calls',
        data: [
          data?.authorisedCalls || 0,
          data?.unauthorisedCalls || 0,
          data?.missingCompanyContextCalls || 0,
          data?.rateLimitedCalls || 0,
          data?.totalCalls || 0,
        ],
      },
    ];
  }, [data]);

  const callsChart = useMemo(
    () =>
      data ? (
        <Card className="mb-6">
          <CardContent>
            <ReactApexChart options={callsChartOptions} series={callsChartSeries} type="bar" height={350} />
          </CardContent>
        </Card>
      ) : null,
    [data, callsChartOptions, callsChartSeries]
  );

  const topAPIsListColumns = useMemo<ColumnDef<TopApiEndpointData, TopApiEndpointData>[]>(() => {
    return [
      {
        header: () => 'Endpoint',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return <div>{original.name}</div>;
        },
      },
      {
        header: () => 'Call count',
        accessorFn: (row) => row,
        id: 'callCount',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.callCount),
        cell: ({ row: { original } }) => {
          return <div>{original.callCount}</div>;
        },
      },
    ];
  }, []);

  const topAPIsList = useMemo(
    () =>
      data ? (
        <Card>
          <CardHeader title="Most Used APIs" />
          <CardContent>
            <BasicTable<TopApiEndpointData> rowData={data.topAPIs} loading={loading} columnData={topAPIsListColumns} />
          </CardContent>
        </Card>
      ) : null,
    [data, loading, topAPIsListColumns]
  );

  const topClientsListColumns = useMemo<ColumnDef<TopClientData, TopClientData>[]>(() => {
    return [
      {
        header: () => 'Client',
        accessorFn: (row) => row,
        id: 'clientId',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.clientId),
        cell: ({ row: { original } }) => {
          return <div>{original.clientId}</div>;
        },
      },
      {
        header: () => 'Call count',
        accessorFn: (row) => row,
        id: 'callCount',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.callCount),
        cell: ({ row: { original } }) => {
          return <div>{original.callCount}</div>;
        },
      },
    ];
  }, []);

  const topClientsList = useMemo(
    () =>
      data ? (
        <Card>
          <CardHeader title="Top Clients" />
          <CardContent>
            <BasicTable<TopClientData> rowData={data.topClients} loading={loading} columnData={topClientsListColumns} />
          </CardContent>
        </Card>
      ) : null,
    [data, loading, topClientsListColumns]
  );

  const refreshData = () => {
    refreshRequestData?.();
  };

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>API Monitoring Dashboard</Typography>
        }
        showAction
      />

      <ContentWrapper loading={loading || loadingData}>
        <Box
          sx={{
            display: 'flex-column',
            justifyContent: 'flex-start',
            width: '100%',
            ...spacing.mb20,
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <IconButton onClick={refreshData} className="p-2 rounded-full hover:bg-gray-200">
              <RefreshIcon />
            </IconButton>
            <Typography variant="caption">
              {data?.earliestCallDate ? `Data is from: ${data.earliestCallDate}` : 'Loading data...'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {callsChart}
            {topAPIsList}
            {topClientsList}
          </Box>
        </Box>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
