import { UserAddressDto } from '@v2/feature/user/features/user-forms/user-address/user-address.dto';

export const formatAddress = (address: UserAddressDto): string => {
  let addressLine = address.addressLine1 ?? '';
  if (address.addressLine2) addressLine = `${addressLine} ${address.addressLine2}`;
  if (address.city) addressLine = `${addressLine} ${address.city}`;
  if (address.country) addressLine = `${addressLine} ${address.country}`;

  return addressLine;
};
