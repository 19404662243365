import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { SETTINGS_TASK_CHECKLIST_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { MultipleSelectCheckbox } from '@/v2/components/forms/multiple-select-checkbox.component';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { ChecklistDto } from '@/v2/feature/task/subfeature/checklist/checklist.dto';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type EditTasksProps = {
  checklistIds?: number[];
  onSave: (checklistIds: number[]) => void;
};

export const EditTasks = ({ checklistIds, onSave }: EditTasksProps) => {
  const { polyglot } = usePolyglot();
  const validationSchema = yup.object({
    checklistId: yup.number().integer().min(1, polyglot.t('EditTasks.errorMessages.checklistRequired')),
  });
  const [checklists, setChecklists] = useState<ChecklistDto[]>();
  const routerHistory = useHistory();
  const refreshTaskTypes = useCallback(() => {
    TaskAPI.getChecklists().then(setChecklists);
  }, []);

  const formik = useFormik({
    initialValues: {
      checklistIds: checklistIds ?? [],
    },
    validationSchema,
    onSubmit(values) {
      onSave(values.checklistIds);
    },
  });

  useEffect(refreshTaskTypes, [refreshTaskTypes]);

  const checklistOptions = useMemo(() => {
    return checklists
      ?.sort((a, b) => (a.name ?? '')?.localeCompare(b.name ?? '', undefined, { sensitivity: 'base' }))
      .map<OptionObj>((c) => ({
        label: c.name ?? '',
        value: c.id,
      }));
  }, [checklists]);

  const selectedValues = useMemo(() => {
    return checklistOptions && formik.values.checklistIds
      ? checklistOptions?.filter((c) => formik.values.checklistIds?.includes(c.value as number))
      : [];
  }, [formik.values.checklistIds, checklistOptions]);

  return (
    <>
      <Typography sx={themeFonts.title2}>{polyglot.t('EditTasks.select')}</Typography>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
        {polyglot.t('EditTasks.checklistCollection')}
      </Typography>
      {!checklistOptions && <LoadingSpinner />}
      {checklistOptions && checklistOptions.length === 0 && (
        <Stack>
          <Typography sx={{ ...themeFonts.caption, mt: spacing.mt20 }}>
            {polyglot.t('EditTasks.noChecklists')}
          </Typography>
          <ButtonComponent
            type="button"
            fullWidth
            colorVariant="primary"
            sizeVariant="large"
            style={{ marginTop: '40px' }}
            onClick={() => routerHistory.push(SETTINGS_TASK_CHECKLIST_ROUTE)}
          >
            Go to checklists
          </ButtonComponent>
        </Stack>
      )}
      {checklistOptions && checklistOptions.length > 0 && (
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ minHeight: '65px', ...spacing.mt20 }}>
            <MultipleSelectCheckbox
              id="checklistIds"
              label={polyglot.t('EditTasks.select')}
              limitTags={5}
              options={checklistOptions}
              value={selectedValues}
              onChange={(_, values) => {
                const updatedOptionList = values.map(({ value }) => value);
                formik.setFieldValue('checklistIds', updatedOptionList, true);
              }}
              isOptionEqualToValue={(x, y) => x.value === y.value}
              getOptionLabel={({ label }: { label: string }): string => label}
              disableClearable
            />
          </Box>
          <ButtonComponent fullWidth style={{ marginTop: '40px' }} colorVariant="primary" sizeVariant="large">
            {polyglot.t('General.save')}
          </ButtonComponent>
        </form>
      )}
    </>
  );
};
