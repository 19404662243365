import { Fragment } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { ABSENCE_ME_REQUESTS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/feature/dashboard/dashboard.scss';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { PolicyAnalytics } from '@/v2/feature/dashboard/features/sections/user-time-planner/components/policy-analytics.component';
import { TimePlannerEmptyState } from '@/v2/feature/dashboard/features/sections/user-time-planner/components/time-planner-empty-state.component';
import { AbsenceTimeWidgetBreakdown } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserTimePlannerSmall = ({
  userBalance,
  readOnly = false,
}: {
  userBalance: AbsenceTimeWidgetBreakdown[];
  readOnly?: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const primaryBalance = userBalance[0];
  const routerHistory = useHistory();
  return (
    <WidgetLayout readOnly={readOnly} size="small">
      <Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              '&:hover': { background: themeColors.transparency },
              borderRadius: radius.br10,
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={() => routerHistory.push(ABSENCE_ME_REQUESTS_ROUTE)}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: spacing.p5 }}>
              <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
                {polyglot.t('UserTimePlannerSmall.timePlanner')}
              </Typography>
            </Box>
          </Box>
        </Box>
        {userBalance && userBalance.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
              verticalAlign: 'middle',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: spacing.m10,
            }}
          >
            <PolicyAnalytics primaryBalance={primaryBalance} />
          </Box>
        ) : (
          <TimePlannerEmptyState />
        )}
      </Fragment>
    </WidgetLayout>
  );
};
