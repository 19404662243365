export const radius = {
  br4: '4px',
  br5: '5px',
  br8: '8px',
  br10: '10px',
  br12: '12px',
  br15: '15px',
  br16: '16px',
  br20: '20px',
  br25: '25px',
};
