import React, { useCallback, useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { generatePath, useHistory } from 'react-router-dom';

import { ContractAPI } from '@/api-client/contract.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DOCUMENTS_ME_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { WitnessDetailsDto } from '@/v2/feature/templates/templates.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly contractId: string;
  readonly returnPath?: string;
  readonly witnessPending?: boolean;
}

export const UserSignContractModal = ({
  setOpen,
  open,
  contractId,
  returnPath,
  witnessPending = false,
}: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [isSigningContract, setIsSigningContract] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [employeeSignature, setEmployeeSignature] = useState<string>('');
  const [contractSigned, setContractSigned] = useState<boolean>(false);
  const [witnessSigning, setWitnessSigning] = useState<boolean>(false);
  const [witnessSigned, setWitnessSigned] = useState<boolean>(false);
  const [witnessDetails, setWitnessDetails] = useState<WitnessDetailsDto | null>(null);
  const [witnessSignature, setWitnessSignature] = useState<string>('');
  const [witnessFullName, setWitnessFullName] = useState<string>('');
  const [witnessFullAddress, setWitnessFullAddress] = useState<string>('');

  const routerHistory = useHistory();

  const getWitnessDetails = useCallback(() => {
    return witnessSignature && witnessFullName && witnessFullAddress
      ? {
          witnessSignature,
          witnessFullName,
          witnessFullAddress,
        }
      : null;
  }, [witnessFullAddress, witnessFullName, witnessSignature]);

  const signContract = useCallback(
    async (signingWitnessDetailsNow = false) => {
      setErrorMessage(undefined);
      try {
        setIsSigningContract(true);

        if (!employeeSignature) {
          showMessage(polyglot.t('UserSignContractModal.errorMessages.signature'), 'error');
        }

        let detailsForWitness;
        if (signingWitnessDetailsNow) {
          detailsForWitness = getWitnessDetails();
          setWitnessDetails(detailsForWitness);
          setWitnessSigned(!!detailsForWitness);
        }

        if (witnessPending && !!detailsForWitness) {
          await ContractAPI.sign({ contractId, signature: employeeSignature, witnessDetails: detailsForWitness });
          showMessage(polyglot.t('UserSignContractModal.successMessages.signed'), 'success');
        } else if (!witnessPending) {
          await ContractAPI.sign({ contractId, signature: employeeSignature });
          showMessage(polyglot.t('UserSignContractModal.successMessages.signed'), 'success');
        }

        setContractSigned(true);
        if (witnessPending) setWitnessSigning(true);
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.message ||
            error?.message ||
            'Issues encountered in creating this document, please try again'
        );
        showMessage(
          polyglot.t('UserSignContractModal.errorMessages.sign', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setIsSigningContract(false);
      }
    },
    [employeeSignature, witnessPending, showMessage, polyglot, getWitnessDetails, contractId]
  );

  const handlePostUserSignAction = () => {
    routerHistory.push(returnPath ? returnPath : generatePath(DOCUMENTS_ME_ROUTE));
    return;
  };

  const witnessDone = !witnessPending || (witnessPending && witnessSigned && witnessDetails !== null);

  return (
    <DrawerModal isOpen={open} setIsOpen={setOpen}>
      <Box sx={drawerContentSx}>
        {!contractSigned && !witnessSigning && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">{polyglot.t('UserSignContractModal.signTheContract')}</Typography>

            <Typography variant="caption">{polyglot.t('UserSignContractModal.userAgreementMessage')}</Typography>
            <Typography variant="caption">{polyglot.t('UserSignContractModal.userAgreementMessagePart2')}</Typography>

            <FormControl>
              <TextfieldComponent
                name="employeeSignature"
                label={polyglot.t('UserSignContractModal.employeeSignature')}
                value={employeeSignature}
                type="text"
                onChange={(e) => setEmployeeSignature(e.target.value)}
                error={!employeeSignature}
                helperText={polyglot.t('UserSignContractModal.helperSignature')}
                clearText={() => setEmployeeSignature('')}
              />
            </FormControl>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mt: '2em',
              }}
            >
              <Typography variant="caption" sx={{ ...themeFonts.signature }}>
                {employeeSignature}
              </Typography>
            </Box>

            <Box sx={buttonBoxDrawerSx}>
              {/* <ButtonComponent
                fullWidth
                sizeVariant="medium"
                colorVariant="secondary"
                onClick={() => {
                  setOpen(false);
                  setEmployeeSignature('');
                }}
              >
                {polyglot.t('General.cancel')}
              </ButtonComponent> */}

              <LoaderButton
                fullWidth
                name={polyglot.t('UserSignContractModal.sign')}
                loading={isSigningContract}
                disabled={isSigningContract || !employeeSignature}
                onClick={() => signContract()}
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          </Box>
        )}

        {!witnessDone && contractSigned && witnessSigning && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">{polyglot.t('UserSignContractModal.witnessDetails')}</Typography>
            <Typography variant="caption">{polyglot.t('UserSignContractModal.userWitnessMessagePart1')}</Typography>
            <Typography variant="caption">{polyglot.t('UserSignContractModal.userAgreementMessage')}</Typography>
            <Typography variant="caption">{polyglot.t('UserSignContractModal.userWitnessMessagePart2')}</Typography>

            <FormControl>
              <TextfieldComponent
                name="witnessFullName"
                label={polyglot.t('UserSignContractModal.witnessFullName')}
                value={witnessFullName}
                type="text"
                onChange={(e) => setWitnessFullName(e.target.value)}
                error={!witnessFullName}
                clearText={() => setWitnessFullName('')}
              />
            </FormControl>

            <FormControl>
              <TextfieldComponent
                name="witnessFullAddress"
                label={polyglot.t('UserSignContractModal.witnessFullAddress')}
                value={witnessFullAddress}
                type="text"
                onChange={(e) => setWitnessFullAddress(e.target.value)}
                error={!witnessFullAddress}
                clearText={() => setWitnessFullAddress('')}
              />
            </FormControl>

            <FormControl>
              <TextfieldComponent
                name="witnessSignature"
                label={polyglot.t('UserSignContractModal.witnessSignature')}
                value={witnessSignature}
                type="text"
                onChange={(e) => setWitnessSignature(e.target.value)}
                error={!witnessSignature}
                helperText={polyglot.t('UserSignContractModal.helperSignature')}
                clearText={() => setWitnessSignature('')}
              />
            </FormControl>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mt: '2em',
              }}
            >
              <Typography variant="caption" sx={{ ...themeFonts.signature }}>
                {witnessSignature}
              </Typography>
            </Box>

            <Box sx={buttonBoxDrawerSx}>
              {/* <ButtonComponent
                fullWidth
                sizeVariant="medium"
                colorVariant="secondary"
                onClick={() => {
                  setOpen(false);
                  setWitnessSignature('');
                }}
              >
                {polyglot.t('General.cancel')}
              </ButtonComponent> */}

              <LoaderButton
                fullWidth
                name={polyglot.t('UserSignContractModal.sign')}
                loading={isSigningContract}
                disabled={isSigningContract || !witnessSignature || !witnessFullAddress || !witnessFullName}
                onClick={() => signContract(true)}
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          </Box>
        )}

        {contractSigned && witnessDone && !Boolean(errorMessage) && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">{polyglot.t('UserSignContractModal.thankYou')}</Typography>

            <Typography variant="caption">{polyglot.t('UserSignContractModal.youWillReceiveEmail')}</Typography>

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="medium"
                fullWidth
                onClick={() => handlePostUserSignAction()}
              >
                {polyglot.t('UserSignContractModal.close')}
              </ButtonComponent>
            </Box>
          </Box>
        )}

        {Boolean(errorMessage) && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">{polyglot.t('UserSignContractModal.sorry')}</Typography>
            <Typography variant="caption">{errorMessage}</Typography>
            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="medium"
                fullWidth
                onClick={() => handlePostUserSignAction()}
              >
                {polyglot.t('UserSignContractModal.close')}
              </ButtonComponent>
            </Box>
          </Box>
        )}
      </Box>
    </DrawerModal>
  );
};
