import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { PaymentTypeSettingsEndpoints } from './payment-type-settings.api';

import {
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
} from '@/lib/routes';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PaymentType } from '@/v2/feature/payments/payments.dto';
import {
  PaymentCategoryEnum,
  PaymentTypeSettings,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

export const PaymentTypesSettingsPage = ({ type }: { type: PaymentType }) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const { data, isValidating: loadingPaymentTypeSettingsData } = useApiClient(
    type === PaymentType.Expense
      ? PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId()
      : type === PaymentType.Invoice
      ? PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId()
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const [searchInput, setSearchInput] = useState('');

  const paymentTypeSettingsData = useMemo(() => {
    if (!searchInput || !data) return data?.settings ?? [];

    const search = searchInput.toLowerCase();
    return data.settings.filter((p) => p.name.toLowerCase().includes(search));
  }, [data, searchInput]);

  const columnData = useMemo<ColumnDef<PaymentTypeSettings, PaymentTypeSettings>[]>(() => {
    return [
      {
        header: () => polyglot.t('PaymentSettingsPage.tableColumns.name'),
        accessorFn: (row) => row,
        id: 'name',
        minSize: 150,
        maxSize: 150,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (info) => {
          return <Typography variant="caption">{info.getValue().name}</Typography>;
        },
      },
      {
        header: () => polyglot.t('PaymentSettingsPage.tableColumns.accountingCode'),
        accessorFn: (row) => row,
        id: 'accountingCodeConfig',
        minSize: 150,
        maxSize: 150,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item?.accountingCode),
        cell: ({ row: { original } }) => {
          const accountingCodeDescription = original.accountingCodeConfig
            ? original.accountingCodeConfig
                .map((item) => `${item.accountingCode} - ${item.accountingCodeDescription}`)
                .join(', ')
            : '';
          return original.accountingCodeConfig ? (
            <Typography variant="caption">
              {accountingCodeDescription ? `${truncateWithEllipses(accountingCodeDescription, 40)}` : ''}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
    ];
  }, [polyglot]);

  const handleRowClick = (row: Row<PaymentTypeSettings>) => {
    const isExpense = row.original.type === PaymentCategoryEnum.EXPENSE;
    if (!row.original.id) return;
    history.push(
      generatePath(
        isExpense
          ? SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE
          : SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
        {
          id: row.original.id,
        }
      )
    );
  };

  return (
    <Box>
      <Box sx={{ mb: spacing.s1 }}>
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
      </Box>
      <Box>
        <BasicTable<PaymentTypeSettings>
          rowData={paymentTypeSettingsData ?? []}
          columnData={columnData}
          loading={loadingPaymentTypeSettingsData}
          hidePagination
          rowClick={(row: Row<PaymentTypeSettings>) => handleRowClick(row)}
        />
      </Box>
    </Box>
  );
};
