import { ddmmyyyyDateFormat, isoDateAndTimeFormat } from '@v2/infrastructure/date/date-format.util';
import * as yup from 'yup';

export const absenceImportValidationSchema = (isHourly: boolean) =>
  yup.object({
    userId: yup.number().required('Assigned User is required'),
    policyName: yup.string().required('Policy name is required'),
    start: yup
      .string()
      .matches(ddmmyyyyDateFormat, 'Enter a valid date (dd/MM/yyyy)')
      .required('Start Date is required'),
    end: isHourly
      ? yup
          .string()
          .nullable()
          .notRequired()
          .test('is-same-date', 'End date must be the same as start date or null', function (value) {
            const { start } = this.parent;
            return !value || value.slice(0, 10) === start.slice(0, 10);
          })
      : yup.string().matches(ddmmyyyyDateFormat, 'Enter a valid date (dd/MM/yyyy)').nullable().notRequired(),
    startHour: isHourly
      ? yup.string().matches(isoDateAndTimeFormat, 'Enter a valid start hour').required('Start hour is required')
      : yup.string().nullable().matches(isoDateAndTimeFormat, 'Enter a validaaaaa end hour').notRequired(),
    endHour: isHourly
      ? yup.string().matches(isoDateAndTimeFormat, 'Enter a valid start hour').required('End hour is required')
      : yup.string().nullable().matches(isoDateAndTimeFormat, 'Enter a validbbb end hour').notRequired(),
    morningOnly: yup
      .string()
      .nullable()
      .oneOf(
        isHourly ? ['No', null] : ['Yes', 'No', null],
        isHourly ? 'Hourly policies do not support half days (AM)' : 'Allowed values are "Yes" and "No".'
      )
      .notRequired(),
    afternoonOnly: yup
      .string()
      .nullable()
      .oneOf(
        isHourly ? ['No', null] : ['Yes', 'No', null],
        isHourly ? 'Hourly policies do not support half days (PM)' : 'Allowed values are "Yes" and "No".'
      )
      .notRequired(),
    status: yup
      .string()
      .oneOf(
        ['approved', 'pending', 'rejected', 'Approved', 'Pending', 'Rejected'],
        'Allowed values for status are "Approved", "Pending" and "Rejected".'
      )
      .required('Status is required'),
    notes: yup.string().max(255, 'Max 255 chars allowed.').nullable().notRequired(),
  });

export const absenceAdjustmentsImportValidationSchema = () =>
  yup.object({
    // userId: yup.number().integer('User could not be found').required('Assigned User is required'),
    // policyId: yup.number().integer('A valid policy is required').required('Assigned User is required'),
    policyName: yup.string().required('Policy name is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    workEmail: yup.string().email('A valid email is required').required('Work email is required'),
    effectiveYear: yup.number().integer('Effective year should be an integer').required('Assigned User is required'),
    adjustment: yup.number().typeError('Adjustment should be a number').required('Adjustment is required'),
    unit: yup
      .string()
      .oneOf(['Days', 'Hours'], 'Allowed values for status are "Days" and "Hours".')
      .required('Unit is required'),
    note: yup.string().max(255, 'Max 255 chars allowed.').nullable().notRequired(),
  });
