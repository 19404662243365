import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { AbsenceAPI } from '@/v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@/v2/feature/absence/absence.dto';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

type AbsencePolicyInstructionsUpdate = Pick<AbsencePolicyDto, 'instructions'>;

export const UpdatePolicyInstructionsDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh, onClose }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <UpdatePolicyInstructionsDrawerContent absencePolicy={absencePolicy} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdatePolicyInstructionsDrawerContent = ({
  absencePolicy,
  refresh,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: AbsencePolicyInstructionsUpdate) => {
      if (!values.instructions) {
        return;
      }

      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyInstructionsById(absencePolicy?.id, values.instructions);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [absencePolicy?.id, refresh, setIsOpen, showMessage, polyglot]
  );

  const formik = useFormik<AbsencePolicyInstructionsUpdate>({
    initialValues: {
      instructions: absencePolicy?.instructions ?? null,
    },
    validationSchema: yup.object({
      instructions: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="title2">{polyglot.t('AbsencePolicyGeneralSection.instructionsModalTitle')}</Typography>
        </Box>
        <Box>
          <TextfieldComponent
            name="instructions"
            label={polyglot.t('AbsencePolicyGeneralSection.instructions')}
            value={formik.values.instructions}
            onChange={formik.handleChange}
            error={formik.touched.instructions && !!formik.errors.instructions}
            helperText={(formik.touched.instructions && formik.errors.instructions) ?? ' '}
            endAdornment="none"
            multiline
            minRows={2}
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.update')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
