import { Box } from '@mui/material';

import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SurveySectionBank } from '@/v2/feature/growth/surveys/interfaces/survey-section-bank.interface';
// import { SurveyTemplate } from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SurveySectionBuilder = ({
  isEditable,
  section,
  sectionOptions,
  dragQuestionComponent,
  newQuestionAction,
}: {
  isEditable: boolean;
  section: Pick<SurveySectionBank, 'name'>;
  sectionOptions: readonly OptionProps[];
  dragQuestionComponent: JSX.Element;
  newQuestionAction: () => void;
}) => {
  return (
    <Box sx={{ borderBottom: `1px solid ${themeColors.lightGrey}` }}>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: themeColors.Background,
          justifyContent: 'space-between',
          alignItems: 'center',
          p: spacing.p16,
          marginTop: spacing.m24,
          borderBottom: `1px solid ${themeColors.lightGrey}`,
        }}
      >
        <Typography variant="title4">{section.name}</Typography>
        {isEditable && (
          <StyledMenuComponent
            options={sectionOptions}
            actionButtonDetails={{
              type: 'iconButton',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: 'actions',
              icon: <ActionsSmall {...iconSize} />,
            }}
          />
        )}
      </Box>

      {dragQuestionComponent}

      {isEditable && (
        <Box
          sx={{
            bgcolor: themeColors.white,
            p: spacing.p16,
          }}
        >
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="medium"
            startIcon={<Plus {...iconSize} />}
            onClick={newQuestionAction}
          >
            New question
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};
