import { Dispatch, SetStateAction } from 'react';

import { Box, FormControlLabel, FormLabel, RadioGroup, Stack } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import {
  AnonymityThreshold,
  AnonymityType,
  SurveyCycle,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SCParticipantAnonymityModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  surveyCycle: SurveyCycle | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCParticipantAnonymityModalContent surveyCycle={surveyCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

const useAnonymitySettingForm = (
  surveyCycle: SurveyCycle | undefined,
  onClose: () => void,
  refresh: () => Promise<void>
) => {
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      visibilitySettings: surveyCycle?.visibilitySettings || {
        anonymiseAnswers: false,
        anonymityType: null,
        anonymityThreshold: null,
      },
    },
    validationSchema: yup.object({
      visibilitySettings: yup.object({
        anonymiseAnswers: yup.boolean().notRequired(),
        anonymityType: yup.string().nullable().notRequired(),
        anonymityThreshold: yup.number().nullable().notRequired(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!surveyCycle) {
          throw new Error('Survey cycle is not defined.');
        }

        const isPreOngoingState = [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle.state);
        if (isPreOngoingState) {
          const updatedCycle = {
            ...surveyCycle,
            visibilitySettings: values.visibilitySettings,
          };
          await SurveyCycleAPI.updateSurveyCycle(updatedCycle);
        }
        showMessage('Successfully updated anonymity settings', 'success');
        await refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};
const SCParticipantAnonymityModalContent = ({
  surveyCycle,
  onClose,
  refresh,
}: {
  surveyCycle: SurveyCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const formik = useAnonymitySettingForm(surveyCycle, onClose, refresh);
  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx} onSubmit={formik.handleSubmit}>
        <Typography variant="title2">Edit anonymity settings</Typography>

        <Stack direction="column">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Anonymity</Typography>
            <SwitchComponent
              checked={formik.values.visibilitySettings.anonymiseAnswers}
              name="anonymous-survey"
              onChange={(_e, enabled) => {
                formik.setFieldValue('visibilitySettings', { anonymiseAnswers: enabled });
                if (enabled) {
                  formik.setFieldValue('visibilitySettings.anonymityType', AnonymityType.Full);
                  formik.setFieldValue('visibilitySettings.anonymityThreshold', AnonymityThreshold.Three);
                } else {
                  formik.setFieldValue('visibilitySettings.anonymityType', null);
                  formik.setFieldValue('visibilitySettings.anonymityThreshold', null);
                }
              }}
            />
          </Box>
          <Typography variant="caption" color="Grey">
            Direct employee attribution will not be possible. Participant list will not be editable after survey is
            launched.
          </Typography>
        </Stack>

        <Stack
          direction="column"
          gap={spacing.g8}
          sx={{
            visibility: Boolean(formik.values.visibilitySettings?.anonymiseAnswers) ? 'visible' : 'hidden',
            opacity: Boolean(formik.values.visibilitySettings?.anonymiseAnswers) ? 1 : 0,
            transition: 'opacity 0.3s ease, visibility 0.3s ease',
          }}
        >
          <FormLabel>
            <Typography variant="caption">Anonymity type</Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="anonymity type"
            name="anonymity-type"
            id="anonymity-type"
            onChange={(event) => {
              formik.setFieldValue('visibilitySettings.anonymityType', event.target.value as AnonymityType);
            }}
            sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <FormControlLabel
              key="full-anonymity-type"
              id="full-anonymity-type"
              labelPlacement="end"
              value={AnonymityType.Full}
              checked={Boolean(
                formik.values.visibilitySettings.anonymityType !== null &&
                  formik.values.visibilitySettings.anonymityType === AnonymityType.Full
              )}
              control={<StyledRadio />}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption">Full</Typography>
                  <Typography variant="caption" color="Grey" id="full-type-description">
                    No respondent data is collected
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              key="semi-anonymity-type"
              id="semi-anonymity-type"
              labelPlacement="end"
              value={AnonymityType.Semi}
              checked={formik.values.visibilitySettings.anonymityType === AnonymityType.Semi}
              control={<StyledRadio />}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption">Semi</Typography>
                  <Typography variant="caption" color="Grey" id="semi-type-description">
                    Participant's department, site, gender and tenure is saved.
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </Stack>

        <Stack
          direction="column"
          gap={spacing.g8}
          sx={{
            visibility: Boolean(formik.values.visibilitySettings?.anonymiseAnswers) ? 'visible' : 'hidden',
            opacity: Boolean(formik.values.visibilitySettings?.anonymiseAnswers) ? 1 : 0,
            transition: 'opacity 0.3s ease, visibility 0.3s ease',
          }}
        >
          <FormLabel>
            <Typography variant="caption">Anonymity threshold</Typography>
            <Typography variant="caption" color="Grey">
              Minimum answers required to view results for any question or chart
            </Typography>
          </FormLabel>
          <RadioGroup
            name="anonymity-threshold"
            onChange={(event) => {
              formik.setFieldValue('visibilitySettings.anonymityThreshold', Number(event.target.value));
            }}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <FormControlLabel
              key="three-min-answer"
              labelPlacement="end"
              value={AnonymityThreshold.Three}
              checked={Boolean(
                formik.values.visibilitySettings.anonymityThreshold !== null &&
                  formik.values.visibilitySettings.anonymityThreshold === AnonymityThreshold.Three
              )}
              control={<StyledRadio />}
              label={<Typography variant="caption">{AnonymityThreshold.Three}</Typography>}
            />
            <FormControlLabel
              key="five-min-answer"
              labelPlacement="end"
              value={AnonymityThreshold.Five}
              checked={formik.values.visibilitySettings.anonymityThreshold === AnonymityThreshold.Five}
              control={<StyledRadio />}
              label={<Typography variant="caption">{AnonymityThreshold.Five}</Typography>}
            />

            <FormControlLabel
              key="ten-min-answer"
              labelPlacement="end"
              value={AnonymityThreshold.Ten}
              checked={formik.values.visibilitySettings.anonymityThreshold === AnonymityThreshold.Ten}
              control={<StyledRadio />}
              label={<Typography variant="caption">{AnonymityThreshold.Ten}</Typography>}
            />
          </RadioGroup>
        </Stack>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            disabled={!formik.isValid}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
