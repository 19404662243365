import { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { KeyedMutator } from 'swr';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { canAccessScopes } from '@/lib/scopes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewAPI } from '@/v2/feature/growth/reviews/api-client/review.api';
import { ResultsOverviewCommentModal } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-comment-modal.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewResult } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { ReviewComment } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { GridDisplayBarV2 } from '@/v2/feature/growth/shared/components/grid-display-bar.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ResultsOverviewReviewComment = ({
  results,
  refreshResults,
  loggedInUser,
  reachType,
}: {
  results: ReviewResult | null | undefined;
  refreshResults: KeyedMutator<ReviewResult> | undefined;
  loggedInUser: number;
  reachType: ReachType;
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<'Public' | 'Private' | undefined>(undefined);
  const [selectedComment, setSelectedComment] = useState<ReviewComment | undefined>(undefined);
  const [showMessage] = useMessage();

  const isManager = canAccessScopes(user, ['reviews:manager']);

  const publicText =
    reachType === ReachType.Me
      ? 'Leave a comment for your records or to discuss with your manager'
      : "Leave a 'public' comment to share feedback with participant or 'private' to keep it between managers/admins.";
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16 }}>
      <Typography variant="caption" color="Grey">
        {publicText}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }} className="hide-for-capture">
        <ButtonComponent
          colorVariant="secondary"
          sizeVariant="small"
          onClick={() => {
            setIsOpen(true);
            setMode('Public');
          }}
        >
          Add public comment
        </ButtonComponent>
        {isManager && (
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="small"
            onClick={() => {
              setIsOpen(true);
              setMode('Private');
            }}
          >
            Add private comment
          </ButtonComponent>
        )}
      </Box>

      {results?.comments &&
        results.comments.map((commentObj) => (
          <GridDisplayBarV2
            cells={[
              {
                content: (
                  <Box sx={{ display: 'flex', width: '85%', flexDirection: 'column', gap: spacing.g8 }}>
                    <Typography
                      variant="caption"
                      dangerouslySetInnerHTML={{ __html: commentObj?.comment ?? '' }}
                      sx={{ padding: 0, margin: 0, overflowWrap: 'break-word' }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
                      <ChipComponent
                        name={commentObj.isPublic ? 'Public comment' : 'Private comment'}
                        backgroundColor="white"
                        border="background"
                        textVariant="caption"
                      />
                      <ChipComponent
                        name={
                          <UserCell
                            userId={commentObj.commentBy}
                            nameVariant="caption"
                            nameSx={{ color: themeColors.Grey }}
                          />
                        }
                        backgroundColor="white"
                        border="background"
                      />
                    </Box>
                  </Box>
                ),
                gridXs: 8,
              },
              {
                content:
                  Number(commentObj.commentBy) === Number(loggedInUser) ? (
                    <StyledMenuComponent
                      className="hide-for-capture"
                      options={[
                        {
                          icon: <Edit {...iconSize} />,
                          handler: () => {
                            setSelectedComment(commentObj);
                            setIsOpen(true);
                            setMode(commentObj.isPublic ? 'Public' : 'Private');
                          },
                          label: polyglot.t('General.edit'),
                        },
                        {
                          icon: <Trash {...iconSize} />,
                          handler: async () => {
                            try {
                              if (results) {
                                await ReviewAPI.deleteComment(results.reviewId, results.cycleId, commentObj);
                                refreshResults?.();
                                showMessage('Comment deleted successfully', 'success');
                              }
                            } catch (error) {
                              showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                            }
                          },
                          label: polyglot.t('General.delete'),
                        },
                      ]}
                      actionButtonDetails={{
                        type: 'iconButton',
                        colorVariant: 'secondary',
                        sizeVariant: 'small',
                        title: 'actions',
                        icon: <ActionsSmall {...iconSize} />,
                      }}
                    />
                  ) : (
                    <></>
                  ),
                gridXs: 4,
                sx: { display: 'flex', justifyContent: 'flex-end' },
              },
            ]}
          />
        ))}

      <ResultsOverviewCommentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedComment(undefined);
        }}
        results={results}
        refreshResults={refreshResults}
        isPublic={Boolean(mode === 'Public')}
        selectedComment={selectedComment}
      />
    </Box>
  );
};
