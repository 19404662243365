import { User } from '@/models';

export enum RelativeAssignment {
  manager = 'Manager of Checklist assignee',
  checklistAssignee = 'Checklist assignee',
  referenceUser = 'Checklist assignee',
  someoneElse = 'Someone else',
  everyone = 'Everyone',
  customRule = 'Custom rule',
}

export enum RelativeAssignmentValues {
  manager = 'manager',
  checklistAssignee = 'checklistAssignee',
  referenceUser = 'checklistAssignee',
  someoneElse = 'someoneElse',
  everyone = 'everyone',
  customRule = 'customRule',
}

export const RelativeAssignmentOptions = [
  { label: RelativeAssignment.checklistAssignee, value: 'checklistAssignee' },
  { label: RelativeAssignment.manager, value: 'manager' },
  { label: RelativeAssignment.someoneElse, value: 'someoneElse' },
  { label: RelativeAssignment.everyone, value: RelativeAssignmentValues.everyone },
  { label: RelativeAssignment.customRule, value: RelativeAssignmentValues.customRule },
];

export const RequestedForRelativeAssignmentOptions = [
  { label: RelativeAssignment.checklistAssignee, value: 'checklistAssignee' },
  { label: RelativeAssignment.manager, value: 'manager' },
  { label: RelativeAssignment.someoneElse, value: 'someoneElse' },
];

export interface ChecklistItemDto {
  id: number;
  companyId: number;
  checklistId: number;
  name: string;
  description: string;
  userAssignWithPlaceholder: boolean;
  assignedToPlaceholder: RelativeAssignmentValues;
  requestedForPlaceholder: RelativeAssignmentValues;
  assignedToCustomRule?: string;
  dueDateOffsetDuration: number;
  dueDateOffsetUnit: 'Weeks' | 'Days' | 'Months';
  assignedUserId?: number | null;
  assignedUserIds?: number[] | null;
  assignedUser?: Pick<User, 'userId' | 'firstName' | 'lastName'>;
  requestedForId?: number | null;
  requestedFor?: Pick<User, 'userId' | 'firstName' | 'lastName'>;
  dueDate?: string | null;
  taskForSomebodyElse?: boolean;
  createdBy?: number;
  updatedBy?: number;
  createdAt?: Date;
  updatedAt?: Date;
  attachmentValues?: string;
}

export type CreateChecklistItemDto = Pick<
  ChecklistItemDto,
  | 'name'
  | 'description'
  | 'assignedUserId'
  | 'assignedUserIds'
  | 'requestedForId'
  | 'dueDate'
  | 'userAssignWithPlaceholder'
  | 'assignedToPlaceholder'
  | 'requestedForPlaceholder'
  | 'dueDateOffsetDuration'
  | 'dueDateOffsetUnit'
  | 'taskForSomebodyElse'
  | 'attachmentValues'
  | 'assignedToCustomRule'
>;

export type LaunchChecklistItemDto = Pick<ChecklistItemDto, 'assignedUserIds' | 'dueDate'>;

export type UpdateChecklistItemDto = Pick<
  ChecklistItemDto,
  | 'name'
  | 'description'
  | 'assignedUserId'
  | 'assignedUserIds'
  | 'requestedForId'
  | 'dueDate'
  | 'userAssignWithPlaceholder'
  | 'assignedToPlaceholder'
  | 'requestedForPlaceholder'
  | 'dueDateOffsetDuration'
  | 'dueDateOffsetUnit'
  | 'taskForSomebodyElse'
  | 'attachmentValues'
  | 'assignedToCustomRule'
>;
