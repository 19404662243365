import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SettingsRouter } from '@v2/feature/settings/settings.router';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { CompanyAPI } from '@/api-client/company.api';
import FailureCloseTab from '@/component/FailureCloseTab';
import SuccessCloseTab from '@/component/SuccessCloseTab';
import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import {
  DASHBOARD_ROUTE,
  FAILURE_ROUTE,
  GROWTH_ROUTE,
  MONEY_ROUTE,
  PARTNER_API_AUTHORISE_ROUTE,
  PEOPLE_ROUTE,
  REPORTS_ROUTE,
  SETTINGS_ROUTE,
  SUCCESS_ROUTE,
  SUPER_ADMIN_ROUTE,
  TECH_ROUTE,
  TIME_ROUTE,
} from '@/lib/routes';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { oldToNewRedirects } from '@/v2/feature/app-layout/features/main-content/main.util';
import { GrowthRouter } from '@/v2/feature/app-layout/features/v2/routers/growth.router';
import { ItRouter } from '@/v2/feature/app-layout/features/v2/routers/it.router';
import { MoneyRouter } from '@/v2/feature/app-layout/features/v2/routers/money.router';
import { PeopleRouter } from '@/v2/feature/app-layout/features/v2/routers/people.router';
import { ReportRouter } from '@/v2/feature/app-layout/features/v2/routers/report.router';
import { TimeRouter } from '@/v2/feature/app-layout/features/v2/routers/time.router';
import { AuthMeUser } from '@/v2/feature/auth/auth.interface';
import { DashboardRouter } from '@/v2/feature/dashboard/dashboard.router';
import { PublicAPIRouter } from '@/v2/feature/public-api/public-api.router';
import { SuperAdminRouter } from '@/v2/feature/super-admin/super-admin.router';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

declare global {
  interface Window {
    // TODO specify the correct types
    hsConversationsSettings: Record<string, any>;
    hsConversationsOnReady: Array<() => void>;
    HubSpotConversations: {
      on: any;
      off: any;
      clear: (params?: { resetWidget: boolean }) => void;
      widget: {
        status: () => { loaded: boolean; pending: boolean };
        load: (params?: { widgetOpen: boolean }) => void;
        remove: () => void;
        open: () => void;
        close: () => void;
        refresh: (openToNewThread?: boolean) => void;
      };
    };
  }
}

interface Props {
  readonly isSuperAdmin: boolean;
  readonly userAPIResponse: AuthMeUser;
}

export const MainRouter = ({ isSuperAdmin, userAPIResponse }: Props): JSX.Element => {
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const [globalState, dispatch] = useContext(GlobalContext);
  const { user } = globalState;
  const { emailAddress, firstName, lastName } = user ?? {};
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showMessage] = useMessage();

  const { useInitLanguage } = usePolyglot();
  useInitLanguage();

  const hubspotData = useMemo(() => ({ email: emailAddress, firstName, lastName }), [
    emailAddress,
    firstName,
    lastName,
  ]);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);

  const getChatSupportFlag = useCallback(async () => {
    const hasPriority = await CompanyAPI.priorityChatSupport();
    if (hasPriority) {
      try {
        const token = await AppIntegrationAPI.generateHubSpotIdentificationToken(emailAddress, firstName, lastName);
        if (token) {
          console.info('Got hs token');
          window.hsConversationsSettings = {
            identificationEmail: hubspotData.email,
            identificationToken: token ?? '',
          };
          // Add event listener.
          window.hsConversationsOnReady = [
            () => {
              setHasLoaded(true);
            },
          ];

          // Create script component.
          let script = document.createElement('script');
          script.src = `//js-na1.hs-scripts.com/20337724.js`;
          script.async = true;
          console.info('Script loaded');

          document.body.appendChild(script);
          window.HubSpotConversations?.clear({ resetWidget: true });
          window.HubSpotConversations?.widget?.open();
          window.HubSpotConversations?.widget?.load();

          console.info('Widget loaded', hasLoaded);

          return () => {
            document?.body?.removeChild(script);
            window.hsConversationsOnReady = [];
          };
        } else {
          console.info('2 - No visitor token or email available, so not initializing chat!');
        }
      } catch (error) {
        showMessage(`Encountered an error: ${error}`, 'error');
      }
    } else {
      const timer = setTimeout(() => {
        if (window.HubSpotConversations?.widget?.remove) {
          window.HubSpotConversations.widget.remove();
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [emailAddress, firstName, hasLoaded, hubspotData.email, lastName, showMessage]);

  useEffect(() => {
    getChatSupportFlag();
  }, [getChatSupportFlag]);

  useEffect(() => {
    (async function () {
      const response = userAPIResponse;
      const showHiddenFeatures = response?.showHiddenFeatures ?? null;
      const paymentFailed = response?.hasFailedPayment ?? false;
      const isTestModeEnabled = response?.isTestModeEnabled ?? false;
      const trialModeDetails = response?.trialMode ?? { enabled: false };

      dispatch({
        type: GlobalStateActions.UPDATE_HIDDEN_FEATURE,
        payload: showHiddenFeatures,
      });
      dispatch({
        type: GlobalStateActions.PAYMENT_FAILED,
        payload: paymentFailed,
      });
      dispatch({
        type: GlobalStateActions.UPDATE_TEST_MODE,
        payload: isTestModeEnabled,
      });
      dispatch({
        type: GlobalStateActions.LOG_OUT,
        payload: false,
      });
      dispatch({
        type: GlobalStateActions.UPDATE_TRIAL_MODE,
        payload: trialModeDetails,
      });
    })();
  }, [dispatch, userAPIResponse]);

  // TODO: REDIRECT FROM OLD ROUTES
  useEffect(() => {
    const { pathname } = routerLocation;
    oldToNewRedirects(pathname, routerHistory);
  }, [routerHistory, routerLocation]);

  return (
    <Switch>
      <Route exact path={SUCCESS_ROUTE} component={SuccessCloseTab} />
      <Route exact path={FAILURE_ROUTE} component={FailureCloseTab} />

      <RouteScopesControl
        scopes={['user:read']}
        context={scopesContext}
        path={SETTINGS_ROUTE}
        component={SettingsRouter}
      />

      <RouteScopesControl scopes={['user:read']} context={scopesContext} path={PEOPLE_ROUTE} component={PeopleRouter} />
      <RouteScopesControl
        scopes={['absence', 'attendance']}
        context={scopesContext}
        path={TIME_ROUTE}
        component={TimeRouter}
      />
      <RouteScopesControl
        scopes={['payroll', 'payments', 'expenses', 'pension', 'insurance', 'invoices']}
        context={scopesContext}
        path={MONEY_ROUTE}
        component={MoneyRouter}
      />
      <RouteScopesControl scopes={['apps', 'devices']} context={scopesContext} path={TECH_ROUTE} component={ItRouter} />
      <RouteScopesControl
        scopes={['reviews', 'surveys']}
        context={scopesContext}
        path={GROWTH_ROUTE}
        component={GrowthRouter}
      />
      <RouteScopesControl scopes={['reports']} context={scopesContext} path={REPORTS_ROUTE} component={ReportRouter} />

      <RouteScopesControl
        scopes={['company.settings:all']}
        context={scopesContext}
        path={PARTNER_API_AUTHORISE_ROUTE}
        component={PublicAPIRouter}
      />

      {isSuperAdmin && <Route path={SUPER_ADMIN_ROUTE} component={SuperAdminRouter} />}

      <Route path={DASHBOARD_ROUTE} component={DashboardRouter} />

      {/* THIS SHOULD ALWAYS BE THE LAST IN THE ROUTE LIST */}
      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
