import { Box } from '@mui/material';

import '@/v2/feature/dashboard/features/sections/user-todos/user-todos.scss';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface TodoCardProps {
  readonly showBorder?: boolean;
  readonly icon: JSX.Element;
  readonly title: JSX.Element;
  readonly actionOnclick: () => void;
  readonly userAvatar?: JSX.Element;
}

export const TodoCard = ({ showBorder = true, icon, title, actionOnclick, userAvatar }: TodoCardProps) => {
  return (
    <div
      className="clickable todo-item"
      style={{
        borderBottom: showBorder ? 'none' : 'none',
        borderRadius: radius.br5,
        height: '30px',
      }}
      onClick={actionOnclick}
    >
      <div style={{ padding: spacing.p5 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center', width: '84%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {icon}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {title}
            </Box>
          </Box>

          <Box sx={{ width: '10%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {userAvatar}
          </Box>
        </Box>
      </div>
    </div>
  );
};
