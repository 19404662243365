import { FailedPayment } from '@/models/billing.model';
import axios from 'axios';

export class BillingFailedPaymentAPI {
  static async getFailedPayments(): Promise<FailedPayment[]> {
    return (await axios.get('/apiv2/billing/failed-payment')).data;
  }

  static async retryFailedPayment(params: { failedPaymentId: number }): Promise<FailedPayment[]> {
    const { failedPaymentId } = params;
    return (await axios.post('/apiv2/billing/failed-payment/retry', { failedPaymentId })).data;
  }
}
