import { useCallback } from 'react';

import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { PensionSettingsGeneralPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-general.page';
import { PensionSettingsMembersPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-members.page';
import { PensionSettingsProviderPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-provider.page';
import { PensionSettingsWorkerGroupsPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-worker-groups.page';
import { PensionEndpoints } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE,
  SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE,
  SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE,
  SETTINGS_BENEFITS_ROUTE,
} from '@/lib/routes';

export const getPageConfig = (type: string, id: number, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, { category: type, id, productType: 'standard' }),
        },
        {
          title: polyglot.t('PensionModule.providerSettings'),
          stub: 'provider',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE, { category: type, id, productType: 'standard' }),
        },
        {
          title: polyglot.t('PensionModule.workerGroups'),
          stub: 'worker-groups',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE, {
            category: type,
            id,
            productType: 'standard',
          }),
        },
        {
          title: polyglot.t('General.members'),
          stub: 'members',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, { category: type, id, productType: 'standard' }),
        },
      ],
    },
  ] as NavConfigItem[];
};

interface PensionSettingsRouterProps {
  readonly id: number;
  readonly type: string;
}

export const PensionSettingsRouter = ({ id, type }: PensionSettingsRouterProps) => {
  const { data: pensionScheme, mutate: refresh } = useApiClient(PensionEndpoints.getPension(id));

  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const refreshPension = useCallback(async () => {
    try {
      if (refresh) await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, refresh, showMessage]);

  return (
    <>
      <DomainSideMenuContent
        title={pensionScheme?.displayName ?? polyglot.t('PensionModule.pensionScheme')}
        subtitle={polyglot.t('PensionModule.pension')}
        pageConfig={getPageConfig(type, id, polyglot)}
        backPath={SETTINGS_BENEFITS_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper>
        {pensionScheme && (
          <Switch>
            <Route exact path={SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE}>
              <PensionSettingsGeneralPage type={type} pensionScheme={pensionScheme} refreshPension={refreshPension} />
            </Route>

            <Route exact path={SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE}>
              <PensionSettingsProviderPage pensionScheme={pensionScheme} refreshPension={refreshPension} />
            </Route>

            <Route exact path={SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE}>
              <PensionSettingsWorkerGroupsPage pensionScheme={pensionScheme} refreshPension={refreshPension} />
            </Route>

            <Route exact path={SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE}>
              <PensionSettingsMembersPage />
            </Route>

            <Redirect to={SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE} />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
};
