import { useContext, useEffect, useReducer, useState } from 'react';

import { Typography } from '@mui/material';
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal,
  uploadDocumentIfNeeded,
} from '@v2/feature/documents/documents.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { CreateZeltDocument, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DocumentEmptyOverview } from '@/v2/feature/documents/components/document-empty-overview.component';
import { DocumentFormModal } from '@/v2/feature/documents/components/document-form-modal.component';
import { PersonalDocuments } from '@/v2/feature/documents/document-me/components/personal-documents.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { DocumentAction } from '@/v2/feature/documents/documents.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface UserDocumentsPageProps {
  readonly userId: number;
  readonly userName: string;
}

const initialState = initialiseState([]);

export const UserDocumentsPage = ({ userId, userName }: UserDocumentsPageProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [documentFormMode, setDocumentFormMode] = useState<DocumentAction>('add');
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<readonly ZeltDocument[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');

  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(['documents:all'], getScopesContext(globalState.user));

  const [showMessage] = useMessage();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const [documents, types] = await Promise.all([
          DocumentAPI.listUserDocuments(userId),
          DocumentAPI.listDocumentTypes(),
        ]);
        setDocuments(documents);
        documentDispatch({ kind: 'set_types', value: types });
      } catch (error) {
        showMessage(
          polyglot.t('UserDocumentsPage.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [polyglot, userId, showMessage, documentDispatch]);

  function closeUploadModal() {
    setOpen(false);
  }

  const getDocuments = async () => {
    try {
      const documents = await DocumentAPI.listUserDocuments(userId);
      setDocuments(documents);
    } catch (error) {
      showMessage(
        polyglot.t('UserDocumentsPage.errorMessages.retrieve', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const completeSave = async () => {
    try {
      let dynamicFileUuid;
      if (documentState.documentLink && !documentState.fileUuid) {
        dynamicFileUuid = await uploadDocumentIfNeeded(documentState.documentLink);
      }
      if (!documentState.fileUuid && !dynamicFileUuid) {
        showMessage(polyglot.t('UserDocumentsPage.errorMessages.select'), 'error');
        return;
      }
      let documentObject: CreateZeltDocument = {
        name: documentState.name || '',
        type: documentState.type?.value ?? '',
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid ?? dynamicFileUuid,
        note: documentState.note,
        expirySettings: documentState?.expirySettings ?? { expiryDate: '', documentExpires: false },
      };
      if (documentState.id && documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t('UserDocumentsPage.successMessages.update'), 'success');
      } else if (documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.createDocument(documentObject);
        showMessage(polyglot.t('UserDocumentsPage.successMessages.save'), 'success');
      }
      await getDocuments();
    } catch (error) {
      showMessage(
        polyglot.t('UserDocumentsPage.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  return (
    <RootStyle>
      {!loading && documents.length < 1 ? (
        <>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                {polyglot.t('UserDocumentsPage.documents')}
              </Typography>
            }
          />
          <ContentWrapper>
            <DocumentEmptyOverview
              onUploadClick={() => {
                documentDispatch({ kind: 'add_document', owner: { label: userName, value: userId } });
                setDocumentFormMode('add');
                setFilterValue(
                  getDocumentParentCategory(
                    'me',
                    'add',
                    undefined,
                    currentUserIsDocumentAdmin,
                    documentState.types as ZeltDocumentType[]
                  )
                );
                setOpen(true);
              }}
              onRequestClick={() => {
                documentDispatch({ kind: 'request_document', owner: { label: userName, value: userId }, reach: 'me' });
                setDocumentFormMode('request');
                setFilterValue(
                  getDocumentParentCategory(
                    'me',
                    'request',
                    undefined,
                    currentUserIsDocumentAdmin,
                    documentState.types as ZeltDocumentType[]
                  )
                );
                setOpen(true);
              }}
              isAdmin={currentUserIsDocumentAdmin}
            />
          </ContentWrapper>
        </>
      ) : (
        <>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                {polyglot.t('UserDocumentsPage.documents')}
              </Typography>
            }
            showAction={true}
            actions={
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="small"
                onClick={() => {
                  documentDispatch({ kind: 'add_document', owner: { label: userName, value: userId } });
                  setDocumentFormMode('add');
                  setFilterValue(
                    getDocumentParentCategory(
                      'me',
                      'add',
                      undefined,
                      currentUserIsDocumentAdmin,
                      documentState.types as ZeltDocumentType[]
                    )
                  );
                  setOpen(true);
                }}
              >
                {polyglot.t('UserDocumentsPage.newDocument')}
              </ButtonComponent>
            }
          />
          <PersonalDocuments
            userId={userId}
            userName={userName}
            setDocumentFormMode={setDocumentFormMode}
            documentState={documentState}
            documentDispatch={documentDispatch}
            setOpen={setOpen}
            loading={loading}
            documents={documents}
            setFilterValue={setFilterValue}
          />
        </>
      )}

      <DocumentFormModal
        mode={documentFormMode}
        state={documentState}
        currentUserIsAdmin={currentUserIsDocumentAdmin}
        dispatch={documentDispatch}
        newDocumentTypes={documentState.types as ZeltDocumentType[]}
        onClose={closeUploadModal}
        onSubmit={completeSave}
        setOpen={setOpen}
        open={open}
        setLoading={setLoading}
        getDocuments={getDocuments}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      />
    </RootStyle>
  );
};
