import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReportConfigBackButton } from '@v2/feature/reports/reports-advanced/components/report-config-back-button.component';
import { selectColumnUtil, unselectColumnUtil } from '@v2/feature/reports/reports-advanced/reports-advanced.util';
import {
  ReportColumnCategory,
  ReportColumnOption,
  ReportColumnType,
  SelectedColumnsRequest,
} from '@v2/feature/reports/reports.interface';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface Props {
  readonly selectedColumns: SelectedColumnsRequest;
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnsRequest>>;
  readonly goBack: () => void;
  readonly reportColumns: readonly ReportColumnCategory[];
}

export const ReportColumnsSelection = ({ selectedColumns, setSelectedColumns, reportColumns, goBack }: Props) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const [selectedColumnsLocal, setSelectedColumnsLocal] = useState<SelectedColumnsRequest>({});

  const filteredColumns = useMemo(() => {
    if (!searchInput) return reportColumns;

    const search = searchInput.toLowerCase();

    return reportColumns
      .filter((c) => {
        for (let key in c.columns) {
          if (c.columns[key].label.toLowerCase().includes(search)) return true;
        }
        return c.category.toLowerCase().includes(search);
      })
      .map((c) => {
        if (c.category.toLowerCase().includes(search)) return c;

        const filteredCols: Record<string, ReportColumnOption> = {};
        for (const key in c.columns) {
          if (c.columns[key].label.toLowerCase().includes(search)) {
            filteredCols[key] = c.columns[key];
          }
        }
        return { ...c, columns: filteredCols };
      });
  }, [reportColumns, searchInput]);

  const addColumns = useCallback(
    (selectedColumnsLocal: SelectedColumnsRequest) => {
      setSelectedColumns((prevSelectedColumns) => {
        // merge localSelected columns into the already selected columns and keep both sets of values in the new instance (even if a column is repeated)
        const prevCopy = { ...prevSelectedColumns };
        for (const key in selectedColumnsLocal) {
          if (!prevCopy[key]) prevCopy[key] = [];
          prevCopy[key].push(...selectedColumnsLocal[key]);
        }
        return prevCopy;
      });
      goBack();
    },
    [goBack, setSelectedColumns]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <ReportConfigBackButton title="Columns" goBack={goBack} />

      <TableSearch
        query={searchInput}
        handleChange={(e) => {
          setSearchInput(e.target.value);
        }}
        style={{ minWidth: '150px', maxWidth: '240px' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          paddingRight: '4px',
        }}
      >
        {filteredColumns.map((c) => (
          <Box key={c.stub}>
            <Typography key={c.stub} variant="title4">
              {c.category}
            </Typography>
            {Object.keys(c.columns).map((key) => {
              const isSelected = Boolean(
                selectedColumnsLocal[c.stub] && selectedColumnsLocal[c.stub].some((c) => c.col === key)
              );
              const previousSelectionNumber = selectedColumns[c.stub]
                ? selectedColumns[c.stub].filter((col) => col.col === key).length
                : 0;

              return (
                <Box key={c.columns[key].label} sx={{ display: 'flex', gap: spacing.s1, alignItems: 'center' }}>
                  <CheckboxComponent
                    label={c.columns[key].label}
                    name={c.columns[key].label}
                    checked={isSelected}
                    value="allSelected"
                    onChange={(_, checked) => {
                      if (!checked) {
                        setSelectedColumnsLocal((prev) => {
                          let prevCopy = { ...prev };
                          // on uncheck delete all the operators for this column
                          for (const type of Object.values(ReportColumnType)) {
                            prevCopy = unselectColumnUtil(prevCopy, c.stub, key, type as ReportColumnType);
                          }
                          return prevCopy;
                        });
                      } else {
                        // default type is plain unless list of operators is set and PLAIN is not allowed, in that case use the first available operator
                        let type = ReportColumnType.PLAIN;
                        if (
                          c.columns[key].operators &&
                          c.columns[key].operators!.length > 0 &&
                          !c.columns[key].operators!.includes(ReportColumnType.PLAIN)
                        )
                          type = c.columns[key].operators![0];

                        setSelectedColumnsLocal((prev) => {
                          return selectColumnUtil(prev, c.stub, key, type, c.columns[key].label, selectedColumns);
                        });
                      }
                    }}
                  />
                  {previousSelectionNumber > 0 && (
                    <Typography variant="caption" color="Grey">
                      ({previousSelectionNumber + (isSelected ? 1 : 0)})
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        ))}
      </Box>
      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
          onClick={() => addColumns(selectedColumnsLocal)}
        >
          Add
        </ButtonComponent>
      </Box>
    </Box>
  );
};
