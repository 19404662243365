import { ChangeEventHandler, useState } from 'react';

import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import { v4 as uuidv4 } from 'uuid';

import { BillingDiscountAPI } from '@/api-client/billing-discount.api';
import useMessage from '@/hooks/notification.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const SuperAdminBillingDiscountAddForm = (props: { onSave?: () => Promise<void> }) => {
  const [discountName, setDiscountName] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountStartDate, setDiscountStartDate] = useState<string>(new LocalDate().toDateString());
  const [discountEndDate, setDiscountEndDate] = useState<string>(new LocalDate().toDateString());
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDiscountName(e.target.value);
  };

  const handleTypeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDiscountType(e.target.value);
  };

  const handleAmountChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDiscountAmount(Number(e.target.value));
  };

  const handleStartDateChange = (newDate: string) => {
    setDiscountStartDate(newDate);
  };

  const handleEndDateChange = (newDate: string) => {
    setDiscountEndDate(newDate);
  };

  const getValidationError = (): string | null => {
    if (Number(discountAmount) <= 0) {
      return 'Amount should be greater than zero';
    }

    if (!discountName) {
      return 'Discount name must be provided';
    }

    if (!discountType || discountType === 'none') {
      return 'Discount type must be provided';
    }

    return null;
  };

  const saveDiscount = async () => {
    try {
      const errorMessage = getValidationError();

      if (errorMessage) {
        return showMessage(errorMessage, 'error');
      }

      const discountPayload = {
        amountValue: Number(discountAmount),
        description: discountName,
        discountType,
        startDate: discountStartDate,
        endDate: discountEndDate,
      };

      setIsSaving(true);
      await BillingDiscountAPI.createDiscount(discountPayload);
      showMessage('Discount created successfully', 'success');

      if (props.onSave) {
        await props.onSave();
      }

      setIsSaving(false);
    } catch (e) {
      console.error('::: Failed to create discount ::', e);
      showMessage('Failed to create discount', 'error');
      setIsSaving(false);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: 5 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: 4, mb: 4 }}>Add a discount</Typography>
        <TextField
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          onChange={handleNameChange}
          value={discountName}
          label="Discount Name"
          key={1}
        ></TextField>
        <TextField
          select
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          onChange={handleTypeChange}
          label="Discount Type"
          key={2}
          defaultValue={discountType}
        >
          <MenuItem value={'none'}>None</MenuItem>
          <MenuItem value={'fixed'}>Fixed (£)</MenuItem>
          <MenuItem value={'percentage'}>Percentage (%)</MenuItem>
        </TextField>
        <TextField
          type="number"
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          onChange={handleAmountChange}
          label="Discount Amount"
          key={3}
          defaultValue={discountAmount}
        ></TextField>
        <Box sx={fieldSx}>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={discountStartDate}
            onChange={(value) => {
              handleStartDateChange(value);
            }}
            name="startDate"
            label="Start date"
          />
        </Box>
        <Box sx={fieldSx}>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={discountEndDate}
            onChange={(value) => {
              handleEndDateChange(value);
            }}
            name="endDate"
            label="End date"
          />
        </Box>
        <Box sx={buttonBoxSx}>
          <LoaderButton
            name="Save"
            type="button"
            loading={isSaving}
            sizeVariant="large"
            colorVariant="primary"
            onClick={saveDiscount}
            fullWidth
            key={uuidv4()}
          />
        </Box>
      </Box>
    </>
  );
};
