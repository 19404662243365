import { useContext } from 'react';

import { DevicesStoreSettingsPage } from '@v2/feature/device/features/devices-settings/features/general/devices-store-settings.page';
import { AndroidMDMSettings } from '@v2/feature/device/features/devices-settings/features/mdm-android/android-mdm.page';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  SETTINGS_DEVICES_ANDROID_MDM_ROUTE,
  SETTINGS_DEVICES_DEP_ROUTE,
  SETTINGS_DEVICES_GENERAL_ROUTE,
  SETTINGS_DEVICES_MDM_ROUTE,
  SETTINGS_DEVICES_STORE_ROUTE,
  SETTINGS_DEVICES_ZELT_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
} from '@/lib/routes';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';
import { DevicesGeneralPage } from '@/v2/feature/device/features/devices-settings/features/general/devices-settings.page';
import { DevicesMDMSettings } from '@/v2/feature/device/features/devices-settings/features/mdm/devices-mdm.page';
import { CreateCustomDepWizardPage } from '@/v2/feature/device/features/devices-settings/features/zelt-mdm/create-custom-dep-wizard.page';
import {
  ZeltMdmRouter,
  ZeltMobileMdmRouter,
} from '@/v2/feature/device/features/devices-settings/features/zelt-mdm/zelt-mdm.router';

export const DevicesSettingsRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  return (
    <Switch>
      <RouteScopesControl
        context={scopesContext}
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_DEVICES_ZELT_ROUTE}
      >
        <ZeltMdmRouter />
      </RouteScopesControl>
      <RouteScopesControl
        context={scopesContext}
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_MOBILE_DEVICES_ZELT_ROUTE}
      >
        <ZeltMobileMdmRouter />
      </RouteScopesControl>
      <RouteScopesControl
        context={scopesContext}
        exact
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_DEVICES_MDM_ROUTE}
      >
        <DevicesMDMSettings />
      </RouteScopesControl>
      <RouteScopesControl
        context={scopesContext}
        exact
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_DEVICES_ANDROID_MDM_ROUTE}
      >
        <AndroidMDMSettings />
      </RouteScopesControl>
      <RouteScopesControl
        context={scopesContext}
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_DEVICES_STORE_ROUTE}
      >
        <DevicesStoreSettingsPage />
      </RouteScopesControl>

      <RouteScopesControl
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_DEVICES_DEP_ROUTE}
        context={scopesContext}
      >
        <CreateCustomDepWizardPage />
      </RouteScopesControl>

      <RouteScopesControl
        context={scopesContext}
        scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
        path={SETTINGS_DEVICES_GENERAL_ROUTE}
      >
        <DevicesGeneralPage />
      </RouteScopesControl>

      <Redirect to={SETTINGS_DEVICES_GENERAL_ROUTE} />
    </Switch>
  );
};
