import { useCallback, useEffect, useState } from 'react';

import { UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { generatePath, useHistory } from 'react-router-dom';

import { useUserIdParam } from '@/hooks/userid-param.hook';
import { LOGIN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { CachedUsersProvider } from '@/v2/feature/user/context/cached-users.context';
import { OnboardingPayrollNonUK } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll-non-uk.page';
import { OnboardingPayrollUK } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll-uk.page';
import { isUKCountryCode } from '@/v2/infrastructure/country/country.util';

type OnboardingPayrollPageProps = {
  mode: 'onboarding' | 'new-starter';
};

export const OnboardingPayrollPage = ({ mode }: OnboardingPayrollPageProps) => {
  const routerHistory = useHistory();
  const userId = useUserIdParam();

  const [userPayroll, setUserPayroll] = useState<UserPayrollDto | null>();
  const [payrollSchema, setPayrollSchema] = useState<PayrollSchema | null>();

  const getUserP45data = useCallback(async () => {
    if (!Number.isInteger(userId)) {
      routerHistory.replace(LOGIN_ROUTE);
      return;
    }
    const [data, schema] = await Promise.all([
      PayrollAPI.getActiveUserPayroll(userId),
      PayrollAPI.getUserPayrollSchema(userId),
    ]);
    setUserPayroll(data || null);
    setPayrollSchema(schema || null);
  }, [routerHistory, userId]);

  useEffect(() => {
    getUserP45data();
  }, [getUserP45data]);

  const onClose = useCallback(() => {
    ({
      onboarding: () => routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId })),
      'new-starter': () => routerHistory.goBack(),
    }[mode]());
  }, [mode, routerHistory, userId]);

  if (userPayroll === undefined || payrollSchema === undefined) {
    return <></>;
  }

  return (
    <CachedUsersProvider>
      {!payrollSchema || isUKCountryCode(payrollSchema.countryCode) ? (
        <OnboardingPayrollUK userId={userId} userPayroll={userPayroll} onClose={onClose} />
      ) : (
        <OnboardingPayrollNonUK
          userId={userId}
          userPayroll={userPayroll}
          payrollSchema={payrollSchema}
          onClose={onClose}
        />
      )}
    </CachedUsersProvider>
  );
};
