import { Dispatch, SetStateAction } from 'react';

import { Box, InputAdornment } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ISCGeneralScheduledReopenModal {
  surveyCycle: SurveyCycle | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}

export const SCGeneralScheduledReopenModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: ISCGeneralScheduledReopenModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCGeneralScheduledReopenContent surveyCycle={surveyCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

interface ISCGeneralScheduledReopenContent {
  surveyCycle: SurveyCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
}

const SCGeneralScheduledReopenContent = ({ surveyCycle, onClose, refresh }: ISCGeneralScheduledReopenContent) => {
  const [showMessage] = useMessage();

  const validationSchema = yup.object({
    extendFor: yup.number().integer().min(1, 'Extension must be at least 1 day').required('Extension is required'),
  });

  const formik = useFormik({
    initialValues: {
      extendFor: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (surveyCycle) {
          await SurveyCycleAPI.reopenCycle(surveyCycle.id, values.extendFor);
          showMessage(`Survey successfully opened survey cycle`, 'success');
          await refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Reopen cycle</Typography>
        <Typography variant="caption" color="Grey">
          Are you sure you want to reopen this survey cycle? You will be able to edit participants, timeline settings,
          and reminders. Specify the number of additional days to extend the end date for this cycle below.
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
          <TextfieldComponent
            name="extendFor"
            label="Survey is extended for"
            value={formik.values.extendFor}
            onChange={formik.handleChange}
            error={formik.touched?.extendFor && !!formik.errors?.extendFor}
            helperText={(formik.touched?.extendFor && formik.errors?.extendFor) ?? ' '}
            endAdornment={
              <InputAdornment position="end">
                <Typography variant="caption">days</Typography>
              </InputAdornment>
            }
            type="number"
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent onClick={onClose} sizeVariant="medium" colorVariant="secondary" fullWidth>
            Cancel
          </ButtonComponent>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name="Reopen"
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
