import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { TabFilterButtons } from '@v2/components/tab-filter-buttons.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOwnership } from '@v2/feature/device/device.interface';
import { getModelImage } from '@v2/feature/device/device.util';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { RefinancePlanCreateAccountDrawer } from '@v2/feature/super-admin/features/super-admin-refinancing/components/refinance-plan-create-account-drawer.component';
import { RefinancePlanDrawer } from '@v2/feature/super-admin/features/super-admin-refinancing/components/refinance-plan-drawer.component';
import { SearchCompanyNationalIdDrawer } from '@v2/feature/super-admin/features/super-admin-refinancing/components/search-company-national-id-drawer.component';
import { RefinancingAPI } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api';
import {
  DeviceOrderWithCompanyDetails,
  RefinancingCompanyStatus,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { isBefore } from 'date-fns';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Task } from '@/images/dashboard-icons/Task.svg';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';

const ZELT_ID = 320;
const TabFilter = [
  { name: 'All', value: 'all' },
  { name: 'Invoiced by Zelt', value: 'InvoicedByZelt' },
  { name: 'Invoiced by Two', value: 'InvoicedByTwo' },
];

interface SuperAdminRefinancingDevicesPageProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
}

export const SuperAdminRefinancingDevicesPage = ({ companies }: SuperAdminRefinancingDevicesPageProps): JSX.Element => {
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState<boolean>(false);
  const [isPlanDrawerOpen, setIsPlanDrawerOpen] = useState<boolean>(false);
  const [isCreateRepaymentAccountDrawerOpen, setIsCreateRepaymentAccountDrawerOpen] = useState<boolean>(false);

  // Set a unique id each time a refresh is triggered so the creation of refinancing plan is idempotent if the same request is sent multiple times before a refresh
  const [idempotencyIdForRefinancingPlan, setIdempotencyIdForRefinancingPlan] = useState<number>(0);

  const [selectedCompanyData, setSelectedCompanyData] = useState<DeviceOrderWithCompanyDetails | null>(null);
  const [deviceOrders, setDeviceOrders] = useState<DeviceOrderWithCompanyDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>('all');
  const [searchInput, setSearchInput] = useState<string>('');

  const today = useMemo(() => {
    const now = new Date();
    now.setHours(23);
    now.setMinutes(59);
    return now;
  }, []);

  const [showMessage] = useMessage();

  const refreshOrders = useCallback(async () => {
    try {
      // TODO: @devices-now - this returns all orders - but the orders for devices that have already been returned should not appear here anymore.
      setLoading(true);
      const deviceOrders = (await DeviceAPI.getDeviceOrdersAsSuperadmin(true)).filter(
        (order) => order.companyId !== ZELT_ID
      );
      const companyIds = [...new Set(deviceOrders.map((order) => order.companyId))];
      const externallyInvoicedOrderIds = deviceOrders
        .filter((order) => order.externallyInvoiced)
        .map((order) => order.id);
      const companyRefinancingArray = await RefinancingAPI.getCompaniesRefinancingData(companyIds);
      const companyRefinancingPlansArray = await RefinancingAPI.getCompaniesRepaymentPlansForOrders(
        companyIds,
        externallyInvoicedOrderIds
      );

      const ordersWithCompanyDetails = deviceOrders
        .filter((order) => order.device?.ownership === DeviceOwnership.Rental)
        .map((order) => ({
          ...order,
          company: companies.find((c) => c.companyId === order.companyId),
          refinancing: companyRefinancingArray.find((c) => c.companyId === order.companyId),
          refinancingPlan: companyRefinancingPlansArray.find((plan) => plan.orderId === order.id),
        }));
      setDeviceOrders(ordersWithCompanyDetails);

      // close all drawers
      setSelectedCompanyData(null);
      setIsSearchDrawerOpen(false);
      setIsPlanDrawerOpen(false);
      setIsCreateRepaymentAccountDrawerOpen(false);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }

    setIdempotencyIdForRefinancingPlan(Math.floor(Math.random() * 1000) + 1000);
  }, [companies, showMessage]);

  useEffect(() => {
    refreshOrders();
  }, [refreshOrders]);

  const columnRows = useMemo(() => {
    let orders = [...deviceOrders];
    if (filterValue.includes('InvoicedByZelt')) {
      orders = orders.filter(
        (order) =>
          order.refinancingPlan?.fulfilmentStatus !== 'SUCCEEDED' ||
          !order.externallyInvoiced ||
          isBefore(today, new Date(order.externallyInvoiced))
      );
    } else if (filterValue.includes('InvoicedByTwo')) {
      orders = orders.filter(
        (order) =>
          order.refinancingPlan?.fulfilmentStatus === 'SUCCEEDED' &&
          order.externallyInvoiced &&
          !isBefore(today, new Date(order.externallyInvoiced))
        // TODO: @two - handle the case when the invoice by two period is finished, so the device comes back to Zelt invoicing
      );
    }

    if (searchInput) {
      orders = orders.filter((order) => {
        // TODO: This is not efficient at all - should get the default entity directly
        const defaultEntity = order.company?.entities?.find((e) => e.isDefaultBillingEntity);
        return (
          order.company?.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          defaultEntity?.nationalId?.includes(searchInput) ||
          defaultEntity?.legalName?.includes(searchInput) ||
          order.deviceModel?.modelName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    return orders;
  }, [today, filterValue, searchInput, deviceOrders]);

  const columnData = useMemo<ColumnDef<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>[]>(() => {
    return [
      {
        header: () => 'Device',
        accessorFn: (row) => row,
        id: 'modelName',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.deviceModel?.modelName ? (
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
              <Box>
                {getModelImage(deviceOrder.deviceModel?.type, deviceOrder.deviceModel?.modelName, {
                  width: '30px',
                  height: 'auto',
                })}
              </Box>
              <Typography sx={themeFonts.caption}>{deviceOrder.deviceModel.modelName}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.status ? (
            <Typography sx={themeFonts.caption}>{deviceOrder.status}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Company name',
        accessorFn: (row) => row,
        id: 'companyName',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.company?.name ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={themeFonts.caption}>{deviceOrder.company.name}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Legal name',
        accessorFn: (row) => row,
        id: 'legalName',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          if (!deviceOrder.company?.entities) return <EmptyCell />;

          const defaultEntity = deviceOrder.company?.entities.find((e) => e.isDefaultBillingEntity);
          return defaultEntity?.legalName ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={themeFonts.caption}>{defaultEntity.legalName}</Typography>
              {deviceOrder.company.entities[0].nationalId && (
                <Typography sx={themeFonts.caption}>#{defaultEntity.nationalId}</Typography>
              )}
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Company status',
        accessorFn: (row) => row,
        id: 'companyStatus',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.refinancing?.creditCheckStatus ? (
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
              {deviceOrder.refinancing.creditCheckStatus === RefinancingCompanyStatus.Approved ? (
                <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
              ) : (
                <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
              )}
              <Typography sx={themeFonts.caption}>{deviceOrder.refinancing.creditCheckStatus}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Repayment account',
        accessorFn: (row) => row,
        id: 'repaymentAccount',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.refinancing?.accountId ? (
            <Tooltip title={deviceOrder.refinancing?.accountId} placement="top">
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                <Typography sx={themeFonts.caption}>CREATED</Typography>
              </Box>
            </Tooltip>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Funding status',
        accessorFn: (row) => row,
        id: 'fundingStatus',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.refinancingPlan ? (
            <Tooltip title={deviceOrder.refinancingPlan.externalId} placement="top">
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                {deviceOrder.refinancingPlan.fundingStatus === 'ACCEPTED' ? (
                  <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                ) : deviceOrder.refinancingPlan.fundingStatus === 'REJECTED' ? (
                  <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
                ) : (
                  <Waiting {...iconSize} style={{ fill: themeColors.GreyMiddle }} />
                )}
                <Typography sx={themeFonts.caption}>
                  {deviceOrder.refinancingPlan.fundingStatus ?? 'CREATED'}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Fulfilment status',
        accessorFn: (row) => row,
        id: 'fulfilmentStatus',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.refinancingPlan ? (
            <Tooltip title={deviceOrder.refinancingPlan.externalId} placement="top">
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                {deviceOrder.refinancingPlan.fulfilmentStatus === 'SUCCEEDED' ? (
                  <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                ) : deviceOrder.refinancingPlan.fulfilmentStatus === 'FAILED' ? (
                  <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
                ) : (
                  <Waiting {...iconSize} style={{ fill: themeColors.GreyMiddle }} />
                )}
                <Typography sx={themeFonts.caption}>
                  {deviceOrder.refinancingPlan.fulfilmentStatus ?? 'CREATED'}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Device',
        accessorFn: (row) => row,
        id: 'device.serialNumber',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.device?.serialNumber ? (
            <Typography sx={themeFonts.caption}>{deviceOrder.device.serialNumber}</Typography>
          ) : (
            <Tooltip title="Missing serial number" placement="top">
              <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
            </Tooltip>
          );
        },
      },
      {
        header: () => 'Order details',
        accessorFn: (row) => row,
        id: 'contractLength',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.contractLength ? (
            <Typography sx={themeFonts.caption}>{deviceOrder.contractLength} months</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Delivery date',
        accessorFn: (row) => row,
        id: 'deliveryDate',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.deliveryDate ? (
            <Typography sx={themeFonts.caption}>{new Date(deviceOrder.deliveryDate).toLocaleDateString()}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Price',
        accessorFn: (row) => row,
        id: 'price',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.price ? (
            <Typography sx={themeFonts.caption}>{formatMoney({ amount: deviceOrder.price })}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Invoiced by',
        accessorFn: (row) => row,
        id: 'externallyInvoiced',
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return deviceOrder.externallyInvoiced && isBefore(new Date(deviceOrder.externallyInvoiced), today) ? (
            <Box>
              <Typography sx={themeFonts.caption}>Invoiced by Two</Typography>
              <Typography sx={themeFonts.caption}>
                {new Date(deviceOrder.externallyInvoiced).toLocaleDateString()}
              </Typography>
            </Box>
          ) : //   TODO: @two - handle the case when the invoice by two period is finished, so the device comes back to Zelt invoicing
          deviceOrder.externallyInvoiced ? (
            <Tooltip
              title={`Scheduled to be invoiced by Two starting with ${new Date(
                deviceOrder.externallyInvoiced
              ).toLocaleDateString()}`}
              placement="top"
            >
              <Typography sx={themeFonts.caption}>Invoiced by Zelt</Typography>
            </Tooltip>
          ) : (
            <Typography sx={themeFonts.caption}>Invoiced by Zelt</Typography>
          );
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderWithCompanyDetails, DeviceOrderWithCompanyDetails>) => {
          const deviceOrder: DeviceOrderWithCompanyDetails = info.getValue();
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                alignItems: 'center',
                gap: spacing.gap5,
              }}
            >
              <StyledMenuComponent
                options={[
                  {
                    handler: () => {
                      setSelectedCompanyData(deviceOrder);
                      setIsSearchDrawerOpen(true);
                    },
                    label: 'Credit check',
                    icon: <Eye {...iconSize} />,
                  },
                  {
                    handler: async () => {
                      if (!!deviceOrder.refinancing?.accountId) {
                        showMessage('An account for this company has been already created.', 'info');
                        return;
                      }
                      setIsCreateRepaymentAccountDrawerOpen(true);
                      setSelectedCompanyData(deviceOrder);
                    },
                    label: 'Create repayment account',
                    icon: <Task {...iconSize} />,
                    hidden:
                      !!deviceOrder.refinancing?.accountId ||
                      deviceOrder.refinancing?.creditCheckStatus !== RefinancingCompanyStatus.Approved,
                  },
                  {
                    handler: () => {
                      setSelectedCompanyData(deviceOrder);
                      setIsPlanDrawerOpen(true);
                    },
                    label: 'Refinance',
                    icon: <Task {...iconSize} />,
                    hidden: !deviceOrder.refinancing?.accountId,
                    disabled:
                      deviceOrder.refinancing?.creditCheckStatus !== RefinancingCompanyStatus.Approved ||
                      !deviceOrder.deviceId,
                  },
                ]}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <ActionsSmall {...iconSize} />,
                }}
              />
            </Box>
          );
        },
      },
    ];
  }, [today, showMessage]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Refinancing</Typography>}
      />

      <ContentWrapper loading={loading}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TabFilterButtons
            filters={TabFilter}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            hasSearch
            onFilterChange={({ filterValue, searchInput }) => {
              setFilterValue(filterValue);
              setSearchInput(searchInput);
            }}
          />

          <ButtonComponent onClick={refreshOrders} type="button" sizeVariant="small" colorVariant="secondary">
            Refresh
          </ButtonComponent>
        </Box>

        <Box sx={spacing.mt20}>
          <BasicTable<DeviceOrderWithCompanyDetails> rowData={[...columnRows]} columnData={columnData} />
        </Box>

        {isSearchDrawerOpen && selectedCompanyData && (
          <SearchCompanyNationalIdDrawer
            isOpen={isSearchDrawerOpen}
            setIsOpen={setIsSearchDrawerOpen}
            order={selectedCompanyData}
            refresh={refreshOrders}
          />
        )}

        {isPlanDrawerOpen && selectedCompanyData && (
          <RefinancePlanDrawer
            isOpen={isPlanDrawerOpen}
            setIsOpen={setIsPlanDrawerOpen}
            order={selectedCompanyData}
            refresh={refreshOrders}
            idempotencyId={idempotencyIdForRefinancingPlan}
          />
        )}

        {isCreateRepaymentAccountDrawerOpen && selectedCompanyData && (
          <RefinancePlanCreateAccountDrawer
            isOpen={isCreateRepaymentAccountDrawerOpen}
            setIsOpen={setIsCreateRepaymentAccountDrawerOpen}
            order={selectedCompanyData}
            refresh={refreshOrders}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
