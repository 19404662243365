import React, { useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { BenefitsSettingsPageSection } from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import { ManageWorkerGroupDrawer } from '@v2/feature/benefits/subfeature/pension/components/manage-pension-worker-group-drawer.component';
import { PensionEndpoints } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto, PensionWorkerGroupDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';

interface PageProps {
  readonly pensionScheme: PensionSchemeDto;
  readonly refreshPension: () => Promise<void>;
}

export const PensionSettingsWorkerGroupsPage = ({ pensionScheme, refreshPension }: PageProps) => {
  const { data: providerEmployees, isLoading } = useApiClient(
    PensionEndpoints.fetchPensionSchemeEmployeesPension(pensionScheme.id),
    { suspense: false }
  );

  const { polyglot } = usePolyglot();

  const [isWorkerGroupDrawerOpen, setIsWorkerGroupDrawerOpen] = useState<boolean>(false);
  const [selectedWorkerGroup, setSelectedWorkerGroup] = useState<PensionWorkerGroupDto | null>(null);

  const workerGroupColumns = useMemo<ColumnDef<PensionWorkerGroupDto, PensionWorkerGroupDto>[]>(
    () => [
      {
        header: () => polyglot.t('General.name'),
        id: 'name',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => <div>{original.name ?? <EmptyCell />}</div>,
      },
      {
        header: () => polyglot.t('PensionModule.employeesEnrolled'),
        id: 'display',
        cell: ({ row: { original } }) => (
          <div>
            {providerEmployees?.filter((employee) => employee.workerGroupId === original.externalId).length ?? (
              <EmptyCell />
            )}
          </div>
        ),
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
      },
      {
        header: () => polyglot.t('BenefitModule.employerContribution'),
        id: 'employerContribution',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) =>
          original.employerContribution ? <div>{original.employerContribution}%</div> : <EmptyCell />,
      },
      {
        header: () => polyglot.t('BenefitModule.employeeContribution'),
        id: 'employeeContribution',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) =>
          original.employeeContribution ? <div>{original.employeeContribution}%</div> : <EmptyCell />,
      },
      {
        header: () => '',
        id: 'editIcon',
        accessorFn: (row) => row,
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton
              aria-label="settings"
              onClick={() => {
                setSelectedWorkerGroup(original);
                setIsWorkerGroupDrawerOpen(true);
              }}
              sx={tableIconButtonSx}
            >
              <Edit {...iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [polyglot, providerEmployees]
  );

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('PensionModule.workerGroups')}
      buttons={[
        <IconButton
          onClick={() => {
            setSelectedWorkerGroup(null);
            setIsWorkerGroupDrawerOpen(true);
          }}
          title={polyglot.t('General.add')}
          sx={tableIconButtonSx}
        >
          <Plus {...iconSize} />
        </IconButton>,
      ]}
    >
      <BasicTable rowData={pensionScheme.workerGroups ?? []} columnData={workerGroupColumns} loading={isLoading} />

      <ManageWorkerGroupDrawer
        isOpen={isWorkerGroupDrawerOpen}
        setIsOpen={setIsWorkerGroupDrawerOpen}
        refresh={refreshPension}
        workerGroup={selectedWorkerGroup}
        pensionSchemeId={pensionScheme.id}
      />
    </BenefitsSettingsPageSection>
  );
};
