import axios from 'axios';

import { ReviewSection, ReviewSectionUpsert } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';

export class ReviewSectionAPI {
  static async createSectionByCycleId(sectionBody: ReviewSectionUpsert): Promise<ReviewSection> {
    return (await axios.post('/apiv2/review-section', sectionBody)).data;
  }

  static async updateReviewSectionInBank(sectionBody: ReviewSectionUpsert): Promise<void> {
    await axios.patch('/apiv2/review-section', sectionBody);
  }

  static async deleteReviewSection(sectionId: string, cycleId: string): Promise<void> {
    await axios.delete(`/apiv2/review-section/${sectionId}/${cycleId}`);
  }
}
