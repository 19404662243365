import React from 'react';

import useMessage from '@/hooks/notification.hook';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SectionModalContent } from '@/v2/feature/growth/reviews/features/components/section-modal-content.component';
import { SurveySectionBankAPI } from '@/v2/feature/growth/surveys/api-client/survey-section-bank.api';
import {
  SurveySectionBank,
  SurveySectionBankCreate,
  SurveySectionBankUpdate,
} from '@/v2/feature/growth/surveys/interfaces/survey-section-bank.interface';

export const SurveyTemplateSectionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  section,
  refresh,
  templateId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  section: SurveySectionBank | null;
  refresh: () => Promise<void>;
  templateId: string;
}) => {
  const [showMessage] = useMessage();

  const onSubmit = async (values: { name: string }) => {
    if (section) {
      const updatedValues = { id: section.id, name: values.name, templateId };
      await SurveySectionBankAPI.updateSurveySectionInBank(updatedValues as SurveySectionBankUpdate);
      showMessage('Section updated successfully', 'success');
    } else {
      const updatedValues = { name: values.name, templateId: templateId };
      await SurveySectionBankAPI.createSurveySectionInBank(updatedValues as SurveySectionBankCreate);
      showMessage('Section created successfully', 'success');
    }
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <SectionModalContent
        onClose={onClose}
        section={section as Pick<SurveySectionBank, 'name'>}
        refresh={refresh}
        onSubmit={onSubmit}
      />
    </DrawerModal>
  );
};

// const SectionModalContent = ({
//   onClose,
//   section,
//   refresh,
//   templateId,
// }: {
//   onClose: () => void;
//   section: SurveySectionBank | null;
//   refresh: () => Promise<void>;
//   templateId: string;
// }) => {
//   const [loading, setLoading] = useState<boolean>(false);
//   const [showMessage] = useMessage();
//   const { polyglot } = usePolyglot();

//   const formik = useFormik({
//     initialValues: section ?? {
//       name: '',
//       templateId: templateId,
//     },
//     enableReinitialize: true,
//     validationSchema: SectionSchema(),
//     onSubmit: async (values) => {
//       setLoading(true);
//       try {
//         if (section) {
//           await SurveySectionBankAPI.updateSurveySectionInBank(values);
//           showMessage('Section updated successfully', 'success');
//         } else {
//           await SurveySectionBankAPI.createSurveySectionInBank(values);
//           showMessage('Section created successfully', 'success');
//         }

//         refresh();
//         onClose();
//       } catch (error) {
//         showMessage("Couldn't create the section", 'error');
//       } finally {
//         setLoading(false);
//       }
//     },
//   });

//   return (
//     <FormikProvider value={formik}>
//       <Form onSubmit={formik.handleSubmit}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
//           <Box sx={{ mb: spacing.m10 }}>
//             <Typography variant="title2">{section ? 'Edit section' : 'New section'}</Typography>
//           </Box>

//           <Box sx={fieldSx}>
//             <TextfieldComponent
//               multiline
//               name="name"
//               label="Name"
//               value={formik.values.name}
//               type="text"
//               onChange={formik.handleChange}
//               error={formik.touched.name && !!formik.errors.name}
//               helperText={(formik.touched.name && formik.errors.name) ?? ' '}
//               endAdornment="none"
//             />
//           </Box>

//           <Box sx={spacing.mt20}>
//             <LoaderButton
//               name={polyglot.t('General.save')}
//               loading={loading}
//               fullWidth={true}
//               sizeVariant="medium"
//               colorVariant="primary"
//             />
//           </Box>
//         </Box>
//       </Form>
//     </FormikProvider>
//   );
// };
