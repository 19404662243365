import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { SiteDto } from '@v2/feature/site/site.dto';

export interface UserRole {
  id: number;
  companyId: number;
  userId: number;
  status: UserLifecycleStatuses;
  managerId?: number | null;
  departmentId?: number | null;
  jobTitle?: string;
  jobPositionId: number | null;
  effectiveDate: string;
  lastChangeType?: string;
  lastChangeReason?: string;
  siteId?: number | null;
  department?: Department;
  site?: SiteDto;
  customUpdates: UserCustomDataDto[];
}

export enum UserLifecycleStatuses {
  Hired = 'Hired',
  Employed = 'Employed',
  Leave = 'Leave',
  Terminated = 'Terminated',
  Offboarding = 'Offboarding',
}

export interface Department {
  id: number;
  name: string;
}

export type UserRoleFormInfo = Pick<
  UserRole,
  | 'id'
  | 'jobTitle'
  | 'jobPositionId'
  | 'departmentId'
  | 'managerId'
  | 'effectiveDate'
  | 'lastChangeType'
  | 'lastChangeReason'
  | 'siteId'
  | 'customUpdates'
>;

export type UserRoleImportValues = Omit<UserRoleFormInfo, 'id'> & {
  managerEmail: string;
};
