import React, { useContext, useMemo, useState } from 'react';

import { Box, FormControl, Tooltip } from '@mui/material';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TASK_CHECKLIST_ROUTE } from '@/lib/routes';
import { AutocompleteComponent, OptionObject } from '@/v2/components/forms/autocomplete.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { RelativeAssignmentValues } from '@/v2/feature/task/subfeature/checklist/checklist-item.dto';
import { ChecklistDto } from '@/v2/feature/task/subfeature/checklist/checklist.dto';
import { TaskAPI, TaskEndpoints } from '@/v2/feature/task/task.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const TaskLaunchModal = ({
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  assignUserId,
  reach,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => void;
  assignUserId?: number;
  reach: 'company' | 'team' | 'user';
}) => {
  const { data: allChecklists, isValidating: loading } = useApiClient(TaskEndpoints.getChecklists(), {
    suspense: false,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} loading={loading}>
      <TaskLaunchContent
        allChecklists={allChecklists}
        onClose={onClose}
        refresh={refresh}
        assignUserId={assignUserId}
        reach={reach}
      />
    </DrawerModal>
  );
};

const LaunchSchema = (polyglot: Polyglot) =>
  Yup.object({
    checklistId: Yup.number().required(),
    dueDate: Yup.string().nullable().required(),
    assignedUserIds: Yup.array().of(Yup.number()).required(polyglot.t('LaunchFormDrawer.errorMessages.needUser')),
    assignedUserId: Yup.number()
      .nullable()
      .integer()
      .notRequired()
      .typeError(polyglot.t('LaunchFormDrawer.errorMessages.assignUser')),
  });

const TaskLaunchContent = ({
  allChecklists,
  onClose,
  refresh,
  assignUserId,
  reach,
}: {
  allChecklists: ChecklistDto[] | null | undefined;
  onClose: () => void;
  refresh: () => void;
  assignUserId?: number;
  reach: 'company' | 'team' | 'user';
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const routerHistory = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedChecklistId, setSelectedChecklistId] = useState<number | undefined>(undefined);

  const users = useMemo(() => {
    const reports = new Set(globalState.user.reports);
    return reach === 'team'
      ? nonTerminatedCachedUsers
          .filter((u) => reports.has(u.userId) || u.userId === globalState.user.userId)
          .map((u) => {
            return { label: u.displayName, value: u.userId, ...u };
          })
      : nonTerminatedCachedUsers.map((u) => {
          return { label: u.displayName, value: u.userId, ...u };
        });
  }, [globalState.user.reports, globalState.user.userId, nonTerminatedCachedUsers, reach]);

  const formik = useFormik({
    initialValues: {
      checklistId: null,
      dueDate: null as string | null,
      assignedUserId: null,
      assignedUserIds: assignUserId ? [assignUserId] : ([] as number[]),
    },
    validationSchema: LaunchSchema(polyglot),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (values.checklistId) {
          setLoading(true);
          if (!assignUserId && isSingleSelect && values.assignedUserId) {
            values.assignedUserIds = [values.assignedUserId];
          }

          if (assignUserId) values.assignedUserIds = [assignUserId];

          await TaskAPI.launchChecklistById(values.checklistId, values);
          showMessage(polyglot.t('LaunchFormDrawer.successMessages.create'), 'success');
          refresh();
          onClose();
        } else {
          showMessage('Please select the checklist you want to launch', 'error');
        }
      } catch (error) {
        showMessage(
          polyglot.t('LaunchFormDrawer.errorMessages.launch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const checklistOptions: readonly OptionObject<string | number>[] = useMemo(() => {
    let options: OptionObject<string | number>[] = [];
    if (allChecklists) {
      options = allChecklists.map((c) => ({
        label: c.name ?? '',
        value: c.id,
      }));
    }

    return options;
  }, [allChecklists]);

  const selectedChecklist = useMemo(() => {
    return allChecklists?.find((checklist) => checklist.id === selectedChecklistId);
  }, [allChecklists, selectedChecklistId]);

  const isSingleSelect = useMemo(() => {
    return selectedChecklist?.checklistItems.find(
      (item) =>
        item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee ||
        item.requestedForPlaceholder === RelativeAssignmentValues.manager
    );
  }, [selectedChecklist]);

  const showAssigneeSelect = useMemo(() => {
    return selectedChecklist?.checklistItems.find(
      (item) =>
        item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee ||
        item.requestedForPlaceholder === RelativeAssignmentValues.manager ||
        item.assignedToPlaceholder === RelativeAssignmentValues.checklistAssignee ||
        item.assignedToPlaceholder === RelativeAssignmentValues.manager
    );
  }, [selectedChecklist]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Launch checklist</Typography>

        {assignUserId && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Typography variant="captionSmall">Checklist Assignee</Typography>
            <UserCell userId={assignUserId} nameVariant="title4" />
          </Box>
        )}

        <AutocompleteComponent
          name="checklistId"
          label="Select checklist"
          options={checklistOptions}
          value={checklistOptions.find(({ value }) => value === formik.values.checklistId)}
          compareValue={formik.values.checklistId ?? ''}
          // @ts-ignore
          onChange={(_, x: OptionObj) => {
            setSelectedChecklistId(x?.value);
            formik.setFieldValue('checklistId', x?.value ?? null);
            formik.setFieldValue('assignUserId', null);
            formik.setFieldValue('assignedUserIds', []);
            formik.setFieldValue('dueDate', null);
          }}
          error={formik.touched.checklistId && Boolean(formik.errors.checklistId)}
          helperText={formik.touched.checklistId && formik.errors.checklistId}
          editList={{
            handler: () => routerHistory.push(SETTINGS_TASK_CHECKLIST_ROUTE),
            isHidden: !hasScopes(['task:all'], { userId: user.userId }),
          }}
        />

        <Box
          sx={{
            transition: 'all 0.3s ease',
            transitionProperty: 'opacity',
            opacity: formik.values.checklistId ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {showAssigneeSelect && !assignUserId && (
            <>
              {isSingleSelect ? (
                <SingleUserSelect
                  name="assignedUserId"
                  options={reach}
                  onChange={(_, x) => formik.setFieldValue('assignedUserId', x?.value ?? null)}
                  value={formik.values.assignedUserId}
                  label={polyglot.t('LaunchFormDrawer.whoIsAssignee')}
                  error={formik.touched.assignedUserId && Boolean(formik.errors.assignedUserId)}
                  helperText={formik.touched.assignedUserId && (formik.errors.assignedUserId as string)}
                />
              ) : (
                <Tooltip title={polyglot.t('LaunchFormDrawer.whoShouldCompleteTask')} placement="top-start">
                  <UserSelect
                    label={polyglot.t('LaunchFormDrawer.whoIsAssignee')}
                    selectedLabel={polyglot.t('LaunchFormDrawer.checklistUser')}
                    value={formik.values.assignedUserIds}
                    onChange={(userIds: number[]) => {
                      formik.setFieldValue('assignedUserIds', userIds);
                    }}
                    fieldSx={{ ...spacing.mb20 }}
                    userOptions={users}
                  />
                </Tooltip>
              )}
            </>
          )}

          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              shortcuts
              value={formik.values.dueDate}
              shortcutLabel="When is the checklist due date?"
              onChange={(value) => {
                formik.setFieldValue('dueDate', value);
              }}
              name="dueDate"
              label={polyglot.t('LaunchFormDrawer.date')}
              error={!!formik.errors.dueDate && Boolean(formik.touched.dueDate)}
              helperText={formik.errors.dueDate && Boolean(formik.touched.dueDate)}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            ...buttonBoxDrawerSx,
            transition: 'all 0.3s ease',
            transitionProperty: 'opacity',
            opacity: formik.values.checklistId ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <LoaderButton
            name={polyglot.t('General.launch')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
