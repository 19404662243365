import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DevicePossessionDrawer } from '@v2/feature/super-admin/features/helper-dashboard/components/device-possession-drawer.component';
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/table.styles';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { nestErrorMessage } from '@/lib/errors';
import { getDateString } from '@/v2/components/forms/date-label.component';

export const HelperDevicesRouter = ({ company }: { company: SuperAdminCompanyInfo | undefined }): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const params = useParams<{ readonly companyId: string }>();
  const companyId = Number(params.companyId);

  const [devicePossessions, setDevicePossessions] = useState<DevicePossessionDto[]>([]);
  const [selectedDevicePossession, setSelectedDevicePossession] = useState<DevicePossessionDto | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [noOfActivePossessionsOfDevices, setNoOfActivePossessionsOfDevices] = useState<{ [deviceId: number]: number }>(
    {}
  );

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const devicePossessions = await DeviceAPI.getAllDevicePossessionsByCompanyId(companyId);
      setDevicePossessions(devicePossessions);

      const mapWithDevicesOccurrencesInActivePossessions: { [deviceId: number]: number } = {};
      for (const devicePossession of devicePossessions) {
        if (
          devicePossession.startDate &&
          !devicePossession.endDate &&
          !mapWithDevicesOccurrencesInActivePossessions[devicePossession.deviceId]
        ) {
          mapWithDevicesOccurrencesInActivePossessions[devicePossession.deviceId] = 1;
        } else if (devicePossession.startDate && !devicePossession.endDate) {
          mapWithDevicesOccurrencesInActivePossessions[devicePossession.deviceId] += 1;
        }
      }
      setNoOfActivePossessionsOfDevices(mapWithDevicesOccurrencesInActivePossessions);
    } catch (error) {
      showMessage(`Error loading device possessions: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, companyId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columnData = useMemo<ColumnDef<DevicePossessionDto, DevicePossessionDto>[]>(() => {
    return [
      {
        header: () => 'Id',
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return <Box>{possession.id}</Box>;
        },
        maxSize: 35,
        minSize: 35,
      },
      {
        header: () => 'Device Id',
        accessorFn: (row) => row,
        id: 'deviceId',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return <Box>{possession.deviceId}</Box>;
        },
        maxSize: 45,
        minSize: 45,
      },
      {
        header: () => 'Model Name',
        accessorFn: (row) => row,
        id: 'device.modelName',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.device?.modelName ? <Box>{possession.device.modelName}</Box> : <EmptyCell />;
        },
        maxSize: 150,
        minSize: 150,
      },
      {
        header: () => 'Possession Type',
        accessorFn: (row) => row,
        id: 'possessionType',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return <Box>{possession.possessionType}</Box>;
        },
        maxSize: 80,
        minSize: 80,
      },
      {
        header: () => 'Possession Id',
        accessorFn: (row) => row,
        id: 'possessionId',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return <Box>{possession.possessionId}</Box>;
        },
        maxSize: 70,
        minSize: 70,
      },
      {
        header: () => 'Delivery Address',
        accessorFn: (row) => row,
        id: 'deliveryAddress',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.deliveryAddress ? (
            <Tooltip title={possession.deliveryAddress} placement="top">
              <Box>{possession.deliveryAddress}</Box>
            </Tooltip>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 200,
        minSize: 200,
      },
      {
        header: () => 'Start Date',
        accessorFn: (row) => row,
        id: 'startDate',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.startDate ? <Box>{getDateString(possession.startDate)}</Box> : <EmptyCell />;
        },
        maxSize: 85,
        minSize: 85,
      },
      {
        header: () => 'End Date',
        accessorFn: (row) => row,
        id: 'endDate',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return (
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
              {possession.endDate ? getDateString(possession.endDate) : <EmptyCell />}
              {noOfActivePossessionsOfDevices[possession.deviceId] &&
                noOfActivePossessionsOfDevices[possession.deviceId] > 1 && <Mistake {...iconSize} />}
            </Box>
          );
        },
        maxSize: 85,
        minSize: 85,
      },
      {
        header: () => 'Created At',
        accessorFn: (row) => row,
        id: 'createdAt',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return <Box>{getDateString(possession.createdAt)}</Box>;
        },
        maxSize: 85,
        minSize: 85,
      },
    ];
  }, [noOfActivePossessionsOfDevices]);

  const handleRowClick = useCallback((row: Row<DevicePossessionDto>) => {
    setSelectedDevicePossession(row.original);
    setIsDrawerOpen(true);
  }, []);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE}>
        <>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                Helper page - Devices | {company?.name ? company.name : ''} [ {companyId} ]
              </Typography>
            }
            showBack
            backPath={generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })}
          />
          <ContentWrapper loading={loading}>
            <Box>
              <BasicTable<DevicePossessionDto>
                rowData={devicePossessions}
                columnData={columnData}
                rowClick={handleRowClick}
              />
            </Box>

            {selectedDevicePossession && (
              <DevicePossessionDrawer
                isOpen={isDrawerOpen}
                setIsOpen={setIsDrawerOpen}
                devicePossession={selectedDevicePossession}
                companyId={companyId}
                refresh={refresh}
                onUpdate={() => {
                  setSelectedDevicePossession(null);
                  setIsDrawerOpen(false);
                }}
              />
            )}
          </ContentWrapper>
        </>
      </Route>
    </Switch>
  );
};
