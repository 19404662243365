import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { HolidayCalendarAPI } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import {
  HolidayCalendarDto,
  UpdateHolidayCalendarGeneralDto,
} from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const validationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup.string().required(polyglot.t('validation.requiredField')),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly holidayCalendar: HolidayCalendarDto;
  readonly refresh: () => Promise<void>;
}

export const HolidayCalendarGeneralDrawer = ({ isOpen, setIsOpen, holidayCalendar, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <HolidayCalendarGeneralDrawerContent
          holidayCalendar={holidayCalendar}
          refresh={refresh}
          setIsOpen={setIsOpen}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly holidayCalendar: HolidayCalendarDto;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

const HolidayCalendarGeneralDrawerContent = ({ holidayCalendar, refresh, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const updateCalendar = useCallback(
    async (values: UpdateHolidayCalendarGeneralDto) => {
      try {
        setLoading(true);
        const data: UpdateHolidayCalendarGeneralDto = { name: values.name };
        await HolidayCalendarAPI.updateHolidayCalendarGeneral(holidayCalendar.id, data);
        await refresh();

        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setLoading(false);
    },
    [showMessage, polyglot, refresh, holidayCalendar, setIsOpen]
  );

  const formik = useFormik<UpdateHolidayCalendarGeneralDto>({
    initialValues: {
      name: holidayCalendar.name,
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: updateCalendar,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('HolidayCalendarModule.newCalendar')}</Typography>

        <TextfieldComponent
          label={polyglot.t('General.name')}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && (formik.errors.name as string)}
          fullWidth
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
