import { Box, Typography } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface FilledFieldProps {
  readonly label: string;
  readonly value: string;
}

export const FilledField = ({ label, value }: FilledFieldProps) => {
  return (
    <Box sx={{ display: 'block' }}>
      <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>{label}</Typography>
      <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, marginTop: spacing.m5 }}>{value}</Typography>
    </Box>
  );
};
