import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { ColumnDef, Row } from '@tanstack/react-table';
import { NumberCell } from '@v2/components/table/number-cell.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { getNormalisedKeysOldReports, getNormalisedValue } from '@v2/feature/reports/util/report.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { translateColumnsOptions } from '@/v2/infrastructure/i18n/translate.util';

interface Props {
  filteredData: Record<string, string | number>[];
  resultColumnNameMapping?: Record<string, string>; // optional mapping for column names
  loading: boolean;
}

const DEVICE_NUMBER_COLUMNS = ['ram', 'cpu', 'gpu', 'storage', 'screenSize'];
const ATTENDANCE_NUMBER_COLUMNS: string[] = [];
const TIME_NUMBER_COLUMNS = ['workdayCount', 'currentBalanceInDays'];

const ATTENDANCE_EXCEL_CLOCK_HOURS_COLUMNS = ['regularLength', 'breakLength', 'overtimeLength'];
const TIME_EXCEL_CLOCK_HOURS_COLUMNS = ['length', 'currentBalance', 'unitsTaken', 'unitsBooked'];

const PEOPLE_NUMBER_COLUMNS = [
  'userId',
  'Contract: ftePercent',
  'Contract: noticePeriodLength',
  'Contract: probationPeriodLength',
  'Contract: entityId',
  'Compensation: rate',
  'Compensation: units',
  'Payroll: openingCurrentTax',
  'Payroll: openingPreviousTax',
  'Payroll: openingCurrentGross',
  'Payroll: openingPreviousGross',
];

const NUMBER_COLUMNS_SET = new Set<string>([
  ...DEVICE_NUMBER_COLUMNS,
  ...ATTENDANCE_NUMBER_COLUMNS,
  ...PEOPLE_NUMBER_COLUMNS,
  ...TIME_NUMBER_COLUMNS,
]);
export const EXCEL_CLOCK_HOURS_COLUMNS_SET = new Set<string>([
  ...ATTENDANCE_EXCEL_CLOCK_HOURS_COLUMNS,
  ...TIME_EXCEL_CLOCK_HOURS_COLUMNS,
]);

function sortingFn(
  columnName: string,
  a: Row<Record<string, number | string>>,
  b: Row<Record<string, number | string>>
): number {
  const valueOfA = a.original as Record<string, number | string>;
  const valueOfB = b.original as Record<string, number | string>;

  if (
    NUMBER_COLUMNS_SET.has(columnName) ||
    ((!valueOfA[columnName] || typeof valueOfA[columnName] === 'number') &&
      (!valueOfB[columnName] || typeof valueOfB[columnName] === 'number'))
  ) {
    return sortNumeric(a, b, (item: Record<string, unknown>) =>
      item[columnName] ? Number.parseFloat(item[columnName] as string) : undefined
    );
  }

  if (EXCEL_CLOCK_HOURS_COLUMNS_SET.has(columnName)) {
    return sortNumeric(a, b, (item: Record<string, unknown>) => {
      const [hourValue, minutesValue] = item[columnName] ? (item[columnName] as string).split(':') : [];

      const h = hourValue ? Number.parseInt(hourValue) : 0;
      const m = minutesValue ? Number.parseInt(minutesValue) : 0;

      return h * 60 + m;
    });
  }

  return sortString(
    a,
    b,
    (item: Record<string, string | number>) => getNormalisedValue(item[columnName] as string, columnName) as string
  );
}

export const ResultTableOldReports = ({ filteredData, resultColumnNameMapping, loading }: Props) => {
  const { polyglot } = usePolyglot();

  const currentColumn = useMemo(() => {
    const headerName = (columnName: string) =>
      resultColumnNameMapping?.[columnName] ??
      translateColumnsOptions(
        columnName
          .split(/(?=[A-Z])/)
          .join(' ')
          .replaceAll('-', ' ')
          .replaceAll(':', '')
          .replaceAll(' ', '')
          .toLowerCase(),
        polyglot
      );

    return getNormalisedKeysOldReports(filteredData).map((o) => {
      return {
        header: () => headerName(o),
        accessorFn: (row: any) => row,
        id: o,
        enableSorting: true,
        sortingFn: (a, b) => sortingFn(o, a, b),
        cell: ({ row: { original } }: { row: any }) => (
          <Box key={o} sx={{ width: '100%' }}>
            {NUMBER_COLUMNS_SET.has(o) || EXCEL_CLOCK_HOURS_COLUMNS_SET.has(o) ? (
              <NumberCell value={getNormalisedValue(original[o], o)} />
            ) : (
              <Typography variant="caption">{getNormalisedValue(original[o], o)}</Typography>
            )}
          </Box>
        ),
      } as ColumnDef<Record<string, number | string>, Record<string, number | string>>;
    });
  }, [polyglot, filteredData, resultColumnNameMapping]);

  return (
    <BasicTable<Record<string, number | string>>
      rowData={[...filteredData]}
      columnData={currentColumn}
      fixedLastColumn={false}
      loading={loading}
    />
  );
};
