import { Dispatch, SetStateAction, Suspense } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import {
  getBookedFromAllowanceFromBreakdown,
  getBookedFromBreakdown,
  getBookedFromPHAllowanceFromBreakdown,
  getPendingBookedFromAllowanceFromBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

const WIDTH_COLUMN1 = '80%';
const WIDTH_COLUMN2 = '20%';

interface BookedAllowanceDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

export const BookedAllowanceDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats,
}: BookedAllowanceDrawerProps) => {
  const { polyglot } = usePolyglot();
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box>
          <Typography variant="title2">{polyglot.t('AllowanceDrawer.usedAllowance')}</Typography>
          <BookedAllowanceDrawerContent
            userBalanceDetailedStats={userBalanceDetailedStats}
            absencePolicy={absencePolicy}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface BookedDrawerFieldProps {
  readonly title: string;
  readonly value: string;
  readonly boldTitle?: boolean;
}

const BookedDrawerField = ({ title, value, boldTitle = false }: BookedDrawerFieldProps) => (
  <Box sx={{ display: 'flex' }}>
    <Box sx={{ width: WIDTH_COLUMN1, display: 'flex', flexDirection: 'column' }}>
      <Typography variant={boldTitle ? 'title4' : 'caption'}>{title}</Typography>
    </Box>
    <Box sx={{ width: WIDTH_COLUMN2, display: 'flex', justifyContent: 'end' }}>
      <Typography variant="title4">{value}</Typography>
    </Box>
  </Box>
);

interface BookedAllowanceDrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

const BookedAllowanceDrawerContent = ({
  absencePolicy,
  userBalanceDetailedStats,
}: BookedAllowanceDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];

  const bookedFromMainAllowance = getBookedFromAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const hasPendingUpcoming = getPendingBookedFromAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const bookedFromPHAllowance = getBookedFromPHAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);

  const totalBooked = getBookedFromBreakdown(absencePolicy, policyBreakdown, polyglot);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: spacing.m20 }}>
      <BookedDrawerField
        title={
          hasPendingUpcoming
            ? polyglot.t('AllowanceDrawer.bookedFromAllowanceIncPending')
            : polyglot.t('AllowanceDrawer.bookedFromAllowance')
        }
        value={bookedFromMainAllowance}
      />
      <BookedDrawerField title={polyglot.t('AllowanceDrawer.bookedFromPHAllowance')} value={bookedFromPHAllowance} />
      <Divider style={{ marginBottom: spacing.m10 }} />
      <BookedDrawerField title={polyglot.t('AllowanceDrawer.bookedTotal')} value={totalBooked} boldTitle />
    </Box>
  );
};
