import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary, keyBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/fields/Dropdown.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SURVEYS_ME_INVITE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { OrderPreference } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyAnswerAPI } from '@/v2/feature/growth/surveys/api-client/survey-answer.api';
import { SurveyEntryAPI, SurveyEntryEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-entry.api';
import { MultipleChoiceQuestion } from '@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/multiple-choice-question.component';
import { OpenEndedQuestion } from '@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/open-ended-question.components';
import { ScaleQuestion } from '@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/scale-question.component';
import { SingleAnswerQuestion } from '@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/single-answer-question.component';
import { getSurveyDueDate } from '@/v2/feature/growth/surveys/features/survey-entry/survey-entry.util';
import { SurveyAnswer, UpsertSurveyAnswer } from '@/v2/feature/growth/surveys/interfaces/survey-answer.interface';
import {
  AnonymityType,
  DEFAULT_THRESHOLD_VALUE,
  SurveyCycle,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import {
  EntryDetail,
  EntryState,
  GrowthEntry,
  GrowthUpsertAnswer,
  SurveyEntry,
} from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { SurveySection } from '@/v2/feature/growth/surveys/interfaces/survey-section.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';

export type UpdateAnswer = { updatedAnswer: string | null; comment: string | null };

export const SurveyAnswerSubmissionPage = () => {
  const params = useParams<{ entryId: string; cycleId: string }>();
  const { entryId, cycleId } = params;
  const [answerArray, setAnswerArray] = useState<UpsertSurveyAnswer[] | null | undefined>(null);
  const [answers, setAnswers] = useState<SurveyAnswer[] | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

  const { data: entryDetail, isValidating: loadingEntryDetail } = useApiClient(
    SurveyEntryEndpoints.getSurveyEntryDetail(entryId, cycleId),
    {
      suspense: false,
    }
  );

  const getAnswersData = useCallback(async () => {
    try {
      const response = await SurveyAnswerAPI.getAnswer(entryId, cycleId);
      setAnswers(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  }, [entryId, cycleId, showMessage]);

  useEffect(() => {
    getAnswersData();
  }, [getAnswersData]);

  const checkForScroll = () => {
    if (containerRef.current) {
      const isContentOverflowing = containerRef.current.scrollHeight > window.innerHeight;
      setShowScrollButton(isContentOverflowing);
    }
  };

  const handleScrollClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
      setShowScrollButton(false);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      setShowScrollButton(!isAtBottom);
    }
  };

  useEffect(() => {
    if (answers) {
      setAnswerArray(answers);
    }
  }, [answers]);

  useEffect(() => {
    setTimeout(() => checkForScroll(), 1500);
    window.addEventListener('resize', checkForScroll);
    return () => window.removeEventListener('resize', checkForScroll);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  });

  const getAnonimityText = useMemo(() => {
    if (!Boolean(entryDetail?.cycle?.visibilitySettings?.anonymiseAnswers))
      return 'No, your answers are not anonymous.';
    if (
      Boolean(entryDetail?.cycle?.visibilitySettings?.anonymiseAnswers) &&
      entryDetail?.cycle?.visibilitySettings?.anonymityType &&
      entryDetail?.cycle?.visibilitySettings?.anonymityType === AnonymityType.Semi
    )
      return 'It is semi-anonymous. Your department, site, gender and tenure are collected for segmenting';

    return 'Yes';
  }, [entryDetail?.cycle?.visibilitySettings]);

  let cycle: EntryDetail['cycle'] | undefined;
  let sections: EntryDetail['sections'] | undefined;
  let questions: EntryDetail['questions'] | undefined;
  let entry: EntryDetail['entry'] | undefined;

  if (entryDetail) {
    ({ cycle, sections, questions, entry } = entryDetail);
  }
  const questionsLookup = useMemo(() => keyBy(questions ?? [], 'id'), [questions]);
  const isSubmitable = useMemo(() => {
    if (!questions || !answerArray) {
      return false;
    }

    const allQuestionsAnswered = answerArray.length === questions.length;

    const allCommentsValid = questions.every((question) => {
      if (question.isCommentRequired) {
        const answer = answerArray.find((a) => a.questionId === question.id);
        return answer && answer.comment && answer.comment.length > 0;
      }
      return true;
    });

    return allQuestionsAnswered && allCommentsValid;
  }, [answerArray, questions]);

  if (!entry || !cycle || !questions) return <></>;

  const handleAnswerChange = (questionId: string, updateObject: UpdateAnswer, entry: GrowthEntry) => {
    setAnswerArray((prevAnswers) => {
      const updatedAnswers =
        prevAnswers?.map((answer) => {
          if (answer.questionId === questionId) {
            return { ...answer, answer: updateObject.updatedAnswer, comment: updateObject.comment };
          }
          return answer;
        }) || [];

      if (!prevAnswers?.some((answer) => answer.questionId === questionId)) {
        updatedAnswers.push({
          id: v4(),
          cycleId,
          entryId,
          participantId: (entry as SurveyEntry).participantId,
          comment: updateObject.comment,
          answer: updateObject.updatedAnswer,
          questionId: questionId,
        });
      }

      return updatedAnswers;
    });
  };

  const handleSaveDraft = async () => {
    try {
      if (answerArray) {
        await SurveyAnswerAPI.saveAnswersAsDraft(answerArray, entryId, cycleId);
        await getAnswersData();
        showMessage('Successfully saved as draft', 'success');
      }
      const currentSurveyEntry = entryDetail?.entry;
      if (currentSurveyEntry?.participantId)
        await SurveyEntryAPI.updateSurveyEntry({
          id: entryId,
          cycleId,
          participantId: currentSurveyEntry.participantId,
          entryState: EntryState.Draft,
        });
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const handleSubmit = async () => {
    if (!isSubmitable || !answerArray || !entry) return;
    try {
      setIsSubmitting(true);
      await SurveyAnswerAPI.submitAnswers(answerArray, entryId, cycleId, entry?.participantId);
      routerHistory.push(SURVEYS_ME_INVITE_ROUTE);
      showMessage('Successfully submitted the answers', 'success');
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isAnonymousSurvey = cycle?.visibilitySettings?.anonymiseAnswers;

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{entryDetail?.cycle.displayName}</Typography>}
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
            {!isAnonymousSurvey && entryDetail?.entry.entryState !== EntryState.Submitted && (
              <ButtonComponent colorVariant="secondary" sizeVariant="small" onClick={() => handleSaveDraft()}>
                Save
              </ButtonComponent>
            )}
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
              disabled={!isSubmitable}
            >
              Submit
            </ButtonComponent>
          </Box>
        }
        showAction={true}
        sx={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}
        showBack={true}
        backPath={SURVEYS_ME_INVITE_ROUTE}
      />
      <ContentWrapper
        loading={loadingEntryDetail}
        ref={containerRef}
        sx={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 120px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            overflowY: 'hidden',
            flexDirection: 'column',
            gap: spacing.g16,
            maxWidth: '600px',
            width: '100%',
            margin: '0 auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
            <ViewItem
              label="Due date"
              value={getSurveyDueDate({ ...entry, cycle: cycle })
                .getDate()
                .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
            />

            <ViewItem label="Is this survey anonymous?" value={getAnonimityText} />
            {isAnonymousSurvey && (
              <ViewItem
                label="Anonymity threshold"
                value={`${cycle?.visibilitySettings?.anonymityThreshold || DEFAULT_THRESHOLD_VALUE}`}
              />
            )}
          </Box>
          {cycle?.order.map((item) => (
            <RenderQuestionOrSection
              key={item.id}
              item={item}
              questionsLookup={questionsLookup}
              handleAnswerChange={handleAnswerChange}
              sections={sections}
              cycle={cycle}
              entry={entry}
              answerArray={answerArray}
            />
          ))}

          {showScrollButton && (
            <IconButton
              colorVariant="primary"
              sizeVariant="small"
              onClick={handleScrollClick}
              style={{ position: 'fixed', bottom: '20px', right: '20px', textAlign: 'center' }}
            >
              <ArrowDown {...iconSize} />
            </IconButton>
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};

const RenderQuestionOrSection = ({
  item,
  questionsLookup,
  handleAnswerChange,
  sections,
  cycle,
  entry,
  answerArray,
}: {
  item: OrderPreference;
  questionsLookup: Dictionary<SurveyQuestion>;
  handleAnswerChange: (questionId: string, updateObject: UpdateAnswer, entry: GrowthEntry) => void;
  sections: SurveySection[] | undefined;
  cycle: SurveyCycle | undefined;
  entry: SurveyEntry | undefined;
  answerArray: UpsertSurveyAnswer[] | null | undefined;
}) => {
  const renderQuestion = (questionId: string) => {
    const question = questionsLookup && questionsLookup[questionId];

    if (!question || !cycle) return null;
    return (
      <QuestionComponent
        key={question.id}
        question={question}
        answerArray={answerArray}
        handleAnswerChange={handleAnswerChange}
        entry={entry}
      />
    );
  };

  const renderSection = (sectionId: string) => {
    const section = sections?.find((s) => s.id === sectionId);
    if (!section || !cycle) return null;

    const orderSection = cycle?.order.find((o) => o.id === sectionId && o.type === 'section');
    if (!orderSection) return null;

    const orderedQuestions = (orderSection.questions ?? [])
      .map((questionId) => questionsLookup[questionId])
      .filter((question): question is SurveyQuestion => Boolean(question));

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16 }}>
        <Typography variant="title2">{section.name}</Typography>
        {orderedQuestions.map((q) => (
          <QuestionComponent
            key={q.id}
            question={q}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        ))}
      </Box>
    );
  };
  const renderQuestionOrSection = () => {
    if (item.type === 'question' && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === 'section' && (item.questions ?? []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return <Box sx={{ marginTop: spacing.m48 }}>{renderSection(item.id)}</Box>;
    } else return <></>;
  };

  return <>{renderQuestionOrSection()}</>;
};

const QuestionComponent = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
}: {
  question: SurveyQuestion;
  answerArray: GrowthUpsertAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: GrowthEntry) => void;
  entry: SurveyEntry | undefined;
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const questionText = useMemo(() => {
    if (!question) return '';
    const company_name = user?.company?.name ?? undefined;
    const cleanedQuestionText = replaceParamsInQuestionText(stripHtml(question.questionText), { company_name }) ?? '';
    return cleanedQuestionText;
  }, [question, user?.company?.name]);

  const getQuestionByType = (question: SurveyQuestion) => {
    switch (question.type) {
      case QuestionType.SingleAnswer:
        return (
          <SingleAnswerQuestion
            question={question}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      case QuestionType.OpenEnded:
        return (
          <OpenEndedQuestion
            question={question}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      case QuestionType.MultipleAnswer:
        return (
          <MultipleChoiceQuestion
            question={question}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      case QuestionType.ScaleQuestion:
        return (
          <ScaleQuestion
            question={question}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.s2,
        padding: spacing.p16,
        border: borders.background,
        borderRadius: radius.br8,
      }}
    >
      <Typography variant="title4" dangerouslySetInnerHTML={{ __html: questionText ?? '' }}></Typography>
      {getQuestionByType(question)}
    </Box>
  );
};

const ViewItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: '2fr 2fr',
        rowGap: spacing.g24,
        columnGap: spacing.g4,
        width: '100%',
      }}
    >
      <Typography variant="caption" sx={{ color: themeColors.Grey }}>
        {label}
      </Typography>
      <Typography variant="caption">{value}</Typography>
    </Box>
  );
};
