import { useState } from 'react';

import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { BenefitsSettingsTable } from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-table.component';
import { NewBenefitDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';

interface BenefitsSettingsListPageProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
  readonly refreshCustomBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export const BenefitsSettingsListPage = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshCustomBenefits,
  loading,
}: BenefitsSettingsListPageProps) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('BenefitModule.benefits')}</Typography>}
        showAction
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsOpen(true)}>
            {polyglot.t('BenefitModule.newBenefit')}
          </ButtonComponent>
        }
      />

      <ContentWrapper loading={loading}>
        <BenefitsSettingsTable
          insuranceQuote={insuranceQuote}
          pensionSchemes={pensionSchemes}
          customBenefits={customBenefits}
        />

        <NewBenefitDrawer isOpen={isOpen} setIsOpen={setIsOpen} refresh={refreshCustomBenefits} />
      </ContentWrapper>
    </RootStyle>
  );
};
