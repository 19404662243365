import axios from 'axios';

import {
  UserAddressDto,
  UpdateUserAddressDto,
  CreateUserAddressDto,
} from '@/v2/feature/user/features/user-forms/user-address/user-address.dto';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
export class UserAddressAPI {
  static async findByUserId(userId: number): Promise<EntriesAndEffectiveRecord<UserAddressDto>> {
    return axios.get(`/apiv2/users/${userId}/address`).then(({ data }) => data);
  }

  static async findCurrentByUserId(userId: number): Promise<UserAddressDto | null> {
    return (await axios.get(`/apiv2/users/${userId}/address/current`)).data || null;
  }

  static async create(userId: number, userAddress: CreateUserAddressDto): Promise<UserAddressDto> {
    return (await axios.post(`/apiv2/users/${userId}/address`, userAddress)).data;
  }

  static async update(userId: number, update: UpdateUserAddressDto): Promise<UserAddressDto> {
    return (await axios.patch(`/apiv2/users/${userId}/address/${update.userAddressId}`, update)).data;
  }

  static async delete(userId: number, addressId: number): Promise<void> {
    return axios.delete(`/apiv2/users/${userId}/address/${addressId}`);
  }
}
