import { isSameDay } from 'date-fns';
import Polyglot from 'node-polyglot';

import { AfternoonOnly, CompanyEvent, FullDay, MorningOnly } from '@/v2/feature/calendar/calendar.interface';
import { DARK_CONTRAST_COLOR, themeColors } from '@/v2/styles/colors.styles';

export const getStartFullOrHalfDayType = (polyglot: Polyglot, event?: CompanyEvent): string => {
  if (event?.endDate) {
    // Multiple days value
    if (event.afternoonOnly) return AfternoonOnly(polyglot).value;
    return FullDay(polyglot).value;
  }

  if (event?.morningOnly) return MorningOnly(polyglot).value;
  if (event?.afternoonOnly) return AfternoonOnly(polyglot).value;
  return FullDay(polyglot).value;
};

export const getEndFullOrHalfDayType = (polyglot: Polyglot, event?: CompanyEvent): string => {
  if (!event?.endDate) return FullDay(polyglot).value;

  // Multiple days value
  if (event.morningOnly) return MorningOnly(polyglot).value;
  return FullDay(polyglot).value;
};

export const getTextColor = (event: CompanyEvent) => {
  if (event?.color) {
    return DARK_CONTRAST_COLOR.includes(event?.color) ? themeColors.white : themeColors.DarkGrey;
  }
  return themeColors.DarkGrey;
};

export const getWeekWidth = (d: CompanyEvent, day: Date) => {
  if (d?.endDate) {
    if (d.morningOnly || d.afternoonOnly) {
      if (isSameDay(new Date(day), new Date(d.startDate)) && d.afternoonOnly) return '50%';
      else if (isSameDay(new Date(day), new Date(d.endDate)) && d.morningOnly) return '50%';
      else return '100%';
    } else return '100%';
  } else {
    return d.morningOnly || d.afternoonOnly ? '50%' : '100%';
  }
};

export const getWeekAlignment = (d: CompanyEvent, day: Date) => {
  if (d?.endDate) {
    if (d.morningOnly || d.afternoonOnly) {
      if (isSameDay(new Date(day), new Date(d.startDate)) && d.afternoonOnly) return 'flex-end';
      else if (isSameDay(new Date(day), new Date(d.endDate)) && d.morningOnly) return 'flex-start';
      else return 'center';
    } else return 'center';
  } else {
    if (d.morningOnly || d.afternoonOnly) return d.morningOnly ? 'flex-start' : 'flex-end';
    return 'center';
  }
};
