import { Box } from '@mui/material';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const OpenEndedQuestionReadonly = ({ questionText }: { questionText: string }) => {
  if (!questionText) return <></>;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <Typography variant="caption" dangerouslySetInnerHTML={{ __html: questionText ?? '' }}></Typography>
      <RichTextField value={null} disabled={true} />
    </Box>
  );
};
