import React, { Fragment, useCallback } from 'react';

import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyAllowanceSection } from '@v2/feature/absence/subfeatures/settings/policy-details/policy-allowance-section.component';
import { AbsencePolicyApprovalSection } from '@v2/feature/absence/subfeatures/settings/policy-details/policy-approval-section.component';
import { AbsencePolicyGeneralSection } from '@v2/feature/absence/subfeatures/settings/policy-details/policy-general-section.component';
import { AbsencePolicyMembersSection } from '@v2/feature/absence/subfeatures/settings/policy-details/policy-members-section.component';
import { AbsencePolicyNotificationsSection } from '@v2/feature/absence/subfeatures/settings/policy-details/policy-notifications-section.component';
import { AbsencePolicyPayrollSection } from '@v2/feature/absence/subfeatures/settings/policy-details/policy-payroll-section.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import {
  SETTINGS_TIME_POLICIES_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE,
} from '@/lib/routes';

export const getPageConfig = (policyId: number, inPayroll: boolean, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('AbsencePolicyRouter.general'),
          stub: 'general',
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE, { policyId }),
        },
        {
          title: polyglot.t('AbsencePolicyRouter.allowance'),
          stub: 'allowance',
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE, { policyId }),
        },
        {
          title: polyglot.t('AbsencePolicyRouter.members'),
          stub: 'members',
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE, { policyId }),
        },
        {
          title: polyglot.t('AbsencePolicyRouter.approval'),
          stub: 'approval',
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE, { policyId }),
        },
        {
          title: polyglot.t('AbsencePolicyRouter.notifications'),
          stub: 'notifications',
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE, { policyId }),
        },
        {
          title: polyglot.t('AbsencePolicyRouter.payroll'),
          stub: 'payroll',
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE, { policyId }),
          isHidden: !inPayroll,
        },
      ],
    },
  ] as NavConfigItem[];
};

export const AbsencePolicyRouter = ({
  refreshPolicies,
  refreshArchivedPolicies,
}: {
  refreshPolicies: () => Promise<void>;
  refreshArchivedPolicies: () => Promise<void>;
}): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const params = useParams<{ policyId: string }>();
  const policyId = Number(params.policyId);

  const {
    data: absencePolicy,
    mutate: refreshPolicy,
    isLoading,
  } = useApiClient(AbsenceEndpoints.getAbsencePolicyExtendedById(policyId), { suspense: false });

  const { companyConfig } = useCompanyConfigState();

  const refresh = useCallback(async () => {
    if (refreshPolicy) await refreshPolicy();
  }, [refreshPolicy]);

  return (
    <Fragment>
      <DomainSideMenuContent
        title={absencePolicy?.name ?? ''}
        subtitle={polyglot.t('AbsencePolicyRouter.absencePolicy')}
        pageConfig={getPageConfig(policyId, companyConfig.inGlobalPayroll || companyConfig.inPayroll, polyglot)}
        backPath={SETTINGS_TIME_POLICIES_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper loading={isLoading}>
        {absencePolicy && (
          <Switch>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE}>
              <AbsencePolicyGeneralSection
                absencePolicy={absencePolicy}
                refreshPolicy={refresh}
                refreshPolicies={refreshPolicies}
                refreshArchivedPolicies={refreshArchivedPolicies}
              />
            </RouteScopesControl>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE}>
              <AbsencePolicyAllowanceSection absencePolicy={absencePolicy} refresh={refresh} />
            </RouteScopesControl>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE}>
              <AbsencePolicyMembersSection absencePolicy={absencePolicy} refresh={refresh} />
            </RouteScopesControl>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE}>
              <AbsencePolicyApprovalSection absencePolicy={absencePolicy} refresh={refresh} />
            </RouteScopesControl>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE}>
              <AbsencePolicyNotificationsSection absencePolicy={absencePolicy} refresh={refresh} />
            </RouteScopesControl>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE}>
              <AbsencePolicyPayrollSection absencePolicy={absencePolicy} refresh={refresh} />
            </RouteScopesControl>

            <Redirect to={SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE} exact />
          </Switch>
        )}
      </ContentWrapper>
    </Fragment>
  );
};
