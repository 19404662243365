import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { countFilters } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { FilterEditSection } from '@/v2/feature/reports/reports-advanced/components/filter-edit.section';
import { ReportSideBarView } from '@/v2/feature/reports/reports-advanced/components/report-config.section';
import { ReportFiltersSelection } from '@/v2/feature/reports/reports-advanced/components/report-filters-selection.component';
import { ReportFilters } from '@/v2/feature/reports/reports-advanced/components/report-filters.component';
import { ReportsEndpoints } from '@/v2/feature/reports/reports.api';
import { ReportFilterSelected, SelectedFiltersRequest } from '@/v2/feature/reports/reports.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { deepCopy } from '@/v2/infrastructure/object/object.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ReportedUser {
  userId: number;
  displayName: string;
  jobTitle: string;
}
export const SCParticipantsUpsertTriggersModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach,
}: {
  surveyCycle: SurveyCycle;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
  readonly reach: ReachType;
}) => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      widthPercentage={95}
      sx={{ overflow: 'hidden', px: spacing.s3, height: '100%', boxSizing: 'border-box' }}
    >
      <SCParticipantsUpsertTriggersContent
        surveyCycle={surveyCycle}
        onClose={onClose}
        refresh={refresh}
        reach={reach}
      />
    </DrawerModal>
  );
};

const SCParticipantsUpsertTriggersContent = ({
  surveyCycle,
  onClose,
  refresh,
  reach,
}: {
  surveyCycle: SurveyCycle;
  onClose: () => void;
  refresh: () => Promise<void>;
  readonly reach: ReachType;
}) => {
  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getGrowthReportsFiltersOptions(), {
    suspense: false,
  });

  const [view, setView] = useState<ReportSideBarView>('filters-list');
  const [filterToEdit, setFilterToEdit] = useState<ReportFilterSelected | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersRequest>(
    deepCopy(surveyCycle.enrolmentTriggerFilters ?? {}) || {}
  );
  const [reportResponse, setReportResponse] = useState<ReportedUser[] | undefined>(undefined);
  const [isGenerating, setIsGenerating] = useState(false);

  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const generateReport = useCallback(async () => {
    try {
      setIsGenerating(true);
      const response = await SurveyCycleAPI.generateRollingSurveyParticipants(selectedFilters, reach);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(undefined);
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsGenerating(false);
  }, [selectedFilters, polyglot, showMessage, reach]);

  useEffect(() => {
    generateReport();
  }, [generateReport]);

  const handleTriggerFilterUpsert = async () => {
    try {
      if (!(surveyCycle && surveyCycle.id)) {
        showMessage('Cycle not found', 'error');
        return;
      }
      await SurveyCycleAPI.updateEnrolmentTrigger(surveyCycle?.id, selectedFilters);
      showMessage('Successfully updated the enrolment trigger', 'success');
      refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  };

  const tableColumns = useMemo<ColumnDef<ReportedUser, ReportedUser>[]>(
    () => [
      {
        header: () => 'Display name',
        accessorFn: (row) => row,
        id: 'displayName',
        enableSorting: false,
        cell: ({
          row: {
            original: { displayName },
          },
        }) => {
          return displayName ? <div>{displayName}</div> : <EmptyCell />;
        },
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Job title',
        accessorFn: (row) => row,
        id: 'jobTitle',
        enableSorting: false,
        cell: ({
          row: {
            original: { jobTitle },
          },
        }) => {
          return jobTitle ? <div>{jobTitle}</div> : <EmptyCell />;
        },
        minSize: 120,
        maxSize: 150,
      },
    ],
    []
  );

  const isSaveDisabled = useMemo(() => {
    return Boolean(
      surveyCycle &&
        surveyCycle.state !== CycleState.Draft &&
        (selectedFilters === null || (selectedFilters && countFilters(selectedFilters) === 0))
    );
  }, [surveyCycle, selectedFilters]);

  return (
    <Box style={{ ...drawerContentSx, width: '100%' }}>
      <Typography variant="title2">Add enrolment trigger</Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box sx={{ width: '30%', maxHeight: `${window.innerHeight - 80}px`, overflowY: 'auto' }}>
          {view === 'filters-list' ? (
            <Box sx={drawerContentSx}>
              <ReportFilters
                openAddFilters={() => {
                  setView('filters-selection');
                }}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                editFilter={(selectedFilter: ReportFilterSelected) => {
                  setFilterToEdit(selectedFilter);
                  setView('filter-edit');
                }}
                reportFilters={filtersAndColumns?.filters ?? []}
              />
              <Box sx={{ ...buttonBoxDrawerSx, pb: 0 }}>
                <LoaderButton
                  type="button"
                  sizeVariant="medium"
                  disabled={isSaveDisabled}
                  onClick={() => handleTriggerFilterUpsert()}
                  colorVariant="primary"
                  name={polyglot.t('General.save')}
                  loading={isGenerating || isLoading}
                  fullWidth
                />
              </Box>
            </Box>
          ) : view === 'filters-selection' ? (
            <ReportFiltersSelection
              goBack={() => {
                setView('filters-list');
              }}
              goToEdit={() => {
                setView('filter-edit');
              }}
              reportFilters={filtersAndColumns?.filters ?? []}
              setFilterToEdit={setFilterToEdit}
            />
          ) : view === 'filter-edit' ? (
            <FilterEditSection
              setSelectedFilters={setSelectedFilters}
              goBack={() => {
                setView('filters-list');
                setFilterToEdit(null);
              }}
              reportFilters={filtersAndColumns?.filters ?? []}
              filter={filterToEdit}
            />
          ) : // FILTERS END
          null}
        </Box>

        <Box sx={{ width: '60%', maxHeight: `${window.innerHeight - 135}px`, height: '100%' }}>
          <BasicTable rowData={reportResponse || []} columnData={tableColumns} loading={isLoading || isGenerating} />
        </Box>
      </Box>
    </Box>
  );
};
