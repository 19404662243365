import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import autoAnimate from '@formkit/auto-animate';
import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Typography } from '@v2/components/typography/typography.component';
import { FormikProvider, useFormik } from 'formik';
// eslint-disable-next-line import/no-named-as-default
import { useHistory, useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';

import { LOGIN_ROUTE, PASSWORD_FORGOT_ROUTE } from '@/lib/routes';
import { AccountStatus } from '@/lib/users';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { CONTRACT_RECIPIENT_NOT_SET_PASSWORD_ERROR } from '@/v2/feature/auth/auth.interface';
import { performLogin, performSSOLogin } from '@/v2/feature/auth/auth.util';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { AuthLoginRouterState } from '@/v2/feature/auth/features/auth-router.states';
import { clearApiClientCache } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { spacing } from '@/v2/styles/spacing.styles';

interface FormProps {
  readonly username: string;
  readonly password: string;
}

export interface SSOCheck {
  companyId: number;
  app: string;
  enabled: boolean;
}

export interface SSOLoginCheck {
  ssoConfig: SSOCheck[];
  accountStatus: AccountStatus;
}

const INVALID_CREDENTIALS_MESSAGE = 'Invalid credentials';
const TOO_MANY_REQUESTS_MESSAGE = 'You have tried to login too many times recently; please try again in a minute';
const DEACTIVATED_USER_MESSAGE = 'Your account is currently deactivated.';
const UNACTIVATED_USER_MESSAGE =
  'Your account has not been activated yet, please ask your manager to send you an invite link.';
// this will eventually need to become a selector, that the user can choose
export const ENABLED_SSO_APPS = ['sso-google-workspace', 'sso-okta', 'sso-azure-ad'];
const ACCOUNT_STATES_TO_PUSH_SSO_USER_FOR_PASSWORD_AUTH: AccountStatus[] = [
  AccountStatus.Invited,
  AccountStatus.InvitedToOnboard,
];

export const AuthLoginPage = (): JSX.Element => {
  const swrConfig = useSWRConfig();
  const routerHistory = useHistory();
  const routerLocation = useLocation<AuthLoginRouterState | undefined>();
  const { continueOnboarding, contractRecipientHasSetPassword, contractSigningRedirect, email } =
    routerLocation.state || {};
  const queries = new URLSearchParams(routerLocation.search);
  const resetedPassword = queries.get('reset') === 'true';
  const deactivatedUser = queries.get('deactivated') === 'true';
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ssoError, setSSOError] = useState<string | null>(null);
  const [accountStatus, setAccountStatus] = useState<AccountStatus | undefined>(undefined);
  const [contractSignatoryPasswordNotSet, setContractSignatoryPasswordNotSet] = useState(false);

  const [ssoEnabled, setSSOEnabled] = useState<boolean>(false);
  const [disableLogin, setDisableLogin] = useState<boolean>(false);
  const [matchingProvider, setMatchingProvider] = useState<SSOCheck | undefined>();

  const { identifyUser } = useJune();

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    if (deactivatedUser) {
      setErrorMessage(DEACTIVATED_USER_MESSAGE);
    }
    if (
      contractSigningRedirect &&
      (!contractRecipientHasSetPassword || contractRecipientHasSetPassword?.length === 0)
    ) {
      setContractSignatoryPasswordNotSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forwardToOnboarding = useMemo(() => {
    return accountStatus === AccountStatus.InvitedToOnboard || continueOnboarding;
  }, [accountStatus, continueOnboarding]);

  const validationSchema = yup.object({
    username: yup.string().email('Enter a valid email address').required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const formik = useFormik<FormProps>({
    initialValues: {
      username: continueOnboarding && email ? email : '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ username, password }: FormProps) => {
      if (resetedPassword) routerHistory.replace(LOGIN_ROUTE);
      setErrorMessage('');
      setIsLoading(true);
      if (ssoEnabled) return;
      try {
        clearApiClientCache(swrConfig);
        await performLogin(routerHistory, routerLocation, { username, password }, identifyUser, forwardToOnboarding);
      } catch (error: any) {
        console.error(error);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          setErrorMessage(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 422) {
          setErrorMessage(INVALID_CREDENTIALS_MESSAGE);
          return;
        }
        if (error.response && error.response.status === 429) {
          setErrorMessage(TOO_MANY_REQUESTS_MESSAGE);
          return;
        }
        setErrorMessage(`${error}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const getSSOCheckResult = useCallback(async (username: string) => {
    try {
      const loginCheckData = await AuthAPI.ssoCheck(username);
      return loginCheckData;
    } catch (error) {
      console.error('Error: ', error);
    }
  }, []);

  const debouncedCheckForSSO = useDebouncedCallback(
    async (username: string) => {
      setIsLoading(true);
      if (!formik.errors.hasOwnProperty('username') && username.length > 3) {
        setSSOError(null);
        setDisableLogin(false);
        const loginCheckData = await getSSOCheckResult(username);
        if (!loginCheckData) return;
        setAccountStatus(loginCheckData?.accountStatus);
        if (ACCOUNT_STATES_TO_PUSH_SSO_USER_FOR_PASSWORD_AUTH.includes(loginCheckData?.accountStatus)) {
          // continue with normal password login
          setSSOEnabled(false);
        } else if (loginCheckData?.accountStatus === AccountStatus.Active) {
          const ssoConfig = loginCheckData.ssoConfig;
          const ssoEnabledForCompany = ssoConfig.some((app) => app.enabled === true);
          const matchingProviderData = ssoConfig.find(
            (eachApp) => ENABLED_SSO_APPS.includes(eachApp.app) && eachApp.enabled
          );
          setMatchingProvider(matchingProviderData);
          setSSOEnabled(ssoEnabledForCompany);
          setSSOError(null);
        } else if (loginCheckData?.accountStatus === AccountStatus.Deactivated) {
          // prevent login if account is deactivated, till username changes
          setSSOError(DEACTIVATED_USER_MESSAGE);
          setSSOEnabled(false);
          setDisableLogin(true);
        } else if (loginCheckData?.accountStatus === AccountStatus.Created) {
          // prevent login if account is created, till username changes
          setSSOError(UNACTIVATED_USER_MESSAGE);
          setSSOEnabled(false);
          setDisableLogin(true);
        } else {
          // continue with normal password login
          setSSOEnabled(false);
        }
      }
      setIsLoading(false);
    },
    500 // 500ms debounce time
  );

  useEffect(() => {
    debouncedCheckForSSO.callback(formik.values.username);
  }, [debouncedCheckForSSO, formik.values.username]);

  const triggerSSOLogin = useCallback(async () => {
    try {
      if (!formik.values.username) return;
      if (ssoEnabled && matchingProvider) {
        await performSSOLogin(
          formik.values.username,
          routerHistory,
          routerLocation,
          matchingProvider,
          identifyUser,
          continueOnboarding
        );
      }
    } catch (error) {
      setSSOError(error.message);
    } finally {
      formik.resetForm();
      setIsLoading(false);
    }
  }, [continueOnboarding, formik, identifyUser, matchingProvider, routerHistory, routerLocation, ssoEnabled]);

  return (
    <FormikProvider value={formik}>
      <AuthLayout
        title="Log in"
        submit={formik.handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // submit form via Enter key press only works for NON-SSO flow
            if (!ssoEnabled) formik.handleSubmit(e);
            else {
              // SSO auto forward via Enter key press does not work as expected
              // user needs to click continue button to initiate SSO flow
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }}
      >
        {(resetedPassword || continueOnboarding) && (
          <Alert severity="success" sx={{ my: 2 }}>
            Password already set. Please login using the password you have set or click “Forgot password” to reset it.
          </Alert>
        )}
        {contractSignatoryPasswordNotSet && (
          <Alert severity="warning" sx={{ my: 2 }}>
            {CONTRACT_RECIPIENT_NOT_SET_PASSWORD_ERROR}
          </Alert>
        )}
        <Stack sx={{ minHeight: '40px' }} ref={parent}>
          <TextfieldComponent
            label="Work email"
            name="username"
            id="username"
            value={formik.values.username}
            onChange={(e) => {
              formik.setFieldValue('username', e.target.value?.toLowerCase());
            }}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            type="email"
            autoComplete="email"
            endAdornment="none"
            autoFocus
          />
          {ssoError && (
            <Typography variant="caption" color="darkRed" sx={{ opacity: 1 }}>
              {ssoError}
            </Typography>
          )}
          {!ssoEnabled && (
            <Stack sx={{ mt: spacing.m30 }}>
              <TextfieldComponent
                label="Password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!errorMessage}
                helperText={errorMessage}
                type="password"
                autoComplete="current-password"
              />
              <Stack sx={{ ...spacing.mt20 }}>
                <ButtonComponent
                  sizeVariant="link"
                  colorVariant="textUnderline"
                  onClick={(e) => {
                    // pass the email address to the /password/forgot page so the user doesn't have to type it again
                    routerHistory.push(PASSWORD_FORGOT_ROUTE, { email: formik.values.username });
                    e.preventDefault();
                  }}
                >
                  Forgot password?
                </ButtonComponent>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack sx={{ marginTop: spacing.m30, width: { xs: '50%', sm: '100%', md: '100%', lg: '100%' } }}>
          <ButtonComponent
            fullWidth
            disabled={isLoading || disableLogin}
            sizeVariant="medium"
            colorVariant="primary"
            onClick={async () => {
              ssoEnabled ? await triggerSSOLogin() : formik.handleSubmit();
            }}
            type="button"
          >
            {ssoEnabled ? 'Continue' : 'Log in'}
          </ButtonComponent>
        </Stack>
      </AuthLayout>
    </FormikProvider>
  );
};
