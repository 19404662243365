import { Typography } from '@/v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { useCallback, useMemo, useState } from 'react';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { ColumnDef, Row } from '@tanstack/react-table';
import { OneOffPaymentDto } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { TableSearch } from '@v2/components/table/table-search.component';
import { spacing } from '@v2/styles/spacing.styles';
import { Box, IconButton } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { NumberCell } from '@v2/components/table/number-cell.component';
import { ScopesControl } from '@/component/widgets/Scopes';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { iconSize } from '@v2/styles/menu.styles';
import { UpdateUpcomingPayItemDrawer } from '@/component/dashboard/userDetails/pay-items/components/update-upcoming-pay-item-drawer.component';
import { round2Digits } from '@v2/util/number.util';
import { AddNewPayItemDrawer } from '@/component/dashboard/userDetails/pay-items/components/add-new-pay-item-drawer.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { ViewUpcomingPayItemDrawer } from '@/component/dashboard/userDetails/pay-items/components/view-upcoming-pay-item-drawer.component';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { themeColors } from '@/v2/styles/colors.styles';

interface TableProps {
  readonly title: string;
  readonly userId: number;
  readonly oneOffPayments: readonly OneOffPaymentDto[];
  readonly refresh: () => Promise<void>;
  readonly showAdd?: boolean;
  readonly showEdit?: boolean;
}

export const UpcomingPayItemsTable = ({
  title,
  userId,
  oneOffPayments,
  refresh,
  showAdd = false,
  showEdit = false,
}: TableProps) => {
  const { polyglot } = usePolyglot();

  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedPayItem, setSelectedPayItem] = useState<OneOffPaymentDto | null>(null);
  const [editUpcomingPayItem, setEditUpcomingPayItem] = useState<boolean>(false);
  const [isViewDrawerOpen, setIsViewDrawerOpen] = useState<boolean>(false);
  const [isAddNewOpen, setIsAddNewOpen] = useState<boolean>(false);

  const filteredPayItems = useMemo(() => {
    if (!oneOffPayments) return [];
    if (!searchInput) return [...oneOffPayments];

    const search = searchInput.toLowerCase();
    return oneOffPayments.filter((pI) => pI.description.toLowerCase().includes(search));
  }, [searchInput, oneOffPayments]);

  const tableColumns = useMemo<ColumnDef<OneOffPaymentDto, OneOffPaymentDto>[]>(() => {
    return [
      {
        header: () => polyglot.t('General.date'),
        accessorFn: (row) => row,
        id: 'date',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.date),
        cell: ({ row: { original } }) => {
          return <Typography variant="caption">{polyglot.t(original.date)}</Typography>;
        },
      },
      {
        header: () => polyglot.t('General.description'),
        accessorFn: (row) => row,
        id: 'description',
        minSize: 200,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return <Typography variant="caption">{original.description}</Typography>;
        },
      },
      {
        header: () => polyglot.t('PayItemModule.amount'),
        accessorFn: (row) => row,
        id: 'amount',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.amount * item.multiplier),
        cell: ({ row: { original } }) => (
          <NumberCell
            value={round2Digits(original.amount * original.multiplier) ?? 0}
            cellSx={{ maxWidth: '50px', justifyContent: 'center' }}
          />
        ),
      },
      {
        header: () => polyglot.t('PayItemModule.payCode'),
        accessorFn: (row) => row,
        id: 'payCode',
        enableSorting: true,
        cell: ({ row: { original } }) => {
          return <Typography variant="caption">{original.payCode}</Typography>;
        },
      },
      {
        header: () => polyglot.t('PensionModule.state'),
        accessorFn: (row) => row,
        id: 'paidImpliedStatus',
        enableSorting: true,
        cell: ({ row: { original } }) => {
          return original.paidImpliedStatus ? (
            <Box sx={{ display: 'flex' }}>
              <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => polyglot.t('PayItemModule.source'),
        accessorFn: (row) => row,
        id: 'source',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.status),
        cell: ({ row: { original } }) => {
          if (original.autogenerated && original.absenceId)
            return <Typography variant="caption">{polyglot.t('PayItemModule.autogeneratedFromAbsence')}</Typography>;
          if (original.autogenerated && original.attendanceId)
            return <Typography variant="caption">{polyglot.t('PayItemModule.autogeneratedFromAttendance')}</Typography>;
          if (original.autogenerated && original.userCustomBenefitPaymentId)
            return <Typography variant="caption">{polyglot.t('PayItemModule.autogeneratedFromBenefit')}</Typography>;
          if (original.autogenerated)
            return <Typography variant="caption">{polyglot.t('PayItemModule.autogenerated')}</Typography>;

          return <UserCell userId={original.createdBy} prefix={polyglot.t('PayItemModule.createdBy')} />;
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        maxSize: 50,
        minSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          // can only edit pay item if not historical / not paid
          original.id && !original.paidImpliedStatus ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'default' }}
              onClick={(e) => e.stopPropagation()}
            >
              <ScopesControl scopes={['payroll:all']} context={{ userId: original.userId }}>
                <IconButton
                  title={polyglot.t('CompensationCard.edit')}
                  onClick={() => {
                    setSelectedPayItem(original);
                    setEditUpcomingPayItem(true);
                  }}
                  sx={tableIconButtonSx}
                >
                  <Edit {...iconSize} />
                </IconButton>
              </ScopesControl>
            </Box>
          ) : null,
      },
    ];
  }, [polyglot]);

  const handleRowClick = useCallback((row: Row<OneOffPaymentDto>) => {
    setSelectedPayItem(row.original);
    setIsViewDrawerOpen(true);
  }, []);

  return (
    <>
      <Typography variant="title3" sx={{ mb: spacing.m5 }}>
        {title}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', gap: spacing.g5 }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>

        {showAdd && (
          <ScopesControl scopes={['payroll:all']} context={{ userId }}>
            <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => setIsAddNewOpen(true)}>
              {polyglot.t('General.add')}
            </ButtonComponent>
          </ScopesControl>
        )}
      </Box>

      <Box sx={{ mt: spacing.m20 }}>
        <BasicTable<OneOffPaymentDto> rowData={filteredPayItems} columnData={tableColumns} rowClick={handleRowClick} />
      </Box>

      <ViewUpcomingPayItemDrawer
        isOpen={isViewDrawerOpen}
        setIsOpen={setIsViewDrawerOpen}
        oneOffPayment={selectedPayItem}
        refresh={refresh}
        onClose={() => setSelectedPayItem(null)}
        showDelete={!selectedPayItem?.paidImpliedStatus} // only allow deletion of pay item / one off payment if upcoming and not historic
      />

      {showEdit && editUpcomingPayItem && selectedPayItem && (
        <UpdateUpcomingPayItemDrawer
          isOpen={editUpcomingPayItem}
          setIsOpen={setEditUpcomingPayItem}
          oneOffPayment={selectedPayItem}
          refresh={refresh}
          onClose={() => setSelectedPayItem(null)}
        />
      )}

      {isAddNewOpen && (
        <AddNewPayItemDrawer isOpen={isAddNewOpen} setIsOpen={setIsAddNewOpen} userId={userId} refresh={refresh} />
      )}
    </>
  );
};
