import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { DaysOptions, DayType } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserCalendarView = ({
  calendarData,
  weekDaysFromMonToSun,
  headerAction,
}: {
  calendarData: JSX.Element[];
  weekDaysFromMonToSun: string[];
  headerAction?: () => void;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <div className="cs-separation">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
        <Box
          sx={{
            '&:hover': { background: themeColors.transparency },
            borderRadius: radius.br10,
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => headerAction?.()}
        >
          <div style={{ padding: spacing.p5 }}>
            <Typography variant="title2">{polyglot.t('UserCalendarView.calendar')}</Typography>
          </div>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', width: '100%' }}>
        <div style={{ padding: spacing.p5, width: '100%' }}>
          <table className="cw-table">
            <thead>
              <tr>
                {weekDaysFromMonToSun.map((d, idx) => (
                  <th className="cw-table-head" key={`${d}-ucvb-week`}>
                    <Box
                      key={`${idx}-${d}`}
                      sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="captionSmall"
                        sx={{
                          color: themeColors.DarkGrey,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {DaysOptions(polyglot)[d as DayType]}
                      </Typography>
                    </Box>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{calendarData}</tbody>
          </table>
        </div>
      </Box>
    </div>
  );
};
