import { AddChecklistTask } from '@v2/feature/task/subfeature/checklist/pages/add-checklist.page';
import { ChecklistsPage } from '@v2/feature/task/subfeature/checklist/pages/checklists.page';
import { TasksChecklistOverview } from '@v2/feature/task/subfeature/checklist/pages/manage-checklist.page';
import { Route, Switch } from 'react-router-dom';

import {
  SETTINGS_TASK_CHECKLISTS_ADD_CHECKLIST_ROUTE,
  SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE,
  SETTINGS_TASK_CHECKLIST_ROUTE,
} from '@/lib/routes';

export const ChecklistRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path={SETTINGS_TASK_CHECKLISTS_ADD_CHECKLIST_ROUTE}>
        <AddChecklistTask />
      </Route>

      <Route exact path={SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE}>
        <TasksChecklistOverview />
      </Route>

      <Route path={SETTINGS_TASK_CHECKLIST_ROUTE}>
        <ChecklistsPage />
      </Route>
    </Switch>
  );
};
