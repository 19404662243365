import React, { useContext, useEffect, useState } from 'react';

import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { KeyedMutator } from 'swr';

import { CompanyAPI } from '@/api-client/index.api';
import { UploadProfilePicture } from '@/component/forms/UploadProfilePicture';
import { GlobalContext } from '@/GlobalState';
import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CompanySettingsDetailsDrawer } from '@/v2/feature/company/company-settings/features/components/company-settings/company-details/company-settings-details-drawer.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const CompanySettingsDetailsComponent = ({
  generalSettings,
  refreshSettings,
}: {
  generalSettings: GeneralSettingsDto | null;
  refreshSettings: KeyedMutator<GeneralSettingsDto> | undefined;
}) => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const [localAvatar, setLocalAvatar] = useState<string>();
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setLocalAvatar(globalState.user.company.avatar);
  }, [globalState.user.company.avatar, setLocalAvatar]);

  return (
    <>
      <SettingsSubsectionContent
        sections={[
          {
            title: polyglot.t('CompanySettingsGeneralEdit.companySettings'),
            onEdit: () => setEditMode(true),
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('CompanySettingsGeneralEdit.displayName'),
                value: generalSettings?.name ?? '-',
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('CompanySettingsGeneralEdit.companyLogo'),
                value: (
                  <UploadProfilePicture
                    boxSize={20}
                    avatarSize="xxsmall"
                    disabled={true}
                    value={localAvatar}
                    onChange={(tempAvatar) => {
                      setLocalAvatar(tempAvatar);
                    }}
                    url={CompanyAPI.getCompanyUploadURL()}
                    userId={null}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <CompanySettingsDetailsDrawer
        generalSettings={generalSettings}
        refreshSettings={refreshSettings}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    </>
  );
};
