import { useMemo } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { UpdateAnswer } from '@v2/feature/growth/reviews/features/review-answer/review-answer-submission/review-answer-submission.page';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthEntry, GrowthUpsertAnswer } from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { borders } from '@/v2/styles/borders.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SingleAnswerQuestion = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
}: {
  question: SurveyQuestion;
  answerArray: GrowthUpsertAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: GrowthEntry) => void;
  entry: GrowthEntry | undefined;
}) => {
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = answerArray?.find((ans) => ans.questionId === question.id) || { answer: '', comment: '' };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);

  if (!question || !entry) return <></>;

  const handleChoiceChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
  };

  const handleCommentChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <FormControl component="fieldset" sx={{ width: '100%', margin: 0 }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="answer"
          onChange={(e) => {
            handleChoiceChange(e.target.value);
          }}
        >
          {question &&
            question?.choiceOptions &&
            Object.keys(question?.choiceOptions).map((a) => (
              <FormControlLabel
                key={a}
                labelPlacement="end"
                value={a}
                checked={a === currentAnswer}
                control={<StyledRadio />}
                label={<Typography variant="caption">{question?.choiceOptions[+a]}</Typography>}
                sx={{
                  py: spacing.p10,
                  margin: 0,
                  gap: spacing.g10,
                  px: 0,
                  '& span.MuiButtonBase-root.MuiRadio-root': {
                    padding: 0,
                  },
                }}
              />
            ))}
        </RadioGroup>
      </FormControl>

      {question.hasComment && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.g8,
            pt: spacing.p16,
            borderTop: `${borders.background}`,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Add comment
            </Typography>
            <Typography variant="caption" color="Grey">
              {question.isCommentRequired ? 'Required' : 'Optional'}
            </Typography>
          </Box>
          <RichTextField
            key={question.id}
            value={currentComment}
            onChange={(value: string) => handleCommentChange(value)}
          />
        </Box>
      )}
    </Box>
  );
};
