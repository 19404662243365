import { SxProps, Theme } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const iconButtonSx: SxProps<Theme> = {
  borderRadius: radius.br10,
  p: spacing.p10,
  width: 40,
  height: 40,
  backgroundColor: themeColors.Background,
  fill: themeColors.DarkGrey,
  color: themeColors.DarkGrey,
  objectFit: 'contain',
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const tableIconButtonSx: SxProps<Theme> = {
  ...themeFonts.caption,
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  objectFit: 'contain',
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const tableWhiteIconButtonSx: SxProps<Theme> = {
  ...themeFonts.caption,
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  objectFit: 'contain',
  backgroundColor: themeColors.white,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.TableHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const tableIconErrorButtonSx: SxProps<Theme> = {
  ...themeFonts.caption,
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  objectFit: 'contain',
  backgroundColor: themeColors.Red,
  color: themeColors.white,
  fill: themeColors.white,
  '&:hover': {
    backgroundColor: themeColors.hoverRed,
    fill: themeColors.white,
  },
  '&:target': {
    backgroundColor: themeColors.pressRed,
    fill: themeColors.white,
  },
  '&:disabled': {
    backgroundColor: themeColors.lightRed,
    fill: themeColors.white,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const tablePrimaryIconButtonSx: SxProps<Theme> = {
  ...themeFonts.caption,
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  backgroundColor: themeColors.ZeltYellow,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.YellowHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.YellowPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.DisabledYellow,
    fill: themeColors.DarkGrey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const tableSecondaryIconButtonSx: SxProps<Theme> = {
  ...themeFonts.caption,
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const paginationIconButtonSx: SxProps<Theme> = {
  ...themeFonts.caption,
  borderRadius: radius.br8,
  p: spacing.p8,
  minWidth: 30,
  height: 30,
  marginLeft: spacing.m5,
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const paginationActiveStyle = {
  ...themeFonts.caption,
  fill: themeColors.DarkGrey,
  backgroundColor: themeColors.DarkGrey,
  color: themeColors.white,
  '&:hover': {
    color: themeColors.white,
    fill: themeColors.DarkGrey,
    backgroundColor: themeColors.DarkGrey,
  },
};

export const iconCrossButtonSx: SxProps<Theme> = {
  borderRadius: radius.br10,
  p: spacing.p10,
  width: 40,
  height: 40,
  fontSize: '20px',
  backgroundColor: themeColors.Background,
  fill: themeColors.DarkGrey,
  color: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const iconCrossPrimaryButtonSx: SxProps<Theme> = {
  borderRadius: radius.br10,
  p: spacing.p10,
  width: 40,
  height: 40,
  fontSize: '20px',
  backgroundColor: themeColors.ZeltYellow,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.YellowHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.YellowPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.DisabledYellow,
    fill: themeColors.DarkGrey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};

export const hazardMissingField: SxProps<Theme> = {
  width: '20px',
  height: '20px',
  borderRadius: radius.br15,
  backgroundColor: themeColors.red,
  fill: themeColors.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const iconCTAButtonSx: SxProps<Theme> = {
  borderRadius: radius.br10,
  p: spacing.p5,
  width: 30,
  height: 30,
  fontSize: '20px',
  backgroundColor: themeColors.Background,
  fill: themeColors.DarkGrey,
  '&:hover': {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey,
  },
  '&:target': {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey,
  },
  '&:disabled': {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: 'not-allowed',
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
};
