import React, { useCallback } from 'react';

import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { HolidayCalendarEndpoints } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import { HolidayCalendarGeneralSection } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/pages/holiday-calendar-general-section.component';
import { HolidayCalendarHolidaysSection } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/pages/holiday-calendar-holidays-section.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE,
} from '@/lib/routes';

const getPageConfig = (calendarId: string, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId }),
        },
        {
          title: polyglot.t('HolidayCalendarModule.calendar'),
          stub: 'calendar',
          path: generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE, { calendarId }),
        },
      ],
    },
  ] as NavConfigItem[];
};

interface RouterProps {
  readonly refreshAll: () => Promise<void>;
}

export const HolidayCalendarRouter = ({ refreshAll }: RouterProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const params = useParams<{ calendarId: string }>();
  const calendarId = params.calendarId;

  const {
    data: holidayCalendar,
    mutate: refreshHolidayCalendar,
    isLoading,
  } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendarById(calendarId, true), { suspense: false });

  const refreshCalendar = useCallback(async () => {
    if (refreshHolidayCalendar) await refreshHolidayCalendar();
  }, [refreshHolidayCalendar]);

  return (
    <>
      <DomainSideMenuContent
        title={holidayCalendar?.name ?? ''}
        subtitle={polyglot.t('HolidayCalendarModule.holidayCalendar')}
        pageConfig={getPageConfig(calendarId, polyglot)}
        backPath={SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper loading={isLoading}>
        {holidayCalendar ? (
          <Switch>
            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE}>
              <HolidayCalendarGeneralSection
                holidayCalendar={holidayCalendar}
                refreshAll={async () => {
                  await Promise.all([refreshAll(), refreshCalendar()]);
                }}
              />
            </RouteScopesControl>

            <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE}>
              <HolidayCalendarHolidaysSection holidayCalendar={holidayCalendar} refresh={refreshCalendar} />
            </RouteScopesControl>

            <Redirect to={SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE} />
          </Switch>
        ) : (
          <Switch>
            <Redirect to={SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE} />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
};
