import React, { useState } from 'react';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import {
  ScheduleTimeEntry,
  ScheduleWeeksPattern,
  WeekDay,
  WeekDayNames,
} from '@v2/feature/attendance/attendance.interface';
import { SchedulePatternFlexibleRow } from '@v2/feature/attendance/settings/components/schedule-pattern-flexible-row.component';
import { SchedulePatternRow } from '@v2/feature/attendance/settings/components/schedule-pattern-row.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { FormikProps } from 'formik';

import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';

interface FormProps {
  readonly formik: FormikProps<ScheduleWeeksPattern>;
  readonly weekNo: number;
  readonly schedule: AttendanceScheduleDto;
  readonly noOfWeeks: number;
}

export const AttendanceScheduleWeekPatternForm = ({ formik, weekNo, schedule, noOfWeeks }: FormProps) => {
  const { polyglot } = usePolyglot();
  const [isAddingNew, setIsAddingNew] = useState(false);

  const renderRow = (day: WeekDay) => {
    const commonProps = {
      day,
      weekNo,
      values: formik.values,
      setDayForm: (value: ScheduleTimeEntry) => {
        const updatedValues = [...formik.values[day]];
        updatedValues[weekNo] = value;
        formik.setFieldValue(day, updatedValues);
      },
    };

    return !schedule.isFlexible ? (
      <SchedulePatternRow {...commonProps} />
    ) : (
      <SchedulePatternFlexibleRow {...commonProps} />
    );
  };

  const addWeek = async () => {
    setIsAddingNew(true);
    await Promise.all([
      formik.setFieldValue('noOfWeeks', formik.values.noOfWeeks + 1),
      formik.setFieldValue('monday', [...formik.values.monday, null]),
      formik.setFieldValue('tuesday', [...formik.values.tuesday, null]),
      formik.setFieldValue('wednesday', [...formik.values.wednesday, null]),
      formik.setFieldValue('thursday', [...formik.values.thursday, null]),
      formik.setFieldValue('friday', [...formik.values.friday, null]),
      formik.setFieldValue('saturday', [...formik.values.saturday, null]),
      formik.setFieldValue('sunday', [...formik.values.sunday, null]),
    ]);
    setIsAddingNew(false);
  };

  const removeWeek = async () => {
    await Promise.all([
      formik.setFieldValue('noOfWeeks', formik.values.noOfWeeks - 1),
      formik.setFieldValue('monday', [...formik.values.monday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue('tuesday', [...formik.values.tuesday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue('wednesday', [...formik.values.wednesday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue('thursday', [...formik.values.thursday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue('friday', [...formik.values.friday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue('saturday', [...formik.values.saturday.filter((_, index) => index !== weekNo)]),
      formik.setFieldValue('sunday', [...formik.values.sunday.filter((_, index) => index !== weekNo)]),
    ]);
  };

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="title4" sx={{ my: '20px' }}>
          {noOfWeeks > 1 ? polyglot.t('AttendanceSchedule.weekNo', { weekNo: weekNo + 1 }) : ''}
        </Typography>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          {noOfWeeks > 1 && (
            <IconButton
              sx={tableIconButtonSx}
              onClick={removeWeek}
              title={polyglot.t('AttendanceCompanySettingsScheduleDetailsPage.remove')}
            >
              <TrashIcon {...iconSize} />
            </IconButton>
          )}

          {weekNo === noOfWeeks - 1 && !schedule.isFlexible && (
            <ButtonComponent
              sizeVariant="filter"
              colorVariant="secondary"
              onClick={addWeek}
              endIcon={<Plus {...iconSize} />}
              loading={isAddingNew}
            >
              {polyglot.t('AttendanceSchedulePattern.addWeek')}
            </ButtonComponent>
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15 }}>
        {WeekDayNames.map((day) => renderRow(day))}
      </Box>
    </Box>
  );
};
