import { useContext } from 'react';

import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsenceCompanyBalancesPage } from '@v2/feature/absence/company/policies/pages/absence-company-balances.page';
import { AbsenceRequestsPage } from '@v2/feature/absence/company/policies/pages/absence-requests.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { ABSENCE_COMPANY_BALANCES_ROUTE, ABSENCE_COMPANY_REQUESTS_ROUTE, ABSENCE_COMPANY_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';

export const AbsenceCompanyRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { data: absencePolicies, isLoading } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), {
    suspense: false,
  });

  return (
    <Switch>
      <RouteScopesHas scopes={['absence:all']} path={ABSENCE_COMPANY_REQUESTS_ROUTE} exact>
        <AbsenceRequestsPage
          reach="company"
          absencePolicies={absencePolicies ?? []}
          absencePoliciesLoading={Boolean(isLoading)}
        />
      </RouteScopesHas>

      <RouteScopesHas scopes={['absence:all']} path={ABSENCE_COMPANY_BALANCES_ROUTE} exact>
        <AbsenceCompanyBalancesPage absencePolicies={absencePolicies ?? []} />
      </RouteScopesHas>

      {checkScopes(globalState.user, ['absence:all']) ? (
        <Redirect to={ABSENCE_COMPANY_REQUESTS_ROUTE} />
      ) : (
        <Redirect to={ABSENCE_COMPANY_ROUTE} />
      )}
    </Switch>
  );
};
