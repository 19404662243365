import React, { forwardRef } from 'react';

import { Button, ButtonProps } from '@mui/base/Button';

import '@/v2/scss/components/button-component.scss';

import { ReactComponent as LoaderIcon } from '@/images/side-bar-icons/Loader.svg';

export type ButtonSizeVariant = 'large' | 'medium' | 'small' | 'filter' | 'link';

export type ButtonColorVariant =
  | 'primary'
  | 'secondary'
  | 'light'
  | 'danger'
  | 'dashed'
  | 'tooltip'
  | 'text'
  | 'textUnderline'
  | 'active';

export type ButtonComponentProps = ButtonProps & {
  children?: React.ReactNode;
  sizeVariant: ButtonSizeVariant;
  colorVariant: ButtonColorVariant;
  className?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties; // sx is mui prop
  startIcon?: React.JSX.Element;
  endIcon?: React.JSX.Element;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  loading?: boolean;
};

export const ButtonComponent = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (
    {
      className,
      sizeVariant,
      colorVariant,
      fullWidth,
      style,
      children,
      startIcon,
      endIcon,
      type,
      loading,
      ...otherProps
    },
    ref
  ) => {
    const derivedType = type ? type : otherProps.onClick ? 'button' : 'submit';
    return (
      <Button
        ref={ref}
        {...otherProps}
        className={`${className} ${sizeVariant}-button ${colorVariant}-button ${fullWidth ? 'fullWidth-button' : ''}`}
        style={style}
        type={derivedType}
      >
        {loading ? (
          <LoaderIcon className="button-loader-spin" />
        ) : (
          <>
            {startIcon}
            {children}
            {endIcon}
          </>
        )}
      </Button>
    );
  }
);
