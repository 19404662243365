import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly remainingBalance: number;
  readonly refresh: () => Promise<void>;
}

export const RequestOnDemandBenefitDrawer = ({
  isOpen,
  setIsOpen,
  userCustomBenefit,
  remainingBalance,
  refresh,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <RequestOnDemandBenefitDrawerContent
        userCustomBenefit={userCustomBenefit}
        remainingBalance={remainingBalance}
        refresh={refresh}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
  readonly remainingBalance: number;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const RequestOnDemandBenefitDrawerContent = ({
  userCustomBenefit,
  remainingBalance,
  refresh,
  setIsOpen,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik<{ amount: number; notes: string }>({
    initialValues: {
      amount: 0,
      notes: '',
    },
    validationSchema: yup.object({
      amount: yup
        .number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .min(0, polyglot.t('ValidationMessages.validValue'))
        .max(remainingBalance, polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      notes: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values: { amount: number; notes: string }) => {
      try {
        setIsLoading(true);
        const data = { amount: Number(values.amount), notes: values.notes };
        await CustomBenefitAPI.userCustomBenefitOneOffPayment(
          userCustomBenefit.userId,
          userCustomBenefit.customBenefitId,
          userCustomBenefit.id,
          data
        );
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography variant="title3">{polyglot.t('BenefitModule.requestPayment')}</Typography>

        <Box sx={{ mt: '20px' }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('BenefitModule.remainingToday')}
          </Typography>
          <Typography variant="title4">{formatMoney({ amount: remainingBalance })}</Typography>
        </Box>

        <Box sx={{ ...fieldSx, mt: '10px' }}>
          <TextfieldComponent
            name="amount"
            label={polyglot.t('General.amount')}
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
            fullWidth
            size="small"
          />
        </Box>

        <Box sx={{ ...fieldSx, mt: '10px' }}>
          <TextfieldComponent
            name="notes"
            label={polyglot.t('General.notes')}
            value={formik.values.notes}
            onChange={formik.handleChange}
            error={formik.touched.notes && Boolean(formik.errors.notes)}
            helperText={formik.touched.notes && formik.errors.notes}
            fullWidth
            size="small"
          />
        </Box>

        <Box sx={{ ...fieldSx, mt: '30px' }}>
          <LoaderButton
            name={polyglot.t('General.request')}
            sizeVariant="medium"
            loading={isLoading}
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
