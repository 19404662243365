import { Fragment, useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { addDays, isMonday, previousMonday } from 'date-fns';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { TIME_ME_CALENDAR_ROUTE } from '@/lib/routes';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getWeekRange } from '@/v2/feature/dashboard/dashboard.util';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { UserCalendarEmptyState } from '@/v2/feature/dashboard/features/sections/user-calendar/components/user-calendar-empty-state.component';
import {
  MappedEventsProps,
  MappedUserProps,
} from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.interface';
import {
  getCategorizedData,
  getCurrentDateAndFutureEvents,
  getFilteredData,
} from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util';
import { CalendarWidgetReach } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserCalendarVersionSmall = ({
  calendarSmall,
  readOnly = false,
}: {
  calendarSmall: CalendarWidgetReach;
  readOnly?: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const signedInUser = getCachedUserById(state.user.userId);
  const weekDaysFromMonToSun = useMemo(() => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], []);
  const monday = useMemo(
    () => (isMonday(new LocalDate().getDate()) ? new LocalDate().getDate() : previousMonday(new LocalDate().getDate())),
    []
  );
  const routerHistory = useHistory();

  const filteredData: MappedUserProps[] = useMemo(() => {
    const weekRange = getWeekRange();
    let users = nonTerminatedCachedUsers;
    return getFilteredData(
      weekRange,
      users,
      calendarSmall?.view,
      signedInUser,
      calendarSmall?.time?.requests,
      calendarSmall?.fellowReportees
    );
  }, [
    calendarSmall?.time.requests,
    nonTerminatedCachedUsers,
    signedInUser,
    calendarSmall?.view,
    calendarSmall?.fellowReportees,
  ]);

  const structuredData = useMemo(() => {
    let eventData: MappedEventsProps[] = [];
    weekDaysFromMonToSun.map((d, idx) => {
      const currentDate = new LocalDate(addDays(monday, idx)).getDate();
      return getCategorizedData(eventData, filteredData, currentDate);
    });
    return eventData ?? [];
  }, [monday, weekDaysFromMonToSun, filteredData]);

  const compressedData = useMemo(() => {
    const weekRange = getWeekRange();
    const firstDayOfTheWeek = new LocalDate(weekRange[0]).getDate();
    const todayAndFutureEvents = getCurrentDateAndFutureEvents(structuredData, firstDayOfTheWeek);
    return groupBy(todayAndFutureEvents, 'type');
  }, [structuredData]);

  return (
    <WidgetLayout readOnly={readOnly} size="small">
      <Fragment>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
          <Box
            sx={{
              width: '100%',
              '&:hover': { background: themeColors.transparency },
              borderRadius: radius.br10,
              cursor: 'pointer',
            }}
            onClick={() => routerHistory.push(TIME_ME_CALENDAR_ROUTE)}
          >
            <div style={{ padding: spacing.p5 }}>
              <Typography variant="title2">{polyglot.t('UserCalendarVersionSmall.calendar')}</Typography>
            </div>
          </Box>
        </Box>

        {Object.keys(compressedData).length > 0 ? (
          <Box>
            {[...Object.keys(compressedData)].slice(0, 4).map((key) => {
              const userIds = [...compressedData[key]].map((u) => u.userId);
              return (
                <Box
                  key={`${key}-ucs`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: spacing.p5,
                    paddingY: spacing.p3,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {key}
                  </Typography>
                  <MultiUserAvatar userIds={userIds} showLimit={2} avatarSize="xxxsmall" textVariant="caption" />
                </Box>
              );
            })}

            {Object.keys(compressedData).length > 4 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mt: spacing.m5,
                }}
              >
                <a
                  href={TIME_ME_CALENDAR_ROUTE}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: themeColors.Grey, textDecoration: 'none', ...themeFonts.captionSmall }}
                >
                  {polyglot.t('UserCalendarVersionSmall.viewMore', { count: Object.keys(compressedData).length - 4 })}
                </a>
              </Box>
            )}
          </Box>
        ) : (
          <UserCalendarEmptyState />
        )}
      </Fragment>
    </WidgetLayout>
  );
};
