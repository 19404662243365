import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { FixedSizeList } from 'react-window';

import { CompanyDepartmentAPI, SiteAPI } from '@/api-client/index.api';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { filterStringToObject } from '@/v2/feature/user/user.util';
import { spacing } from '@/v2/styles/spacing.styles';

// TODO: @polyglot-later
export const SpecificUserModal = ({
  isSpecificOpen,
  setSpecificIsOpen,
  label,
  value,
  onChange,
  filterValue,
  setLastValidFilterValue,
  onClose,
  userOptions,
  allowEmpty = false,
  excludeUsers,
}: {
  isSpecificOpen: boolean;
  setSpecificIsOpen: Dispatch<SetStateAction<boolean>>;
  label?: string;
  value?: number[];
  onChange: (userIds: number[], filterValue: UserSelectFiltersOptions, customRule?: string) => void;
  filterValue: UserSelectFiltersOptions;
  setLastValidFilterValue: React.Dispatch<React.SetStateAction<UserSelectFiltersOptions>>;
  onClose?: () => void;
  userOptions?: {
    readonly value: number;
    readonly label: string;
  }[];
  allowEmpty?: boolean;
  excludeUsers?: number[];
}) => {
  const { polyglot } = usePolyglot();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');
  const [selectedUserIds, setSelectedUserIds] = useState<Set<number>>(new Set(value));

  const userDetails = useMemo(() => {
    const exclude = excludeUsers ? new Set(excludeUsers) : null;

    if (!userOptions) {
      if (!exclude) return nonTerminatedCachedUsers;

      return nonTerminatedCachedUsers.filter((u) => !exclude.has(u.userId));
    }

    const userIds = new Set(userOptions.map(({ value }) => value));
    const nonTerminatedUsers =
      nonTerminatedCachedUsers.length > userOptions.length
        ? nonTerminatedCachedUsers.filter((u) => userIds.has(u.userId))
        : nonTerminatedCachedUsers;
    if (exclude) return nonTerminatedUsers.filter((u) => !exclude.has(u.userId));

    return nonTerminatedUsers;
  }, [nonTerminatedCachedUsers, userOptions, excludeUsers]);

  const filteredUsers = useMemo(() => {
    let filteredUserList = userDetails;
    if (searchQuery) {
      filteredUserList = filteredUserList.filter((u) =>
        u.displayName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      for (const key of Object.keys(filterOptions)) {
        switch (key) {
          case 'Department': {
            filteredUserList = filteredUserList.filter(
              (user) => user.role?.department && filterOptions[key].includes(user.role.department.id?.toString())
            );

            break;
          }
          case 'Site': {
            filteredUserList = filteredUserList.filter(
              (user) => user.role?.site && filterOptions[key].includes(user.role.site.id?.toString())
            );

            break;
          }

          default:
            break;
        }
      }
    }

    return filteredUserList;
  }, [filterString, searchQuery, userDetails]);

  const [departments, setDepartments] = useState<
    {
      readonly label: string;
      readonly value: number;
    }[]
  >([]);

  const [sites, setSites] = useState<
    {
      readonly label: string;
      readonly value: number;
    }[]
  >([]);

  const getFilters = useCallback(async () => {
    const [sitesData, departmentsData] = await Promise.all([
      SiteAPI.listSites(true),
      CompanyDepartmentAPI.getCompanyDepartments(),
    ]);

    setDepartments(
      departmentsData.map((d) => ({
        value: d.id,
        label: polyglot.t(d.name),
      }))
    );

    setSites(
      sitesData.map((s) => ({
        value: s.id,
        label: polyglot.t(s.name),
      }))
    );
  }, [polyglot]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const FilterTypes = {
    Department: departments,
    Site: sites,
  };

  const getFilteredUser = () => {
    onChange([...selectedUserIds], filterValue);
    setSpecificIsOpen(false);
    setLastValidFilterValue(filterValue);
  };

  return (
    <DrawerModal isOpen={isSpecificOpen} setIsOpen={setSpecificIsOpen} onClose={onClose}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{label ?? polyglot.t('General.users')}</Typography>

        <CategoryFilters filterTypes={FilterTypes} setFilterString={setFilterString} filterString={filterString} />

        <Box sx={{ marginTop: spacing.m10 }}>
          <TableSearch
            query={searchQuery}
            handleChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            style={{ width: '90%' }}
          />
        </Box>

        <CheckboxComponent
          labelPlacement="start"
          label={
            <Typography variant="caption" color="Grey">
              Select all
            </Typography>
          }
          checked={selectedUserIds.size > 0 && selectedUserIds.size === filteredUsers.length}
          disabled={filteredUsers.length === 0}
          onChange={(_, checked) => {
            if (checked) {
              setSelectedUserIds(new Set(filteredUsers.map(({ userId }) => userId)));
            } else {
              setSelectedUserIds(new Set());
            }
          }}
          checkboxSx={{ ml: 'auto' }}
          sx={{ width: '100%' }}
        />

        <Box
          sx={{
            maxHeight: 'calc(100vh - 350px)',
            overflowY: 'scroll',
            gap: spacing.g10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FixedSizeList height={window.innerHeight - 400} itemCount={filteredUsers.length} itemSize={35} width="100%">
            {({ index, style }) => {
              const user = filteredUsers[index];
              const isChecked = selectedUserIds.has(user.userId);
              return (
                <div style={style} key={user.userId}>
                  <CheckboxComponent
                    label={<UserCell userId={user.userId} key={user.userId} />}
                    labelPlacement="start"
                    checked={isChecked}
                    onChange={(_, checked) => {
                      const newSelectedUserIds = new Set(selectedUserIds);
                      if (checked) {
                        newSelectedUserIds.add(user.userId);
                      } else {
                        newSelectedUserIds.delete(user.userId);
                      }
                      setSelectedUserIds(newSelectedUserIds);
                    }}
                    checkboxSx={{ ml: 'auto' }}
                    sx={{ width: '100%' }}
                  />
                </div>
              );
            }}
          </FixedSizeList>
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
            onClick={() => getFilteredUser()}
            disabled={allowEmpty ? false : selectedUserIds.size === 0}
          >
            Done
          </ButtonComponent>
        </Box>
      </Box>
    </DrawerModal>
  );
};
