import axios from 'axios';

import {
  SurveySectionBank,
  SurveySectionBankCreate,
  SurveySectionBankUpdate,
} from '@/v2/feature/growth/surveys/interfaces/survey-section-bank.interface';

export class SurveySectionBankAPI {
  static async createSurveySectionInBank(sectionBody: SurveySectionBankCreate): Promise<SurveySectionBank> {
    return (await axios.post('/apiv2/survey-section-bank', sectionBody)).data;
  }

  static async updateSurveySectionInBank(sectionBody: SurveySectionBankUpdate): Promise<void> {
    await axios.patch('/apiv2/survey-section-bank', sectionBody);
  }

  static async deleteSectionById(templateId: string, sectionId: string): Promise<void> {
    await axios.delete(`/apiv2/survey-section-bank/${templateId}/${sectionId}`);
  }
}
