import React, { useEffect, useState } from 'react';
import { FILE_UPLOAD_ENDPOINT } from '@/api-client/routes';
import useMessage from '@/hooks/notification.hook';
import { CustomPreview } from '@/v2/components/custom-preview.component';
import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';
import { borders } from '@/v2/styles/borders.styles';
import { neutralColors, themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { Box, SxProps, Theme } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Dropzone, { IFileWithMeta, IUploadParams } from 'react-dropzone-uploader';
import Resizer from 'react-image-file-resizer';
import 'react-dropzone-uploader/dist/styles.css';
import './UploadInput.css';

interface UploadInputProps<T> {
  readonly label?: string;
  readonly maxFiles?: number;
  readonly maxSizeBytes?: number;
  readonly value?: File;
  readonly onChange: (response?: T, file?: File, status?: string) => void;
  readonly endpoint?: string;
  readonly cardStyle?: SxProps<Theme>;
  readonly icon?: React.JSX.Element;
  readonly disabled?: boolean;
  readonly skipUpload?: boolean;
  readonly displayText?: string;
  readonly displayTextColor?: keyof typeof themeColors;
  readonly displayTextVariant?: TypographyVariant;
  readonly fileOwner?: number;
  sx?: SxProps<Theme>;
}

export function UploadInput<T>({
  maxFiles = 1,
  maxSizeBytes = 10485760, // 10MB
  value,
  onChange,
  endpoint = FILE_UPLOAD_ENDPOINT,
  disabled = false,
  skipUpload,
  displayText,
  displayTextColor = 'DarkGrey',
  displayTextVariant = 'caption',
  fileOwner,
  sx,
}: UploadInputProps<T>): React.JSX.Element {
  const { polyglot } = usePolyglot();

  const [resetKey, setResetKey] = useState(1);
  const [drag, setDrag] = useState(false);
  const [showMessage] = useMessage();

  const resizeImage = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // max width
        800, // max height (maintains aspect ratio)
        'JPEG', // format
        100, // quality
        0, // rotation
        async (uri) => {
          // Convert the resized image back to a File
          if (typeof uri === 'string') {
            const response = await fetch(uri);
            const blob = await response.blob();
            const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
            resolve(resizedFile);
          } else {
            resolve(uri as File);
          }
        },
        'base64',
        500 // max size in KB
      );
    });

  const preprocessFile = async (fileWithMeta: IFileWithMeta): Promise<IFileWithMeta> => {
    const file = fileWithMeta.file;
    if (file.type.startsWith('image/')) {
      const resizedFile = await resizeImage(file);
      fileWithMeta.meta = { ...fileWithMeta.meta, name: resizedFile.name };
      fileWithMeta.file = resizedFile;
    }
    return fileWithMeta;
  };

  const handleChangeStatus = (file: IFileWithMeta, status: string, allFiles: IFileWithMeta[]) => {
    if (status === 'done') {
      onChange(file.xhr?.response ? JSON.parse(file.xhr?.response) : '', file.file, status);
      setDrag(false);
    } else if (status === 'removed') {
      onChange(undefined, undefined, status);
      setDrag(false);
    } else if (status === 'error_file_size') {
      onChange(undefined, undefined, status);
      setResetKey(resetKey + 1);
      setDrag(false);
      showMessage('File cannot be bigger than 10MB; please try uploading a smaller file.', 'error');
    } else {
      onChange(undefined, file?.file, status);
    }
  };

  useEffect(() => {
    if (!value) {
      setResetKey(resetKey + 1);
    }
    // TODO: this is hotfix!
    // This is to reset the file from Dropzone after submitting, eslint complains
    // eslint-disable-next-line
  }, [value]);

  const getUploadParams = async (fileWithMeta: IFileWithMeta): Promise<IUploadParams> => {
    const preprocessedFileWithMeta = await preprocessFile(fileWithMeta);
    const url = endpoint;
    const params = { url, headers: {}, file: preprocessedFileWithMeta.file };
    if (fileOwner) params.headers = { fileOwner };
    return params;
  };

  return (
    <Dropzone
      key={resetKey}
      getUploadParams={skipUpload ? undefined : getUploadParams}
      onChangeStatus={handleChangeStatus}
      PreviewComponent={(props) => <CustomPreview {...props} />}
      canCancel={false}
      maxFiles={maxFiles}
      maxSizeBytes={maxSizeBytes}
      styles={{
        dropzone: {
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
          minHeight: '45px',
        },
        input: { border: 'none', boxShadow: 'none', backgroundColor: neutralColors.n0 },
        inputLabel: { width: 'inherit', position: 'inherit', backgroundColor: neutralColors.n0 },
      }}
      inputContent={
        <Box
          key="input-content"
          sx={{
            alignItems: 'center',
            minHeight: '45px',
            cursor: disabled ? 'not-allowed' : 'pointer',
            width: '100%',
            mt: '5px',
            ...sx,
          }}
        >
          <Box
            key="drag"
            sx={{
              pointerEvents: disabled ? 'inherit' : 'none',
              opacity: disabled ? '0.4' : '1',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '20px',
            }}
          >
            <Box
              key={1}
              sx={{
                width: '100%',
                height: '36px',
                border: drag ? borders.dashHover : borders.dash,
                borderRadius: radius.br10,
                color: themeColors.DarkGrey,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: disabled ? 'inherit' : 'none',
                opacity: disabled ? '0.4' : '1',
              }}
              onDragEnter={() => {
                setDrag(true);
              }}
              onDragLeave={() => {
                if (!value) {
                  setDrag(false);
                }
              }}
            >
              <Typography variant="title4">{polyglot.t('UploadInput.drop')}</Typography>
            </Box>
            {!drag && (
              <Box key={2}>
                <Typography variant="title4">{polyglot.t('UploadInput.or')}</Typography>
              </Box>
            )}
            {!drag && (
              <Box
                key={3}
                sx={{
                  width: '100%',
                  height: '40px',
                  borderRadius: radius.br10,
                  backgroundColor: themeColors.Background,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: themeColors.TableHover,
                  },
                }}
              >
                <Typography variant="title4">{polyglot.t('UploadInput.click')}</Typography>
              </Box>
            )}
          </Box>

          {displayText && (
            <Typography variant={displayTextVariant} color={displayTextColor} sx={{ marginTop: '10px' }}>
              {displayText}
            </Typography>
          )}
        </Box>
      }
    />
  );
}
