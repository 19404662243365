import { Dispatch, SetStateAction, useState } from 'react';

import { Autocomplete, Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceModelDto } from '@v2/feature/device/device.dto';
import {
  DeviceOSValueLabelOptions,
  DeviceTypesValueLabelOptions,
  ManufacturerOptions,
} from '@v2/feature/device/device.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { StyledTextfield } from '@v2/styles/textfield.styles';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import MaterialModal from '@/component/widgets/ModalV2';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { spacing } from '@/v2/styles/spacing.styles';

interface SuperAdminDeviceModelDrawerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly deviceModel: DeviceModelDto | null;
  readonly closeDrawer: () => void;
  readonly refresh: () => void;
}

export const SuperAdminDeviceModelDrawer = ({
  isOpen,
  setIsOpen,
  deviceModel,
  closeDrawer,
  refresh,
}: SuperAdminDeviceModelDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <SuperAdminDeviceModelDrawerContent deviceModel={deviceModel} closeDrawer={closeDrawer} refresh={refresh} />
  </DrawerModal>
);

interface SuperAdminDeviceModelDrawerContentProps {
  readonly deviceModel: DeviceModelDto | null;
  readonly closeDrawer: () => void;
  readonly refresh: () => void;
}

interface DeviceModelFormData extends Partial<Omit<DeviceModelDto, 'id' | 'isAvailable'>> {
  isAvailable: 'Yes' | 'No';
}

export const SuperAdminDeviceModelDrawerContent = ({
  deviceModel,
  closeDrawer,
  refresh,
}: SuperAdminDeviceModelDrawerContentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const initialValues: DeviceModelFormData = {
    type: deviceModel?.type,
    modelName: deviceModel?.modelName,
    modelNumber: deviceModel?.modelNumber,
    modelVersion: deviceModel?.modelVersion,
    screenSize: deviceModel?.screenSize,
    os: deviceModel?.os ?? 'none',
    manufacturer: deviceModel?.manufacturer,
    cpuCores: deviceModel?.cpuCores,
    gpuCores: deviceModel?.gpuCores,
    ram: deviceModel?.ram,
    storage: deviceModel?.storage,
    price24: deviceModel?.price24,
    price36: deviceModel?.price36,
    priceEu24: deviceModel?.priceEu24,
    priceEu36: deviceModel?.priceEu36,
    fullPrice: deviceModel?.fullPrice,
    fullPriceEu: deviceModel?.fullPriceEu,
    deliveryDays: deviceModel?.deliveryDays ?? 7,
    deliveryDaysEu: deviceModel?.deliveryDaysEu ?? 7,
    isAvailable: deviceModel ? (deviceModel.isAvailable ? 'Yes' : 'No') : 'Yes',
  };

  const validationSchema = yup.object({
    type: yup.string().required('You must specify a value.'),
    modelName: yup.string().required('You must specify a value.'),
    modelNumber: yup.string().nullable().notRequired(),
    modelVersion: yup.string().nullable().notRequired(),
    manufacturer: yup.string().nullable().notRequired(),
    os: yup.string().required('You must specify a value.'),
    screenSize: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    cpuCores: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    gpuCores: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    ram: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    storage: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    price24: yup.number().typeError('You must specify a number.').required('You must specify a value.'),
    price36: yup.number().typeError('You must specify a number.').required('You must specify a value.'),
    priceEu24: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    priceEu36: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    fullPrice: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    fullPriceEu: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    deliveryDays: yup.number().typeError('You must specify a number.').required('You must specify a value.'),
    deliveryDaysEu: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    isAvailable: yup.string().oneOf(['Yes', 'No']).nullable().required('Must specify if device is available'),
  });

  const getFormData = (data: DeviceModelFormData): Omit<DeviceModelDto, 'id'> | undefined => {
    if (!data.type || !data.modelName || !data.os || !data.price36 || !data.price24 || !data.deliveryDays) {
      return undefined;
    }

    return {
      type: data.type,
      modelName: data.modelName,
      modelNumber: data.modelNumber ?? null,
      modelVersion: data.modelVersion ?? null,
      screenSize: data.screenSize ? Number(data.screenSize) : null,
      manufacturer: data.manufacturer ?? null,
      os: data.os,
      cpuCores: data.cpuCores ? Number(data.cpuCores) : null,
      gpuCores: data.gpuCores ? Number(data.gpuCores) : null,
      ram: data.ram ? Number(data.ram) : null,
      storage: data.storage ? Number(data.storage) : null,
      price24: Number(data.price24),
      price36: Number(data.price36),
      priceEu24: data.priceEu24 ? Number(data.priceEu24) : null,
      priceEu36: data.priceEu36 ? Number(data.priceEu36) : null,
      fullPrice: data.fullPrice ? Number(data.fullPrice) : null,
      fullPriceEu: data.fullPriceEu ? Number(data.fullPriceEu) : null,
      deliveryDays: Number(data.deliveryDays),
      deliveryDaysEu: data.deliveryDaysEu ? Number(data.deliveryDaysEu) : null,
      isAvailable: data.isAvailable === 'Yes',
    };
  };

  const updateDeviceModel = async (modelUpdate: DeviceModelFormData) => {
    const formData = getFormData(modelUpdate);
    if (!formData) {
      showMessage('Invalid data provided.', 'error');
      return;
    }

    const update = {
      ...formData,
      os: formData.os && formData.os !== 'none' ? formData.os : null,
    };
    try {
      setLoading(true);
      await DeviceAPI.updateDeviceModel(deviceModel!.id, update);
      showMessage('Device model successfully updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const addDeviceModel = async (deviceModel: DeviceModelFormData) => {
    const formData = getFormData(deviceModel);
    if (!formData) {
      showMessage('Invalid data provided.', 'error');
      return;
    }

    try {
      setLoading(true);
      await DeviceAPI.addDeviceModel(formData);
      showMessage('Device model successfully updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<DeviceModelFormData>({
    initialValues,
    validationSchema,
    onSubmit: async (values: DeviceModelFormData) => {
      deviceModel ? await updateDeviceModel(values) : await addDeviceModel(values);
      closeDrawer();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Box component="form" autoComplete="off" sx={drawerContentSx}>
        <Typography variant="title2">{deviceModel ? deviceModel.modelName : 'Add device model'}</Typography>
        <SelectComponent
          name="isAvailable"
          label="Available in Devices Store"
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ]}
          value={formik.values.isAvailable}
          onChange={formik.handleChange}
          compareValue={formik.values.isAvailable}
          error={!!formik.errors.isAvailable && formik.touched.isAvailable}
          helperText={formik.touched.isAvailable && formik.errors.isAvailable}
          required={true}
        />
        <SelectComponent
          name="type"
          label="Type"
          options={DeviceTypesValueLabelOptions}
          value={formik.values.type}
          onChange={formik.handleChange}
          compareValue={formik.values.type}
          error={!!formik.errors.type && formik.touched.type}
          helperText={formik.touched.type && formik.errors.type}
          required={true}
        />
        <TextfieldComponent
          label="Model Name"
          name="modelName"
          value={formik.values.modelName}
          onChange={formik.handleChange}
          error={formik.touched.modelName && Boolean(formik.errors.modelName)}
          helperText={formik.touched.modelName && formik.errors.modelName}
          fullWidth
          size="small"
          endAdornment="none"
          required={true}
        />

        <TextfieldComponent
          label="Model Number"
          name="modelNumber"
          value={formik.values.modelNumber}
          onChange={formik.handleChange}
          error={formik.touched.modelNumber && Boolean(formik.errors.modelNumber)}
          helperText={formik.touched.modelNumber && formik.errors.modelNumber}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Model Version"
          name="modelVersion"
          value={formik.values.modelVersion}
          onChange={formik.handleChange}
          error={formik.touched.modelVersion && Boolean(formik.errors.modelVersion)}
          helperText={formik.touched.modelVersion && formik.errors.modelVersion}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <Autocomplete
          id="manufacturer"
          freeSolo
          options={ManufacturerOptions}
          sx={{ width: '100%' }}
          onChange={(event) => {
            // eslint-disable-next-line
            const target = (event.target as unknown) as { textContent: string };
            formik.setFieldValue('manufacturer', target.textContent);
          }}
          value={formik.values.manufacturer}
          renderInput={(params) => (
            <StyledTextfield
              {...params}
              variant="standard"
              name="manufacturer"
              label="Manufacturer"
              value={formik.values.manufacturer ?? ''}
              onChange={formik.handleChange}
              fullWidth
              size="small"
              error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
              helperText={formik.touched.manufacturer && formik.errors.manufacturer}
            />
          )}
        />

        <SelectComponent
          name="os"
          label="OS"
          options={[{ label: 'None', value: 'none' }, ...DeviceOSValueLabelOptions]}
          value={formik.values.os ?? undefined}
          compareValue={formik.values.os ?? undefined}
          error={!!formik.errors.os && formik.touched.os}
          onChange={formik.handleChange}
          helperText={formik.touched.os && formik.errors.os}
          required={true}
        />

        <TextfieldComponent
          label="RAM (GB)"
          name="ram"
          value={formik.values.ram}
          onChange={formik.handleChange}
          error={formik.touched.ram && !!formik.errors.ram}
          helperText={formik.touched.ram && formik.errors.ram}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="CPU Cores"
          name="cpuCores"
          value={formik.values.cpuCores}
          onChange={formik.handleChange}
          error={formik.touched.cpuCores && Boolean(formik.errors.cpuCores)}
          helperText={formik.touched.cpuCores && formik.errors.cpuCores}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="GPU Cores"
          name="gpuCores"
          value={formik.values.gpuCores}
          onChange={formik.handleChange}
          error={formik.touched.gpuCores && Boolean(formik.errors.gpuCores)}
          helperText={formik.touched.gpuCores && formik.errors.gpuCores}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Storage (GB)"
          name="storage"
          value={formik.values.storage}
          onChange={formik.handleChange}
          error={formik.touched.storage && Boolean(formik.errors.storage)}
          helperText={formik.touched.storage && formik.errors.storage}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Screen Size (inch)"
          name="screenSize"
          value={formik.values.screenSize}
          onChange={formik.handleChange}
          error={formik.touched.screenSize && Boolean(formik.errors.screenSize)}
          helperText={formik.touched.screenSize && formik.errors.screenSize}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Price £ (36 months)"
          name="price36"
          value={formik.values.price36}
          onChange={formik.handleChange}
          error={formik.touched.price36 && Boolean(formik.errors.price36)}
          helperText={formik.touched.price36 && formik.errors.price36}
          fullWidth
          size="small"
          endAdornment="none"
          required
        />

        <TextfieldComponent
          label="Price £ (24 months)"
          name="price24"
          value={formik.values.price24}
          onChange={formik.handleChange}
          error={formik.touched.price24 && Boolean(formik.errors.price24)}
          helperText={formik.touched.price24 && formik.errors.price24}
          fullWidth
          size="small"
          endAdornment="none"
          required
        />

        <TextfieldComponent
          label="Price EU £ (36 months)"
          name="priceEu36"
          value={formik.values.priceEu36}
          onChange={formik.handleChange}
          error={formik.touched.priceEu36 && Boolean(formik.errors.priceEu36)}
          helperText={formik.touched.priceEu36 && formik.errors.priceEu36}
          fullWidth
          size="small"
          endAdornment="none"
          required
        />

        <TextfieldComponent
          label="Price EU £ (24 months)"
          name="priceEu24"
          value={formik.values.priceEu24}
          onChange={formik.handleChange}
          error={formik.touched.priceEu24 && Boolean(formik.errors.priceEu24)}
          helperText={formik.touched.priceEu24 && formik.errors.priceEu24}
          fullWidth
          size="small"
          endAdornment="none"
          required
        />

        <TextfieldComponent
          label="Full price"
          name="fullPrice"
          value={formik.values.fullPrice}
          onChange={formik.handleChange}
          error={formik.touched.fullPrice && Boolean(formik.errors.fullPrice)}
          helperText={formik.touched.fullPrice && formik.errors.fullPrice}
          fullWidth
          size="small"
          endAdornment="none"
          required
        />

        <TextfieldComponent
          label="Full price EU"
          name="fullPriceEu"
          value={formik.values.fullPriceEu}
          onChange={formik.handleChange}
          error={formik.touched.fullPriceEu && Boolean(formik.errors.fullPriceEu)}
          helperText={formik.touched.fullPriceEu && formik.errors.fullPriceEu}
          fullWidth
          size="small"
          endAdornment="none"
          required
        />

        <TextfieldComponent
          label="Delivery Days (UK)"
          name="deliveryDays"
          value={formik.values.deliveryDays}
          onChange={formik.handleChange}
          error={formik.touched.deliveryDays && Boolean(formik.errors.deliveryDays)}
          helperText={formik.touched.deliveryDays && formik.errors.deliveryDays}
          fullWidth
          size="small"
          endAdornment="none"
          required={true}
        />

        <TextfieldComponent
          label="Delivery Days (EU)"
          name="deliveryDaysEu"
          value={formik.values.deliveryDaysEu}
          onChange={formik.handleChange}
          error={formik.touched.deliveryDaysEu && Boolean(formik.errors.deliveryDaysEu)}
          helperText={formik.touched.deliveryDaysEu && formik.errors.deliveryDaysEu}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          {deviceModel?.id && (
            <ButtonComponent colorVariant="danger" sizeVariant="medium" onClick={() => setIsDeleteModalOpen(true)}>
              Delete
            </ButtonComponent>
          )}

          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name="Save"
            loading={loading}
            onClick={() => formik.handleSubmit()}
            fullWidth
          />
        </Box>
      </Box>

      {deviceModel?.id && (
        <MaterialModal
          title="Are you sure you want to delete this device model?"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          actionButton={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                gap: spacing.g20,
                alignItems: 'center',
              }}
            >
              <ButtonComponent colorVariant="secondary" sizeVariant="small" onClick={() => setIsDeleteModalOpen(false)}>
                Close
              </ButtonComponent>
              <ButtonComponent
                sizeVariant="small"
                colorVariant="danger"
                onClick={async () => {
                  try {
                    await DeviceAPI.deleteDeviceModel(deviceModel.id);
                    showMessage('Device successfully deleted.', 'success');
                    await refresh();
                    closeDrawer();
                    setIsDeleteModalOpen(false);
                  } catch (error) {
                    showMessage(`${nestErrorMessage(error)}`, 'error');
                  }
                }}
              >
                Delete
              </ButtonComponent>
            </Box>
          }
        >
          <Typography variant="caption">Warning: This action is irreversible!</Typography>
        </MaterialModal>
      )}
    </FormikProvider>
  );
};
