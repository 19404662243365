import React from 'react';

import { ButtonProps, IconButton } from '@mui/material';

import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { themeColors } from '@/v2/styles/colors.styles';

type ClearIconButtonProps = ButtonProps;

const iconSize = { width: '14px', height: '14px' } as const;

export const ClearIconButton = (props: ClearIconButtonProps) => {
  return (
    <IconButton {...props}>
      <ClearIcon {...iconSize} style={{ fill: themeColors.middleGrey }} />
    </IconButton>
  );
};
