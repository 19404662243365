import { CurrentUser, Scope, ScopeContext, ScopeRoot } from '@/models';
import { ParsedScope, ScopeActionEnum, ScopeFilterEnum } from '@/models/scopes.model';

/**
 * Checks whether a specific scope is an umbrella superset of another one and therefore
 * has equal or more access than it
 * @param scope tentatively subset of `maybeSupersetScope`
 * @param maybeSupersetScope scope to check whether it's a superset or not
 * @returns whether maybeSupersetScope has equal or more access than scope
 */
// TODO: if has scopes will get removed, this won't be needed anymore and can be deleted
export function isSupersetScope(scope: ParsedScope, maybeSupersetScope: ParsedScope): boolean {
  if (maybeSupersetScope.entity !== scope.entity) {
    return false;
  }
  if (maybeSupersetScope.action !== ScopeActionEnum.ReadWrite && maybeSupersetScope.action !== scope.action) {
    return false;
  }
  switch (scope.filter) {
    case ScopeFilterEnum.All: {
      return maybeSupersetScope.filter === ScopeFilterEnum.All;
    }
    case ScopeFilterEnum.Manager: {
      return maybeSupersetScope.filter === ScopeFilterEnum.All || maybeSupersetScope.filter === ScopeFilterEnum.Manager;
    }
    default: {
      return true;
    }
  }
}

/**
 * Verify that the user has permissions to all scopes (or higher) in the list
 * Doesn't use context information and simply checks for raw scope values
 * @todo rework this logic, the user scope set could be assembled once during user loading
 * @param user CurrentUser with scope credentials
 * @param scopes list of scopes to comply to
 * @returns whether the user complies to the list of scopes
 */
export function hasScopes(user: Pick<CurrentUser, 'scopes2'>, scopes: readonly Scope[]): boolean {
  const parsedUserScopes = (user.scopes2?.map((s) => s.scope) ?? []).map(parseScope);
  const parsedRequiredScopes = scopes.map(parseScope);
  return parsedRequiredScopes.every((rs) => parsedUserScopes.some((us) => isSupersetScope(rs, us)));
}

/**
 * Check that a user has (contextless) access to the scopes described, accounting for special {Manager} filter rules.
 * Ideally should ONLY be used when checking for scopes presence WITHOUT importance to inclusions / exclusion lists
 * Generally fine to be used in routers, as we are looking for the presence of a scope rather than a context specific action
 * @param user CurrentUser with scope credentials
 * @param scopes list of scopes to comply to
 * @returns whether the user complies to the list of scopes
 */
export function canAccessScopes(user: Pick<CurrentUser, 'scopes2' | 'reports'>, scopes: readonly Scope[]): boolean {
  const hasReports = user.reports && user.reports.length > 0;
  const userScopes = user.scopes2;

  for (const scope of scopes) {
    const parsedScope = parseScope(scope);
    switch (parsedScope.filter) {
      case ScopeFilterEnum.Owner: {
        // check if user has a scopes with the same entity as scope in the user scopes list
        if (userScopes.some((userScope) => parseScope(userScope.scope).entity === parsedScope.entity)) return true;
        break;
      }
      case ScopeFilterEnum.Manager: {
        // check if user has reports and scope:manager or if user has scope:all in the user scopes list
        if (
          userScopes.some((userScope) => {
            const parsedUserScope = parseScope(userScope.scope);
            return (
              parsedUserScope.entity === parsedScope.entity &&
              ((hasReports && parsedUserScope.filter === ScopeFilterEnum.Manager) ||
                ScopeFilterEnum.All === parsedUserScope.filter)
            );
          })
        )
          return true;
        break;
      }
      case ScopeFilterEnum.All: {
        // if user has the Admin scope in user scopes list return true
        if (
          userScopes.some((userScope) => {
            const parsedUserScope = parseScope(userScope.scope);
            return parsedUserScope.entity === parsedScope.entity && parsedUserScope.filter === ScopeFilterEnum.All;
          })
        )
          return true;
        break;
      }
      default:
        return false;
    }
  }
  return false;
}

export function checkScopes(
  user: CurrentUser,
  scopes: readonly Scope[] = [],
  context?: ScopeContext,
  matchExactAction = false
): boolean {
  if (scopes.length === 0) {
    return true;
  } else if (!user.scopes2) {
    return false;
  } else {
    const localUser = { ...user, scopes: user.scopes2?.map((s) => s.scope) };

    // if (
    //   user.permissionGroups &&
    //   user.permissionGroups.some((pg) => pg?.inclusions?.length > 0 || pg?.exclusions?.length > 0) &&
    //   context?.userId
    // ) {
    //   const nonExcludedPermissionGroups = user.permissionGroups.filter(
    //     (pg) =>
    //       !convertUserSummariesToIds(pg.exclusions).some((excludedUserId) => excludedUserId === context.userId) &&
    //       (!pg.inclusions ||
    //         pg.inclusions.length === 0 ||
    //         convertUserSummariesToIds(pg.inclusions).some((includedUserIds) => includedUserIds === context.userId))
    //   );

    //   const nonExcludedScopes = [
    //     ...new Set(
    //       nonExcludedPermissionGroups.flatMap((group) => group.permissions.map((permission) => permission.scope))
    //     ),
    //   ];

    //   localUser.scopes = nonExcludedScopes;
    // }
    if (user.scopes2 && context?.userId) {
      const nonExcludedPermissionGroups = user.scopes2.filter(
        (pg) =>
          !pg.exclusions?.includes(context.userId) &&
          (!pg.inclusions || pg.inclusions?.length === 0 || pg.inclusions?.includes(context.userId))
      );

      const nonExcludedScopes = [...new Set(nonExcludedPermissionGroups.flatMap((permission) => permission.scope))];

      localUser.scopes = nonExcludedScopes;
    }

    return !!scopes?.some((scope) => checkScope(localUser, scope, context, matchExactAction));
  }
}

// should not be used directly with the new scopes2 structure - should be used in conjunction with checkScopes (above),
// so that scopes gets correctly mapped to scopes2 or checkScope on its own will fail because user.scopes will be undefined
function checkScope(user: CurrentUser, scope: Scope, context?: ScopeContext, matchExactAction = false): boolean {
  if (!user.scopes || user.scopes.length === 0) {
    return false;
  }
  const parsedScope = parseScope(scope);
  return user.scopes.some((userScope) => {
    const parsedUserScope = parseScope(userScope);
    // If it doesn't concern the same entity, not needed to go any further
    if (parsedUserScope.entity !== parsedScope.entity) {
      return false;
    }

    if (parsedUserScope.filter === ScopeFilterEnum.All) {
      return matchScopes(parsedUserScope, parsedScope, matchExactAction);
    }
    if (parsedUserScope.filter === ScopeFilterEnum.Manager) {
      return checkManagerScope(user, parsedUserScope, parsedScope, context, matchExactAction);
    }
    if (parsedUserScope.filter === ScopeFilterEnum.Owner) {
      return checkOwnerScope(user, parsedUserScope, parsedScope, context, matchExactAction);
    }

    return false;
  });
}

function checkOwnerScope(
  user: CurrentUser,
  userScope: ParsedScope,
  requiredScope: ParsedScope,
  context?: ScopeContext,
  matchExactAction = false
): boolean {
  // if (!context?.userId) throw new Error('Owner scope requires context.userId to be defined');
  const isOwner = user.userId === context?.userId;
  return (
    // TODO This check (userScope.filter === ScopeFilterEnum.All) is causing issues with how we are overloading
    // `read:all` scopes to mean non-admin access.
    matchScopes(userScope, requiredScope, matchExactAction) && (isOwner || userScope.filter === ScopeFilterEnum.All)
  );
}

function checkManagerScope(
  user: CurrentUser,
  userScope: ParsedScope,
  requiredScope: ParsedScope,
  context?: ScopeContext,
  matchExactAction = false
): boolean {
  // if (!context?.userId) throw new Error('Manager scope requires context.userId to be defined');
  const isManagerOfUser = context?.userId && user.reports?.includes(context.userId);
  return (
    matchScopes(userScope, requiredScope, matchExactAction) &&
    (isManagerOfUser || userScope.filter === ScopeFilterEnum.All)
  );
}

function matchScopes(userScope: ParsedScope, requiredScope: ParsedScope, matchExactAction = false): boolean {
  const matchEntity = userScope.entity === requiredScope.entity;
  const matchAction =
    (!matchExactAction && userScope.action === ScopeActionEnum.ReadWrite) || userScope.action === requiredScope.action;
  const matchFilter =
    userScope.filter === ScopeFilterEnum.All ||
    userScope.filter === ScopeFilterEnum.Manager ||
    userScope.filter === requiredScope.filter;
  return matchEntity && matchAction && matchFilter;
}

export function parseScope(scope: Scope): ParsedScope {
  const scopeParts = scope.split(':');
  if (!scopeParts.length) {
    throw new Error('Invalid scope');
  }

  // {entity}
  if (scopeParts.length === 1) {
    return {
      entity: scopeParts[0] as ScopeRoot,
      action: ScopeActionEnum.ReadWrite,
      filter: ScopeFilterEnum.Owner,
    };
  }

  // {entity:action} OR {entity:filter}
  if (scopeParts.length === 2) {
    const actionOrFilter = `:${scopeParts[1]}`;
    let action: ScopeActionEnum, filter: ScopeFilterEnum;
    // {entity:action}
    if (Object.values(ScopeActionEnum).includes(actionOrFilter as ScopeActionEnum)) {
      action = actionOrFilter as ScopeActionEnum;
      filter = ScopeFilterEnum.Owner;
    } else {
      // {entity:filter}
      action = ScopeActionEnum.ReadWrite;
      filter = actionOrFilter as ScopeFilterEnum;
    }
    return {
      entity: scopeParts[0] as ScopeRoot,
      action,
      filter,
    };
  }

  // {entity:action:filter}
  return {
    entity: scopeParts[0] as ScopeRoot,
    action: `:${scopeParts[1]}` as ScopeActionEnum,
    filter: `:${scopeParts[2]}` as ScopeFilterEnum,
  };
}

export function checkIsManagerOrAdmin(user: CurrentUser, scopes: Scope[]): boolean {
  const isAdmin = checkScopes(user, scopes, { userId: user.userId });
  if (isAdmin) return true;
  let isManager = false;
  if (user && user.reports.length > 0) {
    isManager = checkScopes(user, scopes, { userId: user.reports[0] });
  }

  return isManager;
}
