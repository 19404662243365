import { FormEventHandler } from 'react';

import { Box, Slide, Stack } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { AuthFooter } from '@/v2/feature/auth/components/auth-footer.component';
import { AuthLoginButton } from '@/v2/feature/auth/components/auth-login-button.component';
import { AuthThemeBg } from '@/v2/feature/auth/components/auth-theme-bg.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  title: string;
  description?: string;
  children: any;
  showLoginButton?: boolean;
  submit?: FormEventHandler<HTMLFormElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined;
};

export const AuthLayout = ({ title, description, showLoginButton, children, submit, onKeyDown }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' },
        height: '100%',
        backgroundColor: themeColors.Background,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: '55%', md: '65%', lg: '70%' },
          height: { xs: '50%', sm: '100%', md: '100%', lg: '100%' },
        }}
      >
        <AuthThemeBg />
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '45%', md: '35%', lg: '30%' },
          height: { xs: '50%', sm: '100%', md: '100%', lg: '100%' },
        }}
      >
        <Slide direction={window.innerWidth > 600 ? 'left' : 'up'} in={true} mountOnEnter timeout={{ enter: 600 }}>
          <Box
            sx={{
              boxSizing: 'border-box',
              backgroundColor: themeColors.white,
              ...spacing.pad40,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Stack sx={{ mt: { xs: '0px', sm: '0px', md: '0px', lg: '0px' }, mb: '30px' }}>
                <Typography variant="title1">{title}</Typography>
                {description && (
                  <Typography variant="caption" sx={{ mt: spacing.m10 }}>
                    {description}
                  </Typography>
                )}
              </Stack>
              <form onSubmit={submit} onKeyDown={onKeyDown}>
                {children}
              </form>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g40 }}>
              {showLoginButton && <AuthLoginButton />}
              <AuthFooter />
            </Box>
          </Box>
        </Slide>
      </Box>
    </Box>
  );
};
