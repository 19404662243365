import { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { ActivityLog } from '@v2/feature/billing/components/activity-log.component';
import { BillingInformationSection } from '@v2/feature/billing/components/billing-information-section.component';
import { BillingOverview } from '@v2/feature/billing/components/billing-overview.component';
import { FailedPaymentAlert } from '@v2/feature/billing/components/failed-payment-alert.component';
import { spacing } from '@v2/styles/spacing.styles';

import { BillingSubscriptionAPI } from '@/api-client/billing-subscription.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  CompanyBillingSubscription,
  CompanySubscriptionStats,
  SubscriptionBillingStats,
} from '@/models/subscription.model';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SubscriptionSection } from '@/v2/feature/billing/components/subscription-section.component';
import { CompanySubscriptionBillingOverview } from '@/v2/feature/billing/components/v2-company-subscription-billing-overview.component';
import { CompanySubscriptionSection } from '@/v2/feature/billing/components/v2-company-subscription-section.component';
import { DowngradeViability } from '@/v2/feature/company/company-settings/company-settings.interface';
import { CompanyDeleteComponent } from '@/v2/feature/company/company-settings/features/components/company-delete.component';
import { CompanySubscriptionDetails } from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';

enum CheckoutSessionStatus {
  'success' = 'success',
  'cancelled' = 'cancelled',
}

enum PaymentMethodSetupStatus {
  'setup_success' = 'setup_success',
  'setup_cancelled' = 'setup_cancelled',
}

const CHECKOUT_SUCCESS_MSG =
  'Charge was successful. Subscription will be activated on payment confirmation. \nThis takes a few seconds.';
const CHECKOUT_CANCELLED_MSG = 'Upgrade cancelled successfully.';
const PAYMENT_METHOD_SUCCESS_MSG =
  'Payment method is being setup. Card would reflect under billing section once completed.';
const PAYMENT_METHOD_CANCELLED_MSG = 'Payment method setup has been cancelled.';

export const BillingSettingsPage = (): JSX.Element => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [showMessage] = useMessage();

  const [subscriptions, setSubscriptions] = useState<CompanyBillingSubscription[]>([]);
  const [subscriptionStats, setSubscriptionStats] = useState<CompanySubscriptionStats | null>(null);
  const [subscriptionsBillingStats, setSubscriptionsBillingStats] = useState<SubscriptionBillingStats | null>(null);

  const [subscriptionDetailsForCompany, setSubscriptionDetailsForCompany] = useState<CompanySubscriptionDetails | null>(
    null
  );

  const [loading, setLoading] = useState<boolean>(false);

  const { trackPage } = useJune();

  const { hasScopes } = useScopes();
  const scopesContext = { userId: user.userId };
  const isAdminUser = hasScopes(['company.settings:all'], scopesContext);
  const { data: downgradeViability } = useApiClient(
    isAdminUser ? CompanyEndpoints.getDowngradeViability() : { url: undefined },
    { suspense: false }
  );
  const defaultProductDowngradeViability = { disableDowngrade: false, disabledDescription: '' };

  const defaultViability: Record<string, DowngradeViability> = {
    APPS: defaultProductDowngradeViability,
    DEVICES: defaultProductDowngradeViability,
    MONEY: defaultProductDowngradeViability,
    PEOPLE: defaultProductDowngradeViability,
  };

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const [
        companyBillingSubscriptions,
        subscriptionStats,
        billingStats,
        companySubscriptionDetails,
      ] = await Promise.all([
        BillingSubscriptionAPI.getCompanySubscriptions(),
        BillingSubscriptionAPI.getSubscriptionStats(),
        BillingSubscriptionAPI.getSubscriptionBillingStats(),
        BillingSubscriptionAPI.getCompanySubscriptionDetails(),
      ]);
      setSubscriptionsBillingStats(billingStats);

      setSubscriptions(companyBillingSubscriptions);
      setSubscriptionStats(subscriptionStats);

      setSubscriptionDetailsForCompany(companySubscriptionDetails);

      if (getSubscriptionStatus('status') === CheckoutSessionStatus.success) {
        showMessage(CHECKOUT_SUCCESS_MSG, 'success', 'top-right', markNotificationAsRead);
      }

      if (getSubscriptionStatus('status') === CheckoutSessionStatus.cancelled) {
        showMessage(CHECKOUT_CANCELLED_MSG, 'info', 'top-right', markNotificationAsRead);
      }

      if (getSubscriptionStatus('status') === PaymentMethodSetupStatus.setup_success) {
        showMessage(PAYMENT_METHOD_SUCCESS_MSG, 'success', 'top-right', markNotificationAsRead);
      }

      if (getSubscriptionStatus('status') === PaymentMethodSetupStatus.setup_cancelled) {
        showMessage(PAYMENT_METHOD_CANCELLED_MSG, 'info', 'top-right', markNotificationAsRead);
      }
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
    setLoading(false);
  }, [showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const markNotificationAsRead = () => {
    const urlWithoutQuery = window.location.href.toString().split('?')[0];
    window.history.pushState({}, '', urlWithoutQuery);
  };

  const getSubscriptionStatus = (field: string) => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get(field);
  };

  useEffect(() => {
    trackPage('Company billing settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Billing</Typography>} />

      <ContentWrapper loading={loading} sx={{}}>
        {!subscriptionDetailsForCompany?.migratedToNewPlans ? (
          <>
            {subscriptionStats && subscriptions && (
              <Box sx={{ mb: spacing.m40 }}>
                <BillingOverview
                  subscriptionStats={subscriptionStats}
                  subscriptionsBillingStats={subscriptionsBillingStats}
                />
              </Box>
            )}

            <FailedPaymentAlert />

            {subscriptionStats && subscriptions && (
              <Box sx={{ display: 'flex', gap: spacing.g80 }}>
                <SubscriptionSection
                  subscriptions={subscriptions}
                  subscriptionsBillingStats={subscriptionsBillingStats}
                  subscriptionsStats={subscriptionStats}
                  downgradeViability={downgradeViability ?? defaultViability}
                  refresh={refresh}
                />

                <BillingInformationSection />
              </Box>
            )}
          </>
        ) : (
          <>
            {subscriptionStats && subscriptions && (
              <Box sx={{ mb: spacing.m40 }}>
                <CompanySubscriptionBillingOverview subscriptionDetailsForCompany={subscriptionDetailsForCompany} />
              </Box>
            )}

            <FailedPaymentAlert />

            {subscriptionStats && subscriptions && (
              <Box sx={{ display: 'flex', gap: spacing.g80 }}>
                <CompanySubscriptionSection
                  subscriptionDetailsForCompany={subscriptionDetailsForCompany}
                  refresh={refresh}
                />

                <BillingInformationSection />
              </Box>
            )}
          </>
        )}

        <ActivityLog subscriptionDetailsForCompany={subscriptionDetailsForCompany} />

        <CompanyDeleteComponent boxSx={{ mt: spacing.m40 }} />
      </ContentWrapper>
    </RootStyle>
  );
};
