import { UserDetailsTabsRouter } from '@/component/dashboard/userDetails/user-details-tabs.router';
import { RouteScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { USER_DETAILS_ROUTE } from '@/lib/routes';
import { Switch, useParams } from 'react-router-dom';

export const UserDetailsRouter = (): JSX.Element => {
  const params: { userId: string } = useParams();
  const userId = Number(params.userId);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  return (
    <Switch>
      <RouteScopesControl scopes={['user:read']} context={scopesContext} path={USER_DETAILS_ROUTE}>
        <UserDetailsTabsRouter />
      </RouteScopesControl>
    </Switch>
  );
};
