import { Suspense, useCallback, useEffect, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { DevicesSelectTable } from '@/v2/feature/device/components/devices-list-overview/devices-select-table.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto, DeviceTransitDto } from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

type DeviceSelectPageProps = {
  selectedDevice?: DevicePossessionDto;
  onContinueClick: (selectedDevice: DevicePossessionDto) => void;
  onCloseClick: () => void;
};

export const DeviceSelectPage = ({ selectedDevice, onContinueClick, onCloseClick }: DeviceSelectPageProps) => {
  const [inventoryPossessions, setInventoryPossessions] = useState<readonly DevicePossessionDto[]>([]);
  const [deviceTransits, setDeviceTransits] = useState<readonly DeviceTransitDto[]>([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>(selectedDevice ? [selectedDevice.deviceId] : []);

  const refreshInventoryState = useCallback(() => {
    DeviceAPI.getInInventoryDevices()
      .then((devices) =>
        devices.sort(
          (a, b) =>
            (a.device?.modelName ?? '').localeCompare(b.device?.modelName ?? '') ||
            (a.device?.serialNumber ?? '').localeCompare(b.device?.serialNumber ?? '')
        )
      )
      .then(setInventoryPossessions);
    DeviceAPI.getInTransitDevices().then(setDeviceTransits);
  }, []);

  useEffect(refreshInventoryState, [refreshInventoryState]);
  useEscapeKey(onCloseClick);

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <IconButton sx={{ ...iconButtonSx, flex: 0, position: 'absolute', right: spacing.m50 }} onClick={onCloseClick}>
        <Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
      </IconButton>
      <Stack sx={{ width: 'min(800px,90vw)', mx: 'auto', overflow: 'hidden' }}>
        <Typography sx={{ ...themeFonts.title2, mb: spacing.mb10 }}>Select from inventory</Typography>
        <Typography sx={{ ...themeFonts.caption, mb: spacing.mb20 }}>
          Make sure your new joiner has a device ready for when they start.
        </Typography>
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="83%"
              height="100%"
              sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
            />
          }
        >
          <Stack sx={{ overflowY: 'auto' }}>
            <DevicesSelectTable
              devicePossessions={inventoryPossessions}
              deviceTransits={deviceTransits}
              rowClick={(device) => setSelectedDeviceIds([device.deviceId])}
              showNotes
              refresh={() => Promise.resolve()}
              showAssignedTo
              deviceLocation="inventory"
              checkedDeviceIds={selectedDeviceIds}
              onDeviceCheckChange={(id, checked) => {
                // for now, just allow one device to be selected
                setSelectedDeviceIds(checked ? [id] : []);
              }}
            />
          </Stack>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            style={{
              marginTop: '20px',
              marginBottom: '40px',
              flexShrink: 0,
              opacity: selectedDeviceIds.length ? 1 : 0,
            }}
            disabled={!selectedDeviceIds.length}
            onClick={() => {
              const selectedDevices = inventoryPossessions.filter((d) => selectedDeviceIds.includes(d.deviceId));
              if (selectedDevices.length === 1) onContinueClick(selectedDevices[0]);
            }}
          >
            Continue
          </ButtonComponent>
        </Suspense>
      </Stack>
    </Stack>
  );
};
