import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableFilter } from '@v2/components/table/table-filter.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AccountingTransactionDto } from '@v2/feature/payments/features/accounting-transaction/accounting-transaction.dto';
import {
  AccountingTransactionStatus,
  AccountingTransactionType,
  AccountType,
} from '@v2/feature/payments/features/accounting-transaction/accounting-transaction.interface';
import { AccountingTransactionsAPI } from '@v2/feature/payments/features/accounting-transaction/accounting-transactions.api';
import { AccountingTransactionSummary } from '@v2/feature/super-admin/features/helper-dashboard/components/accounting-transactions-summary.component';
import {
  SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { filterStringToObject } from '@v2/feature/user/user.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const FILTERS = ['Step', 'System', 'Account', 'Subaccount', 'Status', 'Transaction type', 'Reconciled'];

const getSystemFromAccountingTransaction = (
  accountingTransaction: AccountingTransactionDto,
  users: readonly UserDetailsSuperAdminDto[],
  currentCompany: SuperAdminCompanyInfo | undefined
): string | null => {
  if (accountingTransaction.userId) {
    const user = users.find((user) => user.userId === accountingTransaction.userId);
    return user ? `${user.firstName} ${user.lastName}` : null;
  }
  if (accountingTransaction.companyId) {
    return currentCompany?.name ?? 'COMPANY_NAME';
  }
  return 'ZELT';
};

const getSubaccountFromAccountingTransaction = (
  accountingTransaction: AccountingTransactionDto,
  users: readonly UserDetailsSuperAdminDto[],
  currentCompany: SuperAdminCompanyInfo | undefined
): string | null => {
  if (accountingTransaction.subaccountUserId) {
    const user = users.find((user) => user.userId === accountingTransaction.subaccountUserId);
    return user ? `${user.firstName} ${user.lastName}` : null;
  }
  if (accountingTransaction.subaccountCompanyId) {
    return currentCompany?.name ?? 'COMPANY_NAME';
  }
  return !accountingTransaction.companyId ? 'ZELT' : null;
};

export const HelperAccountingTransactionsPage = ({
  users,
  company,
}: {
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly company: SuperAdminCompanyInfo | undefined;
}) => {
  const params = useParams<{ companyId: string }>();
  const companyId: number = company?.companyId ?? Number(params.companyId);

  const [searchInput, setSearchInput] = useState<string>('');
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState<string>('Status=APPROVED,PAYMENT_RECEIVED');

  const [loading, setLoading] = useState<boolean>(false);
  const [accountingTransactions, setAccountingTransactions] = useState<AccountingTransactionDto[]>([]);

  const [showMessage] = useMessage();

  const companyUsers = useMemo(() => {
    return users.filter((user) => user.company.companyId === companyId);
  }, [users, companyId]);

  const getFilterOptions = useCallback(async () => {
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case 'Step':
          filters = { ...filters, [filter]: [1, 2, 3, 4].map((key) => ({ value: key, label: key })) };
          break;
        case 'System':
          filters = {
            ...filters,
            [filter]: [
              ...new Set(
                accountingTransactions.map((transaction) => {
                  return getSystemFromAccountingTransaction(transaction, companyUsers, company);
                })
              ),
            ]
              .filter(Boolean)
              .map((system) => ({ label: system!, value: system! })),
          };
          break;
        case 'Account':
          filters = { ...filters, [filter]: Object.keys(AccountType).map((key) => ({ value: key, label: key })) };
          break;
        case 'Subaccount':
          filters = {
            ...filters,
            [filter]: [
              ...new Set(
                accountingTransactions.map((transaction) => {
                  return getSubaccountFromAccountingTransaction(transaction, companyUsers, company);
                })
              ),
            ]
              .filter(Boolean)
              .map((subaccount) => ({ value: subaccount!, label: subaccount! })),
          };
          break;
        case 'Status': {
          filters = {
            ...filters,
            [filter]: Object.keys(AccountingTransactionStatus).map((key) => ({ value: key, label: key })),
          };
          break;
        }
        case 'Transaction type': {
          filters = {
            ...filters,
            [filter]: Object.keys(AccountingTransactionType).map((key) => ({ value: key, label: key })),
          };
          break;
        }
        case 'Reconciled':
          filters = {
            ...filters,
            [filter]: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          };
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }, [accountingTransactions, companyUsers, company]);

  const getCompanyAccountingTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const accountingTransactions = await AccountingTransactionsAPI.getCompanyAccountingTransactionsAsSuperAdmin(
        companyId
      );
      setAccountingTransactions(accountingTransactions);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get users carry over. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [companyId, showMessage]);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const columns = useMemo<ColumnDef<AccountingTransactionDto, AccountingTransactionDto>[]>(
    () => [
      {
        header: () => 'Step',
        id: 'step',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.id !== 0 ? <>{original.step}</> : null;
        },
        maxSize: 10,
        minSize: 10,
      },
      {
        header: () => 'System',
        id: 'system',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;

          const account = getSystemFromAccountingTransaction(original, companyUsers, company);
          return account ? <>{account}</> : <EmptyCell />;
        },
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => 'Account',
        id: 'account',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return <>{original.accountType}</>;
        },
        maxSize: 80,
        minSize: 80,
      },
      {
        header: () => 'Subaccount',
        id: 'subaccount',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          const subaccount = getSubaccountFromAccountingTransaction(original, companyUsers, company);
          return subaccount ? <>{subaccount}</> : <EmptyCell />;
        },
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => 'Description',
        id: 'description',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return <>{original.description}</>;
        },
        maxSize: 200,
        minSize: 200,
      },
      {
        header: () => 'Status',
        id: 'status',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return <>{original.status}</>;
        },
        maxSize: 125,
        minSize: 125,
      },
      {
        header: () => 'Debit',
        id: 'debit',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return <Box sx={{ color: themeColors.green, fontWeight: 500 }}>{original.debit}</Box>;
          return original.debit !== 0 ? <>{original.debit}</> : <EmptyCell />;
        },
        maxSize: 40,
        minSize: 40,
      },
      {
        header: () => 'Credit',
        id: 'credit',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0)
            return <Box sx={{ color: themeColors.lightRed, fontWeight: 500 }}>{original.credit}</Box>;
          return original.credit !== 0 ? <>{original.credit}</> : <EmptyCell />;
        },
        maxSize: 40,
        minSize: 40,
      },
      {
        header: () => 'Amount',
        id: 'amount',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0)
            return (
              <Box
                sx={{
                  color: original.amount === 0 ? themeColors.DarkGrey : themeColors.darkRed,
                  fontWeight: original.amount === 0 ? 500 : 700,
                }}
              >
                {original.amount}
              </Box>
            );
          return original.amount !== 0 ? <>{original.amount}</> : <EmptyCell />;
        },
        maxSize: 40,
        minSize: 40,
      },
      {
        header: () => 'Transaction type',
        id: 'transactionType',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return <>{original.transactionType}</>;
        },
        maxSize: 80,
        minSize: 80,
      },
      {
        header: () => 'Reconciled',
        id: 'reconciled',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return original.reconciled ? <>{new Date(original.reconciled).toLocaleDateString()}</> : <EmptyCell />;
        },
        maxSize: 60,
        minSize: 60,
      },
    ],
    [company, companyUsers]
  );

  useEffect(() => {
    getCompanyAccountingTransactions();
  }, [getCompanyAccountingTransactions]);

  const filteredAccountingTransactions = useMemo(() => {
    let filteredRecords = [...accountingTransactions];

    if (searchInput) {
      filteredRecords = filteredRecords.filter((record) => {
        const account = getSystemFromAccountingTransaction(record, companyUsers, company);
        const subaccount = getSubaccountFromAccountingTransaction(record, companyUsers, company);

        return (
          account?.toLowerCase().includes(searchInput.toLowerCase()) ||
          subaccount?.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }

    if (filterString) {
      const filterOptions = filterStringToObject(filterString);

      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case 'Step': {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.step && filterOptions[key].includes(String(transaction.step));
              });
              break;
            }
            case 'System': {
              filteredRecords = filteredRecords.filter((transaction) => {
                const system = getSystemFromAccountingTransaction(transaction, companyUsers, company);
                return system && filterOptions[key].includes(system);
              });
              break;
            }
            case 'Account': {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.accountType && filterOptions[key].includes(transaction.accountType);
              });
              break;
            }
            case 'Subaccount': {
              filteredRecords = filteredRecords.filter((transaction) => {
                const subaccount = getSubaccountFromAccountingTransaction(transaction, companyUsers, company);
                return subaccount && filterOptions[key].includes(subaccount);
              });
              break;
            }
            case 'Status': {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.status && filterOptions[key].includes(transaction.status);
              });
              break;
            }
            case 'Transaction type': {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.transactionType && filterOptions[key].includes(transaction.transactionType);
              });
              break;
            }
            case 'Reconciled': {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.step && filterOptions[key].includes(String(Boolean(transaction.reconciled)));
              });
              break;
            }
            default:
              break;
          }
        }
      }
    }

    const sums = filteredRecords.reduce(
      (acc, record) => {
        acc.credit += record.credit;
        acc.debit += record.debit;
        acc.amount += record.amount;

        return acc;
      },
      { credit: 0, debit: 0, amount: 0 }
    );

    filteredRecords.push({
      id: 0,
      step: 0,
      companyId: 0,
      userId: 0,
      subaccountCompanyId: 0,
      subaccountUserId: 0,
      accountType: AccountType.Deposit,
      description: '',
      status: AccountingTransactionStatus.DRAFT,
      debit: sums.debit,
      credit: sums.credit,
      amount: sums.amount,
      transactionType: AccountingTransactionType.Asset,
      reconciled: null,
      invoiceId: null,
    });
    return filteredRecords;
  }, [accountingTransactions, searchInput, filterString, companyUsers, company]);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE}>
        <TopHeader
          title={
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              Helper page - Accounting transactions | {company?.name ? company.name : ''} [ {companyId} ]
            </Typography>
          }
          showBack
          backPath={generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })}
        />
        <ContentWrapper loading={loading} sx={{}}>
          <AccountingTransactionSummary
            accountingTransactions={filteredAccountingTransactions}
            companyId={companyId}
            companyName={company?.name ?? 'Company'}
            users={companyUsers}
          />

          <Box sx={{ display: 'flex', ...spacing.px40, gap: spacing.gap10 }}>
            <TableFilter filterTypes={filterTypes} setFilterString={setFilterString} filterString={filterString} />
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ mt: spacing.mt20 }}>
            <BasicTable<AccountingTransactionDto>
              rowData={filteredAccountingTransactions}
              columnData={columns}
              hidePagination
            />
          </Box>
        </ContentWrapper>
      </Route>
    </Switch>
  );
};
