import React, { useContext, useEffect, useState } from 'react';

import { Box, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeFonts } from '@v2/styles/fonts.styles';

import { HealthAPI } from '@/api-client/health.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { DASHBOARD_ROUTE } from '@/lib/routes';
import { CompanyUserHeader } from '@/v2/feature/app-layout/features/sidebar/components/company-user-header.component';
import {
  NavConfig,
  NavigationSection,
} from '@/v2/feature/app-layout/features/sidebar/components/navigation-section.component';
import { getAdminSidebarConfig } from '@/v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util';
import { neutralColors, themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const DRAWER_WIDTH = 200;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

export const SuperAdminSidebar = (): JSX.Element => {
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const [sideBarConfig, setSideBarConfig] = useState<readonly NavConfig[]>([]);
  const [serverTZ, setServerTZ] = useState<string>('UTC');

  const toggleDrawerState = (drawerState: boolean) => {
    dispatch({ type: GlobalStateActions.UPDATE_DRAWER, payload: drawerState });
  };

  useEffect(() => {
    (() => {
      if (state.drawerState) {
        toggleDrawerState(false);
      }
      const config = getAdminSidebarConfig();
      setSideBarConfig(config);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        const serverTZ = await HealthAPI.getServersTimeZone();
        setServerTZ(serverTZ);
      } catch (error) {
        setServerTZ('Could not be retrieved');
      }
    })();
  }, []);

  const renderContent = (
    <Box
      sx={{
        paddingTop: spacing.p35,
        ...spacing.px20,
        background: neutralColors.n0,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <CompanyUserHeader redirectPath={DASHBOARD_ROUTE} headerName="Backoffice" />
      {serverTZ !== 'UTC' && (
        <Box sx={{ my: spacing.m10, p: spacing.m5, border: '2px solid red', borderRadius: '10px' }}>
          <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
            <Mistake width={25} height={25} />
            <Typography sx={{ ...themeFonts.title4, color: themeColors.Red }}>WARNING!!!</Typography>
          </Box>

          <Typography sx={{ ...themeFonts.title4, color: themeColors.Red }}>Server's timezone is not UTC!</Typography>
          <Typography sx={themeFonts.title4}>Server TZ = {serverTZ}</Typography>
        </Box>
      )}
      <NavigationSection navConfig={sideBarConfig} />
    </Box>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: DRAWER_WIDTH,
        },
      }}
    >
      {/* <MHidden width="lgUp"> */}
      <Drawer
        open={state.drawerState}
        onClose={() => toggleDrawerState(false)}
        PaperProps={{
          sx: { width: DRAWER_WIDTH },
        }}
      >
        {renderContent}
      </Drawer>
      {/* </MHidden> */}
      {/* <MHidden width="lgDown"> */}
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: DRAWER_WIDTH,
            bgcolor: 'background.default',
            overflowY: 'auto',
          },
        }}
      >
        {renderContent}
      </Drawer>
      {/* </MHidden> */}
    </RootStyle>
  );
};
