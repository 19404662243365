import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { EventProps } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly kind: SpecialEvent;
  readonly setSelectedEvent: React.Dispatch<React.SetStateAction<EventProps | undefined>>;
  readonly userId: number;
  readonly setIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly eventDate: string;
  readonly activeView: 'Month' | 'Week';
}

const SpecialEvents = {
  birthday: {
    title: 'Birthday',
    color: themeColors.Orange,
  },
  'first-day': {
    title: 'First Day',
    color: themeColors.Green,
  },
  'work-anniversary': {
    title: 'Anniversary',
    color: themeColors.GreenMiddle,
  },
  'last-day': {
    title: 'Last Day',
    color: themeColors.GreyMiddle,
  },
} as const;
type SpecialEvent = keyof typeof SpecialEvents;

export const CalendarSpecialEvent = React.memo(
  ({ kind, setSelectedEvent, userId, setIsViewOpen, eventDate, activeView }: Props): JSX.Element => {
    const { polyglot } = usePolyglot();

    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', height: '40px', padding: 0, margin: 0 }}
        onClick={() => {
          setSelectedEvent({
            type: SpecialEvents[kind].title,
            startDate: eventDate,
            endDate: null,
            userId,
          });
          setIsViewOpen(true);
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: SpecialEvents[kind].color,
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
            opacity: 1,
            padding: 0,
            margin: 0,
            boxSizing: 'border-box',
            '&:hover::before': {
              cursor: 'pointer',
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              zIndex: 1,
            },
            '& > *': {
              position: 'relative',
              zIndex: 1,
            },
          }}
        >
          <Box
            sx={{
              display: activeView === 'Week' ? 'flex' : 'none',
              alignItems: 'center',
              gap: spacing.g5,
              px: spacing.p5,
            }}
          >
            <Box>
              <Typography variant="captionSmall" color="white">
                {polyglot.t('CalendarSpecialEvent.allDay')}
              </Typography>
              <Typography variant="captionSmall" color="white">
                {SpecialEvents[kind].title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);
