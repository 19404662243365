import { useCallback, useMemo } from 'react';

import { capitalize } from 'lodash';

import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { HeatMapPropertiesDto } from '@/v2/feature/analytics/analytics.dto';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';

const COLOR_RANGE = 5;
export const ChartHeatmapTable = ({
  series,
  categories,
  filter,
  loadingHeatmap,
}: {
  series: HeatMapPropertiesDto[];
  categories: string[];
  filter: string;
  loadingHeatmap: boolean;
}) => {
  const getMinMaxValues = useCallback((series: HeatMapPropertiesDto[]) => {
    if (series.length === 0) {
      return [];
    }
    const numberOfItems = series[0].data.length;
    const minMaxValues = new Array(numberOfItems);

    for (let idx = 0; idx < numberOfItems; idx++) {
      const rowData = series.map((item) => item.data[idx]).filter((item) => item !== null) as number[];
      minMaxValues[idx] = {
        min: Math.min(...rowData),
        max: Math.max(...rowData),
      };
    }

    return minMaxValues;
  }, []);

  const getMinMaxByRow = useMemo(() => {
    if (!series) return [{ min: 0, max: 100 }];
    return getMinMaxValues(series);
  }, [getMinMaxValues, series]);

  const getColorRange = useCallback(
    (value: number | null, categoryIndex: number) => {
      const { min, max } = getMinMaxByRow[categoryIndex];
      const stepSize = Math.round((max - min) / COLOR_RANGE);
      const thresholds = [min, ...[1, 2, 3, 4].map((factor) => min + factor * stepSize)];
      const colors = ['#F5ABA3', '#FCEFED', '#F0FAF2', '#B3E5C0', '#74CF8C'];

      if (value === null) return themeColors.Background;
      let colorIndex = thresholds.findIndex((threshold, index) => {
        return value >= threshold && (thresholds[index + 1] === undefined || value < thresholds[index + 1]);
      });

      if (colorIndex === -1) {
        colorIndex = colors.length - 1;
      }

      return colors[colorIndex];
    },
    [getMinMaxByRow]
  );

  const renderTableHeaders = useMemo(() => {
    if (loadingHeatmap) {
      return (
        <tr style={{ textAlign: 'left', borderBottom: borders.background, boxSizing: 'border-box' }}>
          <th
            style={{
              textAlign: 'left',
              height: '60px',
              width: '550px',
              verticalAlign: 'bottom',
              paddingBottom: '15px',
              paddingRight: '16px',
              paddingLeft: '16px',
            }}
          >
            <Typography variant="caption" color="Grey">
              {capitalize(filter)}
            </Typography>
          </th>
          {new Array(11).fill(0).map((_, idx) => (
            <th
              key={idx}
              style={{
                textAlign: 'left',
                height: '60px',
                width: '60px',
                verticalAlign: 'bottom',
                paddingBottom: '28px',
              }}
            >
              <Typography
                variant="caption"
                color="DarkGrey"
                sx={{
                  display: 'block',
                  maxWidth: '118px',
                  maxHeight: '60px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  transform: 'rotate(-45deg)',
                  textAlign: 'left',
                }}
              ></Typography>
            </th>
          ))}
        </tr>
      );
    }
    return (
      <tr style={{ textAlign: 'left', borderBottom: borders.background, boxSizing: 'border-box' }}>
        <th
          style={{
            textAlign: 'left',
            height: '60px',
            width: '550px',
            maxWidth: '550px',
            minWidth: '550px',
            verticalAlign: 'bottom',
            paddingBottom: '15px',
            paddingRight: '16px',
            paddingLeft: '16px',
          }}
        >
          <Typography variant="caption" color="Grey">
            {capitalize(filter)}
          </Typography>
        </th>
        {series.map((item) => (
          <th
            key={item.name}
            style={{
              textAlign: 'left',
              height: '60px',
              width: '60px',
              verticalAlign: 'bottom',
              paddingBottom: '28px',
            }}
          >
            <StyledTooltip title={capitalize(item.name)} placement="top">
              <div>
                <Typography
                  variant="caption"
                  color="DarkGrey"
                  sx={{
                    display: 'block',
                    maxWidth: '118px',
                    maxHeight: '60px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    transform: 'rotate(-45deg)',
                    textAlign: 'left',
                  }}
                >
                  {capitalize(item.name)}
                </Typography>
              </div>
            </StyledTooltip>
          </th>
        ))}
      </tr>
    );
  }, [loadingHeatmap, series, filter]);

  const renderTableBody = useMemo(() => {
    if (loadingHeatmap) {
      return new Array(11).fill(0).map((_) => (
        <tr style={{ borderBottom: borders.background }}>
          <td
            style={{
              height: '60px',
              width: '550px',
              maxWidth: '550px',
              minWidth: '550px',
              borderBottom: borders.background,
              paddingRight: '16px',
              paddingLeft: '16px',
            }}
          >
            <SkeletonLoader variant="rectangular" height={60} width="100%" />
          </td>
          {new Array(11).fill(0).map((_, idx) => (
            <td
              key={idx}
              style={{
                height: '60px',
                width: '60px',
                maxWidth: '60px',
                boxSizing: 'border-box',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <SkeletonLoader variant="rectangular" height={60} width={60} />
            </td>
          ))}
        </tr>
      ));
    }
    return categories.map((category, index) => (
      <tr key={category}>
        <td
          style={{
            height: '60px',
            width: '550px',
            maxWidth: '550px',
            minWidth: '550px',
            paddingRight: '40px',
            paddingLeft: '16px',
            borderBottom: borders.background,
          }}
        >
          <Typography variant="caption"> {category}</Typography>
        </td>
        {series.map((item) => {
          const value = item.data[index];
          const color = getColorRange(value, index);
          return (
            <td
              key={item.name}
              style={{
                background: color,
                height: '60px',
                width: '60px',
                maxWidth: '60px',
                boxSizing: 'border-box',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                variant="title4"
                sx={{
                  display: 'inline-block',
                  maxWidth: '60px',
                  maxHeight: '60px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
              >
                {item.data[index] === null ? '–' : item.data[index]}
              </Typography>
            </td>
          );
        })}
      </tr>
    ));
  }, [categories, series, getColorRange, loadingHeatmap]);

  const getTableWidth = useCallback(() => {
    const baseWidth = 610;
    const totalWidth = series.length > 1 ? series.length * 60 + baseWidth : 3 * 60 + baseWidth;
    const maxWidth = window.innerWidth - 300;
    return totalWidth >= maxWidth ? '100%' : `${totalWidth}px`;
  }, [series.length]);

  return (
    <div style={{ overflowX: 'scroll', maxWidth: getTableWidth(), width: '100%' }}>
      <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
        <thead>{renderTableHeaders}</thead>
        <tbody>{renderTableBody}</tbody>
      </table>
    </div>
  );
};
