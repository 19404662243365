import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import Polyglot from 'node-polyglot';

import { CompanyAPI } from '@/api-client/company.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';

export type PolyglotData = Record<string, string | Record<string, unknown>>;

interface PolyglotContextState {
  readonly loaded: boolean;
  readonly polyglot: Polyglot;
  readonly refreshPolyglotData: () => Promise<void>;
  readonly replacePhrasesOnly: () => Promise<void>;
  readonly useInitLanguage: () => void;
  readonly changeLocale: (locale: string | number | undefined) => void;
  readonly setUserLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const PolyglotContext = createContext<PolyglotContextState>({
  loaded: false,
  polyglot: new Polyglot(),
  refreshPolyglotData: async () => {},
  replacePhrasesOnly: async () => {},
  useInitLanguage: () => {},
  changeLocale: () => {},
  setUserLanguage: () => {},
});

export const usePolyglot = (): PolyglotContextState => {
  const contextState = useContext(PolyglotContext);
  if (contextState === null) {
    throw new Error('usePolyglot must be used within a PolyglotProvider tag');
  }

  return contextState;
};

interface PolyglotProviderProps {
  children?: ReactNode;
}

const translationsAvailable = ['en', 'ar', 'es', 'fr', 'de', 'pt', 'it'];

export const PolyglotProvider = ({ children }: PolyglotProviderProps) => {
  const [hasCompanyTranslations, setHasCompanyTranslations] = useState(false);
  const [cache, setCache] = useState<{
    loaded: boolean;
    polyglot: Polyglot | undefined;
    translationsJSON: any | undefined;
  }>({
    loaded: false,
    translationsJSON: undefined,
    polyglot: new Polyglot(),
  });
  const browserLanguage = navigator.language.split('-')[0]; // Get the browser's language (e.g., "en" from "en-US")
  const defaultLocale = browserLanguage in translationsAvailable ? browserLanguage : 'en'; // Use browser language if available
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const [userLanguage, setUserLanguage] = useState(state?.user?.language);

  const changeLocale = useCallback(
    (locale: string | number | undefined) => {
      if (typeof locale !== 'undefined' && cache.polyglot && cache.translationsJSON && cache.translationsJSON[locale]) {
        setUserLanguage(locale as string);
        cache.polyglot.locale(locale.toString());
        cache.polyglot.replace(cache.translationsJSON[locale]);
        // Direction change is after the new nav
        if (locale === 'ar') {
          document.documentElement.setAttribute('dir', 'rtl');
          document.documentElement.setAttribute('lang', 'ar');
        } else {
          document.documentElement.setAttribute('dir', 'ltr');
          document.documentElement.setAttribute('lang', locale.toString());
        }
      } else {
        console.warn(`Translation for ${locale} not found.`);
      }
    },
    [cache]
  );

  const useInitLanguage = useCallback(() => {
    if (cache.translationsJSON && userLanguage && userLanguage in cache.translationsJSON) {
      changeLocale(userLanguage);
    } else {
      changeLocale(defaultLocale); // Fallback to default if there's an issue
    }
  }, [cache.translationsJSON, changeLocale, defaultLocale, userLanguage]);

  const refreshPolyglotData = useCallback(async () => {
    try {
      setLoading(true);
      const translationsJSON = await CompanyAPI.getDefaultTranslationsJSON();

      const polyglot = new Polyglot({
        phrases: translationsJSON,
      });
      setCache({
        polyglot,
        translationsJSON,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
      showMessage('Could not load translations', 'error');
      setCache({
        polyglot: new Polyglot(),
        translationsJSON: {},
        loaded: true,
      });
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshPolyglotData();
  }, [refreshPolyglotData]);

  const replacePhrasesOnly = useCallback(async () => {
    if (!cache.polyglot || hasCompanyTranslations) return;
    try {
      setLoading(true);
      const translationsJSON = await CompanyAPI.getTranslationsJSON();

      setCache({
        polyglot: new Polyglot({ phrases: translationsJSON }),
        translationsJSON,
        loaded: true,
      });

      setHasCompanyTranslations(true);
    } catch (error) {
      showMessage(`Could not replace polyglot content. ${nestErrorMessage(error)}`, 'error');
    }
    setLoading(false);
  }, [hasCompanyTranslations, showMessage, cache]);

  return (
    <PolyglotContext.Provider
      value={{
        polyglot: cache.polyglot as Polyglot,
        loaded: cache.loaded,
        refreshPolyglotData,
        useInitLanguage,
        changeLocale,
        replacePhrasesOnly,
        setUserLanguage,
      }}
    >
      {loading ? (
        <SkeletonLoader
          variant="rectangular"
          width="83%"
          height="40%"
          sx={{ borderRadius: 2, backgroundColor: themeColors.Background }}
        />
      ) : (
        <>{children}</>
      )}
    </PolyglotContext.Provider>
  );
};
