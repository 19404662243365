import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import { CompanyPayroll, PayrollPayScheduleDto, TaxYear, TaxYearStart } from '@shared/modules/payroll/payroll.types';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { translateJSMonthNo } from '@v2/infrastructure/i18n/translate.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { range } from 'lodash';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { formatPayPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import {
  formatPayrollDateType,
  formatTaxYearStart,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings.util';
import { UK_TAX_YEAR_START } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { addDate, getMaxDayInMonth } from '@/v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

const SupportedPayPeriods = ['Monthly', 'Weekly'] as const;

const DefaultPayPeriod = 'Monthly';

const daysOfMonthOptions = range(31).map((n) => ({ label: (n + 1).toString().padStart(2, '0'), value: n + 1 }));

const twoDigit = (n: number) => n.toString().padStart(2, '0');

const getTaxYearFromFirstPeriodEndDateAndTaxYearStart = (
  firstPeriodEndDate: string,
  taxYearStart: TaxYearStart
): TaxYear => {
  const [firstPeriodEndYear, firstPeriodEndDateMonth, firstPeriodEndDateDay] = firstPeriodEndDate.split('-');
  const numericFirstPeriodEndYear = Number(firstPeriodEndYear);
  const isPaymentEndDateBeforeTaxYearStart = `${firstPeriodEndDateMonth}-${firstPeriodEndDateDay}` < taxYearStart;
  const yearNumber = isPaymentEndDateBeforeTaxYearStart ? numericFirstPeriodEndYear - 1 : numericFirstPeriodEndYear;
  return `Year${yearNumber}`;
};

const PayrollScheduleEdit = ({
  isOpen,
  setIsOpen,
  isUKPayroll,
  payrollId,
  schedule,
  onClose,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly isUKPayroll: boolean;
  readonly payrollId: number;
  readonly schedule?: PayrollPayScheduleDto | null;
  readonly onClose: () => void;
}) => {
  const { polyglot } = usePolyglot();
  const [savingSchedule, setSavingSchedule] = useState(false);
  const isLastDay = useRef(false);
  const [showMessage] = useMessage();

  const [endOfThisMonth, twentyFifthOfThisMonth, nextFriday, nextSunday] = useMemo(() => {
    const now = new Date();
    const [day, month, year] = [now.getDate(), now.getMonth() + 1, now.getFullYear()];
    const endOfThisMonth = `${year}-${twoDigit(month)}-${getMaxDayInMonth(month, year)}`;
    const twentyFifthOfThisMonth = `${year}-${twoDigit(month)}-25`;
    const today = `${year}-${twoDigit(month)}-${twoDigit(day)}`;
    const nextSunday = addDate(today, { days: 7 - now.getDay() });
    const nextFriday = addDate(nextSunday, { days: -2 });
    return [endOfThisMonth, twentyFifthOfThisMonth, nextFriday, nextSunday];
  }, []);

  const defaultFirstPeriodEndDate = useCallback(
    (payPeriod: PayPeriod) => {
      return payPeriod === 'Weekly' ? nextSunday : endOfThisMonth;
    },
    [endOfThisMonth, nextSunday]
  );
  const defaultFirstPaymentDate = useCallback(
    (payPeriod: PayPeriod) => {
      return payPeriod === 'Weekly' ? nextFriday : twentyFifthOfThisMonth;
    },
    [twentyFifthOfThisMonth, nextFriday]
  );

  const monthOptions = useMemo(
    () =>
      range(12).map((n) => ({
        label: translateJSMonthNo(n, polyglot),
        value: n + 1,
      })),
    [polyglot]
  );

  const periodOptions = useMemo<OptionObject[]>(
    () =>
      SupportedPayPeriods.map((value) => ({
        label: formatPayPeriod(value, polyglot),
        value,
      })),
    [polyglot]
  );

  const formik = useFormik({
    initialValues: {
      payPeriod: schedule?.payPeriod ?? DefaultPayPeriod,
      taxYearStart: schedule?.taxYearStart ?? (isUKPayroll ? UK_TAX_YEAR_START : '01-01'),
      firstPeriodEndDate: schedule?.firstPeriodEndDate ?? defaultFirstPeriodEndDate(DefaultPayPeriod),
      firstPaymentDate: schedule?.firstPaymentDate ?? defaultFirstPaymentDate(DefaultPayPeriod),
    },
    onSubmit: async (values) => {
      const { payPeriod, firstPaymentDate, firstPeriodEndDate, taxYearStart } = values;
      let payScheduleUpdated = false;
      setSavingSchedule(true);
      try {
        if (schedule) {
          await PayrollExternalApi.updatePaySchedule(
            payrollId,
            schedule.taxYear,
            schedule.payPeriod,
            schedule.ordinal,
            {
              payPeriod,
              firstPaymentDate,
              firstPeriodEndDate,
              taxYearStart,
            }
          );
        } else {
          const taxYear = getTaxYearFromFirstPeriodEndDateAndTaxYearStart(firstPeriodEndDate, taxYearStart);
          await PayrollExternalApi.createNewPaySchedule(payrollId, taxYear, payPeriod, {
            firstPaymentDate,
            firstPeriodEndDate,
            taxYearStart,
          });
        }
        payScheduleUpdated = true;
      } catch (error) {
        showMessage(`A new pay schedule could not be configured. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setSavingSchedule(false);
      }
      if (payScheduleUpdated) {
        onClose();
      }
    },
  });

  useLayoutEffect(() => {
    const [month, day] = formik.values.taxYearStart.split('-').map(Number);
    const maxDay = getMaxDayInMonth(month, 2000);
    isLastDay.current = day >= maxDay;
    if (day <= maxDay) return;
    formik.setFieldValue('taxYearStart', `${twoDigit(month)}-${twoDigit(maxDay)}`);
  }, [formik, formik.values.taxYearStart]);

  const resetDates = useCallback(
    (payPeriod: PayPeriod) => {
      formik.setFieldValue('firstPeriodEndDate', defaultFirstPeriodEndDate(payPeriod));
      formik.setFieldValue('firstPaymentDate', defaultFirstPaymentDate(payPeriod));
    },
    [defaultFirstPaymentDate, defaultFirstPeriodEndDate, formik]
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Typography variant="title2">Schedule</Typography>
          <SelectComponent
            name="payPeriod"
            label="Pay period"
            options={periodOptions}
            value={formik.values.payPeriod}
            onChange={(e) => {
              formik.handleChange(e);
              // if the pay period is changed, reset the payment/end-period dates to sensible defaults
              resetDates(e.target.value as PayPeriod);
            }}
            disabled={savingSchedule}
          />
          <SelectComponent
            name="taxYearStartDate"
            label="Tax year start (Date)"
            options={daysOfMonthOptions}
            value={Number(formik.values.taxYearStart.slice(3, 5))}
            onChange={(e) => {
              const newDay = twoDigit(Number(e.target.value));
              const month = formik.values.taxYearStart.slice(0, 2);
              formik.setFieldValue('taxYearStart', `${month}-${newDay}`);
            }}
            disabled={isUKPayroll || savingSchedule}
          />
          <SelectComponent
            name="taxYearStartMonth"
            label="Tax year start (Month)"
            options={monthOptions}
            value={Number(formik.values.taxYearStart.slice(0, 2))}
            onChange={(e) => {
              const newMonth = twoDigit(Number(e.target.value));
              const day = isLastDay.current
                ? twoDigit(getMaxDayInMonth(Number(e.target.value), 2000))
                : formik.values.taxYearStart.slice(3, 5);
              formik.setFieldValue('taxYearStart', `${newMonth}-${day}`);
            }}
            disabled={isUKPayroll || savingSchedule}
          />

          <DatePickerComponent
            name="firstPeriodEndDate"
            label="First pay period ending"
            value={formik.values.firstPeriodEndDate}
            onChange={(date) => formik.setFieldValue('firstPeriodEndDate', date)}
            disabled={savingSchedule}
          />

          <DatePickerComponent
            name="firstPaymentDate"
            label="First payment date"
            value={formik.values.firstPaymentDate}
            onChange={(date) => formik.setFieldValue('firstPaymentDate', date)}
            disabled={savingSchedule}
          />

          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth type="submit">
              {polyglot.t('General.save')}
            </ButtonComponent>
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};

interface Props {
  readonly payroll: CompanyPayroll;
  readonly sx?: SxProps<Theme>;
  readonly refreshPayroll: () => Promise<void>;
}

export const PayrollScheduleSettingsSection = ({ payroll, sx, refreshPayroll }: Props): JSX.Element => {
  const [editSchedule, setEditSchedule] = useState<boolean>(false);
  const hasPayruns = !!payroll.payruns.length || !!payroll.globalPayruns.length;
  const [showMessage] = useMessage();

  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader
        showEditButton={!editSchedule}
        onEditClick={() => {
          if (hasPayruns) {
            showMessage('The payroll schedule cannot be changed after the first payrun has started.', 'info');
            return;
          }
          setEditSchedule(true);
        }}
      >
        Schedule
      </PayrollSettingSectionHeader>

      <Box
        sx={{
          display: 'inline-grid',
          gridTemplateColumns: '1fr 3fr',
          rowGap: spacing.g10,
          columnGap: spacing.g20,
          mt: spacing.mt10,
        }}
      >
        <>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            Tax year start
          </Typography>
          <Typography variant="title4">{formatTaxYearStart(payroll.schedule?.taxYearStart) ?? '-'}</Typography>
        </>
        <>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            Pay period
          </Typography>
          <Typography variant="title4">{payroll.schedule?.payPeriod ?? '-'}</Typography>
        </>
        <>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            Pay period ends
          </Typography>
          <Typography variant="title4">
            {formatPayrollDateType(
              payroll.schedule?.payPeriod,
              payroll.schedule?.firstPeriodEndDate,
              payroll.schedule?.periodEndDateType
            ) ?? '-'}
          </Typography>
        </>
        <>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            Payment date
          </Typography>
          <Typography variant="title4">
            {formatPayrollDateType(
              payroll.schedule?.payPeriod,
              payroll.schedule?.firstPaymentDate,
              payroll.schedule?.paymentDateType
            ) ?? '-'}
          </Typography>
        </>
      </Box>

      <PayrollScheduleEdit
        isOpen={editSchedule}
        setIsOpen={setEditSchedule}
        payrollId={payroll.id}
        isUKPayroll={!!payroll.employer}
        schedule={payroll.schedule}
        onClose={async () => {
          await refreshPayroll();
          setEditSchedule(false);
        }}
      />
    </Stack>
  );
};
