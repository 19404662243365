import { Pagination } from '@/models/company.model';

export interface UserOffboardingDues {
  apps?: number;
  devices?: number;
  inPayroll?: boolean;
  inPension?: boolean;
  inInsurance?: boolean;
}

export interface UserOffboardingParameters {
  userId: number | undefined;
  terminationDate: string;
  changeReason: string;
  note?: string | null;
}

export const MoneyEnum = {
  inPayroll: 'payroll',
  inPension: 'pension',
  inInsurance: 'insurance',
};

export enum ProgressStatus {
  None = 'None',
  Pending = 'Pending',
  Completed = 'Completed',
  Missing = 'Missing',
}

export interface OffboardingProgressInterface {
  userId: number;
  name: string;
  terminationDate: string;
  deactivationDate: string | null;
  offboardedBy: number | null;
}

export type OffboardingProgressPageResult = {
  users: OffboardingProgressInterface[];
  pagination: Pagination;
};

export enum TimeSegment {
  All = 'All',
  Past = 'Past',
  Upcoming = 'Upcoming',
}
