import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { nestErrorMessage } from '@/lib/errors';
import { APP_STORE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CalendarEndpoints, CalendarSettingsAPI, GoogleCalendarAPI } from '@/v2/feature/calendar/calendar.api';
import { CalendarSettingProps, ViewSettingEnum, ViewSettingsLabel } from '@/v2/feature/calendar/calendar.interface';
import { CalendarSettingDrawer } from '@/v2/feature/calendar/features/settings/calendar-setting-drawer.component';
import { CalendarSettingsSyncDrawer } from '@/v2/feature/calendar/features/settings/calendar-settings-sync-drawer.component';
import { OutlookCalendarAPI } from '@/v2/feature/calendar/outlook-calendar.api';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { definitionListSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

export const CalendarSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [state] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const context = getScopesContext(state.user);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [calenderSettings, setCalenderSettings] = useState<CalendarSettingProps | undefined>(undefined);
  const hasAppsRead = hasScopes(['apps'], context);
  const [calendarSyncDrawerOpen, setCalendarSyncDrawerOpen] = useState<boolean>(false);
  const { data: installedApps } = useApiClient(AppIntegrationEndpoints.getCalendarApps(hasAppsRead), {
    suspense: false,
  });

  // const [googleCalendarConfigO, setGoogleCalendarConfigO] = useState<
  //   O.Option<GoogleCalendar_GetCalendarStatusForUserDto>
  // >(() => O.none);
  // const [googleCalendarGetConfig$] = useState(() => new Subject<void>());
  // const [googleCalendarGetConfigRes$] = useState(
  //   () => new Subject<AxiosResponse<GoogleCalendar_GetCalendarStatusForUserDto>>()
  // );
  // useEffect(() => {
  //   pipe(
  //     googleCalendarGetConfig$.subscribe({
  //       next: async () => {
  //         try {
  //           const res = await GoogleCalendarAPI.getConfig();
  //           googleCalendarGetConfigRes$.next(res);
  //         } catch (_error) {
  //           // TODO if 401 or 404, then do nothing, otherwise, implement retry.
  //         }
  //       },
  //     }),
  //     (s) => () => s.unsubscribe()
  //   );
  // }, [googleCalendarGetConfig$, googleCalendarGetConfigRes$]);

  // useEffect(() => {
  //   pipe(
  //     googleCalendarGetConfigRes$.subscribe({
  //       next: async (res) => {
  //         setGoogleCalendarConfigO(O.some(res.data));
  //       },
  //     }),
  //     (s) => () => s.unsubscribe()
  //   );
  // }, [googleCalendarGetConfigRes$]);

  // useEffect(() => {
  //   googleCalendarGetConfig$.next();
  // }, [googleCalendarGetConfig$]);

  const microsoft365Installed = useMemo(() => {
    if (hasAppsRead)
      return installedApps && installedApps?.length > 0
        ? installedApps?.some((app: any) => app.stub === 'microsoft365')
        : false;
  }, [installedApps, hasAppsRead]);

  const googleConnected = useMemo(() => {
    if (hasAppsRead)
      return installedApps && installedApps?.length > 0
        ? installedApps?.some((app: any) => app.stub === 'google-workspace')
        : false;
  }, [installedApps, hasAppsRead]);

  const neitherAppIsConnected = useMemo(() => {
    return !(microsoft365Installed || googleConnected);
  }, [googleConnected, microsoft365Installed]);

  const syncGoogleCalendarOnDemand = async () => {
    try {
      await GoogleCalendarAPI.syncCalendar();
      showMessage(polyglot.t('CalendarPage.successMessages.sync'), 'success');
    } catch (err) {
      showMessage(`${nestErrorMessage(err)}`, 'error');
    }
  };

  const syncOutlookCalendarOnDemand = async () => {
    try {
      await OutlookCalendarAPI.syncCalendar();
      showMessage(polyglot.t('CalendarPage.successMessages.create'), 'success');
    } catch (err) {
      showMessage(`${nestErrorMessage(err)}`, 'error');
    }
  };

  const syncCalendar = () => {
    if (microsoft365Installed) syncOutlookCalendarOnDemand();
    if (googleConnected) syncGoogleCalendarOnDemand();
  };

  const [showMessage] = useMessage();

  const getCalendarSetting = useCallback(async () => {
    try {
      setLoading(true);
      const settings = await CalendarSettingsAPI.getSettings();
      setCalenderSettings(settings);
    } catch (error) {
      showMessage(
        polyglot.t('CalendarSettingDrawerContent.errorMessages.encounter', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [showMessage, polyglot]);

  useEffect(() => {
    getCalendarSetting();
  }, [getCalendarSetting]);

  const connectedApp = useMemo(() => {
    if (microsoft365Installed && googleConnected) return 'both Google & Outlook calendars';
    if (microsoft365Installed) return 'Outlook calendar';
    if (googleConnected) return 'Google calendar';
    return undefined;
  }, [googleConnected, microsoft365Installed]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('CalendarSettingsPage.settingsTilte')}
          </Typography>
        }
      />
      <ContentWrapper loading={false}>
        <Box sx={{ maxWidth: '500px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...spacing.mb20 }}>
            <Typography variant="title3"> {polyglot.t('CalendarSettingsPage.configureView')}</Typography>
            <IconButton sx={tableIconButtonSx} onClick={() => setEditMode(true)}>
              <Edit {...iconSize} />
            </IconButton>
          </Box>

          <CalendarSettingReadonly initialValues={calenderSettings} loading={loading} />
          <CalendarSettingDrawer
            initialValues={calenderSettings}
            isOpen={editMode}
            setIsOpen={setEditMode}
            refresh={() => getCalendarSetting()}
          />
        </Box>

        <Box sx={{ maxWidth: '500px', mt: spacing.mt40 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...spacing.mb20 }}>
            <Typography variant="title3"> {polyglot.t('CalendarSettingsPage.integration')}</Typography>
            {!neitherAppIsConnected && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => setCalendarSyncDrawerOpen(true)}
              >
                {polyglot.t('CalendarSettingsPage.resyncCalendar')}
              </ButtonComponent>
            )}
            {neitherAppIsConnected && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => routerHistory.push(APP_STORE_ROUTE)}
              >
                {polyglot.t('CalendarSettingsPage.gotoAppStore')}
              </ButtonComponent>
            )}
          </Box>

          <CalendarIntegrationSettingReadonly
            microsoft365Connected={!!microsoft365Installed}
            googleConnected={!!googleConnected}
          />
          <CalendarSettingDrawer
            initialValues={calenderSettings}
            isOpen={editMode}
            setIsOpen={setEditMode}
            refresh={() => getCalendarSetting()}
          />
        </Box>
        {connectedApp && (
          <CalendarSettingsSyncDrawer
            isOpen={calendarSyncDrawerOpen}
            setIsOpen={setCalendarSyncDrawerOpen}
            syncAction={() => {
              setCalendarSyncDrawerOpen(false);
              syncCalendar();
            }}
            appName={connectedApp}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};

const CalendarSettingReadonly = ({
  initialValues,
  loading,
}: {
  initialValues: CalendarSettingProps | undefined;
  loading: boolean;
}) => {
  const { polyglot } = usePolyglot();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.g10,
        ...spacing.mt20,
      }}
    >
      <ViewItem
        title={polyglot.t('CalendarSettingReadonly.calendarView')}
        value={
          loading ? (
            <SkeletonLoader variant="text" width="20%" height="16px" />
          ) : initialValues && initialValues.viewSetting ? (
            ViewSettingsLabel(polyglot)[initialValues.viewSetting]
          ) : (
            ViewSettingsLabel(polyglot)[ViewSettingEnum.Company]
          )
        }
      />
    </Box>
  );
};

const CalendarIntegrationSettingReadonly = ({
  microsoft365Connected,
  googleConnected,
}: {
  microsoft365Connected: boolean;
  googleConnected: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const { data: existingPublishLinks } = useApiClient(
    microsoft365Connected ? CalendarEndpoints.getOutlookCalendarPublishLinks() : { url: '' },
    {
      suspense: false,
    }
  );
  const integrationStatus = useMemo(() => {
    if (googleConnected && microsoft365Connected)
      return polyglot.t('CalendarIntegrationSettingReadonly.bothGoogleAndOutlookAreConnected');
    if (microsoft365Connected) return polyglot.t('CalendarIntegrationSettingReadonly.outlookConnected');
    if (googleConnected) return polyglot.t('CalendarIntegrationSettingReadonly.googleConnected');
    return polyglot.t('CalendarIntegrationSettingReadonly.noCalendarIntegrations');
  }, [googleConnected, microsoft365Connected, polyglot]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.g10,
        ...spacing.mt20,
      }}
    >
      <ViewItem title={integrationStatus} onlyTitle={true} />
      <Box component="dl" sx={definitionListSx}>
        {existingPublishLinks?.icsURL && (
          <FieldStructure
            fieldTitle={polyglot.t('CalendarIntegrationSettingReadonly.icsCalendarLink')}
            fieldValue={
              existingPublishLinks?.icsURL && {
                isLink: true,
                label: truncateWithEllipses(existingPublishLinks.icsURL, 30),
                icon: <Copy {...iconSize} />,
                onClick: () => navigator.clipboard.writeText(existingPublishLinks.icsURL),
              }
            }
            fieldStub="icsURL"
          />
        )}
        {existingPublishLinks?.htmlURL && (
          <FieldStructure
            fieldTitle={polyglot.t('CalendarIntegrationSettingReadonly.htmlCalendarLink')}
            fieldValue={
              existingPublishLinks?.htmlURL && {
                isLink: true,
                label: truncateWithEllipses(existingPublishLinks.htmlURL, 30),
                icon: <Copy {...iconSize} />,
                onClick: () => navigator.clipboard.writeText(existingPublishLinks.htmlURL),
              }
            }
            fieldStub="htmlURL"
          />
        )}
      </Box>
    </Box>
  );
};

export const ViewItem = ({
  title,
  value = '',
  onlyTitle = false,
}: {
  title: string;
  value?: JSX.Element | string;
  onlyTitle?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: !onlyTitle ? '1.5fr 2.5fr' : '4fr 1fr',
        rowGap: spacing.g10,
        columnGap: spacing.g20,
      }}
    >
      <Typography variant="caption">{title}</Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};
