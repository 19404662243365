import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import { ShowMessageProps, SnackBarMessage } from '@v2/components/snack-bar-message.component';
import React, { useContext, useEffect, useState } from 'react';

const MESSAGE_TIMEOUT = 5000;

const initialMessageState: ShowMessageProps = { message: '', type: 'success', show: false } as const;

interface MessageProps {
  readonly messageObject: ShowMessageProps;
}

/**
 * TODO refactor, as it looks unnecessarily complex
 */
const Message = ({ messageObject }: MessageProps): JSX.Element => {
  const [showMessage, setShowMessage] = useState(initialMessageState);

  useEffect(() => {
    setShowMessage(messageObject);
  }, [messageObject]);

  return (
    <SnackBarMessage
      showMessage={showMessage}
      handleClose={(_: unknown, reason?: string) => {
        if (reason !== 'clickaway') {
          setShowMessage({ ...showMessage, show: false });
        }
      }}
    />
  );
};

export interface MessageWrapperProps {
  readonly timeout?: number;
}

export const MessageWrapper = ({ timeout = MESSAGE_TIMEOUT }: MessageWrapperProps): JSX.Element => {
  const [state, dispatch] = useContext(GlobalContext);
  const { message } = state;

  useEffect(() => {
    if (Boolean(message.length)) {
      setTimeout(() => {
        dispatch({ type: GlobalStateActions.HIDE_MESSAGE });
      }, timeout);
    }
  }, [message.length, dispatch, timeout]);

  return message.length !== 0 ? (
    <>
      {message.map((msg, idx: number) => (
        <Message key={idx} messageObject={{ message: msg.message, show: true, type: msg.type }} />
      ))}
    </>
  ) : (
    <></>
  );
};
