import { Typography } from '@mui/material';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { SecurityAuditReportTable } from '@v2/feature/security/security-settings/components/security-audit-report-table.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly devicePossession: DevicePossessionDto;
  readonly loading: boolean;
}

export const DeviceActivityLogPage = ({ devicePossession }: Props) => {
  const { data, isValidating } = useApiClient(DeviceEndpoints.getActivityLogForDevice(devicePossession.deviceId), {
    suspense: false,
  });
  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Activity log</Typography>}
      />
      <ContentWrapper loading={false}>
        <SecurityAuditReportTable auditTrailRows={data} loadingAuditTrail={isValidating} activityLog={true} />
      </ContentWrapper>
    </RootStyle>
  );
};
