import React, { useMemo, useState } from 'react';

import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { StockDeviceEditDrawer } from '@v2/feature/device/components/devices-list-overview/stock-device-edit-drawer.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto } from '@v2/feature/device/device.dto';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

interface DevicesOrdersTableProps {
  readonly devices: readonly DeviceDto[];
  readonly refresh: () => Promise<void>;
}

export const DevicesStockTable = ({ devices, refresh }: DevicesOrdersTableProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState('');
  const [deviceToEdit, setDeviceToEdit] = useState<DeviceDto | undefined>(undefined);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [deviceToDelete, setDeviceToDelete] = useState<DeviceDto | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      const modelName = device.modelName;
      const deviceModelId = device.deviceModelId?.toString() ?? undefined;
      const serialNumber = device.serialNumber ?? undefined;

      return (
        modelName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        serialNumber?.toLowerCase().includes(searchInput.toLowerCase()) ||
        deviceModelId?.includes(searchInput.toLowerCase())
      );
    });
  }, [devices, searchInput]);

  const columnData = useMemo<ColumnDef<DeviceDto, DeviceDto>[]>(
    () => [
      {
        header: () => 'Model',
        accessorFn: (row) => row,
        id: 'deviceModelId',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return <Box>{device.deviceModelId}</Box>;
        },
        size: 30,
      },
      {
        header: () => 'Model Name',
        accessorFn: (row) => row,
        id: 'modelName',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return <Box>{device.modelName}</Box>;
        },
        size: 100,
      },
      {
        header: () => 'Model Number',
        accessorFn: (row) => row,
        id: 'modelNumber',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return <Box>{device.modelNumber ? <Typography>{device.modelNumber}</Typography> : <EmptyCell />}</Box>;
        },
        size: 100,
      },
      {
        header: () => 'Serial Number',
        accessorFn: (row) => row,
        id: 'serialNumber',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return device.serialNumber ? <Box>{device.serialNumber}</Box> : <EmptyCell />;
        },
        size: 250,
      },
      {
        header: () => 'Storage',
        accessorFn: (row) => row,
        id: 'storage',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return <Box>{device.storage ? <Typography>{device.storage} GB</Typography> : <EmptyCell />}</Box>;
        },
        size: 100,
      },
      {
        header: () => 'RAM',
        accessorFn: (row) => row,
        id: 'ram',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return <Box>{device.ram ? <Typography>{device.ram} GB</Typography> : <EmptyCell />}</Box>;
        },
        size: 100,
      },
      {
        header: () => 'Screen Size',
        accessorFn: (row) => row,
        id: 'screenSize',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return <Box>{device.screenSize ? <Typography>{device.screenSize}"</Typography> : <EmptyCell />}</Box>;
        },
        size: 100,
      },
      {
        header: () => ' ',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: (info: CellContext<DeviceDto, DeviceDto>) => {
          const device: DeviceDto = info.getValue();
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setDeviceToDelete(device);
                  setIsDeleteModalOpen(true);
                }}
              >
                <Trash {...iconSize} />
              </IconButton>

              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setDeviceToEdit(device);
                  setIsEditDrawerOpen(true);
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            </Box>
          );
        },
        size: 100,
      },
    ],
    []
  );

  const deleteDevice = async () => {
    try {
      if (!deviceToDelete) {
        showMessage('Could not delete device. A device was not selected.', 'error');
        return;
      }
      await DeviceAPI.deleteStockDeviceByDeviceIdAsSuperadmin(deviceToDelete?.id);
      showMessage('Device deleted successfully.', 'success');
      setDeviceToDelete(undefined);
      await refresh();
      setIsDeleteModalOpen(false);
    } catch (error) {
      showMessage(`Could not delete the device. ${nestErrorMessage(error)}`, 'error');
    }
  };

  return (
    <>
      {devices.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20 }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')}
            style={{ width: '350px', minWidth: '350px' }}
          />
        </Box>
      )}
      <BasicTable<DeviceDto> rowData={filteredDevices} columnData={columnData} />
      <StockDeviceEditDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        onClose={() => setDeviceToEdit(undefined)}
        device={deviceToEdit}
        refresh={refresh}
      />

      <Modal
        open={isDeleteModalOpen}
        onClose={() => {
          setDeviceToDelete(undefined);
          setIsDeleteModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            borderRadius: 1,
          }}
        >
          <Typography variant="h4">Delete Device</Typography>
          <Typography sx={{ my: 2 }}>Are you sure you want to delete this device?</Typography>

          {deviceToDelete && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, justifyContent: 'center' }}>
              <Typography>Device Details:</Typography>
              <Typography variant="body2">- {deviceToDelete.modelName}</Typography>
              <Typography variant="body2">- {deviceToDelete.modelNumber}</Typography>
              {deviceToDelete.serialNumber && <Typography variant="body2">- {deviceToDelete.serialNumber}</Typography>}
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, width: '100%' }}>
            <Button variant="contained" color="error" onClick={deleteDevice} size="small">
              Delete Device
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setDeviceToDelete(undefined);
                setIsDeleteModalOpen(false);
              }}
              size="small"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
