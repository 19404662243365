// eslint-disable-next-line filenames/match-regex
export class DepWizardHelper {
  public static formatDate(dateString: Date) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  }
}
