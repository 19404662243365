export const oldToNewRedirects = (pathname: string, routerHistory: any) => {
  const redirectTo = (pattern: string, replacement: string) => {
    if (pathname.includes(pattern)) {
      routerHistory.push(pathname.replace(pattern, replacement));
    }
  };

  // Define a set of route handling functions
  const handleReviewsRoute = () => {
    redirectTo('/reviews/me/ongoing/answers', '/growth/me/reviews/ongoing/answers');
    if (pathname === '/reviews/me/results') {
      routerHistory.push('/growth/me/reviews/results');
    }
    redirectTo('/reviews/company/ongoing', '/growth/company/reviews/ongoing');
  };

  const handlePaymentsRoute = () => {
    redirectTo('/payments/me', '/money/me/payments/contractor');
    redirectTo('/payments/company/contractors', '/money/company/payments/overview');
  };

  // Route checks
  if (pathname.startsWith('/reviews/')) {
    handleReviewsRoute();
    return; // Exit early to avoid further processing
  }

  if (pathname.startsWith('/payments/')) {
    handlePaymentsRoute();
    return; // Exit early
  }

  redirectTo('/time-planner/company/requests', '/time/company/absence/requests');
  redirectTo('/devices/me/overview', '/tech/me/devices/overview');
  redirectTo('/payroll/me/summary', '/money/me/salary/summary');

  if (pathname === '/action-items') {
    routerHistory.push('/people/me/action-items');
  }

  if (pathname === '/tasks/me/overview') {
    routerHistory.push('/people/me/tasks/overview');
  }

  const userIdMatch = pathname.match(/^\/users\/(\d+)(\/[a-z]+)?$/);
  if (userIdMatch) {
    const userId = userIdMatch[1];
    const additionalPath = userIdMatch[2] ? userIdMatch[2].substring(1) : 'personal';
    routerHistory.push(`/people/users/${userId}/${additionalPath}`);
  }

  const documentMatch = pathname.match(/^\/users\/([^/]+)\/documents$/);
  if (documentMatch) {
    routerHistory.push(`/people/users/${documentMatch[1]}/documents`);
  }
};
