import { useCallback, useMemo } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { SiteWithMembersDto } from '@/v2/feature/site/site.dto';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface SiteListingTableProps {
  readonly sites: SiteWithMembersDto[];
  readonly loading: boolean;
  readonly setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setSelectedSite: React.Dispatch<React.SetStateAction<SiteWithMembersDto | undefined>>;
  readonly setIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SiteListingTable = ({
  sites,
  loading,
  setIsFormOpen,
  setSelectedSite,
  setIsViewOpen,
}: SiteListingTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const tableColumns = useMemo<ColumnDef<SiteWithMembersDto, SiteWithMembersDto>[]>(() => {
    return [
      {
        header: () => polyglot.t('SiteListingTable.name'),
        accessorFn: (row) => row,
        id: 'displayName',
        maxSize: 120,
        minSize: 120,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return original && <div>{polyglot.t(original.name)}</div>;
        },
      },
      {
        header: () => polyglot.t('SiteListingTable.address'),
        accessorFn: (row) => row,
        id: 'address',
        maxSize: 200,
        minSize: 150,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && <div>{original.address}</div>;
        },
      },
      {
        header: () => polyglot.t('SiteListingTable.employees'),
        accessorFn: (row) => row,
        id: 'employees',
        maxSize: 350,
        minSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>
            {original.users ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '20px' }}>{original.users.length}</Box>
                <Box sx={{ display: 'flex', ml: spacing.ml10 }}>
                  {original.users.map((user) => (
                    <UserAvatar key={user.userId} userId={user.userId} tooltip size="xxsmall" sx={{ ml: spacing.m4 }} />
                  ))}
                </Box>
              </Box>
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
      },
      {
        header: () => '',
        id: 'action',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: spacing.g5 }}>
            <IconButton
              title={polyglot.t('SiteListingTable.edit')}
              onClick={(event) => {
                setIsFormOpen(true);
                setSelectedSite(original);
                event.stopPropagation();
              }}
              sx={tableIconButtonSx}
            >
              <Edit {...iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ];
  }, [polyglot, setIsFormOpen, setSelectedSite]);

  const handleRowClick = useCallback(
    (row: Row<SiteWithMembersDto>) => {
      setSelectedSite(row.original);
      setIsViewOpen(true);
    },
    [setIsViewOpen, setSelectedSite]
  );

  return (
    <BasicTable<SiteWithMembersDto>
      rowData={sites}
      columnData={tableColumns}
      loading={loading}
      initialSort={[{ id: 'displayName', desc: true }]}
      rowClick={handleRowClick}
    />
  );
};
