import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Minus } from '@/images/side-bar-icons/Minus.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@/v2/styles/menu.styles';

type PayrollUserActionMenuProps = {
  onAddToPayrollClick: () => void;
  onRemoveFromPayrollClick: () => void;
};

export const PayrollUserActionMenu = ({
  onAddToPayrollClick,
  onRemoveFromPayrollClick,
}: PayrollUserActionMenuProps) => {
  const { polyglot } = usePolyglot();
  return (
    <StyledMenuComponent
      options={[
        {
          label: 'Add to payroll',
          icon: <Plus {...iconSize} />,
          handler: onAddToPayrollClick,
        },
        {
          label: 'Remove from payroll',
          icon: <Minus {...iconSize} />,
          handler: onRemoveFromPayrollClick,
        },
      ]}
      actionButtonDetails={{
        type: 'button',
        colorVariant: 'secondary',
        sizeVariant: 'small',
        title: polyglot.t('General.actions'),
        icon: <ArrowDown {...iconSize} />,
        iconPosition: 'end',
      }}
    />
  );
};
