import { useState } from 'react';

import { TrackTimeDrawer } from '@/v2/feature/attendance/company/components/track-time-drawer.component';
import { SubmittedAttendanceTodos } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const AttendanceActionItem = ({
  attendanceActionRow,
  refresh,
  onClose,
  afterClose,
}: {
  readonly attendanceActionRow: SubmittedAttendanceTodos;
  readonly refresh: () => Promise<void>;
  readonly onClose?: () => void;
  readonly afterClose?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(attendanceActionRow));

  return (
    <TrackTimeDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      userId={attendanceActionRow.userId}
      attendanceId={attendanceActionRow.id}
      refresh={async () => await refresh()}
      onClose={onClose}
      afterClose={afterClose}
      mode="view"
      view="user"
    />
  );
};
