import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import { UserAPI } from '@/v2/feature/user/user.api';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { EmploymentTypeEnum } from '@/v2/feature/user-onboarding/by-admin/interface/onboarding.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

type NewEmployeeSectionProps = {
  onNext: (userId: number) => void;
  sx?: SxProps<Theme>;
  templateId?: number;
  user?: UserSummaryDto & { personalEmail: string | null };
};

export const NewEmployeeSection = ({ onNext, sx, templateId, user }: NewEmployeeSectionProps) => {
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { refreshCachedUsers } = useCachedUsers();

  const NewEmployeeSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().trim().required(polyglot.t('NewEmployeeSection.errorMessages.nameRequired')),
        lastName: Yup.string().trim().required(polyglot.t('NewEmployeeSection.errorMessages.lastNameRequired')),
        emailAddress: Yup.string()
          .trim()
          .email(polyglot.t('NewEmployeeSection.errorMessages.emailInvalid'))
          .required(polyglot.t('NewEmployeeSection.errorMessages.emailRequired')),
        personalEmail: Yup.string()
          .trim()
          .email(polyglot.t('NewEmployeeSection.errorMessages.emailInvalid'))
          .required(polyglot.t('NewEmployeeSection.errorMessages.personalEmailRequired')),
        startDate: Yup.string().test(dateFieldTest).required(polyglot.t('NewEmployeeSection.errorMessages.chooseDate')),
      }),
    [polyglot]
  );

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      emailAddress: user?.emailAddress ?? '',
      personalEmail: user?.personalEmail ?? '',
      startDate: user?.startDate ?? todaysDateShortISOString(),
      displayName: user?.displayName ?? '',
      isAdmin: false,
      type: EmploymentTypeEnum.Employee,
      templateId,
    },
    validateOnMount: true,
    validationSchema: NewEmployeeSchema,
    onSubmit: async (values) => {
      setSaving(true);
      try {
        const userId = user
          ? (await UserAPI.patchUserBasicInfo(user.userId, {
              ...values,
              displayName: `${values.firstName} ${values.lastName}`,
            }),
            user.userId)
          : await OnboardingAPI.createUserOnboarding(
              {
                ...values,
                displayName: `${values.firstName} ${values.lastName}`,
              },
              refreshCachedUsers
            );
        onNext(userId);
      } catch (error) {
        showMessage(
          polyglot.t('NewEmployeeSection.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('NewEmployeeSection.add')}
          </Typography>

          <TextfieldComponent
            name="firstName"
            label={polyglot.t('NewEmployeeSection.firstName')}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            disabled={saving}
            clearText={() => formik.setFieldValue('firstName', '')}
            helperText={hasSubmitted && formik.errors.firstName}
            error={hasSubmitted && !!formik.errors.firstName}
            autoFocus
          />
          <TextfieldComponent
            name="lastName"
            label={polyglot.t('NewEmployeeSection.lastName')}
            value={formik.values.lastName}
            disabled={saving}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('lastName', '')}
            helperText={hasSubmitted && formik.errors.lastName}
            error={hasSubmitted && !!formik.errors.lastName}
          />
          <TextfieldComponent
            name="emailAddress"
            label={polyglot.t('NewEmployeeSection.emailAddress')}
            type="email"
            value={formik.values.emailAddress}
            disabled={saving}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('emailAddress', '')}
            helperText={hasSubmitted && formik.errors.emailAddress}
            error={hasSubmitted && !!formik.errors.emailAddress}
          />
          <TextfieldComponent
            name="personalEmail"
            label={polyglot.t('NewEmployeeSection.personalEmail')}
            type="email"
            value={formik.values.personalEmail}
            disabled={saving}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('personalEmail', '')}
            helperText={(hasSubmitted && (formik.errors.personalEmail as string)) ?? ''}
            error={hasSubmitted && !!formik.errors.personalEmail}
          />
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.startDate ?? null}
            onChange={(value) => {
              formik.setFieldValue('startDate', value);
            }}
            name="start"
            label={polyglot.t('NewEmployeeSection.start')}
            disabled={saving || !!user} // we can't modify the start date here once the user is created
          />
          {formik.isValid && (
            <LoaderButton
              name={polyglot.t('General.continue')}
              loading={saving}
              colorVariant="primary"
              sizeVariant="large"
              fullWidth
            />
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
