import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as CalendarBig } from '@/images/reports/CalendarBig.svg';
import { ReactComponent as ReportBig } from '@/images/reports/ReportBig.svg';
import { ReactComponent as TableBig } from '@/images/reports/TableBig.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  IconContentActionCardProps,
  IntroCardGroup,
} from '@/v2/components/theme-components/icon-content-action-card.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { parentCardSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReportsEmptyState = ({ onClick }: { onClick: () => void }) => {
  const { polyglot } = usePolyglot();

  const ReportsCards: IconContentActionCardProps[] = [
    {
      title: polyglot.t('ReportsEmptyState.buildYourOwn'),
      content: polyglot.t('ReportsEmptyState.buildYourOwnDesc'),
      iconMedia: <ReportBig />,
    },
    {
      title: polyglot.t('ReportsEmptyState.stayOnTop'),
      content: polyglot.t('ReportsEmptyState.stayOnTopDesc'),
      iconMedia: <CalendarBig />,
    },
    {
      title: polyglot.t('ReportsEmptyState.previewData'),
      content: polyglot.t('ReportsEmptyState.previewDataDesc'),
      iconMedia: <TableBig />,
    },
  ];

  return (
    <Box
      sx={{
        ...spacing.px40,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={parentCardSx}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
          {polyglot.t('ReportsEmptyState.create')}
        </Typography>
        <Box sx={{ mt: spacing.m30 }}>
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={onClick}>
            {polyglot.t('ReportsEmptyState.new')}
          </ButtonComponent>
        </Box>
      </Box>

      <IntroCardGroup cards={ReportsCards} sx={{ mt: 'auto' }} />
    </Box>
  );
};
