import { useCallback, useState } from 'react';

import { IconButton, Stack } from '@mui/material';
import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceImportAPI } from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.api';
import {
  AttendanceImportDto,
  AttendanceImportResultDto,
} from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { round2Digits } from '@v2/util/number.util';
import pako from 'pako';
import { useHistory } from 'react-router-dom';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { SiteEndpoints } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { useProfileFields } from '@/hooks/profile-fields.hook';
import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { nestErrorMessage } from '@/lib/errors';
import { StepperComponent } from '@/v2/components/stepper.component';
import { AbsenceImportAPI } from '@/v2/feature/absence/subfeatures/absence-import/absence-import.api';
import {
  AbsenceAdjustmentImportDto,
  AbsenceAdjustmentImportResultDto,
  AbsenceImportDto,
  AbsenceImportResultDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { DeviceImportAPI } from '@/v2/feature/device/features/devices-import/device-import.api';
import {
  DeviceImportDto,
  DevicesImportResultDto,
} from '@/v2/feature/device/features/devices-import/devices-import.dto';
import {
  EntityImportErrorDto,
  EntityImportResultDto,
  EntityImportValidationResultDto,
} from '@/v2/feature/entity-import/entity-import.dto';
import {
  EntityImportWizardData,
  EntityImportWizardSource,
} from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { finalisePeopleDataForImport } from '@/v2/feature/entity-import/wizard/entity-import-mapper.util';
import { reValidatePeopleImportResult } from '@/v2/feature/entity-import/wizard/entity-import-validator.util';
import { InUseByTypes } from '@/v2/feature/entity-import/wizard/import-wizard.interface';
import { DataDomainChoiceSection } from '@/v2/feature/entity-import/wizard/sections/data-domain-choice.section';
import { DataSourceChoiceSection } from '@/v2/feature/entity-import/wizard/sections/data-source-choice.section';
import { ImportCompleteSection } from '@/v2/feature/entity-import/wizard/sections/import-complete.section';
import { ImportResultsSection } from '@/v2/feature/entity-import/wizard/sections/import-results.section';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserImportAPI } from '@/v2/feature/user/features/user-import/user-import.api';
import {
  UserImport2Dto,
  UserImportDto,
  UserImportResult2Dto,
  UserImportResultDto,
} from '@/v2/feature/user/features/user-import/user-import.dto';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { ValidationError } from '@/v2/infrastructure/api-error/api-error.interface';
import { ddMMYYYYToIsoDateString } from '@/v2/infrastructure/date/date-format.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ImportWizardStepperSteps = ['Data', 'Source', 'Results', 'Complete'] as const;

export type ImportWizardStepperStep = typeof ImportWizardStepperSteps[number];

export const iconSize = { width: 14, height: 14 } as const;
const MAX_NON_BACKGROUND_PROCESSING = 10; // if more than this amount of records push process to queue
const MAX_NON_BACKGROUND_ATTENDANCES_PROCESSING = 100;
const MAX_NON_BACKGROUND_ABSENCES_PROCESSING = 100;
const MAX_NON_BACKGROUND_ABSENCE_ADJUSTMENTS_PROCESSING = 100;

export type ImportState = {
  domain?: EntityImportWizardData;
  source?: EntityImportWizardSource;
  result?:
    | EntityImportValidationResultDto<
        | UserImportDto
        | UserImport2Dto
        | AbsenceImportDto
        | DeviceImportDto
        | AttendanceImportDto
        | AbsenceAdjustmentImportDto
      >
    | EntityImportResultDto<
        | UserImportDto
        | UserImport2Dto
        | AbsenceImportDto
        | DeviceImportDto
        | AttendanceImportDto
        | AbsenceAdjustmentImportDto
      >;
};

function compressData(data: any[]): ArrayBuffer {
  const jsonStr = JSON.stringify(data);
  const input = new TextEncoder().encode(jsonStr);

  const compressed = pako.deflate(input);

  return compressed.buffer;
}

export const ImportWizardFlow = () => {
  const { polyglot } = usePolyglot();

  const [activeStep, setActiveStep] = useState<ImportWizardStepperStep>('Data');
  const [importState, setImportState] = useState<ImportState>();
  const { refreshCachedUsers, getCachedUsersByEmail } = useCachedUsers();
  const [loading, setLoading] = useState(false);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { data: companySettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const { data: attendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });
  const { data: sitesForCompany } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { getCustomFieldsForForm } = useProfileFields();
  const returnToImportSettingsPage = useCallback(() => {
    routerHistory.goBack();
  }, [routerHistory]);

  const isFirstStep = useCallback(() => {
    return ImportWizardStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep]);

  const handleNext = useCallback(
    (importComplete?: boolean) => {
      const currentStepIdx = ImportWizardStepperSteps.indexOf(activeStep);
      if (currentStepIdx >= ImportWizardStepperSteps.length - 1 || importComplete) {
        returnToImportSettingsPage();
        return;
      }
      setActiveStep(ImportWizardStepperSteps[currentStepIdx + 1]);
    },
    [activeStep, returnToImportSettingsPage]
  );

  const handleBack = useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIdx = ImportWizardStepperSteps.indexOf(currentStep);
      return ImportWizardStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, []);

  const importHasErrors = (
    result:
      | UserImportResultDto
      | AbsenceImportResultDto
      | DevicesImportResultDto
      | AttendanceImportResultDto
      | AbsenceAdjustmentImportResultDto
  ) => {
    const errorsFromImport = result.errors.flatMap((r) => r.errors);
    return errorsFromImport.length > 0;
  };

  const importResultHasOverlappingErrors = (
    result: AbsenceImportResultDto | AttendanceImportResultDto | AbsenceAdjustmentImportResultDto
  ) => {
    const OVERLAPPING_RECORD_ERROR_MESSAGE = polyglot.t('importResultHasOverlappingErrors.errorMessages.overlap');
    return [...new Set(result.errors.flatMap((e) => e.errors).flatMap((record) => record.constraints.taken))].every(
      (errorMessage: string) => errorMessage && errorMessage.includes(OVERLAPPING_RECORD_ERROR_MESSAGE)
    );
  };

  const getUniqueErrorsFromImportResult = (importErrors: ValidationError[]) => {
    return Array.from(
      new Set(
        Object.entries(importErrors.flatMap((e: { constraints: any }) => e.constraints))
          .map((e) => Object.values(e[1]))
          .flat()
      )
    );
  };

  const notifyImportResult = (
    importResult:
      | UserImportResultDto
      | UserImportResult2Dto
      | AbsenceImportResultDto
      | AbsenceAdjustmentImportResultDto
      | DevicesImportResultDto
      | AttendanceImportResultDto,
    overlappingAbsenceRecords = false
  ) => {
    const importSuffix = [];
    if (importResult.importedCount > 0)
      importSuffix.push(
        `${importResult.importedCount} ${importState?.domain} ${polyglot.t('notifyImportResult.imported')}`
      );
    if (importResult.updatedCount > 0)
      importSuffix.push(
        `${importResult.updatedCount} ${importState?.domain} ${polyglot.t('notifyImportResult.updated')}`
      );

    const errorsFromImport = importResult.errors.flatMap((r) => r.errors).filter((e) => Object.keys(e)?.length > 0);
    if (errorsFromImport && errorsFromImport.length === 0) {
      showMessage(`${polyglot.t('notifyImportResult.successMessages.import')} - ${importSuffix.join(', ')}`, 'success');
      return;
    }

    if (!overlappingAbsenceRecords) {
      const allUniqueErrors = getUniqueErrorsFromImportResult(errorsFromImport);
      const failureMessage =
        importResult.updatedCount > 0 || importResult.importedCount > 0
          ? `${polyglot.t('notifyImportResult.errorMessages.partially')}  - ${importSuffix.join(', ')} - ${polyglot.t(
              'notifyImportResult.errorMessages.otherRecords'
            )}: ${allUniqueErrors.join(', ')}`
          : `${polyglot.t('notifyImportResult.errorMessages.errorList')} - ${allUniqueErrors.join(', ')}`;
      showMessage(
        failureMessage,
        importResult.updatedCount > 0 || importResult.importedCount > 0 ? 'warning' : 'error'
      );
    } else {
      const allUniqueErrors = getUniqueErrorsFromImportResult(errorsFromImport);
      const failureMessage =
        importResult.updatedCount > 0 || importResult.importedCount > 0
          ? `${polyglot.t('notifyImportResult.errorMessages.partially')}  - ${importSuffix.join(', ')} - ${polyglot.t(
              'notifyImportResult.errorMessages.otherRecords'
            )}: ${allUniqueErrors.join(', ')}`
          : polyglot.t('notifyImportResult.errorMessages.overlappingRecords');
      showMessage(
        failureMessage,
        importResult.updatedCount > 0 || importResult.importedCount > 0 ? 'warning' : 'error'
      );
    }
  };

  const importPeopleCSV = async (selectionModel?: string[], users?: UserImport2Dto[]) => {
    if (!importState?.source) return;
    try {
      setLoading(true);
      if (users?.length) {
        const importResult = await UserImportAPI.importViaCsvV4(users, importState.source);
        notifyImportResult(importResult);
        setImportState({
          domain: importState?.domain,
          source: importState?.source,
          result: importResult,
        });
        await refreshCachedUsers();
        handleNext();
        return;
      }

      // if users is missing - AzureAD import - not implemented via importViaCsvV4
      const finalUserDataForImport = finalisePeopleDataForImport(
        importState,
        companySettings?.entities ?? [],
        attendanceSchedules ?? [],
        selectionModel ?? []
      );
      if (finalUserDataForImport.length > 0) {
        let importResult;
        if (finalUserDataForImport?.length <= MAX_NON_BACKGROUND_PROCESSING) {
          importResult = await UserImportAPI.importViaCsv(finalUserDataForImport);
        } else if (finalUserDataForImport?.length > MAX_NON_BACKGROUND_PROCESSING && importState?.source) {
          try {
            const compressedData = compressData(finalUserDataForImport);
            importResult = await UserImportAPI.importViaCsvV3(compressedData, importState?.source);
            if (!importResult) {
              showMessage(polyglot.t('importPeopleCSV.errorMessages.failure1'), 'error');
              return;
            } else {
              refreshCachedUsers();
              handleNext(true);
              showMessage(`${polyglot.t('importPeopleCSV.importInitiated')}.`, 'success');
            }
            return;
          } catch (error) {
            console.error(error);
            showMessage(polyglot.t('importPeopleCSV.errorMessages.failure2'), 'error');
            return;
          }
        }
        if (!importResult) {
          showMessage(polyglot.t('importPeopleCSV.errorMessages.failure2'), 'error');
          return;
        }
        notifyImportResult(importResult);
        if (!importHasErrors(importResult)) {
          setImportState({
            domain: importState?.domain,
            source: importState?.source,
            result: importResult,
          });
          refreshCachedUsers();
          handleNext();
        } else {
          // import attempt has errors, so set state for further corrections / retry
          notifyImportResult(importResult);
          const departments = (departmentsForCompany ?? []).map((dept) => ({ label: dept.name, value: dept.id }));
          const sites = (sitesForCompany ?? []).map((dept) => ({ label: dept.name, value: dept.id }));
          const revalidatedResult = await reValidatePeopleImportResult(importResult, departments, sites, polyglot);
          setImportState({
            domain: importState?.domain,
            source: importState?.source,
            result: revalidatedResult,
          });
        }
      } else {
        showMessage(polyglot.t('importPeopleCSV.warningMessages.noimport'), 'warning');
      }
    } catch (error) {
      showMessage(`${polyglot.t('importPeopleCSV.errorMessages.import')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const importAbsenceCSV = async () => {
    try {
      setLoading(true);
      const finalAbsenceDataForImport = importState?.result?.errors.some((e) => e.entity)
        ? (importState?.result?.errors as EntityImportErrorDto<AbsenceImportDto>[]).flatMap(
            (record: EntityImportErrorDto<AbsenceImportDto>): AbsenceImportDto => {
              const {
                firstName,
                lastName,
                workEmail,
                policyName,
                afternoonOnly,
                morningOnly,
                start,
                end,
                startHour,
                endHour,
                notes,
                status,
              } = record.entity;

              return {
                firstName,
                lastName,
                workEmail,
                afternoonOnly: afternoonOnly === 'Yes',
                morningOnly: morningOnly === 'Yes',
                policyName,
                start: ddMMYYYYToIsoDateString(start)!,
                end: end ? ddMMYYYYToIsoDateString(end) : null,
                startHour,
                startHourTimestamp: startHour ? new Date(startHour) : null,
                endHour,
                endHourTimestamp: endHour ? new Date(endHour) : null,
                notes,
                status: (status ? status : AbsenceStatus.Pending).toLowerCase(),
              };
            }
          )
        : [];

      if (
        finalAbsenceDataForImport.length > 0 &&
        finalAbsenceDataForImport.length <= MAX_NON_BACKGROUND_ABSENCES_PROCESSING
      ) {
        const importResult = await AbsenceImportAPI.importViaCsv(finalAbsenceDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else if (importResultHasOverlappingErrors(importResult)) {
          notifyImportResult(importResult, true);
          handleNext();
        } else {
          // import attempt has errors, so set state for further corrections / retry
          notifyImportResult(importResult);
        }

        setImportState({
          domain: importState?.domain,
          source: importState?.source,
          result: importResult,
        });
      } else if (finalAbsenceDataForImport.length > MAX_NON_BACKGROUND_ABSENCES_PROCESSING) {
        const compressedData = compressData(finalAbsenceDataForImport);
        const importResult = await AbsenceImportAPI.importViaCSVUsingQueue(compressedData);
        if (!importResult) {
          showMessage(polyglot.t('importAbsenceCSV.errorMessages.failure1'), 'error');
          return;
        } else {
          handleNext(true);
          showMessage(`${polyglot.t('importAbsenceCSV.importInitiated')}.`, 'success');
        }
      } else {
        showMessage(polyglot.t('importAbsenceCSV.warningMessages.noimport'), 'warning');
      }
    } catch (error) {
      showMessage(`${polyglot.t('importAbsenceCSV.errorMessages.import')} import: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const importAbsenceAdjustmentsCSV = async () => {
    try {
      setLoading(true);
      const finalAbsenceAdjustmentsDataForImport = importState?.result?.errors.some((e) => e.entity)
        ? (importState?.result?.errors as EntityImportErrorDto<AbsenceAdjustmentImportDto>[]).flatMap(
            (record: EntityImportErrorDto<AbsenceAdjustmentImportDto>): AbsenceAdjustmentImportDto => {
              const {
                id,
                firstName,
                lastName,
                workEmail,
                policyName,
                effectiveYear,
                adjustment,
                unit,
                note,
              } = record.entity;

              return {
                id,
                firstName,
                lastName,
                workEmail: workEmail.toLowerCase(),
                policyName,
                effectiveYear: Math.round(Number(effectiveYear)),
                adjustment: round2Digits(Number(adjustment)) as number,
                unit,
                note,
              };
            }
          )
        : [];

      if (
        finalAbsenceAdjustmentsDataForImport.length > 0 &&
        finalAbsenceAdjustmentsDataForImport.length <= MAX_NON_BACKGROUND_ABSENCE_ADJUSTMENTS_PROCESSING
      ) {
        const importResult = await AbsenceImportAPI.importAdjustmentsViaCsv(finalAbsenceAdjustmentsDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else if (importResultHasOverlappingErrors(importResult)) {
          notifyImportResult(importResult, true);
          handleNext();
        } else {
          // import attempt has errors, so set state for further corrections / retry
          notifyImportResult(importResult);
        }

        setImportState({
          domain: importState?.domain,
          source: importState?.source,
          result: importResult,
        });
      } else if (finalAbsenceAdjustmentsDataForImport.length > MAX_NON_BACKGROUND_ABSENCE_ADJUSTMENTS_PROCESSING) {
        const compressedData = compressData(finalAbsenceAdjustmentsDataForImport);
        const importResult = await AbsenceImportAPI.importAdjustmentsViaCSVUsingQueue(compressedData);
        if (!importResult) {
          showMessage(polyglot.t('importAbsenceCSV.errorMessages.failure1'), 'error');
          return;
        } else {
          handleNext(true);
          showMessage(`${polyglot.t('importAbsenceCSV.importInitiated')}.`, 'success');
        }
      } else {
        showMessage(polyglot.t('importAbsenceCSV.warningMessages.noimport'), 'warning');
      }
    } catch (error) {
      showMessage(`${polyglot.t('importAbsenceCSV.errorMessages.import')} import: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getPossessionIdBasedOnInUseType = (
    record: EntityImportErrorDto<DeviceImportDto>,
    currentUsers: readonly CachedUser[]
  ) => {
    if (record.entity.inUseBy === InUseByTypes.Email && currentUsers.length > 0)
      return currentUsers.find((u) => u.emailAddress === record.entity.workEmail)?.userId;
    if (
      record.entity.inUseBy === InUseByTypes.Site &&
      sitesForCompany &&
      sitesForCompany.length > 0 &&
      record.entity.site
    )
      return sitesForCompany?.find((s) => s.name?.toLowerCase() === record.entity.site?.toLowerCase())?.id;
    return undefined;
  };

  const importDeviceCSV = async () => {
    try {
      setLoading(true);
      const usersForAssignment =
        importState && importState.result && importState.result.errors
          ? (importState.result.errors
              .filter((e) => (e.entity as DeviceImportDto).inUseBy === InUseByTypes.Email)
              .map((e) => (e.entity as DeviceImportDto).workEmail)
              .filter(Boolean) as string[])
          : [];
      const currentUsers =
        usersForAssignment && usersForAssignment.length > 0 ? getCachedUsersByEmail(usersForAssignment) : [];
      const finalDeviceDataForImport = importState?.result?.errors.some((e) => e.entity)
        ? (importState?.result?.errors as EntityImportErrorDto<DeviceImportDto>[]).flatMap(
            (record: EntityImportErrorDto<DeviceImportDto>) => {
              return {
                ...record.entity,
                possessionId: getPossessionIdBasedOnInUseType(record, currentUsers),
              };
            }
          )
        : [];
      if (finalDeviceDataForImport.length > 0) {
        const importResult = await DeviceImportAPI.importViaCsv(finalDeviceDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else {
          // import attempt has errors, so set state for further corrections / retry
          notifyImportResult(importResult);
        }
        setImportState({
          domain: importState?.domain,
          source: importState?.source,
          result: importResult,
        });
      } else {
        showMessage(polyglot.t('importDeviceCSV.warningMessages.noimport'), 'warning');
      }
    } catch (error) {
      showMessage(`${polyglot.t('importDeviceCSV.errorMessages.import')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const importAttendanceCSV = async () => {
    try {
      setLoading(true);
      const finalAttendanceDataForImport = importState?.result?.errors.some((e) => e.entity)
        ? (importState?.result?.errors as EntityImportErrorDto<AttendanceImportDto>[]).flatMap(
            (record: EntityImportErrorDto<AttendanceImportDto>): AttendanceImportDto => {
              const { firstName, lastName, workEmail, attendanceType, logDate, start, end } = record.entity;
              return {
                firstName,
                lastName,
                workEmail,
                attendanceType,
                logDate,
                start,
                end,
              };
            }
          )
        : [];

      if (
        finalAttendanceDataForImport.length > 0 &&
        finalAttendanceDataForImport.length < MAX_NON_BACKGROUND_ATTENDANCES_PROCESSING
      ) {
        const importResult = await AttendanceImportAPI.importViaCSVInstant(finalAttendanceDataForImport);
        if (!importHasErrors(importResult)) {
          notifyImportResult(importResult);
          handleNext();
        } else if (importResultHasOverlappingErrors(importResult)) {
          notifyImportResult(importResult, true);
          handleNext();
        } else {
          // import attempt has errors, so set state for further corrections / retry
          notifyImportResult(importResult);
        }

        setImportState({
          domain: importState?.domain,
          source: importState?.source,
          result: importResult,
        });
      } else if (finalAttendanceDataForImport.length >= MAX_NON_BACKGROUND_ATTENDANCES_PROCESSING) {
        const compressedData = compressData(finalAttendanceDataForImport);
        const importResult = await AttendanceImportAPI.importViaCSVUsingQueue(compressedData);
        if (!importResult) {
          showMessage(polyglot.t('importAttendanceCSV.errorMessages.failure1'), 'error');
          return;
        } else {
          handleNext(true);
          showMessage(`${polyglot.t('importAttendanceCSV.importInitiated')}.`, 'success');
        }
      } else {
        showMessage(polyglot.t('importAttendanceCSV.warningMessages.noimport'), 'warning');
      }
    } catch (error) {
      showMessage(`${polyglot.t('importAttendanceCSV.errorMessages.import')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const completeImportHandler = async (selectionModel?: string[], users?: UserImport2Dto[]) => {
    if (importState?.domain === EntityImportWizardData.Users && importState?.source === EntityImportWizardSource.CSV) {
      await importPeopleCSV(selectionModel, users); // this is not done anymore via the queue
    } else if (
      importState?.domain === EntityImportWizardData.Absences &&
      importState?.source === EntityImportWizardSource.CSV
    ) {
      await importAbsenceCSV();
    } else if (
      importState?.domain === EntityImportWizardData.AbsenceAdjustments &&
      importState?.source === EntityImportWizardSource.CSV
    ) {
      await importAbsenceAdjustmentsCSV();
    } else if (
      importState?.domain === EntityImportWizardData.Devices &&
      importState?.source === EntityImportWizardSource.CSV
    ) {
      await importDeviceCSV();
    } else if (
      importState?.domain === EntityImportWizardData.Attendances &&
      importState?.source === EntityImportWizardSource.CSV
    ) {
      await importAttendanceCSV();
    } else if (
      importState?.domain === EntityImportWizardData.Users &&
      importState?.source === EntityImportWizardSource.AzureAD
    ) {
      // TODO - need to move this to a separate flow in the background, rather than foreground processing
      await importPeopleCSV(selectionModel);
    } else {
      showMessage(
        `${polyglot.t('completeImportHandler.warningMessages.domainNotConfigured')}: ${importState?.domain} - ${
          importState?.source
        } - ${polyglot.t('completeImportHandler.warningMessages.checkLater')}`,
        'warning'
      );
    }
  };

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <Stack sx={{ flexFlow: 'row', width: '70%', mx: 'auto' }}>
        {!isFirstStep() && (
          <IconButton sx={{ ...iconButtonSx, flex: 0, position: 'absolute', left: spacing.m50 }} onClick={handleBack}>
            <Back {...iconSize} />
          </IconButton>
        )}
        <StepperComponent sx={{ flex: 1 }} steps={ImportWizardStepperSteps} activeStep={activeStep} />
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', right: spacing.m50 }}
          onClick={() => returnToImportSettingsPage()}
        >
          <Close {...iconSize} stroke={themeColors.DarkGrey} />
        </IconButton>
      </Stack>
      <Stack sx={{ overflow: 'hidden auto', pb: spacing.p30 }}>
        {{
          Data: () => (
            <DataDomainChoiceSection
              importState={importState}
              onNext={(domain: EntityImportWizardData) => {
                if (domain) {
                  if (
                    [
                      EntityImportWizardData.Absences,
                      EntityImportWizardData.AbsenceAdjustments,
                      EntityImportWizardData.Devices,
                      EntityImportWizardData.Attendances,
                    ].includes(domain)
                  ) {
                    // if Import domain is Absences or Devices, default source to CSV, as there is no other flow available
                    setImportState({ domain, source: EntityImportWizardSource.CSV });
                  } else {
                    setImportState({ domain });
                  }
                }
                handleNext();
              }}
              sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
            />
          ),
          Source: () =>
            importState && (
              <DataSourceChoiceSection
                sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
                importState={importState}
                onNext={function (source, result): void {
                  setImportState({
                    domain: importState.domain,
                    source,
                    result,
                  });
                  handleNext();
                }}
                entities={companySettings?.entities ?? []}
                sites={sitesForCompany ?? []}
                departments={departmentsForCompany ?? []}
                attendanceSchedules={attendanceSchedules ?? []}
                getCustomFieldsForForm={getCustomFieldsForForm}
              />
            ),
          Results: () =>
            importState && (
              <ImportResultsSection
                sx={{ width: '70%', mx: 'auto', mt: spacing.mt40 }}
                importState={importState}
                onUpdateAndRevalidate={(result) => {
                  setImportState({
                    domain: importState.domain,
                    source: importState.source,
                    result,
                  });
                  const remainingErrors = result.errors.flatMap((r) => r.errors);
                  if (remainingErrors.length > 0)
                    showMessage('Successfully updated record and re-validated', 'success');
                }}
                completeImport={completeImportHandler}
                loading={loading}
                setLoading={setLoading}
                attendanceSchedules={attendanceSchedules ?? []}
              />
            ),
          Complete: () =>
            importState && (
              <ImportCompleteSection
                importState={importState}
                onNext={handleNext}
                sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}
              />
            ),
        }[activeStep]()}
      </Stack>
    </Stack>
  );
};
