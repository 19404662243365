import { useCallback, useContext } from 'react';
import { GlobalContext } from '@/GlobalState';
import { checkScopes } from '@/lib/scopes';
import { Scope, ScopeContext } from '@/models';

const useScopes = () => {
  // TODO this fails on register/login flow because Context is not defined at that point.
  // Should fix it by introducing GlobalState check before Register flow
  const context = useContext(GlobalContext);
  const state = context ? context[0] : { user: undefined };

  const getScopesContext = useCallback(
    (targetUser?: { userId: number }): ScopeContext => ({
      userId: targetUser?.userId ?? -1,
    }),
    []
  );

  const hasScopes = useCallback(
    (scopes: readonly Scope[], context?: ScopeContext): boolean =>
      state.user ? checkScopes(state.user, scopes, context) : false,
    [state.user]
  );

  return {
    hasScopes,
    getScopesContext,
  };
};

export default useScopes;
