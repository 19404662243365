import React, { useEffect } from 'react';

import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBalancesTable } from '@v2/feature/absence/components/absence-balances-table.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';

import { Typography } from '@/v2/components/typography/typography.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';

interface Props {
  readonly absencePolicies: readonly AbsencePolicyDto[];
}

export const AbsenceCompanyBalancesPage = ({ absencePolicies }: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Time Company balances page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('AbsenceCompanyBalancesPage.balances')}</Typography>}
      />
      <ContentWrapper loading={false}>
        <AbsenceBalancesTable absencePolicies={absencePolicies} view="company" />
      </ContentWrapper>
    </RootStyle>
  );
};
