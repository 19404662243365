import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SelectComponent } from '@v2/components/forms/select.component';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useScopes from '@/hooks/scopes.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { spacing } from '@/v2/styles/spacing.styles';

const getApprovalRulesOptions = (approvalRules: readonly { id: number; name: string }[]) =>
  approvalRules.map((rule) => ({
    label: rule.name,
    value: rule.id,
  }));

type ApprovalPageProps = {
  readonly initialValues: number;
  readonly onNext: (approvalRuleId: number) => void;
  readonly approvalRules: ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
};

export const ApprovalPage = ({ initialValues, onNext, approvalRules, refreshApprovalRules }: ApprovalPageProps) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();

  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);

  const [approvalRule, setApprovalRule] = useState<number | ''>(initialValues !== 0 ? initialValues : '');

  return (
    <Stack sx={{ flex: 1, alignItems: 'center', width: '100%' }}>
      <Stack sx={{ width: '450px', gap: spacing.g30 }}>
        <Typography variant="title2">{polyglot.t('ApprovalPage.approval')}</Typography>
        <Typography variant="caption">{polyglot.t('ApprovalPage.approvalDesc')}</Typography>

        <SelectComponent
          name="approvalRule"
          label={polyglot.t('ApprovalRuleModule.approvalRule')}
          options={getApprovalRulesOptions(approvalRules ?? [])}
          value={approvalRule}
          onChange={(e) => {
            const ruleId = e.target.value ? Number(e.target.value) : '';
            setApprovalRule(ruleId);
          }}
          editList={{
            isHidden: !hasScopes(['company.settings:all']),
            handler: () => {
              setIsApprovalDrawerOpen(true);
            },
          }}
        />

        <ApprovalRuleDrawer
          isOpen={isApprovalDrawerOpen}
          setIsOpen={setIsApprovalDrawerOpen}
          approvalRule={null}
          afterClose={() => {
            setIsApprovalDrawerOpen(false);
          }}
          refresh={async () => {
            if (refreshApprovalRules) await refreshApprovalRules();
          }}
        />

        <Box sx={{ mt: spacing.mt40 }}>
          <ButtonComponent
            sizeVariant="large"
            colorVariant="primary"
            type="button"
            fullWidth
            onClick={() => {
              if (approvalRule) onNext(approvalRule);
            }}
            disabled={!approvalRule}
          >
            {polyglot.t('General.continue')}
          </ButtonComponent>
        </Box>
      </Stack>
    </Stack>
  );
};
