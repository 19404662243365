import { useContext, useState } from 'react';

import { Box, Button, SxProps } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { CompanyAPI } from '@/api-client/company.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LOGIN_ROUTE } from '@/lib/routes';
import { ConfirmModal } from '@/v2/components/confirm-modal.component';
import { dangerLargeBtn } from '@/v2/styles/buttons.styles';

export const CompanyDeleteComponent = ({ boxSx = {} }: { boxSx?: SxProps }): JSX.Element | null => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();

  const [showMessage] = useMessage();

  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [state] = useContext(GlobalContext);
  const showButton =
    state.showHiddenFeatures ||
    state.user.emailAddress.endsWith('@rubikdata.io') ||
    state.user.emailAddress.endsWith('@zelt.app');

  async function deleteCompanyData() {
    try {
      await CompanyAPI.deleteOwnCompany();
      routerHistory.push(LOGIN_ROUTE);
    } catch (error) {
      showMessage(`${nestErrorMessage(error)}`, 'error');
    }
  }

  const openRemovalModal = () => {
    setIsRemovalModalOpen(true);
  };

  const closeRemovalModal = () => {
    setIsRemovalModalOpen(false);
  };

  return showButton ? (
    <Box sx={boxSx}>
      <Button sx={{ ...dangerLargeBtn }} onClick={openRemovalModal}>
        {polyglot.t('General.delete')}
      </Button>
      <ConfirmModal
        isOpen={isRemovalModalOpen}
        onClose={closeRemovalModal}
        takeAction={deleteCompanyData}
        title={polyglot.t('General.delete')}
        message={polyglot.t('CompanyDeleteComponent.confirmDelete')}
        callToAction={polyglot.t('CompanyDeleteComponent.callToAction')}
        type="removal"
      />
    </Box>
  ) : null;
};
