import { Fragment } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ScheduleTimeEntry, WeekDay } from '@v2/feature/attendance/attendance.interface';
import { WidgetLayout } from '@v2/feature/dashboard/features/components/widget-layout.component';
import { WidgetInfoMessage } from '@v2/feature/dashboard/features/sections/user-shift/user-shift.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { ATTENDANCE_ME_REQUESTS_ROUTE } from '@/lib/routes';

interface ComponentProps {
  clockInEarlyCapMinutes: number;
  theFollowingScheduledDay: {
    day: WeekDay;
    daySchedule: ScheduleTimeEntry;
  } | null;
  isStarted: boolean;
  hasStartedBreak: boolean;
  isEnded: boolean;
  isStartingShift: boolean;
  isFetchingLocation: boolean;
  lessThanEarlyCapMinutesUntilStart: boolean;
  isStartingBreak: boolean;
  startShift: () => Promise<void>;
  startBreak: () => Promise<void>;
  endShift: () => Promise<void>;
  endBreak: () => Promise<void>;
  isEndingBreak: boolean;
  isEndingShift: boolean;
  time: string;
  shiftLoggedHours: string[];
  relevantDate: Date;
  readOnly: boolean;
  shiftOrScheduledShift: boolean;
}

export const UserShiftHandlerWidgetContent = ({
  clockInEarlyCapMinutes,
  relevantDate,
  readOnly,
  shiftOrScheduledShift,
  theFollowingScheduledDay,
  isStarted,
  hasStartedBreak,
  isEnded,
  time,
  shiftLoggedHours,
  isStartingShift,
  isFetchingLocation,
  lessThanEarlyCapMinutesUntilStart,
  isStartingBreak,
  startShift,
  startBreak,
  endShift,
  endBreak,
  isEndingBreak,
  isEndingShift,
}: ComponentProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  return (
    <WidgetLayout readOnly={readOnly} size="small">
      <Fragment>
        <Box
          sx={{
            '&:hover': { background: themeColors.transparency },
            borderRadius: radius.br10,
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => routerHistory.push(ATTENDANCE_ME_REQUESTS_ROUTE)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: spacing.p5,
            }}
          >
            <Typography variant="title2">{polyglot.t('AttendanceDomain.AttendanceShift.Shift')}</Typography>
            <Typography variant="captionSmall">{format(relevantDate, 'EEEE dd')}</Typography>
          </div>
        </Box>

        {shiftOrScheduledShift ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <WidgetInfoMessage
              isScheduledForToday={shiftOrScheduledShift}
              scheduleDay={theFollowingScheduledDay}
              isStarted={isStarted}
              hasStartedBreak={hasStartedBreak}
              time={time}
              isEnded={isEnded}
              clockInEarlyCapMinutes={clockInEarlyCapMinutes}
            />
            <div>
              <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                Schedule
              </Typography>
              <div style={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
                {shiftLoggedHours && (
                  <Typography variant="caption" sx={{ minWidth: '70px', textAlign: 'start' }}>
                    {shiftLoggedHours[0]}
                  </Typography>
                )}
                {shiftLoggedHours && (
                  <Typography variant="caption" sx={{ minWidth: '70px', textAlign: 'start' }}>
                    {shiftLoggedHours[1]}
                  </Typography>
                )}
                {shiftLoggedHours && (
                  <Typography variant="caption" sx={{ minWidth: '70px', textAlign: 'start' }}>
                    {shiftLoggedHours[2]}
                  </Typography>
                )}
              </div>
            </div>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'space-between',
                gap: spacing.g20,
              }}
            >
              {!isEnded && (
                <Box sx={{ display: 'flex', gap: spacing.g5, mt: spacing.m5, width: '100%' }}>
                  {!isStarted && (
                    <LoaderButton
                      name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Start')}
                      loading={isStartingShift || isFetchingLocation}
                      type="button"
                      fullWidth
                      onClick={startShift}
                      disabled={!lessThanEarlyCapMinutesUntilStart}
                      colorVariant="primary"
                      sizeVariant="medium"
                    />
                  )}

                  {isStarted && !hasStartedBreak && (
                    <LoaderButton
                      name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Break')}
                      loading={isStartingBreak || isFetchingLocation}
                      type="button"
                      fullWidth
                      onClick={startBreak}
                      colorVariant="secondary"
                      sizeVariant="medium"
                    />
                  )}
                  {isStarted && !hasStartedBreak && (
                    <LoaderButton
                      name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Finish')}
                      loading={isEndingShift || isFetchingLocation}
                      type="button"
                      fullWidth
                      onClick={endShift}
                      colorVariant="danger"
                      sizeVariant="medium"
                    />
                  )}

                  {isStarted && hasStartedBreak && (
                    <LoaderButton
                      name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.EndBreak')}
                      loading={isEndingBreak || isFetchingLocation}
                      type="button"
                      fullWidth
                      onClick={endBreak}
                      colorVariant="secondary"
                      sizeVariant="medium"
                    />
                  )}
                </Box>
              )}
            </Box>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              verticalAlign: 'center',
              height: '100%',
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: themeColors.Grey,
              }}
            >
              {polyglot.t('AttendanceDomain.AttendanceShift.NotScheduled')}
            </Typography>
          </div>
        )}
      </Fragment>
    </WidgetLayout>
  );
};
