import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { ScheduleSettingsFormData } from '@v2/feature/attendance/attendance.interface';
import { getScheduleSettingsValidationSchema } from '@v2/feature/attendance/attendance.util';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
}

export const AttendanceScheduleEditScheduleSettingsDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AttendanceScheduleEditPayrollDrawerContent
        attendanceSchedule={attendanceSchedule}
        refresh={refresh}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AttendanceScheduleEditPayrollDrawerContent = ({
  attendanceSchedule,
  refresh,
  isOpen,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: ScheduleSettingsFormData) => {
      try {
        const update: ScheduleSettingsFormData = {
          isFlexible: values.isFlexible,
          fteEquivalent: Number(values.fteEquivalent),
          fteEquivalentInDays: Number(values.fteEquivalentInDays),
        };
        setLoading(true);
        await AttendanceAPI.updateAttendanceScheduleSettings(attendanceSchedule.id, update);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AttendanceScheduleEditSettingsDrawer.errorMessages.update', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [attendanceSchedule.id, showMessage, refresh, setIsOpen, polyglot]
  );

  const formik = useFormik<ScheduleSettingsFormData>({
    initialValues: {
      isFlexible: attendanceSchedule.isFlexible,
      fteEquivalent: attendanceSchedule.fteEquivalent,
      fteEquivalentInDays: attendanceSchedule.fteEquivalentInDays,
    },
    validationSchema: getScheduleSettingsValidationSchema(polyglot),
    onSubmit,
  });

  useEffect(() => {
    if (isOpen) formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('AttendanceModule.schedule')}</Typography>

        <Box sx={{ ...fieldSx }}>
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              name="is-flexible"
              onChange={(event) => {
                formik.setFieldValue('isFlexible', event.target.value === 'true');
              }}
            >
              <FormControlLabel
                key="defined-hours"
                labelPlacement="end"
                value={'false'}
                checked={!formik.values.isFlexible}
                control={<StyledRadio />}
                label={
                  <Box>
                    <Typography variant={!formik.values.isFlexible ? 'title4' : 'caption'}>
                      {polyglot.t('ScheduleScheduleForm.definedHours')}
                    </Typography>
                    <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                      {polyglot.t('ScheduleScheduleForm.definedHoursDesc')}
                    </Typography>
                  </Box>
                }
                sx={{ mb: '20px' }}
              />
              <FormControlLabel
                key="flexible-hours"
                labelPlacement="end"
                value={'true'}
                checked={formik.values.isFlexible}
                control={<StyledRadio />}
                label={
                  <Box>
                    <Typography variant={formik.values.isFlexible ? 'title4' : 'caption'}>
                      {polyglot.t('ScheduleScheduleForm.flexibleHours')}
                    </Typography>
                    <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                      {polyglot.t('ScheduleScheduleForm.flexibleHoursDesc')}
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography variant="title4">{polyglot.t('AttendanceModule.fteEquivalent')}</Typography>
          <Typography variant="caption">{polyglot.t('AttendanceModule.fteEquivalentDesc')}</Typography>

          <Box sx={{ ...fieldSx, display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Typography variant="title4" sx={{ width: '40%' }}>
              {polyglot.t('EditFTEEquivalentDrawer.FullTimeDays')}
            </Typography>
            <TextfieldComponent
              sx={{ width: '50%' }}
              label={polyglot.t('TimeUnitTypes.days')}
              name="fteEquivalentInDays"
              value={formik.values.fteEquivalentInDays}
              onChange={formik.handleChange}
              error={formik.touched.fteEquivalentInDays && !!formik.errors.fteEquivalentInDays}
              helperText={
                ((formik.touched.fteEquivalentInDays && formik.errors.fteEquivalentInDays) as string) ?? (' ' as string)
              }
              endAdornment="none"
            />
          </Box>

          <Box sx={{ ...fieldSx, display: 'flex', gap: '10px', alignItems: 'center', mt: '10px' }}>
            <Typography variant="title4" sx={{ width: '40%' }}>
              {polyglot.t('EditFTEEquivalentDrawer.FullTimeHours')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px', width: '50%' }}>
              <TextfieldComponent
                name="hours"
                label={polyglot.t('TimeUnitTypes.hours')}
                value={Math.floor(formik.values.fteEquivalent / 60)}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  const minutes = formik.values.fteEquivalent % 60;
                  const fteEq = Number(value) * 60 + minutes;
                  formik.setFieldValue('fteEquivalent', fteEq);
                }}
              />
              <TextfieldComponent
                name="minutes"
                label={polyglot.t('TimeUnitTypes.minutes')}
                value={formik.values.fteEquivalent % 60}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  const hours = Math.floor(formik.values.fteEquivalent / 60);
                  const fteEq = hours * 60 + Math.min(Number(value), 59);
                  formik.setFieldValue('fteEquivalent', fteEq);
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="primary" loading={loading} fullWidth>
            {polyglot.t('General.save')}
          </ButtonComponent>
        </Box>
      </Form>
    </FormikProvider>
  );
};
