import { useCallback, useContext, useEffect, useState } from 'react';

import { Analytics, AnalyticsBrowser } from '@june-so/analytics-next';

import { GlobalContext } from '@/GlobalState';
import { CurrentUser } from '@/models';
import { isProduction } from '@/v2/infrastructure/firebase/identity-platform.util';
import { getDisplayName } from '@/v2/util/string-format.util';
import { useLocalStorage } from '@/v2/util/use-local-storage.util';

const ONE_HOUR_IN_MS = 3600000;

export type JUNE_ID_USER_PROPS = { user: CurrentUser; onAuth?: boolean };

// TODO: need to start tracking these user profile actions from the frontend
export enum JUNE_ACTIONS {
  // PENDING TRACKED - below
  // User Profile actions
  ABOUT_FORM_CANCEL = 'About.form.cancel.editing',
  ABOUT_FORM_EDIT = 'About.form.edit',
  ABOUT_FORM_SAVE = 'About.form.save',

  ADDRESS_FORM_CANCEL = 'Address.form.cancel.editing',
  ADDRESS_FORM_EDIT = 'Address.form.edit',
  ADDRESS_FORM_SAVE = 'Address.form.save',

  BANK_ACCOUNT_FORM_CANCEL = 'Bank account.form.cancel.editing',
  BANK_ACCOUNT_FORM_EDIT = 'Bank account.form.edit',
  BANK_ACCOUNT_FORM_SAVE = 'Bank account.form.save',

  BASIC_INFORMATION_FORM_CANCEL = 'Basic information.form.cancel.editing',
  BASIC_INFORMATION_FORM_EDIT = 'Basic information.form.edit',
  BASIC_INFORMATION_FORM_SAVE = 'Basic information.form.save',

  COMPENSATION_FORM_CANCEL = 'Compensation.form.cancel.editing',
  COMPENSATION_FORM_EDIT = 'Compensation.form.edit',

  CONTRACT_FORM_CANCEL = 'Contract.form.cancel.editing',
  CONTRACT_FORM_EDIT = 'Contract.form.edit',

  EMERGENCY_CONTACT_FORM_CANCEL = 'Emergency contact.form.cancel.editing',
  EMERGENCY_CONTACT_FORM_EDIT = 'Emergency contact.form.edit',
  EMERGENCY_CONTACT_FORM_SAVE = 'Emergency contact.form.save',

  EQUITY_FORM_CANCEL = 'Equity.form.cancel.editing',
  EQUITY_FORM_EDIT = 'Equity.form.edit',

  FAMILY_FORM_CANCEL = 'Family.form.cancel.editing',
  FAMILY_FORM_EDIT = 'Family.form.edit',
  FAMILY_FORM_SAVE = 'Family.form.save',

  IDENTIFICATION_FORM_CANCEL = 'Identification.form.cancel.editing',
  IDENTIFICATION_FORM_EDIT = 'Identification.form.edit',
  IDENTIFICATION_FORM_SAVE = 'Identification.form.save',

  LIFECYCLE_FORM_CANCEL = 'Lifecycle.form.cancel.editing',
  LIFECYCLE_FORM_EDIT = 'Lifecycle.form.edit',
  LIFECYCLE_FORM_SAVE = 'Lifecycle.form.save',

  PERSONAL_CONTACT_DETAILS_FORM_CANCEL = 'Personal contact details.form.cancel.editing',
  PERSONAL_CONTACT_DETAILS_FORM_EDIT = 'Personal contact details.form.edit',
  PERSONAL_CONTACT_DETAILS_FORM_SAVE = 'Personal contact details.form.save',

  PERSONAL_INFORMATION_FORM_CANCEL = 'Personal information.form.cancel.editing',
  PERSONAL_INFORMATION_FORM_EDIT = 'Personal information.form.edit',
  PERSONAL_INFORMATION_FORM_SAVE = 'Personal information.form.save',

  RIGHT_TO_WORK_FORM_CANCEL = 'Right to work.form.cancel.editing',
  RIGHT_TO_WORK_FORM_EDIT = 'Right to work.form.edit',
  RIGHT_TO_WORK_FORM_SAVE = 'Right to work.form.save',

  ROLE_FORM_CANCEL = 'Role.form.cancel.editing',
  ROLE_FORM_EDIT = 'Role.form.edit',

  WORK_CONTACT_DETAILS_FORM_CANCEL = 'Work contact details.form.cancel.editing',
  WORK_CONTACT_DETAILS_FORM_EDIT = 'Work contact details.form.edit',
  WORK_CONTACT_DETAILS_FORM_SAVE = 'Work contact details.form.save',

  // CURRENTLY TRACKED
  USED_DOCUMENT_TEMPLATE = 'Used document template',

  CREATED_BULK_PAYMENT_VIA_EXPORT = 'Created bulk payment via export',
  CREATED_BULK_PAYMENT_VIA_OPEN_BANKING = 'Created bulk payment via open banking',
}

let analyticsInstance: Analytics | undefined;

export interface JunePayload {
  userId: number;
  event: string;
  properties?: Record<string, unknown>;
}

export function useJune(): {
  identifyUser: (props: JUNE_ID_USER_PROPS) => void;
  trackPage: (page: string) => void;
  trackEvent: (event: JunePayload) => void;
} {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(analyticsInstance);
  const [state] = useContext(GlobalContext);
  const currentUser = state?.user;
  // Local state to store the last identify call timestamp
  const [lastIdentifyTimestamp, setLastIdentifyTimestamp] = useLocalStorage<number | null>(
    `${currentUser.userId}-lastIdentify`,
    null
  );

  useEffect(() => {
    if (!analyticsInstance) {
      const loadAnalytics = async () => {
        try {
          const response = await AnalyticsBrowser.load({
            writeKey: '4Jzfw1BKbsGbhTHB',
          });
          analyticsInstance = response[0];
          setAnalytics(analyticsInstance);
        } catch (error) {
          console.error('Error loading analytics:', error);
        }
      };
      loadAnalytics();
    }
  }, []);

  const identifyUser = useCallback(
    (props: JUNE_ID_USER_PROPS): void => {
      const { user, onAuth = false } = props;
      if (analytics && isProduction) {
        const currentTime = Date.now();

        // Check if it has been more than 1 hour since the last identify call or on auth action (login / logout)
        if (onAuth || lastIdentifyTimestamp === null || currentTime - lastIdentifyTimestamp >= ONE_HOUR_IN_MS) {
          analytics.group({
            userId: user.userId,
            groupId: user.company.companyId,
            traits: {
              name: user.company.name,
              avatar: user.company?.avatar || null,
            },
          });
          analytics.identify({
            userId: user.userId,
            context: { groupId: user.company.companyId },
            traits: {
              email: user?.emailAddress,
              name: getDisplayName(user),
              companyId: user.company.companyId,
              companyName: user.company?.name,
              firstName: user.firstName,
              lastName: user.lastName,
              isAdmin: user?.accountType?.Admin ? user?.accountType?.Admin === true : false,
              planIsPeoplePro: user?.currentPlans?.PEOPLE?.pro ? user?.currentPlans?.PEOPLE.pro === true : false,
              planIsMoneyPro: user?.currentPlans?.MONEY?.pro ? user?.currentPlans?.MONEY.pro === true : false,
              planIsDevicesPro: user?.currentPlans?.DEVICES?.pro ? user?.currentPlans?.DEVICES.pro === true : false,
              planIsAppsPro: user?.currentPlans?.APPS?.pro ? user?.currentPlans?.APPS.pro === true : false,
              lastTimeOnlineInZelt: new Date().toUTCString(),
              lastDateOnlineInZelt: new Date().toISOString().split('T')[0],
            },
          });
          setLastIdentifyTimestamp(currentTime);
        }
      }
    },
    [analytics, lastIdentifyTimestamp, setLastIdentifyTimestamp]
  );

  const trackPage = useCallback(
    (page: string) => {
      if (analytics && isProduction) {
        const currentTime = Date.now();

        // Check if it has been more than 1 hour since the last identify call
        if (lastIdentifyTimestamp === null || currentTime - lastIdentifyTimestamp >= ONE_HOUR_IN_MS) {
          // Make the identify call before tracking the page
          identifyUser({ user: currentUser });
        }

        analytics.page(page);
      }
    },
    [analytics, currentUser, identifyUser, lastIdentifyTimestamp]
  );

  const trackEvent = useCallback(
    (payload: JunePayload) => {
      if (analytics && isProduction) {
        analytics.track(payload.event, payload.properties);
      }
    },
    [analytics]
  );

  return { identifyUser, trackPage, trackEvent };
}
