import React from 'react';

import { Box, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface EnableSSODisableMFAConflictConfirmDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
}

export const EnableSSODisableMFAConflictConfirmDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  onConfirm,
}: EnableSSODisableMFAConflictConfirmDrawerProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <Typography sx={titleSx}>{polyglot.t('EnableSSODisableMFAConflictConfirmDrawer.enablesso')}</Typography>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.m15 }}>
          {polyglot.t('EnableSSODisableMFAConflictConfirmDrawer.switchssonote')}
        </Typography>

        <Box sx={{ mt: 3 }}>
          <ButtonComponent
            fullWidth
            onClick={() => {
              onConfirm();
              onClose();
            }}
            sizeVariant="medium"
            colorVariant="primary"
          >
            {polyglot.t('EnableSSODisableMFAConflictConfirmDrawer.continue')}
          </ButtonComponent>
        </Box>
      </>
    </DrawerModal>
  );
};
