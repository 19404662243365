import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto, DevicePossessionDto, ExternalUserCredentialsDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType } from '@v2/feature/device/device.interface';
import { primarySmallBtn, secondarySmallBtn } from '@v2/styles/buttons.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';

interface DeviceActivationStepCredentialsProps {
  readonly deviceOrder: DeviceOrderDto;
  readonly goToNextStep: () => void;
}

export const DeviceActivationStepCredentials = ({
  deviceOrder,
  goToNextStep,
}: DeviceActivationStepCredentialsProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isUserOwner, setIsUserOwner] = useState<boolean>(true);
  const [credentials, setCredentials] = useState<ExternalUserCredentialsDto | null>(null);
  const [showMessage] = useMessage();

  const getOwnerCredentials = useCallback(
    async (devicePossession: DevicePossessionDto) => {
      try {
        setLoading(true);
        const credentials = await DeviceAPI.getDeviceAssignedExternalUserCredentials(
          devicePossession.possessionId // userId
        );
        setCredentials(credentials);
      } catch (error) {
        showMessage(`Could not retrieve user credentials. ${nestErrorMessage(error)}`, 'error');
        setCredentials(null);
      } finally {
        setLoading(false);
      }
    },
    [showMessage]
  );

  useEffect(() => {
    (async () => {
      if (deviceOrder.possession?.possessionType !== DevicePossessionType.User) {
        setIsUserOwner(false);
        setLoading(false);
        return;
      }

      await getOwnerCredentials(deviceOrder.possession);
    })();
  }, [getOwnerCredentials, deviceOrder]);

  return (
    <ContentWrapper loading={loading} noHeader border={false}>
      <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', gap: spacing.gap20 }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Credentials</Typography>
          {!isUserOwner && (
            <Box>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                You can retrieve device credentials only for devices assigned to users.
              </Typography>
            </Box>
          )}
          {isUserOwner && credentials && (
            <Box>
              <Typography sx={{ ...themeFonts.caption, mb: spacing.mb10, color: themeColors.DarkGrey }}>
                Unlock your laptop using these details
              </Typography>

              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                Username: <b>{credentials.username}</b>
              </Typography>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                Password: <b>{credentials.password}</b>
              </Typography>

              <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10, color: themeColors.DarkGrey }}>
                You can always get these credentials again from your device page in Zelt by clicking Device actions.
              </Typography>
            </Box>
          )}

          {isUserOwner && !credentials && (
            <Box>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                User credentials could not be found.
              </Typography>

              {deviceOrder.possession && (
                <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10, color: themeColors.DarkGrey }}>
                  You can retry or you can skip this step and then try again using the Device Actions button.
                </Typography>
              )}
            </Box>
          )}
          {isUserOwner && !credentials ? (
            <Box sx={{ display: 'flex', gap: spacing.gap10 }}>
              {deviceOrder.possession && (
                <LoaderButton
                  name="Retry"
                  colorVariant="primary"
                  sizeVariant="small"
                  onClick={() => getOwnerCredentials(deviceOrder.possession!)}
                  loading={loading}
                />
              )}
              <Button sx={secondarySmallBtn} onClick={goToNextStep}>
                Skip
              </Button>
            </Box>
          ) : (
            <Box>
              <Button sx={primarySmallBtn} onClick={goToNextStep}>
                Continue
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </ContentWrapper>
  );
};
