import { useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';

import { UserProfileDevicesPage } from './pages/user-profile-devices.page';

import useMessage from '@/hooks/notification.hook';
import { USER_DEVICES_PAGE } from '@/lib/routes';

export const UserProfileDeviceRouter = (): JSX.Element => {
  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {})();
  }, [showMessage]);

  return (
    <Switch>
      <Route exact path={USER_DEVICES_PAGE}>
        <UserProfileDevicesPage />
      </Route>
    </Switch>
  );
};
