import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

type OnboardingManager = {
  readonly onboardingComplete: boolean;
  readonly pendingRequirements: Record<string, any>;
  readonly setPendingRequirements: Dispatch<SetStateAction<readonly string[]>>;
};

const UserOnboardingByUserContext = createContext<OnboardingManager>({
  onboardingComplete: false,
  pendingRequirements: {},
  setPendingRequirements: () => {},
});

export const useUserOnboardingByUserContext = () => {
  const contextState = useContext(UserOnboardingByUserContext);
  if (contextState === null) {
    throw new Error('useUserOnboardingByUserContext must be used within a UserOnboardingByUserProvider tag');
  }
  return contextState;
};

interface Props {
  readonly children: ReactNode;
}

export const UserOnboardingByUserProvider = ({ children }: Props): JSX.Element => {
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);
  const [pendingRequirements, setPendingRequirements] = useState<readonly string[]>([]);

  useEffect(() => {
    setOnboardingComplete(pendingRequirements.length === 0);
  }, [pendingRequirements]);

  return (
    <UserOnboardingByUserContext.Provider value={{ onboardingComplete, pendingRequirements, setPendingRequirements }}>
      {children}
    </UserOnboardingByUserContext.Provider>
  );
};
