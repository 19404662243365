import { useCallback, useEffect, useMemo, useState } from 'react';

import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as TasksIcon } from '@/images/onboarding-step-icons/TaskBig.svg';
import { Scope } from '@/models';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { EditTasks } from '@/v2/feature/onboarding/onboarding-items/tasks/edit-tasks.component';
import { ChecklistDto } from '@/v2/feature/task/subfeature/checklist/checklist.dto';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { TaskScopes } from '@/v2/feature/task/task.scopes';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  checkOnboardingItemScope: (scope: readonly Scope[]) => boolean;
  onDelete?: () => void;
  setDrawerOpen: (open: boolean) => void;
  setDrawerView: (view: JSX.Element) => void;
};

export const TasksOnboardingItem = ({
  template,
  setDrawerOpen,
  setDrawerView,
  onDelete,
  checkOnboardingItemScope,
}: Props) => {
  const { polyglot } = usePolyglot();
  const canAddTasks = checkOnboardingItemScope(TaskScopes.GET_CHECKLISTS);
  const [checklists, setChecklists] = useState<ChecklistDto[]>();

  const refreshTaskTypes = useCallback(() => {
    if (!canAddTasks) return;
    TaskAPI.getChecklists().then(setChecklists);
  }, [canAddTasks]);

  useEffect(refreshTaskTypes, [refreshTaskTypes]);

  const chips = useMemo(() => {
    if (!checklists) return [];
    if (!template.template.tasks) return [];
    return template.template.tasks.checklistIds.map((id) => checklists.find((c) => c.id === id)?.name ?? '');
  }, [checklists, template.template.tasks]);

  return (
    <ActionSelectionItem
      step={{
        Icon: TasksIcon,
        title: polyglot.t('TasksOnboardingItem.title'),
        description: polyglot.t('TasksOnboardingItem.desc'),
      }}
      chips={chips}
      addButtonIcon={template.template.tasks ? 'edit' : 'plus'}
      onAddClick={
        canAddTasks &&
        (() => {
          setDrawerView(
            <EditTasks
              checklistIds={template.template.tasks?.checklistIds}
              onSave={(checklistIds) => {
                template.template.tasks = {
                  checklistIds: [...checklistIds],
                };
                setDrawerOpen(false);
              }}
            />
          );
          setDrawerOpen(true);
        })
      }
      onDeleteClick={template.template.tasks && onDelete}
      sx={{
        py: spacing.py20,
      }}
    />
  );
};
