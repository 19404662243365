import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledAuto = {
  //Autocomplete paper
  backgroundColor: themeColors.white,
  boxShadow: '0px 2px 20px 0px #0D0D0E1A !important',
  borderRadius: radius.br10,
  //Autocomplete field menulist
  '& ul.MuiList-root.MuiList-padding.MuiMenu-list ': {
    padding: '0px',
  },

  '& ul': {
    padding: '0px',
  },

  //Autocomplete field menuitem
  '& li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root': {
    color: 'black',
    ...themeFonts.caption,
    minHeight: '40px',
    '&.Mui-selected': {
      color: themeColors.DarkGrey,
      ...themeFonts.title4,
    },
  },
};

export const StyledAutoTextfield = styled(TextField)(() => ({
  //this class hides background if form is preselected
  'input:-webkit-autofill,input:-webkit-autofill:focus': {
    transition: 'none',
  },
  borderBottom: 'none',
  outline: 'none',

  '&.MuiTextField-root': {
    //normal placeholder label
    borderBottom: 'none',
    '& label.MuiInputLabel-root.Mui-error': {
      color: `${themeColors.Grey} !important`,
      ...themeFonts.caption,
      paddingBottom: '6px',
    },
    // label for untoched fields
    '& label.MuiFormLabel-root.MuiInputLabel-root': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.title4,
    },
    //shrunk placeholder on top
    '& label.MuiInputLabel-shrink.MuiInputLabel-sizeSmall': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.captionSmall,
      transform: 'none',
      marginTop: '-1px',
    },
    //typed in value
    '& input.MuiInputBase-input.MuiInput-input': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.title4,
      padding: '0px 0 0px 0px',
      '&.MuiInputBase-inputAdornedEnd': {
        paddingRight: 12,
      },
      '&.MuiInputBase-inputAdornedStart': {
        paddingRight: 4,
      },
      '&[value=""]': {
        fontWeight: 300, // lighter placeholder text
      },
    },
    //underlines variations
    // 1. normal state (non active)
    '.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '.MuiInput-underline:not(.Mui-disabled):hover:before': {
      borderBottom: 'none',
    },
    // 2. normal state (active)
    '.MuiInput-underline.Mui-focused:after': {
      borderBottom: 'none',
    },
    // 2. normal state (active:error)
    '.MuiInput-underline.Mui-error:after': {
      borderBottom: 'none',
    },
    '.MuiInput-underline': {
      borderBottom: 'none !important',
    },
    // normal helper text
    '& p.MuiFormHelperText-root': {
      ...themeFonts.captionSmall,
      color: themeColors.Grey,
      marginTop: spacing.m5,
    },
    // error helper text
    '& p.MuiFormHelperText-root.Mui-error': {
      color: themeColors.darkRed,
    },

    '& p.MuiTypography-root': {
      ...themeFonts.title4,
    },
    //input adornment buttons
    '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium': {
      padding: 0,
      position: 'absolute',
      right: 0,
      marginRight: 0,
    },
    // hides background of selects auto selection
    '& div.MuiSelect-select': {
      backgroundColor: 'transparent',
    },
    // select value font
    '& div.MuiSelect-select.MuiInputBase-input.MuiInput-input': {
      color: `${themeColors.DarkGrey} !important`,
      ...themeFonts.title4,
    },
    '& div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth': {
      borderBottom: 'none',
    },
    '& div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth:after': {
      borderBottom: 'none',
    },
    '& div.MuiInputBase-root-MuiInput-root:after': {
      borderBottom: 'none',
    },
    '& div.MuiInputBase-root.MuiInput-root': {
      color: `${themeColors.DarkGrey} !important`,
      boxSizing: 'border-box',
      ...themeFonts.title4,
      background: themeColors.Background,
      minHeight: '40px',
      borderRadius: '10px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${themeColors.Background} !important`,

      ':hover': {
        background: themeColors.GreyHover,
      },

      '&.Mui-focused': {
        border: `1px solid ${themeColors.GreyMiddle}!important`,
      },
    },
    '& div.MuiInputBase-root.MuiInput-root.Mui-error': {
      border: `1px solid ${themeColors.Red} !important`,
    },
    // endorment component alignment
    '& div.MuiAutocomplete-endAdornment': {
      top: 'calc(50%)',
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
    },
    //clear indictaor autocomplete
    '& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      visibility: 'visible',
    },

    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },

    //hidden label field
    '& .MuiInputBase-hiddenLabel': {
      paddingTop: '0px !important',
    },
  },

  '& div.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-standard.MuiInputAdornment-sizeSmall': {
    fill: themeColors.Grey,
  },

  '& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
    fill: themeColors.Grey,
  },

  '& div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot': {
    // paddingBottom: '6px',
    borderBottom: 'none',
  },

  '& div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot': {
    // paddingBottom: '6px',
    borderBottom: 'none',
  },

  '& div.MuiAutocomplete-root.Mui-focused .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiInput-underline:after': {
    borderBottom: 'none',
  },

  textarea: {
    '&::placeholder': {
      ...themeFonts.caption,
    },
  },
}));
