import { createContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import {
  PAYROLL_COMPANY_INTRO_ROUTE,
  PAYROLL_COMPANY_NEW_PAYROLL_ROUTE,
  PAYROLL_COMPANY_OVERVIEW_ROUTE,
  PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE,
  PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE,
  PAYROLL_COMPANY_ROUTE,
} from '@/lib/routes';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { CompanyPayrollPage } from '@/v2/feature/payroll/features/payroll-company/company-payroll.page';
import { PayrollTopHeader } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-top-header.component';
import { PayrollCompanyCreate } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-create/payroll-company-create.component';
import { PayrollCompanyIntro } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/payroll-company-intro.component';
import { PayrollCompanyListPayrolls } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-setup/payroll-company-list-payrolls.page';
import { PayrunPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/payrun.page';
import { PayrollScopes } from '@/v2/feature/payroll/payroll.scopes';
import { RootStyle } from '@/v2/styles/root.styles';

interface PayrollCompanyRouterProps {
  loading: boolean;
}

export const PayrollContext = createContext({
  refreshPayrollState: () => {},
});

export const PayrollCompanyRouter = ({ loading }: PayrollCompanyRouterProps): JSX.Element => {
  const { companyConfig, refreshCompanyConfig, isLoading } = useCompanyConfigState();

  // true if we have at least one payroll, false if no payrolls and undefined while we're loading
  const hasPayroll = isLoading ? undefined : companyConfig?.inPayroll;

  if (loading || typeof hasPayroll !== 'boolean') {
    return (
      <RootStyle>
        <PayrollTopHeader />
        <LoadingSpinner />
      </RootStyle>
    );
  }

  return (
    <PayrollContext.Provider value={{ refreshPayrollState: () => refreshCompanyConfig() }}>
      <Switch>
        <RouteScopesControl path={PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE} exact scopes={['payroll:all']}>
          <PayrunPage />
        </RouteScopesControl>

        <RouteScopesControl path={PAYROLL_COMPANY_NEW_PAYROLL_ROUTE} exact scopes={PayrollScopes.CREATE_NEW_PAYROLL}>
          <PayrollCompanyCreate />
        </RouteScopesControl>

        <RouteScopesControl path={PAYROLL_COMPANY_INTRO_ROUTE} exact scopes={PayrollScopes.VIEW_COMPANY_INTRO}>
          {hasPayroll && <Redirect to={PAYROLL_COMPANY_OVERVIEW_ROUTE} />}
          {!hasPayroll && <PayrollCompanyIntro />}
        </RouteScopesControl>

        <RouteScopesControl path={PAYROLL_COMPANY_OVERVIEW_ROUTE} exact scopes={['payroll:all']}>
          {!hasPayroll && <Redirect to={PAYROLL_COMPANY_INTRO_ROUTE} />}
          {hasPayroll && <PayrollCompanyListPayrolls />}
        </RouteScopesControl>

        <RouteScopesControl path={PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE} exact scopes={['payroll:all']}>
          <CompanyPayrollPage />
        </RouteScopesControl>

        <Redirect
          from={PAYROLL_COMPANY_ROUTE}
          to={hasPayroll ? PAYROLL_COMPANY_OVERVIEW_ROUTE : PAYROLL_COMPANY_INTRO_ROUTE}
        />
      </Switch>
    </PayrollContext.Provider>
  );
};
