import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { Discount } from '@/models/discount.model';
import { ProductPlanTypes } from '@/models/subscription.model';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface props {
  section: string;
  planDefaultValue: string;
  planLabel: string;
  planInputKey: string;
  discountInputKey: string;
  discountLabel: string;
  discountDefaultValue?: number;
  handlePlanSelect: (subscriptionId: number, planId: number) => void;
  handleDiscountSelect: (subscriptionId: number, discountId: number) => void;
  discountList: Discount[];
}

export const SuperAdminBillingPlanEditFormItem = ({
  section,
  planDefaultValue,
  planLabel,
  handlePlanSelect,
  discountDefaultValue,
  handleDiscountSelect,
  discountLabel,
  planInputKey,
  discountInputKey,
  discountList,
}: props) => {
  let plans = ProductPlanTypes.People;
  let defaultValue: number;

  switch (section) {
    case 'Apps':
      plans = ProductPlanTypes.Apps;
      break;

    case 'Money':
      plans = ProductPlanTypes.Money;
      break;

    case 'Devices':
      plans = ProductPlanTypes.Devices;
      break;
  }

  defaultValue = plans[planDefaultValue];

  return (
    <>
      <Box sx={{ marginTop: 5 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: 4, mb: 4 }}>{section}</Typography>

        <TextField
          sx={{ mb: 4 }}
          select
          variant="outlined"
          fullWidth
          onChange={(e) => handlePlanSelect(Number(planInputKey), Number(e.target.value))}
          label={planLabel}
          key={planInputKey}
          defaultValue={defaultValue}
        >
          <MenuItem value={plans.Free}>Free</MenuItem>
          <MenuItem value={plans.Pro}>Pro</MenuItem>
        </TextField>
      </Box>

      <TextField
        select
        variant="outlined"
        fullWidth
        onChange={(e) => handleDiscountSelect(Number(planInputKey), Number(e.target.value))}
        label={discountLabel}
        key={discountInputKey}
        defaultValue={discountDefaultValue || 0}
      >
        <MenuItem value={0}>None</MenuItem>
        {discountList.map((discount: Discount) => (
          <MenuItem value={discount.id} key={uuidv4()}>
            {discount.description}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
