import React from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';

interface props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element;
  readonly onClose?: () => Promise<void> | void;
  readonly afterClose?: () => Promise<void> | void;
  closeIcon?: 'close' | 'back';
}

export const SuperAdminBillingPlanEditModal = ({
  isOpen,
  setIsOpen,
  children,
  onClose,
  afterClose,
  closeIcon,
}: props) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose} closeIcon={closeIcon}>
      {children}
    </DrawerModal>
  );
};
