import { CSSProperties, useState } from 'react';

import { Box } from '@mui/material';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ScaleConfig } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ScaleQuestionReadonly = ({
  questionText,
  scaleConfig,
  hasComment,
  isCommentRequired,
}: {
  questionText: string;
  scaleConfig: ScaleConfig | null;
  hasComment: boolean;
  isCommentRequired: boolean;
}) => {
  if (!questionText) return <></>;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <Typography variant="caption" dangerouslySetInnerHTML={{ __html: questionText ?? '' }}></Typography>
      <RatingScale scaleConfig={scaleConfig} />

      {hasComment && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.g8,
            pt: spacing.p16,
            borderTop: `${borders.background}`,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Add comment
            </Typography>
            <Typography variant="caption" color="Grey">
              {isCommentRequired ? 'Required' : 'Optional'}
            </Typography>
          </Box>
          <RichTextField value={null} disabled={true} />
        </Box>
      )}
    </Box>
  );
};

const RatingScale = ({ scaleConfig }: { scaleConfig: ScaleConfig | null }) => {
  const [scaleKey, selectedScaleKey] = useState<string | null>(null);

  const scaleItemStyle = (value: string): CSSProperties => ({
    flex: 1,
    height: '44px',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: scaleKey === value ? themeColors.DarkGrey : themeColors.Background,
    borderRadius: radius.br8,
    userSelect: 'none',
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
      {scaleConfig?.points && (
        <Box sx={{ visibility: scaleKey ? 'visible' : 'hidden', minHeight: '20px' }}>
          <Typography variant="caption" color="Grey">
            {scaleKey ? scaleConfig.points[scaleKey] : ''}
          </Typography>
        </Box>
      )}
      <Box style={{ display: 'flex', flexGrow: 1, gap: spacing.g4, height: '44px' }}>
        {scaleConfig?.value &&
          Object.entries(scaleConfig.value).map(([key, value]) => (
            <Box
              key={key}
              style={scaleItemStyle(key)}
              onClick={() => {
                selectedScaleKey(key);
              }}
            >
              <Typography variant="caption" color={scaleKey === key ? 'Background' : 'DarkGrey'}>
                {value}
              </Typography>
            </Box>
          ))}
      </Box>
    </div>
  );
};
