import { useState } from 'react';

import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PensionSummaryPage } from '@v2/feature/benefits/subfeature/pension/pension-summary.page';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PensionSchemeDto, UserPensionDto } from './pension.dto';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  BENEFITS_COMPANY_ROUTE,
  PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE,
  PENSION_COMPANY_ROUTE,
  PENSION_COMPANY_SUMMARY_ROUTE,
} from '@/lib/routes';

interface PensionCompanyRouterProps {
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly loading: boolean;
}

export const PensionCompanyRouter = ({ pensionSchemes, loading }: PensionCompanyRouterProps) => {
  const { polyglot } = usePolyglot();

  const userPensionState = useState<readonly UserPensionDto[]>([]);

  return (
    <RootStyle>
      <TopHeader title={polyglot.t('PensionModule.pension')} showBack backPath={BENEFITS_COMPANY_ROUTE} />

      <Switch>
        <RouteScopesControl scopes={['pension:all']} path={PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE}>
          <PensionSummaryPage
            pensionSchemes={pensionSchemes}
            userPensionState={userPensionState}
            loadingParent={loading}
          />
        </RouteScopesControl>
        <Route path={PENSION_COMPANY_ROUTE}>
          <Redirect to={PENSION_COMPANY_SUMMARY_ROUTE} />
        </Route>
      </Switch>
    </RootStyle>
  );
};
