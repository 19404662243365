import { useCallback } from 'react';

import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { AttendanceScheduleRouter } from '@v2/feature/attendance/attendance-schedule.router';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceCompanySettingsAttendanceSchedulesPage } from '@v2/feature/attendance/company/pages/attendance-company-settings/attendance-company-settings-attendance-schedules.page';
import { AttendanceCompanySettingsOtherPage } from '@v2/feature/attendance/company/pages/attendance-company-settings/attendance-company-settings-other.page';
import { AttendanceSettingsSchedulePatternPage } from '@v2/feature/attendance/settings/sections/attendance-settings-schedule-pattern.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Redirect, Switch } from 'react-router-dom';

import { SiteEndpoints } from '@/api-client/site.api';
import { RouteScopesHas } from '@/component/widgets/Scopes';
import {
  SETTINGS_ATTENDANCE_OTHER_ROUTE,
  SETTINGS_ATTENDANCE_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_ROUTE,
} from '@/lib/routes';

export const AttendanceSettingsRouter = () => {
  const { data: attendanceSchedules, mutate: refreshAttendanceSchedules } = useApiClient<
    AttendanceScheduleDto[],
    Error
  >(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(), {
    suspense: false,
  });
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });

  const refreshApprovalRules = useCallback(async () => {
    if (refreshRules) await refreshRules();
  }, [refreshRules]);

  const refreshSchedules = useCallback(async () => {
    if (refreshAttendanceSchedules) await refreshAttendanceSchedules();
  }, [refreshAttendanceSchedules]);

  return (
    <Switch>
      <RouteScopesHas scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE}>
        <AttendanceSettingsSchedulePatternPage />
      </RouteScopesHas>

      <RouteScopesHas scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_ROUTE}>
        <AttendanceScheduleRouter
          refreshSchedules={refreshSchedules}
          sites={sites ?? []}
          approvalRules={approvalRules ?? []}
          refreshApprovalRules={refreshApprovalRules}
        />
      </RouteScopesHas>

      <RouteScopesHas scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE} exact>
        <AttendanceCompanySettingsAttendanceSchedulesPage
          attendanceSchedules={attendanceSchedules ?? []}
          refreshSchedules={refreshSchedules}
        />
      </RouteScopesHas>

      <RouteScopesHas scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_OTHER_ROUTE} exact>
        <AttendanceCompanySettingsOtherPage />
      </RouteScopesHas>

      <Redirect from={SETTINGS_ATTENDANCE_ROUTE} to={SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE} />
    </Switch>
  );
};
