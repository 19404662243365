import type { SystemStyleObject } from '@mui/system';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const deviceListSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 0,
  mb: spacing.m45,
};
export const deviceSx: SystemStyleObject = { display: 'flex', marginBottom: spacing.m15 };
export const deviceTermSx: SystemStyleObject = { flex: 1, ...themeFonts.caption };
export const deviceValueSx: SystemStyleObject = { flex: 2, ...themeFonts.title4 };
export const devicetitleSx: SystemStyleObject = { ...themeFonts.title2, ...spacing.mb20, color: themeColors.DarkGrey };
