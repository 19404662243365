import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { TaskFormModal } from '@v2/feature/task/components/task-add-modal/task-form-modal.component';
import { TaskTable } from '@v2/feature/task/components/task-table/task-table.component';
import { TaskDto } from '@v2/feature/task/task.dto';
import { TaskStatuses, TaskTabFilter } from '@v2/feature/task/task.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TaskLaunchModal } from '@/v2/feature/task/components/task-launch/task-launch-modal.component';
import {
  TaskAndChecklistItemCommon,
  TaskViewModal,
} from '@/v2/feature/task/components/task-view/task-view-modal.component';
import { TaskAPI, TaskEndpoints } from '@/v2/feature/task/task.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface TasksUserPageProps {
  readonly userId: number;
  readonly setIsTaskFormModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly isTaskFormModalOpen: boolean;
  readonly reach: 'me' | 'userProfile';
  readonly loading?: boolean;
  readonly forOffboarding?: boolean;
  readonly refreshOffboarding?: () => Promise<void>;
}

export const PersonalTask = ({
  userId,
  setIsTaskFormModalOpen,
  isTaskFormModalOpen,
  reach,
  loading,
  forOffboarding = false,
  refreshOffboarding,
}: TasksUserPageProps) => {
  const { polyglot } = usePolyglot();

  const TabFilter = useMemo(
    () => [
      { name: polyglot.t('TasksListPage.inSevenDays'), value: TaskTabFilter.InSevenDays },
      { name: polyglot.t('TasksListPage.inThirtyDays'), value: TaskTabFilter.InThirtyDays },
      { name: polyglot.t('TasksListPage.all'), value: TaskTabFilter.All },
      { name: polyglot.t('TasksListPage.done'), value: TaskTabFilter.Done },
    ],
    [polyglot]
  );

  const [taskToEdit, setTaskToEdit] = useState<TaskDto | null>(null);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<TaskDto | null>(null);
  const [isTaskViewModalOpen, setIsTaskViewModalOpen] = useState<boolean>(false);
  const [paginationState, setPaginationState] = useState<PaginationState>({ pageIndex: 1, pageSize: 100 });
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [tableState, setTableState] = useState<{ filterValue: string; searchInput: string }>({
    filterValue: TaskTabFilter.InSevenDays,
    searchInput: '',
  });
  const { filterValue, searchInput } = tableState;

  const { data: allTasks, isLoading, mutate: refreshTasks } = useApiClient(
    TaskEndpoints.listTasks(userId, paginationState, {
      sortField: sortingState[0],
      status: filterValue,
      search: searchInput,
      forOffboarding,
    }),
    {
      suspense: false,
    }
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);

  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const params: { userId: string } = useParams();
  const targetUserId = Number(params.userId);
  const { getScopesContext, hasScopes } = useScopes();
  const hasTaskManagerScopes = hasScopes(['task:manager'], getScopesContext({ userId: targetUserId }));
  const hasTaskAdminScopes = hasScopes(['task:all'], getScopesContext(user));
  const isManagerOfTargetUserId = useMemo(() => {
    return hasTaskManagerScopes && user.reports.includes(targetUserId);
  }, [hasTaskManagerScopes, targetUserId, user.reports]);

  const hasVisibility = useMemo(() => hasTaskAdminScopes || isManagerOfTargetUserId || forOffboarding, [
    hasTaskAdminScopes,
    isManagerOfTargetUserId,
    forOffboarding,
  ]);

  const updateTableState = useCallback((newState: Partial<typeof tableState>) => {
    setTableState((prevState) => {
      // if the state-change can result in a different set of results, reset the pagination
      if ('filterValue' in newState || 'searchInput' in newState) {
        setPaginationState((p) => ({ ...p, pageIndex: 1 }));
      }
      return { ...prevState, ...newState };
    });
  }, []);

  const refresh = useCallback(async () => {
    if (refreshTasks) await refreshTasks();
    if (refreshOffboarding) await refreshOffboarding();
  }, [refreshTasks, refreshOffboarding]);

  const closeTaskFormModal = (): void => {
    setTaskToEdit(null);
    setIsTaskFormModalOpen(false);
  };

  const updateTaskStatus = async (taskId: number, assignedUserId: number, status: TaskStatuses) => {
    try {
      await TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
      showMessage(polyglot.t('PersonalTask.successMessages.updateStatus'), 'success');
      await refresh();
      if (selectedTask) setSelectedTask({ ...selectedTask, status });
    } catch (error) {
      showMessage(
        polyglot.t('PersonalTask.errorMessages.updateStatus', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const deleteTask = async (taskId: number) => {
    try {
      await TaskAPI.removeTask(taskId);
      showMessage(polyglot.t('PersonalTask.successMessages.delete'), 'success');
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('PersonalTask.errorMessages.remove', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  };

  const remindAboutTask = async (taskId: number) => {
    try {
      await TaskAPI.remindAboutTask(taskId);
      showMessage(polyglot.t('PersonalTask.successMessages.remind'), 'success');
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('PersonalTask.errorMessages.remind', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  };

  const confirmDeleteTask = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setIsRemovalModalOpen(true);
  };

  const deleteTaskAfterConfirmation = useCallback(
    async (tasksIds: number[]) => {
      try {
        setIsRemovalModalOpen(false);
        setAnchorEl(null);
        await TaskAPI.removeTasks(tasksIds, forOffboarding);
        showMessage(polyglot.t('PersonalTask.successMessages.deleteMult'), 'success');
        setSelectionModel([]);
        await refresh();
      } catch (error) {
        showMessage(
          polyglot.t('PersonalTask.errorMessages.removeMult', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [forOffboarding, showMessage, polyglot, refresh]
  );

  return (
    <ContentWrapper loading={loading || isLoading} sx={{ ...spacing.pt20 }}>
      <Box sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {forOffboarding ? (
            <Box></Box>
          ) : (
            <TabFilterButtons
              filters={TabFilter}
              setFilterValue={(filterValue) => updateTableState({ filterValue })}
              filterValue={filterValue}
              hasSearch
              onFilterChange={({ filterValue, searchInput }) => {
                updateTableState({
                  filterValue,
                  searchInput,
                });
              }}
            />
          )}

          <Box>
            {(hasTaskAdminScopes || isManagerOfTargetUserId) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right', gap: spacing.g15 }}>
                {selectionModel.length > 0 ? (
                  <ButtonComponent
                    sizeVariant="small"
                    colorVariant="secondary"
                    style={{ display: 'flex', gap: spacing.g5 }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      confirmDeleteTask(event);
                    }}
                  >
                    <Trash {...iconSize} /> <>{polyglot.t('General.delete')}</>
                  </ButtonComponent>
                ) : (
                  reach === 'userProfile' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="secondary"
                        onClick={() => setIsLaunchModalOpen(true)}
                      >
                        Checklist
                      </ButtonComponent>
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="primary"
                        onClick={() => setIsTaskFormModalOpen(true)}
                      >
                        {polyglot.t('PersonalTask.new')}
                      </ButtonComponent>
                    </Box>
                  )
                )}
              </Box>
            )}
          </Box>
        </Box>
        {allTasks && (
          <Box sx={{ ...spacing.mt20 }}>
            <TaskTable
              tasks={allTasks}
              pagination={[paginationState, setPaginationState]}
              sorting={[sortingState, setSortingState]}
              openSelectedTaskModal={(task: TaskDto) => {
                setSelectedTask(task);
                setIsTaskViewModalOpen(true);
              }}
              openEditTaskModal={(task: TaskDto) => {
                setTaskToEdit(task);
                setIsTaskFormModalOpen(true);
              }}
              markAsComplete={async (task: TaskDto) => {
                if (task.id && task.assignedUserId)
                  await updateTaskStatus(task.id, task.assignedUserId, TaskStatuses.COMPLETE);
              }}
              showUserLink={false}
              loading={loading}
              setSelectionModel={setSelectionModel}
              selectionModel={selectionModel}
              currentUserId={user.userId}
              deleteAction={deleteTask}
              remindAction={remindAboutTask}
              reach="me"
            />
          </Box>
        )}
      </Box>
      {/* TASK ADD AND EDIT MODAL */}
      <TaskFormModal
        isOpen={isTaskFormModalOpen}
        setIsOpen={setIsTaskFormModalOpen}
        onClose={closeTaskFormModal}
        userId={userId}
        formData={taskToEdit}
        refresh={refresh}
      />

      {/* TASK VIEW MODAL */}
      {selectedTask && (
        <TaskViewModal
          isOpen={isTaskViewModalOpen}
          setIsOpen={setIsTaskViewModalOpen}
          onClose={() => {
            setSelectedTask(null);
            setIsTaskViewModalOpen(false);
          }}
          task={selectedTask as TaskAndChecklistItemCommon}
          action={updateTaskStatus}
          deleteTask={async () => {
            if (!selectedTask) return;
            await deleteTaskAfterConfirmation([selectedTask.id]);
            setIsTaskViewModalOpen(false);
          }}
          editTask={() => {
            setTaskToEdit(selectedTask);
            setIsTaskFormModalOpen(true);
            setIsTaskViewModalOpen(false);
          }}
        />
      )}

      {hasVisibility && (
        <TaskLaunchModal
          isOpen={isLaunchModalOpen}
          setIsOpen={setIsLaunchModalOpen}
          onClose={() => setIsLaunchModalOpen(false)}
          refresh={refresh}
          assignUserId={userId}
          reach={hasTaskAdminScopes ? 'company' : hasTaskManagerScopes ? 'team' : 'user'}
        />
      )}
      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => setIsRemovalModalOpen(false)}
        anchorEl={anchorEl}
        takeAction={async () => deleteTaskAfterConfirmation(selectionModel)}
        message={polyglot.t('PersonalTask.confirmDelete', { smart_count: selectionModel ? selectionModel.length : 0 })}
        callToAction={polyglot.t('PersonalTask.delete', { smart_count: selectionModel ? selectionModel.length : 0 })}
      />
    </ContentWrapper>
  );
};
