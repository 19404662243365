import axios from 'axios';
import { BillingInformation } from '@/models/subscription.model';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';

export class BillingAPI {
  static async getBillingInformation(): Promise<BillingInformation> {
    return (await axios.get('/apiv2/billing/info')).data;
  }
}

export class BillingEndpoints {
  static getBillingInfo(): Endpoint<BillingInformation> {
    return { url: '/apiv2/billing/info' };
  }
}
