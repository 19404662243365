import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { InfoModal } from '@v2/components/info-modal.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { AppliedDevicePoliciesDto, DevicePolicyDto, mergeDevicePolicies } from '@v2/feature/device/device.dto';
import { DevicePolicyType, SupportedDeviceType } from '@v2/feature/device/device.interface';
import { AndroidSettingsCard } from '@v2/feature/device/features/devices-settings/features/mdm-android/android-setings-card.component';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { NoAppliedPolicies } from '@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import {
  buttonBoxSx,
  cardListSx,
  cardSubtitleSx,
  cardTitleIconSx,
  fieldLabelSx,
  helperTextSx,
  settingsfieldSx,
} from '@/v2/styles/settings.styles';

export const AndroidMDMSettings = (): JSX.Element => {
  const [showMessage] = useMessage();

  // const [globalState] = useContext(GlobalContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [editPoliciesCard, setEditPoliciesCard] = useState<boolean>(false);
  const [unchangedAppliedPolicies, setUnchangedAppliedPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [androidAppliedPolicies, setAndroidAppliedPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [passCodePolicies, setPassCodePolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [osUpdatesPolicies, setOsUpdatesPolicies] = useState<readonly DevicePolicyDto[]>([]);
  const [allPolicies, setAllPolicies] = useState<DevicePolicyDto[]>([]);
  const [appliedPolicies, setAppliedPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);

  const handlePolicyChange = (policyType: DevicePolicyType) => (event: ChangeEvent<HTMLInputElement>) => {
    setAndroidAppliedPolicies((prev) => ({
      ...prev,
      [policyType]: [Number(event.target.value)],
    }));
  };

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const [policies, appliedPolicies] = await Promise.all([
        DeviceAPI.getAvailableCompanyDevicePolicies(),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
      ]);
      setUnchangedAppliedPolicies(Boolean(appliedPolicies) ? appliedPolicies : NoAppliedPolicies);
      setAndroidAppliedPolicies(Boolean(appliedPolicies) ? appliedPolicies : NoAppliedPolicies);
      setAppliedPolicies(Boolean(appliedPolicies) ? appliedPolicies : NoAppliedPolicies);
      setAllPolicies(policies);
      setPassCodePolicies([
        {
          id: 0,
          name: 'None',
          type: DevicePolicyType.passcode,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.android,
        },
        ...policies.filter(
          (policy) =>
            policy.type === DevicePolicyType.passcode &&
            policy.supportedDeviceType.toString() === SupportedDeviceType.android.toString()
        ),
      ]);
      setOsUpdatesPolicies([
        {
          id: 0,
          name: 'None',
          type: DevicePolicyType.updates,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.android,
        },
        ...(policies && policies.length > 0
          ? policies.filter(
              (policy) =>
                policy.type === DevicePolicyType.updates &&
                policy.supportedDeviceType.toString() === SupportedDeviceType.android.toString()
            )
          : []),
      ]);
    } catch (error) {
      showMessage('Something went wrong. Could not load the company policies.', 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const passcodePoliciesOptions = passCodePolicies
    .map((policy) => ({
      value: policy.id,
      label: policy.name.includes('Basic') ? `${policy.name} (Recommended)` : policy.name,
      description: policy.description,
    }))
    .sort((a, b) => {
      const sortValue: { [key: string]: number } = {
        None: 4,
        'Basic (Recommended)': 1,
        Moderate: 2,
        Strict: 3,
      };
      return (sortValue[a.label as string] ?? 0) - (sortValue[b.label] ?? 0);
    });

  const selectedPasscodePolicy =
    passcodePoliciesOptions.find((policy) =>
      androidAppliedPolicies[DevicePolicyType.passcode].includes(policy.value)
    ) ?? passcodePoliciesOptions.find((value) => value.value === 0);

  const osUpdatesPoliciesOptions = osUpdatesPolicies
    .map((policy) => ({
      value: policy.id,
      label: policy.name,
      description: policy.description,
    }))
    .sort((a, b) => {
      const sortValue: { [key: string]: number } = {
        None: 4,
        'Notify only': 1,
        'Auto install updates and remind to restart if required': 2,
        'Auto install and force restart': 3,
      };
      return (sortValue[a.label as string] ?? 0) - (sortValue[b.label] ?? 0);
    });
  const selectedOsUpdatesPolicy =
    osUpdatesPoliciesOptions.find((policy) =>
      androidAppliedPolicies[DevicePolicyType.updates].includes(policy.value)
    ) ?? osUpdatesPoliciesOptions.find((value) => value.value === 0);

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>} />
      <ContentWrapper loading={loading}>
        {editPoliciesCard ? (
          <Box>
            <Box
              sx={{
                width: '100%',
                maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '60%', xl: '60%' },
                paddingBottom: spacing.p60,
              }}
            >
              <Box sx={cardTitleIconSx}>
                <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Security</Typography>
              </Box>
              <Typography sx={cardSubtitleSx}>
                Configure security settings for Android devices connected to Hexnode MDM.
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap30 }}>
                <Box sx={settingsfieldSx}>
                  <Typography sx={fieldLabelSx}>Passcode</Typography>
                  <Box sx={{ ...cardListSx, position: 'relative' }}>
                    <SelectComponent
                      name="passcode"
                      label=""
                      options={passcodePoliciesOptions}
                      value={selectedPasscodePolicy?.value}
                      compareValue={selectedPasscodePolicy?.value}
                      onChange={handlePolicyChange(DevicePolicyType.passcode)}
                      disabled={!editPoliciesCard}
                    />

                    {selectedPasscodePolicy?.description && (
                      <Typography sx={helperTextSx} color={themeColors.Grey}>
                        {selectedPasscodePolicy.description}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box sx={settingsfieldSx}>
                  <Typography sx={fieldLabelSx}>OS updates</Typography>
                  <Box sx={{ ...cardListSx, position: 'relative' }}>
                    <SelectComponent
                      name="osUpdates"
                      label=""
                      options={osUpdatesPoliciesOptions}
                      value={selectedOsUpdatesPolicy?.value ?? 0}
                      compareValue={selectedOsUpdatesPolicy?.value}
                      onChange={handlePolicyChange(DevicePolicyType.updates)}
                      disabled={!editPoliciesCard}
                    />

                    {selectedOsUpdatesPolicy?.description && (
                      <Typography sx={helperTextSx} color={themeColors.Grey}>
                        {selectedOsUpdatesPolicy.description}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: '100%',
                maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '60%', xl: '60%' },
                paddingBottom: spacing.p60,
              }}
            >
              <Box>
                {editPoliciesCard && (
                  <Box
                    sx={{
                      ...buttonBoxSx,
                      display: 'flex',
                      maxWidth: '400px',
                      width: 'auto',
                      mt: spacing.mt40,
                      gap: spacing.gap10,
                    }}
                  >
                    <ButtonComponent
                      fullWidth
                      sizeVariant="medium"
                      colorVariant="secondary"
                      onClick={() => {
                        setEditPoliciesCard(false);
                        setAndroidAppliedPolicies(unchangedAppliedPolicies);
                      }}
                    >
                      Cancel
                    </ButtonComponent>
                    <LoaderButton
                      name="Save"
                      loading={loading}
                      fullWidth={true}
                      colorVariant="primary"
                      sizeVariant="medium"
                      onClick={async () => {
                        try {
                          const relevantPolicyIds = new Set(
                            allPolicies
                              .filter(
                                (policy) =>
                                  policy.supportedDeviceType.toString() === SupportedDeviceType.android.toString()
                              )
                              .map((policy) => policy.id)
                          );
                          for (const key of Object.values(DevicePolicyType)) {
                            appliedPolicies[key] = appliedPolicies[key].filter(
                              (value) => !relevantPolicyIds.has(value)
                            );
                          }
                          let updatedPolicies = mergeDevicePolicies(androidAppliedPolicies, appliedPolicies);
                          await DeviceAPI.updateCompanyDeviceAppliedPolicies(updatedPolicies);
                          setEditPoliciesCard(false);
                          showMessage('Policies successfully saved.', 'success');
                          setIsInfoModalOpen(true);
                          await refresh();
                        } catch {
                          showMessage('The new policies could not be saved. Please try again.', 'error');
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <AndroidSettingsCard
            setEditPoliciesCard={setEditPoliciesCard}
            selectedPasscodePolicy={selectedPasscodePolicy}
            selectedOsUpdatesPolicy={selectedOsUpdatesPolicy}
          />
        )}

        <InfoModal isOpen={isInfoModalOpen} title="Changes saved" onClose={() => setIsInfoModalOpen(false)}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            Your changes have been applied to all devices, however it may take sometime to show here. Check back in a
            few hours.
          </Typography>
        </InfoModal>
      </ContentWrapper>
    </RootStyle>
  );
};
