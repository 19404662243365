import { UserAvatar, UserAvatarSize } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

interface Props {
  readonly value?: string;
  readonly onChange: (avatar?: string) => void;
  readonly disabled?: boolean;
  readonly url: string;
  readonly userId: number | null;
  readonly showBadge?: boolean;
  readonly badge?: JSX.Element;
  readonly avatarSize?: UserAvatarSize;
  readonly boxSize?: number;
}

/** TODO rewrite */
export const UploadProfilePicture = ({
  value,
  onChange,
  disabled = false,
  url,
  userId,
  showBadge = false,
  badge,
  avatarSize = 'medium',
  boxSize = 40,
}: Props): JSX.Element => {
  const [resetKey, setResetKey] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarVisibility, setAvatarVisibility] = useState(true);

  useEffect(() => {
    setResetKey(resetKey + 1);
    if (resetKey !== 1) setIsLoading(false);
    // TODO: this is hotfix!
    // This is to reset the file from Dropzone after submitting, eslint complains
    // eslint-disable-next-line
  }, [value]);

  const handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => {
    switch (status) {
      case 'preparing': {
        setIsLoading(true);
        break;
      }
      case 'done': {
        if (file.xhr) {
          onChange(file.xhr.response);
        }
        // TODO else
        setIsLoading(false);
        break;
      }
      case 'removed': {
        onChange();
        break;
      }
      default: {
        break;
      }
    }
  };

  const setVisibility = (visibility: boolean) => {
    if (!disabled) {
      setAvatarVisibility(visibility);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: boxSize,
      }}
      onMouseEnter={() => setVisibility(false)}
      onMouseLeave={() => setVisibility(true)}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Skeleton
            animation="wave"
            variant="circular"
            width={boxSize}
            height={boxSize}
            sx={{ backgroundColor: themeColors.Background }}
          />
        </Box>
      ) : (
        <Box>
          {userId ? (
            <UserAvatar userId={userId} size={avatarSize} showBadge={showBadge} badge={badge} key={userId} />
          ) : (
            <Avatar src={value} sx={{ width: boxSize, height: boxSize }} />
          )}
        </Box>
      )}
      {!disabled && (
        <Dropzone
          styles={{
            dropzone: {
              position: 'absolute',
              borderRadius: '50%',
              width: boxSize,
              height: boxSize,
              top: 0,
              margin: 0,
              minHeight: '0px',
              overflow: 'hidden',
              border: 'none',
            },
            inputLabel: {
              fontSize: '1rem',
              padding: '0.5rem',
              textAlign: 'center',
              ...(value ? { fontSize: '1.25rem' } : {}),
            },
          }}
          key={resetKey}
          getUploadParams={() => ({
            url,
          })}
          onChangeStatus={handleChangeStatus}
          maxFiles={1}
          maxSizeBytes={10485760}
          canCancel={false}
          accept="image/*"
          inputContent={disabled || isLoading || avatarVisibility ? '' : ''}
        />
      )}
    </Box>
  );
};
