import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { XMLValidator } from 'fast-xml-parser';
import { Form, FormikProvider, useFormik } from 'formik';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { SSOState } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { EnableSSODisableMFAConflictConfirmDrawer } from '@/v2/feature/app-integration/features/app-details/components/app-details-sso-enable-mfa-conflict-drawer.component';
import { isDefined } from '@/v2/feature/user-onboarding/user-onboarding.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { arrayBufferToString } from '@/v2/util/string.util';

interface SSOSamlMetadata {
  certificate: string;
  ssoEnabled: boolean;
}

const ssoProdACSURL = 'https://zelt-production.firebaseapp.com/__/auth/handler';
const ssoAzureADVideoInstructionsURL = 'https://youtu.be/E5v8pNmPn_0';

interface SSOAzureAdUpdateFormProps {
  mfaEnabled: boolean;
  ssoState: SSOState[];
  onUpdate: VoidFunction;
}

export const SSOAzureAdUpdateForm = ({ mfaEnabled, ssoState, onUpdate }: SSOAzureAdUpdateFormProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const SSO_APP_STUB = 'sso-azure-ad';
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<string>('');
  const [ssoEnabled, setSsoEnabled] = useState<boolean>(true);
  const [confirmEnablement, setConfirmEnablement] = useState<boolean>(false);
  const [confirmEnableSSOAndDisableMFA, setConfirmEnableSSOAndDisableMFA] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    try {
      const azureAdEntry = ssoState?.find((eachEntry) => eachEntry.app === 'azure-ad');
      const ssoGwEntry = ssoState?.find((eachEntry) => eachEntry.app === SSO_APP_STUB);
      const someEntryEnabled = ssoState?.some((eachEntry) => eachEntry.state.enabled);
      if (azureAdEntry) setCertificate(azureAdEntry?.state?.certificate);
      if (ssoGwEntry) setCertificate(ssoGwEntry?.state?.certificate);
      setSsoEnabled(someEntryEnabled);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t('SSOAzureAdUpdateForm.errorMessages.fetch')}: ${JSON.stringify(error)}`, 'error');
    }
  }, [showMessage, ssoState, polyglot]);

  useEffect(() => {
    refresh();
  }, [refresh, ssoState]);

  const saveSsoSamlMetadata = async (values: SSOSamlMetadata) => {
    try {
      if (!ssoEnabled && mfaEnabled && !confirmEnableSSOAndDisableMFA) {
        setConfirmEnablement(true);
        return;
      }
      if (!values || Object.keys(values)?.length === 0) return;
      setLoading(true);
      const finalPayload = {
        certificate: isDefined(values.certificate) ? values.certificate : certificate,
        enabled: true,
      };
      const encoded = btoa(JSON.stringify(finalPayload));
      if (values.certificate && encoded) {
        await AppDetailsAPI.saveSSOSamlMetadata(SSO_APP_STUB, {
          sso_saml_metadata: encoded,
          disable_mfa: confirmEnableSSOAndDisableMFA,
        });
      }

      showMessage(polyglot.t('SSOAzureAdUpdateForm.successMessages.update'), 'success');
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t('SSOAzureAdUpdateForm.errorMessages.save')}: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
      refresh();
    }
  };

  const azureADEnabled = useMemo(() => {
    return !!ssoState.find((eachApp) => eachApp.app === SSO_APP_STUB && eachApp.state.enabled);
  }, [ssoState]);

  const disableSAMLSSO = async () => {
    try {
      if (!azureADEnabled) return;
      setLoading(true);
      await AppDetailsAPI.disableSSOSaml(SSO_APP_STUB);

      showMessage(polyglot.t('SSOAzureAdUpdateForm.successMessages.disable'), 'success');
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t('SSOAzureAdUpdateForm.errorMessages.disable')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
      refresh();
    }
  };

  const formik = useFormik<SSOSamlMetadata>({
    initialValues: {
      certificate,
      ssoEnabled,
    },
    enableReinitialize: true,
    onSubmit: saveSsoSamlMetadata,
  });

  const triggerEnableSSOAndDisableMFA = () => {
    setConfirmEnableSSOAndDisableMFA(true);
    formik.handleSubmit();
  };

  return (
    <Box sx={{ width: '100%', mt: spacing.mt10 }}>
      {!azureADEnabled ? (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
              {`${polyglot.t('SSOAzureAdUpdateForm.uploadIdP')}.`}
              <br />
              {`${polyglot.t('SSOAzureAdUpdateForm.findGuide')} `}
              <Link target="_blank" rel="noreferrer" href={ssoAzureADVideoInstructionsURL}>
                {polyglot.t('SSOAzureAdUpdateForm.video')}.
              </Link>
              <br />
              <br />
              {`${polyglot.t('SSOAzureAdUpdateForm.findIdP')}.`}
              <br />
              <br />
              {`${polyglot.t('SSOAzureAdUpdateForm.uselink')}:`}
              <br />
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
                {`${ssoProdACSURL}`}
              </Typography>
              <br />
            </Typography>
          </Box>
          <>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} component="section">
                  <Box sx={{ width: '100%' }}>
                    <Stack>
                      <UploadInput<Record<string, any>>
                        skipUpload={true}
                        onChange={async (_: any, file: File | undefined) => {
                          try {
                            if (!file) return;
                            const arrayBuffer = await file.arrayBuffer();
                            const xmlBuffer = await arrayBufferToString(arrayBuffer);
                            const xmlValidationResult: Boolean | { err: Record<string, any> } = XMLValidator.validate(
                              xmlBuffer
                            );
                            if (typeof xmlValidationResult !== 'boolean') {
                              formik.setFieldError(
                                'certificate',
                                polyglot.t('SSOAzureAdUpdateForm.errorMessages.invalidxml')
                              );
                              throw new Error('Invalid XML file');
                            }
                            formik.setFieldError('certificate', undefined);
                            formik.setFieldValue('certificate', xmlBuffer, true);
                          } catch (error) {
                            showMessage(polyglot.t('SSOAzureAdUpdateForm.errorMessages.parsingcsv'), 'error');
                            console.error(':::: ERROR PARSING FILE :::::', error);
                          }
                        }}
                      />
                    </Stack>
                  </Box>
                </Box>
                <Stack sx={{ flexFlow: 'row', my: spacing.m20 }}>
                  <LoaderButton
                    fullWidth
                    disabled={
                      (formik.values.ssoEnabled && !certificate && !formik.values.certificate) ||
                      !formik.isValid ||
                      !formik.dirty ||
                      loading
                    }
                    sizeVariant="large"
                    colorVariant="primary"
                    name={polyglot.t('General.save')}
                    loading={loading}
                  />
                </Stack>
              </Form>
            </FormikProvider>
            <EnableSSODisableMFAConflictConfirmDrawer
              isOpen={confirmEnablement}
              setIsOpen={setConfirmEnablement}
              onClose={() => setConfirmEnablement(false)}
              onConfirm={() => triggerEnableSSOAndDisableMFA()}
            />
          </>
        </>
      ) : (
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center' }} component="section">
                <Box sx={{ width: '100%' }}>
                  <Stack>
                    <UploadInput<Record<string, any>>
                      skipUpload={true}
                      onChange={async (_: any, file: File | undefined) => {
                        try {
                          if (!file) return;
                          const arrayBuffer = await file.arrayBuffer();
                          const xmlBuffer = await arrayBufferToString(arrayBuffer);
                          const xmlValidationResult: Boolean | { err: Record<string, any> } = XMLValidator.validate(
                            xmlBuffer
                          );
                          if (typeof xmlValidationResult !== 'boolean') {
                            formik.setFieldError(
                              'certificate',
                              polyglot.t('SSOAzureAdUpdateForm.errorMessages.invalidxml')
                            );
                            throw new Error('Invalid XML file');
                          }
                          formik.setFieldError('certificate', undefined);
                          formik.setFieldValue('certificate', xmlBuffer, true);
                        } catch (error) {
                          showMessage(polyglot.t('SSOAzureAdUpdateForm.errorMessages.parsingcsv'), 'error');
                          console.error(':::: ERROR PARSING FILE :::::', error);
                        }
                      }}
                    />
                  </Stack>
                </Box>
              </Box>
              <Stack sx={{ flexFlow: 'row', mt: spacing.m20, gap: spacing.g10, justifyContent: 'center' }}>
                <ButtonComponent
                  fullWidth
                  sizeVariant="medium"
                  colorVariant="secondary"
                  onClick={() => {
                    disableSAMLSSO();
                  }}
                  disabled={loading}
                >
                  {polyglot.t('SSOAzureAdUpdateForm.disable')}
                </ButtonComponent>
                <LoaderButton
                  fullWidth
                  disabled={
                    (formik.values.ssoEnabled && !certificate && !formik.values.certificate) ||
                    !formik.isValid ||
                    !formik.dirty ||
                    loading
                  }
                  sizeVariant="medium"
                  colorVariant="primary"
                  name={polyglot.t('SSOAzureAdUpdateForm.update')}
                  loading={loading}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
    </Box>
  );
};
