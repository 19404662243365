import { useMemo } from 'react';

import { CustomProfileFormEndpoints } from '@v2/feature/custom-fields/custom-profile-fields.api';
import { PeopleChangeCreateReportResults } from '@v2/feature/reports/features/create-report/people/people-change-create-report-results.component';
import { PeopleCurrentCreateReportResults } from '@v2/feature/reports/features/create-report/people/people-current-create-report-results.component';
import { ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { DataDomain, FormDataInterface, ReportTypePeopleSelection } from '@v2/feature/reports/reports.interface';
import { includeCustomFieldsColumns } from '@v2/feature/reports/util/people-report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

export const PeopleReportResults = ({
  formData,
  setFormData,
}: {
  readonly formData: FormDataInterface;
  readonly setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
}) => {
  const { data: fields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), { suspense: false });
  const { data: additionalColumns } = useApiClient(ReportsEndpoints.getPeopleReportsAdditionalColumnsOptions(), {
    suspense: false,
  });

  const customFields = useMemo(() => {
    // Include custom fields for People domain if any of the Basic, Details, Family or About entities are selected
    if (formData.domain === DataDomain.People) {
      const response = includeCustomFieldsColumns(formData.entities, fields?.dataFieldsCustom ?? []);
      return response;
    }
    return {};
  }, [fields?.dataFieldsCustom, formData.domain, formData.entities]);

  if (!customFields || !additionalColumns) return null;

  return formData.type === ReportTypePeopleSelection.ChangeReport ? (
    <PeopleChangeCreateReportResults
      formData={formData}
      setFormData={setFormData}
      customFields={customFields}
      additionalColumns={additionalColumns}
    />
  ) : (
    <PeopleCurrentCreateReportResults
      formData={formData}
      setFormData={setFormData}
      customFields={customFields}
      additionalColumns={additionalColumns}
    />
  );
};
