import {
  AttendanceDto,
  AttendanceScheduleDto,
  AttendanceSettingsDto,
  AttendanceTypeDto,
  CreateAttendanceDto,
  CreateAttendanceScheduleDto,
  PersonalWeeklyEntriesTableAndHeaderData,
  UpdateAttendanceSettingsDto,
  UserAttendanceTableAndHeaderData,
  UserAttendanceWeekList,
} from '@v2/feature/attendance/attendance.dto';
import {
  AttendanceStatus,
  ScheduleApprovalFormData,
  ScheduleGeneralFormData,
  SchedulePayrollFormData,
  ScheduleSettingsFormData,
  ScheduleTrackingSettingsFormData,
  ScheduleWeeksPattern,
} from '@v2/feature/attendance/attendance.interface';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import { PaginatedResponse } from '@v2/util/pagination.util';
import axios from 'axios';

export class AttendanceAPI {
  static async findAllAttendancePerUserId(
    page: number,
    pageSize: number,
    searchInput: string,
    filterString: string
  ): Promise<PaginatedResponse<{ userId: number; count: number }>> {
    const queryString = [
      filterString ? filterString : undefined,
      searchInput ? `search=${searchInput}` : undefined,
      page ? `page=${page}` : undefined,
      pageSize ? `pageSize=${pageSize}` : undefined,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/attendances/company/count-per-userId?${queryString}`))?.data;
  }

  static async findTeamAttendancePerUserId(
    page: number,
    pageSize: number,
    searchInput: string,
    filterString: string
  ): Promise<PaginatedResponse<{ userId: number; count: number }>> {
    const queryString = [
      filterString ? filterString : undefined,
      searchInput ? `search=${searchInput}` : undefined,
      page ? `page=${page}` : undefined,
      pageSize ? `pageSize=${pageSize}` : undefined,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/attendances/team/count-per-userId?${queryString}`))?.data;
  }

  static async createAttendanceSchedule(createBody: CreateAttendanceScheduleDto): Promise<number> {
    return (await axios.post('/apiv2/attendance-schedules', createBody)).data;
  }

  static async updateAttendanceScheduleGeneral(scheduleId: number, update: ScheduleGeneralFormData): Promise<void> {
    await axios.patch(`/apiv2/attendance-schedules/${scheduleId}/general`, update);
  }

  static async updateAttendanceSchedulePattern(scheduleId: number, update: ScheduleWeeksPattern): Promise<void> {
    await axios.patch(`/apiv2/attendance-schedules/${scheduleId}/pattern`, update);
  }

  static async updateAttendanceScheduleTrackingSettings(
    scheduleId: number,
    update: ScheduleTrackingSettingsFormData
  ): Promise<void> {
    await axios.patch(`/apiv2/attendance-schedules/${scheduleId}/tracking-settings`, update);
  }

  static async updateAttendanceSchedulePayroll(scheduleId: number, update: SchedulePayrollFormData): Promise<void> {
    await axios.patch(`/apiv2/attendance-schedules/${scheduleId}/payroll`, update);
  }

  static async updateAttendanceScheduleSettings(scheduleId: number, update: ScheduleSettingsFormData): Promise<void> {
    await axios.patch(`/apiv2/attendance-schedules/${scheduleId}/schedule-settings`, update);
  }

  static async updateAttendanceScheduleApproval(scheduleId: number, update: ScheduleApprovalFormData): Promise<void> {
    await axios.patch(`/apiv2/attendance-schedules/${scheduleId}/approval`, update);
  }

  static async deleteAttendanceScheduleById(id: number): Promise<void> {
    await axios.delete(`/apiv2/attendance-schedules/${id}`);
  }

  static async createAttendanceLog(createData: CreateAttendanceDto): Promise<void> {
    await axios.post('/apiv2/attendances', createData);
  }

  static async updateAttendanceLogById(id: number, updateData: CreateAttendanceDto): Promise<void> {
    await axios.patch(`/apiv2/attendances/${id}`, updateData);
  }

  static async getPersonalAttendanceEntriesByWeekNo(
    userId: number,
    weekNo: number,
    year: number
  ): Promise<PersonalWeeklyEntriesTableAndHeaderData> {
    return (await axios.get(`/apiv2/attendances/${userId}/weekly/${weekNo}/${year}`)).data;
  }

  static async getAttendanceTableByUserId(
    userId: number,
    filterString: string
  ): Promise<UserAttendanceTableAndHeaderData> {
    const queryString = [filterString ? filterString : undefined].filter(Boolean).join('&');
    return (await axios.get(`/apiv2/attendances/${userId}/table/by-user?${queryString}`)).data;
  }

  static async getWeekListByUserId(
    userId: number,
    year: number,
    searchInput: string
  ): Promise<PaginatedResponse<UserAttendanceWeekList>> {
    const queryString = [`year=${year}`, searchInput ? `search=${searchInput}` : undefined].filter(Boolean).join('&');
    return (await axios.get(`/apiv2/attendances/${userId}/week-list?${queryString}`))?.data;
  }

  static async getAttendanceByUserIdAndLogDate(userId: number, logDate: string): Promise<AttendanceDto> {
    return (await axios.get(`/apiv2/attendances/${userId}/${logDate}/by-date`)).data;
  }

  static async submitUserAttendanceByWeek(userId: number, year: number, weekNo: number): Promise<void> {
    await axios.patch(`/apiv2/attendances/submit/week/${weekNo}/year/${year}/users/${userId}`);
  }

  static async approveAttendanceById(userId: number, attendanceId: number, status: AttendanceStatus): Promise<void> {
    // TODO: @approval
    await axios.patch(`/apiv2/attendances/${attendanceId}/users/${userId}/single-approval`, { status });
  }

  static async approveUserAttendanceByWeek(userId: number, year: number, weekNo: number): Promise<void> {
    // TODO: @approval
    await axios.patch(`/apiv2/attendances/approve/week/${weekNo}/year/${year}/users/${userId}`);
  }

  static async rejectUserAttendanceByWeek(userId: number, year: number, weekNo: number): Promise<void> {
    // TODO: @approval - should be the same endpoint as approveUserAttendanceByWeek
    await axios.patch(`/apiv2/attendances/reject/week/${weekNo}/year/${year}/users/${userId}`);
  }

  static async approveUsersRequestByWeekOrMonth(userId: number, update: any): Promise<void> {
    // TODO: @approval should be a single endpoint as the one from above
    await axios.patch(`/apiv2/attendances/bulk-approve/users/${userId}`, update);
  }

  static async rejectUsersRequestByWeekOrMonth(userId: number, update: any): Promise<void> {
    // TODO: @approval should be a single endpoint as the one from above
    await axios.patch(`/apiv2/attendances/bulk-reject/users/${userId}`, update);
  }

  static async deleteAttendanceById(id: number): Promise<void> {
    await axios.delete(`/apiv2/attendances/${id}`);
  }

  static async deleteUserAttendancesByWeek(userId: number, year: number, weekNo: number): Promise<void> {
    await axios.delete(`/apiv2/attendances/week/${weekNo}/year/${year}/users/${userId}`);
  }

  static async autopopulateUserAttendancesByWeek(
    userId: number,
    weekNo: number,
    year: number,
    monday: string
  ): Promise<void> {
    await axios.post(`/apiv2/attendances/autopopulate/week/${weekNo}/year/${year}/users/${userId}`, { monday });
  }

  static async copyUserAttendancesFromLastWeek(userId: number, weekNo: number, year: number): Promise<void> {
    await axios.post(`/apiv2/attendances/copy-last-week/week/${weekNo}/year/${year}/users/${userId}`);
  }

  static async updateAttendanceSettings(updateData: UpdateAttendanceSettingsDto): Promise<void> {
    await axios.put('/apiv2/attendance-settings', updateData);
  }
}

export class AttendanceEndpoints {
  static getAttendanceSchedules(): Endpoint<AttendanceScheduleDto[]> {
    return {
      url: '/apiv2/attendance-schedules',
    };
  }

  static getTeamAttendanceSchedules(): Endpoint<AttendanceScheduleDto[]> {
    return {
      url: '/apiv2/attendance-schedules/team',
    };
  }

  static getAttendanceScheduleById(id: number): Endpoint<AttendanceScheduleDto> {
    return {
      url: `/apiv2/attendance-schedules/${id}`,
    };
  }

  static getUserAttendanceSchedule(userId: number): Endpoint<AttendanceScheduleDto> {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}`,
    };
  }

  static getUserAttendanceScheduleByAttendanceId(
    userId: number,
    attendanceId: number
  ): Endpoint<AttendanceScheduleDto> {
    // TODO: @attendance - should we get current schedule, at a specific time === should get attendance schedule by attendance id if attendanceId is present.
    return {
      url: `/apiv2/attendance-schedules/users/${userId}/attendances/${attendanceId}`,
    };
  }

  static getCompanyAttendanceTypes(): Endpoint<AttendanceTypeDto[]> {
    return {
      url: `/apiv2/attendance-types`,
    };
  }

  static getTeamAttendanceTypes(): Endpoint<AttendanceTypeDto[]> {
    return {
      url: `/apiv2/attendance-types/team`,
    };
  }

  // TODO: @approval is this protected by approval rule + is it needed
  static canApproveRejectSchedule(userId: number): Endpoint<{ canApproveOrReject: boolean; isAutoapprove: boolean }> {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}/can-approve-and-reject`,
    };
  }

  static getMyAttendanceRequests(): Endpoint<AttendanceDto[]> {
    return {
      url: '/apiv2/attendances/me',
    };
  }

  static getUserAttendanceRequests(userId: number): Endpoint<AttendanceDto[]> {
    return {
      url: `/apiv2/attendances/users/${userId}`,
    };
  }

  static getScheduleMembers(scheduleId: number): Endpoint<{ userId: number; name: string }[]> {
    return {
      url: `/apiv2/attendance-schedules/${scheduleId}/members`,
    };
  }

  static getCompanyAttendanceSettings(): Endpoint<AttendanceSettingsDto> {
    return {
      url: '/apiv2/attendance-settings',
    };
  }

  static getAttendanceById(id: number, userId: number): Endpoint<AttendanceDto> {
    return {
      url: `/apiv2/attendances/${id}/${userId}/by-attendance`,
    };
  }

  static getAttendanceByUserIdAndLogDate(userId: number, logDate: string): Endpoint<AttendanceDto> {
    return {
      url: `/apiv2/attendances/${userId}/${logDate}/by-date`,
    };
  }
}
