import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PolicyNotificationsFormData } from '@v2/feature/absence/absence.interface';
import {
  ABSENCE_POLICY_NOTIFICATIONS_REMINDERS_HOURS_OPTIONS,
  getAbsencePolicyNotificationsValidationSchema,
} from '@v2/feature/absence/company/policies/pages/absence-policy.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyNotificationsEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly slackChannels: readonly { id: string; name: string }[];
  readonly refresh: () => Promise<void>;
}

export const PolicyNotificationsEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  slackChannels,
  refresh,
}: PolicyNotificationsEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const SlackChannelsOptions = useMemo(
    () =>
      slackChannels.map((channel) => ({
        value: channel.id,
        label: channel.name,
      })),
    [slackChannels]
  );

  const onSubmit = useCallback(
    async (values: PolicyNotificationsFormData) => {
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyNotifications(absencePolicy.id, values);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );

  const formik = useFormik<PolicyNotificationsFormData>({
    initialValues: {
      notificationsEnabled: absencePolicy.notificationsEnabled,
      remindersSettings: {
        ...absencePolicy.remindersSettings,
        slack: slackChannels.length > 0 ? absencePolicy.remindersSettings.slack : false, // if no channels found - disable slack
      },
    },
    validationSchema: getAbsencePolicyNotificationsValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsenceUtil.notifications')}</Typography>

          <Typography variant="title3">{polyglot.t('AbsenceUtil.absenceRequests')}</Typography>
          <CheckboxComponent
            label={
              <Typography variant="title4">
                {polyglot.t('EditPolicyNotificationsDrawerContent.sendNotifications')}
              </Typography>
            }
            name="notificationsEnabled"
            checked={formik.values.notificationsEnabled}
            onChange={formik.handleChange}
          />

          <Typography variant="title3" sx={{ mt: '10px' }}>
            {polyglot.t('AbsenceUtil.absenceReminder')}
          </Typography>

          <Box sx={{ display: 'flex', gap: spacing.g20, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', gap: spacing.g15, flexDirection: 'column' }}>
              <CheckboxComponent
                label={
                  <Typography
                    variant="title4"
                    sx={{ color: slackChannels.length === 0 ? themeColors.Grey : undefined }}
                  >
                    {polyglot.t('EditPolicyNotificationsDrawerContent.channel')}
                  </Typography>
                }
                name="remindersSettings.slack"
                checked={formik.values.remindersSettings.slack ?? false}
                disabled={slackChannels.length === 0}
                onChange={formik.handleChange}
              />
            </Box>
          </Box>

          {formik.values.remindersSettings.slack && slackChannels.length > 0 && (
            <SelectComponent
              name="remindersSettings.slackChannels"
              label={polyglot.t('EditPolicyNotificationsDrawerContent.reminderSetting')}
              options={SlackChannelsOptions}
              value={formik.values.remindersSettings.slackChannels[0]}
              compareValue={formik.values.remindersSettings.slackChannels[0]}
              onChange={(e) => {
                const value = e.target.value;
                formik.setFieldValue('remindersSettings.slackChannels', [value]);
              }}
              error={
                !!formik.errors.remindersSettings?.slackChannels && formik.touched.remindersSettings?.slackChannels
              }
              helperText={
                formik.touched.remindersSettings?.slackChannels &&
                (formik.errors.remindersSettings?.slackChannels as string)
              }
            />
          )}

          {formik.values.remindersSettings.slack && (
            <SelectComponent
              name="remindersSettings.remindersScheduledHour"
              label={polyglot.t('EditPolicyNotificationsDrawerContent.reminderSettingSchedule')}
              options={ABSENCE_POLICY_NOTIFICATIONS_REMINDERS_HOURS_OPTIONS}
              value={formik.values.remindersSettings.remindersScheduledHour}
              compareValue={formik.values.remindersSettings.remindersScheduledHour}
              onChange={formik.handleChange}
              error={
                !!formik.errors.remindersSettings?.remindersScheduledHour &&
                formik.touched.remindersSettings?.remindersScheduledHour
              }
              helperText={
                formik.touched.remindersSettings?.remindersScheduledHour &&
                (formik.errors.remindersSettings?.remindersScheduledHour as string)
              }
            />
          )}

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
