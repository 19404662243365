import React from 'react';

import { Box } from '@mui/material';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

export const AttendancePenaltyRuleCell = ({
  percent,
  width,
  variant = 'caption',
  color,
}: {
  readonly percent: number;
  width?: string;
  variant?: TypographyVariant;
  color?: keyof typeof themeColors;
}) => {
  const { polyglot } = usePolyglot();
  return (
    <Box sx={{ width }}>
      <Typography variant={variant} color={color}>
        {percent === 0
          ? polyglot.t('AttendanceModule.Penalties.warning')
          : polyglot.t('AttendanceModule.Penalties.dayRateTimesPercent', { percent })}
      </Typography>
    </Box>
  );
};
