import { Box, Button } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { linkButtonSx, tabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

type RowDataType<T> = { id: T };

interface SelectDeselectRowsProps<T> {
  readonly setSelectionModel: React.Dispatch<React.SetStateAction<T[]>>;
  readonly selectionModel: T[];
  readonly rows: readonly RowDataType<T>[];
  readonly hideSelectAll?: boolean;
}

export function SelectDeselectIdRows<T>({
  setSelectionModel,
  selectionModel,
  rows,
  hideSelectAll = false,
}: SelectDeselectRowsProps<T>): JSX.Element | null {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g15 }}>
      {selectionModel.length > 0 && (
        <>
          <Button
            key={selectionModel.length}
            sx={{
              ...tabsFilterBtnSx,
            }}
            onClick={() => {
              setSelectionModel([]);
            }}
            disableRipple
          >
            {`${selectionModel?.length} selected`}
            &nbsp;
            <Close {...iconSize} stroke={themeColors.DarkGrey} fill={themeColors.DarkGrey} />
          </Button>
          {!hideSelectAll && selectionModel.length < rows.length && (
            <Button
              sx={{
                ...linkButtonSx,
                textDecoration: 'none',
                ...themeFonts.caption,
                color: themeColors.DarkGrey,
                '&:hover': {
                  background: 'none',
                },
              }}
              onClick={() => {
                setSelectionModel(rows.map((r) => r.id));
              }}
            >
              {polyglot.t('SelectDeselectRows.selectAll')}
            </Button>
          )}
        </>
      )}
    </Box>
  );
}
