import React, { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceUpdateSuperadminDto } from '@v2/feature/device/device.dto';
import { DeviceType } from '@v2/feature/device/device.interface';
import { DeviceTypesValueLabelOptions } from '@v2/feature/device/device.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import { FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as FullScreen } from '@/images/side-bar-icons/FullScreen.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE } from '@/lib/routes';

interface StockDeviceEditDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly device?: DeviceDto;
  readonly refresh: () => Promise<void>;
}

export const StockDeviceEditDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  device,
  refresh,
}: StockDeviceEditDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    {device ? (
      <StockDeviceEditDrawerContent
        device={device}
        onAdd={async () => {
          await refresh();
          onClose();
          setIsOpen(false);
        }}
      />
    ) : (
      <Box>
        <Typography variant="title4">Device not selected.</Typography>
      </Box>
    )}
  </DrawerModal>
);

interface EditDeviceForm {
  serialNumber: string | null;
  internalNotes: string | null;
  type: DeviceType;
}

interface StockDeviceEditDrawerContentProps {
  readonly device: DeviceDto;
  readonly onAdd: () => Promise<void>;
}

export const StockDeviceEditDrawerContent = ({ device, onAdd }: StockDeviceEditDrawerContentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const history = useHistory();

  const formik = useFormik<EditDeviceForm>({
    initialValues: {
      serialNumber: device.serialNumber ?? null,
      internalNotes: device.internalNotes ?? null,
      type: device.type ?? DeviceType.Laptop,
    },
    validationSchema: yup.object({
      serialNumber: yup.string().nullable().notRequired(),
      internalNotes: yup.string().nullable().notRequired(),
      type: yup.string().required('Device type is required'),
    }),
    onSubmit: async (formData: EditDeviceForm) => {
      try {
        setLoading(true);
        const update: DeviceUpdateSuperadminDto = {
          serialNumber: formData.serialNumber,
          internalNotes: formData.internalNotes,
          type: formData.type,
        };
        await DeviceAPI.updateDeviceByIdAsSuperadmin(device.id, update);
        showMessage('Device successfully updated.', 'success');
        await onAdd();
      } catch (error) {
        showMessage(`Could not update device: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Box sx={drawerContentSx}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="title2">Update stock device</Typography>
          <IconButton
            title="more-details"
            onClick={() => {
              history.push(generatePath(SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, { deviceId: device.id }));
            }}
            sx={tableIconButtonSx}
          >
            <FullScreen {...actionIconSize} />
          </IconButton>
        </Box>
        <Typography variant="title3">{device.modelName}</Typography>

        <SelectComponent
          name="type"
          label="Type"
          options={DeviceTypesValueLabelOptions}
          value={formik.values.type ?? undefined}
          compareValue={formik.values.type ?? undefined}
          onChange={formik.handleChange}
          error={formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
        />

        <TextfieldComponent
          label="Serial Number"
          name="serialNumber"
          value={formik.values.serialNumber}
          onChange={formik.handleChange}
          error={formik.touched.serialNumber && !!formik.errors.serialNumber}
          helperText={formik.touched.serialNumber && formik.errors.serialNumber}
          autoFocus
          clearText={() => formik.setFieldValue('serialNumber', '')}
        />
        <TextfieldComponent
          label="Internal notes"
          name="internalNotes"
          value={formik.values.internalNotes}
          onChange={formik.handleChange}
          error={formik.touched.internalNotes && !!formik.errors.internalNotes}
          helperText={formik.touched.internalNotes && formik.errors.internalNotes}
          clearText={() => formik.setFieldValue('internalNotes', '')}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Update"
            fullWidth
            loading={loading}
            onClick={() => formik.handleSubmit()}
            colorVariant="primary"
            sizeVariant="medium"
          />
        </Box>
      </Box>
    </FormikProvider>
  );
};
