import { ReviewCycle, UpsertReviewers } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewSection } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';

export type ReviewEntry = {
  createdAt: Date;
  updatedAt: Date;
  id: string;
  cycleId: string;
  reviewId: string;
  revieweeId: number;
  reviewerManagerId?: number;
  reviewerSelfId?: number;
  reviewerPeerId?: number;
  reviewerUpwardId?: number;
  startDate: string;
  endDate: string;
  entryState: EntryState;
  cycle?: ReviewCycle;
};

export type ReviewEntryUpdate = Pick<ReviewEntry, 'id' | 'cycleId' | 'revieweeId' | 'entryState'>;
export type ReviewEntryUpdateAndPeerInvite = { entry: ReviewEntryUpdate; reviewersToAdd: UpsertReviewers };
export type EntryDetail = {
  cycle: ReviewCycle;
  entry: ReviewEntry;
  questions: ReviewQuestion[];
  sections: ReviewSection[];
};

export enum EntryState {
  NotStarted = 'not_started',
  Draft = 'draft',
  Submitted = 'submitted',
}
