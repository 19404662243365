import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { UserCustomBenefitRequestType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { BenefitRequestActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-request-action-item.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateUserCustomBenefitRequestType } from '@v2/infrastructure/i18n/translate.util';

import { ReactComponent as Salary } from '@/images/dashboard-icons/Salary.svg';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { BenefitRequestsBigWidget, BenefitRequestTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const BenefitRequestTodoComponent = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: BenefitRequestsBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<BenefitRequestTodo | undefined>(undefined);

  const getTitle = (name: string, requestType: UserCustomBenefitRequestType): string => {
    return `${polyglot.t(name)} has a benefit ${translateUserCustomBenefitRequestType(requestType, polyglot)} request`;
  };

  return (
    <Box>
      {todo.customBenefitRequests.map((request) => (
        <TodoCard
          key={`benefit-request-${request.id}`}
          icon={<Salary {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(request.userName, request.requestType)}
            </Typography>
          }
          actionOnclick={() => setSelectedRow(request)}
          showBorder={!setLastChild}
          userAvatar={request.userId ? <UserAvatar key={request.id} userId={request.userId} size="xxxsmall" /> : <></>}
        />
      ))}

      {selectedRow && (
        <BenefitRequestActionItem
          benefitRequestTodo={selectedRow}
          refresh={async () => {
            setSelectedRow(undefined);
            if (refreshTodos) await refreshTodos();
          }}
          onClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
