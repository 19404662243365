import { useContext, useEffect, useReducer, useState } from 'react';

import { Typography } from '@mui/material';
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal,
} from '@v2/feature/documents/documents.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { CreateZeltDocument, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DocumentEmptyOverview } from '@/v2/feature/documents/components/document-empty-overview.component';
import { DocumentFormModal } from '@/v2/feature/documents/components/document-form-modal.component';
import { PersonalDocuments } from '@/v2/feature/documents/document-me/components/personal-documents.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { DocumentAction } from '@/v2/feature/documents/documents.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly userId: number;
  readonly userName: string;
}

const initialState = initialiseState([]);

export const UserProfileDocument = ({ userId, userName }: Props): JSX.Element => {
  const [documentFormMode, setDocumentFormMode] = useState<DocumentAction>('add');
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<readonly ZeltDocument[]>([]);
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(['documents:all'], getScopesContext(globalState.user));
  const [filterValue, setFilterValue] = useState<string>('');
  const [showMessage] = useMessage();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const [documents, types] = await Promise.all([
          DocumentAPI.listUserDocuments(userId),
          DocumentAPI.listDocumentTypes(),
        ]);
        setDocuments(documents);
        documentDispatch({ kind: 'set_types', value: types });
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [userId, showMessage, documentDispatch]);

  const completeSave = async () => {
    try {
      let documentObject: CreateZeltDocument = {
        name: documentState.name || '',
        type: documentState.type?.value ?? '',
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid,
        note: documentState.note,
        expirySettings: documentState?.expirySettings ?? { expiryDate: '', documentExpires: false },
      };
      if (documentState.id && documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage('Document updated successfully.', 'success');
      } else if (documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.createDocument(documentObject);
        showMessage('Document saved successfully.', 'success');
      }
      await getDocuments();
    } catch (error) {
      showMessage(`Could not save the document. ${nestErrorMessage(error)}`, 'error');
    }
  };

  function closeUploadModal() {
    setOpen(false);
  }

  const getDocuments = async () => {
    try {
      const documents = await DocumentAPI.listUserDocuments(userId);
      setDocuments(documents);
    } catch (error) {
      showMessage(`Could not retrieve the document. ${nestErrorMessage(error)}`, 'error');
    }
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2 }}>Documents</Typography>} />
      {!loading && documents.length < 1 ? (
        <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
          <DocumentEmptyOverview
            onUploadClick={() => {
              documentDispatch({ kind: 'add_document', owner: { label: userName, value: userId } });
              setDocumentFormMode('add');
              setFilterValue(
                getDocumentParentCategory(
                  'me',
                  'add',
                  undefined,
                  currentUserIsDocumentAdmin,
                  documentState.types as ZeltDocumentType[]
                )
              );
              setOpen(true);
            }}
            onRequestClick={() => {
              documentDispatch({ kind: 'request_document', owner: { label: userName, value: userId }, reach: 'me' });
              setDocumentFormMode('request');
              setFilterValue(
                getDocumentParentCategory(
                  'me',
                  'request',
                  undefined,
                  currentUserIsDocumentAdmin,
                  documentState.types as ZeltDocumentType[]
                )
              );
              setOpen(true);
            }}
            isAdmin={currentUserIsDocumentAdmin}
          />
        </ContentWrapper>
      ) : (
        <PersonalDocuments
          userId={userId}
          userName={userName}
          setDocumentFormMode={setDocumentFormMode}
          documentState={documentState}
          documentDispatch={documentDispatch}
          setOpen={setOpen}
          loading={loading}
          documents={documents}
          setFilterValue={setFilterValue}
        />
      )}
      <DocumentFormModal
        mode={documentFormMode}
        state={documentState}
        currentUserIsAdmin={currentUserIsDocumentAdmin}
        dispatch={documentDispatch}
        newDocumentTypes={documentState.types as ZeltDocumentType[]}
        onClose={closeUploadModal}
        onSubmit={completeSave}
        setOpen={setOpen}
        open={open}
        setLoading={setLoading}
        getDocuments={getDocuments}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      />
    </RootStyle>
  );
};
