import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewTemplateAPI } from '@/v2/feature/growth/reviews/api-client/review-template.api';
import {
  ReviewTemplate,
  ReviewTemplateCreation,
} from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const UpsertReviewTemplateModal = ({
  isOpen,
  setIsOpen,
  onClose,
  template,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  template: ReviewTemplate | undefined;
}) => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <CreateReviewTemplateContent onClose={onClose} template={template} />
    </DrawerModal>
  );
};

const CreateReviewTemplateContent = ({
  onClose,
  template,
}: {
  onClose: () => void;
  template: ReviewTemplate | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const TemplateSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().max(255, polyglot.t('validation.selectValid')).required(),
      }),
    [polyglot]
  );

  const formik = useFormik<ReviewTemplateCreation>({
    initialValues: {
      name: template ? template.name : '',
    },
    enableReinitialize: true,
    validationSchema: TemplateSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (template) {
          await ReviewTemplateAPI.updateReviewTemplate({ id: template.id, ...values });
          showMessage('Successfully updated', 'success');
        } else {
          const createdTemplate = await ReviewTemplateAPI.createReviewTemplate(values);
          formik.resetForm();
          routerHistory.push(
            generatePath(SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE, {
              templateId: createdTemplate?.id,
            })
          );
          showMessage('Successfully created', 'success');
        }
        onClose();
      } catch (error) {
        showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            <Box sx={{ mb: spacing.m24 }}>
              <Typography variant="title2">{template ? 'Edit' : 'New'} template</Typography>
            </Box>
            <TextfieldComponent
              name="name"
              label="Name"
              value={formik.values.name}
              type="text"
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={(formik.touched.name && formik.errors.name) ?? ' '}
              clearText={() => formik.setFieldValue('name', '')}
              disabled={loading}
              required={true}
            />
          </Box>

          <Box sx={spacing.mt30}>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
