import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';

import { AccountStatus } from '@/lib/users';
import { UUIDV4 } from '@/lib/uuid';
import { FIELD_CATEGORY_FOR_CONTRACT_TEMPLATE } from '@/v2/feature/templates/templates.interface';
import { UserImportDto } from '@/v2/feature/user/features/user-import/user-import.dto';

export enum UserAccountTypes {
  Admin = 'Admin',
  ProfileOwner = 'Profile Owner',
  OtherProfiles = 'Other Profiles',
  Manager = 'Manager',
}

export type UserEvent = {
  readonly userId: number;
  readonly companyId: number;
  readonly updatedBy: number;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly id: number;
  readonly status: string;
  readonly createdBy: number;
  readonly effectiveDate: Date;
  readonly changeReason: string;
  readonly effectiveEndDate?: Date;
};

export type UserAccountType = keyof typeof UserAccountTypes;

export type UserImportKeyType = keyof UserImportDto;

export type UserAccountTypesConfig = Record<UserAccountType, boolean>;

export type UserTypes = 'admin' | 'manager' | 'user';

export interface MissingField {
  readonly cardId: CustomProfileFormType | UUIDV4;
  readonly text: string;
  readonly details: {
    fieldId: string;
    text: string;
  }[];
  readonly type: UserTypes[];
}

export interface MissingFieldsByDomain {
  name?: string;
  readonly payroll?: MissingField[];
  readonly personal?: MissingField[];
}

export interface MissingFieldForContractTemplate {
  readonly category: FIELD_CATEGORY_FOR_CONTRACT_TEMPLATE;
  readonly fieldId: string;
}

export interface LocaleProps {
  language: string;
}

export interface PrivacyProps {
  showBirthday: boolean;
}

export interface PersonalSettingsProps {
  language: string;
  showBirthday: boolean;
}

export interface UserBenefitsTablePensionSchemeData {
  id: number;
  payrollId: number | null;
  providerName: string | null;
  displayName: string | null;
  externalId: string | null;
}

export type UserBenefitsTableUserCustomBenefitData = Pick<
  UserCustomBenefitDto,
  | 'customBenefitId'
  | 'effectiveDate'
  | 'openingBalance'
  | 'employerContribution'
  | 'employeeContribution'
  | 'dependants'
> & {
  currency: CurrencyShort;
};

export interface TableStructure {
  pensionSchemes: UserBenefitsTablePensionSchemeData[];
  insurance:
    | {
        id: number;
        providerName: string;
      }
    | undefined;
  customBenefits: { id: number; name: string }[];
}

export type UserBenefitsTableUserInsuranceData = Pick<
  UserInsuranceDto,
  | 'status'
  | 'startDate'
  | 'endDate'
  | 'monthlyPremium'
  | 'monthlyContribution'
  | 'dependants'
  | 'dependantsMonthlyPremium'
  | 'dependantsMonthlyContribution'
  | 'dependantsStatus'
> & {
  currency: CurrencyShort;
};

export type UserBenefitsTableUserPensionData = Pick<
  UserPensionDto,
  | 'inPension'
  | 'payrollId'
  | 'startDate'
  | 'pensionProviderId'
  | 'pensionProviderName'
  | 'employerContribution'
  | 'employeeContribution'
> & { state: string | null };

export interface UserBenefitsData {
  userId: number;
  name: string;
  pension: UserBenefitsTableUserPensionData | undefined;
  insurance: UserBenefitsTableUserInsuranceData | undefined;
  customBenefits: UserBenefitsTableUserCustomBenefitData[];
  entityName: string | null;
}

const INVITED_ACCOUNT_STATUSES = [AccountStatus.Invited, AccountStatus.InvitedToOnboard];

export const INVITABLE_USER_ACCOUNT_STATUS = [AccountStatus.Created, ...INVITED_ACCOUNT_STATUSES];

export const ACTIVE_INVITABLE_USER_ACCOUNT_STATUS_FOR_BILLING = [AccountStatus.Active, ...INVITED_ACCOUNT_STATUSES];
