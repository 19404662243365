import { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';

import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MissingFieldsDetail } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { PersonalInformationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-form.component';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshPayroll?: () => Promise<void>;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingPersonal = ({ userId, refreshPayroll, missingFieldsForCategory }: Props) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: personalData } = useApiClient(UserEndpoints.getUserPersonalInfo(userId), {
    suspense: false,
  });

  return (
    <>
      <Stack sx={{ flexFlow: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Typography sx={themeFonts.caption}>{polyglot.t('PayrollMissing.personalDetails')}</Typography>
          <IconButton sizeVariant="small" colorVariant="secondary" onClick={() => setIsModalOpen(true)}>
            <Plus width="14px" height="14px" />
          </IconButton>
        </Box>
        {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      </Stack>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        {personalData ? (
          <PersonalInformationForm
            initialValues={personalData}
            userId={userId}
            onSubmit={() => refreshPayroll?.()}
            onClose={() => setIsModalOpen(false)}
            showEmployee
            customSchema={{
              // dob is required for payroll
              dob: Yup.string().test(dateFieldTest).nullable().required(polyglot.t('PayrollMissing.dobRequired')),
            }}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DrawerModal>
    </>
  );
};
