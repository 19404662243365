import { useCallback, useEffect, useState } from 'react';

import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  ONBOARDING_INTRO_ROUTE,
  ONBOARDING_NEW_USER_ROUTE,
  ONBOARDING_ROUTE,
  ONBOARDING_USER_STATUS_APPS_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ROUTE,
  ONBOARDING_USER_STATUS_ROUTE,
} from '@/lib/routes';
import { OnboardingAppsPage } from '@/v2/feature/onboarding/onboarding-apps/onboarding-apps.page';
import { OnboardingDeviceInventoryPage } from '@/v2/feature/onboarding/onboarding-device/onboarding-device-inventory.page';
import { OnboardingDeviceOrderPage } from '@/v2/feature/onboarding/onboarding-device-order.page';
import { OnboardingIntro } from '@/v2/feature/onboarding/onboarding-intro.page';
import { OnboardingUserStatusPage } from '@/v2/feature/onboarding/onboarding-user-status.page';
import { OnboardingUserTable } from '@/v2/feature/onboarding/onboarding-user-table.page';
import { OnboardingUserSetup } from '@/v2/feature/onboarding/user-setup/onboarding-user-setup.page';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';

export const OnboardingRouter = (): JSX.Element => {
  const [templates, setTemplates] = useState<OnboardingTemplate[]>();
  const [showMessage] = useMessage();

  const refresh = useCallback(async () => {
    try {
      const [templates] = await Promise.all([OnboardingAPI.getOnboardingTemplates()]);
      setTemplates(templates);
    } catch (error) {
      showMessage(`Onboarding failed to load. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (!templates) {
    return <OnboardingIntro loading />;
  }

  return (
    <Switch>
      <Route path={ONBOARDING_NEW_USER_ROUTE} component={OnboardingUserSetup} />
      <Route path={ONBOARDING_USER_STATUS_APPS_ROUTE} component={OnboardingAppsPage} />
      <Route path={ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE} component={OnboardingDeviceOrderPage} />
      <Route path={ONBOARDING_USER_STATUS_DEVICE_ROUTE} component={OnboardingDeviceInventoryPage} />
      <Route path={ONBOARDING_USER_STATUS_ROUTE} component={OnboardingUserStatusPage} />
      <RouteScopesControl scopes={['user.onboard:all']} path={ONBOARDING_INTRO_ROUTE}>
        {templates.length > 0 && <Redirect to={ONBOARDING_ROUTE} />}
        {templates.length === 0 && <OnboardingIntro refresh={refresh} />}
      </RouteScopesControl>
      <RouteScopesControl scopes={['user.onboard:all']} path={ONBOARDING_ROUTE}>
        {templates.length > 0 && <OnboardingUserTable />}
        {templates.length === 0 && <Redirect to={ONBOARDING_INTRO_ROUTE} />}
      </RouteScopesControl>
    </Switch>
  );
};
