import { useEffect, useState } from 'react';

import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { BenefitRequestViewDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-request-view-drawer.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { BenefitRequestTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const BenefitRequestActionItem = ({
  benefitRequestTodo,
  refresh,
  onClose,
}: {
  readonly benefitRequestTodo: BenefitRequestTodo | undefined;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}) => {
  const { data: benefitRequest } = useApiClient(
    benefitRequestTodo
      ? CustomBenefitEndpoints.getUserCustomBenefitRequestById(
          benefitRequestTodo.userId,
          benefitRequestTodo.customBenefitId,
          benefitRequestTodo.userCustomBenefitId,
          benefitRequestTodo.id
        )
      : null,
    { suspense: false }
  );

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsViewOpen(Boolean(benefitRequestTodo));
  }, [benefitRequestTodo]);

  return benefitRequest ? (
    <BenefitRequestViewDrawer
      isOpen={isViewOpen}
      setIsOpen={setIsViewOpen}
      request={benefitRequest}
      refresh={refresh}
      onClose={onClose}
    />
  ) : null;
};
