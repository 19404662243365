import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { CreateExistingDeviceDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType, DeviceType } from '@v2/feature/device/device.interface';
import {
  DeviceOSValueLabelOptions,
  DeviceTypesValueLabelOptions,
  MDMABLE_DEVICE_TYPES,
} from '@v2/feature/device/device.util';
import { useDepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { SiteAPI } from '@/api-client/site.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_ROUTE } from '@/lib/routes';

interface AddExistingDeviceDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const AddExistingDeviceDrawer = ({ isOpen, setIsOpen, refresh }: AddExistingDeviceDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AddExistingDeviceDrawerContent refresh={refresh} />
    </DrawerModal>
  );
};

interface AddExistingDeviceFormData {
  assignToEmployee: boolean;
  userId: number | null;
  siteId: number | null;
  os: string;
  modelName: string;
  deviceName: string;
  serialNumber: string | null;
  type: string;
  isDep: boolean;
  depDeviceUsername: string | null;
}

interface AddExistingDeviceDrawerContentProps {
  readonly refresh: () => Promise<void>;
}

const AddExistingDeviceDrawerContent = ({ refresh }: AddExistingDeviceDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);

  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const location = useLocation();
  const isAdmin = location.pathname.includes(DEVICES_COMPANY_ROUTE) && hasScopes(['devices:all'], scopesContext);
  const [globalState] = useContext(GlobalContext);
  const { depAccountDetails } = useDepAccountDetails(
    globalState.user.company.companyId + globalState.user.company.name
  );
  const [showMessage] = useMessage();
  const formik = useFormik<AddExistingDeviceFormData>({
    initialValues: {
      assignToEmployee: true,
      userId: isAdmin ? null : state.user.userId,
      siteId: null,
      os: '',
      modelName: '',
      deviceName: '',
      serialNumber: null,
      isDep: false,
      type: DeviceType.Laptop,
      depDeviceUsername: '',
    },
    validationSchema: yup.object({
      assignToEmployee: yup.boolean().required(),
      userId: yup.number().when('assignToEmployee', {
        is: true,
        then: (schema) => schema.typeError('Employee should be selected').required('Employee should be selected'),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
      siteId: yup.number().when('assignToEmployee', {
        is: false,
        then: (schema) => schema.typeError('A site should be selected').required('A site should be selected'),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
      type: yup.string().required('Type is required'),
      os: yup.string().nullable().notRequired(),
      modelName: yup.string().required('Model Name is required'),
      deviceName: yup.string().nullable().notRequired(),
      serialNumber: yup.string().nullable().notRequired(),
      isDep: yup.boolean().nullable().notRequired(),
      depDeviceUsername: yup.string().nullable().notRequired(),
    }),
    onSubmit: async (formData: AddExistingDeviceFormData) => {
      let isDeviceAdded = false;
      let isDepCorrect = false;
      if ((formData.assignToEmployee && !formData.userId) || (!formData.assignToEmployee && !formData.siteId)) {
        showMessage('A user or a site should be selected.', 'error');
        return;
      }
      try {
        setLoading(true);
        if (formData.assignToEmployee && !isAdmin) {
          const pattern = /\/users\/(\d+)\/devices/;
          const userID = location.pathname.match(pattern);
          if (userID && userID[1]) {
            formData.userId = Number(userID[1]);
          }
        }
        if (formData.isDep && !formData.serialNumber && !formData.depDeviceUsername) {
          throw new Error();
        }
        isDepCorrect = true;
        const existingDeviceData: CreateExistingDeviceDto = {
          possessionType: formData.assignToEmployee ? DevicePossessionType.User : DevicePossessionType.CompanySite,
          possessionId: formData.assignToEmployee ? formData.userId! : formData.siteId!,
          modelName: formData.modelName,
          deviceName: formData.deviceName,
          serialNumber: formData.serialNumber,
          os: formData.os,
          type: formData.type as DeviceType,
          mdmServerName: formData.isDep ? globalState.user.company.companyId + globalState.user.company.name : null,
          depDeviceUsername: formData.isDep ? formData.depDeviceUsername : null,
        };
        await DeviceAPI.addExistingDevice(existingDeviceData);
        showMessage('Device added successfully.', 'success');
        isDeviceAdded = true;
        await refresh();
      } catch (error) {
        if (!isDepCorrect) {
          showMessage(
            'The device has to have the correct serial number or device username to enroll it by auto enrolment',
            'error'
          );
        }
        if (isDeviceAdded) {
          showMessage('Something went wrong. Please refresh the page.', 'error');
        } else {
          showMessage(`Could not add device. ${nestErrorMessage(error)}`, 'error');
        }
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            return { value: site.id, label: site.name, address: site.address ?? '' };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Add existing device</Typography>
        {isAdmin && (
          <CheckboxComponent
            name="assignToEmployee"
            label="Device in use by an employee"
            checked={formik.values.assignToEmployee}
            onChange={formik.handleChange}
          />
        )}

        {formik.values.assignToEmployee && isAdmin && (
          <SingleUserSelect
            name="userId"
            options="company"
            value={formik.values.userId}
            onChange={(_, x: unknown) => formik.setFieldValue('userId', (x as { value: number })?.value ?? null)}
            label="Employee"
            error={Boolean(formik.errors.userId)}
            helperText={formik.errors.userId}
          />
        )}

        <SelectComponent
          name="type"
          label="Type"
          options={DeviceTypesValueLabelOptions}
          value={formik.values.type ?? undefined}
          compareValue={formik.values.type ?? undefined}
          onChange={formik.handleChange}
          error={formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
        />

        {!formik.values.assignToEmployee && isAdmin && (
          <SelectComponent
            name="siteId"
            label="Site"
            options={sitesOptions}
            value={formik.values.siteId}
            compareValue={formik.values.siteId}
            error={!!formik.errors.siteId && formik.touched.siteId}
            helperText={(formik.touched.siteId && formik.errors.siteId) as string}
            onChange={async (e) => {
              await formik.handleChange(e);
              const siteAddress = sitesOptions.find((s) => s.value === e.target.value)?.address ?? '';
              await formik.setFieldValue('receiverAddress', siteAddress);
            }}
          />
        )}

        {MDMABLE_DEVICE_TYPES.includes(formik.values.type as DeviceType) && (
          <SelectComponent
            name="os"
            label="Platform"
            options={DeviceOSValueLabelOptions}
            value={formik.values.os ?? undefined}
            compareValue={formik.values.os ?? undefined}
            error={formik.touched.os && !!formik.errors.os}
            onChange={formik.handleChange}
            helperText={formik.touched.os && formik.errors.os}
          />
        )}

        <TextfieldComponent
          label="Model Name"
          name="modelName"
          value={formik.values.modelName}
          onChange={formik.handleChange}
          error={formik.touched.modelName && Boolean(formik.errors.modelName)}
          helperText={formik.touched.modelName && formik.errors.modelName}
          size="small"
          endAdornment="none"
          placeholder="MacBook Air"
        />

        <TextfieldComponent
          label="Device Name"
          name="deviceName"
          value={formik.values.deviceName}
          onChange={formik.handleChange}
          error={formik.touched.deviceName && Boolean(formik.errors.deviceName)}
          helperText={formik.touched.deviceName && formik.errors.deviceName}
          size="small"
          endAdornment="none"
          placeholder="My device "
        />

        <TextfieldComponent
          label="Serial Number"
          name="serialNumber"
          value={formik.values.serialNumber}
          onChange={formik.handleChange}
          error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
          helperText={formik.touched.serialNumber && formik.errors.serialNumber}
          size="small"
          endAdornment="none"
          placeholder="00000000000"
        />

        {depAccountDetails && (
          <CheckboxComponent
            name="isDep"
            label="Device is added in the connected Apple Business Manager"
            checked={formik.values.isDep}
            onChange={formik.handleChange}
          />
        )}
        {depAccountDetails && formik.values.isDep && (
          <TextfieldComponent
            label="Device username"
            name="depDeviceUsername"
            value={formik.values.depDeviceUsername}
            onChange={formik.handleChange}
            error={formik.touched.depDeviceUsername && Boolean(formik.errors.depDeviceUsername)}
            helperText={formik.touched.depDeviceUsername && formik.errors.depDeviceUsername}
            size="small"
            endAdornment="none"
            placeholder="TestAccount"
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name="Add existing" loading={loading} fullWidth colorVariant="primary" sizeVariant="medium" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
