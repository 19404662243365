import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE,
  REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE,
  REPORTS_DASHBOARD_ROUTE,
} from '@/lib/routes';
import { PayrollAnalyticsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-analytics/payroll-analytics.page';
import { AnalyticsInsightsPeoplePage } from '@/v2/feature/user/features/analytics/analytics-insights-people.page';

export const AnalyticsCompanyRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return (
    <Switch>
      <RouteScopesControl
        context={scopesContext}
        scopes={['company.settings:all']}
        path={REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE}
        component={AnalyticsInsightsPeoplePage}
      />

      <RouteScopesControl
        context={scopesContext}
        path={REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE}
        scopes={['payroll:all']}
      >
        <PayrollAnalyticsPage />
      </RouteScopesControl>

      <Redirect from={REPORTS_DASHBOARD_ROUTE} to={REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE} />
    </Switch>
  );
};
