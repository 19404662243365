import {
  CreateDiscount,
  CreateProductPlanDiscount,
  EditDiscount,
  RemoveDiscount,
  SuperAdminDiscountPageResult,
} from '@/models/discount.model';
import axios from 'axios';

export class BillingDiscountAPI {
  static async getAllDiscounts(param?: {
    page?: string;
    pageSize?: string;
    searchString?: string;
    shouldLimitResult?: string;
  }): Promise<SuperAdminDiscountPageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/billing/discount?${searchParams.toString()}`)).data;
  }

  static async applyDiscount(payload: CreateProductPlanDiscount): Promise<void> {
    return (await axios.post('/apiv2/billing/discount/apply', payload)).data;
  }

  static async createDiscount(payload: CreateDiscount): Promise<void> {
    await axios.post('/apiv2/billing/discount/create', payload);
  }

  static async editDiscount(payload: EditDiscount): Promise<void> {
    await axios.patch('/apiv2/billing/discount/edit', payload);
  }

  static async removeDiscount(payload: RemoveDiscount): Promise<void> {
    await axios.delete('/apiv2/billing/discount/remove', { data: payload });
  }
}
