import { useMemo } from 'react';

import { Box } from '@mui/material';
import { getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { getDay } from 'date-fns';

import { Typography } from '@/v2/components/typography/typography.component';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';
import { AttendanceDto, AttendanceScheduleDto, AttendanceTypeDto } from '@/v2/feature/attendance/attendance.dto';
import { ScheduleTimeEntry } from '@/v2/feature/attendance/attendance.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SummaryDetailsComponent = ({
  userSchedule,
  request,
  attendanceTypesAllowedById,
}: {
  userSchedule: AttendanceScheduleDto | null | undefined;
  request: AttendanceDto;
  attendanceTypesAllowedById: {
    [typeId: number]: AttendanceTypeDto;
  };
}) => {
  const { polyglot } = usePolyglot();

  const latestRegularStartHour = useMemo(() => {
    if (!request?.attendanceEntries[0] || !request.attendanceEntries[0].startHour) return null;
    let earliestStartHour = request.attendanceEntries[0].startHour;

    request.attendanceEntries.forEach((item) => {
      if (
        new Date(item.startHour) < new Date(earliestStartHour) &&
        attendanceTypesAllowedById[item.typeId]?.name === 'Regular'
      ) {
        earliestStartHour = item.startHour;
      }
    });

    return earliestStartHour.slice(11, 16);
  }, [request, attendanceTypesAllowedById]);

  const latestRegularEndHour = useMemo(() => {
    if (!request?.attendanceEntries || !request.attendanceEntries[0]) return null;

    let latestEndHour = request.attendanceEntries[0].endHour;

    request.attendanceEntries.forEach((item) => {
      if (
        new Date(item.endHour) > new Date(latestEndHour) &&
        attendanceTypesAllowedById[item.typeId]?.name === 'Regular'
      ) {
        latestEndHour = item.endHour;
      }
    });

    return latestEndHour.slice(11, 16);
  }, [request, attendanceTypesAllowedById]);

  const calculateTotalRegularHours = useMemo(() => {
    let totalMinutes = 0;
    request.attendanceEntries.forEach((entry) => {
      if (attendanceTypesAllowedById[entry.typeId]?.name === 'Regular') {
        const start = new Date(entry.startHourTimestamp).getTime();
        const end = new Date(entry.endHourTimestamp).getTime();
        const difference = end - start;
        totalMinutes += Math.round(difference / (1000 * 60));
      }
    });

    const hours = Math.round(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours === 0) {
      return `${Math.round(minutes)}m`;
    } else {
      return `${hours}h ${Math.round(minutes)}m`;
    }
  }, [request, attendanceTypesAllowedById]);

  const calculateDuration = (obj: ScheduleTimeEntry): string => {
    if (!obj) return '0h';

    const fromTime = new Date(obj.from);
    const toTime = new Date(obj.to);
    let breakDuration = 0;

    if (obj.break) {
      const breakTime = new Date('1970-01-01T' + obj.break.split('T')[1]);
      breakDuration = breakTime.getHours() * 60 * 60 * 1000 + breakTime.getMinutes() * 60 * 1000;
    }
    let totalDuration = toTime.getTime() - fromTime.getTime();

    // overnight
    if (toTime < fromTime) totalDuration += 24 * 60 * 60 * 1000;

    const durationAfterBreak = totalDuration - breakDuration;
    const hours = Math.floor(durationAfterBreak / (1000 * 60 * 60));
    const minutes = Math.floor((durationAfterBreak % (1000 * 60 * 60)) / (1000 * 60));

    return `${Math.round(hours)}h ${Math.round(minutes)}m`;
  };

  const parseDuration = (duration: string): number => {
    const parts = duration.split(' ');
    let hrs = 0;
    let mins = 0;

    parts.forEach((part) => {
      if (part.includes('h')) {
        hrs = parseInt(part, 10);
      } else if (part.includes('m')) {
        mins = parseInt(part, 10);
      }
    });

    return hrs * 60 + mins;
  };

  const getLogDaySchedule = (logDate: string): ScheduleTimeEntry => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayOfWeek = getDay(new Date(logDate));
    const dayName = days[dayOfWeek] as keyof AttendanceScheduleDto;
    const weekIndex = userSchedule
      ? getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks)
      : null;

    return userSchedule && weekIndex !== null && userSchedule[dayName]
      ? (userSchedule[dayName] as ScheduleTimeEntry[])[weekIndex]
      : null;
  };

  const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const formatTimeDifference = (differenceInMinutes: number) => {
    const hours = differenceInMinutes < 0 ? Math.ceil(differenceInMinutes / 60) : Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;
    const sign = differenceInMinutes >= 0 ? '+' : '-';

    return differenceInMinutes === 0 ? (
      <></>
    ) : (
      <Typography variant="title4" sx={{ color: differenceInMinutes >= 0 ? themeColors.Green : themeColors.Red }}>
        {sign}
        {Math.abs(Math.round(hours))}h {Math.abs(Math.round(minutes))}m
      </Typography>
    );
  };
  const getStartComparison = (logDate: string, earliestStart: string) => {
    const logDateSchedule = getLogDaySchedule(logDate);
    const earlyHourSchedule = logDateSchedule ? logDateSchedule.from.slice(11, 16) : null;
    if (earlyHourSchedule) {
      const differenceInMinutes = timeToMinutes(earlyHourSchedule) - timeToMinutes(earliestStart);
      return formatTimeDifference(differenceInMinutes);
    }
  };

  const getEndComparison = (logDate: string, latestEnd: string) => {
    const logDateSchedule = getLogDaySchedule(logDate);
    const latestHourSchedule = logDateSchedule ? logDateSchedule.to.slice(11, 16) : null;
    if (latestHourSchedule) {
      const differenceInMinutes = timeToMinutes(latestEnd) - timeToMinutes(latestHourSchedule);
      return formatTimeDifference(differenceInMinutes);
    }
  };

  const getLengthComparison = (logDate: string, calculateTotalRegularHours: string) => {
    const logDateSchedule = getLogDaySchedule(logDate);
    if (logDateSchedule) {
      const duration = calculateDuration(logDateSchedule);
      const totalMinutes1 = parseDuration(calculateTotalRegularHours);
      const totalMinutes2 = parseDuration(duration);
      const difference = totalMinutes1 - totalMinutes2;
      return formatTimeDifference(difference);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
      <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('SummaryDetailsComponent.summary')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
        {latestRegularStartHour && (
          <DrawerViewerItem
            title={polyglot.t('SummaryDetailsComponent.start')}
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g20 }}>
                <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                  {latestRegularStartHour}
                </Typography>
                {userSchedule && userSchedule.totalTime !== 0 && !userSchedule.isFlexible && (
                  <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                    {getStartComparison(request.logDate, latestRegularStartHour)}
                  </Typography>
                )}
              </Box>
            }
          />
        )}

        {latestRegularEndHour && (
          <DrawerViewerItem
            title={polyglot.t('SummaryDetailsComponent.end')}
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g20 }}>
                <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                  {latestRegularEndHour}
                </Typography>
                {userSchedule &&
                  userSchedule.totalTime !== 0 &&
                  !userSchedule.isFlexible &&
                  getEndComparison(request.logDate, latestRegularEndHour)}
              </Box>
            }
          />
        )}

        <DrawerViewerItem
          title={polyglot.t('SummaryDetailsComponent.length')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g20 }}>
              <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                {calculateTotalRegularHours}
              </Typography>
              {userSchedule &&
                userSchedule.totalTime !== 0 &&
                !userSchedule.isFlexible &&
                getLengthComparison(request.logDate, calculateTotalRegularHours)}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};
