import ErrorIcon from '@mui/icons-material/Error';
import { Box, Tooltip } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { v4 } from 'uuid';

import { isImportCellEditable } from '../util/entity-import.util';

import { EntityImportErrorDto } from '@/v2/feature/entity-import/entity-import.dto';

interface EntityImportCsvErrorsCellProps<T> {
  prop: keyof T;
  row: EntityImportErrorDto<T>;
}

function EntityImportDatagridValidationCell<T>({ row, prop }: EntityImportCsvErrorsCellProps<T>): JSX.Element {
  const colErrors = row.errors
    .filter((error) => error.property === prop)
    .flatMap((error) => Object.values(error.constraints));
  const colValue = row.entity[prop] || '';

  if (!colErrors.length) return <>{colValue}</>;

  return (
    <Tooltip
      title={
        <ul>
          {colErrors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      }
    >
      <Box color="red">
        <ErrorIcon color="error" fontSize="small" />
        <>{colValue}</>
      </Box>
    </Tooltip>
  );
}

interface EntityImportCsvErrorsProps<T> {
  rows: EntityImportErrorDto<T>[];
  columns: GridColDef<EntityImportErrorDto<T>>[];
  setTableDataAsCsv?: (csvText: string) => void;
  isTableEditable?: boolean;
  rowIdsToExport?: string[];
}

export function EntityImportDatagridValidation<T extends GridValidRowModel>({
  rows,
  columns,
  setTableDataAsCsv,
  rowIdsToExport,
  ...props
}: EntityImportCsvErrorsProps<T>): JSX.Element {
  const apiRef = useGridApiRef();

  const mappedColumns = columns.map<GridColDef<EntityImportErrorDto<T>>>((colDef) => ({
    ...colDef,
    sortable: true,
    valueGetter: (param) => param.row.entity[colDef.field],
    valueSetter: (param) => {
      return {
        ...param.row,
        entity: { ...param.row.entity, [colDef.field]: param.value },
        errors: param.row?.errors?.filter((error) => error.property !== colDef?.field),
      };
    },
    renderCell: (param) => {
      return <EntityImportDatagridValidationCell<T> row={param.row} prop={colDef.field} />;
    },
  }));
  return (
    <>
      <DataGridPro
        isCellEditable={(param) => isImportCellEditable(param, rows)}
        experimentalFeatures={{ newEditingApi: true }}
        {...props}
        rows={rows}
        apiRef={apiRef}
        autoHeight
        columns={mappedColumns}
        isRowSelectable={() => false}
        getRowId={(row) => (row?.id ? row.id : v4())}
        onStateChange={() => {
          try {
            const data = apiRef.current.getDataAsCsv({
              getRowsToExport: () => (rowIdsToExport ? rowIdsToExport : apiRef.current.getAllRowIds()),
            });
            if (setTableDataAsCsv) setTableDataAsCsv(data);
          } catch (error) {
            console.error(error);
          }
        }}
      />
    </>
  );
}
