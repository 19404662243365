import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { EditUserPayrollPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll.page';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshPayroll?: () => Promise<void>;
};

export const MissingTaxInformation = ({ userId, refreshPayroll }: Props) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Typography sx={themeFonts.caption}>{polyglot.t('PayrollMissing.taxInformation')}</Typography>
        <IconButton onClick={() => setIsModalOpen(true)} colorVariant="secondary" sizeVariant="small">
          <Plus width="14px" height="14px" />
        </IconButton>
      </Box>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        <EditUserPayrollPage
          userId={userId}
          record={null}
          onUpdateFinished={() => refreshPayroll?.()}
          close={() => setIsModalOpen(false)}
          isUserUpdating={false}
          mode="initial"
          requireReason={false}
        />
      </DrawerModal>
    </>
  );
};
