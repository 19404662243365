import { LocalDate } from '@v2/util/local-date';
import axios from 'axios';

import {
  ActionListItems,
  WidgetConfiguration,
  WigdetData,
  WishBody,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { ActionItemsTabFilter } from '@/v2/feature/task/task.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class DashboardAPI {
  static async updateWidgetSettings(update: any): Promise<void> {
    return (await axios.patch(`/apiv2/dashboard/widgets/update`, update))?.data;
  }

  static async sendWishes(update: WishBody): Promise<void> {
    return (await axios.post(`/apiv2/users/notifications/wishes`, update))?.data;
  }

  static async getGIFS(query: string): Promise<string[]> {
    return (await axios.get(`/apiv2/dashboard/widgets/GIFS?query=${query}`))?.data;
  }
}
export class DashboardEndpoints {
  static getUserWidgets(): Endpoint<WigdetData> {
    // dont add action tab filters, its misleading as user cant see the filters
    const today = new LocalDate().toDateString();
    const reach = 'personal';
    return {
      url: `/apiv2/dashboard/widgets/user?today=${today}&todoreach=${reach}`,
    };
  }

  static getUserConfiguration(): Endpoint<WidgetConfiguration> {
    return {
      url: `/apiv2/dashboard/widgets/preferences`,
    };
  }

  static getAllWidgets(): Endpoint<WigdetData> {
    const today = new LocalDate().toDateString();
    const reach = 'personal';
    return {
      url: `/apiv2/dashboard/widgets/all?today=${today}&todoreach=${reach}`,
    };
  }

  static getAllActionItems(status: ActionItemsTabFilter): Endpoint<ActionListItems[]> {
    const today = new LocalDate().toDateString();
    const reach = 'personal';
    return {
      url: `/apiv2/dashboard/widgets/action-items?today=${today}&todoreach=${reach}&status=${status}`,
    };
  }
}
