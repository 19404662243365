import React from 'react';

import { v4 } from 'uuid';

import useMessage from '@/hooks/notification.hook';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ReviewSectionAPI } from '@/v2/feature/growth/reviews/api-client/review-section.api';
import { SectionModalContent } from '@/v2/feature/growth/reviews/features/components/section-modal-content.component';
import { ReviewSection, ReviewSectionUpsert } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';

export const CycleCreationSectionFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  section,
  refresh,
  cycleId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  section: ReviewSection | null;
  refresh: () => Promise<void>;
  cycleId: string;
}) => {
  const [showMessage] = useMessage();

  const onSubmit = async (values: { name: string }) => {
    if (section) {
      const updateObject = { id: section.id, name: values.name, cycleId };
      await ReviewSectionAPI.updateReviewSectionInBank(updateObject as ReviewSectionUpsert);
      showMessage('Section updated successfully', 'success');
    } else {
      const updateObject = { id: v4(), name: values.name, cycleId };
      await ReviewSectionAPI.createSectionByCycleId(updateObject as ReviewSectionUpsert);
      showMessage('Section created successfully', 'success');
    }
  };
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <SectionModalContent
        onClose={onClose}
        section={section as Pick<ReviewSection, 'name'>}
        refresh={refresh}
        onSubmit={onSubmit}
      />
    </DrawerModal>
  );
};
