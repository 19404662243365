import { BENEFITS_COMPANY_ROUTE, BENEFITS_ME_ROUTE } from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const MONEY_BENEFITS_ROUTES: RoleRoutes = {
  company: BENEFITS_COMPANY_ROUTE,
  me: BENEFITS_ME_ROUTE,
};

export const MONEY_BENEFITS_SCOPES: RoleScopes = {
  company: ['insurance:all'] as Scope[],
  me: ['insurance:read'] as Scope[],
};
