import React from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { UserPersonalSettingsSections } from '@v2/feature/user/features/user-settings/components/user-personal-settings-sections.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const UserSettings = (): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const {
    data: settings,
    mutate: refreshSettings,
    isLoading: settingLoading,
  } = useApiClient(UserEndpoints.getPersonalSettings(), { suspense: false });

  return (
    <ContentWrapper loading={settingLoading}>
      <SettingsSectionContent title={polyglot.t('UserSettings.personal')}>
        <UserPersonalSettingsSections settings={settings} refreshSettings={refreshSettings} />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};
