import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as CleanGrey } from '@/images/fields/CleanGrey.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { ReviewEntryAPI } from '@/v2/feature/growth/reviews/api-client/review-entry.api';
import {
  getReviewDueDate,
  getReviewType,
} from '@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util';
import { ReviewerTypes, UpsertReviewers } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { EntryState, ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReviewEntriesPersonalStartModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  selectedEntry,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  afterClose: () => void;
  selectedEntry: ReviewEntry | undefined;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      {selectedEntry ? (
        <ReviewEntriesPersonalStartContent selectedEntry={selectedEntry} onClose={onClose} />
      ) : (
        <Typography variant="caption" color="Grey">
          No record was selected
        </Typography>
      )}
    </DrawerModal>
  );
};

const useStartModalForm = (
  selectedEntry: ReviewEntry | undefined,
  setLoading: Dispatch<SetStateAction<boolean>>,
  shouldSelectPeers: boolean
) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const formik = useFormik<{ peerIds: number[] }>({
    initialValues: {
      peerIds: [],
    },
    validationSchema: yup.object({
      peerIds: shouldSelectPeers
        ? yup.array().of(yup.number()).min(1, 'At least one peer is required').required('Peers are required')
        : yup.array().of(yup.number()),
    }),
    onSubmit: async (values) => {
      try {
        if (!selectedEntry || !selectedEntry.cycle?.id) {
          return;
        }

        if (shouldSelectPeers) {
          const reviewersToAdd: UpsertReviewers = {
            reviewers: [{ reviewerType: ReviewerTypes.Peer, ids: values.peerIds }],
            revieweeId: selectedEntry.revieweeId,
          };

          const entry = {
            ...selectedEntry,
            entryState: EntryState.Draft,
            cycleId: selectedEntry.cycle.id,
          };
          await ReviewEntryAPI.invitePeersByEntry({ reviewersToAdd, entry });
        }
        routerHistory.push(
          generatePath(REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE, {
            entryId: selectedEntry.id,
            cycleId: selectedEntry.cycle.id,
          })
        );
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return formik;
};

const ReviewEntriesPersonalStartContent = ({
  selectedEntry,
  onClose,
}: {
  selectedEntry: ReviewEntry;
  onClose: () => void;
}) => {
  const reviewType = useMemo(() => (selectedEntry ? getReviewType(selectedEntry) : ''), [selectedEntry]);
  const routerHistory = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const shouldSelectPeers = useMemo(
    () =>
      Boolean(
        reviewType === ReviewerTypes.Self &&
          selectedEntry.cycle &&
          selectedEntry &&
          selectedEntry.entryState === EntryState.NotStarted &&
          selectedEntry.cycle.reviewerSelect.includes(ReviewerTypes.Peer) &&
          selectedEntry.cycle.cycleSettings.allowRevieweesToInvitePeers &&
          (!selectedEntry.cycle.peerReviewerIds || !selectedEntry.cycle.peerReviewerIds[selectedEntry?.revieweeId])
      ),
    [reviewType, selectedEntry]
  );

  const handleContinue = useCallback(async () => {
    if (!selectedEntry || !selectedEntry.cycle) {
      return;
    }
    routerHistory.push(
      generatePath(REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE, {
        entryId: selectedEntry.id,
        cycleId: selectedEntry.cycle.id,
      })
    );
  }, [selectedEntry, routerHistory]);

  const formik = useStartModalForm(selectedEntry, setLoading, shouldSelectPeers);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
          <Typography variant="title2">
            Let's {selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? 'start' : 'continue'} your{' '}
            {reviewType} review
          </Typography>

          <Typography variant="caption" color="Grey">
            You can save and exit at any time, come back to this review later.
          </Typography>
        </Box>

        {selectedEntry &&
          selectedEntry.cycle &&
          (selectedEntry.cycle.displayName || selectedEntry.cycle.internalName) && (
            <ViewItem label="Cycle name" value={selectedEntry.cycle.displayName || selectedEntry.cycle.internalName} />
          )}

        {selectedEntry && selectedEntry.endDate && (
          <ViewItem
            label="Due date"
            value={getReviewDueDate(selectedEntry)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          />
        )}

        {selectedEntry?.revieweeId &&
          (reviewType === ReviewerTypes.Manager ||
            reviewType === ReviewerTypes.Upward ||
            reviewType === ReviewerTypes.Peer) && (
            <ViewItem label="You are reviewing" value={<UserCell userId={selectedEntry?.revieweeId} />} />
          )}

        {selectedEntry && selectedEntry.cycle && shouldSelectPeers && (
          <UserSelect
            label="Peer reviewer"
            labelSx={{ ...themeFonts.caption, color: themeColors.Grey }}
            selectedSpecificButtonLabel="Add peer reviewer"
            value={formik.values.peerIds ?? []}
            onChange={(userIds: number[]) => {
              formik.setFieldValue('peerIds', userIds);
            }}
            fieldSx={{ ...spacing.mb20 }}
            excludeEveryone={true}
            excludeCustomRule={true}
            allInvalidUserIds={[selectedEntry.revieweeId] ?? []}
            hideUserList
          />
        )}

        {shouldSelectPeers && selectedEntry?.revieweeId && formik.values.peerIds.length > 0 && (
          <Box>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Newly added peer reviewers
            </Typography>
            <List
              height={250}
              itemCount={formik.values.peerIds.length}
              itemSize={40}
              width={'100%'}
              itemData={{ ...formik.values.peerIds, props: { formik, themeColors, spacing, iconSize } }}
            >
              {RenderNewPeerRow}
            </List>
          </Box>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" fullWidth onClick={onClose}>
            Cancel
          </ButtonComponent>
          {selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? (
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name="Start review"
              fullWidth
              type="submit"
              loading={loading}
              disabled={shouldSelectPeers && formik.values.peerIds.length === 0}
            />
          ) : (
            <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth onClick={() => handleContinue()}>
              Continue
            </ButtonComponent>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
};

const ViewItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g4 }}>
      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
        {label}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};

const RenderNewPeerRow = ({ index, style, data }: any) => {
  const peerId = data[index];
  const { formik } = data.props;

  return (
    <Box
      style={style}
      sx={{
        paddingY: spacing.p12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <UserCell userId={peerId} />
      <CleanGrey
        {...iconSize}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          const filteredPeerIds = formik.values.peerIds.filter((id: number) => id !== peerId);
          formik.setFieldValue('peerIds', filteredPeerIds);
        }}
      />
    </Box>
  );
};
