import { DevicePolicyType } from '@v2/feature/device/device.interface';

export const MinimumLength = [
  { value: 0, label: 'Not set' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
];

export const MinimumComplexChars = [
  { value: 0, label: 'Not set' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
];
export const MaxInactivity = [
  { value: 0, label: 'Not set' },
  { value: 1, label: '1 min' },
  { value: 2, label: '2 min' },
  { value: 3, label: '3 min' },
  { value: 4, label: '4 min' },
  { value: 5, label: '5 min' },
  { value: 6, label: '6 min' },
  { value: 7, label: '7 min' },
  { value: 8, label: '8 min' },
  { value: 9, label: '9 min' },
  { value: 10, label: '10 min' },
  { value: 15, label: '15 min' },
];

export const DeferForceAtUserLoginMaxBypassAttemptsOptions = [
  { value: -1, label: 'Not set' },
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];
export enum ZeltPolicyPayloadIdentifier {
  FILE_VAULT_PAYLOAD_IDENTIFIER = 'app.zelt.filevault',
  FIREWALL_PAYLOAD_IDENTIFIER = 'app.zelt.firewall',
  PASSWORD_PAYLOAD_IDENTIFIER = 'app.zelt.password',
  BROWSING_PAYLOAD_IDENTIFIER = 'app.zelt.browsing',
  ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER = 'app.zelt.restrictions.icloud',
  SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER = 'app.zelt.restrictions.security',
  SCREEN_RESTRICTIONS_PAYLOAD_IDENTIFIER = 'app.zelt.restrictions.screen',
  ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER = 'app.zelt.restrictions.additional.password',
  OS_UPDATES_RESTRICTIONS_PAYLOAD_IDENTIFIER = 'app.zelt.restrictions.os-updates',
  SCREEN_LOGIN_SETTINGS_PAYLOAD_IDENTIFIER = 'app.zelt.settings.login.screen',
  LOGIN_ITEMS_PAYLOAD_IDENTIFIER = 'app.zelt.login-items',
  WIFI_PAYLOAD_IDENTIFIER = 'app.zelt.wifi',
}

export const NoAppliedPolicies = {
  [DevicePolicyType.passcode]: [0],
  [DevicePolicyType.encryption]: [0],
  [DevicePolicyType.location]: [0],
  [DevicePolicyType.updates]: [0],
  [DevicePolicyType.icloud]: [0],
  [DevicePolicyType.apps]: [0],
  [DevicePolicyType.firewall]: [0],
};

export enum ZeltMdmDevicePolicyType {
  passcode = 'passcode',
  encryption = 'encryption',
  apps = 'apps',
  icloud = 'icloud',
  firewall = 'firewall',
  screen = 'screen',
  security = 'security',
  browsing = 'browsing',
  additional_password = 'additional_password',
  osupdate_restrictions = 'os_update_restrictions',
  osupdate_automatic = 'os_update_automatic',
  activation_lock = 'activation_lock',
}

export const FilevaultEnabled = [
  { value: 'On', label: 'On' },
  { value: 'Off', label: 'Off' },
];
