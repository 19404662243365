import React from 'react';

import { SxProps, Box, Theme } from '@mui/material';

import {
  StyledTabs,
  StyledTab,
} from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';

type PayrunSalaryTabsProps = {
  tabs: readonly string[];
  currentTab?: string;
  onChange?: (tab: string) => void;
  sx?: SxProps<Theme>;
};

export const PayrunSalaryTabs = ({ tabs, currentTab, onChange, sx }: PayrunSalaryTabsProps) => {
  return (
    <Box sx={sx}>
      <StyledTabs
        value={currentTab || false}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={(_, value) => {
          onChange?.(value);
        }}
      >
        {tabs.map((label) => (
          <StyledTab
            key={label}
            disableRipple
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  textTransform: 'inherit',
                }}
              >
                {label}
              </div>
            }
            value={label}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};
