import React from 'react';

import { Box, Button } from '@mui/material';

import { ReactComponent as BackBtn } from '@/images/app-icons/BackBtn.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { UnSanitizedGuide } from '@/v2/feature/strapi-help/strapi.interface';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const GuideComponent = ({
  selectedGuide,
  setSelectedGuide,
}: {
  selectedGuide: UnSanitizedGuide;
  setSelectedGuide: React.Dispatch<React.SetStateAction<UnSanitizedGuide | undefined>>;
}) => {
  return (
    <Box sx={{ mt: spacing.mt30 }}>
      <Button startIcon={<BackBtn />} sx={secondaryCTABtn} onClick={() => setSelectedGuide(undefined)}>
        Back
      </Button>

      <Box sx={spacing.mt20}>
        <Typography variant="title2">{selectedGuide.Title}</Typography>
        <Typography variant="caption" sx={{ marginTop: spacing.m10, color: themeColors.Grey }}>
          {selectedGuide.Description}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, marginTop: spacing.m30 }}>
          {selectedGuide.Step &&
            selectedGuide.Step.length > 0 &&
            selectedGuide.Step.map((step, idx) => (
              <Box key={`${idx}-step-guide`}>
                {step.Screenshot && step?.Screenshot?.data && step?.Screenshot?.data[0] && (
                  <Box sx={{ width: '100%' }}>
                    <img
                      src={`https://strapi.zelt.app${step?.Screenshot?.data[0]?.attributes?.url}`}
                      alt="step-img"
                      width="100%"
                    />
                  </Box>
                )}
                <Typography variant="title4" sx={{ marginTop: spacing.m10 }}>
                  {step.Heading}
                </Typography>
                <Typography variant="caption" sx={{ marginTop: spacing.m10 }}>
                  {step.Description}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
