import { Box, BoxProps, List, ListItemIcon, ListItemText } from '@mui/material';
import { getActiveSub } from '@v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { Scope } from '@/models';
import { themeColors } from '@/v2/styles/colors.styles';
import { activeSubStyle, ListItemStyle } from '@/v2/styles/list-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface NavConfigItem {
  readonly title: string;
  readonly path: readonly string[];
  readonly icon?: JSX.Element;
  readonly info?: JSX.Element;
  readonly isHidden?: boolean;
  readonly children?: readonly {
    readonly title: string;
    readonly path: string;
    readonly isHidden?: boolean;
    readonly externalPath?: boolean;
    readonly icon: JSX.Element;
    readonly iconActive: JSX.Element;
    readonly scopes?: readonly Scope[];
  }[];
}

export interface NavConfig {
  readonly subheader: string;
  readonly items: readonly NavConfigItem[];
}

interface NavItemProps {
  readonly item: NavConfigItem;
}

export const NavItem = ({ item }: NavItemProps): JSX.Element => {
  const { pathname } = useLocation();
  const { title, children, isHidden } = item;

  if (children) {
    return (
      <List sx={{ display: 'flex', flexDirection: 'column' }} disablePadding>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          {children.map((item) => {
            const { title, path, icon, externalPath, isHidden, iconActive } = item;
            const isVisibleForUser = !isHidden;
            return (
              isVisibleForUser && (
                <ListItemStyle
                  key={title}
                  // @ts-ignore
                  component={RouterLink}
                  to={{ pathname: path }}
                  target={externalPath ? '_blank' : undefined}
                  sx={{
                    ...(getActiveSub(pathname, path) && activeSubStyle),
                    width: '100%',
                    textTransform: 'none',
                  }}
                >
                  <ListItemIcon sx={{ marginRight: spacing.m10, minWidth: '16px' }}>
                    {getActiveSub(pathname, path) ? iconActive : icon}
                  </ListItemIcon>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <ListItemText disableTypography primary={title} />
                  </Box>
                </ListItemStyle>
              )
            );
          })}
        </Box>
        {!isHidden && <Box sx={{ borderBottom: `1px solid ${themeColors.Background}`, ...spacing.my20 }} />}
      </List>
    );
  }

  return (
    <ListItemStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
};

interface Props extends BoxProps {
  readonly navConfig: readonly NavConfig[];
}

export const NavigationSection = ({ navConfig }: Props): JSX.Element => {
  return (
    <Box>
      {navConfig.map((list) =>
        list.items.map((item: NavConfigItem) => !item.isHidden && <NavItem key={item.title} item={item} />)
      )}
    </Box>
  );
};
