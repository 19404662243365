import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { capitalize } from 'lodash';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { countFilters } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { isTimingCorrectlySet } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.util';
import { SurveyCycleLaunch } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface ISCRollingLaunchVieww {
  readonly surveyCycleLaunchDetails: SurveyCycleLaunch;
  readonly cycleId: string;
  readonly goBack: () => void;
}
export const SCRollingLaunchView = ({ surveyCycleLaunchDetails, cycleId, goBack }: ISCRollingLaunchVieww) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const hasEnrolmentTrigger = useMemo(
    () =>
      Boolean(
        surveyCycleLaunchDetails.enrolmentTriggerFilters &&
          surveyCycleLaunchDetails.enrolmentTriggerFilters !== null &&
          Object.keys(surveyCycleLaunchDetails.enrolmentTriggerFilters).length > 0
      ),
    [surveyCycleLaunchDetails]
  );

  const activateSurveyCycle = async () => {
    try {
      setLoading(true);
      await SurveyCycleAPI.activateSurveyCycle(cycleId);
      goBack();
      showMessage('Successfully launched the cycle', 'success');
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const showActionButtons = useMemo(
    () =>
      surveyCycleLaunchDetails &&
      surveyCycleLaunchDetails.state === CycleState.Draft &&
      surveyCycleLaunchDetails.totalQuestions > 0 &&
      surveyCycleLaunchDetails.enrolmentTriggerFilters &&
      Object.keys(surveyCycleLaunchDetails.enrolmentTriggerFilters).length > 0 &&
      surveyCycleLaunchDetails.timelineSettings &&
      isTimingCorrectlySet(surveyCycleLaunchDetails?.timelineSettings),
    [surveyCycleLaunchDetails]
  );

  return (
    <SettingsSubsectionContent
      sections={[
        {
          contentWidth: '100%',
          headerWidth: '100%',
          title: 'Start cycle',
          items: [
            {
              type: SectionItemType.Pair,
              label: 'Survey type',
              value: <Typography variant="title4">{capitalize(surveyCycleLaunchDetails.type)}</Typography>,
            },
            {
              type: SectionItemType.Pair,
              label: polyglot.t('SurveyParticipantDrawer.anonymousSwitch'),
              value: (
                <Typography variant="title4" color={'DarkGrey'}>
                  {surveyCycleLaunchDetails?.visibilitySettings?.anonymiseAnswers ? 'Enabled' : 'Disabled'}
                </Typography>
              ),
            },
            ...(surveyCycleLaunchDetails?.visibilitySettings &&
            surveyCycleLaunchDetails?.visibilitySettings?.anonymityType
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t('SurveyParticipantDrawer.anonymityType'),
                    value: (
                      <Typography variant="title4" color={'DarkGrey'}>
                        {capitalize(surveyCycleLaunchDetails?.visibilitySettings?.anonymityType)}
                      </Typography>
                    ),
                  },
                ]
              : []),
            {
              type: SectionItemType.Pair,
              label: 'Number of questions',
              value: (
                <Typography
                  variant="title4"
                  color={surveyCycleLaunchDetails.totalQuestions > 0 ? 'DarkGrey' : 'RedDark'}
                >
                  {surveyCycleLaunchDetails.totalQuestions || 'Please add some questions'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Currently qualifying',
              value: (
                <Typography variant="title4">
                  {hasEnrolmentTrigger
                    ? surveyCycleLaunchDetails.participantCount > 0
                      ? `${surveyCycleLaunchDetails.participantCount} participant(s)`
                      : 'No participants qualify the enrolment trigger'
                    : 'Please add participant(s) by creating an enrolment trigger'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Filters',
              value: (
                <Typography variant="title4" color={hasEnrolmentTrigger ? 'DarkGrey' : 'RedDark'}>
                  {hasEnrolmentTrigger
                    ? `${countFilters(surveyCycleLaunchDetails.enrolmentTriggerFilters)} filter(s)`
                    : 'Please create an enrolment trigger'}
                </Typography>
              ),
            },

            {
              type: SectionItemType.Component,
              value: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g4, mt: spacing.m8 }}>
                  <div>
                    <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => goBack()}>
                      Save and close
                    </ButtonComponent>
                  </div>
                  {showActionButtons && (
                    <LoaderButton
                      sizeVariant="small"
                      colorVariant="primary"
                      onClick={activateSurveyCycle}
                      loading={loading}
                      disabled={loading}
                    >
                      Activate cycle
                    </LoaderButton>
                  )}
                </Box>
              ),
              hidden: Boolean(
                surveyCycleLaunchDetails &&
                  surveyCycleLaunchDetails.state &&
                  surveyCycleLaunchDetails.state !== CycleState.Draft
              ),
            },
          ],
        },
      ]}
    />
  );
};
