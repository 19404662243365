import React from 'react';

import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { DeviceDto } from '@v2/feature/device/device.dto';
import { PasswordPolicyLevel } from '@v2/feature/device/device.interface';

interface DialogBoxProps {
  open: boolean;
  onClose: () => void;
  text: string | string[] | undefined;
  additionalText: string | string[] | undefined;
  device: DeviceDto | undefined;
}

export const SimpleInHouseMdmDeviceDialog: React.FC<DialogBoxProps> = ({
  open,
  onClose,
  text,
  additionalText,
  device,
}) => {
  const renderDialogContent = () => {
    if (text && Array.isArray(text)) {
      return (
        <>
          <Typography variant="subtitle1">Company applied policies</Typography>
          <br />
          {text.map((item, index) => (
            <DialogContentText key={index}>
              {item}
              <br />
            </DialogContentText>
          ))}
        </>
      );
    }
    return <DialogContentText>{text}</DialogContentText>;
  };

  const renderAdditionalText = () => {
    if (additionalText && Array.isArray(additionalText)) {
      return (
        <>
          <br />
          <Typography variant="subtitle1">Company applied policies by Zelt MDM</Typography>
          <br />
          {additionalText.map((item, index) => (
            <DialogContentText key={index}>
              {item}
              <br />
            </DialogContentText>
          ))}
        </>
      );
    }
    return <DialogContentText>{additionalText}</DialogContentText>;
  };
  const renderDeviceAppliedPolicyText = () => {
    const { icloudRestrictionEnabled, passwordProtected, passwordPolicyLevel, firewallEnabled, encryptionEnabled } =
      device || {};

    return (
      <>
        <br />
        <Typography variant="subtitle1">Policies installed on device</Typography>
        <br />
        {icloudRestrictionEnabled && <DialogContentText key="icloud">Disable iCloud sync</DialogContentText>}
        {passwordProtected && (
          <DialogContentText key="password">
            Password Policy Level: {getPasswordPolicyText(passwordPolicyLevel)}
          </DialogContentText>
        )}
        {firewallEnabled && <DialogContentText key="firewall">Enable firewall</DialogContentText>}
        {encryptionEnabled && <DialogContentText key="encryption">Encryption Policy</DialogContentText>}
      </>
    );
  };
  const getPasswordPolicyText = (passwordPolicyLevel: PasswordPolicyLevel | undefined) => {
    if (!passwordPolicyLevel) {
      return 'None Passcode';
    }
    switch (passwordPolicyLevel.valueOf()) {
      case 'BASIC':
        return 'Basic Passcode';
      case 'MODERATE':
        return 'Moderate Passcode';
      case 'STRICT':
        return 'Strict Passcode';
      default:
        return 'None Passcode';
    }
  };
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { backgroundColor: '#FFFFFF', color: 'black' } }}>
      <Typography variant="h6" sx={{ padding: '16px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}>
        Company policies
      </Typography>
      <DialogContent style={{ width: '430px', height: '250px' }}>
        {renderDialogContent()}
        {renderAdditionalText()}
        {renderDeviceAppliedPolicyText()}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={onClose} sx={{ backgroundColor: '#F6F3EF', color: 'black' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
