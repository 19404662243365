export type CreateCharge = {
  readonly id: number | null;
  readonly companyId: number;
  readonly userId: number;
  readonly amount: number;
  readonly memo: string;
  readonly type?: string;
};

export enum ChargeTypes {
  IdCheck = 'id-check', // - id check charges should not be added from back office
  DevicePurchase = 'device-purchase',
  LogisticsRecharge = 'logistics-recharge',
  EarlyReturnFee = 'early-return-fee',
  DeviceAccessories = 'device-accessories',
}

export interface CompanyBasic {
  companyId: number;
  name: string;
}
