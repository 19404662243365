import Polyglot from 'node-polyglot';

import { CurrentUser, Scope } from '@/models';

export type RoleTypes = 'company' | 'team' | 'me';
export const RoleTypesArray = ['company', 'team', 'me'];

export enum MenuActionType {
  SET_SECOND_MENU_VISIBILITY = 'SET_SECOND_MENU_VISIBILITY',
  SET_ACTIVE_PARENT = 'SET_ACTIVE_PARENT',
  SET_ACTIVE_SUBMENU_PARENT = 'SET_ACTIVE_SUBMENU_PARENT',
  SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE',
}

export type MenuState = {
  showSecondMenu: boolean;
  activeParent: string | undefined;
  activeSubMenuParent: string | undefined;
  activeRole: RoleTypes | undefined;
};

export type MenuAction =
  | { type: MenuActionType.SET_SECOND_MENU_VISIBILITY; payload: boolean }
  | { type: MenuActionType.SET_ACTIVE_PARENT; payload: string | undefined }
  | { type: MenuActionType.SET_ACTIVE_SUBMENU_PARENT; payload: string | undefined }
  | { type: MenuActionType.SET_ACTIVE_ROLE; payload: RoleTypes | undefined };

export interface RoleRoutes {
  company?: string;
  team?: string;
  me?: string;
}

export interface RoleScopes {
  company?: Scope[];
  team?: Scope[];
  me?: Scope[];
}

export const RolesOptions = (polyglot: Polyglot) => {
  return {
    company: polyglot?.t('topNavigationMenu.company'),
    team: polyglot?.t('topNavigationMenu.team'),
    me: polyglot?.t('topNavigationMenu.me'),
  };
};

export interface MainMenuItem {
  title: string;
  stub: string;
  path: string;
  icon: JSX.Element;
  iconActive: JSX.Element;
  loopAnimation: unknown;
  loopFilledAnimation: unknown;
  isHidden: boolean;
  hasChildren: boolean;
  subMenu?: SubMenuConfig[];
}

export enum MainMenuSectionEnum {
  dashboard = 'dashboard',
  people = 'people',
  time = 'time',
  money = 'money',
  tech = 'tech',
  growth = 'growth',
  report = 'report',
}

export interface MainMenuSectionProps {
  [MainMenuSectionEnum.dashboard]: MainMenuItem;
  [MainMenuSectionEnum.people]: MainMenuItem;
  [MainMenuSectionEnum.time]: MainMenuItem;
  [MainMenuSectionEnum.money]: MainMenuItem;
  [MainMenuSectionEnum.tech]: MainMenuItem;
  [MainMenuSectionEnum.growth]: MainMenuItem;
  [MainMenuSectionEnum.report]: MainMenuItem;
}

export interface SubItemConfig {
  title: string;
  stub: string;
  user: CurrentUser;
  routes: RoleRoutes[];
  scopes: Scope[];
  isHidden: boolean;
  hasChildren: boolean;
  role: RoleTypes;
  subMenu?: SubMenuConfig[];
}

export interface SubMenuConfig {
  title: string;
  stub: string;
  route: RoleRoutes;
  scopes: Scope[];
  isHidden: boolean;
}

export type ScopeCheck = {
  domain: MainMenuSectionEnum;
  scopes: {
    me?: Scope[];
    team?: Scope[];
    company?: Scope[];
  };
};
