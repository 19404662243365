import axios from 'axios';

import {
  GenderOverTimeDto,
  HeadcountByDepartmentDto,
  HeadcountOverTimeDto,
  HiresFiresOverMonthDto,
} from '@/v2/feature/analytics/analytics.dto';
import { QuartileProperties, SalaryBand } from '@/v2/feature/analytics/analytics.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class AnalyticsAPI {
  static async findGenderOverTime(): Promise<GenderOverTimeDto> {
    return (await axios.get('/apiv2/analytics/users/gender-over-time')).data;
  }

  static async findHeadcountByDepartment(): Promise<HeadcountByDepartmentDto[]> {
    return (await axios.get('/apiv2/analytics/users/headcount/department')).data;
  }

  static async getHeadcountOverTime(): Promise<HeadcountOverTimeDto> {
    return (await axios.get('/apiv2/analytics/users/headcount-over-time')).data;
  }

  static async getHiresAndFiresLastMonth(): Promise<HiresFiresOverMonthDto> {
    return (await axios.get('/apiv2/analytics/users/hires-fires-over-month')).data;
  }
}

export class AnalyticEndpoints {
  static getSalaryBand(filterString: string): Endpoint<SalaryBand[]> {
    return {
      url: `/apiv2/analytics/users/salary-band?${filterString}`,
    };
  }

  static findGenderPayGap(filterString: string): Endpoint<QuartileProperties> {
    return {
      url: `/apiv2/analytics/users/quartile-salary-per-gender?${filterString}`,
    };
  }

  static findLastSalaryChange(filterString: string): Endpoint<QuartileProperties> {
    return {
      url: `/apiv2/analytics/users/last-salary-change?${filterString}`,
    };
  }
}
