import { useContext, useMemo } from 'react';

import { Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { REVIEWS_COMPANY_ROUTE, REVIEWS_ME_ROUTE, REVIEWS_TEAM_ROUTE } from '@/lib/routes';
import { ReviewsCompanyRouter } from '@/v2/feature/growth/reviews/reviews-company.router';
import { ReviewsMeRouter } from '@/v2/feature/growth/reviews/reviews-me.router';
import { ReviewsTeamRouter } from '@/v2/feature/growth/reviews/reviews-team.router';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const ReviewsRouter = () => {
  const [state] = useContext(GlobalContext);
  const { user } = state;

  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false,
  });

  const isManager = useMemo(() => {
    const nonTerminatedDirectReports =
      userSummaryWithReports?.directReports?.filter(
        (d) => d.userEvent && d.userEvent.status && ['Employed', 'Hired'].includes(d.userEvent.status)
      )?.length ?? 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);

  return (
    <Switch>
      <RouteScopesHas scopes={['reviews:all']} path={REVIEWS_COMPANY_ROUTE}>
        <ReviewsCompanyRouter />
      </RouteScopesHas>

      {isManager && (
        <RouteScopesHas scopes={['reviews:manager']} path={REVIEWS_TEAM_ROUTE}>
          <ReviewsTeamRouter />
        </RouteScopesHas>
      )}

      <RouteScopesHas scopes={['reviews']} path={REVIEWS_ME_ROUTE}>
        <ReviewsMeRouter />
      </RouteScopesHas>
    </Switch>
  );
};
