import { Box } from '@mui/material';
import { ZeltCalendarHoliday } from '@v2/feature/calendar/features/calendar.page';

import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CalendarPublicHolidayDrawerContent = ({
  userId,
  publicHoliday,
}: {
  userId: number;
  publicHoliday: ZeltCalendarHoliday;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
      <Typography variant="title2">{publicHoliday.name}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        <Typography variant="caption" sx={{ color: themeColors.Grey }}>
          Employee
        </Typography>
        <UserCell userId={userId} avatarSize="xxxsmall" nameSx={themeFonts.caption} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        <Typography variant="caption" sx={{ color: themeColors.Grey }}>
          Period
        </Typography>
        <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
          {publicHoliday.date}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        <Typography variant="caption" sx={{ color: themeColors.Grey }}>
          Note
        </Typography>
        <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
          {publicHoliday.name}
        </Typography>
      </Box>
    </Box>
  );
};
