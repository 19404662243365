import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { AutocompleteComponent, AutocompleteComponentProps } from '@/v2/components/forms/autocomplete.component';
import { getGenderOptions } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

type GenderAutocompleteComponentProps = Omit<
  AutocompleteComponentProps,
  'onChange' | 'label' | 'options' | 'compareValue' | 'value' | 'ref'
>;

export interface GenderSelectProps extends GenderAutocompleteComponentProps {
  name: string;
  error?: boolean;
  helperText?: string | false;
  onChange: (gender: string | null) => void;
  value?: string;
}

export const GenderSelect = ({ name, value, error, helperText, onChange, ...autoCompleteProps }: GenderSelectProps) => {
  const { polyglot } = usePolyglot();
  return (
    <AutocompleteComponent
      {...autoCompleteProps}
      name={name}
      label={polyglot.t('GenderSelect.gender')}
      options={getGenderOptions(polyglot)}
      value={getGenderOptions(polyglot).find((option) => option.value === value)}
      compareValue={value ?? ''}
      onChange={(_, x) => {
        // @ts-ignore
        onChange((x?.value as string) ?? null);
      }}
      error={error}
      helperText={helperText}
    />
  );
};
