import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { InsuranceAPI } from '@/v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsuranceQuoteDto } from '@/v2/feature/benefits/subfeature/insurance/insurance.dto';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const validationSchema = yup.object({
  includedUserIds: yup.array().required('Required field').min(1, 'Please choose at least user'),
});

export const InsuranceUpdateUserPolicyDrawer = ({
  insuranceQuote,
  refresh,
  setIsOpen,
}: {
  insuranceQuote: InsuranceQuoteDto;
  readonly refresh: () => Promise<void>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: { includedUserIds: insuranceQuote.includedUserIds ?? [] },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await InsuranceAPI.updateQuoteMembers(insuranceQuote.id, values.includedUserIds);
        showMessage(`Quote successfully updated`, 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box>
          <Typography sx={{ ...themeFonts.title2 }}>Edit employees</Typography>

          <Box sx={fieldSx}>
            <UserSelect
              label="Who should be selected?"
              selectedLabel="Selected employees"
              value={formik.values.includedUserIds}
              onChange={(userIds: number[]) => {
                formik.setFieldValue('includedUserIds', userIds);
              }}
              error={formik.touched.includedUserIds && Boolean(formik.errors.includedUserIds)}
              helperText={formik.touched.includedUserIds && formik.errors.includedUserIds}
              fieldSx={{ ...spacing.mb20 }}
            />
          </Box>

          <Box sx={{ ...spacing.mt20 }}>
            <LoaderButton name="Save" sizeVariant="large" colorVariant="primary" loading={loading} fullWidth />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
