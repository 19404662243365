import React, { Dispatch, SetStateAction } from 'react';

import { AbsenceDto, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceDrawerPage } from '@v2/feature/absence/sections/absence-drawer/absence-drawer.page';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { AbsenceImportDto } from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';

interface AbsenceDrawerProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly afterClose?: () => void;
  readonly assignedUserId?: number;
  readonly userId?: number;
  readonly absence?: AbsenceDto;
  readonly refresh?: (policyId: number) => Promise<void> | void;
  readonly setIsAbsenceDrawerOpen: Dispatch<SetStateAction<boolean>>;
  readonly reach?: 'company' | 'team';
  usedForDataImport?: boolean;
  readonly importHandler?: (values: AbsenceImportDto, policy: AbsencePolicyDto | undefined) => void;
  readonly policyId?: number;
}

export const AbsenceDrawer = ({
  isOpen,
  onClose,
  afterClose,
  absence,
  userId,
  refresh,
  setIsAbsenceDrawerOpen,
  reach,
  usedForDataImport,
  importHandler,
  policyId,
}: AbsenceDrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsAbsenceDrawerOpen} onClose={onClose} afterClose={afterClose}>
    <AbsenceDrawerPage
      absence={absence}
      userId={userId}
      refresh={refresh}
      closePage={() => onClose()}
      reach={reach}
      usedForDataImport={usedForDataImport}
      importHandler={importHandler}
      policyId={policyId}
    />
  </DrawerModal>
);
