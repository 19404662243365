import React from 'react';

import { Box, IconButton } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import '@/component/dashboard/dashboard.css';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Back } from '@/images/fields/Left.svg';
import { ReactComponent as Expand } from '@/images/new-theme-icon/Expand.svg';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { StyledSwipeableDrawer } from '@/v2/styles/sidebar-edit.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const TRANSITION_DURATION = 400;

interface DrawerModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.JSX.Element;
  readonly onClose?: () => Promise<void> | void;
  readonly afterClose?: () => Promise<void> | void;
  readonly widthPercentage?: number;
  readonly fixedWidthPx?: number; // fixedWidth has priority over widthPercentage
  readonly bgcolor?: string;
  readonly sx?: SxProps<Theme> | null;
  readonly showExpand?: boolean;
  readonly expandAction?: () => void | undefined;
  readonly closeIcon?: 'close' | 'back';
  readonly loading?: boolean;
}

export const DrawerModal = ({
  isOpen,
  setIsOpen,
  children,
  onClose,
  afterClose,
  widthPercentage = 35,
  fixedWidthPx = undefined,
  bgcolor = themeColors.white,
  sx = spacing.px16,
  showExpand = false,
  expandAction,
  closeIcon = 'close',
  loading,
}: DrawerModalProps) => {
  const width = widthPercentage / 100;

  return (
    <StyledSwipeableDrawer
      anchor="right"
      open={isOpen}
      transitionDuration={400}
      ModalProps={{
        // setting this to true will load the drawer content before the drawer is opened
        // (the documentation says the default value is false, but in the code it has been changed to true)
        keepMounted: false,
      }}
      PaperProps={{
        sx: {
          height: '100%',
          width:
            fixedWidthPx !== undefined
              ? `${fixedWidthPx}px`
              : {
                  xs: '100%',
                  sm: '380px',
                  md: '380px',
                  lg: `calc(calc(100% - 199px) * ${width})`,
                  xl: `calc(calc(100% - 199px) * ${width})`,
                },
          bgcolor,
        },
      }}
      onOpen={() => {
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
      onClose={async () => {
        if (onClose) await onClose();
        setIsOpen(false);
        if (afterClose) setTimeout(() => afterClose(), TRANSITION_DURATION);
      }}
    >
      <>
        {isOpen && (
          <>
            <Box
              className="fadeIn-delay"
              sx={{
                position: 'fixed',
                zIndex: 2,
                right:
                  fixedWidthPx !== undefined
                    ? `${fixedWidthPx + 10}px`
                    : {
                        xs: '10px',
                        sm: `calc(calc(100% - 199px) * 390px)`,
                        md: `calc(calc(100% - 199px) * 390px)`,
                        lg: `calc(calc(100% - 199px) * ${width} + 10px)`,
                        xl: `calc(calc(100% - 199px) * ${width} + 10px)`,
                      },
                top: 20,
              }}
            >
              <IconButton
                sx={{ ...iconCTAButtonSx, backgroundColor: themeColors.white, fill: themeColors.DarkGrey }}
                onClick={async () => {
                  if (onClose) await onClose();
                  setIsOpen(false);
                }}
              >
                {{
                  close: () => <Close {...iconSize} stroke={themeColors.DarkGrey} />,
                  back: () => <Back {...iconSize} stroke={themeColors.DarkGrey} />,
                }[closeIcon]()}
              </IconButton>
            </Box>
            {showExpand && (
              <Box
                className="fadeIn-delay"
                sx={{
                  position: 'fixed',
                  zIndex: 2,
                  right:
                    fixedWidthPx !== undefined
                      ? `${fixedWidthPx}px`
                      : {
                          xs: '10px',
                          sm: `calc(calc(100% - 199px) * 390px)`,
                          md: `calc(calc(100% - 199px) * 390px)`,
                          lg: `calc(calc(100% - 199px) * ${width} + 10px)`,
                          xl: `calc(calc(100% - 199px) * ${width} + 10px)`,
                        },
                  top: 80,
                }}
              >
                <IconButton
                  sx={{ ...iconCTAButtonSx, backgroundColor: themeColors.white, fill: themeColors.DarkGrey }}
                  onClick={expandAction}
                >
                  <Expand width={14} height={14} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
                </IconButton>
              </Box>
            )}
          </>
        )}
        <Box sx={{ py: spacing.p16, position: 'relative', ...sx, height: '100%' }}>
          {loading ? <DrawerModalLoader /> : <>{children}</>}
        </Box>
      </>
    </StyledSwipeableDrawer>
  );
};

const DrawerModalLoader = () => {
  return (
    <Box>
      <SkeletonLoader variant="rectangular" height="36px" width="100%" sx={{ background: themeColors.Background }} />
      <LoadingSpinner size="lg" yMargin="lg" />
    </Box>
  );
};
