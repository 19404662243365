import { useRef } from 'react';

import { Box } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import 'chart.js/auto';

type LayoutPosition = 'left' | 'top' | 'right' | 'bottom' | 'center' | 'chartArea' | { [scaleId: string]: number };

type LayoutAlign = 'center' | 'end' | 'start' | undefined;

const defaultColors = [
  '#ADD836',
  '#E43700',
  '#4F7BEB',
  '#7D5CB8',
  '#FFCD6D',
  '#B90E0F',
  '#FD9F26',
  '#F8BCD0',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
];
export const ChartPolarArea = ({
  series,
  categories,
  colors = defaultColors,
  legendPosition = 'left',
  legendAlign = 'start',
  noDataTitle = 'Data not found',
  noData,
}: {
  series: number[];
  categories: string[];
  colors?: string[];
  legendPosition?: LayoutPosition;
  legendAlign?: LayoutAlign;
  noDataTitle?: string;
  noData?: boolean;
}) => {
  const chartRef = useRef<ChartJS<'polarArea'>>(null);

  const chartData = {
    labels: categories,
    datasets: [
      {
        data: series,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: { padding: 4, stepSize: 25, display: false },
        pointLabels: {
          display: false,
          font: {
            size: 14,
            family: 'Inter, sans-serif',
            weight: 300,
          },
        },
        border: {
          dashOffset: 0.5,
        },
      },
    },
    plugins: {
      legend: {
        position: legendPosition as LayoutPosition,
        align: legendAlign as LayoutAlign,
        fullWidth: false,
        maxWidth: 180,
        labels: {
          pointStyle: 'circle',
          usePointStyle: true,
          boxWidth: 5,
          boxHeight: 5,
          padding: 20,
          color: themeColors.Grey,
          font: {
            size: 14,
            family: 'Inter, sans-serif',
            weight: 300,
          },
        },
      },
    },
  };

  if (noData)
    return (
      <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="caption" color="Grey">
          {noDataTitle}
        </Typography>
      </Box>
    );

  return <PolarArea ref={chartRef} data={chartData} options={options} />;
};
