import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMembersSection } from '@v2/feature/user/features/user-profile/details/components/user-family/user-family-members-section.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { useProfileFields } from '@/hooks/profile-fields.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  FAMILY_INFORMATION_MARITAL,
  FamilyValues,
  getMaritalOptions,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';

export const FamilySchema = Yup.object().shape({
  maritalStatus: Yup.string().oneOf(FAMILY_INFORMATION_MARITAL).notRequired(),
});

interface Props {
  readonly initialValues: FamilyValues;
  readonly userId: number;
  readonly usedForDataImport?: boolean;
  readonly userFamilyMembers: UserFamilyMemberDto[];
  readonly refreshMembers: () => Promise<void>;
  readonly onSubmit?: (_: FamilyValues) => void;
  readonly onClose: () => void;
  readonly handleRefresh: () => void;
}

export const FamilyForm = ({
  initialValues,
  userFamilyMembers,
  refreshMembers,
  usedForDataImport,
  userId,
  onSubmit,
  onClose,
  handleRefresh,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { getDefaultField } = useProfileFields();

  const formik = useFormik<FamilyValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: FamilySchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          await UserAPI.patchUserFamilyInfo(userId, { ...values });
          showMessage(polyglot.t('FamilyForm.successMessages.update'), 'success');
        }
        onSubmit?.({ ...values });
        handleRefresh();
      } catch (error) {
        showMessage(polyglot.t('FamilyForm.errorMessages.save', { msg: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('FamilyForm.edit')}</Typography>
        <ProfileField fieldStub="maritalStatus">
          <SelectComponent
            name="maritalStatus"
            label={polyglot.t('FamilyForm.maritalStatus')}
            options={getMaritalOptions(polyglot)}
            value={formik.values.maritalStatus}
            compareValue={formik.values.maritalStatus}
            error={!!formik.errors.maritalStatus && formik.touched.maritalStatus}
            onChange={formik.handleChange}
            helperText={formik.errors.maritalStatus && formik.touched.maritalStatus}
            required={getDefaultField('maritalStatus')?.isRequired}
          />
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
        />

        <UserFamilyMembersSection
          userId={userId}
          userFamilyMembers={userFamilyMembers}
          refreshMembers={refreshMembers}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
