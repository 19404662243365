import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { SuperAdminDevicePossessionUpdate } from '@v2/feature/device/device.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { createShortDateAsUTC } from '@v2/util/date-format.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';

interface DevicePossessionDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
  readonly companyId: number;
  readonly refresh: () => Promise<void>;
  readonly onUpdate: () => void;
}

export const DevicePossessionDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  companyId,
  refresh,
  onUpdate,
}: DevicePossessionDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <DevicePossessionDrawerContent
      companyId={companyId}
      devicePossession={devicePossession}
      refresh={refresh}
      onUpdate={onUpdate}
    />
  </DrawerModal>
);

interface DevicePossessionDrawerContentProps {
  readonly companyId: number;
  readonly devicePossession: DevicePossessionDto;
  readonly refresh: () => Promise<void>;
  readonly onUpdate: () => void;
}

const DevicePossessionDrawerContent = ({
  companyId,
  devicePossession,
  refresh,
  onUpdate,
}: DevicePossessionDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<SuperAdminDevicePossessionUpdate>({
    initialValues: {
      startDate: devicePossession.startDate,
      endDate: devicePossession.endDate,
      deliveryAddress: devicePossession.deliveryAddress,
    },
    validationSchema: yup.object({
      startDate: yup.date().nullable().notRequired().typeError('Invalid date format.'),
      endDate: yup.date().nullable().notRequired().typeError('Invalid date format.'),
      deliveryAddress: yup.string().required('Delivery address is required.'),
    }),
    onSubmit: async (values) => await updateDevicePossession(values),
  });

  const updateDevicePossession = async (possessionUpdate: SuperAdminDevicePossessionUpdate): Promise<void> => {
    try {
      setLoading(true);
      await DeviceAPI.updateCompanyDevicePossessionById(companyId, devicePossession.id, possessionUpdate);
      showMessage('Possession updated successfully.', 'success');
      await refresh();
      onUpdate();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography sx={titleSx}>Update possession</Typography>

      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Box sx={fieldSx}>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.startDate ? formik.values.startDate : ''}
              onChange={(newVal) => {
                const formattedDate = newVal ? createShortDateAsUTC(newVal) : undefined;
                formik.setFieldValue('startDate', formattedDate);
              }}
              name="startDate"
              label="Start date"
            />
          </Box>
          <Box sx={fieldSx}>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.startDate ? formik.values.startDate : ''}
              onChange={(newVal) => {
                const formattedDate = newVal ? createShortDateAsUTC(newVal) : undefined;
                formik.setFieldValue('endDate', formattedDate);
              }}
              name="endDate"
              label="End date"
            />
          </Box>
          <Box sx={fieldSx}>
            <TextfieldComponent
              label="Delivery Address"
              name="deliveryAddress"
              value={formik.values.deliveryAddress}
              onChange={formik.handleChange}
              error={formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress)}
              helperText={(formik.touched.deliveryAddress && formik.errors.deliveryAddress) as string}
              fullWidth
              size="small"
              endAdornment="none"
            />
          </Box>

          <LoaderButton name="Update" loading={loading} sizeVariant="small" colorVariant="primary" fullWidth />
        </Form>
      </FormikProvider>
    </>
  );
};
