import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { HelperAbsenceCarryOverCalculationDrawer } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-carry-over-calculation-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { spacing } from '@v2/styles/spacing.styles';
import { keyBy } from 'lodash';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { UserAbsenceAllowanceAdjustmentDto } from '@/models/absence.model';

interface SectionProps {
  readonly companyId: number;
  readonly absencePolicies: AbsencePolicyDto[];
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const HelperAbsenceUsersCarryOverSection = ({ companyId, absencePolicies, users }: SectionProps) => {
  const [selectedPolicyId, setSelectedPolicyId] = useState<number | null>(null);
  const [carryOverYear, setCarryOverYear] = useState<number>(new Date().getFullYear());

  const [searchInput, setSearchInput] = useState<string>('');
  const [showCarryOver, setShowCarryOver] = useState<boolean>(true);
  const [showOneOff, setShowOneOff] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmationDrawerOpen, setIsConfirmationDrawerOpen] = useState<boolean>(false);

  const [usersAdjustments, setUsersAdjustments] = useState<UserAbsenceAllowanceAdjustmentDto[] | null>(null);

  const [showMessage] = useMessage();

  const usersMap = useMemo(() => keyBy(users, 'userId'), [users]);
  const policiesMap = useMemo(() => keyBy(absencePolicies, 'id'), [absencePolicies]);

  const getUsersCarryOverByYear = useCallback(async () => {
    if (!selectedPolicyId || !carryOverYear) {
      showMessage('Please select a year and a policy first', 'error');
      return;
    }
    try {
      setLoading(true);
      const usersAdjustments = await AbsenceAPI.getCompanyUserAllowanceAdjustmentsByYearAsSuperAdmin(
        companyId,
        carryOverYear,
        selectedPolicyId
      );
      setUsersAdjustments(usersAdjustments);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get users carry over. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [selectedPolicyId, carryOverYear, companyId, showMessage]);

  const columns = useMemo<ColumnDef<UserAbsenceAllowanceAdjustmentDto, UserAbsenceAllowanceAdjustmentDto>[]>(
    () => [
      {
        header: () => 'User ID',
        id: 'userId',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.userId),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return <Typography variant="caption">{original.userId}</Typography>;
        },
      },
      {
        header: () => 'User name',
        id: 'firstName',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) =>
            usersMap[item.userId] ? `${usersMap[item.userId].firstName} ${usersMap[item.userId].lastName}` : ''
          ),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const user = usersMap[original.userId];
          return user ? (
            <Typography variant="caption">{`${user.firstName} ${user.lastName}`}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Policy',
        id: 'policyId',
        accessorFn: (row) => row,
        enableSorting: false,
        // sortingFn: (a, b) =>
        //   sortString(a, b, (item) => (policiesMap[item.policyId] ? policiesMap[item.policyId].name : '')),
        cell: ({ row: { original } }) => {
          const policy = policiesMap[original.policyId];
          return policy ? <Typography variant="caption">{policy.name}</Typography> : <EmptyCell />;
        },
      },
      {
        header: () => 'Effective year',
        id: 'effectiveYear',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return <Typography variant="caption">{original.effectiveYear}</Typography>;
        },
      },
      {
        header: () => 'Type',
        id: 'type',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return <Typography variant="caption">{original.type}</Typography>;
        },
      },
      {
        header: () => 'Adjustment (minutes)',
        id: 'adjustment',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.adjustment ?? 0),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography variant="caption">{original.adjustment}</Typography>,
      },
      {
        header: () => 'Adjustment (hours)',
        id: 'adjustment_hours',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.adjustment ?? 0),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography variant="caption">{convertMinutesToClockHours(original.adjustment, null)}</Typography>
        ),
      },
    ],
    [usersMap, policiesMap]
  );

  const filteredUserAllowance = useMemo(() => {
    if (!usersAdjustments) return null;

    let filtered = !searchInput
      ? usersAdjustments
      : usersAdjustments.filter((userAdjustment) => {
          if (searchInput === String(userAdjustment.userId)) return true;

          const user = users.find((user) => user.userId === userAdjustment.userId);
          return user && `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchInput.toLowerCase());
        });

    if (showOneOff && showCarryOver) return filtered;
    return filtered.filter((r) => (r.type === 'one-off' && showOneOff) || (r.type === 'carry-over' && showCarryOver));
  }, [usersAdjustments, searchInput, users, showCarryOver, showOneOff]);

  const yearsOptions = useMemo(() => {
    const policy = selectedPolicyId && absencePolicies.find((p) => p.id === selectedPolicyId);
    if (!policy || (policy.cycleStartDay === 1 && policy.cycleStartMonth === 1))
      return [
        { label: '2021', value: 2021 },
        { label: '2022', value: 2022 },
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
        { label: '2026', value: 2026 },
      ];

    return [
      { label: '2021/22', value: 2021 },
      { label: '2022/23', value: 2022 },
      { label: '2023/24', value: 2023 },
      { label: '2024/25', value: 2024 },
      { label: '2025/26', value: 2025 },
      { label: '2026/27', value: 2026 },
    ];
  }, [absencePolicies, selectedPolicyId]);

  return (
    <Box>
      <Typography variant="title3" sx={{ mt: '20px', mb: '10px' }}>
        Users allowance adjustments by year & policy
      </Typography>

      <Box sx={{ display: 'flex', gap: spacing.gap20, alignItems: 'end' }}>
        <SelectComponent
          name="policy"
          label="Policy"
          options={(absencePolicies ?? []).map((policy) => ({
            label: `${policy.name} (${policy.id})`,
            value: policy.id,
          }))}
          value={selectedPolicyId}
          onChange={(e) => setSelectedPolicyId(Number(e.target.value))}
          sx={{ width: '200px' }}
        />

        <SelectComponent
          name="effectiveYear"
          label="Effective year"
          options={yearsOptions}
          value={carryOverYear}
          onChange={(e) => setCarryOverYear(Number(e.target.value))}
          sx={{ width: '100px' }}
        />

        <LoaderButton
          name="Get Adjustments"
          onClick={getUsersCarryOverByYear}
          loading={loading}
          disabled={!selectedPolicyId}
          sizeVariant="medium"
          colorVariant="primary"
        />
      </Box>

      {filteredUserAllowance ? (
        <Box sx={{ mt: spacing.mt20 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <TableSearch
                query={searchInput}
                handleChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                placeholder="Search"
                style={{ minWidth: '100px' }}
              />
              <CheckboxComponent
                name="carry-over"
                label="carry-over"
                checked={showCarryOver}
                onChange={() => setShowCarryOver((prev) => !prev)}
              />
              <CheckboxComponent
                name="one-off"
                label="one-off"
                checked={showOneOff}
                onChange={() => setShowOneOff((prev) => !prev)}
              />
            </Box>
            <LoaderButton
              name={`Calculate Carry Over (${companyId})`}
              onClick={() => {
                setIsConfirmationDrawerOpen(true);
              }}
              loading={loading}
              disabled={!!selectedPolicyId && selectedPolicyId < 1}
              sizeVariant="small"
              colorVariant="secondary"
            />

            {selectedPolicyId && (
              <HelperAbsenceCarryOverCalculationDrawer
                isOpen={isConfirmationDrawerOpen}
                setIsOpen={setIsConfirmationDrawerOpen}
                absencePolicy={absencePolicies.find((p) => p.id === selectedPolicyId)}
                companyId={companyId}
                carryOverYear={carryOverYear}
                refresh={getUsersCarryOverByYear}
              />
            )}
          </Box>
          <Box>
            <Box sx={{ mt: spacing.mt20 }}>
              <BasicTable<UserAbsenceAllowanceAdjustmentDto>
                rowData={filteredUserAllowance}
                columnData={columns}
                hidePagination
                loading={loading}
                noDataMessage="Sorry, we couldn't find anything..."
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: spacing.mt20 }}>
          <Typography variant="caption">
            Please select a year and a policy, then click the button to get the carry over data.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
