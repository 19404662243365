import React from 'react';

import { Stack, Typography } from '@mui/material';

import { PayrollSettingHMRCEdit } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-edit.component';
import { ExternalEmployerDto } from '@/v2/feature/payroll/payroll-external.dto';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollCompanyHMRCSetupPageProps = {
  onClose: (updated: boolean) => void;
  payrollId: number;
  readonly payroll?: ExternalEmployerDto;
};

export const PayrollCompanyHMRCSetupPage = ({ onClose, payroll, payrollId }: PayrollCompanyHMRCSetupPageProps) => {
  return (
    <Stack sx={{ flex: 1, height: '100%', gap: spacing.m30, mx: spacing.mx40, mt: spacing.m30 }}>
      <Typography sx={themeFonts.title2}>Complete HMRC Information</Typography>
      <PayrollSettingHMRCEdit payrollId={payrollId} payroll={payroll} onClose={onClose} />
    </Stack>
  );
};
