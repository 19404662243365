import { Typography } from '@mui/material';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { InHouseMdmInstalledApplications } from '@v2/feature/device/features/device-cards/components/inhouse-mdm-installed-applications.component';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly devicePossession: DevicePossessionDto;
  readonly loading: boolean;
}

export const InHouseMdmDeviceInstalledAppsPage = ({ devicePossession, loading }: Props) => {
  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>} />
      <ContentWrapper loading={false} secondLevel={true} sx={{}}>
        <InHouseMdmInstalledApplications device={devicePossession?.device!} loading={loading} />
      </ContentWrapper>
    </RootStyle>
  );
};
