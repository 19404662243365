// eslint-disable-next-line filenames/match-regex
import { HEADER_FONT_PRIMARY } from '@/v2/styles/fonts.styles';

const SMART_FIELD_SECTION_HEADER_STYLE = `fontFamily:${HEADER_FONT_PRIMARY}; fontSize: 9; opacity: 0.4`;

// eslint-disable-next-line filenames/match-regex
export const getPersonalSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Personal</span>
    <span class='smart__field__option' id='first_name_option'>First name</span>
    <span class='smart__field__option' id='last_name_option'>Last name</span>
    <span class='smart__field__option' id='full_name_option'>Full name</span>
    <span class='smart__field__option' id='dob_option'>Date of birth</span>
    <span class='smart__field__option' id='passport_number_option'>Passport number</span>
    <span class='smart__field__option' id='personal_email_option'>Personal email</span>
    <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Address</span>
    <span class='smart__field__option' id='address_option'>Full address</span>
    <span class='smart__field__option' id='address_line1_option'>Address line 1</span>
    <span class='smart__field__option' id='address_line2_option'>Address line 2</span>
    <span class='smart__field__option' id='address_city_option'>City</span>
    <span class='smart__field__option' id='address_country_option'>Country</span>
    <span class='smart__field__option' id='address_postcode_option'>Postcode</span>
  </div>
</div>`;

export const getWorkSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Role</span>
  <span class='smart__field__option' id='start_date_option'>Start date</span>
    <span class='smart__field__option' id='role_option'>Job title</span>
    <span class='smart__field__option' id='reports_to_option'>Reports to</span>
    <span class='smart__field__option' id='site_option'>Site</span>
    <span class='smart__field__option' id='department_option'>Department</span>
    <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Contract</span>
    <span class='smart__field__option' id='probation_period_option'>Probation period</span>
    <span class='smart__field__option' id='notice_period_option'>Notice period</span>
    <span class='smart__field__option' id='worker_type_option'>Worker type</span>
    <span class='smart__field__option' id='employment_contract_option'>Employment contract</span>
    <span class='smart__field__option' id='attendance_schedule_option'>Attendance schedule hours</span>
  </div>
</div>`;

// FTE% removed from work options
// <span class='smart__field__option' id='fte_percentage_option'>Attendance schedule FTE%</span>

export const getCompensationSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Salary</span>
  <span class='smart__field__option' id='salary_basis_option'>Salary basis</span>
  <span class='smart__field__option' id='rate_option'>Amount</span>
  <span class='smart__field__option' id='units_option'>Units</span>
  <span class='smart__field__option' id='pay_schedule_option'>Pay schedule</span>

  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Equity</span>
  <!--<span class='smart__field__option' id='equity_option'>Equity</span>-->
  <span class='smart__field__option' id='equity_type_option'>Equity type</span>
  <span class='smart__field__option' id='equity_grant_date_option'>Grant date</span>
  <span class='smart__field__option' id='equity_amount_option'>Amount</span>
  <span class='smart__field__option' id='equity_vesting_start_option'>Vesting start</span>
  <span class='smart__field__option' id='equity_vesting_period_option'>Vesting period</span>
  <span class='smart__field__option' id='equity_vesting_cliff_option'>Vesting cliff</span>
  <span class='smart__field__option' id='equity_exercise_price_option'>Exercise price</span>

  </div>
</div>`;

export const getSignatureSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Recipient</span>
  <span class='smart__field__option' id='employee_signature_option'>Recipient Signature</span>
  <span class='smart__field__option' id='employee_signature_timestamp'>Recipient Signature Timestamp</span>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Additional</span>
  <span class='smart__field__option' id='company_signature_option'>Additional Signatory Signature</span>
  <span class='smart__field__option' id='company_signature_timestamp'>Additional Signatory Timestamp</span>
  <span class='smart__field__option' id='company_signature_full_name'>Additional Signatory Full name</span>
  <span class='smart__field__option' id='company_signature_job_title'>Additional Signatory Job title</span>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Witness</span>
  <span class='smart__field__option' id='witness_details_option'>Witness Details</span>
  </div>
</div>`;

export const SMART_FIELD_TYPES = {
  // PERSONAL
  FIRST_NAME: '{{employee_first_name}}',
  LAST_NAME: '{{employee_last_name}}',
  FULL_NAME: '{{employee_full_name}}',
  DOB: '{{employee_dob}}',
  PASSPORT_NUMBER: '{{employee_passport_number}}',
  PERSONAL_EMAIL: '{{employee_personal_email}}',

  // ADDRESS
  ADDRESS: '{{employee_address}}',
  ADDRESS_LINE1: '{{employee_address_line1}}',
  ADDRESS_LINE2: '{{employee_address_line2}}',
  ADDRESS_CITY: '{{employee_address_city}}',
  ADDRESS_COUNTRY: '{{employee_address_country}}',
  ADDRESS_POSTCODE: '{{employee_address_postcode}}',

  // ROLE
  START_DATE: '{{employee_start_date}}',
  ROLE: '{{employee_role}}',
  REPORTS_TO: '{{employee_reports_to}}',
  SITE: '{{employee_site}}',
  DEPARTMENT: '{{employee_department}}',

  // CONTRACT
  PROBATION_PERIOD: '{{employee_probation_period}}',
  NOTICE_PERIOD: '{{employee_notice_period}}',
  WORKER_TYPE: '{{employee_worker_type}}',
  EMPLOYMENT_CONTRACT: '{{employee_employment_contract}}',
  ATTENDANCE_SCHEDULE: '{{employee_attendance_schedule}}',

  // COMPENSATION
  PAY_SCHEDULE: '{{compensation_pay_schedule}}',
  RATE: '{{compensation_rate}}',
  UNITS: '{{compensation_units}}',
  CURRENCY: '{{compensation_currency}}',
  SALARY_BASIS: '{{compensation_salary_basis}}',

  // EQUITY
  EQUITY: '{{compensation_equity}}',
  EQUITY_TYPE: '{{compensation_equity_type}}',
  EQUITY_GRANT_DATE: '{{compensation_equity_grant_date}}',
  EQUITY_AMOUNT: '{{compensation_equity_amount}}',
  EQUITY_VESTING_START: '{{compensation_equity_vesting_start}}',
  EQUITY_VESTING_PERIOD: '{{compensation_equity_vesting_period}}',
  EQUITY_VESTING_CLIFF: '{{compensation_equity_vesting_cliff}}',
  EQUITY_EXERCISE_PRICE: '{{compensation_equity_exercise_price}}',
  // PAID_TIME_OFF: '{{compensation_paid_time_off}}',
  //COMPANY_LOGO: '{{company_logo}}',

  // SIGNATURES
  RECIPIENT_SIGNATURE: `<span class='smartfield__signature'> {{employee_signature}} </span>`,
  EMPLOYEE_SIGNATURE_TIMESTAMP: '{{recipient_signature_timestamp}}',
  COMPANY_SIGNATURE: `<span class='smartfield__signature'> {{additional_signature}} </span>`,
  ADDITIONAL_SIGNATORY_TIMESTAMP: '{{additional_signatory_timestamp}}',
  ADDITIONAL_SIGNATORY_FULL_NAME: '{{additional_signatory_full_name}}',
  ADDITIONAL_SIGNATORY_JOB_TITLE: '{{additional_signatory_job_title}}',
  WITNESS_DETAILS: '{{witness_details}}',
} as const;

export const defaultButtons = [
  'bold',
  'italic',
  'underline',
  'font',
  'fontsize',
  'indent',
  'left',
  'center',
  'right',
  'justify',
];
