import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Document } from '@/images/dashboard-icons/Document.svg';
import { DocumentActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/document-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { DocumentTodo, MissingDocumentsBigWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const MissingDocumentsTodo = ({
  todo,
  setLastChild,
  userId,
  refreshTodos,
}: {
  todo: MissingDocumentsBigWidget;
  setLastChild: boolean;
  userId: number;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<DocumentTodo | undefined>(undefined);

  const getTitle = (doc: DocumentTodo) => {
    return polyglot.t('MissingDocumentsTodo.missingDocs', { docName: doc.name });
  };

  return (
    <Box>
      {todo.documents.map((doc) => (
        <TodoCard
          key="missing-docs"
          icon={<Document {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(doc)}
            </Typography>
          }
          actionOnclick={() => setSelectedRow(doc)}
          showBorder={!setLastChild}
          userAvatar={userId ? <UserAvatar userId={userId} size="xxxsmall" /> : <></>}
        />
      ))}

      {selectedRow && (
        <DocumentActionItem
          documentActionRow={selectedRow as DocumentTodo}
          refresh={async () => {
            await refreshTodos?.();
          }}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
