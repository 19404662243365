import Stack from '@mui/material/Stack';

import { Typography } from '@/v2/components/typography/typography.component';
import { spacing } from '@/v2/styles/spacing.styles';

type TitledHeaderProps = {
  label: string;
  value: React.ReactNode;
};

export const TitledHeader = ({ label, value }: TitledHeaderProps) => {
  return (
    <Stack sx={{ gap: spacing.g5 }}>
      <Typography variant="caption">{label}</Typography>
      {typeof value === 'object' && value}
      {typeof value !== 'object' && typeof value !== 'undefined' && (
        <Typography variant={'title2'} sx={{ whiteSpace: 'nowrap' }}>
          {`${value}`}
        </Typography>
      )}
    </Stack>
  );
};

export const TitledHeaderStack = ({ items }: { items: TitledHeaderProps[] }) => {
  return (
    <Stack sx={{ flexFlow: 'row', gap: spacing.g60 }}>
      {items.map((props) => (
        <TitledHeader key={props.label} {...props} />
      ))}
    </Stack>
  );
};
