import { Route, Switch } from 'react-router-dom';

import { EntityImportResultPageProps } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { PayrollImportCsvPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-import/pages/payroll-import-csv.page';
import { PayrollImportResultDto } from '@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.dto';

const ENTITY_IMPORT_WIZARD_ENTRIES_CSV_ROUTE = '/settings/import/wizard/payroll/csv';

export function PayrollImportRouter(props: EntityImportResultPageProps<PayrollImportResultDto>) {
  return (
    <Switch>
      <Route path={ENTITY_IMPORT_WIZARD_ENTRIES_CSV_ROUTE}>
        <PayrollImportCsvPage {...props} />
      </Route>
    </Switch>
  );
}
