/**
 * copy of the palette of greys found in `frontend/web-app/src/minimal-theme/theme/palette.ts`
 */
export const neutralColors = {
  /** theme */
  n0: '#FFFFFF',
  /** theme */
  n1: '#F2F2F7',
  /** theme */
  n2: '#EAEAED',
  /** theme */
  n3: '#DEDFE3',
  /** @deprecated */
  n4: '#C4CDD5',
  /** theme */
  n5: '#91939F',
  /** @deprecated */
  n6: '#637381',
  /** @deprecated */
  n7: '#454F5B',
  /** @deprecated */
  n8: '#212B36',
  /** @deprecated */
  n9: '#161C24',
  /** theme */
  n10: '#000000',
} as const;

/**
 * theme palette for Zelt
 */
export const themeColors = {
  black: '#000000',
  blue: '#328CE7',
  grey: '#91939F',
  middleGrey: '#DEDFE3',
  lightGrey: '#F2F2F7',
  pressGrey: '#EAEAED',
  hoverGrey: '#EEEEF2',
  tableHoverGrey: '#F9F9FB',
  darkRed: '#D70015',
  lightBlue: '#D9EBFF',
  pressBlue: '#0E54D8',
  hoverBlue: '#0F5CEE',
  superLightBlue: '#F3F7FF',
  middleBlue: '#89B2FF',
  green: '#32D74B',
  orange: '#FF9500',
  white: '#FFFFFF',
  red: '#FF352A',
  hoverRed: '#D70015',
  pressRed: '#D70015',
  lightRed: '#C78581',
  transparency: '#0000001A',
  backgroundTransparency: '##ffffff1a',

  //new branding primary
  ZeltYellow: '#FFCD6D',
  Orange: '#FF9664',
  DarkGrey: '#2F2F2F',
  GreyMiddle: '#DFDFDF',

  //new branding secondary
  Grey: '#9E9E9E',
  DisabledGrey: 'rgba(158, 158, 158, 0.3)',
  GreyPress: '#EEE9E3',
  GreyHover: '#F1EDE8',
  GreyLight: '#F5F5F5',
  Background: '#F6F3EF',
  TableHover: '#FBFAF9',
  ZeltSuccess: '#52B356',
  RedDark: '#B90F0F',
  YellowPress: '#F3BA4C',
  YellowHover: '#FAC256',
  LightYellow: '#FFE478',
  PastelYellow: '#FFEFB1',
  DisabledYellow: 'rgba(255, 205, 109, 0.5)',
  Blue: '#A8D1FF',
  BlueLight: '#D9EBFF',
  BlueSuperLight: '#F3F7FF',
  VioletMiddle: '#C783D2',
  LightViolet: '#FED7F8',
  Violet: '#E1BEE7',
  Bordo: '#C51162',
  LightPink: '#F8BBD0',
  Pink: '#F06292',
  DarkGreen: '#157E19',
  Green: '#74CF8C',
  GreenDisabled: 'rgba(116, 207, 140, 0.5)', // Green (#74CF8C) with opacity at 50%
  GreenMiddle: '#52B256',
  PastelGreen: '#C7F39B',
  SeaGreen: '#6BE1CC',
  FreshGreen: '#A5E88E',
  Red: '#EB5A4B',
  RedSuperLight: 'rgba(235, 90, 75, 0.1)',
  BrownHover: '#6D5144',
  PastelSeaGreen: '#26A69A',
  PastelBrown: '#9D695A',
  PastelPurple: '#C783D2',
} as const;

export type ThemeColor = keyof typeof themeColors;

interface RGBColor {
  readonly r: number;
  readonly g: number;
  readonly b: number;
}

export function hexToRgb(hex: string): RGBColor | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? ({
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } as const)
    : null;
}

export function webRgb({ r, g, b }: RGBColor, alpha: number = 1) {
  return `rgba(${r},${g},${b},${alpha})`;
}

export const PRIMARY_CHART_COLOR = ['#FFCD6D', '#74CF8C', '#318CE7', '#7D5CB7'];
export const SECONDARY_CHART_COLOR = [
  '#B90F0F',
  '#FD9F26',
  '#F8BBD0',
  '#6BE1CC',
  '#FFE478',
  '#FED7F8',
  '#A4E9EC',
  '#C51162',
  '#7D5CB7',
  '#F06292',
  '#74CF8C',
  '#E1BEE7',
  '#52B256',
  '#F6F3EF',
  '#C783D2',
  '#7D5CB7',
  '#237A70',
  '#C7F39B',
  '#26A69A',
];

export const DARK_CONTRAST_COLOR = [
  '#B90F0F',
  '#C51162',
  '#FD9F26',
  '#7D5CB7',
  '#237A70',
  '#EB5A4B',
  '#C783D2',
  '#26A69A',
  '#52B256',
  '#F06292',
];
export const LIGHT_CONTRAST_COLOR = [
  '#FFE478',
  '#C7F39B',
  '#96E979',
  '#74CF8C',
  '#F8BBD0',
  '#FED7F8',
  '#E1BEE7',
  '#A4E9EC',
  '#6BE1CC',
];

export const COLOR_PICKER_COLORS = [
  themeColors.ZeltYellow,
  themeColors.LightYellow,
  themeColors.PastelYellow,
  themeColors.Orange,
  themeColors.Red,
  themeColors.Background,
  themeColors.GreyMiddle,
  themeColors.BlueSuperLight,
  themeColors.BlueLight,
  themeColors.Blue,
  themeColors.VioletMiddle,
  themeColors.Bordo,
  themeColors.Pink,
  themeColors.LightPink,
  themeColors.LightViolet,
  themeColors.Violet,
  themeColors.Green,
  themeColors.FreshGreen,
  themeColors.PastelGreen,
  themeColors.SeaGreen,
  themeColors.GreenMiddle,
];
