import { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { CompanyPayrollType } from '@v2/feature/payroll/payroll.interface';

import { TitledHeaderStack } from '@/v2/components/titled-header.component';
import { PayrunHeader } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header.component';
import { PayrunOverviewTabs } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-overview-tabs.component';
import { PayrunProcessSection } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-process.section';
import { PayrunSalariesSection } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-salaries.section';
import { ExternalPayRunDto } from '@/v2/feature/payroll/payroll-external.dto';
import { PayRunDto, PayRunEntryDto } from '@/v2/feature/payroll/payroll.dto';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

type PayrunTotalPaymentsSummaryProps = {
  payrun: ExternalPayRunDto;
  payrunEmpAllowance: number;
};

export const PayrunTotalPaymentsSummary = ({ payrun, payrunEmpAllowance }: PayrunTotalPaymentsSummaryProps) => {
  const {
    totalCost = 0,
    takeHomePay = 0,
    tax = 0,
    employerNi = 0,
    employeeNi = 0,
    studentLoanRecovered = 0,
    postgradLoanRecovered = 0,
    employeePensionContribution = 0,
    employerPensionContribution = 0,
  } = payrun.totals;
  const totalPension = employeePensionContribution + employerPensionContribution;
  const totalHMRC = tax + employerNi + employeeNi + studentLoanRecovered + postgradLoanRecovered - payrunEmpAllowance;
  const employerCost = totalCost - payrunEmpAllowance;
  return (
    <TitledHeaderStack
      items={[
        { label: 'Paid to Employees', value: formatCurrency(takeHomePay) },
        { label: 'Paid to Pension', value: formatCurrency(totalPension) },
        { label: 'Paid to HMRC', value: formatCurrency(totalHMRC) },
        { label: 'Employer Cost', value: formatCurrency(employerCost) },
      ]}
    />
  );
};

const PayrunOverviewTabsLabels = ['Overview', 'Employees'] as const;
type PayrunOverviewTabsLabel = typeof PayrunOverviewTabsLabels[number];

type PayrunFlowPageProps = {
  entityName: string;
  payrollCount: number;
  externalPayRun: ExternalPayRunDto;
  entries: PayRunEntryDto[];
  previousEntries: PayRunEntryDto[];
  localPayRun: PayRunDto;
  payrunEmpAllowance: number;
  refreshPayrun: () => Promise<void>;
  completePayrun: (localPayrun: PayRunDto) => Promise<boolean>;
};

export const PayrunOverviewPage = ({
  entityName,
  externalPayRun,
  entries,
  previousEntries,
  localPayRun,
  payrunEmpAllowance,
  refreshPayrun,
  completePayrun,
}: PayrunFlowPageProps) => {
  const [tab, setTab] = useState<PayrunOverviewTabsLabel>('Overview');
  return (
    <Stack sx={{ flex: 1, overflow: 'hidden' }}>
      <PayrunHeader
        entityName={entityName}
        payrollType={CompanyPayrollType.UKPayroll}
        payrollId={localPayRun.payrollId}
        state={localPayRun.state}
        onReopenPayrun={refreshPayrun}
        sx={{ mt: spacing.m20, mr: spacing.mr40 }}
      />
      <Box sx={{ mt: spacing.mt20 }} />
      <PayrunTotalPaymentsSummary payrun={externalPayRun} payrunEmpAllowance={payrunEmpAllowance} />
      <PayrunOverviewTabs
        tabs={PayrunOverviewTabsLabels}
        currentTab={tab}
        onChange={(tab) => setTab(tab)}
        sx={{ mt: spacing.mt30 }}
      />
      {tab === 'Overview' && (
        <PayrunProcessSection
          hideTitle
          localPayRun={localPayRun}
          completePayrun={async (localPayrun) => {
            const result = await completePayrun(localPayrun);
            if (result) refreshPayrun();
            return result;
          }}
          sx={{ mt: spacing.m10 }}
        />
      )}
      {tab === 'Employees' && (
        <PayrunSalariesSection
          hideTitle
          hideButtons
          localPayRun={localPayRun}
          entries={entries}
          previousEntries={previousEntries}
          refreshPayrun={refreshPayrun}
        />
      )}
    </Stack>
  );
};
