import {
  CancelPlanDataDto,
  RefinancingPlanDto,
  TwoPreviewRepaymentPlanDto,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.dto';
import {
  CompanyRefinancing,
  CreateRepaymentData,
  RefinancingSearchResult,
  TwoCompanyAddress,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class RefinancingAPI {
  static async getCompaniesRefinancingData(companyIds: number[]): Promise<CompanyRefinancing[]> {
    return (
      await axios.get('/apiv2/companies/refinancing/superadmin', { params: { companyIds: companyIds.join(',') } })
    ).data;
  }

  static async searchCompanyNationalId(query: string): Promise<RefinancingSearchResult[]> {
    return (await axios.get(`/apiv2/companies/refinancing/superadmin/search?query=${query}`)).data;
  }

  static async searchCompanyAddressByNationalId(nationalId: string): Promise<TwoCompanyAddress | null> {
    return (await axios.get(`/apiv2/companies/refinancing/superadmin/address/national-id/${nationalId}`)).data ?? null;
  }

  static async runCompanyCreditCheck(companyId: number): Promise<CompanyRefinancing> {
    return (await axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/credit-check`)).data;
  }

  static async saveCompanyDetails(
    companyId: number,
    nationalId: string,
    legalName: string,
    address: { addressLine1: string; addressLine2?: string; postCode: string; city: string; countryCode: string }
  ): Promise<void> {
    await axios.patch(`/apiv2/companies/refinancing/superadmin/${companyId}`, { nationalId, legalName, address });
  }

  static async createCompanyRepaymentAccount(companyId: number, data: { billingPhone: string }): Promise<void> {
    await axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/account`, data);
  }

  static async cancelRefinancingPlanAsSuperAdmin(
    companyId: number,
    orderId: number,
    refinancingPlanId: number,
    data: CancelPlanDataDto
  ): Promise<void> {
    await axios.post(
      `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${refinancingPlanId}/cancel`,
      data
    );
  }

  static async createOrderRepaymentPlan(
    companyId: number,
    orderId: number,
    data: CreateRepaymentData
  ): Promise<RefinancingPlanDto> {
    return (await axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans`, data))
      ?.data;
  }

  static async deleteOrderRepaymentPlan(companyId: number, orderId: number, planId: number): Promise<void> {
    await axios.delete(`/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${planId}`);
  }

  static async getCompaniesRepaymentPlansForOrders(
    companyIds: number[],
    orderIds: number[]
  ): Promise<RefinancingPlanDto[]> {
    return (
      await axios.get('/apiv2/companies/refinancing/superadmin/plans', {
        params: {
          companyIds: companyIds.join(','),
          orderIds: orderIds.join(','),
        },
      })
    ).data;
  }

  static async updateAndReturnRepaymentPlanForOrder(
    companyId: number,
    refinancingPlanId: number,
    orderId: number
  ): Promise<RefinancingPlanDto> {
    return (
      await axios.put(
        `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${refinancingPlanId}/status`
      )
    ).data;
  }

  static async fulfilRepaymentPlanForOrder(
    companyId: number,
    refinancingPlanId: number,
    orderId: number
  ): Promise<RefinancingPlanDto> {
    return (
      await axios.put(
        `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${refinancingPlanId}/fulfil`
      )
    ).data;
  }

  static async previewOrderRepaymentPlan(
    companyId: number,
    orderId: number,
    data: CreateRepaymentData
  ): Promise<TwoPreviewRepaymentPlanDto> {
    return (
      await axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/preview`, data)
    )?.data;
  }
}

export class RefinancingEndpoints {
  static getOrderRepaymentPlan(companyId: number, orderId: number): Endpoint<RefinancingPlanDto> {
    return {
      url: `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans`,
    };
  }
}
