import { useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const SectionSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
  });
export const SectionModalContent = ({
  onClose,
  section,
  refresh,
  onSubmit,
}: {
  onClose: () => void;
  section: { name: string };
  refresh: () => Promise<void>;
  onSubmit: (values: { name: string }) => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const formik = useFormik({
    initialValues: {
      name: section ? section.name : '',
    },
    enableReinitialize: true,
    validationSchema: SectionSchema(),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await onSubmit(values);
        refresh();
        onClose();
      } catch (error) {
        showMessage("Couldn't create the section", 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            <Box sx={{ mb: spacing.m10 }}>
              <Typography variant="title2">{section ? 'Edit section' : 'New section'}</Typography>
            </Box>

            <Box sx={fieldSx}>
              <TextfieldComponent
                multiline
                name="name"
                label="Name"
                value={formik.values.name}
                type="text"
                onChange={formik.handleChange}
                error={formik.touched.name && !!formik.errors.name}
                helperText={(formik.touched.name && formik.errors.name) ?? ' '}
                endAdornment="none"
              />
            </Box>
          </Box>

          <Box sx={spacing.mt20}>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
