// BulkUploadContext.tsx
import React, { createContext, useContext, useReducer } from 'react';

import { IFileWithMeta } from 'react-dropzone-uploader';

import { ZeltDocumentUser } from '@/lib/documents';
import { DocumentUploadStatus } from '@/v2/feature/documents/documents.interface';
import { DocumentType, ExpirySettings, VisibilitySettings } from '@/v2/feature/documents/documents.util';

// Define the types for your context state and actions
export interface BulkUploadState {
  bulkDocuments: UploadState[]; // Store documents by documentId
  // Add more properties as needed
}

export interface UploadState {
  documentId: string;
  file: IFileWithMeta;
  assignee: ZeltDocumentUser[];
  visibilitySettings?: VisibilitySettings;
  expirySettings?: ExpirySettings;
  documentType?: DocumentType;
  note?: string;
  state: DocumentUploadStatus;
  pendingUpdate?: Partial<UploadState>; // to hold pending updates
}

export interface UserSelectOptionObj {
  value: number;
  label: string;
  firstName: string;
  lastName: string;
  displayName: string;
}

export type BulkUploadAction =
  | { type: 'ADD_DOCUMENT'; payload: UploadState }
  | { type: 'UPDATE_DOCUMENT'; payload: { documentId: string; updatedData: Partial<UploadState> } }
  | { type: 'REMOVE_DOCUMENT'; payload: string }
  | { type: 'RESTORE_STATE'; payload: BulkUploadState }
  | { type: 'UPDATE_DOCUMENT_PENDING'; payload: { documentId: string; updatedData: Partial<UploadState> } }
  | { type: 'COMMIT_DOCUMENT_UPDATE'; payload: string }
  | { type: 'CANCEL_DOCUMENT_UPDATE'; payload: string };

const initialState: BulkUploadState = {
  bulkDocuments: [],
};

const BulkUploadContext = createContext<
  | {
      state: BulkUploadState;
      dispatch: React.Dispatch<BulkUploadAction>;
    }
  | undefined
>(undefined);

export function useBulkUpload() {
  const context = useContext(BulkUploadContext);
  if (context === undefined) {
    throw new Error('useBulkUpload must be used within a BulkUploadProvider');
  }
  return context;
}

export function BulkUploadProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(bulkUploadReducer, initialState);

  // // Load state from local storage on initial render
  // useEffect(() => {
  //   const savedState = localStorage.getItem('bulkUploadState');
  //   if (savedState) {
  //     dispatch({ type: 'RESTORE_STATE', payload: JSON.parse(savedState) });
  //   }
  // }, []);

  // // Save state to local storage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem('bulkUploadState', JSON.stringify(state));
  // }, [state]);

  return <BulkUploadContext.Provider value={{ state, dispatch }}>{children}</BulkUploadContext.Provider>;
}

// Define your reducer function
function bulkUploadReducer(state: BulkUploadState, action: BulkUploadAction): BulkUploadState {
  switch (action.type) {
    case 'ADD_DOCUMENT':
      return { ...state, bulkDocuments: [...state.bulkDocuments, action.payload] };
    case 'UPDATE_DOCUMENT':
      const updatedState = state.bulkDocuments.map((doc) =>
        doc.documentId === action.payload.documentId ? { ...doc, ...action.payload.updatedData } : doc
      );
      return {
        ...state,
        bulkDocuments: updatedState,
      };
    case 'REMOVE_DOCUMENT':
      return {
        ...state,
        bulkDocuments: state.bulkDocuments.filter((doc) => doc.documentId !== action.payload),
      };
    case 'RESTORE_STATE':
      return action.payload;
    case 'UPDATE_DOCUMENT_PENDING':
      return {
        ...state,
        bulkDocuments: state.bulkDocuments.map((doc) =>
          doc.documentId === action.payload.documentId
            ? { ...doc, pendingUpdate: { ...(doc.pendingUpdate || {}), ...action.payload.updatedData } }
            : doc
        ),
      };
    case 'COMMIT_DOCUMENT_UPDATE':
      return {
        ...state,
        bulkDocuments: state.bulkDocuments.map((doc) =>
          doc.documentId === action.payload ? { ...doc, ...(doc.pendingUpdate || {}), pendingUpdate: undefined } : doc
        ),
      };
    case 'CANCEL_DOCUMENT_UPDATE':
      return {
        ...state,
        bulkDocuments: state.bulkDocuments.map((doc) =>
          doc.documentId === action.payload ? { ...doc, pendingUpdate: undefined } : doc
        ),
      };
    default:
      return state;
  }
}
