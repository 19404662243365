import { styled, Switch, SwitchProps } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const StyledSwitch = styled(Switch)(() => ({
  width: '38px',
  height: '20px',
  padding: '0px',
  borderRadius: radius.br15,
  '& span.MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root ': {
    padding: '0px',
    '& .Mui-checked': {
      color: themeColors.DarkGrey,
      left: '-2px',
    },
  },
  '& span.MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    margin: spacing.m2,
  },
  '& span.MuiButtonBase-root.MuiSwitch-switchBase': {
    color: themeColors.white,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: themeColors.GreyMiddle,
    opacity: 1,
  },

  '& span.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
    color: themeColors.white,
    left: '-2px',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: themeColors.DarkGrey,
    opacity: 1,
  },
}));

export const SwitchComponent = (props: SwitchProps) => {
  return <StyledSwitch {...props} />;
};
