import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { getRegularTimeFromScheduleData } from '@v2/feature/absence/absence.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CalendarAttendanceContentDrawer } from '@/v2/feature/calendar/features/components/calendar-attendance-content-drawer.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

interface Props {
  readonly schedule: {
    id: number;
    name: string;
    startHour: string | undefined;
    endHour: string | undefined;
  };
  readonly activeView: 'Month' | 'Week';
  readonly date: string;
  readonly userId: number;
}

export const CalendarScheduleEvent = ({ schedule, activeView, date, userId }: Props) => {
  const { polyglot } = usePolyglot();
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  const regularTime = useMemo(() => getRegularTimeFromScheduleData(schedule.startHour, schedule.endHour, polyglot), [
    polyglot,
    schedule,
  ]);

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', height: '40px' }}
        onClick={() => {
          setIsViewOpen(true);
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: themeColors.Background,
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
            boxSizing: 'border-box',
            opacity: 1,
            '&:hover::before': {
              cursor: 'pointer',
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              zIndex: 1,
            },
            '& > *': {
              position: 'relative',
              zIndex: 1,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5, px: spacing.p5 }}>
            {schedule && activeView === 'Week' && (
              <div style={{ width: '100%', overflow: 'hidden' }}>
                {(schedule.startHour || schedule.endHour) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                    <Typography variant="captionSmall" color="DarkGrey">
                      {regularTime}
                    </Typography>
                  </Box>
                )}

                {schedule && (
                  <Typography
                    variant="captionSmall"
                    color="Grey"
                    sx={{
                      textTransform: 'capitalize',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: activeView === 'Week' ? '140px' : '40px',
                    }}
                  >
                    {schedule.name}
                  </Typography>
                )}
              </div>
            )}
          </Box>
        </Box>
      </Box>

      <DrawerModal isOpen={isViewOpen} setIsOpen={setIsViewOpen}>
        <CalendarAttendanceContentDrawer
          userId={userId}
          selectedDate={new LocalDate(date).getDate()}
          schedule={schedule}
        />
      </DrawerModal>
    </>
  );
};
