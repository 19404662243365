import React, { useMemo } from 'react';

import { SelectComponent } from '@v2/components/forms/select.component';
import { FilterValueSelectorProps } from '@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component';
import { ReportSQLOperator } from '@v2/feature/reports/reports.interface';

export const BooleanSelector = ({ operator, value, setValue, label }: Omit<FilterValueSelectorProps, 'filterType'>) => {
  // TODO: @NOW - check all the filters in each type of operator!! in this folder

  const BooleanOperatorsWithNoExtraField = useMemo(() => {
    return new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);

  return BooleanOperatorsWithNoExtraField.has(operator) ? null : (
    <SelectComponent
      name="value"
      label={label}
      options={[
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ]}
      value={value as 'string'}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      fullWidth
    />
  );
};
