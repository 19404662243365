import React from 'react';

import { Box, SxProps } from '@mui/material';

import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { getSelectProps } from '@/v2/styles/menu.styles';

const meridiemOptions = [
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'PM',
    label: 'PM',
  },
];

interface TimeTextFieldProps {
  name: string;
  label: string;
  value: number | string | null;
  onChange: (value: number | string | null) => void;
  selectedMeridiem: string | null;
  onMeridiemChange: (value: string) => void;
  error?: boolean;
  helperText?: string;
  disableMeridiemSelector?: boolean;
  sx?: SxProps;
}

export const TimeTextField = ({
  name,
  label,
  value,
  onChange,
  selectedMeridiem,
  onMeridiemChange,
  error,
  helperText,
  disableMeridiemSelector = false,
  sx,
  ...props
}: TimeTextFieldProps) => {
  const handleMeridiemChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onMeridiemChange(event.target.value as string);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'flex-end',
          width: '100%',
          boxSizing: 'border-box',
          p: 0,
          m: 0,
          ...sx,
        }}
      >
        <Box sx={{ flex: '1 1 auto', boxSizing: 'border-box', p: 0, m: 0 }}>
          <TextfieldComponent
            {...props}
            fullWidth
            value={value === 0 ? '' : value}
            variant="standard"
            type="number"
            onChange={handleTimeChange}
            label={label}
            name={name}
            minValue={1}
            maxValue={12}
            maxLength={1}
            validateNumber={true}
            error={error}
            endAdornment="none"
            sx={{
              '& div.MuiInputBase-root.MuiInput-root': {
                borderBottomRightRadius: '0px !important',
                borderTopRightRadius: '0px !important',
              },
            }}
          />
        </Box>
        <Box sx={{ flex: '0 0 75px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            disabled={disableMeridiemSelector}
            value={selectedMeridiem}
            onChange={handleMeridiemChange}
            sx={{
              borderLeft: `1px solid ${themeColors.GreyMiddle}`,
              boxSizing: 'border-box',
              '& div.MuiInputBase-root.MuiInput-root': {
                borderBottomLeftRadius: '0px !important',
                borderTopLeftRadius: '0px !important',
              },
            }}
            variant="standard"
            size="small"
            error={error}
            SelectProps={{ ...getSelectProps }}
            options={meridiemOptions}
            name="meridiem"
          />
        </Box>
      </Box>
      {error && helperText && (
        <Typography variant="captionSmall" color="RedDark">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
