import { useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

import { CompanyEndpoints } from '@/api-client/company.api';
// import { GlobalContext } from '@/GlobalState';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { CompanySettingsCustomisationDetailsDrawer } from '@/v2/feature/company/company-settings/features/components/company-settings/customisation-details/company-settings-customisation-details-drawer.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const CompanyWallpaperPage = () => {
  const { data: generalSettings, isValidating: loadingSettings, mutate: refreshSettings } = useApiClient(
    CompanyEndpoints.getGeneralSettings(),
    {
      suspense: false,
    }
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  // const [state] = useContext(GlobalContext);
  // const { user } = state;
  const { polyglot } = usePolyglot();

  return (
    <ContentWrapper loading={loadingSettings}>
      <SettingsSectionContent
        title={polyglot.t('SettingsRouterPageConfig.wallpaper')}
        onEdit={() => {
          // OLD RESTRICTION
          // if (user?.restrictions?.PEOPLE?.disableDocTemplateCreation) setUpgradeModalOpen(true);
          // else
          setEditMode(true);
        }}
      >
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('CompanySettingsCustomisationDetails.companyWallpaper'),
                  value: generalSettings?.wallpaper
                    ? polyglot.t('CompanySettingsCustomisationDetails.wallpaper')
                    : polyglot.t('CompanySettingsCustomisationDetails.uploadYourOwn'),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      {generalSettings && (
        <CompanySettingsCustomisationDetailsDrawer
          setEditMode={setEditMode}
          editMode={editMode}
          generalSettings={generalSettings}
          refreshSettings={refreshSettings}
        />
      )}
    </ContentWrapper>
  );
};
