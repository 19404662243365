import { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { SiteAPI } from '@/api-client/site.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DevicePossessionType, ReturnDeviceReason } from '@/v2/feature/device/device.interface';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { formatAddress } from '@/v2/util/user-data.util';

export const ChangeOwnerCompanyInventoryForm = ({
  devicePossession,
  closePage,
}: {
  devicePossession: DevicePossessionDto;
  closePage: () => Promise<void>;
}) => {
  const [senderAddress, setSenderAddress] = useState<string>('');
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<number | null>(null);
  const [siteAddress, setSiteAddress] = useState<string>('');
  const [state, dispatch] = useContext(GlobalContext);
  const [siteError, setSiteError] = useState<boolean>(false);
  const [siteAddressError, setSiteAddressError] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const initiateDeviceReturn = useCallback(async () => {
    let isReturnInitiated = false;
    if (!siteId) {
      setSiteError(true);
      return;
    }
    if (!siteAddress) {
      setSiteAddressError(true);
      return;
    }
    try {
      setIsSubmitting(true);
      await DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        ReturnDeviceReason.ReturnToCompanySite,
        senderAddress,
        siteAddress,
        siteId,
        undefined
      );

      showMessage('Successfully requested a return', 'success');

      isReturnInitiated = true;

      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await closePage();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not initiate return device flow. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [siteId, siteAddress, devicePossession.id, senderAddress, showMessage, state.user.userId, dispatch, closePage]);

  const fetchUserAddress = useCallback(
    async (assignedUserId: number): Promise<void> => {
      try {
        const userAddress = await UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            return { value: site.id, label: site.name, address: site.address ?? '' };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  return (
    <Box sx={drawerContentSx}>
      <SelectComponent
        name="siteId"
        label="Site"
        options={sitesOptions}
        value={siteId}
        compareValue={siteId}
        onChange={async (e) => {
          const siteId = e.target.value;
          const siteAddress = sitesOptions.find((s) => s.value === siteId)?.address ?? '';
          setSiteId(siteId);
          setSiteAddress(siteAddress);

          setSiteError(false);
          if (siteAddress) setSiteAddressError(false);
        }}
        error={siteError}
        helperText={siteError && 'Site is a required field'}
      />

      <TextfieldComponent
        name="siteAddress"
        label="Site address"
        value={siteAddress}
        onChange={(e) => {
          setSiteAddress(e.target.value);
          setSiteAddressError(false);
        }}
        error={siteAddressError}
        helperText={siteAddressError && 'Site address is a required field'}
      />

      <Typography variant="caption">
        This device will be moved to company inventory. Please ask the current owner to remove all personal files. You
        can initiate a wipe of this device later.
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          name="Save"
          colorVariant="primary"
          sizeVariant="medium"
          fullWidth
          loading={isSubmitting}
          onClick={async () => await initiateDeviceReturn()}
        />
      </Box>
    </Box>
  );
};
