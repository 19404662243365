import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { CompanyDepartmentAPI } from '@/api-client/index.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CompanyDepartmentWithMemberDetailsDto } from '@/models/company-department.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DepartmentFormModal } from '@/v2/feature/department/department-settings/components/department-form-modal.component';
import { DepartmentListingTable } from '@/v2/feature/department/department-settings/components/department-listing-table.component';
import { DepartmentViewModal } from '@/v2/feature/department/department-settings/components/department-view-modal.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DepartmentSettingsPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState<string>('');
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [selectedDepartment, setSelectedDepartment] = useState<CompanyDepartmentWithMemberDetailsDto>();
  const [departments, setDepartments] = useState<CompanyDepartmentWithMemberDetailsDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const refreshDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const departments = await CompanyDepartmentAPI.getCompanyDepartmentsWithMemberDetails();
      setDepartments(departments);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDepartments = useMemo(() => {
    return searchInput.length > 0
      ? departments.filter((user) => user.name.toLowerCase().includes(searchInput.toLowerCase()))
      : departments;
  }, [departments, searchInput]);

  useEffect(() => {
    trackPage('Company department settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Departments</Typography>} />
      <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </Box>

          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsFormOpen(true)}>
            {polyglot.t('DepartmentSettingsPage.newDepartment')}
          </ButtonComponent>
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          <DepartmentListingTable
            departments={filteredDepartments}
            loading={loading}
            setIsViewOpen={setIsViewOpen}
            setIsFormOpen={setIsFormOpen}
            setSelectedDepartment={setSelectedDepartment}
          />

          <DepartmentFormModal
            isOpen={isFormOpen}
            setIsOpen={setIsFormOpen}
            selectedDepartment={selectedDepartment}
            refreshDepartments={refreshDepartments}
            closePage={() => {
              setIsViewOpen(false);
              setIsFormOpen(false);
              setTimeout(() => setSelectedDepartment(undefined), 500);
            }}
          />

          <DepartmentViewModal
            isOpen={isViewOpen}
            setIsOpen={setIsViewOpen}
            setIsFormOpen={setIsFormOpen}
            selectedDepartment={selectedDepartment}
            refreshDepartments={refreshDepartments}
            closePage={() => {
              setIsViewOpen(false);
              setTimeout(() => setSelectedDepartment(undefined), 500);
            }}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
