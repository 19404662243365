import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  INVOICES_COMPANY_EDIT_INVOICE_ROUTE,
  INVOICES_COMPANY_NEW_INVOICE_ROUTE,
  INVOICES_COMPANY_OVERVIEW_ROUTE,
  INVOICES_COMPANY_ROUTE,
} from '@/lib/routes';
import { InvoicesPage } from '@/v2/feature/payments/pages/invoice.page';
import { NewInvoicePage } from '@/v2/feature/payments/pages/new-invoice.page';

export const InvoiceCompanyRouter = () => {
  return (
    <Switch>
      <RouteScopesControl scopes={['invoices:all']} path={INVOICES_COMPANY_OVERVIEW_ROUTE} exact>
        <InvoicesPage reach="company" />
      </RouteScopesControl>
      <RouteScopesControl scopes={['invoices:all']} path={INVOICES_COMPANY_NEW_INVOICE_ROUTE} exact>
        <NewInvoicePage />
      </RouteScopesControl>

      <RouteScopesControl scopes={['invoices:all']} path={INVOICES_COMPANY_EDIT_INVOICE_ROUTE} exact>
        <NewInvoicePage reach="company" editMode={true} />
      </RouteScopesControl>

      <Redirect to={INVOICES_COMPANY_OVERVIEW_ROUTE} from={INVOICES_COMPANY_ROUTE} />
    </Switch>
  );
};
