import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';

import { GooglePlacesKey } from '@/api-client/google-places.api';
import useMessage from '@/hooks/notification.hook';
import { StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceDetailsResult {
  formatted_address: string;
  geometry: { location: { lat: () => number; lng: () => number } };
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

interface GoogleMapsAutocompleteProps {
  readonly label: ReactNode;
  readonly value: string;
  readonly fullWidth?: boolean;
  readonly onChange: (address: string, latitude: number | null, longitude: number | null) => void;
  readonly apiKey: string;
}

export const GoogleMapsAutocomplete = ({ label, value, fullWidth, onChange, apiKey }: GoogleMapsAutocompleteProps) => {
  const [place, setPlace] = useState<PlaceType | null>(
    value
      ? {
          description: value,
          place_id: '',
          structured_formatting: { main_text: '', secondary_text: '' },
        }
      : null
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlace = useMemo(
    () => (request: { placeId: string }, callback: (result?: PlaceDetailsResult) => void) => {
      (placesService.current as any).getDetails(request, callback);
    },
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(place ? [place] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (place) {
          newOptions = [place];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [place, inputValue, fetch]);

  useEffect(() => {
    let active = true;

    if (!placesService.current && (window as any).google) {
      const map = new (window as any).google.maps.Map(document.getElementById('google-maps'));

      placesService.current = new (window as any).google.maps.places.PlacesService(map);
    }
    if (!placesService.current) {
      return undefined;
    }

    if (!place?.place_id) {
      return undefined;
    }

    fetchPlace({ placeId: place.place_id }, (result?: PlaceDetailsResult) => {
      if (active && result) {
        const fullAddress = result?.formatted_address ?? '';
        const longitude = result?.geometry?.location?.lng() ?? null;
        const latitude = result?.geometry?.location?.lat() ?? null;

        onChange(fullAddress ?? '', latitude, longitude);
      }
    });

    return () => {
      active = false;
    };
  }, [fetchPlace, place, fetch, onChange]);

  return (
    <Autocomplete
      id="google-map-demo"
      freeSolo
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={place}
      noOptionsText="No locations"
      onChange={(event: any, newValue: string | PlaceType | null) => {
        setOptions(newValue ? [newValue as PlaceType, ...options] : options);
        setPlace(newValue as PlaceType | null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <StyledAutoTextfield
          variant="standard"
          {...params}
          placeholder={typeof label === 'string' ? label : undefined}
          size="small"
          InputLabelProps={{ shrink: true }}
          label={label}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

interface GooglePlacesWrapperProps {
  readonly label: ReactNode;
  readonly value: string;
  readonly fullWidth?: boolean;
  readonly onChange: (address: string, latitude: number | null, longitude: number | null) => void;
}

export const GooglePlacesWrapper = ({ label, value, fullWidth, onChange }: GooglePlacesWrapperProps) => {
  const [key, setKey] = useState<string | null>(null);

  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        const apiKey = await GooglePlacesKey.getPlacesAPIKey();
        setKey(apiKey);
      } catch (error) {
        showMessage('Could not load map finder.', 'error');
      }
    })();
  }, [showMessage]);

  return key ? (
    <GoogleMapsAutocomplete apiKey={key} label={label} value={value} fullWidth={fullWidth} onChange={onChange} />
  ) : null;
};
