import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortBoolean, sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { NumericYearSelectChip } from '@v2/feature/absence/me/policies/components/year-select-chip.component';
import { HolidayDrawer } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-drawer.component';
import { HolidayDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';

interface TableProps {
  readonly holidays: HolidayDto[];
  readonly calendarId: string;
  readonly refresh: () => Promise<void>;
}

export const HolidaysTable = ({ calendarId, holidays, refresh }: TableProps) => {
  const { polyglot } = usePolyglot();
  const [selectedHoliday, setSelectedHoliday] = useState<HolidayDto | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const [year, setYear] = useState<number>(new Date().getFullYear());

  const filteredAndSortedHolidays = useMemo(() => {
    const filteredByYear = holidays.filter(
      (h) => h.isRecurring || new LocalDate(h.date).getDate().getFullYear() === year
    );

    const search = searchInput ? searchInput.toLowerCase() : null;
    const filteredBySearch = search
      ? filteredByYear.filter((c) => c.name.toLowerCase().includes(search))
      : filteredByYear;

    filteredBySearch.sort((a, b) => (a.date.slice(5) > b.date.slice(5) ? 1 : -1));

    return filteredBySearch;
  }, [year, holidays, searchInput]);

  const columns = useMemo<ColumnDef<HolidayDto, HolidayDto>[]>(() => {
    return [
      {
        header: () => polyglot.t('HolidayCalendarModule.holidayName'),
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.name}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('General.date'),
        accessorFn: (row) => row,
        id: 'date',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => `${year}-${item.date.slice(5)}`),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">
              {new LocalDate(original.date).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
              })}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('HolidayCalendarModule.occurs'),
        accessorFn: (row) => row,
        id: 'isRecurring',
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isRecurring),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">
              {original.isRecurring
                ? polyglot.t('HolidayCalendarModule.everyYear')
                : polyglot.t('HolidayCalendarModule.once')}
            </Typography>
          </Box>
        ),
      },
    ];
  }, [year, polyglot]);

  const handleRowClick = useCallback((row: Row<HolidayDto>) => {
    setSelectedHoliday(row.original);
    setIsOpen(true);
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: '5px' }}>
        <NumericYearSelectChip
          year={year}
          setYear={setYear}
          options={[
            { label: '2022', year: 2022 },
            { label: '2023', year: 2023 },
            { label: '2024', year: 2024 },
            { label: '2025', year: 2025 },
            { label: '2026', year: 2026 },
          ]}
        />

        <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
      </Box>

      {holidays.length > 0 ? (
        <Box sx={{ mt: '24px' }}>
          <BasicTable<HolidayDto> rowData={filteredAndSortedHolidays} columnData={columns} rowClick={handleRowClick} />

          <HolidayDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            calendarId={calendarId}
            holiday={selectedHoliday}
            refresh={refresh}
            onClose={() => setSelectedHoliday(null)}
          />
        </Box>
      ) : (
        <Box>
          <Typography variant="caption" color="Grey" sx={{ mt: '8px' }}>
            {polyglot.t('HolidayCalendarModule.noHolidays')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
