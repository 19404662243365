import { useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Dropzone, { IFileWithMeta, ILayoutProps, ISubmitButtonProps } from 'react-dropzone-uploader';

import { FILE_UPLOAD_ENDPOINT } from '@/api-client/routes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Warning } from '@/images/side-bar-icons/Warning.svg';
import { ZeltDocument, ZeltDocumentType, ZeltDocumentUser } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { UploadState, useBulkUpload } from '@/v2/feature/documents/bulk-upload-documents.context';
import { DocumentBulkUploadActionsFormDrawer } from '@/v2/feature/documents/components/document-bulk-upload-actions-form-modal.component';
import { DocumentBulkUploadResultTable } from '@/v2/feature/documents/components/document-bulk-upload-table-result.component';
import { DocumentFormModal } from '@/v2/feature/documents/components/document-form-modal.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import {
  BulkUploadDocumentActionsMode,
  BulkUploadResult,
  ConfirmBulkDrawerActionProps,
  DocumentUploadStatus,
} from '@/v2/feature/documents/documents.interface';
import {
  DocumentType,
  initialiseState,
  OTHER_TYPE,
  reduceDocumentFormModal,
} from '@/v2/feature/documents/documents.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { borders } from '@/v2/styles/borders.styles';
import { neutralColors, themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

interface BulkFileUploadInputProps<T> {
  readonly onComplete?: (success: number) => void;
  readonly finishBulkUploadSession?: () => void;
  readonly label?: string;
  readonly maxFiles?: number;
  readonly maxSizeBytes?: number;
  readonly value?: File;
  readonly onChange: (response?: T, file?: File) => void;
  readonly endpoint?: string;
  readonly cardStyle?: SxProps<Theme>;
  readonly icon?: JSX.Element;
  readonly disabled?: boolean;
  readonly skipUpload?: boolean;
  readonly displayText?: string;
  _existingFiles?: File[];
  sx?: SxProps<Theme>;
}

interface AdditionalSubmitButtonProps {
  filesStillPendingMapping: number;
  allFilesUploaded: boolean;
  someFilesFailed: boolean;
  finishBulkUploadSession: (() => void) | undefined;
  documentsSelected: boolean;
  setBulkActionDrawerOpen?: (open: boolean) => void;
  setBulkActionDrawerMode?: (mode: BulkUploadDocumentActionsMode) => void;
  loading: boolean;
}

const initialState = initialiseState([]);

// create a mapper function to map IFileWithMeta to ZeltDocument
const mapBulkFilesToZeltDocuments = (
  documents: UploadState[],
  companyId: number,
  uploadedBy: number,
  editHandler: (document: ZeltDocument) => void
): ZeltDocument[] => {
  const createdAt = new LocalDate().toDateString();
  return documents.map((d) => {
    return {
      id: 0,
      fileName: d.file.meta.name,
      fileUuid: d.file.meta.id,
      name: d.file.meta.name,
      size: d.file.meta.size,
      type: d.documentType?.value ?? OTHER_TYPE?.value,
      belongsTo: d.assignee,
      fileUrl: '',
      canAllEmployeesSee: false,
      companyId,
      isExternalResource: false,
      programmaticRecordId: null,
      programmaticRecordType: null,
      createdAt,
      updatedAt: createdAt,
      uploadedBy,
      insurancePolicyId: null,
      visibilitySettings: d?.visibilitySettings,
      expirySettings: d?.expirySettings,
      note: d.note,
      dzProps: {
        file: d.file.file,
        meta: d.file.meta,
        remove: d.file.remove,
        edit: editHandler,
        state: d.state,
      },
    };
  });
};

// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({
  input,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  submitButton,
  dropzoneProps,
  files,
}: ILayoutProps) => {
  const { polyglot } = usePolyglot();

  const MAX_FILES = 50;

  return (
    <Box sx={{ width: '100%', mt: spacing.mt20 }}>
      <Stack>
        {files.length <= MAX_FILES && (
          <>
            <Box {...dropzoneProps}>{files.length <= 0 && input}</Box>
          </>
        )}
        {files.length > MAX_FILES && (
          <Box sx={{ display: 'flex', ...spacing.mt20, alignItems: 'center', gap: spacing.g10 }}>
            <Box>
              <Warning width={16} height={16} style={{ fill: themeColors.orange }} />
            </Box>
            <Typography sx={{ ...themeFonts.caption }}>
              {polyglot.t('BulkFileUploadInput.warningMessageTooManyFiles', { filesLength: files.length })}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const CustomSubmitButton = ({
  onSubmit,
  disabled,
  filesStillPendingMapping,
  allFilesUploaded,
  someFilesFailed,
  finishBulkUploadSession,
  documentsSelected,
  setBulkActionDrawerOpen,
  setBulkActionDrawerMode,
  loading,
}: ISubmitButtonProps & AdditionalSubmitButtonProps) => {
  const { polyglot } = usePolyglot();

  const bulkActionsForDocument = useMemo(() => {
    const optionsAvailable = [];
    if (setBulkActionDrawerOpen && setBulkActionDrawerMode) {
      const bulkActionHandler = (mode: BulkUploadDocumentActionsMode) => {
        setBulkActionDrawerMode(mode);
        setBulkActionDrawerOpen(true);
      };
      optionsAvailable.push({
        handler: () => bulkActionHandler(BulkUploadDocumentActionsMode.DELETE),
        label: polyglot.t('BulkFileUploadInput.buttonText.bulkDelete'),
        disabled: false,
      });
      optionsAvailable.push({
        handler: () => bulkActionHandler(BulkUploadDocumentActionsMode.ASSIGN_OWNER),
        label: polyglot.t('BulkFileUploadInput.buttonText.bulkAssignOwner'),
        disabled: false,
      });
      optionsAvailable.push({
        handler: () => bulkActionHandler(BulkUploadDocumentActionsMode.ASSIGN_TYPE),
        label: polyglot.t('BulkFileUploadInput.buttonText.bulkAssignType'),
        disabled: false,
      });
    }

    return optionsAvailable;
  }, [setBulkActionDrawerOpen, setBulkActionDrawerMode, polyglot]);

  return !documentsSelected ? (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '30%' }}>
        <LoaderButton
          loading={loading}
          sizeVariant="large"
          colorVariant="primary"
          fullWidth
          onClick={() => {
            if (!allFilesUploaded || someFilesFailed) onSubmit([]);
            if (allFilesUploaded && finishBulkUploadSession) finishBulkUploadSession();
          }}
          disabled={disabled || filesStillPendingMapping > 0}
        >
          {filesStillPendingMapping > 0
            ? polyglot.t('BulkFileUploadInput.buttonText.mapPendingDocs', {
                documentCount: filesStillPendingMapping,
              })
            : filesStillPendingMapping === 0 && !allFilesUploaded && !someFilesFailed
            ? polyglot.t('BulkFileUploadInput.buttonText.continue')
            : allFilesUploaded
            ? polyglot.t('BulkFileUploadInput.buttonText.done')
            : someFilesFailed
            ? polyglot.t('BulkFileUploadInput.buttonText.retry')
            : ''}
        </LoaderButton>
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '30%' }}>
        <StyledMenuComponent
          options={bulkActionsForDocument}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'medium',
            title: polyglot.t('BulkFileUploadInput.buttonText.bulkActions'),
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />
      </Box>
    </Box>
  );
};

export function BulkFileUploadInput<T>({
  onComplete,
  finishBulkUploadSession,
  maxFiles = 50,
  maxSizeBytes = 10485760,
  value,
  onChange,
  endpoint = FILE_UPLOAD_ENDPOINT,
  disabled = false,
  skipUpload,
  displayText,
  _existingFiles,
  sx,
}: BulkFileUploadInputProps<T>): JSX.Element {
  const { polyglot } = usePolyglot();

  // add type defs to function props to get TS support inside function bodies,
  // and not just where functions are passed as props into Dropzone
  const [resetKey, setResetKey] = useState(1);
  const [drag, setDrag] = useState(false);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { state: bulkUploadState, dispatch: bulkUploadDispatch } = useBulkUpload();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadResult, setUploadResult] = useState<BulkUploadResult>({
    success: 0,
    error: 0,
    errorDetails: [],
  });

  const currentUser = globalState.user;
  const [_filterValue, setFilterValue] = useState<string>('');
  const [documentTypes, setDocumentTypes] = useState<ZeltDocumentType[]>([]);
  const { hasScopes, getScopesContext } = useScopes();
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [bulkActionDrawerOpen, setBulkActionDrawerOpen] = useState(false);
  const [bulkActionDrawerMode, setBulkActionDrawerMode] = useState<BulkUploadDocumentActionsMode | undefined>();
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers({ refresh: true });
  const allUserOptions = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);
  const currentUserIsDocumentAdmin = hasScopes(
    ['documents:all', 'documents:manager'],
    getScopesContext({ userId: globalState.user?.userId })
  );

  const handleEdit = (document: ZeltDocument) => {
    documentDispatch({ kind: 'bulk_upload_edit_document', value: document, userList: allUserOptions });
    setOpen(true);
    setFilterValue('company');
  };

  // Merge existing files with newly uploaded files (if any)
  // const allFiles: File[] = useMemo(() => {
  //   const newFiles = value ? [value] : [];
  //   return existingFiles ? [...existingFiles, ...newFiles] : [];
  // }, [existingFiles, value]);

  async function readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((reader?.result as string).split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const handleChangeStatus = (file: IFileWithMeta, status: string) => {
    if (status === 'done') {
      onChange(file.xhr?.response ? JSON.parse(file.xhr?.response) : '', file.file);
      setDrag(false);
      bulkUploadDispatch({
        type: 'ADD_DOCUMENT',
        payload: {
          documentId: file.meta.id,
          file: file,
          documentType: OTHER_TYPE,
          assignee: [],
          state: DocumentUploadStatus.PENDING,
        },
      });
    } else if (status === 'removed') {
      onChange();
      setDrag(false);
      bulkUploadDispatch({
        type: 'REMOVE_DOCUMENT',
        payload: file.meta.id,
      });
    } else if (status === 'error_file_size') {
      onChange();
      setResetKey(resetKey + 1);
      setDrag(false);
      showMessage('File cannot be bigger than 10MB; please try uploading a smaller file.', 'error');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const types = await DocumentAPI.listDocumentTypes();
        setDocumentTypes(types);
        documentDispatch({ kind: 'set_types', value: types });
      } catch (error) {
        console.error(`Something bad happened. ${nestErrorMessage(error)}`);
      }
    })();
  }, []);

  useEffect(() => {
    if (!value) {
      setResetKey(resetKey + 1);
    }
    // TODO: this is hotfix!
    // This is to reset the file from Dropzone after submitting, eslint complains
    // eslint-disable-next-line
  }, [value]);

  const allFilesUploaded = useMemo(() => {
    return (
      bulkUploadState?.bulkDocuments?.length > 0 &&
      bulkUploadState?.bulkDocuments?.every((doc) => doc.state === DocumentUploadStatus.UPLOADED)
    );
  }, [bulkUploadState?.bulkDocuments]);

  const someFilesFailed = useMemo(() => {
    return bulkUploadState?.bulkDocuments?.some((doc) => doc.state === DocumentUploadStatus.FAILED);
  }, [bulkUploadState?.bulkDocuments]);

  const filesStillPendingMapping = useMemo(() => {
    return bulkUploadState?.bulkDocuments?.filter((doc) => doc.state === DocumentUploadStatus.PENDING).length;
  }, [bulkUploadState?.bulkDocuments]);

  const filesSelected = useMemo(() => {
    return selectionModel?.length > 0;
  }, [selectionModel]);

  const handleBulkDelete = () => {
    const selectedFiles = bulkUploadState?.bulkDocuments.filter((d) => selectionModel.includes(d.documentId));
    if (selectedFiles) {
      selectedFiles.forEach((f) => f.file.remove());
      showMessage(`Removed ${selectedFiles?.length} document(s).`, 'success');
      setBulkActionDrawerOpen(false);
      setSelectionModel([]);
    }
  };

  const handleBulkAssignOwner = (newOwner: number) => {
    const newOwnerUser = getCachedUserById(newOwner);
    const selectedFiles = bulkUploadState?.bulkDocuments.filter((d) => selectionModel.includes(d.documentId));
    if (selectedFiles) {
      for (const eachFile of selectedFiles) {
        const documentId = eachFile.file.meta.id;
        if (bulkUploadDispatch && documentId) {
          const ownershipList = [
            {
              documentId: 0,
              User: {
                userId: newOwner,
                firstName: newOwnerUser?.firstName,
                lastName: newOwnerUser?.lastName,
                displayName: newOwnerUser?.displayName,
              },
            },
          ] as ZeltDocumentUser[];
          bulkUploadDispatch({
            type: 'UPDATE_DOCUMENT',
            payload: {
              documentId,
              updatedData: {
                assignee: ownershipList,
                state: DocumentUploadStatus.MAPPED,
              },
            },
          });
        }
      }
      showMessage(`Successfully updated owner for ${selectedFiles?.length} document(s).`, 'success');
      setBulkActionDrawerOpen(false);
      setSelectionModel([]);
    }
  };

  const handleBulkAssignType = (newType: DocumentType) => {
    const selectedFiles = bulkUploadState?.bulkDocuments.filter((d) => selectionModel.includes(d.documentId));
    if (selectedFiles) {
      for (const eachFile of selectedFiles) {
        const documentId = eachFile.file.meta.id;
        if (bulkUploadDispatch && documentId) {
          bulkUploadDispatch({
            type: 'UPDATE_DOCUMENT',
            payload: {
              documentId,
              updatedData: {
                documentType: newType,
                state: DocumentUploadStatus.MAPPED,
              },
            },
          });
        }
      }
      showMessage(`Successfully updated type for ${selectedFiles?.length} document(s).`, 'success');
      setBulkActionDrawerOpen(false);
      setSelectionModel([]);
    }
  };

  const handleBulkActionsDrawerConfirmAction = ({ mode, owner, type }: ConfirmBulkDrawerActionProps) => {
    switch (mode) {
      case 'delete':
        handleBulkDelete();
        break;
      case 'assignType':
        if (type) handleBulkAssignType(type);
        break;
      case 'assignOwner':
        if (owner) handleBulkAssignOwner(owner);
        break;
      default:
        break;
    }
  };

  const filesToUpload = useMemo(() => {
    return bulkUploadState?.bulkDocuments.map((d) => d.file);
  }, [bulkUploadState?.bulkDocuments]);

  return (
    <>
      <Dropzone
        maxFiles={maxFiles}
        maxSizeBytes={maxSizeBytes}
        key={resetKey}
        multiple={true}
        getUploadParams={skipUpload ? undefined : () => ({ url: endpoint })}
        onChangeStatus={handleChangeStatus}
        LayoutComponent={(props) => <Layout {...props} />}
        styles={{
          dropzone: {
            border: 'none',
            boxShadow: 'none',
            overflow: 'hidden',
            minHeight: '55px',
          },
          input: { border: 'none', boxShadow: 'none', backgroundColor: neutralColors.n0 },
          inputLabel: { width: 'inherit', position: 'inherit', backgroundColor: neutralColors.n0 },
        }}
        inputContent={
          <Box
            sx={{
              alignItems: 'center',
              minHeight: 55,
              cursor: disabled ? 'not-allowed' : 'pointer',
              width: '100%',
              mt: spacing.mt20,
              ...sx,
            }}
          >
            <Box
              sx={{
                pointerEvents: disabled ? 'inherit' : 'none',
                opacity: disabled ? '0.4' : '1',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                gap: '20px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '50px',
                  border: drag ? borders.dashHover : borders.dash,
                  borderRadius: radius.br10,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pointerEvents: disabled ? 'inherit' : 'none',
                  opacity: disabled ? '0.4' : '1',
                }}
                onDragEnter={() => {
                  setDrag(true);
                }}
                onDragLeave={() => {
                  if (!value) {
                    setDrag(false);
                  }
                }}
              >
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                  {polyglot.t('UploadInput.drop')}
                </Typography>
              </Box>
              {!drag && (
                <Box>
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                    {polyglot.t('UploadInput.or')}
                  </Typography>
                </Box>
              )}
              {!drag && (
                <Box
                  sx={{
                    width: '100%',
                    height: '50px',
                    borderRadius: radius.br10,
                    backgroundColor: themeColors.Background,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: themeColors.TableHover,
                    },
                  }}
                >
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                    {polyglot.t('UploadInput.click')}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box sx={{ mt: spacing.mt20 }}>
              {displayText && (
                <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>{displayText}</Typography>
              )}
            </Box>
          </Box>
        }
      />
      {bulkUploadState?.bulkDocuments.length > 0 && (
        <>
          <Typography sx={{ ...themeFonts.title2, mb: spacing.mb20 }}>
            {polyglot.t('BulkFileUploadInput.layoutMessage')}
          </Typography>
          <DocumentBulkUploadResultTable
            documents={mapBulkFilesToZeltDocuments(
              bulkUploadState?.bulkDocuments,
              currentUser.company.companyId,
              currentUser.userId,
              handleEdit
            )}
            onEditClick={function (document: ZeltDocument): void {
              if (document.dzProps?.edit) document.dzProps.edit(document);
            }}
            onDelete={(file?: IFileWithMeta): void => {
              if (!file) return;
              file.remove();
            }}
            documentTypes={documentTypes}
            uploadResult={uploadResult}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
          <CustomSubmitButton
            filesStillPendingMapping={filesStillPendingMapping}
            allFilesUploaded={allFilesUploaded}
            someFilesFailed={someFilesFailed}
            finishBulkUploadSession={finishBulkUploadSession}
            documentsSelected={filesSelected}
            setBulkActionDrawerOpen={setBulkActionDrawerOpen}
            setBulkActionDrawerMode={setBulkActionDrawerMode}
            loading={loading}
            disabled={bulkUploadState?.bulkDocuments?.length > 50 || loading}
            files={filesToUpload}
            // had to move onSubmitHandler in line as typescript was not recognizing the outside handler
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onSubmit={async (files: IFileWithMeta[]) => {
              let result: BulkUploadResult = { ...uploadResult };
              const filesToProcess = bulkUploadState?.bulkDocuments?.filter((d) =>
                ['mapped', 'failed'].includes(d.state)
              );
              for (const eachFile of filesToProcess) {
                setLoading(true);
                bulkUploadDispatch({
                  type: 'UPDATE_DOCUMENT',
                  payload: {
                    documentId: eachFile.file.meta.id,
                    updatedData: { state: DocumentUploadStatus.PROCESSING },
                  },
                });
                try {
                  const mapping = bulkUploadState?.bulkDocuments?.find((d) => d.documentId === eachFile.file.meta.id);
                  if (mapping) {
                    const fileData = await readFileAsBase64(eachFile.file?.file);
                    await DocumentAPI.uploadDocument(fileData, mapping);
                  }
                  result.success += 1;
                  setUploadResult((previousUploadResult: BulkUploadResult) => ({ ...previousUploadResult, ...result }));
                  bulkUploadDispatch({
                    type: 'UPDATE_DOCUMENT',
                    payload: {
                      documentId: eachFile.file?.meta.id,
                      updatedData: { state: DocumentUploadStatus.UPLOADED },
                    },
                  });
                } catch (error) {
                  result.error += 1;
                  result.errorDetails.push({
                    documentId: eachFile.file?.meta.id,
                    errorMessage: nestErrorMessage(error),
                  });
                  setUploadResult((previousUploadResult: BulkUploadResult) => ({ ...previousUploadResult, ...result }));
                  bulkUploadDispatch({
                    type: 'UPDATE_DOCUMENT',
                    payload: {
                      documentId: eachFile.file?.meta.id,
                      updatedData: { state: DocumentUploadStatus.FAILED },
                    },
                  });
                }
              }
              setLoading(false);
              onComplete?.(result?.success);
            }}
            extra={{
              active: true,
              reject: true,
              dragged: [],
              accept: '',
              multiple: true,
              minSizeBytes: 1024,
              maxSizeBytes: 50024,
              maxFiles: 50,
            }}
          />
        </>
      )}

      {nonTerminatedCachedUsers && (
        <DocumentFormModal
          mode={'edit'}
          state={documentState}
          currentUserIsAdmin={currentUserIsDocumentAdmin}
          dispatch={documentDispatch}
          newDocumentTypes={documentState.types as ZeltDocumentType[]}
          onSubmit={async () => {}}
          users={nonTerminatedCachedUsers}
          canSelectEveryone={false}
          canChangeOwnership
          setOpen={setOpen}
          open={open}
          setLoading={setLoading}
          getDocuments={async () => {}}
          setFilterValue={setFilterValue}
          filterValue={'personal'}
          bulkUpload={true}
          bulkUploadDispatch={bulkUploadDispatch}
        />
      )}

      {bulkActionDrawerMode && (
        <DocumentBulkUploadActionsFormDrawer
          selectedDocuments={selectionModel}
          confirmAction={handleBulkActionsDrawerConfirmAction}
          mode={bulkActionDrawerMode}
          isOpen={bulkActionDrawerOpen}
          setIsOpen={setBulkActionDrawerOpen}
          documentTypes={documentState.types as ZeltDocumentType[]}
        />
      )}
    </>
  );
}
