import { useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { isPast } from 'date-fns';
import dayjs from 'dayjs';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { Divider } from '@/v2/components/divider.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';
import {
  CompanySubscription,
  currencyOptions,
  DiscountTypeValues,
  PlanDefinition,
  SeatsMethod,
  seatsMethodOptions,
  SubscribedPlanDefinition,
  subscriptionTypeOptions,
  vatSettingOptions,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { toTitleCase } from '@/v2/util/string.util';

export const discountExpired = (discountEndDate?: Date | null) =>
  discountEndDate ? isPast(new Date(discountEndDate)) : false;

interface props {
  companySubscription: CompanySubscription;
  availablePlans: PlanDefinition[];
  onSave?: () => Promise<void>;
  onEditModule?: (moduleToEdit: SubscribedPlanDefinition, updatedSubscription: CompanySubscription) => void;
}

export const SuperAdminBillingCompanySubscriptionEditForm = ({
  companySubscription,
  availablePlans,
  onSave,
  onEditModule,
}: props) => {
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [updatedSubscription, setUpdatedSubscription] = useState<CompanySubscription>(companySubscription);
  const [showMessage] = useMessage();

  const handleSave = useCallback(async () => {
    try {
      setIsSavingChanges(true);

      if (onSave) {
        const { companyId, id } = updatedSubscription;
        await CompanyAPI.updateCompanySubscription(companyId, id, updatedSubscription);
        await onSave();
      }

      setIsSavingChanges(false);

      showMessage('Customer subscription updated successfully', 'success');
    } catch (e) {
      showMessage('Failed to update customer plan', 'error');
      console.error(':::: error ::::', e);
      setIsSavingChanges(false);
    }
  }, [onSave, showMessage, updatedSubscription]);

  const handleModuleAddition = useCallback(
    (planId: number) => {
      const matchingPlan = availablePlans.find((plan: PlanDefinition) => plan.id === planId);

      if (matchingPlan) {
        const planToAdd: SubscribedPlanDefinition = {
          planDefinitionId: planId,
          planName: matchingPlan.name,
          cost: matchingPlan.cost,
          pricePerSeat: matchingPlan.cost,
        };
        setUpdatedSubscription((prev) => ({
          ...prev,
          subscribedPlans: [...prev.subscribedPlans, planToAdd],
        }));
      }
    },
    [availablePlans]
  );

  const newModuleOptions = useMemo(
    () =>
      availablePlans
        .filter(
          (eachOption) =>
            !updatedSubscription.subscribedPlans.some(
              (m) => m.planDefinitionId === eachOption.id || m.planName === eachOption.name
            )
        )
        .map((eachPlan) => {
          return {
            handler: async () => {
              handleModuleAddition(eachPlan.id);
            },
            label: eachPlan.name,
            disabled: false,
          };
        }),
    [availablePlans, handleModuleAddition, updatedSubscription.subscribedPlans]
  );

  const noMoreModuleOption = [
    {
      handler: () => {},
      label: 'No more modules available',
      disabled: true,
    },
  ];

  const discountEndDateDetail = (p: SubscribedPlanDefinition) =>
    p.discountEndDate ? `Valid till ${dateAPItoDisplay(p.discountEndDate)}` : '';

  const numberOfSeatsLabel =
    updatedSubscription.seatsMethod === SeatsMethod.FIXED ? 'Number of seats' : 'Minimum seats';

  const discountColumnDetail = useCallback(
    (p: SubscribedPlanDefinition) => {
      if (p.discountType && p.discountValue && discountExpired(p.discountEndDate)) {
        return `${p.discountEndDate ? 'Discount expired ' + dateAPItoDisplay(p.discountEndDate) : ''}`;
      }
      if (p.discountType && p.discountValue && !discountExpired(p.discountEndDate)) {
        if (p.discountType === DiscountTypeValues.FIXED) {
          return `${formatCurrency(p.discountValue, undefined, updatedSubscription.currency)} ${discountEndDateDetail(
            p
          )}`;
        } else if (p.discountType === DiscountTypeValues.PERCENTAGE) {
          return `${p.discountValue}% ${discountEndDateDetail(p)}`;
        }
      }
      return <EmptyCell />;
    },
    [updatedSubscription.currency]
  );

  const effectivePriceForModule = useCallback(
    (original: SubscribedPlanDefinition) => {
      // if price per seat exists, and discount value exists and discount has not expired - return price per seat
      if (original.pricePerSeat && original.discountValue && !discountExpired(original.discountEndDate))
        return formatCurrency(
          original.pricePerSeat[updatedSubscription.currency],
          undefined,
          updatedSubscription.currency
        );
      // if no discount value OR (discount value exists and discount has expired) and original cost is there - return cost
      else if (
        (!original.discountValue || (original.discountValue && discountExpired(original.discountEndDate))) &&
        original.cost
      )
        return formatCurrency(original.cost[updatedSubscription.currency], undefined, updatedSubscription.currency);

      return <EmptyCell />;
    },
    [updatedSubscription.currency]
  );

  const moduleColumns = useMemo<ColumnDef<SubscribedPlanDefinition, SubscribedPlanDefinition>[]>(() => {
    return [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.planName}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'List price',
        accessorFn: (row) => row,
        id: 'cost',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">
              {original.cost && updatedSubscription.currency ? (
                formatCurrency(original.cost[updatedSubscription.currency], undefined, updatedSubscription.currency)
              ) : (
                <EmptyCell />
              )}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => 'Discount',
        accessorFn: (row) => row,
        id: 'discount',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{discountColumnDetail(original)}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'Effective price',
        accessorFn: (row) => row,
        id: 'cost',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{effectivePriceForModule(original)}</Typography>
          </Box>
        ),
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        size: 75,
        cell: ({ row: { original } }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                alignItems: 'center',
                gap: spacing.gap5,
              }}
            >
              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  onEditModule!(original, updatedSubscription);
                }}
              >
                <EditIcon {...iconSize} />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [discountColumnDetail, effectivePriceForModule, onEditModule, updatedSubscription]);

  const sortedModules = useMemo(() => {
    return [...updatedSubscription.subscribedPlans].sort((a, b) => a.planDefinitionId - b.planDefinitionId);
  }, [updatedSubscription]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Plan</Typography>

      <DrawerViewerItem title="Company" key="companyName" value={companySubscription?.company?.name ?? ''} />

      <SelectComponent
        name="currency"
        label="Billing currency"
        options={currencyOptions.map((currency) => ({
          label: currency,
          value: currency,
        }))}
        value={updatedSubscription.currency}
        compareValue={updatedSubscription.currency}
        onChange={(e) => {
          const newCurrency = e.target.value;
          setUpdatedSubscription((prev) => ({ ...prev, currency: newCurrency }));
        }}
      />

      <SelectComponent
        name="vatSetting"
        label="VAT setting"
        options={vatSettingOptions.map((vatSetting) => ({
          label: vatSetting,
          value: vatSetting,
        }))}
        value={updatedSubscription.vatSetting}
        compareValue={updatedSubscription.vatSetting}
        onChange={(e) => {
          const newSetting = e.target.value;
          setUpdatedSubscription((prev) => ({ ...prev, vatSetting: newSetting }));
        }}
      />
      <Divider />
      <SelectComponent
        name="type"
        label="Type"
        options={subscriptionTypeOptions.map((typeOption) => ({
          label: toTitleCase(typeOption),
          value: typeOption,
        }))}
        value={updatedSubscription.type}
        compareValue={updatedSubscription.type}
        onChange={(e) => {
          const newType = e.target.value;
          setUpdatedSubscription((prev) => ({ ...prev, type: newType }));
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box width="85%">
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={updatedSubscription.endDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                setUpdatedSubscription((prev) => ({ ...prev, endDate: new Date(value) }));
              }
            }}
            name="endDate"
            label={'End date (Optional)'}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {updatedSubscription.endDate && (
            <ButtonComponent
              sizeVariant="small"
              colorVariant="secondary"
              onClick={() => {
                setUpdatedSubscription((prev) => ({ ...prev, endDate: null }));
              }}
            >
              Clear
            </ButtonComponent>
          )}
        </Box>
      </Box>
      <SelectComponent
        name="seatsMethod"
        label="Seats method"
        options={seatsMethodOptions.map((methodOption) => ({
          label: toTitleCase(methodOption),
          value: methodOption,
        }))}
        value={updatedSubscription.seatsMethod}
        compareValue={updatedSubscription.seatsMethod}
        onChange={(e) => {
          const newMethod = e.target.value;
          setUpdatedSubscription((prev) => ({ ...prev, seatsMethod: newMethod }));
        }}
      />
      <TextfieldComponent
        label={numberOfSeatsLabel}
        name="numberOfSeats"
        value={updatedSubscription.numberOfSeats}
        onChange={(e) => {
          const newSeats = +e.target.value;
          setUpdatedSubscription((prev) => ({ ...prev, numberOfSeats: newSeats }));
        }}
        fullWidth
        size="small"
        endAdornment="none"
      />
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="title4">Modules</Typography>
        <StyledMenuComponent
          options={newModuleOptions?.length > 0 ? newModuleOptions : noMoreModuleOption}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Add new',
            fullWidth: true,
          }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        />
      </Box>
      {updatedSubscription.subscribedPlans?.length > 0 ? (
        <BasicTable<SubscribedPlanDefinition>
          rowData={sortedModules}
          columnData={moduleColumns}
          loading={false}
          hidePagination
        />
      ) : (
        <Typography variant="captionSmall" color="grey">
          No modules added yet
        </Typography>
      )}
      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          name="Save"
          sizeVariant="medium"
          loading={isSavingChanges}
          colorVariant="primary"
          fullWidth
          onClick={handleSave}
        />
      </Box>
    </Box>
  );
};
