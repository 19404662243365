import { useCallback, useState } from 'react';

import { Typography } from '@v2/components/typography/typography.component';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { EditCustomBenefitApprovalDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-approval-drawer.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import {
  CustomBenefitDto,
  CustomBenefitPaymentMethodType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface PageProps {
  readonly customBenefit: CustomBenefitDto;
  readonly refreshBenefit: () => Promise<void>;
}

export const CustomBenefitSettingsApprovalPage = ({ customBenefit, refreshBenefit }: PageProps) => {
  const { polyglot } = usePolyglot();
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(), {
    suspense: false,
  });

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const approvalRule = approvalRules?.find((rule) => rule.id === customBenefit.approvalRuleId);

  const refreshApprovalRules = useCallback(async () => {
    if (refreshRules) await refreshRules();
  }, [refreshRules]);

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('BenefitModule.approvalRule')}
      onEdit={() => {
        setIsEditOpen(true);
      }}
    >
      <DescriptionLine field={polyglot.t('BenefitModule.approval')} value={approvalRule?.name} />

      {customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Expenses && (
        <Typography variant="caption">
          This gets overwritten by the expense approval rule when payment method is "Expenses".
        </Typography>
      )}
      {isEditOpen && (
        <EditCustomBenefitApprovalDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          customBenefit={customBenefit}
          refresh={refreshBenefit}
          approvalRules={approvalRules ?? []}
          refreshApprovalRules={refreshApprovalRules}
        />
      )}
    </BenefitsSettingsPageSection>
  );
};
