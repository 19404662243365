import { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { EmptyStateBox } from '@v2/components/empty-state-box.component';
import { capitalize } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { useEventSource } from '@/hooks/event-source-hook';
import { REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE, REVIEWS_TEAM_ONGOING_DETAIL_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { RCCreationModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/components/rc-creation-modal.component';
import { getStatus } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleTypeArray } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { PushEvent } from '@/v2/infrastructure/push-events/push-events.interface';
import { eventSourceErrorHandler } from '@/v2/infrastructure/push-events/push-events.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const ReviewCycleOngoingListingPage = ({ reach }: { reach: ReachType }) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');

  const {
    data: ongoingCycles,
    mutate: refreshOngoingCycles,
    isLoading: loadingCycles,
    isValidating: validatingCycles,
  } = useApiClient(ReviewCycleEndpoints.getOngoingCycles(reach, searchInput, filterString), {
    suspense: false,
  });
  const [state] = useContext(GlobalContext);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const environmentPrefix = `${isProduction ? 'prod' : 'test'}`;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const channelName = `review_cycle-${environmentPrefix}-company_id-${companyId}`;
  const reviewCycleUpdatedDataHandler = (data: PushEvent) => {
    if (data.message && data.companyId === companyId) {
      refreshOngoingCycles?.();
      return;
    }
  };

  useEventSource(ReviewCycleEndpoints.getEventSourceUrlForReviewCycleLaunch(channelName)?.url, {
    onMessage: reviewCycleUpdatedDataHandler,
    onError: eventSourceErrorHandler,
  });

  useEventSource(ReviewCycleEndpoints.getEventSourceUrlForReviewCycleFinalise(channelName)?.url, {
    onMessage: reviewCycleUpdatedDataHandler,
    onError: eventSourceErrorHandler,
  });

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Reviews</Typography>}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsDrawerOpen(true)}>
            Start review
          </ButtonComponent>
        }
        showAction={Boolean(ongoingCycles && ongoingCycles.length > 0)}
      />
      <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
        {!loadingCycles &&
        (!ongoingCycles || (ongoingCycles && ongoingCycles.length === 0)) &&
        !searchInput &&
        !filterString ? (
          <EmptyStateBox
            onClick={() => setIsDrawerOpen(true)}
            title="Create first review"
            subtitle="Ongoing review cycles will be shown on this page once you’ve started. You can begin a new review by using a template or by creating your own questions."
            buttonTitle="Start review"
          />
        ) : (
          <ReviewCycleOngoingTable
            ongoingCycles={ongoingCycles ?? []}
            validatingCycles={validatingCycles}
            reach={reach}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            filterString={filterString}
            setFilterString={setFilterString}
          />
        )}

        <RCCreationModal
          reviewCycle={undefined}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          reach={reach}
          onClose={() => setIsDrawerOpen(false)}
          refresh={async () => {
            await refreshOngoingCycles?.();
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};

const ReviewCycleOngoingTable = ({
  ongoingCycles,
  validatingCycles,
  reach,
  searchInput,
  setSearchInput,
  filterString,
  setFilterString,
}: {
  ongoingCycles: ReviewCycle[] | undefined;
  validatingCycles: boolean;
  reach: ReachType;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  filterString: string;
  setFilterString: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const routerHistory = useHistory();
  const tableColumns = useMemo<ColumnDef<ReviewCycle, ReviewCycle>[]>(
    () => [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'internalName',
        enableSorting: false,

        cell: ({
          row: {
            original: { internalName },
          },
        }) => (internalName ? <div>{internalName} </div> : <EmptyCell />),
        minSize: 180,
        maxSize: 350,
      },
      {
        header: () => 'Type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,

        cell: ({
          row: {
            original: { type },
          },
        }) => (type ? capitalize(type) : <EmptyCell />),
        minSize: 90,
        maxSize: 120,
      },
      {
        header: () => 'Created by',
        accessorFn: (row) => row,
        id: 'createdBy',
        enableSorting: false,

        cell: ({
          row: {
            original: { owner },
          },
        }) => <div>{owner ? <UserCell userId={owner} /> : <EmptyCell />}</div>,
        minSize: 90,
        maxSize: 120,
      },
      {
        header: () => 'Start date',
        accessorFn: (row) => row,
        id: 'timelineSettings.startDate',
        enableSorting: false,
        minSize: 50,
        maxSize: 120,

        cell: ({
          row: {
            original: {
              timelineSettings: { startDate },
            },
          },
        }) =>
          startDate ? (
            <div>
              {new LocalDate(startDate)
                .getDate()
                .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
            </div>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'state',
        enableSorting: false,

        cell: ({
          row: {
            original: { state },
          },
        }) => (state ? getStatus()[state] : <EmptyCell />),
        minSize: 90,
        maxSize: 120,
      },
    ],
    []
  );

  const handleRowClick = useCallback(
    (row: Row<ReviewCycle>) => {
      if (!(row.original.state === CycleState.Launching || row.original.state === CycleState.Finalising)) {
        const reachRoute =
          reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE : REVIEWS_TEAM_ONGOING_DETAIL_ROUTE;
        routerHistory.push(
          generatePath(reachRoute, {
            cycleId: row.original.id,
          })
        );
      }
    },
    [routerHistory, reach]
  );

  const customRowStyle = (row: Row<ReviewCycle>) => {
    if (row.original.state === CycleState.Launching)
      return { color: themeColors.Grey, background: themeColors.tableHoverGrey };
    return {};
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
        <CategoryFilters
          filterTypes={{
            type: CycleTypeArray.map((d) => {
              return { value: d, label: capitalize(d) };
            }),
          }}
          setFilterString={setFilterString}
          filterString={filterString}
        />
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
      </Box>
      <BasicTable<ReviewCycle>
        rowData={ongoingCycles ?? []}
        columnData={tableColumns}
        loading={validatingCycles}
        rowClick={handleRowClick}
        customRowStyle={customRowStyle}
      />
    </Box>
  );
};
