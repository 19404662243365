import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { getModelImage } from '@v2/feature/device/device.util';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TECH_ROUTE } from '@/lib/routes';

interface DeleteDeviceDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
  readonly onDelete: () => Promise<void>;
}

export const DeleteDeviceDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  onDelete,
}: DeleteDeviceDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <DeleteDeviceDrawerContent devicePossession={devicePossession} onDelete={onDelete} />
    </DrawerModal>
  );
};

interface DeleteDeviceDrawerContentProps {
  readonly devicePossession: DevicePossessionDto;
  readonly onDelete: () => Promise<void>;
}

const DeleteDeviceDrawerContent = ({ devicePossession, onDelete }: DeleteDeviceDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const history = useHistory();

  const deleteDevice = async (): Promise<void> => {
    try {
      setLoading(true);
      await DeviceAPI.deleteCompanyOwnedDeviceByDevicePossessionId(devicePossession.id);
      showMessage(`Device deleted successfully`, 'success');
      await onDelete();
      history.push(TECH_ROUTE);
    } catch (error) {
      showMessage(`Could not delete device. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography sx={titleSx}>Delete device</Typography>

      {devicePossession.device && (
        <Box sx={{ mb: 2 }}>
          <Box sx={{ mb: 1 }}>
            {getModelImage(devicePossession.device.type, devicePossession.device.modelName, {
              width: '200px',
              height: 'auto',
            })}
          </Box>
          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
            Model Name: {devicePossession.device.modelName}
          </Typography>
          {devicePossession.device.modelNumber && (
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              Model Number: {devicePossession.device.modelNumber}
            </Typography>
          )}
          {devicePossession.device.serialNumber && (
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              Serial Number: {devicePossession.device.serialNumber}
            </Typography>
          )}
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 2 }}>
        <LoaderButton
          name="Delete device"
          loading={loading}
          onClick={deleteDevice}
          fullWidth
          colorVariant="danger"
          sizeVariant="small"
        />
      </Box>
    </>
  );
};
