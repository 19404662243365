import { Box, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { spacing } from '@v2/styles/spacing.styles';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { BasicBankAccountDetails } from '@/v2/feature/payments/payments.dto';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const PayerDetailsStep = ({ onNext }: { onNext: () => void }): JSX.Element => {
  const [, setState] = usePaymentContext();
  const formik = useFormik<BasicBankAccountDetails>({
    initialValues: {
      accountName: '',
      accountNumber: '',
      sortCode: '',
    },
    validationSchema: Yup.object().shape({
      accountName: Yup.string()
        .matches(/^[0-9a-zA-Z ]*$/, 'Please avoid any special character')
        .required('Account Name is required'),
      accountNumber: Yup.string()
        .matches(/^[0-9a-zA-Z]*$/, 'Please avoid using space or any special character')
        .required('Account Number is required'),
      sortCode: Yup.string()
        .matches(/^[0-9-]*$/, 'Please use only numeric characters')
        .required('Sort Code is required'),
    }),
    onSubmit: () => goToNextStep(),
  });

  const goToNextStep = () => {
    setState(({ payments, requests, institution }) => {
      return {
        payments,
        requests,
        payerDetails: {
          accountName: formik.values.accountName,
          accountNumber: formik.values.accountNumber,
          sortCode: formik.values.sortCode.replaceAll('-', ''),
        },
        institution,
      };
    });
    onNext();
  };

  return (
    <FormikProvider value={formik}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <Box sx={{ maxWidth: '400px' }}>
          <Typography sx={{ ...themeFonts.title2 }}>Please add payer details</Typography>

          <Box sx={{ ...spacing.mt40 }}>
            <Box sx={fieldSx}>
              <TextfieldComponent
                label="Account Name"
                name="accountName"
                value={formik.values.accountName}
                onChange={formik.handleChange}
                error={formik.touched.accountName && Boolean(formik.errors.accountName)}
                helperText={formik.touched.accountName && formik.errors.accountName}
                type="text"
                endAdornment="none"
                autoFocus
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                label="Account Number"
                name="accountNumber"
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                type="text"
                endAdornment="none"
                autoFocus
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                label="Sort Code"
                name="sortCode"
                fullWidth
                value={formik.values.sortCode}
                onChange={formik.handleChange}
                error={formik.touched.sortCode && Boolean(formik.errors.sortCode)}
                helperText={formik.touched.sortCode && formik.errors.sortCode}
                type="text"
                endAdornment="none"
                autoFocus
              />
            </Box>
          </Box>

          <Box sx={{ ...spacing.mt40 }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              type="submit"
              onClick={() => formik.handleSubmit()}
              fullWidth
            >
              Next
            </ButtonComponent>
          </Box>
        </Box>
      </Box>
    </FormikProvider>
  );
};
