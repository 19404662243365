export const MINUTES_IN_WEEK = 10_080; // 60min * 24h * 7days;

export const HOURS_40_IN_MINUTES = 2400; // 40h * 60min;

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Compares only the time part of 2 dates.
 * @returns 1 if date1 is more than date2, -1 if date2 < date1 and 0 if the same
 */
export function compareTimePartOnly(date1: Date, date2: Date): -1 | 0 | 1 {
  const date1Local = new Date(date1);
  const date2Local = new Date(date2);

  date1Local.setFullYear(0, 0, 0);
  date2Local.setFullYear(0, 0, 0);

  if (date1Local > date2Local) return 1;
  else if (date2Local > date1Local) return -1;

  return 0;
}
