import React from 'react';

import { Box } from '@mui/material';
import { OptionObject } from '@v2/components/forms/select.component';
import { AbsenceDto, AbsencePolicyDto, AbsencePolicyTenureAllocation } from '@v2/feature/absence/absence.dto';
import { AbsencePolicyAllowanceType, HolidaysProratingRule } from '@v2/feature/absence/absence.interface';
import {
  COUNTRY_ISO_CODE_MAPPING,
  CustomCountryEnum,
  NoneCountryEnum,
} from '@v2/infrastructure/country/country.interface';
import {
  translateAbsencePolicyTenureAllocation,
  translateAbsencePolicyTenureAllocationDescription,
} from '@v2/infrastructure/i18n/translate.util';
import { COLOR_PICKER_COLORS } from '@v2/styles/colors.styles';
import { LocalDate } from '@v2/util/local-date';
import Polyglot from 'node-polyglot';

import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import { CustomRuleOptions } from '@/v2/components/user-select-type/user-select.interface';

export const displayAbsenceDay = (dateString: string, showYear = false) =>
  new LocalDate(dateString)?.getDate().toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'short',
    year: showYear ? 'numeric' : undefined,
  }) ?? 'N/A';

export const getPolicyAllowanceTypeLabel = (allowanceType: AbsencePolicyAllowanceType, polyglot: Polyglot): string => {
  switch (allowanceType) {
    case AbsencePolicyAllowanceType.LimitedHourly:
      return polyglot.t('AbsenceUtil.limitedHourly');
    case AbsencePolicyAllowanceType.Limited:
      return polyglot.t('AbsenceUtil.limitedDaily');
    case AbsencePolicyAllowanceType.Unlimited:
      return polyglot.t('AbsenceUtil.unlimitedDaily');
    case AbsencePolicyAllowanceType.UnlimitedHourly:
      return polyglot.t('AbsenceUtil.unlimitedHourly');
    default:
      return polyglot.t('General.unknown');
  }
};

export const getAbsencePolicyTenureAllocationOptions = (polyglot: Polyglot): OptionObject[] => {
  return Object.values(AbsencePolicyTenureAllocation).map((o) => ({
    label: translateAbsencePolicyTenureAllocation(o, polyglot),
    description: translateAbsencePolicyTenureAllocationDescription(o, polyglot),
    value: o,
  }));
};

export const getPolicyHolidaysProratingRuleOption = (
  policy: Pick<AbsencePolicyDto, 'holidaysProratingRule'>,
  polyglot: Polyglot
): OptionObject | undefined => {
  if (policy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)
    return {
      value: HolidaysProratingRule.FullValueProrated,
      label: polyglot.t('AbsenceUtil.proRata'),
      description: polyglot.t('AbsenceUtil.proRataDesc'),
    };

  return getActualPartTimeMethodOptions(false, polyglot).find((o) => o.value === policy.holidaysProratingRule);
};

export const getActualPartTimeMethodOptions = (
  disableProratedOptions: boolean,
  polyglot: Polyglot
): readonly OptionObject[] => {
  return [
    {
      value: HolidaysProratingRule.AllInPeriod,
      label: polyglot.t('AbsenceUtil.publicHolidaysAllInPeriod'),
      description: polyglot.t('AbsenceUtil.publicHolidaysAllInPeriodDesc'),
    },
    {
      value: HolidaysProratingRule.AllInCycle,
      label: polyglot.t('AbsenceUtil.publicHolidaysAllInCycle'),
      description: polyglot.t('AbsenceUtil.publicHolidaysAllInCycleDesc'),
    },
    {
      value: HolidaysProratingRule.AllInPeriodProrated,
      label: polyglot.t('AbsenceUtil.publicHolidaysAllInPeriodProrated'),
      description: polyglot.t('AbsenceUtil.publicHolidaysAllInPeriodProratedDesc'),
      disabled: disableProratedOptions,
    },
  ] as const;
};

export const getAbsenceProratingMethod = (
  policy: Pick<AbsencePolicyDto, 'proratingStartDate' | 'proratingFte'>,
  polyglot: Polyglot
): {
  value: string;
  description: string;
} => {
  if (policy?.proratingStartDate && !policy.proratingFte)
    return {
      value: polyglot.t('AbsenceUtil.prorateByStartDate'),
      description: polyglot.t('AbsenceUtil.prorateByStartDateDesc'),
    };

  if (policy?.proratingStartDate && policy.proratingStartDate)
    return {
      value: polyglot.t('AbsenceUtil.prorateByStartDateAndFTE'),
      description: polyglot.t('AbsenceUtil.prorateByStartDateAndFTEDesc'),
    };

  return { value: polyglot.t('AbsenceUtil.none'), description: '' };
};

export const NoPublicHolidayCalendarOption = { value: NoneCountryEnum.code, label: NoneCountryEnum.name };
export const CustomPublicHolidayCalendarOption = { value: CustomCountryEnum.code, label: CustomCountryEnum.name };

export const getPublicHolidayCalendarOptions = (options?: {
  excludeNone?: boolean;
  includeCustom?: boolean;
}): {
  label: string;
  value: string;
}[] => [
  ...(options?.excludeNone ? [] : [NoPublicHolidayCalendarOption]),
  ...(options?.includeCustom ? [CustomPublicHolidayCalendarOption] : []),
  ...Object.entries(COUNTRY_ISO_CODE_MAPPING).map(([name, code]) => {
    return {
      value: code.toLowerCase(),
      label: name,
    };
  }),
];

const colorWidth = 14;
export const COLOR_OPTIONS = COLOR_PICKER_COLORS.map((color) => ({
  value: color,
  label: (
    <Box
      sx={{
        display: 'flex',
        mt: '5px',
        bgcolor: color,
        width: `${colorWidth}px`,
        height: `${colorWidth}px`,
        borderRadius: `${colorWidth / 2}px`,
      }}
    />
  ),
}));

export const getMonthOptions = (polyglot: Polyglot) => {
  return [
    { value: 1, label: polyglot.t('getMonthOptions.january') },
    { value: 2, label: polyglot.t('getMonthOptions.february') },
    { value: 3, label: polyglot.t('getMonthOptions.march') },
    { value: 4, label: polyglot.t('getMonthOptions.april') },
    { value: 5, label: polyglot.t('getMonthOptions.may') },
    { value: 6, label: polyglot.t('getMonthOptions.june') },
    { value: 7, label: polyglot.t('getMonthOptions.july') },
    { value: 8, label: polyglot.t('getMonthOptions.august') },
    { value: 9, label: polyglot.t('getMonthOptions.september') },
    { value: 10, label: polyglot.t('getMonthOptions.october') },
    { value: 11, label: polyglot.t('getMonthOptions.november') },
    { value: 12, label: polyglot.t('getMonthOptions.december') },
  ];
};

export enum ProratingRule {
  None = 'None',
  StartDate = 'StartDate',
  StartDateAndFtePercent = 'StartDateAndFtePercent',
}

export const getNoProratingOption = (polyglot: Polyglot) => {
  return { value: ProratingRule.None, label: polyglot.t('getNoProratingOption.label') };
};

export const getProratingByStartDateOption = (polyglot: Polyglot) => {
  return { value: ProratingRule.StartDate, label: polyglot.t('getProratingByStartDateOption.label') };
};

export const getProratingByStartDateAndFTEPercentOption = (polyglot: Polyglot) => {
  return {
    value: ProratingRule.StartDateAndFtePercent,
    label: polyglot.t('getProratingByStartDateAndFTEPercentOption.label'),
  };
};

export const getCustomRule = (
  customRule: string | null,
  ruleDataOption: OptionObj[] | undefined,
  polyglot: Polyglot
) => {
  const ruleTypeArray = customRule ? customRule?.split('=') : [];
  if (ruleTypeArray.length === 0 && !ruleDataOption) return;
  const ruleType = ruleTypeArray[0] ?? [];
  const ruleValue = ruleDataOption && ruleDataOption.find((r) => r.value === Number(ruleTypeArray[1]))?.label;

  switch (ruleType) {
    case CustomRuleOptions.Site:
      return polyglot.t('AbsenceUtil.siteRule', { list: ruleValue ?? '' });

    case CustomRuleOptions.Department:
      return polyglot.t('AbsenceUtil.departmentRule', { list: ruleValue ?? '' });

    case CustomRuleOptions.Entity:
      return polyglot.t('AbsenceUtil.entityRule', { list: ruleValue ?? '' });

    default:
      return '';
  }
};

export const getRegularTimeFromScheduleData = (
  startHour: string | undefined,
  endHour: string | undefined,
  polyglot: Polyglot
): string | null => {
  if (startHour && endHour) return `${startHour} - ${endHour}`;
  if (startHour) return convertTimeHMToClockHours(startHour, polyglot);
  return null;
};

export const convertTimeHMToClockHours = (value: string, polyglot: Polyglot) => {
  if (!value) return polyglot.t('AbsenceUtil.clockHours', { sign: '', hours: 0 });
  const [h, m] = value.split(':');

  const hours = Number(h);
  const minutes = Number(m);

  if (minutes === 0) return polyglot.t('AbsenceUtil.clockHours', { sign: '', hours });

  return polyglot.t('AbsenceUtil.clockHoursAndMinutes', { sign: '', hours, minutes });
};

export const convertMinutesToClockHours = (value: number | undefined | null, polyglot: Polyglot | null): string => {
  const minutes = Math.abs(value ?? 0);
  if (!minutes) return '0h';
  const minutesOnly = Math.round(minutes % 60);

  const hours = Math.round((minutes - minutesOnly) / 60);

  const sign = value && value < 0 ? '-' : '';

  if (minutesOnly)
    return !polyglot
      ? `${sign}${hours}h ${minutesOnly}m`
      : polyglot.t('AbsenceUtil.clockHoursAndMinutes', {
          sign,
          hours,
          minutes: minutesOnly,
        });
  return !polyglot ? `${sign}${hours}h` : polyglot.t('AbsenceUtil.clockHours', { sign, hours });
};

export const convertMinutesToHHMM = (totalMinutes: number): string => {
  const sign = totalMinutes < 0 ? '-' : '';
  const absoluteTotalMinutes = Math.abs(totalMinutes);
  const hours = Math.floor(absoluteTotalMinutes / 60);
  const minutes = absoluteTotalMinutes % 60;
  return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const isHourlyPolicy = (policy: Pick<AbsencePolicyDto, 'allowanceType'> | undefined): boolean => {
  return Boolean(
    policy?.allowanceType === AbsencePolicyAllowanceType.LimitedHourly ||
      policy?.allowanceType === AbsencePolicyAllowanceType.UnlimitedHourly
  );
};

export const isUnlimitedPolicy = (policy: Pick<AbsencePolicyDto, 'allowanceType'> | undefined): boolean => {
  return Boolean(
    policy?.allowanceType === AbsencePolicyAllowanceType.UnlimitedHourly ||
      policy?.allowanceType === AbsencePolicyAllowanceType.Unlimited
  );
};

export function getPeriodFromAbsence(absence: AbsenceDto, showHours = true): string {
  const startYear = absence.start.slice(0, 4);
  const endYear = absence.end ? absence.end.slice(0, 4) : startYear;
  const showStartYear = startYear !== endYear || !absence.end;
  const startDate = displayAbsenceDay(absence.start, showStartYear);
  const endDate = absence.end ? displayAbsenceDay(absence.end, true) : null;

  const isAM = !absence.startHourTimestamp ? absence.morningOnly : false;
  const isPM = !absence.startHourTimestamp ? absence.afternoonOnly : false;

  const startDateSuffixEl = isPM ? ' (PM)' : isAM && !absence.end ? ' (AM)' : '';
  const endDateSuffixEl = isAM ? ' (AM)' : '';

  const mainText = endDate
    ? `${startDate}${startDateSuffixEl} - ${endDate}${endDateSuffixEl}`
    : `${startDate}${startDateSuffixEl}`;

  if (!absence.startHourTimestamp || !absence.endHourTimestamp || !showHours) return mainText;

  const startHour = new Date(absence.startHourTimestamp).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });
  const endHour = new Date(absence.endHourTimestamp).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${mainText} (${startHour}-${endHour})`;
}

export function getNextDateFromBEStringDate(dateString: string): string {
  const [y, m, d] = dateString.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  date.setDate(date.getDate() + 1);

  const y1 = date.getFullYear();
  const m1 = (100 + date.getMonth() + 1).toString().slice(-2);
  const d1 = (100 + date.getDate()).toString().slice(-2);
  return `${y1}-${m1}-${d1}`;
}

export function syncStartAndEndHourWithTheSameDate(
  absenceData: Pick<AbsenceDto, 'start' | 'startHour' | 'endHour' | 'startHourTimestamp' | 'endHourTimestamp'>,
  polyglot: Polyglot
): {
  startHour: string;
  endHour: string;
  startHourTimestamp: Date;
  endHourTimestamp: Date;
} {
  if (!absenceData.start || !absenceData.startHour || !absenceData.endHour)
    throw new Error(polyglot.t('AbsenceUtil.syncStartAndEndHourMissingFields'));

  const localDate = new LocalDate(absenceData.start);

  const startHour = `${localDate.toDateString()}${absenceData.startHour.slice(10)}`;
  const endHour = `${localDate.toDateString()}${absenceData.endHour.slice(10)}`;

  const date = localDate.getDate();
  const startHourTimestamp = new Date(absenceData.startHourTimestamp ?? absenceData.startHour);
  startHourTimestamp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

  const endHourTimestamp = new Date(absenceData.endHourTimestamp ?? absenceData.endHour);
  endHourTimestamp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

  return { startHour, endHour, startHourTimestamp, endHourTimestamp };
}
