import * as React from 'react';
import { forwardRef } from 'react';

import {
  Autocomplete,
  autocompleteClasses,
  Box,
  FilterOptionsState,
  ListSubheader,
  Paper,
  Popper,
  Typography,
  UseAutocompleteProps,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface OptionObj {
  readonly value: number | string | 'public-org'; // userid or everyone
  readonly label: string;
}

export interface UserIdOption {
  readonly value: number; // userid
  readonly label: string;
}

interface Props {
  readonly name: string;
  readonly options: readonly OptionsProps[] | 'company' | 'team' | 'user';
  readonly value: number | undefined | null; // userid
  readonly onChange: UseAutocompleteProps<OptionsProps, false, undefined, undefined>['onChange'];
  readonly label?: string;
  readonly error: boolean | undefined;
  readonly helperText: string | false | undefined;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly allValidUserIds?: number[];
  readonly allUsers?: boolean;
}

const LISTBOX_PADDING = 8; // px

export interface OptionsProps extends CachedUser {
  label: string;
  value: number;
}

const iconSize = { width: '14px', height: '14px' } as const;

function RenderRow(props: ListChildComponentProps) {
  const { polyglot } = usePolyglot();
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  const user = dataSet[1];
  const description = () => {
    if (
      user?.role?.jobTitle &&
      user?.role?.jobTitle?.length > 0 &&
      user?.role?.site?.name &&
      user?.role?.site?.name?.length > 0
    ) {
      return [polyglot.t(user?.role?.jobTitle ?? ''), polyglot.t(user?.role?.site?.name ?? '')].join(', ');
    } else {
      if (user?.role?.jobTitle && user?.role?.jobTitle?.length > 0) return polyglot.t(user?.role?.jobTitle ?? '');
      else return polyglot.t(user?.role?.site?.name ?? '');
    }
  };

  return (
    <Box component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Box key={user.value} sx={{ display: 'block', minHeight: 20, paddingY: spacing.p5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UserAvatar userId={user.value!} size="xxsmall" />
          <Box sx={{ marginLeft: spacing.m10, minHeight: 20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{user.label}</Typography>
          </Box>
        </Box>

        <Box sx={{ marginLeft: spacing.m30, display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              ...themeFonts.captionSmall,
              marginTop: spacing.m5,
              color: themeColors.Grey,
              textTransform: 'capitalize',
            }}
          >
            {description()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 60 : 60;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {RenderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,

      '& li.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#fff !important',
      },
    },
  },
});

export const SingleUserSelect = forwardRef<typeof Autocomplete, Props>(
  (
    {
      name,
      options: optionsOrReach,
      value: selectedUserId,
      onChange,
      label,
      error,
      helperText,
      disabled = false,
      placeholder,
      allValidUserIds,
      allUsers = false,
    },
    ref
  ) => {
    const { polyglot } = usePolyglot();
    const [globalState] = React.useContext(GlobalContext);
    const { nonTerminatedCachedUsers, cachedUsers } = useCachedUsers();

    const options = React.useMemo<readonly OptionsProps[]>(() => {
      const validOptionsSet = allValidUserIds ? new Set(allValidUserIds) : null;
      if (typeof optionsOrReach === 'object') {
        if (validOptionsSet) return optionsOrReach.filter((u) => u.userId && validOptionsSet.has(u.userId));

        return optionsOrReach;
      }
      const reach = optionsOrReach ?? 'company';
      return {
        user() {
          const user = nonTerminatedCachedUsers.find((u) => u.userId === globalState.user.userId);
          return user ? [{ label: polyglot.t(user.displayName), value: user.userId, ...user }] : [];
        },
        team() {
          const reportsAndUs = new Set(globalState.user.reports);
          reportsAndUs.add(globalState.user.userId);
          return nonTerminatedCachedUsers
            .filter((u) => reportsAndUs.has(u.userId) && (!validOptionsSet || validOptionsSet.has(u.userId)))
            .map((u) => ({ label: polyglot.t(u.displayName), value: u.userId, ...u }));
        },
        company: () => {
          const validFiltered = validOptionsSet
            ? nonTerminatedCachedUsers.filter((u) => validOptionsSet.has(u.userId))
            : allUsers
            ? cachedUsers
            : nonTerminatedCachedUsers;
          return validFiltered.map((u) => ({ label: polyglot.t(u.displayName), value: u.userId, ...u }));
        },
      }[reach]();
    }, [
      allValidUserIds,
      optionsOrReach,
      allUsers,
      nonTerminatedCachedUsers,
      cachedUsers,
      polyglot,
      globalState.user.userId,
      globalState.user.reports,
    ]);

    const selectedValue = React.useMemo(() => {
      // converting to unknown and then to OptionsProps to be able to use the empty value when no user is selected, otherwise if you select someone, then remove that id, the name stay in the textfield, even if the value of userId becomes undefined
      if (!selectedUserId) return ({ value: undefined, label: '' } as unknown) as OptionsProps;
      return options.find(({ value }) => value === selectedUserId);
    }, [options, selectedUserId]);

    return (
      <Autocomplete
        ref={ref}
        id="virtualize-auto"
        disabled={disabled}
        options={options}
        value={selectedValue}
        onChange={onChange}
        fullWidth
        disableListWrap
        renderOption={(props, option, state) => {
          return [props, option, state.inputValue] as React.ReactNode;
        }}
        PopperComponent={StyledPopper}
        renderInput={(params) => {
          return (
            <StyledAutoTextfield
              {...params}
              label={label}
              placeholder={placeholder}
              InputLabelProps={{ shrink: true }}
              fullWidth
              name={name}
              variant="standard"
              error={error}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                startAdornment: selectedUserId && (
                  <Box sx={{ mr: spacing.m5 }}>
                    <UserAvatar key={selectedUserId} userId={selectedUserId} size="xxsmall" />
                  </Box>
                ),
              }}
            />
          );
        }}
        ListboxComponent={ListboxComponent}
        popupIcon={<ArrowDownACIcon {...iconSize} />}
        clearIcon={<ClearIcon {...iconSize} />}
        PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
        filterOptions={(options: OptionsProps[], state: FilterOptionsState<OptionsProps>) => {
          const inputValue = state.inputValue?.toLowerCase() ?? '';
          return options.filter((o) => o.label && o.label.toLowerCase().includes(inputValue));
        }}
      />
    );
  }
);
