import { Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { UploadInput } from '@/component/forms/UploadInput';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type P45FileUploadProps = {
  userId: number;
  disabled?: boolean;
};

export const P45FileUpload = ({ userId, disabled }: P45FileUploadProps) => {
  const { polyglot } = usePolyglot();
  return (
    <>
      <Typography
        sx={{
          ...themeFonts.title4,
          color: themeColors.DarkGrey,
        }}
      >
        {polyglot.t('OnboardingByUser.uploadP45')}
      </Typography>
      <UploadInput<{ uuid: string }>
        label={polyglot.t('OnboardingByUser.uploadP45')}
        cardStyle={{
          padding: 1,
          backgroundColor: '#3366ff1a',
          boxShadow: 'none',
          marginBottom: 3,
          width: '100%',
        }}
        onChange={async (resp) => {
          if (!resp) return;
          await DocumentAPI.createP45Document(userId, {
            fileUuid: resp.uuid,
            name: `p45-${userId}`,
            type: 'p45',
            belongsTo: [{ value: userId }],
          });
        }}
        disabled={disabled}
      />
    </>
  );
};
