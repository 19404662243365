import { Pagination } from '@/models/company.model';
import { Period } from '@/models/subscription.model';

export enum SeatsMethod {
  FIXED = 'fixed',
  ACTIVE = 'active',
}

export const seatsMethodOptions = Object.values(SeatsMethod);

export enum BillingCurrency {
  GBP = 'GBP',
  USD = 'USD',
}

export const currencyOptions = Object.values(BillingCurrency);

export enum SubscriptionType {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
  TRIAL = 'trial',
}

export const subscriptionTypeOptions = Object.values(SubscriptionType);

export interface PlanDefinitionCost {
  GBP: number;
  USD: number;
}

export enum DiscountTypeValues {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

export const discountTypeOptions = Object.values(DiscountTypeValues);

export enum DiscountTypeLabels {
  percentage = 'Percentage discount',
  fixed = 'Fixed amount discount',
}

export interface PlanDefinition {
  readonly id: number;
  name: string;
  cost: PlanDefinitionCost;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface SubscribedPlanDefinition {
  planDefinitionId: number;
  planName: string;
  cost: PlanDefinitionCost;
  pricePerSeat?: PlanDefinitionCost;
  discountType?: DiscountTypeValues;
  discountValue?: number;
  discountEndDate?: Date | null;
  discountValid?: boolean;
}

export type CompanySubscription = {
  companyId: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly id: number;
  deletedAt?: any;
  type: SubscriptionType;
  currency: BillingCurrency;
  vatSetting: VatSetting;
  endDate?: Date | null;
  seatsMethod: SeatsMethod;
  numberOfSeats?: number;
  subscribedPlans: SubscribedPlanDefinition[];
  company?: {
    name: string;
  };
};

export type BillingMetric = {
  noOfActiveDays: number;
  noOfActiveUsers: number;
  noOfRentedDevices?: number;
  isExpired?: boolean;
  isCancelled?: boolean;
  period: Period;
};

export type BillResultV2 = {
  isExpired?: boolean;
  isCancelled?: boolean;
  subscriptionId: number;
  productPlanId: number[];
  subscriptionCost: number;
  totalDiscount: number;
  period: { startDate: string; endDate: string };
  product: string;
  totalCostPostTax?: number;
  planPrice: number;
  totalCost: number;
  hasDiscounts: boolean;
  seatMultiplier: number;
};

export type EnhancedCompanySubscription = CompanySubscription & {
  seatMultiplier: number;
  billingMetric: BillingMetric;
};

export interface CompanySubscriptionDetails {
  companySubscription: EnhancedCompanySubscription;
  billResult: BillResultV2;
  migratedToNewPlans?: boolean;
}

export type CompanySubscriptionResult = {
  subscriptions: CompanySubscription[];
  pagination: Pagination;
};

export enum VatSetting {
  PERCENTAGE_20 = '20%',
  NO_VAT = 'No VAT',
  REVERSE_CHARGE = 'Reverse charge',
}

export const vatSettingOptions = Object.values(VatSetting);
