import { GridCellParams } from '@mui/x-data-grid';

import { EntityImportErrorDto } from '../entity-import.dto';

export function isImportCellEditable<T>(param: GridCellParams, rows: EntityImportErrorDto<T>[]): boolean {
  return (
    rows.filter((row) => row.id === param.id)?.[0]?.errors?.filter((error) => error.property === param.field)?.length >
    0
  );
}
