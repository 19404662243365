import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ApproverSelectComponent } from '@/v2/components/approver-select/approver-select.component';
import { ApproverGroup } from '@/v2/components/approver-select/approver-select.interface';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { MultipleSelectCheckbox } from '@/v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { OptionObj } from '@/v2/components/user-select-type/user-select.component';
import { CombinedAllFields } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { DItem } from '@/v2/feature/notification/notification-settings/features/notification-setting.page';
import {
  CustomNotificationType,
  DOMAINS_WITH_BEFORE,
  NotificationForTable,
  NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK,
  NotificationSystemName,
  ProfileNotifications,
  WhenFilterEnum,
  WhenFilterOptions,
} from '@/v2/feature/notification/notification-settings/notification.interface';
import { addDefaultUserDateFields } from '@/v2/feature/notification/notification.util';
import { ProfileNotificationAPI } from '@/v2/feature/notification/profile-notification.api';
import { HOUR_OF_THE_DAY_OPTIONS } from '@/v2/feature/user/features/user-notifications/user-notifications.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface NotificationsSettingsAddFormDrawerProps {
  notificationToEdit: NotificationForTable;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allSlackChannels: readonly DItem[];
  preSelectedEmailRecipients?: string;
  slackConnected: boolean;
  refreshNotificationData: () => void;
  allDateFields: CombinedAllFields | null | undefined;
}

const NOTIFICATIONS_WITH_BEFORE_OPTION_ENABLED = [
  NotificationSystemName.documentExpiry,
  NotificationSystemName.probationPeriod,
];

export const NotificationsSettingAddFormDrawer = ({
  notificationToEdit,
  setIsOpen,
  allSlackChannels,
  slackConnected,
  refreshNotificationData,
  allDateFields,
}: NotificationsSettingsAddFormDrawerProps) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>(
    notificationToEdit.systemName &&
      DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) &&
      notificationToEdit?.daysBefore &&
      notificationToEdit.daysBefore > 0
      ? WhenFilterEnum.BEFORE
      : WhenFilterEnum.IMMEDIATE
  );

  const mapValuesToPayload = (values: NotificationForTable): ProfileNotifications => {
    const now = new Date();
    const {
      emailEnabled,
      slackEnabled,
      slackChannels,
      sendToEditValue,
      cronHour,
      employeeTriggerEvent,
      name,
      notificationText,
      sendSlackBot,
    } = values;
    const slackChannelValues = slackChannels?.map((d) => d.value);
    const [fieldId, formId] = (employeeTriggerEvent ?? '')?.split('/');
    const record: ProfileNotifications = {
      companyId: 0,
      createdAt: now,
      updatedAt: now,
      id: 0,
      domain: CustomNotificationType.PROFILE_NOTIFICATION,
      sendAdmin: sendToEditValue?.presets?.includes('admin'),
      sendManager: sendToEditValue?.presets?.includes('manager:1'),
      sendSpecificUsers: sendToEditValue?.userIds?.length > 0 ? true : false,
      email: emailEnabled,
      specificUserEmails: sendToEditValue?.userIds,
      daysBefore: (filterValue && filterValue === WhenFilterEnum.BEFORE ? values.daysBefore : 0) ?? 0,
      slackChannels: slackChannelValues ?? [],
      slack: (slackEnabled || sendSlackBot) ?? false,
      cronHour: cronHour ?? 6,
      fieldId,
      formId,
      name,
      notificationText,
      sendSlackBot: sendSlackBot ?? false,
    };
    return record;
  };

  const handleSubmit = async (values: NotificationForTable) => {
    try {
      if (values) {
        const record = mapValuesToPayload(values);
        await ProfileNotificationAPI.createNewProfileNotification(record);
      }
      refreshNotificationData();
      setIsOpen(false);
      formik.resetForm();
      showMessage(polyglot.t('NotificationsSettingAddFormDrawer.successMessages.create'), 'success');
    } catch (e) {
      showMessage(polyglot.t('NotificationsSettingsEdit.errorMessages.cannotbeSaved'), 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      ...notificationToEdit,
    },
    // validationSchema: yup.object({
    //   TODO
    // }),
    enableReinitialize: true,
    onSubmit: async (values: NotificationForTable) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    setFilterValue(
      notificationToEdit.systemName &&
        DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) &&
        notificationToEdit?.daysBefore &&
        notificationToEdit.daysBefore > 0
        ? WhenFilterEnum.BEFORE
        : WhenFilterEnum.IMMEDIATE
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationToEdit]);

  const testCustomNotification = async () => {
    try {
      setLoading(true);
      const record = mapValuesToPayload(formik.values);
      await ProfileNotificationAPI.testProfileNotification(record);
      showMessage(polyglot.t('NotificationsSettingAddFormDrawer.successMessages.test'), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('NotificationsSettingAddFormDrawer.errorMessages.test', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const saveAndEnable = async () => {
    try {
      setLoading(true);
      await handleSubmit(formik.values);
    } catch (error) {
      showMessage(
        polyglot.t('NotificationsSettingAddFormDrawer.errorMessages.save', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCronHourChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('cronHour', (event.target as HTMLInputElement)?.value);
  };

  const handleEmployeeTriggerEvent = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('employeeTriggerEvent', (event.target as HTMLInputElement)?.value);
  };

  const allDateFieldOptions: OptionObj[] = useMemo(() => {
    const result: OptionObj[] = [];
    if (allDateFields && allDateFields.dataFieldsCustom) {
      for (const eachCustom of allDateFields.dataFieldsCustom) {
        result.push({
          value: `${eachCustom.fieldId}/${eachCustom.formId}`,
          label: eachCustom.fieldName,
        });
      }
    }
    // default employee fields;
    return addDefaultUserDateFields(result);
  }, [allDateFields]);

  const sendToAdmin = formik.values?.sendToEditValue?.presets?.includes('admin');
  const sendToManager = formik.values?.sendToEditValue?.presets?.includes('manager:1');

  const suffixLabelForSlackBot = useMemo(() => {
    const botRecipients = [];
    if (sendToAdmin) botRecipients.push('Admin');
    if (sendToManager) botRecipients.push('Manager');
    return botRecipients.length > 0 ? ` to ${botRecipients.join(', ')}` : '';
  }, [sendToAdmin, sendToManager]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          <Typography sx={{ ...themeFonts.title2 }}>
            {polyglot.t('NotificationSettingsPage.newNotification')}
          </Typography>
        </Box>
        <Box
          sx={{
            ...spacing.mt30,
            display: 'flex',
            flexDirection: 'row',
            gap: spacing.g20,
            justifyContent: 'space-between',
          }}
        >
          <TextfieldComponent
            label={polyglot.t('NotificationsSettingAddFormDrawer.notificationName')}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
            clearText={() => formik.setFieldValue('name', '')}
          />
        </Box>
        <Box
          sx={{
            ...spacing.mt15,
            display: 'flex',
            flexDirection: 'row',
            gap: spacing.g20,
            justifyContent: 'space-between',
          }}
        >
          <TextfieldComponent
            label={polyglot.t('NotificationsSettingAddFormDrawer.notificationText')}
            name="notificationText"
            value={formik.values.notificationText}
            onChange={formik.handleChange}
            error={formik.touched.notificationText && !!formik.errors.notificationText}
            helperText={formik.touched.notificationText && formik.errors.notificationText}
            type="text"
            clearText={() => formik.setFieldValue('notificationText', '')}
          />
        </Box>

        <Stack display={'flex'} direction={'column'} sx={{ ...spacing.mt20 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('NotificationsSettingAddFormDrawer.sendTo')}
          </Typography>
          <ApproverSelectComponent
            hideEveryone={
              formik.values.systemName &&
              NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) &&
              formik.values.sendToEditValue.userIds?.length === 0
                ? false
                : true
            }
            hidePresets={
              formik.values.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName)
                ? ['admin', 'manager:1']
                : ['everyone']
            }
            hideSpecific={
              (formik.values.systemName &&
                NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) &&
                !formik.values.sendToEditValue.presets.includes('everyone')) ||
              (formik.values.systemName && !NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName))
                ? false
                : true
            }
            hideAutoApprove
            value={formik.values.sendToEditValue}
            userModalTitle={polyglot.t('NotificationsSettingAddFormDrawer.userModalTitle')}
            onChange={(value: ApproverGroup) => {
              formik.setFieldValue('sendToEditValue', value);
            }}
            sx={{ mb: spacing.mb20 }}
          />
          {notificationToEdit && (
            <Box sx={{ mt: spacing.mt15 }}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                {polyglot.t('NotificationsSettingsEdit.employeeTriggerEvent')}
              </Typography>
              {allDateFieldOptions.length > 0 ? (
                <SelectComponent
                  name="employeeTriggerEvent"
                  label={''}
                  options={allDateFieldOptions}
                  value={formik.values.employeeTriggerEvent}
                  compareValue={formik.values.employeeTriggerEvent}
                  error={undefined}
                  onChange={handleEmployeeTriggerEvent}
                  helperText={undefined}
                  fullWidth
                />
              ) : (
                <Typography sx={{ ...themeFonts.caption, color: themeColors.black }}>
                  {polyglot.t('NotificationsSettingAddFormDrawer.noCustomDate')}
                </Typography>
              )}
            </Box>
          )}
          <Stack sx={{ mt: spacing.mt15 }} gap={spacing.g10}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('NotificationsSettingAddFormDrawer.sendNotification')}
            </Typography>
            <TabFilterButtons
              disabled={
                notificationToEdit?.systemName &&
                !NOTIFICATIONS_WITH_BEFORE_OPTION_ENABLED.includes(notificationToEdit?.systemName)
              }
              filters={WhenFilterOptions(polyglot)}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              onFilterChange={({ filterValue }) => {
                setFilterValue(filterValue);
                formik.setFieldValue('when', filterValue);
              }}
            />
            {filterValue === WhenFilterEnum.BEFORE && (
              <TextfieldComponent
                name="daysBefore"
                label={polyglot.t('NotificationsSettingAddFormDrawer.daysBefore')}
                value={formik.values.daysBefore}
                type="number"
                onChange={formik.handleChange}
                error={formik.touched.daysBefore && !!formik.errors.daysBefore}
                helperText={(formik.touched.daysBefore && formik.errors.daysBefore) ?? ' '}
                clearText={() => formik.setFieldValue('daysBefore', '')}
              />
            )}
          </Stack>
          <Stack sx={{ mt: spacing.mt20 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.t('NotificationsSettingAddFormDrawer.channel')}
            </Typography>
            {notificationToEdit && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: spacing.mt10 }}>
                <CheckboxComponent
                  label={polyglot.t('NotificationsSettingAddFormDrawer.email')}
                  name={'emailEnabledSetting'}
                  checked={formik.values.emailEnabled}
                  value={formik.values.emailEnabled}
                  onChange={(e, checked) => {
                    formik.setFieldValue('emailEnabled', checked);
                  }}
                />
              </Box>
            )}
            {notificationToEdit && slackConnected && (
              <>
                {(sendToAdmin || sendToManager) && (
                  <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: spacing.mt20 }}>
                    <CheckboxComponent
                      label={polyglot.t('NotificationsSettingAddFormDrawer.slackBotSettings', {
                        suffix: suffixLabelForSlackBot,
                      })}
                      name={'slackBotSetting'}
                      checked={formik.values.sendSlackBot}
                      value={formik.values.sendSlackBot}
                      onChange={(e, checked) => {
                        formik.setFieldValue('sendSlackBot', checked);
                      }}
                    />
                  </Box>
                )}
                <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: spacing.mt20 }}>
                  <CheckboxComponent
                    label={polyglot.t('NotificationsSettingsEdit.slackChannels')}
                    name={'slackEnabledSetting'}
                    checked={formik.values.slackEnabled}
                    value={formik.values.slackEnabled}
                    onChange={(e, checked) => {
                      formik.setFieldValue('slackEnabled', checked);
                      const updatedValues =
                        checked && formik.values.slackChannels ? [...formik.values.slackChannels] : [];
                      formik.setFieldValue('slackChannels', updatedValues);
                    }}
                  />
                </Box>
              </>
            )}
          </Stack>
          {notificationToEdit && slackConnected && formik.values.slackEnabled && (
            <Box sx={{ mt: spacing.mt20 }}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                {polyglot.t('NotificationsSettingsEdit.slackChannels')}
              </Typography>
              <MultipleSelectCheckbox<string>
                label={''}
                id={'slackChannel'}
                limitTags={-1}
                options={allSlackChannels}
                value={formik.values.slackChannels ?? []}
                onChange={(e, values) => {
                  formik.setFieldValue('slackChannels', values);
                }}
                isOptionEqualToValue={(x, y) => x.value === y.value}
                getOptionLabel={({ label }: { label: string }): string => label}
              />
            </Box>
          )}
          {notificationToEdit && formik.values && (
            <Box sx={{ mt: spacing.mt20 }}>
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                {polyglot.t('NotificationsSettingsEdit.hourToSendReminders')}
              </Typography>
              <SelectComponent
                name="cronHour"
                label={''}
                options={HOUR_OF_THE_DAY_OPTIONS}
                value={formik.values.cronHour}
                compareValue={formik.values.cronHour}
                error={undefined}
                onChange={handleCronHourChange}
                helperText={undefined}
                fullWidth
              />
            </Box>
          )}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: spacing.g10,
            width: '100%',
            ...spacing.mt30,
          }}
        >
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="medium"
            disabled={!formik.values.slackEnabled && !formik.values.emailEnabled}
            fullWidth
            onClick={testCustomNotification}
          >
            {polyglot.t('General.sendATest')}
          </ButtonComponent>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            disabled={loading || !formik.values.employeeTriggerEvent}
            fullWidth
            onClick={saveAndEnable}
          >
            {polyglot.t('General.saveAndEnable')}
          </ButtonComponent>
        </Box>
      </Form>
    </FormikProvider>
  );
};
