import axios from 'axios';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
import {
  CreateUserRoleDto,
  UpdateUserRoleDto,
  UserRoleDto,
} from '@v2/feature/user/features/user-forms/user-role/user-role.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';

export class UserRoleAPI {
  static async getUserRoleInfo(userId: number): Promise<EntriesAndEffectiveRecord<UserRoleDto>> {
    return (await axios.get(`/apiv2/users/${userId}/role`)).data;
  }

  static async patch(userId: number, roleId: number, userRoleUpdate: UpdateUserRoleDto): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/role/${roleId}`, userRoleUpdate);
  }

  static async create(userId: number, userRoleCreate: CreateUserRoleDto): Promise<UserRoleDto> {
    return (await axios.post(`/apiv2/users/${userId}/role`, userRoleCreate)).data;
  }

  static async setNewManagerOnOnboardingCurrentUser(userId: number, newManagerId: number): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/role/offboarding/new-manager`, { newManagerId });
  }

  static async deleteById(userId: number, roleId: number): Promise<void> {
    return axios.delete(`/apiv2/users/${userId}/role/${roleId}`);
  }

  static async getRolesByCompanyId(companyId: number): Promise<UserRoleDto[]> {
    return (await axios.get(`/apiv2/superadmin/company/${companyId}/roles`)).data;
  }

  static async bulkDeleteRolesByIds(companyId: number, roleIds: number[]): Promise<void> {
    await axios.delete(`/apiv2/superadmin/company/${companyId}/roles`, { data: roleIds });
  }
}

export class UserRoleEndpoints {
  static getUserRole(userId: number): Endpoint<EntriesAndEffectiveRecord<UserRoleDto>> {
    return {
      url: `/apiv2/users/${userId}/role`,
    };
  }

  static getOffboardingUserRoleChanges(
    userId: number
  ): Endpoint<{
    effectiveRoles: Pick<UserRoleDto, 'id' | 'userId' | 'effectiveDate'>[];
    futureRoles: Pick<UserRoleDto, 'id' | 'userId' | 'effectiveDate'>[];
  }> {
    return {
      url: `/apiv2/users/${userId}/role/offboarding/manager-changes`,
    };
  }
}
