import React, { useCallback, useMemo } from 'react';

import { Box, capitalize } from '@mui/material';
import { iconSize } from '@v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { MeasureFilterToLabel } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-cycle-detail-results.page';
import { radius } from '@v2/styles/radius.styles';

import { Action, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import { FiltersDrawer } from '@/v2/components/table/filters-drawer.component';
import { AnonymityType, SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface SurveyResultsFiltersProps {
  readonly existingFactors: string[];
  readonly showByFilter: 'Question' | 'Factor';
  readonly setShowByFilter?: React.Dispatch<React.SetStateAction<'Question' | 'Factor'>>;
  readonly measureByFilter: 'nps' | 'positive' | 'avg';
  readonly setMeasureByFilter: React.Dispatch<React.SetStateAction<'nps' | 'positive' | 'avg'>>;
  readonly impactFilter: string;
  readonly setImpactFilter: React.Dispatch<React.SetStateAction<any>>;
  dispatch: React.Dispatch<Action>;
  readonly filtersAndTypesOptions: any;
  readonly setFilterString: React.Dispatch<React.SetStateAction<string>>;
  readonly filterString: string;
  readonly surveyCycle: SurveyCycle;
}

export const SurveyResultsFilters = ({
  existingFactors,
  showByFilter,
  setShowByFilter,
  measureByFilter,
  setMeasureByFilter,
  impactFilter,
  setImpactFilter,
  dispatch,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
  surveyCycle,
}: SurveyResultsFiltersProps) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const setShowByHandler = useCallback(
    async (value: 'Question' | 'Factor') => {
      try {
        if (!setShowByFilter) return;
        setShowByFilter(value);
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('surveys', 'results', 'showBy', value);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, setShowByFilter, showMessage]
  );

  const showByOptions = useMemo(
    () =>
      setShowByFilter
        ? [
            {
              handler: async () => await setShowByHandler('Question'),
              label: 'Question',
            },
            {
              handler: async () => await setShowByHandler('Factor'),
              label: 'Factor',
            },
          ]
        : [],
    [setShowByHandler, setShowByFilter]
  );

  const setMeasuredByHandler = useCallback(
    async (value: 'nps' | 'positive' | 'avg') => {
      try {
        setMeasureByFilter(value);
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('surveys', 'results', 'measuredBy', value);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, setMeasureByFilter, showMessage]
  );

  const measuredByOptions = useMemo(
    () => [
      {
        handler: async () => await setMeasuredByHandler('nps'),
        label: MeasureFilterToLabel['nps'],
      },
      {
        handler: async () => await setMeasuredByHandler('positive'),
        label: MeasureFilterToLabel['positive'],
      },
      {
        handler: async () => await setMeasuredByHandler('avg'),
        label: MeasureFilterToLabel['avg'],
      },
    ],
    [setMeasuredByHandler]
  );

  const impactOnOptions = useMemo(
    () =>
      existingFactors.map((factor) => ({
        handler: async () => await setImpactFilter(factor),
        label: factor ?? '',
      })),
    [existingFactors, setImpactFilter]
  );

  const shouldShowFilters = useMemo(
    () =>
      (surveyCycle.visibilitySettings && !surveyCycle.visibilitySettings.anonymiseAnswers) ||
      (surveyCycle.visibilitySettings &&
        surveyCycle.visibilitySettings.anonymiseAnswers &&
        surveyCycle.visibilitySettings.anonymityType === AnonymityType.Semi),
    [surveyCycle]
  );

  const updateResultsSelectedFilters = useCallback(
    async (filterString: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'surveys',
          'results',
          'selectedFilter',
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  return (
    <Box
      sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
      className="hide-for-capture"
    >
      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        {setShowByFilter && (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Show by
            </Typography>
            <StyledMenuComponent
              options={showByOptions}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: showByFilter,
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
                style: { borderRadius: radius.br25 },
              }}
            />
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Typography variant="caption" color="Grey">
            Measured by
          </Typography>
          <StyledMenuComponent
            options={measuredByOptions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: MeasureFilterToLabel[measureByFilter] ?? capitalize(measureByFilter),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              style: { borderRadius: radius.br25 },
            }}
          />
        </Box>

        {showByFilter === 'Question' && impactFilter && existingFactors && existingFactors.length > 0 && (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              And the impact on
            </Typography>
            <StyledMenuComponent
              options={impactOnOptions}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: impactFilter,
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
                style: { borderRadius: radius.br25 },
              }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        {Boolean(shouldShowFilters) && filtersAndTypesOptions && (
          <FiltersDrawer
            filtersOptions={[{ filters: filtersAndTypesOptions?.surveyFilters ?? {} }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            onApply={updateResultsSelectedFilters}
          />
        )}
      </Box>
    </Box>
  );
};
