import { camelCase } from 'lodash';
import { parse } from 'papaparse';

export function getCSVRows(arr: Object[]) {
  const dataMap = new Map();

  function setValue(rowValues: any[], path: any[], val: any) {
    if (Object(val) !== val) {
      const pathStr = path.join('.');
      const idx = (dataMap.has(pathStr) ? dataMap : dataMap.set(pathStr, dataMap.size)).get(pathStr);
      rowValues[idx] = val;
    } else {
      for (let key in val) {
        setValue(rowValues, key === '0' ? path : path.concat(key), val[key]);
      }
    }

    return rowValues;
  }

  const result = arr.map((obj) => setValue([], [], obj));
  return [[...dataMap.keys()], ...result];
}

export function toCsv(arr: Object[][]): string {
  return arr.map((row) => row.join(',')).join('\n');

  // return arr.map((row) => row.map((val) => (isNaN(val) && !isUndefined(val) ? val : +val)).join(',')).join('\n');
}

export const parseCsvBuffer = (csvString: Buffer | string): Record<string, string>[] => {
  const { data, errors, meta: _ } = parse<Record<string, string>>(csvString.toString(), {
    download: false,
    header: true,
    transformHeader: (header) => {
      if (header.includes('.')) {
        return header.trim();
      }
      return camelCase(header);
    },
    skipEmptyLines: 'greedy',
    transform: (value) => {
      return value.trim();
    },
  });
  if (errors[0]) {
    throw errors[0];
  }
  return data;
};
