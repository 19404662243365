import React from 'react';

import { Box } from '@mui/material';
import { Chart, TooltipModel } from 'chart.js';
import { capitalize } from 'lodash';

import { ReactComponent as Inprogress } from '@/images/fields/Inprogress.svg';
import { ReactComponent as Overdue } from '@/images/fields/Overdue.svg';
import { ReactComponent as HiddenEye } from '@/images/new-theme-icon/HiddenEye.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  BinaryProgress,
  PolyProgress,
  ReachType,
  ReviewCycle,
  ReviewerTypes,
  ReviewerTypesColors,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewProgress } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { QuestionResponseSummary } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { GrowthProgressLineChart } from '@/v2/feature/growth/shared/components/growth-progress-chart.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SelectedFiltersRequest } from '@/v2/feature/reports/reports.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DATE_FORMAT = 'dd MMM yyyy';

export const getStatus = () => {
  return {
    [CycleState.Draft]: (
      <ChipComponent
        name={capitalize(CycleState.Draft)}
        backgroundColor="white"
        textColor="DarkGrey"
        border="light"
        textVariant="caption"
      />
    ),
    [CycleState.Scheduled]: (
      <ChipComponent
        name={capitalize(CycleState.Scheduled)}
        backgroundColor="DarkGrey"
        textColor="white"
        textVariant="caption"
      />
    ),
    [CycleState.Launching]: (
      <ChipComponent
        name={`${capitalize(CycleState.Launching)}...`}
        backgroundColor="Background"
        textColor="DarkGrey"
        textVariant="caption"
      />
    ),
    [CycleState.Paused]: (
      <ChipComponent
        name={`${capitalize(CycleState.Paused)}`}
        backgroundColor="Background"
        textColor="DarkGrey"
        textVariant="caption"
      />
    ),
    [CycleState.Ongoing]: (
      <ChipComponent
        name={capitalize(CycleState.Ongoing)}
        backgroundColor="Green"
        textColor="white"
        textVariant="caption"
      />
    ),
    [CycleState.Finalising]: (
      <ChipComponent
        name={`${capitalize(CycleState.Finalising)}...`}
        backgroundColor="Background"
        textColor="DarkGrey"
        textVariant="caption"
      />
    ),
    [CycleState.Completed]: (
      <ChipComponent
        name={capitalize(CycleState.Completed)}
        backgroundColor="Background"
        textColor="DarkGrey"
        textVariant="caption"
      />
    ),
  };
};

export const TitleStatusComponent = ({
  reviewCycle,
  reachType,
  showAction,
  action,
}: {
  reviewCycle: Pick<ReviewCycle, 'state' | 'internalName' | 'displayName'>;
  reachType: ReachType;
  showAction?: boolean;
  action?: React.JSX.Element;
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
      <Typography
        variant="title2"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '450px', display: 'block' }}
      >
        {reachType === ReachType.Me
          ? reviewCycle.displayName || reviewCycle.internalName
          : reviewCycle.internalName ?? 'Overview'}
      </Typography>
      {reachType !== ReachType.Me && getStatus()[reviewCycle.state]}
    </Box>
    {showAction && <>{action}</>}
  </Box>
);

export const binaryStatusChip = (reviewrObject: BinaryProgress, label: ReviewerTypes, state: CycleState) => {
  const { complete, show, overdue } = reviewrObject;
  const isOverdue = state === CycleState.Ongoing && overdue;
  const fillColor = complete ? themeColors.Green : isOverdue ? themeColors.RedDark : themeColors.Grey;

  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        alignItems: 'center',
        gap: spacing.g4,
        padding: spacing.p4,
        borderRadius: radius.br25,
        border: `1px solid ${themeColors.lightGrey}`,
      }}
    >
      {complete ? (
        <OkGreen {...iconSize} style={{ fill: fillColor }} />
      ) : (
        <Waiting {...iconSize} style={{ fill: fillColor }} />
      )}
      <Typography variant="caption" color="Grey">
        {label}
      </Typography>
    </Box>
  );
};

export const polyStatusChip = (reviewrObject: PolyProgress, label: ReviewerTypes, state: CycleState) => {
  const { complete, total, show, overdue } = reviewrObject;
  const isComplete = complete === total;
  const isOverdue = state === CycleState.Ongoing && overdue;
  return (
    <Box
      sx={{
        display: show ? 'flex' : 'none',
        alignItems: 'center',
        gap: spacing.g4,
        padding: spacing.p4,
        borderRadius: radius.br25,
        border: `1px solid ${themeColors.lightGrey}`,
      }}
    >
      {isComplete ? (
        <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
      ) : reviewrObject.complete > 0 ? (
        isOverdue ? (
          <Overdue {...iconSize} />
        ) : (
          <Inprogress {...iconSize} />
        )
      ) : (
        <Waiting {...iconSize} style={{ fill: isOverdue ? themeColors.RedDark : themeColors.Grey }} />
      )}

      {reviewrObject.complete > 0 && (
        <Typography variant="caption" color="Grey">
          {reviewrObject.complete}/{reviewrObject.total}
        </Typography>
      )}

      <Typography variant="caption" color="Grey">
        {label}
      </Typography>
    </Box>
  );
};

export const getParticipantProgressStatus = (participantObject: ReviewProgress, reviewCycle: ReviewCycle) => {
  const { reviewerSelect, state } = reviewCycle;
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
      {reviewerSelect.includes(ReviewerTypes.Self) &&
        binaryStatusChip(participantObject.status.self, ReviewerTypes.Self, state)}
      {reviewerSelect.includes(ReviewerTypes.Manager) &&
        participantObject.status.manager.total > 0 &&
        polyStatusChip(participantObject.status.manager, ReviewerTypes.Manager, state)}
      {reviewerSelect.includes(ReviewerTypes.Upward) &&
        participantObject.status.upward.total > 0 &&
        polyStatusChip(participantObject.status.upward, ReviewerTypes.Upward, state)}
      {reviewerSelect.includes(ReviewerTypes.Peer) &&
        participantObject.status.peer.total > 0 &&
        polyStatusChip(participantObject.status.peer, ReviewerTypes.Peer, state)}
    </div>
  );
};

export const getReviewerTypesChips = (hideVisibility: boolean) => {
  return {
    [ReviewerTypes.Self]: (
      <ChipComponent
        name={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm }}>
            {ReviewerTypes.Self} {hideVisibility && <HiddenEye {...iconSize} fill={themeColors.white} />}
          </Box>
        }
        backgroundColor="PastelSeaGreen"
        textColor="white"
        textVariant="caption"
      />
    ),
    [ReviewerTypes.Manager]: (
      <ChipComponent
        name={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm }}>
            {ReviewerTypes.Manager} {hideVisibility && <HiddenEye {...iconSize} fill={themeColors.white} />}
          </Box>
        }
        backgroundColor="Pink"
        textColor="white"
        textVariant="caption"
      />
    ),
    [ReviewerTypes.Upward]: (
      <ChipComponent
        name={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm }}>
            {ReviewerTypes.Upward} {hideVisibility && <HiddenEye {...iconSize} fill={themeColors.white} />}
          </Box>
        }
        backgroundColor="PastelPurple"
        textColor="white"
        textVariant="caption"
      />
    ),
    [ReviewerTypes.Peer]: (
      <ChipComponent
        name={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm }}>
            {ReviewerTypes.Peer} {hideVisibility && <HiddenEye {...iconSize} fill={themeColors.white} />}
          </Box>
        }
        backgroundColor="PastelBrown"
        textColor="white"
        textVariant="caption"
      />
    ),
  };
};

export const getReviewerTypesColor = (): ReviewerTypesColors => {
  return {
    [ReviewerTypes.Self]: 'PastelSeaGreen',
    [ReviewerTypes.Peer]: 'PastelBrown',
    [ReviewerTypes.Upward]: 'PastelPurple',
    [ReviewerTypes.Manager]: 'Pink',
  };
};

export const hexToRGBA = (hex: string, opacity: number) => {
  // Remove the hash at the beginning of the hex color
  hex = hex.replace('#', '');

  // Parse the hex color to get the RGB components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the rgba color with the specified opacity
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getAnalyticsByType = (question: QuestionResponseSummary) => {
  switch (question.type) {
    case QuestionType.OpenEnded:
      return (
        <Typography variant="caption" color="Grey">
          Not available for Comment question.
        </Typography>
      );

    case QuestionType.ScaleQuestion:
      return (
        <GrowthProgressLineChart
          label={`${question.series.length > 0 ? `${question.series[0]}%` : `${0}%`}`}
          labelVariant="caption"
          percentJustifyContent="start"
          percentages={[
            {
              value: question.series.length > 0 ? Number(question.series[0]) : 0,
              color: 'blue',
            },
          ]}
        />
      );

    default:
      return (
        <GrowthProgressLineChart
          label="–"
          labelVariant="caption"
          percentJustifyContent="start"
          percentages={question.series.map((q, idx) => {
            return { value: Number(q), color: 'blue', label: question.categories[idx], showTooltip: true };
          })}
        />
      );
  }
};

const getOrCreateTooltip = (chart: Chart): HTMLDivElement => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div') as HTMLDivElement;

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = themeColors.DarkGrey;
    tooltipEl.style.width = '220px';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.fontWeight = '300';
    tooltipEl.style.fontFamily = 'Inter, sans-serif';

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.fontFamily = 'Inter, sans-serif';
    table.style.fontWeight = '300';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: { chart: Chart; tooltip: TooltipModel<'radar'> }) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach((title: any) => {
      const tr: any = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th: any = document.createElement('th');
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i: number) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor as string;
      span.style.borderColor = colors.borderColor as string;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';
      span.style.fontFamily = 'Inter, sans-serif';
      span.style.fontWeight = '300';

      const tr: any = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td: any = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(body + '%');

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.fontSize = '12px';
  tooltipEl.style.fontFamily = 'Inter, sans-serif';
  tooltipEl.style.fontWeight = '300';
  tooltipEl.style.textAlign = 'left';
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export const updateReviewerIds = (
  reviewerIds: {
    [x: number]: number[];
  },
  filteredIds: number[],
  revieweeId: number
) => {
  if (filteredIds.length > 0) {
    reviewerIds[revieweeId] = filteredIds;
  } else {
    delete reviewerIds[revieweeId];
  }
};

export const ReviewerTypesOrderMap = {
  [ReviewerTypes.Self]: 1,
  [ReviewerTypes.Manager]: 2,
  [ReviewerTypes.Upward]: 3,
  [ReviewerTypes.Peer]: 4,
};

export const countFilters = (nestedObject: SelectedFiltersRequest | null | undefined): number => {
  if (!nestedObject || nestedObject === null) return 0;

  let totalCount = 0;
  for (const categoryKey in nestedObject) {
    const category = nestedObject[categoryKey];
    for (const filterKey in category) {
      const filtersArray = category[filterKey];
      if (Array.isArray(filtersArray)) {
        totalCount += filtersArray.length;
      }
    }
  }
  return totalCount;
};
