import * as React from 'react';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDown.svg';

export const AccordionComponent = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} TransitionProps={{ unmountOnExit: true }}>
    {props.children}
  </MuiAccordion>
))(() => ({
  margin: 0,
  padding: 0,
  height: 'auto',
  minHeight: 'auto',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionComponentSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDown />} {...props} />
))(() => ({
  margin: 0,
  padding: 0,
  height: 'auto',
  minHeight: 'auto',
  backgroundColor: themeColors.white,
  borderRadius: '15px',
  flexDirection: 'row',
  '&:hover': {
    backgroundColor: themeColors.Background,
  },
  '& .MuiAccordionSummary-content': {
    marginTop: spacing.m5,
    marginBottom: spacing.m5,
    marginLeft: spacing.m15,
    marginRight: spacing.m15,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: spacing.m15,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
  },
}));

export const AccordionComponentDetails = styled(MuiAccordionDetails)(() => ({
  paddingTop: spacing.p5,
  paddingBottom: spacing.p5,
  paddingLeft: spacing.p15,
}));
