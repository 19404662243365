/* eslint-disable filenames/match-regex */
import { ColumnDef, Row } from '@tanstack/react-table';

import { EmptyCell } from '@/v2/components/table/empty-cell.component';

export interface IIncludable {
  [key: string]: any;
}

export type FormatFunction<T, K> = (field: T) => K;

export class TableColumn<D> {
  define(columnDefinition: {
    header: string;
    id: string;
    fieldName: string;
    size?: number;
    maxSize?: number;
    minSize?: number;
    enableSorting?: boolean;
    sortingFn?: (a: Row<IIncludable & D>, b: Row<IIncludable & D>) => number;
    formatter?: FormatFunction<any, any>;
    parseRow?: (row: IIncludable & D) => any;
    defaultValue?: string;
  }): ColumnDef<IIncludable & D> {
    const {
      id,
      header,
      enableSorting,
      sortingFn,
      size,
      maxSize,
      minSize,
      fieldName,
      formatter,
      parseRow,
      defaultValue,
    } = columnDefinition;

    return {
      header: () => header,
      accessorFn: (row: IIncludable & D) => row,
      id,
      enableSorting,
      sortingFn,
      cell: ({ row: { original } }) => (
        <div>
          {parseRow ? (
            parseRow(original)
          ) : original[fieldName] ? (
            formatter ? (
              formatter(original[fieldName])
            ) : (
              original[fieldName]
            )
          ) : defaultValue ? (
            defaultValue
          ) : (
            <EmptyCell></EmptyCell>
          )}
        </div>
      ),
      size,
      maxSize,
      minSize,
    };
  }
}
