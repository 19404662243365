import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';

type Member = {
  readonly userId: number;
  readonly name: string;
};

interface TableProps {
  readonly members: Member[];
}

export const SettingsMembersList = ({ members }: TableProps) => {
  const { polyglot } = usePolyglot();

  const sortedMembers = useMemo(() => {
    return members?.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];
  }, [members]);

  const [searchInput, setSearchInput] = useState('');

  const filteredUsers = useMemo(() => {
    if (!searchInput) return sortedMembers;

    const search = searchInput.toLowerCase();
    return [...sortedMembers.filter((u) => u.name.toLowerCase().includes(search))];
  }, [searchInput, sortedMembers]);

  const tableColumns = useMemo<ColumnDef<Member, Member>[]>(
    () => [
      {
        header: () => (
          <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
            {polyglot.t('General.name')}
          </Typography>
        ),
        accessorFn: (row) => row,
        id: 'displayName',
        enableSorting: true,
        cell: ({
          row: {
            original: { userId },
          },
        }) => (
          <Box key={userId} sx={{ display: 'flex', gap: spacing.g10 }}>
            <UserAvatar userId={userId} size="xsmall" showName />
          </Box>
        ),
      },
    ],
    [polyglot]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography variant="title3" sx={{ mt: '10px' }}>
        {polyglot.t('AbsencePolicyMembersSection.list')}
      </Typography>

      <TableSearch
        query={searchInput}
        handleChange={(e) => setSearchInput(e.target.value)}
        style={{ width: '572px' }}
      />

      {filteredUsers.length > 0 && (
        <Box sx={{ width: '600px' }}>
          <BasicTable<Member>
            rowData={filteredUsers}
            columnData={tableColumns}
            initialSort={[{ id: 'displayName', desc: true }]}
            fixedLastColumn={false}
          />
        </Box>
      )}
    </Box>
  );
};
