import axios from 'axios';

import { PayrollImportResultDto } from '@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.dto';

export const PAYROLL_IMPORT_CSV_ENDPOINT = '/apiv2/payroll/import/csv';

export class PayrollImportAPI {
  static async importPayrollCsvText(csvText: string): Promise<PayrollImportResultDto> {
    return (await axios.post('/apiv2/payroll/import/csv/text', { csvText })).data;
  }
}
