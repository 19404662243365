import { useEffect } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  ScreenSettingsCombine,
} from '@/v2/feature/device/device.dto';

export const ZeltMdmScreenSettingsSecurityPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): ScreenSettingsCombine => {
    return {
      allowWallpaperModification:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.allowWallpaperModification !== undefined
          ? !configurablePoliciesSelected?.configurableScreenSettingPolicy?.allowWallpaperModification
          : false,
      SHOWFULLNAME: configurablePoliciesSelected?.configurableScreenSettingPolicy?.SHOWFULLNAME ?? false,
      LoginwindowText: configurablePoliciesSelected?.configurableScreenSettingPolicy?.LoginwindowText ?? '',
      ShutDownDisabled: configurablePoliciesSelected?.configurableScreenSettingPolicy?.ShutDownDisabled ?? false,
      SleepDisabled: configurablePoliciesSelected?.configurableScreenSettingPolicy?.SleepDisabled ?? false,
      RestartDisabled: configurablePoliciesSelected?.configurableScreenSettingPolicy?.RestartDisabled ?? false,
      DisableConsoleAccess:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.DisableConsoleAccess ?? false,
    };
  };

  const initialValues: ScreenSettingsCombine = getPolicy();

  function analyzePolicyBeforeSave(policy: ScreenSettingsCombine) {
    if (
      !policy.allowWallpaperModification &&
      !policy.SHOWFULLNAME &&
      policy.LoginwindowText === '' &&
      !policy.ShutDownDisabled &&
      !policy.SleepDisabled &&
      !policy.RestartDisabled &&
      !policy.DisableConsoleAccess
    ) {
      return undefined;
    }
    return {
      allowWallpaperModification: !policy.allowWallpaperModification,
      SHOWFULLNAME: policy.SHOWFULLNAME,
      LoginwindowText: policy.LoginwindowText,
      ShutDownDisabled: policy.ShutDownDisabled,
      SleepDisabled: policy.SleepDisabled,
      RestartDisabled: policy.RestartDisabled,
      DisableConsoleAccess: policy.DisableConsoleAccess,
    };
  }

  const formik = useFormik<ScreenSettingsCombine>({
    initialValues,
    onSubmit: async (form: ScreenSettingsCombine) => {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableScreenSettingPolicy: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="title2">Screen</Typography>
      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextfieldComponent
            label="Login welcome text"
            name="LoginwindowText"
            value={formik.values.LoginwindowText}
            onChange={formik.handleChange}
            error={formik.touched.LoginwindowText && !!formik.errors.LoginwindowText}
            helperText={formik.touched.LoginwindowText && formik.errors.LoginwindowText}
            autoFocus
          />
          <CheckboxComponent
            label="Login window - shows the name and password"
            name="SHOWFULLNAME"
            checked={formik.values.SHOWFULLNAME}
            onChange={(e, checked) => {
              formik.setFieldValue('SHOWFULLNAME', checked);
            }}
          />
          <CheckboxComponent
            label="Hide shut down"
            name="ShutDownDisabled"
            checked={formik.values.ShutDownDisabled}
            onChange={(e, checked) => {
              formik.setFieldValue('ShutDownDisabled', checked);
            }}
          />
          <CheckboxComponent
            label="Hide sleep"
            name="SleepDisabled"
            checked={formik.values.SleepDisabled}
            onChange={(e, checked) => {
              formik.setFieldValue('SleepDisabled', checked);
            }}
          />
          <CheckboxComponent
            label="Hide restart"
            name="RestartDisabled"
            checked={formik.values.RestartDisabled}
            onChange={(e, checked) => {
              formik.setFieldValue('RestartDisabled', checked);
            }}
          />
          <CheckboxComponent
            label="Disable console access"
            name="DisableConsoleAccess"
            checked={formik.values.DisableConsoleAccess}
            onChange={(e, checked) => {
              formik.setFieldValue('DisableConsoleAccess', checked);
            }}
          />
          <CheckboxComponent
            label="Disable wallpaper change"
            name="allowWallpaperModification"
            checked={formik.values.allowWallpaperModification}
            onChange={(e, checked) => {
              formik.setFieldValue('allowWallpaperModification', checked);
            }}
          />
          <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: 2 }}>
            <ButtonComponent
              onClick={() => formik.handleSubmit()}
              fullWidth
              colorVariant="primary"
              sizeVariant="medium"
            >
              Save
            </ButtonComponent>
          </Box>
        </Box>
      </FormikProvider>
    </Box>
  );
};
