import { useMemo, useState } from 'react';

import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { PolicyNotificationsEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-notifications-edit-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { useSlackHook } from '@/api-client/slack.hook';

interface AbsencePolicyGeneralSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const AbsencePolicyNotificationsSection = ({ absencePolicy, refresh }: AbsencePolicyGeneralSectionProps) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const { slackChannels } = useSlackHook();

  const remindersChannels = useMemo(
    () =>
      [absencePolicy.remindersSettings.slack ? polyglot.t('NotificationsSectionReadonly.slackChannel') : null]
        .filter(Boolean)
        .join(', '),
    [absencePolicy?.remindersSettings, polyglot]
  );

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyNotificationsSection.notifications')}
      onEdit={() => setIsEditDrawerOpen(true)}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyNotificationsSection.notifications'),
                value: absencePolicy.notificationsEnabled
                  ? polyglot.t('AbsencePolicyNotificationsSection.enabled')
                  : polyglot.t('AbsencePolicyNotificationsSection.disabled'),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyNotificationsSection.reminders'),
                value: remindersChannels,
                hidden: remindersChannels.length === 0,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('NotificationsSectionReadonly.slackchannels'),
                value: absencePolicy.remindersSettings.slackChannels
                  .map((channelId) => slackChannels.find((channel) => channel.id === channelId)?.name ?? '???')
                  .join(', '),
                hidden: !absencePolicy.remindersSettings.slack,
              },
            ],
          },
        ]}
      />

      <PolicyNotificationsEditDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
        slackChannels={slackChannels}
      />
    </SettingsSectionContent>
  );
};
