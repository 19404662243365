import React, { Suspense } from 'react';

import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DisableMFAPage } from '@/v2/feature/user/features/mfa/disable-mfa.page';
import { themeColors } from '@/v2/styles/colors.styles';

interface Props {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DisableMFADrawer = ({ isOpen, setIsOpen }: Props): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <DisableMFAPage onClose={() => setIsOpen(false)} />
    </Suspense>
  </DrawerModal>
);
