import React, { ReactNode } from 'react';

import { Box, CircularProgress, CircularProgressProps, SxProps } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';

const sizeMapping = {
  xxs: 1,
  xs: 4,
  sm: 6,
  md: 8,
  lg: 12,
  xl: 16,
} as const;

type Size = keyof typeof sizeMapping;

const yMarginMapping = {
  xxs: 1,
  xs: 2,
  sm: 4,
  md: 8,
  lg: 16,
  xl: 20,
} as const;

type YMargin = keyof typeof yMarginMapping;

interface LoadingSpinnerProps {
  readonly size?: Size;
  readonly yMargin?: YMargin;
  readonly color?: string;
}

export const LoadingSpinner = ({ size = 'lg', yMargin = 'lg' }: LoadingSpinnerProps): JSX.Element => (
  <Box
    sx={{
      mt: yMarginMapping[yMargin],
      color: themeColors.ZeltYellow,
      display: 'flex',
      placeContent: 'center',
    }}
  >
    <CircularProgress
      sx={{
        height: sizeMapping[size],
        width: sizeMapping[size],
        color: themeColors.ZeltYellow,
      }}
    />
  </Box>
);

interface LoaderProps {
  readonly loading: boolean;
  readonly children: ReactNode;
  readonly yMargin?: YMargin;
  readonly size?: Size;
  readonly sx?: SxProps;
  readonly hideFooter?: boolean;
}

export const Loader = ({
  loading,
  children,
  size = 'lg',
  yMargin = 'lg',
  sx = {},
  hideFooter,
}: LoaderProps): React.JSX.Element => (
  <Box
    sx={{
      minHeight: hideFooter ? '100vh' : 'auto',
      height: hideFooter ? 'inherit' : '100%',
      display: 'flex',
      flexFlow: 'column',
      flex: 1,
      ...sx,
    }}
  >
    {loading && <LoadingSpinner size={size} yMargin={yMargin} />}
    {!loading && children}
    {!hideFooter && <Box sx={{ flex: '0 0 40px' }} />} {/* footer */}
  </Box>
);

interface SmallLoaderProps {
  readonly loading: boolean;
  readonly children: ReactNode;
  readonly sx?: SxProps;
  readonly spinnerSx?: SxProps;
  readonly size?: CircularProgressProps['size'];
}

export const SmallLoader = ({ loading, children, size, sx = {}, spinnerSx = {} }: SmallLoaderProps) => {
  return loading ? (
    <Box sx={{ mt: 2, display: 'flex', placeContent: 'center', ...spinnerSx }}>
      <CircularProgress size={size} sx={{ color: themeColors.ZeltYellow }} />
    </Box>
  ) : (
    <Box sx={sx}>{children}</Box>
  );
};
