import React, { useCallback, useEffect, useState } from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceModelDto, DeviceOrderDto } from '@v2/feature/device/device.dto';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminDeviceDetailsPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-details.page';
import { SuperAdminDeviceModelsPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-models.page';
import { SuperAdminDeviceOrdersPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-orders.page';
import { SuperAdminDevicesOverviewPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-overview.page';
import { SuperAdminDeviceStockPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-stock.page';
import { SuperAdminDevicesTransitsPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-transits.page';
import { SuperAdminInHouseMdmDevicesOverviewPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-in-house-mdm-devices-overview.page';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { keyBy } from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_DEVICES_ROUTE,
  SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE,
  SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE,
  SUPER_ADMIN_DEVICES_MODELS_ROUTE,
  SUPER_ADMIN_DEVICES_ORDERS_ROUTE,
  SUPER_ADMIN_DEVICES_OVERVIEW_ROUTE,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_DEVICES_STOCK_ROUTE,
  SUPER_ADMIN_DEVICES_TRANSITS_ROUTE,
  SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE,
} from '@/lib/routes';

const DEVICES_PAGE_CONFIG = {
  header: {
    settingsPath: SETTINGS_DEVICES_ROUTE,
    tabs: [
      { label: 'In Use', value: SUPER_ADMIN_DEVICES_OVERVIEW_ROUTE, scopes: ['devices'] },
      { label: 'In-house MDM', value: SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE, scopes: ['devices'] },
      { label: 'Inventory', value: SUPER_ADMIN_DEVICES_STOCK_ROUTE, scopes: ['devices'] },
      { label: 'Orders', value: SUPER_ADMIN_DEVICES_ORDERS_ROUTE, scopes: ['devices'] },
      { label: 'Transits', value: SUPER_ADMIN_DEVICES_TRANSITS_ROUTE, scopes: ['devices'] },
      { label: 'Shop', value: SUPER_ADMIN_DEVICES_MODELS_ROUTE, scopes: ['devices'] },
    ],
  },
} as const;

interface SuperAdminDevicesRouterProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const SuperAdminDevicesRouter = ({ companies, users }: SuperAdminDevicesRouterProps): JSX.Element => {
  const activeCompanies = companies.filter((company) => company.isActive);
  const [deviceModels, setDeviceModels] = useState<readonly DeviceModelDto[]>([]);
  const [stockDevices, setStockDevices] = useState<readonly DeviceDto[]>([]);
  const [deviceOrders, setDeviceOrders] = useState<readonly DeviceOrderDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [sitesById, setSitesById] = useState<{ [id: number]: SiteDto }>({});

  const getAllSites = useCallback(async () => {
    try {
      const sites = await SiteAPI.listSitesAsSuperAdmin();
      setSitesById(keyBy(sites, 'id'));
    } catch (error) {
      showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  const refreshDeviceOrders = useCallback(async () => {
    try {
      setLoading(true);
      const deviceOrders = await DeviceAPI.getDeviceOrdersAsSuperadmin();
      setDeviceOrders(deviceOrders);
    } catch (error) {
      showMessage(`Could not retrieve inventory devices list. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  const refreshDevicesStockAndModels = useCallback(async () => {
    try {
      setLoading(true);
      const [deviceModels, devicesStock] = await Promise.all([
        DeviceAPI.getDeviceModels(true),
        DeviceAPI.getStockDevicesAsSuperadmin(),
      ]);
      setDeviceModels(deviceModels);
      setStockDevices(devicesStock);
    } catch (error) {
      showMessage(`Could not retrieve devices list. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    Promise.all([refreshDevicesStockAndModels(), getAllSites(), refreshDeviceOrders()]);
  }, [getAllSites, refreshDevicesStockAndModels, refreshDeviceOrders]);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE}>
        <SuperAdminInHouseMdmDevicesOverviewPage
          users={users}
          companies={activeCompanies}
          sites={sitesById}
          pageConfig={DEVICES_PAGE_CONFIG}
        />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_MODELS_ROUTE}>
        <SuperAdminDeviceModelsPage
          deviceModels={deviceModels}
          stockDevices={stockDevices}
          pageConfig={DEVICES_PAGE_CONFIG}
          loading={loading}
          refresh={refreshDevicesStockAndModels}
        />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_ORDERS_ROUTE}>
        <SuperAdminDeviceOrdersPage
          users={users}
          companies={companies}
          deviceModels={deviceModels}
          deviceOrders={deviceOrders}
          pageConfig={DEVICES_PAGE_CONFIG}
          refreshDeviceOrders={refreshDeviceOrders}
          sites={sitesById}
        />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_TRANSITS_ROUTE}>
        <SuperAdminDevicesTransitsPage
          users={users}
          companies={activeCompanies}
          sites={sitesById}
          pageConfig={DEVICES_PAGE_CONFIG}
        />
      </Route>
      <Route path={[SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE]}>
        <SuperAdminDeviceDetailsPage />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_STOCK_ROUTE}>
        <SuperAdminDeviceStockPage
          deviceModels={deviceModels}
          stockDevices={stockDevices}
          pageConfig={DEVICES_PAGE_CONFIG}
          loading={loading}
          refresh={refreshDevicesStockAndModels}
        />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_OVERVIEW_ROUTE}>
        <SuperAdminDevicesOverviewPage
          users={users}
          companies={activeCompanies}
          sites={sitesById}
          deviceOrders={deviceOrders}
          pageConfig={DEVICES_PAGE_CONFIG}
        />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_ROUTE}>
        <Redirect to={SUPER_ADMIN_DEVICES_OVERVIEW_ROUTE} />
      </Route>
    </Switch>
  );
};
