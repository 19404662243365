import React, { useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { PayrunButtons } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-buttons.component';
import { ExternalPayRunDto } from '@/v2/feature/payroll/payroll-external.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

type PayrunReviewLineProps = {
  label: string;
  value: string;
  sx?: SxProps<Theme>;
};

const PayrunReviewLine = ({ label, value, sx }: PayrunReviewLineProps) => {
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{ py: spacing.p15, pr: '120px', borderBottom: `1px solid ${themeColors.lightGrey}` }}
    >
      <Typography sx={{ color: themeColors.DarkGrey, ...themeFonts.caption, ...sx }}>{label}</Typography>
      <Typography sx={{ color: themeColors.DarkGrey, ...themeFonts.caption, ...sx }}>{value}</Typography>
    </Stack>
  );
};

type PayrunReviewSectionProps = {
  payrollId: number;
  payrun: ExternalPayRunDto;
  payrunEmpAllowance: number;
  onBack?: () => void;
  onNext?: () => void;
  startPayrunProcess: (payrollId: number, payrun: ExternalPayRunDto, paymentDate: string) => Promise<boolean>;
  sx?: SxProps<Theme>;
};

export const PayrunReviewSection = ({
  payrollId,
  payrun,
  payrunEmpAllowance,
  onBack,
  onNext,
  startPayrunProcess,
  sx,
}: PayrunReviewSectionProps) => {
  const [startingPayrunProcess, setStartingPayrunProcess] = useState(false);
  const [paymentDate, setPaymentDate] = useState<string>(payrun.paymentDate);

  const {
    totalCost = 0,
    takeHomePay = 0,
    tax = 0,
    employerNi = 0,
    employeeNi = 0,
    studentLoanRecovered = 0,
    postgradLoanRecovered = 0,
    employeePensionContribution = 0,
    employerPensionContribution = 0,
  } = payrun.totals;
  const totalPension = employeePensionContribution + employerPensionContribution;
  const totalHMRC = tax + employerNi + employeeNi + studentLoanRecovered + postgradLoanRecovered - payrunEmpAllowance;
  const totalEmployerCost = totalCost - payrunEmpAllowance;
  const lines: Record<string, { value: number; style?: SxProps<Theme> }> = {
    'Pay to Employees': { value: takeHomePay, style: themeFonts.title4 },
    'Pay to HMRC': { value: totalHMRC, style: themeFonts.title4 },
    'PAYE Tax': { value: tax },
    'Employer NI': { value: employerNi },
    'Employee NI': { value: employeeNi },
    'Student loans': { value: studentLoanRecovered + postgradLoanRecovered },
    'Employment allowance': { value: -payrunEmpAllowance }, // subtraction
    'Pay to Pension': { value: totalPension, style: themeFonts.title4 },
    'Employer contributions': { value: employerPensionContribution },
    'Employee contributions': { value: employeePensionContribution },
    'Total Cost': { value: totalEmployerCost, style: themeFonts.title4 },
  };

  if (!payrunEmpAllowance) {
    delete lines['Employment allowance'];
  }

  return (
    <Stack sx={{ flex: 1, overflowY: 'auto', ...sx }}>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
        Review the total cost of this payrun
      </Typography>
      <Stack sx={{ mt: spacing.mt20, maxWidth: '600px' }}>
        {Object.entries(lines).map(([label, { value, style }]) => (
          <PayrunReviewLine
            key={label}
            label={label}
            value={formatCurrency(value) || ''}
            sx={{ ...style, color: themeColors.DarkGrey }}
          />
        ))}
      </Stack>

      <Stack sx={{ mt: spacing.mt40, gap: spacing.g10, maxWidth: '450px' }}>
        {payrun.dateClosed ? (
          <Typography
            sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}
          >{`This payrun was closed on ${new Date(payrun.dateClosed).toLocaleDateString()}`}</Typography>
        ) : (
          <>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Payrun payment date</Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              Enter the date on which you intend to pay your employees. This information is submitted to HMRC.
            </Typography>
            <DatePickerComponent
              name="payrun-payment-date"
              label="Payment date"
              onChange={(value) => {
                setPaymentDate(value);
              }}
              value={paymentDate}
              disabled={startingPayrunProcess}
              error={undefined}
              helperText={undefined}
            />
          </>
        )}
      </Stack>
      <PayrunButtons
        primaryButtonLabel={payrun.isClosed ? 'View payrun' : 'Process payrun'}
        showBack
        onBackClick={onBack}
        onPrimaryClick={async () => {
          if (payrun.isClosed) {
            onNext?.();
            return;
          }
          if (!paymentDate) return;
          setStartingPayrunProcess(true);
          const payrunStarted = await startPayrunProcess(payrollId, payrun, paymentDate);
          setStartingPayrunProcess(false);
          if (!payrunStarted) {
            return;
          }
          onNext?.();
        }}
        loadingPrimary={startingPayrunProcess}
        disabledPrimary={!paymentDate || startingPayrunProcess}
        disabledBack={startingPayrunProcess}
        sx={{ my: spacing.my40 }}
      />
    </Stack>
  );
};
