import { Dispatch, SetStateAction, useMemo } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider } from 'formik';

import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TimeTextField } from '@/v2/components/forms/time-text-field.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  getDeadlineByReviewerFields,
  ShowFlags,
} from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util';
import { useTimelineForm } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/hook/use-timeline-form.hook';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const TimelineScheduledModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  showFlags,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
  showFlags: ShowFlags;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <TimelineScheduledContent reviewCycle={reviewCycle} onClose={onClose} refresh={refresh} showFlags={showFlags} />
    </DrawerModal>
  );
};

const TimelineScheduledContent = ({
  reviewCycle,
  onClose,
  refresh,
  showFlags,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  showFlags: ShowFlags;
}) => {
  const { polyglot } = usePolyglot();
  const formik = useTimelineForm(reviewCycle, onClose, refresh, showFlags);

  const canUpdateStartDate = useMemo(
    () => reviewCycle?.state === CycleState.Draft || reviewCycle?.state === CycleState.Scheduled,
    [reviewCycle?.state]
  );

  const Views = [
    { name: 'Start now', value: 'true' },
    { name: 'Schedule for later', value: 'false' },
  ];
  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Timeline</Typography>

        {canUpdateStartDate && (
          <Box sx={{ mt: spacing.m5, mb: spacing.m10 }}>
            <TabFilterButtons
              filters={Views}
              setFilterValue={(value: string) => {
                if (value === 'true') {
                  formik.setFieldValue('timelineSettings.startDate', null);
                  formik.setFieldValue('timelineSettings.startTime', null);
                  formik.setFieldValue('timelineSettings.startTimeMeridiem', null);
                }

                formik.setFieldValue('timelineSettings.startNow', value === 'true');
              }}
              filterValue={formik.values.timelineSettings.startNow.toString()}
            />
          </Box>
        )}

        {canUpdateStartDate && !formik.values.timelineSettings.startNow && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
            <DatePickerComponent
              name="timelineSettings.startDate"
              label="Start date (GMT)"
              sx={{ width: '100%' }}
              disablePast
              value={formik.values.timelineSettings.startDate}
              onChange={(date) => formik.setFieldValue('timelineSettings.startDate', date)}
            />
          </Box>
        )}

        {canUpdateStartDate && !formik.values.timelineSettings.startNow && (
          <TimeTextField
            name="timelineSettings.startTime"
            label="Start time (GMT)"
            value={formik.values.timelineSettings.startTime}
            onChange={(value) => {
              formik.setFieldValue('timelineSettings.startTime', value);
            }}
            selectedMeridiem={formik.values.timelineSettings.startTimeMeridiem}
            sx={{ width: '100%' }}
            onMeridiemChange={(newMeridiem) => {
              formik.setFieldValue('timelineSettings.startTimeMeridiem', newMeridiem);
            }}
            error={formik.touched.timelineSettings?.startTime && Boolean(formik.errors.timelineSettings?.startTime)}
            helperText={
              (formik.touched.timelineSettings?.startTime && formik.errors.timelineSettings?.startTime) as string
            }
          />
        )}

        {getDeadlineByReviewerFields(showFlags, formik, polyglot)}
      </Form>
    </FormikProvider>
  );
};
