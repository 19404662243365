import { useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { StepperHeader } from '@/v2/components/stepper-header.component';
import { StepperComponent } from '@/v2/components/stepper.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { ExecutionDateStep } from '@/v2/feature/payments/features/make-payment/components/execution-date-step.component';
import { PayerDetailsStep } from '@/v2/feature/payments/features/make-payment/components/payer-details-step.component';
import { PaymentResultStep } from '@/v2/feature/payments/features/make-payment/components/payment-result-step.component';
import { PaymentYapilyConnectStep } from '@/v2/feature/payments/features/make-payment/components/payment-yapily-connect-step.component';
import { PAYMENTS_ROUTE } from '@/v2/feature/payments/payments.router';
import { spacing } from '@/v2/styles/spacing.styles';

export const PaymentFlowPage = ({ showPayerDetails }: { showPayerDetails: boolean }) => {
  const routerHistory = useHistory();
  const paymentSteps = useMemo(() => [...(showPayerDetails ? ['Details'] : []), 'Date', 'Confirm', 'Success'], [
    showPayerDetails,
  ]);
  const [step, setStep] = useState<string>(showPayerDetails ? 'Details' : 'Date');
  const [unsafeClose, setUnsafeClose] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const paymentStepsComponent = useMemo(
    () => [
      ...(showPayerDetails
        ? [{ name: 'Details', component: <PayerDetailsStep onNext={() => setStep('Date')} /> }]
        : []),
      { name: 'Date', component: <ExecutionDateStep onNext={() => setStep('Confirm')} /> },
      {
        name: 'Confirm',
        component: <PaymentYapilyConnectStep onNext={() => setStep('Success')} setUnsafeClose={setUnsafeClose} />,
      },
      { name: 'Success', component: <PaymentResultStep /> },
    ],
    [showPayerDetails]
  );

  return (
    <Stack sx={{ flex: 1 }}>
      <StepperHeader
        content={<StepperComponent steps={paymentSteps} activeStep={step} />}
        contentSx={{ width: '80%', textAlign: 'center' }}
        showStepBack={true}
        stepBackAction={() => {
          if (paymentSteps.indexOf(step) > 0) return setStep(paymentSteps[paymentSteps.indexOf(step) - 1]);
          routerHistory.goBack();
        }}
        stepBackSx={{
          width: '10%',
          textAlign: 'left',
        }}
        showBack={true}
        backAction={(e) => {
          if (unsafeClose) {
            setAnchorEl((e as React.MouseEvent<HTMLButtonElement, MouseEvent>).currentTarget);
          } else routerHistory.push(PAYMENTS_ROUTE);
        }}
        backSx={{ width: '10%', textAlign: 'right' }}
      />
      <Box sx={{ mt: spacing.mt40 }} />

      {paymentStepsComponent[paymentSteps.indexOf(step)]?.component}

      <NotificationModal
        isOpen={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        takeAction={async () => routerHistory.push(PAYMENTS_ROUTE)}
        message={`Are you sure you want to close this page? The payment is still being processed. Closing may cause errors.`}
        callToAction="Yes"
      />
    </Stack>
  );
};
