import { Box, Typography } from '@mui/material';
import { InsurancePolicyDto, UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
import { INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE, INSURANCE_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeColors } from '@/v2/styles/colors.styles';

interface InsuranceProviderDetailsProps {
  readonly insurancePolicy: InsurancePolicyDto;
  readonly userInsurance?: UserInsuranceDto;
}

export const InsuranceProviderDetails = ({ insurancePolicy, userInsurance }: InsuranceProviderDetailsProps) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: spacing.gap40 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap10 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Provider</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {insurancePolicy.providerName}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap10 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Policy start</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {new Date(userInsurance?.startDate ?? insurancePolicy.startDate).toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap10 }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Policy end</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {new Date(insurancePolicy.endDate).toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Typography>
          </Box>
        </Box>

        {matchPath(location.pathname, INSURANCE_COMPANY_OVERVIEW_ROUTE) && (
          <ScopesControl scopes={['insurance:all']}>
            <Box>
              <ButtonComponent
                sizeVariant="small"
                colorVariant="primary"
                onClick={() => history.push(INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE)}
              >
                Edit Employees
              </ButtonComponent>
            </Box>
          </ScopesControl>
        )}
      </Box>

      <Typography sx={{ ...themeFonts.title2, mt: spacing.mt40, mb: spacing.mb20, color: themeColors.DarkGrey }}>
        Contact Details
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap15 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.caption, width: '200px', color: themeColors.DarkGrey }}>Claims</Typography>
          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
            {insurancePolicy.claimsPhone}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.caption, width: '200px', color: themeColors.DarkGrey }}>Support</Typography>
          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
            {insurancePolicy.supportPhone}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.caption, width: '200px', color: themeColors.DarkGrey }}>
            Insurance broker
          </Typography>
          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
            {insurancePolicy.insuranceBrokerPhone}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
