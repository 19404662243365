import { useState } from 'react';

import { KeyedMutator } from 'swr';

import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SCScheduledTimelineModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/components/scheduled/sc-scheduled-timeline-modal.component';
import {
  SurveyCycle,
  SurveyFrequencyEnum,
  SurveyFrequencyValues,
  SurveyStartOptionsEnum,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

interface ISCScheduledTimelineView {
  readonly isEditable: boolean;
  readonly surveyCycle: SurveyCycle;
  readonly refreshCycle: KeyedMutator<SurveyCycle> | undefined;
}

export const SCScheduledTimelineView = ({ isEditable, surveyCycle, refreshCycle }: ISCScheduledTimelineView) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { polyglot } = usePolyglot();

  return (
    <>
      <SettingsSubsectionContent
        sections={[
          {
            title: 'Timeline',
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption" sx={{ pb: spacing.s2 }}>
                    Timeline defines the end date of the survey cycle for all participants. All invites will expire on
                    the last day of the cycle.
                  </Typography>
                ),
              },
              ...(surveyCycle?.timelineSettings?.startNow && isEditable && surveyCycle.state !== CycleState.Ongoing
                ? [
                    {
                      type: SectionItemType.Pair,
                      label: 'Start options',
                      value: SurveyStartOptionsEnum.now,
                    },
                  ]
                : [
                    {
                      type: SectionItemType.Pair,
                      label: 'Start date (GMT)',
                      value: surveyCycle?.timelineSettings?.startDate
                        ? new LocalDate(surveyCycle?.timelineSettings?.startDate)
                            .getDate()
                            .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })
                        : 'NA',
                    },
                    {
                      type: SectionItemType.Pair,
                      label: 'Start time (GMT)',
                      value:
                        surveyCycle?.timelineSettings?.startTime && surveyCycle?.timelineSettings?.startTimeMeridiem
                          ? `${surveyCycle?.timelineSettings?.startTime} ${surveyCycle?.timelineSettings?.startTimeMeridiem}`
                          : 'NA',
                    },
                  ]),
              {
                type: SectionItemType.Pair,
                label: polyglot.t('CycleSettingTimelineSection.surveyOpenFor'),
                value: `${surveyCycle?.timelineSettings?.surveyOpenFor ?? '7'} days`,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('CycleSettingTimelineSection.surveyFrequency'),
                value:
                  surveyCycle?.timelineSettings?.surveyFrequency === SurveyFrequencyValues.once
                    ? SurveyFrequencyEnum.once
                    : SurveyFrequencyEnum.recurring,
              },
            ],
          },
        ]}
      />

      <SCScheduledTimelineModal
        surveyCycle={surveyCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refreshCycle?.();
        }}
      />
    </>
  );
};
