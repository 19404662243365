import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { AttendanceAPI, AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceStatus, ScheduleTrackingType } from '@v2/feature/attendance/attendance.interface';
import { getWeekDates, getWeekStatusFromAttendanceStatuses } from '@v2/feature/attendance/attendance.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as Reject } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Star } from '@/images/side-bar-icons/Star.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';

interface SubmitWeekRequestsButtonProps {
  readonly userId: number;
  readonly year: number;
  readonly weekNo: number;
  readonly weekStatuses: readonly AttendanceStatus[];
  readonly refreshRequests: () => Promise<void>;
  readonly trackingType: ScheduleTrackingType | undefined;
  readonly isClockInSchedule?: boolean;
}

export const SubmitWeekRequestsButtonComponent = ({
  userId,
  year,
  weekNo,
  weekStatuses,
  refreshRequests,
  trackingType,
  isClockInSchedule = false,
}: SubmitWeekRequestsButtonProps) => {
  const { polyglot } = usePolyglot();
  const { data: approvalData } = useApiClient(AttendanceEndpoints.canApproveRejectSchedule(userId), {
    suspense: false,
  });

  const { hasScopes, getScopesContext } = useScopes();
  const canManage = hasScopes(['attendance:manager'], getScopesContext({ userId }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mode, setMode] = useState<'submit' | 'approve' | 'reject' | 'delete' | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setMode(null);
  };

  const [showMessage] = useMessage();

  const { canApproveOrReject, isAutoapprove } = useMemo(
    () =>
      approvalData ?? {
        canApproveOrReject: false,
        isAutoapprove: false,
      },
    [approvalData]
  );

  const weekStatus = useMemo(() => {
    const weekStatusSet = new Set(weekStatuses);
    return getWeekStatusFromAttendanceStatuses(weekStatusSet);
  }, [weekStatuses]);

  const submitWeekRequests = useCallback(async () => {
    try {
      await AttendanceAPI.submitUserAttendanceByWeek(userId, year, weekNo);
      showMessage(polyglot.t('AttendanceDomain.entriesSubmitted'), 'success');
      await refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotSubmitEntries', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [userId, year, weekNo, refreshRequests, showMessage, polyglot]);

  const approveSubmission = useCallback(async () => {
    try {
      await AttendanceAPI.approveUserAttendanceByWeek(userId, year, weekNo);
      showMessage(polyglot.t('AttendanceDomain.submissionApproved'), 'success');
      await refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotApproveSubmission', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, userId, year, weekNo, refreshRequests, showMessage]);

  const deleteWeekRequests = useCallback(async () => {
    try {
      await AttendanceAPI.deleteUserAttendancesByWeek(userId, year, weekNo);
      showMessage(polyglot.t('AttendanceDomain.submissionDeleted'), 'success');
      await refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotDeleteSubmission', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, userId, year, weekNo, refreshRequests, showMessage]);

  const rejectSubmission = useCallback(async () => {
    try {
      await AttendanceAPI.rejectUserAttendanceByWeek(userId, year, weekNo);
      showMessage(polyglot.t('AttendanceDomain.submissionRejected'), 'success');
      await refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotRejectSubmission', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, userId, year, weekNo, refreshRequests, showMessage]);

  const autopopulateFromSchedule = useCallback(async () => {
    const weekDates = getWeekDates(year, weekNo);
    const monday = weekDates[0].value;
    try {
      await AttendanceAPI.autopopulateUserAttendancesByWeek(userId, weekNo, year, monday);
      await refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.somethingWentWrong', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, userId, weekNo, year, showMessage, refreshRequests]);

  const copyFromLastWeek = useCallback(async () => {
    try {
      await AttendanceAPI.copyUserAttendancesFromLastWeek(userId, weekNo, year);
      await refreshRequests();
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.somethingWentWrong', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, userId, weekNo, year, showMessage, refreshRequests]);

  const showActions = canManage || (canApproveOrReject && !isAutoapprove);

  return (
    <Box sx={{ display: 'flex', gap: spacing.g10 }}>
      {weekStatuses.length > 0 && (canApproveOrReject || weekStatus === AttendanceStatus.InProgress) && (
        <StyledMenuComponent
          options={[
            {
              icon: <Trash {...iconSize} />,
              handler: deleteWeekRequests,
              label: polyglot.t('AttendanceDomain.clear'),
              hidden: !showActions,
            },
            ...(canApproveOrReject && weekStatus === AttendanceStatus.Submitted
              ? [
                  {
                    icon: <Reject {...iconSize} />,
                    handler: async () => await rejectSubmission(),
                    label: polyglot.t('AttendanceDomain.rejectAll'),
                  },
                ]
              : []),
          ]}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: polyglot.t('General.actions'),
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />
      )}

      {trackingType &&
        trackingType !== ScheduleTrackingType.ClockInClockOut &&
        weekStatuses.length === 0 &&
        weekStatus === 'none' && (
          <Box>
            <StyledMenuComponent
              options={[
                {
                  icon: <Star {...iconSize} />,
                  handler: autopopulateFromSchedule,
                  label: polyglot.t('AttendanceDomain.autopopulate'),
                  hidden: !canApproveOrReject && isClockInSchedule,
                },
                {
                  icon: <Copy {...iconSize} />,
                  handler: copyFromLastWeek,
                  label: polyglot.t('AttendanceDomain.copyFromLastWeek'),
                  hidden: !canApproveOrReject && isClockInSchedule,
                },
              ]}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: polyglot.t('General.actions'),
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
              }}
            />
          </Box>
        )}
      {weekStatus === AttendanceStatus.InProgress && (
        <ButtonComponent
          sizeVariant="small"
          colorVariant="primary"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setMode('submit');
          }}
        >
          {polyglot.t('General.submit')}
        </ButtonComponent>
      )}
      {canApproveOrReject && weekStatus === AttendanceStatus.Submitted && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setMode('approve');
            }}
          >
            {polyglot.t('AttendanceDomain.approveAll')}
          </ButtonComponent>
        </Box>
      )}
      <NotificationModal
        isOpen={mode !== null}
        onClose={() => handleClose()}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (mode === 'submit') await submitWeekRequests();
          if (mode === 'approve') await approveSubmission();
          setMode(null);
        }}
        message={
          mode === 'submit'
            ? polyglot.t('AttendanceDomain.wantToSubmit')
            : mode === 'approve'
            ? polyglot.t('AttendanceDomain.wantToApprove')
            : ''
        }
        callToAction={polyglot.t('General.yes')}
      />
    </Box>
  );
};
