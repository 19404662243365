import React from 'react';

import { Box } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const ParticipantsEmptyState = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <ButtonComponent
        sizeVariant="small"
        colorVariant="primary"
        onClick={() => setIsOpen(true)}
        aria-label="Add Participants"
      >
        Add participants
      </ButtonComponent>
    </Box>
  );
};
