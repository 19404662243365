import { useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { AbsencePolicyAttachmentType, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { PolicyGeneralEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-general-edit-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { AbsenceAPI } from '@/v2/feature/absence/absence.api';
import { AbsencePolicyArchiveConfirmationDrawer } from '@/v2/feature/absence/subfeatures/settings/components/absence-policy-archive-confirmation-drawer.component';
import { AbsencePolicyDeleteConfirmationDrawer } from '@/v2/feature/absence/subfeatures/settings/components/absence-policy-delete-confirmation-drawer.component';
import { AbsencePolicyUnarchiveConfirmationDrawer } from '@/v2/feature/absence/subfeatures/settings/components/absence-policy-unarchive-confirmation-drawer.component';
import { UpdatePolicyInstructionsDrawer } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/update-policy-instructions-drawer.component';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

function translateAttachmentValue(attachmentType: AbsencePolicyAttachmentType | null, polyglot: Polyglot): string {
  if (attachmentType === AbsencePolicyAttachmentType.Required) return polyglot.t('General.required');
  if (attachmentType === AbsencePolicyAttachmentType.Optional) return polyglot.t('General.optional');

  return polyglot.t('General.none');
}

interface AbsencePolicyGeneralSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refreshPolicy: () => Promise<void>;
  readonly refreshPolicies: () => Promise<void>;
  readonly refreshArchivedPolicies: () => Promise<void>;
}

export const AbsencePolicyGeneralSection = ({
  absencePolicy,
  refreshPolicy,
  refreshPolicies,
  refreshArchivedPolicies,
}: AbsencePolicyGeneralSectionProps) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);
  const [isArchiveDrawerOpen, setIsArchiveDrawerOpen] = useState<boolean>(false);
  const [isUnarchiveDrawerOpen, setIsUnarchiveDrawerOpen] = useState<boolean>(false);
  const [isUpdateInstructionsOpen, setIsUpdateInstructionsOpen] = useState<boolean>(false);
  const [requestsCount, setRequestsCount] = useState<number>(1);

  const [showMessage] = useMessage();

  const policyCyclePeriod = useMemo(() => {
    if (!absencePolicy) return '';

    const startDate = new Date(
      new Date().getFullYear(),
      absencePolicy.cycleStartMonth - 1,
      absencePolicy.cycleStartDay
    );

    const endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);

    const startDateString = startDate.toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
    const endDateString = endDate.toLocaleDateString(undefined, { month: 'long', day: 'numeric' });

    return `${startDateString} - ${endDateString}`;
  }, [absencePolicy]);

  useEffect(() => {
    (async () => {
      if (!absencePolicy) {
        setRequestsCount(0);
        return;
      }
      try {
        const requestsCount = await AbsenceAPI.getCountOfPolicyRequests(absencePolicy.id);
        setRequestsCount(requestsCount);
      } catch (error) {
        showMessage(
          polyglot.t('PolicyGeneralEditDrawer.couldNotGetCount', {
            name: absencePolicy.name,
            nestError: nestErrorMessage(error),
          }),
          'error'
        );
      }
    })();
  }, [polyglot, showMessage, absencePolicy]);

  const buttonsForInstructions: React.ReactNode[] = useMemo(() => {
    const buttons = [
      <IconButton
        sx={tableIconButtonSx}
        onClick={() => {
          setIsUpdateInstructionsOpen(true);
        }}
        title={polyglot.t('General.edit')}
      >
        <EditIcon {...actionIconSize} />
      </IconButton>,
    ];
    if (absencePolicy.instructions) {
      buttons.push(
        <IconButton
          sx={tableIconButtonSx}
          onClick={async () => {
            await AbsenceAPI.deleteAbsencePolicyInstructionsById(absencePolicy.id);
            await refreshPolicy();
            showMessage(polyglot.t('AbsencePolicyGeneralSection.successMessages.deleteInstruction'), 'success');
          }}
          title={polyglot.t('General.remove')}
        >
          <TrashIcon {...actionIconSize} />
        </IconButton>
      );
    }
    return buttons;
  }, [absencePolicy.id, absencePolicy.instructions, polyglot, refreshPolicy, showMessage]);

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyGeneralSection.general')}
      onEdit={() => setIsEditDrawerOpen(true)}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyGeneralSection.color'),
                value: (
                  <Box
                    sx={{
                      bgcolor: absencePolicy.color,
                      width: '20px',
                      height: '20px',
                      borderRadius: '10px',
                    }}
                  />
                ),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyGeneralSection.name'),
                value: absencePolicy.fullName ?? '',
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyGeneralSection.displayName'),
                value: absencePolicy.name,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyGeneralSection.visibility'),
                value: absencePolicy.isPublic
                  ? polyglot.t('AbsencePolicyGeneralSection.public')
                  : polyglot.t('AbsencePolicyGeneralSection.hidden'),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyGeneralSection.cyclePeriod'),
                value: policyCyclePeriod,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyGeneralSection.attachment'),
                value: translateAttachmentValue(absencePolicy.attachmentType, polyglot),
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyGeneralSection.instructions'),
            buttons: buttonsForInstructions,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography
                    variant="caption"
                    color={absencePolicy.instructions ? 'black' : 'grey'}
                    sx={{ fontStyle: absencePolicy.instructions ? 'italic' : '' }}
                  >
                    {absencePolicy.instructions ?? polyglot.t('AbsencePolicyGeneralSection.instructionsEmpty')}
                  </Typography>
                ),
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyGeneralSection.archivePolicy'),
            hidden: !!absencePolicy.deletedAt,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption">
                    {polyglot.t('AbsencePolicyGeneralSection.archiveDescriptionPart1')}
                    <span
                      onClick={() => {
                        setIsArchiveDrawerOpen(true);
                      }}
                      style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {polyglot.t('AttendanceSchedule.here')}
                    </span>
                    {polyglot.t('AbsencePolicyGeneralSection.archiveDescriptionPart2')}
                  </Typography>
                ),
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyGeneralSection.deletePolicy'),
            hidden: !!absencePolicy.deletedAt,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption">
                    {polyglot.t('AbsencePolicyGeneralSection.deletePolicyDescription')}
                    <span
                      onClick={() => {
                        setIsDeleteDrawerOpen(true);
                      }}
                      style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {polyglot.t('AttendanceSchedule.here')}
                    </span>
                  </Typography>
                ),
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyUnarchiveConfirmationDrawer.unarchivePolicy'),
            hidden: !absencePolicy.deletedAt,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption">
                    {polyglot.t('AbsencePolicyGeneralSection.unarchiveDescriptionPart1')}
                    <span
                      onClick={() => {
                        setIsUnarchiveDrawerOpen(true);
                      }}
                      style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {polyglot.t('AttendanceSchedule.here')}
                    </span>
                  </Typography>
                ),
              },
            ],
          },
        ]}
      />

      <PolicyGeneralEditDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        absencePolicy={absencePolicy}
        refreshPolicy={refreshPolicy}
        refreshPolicies={refreshPolicies}
        refreshArchivedPolicies={refreshArchivedPolicies}
      />
      <AbsencePolicyDeleteConfirmationDrawer
        isOpen={isDeleteDrawerOpen}
        setIsOpen={setIsDeleteDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={async () => {
          await Promise.all([refreshPolicy(), refreshPolicies()]);
        }}
        requestsCount={requestsCount}
      />
      <AbsencePolicyArchiveConfirmationDrawer
        isOpen={isArchiveDrawerOpen}
        setIsOpen={setIsArchiveDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={async () => {
          await Promise.all([refreshPolicy(), refreshPolicies(), refreshArchivedPolicies()]);
        }}
      />
      <AbsencePolicyUnarchiveConfirmationDrawer
        isOpen={isUnarchiveDrawerOpen}
        setIsOpen={setIsUnarchiveDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={async () => {
          await Promise.all([refreshPolicy(), refreshPolicies(), refreshArchivedPolicies()]);
        }}
      />
      <UpdatePolicyInstructionsDrawer
        isOpen={isUpdateInstructionsOpen}
        onClose={() => setIsUpdateInstructionsOpen(false)}
        setIsOpen={setIsUpdateInstructionsOpen}
        absencePolicy={absencePolicy}
        refresh={async () => {
          await refreshPolicy();
        }}
      />
    </SettingsSectionContent>
  );
};
