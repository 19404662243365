import { useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DocumentTypeAPI } from '@/v2/feature/documents/document-type.api';
import {
  getDocumentTypeAvailableToOptions,
  NewDocumentTypeFormValues,
} from '@/v2/feature/documents/documents.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { LocalDate } from '@/v2/util/local-date';

interface Props {
  readonly initialValues?: NewDocumentTypeFormValues;
  readonly onClose: () => void;
  readonly refreshDocumentTypes: () => void;
  readonly mode: 'edit' | 'create';
}

export const DocumentSettingsNewTypeForm = ({
  initialValues,
  onClose,
  refreshDocumentTypes,
  mode = 'create',
}: Props): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const defaultInitialValues = {
    id: 0,
    documentTypeName: '',
    value: '',
    availableTo: undefined,
  };

  const formik = useFormik<NewDocumentTypeFormValues>({
    initialValues: initialValues ?? defaultInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      documentTypeName: Yup.string().required('Required'),
      availableTo: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // logic for all empty values are filled in backend via create service method
        // based on supplied values
        const customDocumentTypePayload: ZeltDocumentType = {
          id: values.id,
          value: values.value,
          label: values.documentTypeName,
          category:
            values.availableTo === 'company'
              ? 'Company Documents'
              : values.availableTo === 'personal'
              ? 'Personal Documents'
              : 'Other',
          availableTo: values?.availableTo,
          icon: '',
          bgColor: '',
          canUserAdd: false,
          onePersonOnly: false,
          canBelongToEveryone: false,
          onboardingDocument: false,
          needsVerification: false,
          default: false,
          visible: true,
          createdAt: new LocalDate().toDateString(),
        };
        if (mode === 'create') {
          await DocumentTypeAPI.createNewCustomDocumentType(customDocumentTypePayload);
          showMessage(polyglot.t('DocumentSettingsNewTypeForm.successMessages.create'), 'success');
        } else {
          await DocumentTypeAPI.updateCustomDocumentType(customDocumentTypePayload);
          showMessage(polyglot.t('DocumentSettingsNewTypeForm.successMessages.update'), 'success');
        }
        refreshDocumentTypes();
        onClose();
      } catch (error) {
        showMessage(
          polyglot.t('DocumentSettingsNewTypeForm.errorMessages.fail', {
            mode: mode,
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {mode === 'edit'
            ? polyglot.t('DocumentSettingsNewTypeForm.editType')
            : polyglot.t('DocumentSettingsNewTypeForm.newType')}
        </Typography>

        <FormControl size="small" fullWidth>
          <TextfieldComponent
            name="documentTypeName"
            label={polyglot.t('DocumentSettingsNewTypeForm.documentTypeName')}
            value={formik.values.documentTypeName}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.documentTypeName && !!formik.errors.documentTypeName}
            helperText={(formik.touched.documentTypeName && formik.errors.documentTypeName) ?? ' '}
            clearText={() => formik.setFieldValue('documentTypeName', '')}
            required={true}
          />
        </FormControl>

        <FormControl size="small" fullWidth>
          <SelectComponent
            name="availableTo"
            label={polyglot.t('DocumentSettingsNewTypeForm.availableTo')}
            options={getDocumentTypeAvailableToOptions(polyglot)}
            value={formik.values.availableTo}
            compareValue={formik.values.availableTo}
            error={!!formik.errors.availableTo && formik.touched.availableTo}
            onChange={formik.handleChange}
            helperText={formik.errors.availableTo && formik.touched.availableTo}
            required={true}
          />
        </FormControl>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth={true}
            colorVariant="primary"
            sizeVariant="medium"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
