import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import { ReactComponent as Settings } from '@/images/fields/Settings.svg';
import { ReactComponent as Apps } from '@/images/side-bar-icons/Apps.svg';
import { ReactComponent as Payment } from '@/images/side-bar-icons/Bill.svg';
import { ReactComponent as Clock } from '@/images/side-bar-icons/Clock.svg';
import { ReactComponent as Device } from '@/images/side-bar-icons/Device.svg';
import { ReactComponent as Document } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as NoAccess } from '@/images/side-bar-icons/NoAccess.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as PartialAccess } from '@/images/side-bar-icons/PartialAccess.svg';
import { ReactComponent as PersonIcon } from '@/images/side-bar-icons/Person.svg';
import { ReactComponent as Report } from '@/images/side-bar-icons/Report.svg';
import { ReactComponent as Reviews } from '@/images/side-bar-icons/Reviews.svg';
import { ReactComponent as Star } from '@/images/side-bar-icons/Star.svg';
import { ReactComponent as Task } from '@/images/side-bar-icons/Task.svg';
import { ReactComponent as TimeAway } from '@/images/side-bar-icons/TimeAway.svg';
import { ReactComponent as Wallet } from '@/images/side-bar-icons/Wallet.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { PermissionCategory } from '@/v2/feature/permission-group/permission-group.interface';
import { translatePermissionCategory } from '@/v2/infrastructure/i18n/translate.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface PermissionCategoryListingTableProps {
  readonly permissionCategories: PermissionCategory[];
  readonly defaultPermission: boolean;
  readonly loading: boolean;
  readonly openDrawerInEditMode: (permissionGroup: PermissionCategory) => void;
  readonly openDrawerInViewMode: (permissionGroup: Row<PermissionCategory>) => void;
  readonly permissionGroupName: string;
}

export const PERMISSION_CATEGORY_ICON_MAP: Record<string, JSX.Element> = {
  'Personal profile': <PersonIcon {...iconSize} />,
  'Compensation profile': <PersonIcon {...iconSize} />,
  'Work profile': <PersonIcon {...iconSize} />,
  'Contact profile': <PersonIcon {...iconSize} />,
  Apps: <Apps {...iconSize} />,
  Administration: <Settings {...iconSize} />,
  Devices: <Device {...iconSize} />,
  Payroll: <Wallet {...iconSize} />,
  Benefits: <Star {...iconSize} />,
  Payments: <Payment {...iconSize} />,
  Expenses: <Payment {...iconSize} />,
  Invoices: <Payment {...iconSize} />,
  'Time and Calendar': <TimeAway {...iconSize} />,
  Documents: <Document {...iconSize} />,
  Tasks: <Task {...iconSize} />,
  Reviews: <Reviews {...iconSize} />,
  Reports: <Report {...iconSize} />,
  Attendance: <Clock {...iconSize} style={{ fill: themeColors.black }} />,
};
const PERMISSION_ACCESS_ICON_MAP = (polyglot: Polyglot): Record<string, JSX.Element> => {
  return {
    [polyglot.t('PermissionCategoryListingTable.partial')]: <PartialAccess {...iconSize} />,
    [polyglot.t('PermissionCategoryListingTable.noAccess')]: <NoAccess {...iconSize} />,
    [polyglot.t('PermissionCategoryListingTable.fullAccess')]: (
      <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
    ),
  };
};
export const PermissionCategoryListingTable = ({
  permissionCategories,
  loading,
  openDrawerInEditMode,
  openDrawerInViewMode,
  permissionGroupName,
}: PermissionCategoryListingTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const isAdmin = permissionGroupName === 'Admin';

  const [enabledState, setEnabledState] = useState<Record<string, string>>({});
  const getEnabledPermissionsState = useCallback(
    (category: PermissionCategory) => {
      const totalPermissions = category.subcategories[0]?.permissions?.length;
      const enabledPermissions = category.subcategories[0]?.permissions?.filter(
        (p) => p.permission_group_scope?.enabled
      ).length;
      return enabledPermissions === totalPermissions
        ? polyglot.t('PermissionCategoryListingTable.fullAccess')
        : enabledPermissions > 0 && enabledPermissions < totalPermissions
        ? polyglot.t('PermissionCategoryListingTable.partial')
        : enabledPermissions === 0
        ? polyglot.t('PermissionCategoryListingTable.noAccess')
        : '';
    },
    [polyglot]
  );

  useEffect(() => {
    const enabledState: Record<string, string> = {};
    permissionCategories.forEach((category) => {
      enabledState[category.name] = getEnabledPermissionsState(category);
    });
    setEnabledState(enabledState);
  }, [getEnabledPermissionsState, permissionCategories, polyglot]);

  const tableColumns = useMemo<ColumnDef<PermissionCategory, PermissionCategory>[]>(() => {
    return [
      {
        header: () => polyglot.t('PermissionCategoryListingTable.domain'),
        accessorFn: (row) => row,
        id: 'name',
        maxSize: 350,
        minSize: 350,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return (
            original && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {PERMISSION_CATEGORY_ICON_MAP[original.name]}
                <Typography sx={{ ...themeFonts.caption, ml: spacing.m5, color: themeColors.DarkGrey }}>
                  {translatePermissionCategory(original.name, polyglot)}
                </Typography>
              </Box>
            )
          );
        },
      },
      {
        header: () => polyglot.t('PermissionCategoryListingTable.access'),
        accessorFn: (row) => row,
        id: 'access',
        maxSize: 100,
        minSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && enabledState[original.name] ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: enabledState[original.name] !== 'Full access' ? themeColors.Grey : themeColors.DarkGrey,
              }}
            >
              {PERMISSION_ACCESS_ICON_MAP(polyglot)[enabledState[original.name]]}
              <Typography sx={{ ...themeFonts.caption, ml: spacing.m5, color: themeColors.DarkGrey }}>
                {enabledState[original.name]}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
      },
    ];
  }, [enabledState, polyglot]);

  const handleRowClick = useCallback(
    (row: Row<PermissionCategory>) => {
      !isAdmin ? openDrawerInEditMode(row.original) : openDrawerInViewMode(row);
    },
    [openDrawerInViewMode, openDrawerInEditMode, isAdmin]
  );

  return (
    <Box sx={{ mt: spacing.m20 }}>
      <BasicTable<PermissionCategory>
        rowData={permissionCategories}
        columnData={tableColumns}
        loading={loading}
        hidePagination={true}
        rowClick={handleRowClick}
      />
    </Box>
  );
};
