import { AttendanceEntryDto, AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import Polyglot from 'node-polyglot';

export const WeekDayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

export type WeekDay = typeof WeekDayNames[number];

export const DayNoToWeekDay: { [dayNo: number]: WeekDay } = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export const weekDayToDayNo = (weekDay: WeekDay) => {
  return Object.values(DayNoToWeekDay).findIndex((wd) => wd === weekDay);
};

export type ScheduleTimeEntry = {
  from: string;
  to: string;
  fromTimestamp: Date | null;
  toTimestamp: Date | null;
  break: string;
  totalHours?: string;
} | null;

export type ScheduleGeneralFormData = Pick<AttendanceScheduleDto, 'name'>;

export type ScheduleScheduleFormData = Pick<
  AttendanceScheduleDto,
  | 'totalTime'
  | 'fteEquivalent'
  | 'fteEquivalentInDays'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'isFlexible'
  | 'startDateOfFirstWeek'
  | 'noOfWeeks'
>;

export type ScheduleWeeksPattern = Pick<
  AttendanceScheduleDto,
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'noOfWeeks'
  | 'startDateOfFirstWeek'
>;

export type ScheduleTrackingSettingsFormData = Pick<
  AttendanceScheduleDto,
  | 'trackingType'
  | 'timesheetType'
  | 'useGeolocation'
  | 'restrictByGeolocation'
  | 'geolocationDistance'
  | 'geolocationEmployeeSite'
  | 'allowedWorkSites'
  | 'clockInEarlyCapMinutes'
> & {
  attendanceTypesAllowedIds: number[];
};

export type SchedulePayrollFormData = Pick<AttendanceScheduleDto, 'includedInPayroll' | 'payCode'>;

export type ScheduleSettingsFormData = Pick<
  AttendanceScheduleDto,
  'isFlexible' | 'fteEquivalent' | 'fteEquivalentInDays'
>;

export type ScheduleApprovalFormData = Pick<AttendanceScheduleDto, 'approvalRuleId'>;

export type CreateAttendanceEntry = Pick<
  AttendanceEntryDto,
  'typeId' | 'startHour' | 'endHour' | 'startHourTimestamp' | 'endHourTimestamp'
>;

export enum ScheduleTrackingType {
  None = 'None',
  ClockInClockOut = 'ClockInClockOut',
  Regular = 'Regular',
}

export const ScheduleTrackingTypeOptions = (isFlexible: boolean, polyglot: Polyglot) => [
  ...(isFlexible
    ? []
    : [{ label: polyglot.t('getTrackingTypeLabel.clockInOut'), value: ScheduleTrackingType.ClockInClockOut }]),
  { label: polyglot.t('getTrackingTypeLabel.regularTracking'), value: ScheduleTrackingType.Regular },
  { label: polyglot.t('getTrackingTypeLabel.noTracking'), value: ScheduleTrackingType.None },
];

export enum ScheduleTrackingTimesheetType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum AttendanceStatus {
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum ScheduleType {
  DefinedHours = 'DefinedHours',
  Flexible = 'Flexible',
}

export const ScheduleTypeOption = (isClockInClockOut: boolean, polyglot: Polyglot) => [
  { name: polyglot.t('ScheduleScheduleForm.definedHours'), value: ScheduleType.DefinedHours },
  { name: polyglot.t('ScheduleScheduleForm.flexible'), value: ScheduleType.Flexible, disabled: isClockInClockOut },
];
