import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomDEPTokenSteps } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component';
import { DepWizardHelper } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-wizard-helper';
import {
  DepAccountDetails,
  DepWizardDto,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const CustomDepTokenResult = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<DepWizardDto>>;
  formData: DepWizardDto;
}) => {
  const [depAccountDetails, setDepAccountDetails] = useState<DepAccountDetails | undefined>(undefined);
  const [showMessage] = useMessage();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await InHouseMdmAPI.getDepAccountDetails(formData.mdmServerName);
        setDepAccountDetails(response);
      } catch (error) {
        showMessage('Error fetching dep account details', 'error');
      }
    };
    fetchData();
  }, [formData.mdmServerName, showMessage]);
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography variant="title2">Auto enrolment overview</Typography>
        <Box>
          <Box
            sx={{
              mt: spacing.m5,
              display: 'grid',
              gridTemplateColumns: 'calc(25%) calc(75%)',
              color: themeColors.DarkGrey,
            }}
          >
            <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
              Organisation
            </Typography>
            <Typography variant="caption" sx={{ ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }}>
              {depAccountDetails?.org_name ?? 'N/A'}
            </Typography>
            <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
              MDM Server name
            </Typography>
            <Typography variant="caption" sx={{ ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }}>
              {depAccountDetails?.server_name ?? 'N/A'}
            </Typography>
            <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
              Renew by
            </Typography>
            <Typography variant="caption" sx={{ ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }}>
              {depAccountDetails?.access_token_expiry
                ? DepWizardHelper.formatDate(depAccountDetails?.access_token_expiry)
                : 'N/A'}
            </Typography>
          </Box>
        </Box>

        <ButtonComponent
          sizeVariant="large"
          colorVariant="primary"
          style={{ marginTop: spacing.m25 }}
          onClick={() => {
            formData.type = CustomDEPTokenSteps.Result;
            setFormData(formData);
            onNext();
          }}
          fullWidth
        >
          Finish
        </ButtonComponent>
      </Box>
    </Box>
  );
};
