import '@/v2/components/list-view/list-view.scss';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { PaginationDetail } from '@/v2/components/table/pagination-detail.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface listProps {
  id: number | string;
  name: string;
  details: string;
  showAlert?: boolean;
  alertColor?: string;
  avatar?: JSX.Element;
  highlightTitle?: boolean;
}

interface PaginationProps {
  pageSize: number;
  pageIndex: number;
  total: number;
  totalItems: number;
  onPageChange: (newPageIndex: number, newPageSize: number) => void;
}

export const ListView = ({
  list,
  pagination,
  clickAction,
  loading,
  selected,
  hideCicularLoader = false,
}: {
  list: listProps[];
  pagination?: PaginationProps;
  clickAction: (id: number | string) => Promise<void> | void;
  loading?: boolean;
  selected?: number | string | undefined;
  hideCicularLoader?: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const isNextDisabled = pagination
    ? pagination.pageIndex >= Math.ceil(pagination.total / pagination.pageSize) - 1
    : true;
  const isPrevDisabled = pagination ? pagination.pageIndex <= 0 : true;

  const handleNext = () => {
    if (pagination && !isNextDisabled) {
      pagination.onPageChange(pagination.pageIndex + 1, pagination.pageSize);
    }
  };

  const handlePrevious = () => {
    if (pagination && !isPrevDisabled) {
      pagination.onPageChange(pagination.pageIndex - 1, pagination.pageSize);
    }
  };

  return (
    <div>
      {loading ? (
        <ListViewItemLoader hideCicularLoader={hideCicularLoader} />
      ) : (
        <>
          {list.map((l, i) => (
            <ListViewItem
              key={i}
              name={polyglot.t(l.name)}
              details={l.details}
              avatar={l.avatar}
              id={l.id}
              showAlert={l.showAlert}
              alertColor={l.alertColor}
              clickAction={clickAction}
              selected={selected}
              highlightTitle={l.highlightTitle}
            />
          ))}

          {pagination && (
            <PaginationDetail
              totalPageCount={pagination.total}
              totalRecordCount={pagination.totalItems}
              current={pagination.pageIndex}
              onNextAction={handleNext}
              nextDisabled={pagination.pageIndex === pagination.total}
              onPreviousAction={handlePrevious}
              previousDisabled={pagination.pageIndex === 1}
              handleState={(page: number) => pagination.onPageChange(page, pagination.pageSize)}
              paginationState={{ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize }}
              setPaginationState={(pageSize: number, pageIndex: number) => {
                pagination.onPageChange(pageIndex, pageSize);
              }}
              showCompact={true}
            />
          )}
        </>
      )}
    </div>
  );
};

const ListViewItem = ({
  avatar,
  name,
  id,
  details,
  clickAction,
  selected,
  showAlert,
  alertColor,
  highlightTitle,
}: {
  avatar?: JSX.Element;
  name: string;
  id: number | string;
  details: string;
  clickAction: (id: number | string) => Promise<void> | void;
  selected?: number | string | undefined;
  showAlert: boolean | undefined;
  alertColor: string | undefined;
  highlightTitle: boolean | undefined;
}) => {
  return (
    <div className="view-item-box">
      <div onClick={() => clickAction(id)} role="button">
        <div className={selected === id ? 'view-item-padding-active' : 'view-item-padding'}>
          <div className="view-item-content">
            <div className="view-item-detail">
              {avatar}
              <div className="item-title-detail">
                <Typography
                  variant={highlightTitle ? 'title4' : 'caption'}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {name}
                </Typography>
                <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                  {details}
                </Typography>
              </div>
            </div>

            {showAlert && (
              <div
                className="Ellipse190"
                style={{ width: '10px', height: '10px', background: alertColor, borderRadius: radius.br20 }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ListViewItemLoader = ({ hideCicularLoader }: { hideCicularLoader: boolean }) => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 10].map((num) => (
        <div style={{ height: '55px', paddingLeft: '20px', paddingRight: '20px' }} key={num}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {/* Circular Skeleton */}
            {!hideCicularLoader && (
              <SkeletonLoader
                variant="circular"
                width="35px"
                height="35px"
                sx={{ background: themeColors.Background, marginRight: '16px' }}
              />
            )}

            {/* Rectangular Skeletons */}
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                gap: spacing.g5,
              }}
            >
              <SkeletonLoader
                variant="rectangular"
                width="30%"
                height="16px"
                sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
              />
              <SkeletonLoader
                variant="rectangular"
                width="80%"
                height="12px"
                sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
