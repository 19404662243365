import { useMemo, useState } from 'react';

import { Box, FormControl, IconButton, Stack } from '@mui/material';
import { AutocompleteComponent } from '@v2/components/forms/autocomplete.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';

import { UserBankAccountFormSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { UserBankAccountAPI } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import {
  UpdateUserBankAccountDto,
  UserBankAccountDto,
} from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import {
  drawerContentSx,
  editDeleteHeaderSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  getCountryOptionsForSelectComponent,
  getEURegionCoutry,
  getUKRegionCountry,
} from '@/v2/infrastructure/country/country.util';
import { popularCurrencyOptions } from '@/v2/infrastructure/currency/currency.interface';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly initialValues: UserBankAccountDto | UpdateUserBankAccountDto | null;
  readonly userId: number;
  readonly defaultName: string;
  readonly getUserBankAccounts: () => Promise<void>;
  readonly rowModalMode: 'add' | 'edit';
  readonly handleSubmit: (userId: number) => void;
  readonly setFormCurrentlyEditing: (beingEdited: boolean) => void;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly loading: boolean;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: UpdateUserBankAccountDto) => void;
  showEmployee?: boolean;
}

export const BankForm = ({
  initialValues,
  userId,
  defaultName,
  getUserBankAccounts,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
  showEmployee = false,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [rowForDeletion, setRowForDeletion] = useState<number>(0);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const defaultBankModalValues: UserBankAccountDto = {
    accountName: defaultName,
    accountNumber: '',
    sortCode: '',
    bankName: '',
    effectiveDate: new LocalDate().toDateString(),
    bankAccountId: 0,
    country: '',
    currency: '',
    customUpdates: [],
  };

  const validationSchema = useMemo(() => UserBankAccountFormSchema(polyglot), [polyglot]);

  const formik = useFormik<UserBankAccountDto>({
    initialValues: initialValues ? (initialValues as UserBankAccountDto) : defaultBankModalValues,
    // enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (!usedForDataImport) {
          if (
            !values.effectiveDate ||
            !values.accountName ||
            !values.accountNumber ||
            !values.bankName ||
            !values.sortCode ||
            !values.country ||
            !values.currency
          ) {
            showMessage(polyglot.t('BankForm.errorMessages.updateCreate'), 'error');
            return;
          }

          const data: UpdateUserBankAccountDto = {
            effectiveDate: values.effectiveDate,
            bankName: values.bankName,
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            sortCode: values.sortCode,
            country: values.country,
            currency: values.currency,
            customUpdates: values.customUpdates,
          };
          if ('bankAccountId' in values && values.bankAccountId) {
            await UserBankAccountAPI.update(userId, values.bankAccountId, data);
          } else {
            await UserBankAccountAPI.create(userId, data);
          }
          showMessage(polyglot.t('BankForm.successMessages.save'), 'success');
          await getUserBankAccounts();
          formik.resetForm();
          setIsModalOpen(false);
        } else {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage(polyglot.t('BankForm.errorMessages.update'), 'error');
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    },
  });

  const handleDeleteRow = async () => {
    try {
      if (rowForDeletion) {
        await UserBankAccountAPI.deleteById(userId, rowForDeletion);
      }
      showMessage(polyglot.t('BankForm.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(polyglot.t('BankForm.errorMessages.delete'), 'error');
    } finally {
      await getUserBankAccounts();
      handleSubmit(userId);
      setFormCurrentlyEditing(false);
      setRowForDeletion(0);
      setIsModalOpen(false);
      formik.resetForm();
      setIsRemovalModalOpen(false);
    }
  };

  const deleteBankRow = (rowId: number) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };

  const countryOptions = useMemo(() => getCountryOptionsForSelectComponent(), []);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {rowModalMode === 'add' ? (
          <Typography variant="title2">{polyglot.t('BankForm.new')}</Typography>
        ) : (
          <Box sx={{ ...editDeleteHeaderSx, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{polyglot.t('BankForm.edit')}</Typography>
            {'bankAccountId' in formik.values && (
              <IconButton
                sx={iconCTAButtonSx}
                onClick={(event) => {
                  if (!('bankAccountId' in formik.values)) return;
                  setAnchorEl(event.currentTarget);
                  setIsRemovalModalOpen(true);
                  deleteBankRow(formik.values.bankAccountId);
                }}
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
            )}
          </Box>
        )}
        {showEmployee && (
          <Stack sx={{ gap: spacing.g5 }}>
            <Typography variant="captionSmall">{polyglot.t('BankForm.employee')}</Typography>
            <UserCell userId={userId} nameVariant="title4" />
          </Stack>
        )}
        <ProfileField fieldStub="bankAccount.effectiveDate">
          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.effectiveDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('effectiveDate', value);
                }
              }}
              name="effectiveDate"
              label={polyglot.t('BankForm.effectiveDate')}
              error={!!formik.errors.effectiveDate && Boolean(formik.touched.effectiveDate)}
              helperText={formik.errors.effectiveDate && Boolean(formik.touched.effectiveDate)}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="bankAccount.country">
          <FormControl size="small" fullWidth>
            <AutocompleteComponent
              name="country"
              label={polyglot.t('BankForm.country')}
              options={countryOptions}
              value={countryOptions.find(({ value }) => value === formik.values.country)}
              compareValue={formik.values.country ?? ' '}
              // @ts-ignore
              onChange={(_, x: { value: string }) => {
                formik.setFieldValue('country', x?.value ?? null);

                const EUCountries = getEURegionCoutry()?.options.map((o) => o.value);
                const isEUCountry = x?.value && EUCountries?.includes(x.value);
                if (isEUCountry && !formik.values.currency) {
                  formik.setFieldValue('currency', 'EUR');
                  return;
                }

                const UKCountries = getUKRegionCountry()?.options.map((o) => o.value);
                const isUKCountry = x?.value && UKCountries?.includes(x.value);
                if (isUKCountry && !formik.values.currency) formik.setFieldValue('currency', 'GBP');
              }}
              error={!!formik.errors.country && Boolean(formik.touched.country)}
              helperText={formik.touched.country ? formik.errors.country : ''}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="bankAccount.currency">
          <FormControl size="small" fullWidth>
            <AutocompleteComponent
              name="currency"
              label={polyglot.t('BankForm.currency')}
              options={popularCurrencyOptions}
              value={popularCurrencyOptions.find(({ value }) => value === formik.values.currency) ?? null}
              compareValue={formik.values.currency ?? null}
              // @ts-ignore
              onChange={(_, x) => formik.setFieldValue('currency', x?.value ?? null)}
              error={!!formik.errors.currency && formik.touched.currency}
              helperText={formik.touched.currency ? formik.errors.currency : ''}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="bankAccount.bankName">
          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="bankName"
              label={polyglot.t('BankForm.bank')}
              value={formik.values.bankName}
              onChange={formik.handleChange}
              error={formik.touched.bankName && !!formik.errors.bankName}
              helperText={(formik.touched.bankName && formik.errors.bankName) ?? ' '}
              clearText={() => formik.setFieldValue('bankName', '')}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="bankAccount.accountName">
          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="accountName"
              label={polyglot.t('BankForm.accountName')}
              value={formik.values.accountName}
              onChange={formik.handleChange}
              error={formik.touched.accountName && !!formik.errors.accountName}
              helperText={(formik.touched.accountName && formik.errors.accountName) ?? ' '}
              clearText={() => formik.setFieldValue('accountName', '')}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="bankAccount.accountNumber">
          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="accountNumber"
              label={polyglot.t('BankForm.accountNumber')}
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              error={formik.touched.accountNumber && !!formik.errors.accountNumber}
              helperText={(formik.touched.accountNumber && formik.errors.accountNumber) ?? ' '}
              clearText={() => formik.setFieldValue('accountNumber', '')}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="bankAccount.sortCode">
          <FormControl size="small" fullWidth>
            <TextfieldComponent
              name="sortCode"
              label={polyglot.t('BankForm.sortCode')}
              value={formik.values.sortCode}
              onChange={formik.handleChange}
              error={formik.touched.sortCode && !!formik.errors.sortCode}
              helperText={(formik.touched.sortCode && formik.errors.sortCode) ?? ' '}
              clearText={() => formik.setFieldValue('sortCode', '')}
            />
          </FormControl>
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
          rowModalMode={rowModalMode}
          formName={CustomProfileFormType.BankAccount}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>

      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => {
          setIsRemovalModalOpen(false);
          setRowForDeletion(0);
        }}
        anchorEl={anchorEl}
        takeAction={handleDeleteRow}
        message={polyglot.t('BankForm.confirmDelete')}
        callToAction={polyglot.t('BankForm.callToAction')}
      />
    </FormikProvider>
  );
};
