import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getSurveyDueDate } from '@/v2/feature/growth/surveys/features/survey-entry/survey-entry.util';
import { AnonymityType, DEFAULT_THRESHOLD_VALUE } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { EntryState, SurveyEntry } from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';

export const SurveyEntriesPersonalStartModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  selectedEntry,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  afterClose: () => void;
  selectedEntry: SurveyEntry | undefined;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} loading={false} afterClose={afterClose}>
      <SurveyEntriesPersonalStartContent selectedEntry={selectedEntry} onClose={onClose} />
    </DrawerModal>
  );
};

const useStartModalForm = (
  selectedEntry: SurveyEntry | undefined,
  setLoading: Dispatch<SetStateAction<boolean>>,
  shouldSelectPeers: boolean
) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const formik = useFormik<{ peerIds: number[] }>({
    initialValues: {
      peerIds: [],
    },
    validationSchema: yup.object({
      peerIds: shouldSelectPeers
        ? yup.array().of(yup.number()).min(1, 'At least one peer is required').required('Peers are required')
        : yup.array().of(yup.number()),
    }),
    onSubmit: async (_values) => {
      try {
        if (!selectedEntry || !selectedEntry.cycle) {
          return;
        }
        routerHistory.push(
          generatePath(SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE, {
            entryId: selectedEntry.id,
            cycleId: selectedEntry.cycle.id,
          })
        );
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return formik;
};

const SurveyEntriesPersonalStartContent = ({
  selectedEntry,
  onClose,
}: {
  selectedEntry: SurveyEntry | undefined;
  onClose: () => void;
}) => {
  const routerHistory = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const getAnonimityText = useMemo(() => {
    if (!Boolean(selectedEntry?.cycle?.visibilitySettings?.anonymiseAnswers))
      return 'No, your answers are not anonymous.';
    if (
      Boolean(selectedEntry?.cycle?.visibilitySettings?.anonymiseAnswers) &&
      selectedEntry?.cycle?.visibilitySettings?.anonymityType &&
      selectedEntry?.cycle?.visibilitySettings?.anonymityType === AnonymityType.Semi
    )
      return 'Yes. Your answers will not be attributed to you, however some demographic data is collected to help segment and analyse answers. Your department, site, gender and tenure are saved alongside answers you provide. Individual responses remain anonymous and will only be reviewed in aggregate form to protect your privacy.';

    return 'Yes, this survey does not collect any personally identifiable information.';
  }, [selectedEntry?.cycle?.visibilitySettings]);

  const handleContinue = useCallback(async () => {
    if (!selectedEntry || !selectedEntry.cycle) {
      return;
    }
    routerHistory.push(
      generatePath(SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE, {
        entryId: selectedEntry.id,
        cycleId: selectedEntry.cycle.id,
      })
    );
  }, [selectedEntry, routerHistory]);

  const formik = useStartModalForm(selectedEntry, setLoading, false);

  const isAnonymousSurvey = selectedEntry?.cycle?.visibilitySettings?.anonymiseAnswers;

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
          <Typography variant="title2">
            Let's {selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? 'start' : 'continue'} your
            survey
          </Typography>

          <Typography variant="caption" color="Grey">
            {isAnonymousSurvey
              ? 'Please note that you will not be able to save your progress, anonymous surveys only allow for one submission. '
              : 'You can save and exit at any time, come back to this survey later'}
          </Typography>
        </Box>
        {selectedEntry?.cycle?.displayName && <ViewItem label="Cycle name" value={selectedEntry.cycle.displayName} />}

        {selectedEntry?.cycle?.timelineSettings?.startDate && (
          <ViewItem label="Due date" value={dateAPItoDisplay(getSurveyDueDate(selectedEntry).toDateString()) ?? ''} />
        )}

        {selectedEntry?.participantId && <ViewItem label="Anonymous" value={getAnonimityText} />}

        {isAnonymousSurvey && (
          <ViewItem
            label="How will my answers be displayed?"
            value={`At minimum, ${
              selectedEntry?.cycle?.visibilitySettings?.anonymityThreshold || DEFAULT_THRESHOLD_VALUE
            } answers required in any question or filtered segment to show results`}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" fullWidth onClick={onClose}>
            Cancel
          </ButtonComponent>
          {selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? (
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name="Start survey"
              fullWidth
              type="submit"
              loading={loading}
            />
          ) : (
            <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth onClick={() => handleContinue()}>
              Continue
            </ButtonComponent>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
};

const ViewItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g4 }}>
      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
        {label}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};
