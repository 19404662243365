import { useCallback, useState } from 'react';

import { Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { nestErrorMessage } from '@/lib/errors';
import { USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { InlineLink } from '@/v2/components/inline-link.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const MIN_PWD_LENGTH = 12;

const validationSchema = yup.object({
  password: yup
    .string()
    .min(MIN_PWD_LENGTH, `Password must be at least ${MIN_PWD_LENGTH} characters`)
    .required('Password is required'),
  confirm: yup
    .string()
    .test('mismatch-passwords', 'Confirmed password does not match', function (confirm) {
      const { password } = this.parent;
      return !password || !confirm || password === confirm;
    })
    .required('Password confirmation is required'),
});

interface CreatePasswordProps {
  readonly password: string;
  readonly confirm: string;
}

export const UserOnboardingCreatePassword = () => {
  const routerHistory = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email');
  const userId = searchParams.get('id') || '-1';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const createPasswordUrl = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const apiParams = new URLSearchParams();
    apiParams.set('id', searchParams.get('id') || '');
    apiParams.set('token', searchParams.get('token') || '');
    apiParams.set('expires', searchParams.get('expires') || '');
    apiParams.set('signature', searchParams.get('signature') || '');
    apiParams.set('email', searchParams.get('email') || '');
    return `/apiv2/auth/register?${apiParams.toString()}`;
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (formData: CreatePasswordProps) => {
      setIsSubmitting(true);
      setErrorMessage('');
      try {
        await axios.post(createPasswordUrl(), {
          password: formData.password,
        });
        routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
      } catch (err) {
        if (err.response.status === 422 && err.response?.data?.error === 'LEAKED_PASSWORD') {
          setErrorMessage('Password does not meet the security requirements, please try a different password');
        } else {
          setErrorMessage(`${nestErrorMessage(err)}`);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <AuthLayout
      title="Please set your password"
      description={email ? `Your work email is ${email}` : ''}
      showLoginButton
      submit={formik.handleSubmit}
    >
      <TextfieldComponent
        label="Email"
        name="email"
        value={email}
        type="text"
        autoComplete="email"
        sx={{ display: 'none' }}
      />
      <TextfieldComponent
        label="Password"
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={hasSubmitted && !!formik.errors.password}
        helperText={(hasSubmitted && formik.errors.password) || `Must be at least ${MIN_PWD_LENGTH} characters`}
        type="password"
        autoComplete="new-password"
        autoFocus
      />
      <TextfieldComponent
        label="Confirm password"
        name="confirm"
        value={formik.values.confirm}
        onChange={formik.handleChange}
        error={!!errorMessage || (hasSubmitted && !!formik.errors.confirm)}
        helperText={errorMessage || (hasSubmitted && formik.errors.confirm)}
        type="password"
        autoComplete="new-password"
        sx={{ mt: spacing.mt30 }}
      />
      <LoaderButton
        type="submit"
        name="Continue"
        loading={isSubmitting}
        sizeVariant="medium"
        colorVariant="primary"
        fullWidth
        style={{ marginTop: '40px' }}
      />
      <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mt: spacing.m30 }}>
        {'By continuing, you are agreeing to our '}
        <InlineLink href="https://www.zelt.app/privacy" title="Privacy Policy" openInNewWindow />
        {' and '}
        <InlineLink href="https://www.zelt.app/terms" title="Terms and Conditions" openInNewWindow />
      </Typography>
    </AuthLayout>
  );
};
