import { Box } from '@mui/material';

import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const BadgeLoaderHeader = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g40, height: '50px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: spacing.g10,
          height: '100%',
          maxWidth: '210px',
          width: '100%',
        }}
      >
        <SkeletonLoader variant="circular" width="40px" height="40px" sx={{ background: themeColors.Background }} />

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: spacing.g5,
            height: '100%',
          }}
        >
          <SkeletonLoader
            variant="rectangular"
            width="30%"
            height="16px"
            sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
          />
          <SkeletonLoader
            variant="rectangular"
            width="80%"
            height="12px"
            sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
          />
        </div>
      </div>

      {/* badge loading */}

      <BadgeLoaderItem />
      <BadgeLoaderItem />
      <BadgeLoaderItem />
    </Box>
  );
};

const BadgeLoaderItem = () => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: spacing.g5,
        height: '100%',
        maxWidth: '50px',
        width: '100%',
      }}
    >
      <SkeletonLoader
        variant="rectangular"
        width="100%"
        height="16px"
        sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
      />
      <SkeletonLoader
        variant="rectangular"
        width="100%"
        height="12px"
        sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
      />
    </div>
  );
};
