import React, { Dispatch, SetStateAction, useState } from 'react';

import { Box, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface WipeDeviceDrawerProps {
  readonly isOpen: boolean;
  readonly onClose: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
}
export const WipeDeviceDrawer = ({ isOpen, onClose, devicePossession, setIsOpen }: WipeDeviceDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <WipeDeviceDrawerContent devicePossession={devicePossession} closePage={onClose} />
    </DrawerModal>
  );
};

const WipeDeviceDrawerContent = ({
  devicePossession,
  closePage,
}: {
  readonly devicePossession: DevicePossessionDto;
  closePage: () => Promise<void>;
}) => {
  const [confirmationText, setConfirmationText] = useState<string>('');
  const [showMessage] = useMessage();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
      <Typography sx={themeFonts.title2}>Wipe device</Typography>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.Red }}>
        Device will be wiped next time it is connected to the internet. The device will be shut down and reset to
        factory settings.
      </Typography>

      <Box sx={{ ...spacing.mt40 }}>
        <TextfieldComponent
          name="confirm"
          label=""
          placeholder="Type “Confirm” here to wipe device"
          fullWidth
          value={confirmationText}
          type="text"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmationText(event.target.value)}
          clearText={() => setConfirmationText('')}
        />
      </Box>

      <Box sx={{ mt: spacing.m30 }}>
        <ButtonComponent
          disabled={confirmationText !== 'Confirm'}
          fullWidth
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={async () => {
            try {
              if (devicePossession && devicePossession.id) await DeviceAPI.wipeDevice(devicePossession.id);
              showMessage('Action launched successfully', 'success');
              closePage();
            } catch (error) {
              showMessage('The launch of the action failed', 'error');
            }
          }}
        >
          Wipe device
        </ButtonComponent>
      </Box>
    </Box>
  );
};
