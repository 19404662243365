import React, { Dispatch, SetStateAction, Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { PublicHolidaysAPI } from '@v2/feature/absence/public-holidays.api';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absence: Pick<
    AbsenceDto,
    'userId' | 'policyId' | 'isPublicHoliday' | 'deletionRequestId' | 'canRequestPHDeletion' | 'start'
  >;
  readonly refresh: () => Promise<void>;
}

export const RequestPHCancellationDrawer = ({ isOpen, setIsOpen, absence, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <RequestPHCancellationDrawerContent absence={absence} refresh={refresh} setIsOpen={setIsOpen} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly absence: Pick<
    AbsenceDto,
    'userId' | 'policyId' | 'isPublicHoliday' | 'deletionRequestId' | 'canRequestPHDeletion' | 'start'
  >;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const RequestPHCancellationDrawerContent = ({ absence, refresh, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [loading, setIsLoading] = useState<boolean>(false);

  const requestPHCancellation = useCallback(async () => {
    if (!absence.isPublicHoliday || !absence.canRequestPHDeletion) return;
    setIsLoading(true);
    try {
      await PublicHolidaysAPI.requestDeletionOfPublicHoliday(absence.userId, absence.policyId, absence.start);
      setIsOpen(false);
      await refresh();
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), 'error');
    }
    setIsLoading(false);
  }, [absence, showMessage, polyglot, refresh, setIsOpen]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('AbsencePHCancellation.title')}</Typography>

      <Typography variant="caption">{polyglot.t('AbsencePHCancellation.description')}</Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('AbsencePHCancellation.requestDeletion')}
          onClick={requestPHCancellation}
          sizeVariant="medium"
          colorVariant="primary"
          loading={loading}
          fullWidth
        />
      </Box>
    </Box>
  );
};
