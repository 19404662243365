import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import {
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { detailTermSx, detailValueSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';
import { toTitleCase } from '@/v2/util/string.util';

interface Props {
  appDetails: InstalledAppDto | undefined;
  mode: 'candidate' | 'employment' | undefined;
}

export interface OnboardingAutomaticConfiguration {
  selected?: number | undefined;
  all: OnboardingTemplate[];
  enabled: boolean;
}

export const AppAutomaticOnboardingConfiguration = ({ appDetails, mode }: Props): JSX.Element => {
  const [edit, setEdit] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [onboardingConfiguration, setOnboardingConfiguration] = useState<OnboardingAutomaticConfiguration | null>(null);
  const [onboardingTemplateOptions, setOnboardingTemplateOptions] = useState<readonly OptionObject[]>([]);

  const fetchOnboardingConfiguration = useCallback(async () => {
    try {
      setLoading(true);
      const onboardingConfiguration = await AppDetailsAPI.getOnboardingAutomaticConfiguration(appDetails?.stub ?? '');
      setOnboardingConfiguration(onboardingConfiguration);
      const templateOptions = onboardingConfiguration?.all?.map((eachTemplate) => {
        return {
          label: eachTemplate?.name,
          value: eachTemplate?.templateId,
        };
      });
      templateOptions.push({ label: 'No selected template', value: 0 });
      setOnboardingTemplateOptions(templateOptions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Failed to fetch SSO SAML Metdata: ${JSON.stringify(error)}`, 'error');
    }
  }, [appDetails?.stub, showMessage]);

  useEffect(() => {
    fetchOnboardingConfiguration();
  }, [fetchOnboardingConfiguration]);

  const updateOnboardingTemplates = async (values: OnboardingAutomaticConfiguration) => {
    try {
      setLoading(true);
      const updatedOnboardingConfiguration = await AppDetailsAPI.updateOnboardingTemplateSelectionForWebhook(
        appDetails?.stub ?? '',
        {
          selected: values.selected ?? 0,
          enabled: values.enabled,
        }
      );
      showMessage('Successfully updated Onboarding configuration', 'success');
      setEdit(false);
      setOnboardingConfiguration(updatedOnboardingConfiguration);
    } catch (error) {
      showMessage(`Failed to update Onboarding configuration: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const selectedTemplateId = useMemo(() => {
    return onboardingConfiguration?.all?.find((t) => t.templateId === onboardingConfiguration?.selected)?.templateId;
  }, [onboardingConfiguration?.all, onboardingConfiguration?.selected]);

  const selectedTemplate = useMemo(() => {
    return onboardingConfiguration?.all?.find((t) => t.templateId === onboardingConfiguration?.selected);
  }, [onboardingConfiguration?.all, onboardingConfiguration?.selected]);

  const formik = useFormik<OnboardingAutomaticConfiguration>({
    initialValues: {
      selected: selectedTemplateId,
      all: onboardingConfiguration?.all ?? [],
      enabled: !!onboardingConfiguration?.enabled,
    },
    enableReinitialize: true,
    onSubmit: updateOnboardingTemplates,
  });

  const cancelEdit = () => {
    formik.resetForm();
    setEdit(false);
  };

  const subtitleText = mode === 'candidate' ? 'hired candidates' : 'new employments';

  return (
    <Box sx={{ width: '550px', mt: spacing.m60 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {mode && (
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {toTitleCase(mode.toString())} onboarding
          </Typography>
        )}
        <IconButton sx={{ ...tableIconButtonSx, display: `${edit ? 'none' : ''}` }} onClick={() => setEdit(true)}>
          <EditIcon {...actionIconSize} />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex' }}>
        {mode && (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
            {`Select an onboarding flow for ${subtitleText} in ${appDetails?.name} to automate hiring admin tasks.`}
          </Typography>
        )}
      </Box>
      {!edit ? (
        <Box component="dl" sx={{ definitionListSx }}>
          {selectedTemplate && (
            <Box sx={{ ...definitionSx, mt: '30px', display: 'flex', alignItems: 'center' }}>
              <Typography component="dt" sx={definitionTermSx}>
                Template
              </Typography>

              <Typography component="dd" sx={definitionValueSx}>
                {selectedTemplate ? selectedTemplate?.name : 'Not selected'}
              </Typography>
            </Box>
          )}
          <Box sx={{ ...definitionSx, mt: '30px', display: 'flex', alignItems: 'center' }}>
            <Typography component="dt" sx={definitionTermSx}>
              Automatically import {subtitleText} to Zelt
            </Typography>
            <Typography component="dd" sx={definitionValueSx}>
              {!!onboardingConfiguration?.enabled ? 'Enabled' : 'Disabled'}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              {selectedTemplate && (
                <Box sx={{ display: 'flex', mt: '30px', alignItems: 'center' }} component="section">
                  <Box sx={{ minWidth: '190px', maxWidth: '190px' }}>
                    <Typography sx={detailTermSx}>Template</Typography>
                  </Box>
                  <Box sx={detailValueSx}>
                    <SelectComponent
                      name="selected"
                      options={onboardingTemplateOptions ?? []}
                      value={formik.values.selected}
                      compareValue={formik.values.selected}
                      error={!!formik.errors.selected && formik.touched.selected}
                      onChange={formik.handleChange}
                      helperText={formik.touched.selected && Boolean(formik.errors.selected)}
                    />
                  </Box>
                </Box>
              )}

              <Box sx={{ display: 'flex', mt: '30px', alignItems: 'center' }} component="section">
                <Box sx={{ minWidth: '190px', maxWidth: '190px' }}>
                  <Typography sx={detailTermSx}>Automatically import {subtitleText} to Zelt</Typography>
                </Box>
                <Box sx={detailValueSx}>
                  <SwitchComponent
                    checked={formik.values.enabled}
                    name="automaticOnboardingEnabled"
                    onChange={(_e, enabled) => {
                      formik.setFieldValue('enabled', enabled);
                    }}
                    disabled={loading}
                  />
                </Box>
              </Box>
              <Stack sx={{ flexFlow: 'row', my: spacing.m50, gap: spacing.g10 }}>
                <ButtonComponent
                  sizeVariant="medium"
                  colorVariant="secondary"
                  disabled={false}
                  onClick={() => cancelEdit()}
                >
                  Cancel
                </ButtonComponent>
                <LoaderButton
                  disabled={!formik.isValid || !formik.dirty || loading}
                  name="Save"
                  loading={loading}
                  sizeVariant="medium"
                  colorVariant="primary"
                />
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
    </Box>
  );
};
