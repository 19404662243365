import { Fragment } from 'react';

import { Box } from '@mui/material';
import { BenefitRequestTodoVersionSmall } from '@v2/feature/dashboard/features/sections/user-todos/components/version-small/benefit-request-todo-version-small.component';
import { ExpenseRequestTodoVersionSmall } from '@v2/feature/dashboard/features/sections/user-todos/components/version-small/expense-request-todo-version-small.section';
import { InvoiceRequestTodoVersionSmall } from '@v2/feature/dashboard/features/sections/user-todos/components/version-small/invoice-request-todo-version-small.section';
import { PendingBenefitPaymentTodoVersionSmall } from '@v2/feature/dashboard/features/sections/user-todos/components/version-small/pending-benefit-payment-todo-version-small.component';
import { PHDeletionRequestsTodoVersionSmall } from '@v2/feature/dashboard/features/sections/user-todos/components/version-small/ph-deletion-requests-todo-version-small.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { PEOPLE_ME_ACTION_ITEMS } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { UserTodosEmptyState } from '@/v2/feature/dashboard/features/sections/user-todos/components/user-todos-empty-state.component';
import { AppTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/app-todo-version-small.component';
import { AttendanceTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/attendance-todo-version-small.component';
import { ContractTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/contract-todo-version-small.component';
import { DocumentTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/document-todo-version-small.component';
import { ReviewTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/review-todo-version-small.component';
import { ShiftsTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/shifts-todo-version-small.component';
import { SubmittedRequestTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/submitted-request-todo-version-small.component';
import { TaskTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/task-todo-version-small.component';
import { TimeRequestTodoVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/time-request-todo-version-small.component';
import '@/v2/feature/dashboard/features/sections/user-todos/user-todos.scss';
import { KindTypes, TodoWidgetSmallReach } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserTodosVersionSmall = ({
  todos,
  readOnly = false,
}: {
  todos: TodoWidgetSmallReach | null | undefined;
  readOnly?: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const limit = 5;

  return (
    <WidgetLayout readOnly={readOnly} size="small">
      <Fragment>
        <Box
          sx={{
            width: '100%',
            '&:hover': { background: themeColors.transparency },
            borderRadius: radius.br10,
            cursor: 'pointer',
          }}
          onClick={() => routerHistory.push(PEOPLE_ME_ACTION_ITEMS)}
        >
          <div style={{ padding: spacing.p5 }}>
            <Typography variant="title2">{polyglot.t('UserTodosVersionSmall.action')}</Typography>
          </div>
        </Box>
        <Box sx={{ height: '100%' }}>
          {Boolean(todos && todos?.personal?.totalCount === 0) ? (
            <UserTodosEmptyState />
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
              }}
            >
              {todos?.personal?.todosItems?.map((todo) => {
                switch (todo.kind) {
                  case KindTypes.IncompleteTasks:
                    return <TaskTodoVersionSmall key="overdue-tasks" todo={todo} />;

                  case KindTypes.PendingRequest:
                    return <TimeRequestTodoVersionSmall key="pending-reqs" todo={todo} />;

                  case KindTypes.PendingPHCancellation:
                    return <PHDeletionRequestsTodoVersionSmall key="pending-ph-deletion-reqs" todo={todo} />;

                  case KindTypes.ExpenseRequest:
                    return <ExpenseRequestTodoVersionSmall key="expense-reqs" todo={todo} />;

                  case KindTypes.InvoiceRequest:
                    return <InvoiceRequestTodoVersionSmall key="invoice-reqs" todo={todo} />;

                  case KindTypes.PendingBenefitPayment:
                    return <PendingBenefitPaymentTodoVersionSmall key="pending-benefit-payments" todo={todo} />;

                  case KindTypes.SubmittedAttendance:
                    return <AttendanceTodoVersionSmall key="submitted-reqs" todo={todo} />;

                  case KindTypes.UnfinishedAttendance:
                    return <ShiftsTodoVersionSmall key="unfinished-shifts" todo={todo} />;

                  case KindTypes.MissingDocuments:
                    return <DocumentTodoVersionSmall key="missing-docs" todo={todo} />;

                  case KindTypes.PendingContracts:
                    return <ContractTodoVersionSmall key="pending-contracts" todo={todo} />;

                  case KindTypes.PendingReviews:
                    return <ReviewTodoVersionSmall key="pending-reviews" todo={todo} />;

                  case KindTypes.PendingRequests:
                    return <AppTodoVersionSmall key="app-requests" todo={todo} />;

                  case KindTypes.SubmittedRequestForm:
                    return <SubmittedRequestTodoVersionSmall key="submitted-requests" todo={todo} />;
                  case KindTypes.BenefitRequest:
                    return <BenefitRequestTodoVersionSmall key="submitted-requests" todo={todo} />;
                  default:
                    return <></>;
                }
              })}

              {Boolean(todos?.personal?.totalCount && todos?.personal?.totalCount - limit > 0) && (
                <div
                  onClick={() => routerHistory.push(PEOPLE_ME_ACTION_ITEMS)}
                  role="button"
                  className="clickable"
                  style={{ padding: spacing.p5 }}
                >
                  <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
                    {todos?.personal?.totalCount && todos.personal.totalCount > 0
                      ? polyglot.t('UserTodosVersionSmall.more', {
                          smart_count: todos?.personal?.totalCount && todos?.personal?.totalCount - limit,
                        })
                      : ''}
                  </Typography>
                </div>
              )}
            </Box>
          )}
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
