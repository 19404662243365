import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { OptionObj } from '@v2/components/forms/user-select/single-user-select.component';
import {
  CustomRuleOptions,
  getUserSelectOptionLabel,
  UserSelectFiltersOptions,
} from '@v2/components/user-select-type/user-select.interface';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { getCustomRule } from '@v2/feature/absence/absence.util';
import { SettingsMembersList } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-members-list.component';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { EditPolicyMembersDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-members-edit-drawer.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { CompanyDepartmentAPI } from '@/api-client/company-department.api';
import { CompanyAPI } from '@/api-client/company.api';
import { SiteAPI } from '@/api-client/site.api';

interface AbsencePolicyGeneralSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const AbsencePolicyMembersSection = ({ absencePolicy, refresh }: AbsencePolicyGeneralSectionProps) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [ruleDataOption, setRuleDataOption] = useState<OptionObj[]>([]);

  const { nonTerminatedCachedUsers: companyUsers } = useCachedUsers();

  const listOfUsers = useMemo(() => {
    if (absencePolicy.membersRule === UserSelectFiltersOptions.Everyone)
      return companyUsers.map((u) => ({
        userId: u.userId,
        name: u.displayName ?? `${u.firstName} ${u.lastName}`,
      }));

    const selectedIds: number[] = absencePolicy.selectedMembersIds ?? [];
    const userIdsSet = new Set(selectedIds);

    return companyUsers
      .filter((u) => userIdsSet.has(u.userId))
      .map((u) => ({
        userId: u.userId,
        name: u.displayName ?? `${u.firstName} ${u.lastName}`,
      }));
  }, [absencePolicy.membersRule, absencePolicy.selectedMembersIds, companyUsers]);

  const getCustomRuleData = useCallback(async () => {
    const ruleTypeArray = absencePolicy.customRule ? absencePolicy.customRule?.split('=') : [];
    if (ruleTypeArray.length === 0) return;
    const ruleType = ruleTypeArray[0] ?? [];
    if (ruleType === CustomRuleOptions.Site) {
      const sitesData = await SiteAPI.listSites(true);
      setRuleDataOption(
        sitesData.map((s) => {
          return { label: s.name, value: s.id };
        })
      );
    }

    if (ruleType === CustomRuleOptions.Department) {
      const departmentsData = await CompanyDepartmentAPI.getCompanyDepartments();
      setRuleDataOption(
        departmentsData.map((d) => {
          return { label: d.name, value: d.id };
        })
      );
    }

    if (ruleType === CustomRuleOptions.Entity) {
      const entitiesData = (await CompanyAPI.getGeneralSettings())?.entities ?? [];
      setRuleDataOption(
        entitiesData.map((e) => {
          return { label: e.legalName, value: e.id };
        })
      );
    }
  }, [absencePolicy.customRule]);

  useEffect(() => {
    getCustomRuleData();
  }, [getCustomRuleData]);

  const customRuleString = useMemo(() => getCustomRule(absencePolicy.customRule, ruleDataOption, polyglot), [
    absencePolicy,
    ruleDataOption,
    polyglot,
  ]);

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyMembersSection.members')}
      onEdit={() => setIsEditDrawerOpen(true)}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyMembersSection.members'),
                value: getUserSelectOptionLabel(absencePolicy.membersRule, polyglot),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyMembersSection.rule'),
                value: polyglot.t('MembersSectionReadonly.selectAllFrom', { source: customRuleString }),
                hidden: !customRuleString,
              },
              {
                type: SectionItemType.Component,
                value: <SettingsMembersList members={listOfUsers} />,
              },
            ],
          },
        ]}
      />

      <EditPolicyMembersDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
    </SettingsSectionContent>
  );
};
