import { Typography } from '@mui/material';
import Polyglot from 'node-polyglot';

import { SECONDARY_CHART_COLOR } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface CountryHolidays {
  readonly start: Date;
  readonly end: Date;
  readonly name: string;
  readonly type: string;
  readonly date: string;
  countryCode: string;
}

export interface CompanyEvent {
  id: number;
  companyId: number;
  name: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
  startDate: string;
  endDate: string | null;
  morningOnly: boolean;
  afternoonOnly: boolean;
  blockTimeawayRequests: boolean;
  createdBy: number;
  color?: string;
}

export interface CreateCompanyEvent {
  name: string;
  description?: string;
  startDate: string;
  endDate: string | null;
  morningOnly: boolean;
  afternoonOnly: boolean;
  blockTimeawayRequests: boolean;
  color?: string;
}

export interface FormData {
  readonly id?: number;
  readonly name: string;
  readonly description?: string;
  readonly startDate: string;
  readonly endDate: string | null;
  readonly morningOnly: boolean;
  readonly afternoonOnly: boolean;
  readonly blockTimeawayRequests: boolean;
  readonly startFullOrHalfDay: string;
  readonly endFullOrHalfDay: string;
  readonly multipleDays: boolean;
  readonly color?: string | null;
}

export const FullDay = (polyglot: Polyglot) => {
  return { value: 'full', label: polyglot.t('CalendarInterface.fullDay') };
};

export const MorningOnly = (polyglot: Polyglot) => {
  return { value: 'morningOnly', label: polyglot.t('CalendarInterface.morningOnly') };
};

export const AfternoonOnly = (polyglot: Polyglot) => {
  return { value: 'afternoonOnly', label: polyglot.t('CalendarInterface.afternoonOnly') };
};

export const OtherLength = (polyglot: Polyglot) => {
  return { value: 'otherLength', label: polyglot.t('CalendarInterface.otherLength') };
};

export const fullOrMorningOnlyValues = (polyglot: Polyglot) => {
  return [FullDay(polyglot), MorningOnly(polyglot)];
};

export const fullOrAfternoonOnlyValues = (polyglot: Polyglot) => {
  return [FullDay(polyglot), AfternoonOnly(polyglot)];
};

export const fullOrHalfDayValues = (polyglot: Polyglot) => {
  return [FullDay(polyglot), MorningOnly(polyglot), AfternoonOnly(polyglot), OtherLength(polyglot)];
};

export const colorOptions = SECONDARY_CHART_COLOR.map((s) => {
  return {
    value: s,
    label: <Typography sx={{ bgcolor: s, width: '14px', height: '14px', borderRadius: '100%', mt: spacing.m4 }} />,
  };
});

export interface CalendarSettingProps {
  companyId: number;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  viewSetting: 'company' | 'me' | 'team';
}

export interface UpsertCalendarSettingProps {
  id?: number;
  viewSetting: 'company' | 'me' | 'team';
}

export enum ViewSettingEnum {
  Company = 'company',
  Me = 'me',
  Team = 'team',
}

export const ViewSettingValueArray = [ViewSettingEnum.Company, ViewSettingEnum.Team, ViewSettingEnum.Me];

export const ViewSettingsLabel = (polyglot: Polyglot) => {
  return {
    [ViewSettingEnum.Company]: polyglot?.t('CalendarSettingsPage.company'),
    [ViewSettingEnum.Team]: polyglot?.t('CalendarSettingsPage.team'),
    [ViewSettingEnum.Me]: polyglot?.t('CalendarSettingsPage.me'),
  };
};

export const ViewSettingsOptionLabel = (polyglot: Polyglot) => {
  return [
    { label: polyglot?.t('CalendarSettingsPage.company'), value: ViewSettingEnum.Company },
    { label: polyglot?.t('CalendarSettingsPage.team'), value: ViewSettingEnum.Team },
    { label: polyglot?.t('CalendarSettingsPage.me'), value: ViewSettingEnum.Me },
  ];
};

export interface OutlookCalendarPublishLinks {
  icsURL: string;
  htmlURL: string;
}

export type OutlookCalendarPublishDrawerMode = 'publish' | 'subscribe';

export enum iCalLinkModeEnum {
  ALL = 'all',
  FILTERED = 'filtered',
}

export type iCalLinkMode = iCalLinkModeEnum.ALL | iCalLinkModeEnum.FILTERED;
