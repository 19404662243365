import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { CreateAttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { getScheduleGeneralValidationSchema } from '@v2/feature/attendance/attendance.util';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE } from '@/lib/routes';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const AttendanceSettingsNewScheduleDrawer = ({ isOpen, setIsOpen, refresh }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: CreateAttendanceScheduleDto) => {
      try {
        setLoading(true);
        const scheduleId = await AttendanceAPI.createAttendanceSchedule(values);

        await refresh();
        setIsOpen(false);
        history.push(generatePath(SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE, { scheduleId }));
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [history, showMessage, refresh, setIsOpen, polyglot]
  );

  const formik = useFormik<CreateAttendanceScheduleDto>({
    initialValues: {
      name: '',
    },
    validationSchema: getScheduleGeneralValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AttendanceCompanySettingsAttendanceSchedulesPage.new')}</Typography>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="name"
              label={polyglot.t('ScheduleGeneralForm.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={(formik.touched.name && formik.errors.name) ?? ' '}
            />
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
          <UpgradeToProModal
            isOpen={upgradeModalOpen}
            setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
            planName={PlanNames.PEOPLE_PRO}
            messageSuffix="proGeneric"
          />
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
