import { Fragment } from 'react';

import { Box } from '@mui/material';
import { Redirect, Switch, generatePath, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_BUILDER_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_GENERAL_ROUTE,
} from '@/lib/routes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { ReviewTemplateEndpoints } from '@/v2/feature/growth/reviews/api-client/review-template.api';
import { ReviewTemplateBuilderPage } from '@/v2/feature/growth/reviews/features/review-template/review-template-builder/pages/review-template-builder.page';
import { ReviewTemplateGeneralPage } from '@/v2/feature/growth/reviews/features/review-template/review-template-builder/pages/review-template-general.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const getPageConfig = (templateId: string): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'General',
          stub: 'general',
          path: generatePath(SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_GENERAL_ROUTE, { templateId }),
        },
        {
          title: 'Questions',
          stub: 'questions',
          path: generatePath(SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_BUILDER_ROUTE, { templateId }),
        },
      ],
    },
  ] as NavConfigItem[];
};

export const ReviewTemplateOverviewPage = () => {
  const params = useParams<{ templateId: string }>();
  const templateId = params.templateId;

  const {
    data: templateObject,
    mutate: refreshTemplate,
    isLoading: templateLoading,
  } = useApiClient(ReviewTemplateEndpoints.getReviewTemplateStructurebyId(templateId), { suspense: false });
  return (
    <Fragment>
      <DomainSideMenuContent
        title="Templates"
        pageConfig={getPageConfig(templateId)}
        backPath={SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper loading={templateLoading} noHorizontalPadding>
        <Box className="main-title" sx={{ p: spacing.p16 }}>
          <Typography variant="title2">
            {templateObject && templateObject.template ? templateObject.template.name : 'Template'}
          </Typography>
        </Box>
        <Switch>
          <RouteScopesControl scopes={['reviews:all']} path={SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_GENERAL_ROUTE}>
            <ReviewTemplateGeneralPage templateObject={templateObject} refreshTemplate={refreshTemplate} />
          </RouteScopesControl>
          <RouteScopesControl scopes={['reviews:all']} path={SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_BUILDER_ROUTE}>
            <ReviewTemplateBuilderPage templateObject={templateObject} refreshTemplate={refreshTemplate} />
          </RouteScopesControl>

          <Redirect to={SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_GENERAL_ROUTE} />
        </Switch>
      </ContentWrapper>
    </Fragment>
  );
};
