import axios from 'axios';

import {
  BankCountInterface,
  MoveToSection,
  OrderPreferenceUpdate,
  RemoveFromSection,
  ReviewTemplate,
  ReviewTemplateById,
  ReviewTemplateCreation,
  ReviewTemplateUpdate,
} from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewTemplateAPI {
  static async createReviewTemplate(templateBody: ReviewTemplateCreation): Promise<ReviewTemplate> {
    return (await axios.post('/apiv2/review-template', templateBody)).data;
  }

  static async updateReviewTemplate(updateBody: ReviewTemplateUpdate): Promise<ReviewTemplate> {
    return (await axios.patch('/apiv2/review-template', updateBody)).data;
  }

  static async updateOrderReviewTemplate(updateBody: OrderPreferenceUpdate): Promise<ReviewTemplate> {
    return (await axios.patch('/apiv2/review-template/order', updateBody)).data;
  }

  static async moveQuestionOutsideSection(updateBody: RemoveFromSection): Promise<ReviewTemplate> {
    return (await axios.patch(`/apiv2/review-template/remove-from-section`, updateBody)).data;
  }

  static async moveQuestionInsideSection(updateBody: MoveToSection): Promise<ReviewTemplate> {
    return (await axios.patch(`/apiv2/review-template/add-to-section`, updateBody)).data;
  }

  static async getReviewTemplatebyId(templateId: string): Promise<ReviewTemplate> {
    return (await axios.get(`/apiv2/review-template/${templateId}`)).data;
  }

  static async deleteTemplatebyId(templateId: string): Promise<void> {
    await axios.delete(`/apiv2/review-template/${templateId}`);
  }

  static async addQuestionsToTemplate(questionBankIds: string[], templateId: string): Promise<any> {
    return (await axios.post(`/apiv2/review-template/add-questions/${templateId}`, questionBankIds)).data;
  }
}

export class ReviewTemplateEndpoints {
  static getReviewTemplatesCompanyId(search?: string): Endpoint<ReviewTemplate[]> {
    const urlParams = new URLSearchParams({
      ...(search && { search }),
    });
    return {
      url: `/apiv2/review-template?${urlParams.toString()}`,
    };
  }

  static getBankCount(): Endpoint<BankCountInterface> {
    return {
      url: '/apiv2/review-template/bank-count',
    };
  }

  static getReviewTemplateStructurebyId(templateId: string): Endpoint<ReviewTemplateById> {
    return {
      url: `/apiv2/review-template/${templateId}`,
    };
  }
}
