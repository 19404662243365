import React, { useState } from 'react';

import { Box } from '@mui/material';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceScheduleEditPayrollDrawer } from '@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-payroll-drawer.component';
import { AttendancePayrollPenaltiesSubSection } from '@v2/feature/attendance/settings/components/attendance-payroll-penalties-subsection.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { useCompanyConfigState } from '@/hooks/company-config.hook';

interface SectionProps {
  readonly schedule: AttendanceScheduleDto;
  readonly refreshSchedule: () => Promise<void>;
}

export const AttendanceSettingsPayrollSection = ({ schedule, refreshSchedule }: SectionProps) => {
  const { companyConfig } = useCompanyConfigState();

  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);

  return (
    <Box>
      <SettingsSectionContent
        title={polyglot.t('AttendanceSchedule.payroll')}
        onEdit={() => {
          setEdit(true);
        }}
      >
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('PayrollModule.includedInPayroll'),
                  value: schedule.includedInPayroll ? polyglot.t('General.yes') : polyglot.t('General.no'),
                },
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('PayItemModule.payCode'),
                  value: schedule.payCode ?? polyglot.t('General.none'),
                },
              ],
            },
          ]}
        />

        <AttendanceScheduleEditPayrollDrawer
          isOpen={edit}
          setIsOpen={setEdit}
          attendanceSchedule={schedule}
          refresh={refreshSchedule}
        />
      </SettingsSectionContent>

      {(companyConfig.inGlobalPayroll || companyConfig.inPayroll) && schedule.includedInPayroll && (
        <AttendancePayrollPenaltiesSubSection schedule={schedule} />
      )}
    </Box>
  );
};
