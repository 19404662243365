import axios from 'axios';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import {
  NotificationSettingsForEvent,
  NotificationSystemName,
  UserNotifications,
} from '@/v2/feature/notification/notification-settings/notification.interface';

export async function updateNotificationSettings(
  notificationName: NotificationSystemName,
  notificationSettings: NotificationSettingsForEvent
): Promise<void> {
  await axios.put(`/apiv2/users/notifications`, { notificationName, notificationSettings });
}

export async function toggleNotificationStatus(
  notificationName: NotificationSystemName,
  newStatus: boolean
): Promise<void> {
  await axios.put(`/apiv2/users/notifications/toggle-status`, { notificationName, newStatus });
}

export class UserNotificationEndpoints {
  static getNotificationSettings(): Endpoint<UserNotifications> {
    return {
      url: '/apiv2/users/notifications',
    };
  }
}
