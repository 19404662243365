import { useCallback, useMemo, useState } from 'react';

import { Stack, SwipeableDrawer } from '@mui/material';
import { CompanyPayrollOverview } from '@shared/modules/payroll/company-payroll';
import { ColumnDef } from '@tanstack/react-table';
import { pick } from 'lodash';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { TitledHeaderStack } from '@/v2/components/titled-header.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SetupPayrollDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/set-up-payroll-drawer.component';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort, filterByTextSearch } from '@/v2/util/array.util';
import { formatCurrency } from '@/v2/util/currency-format.util';

type PayrollIntroTable = CompanyPayrollOverview['employees'][number];

export const PayrollCompanyIntro = () => {
  const { polyglot } = usePolyglot();
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [showPayrollSetupDrawer, setShowPayrollSetupDrawer] = useState(false);

  const { data } = useApiClient(PayrollEndpoints.getCompanyPayrollOverview(), { suspense: false });
  const employees = data?.employees;

  const filteredEmployees = useMemo(() => {
    if (!employees) return employees;
    if (!searchQuery && !categoryFilter) return employees;

    let result = employees;
    if (categoryFilter) {
      const sections = Object.fromEntries<string>(
        categoryFilter.split('&').map((s) => s.split('=')) as [string, string][]
      );
      for (const [category, filter] of Object.entries(sections)) {
        if (category === polyglot.t('CompanyPayroll.department')) {
          const departmentIds = new Set(filter.split(',').map((s) => Number(s)));
          result = result.filter((user) => user.department && departmentIds.has(user.department.id));
        }
        if (category === polyglot.t('CompanyPayroll.entity')) {
          const entityIds = new Set(filter.split(',').map((s) => Number(s)));
          result = result.filter((user) => user.entity && entityIds.has(user.entity.id));
        }
        if (category === polyglot.t('CompanyPayroll.payrollStatus')) {
          const statuses = new Set(filter.split(','));
          result = result.filter((user) => statuses.has(user.status));
        }
      }
    }

    return filterByTextSearch(searchQuery, result, (item) => [
      `${item.firstName} ${item.lastName}`,
      item.department?.name,
      item.entity?.legalName,
    ]);
  }, [employees, searchQuery, categoryFilter, polyglot]);

  const starterColor = themeColors.GreenMiddle;
  const leaverColor = themeColors.RedDark;

  const getStatusLabel = useCallback(
    (status: PayrollIntroTable['status']) => {
      return {
        current: { label: polyglot.t('CompanyPayroll.payrollStatusCurrent'), color: undefined },
        starter: { label: polyglot.t('CompanyPayroll.payrollStatusStarter'), color: starterColor },
        leaver: { label: polyglot.t('CompanyPayroll.payrollStatusLeaver'), color: leaverColor },
      }[status];
    },
    [leaverColor, polyglot, starterColor]
  );

  const columns = useMemo<ColumnDef<PayrollIntroTable, PayrollIntroTable>[]>(() => {
    return [
      {
        id: 'user',
        header: () => polyglot.t('CompanyPayroll.name'),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortString(a, b, (item) => `${item.firstName} ${item.lastName}`, { sensitivity: 'base' }),
        cell: (c) => <UserCell userId={c.row.original.userId} name={pick(c.row.original, ['firstName', 'lastName'])} />,
      },
      {
        id: 'department',
        header: () => polyglot.t('CompanyPayroll.department'),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortString(a, b, (item) => item.department?.name, { sensitivity: 'base' }),
        cell: (c) => c.row.original.department?.name ?? '',
      },
      {
        id: 'entity',
        header: () => polyglot.t('CompanyPayroll.entity'),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortString(a, b, (item) => item.entity?.legalName, { sensitivity: 'base' }),
        cell: (c) => c.row.original.entity?.legalName ?? '',
      },
      {
        id: 'status',
        header: () => polyglot.t('CompanyPayroll.payrollStatus'),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortString(a, b, (item) => getStatusLabel(item.status).label),
        cell: (c) => {
          const { color, label } = getStatusLabel(c.row.original.status);
          return (
            <Typography variant="caption" sx={{ color }}>
              {label}
            </Typography>
          );
        },
      },
      {
        id: 'salary',
        header: () => polyglot.t('CompanyPayroll.monthlySalary'),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.salary?.rate),
        cell: (c) =>
          c.row.original.salary && (
            <Typography variant="caption" sx={{ textAlign: 'right', width: '6em' }}>
              {formatCurrency(c.row.original.salary.rate, undefined, c.row.original.salary.currency)}
            </Typography>
          ),
      },
    ];
  }, [getStatusLabel, polyglot]);

  const currentPeriod = useMemo(() => {
    if (!data) return '';
    // 'Jan 00'
    return new Date(data.payrunEndDate).toLocaleDateString('en-GB', { month: 'short', year: '2-digit' });
  }, [data]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('CompanyPayroll.salary')}</Typography>}
        showAction
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() => {
              setShowPayrollSetupDrawer(true);
            }}
          >
            {polyglot.t('CompanyPayroll.setupPayroll')}
          </ButtonComponent>
        }
      />

      <ContentWrapper border={false} noHorizontalPadding sx={{ pb: 0, ml: spacing.ml50 }}>
        {data && (
          <Stack sx={{ gap: spacing.g20, flex: 1 }}>
            <TitledHeaderStack
              items={[
                {
                  label: polyglot.t('CompanyPayroll.currentPeriod'),
                  value: <Typography variant="title1">{currentPeriod}</Typography>,
                },
                {
                  label: polyglot.t('CompanyPayroll.employeeCount'),
                  value: (
                    <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
                      <Typography variant="title1">{data.employees.length}</Typography>
                      <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
                        {data.starters > 0 && (
                          <Typography variant="title3" sx={{ color: starterColor }}>{`+${data.starters}`}</Typography>
                        )}
                        {data.leavers > 0 && (
                          <Typography variant="title3" sx={{ color: leaverColor }}>{`-${data.leavers}`}</Typography>
                        )}
                      </Stack>
                    </Stack>
                  ),
                },
              ]}
            />
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
              <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
              <CategoryFilters
                filterTypes={{
                  [polyglot.t('CompanyPayroll.department')]: [...data.departments]
                    .sort((a, b) => caseInsensitiveSort(a, b, (x) => x.name))
                    .map((d) => ({ label: d.name, value: d.id })),
                  [polyglot.t('CompanyPayroll.entity')]: [...data.entities]
                    .sort((a, b) => caseInsensitiveSort(a, b, (x) => x.legalName))
                    .map((e) => ({ label: e.legalName, value: e.id })),
                  [polyglot.t('CompanyPayroll.payrollStatus')]: [
                    { label: polyglot.t('CompanyPayroll.payrollStatusStarter'), value: 'starter' },
                    { label: polyglot.t('CompanyPayroll.payrollStatusLeaver'), value: 'leaver' },
                    { label: polyglot.t('CompanyPayroll.payrollStatusCurrent'), value: 'current' },
                  ],
                }}
                setFilterString={setCategoryFilter}
                filterString={categoryFilter}
              />
            </Stack>

            <Stack sx={{ flex: 1 }}>
              {filteredEmployees && (
                <BasicTable
                  rowData={filteredEmployees}
                  columnData={columns}
                  initialPageSize={100}
                  fixedLastColumn={false}
                  initialSort={[{ id: 'user', desc: true }]}
                  style={{ flex: '1 1 0' }}
                />
              )}
            </Stack>
          </Stack>
        )}
      </ContentWrapper>
      <SwipeableDrawer
        anchor="right"
        open={showPayrollSetupDrawer}
        onOpen={() => {}}
        onClose={() => {
          setShowPayrollSetupDrawer(false);
        }}
      >
        <SetupPayrollDrawer />
      </SwipeableDrawer>
    </RootStyle>
  );
};
