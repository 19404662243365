export const DASHBOARD_ROUTE = '/';

export const BACKOFFICE_ROUTE = '/backoffice';
export const LOGIN_ROUTE = '/login';
export const LOGIN_ROUTE_SSO_IOS = '/login/sso-ios';
export const LOGIN_2FA_ROUTE = '/login/2fa';
export const LOGOUT_ROUTE = '/logout';
export const PASSWORD_FORGOT_ROUTE = '/password/forgot';
export const PASSWORD_RESET_ROUTE = '/password/reset';
export const SIGN_UP = '/usethistojoinzelt';
export const XERO_SIGN_UP = '/xerosignup';
export const QUICKBOOKS_DISCONNECTED = '/quickbooks-disconnected';
export const REGISTER_ORGANIZATION = '/register/:email';

/** PEOPLE ROUTES */
export const PEOPLE_ROUTE = '/people';
export const PEOPLE_COMPANY_DIRECTORY_ROUTE = '/people/company/directory';
export const PEOPLE_TEAM_DIRECTORY_ROUTE = '/people/team/directory';
export const PEOPLE_ME_DIRECTORY_ROUTE = '/people/me/directory';

export const PEOPLE_COMPANY_ACTION_ITEMS = '/people/company/action-items';
export const PEOPLE_TEAM_ACTION_ITEMS = '/people/team/action-items';
export const PEOPLE_ME_ACTION_ITEMS = '/people/me/action-items';

export const ONBOARDING_DOMAIN_ROUTE = '/people/company/onboarding';
export const ONBOARDING_ROUTE = '/people/company/onboarding/overview';
export const ONBOARDING_INTRO_ROUTE = '/people/company/onboarding/welcome';
export const ONBOARDING_NEW_USER_ROUTE = '/people/company/onboarding/overview/users/new';
export const ONBOARDING_USER_STATUS_ROUTE = '/people/company/onboarding/overview/users/:userId';
export const ONBOARDING_USER_STATUS_APPS_ROUTE = '/people/company/onboarding/overview/users/:userId/apps';
export const ONBOARDING_USER_STATUS_DEVICE_ROUTE = '/people/company/onboarding/overview/users/:userId/device';
export const ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE =
  '/people/company/onboarding/overview/users/:userId/device/order';

export const OFFBOARDING_ROUTE = '/people/company/offboarding';
export const USER_OFFBOARDING_ROUTE = '/people/company/offboarding/:userId';
export const USER_OFFBOARDING_TASKS_ROUTE = '/people/company/offboarding/:userId/tasks';
export const USER_OFFBOARDING_ABSENCE_ROUTE = '/people/company/offboarding/:userId/absence';
export const USER_OFFBOARDING_APPS_ROUTE = '/people/company/offboarding/:userId/apps';
export const USER_OFFBOARDING_DEVICES_ROUTE = '/people/company/offboarding/:userId/devices';
export const USER_OFFBOARDING_MANAGER_CHANGE_ROUTE = '/people/company/offboarding/:userId/manager-change';

export const USER_DETAILS_ROUTE = '/people/users/:userId(\\d+)';
export const USER_DETAILS_PROFILE_ROUTE = '/people/users/:userId(\\d+)/personal';
export const USER_ABSENCE_ROUTE = '/people/users/:userId/absences';
export const USER_ATTENDANCE_ROUTE = '/people/users/:userId/attendance';
export const USER_ATTENDANCE_WEEK_ROUTE = '/people/users/:userId/attendance/week/:weekNo/:year';

export const DOCUMENTS_ROUTE = '/people';
export const DOCUMENTS_COMPANY_DOMAIN_ROUTE = '/people/company/documents';
export const DOCUMENTS_COMPANY_ROUTE = '/people/company/documents/overview';
export const DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE = '/people/company/documents/bulk-upload';
export const DOCUMENTS_COMPANY_V2_ROUTE = '/people/company/documents/v2';

// CONTRACT SIGNING ROUTES
export const TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX = '/people/me/documents/overview/contract/sign/';
export const TEMPLATE_CONTRACT_SIGN_ROUTE =
  '/people/me/documents/overview/contract/sign/:templateId/:contractId/:userId';
export const TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE =
  '/people/me/documents/overview/contract/company-sign/:templateId/:contractId/:userId';
export const OLD_TEMPLATE_SIGNING_ROUTE_FOR_REDIRECT =
  '/template/contract/company-sign/:templateId/:contractId/:userId';

export const DOCUMENTS_TEAM_DOMAIN_ROUTE = '/people/team/documents';
export const DOCUMENTS_TEAM_ROUTE = '/people/team/documents/overview';
export const DOCUMENTS_ME_DOMAIN_ROUTE = '/people/me/documents';
export const DOCUMENTS_ME_ROUTE = '/people/me/documents/overview';
export const USER_MONEY_ROUTE = '/people/users/:userId/money';
export const ADMIN_USER_DOCUMENTS_ROUTE = '/people/users/:userId/documents';
// export const ADMIN_USER_GROWTH_ROUTE = '/people/users/:userId/growth';
export const ADMIN_USER_REVIEWS_ROUTE = '/people/users/:userId/reviews';
export const ADMIN_USER_REVIEWS_RESULT_ROUTE = '/people/users/:userId/reviews/result';
export const ADMIN_USER_REVIEWS_RESULT_DETAIL_ROUTE = '/people/users/:userId/reviews/result/:cycleId/detail';
export const ADMIN_USER_REVIEWS_RESULT_DETAIL_OVERVIEW_ROUTE =
  '/people/users/:userId/reviews/result/:cycleId/detail/overview';
export const ADMIN_USER_REVIEWS_RESULT_DETAIL_RADAR_ROUTE =
  '/people/users/:userId/reviews/result/:cycleId/detail/radar';

export const ADMIN_USER_SURVEYS_ROUTE = '/people/users/:userId/growth/surveys';
export const USER_PAYROLL_SETTINGS = '/people/users/:userId/money/payroll/settings';
export const USER_PAYROLL_NEW_STARTER = '/people/users/:userId/money/payroll/newstarter';
export const USER_PAYROLL_PAYSLIPS = '/people/users/:userId/money/payroll';

export const USER_PAY_ITEMS_ROUTE = '/people/users/:userId/money/pay-items';

export const USER_EXPENSES_ROUTE = '/people/users/:userId/money/expenses';
export const USER_INVOICES_ROUTE = '/people/users/:userId/money/invoices';

export const USER_EXPENSES_NEW_EXPENSE_ROUTE = '/people/users/:userId/money/expenses/new';
export const USER_INVOICES_NEW_INVOICE_ROUTE = '/people/users/:userId/money/invoices/new';

export const USER_BENEFITS_ROUTE = '/people/users/:userId/money/benefits';
export const USER_BENEFITS_OVERVIEW_ROUTE = '/people/users/:userId/money/benefits/overview';
// BENEFITS_ME_ROUTE
export const USER_BENEFITS_INSURANCE_ROUTE = '/people/users/:userId/money/benefits/insurance';
export const USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE = '/people/users/:userId/money/benefits/insurance/overview';

export const USER_BENEFITS_CUSTOM_BENEFIT_ROUTE = '/people/users/:userId/money/benefits/custom-benefit';
export const USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE =
  '/people/users/:userId/money/benefits/custom-benefit/:id/overview';
export const USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE =
  '/people/users/:userId/money/benefits/custom-benefit/:id/expense';

export const USER_PENSION_TAB = '/people/users/:userId/money/benefits/pension';
export const USER_BENEFITS_PENSION_SUMMARY_ROUTE = '/people/users/:userId/money/benefits/pension/summary';

export const USER_APPS_TAB = '/people/users/:userId/apps';
export const USER_PERSONAL_TAB = '/people/users/:userId/personal';
export const USER_WORK_TAB = '/people/users/:userId/work';
export const USER_CONTACT_TAB = '/people/users/:userId/contact';
export const USER_COMPENSATION_TAB = '/people/users/:userId/compensation';

export const TASKS_ROUTE = '/people';
export const TASKS_COMPANY_ROUTE = '/people/company/tasks';
export const TASKS_TEAM_ROUTE = '/people/team/tasks';
export const TASKS_ME_ROUTE = '/people/me/tasks';
export const TASKS_COMPANY_OVERVIEW_ROUTE = '/people/company/tasks/overview';
export const TASKS_TEAM_OVERVIEW_ROUTE = '/people/team/tasks/overview';
export const TASKS_ME_OVERVIEW_ROUTE = '/people/me/tasks/overview';

export const USER_TASKS_PAGE = '/people/users/:userId/tasks';
export const USER_DEVICES_PAGE = '/people/users/:userId/devices';

/** MONEY ROUTES */
export const MONEY_ROUTE = '/money';
export const PAYROLL_ROUTE = '/payroll';
export const PAYROLL_COMPANY_ROUTE = '/money/company/salary';
export const PAYROLL_ME_ROUTE = '/money/me/salary';
export const PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE = '/money/company/salary/payroll/:payrollId';
export const PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE =
  '/money/company/salary/payruns/:payrollId/payruns/:taxYear/:payPeriod/:periodNumber';
export const PAYROLL_COMPANY_OVERVIEW_ROUTE = '/money/company/salary/overview';
export const PAYROLL_COMPANY_INTRO_ROUTE = '/money/company/salary/overview/intro';
export const PAYROLL_COMPANY_NEW_PAYROLL_ROUTE = '/money/company/salary/overview/:payrollId/new';
export const PAYROLL_ME_SUMMARY_ROUTE = '/money/me/salary/summary';

export const BENEFITS_ME_ROUTE = '/money/me/benefits/overview';
export const BENEFITS_COMPANY_ROUTE = '/money/company/benefits/overview';

export const INSURANCE_COMPANY_ROUTE = '/money/company/benefits/overview/insurance';
export const INSURANCE_COMPANY_OVERVIEW_ROUTE = '/money/company/benefits/overview/insurance/overview';
export const INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE = '/money/company/benefits/overview/insurance/employees';
export const INSURANCE_ME_ROUTE = '/money/me/benefits/overview/insurance';
export const INSURANCE_ME_OVERVIEW_ROUTE = '/money/me/benefits/overview/insurance/overview';
export const CUSTOM_BENEFIT_OVERVIEW_ROUTE = '/money/me/benefits/overview/custom-benefit/:id/overview';
export const CUSTOM_BENEFIT_EXPENSE_ROUTE = '/money/me/benefits/overview/custom-benefit/:id/expense';

export const PENSION_COMPANY_ROUTE = '/money/company/benefits/overview/pension';

export const PENSION_SETTINGS_ROUTE = '/money/company/benefits/overview/pension/settings';
export const PENSION_COMPANY_SUMMARY_ROUTE = '/money/company/benefits/overview/pension/summary';
export const PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE =
  '/money/company/benefits/overview/pension/summary/providers/:pensionSchemeId';
export const PENSION_ME_ROUTE = '/money/me/benefits/overview/pension';
export const PENSION_ME_SUMMARY_ROUTE = '/money/me/benefits/overview/pension/summary';

export const CUSTOM_BENEFIT_COMPANY_ROUTE = '/money/company/benefits/overview/custom-benefit';
export const DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE = '/money/company/benefits/overview/custom-benefit/:benefitId';
export const CUSTOM_BENEFIT_ME_ROUTE = '/money/me/benefits/overview/custom-benefit';

export const PAYMENT_COMPANY_ROUTE = '/money/company/payments';
export const PAYMENT_COMPANY_OVERVIEW_ROUTE = '/money/company/payments/overview';
export const PAYMENT_COMPANY_NEW_INVOICE_ROUTE = '/money/company/payments/invoices/new';

export const INVOICES_COMPANY_ROUTE = '/money/company/invoices';
export const INVOICES_ME_ROUTE = '/money/me/invoices';
export const INVOICES_TEAM_ROUTE = '/money/team/invoices';
export const INVOICES_COMPANY_OVERVIEW_ROUTE = '/money/company/invoices/overview';
export const INVOICES_ME_OVERVIEW_ROUTE = '/money/me/invoices/overview';
export const INVOICES_TEAM_OVERVIEW_ROUTE = '/money/team/invoices/overview';
export const INVOICES_ME_NEW_INVOICE_ROUTE = '/money/me/invoices/new';
export const INVOICES_COMPANY_NEW_INVOICE_ROUTE = '/money/company/invoices/new';
export const INVOICES_TEAM_NEW_INVOICE_ROUTE = '/money/team/invoices/new';
export const INVOICES_ME_EDIT_INVOICE_ROUTE = '/money/me/invoices/edit/:id';
export const INVOICES_COMPANY_EDIT_INVOICE_ROUTE = '/money/company/invoices/edit/:id';

export const EXPENSES_COMPANY_ROUTE = '/money/company/expenses';
export const EXPENSES_ME_ROUTE = '/money/me/expenses';
export const EXPENSES_TEAM_ROUTE = '/money/team/expenses';
export const EXPENSES_TEAM_OVERVIEW_ROUTE = '/money/team/expenses/overview';
export const EXPENSES_TEAM_NEW_EXPENSE_ROUTE = '/money/team/expenses/new';
export const EXPENSE_TEAM_EDIT_EXPENSE_ROUTE = '/money/team/expenses/edit/:id';
export const EXPENSES_COMPANY_OVERVIEW_ROUTE = '/money/company/expenses/overview';
export const EXPENSES_ME_OVERVIEW_ROUTE = '/money/me/expenses/overview';
export const EXPENSE_COMPANY_NEW_EXPENSE_ROUTE = '/money/company/expenses/new';
export const EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE = '/money/company/expenses/edit/:id';
export const EXPENSE_ME_NEW_EXPENSE_ROUTE = '/money/me/expenses/new';
export const EXPENSE_ME_EDIT_EXPENSE_ROUTE = '/money/me/expenses/edit/:id';

export const PAYMENT_ME_ROUTE = '/money/me/payments';
export const PAYMENT_ME_OVERVIEW_ROUTE = '/money/me/payments/overview';
export const PAYMENT_ME_NEW_INVOICE_ROUTE = '/money/me/payments/invoices/new';
export const PAYMENT_ME_NEW_EXPENSE_ROUTE = '/money/me/payments/expenses/new';

export const PAYMENT_TEAM_ROUTE = '/money/team/payments';
export const PAYMENT_TEAM_OVERVIEW_ROUTE = '/money/team/payments/overview';
export const PAYMENTS_TEAM_SELECT_BANK_ROUTE = '/money/team/payments/overview/select-bank';
export const PAYMENTS_TEAM_CREATE_ROUTE = '/money/team/payments/overview/create';
export const PAYMENT_TEAM_NEW_INVOICE_ROUTE = '/money/team/payments/invoices/new';

/** TIME ROUTES */
export const TIME_ROUTE = '/time';
export const TIME_COMPANY_CALENDAR_ROUTE = '/time/company/calendar';
export const TIME_TEAM_CALENDAR_ROUTE = '/time/team/calendar';
export const TIME_ME_CALENDAR_ROUTE = '/time/me/calendar';

export const ABSENCE_COMPANY_ROUTE = '/time/company/absence';

export const ABSENCE_COMPANY_REQUESTS_ROUTE = '/time/company/absence/requests';
export const ABSENCE_COMPANY_BALANCES_ROUTE = '/time/company/absence/balances';

export const ABSENCE_TEAM_ROUTE = '/time/team/absence';
export const ABSENCE_TEAM_REQUESTS_ROUTE = '/time/team/absence/requests';
export const ABSENCE_TEAM_BALANCES_ROUTE = '/time/team/absence/balances';

export const ABSENCE_ME_ROUTE = '/time/me/absence';
export const ABSENCE_ME_REQUESTS_ROUTE = '/time/me/absence/requests';

export const ATTENDANCE_COMPANY_ROUTE = '/time/company/attendance';
export const ATTENDANCE_COMPANY_REQUESTS_ROUTE = '/time/company/attendance/requests';
export const ATTENDANCE_COMPANY_WEEK_DETAILS_ROUTE =
  '/time/company/attendance/requests/weekly/:weekNo/:year/users/:userId';

export const ATTENDANCE_TEAM_ROUTE = '/time/team/attendance';
export const ATTENDANCE_TEAM_REQUESTS_ROUTE = '/time/team/attendance/requests';
export const ATTENDANCE_TEAM_WEEK_DETAILS_ROUTE = '/time/team/attendance/requests/weekly/:weekNo/:year/users/:userId';

export const ATTENDANCE_ME_ROUTE = '/time/me/attendance';
export const ATTENDANCE_ME_REQUESTS_ROUTE = '/time/me/attendance/requests';

/**PUBLIC API ROUTES */
export const PARTNER_API_AUTHORISE_ROUTE = '/partner-api/authorize';
/** GROWTH ROUTES */
export const GROWTH_ROUTE = '/growth';
export const REVIEWS_COMPANY_ROUTE = '/growth/company/reviews';
export const REVIEWS_COMPANY_ONGOING_ROUTE = '/growth/company/reviews/ongoing';

export const REVIEWS_COMPANY_ONGOING_SETUP_ROUTE = '/growth/company/reviews/ongoing/setup/:cycleId';
export const REVIEWS_COMPANY_ONGOING_SETUP_GENERAL_ROUTE = '/growth/company/reviews/ongoing/setup/:cycleId/general';
export const REVIEWS_COMPANY_ONGOING_SETUP_FORM_ROUTE = '/growth/company/reviews/ongoing/setup/:cycleId/form';
export const REVIEWS_COMPANY_ONGOING_SETUP_PARTICIPANTS_ROUTE =
  '/growth/company/reviews/ongoing/setup/:cycleId/participants';
export const REVIEWS_COMPANY_ONGOING_SETUP_VISIBILITY_ROUTE =
  '/growth/company/reviews/ongoing/setup/:cycleId/visibility';
export const REVIEWS_COMPANY_ONGOING_SETUP_TIMELINE_ROUTE = '/growth/company/reviews/ongoing/setup/:cycleId/timeline';
export const REVIEWS_COMPANY_ONGOING_SETUP_NOTIFICATION_ROUTE =
  '/growth/company/reviews/ongoing/setup/:cycleId/notifications';
export const REVIEWS_COMPANY_ONGOING_SETUP_LAUNCH_ROUTE = '/growth/company/reviews/ongoing/setup/:cycleId/launch';

export const REVIEWS_COMPANY_ONGOING_DETAIL_ROUTE = '/growth/company/reviews/ongoing/detail/:cycleId';
export const REVIEWS_COMPANY_ONGOING_DETAIL_OVERVIEW_ROUTE = '/growth/company/reviews/ongoing/detail/:cycleId/overview';

export const REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE = '/growth/company/reviews/ongoing/detail/:cycleId/result';
export const REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/result/:revieweeId/detail';
export const REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_OVERVIEW_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/result/:revieweeId/detail/overview';
export const REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_RADAR_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/result/:revieweeId/detail/radar';

export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_ROUTE = '/growth/company/reviews/ongoing/detail/:cycleId/settings';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/general';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/form';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/participants';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_VISIBILITY_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/visibility';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/timeline';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/notifications';
export const REVIEWS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE =
  '/growth/company/reviews/ongoing/detail/:cycleId/settings/launch';

export const REVIEWS_COMPANY_ONGOING_SETTINGS_REVIEWEES_ROUTE =
  '/growth/company/reviews/ongoing/:cycleId/settings/reviewees';
export const REVIEWS_COMPANY_ONGOING_SETTINGS_REVIEWERS_ROUTE =
  '/growth/company/reviews/ongoing/:cycleId/settings/reviewers';
export const REVIEWS_COMPANY_ONGOING_SETTINGS_RESULTS_ROUTE =
  '/growth/company/reviews/ongoing/:cycleId/settings/results';
export const REVIEWS_COMPANY_ONGOING_SETTINGS_NOTIFICATIONS_ROUTE =
  '/growth/company/reviews/ongoing/:cycleId/settings/notifications';

export const REVIEWS_COMPANY_ONGOING_SETTINGS_REVIEWERS_EDIT_ROUTE =
  '/growth/company/reviews/ongoing/:cycleId/settings/reviewers/edit';

export const SURVEYS_COMPANY_ROUTE = '/growth/company/surveys';
export const SURVEYS_COMPANY_ONGOING_ROUTE = '/growth/company/surveys/ongoing';
// // // // export const SURVEYS_COMPANY_ONGOING_NEW_SURVEYS_ROUTE = '/growth/company/surveys/ongoing/new-survey-cycle';
export const SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE = '/growth/company/surveys/ongoing/detail/:cycleId';
export const SURVEYS_COMPANY_ONGOING_DETAIL_INSIGHTS_ROUTE = '/growth/company/surveys/ongoing/detail/:cycleId/insights';
export const SURVEYS_COMPANY_ONGOING_DETAIL_HEATMAP_ROUTE = '/growth/company/surveys/ongoing/detail/:cycleId/heatmap';

// export const SURVEYS_COMPANY_ONGOING_DETAIL_OVERVIEW_ROUTE = '/growth/company/surveys/ongoing/detail/:cycleId/overview';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/general';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/form';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/participants';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_VISIBILITY_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/visibility';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/timeline';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/notifications';
export const SURVEYS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE = '/growth/company/surveys/ongoing/detail/:cycleId/result';
export const SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE =
  '/growth/company/surveys/ongoing/detail/:cycleId/settings/launch';

export const SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE = '/growth/company/surveys/ongoing/:cycleId/settings';
export const SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE =
  '/growth/company/surveys/ongoing/:cycleId/settings/general';
export const SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE = '/growth/company/surveys/ongoing/:cycleId/settings/form';
export const SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE =
  '/growth/company/surveys/ongoing/:cycleId/settings/participants';
export const SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE =
  '/growth/company/surveys/ongoing/:cycleId/settings/timeline';
export const SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE =
  '/growth/company/surveys/ongoing/:cycleId/settings/notifications';
export const SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE = '/growth/company/surveys/ongoing/:cycleId/settings/launch';

export const SURVEYS_COMPANY_ONGOING_SETTINGS_RESULTS_ROUTE =
  '/growth/company/surveys/ongoing/:cycleId/settings/results';
export const SURVEYS_COMPANY_RESULTS_FORM_ROUTE = '/growth/company/surveys/ongoing/result/:cycleId';
export const SURVEYS_COMPANY_RESULTS_BY_USER_FORM_ROUTE = '/growth/company/surveys/ongoing/result/:cycleId/:userId';

export const REVIEWS_ME_ROUTE = '/growth/me/reviews';
export const SURVEYS_ME_ROUTE = '/growth/me/surveys';

export const REVIEWS_ME_RESULTS_FORM_ROUTE = '/growth/me/reviews/ongoing/result/:cycleId';

export const REVIEWS_ME_INVITE_ROUTE = '/growth/me/reviews/invite';
export const REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE = '/growth/me/reviews/invite/answers/:entryId/:cycleId';
export const REVIEWS_ME_RESULT_ROUTE = '/growth/me/reviews/result';
export const REVIEWS_ME_RESULT_DETAIL_ROUTE = '/growth/me/reviews/result/:cycleId/:revieweeId/detail';
export const REVIEWS_ME_RESULT_DETAIL_OVERVIEW_ROUTE = '/growth/me/reviews/result/:cycleId/:revieweeId/detail/overview';
export const REVIEWS_ME_RESULT_DETAIL_RADAR_ROUTE = '/growth/me/reviews/result/:cycleId/:revieweeId/detail/radar';

export const REVIEWS_TEAM_ROUTE = '/growth/team/reviews';
export const REVIEWS_TEAM_ONGOING_ROUTE = '/growth/team/reviews/ongoing';

export const REVIEWS_TEAM_ONGOING_SETUP_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId';
export const REVIEWS_TEAM_ONGOING_SETUP_GENERAL_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId/general';
export const REVIEWS_TEAM_ONGOING_SETUP_FORM_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId/form';
export const REVIEWS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId/participants';
export const REVIEWS_TEAM_ONGOING_SETUP_VISIBILITY_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId/visibility';
export const REVIEWS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId/timeline';
export const REVIEWS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE =
  '/growth/team/reviews/ongoing/setup/:cycleId/notifications';
export const REVIEWS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE = '/growth/team/reviews/ongoing/setup/:cycleId/launch';

export const REVIEWS_TEAM_ONGOING_DETAIL_ROUTE = '/growth/team/reviews/ongoing/detail/:cycleId';
export const REVIEWS_TEAM_ONGOING_DETAIL_OVERVIEW_ROUTE = '/growth/team/reviews/ongoing/detail/:cycleId/overview';

export const REVIEWS_TEAM_ONGOING_DETAIL_RESULT_ROUTE = '/growth/team/reviews/ongoing/detail/:cycleId/result';
export const REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/result/:revieweeId/detail';
export const REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_OVERVIEW_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/result/:revieweeId/detail/overview';
export const REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_RADAR_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/result/:revieweeId/detail/radar';

export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_ROUTE = '/growth/team/reviews/ongoing/detail/:cycleId/settings';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/general';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_FORM_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/form';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/participants';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_VISIBILITY_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/visibility';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/timeline';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/notifications';
export const REVIEWS_TEAM_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE =
  '/growth/team/reviews/ongoing/detail/:cycleId/settings/launch';

export const REVIEWS_TEAM_RESULTS_BY_USER_FORM_ROUTE = '/growth/team/reviews/result/:cycleId/:userId';

export const SURVEYS_ME_INVITE_ROUTE = '/growth/me/surveys/invite';
export const SURVEYS_ME_RESULT_ROUTE = '/growth/me/surveys/result';
export const SURVEYS_ME_RESULT_DETAIL_ROUTE = '/growth/me/surveys/result/:cycleId/:participantId/detail';
export const SURVEYS_ME_RESULT_DETAIL_OVERVIEW_ROUTE =
  '/growth/me/surveys/result/:cycleId/:participantId/detail/overview';
export const SURVEYS_ME_RESULT_DETAIL_RADAR_ROUTE = '/growth/me/surveys/result/:cycleId/:participantId/detail/radar';

export const SURVEYS_ME_ONGOING_ROUTE = '/growth/me/surveys/ongoing';
export const SURVEYS_ME_RESULTS_FORM_ROUTE = '/growth/me/surveys/ongoing/result/:cycleId';
export const SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE = '/growth/me/surveys/ongoing/answers/:entryId/:cycleId';
export const SURVEYS_TEAM_ROUTE = '/growth/team/surveys';
export const SURVEYS_TEAM_ONGOING_ROUTE = '/growth/team/surveys/ongoing';
export const SURVEYS_TEAM_ONGOING_DETAIL_ROUTE = '/growth/team/surveys/ongoing/detail/:cycleId';
export const SURVEYS_TEAM_RESULTS_BY_USER_FORM_ROUTE = '/growth/team/surveys/result/:cycleId/:userId';

export const SURVEYS_TEAM_ONGOING_DETAIL_INSIGHTS_ROUTE = '/growth/team/surveys/ongoing/detail/:cycleId/insights';
export const SURVEYS_TEAM_ONGOING_DETAIL_HEATMAP_ROUTE = '/growth/team/surveys/ongoing/detail/:cycleId/heatmap';

export const SURVEYS_TEAM_ONGOING_DETAIL_OVERVIEW_ROUTE = '/growth/team/surveys/ongoing/detail/:cycleId/overview';
export const SURVEYS_TEAM_ONGOING_DETAIL_RESULT_ROUTE = '/growth/team/surveys/ongoing/detail/:cycleId/result';
export const SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_FORM_ROUTE =
  '/growth/team/surveys/ongoing/detail/:cycleId/settings/form';
export const SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE =
  '/growth/team/surveys/ongoing/detail/:cycleId/settings/notifications';
export const SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE =
  '/growth/team/surveys/ongoing/detail/:cycleId/settings/participants';
export const SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE =
  '/growth/team/surveys/ongoing/detail/:cycleId/settings/timeline';
export const SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE =
  '/growth/team/surveys/ongoing/detail/:cycleId/settings/launch';
export const SURVEYS_TEAM_ONGOING_SETUP_VISIBILITY_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId/visibility';
export const SURVEYS_TEAM_ONGOING_SETUP_TIMELINE_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId/timeline';
export const SURVEYS_TEAM_ONGOING_SETUP_PARTICIPANTS_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId/participants';
export const SURVEYS_TEAM_ONGOING_SETUP_NOTIFICATION_ROUTE =
  '/growth/team/surveys/ongoing/setup/:cycleId/notifications';
export const SURVEYS_TEAM_ONGOING_SETUP_LAUNCH_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId/launch';
export const SURVEYS_TEAM_ONGOING_SETUP_FORM_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId/form';
export const SURVEYS_TEAM_ONGOING_SETUP_GENERAL_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId/general';
export const SURVEYS_TEAM_ONGOING_SETUP_ROUTE = '/growth/team/surveys/ongoing/setup/:cycleId';
export const SURVEYS_TEAM_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE =
  '/growth/team/surveys/ongoing/detail/:cycleId/settings/general';

/** REPORT ROUTES */
export const REPORTS_ROUTE = '/report';
export const REPORT_COMPANY_REPORTS_ROUTE = '/report/company/reports';
export const REPORT_COMPANY_REPORTS_OVERVIEW = '/report/company/reports/overview';

export const REPORT_COMPANY_REPORTS_V2_ROUTE = '/report/company/reports-advanced';
export const REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE = '/report/company/reports-advanced/overview';
export const REPORTS_V2_CREATE_ROUTE = '/report/company/reports-advanced/create';
export const REPORTS_V2_VIEW_REPORT_ROUTE = '/report/company/reports-advanced/details/:reportId';

export const REPORT_COMPANY_REPORTS_TEST = '/report/company/reports-test/overview';
export const REPORTS_CREATE_ROUTE = '/report/company/reports/overview/create';
export const REPORTS_UPDATE_ROUTE = '/report/company/reports/overview/details/:reportId';

export const REPORT_COMPANY_ADVANCED_REPORTS_OVERVIEW_ROUTE = '/report/company/advanced-reports/overview';
export const REPORTS_COMPANY_ADVANCED_REPORTS_CREATE_ROUTE = '/report/company/advanced-reports/overview/create';
export const REPORTS_COMPANY_ADVANCED_REPORTS_UPDATE_ROUTE =
  '/report/company/advanced-reports/overview/details/:reportId';

export const REPORTS_DASHBOARD_ROUTE = '/report/company/insights';
export const REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE = '/report/company/insights/people';
export const REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE = '/report/company/insights/salary';

/** BACKOFF ROUTES */
export const SUCCESS_ROUTE = '/success';
export const FAILURE_ROUTE = '/failure';
export const SUPER_ADMIN_ROUTE = '/backoffice';
export const SUPER_ADMIN_STATISTICS_ROUTE = '/backoffice/statistics';
export const SUPER_ADMIN_DEVICES_ROUTE = '/backoffice/devices';
export const SUPER_ADMIN_DEVICES_OVERVIEW_ROUTE = '/backoffice/devices/overview';
export const SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE = '/backoffice/devices/overview/:deviceId';
export const SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE = '/backoffice/devices/in-house-mdm/overview';
export const SUPER_ADMIN_DEVICES_MODELS_ROUTE = '/backoffice/devices/models';
export const SUPER_ADMIN_DEVICES_ORDERS_ROUTE = '/backoffice/devices/orders';
export const SUPER_ADMIN_DEVICES_STOCK_ROUTE = '/backoffice/devices/stock';
export const SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE = '/backoffice/devices/stock/:deviceId';
export const SUPER_ADMIN_DEVICES_TRANSITS_ROUTE = '/backoffice/devices/transits';
export const SUPER_ADMIN_DEVICES_CONFIG_ROUTE = '/backoffice/devices/config';
export const SUPER_ADMIN_COMPANIES = '/backoffice/companies';
export const SUPER_ADMIN_USERS = '/backoffice/users';
export const SUPER_ADMIN_APPS_ROUTE = '/backoffice/apps';
export const SUPER_ADMIN_BILLING_ROUTE = '/backoffice/billing';
export const SUPER_ADMIN_BILLING_PLANS_ROUTE = '/backoffice/billing/plans';
export const SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE = '/backoffice/billing/discounts';
export const SUPER_ADMIN_BILLING_INVOICES_ROUTE = '/backoffice/billing/invoices';
export const SUPER_ADMIN_BILLING_V2_ROUTE = '/backoffice/v2-billing';
export const SUPER_ADMIN_BILLING_V2_PLANS_ROUTE = '/backoffice/v2-billing/plans';
export const SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE = '/backoffice/v2-billing/subscriptions';
export const SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE = '/backoffice/v2-billing/invoices';
export const SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE = '/backoffice/v2-billing/one-off-charges';
export const SUPER_ADMIN_MONITORING_ROUTE = '/backoffice/monitoring';
export const SUPER_ADMIN_PARTNER_API_ROUTE = '/backoffice/partner-api';
export const SUPER_ADMIN_ID_CHECKS_ROUTE = '/backoffice/id-checks';
export const SUPER_ADMIN_INSURANCE_ROUTE = '/backoffice/insurance';
export const SUPER_ADMIN_REFINANCING_ROUTE = '/backoffice/refinancing';
export const SUPER_ADMIN_REFINANCING_DEVICES_ROUTE = '/backoffice/refinancing/devices';
export const SUPER_ADMIN_INSURANCE_QUOTES_ROUTE = '/backoffice/insurance/quotes';
export const SUPER_ADMIN_INSURANCE_POLICIES_ROUTE = '/backoffice/insurance/policies';
export const SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE = '/backoffice/insurance/policies/:policyId';
export const SUPER_ADMIN_SALARY_ROUTE = '/backoffice/salary';
export const SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE = '/backoffice/salary/paycodes';
export const SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE = '/backoffice/linkedin-user-data-fetch';
export const SUPER_ADMIN_MDM_MIGRATION_ROUTE = '/backoffice/start-mdm-migration';

/** SETTINGS ROUTES */
export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_NOTIFICATIONS_ROUTE = '/settings/notifications';
export const ENTITY_IMPORT_V2_ROUTE = '/settings/v2/import';
export const SETTINGS_COMPANY_ROUTE = '/settings/general/company';
export const SETTINGS_PERSONAL_ROUTE = '/settings/personal';
export const SETTINGS_ORG_STRUCTURE_ROUTE = '/settings/general/org-structure';
export const SETTINGS_SITES_ROUTE = '/settings/general/org-structure/sites';
export const SETTINGS_TRANSLATIONS_ROUTE = '/settings/customisation/translations';
export const SETTINGS_JOB_POSITIONS_ROUTE = '/settings/general/org-structure/job-positions';
export const SETTINGS_JOB_LEVELS_ROUTE = '/settings/general/org-structure/job-levels';
export const SETTINGS_DEPARTMENTS_ROUTE = '/settings/general/org-structure/departments';
export const SETTINGS_WALLPAPER_ROUTE = '/settings/customisation/wallpaper';
export const SETTINGS_PROFILE_ROUTE = '/settings/customisation/profile';
export const SETTINGS_PROFILE_TAB_ROUTE = '/settings/customisation/profile/:tabId';
export const SETTINGS_BILLING_ROUTE = '/settings/billing';
export const SETTINGS_APPROVAL_RULES_ROUTE = '/settings/customisation/approval-rules';
export const SETTINGS_ID_CHECK_PACKAGES_ROUTE = '/settings/id-checks';
export const SETTINGS_SECURITY_ROUTE = '/settings/security';
export const SETTINGS_CUSTOMISATION_ROUTE = '/settings/customisation';
export const SETTINGS_SECURITY_AUDIT_TRAIL_REPORT = '/settings/security/audit-trail';
export const SETTINGS_REQUEST_FORMS_ROUTE = '/settings/request-forms';
export const SETTINGS_REQUEST_FORMS_NEW_ROUTE = '/settings/request-forms/new';
export const SETTINGS_REQUEST_FORMS_EDIT_ROUTE = '/settings/request-forms/edit/:formId';
export const SETTINGS_TIME_ROUTE = '/settings/time';
export const SETTINGS_TIME_POLICIES_ROUTE = '/settings/time/policy';
export const SETTINGS_TIME_OTHER_ROUTE = '/settings/time/other';
export const SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE = '/settings/time/holiday-calendars';
export const SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE = '/settings/time/holiday-calendars/:calendarId';
export const SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE = '/settings/time/holiday-calendars/:calendarId/general';
export const SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE = '/settings/time/holiday-calendars/:calendarId/calendar';
export const SETTINGS_TIME_POLICY_DETAILS_ROUTE = '/settings/time/policy/:policyId';
export const SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE = '/settings/time/policy/:policyId/general';
export const SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE = '/settings/time/policy/:policyId/allowance';
export const SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE = '/settings/time/policy/:policyId/approval';
export const SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE = '/settings/time/policy/:policyId/members';
export const SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE = '/settings/time/policy/:policyId/notifications';
export const SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE = '/settings/time/policy/:policyId/payroll';
export const SETTINGS_CALENDAR_ROUTE = '/settings/calendar';

export const SETTINGS_DEVICES_ROUTE = '/settings/devices';
export const SETTINGS_DEVICES_GENERAL_ROUTE = '/settings/devices/general';
export const SETTINGS_DEVICES_STORE_ROUTE = '/settings/devices/general/store';
export const SETTINGS_DEVICES_DEP_ROUTE = '/settings/devices/general/auto-enrolment';
export const SETTINGS_DEVICES_MDM_ROUTE = '/settings/devices/mdm';
export const SETTINGS_DEVICES_ANDROID_MDM_ROUTE = '/settings/devices/android';
export const SETTINGS_DEVICES_ZELT_ROUTE = '/settings/devices/apple';
export const SETTINGS_MOBILE_DEVICES_ZELT_ROUTE = '/settings/devices/ios-mobile';
export const SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE = '/settings/devices/apple/passcode';
export const SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE = '/settings/devices/ios-mobile/passcode';
export const SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE = '/settings/devices/apple/encryption';
export const SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE = '/settings/devices/apple/firewall';
export const SETTINGS_DEVICES_ZELT_WIFI_ROUTE = '/settings/devices/apple/wifi';
export const SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE = '/settings/devices/apple/os-updates';
export const SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE = '/settings/devices/apple/activation-lock';
export const SETTINGS_DEVICES_ZELT_APPS_ROUTE = '/settings/devices/apple/apps';
export const SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE = '/settings/devices/apple/screen';
export const SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE = '/settings/devices/apple/restrictions';
export const SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE = '/settings/devices/ios-mobile/restrictions';
export const SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE = '/settings/devices/ios-mobile/wifi';
export const SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE = '/settings/devices/ios-mobile/os-update';
export const SETTINGS_ATTENDANCE_ROUTE = '/settings/attendance';
export const SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE = '/settings/attendance/schedules';
export const SETTINGS_ATTENDANCE_OTHER_ROUTE = '/settings/attendance/other';
export const SETTINGS_ATTENDANCE_SCHEDULE_ROUTE = '/settings/attendance/schedules/:scheduleId';
export const SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE = '/settings/attendance/schedules/:scheduleId/general';
export const SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE = '/settings/attendance/schedules/:scheduleId/schedule';
export const SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE = '/settings/attendance/schedules/:scheduleId/tracking';
export const SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE = '/settings/attendance/schedules/:scheduleId/approval';
export const SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE = '/settings/attendance/schedules/:scheduleId/members';
export const SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE = '/settings/attendance/schedules/:scheduleId/payroll';
export const SETTINGS_ATTENDANCE_SCHEDULE_PATTERN_ROUTE = '/settings/attendance/schedules/:scheduleId/pattern';

export const SETTINGS_ONBOARDING_ROUTE = '/settings/onboarding';
export const SETTINGS_ONBOARDING_FLOWS_ROUTE = '/settings/onboarding/flows';
export const SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE = '/settings/onboarding/flows/:templateId';

export const SETTINGS_TASK_ROUTE = '/settings/tasks';
export const SETTINGS_TASK_CHECKLIST_ROUTE = '/settings/tasks/checklists';
export const SETTINGS_TASK_CHECKLISTS_ROUTE = '/settings/tasks/checklists';
export const SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE = '/settings/tasks/checklists/:checklistId';
export const SETTINGS_TASK_CHECKLISTS_ADD_CHECKLIST_ROUTE = '/settings/tasks/checklists/new-checklist';

export const SETTINGS_DOCUMENTS_ROUTE = '/settings/documents';
export const SETTINGS_DOCUMENTS_TYPES_ROUTE = '/settings/documents/types';
export const SETTINGS_DOCUMENTS_TEMPLATES_ROUTE = '/settings/documents/templates';
export const SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE = '/settings/documents/templates/edit/:templateId';
export const SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE = '/settings/documents/templates/preview/:templateId';
export const SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE =
  '/settings/documents/templates/edit-contract/:templateId/:userId/:contractTemplateData?';
export const SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE =
  '/settings/documents/templates/contract/send/:templateId/:userId/:contractTemplateData?';
export const SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE =
  '/settings/documents/templates/contract/sign-send/:templateId/:userId/:contractTemplateData?';

export const SETTINGS_GROWTH_ROUTE = '/settings';
export const SETTINGS_GROWTH_SURVEYS_ROUTE = '/settings/surveys';
export const SETTINGS_GROWTH_REVIEWS_ROUTE = '/settings/reviews';
export const SETTINGS_GROWTH_SCALES_ROUTE = '/settings/scales';
export const SETTINGS_GROWTH_FACTORS_ROUTE = '/settings/factors';
export const SETTINGS_GROWTH_REVIEWS_QUESTIONS_ROUTE = '/settings/reviews/questions';
export const SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE = '/settings/reviews/templates';
export const SETTINGS_GROWTH_SURVEYS_QUESTIONS_ROUTE = '/settings/surveys/questions';
export const SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE = '/settings/surveys/templates';
export const SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE = '/settings/surveys/templates/:templateId';
export const SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE = '/settings/reviews/templates/:templateId';
export const SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_BUILDER_ROUTE = '/settings/reviews/templates/:templateId/questions';
export const SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_GENERAL_ROUTE = '/settings/reviews/templates/:templateId/general';
export const SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE = '/settings/surveys/templates/:templateId/questions';
export const SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE = '/settings/surveys/templates/:templateId/general';

export const SETTINGS_MONEY_ROUTE = '/settings/money';
export const SETTINGS_MONEY_SALARY_ROUTE = '/settings/money/salary';
export const SETTINGS_MONEY_PAYMENTS_ROUTE = '/settings/money/payments';
export const SETTINGS_MONEY_INVOICES_ROUTE = '/settings/money/invoices';
export const SETTINGS_MONEY_EXPENSES_ROUTE = '/settings/money/expenses';
// invoice setting routes
export const SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE = '/settings/money/invoices/:id';
export const SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE = '/settings/money/invoices/:id/general';
export const SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE = '/settings/money/invoices/:id/members';
export const SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE = '/settings/money/invoices/:id/approval';
export const SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE = '/settings/money/invoices/:id/accounting';

// expense setting routes
export const SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE = '/settings/money/expenses/:id';
export const SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE = '/settings/money/expenses/:id/general';
export const SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_MEMBERS_ROUTE = '/settings/money/expenses/:id/members';
export const SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_APPROVAL_ROUTE = '/settings/money/expenses/:id/approval';
export const SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ACCOUNTING_ROUTE = '/settings/money/expenses/:id/accounting';

export const SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE = '/settings/money/salary/:payrollId';
export const SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE = '/settings/money/salary/:payrollId/general';
export const SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE = '/settings/money/salary/:payrollId/hmrc';
export const SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE = '/settings/money/salary/:payrollId/accounting';
export const SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE = '/settings/money/salary/:payrollId/paycodes';
export const SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE =
  '/settings/money/salary/:payrollId/integrations';

export const SETTINGS_BENEFITS_ROUTE = '/settings/money/benefits';
export const SETTINGS_BENEFITS_DETAILS_ROUTE = '/settings/money/benefits/details/:productType/:category/:id';
export const SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE =
  '/settings/money/benefits/details/:productType/:category/:id/general';
export const SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE =
  '/settings/money/benefits/details/:productType/:category/:id/provider';
export const SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE =
  '/settings/money/benefits/details/:productType/:category/:id/worker-groups';
export const SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE =
  '/settings/money/benefits/details/:productType/:category/:id/members';
export const SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE =
  '/settings/money/benefits/details/:productType/:category/:id/payment';
export const SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE =
  '/settings/money/benefits/details/:productType/:category/:id/approval';

export const SETTINGS_CREATE_NEW_BENEFIT_ROUTE = '/settings/money/benefits/new/:category/:name';

/** EXTERNAL USER ONBOARDING ROUTES */
export const USER_ONBOARDING_ROUTE = '/users/onboarding/';
export const USER_ONBOARDING_WELCOME_ROUTE = '/users/onboarding/welcome';
export const USER_ONBOARDING_PASSWORD = '/users/onboarding/password';
export const USER_ONBOARDING_PASSWORD_ALREADY_SET = '/users/onboarding/password-already-set';
export const USER_ONBOARDING_SUMMARY_ROUTE = '/users/onboarding/:userId/overview';
export const USER_ONBOARDING_ABOUT = '/users/onboarding/:userId/about';
export const USER_ONBOARDING_PAYROLL = '/users/onboarding/:userId/payroll';
export const USER_ONBOARDING_DOCUMENTS = '/users/onboarding/:userId/documents';
export const USER_ONBOARDING_TASKS = '/users/onboarding/:userId/tasks';

/** TECH ROUTES */
export const TECH_ROUTE = '/tech';
export const DEVICES_ROUTE = '/devices';
export const DEVICES_ACTIVATION_ROUTE = '/devices/activation';
export const DEVICES_COMPANY_ROUTE = '/tech/company/devices';
export const DEVICES_COMPANY_OVERVIEW_ROUTE = '/tech/company/devices/overview';
export const DEVICES_COMPANY_DIRECTORY_ROUTE = '/tech/company/devices/directory';
export const DEVICES_COMPANY_IN_TRANSIT_ROUTE = '/tech/company/devices/in-transit';
export const DEVICES_COMPANY_INVENTORY_ROUTE = '/tech/company/devices/inventory';
export const DEVICES_COMPANY_LOCATION_ROUTE = '/tech/company/devices/location';
export const DEVICES_COMPANY_STORE_ROUTE = '/tech/company/devices/store';
export const DEVICES_COMPANY_ORDER_DETAILS_ROUTE = '/tech/company/devices/orders/:orderId';
export const DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE = '/tech/company/devices/orders/activation';
export const DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE = '/tech/company/devices/orders/:orderId/activation';
export const DEVICE_COMPANY_DEVICE_ENROLL_ROUTE = '/tech/company/devices/overview/:devicePossessionId/enroll';
export const DEVICES_COMPANY_DEVICE_DETAILS_ROUTE = '/tech/company/devices/overview/:devicePossessionId';
export const DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE =
  '/tech/company/devices/overview/:devicePossessionId/details';
export const DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE =
  '/tech/company/devices/overview/:devicePossessionId/security';
export const DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE =
  '/tech/company/devices/overview/:devicePossessionId/installed-apps';
export const DEVICES_ACTIVITY_LOG_ROUTE = '/tech/company/devices/overview/:devicePossessionId/activity-log';
export const DEVICES_NOTES_ROUTE = '/tech/company/devices/overview/:devicePossessionId/notes';
export const DEVICES_COMPANY_INHOUSE_MDM_DEVICE_INSTALLED_APPS_ROUTE =
  '/tech/company/devices/overview/:devicePossessionId/zelt-mdm-installed-apps';
export const DEVICES_COMPANY_DEVICE_POLICIES_ROUTE =
  '/tech/company/devices/overview/:devicePossessionId/active-policies';
export const DEVICES_COMPANY_DEVICE_LOCATION_ROUTE = '/tech/company/devices/overview/:devicePossessionId/location';

export const DEVICES_ME_ROUTE = '/tech/me/devices';
export const DEVICES_ME_DIRECTORY_ROUTE = '/tech/me/devices/directory';
export const DEVICES_ME_ORDERS_ROUTE = '/tech/me/devices/orders';
export const DEVICES_ME_STORE_ROUTE = '/tech/me/devices/store';
export const DEVICES_ME_ORDER_DETAILS_ROUTE = '/tech/me/devices/orders/:orderId';
export const DEVICES_ME_ORDERS_ACTIVATION_ROUTE = '/tech/me/devices/orders/activation';
export const DEVICES_ME_ORDER_ACTIVATION_ROUTE = '/tech/me/devices/orders/:orderId/activation';
export const DEVICES_ME_OVERVIEW_DEVICE_ROUTE = '/tech/me/devices/active/:devicePossessionId';
export const DEVICES_ME_OVERVIEW_DEVICE_ENROLL_ROUTE = '/tech/me/devices/active/:devicePossessionId/enroll';
export const DEVICES_ME_DEVICE_SECURITY_ROUTE = '/tech/me/devices/active/:devicePossessionId/security';
export const DEVICES_ME_DEVICE_DETAILS_ROUTE = '/tech/me/devices/active/:devicePossessionId/details';
export const DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE = '/tech/me/devices/active/:devicePossessionId/apps';
export const DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE = '/tech/me/devices/active/:devicePossessionId/activity-log';
export const DEVICES_ME_DEVICE_NOTES_ROUTE = '/tech/me/devices/active/:devicePossessionId/notes';
export const DEVICES_ME_DEVICE_POLICIES_ROUTE = '/tech/me/devices/active/:devicePossessionId/active-policies';
export const DEVICES_ME_DEVICE_LOCATION_ROUTE = '/tech/me/devices/active/:devicePossessionId/location';

export const APPS_ROUTE = '/apps';
export const APPS_COMPANY_ROUTE = '/tech/company/apps';
export const APPS_COMPANY_OVERVIEW_ROUTE = '/tech/company/apps/overview';
export const APP_COMPANY_STORE_ROUTE = '/tech/company/apps/store';
export const APPS_REQUESTS_ROUTE = '/tech/company/apps/requests';
export const APP_INTEGRATION_DETAILS_ROUTE = '/tech/company/apps/overview/:appStub';
export const APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE = '/tech/company/apps/overview/:appStub/directory';
export const APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE =
  '/tech/company/apps/overview/:appStub/external-directory';
export const APP_INTEGRATION_DETAILS_ABOUT_ROUTE = '/tech/company/apps/overview/:appStub/about';
export const APP_INTEGRATION_DETAILS_GROUPS_ROUTE = '/tech/company/apps/overview/:appStub/groups';
export const APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE = '/tech/company/apps/overview/:appStub/insights';
export const APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE = '/tech/company/apps/overview/:appStub/employments';
export const APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE = '/tech/company/apps/overview/:appStub/candidates';
export const APP_INTEGRATION_DETAILS_SETTINGS_ROUTE = '/tech/company/apps/overview/:appStub/settings';

export const APPS_PERSONAL_ROUTE = '/tech/me/apps';
export const APPS_PERSONAL_OVERVIEW_ROUTE = '/tech/me/apps/overview';
export const APP_STORE_ROUTE = '/tech/me/apps/store';
export const APP_INTEGRATION_PERSONAL_DETAILS_ROUTE = '/tech/me/apps/overview/:appStub';
export const APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE = '/tech/me/apps/overview/:appStub/directory';
export const APP_INTEGRATION_PERSONAL_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE =
  '/tech/me/apps/overview/:appStub/external-directory';
export const APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE = '/tech/me/apps/overview/:appStub/about';
export const APP_INTEGRATION_PERSONAL_DETAILS_GROUPS_ROUTE = '/tech/me/apps/overview/:appStub/groups';
export const APP_INTEGRATION_PERSONAL_DETAILS_INSIGHTS_ROUTE = '/tech/me/apps/overview/:appStub/insights';
export const APP_INTEGRATION_PERSONAL_DETAILS_EMPLOYMENTS_ROUTE = '/tech/me/apps/overview/:appStub/employments';
export const APP_INTEGRATION_PERSONAL_DETAILS_CANDIDATES_ROUTE = '/tech/me/apps/overview/:appStub/candidates';
export const APP_INTEGRATION_PERSONAL_DETAILS_SETTINGS_ROUTE = '/tech/me/apps/overview/:appStub/settings';

export const REQUESTS_ROUTE = '/people/me/action-items/requests';
export const REQUESTS_CHOOSE_REQUEST_ROUTE = '/people/me/action-items/requests/choose';
export const REQUESTS_SUBMIT_REQUEST_ROUTE = '/people/me/action-items/requests/submit/:id';

export const SETTINGS_PERMISSION_GROUPS_ROUTE = '/settings/permission-groups';
export const SETTINGS_CREATE_PERMISSION_GROUP_ROUTE = '/settings/permission-groups/create';
export const SETTINGS_PERMISSION_GROUP_ROUTE = '/settings/permission-groups/:permissionGroupId';
export const SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE = '/settings/permission-groups/:permissionGroupId/general';
export const SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE =
  '/settings/permission-groups/:permissionGroupId/permissions';
export const SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE = '/settings/permission-groups/:permissionGroupId/members';
export const SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE = '/settings/permission-groups/:permissionGroupId/targets';
