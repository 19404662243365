import { useMemo } from 'react';

import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as IDVerifyIcon } from '@/images/onboarding-step-icons/IDBig.svg';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { EditIdVerify } from '@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component';
import { CheckPackage } from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  setDrawerOpen: (open: boolean) => void;
  setDrawerView: (view: JSX.Element) => void;
  onDelete?: () => void;
};

export const IDVerifyOnboardingItem = ({ template, setDrawerOpen, setDrawerView, onDelete }: Props) => {
  const { polyglot } = usePolyglot();
  const name = useMemo(() => {
    const { check } = template.template.idverify ?? {};
    if (!check) return undefined;
    return check?.name;
  }, [template.template.idverify]);
  return (
    <ActionSelectionItem
      step={{
        Icon: IDVerifyIcon,
        title: polyglot.t('IDVerifyOnboardingItem.title'),
        description: polyglot.t('IDVerifyOnboardingItem.desc'),
      }}
      chips={[name ?? '']}
      addButtonIcon={template.template.idverify ? 'edit' : 'plus'}
      onAddClick={
        template.template.idverify
          ? undefined
          : () => {
              setDrawerView(
                <EditIdVerify
                  onSave={async (checkPackage?: CheckPackage) => {
                    template.template.idverify = { check: checkPackage, enabled: true };
                    setDrawerOpen(false);
                  }}
                />
              );
              setDrawerOpen(true);
            }
      }
      sx={{
        py: spacing.py20,
      }}
      onDeleteClick={template.template.idverify && onDelete}
    />
  );
};
