import {
  DEVICES_COMPANY_OVERVIEW_ROUTE,
  DEVICES_COMPANY_STORE_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_STORE_ROUTE,
  DEVICES_COMPANY_DIRECTORY_ROUTE,
  DEVICES_COMPANY_INVENTORY_ROUTE,
} from '@/lib/routes';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const TECH_DEVICE_OVERVIEW_ROUTES: RoleRoutes = {
  company: DEVICES_COMPANY_OVERVIEW_ROUTE,
};

export const TECH_DEVICE_DIRECTORY_ROUTES: RoleRoutes = {
  company: DEVICES_COMPANY_DIRECTORY_ROUTE,
  me: DEVICES_ME_DIRECTORY_ROUTE,
};

export const TECH_DEVICE_INVENTORY_ROUTES: RoleRoutes = {
  company: DEVICES_COMPANY_INVENTORY_ROUTE,
};

export const TECH_DEVICE_STORE_ROUTES: RoleRoutes = {
  company: DEVICES_COMPANY_STORE_ROUTE,
  me: DEVICES_ME_STORE_ROUTE,
};

export const TECH_DEVICE_DIRECTORY_SCOPES: RoleScopes = {
  company: ['devices:all'],
  me: ['devices'],
};

export const TECH_DEVICE_STORE_SCOPES: RoleScopes = {
  company: ['devices:all'],
  me: ['devices.store'],
};
