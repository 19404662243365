import { Fragment } from 'react';

import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { spacing } from '@/v2/styles/spacing.styles';
import '@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner.scss';

export const UserTimePlannerBigLoader = () => {
  return (
    <WidgetLayout readOnly={false} size="big" isLoadingState={true}>
      <Fragment>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: spacing.p5 }}>
            <Typography variant="title2">Time planner</Typography>
            <Typography variant="captionSmall">Policy summary</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: spacing.g20,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {[0, 1, 2].map((num) => (
            <Box
              key={`${num}-utpb`}
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                verticalAlign: 'middle',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: spacing.m10,
              }}
            >
              <div className="utp-progress-bar">
                <progress value="75" max="100" style={{ visibility: 'hidden', height: 0, width: 0 }}>
                  75%
                </progress>
              </div>
            </Box>
          ))}
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
