import {
  CreateHolidayCalendarDto,
  HolidayCalendarDto,
  UpdateHolidayCalendarGeneralDto,
  UpsertHoliday,
} from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class HolidayCalendarAPI {
  static async createHolidayCalendar(createBody: CreateHolidayCalendarDto): Promise<number> {
    return (await axios.post('/apiv2/holiday-calendars', createBody))?.data;
  }

  static async updateHolidayCalendarGeneral(
    calendarId: string,
    update: UpdateHolidayCalendarGeneralDto
  ): Promise<void> {
    return (await axios.patch(`/apiv2/holiday-calendars/${calendarId}/general`, update))?.data;
  }

  static async updateCalendarHoliday(calendarId: string, holidayId: string, update: UpsertHoliday): Promise<void> {
    return (await axios.patch(`/apiv2/holiday-calendars/${calendarId}/holidays/${holidayId}`, update))?.data;
  }

  static async addHolidayToCalendar(calendarId: string, create: UpsertHoliday): Promise<void> {
    return (await axios.post(`/apiv2/holiday-calendars/${calendarId}/holidays`, create))?.data;
  }

  static async deleteCalendarHoliday(calendarId: string, holidayId: string): Promise<void> {
    await axios.delete(`/apiv2/holiday-calendars/${calendarId}/holidays/${holidayId}`);
  }

  static async deleteHolidayCalendar(calendarId: string): Promise<void> {
    await axios.delete(`/apiv2/holiday-calendars/${calendarId}`);
  }
}

export class HolidayCalendarEndpoints {
  static getHolidayCalendars(includeHolidays = false): Endpoint<HolidayCalendarDto[]> {
    return {
      url: `/apiv2/holiday-calendars?includeHolidays=${includeHolidays}`,
    };
  }

  static getHolidayCalendarById(calendarId: string, includeHolidays = false): Endpoint<HolidayCalendarDto> {
    return {
      url: `/apiv2/holiday-calendars/${calendarId}?includeHolidays=${includeHolidays}`,
    };
  }
}
