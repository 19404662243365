import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { Form, useFormik, FormikProvider } from 'formik';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CalendarAPI } from '@/v2/feature/calendar/calendar.api';
import {
  OutlookCalendarPublishDrawerMode,
  OutlookCalendarPublishLinks,
} from '@/v2/feature/calendar/calendar.interface';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import {
  definitionListSx,
  fieldSx,
  titleSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface OutlookCalendarPublishDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly mode: 'publish' | 'subscribe';
  readonly publishLinks?: OutlookCalendarPublishLinks | null;
  readonly refreshCalendarPublishLinks: KeyedMutator<OutlookCalendarPublishLinks> | undefined;
}

export const OutlookCalendarPublishDrawer = ({
  isOpen,
  setIsOpen,
  mode,
  publishLinks,
  refreshCalendarPublishLinks,
}: OutlookCalendarPublishDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <OutlookCalendarPublishDrawerContent
        setIsOpen={setIsOpen}
        mode={mode}
        existingPublishLinks={publishLinks}
        refreshCalendarPublishLinks={refreshCalendarPublishLinks}
      />
    </DrawerModal>
  );
};

interface OutlookCalendarPublishDrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly mode: OutlookCalendarPublishDrawerMode;
  readonly existingPublishLinks?: OutlookCalendarPublishLinks | null;
  readonly refreshCalendarPublishLinks: KeyedMutator<OutlookCalendarPublishLinks> | undefined;
}

export const OutlookCalendarPublishDrawerContent = ({
  setIsOpen,
  mode,
  existingPublishLinks,
  refreshCalendarPublishLinks,
}: OutlookCalendarPublishDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (values: OutlookCalendarPublishLinks) => {
      try {
        setLoading(true);
        await CalendarAPI.updatePublishLinksForOutlookCalendar(values);
        await refreshCalendarPublishLinks!();
        setIsOpen(false);
        showMessage(polyglot.t('OutlookCalendarPublishDrawer.successMessages.publish'), 'success');
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [polyglot, refreshCalendarPublishLinks, setIsOpen, showMessage]
  );

  const formik = useFormik<OutlookCalendarPublishLinks>({
    initialValues: {
      icsURL: existingPublishLinks?.icsURL ?? '',
      htmlURL: existingPublishLinks?.htmlURL ?? '',
    },
    validationSchema: yup.object({
      icsURL: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      htmlURL: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Box>
        <Typography sx={titleSx}>
          {polyglot.t(mode === 'publish' ? 'OutlookCalendarPublishDrawer.header' : 'CalendarPage.subscribe')}
        </Typography>
        {mode === 'publish' && (
          <>
            <Typography sx={themeFonts.caption}>{polyglot.t('OutlookCalendarPublishDrawer.body')}</Typography>
            <br />
            <Typography sx={themeFonts.caption}>
              {polyglot.t('OutlookCalendarPublishDrawer.belowBody')}
              <b>{polyglot.t('OutlookCalendarPublishDrawer.bodyBold')}</b>
              {polyglot.t('OutlookCalendarPublishDrawer.belowBodyContinued')}
            </Typography>
            <br />
            <Typography sx={themeFonts.caption}>{polyglot.t('OutlookCalendarPublishDrawer.bodyFinal')}</Typography>
          </>
        )}
      </Box>
      {mode === 'publish' && (
        <Form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="icsURL"
              label={polyglot.t('OutlookCalendarPublishDrawer.icsURL')}
              value={formik.values.icsURL}
              onChange={formik.handleChange}
              error={formik.touched.icsURL && !!formik.errors.icsURL}
              helperText={(formik.touched.icsURL && formik.errors.icsURL) ?? ' '}
              endAdornment="none"
            />
          </Box>
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="htmlURL"
              label={polyglot.t('OutlookCalendarPublishDrawer.htmlURL')}
              value={formik.values.htmlURL}
              onChange={formik.handleChange}
              error={formik.touched.htmlURL && !!formik.errors.htmlURL}
              helperText={(formik.touched.htmlURL && formik.errors.htmlURL) ?? ' '}
              endAdornment="none"
            />
          </Box>

          <Box sx={{ mt: spacing.m40 }}>
            <LoaderButton
              name={polyglot.t('OutlookCalendarPublishDrawer.publish')}
              loading={loading}
              fullWidth
              sizeVariant="large"
              colorVariant="primary"
            />
          </Box>
        </Form>
      )}
      {mode === 'subscribe' && (
        <Box component="dl" sx={definitionListSx}>
          {existingPublishLinks?.icsURL && (
            <FieldStructure
              fieldTitle={polyglot.t('CalendarIntegrationSettingReadonly.icsCalendarLink')}
              fieldValue={
                existingPublishLinks?.icsURL && {
                  isLink: true,
                  label: truncateWithEllipses(existingPublishLinks.icsURL, 30),
                  icon: <Copy {...iconSize} />,
                  onClick: () => navigator.clipboard.writeText(existingPublishLinks.icsURL),
                }
              }
              fieldStub="icsURL"
            />
          )}
          {existingPublishLinks?.htmlURL && (
            <FieldStructure
              fieldTitle={polyglot.t('CalendarIntegrationSettingReadonly.htmlCalendarLink')}
              fieldValue={
                existingPublishLinks?.htmlURL && {
                  isLink: true,
                  label: truncateWithEllipses(existingPublishLinks.htmlURL, 30),
                  icon: <Copy {...iconSize} />,
                  onClick: () => navigator.clipboard.writeText(existingPublishLinks.htmlURL),
                }
              }
              fieldStub="htmlURL"
            />
          )}
        </Box>
      )}
    </FormikProvider>
  );
};
