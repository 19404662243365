import {
  ABSENCE_COMPANY_BALANCES_ROUTE,
  ABSENCE_COMPANY_REQUESTS_ROUTE,
  ABSENCE_ME_REQUESTS_ROUTE,
  ABSENCE_TEAM_BALANCES_ROUTE,
  ABSENCE_TEAM_REQUESTS_ROUTE,
} from '@/lib/routes';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const TIME_ABSENCE_REQUESTS_ROUTES: RoleRoutes = {
  company: ABSENCE_COMPANY_REQUESTS_ROUTE,
  team: ABSENCE_TEAM_REQUESTS_ROUTE,
  me: ABSENCE_ME_REQUESTS_ROUTE,
};

export const TIME_ABSENCE_BALANCES_ROUTES: RoleRoutes = {
  company: ABSENCE_COMPANY_BALANCES_ROUTE,
  team: ABSENCE_TEAM_BALANCES_ROUTE,
};

export const TIME_ABSENCE_REQUESTS_SCOPES: RoleScopes = {
  company: ['absence:all'],
  team: ['absence:manager'],
  me: ['absence'],
};

export const TIME_ABSENCE_BALANCES_SCOPES: RoleScopes = {
  company: ['absence:all'],
  team: ['absence:manager'],
};
