import { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import { canAccessScopes } from '@/lib/scopes';
import { ProgressBar } from '@/v2/components/progress-bar.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewResultEndpoints } from '@/v2/feature/growth/reviews/api-client/review-result.api';
import { ReviewCycleFinaliseModal } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-overview/components/review-cycle-finalise-modal.component';
import { DATE_FORMAT, TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import {
  ReachType,
  ReminderType,
  ReviewCycle,
  ReviewParticipationObject,
  ReviewerTypes,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewParticipation } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { CycleState, CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const ReviewCycleDetailOverviewPage = ({
  reviewCycle,
  refresh,
  cycleLoading,
  reach,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  refresh: () => Promise<void>;
  readonly cycleLoading: boolean | undefined;
  reach: ReachType;
}) => {
  const [showMessage] = useMessage();

  const params = useParams<{ cycleId: string }>();
  const cycleId = params.cycleId;
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['reviews:all']);
  const isManager = canAccessScopes(user, ['reviews:manager']);

  const [finaliseOpen, setFinaliseOpen] = useState<boolean>(false);

  const { data: participation, isLoading: loadingParticipation } = useApiClient(
    ReviewResultEndpoints.getReviewParticipationByCycleId(cycleId, reach),
    {
      suspense: false,
    }
  );

  const isFinished = useMemo(() => {
    if (!participation) return false;

    return Boolean(participation.participant === 100);
  }, [participation]);

  const getActionsOptions = useCallback(() => {
    if (!reviewCycle) return [];

    const sendReminder = async (type: ReminderType) => {
      try {
        await ReviewCycleAPI.sendReminderByCycleId(type, reviewCycle.id, reach);
        showMessage('Successfully sent reminders', 'success');
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    };

    return [
      ...(reviewCycle.type !== CycleType.Rolling
        ? [
            {
              handler: () => setFinaliseOpen(true),
              label: 'Finalize cycle',
              disabled: false,
            },
          ]
        : []),
      {
        handler: () => sendReminder(ReminderType.All),
        label: 'Remind all',
        disabled: false,
      },
      {
        handler: () => sendReminder(ReminderType.Overdue),
        label: 'Remind overdue',
        disabled: false,
      },
    ];
  }, [reviewCycle, showMessage, reach]);

  if (!reviewCycle) return <></>;

  return (
    <>
      <SettingsSectionContent
        headerWidth="100%"
        contentWidth="100%"
        title={
          <TitleStatusComponent
            reviewCycle={reviewCycle}
            reachType={reach}
            showAction={
              (isAdmin || (isManager && reviewCycle.owner === user.userId)) &&
              (reviewCycle.state === CycleState.Ongoing || reviewCycle.state === CycleState.Paused)
            }
            action={
              <StyledMenuComponent
                options={getActionsOptions()}
                actionButtonDetails={{
                  type: 'button',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'Actions',
                  icon: <ArrowDown {...iconSize} />,
                  iconPosition: 'end',
                }}
              />
            }
          />
        }
        noHorizontalPadding={false}
        topHeaderPaddingSx={{ px: spacing.px16 }}
        loading={cycleLoading || loadingParticipation}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              headerWidth: '100%',
              title: 'Overview',
              items: [
                {
                  type: SectionItemType.Pair,
                  label: 'Review cycle started on',
                  value: format(new LocalDate(reviewCycle.timelineSettings.startDate).getDate(), DATE_FORMAT),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Participants',
                  value: `${reviewCycle.revieweeId.length} participants`,
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <ProgressBar
                      progress={participation?.participant ?? 0}
                      label={`${participation?.participant ?? 0}%`}
                    />
                  ),
                },
              ],
            },
          ]}
        />

        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              headerWidth: '100%',
              title: 'Timeline',
              hidden: Boolean(reviewCycle.reviewerSelect.length === 0),
              items: [
                {
                  type: SectionItemType.Component,
                  value: <ReviewerProgress reviewCycle={reviewCycle} participation={participation} />,
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      <ReviewCycleFinaliseModal
        reviewCycle={reviewCycle}
        isOpen={finaliseOpen}
        setIsOpen={setFinaliseOpen}
        onClose={() => setFinaliseOpen(false)}
        isFinished={isFinished}
        refresh={refresh}
        reach={reach}
      />
    </>
  );
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  gap: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const ReviewerProgress = ({
  reviewCycle,
  participation,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  participation: ReviewParticipation | null | undefined;
}) => {
  const generateReviews = useCallback(
    (reviewCycle: ReviewCycle | null | undefined, participation: ReviewParticipation | null | undefined) => {
      if (!reviewCycle) return [];
      return Object.values(ReviewerTypes).map((type) => {
        const progress = participation?.[type.toLowerCase() as keyof ReviewParticipation] as ReviewParticipationObject;

        return {
          label: `${type} review `,
          completion: progress.percent ?? 0,
          isHidden: !reviewCycle.reviewerSelect.includes(type) || progress.percent === null,
          isOverdue:
            reviewCycle.state === CycleState.Ongoing &&
            Boolean(progress.percent ? progress.percent < 100 : true) &&
            progress.overdue,
        };
      });
    },
    []
  );

  const reviews = useMemo(() => generateReviews(reviewCycle, participation), [
    participation,
    reviewCycle,
    generateReviews,
  ]);

  if (!reviewCycle) return <></>;

  return (
    <Box sx={containerStyle}>
      {reviews.map((review, index) => {
        if (review.isHidden) return null;
        return (
          <div key={index} style={{ flex: '1', minWidth: '200px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
              <Typography variant="caption" color="Grey">
                {review.label}
              </Typography>
              <ProgressBar label={`${review.completion}%`} progress={review.completion} showRed={review.isOverdue} />
            </Box>
          </div>
        );
      })}
    </Box>
  );
};
