import axios from 'axios';
import {
  CreateUserEquity,
  UpdateUserEquity,
  UserEquity,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class EquityAPI {
  static async getByUserId(userId: number): Promise<UserEquity[]> {
    return (await axios.get(`/apiv2/users/${userId}/equity`)).data;
  }

  static async create(newUserEquityEntry: CreateUserEquity): Promise<void> {
    await axios.post(`/apiv2/users/${newUserEquityEntry.userId}/equity/`, newUserEquityEntry);
  }

  static async updateById(existingUserEquityEntry: UpdateUserEquity): Promise<void> {
    await axios.patch(
      `/apiv2/users/${existingUserEquityEntry.userId}/equity/${existingUserEquityEntry.id}`,
      existingUserEquityEntry
    );
  }

  static async deleteById(userId: number, equityId: number): Promise<void> {
    return axios.delete(`/apiv2/users/${userId}/equity/${equityId}`);
  }
}

export class EquityEndpoints {
  static findByUserId(userId: number): Endpoint<UserEquity[] | null> {
    return {
      url: `/apiv2/users/${userId}/equity/current`,
    };
  }
}
