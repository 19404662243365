import { Fragment } from 'react';

import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/feature/dashboard/dashboard.scss';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { spacing } from '@/v2/styles/spacing.styles';
import '@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner.scss';

export const UserTimePlannerSmallLoader = () => {
  return (
    <WidgetLayout readOnly={false} size="small" isLoadingState={true}>
      <Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: spacing.p5 }}>
              <Typography variant="title2">Time planner</Typography>
              <Typography variant="captionSmall">Policy summary</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            verticalAlign: 'middle',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: spacing.m10,
          }}
        >
          <div className="utp-progress-bar">
            <progress value="75" max="100" style={{ visibility: 'hidden', height: 0, width: 0 }}>
              75%
            </progress>
          </div>
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
