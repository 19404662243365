import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { Redirect, Switch } from 'react-router-dom';

import { UserPensionPage } from '@/component/dashboard/userDetails/pension/user-pension.page';
import { RouteScopesControl } from '@/component/widgets/Scopes';
import { BENEFITS_ME_ROUTE, PENSION_ME_SUMMARY_ROUTE } from '@/lib/routes';

interface PensionMeRouterProps {
  readonly userId: number;
  readonly userPension: UserPensionDto | null;
  readonly refreshUserPension: () => Promise<void>;
  readonly loading: boolean;
}

export const PensionMeRouter = ({
  userId,
  userPension,
  loading,
  refreshUserPension,
}: PensionMeRouterProps): JSX.Element => {
  return (
    <Switch>
      {userPension?.inPension && (
        <RouteScopesControl exact scopes={['pension']} context={{ userId }} path={PENSION_ME_SUMMARY_ROUTE}>
          <UserPensionPage
            userId={userId}
            employeePension={userPension}
            onEmployeeUpdate={refreshUserPension}
            loading={loading}
          />
        </RouteScopesControl>
      )}

      <Redirect to={BENEFITS_ME_ROUTE} />
    </Switch>
  );
};
