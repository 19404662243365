import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TimePickerComponent } from '@v2/components/forms/time-picker.component';
import { PlusOneNextDayComponent } from '@v2/components/plus-one-next-day.component';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { ScheduleTimeEntry, ScheduleWeeksPattern, WeekDay } from '@v2/feature/attendance/attendance.interface';
import { getEmptyDaySlot } from '@v2/feature/attendance/attendance.util';
import { isValidTimeString } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';

export const SchedulePatternRow = ({
  day,
  weekNo,
  values,
  setDayForm,
}: {
  day: WeekDay;
  weekNo: number;
  values: ScheduleWeeksPattern;
  setDayForm: (v: ScheduleTimeEntry) => void;
}) => {
  const { polyglot } = usePolyglot();
  const [isEnabled, setIsEnabled] = useState<boolean>(!!values[day][weekNo]);
  const [dayTime, setDayTime] = useState<number>(0);

  const updateDayTime = useCallback(
    (record: ScheduleTimeEntry) => {
      if (!isEnabled || !record?.fromTimestamp || !record.toTimestamp) return 0;

      const [breakHours, breakMinutes] =
        record.break && record.break.slice(11, 16).match(/^\d\d:\d\d$/g)
          ? record.break.slice(11, 16).split(':')
          : [0, 0];
      const breakInMinutes = 60 * Number(breakHours) + Number(breakMinutes);

      let workMinutes = Math.round(
        (new Date(record.toTimestamp).getTime() - new Date(record.fromTimestamp).getTime()) / (1000 * 60)
      );

      if (record.toTimestamp < record.fromTimestamp) workMinutes += 60 * 24;

      setDayTime(workMinutes - breakInMinutes);
    },
    [isEnabled]
  );

  useEffect(() => {
    updateDayTime(values[day][weekNo]);
  }, [updateDayTime, values, day, weekNo]);

  const isOvernight =
    values[day] &&
    values[day][weekNo] &&
    values[day][weekNo]!.fromTimestamp &&
    values[day][weekNo]!.toTimestamp &&
    values[day][weekNo]!.fromTimestamp! > values[day][weekNo]!.toTimestamp!;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <CheckboxComponent
        label={polyglot.t(`Days.${day.toLowerCase()}`)}
        name={`${day}-enabled`}
        checked={isEnabled}
        onChange={(_, checked) => {
          setIsEnabled(checked);
          setDayForm(checked ? getEmptyDaySlot() : null);
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 0.25fr 0.75fr 1fr',
          gridGap: spacing.g10,
          alignItems: 'center',
          transition: 'all 0.2s linear',
          transitionProperty: 'opacity, height, margin-top',
          visibility: isEnabled ? 'visible' : 'hidden',
          opacity: isEnabled ? 1 : 0,
          height: isEnabled ? 'auto' : '0px',
          marginTop: isEnabled ? '10px' : '0px',
        }}
      >
        <Box>
          <TimePickerComponent
            textFieldKey="from"
            label={polyglot.t('ScheduleRow.from')}
            value={values[day][weekNo] ? values[day][weekNo]!.from : ''}
            onChange={(event) => {
              const time = event.target.value;
              if (isValidTimeString(time)) {
                const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                const dayArray = values[day][weekNo] ?? null;
                const dayRecord = { ...(dayArray ? dayArray : getEmptyDaySlot()) };

                dayRecord.from = date.toFullString();
                dayRecord.fromTimestamp = date.getDate();

                // If to is set, make sure the record has the same date as from, otherwise the time between the dates is wrongly calculated (happens on update)
                if (dayRecord.to && dayRecord.toTimestamp) {
                  const toTimestamp = new Date(dayRecord.toTimestamp);
                  toTimestamp.setDate(date.getDate().getDate());
                  toTimestamp.setMonth(date.getDate().getMonth());
                  toTimestamp.setFullYear(date.getDate().getFullYear());

                  dayRecord.to = `${date.toDateString()}T${dayRecord.to.split('T')[1]}`;
                  dayRecord.toTimestamp = toTimestamp;
                }

                setDayForm(dayRecord);
              }
            }}
            disabled={!isEnabled}
            fullWidth
          />
        </Box>

        <Box>
          <TimePickerComponent
            textFieldKey="to"
            label={polyglot.t('ScheduleRow.to')}
            value={values[day][weekNo] ? values[day][weekNo]!.to : ''}
            onChange={(event) => {
              const time = event.target.value;
              if (isValidTimeString(time)) {
                const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                const dayArray = values[day][weekNo] ?? null;
                const dayRecord = { ...(dayArray ? dayArray : getEmptyDaySlot()) };

                dayRecord.to = date.toFullString();
                dayRecord.toTimestamp = date.getDate();

                // If from is set, make sure the record has the same date as to, otherwise the time between the dates is wrongly calculated (happens on update)
                if (dayRecord.from && dayRecord.fromTimestamp) {
                  const fromTimestamp = new Date(dayRecord.fromTimestamp);
                  fromTimestamp.setDate(date.getDate().getDate());
                  fromTimestamp.setMonth(date.getDate().getMonth());
                  fromTimestamp.setFullYear(date.getDate().getFullYear());

                  dayRecord.from = `${date.toDateString()}T${dayRecord.from.split('T')[1]}`;
                  dayRecord.fromTimestamp = fromTimestamp;
                }

                setDayForm(dayRecord);
              }
            }}
            disabled={!isEnabled}
            fullWidth
          />
        </Box>

        <Box>{isOvernight && <PlusOneNextDayComponent />}</Box>

        <Box>
          <Typography
            sx={{
              ...themeFonts.title4,
              color: isEnabled ? undefined : themeColors.Grey,
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >
            {convertMinutesToClockHours(dayTime, polyglot)}
          </Typography>
        </Box>

        <Box>
          <TimePickerComponent
            textFieldKey="break"
            label={polyglot.t('ScheduleRow.break')}
            value={values[day][weekNo] && values[day][weekNo] ? values[day][weekNo]!.break : ''}
            onChange={(event) => {
              const time = event.target.value;
              if (isValidTimeString(time)) {
                const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                const dayArray = values[day][weekNo] ?? null;
                const dayRecord = { ...(dayArray ? dayArray : getEmptyDaySlot()) };

                dayRecord.break = date.toFullString();

                setDayForm(dayRecord);
              }
            }}
            disabled={!isEnabled}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
