import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { IconContentRadioCard } from '@v2/components/theme-components/icon-content-radio-card.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { StyledRadio } from '@v2/components/theme-components/styled-radio.component';
import { Typography } from '@v2/components/typography/typography.component';
import { OTHER_PAYROLL_ENTITY } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup.page';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CreateCustomBenefitDto,
  CustomBenefitCategory,
  CustomBenefitType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from '@/lib/routes';

interface ChoosePayrollStepProps {
  name: string;
  onNext: () => void;
  payrolls: CompanyPayroll[];
  payrollId: number;
  setPayrollId: (payrollId: number) => void;
  readonly refresh: () => Promise<void>;
}

export const ChoosePayrollStep = ({
  name,
  onNext,
  payrolls,
  refresh,
  payrollId,
  setPayrollId,
}: ChoosePayrollStepProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();

  const [isCreatingCustomBenefit, setIsCreatingCustomBenefit] = useState<boolean>(false);

  const createCustomPension = useCallback(async () => {
    try {
      setIsCreatingCustomBenefit(true);
      const data: CreateCustomBenefitDto = {
        category: CustomBenefitCategory.Pension,
        type: CustomBenefitType.Recurring,
        name: name,
        description: null,
        includedUserIds: [],
        benefitLink: null,
      };
      const id = await CustomBenefitAPI.createCustomBenefit(data);
      await refresh();
      history.push(
        generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          category: CustomBenefitCategory.Pension,
          id,
          productType: 'custom',
        })
      );
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsCreatingCustomBenefit(false);
  }, [polyglot, name, history, showMessage, refresh]);

  const nextStep = useCallback(async () => {
    if (payrollId === OTHER_PAYROLL_ENTITY) await createCustomPension();
    else onNext();
  }, [onNext, payrollId, createCustomPension]);

  return (
    <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
      <Typography variant="title2" sx={{ mb: spacing.mb30 }}>
        {polyglot.t('PensionModule.selectPayrollEntity')}
      </Typography>
      {payrolls.map((payroll) => (
        <IconContentRadioCard
          key={payroll.id}
          cardOnClick={() => setPayrollId(payroll.id)}
          title={payroll.entity?.legalName ?? `PayrollID ${payroll.id}`}
          content={null}
          action={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <StyledRadio
                name={payroll.entity?.legalName ?? `PayrollID ${payroll.id}`}
                id={`payroll-${payroll.id}`}
                value={`${payroll.id}`}
                selectedValue={`${payrollId || ''}`}
              />
            </Box>
          }
          showDivider
          sx={{ minHeight: '40px', maxHeight: '40px' }}
        />
      ))}

      <IconContentRadioCard
        key="other"
        cardOnClick={() => setPayrollId(OTHER_PAYROLL_ENTITY)}
        title={polyglot.t('General.other')}
        content={null}
        action={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'end',
            }}
          >
            <StyledRadio
              name={polyglot.t('General.other')}
              id={`payroll-other`}
              value={String(OTHER_PAYROLL_ENTITY)}
              selectedValue={`${payrollId || ''}`}
            />
          </Box>
        }
        showDivider
        sx={{ minHeight: '40px', maxHeight: '40px' }}
      />

      <Box sx={{ ...buttonBoxSx, mt: spacing.mt40 }}>
        <LoaderButton
          name={polyglot.t('General.continue')}
          sizeVariant="medium"
          colorVariant="primary"
          loading={isCreatingCustomBenefit}
          fullWidth
          onClick={nextStep}
          disabled={!payrollId}
        />
      </Box>
    </Box>
  );
};
