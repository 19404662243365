import React, { useCallback, useEffect, useState } from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  sx?: SxProps;
};

export const ResendAuthCodeLink = ({ sx }: Props) => {
  const { polyglot } = usePolyglot();

  const [sendNewAuthCodeState, setSendNewAuthCodeState] = useState<'sending' | Date | null>(null);

  const sendNewAuthCode = useCallback(() => {
    (async () => {
      setSendNewAuthCodeState('sending');
      await AuthAPI.requestMFAEnableCode();
      setSendNewAuthCodeState(new Date());
    })();
  }, []);

  useEffect(() => {
    if (sendNewAuthCodeState instanceof Date) {
      // add a small delay before allowing the user to request another code
      const allowAnotherSendAt = sendNewAuthCodeState.getTime() + 10e3;
      const timeToWait = Math.max(allowAnotherSendAt - Date.now(), 0);
      const t = setTimeout(() => setSendNewAuthCodeState(null), timeToWait);
      return () => clearTimeout(t);
    }
  }, [sendNewAuthCodeState]);

  return (
    <Box sx={sx}>
      {!sendNewAuthCodeState && (
        <Typography
          title={polyglot.t('ResendAuthCodeLink.ifNotReceived')}
          onClick={() => sendNewAuthCode()}
          sx={{
            ...themeFonts.caption,
            color: themeColors.Grey,
            '&:hover': { color: themeColors.DarkGrey, cursor: 'pointer' },
          }}
        >
          {polyglot.t('ResendAuthCodeLink.resend')}
        </Typography>
      )}
      {sendNewAuthCodeState instanceof Date && (
        <Typography
          sx={{
            ...themeFonts.caption,
            color: themeColors.DarkGrey,
          }}
        >
          {polyglot.t('ResendAuthCodeLink.newConfirmation')}
        </Typography>
      )}
    </Box>
  );
};
