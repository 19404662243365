import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { PaymentFlowPage } from '@/v2/feature/payments/features/make-payment/pages/layout/components/payment-flow.page';
import { usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { PAYMENTS_ROUTE } from '@/v2/feature/payments/payments.router';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export function CreatePaymentPage(): JSX.Element {
  const routerHistory = useHistory();

  const [state] = usePaymentContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [showPayerDetails, setShowPayerDetails] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    if (state.payments && state.payments?.length < 1) {
      routerHistory.push(PAYMENTS_ROUTE);
    }
    const isSinglePayment = state.payments?.length === 1 && !state.institution?.config?.singlePayment?.payerRequired;
    const isBulkPayment =
      state.payments && state.payments.length > 1 && !state.institution?.config?.bulkPayment?.payerRequired;
    if (isSinglePayment || isBulkPayment) {
      setShowPayerDetails(false);
    }

    setLoading(false);
  }, [
    state,
    state.payments,
    state.institution?.config?.bulkPayment?.payerRequired,
    state.institution?.config?.singlePayment?.payerRequired,
    routerHistory,
  ]);

  return (
    <RootStyle>
      <ContentWrapper
        loading={loading}
        noHeader={true}
        border={false}
        sx={{ paddingTop: spacing.p30, ...spacing.px40 }}
      >
        <PaymentFlowPage showPayerDetails={showPayerDetails} />
      </ContentWrapper>
    </RootStyle>
  );
}
