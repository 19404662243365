import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { PaginationState } from '@tanstack/react-table';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI, AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsenceDto, AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown, AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { AllowanceAdjustmentDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/allowance-adjustment-drawer.component';
import { BalanceAdjustmentsDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/balance-adjustments-drawer.component';
import { PolicyBreakdownCard } from '@v2/feature/absence/me/policies/policy-breakdown/components/policy-breakdown-card.component';
import { PolicyBreakdown } from '@v2/feature/absence/me/policies/policy-breakdown/components/policy-breakdown.component';
import { AbsenceDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import { AbsenceViewDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page';
import { AbsenceTable, getInitialFilterString } from '@v2/feature/absence/sections/absence-table.section';
import { AbsenceUserFilterFeaturesUpdater } from '@v2/feature/absence/subfeatures/settings/components/absence-user-filter-feature-updater.component';
import { Content2Columns } from '@v2/feature/app-layout/features/main-content/layouts/components/content-2-columns.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { matchPath, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import { USER_ABSENCE_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { iconSize } from '@/v2/styles/menu.styles';

const PERSONAL_ABSENCE_PAGE_SIZE = 100;

interface PersonalAbsenceProps {
  readonly userId: number;
  readonly year: 'current' | 'last' | 'next';
  readonly absencePolicies: readonly AbsencePolicyDto[] | null;
  readonly setIsAbsenceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly isAbsenceDrawerOpen: boolean;
  readonly selectedPolicy: AbsencePolicyDto | null;
  readonly setSelectedPolicy: React.Dispatch<React.SetStateAction<AbsencePolicyDto | null>>;
}

export const PersonalAbsenceContent = ({
  userId,
  year,
  absencePolicies,
  setIsAbsenceDrawerOpen,
  isAbsenceDrawerOpen,
  selectedPolicy,
  setSelectedPolicy,
}: PersonalAbsenceProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const { data: userBalanceDetailedStats, mutate: refreshUserBalanceDetailedStats, isLoading } = useApiClient<
    UserBalanceDetailedStatsDto,
    Error
  >(AbsenceEndpoints.fetchUserAbsenceBalanceBreakdown(userId, { calendar: year, includeFormerPolicies: true }), {
    suspense: false,
  });
  const readForAllYear = useRef(year);

  const [globalState, globalDispatch] = useContext(GlobalContext);

  const [userAbsences, setUserAbsences] = useState<readonly AbsenceDto[]>([]);
  const [loading, setLoading] = useState(false);

  const [isViewAbsenceDrawerOpen, setIsViewAbsenceDrawerOpen] = useState<boolean>(false);
  const [viewAbsence, setViewAbsence] = useState<AbsenceDto | undefined>(undefined);

  const [showMessage] = useMessage();

  const [userBalanceStats, setUserBalanceStats] = useState<UserBalanceDetailedStatsDto | undefined>(undefined);

  useEffect(() => {
    setUserBalanceStats(userBalanceDetailedStats ?? undefined);
  }, [userBalanceDetailedStats]);

  const refreshBalances = useCallback(
    async (policyId: number | 'all') => {
      try {
        // if should read for all policies, or the year has changed, get all policies balances
        if (refreshUserBalanceDetailedStats && (policyId === 'all' || readForAllYear.current !== year)) {
          await refreshUserBalanceDetailedStats();
          readForAllYear.current = year;
        } else if (policyId && policyId !== 'all') {
          const policyUpdatedData = await AbsenceAPI.getUserAbsenceBalanceBreakdownByPolicyId(userId, {
            policyId: policyId as number,
            calendar: year,
          });
          const policyUpdate = (policyUpdatedData[policyId] ?? null) as AbsenceBreakdown | null;
          setUserBalanceStats((prev) => {
            if (!prev) return policyUpdatedData;
            const update = { ...prev };
            update[policyId] = policyUpdate;
            return update;
          });
        }
      } catch (error) {
        showMessage(
          polyglot.t('PersonalAbsence.errorMessages.couldNotRefreshBalance', { nestError: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, refreshUserBalanceDetailedStats, showMessage, userId, year]
  );

  const initialFilterString = useMemo(
    () =>
      absencePolicies
        ? getInitialFilterString(
            globalState.user?.features?.timeAway?.requestsTable?.personalFilters ?? '',
            absencePolicies
          )
        : '',
    [globalState.user, absencePolicies]
  );

  const [drawerAbsence, setDrawerAbsence] = useState<AbsenceDto | undefined>(undefined);
  const [filterString, setFilterString] = useState<string>(initialFilterString);

  useEffect(() => {
    setFilterString((prev) => (prev ? prev : initialFilterString));
  }, [initialFilterString]);

  const [searchInput, setSearchInput] = useState<string>('');

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PERSONAL_ABSENCE_PAGE_SIZE,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isAdjustmentsDrawerOpen, setIsAdjustmentsDrawerOpen] = useState(false);
  const [isAllowanceUpdateDrawerOpen, setIsAllowanceUpdateDrawerOpen] = useState(false);

  const location = useLocation();
  // Show button only in user profile -> time planner section and only if the profile is the current user's profile
  const shouldShowNewRequestButton = useMemo(
    () =>
      matchPath(location.pathname, {
        path: USER_ABSENCE_ROUTE,
        exact: true,
      }) && checkScopes(globalState.user, ['absence'], { userId }),
    [location.pathname, userId, globalState.user]
  );

  const canManage = useMemo(
    () =>
      matchPath(location.pathname, {
        path: USER_ABSENCE_ROUTE,
        exact: true,
      }) && checkScopes(globalState.user, ['absence:manager'], { userId }),
    [location.pathname, userId, globalState.user]
  );

  const refreshAbsences = useCallback(
    async (policyId: number) => {
      setLoading(true);
      try {
        const userAbsencesResponse = await AbsenceAPI.fetchUserAbsences(
          userId,
          policyId,
          year,
          pagination.pageIndex,
          pagination.pageSize,
          filterString,
          searchInput
        );
        setIsViewAbsenceDrawerOpen(false);
        setUserAbsences(userAbsencesResponse.items);
        setTotalPages(userAbsencesResponse.totalPages);
        setTotalItems(userAbsencesResponse.totalItems);
      } catch (error) {
        showMessage(
          polyglot.t('PersonalAbsence.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
      setLoading(false);
    },
    [filterString, pagination.pageIndex, pagination.pageSize, searchInput, showMessage, userId, polyglot, year]
  );

  const refreshPageData = useCallback(
    async (policyId: number) => {
      setLoading(true);
      await Promise.all([refreshAbsences(policyId), refreshBalances(policyId)]);
    },
    [refreshBalances, refreshAbsences]
  );

  useEffect(() => {
    if (!selectedPolicy) return;
    refreshPageData(selectedPolicy.id);
  }, [selectedPolicy, refreshPageData, selectedPolicy?.id]);

  const deleteAbsence = async (absenceId: number) => {
    await AbsenceAPI.deleteAbsenceRecord(absenceId);
    setUserAbsences(userAbsences.filter((absence) => absence.absenceId !== absenceId));
  };

  const removeRequestHelper = async (absence: AbsenceDto) => {
    try {
      await deleteAbsence(absence.absenceId);
      showMessage(polyglot.t('PersonalAbsence.successMessages.remove'), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('PersonalAbsence.errorMessages.remove', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    await refreshPageData(absence.policyId);
  };

  const decideAbsence = useCallback(
    async (absence: AbsenceDto, decision: AbsenceStatus, rejectionNotes?: string): Promise<AbsenceDto | undefined> => {
      let updatedAbsence: AbsenceDto | undefined;
      try {
        if (decision === AbsenceStatus.Approved) {
          const updatedAbsences = await AbsenceAPI.approveAllAbsences([absence.absenceId]);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t('PersonalAbsence.successMessages.approve'), 'success');
        } else if (decision === AbsenceStatus.Rejected) {
          const updatedAbsences = await AbsenceAPI.rejectAllAbsences([absence.absenceId], rejectionNotes);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t('PersonalAbsence.successMessages.reject'), 'success');
        }
        await refreshPageData(absence.policyId);
      } catch (err) {
        showMessage(nestErrorMessage(err), 'error');
      }
      return updatedAbsence;
    },
    [refreshPageData, showMessage, polyglot]
  );

  const handleApprove = useCallback((a: AbsenceDto) => decideAbsence(a, AbsenceStatus.Approved), [decideAbsence]);
  const handleReject = useCallback(
    (a: AbsenceDto, rejectionNotes?: string) => decideAbsence(a, AbsenceStatus.Rejected, rejectionNotes),
    [decideAbsence]
  );
  const onRequestCancellation = useCallback(
    async (absence: AbsenceDto) => {
      try {
        await AbsenceAPI.requestTimeRequestCancellation(absence.absenceId);
        showMessage(polyglot.t('PersonalAbsence.successMessages.cancel'), 'success');
        await refreshPageData(absence.policyId);
      } catch (error) {
        showMessage(
          polyglot.t('PersonalAbsence.errorMessages.cancel', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [refreshPageData, showMessage, polyglot]
  );

  const onForceApproval = useCallback(
    async (absence: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => {
      try {
        await AbsenceAPI.forceApprovalByAbsencesIds([absence.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved
              ? 'PersonalAbsence.successMessages.approve'
              : 'PersonalAbsence.successMessages.reject'
          ),
          'success'
        );
        await refreshPageData(absence.policyId);
      } catch (error) {
        showMessage(
          polyglot.t('PersonalAbsence.errorMessages.cancel', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [refreshPageData, showMessage, polyglot]
  );

  const handleRowClicked = useCallback((original: AbsenceDto) => {
    setViewAbsence(original);
    setIsViewAbsenceDrawerOpen(true);
  }, []);

  const pageActions = useMemo(
    () => [
      {
        // title: polyglot.t('General.actions'),
        title: '',
        options: [
          {
            // icon: <Calendar {...iconSize} />,
            handler: async () => {
              setIsAbsenceDrawerOpen(true);
              setDrawerAbsence(undefined);
            },
            label: polyglot.t('AbsenceRequestsPage.newRequest'),
          },
          {
            handler: async () => {
              setIsAllowanceUpdateDrawerOpen(true);
            },
            label: polyglot.t('AbsenceRequestsPage.editAllowance'),
          },
          {
            handler: async () => {
              setIsAdjustmentsDrawerOpen(true);
            },
            label: polyglot.t('AbsenceRequestsPage.addAnAdjustment'),
          },
        ],
      },
    ],
    [setIsAbsenceDrawerOpen, polyglot]
  );

  return (
    <ContentWrapper loading={isLoading} sx={{ pt: 0 }}>
      <Content2Columns
        column1={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            {userBalanceStats &&
              absencePolicies &&
              absencePolicies.map((policy) => (
                <PolicyBreakdownCard
                  key={policy.id}
                  absencePolicy={policy}
                  userBalanceDetailedStats={userBalanceStats}
                  isActive={Boolean(selectedPolicy && policy.id === selectedPolicy.id)}
                  onClick={() => setSelectedPolicy(policy)}
                />
              ))}
          </Box>
        }
        column2={
          <Box>
            {userBalanceStats && selectedPolicy && (
              <PolicyBreakdown
                isCurrentYear={year === 'current'}
                userId={userId}
                refresh={refreshBalances}
                absencePolicy={selectedPolicy}
                userBalanceDetailedStats={userBalanceStats}
              />
            )}

            <ContentWrapper loading={false} noHorizontalPadding sx={{ paddingTop: '20px' }}>
              {absencePolicies &&
                (userAbsences.length > 0 ||
                  (userAbsences.length === 0 && (filterString || searchInput || pagination.pageIndex > 1))) && (
                  <AbsenceTable
                    rows={userAbsences}
                    loading={loading}
                    onEdit={(absence: AbsenceDto) => {
                      setDrawerAbsence(absence);
                      setIsAbsenceDrawerOpen(true);
                    }}
                    onDelete={removeRequestHelper}
                    onApprove={handleApprove}
                    onReject={handleReject}
                    onRequestCancellation={onRequestCancellation}
                    onRowClick={handleRowClicked}
                    onForceApproval={onForceApproval}
                    newRequestButton={
                      shouldShowNewRequestButton ? (
                        canManage ? (
                          <StyledMenuComponent
                            headerOptions={pageActions}
                            actionButtonDetails={{
                              type: 'button',
                              colorVariant: 'secondary',
                              sizeVariant: 'small',
                              title: polyglot.t('CalendarPage.actions'),
                              icon: <ArrowDown {...iconSize} />,
                              iconPosition: 'end',
                              fullWidth: true,
                            }}
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                          />
                        ) : (
                          <ButtonComponent
                            sizeVariant="small"
                            colorVariant="primary"
                            onClick={() => {
                              setDrawerAbsence(undefined);
                              setIsAbsenceDrawerOpen(true);
                            }}
                          >
                            {polyglot.t('PersonalAbsence.newRequest')}
                          </ButtonComponent>
                        )
                      ) : undefined
                    }
                    pagination={pagination}
                    setPagination={setPagination}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    searchInput={searchInput}
                    setDebouncedSearchInput={(value: string) => {
                      setSearchInput(!value ? '' : value);
                      // Resets pagination index once we perform a new search
                      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                    }}
                    filterString={filterString}
                    setFilterString={setFilterString}
                    view="user"
                    userId={userId}
                    hideTimePolicyFilter
                    hidePolicyColumn
                  />
                )}
              {userAbsences.length === 0 && !filterString && !searchInput && pagination.pageIndex === 1 && (
                <Box sx={{ pl: spacing.p5, display: 'flex', flexDirection: 'column', gap: spacing.m10 }}>
                  <Typography variant="title4">{polyglot.t('PersonalAbsence.trackTime')}</Typography>
                  <Typography variant="caption">{polyglot.t('PersonalAbsence.youCanRequest')}</Typography>
                  {shouldShowNewRequestButton && (
                    <Box>
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="primary"
                        onClick={() => {
                          setIsAbsenceDrawerOpen(true);
                          setDrawerAbsence(undefined);
                        }}
                      >
                        {polyglot.t('PersonalAbsence.newRequest')}
                      </ButtonComponent>
                    </Box>
                  )}
                </Box>
              )}
            </ContentWrapper>
            {selectedPolicy && isAbsenceDrawerOpen && (
              <AbsenceDrawer
                isOpen={isAbsenceDrawerOpen}
                onClose={() => {
                  setIsAbsenceDrawerOpen(false);
                  setDrawerAbsence(undefined);
                }}
                refresh={refreshPageData}
                absence={drawerAbsence}
                userId={userId}
                setIsAbsenceDrawerOpen={setIsAbsenceDrawerOpen}
                policyId={selectedPolicy.id}
              />
            )}
            {viewAbsence && isViewAbsenceDrawerOpen && !isAbsenceDrawerOpen && (
              <AbsenceViewDrawer
                isOpen={isViewAbsenceDrawerOpen}
                absence={viewAbsence}
                setAbsence={setViewAbsence}
                afterClose={() => {
                  setViewAbsence(undefined);
                }}
                setIsAbsenceDrawerViewOpen={setIsViewAbsenceDrawerOpen}
                handleApprove={handleApprove}
                handleReject={handleReject}
                onDelete={removeRequestHelper}
                onRequestCancellation={onRequestCancellation}
                onForceApproval={onForceApproval}
                onEdit={(absence: AbsenceDto) => {
                  setDrawerAbsence(absence);
                  setIsAbsenceDrawerOpen(true);
                  setIsViewAbsenceDrawerOpen(false);
                }}
                refresh={async () => await refreshPageData(viewAbsence!.policyId)}
                showCalendarLink
              />
            )}
            {userBalanceDetailedStats && selectedPolicy && (
              <BalanceAdjustmentsDrawer
                isOpen={isAdjustmentsDrawerOpen}
                setIsOpen={setIsAdjustmentsDrawerOpen}
                userId={userId}
                absencePolicy={selectedPolicy}
                policyBreakdown={userBalanceDetailedStats[selectedPolicy.id] as AbsenceBreakdown}
                refresh={async () => await refreshPageData(selectedPolicy.id)}
                isOnRegularSchedule={!!userBalanceDetailedStats[selectedPolicy.id]?.isOnRegularSchedule}
                currentAverageWorkDayLength={
                  userBalanceDetailedStats[selectedPolicy.id]?.currentAverageWorkDayLength ?? 480
                }
              />
            )}
            {userBalanceDetailedStats &&
              selectedPolicy &&
              userBalanceDetailedStats[selectedPolicy.id] &&
              selectedPolicy && (
                <AllowanceAdjustmentDrawer
                  isOpen={isAllowanceUpdateDrawerOpen}
                  setIsOpen={setIsAllowanceUpdateDrawerOpen}
                  userId={userId}
                  absencePolicy={selectedPolicy}
                  refresh={async () => await refreshPageData(selectedPolicy.id)}
                  policyBreakdown={userBalanceDetailedStats[selectedPolicy.id] as AbsenceBreakdown}
                  currentCycle={userBalanceDetailedStats[selectedPolicy.id]!.currentCycle}
                />
              )}
            <AbsenceUserFilterFeaturesUpdater
              filterString={filterString}
              initialFilterString={initialFilterString}
              globalDispatch={globalDispatch}
              view="personal"
            />
          </Box>
        }
        gap="gap40"
      />
    </ContentWrapper>
  );
};
