import { useCallback, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_MONEY_SALARY_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollLocalApi } from '@/v2/feature/payroll/payroll-local.api';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { StyledSwipeableDrawer } from '@/v2/styles/sidebar-edit.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
  readonly sx?: SxProps<Theme>;
  readonly refreshPayroll: () => Promise<void>;
}

export const PayrollDeleteSection = ({ payroll, sx, refreshPayroll }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showDeletePayrollDrawer, setShowDeletePayrollDrawer] = useState(false);
  const [typedEntityName, setTypedEntityName] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      await PayrollLocalApi.deletePayroll(payroll.id);
      await refreshPayroll();
      showMessage('Payroll removed successfully.', 'success');
      // navigate back to the salary settings page
      routerHistory.push(SETTINGS_MONEY_SALARY_ROUTE);
    } catch (error) {
      showMessage(`Payroll deletion failed. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDeleting(false);
    }
  }, [payroll, refreshPayroll, routerHistory, setIsDeleting, showMessage]);

  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader>Delete payroll</PayrollSettingSectionHeader>
      <Typography variant="caption">
        If you want to permanently delete this payroll and all of its data, including payslips and reports, you can do
        so&nbsp;
        <ButtonComponent
          sizeVariant="link"
          colorVariant="text"
          style={{ color: themeColors.RedDark, display: 'inline' }}
          onClick={() => setShowDeletePayrollDrawer(true)}
        >
          here
        </ButtonComponent>
        .
      </Typography>
      <StyledSwipeableDrawer
        anchor="right"
        onClose={() => setShowDeletePayrollDrawer(false)}
        onOpen={() => {}}
        open={showDeletePayrollDrawer}
      >
        {showDeletePayrollDrawer && (
          <Stack sx={{ width: '400px', padding: '20px', gap: spacing.g20, ...drawerContentSx }}>
            <Typography variant="title2">Delete payroll</Typography>

            <Typography variant="caption">Please type the payroll entity name to confirm.</Typography>

            <TextfieldComponent
              name="entity-name"
              value={typedEntityName}
              onChange={(e) => setTypedEntityName(e.target.value)}
              autoFocus
              disabled={isDeleting}
            />

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="danger"
                fullWidth
                loading={isDeleting}
                disabled={typedEntityName !== payroll.entity.legalName}
                onClick={() => handleDelete()}
              >
                {polyglot.t('General.delete')}
              </ButtonComponent>
            </Box>
          </Stack>
        )}
      </StyledSwipeableDrawer>
    </Stack>
  );
};
