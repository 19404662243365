import { Box, Link } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { spacing } from '@/v2/styles/spacing.styles';

export const JiraApiTokenInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        a) Log in to{' '}
        <Link target="_blank" href="https://id.atlassian.com/manage-profile/security/api-tokens">
          Atlassian - API Tokens site
        </Link>
        .
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        b) Click Create API token.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        c) From the dialog that appears, enter a memorable and concise Label for your token and click Create.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        d) Click Copy to clipboard, then paste the token here
      </Typography>
    </Box>
  );
};

export const JiraSiteUrlInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        a) Log in to{' '}
        <Link target="_blank" href="https://admin.atlassian.com/">
          Atlassian Admin
        </Link>
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        b) Choose the organisation you intend to integrate Zelt with.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        c) Click the three dots menu, and Open Jira Software
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        d) The tab that opens up will have your Jira Site URL, and it will be something like mycompany.atlassian.net
      </Typography>
    </Box>
  );
};

export const JiraAdminEmailInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        NOTE: This email has to belong to your Site / Organisation admin; any other email address will not allow you to
        integrate successfully
      </Typography>
    </Box>
  );
};
