import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { Dictionary } from 'lodash';

import { ChipComponent } from '@/v2/components/chip/chip.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { ResultsOverviewQuestionDisplayBar } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-question-display-bar.component';
import { getReviewerTypesChips } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { QuestionResponseSummary } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { GridDisplayBarV2 } from '@/v2/feature/growth/shared/components/grid-display-bar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ResultsOverviewByQuestionModal = ({
  selectedQuestion,
  isOpen,
  setIsOpen,
  onClose,
  growthFactors,
}: {
  selectedQuestion: QuestionResponseSummary | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} widthPercentage={95}>
      <ResultsOverviewByQuestionModalContent
        selectedQuestion={selectedQuestion}
        answers={selectedQuestion?.answers ?? []}
        question={selectedQuestion}
        growthFactors={growthFactors}
      />
    </DrawerModal>
  );
};

const ResultsOverviewByQuestionModalContent = ({
  selectedQuestion,
  answers,
  question,
  growthFactors,
}: {
  selectedQuestion: QuestionResponseSummary | undefined;
  answers: ReviewAnswer[];
  question: Pick<QuestionResponseSummary, 'type' | 'scaleConfig' | 'answerOptions'> | undefined;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  if (!selectedQuestion) return <></>;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g48 }}>
      <Box>
        <Typography variant="title2">Question</Typography>
        <ResultsOverviewQuestionDisplayBar question={selectedQuestion} growthFactors={growthFactors} />
      </Box>

      <Box>
        <Typography variant="title2">Answers</Typography>
        {answers && answers.map((a) => <ReviewAnswerComponent answer={a} question={question} />)}
      </Box>
    </Box>
  );
};

const ReviewAnswerComponent = ({
  answer,
  question,
}: {
  answer: ReviewAnswer;
  question: Pick<QuestionResponseSummary, 'type' | 'scaleConfig' | 'answerOptions'> | undefined;
}) => {
  const scaleLabel = useMemo(() => {
    return answer.answer && question
      ? `${question.scaleConfig?.points?.[answer.answer]} (${
          question.scaleConfig?.value?.[answer.answer] || 0
        } out of ${Math.max(...Object.values(question.scaleConfig?.value || []).map(Number))})`
      : 'NA';
  }, [answer.answer, question]);

  if (!question) return <></>;

  return (
    <GridDisplayBarV2
      cells={[
        {
          content: (
            <Box sx={{ display: 'flex', width: '85%', flexDirection: 'column', gap: spacing.g8 }}>
              {answer.comment ? (
                <Typography variant="caption" dangerouslySetInnerHTML={{ __html: answer.comment ?? '' }} />
              ) : (
                <Typography variant="caption" color="Grey">
                  No comment provided
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
                <ChipComponent
                  name={
                    answer.reviewerId ? (
                      <UserCell userId={answer.reviewerId} nameVariant="caption" nameSx={{ color: themeColors.Grey }} />
                    ) : (
                      <Typography variant="caption" color="Grey">
                        Author hidden
                      </Typography>
                    )
                  }
                  backgroundColor="white"
                  border="background"
                />

                <Box> {getReviewerTypesChips(false)[answer.answerType]}</Box>
              </Box>
            </Box>
          ),
          gridXs: 8,
        },
        {
          content: answer.answer ? (
            <>
              {question.type === QuestionType.ScaleQuestion ? (
                <Typography variant="caption" color="DarkGrey">
                  {scaleLabel}
                </Typography>
              ) : question.type === QuestionType.MultipleAnswer ? (
                <Typography variant="caption" color="DarkGrey">
                  {answer.answer
                    .split(',')
                    .map((ans) => question.answerOptions?.[ans])
                    .filter(Boolean)
                    .join(', ')}
                </Typography>
              ) : question.type === QuestionType.SingleAnswer ? (
                <Typography variant="caption" color="DarkGrey">
                  {question.answerOptions?.[answer.answer] || 'NA'}
                </Typography>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          ),
          gridXs: 4,
          sx: { display: 'flex', justifyContent: 'flex-start' },
        },
      ]}
    />
  );
};
