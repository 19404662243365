import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { CreateZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { DocumentTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { DocumentFormModal } from '@/v2/feature/documents/components/document-form-modal.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { DocumentAction } from '@/v2/feature/documents/documents.interface';
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal,
} from '@/v2/feature/documents/documents.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

const initialState = initialiseState([]);

export const DocumentActionItem = ({
  documentActionRow,
  refresh,
  afterClose,
}: {
  documentActionRow: DocumentTodo;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const [documentFormMode, setDocumentFormMode] = useState<DocumentAction>('add');
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [filterValue, setFilterValue] = useState<string>('');

  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(['documents:all'], getScopesContext(globalState.user));
  const [open, setOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const allUsers = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);

  // need to set document types or an empty array, sets the pending document incorrectly to Other
  useEffect(() => {
    (async () => {
      try {
        const types = await DocumentAPI.listDocumentTypes();
        documentDispatch({ kind: 'set_types', value: types });
      } catch (error) {
        console.error(`Something bad happened. ${nestErrorMessage(error)}`);
      }
    })();
  }, []);

  const getDocumentById = useCallback(async () => {
    try {
      const document = await DocumentAPI.getDocumentById(Number(documentActionRow.id));
      documentDispatch({ kind: 'edit_document', value: document, userList: allUsers });
      setDocumentFormMode('edit');
      setFilterValue(
        getDocumentParentCategory(
          'me',
          'edit',
          document,
          currentUserIsDocumentAdmin,
          documentState.types as ZeltDocumentType[]
        )
      );
      setOpen(true);
    } catch (error) {
      showMessage(
        polyglot.t('DocumentActionItem.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [polyglot, documentActionRow.id, allUsers, currentUserIsDocumentAdmin, documentState.types, showMessage]);

  useEffect(() => {
    getDocumentById();
  }, [getDocumentById]);

  const completeSave = async () => {
    try {
      let documentObject: CreateZeltDocument = {
        name: documentState.name || '',
        type: documentState.type?.value ?? '',
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid,
        note: documentState.note,
        expirySettings: documentState?.expirySettings ?? { expiryDate: '', documentExpires: false },
      };
      if (documentState.id && documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t('DocumentActionItem.successMessages.update'), 'success');
      } else if (documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.createDocument(documentObject);
        showMessage(polyglot.t('DocumentActionItem.successMessages.save'), 'success');
      }
      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('DocumentActionItem.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  function closeUploadModal() {
    setOpen(false);
  }

  return (
    <DocumentFormModal
      mode={documentFormMode}
      state={documentState}
      currentUserIsAdmin={currentUserIsDocumentAdmin}
      dispatch={documentDispatch}
      newDocumentTypes={documentState.types as ZeltDocumentType[]}
      onClose={closeUploadModal}
      afterClose={afterClose}
      onSubmit={completeSave}
      setOpen={setOpen}
      open={open}
      setLoading={() => {}}
      getDocuments={async () => {
        await refresh();
      }}
      setFilterValue={setFilterValue}
      filterValue={filterValue}
    />
  );
};
