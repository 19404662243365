import { useCallback, useContext, useEffect, useState } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TaskTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { TaskFormModal } from '@/v2/feature/task/components/task-add-modal/task-form-modal.component';
import {
  TaskAndChecklistItemCommon,
  TaskViewModal,
} from '@/v2/feature/task/components/task-view/task-view-modal.component';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { TaskDto } from '@/v2/feature/task/task.dto';
import { TaskStatuses } from '@/v2/feature/task/task.interface';

export const TaskActionItem = ({
  taskActionRow,
  refresh,
  afterClose,
}: {
  taskActionRow: TaskTodo;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [showMessage] = useMessage();

  const [selectedTask, setSelectedTask] = useState<TaskDto | null>(null);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const getTaskById = useCallback(async () => {
    try {
      const task = await TaskAPI.getTaskById(Number(taskActionRow.id));
      setSelectedTask(task);
      setIsViewOpen(true);
    } catch (error) {
      console.error(error);
      showMessage(polyglot.t('TaskActionItem.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }), 'error');
      setSelectedTask(null);
    }
  }, [taskActionRow.id, showMessage, polyglot]);

  useEffect(() => {
    getTaskById();
  }, [getTaskById]);

  const updateTaskStatus = async (taskId: number, assignedUserId: number, status: TaskStatuses) => {
    try {
      await TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
      showMessage(polyglot.t('TaskActionItem.successMessages.updateStatus'), 'success');
      await refresh();
      if (selectedTask) setSelectedTask({ ...selectedTask, status });
    } catch (error) {
      showMessage(
        polyglot.t('TaskActionItem.errorMessages.updateStatus', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const deleteTaskAfterConfirmation = async () => {
    try {
      if (selectedTask?.id) await TaskAPI.removeTasks([selectedTask?.id]);
      showMessage(polyglot.t('TaskActionItem.successMessages.deleteMult'), 'success');
      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('TaskActionItem.errorMessages.removeMult', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  return (
    <>
      <TaskFormModal
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        afterClose={() => {
          setSelectedTask(null);
          afterClose();
        }}
        userId={currentUser.userId}
        formData={selectedTask}
        refresh={refresh}
      />

      <TaskViewModal
        isOpen={isViewOpen}
        setIsOpen={setIsViewOpen}
        onClose={() => {
          setIsViewOpen(false);
        }}
        afterClose={() => {
          setSelectedTask(null);
          afterClose();
        }}
        task={selectedTask as TaskAndChecklistItemCommon}
        action={updateTaskStatus}
        deleteTask={deleteTaskAfterConfirmation}
        editTask={() => {
          setIsEditOpen(true);
          setIsViewOpen(false);
        }}
      />
    </>
  );
};
