import React, { forwardRef, useCallback, useRef, useState } from 'react';

import { Box, IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { ReactComponent as EyeSlash } from '@/images/side-bar-icons/EyeSlash.svg';
import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as QuestionIcon } from '@/images/side-bar-icons/Question.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ClearIconButton } from '@/v2/components/theme-components/clear-icon-button.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

export type Props = StandardTextFieldProps & {
  readonly clearText?: () => void;
  readonly endAdornment?: 'clear-text' | 'none' | 'copy' | JSX.Element;
  readonly maxLength?: number;
  readonly tooltip?: string | JSX.Element;
  readonly InputProps?: Partial<StandardInputProps>;
  readonly autoTrim?: boolean;
  readonly minValue?: number;
  readonly maxValue?: number;
  readonly validateNumber?: boolean;
};
const iconSize = { width: '14px', height: '14px' } as const;

const defaultTo = <T,>(value: T | undefined, defaultValue: T) => (typeof value === 'undefined' ? defaultValue : value);

export const TextfieldComponent = forwardRef<typeof TextField, Props>(
  (
    {
      id,
      value,
      type,
      onBlur,
      onChange,
      error,
      clearText,
      disabled,
      endAdornment = 'clear-text',
      autoComplete,
      autoFocus,
      multiline,
      minValue,
      maxValue,
      spellCheck,
      maxLength,
      tooltip,
      InputProps = {},
      fullWidth = true,
      autoTrim = true,
      validateNumber = false,
      ...textFieldProps
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [copied, setCopied] = useState(false);
    const textFieldType = type === 'password' && showPassword ? 'text' : type;
    const initialValue = useRef(value).current;
    const [showMessage] = useMessage();
    const checkNumberValue = useCallback(
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const { min, max, value } = e.target as HTMLInputElement;
        let parsedValue;
        try {
          parsedValue = parseInt(value);
        } catch (e) {
          showMessage(`Something went wrong. ${nestErrorMessage(e)}`, 'error');
          return;
        }
        if (!parsedValue) {
          return;
        }
        let trimmedValue = parsedValue;

        if (min !== undefined && parsedValue <= parseInt(min)) {
          trimmedValue = parseInt(min);
        }
        if (max !== undefined && parsedValue >= parseInt(max)) {
          trimmedValue = parseInt(max);
        }
        if (trimmedValue !== parsedValue) {
          e.target.value = trimmedValue.toString();
          onChange?.(e);
        }
      },
      [onChange, showMessage]
    );
    const checkAutoTrim = useCallback(
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        if (!autoTrim) return;
        const trimmedValue = e.target.value.trim();
        if (trimmedValue === e.target.value) return;
        e.target.value = trimmedValue;
        onChange?.(e);
      },
      [autoTrim, onChange]
    );
    const handleCopy = useCallback(() => {
      setCopied(true);
      navigator.clipboard.writeText(String(value));
      setTimeout(() => setCopied(false), 3000);
    }, [value]);
    return (
      <StyledTextfield
        {...textFieldProps}
        fullWidth={fullWidth}
        variant="standard"
        placeholder={textFieldProps.placeholder}
        inputRef={ref}
        type={textFieldType}
        size="small"
        value={value ?? ''}
        error={error}
        multiline={multiline}
        disabled={disabled}
        onBlur={(e) => {
          if (textFieldType === 'number' && validateNumber) {
            checkNumberValue(e);
          } else {
            checkAutoTrim(e);
          }
          onBlur?.(e);
        }}
        onChange={onChange}
        inputProps={{
          autoComplete,
          autoFocus,
          spellCheck:
            // normally, spellCheck on inputs is on by default, but for most of our
            // editable text fields, it doesn't make sense to spell check the data
            defaultTo(spellCheck, false),
          maxLength,
          max: maxValue,
          min: minValue,
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...InputProps,
          id,
          endAdornment: (
            <InputAdornment position="end">
              {
                //value !== initialValue &&
                //!error &&
                value &&
                !multiline &&
                endAdornment === 'clear-text' &&
                clearText &&
                type !== 'password' &&
                !disabled ? (
                  <ClearIconButton onClick={clearText} />
                ) : value &&
                  value !== initialValue &&
                  !!error &&
                  endAdornment === 'clear-text' &&
                  type !== 'password' ? (
                  <MistakeIcon {...iconSize} />
                ) : value && type === 'password' ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((value) => !value)}
                  >
                    {showPassword ? <Eye {...iconSize} /> : <EyeSlash {...iconSize} />}
                  </IconButton>
                ) : !value && tooltip ? (
                  <StyledTooltip title={tooltip} placement="top">
                    <QuestionIcon {...iconSize} fill={themeColors.Grey} />
                  </StyledTooltip>
                ) : value && !multiline && endAdornment === 'copy' ? (
                  <Box sx={{ cursor: 'pointer' }}>
                    {copied ? (
                      <Chose {...iconSize} />
                    ) : (
                      <Copy {...iconSize} style={{ fill: themeColors.Grey }} onClick={handleCopy} />
                    )}
                  </Box>
                ) : typeof endAdornment !== 'string' ? (
                  endAdornment
                ) : null
              }
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
