import { useCallback, useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { ReportEntity, UpdateReportOld } from '@v2/feature/reports/reports.interface';
import {
  DevicesReportColumnsOptions,
  getDeviceReportFiltersOptions,
} from '@v2/feature/reports/util/devices-report.util';
import { exportReportCSVOldReports, extractAllColumnsFromColumnsConfig } from '@v2/feature/reports/util/report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { primarySmallBtn, secondarySmallBtn } from '@v2/styles/buttons.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';

export const DevicesReportSection = ({ report, loading }: { report: ReportDto; loading: boolean }) => {
  const { polyglot } = usePolyglot();

  const { data: devicesFiltersOptions } = useApiClient(ReportsEndpoints.getDevicesReportsFiltersOptions(), {
    suspense: false,
  });
  const [filteredData, setFilteredData] = useState<Record<string, string | number>[] | undefined>(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState<string>(report?.config?.filters ?? '');
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    report?.config?.columns ? extractAllColumnsFromColumnsConfig(report.config.columns) : []
  );
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState<boolean>(true);
  const [reportName, setReportName] = useState<string>(report?.name ?? '');
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    const filters = getDeviceReportFiltersOptions(devicesFiltersOptions, polyglot);
    setFilterTypes(filters);
  }, [polyglot, devicesFiltersOptions]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData?.filter(
        (data) => !searchInput || (data?.owner && String(data.owner).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  }, [searchInput, filteredData]);

  const getReportData = useCallback(
    async (report: ReportDto) => {
      setReportsLoading(true);
      try {
        const paramObj = {
          ...report.config,
          filters: filterString,
          columns: { [ReportEntity.Devices]: selectedColumns },
        };

        const filteredDevices = await ReportsAPI.generateDevicesCreatedReports(paramObj);
        setFilteredData(filteredDevices[ReportEntity.Devices]);
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setReportsLoading(false);
      }
    },
    [filterString, selectedColumns, showMessage]
  );

  useEffect(() => {
    if (report) getReportData(report);
  }, [getReportData, report]);

  const handleSave = async () => {
    try {
      if (report) {
        const paramObj: UpdateReportOld = {
          filters: filterString,
          fileName: reportName ?? report.name,
          columns: { [ReportEntity.Devices]: selectedColumns },
        };
        await ReportsAPI.patchReport(report.id, paramObj);
      }
      showMessage(polyglot.t('DevicesReportSection.successMessages.update'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };

  const exportCsv = async () => {
    try {
      exportReportCSVOldReports(reportName, filteredData ? { [ReportEntity.Devices]: filteredData } : undefined);
    } catch (e) {
      showMessage(polyglot.t('DevicesReportSection.errorMessages.download'), 'error');
    }
  };

  return (
    <RootStyle>
      <TopHeader
        showBack
        title={
          reportName ? (
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={undefined}
            />
          ) : (
            <></>
          )
        }
        showAction
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <Button fullWidth sx={secondarySmallBtn} startIcon={<Export {...iconSize} />} onClick={exportCsv}>
              {polyglot.t('DevicesReportSection.downloadCsv')}
            </Button>
            <Button sx={primarySmallBtn} onClick={handleSave}>
              {polyglot.t('General.save')}
            </Button>
          </Box>
        }
      />
      <ContentWrapper loading={loading} sx={{ pt: 0 }}>
        <Box sx={{ mt: spacing.m20, display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <ColumnsDrawer
            columnsOptions={DevicesReportColumnsOptions(polyglot)}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
          <FiltersDrawer
            filtersOptions={[{ filters: filterTypes }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            encodedFilterNames
          />
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
        <Box sx={{ mt: spacing.m20 }}>
          <ResultTableOldReports filteredData={filteredAndSearchedData ?? []} loading={reportLoading} />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
