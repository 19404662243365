import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomDEPTokenSteps } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component';
import {
  DepMdmTokenResponse,
  DepWizardDto,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const UploadMdmTokenComponent = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<DepWizardDto>>;
  formData: DepWizardDto;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [uploadedCorrectly, setUploadedCorrectly] = useState<boolean>(false);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography variant="title2">Upload Server Token</Typography>
        <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m20 }}>
          Upload the file you just downloaded from Apple Business Manager. This will enable the connection between Zelt
          MDM and your ABM.
        </Typography>
        <Box sx={{ display: 'block' }}>
          <UploadInput<DepMdmTokenResponse>
            sx={{
              display: 'block',
            }}
            onChange={async (response, status) => {
              console.info(`stata` + status);
              if (response) {
                const fetchData = async () => {
                  try {
                    await InHouseMdmAPI.getDepAccountDetailsAndSave(formData.mdmServerName);
                  } catch (error) {
                    showMessage('Error fetching dep account details', 'error');
                  }
                };
                await fetchData();
                setUploadedCorrectly(true);
                return;
              }
            }}
            endpoint={`/apiv2/zelt-mdm/dep/token/public/${formData.mdmServerName}`}
          />
        </Box>
        {uploadedCorrectly && (
          <ButtonComponent
            sizeVariant="large"
            colorVariant="primary"
            style={{ marginTop: spacing.m25 }}
            onClick={() => {
              formData.type = CustomDEPTokenSteps.Download_key;
              setFormData(formData);
              onNext();
            }}
            fullWidth
          >
            {polyglot.t('General.next')}
          </ButtonComponent>
        )}
      </Box>
    </Box>
  );
};
