import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { EditPensionProviderSettingsDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/components/edit-pension-provider-settings-drawer.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { definitionValueSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import { PaymentValues, PensionRulesOptions } from '@/lib/pensions';

interface PageProps {
  readonly pensionScheme: PensionSchemeDto;
  readonly refreshPension: () => Promise<void>;
}

export const PensionSettingsProviderPage = ({ pensionScheme, refreshPension }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('PensionModule.providerSettings')}
      onEdit={() => {
        setIsEditOpen(true);
      }}
    >
      <DescriptionLine
        field={polyglot.t('PensionModule.provider')}
        value={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: spacing.gap10,
              ml: 0,
            }}
          >
            {getPensionLogoByProviderName(pensionScheme.providerName)}
            <Typography sx={definitionValueSx}>
              {PensionProvidersValueToLabel[pensionScheme.providerName] ?? pensionScheme.providerName}
            </Typography>
          </Box>
        }
      />
      <DescriptionLine
        field={polyglot.t('PensionModule.provider')}
        value={
          PensionRulesOptions.find((option) => option.value === pensionScheme.pensionRule)?.label ??
          pensionScheme.pensionRule
        }
      />
      <DescriptionLine
        field={polyglot.t('PensionModule.defaultWorkerGroup')}
        value={
          pensionScheme.workerGroups.find((wG) => wG.externalId === pensionScheme.defaultWorkerGroup)?.name ?? 'N/A'
        }
      />
      <DescriptionLine
        field={polyglot.t('PensionModule.paymentMethod')}
        value={
          PaymentValues.find((option) => option.value === pensionScheme.payMethod)?.label ?? pensionScheme.payMethod
        }
      />

      {isEditOpen && (
        <EditPensionProviderSettingsDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          provider={pensionScheme}
          refresh={refreshPension}
        />
      )}
    </BenefitsSettingsPageSection>
  );
};
