import React, { forwardRef } from 'react';

import { Button, ButtonProps } from '@mui/base/Button';
import '@/v2/scss/components/icon-button-component.scss';

export type IconButtonSizeVariant = 'medium' | 'small' | 'circle';
export type IconButtonColorVariant = 'primary' | 'secondary' | 'danger' | 'light';

export type IconButtonComponentProps = ButtonProps & {
  children: React.ReactNode;
  sizeVariant: IconButtonSizeVariant;
  colorVariant: IconButtonColorVariant;
  className?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties; // sx is mui prop
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonComponentProps>(
  ({ className, sizeVariant, colorVariant, fullWidth, style, children, ...otherProps }, ref) => {
    return (
      <Button
        ref={ref}
        {...otherProps}
        color={colorVariant}
        className={`${className} ${sizeVariant}-icon-button ${colorVariant}-icon-button ${
          fullWidth ? 'fullWidth-button' : ''
        }`}
        style={style}
      >
        {children}
      </Button>
    );
  }
);
