import { User } from '@/models';
import { Pagination } from '@/models/company.model';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import {
  AttachmentWithUrl,
  DocumentTypeAvailableToType,
  DocumentUploadStatus,
} from '@/v2/feature/documents/documents.interface';
import { ExpirySettings, VisibilitySettings } from '@/v2/feature/documents/documents.util';
import { ContractObject } from '@/v2/feature/templates/templates.interface';
import { ReactNode } from 'react';
import { IMeta } from 'react-dropzone-uploader';

/** @deprecated should use the one in document state instead */
export const DEFAULT_DOCUMENT_TYPE = 'other';
export const BELONGS_TO_EVERYONE = { value: 'public-org', label: 'Everyone' } as OptionObj;

export type ZeltDocumentCategory =
  | 'Personal Documents'
  | 'Company Documents'
  | 'Tax Documents'
  | 'Contracts'
  | 'Other'
  | 'Contract';

/** @deprecated */
export interface OldZeltDocumentType {
  readonly value: string;
  readonly label: string;
  readonly mappedCategory: ZeltDocumentCategory;
  readonly icon: string;
  readonly bgColor: string;
  readonly needsVerification: false;
  // not sure if the following 2 props are available in OldZeltDocumentType - should stop using this interface
  readonly canUserAdd: boolean;
  readonly category: ZeltDocumentCategory;
}

export interface ZeltDocumentType {
  readonly id: number;
  readonly value: string;
  readonly label: string;
  readonly category: ZeltDocumentCategory;
  readonly icon: string;
  readonly bgColor: string;
  readonly canUserAdd: boolean;
  readonly availableTo?: DocumentTypeAvailableToType;
  readonly default: boolean;
  readonly visible: boolean;
  readonly createdAt: string;
  /** @todo revert and rename to multiownership */
  readonly onePersonOnly: boolean;
  readonly canBelongToEveryone: boolean;
  readonly onboardingDocument: boolean;
  readonly needsVerification: boolean;

  [x: string]: ReactNode;
}

export interface ZeltDocumentVerification {
  readonly createdAt: Date;
  readonly User: Pick<User, 'userId' | 'firstName' | 'lastName' | 'displayName'>;
}

export interface ZeltDocument {
  readonly fileUuid?: string;
  readonly fileName?: string;
  readonly belongsToUserId?: number;
  readonly fileUrl: string;
  readonly canAllEmployeesSee: boolean;
  readonly companyId: number;
  readonly id: number;
  readonly isExternalResource: false;
  readonly name: string;
  readonly note?: string;
  readonly programmaticRecordId: null;
  readonly programmaticRecordType: null;
  /** {ZeltDocumentType['value']} */
  readonly type: string;
  /** ISO Date */
  readonly createdAt: string;
  /** ISO Date */
  readonly updatedAt: string;
  readonly uploadedBy: number;
  belongsTo?: readonly ZeltDocumentUser[];
  readonly attachments?: readonly AttachmentWithUrl[];
  readonly documentVerification?: ZeltDocumentVerification;
  readonly insurancePolicyId: number | null;
  readonly contractId?: string;
  readonly contract?: ContractObject;
  readonly isPinned?: boolean;
  readonly visibilitySettings?: VisibilitySettings;
  readonly expirySettings?: ExpirySettings;
  readonly dzProps?: {
    meta?: IMeta;
    file?: File;
    remove?: () => void;
    edit?: (document: ZeltDocument) => void;
    state?: DocumentUploadStatus;
  };
}

export interface DocumentExport {
  documentName: string;
  type: string;
  uploadDate: string;
  uploadedBy: string;
  status: string;
  belongsTo: string;
  signatories: number[];
}

export interface CreateZeltDocument {
  readonly belongsTo: readonly BelongsToExtended[];
  readonly fileUuid?: string;
  readonly name: string;
  readonly note?: string;
  readonly type: string;
  readonly isPinned?: boolean;
  readonly visibilitySettings?: VisibilitySettings;
  readonly expirySettings?: ExpirySettings;
}

export interface ZeltDocumentUser {
  readonly documentId: number;
  readonly User: Pick<User, 'userId' | 'firstName' | 'lastName' | 'displayName'>;
}

export interface BelongsToExtended {
  readonly label?: string;
  readonly value?: string | number;
  readonly User?: {
    readonly firstName?: string;
    readonly lastName?: string;
    readonly userId?: number;
    readonly displayName?: string;
  };
}

export interface OnboardingZeltDocument {
  readonly id?: number;
  readonly name: string;
  readonly type: string;
  readonly canAllEmployeesSee?: boolean;
  readonly belongsTo?: readonly BelongsToExtended[];
  readonly DocumentUsers?: readonly ZeltDocumentUser[];
}

export type DocumentPageResult = {
  documents: ZeltDocument[];
  pagination: Pagination;
  documentsExist: boolean;
};

export function getDocumentTypeLabel(
  document: ZeltDocument,
  types: readonly Pick<ZeltDocumentType, 'label' | 'value'>[]
) {
  return types.find(({ value }) => document.type === value)?.label ?? 'Other';
}
