import { useMemo } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { iconSize } from '@/v2/styles/menu.styles';

interface NewPaymentButtonProps {
  onExpenseClick: () => void;
  onInvoiceClick: () => void;
  size?: 'small' | 'medium' | 'large';
}

export const NewPaymentButton = ({ onExpenseClick, onInvoiceClick }: NewPaymentButtonProps) => {
  const { polyglot } = usePolyglot();

  const newDocumentButtonOptions = useMemo(() => {
    const optionsAvailable = [];
    if (onExpenseClick)
      optionsAvailable.push({
        icon: undefined,
        handler: () => onExpenseClick(),
        label: polyglot.t('PaymentListPage.newButtonExpenseOption'),
        disabled: false,
      });
    if (onInvoiceClick)
      optionsAvailable.push({
        icon: undefined,
        handler: () => onInvoiceClick(),
        label: polyglot.t('PaymentListPage.newButtonInvoiceOption'),
        disabled: false,
      });
    return optionsAvailable;
  }, [polyglot, onExpenseClick, onInvoiceClick]);

  return (
    <>
      <StyledMenuComponent
        options={newDocumentButtonOptions}
        actionButtonDetails={{
          type: 'button',
          colorVariant: 'primary',
          sizeVariant: 'small',
          title: polyglot.t('PaymentListPage.newButtonPayment'),
          icon: <ArrowDown {...iconSize} />,
          iconPosition: 'end',
        }}
      />
    </>
  );
};
