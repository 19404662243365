import { useCallback, useState } from 'react';

import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import { BillingDiscountAPI } from '@/api-client/billing-discount.api';
import { BillingSubscriptionAPI } from '@/api-client/billing-subscription.api';
import useMessage from '@/hooks/notification.hook';
import { CompanyPlanInfo, SubscriptionPlanInfo } from '@/models/company.model';
import { Discount, ProductDiscount } from '@/models/discount.model';
import { FreePlanList, FreeToProPlanMap } from '@/models/subscription.model';
import { SuperAdminBillingPlanEditFormItem } from '@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-form-item.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface props {
  discounts: Discount[];
  companyPlan: CompanyPlanInfo;
  onSave?: () => Promise<void>;
}

type Downgrade = { id: number; productPlanId: number };
type DowngradeList = Downgrade[];

type Upgrade = { discounts?: number[] | undefined; productPlanId: number };
type UpgradeList = Upgrade[];

type ApplyDiscountList = ProductDiscount[];
type RemoveDiscountList = ProductDiscount[];

type ChangesResult = {
  downgrades: DowngradeList;
  upgrades: UpgradeList;
  applyDiscount: ApplyDiscountList;
  removeDiscount: RemoveDiscountList;
};

export const SuperAdminBillingPlanEditForm = ({ companyPlan, discounts, onSave }: props) => {
  const [subscriptionChanges, setSubscriptionChanges] = useState<
    Record<number, { planId: number | undefined; discountId: number | undefined }>
  >();

  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const handlePlanChange = (subscriptionId: number, planId: number) => {
    setSubscriptionChanges({
      ...subscriptionChanges,
      [subscriptionId]: { discountId: subscriptionChanges?.[subscriptionId]?.discountId, planId },
    });
  };

  const handleDiscountChange = (subscriptionId: number, discountId: number) => {
    setSubscriptionChanges({
      ...subscriptionChanges,
      [subscriptionId]: { discountId, planId: subscriptionChanges?.[subscriptionId]?.planId },
    });
  };

  const detectUpgradeAndDowngrades = useCallback(() => {
    if (!subscriptionChanges) {
      return;
    }

    const downgrades: DowngradeList = [];
    const upgrades: UpgradeList = [];
    const applyDiscount: ApplyDiscountList = [];
    const removeDiscount: RemoveDiscountList = [];

    const subscriptions = companyPlan?.subscriptions || [];

    for (let i = 0; i < subscriptions.length; ++i) {
      // Detect changes in the subscription
      const subscription = subscriptions[i];
      const updates = subscriptionChanges[subscription.id];

      if (!updates) continue;

      const discountSelected =
        updates.discountId && updates.discountId > 0 && updates.discountId !== subscription.discounts[0]?.id;

      const discountRemoved =
        updates.discountId !== undefined && updates.discountId === 0 && subscription.discounts.length;

      if (updates.planId) {
        // Determine upgrade or downgrade
        if (updates.planId > subscription.productPlanId) {
          let discountsToApply = [];

          if (discountSelected) {
            discountsToApply.push(updates.discountId);
          }

          if (discountRemoved) {
            removeDiscount.push({
              companyId: Number(companyPlan?.companyId as number),
              productPlanId: subscription.productPlanId,
              discountId: subscription.discounts[0]?.id,
            } as ProductDiscount);
          }

          upgrades.push({
            companyId: Number(companyPlan?.companyId as number),
            productPlanId: updates.planId,
            ...(discountsToApply.length > 0 ? { discounts: discountsToApply } : undefined),
          } as Upgrade);
        }

        if (updates.planId < subscription.productPlanId) {
          downgrades.push({
            id: subscription.id,
            // Cancel pro subscription
            productPlanId: subscription.productPlanId,
          });
        }

        // Didn't change subscription, just applied new discount
        if (
          updates.planId === subscription.productPlanId &&
          discountSelected &&
          !FreePlanList.includes(updates.planId)
        ) {
          applyDiscount.push({
            companyId: companyPlan?.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId,
          } as ProductDiscount);
        }

        // Didn't change subscription, just applied new discount
        // while on Free plan
        if (
          updates.planId === subscription.productPlanId &&
          discountSelected &&
          FreePlanList.includes(updates.planId)
        ) {
          // add discount for Free plan
          applyDiscount.push({
            companyId: companyPlan?.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId,
          } as ProductDiscount);
          // also preemptively add discount for related Pro plan
          applyDiscount.push({
            companyId: companyPlan?.companyId,
            productPlanId: FreeToProPlanMap[subscription.productPlanId],
            discountId: updates.discountId,
          } as ProductDiscount);
        }

        // Didn't change subscription, just removed discount
        if (
          updates.planId === subscription.productPlanId &&
          discountRemoved &&
          !FreePlanList.includes(updates.planId)
        ) {
          removeDiscount.push({
            companyId: Number(companyPlan?.companyId as number),
            productPlanId: subscription.productPlanId,
            discountId: subscription.discounts[0]?.id,
          } as ProductDiscount);
        }
      } else {
        // Didn't change subscription, just applied new discount
        if (discountSelected && !FreePlanList.includes(subscription.productPlanId)) {
          applyDiscount.push({
            companyId: companyPlan?.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId,
          } as ProductDiscount);
        }

        // Didn't change subscription, just removed discount
        if (discountRemoved && !FreePlanList.includes(subscription.productPlanId)) {
          removeDiscount.push({
            companyId: Number(companyPlan?.companyId as number),
            productPlanId: subscription.productPlanId,
            discountId: subscription.discounts[0]?.id,
          } as ProductDiscount);
        }

        // Didn't change subscription, just removed discount while on Free plan
        if (discountRemoved && FreePlanList.includes(subscription.productPlanId)) {
          // remove discount for Free plan
          removeDiscount.push({
            companyId: Number(companyPlan?.companyId as number),
            productPlanId: subscription.productPlanId,
            discountId: subscription.discounts[0]?.id,
          } as ProductDiscount);
          // also preemptively remove discount for Pro plan
          removeDiscount.push({
            companyId: Number(companyPlan?.companyId as number),
            productPlanId: FreeToProPlanMap[subscription.productPlanId],
            discountId: subscription.discounts[0]?.id,
          } as ProductDiscount);
        }

        // Didn't change subscription, just applied new discount while on Free plan
        if (discountSelected && FreePlanList.includes(subscription.productPlanId)) {
          // add discount for Free plan
          applyDiscount.push({
            companyId: companyPlan?.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId,
          } as ProductDiscount);
          // also preemptively add discount for related Pro plan
          applyDiscount.push({
            companyId: companyPlan?.companyId,
            productPlanId: FreeToProPlanMap[subscription.productPlanId],
            discountId: updates.discountId,
          } as ProductDiscount);
        }
      }
    }

    return { downgrades, upgrades, applyDiscount, removeDiscount };
  }, [companyPlan?.companyId, companyPlan?.subscriptions, subscriptionChanges]);

  const handleSave = useCallback(async () => {
    try {
      if (!subscriptionChanges) {
        showMessage('No changes made', 'info');
        return;
      }

      const { downgrades, upgrades, applyDiscount, removeDiscount } = detectUpgradeAndDowngrades() as ChangesResult;

      const downgradePromises = downgrades.map((downgradePayload) =>
        BillingSubscriptionAPI.downgradeSubscriptionAsSuperadmin(companyPlan.companyId, downgradePayload)
      );

      const upgradePromise = upgrades.length
        ? BillingSubscriptionAPI.upgradeSubscriptionAsSuperadmin(companyPlan.companyId, upgrades)
        : [];

      const applyDiscountPromises = applyDiscount.map((applyPayload) => BillingDiscountAPI.applyDiscount(applyPayload));
      const removeDiscountPromises = removeDiscount.map((removeDiscount) =>
        BillingDiscountAPI.removeDiscount(removeDiscount)
      );

      setIsSavingChanges(true);

      if (
        !downgradePromises.length &&
        !upgradePromise &&
        !applyDiscountPromises.length &&
        !removeDiscountPromises.length
      ) {
        return;
      }

      await Promise.all([...downgradePromises, upgradePromise, ...applyDiscountPromises, ...removeDiscountPromises]);

      if (onSave) {
        await onSave();
      }

      setIsSavingChanges(false);

      showMessage('Customer plan updated successfully', 'success');
    } catch (e) {
      showMessage('Failed to update customer plan', 'error');
      console.error(':::: error ::::', e);
      setIsSavingChanges(false);
    }
  }, [subscriptionChanges, detectUpgradeAndDowngrades, companyPlan.companyId, onSave, showMessage]);

  return (
    <>
      {companyPlan && (
        <>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Edit Plan</Typography>
          <Box sx={{ marginTop: 5 }}>
            <TextField
              key={5}
              label="Company Name"
              variant="outlined"
              fullWidth
              size="medium"
              name="companyName"
              value={companyPlan.name}
              disabled={true}
            />

            <TextField
              key={5}
              sx={{ marginTop: 5 }}
              label="Company ID"
              variant="outlined"
              fullWidth
              size="medium"
              name="companyId"
              value={companyPlan.companyId}
              disabled={true}
            />

            {companyPlan.subscriptions.map((subscription: SubscriptionPlanInfo, index) => (
              <SuperAdminBillingPlanEditFormItem
                section={subscription.planName?.split(' ')[0]}
                planDefaultValue={subscription.planName?.split(' ')[1]}
                planLabel={`${subscription.planName?.split(' ')[0]} Plan`}
                planInputKey={subscription.id.toString()}
                handlePlanSelect={handlePlanChange}
                discountInputKey={`${subscription.id}-${index}`}
                discountDefaultValue={subscription.discounts[subscription?.discounts?.length - 1 || 0]?.id}
                handleDiscountSelect={handleDiscountChange}
                discountLabel={`${subscription.planName?.split(' ')[0]} Plan Discount`}
                discountList={discounts}
              />
            ))}

            <Box width={'100%'} display={'flex'} justifyContent={'center'} mt={2}>
              <Button
                disabled={!subscriptionChanges || isSavingChanges}
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSave}
              >
                Save {isSavingChanges && <CircularProgress size={'medium'} />}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
