import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { CustomCountryEnum } from '@v2/infrastructure/country/country.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { identity, pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import Polyglot from 'node-polyglot';

import { ContractsAPI } from '@/api-client/contracts.api';
import { UserContract } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { UserContractSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { DateLabelComponent, getDateString } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { useProfileFormUpdate } from '@/v2/feature/user/context/user-profile.context';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import {
  CardStructure,
  FieldStructure,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  definitionValueSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { ContractForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-contract-form.component';
import { buildColumnsForCustomFields } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import {
  translategetContractTypeOptions,
  translategetEmploymentTypeOptions,
} from '@/v2/infrastructure/i18n/translate.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

const skeletonHeight = (polyglot: Polyglot) => calculateSkeletonHeight(UserContractSchema(polyglot));

const SCOPE = 'user.contract:all' as const;

const iconSize = { width: 14, height: 14 } as const;

export const UserContractCard = ({
  userId,
  // missingFields = [],
  handleSubmit = () => {},
}: UserProfileCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [rowModalMode, setRowModalMode] = useState<'add' | 'edit'>('add');

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [data, setData] = useState<EntriesAndEffectiveRecord<UserContract>>();

  const setFormCurrentlyEditing = useProfileFormUpdate();

  const [modalInitialValues, setModalInitialValues] = useState<UserContract | null>(null);

  const loadInitialData = useCallback(async (): Promise<void> => {
    if (!userId) return;
    setLoading(true);
    try {
      const userContracts = await ContractsAPI.findByUserId(userId);
      setData(userContracts);
      if (!userContracts?.entries?.length) setShowDetails(false);
    } catch (error) {
      showMessage(polyglot.t('UserContractCard.errorMessages.load'), 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, userId, polyglot]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const editContractRow = useCallback((rowData: UserContract) => {
    setModalInitialValues(rowData);
    setRowModalMode('edit');
    setIsModalOpen(true);
  }, []);

  const addContractRow = useCallback(() => {
    setModalInitialValues(null);
    setRowModalMode('add');
    setIsModalOpen(true);
  }, []);

  const sortByEffectiveDate = useCallback(
    (entries: readonly UserContract[], sortOrder: 'oldest-first' | 'oldest-last' = 'oldest-last') => {
      const sorted = [...entries].sort(
        (a, b) =>
          b.effectiveDate.localeCompare(a.effectiveDate) || b.createdAt.localeCompare(a.createdAt) || b.id - a.id
      );
      if (sortOrder === 'oldest-first') sorted.reverse();
      return sorted;
    },
    []
  );

  const nextContractStart = useMemo(() => {
    if (!data) return undefined;
    const { effectiveRecord, entries } = data;
    const sortedEntries = sortByEffectiveDate(entries);
    const effectiveRecordIdx = sortedEntries.findIndex(({ id }) => id === effectiveRecord?.id);
    return sortedEntries[effectiveRecordIdx - 1]?.effectiveDate;
  }, [data, sortByEffectiveDate]);

  const getHolidayCalendarName = useCallback((contract: UserContract): string | null => {
    if (!contract.publicHolidays) return null;
    const country = getCountryNameForCode(contract.publicHolidays);
    if (!country) return null;

    if (country !== CustomCountryEnum.code) return country;

    return contract.holidayCalendar?.name ? contract.holidayCalendar.name : null;
  }, []);

  const columnData = useMemo<ColumnDef<UserContract, UserContract>[]>(
    () => [
      {
        header: () => polyglot.t('UserContractCard.employmentContract'),
        accessorFn: identity,
        id: 'contract',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.contract ? original.contract : ''}</div>,
      },
      {
        header: () => polyglot.t('UserContractCard.workerType'),
        accessorFn: identity,
        id: 'workerType',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>{translategetEmploymentTypeOptions(original.type ? original.type : '', polyglot)}</div>
        ),
      },
      {
        header: () => polyglot.t('UserContractCard.publicHolidays'),
        accessorFn: identity,
        id: 'publicHolidays',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => {
          const holidayCalendar = getHolidayCalendarName(original);
          return holidayCalendar ? <div>{holidayCalendar}</div> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('UserContractCard.entity'),
        accessorFn: identity,
        id: 'entity',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.entity?.legalName ?? ''}</div>,
      },
      {
        header: () => polyglot.t('UserContractCard.attendanceScheduleId'),
        accessorFn: identity,
        id: 'attendanceScheduleId',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.attendanceSchedule?.name ?? ''}</div>,
      },
      {
        header: () => polyglot.t('UserContractCard.changeReason'),
        accessorFn: identity,
        id: 'changeReason',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.changeReason ? original.changeReason : ''}</div>,
      },
      {
        header: () => polyglot.t('UserContractCard.probationPeriod'),
        accessorFn: identity,
        id: 'probationPeriod',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>
            {original.probationPeriodLength ?? ''} {original.probationPeriodUnit ?? ''}
          </div>
        ),
      },
      {
        header: () => polyglot.t('UserContractCard.noticePeriod'),
        accessorFn: identity,
        id: 'noticePeriod',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>
            {original.noticePeriodLength ?? ''} {original.noticePeriodUnit ?? ''}
          </div>
        ),
      },
      ...buildColumnsForCustomFields(data?.entries, { size: 80 }),
      {
        header: () => polyglot.t('UserContractCard.contractEndDate'),
        accessorFn: identity,
        id: 'contractEndDate',
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => (
          <div>{original.contractEndDate ? <DateLabelComponent date={original.contractEndDate} /> : ''}</div>
        ),
      },
      {
        header: () => polyglot.t('UserContractCard.effectiveDate'),
        accessorFn: identity,
        id: 'effectiveDate',
        enableSorting: false,
        size: 100,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },
      {
        header: () => '',
        accessorFn: identity,
        id: 'actions',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton
                title="edit"
                onClick={() => {
                  setRowModalMode('edit');
                  editContractRow(original);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </ScopesControl>
          </div>
        ),
      },
    ],
    [data?.entries, polyglot, getHolidayCalendarName, scopesContext, editContractRow]
  );

  return pipe(
    data ? O.some(data) : O.none,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight(polyglot)}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      ({ entries, effectiveRecord }) => (
        <CardStructure
          cardTitle={polyglot.t('UserContractCard.contract')}
          cardScope={[SCOPE]}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          userId={userId}
          addAction={addContractRow}
          editAction={
            effectiveRecord &&
            (() => {
              setRowModalMode('edit');
              editContractRow(effectiveRecord);
            })
          }
          showMissingInfo={!effectiveRecord}
          cardFieldCustomUpdates={effectiveRecord?.customUpdates}
          cardFieldStubs={[
            'contract.effectiveDate',
            'contract.contract',
            'contract.attendanceSchedule',
            'contract.publicHolidays',
            'contract.entity',
            'contract.noticePeriod',
            'contract.probationPeriod',
            'contract.contractEndDate',
          ]}
          cardFieldDetails={
            effectiveRecord && (
              <Box component="dl" sx={definitionListSx}>
                <FieldStructure
                  fieldTitle={polyglot.t('UserContractCard.workerType')}
                  fieldValue={translategetEmploymentTypeOptions(effectiveRecord.type, polyglot)}
                  fieldStub="contract.effectiveDate"
                />
                <FieldStructure
                  fieldTitle={polyglot.t('UserContractCard.employmentContract')}
                  fieldValue={translategetContractTypeOptions(effectiveRecord.contract ?? '', polyglot)}
                  fieldStub="contract.contract"
                />
                <FieldStructure
                  fieldTitle={polyglot.t('UserContractCard.attendanceScheduleId')}
                  fieldValue={effectiveRecord.attendanceSchedule?.name}
                  fieldStub="contract.attendanceSchedule"
                />
                <FieldStructure
                  fieldTitle={polyglot.t('UserContractCard.publicHolidays')}
                  fieldValue={getHolidayCalendarName(effectiveRecord)}
                  fieldStub="contract.publicHolidays"
                />
                <FieldStructure
                  fieldTitle={polyglot.t('UserContractCard.entity')}
                  fieldValue={effectiveRecord.entity?.legalName}
                  fieldStub="contract.entity"
                />
                {typeof effectiveRecord.probationPeriodLength === 'number' && (
                  <FieldStructure
                    fieldTitle={polyglot.t('UserContractCard.probationPeriod')}
                    fieldValue={`${effectiveRecord.probationPeriodLength} ${effectiveRecord.probationPeriodUnit}`}
                    fieldStub="contract.probationPeriod"
                  />
                )}
                {typeof effectiveRecord.noticePeriodLength === 'number' && (
                  <FieldStructure
                    fieldTitle={polyglot.t('UserContractCard.noticePeriod')}
                    fieldValue={`${effectiveRecord.noticePeriodLength} ${effectiveRecord.noticePeriodUnit}`}
                    fieldStub="contract.noticePeriod"
                  />
                )}
                {sortCustomFields(effectiveRecord.customUpdates).map(
                  (f) =>
                    !f.field.isHidden && (
                      <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                    )
                )}
                {!!effectiveRecord.contractEndDate && (
                  <FieldStructure
                    fieldTitle={polyglot.t('UserContractCard.contractEndDate')}
                    fieldValue={
                      <DateLabelComponent date={effectiveRecord.contractEndDate} titleSx={definitionValueSx} />
                    }
                    fieldStub="contract.contractEndDate"
                  />
                )}
                {nextContractStart && (
                  <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
                    {polyglot.t('UserContractCard.newContractMessage')} {getDateString(nextContractStart)}
                  </Typography>
                )}
              </Box>
            )
          }
          drawerDetails={
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
                <ContractForm
                  initialValues={modalInitialValues}
                  isEffectiveRecord={modalInitialValues?.id === effectiveRecord?.id}
                  userId={userId}
                  loadInitialData={loadInitialData}
                  rowModalMode={rowModalMode}
                  handleSubmit={handleSubmit}
                  setFormCurrentlyEditing={setFormCurrentlyEditing}
                  setIsModalOpen={setIsModalOpen}
                  setLoading={setLoading}
                  loading={loading}
                  onClose={() => setIsModalOpen(false)}
                />
              </DrawerModal>
            </ScopesControl>
          }
          addButtonTitle={polyglot.t('UserContractCard.newContract')}
          tableRowData={sortByEffectiveDate(entries, 'oldest-last')}
          tableColumn={columnData}
        />
      )
    )
  );
};
