import { useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { PermissionGroupAPI } from '../permission-group.api';
import { PermissionGroupDto } from '../permission-group.dto';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface PermissionGroupListingTableProps {
  readonly permissionGroups: PermissionGroupDto[];
  readonly loading: boolean;
  readonly openDrawerInEditMode: (permissionGroup: PermissionGroupDto) => void;
  refreshPermissionGroups: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PermissionGroupListingTable = ({
  permissionGroups,
  loading,
  openDrawerInEditMode,
  refreshPermissionGroups,
  setLoading,
}: PermissionGroupListingTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [permissionGroupToDelete, setPermissionGroupToDelete] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMessage] = useMessage();

  const deletePermissionGroup = async () => {
    try {
      setLoading(true);
      if (permissionGroupToDelete) await PermissionGroupAPI.deletePermissionGroup(permissionGroupToDelete);
      else {
        throw new Error(polyglot.t('PermissionGroupListingTable.errorMessages.noGroup'));
      }
      refreshPermissionGroups();
      showMessage(polyglot.t('PermissionGroupListingTable.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(
        `${polyglot.t('PermissionGroupListingTable.errorMessages.delete')}: ${nestErrorMessage(error)}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const tableColumns = useMemo<ColumnDef<PermissionGroupDto, PermissionGroupDto>[]>(() => {
    const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, departmentId: number) => {
      if (event) setAnchorEl(event.currentTarget);
      setPermissionGroupToDelete(departmentId);
      setIsConfirmModalOpen(true);
    };
    return [
      {
        header: () => polyglot.t('PermissionGroupListingTable.name'),
        accessorFn: (row) => row,
        id: 'displayName',
        maxSize: 200,
        minSize: 150,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && <div>{original.name}</div>;
        },
      },
      {
        header: () => polyglot.t('PermissionGroupListingTable.description'),
        accessorFn: (row) => row,
        id: 'description',
        maxSize: 350,
        minSize: 350,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && <div>{original.description}</div>;
        },
      },
      {
        header: () => polyglot.t('PermissionGroupListingTable.members'),
        accessorFn: (row) => row,
        id: 'employees',
        maxSize: 250,
        minSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>
            {original.members ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: 20 }}>{original.members.length}</Box>
              </Box>
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
      },
      {
        header: () => '',
        id: 'action',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: spacing.g5 }}>
            {!original?.isDefault && (
              <IconButton
                title={polyglot.t('General.delete')}
                onClick={(event) => {
                  confirmDelete(event, original.id);
                  event.stopPropagation();
                }}
                sx={tableIconButtonSx}
              >
                <Trash {...iconSize} />
              </IconButton>
            )}
          </Box>
        ),
      },
    ];
  }, [polyglot]);

  const handleRowClick = useCallback(
    (row: Row<PermissionGroupDto>) => {
      openDrawerInEditMode(row.original);
    },
    [openDrawerInEditMode]
  );

  return (
    <>
      <BasicTable<PermissionGroupDto>
        rowData={permissionGroups}
        columnData={tableColumns}
        loading={loading}
        rowClick={handleRowClick}
      />
      <NotificationModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (permissionGroupToDelete) {
            setIsConfirmModalOpen(false);
            await deletePermissionGroup();
          } else showMessage(polyglot.t('PermissionGroupListingTable.errorMessages.noSelected'), 'error');
        }}
        message={polyglot.t('PermissionGroupListingTable.confirmDelete')}
        callToAction="Yes"
      />
    </>
  );
};
