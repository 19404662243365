import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { AppStatusTabFilter } from '@v2/feature/app-integration/app-router.util';

import cover from '@/images/app-empty.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { getIconForAppStatus } from '@/v2/feature/app-integration/app-integration-detailed-personal.router';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  GroupMembership,
  InstalledAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APP_ACTION_DRAWER_MODES,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationStub,
} from '@/v2/feature/app-integration/app-integration.interface';
import {
  externalUserHasDelayedAppAction,
  getScheduledActionDateString,
  matchingDelayedActionForExternalUser,
} from '@/v2/feature/app-integration/app-integration.util';
import { getGroupListForUser } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { AppAccessDetailDrawer } from '@/v2/feature/app-integration/features/app-details/components/app-access-detail-drawer.component';
import { AppDetailConfigurationModal } from '@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component';
import { AppDetailsActionButton } from '@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component';
import { AppDetailsTable } from '@/v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { AppActionsDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section';
import { AppGroupManagementDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { primarySmallBtn, secondarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface AppExternalNotEnrolledProps {
  readonly teamAccessUserList: readonly AppIntegrationUserDto[];
  readonly externalUserList: readonly AppIntegrationUserDto[];
  readonly groupMemberships?: readonly GroupMembership[];
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
  readonly loading: boolean;
  readonly error: boolean;
  readonly onAppChange: (_: string) => void;
  readonly hasAppsAllOrAppOwner: boolean;
}

export const AppExternalNotEnrolledPage = ({
  teamAccessUserList,
  externalUserList,
  groupMemberships,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  hasAppsAllOrAppOwner,
}: AppExternalNotEnrolledProps) => {
  const { polyglot } = usePolyglot();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('all');
  const [filteredExternalList, setFilteredExternalList] = useState<readonly AppIntegrationUserDto[]>(externalUserList);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [selectedActiveUserLogin, setSelectedActiveUserLogin] = useState<string | undefined>(undefined);
  const [preselectedUser, setPreselectedUser] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [actionsDrawerMode, setActionsDrawerMode] = useState<APP_ACTION_DRAWER_MODES | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState<APP_GROUP_MANAGEMENT_DRAWER_MODES | undefined>(undefined);

  const [openAccessDetailModal, setOpenAccessDetailModal] = useState<boolean>(false);
  const [userForAccessDetail, setUserForAccessDetail] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [scheduledActionDate, setScheduledActionDate] = useState<string | undefined>(undefined);

  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false,
    }
  );

  const statusColumn = useCallback(
    (original: AppIntegrationUserDto) => (
      <Box sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            ...themeFonts.caption,
            display: 'flex',
            alignItems: 'center',
            gap: spacing.m5,
          }}
        >
          {original.userStatus &&
          !externalUserHasDelayedAppAction(
            delayedActions,
            original.primaryEmail,
            original.id ? String(original.id) : '0'
          )
            ? getIconForAppStatus(polyglot, original.userStatus)
            : ''}

          {original.userStatus &&
          delayedActions &&
          externalUserHasDelayedAppAction(
            delayedActions,
            original.primaryEmail,
            original.id ? String(original.id) : '0'
          )
            ? getIconForAppStatus(polyglot, 'Scheduled')
            : ''}
        </Typography>
      </Box>
    ),
    [delayedActions, polyglot]
  );

  useEffect(() => {
    setFilteredExternalList(
      filterString === 'all'
        ? externalUserList
        : externalUserList.filter(
            (eachUser) =>
              eachUser.userStatus === filterString ||
              eachUser?.emails?.some((eachEmail) => eachEmail.status === filterString)
          )
    );
  }, [externalUserList, filterString]);

  useEffect(() => {
    setFilteredExternalList(
      searchInput.length === 0
        ? externalUserList
        : externalUserList.filter(
            (eachUser) =>
              eachUser.displayName && eachUser.displayName?.toLowerCase().includes(searchInput.toLowerCase())
          )
    );
  }, [externalUserList, searchInput]);

  const usersNotEnrolledColumn = useMemo<ColumnDef<AppIntegrationUserDto, AppIntegrationUserDto>[]>(
    () => [
      {
        header: () => polyglot.t('AppTeamAccessPage.columns.name'),
        id: 'fullName',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Typography
            sx={{
              ...themeFonts.caption,
              display: 'flex',
              alignItems: 'center',
              gap: spacing.m5,
              color: themeColors.DarkGrey,
            }}
          >
            {(original.name && original.name.fullName) ?? 'N/A'}
          </Typography>
        ),
      },
      {
        header: () => polyglot.t('AppAccessDetailsDrawer.login'),
        id: 'primaryEmail',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        hide: appStub === 'github',
        cell: ({ row: { original } }) => (
          <Box>
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m15,
              }}
            >
              {original.primaryEmail || 'N/A'}
            </Typography>
          </Box>
        ),
      },
      ...(app?.allowsGroupManagement
        ? [
            {
              header: () => 'Groups',
              id: 'groupMemberships',
              size: 160,
              accessorFn: (row: any) => row,
              enableSorting: false,
              cell: ({ row: { original } }: { row: { original: AppIntegrationUserDto } }) => (
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                  }}
                >
                  {groupMemberships && original.primaryEmail
                    ? getGroupListForUser(original.primaryEmail, groupMemberships)
                    : ''}
                </Typography>
              ),
            },
          ]
        : []),
      {
        header: () => polyglot.t('AppTeamAccessPage.columns.status'),
        id: 'userStatus',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => statusColumn(original),
      },
      {
        header: () => '',
        id: 'action',
        maxSize: 80,
        minSize: 80,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <AppDetailsActionButton
            setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
            setSelectedActiveUserLogin={setSelectedActiveUserLogin}
            setGroupManagementDrawerOpen={setIsDrawerOpen}
            setGroupManagementDrawerMode={setDrawerMode}
            refresh={onAppChange}
            appStub={appStub}
            user={original}
            delayedActions={delayedActions ?? { delayed: [] }}
            refreshDelayedActions={refreshDelayedActions}
            table="external"
            hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
            setPreselectedUser={setPreselectedUser}
            setActionsDrawerMode={setActionsDrawerMode}
            error={error}
          />
        ),
      },
    ],
    [
      appStub,
      app?.allowsGroupManagement,
      polyglot,
      groupMemberships,
      statusColumn,
      onAppChange,
      delayedActions,
      refreshDelayedActions,
      hasAppsAllOrAppOwner,
      error,
    ]
  );

  const activeTeamMembers = (teamList: readonly AppIntegrationUserDto[]): AppIntegrationUserDto[] => {
    return teamList?.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) => ['Active', 'Invited'].includes(eachEmail.status)) ||
        eachUser.userStatus === 'Active'
    );
  };

  const teamMembersWithNoAccess = (teamList: readonly AppIntegrationUserDto[]): AppIntegrationUserDto[] => {
    return teamList.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) => ['No acess'].includes(eachEmail.status)) ||
        eachUser.userStatus === 'No access'
    );
  };

  const addUsersToGroup = () => {
    setIsDrawerOpen(true);
    setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.add);
  };

  const handleRowClicked = useCallback(
    (row: Row<AppIntegrationUserDto>) => {
      const { original } = row;
      setOpenAccessDetailModal(true);
      setUserForAccessDetail(original);

      const matchingAction = matchingDelayedActionForExternalUser(
        delayedActions,
        original.primaryEmail,
        original.id ? String(original.id) : '0'
      );
      setScheduledActionDate(matchingAction ? getScheduledActionDateString(matchingAction) : undefined);
    },
    [delayedActions]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<AppNameHeader title={polyglot.t('AppExternalNotEnrolledPage.title')} app={app} />}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => addUsersToGroup()}>
            New account
          </ButtonComponent>
        }
        showAction={hasAppsAllOrAppOwner && app?.allowsGroupManagement}
      />
      <ContentWrapper loading={loading ?? false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'left',
          }}
        >
          {!error && (
            <>
              <TabFilterButtons
                filters={AppStatusTabFilter(polyglot)}
                setFilterValue={setFilterString}
                filterValue={filterString}
                hasSearch
                onFilterChange={({ filterValue, searchInput }) => {
                  setFilterString(filterValue);
                  setSearchInput(searchInput);
                }}
              />
              {hasAppsAllOrAppOwner && (
                <Button
                  sx={secondarySmallBtn}
                  startIcon={<Reload {...iconSize} />}
                  onClick={() => onAppChange(appStub)}
                >
                  Refresh
                </Button>
              )}
            </>
          )}
        </Box>
        {!error && (
          <Box sx={{ ...spacing.mt20 }}>
            <AppDetailsTable
              column={usersNotEnrolledColumn}
              row={filteredExternalList ?? []}
              loading={loading ?? false}
              handleRowClicked={handleRowClicked}
            />
            {error && !app?.authorised && (
              <EmptyStateComponent
                header="Authorize this app"
                subheader="Zelt allows you to manage your whole toolstack from one place."
                detail="To start using your app connect your account in Settings first."
                cover={cover}
              />
            )}
          </Box>
        )}
        {appStub && appStub === 'github' && error && app?.authorised && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              mt: spacing.mt40,
            }}
          >
            Please add your organisation name to finish integration setup
            <Button sx={{ ...primarySmallBtn, ...spacing.mt40 }} onClick={() => setShowModal(true)}>
              Add organisation
            </Button>
          </Box>
        )}
        <AppDetailConfigurationModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          app={app}
          appStub={appStub}
          refresh={() => onAppChange(appStub)}
        />
        <AppActionsDrawer
          appStub={appStub}
          usersWithoutAccess={teamAccessUserList}
          usersWithAccess={externalUserList}
          isOpen={isAppActionsDrawerOpen}
          onClose={() => setIsAppActionsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          refreshDelayedActions={refreshDelayedActions}
          setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
          preselectedUser={preselectedUser}
          mode={actionsDrawerMode}
          selectedActiveUserLogin={selectedActiveUserLogin}
          setSelectedActiveUserLogin={setSelectedActiveUserLogin}
          directoryMode={'external'}
        />
        <AppGroupManagementDrawer
          appStub={appStub}
          selectedGroupMembership={undefined}
          externalUserList={externalUserList}
          usersWithoutAccess={teamMembersWithNoAccess(teamAccessUserList)}
          usersWithAccess={activeTeamMembers(teamAccessUserList)}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          refreshApp={() => onAppChange(appStub)}
          setIsDrawerOpen={setIsDrawerOpen}
          existingUser={preselectedUser}
          mode={drawerMode}
          groupMembers={{}}
          groupList={groupMemberships}
        />
        {userForAccessDetail && app?.name && (
          <AppAccessDetailDrawer
            isOpen={openAccessDetailModal}
            setIsOpen={setOpenAccessDetailModal}
            appUserDetail={userForAccessDetail}
            appName={app?.name}
            appStatusValue={statusColumn(userForAccessDetail)}
            scheduledActionDate={scheduledActionDate}
            appActionButtons={
              <AppDetailsActionButton
                setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
                setGroupManagementDrawerOpen={setIsDrawerOpen}
                setGroupManagementDrawerMode={setDrawerMode}
                refresh={onAppChange}
                appStub={appStub}
                user={userForAccessDetail}
                delayedActions={delayedActions ?? { delayed: [] }}
                refreshDelayedActions={refreshDelayedActions}
                table="team"
                setPreselectedUser={setPreselectedUser}
                setActionsDrawerMode={setActionsDrawerMode}
                hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
                error={error}
                appAccessDetailsDrawerMode={true}
              />
            }
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
