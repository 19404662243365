import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PersonalAbsenceContent } from '@v2/feature/absence/me/policies/components/personal-absence-content.component';
import { LabelYearSelectChip } from '@v2/feature/absence/me/policies/components/year-select-chip.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export interface UserAbsencePageProps {
  readonly userId: number;
  readonly absencePolicies: readonly AbsencePolicyDto[] | null;
}

export const PersonalAbsencePage = ({ absencePolicies, userId }: UserAbsencePageProps): React.JSX.Element => {
  const [selectedPolicy, setSelectedPolicy] = useState<AbsencePolicyDto | null>(null);

  const { polyglot } = usePolyglot();
  const { getScopesContext } = useScopes();

  const [globalState] = useContext(GlobalContext);
  const context = getScopesContext({ userId });
  const hasAbsenceScope = checkScopes(globalState.user, ['absence'], context);
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState(false);
  const [year, setYear] = useState<'current' | 'last' | 'next'>('current');

  const { trackPage } = useJune();

  useEffect(() => {
    if (absencePolicies) setSelectedPolicy(absencePolicies[0] ?? null);
  }, [absencePolicies]);

  useEffect(() => {
    trackPage('Time personal page');
  }, [trackPage]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Typography variant="title2">{polyglot.t('AbsenceModule.requests')}</Typography>
            <LabelYearSelectChip year={year} setYear={setYear} />
          </Box>
        }
        showAction={hasAbsenceScope}
        actions={
          <ScopesControl scopes={['absence']} context={context}>
            <ButtonComponent onClick={() => setIsAbsenceDrawerOpen(true)} sizeVariant="small" colorVariant="primary">
              {polyglot.t('PersonalAbsencePage.newRequest')}
            </ButtonComponent>
          </ScopesControl>
        }
      />
      <PersonalAbsenceContent
        userId={userId}
        year={year}
        absencePolicies={absencePolicies}
        setIsAbsenceDrawerOpen={setIsAbsenceDrawerOpen}
        isAbsenceDrawerOpen={isAbsenceDrawerOpen}
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
      />
    </RootStyle>
  );
};
