import { useMemo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TimePickerComponent } from '@v2/components/forms/time-picker.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { isValidTimeString } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableWhiteIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { helperTextSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';

import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export interface RowEntry {
  typeId: number | '';
  startHour: string;
  endHour: string;
  startHourTimestamp: Date | null;
  endHourTimestamp: Date | null;
  priority: number;
}

interface SelectWorkOptionsProps {
  readonly logDate: string;
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly entries: RowEntry[];
  readonly setEntries: (value: RowEntry[]) => void;
  readonly showErrorMessage: boolean;
}

export const SelectWorkOptions = ({
  logDate,
  attendanceSchedule,
  entries,
  setEntries,
  showErrorMessage,
}: SelectWorkOptionsProps) => {
  const { polyglot } = usePolyglot();
  const TypeOptions = useMemo(
    () =>
      attendanceSchedule.attendanceTypesAllowed.map((type) => ({
        value: type.id,
        label: type.name,
      })),
    [attendanceSchedule?.attendanceTypesAllowed]
  );

  return (
    <Box>
      <Box key="labels" sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g15, mb: spacing.m10 }}>
        <Box sx={{ width: '160px' }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('AttendanceDomain.type')}
          </Typography>
        </Box>
        <Box sx={{ width: '80px' }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('AttendanceDomain.from')}
          </Typography>
        </Box>
        <Box sx={{ width: '80px' }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('AttendanceDomain.to')}
          </Typography>
        </Box>
        <Box sx={{ width: '30px' }}></Box>
      </Box>
      {entries && entries.length > 0 && (
        <Box sx={{ mb: spacing.m20, display: 'flex', flexDirection: 'column', gap: spacing.m30 }}>
          {entries.map((r, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g15, alignItems: 'center' }}
            >
              <Box sx={{ width: '160px' }}>
                <SelectComponent
                  name="type"
                  options={TypeOptions}
                  value={r.typeId}
                  onChange={(e) => {
                    const updatedEntries = [...entries];
                    updatedEntries[index].typeId = e.target.value;
                    setEntries(updatedEntries);
                  }}
                />
              </Box>

              <Box sx={{ width: '80px' }}>
                <TimePickerComponent
                  textFieldKey="startHour"
                  value={r.startHour}
                  onChange={(event) => {
                    const time = event.target.value;
                    if (isValidTimeString(time)) {
                      const date = new LocalDate(`${logDate}T${time}:00`);
                      const updatedEntries = [...entries];
                      updatedEntries[index].startHour = date.toFullString();
                      updatedEntries[index].startHourTimestamp = date.getDate();
                      setEntries(updatedEntries);
                    }
                  }}
                  fullWidth
                />
              </Box>

              <Box sx={{ width: '80px' }}>
                <TimePickerComponent
                  textFieldKey="endHour"
                  value={r.endHour}
                  onChange={(event) => {
                    const time = event.target.value;
                    if (isValidTimeString(time)) {
                      const date = new LocalDate(`${logDate}T${time}:00`);
                      const updatedEntries = [...entries];
                      updatedEntries[index].endHour = date.toFullString();
                      updatedEntries[index].endHourTimestamp = date.getDate();
                      setEntries(updatedEntries);
                    }
                  }}
                  fullWidth
                />
              </Box>

              <Box sx={{ width: '30px' }}>
                <IconButton
                  sx={tableWhiteIconButtonSx}
                  onClick={() => {
                    setEntries(entries.filter((e, i) => i !== index));
                  }}
                >
                  <TrashIcon {...iconSize} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {showErrorMessage && (
        <Typography sx={{ ...helperTextSx, color: themeColors.darkRed, mb: spacing.m10 }}>
          {polyglot.t('AttendanceDomain.errors.makeSureAllAreValid')}
        </Typography>
      )}
      <Box>
        <ButtonComponent
          colorVariant="secondary"
          sizeVariant="filter"
          onClick={() => {
            setEntries([
              ...entries.map((e, index) => ({ ...e, priority: index })),
              {
                typeId: '',
                startHour: '',
                endHour: '',
                startHourTimestamp: null,
                endHourTimestamp: null,
                priority: entries.length,
              },
            ]);
          }}
        >
          + {polyglot.t('General.add')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
