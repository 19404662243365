import React, { useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';

import { CompanyDepartmentAPI } from '@/api-client/company-department.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CompanyDepartmentWithMemberDetailsDto } from '@/models/company-department.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface IDepartmentViewModal {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly closePage: () => void;
  readonly setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedDepartment: CompanyDepartmentWithMemberDetailsDto | undefined;
  readonly refreshDepartments: () => Promise<void>;
}
export const DepartmentViewModal = ({
  isOpen,
  setIsOpen,
  closePage,
  setIsFormOpen,
  selectedDepartment,
  refreshDepartments,
}: IDepartmentViewModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={closePage}>
      <DepartmentViewModalContent
        setIsFormOpen={setIsFormOpen}
        closePage={closePage}
        selectedDepartment={selectedDepartment}
        refreshDepartments={refreshDepartments}
      />
    </DrawerModal>
  );
};

interface IDepartmentViewModalContent {
  readonly closePage: () => void;
  readonly setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedDepartment: CompanyDepartmentWithMemberDetailsDto | undefined;
  readonly refreshDepartments: () => Promise<void>;
}
const DepartmentViewModalContent = ({
  setIsFormOpen,
  closePage,
  selectedDepartment,
  refreshDepartments,
}: IDepartmentViewModalContent) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [departmentToDelete, setDepartmentToDelete] = useState<number>();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const deleteDepartment = async () => {
    try {
      if (departmentToDelete) await CompanyDepartmentAPI.deleteCompanyDepartment(departmentToDelete);
      else throw new Error('No department to delete');
      showMessage('Successfully deleted department', 'success');
      await refreshDepartments();
    } catch (error) {
      showMessage(`Error encountered when trying to delete department: ${nestErrorMessage(error)}`, 'error');
    }
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, departmentId: number) => {
    if (event) setAnchorEl(event.currentTarget);
    setDepartmentToDelete(departmentId);
    setIsConfirmModalOpen(true);
  };
  if (!selectedDepartment) {
    return (
      <Box sx={drawerContentSx}>
        <Typography variant="caption">Department not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{selectedDepartment?.name}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
        <Typography variant="title4">Members</Typography>
        {selectedDepartment?.users && selectedDepartment?.users?.length > 0 ? (
          <Box
            sx={{
              maxHeight: '70vh',
              overflowY: 'auto',
            }}
          >
            {selectedDepartment?.users?.map((eachUser) => {
              return (
                <Box key={eachUser.userId} sx={{ display: 'flex', mb: spacing.mb20 }}>
                  <UserCell key={eachUser.userId} userId={eachUser.userId} avatarSize="xxsmall" nameVariant="caption" />
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography variant="caption">No members</Typography>
        )}
      </Box>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          fullWidth
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={(event) => {
            if (selectedDepartment) confirmDelete(event, selectedDepartment.id);
            else showMessage('No department selected', 'error');
          }}
        >
          {polyglot.t('DepartmentManagementDrawerPage.deleteButton')}
        </ButtonComponent>
        <ButtonComponent
          fullWidth
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={() => {
            setIsFormOpen(true);
          }}
        >
          {polyglot.t('DepartmentManagementDrawerPage.editButton')}
        </ButtonComponent>
      </Box>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (departmentToDelete) {
            setIsConfirmModalOpen(false);
            closePage();
            await deleteDepartment();
          } else showMessage('No department selected', 'error');
        }}
        message={polyglot.t('DepartmentManagementDrawerPage.confirmDeleteMessage')}
        callToAction="Yes"
      />
    </Box>
  );
};
