import React, { useContext, useMemo, useState } from 'react';

import { Box, FormControl, IconButton } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';

import { UserRoleAPI } from '@/api-client/user-role.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { checkScopes } from '@/lib/scopes';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { UserRoleFormInfo, UserRoleImportValues } from '@/models/user-role.model';
import { AutocompleteComponent, OptionObject } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { OptionsProps, SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { OptionObj } from '@/v2/components/user-select-type/user-select.component';
import { DepartmentFormModal } from '@/v2/feature/department/department-settings/components/department-form-modal.component';
import { JobPositionAddEditFormDrawer } from '@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { SiteFormModal } from '@/v2/feature/site/site-settings/features/components/site-form-modal.component';
import { SiteDto } from '@/v2/feature/site/site.dto';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import {
  drawerContentSx,
  editDeleteHeaderSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { getRoleLastChangeTypeOptions } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const RoleSchemaValues = {
  jobPositionId: Yup.number().integer().typeError('Job title is a required field').required('Required'),
  managerId: Yup.number().nullable().notRequired(),
  departmentId: Yup.number().nullable().notRequired(),
  effectiveDate: Yup.string().test(dateFieldTest).notRequired(),
  lastChangeType: Yup.string().nullable().notRequired(),
  lastChangeReason: Yup.string().nullable().optional(),
  siteId: Yup.number().nullable().optional(),
};

export const RoleSchema = Yup.object().shape(RoleSchemaValues);

export const RoleSchemaForImport = Yup.object().shape({
  ...RoleSchemaValues,
  jobTitle: Yup.string().notRequired(),
  jobPositionId: Yup.number().nullable().notRequired(),
});

interface Props {
  readonly userId: number;
  readonly managers: readonly OptionsProps[];
  readonly departments: readonly OptionObject[];
  readonly initialValues: UserRoleFormInfo | UserRoleImportValues | null;
  readonly sites: readonly OptionObject[];
  readonly jobPositions: OptionObject[];
  readonly onSubmit: (_: UserRoleFormInfo) => void;
  readonly onRefresh: () => void;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: UserRoleImportValues) => void;
  readonly refreshSites: KeyedMutator<SiteDto[]> | undefined;
  readonly refreshDept: KeyedMutator<CompanyDepartmentDto[]> | undefined;
  readonly refreshJobPosition: KeyedMutator<JobPosition[]> | undefined;
  readonly defaultJobPosition: JobPositionForTable;
}

export const RoleForm = ({
  initialValues,
  userId,
  managers,
  departments,
  sites,
  jobPositions,
  onSubmit,
  onRefresh,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
  refreshSites,
  refreshDept,
  refreshJobPosition,
  defaultJobPosition,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteCandidate, setDeleteCandidate] = useState<UserRoleFormInfo>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const rowModalMode = initialValues && 'id' in initialValues ? 'edit' : 'add';
  const [siteDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [departmentDrawerOpen, setDepartmentDrawerOpen] = useState(false);
  const [jobDrawerOpen, setJobDrawerOpen] = useState<boolean>(false);

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;

  const scopesContext = { userId: user.userId };

  const defaultBlankModalValues: UserRoleFormInfo = useMemo(
    () => ({
      id: 0,
      jobTitle: '',
      jobPositionId: null,
      departmentId: undefined,
      managerId: undefined,
      effectiveDate: new LocalDate().toDateString(),
      lastChangeType: undefined,
      lastChangeReason: '',
      siteId: undefined,
      customUpdates: [],
    }),
    []
  );

  const formik = useFormik<UserRoleFormInfo | UserRoleImportValues>({
    initialValues: initialValues ?? defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: RoleSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if ('id' in values) {
          const update = {
            ...values,
          };
          if (values.id && values.id > 0) {
            // TODO - temporary fix for frontend, till DatePickerComponent is fixed
            // long term / bigger fix updates value on DatePickerComponent to only accept string
            // and fixes all related issues related to changing value type on component
            await UserRoleAPI.patch(userId, values.id, update);
          } else {
            await UserRoleAPI.create(userId, update);
          }
          onSubmit(update);
          onRefresh();
          onClose();
          showMessage(polyglot.t('RoleForm.successMessages.update'), 'success');
        } else {
          importHandler?.({ ...values });
        }
      } catch (error) {
        showMessage(`${polyglot.t('RoleForm.errorMessages.delete')}: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteRole = async () => {
    try {
      if (usedForDataImport) {
        showMessage(polyglot.t('RoleForm.errorMessages.deleteFlow'), 'error');
        return;
      }
      if (deleteCandidate) {
        await UserRoleAPI.deleteById(userId, deleteCandidate?.id);
        showMessage(polyglot.t('RoleForm.successMessages.delete'), 'success');
        onRefresh();
        onClose();
      }
    } catch (error) {
      showMessage(polyglot.t('RoleForm.errorMessages.delete'), 'error');
    }
  };

  const currentJobPositionValue = useMemo(() => {
    return jobPositions.find((option) => option.value === Number(formik.values.jobPositionId));
  }, [formik.values.jobPositionId, jobPositions]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {rowModalMode === 'add' ? (
          <Typography variant="title2">{polyglot.t('RoleForm.new')}</Typography>
        ) : (
          <Box sx={{ ...editDeleteHeaderSx, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{polyglot.t('RoleForm.edit')}</Typography>
            <IconButton
              sx={iconCTAButtonSx}
              onClick={(event) => {
                if (!('id' in formik.values)) return;
                setAnchorEl(event.currentTarget);
                setIsRemovalModalOpen(true);
                setDeleteCandidate(formik.values);
              }}
              disabled={usedForDataImport}
            >
              <TrashIcon {...actionIconSize} />
            </IconButton>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          <ProfileField fieldStub="role.effectiveDate">
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.effectiveDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('effectiveDate', value);
                }
              }}
              name="effectiveDate"
              label={polyglot.t('RoleForm.effectiveDate')}
              error={!!formik.errors.effectiveDate && formik.touched.effectiveDate}
              helperText={formik.errors.effectiveDate && formik.touched.effectiveDate}
            />
          </ProfileField>

          {jobPositions && (
            <ProfileField fieldStub="role.jobPositionId">
              <AutocompleteComponent
                name="jobPositionId"
                label={polyglot.t('RoleForm.jobTitle')}
                options={jobPositions}
                value={currentJobPositionValue}
                compareValue={formik.values.jobPositionId}
                // @ts-ignore
                onChange={(_, x: OptionObj) => {
                  formik.setFieldValue('jobPositionId', x?.value ?? null);
                }}
                error={formik.touched.jobPositionId && Boolean(formik.errors.jobPositionId)}
                helperText={formik.touched.jobPositionId && formik.errors.jobPositionId}
                editList={{
                  handler: () => setJobDrawerOpen(true),
                  isHidden: false,
                }}
              />
            </ProfileField>
          )}

          <ProfileField fieldStub="role.department">
            <AutocompleteComponent
              name="departmentId"
              label={polyglot.t('RoleForm.departmentId')}
              options={departments}
              value={departments.find(({ value }: { value: number | string }) => value === formik.values.departmentId)}
              compareValue={formik.values.departmentId ?? ''}
              // @ts-ignore
              onChange={(_, x: OptionObj) => {
                formik.setFieldValue('departmentId', x?.value ?? null);
              }}
              error={formik.touched.departmentId && Boolean(formik.errors.departmentId)}
              helperText={formik.touched.departmentId && formik.errors.departmentId}
              editList={{
                handler: () => setDepartmentDrawerOpen(true),
                isHidden: !checkScopes(user, ['company.settings:all'], scopesContext),
              }}
            />
          </ProfileField>

          <ProfileField fieldStub="role.site">
            <AutocompleteComponent
              name="siteId"
              label={polyglot.t('RoleForm.siteId')}
              options={sites}
              value={sites.find(({ value }) => value === formik.values.siteId)}
              compareValue={formik.values.siteId ?? ''}
              // @ts-ignore
              onChange={(_, x: OptionObj) => {
                formik.setFieldValue('siteId', x?.value ?? null);
              }}
              error={formik.touched.siteId && Boolean(formik.errors.siteId)}
              helperText={formik.touched.siteId && formik.errors.siteId}
              editList={{
                handler: () => setSiteDrawerOpen(true),
                isHidden: !checkScopes(user, ['company.settings:all'], scopesContext),
              }}
            />
          </ProfileField>

          <ProfileField fieldStub="role.manager">
            <SingleUserSelect
              name="managerId"
              options={managers}
              // @ts-ignore
              onChange={(_, x) => formik.setFieldValue('managerId', x?.value ?? null)}
              value={formik.values.managerId}
              label={polyglot.t('RoleForm.managerId')}
              error={Boolean(formik.errors.managerId)}
              helperText={formik.errors.managerId}
            />
          </ProfileField>

          <ProfileField fieldStub="role.lastChangeType">
            <FormControl fullWidth error={!!formik.errors.lastChangeType}>
              <SelectComponent
                name="lastChangeType"
                label={polyglot.t('RoleForm.lastChangeType')}
                options={getRoleLastChangeTypeOptions(polyglot)}
                value={formik.values.lastChangeType}
                compareValue={formik.values.lastChangeType}
                error={!!formik.errors.lastChangeType && formik.touched.lastChangeType}
                onChange={formik.handleChange}
                helperText={formik.errors.lastChangeType && formik.touched.lastChangeType}
              />
            </FormControl>
          </ProfileField>

          <ProfileField fieldStub="role.lastChangeReason">
            <TextfieldComponent
              name="lastChangeReason"
              label={polyglot.t('RoleForm.lastChangeReason')}
              value={formik.values.lastChangeReason}
              type="text"
              onChange={formik.handleChange}
              error={formik.touched.lastChangeReason && !!formik.errors.lastChangeReason}
              helperText={(formik.touched.lastChangeReason && formik.errors.lastChangeReason) ?? ' '}
              clearText={() => formik.setFieldValue('lastChangeReason', '')}
            />
          </ProfileField>

          <CustomFieldComponents
            values={formik.values.customUpdates}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            rowModalMode={rowModalMode}
            formName={CustomProfileFormType.Role}
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth={true}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>

        <NotificationModal
          isOpen={isRemovalModalOpen}
          onClose={() => setIsRemovalModalOpen(false)}
          anchorEl={anchorEl}
          takeAction={deleteRole}
          message={polyglot.t('RoleForm.confirmDelete')}
          callToAction={polyglot.t('RoleForm.callToAction')}
        />

        <SiteFormModal
          isOpen={siteDrawerOpen}
          setIsOpen={setSiteDrawerOpen}
          selectedSite={undefined}
          refreshSites={async () => {
            if (refreshSites) await refreshSites();
          }}
          closePage={() => setSiteDrawerOpen(false)}
        />

        <DepartmentFormModal
          isOpen={departmentDrawerOpen}
          setIsOpen={setDepartmentDrawerOpen}
          selectedDepartment={undefined}
          refreshDepartments={async () => {
            if (refreshDept) await refreshDept();
          }}
          closePage={() => setDepartmentDrawerOpen(false)}
        />

        <JobPositionAddEditFormDrawer
          isOpen={jobDrawerOpen}
          setIsOpen={setJobDrawerOpen}
          jobPositionToEdit={defaultJobPosition}
          onClose={() => setJobDrawerOpen(false)}
          refreshJobPositions={async () => {
            if (refreshJobPosition) await refreshJobPosition();
          }}
        />
      </Form>
    </FormikProvider>
  );
};
