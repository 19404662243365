import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

export const PensionSettingsMembersPage = () => {
  const { polyglot } = usePolyglot();
  return (
    <BenefitsSettingsPageSection title={polyglot.t('General.members')}>
      <DescriptionLine
        field={polyglot.t('General.members')}
        value={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="title4">{polyglot.t('PensionModule.memberAllocation')}</Typography>
            <Typography variant="title4">{polyglot.t('PensionModule.rememberToAssess')}</Typography>
          </Box>
        }
      />
    </BenefitsSettingsPageSection>
  );
};
