import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { PEOPLE_ME_ACTION_ITEMS } from '@/lib/routes';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { ApprovalRuleDto } from '@/v2/feature/approval-rule/approval-rule.dto';
import { RequestFormComponent } from '@/v2/feature/requests/features/request-forms/components/request-form.component';
import { RequestFormTemplate } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SubmitRequestPage = () => {
  const { data: approvalRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });

  const [formTemplate, setFormTemplate] = useState<RequestFormTemplate | 'not-found'>();
  const { id: formIdValue } = useParams<{ id: string }>();
  const { data: forms, error } = useApiClient(RequestFormsEndpoints.getFormTemplates(), { suspense: false });

  useEffect(() => {
    if (!forms) return;
    const formId = Number(formIdValue);
    const form = forms.find((f) => f.id === formId);
    setFormTemplate(form ?? 'not-found');
  }, [formIdValue, forms]);

  if (formTemplate && formTemplate !== 'not-found') {
    return <SubmitRequestPageLayout approvalRules={approvalRules ?? []} formTemplate={formTemplate} />;
  }

  return (
    <>
      {forms === undefined && <LoadingSpinner />}
      {error && (
        <Stack>
          <Typography variant="caption">{error.data?.message}</Typography>
        </Stack>
      )}
      {formTemplate === 'not-found' && (
        <Stack>
          <Typography variant="caption">Form not found</Typography>
        </Stack>
      )}
    </>
  );
};

const SubmitRequestPageLayout = ({
  formTemplate,
  approvalRules,
}: {
  formTemplate: RequestFormTemplate;
  approvalRules: ApprovalRuleDto[] | null | undefined;
}) => {
  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  return (
    <RootStyle>
      <ContentWrapper>
        <Box
          sx={{
            display: 'flex',
            overflowY: 'hidden',
            flexDirection: 'column',
            gap: spacing.s2,
            maxWidth: '500px',
            width: '100%',
            margin: '0 auto',
          }}
        >
          <RequestFormComponent
            template={formTemplate}
            onSubmit={(requiresApproval) => {
              if (requiresApproval) {
                showMessage('Your request has been submitted for approval.', 'success');
              } else {
                showMessage('Your request has been saved.', 'success');
              }
            }}
            onClose={() => {
              routerHistory.push(PEOPLE_ME_ACTION_ITEMS);
            }}
            approvalRules={approvalRules ?? []}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
