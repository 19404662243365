import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { IconButton } from '@v2/components/forms/icon-button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { nestErrorMessage } from '@/lib/errors';
import { APP_STORE_ROUTE } from '@/lib/routes';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortNumeric } from '@/v2/components/table/table-sorting.util';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  PaymentSettingEditMode,
  PaymentSettingsNewTypeDrawer,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component';
import {
  PaymentSettingsPageSection,
  DescriptionLine,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import {
  AccountingCodeConfig,
  PaymentTypeSettings,
  SyncToExternalOptionsEnum,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import {
  PaymentTypeSettingsAPI,
  PaymentTypeSettingsEndpoints,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

interface PageProps {
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly refresh: KeyedMutator<PaymentTypeSettings>;
}

const ACCOUNTING_FINANCE_CATEGORY = 'Accounting and Finance';

export const PaymentTypeAccountingSettingsPage = ({ paymentTypeSetting, refresh }: PageProps) => {
  const { polyglot } = usePolyglot();
  // const { pathname } = useLocation();
  const routerHistory = useHistory();
  // const isExpense = isExpenseCheck(pathname);
  // const type = isExpense ? 'expense' : 'invoices';
  const [showMessage] = useMessage();
  const { data: isAccountingAppConfigured } = useApiClient(PaymentTypeSettingsEndpoints.accountingAppConfigured(), {
    suspense: false,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data: payrollId } = useApiClient(PayrollEndpoints.getCompanyCurrentPayrollId(), {
    suspense: false,
  });

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<PaymentSettingEditMode | undefined>(undefined);

  const [codeToDelete, setCodeToDelete] = useState<number>(0);

  const [typeForEdit, setTypeForEdit] = useState<PaymentTypeSettings | undefined>(undefined);
  const [codeConfigIndexToEdit, setCodeConfigIndexToEdit] = useState<number | undefined>(undefined);

  const { payrolled, accountingCodeConfig, paycode, syncToExternal, externalSyncOrganisation } = paymentTypeSetting;

  const handleAccountingEdit = useCallback(
    (code: number) => {
      setIsEditOpen(true);
      setTypeForEdit(paymentTypeSetting);
      const indexToEdit = accountingCodeConfig
        ? accountingCodeConfig.findIndex((item) => item.accountingCode === code)
        : undefined;
      setCodeConfigIndexToEdit(indexToEdit);
      setEditMode('accounting');
    },
    [accountingCodeConfig, paymentTypeSetting]
  );

  const handleAccountingInsert = useCallback(() => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setCodeConfigIndexToEdit(undefined);
    setEditMode('accounting');
  }, [paymentTypeSetting]);

  const handleDeleteAccountingCode = useCallback(async () => {
    try {
      await PaymentTypeSettingsAPI.deleteAccountingCodeFromPaymentTypeSetting(paymentTypeSetting.id, codeToDelete);
      showMessage('Successfully removed accounting code from type', 'success');
      setAnchorEl(null);
      setCodeToDelete(0);
      refresh();
    } catch (error) {
      showMessage(`Failed to remove accounting code from type: ${nestErrorMessage(error)}`, 'error');
    }
  }, [codeToDelete, paymentTypeSetting.id, refresh, showMessage]);

  const handleSyncEdit = useCallback(() => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setEditMode('sync');
  }, [paymentTypeSetting]);

  const columns = useMemo<ColumnDef<AccountingCodeConfig, AccountingCodeConfig>[]>(() => {
    return [
      {
        header: () => polyglot.t('PaymentSettingsPage.tableColumns.accountingCode'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'accountingCode',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.accountingCode),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.accountingCode}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('PaymentSettingsPage.tableColumns.accountingCodeDescription'),
        accessorFn: (row) => row,
        id: 'accountingCodeDescription',
        minWidth: 30,
        maxWidth: 80,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.accountingCodeDescription ? (
            <Typography variant="caption">{original.accountingCodeDescription}</Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        maxSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: spacing.g5 }}>
            <IconButton
              key="delete"
              sizeVariant="small"
              colorVariant="secondary"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setCodeToDelete(original.accountingCode);
              }}
            >
              <TrashIcon {...actionIconSize} />
            </IconButton>
            <IconButton
              key="edit"
              sizeVariant="small"
              colorVariant="secondary"
              onClick={() => handleAccountingEdit(original.accountingCode)}
            >
              <EditIcon {...actionIconSize} />
            </IconButton>
          </Box>
        ),
      },
    ];
  }, [handleAccountingEdit, polyglot]);

  const connectAccountingApp = useCallback(() => {
    if (!paymentTypeSetting.id) return;
    routerHistory.push({
      pathname: generatePath(APP_STORE_ROUTE),
      state: { category: ACCOUNTING_FINANCE_CATEGORY },
    });
  }, [paymentTypeSetting.id, routerHistory]);

  return (
    <PaymentSettingsPageSection
      title={polyglot.t('PaymentSettingsPage.accountingHeader')}
      editSection={
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: spacing.g5 }}>
          {!isAccountingAppConfigured && payrollId && (
            <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => connectAccountingApp()}>
              {polyglot.t('PayrollCompanySetup.connectAccountingTitle')}
            </ButtonComponent>
          )}
          <IconButton key="edit" sizeVariant="small" colorVariant="primary" onClick={() => handleAccountingInsert()}>
            <Plus {...actionIconSize} />
          </IconButton>
        </Box>
      }
    >
      {!payrolled ? (
        <>
          {accountingCodeConfig?.length && accountingCodeConfig?.length > 0 ? (
            <Box sx={{ width: '100%' }}>
              <BasicTable<AccountingCodeConfig>
                rowData={paymentTypeSetting?.accountingCodeConfig ?? []}
                columnData={columns}
                loading={false}
                hidePagination
                externalTdAndThPadding="0 0px"
              />
            </Box>
          ) : (
            <Box>
              <Typography variant="caption" color="Grey" sx={{ mt: '8px' }}>
                {polyglot.t('PaymentSettingsPage.noAccountingCodes')}
              </Typography>
            </Box>
          )}
          {isAccountingAppConfigured && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: spacing.mt20 }}>
                <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
                  {polyglot.t('PaymentSettingsPage.syncSettings')}
                </Typography>
                <IconButton key="edit" sizeVariant="small" colorVariant="secondary" onClick={() => handleSyncEdit()}>
                  <EditIcon {...actionIconSize} />
                </IconButton>
              </Box>
              <DescriptionLine
                field={polyglot.t('PaymentSettingsPage.organisation')}
                value={`${
                  externalSyncOrganisation?.name
                    ? externalSyncOrganisation.name
                    : 'No syncing organisation selected yet'
                }`}
              />
              <DescriptionLine
                field={polyglot.t('ContractorInvoiceModal.syncMethod')}
                value={`${syncToExternal ? SyncToExternalOptionsEnum[syncToExternal] : ''}`}
              />
            </>
          )}
        </>
      ) : (
        <DescriptionLine field={polyglot.t('PaymentSettingsPage.subtitles.paycode')} value={paycode} />
      )}
      {isEditOpen && (
        <PaymentSettingsNewTypeDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          refreshIndividualSetting={refresh}
          typeForEdit={typeForEdit}
          editMode={editMode}
          codeConfigIndex={codeConfigIndexToEdit}
        />
      )}
      <NotificationModal
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        takeAction={async () => {
          handleDeleteAccountingCode();
        }}
        message={polyglot.t('PaymentSettingsPage.deleteCodeConfirm')}
        callToAction={polyglot.t('General.yes')}
      />
    </PaymentSettingsPageSection>
  );
};
