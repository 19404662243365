import React, { useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { OptionProps, StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx, tablePrimaryIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/table.styles';
import Polyglot from 'node-polyglot';

import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Reject } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { RejectDrawer } from '@/v2/components/reject-drawer.component';

interface ActionProps {
  readonly absence: AbsenceDto;
  readonly onEdit?: (_: AbsenceDto) => void;
  readonly onDelete?: (_: AbsenceDto) => Promise<void>;
  readonly onApprove?: (_: AbsenceDto) => Promise<AbsenceDto | void>;
  readonly onReject?: (a: AbsenceDto, rejectionNote?: string) => Promise<AbsenceDto | void>;
  readonly onRequestCancellation?: (_: AbsenceDto) => Promise<void>;
  readonly onRejectCancellation?: (_: AbsenceDto) => Promise<void>;
  readonly onForceApproval?: (_: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => Promise<void>;
}

export function AbsenceTableActions({
  absence,
  onApprove,
  onReject,
  onDelete,
  onEdit,
  onRequestCancellation,
  onRejectCancellation,
  onForceApproval,
}: ActionProps) {
  const { polyglot } = usePolyglot();

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [mode, setMode] = useState<string | null>(null);

  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
    setMode(null);
  };

  const showActions = useMemo(
    () =>
      (absence.canEdit && onEdit) ||
      (absence.canDelete && onDelete) ||
      (absence.canForceApprove && onForceApproval) ||
      (absence.canForceReject && onForceApproval) ||
      (absence.canRequestCancellation && onRequestCancellation),
    [absence, onEdit, onDelete, onForceApproval, onRequestCancellation]
  );

  return !showActions ? null : (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <StyledMenuComponent
        options={getAbsenceActionsOptions(absence, polyglot, onEdit, onDelete, onForceApproval, onRequestCancellation)}
        actionButtonDetails={{
          type: 'iconButton',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: 'actions',
          icon: <ActionsSmall {...iconSize} />,
        }}
      />
      {absence.canReject && (
        <IconButton
          sx={tableIconButtonSx}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setMode('reject');
            e.stopPropagation();
          }}
        >
          <Reject {...iconSize} />
        </IconButton>
      )}
      {absence.canApprove && onApprove && (
        <IconButton
          sx={tablePrimaryIconButtonSx}
          onClick={async (e) => {
            if (onApprove) await onApprove(absence);
            e.stopPropagation();
          }}
        >
          <Chose {...iconSize} />
        </IconButton>
      )}

      {absence.canRejectCancellation && (
        <IconButton
          sx={tableIconButtonSx}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setMode('reject cancellation of');
            e.stopPropagation();
          }}
        >
          <Reject {...iconSize} />
        </IconButton>
      )}

      {absence.canApproveCancellation && (
        <IconButton
          sx={tablePrimaryIconButtonSx}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setMode('delete');
            e.stopPropagation();
          }}
        >
          <Trash {...iconSize} />
        </IconButton>
      )}

      {isRejectDrawerOpen && onReject && (
        <RejectDrawer
          isOpen={isRejectDrawerOpen}
          setIsOpen={setIsRejectDrawerOpen}
          onReject={async (notes) => {
            await onReject(absence, notes);
          }}
        />
      )}

      <NotificationModal
        isOpen={mode !== null}
        onClose={() => handleClose()}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (mode === 'reject' && absence.canReject) setIsRejectDrawerOpen(true);
          else if (mode === 'delete' && (absence.canDelete || absence.canApproveCancellation) && onDelete)
            await onDelete(absence);
          else if (mode === 'reject cancellation of' && onRejectCancellation) await onRejectCancellation(absence);
          setMode(null);
        }}
        message={polyglot.t('AbsenceTableActions.confirmDelete', { mode: mode })}
        callToAction={polyglot.t('AbsenceTableActions.callToAction')}
      />
    </Box>
  );
}

const getAbsenceActionsOptions = (
  absence: AbsenceDto,
  polyglot: Polyglot,
  onEdit?: (_: AbsenceDto) => void,
  onDelete?: (_: AbsenceDto) => Promise<void>,
  onForceApproval?: (_: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => Promise<void>,
  onRequestCancellation?: (_: AbsenceDto) => Promise<void>
): OptionProps[] => {
  const actions: OptionProps[] = [];

  if (absence.canForceApprove && onForceApproval)
    actions.push({
      icon: <Chose {...iconSize} />,
      handler: async () => {
        await onForceApproval(absence, AbsenceStatus.Approved);
      },
      label: polyglot.t('AbsenceTableActions.forceApprove'),
      disabled: false,
    });

  if (absence.canForceReject && onForceApproval)
    actions.push({
      icon: <Reject {...iconSize} />,
      handler: async () => {
        await onForceApproval(absence, AbsenceStatus.Rejected);
      },
      label: polyglot.t('AbsenceTableActions.forceReject'),
      disabled: false,
    });

  if (absence.canEdit && onEdit)
    actions.push({
      icon: <Edit {...iconSize} />,
      handler: () => {
        onEdit(absence);
      },
      label: polyglot.t('AbsenceTableActions.edit'),
      disabled: false,
    });

  if (absence.canDelete && onDelete)
    actions.push({
      icon: <Trash {...iconSize} />,
      handler: async () => {
        await onDelete(absence);
      },
      label: polyglot.t('General.delete'),
    });

  if (absence.canRequestCancellation && onRequestCancellation)
    actions.push({
      icon: <Reject {...iconSize} />,
      handler: async () => {
        await onRequestCancellation(absence);
      },
      label: polyglot.t('General.cancel'),
    });

  return actions;
};
