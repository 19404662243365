import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import {
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { AddEffectiveUserCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/add-effective-user-custom-benefit-drawer.component';
import { UpdateEffectiveUserCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/update-effective-user-custom-benefit-drawer.component';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';

import { ButtonComponent } from '@/v2/components/forms/button.component';

interface UserCustomBenefitWithIncludedProp extends Partial<UserCustomBenefitDto> {
  readonly userId: number;
  readonly included: boolean;
  readonly customBenefitId: number;
}

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitWithIncludedProp;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}

export const ViewUserCustomBenefitDrawer = ({ isOpen, setIsOpen, userBenefit, onClose, refresh }: DrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(
    isRecurringBenefit(userBenefit.customBenefit?.type) ? UserEndpoints.getUserFamilyMembers(userBenefit.userId) : null,
    { suspense: false }
  );

  const { data: canManage } = useApiClient(
    CustomBenefitEndpoints.canManageBenefitForUser(userBenefit.customBenefitId, userBenefit.userId),
    { suspense: false }
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ViewUserCustomBenefitDrawerContent
        userBenefit={userBenefit}
        userFamilyMembers={userFamilyMembers ?? []}
        refresh={refresh}
        setIsViewOpen={setIsOpen}
        canManage={!!canManage}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefit: UserCustomBenefitWithIncludedProp;
  readonly userFamilyMembers: UserFamilyMemberDto[];
  readonly refresh: () => Promise<void>;
  readonly setIsViewOpen: Dispatch<SetStateAction<boolean>>;
  readonly canManage: boolean;
}

export const ViewUserCustomBenefitDrawerContent = ({
  userBenefit,
  userFamilyMembers,
  refresh,
  setIsViewOpen,
  canManage,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);

  const hasOpeningBalance = isUsingOpeningBalance(userBenefit.customBenefit?.type);
  const isLoan = isLoanBenefit(userBenefit.customBenefit?.type);
  const isRecurring = isRecurringBenefit(userBenefit.customBenefit?.type);

  const dependantsList = useMemo(() => {
    if (!userBenefit?.dependants || !userBenefit?.dependantsList) return '';
    return (
      userFamilyMembers
        .filter((m) => userBenefit.dependantsList!.includes(m.id))
        .map((m) => m.name)
        .join(', ') ?? ''
    );
  }, [userFamilyMembers, userBenefit]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">
        {userBenefit.customBenefit?.name ?? polyglot.t('BenefitModule.userBenefit')}
      </Typography>
      <Box>
        <Typography variant="captionSmall">{polyglot.t('General.employee')}</Typography>
        <UserCell userId={userBenefit.userId} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
      </Box>

      {!userBenefit.included && (
        <Typography variant="caption">{polyglot.t('BenefitModule.employeeNotAMemberOfBenefit')}</Typography>
      )}

      {userBenefit.effectiveDate && (
        <LineItem
          name={polyglot.t('General.effectiveDate')}
          value={new LocalDate(userBenefit.effectiveDate).toLocaleDateString()}
        />
      )}

      {userBenefit.effectiveEndDate && (
        <LineItem
          name={polyglot.t('General.effectiveEndDate')}
          value={new LocalDate(userBenefit.effectiveEndDate).toLocaleDateString()}
        />
      )}

      {userBenefit.customBenefit?.type && (
        <LineItem name={polyglot.t('General.type')} value={userBenefit.customBenefit.type} />
      )}

      {hasOpeningBalance && (
        <LineItem
          name={isLoan ? polyglot.t('BenefitModule.borrowed') : polyglot.t('BenefitModule.allowance')}
          value={
            userBenefit.openingBalance
              ? formatMoney({
                  amount: userBenefit.openingBalance,
                  asDecimal: true,
                })
              : null
          }
        />
      )}

      {isLoan && (
        <LineItem name={polyglot.t('BenefitModule.numberOfInstallments')} value={userBenefit.numberOfInstallments} />
      )}

      {isRecurring && (
        <LineItem
          name={polyglot.t('BenefitModule.employerContribution')}
          value={
            userBenefit.employerContribution
              ? formatMoney({
                  amount: userBenefit.employerContribution,
                  asDecimal: true,
                })
              : null
          }
        />
      )}

      {isRecurring && (
        <LineItem
          name={polyglot.t('BenefitModule.employeeContribution')}
          value={
            userBenefit.employeeContribution
              ? formatMoney({
                  amount: userBenefit.employeeContribution,
                  asDecimal: true,
                })
              : null
          }
        />
      )}

      {isRecurring && <LineItem name={polyglot.t('BenefitModule.dependants')} value={userBenefit.dependants} />}

      {isRecurring && dependantsList && (
        <LineItem name={polyglot.t('BenefitModule.dependantsList')} value={dependantsList} />
      )}

      {userBenefit.updatedBy && (
        <Box>
          <Typography variant="captionSmall">{polyglot.t('BenefitModule.lastUpdatedBy')}</Typography>
          <UserCell userId={userBenefit.updatedBy} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
        </Box>
      )}

      {userBenefit.createdBy && (
        <Box>
          <Typography variant="captionSmall">{polyglot.t('General.createdBy')}</Typography>
          <UserCell userId={userBenefit.createdBy} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
        </Box>
      )}

      {canManage && (
        <Box sx={buttonBoxDrawerSx}>
          {userBenefit?.included &&
            userBenefit.effectiveDate &&
            userBenefit.customBenefit &&
            userBenefit.customBenefit?.type !== CustomBenefitType.Loan && (
              <ButtonComponent
                onClick={() => {
                  setIsUpdateOpen(true);
                }}
                sizeVariant="medium"
                colorVariant="secondary"
                fullWidth
              >
                {polyglot.t('BenefitModule.updateCurrent')}
              </ButtonComponent>
            )}
          {(!userBenefit?.included ||
            (userBenefit.customBenefit && userBenefit.customBenefit?.type === CustomBenefitType.Recurring)) && (
            <ButtonComponent
              onClick={() => {
                setIsAddOpen(true);
              }}
              sizeVariant="medium"
              colorVariant={userBenefit && userBenefit.effectiveDate ? 'secondary' : 'primary'}
              fullWidth
            >
              {userBenefit && userBenefit.effectiveDate
                ? polyglot.t('BenefitModule.change')
                : polyglot.t('BenefitModule.addUserToBenefit')}
            </ButtonComponent>
          )}
        </Box>
      )}

      <AddEffectiveUserCustomBenefitDrawer
        isOpen={isAddOpen}
        setIsOpen={setIsAddOpen}
        userBenefit={userBenefit}
        onClose={() => {
          setIsViewOpen(false);
          setIsAddOpen(false);
        }}
        refresh={refresh}
      />
      <UpdateEffectiveUserCustomBenefitDrawer
        isOpen={isUpdateOpen}
        setIsOpen={setIsUpdateOpen}
        userBenefit={userBenefit}
        onClose={() => {
          setIsViewOpen(false);
          setIsUpdateOpen(false);
        }}
        refresh={refresh}
      />
    </Box>
  );
};

const LineItem = ({ name, value }: { name: string; value: string | number | undefined | null }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <Typography variant="captionSmall">{name}</Typography>
      <Typography variant="title4">{value ?? <EmptyCell />}</Typography>
    </Box>
  );
};
