import { OptionProps, StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';

interface ChipSelectProps {
  readonly title: string;
  readonly options: readonly OptionProps[];
}

export const ChipSelect = ({ title, options }: ChipSelectProps) => {
  return (
    <StyledMenuComponent
      options={options}
      actionButtonDetails={{
        type: 'button',
        sizeVariant: 'filter',
        colorVariant: 'secondary',
        title,
      }}
    />
  );
};
