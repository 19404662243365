import { ReactNode } from 'react';

import { Box, IconButton, SxProps } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';

interface SettingsSectionContentProps {
  readonly children: ReactNode;
  readonly title: string | ReactNode;
  readonly headerWidth?: string;
  readonly onEdit?: () => void;
  readonly buttons?: ReactNode[];
  readonly buttonIcon?: ReactNode;
  readonly contentWidth?: string;
  readonly loading?: boolean;
  readonly noHorizontalPadding?: boolean;
  readonly topHeaderPaddingSx?: SxProps;
  readonly contentSx?: SxProps;
  readonly id?: string;
}

export const SettingsSectionContent = ({
  children,
  title,
  onEdit,
  buttons,
  headerWidth = '600px',
  contentWidth = '600px',
  buttonIcon = <EditIcon {...actionIconSize} />,
  loading = false,
  noHorizontalPadding = true,
  topHeaderPaddingSx,
  contentSx,
  id,
}: SettingsSectionContentProps) => {
  return (
    <Box sx={{ width: '100%', overflowY: 'auto' }}>
      <TopHeader
        title={
          <Box
            sx={{
              width: headerWidth,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%' }}>
              {typeof title === 'string' ? (
                <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
                  {title}
                </Typography>
              ) : (
                title
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: spacing.g5 }}>
              {buttons}
              {onEdit && (
                <IconButton key="edit" sx={tableIconButtonSx} onClick={onEdit}>
                  {buttonIcon}
                </IconButton>
              )}
            </Box>
          </Box>
        }
        sx={{ width: headerWidth, ...topHeaderPaddingSx }}
      />
      <ContentWrapper
        sx={{ scrollbarWidth: 'none', ...contentSx }}
        noHorizontalPadding={noHorizontalPadding}
        loading={loading}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g48, width: contentWidth }} id={id}>
          {children}
        </Box>
      </ContentWrapper>
    </Box>
  );
};
