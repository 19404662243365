import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PolicyProratingFormData } from '@v2/feature/absence/absence.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyProratingEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyProratingEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: PolicyProratingEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: PolicyProratingFormData) => {
      const policyData: PolicyProratingFormData = {
        proratingStartDate: true, // This is true in both cases (prorate by start date or prorate by start date & fte)
        proratingFte: values.proratingFte,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyProrating(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [setIsOpen, refresh, absencePolicy, showMessage, polyglot]
  );

  const formik = useFormik<PolicyProratingFormData>({
    initialValues: {
      proratingStartDate: absencePolicy.proratingStartDate,
      proratingFte: absencePolicy.proratingFte,
    },
    validationSchema: yup.object({
      proratingStartDate: yup.boolean().required('Required field'),
      proratingFte: yup.boolean().required('Required field'),
    }),
    onSubmit,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsenceUtil.prorating')}</Typography>

          <Box>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                name="prorating-allocation-group"
                onChange={(event) => {
                  formik.setFieldValue('proratingFte', event.target.value === 'true');
                }}
              >
                <FormControlLabel
                  key="startDateOnly"
                  labelPlacement="end"
                  value={'false'}
                  checked={!formik.values.proratingFte}
                  control={<StyledRadio />}
                  label={
                    <Box>
                      <Typography variant="caption">{polyglot.t('AbsenceUtil.prorateByStartDate')}</Typography>
                      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                        {polyglot.t('AbsenceUtil.prorateByStartDateDesc')}
                      </Typography>
                    </Box>
                  }
                  sx={{ mb: '20px' }}
                />
                <FormControlLabel
                  key="startDateAndFte"
                  labelPlacement="end"
                  value={'true'}
                  checked={formik.values.proratingFte}
                  control={<StyledRadio />}
                  label={
                    <Box>
                      <Typography variant="caption">{polyglot.t('AbsenceUtil.prorateByStartDateAndFTE')}</Typography>
                      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                        {polyglot.t('AbsenceUtil.prorateByStartDateAndFTEDesc')}
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
