import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { identity } from 'fp-ts/lib/function';
import Polyglot from 'node-polyglot';

import { UserBankAccountFormSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { useProfileFormUpdate } from '@/v2/feature/user/context/user-profile.context';
import { UserBankAccountAPI } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import { UserBankAccountDto } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import {
  CardStructure,
  FieldStructure,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { BankForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-form.component';
import { buildColumnsForCustomFields } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { UserAPI } from '@/v2/feature/user/user.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';

const SCOPE = 'user.bankAccount' as const;

const iconSize = { width: 14, height: 14 } as const;
const skeletonHeight = (polyglot: Polyglot) => calculateSkeletonHeight(UserBankAccountFormSchema(polyglot));

export const UserBankInfoForm = ({
  userId,
  handleSubmit = () => {},
  missingFields,
}: UserProfileCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [data, setData] = useState<EntriesAndEffectiveRecord<UserBankAccountDto>>();

  const [loading, setLoading] = useState(false);

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [defaultName, setDefaultName] = useState<string>('');

  const setFormCurrentlyEditing = useProfileFormUpdate();

  const [modalInitialValues, setModalInitialValues] = useState<UserBankAccountDto | null>(null);

  const getUserBankAccounts = useCallback(async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const [userBankAccounts, userBasic] = await Promise.all([
        UserBankAccountAPI.findByUserId(userId),
        UserAPI.getUserBasicInfo(userId),
      ]);
      setDefaultName(userBasic?.name ?? '');
      setData(userBankAccounts);
      if (!userBankAccounts?.entries?.length) setShowDetails(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t('UserBankInfoForm.errorMessages.load')}: ${error}`, 'error');
    }
  }, [polyglot, showMessage, userId]);

  useEffect(() => {
    getUserBankAccounts();
  }, [getUserBankAccounts]);

  const editBankRow = useCallback((rowData: UserBankAccountDto) => {
    setModalInitialValues(rowData);
    setIsModalOpen(true);
  }, []);

  const addBankRow = () => {
    setModalInitialValues(null);
    setIsModalOpen(true);
  };

  const columnData = useMemo<ColumnDef<UserBankAccountDto, UserBankAccountDto>[]>(
    () => [
      {
        header: () => polyglot.t('UserBankInfoForm.accountName'),
        accessorFn: identity,
        id: 'accountName',
        enableSorting: false,
        size: 120,
        cell: ({ row: { original } }) => <div>{original.accountName}</div>,
      },
      {
        header: () => polyglot.t('UserBankInfoForm.accountNumber'),
        accessorFn: identity,
        id: 'accountNumber',
        enableSorting: false,
        size: 120,
        cell: ({ row: { original } }) => <div>{original.accountNumber ? original.accountNumber : ''}</div>,
      },
      {
        header: () => polyglot.t('UserBankInfoForm.sortCode'),
        accessorFn: identity,
        id: 'sortCode',
        enableSorting: false,
        size: 120,
        cell: ({ row: { original } }) => <div>{original.sortCode ? original.sortCode : ''}</div>,
      },
      {
        header: () => polyglot.t('UserBankInfoForm.bank'),
        accessorFn: identity,
        id: 'bankName',
        enableSorting: false,
        size: 120,
        cell: ({ row: { original } }) => <div>{original.bankName ? original.bankName : ''}</div>,
      },
      {
        header: () => polyglot.t('UserBankInfoForm.country'),
        accessorFn: identity,
        id: 'country',
        enableSorting: false,
        size: 120,
        cell: ({ row: { original } }) => <div>{original.country ? original.country : ''}</div>,
      },
      {
        header: () => polyglot.t('UserBankInfoForm.currency'),
        accessorFn: identity,
        id: 'currency',
        enableSorting: false,
        size: 120,
        cell: ({ row: { original } }) => <div>{original.currency ?? ''}</div>,
      },
      ...buildColumnsForCustomFields(data?.entries, { size: 120 }),
      {
        header: () => polyglot.t('UserBankInfoForm.effectiveDate'),
        accessorFn: identity,
        id: 'effectiveDate',
        enableSorting: false,
        size: 120,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },
      {
        header: () => '',
        accessorFn: identity,
        id: 'actions',
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton
                title={polyglot.t('UserBankInfoForm.edit')}
                onClick={() => {
                  editBankRow(original);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </ScopesControl>
          </div>
        ),
      },
    ],
    [polyglot, data?.entries, scopesContext, editBankRow]
  );

  const effectiveRecord = data?.effectiveRecord;

  return !data ? (
    <SkeletonLoader
      variant="rectangular"
      height={skeletonHeight(polyglot)}
      sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
    />
  ) : (
    <CardStructure
      cardTitle={polyglot.t('UserBankInfoForm.bankAccount')}
      cardScope={[SCOPE]}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      userId={userId}
      addAction={addBankRow}
      showMissingInfo={!effectiveRecord}
      cardFieldCustomUpdates={effectiveRecord?.customUpdates}
      cardFieldStubs={[
        'bankAccount.accountName',
        'bankAccount.accountNumber',
        'bankAccount.bankName',
        'bankAccount.sortCode',
        'bankAccount.country',
        'bankAccount.currency',
      ]}
      cardFieldDetails={
        effectiveRecord && (
          <Box component="dl" sx={definitionListSx}>
            <FieldStructure
              fieldTitle={polyglot.t('UserBankInfoForm.accountName')}
              fieldValue={effectiveRecord.accountName}
              fieldStub="bankAccount.accountName"
            />
            <FieldStructure
              fieldTitle={polyglot.t('UserBankInfoForm.accountNumber')}
              fieldValue={effectiveRecord.accountNumber}
              fieldStub="bankAccount.accountNumber"
            />
            <FieldStructure
              fieldTitle={polyglot.t('UserBankInfoForm.bankName')}
              fieldValue={effectiveRecord.bankName}
              fieldStub="bankAccount.bankName"
            />
            <FieldStructure
              fieldTitle={polyglot.t('UserBankInfoForm.sCode')}
              fieldValue={effectiveRecord.sortCode}
              fieldStub="bankAccount.sortCode"
            />
            <FieldStructure
              fieldTitle={polyglot.t('UserBankInfoForm.country')}
              fieldValue={effectiveRecord.country}
              fieldStub="bankAccount.country"
            />
            <FieldStructure
              fieldTitle={polyglot.t('UserBankInfoForm.currency')}
              fieldValue={effectiveRecord.currency}
              fieldStub="bankAccount.currency"
            />
            {sortCustomFields(effectiveRecord.customUpdates).map(
              (f) =>
                !f.field.isHidden && (
                  <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                )
            )}
          </Box>
        )
      }
      drawerDetails={
        <ScopesControl scopes={[SCOPE]} context={scopesContext}>
          <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <BankForm
              initialValues={modalInitialValues}
              userId={userId}
              defaultName={defaultName}
              getUserBankAccounts={getUserBankAccounts}
              rowModalMode={modalInitialValues ? 'edit' : 'add'}
              handleSubmit={handleSubmit}
              setFormCurrentlyEditing={setFormCurrentlyEditing}
              setIsModalOpen={setIsModalOpen}
              setLoading={setLoading}
              loading={loading}
              onClose={() => setIsModalOpen(false)}
            />
          </DrawerModal>
        </ScopesControl>
      }
      addButtonTitle={polyglot.t('UserBankInfoForm.new')}
      tableRowData={[...data.entries]}
      tableColumn={columnData}
      missingInfoIcon={
        <ScopesControl scopes={['user:all']} context={scopesContext}>
          {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.BankAccount) && (
            <Box sx={hazardMissingField}>
              <Hazard {...hazardSize} />
            </Box>
          )}
        </ScopesControl>
      }
      editAction={
        effectiveRecord &&
        (() => {
          editBankRow(effectiveRecord);
        })
      }
    />
  );
};
