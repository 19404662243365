import type { SystemStyleObject } from '@mui/system';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const parentCardSx: SystemStyleObject = {
  maxWidth: { sm: '300px', md: '600px', lg: '600px' },
  paddingBottom: spacing.p60,
};

export const parentContainerSx = {
  width: '100%',
  display: 'flex',
  gap: spacing.gap80,
};

export const responsiveParentCardSx = {
  minWidth: { sm: '150px', md: '300px', lg: '300px' },
  width: '100%',
};

export const smallParentCardSx: SystemStyleObject = {
  maxWidth: { sm: '200px', md: '400px', lg: '400px' },
  minWidth: { sm: '200px', md: '400px', lg: '400px' },
};

export const settingCardSx: SystemStyleObject = {
  maxWidth: { sm: '300px', md: '600px', lg: '600px' },
};

export const cardTitleIconSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  ...spacing.mb10,
};

export const cardSubtitleSx: SystemStyleObject = {
  ...themeFonts.caption,
  ...spacing.mb30,
};

export const cardListSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  width: '65%',
};
export const settingsfieldSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 65,
  gap: spacing.g20,
};

export const fieldLabelSx: SystemStyleObject = {
  ...themeFonts.caption,
  width: '30%',
};

export const lockBoxSx: SystemStyleObject = {
  position: 'absolute',
  right: -40,
  top: 0,
};

export const lockIconSx: SystemStyleObject = {
  borderRadius: radius.br15,
  padding: '7px',
  backgroundColor: themeColors.DarkGrey,
  display: 'flex',
  justifyContent: 'center',
  width: '15px',
  height: '15px',
};

export const helperTextSx: SystemStyleObject = {
  ...themeFonts.captionSmall,
  marginTop: spacing.m5,
};

export const detailListSx: SystemStyleObject = { display: 'flex', flexDirection: 'column', margin: 0, padding: 0 };
export const detailSx: SystemStyleObject = {
  display: 'flex',
  marginBottom: spacing.m15,
  width: '100%',
  gap: spacing.g10,
};
export const detailTermSx: SystemStyleObject = {
  maxWidth: { sm: '90px', md: '190px', lg: '190px' },
  width: '100%',
  ...themeFonts.caption,
};
export const detailValueSx: SystemStyleObject = {
  maxWidth: { sm: '200px', md: '400px', lg: '400px' },
  width: '100%',
  ...themeFonts.title4,
};
export const detailHelperSx: SystemStyleObject = {
  marginTop: spacing.m5,
  ...themeFonts.captionSmall,
  color: themeColors.Grey,
};

export const buttonBoxSx: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: spacing.gap10,
};

export const buttonBoxDrawerSx: SystemStyleObject = {
  ...buttonBoxSx,
  mt: 'auto',
  pb: '20px',
};
