import { RequestDto, RequestStatus } from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import { themeColors } from '@/v2/styles/colors.styles';

export const getRequestStatusColor = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.Requested:
      return themeColors.orange;
    case RequestStatus.Completed:
      return themeColors.green;
    case RequestStatus.Scheduled:
      return themeColors.lightBlue;
    case RequestStatus.Rejected:
      return themeColors.darkRed;
    case RequestStatus.Failed:
      return themeColors.red;
    case RequestStatus.Cancelled:
      return themeColors.Grey;
  }
};

export const userCanRequestAppAccess = (requestList: readonly RequestDto[]): boolean => {
  return (
    requestList.length === 0 ||
    requestList.every(
      (eachRequest) =>
        eachRequest.status === RequestStatus.Cancelled ||
        eachRequest.status === RequestStatus.Rejected ||
        eachRequest.status === RequestStatus.Failed ||
        eachRequest.status === RequestStatus.Completed
    )
  );
};

export const userCanCancelAppAccessRequest = (requestList: readonly RequestDto[]): boolean => {
  return requestList.some((eachRequest) => eachRequest.status === RequestStatus.Requested);
};

export const filterByRequestStatus = (requestList: readonly RequestDto[], validValues: string[]) => {
  return requestList.filter((r) => r.status && validValues.includes(r.status));
};

export const filterByRequestedApp = (requestList: readonly RequestDto[], validValues: string[]) => {
  return requestList.filter((r) => r.requestInfo && validValues.includes(r.requestInfo.appStub));
};
