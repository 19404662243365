// TODO All of this errors could be validated using a runtime validation library for more specificity
import { AxiosError } from 'axios';
import Polyglot from 'node-polyglot';

export enum Errors {
  NOT_FOUND = 'NOT_FOUND',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  PASSWORD_LENGTH = 'PASSWORD_LENGTH',
  LEAKED_PASSWORD = 'LEAKED_PASSWORD',
  EXPIRED_PASSWORD_RESET = 'EXPIRED_PASSWORD_RESET',
}

// Nest error handler
export function nestErrorMessage(error: AxiosError<any> | undefined, polyglot?: Polyglot): string {
  console.error({ message: error?.message, response: error?.response });
  let rawMessage: string;
  if (error?.response?.data?.response?.message) rawMessage = JSON.stringify(error?.response?.data?.response?.message);
  else rawMessage = error?.response?.data?.message || error?.message || 'Something went wrong';

  if (polyglot) return polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: rawMessage });

  return rawMessage;
}
