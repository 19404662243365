import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateDomainTypes } from '@v2/infrastructure/i18n/translate.util';

import { ReactComponent as Apps } from '@/images/dashboard-icons/Apps.svg';
import { TodoCardVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/todo-card-version-small.component';
import { PendingRequestsSmallWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { iconSize } from '@/v2/styles/menu.styles';

export const AppTodoVersionSmall = ({ todo }: { todo: PendingRequestsSmallWidget }) => {
  const { polyglot } = usePolyglot();

  return (
    <TodoCardVersionSmall
      key={`${todo.domain}-req`}
      icon={<Apps {...iconSize} />}
      title={translateDomainTypes(todo.domain, polyglot)}
      countTitle={polyglot.t('AppTodoVersionSmall.requests', { smart_count: todo.count })}
    />
  );
};
