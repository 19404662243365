import { useCallback, useEffect, useRef, useState } from 'react';

import { OnboardingUserState } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { isPast, isToday } from 'date-fns';
import { generatePath, useHistory } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
// import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Clock } from '@/images/fields/Clock.svg';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as EnvelopeSimple } from '@/images/side-bar-icons/EnvelopeSimple.svg';
import { ReactComponent as Minus } from '@/images/side-bar-icons/Minus.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Reject.svg';
import { ONBOARDING_USER_STATUS_ROUTE, USER_OFFBOARDING_ROUTE } from '@/lib/routes';
import { AccountStatus, inviteUser } from '@/lib/users';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { AppIntegrationUserDto } from '@/v2/feature/app-integration/app-integration.dto';
import { DeviceOrderDto, DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { OffboardingDrawer } from '@/v2/feature/offboarding/components/offboarding-drawer.component';
import { OnboardNewEmployee } from '@/v2/feature/onboarding/components/onboard-new-employee.component';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useUserProfileConfig } from '@/v2/feature/user/features/user-profile/user-profile.hook';
import { UserProfileActionModal } from '@/v2/feature/user/features/user-profile-action/components/user-profile-action-modal.component';
import { UserProfileDeactivateModal } from '@/v2/feature/user/features/user-profile-action/components/user-profile-deactivate-modal.component';
import { UserProfileDeleteModal } from '@/v2/feature/user/features/user-profile-action/components/user-profile-delete-modal.component';
import { UserProfileActionAPI } from '@/v2/feature/user/features/user-profile-action/user-profile-action.api';
import {
  REGULAR_INVITE_STATUSES,
  UserProfileActionModes,
} from '@/v2/feature/user/features/user-profile-action/user-profile-action.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { createDateAsUTC } from '@/v2/util/date-format.util';

const iconSize = { width: '12px', height: '12px' } as const;

interface Props {
  userId: number;
  finallyRefreshUserObject?: (section: string) => Promise<void>;
}

export const UserProfileAction = ({ userId, finallyRefreshUserObject }: Props) => {
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(null);
  const [actionMode, setActionMode] = useState<UserProfileActionModes>();
  // const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const isAdmin = hasScopes(['user:all'], { userId: userId });
  const hasDevicesRead = hasScopes(['devices:read'], { userId: userId });
  const OFFBOARD_DEACTIVATE_STATUSES = [
    AccountStatus.Created,
    AccountStatus.Invited,
    AccountStatus.Active,
    AccountStatus.InvitedToOnboard,
  ];
  const [userDeviceDetails, setUserDeviceDetails] = useState<readonly DevicePossessionDto[]>([]);
  const [userDeviceOrders, setUserDeviceOrders] = useState<DeviceOrderDto[]>([]);
  const [userAppAccounts, setUserAppAccounts] = useState<AppIntegrationUserDto[]>([]);
  const [userInPayrun, setUserInPayrun] = useState(false);
  const [userIsBillingContact, setUserIsBillingContact] = useState(false);
  const [hasPayments, setHasPayments] = useState(false);
  const [deletionBlocked, setDeletionBlocked] = useState(false);
  const [deletionChecksLoading, setDeletionChecksLoading] = useState(false);
  const [deactivationChecksLoading, setDeactivationChecksLoading] = useState(false);
  const [deactivationBlocked, setDeactivationBlocked] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState<boolean>(false);
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState<boolean>(false);
  const { userOverviewConfig } = useUserProfileConfig(userId);
  const [onboardingUser, setOnboardingUser] = useState<OnboardingUserState | null>();
  const onboardNewUser = useRef<CachedUser>();
  const [offboardingModalOpen, setOffboardingModalOpen] = useState<boolean>(false);
  const [isOnboardNewUserOpen, setOnboardNewUserOpen] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);

  const fetchDeletionBlockers = async () => {
    try {
      if (isAdmin) {
        setDeletionChecksLoading(true);
        const {
          assignedDevices,
          activeDeviceOrders,
          appAccounts,
          payrollPayrunMembership,
          isBillingContact,
          hasPayments,
        } = await UserProfileActionAPI.getDeletionChecks(userId);

        setUserDeviceDetails(hasDevicesRead ? assignedDevices : []);
        setUserDeviceOrders(hasDevicesRead ? activeDeviceOrders : []);
        setUserAppAccounts(appAccounts);
        setUserInPayrun(payrollPayrunMembership.inPayrun);
        setUserIsBillingContact(isBillingContact);
        setHasPayments(hasPayments);
        setDeletionBlocked(
          assignedDevices.length > 0 ||
            activeDeviceOrders.length > 0 ||
            appAccounts.length > 0 ||
            payrollPayrunMembership.inPayrun ||
            isBillingContact
        );
      }
    } catch (error) {
      console.error('Encountered an error while trying to fetch deletion checks for user: ', error);
    } finally {
      setDeletionChecksLoading(false);
    }
  };

  const fetchDeactivationBlockers = async () => {
    try {
      if (isAdmin) {
        setDeactivationChecksLoading(true);
        const { assignedDevices, activeDeviceOrders } = await UserProfileActionAPI.getDeactivationChecks(userId);

        setUserDeviceDetails(hasDevicesRead ? assignedDevices : []);
        setUserDeviceOrders(hasDevicesRead ? activeDeviceOrders : []);
        setDeactivationBlocked(assignedDevices.length > 0 || activeDeviceOrders.length > 0);
      }
    } catch (error) {
      console.error('Encountered an error while trying to fetch deactivation checks for user: ', error);
    } finally {
      setDeactivationChecksLoading(false);
    }
  };

  const checkPasswordExists = useCallback(async () => {
    const passwordExists = await OnboardingAPI.checkPasswordExists(userId);
    setIsPasswordSet(passwordExists);
  }, [userId]);

  useEffect(() => {
    if ([AccountStatus.Invited, AccountStatus.InvitedToOnboard].includes(userOverviewConfig?.accountStatus)) {
      checkPasswordExists();
    }
  }, [checkPasswordExists, userOverviewConfig?.accountStatus]);

  const deleteProfileHandler = async () => {
    fetchDeletionBlockers();
    setDeleteUserModalOpen(true);
  };

  const deactivationProfileHandler = async () => {
    fetchDeactivationBlockers();
    setDeactivateUserModalOpen(true);
  };

  const openNewUserDrawer = useCallback(
    async (userId?: number) => {
      onboardNewUser.current = userId ? getCachedUserById(userId) : undefined;
      const additionalUserInfoForOnboarding = userId
        ? await UserAPI.getUserSummaryForOnboardingById(userId, false) // personal email is no longer in cachedUser - fetch separately
        : {};
      onboardNewUser.current = onboardNewUser.current
        ? {
            ...onboardNewUser.current,
            ...additionalUserInfoForOnboarding,
          }
        : onboardNewUser.current;
      setOnboardNewUserOpen(true);
    },
    [getCachedUserById]
  );

  const headerOptions = [
    {
      title: polyglot.t('UserProfileAction.workflows'),
      options: [
        ...(userOverviewConfig?.accountStatus === 'Created'
          ? [
              {
                icon: <Plus {...iconSize} />,
                handler: () => {
                  if (!onboardingUser?.template) {
                    openNewUserDrawer(userId);
                    return;
                  }
                  routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: userId }));
                },
                label: polyglot.t('UserProfileAction.onboard'),
                disabled: false,
              },
            ]
          : []),
        ...(OFFBOARD_DEACTIVATE_STATUSES.includes(userOverviewConfig?.accountStatus)
          ? [
              {
                icon: <Minus {...iconSize} />,
                handler: async () => await offboard(),
                label: polyglot.t('UserProfileAction.offboard'),
                disabled: false,
              },
            ]
          : []),
        ...(Boolean([AccountStatus.InvitedToOnboard].includes(userOverviewConfig?.accountStatus) && isPasswordSet)
          ? [
              {
                icon: <Clock {...iconSize} />,
                handler: () => routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: userId })),
                label: polyglot.t('UserProfileAction.progress'),
                disabled: false,
              },
            ]
          : []),
      ],
    },
    {
      title: polyglot.t('UserProfileAction.access'),
      options: [
        ...(Boolean(
          [AccountStatus.Invited, AccountStatus.InvitedToOnboard].includes(userOverviewConfig?.accountStatus) &&
            !isPasswordSet
        )
          ? [
              {
                icon: <EnvelopeSimple {...iconSize} />,
                handler: async () => await inviteUserWrapper(),
                label: polyglot.t('UserProfileAction.invite'),
                disabled: false,
              },
            ]
          : []),

        ...(OFFBOARD_DEACTIVATE_STATUSES.includes(userOverviewConfig?.accountStatus)
          ? [
              {
                icon: <Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />,
                handler: () => deactivationProfileHandler(),
                label: polyglot.t('UserProfileAction.deactivate'),
                disabled: false,
              },
            ]
          : []),

        {
          icon: <Trash {...iconSize} />,
          handler: () => deleteProfileHandler(),
          label: polyglot.t('General.delete'),
          disabled: userDeviceDetails.length > 0,
        },

        ...(deactivationDate &&
        userOverviewConfig?.accountStatus !== 'Deactivated' &&
        (!isPast(deactivationDate) || !isToday(deactivationDate))
          ? [
              {
                icon: <Cancel {...iconSize} fill={themeColors.Red} />,
                handler: () => setActionMode('cancelDeactivate'),
                label: polyglot.t('UserProfileAction.cancelDeactivation'),
                disabled: false,
              },
            ]
          : []),

        ...((deactivationDate && (isToday(deactivationDate) || isPast(deactivationDate))) ||
        userOverviewConfig?.accountStatus === AccountStatus.Deactivated
          ? [
              {
                icon: <Cancel {...iconSize} />,
                handler: () => setActionMode('reactivate'),
                label: polyglot.t('UserProfileAction.reactivate'),
                disabled: false,
              },
            ]
          : []),
      ],
    },
  ];

  useEffect(() => {
    userOverviewConfig?.deactivationDate
      ? setDeactivationDate(createDateAsUTC(userOverviewConfig?.deactivationDate))
      : setDeactivationDate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOverviewConfig.deactivationDate, userOverviewConfig.accountStatus, userId]);

  const offboard = async () => {
    if (userOverviewConfig.leaveDate)
      routerHistory.push({ pathname: generatePath(USER_OFFBOARDING_ROUTE, { userId: userId }) });
    else setOffboardingModalOpen(true);
  };

  const inviteUserWrapper = async () => {
    // const userBeingInvited = getCachedUserById(userId);
    // OLD RESTRICTION
    // if (
    //   userBeingInvited?.accountStatus &&
    //   !canInviteAgainStatuses.includes(userBeingInvited.accountStatus)
    //    && state.user?.restrictions?.PEOPLE?.disableInvitingMoreThanLimitedUsers
    // )
    // setUpgradeModalOpen(true);
    // else {
    if (REGULAR_INVITE_STATUSES.includes(userOverviewConfig?.accountStatus)) await inviteUser(userId, showMessage);
    else await inviteUser(userId, showMessage, 'onboard');
    // }
  };

  useEffect(() => {
    OnboardingAPI.getOnboardingUser(userId).then(setOnboardingUser);
  }, [userId]);

  return (
    <>
      {isAdmin && (
        <ScopesControl scopes={['user:all']} context={scopesContext} key="actions">
          <StyledMenuComponent
            headerOptions={headerOptions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: polyglot.t('UserProfileAction.title'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
          />

          <UserProfileDeleteModal
            isOpen={deleteUserModalOpen}
            setIsOpen={setDeleteUserModalOpen}
            userId={userId}
            loading={deletionChecksLoading}
            deletionBlocked={deletionBlocked}
            userDeviceDetails={userDeviceDetails}
            userDeviceOrders={userDeviceOrders}
            userAppAccounts={userAppAccounts}
            inPayrun={userInPayrun}
            isBillingContact={userIsBillingContact}
            hasPayments={hasPayments}
          />

          <UserProfileDeactivateModal
            isOpen={deactivateUserModalOpen}
            setIsOpen={setDeactivateUserModalOpen}
            userId={userId}
            loading={deactivationChecksLoading}
            deactivationBlocked={deactivationBlocked}
            refresh={async () => {
              if (finallyRefreshUserObject) await finallyRefreshUserObject('user.lifecycle');
            }}
          />

          <ScopesControl scopes={['user.onboard:manager']} context={{ userId }}>
            <OffboardingDrawer userId={userId} isOpen={offboardingModalOpen} setIsOpen={setOffboardingModalOpen} />
          </ScopesControl>

          <OnboardNewEmployee
            isOpen={isOnboardNewUserOpen}
            setIsOpen={setOnboardNewUserOpen}
            onClose={() => {
              if (setOnboardNewUserOpen) setOnboardNewUserOpen(false);
            }}
            initialValues={onboardNewUser.current}
            onDraftUserCreated={() => setOnboardNewUserOpen(false)}
          />

          <UserProfileActionModal
            userId={userId}
            actionMode={actionMode}
            setActionMode={setActionMode}
            deactivationDate={deactivationDate}
            refresh={() => (finallyRefreshUserObject ? finallyRefreshUserObject('user.basicInfo') : {})}
          />
        </ScopesControl>
      )}
    </>
  );
};
