import { useEffect, useState } from 'react';

import { UserBenefitPaymentDrawer } from '@v2/feature/benefits/components/user-benefit-payment-drawer.component';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { PendingBenefitPaymentTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const BenefitPaymentActionItem = ({
  pendingBenefitPaymentTodo,
  refresh,
  afterClose,
}: {
  pendingBenefitPaymentTodo: PendingBenefitPaymentTodo;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const { data } = useApiClient(
    CustomBenefitEndpoints.getCustomBenefitPaymentAndRemainingBalance(
      pendingBenefitPaymentTodo.customBenefitId,
      pendingBenefitPaymentTodo.userId,
      pendingBenefitPaymentTodo.userCustomBenefitId,
      pendingBenefitPaymentTodo.id
    ),
    { suspense: false }
  );
  const payment = data?.payment ?? null;
  const remainingBalance = data?.remainingBalance ?? null;

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsViewOpen(Boolean(pendingBenefitPaymentTodo));
  }, [pendingBenefitPaymentTodo]);

  return (
    <UserBenefitPaymentDrawer
      isOpen={isViewOpen}
      setIsOpen={setIsViewOpen}
      userBenefitPayment={payment}
      remainingBalance={remainingBalance}
      refresh={refresh}
      onClose={() => {
        setIsViewOpen(false);
      }}
      afterClose={afterClose}
    />
  );
};
