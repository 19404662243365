import { Box, Typography } from '@mui/material';
import { LocationCard } from '@v2/feature/device/components/devices-list-overview/location-card.component';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly devicePossession: DevicePossessionDto;
  readonly assignedUser?: CachedUser;
  readonly loading: boolean;
}

export const DeviceLocationPage = ({ devicePossession, assignedUser, loading }: Props) => {
  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Location</Typography>} />
      <ContentWrapper loading={loading}>
        <Box sx={{ width: '100%', height: '72vh' }}>
          <LocationCard device={devicePossession?.device!} assignedUser={assignedUser} />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
