import { Dispatch, SetStateAction, Suspense } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import {
  getPendingUsedFromAllowanceFromBreakdown,
  getUsedFromAllowanceFromBreakdown,
  getUsedFromBreakdown,
  getUsedFromPHAllowanceFromBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

const WIDTH_COLUMN1 = '80%';
const WIDTH_COLUMN2 = '20%';

interface UsedAllowanceDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

export const UsedAllowanceDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats,
}: UsedAllowanceDrawerProps) => {
  const { polyglot } = usePolyglot();
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box>
          <Typography variant="title2">{polyglot.t('AllowanceDrawer.usedAllowance')}</Typography>
          <UsedAllowanceDrawerContent
            userBalanceDetailedStats={userBalanceDetailedStats}
            absencePolicy={absencePolicy}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface AllowanceDrawerFieldProps {
  readonly title: string;
  readonly value: string;
  readonly boldTitle?: boolean;
}

const UsedDrawerField = ({ title, value, boldTitle = false }: AllowanceDrawerFieldProps) => (
  <Box sx={{ display: 'flex' }}>
    <Box sx={{ width: WIDTH_COLUMN1, display: 'flex' }}>
      <Typography variant={boldTitle ? 'title4' : 'caption'}>{title}</Typography>
    </Box>
    <Box sx={{ width: WIDTH_COLUMN2, display: 'flex', justifyContent: 'end' }}>
      <Typography variant="title4">{value}</Typography>
    </Box>
  </Box>
);

interface UsedAllowanceDrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
}

const UsedAllowanceDrawerContent = ({ absencePolicy, userBalanceDetailedStats }: UsedAllowanceDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];
  const usedFromMainAllowance = getUsedFromAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const usedFromPHAllowance = getUsedFromPHAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const hasPending = getPendingUsedFromAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);

  const totalUsed = getUsedFromBreakdown(absencePolicy, policyBreakdown, polyglot);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: spacing.m20 }}>
      <UsedDrawerField
        title={
          hasPending
            ? polyglot.t('AllowanceDrawer.usedFromAllowanceIncPending')
            : polyglot.t('AllowanceDrawer.usedFromAllowance')
        }
        value={usedFromMainAllowance}
      />
      <UsedDrawerField title={polyglot.t('AllowanceDrawer.usedFromPHAllowance')} value={usedFromPHAllowance} />
      <Divider style={{ marginBottom: spacing.m10 }} />

      <UsedDrawerField title={polyglot.t('AllowanceDrawer.usedTotal')} value={totalUsed} boldTitle />
    </Box>
  );
};
