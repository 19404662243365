import { AttendanceReportType } from '@v2/feature/reports/features/create-report/attendance/attendance-report-type.component';
import { PayrollReportType } from '@v2/feature/reports/features/create-report/payroll/payroll-report-type.component';
import { PeopleReportType } from '@v2/feature/reports/features/create-report/people/people-report-type.component';
import { TimeReportType } from '@v2/feature/reports/features/create-report/time/time-report-type.component';
import { DataDomain, FormDataInterface } from '@v2/feature/reports/reports.interface';

export const ReportType = (props: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  formData: FormDataInterface;
}) => {
  return (
    <>
      {{
        [DataDomain.Devices]: () => <></>,
        [DataDomain.Payroll]: () => <PayrollReportType />,
        [DataDomain.People]: () => <PeopleReportType {...props} />,
        [DataDomain.TimeAway]: () => <TimeReportType {...props} />,
        [DataDomain.Attendance]: () => <AttendanceReportType {...props} />,
      }[props.formData.domain]()}
    </>
  );
};
