import { Button, Typography, Stack, Theme, SxProps } from '@mui/material';

import { ReactComponent as Warning } from '@/images/side-bar-icons/MistakeOrange.svg';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { secondarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

type PayrollHMRCEmptyStateProps = {
  onConnectClick: () => void;
  sx?: SxProps<Theme>;
};

export const PayrollHMRCConnectState = ({ onConnectClick, sx }: PayrollHMRCEmptyStateProps) => {
  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader>HMRC connection</PayrollSettingSectionHeader>
      <Stack sx={{ flexFlow: 'row', gap: spacing.g5 }}>
        {<Warning {...actionIconSize} />}
        <Typography sx={themeFonts.caption}>No HMRC credentials set up</Typography>
      </Stack>
      <Button sx={{ ...secondarySmallBtn, mt: spacing.mt20, width: '140px' }} onClick={() => onConnectClick()}>
        Connect HMRC
      </Button>
    </Stack>
  );
};
