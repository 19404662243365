import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ListItemStyle = styled(Typography)(() => ({
  display: 'flex',
  width: '100%',
  height: '20px',
  alignItems: 'center',
  textTransform: 'capitalize',
  textDecoration: 'none',
  cursor: 'pointer',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    color: themeColors.Grey,
    fill: themeColors.Grey,
    borderRadius: 8,
  },
  '&:target': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    background: 'none',
    fill: themeColors.DarkGrey,
    borderRadius: 8,
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
}));

export const activeSubStyle = {
  ...themeFonts.title4,
  fill: themeColors.DarkGrey,
  '&:hover': {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    borderRadius: 8,
  },
};

export const ListMainItemStyle = styled(Box)(() => ({
  ...themeFonts.title4,
  position: 'relative',
  textTransform: 'capitalize',
  backgroundColor: 'none',
  textDecoration: 'none',
  color: themeColors.DarkGrey,
  '&:hover': {
    color: themeColors.ZeltYellow,
    background: 'none',
    fill: themeColors.ZeltYellow,
    borderRadius: '8px',
  },
  '&:focus': {
    color: themeColors.DarkGrey,
    background: 'none',
  },
}));

//mini side bar styles
export const MiniListItemStyle = styled(Typography)(() => ({
  display: 'flex',
  width: '100%',
  height: '20px',
  alignItems: 'center',
  textTransform: 'capitalize',
  justifyContent: 'center',
  textDecoration: 'none',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  marginBottom: spacing.m10,
  '&:hover': {
    color: themeColors.ZeltYellow,
    fill: themeColors.ZeltYellow,
    borderRadius: 8,
  },
  '&:target': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    background: 'none',
    fill: themeColors.DarkGrey,
    borderRadius: 8,
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
}));

//v2

export const NavItemStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '60px',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    color: themeColors.Grey,
    fill: themeColors.Grey,
    background: themeColors.GreyHover,
  },
  '&:active': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    background: themeColors.GreyPress,
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
}));

export const NavSubItemStyle = styled(Box)(() => ({
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textDecoration: 'none',
  cursor: 'pointer',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    borderRadius: radius.br15,
    cursor: 'pointer',
  },
  '&:target': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    background: 'none',
    fill: themeColors.DarkGrey,
    borderRadius: 8,
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
}));

export const SubItemStyle = styled(Typography)(() => ({
  display: 'flex',
  height: '20px',
  alignItems: 'center',
  textDecoration: 'none',
  paddingLeft: '10px',
  cursor: 'pointer',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  '&:hover': {
    borderRadius: 8,
  },
  '&:target': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    background: 'none',
    fill: themeColors.DarkGrey,
    borderRadius: 8,
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
}));

export const activeSubItemStyle = {
  ...themeFonts.title4,
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  borderRadius: radius.br15,
  '&:hover': {
    color: themeColors.DarkGrey,
    borderRadius: 8,
  },
};
