import React, { useEffect, useState } from 'react';

import { Autocomplete, Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionType, DeviceType } from '@v2/feature/device/device.interface';
import {
  DeviceOSAndroid,
  DeviceOSLinux,
  DeviceOSMacOS,
  DeviceOSValueLabelOptions,
  DeviceOSWindows,
  DeviceOSiOS,
  DeviceOSiPadOS,
} from '@v2/feature/device/device.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import {
  buttonSettingSx,
  nonLabelfieldSx,
} from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeFonts } from '@v2/styles/fonts.styles';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { CreateExistingDeviceDto, DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { cardTitleIconSx, settingCardSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const validationSchema = yup.object({
  assignToEmployee: yup.boolean().required(),
  userId: yup.number().nullable().notRequired(),
  siteId: yup.number().nullable().notRequired(),
  deviceName: yup.string().nullable().notRequired(),
  modelName: yup.string().required('Model name is required'),
  os: yup.string().required('Platform is required'),
  serialNumber: yup.string().required('Serial Number is required'),
  type: yup.string().required('Device type is required'),
});

interface ExistingDeviceFormData {
  assignToEmployee: boolean;
  userId: number | null;
  siteId: number | null;
  modelName: string;
  deviceName: string | null;
  serialNumber: string | null;
  os: string | null;
  type: DeviceType;
  isDep: boolean | null;
}

const MacOSModelNames = ['MacBook Air', 'MacBook Pro'];
const WindowsLinuxModelNames = [
  'Acer Aspire',
  'ASUS ExpertBook',
  'Dell Inspiron',
  'Dell Latitude',
  'Dell XPS',
  'HP Pro',
  'Lenovo ThinkPad',
  'Lenovo IdeaPad',
  'Microsoft Surface',
];
const IOSModelNames = ['iPhone'];
const IPadOSModelNames = ['iPad'];
const AndroidModelNames = ['Samsung Galaxy', 'Google Pixel', 'OnePlus', 'Oppo Find', 'Xiaomi'];
const DefaultModelNameOptions = [
  ...MacOSModelNames,
  ...WindowsLinuxModelNames,
  ...IOSModelNames,
  ...IPadOSModelNames,
  ...AndroidModelNames,
];

interface AboutDeviceContentProps {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setDevicePossession: React.Dispatch<React.SetStateAction<DevicePossessionDto | null>>;
  userId: number | undefined;
}

export const AboutDevice = ({ setProgress, setDevicePossession, userId }: AboutDeviceContentProps) => {
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);
  const routerHistory = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [modelNameOptions, setModelNameOptions] = useState<string[]>(DefaultModelNameOptions);

  const initialValues: ExistingDeviceFormData = {
    assignToEmployee: true,
    userId: userId ?? null,
    siteId: null,
    modelName: '',
    deviceName: '',
    serialNumber: '',
    os: '',
    type: DeviceType.Laptop,
    isDep: null,
  };

  const formik = useFormik<ExistingDeviceFormData>({
    initialValues,
    validationSchema,
    onSubmit: async (values: ExistingDeviceFormData) => addExistingDevice(values),
  });

  const addExistingDevice = async (values: ExistingDeviceFormData) => {
    try {
      if (!values.modelName) {
        showMessage('Device model name is required.', 'error');
        return;
      }
      if (!values.serialNumber) {
        showMessage('Serial Number is required.', 'error');
        return;
      }
      setLoading(true);
      const existingDeviceData: CreateExistingDeviceDto = {
        possessionType: values.assignToEmployee ? DevicePossessionType.User : DevicePossessionType.CompanySite,
        possessionId: values.assignToEmployee ? values.userId! : values.siteId!,
        modelName: values.modelName,
        serialNumber: !!values.serialNumber ? values.serialNumber : null,
        deviceName: !!values.deviceName ? values.deviceName : null,
        os: !!values.os ? values.os : null,
        type: values.type,
      };

      const newPossession = await DeviceAPI.addExistingDevice(existingDeviceData);
      setDevicePossession(newPossession);
      showMessage('Device added successfully.', 'success');
      setProgress(50);
    } catch (error) {
      showMessage(`Could not add the device. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            return { value: site.id, label: site.name, address: site.address ?? '' };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  const updateOsInputOption = (e: React.ChangeEvent<{ value: string; name: string }>) => {
    switch (e.target.value) {
      case DeviceOSMacOS.value:
        setModelNameOptions(MacOSModelNames);
        break;
      case DeviceOSWindows.value:
      case DeviceOSLinux.value:
        setModelNameOptions(WindowsLinuxModelNames);
        break;
      case DeviceOSiOS.value:
        setModelNameOptions(IOSModelNames);
        break;
      case DeviceOSiPadOS.value:
        setModelNameOptions(IPadOSModelNames);
        break;
      case DeviceOSAndroid.value:
        setModelNameOptions(AndroidModelNames);
        break;
      default:
        setModelNameOptions(DefaultModelNameOptions);
    }
    formik.handleChange(e);
  };

  return (
    <FormikProvider value={formik}>
      <Box sx={settingCardSx}>
        <Box sx={cardTitleIconSx}>
          <Typography sx={{ ...themeFonts.title2 }}>Tell us about your device</Typography>
        </Box>
        <Box sx={{ minHeight: 50, display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
          <Typography sx={{ ...themeFonts.caption, width: '40%', mt: 1 }}>Currently used by</Typography>
          {!userId ? (
            <Box sx={{ flex: 'display', flexDirection: 'column', gap: 1, width: '100%' }}>
              <CheckboxComponent
                name="assignToEmployee"
                label="Device in use by an employee"
                checked={formik.values.assignToEmployee}
                onChange={formik.handleChange}
                sx={{ mb: 1 }}
              />

              {formik.values.assignToEmployee ? (
                <Box sx={{ mb: 2 }}>
                  <SingleUserSelect
                    name="userId"
                    options="company"
                    onChange={(_, x) => {
                      const data = x as { value: number | undefined } | undefined;
                      formik.setFieldValue('userId', data?.value ?? null);
                    }}
                    value={formik.values.userId}
                    label=""
                    error={Boolean(formik.errors.userId)}
                    helperText={formik.errors.userId}
                  />
                </Box>
              ) : (
                <SelectComponent
                  name="siteId"
                  label="Site"
                  options={sitesOptions}
                  value={formik.values.siteId}
                  compareValue={formik.values.siteId}
                  error={!!formik.errors.siteId && formik.touched.siteId}
                  helperText={(formik.touched.siteId && formik.errors.siteId) as string}
                  onChange={async (e) => {
                    await formik.handleChange(e);
                    const siteAddress = sitesOptions.find((s) => s.value === e.target.value)?.address ?? '';
                    await formik.setFieldValue('receiverAddress', siteAddress);
                  }}
                />
              )}
            </Box>
          ) : (
            <Typography
              variant="body1"
              sx={{
                mt: 0.5,
                width: '100%',
                display: 'flex',
                gap: spacing.g5,
                ...themeFonts.title4,
                alignItems: 'center',
              }}
            >
              <UserAvatar userId={userId} size="xxsmall" />
              {getCachedUserById(userId)?.displayName ?? 'Unknown'}
            </Typography>
          )}
        </Box>

        <Box sx={{ ...nonLabelfieldSx, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ ...themeFonts.caption, width: '40%' }}>Platform</Typography>
          <SelectComponent
            name="os"
            label=""
            options={DeviceOSValueLabelOptions}
            value={formik.values.os ?? undefined}
            compareValue={formik.values.os ?? undefined}
            error={!!formik.errors.os && formik.touched.os}
            onChange={updateOsInputOption}
            helperText={formik.errors.os && formik.touched.os}
          />
        </Box>

        <Box sx={{ ...nonLabelfieldSx, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ ...themeFonts.caption, width: '40%' }}>Model Name</Typography>
          <Autocomplete
            id="model-name"
            freeSolo
            options={modelNameOptions}
            sx={{ width: '100%' }}
            onChange={(event) => {
              // eslint-disable-next-line
              const target = (event.target as unknown) as { textContent: string };
              formik.setFieldValue('modelName', target.textContent);
            }}
            value={formik.values.modelName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                name="modelName"
                value={formik.values.modelName ?? ''}
                onChange={formik.handleChange}
                placeholder="MacBook Air"
                fullWidth
                size="small"
                error={formik.touched.modelName && Boolean(formik.errors.modelName)}
                helperText={formik.touched.modelName && formik.errors.modelName}
              />
            )}
          />
        </Box>

        <Box sx={{ ...nonLabelfieldSx, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ ...themeFonts.caption, width: '40%' }}>Device Name</Typography>
          <TextfieldComponent
            name="deviceName"
            value={formik.values.deviceName}
            onChange={formik.handleChange}
            error={formik.touched.deviceName && Boolean(formik.errors.deviceName)}
            helperText={formik.touched.deviceName && formik.errors.deviceName}
            size="small"
            endAdornment="none"
            placeholder="My device"
            required
          />
        </Box>

        <Box sx={{ ...nonLabelfieldSx, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ ...themeFonts.caption, width: '40%' }}>Serial Number</Typography>
          <TextfieldComponent
            name="serialNumber"
            value={formik.values.serialNumber}
            onChange={formik.handleChange}
            error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
            helperText={formik.touched.serialNumber && formik.errors.serialNumber}
            size="small"
            endAdornment="none"
            placeholder="00000000000"
            required
          />
        </Box>
      </Box>
      <Box sx={{ ...buttonSettingSx, maxWidth: '30%', display: 'flex', justifyContent: 'flex-start', ...spacing.mt40 }}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" fullWidth onClick={() => routerHistory.goBack()}>
          Cancel
        </ButtonComponent>
        <LoaderButton
          colorVariant="primary"
          sizeVariant="medium"
          name="Save"
          fullWidth={true}
          loading={loading}
          onClick={() => formik.handleSubmit()}
          disabled={!formik.values.modelName}
        />
      </Box>
    </FormikProvider>
  );
};
