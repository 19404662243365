import { useCallback, useRef, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pick } from 'lodash';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Back } from '@/images/fields/Left.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_REQUEST_FORMS_ROUTE } from '@/lib/routes';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { StepperComponent } from '@/v2/components/stepper.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { ApprovalPage } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/approval.page';
import { FormBuilderPage } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/form-builder.page';
import { GeneralPage } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/general.page';
import { MembersPage } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/members.page';
import { PreviewPage } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/preview.page';
import {
  CreateRequestFormTemplate,
  RequestFormTemplate,
} from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsAPI } from '@/v2/feature/requests/request-forms.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { clamp } from '@/v2/util/array.util';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

export const RequestFormStepperSteps = ['General', 'Form', 'Approval', 'Members', 'Preview'] as const;

export type RequestFormStepperStep = typeof RequestFormStepperSteps[number];

export const NewRequestFormPage = ({ templateToEdit }: { templateToEdit?: RequestFormTemplate }) => {
  const { polyglot } = usePolyglot();
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), {
    suspense: false,
  });

  const [savingForm, setSavingForm] = useState(false);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const template = useRef<CreateRequestFormTemplate | RequestFormTemplate>(
    templateToEdit ?? {
      name: '',
      description: '',
      layout: { items: [] },
      approvalRuleId: 0,
      members: { userIds: [], filter: UserSelectFiltersOptions.None },
    }
  );

  const [step, setStep] = useState<RequestFormStepperStep>('General');
  const stepIndex = RequestFormStepperSteps.indexOf(step);

  const refreshApprovalRules = useCallback(async () => {
    if (refreshRules) await refreshRules();
  }, [refreshRules]);

  const performStep = useCallback(
    (direction: 'next' | 'back') => {
      const nexStepIndex = stepIndex + (direction === 'next' ? 1 : -1);
      setStep(clamp(RequestFormStepperSteps, nexStepIndex));
    },
    [stepIndex]
  );

  const saveEditedFormTemplate = useCallback(
    async (template: RequestFormTemplate) => {
      setSavingForm(true);
      try {
        return RequestFormsAPI.updateFormTemplate(template);
      } catch (error) {
        showMessage(
          polyglot.t('NewRequestFormPage.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setSavingForm(false);
      }
    },
    [showMessage, polyglot]
  );

  const saveNewFormTemplate = useCallback(
    async (template: CreateRequestFormTemplate) => {
      setSavingForm(true);
      try {
        return RequestFormsAPI.createFormTemplate(template);
      } catch (error) {
        showMessage(
          polyglot.t('NewRequestFormPage.errorMessages.creation', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setSavingForm(false);
      }
    },
    [showMessage, polyglot]
  );

  const saveRequestTemplate = useCallback(async () => {
    if ('id' in template.current) {
      return saveEditedFormTemplate(template.current);
    } else {
      return saveNewFormTemplate(template.current);
    }
  }, [saveEditedFormTemplate, saveNewFormTemplate]);

  const closeForm = useCallback(() => {
    routerHistory.replace(SETTINGS_REQUEST_FORMS_ROUTE);
  }, [routerHistory]);

  useEscapeKey(() => {
    // if nothing has been saved (ie the template has no name), allow the
    // escape key to close the form
    if (!template.current.name) {
      closeForm();
    }
  });

  return (
    <Stack sx={{ flex: 1, pt: spacing.s3 }}>
      <Stack sx={{ flexFlow: 'row', alignItems: 'start', px: spacing.px40, gap: spacing.g20 }}>
        <IconButton
          colorVariant="secondary"
          sizeVariant="small"
          style={{ visibility: stepIndex > 0 ? undefined : 'hidden' }}
          onClick={() => performStep('back')}
        >
          <Back />
        </IconButton>
        <StepperComponent
          steps={RequestFormStepperSteps}
          activeStep={step}
          sx={{ flex: 1, mt: spacing.s2, mx: spacing.s3 }}
        />
        <IconButton colorVariant="secondary" sizeVariant="small" onClick={() => closeForm()}>
          <Close fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
        </IconButton>
      </Stack>
      <Box sx={{ mt: spacing.s3 }} />
      <Box
        sx={{
          overflowY: 'auto',
          height: '100%',
          pb: spacing.s3,
        }}
      >
        {{
          General: () => (
            <GeneralPage
              initialValues={pick(template.current, ['name', 'description'])}
              onNext={({ name, description }) => {
                template.current.name = name;
                template.current.description = description;
                performStep('next');
              }}
            />
          ),
          Form: () => (
            <FormBuilderPage
              initialForm={template.current.layout}
              onNext={(values) => {
                template.current.layout = values;
                performStep('next');
              }}
            />
          ),
          Approval: () => (
            <ApprovalPage
              initialValues={template.current.approvalRuleId ?? null}
              onNext={(approvalRuleId: number) => {
                template.current.approvalRuleId = approvalRuleId;
                performStep('next');
              }}
              approvalRules={approvalRules ?? []}
              refreshApprovalRules={refreshApprovalRules}
            />
          ),
          Members: () => (
            <MembersPage
              initialValues={template.current.members}
              onNext={(values) => {
                template.current.members = values;
                performStep('next');
              }}
            />
          ),
          Preview: () => (
            <PreviewPage
              template={template.current}
              saving={savingForm}
              onNext={async () => {
                const form = await saveRequestTemplate();
                if (!form) return;
                closeForm();
              }}
              approvalRules={approvalRules ?? []}
            />
          ),
        }[step]()}
      </Box>
    </Stack>
  );
};
