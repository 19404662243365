import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { GridDisplayBarV2 } from '@v2/feature/growth/shared/components/grid-display-bar.component';
import { GrowthCircle } from '@v2/feature/growth/shared/components/growth-chip.component';
import {
  GrowthProgressChartPercentage,
  GrowthProgressLineChart,
} from '@v2/feature/growth/shared/components/growth-progress-chart.component';
import { QuestionCell } from '@v2/feature/growth/shared/components/question-cell.component';
import { MeasureFilterToLabel } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-cycle-detail-results.page';
import { SurveyResultByFactor } from '@v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { Dictionary } from 'lodash';

const getGrowthChartLinePercentLabel = (
  item: SurveyResultByFactor,
  measureByFilter: 'nps' | 'positive' | 'avg'
): string => {
  return `${
    measureByFilter === 'avg'
      ? item.normPercent ?? 0
      : measureByFilter === 'positive'
      ? item.positivePercent ?? 0
      : item.npsPercent ?? 0
  }%`;
};

const getGrowthChartLinePercentages = (
  item: SurveyResultByFactor,
  measureByFilter: 'nps' | 'positive' | 'avg'
): GrowthProgressChartPercentage[] => {
  const item1Value = measureByFilter === 'avg' ? item.normPercent ?? 0 : item.positivePercent ?? 0;
  const percentages: GrowthProgressChartPercentage[] = [
    {
      value: item1Value,
      color: measureByFilter === 'avg' ? 'blue' : 'green',
      showTooltip: measureByFilter === 'nps',
      label: measureByFilter === 'avg' ? MeasureFilterToLabel.avg : 'Positive sentiment',
    },
  ];

  if (measureByFilter !== 'avg')
    percentages.push({
      value: item.negativePercent ?? 0,
      color: 'red' as 'red',
      showTooltip: true,
      label: 'Negative sentiment',
    });
  return percentages;
};

interface SurveyResultsByFactorProps {
  readonly resultByFactor: SurveyResultByFactor[];
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly measureByFilter: 'nps' | 'positive' | 'avg';
}

export const SurveyResultsByFactor = ({
  resultByFactor,
  growthFactors,
  measureByFilter,
}: SurveyResultsByFactorProps) => {
  const { polyglot } = usePolyglot();
  const gridsXs = [6, 4];

  return (
    <Box>
      <GridDisplayBarV2
        key="header-by-factor"
        sx={{ mt: '16px' }}
        cells={[
          {
            content: (
              <Typography variant="caption" color="Grey">
                Factor
              </Typography>
            ),
            gridXs: gridsXs[0],
          },
          {
            content: (
              <Typography variant="caption" color="Grey">
                Score
              </Typography>
            ),
            gridXs: gridsXs[1],
          },
        ]}
      />

      {resultByFactor.map((item) => {
        return (
          <GridDisplayBarV2
            key={item.factor}
            cells={[
              {
                gridXs: gridsXs[0],
                content: (
                  <QuestionCell
                    text={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <GrowthCircle color={growthFactors[item.factor]?.color ?? themeColors.Grey} />
                        <Typography variant="caption" color="DarkGrey">
                          {item.factor}
                        </Typography>
                      </Box>
                    }
                    chips={[
                      {
                        text: polyglot.t('SurveyResult.noOfQuestions', { smart_count: item.noOfQuestions }),
                      },
                    ]}
                  />
                ),
              },
              {
                gridXs: gridsXs[1],
                content: (
                  <GrowthProgressLineChart
                    label={getGrowthChartLinePercentLabel(item, measureByFilter)}
                    labelVariant="title4"
                    percentJustifyContent="space-between"
                    percentages={getGrowthChartLinePercentages(item, measureByFilter)}
                  />
                ),
              },
            ]}
          />
        );
      })}
    </Box>
  );
};
