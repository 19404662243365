import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ChangeOwnerDeviceTransitDataDto, DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeliveryMethod, DevicePossessionType } from '@v2/feature/device/device.interface';
import { DeliveryMethodsOptions } from '@v2/feature/device/device.util';
import { UserAddressAPI } from '@v2/feature/user/features/user-forms/user-address/user-address.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { formatAddress } from '@v2/util/user-data.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';

interface ChangeOwnerUserFormProps {
  readonly currentDevicePossession: DevicePossessionDto;
  readonly changeOwner: (
    currentDevicePossessionId: number,
    transitData: ChangeOwnerDeviceTransitDataDto
  ) => Promise<void>;
}

export const ChangeOwnerUserForm = ({ currentDevicePossession, changeOwner }: ChangeOwnerUserFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [senderAddress, setSenderAddress] = useState<string>('');
  const [receiverAddress, setReceiverAddress] = useState<string>('');
  const [customAccountName, setCustomAccountName] = useState<boolean>(false);

  const { hasScopes, getScopesContext } = useScopes();

  const [showMessage] = useMessage();

  const validationSchema = yup.object({
    deliveryMethod: yup.string().required(),
    possessionId: yup.number().required('New user is required'),
    senderAddress: yup.string().when('deliveryMethod', {
      is: DeliveryMethod.ShippingToEmployee,
      then: (schema) => schema.required('Sender Address is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    deliveryAddress: yup.string().when('deliveryMethod', {
      is: DeliveryMethod.ShippingToEmployee,
      then: (schema) => schema.required('Sender Address is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    accountName: yup.string().nullable(),
  });

  const formik = useFormik<ChangeOwnerDeviceTransitDataDto>({
    initialValues: {
      deliveryMethod: DeliveryMethod.ShippingToEmployee,
      possessionId: 0,
      senderAddress,
      deliveryAddress: '',
      accountName: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const { deliveryMethod, possessionId, senderAddress, deliveryAddress, accountName } = values;
      if (
        currentDevicePossession.possessionType === DevicePossessionType.User &&
        currentDevicePossession.possessionId === possessionId
      ) {
        showMessage('The sender and the receiver should not be the same.', 'error');
        return;
      }

      if (!possessionId) {
        showMessage('No receiver has been selected.', 'error');
        return;
      }

      const transitData: ChangeOwnerDeviceTransitDataDto = {
        deliveryMethod,
        possessionId,
        senderAddress: deliveryMethod === DeliveryMethod.ShippingToEmployee ? senderAddress : null,
        deliveryAddress: deliveryMethod === DeliveryMethod.ShippingToEmployee ? deliveryAddress : null,
        accountName,
      };

      setLoading(true);
      await changeOwner(currentDevicePossession.id, transitData);
      setLoading(false);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const fetchOwnerAddress = useCallback(
    async (devicePossession: DevicePossessionDto): Promise<string> => {
      try {
        if (devicePossession.possessionType === DevicePossessionType.User) {
          const hasReadAddressScope = hasScopes(
            ['user.address:read'],
            getScopesContext({ userId: devicePossession.possessionId })
          );
          if (!hasReadAddressScope) return '';

          const userAddress = await UserAddressAPI.findByUserId(devicePossession.possessionId);
          if (userAddress && userAddress?.effectiveRecord) {
            const effectiveAddress = userAddress.effectiveRecord;
            return formatAddress(effectiveAddress);
          }
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }

      return '';
    },
    [getScopesContext, hasScopes, showMessage]
  );

  const fetchReceiverAddress = useCallback(
    async (possessionId: number): Promise<string> => {
      try {
        const hasReadAddressScope = hasScopes(['user.address:read'], getScopesContext({ userId: possessionId }));
        if (!hasReadAddressScope) return '';

        const userAddress = await UserAddressAPI.findByUserId(possessionId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          return formatAddress(effectiveAddress);
        }
      } catch (error) {
        console.error('Could not retrieve user address.');
      }

      return '';
    },
    [getScopesContext, hasScopes]
  );

  const setOwnerAddress = useCallback(async () => {
    const ownerAddress = await fetchOwnerAddress(currentDevicePossession);
    setSenderAddress(ownerAddress);
  }, [fetchOwnerAddress, currentDevicePossession]);

  useEffect(() => {
    (async () => {
      await setOwnerAddress();
    })();
  }, [setOwnerAddress]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <SingleUserSelect
          name="userId"
          options="company"
          value={formik.values.possessionId}
          onChange={async (_, x: unknown) => {
            const userId = (x as { value: number })?.value ?? null;
            await formik.setFieldValue('possessionId', userId);
            if (userId) {
              const receiverAddress = await fetchReceiverAddress(userId);
              setReceiverAddress(receiverAddress);
              if (formik.values.deliveryMethod !== DeliveryMethod.Handover) {
                await formik.setFieldValue('deliveryAddress', receiverAddress);
              }
            }
          }}
          label="Employee"
          error={Boolean(formik.errors.possessionId)}
          helperText={formik.errors.possessionId}
        />

        <SelectComponent
          name="deliveryMethod"
          label="Delivery method"
          // Hide the handover option here
          options={DeliveryMethodsOptions.filter((o) => o.value !== DeliveryMethod.ShippingToSite)}
          value={formik.values.deliveryMethod}
          compareValue={formik.values.deliveryMethod}
          error={!!formik.errors.deliveryMethod && formik.touched.deliveryMethod}
          onChange={(e) => {
            formik.handleChange(e);

            if (e.target?.value === DeliveryMethod.Handover) {
              formik.setFieldValue('deliveryAddress', 'Handover');
              formik.setFieldValue('senderAddress', 'Handover');
            } else {
              formik.setFieldValue('senderAddress', senderAddress ?? '');
              formik.setFieldValue('deliveryAddress', receiverAddress ?? '');
            }
          }}
          helperText={(formik.touched.deliveryMethod && formik.errors.deliveryMethod) as string}
        />

        {formik.values.deliveryMethod === DeliveryMethod.Handover && (
          <CheckboxComponent
            name="customAccountName"
            label="Custom device account name"
            labelTooltip="Set a custom device account name for a device controlled by automated enrolment. If not set user email will be used."
            checked={customAccountName}
            onChange={async (e, checked) => {
              setCustomAccountName(checked);
              if (!checked) {
                await formik.setFieldValue('accountName', null);
              }
            }}
          />
        )}
        {formik.values.deliveryMethod === DeliveryMethod.Handover && customAccountName && (
          <TextfieldComponent
            label="Device account name for automated enrolment device"
            name="accountName"
            value={formik.values.accountName}
            onChange={formik.handleChange}
            error={formik.touched.accountName && Boolean(formik.errors.accountName)}
            helperText={formik.touched.accountName && formik.errors.accountName}
            size="small"
            endAdornment="none"
            placeholder="TestAccount"
            tooltip={
              'Set a custom account name for a device controlled by automated enrolment. If not set user email will be used.'
            }
          />
        )}
        {formik.values.deliveryMethod === DeliveryMethod.ShippingToEmployee && (
          <>
            <TextfieldComponent
              label="Delivery Address"
              name="deliveryAddress"
              value={formik.values.deliveryAddress}
              onChange={formik.handleChange}
              error={formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress)}
              helperText={formik.touched.deliveryAddress && formik.errors.deliveryAddress}
              fullWidth
              size="small"
              endAdornment="none"
            />

            <TextfieldComponent
              label="Sender Address"
              name="senderAddress"
              value={formik.values.senderAddress}
              onChange={formik.handleChange}
              error={formik.touched.senderAddress && Boolean(formik.errors.senderAddress)}
              helperText={formik.touched.senderAddress && formik.errors.senderAddress}
              fullWidth
              size="small"
              endAdornment="none"
            />
          </>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Change owner"
            colorVariant="primary"
            sizeVariant="medium"
            type="submit"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
