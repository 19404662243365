import { useState } from 'react';

import { Box } from '@mui/material';
import { CurrencySelect } from '@v2/components/currency-select.component';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CountrySelect } from '@/v2/components/country-select.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const validationSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    legalName: Yup.string().trim().required(polyglot.t('CompanyEntityDrawerPage.errorMessages.enterLegalName')),
    address: Yup.object().shape({
      addressLine1: Yup.string()
        .trim()
        .required(polyglot.t('CompanyEntityDrawerPage.errorMessages.enterRegisteredAddress')),
      addressLine2: Yup.string().notRequired(),
      city: Yup.string().trim().required(polyglot.t('CompanyEntityDrawerPage.errorMessages.enterRegisteredAddress')),
      postCode: Yup.string()
        .trim()
        .required(polyglot.t('CompanyEntityDrawerPage.errorMessages.enterRegisteredAddress')),
      countryCode: Yup.string().required(polyglot.t('CompanyEntityDrawerPage.errorMessages.selectCountry')),
    }),
    nationalId: Yup.string().trim().nullable().optional(),
    taxId: Yup.string().trim().nullable().optional(),
    isDefaultBillingEntity: Yup.boolean().notRequired(),
    currency: Yup.string()
      .typeError(polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
  });

interface CompanyEntityDrawerPageProps {
  entity: CompanyUnitDto | null;
  close: (updated: boolean) => void;
}

export const CompanyEntityDrawerPage = ({ entity, close }: CompanyEntityDrawerPageProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      legalName: '',
      nationalId: null,
      taxId: null,
      ...entity,
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postCode: '',
        countryCode: '',
        ...entity?.address,
      },
      isDefaultBillingEntity: !!entity?.isDefaultBillingEntity,
      currency: entity?.currency ?? 'GBP',
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: async (values): Promise<void> => {
      try {
        setIsSubmitting(true);
        if (entity) {
          await CompanyAPI.updateEntity({ id: entity.id, ...values });
        } else {
          await CompanyAPI.createEntity(values);
        }
        close(true);
      } catch (error) {
        showMessage(
          `Failed to ${
            entity
              ? polyglot.t('CompanyEntityDrawerPage.errorMessages.update')
              : polyglot.t('CompanyEntityDrawerPage.errorMessages.save')
          } entity: ${nestErrorMessage(error)}`,
          'error'
        );
        setIsSubmitting(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {entity ? polyglot.t('CompanyEntityDrawerPage.edit') : polyglot.t('CompanyEntityDrawerPage.add')}
        </Typography>

        <TextfieldComponent
          name="legalName"
          label={polyglot.t('CompanyEntityDrawerPage.legalName')}
          value={formik.values.legalName}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.legalName}
          helperText={hasSubmitted && formik.errors.legalName}
          clearText={() => formik.setFieldValue('legalName', '')}
          autoFocus
        />

        <CountrySelect
          name={'country'}
          onChange={(_country, isoCode) => formik.setFieldValue('address.countryCode', isoCode)}
          value={formik.values.address.countryCode}
          valueType="iso-code"
          disabled={isSubmitting}
          helperText={hasSubmitted && formik.errors.address?.countryCode}
          error={hasSubmitted && !!formik.errors.address?.countryCode}
        />

        <TextfieldComponent
          name="address.addressLine1"
          label={polyglot.t('CompanyEntityDrawerPage.line1')}
          value={formik.values.address.addressLine1}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.address?.addressLine1}
          helperText={hasSubmitted && formik.errors.address?.addressLine1}
          clearText={() => formik.setFieldValue('address.addressLine1', '')}
        />

        <TextfieldComponent
          name="address.addressLine2"
          label={polyglot.t('CompanyEntityDrawerPage.line2')}
          value={formik.values.address.addressLine2}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.address?.addressLine2}
          helperText={hasSubmitted && formik.errors.address?.addressLine2}
          clearText={() => formik.setFieldValue('address.addressLine2', '')}
        />

        <TextfieldComponent
          name="address.city"
          label={polyglot.t('CompanyEntityDrawerPage.city')}
          value={formik.values.address.city}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.address?.city}
          helperText={hasSubmitted && formik.errors.address?.city}
          clearText={() => formik.setFieldValue('address.city', '')}
        />

        <TextfieldComponent
          name="address.postCode"
          label={polyglot.t('CompanyEntityDrawerPage.postcode')}
          value={formik.values.address.postCode}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.address?.postCode}
          helperText={hasSubmitted && formik.errors.address?.postCode}
          clearText={() => formik.setFieldValue('address.postCode', '')}
        />

        <TextfieldComponent
          name="nationalId"
          label={polyglot.t('CompanyEntityDrawerPage.companyid')}
          value={formik.values.nationalId}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.nationalId}
          helperText={hasSubmitted && formik.errors.nationalId}
          clearText={() => formik.setFieldValue('nationalId', '')}
        />

        <TextfieldComponent
          name="taxId"
          label={polyglot.t('CompanyEntityDrawerPage.vat')}
          value={formik.values.taxId}
          type="text"
          disabled={isSubmitting}
          onChange={formik.handleChange}
          error={hasSubmitted && !!formik.errors.taxId}
          helperText={hasSubmitted && formik.errors.taxId}
          clearText={() => formik.setFieldValue('taxId', '')}
        />

        <CurrencySelect
          name="currency"
          onChange={(currency) => {
            formik.setFieldValue('currency', currency);
          }}
          value={formik.values.currency}
          helperText={hasSubmitted && formik.errors.currency}
          error={hasSubmitted && !!formik.errors.currency}
        />

        <CheckboxComponent
          label={polyglot.t('CompanyEntityDrawerPage.isDefaultBillingEntity')}
          name="isDefaultBillingEntity"
          checked={formik.values.isDefaultBillingEntity}
          onChange={formik.handleChange}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            sizeVariant="medium"
            fullWidth
            colorVariant="secondary"
            onClick={() => close(false)}
            disabled={isSubmitting}
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            fullWidth
            loading={isSubmitting}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
