import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as FormIcon } from '@/images/dashboard-icons/Form.svg';
import { RequestFormActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/request-form-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { RequestFormTodo, SubmittedRequestsBigWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const SubmittedRequestBigTodo = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: SubmittedRequestsBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<RequestFormTodo | undefined>(undefined);

  return (
    <Box>
      {todo.requests.map((a, idx) => {
        return (
          <TodoCard
            key={`submitted-request-todo-${a.id}`}
            icon={<FormIcon {...iconSize} />}
            title={
              <Typography
                variant="caption"
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {polyglot.t('SubmittedRequestBigTodo.formName', { formName: a.formName })}
              </Typography>
            }
            actionOnclick={() => setSelectedRow(a)}
            showBorder={!(setLastChild && todo.requests.length === idx + 1)}
            userAvatar={<UserAvatar userId={a.userId} size="xxxsmall" />}
          />
        );
      })}

      {selectedRow && (
        <RequestFormActionItem
          requestFormRow={selectedRow}
          refresh={refreshTodos}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
