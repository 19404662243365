import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@v2/components/divider.component';
import { formatMoney, formatPercentage } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { SubscriptionBill } from '@/models/invoice.model';
import { Plan, Product, ProductPlanMapToLabel, SubscriptionBillingStats } from '@/models/subscription.model';

interface ActivePlanDetails {
  plan: SubscriptionBill | null;
  type: Plan;
}

const InitialSubscriptionStats = {
  [Product.PEOPLE]: { plan: null, type: Plan.FREE },
  [Product.MONEY]: { plan: null, type: Plan.FREE },
  [Product.APPS]: { plan: null, type: Plan.FREE },
  [Product.DEVICES]: { plan: null, type: Plan.FREE },
  totalCost: 0,
  noOfActiveUsers: 1,
  hasDiscounts: false,
};

const getDiscountPercentageFromPlan = (plan: SubscriptionBill | null): string | undefined => {
  const discountRatio =
    plan && plan.subscriptionCost && plan.totalDiscount ? plan.totalDiscount / plan.subscriptionCost : 0;

  if (discountRatio === 0) return undefined;

  return formatPercentage(100 * discountRatio, 0);
};

const getSubscriptionStats = (
  subscriptionsBillingStats: SubscriptionBillingStats
): {
  [key in Product]: ActivePlanDetails;
} & {
  totalCost: number;
  noOfActiveUsers: number;
  hasDiscounts: boolean;
} => {
  return Object.keys(subscriptionsBillingStats).reduce(
    (acc, key) => {
      const activePlan = subscriptionsBillingStats
        ? subscriptionsBillingStats[Number(key) as Product].find((plan) => plan.planPrice > 0) ??
          subscriptionsBillingStats[Number(key) as Product][0] ??
          null
        : null;
      const activePlanType = activePlan && activePlan?.planPrice > 0 ? Plan.PRO : Plan.FREE;

      if (!activePlan) return acc;
      acc = {
        ...acc,
        [key]: {
          plan: activePlan,
          type: activePlanType,
        },
        totalCost: acc.totalCost + (activePlan.subscriptionCost ?? 0) - (activePlan.totalDiscount ?? 0),
        noOfActiveUsers:
          activePlan.noOfActiveUsers > acc.noOfActiveUsers ? activePlan.noOfActiveUsers : acc.noOfActiveUsers,
        hasDiscounts: Boolean(acc.hasDiscounts || activePlan.totalDiscount > 0),
      };
      return acc;
    },
    { ...InitialSubscriptionStats }
  );
};

interface TableRowProps {
  readonly column1: string;
  readonly plan?: Plan;
  readonly column2?: string | undefined;
  readonly column3: string;
  readonly showDivider?: boolean;
  readonly boldModule?: boolean;
  readonly boldPrice?: boolean;
}

const TableRow = ({
  column1,
  plan,
  column3,
  column2 = '',
  showDivider = false,
  boldModule = false,
  boldPrice = false,
}: TableRowProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: showDivider ? '50px' : '40px',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ width: '40%', ...(boldModule ? themeFonts.title4 : themeFonts.caption) }}>
          {column1}
          {plan && (
            <Typography
              component="span"
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                ml: spacing.m5,
              }}
            >
              {plan === Plan.FREE ? 'Free' : 'Pro'}
            </Typography>
          )}
        </Typography>
        {column2 && <Typography sx={{ width: '40%', ...themeFonts.caption }}>{column2}</Typography>}
        <Typography sx={{ width: '70px', ...(boldPrice ? themeFonts.title4 : themeFonts.caption) }}>
          {column3}
        </Typography>
      </Box>
      {showDivider && <Divider />}
    </>
  );
};

interface SubscriptionsTableProps {
  subscriptionsBillingStats: SubscriptionBillingStats | null;
}

export const SubscriptionsTable = ({ subscriptionsBillingStats }: SubscriptionsTableProps) => {
  const { polyglot } = usePolyglot();

  const subscriptionsStats = subscriptionsBillingStats
    ? getSubscriptionStats(subscriptionsBillingStats)
    : { ...InitialSubscriptionStats };

  const activePeoplePlan = subscriptionsStats[Product.PEOPLE].plan;
  const activePeoplePlanType = subscriptionsStats[Product.PEOPLE].type;

  const activeMoneyPlan = subscriptionsStats[Product.MONEY].plan;
  const activeMoneyPlanType = subscriptionsStats[Product.MONEY].type;

  const activeAppsPlan = subscriptionsStats[Product.APPS].plan;
  const activeAppsPlanType = subscriptionsStats[Product.APPS].type;

  const activeDevicesPlan = subscriptionsStats[Product.DEVICES].plan;
  const activeDevicesPlanType = subscriptionsStats[Product.DEVICES].type;

  const totalCost = subscriptionsStats.totalCost;
  const noOfActiveUsers = subscriptionsStats.noOfActiveUsers;

  const hasDiscounts = subscriptionsStats.hasDiscounts;

  const peopleDiscount = getDiscountPercentageFromPlan(activePeoplePlan);
  const moneyDiscount = getDiscountPercentageFromPlan(activeMoneyPlan);
  const appsDiscount = getDiscountPercentageFromPlan(activeAppsPlan);
  const devicesDiscount = getDiscountPercentageFromPlan(activeDevicesPlan);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '40px', alignItems: 'center' }}>
        <Typography sx={{ width: '40%', ...themeFonts.caption, color: themeColors.Grey }}>
          {' '}
          {polyglot.t('SubscriptionsTable.module')}
        </Typography>
        {hasDiscounts && (
          <Typography sx={{ width: '40%', ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('SubscriptionsTable.discount')}
          </Typography>
        )}
        <Typography sx={{ width: '70px', ...themeFonts.caption, color: themeColors.Grey }}>
          {polyglot.t('SubscriptionsTable.listPrice')}
        </Typography>
      </Box>
      <Divider />
      <TableRow
        column1={ProductPlanMapToLabel(polyglot)[Product.PEOPLE]}
        plan={activePeoplePlanType}
        column2={peopleDiscount}
        column3={formatMoney({ amount: activePeoplePlan?.planPrice ?? 0 })}
        showDivider
      />
      <TableRow
        column1={ProductPlanMapToLabel(polyglot)[Product.MONEY]}
        plan={activeMoneyPlanType}
        column2={moneyDiscount}
        column3={formatMoney({ amount: activeMoneyPlan?.planPrice ?? 0 })}
        showDivider
      />
      <TableRow
        column1={ProductPlanMapToLabel(polyglot)[Product.APPS]}
        plan={activeAppsPlanType}
        column2={appsDiscount}
        column3={formatMoney({ amount: activeAppsPlan?.planPrice ?? 0 })}
        showDivider
      />
      <TableRow
        column1={ProductPlanMapToLabel(polyglot)[Product.DEVICES]}
        plan={activeDevicesPlanType}
        column2={devicesDiscount}
        column3={formatMoney({ amount: activeDevicesPlan?.planPrice ?? 0 })}
        showDivider
      />
      <TableRow
        column1={polyglot.t('SubscriptionsTable.subscriptionFeePerUser')}
        column3={formatMoney({ amount: totalCost / noOfActiveUsers })}
        boldPrice
      />
      <TableRow
        column1={polyglot.t('SubscriptionsTable.activeUsers')}
        column3={`${noOfActiveUsers} user${noOfActiveUsers === 1 ? '' : 's'}`}
        boldPrice
      />
      <TableRow
        column1={polyglot.t('SubscriptionsTable.subscriptionFee')}
        column3={formatMoney({ amount: totalCost })}
        boldModule
        boldPrice
      />
    </Box>
  );
};
