import { useCallback, useContext, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus } from '@v2/feature/device/device.interface';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TECH_ROUTE } from '@/lib/routes';
import { themeColors } from '@/v2/styles/colors.styles';

interface DeviceActivationStepTLCProps {
  readonly deviceOrder: DeviceOrderDto;
}

export const DeviceActivationStepTLC = ({ deviceOrder }: DeviceActivationStepTLCProps) => {
  const [errorOnConfirming, setErrorOnConfirming] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const history = useHistory();

  const [state, dispatch] = useContext(GlobalContext);

  const setDeviceDelivered = useCallback(async () => {
    let isDeliveryConfirmed = false;
    try {
      if (deviceOrder.status === DeviceOrderStatus.Delivered) {
        history.push(TECH_ROUTE);
        return;
      }
      setLoading(true);
      await DeviceAPI.confirmDeviceOrderDelivery(deviceOrder.id, new LocalDate().toDateString());
      showMessage('Delivery confirmed successfully.', 'success');
      isDeliveryConfirmed = true;

      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      history.push(TECH_ROUTE);
    } catch (error) {
      if (isDeliveryConfirmed) {
        showMessage(
          'Could not update sidebar alerts. Refresh the page please to get the latest state of the page.',
          'error'
        );
      } else {
        setErrorOnConfirming(true);
        showMessage(`Could not confirm delivery. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceOrder, showMessage, state.user.userId]);

  return (
    <ContentWrapper loading={loading} noHeader border={false}>
      <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', gap: spacing.gap20 }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            Take good care of your device. Keep it clean, dry and safe.
          </Typography>
          <Box>
            <LoaderButton
              name={errorOnConfirming ? 'Retry' : 'All done!'}
              colorVariant="primary"
              sizeVariant="small"
              onClick={setDeviceDelivered}
              loading={loading}
            ></LoaderButton>
          </Box>
        </Box>
      </Box>
    </ContentWrapper>
  );
};
