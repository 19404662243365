import { useCallback, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type DisableMFAProps = {
  onClose(): void;
};

export const DisableMFAPage = ({ onClose }: DisableMFAProps) => {
  const { polyglot } = usePolyglot();

  const [disablingMFA, setDisablingMFA] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');

  const checkPasswordAndDisableMFA = useCallback(
    (pwd: string) => {
      if (!pwd) return;
      (async () => {
        setDisablingMFA(true);
        setErrorMsg('');
        try {
          await AuthAPI.updateMFA({ disableMFAPwd: pwd });
          onClose();
        } catch (err) {
          if ((err as any).response?.status === 401) {
            setErrorMsg(polyglot.t('DisableMFAPage.incorrectPassword'));
          } else {
            setErrorMsg((err as Error).toString());
          }
        } finally {
          setDisablingMFA(false);
        }
      })();
    },
    [polyglot, onClose]
  );

  return (
    <form
      onSubmit={(e) => {
        checkPasswordAndDisableMFA(password);
        e.preventDefault();
      }}
    >
      <Stack sx={{ mb: '30px' }}>
        <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('DisableMFAPage.pleaseEnterPassword')}</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
          {polyglot.t('DisableMFAPage.toDisable')}
        </Typography>
      </Stack>
      <TextfieldComponent
        type="password"
        disabled={disablingMFA}
        onChange={(e) => {
          setPassword(e.target.value);
          setErrorMsg('');
        }}
        value={password}
        error={!!errorMsg}
        helperText={errorMsg}
        autoFocus
      />
      <LoaderButton
        name={polyglot.t('General.continue')}
        sizeVariant="medium"
        loading={disablingMFA}
        colorVariant="primary"
        type="submit"
        fullWidth
        style={{ marginTop: '40px !important' }}
      />
    </form>
  );
};
