import { Dispatch, SetStateAction } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@/v2/styles/spacing.styles';

interface CalendarSettingsSyncDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly syncAction: () => void;
  readonly appName: string;
}

export const CalendarSettingsSyncDrawer = ({
  isOpen,
  setIsOpen,
  syncAction,
  appName,
}: CalendarSettingsSyncDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <CalendarSettingsSyncDrawerContent syncAction={syncAction} appName={appName} />
    </DrawerModal>
  );
};

interface CalendarSettingsSyncDrawerContentProps {
  readonly syncAction: () => void;
  readonly appName: string;
}

export const CalendarSettingsSyncDrawerContent = ({ syncAction, appName }: CalendarSettingsSyncDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Box>
      <Typography sx={titleSx}>{polyglot.t('CalendarSettingsPage.resyncCalendar')}</Typography>
      <Typography sx={{ ...themeFonts.caption, mb: spacing.mb30 }}>
        {polyglot.t('CalendarSettingsPage.resyncDrawerBody', { appName })}
      </Typography>
      <ButtonComponent fullWidth sizeVariant="large" colorVariant="primary" onClick={syncAction}>
        {polyglot.t('CalendarSettingsPage.confirmDrawer')}
      </ButtonComponent>
    </Box>
  );
};
