import { useCallback, useState } from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { downloadFileByUrl } from '@/v2/feature/documents/documents.util';
import { IDVerificationResultDto } from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.interface';
import { ViewerItem } from '@/v2/feature/payments/components/payment-details-drawer.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import {
  ID_CHECK_COMPLETED_STATES,
  MANUAL_RTW_CHECK,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { toTitleCase } from '@/v2/util/string.util';

interface ViewResultsForIdChecksModalProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly idCheckState: IDVerificationResultDto;
  readonly idCheckDocumentToDisplay?: string;
}

export const ViewResultsForIdChecksModal = ({
  isOpen,
  setIsOpen,
  idCheckState,
  idCheckDocumentToDisplay,
}: ViewResultsForIdChecksModalProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const params: { userId: string } = useParams();
  const userId = Number(params.userId);
  const { getCachedUserById } = useCachedUsers();
  const [isDownloading, setIsDownloading] = useState(false);
  const currentProfileUser = userId ? getCachedUserById(userId) : undefined;
  const fallbackDocumentName = currentProfileUser
    ? `${currentProfileUser?.firstName} ${currentProfileUser?.lastName} - Identity Check`
    : 'Identity Check';

  const idCheckDocumentName = idCheckDocumentToDisplay ?? fallbackDocumentName;

  const downloadReportForIdCheck = useCallback(async () => {
    setIsDownloading(true);
    const { requestId } = idCheckState;
    if (!requestId) return;
    const reportResponse = await OnboardingAPI.downloadIdCheckReport(idCheckState);
    try {
      // Extract the array of bytes from the response data
      const pdfBytes = new Uint8Array(reportResponse?.file?.data);

      // Create a Blob from the Uint8Array and specify the MIME type as 'application/pdf'
      const file = new Blob([pdfBytes], { type: 'application/pdf' });

      // Create an Object URL for the Blob.
      const fileURL = URL.createObjectURL(file);

      // Create a temporary link element and trigger a click event to download the PDF.
      let link = document.createElement('a');
      const reportName = reportResponse?.name ?? 'IdCheck-report.pdf';
      link.download = reportName;
      link.href = fileURL;
      link.click();
    } catch (error) {
      showMessage(
        polyglot.t('IdCheckPackage.errorMessages.failedToDownloadReport', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setIsDownloading(false);
    }
  }, [idCheckState, polyglot, showMessage]);

  const isExternalIdCheck = idCheckState?.requestId?.length > 0 && idCheckState.requestId !== MANUAL_RTW_CHECK;

  const downloadExistingManualIdCheck = async (fileUuid: string, originalFilename: string) => {
    try {
      setIsDownloading(true);
      if (fileUuid && typeof fileUuid === 'string') {
        await DocumentAPI.downloadViaUuid(fileUuid).then((url: string) => {
          downloadFileByUrl(url, originalFilename);
        });
      }
    } catch (error) {
      showMessage(
        polyglot.t('IdCheckPackage.errorMessages.failedToDownloadReport', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">
          {polyglot.t(
            isExternalIdCheck ? 'UserProfileIdentityChecks.results' : 'UserProfileIdentityChecks.manualIdentityCheck'
          )}
        </Typography>
        <ViewerItem
          title={polyglot.t(isExternalIdCheck ? 'UserProfileIdentityChecks.completionDate' : 'IdCheck.dateOfCheck')}
          value={idCheckState.completedAt ? dateAPItoDisplay(idCheckState.completedAt) ?? '' : 'None'}
        />
        {idCheckState?.status && <ViewerItem title={'Status'} value={toTitleCase(idCheckState?.status)} />}
        {isExternalIdCheck && (
          <ViewerItem title={'Result'} value={idCheckState?.result ? toTitleCase(idCheckState?.result) : 'None'} />
        )}
        {isExternalIdCheck && idCheckState?.updatedAt && (
          <ViewerItem
            title={'Last updated'}
            value={idCheckState.updatedAt ? dateAPItoDisplay(idCheckState.updatedAt) ?? '' : 'None'}
          />
        )}
        {isExternalIdCheck && (
          <Typography variant="title4">{polyglot.t('UserProfileIdentityChecks.summaryOfTheChecks')}</Typography>
        )}
        {isExternalIdCheck &&
          idCheckState.checks?.length > 0 &&
          idCheckState.checks.map((eachCheck) => (
            <ViewerItem title={eachCheck.name} value={toTitleCase(eachCheck.status)} />
          ))}
        {isExternalIdCheck && idCheckState.checks?.length === 0 && (
          <Typography variant="caption" color="Grey">
            We have not received any results yet.{' '}
          </Typography>
        )}
        {isExternalIdCheck && (
          <Typography variant="title4">{polyglot.t('UserProfileIdentityChecks.downloadReportPDF')}</Typography>
        )}

        {!isExternalIdCheck && (
          <Box sx={{ display: 'flex', gap: spacing.gap10, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DocumentIcon {...iconSize} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
              <Typography variant="caption">{idCheckDocumentName}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap5 }}>
              <IconButton
                sx={iconCTAButtonSx}
                disabled={isDownloading}
                onClick={() => downloadExistingManualIdCheck(idCheckState.reportUrl, idCheckDocumentName)}
              >
                <DownloadIcon {...iconSize} />
              </IconButton>
            </Box>
          </Box>
        )}

        {isExternalIdCheck &&
          idCheckState.completedAt &&
          idCheckState.status &&
          ID_CHECK_COMPLETED_STATES.has(idCheckState.status) &&
          idCheckState.requestId && (
            <Box sx={{ display: 'flex', gap: spacing.gap10, alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DocumentIcon {...iconSize} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
                <Typography variant="caption">{idCheckDocumentName}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap5 }}>
                <IconButton sx={iconCTAButtonSx} disabled={isDownloading} onClick={() => downloadReportForIdCheck()}>
                  <DownloadIcon {...iconSize} />
                </IconButton>
              </Box>
            </Box>
          )}

        {isExternalIdCheck &&
          !idCheckState.completedAt &&
          idCheckState.status &&
          !ID_CHECK_COMPLETED_STATES.has(idCheckState.status) &&
          idCheckState.requestId && (
            <Stack direction="column" sx={{ gap: spacing.g20 }}>
              <Typography variant="caption" color="Grey">
                You can request Preliminary Result from Zinc to check if there is any progress for this employee.
                <br />
                <br /> Once all results are in, Zelt will notify you.
              </Typography>
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                loading={isDownloading}
                disabled={isDownloading}
                onClick={() => {
                  downloadReportForIdCheck();
                }}
              >
                Download Preliminary Report
              </ButtonComponent>
            </Stack>
          )}
      </Box>
    </DrawerModal>
  );
};
