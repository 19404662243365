import { useContext, useState, createContext, PropsWithChildren } from 'react';

const UserProfileFormContext = createContext<boolean>(false);
const UserProfileFormUpdateContext = createContext<(beingEdited: boolean) => void>(() => {});

export const useProfileForm = () => {
  return useContext(UserProfileFormContext);
};

export const useProfileFormUpdate = () => {
  return useContext(UserProfileFormUpdateContext);
};

export const UserProfileProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [formCurrentlyEditing, setFormCurrentlyEditing] = useState<boolean>(false);

  return (
    <UserProfileFormContext.Provider value={formCurrentlyEditing}>
      <UserProfileFormUpdateContext.Provider value={setFormCurrentlyEditing}>
        {children}
      </UserProfileFormUpdateContext.Provider>
    </UserProfileFormContext.Provider>
  );
};
