import React from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { FormDataInterface, ReportEntity, ReportTypeTimeSelection } from '@v2/feature/reports/reports.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { capitalize } from 'lodash';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { borders } from '@/v2/styles/borders.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const TimeReportType = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  formData: FormDataInterface;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography sx={themeFonts.title2}>{polyglot.t('TimeReportType.select')}</Typography>

        <FormControl sx={{ width: '100%', ...spacing.mt20 }}>
          <RadioGroup
            value={formData.type}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                type: e.target.value as ReportTypeTimeSelection,
                entities: [ReportEntity.Requests],
              }));
            }}
            sx={{ flexFlow: 'column' }}
          >
            {[
              { value: ReportTypeTimeSelection.Requests, label: polyglot.t('TimeReportType.requests') },
              { value: ReportTypeTimeSelection.Balances, label: polyglot.t('TimeReportType.balances') },
            ].map((item, idx, arr) => (
              <FormControlLabel
                labelPlacement="start"
                value={item['value']}
                control={<StyledRadio />}
                label={
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                    <Typography sx={{ ...themeFonts.caption }}>{capitalize(item['label'])}</Typography>
                  </Box>
                }
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: idx < arr.length - 1 ? borders.background : 'none',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          onClick={onNext}
          style={{ marginTop: spacing.m25 }}
          fullWidth
        >
          {polyglot.t('General.continue')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
