import { ChangeEventHandler, useState } from 'react';

import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { v4 as uuidv4 } from 'uuid';

import { BillingDiscountAPI } from '@/api-client/billing-discount.api';
import useMessage from '@/hooks/notification.hook';
import { Discount } from '@/models/discount.model';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface props {
  discount?: Discount;
  onSave?: () => Promise<void>;
}

export const SuperAdminBillingDiscountEditForm = ({ discount, onSave }: props) => {
  const [discountName, setDiscountName] = useState<string>(discount?.description!);
  const [discountType, setDiscountType] = useState<string>(discount?.discountType!);
  const [discountAmount, setDiscountAmount] = useState<number>(Number(discount?.amountValue!));
  const [discountStartDate, setDiscountStartDate] = useState<string>(discount?.startDate ?? '');
  const [discountEndDate, setDiscountEndDate] = useState<string>(discount?.endDate ?? '');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDiscountName(e.target.value);
  };

  const handleTypeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDiscountType(e.target.value);
  };

  const handleAmountChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDiscountAmount(Number(e.target.value));
  };

  const handleStartDateChange = (newDate: string) => {
    setDiscountStartDate(newDate);
  };

  const handleEndDateChange = (newDate: string) => {
    setDiscountEndDate(newDate);
  };

  const saveDiscount = async () => {
    try {
      const discountPayload = {
        id: Number(discount?.id!),
        amountValue: Number(discountAmount),
        description: discountName,
        discountType,
        startDate: discountStartDate,
        endDate: discountEndDate,
      };

      setIsSaving(true);

      await BillingDiscountAPI.editDiscount(discountPayload);
      showMessage('Discount updated successfully', 'success');

      if (onSave) {
        await onSave();
      }

      setIsSaving(false);
    } catch (e) {
      console.error('::: Failed to update discount ::', e);
      showMessage('Failed to update discount', 'error');
      setIsSaving(false);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: 5 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: 4, mb: 4 }}>Edit discount</Typography>

        <TextField
          disabled
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          label="Discount ID"
          key={11}
          defaultValue={discount?.id!}
        ></TextField>

        <TextField
          required
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          onChange={handleNameChange}
          label="Discount Name"
          value={discountName}
          key={12}
          defaultValue={discountName}
        ></TextField>

        <TextField
          select
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          onChange={handleTypeChange}
          label="Discount Type"
          key={13}
          value={discountType}
          defaultValue={discountType}
        >
          <MenuItem value={'none'}>None</MenuItem>
          <MenuItem value={'fixed'}>Fixed (£)</MenuItem>
          <MenuItem value={'percentage'}>Percentage (%)</MenuItem>
        </TextField>

        <TextField
          sx={{ mb: 4 }}
          variant="outlined"
          fullWidth
          onChange={handleAmountChange}
          label="Discount Amount"
          key={14}
          value={discountAmount}
          defaultValue={discountAmount}
        ></TextField>

        <Box sx={fieldSx}>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={discountStartDate}
            onChange={(value) => {
              handleStartDateChange(value);
            }}
            name="startDate"
            label="Start date"
          />
        </Box>
        <Box sx={fieldSx}>
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={discountEndDate}
            onChange={(value) => {
              handleEndDateChange(value);
            }}
            name="endDate"
            label="End date"
          />
        </Box>

        <Box sx={buttonBoxSx}>
          <LoaderButton
            name="Save"
            type="button"
            loading={isSaving}
            sizeVariant="large"
            colorVariant="primary"
            onClick={saveDiscount}
            fullWidth
            key={uuidv4()}
          />
        </Box>
      </Box>
    </>
  );
};
