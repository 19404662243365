import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { PensionAPI, PensionEndpoints } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoGrey.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  NetPayArrangement,
  PaymentValues,
  PensionProviders,
  PensionRulesOptions,
  ReliefAtSource,
  SalarySacrifice,
} from '@/lib/pensions';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly provider: PensionSchemeDto;
  readonly refresh: () => Promise<void>;
}

export const EditPensionProviderSettingsDrawer = ({ isOpen, setIsOpen, provider, refresh }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditPensionProviderSettingsDrawerContent setIsOpen={setIsOpen} refresh={refresh} provider={provider} />
    </Suspense>
  </DrawerModal>
);

interface UpsertProviderFormData {
  pensionProvider: string | undefined;
  pensionRule: string | undefined;
  defaultWorkerGroup: string | undefined;
  paymentMethod: string | undefined;
  userName: string | undefined;
  password: string | undefined;
  isDefault: boolean;
  employerId: string | undefined;
}

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly provider: PensionSchemeDto;
  readonly refresh: () => Promise<void>;
}

export const EditPensionProviderSettingsDrawerContent = ({ setIsOpen, refresh, provider }: DrawerContentProps) => {
  const { data: noOfActivePensionSchemes } = useApiClient(PensionEndpoints.getCountOfActivePensionSchemes());

  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<UpsertProviderFormData>({
    initialValues: {
      pensionProvider: provider.providerName ?? undefined,
      pensionRule: provider.pensionRule ?? undefined,
      defaultWorkerGroup: provider.defaultWorkerGroup ?? undefined,
      paymentMethod: provider.payMethod ?? undefined,
      userName: provider.username ?? undefined,
      password: undefined,
      isDefault: provider.isDefault ?? noOfActivePensionSchemes === 0,
      employerId: provider.employerId ?? undefined,
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      pensionRule: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      defaultWorkerGroup: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      paymentMethod: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      userName: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      password: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      isDefault: Yup.boolean().notRequired(),
      employerId: Yup.string().when('pensionProvider', {
        is: PensionProviders.Nest,
        then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
      }),
    }),
    onSubmit: async (values: UpsertProviderFormData) => updateProvider(values),
  });

  const updateProvider = async (data: UpsertProviderFormData) => {
    try {
      if (
        !provider?.id ||
        !data.pensionProvider ||
        !data.pensionRule ||
        !data.defaultWorkerGroup ||
        !data.paymentMethod ||
        !data.userName ||
        !data.password
      )
        return;

      setLoading(true);
      const updatePensionBody = {
        providerName: data.pensionProvider,
        pensionRule: data.pensionRule,
        employeeContribution: 5,
        employerContribution: 3,
        defaultWorkerGroup: data.defaultWorkerGroup,
        payMethod: data.paymentMethod,
        username: data.userName,
        password: data.password,
        isDefault: data.isDefault,
        employerId: data.employerId,
      };
      await PensionAPI.updatePensionProviderSettings(provider.id, updatePensionBody);
      showMessage(polyglot.t('PensionModule.pensionConnected'), 'success');
      setIsOpen(false);
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PensionModule.updateProvider')}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="captionSmall">{polyglot.t('PensionModule.pensionProvider')}</Typography>
          {formik.values.pensionProvider ? (
            <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
              <Box sx={{ mt: spacing.m5 }}>{getPensionLogoByProviderName(formik.values.pensionProvider, 23)}</Box>

              <Box>
                <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                  {PensionProvidersValueToLabel[formik.values.pensionProvider] ?? formik.values.pensionProvider}
                </Typography>
              </Box>
            </Box>
          ) : (
            <EmptyCell />
          )}
        </Box>

        {formik.values.pensionProvider &&
        [PensionProviders.Nest, PensionProviders.SmartPension].includes(formik.values.pensionProvider) ? (
          <Box sx={{ display: 'flex', gap: spacing.gap5, alignItems: 'center' }}>
            <CheckboxComponent
              label={polyglot.t('PensionModule.thisIsSalarySacrificePension')}
              name="isDefault"
              checked={formik.values.pensionRule === SalarySacrifice.value}
              onChange={() => {
                if (formik.values.pensionRule !== SalarySacrifice.value)
                  formik.setFieldValue('pensionRule', SalarySacrifice.value);
                else if (formik.values.pensionProvider === PensionProviders.Nest)
                  formik.setFieldValue('pensionRule', ReliefAtSource.value);
                else formik.setFieldValue('pensionRule', NetPayArrangement.value);
              }}
            />
            <StyledTooltip
              title={
                <Typography variant="captionSmall" sx={themeFonts.tooltip}>
                  {polyglot.t('PensionModule.agreementBeforeEnrollment')}{' '}
                  <a
                    href="https://www.zelt.app/post/the-employer-s-guide-to-pension-contributions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {polyglot.t('PensionModule.here')}
                  </a>
                </Typography>
              }
            >
              <Box sx={{ cursor: 'pointer' }}>
                <InfoIcon {...iconSize} />
              </Box>
            </StyledTooltip>
          </Box>
        ) : (
          <SelectComponent
            name="pensionRule"
            label={polyglot.t('PensionModule.pensionRule')}
            options={PensionRulesOptions}
            value={formik.values.pensionRule}
            compareValue={formik.values.pensionRule}
            onChange={formik.handleChange}
            error={!!formik.errors.pensionRule && formik.touched.pensionRule}
            helperText={(formik.touched.pensionRule && formik.errors.pensionRule) as string}
          />
        )}

        {formik.values.pensionProvider === PensionProviders.Nest && (
          <TextfieldComponent
            name="employerId"
            label={polyglot.t('PensionModule.employerId')}
            value={formik.values.employerId}
            onChange={formik.handleChange}
            clearText={() => {
              formik.setFieldValue('employerId', '');
            }}
            error={formik.touched.employerId && !!formik.errors.employerId}
            helperText={(formik.touched.employerId && formik.errors.employerId) as string}
          />
        )}

        <SelectComponent
          name="paymentMethod"
          label={polyglot.t('PensionModule.paymentMethod')}
          options={PaymentValues}
          value={formik.values.paymentMethod}
          compareValue={formik.values.paymentMethod}
          onChange={formik.handleChange}
          error={!!formik.errors.paymentMethod && formik.touched.paymentMethod}
          helperText={(formik.touched.paymentMethod && formik.errors.paymentMethod) as string}
        />

        {provider ? (
          <SelectComponent
            label={polyglot.t('PensionModule.defaultWorkerGroup')}
            name="defaultWorkerGroup"
            options={provider.workerGroups.map((wg) => ({ label: wg.name, value: wg.externalId })) ?? []}
            value={formik.values.defaultWorkerGroup}
            onChange={formik.handleChange}
            error={formik.touched.defaultWorkerGroup && !!formik.errors.defaultWorkerGroup}
            helperText={(formik.touched.defaultWorkerGroup && formik.errors.defaultWorkerGroup) as string}
          />
        ) : (
          <TextfieldComponent
            name="defaultWorkerGroup"
            label={polyglot.t('PensionModule.defaultWorkerGroup')}
            value={formik.values.defaultWorkerGroup}
            onChange={formik.handleChange}
            error={formik.touched.defaultWorkerGroup && !!formik.errors.defaultWorkerGroup}
            helperText={(formik.touched.defaultWorkerGroup && formik.errors.defaultWorkerGroup) as string}
            disabled={!!provider}
          />
        )}

        <TextfieldComponent
          name="userName"
          label={polyglot.t('PensionModule.username')}
          value={formik.values.userName}
          onChange={formik.handleChange}
          error={formik.touched.userName && !!formik.errors.userName}
          helperText={(formik.touched.userName && formik.errors.userName) as string}
        />

        <TextfieldComponent
          name="password"
          label={polyglot.t('PensionModule.password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          type="password"
          error={formik.touched.password && !!formik.errors.password}
          helperText={(formik.touched.password && formik.errors.password) as string}
        />

        {/* Show checkbox if there are multiple pension schemes or if this is an update */}
        {(noOfActivePensionSchemes !== 0 || provider) && (
          <CheckboxComponent
            name="isDefault"
            label={polyglot.t('PensionModule.isDefault')}
            checked={formik.values.isDefault}
            onChange={formik.handleChange}
            disabled={!!(noOfActivePensionSchemes === 0 || provider?.isDefault)}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.update')}
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
