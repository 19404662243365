import { Box, Typography } from '@mui/material';

import { ReactComponent as CleanText } from '@/images/side-bar-icons/CleanText.svg';
import { PermissionGroupMemberUserDto } from '@/v2/feature/permission-group/permission-group.dto';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  setUserListForMembershipDeletion: React.Dispatch<React.SetStateAction<number[] | PermissionGroupMemberUserDto[]>>;
  userListForMembershipDeletion: number[];
  readonly existingMemberList: number[];
}

export const PermissionGroupMemberRemoverList = ({
  setUserListForMembershipDeletion,
  userListForMembershipDeletion,
  existingMemberList,
}: Props): JSX.Element => {
  const { getCachedUserById } = useCachedUsers();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20, mt: spacing.m5 }}>
      {existingMemberList
        .filter((m) => !userListForMembershipDeletion.includes(m))
        .map((memberUserId) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box>
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                  }}
                >
                  <UserAvatar userId={+memberUserId} size="xxsmall" />
                  {getCachedUserById(memberUserId)?.displayName}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  setUserListForMembershipDeletion([...userListForMembershipDeletion, memberUserId]);
                }}
              >
                <CleanText {...iconSize} style={{ fill: themeColors.middleGrey }} />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};
