import { useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { PermissionGroupEditDrawerPage } from '@/v2/feature/permission-group/components/permission-group-edit-drawer.component';
import { PermissionGroupMemberUserDto } from '@/v2/feature/permission-group/permission-group.dto';
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES,
  PermissionGroupPageProps,
} from '@/v2/feature/permission-group/permission-group.interface';
import { DEFAULT_GROUPS } from '@/v2/feature/permission-group/permission-group.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const EditPermissionGroupTargetsPage = ({ permissionGroup, refresh, setLoading }: PermissionGroupPageProps) => {
  const { polyglot } = usePolyglot();

  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup?.name) : false;
  const isAdmin = permissionGroup?.name === 'Admin';

  const openDrawerInEditMembersMode = () => {
    setEditDrawerOpen(true);
  };

  return (
    <SettingsSectionContent
      title={polyglot.t('PermissionGroups.router.targets')}
      onEdit={!isDefault && !isAdmin ? async () => openDrawerInEditMembersMode() : undefined}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.TextLine,
                value: polyglot.t('PermissionGroupListingTable.targets.disclaimer'),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PermissionGroupListingTable.targets.includedTargets'),
                value:
                  permissionGroup.inclusions.length === 0
                    ? polyglot.t('UserSelect.everyone')
                    : polyglot.t('PermissionGroupListingTable.targets.countTargets', permissionGroup.inclusions.length),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PermissionGroupListingTable.targets.excludedTargets'),
                value:
                  permissionGroup.exclusions.length === 0
                    ? polyglot.t('General.none')
                    : polyglot.t('PermissionGroupListingTable.targets.countTargets', permissionGroup.exclusions.length),
              },
            ],
          },
        ]}
      />

      <DrawerModal isOpen={editDrawerOpen} setIsOpen={setEditDrawerOpen}>
        <PermissionGroupEditDrawerPage
          refreshPermissionGroup={refresh}
          selectedPermission={permissionGroup}
          permissionGroupMembers={(permissionGroup?.members as PermissionGroupMemberUserDto[]) ?? []}
          closePage={async () => {
            setEditDrawerOpen(false);
            await refresh();
          }}
          mode={PERMISSION_GROUP_EDIT_DRAWER_MODES.target}
          setLoading={setLoading}
          permissionGroupName={permissionGroup?.name ?? ''}
        />
      </DrawerModal>
    </SettingsSectionContent>
  );
};
