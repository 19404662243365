import { ReactNode } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { ReactComponent as Success } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Failed } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Loading } from '@/images/side-bar-icons/Spin.svg';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize20px = { height: '20px', width: '20px' };

export type PayrunProcessingItemButton = {
  style: 'primary' | 'secondary';
  loading?: boolean;
  label: string;
  show: boolean;
  onClick: () => void;
};

type PayrunProcessingItemProps = {
  title: ReactNode;
  description: string | JSX.Element;
  success?: boolean;
  buttons?: PayrunProcessingItemButton[];
  sx?: SxProps<Theme>;
};

export const PayrunProcessingItem = ({ title, description, success, buttons, sx }: PayrunProcessingItemProps) => {
  const isProcessing = typeof success !== 'boolean';

  const header = (
    <Stack sx={{ gap: spacing.g5 }}>
      <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{title}</Typography>
      {typeof description === 'string' ? (
        <Typography sx={{ ...themeFonts.caption, color: isProcessing ? themeColors.Grey : themeColors.DarkGrey }}>
          {description}
        </Typography>
      ) : (
        description
      )}
    </Stack>
  );

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        width: '600px',
        borderTop: `1px solid`,
        py: spacing.p25,
        borderTopColor: themeColors.middleGrey,
        ...sx,
      }}
    >
      {isProcessing && (
        <>
          <Loading {...iconSize20px} style={{ flexShrink: 0, marginRight: spacing.m15 }} />
          {header}
        </>
      )}
      {!isProcessing && (
        <>
          {success ? (
            <Success {...iconSize20px} style={{ fill: themeColors.Green, flexShrink: 0 }} />
          ) : (
            <Failed {...iconSize20px} style={{ fill: themeColors.Red, flexShrink: 0 }} />
          )}
          <Stack sx={{ mx: spacing.m15 }}>
            {header}
            <Stack sx={{ flexDirection: 'row', gap: spacing.g10 }}>
              {buttons?.map((b) => (
                <LoaderButton
                  type="button"
                  loading={b.loading}
                  onClick={b.onClick}
                  sizeVariant="small"
                  colorVariant={b.style === 'primary' ? 'primary' : 'secondary'}
                  style={{
                    minWidth: '100px',
                    marginTop: '20px',
                    display: b.show ? undefined : 'none',
                  }}
                  name={b.label}
                />
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
