import { SurveyAnswer } from '@v2/feature/growth/surveys/interfaces/survey-answer.interface';

import { CycleState, CycleType, OrderPreference } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { SurveySection } from '@/v2/feature/growth/surveys/interfaces/survey-section.interface';
import { SelectedFiltersRequest } from '@/v2/feature/reports/reports.interface';

export interface SurveyVisibilitySettings {
  anonymiseAnswers: boolean;
  anonymityType: AnonymityType | null;
  anonymityThreshold: AnonymityThreshold | null;
}

export type SurveyCycle = {
  companyId: number;
  internalName: string;
  displayName: string | null;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  owner: number;
  participantIds?: number[];
  order: OrderPreference[];
  state: CycleState;
  type: CycleType;
  visibilitySettings?: SurveyVisibilitySettings | null;
  peerSurveyerIds?: { [key: number]: number[] };
  timelineSettings: SurveyTimelineSettings | null;
  notificationSettings: NotificationSettings;
  enrolmentTriggerFilters?: SelectedFiltersRequest | null;
};
export type SurveyCycleUpdate = Pick<
  SurveyCycle,
  | 'id'
  | 'internalName'
  | 'displayName'
  | 'participantIds'
  | 'peerSurveyerIds'
  | 'visibilitySettings'
  | 'timelineSettings'
  | 'notificationSettings'
  | 'type'
  | 'enrolmentTriggerFilters'
>;

export type SurveyCycleCreate = Pick<SurveyCycle, 'id' | 'internalName' | 'displayName' | 'type'>;
export type SurveyCycleLaunch = Pick<
  SurveyCycle,
  | 'id'
  | 'internalName'
  | 'displayName'
  | 'participantIds'
  | 'timelineSettings'
  | 'visibilitySettings'
  | 'state'
  | 'type'
  | 'enrolmentTriggerFilters'
> & {
  totalQuestions: number;
  participantCount: number;
};
export type SurveyCycleById = {
  cycle: SurveyCycle;
  sections: SurveySection[];
  questions: SurveyQuestion[];
};

export type OrderPreferenceCycleUpdate = {
  order: OrderPreference[];
  cycleId: string;
};

export type RemoveFromSectionCycle = {
  cycleId: string;
  questionId: string;
  sectionId: string;
};

export type MoveToSectionCycle = {
  cycleId: string;
  questionId: string;
  sectionId: string;
};

// export type SurveyParticipants = {
//   selfId: number;
//   peerIds: number[];
//   upwardIds: number[];
//   managerId: number;
// };
export enum AnonymityType {
  Full = 'full',
  Semi = 'semi',
}

export enum AnonymityThreshold {
  Three = 3,
  Five = 5,
  Ten = 10,
}

export type VisibilitySettings = {
  allowManagerToReleaseFeedback: boolean;
  hidePeerAuthor: boolean;
};

export enum SurveyFrequencyEnum {
  once = 'Once',
  recurring = 'Recurring',
}

export enum SurveyFrequencyValues {
  once = 'once',
  recurring = 'recurring',
}

export enum SurveyStartOptionsEnum {
  now = 'Start now',
  later = 'Schedule for later',
}

export type SurveyTimelineSettings = {
  startNow: boolean;
  surveyOpenFor: number;
  surveyFrequency: SurveyFrequencyValues;
  startDate: string | null;
  startTime: string | null;
  startTimeMeridiem: string | null;
};

export type NotificationSettings = {
  reminderFrequency: ReminderFrequencyValue;
};

export enum ReminderFrequencyValue {
  Daily = 'daily',
  EveryMonday = 'everyMonday',
  None = 'none',
}

export enum ReminderFrequencyLabel {
  Daily = 'Daily',
  EveryMonday = 'Weekly on Monday',
  None = 'None',
}

export const ReminderFrequencyOptions = [
  {
    value: ReminderFrequencyValue.Daily,
    label: ReminderFrequencyLabel.Daily,
  },
  {
    value: ReminderFrequencyValue.EveryMonday,
    label: ReminderFrequencyLabel.EveryMonday,
  },
  {
    value: ReminderFrequencyValue.None,
    label: ReminderFrequencyLabel.None,
  },
];

export const ReminderFrequencyDict = {
  [ReminderFrequencyValue.Daily]: ReminderFrequencyLabel.Daily,
  [ReminderFrequencyValue.EveryMonday]: ReminderFrequencyLabel.EveryMonday,
  [ReminderFrequencyValue.None]: ReminderFrequencyLabel.None,
};

export const InviteMessageOptions = [
  {
    value: 'default',
    label: 'Use default message',
  },
];

export enum SurveyCycleAnonymityType {
  full = 'Full',
  semi = 'Semi',
}

export type SurveyParticipation = {
  total: number;
  completed: number;
};

export const SURVEYS_ALL_SCOPE = 'surveys:all';
export const SURVEYS_MANAGER_SCOPE = 'surveys:manager';

export type SurveyAnswerResultStats = {
  noOfAnswers: number;
  noOfComments: number;
  norm: number | null;
  normPercent: number | null;
  positivePercent: number | null;
  neutralPercent: number | null;
  negativePercent: number | null;
  npsPercent: number | null;
};

export type SurveyResultQuestionResponse = Pick<
  SurveyQuestion,
  'id' | 'questionText' | 'factor' | 'type' | 'scaleConfig' | 'choiceOptions'
>;
export type SurveyResultAnswerResponse = Pick<
  SurveyAnswer,
  'id' | 'questionId' | 'answer' | 'comment' | 'participantId'
>;
export type ChoicePercentage = { choice: number; choiceLabel: string; count: number; percentage: number };

export type SurveyResultByQuestion = SurveyAnswerResultStats & {
  question: SurveyResultQuestionResponse;
  answers: SurveyResultAnswerResponse[];
  choicePercentages: ChoicePercentage[] | null; // null if question.type !== Single or Multiple choice
  answersLowerThanThreshold: boolean;
};

export type SurveyResultByFactor = SurveyAnswerResultStats & {
  factor: string;
  color: string;
  noOfQuestions: number;
};

export enum SurveyImpactLabel {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  None = 'None',
}

export type SurveyImpactStats = {
  coefficient: number;
  label: SurveyImpactLabel;
};

export type SurveyResultImpact = Record<string, Record<string, SurveyImpactStats | null>>;

export type SurveyCycleResult = {
  visibilitySettings: any;
  resultByQuestion: SurveyResultByQuestion[];
  resultByFactor: SurveyResultByFactor[];
  impactResult?: SurveyResultImpact;
};

export interface ParticipationResult {
  average: number;
  totalParticipants: number;
  totalEntries: number;
}

export interface FactorScoreChart {
  series: number[];
  categories: string[];
}

export interface SurveyScoreInterface {
  answersLowerThanThreshold: boolean;
  score: number;
}
export interface SurveyInsights {
  participation: ParticipationResult;
  surveyScore: SurveyScoreInterface;
  factorScore: FactorScoreChart;
}

export interface SurveyHeatmap {
  id: string | null;
  result: SurveyCycleResult;
  name: string;
}

export enum MeasureBy {
  NPS = 'NPS',
  Positive = 'Positive',
  Average = 'Average',
}
export enum ShowBy {
  Questions = 'questions',
  Factor = 'factor',
}

export enum ComparedBy {
  Site = 'site',
  Department = 'department',
  Gender = 'gender',
  Tenure = 'tenure',
  None = 'none',
}
export const DEFAULT_THRESHOLD_VALUE = 3;
