import { useCallback, useMemo } from 'react';

import { ColumnDef, Row } from '@tanstack/react-table';
import { EmptyStateBox } from '@v2/components/empty-state-box.component';
import { generatePath, useHistory } from 'react-router-dom';

import { REVIEWS_ME_RESULT_DETAIL_ROUTE } from '@/lib/routes';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReviewEndpoints } from '@/v2/feature/growth/reviews/api-client/review.api';
import { getStatus } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewMeResultList } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { LocalDate } from '@/v2/util/local-date';

export const ReviewPersonalResultListPage = ({ userId }: { userId: number }) => {
  const { data: resultList, isValidating: validatingResultList, isLoading: loadingResultList } = useApiClient(
    ReviewEndpoints.getMeResultList(userId),
    {
      suspense: false,
    }
  );

  const routerHistory = useHistory();

  const tableColumns = useMemo<ColumnDef<ReviewMeResultList, ReviewMeResultList>[]>(
    () => [
      {
        header: () => 'Cycle name',
        id: 'name',
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { name },
          },
        }) => (name ? <div>{name}</div> : <EmptyCell />),
      },
      {
        header: () => 'Cycle status',
        accessorFn: (row) => row,
        id: 'state',
        enableSorting: false,

        cell: ({
          row: {
            original: { state },
          },
        }) => (state ? getStatus()[state] : <EmptyCell />),
        minSize: 90,
        maxSize: 120,
      },

      {
        header: () => 'Start date',
        id: 'startDate',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { startDate },
          },
        }) => (
          <div>
            {new LocalDate(startDate)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          </div>
        ),
      },
    ],
    []
  );

  const handleRowClick = useCallback(
    (row: Row<ReviewMeResultList>) => {
      if (row.original.id)
        routerHistory.push(
          generatePath(REVIEWS_ME_RESULT_DETAIL_ROUTE, { cycleId: row.original.id, revieweeId: userId })
        );
    },
    [routerHistory, userId]
  );

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Results</Typography>} />
      <ContentWrapper loading={loadingResultList}>
        {!loadingResultList && resultList && resultList.length === 0 ? (
          <EmptyStateBox
            title="Your results"
            subtitle="Once completed and shared, your performance results will be published here. There are no results for you, yet."
          />
        ) : (
          <BasicTable
            rowData={resultList ? [...resultList] : []}
            columnData={tableColumns}
            loading={validatingResultList}
            hidePagination={true}
            rowClick={handleRowClick}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
