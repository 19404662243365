import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ChipComponentWithIcon } from '@v2/components/chip/chip-with-icon.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurableDeviceData,
  DeviceDto,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { DevicePossessionType } from '@v2/feature/device/device.interface';
import {
  getDeviceOwnerByDevicePossession,
  getDeviceOwnerByDevicePossessionTable,
} from '@v2/feature/device/device.util';
import { DeviceActivityLogPage } from '@v2/feature/device/features/devices-company/pages/device-activity-log.page';
import { DeviceMdmDetailsPage } from '@v2/feature/device/features/devices-company/pages/device-mdm-details.page';
import { DeviceMdmSecurityPage } from '@v2/feature/device/features/devices-company/pages/device-mdm-security.page';
import { DeviceNotesPage } from '@v2/feature/device/features/devices-company/pages/device-notes.page';
import { NoAppliedPolicies } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { EnrollExistingDevice } from '@v2/feature/device/features/order-device/enroll-existing-device.page';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { getModelImage } from '../../device.util';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE,
  DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE,
  DEVICES_ME_DEVICE_DETAILS_ROUTE,
  DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE,
  DEVICES_ME_DEVICE_LOCATION_ROUTE,
  DEVICES_ME_DEVICE_NOTES_ROUTE,
  DEVICES_ME_DEVICE_POLICIES_ROUTE,
  DEVICES_ME_DEVICE_SECURITY_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ENROLL_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { CurrentUser, ScopeContext } from '@/models';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceActionsButton } from '@/v2/feature/device/components/device-details/device-actions-button.component';
import { DeviceInstalledAppsPage } from '@/v2/feature/device/features/devices-company/pages/device-installed-apps.page';
import { DeviceLocationPage } from '@/v2/feature/device/features/devices-company/pages/device-location.page';
import { DevicePoliciesPage } from '@/v2/feature/device/features/devices-company/pages/device-policies.page';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';

const getDevicePageConfig = (
  devicePossessionId: number,
  device: DeviceDto | undefined,
  currentUser: CurrentUser,
  context: ScopeContext | null
) => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'Security',
          stub: 'security',
          path: generatePath(DEVICES_ME_DEVICE_SECURITY_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: context ? !checkScopes(currentUser, ['devices'], context) : true,
          hasChildren: false,
        },
        {
          title: 'Details',
          stub: 'details',
          path: generatePath(DEVICES_ME_DEVICE_DETAILS_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: context ? !checkScopes(currentUser, ['devices'], context) : true,
          hasChildren: false,
        },
        {
          title: 'Activity log',
          stub: 'activity_log',
          path: generatePath(DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: context ? !checkScopes(currentUser, ['devices'], context) : true,
          hasChildren: false,
        },
        {
          title: 'Apps',
          stub: 'apps',
          path: generatePath(DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE, { devicePossessionId: devicePossessionId }),
          isHidden:
            (context ? checkScopes(currentUser, ['devices'], context) : true) &&
            (!device?.applications || device.applications.length === 0) &&
            !(device?.enrollmentStatus === 'enrolled' || device?.enrollmentStatus === 'ENROLMENT_FINISHED'),
          hasChildren: false,
        },
        {
          title: 'Notes',
          stub: 'notes',
          path: generatePath(DEVICES_ME_DEVICE_NOTES_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: context ? !checkScopes(currentUser, ['devices'], context) : true,
          hasChildren: false,
        },
      ],
    },
  ];
};

interface MyDeviceDetailsRouterProps {
  readonly refreshDevicesAppData: () => Promise<void>;
}

export const MyDeviceDetailsRouter = ({ refreshDevicesAppData }: MyDeviceDetailsRouterProps): JSX.Element => {
  const params = useParams<{ readonly devicePossessionId: string }>();
  const devicePossessionId = Number(params.devicePossessionId);
  const [devicePossession, setDevicePossession] = useState<DevicePossessionDto | undefined>(undefined);
  const [appliedPolicies, setAppliedPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getCachedUserById } = useCachedUsers();
  const assignedUser = getCachedUserById(userId);
  const { getScopesContext } = useScopes();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeDeviceTransit, setActiveDeviceTransit] = useState<DeviceTransitDto | null>(null);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(
    NoAppliedPolicies
  );
  const [
    appliedZeltPoliciesForMobile,
    setAppliedZeltPoliciesForMobile,
  ] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(NoAppliedPolicies);
  const [configurableDevice, setConfigurableDevice] = useState<ConfigurableDeviceData | null>(null);

  const scopesContext =
    devicePossession?.possessionType === DevicePossessionType.User
      ? getScopesContext({ userId: devicePossession.possessionId })
      : null;
  const { polyglot } = usePolyglot();
  const fetchDeviceDetails = useCallback(async () => {
    setLoading(true);

    try {
      const [
        configurableDevice,
        retrievedDevicePossession,
        activeTransit,
        appliedPolicies,
        zeltPolicies,
        zeltPoliciesForMobile,
      ] = await Promise.all([
        DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId),
        DeviceAPI.getUserDevicePossessionDetails(userId, devicePossessionId),
        DeviceAPI.getActiveTransitForDevicePossession(devicePossessionId),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile(),
      ]);

      setAppliedZeltPolicies(zeltPolicies);
      setAppliedZeltPoliciesForMobile(zeltPoliciesForMobile);
      setDevicePossession(retrievedDevicePossession);
      setActiveDeviceTransit(activeTransit);
      setAppliedPolicies(Boolean(appliedPolicies) ? appliedPolicies : NoAppliedPolicies);
      setConfigurableDevice(configurableDevice);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage('Could not retrieve user device.', 'error');
    }
    // eslint-disable-next-line
  }, [devicePossessionId, userId]);

  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);

  const refreshDevice = useCallback(async () => {
    try {
      setLoading(true);
      const configurableDevice = await DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId);
      setDevicePossession(configurableDevice.devicePossession);
      setConfigurableDevice(configurableDevice);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    }
    // eslint-disable-next-line
  }, [devicePossessionId]);

  const customTitle = useMemo(() => {
    const enrolled =
      devicePossession?.device?.enrollmentStatus === 'enrolled' ||
      devicePossession?.device?.enrollmentStatus === 'ENROLMENT_FINISHED';
    const getUserName = (): string => {
      return getDeviceOwnerByDevicePossession(
        devicePossession ?? undefined,
        configurableDevice?.sites?.find((site) => site.id === devicePossession?.possessionId) ?? null,
        getCachedUserById
      );
    };

    return (
      <Box>
        {loading ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <SkeletonLoader
              variant="rectangular"
              width="120px"
              height="70px"
              sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: spacing.s2,
              boxSizing: 'border-box',
            }}
          >
            {getModelImage(devicePossession?.device?.type, devicePossession?.device?.modelName, {
              width: '120px',
              height: '120px',
            }) ?? (
              <SkeletonLoader
                variant="rectangular"
                width="120px"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: spacing.s1,
                boxSizing: 'border-box',
              }}
            >
              <ChipComponentWithIcon
                name={enrolled ? 'Enroled' : 'Not enroled'}
                backgroundColor="white"
                textColor="DarkGrey"
                icon={
                  enrolled ? (
                    <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                  ) : (
                    <Mistake {...iconSize} fill={themeColors.Red} />
                  )
                }
                border="light"
                textVariant="caption"
              />

              {devicePossession && devicePossession.possessionId && (
                <>
                  {devicePossession.possessionType === DevicePossessionType.User ? (
                    <Box
                      sx={{
                        width: '90%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1, justifyContent: 'center' }}>
                        <UserAvatar
                          userId={devicePossession.possessionId}
                          key={devicePossession.possessionId}
                          size="xxsmall"
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textAlign: 'center',
                            width: 'inherit',
                          }}
                        >
                          {getUserName()}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    getDeviceOwnerByDevicePossessionTable(
                      devicePossession,
                      configurableDevice?.sites?.find((item) => item.id === devicePossession.possessionId),
                      polyglot,
                      assignedUser?.displayName
                    )
                  )}
                </>
              )}
            </Box>

            {devicePossession && !devicePossession.endDate && (
              <DeviceActionsButton
                configurableDevice={configurableDevice!}
                fetchDeviceDetails={fetchDeviceDetails}
                refreshDevicesAppData={refreshDevicesAppData}
                activeTransit={activeDeviceTransit}
                assignedUser={assignedUser}
              />
            )}
          </Box>
        )}
      </Box>
    );
  }, [
    activeDeviceTransit,
    assignedUser,
    configurableDevice,
    devicePossession,
    fetchDeviceDetails,
    getCachedUserById,
    loading,
    polyglot,
    refreshDevicesAppData,
  ]);
  const isUserPossession = !(devicePossession?.possessionType === DevicePossessionType.User ?? false);
  const isSecurityHidden = !devicePossession?.device || !!activeDeviceTransit || isUserPossession;
  return (
    <>
      {devicePossession && (
        <>
          <DomainSideMenuContent
            pageConfig={getDevicePageConfig(
              devicePossessionId,
              devicePossession?.device,
              globalState.user,
              scopesContext
            )}
            showBack
            backPath={generatePath(DEVICES_ME_DIRECTORY_ROUTE, {
              devicePossessionId: devicePossession.id,
            })}
            customTitle={customTitle}
            type="Domain"
          />
          <Switch>
            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_ME_DEVICE_POLICIES_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DevicePoliciesPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_ME_DEVICE_LOCATION_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceLocationPage devicePossession={devicePossession} assignedUser={assignedUser} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices.enroll', 'devices']}
              context={scopesContext}
              path={DEVICES_ME_OVERVIEW_DEVICE_ENROLL_ROUTE}
            >
              <EnrollExistingDevice initialDevicePossession={devicePossession} refreshAppData={refreshDevicesAppData} />
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_ME_DEVICE_SECURITY_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceMdmSecurityPage
                  devicePossession={devicePossession}
                  fetchDeviceDetails={fetchDeviceDetails}
                  loading={loading}
                  activeTransit={activeDeviceTransit}
                  appliedPolicies={appliedPolicies}
                  appliedZeltPolicies={appliedZeltPolicies}
                  appliedZeltPoliciesForMobile={appliedZeltPoliciesForMobile}
                />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_ME_DEVICE_DETAILS_ROUTE}
              context={scopesContext}
            >
              <DeviceMdmDetailsPage
                configurableDevice={configurableDevice}
                ownerName={getDeviceOwnerByDevicePossession(
                  devicePossession,
                  configurableDevice?.sites.find((site) => site.id === devicePossession.possessionId),
                  getCachedUserById
                )}
                loading={loading}
                refresh={refreshDevice}
              />
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_ME_DEVICE_INSTALLED_APPS_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceInstalledAppsPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl exact scopes={['devices']} path={DEVICES_ME_DEVICE_NOTES_ROUTE} context={scopesContext}>
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceNotesPage devicePossession={devicePossession} loading={loading} refresh={refreshDevice} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_ME_DEVICE_ACTIVITY_LOG_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceActivityLogPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices']}
              path={DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceMdmSecurityPage
                  devicePossession={devicePossession}
                  fetchDeviceDetails={fetchDeviceDetails}
                  loading={loading}
                  activeTransit={activeDeviceTransit}
                  appliedPolicies={appliedPolicies}
                  appliedZeltPolicies={appliedZeltPolicies}
                  appliedZeltPoliciesForMobile={appliedZeltPoliciesForMobile}
                />
              </Suspense>
            </RouteScopesControl>
            {isSecurityHidden ? (
              <Redirect exact from={DEVICES_ME_OVERVIEW_DEVICE_ROUTE} to={DEVICES_ME_DEVICE_DETAILS_ROUTE} />
            ) : (
              <Redirect exact from={DEVICES_ME_OVERVIEW_DEVICE_ROUTE} to={DEVICES_ME_DEVICE_SECURITY_ROUTE} />
            )}
          </Switch>
        </>
      )}
    </>
  );
};
