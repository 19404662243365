import React, { CSSProperties } from 'react';

import { ReactComponent as CleanText } from '@/images/fields/Clean.svg';
import { themeColors } from '@/v2/styles/colors.styles';

const DefaultStyle: CSSProperties = {
  position: 'absolute',
  right: 20,
  fill: themeColors.middleGrey,
  width: '14px',
  height: '14px',
};

type ClearIconProps = React.SVGProps<SVGSVGElement>;

export const ClearIcon = ({ style = DefaultStyle, ...props }: ClearIconProps) => {
  return <CleanText style={style} {...props} />;
};
