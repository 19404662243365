import React, { Suspense, useState } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceTransitDto } from '@v2/feature/device/device.dto';
import { AssignDeviceToABM, DevicePossessionType, DeviceTransitStatus } from '@v2/feature/device/device.interface';
import { getDeviceOwnerAsSuperadminByDevicePossession } from '@v2/feature/device/device.util';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { SiteDto } from '@v2/feature/site/site.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { StyledTextfield } from '@v2/styles/textfield.styles';
import { LocalDate } from '@v2/util/local-date';
import { isToday } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import { saveAs } from 'file-saver';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { ReactComponent as ArrowRightSmall } from '@/images/side-bar-icons/NextBtn.svg';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';

interface SuperAdminDeviceTransitDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly deviceTransit: DeviceTransitDto;
  readonly setDeviceTransit: React.Dispatch<React.SetStateAction<DeviceTransitDto | undefined>>;
  readonly userNames: { [userId: number]: string };
  readonly companyNames: { [companyId: number]: string };
  readonly refresh: () => Promise<void>;
  readonly sites: { [siteId: number]: SiteDto };
}

export const SuperAdminDeviceTransitDrawer = ({
  isOpen,
  setIsOpen,
  deviceTransit,
  setDeviceTransit,
  companyNames,
  userNames,
  refresh,
  sites,
}: SuperAdminDeviceTransitDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminDeviceTransitDrawerContent
        deviceTransit={deviceTransit}
        companyNames={companyNames}
        onDeliveryConfirm={async () => {
          await refresh();
          setDeviceTransit(undefined);
          setIsOpen(false);
        }}
        userNames={userNames}
        sites={sites}
      />
    </Suspense>
  </DrawerModal>
);

interface SuperAdminDeviceTransitDrawerContentProps {
  readonly deviceTransit: DeviceTransitDto;
  readonly userNames: { [userId: number]: string };
  readonly companyNames: { [companyId: number]: string };
  readonly onDeliveryConfirm: () => Promise<void>;
  readonly sites: { [siteId: number]: SiteDto };
}

const SuperAdminDeviceTransitDrawerContent = ({
  deviceTransit,
  onDeliveryConfirm,
  userNames,
  companyNames,
  sites,
}: SuperAdminDeviceTransitDrawerContentProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = useState<string>(new LocalDate().toDateString());
  const [depAccountName, setDepAccountName] = useState<string | null>(null);

  const [showMessage] = useMessage();

  const confirmDeviceReturn = async () => {
    try {
      setIsButtonLoading(true);
      await DeviceAPI.markDeviceAsReturnedAsSuperAdmin(deviceTransit.id);
      await onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as returned. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsButtonLoading(false);
    }
  };

  const markStorageDeviceAsShippedBackToCompany = async () => {
    try {
      setIsButtonLoading(true);
      await DeviceAPI.markStorageDeviceAsShippedAsSuperAdmin(deviceTransit.id);
      await onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as returned. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsButtonLoading(false);
    }
  };

  const markStorageDeviceAsDeliveredToCompany = async () => {
    try {
      setIsButtonLoading(true);
      await DeviceAPI.markStorageDeviceAsDeliveredAsSuperAdmin(deviceTransit.id, deliveryDate);
      await onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as returned. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsButtonLoading(false);
    }
  };

  const assignNewNameToDepDevice = async () => {
    const device = deviceTransit.device;
    if (device && device.id && device.serialNumber && depAccountName) {
      const depEvent: AssignDeviceToABM = {
        depDeviceUsername: depAccountName,
        depTokenName: deviceTransit.companyId + companyNames[deviceTransit.companyId],
        deviceId: device.id,
        serialNumber: device.serialNumber,
      };
      await InHouseMdmAPI.assignDeviceToABM(depEvent);
      showMessage(`Action executed`, 'success');
    } else {
      showMessage(`Serial number, device username and device id have to be provided to assign to DEP`, 'error');
      return;
    }
  };
  const getEnrolmentProfile = async () => {
    if (deviceTransit.deviceId) {
      try {
        setIsButtonLoading(true);
        return await InHouseMdmAPI.getEnrolmentProfile(deviceTransit.deviceId);
      } catch (error) {
        showMessage(`Could not get device data. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      showMessage(`Device id is necessary to generate profile.`, 'error');
      return undefined;
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">
        {deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStock ? 'Outgoing' : 'Incoming'} transit
      </Typography>
      {deviceTransit.device && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="title4">Model Name: {deviceTransit.device.modelName}</Typography>
          {deviceTransit.device.modelNumber && (
            <Typography variant="title4">Model Number: {deviceTransit.device.modelNumber}</Typography>
          )}
          {deviceTransit.device.serialNumber && (
            <Typography variant="title4">Serial Number: {deviceTransit.device.serialNumber}</Typography>
          )}
        </Box>
      )}

      <Box sx={{ display: 'flex', gap: 2, mb: 2, justifyContent: 'space-between', alignItems: 'center' }}>
        {deviceTransit.sender && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {deviceTransit.sender.possessionType !== DevicePossessionType.ZeltStock && (
              <Typography variant="caption">
                {(deviceTransit.sender.companyId && companyNames[deviceTransit.sender.companyId]) || 'Unknown'}
              </Typography>
            )}
            <Typography variant="title4">
              {getDeviceOwnerAsSuperadminByDevicePossession(deviceTransit.sender, userNames, sites)}
            </Typography>
          </Box>
        )}

        <ArrowRightSmall width={32} height={32} />

        {deviceTransit.receiver && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {deviceTransit.receiver.possessionType !== DevicePossessionType.ZeltStock && (
              <Typography variant="caption">
                {(deviceTransit.receiver.companyId && companyNames[deviceTransit.receiver.companyId]) || 'Unknown'}
              </Typography>
            )}
            <Typography variant="title4">
              {getDeviceOwnerAsSuperadminByDevicePossession(deviceTransit.receiver, userNames, sites)}
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        {deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStock && (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'start' }}>
            <Typography variant="caption">
              If the device has been delivered, you can mark it as delivered from the order drawer.
            </Typography>
          </Box>
        )}
        {deviceTransit.receiver?.possessionType === DevicePossessionType.ZeltStock && (
          <LoaderButton
            name="Mark as returned"
            loading={isButtonLoading}
            onClick={confirmDeviceReturn}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        )}
        {deviceTransit.receiver?.possessionType === DevicePossessionType.ZeltStorage && (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'start' }}>
            <LoaderButton
              name="Mark as received for storage"
              loading={isButtonLoading}
              onClick={confirmDeviceReturn}
              fullWidth
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        )}
        {deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStorage &&
          deviceTransit.status === DeviceTransitStatus.Pending && (
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'start' }}>
              <LoaderButton
                name="Mark as shipped"
                loading={isButtonLoading}
                onClick={markStorageDeviceAsShippedBackToCompany}
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          )}

        {deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStorage &&
          deviceTransit.status === DeviceTransitStatus.Shipping && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start' }}>
              <LocalizationProvider key="delivery-date" dateAdapter={AdapterDateFns} locale={locale}>
                <DatePicker
                  components={{
                    OpenPickerIcon: ArrowDownACIcon,
                  }}
                  label="Delivery Date"
                  value={deliveryDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(date) => (date ? setDeliveryDate(date) : setDeliveryDate(new LocalDate().toDateString()))}
                  disableFuture
                  shouldDisableDate={(date) =>
                    Boolean(
                      date > new LocalDate().toDateString() ||
                        date < new LocalDate(deviceTransit.createdAt).toDateString()
                    ) && !isToday(new LocalDate(date!).getDate())
                  }
                  renderInput={(params) => <StyledTextfield {...params} size="small" fullWidth variant="standard" />}
                />
              </LocalizationProvider>

              <LoaderButton
                name="Mark as delivered"
                loading={isButtonLoading}
                onClick={markStorageDeviceAsDeliveredToCompany}
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px' }}>
        <Typography variant="title3">MDM configuration</Typography>
        <Box sx={{ mt: '10px' }}>
          <Typography variant="title4">Enrolment</Typography>
          <Box sx={{ mt: '10px', gap: '5px' }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              loading={isButtonLoading}
              onClick={async () => {
                try {
                  const profile = await getEnrolmentProfile();
                  if (profile) {
                    const blob = new Blob([Buffer.from(profile, 'base64')], {
                      type: 'application/x-apple-aspen-config',
                    });
                    saveAs(blob, 'enrollment.mobileconfig');
                  }
                } catch (error) {
                  showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, 'error');
                }
              }}
              fullWidth
            >
              Download enrolment profile
            </ButtonComponent>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: '10px' }}>
        <Typography variant="title4">DEP configuration</Typography>
        <Box sx={{ mt: '10px' }}>
          <TextfieldComponent
            name="deviceAccountName"
            label="Device account name"
            value={depAccountName ?? ''}
            type="text"
            onChange={(e) => {
              setDepAccountName(e.target.value?.toLowerCase());
            }}
            tooltip={'To set up your device, enter a unique name for the account.'}
          />
        </Box>
        <Box sx={{ mt: '10px' }}>
          <LoaderButton
            name="Assign account name"
            loading={isButtonLoading}
            onClick={assignNewNameToDepDevice}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};
