import { useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { UUIDV4 } from '@/lib/uuid';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { CustomSectionForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component';
import { MissingField } from '@/v2/feature/user/user.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  readonly userId: number;
  readonly refreshPayroll?: () => Promise<void>;
  readonly missingFields: MissingField[];
};

export const MissingCustomSection = ({ userId, refreshPayroll, missingFields }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardId, setCardId] = useState<UUIDV4 | null>(null);

  const { data: forms, mutate: refreshForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false,
  });
  const { data: formValues, mutate: refreshValues } = useApiClient(CustomProfileFormEndpoints.getFormValues(userId), {
    suspense: false,
  });

  const form = useMemo(() => forms?.find((f) => f.formId === cardId), [forms, cardId]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        {missingFields.map((mf) => (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography sx={themeFonts.caption}>{mf.text}</Typography>
            <IconButton
              onClick={() => {
                setIsModalOpen(true);
                setCardId(mf.cardId as UUIDV4);
              }}
              colorVariant="secondary"
              sizeVariant="small"
            >
              <Plus width="14px" height="14px" />
            </IconButton>
          </Box>
        ))}
      </Box>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        <CustomSectionForm
          userId={userId}
          form={form!}
          fields={formValues?.filter((f) => f.formId === form!?.formId) ?? []}
          onSubmit={() => {
            setIsModalOpen(false);
            refreshForms?.();
            refreshValues?.();
            refreshPayroll?.();
          }}
          onClose={() => setIsModalOpen(false)}
        />
      </DrawerModal>
    </>
  );
};
