import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { BillingInvoiceAPI } from '@/api-client/billing-invoice.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BillingCharge } from '@/models/invoice.model';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  ChargeTypes,
  CompanyBasic,
  CreateCharge,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/one-off-charge.interface';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly companyList: CompanyBasic[];
  readonly refresh: () => Promise<void>;
  readonly chargeToEdit?: BillingCharge | null;
}

export const SuperAdminBillingNewOneOffChargeDrawer = ({
  isOpen,
  setIsOpen,
  refresh,
  companyList,
  chargeToEdit,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <SuperAdminBillingNewOneOffChargeDrawerContent
        refresh={refresh}
        setIsOpen={setIsOpen}
        companyList={companyList}
        chargeToEdit={chargeToEdit}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly companyList: CompanyBasic[];
  readonly chargeToEdit?: BillingCharge | null;
}

export const SuperAdminBillingNewOneOffChargeDrawerContent = ({
  refresh,
  setIsOpen,
  companyList,
  chargeToEdit,
}: DrawerContentProps): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const allCompanyOptions = useMemo(() => {
    return companyList.map((c) => ({ value: c.companyId, label: `${c.name} (${c.companyId})` }));
  }, [companyList]);

  const typeOptions = Object.values(ChargeTypes).map((v) => ({ value: v, label: v }));

  const originalCompanyIdForCharge = chargeToEdit?.companyId;
  const editMode = chargeToEdit && chargeToEdit.id;

  const onSubmit = useCallback(
    async (values: CreateCharge) => {
      try {
        setLoading(true);
        if (editMode && originalCompanyIdForCharge)
          await BillingInvoiceAPI.updateExistingChargeAsSuperadmin(originalCompanyIdForCharge, values);
        else await BillingInvoiceAPI.createNewChargeAsSuperadmin(values);
        await refresh();
        setIsOpen(false);
        showMessage('Charge created successfully', 'success');
      } catch (error) {
        showMessage(`Soemthing went wrong ${nestErrorMessage(error)})`, 'error');
      } finally {
        setLoading(false);
      }
    },
    [editMode, originalCompanyIdForCharge, refresh, setIsOpen, showMessage]
  );

  const formik = useFormik<CreateCharge>({
    initialValues: {
      id: chargeToEdit?.id ?? null,
      companyId: chargeToEdit?.companyId ?? 0,
      userId: chargeToEdit?.userId ?? 0,
      amount: chargeToEdit?.amount ?? 0,
      type: chargeToEdit?.type ?? undefined,
      memo: chargeToEdit?.memo ?? '',
    },
    validationSchema: yup.object({
      companyId: yup.number().typeError('Please provide a valid value').required('This field is required'),
      amount: yup.number().typeError('Please provide a valid value').required('This field is required'),
      type: yup.string().oneOf(Object.values(ChargeTypes)).required('This field is required'),
      memo: yup.string().required('This field is required'),
    }),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">New one off charge</Typography>

        <SelectComponent
          name="companyId"
          label={'Company'}
          options={allCompanyOptions}
          value={formik.values.companyId}
          onChange={formik.handleChange}
          compareValue={formik.values.companyId}
          error={!!formik.errors.companyId && formik.touched.companyId}
          helperText={formik.touched.companyId && formik.errors.companyId}
        />

        <SelectComponent
          name="type"
          label={'Type'}
          options={typeOptions}
          value={formik.values.type}
          onChange={formik.handleChange}
          compareValue={formik.values.type}
          error={!!formik.errors.type && formik.touched.type}
          helperText={formik.touched.type && formik.errors.type}
        />

        <TextfieldComponent
          name="amount"
          label={`Amount`}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && !!formik.errors.amount}
          helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
          endAdornment="none"
          type="number"
        />

        <TextfieldComponent
          name="memo"
          label="Memo"
          multiline
          value={formik.values.memo}
          onChange={formik.handleChange}
          error={formik.touched.memo && !!formik.errors.memo}
          helperText={(formik.touched.memo && formik.errors.memo) ?? ' '}
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={editMode ? 'Update' : 'Add'}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
