import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  JiraAdminEmailInstructions,
  JiraApiTokenInstructions,
  JiraSiteUrlInstructions,
} from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-jira.component';
import {
  LastpassAccountIdInstructions,
  LastpassInfoCard,
  LastpassProvHashInstructions,
} from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-lastpass.component';
import { MondayApiKeyInstructions } from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-monday.component';
import {
  NotionInfoCard,
  NotionInstallInstructions,
} from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-notion.component';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE } from '@/lib/routes';
import { CurrentUser } from '@/models';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { AppCredentialDto } from '@/v2/feature/app-integration/features/app-details/app-details.dto';
import { APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { JumpcloudApiKeyInstructions } from '@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-jumpcloud.component';
import { RemoteApiTokenInstructions } from '@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-remote.component';
import { TeamtailorApiKeyInstructions } from '@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-teamtailor.component';
import { buttonBoxSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly currentUser: CurrentUser;
  readonly appDetails: InstalledAppDto | undefined;
}

type AppCredentialsProps = {
  readonly [key: string]: Record<string, unknown>;
};

type AppInfoProps = {
  readonly [key: string]: React.JSX.Element;
};

export const appCredentialsInstructions: AppCredentialsProps = {
  notion: { token: <NotionInstallInstructions /> },
  lastpass: {
    cid: <LastpassAccountIdInstructions />,
    provhash: <LastpassProvHashInstructions />,
  },
  monday: {
    apikey: <MondayApiKeyInstructions />,
  },
  jumpcloud: {
    token: <JumpcloudApiKeyInstructions />,
  },
  jira: {
    token: <JiraApiTokenInstructions />,
    siteUrl: <JiraSiteUrlInstructions />,
    adminEmail: <JiraAdminEmailInstructions />,
  },
  teamtailor: {
    apikey: <TeamtailorApiKeyInstructions />,
  },
  remote: {
    apitoken: <RemoteApiTokenInstructions />,
  },
} as const;

const appInfoCards: AppInfoProps = {
  notion: <NotionInfoCard />,
  lastpass: <LastpassInfoCard />,
} as const;

export const AppBasicAuthConnectForm = ({ currentUser, appDetails }: Props): JSX.Element => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [credentialsPlaceholders, setCredentialsPlaceholders] = useState<
    { [key: string]: Record<string, unknown> } | undefined
  >(undefined);
  const [credentials, setCredentials] = useState<AppCredentialDto | undefined>(undefined);
  const { mutate: refreshInstalledApps } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), {
    suspense: false,
  });
  const [credsTestPassed, setCredsTestPassed] = useState(true);
  const INVALID_CREDENTIALS_ERROR = 'These API credentials are not working. Please generate another set and try again.';
  const PREFILLED_CREDENTIALS = ['adminEmail', 'zeltCompanyId'];

  const updateCredentials = useCallback(
    (key: string, value: string) => {
      setCredentials({
        ...credentials,
        [key]: value,
      });
    },
    [credentials]
  );

  useEffect(() => {
    if (appDetails?.authorised) {
      setCredentialsPlaceholders(appDetails?.credentialsPlaceholders ?? undefined);
      setCredentials(undefined);
    }
    if (
      appDetails &&
      APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION.includes(appDetails.stub) &&
      currentUser &&
      currentUser.emailAddress
    ) {
      updateCredentials('adminEmail', currentUser.emailAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDetails, currentUser]);

  function areCredentialsCompleted() {
    if (!credentials) {
      return false;
    } else {
      if (appDetails && appDetails.directory?.credentials) {
        for (let cred of appDetails?.directory?.credentials) {
          if (cred) {
            const propName = (cred.name as keyof AppCredentialDto) || undefined;
            if (!credentials[propName] && cred.name && !PREFILLED_CREDENTIALS.includes(cred.name)) return false;
          }
        }
      }
    }
    return true;
  }

  async function upsertCredentials(credParams: AppCredentialDto | undefined) {
    try {
      if (credParams && appDetails?.stub) {
        await AppDetailsAPI.saveOrUpdateIntegrationCredentials(appDetails?.stub, credParams);
        const app = await AppDetailsAPI.getIntegrationData(appDetails?.stub);
        refreshInstalledApps!();
        setTimeout(
          () =>
            routerHistory.push(
              generatePath(APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, {
                appStub: appDetails?.stub,
              }),
              { app, needToRefreshDirectory: true }
            ),
          700
        );
      }
    } catch (error) {
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    } finally {
    }
  }

  async function testBasicAuthCredentials(): Promise<boolean | Record<string, unknown> | undefined> {
    try {
      if (credentials && appDetails?.stub) {
        const testResult = await AppDetailsAPI.testBasicAuthCredentials(appDetails?.stub, credentials);
        setCredsTestPassed(!!testResult);
        return testResult;
      }
    } catch (error) {
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    }
  }

  const basicAuthSuccess = (result: boolean | Record<string, unknown> | undefined): boolean => {
    return result && typeof result === 'object' && 'onBehalfOf' in result && 'apikey' && result ? true : false;
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: async (_values) => {
      setLoading(true);
      try {
        const result = await testBasicAuthCredentials();
        if (result) {
          if (basicAuthSuccess(result) && appDetails?.stub === 'greenhouse') {
            await upsertCredentials({ ...credentials, ...(result as Record<string, unknown>) });
          } else {
            await upsertCredentials({ ...credentials });
          }
          showMessage(
            appDetails?.authorised
              ? 'You have successfully updated your app credentials.'
              : 'You have successfully connected the app.',
            'success'
          );
        }
      } catch (error) {
        showMessage(`Encountered an error when trying to connect the app: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const appCredentialType = useMemo(() => {
    if (appDetails?.directory?.credentials && appDetails?.directory?.credentials.length > 0)
      return appDetails?.directory?.credentials[0].label;
    else return 'an API key';
  }, [appDetails?.directory?.credentials]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography variant="title2">Enter {appCredentialType}</Typography>
        <Box>
          <Typography variant="caption" sx={{ mt: spacing.m10 }}>
            {appDetails && appDetails?.stub && appInfoCards[appDetails.stub]}
          </Typography>
          <Box sx={{ mt: spacing.m50 }}>
            {appDetails &&
              appDetails.directory &&
              appDetails.directory.credentials &&
              appDetails.directory.credentials.map((credential) => {
                if (credential.name !== 'zeltCompanyId')
                  return (
                    <Box sx={fieldSx}>
                      <TextfieldComponent
                        name={credential.name}
                        label={credential.label}
                        disabled={
                          credential.name === 'adminEmail' &&
                          APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION.includes(appDetails?.stub)
                        }
                        required
                        placeholder={
                          credentialsPlaceholders
                            ? credentialsPlaceholders[credential?.name || '']?.toString()
                            : undefined
                        }
                        onChange={(event) => {
                          updateCredentials(event.target.name, event.target.value);
                        }}
                        value={
                          credential.name === 'adminEmail' &&
                          APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION.includes(appDetails?.stub)
                            ? currentUser.emailAddress
                            : credentials?.[credential.name as keyof AppCredentialDto]
                        }
                        clearText={() => {
                          updateCredentials(String(credential.name), '');
                        }}
                        error={!credsTestPassed}
                        helperText={(!credsTestPassed && INVALID_CREDENTIALS_ERROR) ?? ' '}
                        tooltip={
                          credential.name && appCredentialsInstructions[appDetails.stub]
                            ? (appCredentialsInstructions[appDetails.stub][String(credential.name)] as JSX.Element)
                            : ''
                        }
                      />
                    </Box>
                  );
                else {
                  return (
                    <Box sx={fieldSx}>
                      <TextfieldComponent
                        name={credential.name}
                        label={credential.label}
                        disabled={true}
                        value={currentUser?.company?.companyId ?? '0'}
                      />
                    </Box>
                  );
                }
              })}
          </Box>
          <Box sx={buttonBoxSx}>
            <LoaderButton
              disabled={!areCredentialsCompleted()}
              name={appDetails?.authorised ? 'Update credentials' : 'Connect'}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
