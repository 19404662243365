import { Box } from '@mui/material';
import { FormikProps } from 'formik';
import Polyglot from 'node-polyglot';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SectionItemType } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewCycle, TimelineSettings } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export type ShowFlags = {
  showSelf: boolean;
  showUpward: boolean;
  showPeer: boolean;
  showManager: boolean;
};

export const getDayOrDays = (days: number): string => (days > 1 ? 'days' : 'day');

export const timelineByReviwer = (
  reviewCycle: ReviewCycle,
  showFlags: {
    showSelf: boolean;
    showUpward: boolean;
    showPeer: boolean;
    showManager: boolean;
  }
) => {
  const { timelineSettings } = reviewCycle;
  return [
    ...(showFlags.showSelf
      ? [
          {
            type: SectionItemType.Pair,
            label: 'Self review deadline',
            value: timelineSettings?.selfReviewDeadline
              ? `${timelineSettings?.selfReviewDeadline} ${getDayOrDays(
                  timelineSettings?.selfReviewDeadline
                )} after start`
              : 'NA',
          },
        ]
      : []),
    ...(showFlags.showUpward
      ? [
          {
            type: SectionItemType.Pair,
            label: 'Upward review deadline',
            value: timelineSettings?.upwardReviewDeadline
              ? `${timelineSettings?.upwardReviewDeadline} ${getDayOrDays(
                  timelineSettings?.upwardReviewDeadline
                )} after start`
              : 'NA',
          },
        ]
      : []),
    ...(showFlags.showPeer
      ? [
          {
            type: SectionItemType.Pair,
            label: 'Peer review deadline',
            value: timelineSettings?.peerReviewDeadline
              ? `${timelineSettings?.peerReviewDeadline} ${getDayOrDays(
                  timelineSettings?.peerReviewDeadline
                )} after start`
              : 'NA',
          },
        ]
      : []),
    ...(showFlags.showManager
      ? [
          {
            type: SectionItemType.Pair,
            label: 'Manager review deadline',
            value: timelineSettings?.managerReviewDeadline
              ? `${timelineSettings?.managerReviewDeadline} ${getDayOrDays(
                  timelineSettings?.managerReviewDeadline
                )} after start`
              : 'NA',
          },
        ]
      : []),
  ];
};

export const getDeadlineByReviewerFields = (
  showFlags: ShowFlags,
  formik: FormikProps<{ timelineSettings: TimelineSettings }>,
  polyglot: Polyglot
) => {
  const { showSelf, showUpward, showPeer, showManager } = showFlags;

  return (
    <>
      {showSelf && (
        <TextfieldComponent
          name="timelineSettings.selfReviewDeadline"
          label="Self review deadline"
          value={formik.values.timelineSettings.selfReviewDeadline}
          onChange={formik.handleChange}
          type="number"
          minValue={1}
          validateNumber={true}
          error={
            formik.touched.timelineSettings?.selfReviewDeadline && !!formik.errors.timelineSettings?.selfReviewDeadline
          }
          helperText={
            (formik.touched.timelineSettings?.selfReviewDeadline &&
              formik.errors.timelineSettings?.selfReviewDeadline) ??
            ' '
          }
          endAdornment={<Typography variant="caption">days after start</Typography>}
        />
      )}

      {showUpward && (
        <TextfieldComponent
          name="timelineSettings.upwardReviewDeadline"
          label="Upward review deadline"
          value={formik.values.timelineSettings.upwardReviewDeadline}
          onChange={formik.handleChange}
          type="number"
          minValue={1}
          validateNumber={true}
          error={
            formik.touched.timelineSettings?.upwardReviewDeadline &&
            !!formik.errors.timelineSettings?.upwardReviewDeadline
          }
          helperText={
            (formik.touched.timelineSettings?.upwardReviewDeadline &&
              formik.errors.timelineSettings?.upwardReviewDeadline) ??
            ' '
          }
          endAdornment={<Typography variant="caption">days after start</Typography>}
        />
      )}

      {showPeer && (
        <TextfieldComponent
          name="timelineSettings.peerReviewDeadline"
          label="Peer review deadline"
          value={formik.values.timelineSettings.peerReviewDeadline}
          onChange={formik.handleChange}
          type="number"
          minValue={1}
          validateNumber={true}
          error={
            formik.touched.timelineSettings?.peerReviewDeadline && !!formik.errors.timelineSettings?.peerReviewDeadline
          }
          helperText={
            (formik.touched.timelineSettings?.peerReviewDeadline &&
              formik.errors.timelineSettings?.peerReviewDeadline) ??
            ' '
          }
          endAdornment={<Typography variant="caption">days after start</Typography>}
        />
      )}

      {showManager && (
        <TextfieldComponent
          name="timelineSettings.managerReviewDeadline"
          label="Manager review deadline"
          value={formik.values.timelineSettings.managerReviewDeadline}
          onChange={formik.handleChange}
          type="number"
          minValue={1}
          validateNumber={true}
          error={
            formik.touched.timelineSettings?.managerReviewDeadline &&
            !!formik.errors.timelineSettings?.managerReviewDeadline
          }
          helperText={
            (formik.touched.timelineSettings?.managerReviewDeadline &&
              formik.errors.timelineSettings?.managerReviewDeadline) ??
            ' '
          }
          endAdornment={<Typography variant="caption">days after start</Typography>}
        />
      )}

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          type="submit"
          sizeVariant="medium"
          colorVariant="primary"
          name={polyglot.t('General.save')}
          loading={formik.isSubmitting}
          fullWidth
        />
      </Box>
    </>
  );
};
