import { useCallback, useEffect, useState } from 'react';

import { Stack, SwipeableDrawer, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  open: boolean;
  onClose: () => void;
  markAsSent: () => Promise<void>;
}

export const AccountingConfirmDoneDrawer = ({ open, onClose, markAsSent }: Props): JSX.Element => {
  const [confirmed, setConfirmed] = useState(false);
  const [markingAsSent, setMarkingAsSent] = useState(false);
  const [showMessage] = useMessage();

  const performMarkAsSent = useCallback(async () => {
    setMarkingAsSent(true);
    try {
      await markAsSent();
      onClose();
    } catch (error) {
      showMessage(`Failed to mark accounting journal as sent. ${nestErrorMessage(error)}`, 'error');
      setMarkingAsSent(false);
    }
  }, [markAsSent, onClose, showMessage]);

  useEffect(() => {
    // reset confirmed flag each time the drawer is opened
    if (open) setConfirmed(false);
  }, [open]);

  return (
    <SwipeableDrawer anchor="right" onClose={onClose} onOpen={() => {}} open={open}>
      <Stack sx={{ width: '440px' }}>
        <Stack sx={{ p: spacing.pad40, gap: spacing.g20 }}>
          <Typography sx={themeFonts.title2}>Accounting</Typography>
          <Typography sx={themeFonts.caption}>
            By marking Accounting journal as sent you are confirming that a file has been uploaded to your accounting
            software or it was shared with an accountant outside of Zelt.
          </Typography>

          <CheckboxComponent
            label="I confirm that the accounting journal has been reported outside of Zelt."
            value={confirmed}
            disabled={markingAsSent}
            onChange={(_, checked) => setConfirmed(checked)}
            autoFocus
            sx={{ alignItems: 'baseline' }}
          />

          <LoaderButton
            name="Mark as sent"
            onClick={performMarkAsSent}
            disabled={!confirmed}
            loading={markingAsSent}
            fullWidth
            sizeVariant="large"
            colorVariant="primary"
            style={{ marginTop: '40px' }}
          />
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};
