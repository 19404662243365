import { Route, Switch } from 'react-router-dom';

import { EntityImportResultPageProps } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { UserImportGoogleWorkspacePage } from '@/v2/feature/user/features/user-import/pages/user-import-google-workspace.page';
import { UserImportResultDto } from '@/v2/feature/user/features/user-import/user-import.dto';

const ENTITY_IMPORT_WIZARD_ENTRIES_GOOGLE_ROUTE = '/settings/import/wizard/users/google';

export function UserImportRouter(props: EntityImportResultPageProps<UserImportResultDto>) {
  return (
    <Switch>
      <Route path={ENTITY_IMPORT_WIZARD_ENTRIES_GOOGLE_ROUTE}>
        <UserImportGoogleWorkspacePage {...props} />
      </Route>
    </Switch>
  );
}
