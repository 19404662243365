import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { NotificationsTestingMode } from '@v2/feature/notification/notification-settings/features/components/notifications-testing-mode.component';

import { UserNotificationEndpoints } from '@/api-client/notification.api';
import { SlackAPI } from '@/api-client/slack.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppDetailsEndpoints } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { NotificationSettingTable } from '@/v2/feature/notification/notification-settings/features/components/notifications-setting-table.component';
import { NotificationEndpoints } from '@/v2/feature/notification/profile-notification.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@/v2/styles/root.styles';

export interface SlackChannel {
  readonly name: string;
  readonly id: string;
}

export interface DItem {
  value: string;
  label: string;
}

export const NotificationSettingPage = () => {
  const { polyglot } = usePolyglot();
  const [allSlackChannels, setAllSlackChannels] = useState<readonly DItem[]>([]);
  const [slackConnected, setSlackConnected] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [postSave, setPostSave] = useState<boolean>(false);

  const {
    data: userNotificationSettings,
    isValidating: loadingUserNotficationSettings,
    mutate: refreshUserNotificationSettings,
  } = useApiClient(UserNotificationEndpoints.getNotificationSettings(), { suspense: false });
  const { data: slackAppDetails } = useApiClient(AppDetailsEndpoints.getAppDetails('slack'), { suspense: false });
  const {
    data: profileNotifications,
    isValidating: loadingProfileNotifications,
    mutate: refreshProfileNotifications,
  } = useApiClient(NotificationEndpoints.getProfileNotifications(), { suspense: false });

  const refreshNotificationData = () => {
    setPostSave(false);
    refreshUserNotificationSettings?.();
    refreshProfileNotifications?.();
    setPostSave(true);
  };

  const getAllSlackChannels = useCallback(async () => {
    try {
      if (slackConnected && !loadingUserNotficationSettings) {
        await SlackAPI.importChannels();
        let allChannels: DItem[] = [];
        allChannels = (await SlackAPI.getSlackChannels()).map((item: SlackChannel) => ({
          value: item.id,
          label: item.name,
        }));

        setAllSlackChannels(allChannels);
      }
    } catch (error) {
      console.error('Failed to load channels: ', nestErrorMessage(error));
      showMessage(polyglot.t('NotificationSettingPage.errorMessages.load'), 'error');
    }
  }, [polyglot, loadingUserNotficationSettings, showMessage, slackConnected]);

  useEffect(() => {
    getAllSlackChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackConnected]);

  useEffect(() => {
    if (slackAppDetails) {
      setSlackConnected(slackAppDetails?.authorised ?? false);
    }
  }, [slackAppDetails]);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">{polyglot.t('NotificationSettingPage.settings')}</Typography>} />
      <ContentWrapper loading={false}>
        <Box>
          <NotificationSettingTable
            userNotifications={userNotificationSettings ?? undefined}
            profileNotifications={profileNotifications ?? undefined}
            slackChannels={allSlackChannels ?? []}
            slackConnected={slackConnected}
            refreshNotificationData={refreshNotificationData}
            loading={!postSave ? loadingUserNotficationSettings || loadingProfileNotifications : false}
          />
        </Box>
        <Box sx={{ mt: '60px' }}>
          <NotificationsTestingMode />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
