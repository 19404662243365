import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef, PaginationState, Row, SortingState } from '@tanstack/react-table';
import { useDebouncedCallback } from 'use-debounce';

import { SuperAdminBillingCompanySubscriptionEditForm } from '../components/super-admin-billing-company-subscription-edit-form.component';
import { SuperAdminBillingCompanySubscriptionEditModuleForm } from '../components/super-admin-billing-company-subscription-edit-module-form.component';

import { CompanyEndpoints } from '@/api-client/company.api';
import { CompanyAPI } from '@/api-client/index.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { FreeProductPlans, PaidProductPlans } from '@/models/subscription.model';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableFilter } from '@/v2/components/table/table-filter.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SuperAdminBillingPlanEditModal } from '@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-edit-modal.component';
import {
  CompanySubscription,
  SubscribedPlanDefinition,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { toTitleCase } from '@/v2/util/string.util';

interface Props {
  readonly pageConfig: PageConfig;
}

export function SuperAdminBillingCompanySubscriptionsPage({ pageConfig }: Props) {
  const [companySubscriptions, setCompanySubscriptions] = useState<CompanySubscription[]>([]);
  // const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState<boolean>(false);
  const [selectedCompanySubscriptionToEdit, setSelectedCompanySubscriptionToEdit] = useState<CompanySubscription>();
  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isEditModuleDrawerOpen, setIsEditModuleDrawerOpen] = useState<boolean>(false);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [editModalCloseIcon, setEditModalCloseIcon] = useState<'close' | 'back'>('close');
  const [moduleBeingEdited, setModuleBeingEdited] = useState<SubscribedPlanDefinition | undefined>(undefined);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [filterString, setFilterString] = useState<string>(``);
  const { data: availablePlans, isLoading: loadingPlans } = useApiClient(
    CompanyEndpoints.getAvailablePlanDefinitions(),
    {
      suspense: false,
    }
  );

  const [filterTypes, setFilterTypes] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const debouncedFilter = useDebouncedCallback(async (filterString: string) => {
    try {
      setFilterString(filterString);
      // Resets pagination index once a new filter is applied
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage('Failed to handle filter', 'error');
    }
  }, 500);

  const handleFilter = useCallback((event: string) => debouncedFilter.callback(event), [debouncedFilter]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const getFilterOptions = useCallback(async () => {
    const PLAN_FILTER_TYPES = [
      { label: 'Core', value: FreeProductPlans.PEOPLE_FREE.toString() },
      { label: 'People Pro', value: PaidProductPlans.PEOPLE_PRO.toString() },
      { label: 'Tech Pro', value: PaidProductPlans.APPS_PRO.toString() },
      { label: 'Growth Pro', value: PaidProductPlans.DEVICES_PRO.toString() },
      { label: 'Money Pro', value: PaidProductPlans.MONEY_PRO.toString() },
    ];

    const FILTERS = ['Plans'];

    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case 'Plans':
          filters = {
            ...filters,
            [filter]: PLAN_FILTER_TYPES,
          };
          break;
        default:
          break;
      }
    });

    setFilterTypes(filters);
  }, []);

  const fetchCompanySubscriptions = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string; plans: string }) => {
      try {
        const { subscriptions, pagination: paginationInfo } = await CompanyAPI.getAllCompanySubscriptions(params);

        setCompanySubscriptions(subscriptions || []);
        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
      } catch (error) {
        showMessage(`Something went wrong fetching company plans. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const refresh = useCallback(async () => {
    try {
      await fetchCompanySubscriptions({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split('Plans=')[1] || '',
      });
      // await fetchDiscounts();
      setIsEditModalOpen(false);
    } catch (error) {
      showMessage(`Failed to refresh. ${nestErrorMessage(error)}`, 'error');
    }
  }, [fetchCompanySubscriptions, pageIndex, searchQuery, filterString, showMessage]);

  useEffect(() => {
    (async () => {
      setIsLoadingSubscriptions(true);
      await fetchCompanySubscriptions({
        page: pageIndex.toString(),
        pageSize: pageSize.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split('Plans=')[1] || '',
      });
      // await fetchDiscounts();
      await getFilterOptions();
      setIsLoadingSubscriptions(false);
    })();
  }, [
    fetchCompanySubscriptions,
    setIsLoadingSubscriptions,
    getFilterOptions,
    pageIndex,
    pageSize,
    searchQuery,
    filterString,
  ]);

  const companyPlanColumn = useMemo<ColumnDef<CompanySubscription, unknown>[]>(
    () => [
      {
        header: () => 'Company id',
        accessorFn: (row) => row,
        id: 'companyId',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original?.companyId}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 50,
      },
      {
        header: () => 'Company name',
        accessorFn: (row) => row,
        id: 'companyName',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.company?.name ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original.company?.name}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          ),
        size: 150,
      },
      {
        header: () => 'Seats method',
        accessorFn: (row) => row,
        id: 'seatsMethod',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original?.seatsMethod ? toTitleCase(original.seatsMethod) : '-'}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 90,
      },
      {
        header: () => 'Subscription type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original?.type ? toTitleCase(original.type) : '-'}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 70,
      },
      {
        header: () => 'Modules',
        accessorFn: (row) => row,
        id: 'modules',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.companyId && original.subscribedPlans?.length > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original.subscribedPlans?.length > 0
                  ? original.subscribedPlans.map((p) => p.planName).join(', ')
                  : 'None'}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 220,
      },
      {
        header: () => 'End date',
        accessorFn: (row) => row,
        id: 'endDate',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original?.endDate ? dateAPItoDisplay(original?.endDate) : '—'}
              </Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 220,
      },
    ],
    []
  );

  const handleRowClick = useCallback((row: Row<CompanySubscription>) => {
    setSelectedCompanySubscriptionToEdit(row.original);
    setIsEditModalOpen(true);
  }, []);

  const handleEditModule = useCallback(
    (moduleToEdit: SubscribedPlanDefinition, updatedSubscription: CompanySubscription) => {
      setEditModalCloseIcon('back');
      setModuleBeingEdited(moduleToEdit);
      setSelectedCompanySubscriptionToEdit(updatedSubscription);
      setIsEditModuleDrawerOpen(true);
    },
    []
  );

  const updateModuleInSubscription = (updatedModule: SubscribedPlanDefinition) => {
    // Check if selectedCompanySubscriptionToEdit and subscribedPlans are defined
    if (!selectedCompanySubscriptionToEdit || !selectedCompanySubscriptionToEdit.subscribedPlans) {
      console.error('selectedCompanySubscriptionToEdit or subscribedPlans is not defined');
      return;
    }

    const moduleIndex = selectedCompanySubscriptionToEdit.subscribedPlans.findIndex(
      (p) => p.planDefinitionId === updatedModule.planDefinitionId
    );

    if (moduleIndex >= 0) {
      const updatedSubscription = {
        ...selectedCompanySubscriptionToEdit,
        subscribedPlans: [
          ...selectedCompanySubscriptionToEdit.subscribedPlans.slice(0, moduleIndex),
          updatedModule,
          ...selectedCompanySubscriptionToEdit.subscribedPlans.slice(moduleIndex + 1),
        ],
      };

      setSelectedCompanySubscriptionToEdit(updatedSubscription);
    } else {
      console.error('Module not found in the current subscription plans');
    }

    setIsEditModuleDrawerOpen(false);
  };

  const handleDeleteModule = (moduleToDelete: SubscribedPlanDefinition) => {
    // Check if selectedCompanySubscriptionToEdit and subscribedPlans are defined
    if (!selectedCompanySubscriptionToEdit || !selectedCompanySubscriptionToEdit.subscribedPlans) {
      console.error('selectedCompanySubscriptionToEdit or subscribedPlans is not defined');
      return;
    }

    const updatedSubscription = {
      ...selectedCompanySubscriptionToEdit,
      subscribedPlans: selectedCompanySubscriptionToEdit.subscribedPlans.filter(
        (p) => p.planDefinitionId !== moduleToDelete.planDefinitionId
      ),
    };

    setSelectedCompanySubscriptionToEdit(updatedSubscription);
    setIsEditModuleDrawerOpen(false);
  };

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Billing</Typography>}
        showAction
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={isLoadingSubscriptions} secondLevel>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            ...spacing.mb20,
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <TableSearch
            style={{ width: '15em' }}
            query={searchQuery}
            handleChange={(e) => {
              setSearchQuery(e.target.value?.trim() ?? '');
              setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
            }}
            placeholder="Enter company id or name..."
          />

          {filterTypes && (
            <TableFilter filterTypes={filterTypes} setFilterString={handleFilter} filterString={filterString} />
          )}
        </Box>
        {!isLoadingSubscriptions && (
          <>
            <BasicServerTable<CompanySubscription>
              rowData={[...companySubscriptions]}
              columnData={companyPlanColumn}
              sorting={sorting}
              setSorting={setSorting}
              pagination={pagination}
              setPagination={setPagination}
              totalPages={totalPages}
              totalItems={totalItems}
              rowClick={handleRowClick}
            />

            {selectedCompanySubscriptionToEdit && availablePlans && (!loadingPlans || !isLoadingSubscriptions) && (
              <SuperAdminBillingPlanEditModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen}>
                <SuperAdminBillingCompanySubscriptionEditForm
                  key={JSON.stringify(selectedCompanySubscriptionToEdit)}
                  companySubscription={{ ...selectedCompanySubscriptionToEdit }}
                  availablePlans={availablePlans}
                  onSave={refresh}
                  onEditModule={handleEditModule}
                />
              </SuperAdminBillingPlanEditModal>
            )}
            {moduleBeingEdited &&
              selectedCompanySubscriptionToEdit &&
              availablePlans &&
              isEditModuleDrawerOpen &&
              (!loadingPlans || !isLoadingSubscriptions) && (
                <SuperAdminBillingPlanEditModal
                  isOpen={isEditModuleDrawerOpen}
                  setIsOpen={setIsEditModuleDrawerOpen}
                  closeIcon={editModalCloseIcon}
                  afterClose={() => {
                    setModuleBeingEdited(undefined);
                    setEditModalCloseIcon('close');
                  }}
                >
                  <SuperAdminBillingCompanySubscriptionEditModuleForm
                    selectedModule={{ ...moduleBeingEdited }}
                    updateSubscriptionInParentDrawer={updateModuleInSubscription}
                    companyName={selectedCompanySubscriptionToEdit?.company?.name ?? ''}
                    subscriptionCurrency={selectedCompanySubscriptionToEdit?.currency}
                    handleDeleteModule={handleDeleteModule}
                  />
                </SuperAdminBillingPlanEditModal>
              )}
          </>
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
}
