import axios from 'axios';

import {
  AccountingSettingUpdate,
  GeneralSettingUpdate,
  ExternalAccountingCode,
  PaymentTypeMembersFormData,
  PaymentTypeSettingAccountingAppConfig,
  PaymentTypeSettings,
  PaymentTypeSettingsUpsert,
  SyncSettingUpdate,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class PaymentTypeSettingsAPI {
  static async create(paymentTypeSetting: PaymentTypeSettingsUpsert): Promise<number> {
    return (await axios.post(`/apiv2/payment-type-settings`, paymentTypeSetting)).data;
  }

  static async update(typeId: number, paymentTypeSetting: PaymentTypeSettingsUpsert) {
    return (await axios.patch(`/apiv2/payment-type-settings/${typeId}`, paymentTypeSetting)).data;
  }

  static async updateGeneral(typeId: number, GeneralSettingUpdate: GeneralSettingUpdate) {
    return (await axios.patch(`/apiv2/payment-type-settings/${typeId}/general`, GeneralSettingUpdate)).data;
  }

  static async updateAccounting(typeId: number, accountingSettingUpdate: AccountingSettingUpdate) {
    return (await axios.patch(`/apiv2/payment-type-settings/${typeId}/accounting`, accountingSettingUpdate)).data;
  }

  static async updateSyncSetting(typeId: number, syncSettingUpdate: SyncSettingUpdate) {
    return (await axios.patch(`/apiv2/payment-type-settings/${typeId}/sync-setting`, syncSettingUpdate)).data;
  }

  static async updateApproval(typeId: number, approvalRuleId: number) {
    return (await axios.patch(`/apiv2/payment-type-settings/${typeId}/approval`, { approvalRuleId })).data;
  }

  static async updatePaymentTypeMembers(typeId: number, data: PaymentTypeMembersFormData): Promise<void> {
    await axios.patch(`/apiv2/payment-type-settings/${typeId}/members`, data);
  }

  static async delete(typeId: number) {
    return (await axios.delete(`/apiv2/payment-type-settings/${typeId}`)).data;
  }

  static async deleteAccountingCodeFromPaymentTypeSetting(typeId: number, code: number) {
    return (await axios.delete(`/apiv2/payment-type-settings/${typeId}/accountingCode/${code}`)).data;
  }

  static async getPaymentTypeSettingsForContractorInvoice(): Promise<PaymentTypeSettingAccountingAppConfig> {
    return (await axios.get('/apiv2/payment-type-settings?type=Invoice')).data;
  }

  static initiateAccountingAppConnection(appStub: string, paymentType: string, typeId: number) {
    return `/apiv2/payment-type-settings/app/${appStub}/${paymentType}/${typeId}/initiate`;
  }
}

export class PaymentTypeSettingsEndpoints {
  static getPaymentTypeSettingsForCompanyId(): Endpoint<PaymentTypeSettingAccountingAppConfig> {
    return {
      url: `/apiv2/payment-type-settings`,
    };
  }

  static accountingAppConfigured(): Endpoint<boolean> {
    return {
      url: '/apiv2/contractor/invoice/is-accounting-app-configured',
    };
  }

  static getExpenseTypesForCompanyId(): Endpoint<PaymentTypeSettingAccountingAppConfig> {
    return {
      url: `/apiv2/payment-type-settings?type=Expense`,
    };
  }

  static getTypeSettingById(id: number): Endpoint<PaymentTypeSettings> {
    return {
      url: `/apiv2/payment-type-settings/${id}`,
    };
  }

  static getInvoiceTypesForCompanyId(): Endpoint<PaymentTypeSettingAccountingAppConfig> {
    return {
      url: `/apiv2/payment-type-settings?type=Invoice`,
    };
  }

  static getAccountingCodesFromProvider(): Endpoint<ExternalAccountingCode[]> {
    return {
      url: '/apiv2/company/payroll/nominal-code/accounting',
    };
  }
}
