import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { capitalize } from 'lodash';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import {
  isCurrentDateTimeLessThanOrEqualToSpecified,
  isTimingCorrectlySet,
} from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.util';
import { SurveyCycleLaunch } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

interface ISCScheduledLaunchView {
  readonly surveyCycleLaunchDetails: SurveyCycleLaunch;
  readonly cycleId: string;
  readonly goBack: () => void;
}
export const SCScheduledLaunchView = ({ surveyCycleLaunchDetails, cycleId, goBack }: ISCScheduledLaunchView) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const isStartNow = useMemo(
    () => Boolean(surveyCycleLaunchDetails && surveyCycleLaunchDetails?.timelineSettings?.startNow),
    [surveyCycleLaunchDetails]
  );

  const isTodayOrFuture = useMemo(() => {
    return surveyCycleLaunchDetails && surveyCycleLaunchDetails?.timelineSettings?.startDate
      ? isCurrentDateTimeLessThanOrEqualToSpecified(surveyCycleLaunchDetails?.timelineSettings) &&
          surveyCycleLaunchDetails.state === CycleState.Draft
      : false;
  }, [surveyCycleLaunchDetails]);

  const launchSurveyCycle = async () => {
    try {
      setLoading(true);
      await SurveyCycleAPI.launchSurveyCycle(cycleId);
      goBack();
      showMessage('Successfully launched the cycle', 'success');
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const showActionButtons = useMemo(
    () =>
      surveyCycleLaunchDetails &&
      surveyCycleLaunchDetails.state === CycleState.Draft &&
      surveyCycleLaunchDetails.totalQuestions > 0 &&
      Boolean(surveyCycleLaunchDetails.participantIds?.length && surveyCycleLaunchDetails.participantIds?.length > 0) &&
      surveyCycleLaunchDetails.timelineSettings &&
      isTimingCorrectlySet(surveyCycleLaunchDetails?.timelineSettings),
    [surveyCycleLaunchDetails]
  );

  return (
    <SettingsSubsectionContent
      sections={[
        {
          contentWidth: '100%',
          headerWidth: '100%',
          title: 'Start survey',
          items: [
            {
              type: SectionItemType.Pair,
              label: 'Type',
              value: <Typography variant="title4">{capitalize(surveyCycleLaunchDetails.type)}</Typography>,
            },
            {
              type: SectionItemType.Pair,
              label: 'Number of questions',
              value: (
                <Typography
                  variant="title4"
                  color={surveyCycleLaunchDetails.totalQuestions > 0 ? 'DarkGrey' : 'RedDark'}
                >
                  {surveyCycleLaunchDetails.totalQuestions ?? 'Please add some questions'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: 'Participants',
              value: (
                <Typography
                  variant="title4"
                  color={
                    surveyCycleLaunchDetails?.participantIds?.length &&
                    surveyCycleLaunchDetails?.participantIds?.length > 0
                      ? 'DarkGrey'
                      : 'RedDark'
                  }
                >
                  {surveyCycleLaunchDetails?.participantIds?.length ?? 'Please add some participants'}
                </Typography>
              ),
            },
            {
              type: SectionItemType.Pair,
              label: polyglot.t('SurveyParticipantDrawer.anonymousSwitch'),
              value: (
                <Typography variant="title4" color={'DarkGrey'}>
                  {surveyCycleLaunchDetails?.visibilitySettings?.anonymiseAnswers ? 'Enabled' : 'Disabled'}
                </Typography>
              ),
            },
            ...(surveyCycleLaunchDetails?.visibilitySettings &&
            surveyCycleLaunchDetails?.visibilitySettings?.anonymityType
              ? [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t('SurveyParticipantDrawer.anonymityType'),
                    value: (
                      <Typography variant="title4" color={'DarkGrey'}>
                        {capitalize(surveyCycleLaunchDetails?.visibilitySettings?.anonymityType)}
                      </Typography>
                    ),
                  },
                ]
              : []),
            ...(isStartNow
              ? []
              : [
                  {
                    type: SectionItemType.Pair,
                    label: 'Start date',
                    value: surveyCycleLaunchDetails.timelineSettings?.startDate ? (
                      <Typography variant="title4" color={isTodayOrFuture ? 'DarkGrey' : 'RedDark'}>
                        {new LocalDate(surveyCycleLaunchDetails.timelineSettings?.startDate)
                          .getDate()
                          .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
                      </Typography>
                    ) : (
                      'NA'
                    ),
                  },
                  {
                    type: SectionItemType.Pair,
                    label: 'Start time (GMT)',
                    value: surveyCycleLaunchDetails.timelineSettings?.startTime ? (
                      <Typography variant="title4" color={isTodayOrFuture ? 'DarkGrey' : 'RedDark'}>
                        {surveyCycleLaunchDetails.timelineSettings?.startTime}
                        {surveyCycleLaunchDetails.timelineSettings?.startTimeMeridiem}
                      </Typography>
                    ) : (
                      'NA'
                    ),
                  },
                ]),
            {
              type: SectionItemType.Pair,
              label: polyglot.t('SurveyParticipantDrawer.surveyOpenFor'),
              value: (
                <Typography variant="title4" color={'DarkGrey'}>
                  {surveyCycleLaunchDetails?.timelineSettings?.surveyOpenFor} days
                </Typography>
              ),
            },
            {
              type: SectionItemType.Component,
              value: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g4 }}>
                  <div>
                    <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => goBack()}>
                      Save and close
                    </ButtonComponent>
                  </div>
                  {showActionButtons && (
                    <div>
                      {isStartNow || isTodayOrFuture ? (
                        <LoaderButton
                          sizeVariant="small"
                          colorVariant="primary"
                          onClick={launchSurveyCycle}
                          loading={loading}
                          disabled={loading}
                        >
                          {isStartNow ? 'Start survey' : 'Schedule survey'}
                        </LoaderButton>
                      ) : null}
                    </div>
                  )}
                </Box>
              ),
              hidden: Boolean(
                surveyCycleLaunchDetails &&
                  surveyCycleLaunchDetails.state &&
                  (surveyCycleLaunchDetails.state === CycleState.Ongoing ||
                    surveyCycleLaunchDetails.state === CycleState.Completed)
              ),
            },
          ],
        },
      ]}
    />
  );
};
