import { Fragment } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ScheduleTimeEntry, WeekDay } from '@v2/feature/attendance/attendance.interface';
import { LabelValueViewItem } from '@v2/feature/dashboard/features/sections/user-attendance/components/label-value-view-item.component';
import { WidgetInfoMessage } from '@v2/feature/dashboard/features/sections/user-shift/user-shift.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { format } from 'date-fns';

interface ComponentProps {
  clockInEarlyCapMinutes: number;
  theFollowingScheduledDay: {
    day: WeekDay;
    daySchedule: ScheduleTimeEntry;
  } | null;
  isStarted: boolean;
  hasStartedBreak: boolean;
  isEnded: boolean;
  isStartingShift: boolean;
  isFetchingLocation: boolean;
  lessThanEarlyCapMinutesUntilStart: boolean;
  isStartingBreak: boolean;
  startShift: () => Promise<void>;
  startBreak: () => Promise<void>;
  endShift: () => Promise<void>;
  endBreak: () => Promise<void>;
  isEndingBreak: boolean;
  isEndingShift: boolean;
  isScheduledForToday: boolean;
  selectedDate: string;
  time: string;
  shiftLoggedHours: string[];
  shiftSelectedLoggedHours: string[];
}

export const UserShiftHandlerDrawerContent = ({
  clockInEarlyCapMinutes,
  theFollowingScheduledDay,
  isStarted,
  hasStartedBreak,
  isEnded,
  time,
  shiftLoggedHours,
  isStartingShift,
  isFetchingLocation,
  lessThanEarlyCapMinutesUntilStart,
  isStartingBreak,
  startShift,
  startBreak,
  endShift,
  endBreak,
  isEndingBreak,
  isEndingShift,
  isScheduledForToday,
  selectedDate,
  shiftSelectedLoggedHours,
}: ComponentProps) => {
  const { polyglot } = usePolyglot();
  return (
    <Fragment>
      <Typography variant="title2">{polyglot.t('AttendanceDomain.Attendance')}</Typography>
      {isScheduledForToday ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, ...spacing.mt20 }}>
          <LabelValueViewItem
            label={polyglot.t('AttendanceDomain.AttendanceShift.Status')}
            value={
              <WidgetInfoMessage
                isScheduledForToday={isScheduledForToday}
                scheduleDay={theFollowingScheduledDay}
                isStarted={isStarted}
                hasStartedBreak={hasStartedBreak}
                time={time}
                isEnded={isEnded}
                clockInEarlyCapMinutes={clockInEarlyCapMinutes}
              />
            }
          />
          <LabelValueViewItem
            label={polyglot.t('AttendanceDomain.AttendanceShift.Date')}
            value={`${format(new LocalDate(selectedDate).getDate(), 'd MMM yyyy')}`}
          />
          <Box sx={{ mt: spacing.m30, display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Typography variant="title4">{polyglot.t('AttendanceDomain.AttendanceShift.YourShift')}</Typography>
            {shiftLoggedHours && (
              <LabelValueViewItem
                label={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Start')}
                value={shiftLoggedHours[0]}
              />
            )}
            {shiftLoggedHours && (
              <LabelValueViewItem
                label={polyglot.t('AttendanceDomain.AttendanceShift.Actions.End')}
                value={shiftLoggedHours[1]}
              />
            )}
            {shiftLoggedHours && (
              <LabelValueViewItem
                label={polyglot.t('AttendanceDomain.AttendanceShift.BreakDuration')}
                value={shiftLoggedHours[2]}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              justifyContent: 'space-between',
              gap: spacing.g20,
              ...spacing.mt40,
            }}
          >
            {!isEnded && (
              <Box sx={{ display: 'flex', gap: spacing.g5, width: '100%' }}>
                {!isStarted && (
                  <LoaderButton
                    name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.ClockIn')}
                    loading={isStartingShift || isFetchingLocation}
                    type="button"
                    fullWidth
                    onClick={startShift}
                    disabled={!lessThanEarlyCapMinutesUntilStart}
                    colorVariant="primary"
                    sizeVariant="medium"
                  />
                )}

                {isStarted && !hasStartedBreak && (
                  <LoaderButton
                    name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Break')}
                    loading={isStartingBreak}
                    type="button"
                    fullWidth
                    onClick={startBreak}
                    colorVariant="secondary"
                    sizeVariant="medium"
                  />
                )}
                {isStarted && !hasStartedBreak && (
                  <LoaderButton
                    name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Finish')}
                    loading={isEndingShift}
                    type="button"
                    fullWidth
                    onClick={endShift}
                    colorVariant="danger"
                    sizeVariant="medium"
                  />
                )}

                {isStarted && hasStartedBreak && (
                  <LoaderButton
                    name={polyglot.t('AttendanceDomain.AttendanceShift.Actions.EndBreak')}
                    loading={isEndingBreak}
                    type="button"
                    fullWidth
                    onClick={endBreak}
                    colorVariant="secondary"
                    sizeVariant="medium"
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, ...spacing.mt20 }}>
          <LabelValueViewItem
            label={polyglot.t('AttendanceDomain.AttendanceShift.Status')}
            value={
              <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                Can not log hours for today
              </Typography>
            }
          />
          <LabelValueViewItem
            label={polyglot.t('AttendanceDomain.AttendanceShift.Date')}
            value={`${format(new LocalDate(selectedDate).getDate(), 'd MMM yyyy')}`}
          />

          <Box sx={{ mt: spacing.m30, display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Typography variant="title4">Your shift</Typography>
            {shiftSelectedLoggedHours && (
              <LabelValueViewItem
                label={polyglot.t('AttendanceDomain.AttendanceShift.Actions.Start')}
                value={shiftSelectedLoggedHours[0]}
              />
            )}
            {shiftSelectedLoggedHours && (
              <LabelValueViewItem
                label={polyglot.t('AttendanceDomain.AttendanceShift.Actions.End')}
                value={shiftSelectedLoggedHours[1]}
              />
            )}
            {shiftSelectedLoggedHours && (
              <LabelValueViewItem
                label={polyglot.t('AttendanceDomain.AttendanceShift.BreakDuration')}
                value={shiftSelectedLoggedHours[2]}
              />
            )}
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
