import {
  AddressFieldsRequirements,
  ContractorInvoice,
  ExpenseStatus,
  PayerType,
  YapilyInstitutions,
} from '@v2/feature/payments/payments.interface';
import { UserSummaryDto } from '@v2/feature/user/dtos/user-summary.dto';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { Dayjs } from 'dayjs';

export enum TransactionType {
  DomesticSinglePayment = 'DOMESTIC_SINGLE_PAYMENT',
  BulkPayment = 'BULK_PAYMENT',
  SinglePaymentSortCode = 'SINGLE_PAYMENT_SORTCODE',
  InternationalSinglePayment = 'INTERNATIONAL_SINGLE_PAYMENT',
  DomesticScheduledPayment = 'DOMESTIC_SCHEDULED_PAYMENT',
  DomesticPeriodicPayment = 'DOMESTIC_PERIODIC_PAYMENT',
}

export enum TransactionStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum PaymentCategory {
  Payroll = 'Payroll',
  Expense = 'Expense',
  Invoice = 'Invoice',
}

export interface TransactionDto {
  transactionId: number;
  institutionId: string;
  uuid: string;
  status: TransactionStatus;
  type: TransactionType;
  createdAt: Date;
  updatedAt: Date | null | Dayjs | string;
}

export enum PaymentStatus {
  All = 'All',
  Paid = 'Paid',
  MarkPaid = 'Mark Paid',
  Failed = 'Failed',
  Pending = 'Pending',
  NotPaid = 'Not Paid',
  Processing = 'Processing',
  Outstanding = 'Outstanding',
  Unknown = 'Unknown',
}

export enum PaymentType {
  Employee = 'Employee',
  Taxes = 'Taxes',
  Pension = 'Pension',
  Expense = 'Expense',
  Invoice = 'Invoice',
}

export enum PaymentCategoryType {
  EmployeeExpense = 'Employee expense',
  EmployeeInvoice = 'Employee invoice',
  CompanyTaxes = 'Company taxes',
}

export interface PaymentDto {
  id: number;
  transactionId: number | null;
  amount: number;
  currency: CurrencyShort;
  reference: string;
  category: PaymentCategory;
  status: PaymentStatus | ExpenseStatus;
  type: PaymentType;
  message?: string;
  userId?: number | null;
  username?: string;
  dueDate?: string;
  transaction?: TransactionDto;
  createdAt: Date;
  updatedAt: Date | null | Dayjs | string;
  paymentOverdue: boolean;
  bankAccountMissing: boolean;
  userAddressMissing: boolean;
  referenceMissing: boolean;
  accountName?: string;
  bankName?: string;
  accountNumber?: string;
  sortCode?: string;
  markPaid: boolean;
  paidBy: number | null;
  paidOn: Date | null;
  paidToAccountName: string | null;
  paidToAccountNumber: string | null;
  paidToSortCode: string | null;
  paidToBankName: string | null;
  isPartOfGroup: boolean;
  paidByUser?: Pick<UserSummaryDto, 'userId' | 'firstName' | 'lastName'> | null;
}

export interface SinglePaymentRequestDto {
  userId?: number | null;
  reference: string;
  amount: number;
  currency: string;
  category: PaymentCategory;
  type: PaymentType;
  dueDate?: string;
  institutionId?: string;
}

export interface SinglePaymentAccountInfo {
  accountNumber: string | null;
  sortCode: string | null;
  userId?: number | null | undefined;
  reference: string;
  amount: number;
  currency?: string | undefined;
  category: PaymentCategory;
  type: PaymentType;
  dueDate?: string;
  institutionId?: string;
}

interface InstitutionCountry {
  displayName: string;
  countryCode2: string;
}

export interface InstitutionConfig {
  singlePayment: {
    supportsImmediatePayment: boolean;
    supportsScheduledPayment: boolean;
    addressFieldsRequired: AddressFieldsRequirements[] | null;
    payerRequired: boolean;
    beneficiariesRequired: boolean;
    executionDateTimeDays: number | null;
    otherDetails?: string[];
  } | null;
  bulkPayment: {
    supportsImmediatePayments: boolean;
    supportsScheduledPayments: boolean;
    bulkLimits: {
      min: number;
      max: number;
    };
    addressFieldsRequired: AddressFieldsRequirements[] | null;
    payerRequired: boolean;
    payerType: PayerType | null;
    beneficiariesRequired: boolean;
    executionDateTimeDays: number | null;
    otherDetails?: string[];
  } | null;
  reference: {
    max: number;
    min: number;
  } | null;
}

export interface PaymentInstitutionDto {
  id: YapilyInstitutions;
  name: string;
  fullName: string;
  countries: InstitutionCountry[];
  environmentType?: string;
  credentialsType?: string;
  media: { source: string; type: string }[];
  features: string[];
  config?: InstitutionConfig;
}

export interface PaymentResponseDto {
  status: string;
  authorisationUrl: string;
  transactionId: number;
}

export interface BasicBankAccountDetails {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  bankName?: string;
}

export type CreateContractorInvoiceDto = Pick<
  ContractorInvoice,
  | 'typeId'
  | 'invoiceNumber'
  | 'invoiceDate'
  | 'dueDate'
  | 'from'
  | 'currency'
  | 'lineItems'
  | 'isVatIncluded'
  | 'vatNumber'
  | 'taxRate'
  | 'taxAmount'
  | 'taxId'
  | 'notes'
  | 'amount'
  | 'totalAmount'
  | 'status'
>;
