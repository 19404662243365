import React from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { SuperAdminRefinancingDevicesPage } from '@v2/feature/super-admin/features/super-admin-refinancing/pages/super-admin-refinancing-devices.page';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SUPER_ADMIN_REFINANCING_DEVICES_ROUTE } from '@/lib/routes';

interface SuperAdminRefinancingRouterProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
}

export const SuperAdminRefinancingRouter = ({ companies }: SuperAdminRefinancingRouterProps): JSX.Element => {
  return (
    <Switch>
      <Route path={SUPER_ADMIN_REFINANCING_DEVICES_ROUTE}>
        <SuperAdminRefinancingDevicesPage companies={companies} />
      </Route>

      <Redirect to={SUPER_ADMIN_REFINANCING_DEVICES_ROUTE} />
    </Switch>
  );
};
