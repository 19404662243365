import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CommaSeparatedTextfieldComponent } from '@/v2/components/forms/comma-separated-textfield.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import {
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { detailTermSx, detailValueSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';
import { isValidCommaSeparatedEmail } from '@/v2/util/string.util';

interface Props {
  appDetails: InstalledAppDto | undefined;
}

export const AppCCForNewAccounts = ({ appDetails }: Props): JSX.Element => {
  const fieldLabel = 'Activation email CC';
  const [edit, setEdit] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [appEmailCC, setAppEmailCC] = useState<string>('');
  const invalidEmailText = useMemo(() => {
    if (appEmailCC && !isValidCommaSeparatedEmail(appEmailCC)) {
      return `The email(s) provided is invalid`;
    }

    return '';
  }, [appEmailCC]);

  const refreshAppEmail = useCallback(async () => {
    const updatedList = await AppIntegrationAPI.getCCEmailList(appDetails?.stub ?? '');
    setAppEmailCC(updatedList ? updatedList.join(', ') : '');
  }, [appDetails?.stub]);

  useEffect(() => {
    refreshAppEmail();
  }, [refreshAppEmail]);

  const saveCCEmailListForApp = async () => {
    try {
      setLoading(true);
      if (appDetails) await AppIntegrationAPI.saveCCEmailList(appDetails?.stub, appEmailCC);
      showMessage('Successfully saved App email CC list!', 'success');
      setEdit(false);
    } catch (error) {
      showMessage(`Failed to save App email CC list: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
      refreshAppEmail();
    }
  };

  return (
    <Box sx={{ width: '550px', mt: spacing.m60 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>App configuration</Typography>
        <IconButton sx={{ ...tableIconButtonSx, display: `${edit ? 'none' : ''}` }} onClick={() => setEdit(true)}>
          <EditIcon {...actionIconSize} />
        </IconButton>
      </Box>
      {!edit ? (
        <Box component="dl" sx={{ definitionListSx }}>
          <Box sx={{ ...definitionSx, mt: '30px', display: 'flex', alignItems: 'center' }}>
            <Typography component="dt" sx={definitionTermSx}>
              {fieldLabel}
            </Typography>
            <Typography component="dd" sx={definitionValueSx}>
              {appEmailCC?.length > 0 ? appEmailCC : 'None'}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', mt: '30px', alignItems: 'center' }} component="section">
            <Box sx={{ minWidth: '190px', maxWidth: '190px' }}>
              <Typography sx={detailTermSx}> {fieldLabel}</Typography>
            </Box>
            <Box sx={detailValueSx}>
              <CommaSeparatedTextfieldComponent
                fieldName="selectedEmailRecipients"
                fieldValue={appEmailCC ?? ''}
                setCommaSeparatedValue={(fieldName, value) => {
                  setAppEmailCC(value);
                }}
                errorText={invalidEmailText ?? ''}
                helperText={'Separate multiple emails by a comma'}
              />
            </Box>
          </Box>
          <Stack sx={{ flexFlow: 'row', my: spacing.m50, gap: spacing.g10 }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="secondary"
              disabled={false}
              onClick={() => setEdit(false)}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              disabled={(!!invalidEmailText && invalidEmailText?.length > 0) || loading}
              onClick={() => saveCCEmailListForApp()}
            >
              Save
            </ButtonComponent>
          </Stack>
        </>
      )}
    </Box>
  );
};
