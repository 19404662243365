import { useState } from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ButtonNameLoader } from '@v2/components/button-name-loader.component';
import { InstalledAppDto } from '@v2/feature/app-integration/app-integration.dto';
import { AppDetailsAPI } from '@v2/feature/app-integration/features/app-details/app-details.api';
import { AppConfigurationDto } from '@v2/feature/app-integration/features/app-details/app-details.dto';

import { primarySmallBtn, secondarySmallBtn } from '@/v2/styles/buttons.styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: 1,
};

interface AppDetailConfigurationModalProps {
  open: boolean;
  handleClose: VoidFunction;
  app: InstalledAppDto | undefined;
  appStub: string;
  refresh: VoidFunction;
}

export const AppDetailConfigurationModal = ({
  open,
  handleClose,
  app,
  appStub,
  refresh,
}: AppDetailConfigurationModalProps) => {
  const [configuration, setConfiguration] = useState<AppConfigurationDto | undefined>(undefined);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  async function onSaveConfiguration() {
    setSaveLoading(true);
    if (configuration) {
      await AppDetailsAPI.saveIntegrationConfiguration(appStub, configuration);
      handleClose();
      refresh();
    }
    setSaveLoading(false);
  }

  function isConfigurationValid() {
    if (!app?.requiredConfiguration) return false;

    const isInvalid = app?.requiredConfiguration.some((config) => {
      if (!configuration || !config) return true;
      const propConfig = configuration[config.name as keyof AppConfigurationDto];
      return !propConfig || (config.pattern && !propConfig.match(config.pattern));
    });
    return !isInvalid;
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <Box>
          <Typography variant="h6">Please add your organisation name</Typography>
        </Box>
        <div>
          {app?.requiredConfiguration?.map((configItem) => (
            <>
              <Box key={configItem.name} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  name={configItem.name}
                  onChange={(event) => {
                    setConfiguration({
                      ...configuration,
                      [event.target.name]: event.target.value,
                    });
                  }}
                  placeholder={configItem.label}
                  InputProps={{
                    inputProps: {
                      type: 'text',
                      min: 1,
                      max: 39,
                    },
                  }}
                  size="small"
                  sx={{ my: 2 }}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  sx={primarySmallBtn}
                  onClick={async (event) => {
                    if (event) event.preventDefault();
                    await onSaveConfiguration();
                  }}
                  disabled={!isConfigurationValid()}
                >
                  <ButtonNameLoader name="Save configuration" loading={saveLoading} />
                </Button>
                <Button sx={secondarySmallBtn} onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Box>
            </>
          ))}
        </div>
      </Box>
    </Modal>
  );
};
