import { CompanyOnboardingDto } from '@shared/modules/company/company.types';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios, { AxiosResponse } from 'axios';

import { User } from '@/models';
import { AuthSignUpDto, LoginResponseDto, SendMFAEnableCodeResponseDto } from '@/v2/feature/auth/auth.dto';
import { AuthMeUser } from '@/v2/feature/auth/auth.interface';
import { SSOCheck, SSOLoginCheck } from '@/v2/feature/auth/features/auth-login/auth-login.page';
import { PasswordSetResult } from '@/v2/feature/user-onboarding/by-admin/interface/onboarding.interface';

export class AuthAPI {
  static lastCallTimestamp: number = 0;
  static cacheDuration: number = 1000;
  static authMeResponse: Promise<AxiosResponse<any>> | null = null;

  static async logout(): Promise<void> {
    this.authMeResponse = null;
    await axios.post('/apiv2/auth/logout');
  }

  static async signupEmail(signup: AuthSignUpDto): Promise<void> {
    await axios.post('/apiv2/auth/signup', signup);
  }

  static async signupAccountCreation(onboarding: CompanyOnboardingDto): Promise<User> {
    return (await axios.post('/apiv2/company/onboarding', onboarding)).data ?? [];
  }

  static async forgotPassword(payload: { email?: string; emailIndex?: number }): Promise<{ emails: string[] }> {
    return (await axios.post('/apiv2/auth/password/forgot', payload)).data ?? [];
  }

  static async resetPassword(password: string, token: string | undefined, email: string | undefined): Promise<void> {
    return (await axios.post('/apiv2/auth/password/reset', { password, token, email })).data ?? [];
  }

  static async login(
    username: string,
    password: string,
    mfaCode?: string,
    deviceId?: string
  ): Promise<LoginResponseDto> {
    return (await axios.post('/apiv2/auth/login', { username, password, mfaCode, deviceId })).data;
  }

  static async ssoSamlLogin(email: string, idToken: string, deviceId?: string): Promise<LoginResponseDto> {
    return (await axios.post('/apiv2/auth/sso/saml/login', { email, idToken, deviceId })).data;
  }

  static async ssoOidcLogin(email: string, idToken: string, deviceId?: string): Promise<LoginResponseDto> {
    return (await axios.post('/apiv2/auth/sso/oidc/login', { email, idToken, deviceId })).data;
  }

  static async ssoCheck(email: string): Promise<SSOLoginCheck> {
    return (await axios.get(`/apiv2/auth/ssocheck/${email}`)).data;
  }

  static async ssoCheckForCompany(): Promise<SSOCheck[]> {
    return (await axios.get(`/apiv2/auth/ssocheck`)).data;
  }

  static async mfaCheck(): Promise<boolean> {
    return (await axios.get(`/apiv2/auth/mfacheck`)).data;
  }

  static async getAuthMe(useCache = true): Promise<AuthMeUser> {
    const currentTime = Date.now();
    const lastCallGap = currentTime - this.lastCallTimestamp;
    // if not using cache, but most recent call is within cache duration (1s), then return cached
    const isCacheValid =
      (useCache && !!this.authMeResponse) || (!!this.authMeResponse && lastCallGap < this.cacheDuration);

    if (!isCacheValid) {
      this.authMeResponse = axios.get('/apiv2/auth/me');
      this.lastCallTimestamp = currentTime;
      this.authMeResponse.catch(() => {
        this.authMeResponse = null;
      });
    }

    const response = await this.authMeResponse;
    return response ? response.data : ({} as AuthMeUser);
  }

  static async getAccountData() {
    return (await axios.get('/apiv2/users/import/google-accounts')).data;
  }

  static async isPasswordSet(email: string): Promise<PasswordSetResult | undefined> {
    return (await axios.get(`/apiv2/auth/pwdcheck?email=${email}`)).data;
  }

  static async isContractRecipientPasswordSet(contractId: string): Promise<string | undefined> {
    return (await axios.get(`/apiv2/auth/contract/recipient/${contractId}`)).data;
  }

  /**
   * Request a new 2FA code to be emailed to the user (to enable MFA)
   */
  static async requestMFAEnableCode(): Promise<SendMFAEnableCodeResponseDto> {
    return (await axios.post('/apiv2/auth/mfa/init')).data;
  }

  /**
   * Enables or disable MFA for the active user.
   *
   * Enabling MFA requires the 2FA code requested using `requestMFAEnableCode()`.
   * Disabling MFA requires the user's current password
   */
  static async updateMFA(payload: { enableMFACode: string } | { disableMFAPwd: string }): Promise<void> {
    await axios.post('/apiv2/auth/mfa/update', payload);
  }
}

export class AuthEndpoints {
  static mfaCheck(): Endpoint<boolean> {
    return {
      url: '/apiv2/auth/mfacheck',
    };
  }
}
