import React, { useState } from 'react';

import { Box, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { MonitoringAPI } from '@v2/feature/monitoring/monitoring.api';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';

interface ViewQueueToCleanUpSummaryDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly queues: Map<string, number> | undefined;
}

export const ViewQueueToCleanUpSummaryDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  queues,
}: ViewQueueToCleanUpSummaryDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    {queues ? (
      <ViewQueueToCleanUpSummaryDrawerContent
        queues={queues}
        onAdd={async () => {
          await onClose();
          setIsOpen(false);
        }}
      />
    ) : (
      <Box>
        <Typography>No queues</Typography>
      </Box>
    )}
  </DrawerModal>
);

interface ViewQueueToCleanUpSummaryDrawerContentProps {
  readonly queues: Map<string, number>;
  readonly onAdd: () => void;
}

export const ViewQueueToCleanUpSummaryDrawerContent = ({
  queues,
  onAdd,
}: ViewQueueToCleanUpSummaryDrawerContentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedQueue, setSelectedQueue] = useState<string | undefined>();
  const [showMessage] = useMessage();
  const cleanQueue = async () => {
    try {
      setLoading(true);
      if (selectedQueue) {
        await MonitoringAPI.cleanInHouseMdmQueues(selectedQueue);
      }
      showMessage('Queues clean-up started', 'success');
      await onAdd();
    } catch (error) {
      showMessage(`Queues clean-up failed`, 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedQueue(event.target.value as string);
  };
  const mappedQueues = Array.from(queues);
  return (
    <>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: spacing.mt10 }}>
        Check logs, to see queues stats
      </Typography>
      <Box>
        {mappedQueues.map(([key, value]) => (
          <Box>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>Queue: {key}</Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              Number of completed jobs: {value}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ mt: spacing.mt20 }}>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, mt: spacing.mt10 }}>
          Select queue to clean up
        </Typography>
        <FormControl fullWidth sx={{ mt: spacing.mt10 }}>
          <InputLabel id="demo-simple-select-label">Queues</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedQueue}
            label="Queues"
            onChange={handleChange}
          >
            <MenuItem value="inHouseMdmDeviceSync">inHouseMdmDeviceSync</MenuItem>
            <MenuItem value="inHouseMdmLockDevice">inHouseMdmLockDevice</MenuItem>
            <MenuItem value="inHouseMdmWipeDevice">inHouseMdmWipeDevice</MenuItem>
            <MenuItem value="mdmDepQueue">mdmDepQueue</MenuItem>
            <MenuItem value="mdmEnrolmentStatus">mdmEnrolmentStatus</MenuItem>
            <MenuItem value="mdmCommandSecurityPolicyQueue">mdmCommandSecurityPolicyQueue</MenuItem>
            <MenuItem value="deviceInstalledAppQueue">deviceInstalledAppQueue</MenuItem>
            <MenuItem value="deviceManagedApplicationQueue">deviceManagedApplicationQueue</MenuItem>
            <MenuItem value="mdmDepDeviceInfoQueue">mdmDepDeviceInfoQueue</MenuItem>
            <MenuItem value="mdmQueue">mdmQueue</MenuItem>
            <MenuItem value="mdmStateSync">mdmStateSync</MenuItem>
            <MenuItem value="securityPolicyStatusQueue">securityPolicyStatusQueue</MenuItem>
            <MenuItem value="mdmFileVaultStatusQueue">mdmFileVaultStatusQueue</MenuItem>
            {/*<MenuItem value="attendanceImportStatusQueue">attendanceImportStatusQueue</MenuItem>*/}
            {/*<MenuItem value="absenceImportStatusQueue">absenceImportStatusQueue</MenuItem>*/}
            {/*<MenuItem value="userImportStatusQueue">mdmFileVaultStatusQueue</MenuItem>*/}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: spacing.mt40 }}>
        <LoaderButton
          name="Clean queue"
          fullWidth
          loading={loading}
          onClick={async () => cleanQueue()}
          colorVariant="primary"
          sizeVariant="large"
        />
      </Box>
    </>
  );
};
