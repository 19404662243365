import { Route, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { SETTINGS_SECURITY_AUDIT_TRAIL_REPORT, SETTINGS_SECURITY_ROUTE } from '@/lib/routes';
import { SecurityAuditTrailReport } from '@/v2/feature/security/security-settings/components/security-audit-report.component';
import { SecuritySettingPage } from '@/v2/feature/security/security-settings/features/security-setting.page';

export const SecuritySettingRouter = (): JSX.Element => (
  <Switch>
    {/* TO DO: Please make it a complete page with topheader */}
    <Route exact path={SETTINGS_SECURITY_AUDIT_TRAIL_REPORT}>
      <SecurityAuditTrailReport />
    </Route>
    <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_SECURITY_ROUTE}>
      <SecuritySettingPage />
    </RouteScopesControl>
  </Switch>
);
