import React, { useCallback } from 'react';

import { Box } from '@mui/material';

import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { definitionListSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

type UserProfileTaxDetailsRemoteProps = {
  countryCode: string;
  payrollId: string;
  remotePayrollValues: RemotePayrollValues;
};

export const UserProfileTaxDetailsRemote = ({
  payrollId,
  countryCode,
  remotePayrollValues,
}: UserProfileTaxDetailsRemoteProps): JSX.Element => {
  const { data: payrollSchema } = useApiClient(PayrollEndpoints.getPayrollSchemaForCountry(countryCode), {
    suspense: false,
  });

  const convertPropertyToFieldName = useCallback((schema: RemoteSchema, property: string): string => {
    return schema?.properties[property]?.title ?? property;
  }, []);

  const formatFieldValue = useCallback((schema: RemoteSchema, property: string, value: any): string => {
    const p = schema?.properties[property];
    return (
      p?.oneOf?.find((item) => item.const === value)?.title ??
      p?.presentation.options?.find((item) => item.value === value)?.label ??
      value?.toString() ??
      ''
    );
  }, []);

  const convertPayrollValuesToFieldStructures = useCallback(
    (values: RemotePayrollValues, schema: RemoteSchema): JSX.Element[] => {
      return Object.entries(values)
        .filter(([_, value]) => value !== null) // null values are not set
        .filter(([key]) => schema.properties[key]?.type !== 'array') // exclude array values for now
        .sort(
          ([a], [b]) =>
            (schema?.properties[a]?.presentation?.position ?? 0) - (schema?.properties[b]?.presentation?.position ?? 0)
        )
        .flatMap(([property, value]) => {
          const propertySchema = schema?.properties?.[property];
          if (propertySchema?.type === 'object') {
            return convertPayrollValuesToFieldStructures(
              value as RemotePayrollValues,
              (propertySchema as unknown) as RemoteSchema
            );
          }
          return (
            <FieldStructure
              fieldTitle={convertPropertyToFieldName(schema, property)}
              fieldValue={formatFieldValue(schema, property, value)}
            />
          );
        });
    },
    [convertPropertyToFieldName, formatFieldValue]
  );

  if (!payrollSchema) {
    // wait for the schema to load
    return <></>;
  }

  return (
    <Box component="dl" sx={definitionListSx}>
      {payrollId && <FieldStructure fieldTitle="Payroll ID" fieldValue={payrollId} />}
      {convertPayrollValuesToFieldStructures(remotePayrollValues, payrollSchema.schema.data)}
    </Box>
  );
};
