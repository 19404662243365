import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DevicePossessionDto,
} from '@v2/feature/device/device.dto';
import { AppleDevices, DeviceModelOs, SupportedDeviceType } from '@v2/feature/device/device.interface';
import { DeviceEncryptionPolicyTile } from '@v2/feature/device/features/device-cards/components/device-encryption-policy-tile.component';
import { DevicePolicyTile } from '@v2/feature/device/features/device-cards/components/device-policy-tile.component';
import { ActivationLockPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/inhouse-mdm-activation-lock-policy-viewer.component';
import { EncryptionPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/inhouse-mdm-encryption-policy-viewer.component';
import { LoginItemPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/inhouse-mdm-login-item-policy-viewer.component';
import { SafariBrowsingPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/inhouse-mdm-safari-browsing-policy-viewer.component';
import { WifiPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/inhouse-mdm-wifi-policy-viewer.component';
import { GeneralPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/mdm-general-policy-viewer.component';
import { OsUpdatesPolicyViewDrawer } from '@v2/feature/device/features/device-cards/components/mdm-os-updates-policy-viewer.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { spacing } from '@/v2/styles/spacing.styles';

export const DeviceProtection = ({
  devicePossession,
  appliedPolicies,
}: {
  devicePossession: DevicePossessionDto;
  appliedPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature;
}): JSX.Element => {
  const device = devicePossession.device;
  const isApple = Boolean(
    devicePossession &&
      devicePossession.device &&
      devicePossession.device.os &&
      AppleDevices.includes(devicePossession.device?.os?.toLowerCase() as DeviceModelOs)
  );
  const isWindows = Boolean(
    devicePossession &&
      devicePossession.device &&
      devicePossession.device.os &&
      DeviceModelOs &&
      DeviceModelOs.Windows === (devicePossession.device?.os?.toLowerCase() as DeviceModelOs)
  );
  const [isPasscodeOpen, setIsPasscodeOpen] = useState<boolean>(false);
  const [isEncryptionOpen, setIsEncryptionOpen] = useState<boolean>(false);
  const [isFirewallOpen, setIsFirewallOpen] = useState<boolean>(false);
  const [isAdditionalPasswordSecurityOpen, setIsAdditionalPasswordSecurityOpen] = useState<boolean>(false);
  const [isScreenPolicyOpen, setIsScreenPolicyOpen] = useState<boolean>(false);
  const [isSecurityPolicyOpen, setIsSecurityPolicyOpen] = useState<boolean>(false);
  const [isBrowsingPolicyOpen, setIsBrowsingPolicyOpen] = useState<boolean>(false);
  const [isOsUpdatePolicyOpen, setIsOsUpdatePolicyOpen] = useState<boolean>(false);
  const [isActivationLockPolicyOpen, setIsActivationLockPolicyOpen] = useState<boolean>(false);
  const [isWifiOpen, setIsWifiOpen] = useState<boolean>(false);
  const [isLoginItemsOpen, setIsLoginItemsOpen] = useState<boolean>(false);
  const { data } = useApiClient(DeviceEndpoints.getAllAvailableDevicePolicies(), {
    suspense: false,
  });
  const isSupportedPolicyByHexnodeDevice = (policy: number[]): boolean => {
    if (data && data.length > 0) {
      const supportedDeviceType = isWindows ? SupportedDeviceType.windows : SupportedDeviceType.android;

      const selectedPolicy = data
        .filter((p) => p.supportedDeviceType.toString() === supportedDeviceType.toString())
        .find((p) => policy.includes(p.id));
      return !!selectedPolicy;
    }

    return false;
  };

  const getObjectSize = (obj: object | null | undefined) => {
    if (isApple) {
      return obj ? Object.keys(obj).length - 1 : 0;
    }
    return 1;
  };

  return device ? (
    <>
      <Box component="header" sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
        <Typography variant={'title2'}>Configuration</Typography>
        {((isApple && appliedPolicies.configurablePasscode) ||
          (!isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.passcode))) && (
          <DevicePolicyTile
            policyName={'Passcode policy'}
            status={device.passwordProtected ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurablePasscode)}
            onClick={() => {
              setIsPasscodeOpen(true);
            }}
          />
        )}
        {((isApple && appliedPolicies.configurableEncryptionPolicy) ||
          (!isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.encryption))) && (
          <DeviceEncryptionPolicyTile
            policyName={'Encryption policy'}
            status={device.encryptionEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableEncryptionPolicy)}
            onClick={() => {
              setIsEncryptionOpen(true);
            }}
            filevaultKeyStatus={isApple ? !!device.filevaultKey : null}
          />
        )}
        {((isApple && appliedPolicies.configurableFirewallPolicy) ||
          (!isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.firewall))) && (
          <DevicePolicyTile
            policyName={'Firewall policy'}
            status={device.firewallEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableFirewallPolicy)}
            onClick={() => {
              setIsFirewallOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableAdditionalPasswordPolicy && (
          <DevicePolicyTile
            policyName={'Additional password security policy'}
            status={device.additionalPasswordEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableAdditionalPasswordPolicy)}
            onClick={() => {
              setIsAdditionalPasswordSecurityOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableScreenSettingPolicy && (
          <DevicePolicyTile
            policyName={'Screen policy'}
            status={device.screenLoginSettingsEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableScreenSettingPolicy)}
            onClick={() => {
              setIsScreenPolicyOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableSecurity && (
          <DevicePolicyTile
            policyName={'Apps and features restrictions policy'}
            status={device.securityEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableSecurity)}
            onClick={() => {
              setIsSecurityPolicyOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableBrowsing && (
          <DevicePolicyTile
            policyName={'Safari browsing policy'}
            status={device.browsingEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableBrowsing)}
            onClick={() => {
              setIsBrowsingPolicyOpen(true);
            }}
          />
        )}
        {((isApple && appliedPolicies.configurableAutomaticOsUpdate) ||
          (!isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.updates))) && (
          <DevicePolicyTile
            policyName={'OS update policy'}
            status={
              (isApple ? appliedPolicies.configurableAutomaticOsUpdate : appliedPolicies.updates[0] !== 0)
                ? 'Installed'
                : 'Missing'
            }
            settingsNumber={getObjectSize(appliedPolicies.configurableAutomaticOsUpdate)}
            onClick={() => {
              setIsOsUpdatePolicyOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableActivationLock && (
          <DevicePolicyTile
            policyName={'Activation lock policy'}
            status={device.activationLockBypassKey ? 'Installed' : 'Missing'}
            settingsNumber={1}
            onClick={() => {
              setIsActivationLockPolicyOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableWifi && (
          <DevicePolicyTile
            policyName={'WiFi policy'}
            status={device.wifiEnabled ? 'Installed' : 'Missing'}
            settingsNumber={getObjectSize(appliedPolicies.configurableWifi)}
            onClick={() => {
              setIsWifiOpen(true);
            }}
          />
        )}
        {isApple && appliedPolicies.configurableFirewallPolicy && (
          <DevicePolicyTile
            policyName={'Open at login policy'}
            status={device.loginItemsEnabled ? 'Installed' : 'Missing'}
            settingsNumber={
              appliedPolicies.configurableLoginItems?.autoLaunchedApplicationDictionaryManaged?.length ?? 0
            }
            onClick={() => {
              setIsLoginItemsOpen(true);
            }}
          />
        )}
        <GeneralPolicyViewDrawer
          policyName={'Passcode policy'}
          isOpen={isPasscodeOpen}
          onClose={() => {
            setIsPasscodeOpen(false);
          }}
          setIsOpen={setIsPasscodeOpen}
          configurationPayloadOption={appliedPolicies.configurablePasscode}
          policy={appliedPolicies.passcode}
          isApple={isApple}
          isWindows={isWindows}
          allPolicies={data}
        />
        <EncryptionPolicyViewDrawer
          policyName={'Encryption policy'}
          isOpen={isEncryptionOpen}
          onClose={() => {
            setIsEncryptionOpen(false);
          }}
          setIsOpen={setIsEncryptionOpen}
          configurationPayloadOption={appliedPolicies.configurableEncryptionPolicy}
          policy={appliedPolicies.encryption}
          filevaultKey={device.filevaultKey}
          isApple={isApple}
          isWindows={isWindows}
          allPolicies={data}
        />
        <GeneralPolicyViewDrawer
          policyName={'Firewall policy'}
          isOpen={isFirewallOpen}
          onClose={() => {
            setIsFirewallOpen(false);
          }}
          setIsOpen={setIsFirewallOpen}
          configurationPayloadOption={appliedPolicies.configurableFirewallPolicy}
          policy={appliedPolicies.firewall}
          isApple={isApple}
          isWindows={isWindows}
          allPolicies={data}
        />
        <GeneralPolicyViewDrawer
          policyName={'Additional password security policy'}
          isOpen={isAdditionalPasswordSecurityOpen}
          onClose={() => {
            setIsAdditionalPasswordSecurityOpen(false);
          }}
          setIsOpen={setIsAdditionalPasswordSecurityOpen}
          configurationPayloadOption={appliedPolicies.configurableAdditionalPasswordPolicy}
          policy={null}
          isApple={isApple}
          isWindows={isWindows}
          allPolicies={data}
        />
        <GeneralPolicyViewDrawer
          policyName={'Screen policy'}
          isOpen={isScreenPolicyOpen}
          onClose={() => {
            setIsScreenPolicyOpen(false);
          }}
          setIsOpen={setIsScreenPolicyOpen}
          configurationPayloadOption={appliedPolicies.configurableScreenSettingPolicy}
          policy={null}
          isApple={isApple}
          isWindows={isWindows}
          allPolicies={data}
        />
        <GeneralPolicyViewDrawer
          policyName={'Apps and features restrictions policy'}
          isOpen={isSecurityPolicyOpen}
          onClose={() => {
            setIsSecurityPolicyOpen(false);
          }}
          setIsOpen={setIsSecurityPolicyOpen}
          configurationPayloadOption={appliedPolicies.configurableSecurity}
          policy={null}
          isApple={isApple}
          isWindows={isWindows}
          allPolicies={data}
        />
        <SafariBrowsingPolicyViewDrawer
          isOpen={isBrowsingPolicyOpen}
          onClose={() => {
            setIsBrowsingPolicyOpen(false);
          }}
          setIsOpen={setIsBrowsingPolicyOpen}
          configurationPayloadOption={appliedPolicies.configurableBrowsing}
        />
        <OsUpdatesPolicyViewDrawer
          policyName={'OS update policy'}
          isOpen={isOsUpdatePolicyOpen}
          onClose={() => {
            setIsOsUpdatePolicyOpen(false);
          }}
          setIsOpen={setIsOsUpdatePolicyOpen}
          configurationPayloadOption={appliedPolicies.configurableAutomaticOsUpdate}
          isApple={isApple}
          isWindows={isWindows}
          policy={appliedPolicies.updates}
          allPolicies={data}
        />
        <WifiPolicyViewDrawer
          policyName={'WiFi policy'}
          isOpen={isWifiOpen}
          onClose={() => {
            setIsWifiOpen(false);
          }}
          setIsOpen={setIsWifiOpen}
          configurationPayloadOption={appliedPolicies.configurableWifi}
        />
        <LoginItemPolicyViewDrawer
          isOpen={isLoginItemsOpen}
          onClose={() => {
            setIsLoginItemsOpen(false);
          }}
          setIsOpen={setIsLoginItemsOpen}
          configurationPayloadOption={appliedPolicies.configurableLoginItems}
        />
        <ActivationLockPolicyViewDrawer
          policyName={'Activation lock policy'}
          isOpen={isActivationLockPolicyOpen}
          onClose={() => {
            setIsActivationLockPolicyOpen(false);
          }}
          setIsOpen={setIsActivationLockPolicyOpen}
          configurationPayloadOption={appliedPolicies.configurableActivationLock}
        />
      </Box>
    </>
  ) : (
    <>
      <Typography variant={'title4'}>No policies applied</Typography>
    </>
  );
};
