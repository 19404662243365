import React, { useMemo, useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { ScheduleTrackingType } from '@v2/feature/attendance/attendance.interface';
import { EMPLOYEE_SITE, getTrackingTypeLabel } from '@v2/feature/attendance/attendance.util';
import { AttendanceScheduleEditSettingsDrawer } from '@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-settings-drawer.component';
import { DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY } from '@v2/feature/dashboard/features/sections/user-shift/user-shift.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface SectionProps {
  readonly schedule: AttendanceScheduleDto;
  readonly refreshSchedule: () => Promise<void>;
  readonly sites: readonly SiteDto[];
}

export const AttendanceSettingsTrackingSection = ({ schedule, refreshSchedule, sites }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);

  const allowedWorkSites = useMemo(() => {
    if (!schedule.useGeolocation) return null;
    const siteNames: string[] = [];
    if (schedule.geolocationEmployeeSite) siteNames.push(EMPLOYEE_SITE(polyglot).label);

    if (schedule.allowedWorkSites) {
      for (const siteId of schedule.allowedWorkSites) {
        const site = sites.find((site) => site.id === siteId);
        if (site) siteNames.push(site.name);
      }
    }

    return siteNames.join(', ');
  }, [polyglot, sites, schedule.useGeolocation, schedule.allowedWorkSites, schedule.geolocationEmployeeSite]);

  return (
    <SettingsSectionContent
      title={polyglot.t('AttendanceSchedule.tracking')}
      onEdit={() => {
        setEdit(true);
      }}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.timesheets'),
                value:
                  schedule.trackingType === ScheduleTrackingType.None
                    ? polyglot.t('General.disabled')
                    : polyglot.t('General.enabled'),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.method'),
                value: getTrackingTypeLabel(schedule.trackingType, polyglot),
              },
              // {
              //   type: SectionItemType.Pair,
              //   label: polyglot.t('SettingsSectionReadonly.allowed'),
              //   value: schedule.attendanceTypesAllowed.map((r) => r.name).join(', '),
              //   hidden:
              //     schedule.trackingType === ScheduleTrackingType.None || schedule.attendanceTypesAllowed.length === 0,
              // },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.earlyShift'),
                value: polyglot.t('SettingsSectionReadonly.minutes', { capMins: schedule.clockInEarlyCapMinutes }),
                hidden: schedule.trackingType !== ScheduleTrackingType.ClockInClockOut,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.gpsTracking'),
                value: schedule.useGeolocation ? polyglot.t('General.enabled') : polyglot.t('General.disabled'),
                hidden: schedule.trackingType !== ScheduleTrackingType.ClockInClockOut,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.restrictLocation'),
                value: schedule.restrictByGeolocation ? polyglot.t('General.enabled') : polyglot.t('General.disabled'),
                hidden: schedule.trackingType !== ScheduleTrackingType.ClockInClockOut,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.allowedLocations'),
                value: allowedWorkSites,
                hidden: !schedule.restrictByGeolocation || !allowedWorkSites,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('SettingsSectionReadonly.maximumDistance'),
                value: polyglot.t('SettingsSectionReadonly.within', {
                  distance: schedule.geolocationDistance ?? DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY,
                }),
                hidden: !schedule.restrictByGeolocation,
              },
            ],
          },
        ]}
      />

      <AttendanceScheduleEditSettingsDrawer
        isOpen={edit}
        setIsOpen={setEdit}
        attendanceSchedule={schedule}
        refresh={refreshSchedule}
        sites={sites}
      />
    </SettingsSectionContent>
  );
};
