import { useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, AlertTitle, Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { v4 } from 'uuid';

import type { GridColDef } from '@mui/x-data-grid';

import { UploadInput } from '@/component/forms/UploadInput';
import { EntityImportDatagridValidation } from '@/v2/feature/entity-import/components/entity-import-datagrid-validation.component';
import { EntityImportResultPageProps } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import {
  PAYROLL_IMPORT_CSV_ENDPOINT,
  PayrollImportAPI,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.api';
import { PayrollImportResultDto } from '@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.dto';
import { getRowIdsWithErrors, getTotalErrorCount, hasErrors } from '@/v2/util/data-import.util';
// @ts-expect-error
import PayrollImportCsvTemplate from '@/zelt-payroll-import-template.csv';

export function PayrollImportCsvPage({
  goBack,
  goNext,
}: EntityImportResultPageProps<PayrollImportResultDto>): JSX.Element {
  const [uploadResponse, setUploadResponse] = useState<PayrollImportResultDto | undefined>();
  const [isTableEditable, setIsTableEditable] = useState(false);
  const [tableDataAsCsv, setTableDataAsCsv] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const tableColumns: GridColDef[] = [
    {
      headerName: 'First Name',
      field: 'firstName',
      editable: isTableEditable,
      minWidth: 90,
      flex: 1,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      editable: isTableEditable,
      minWidth: 90,
      flex: 1,
    },
    {
      headerName: 'Work Email',
      field: 'workEmail',
      editable: isTableEditable,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: 'Tax Code',
      field: 'taxCode',
      editable: isTableEditable,
      minWidth: 90,
      flex: 1,
    },
    {
      headerName: 'NINO',
      field: 'niNumber',
      editable: isTableEditable,
      minWidth: 70,
      flex: 1,
    },
    {
      headerName: 'Legal Gender',
      field: 'legalGender',
      editable: isTableEditable,
      minWidth: 110,
      flex: 1,
    },
    {
      headerName: 'NI Category',
      field: 'niTable',
      editable: isTableEditable,
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: 'Student Loan',
      field: 'studentLoan',
      editable: isTableEditable,
      minWidth: 110,
      flex: 1,
    },
    {
      headerName: 'Postgraduate Loan',
      field: 'postgradLoan',
      editable: isTableEditable,
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Is/Was a director',
      field: 'isDirector',
      editable: isTableEditable,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: 'Directorship Start Date',
      field: 'directorFrom',
      editable: isTableEditable,
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Directorship End Date',
      field: 'directorTo',
      editable: isTableEditable,
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Calculation method',
      field: 'week1Month1',
      editable: isTableEditable,
      minWidth: 130,
      flex: 1,
    },
    {
      headerName: 'Is a new starter',
      field: 'openingNotNewStarter',
      editable: isTableEditable,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: 'Starter declaration (Only for new starters)',
      field: 'starterDeclaration',
      editable: isTableEditable,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Previous employer - Gross income (Only for new starters)',
      field: 'openingPreviousGross',
      editable: isTableEditable,
      minWidth: 160,
      flex: 1,
    },
    {
      headerName: 'Previous employer - Income Tax paid (Only for new starters)',
      field: 'openingPreviousTax',
      editable: isTableEditable,
      minWidth: 160,
      flex: 1,
    },
  ];

  const handleUpload = (result: PayrollImportResultDto | undefined) => {
    result?.errors.map((error) => (error.id = v4()));
    setUploadResponse(result);
    if (result && !result.errors.length) {
      goNext(result);
    }
  };

  const tryAgain = () => {
    setUploadResponse(undefined);
  };

  return (
    <Box
      sx={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box textAlign="center" paddingY={3}>
        <Typography variant="subtitle2">Upload Payroll Information</Typography>
        <Typography variant="body2">
          Use our Template to see what information is required to upload Payroll information for your employees. Make
          sure to provide their work email that is saved in Zelt.
        </Typography>
      </Box>
      {uploadResponse && uploadResponse.errors.length > 0 && (
        <Alert
          severity={hasErrors(uploadResponse.errors) ? 'error' : 'success'}
          sx={{ marginBottom: 3, width: '100%', marginX: 'auto' }}
          action={
            hasErrors(uploadResponse.errors) && (
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={() => tryAgain()}
                sx={{ marginY: 'auto' }}
              >
                Try again
              </Button>
            )
          }
        >
          <AlertTitle>
            {hasErrors(uploadResponse.errors)
              ? 'Check Formatting'
              : 'Import Completed. You can see imported records below'}{' '}
          </AlertTitle>
          {uploadResponse?.importedCount > 0 &&
            `${
              uploadResponse?.errors?.length - getTotalErrorCount(uploadResponse.errors)
            } rows imported successfully. `}
          {hasErrors(uploadResponse.errors) &&
            `${getTotalErrorCount(
              uploadResponse.errors
            )} rows could not be imported. Please double-click on the error fields to correct them and then hit Enter to save each field.`}
        </Alert>
      )}
      <Card dir="ltr" sx={{ minWidth: '100%' }}>
        <CardContent
          sx={{
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!uploadResponse && !loading && (
            <Box width={790} paddingY={10} paddingX={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <a href={PayrollImportCsvTemplate}>
                  <Button variant="contained" onClick={() => {}} startIcon={<DownloadIcon />} sx={{ my: 3 }}>
                    Download Blank CSV Template
                  </Button>
                </a>
              </Box>
              <UploadInput<PayrollImportResultDto>
                onChange={(response) => handleUpload(response)}
                endpoint={PAYROLL_IMPORT_CSV_ENDPOINT}
              />
            </Box>
          )}
          {uploadResponse && uploadResponse.errors.length > 0 && (
            <div style={{ width: '100%' }}>
              {!isTableEditable && hasErrors(uploadResponse.errors) && (
                <IconButton
                  aria-label="settings"
                  onClick={() => setIsTableEditable(!isTableEditable)}
                  sx={{ display: 'flex', marginLeft: 'auto' }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {isTableEditable && hasErrors(uploadResponse.errors) && (
                <Button
                  variant="contained"
                  onClick={async () => {
                    setLoading(true);
                    setIsTableEditable(false);
                    const response = await PayrollImportAPI.importPayrollCsvText(tableDataAsCsv);
                    setUploadResponse(undefined);
                    handleUpload(response);
                  }}
                  sx={{ display: 'flex', marginLeft: 'auto' }}
                >
                  Save
                </Button>
              )}
              <EntityImportDatagridValidation
                rows={uploadResponse.errors}
                columns={tableColumns}
                setTableDataAsCsv={setTableDataAsCsv}
                rowIdsToExport={getRowIdsWithErrors(uploadResponse.errors) as string[]}
              />
            </div>
          )}
        </CardContent>
      </Card>
      <Box sx={{ my: 2, display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Button onClick={goBack} sx={{ mr: 1 }}>
          Back
        </Button>
      </Box>
    </Box>
  );
}
