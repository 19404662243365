import axios from 'axios';

import { SurveyAnswer, UpsertSurveyAnswer } from '@/v2/feature/growth/surveys/interfaces/survey-answer.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class SurveyAnswerAPI {
  static async saveAnswersAsDraft(reviewAnswer: UpsertSurveyAnswer[], entryId: string, cycleId: string): Promise<void> {
    return (await axios.post(`/apiv2/survey-answer/draft/${entryId}/${cycleId}`, reviewAnswer)).data;
  }

  static async submitAnswers(
    reviewAnswer: UpsertSurveyAnswer[],
    entryId: string,
    cycleId: string,
    participantId: number
  ): Promise<void> {
    return (await axios.post(`/apiv2/survey-answer/submit/${entryId}/${cycleId}/${participantId}`, reviewAnswer)).data;
  }

  static async getAnswer(entryId: string, cycleId: string): Promise<SurveyAnswer[]> {
    return (await axios.get(`/apiv2/survey-answer/${entryId}/${cycleId}`)).data;
  }
}

export class SurveyAnswerEndpoints {
  static getAnswer(entryId: string, cycleId: string): Endpoint<SurveyAnswer[]> {
    return {
      url: `/apiv2/survey-answer/${entryId}/${cycleId}`,
    };
  }
}
