import React, { useContext } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { spacing } from '@v2/styles/spacing.styles';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { Scope } from '@/models';

export const EmptyStateComponent = ({
  header,
  subheader,
  detail,
  actionTitle,
  action,
  cover,
  width = '35%',
  actionScope = [],
}: {
  header: string;
  subheader?: string | JSX.Element;
  detail?: string;
  actionTitle?: string;
  action?: () => void;
  cover: string;
  width?: string;
  readonly actionScope?: readonly Scope[];
}) => {
  const [state] = useContext(GlobalContext);

  const { getScopesContext } = useScopes();

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        ...spacing.px40,
      }}
    >
      <Box
        sx={{
          mt: 2,
          width: width,
          px: 1.5,
          py: 3.5,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Box>
              <img src={cover} alt="payroll-empty" width={160} height="auto" />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Box sx={{ display: 'block', textAlign: 'center' }}>
              <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'text.secondary' }}>{header}</Typography>
              </Box>
              <Typography sx={{ fontSize: 13, color: 'text.secondary', mt: 1 }}>{subheader}</Typography>
              <Typography sx={{ fontSize: 12, color: 'text.secondary', mt: 1 }}>{detail}</Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            {actionTitle && action && (
              <ScopesControl scopes={actionScope} context={getScopesContext({ userId: state.user.userId })}>
                <Button variant="contained" color="primary" onClick={() => action()} size="small" sx={{ width: 160 }}>
                  {actionTitle}
                </Button>
              </ScopesControl>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface TableEmptyStateProps {
  readonly title: string;
  readonly subtitle?: string;
}

export const TableEmptyState = ({ title, subtitle }: TableEmptyStateProps) => {
  return (
    <Box sx={{ textAlign: 'center', mt: 10 }}>
      <Typography sx={{ fontSize: 14 }}>{title}</Typography>
      {subtitle && <Typography sx={{ fontSize: 14 }}>{subtitle}</Typography>}
    </Box>
  );
};
