import React, { Fragment, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button } from '@mui/material';
import { UserShiftHandler } from '@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import _, { Dictionary, keyBy } from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { KeyedMutator } from 'swr';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { DashboardLayoutLoader } from '@/v2/feature/dashboard/components/dashboard-layout-loader.component';
import { DashboardEndpoints } from '@/v2/feature/dashboard/dashboard.api';
import '@/v2/feature/dashboard/dashboard.scss';
import { DashboardEditMode } from '@/v2/feature/dashboard/features/dashboard-edit-mode.page';
import { UserAttendance } from '@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.section';
import { UserCalendarVersionBig } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-big.section';
import { UserCalendarVersionSmall } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-small.section';
import { DashboardUserProfile } from '@/v2/feature/dashboard/features/sections/user-profile-widget/dashborad-user-profile.section';
import { UserTimePlannerBig } from '@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-big.section';
import { UserTimePlannerSmall } from '@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-small.section';
import { UserTodosVersionBig } from '@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-big.section';
import { UserTodosVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-small.section';
import {
  AttendanceWidget,
  CalendarWidgetBigWidget,
  CalendarWidgetSmallWidget,
  ProfileWidget,
  ShiftWidget,
  TimePlannerBigWidget,
  TimePlannerSmallWidget,
  TodoWidgetBig,
  TodoWidgetSmall,
  WidgetConfiguration,
  WidgetInformation,
  WidgetTypes,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const DashboardPage: React.FC = () => {
  return (
    <RootStyle>
      <Suspense fallback={<></>}>
        <DashboardLayoutConfigLoader />
      </Suspense>
    </RootStyle>
  );
};

const DashboardLayoutConfigLoader = () => {
  const {
    data: userConfig,
    isLoading: configLoading,
    mutate: refreshConfig,
    isValidating: configValidating,
  } = useApiClient(DashboardEndpoints.getUserConfiguration());

  return (
    <ContentWrapper
      loading={false}
      sx={{
        padding: 0,
        scrollbarWidth: 'none',
        paddingBottom: '30px',
        backgroundImage: `url(${userConfig?.companyWallpaper?.wallpaper})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      hideFooter
      border={false}
      noHeader
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Suspense fallback={<DashboardLayoutLoader userConfig={userConfig} />}>
          <DashboardLayout
            userConfig={userConfig}
            refreshConfig={refreshConfig}
            configLoading={configLoading}
            configValidating={configValidating}
          />
          {/* <DashboardLayoutLoader userConfig={userConfig} /> */}
        </Suspense>
      </div>
    </ContentWrapper>
  );
};

const DashboardLayout = ({
  userConfig,
  refreshConfig,
  configLoading,
  configValidating,
}: {
  userConfig: WidgetConfiguration | null | undefined;
  refreshConfig: KeyedMutator<WidgetConfiguration> | undefined;
  configLoading: boolean | undefined;
  configValidating: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;

  const { data: userWidget, mutate: refreshWidgetData } = useApiClient(DashboardEndpoints.getUserWidgets());

  const refreshWidget = useCallback(async () => {
    if (refreshWidgetData) refreshWidgetData();
  }, [refreshWidgetData]);
  const { trackPage } = useJune();

  const { data: widgetData } = userWidget ?? {};
  const widgetDataLookup: Dictionary<WidgetInformation> = useMemo(() => {
    return keyBy(widgetData, 'type');
  }, [widgetData]);

  const { preference, order } = userConfig ?? {};
  let preferenceIdLookup = useMemo(() => {
    return keyBy(preference, 'id');
  }, [preference]);

  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    trackPage('Dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialRows = useMemo(() => {
    return [
      ...(widgetDataLookup[WidgetTypes.USER_PROFILE_SMALL]
        ? [
            {
              type: WidgetTypes.USER_PROFILE_SMALL,
              content: (
                <DashboardUserProfile
                  user={user}
                  widgetData={(widgetDataLookup[WidgetTypes.USER_PROFILE_SMALL] as ProfileWidget)?.profile}
                  refreshWidgetData={refreshWidget}
                />
              ),
            },
          ]
        : []),
      ...(widgetDataLookup[WidgetTypes.USER_TODOS_BIG]
        ? [
            {
              type: WidgetTypes.USER_TODOS_BIG,
              content: (
                <UserTodosVersionBig
                  todos={(widgetDataLookup[WidgetTypes.USER_TODOS_BIG] as TodoWidgetBig)?.todosBig}
                  refreshtodoList={refreshWidget}
                />
              ),
            },
          ]
        : []),

      ...(widgetDataLookup[WidgetTypes.USER_TODOS_SMALL]
        ? [
            {
              type: WidgetTypes.USER_TODOS_SMALL,
              content: (
                <UserTodosVersionSmall
                  todos={(widgetDataLookup[WidgetTypes.USER_TODOS_SMALL] as TodoWidgetSmall)?.todosSmall}
                />
              ),
            },
          ]
        : []),

      ...(widgetDataLookup[WidgetTypes.USER_CALENDAR_BIG]
        ? [
            {
              type: WidgetTypes.USER_CALENDAR_BIG,
              content: (
                <UserCalendarVersionBig
                  calendarBig={
                    (widgetDataLookup[WidgetTypes.USER_CALENDAR_BIG] as CalendarWidgetBigWidget)?.calendarBig
                  }
                  refreshWidgetData={refreshWidget}
                />
              ),
            },
          ]
        : []),

      ...(widgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL]
        ? [
            {
              type: WidgetTypes.USER_CALENDAR_SMALL,
              content: (
                <UserCalendarVersionSmall
                  calendarSmall={
                    (widgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL] as CalendarWidgetSmallWidget)?.calendarSmall
                  }
                />
              ),
            },
          ]
        : []),

      ...(widgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG]
        ? [
            {
              type: WidgetTypes.USER_ATTENDANCE_BIG,
              content: (
                <UserAttendance
                  attendance={(widgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG] as AttendanceWidget)?.attendance}
                  refreshWidgetData={refreshWidget}
                  shiftWidgeData={(widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget) ?? undefined}
                />
              ),
            },
          ]
        : []),

      ...(widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]
        ? [
            {
              type: WidgetTypes.USER_SHIFT_SMALL,
              content: (
                <UserShiftHandler
                  attendanceSchedule={
                    (widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift.attendanceSchedule
                  }
                  shift={(widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.todayShift}
                  userSite={(widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.userSite}
                  refresh={refreshWidget}
                  currentWeekAttendance={
                    (widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] as ShiftWidget)?.shift?.currentWeekAttendance
                  }
                  mode="widget"
                />
              ),
            },
          ]
        : []),
      ...(widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL]
        ? [
            {
              type: WidgetTypes.USER_TIME_PLANNER_SMALL,
              content: (
                <UserTimePlannerSmall
                  userBalance={
                    (widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL] as TimePlannerSmallWidget)?.balancesSmall
                  }
                />
              ),
            },
          ]
        : []),

      ...(widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG]
        ? [
            {
              type: WidgetTypes.USER_TIME_PLANNER_BIG,
              content: (
                <UserTimePlannerBig
                  userBalance={
                    (widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG] as TimePlannerBigWidget)?.balancesBig
                  }
                />
              ),
            },
          ]
        : []),
    ];
  }, [widgetDataLookup, user, refreshWidget]);

  const generateLayout = useCallback(() => {
    let x = 0;
    let y = 0;
    let countY = 0;
    const layouts = (order as string[])?.reduce<
      { x: number; y: number; w: number; h: number; i: string; static: any; isBounded: boolean; isResizable: boolean }[]
    >((acc, widget) => {
      const widgetDetails = preferenceIdLookup[widget];
      const w = widgetDetails?.size === 'small' ? 4 : 8;
      if (!widgetDataLookup[widgetDetails?.type]) return acc; // Skip if widget data is not available
      if (x + w > 12) {
        x = 0;
        y += countY;
        countY = 0;
      }
      const layout = {
        x: x,
        y: y,
        w: w,
        h: 1,
        i: widget,
        static: widgetDetails?.isStatic,
        isBounded: true,
        isResizable: false,
      };

      x += w;
      countY += w;

      return [...acc, layout]; // Add the layout object to the accumulator array
    }, []);

    return layouts;
  }, [preferenceIdLookup, widgetDataLookup, order]);

  const layouts = useMemo(() => {
    return { lg: generateLayout() };
  }, [generateLayout]);

  const generateDOM = () => {
    return _.map(layouts.lg, function (l, i) {
      const widget = preferenceIdLookup[l.i];
      const component = initialRows.find((row) => row.type === widget.type)?.content;
      return (
        <div key={l.i} id={`user-widget-${i}`}>
          {component}
        </div>
      );
    });
  };
  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <ResponsiveGridLayout
          className="layout grid-width"
          layouts={{ lg: generateLayout() }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
          measureBeforeMount={true}
          rowHeight={280}
          isDraggable={false}
          isResizable={false}
          useCSSTransforms={false}
          preventCollision={true}
          margin={[30, 30]}
        >
          {generateDOM()}
        </ResponsiveGridLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            endIcon={<ArrowDown {...iconSize} fill={themeColors.DarkGrey} />}
            onClick={() => setEdit(true)}
            sx={{ ...secondaryCTABtn, borderRadius: radius.br15 }}
          >
            {polyglot.t('DashboardLayout.edit')}
          </Button>
        </Box>
      </Box>
      {edit && (
        <DashboardEditMode
          setEdit={setEdit}
          refreshWidgetData={refreshWidget}
          userConfig={userConfig}
          refreshConfig={refreshConfig}
          configLoading={configLoading}
          configValidating={configValidating}
        />
      )}
    </Fragment>
  );
};
