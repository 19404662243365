import { SxProps, Theme, Typography } from '@mui/material';
import { differenceInDays, format, formatDistanceToNowStrict, isValid, parseISO } from 'date-fns';
import { Dayjs } from 'dayjs';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { removeTZ } from '@/v2/util/date-format.util';

//function could be used separately from the component
export const getDateString = (date: string | Date | Dayjs, duration = false, dateformat = 'd MMM yyyy') => {
  try {
    // if difference is under 30 days, then we display the distance in days, otherwise in months
    const useDaysUnit = differenceInDays(new Date(), new Date(date as Date)) < 30;
    if (date && isValid(new Date(date as Date))) {
      return duration
        ? formatDistanceToNowStrict(new Date(date as Date | string), {
            unit: useDaysUnit ? 'day' : 'month',
            addSuffix: true,
          })
        : format(parseISO(removeTZ(new Date(date as Date | string).toISOString())), dateformat);
    }
    return '';
  } catch (error) {
    console.error(error);
    return 'Invalid date';
  }
};

export const DateLabelComponent = ({
  date,
  dateformat = 'd MMM yyyy',
  duration = false,
  titleSx = { ...themeFonts.caption, color: themeColors.DarkGrey },
}: {
  date: string | Date | Dayjs;
  dateformat?: 'dd/MM/yyyy' | 'd MMM yyyy' | 'd MMM' | 'd MMM yyyy, h:mm a';
  duration?: boolean;
  titleSx?: SxProps<Theme>;
}) => {
  return <Typography sx={{ whiteSpace: 'nowrap', ...titleSx }}>{getDateString(date, duration, dateformat)}</Typography>;
};
