import React, { useCallback, useEffect, useState } from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { SuperAdminInsurancePoliciesPage } from '@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-policies.page';
import { SuperAdminInsurancePolicyDetailsPage } from '@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-policy-details.page';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { Redirect, Route, Switch } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SUPER_ADMIN_INSURANCE_POLICIES_ROUTE, SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE } from '@/lib/routes';

interface SuperAdminDevicesRouterProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly pageConfig: PageConfig;
}

export const SuperAdminInsurancePoliciesRouter = ({
  companies,
  users,
  pageConfig,
}: SuperAdminDevicesRouterProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [policies, setPolicies] = useState<any[]>([]);

  const [showMessage] = useMessage();

  const refreshPolicies = useCallback(async () => {
    setLoading(true);
    try {
      const policies = await InsuranceAPI.getAllInsurancePoliciesAsSuperAdmin();
      setPolicies(policies);
    } catch (error) {
      showMessage(`Could not fetch insurance policies. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshPolicies();
  }, [refreshPolicies]);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE}>
        <SuperAdminInsurancePolicyDetailsPage
          companies={companies}
          users={users}
          policies={policies}
          pageConfig={pageConfig}
          loading={loading}
        />
      </Route>
      <Route path={SUPER_ADMIN_INSURANCE_POLICIES_ROUTE}>
        <SuperAdminInsurancePoliciesPage
          companies={companies}
          policies={policies}
          refreshPolicies={refreshPolicies}
          pageConfig={pageConfig}
          loading={loading}
        />
      </Route>

      <Redirect to={SUPER_ADMIN_INSURANCE_POLICIES_ROUTE} />
    </Switch>
  );
};
