import { useContext } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { linkButtonSx } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { SETTINGS_NOTIFICATIONS_ROUTE } from '@/lib/routes';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const TestModeBanner = () => {
  const [globalState] = useContext(GlobalContext);
  const history = useHistory();

  const { polyglot } = usePolyglot();

  return globalState.isTestModeEnabled ? (
    <Box
      sx={{
        width: '100%',
        backgroundColor: themeColors.Grey,
        ...themeFonts.caption,
        color: themeColors.black,
        display: 'flex',
        justifyContent: 'center',
        gap: spacing.g5,
        paddingY: spacing.p10,
        position: 'absolute',
        zIndex: 5,
        alignItems: 'center',
        opacity: '60%',
      }}
    >
      <Typography sx={themeFonts.caption}>{polyglot.t('TestModeBanner.bannerText')}</Typography>
      <Button
        onClick={() => history.push(SETTINGS_NOTIFICATIONS_ROUTE)}
        sx={{ ...themeFonts.caption, ...linkButtonSx, color: themeColors.black, paddingX: 0 }}
      >
        {polyglot.t('TestModeBanner.here')}
      </Button>
    </Box>
  ) : null;
};
