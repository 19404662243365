import { Box, Typography } from '@mui/material';

import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface Props {
  icon: JSX.Element;
  text: string;
}

export const UserDeletionBlockerItem = ({ icon, text }: Props): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      {icon}
      <Typography sx={{ ...themeFonts.title4, ml: spacing.m10 }}>{text}</Typography>
    </Box>
  );
};
