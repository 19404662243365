import { useState } from 'react';

import { KeyedMutator } from 'swr';

import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { TimelineScheduledModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/scheduled/timeline-scheduled-modal.component';
import { timelineByReviwer } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

interface ITimelineScheduledView {
  reviewCycle: ReviewCycle;
  showFlags: {
    showSelf: boolean;
    showUpward: boolean;
    showPeer: boolean;
    showManager: boolean;
  };
  isEditable: boolean;
  refreshCycle: KeyedMutator<ReviewCycle>;
}
export const TimelineScheduledView = ({ reviewCycle, showFlags, isEditable, refreshCycle }: ITimelineScheduledView) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { timelineSettings } = reviewCycle;
  return (
    <>
      <SettingsSubsectionContent
        sections={[
          {
            headerWidth: '100%',
            title: 'Timeline',
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption" sx={{ pb: spacing.s2 }}>
                    Timeline defines due dates for participants and serves as a reminder. After the deadline has passed,
                    results will not be released automatically unless the whole cycle is explicitly finalised by cycle
                    owner in the Overview page or individual results are shared with participants.
                  </Typography>
                ),
              },
              ...(timelineSettings.startNow && isEditable && reviewCycle.state !== CycleState.Ongoing
                ? [
                    {
                      type: SectionItemType.Pair,
                      label: 'Start now',
                      value: 'Cycle will be immediately launched',
                    },
                  ]
                : [
                    {
                      type: SectionItemType.Pair,
                      label: 'Start date (GMT)',
                      value: timelineSettings?.startDate
                        ? new LocalDate(timelineSettings?.startDate)
                            .getDate()
                            .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })
                        : 'NA',
                    },
                    {
                      type: SectionItemType.Pair,
                      label: 'Start time (GMT)',
                      value:
                        timelineSettings?.startTime && timelineSettings?.startTimeMeridiem
                          ? `${timelineSettings?.startTime} ${timelineSettings?.startTimeMeridiem}`
                          : 'NA',
                    },
                  ]),
              ...timelineByReviwer(reviewCycle, showFlags),
            ],
          },
        ]}
      />
      <TimelineScheduledModal
        reviewCycle={reviewCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refreshCycle?.();
        }}
        showFlags={showFlags}
      />
    </>
  );
};
