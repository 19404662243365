import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { isValid } from 'date-fns';
import dayjs from 'dayjs';

import { fieldSx as defaultFieldSx } from './styles.layout';

import { useProfileFields } from '@/hooks/profile-fields.hook';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TypeableDateComponent } from '@/v2/components/forms/typeable-date.component';
import { CustomProfileFieldType, UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { sortCustomFields, sortSelectOptions } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const ShowCustomFieldComponent = ({
  field,
  setCustomUpdates,
  autoFocus,
  fieldSx = defaultFieldSx,
  disabled,
}: {
  autoFocus?: boolean;
  field: UserCustomDataDto;
  setCustomUpdates: React.Dispatch<React.SetStateAction<UserCustomDataDto[]>>;
  fieldSx?: SystemStyleObject;
  disabled?: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const [localValue, setLocalValue] = useState<string>(field.value);
  const [fieldError, setFieldError] = useState<string>();

  const getFieldByType = useCallback(
    (customField: UserCustomDataDto) => {
      const update = (value: string) => {
        setCustomUpdates((updates) => [
          ...updates.filter((u) => u.field.fieldId !== customField.field.fieldId),
          { ...customField, value },
        ]);
      };

      switch (customField.field.fieldType) {
        case CustomProfileFieldType.Input:
          return (
            <Box sx={fieldSx}>
              <TextfieldComponent
                label={customField.field.fieldName}
                required={customField.field.isRequired}
                value={localValue}
                onChange={(e) => {
                  setLocalValue(e.target.value);
                  update(e.target.value);
                }}
                autoFocus={autoFocus}
                disabled={disabled}
              />
            </Box>
          );

        case CustomProfileFieldType.DatePicker:
          return (
            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  required={customField.field.isRequired}
                  value={localValue ?? null}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      setLocalValue(value);
                      update(value);
                    }
                  }}
                  name={customField.field.fieldName}
                  label={customField.field.fieldName}
                  disabled={disabled}
                />
              </FormControl>
            </Box>
          );

        case CustomProfileFieldType.Select:
          return (
            <Box sx={fieldSx}>
              <SelectComponent
                required={customField.field.isRequired}
                label={customField.field.fieldName}
                name={customField.field.fieldName}
                options={sortSelectOptions(customField.field.options?.allOptions ?? [])}
                value={localValue}
                // compareValue={localValue}
                onChange={(e) => {
                  setLocalValue(e.target.value);
                  update(e.target.value);
                }}
                disabled={disabled}
              />
            </Box>
          );

        case CustomProfileFieldType.Number:
          return (
            <Box sx={fieldSx}>
              <TextfieldComponent
                required={customField.field.isRequired}
                type="number"
                label={customField.field.fieldName}
                value={localValue}
                onChange={(e) => {
                  setLocalValue(e.target.value);
                  update(e.target.value);
                }}
                autoFocus={autoFocus}
                disabled={disabled}
              />
            </Box>
          );

        case CustomProfileFieldType.DateInput:
          return (
            <Box sx={fieldSx}>
              <TypeableDateComponent
                name={customField.field.fieldName}
                label={customField.field.fieldName}
                required={customField.field.isRequired}
                value={localValue}
                onChange={(value) => {
                  if (!isValid(new Date(value))) {
                    setFieldError(polyglot.t('ShowCustomFieldComponent.errorMessages.invalidDate'));
                  } else {
                    setLocalValue(value);
                    update(value);
                    setFieldError(undefined);
                  }
                }}
                autoFocus={autoFocus}
                error={!!fieldError}
                helperText={fieldError}
                disabled={disabled}
              />
            </Box>
          );

        default:
          return null;
      }
    },
    [autoFocus, disabled, fieldError, fieldSx, localValue, polyglot, setCustomUpdates]
  );

  return getFieldByType(field);
};

type ProfileFieldProps = PropsWithChildren<{
  fieldStub: string;
  sx?: SystemStyleObject;
}>;

export const ProfileField = ({ fieldStub, sx = defaultFieldSx, children }: ProfileFieldProps) => {
  const { getDefaultField, loading } = useProfileFields();
  const field = getDefaultField(fieldStub);

  // until we know if the field should be displayed or not, keep it hidden
  // if field is missing, it may be because it is a custom field - should not hide field if getDefaultField cannot find it
  // should hide field only if field is returned and has isHidden = true. for this reason the default hidden fields should be returned by the api
  // but make sure the fields value are not returned if fields are hidden
  // same in FieldStructure
  if (loading || (field && field.isHidden)) return null;

  return <Box sx={sx}>{children}</Box>;
};

type CustomFieldComponentsProps = {
  values: UserCustomDataDto[];
  onChange: (values: UserCustomDataDto[]) => void;
  disabled?: boolean;
  formName?:
    | CustomProfileFormType.Address
    | CustomProfileFormType.BankAccount
    | CustomProfileFormType.Contract
    | CustomProfileFormType.Role
    | CustomProfileFormType.Salary;
  rowModalMode?: 'add' | 'edit';
  fieldSx?: SystemStyleObject;
};

export const CustomFieldComponents = ({
  values,
  onChange,
  disabled,
  formName,
  rowModalMode,
  fieldSx,
}: CustomFieldComponentsProps) => {
  const [customUpdates, setCustomUpdates] = useState<UserCustomDataDto[]>(values);
  const sortedCustomFields = useMemo(() => sortCustomFields(customUpdates), [customUpdates]);

  const { getCustomFieldsForForm } = useProfileFields();

  useLayoutEffect(() => {
    if (!formName || !rowModalMode) return;
    if (rowModalMode !== 'add') return;
    // when we are adding a new effective record, we need to initialise a new set of custom fields
    setCustomUpdates(
      getCustomFieldsForForm(formName).map<UserCustomDataDto>((field) => ({
        fieldId: field.fieldId,
        formId: field.formId,
        field,
        value: '',
      }))
    );
  }, [getCustomFieldsForForm, rowModalMode, formName]);

  useEffect(() => {
    if (customUpdates !== values) {
      onChange(customUpdates);
    }
  }, [customUpdates, onChange, values]);

  return sortedCustomFields.length > 0 ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
      {sortedCustomFields.map((f) => (
        <ShowCustomFieldComponent
          key={f.field.fieldId}
          field={f}
          setCustomUpdates={setCustomUpdates}
          disabled={disabled}
          fieldSx={fieldSx}
        />
      ))}
    </Box>
  ) : null;
};
