import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as DocumentIcon } from '@/images/onboarding-step-icons/DocumentBig.svg';
import { ZeltDocumentType } from '@/lib/documents';
import { Scope } from '@/models';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { DocumentsScopes } from '@/v2/feature/documents/documents.scopes';
import { EditDocuments } from '@/v2/feature/onboarding/onboarding-items/documents/edit-documents.component';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  checkOnboardingItemScope: (scope: readonly Scope[]) => boolean;
  onDelete?: () => void;
  setDrawerOpen: (open: boolean) => void;
  setDrawerView: (view: JSX.Element) => void;
};

export const DocumentsOnboardingItem = ({
  template,
  onDelete,
  setDrawerOpen,
  setDrawerView,
  checkOnboardingItemScope,
}: Props) => {
  const { polyglot } = usePolyglot();
  const canAddDocuments = checkOnboardingItemScope(DocumentsScopes.LIST_DOCUMENT_TYPES);
  const [allDocumentTypes, setDocumentTypes] = useState<ZeltDocumentType[]>();

  const refreshDocumentTypes = useCallback(async () => {
    if (!canAddDocuments) return;
    const [documentTypesFromServer] = await Promise.all([DocumentAPI.listDocumentTypes()]);
    // should filter out possible documents for onboarding flow to ONLY be personal and visible document types
    // no hardcoded values should be used for filtering out possible document types
    const filteredDocuments = documentTypesFromServer.filter((type) => type.availableTo === 'personal' && type.visible);
    setDocumentTypes(filteredDocuments);
  }, [canAddDocuments]);

  useEffect(() => {
    refreshDocumentTypes();
  }, [refreshDocumentTypes]);

  const chips = useMemo(() => {
    if (!allDocumentTypes) return [];
    if (!template.template.documents) return [];
    return [
      ...template.template.documents.types.reduce<string[]>((result, t) => {
        const label = t !== 'other' && allDocumentTypes.find((dt) => dt.value === t)?.label;
        if (label) result.push(label);
        return result;
      }, []),
      ...template.template.documents.others,
    ];
  }, [allDocumentTypes, template.template.documents]);

  return (
    <ActionSelectionItem
      step={{
        Icon: DocumentIcon,
        title: polyglot.t('DocumentsOnboardingItem.title'),
        description: polyglot.t('DocumentsOnboardingItem.desc'),
      }}
      chips={chips}
      addButtonIcon={template.template.documents ? 'edit' : 'plus'}
      onAddClick={
        canAddDocuments &&
        allDocumentTypes &&
        (() => {
          setDrawerView(
            <EditDocuments
              documentTypes={template.template.documents?.types}
              allDocumentTypes={allDocumentTypes}
              otherDocumentNames={template.template.documents?.others}
              onSave={(types, others) => {
                template.template.documents =
                  types.length || others.length
                    ? {
                        types,
                        others,
                      }
                    : undefined;
                setDrawerOpen(false);
              }}
            />
          );
          setDrawerOpen(true);
        })
      }
      onDeleteClick={template.template.documents && onDelete}
      sx={{
        py: spacing.py20,
      }}
    />
  );
};
