import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { AppGroupManagementMemberAddList } from './components/app-group-management-member-add.component';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { MultiSelectOption } from '@/v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationAPI, AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  GoogleGroupMember,
  GroupId,
  GroupMember,
  GroupMembership,
  JiraGroupMember,
  Microsoft365GroupMember,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationStub,
  REFRESH_DELAY_APP_USER_LIST,
  UserToBeAdded,
} from '@/v2/feature/app-integration/app-integration.interface';
import { emailOptionsForUserCreation } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { AppGroupManagementMemberEditList } from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/components/app-group-management-member-edit-list.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import {
  buttonBoxSx,
  fieldSx,
  titleSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export interface AppGroupManagementFormData {
  userListForProcessing: UserToBeAdded[];
  existingUser?: AppIntegrationUserDto;
  existingUserGroupList?: MultiSelectOption<GroupId>[];
  emailSelectorRequired?: boolean;
  selectedEmailForUserCreation?: string;
}

export type AppStub = 'jumpcloud' | 'monday' | 'google-workspace' | 'github';

interface AppGroupManagementDrawerPageProps {
  appStub: AppStub | AppIntegrationStub;
  readonly selectedGroupMembership?: GroupMembership;
  readonly usersWithoutAccess: readonly AppIntegrationUserDto[];
  readonly usersWithAccess?: readonly AppIntegrationUserDto[];
  readonly externalUserList: readonly AppIntegrationUserDto[];
  readonly existingUser?: AppIntegrationUserDto;
  readonly refreshApp: VoidFunction;
  readonly closePage: () => void;
  readonly mode?: APP_GROUP_MANAGEMENT_DRAWER_MODES;
  readonly groupMembers?: Record<string, GroupMember>;
  readonly groupList?: readonly GroupMembership[];
}

export const AppGroupManagementDrawerPage = ({
  appStub,
  selectedGroupMembership,
  usersWithoutAccess,
  usersWithAccess,
  externalUserList,
  existingUser,
  refreshApp,
  groupMembers,
  closePage,
  mode = APP_GROUP_MANAGEMENT_DRAWER_MODES.edit,
  groupList,
}: AppGroupManagementDrawerPageProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();

  const { data: personalEmailForUser } = useApiClient(
    existingUser?.userId
      ? AppIntegrationEndpoints.getPersonalEmailForAppsByUserId(+existingUser?.userId, appStub)
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const defaultUserForAdding: UserToBeAdded = {
    appUserId: '',
    dateOfActivation: new LocalDate().toDateString(),
    selectedUser: undefined,
    groupList: [],
  };

  const usersWithoutAccessOptions = usersWithoutAccess.map((u) => {
    return { label: u.displayName, value: u.userId };
  }) as OptionObj[];

  const externalEmailList = useMemo(() => {
    return externalUserList ? externalUserList?.map((e) => e.primaryEmail) : [];
  }, [externalUserList]);

  const microsoft365UsersWithoutAccessOptions = usersWithoutAccess
    .filter((u) => !u.emails?.some((e) => externalEmailList.includes(e.email)))
    .map((u) => {
      return { label: u.displayName, value: u.userId };
    }) as OptionObj[];

  const userOptionsForAdd = useMemo(() => {
    if (['google-workspace', 'jira'].includes(appStub)) return usersWithoutAccessOptions;
    if (appStub === 'microsoft365') return microsoft365UsersWithoutAccessOptions;
  }, [appStub, microsoft365UsersWithoutAccessOptions, usersWithoutAccessOptions]);

  const [showMessage] = useMessage();
  const [userListForMembershipDeletion, setUserListForMembershipDeletion] = useState<GroupMember[]>([]);
  const [refreshAt, setRefreshAt] = useState<number | null>(null);

  const matchingUserForEmailOrId = useCallback(
    (identifier: string): AppIntegrationUserDto | undefined => {
      let matchingExternalUser;
      if (['google-workspace', 'microsoft365'].includes(appStub))
        matchingExternalUser = externalUserList?.find((eachUser) => eachUser.primaryEmail === identifier);
      if (appStub === 'jira') matchingExternalUser = externalUserList?.find((eachUser) => eachUser.id === identifier);

      if (matchingExternalUser) return matchingExternalUser;

      let matchingTeamUser;
      if (['google-workspace', 'microsoft365'].includes(appStub))
        matchingTeamUser = usersWithoutAccess?.find(
          (eachUser) => eachUser.emails && eachUser.emails.some((eachEmailEntry) => eachEmailEntry.email === identifier)
        );
      if (appStub === 'jira') matchingTeamUser = usersWithoutAccess?.find((eachUser) => eachUser.id === identifier);

      if (matchingTeamUser) return matchingTeamUser;

      let matchingUserWithAccess;
      if (['google-workspace', 'microsoft365'].includes(appStub))
        matchingUserWithAccess = usersWithAccess?.find(
          (eachUser) => eachUser.emails && eachUser.emails.some((eachEmailEntry) => eachEmailEntry.email === identifier)
        );
      if (appStub === 'jira') matchingUserWithAccess = usersWithAccess?.find((eachUser) => eachUser.id === identifier);

      if (matchingUserWithAccess) return matchingUserWithAccess;

      return undefined;
    },
    [appStub, externalUserList, usersWithAccess, usersWithoutAccess]
  );

  useEffect(() => {
    if (!refreshAt) return;
    const delay = Math.max(refreshAt - Date.now(), 0);
    setTimeout(() => {
      setRefreshAt(null);
      refreshApp();
    }, delay);
  }, [refreshApp, refreshAt]);

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  function refreshAppData(delay = REFRESH_DELAY_APP_USER_LIST) {
    // actions are now asyncly queued before being performed so just pause
    // for a few seconds before refreshing the page to allow some time for
    // the action to complete.
    setRefreshAt(Date.now() + delay);
  }

  const initialValues: AppGroupManagementFormData = {
    userListForProcessing: mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add ? [defaultUserForAdding] : [],
    emailSelectorRequired: false,
    selectedEmailForUserCreation: undefined,
  };

  async function doDeleteUserFromGroup(appUserId: string, identifier: string, groupId: string): Promise<void> {
    try {
      const matchingUser = matchingUserForEmailOrId(identifier);
      await AppIntegrationAPI.deleteAppUserFromGroup(
        appStub,
        appUserId,
        matchingUser?.userId ? +matchingUser.userId : 0,
        groupId
      );
      showMessage('Deleting user from group...', 'success');
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
    }
  }

  async function addUserToGroup(identifier: string, groupId: string, appUserEmail?: string): Promise<void> {
    try {
      if (!identifier) {
        showMessage('Unable to add user to group, as user identifier is missing', 'error');
        return;
      }
      if (identifier && ['google-workspace', 'jira'].includes(appStub)) {
        const matchingUser = identifier ? matchingUserForEmailOrId(identifier) : undefined;
        if (matchingUser)
          await AppIntegrationAPI.addAppUserToGroup(
            appStub,
            identifier,
            matchingUser?.userId ? +matchingUser.userId : 0,
            groupId
          );
        showMessage('Adding user to group(s)...', 'success');
      }
      if (identifier && appStub === 'microsoft365') {
        const matchingUser = appUserEmail ? matchingUserForEmailOrId(appUserEmail) : undefined;
        if (matchingUser)
          await AppIntegrationAPI.addAppUserToGroup(
            appStub,
            identifier,
            matchingUser?.userId ? +matchingUser.userId : 0,
            groupId
          );
        showMessage('Adding user to group(s)...', 'success');
      }
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
    }
  }

  const emailSelectorRequired = useMemo(
    () => mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub),
    [mode, appStub]
  );

  const getParamsForAppUserCreation = (values: AppGroupManagementFormData) => {
    if (emailSelectorRequired) return { selectedEmail: values.selectedEmailForUserCreation };
    return undefined;
  };

  const validationSchema = yup.object({
    selectedEmailForUserCreation: yup
      .string()
      .notRequired()
      .when('emailSelectorRequired', {
        is: true,
        then: yup.string().nullable().required('Selected email is required'),
      }),
  });

  const formik = useFormik<AppGroupManagementFormData>({
    initialValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      try {
        setIsSubmitting(true);
        if (appStub === 'google-workspace') {
          if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && values.userListForProcessing) {
            const userCreationWithGroupsPromises = values.userListForProcessing.map((eachUser) =>
              AppIntegrationAPI.createAppUser(
                appStub,
                +eachUser.userId!,
                {
                  groupIds: eachUser.groupList ? eachUser.groupList.map((g) => String(g.value)) : [],
                  ...getParamsForAppUserCreation(values),
                },
                eachUser.dateOfActivation
              )
            );
            await Promise.all(userCreationWithGroupsPromises);
            showMessage('User creation initiated...', 'success');
          } else if (
            mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit &&
            selectedGroupMembership &&
            selectedGroupMembership.id
          ) {
            const userMembershipDeletionPromises = userListForMembershipDeletion.map((m) => {
              const eachMember = m as GoogleGroupMember;
              return doDeleteUserFromGroup(eachMember.id, eachMember.email, selectedGroupMembership?.id);
            });
            await Promise.all(userMembershipDeletionPromises);
          } else if (
            mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup &&
            existingUser &&
            values.existingUserGroupList
          ) {
            if (values.existingUserGroupList?.length > 0 && existingUser && existingUser.id) {
              let appUserEmail =
                existingUser?.emails && existingUser?.emails.length > 0 ? existingUser.emails[0].email : undefined;
              if (!appUserEmail && existingUser?.primaryEmail) appUserEmail = existingUser.primaryEmail;
              const addUserToGroupPromises = values.existingUserGroupList.map((eachGroup) =>
                appUserEmail ? addUserToGroup(appUserEmail, String(eachGroup.value)) : undefined
              );
              await Promise.all(addUserToGroupPromises.filter(Boolean));
            }
          }
        }
        if (appStub === 'jira') {
          if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && values.userListForProcessing) {
            const userCreationWithGroupsPromises = values.userListForProcessing.map((eachUser) =>
              AppIntegrationAPI.createAppUser(
                appStub,
                +eachUser.userId!,
                {
                  groupIds: eachUser.groupList ? eachUser.groupList.map((g) => String(g.value)) : [],
                },
                eachUser.dateOfActivation
              )
            );
            await Promise.all(userCreationWithGroupsPromises);
            showMessage('User creation initiated...', 'success');
          } else if (
            mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit &&
            selectedGroupMembership &&
            selectedGroupMembership.id
          ) {
            const userMembershipDeletionPromises = userListForMembershipDeletion.map((m) => {
              const eachMember = m as JiraGroupMember;
              return doDeleteUserFromGroup(eachMember.accountId, eachMember.accountId, selectedGroupMembership?.id);
            });
            await Promise.all(userMembershipDeletionPromises);
          } else if (
            mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup &&
            existingUser &&
            values.existingUserGroupList
          ) {
            if (values.existingUserGroupList?.length > 0 && existingUser && existingUser.id) {
              const addUserToGroupPromises = values.existingUserGroupList.map((eachGroup) =>
                addUserToGroup(String(existingUser.id), String(eachGroup.value))
              );
              await Promise.all(addUserToGroupPromises.filter(Boolean));
            }
          }
        }
        if (appStub === 'microsoft365') {
          if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && values.userListForProcessing) {
            const userCreationWithGroupsPromises = values.userListForProcessing.map((eachUser) =>
              AppIntegrationAPI.createAppUser(
                appStub,
                +eachUser.userId!,
                {
                  groupIds: eachUser.groupList ? eachUser.groupList.map((g) => String(g.value)) : [],
                  ...getParamsForAppUserCreation(values),
                },
                eachUser.dateOfActivation
              )
            );
            await Promise.all(userCreationWithGroupsPromises);
            showMessage('User creation initiated...', 'success');
          } else if (
            mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit &&
            selectedGroupMembership &&
            selectedGroupMembership.id
          ) {
            const userMembershipDeletionPromises = userListForMembershipDeletion.map((m) => {
              const eachMember = m as GoogleGroupMember;
              return doDeleteUserFromGroup(eachMember.id, eachMember.email, selectedGroupMembership?.id);
            });
            await Promise.all(userMembershipDeletionPromises);
          } else if (
            mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup &&
            existingUser &&
            values.existingUserGroupList
          ) {
            if (values.existingUserGroupList?.length > 0 && existingUser && existingUser.id) {
              let appIdentifier = String(existingUser?.id);
              const addUserToGroupPromises = values.existingUserGroupList.map((eachGroup) =>
                appIdentifier && existingUser.primaryEmail
                  ? addUserToGroup(appIdentifier, String(eachGroup.value), existingUser.primaryEmail)
                  : undefined
              );
              await Promise.all(addUserToGroupPromises.filter(Boolean));
            }
          }
        }
        closePage();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('emailSelectorRequired', emailSelectorRequired);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSelectorRequired]);

  const enhancedSelectedUserObject = useMemo(() => {
    if (
      formik.values.userListForProcessing &&
      formik.values.userListForProcessing.length === 1 &&
      formik.values.userListForProcessing[0].userId
    ) {
      const userObject = getCachedUserById(formik.values.userListForProcessing[0].userId);
      return userObject
        ? {
            ...userObject,
            emails: [
              {
                email: userObject.emailAddress,
                personalEmail: personalEmailForUser ?? '',
                primary: true,
                status: 'No access', // all users who already have access are already removed from this user list
              },
            ],
          }
        : undefined;
    }
  }, [formik.values.userListForProcessing, getCachedUserById, personalEmailForUser]);

  const selectedEmailOptions = useMemo(() => {
    return enhancedSelectedUserObject
      ? emailOptionsForUserCreation(
          appStub,
          enhancedSelectedUserObject,
          personalEmailForUser ?? '',
          nonTerminatedCachedUsers
        )
      : undefined;
  }, [appStub, enhancedSelectedUserObject, nonTerminatedCachedUsers, personalEmailForUser]);

  const emailSelectorForUserCreation = useMemo(() => {
    if (emailSelectorRequired) {
      return (
        <Box sx={fieldSx}>
          <SelectComponent
            name="emailSelector"
            label="Personal or Work email"
            options={selectedEmailOptions ?? []}
            value={formik.values.selectedEmailForUserCreation}
            error={!!formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation}
            onChange={(e) => {
              const newVal = e.target.value;
              formik.setFieldValue('selectedEmailForUserCreation', newVal, true);
            }}
            helperText={formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation}
          />
        </Box>
      );
    } else return <></>;
  }, [emailSelectorRequired, selectedEmailOptions, formik]);

  const getDrawerTitle = (currentMode: APP_GROUP_MANAGEMENT_DRAWER_MODES) => {
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit) return 'Edit members';
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add) return 'Add users';
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup) return 'Add to group';
    else return '';
  };

  const getDrawerConfirmButtonText = (currentMode: APP_GROUP_MANAGEMENT_DRAWER_MODES) => {
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit) return 'Save';
    if (
      [APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup, APP_GROUP_MANAGEMENT_DRAWER_MODES.add].includes(
        currentMode
      )
    )
      return 'Add';
    else return '';
  };

  // Need to ensure that only groups that a user is not part of is shown in the list of possible groups to be added to
  const groupOptionList = useMemo<MultiSelectOption<GroupId>[]>(() => {
    if (groupList && !existingUser)
      return groupList.map((g) => {
        return {
          label: g.name,
          value: g.id,
        };
      });
    if (groupList && existingUser) {
      return groupList
        .filter(
          (eachGroup) =>
            !Object.values(eachGroup.members).some((groupMember: GroupMember) => {
              if (['google-workspace', 'microsoft365'].includes(appStub))
                return (groupMember as GoogleGroupMember).id === existingUser.id;
              if (appStub === 'jira') return (groupMember as JiraGroupMember).accountId === existingUser.id;
              return false;
            })
        )
        .map((g) => {
          return {
            label: g.name,
            value: g.id,
          };
        });
    }

    return groupList
      ? groupList.map((g) => {
          return {
            label: g.name,
            value: g.id,
          };
        })
      : [];
  }, [appStub, existingUser, groupList]);

  const listForMemberDeletion = useMemo(() => {
    if (userListForMembershipDeletion && appStub === 'google-workspace')
      return userListForMembershipDeletion.map((g) => (g as GoogleGroupMember).email);
    if (userListForMembershipDeletion && appStub === 'jira')
      return userListForMembershipDeletion.map((g) => (g as JiraGroupMember).accountId);
    if (userListForMembershipDeletion && appStub === 'microsoft365')
      return userListForMembershipDeletion.map((g) => (g as Microsoft365GroupMember).id);
    return [];
  }, [appStub, userListForMembershipDeletion]);

  useEffect(() => {
    if (selectedEmailOptions?.length === 1) {
      formik.setFieldValue('selectedEmailForUserCreation', selectedEmailOptions[0]?.value, true);
    } else {
      formik.setFieldValue('selectedEmailForUserCreation', undefined, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmailOptions]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Typography sx={titleSx}>{getDrawerTitle(mode)}</Typography>
          <AppGroupManagementMemberEditList
            appStub={appStub}
            existingUser={existingUser}
            mode={mode}
            setUserListForMembershipDeletion={setUserListForMembershipDeletion}
            userListForMembershipDeletion={userListForMembershipDeletion}
            matchingUserForEmailOrId={matchingUserForEmailOrId}
            groupMembers={groupMembers ?? {}}
            listForMemberDeletion={listForMemberDeletion}
            groupOptionList={groupOptionList}
            existingUserGroupList={formik.values.existingUserGroupList ?? []}
            formikSetFieldValue={formik.setFieldValue}
          />

          <AppGroupManagementMemberAddList
            mode={mode}
            userListForProcessing={formik.values.userListForProcessing}
            userOptionsForAdd={userOptionsForAdd ?? []}
            groupOptionList={groupOptionList}
            formikSetFieldValue={formik.setFieldValue}
          />

          {emailSelectorForUserCreation}

          <Box sx={buttonBoxSx}>
            <LoaderButton
              fullWidth
              type="submit"
              loading={isSubmitting}
              sizeVariant="medium"
              colorVariant="primary"
              disabled={
                isSubmitting ||
                (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add &&
                  (formik.values.userListForProcessing.some((e) => !e.userId) || !formik.isValid)) ||
                (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && userListForMembershipDeletion.length === 0)
              }
            >
              {getDrawerConfirmButtonText(mode)}
            </LoaderButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};
