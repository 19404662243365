import { TaxYear } from '@shared/modules/payroll/payroll.types';
import axios from 'axios';

import type { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';

export class GlobalPayrollAPI {
  static async openPayrun(payrollId: number, taxYear: TaxYear, payPeriod: PayPeriod): Promise<GlobalPayrun> {
    return (
      (await axios.post(`/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/open`))
        .data || null
    );
  }

  static async reopenPayrun(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    period: number
  ): Promise<GlobalPayrun> {
    return (
      (
        await axios.post(
          `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/reopen`
        )
      ).data || null
    );
  }

  static async updatePayrunEntries(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    period: number,
    update: GlobalPayrunEntryUpdate
  ): Promise<GlobalPayrun> {
    return (
      await axios.patch(
        `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/entries`,
        { update }
      )
    ).data;
  }

  static async deletePayrun(payrollId: number, taxYear: TaxYear, payPeriod: PayPeriod, period: number): Promise<void> {
    await axios.delete(`/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}`);
  }

  static async finalisePayrun(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    period: number
  ): Promise<GlobalPayrun> {
    return (
      (
        await axios.post(
          `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/finalise`
        )
      ).data || null
    );
  }

  static async createPaycode(payrollId: number, update: GlobalPaycodeCreate): Promise<GlobalPaycode> {
    return (await axios.post(`/apiv2/company/salary/payroll/${payrollId}/global/paycode`, update)).data;
  }

  static async updatePaycode(payrollId: number, update: GlobalPaycodeUpdate): Promise<GlobalPaycode> {
    return (await axios.patch(`/apiv2/company/salary/payroll/${payrollId}/global/paycode`, update)).data;
  }

  static async deletePaycode(payrollId: number, codeToDelete: string): Promise<void> {
    await axios.delete(`/apiv2/company/salary/payroll/${payrollId}/global/paycode/${codeToDelete}`);
  }

  static async getPayrunForUserId(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    period: number,
    userId: number
  ): Promise<GlobalPayrun | null> {
    return (
      await axios.get(
        `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/${userId}`
      )
    ).data;
  }

  static async updatePaycodeSeedersAsSuperAdmin(countryCode: string, paycodes: GlobalPaycodeCreate[]): Promise<void> {
    await axios.patch(`/apiv2/company/salary/payroll/0/global/paycode/seeders?country=${countryCode}`, paycodes);
  }
}

export class GlobalPayrollEndpoints {
  static getPayrun(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    period: number
  ): Endpoint<GlobalPayrun | null> {
    return {
      url: `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}`,
    };
  }

  static getPayrollPaycodes(payrollId: number): Endpoint<GlobalPaycodesDto | null> {
    return {
      url: `/apiv2/company/salary/payroll/${payrollId}/global/paycodes`,
    };
  }

  static exportPayrun(payrollId: number, taxYear: TaxYear, payPeriod: PayPeriod, period: number): Endpoint<void> {
    return {
      url: `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/export`,
    };
  }

  static getPaycodeSeedersAsSuperAdmin(): Endpoint<GlobalPaycodeSeedersDto> {
    return {
      url: '/apiv2/company/salary/payroll/0/global/paycode/seeders',
    };
  }
}
