import { useHistory } from 'react-router-dom';

import { REVIEWS_COMPANY_ONGOING_ROUTE, REVIEWS_TEAM_ONGOING_ROUTE } from '@/lib/routes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { LaunchRolling } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/components/rolling/launch-rolling.component';
import { LaunchScheduled } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/components/scheduled/launch-scheduled.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewCycle, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const RCUpsertLaunchPage = ({
  cycleId,
  reviewCycle,
  reach,
}: {
  cycleId: string;
  reviewCycle: ReviewCycle | null | undefined;
  reach: ReachType;
}) => {
  const { data: reviewCycleLaunchDetails, isLoading: cycleLaunchLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewLaunchDetailsByCycleId(cycleId),
    {
      suspense: false,
    }
  );
  const routerHistory = useHistory();

  if (!reviewCycleLaunchDetails) return <></>;

  const showFlags = {
    showSelf: Boolean(reviewCycleLaunchDetails.reviewerSelect?.includes(ReviewerTypes.Self)),
    showUpward: Boolean(reviewCycleLaunchDetails.reviewerSelect?.includes(ReviewerTypes.Upward)),
    showPeer: Boolean(reviewCycleLaunchDetails.reviewerSelect?.includes(ReviewerTypes.Peer)),
    showManager: Boolean(reviewCycleLaunchDetails.reviewerSelect?.includes(ReviewerTypes.Manager)),
  };

  const goBack = () => {
    const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_ROUTE : REVIEWS_TEAM_ONGOING_ROUTE;
    routerHistory.push(reachRoute);
  };

  if (!reviewCycle) return <></>;

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reach} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={cycleLaunchLoading}
    >
      {reviewCycle.type === CycleType.Rolling ? (
        <LaunchRolling
          reviewCycleLaunchDetails={reviewCycleLaunchDetails}
          cycleId={cycleId}
          goBack={goBack}
          showFlags={showFlags}
        />
      ) : (
        <LaunchScheduled
          reviewCycleLaunchDetails={reviewCycleLaunchDetails}
          cycleId={cycleId}
          goBack={goBack}
          showFlags={showFlags}
        />
      )}
    </SettingsSectionContent>
  );
};
