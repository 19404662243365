import { useMemo } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { CompanyConfig } from '@shared/modules/company/company.types';
import { Typography } from '@v2/components/typography/typography.component';
import {
  DataDomain,
  domainArray,
  FormDataInterface,
  ReportEntity,
  ReportTypeAttendanceSelection,
  ReportTypeDevicesSelection,
  ReportTypePeopleSelection,
  ReportTypeTimeSelection,
} from '@v2/feature/reports/reports.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { borders } from '@v2/styles/borders.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';

const getTypeByDomain = (
  domain: DataDomain
): ReportTypeTimeSelection | ReportTypeDevicesSelection | ReportTypeAttendanceSelection | ReportTypePeopleSelection => {
  if (domain === DataDomain.TimeAway) return ReportTypeTimeSelection.Requests;
  if (domain === DataDomain.Devices) return ReportTypeDevicesSelection.Devices;
  if (domain === DataDomain.Attendance) return ReportTypeAttendanceSelection.Attendance;

  return ReportTypePeopleSelection.CurrentRecords;
};

export const ReportDomain = ({
  onNext,
  setFormData,
  formData,
  companyConfig,
}: {
  readonly onNext: () => void;
  readonly setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  readonly formData: FormDataInterface;
  readonly companyConfig: CompanyConfig;
}) => {
  const { polyglot } = usePolyglot();

  const domains = useMemo(() => {
    return domainArray(polyglot).filter(
      (d) => d.name !== DataDomain.Payroll || (d.name === DataDomain.Payroll && companyConfig.inPayroll)
    );
  }, [polyglot, companyConfig]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography variant="title2">{polyglot.t('ReportDomain.selectData')}</Typography>

        <FormControl sx={{ width: '100%', ...spacing.mt20 }}>
          <RadioGroup
            value={formData.domain}
            onChange={(event) => {
              const domain = event.target.value as DataDomain;
              setFormData((prev) => ({
                ...prev,
                domain,
                type: getTypeByDomain(domain),
                ...([DataDomain.Devices, DataDomain.Attendance, DataDomain.TimeAway].includes(domain)
                  ? {
                      entities: [
                        event.target.value === DataDomain.Devices
                          ? ReportEntity.Devices
                          : event.target.value === DataDomain.Attendance
                          ? ReportEntity.Attendance
                          : ReportEntity.Requests,
                      ],
                    }
                  : {}),
              }));
            }}
            sx={{ flexFlow: 'column' }}
          >
            {domains.map((d, idx) => (
              <FormControlLabel
                key={idx}
                labelPlacement="start"
                value={d.name}
                control={<StyledRadio />}
                label={<Typography variant="caption">{d.label}</Typography>}
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: idx < domainArray(polyglot).length - 1 ? borders.background : 'none',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <ButtonComponent
          sizeVariant="large"
          colorVariant="primary"
          style={{ marginTop: spacing.m25 }}
          onClick={onNext}
          fullWidth
        >
          {polyglot.t('General.next')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
