import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  ZeltMdmDevicePolicyType,
  ZeltPolicyPayloadIdentifier,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  ICloudRestrictionOptions,
  ParentalControlsContentFilterOptions,
  RestrictionSecurityOptions,
} from '@/v2/feature/device/device.dto';

export const ZeltMdmRestrictionPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  selectedRestriction,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  selectedRestriction: ZeltMdmDevicePolicyType | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [whiteListUrlsHelper, setWhiteListUrlsHelper] = useState<string>(
    configurablePoliciesSelected?.configurableBrowsing?.filterWhitelist?.toString() ?? ''
  );
  const [blackListUrlsHelper, setBlackListUrlsHelper] = useState<string>(
    configurablePoliciesSelected?.configurableBrowsing?.filterBlacklist?.toString() ?? ''
  );
  const getICloudRestriction = (): ICloudRestrictionOptions => {
    return {
      allowCloudBookmarks:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudBookmarks !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudBookmarks
          : false,
      allowCloudMail:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudMail !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudMail
          : false,
      allowCloudCalendar:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudCalendar !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudCalendar
          : false,
      allowCloudReminders:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudReminders !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudReminders
          : false,
      allowCloudAddressBook:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudAddressBook !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudAddressBook
          : false,
      allowCloudNotes:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudNotes !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudNotes
          : false,
      allowCloudDocumentSync:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudDocumentSync !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudDocumentSync
          : false,
      allowCloudKeychainSync:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudKeychainSync !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudKeychainSync
          : false,
      allowCloudPhotoLibrary:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudPhotoLibrary !== undefined
          ? !configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudPhotoLibrary
          : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const iCloudinitialValues: ICloudRestrictionOptions = getICloudRestriction();

  function analyzeICloudPolicyBeforeSave(policy: ICloudRestrictionOptions) {
    let allNulls = true;
    for (const key in policy) {
      if (key !== 'PayloadIdentifier' && (policy as Record<string, boolean>)[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return undefined;
    }
    return {
      allowCloudBookmarks: !policy.allowCloudBookmarks,
      allowCloudMail: !policy.allowCloudMail,
      allowCloudCalendar: !policy.allowCloudCalendar,
      allowCloudReminders: !policy.allowCloudReminders,
      allowCloudAddressBook: !policy.allowCloudAddressBook,
      allowCloudNotes: !policy.allowCloudNotes,
      allowCloudDocumentSync: !policy.allowCloudDocumentSync,
      allowCloudKeychainSync: !policy.allowCloudKeychainSync,
      allowCloudPhotoLibrary: !policy.allowCloudPhotoLibrary,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }

  const formikICloud = useFormik<ICloudRestrictionOptions>({
    initialValues: iCloudinitialValues,
    onSubmit: async (iCloudForm: ICloudRestrictionOptions) => {
      const configurablePolicy = analyzeICloudPolicyBeforeSave(iCloudForm);
      updatePolicies({
        ...configurablePoliciesSelected,
        configurableICloudRestriction: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  const getSecurityRestrictions = (): RestrictionSecurityOptions => {
    return {
      allowCamera:
        configurablePoliciesSelected?.configurableSecurity?.allowCamera !== undefined
          ? !configurablePoliciesSelected?.configurableSecurity?.allowCamera
          : false,
      allowSafari:
        configurablePoliciesSelected?.configurableSecurity?.allowSafari !== undefined
          ? !configurablePoliciesSelected?.configurableSecurity?.allowSafari
          : false,
      allowAirDrop:
        configurablePoliciesSelected?.configurableSecurity?.allowAirDrop !== undefined
          ? !configurablePoliciesSelected?.configurableSecurity?.allowAirDrop
          : false,
      allowDictation:
        configurablePoliciesSelected?.configurableSecurity?.allowDictation !== undefined
          ? !configurablePoliciesSelected?.configurableSecurity?.allowDictation
          : false,
      allowScreenShot:
        configurablePoliciesSelected?.configurableSecurity?.allowScreenShot !== undefined
          ? !configurablePoliciesSelected?.configurableSecurity?.allowScreenShot
          : false,
      allowAssistant:
        configurablePoliciesSelected?.configurableSecurity?.allowAssistant !== undefined
          ? !configurablePoliciesSelected?.configurableSecurity?.allowAssistant
          : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const securityValues: RestrictionSecurityOptions = getSecurityRestrictions();

  function analyzeSecurityPolicyBeforeSave(policy: RestrictionSecurityOptions) {
    let allNulls = true;
    for (const key in policy) {
      if (key !== 'PayloadIdentifier' && (policy as Record<string, boolean>)[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return undefined;
    }
    return {
      allowCamera: !policy.allowCamera,
      allowSafari: !policy.allowSafari,
      allowAirDrop: !policy.allowAirDrop,
      allowDictation: !policy.allowDictation,
      allowScreenShot: !policy.allowScreenShot,
      allowAssistant: !policy.allowAssistant,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }

  const formikSecurity = useFormik<RestrictionSecurityOptions>({
    initialValues: securityValues,
    onSubmit: async (form: RestrictionSecurityOptions) => {
      const configurablePolicy = analyzeSecurityPolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableSecurity: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  const getBrowsingRestrictions = (): ParentalControlsContentFilterOptions => {
    return {
      restrictWeb:
        configurablePoliciesSelected?.configurableBrowsing?.restrictWeb !== undefined
          ? configurablePoliciesSelected?.configurableBrowsing?.restrictWeb
          : true,
      useContentFilter:
        configurablePoliciesSelected?.configurableBrowsing?.useContentFilter !== undefined
          ? configurablePoliciesSelected?.configurableBrowsing?.useContentFilter
          : true,
      filterBlacklist:
        configurablePoliciesSelected?.configurableBrowsing?.filterBlacklist !== undefined
          ? configurablePoliciesSelected?.configurableBrowsing?.filterBlacklist
          : [],
      filterWhitelist:
        configurablePoliciesSelected?.configurableBrowsing?.filterWhitelist !== undefined
          ? configurablePoliciesSelected?.configurableBrowsing?.filterWhitelist
          : [],
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.BROWSING_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const browsingValues: ParentalControlsContentFilterOptions = getBrowsingRestrictions();

  function analyzeBrowsingPolicyBeforeSave() {
    if (whiteListUrlsHelper.length === 0 && blackListUrlsHelper.length === 0) {
      return undefined;
    }
    return {
      restrictWeb: true,
      useContentFilter: true,
      ...(blackListUrlsHelper.length > 0 ? { filterBlacklist: blackListUrlsHelper.split(',') } : {}),
      ...(whiteListUrlsHelper.length > 0 ? { filterWhitelist: whiteListUrlsHelper.split(',') } : {}),
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.BROWSING_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }
  const formikBrowsing = useFormik<ParentalControlsContentFilterOptions>({
    initialValues: browsingValues,
    onSubmit: async () => {
      const configurablePolicy = analyzeBrowsingPolicyBeforeSave();
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableBrowsing: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formikICloud.validateForm();
    formikSecurity.validateForm();
    formikBrowsing.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectedRestriction === ZeltMdmDevicePolicyType.icloud && (
        <Box>
          <Typography variant="title2">iCloud restrictions</Typography>
          <FormikProvider value={formikICloud}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
              <CheckboxComponent
                label="Safari bookmarks"
                name="allowCloudBookmarks"
                checked={formikICloud.values.allowCloudBookmarks}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Mail"
                name="allowCloudMail"
                checked={formikICloud.values.allowCloudMail}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Calendar"
                name="allowCloudCalendar"
                checked={formikICloud.values.allowCloudCalendar}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Reminders"
                name="allowCloudReminders"
                checked={formikICloud.values.allowCloudReminders}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Contacts"
                name="allowCloudAddressBook"
                checked={formikICloud.values.allowCloudAddressBook}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Notes"
                name="allowCloudNotes"
                checked={formikICloud.values.allowCloudNotes}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Drive"
                name="allowCloudDocumentSync"
                checked={formikICloud.values.allowCloudDocumentSync}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="KeyChain sync"
                name="allowCloudKeychainSync"
                checked={formikICloud.values.allowCloudKeychainSync}
                onChange={formikICloud.handleChange}
              />
              <CheckboxComponent
                label="Photos"
                name="allowCloudPhotoLibrary"
                checked={formikICloud.values.allowCloudPhotoLibrary}
                onChange={formikICloud.handleChange}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: 3 }}>
              <ButtonComponent
                onClick={() => formikICloud.handleSubmit()}
                fullWidth
                colorVariant="primary"
                sizeVariant="medium"
              >
                Save
              </ButtonComponent>
            </Box>
          </FormikProvider>
        </Box>
      )}

      {selectedRestriction === ZeltMdmDevicePolicyType.security && (
        <Box>
          <Typography variant="title2">Apps and features restrictions</Typography>
          <FormikProvider value={formikSecurity}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
              <CheckboxComponent
                label="Camera"
                name="allowCamera"
                checked={formikSecurity.values.allowCamera}
                onChange={formikSecurity.handleChange}
              />
              <CheckboxComponent
                label="Safari"
                name="allowSafari"
                checked={formikSecurity.values.allowSafari}
                onChange={formikSecurity.handleChange}
              />
              <CheckboxComponent
                label="AirDrop"
                name="allowAirDrop"
                checked={formikSecurity.values.allowAirDrop}
                onChange={formikSecurity.handleChange}
              />
              <CheckboxComponent
                label="Siri"
                name="allowAssistant"
                checked={formikSecurity.values.allowAssistant}
                onChange={formikSecurity.handleChange}
              />
              <CheckboxComponent
                label="Dictation"
                name="allowDictation"
                checked={formikSecurity.values.allowDictation}
                onChange={formikSecurity.handleChange}
              />
              <CheckboxComponent
                label="Screen capture"
                name="allowScreenShot"
                checked={formikSecurity.values.allowScreenShot}
                onChange={formikSecurity.handleChange}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: 3 }}>
              <ButtonComponent
                onClick={() => formikSecurity.handleSubmit()}
                fullWidth
                colorVariant="primary"
                sizeVariant="medium"
              >
                Save
              </ButtonComponent>
            </Box>
          </FormikProvider>
        </Box>
      )}

      {selectedRestriction === ZeltMdmDevicePolicyType.browsing && (
        <Box>
          <Typography variant="title2">Safari browsing</Typography>
          <Box sx={{ gap: 2, mt: 2 }}>
            <Typography variant="caption">
              Please be aware that your actions can have an impact on your access to web URLs.
            </Typography>
          </Box>
          <FormikProvider value={formikBrowsing}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
              <TextfieldComponent
                label="Blacklist web URLs"
                name="blackListUrlsHelper"
                value={blackListUrlsHelper}
                onChange={(e) => {
                  let value = e?.target.value;
                  setBlackListUrlsHelper(value);
                }}
              />
              <TextfieldComponent
                label="Whitelist web URLs"
                name="whiteListUrlsHelper"
                value={whiteListUrlsHelper}
                onChange={(e) => {
                  let value = e?.target.value;
                  setWhiteListUrlsHelper(value);
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: 3 }}>
              <ButtonComponent
                onClick={() => formikBrowsing.handleSubmit()}
                fullWidth
                colorVariant="primary"
                sizeVariant="medium"
              >
                Save
              </ButtonComponent>
            </Box>
          </FormikProvider>
        </Box>
      )}
    </>
  );
};
