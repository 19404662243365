import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  GROWTH_ROUTE,
  REVIEWS_COMPANY_ROUTE,
  REVIEWS_ME_ROUTE,
  REVIEWS_TEAM_ROUTE,
  SURVEYS_COMPANY_ROUTE,
  SURVEYS_ME_ROUTE,
  SURVEYS_TEAM_ROUTE,
} from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';
import { ReviewsRouter } from '@/v2/feature/growth/reviews/reviews.router';
import { SurveysRouter } from '@/v2/feature/growth/surveys/surveys.router';

export const GrowthRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  return (
    <Switch>
      <RouteScopesControl
        scopes={['reviews']}
        context={scopesContext}
        path={[REVIEWS_COMPANY_ROUTE, REVIEWS_TEAM_ROUTE, REVIEWS_ME_ROUTE]}
        component={ReviewsRouter}
      />

      <RouteScopesControl
        scopes={['surveys']}
        context={scopesContext}
        path={[SURVEYS_COMPANY_ROUTE, SURVEYS_TEAM_ROUTE, SURVEYS_ME_ROUTE]}
        component={SurveysRouter}
      />
      {canAccessScopes(user, ['reviews:all']) && <Redirect from={GROWTH_ROUTE} to={REVIEWS_COMPANY_ROUTE} />}
      {canAccessScopes(user, ['reviews:manager']) && <Redirect from={GROWTH_ROUTE} to={REVIEWS_TEAM_ROUTE} />}
      {canAccessScopes(user, ['reviews']) && <Redirect from={GROWTH_ROUTE} to={REVIEWS_ME_ROUTE} />}
      {canAccessScopes(user, ['surveys:all']) && <Redirect from={GROWTH_ROUTE} to={SURVEYS_COMPANY_ROUTE} />}
      {canAccessScopes(user, ['surveys:manager']) && <Redirect from={GROWTH_ROUTE} to={SURVEYS_TEAM_ROUTE} />}
      {canAccessScopes(user, ['surveys']) && <Redirect from={GROWTH_ROUTE} to={SURVEYS_ME_ROUTE} />}
    </Switch>
  );
};
