import { useContext } from 'react';

import { OffboardingUserRouter } from '@v2/feature/offboarding/offboarding-user.router';
import { Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { OFFBOARDING_ROUTE, USER_OFFBOARDING_ROUTE } from '@/lib/routes';
import { OffboardingPage } from '@/v2/feature/offboarding/offboarding.page';

export const OffboardingRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: globalState.user.userId });

  return (
    <Switch>
      <RouteScopesControl scopes={['user.onboard:all']} path={USER_OFFBOARDING_ROUTE} context={scopesContext}>
        <OffboardingUserRouter />
      </RouteScopesControl>

      <RouteScopesControl scopes={['user.onboard:all']} path={OFFBOARDING_ROUTE} context={scopesContext}>
        <OffboardingPage />
      </RouteScopesControl>
    </Switch>
  );
};
