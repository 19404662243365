import { Children, Fragment, ReactNode } from 'react';

type ReactChildArray = ReturnType<typeof Children.toArray>;

export function flattenChildren(children: React.ReactNode): ReactChildArray {
  const childrenArray = Children.toArray(children);
  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    if ((child as React.ReactElement<any>).type === Fragment) {
      return flatChildren.concat(flattenChildren((child as React.ReactElement<any>).props.children));
    }
    flatChildren.push(child);
    return flatChildren;
  }, []);
}

interface Props {
  children: ReactNode;
}

/**
 * This component is needed when we want to achieve modularity and nest custom routers inside Switch (ie.: {domain}.router.tsx pattern).
 * The issue comes from the fact that we need to wrap our modular routers with a fragment. React router isn't able to identify fragments
 * so we need to flatten it.
 * */
export const NestedRouter = ({ children }: Props): JSX.Element => <>{flattenChildren(children)}</>;
