import { useCallback, useEffect, useState } from 'react';

import { BenefitsCreateRouter } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/benefits-create.router';
import { BenefitsSettingsListPage } from '@v2/feature/benefits/subfeature/benefits-settings/pages/benefits-settings-list.page';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionAPI as PensionAPIV2 } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_BENEFITS_DETAILS_ROUTE,
  SETTINGS_BENEFITS_ROUTE,
  SETTINGS_CREATE_NEW_BENEFIT_ROUTE,
} from '@/lib/routes';
import { ScopeContext } from '@/models';
import { BenefitsSettingsDetailsRouter } from '@/v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/benefits-settings-details.router';

export const BenefitsSettingsRouter = ({ context }: { context: ScopeContext }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const { companyConfig, isLoading } = useCompanyConfigState();

  const hasPensionAllScope = hasScopes(['pension:all']);
  const hasInsuranceAllScope = hasScopes(['insurance:all']);
  const [loading, setLoading] = useState<boolean>(true);
  const [pensionSchemes, setPensionSchemes] = useState<readonly PensionSchemeDto[]>([]);
  const [insuranceQuote, setInsuranceQuote] = useState<InsuranceQuoteDto | null>(null);
  const [customBenefits, setCustomBenefits] = useState<CustomBenefitDto[]>([]);

  const refreshPensionSchemes = useCallback(async () => {
    if (!hasPensionAllScope || !companyConfig?.inPension) return;
    try {
      const pensionSchemes = await PensionAPIV2.getCompanyPensionSchemes();
      setPensionSchemes(pensionSchemes);
    } catch (err) {
      showMessage(
        polyglot.t('PensionModule.ErrorMessages.couldNotGetProviders', { errorMessage: nestErrorMessage(err) }),
        'error'
      );
    }
  }, [polyglot, companyConfig, hasPensionAllScope, showMessage]);

  const refreshInsuranceQuote = useCallback(async () => {
    if (!hasInsuranceAllScope) return;
    try {
      const insuranceQuote = await InsuranceAPI.getInsuranceQuote();
      setInsuranceQuote(insuranceQuote);
    } catch (err) {
      showMessage(
        polyglot.t('BenefitModule.ErrorMessages.couldNotGetInsuranceQuote', { errorMessage: nestErrorMessage(err) }),
        'error'
      );
    }
  }, [polyglot, hasInsuranceAllScope, showMessage]);

  const refreshCustomBenefits = useCallback(async () => {
    if (!hasInsuranceAllScope) return;
    try {
      const benefits = await CustomBenefitAPI.getAllCustomBenefits();
      setCustomBenefits(benefits);
    } catch (err) {
      showMessage(
        polyglot.t('BenefitModule.ErrorMessages.couldNotGetCustomBenefit', { errorMessage: nestErrorMessage(err) }),
        'error'
      );
    }
  }, [polyglot, hasInsuranceAllScope, showMessage]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([refreshPensionSchemes(), refreshInsuranceQuote(), refreshCustomBenefits()]);
      setLoading(false);
    })();
  }, [refreshPensionSchemes, refreshInsuranceQuote, refreshCustomBenefits]);

  return (
    <Switch>
      <RouteScopesControl
        scopes={['insurance:all', 'pension:all']}
        path={SETTINGS_BENEFITS_DETAILS_ROUTE}
        component={BenefitsSettingsDetailsRouter}
      />

      <RouteScopesControl
        context={context}
        scopes={['insurance:all', 'pension:all']}
        path={SETTINGS_CREATE_NEW_BENEFIT_ROUTE}
      >
        <BenefitsCreateRouter
          pensionSchemes={pensionSchemes}
          refreshPensionSchemes={refreshPensionSchemes}
          refreshInsuranceQuote={refreshInsuranceQuote}
          companyConfig={companyConfig ?? { inPayroll: false, inPension: false }}
        />
      </RouteScopesControl>

      <RouteScopesControl context={context} scopes={['insurance:all', 'pension:all']} path={SETTINGS_BENEFITS_ROUTE}>
        <BenefitsSettingsListPage
          pensionSchemes={pensionSchemes}
          customBenefits={customBenefits}
          insuranceQuote={insuranceQuote}
          refreshCustomBenefits={refreshCustomBenefits}
          loading={Boolean(loading || isLoading)}
        />
      </RouteScopesControl>

      <Redirect to={SETTINGS_BENEFITS_ROUTE} />
    </Switch>
  );
};
