import { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { MemberGroup } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type MembersPageProps = {
  initialValues?: MemberGroup;
  onNext: (members: MemberGroup) => void;
};

export const MembersPage = ({ initialValues, onNext }: MembersPageProps) => {
  const { polyglot } = usePolyglot();

  const [members, setMembers] = useState(initialValues);

  const hasMember =
    !!members && (members.customRule || members.filter !== UserSelectFiltersOptions.None || !!members.userIds.length);
  return (
    <Stack sx={{ flex: 1, alignItems: 'center', width: '100%' }}>
      <Stack sx={{ width: '450px', gap: spacing.g30 }}>
        <Typography variant="title2">{polyglot.t('MembersPage.members')}</Typography>
        <Typography variant="caption">{polyglot.t('MembersPage.membersDesc')}</Typography>
        <UserSelect
          label={polyglot.t('MembersPage.members')}
          selectedLabel=""
          initialFilterValue={members?.filter}
          ruleString={members?.customRule}
          value={members?.userIds}
          onChange={(userIds, filter, customRule) => {
            setMembers({
              userIds,
              filter,
              customRule,
            });
          }}
        />
        <Box sx={{ mt: spacing.mt40 }}>
          {hasMember && (
            <ButtonComponent
              sizeVariant="large"
              colorVariant="primary"
              type="button"
              fullWidth
              onClick={() => onNext(members)}
            >
              {polyglot.t('General.continue')}
            </ButtonComponent>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
