import { Fragment, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import { UserDetailsScopes } from '@/component/dashboard/userDetails/user-details.scopes';
import { RouteScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import {
  ADMIN_USER_DOCUMENTS_ROUTE,
  ADMIN_USER_REVIEWS_ROUTE,
  PEOPLE_ME_DIRECTORY_ROUTE,
  USER_ABSENCE_ROUTE,
  USER_APPS_TAB,
  USER_ATTENDANCE_ROUTE,
  USER_BENEFITS_ROUTE,
  USER_COMPENSATION_TAB,
  USER_CONTACT_TAB,
  USER_DETAILS_PROFILE_ROUTE,
  USER_DETAILS_ROUTE,
  USER_DEVICES_PAGE,
  USER_EXPENSES_NEW_EXPENSE_ROUTE,
  USER_EXPENSES_ROUTE,
  USER_INVOICES_NEW_INVOICE_ROUTE,
  USER_INVOICES_ROUTE,
  USER_PAY_ITEMS_ROUTE,
  USER_PAYROLL_NEW_STARTER,
  USER_PAYROLL_PAYSLIPS,
  USER_PAYROLL_SETTINGS,
  USER_PERSONAL_TAB,
  USER_TASKS_PAGE,
  USER_WORK_TAB,
} from '@/lib/routes';
import { Scope, ScopeRoot } from '@/models';
import { Redirect, Switch } from 'react-router-dom';

import { UserBenefitsRouter } from '@/component/dashboard/userDetails/benefits/user-benefits.router';
import { UserPayItemsPage } from '@/component/dashboard/userDetails/pay-items/user-pay-items.page';
import { getUserProfileSecondaryMenu } from '@/component/dashboard/userDetails/user-details.interface';
import { UploadProfilePicture } from '@/component/forms/UploadProfilePicture';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import useMessage from '@/hooks/notification.hook';
import { checkScopes } from '@/lib/scopes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AbsenceScopes } from '@/v2/feature/absence/absence.scopes';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';
import { DocumentsScopes } from '@/v2/feature/documents/documents.scopes';
import { ExpensePage } from '@/v2/feature/expense/expense.page';
import { USER_PROFILE_GROWTH_SCOPE } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { InvoicesPage } from '@/v2/feature/payments/pages/invoice.page';
import { NewExpensePage } from '@/v2/feature/payments/pages/new-expense.page';
import { NewInvoicePage } from '@/v2/feature/payments/pages/new-invoice.page';
import { TaskScopes } from '@/v2/feature/task/task.scopes';
import { OnboardingPayrollPage } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll.page';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { collateMissingFieldsForUser } from '@/v2/feature/user/features/user-forms/user-forms.util';
import { UserAbsencePage } from '@/v2/feature/user/features/user-profile/absence/user-absence.page';
import { UserAppsPage } from '@/v2/feature/user/features/user-profile/apps/pages/user-apps.page';
import { UserAttendancePage } from '@/v2/feature/user/features/user-profile/attendance/user-attendance.page';
import { UserProfileAction } from '@/v2/feature/user/features/user-profile/details/components/user-profile-action.component';
import { UserCompensation } from '@/v2/feature/user/features/user-profile/details/pages/user-compensation.page';
import { UserContact } from '@/v2/feature/user/features/user-profile/details/pages/user-contact.page';
import { UserPersonal } from '@/v2/feature/user/features/user-profile/details/pages/user-personal.page';
import { UserWork } from '@/v2/feature/user/features/user-profile/details/pages/user-work.page';
import { UserProfileDocument } from '@/v2/feature/user/features/user-profile/documents/user-profile-document.page';
import { UserProfilePayroll } from '@/v2/feature/user/features/user-profile/payroll/user-profile-payroll.page';
import { UserTasksPage } from '@/v2/feature/user/features/user-profile/tasks/user-tasks.page';
import { UserAPI, UserEndpoints } from '@/v2/feature/user/user.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { textOverflowSx } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { Box } from '@mui/material';
import { UserProfileDeviceRouter } from '@v2/feature/user/features/user-profile/devices/user-profile-device.router';
import { useUserProfileConfig } from '@v2/feature/user/features/user-profile/user-profile.hook';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { UserDetailsGrowthRouter } from './user-details-growth.router';

export const UserDetailsTabsRouter = (): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [state, dispatch] = useContext(GlobalContext);
  const userId = useUserIdParam();

  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const { userOverviewConfig, refreshUserOverviewConfig } = useUserProfileConfig(userId);
  const { companyConfig } = useCompanyConfigState();

  const scopesContext = getScopesContext({ userId });
  const isOwnerProfile = state.user?.userId === userId;
  const getProfileScope = useCallback(
    (scopeRoot: ScopeRoot): readonly Scope[] => [
      (isOwnerProfile ? `${scopeRoot}:read` : `${scopeRoot}:read:all`) as Scope,
    ],
    [isOwnerProfile]
  );

  const payrollScopes = getProfileScope('payroll');
  const pensionScopes = getProfileScope('pension');
  const deferredEndpointsPaused = useRef(true);

  const [globalState] = useContext(GlobalContext);
  const hasUserAllScope = checkScopes(globalState.user, ['user', 'user:all'], scopesContext);
  const [localAvatar, setLocalAvatar] = useState<string>();
  const [showMessage] = useMessage();

  const { data: userMissingFields, mutate: refreshMissingFields, isLoading } = useApiClient(
    UserEndpoints.getUserMissingFields(userId),
    {
      isPaused: () => deferredEndpointsPaused.current,
      suspense: false,
    }
  );

  const missingFields = useMemo(() => userMissingFields && collateMissingFieldsForUser(userMissingFields), [
    userMissingFields,
  ]);

  useEffect(() => {
    // missing-fields and absence policies are expensive APIs, so for now defer them to
    // allow other (faster) profile APIs to run first
    if (!deferredEndpointsPaused.current) return;
    deferredEndpointsPaused.current = false;
    refreshMissingFields?.();
  }, [refreshMissingFields]);

  const handleUpdate = useCallback(async () => {
    await refreshUserOverviewConfig();
    dispatch({
      type: GlobalStateActions.UPDATE_ALERTS,
      payload: { people: await UserAPI.getAlerts(state.user?.userId) },
    });
  }, [dispatch, refreshUserOverviewConfig, state.user?.userId]);

  const finallyRefreshUserObject = useCallback(
    async (section: string) => {
      if (['user.basicInfo', 'user.role', 'user.lifecycle'].includes(section)) {
      }
      await handleUpdate();
    },
    [handleUpdate]
  );
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userId);

  const getUserAvatar = useCallback(async () => {
    if (!userId) return;
    try {
      const userAvataURL = await UserAPI.getUserAvatar(userId);
      setLocalAvatar(userAvataURL);
    } catch (error) {
      showMessage(polyglot.t('UserProfileHeader.errorMessages.load'), 'error');
    }
  }, [showMessage, userId, polyglot]);

  useEffect(() => {
    getUserAvatar();
  }, [getUserAvatar]);

  const customSection = useMemo(() => {
    return isLoading ? (
      <UserProfileLoader />
    ) : user ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s2 }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', boxSizing: 'border-box' }}>
          {hasUserAllScope ? (
            <UploadProfilePicture
              value={localAvatar}
              onChange={(tempAvatar) => {
                setLocalAvatar(tempAvatar);
              }}
              url={UserAPI.getUserAvatarURL(userId)}
              userId={userId}
              avatarSize="x100"
              boxSize={100}
            />
          ) : (
            <UserAvatar userId={user?.userId} size="x100" key={user?.userId} />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <Typography variant="title3" sx={textOverflowSx}>
            {polyglot.t(user.displayName)}
          </Typography>
          {user.role?.jobTitle && (
            <Typography variant="caption" sx={textOverflowSx}>
              {polyglot.t(user.role?.jobTitle ?? '')}
            </Typography>
          )}
          {user.emailAddress && (
            <Typography
              variant="captionSmall"
              sx={{
                color: themeColors.Grey,
                marginTop: spacing.sm,
                ...textOverflowSx,
              }}
            >
              {user.emailAddress}
            </Typography>
          )}
        </div>
        <UserProfileAction userId={userId} finallyRefreshUserObject={finallyRefreshUserObject} />
      </Box>
    ) : (
      <></>
    );
  }, [user, isLoading, finallyRefreshUserObject, hasUserAllScope, localAvatar, polyglot, userId]);

  return (
    <Fragment>
      <DomainSideMenuContent
        showBack
        backPath={PEOPLE_ME_DIRECTORY_ROUTE}
        pageConfig={
          getUserProfileSecondaryMenu(
            userId,
            companyConfig,
            userOverviewConfig,
            currentUser,
            !!state.showHiddenFeatures,
            scopesContext,
            polyglot,
            missingFields
          ) as readonly NavConfigItem[]
        }
        customTitle={customSection}
      />
      <Switch>
        <RouteScopesControl exact scopes={TaskScopes.VIEW_USER_TASKS} context={scopesContext} path={USER_TASKS_PAGE}>
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="83%"
                height="40%"
                sx={{ borderRadius: 2, backgroundColor: themeColors.Background }}
              />
            }
          >
            <UserTasksPage userId={userId} />
          </Suspense>
        </RouteScopesControl>
        <RouteScopesControl
          exact
          scopes={AbsenceScopes.VIEW_USER_ABSENCES}
          context={scopesContext}
          path={USER_ABSENCE_ROUTE}
        >
          <UserAbsencePage userId={userId} />
        </RouteScopesControl>
        <RouteScopesControl scopes={AbsenceScopes.VIEW_USER_ABSENCES} context={scopesContext} path={USER_ABSENCE_ROUTE}>
          <Redirect to={USER_ABSENCE_ROUTE} />
        </RouteScopesControl>
        <RouteScopesControl scopes={['attendance:manager']} context={scopesContext} path={USER_ATTENDANCE_ROUTE}>
          <UserAttendancePage userId={userId} />
        </RouteScopesControl>
        <RouteScopesControl scopes={DeviceScopes.VIEW_USER_DEVICES} context={scopesContext} path={USER_DEVICES_PAGE}>
          <UserProfileDeviceRouter />
        </RouteScopesControl>
        <RouteScopesControl scopes={payrollScopes} context={scopesContext} path={USER_PAYROLL_NEW_STARTER}>
          <OnboardingPayrollPage mode="new-starter" />
        </RouteScopesControl>
        {userOverviewConfig?.contractType !== 'Contractor' && (
          <RouteScopesControl
            scopes={payrollScopes}
            context={scopesContext}
            path={[USER_PAYROLL_PAYSLIPS, USER_PAYROLL_SETTINGS]}
            exact
          >
            <UserProfilePayroll userId={userId} userOverviewConfig={userOverviewConfig} />
          </RouteScopesControl>
        )}
        {userOverviewConfig?.contractType !== 'Contractor' && (
          <RouteScopesControl scopes={['insurance', 'pension']} context={scopesContext} path={[USER_BENEFITS_ROUTE]}>
            <UserBenefitsRouter userId={userId} pensionScopes={pensionScopes} handleUpdate={handleUpdate} />
          </RouteScopesControl>
        )}
        {userOverviewConfig?.contractType !== 'Contractor' && (
          <RouteScopesControl scopes={['payroll']} context={scopesContext} path={[USER_PAY_ITEMS_ROUTE]}>
            <UserPayItemsPage userId={userId} />
          </RouteScopesControl>
        )}
        <RouteScopesControl scopes={['expenses']} context={scopesContext} path={USER_EXPENSES_NEW_EXPENSE_ROUTE}>
          <NewExpensePage preselectedEmployee={userId} fromUserProfile={true} />
        </RouteScopesControl>

        <RouteScopesControl scopes={['expenses']} context={scopesContext} path={[USER_EXPENSES_ROUTE]}>
          <ExpensePage reach="me" userId={userId} />
        </RouteScopesControl>

        <RouteScopesControl scopes={['invoices']} context={scopesContext} path={USER_INVOICES_NEW_INVOICE_ROUTE}>
          <NewInvoicePage preselectedEmployee={userId} fromUserProfile={true} />
        </RouteScopesControl>

        <RouteScopesControl scopes={['invoices']} context={scopesContext} path={[USER_INVOICES_ROUTE]}>
          <InvoicesPage reach="me" userId={userId} />
        </RouteScopesControl>

        <RouteScopesControl
          scopes={DocumentsScopes.VIEW_USER_DOCUMENTS}
          context={scopesContext}
          path={ADMIN_USER_DOCUMENTS_ROUTE}
          exact
        >
          <UserProfileDocument userId={userId} userName={userOverviewConfig.name} />
        </RouteScopesControl>
        <RouteScopesControl scopes={USER_PROFILE_GROWTH_SCOPE} context={scopesContext} path={ADMIN_USER_REVIEWS_ROUTE}>
          <UserDetailsGrowthRouter />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={UserDetailsScopes.VIEW_USER_WORK}
          context={scopesContext}
          path={USER_WORK_TAB}
          exact
        >
          <UserWork userId={userId} onUpdate={handleUpdate} />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={UserDetailsScopes.VIEW_USER_PERSONAL}
          context={scopesContext}
          path={USER_PERSONAL_TAB}
          exact
        >
          <UserPersonal userId={userId} onUpdate={handleUpdate} />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={UserDetailsScopes.VIEW_USER_CONTACT}
          context={scopesContext}
          path={USER_CONTACT_TAB}
          exact
        >
          <UserContact userId={userId} onUpdate={handleUpdate} />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={UserDetailsScopes.VIEW_USER_COMPENSATION}
          context={scopesContext}
          path={USER_COMPENSATION_TAB}
          exact
        >
          <UserCompensation userId={userId} onUpdate={handleUpdate} />
        </RouteScopesControl>
        <RouteScopesControl exact scopes={AppScopes.VIEW_USER_APPS} context={scopesContext} path={USER_APPS_TAB}>
          <UserAppsPage />
        </RouteScopesControl>
        <Redirect from={USER_DETAILS_ROUTE} to={USER_DETAILS_PROFILE_ROUTE} />
      </Switch>
    </Fragment>
  );
};

const UserProfileLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.s2,
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <SkeletonLoader variant="circular" width="100px" height={100} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.s1,
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <SkeletonLoader variant="rectangular" width="130px" height={15} rowGap="5px" />
        <SkeletonLoader variant="rectangular" width="120px" height={15} rowGap="5px" />
        <SkeletonLoader variant="rectangular" width="100px" height={15} rowGap="5px" />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.s1,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <SkeletonLoader variant="rectangular" width="100%" height={30} rowGap="5px" />
      </div>
    </div>
  );
};
