import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';
import { NO_VAT_VALUE } from '@v2/feature/payments/expenses.util';
import { PaymentDto, TransactionType } from '@v2/feature/payments/payments.dto';
import { PaymentTypeSettings } from '@v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';

import { BasicUser } from '@/models/company-department.model';
import { Company } from '@/models/company.model';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';

export enum YapilyInstitutions {
  BarclaysBusiness = 'barclays_business',
  BarclaysCorporate = 'barclays_corporate',
  LloydsBusiness = 'lloydsbusiness',
  Natwest = 'natwest',
  NatwestBusiness = 'natwestbusiness',
  Revolut = 'revolut',
  SantanderUk = 'santander_uk',
  Starling = 'starling',
  AIBROIBusiness = 'aibbusiness',
  HSBCBusiness = 'hsbcbusiness_uk',
  HSBCNet = 'hsbccorporate',
  RBS = 'rbs',
  RBSBusiness = 'rbsbusiness',
  SiliconValleyBank = 'siliconvalleybank',
  Monzo = 'monzo_ob',
  Tide = 'tide',
  Wise = 'wise-live',
  OtherBanks = 'other-banks',

  // SANDBOXES
  ModeloSandbox = 'modelo-sandbox',
  AibGBSandbox = 'aibgb-sandbox',
  BankOfScotlandSandbox = 'bankofscotland-sandbox',
  BarclaysSandbox = 'barclays-sandbox',
  LloydsSandbox = 'lloyds-sandbox',
  NatwestSandbox = 'natwest-sandbox',
}

export interface TransactionPaymentRequest {
  type: TransactionType;
  payee: {
    name: string;
    accountIdentifications: {
      type: string;
      identification: string;
    }[];
  };
  amount: {
    amount: number;
    currency: string;
  };
  reference: string;
  payments?: TransactionPaymentRequest[];
  createdAt: Date;
  updatedAt: Date;
}

export type ExportCSVPaymentFormat = DefaultCSVPayment | RevolutCSVPayment | StarlingCSVPayment | WiseCSVPayment;

export interface DefaultCSVPayment {
  Beneficiary?: string;
  AccountName?: string;
  AccountNumber: string;
  SortCode: string;
  Amount: number;
  Currency: string;
  DueDate?: string;
  Reference: string;
}

export interface RevolutCSVPayment {
  Name: string;
  'Recipient type': string;
  'Account number': string;
  'Sort code': string;
  'Recipient bank country': string;
  Currency: string;
  Amount: number;
  'Payment reference': string;
}

export interface StarlingCSVPayment {
  'Sort Code': string;
  'Account Number': string;
  'First Name': string;
  'Last Name': string;
  'Business Name': string;
  Reference: string;
  'Amount (GBP)': number;
}

export interface WiseCSVPayment {
  name: string;
  recipientEmail: string;
  paymentReference: string;
  receiverType: string;
  amountCurrency: string;
  amount: number;
  sourceCurrency: string;
  targetCurrency: string;
  sortCode: string;
  accountNumber: string;
  IBAN: string;
}

export enum PayerType {
  'SinglePayer' = 'singlePayer',
  'PayerInEachPayment' = 'payerInEachPayment',
}

export type AddressFieldsRequirements =
  | 'addressLines'
  | 'townName'
  | 'country'
  | 'postCode'
  | 'buildingNumber'
  | 'streetName';

export enum ContractorInvoiceStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Voided = 'Voided',
  Draft = 'Draft',
}

export type ContractorInvoice = {
  id: string;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  createdBy: number;
  updatedBy: number;
  from: number;
  approvedOn: Date;
  approvedBy: number;
  companyId: number;
  status: ContractorInvoiceStatus;
  lineItems: Partial<ContractorInvoiceLineItem>[];
  isVatIncluded: boolean | null;
  vatNumber: string | null;
  taxRate: number | null;
  taxId: string | null;
  notes: string | null;
  totalAmount: number;
  taxAmount: number;
  amount: number | null;
  currency: CurrencyShort;
  createdAt: Date;
  updatedAt: Date;
  company: Company;
  externalId: string | null;
  createdByUser: BasicUser;
  updatedByUser: BasicUser;
  fromUser: BasicUser;
  approvedByUser?: BasicUser;
  payment?: PaymentDto;
  typeId: number;
  approver?: number[]; // generated column based on current setting
  type?: PaymentTypeSettings;
  approvalNotes: string | null;
};

export enum EntryTypeForPaymentTable {
  EXPENSE = 'Expense',
  INVOICE = 'Invoice',
  PAYMENT = 'Payment',
}

export const DEFAULT_CURRENCY = 'GBP';

export enum ExpenseStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Voided = 'Voided',
  Draft = 'Draft',
  All = 'All',
}

export interface ExpenseLineItem {
  readonly id: string;
  readonly expenseId: string;
  readonly typeId: number;
  accountingCode: number;
  accountingCodeDescription: string;
  readonly isTaxIncluded: boolean;
  gross: number;
  amount: number;
  readonly taxRate?: number | null;
  readonly externalLineItemId?: string | null;
  readonly type: PaymentTypeSettings;
}

export interface ContractorInvoiceLineItem {
  readonly id: string;
  readonly invoiceId: string;
  readonly typeId: number;
  accountingCode: number;
  accountingCodeDescription: string;
  readonly isTaxIncluded: boolean;
  gross: number;
  amount: number;
  readonly taxRate?: number | null;
  readonly externalLineItemId?: string | null;
  readonly type: PaymentTypeSettings;
}

export interface Expense {
  readonly id: string;
  readonly companyId: number;
  readonly createdBy: number;
  readonly updatedBy: number;
  readonly from: number;
  readonly typeId: number | null;
  readonly mappedType?: string | null;
  readonly approvedOnTimestamp: Date | null;
  readonly approverSteps: ApproverStep[];
  readonly approvedByIds: number[];
  readonly rejectedByIds: number[];
  readonly approvalNotes: string | null;
  readonly status: ExpenseStatus;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly date: string;
  readonly paymentId?: number | null;
  readonly amount: number;
  readonly isTaxIncluded: boolean;
  readonly taxRate: number | null;
  readonly taxAmount?: number;
  readonly gross: number;
  readonly currency: CurrencyShort;
  readonly externalId?: string | null;
  readonly notes?: string | null;
  readonly attachment: string | null;
  readonly createdByUser?: BasicUser;
  readonly updatedByUser?: BasicUser;
  readonly fromUser?: BasicUser;
  readonly preTaxAmount?: number | null;
  readonly customBenefitId: number | null;
  readonly userCustomBenefitId: number | null;
  readonly lineItems: Partial<ExpenseLineItem>[];
}

export type CreateExpense = Pick<
  Expense,
  | 'typeId'
  | 'from'
  | 'status'
  | 'date'
  | 'amount'
  | 'gross'
  | 'isTaxIncluded'
  | 'taxRate'
  | 'notes'
  | 'currency'
  | 'attachment'
  | 'preTaxAmount'
  | 'customBenefitId'
  | 'userCustomBenefitId'
  | 'lineItems'
> & {
  readonly approver?: number[];
};

export type EditExpense = CreateExpense & { id: string };

export type UpdateExpenseApprovalDto = { status: ExpenseStatus; approvalNotes: string | null };

export const NO_VAT_OPTION = 'No VAT';
export const REVERSE_CHARGE_OPTION = 'Reverse charge';

export const TaxRateOptions: OptionObj[] = [
  {
    label: NO_VAT_OPTION,
    value: NO_VAT_VALUE, // dummy value to default to no vat / null
  },
  {
    label: '20% (VAT on Expenses)',
    value: 20,
  },
  {
    label: '5% (VAT on Expenses)',
    value: 5,
  },
  {
    label: 'Reverse Charge Expenses (20%)',
    value: 0,
  },
];
