import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ChartRadar } from '@/v2/components/charts/chart-radar.component';
import { iconSize } from '@/v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewResultEndpoints } from '@/v2/feature/growth/reviews/api-client/review-result.api';
import {
  externalTooltipHandler,
  getReviewerTypesColor,
  hexToRGBA,
  TitleStatusComponent,
} from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewCycle, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { RadarChart, RadarChartByCategory } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { GrowthForbiddenAccess } from '@/v2/feature/growth/shared/components/growth-forbidden-access.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { capturePdf, capturePNG } from '@/v2/util/export-reports.util';

export const ReviewCycleDetailResultRadar = ({
  reviewCycle,
  cycleLoading,
  reachType,
}: {
  reviewCycle: Pick<ReviewCycle, 'state' | 'internalName' | 'displayName'>;
  cycleLoading: boolean;
  reachType: ReachType;
}) => {
  const params = useParams<{ cycleId: string; revieweeId: string }>();
  const { cycleId, revieweeId } = params;
  const { data: radarData, isLoading: radarDataLoading, error: radarError } = useApiClient(
    ReviewResultEndpoints.getRadarChartByReviewee(revieweeId, cycleId),
    {
      suspense: false,
    }
  );

  const reportNamePdf = useMemo(() => `${reviewCycle.internalName}-radar-pdf`, [reviewCycle.internalName]);
  const reportNamePng = useMemo(() => `${reviewCycle.internalName}-radar-png`, [reviewCycle.internalName]);

  const shouldShowEmptyState = useMemo(() => {
    return (
      Boolean(
        radarData?.radarChartByFactors?.categories?.length === 0 &&
          radarData?.radarChartByQuestionId?.categories?.length === 0
      ) || !radarData
    );
  }, [radarData]);

  if (!radarData && radarError && radarError.status === 403) {
    return (
      <div style={{ width: '100%', margin: spacing.s6 }}>
        <GrowthForbiddenAccess />
      </div>
    );
  }

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reachType} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      headerWidth="100%"
      loading={radarDataLoading || cycleLoading}
      buttons={[
        <StyledMenuComponent
          options={[
            {
              handler: () => capturePdf(reportNamePdf),
              label: 'PDF report',
            },
            {
              handler: () => capturePNG(reportNamePng),
              label: 'PNG report',
            },
          ]}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Export',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />,
      ]}
    >
      <SettingsSubsectionContent
        id={reportNamePdf}
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Radar chart',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <RadarChartByCategories
                    radarData={radarData}
                    shouldShowEmptyState={shouldShowEmptyState}
                    reportNamePng={reportNamePng}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </SettingsSectionContent>
  );
};

const RadarChartByCategories = ({
  radarData,
  shouldShowEmptyState,
  reportNamePng,
}: {
  radarData: RadarChartByCategory | null | undefined;
  shouldShowEmptyState: boolean;
  reportNamePng: string;
}) => {
  const [data, setData] = useState<RadarChart | undefined>(radarData?.radarChartByQuestionId);
  const [label, setLabel] = useState<string>('Questions');

  const getActions = () => {
    return [
      {
        handler: () => {
          setData(radarData?.radarChartByQuestionId);
          setLabel('Questions');
        },
        label: 'Questions',
        disabled: false,
      },
      {
        label: 'Factors',
        handler: () => {
          setData(radarData?.radarChartByFactors);
          setLabel('Factors');
        },
        disabled: false,
      },
    ];
  };

  const seriesData = useMemo(() => {
    const series = data?.series ?? [];
    return series.map((s) => {
      return {
        ...s,
        fill: true,
        borderColor: themeColors[getReviewerTypesColor()[s.label as ReviewerTypes]],
        backgroundColor: hexToRGBA(themeColors[getReviewerTypesColor()[s.label as ReviewerTypes]], 0.2),
        pointBackgroundColor: themeColors[getReviewerTypesColor()[s.label as ReviewerTypes]],
        pointHoverBackgroundColo: themeColors[getReviewerTypesColor()[s.label as ReviewerTypes]],
      };
    });
  }, [data?.series]);

  return !shouldShowEmptyState ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <div className="hide-for-capture">
        <StyledMenuComponent
          options={getActions()}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: label,
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
            style: { borderRadius: radius.br25 },
          }}
        />
      </div>

      <Box
        id={reportNamePng}
        sx={{
          width: '100%',
          height: '500px',
          border: borders.background,
          borderRadius: radius.br12,
          paddingY: spacing.p24,
        }}
      >
        <ChartRadar
          series={seriesData ?? []}
          categories={data?.categories ?? []}
          externalTooltipHandler={externalTooltipHandler}
          tooltipEnabled={false}
        />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: '100%',
        height: '500px',
        border: borders.background,
        borderRadius: radius.br12,
        paddingY: spacing.p24,
      }}
    >
      <ChartRadar series={[]} categories={[]} externalTooltipHandler={externalTooltipHandler} tooltipEnabled={false} />
    </Box>
  );
};
