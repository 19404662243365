import { FiltersEndpoints } from '@v2/feature/filters/filters.api';
import { TimeReportBalancesSection } from '@v2/feature/reports/features/update-report/time/time-report-balances-section.component';
import { TimeReportRequestsSection } from '@v2/feature/reports/features/update-report/time/time-report-requests-section.component';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { ReportTypeTimeSelection } from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

export const TimeReportSection = ({ report, loading }: { readonly report: ReportDto; readonly loading: boolean }) => {
  const { data: filters } = useApiClient(FiltersEndpoints.geTimeFiltersOfReports(), {
    suspense: false,
  });

  return report.config?.type === ReportTypeTimeSelection.Balances ? (
    <TimeReportBalancesSection filters={filters?.peopleFilters ?? {}} report={report} loading={loading} />
  ) : report.config?.type === ReportTypeTimeSelection.Requests ? (
    <TimeReportRequestsSection
      report={report}
      filters={filters ?? { peopleFilters: {}, timeFilters: {} }}
      loading={loading}
    />
  ) : null;
};
