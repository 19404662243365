import {
  APPS_COMPANY_OVERVIEW_ROUTE,
  APPS_PERSONAL_OVERVIEW_ROUTE,
  APPS_REQUESTS_ROUTE,
  APP_COMPANY_STORE_ROUTE,
  APP_STORE_ROUTE,
} from '@/lib/routes';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const TECH_APPS_OVERVIEW_ROUTES: RoleRoutes = {
  company: APPS_COMPANY_OVERVIEW_ROUTE,
  me: APPS_PERSONAL_OVERVIEW_ROUTE,
};

export const TECH_APPS_REQUEST_ROUTES: RoleRoutes = {
  company: APPS_REQUESTS_ROUTE,
};

export const TECH_APPS_STORE_ROUTES: RoleRoutes = {
  company: APP_COMPANY_STORE_ROUTE,
  me: APP_STORE_ROUTE,
};

export const TECH_APPS_OVERVIEW_SCOPES: RoleScopes = {
  company: ['apps:all'],
  me: ['apps'],
};

export const TECH_APPS_REQUEST_SCOPES: RoleScopes = {
  company: ['apps:all'],
};

export const TECH_APPS_STORE_SCOPES: RoleScopes = {
  company: ['apps:all'],
  me: ['apps'],
};
