import { useContext } from 'react';

import { PeopleDirectoryPage } from '@v2/feature/user/pages/people-directory.page';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  DASHBOARD_ROUTE,
  PEOPLE_ROUTE,
  PEOPLE_COMPANY_DIRECTORY_ROUTE,
  PEOPLE_TEAM_DIRECTORY_ROUTE,
  PEOPLE_ME_DIRECTORY_ROUTE,
} from '@/lib/routes';

export const UserDirectoryRouter = (): JSX.Element => {
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const user = state.user;

  return (
    <Switch>
      <RouteScopesControl
        exact
        scopes={['user:read:all']}
        context={getScopesContext(user)}
        path={[PEOPLE_COMPANY_DIRECTORY_ROUTE, PEOPLE_TEAM_DIRECTORY_ROUTE, PEOPLE_ME_DIRECTORY_ROUTE]}
      >
        <PeopleDirectoryPage />
      </RouteScopesControl>

      {hasScopes(['user:read:all']) ? <Redirect to={PEOPLE_ROUTE} /> : <Redirect to={DASHBOARD_ROUTE} />}
    </Switch>
  );
};
