import { BenefitsSettingsRouter } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings.router';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import useScopes from '@/hooks/scopes.hook';
import {
  SETTINGS_BENEFITS_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE,
  SETTINGS_MONEY_SALARY_ROUTE,
  SETTINGS_MONEY_INVOICES_ROUTE,
  SETTINGS_MONEY_EXPENSES_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE,
} from '@/lib/routes';
import { ScopeContext } from '@/models';
import { PaymentType } from '@/v2/feature/payments/payments.dto';
import { PayrollCompanySettingsPayrollList } from '@/v2/feature/payroll/features/payroll-settings/payroll-company-settings-payroll-list.page';
import { PayrollCompanySettingsRouter } from '@/v2/feature/payroll/features/payroll-settings/payroll-company-settings-payroll.router';
import { ExpenseTypeSettingsDetailsRouter } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/expense-type-settings-details.router';
import { InvoiceTypeSettingsDetailsRouter } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/invoice-type-settings-details.router';
import { PaymentsSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payments-settings.page';
import { PayrollContext } from '@/v2/feature/payroll/payroll-company.router';

export const MoneySettingsRouter = ({ context }: { context: ScopeContext }) => {
  const { refreshCompanyConfig } = useCompanyConfigState();
  const { hasScopes } = useScopes();
  const hasPayrollAll = hasScopes(['payroll:all']);
  const hasBenefitsAll = hasScopes(['pension:all', 'insurance:all']);

  return (
    <PayrollContext.Provider value={{ refreshPayrollState: () => refreshCompanyConfig() }}>
      <Switch>
        <RouteScopesControl
          scopes={['insurance:all', 'pension:all']}
          path={SETTINGS_BENEFITS_ROUTE}
          component={BenefitsSettingsRouter}
        />

        <RouteScopesControl
          scopes={['payments:all', 'expenses:all']}
          path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE}
          component={ExpenseTypeSettingsDetailsRouter}
        />

        <RouteScopesControl
          scopes={['payments:all', 'invoices:all']}
          path={SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE}
          component={InvoiceTypeSettingsDetailsRouter}
        />

        <RouteScopesControl
          scopes={['payroll:all']}
          path={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE}
          component={PayrollCompanySettingsRouter}
        />

        <RouteScopesControl context={context} scopes={['payroll:all']} path={SETTINGS_MONEY_SALARY_ROUTE} exact>
          <PayrollCompanySettingsPayrollList />
        </RouteScopesControl>

        <RouteScopesControl context={context} scopes={['invoices:all']} path={SETTINGS_MONEY_INVOICES_ROUTE} exact>
          <PaymentsSettingsPage type={PaymentType.Invoice} />
        </RouteScopesControl>

        <RouteScopesControl context={context} scopes={['expenses:all']} path={SETTINGS_MONEY_EXPENSES_ROUTE} exact>
          <PaymentsSettingsPage type={PaymentType.Expense} />
        </RouteScopesControl>

        {hasPayrollAll && <Redirect to={SETTINGS_MONEY_SALARY_ROUTE} />}

        {hasBenefitsAll && <Redirect to={SETTINGS_BENEFITS_ROUTE} />}
      </Switch>
    </PayrollContext.Provider>
  );
};
