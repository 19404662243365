import axios from 'axios';
import {
  CreateUserContract,
  UpdateUserContract,
  UserContract,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
import { UserContractDto } from '@/v2/feature/user/features/user-forms/user-contract/user-contract.dto';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ContractsAPI {
  static async findByUserId(userId: number): Promise<EntriesAndEffectiveRecord<UserContract>> {
    return (await axios.get(`/apiv2/users/${userId}/contracts`)).data;
  }

  static async createUserContract(userId: number, newUserContractEntry: CreateUserContract): Promise<UserContractDto> {
    return (await axios.post(`/apiv2/users/${userId}/contracts`, newUserContractEntry)).data;
  }

  static async updateById(userId: number, existingUserContractEntry: UpdateUserContract): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/contracts/${existingUserContractEntry.id}`, existingUserContractEntry);
  }

  static async deleteById(userId: number, contractId: number): Promise<void> {
    return axios.delete(`/apiv2/users/${userId}/contracts/${contractId}`);
  }

  static async getContractsByCompanyId(companyId: number): Promise<UserContractDto[]> {
    return (await axios.get(`/apiv2/superadmin/company/${companyId}/contracts`)).data;
  }

  static async bulkDeleteContractsByIds(companyId: number, contractIds: number[]): Promise<void> {
    await axios.delete(`/apiv2/superadmin/company/${companyId}/contracts`, { data: contractIds });
  }
}

export class UserContractEndpoints {
  static findCurrentByUserId(userId: number): Endpoint<UserContract | null> {
    return {
      url: `/apiv2/users/${userId}/contracts/current`,
    };
  }

  static getAllCurrent(): Endpoint<UserContract[]> {
    return { url: `/apiv2/users/contracts/current` };
  }
}
