import React, { useEffect, useState } from 'react';

import { Box, Card, Typography } from '@mui/material';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { getDeviceOwnerByDevicePossession } from '@v2/feature/device/device.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { keyBy } from 'lodash';

import { SiteAPI } from '@/api-client/site.api';
import PigeonMap, { MapMarker } from '@/component/widgets/PigeonMap';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

interface DevicesLocationPageProps {
  readonly devicesPossessions: readonly DevicePossessionDto[];
  readonly loading: boolean;
}

export const DevicesLocationPage = ({ devicesPossessions, loading }: DevicesLocationPageProps): JSX.Element => {
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [sitesById, setSitesById] = useState<{ [id: number]: SiteDto }>({});

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesById(keyBy(sites, 'id'));
      } catch (error) {
        showMessage(`Could not list sites. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage]);

  const inUseDevices = devicesPossessions.filter((devicePossession) => devicePossession.device?.lastLocation);
  const markers: MapMarker[] = inUseDevices
    .filter((devicePossession) => Boolean(devicePossession.device?.lastLocation))
    .map((devicePossession) => ({
      latitude: Number(devicePossession.device?.lastLocation?.latitude),
      longitude: Number(devicePossession.device?.lastLocation?.longitude),
      name: devicePossession.device?.lastLocation?.name,
      time: devicePossession.device?.lastLocation?.time ? devicePossession.device?.lastLocation?.time : null,
      owner: getDeviceOwnerByDevicePossession(
        devicePossession,
        sitesById[devicePossession.possessionId],
        getCachedUserById
      ),
    }));

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices locations</Typography>}
        showBack={true}
        backPath={DEVICES_COMPANY_OVERVIEW_ROUTE}
      />

      <ContentWrapper loading={loading}>
        <Card>
          <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, width: '100%', height: '75vh' }}>
            <PigeonMap markers={markers} zoomControl />
          </Box>
        </Card>
      </ContentWrapper>
    </RootStyle>
  );
};
