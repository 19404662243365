import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DeviceModelDto } from '@/v2/feature/device/device.dto';
import { getModelImage } from '@/v2/feature/device/device.util';
import {
  DeviceSettings,
  RequiredEnrolmentType,
} from '@/v2/feature/device/features/devices-settings/device-settings.interface';
import { DeviceSettingsAPI } from '@/v2/feature/device/features/devices-settings/devices-settings.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DevicesStoreSettingsPage = () => {
  const routerLocation = useLocation();
  const [deviceModels, setDeviceModels] = useState<readonly DeviceModelDto[]>([]);
  const [generalSettings, setGeneralSettings] = useState<DeviceSettings | undefined>(undefined);
  const [hiddenDevices, setHiddenDevices] = useState<number[] | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const getAllAvailableDevices = useCallback(async () => {
    try {
      setLoading(true);
      const [deviceModels, settings] = await Promise.all([
        DeviceAPI.getDeviceModels(),
        DeviceSettingsAPI.getCompanyDeviceSettings(),
      ]);
      setDeviceModels(deviceModels);
      setGeneralSettings(settings);
      setHiddenDevices(settings.hiddenDeviceModels ?? []);
    } catch (error) {
      showMessage(`Could not retrieve devices list. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    getAllAvailableDevices();
  }, [getAllAvailableDevices]);

  const columnData = useMemo<ColumnDef<DeviceModelDto, DeviceModelDto>[]>(() => {
    return [
      {
        header: () => 'Devices',
        accessorFn: (row) => row,
        id: 'modelName',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return (
            <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
              <Box>
                {deviceModel &&
                  getModelImage(deviceModel.type, deviceModel.modelName, {
                    width: '30px',
                    height: 'auto',
                  })}
              </Box>
              {deviceModel.modelName}
            </Box>
          );
        },
        size: 135,
      },
      {
        header: () => 'Model Number',
        accessorFn: (row) => row,
        id: 'modelNumber',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.modelNumber ? <Box>{deviceModel.modelNumber}</Box> : <EmptyCell />;
        },
        size: 100,
      },
      {
        header: () => 'Model Version',
        accessorFn: (row) => row,
        id: 'modelVersion',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.modelVersion ? <Box>{deviceModel.modelVersion}</Box> : <EmptyCell />;
        },
        size: 110,
      },
      {
        header: () => 'Manufacturer',
        accessorFn: (row) => row,
        id: 'manufacturer',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.manufacturer ? <Box>{deviceModel.manufacturer}</Box> : <EmptyCell />;
        },
        size: 110,
      },
      {
        header: () => 'Screen Size',
        accessorFn: (row) => row,
        id: 'screenSize',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.screenSize ? <Box>{deviceModel.screenSize}"</Box> : <EmptyCell />;
        },
        size: 75,
      },

      {
        header: () => 'RAM',
        accessorFn: (row) => row,
        id: 'ram',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.ram ? <Box>{deviceModel.ram}GB</Box> : <EmptyCell />;
        },
        size: 30,
      },
      {
        header: () => 'Storage',
        accessorFn: (row) => row,
        id: 'storage',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.storage ? <Box>{deviceModel.storage}GB</Box> : <EmptyCell />;
        },
        size: 50,
      },
      {
        header: () => 'CPU',
        accessorFn: (row) => row,
        id: 'cpuCores',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.cpuCores ? <Box>{deviceModel.cpuCores}-cores</Box> : <EmptyCell />;
        },
        size: 65,
      },
      {
        header: () => 'Hide',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();

          return (
            <SwitchComponent
              checked={hiddenDevices ? hiddenDevices?.some((d) => d === deviceModel.id) : false}
              name="show-device"
              onChange={async (_e, enabled) => {
                let finalArray = [];
                if (enabled) {
                  finalArray = [...(hiddenDevices ?? []), deviceModel.id];
                } else {
                  finalArray =
                    hiddenDevices && hiddenDevices?.length > 0 ? hiddenDevices.filter((d) => d !== deviceModel.id) : [];
                }
                const updateSettings = {
                  requiredEnrolment: generalSettings?.requiredEnrolment as RequiredEnrolmentType,
                  hiddenDeviceModels: finalArray,
                };
                setHiddenDevices(finalArray);
                await DeviceSettingsAPI.updateCompanyDeviceSettings(updateSettings);
              }}
            />
          );
        },
        size: 65,
      },
    ];
  }, [hiddenDevices, generalSettings?.requiredEnrolment]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Store</Typography>}
        showBack={routerLocation.pathname.includes('/settings')}
      />
      <ContentWrapper loading={false}>
        <BasicTable<DeviceModelDto> rowData={[...deviceModels]} columnData={columnData} loading={loading} />
      </ContentWrapper>
    </RootStyle>
  );
};
