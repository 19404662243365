import React, { Suspense, useMemo, useState } from 'react';

import { Box, Chip, FormLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { AutocompleteComponent } from '@v2/components/forms/autocomplete.component';
import { OptionObject } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { OptionObj } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CustomBenefitDto,
  CustomBenefitPaymentMethodType,
  UpdateCustomBenefitPaymentDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { isLoanBenefit, isRecurringBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { getExpenseTypeOptions } from '@v2/feature/payments/expenses.util';
import { PaymentTypeSettingsEndpoints } from '@v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import { CompanyEndpoints } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { PaymentCategoryEnum } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';

const getValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    paymentMethod: yup
      .string()
      .oneOf(Object.values(CustomBenefitPaymentMethodType), polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    paymentTypeIds: yup
      .array()
      .of(
        yup
          .number()
          .integer(polyglot.t('ValidationMessages.validValue'))
          .typeError(polyglot.t('ValidationMessages.validValue'))
      )
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required('ValidationMessages.requiredField')
      .when('paymentMethod', {
        is: (val: CustomBenefitPaymentMethodType) => val === CustomBenefitPaymentMethodType.Expenses,
        then: (schema) => schema.min(1, polyglot.t('ValidationMessages.requiredField')),
      }),
    payCode: yup.string().nullable().notRequired(),
    // .when('paymentMethod', {
    //   is: (val: CustomBenefitPaymentMethodType) => val === CustomBenefitPaymentMethodType.Payroll,
    //   then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    payCodeEmployer: yup.string().nullable().notRequired(),
    // .when('paymentMethod', {
    //   is: (val: CustomBenefitPaymentMethodType) => val === CustomBenefitPaymentMethodType.Payroll,
    //   then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitPaymentDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditCustomBenefitPaymentDrawerContent setIsOpen={setIsOpen} refresh={refresh} customBenefit={customBenefit} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitPaymentDrawerContent = ({ setIsOpen, refresh, customBenefit }: DrawerContentProps) => {
  const { data } = useApiClient(PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId());
  const { data: companyConfig } = useApiClient(CompanyEndpoints.getCompanyConfig());
  const hasCompanyPayroll = companyConfig?.inPayroll || companyConfig?.inGlobalPayroll;

  const expenseTypes = useMemo(() => {
    return data?.settings?.filter((type) => type.type === PaymentCategoryEnum.EXPENSE) ?? [];
  }, [data]);

  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const isLoan = isLoanBenefit(customBenefit.type);
  const isRecurring = isRecurringBenefit(customBenefit.type);

  const expenseTypeOptions: OptionObject[] = useMemo(() => getExpenseTypeOptions(expenseTypes ?? []), [expenseTypes]);

  const formik = useFormik<UpdateCustomBenefitPaymentDto>({
    initialValues: {
      paymentMethod: customBenefit.paymentMethod ?? CustomBenefitPaymentMethodType.Tracking,
      payCode: customBenefit.payCode,
      payCodeEmployer: customBenefit.payCodeEmployer,
      paymentTypeIds: customBenefit.paymentTypes?.map(({ id }) => id) ?? [],
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const update: UpdateCustomBenefitPaymentDto = {
          paymentMethod: values.paymentMethod,
          paymentTypeIds:
            values.paymentMethod === CustomBenefitPaymentMethodType.Expenses && values.paymentTypeIds
              ? values.paymentTypeIds
              : [],
          payCode: values.payCode,
          payCodeEmployer: values.payCodeEmployer,
        };
        await CustomBenefitAPI.updateCustomBenefitPayment(customBenefit.id, update);
        showMessage(polyglot.t('BenefitModule.benefitUpdated'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.editBenefit')}</Typography>

        <FormControl sx={{ width: '100%' }}>
          <FormLabel id="payment-method-group-label">
            <Typography variant="captionSmall">{polyglot.t('BenefitModule.paymentMethod')}</Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="payment-method-group-label"
            name="paymentMethod"
            onChange={(event) => {
              const value = event.target.value as CustomBenefitPaymentMethodType;
              formik.setFieldValue('paymentMethod', event.target.value as CustomBenefitPaymentMethodType);
              if (value !== CustomBenefitPaymentMethodType.Expenses) formik.setFieldValue('paymentTypeIds', []);
            }}
            sx={{ gap: '10px' }}
          >
            {!isLoan && (
              <FormControlLabel
                key="tracking"
                labelPlacement="end"
                value={CustomBenefitPaymentMethodType.Tracking}
                checked={formik.values.paymentMethod === CustomBenefitPaymentMethodType.Tracking}
                control={<StyledRadio />}
                label={
                  <Box>
                    <Typography variant="caption">Tracking only</Typography>
                    <Typography variant="captionSmall" color="Grey">
                      Manage benefits and members without payments
                    </Typography>
                  </Box>
                }
              />
            )}
            {!isLoan && !isRecurring && (
              <FormControlLabel
                key="expenses"
                labelPlacement="end"
                value={CustomBenefitPaymentMethodType.Expenses}
                checked={formik.values.paymentMethod === CustomBenefitPaymentMethodType.Expenses}
                control={<StyledRadio />}
                label={
                  <Box>
                    <Typography variant="caption">Expenses</Typography>
                    <Typography variant="captionSmall" color="Grey">
                      Allow to claim expenses against benefit allowance
                    </Typography>
                  </Box>
                }
              />
            )}
            <FormControlLabel
              key="payroll"
              labelPlacement="end"
              value={CustomBenefitPaymentMethodType.Payroll}
              checked={formik.values.paymentMethod === CustomBenefitPaymentMethodType.Payroll}
              control={<StyledRadio />}
              disabled={!hasCompanyPayroll}
              label={
                <Box>
                  <Typography variant="caption">
                    {hasCompanyPayroll ? 'Payroll' : 'Payroll (Complete set up first)'}
                  </Typography>
                  <Typography variant="captionSmall" color="Grey">
                    Pay or deduct contributions via payroll
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>

        {formik.values.paymentMethod === CustomBenefitPaymentMethodType.Expenses && (
          <Box>
            <Typography variant="title4">Expense settings</Typography>
            <Typography variant="caption" sx={{ my: '10px' }}>
              Select expense policy that should be used for this allowance.
            </Typography>

            <AutocompleteComponent
              multiple
              label={polyglot.t('BenefitModule.expenseType')}
              name="paymentTypeIds"
              filterSelectedOptions
              disableCloseOnSelect
              value={expenseTypeOptions.filter((o) =>
                (formik.values.paymentTypeIds as number[]).includes(o.value as number)
              )}
              options={expenseTypeOptions as { label: string; value: number }[]}
              onChange={(_, e) => {
                const value = e as OptionObj[];
                formik.setFieldValue(
                  'paymentTypeIds',
                  value.map((o) => o.value as number)
                );
              }}
              isOptionEqualToValue={(x, y) => x.value === y.value}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return <Chip label={option.label} {...getTagProps({ index })} />;
                })
              }
              error={!!formik.errors.paymentTypeIds && formik.touched.paymentTypeIds}
              helperText={formik.touched.paymentTypeIds && (formik.errors.paymentTypeIds as string)}
            />
          </Box>
        )}

        {formik.values.paymentMethod === CustomBenefitPaymentMethodType.Payroll && (
          <TextfieldComponent
            name="payCode"
            label={polyglot.t('PayItemModule.payCodeEmployee')}
            value={formik.values.payCode}
            onChange={formik.handleChange}
            error={formik.touched.payCode && !!formik.errors.payCode}
            helperText={(formik.touched.payCode && formik.errors.payCode) ?? ' '}
          />
        )}
        {formik.values.paymentMethod === CustomBenefitPaymentMethodType.Payroll && isRecurring && (
          <TextfieldComponent
            name="payCodeEmployer"
            label={polyglot.t('PayItemModule.payCodeEmployer')}
            value={formik.values.payCodeEmployer}
            onChange={formik.handleChange}
            error={formik.touched.payCodeEmployer && !!formik.errors.payCodeEmployer}
            helperText={(formik.touched.payCodeEmployer && formik.errors.payCodeEmployer) ?? ' '}
          />
        )}
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
