import { Box } from '@mui/material';
import { DeviceDto } from '@v2/feature/device/device.dto';

import PigeonMap, { MapMarker } from '@/component/widgets/PigeonMap';
import { Typography } from '@/v2/components/typography/typography.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';

interface Props {
  readonly device: DeviceDto;
  readonly assignedUser?: CachedUser;
}

export const LocationCard = ({ device, assignedUser }: Props): JSX.Element => {
  const getMarker = (device: DeviceDto): MapMarker | null => {
    const lastLocation = device?.lastLocation;
    return lastLocation
      ? {
          latitude: Number(lastLocation.latitude),
          longitude: Number(lastLocation.longitude),
          name: lastLocation.name,
          time: lastLocation.time,
          owner: assignedUser?.displayName,
        }
      : null;
  };

  return (
    <>
      {device?.lastLocation?.latitude &&
        device?.lastLocation.longitude &&
        !device?.lastLocation?.locationTrackingDisabled && (
          <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%', borderRadius: '15px', overflow: 'hidden' }}>
              <PigeonMap markers={[getMarker(device)!]} />
            </Box>
          </Box>
        )}
      {(!device?.lastLocation?.latitude || !device?.lastLocation.longitude) && (
        <>
          {device?.lastLocation?.locationTrackingDisabled && (
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              User disabled location tracking.
            </Typography>
          )}
          {!device?.lastLocation?.locationTrackingDisabled && (
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Location not available.
            </Typography>
          )}
        </>
      )}
    </>
  );
};
