import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { FullConfiguration } from 'swr/dist/types';

import { Action, GlobalStateActions } from '@/GlobalState';
import { DASHBOARD_ROUTE, LOGIN_2FA_ROUTE, LOGIN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthLoginDtoWithUserDetails, LoginResponseDto } from '@/v2/feature/auth/auth.dto';
import { PARTNER_API_PREFIX } from '@/v2/feature/auth/auth.interface';
import { SSOCheck } from '@/v2/feature/auth/features/auth-login/auth-login.page';
import { clearApiClientCache } from '@/v2/infrastructure/api-client/api-client.hook';
import {
  SignInFlow,
  signinWithAzureAD,
  signinWithGoogle,
  signinWithOkta,
} from '@/v2/infrastructure/firebase/identity-platform.util';
import { JUNE_ID_USER_PROPS } from '@/v2/infrastructure/june/june.hook';
import { equalsIgnoreCase } from '@/v2/util/string.util';

type UserCredentials = {
  username: string;
  password: string;
  deviceId?: string;
  mfaCode?: string;
};

const postSuccesfulLoginActions = async (
  loginResponse: AuthLoginDtoWithUserDetails,
  routerHistory: ReturnType<typeof useHistory>,
  routerLocation: ReturnType<typeof useLocation>,
  username: string,
  identifyUser: (props: JUNE_ID_USER_PROPS) => void,
  continueOnboarding?: boolean
) => {
  const queries = new URLSearchParams(routerLocation.search);
  if (queries.has('fromUri')) {
    const uri = queries.get('fromUri');
    // SECURITY: only allow paths to zelt
    if (uri && uri.search(/^\/[a-z]/) === 0) {
      window.location.href = uri;
      return;
    }
  } else if (queries.has('code')) {
    const params = routerLocation.search.split('&');
    const code = params[0];
    const appStub = params[1].replace('appStub=', '');

    if (appStub) {
      try {
        await AppIntegrationAPI.installApps([appStub]);
        // @ts-ignore
        window.location = AppIntegrationAPI.getHandleCodeUrl(appStub, code);
      } catch (e: any) {}
    }
  }

  const authMe = await AuthAPI.getAuthMe();
  identifyUser({ user: authMe.user, onAuth: true });
  if (continueOnboarding) {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId: authMe.user.userId }));
    return;
  }
  // Check for 'redirect' query parameter
  if (queries.has('redirect')) {
    const redirect = queries.get('redirect');
    const isPartnerAPIOAuthFlow = redirect?.includes(PARTNER_API_PREFIX);
    console.info('Handling redirect: ', redirect, isPartnerAPIOAuthFlow);
    if (redirect) {
      routerHistory.push(redirect);
      return;
    }
  }

  routerHistory.push(DASHBOARD_ROUTE);
};

export const performLogin = async (
  routerHistory: ReturnType<typeof useHistory>,
  routerLocation: ReturnType<typeof useLocation>,
  { username, password, mfaCode, deviceId }: UserCredentials,
  identifyUser: (props: JUNE_ID_USER_PROPS) => void,
  continueOnboarding?: boolean
) => {
  // DEBUG SSO from this point
  const loginResponse = await AuthAPI.login(username, password, mfaCode, deviceId);
  if ('mfaType' in loginResponse) {
    // we need a 2fa code to login
    routerHistory.push(LOGIN_2FA_ROUTE, {
      username,
      password,
      mfaType: loginResponse.mfaType,
    });
    return;
  }
  await postSuccesfulLoginActions(
    loginResponse,
    routerHistory,
    routerLocation,
    username,
    identifyUser,
    continueOnboarding
  );
};

export const performLogout = async (
  routerHistory: ReturnType<typeof useHistory>,
  dispatch: React.Dispatch<Action>,
  swrConfig: FullConfiguration
) => {
  clearApiClientCache(swrConfig);
  dispatch({
    type: GlobalStateActions.LOG_OUT,
    payload: true,
  });
  await AuthAPI.logout();
  routerHistory.push(LOGIN_ROUTE);
};

export const getSSOLoginResponse = async (
  userEmail: string,
  ssoCheckResult: SSOCheck,
  flow: SignInFlow = SignInFlow.Popup,
  deviceId?: string
): Promise<LoginResponseDto | undefined> => {
  let ssoResult;
  if (ssoCheckResult?.app === 'sso-google-workspace') {
    ssoResult = await signinWithGoogle(userEmail, ssoCheckResult, flow);
    if (
      userEmail &&
      ssoResult?.user.email &&
      equalsIgnoreCase(userEmail, ssoResult?.user?.email) &&
      ssoResult?.operationType === 'signIn' &&
      ssoResult?.user?.emailVerified === true
    ) {
      const idToken = await ssoResult.user.getIdToken();
      return await AuthAPI.ssoSamlLogin(ssoResult?.user?.email.toLocaleLowerCase(), idToken, deviceId);
    } else {
      throw new Error('Failed to authorize');
    }
    // for testing SSO, comment entire block above and uncomment below:
    // const idToken = 'test'; //await ssoResult.user.getIdToken();
    // return await AuthAPI.ssoSamlLogin('sandeep@zelt.app', idToken, 'TEST-iOSDevice-123'); //ssoResult?.user?.email, idToken);
  } else if (ssoCheckResult?.app === 'sso-okta') {
    ssoResult = await signinWithOkta(userEmail, ssoCheckResult, flow);
    console.debug(
      `ssoResult: emailFromLoginPage: ${userEmail}, emailFromSSOProvider: ${ssoResult?.user?.email}, emailVerified: ${ssoResult?.user?.emailVerified}, provider: ${ssoResult?.providerId}, operation: ${ssoResult?.operationType}`
    );
    if (
      userEmail &&
      ssoResult?.user.email &&
      equalsIgnoreCase(userEmail, ssoResult?.user?.email) &&
      ssoResult?.operationType === 'signIn' &&
      ssoResult?.user?.emailVerified === true
    ) {
      const idToken = await ssoResult.user.getIdToken();
      return await AuthAPI.ssoOidcLogin(ssoResult?.user?.email.toLocaleLowerCase(), idToken, deviceId);
    } else {
      throw new Error('Failed to authorize');
    }
  } else if (ssoCheckResult?.app === 'sso-azure-ad') {
    ssoResult = await signinWithAzureAD(userEmail, ssoCheckResult, flow);
    if (
      userEmail &&
      ssoResult?.user.email &&
      equalsIgnoreCase(userEmail, ssoResult?.user?.email) &&
      ssoResult?.operationType === 'signIn' &&
      ssoResult?.user?.emailVerified === true
    ) {
      const idToken = await ssoResult.user.getIdToken();
      return await AuthAPI.ssoSamlLogin(ssoResult?.user?.email.toLocaleLowerCase(), idToken, deviceId);
    } else {
    }
  } else {
    throw new Error(`SSO Provider not yet configured: ${JSON.stringify(ssoCheckResult)}`);
  }
};

export const performSSOLogin = async (
  userEmail: string,
  routerHistory: ReturnType<typeof useHistory>,
  routerLocation: ReturnType<typeof useLocation>,
  ssoCheckResult: SSOCheck,
  identifyUser: (props: JUNE_ID_USER_PROPS) => void,
  continueOnboarding?: boolean
) => {
  let loginResult = await getSSOLoginResponse(userEmail, ssoCheckResult);
  await postSuccesfulLoginActions(
    loginResult as AuthLoginDtoWithUserDetails,
    routerHistory,
    routerLocation,
    userEmail,
    identifyUser,
    continueOnboarding
  );
};
