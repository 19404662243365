import React, { useContext } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { QuestionType } from '@v2/feature/growth/reviews/interfaces/review-question.interface';
import { GridDisplayBarV2 } from '@v2/feature/growth/shared/components/grid-display-bar.component';
import {
  GrowthProgressChartPercentage,
  GrowthProgressLineChart,
} from '@v2/feature/growth/shared/components/growth-progress-chart.component';
import { QuestionCell } from '@v2/feature/growth/shared/components/question-cell.component';
import {
  SurveyImpactLabel,
  SurveyResultByQuestion,
  SurveyResultImpact,
} from '@v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { replaceParamsInQuestionText, stripHtml } from '@v2/util/string.util';
import { Dictionary } from 'lodash';
import Polyglot from 'node-polyglot';

import { GlobalContext } from '@/GlobalState';

const getGrowthChartLinePercentLabel = (
  item: SurveyResultByQuestion,
  measureByFilter: 'nps' | 'positive' | 'avg'
): string => {
  if (item.question.type === QuestionType.ScaleQuestion)
    return `${
      measureByFilter === 'avg'
        ? item.normPercent ?? 0
        : measureByFilter === 'positive'
        ? item.positivePercent ?? 0
        : item.npsPercent ?? 0
    }%`;

  if ([QuestionType.SingleAnswer, QuestionType.MultipleAnswer].includes(item.question.type)) return '-';

  return ' ';
};

const getGrowthChartLinePercentages = (
  item: SurveyResultByQuestion,
  measureByFilter: 'nps' | 'positive' | 'avg',
  polyglot: Polyglot
): GrowthProgressChartPercentage[] => {
  if (item.question.type === QuestionType.ScaleQuestion) {
    const percentages: GrowthProgressChartPercentage[] = [
      {
        value: measureByFilter === 'avg' ? item.normPercent ?? 0 : item.positivePercent ?? 0,
        color: measureByFilter === 'avg' ? 'blue' : 'green',
        showTooltip: measureByFilter !== 'avg',
        label: 'Positive sentiment',
      },
    ];

    if (measureByFilter !== 'avg' && item.negativePercent)
      percentages.push({
        value: item.negativePercent,
        color: 'red' as 'red',
        showTooltip: true,
        label: 'Negative sentiment',
      });
    return percentages;
  }

  if ([QuestionType.SingleAnswer, QuestionType.MultipleAnswer].includes(item.question.type)) {
    return (
      item.choicePercentages?.map((choiceData) => ({
        value: choiceData.percentage,
        color: 'blue',
        label: polyglot.t('SurveyResult.choiceLabelWithAnswerCount', {
          label: choiceData.choiceLabel,
          smart_count: choiceData.count,
        }),
        showTooltip: true,
      })) ?? []
    );
  }

  return [];
};

interface SurveyQuestionGridLineProps {
  readonly item: SurveyResultByQuestion;
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly setSelectedQuestion?: React.Dispatch<React.SetStateAction<SurveyResultByQuestion | null>>;
  readonly setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly impactResult: SurveyResultImpact | undefined;
  readonly impactFilter: string;
  readonly gridsXs: number[];
  readonly measureByFilter: 'avg' | 'nps' | 'positive';
}

export const SurveyQuestionGridLine = ({
  item,
  growthFactors,
  impactResult,
  impactFilter,
  measureByFilter,
  setIsOpen,
  setSelectedQuestion,
  gridsXs,
}: SurveyQuestionGridLineProps) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = user?.company?.name ?? 'Company';

  return (
    <GridDisplayBarV2
      key={item.question.id}
      onClick={
        setIsOpen && setSelectedQuestion
          ? () => {
              setSelectedQuestion(item);
              setIsOpen(true);
            }
          : undefined
      }
      cells={[
        {
          gridXs: gridsXs[0],
          content: (
            <QuestionCell
              text={replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name })}
              chips={[
                ...(item.question.factor
                  ? [
                      {
                        text: item.question.factor,
                        icon: growthFactors[item.question.factor]?.color ?? themeColors.Grey,
                      },
                    ]
                  : []),
                ...(item.question.scaleConfig?.type
                  ? [
                      {
                        text: item.question.scaleConfig?.type,
                      },
                    ]
                  : []),

                {
                  icon: 'smile',
                  text: item.noOfAnswers,
                  iconPosition: 'end',
                },
                {
                  icon: 'comment',
                  text: item.noOfComments,
                  iconPosition: 'end',
                },
              ]}
            />
          ),
        },
        ...(item.answersLowerThanThreshold
          ? [
              {
                gridXs: gridsXs[1],
                content: (
                  <Typography variant="caption" color="Grey">
                    Not sufficient data to show results.
                  </Typography>
                ),
              },
            ]
          : [
              {
                gridXs: gridsXs[1],
                content:
                  item.question.type === QuestionType.OpenEnded ? (
                    <Typography variant="caption" color="Grey">
                      Not available for 'Comment' question
                    </Typography>
                  ) : (
                    <GrowthProgressLineChart
                      label={getGrowthChartLinePercentLabel(item, measureByFilter)}
                      labelVariant="caption"
                      percentJustifyContent="space-between"
                      percentages={getGrowthChartLinePercentages(item, measureByFilter, polyglot)}
                    />
                  ),
              },
              {
                gridXs: gridsXs[2],
                content:
                  item.question.type === QuestionType.ScaleQuestion &&
                  impactResult &&
                  impactResult[item.question.id][impactFilter]?.label ? (
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <ImpactIcon surveyImpactLabel={impactResult[item.question.id][impactFilter]?.label} />
                      <Typography variant="caption">{impactResult[item.question.id][impactFilter]?.label}</Typography>
                    </Box>
                  ) : null,
              },
            ]),
      ]}
    />
  );
};

const getBoxColors = (surveyImpactLabel?: SurveyImpactLabel) => {
  switch (surveyImpactLabel) {
    case SurveyImpactLabel.High:
      return [themeColors.DarkGrey, themeColors.DarkGrey, themeColors.DarkGrey, themeColors.DarkGrey];
    case SurveyImpactLabel.Medium:
      return [themeColors.Grey, themeColors.DarkGrey, themeColors.DarkGrey, themeColors.DarkGrey];
    case SurveyImpactLabel.Low:
      return [themeColors.Grey, themeColors.Grey, themeColors.DarkGrey, themeColors.DarkGrey];
    case SurveyImpactLabel.None:
      return [themeColors.Grey, themeColors.Grey, themeColors.Grey, themeColors.DarkGrey];
    default:
      return [themeColors.Grey, themeColors.Grey, themeColors.Grey, themeColors.Grey];
  }
};

const ImpactIcon = ({ surveyImpactLabel }: { surveyImpactLabel: SurveyImpactLabel | undefined }) => {
  const boxColors = getBoxColors(surveyImpactLabel);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      {boxColors.map((color, index) => (
        <Box key={index} sx={{ bgcolor: color, width: '4px', height: '1px' }} />
      ))}
    </Box>
  );
};
