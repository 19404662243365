import React, { Dispatch, SetStateAction, Suspense, useCallback, useMemo, useState } from 'react';

import { Box, Button, Divider, IconButton } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI, AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto, UserAbsenceAllowancesDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, isHourlyPolicy } from '@v2/feature/absence/absence.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { getAllowancesUnits } from '@v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from '@v2/styles/buttons.styles';
import { iconButtonSx } from '@v2/styles/icon-button.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { round2Digits } from '@v2/util/number.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';

interface BalanceAdjustmentsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly refresh: (policyId: number | 'all') => Promise<void>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
  readonly afterClose?: () => void;
}

export const BalanceAdjustmentsDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  policyBreakdown,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  afterClose,
}: BalanceAdjustmentsDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <BalanceAdjustmentsDrawerContent
          userId={userId}
          policyBreakdown={policyBreakdown}
          absencePolicy={absencePolicy}
          refreshAllowancePage={refresh}
          isOnRegularSchedule={isOnRegularSchedule}
          currentAverageWorkDayLength={currentAverageWorkDayLength}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface BalanceAdjustmentsDrawerContentProps {
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly refreshAllowancePage: (policyId: number | 'all') => Promise<void>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
}

const BalanceAdjustmentsDrawerContent = ({
  userId,
  absencePolicy,
  refreshAllowancePage,
  policyBreakdown,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
}: BalanceAdjustmentsDrawerContentProps) => {
  const currentYear = useMemo(() => policyBreakdown.holidayYear, [policyBreakdown]);

  const { data: userAllowance, mutate: refreshAllowance } = useApiClient(
    AbsenceEndpoints.getUserAbsencePolicyAllowances(absencePolicy.id, userId, currentYear)
  );

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    await Promise.all([refreshAllowancePage(absencePolicy.id), refreshAllowance ? refreshAllowance() : undefined]);
  }, [absencePolicy.id, refreshAllowancePage, refreshAllowance]);

  const isHourly = isHourlyPolicy(absencePolicy);

  return (
    <Box sx={drawerContentSx}>
      {isEditMode ? (
        <EditMode
          userId={userId}
          effectiveYear={currentYear}
          setIsEditMode={setIsEditMode}
          refresh={refresh}
          absencePolicy={absencePolicy}
          isOnRegularSchedule={isOnRegularSchedule}
          currentAverageWorkDayLength={currentAverageWorkDayLength}
          isHourly={isHourly}
        />
      ) : (
        <ReadMode
          userId={userId}
          effectiveYear={currentYear}
          absencePolicy={absencePolicy}
          userAllowance={userAllowance ?? null}
          setIsEditMode={setIsEditMode}
          refresh={refresh}
          isOnRegularSchedule={isOnRegularSchedule}
          currentAverageWorkDayLength={currentAverageWorkDayLength}
          isHourly={isHourly}
        />
      )}
    </Box>
  );
};

const ReadMode = ({
  userId,
  absencePolicy,
  effectiveYear,
  userAllowance,
  setIsEditMode,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  isHourly,
}: {
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly effectiveYear: number;
  readonly userAllowance: UserAbsenceAllowancesDto | null;
  readonly setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
  readonly isHourly: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(['absence:manager'], { userId });

  const total = useMemo((): string => {
    const prefix = (userAllowance?.userOneOffAdjustment ?? 0) > 0 ? '+' : '';
    if (isOnRegularSchedule && !isHourly && currentAverageWorkDayLength) {
      const days = round2Digits((userAllowance?.userOneOffAdjustment ?? 0) / currentAverageWorkDayLength);
      return `${prefix}${days} day${days === 1 ? '' : 's'}`;
    }

    return `${prefix}${convertMinutesToClockHours(userAllowance?.userOneOffAdjustment ?? 0, polyglot)}`;
  }, [polyglot, userAllowance, isOnRegularSchedule, currentAverageWorkDayLength, isHourly]);

  const [showMessage] = useMessage();
  const deleteEntry = useCallback(
    async (entryId: string) => {
      try {
        await AbsenceAPI.deleteAbsencePolicyUserAllowanceOneOffAdjustmentEntry(
          userId,
          absencePolicy.id,
          effectiveYear,
          entryId
        );
        await refresh();
      } catch (error) {
        showMessage(polyglot.t('AllowanceDrawer.errors.badRequest', { nestError: nestErrorMessage(error) }), 'error');
      }
    },
    [polyglot, showMessage, userId, absencePolicy.id, effectiveYear, refresh]
  );

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('AllowanceDrawer.adjustments')}</Typography>

      {userAllowance?.oneOffAdjustmentEntries?.map((entry) => {
        const createdBy = entry.createdBy ? getCachedUserById(entry.createdBy) : null;
        const entryValue =
          isOnRegularSchedule && !isHourly && currentAverageWorkDayLength
            ? polyglot.t('AllowanceDrawer.noOfDays', {
                smart_count: round2Digits(entry.value / currentAverageWorkDayLength) ?? 0,
              })
            : convertMinutesToClockHours(entry.value, polyglot);

        return (
          <Box
            key={entry.id}
            sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: spacing.m5, mb: spacing.m20 }}
          >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: spacing.m5 }}>
              <Typography variant="title4">{polyglot.t('AllowanceDrawer.adjustment')}</Typography>
              <IconButton sx={iconButtonSx} onClick={async () => await deleteEntry(entry.id)}>
                <TrashIcon {...iconSize} />
              </IconButton>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">{polyglot.t('AllowanceDrawer.type')}</Typography>
              <Typography variant="title4">
                {entry.value >= 0 ? polyglot.t('AllowanceDrawer.addition') : polyglot.t('AllowanceDrawer.deduction')}
              </Typography>
            </Box>

            {createdBy && (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="caption">{polyglot.t('AllowanceDrawer.createdBy')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                  <UserAvatar userId={createdBy.userId} size="xxsmall" />
                  <Typography variant="title4">
                    {createdBy.displayName || `${createdBy.firstName} ${createdBy.lastName}`}
                  </Typography>
                </Box>
              </Box>
            )}

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">{polyglot.t('AllowanceDrawer.createdOn')}</Typography>
              <Typography variant="title4">{new Date(entry.createdAt).toLocaleDateString()}</Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">{polyglot.t('AllowanceDrawer.amount')}</Typography>
              <Typography variant="title4">{`${entry.value > 0 ? '+' : ''}${entryValue}`}</Typography>
            </Box>

            {entry.notes && (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
                <Typography variant="caption">{polyglot.t('AllowanceDrawer.note')}</Typography>
                <Typography variant="title4">{entry.notes}</Typography>
              </Box>
            )}

            <Divider sx={{ mb: spacing.m20 }} />
          </Box>
        );
      })}

      <Box key="total" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="caption">{polyglot.t('AllowanceDrawer.total')}</Typography>
        <Typography variant="title4">{total}</Typography>
      </Box>

      {isAdminOrManager && (
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent onClick={() => setIsEditMode(true)} sizeVariant="medium" colorVariant="secondary" fullWidth>
            {polyglot.t('AllowanceDrawer.add')}
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};

const EditMode = ({
  userId,
  absencePolicy,
  effectiveYear,
  setIsEditMode,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  isHourly,
}: {
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly effectiveYear: number;
  readonly setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
  readonly isHourly: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const updateOneOffAdjustment = useCallback(
    async (value: number, notes: string) => {
      setLoading(true);
      try {
        await AbsenceAPI.updateAbsencePolicyUserAllowanceOneOffAdjustment(userId, absencePolicy.id, {
          effectiveYear,
          value,
          notes,
        });
        await refresh();
        setIsEditMode(false);
      } catch (error) {
        showMessage(
          polyglot.t('AllowanceDrawer.errors.couldNotUpdateAllowance', {
            nestError: nestErrorMessage(error),
          }),
          'error'
        );
      }
      setLoading(false);
    },
    [polyglot, absencePolicy.id, userId, effectiveYear, refresh, showMessage, setIsEditMode]
  );

  const formik = useFormik<{
    value: number;
    notes: string;
    unit: 'day' | 'hour';
    adjustmentType: 'addition' | 'deduction';
  }>({
    initialValues: {
      unit: isOnRegularSchedule && !isHourly && currentAverageWorkDayLength ? 'day' : 'hour',
      value: 0,
      adjustmentType: 'addition',
      notes: '',
    },
    validationSchema: Yup.object({
      value: Yup.number()
        .min(0, polyglot.t('AllowanceDrawer.errors.inputValidValue'))
        .typeError(polyglot.t('AllowanceDrawer.errors.inputValidValue'))
        .required(polyglot.t('AllowanceDrawer.errors.validValueRequired')),
      notes: Yup.string().notRequired(),
      unit: Yup.string().oneOf(['hour', 'day']).required(polyglot.t('AllowanceDrawer.errors.validValueRequired')),
      adjustmentType: Yup.string()
        .oneOf(['addition', 'deduction'])
        .required(polyglot.t('AllowanceDrawer.errors.validValueRequired')),
    }),
    onSubmit: async ({
      value,
      notes,
      unit,
      adjustmentType,
    }: {
      value: number;
      notes: string;
      unit: 'hour' | 'day';
      adjustmentType: 'addition' | 'deduction';
    }) => {
      const signMultiplier = adjustmentType === 'addition' ? 1 : -1;
      const minutesMultiplier = unit === 'hour' || !currentAverageWorkDayLength ? 60 : currentAverageWorkDayLength;
      const adjustment = Math.round(Number(value) * minutesMultiplier * signMultiplier);

      await updateOneOffAdjustment(adjustment, notes);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('AllowanceDrawer.newAdjustment')}</Typography>

        <Box sx={{ width: '100%', display: 'flex', gap: spacing.g10 }}>
          <Button
            key="addition"
            sx={formik.values.adjustmentType === 'addition' ? activeTabsFilterBtnSx : tabsFilterBtnSx}
            onClick={() => formik.setFieldValue('adjustmentType', 'addition')}
            disableRipple
          >
            {polyglot.t('AllowanceDrawer.addition')}
          </Button>

          <Button
            key="deduction"
            sx={formik.values.adjustmentType === 'deduction' ? activeTabsFilterBtnSx : tabsFilterBtnSx}
            onClick={() => formik.setFieldValue('adjustmentType', 'deduction')}
            disableRipple
          >
            {polyglot.t('AllowanceDrawer.deduction')}
          </Button>
        </Box>

        <Box sx={{ display: 'flex', gap: spacing.g10 }}>
          <SelectComponent
            name="unit"
            label={polyglot.t('BalanceAdjustmentsDrawer.unit')}
            options={getAllowancesUnits(polyglot)}
            value={formik.values.unit}
            onChange={formik.handleChange}
            disabled={!isOnRegularSchedule || isHourly || !currentAverageWorkDayLength}
          />
          <TextfieldComponent
            label={polyglot.t('BalanceAdjustmentsDrawer.adjustment')}
            name="value"
            value={formik.values.value}
            onChange={(e) => {
              formik.setFieldValue('value', e.target.value);
            }}
            fullWidth
            size="small"
            endAdornment="none"
            error={formik.touched.value && !!formik.errors.value}
            helperText={(formik.touched.value && formik.errors.value) as string}
          />
        </Box>

        <TextfieldComponent
          name="notes"
          label={polyglot.t('AllowanceDrawer.note')}
          value={formik.values.notes}
          onChange={formik.handleChange}
          endAdornment="none"
          error={formik.touched.notes && !!formik.errors.notes}
          helperText={(formik.touched.notes && formik.errors.notes) as string}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
