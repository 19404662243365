import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { BankForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-form.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  defaultName: string;
  readonly refreshPayroll?: () => Promise<void>;
};

export const MissingBankAccount = ({ userId, defaultName, refreshPayroll }: Props) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Typography sx={themeFonts.caption}>{polyglot.t('PayrollMissing.bankAccount')}</Typography>
        <IconButton colorVariant="secondary" sizeVariant="small" onClick={() => setIsModalOpen(true)}>
          <Plus width="14px" height="14px" />
        </IconButton>
      </Box>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        <BankForm
          initialValues={null}
          userId={userId}
          defaultName={defaultName}
          getUserBankAccounts={() => Promise.resolve()}
          rowModalMode="add"
          handleSubmit={() => refreshPayroll?.()}
          setFormCurrentlyEditing={() => {}}
          setIsModalOpen={setIsModalOpen}
          loading={loading}
          setLoading={setLoading}
          onClose={() => setIsModalOpen(false)}
          showEmployee
        />
      </DrawerModal>
    </>
  );
};
