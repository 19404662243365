import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { FieldCard } from '@v2/components/field-card.component';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitRequestType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { isRecurringBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { BenefitOptOutDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-opt-out-drawer.component';
import { BenefitRequestViewDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-request-view-drawer.component';
import { UserBenefitOverviewTitle } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/user-benefit-overview-title.component';
import { UserCustomBenefitRequestDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit-request-drawer.component';
import {
  UserCustomBenefitDto,
  UserCustomBenefitRequestStatus,
} from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserCustomBenefitListTableComponent } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-list-table.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import useScopes from '@/hooks/scopes.hook';
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE, USER_BENEFITS_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { RootStyle } from '@/v2/styles/root.styles';

interface RecurringBenefitOverviewProps {
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly userId: number;
  readonly loading: boolean;
  readonly refresh: () => Promise<void>;
}

export const RecurringBenefitOverview = ({
  userId,
  userCustomBenefit,
  loading,
  refresh,
}: RecurringBenefitOverviewProps) => {
  const {
    data: userBenefitsRequests,
    mutate: refreshReq,
  } = useApiClient(
    CustomBenefitEndpoints.getUserCustomBenefitRequestsByBenefitId(
      userId,
      userCustomBenefit.customBenefitId,
      userCustomBenefit.id
    ),
    { suspense: false }
  );

  const { polyglot } = usePolyglot();
  const location = useLocation();
  const { hasScopes } = useScopes();
  const [isBenefitRequestOpen, setIsBenefitRequestOpen] = useState<boolean>(false);
  const [isOptOutOpen, setIsOptOutOpen] = useState<boolean>(false);

  const [viewRequest, setViewRequest] = useState<boolean>(false);
  const [viewOptOutRequest, setViewOptOutRequest] = useState<boolean>(false);

  const hasNoPendingChangeRequest = useMemo(() => {
    if (!userBenefitsRequests) return undefined;
    return (
      userBenefitsRequests.filter(
        (req) =>
          req.type === UserCustomBenefitRequestType.RecurringChange &&
          req.status === UserCustomBenefitRequestStatus.Pending
      ).length === 0
    );
  }, [userBenefitsRequests]);

  const hasNoPendingOptOutRequest = useMemo(() => {
    if (!userBenefitsRequests) return undefined;
    return (
      userBenefitsRequests.filter(
        (req) =>
          req.type === UserCustomBenefitRequestType.OptOut && req.status === UserCustomBenefitRequestStatus.Pending
      ).length === 0
    );
  }, [userBenefitsRequests]);

  const refreshRequests = useCallback(async () => {
    if (refreshReq) await refreshReq();
  }, [refreshReq]);

  const pendingChangeRequest = useMemo(
    () =>
      userBenefitsRequests?.find(
        (req) =>
          req.type === UserCustomBenefitRequestType.RecurringChange &&
          req.status === UserCustomBenefitRequestStatus.Pending
      ),
    [userBenefitsRequests]
  );

  const pendingOptOutRequest = useMemo(
    () =>
      userBenefitsRequests?.find(
        (req) =>
          req.type === UserCustomBenefitRequestType.OptOut && req.status === UserCustomBenefitRequestStatus.Pending
      ),
    [userBenefitsRequests]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<UserBenefitOverviewTitle userCustomBenefit={userCustomBenefit} />}
        showBack
        backPath={
          matchPath(location.pathname, USER_BENEFITS_ROUTE)
            ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId })
            : BENEFITS_ME_ROUTE
        }
        actions={
          <Box sx={{ display: 'flex', gap: '5px' }}>
            {hasNoPendingOptOutRequest && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => {
                  setIsOptOutOpen(true);
                }}
              >
                {polyglot.t('BenefitModule.optOut')}
              </ButtonComponent>
            )}
            {hasNoPendingChangeRequest && (
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="small"
                onClick={() => {
                  setIsBenefitRequestOpen(true);
                }}
              >
                {polyglot.t('BenefitModule.requestChange')}
              </ButtonComponent>
            )}
          </Box>
        }
        showAction={Boolean(
          hasScopes(['insurance'], { userId }) &&
            userCustomBenefit &&
            isRecurringBenefit(userCustomBenefit.customBenefit?.type)
        )}
      />
      <ContentWrapper loading={loading} sx={{ mt: '20px', mb: '10px' }}>
        <Box sx={{ width: '600px', display: 'flex', gap: '5px', alignItems: 'center', mb: '30px' }}>
          <FieldCard
            name={polyglot.t('BenefitModule.employerContribution')}
            value={formatMoney({
              amount: userCustomBenefit.employerContribution ?? 0,
              asDecimal: true,
            })}
            action={undefined}
            color="secondary"
          />
          <Typography variant="caption">+</Typography>

          <FieldCard
            name={polyglot.t('BenefitModule.employeeContribution')}
            value={formatMoney({
              amount: userCustomBenefit.employeeContribution ?? 0,
              asDecimal: true,
            })}
            color="warning"
            action={undefined}
          />

          <Typography variant="caption">=</Typography>

          <FieldCard
            name={polyglot.t('BenefitModule.totalValue')}
            value={formatMoney({
              amount: (userCustomBenefit.employerContribution ?? 0) + (userCustomBenefit.employeeContribution ?? 0),
              asDecimal: true,
            })}
            action={undefined}
            color="success"
          />
        </Box>

        {pendingOptOutRequest && (
          <Box sx={{ display: 'flex', mb: '20px' }}>
            <Typography variant="caption">
              {polyglot.t('BenefitModule.optOutRequested')}
              <span
                onClick={() => {
                  setViewOptOutRequest(true);
                }}
                style={{ marginLeft: '5px', cursor: 'pointer', color: themeColors.pressBlue }}
              >
                {polyglot.t('BenefitModule.seeMoreDetailsHere')}
              </span>
            </Typography>
          </Box>
        )}

        {pendingChangeRequest && (
          <Box sx={{ display: 'flex', mb: '20px' }}>
            <Typography variant="caption">
              {polyglot.t('BenefitModule.changeRequested')}.
              <span
                onClick={() => {
                  setViewRequest(true);
                }}
                style={{ cursor: 'pointer', color: themeColors.pressBlue }}
              >
                {polyglot.t('BenefitModule.seeMoreDetailsHere')}
              </span>
            </Typography>
          </Box>
        )}

        <UserCustomBenefitListTableComponent
          benefitId={userCustomBenefit.customBenefitId}
          userBenefit={userCustomBenefit}
          userId={userId}
        />
        <UserCustomBenefitRequestDrawer
          isOpen={isBenefitRequestOpen}
          setIsOpen={setIsBenefitRequestOpen}
          userBenefit={userCustomBenefit as UserCustomBenefitDto}
          onClose={() => {
            setIsBenefitRequestOpen(false);
          }}
          refresh={refreshRequests}
          type={UserCustomBenefitRequestType.RecurringChange}
        />

        {pendingChangeRequest && (
          <BenefitRequestViewDrawer
            isOpen={viewRequest}
            setIsOpen={setViewRequest}
            request={pendingChangeRequest}
            refresh={async () => {
              await Promise.all([refresh(), refreshRequests()]);
            }}
          />
        )}
        {pendingOptOutRequest && (
          <BenefitRequestViewDrawer
            isOpen={viewOptOutRequest}
            setIsOpen={setViewOptOutRequest}
            request={pendingOptOutRequest}
            refresh={async () => {
              await Promise.all([refresh(), refreshRequests()]);
            }}
          />
        )}

        <BenefitOptOutDrawer
          isOpen={isOptOutOpen}
          setIsOpen={setIsOptOutOpen}
          userId={userId}
          userBenefit={userCustomBenefit}
          refresh={async () => {
            await Promise.all([refreshRequests(), refresh()]);
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
