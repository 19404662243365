import axios from 'axios';

import { DeviceSettings } from '@/v2/feature/device/features/devices-settings/device-settings.interface';

export class DeviceSettingsAPI {
  static async getCompanyDeviceSettings(): Promise<DeviceSettings> {
    return (await axios.get('/apiv2/device-settings')).data;
  }

  static async updateCompanyDeviceSettings(
    updatedSettings: Pick<DeviceSettings, 'requiredEnrolment' | 'hiddenDeviceModels'>
  ): Promise<void> {
    return (await axios.patch('/apiv2/device-settings', updatedSettings)).data;
  }
}
