export interface UnSanitizedFaq {
  Question: string;
  Answer: string;
  Video?: { url: string };
  Media: { data: Array<StrapiMedia> };
  tags: { data: Array<string> };
}

export interface UnSanitizedGuide {
  Title: string;
  Description: string;
  Step: Array<Step>;
  tags: Array<string>;
}
export interface Step {
  Heading: string;
  Description: string;
  Screenshot: { data: Array<StrapiMedia> };
}
export interface StrapiMedia {
  id: string;
  attributes: { url: string };
}

export interface StrapiFaqData {
  attributes: UnSanitizedFaq;
  id: number;
}

export interface StrapiGuideData {
  attributes: UnSanitizedGuide;
  id: number;
}

export interface PaginationObject {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}
export interface PaginationInterface {
  pagination: PaginationObject;
}

export const SupportDomainList = [
  'Tasks',
  'Settings',
  'Reviews',
  'Reports',
  'Pension',
  'Salary',
  'Payments',
  'Onboarding',
  'Offboarding',
  'Documents',
  'Profile',
  'Devices',
  'Calendar',
  'Benefits',
  'Attendance',
  'Apps',
  'Absence',
];
