import { yupToFormErrors } from 'formik';
import * as yup from 'yup';

import { SchemaField } from '@/v2/infrastructure/forms/remote/schema-field-definitions.interface';

type YupObject = yup.ValidationError | undefined;

type HandleValidationFn = (values: Object) => YupObject;

export type ParserFields = {
  fields: SchemaField[];
  validationSchema: () => void;
  handleValidation: HandleValidationFn;
};

export function getFormikValidationHandler(handleValidation: HandleValidationFn) {
  return (values: Object) => {
    // Transform JSON Schema errors into Formik errors.
    const yupErrors = handleValidation(values);
    if (yupErrors) {
      const errors = yupToFormErrors(yupErrors);
      return errors;
    }
  };
}

type SchemaValue = number | string | boolean | unknown[] | Record<string, unknown>;

export type SchemaValues = Record<string, SchemaValue>;

/**
 * Converts a list of SchemaFields into the object structure used by formik
 * @param fields
 * @returns
 */
export function mapSchemaFieldsToFormikObject(fields: SchemaField[]): SchemaValues {
  function getInitialFieldValue(schema: any) {
    if (schema.type === 'object') {
      return getInitialObjectValue(schema.fields);
    }
    return (
      {
        number: 0,
        string: '',
        boolean: false,
        array: [] as unknown[],
      }[schema.type as string] ?? ''
    );
  }
  function getInitialObjectValue(fields: Record<string, string>) {
    const obj: Record<string, unknown> = {};
    for (const f in fields) {
      obj[f] = getInitialFieldValue(fields[f]);
    }
    return obj;
  }
  const values = fields.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.name]: getInitialFieldValue(curr.schema),
    };
  }, {} as SchemaValues);

  return values;
}
