import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendancePenaltyRuleCell } from '@v2/feature/attendance/settings/components/attendance-penalty-rule-cell.component';
import { AttendancePenaltyAPI } from '@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.api';
import { AttendancePenaltyDto } from '@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly schedule: AttendanceScheduleDto;
  readonly allPenalties: AttendancePenaltyDto[];
  readonly refresh: () => Promise<void>;
}

export const AttendancePayrollPenaltiesDrawer = ({
  isOpen,
  setIsOpen,
  schedule,
  allPenalties,
  refresh,
}: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    if (!isOpen) return;
    setSelected(allPenalties.filter((r) => r.enabled).map((r) => r.id));
  }, [isOpen, allPenalties]);

  const [isUpdating, setIsUpdating] = useState(false);

  const enableAndDisable = useCallback(async () => {
    try {
      setIsUpdating(true);
      const toEnable = selected;
      const toDisable = allPenalties.filter((r) => !selected.includes(r.id)).map((r) => r.id);
      await AttendancePenaltyAPI.enableAndDisableAttendancePenaltyRules(schedule.id, toEnable, toDisable);

      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsUpdating(false);
    }
  }, [setIsOpen, schedule, selected, allPenalties, showMessage, polyglot, refresh]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} fixedWidthPx={465}>
      <Box>
        <Typography variant="title2" sx={{ mb: '20px' }}>
          {polyglot.t('AttendanceModule.penalties')}
        </Typography>

        {allPenalties.map((p) => {
          const isSelected = selected.includes(p.id);
          return (
            <Box
              key={p.id}
              sx={{
                px: '5px',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                mb: '5px',
                ':hover': { bgcolor: themeColors.Background, borderRadius: '10px' },
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelected((prev) => (prev.includes(p.id) ? prev.filter((id) => id !== p.id) : [...prev, p.id]));
              }}
            >
              <Box>
                <CheckboxComponent
                  label={undefined}
                  name="allSelected"
                  checked={isSelected}
                  value="allSelected"
                  // onChange={() => {
                  //   setSelected((prev) => (prev.includes(p.id) ? prev.filter((id) => id !== p.id) : [...prev, p.id]));
                  // }}
                />
              </Box>
              <Box>
                <Typography variant="caption">{p.name}</Typography>

                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <AttendancePenaltyRuleCell width="90px" percent={p.multiplier1} variant="captionSmall" color="Grey" />
                  <AttendancePenaltyRuleCell width="90px" percent={p.multiplier2} variant="captionSmall" color="Grey" />
                  <AttendancePenaltyRuleCell width="90px" percent={p.multiplier3} variant="captionSmall" color="Grey" />
                  <AttendancePenaltyRuleCell width="90px" percent={p.multiplier4} variant="captionSmall" color="Grey" />
                </Box>
              </Box>
            </Box>
          );
        })}

        <Box sx={{ display: 'flex', gap: '10px', mt: '40px' }}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            fullWidth
            type="button"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.update')}
            loading={isUpdating}
            onClick={enableAndDisable}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
