import { useState } from 'react';

import { FormControl, FormHelperText, SxProps } from '@mui/material';
import OTPInput from 'react-otp-input';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import './auth-mfs-code.css';

/**
 * Single MFA code entry box
 */

interface MFACodeEntryProps {
  onCodeComplete(value: string): void;
  codeLength?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  sx?: SxProps;
}

/**
 * Set of MFA code entry boxes
 */
export const MFACodeEntry = ({
  codeLength = 6,
  disabled,
  error,
  helperText,
  onCodeComplete,
  sx,
}: MFACodeEntryProps): JSX.Element => {
  const [otp, setOtp] = useState('');

  return (
    <FormControl sx={{ width: '100%', margin: 0, ...sx }} error={error}>
      <OTPInput
        value={otp}
        onChange={(val) => {
          onCodeComplete(val);
          setOtp(val);
        }}
        numInputs={codeLength}
        renderInput={(props) => <input {...props} disabled={disabled} className="otp-style" />}
        containerStyle={{ display: 'flex', justifyContent: 'space-around', gap: spacing.g10 }}
        inputStyle={{
          border: 'none',
          borderBottom: `1px solid ${themeColors.DarkGrey}`,
          ...themeFonts.title2,
          color: themeColors.DarkGrey,
        }}
      />

      {error && helperText && (
        <FormHelperText sx={{ margin: 0, padding: 0 }} id="component-error-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
