import { Theme } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { SwipeableDrawer, SwipeableDrawerProps, SxProps } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';

export const StyledSwipeableDrawer: StyledComponent<SwipeableDrawerProps & SxProps<Theme>, {}, {}> = styled(
  SwipeableDrawer
)(() => ({
  '& div.MuiBackdrop-root': {
    background: themeColors.transparency,
  },
  '& div.MuiPaper-root.MuiPaper-elevation': {
    boxShadow: 'none',
  },
}));

export const ProfileStyledSwipeableDrawer: StyledComponent<SwipeableDrawerProps & SxProps<Theme>, {}, {}> = styled(
  SwipeableDrawer
)(() => ({
  '& div.MuiBackdrop-root': {
    background: 'none',
  },
  '& div.MuiPaper-root.MuiPaper-elevation': {
    boxShadow: '0px 4px 120px 0px #00000026',
  },
}));
