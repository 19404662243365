import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import AttendanceEmpty from '@/images/attendance/AttendanceEmpty.png';
import { Typography } from '@/v2/components/typography/typography.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const AttendanceEmptyState = () => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={spacing.px40}>
      <Box sx={spacing.pt20}>
        <img src={AttendanceEmpty} width={140} height={140} alt="attendance-empty" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5, mt: spacing.m30 }}>
        <Typography variant="title2">{polyglot.t('AttendanceEmptyState.noTimeSheets')}</Typography>
        <Typography variant="caption">{polyglot.t('AttendanceEmptyState.nothingToShow')} </Typography>
      </Box>
    </Box>
  );
};
