import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import { Box } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { iconSize } from './document-bulk-upload-flow.component';
import { DocumentSettingsNewTypeForm } from './document-settings-new-type-drawer.component';
import { DocumentEndpoints } from '../document.api';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as InfoGrey } from '@/images/side-bar-icons/InfoGrey.svg';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export interface DocumentBulkEditValues {
  documentType: DocumentType | null;
  hideFromEmployee: boolean;
  privateDocument: boolean;
}

interface DocumentConfirmBulkEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onBulkEdit: (updatedValues: DocumentBulkEditValues) => Promise<void>;
  readonly drawerTitle: string;
  readonly confirmationText: string;
  readonly documentCountForEdit: number;
}

interface DocumentConfirmBulkEditDrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onBulkEdit: (updatedValues: DocumentBulkEditValues) => Promise<void>;
  readonly drawerTitle: string;
  readonly confirmationText: string;
}

export const DocumentConfirmBulkEditDrawer = ({
  isOpen,
  setIsOpen,
  onBulkEdit,
  drawerTitle,
  confirmationText,
}: DocumentConfirmBulkEditDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <DocumentConfirmBulkEditDrawerContent
        onBulkEdit={onBulkEdit}
        setIsOpen={setIsOpen}
        drawerTitle={drawerTitle}
        confirmationText={confirmationText}
      />
    </DrawerModal>
  );
};

export const DocumentConfirmBulkEditDrawerContent = ({
  onBulkEdit,
  setIsOpen,
  drawerTitle,
  confirmationText,
}: DocumentConfirmBulkEditDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const { data: allTypes, mutate: refreshAllTypes } = useApiClient(DocumentEndpoints.getTypesData(), {
    suspense: false,
  });
  const [formState, setFormState] = useState<DocumentBulkEditValues>({
    documentType: null,
    hideFromEmployee: false,
    privateDocument: false,
  });

  const onActionConfirmClick = useCallback(async () => {
    setLoading(true);
    await onBulkEdit(formState);
    setIsOpen(false);
    setLoading(false);
  }, [formState, onBulkEdit, setIsOpen]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{drawerTitle}</Typography>
      <Typography variant="caption">{confirmationText}</Typography>

      <AutocompleteComponent
        name="documentType"
        label={polyglot.t('DocumentFormModal.documentType')}
        options={allTypes ?? []}
        value={formState.documentType}
        // @ts-ignore
        onChange={(_, x: DocumentType) => {
          setFormState({ ...formState, documentType: x });
        }}
        compareValue={formState.documentType ?? ''}
        editList={{
          handler: () => setNewTypeDrawerIsOpen(true),
          isHidden: !hasScopes(['documents:all'], { userId: user.userId }),
        }}
      />
      <CheckboxComponent
        name="visibilitySettings"
        label={polyglot.t('DocumentFormModal.visibilitySettings')}
        checked={formState.hideFromEmployee ?? false}
        onChange={(e) => {
          const target = e.target as HTMLInputElement;
          setFormState({ ...formState, hideFromEmployee: target.checked });
        }}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <CheckboxComponent
          name="privateDocument"
          label={polyglot.t('DocumentFormModal.privateDocument')}
          checked={formState?.privateDocument ?? false}
          onChange={(e) => {
            const target = e.target as HTMLInputElement;
            setFormState({ ...formState, privateDocument: target.checked });
          }}
        />
        <StyledTooltip title={polyglot.t('DocumentFormModal.privateHelperText')}>
          <InfoGrey {...iconSize} />
        </StyledTooltip>
      </Box>
      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={() => setIsOpen(false)}
          disabled={loading}
          fullWidth
        >
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.update')}
          disabled={loading}
          loading={loading}
          onClick={onActionConfirmClick}
          type="button"
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
        />
      </Box>
      <DrawerModal isOpen={newTypeDrawerIsOpen} setIsOpen={setNewTypeDrawerIsOpen}>
        <DocumentSettingsNewTypeForm
          initialValues={undefined}
          onClose={() => {
            setNewTypeDrawerIsOpen(false);
          }}
          refreshDocumentTypes={() => refreshAllTypes?.()}
          mode="create"
        />
      </DrawerModal>
    </Box>
  );
};
