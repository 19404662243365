import { Suspense, useContext, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { themeFonts } from '@v2/styles/fonts.styles';
import { useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { AboutDevice } from '@/v2/feature/device/features/order-device/components/about-device.component';
import { ProtectDevice } from '@/v2/feature/device/features/order-device/components/protect-device.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { ThemeSlider } from '@/v2/styles/slider.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface EnrollExistingDeviceProps {
  readonly initialDevicePossession?: DevicePossessionDto | null;
  readonly refreshAppData: () => Promise<void>;
}

export const EnrollExistingDevice = ({
  initialDevicePossession = null,
  refreshAppData,
}: EnrollExistingDeviceProps): JSX.Element => {
  const routerLocation = useLocation();
  const [globalState] = useContext(GlobalContext);

  const userId = routerLocation.pathname.includes('me') ? globalState.user.userId : undefined;
  const [progress, setProgress] = useState<number>(50);
  const [devicePossession, setDevicePossession] = useState<DevicePossessionDto | null>(initialDevicePossession);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Add existing</Typography>}
        onBack={refreshAppData}
      />

      <ContentWrapper loading={false}>
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="90%"
              height="90vh"
              sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
            />
          }
        >
          <Box sx={{ width: '100%', ...spacing.mb20 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={progress > 0 ? { ...themeFonts.title4 } : { ...themeFonts.caption, color: themeColors.Grey }}
              >
                Details
              </Typography>
              <Typography
                sx={progress >= 50 ? { ...themeFonts.title4 } : { ...themeFonts.caption, color: themeColors.Grey }}
              >
                Protect
              </Typography>
              <Typography
                sx={progress >= 100 ? { ...themeFonts.title4 } : { ...themeFonts.caption, color: themeColors.Grey }}
              >
                Sync
              </Typography>
            </Box>
            <ThemeSlider
              defaultValue={progress}
              value={progress}
              aria-label="Small"
              marks={[{ value: 0 }, { value: 50 }, { value: 100 }]}
            />
          </Box>
          {progress === 0 && (
            <AboutDevice setProgress={setProgress} setDevicePossession={setDevicePossession} userId={userId} />
          )}
          {progress >= 50 && devicePossession && (
            <ProtectDevice setProgress={setProgress} devicePossession={devicePossession} />
          )}
        </Suspense>
      </ContentWrapper>
    </RootStyle>
  );
};
