import { SlackAPI } from '@/api-client/slack.api';
import { useCallback, useEffect, useState } from 'react';

export const useSlackHook = (): { slackChannels: readonly { id: string; name: string }[]; isLoading: boolean } => {
  const [slackChannels, setSlackChannels] = useState<{ id: string; name: string }[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchChannels = useCallback(async () => {
    try {
      await SlackAPI.importChannels();
      const slackChannels = await SlackAPI.getSlackChannels();
      setSlackChannels(slackChannels);
    } catch (error) {
      console.error('Could not fetch slack channels.', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  return { slackChannels, isLoading };
};
