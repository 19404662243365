import { ReactNode } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface IconContentActionCardProps {
  title: string;
  content: string | null;
  showDivider?: boolean;
  iconMedia?: JSX.Element | ReactNode;
  action?: JSX.Element | null;
  titleSx?: SxProps<Theme>;
  iconGap?: React.CSSProperties['gap'];
  textGap?: React.CSSProperties['gap'];
  cardOnClick?: () => void;
  sx?: SxProps<Theme>;
}

export const IconContentRadioCard = ({
  title,
  content,
  showDivider = false,
  iconMedia,
  action,
  titleSx,
  iconGap,
  textGap,
  cardOnClick,
  sx,
}: IconContentActionCardProps) => {
  return (
    <Box
      onClick={cardOnClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: spacing.g20,
        width: '100%',
        justifyContent: 'space-between',
        borderBottom: showDivider ? borders.middle : 'none',
        cursor: 'pointer',
        ...sx,
        ...spacing.py20,
      }}
    >
      <Box sx={{ display: 'flex', gap: iconGap ?? spacing.g15 }}>
        {/* image */}
        <Box>{!!iconMedia && iconMedia}</Box>

        {/* title + content */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: textGap ?? spacing.g5, justifyContent: 'center' }}>
          <Typography sx={{ ...themeFonts.caption, ...titleSx }}>{title}</Typography>
          {content && <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>{content}</Typography>}
        </Box>
      </Box>

      {/* action */}
      {!!action && <Box sx={{ display: 'flex' }}>{action}</Box>}
    </Box>
  );
};
