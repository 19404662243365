import { AttendanceDto, AttendanceEntryDto, AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import { LocalDate } from '@v2/util/local-date';
import { differenceInMinutes } from 'date-fns';

import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import { getTimeFromDateString } from '@/v2/feature/attendance/attendance.util';
import { ColoursByType, SlotEventsProps } from '@/v2/feature/attendance/components/week-calendar.component';
import { themeColors } from '@/v2/styles/colors.styles';

export function getISOWeek(logDate: Date): number {
  const date = new Date(logDate);
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86_400_000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
}

function getStartOfWeek(date: Date): string {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new LocalDate(date.setDate(diff)).toDateString();
}

function getEndOfWeek(date: Date): string {
  const startOfWeek: Date = new LocalDate(getStartOfWeek(date)).getDate();
  return new LocalDate(startOfWeek.setDate(startOfWeek.getDate() + 6)).toDateString();
}

export interface Week {
  weekNo: number;
  startOfWeek: string;
  endOfWeek: string;
}

export function getWeeksByYear(year: number): Week[] {
  const weeks = [];
  const date = new Date(year, 0, 1);

  while (date.getFullYear() === year) {
    const weekNo = getISOWeek(date);
    const startOfWeek = getStartOfWeek(date);
    const endOfWeek = getEndOfWeek(date);
    weeks.push({ weekNo, startOfWeek, endOfWeek });

    date.setDate(date.getDate() + 7);
  }

  return weeks;
}

export function hasEntriesInPreviousWeek(
  userId: number,
  requests: readonly AttendanceDto[] | undefined,
  currentWeekNo: number,
  currentWeekYear: number
): boolean {
  const previousWeek = currentWeekNo === 1 ? 52 : currentWeekNo - 1;
  const previousWeekYear = currentWeekNo === 1 ? currentWeekYear - 1 : currentWeekYear;
  return (
    requests?.some((r) => r.userId === userId && r.weekNo === previousWeek && r.year === previousWeekYear) ?? false
  );
}

const calculateMidSlot = (start: string, end: string) => {
  const startTime = start.slice(0, 2) + ':' + start.slice(2);
  const endTime = end.slice(0, 2) + ':' + end.slice(2);

  const startDate = new Date('2000/01/01 ' + startTime);
  const endDate = new Date('2000/01/01 ' + endTime);

  const midTime = new Date((startDate.getTime() + endDate.getTime()) / 2);
  const midHours = midTime.getHours().toString().padStart(2, '0');
  const midMinutes = midTime.getMinutes().toString().padStart(2, '0');

  const midSlot = `${midHours}:${midMinutes}`;

  return midSlot;
};

export const getScheduledEntries = (slotFrom: string, slotTo: string, i: number, events: SlotEventsProps[]) => {
  const from = slotFrom.split(':');
  const to = slotTo.split(':');
  const slotStartHour = parseInt(from[0]);
  const slotEndHour = parseInt(to[0]);
  const slotStartMinutes = parseInt(from[1]);
  const slotEndMinutes = parseInt(to[1]);

  // Check if the slot covers the current half hour
  const isStartHalfHour = slotStartMinutes > 0 && i === slotStartHour;
  const isEndHalfHour = slotEndMinutes > 0;
  const endHour = isEndHalfHour ? slotEndHour : slotEndHour - 1;

  const isSlotHighlighted = i >= slotStartHour && i < endHour;
  const isSlotStart = i === slotStartHour;
  const isSlotEnd = i === endHour;
  const isSlotStartHalf = isStartHalfHour && i === slotStartHour;

  return events.push({
    label: isSlotStart ? `${slotFrom}-${slotTo}` : '',
    backgroundColor: 'transparent',
    hasAttendanceEntries: false,
    isScheduled: isSlotHighlighted || isSlotStart || isSlotEnd,
    isAbsence: false,
    height: i === slotStartHour && slotStartMinutes > 0 ? '50%' : i === endHour && slotEndMinutes > 0 ? '50%' : '100%',
    top: isSlotStartHalf ? `25px` : '0px',
    borderRadius: i === slotStartHour ? '10px 10px 0px 0px' : i === endHour ? '0px 0px 10px 10px' : '0px',
    showBoxShadow: false,
    borderStyle: 'dashed',
    borderColor: themeColors.DarkGrey,
    borderWidth: isSlotEnd ? '0px 1px 1px 1px' : isSlotStart ? '1px 1px 0px 1px' : '0px 1px 0px 1px',
  });
};

export const getAbsenceEntries = (
  absences: AbsenceDto[],
  slotFrom: string,
  slotTo: string,
  i: number,
  events: SlotEventsProps[]
) => {
  const totalHeightInPx = 50;
  const from = slotFrom.split(':');
  const to = slotTo.split(':');
  const slotStartHour = parseInt(from[0]);
  const slotEndHour = parseInt(to[0]);
  const slotStartMinutes = parseInt(from[1]);
  const slotEndMinutes = parseInt(to[1]);
  const hourlyAbsences = absences.filter((a) => a.startHour !== null);
  const partialAbsences = absences.filter((a) => a.morningOnly || a.afternoonOnly);
  const currentAbsence = absences[0];

  if (hourlyAbsences.length > 0) {
    //
    let totalHeightBySlots = 0;

    for (const hourlyAbsence of hourlyAbsences) {
      if (hourlyAbsence.startHour && hourlyAbsence.endHour) {
        //make hourly and attendance one helper (same logic)
        const slotStartHour = parseInt(getTimeFromDateString(hourlyAbsence.startHour).split(':')[0]);
        const slotEndHour = parseInt(getTimeFromDateString(hourlyAbsence.endHour).split(':')[0]);
        const slotEndMinutes = parseInt(getTimeFromDateString(hourlyAbsence.endHour).split(':')[1]);
        const slotStartMinutes = parseInt(getTimeFromDateString(hourlyAbsence.startHour).split(':')[1]);
        const isEndHalfHour = slotEndMinutes > 0;
        const endHour = isEndHalfHour ? slotEndHour : slotEndHour - 1;
        const slotDuration = differenceInMinutes(
          new LocalDate(hourlyAbsence.endHour).getDate(),
          new LocalDate(hourlyAbsence.startHour).getDate()
        );

        // length > 1 && excludes start/end
        const isSlotFilled = i > slotStartHour && i < endHour;
        //length = 1/2
        const isSlotFilledFirstHalf =
          i === slotStartHour && i === slotEndHour && slotEndMinutes > 0 && slotDuration < 60;
        //length = 1/2
        const isSlotFilledSecondHalf =
          i === slotStartHour && i < slotEndHour && slotStartMinutes > 0 && slotDuration < 60;
        // length > 0 && start == 1/2
        const isSlotFilledStart = i === slotStartHour && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf;
        // length > 0 && end == 1/2
        const isSlotFilledEnd = i === endHour && !isSlotFilledSecondHalf && !isSlotFilledFirstHalf;
        //lenght === 1
        const isSlotFilledOneDay = isSlotFilledStart && isSlotFilledEnd;
        // length > 1 && start
        const isSlotFilledStartHalf =
          i === slotStartHour && slotStartMinutes > 0 && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf;
        // length > 1 && end
        const isSlotFilledEndHalf =
          slotEndMinutes > 0 && i === endHour && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf;

        const height =
          isSlotFilledFirstHalf || isSlotFilledSecondHalf
            ? `${Math.floor((slotDuration * totalHeightInPx) / 60)}px`
            : isSlotFilledStartHalf
            ? `${((60 - slotStartMinutes) / 60) * totalHeightInPx + 1}px`
            : isSlotFilledEndHalf
            ? `${(slotEndMinutes / 60) * totalHeightInPx}px`
            : isSlotFilled
            ? '49px'
            : 'inherit';

        const top =
          isSlotFilledSecondHalf || isSlotFilledFirstHalf
            ? totalHeightBySlots
            : isSlotFilledStartHalf
            ? totalHeightInPx - ((60 - slotStartMinutes) / 60) * totalHeightInPx
            : '0px';

        if (isSlotFilledFirstHalf || isSlotFilledSecondHalf || isSlotFilledStartHalf || isSlotFilledEndHalf)
          totalHeightBySlots += parseInt(height) + 1;

        const isAbsence =
          isSlotFilled ||
          isSlotFilledStart ||
          isSlotFilledOneDay ||
          isSlotFilledEnd ||
          isSlotFilledStartHalf ||
          isSlotFilledEndHalf ||
          isSlotFilledFirstHalf ||
          isSlotFilledSecondHalf;

        events.push({
          label: i === slotStartHour ? hourlyAbsence.policy?.name ?? 'NA' : '',
          backgroundColor: hourlyAbsence.policy?.color ?? themeColors.Grey,
          hasAttendanceEntries: false,
          isScheduled: false,
          isAbsence: isAbsence,
          height,
          top: `${top}px`,
          borderRadius: i === slotStartHour ? '10px 10px 0px 0px' : i === endHour ? '0px 0px 10px 10px' : '0px',
          showBoxShadow: i === slotStartHour ? false : true,
          borderWidth: '0px',
          borderStyle: 'none',
          borderColor: 'none',
        });
      }
    }
  } else if (partialAbsences.length > 0) {
    for (const partialAbsence of partialAbsences) {
      const morningOnlyEndTime = calculateMidSlot(slotFrom, slotTo);
      const morningOnlyEndHour = parseInt(morningOnlyEndTime.split(':')[0]);
      const morningOnlyEndMinutes = parseInt(morningOnlyEndTime.split(':')[1]);
      const afternonOnlyEndHour = partialAbsence.afternoonOnly && slotEndMinutes > 0 ? slotEndHour : slotEndHour - 1;

      const isSlotFilled = partialAbsence.morningOnly
        ? i > slotStartHour && i < morningOnlyEndHour
        : partialAbsence.afternoonOnly
        ? i > morningOnlyEndHour && i < afternonOnlyEndHour
        : false;

      const isSlotFilledStartHalf = partialAbsence.morningOnly
        ? slotStartMinutes > 0 && i === slotStartHour
        : partialAbsence.afternoonOnly
        ? morningOnlyEndMinutes > 0 && morningOnlyEndHour === i
        : false;

      // length > 1 && end
      const isSlotFilledEndHalf = partialAbsence.morningOnly
        ? morningOnlyEndMinutes > 0 && i === morningOnlyEndHour
        : partialAbsence.afternoonOnly
        ? slotEndMinutes > 0 && afternonOnlyEndHour === i
        : false;

      const height = isSlotFilledStartHalf
        ? `${((60 - slotStartMinutes) / 60) * totalHeightInPx + 1}px`
        : isSlotFilledEndHalf
        ? `${(slotEndMinutes / 60) * totalHeightInPx}px`
        : isSlotFilled
        ? '49px'
        : 'inherit';

      const top = isSlotFilledStartHalf ? totalHeightInPx - ((60 - slotStartMinutes) / 60) * totalHeightInPx : '0px';

      const isAbsence = partialAbsence.morningOnly
        ? i >= slotStartHour && i <= morningOnlyEndHour
        : partialAbsence.afternoonOnly
        ? i >= morningOnlyEndHour && i <= afternonOnlyEndHour
        : false;
      events.push({
        label: i === slotStartHour ? partialAbsence.policy?.name ?? 'NA' : '',
        backgroundColor: partialAbsence.policy?.color ?? themeColors.Grey,
        hasAttendanceEntries: false,
        isScheduled: false,
        isAbsence: isAbsence,
        height,
        top: `${top}px`,
        borderRadius:
          (partialAbsence.morningOnly && i === slotStartHour) ||
          (partialAbsence.afternoonOnly && i === morningOnlyEndHour)
            ? '10px 10px 0px 0px'
            : (partialAbsence.morningOnly && i === morningOnlyEndHour) ||
              (partialAbsence.afternoonOnly && i === afternonOnlyEndHour)
            ? '0px 0px 10px 10px'
            : '0px',
        showBoxShadow:
          (partialAbsence.morningOnly && i === slotStartHour) ||
          (partialAbsence.afternoonOnly && i === morningOnlyEndHour)
            ? false
            : true,
        borderWidth: '0px',
        borderStyle: 'none',
        borderColor: 'none',
      });
    }
  } else if (currentAbsence) {
    const isEndHalfHour = slotEndMinutes > 0;
    const endHour = isEndHalfHour ? slotEndHour : slotEndHour - 1;

    const isSlotFilledStartHalf = slotStartMinutes > 0 && i === slotStartHour;
    const isSlotFilledEndHalf = slotEndMinutes > 0 && endHour === i;

    const height = isSlotFilledStartHalf
      ? `${((60 - slotStartMinutes) / 60) * totalHeightInPx + 1}px`
      : isSlotFilledEndHalf
      ? `${(slotEndMinutes / 60) * totalHeightInPx}px`
      : i > slotStartHour && i < endHour
      ? 'inherit'
      : 'inherit';
    const top = isSlotFilledStartHalf ? totalHeightInPx - ((60 - slotStartMinutes) / 60) * totalHeightInPx : '0px';

    events.push({
      label: i === slotStartHour ? currentAbsence.policy?.name ?? 'NA' : '',
      backgroundColor: currentAbsence.policy?.color ?? themeColors.Grey,
      hasAttendanceEntries: false,
      isScheduled: false,
      isAbsence: i === endHour || i === slotStartHour || (i > slotStartHour && i < endHour),
      height,
      top: `${top}px`,
      borderRadius: i === slotStartHour ? '10px 10px 0px 0px' : i === endHour ? '0px 0px 10px 10px' : '0px',
      showBoxShadow: i === slotStartHour || i === endHour ? false : true,
      borderWidth: '0px',
      borderStyle: 'none',
      borderColor: 'none',
    });
  }

  return events;
};

export const getAttendanceEntries = (
  attendanceEntries: AttendanceEntryDto[],
  i: number,
  events: SlotEventsProps[],
  attendanceTypes: Record<number, AttendanceTypeDto>
) => {
  const totalHeightInPx = 50;
  const filterEntries = attendanceEntries.filter(
    (a) =>
      i >= parseInt(getTimeFromDateString(a.startHour).split(':')[0]) &&
      i <= parseInt(getTimeFromDateString(a.endHour).split(':')[0])
  );
  let totalHeightBySlots = 0;
  for (const entry of filterEntries) {
    const slotStartHour = parseInt(getTimeFromDateString(entry.startHour).split(':')[0]);
    const slotEndHour = parseInt(getTimeFromDateString(entry.endHour).split(':')[0]);
    const slotEndMinutes = parseInt(getTimeFromDateString(entry.endHour).split(':')[1]);
    const slotStartMinutes = parseInt(getTimeFromDateString(entry.startHour).split(':')[1]);
    const isEndHalfHour = slotEndMinutes > 0;
    const endHour = isEndHalfHour ? slotEndHour : slotEndHour - 1;
    const slotDuration = differenceInMinutes(
      new LocalDate(entry.endHour).getDate(),
      new LocalDate(entry.startHour).getDate()
    );
    // length > 1 && excludes start/end
    const isSlotFilled = i > slotStartHour && i < endHour;
    //length = 1/2
    const isSlotFilledFirstHalf = i === slotStartHour && i === slotEndHour && slotEndMinutes > 0 && slotDuration < 60;
    //length = 1/2
    const isSlotFilledSecondHalf = i === slotStartHour && i < slotEndHour && slotStartMinutes > 0 && slotDuration < 60;
    // length > 0 && start 1/2
    const isSlotFilledStart = i === slotStartHour && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf;
    // length > 0 && end 1/2
    const isSlotFilledEnd = i === endHour && !isSlotFilledSecondHalf && !isSlotFilledFirstHalf;
    //lenght === 1
    const isSlotFilledOneDay = isSlotFilledStart && isSlotFilledEnd;
    // length > 1 && start
    const isSlotFilledStartHalf =
      i === slotStartHour && slotStartMinutes > 0 && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf;
    // length > 1 && end
    const isSlotFilledEndHalf =
      slotEndMinutes > 0 && i === endHour && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf;

    const filledType =
      isSlotFilled ||
      isSlotFilledStart ||
      isSlotFilledEnd ||
      isSlotFilledFirstHalf ||
      isSlotFilledSecondHalf ||
      isSlotFilledStartHalf ||
      isSlotFilledEndHalf
        ? entry
        : null;

    const hasAttendanceEntries =
      isSlotFilled ||
      isSlotFilledStart ||
      isSlotFilledOneDay ||
      isSlotFilledEnd ||
      isSlotFilledStartHalf ||
      isSlotFilledEndHalf ||
      isSlotFilledFirstHalf ||
      isSlotFilledSecondHalf;

    const height =
      isSlotFilledFirstHalf || isSlotFilledSecondHalf
        ? `${Math.floor((slotDuration * totalHeightInPx) / 60)}px`
        : isSlotFilledStartHalf
        ? `${((60 - slotStartMinutes) / 60) * totalHeightInPx + 1}px`
        : isSlotFilledEndHalf
        ? `${(slotEndMinutes / 60) * totalHeightInPx}px`
        : isSlotFilled
        ? '49px'
        : 'inherit';

    const top =
      isSlotFilledSecondHalf || isSlotFilledFirstHalf
        ? totalHeightBySlots
        : isSlotFilledStartHalf
        ? totalHeightInPx - ((60 - slotStartMinutes) / 60) * totalHeightInPx
        : '0px';

    if (isSlotFilledFirstHalf || isSlotFilledSecondHalf || isSlotFilledStartHalf || isSlotFilledEndHalf)
      totalHeightBySlots += parseInt(height) + 1;

    const type = filledType ? attendanceTypes[filledType.typeId].name : null;
    events.push({
      label: i === slotStartHour && !isSlotFilledFirstHalf && !isSlotFilledSecondHalf && type ? type : '',
      backgroundColor: type ? ColoursByType[type as string] : themeColors.Grey,
      hasAttendanceEntries,
      isScheduled: false,
      isAbsence: false,
      height,
      top: `${top}px`,
      borderRadius:
        isSlotFilledFirstHalf || isSlotFilledSecondHalf || isSlotFilledOneDay
          ? '10px'
          : i === slotStartHour
          ? '10px 10px 0px 0px'
          : i === endHour
          ? '0px 0px 10px 10px'
          : '0px',
      showBoxShadow: i === slotStartHour ? false : true,
      borderWidth: '0px',
      borderStyle: 'none',
      borderColor: 'none',
    });
  }
  return events;
};

export function getWeeksAndYearsOptions(
  weekOptions: { weekNo: number; year: number }[] | null | undefined
): {
  yearsOptions: Set<number>;
  weeksOptionsByYear: { [year: number]: Set<number> };
} {
  const today = new Date();
  const prevWeek = new Date();
  prevWeek.setDate(today.getDate() - 7);
  const nextWeek = new Date();
  nextWeek.setDate(today.getDate() + 7);

  const prevWeekOption = { weekNo: getISOWeek(prevWeek), year: prevWeek.getFullYear() };
  const currentWeekOption = { weekNo: getISOWeek(today), year: today.getFullYear() };
  const nextWeekOption = { weekNo: getISOWeek(nextWeek), year: nextWeek.getFullYear() };

  const prevWeekIsIncluded = weekOptions?.some(
    (option) => option.weekNo === prevWeekOption.weekNo && option.year === prevWeekOption.year
  );
  const currentWeekIsIncluded = weekOptions?.some(
    (option) => option.weekNo === currentWeekOption.weekNo && option.year === currentWeekOption.year
  );
  const nextWeekIsIncluded = weekOptions?.some(
    (option) => option.weekNo === nextWeekOption.weekNo && option.year === nextWeekOption.year
  );

  const allOptions = [...(weekOptions ?? [])];
  if (!prevWeekIsIncluded && weekOptions && weekOptions.length === 0) allOptions.push(prevWeekOption);
  if (!currentWeekIsIncluded) allOptions.push(currentWeekOption);
  if (!nextWeekIsIncluded) allOptions.push(nextWeekOption);

  const yearsOptions = new Set<number>();
  const weeksOptionsByYear = {} as { [year: number]: Set<number> };
  for (const option of allOptions) {
    yearsOptions.add(option.year);
    if (!weeksOptionsByYear[option.year]) weeksOptionsByYear[option.year] = new Set<number>();
    weeksOptionsByYear[option.year].add(option.weekNo);
  }

  return { yearsOptions, weeksOptionsByYear };
}
