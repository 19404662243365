import { AttendancePenaltyDto } from '@v2/feature/attendance/subfeatures/attendance-penalty/attendance-penalty.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

export class AttendancePenaltyAPI {
  static async enableAndDisableAttendancePenaltyRules(
    scheduleId: number,
    toEnable: number[],
    toDisable: number[]
  ): Promise<void> {
    await axios.patch(`/apiv2/attendance-penalties/schedules/${scheduleId}`, { toEnable, toDisable });
  }
}

export class AttendancePenaltyEndpoints {
  static getAttendanceSchedulePenaltyRules(scheduleId: number): Endpoint<AttendancePenaltyDto[]> {
    return {
      url: `/apiv2/attendance-penalties/schedules/${scheduleId}`,
    };
  }
}
