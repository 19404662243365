/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useContext } from 'react';

import { Box, Chip, IconButton, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { useHistory, useLocation } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Back } from '@/images/fields/Left.svg';
import { ReactComponent as Menu } from '@/images/fields/Menu Mobile.svg';
import { ReactComponent as Settings } from '@/images/fields/Settings.svg';
import { StyledTooltip, StyledTooltipStyleProps } from '@/v2/components/theme-components/styled-tooltip.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { MainHeaderRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { showCustomDomainSidebar, showDomainSideBar } from '@/v2/util/app-layout.util';

interface Props {
  readonly title: React.JSX.Element | string | null;
  readonly actions?: ReactNode;
  readonly showAction?: boolean;
  readonly onBack?: () => Promise<void> | void;
  readonly showBack?: boolean;
  readonly views?: React.JSX.Element | false | null;
  readonly settingsAction?: () => void;
  readonly settingsHide?: boolean;
  readonly settingsTooltip?: StyledTooltipStyleProps;
  readonly backPath?: string;
  readonly showClose?: boolean;
  readonly closeAction?: () => void;
  readonly alphaTesting?: boolean;
  readonly sx?: SxProps;
}

export function TopHeader({
  title,
  actions,
  showAction = false,
  onBack = undefined,
  showBack,
  views,
  settingsHide = false,
  settingsAction,
  settingsTooltip,
  backPath,
  showClose,
  closeAction,
  alphaTesting = false,
  sx = { width: '100%', ...spacing.mx16 },
}: Props): React.JSX.Element {
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const { pathname } = useLocation();

  const theme = useTheme();
  const isTabletScreen = useMediaQuery(
    `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainHeaderRootStyle>
      <Box sx={sx}>
        <Box sx={{ width: '100%', py: spacing.s2 }}>
          <Box
            sx={{
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: spacing.g15 }}>
              {showBack && (
                <IconButton
                  sx={iconCTAButtonSx}
                  onClick={async () => {
                    if (onBack) await onBack();
                    if (backPath) routerHistory.push(backPath);
                    else routerHistory.goBack();
                  }}
                >
                  <Back {...iconSize} />
                </IconButton>
              )}
              {(showDomainSideBar(pathname) || showCustomDomainSidebar(pathname)) &&
                (isTabletScreen || isMobileScreen) && (
                  <IconButton
                    sx={iconCTAButtonSx}
                    onClick={() => {
                      dispatch({ type: GlobalStateActions.UPDATE_SHOW_SETTING_BAR, payload: !state.showSettingBar });
                    }}
                  >
                    <Menu {...iconSize} />
                  </IconButton>
                )}
              {title && typeof title === 'string' ? <Typography variant="title2">{title}</Typography> : title}
              {!!views && <Box>{views}</Box>}
              {alphaTesting && (
                <Box>
                  <Chip
                    label="Alpha testing"
                    sx={{
                      ...themeFonts.caption,
                      color: themeColors.white,
                      backgroundColor: themeColors.Orange,
                      padding: '5px 0px 5px 0px',
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
              {settingsAction && !settingsHide && (
                <StyledTooltip {...settingsTooltip}>
                  <IconButton sx={iconCTAButtonSx} onClick={settingsAction}>
                    <Settings {...iconSize} />
                  </IconButton>
                </StyledTooltip>
              )}

              {showClose && (
                <IconButton sx={iconCTAButtonSx} onClick={closeAction}>
                  <Close {...iconSize} stroke={themeColors.DarkGrey} />
                </IconButton>
              )}
              {showAction && <>{actions}</>}
            </Box>
          </Box>
        </Box>
      </Box>
    </MainHeaderRootStyle>
  );
}
