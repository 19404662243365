import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import type { AccountInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  buttonBoxSx,
  fieldSx,
  titleSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserAPI } from '@/v2/feature/user/user.api';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatLongDate } from '@/v2/util/date-format.util';

export const AccountInformationSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee Id is required'),
});

interface Props {
  readonly initialValues: AccountInformationValues;
  readonly userId: number;
  readonly onSubmit: (_: AccountInformationValues) => void;
  readonly onClose: () => void;
}

export const AccountInformationForm = ({ initialValues, userId, onSubmit, onClose }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<AccountInformationValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: AccountInformationSchema,
    onSubmit: async (values) => {
      // if (!userBasicsUpdate) return;
      setLoading(true);
      try {
        // TODO return patched data
        await UserAPI.updateEmployeeId(userId, { employeeId: values.employeeId });
        onSubmit(values);
        showMessage(polyglot.t('AccountInformationForm.successMessages.update'), 'success');
      } catch (error) {
        // TODO we should improve how we handle halidation error messages
        showMessage(
          `${polyglot.t('AccountInformationForm.errorMessages.update')}: ${nestErrorMessage(error)}; Please try again.`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography sx={titleSx}>{polyglot.t('AccountInformationForm.edit')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="employeeId"
                label={polyglot.t('AccountInformationForm.employeeId')}
                value={formik.values.employeeId}
                onChange={formik.handleChange}
                type="text"
                error={formik.touched.employeeId && !!formik.errors.employeeId}
                helperText={(formik.touched.employeeId && formik.errors.employeeId) ?? ' '}
                clearText={() => formik.setFieldValue('employeeId', '')}
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="accountStatus"
                label={polyglot.t('AccountInformationForm.status')}
                value={formik.values.accountStatus}
                type="text"
                disabled={true}
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="activationDate"
                label={polyglot.t('AccountInformationForm.activationDate')}
                value={formik.values.activationDate ? formatLongDate(formik.values.activationDate) : ''}
                type="text"
                disabled={true}
              />
            </Box>
            {formik.values.deactivationDate && (
              <Box sx={fieldSx}>
                <TextfieldComponent
                  name="deactivationDate"
                  label={polyglot.t('AccountInformationForm.deactivationDate')}
                  value={formik.values.deactivationDate ? formatLongDate(formik.values.deactivationDate) : ''}
                  type="text"
                  disabled={true}
                />
              </Box>
            )}
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="createdAt"
                label={polyglot.t('AccountInformationForm.createdAt')}
                value={formik.values.createdAt ? formatLongDate(formik.values.createdAt) : ''}
                type="text"
                disabled={true}
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="updatedAt"
                label={polyglot.t('AccountInformationForm.updatedAt')}
                value={formik.values.updatedAt ? formatLongDate(formik.values.updatedAt) : ''}
                type="text"
                disabled={true}
              />
            </Box>
            {formik.values.lastTimeOnline && (
              <Box sx={fieldSx}>
                <TextfieldComponent
                  name="lastTimeOnline"
                  label={polyglot.t('AccountInformationForm.lastTimeOnline')}
                  value={formik.values.lastTimeOnline ? formatLongDate(formik.values.lastTimeOnline) : ''}
                  type="text"
                  disabled={true}
                />
              </Box>
            )}
          </Box>
          <Box sx={buttonBoxSx}>
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
              {polyglot.t('General.cancel')}
            </ButtonComponent>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
