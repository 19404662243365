import {
  OFFBOARDING_ROUTE,
  ONBOARDING_ROUTE,
  PEOPLE_COMPANY_ACTION_ITEMS,
  PEOPLE_COMPANY_DIRECTORY_ROUTE,
  PEOPLE_ME_ACTION_ITEMS,
  PEOPLE_ME_DIRECTORY_ROUTE,
  PEOPLE_TEAM_ACTION_ITEMS,
  PEOPLE_TEAM_DIRECTORY_ROUTE,
} from '@/lib/routes';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const PEOPLE_DIRECTORY_ROUTES: RoleRoutes = {
  company: PEOPLE_COMPANY_DIRECTORY_ROUTE,
  team: PEOPLE_TEAM_DIRECTORY_ROUTE,
  me: PEOPLE_ME_DIRECTORY_ROUTE,
};

export const PEOPLE_DIRECTORY_SCOPES: RoleScopes = {
  company: ['user:read:all'],
  team: ['user:read:all'],
  me: ['user:read:all'],
};

export const PEOPLE_COMPANY_ACTION_ITEMS_ROUTES: RoleRoutes = {
  company: PEOPLE_COMPANY_ACTION_ITEMS,
  team: PEOPLE_TEAM_ACTION_ITEMS,
  me: PEOPLE_ME_ACTION_ITEMS,
};

export const PEOPLE_ONBOARDING_OVERVIEW_ROUTES: RoleRoutes = {
  company: ONBOARDING_ROUTE,
};

export const PEOPLE_OFFBOARDING_OVERVIEW_ROUTES: RoleRoutes = {
  company: OFFBOARDING_ROUTE,
};
