import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { ReportColumnType, ReportResponse, ReportResponseEntry } from '@v2/feature/reports/reports.interface';

interface TableProps {
  readonly reportResponse: ReportResponse | null;
}

function getDefaultLabel(key: string): string {
  const tableSplit = key.split('__').join('->');
  const [label, operator] = tableSplit.split('|');
  if (operator === ReportColumnType.PLAIN) return label;

  return `${operator} (${label})`;
}

type TimeSinceRawType = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
};

function sortTimeSinceReports<Type>(
  a: Row<Type>,
  b: Row<Type>,
  getValue: (entry: Type) => TimeSinceRawType | undefined
) {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);

  if (aValue?.years !== bValue?.years) return (aValue?.years ?? 0) - (bValue?.years ?? 0);
  if (aValue?.months !== bValue?.months) return (aValue?.months ?? 0) - (bValue?.months ?? 0);
  if (aValue?.days !== bValue?.days) return (aValue?.days ?? 0) - (bValue?.days ?? 0);
  if (aValue?.hours !== bValue?.hours) return (aValue?.hours ?? 0) - (bValue?.hours ?? 0);
  if (aValue?.minutes !== bValue?.minutes) return (aValue?.minutes ?? 0) - (bValue?.minutes ?? 0);
  if (aValue?.seconds !== bValue?.seconds) return (aValue?.seconds ?? 0) - (bValue?.seconds ?? 0);
  if (aValue?.milliseconds !== bValue?.milliseconds) return (aValue?.milliseconds ?? 0) - (bValue?.milliseconds ?? 0);

  return 0;
}

export const ReportTable = ({ reportResponse }: TableProps) => {
  const columns = useMemo<ColumnDef<ReportResponseEntry, ReportResponseEntry>[]>(() => {
    if (!reportResponse?.data || reportResponse.data.length === 0) return [];

    const firstRow: ReportResponseEntry = reportResponse.data[0];

    const columnsList: (ColumnDef<ReportResponseEntry, ReportResponseEntry> & { order: number })[] = Object.keys(
      firstRow
    ).map((key) => ({
      id: key,
      // minSize: 100, // size configured bellow in Box sx
      // maxSize: 500,
      enableSorting: true,
      sortingFn: (a, b) =>
        firstRow[key]?.type === 'number'
          ? sortNumeric(a, b, (item) => (item[key].raw as number) ?? 0)
          : firstRow[key]?.type === 'date' &&
            typeof firstRow[key]?.raw === 'object' &&
            key.split('|')[1] === ReportColumnType.AGE
          ? sortTimeSinceReports(a, b, (item) => (item[key].raw as TimeSinceRawType) ?? undefined)
          : sortString(a, b, (item) => (item[key].raw as string) ?? ''),
      accessorFn: (row) => row,
      header: () => reportResponse.header.labels[key] ?? getDefaultLabel(key),
      cell: ({ row: { original } }) => {
        if (!original[key] || original[key].value === null || original[key].value === undefined) return '';
        return (
          <Box sx={{ minWidth: '100px', maxWidth: '500px' }}>
            <Typography variant="caption" sx={{ width: '100%' }}>
              {original[key].value}
            </Typography>
          </Box>
        );
      },
      order: reportResponse.header.order[key] ?? 0,
    }));

    columnsList.sort((a, b) => a.order - b.order);
    return columnsList as ColumnDef<ReportResponseEntry, ReportResponseEntry>[];
  }, [reportResponse]);

  return (
    <Box sx={{ width: '100%', maxHeight: `${window.innerHeight - 123}px` }}>
      <BasicTable<ReportResponseEntry>
        rowData={[...(reportResponse?.data ?? [])]}
        columnData={columns}
        fixedLastColumn={false}
      />
    </Box>
  );
};
