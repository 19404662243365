import Polyglot from 'node-polyglot';

import { ApproverGroup } from '@/v2/components/approver-select/approver-select.interface';
import { MultiSelectOption } from '@/v2/components/forms/multiple-select-checkbox.component';
import { OptionObj } from '@/v2/components/user-select-type/user-select.component';

export enum NotificationTypes {
  newJoiner = 'newJoiner',
  workAnniversary = 'workAnniversary',
  lastDay = 'lastDay',
  birthday = 'birthday',
}

export type NotificationKinds =
  | NotificationTypes.newJoiner
  | NotificationTypes.workAnniversary
  | NotificationTypes.lastDay
  | NotificationTypes.birthday;

export type NotificationKindsSettings = Record<NotificationKinds, boolean>;

export enum DomainTypes {
  bankAccount = 'bankAccount',
  probationPeriod = 'probationPeriod',
  attendance = 'attendance',
  documentExpiry = 'documentExpiry',
  documentChanges = 'documentChanges',
  userAddress = 'userAddress',
  securityDigest = 'securityDigest',
  userSecurityDigest = 'userSecurityDigest',
}

export const getDomainTypesOptions = (polyglot: any) => {
  return [
    {
      value: DomainTypes.bankAccount,
      label: polyglot.t('DomainTypesOptions.bankAccount'),
    },
    {
      value: DomainTypes.attendance,
      label: polyglot.t('DomainTypesOptions.attendanceRoundup'),
    },
    {
      value: DomainTypes.probationPeriod,
      label: polyglot.t('DomainTypesOptions.probationPeriod'),
    },
    {
      value: DomainTypes.documentExpiry,
      label: polyglot.t('DomainTypesOptions.documentExpiry'),
    },
    {
      value: DomainTypes.documentChanges,
      label: polyglot.t('DomainTypesOptions.documentChanges'),
    },
    {
      value: DomainTypes.userAddress,
      label: polyglot.t('DomainTypesOptions.userAddress'),
    },
    {
      value: DomainTypes.securityDigest,
      label: polyglot.t('DomainTypesOptions.securityDigest'),
    },
    {
      value: DomainTypes.userSecurityDigest,
      label: polyglot.t('DomainTypesOptions.userSecurityDigest'),
    },
  ];
};

export type ProfileNotifications = {
  companyId: number;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  domain: string;
  sendAdmin: boolean;
  sendManager: boolean;
  sendSpecificUsers: boolean;
  email: boolean;
  specificUserEmails: number[];
  slack: boolean;
  slackChannels: string[];
  daysBefore: number | null;
  cronHour: number;
  name?: string;
  fieldId?: string;
  formId?: string;
  notificationText?: string;
  sendSlackBot?: boolean;
  weeklyDay?: number | null;
};

export interface NotificationSettingsForCompany {
  notifications: UserNotifications;
}

export interface UserNotifications {
  lastDay: NotificationEvent;
  birthday: NotificationEvent;
  newJoiner: NotificationEvent;
  workAnniversary: NotificationEvent;
}

export interface NotificationEvent {
  enabled: boolean;
  settings: NotificationSettingsForEvent;
}

export interface NotificationSettingsForEvent {
  email: boolean;
  slack: boolean;
  emails: number[];
  cronHour: number;
  sendToEveryone: boolean;
  specificUsers: number[];
  slackChannels: string[];
}

export interface NotificationFields {
  types?: NotificationKindsSettings;
  settings?: {
    slack: boolean;
    slackChannels: string[];
    email: boolean;
    emails: string[];
    cronHour?: number;
  };
}

export enum NotificationSendTo {
  ADMIN = 'admin',
  MANAGER = 'manager',
  SPECIFIC = 'specific',
  EVERYONE = 'everyone',
}

export enum NotificationSendToHumanValue {
  admin = 'Admin',
  manager = 'Manager',
  specific = 'Specific users',
  everyone = 'Everyone',
  allEmployees = 'All employees',
}

export enum NotificationChannel {
  EMAIL = 'Email',
  SLACK_CHANNEL = 'Slack',
  SLACK_BOT = 'Slack bot',
  NONE = 'None',
}

export enum NotificationWhen {
  IMMEDIATE = 'Immediate',
  BEFORE = 'Before',
  ONCE = 'Once',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
}

export interface NotificationForTable {
  id?: number;
  type?: NotificationCategory;
  name?: NotificationHumanName | string;
  systemName?: NotificationSystemName;
  status: boolean;
  sendTo: string;
  sendToEditValue: ApproverGroup;
  when: NotificationWhen | string;
  channel: string;
  emailEnabled: boolean;
  slackEnabled: boolean;
  slackChannels?: MultiSelectOption<string>[];
  cronHour?: number;
  daysBefore?: number | null;
  createdAt?: Date;
  notificationText?: string;
  employeeTriggerEvent?: string;
  sendSlackBot?: boolean;
  weeklyDay?: number | null;
  reminderSchedule?: string;
}

export enum NotificationCategory {
  PEOPLE = 'People',
  DOCUMENTS = 'Documents',
  EMPLOYEE_INFORMATION = 'People',
  PROFILE = 'Profile',
  ATTENDANCE = 'Attendance',
  DEVICES = 'Devices',
}

export enum NotificationHumanName {
  lastDay = 'Last day',
  birthday = 'Birthday',
  newJoiner = 'New joiner',
  workAnniversary = 'Work anniversary',
  bankAccount = 'Bank details change',
  probationPeriod = 'Probation period ending',
  attendance = 'Attendance roundup',
  documentExpiry = 'Document expiry',
  documentChanges = 'Document changes',
  userAddress = 'Address change',
  contractEnd = 'Contract end',
  idCheck = 'ID Check updates',
  securityDigest = 'Security digest',
  userSecurityDigest = 'User security digest',
}

export enum NotificationSystemName {
  lastDay = 'lastDay',
  birthday = 'birthday',
  newJoiner = 'newJoiner',
  workAnniversary = 'workAnniversary',
  bankAccount = 'bankAccount',
  probationPeriod = 'probationPeriod',
  documentExpiry = 'documentExpiry',
  documentChanges = 'documentChanges',
  userAddress = 'userAddress',
  profileNotification = 'profileNotification',
  contractEnd = 'contractEnd',
  attendance = 'attendance',
  onboarding = 'onboarding',
  idCheck = 'idCheck',
  securityDigest = 'securityDigest',
  userSecurityDigest = 'userSecurityDigest',
}

export enum WhenFilterEnum {
  IMMEDIATE = 'immediate',
  BEFORE = 'before',
}

export const WhenFilterOptions = (polyglot: Polyglot) => {
  return [
    { name: polyglot.t('WhenFilterOptions.onDay'), value: WhenFilterEnum.IMMEDIATE },
    { name: polyglot.t('WhenFilterOptions.before'), value: WhenFilterEnum.BEFORE },
  ];
};

export const DOMAINS_WITH_BEFORE = [
  NotificationSystemName.documentExpiry,
  NotificationSystemName.probationPeriod,
  NotificationSystemName.profileNotification,
  NotificationSystemName.contractEnd,
];

export const USER_NOTIFICATIONS_LIST = [
  NotificationSystemName.birthday,
  NotificationSystemName.lastDay,
  NotificationSystemName.newJoiner,
  NotificationSystemName.workAnniversary,
];

export const NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK = [
  ...USER_NOTIFICATIONS_LIST,
  NotificationSystemName.profileNotification,
];

export enum CustomNotificationType {
  PROFILE_NOTIFICATION = 'profileNotification',
}

export const EMPLOYEE_INFO_NOTIFICATIONS = [
  NotificationSystemName.bankAccount,
  NotificationSystemName.probationPeriod,
  NotificationSystemName.userAddress,
];

export const NOTIFICATIONS_TO_HIDE_SPECIFIC = [
  NotificationSystemName.profileNotification,
  NotificationSystemName.onboarding,
];

export enum DefaultNotifications {
  onboardingCompletion = 'Onboarding completion',
  onboardingReminder = 'Onboarding reminder',
}

export const DOCUMENT_NOTIFICATIONS = [NotificationSystemName.documentChanges, NotificationSystemName.documentExpiry];

export const ATTENDANCE_NOTIFICATIONS = [NotificationSystemName.attendance];
export const DEVICE_NOTIFICATIONS = [NotificationSystemName.securityDigest, NotificationSystemName.userSecurityDigest];

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const NumberToDay: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const DAYS_OF_THE_WEEK_OPTIONS: OptionObj[] = [
  { label: NumberToDay[0], value: DayOfWeek.Sunday },
  { label: NumberToDay[1], value: DayOfWeek.Monday },
  { label: NumberToDay[2], value: DayOfWeek.Tuesday },
  { label: NumberToDay[3], value: DayOfWeek.Wednesday },
  { label: NumberToDay[4], value: DayOfWeek.Thursday },
  { label: NumberToDay[5], value: DayOfWeek.Friday },
  { label: NumberToDay[6], value: DayOfWeek.Saturday },
];

export const REMINDER_SCHEDULE_OPTIONS: OptionObj[] = [
  { label: NotificationWhen.ONCE, value: NotificationWhen.ONCE },
  { label: NotificationWhen.DAILY, value: NotificationWhen.DAILY },
  { label: NotificationWhen.WEEKLY, value: NotificationWhen.WEEKLY },
];

export const EMPLOYEE_TRIGGER_EVENTS = new Set(['dob', 'startDate', 'leaveDate']);
