import { Box, IconButton, Typography } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { cardTitleIconSx, settingCardSx } from '@/v2/styles/settings.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

type CompanySettingSectionHeaderProps = {
  title: string;
  editButtonHint?: string;
  editButtonLabel?: string;
  showEditButton?: boolean;
  onEditClick?: () => void;
  header?: string;
  subheader?: string;
  buttonName?: string;
  onButtonClick?: () => Promise<void> | void;
  buttonLoading?: boolean;
};

export const CompanySettingSectionHeader = ({
  title,
  editButtonHint,
  editButtonLabel,
  onEditClick,
  showEditButton,
  header,
  subheader,
  buttonName,
  onButtonClick,
  buttonLoading = false,
}: CompanySettingSectionHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...settingCardSx }}>
      <Box sx={cardTitleIconSx}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>{title}</Typography>
        {showEditButton && !editButtonLabel && (
          <IconButton title={editButtonHint} sx={tableIconButtonSx} onClick={() => onEditClick?.()}>
            <EditIcon {...actionIconSize} />
          </IconButton>
        )}
        {showEditButton && editButtonLabel && (
          <ButtonComponent
            title={editButtonHint}
            colorVariant="secondary"
            sizeVariant="small"
            onClick={() => onEditClick?.()}
          >
            {editButtonLabel}
          </ButtonComponent>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
          {header && <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{header}</Typography>}
          {subheader && (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{subheader}</Typography>
          )}
        </Box>
        {buttonName && onButtonClick && (
          <Box>
            <LoaderButton
              sizeVariant="small"
              colorVariant="secondary"
              name={buttonName}
              type="button"
              onClick={onButtonClick}
              loading={buttonLoading}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
