import { Scope } from '@/models';
import { ReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleType, ScaleConfig } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';

export type QuestionResponseSummary = {
  questionId: string;
  factor: string;
  type: string;
  questionText: string;
  questionMain: string;
  questionSelf: string;
  answersCount: number;
  answerOptions: {
    [key: string]: string;
  };
  commentsCount: number;
  categories: string[];
  series: string[];
  scaleConfig: ScaleConfig | null;
  answers: ReviewAnswer[];
};

export type ReviewerScoreSummary = {
  type: ReviewerTypes | null;
  entryState: string | null;
  reviewerId: number;
  average: number | null;
};

export type ReviewComment = {
  id: string;
  commentBy: number;
  comment: string;
  isPublic: boolean;
};

export type ReviewMeResultList = {
  id: string;
  name: string;
  startDate: string;
  state: CycleState;
};

export type ReviewUserResultList = {
  id: string;
  name: string;
  startDate: string;
  state: CycleState;
  type: CycleType;
  isResultShared: boolean;
};

export const USER_PROFILE_REVIEWS_SCOPE = ['reviews:manager'] as Scope[];
export const USER_PROFILE_SURVEYS_SCOPE = ['surveys:manager'] as Scope[];

export const USER_PROFILE_GROWTH_SCOPE = [...USER_PROFILE_REVIEWS_SCOPE, ...USER_PROFILE_SURVEYS_SCOPE] as const;
