import React from 'react';

import { SxProps, Box, Theme } from '@mui/material';

import {
  StyledTabs,
  StyledTab,
} from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { themeColors } from '@/v2/styles/colors.styles';

type PayrunOverviewTabsProps<T extends string> = {
  tabs: readonly T[];
  currentTab?: T;
  onChange?: (tab: T) => void;
  sx?: SxProps<Theme>;
};

export const PayrunOverviewTabs = <T extends string>({
  tabs,
  currentTab,
  onChange,
  sx,
}: PayrunOverviewTabsProps<T>) => {
  return (
    <Box sx={{ ...sx, position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 1,
          left: 0,
          right: 0,
          height: '1px',
          backgroundColor: themeColors.middleGrey,
        }}
      />
      <StyledTabs
        value={currentTab || false}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={(_, value) => {
          onChange?.(value);
        }}
      >
        {tabs.map((label) => (
          <StyledTab
            key={label}
            disableRipple
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  textTransform: 'inherit',
                }}
              >
                {label}
              </div>
            }
            value={label}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};
