import { useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import { ListItemIcon } from '@mui/material';
import Lottie from 'lottie-react';
import { useHistory, useLocation } from 'react-router-dom';

import SettingsLoop from '@/animations/Settings/Settings_loop_30x30.json';
import SettingsLoopFill from '@/animations/Settings/Settings_loop_FILL_30x30.json';
import { SETTINGS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { NavItemStyle } from '@/v2/styles/list-button.styles';

const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
`;
export const CompanySettings = ({ headerName, redirectPath }: { headerName: string; redirectPath: string }) => {
  const routerHistory = useHistory();
  const { pathname } = useLocation();

  const lottieRef1 = useRef<any>(null);
  const lottieRef2 = useRef<any>(null);

  const isSettingsRoute = pathname.startsWith(SETTINGS_ROUTE);

  useEffect(() => {
    lottieRef1.current?.setSpeed(1.3);
    lottieRef2.current?.setSpeed(1.3);
  }, []);

  return (
    <NavItemStyle
      onClick={() => routerHistory.push(redirectPath)}
      key={pathname}
      onMouseOver={() => {
        lottieRef1.current?.play();
        lottieRef2.current?.play();
      }}
      onMouseEnter={() => {
        lottieRef1.current?.play();
        lottieRef2.current?.play();
      }}
      onMouseLeave={() => {
        lottieRef1.current?.stop();
        lottieRef2.current?.stop();
      }}
    >
      <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
        <LottieContainer style={{ display: isSettingsRoute ? 'flex' : 'none' }}>
          <Lottie
            lottieRef={lottieRef2}
            animationData={SettingsLoopFill}
            loop
            autoplay={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </LottieContainer>

        <LottieContainer style={{ display: isSettingsRoute ? 'none' : 'flex' }}>
          <Lottie
            lottieRef={lottieRef1}
            animationData={SettingsLoop}
            loop
            autoplay={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </LottieContainer>
      </ListItemIcon>
      <Typography variant={isSettingsRoute ? 'title5' : 'captionSmall'}>{headerName}</Typography>
    </NavItemStyle>
  );
};
