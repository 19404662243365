import { useCallback, useContext, useMemo, useState } from 'react';

import { capitalize } from 'lodash';

import { GlobalContext } from '@/GlobalState';
import { useEventSource } from '@/hooks/event-source-hook';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SCParticipantRollingView } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/rolling/sc-participant-rolling-view.component';
import { SCParticipantAnonymityModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-anonymity.modal.component';
import { SCParticipantScheduledView } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-scheduled-view.component';
import { TitleStatusComponent } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { PushEvent } from '@/v2/infrastructure/push-events/push-events.interface';
import { eventSourceErrorHandler } from '@/v2/infrastructure/push-events/push-events.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface SurveyCycleCreationParticipantsPageProps {
  readonly cycleId: string;
  readonly reach: ReachType;
}

export const SurveyCycleCreationParticipantsPage = ({ cycleId, reach }: SurveyCycleCreationParticipantsPageProps) => {
  const { data: surveyCycle, mutate: refreshCycle } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, reach),
    {
      suspense: false,
    }
  );

  const {
    data: surveyParticipants,
    mutate: refreshSurveyParticipants,
    isLoading: surveyParticipantsLoading,
  } = useApiClient(
    surveyCycle?.id ? SurveyCycleEndpoints.getSurveyParticipantsByCycleId(cycleId, reach) : { url: undefined },
    {
      suspense: false,
    }
  );

  const [isAnonymitySettings, setIsAnonymitySettings] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const environmentPrefix = `${isProduction ? 'prod' : 'test'}`;

  const { user } = state;

  const refresh = useCallback(async () => {
    await refreshSurveyParticipants?.();
    await refreshCycle?.();
  }, [refreshCycle, refreshSurveyParticipants]);

  const isAdmin = canAccessScopes(user, ['surveys:all']);
  const isManager = canAccessScopes(user, ['surveys:manager']);
  const channelName = `survey_cycle-${environmentPrefix}-company_id-${companyId}`;
  const surveyParticipantsUpdatedDataHandler = async (data: PushEvent) => {
    if (data.message && data.companyId === companyId) {
      refresh();
      return;
    }
  };

  useEventSource(SurveyCycleEndpoints.getEventSourceUrlForAddParticipantsInCycle(channelName)?.url, {
    onMessage: surveyParticipantsUpdatedDataHandler,
    onError: eventSourceErrorHandler,
  });

  const isEditable = useMemo(() => {
    if (!surveyCycle) return false;

    const isStateEditable = surveyCycle.state === CycleState.Draft || surveyCycle.state === CycleState.Scheduled;
    const isOngoingAndAnonymised =
      surveyCycle.state === CycleState.Ongoing &&
      surveyCycle.visibilitySettings &&
      !surveyCycle.visibilitySettings.anonymiseAnswers;
    const isUserAuthorized = isAdmin || (isManager && surveyCycle.owner === user.userId);

    return Boolean(isStateEditable || isOngoingAndAnonymised) && isUserAuthorized;
  }, [surveyCycle, isAdmin, isManager, user]);

  const isAnonymityEditable = useMemo(
    () =>
      Boolean(
        surveyCycle &&
          (surveyCycle.state === CycleState.Draft || surveyCycle.state === CycleState.Scheduled) &&
          Boolean(isAdmin || (isManager && surveyCycle.owner === user.userId))
      ),
    [surveyCycle, isAdmin, isManager, user]
  );

  if (!surveyCycle) return <></>;

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent surveyCycle={surveyCycle} />}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      noHorizontalPadding={false}
      loading={surveyParticipantsLoading}
    >
      <SettingsSubsectionContent
        sections={[
          {
            title: 'Anonymity settings',
            onEdit: () => setIsAnonymitySettings(true),
            editButtonDisabled: !isAnonymityEditable,
            hideEdit: !isAnonymityEditable,
            items: [
              {
                type: SectionItemType.Pair,
                label: 'Anonymity',
                value: surveyCycle?.visibilitySettings?.anonymiseAnswers ? 'Enable' : 'Disabled',
              },
              ...(surveyCycle?.visibilitySettings?.anonymiseAnswers
                ? [
                    {
                      type: SectionItemType.Pair,
                      label: 'Anonymity type',
                      value:
                        surveyCycle?.visibilitySettings &&
                        surveyCycle?.visibilitySettings?.anonymityType &&
                        surveyCycle?.visibilitySettings?.anonymityType !== null
                          ? (capitalize(surveyCycle?.visibilitySettings?.anonymityType) as string)
                          : 'Full',
                    },
                    {
                      type: SectionItemType.Pair,
                      label: 'Anonymity threshold',
                      value:
                        surveyCycle?.visibilitySettings &&
                        surveyCycle?.visibilitySettings?.anonymityThreshold &&
                        surveyCycle?.visibilitySettings?.anonymityThreshold !== null
                          ? surveyCycle?.visibilitySettings.anonymityThreshold?.toString()
                          : '3',
                    },
                  ]
                : []),
            ],
          },
        ]}
      />

      {surveyCycle && surveyCycle.type === CycleType.Scheduled && (
        <SCParticipantScheduledView
          surveyParticipants={surveyParticipants}
          surveyParticipantsLoading={surveyParticipantsLoading}
          surveyCycle={surveyCycle}
          refresh={refresh}
          reach={reach}
          isEditable={isEditable}
        />
      )}
      {surveyCycle && surveyCycle.type === CycleType.Rolling && (
        <SCParticipantRollingView
          surveyParticipants={surveyParticipants}
          surveyParticipantsLoading={surveyParticipantsLoading}
          surveyCycle={surveyCycle}
          refresh={refresh}
          reach={reach}
          isEditable={isEditable}
        />
      )}

      <SCParticipantAnonymityModal
        surveyCycle={surveyCycle}
        isOpen={isAnonymitySettings}
        setIsOpen={setIsAnonymitySettings}
        onClose={() => setIsAnonymitySettings(false)}
        refresh={refresh}
      />
    </SettingsSectionContent>
  );
};
