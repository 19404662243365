import { isToday as isTodayDateNfs } from 'date-fns';

export function dateDiffInDays(dateA: Date, dateB: Date): number {
  const MsPerDay = 86400000; // 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());

  return Math.floor((utc1 - utc2) / MsPerDay);
}

export const dateIncludedInLastXHours = (date: Date, X: number): Boolean => {
  const now = Math.round(new Date().getTime() / 1000);
  const nowMinusXH = now - X * 3600;
  return new Date(date).getTime() >= new Date(nowMinusXH * 1000).getTime();
};

export const isToday = (date: Date) => {
  return isTodayDateNfs(date);
};
