import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const CheckboxIcon = styled('span')(() => ({
  borderRadius: radius.br5,
  width: 12,
  height: 12,
  border: `1px solid ${themeColors.Grey}`,
  'input:hover ~ &': {
    borderColor: themeColors.DarkGrey,
    transition: 'border-color 0.4s',
  },
  'input:disabled ~ &': {
    borderColor: themeColors.middleGrey,
  },
}));

const CheckedIcon = styled(CheckboxIcon)({
  backgroundColor: themeColors.DarkGrey,
  borderColor: themeColors.DarkGrey,
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    `<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill-rule='evenodd' clip-rule='evenodd' d='M4.91634 8.26438L10.6219 1.67285L11.378 2.32732L4.96529 9.73579L0.644531 5.36808L1.35545 4.66481L4.91634 8.26438Z' fill='white'/>
    </svg>`
  )}")`,
  'input:hover ~ &': {
    backgroundColor: '#111',
    transition: 'background-color 0.1s',
  },
  'input:disabled ~ &': {
    borderColor: themeColors.middleGrey,
    backgroundColor: themeColors.middleGrey,
  },
});

type CheckboxComponentProps = Partial<CheckboxProps> &
  Partial<FormControlLabelProps> & {
    checkboxSx?: SxProps<Theme>;
    label?: string | React.ReactNode;
    labelSx?: SxProps<Theme>;
    labelTooltip?: string | JSX.Element;
    description?: string;
  };

export const CheckboxComponent = ({
  checked,
  checkboxSx,
  disabled,
  onChange,
  name,
  label,
  labelPlacement,
  labelSx,
  labelTooltip,
  description,
  sx,
}: CheckboxComponentProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            checkedIcon={<CheckedIcon />}
            icon={<CheckboxIcon />}
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            sx={{ p: 0, m: 0, marginInlineEnd: spacing.m10, ...checkboxSx }}
          />
        }
        disabled={disabled}
        labelPlacement={labelPlacement}
        label={
          typeof label === 'string' ? (
            <StyledTooltip title={(!disabled && labelTooltip) || ''}>
              <Typography variant="caption" color={disabled ? 'Grey' : 'DarkGrey'} sx={labelSx}>
                {label}
              </Typography>
            </StyledTooltip>
          ) : (
            label
          )
        }
        sx={{ m: 0, ...sx }}
      />
      {description && (
        <Typography
          variant="caption"
          color="Grey"
          sx={{
            ...themeFonts.tooltip,
            ml: '25px',
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};
