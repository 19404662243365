import type { ZeltDocument } from '@/lib/documents';
import type { CurrentUser, Scope } from '@/models';
import type { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import type { DeviceProtectionDetailsDto } from '@/v2/feature/device/device.dto';
import type { MissingField } from '@/v2/feature/user/user.interface';
import type { Alert } from '@/v2/infrastructure/alert/alert.interface';
import type { TaskDto } from '@v2/feature/task/task.dto';
import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import type { PaymentDto } from '@v2/feature/payments/payments.dto';
import { RequestAlertDto } from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import { ContractorInvoice, DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import { AccountStatus } from '@/lib/users';
import { ExtendedScope } from './models/scopes.model';

export enum GlobalStateActions {
  UPDATE_USER = 'UPDATE_USER',
  LOG_OUT = 'LOG_OUT',
  UPDATE_HIDDEN_FEATURE = 'UPDATE_HIDDEN_FEATURE',
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  HIDE_MESSAGE = 'HIDE_MESSAGE',
  UPDATE_INSPECTOR = 'UPDATE_INSPECTOR',
  UPDATE_DRAWER = 'UPDATE_DRAWER',
  UPDATE_ALERTS = 'UPDATE_ALERTS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  UPDATE_TEST_MODE = 'UPDATE_TEST_MODE',
  UPDATE_TRIAL_MODE = 'UPDATE_TRIAL_MODE',
  UPDATE_SHOW_SETTING_BAR = 'UPDATE_SHOW_SETTING_BAR',
}

export type MessageType = 'success' | 'info' | 'warning' | 'error';
export type MessagePosition = 'center' | 'top-right';

export interface Message {
  readonly message: string;
  readonly type: MessageType;
  readonly position: MessagePosition;
}

export interface TrialDetails {
  enabled: boolean;
  endDate?: string;
  lapsed: boolean;
}

export interface GlobalContextState {
  readonly user: CurrentUser;
  readonly showHiddenFeatures: boolean | null;
  readonly loggedOut: boolean;
  readonly message: readonly Message[];
  readonly inspectorMode: boolean;
  readonly paymentFailed: boolean;
  readonly isTestModeEnabled: boolean;
  readonly trialMode: TrialDetails;
  readonly drawerState: boolean;
  readonly showSettingBar: boolean;
  readonly alerts: {
    // TODO: DELETE THIS IF IT IS NOT USED ANYMORE
    readonly tasks?: Alert<TaskDto>;
    readonly documents?: Alert<ZeltDocument>;
    readonly devices?: Alert<DeviceProtectionDetailsDto>;
    readonly absences?: Alert<AbsenceDto>;
    readonly people?: Alert<MissingField>;
    readonly payments?: Alert<PaymentDto>;
    readonly contractorInvoices?: Alert<ContractorInvoice>;
    readonly pension?: Alert<string>;
    readonly salary?: Alert<number>;
    readonly apps?: Alert<RequestAlertDto>;
  };
}

export type Action =
  | {
      readonly type: GlobalStateActions.UPDATE_USER;
      readonly payload: GlobalContextState['user'];
    }
  | {
      readonly type: GlobalStateActions.LOG_OUT;
      readonly payload: GlobalContextState['loggedOut'];
    }
  | {
      readonly type: GlobalStateActions.UPDATE_HIDDEN_FEATURE;
      readonly payload: GlobalContextState['showHiddenFeatures'];
    }
  | {
      readonly type: GlobalStateActions.SHOW_MESSAGE;
      readonly payload: GlobalContextState['message'][number];
    }
  | {
      readonly type: GlobalStateActions.UPDATE_INSPECTOR;
      readonly payload: GlobalContextState['inspectorMode'];
    }
  | {
      readonly type: GlobalStateActions.PAYMENT_FAILED;
      readonly payload: GlobalContextState['paymentFailed'];
    }
  | {
      readonly type: GlobalStateActions.HIDE_MESSAGE;
    }
  | {
      readonly type: GlobalStateActions.UPDATE_DRAWER;
      readonly payload: GlobalContextState['drawerState'];
    }
  | {
      readonly type: GlobalStateActions.UPDATE_ALERTS;
      readonly payload: Partial<GlobalContextState['alerts']>;
    }
  | {
      readonly type: GlobalStateActions.UPDATE_TEST_MODE;
      readonly payload: Partial<GlobalContextState['isTestModeEnabled']>;
    }
  | {
      readonly type: GlobalStateActions.UPDATE_TRIAL_MODE;
      readonly payload: GlobalContextState['trialMode'];
    }
  | {
      readonly type: GlobalStateActions.UPDATE_SHOW_SETTING_BAR;
      readonly payload: GlobalContextState['showSettingBar'];
    };

const reducer = (state: GlobalContextState, action: Action): GlobalContextState => {
  switch (action.type) {
    case GlobalStateActions.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GlobalStateActions.LOG_OUT:
      return {
        ...state,
        loggedOut: action.payload,
      };
    case GlobalStateActions.UPDATE_HIDDEN_FEATURE:
      return {
        ...state,
        showHiddenFeatures: action.payload,
      };
    case GlobalStateActions.SHOW_MESSAGE:
      return {
        ...state,
        message: [...state.message, action.payload],
      };
    case GlobalStateActions.UPDATE_INSPECTOR:
      return {
        ...state,
        inspectorMode: action.payload,
      };
    case GlobalStateActions.PAYMENT_FAILED:
      return {
        ...state,
        paymentFailed: action.payload,
      };
    case GlobalStateActions.HIDE_MESSAGE:
      return {
        ...state,
        message: [],
      };
    case GlobalStateActions.UPDATE_DRAWER:
      return {
        ...state,
        drawerState: action.payload,
      };
    case GlobalStateActions.UPDATE_ALERTS:
      return {
        ...state,
        alerts: { ...state.alerts, ...action.payload },
      };
    case GlobalStateActions.UPDATE_TEST_MODE:
      return {
        ...state,
        isTestModeEnabled: action.payload,
      };
    case GlobalStateActions.UPDATE_TRIAL_MODE:
      return {
        ...state,
        trialMode: action.payload,
      };
    case GlobalStateActions.UPDATE_SHOW_SETTING_BAR:
      return {
        ...state,
        showSettingBar: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const GlobalStateProvider = ({
  children,
  initialState,
}: {
  readonly children: ReactNode;
  readonly initialState: GlobalContextState;
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <GlobalContext.Provider value={[state, dispatch]}>{children}</GlobalContext.Provider>;
};

const initialState: GlobalContextState = {
  user: {
    userId: 0,
    emailAddress: '',
    firstName: '',
    lastName: '',
    language: 'en',
    accountType: {
      ProfileOwner: true,
      Admin: false,
      OtherProfiles: false,
      Manager: false,
    },
    contractType: '',
    accountStatus: AccountStatus.Created,
    company: { name: '', companyId: 0 },
    scopes: [] as Scope[],
    scopes2: [] as ExtendedScope[],
    reports: [] as number[],
    mfaType: null,
    currency: DEFAULT_CURRENCY,
    currentPlans: {
      MONEY: { pro: false },
      PEOPLE: { pro: false },
      APPS: { pro: false },
      DEVICES: { pro: false },
      TECH: {
        pro: false,
      },
      GROWTH: {
        pro: false,
      },
    },
    restrictions: {
      APPS: {
        disableAppCreation: true,
      },
      PEOPLE: {
        // disablePermissionGroupCreation: true,
        // disableTemplateCreation: true,
        // disableInvitingMoreThanLimitedUsers: true,
        // disableCreatingMoreThanLimitedReviewForms: true,
        disableAbsencePolicyCreation: false,
        disableAttendancePolicyCreation: false,
        disableOnboardingTemplateCreation: false,
        disableChecklistCreation: false,
        disableDocTemplateCreation: false,
        disableRequestTemplateCreation: false,
      },
      DEVICES: {
        disablePasscode: true,
        disableEncryption: true,
        disableLocationHistory: true,
        disableUpdates: true,
        disableManageApps: true,
        disableICloudPolicies: true,
        disableFirewall: true,
      },
      MONEY: {
        // disableAddingToPayroll: true,
        disableExpenseTypeCreation: false,
        disableBenefitTypeCreation: false,
        disableInvoiceTypeCreation: false,
        disablePayroll: false,
      },
      TECH: {
        disableEnrollDevices: false,
        disableApps: false,
      },
      GROWTH: {
        disableReviewCycleCreation: false,
        disableSurveyCycleCreation: false,
      },
    },
    hasPaymentMethodOnFile: false,
    features: null,
    navigation: null,
  },
  loggedOut: true,
  showHiddenFeatures: false,
  message: [],
  inspectorMode: false,
  paymentFailed: false,
  drawerState: false,
  isTestModeEnabled: false,
  trialMode: { enabled: false, lapsed: false },
  showSettingBar: false,
  alerts: {
    apps: undefined,
    tasks: undefined,
    documents: undefined,
    devices: undefined,
    absences: undefined,
    people: undefined,
    payments: undefined,
    contractorInvoices: undefined,
    salary: undefined,
    pension: undefined,
  },
} as const;

export const GlobalContext = createContext<[GlobalContextState, React.Dispatch<Action>]>([initialState, () => {}]);

/** @deprecated use `useContext(GlobalContext)` instead */
export const useGlobalContext = () => useContext(GlobalContext);
