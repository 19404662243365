import * as React from 'react';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto, AbsencePolicyTenureAllocation } from '@v2/feature/absence/absence.dto';
import { AbsencePolicyAllowanceType, PolicyAllowanceTenureData } from '@v2/feature/absence/absence.interface';
import { getAbsencePolicyTenureAllocationOptions, isHourlyPolicy } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyTenureEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [localRules, setLocalRules] = useState<[number, string][]>([]);

  useEffect(() => {
    if (isOpen)
      setLocalRules(
        absencePolicy.tenureAdditionSettings
          ? Object.keys(absencePolicy.tenureAdditionSettings).map((year) => [
              Number(year),
              String(absencePolicy.tenureAdditionSettings![Number(year)] ?? 0),
            ])
          : [[1, '0']]
      );
  }, [absencePolicy, isOpen]);

  const onSubmit = useCallback(
    async (values: PolicyAllowanceTenureData) => {
      const policyData: PolicyAllowanceTenureData = {
        tenureEnabled: true,
        tenureAdditionSettings: values.tenureAdditionSettings,
        tenureAllocation: values.tenureAllocation,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyTenure(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage]
  );

  const formik = useFormik<PolicyAllowanceTenureData>({
    initialValues: {
      tenureEnabled: true,
      tenureAdditionSettings: absencePolicy.tenureAdditionSettings ? absencePolicy.tenureAdditionSettings : {},
      tenureAllocation: absencePolicy.tenureAllocation ?? AbsencePolicyTenureAllocation.Anniversary,
    },
    validationSchema: yup.object({
      tenureEnabled: yup.boolean().required(polyglot.t('ValidationMessages.requiredField')),
      tenureAdditionSettings: yup.object().when('tenureEnabled', {
        is: true,
        then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
      tenureAllocation: yup
        .string()
        .oneOf(Object.values(AbsencePolicyTenureAllocation), polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('PolicyTenureEditDrawer.tenureBonus')}</Typography>
          <Typography variant="caption">{polyglot.t('PolicyTenureEditDrawer.tenureBonusDesc')}</Typography>

          <SelectComponent
            name="tenureAllocation"
            label={polyglot.t('AbsenceModule.tenureAllocation')}
            options={getAbsencePolicyTenureAllocationOptions(polyglot)}
            value={formik.values.tenureAllocation ?? AbsencePolicyTenureAllocation.Anniversary}
            onChange={formik.handleChange}
            compareValue={formik.values.tenureAllocation}
            error={!!formik.errors.tenureAllocation && formik.touched.tenureAllocation}
            helperText={formik.touched.tenureAllocation && (formik.errors.tenureAllocation as string)}
          />

          <Box>
            <Typography variant="captionSmall" sx={{ mb: '5px' }}>
              {polyglot.t('AbsenceModule.tenureAllocationRules')}
            </Typography>

            <TenureRules
              allowanceType={absencePolicy.allowanceType}
              localRules={localRules}
              setLocalRules={setLocalRules}
            />
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
              onClick={() => {
                const objectRules = localRules.reduce((acc, [year, units]) => {
                  acc[year] = Number.parseFloat(units);
                  return acc;
                }, {} as Record<number, number>);

                formik.setFieldValue('tenureAdditionSettings', objectRules);

                formik.handleSubmit();
              }}
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};

const TenureRules = ({
  allowanceType,
  localRules,
  setLocalRules,
}: {
  readonly allowanceType: AbsencePolicyAllowanceType;
  readonly localRules: [number, string][];
  readonly setLocalRules: React.Dispatch<React.SetStateAction<[number, string][]>>;
}) => {
  const { polyglot } = usePolyglot();

  const isHourly = isHourlyPolicy({ allowanceType });
  const handleRemoveRule = (indexToRemove: number) => {
    setLocalRules([...(localRules ?? [])].filter((_, index) => indexToRemove !== index));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {localRules.map((item, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextfieldComponent
            label={polyglot.t('PolicyTenureEditDrawer.afterMonths')}
            name={`localRules[${index}][0]`}
            value={item[0]}
            onChange={(e) => {
              const reg = new RegExp(/^\d+$/);

              if (e.target.value && !reg.test(e.target.value)) return;

              setLocalRules((prev) => {
                const newYear = Number(e.target.value);
                const updatedArray = [...prev];
                updatedArray[index][0] = newYear;
                return updatedArray;
              });
            }}
          />

          <TextfieldComponent
            label={
              isHourly
                ? polyglot.t('PolicyTenureEditDrawer.addExtraHours')
                : polyglot.t('PolicyTenureEditDrawer.addExtraDays')
            }
            name={`localRules[${index}][1]`}
            value={item[1]}
            onChange={(e) => {
              const reg = new RegExp(/^\d*\.?\d*$/);
              if (e.target.value && !reg.test(e.target.value)) return;

              setLocalRules((prev) => {
                const newYear = e.target.value;
                const updatedArray = [...prev];
                updatedArray[index][1] = newYear;
                return updatedArray;
              });
            }}
          />

          {localRules.length > 1 && (
            <IconButton
              sizeVariant="small"
              colorVariant="secondary"
              onClick={() => handleRemoveRule(index)}
              title={polyglot.t('General.remove')}
              style={{ marginTop: '14px' }}
            >
              <TrashIcon {...iconSize} />
            </IconButton>
          )}
        </Box>
      ))}

      <ButtonComponent
        sizeVariant="small"
        colorVariant="secondary"
        onClick={() => {
          setLocalRules((prev) => [...prev, [0, '0']]);
        }}
      >
        {polyglot.t('PolicyTenureEditDrawer.addRule')}
      </ButtonComponent>
    </Box>
  );
};
