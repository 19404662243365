import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary, keyBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/fields/Dropdown.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REVIEWS_ME_INVITE_ROUTE } from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReviewAnswerAPI } from '@/v2/feature/growth/reviews/api-client/review-answer.api';
import { ReviewEntryEndpoints } from '@/v2/feature/growth/reviews/api-client/review-entry.api';
import { MultipleChoiceQuestion } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/multiple-choice-question.component';
import { OpenEndedQuestion } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/open-ended-question.components';
import { ScaleQuestion } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/scale-question.component';
import { SingleAnswerQuestion } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/single-answer-question.component';
import {
  getReviewDueDate,
  getReviewType,
} from '@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util';
import { ReviewAnswer, UpsertReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewCycle, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { EntryDetail, EntryState, ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { QuestionType, ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewSection } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';
import { CycleState, OrderPreference } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type UpdateAnswer = { updatedAnswer: string | null; comment: string | null };

export const ReviewAnswerSubmissionPage = () => {
  const params = useParams<{ entryId: string; cycleId: string }>();
  const { entryId, cycleId } = params;
  const [answerArray, setAnswerArray] = useState<UpsertReviewAnswer[] | null | undefined>(null);
  const [answers, setAnswers] = useState<ReviewAnswer[] | undefined>(undefined);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

  const { data: entryDetail, isValidating: loadingEntryDetail } = useApiClient(
    ReviewEntryEndpoints.getReviewEntryDetail(entryId, cycleId),
    {
      suspense: false,
    }
  );

  const getAnswersData = useCallback(async () => {
    try {
      const response = await ReviewAnswerAPI.getAnswer(entryId, cycleId);
      setAnswers(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  }, [entryId, cycleId, showMessage]);

  useEffect(() => {
    getAnswersData();
  }, [getAnswersData]);

  const checkForScroll = () => {
    if (containerRef.current) {
      const isContentOverflowing = containerRef.current.scrollHeight > window.innerHeight;
      setShowScrollButton(isContentOverflowing);
    }
  };

  const handleScrollClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
      setShowScrollButton(false);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      setShowScrollButton(!isAtBottom);
    }
  };

  useEffect(() => {
    if (entryDetail && entryDetail.cycle && entryDetail.cycle.state === CycleState.Completed) {
      routerHistory.push(REVIEWS_ME_INVITE_ROUTE);
      showMessage('This cycle has already been completed', 'info');
    }
  }, [entryDetail, showMessage, routerHistory]);

  useEffect(() => {
    if (answers) {
      setAnswerArray(answers);
    }
  }, [answers]);

  useEffect(() => {
    setTimeout(() => checkForScroll(), 1500);
    window.addEventListener('resize', checkForScroll);
    return () => window.removeEventListener('resize', checkForScroll);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  });

  let cycle: EntryDetail['cycle'] | undefined;
  let sections: EntryDetail['sections'] | undefined;
  let questions: EntryDetail['questions'] | undefined;
  let entry: EntryDetail['entry'] | undefined;

  if (entryDetail) {
    ({ cycle, sections, questions, entry } = entryDetail);
  }
  const questionsLookup = useMemo(() => keyBy(questions ?? [], 'id'), [questions]);
  const reviewType = useMemo(() => (entry ? getReviewType(entry) : undefined), [entry]);
  const isSubmitable = useMemo(() => {
    if (!questions || !answerArray) {
      return false;
    }

    const allQuestionsAnswered = questions.every((q) => {
      const answer = answerArray.find((a) => a.questionId === q.id);
      if (!answer) return false;

      if (q.type === 'openEnded') {
        return answer && answer.comment && answer.comment?.length > 0;
      } else {
        return answer && answer.answer && answer.answer?.length > 0;
      }
    });

    const allCommentsValid = questions.every((question) => {
      if (question.isCommentRequired) {
        const answer = answerArray.find((a) => a.questionId === question.id);
        return answer && answer.comment && answer.comment.length > 0;
      }
      return true;
    });
    return allQuestionsAnswered && allCommentsValid;
  }, [answerArray, questions]);

  if (!entry || !cycle || !questions) return <></>;

  const handleAnswerChange = (questionId: string, updateObject: UpdateAnswer, entry: ReviewEntry) => {
    setAnswerArray((prevAnswers) => {
      const updatedAnswers =
        prevAnswers?.map((answer) => {
          if (answer.questionId === questionId) {
            return { ...answer, answer: updateObject.updatedAnswer, comment: updateObject.comment };
          }
          return answer;
        }) || [];

      if (!prevAnswers?.some((answer) => answer.questionId === questionId)) {
        updatedAnswers.push({
          id: v4(),
          cycleId,
          entryId,
          reviewId: entry.reviewId,
          reviewerId: (entry.reviewerUpwardId ||
            entry.reviewerManagerId ||
            entry.reviewerPeerId ||
            entry.reviewerSelfId) as number,
          answerType: getReviewType(entry) as ReviewerTypes,
          revieweeId: entry.revieweeId,
          comment: updateObject.comment,
          answer: updateObject.updatedAnswer,
          questionId: questionId,
        });
      }

      return updatedAnswers;
    });
  };

  const handleSaveDraft = async () => {
    try {
      if (answerArray && entry) {
        await ReviewAnswerAPI.saveAnswersAsDraft(answerArray, entryId, cycleId, entry.revieweeId);
        await getAnswersData();
        showMessage('Successfully saved as draft', 'success');
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const handleSubmit = async () => {
    try {
      if (isSubmitable && answerArray && entry) {
        await ReviewAnswerAPI.submitAnswers(answerArray, entryId, cycleId, entry.revieweeId);
        routerHistory.push(REVIEWS_ME_INVITE_ROUTE);
        showMessage('Successfully submitted the answers', 'success');
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  };

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2">{entryDetail?.cycle?.displayName || entryDetail?.cycle.internalName}</Typography>
        }
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
            {entryDetail?.entry.entryState !== EntryState.Submitted && (
              <ButtonComponent colorVariant="secondary" sizeVariant="small" onClick={() => handleSaveDraft()}>
                Save
              </ButtonComponent>
            )}
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={() => handleSubmit()}
              disabled={!isSubmitable}
            >
              {entryDetail?.entry.entryState === EntryState.Submitted ? 'Resubmit' : 'Submit'}
            </ButtonComponent>
          </Box>
        }
        showAction={cycle.state !== CycleState.Completed}
        sx={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}
        showBack={true}
        backPath={REVIEWS_ME_INVITE_ROUTE}
      />
      <ContentWrapper
        loading={loadingEntryDetail}
        ref={containerRef}
        sx={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 120px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            overflowY: 'hidden',
            flexDirection: 'column',
            gap: spacing.g16,
            maxWidth: '600px',
            width: '100%',
            margin: '0 auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
            <ViewItem
              label="Due date"
              value={getReviewDueDate({ ...entry, cycle: cycle })
                .getDate()
                .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
            />

            {(reviewType === ReviewerTypes.Manager ||
              reviewType === ReviewerTypes.Upward ||
              reviewType === ReviewerTypes.Peer) && (
              <ViewItem label="You are reviewing" value={<UserCell userId={entry.revieweeId} />} />
            )}
          </Box>

          {entryDetail?.cycle.order.map((item, idx) => (
            <RenderQuestionOrSection
              key={`answer-${idx}`}
              item={item}
              questionsLookup={questionsLookup}
              handleAnswerChange={handleAnswerChange}
              reviewType={reviewType}
              sections={sections}
              cycle={cycle}
              entry={entry}
              answerArray={answerArray}
            />
          ))}

          {showScrollButton && (
            <IconButton
              colorVariant="primary"
              sizeVariant="small"
              onClick={handleScrollClick}
              style={{ position: 'fixed', bottom: '20px', right: '20px', textAlign: 'center' }}
            >
              <ArrowDown {...iconSize} />
            </IconButton>
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};

const RenderQuestionOrSection = ({
  item,
  questionsLookup,
  handleAnswerChange,
  reviewType,
  sections,
  cycle,
  entry,
  answerArray,
}: {
  item: OrderPreference;
  questionsLookup: Dictionary<ReviewQuestion>;
  handleAnswerChange: (questionId: string, updateObject: UpdateAnswer, entry: ReviewEntry) => void;
  reviewType: ReviewerTypes | undefined;
  sections: ReviewSection[] | undefined;
  cycle: Pick<ReviewCycle, 'id' | 'order' | 'internalName' | 'visibilitySettings'> | undefined;
  entry: ReviewEntry | undefined;
  answerArray: UpsertReviewAnswer[] | null | undefined;
}) => {
  const renderQuestion = (questionId: string) => {
    const question = questionsLookup && questionsLookup[questionId];

    if (!question || !cycle) return null;
    return (
      <QuestionComponent
        key={question.id}
        question={question}
        reviewType={reviewType}
        answerArray={answerArray}
        handleAnswerChange={handleAnswerChange}
        entry={entry}
        cycle={cycle}
      />
    );
  };

  const renderSection = (sectionId: string) => {
    const section = sections?.find((s) => s.id === sectionId);
    if (!section || !cycle) return null;

    const orderSection = cycle?.order.find((o) => o.id === sectionId && o.type === 'section');
    if (!orderSection) return null;

    const orderedQuestions = (orderSection.questions ?? [])
      .map((questionId) => questionsLookup[questionId])
      .filter((question): question is ReviewQuestion => Boolean(question));

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16 }}>
        <Typography variant="title2">{section.name}</Typography>
        {orderedQuestions.map((q) => (
          <QuestionComponent
            key={q.id}
            question={q}
            reviewType={reviewType}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
            cycle={cycle}
          />
        ))}
      </Box>
    );
  };
  const renderQuestionOrSection = () => {
    if (item.type === 'question' && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === 'section' && (item.questions ?? []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return <Box sx={{ marginTop: spacing.m48 }}>{renderSection(item.id)}</Box>;
    } else return <></>;
  };

  return <>{renderQuestionOrSection()}</>;
};

const QuestionComponent = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  cycle,
}: {
  question: ReviewQuestion;
  reviewType: ReviewerTypes | undefined;
  answerArray: UpsertReviewAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: ReviewEntry) => void;
  entry: ReviewEntry | undefined;
  cycle: Pick<ReviewCycle, 'id' | 'order' | 'internalName' | 'visibilitySettings'>;
}) => {
  const questionText = useMemo(() => {
    if (!reviewType || !question) return '';
    if (reviewType === ReviewerTypes.Self) return question.questionSelf;

    return question.questionMain;
  }, [reviewType, question]);

  const showHiddenInformation = useMemo(() => {
    if (!cycle || !question || reviewType === ReviewerTypes.Self) return false;
    return true;
  }, [cycle, question, reviewType]);

  const showHiddenInformationTest = useMemo(() => {
    if (!cycle || !question || reviewType === ReviewerTypes.Self || !entry) return <></>;

    const questionVisibility =
      (question.visibilitySettings.hideManagerResult && reviewType === ReviewerTypes.Manager) ||
      (question.visibilitySettings.hidePeerResult && reviewType === ReviewerTypes.Peer) ||
      (question.visibilitySettings.hideUpwardResult && reviewType === ReviewerTypes.Upward);

    if (questionVisibility) {
      return (
        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm, width: 'auto' }}>
          Your answer will not be shared with <UserCell userId={entry.revieweeId} />
        </Typography>
      );
    }

    if (cycle.visibilitySettings.hidePeerAuthor && reviewType === ReviewerTypes.Peer) {
      return (
        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm, width: 'auto' }}>
          <span>Your answer will be shared with </span>
          <span>
            <UserCell userId={entry.revieweeId} />
          </span>
          <span>without showing your name</span>
        </Typography>
      );
    }

    return (
      <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: spacing.sm }}>
        Your answer will be shared with <UserCell userId={entry.revieweeId} />
      </Typography>
    );
  }, [cycle, question, reviewType, entry]);

  const getQuestionByType = (question: ReviewQuestion) => {
    switch (question.type) {
      case QuestionType.SingleAnswer:
        return (
          <SingleAnswerQuestion
            question={question}
            reviewType={reviewType}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      case QuestionType.OpenEnded:
        return (
          <OpenEndedQuestion
            question={question}
            reviewType={reviewType}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      case QuestionType.MultipleAnswer:
        return (
          <MultipleChoiceQuestion
            question={question}
            reviewType={reviewType}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      case QuestionType.ScaleQuestion:
        return (
          <ScaleQuestion
            question={question}
            reviewType={reviewType}
            answerArray={answerArray}
            handleAnswerChange={handleAnswerChange}
            entry={entry}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.s2,
        padding: spacing.p16,
        border: borders.background,
        borderRadius: radius.br8,
      }}
    >
      <Typography variant="title4" dangerouslySetInnerHTML={{ __html: questionText ?? '' }} />
      {showHiddenInformation && (
        <ChipComponent
          name={showHiddenInformationTest}
          backgroundColor="white"
          border="background"
          style={{ width: 'fit-content' }}
        />
      )}
      {getQuestionByType(question)}
    </Box>
  );
};

const ViewItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: '2fr 2fr',
        rowGap: spacing.g24,
        columnGap: spacing.g4,
        width: '100%',
      }}
    >
      <Typography variant="caption" sx={{ color: themeColors.Grey }}>
        {label}
      </Typography>
      <Typography variant="caption">{value}</Typography>
    </Box>
  );
};
