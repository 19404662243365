import { Redirect, Route, Switch } from 'react-router-dom';

import {
  USER_BENEFITS_CUSTOM_BENEFIT_ROUTE,
  USER_BENEFITS_INSURANCE_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_PENSION_TAB,
} from '@/lib/routes';
import { BenefitsUserOverviewPage } from '@v2/feature/benefits/benefits-user-overview.page';
import { useCallback, useEffect, useState } from 'react';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import useMessage from '@/hooks/notification.hook';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { nestErrorMessage } from '@/lib/errors';
import { useUserProfileConfig } from '@v2/feature/user/features/user-profile/user-profile.hook';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import useScopes from '@/hooks/scopes.hook';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { RouteScopesControl } from '@/component/widgets/Scopes';
import { UserPensionRouter } from '@/component/dashboard/userDetails/pension/user-pension.router';
import { Scope } from '@/models';
import { InsuranceUserRouter } from '@v2/feature/benefits/subfeature/insurance/insurance-user.router';
import { CustomBenefitUserRouter } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit-user.router';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

interface Props {
  readonly userId: number;
  readonly pensionScopes: readonly Scope[];
  readonly handleUpdate: () => Promise<void>;
}

export const UserBenefitsRouter = ({ userId, pensionScopes, handleUpdate }: Props): React.JSX.Element => {
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionOwnerScope = hasScopes(['pension'], scopesContext);
  const hasInsuranceOwnerScope = hasScopes(['insurance'], scopesContext);

  const { data: userBenefits, isLoading: loadingCustomBenefits, mutate: refreshUserCBenefits } = useApiClient(
    hasInsuranceOwnerScope ? CustomBenefitEndpoints.allEffectiveUserBenefitsByUserId(userId) : null,
    {
      suspense: false,
    }
  );

  const [employeePension, setEmployeePension] = useState<UserPensionDto | null>(null);
  const [userInsurance, setUserInsurance] = useState<UserInsuranceDto | null>(null);

  const [showMessage] = useMessage();
  const [loadingPension, setLoadingPension] = useState<boolean>(false);
  const [loadingInsurance, setLoadingInsurance] = useState<boolean>(false);

  const { userOverviewConfig, refreshUserOverviewConfig } = useUserProfileConfig(userId);

  const autoEnrollmentCallback = useCallback(async () => {
    if (!hasPensionOwnerScope) return;
    try {
      setLoadingPension(true);
      const [employeePension] = await Promise.all([
        PensionAPI.getEmployeePension(userId),
        await refreshUserOverviewConfig(),
      ]);

      setEmployeePension(employeePension ?? null);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoadingPension(false);
    }
  }, [hasPensionOwnerScope, userId, refreshUserOverviewConfig, showMessage]);

  const fetchUserInsurance = useCallback(async () => {
    if (!hasInsuranceOwnerScope) return;
    try {
      setLoadingInsurance(true);
      const userInsurance = await InsuranceAPI.getUserInsurancePolicyById(userId, true);
      setUserInsurance(userInsurance);
    } catch (error) {
      showMessage(`Could not get insurance policy details. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoadingInsurance(false);
    }
  }, [hasInsuranceOwnerScope, userId, showMessage]);

  const refreshCustomBenefits = useCallback(async () => {
    if (refreshUserCBenefits) await refreshUserCBenefits();
  }, [refreshUserCBenefits]);

  useEffect(() => {
    if (userOverviewConfig?.inPension) {
      autoEnrollmentCallback();
    }
  }, [autoEnrollmentCallback, userOverviewConfig?.inPension]);

  useEffect(() => {
    fetchUserInsurance();
  }, [fetchUserInsurance]);

  return (
    <Switch>
      {userOverviewConfig?.contractType !== 'Contractor' && (
        <RouteScopesControl scopes={pensionScopes} context={scopesContext} path={USER_PENSION_TAB}>
          <UserPensionRouter userId={userId} onUpdate={handleUpdate} />
        </RouteScopesControl>
      )}

      <RouteScopesControl scopes={['insurance']} context={scopesContext} path={USER_BENEFITS_INSURANCE_ROUTE}>
        <InsuranceUserRouter
          userId={userId}
          userInsurance={userInsurance}
          refresh={fetchUserInsurance}
          loading={loadingInsurance}
        />
      </RouteScopesControl>

      {userOverviewConfig.inCustomBenefits && (
        <RouteScopesControl scopes={['insurance']} context={scopesContext} path={USER_BENEFITS_CUSTOM_BENEFIT_ROUTE}>
          <CustomBenefitUserRouter
            userId={userId}
            userBenefits={userBenefits ?? []}
            loading={!!loadingCustomBenefits}
            refreshCustomBenefits={refreshCustomBenefits}
          />
        </RouteScopesControl>
      )}

      <Route exact path={USER_BENEFITS_OVERVIEW_ROUTE}>
        <BenefitsUserOverviewPage
          userId={userId}
          userPension={employeePension}
          userInsurance={userInsurance}
          userBenefits={userBenefits ?? []}
          userOverviewConfig={userOverviewConfig}
          loading={loadingInsurance || loadingPension || !!loadingCustomBenefits}
        />
      </Route>

      <Redirect to={USER_BENEFITS_OVERVIEW_ROUTE} />
    </Switch>
  );
};
