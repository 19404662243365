import React from 'react';

import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { borders } from '@v2/styles/borders.styles';
import { themeColors } from '@v2/styles/colors.styles';

interface ChipProps {
  readonly name: string | JSX.Element;
  readonly onClick?: () => void;
  readonly textColor?: keyof typeof themeColors;
  readonly backgroundColor?: keyof typeof themeColors;
  readonly border?: keyof typeof borders;
  readonly textVariant?: TypographyVariant;
  readonly style?: React.CSSProperties;
}

export const ChipComponent = ({
  name,
  onClick,
  textColor = 'Grey',
  backgroundColor = 'Background',
  border,
  textVariant = 'caption',
  style = {},
}: ChipProps) => {
  const chipStyle = {
    display: 'inline-block',
    padding: '2px 6px',
    borderRadius: '25px',
    backgroundColor: themeColors[backgroundColor],
    cursor: onClick ? 'pointer' : 'default',
    border: border ? borders[border] : undefined,
    ...style,
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div style={chipStyle} onClick={handleClick}>
      <Typography variant={textVariant} color={textColor}>
        {name}
      </Typography>
    </div>
  );
};
