import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { ApprovalStep, RequestToBeApproved } from '@v2/feature/approval-rule/approval-rule.interface';
import { CachedUser } from '@v2/feature/user/context/cached-users.context';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

export const canApproveOrRejectRequest = (request: RequestToBeApproved, currentUserId: number): boolean => {
  const firstIncompleteStep = request.approverSteps.find((step) => !step.isComplete);
  if (!firstIncompleteStep) return false;

  // Check if current user is not in the list of step approvers
  if (!firstIncompleteStep.approversIds?.includes(currentUserId)) return false;

  // Check if current user has not approved already
  if (!request.approvedByIds || request.approvedByIds.includes(currentUserId)) return false;

  // Check if current user has not rejected already
  if (!request.rejectedByIds || request.rejectedByIds.includes(currentUserId)) return false;

  // if included in the list of approvers, and has not approved or rejected already the request, allow
  return true;
};

export const getApprovalStepSchema = (polyglot: Polyglot) =>
  yup.object({
    adminApproval: yup
      .boolean()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    managerApproval: yup
      .boolean()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    managerManagerApproval: yup
      .boolean()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    specificApprovers: yup
      .array()
      .of(yup.number().integer())
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    minApprovers: yup
      .number()
      .min(1, polyglot.t('ValidationMessages.validValue'))
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .integer(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
  });

/**
 * Determines if an approval step is set to auto-approve.
 *
 * @param {ApprovalStep} approvalStep - The approval step to check.
 * @return {boolean} - True if the approval step is set to auto-approve, false otherwise.
 */
function isApprovalStepAutoApprove(approvalStep: ApprovalStep): boolean {
  if (!approvalStep) return false;

  const isSpecificUserApproval = approvalStep.specificApprovers && approvalStep.specificApprovers.length > 0;

  return (
    !approvalStep.adminApproval &&
    !approvalStep.managerApproval &&
    !approvalStep.managerManagerApproval &&
    !isSpecificUserApproval
  );
}

/**
 * Determines if an approval rule is set to auto-approve.
 *
 * @param {ApprovalRuleDto} approvalRule - The approval rule to check.
 * @return {boolean} - True if the approval rule is set to auto-approve, false otherwise.
 */
export function isApprovalRuleAutoApprove(approvalRule: ApprovalRuleDto): boolean {
  if (!approvalRule) return false;
  return approvalRule.approvalSteps.every((approvalStep) => isApprovalStepAutoApprove(approvalStep));
}

export const getStepDescription = (
  step: ApprovalStep,
  getCachedUserById: (userId: number) => CachedUser | undefined,
  polyglot: Polyglot,
  showItems = -1 // <= 0 to show all | > 0 - number of items to show before "and X others"
): string => {
  const rules = [
    step.adminApproval ? polyglot.t('ApproverSelectComponent.Admin') : null,
    step.managerApproval ? polyglot.t('ApproverSelectComponent.Manager') : null,
    step.managerManagerApproval ? polyglot.t('ApproverSelectComponent.ManagersManager') : null,
    ...(step.specificApprovers.length > 0
      ? step.specificApprovers.map((userId) => {
          const user = getCachedUserById(userId);
          return user ? polyglot.t(user.displayName ?? `${user.firstName} ${user.lastName}`) : null;
        })
      : []),
  ].filter(Boolean);

  if (rules.length === 1)
    return polyglot.t('ApprovalRuleModule.xApprovalsNeededFrom', {
      smart_count: step.minApprovers,
      approversList: rules[0],
    });

  if (showItems > 0 && rules.length > showItems) {
    const shortArray = rules.slice(0, showItems);
    const xOthers = polyglot.t('ApprovalRuleModule.andXOthers', { smart_count: rules.length - showItems });
    const approversList = [shortArray.join(', '), polyglot.t('General.and'), xOthers].join(' ');

    return polyglot.t('ApprovalRuleModule.xApprovalsNeededFrom', {
      smart_count: step.minApprovers,
      approversList: approversList,
    });
  }

  const lastItem = rules.pop();
  const approversList =
    rules.length > 0 ? [rules.join(', '), polyglot.t('General.and'), lastItem].join(' ') : [lastItem];

  return polyglot.t('ApprovalRuleModule.xApprovalsNeededFrom', {
    smart_count: step.minApprovers,
    approversList: approversList,
  });
};
