import React, { useCallback } from 'react';

import { HolidayCalendarRouter } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar-details.router';
import { HolidayCalendarEndpoints } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import { HolidayCalendarsListPage } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/pages/holiday-calendars-list.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE, SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE } from '@/lib/routes';

export const AbsenceSettingsPublicHolidaysRouter = (): React.JSX.Element => {
  const {
    data: holidayCalendars,
    mutate: refreshHolidayCalendars,
    isLoading,
  } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });

  const refresh = useCallback(async () => {
    if (refreshHolidayCalendars) await refreshHolidayCalendars();
  }, [refreshHolidayCalendars]);

  return (
    <Switch>
      <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE}>
        <HolidayCalendarRouter refreshAll={refresh} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE}>
        <HolidayCalendarsListPage holidayCalendars={holidayCalendars ?? []} refresh={refresh} isLoading={!!isLoading} />
      </RouteScopesControl>

      <Redirect to={SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE} />
    </Switch>
  );
};
