import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { isHourlyPolicy } from '@v2/feature/absence/absence.util';
import {
  absenceAdjustmentsImportValidationSchema,
  absenceImportValidationSchema,
} from '@v2/feature/absence/subfeatures/absence-import/absence-import.util';
import { AttendanceScheduleDto, AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceImportDto } from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import { getAttendanceImportValidationSchema } from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.util';
import { CompanyUnitDto } from '@v2/feature/company/company-settings/features/company-settings.dto';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { kebabCase } from 'lodash';
import Polyglot from 'node-polyglot';
import { v4 } from 'uuid';

import {
  CreateUserContract,
  TimeUnitTypes,
  UserContractKinds,
  UserContractTypes,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import {
  UserAddressSchemaForImport,
  UserBankAccountFormSchemaForImport,
  UserCompensationSchemaForImport,
  UserContractImportSchema,
  UserEquitySchemaForImport,
  UserLifecycleSchemaForImport,
} from '@/component/dashboard/userDetails/validations/userFormValidations';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { UserRoleImportValues } from '@/models/user-role.model';
import { OptionObject } from '@/v2/components/forms/select.component';
import {
  AbsenceAdjustmentImportDto,
  AbsenceImportDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import {
  CustomProfileFieldDto,
  CustomProfileFormDto,
  UserCustomDataDto,
} from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { deviceImportValidationSchema } from '@/v2/feature/device/features/devices-import/device-import.interface';
import { DeviceImportDto } from '@/v2/feature/device/features/devices-import/devices-import.dto';
import { EntityImportErrorDto, EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import {
  mapAbsenceAdjustmentsImportToZelt,
  mapAbsenceImportToZelt,
  mapDeviceImportToZelt,
  mapUserImportToZelt,
  sanitizeUserImportRecord,
} from '@/v2/feature/entity-import/wizard/entity-import-mapper.util';
import {
  UserImportResultDomain,
  UserImportResultTabsLabels,
} from '@/v2/feature/entity-import/wizard/import-wizard.interface';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { CreateUserAddressDto } from '@/v2/feature/user/features/user-forms/user-address/user-address.dto';
import { CreateUserBankAccountDto } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import {
  PayScheduleEnum,
  SalaryBasisEnum,
  UserCompensationDto,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { UserEmergencyContactDto } from '@/v2/feature/user/features/user-forms/user-emergency-contact/user-emergency-contact.dto';
import {
  UserImport2Dto,
  UserImportDto,
  UserImportResultDto,
} from '@/v2/feature/user/features/user-import/user-import.dto';
import { BasicInformationSchema } from '@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-form.component';
import { PersonalInformationSchemaForImport } from '@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-form.component';
import { RoleSchemaForImport } from '@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component';
import {
  AboutValues,
  BasicInformationValues,
  FAMILY_INFORMATION_MARITAL,
  FamilyValues,
  PERSONAL_INFORMATION_GENDERS,
  PersonalInformationValues,
  UserImportLifecycleValues,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { isDefined } from '@/v2/feature/user-onboarding/user-onboarding.util';
import { ValidationError } from '@/v2/infrastructure/api-error/api-error.interface';
import { CountryCodes } from '@/v2/infrastructure/country/country.interface';
import { dmyToIsoDateString } from '@/v2/infrastructure/date/date-format.util';
import { caseInsensitiveFind } from '@/v2/util/array.util';
import { fixedNumber } from '@/v2/util/number.util';

function getYupValidationErrors(
  err: { inner: any[] },
  domain: UserImportResultDomain | 'absence' | 'device' | 'attendances' | 'absenceAdjustments',
  fieldsToCheck?: string[]
) {
  const validationErrors: ValidationError[] = [];

  err.inner?.forEach((error: { value: any; path: string | number; message: any }) => {
    if (
      (error.path && fieldsToCheck && fieldsToCheck.length > 0 && fieldsToCheck?.includes(String(error.path))) ||
      (error.path && (!fieldsToCheck || fieldsToCheck.length === 0))
    ) {
      validationErrors.push({
        property: error.path.toString(),
        value: error.value,
        constraints: { [error.path]: error.message },
        domain,
      });
    }
  });

  return validationErrors;
}

function validationSchemaForDomain(domain: UserImportResultDomain, polyglot: Polyglot) {
  let chosenSchema;
  switch (domain) {
    case 'Basic':
      chosenSchema = BasicInformationSchema;
      break;

    case 'Details':
      chosenSchema = PersonalInformationSchemaForImport(polyglot);
      break;

    case 'Address':
      chosenSchema = UserAddressSchemaForImport(polyglot);
      break;

    case 'Role':
      chosenSchema = RoleSchemaForImport;
      break;

    case 'Lifecycle':
      chosenSchema = UserLifecycleSchemaForImport;
      break;

    case 'Salary':
      chosenSchema = UserCompensationSchemaForImport(polyglot);
      break;

    case 'Bank account':
      chosenSchema = UserBankAccountFormSchemaForImport(polyglot);
      break;

    case 'Equity':
      chosenSchema = UserEquitySchemaForImport;
      break;

    case 'Contract':
      chosenSchema = UserContractImportSchema(polyglot);
      break;

    // @TODO - should delete this soon - Will be eventually removed since Zinc integration is underway
    // case 'Right to work':
    //   chosenSchema = RightToWorkSchema;
    //   break;

    default:
      break;
  }

  return chosenSchema;
}

const allFieldsForDomainEmpty = (recordToCheck: UserImportDto, fieldsToCheck: string[]) => {
  const fieldsMissingData = new Set();
  const EMPTY_VALUES = [null, undefined, ''];
  fieldsToCheck.forEach((a: string) => {
    if (!recordToCheck[a] || (recordToCheck[a] && EMPTY_VALUES.includes(recordToCheck[a]))) {
      fieldsMissingData.add(a);
    }
  });
  return fieldsMissingData.size === fieldsToCheck.length;
};

const isFieldRequired = (domainSchema: { fields: { [key: string]: any } }, field: string) => {
  return domainSchema?.fields[field]?.exclusiveTests?.required || false;
};

const errorOccurredInFieldsToCheck = (err: any, fieldsToCheck: string[]) => {
  // only fields to check; i.e. required fields - fieldsExcludedFromCheck, in Yup schema should report back errors
  // other errors should be ignored
  return (
    err.inner.length > 0 && err.inner.some((eachError: { path: string }) => fieldsToCheck.includes(eachError.path))
  );
};

const validatePeopleDomain = async (
  domain: UserImportResultDomain,
  ongoingResult: EntityImportErrorDto<UserImportDto>,
  polyglot: Polyglot
): Promise<EntityImportErrorDto<UserImportDto>> => {
  const domainSchema = validationSchemaForDomain(domain, polyglot);
  const fieldsToExcludeFromCheck = ['effectiveDate', 'salaryBasis', 'currency', 'country'];
  // fields to check should include required fields OR fields that have values in the CSV
  const fieldsToCheck = domainSchema
    ? Object.keys(domainSchema?.fields).filter(
        (field) =>
          !fieldsToExcludeFromCheck.includes(field) &&
          (isFieldRequired(domainSchema, field) ||
            (!isFieldRequired(domainSchema, field) && isDefined(ongoingResult.entity[field])))
      ) // remove effectiveDate from fieldsToCheck, as this will always get startDate
    : [];
  let result: EntityImportErrorDto<UserImportDto> = ongoingResult;
  if (fieldsToCheck && fieldsToCheck.length > 0 && !allFieldsForDomainEmpty(ongoingResult.entity, fieldsToCheck)) {
    // skip domain validation check if no data for that domain exists
    try {
      if (domainSchema) await domainSchema.validate(ongoingResult.entity, { abortEarly: false });
    } catch (err: any) {
      if (errorOccurredInFieldsToCheck(err, fieldsToCheck)) {
        const domainErrors = getYupValidationErrors(err, domain, fieldsToCheck);
        result.errors = [...result.errors, ...domainErrors];
      }
    }
  }
  return result;
};

const validateAbsenceDomain = async (
  ongoingResult: EntityImportErrorDto<AbsenceImportDto>,
  policy: AbsencePolicyDto
): Promise<void> => {
  try {
    const isHourly = isHourlyPolicy(policy);
    const hasHourSet = !!ongoingResult.entity.startHour;
    await absenceImportValidationSchema(isHourly || hasHourSet).validate(ongoingResult.entity, { abortEarly: false });
  } catch (err: any) {
    const domainErrors = getYupValidationErrors(err, 'absence');
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
};

const validateAbsenceAdjustmentsDomain = async (
  ongoingResult: EntityImportErrorDto<AbsenceAdjustmentImportDto>
): Promise<void> => {
  try {
    await absenceAdjustmentsImportValidationSchema().validate(ongoingResult.entity, { abortEarly: false });
  } catch (err: any) {
    const domainErrors = getYupValidationErrors(err, 'absenceAdjustments');
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
};

export const reValidatePeopleImportResult = async (
  importResult: UserImportResultDto,
  departments: OptionObject[],
  sites: OptionObject[],
  polyglot: Polyglot
): Promise<EntityImportValidationResultDto<UserImportDto>> => {
  let ongoingResult: EntityImportErrorDto<UserImportDto>;
  const allValidations: EntityImportErrorDto<UserImportDto>[] = [];
  const domainToSkipVerification = ['Right to work'];
  const domainsToVerify = UserImportResultTabsLabels.filter((domain) => !domainToSkipVerification.includes(domain));
  const records = importResult.errors;
  for (const eachRecord of records) {
    const recordForValidation = mapUserImportToZelt(
      sanitizeUserImportRecord(eachRecord.entity),
      departments,
      sites,
      undefined
    );
    ongoingResult = {
      entity: recordForValidation,
      errors: [...eachRecord.errors],
    };
    for (const eachDomain of domainsToVerify) {
      ongoingResult = await validatePeopleDomain(eachDomain, ongoingResult, polyglot);
    }
    allValidations.push(ongoingResult);
  }
  return {
    errors: allValidations,
  };
};

export const validateDeviceDomain = async (
  ongoingResult: EntityImportErrorDto<DeviceImportDto>
): Promise<EntityImportErrorDto<DeviceImportDto>> => {
  let result: EntityImportErrorDto<DeviceImportDto> = ongoingResult;
  try {
    await deviceImportValidationSchema.validate(ongoingResult.entity, { abortEarly: false });
  } catch (err: any) {
    const domainErrors = getYupValidationErrors(err, 'device');
    result.errors = [...result.errors, ...domainErrors];
  }
  return result;
};

const getUserNotFoundError = (
  entity: UserImportDto | AbsenceImportDto | AttendanceImportDto | AbsenceAdjustmentImportDto,
  domain: string,
  property = 'workEmail',
  userNotFoundMessage = 'This user does not exist in your company'
): ValidationError => {
  return {
    target: entity,
    property,
    value: 'Missing user',
    constraints: {
      userNotFound: userNotFoundMessage,
    },
    domain,
    id: entity.id,
  };
};

const getPolicyNotFoundError = (
  entity: AbsenceImportDto | AbsenceAdjustmentImportDto,
  domain: string,
  property = 'policyName',
  notFoundMessage = 'Policy not found'
): ValidationError => {
  return {
    target: entity,
    property,
    value: 'Missing policy',
    constraints: {
      policyNotFound: notFoundMessage,
    },
    domain,
    id: entity.id,
  };
};

export const validateAbsenceImport = async (
  rawLocalData: AbsenceImportDto[],
  allUsersIncludingTerminated: readonly CachedUser[],
  absencePolicies: readonly AbsencePolicyDto[],
  validateIndexOnly: number = -1
): Promise<EntityImportValidationResultDto<AbsenceImportDto>> => {
  let ongoingResult: EntityImportErrorDto<AbsenceImportDto>;
  const allValidations: EntityImportErrorDto<AbsenceImportDto>[] = [];
  for (let i = 0; i < rawLocalData.length; i++) {
    const eachRecord = rawLocalData[i];
    const matchingZeltUser = allUsersIncludingTerminated.find(
      (u) => u.emailAddress === eachRecord.workEmail?.toLowerCase()
    );
    const policy = absencePolicies.find((p) => p.fullName === eachRecord.policyName);

    // if update a specific value only (from drawer), skip validation of all the others that are not opened in the drawer now
    if (validateIndexOnly >= 0 && i !== validateIndexOnly) {
      ongoingResult = {
        entity: eachRecord,
        errors: !matchingZeltUser
          ? [getUserNotFoundError(eachRecord, 'absence')]
          : !policy
          ? [getPolicyNotFoundError(eachRecord, 'absence')]
          : [],
      };
      if (policy) await validateAbsenceDomain(ongoingResult, policy);

      allValidations.push(ongoingResult);
    } else {
      const recordForValidation = mapAbsenceImportToZelt(eachRecord, policy, matchingZeltUser);

      ongoingResult = {
        entity: recordForValidation,
        errors: !matchingZeltUser
          ? [getUserNotFoundError(eachRecord, 'absence')]
          : !policy
          ? [getPolicyNotFoundError(eachRecord, 'absence')]
          : [],
      };
      if (policy) await validateAbsenceDomain(ongoingResult, policy);

      allValidations.push(ongoingResult);
    }
  }

  return {
    errors: allValidations,
  };
};

export const validateAbsenceAdjustmentsImport = async (
  rawLocalData: AbsenceAdjustmentImportDto[],
  allUsersIncludingTerminated: readonly CachedUser[],
  absencePolicies: readonly AbsencePolicyDto[],
  validateIndexOnly: number = -1
): Promise<EntityImportValidationResultDto<AbsenceAdjustmentImportDto>> => {
  let ongoingResult: EntityImportErrorDto<AbsenceAdjustmentImportDto>;
  const allValidations: EntityImportErrorDto<AbsenceAdjustmentImportDto>[] = [];
  for (let i = 0; i < rawLocalData.length; i++) {
    const eachRecord = rawLocalData[i];
    const matchingZeltUser = allUsersIncludingTerminated.find(
      (u) => u.emailAddress === eachRecord.workEmail?.toLowerCase()
    );
    const policy = absencePolicies.find((p) => p.fullName === eachRecord.policyName);

    // if update a specific value only (from drawer), skip validation of all the others that are not opened in the drawer now
    if (validateIndexOnly >= 0 && i !== validateIndexOnly) {
      ongoingResult = {
        entity: eachRecord,
        errors: !matchingZeltUser
          ? [getUserNotFoundError(eachRecord, 'absenceAdjustments')]
          : !policy
          ? [getPolicyNotFoundError(eachRecord, 'absenceAdjustments')]
          : [],
      };
      await validateAbsenceAdjustmentsDomain(ongoingResult);

      allValidations.push(ongoingResult);
    } else {
      const recordForValidation = mapAbsenceAdjustmentsImportToZelt(eachRecord, policy, matchingZeltUser);

      ongoingResult = {
        entity: recordForValidation,
        errors: !matchingZeltUser
          ? [getUserNotFoundError(eachRecord, 'absenceAdjustments')]
          : !policy
          ? [getPolicyNotFoundError(eachRecord, 'absenceAdjustments')]
          : [],
      };
      await validateAbsenceAdjustmentsDomain(ongoingResult);

      allValidations.push(ongoingResult);
    }
  }

  return {
    errors: allValidations,
  };
};

export const validateDeviceImport = async (
  rawLocalData: DeviceImportDto[]
): Promise<EntityImportValidationResultDto<DeviceImportDto>> => {
  let ongoingResult: EntityImportErrorDto<DeviceImportDto>;
  const allValidations: EntityImportErrorDto<DeviceImportDto>[] = [];
  for (const eachRecord of rawLocalData) {
    const recordForValidation = mapDeviceImportToZelt(eachRecord);
    ongoingResult = {
      entity: recordForValidation,
      errors: [],
    };
    ongoingResult = await validateDeviceDomain(ongoingResult);
    allValidations.push(ongoingResult);
  }
  return {
    errors: allValidations,
  };
};

export const validateAttendanceImport = async (
  rawLocalData: AttendanceImportDto[],
  allUsersIncludingTerminated: readonly CachedUser[],
  attendanceTypes: readonly AttendanceTypeDto[]
): Promise<EntityImportValidationResultDto<AttendanceImportDto>> => {
  const allValidations: EntityImportErrorDto<AttendanceImportDto>[] = [];
  for (const eachRecord of rawLocalData) {
    const recordForValidation = mapAttendanceImportToZelt(eachRecord, allUsersIncludingTerminated, attendanceTypes);

    const ongoingResult: EntityImportErrorDto<AttendanceImportDto> = {
      entity: recordForValidation,
      errors: !recordForValidation.userId ? [getUserNotFoundError(eachRecord, 'attendances')] : [],
    };
    await validateAttendanceDomain(ongoingResult, attendanceTypes);

    allValidations.push(ongoingResult);
  }

  return {
    errors: allValidations,
  };
};

const validateAttendanceDomain = async (
  ongoingResult: EntityImportErrorDto<AttendanceImportDto>,
  attendanceTypes: readonly AttendanceTypeDto[]
): Promise<void> => {
  try {
    await getAttendanceImportValidationSchema(attendanceTypes).validate(ongoingResult.entity, { abortEarly: false });
  } catch (err: any) {
    const domainErrors = getYupValidationErrors(err, 'attendances');
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
};

export const mapAttendanceImportToZelt = (
  record: AttendanceImportDto,
  allUsersIncludingTerminated: readonly CachedUser[],
  attendanceTypes: readonly AttendanceTypeDto[]
): AttendanceImportDto => {
  const zeltUser = allUsersIncludingTerminated.find((u) => u.emailAddress === record.workEmail?.toLowerCase());
  const attendanceType = attendanceTypes.find((p) => p.name === record.attendanceType);

  return {
    ...record,
    id: record.id ?? v4(), // add id only if it is not already set
    workEmail: zeltUser ? zeltUser.emailAddress : record.workEmail.toLowerCase().trim(),
    userId: zeltUser ? zeltUser.userId : undefined,
    attendanceType: attendanceType?.name ?? record.attendanceType?.trim() ?? '',
  };
};

const validatePeopleImportRecord2 = async (
  entity: UserImport2Dto,
  polyglot: Polyglot
): Promise<EntityImportErrorDto<UserImport2Dto>> => {
  const result: EntityImportErrorDto<UserImport2Dto> = {
    entity,
    errors: [],
  };

  const schemas: { name: UserImportResultDomain; form: any }[] = [
    { name: 'Basic', form: entity.default.Basic.values },
    { name: 'Details', form: entity.default.Details.values },
    { name: 'Family', form: entity.default.Family.values },
    { name: 'About', form: entity.default.About.values },
    { name: 'Lifecycle', form: entity.default.Lifecycle.values },
    { name: 'Role', form: entity.default.Role.values },
    { name: 'Contract', form: entity.default.Contract.values },
    { name: 'Salary', form: entity.default.Salary.values },
    { name: 'Bank account', form: entity.default.BankAccount.values },
    { name: 'Address', form: entity.default.Address.values },
    { name: 'Emergency', form: entity.default.Emergency.values },
  ];

  for (const { name, form } of schemas) {
    const domainSchema = validationSchemaForDomain(name, polyglot);
    try {
      if (domainSchema && form) {
        await domainSchema.validate(form, { abortEarly: false });
      }
    } catch (err: any) {
      const domainErrors = getYupValidationErrors(err, name);
      result.errors = [...result.errors, ...domainErrors];
    }
  }

  return result;
};

export const validatePeopleImport2 = async (
  records: UserImport2Dto[],
  polyglot: Polyglot
): Promise<EntityImportValidationResultDto<UserImport2Dto>> => {
  return {
    errors: await Promise.all(records.map((record) => validatePeopleImportRecord2(record, polyglot))),
  };
};

const convertPeopleRecordToUserImport2 = (
  record: Record<string, string>,
  users: readonly CachedUser[],
  departments: CompanyDepartmentDto[],
  sites: SiteDto[],
  companyEntities: CompanyUnitDto[],
  attendanceSchedules: AttendanceScheduleDto[],
  customForms: CustomProfileFormDto[],
  getCustomFieldsForForm: (formName: string) => CustomProfileFieldDto[],
  polyglot: Polyglot
) => {
  const textValue = <T extends string, X>(key: string, defaultValue: X) => (record[key] ?? defaultValue) as T | X;
  // const intValue = <X extends number | null | undefined>(key: string, defaultValue: X): number | X =>
  //   /^\d+/.test(record[key]) ? parseInt(record[key], 10) : defaultValue;
  const numberValue = (key: string, defaultValue = 0) =>
    /^\d+(\.\d*)?/.test(record[key]) ? parseFloat(record[key]) : defaultValue;
  const optionalNumber = (key: string) => parseFloat(record[key]) || undefined;
  const moneyValue = (key: string, defaultValue = 0) => fixedNumber(record[key], 2) || defaultValue;
  const currencyValue = (key: string) => record[key]?.toUpperCase();
  const booleanValue = <T extends boolean | null | undefined>(key: string, defaultValue: T) => {
    if (/^(y|yes|t|true)$/i.test(record[key])) return true;
    if (/^(n|no|f|false)$/i.test(record[key])) return false;
    return defaultValue;
  };
  const dateValue = <X>(key: string, defaultValue: X): string | X => {
    const value = record[key];
    if (!value) return defaultValue;
    // try and convert the date from ddmmyyyy format or assume it's already in yyyy-mm-dd format
    return dmyToIsoDateString(value) ?? value;
  };
  const optionValue = <T extends readonly string[], X extends T[number] | null | undefined>(
    key: string,
    values: T,
    defaultValue: X
  ): T[number] | X => {
    const importValue = record[key];
    return caseInsensitiveFind(values, importValue, (item) => item) ?? defaultValue;
  };
  const userIdValue = (key: string) => {
    return caseInsensitiveFind(users, record[key], (item) => item.emailAddress)?.userId;
  };
  const departmentIdValue = (key: string) => {
    return caseInsensitiveFind(departments, record[key], (item) => item.name)?.id;
  };
  const siteIdValue = (key: string) => {
    return caseInsensitiveFind(sites, record[key], (item) => item.name)?.id;
  };
  const companyEntityIdValue = (key: string) => {
    return caseInsensitiveFind(companyEntities, record[key], (item) => item.legalName)?.id ?? null;
  };
  const attendanceScheduleIdValue = (key: string) => {
    return caseInsensitiveFind(attendanceSchedules, record[key], (item) => item.name)?.id ?? null;
  };
  const hasSection = (sectionId: string) =>
    Object.entries(record).some(([key, value]) => key.startsWith(`${sectionId}.`) && value);

  const getImportedCustomFieldsForForm = (formName: string) => {
    return getCustomFieldsForForm(formName).reduce((arr, customField) => {
      const importFieldName = `${formName}.${kebabCase(customField.fieldName)}`;
      if (Object.prototype.hasOwnProperty.call(record, importFieldName)) {
        arr.push({
          fieldId: customField.fieldId,
          formId: customField.formId,
          field: customField,
          value: ['Date picker', 'Date input', 'Date'].includes(customField.fieldType)
            ? dateValue(importFieldName, '')
            : textValue(importFieldName, ''),
        });
      }
      return arr;
    }, [] as UserCustomDataDto[]);
  };

  const userEmailAddress = textValue('basic.work-email', '');
  const cachedUser = caseInsensitiveFind(users, userEmailAddress, (entry) => entry.emailAddress);

  const basic: BasicInformationValues = {
    firstName: textValue('basic.first-name', cachedUser?.firstName ?? ''),
    lastName: textValue('basic.last-name', cachedUser?.lastName ?? ''),
    middleName: textValue('basic.middle-name', null),
    displayName: textValue('basic.display-name', undefined),
    emailAddress: textValue('basic.work-email', ''),
    customUpdates: getImportedCustomFieldsForForm('basic'),
  };

  const details: PersonalInformationValues | null = hasSection('details')
    ? {
        dob: dateValue('details.date-of-birth', null),
        gender: optionValue('details.gender', PERSONAL_INFORMATION_GENDERS, null),
        nationality: textValue('details.nationality', null),
        personalEmail: textValue('basic.personal-email', null), // basic not details
        phone: textValue('details.phone', null),
        passportNumber: textValue('details.passport-no', null),
        customUpdates: getImportedCustomFieldsForForm('details'),
      }
    : null;

  const family: FamilyValues | null = hasSection('family')
    ? {
        maritalStatus: optionValue('family.marital-status', FAMILY_INFORMATION_MARITAL, null),
        customUpdates: getImportedCustomFieldsForForm('family'),
      }
    : null;

  const about: AboutValues | null = hasSection('about')
    ? {
        about: textValue('about.overview', null),
        hobbies: textValue('about.hobbies', null),
        social: textValue('about.linkedin', null),
        foodPreferences: textValue('about.favourite-food', null),
        dietaryRestrictions: textValue('about.dietary-restrictions', null),
        customUpdates: getImportedCustomFieldsForForm('about'),
      }
    : null;

  const lifecycle: UserImportLifecycleValues | null = hasSection('lifecycle')
    ? {
        startDate: dateValue('lifecycle.start-date', ''),
        leaveDate: dateValue('lifecycle.leave-date', undefined),
        changeReason: textValue('lifecycle.change-reason', ''),
        customUpdates: getImportedCustomFieldsForForm('lifecycle'),
      }
    : null;

  const role: UserRoleImportValues | null = hasSection('role')
    ? {
        effectiveDate: dateValue('role.effective', ''),
        jobTitle: textValue('role.job-title', ''),
        jobPositionId: null,
        departmentId: departmentIdValue('role.department'),
        siteId: siteIdValue('role.site'),
        managerId: userIdValue('role.manager'),
        managerEmail: textValue('role.manager', ''),
        customUpdates: getImportedCustomFieldsForForm('role'),
      }
    : null;

  const contract: CreateUserContract | null = hasSection('contract')
    ? {
        effectiveDate: dateValue('contract.effective', ''),
        contract: optionValue(
          'contract.kind',
          UserContractKinds(polyglot).map((c) => c.value),
          'Full-time'
        ),
        type: optionValue(
          'contract.type',
          UserContractTypes(polyglot).map((c) => c.value),
          'Employee'
        ),
        entityId: companyEntityIdValue('contract.entity'),
        ftePercent: numberValue('contract.fte-percent', 100),
        publicHolidays: optionValue('contract.holiday-calendar', CountryCodes, null),
        noticePeriodLength: optionalNumber('contract.notice-period-length'),
        noticePeriodUnit: optionValue(
          'contract.notice-period-unit',
          TimeUnitTypes(polyglot).map((c) => c.value),
          undefined
        ),
        probationPeriodLength: optionalNumber('contract.probation-period-length'),
        probationPeriodUnit: optionValue(
          'contract.probation-period-unit',
          TimeUnitTypes(polyglot).map((c) => c.value),
          undefined
        ),
        attendanceScheduleId: attendanceScheduleIdValue('contract.attendance-schedule'),
        contractEndDate: dateValue('contract.end-date', null),
        customUpdates: getImportedCustomFieldsForForm('contract'),
      }
    : null;

  const salary: UserCompensationDto | null = hasSection('salary')
    ? {
        effectiveDate: dateValue('salary.effective', ''),
        paySchedule: optionValue('salary.pay-schedule', Object.values(PayScheduleEnum), PayScheduleEnum.Monthly),
        salaryBasis: optionValue('salary.basis', Object.values(SalaryBasisEnum), SalaryBasisEnum.Annual),
        rate: moneyValue('salary.rate'),
        currency: (currencyValue('salary.currency') ?? null) as CurrencyShort | null,
        prorateSalaryByFte: booleanValue('salary.prorate-by-fte', false),
        customUpdates: getImportedCustomFieldsForForm('salary'),
      }
    : null;

  const bankAccount: CreateUserBankAccountDto | null = hasSection('bank-account')
    ? {
        effectiveDate: dateValue('bank-account.effective', ''),
        accountName: textValue('bank-account.account-holder-name', ''),
        accountNumber: textValue('bank-account.account-number/iban', ''),
        sortCode: textValue('bank-account.sort-code/bic/swift', ''),
        bankName: textValue('bank-account.bank-name', ''),
        country: textValue('bank-account.country', null),
        currency: currencyValue('bank-account.currency'),
        customUpdates: getImportedCustomFieldsForForm('bank-account'),
      }
    : null;

  const address: CreateUserAddressDto | null = hasSection('address')
    ? {
        effectiveDate: dateValue('address.effective', ''),
        addressLine1: textValue('address.line-1', ''),
        addressLine2: textValue('address.line-2', ''),
        city: textValue('address.city', ''),
        postcode: textValue('address.postcode', ''),
        country: textValue('address.country', undefined),
        customUpdates: getImportedCustomFieldsForForm('address'),
      }
    : null;

  const emergency: UserEmergencyContactDto | null = hasSection('emergency')
    ? {
        emergencyName: textValue('emergency.name', null),
        emergencyNumber: textValue('emergency.number', null),
        emergencyRelationship: textValue('emergency.relationship', null),
        customUpdates: getImportedCustomFieldsForForm('emergency'),
      }
    : null;

  const sectionNameToCustomForm = new Map(
    customForms
      .filter((f) => !f.isDefault)
      .map((f) => [
        kebabCase(f.formName),
        {
          tabName: f.formName,
          formId: f.formId,
          fields: getCustomFieldsForForm(f.formName).map<UserCustomDataDto>((field) => ({
            fieldId: field.fieldId,
            formId: field.formId,
            field,
            value: '',
          })),
        },
      ])
  );

  for (const [key, value] of Object.entries(record)) {
    const [sectionID, fieldName] = key.split('.');
    const entry = sectionNameToCustomForm.get(sectionID);
    if (!entry) continue;
    const matchedField = entry.fields.find((f) => kebabCase(f.field.fieldName) === fieldName);
    if (!matchedField) continue;
    matchedField.value = value;
  }

  const result: UserImport2Dto = {
    default: {
      Basic: {
        tabName: 'Basic',
        values: basic,
      },
      Details: {
        tabName: 'Details',
        values: details,
      },
      Family: {
        tabName: 'Family',
        values: family,
      },
      About: {
        tabName: 'About',
        values: about,
      },
      Role: {
        tabName: 'Role',
        values: role,
      },
      Contract: {
        tabName: 'Contract',
        values: contract,
      },
      Lifecycle: {
        tabName: 'Lifecycle',
        values: lifecycle,
      },
      Salary: {
        tabName: 'Salary',
        values: salary,
      },
      BankAccount: {
        tabName: 'Bank account',
        values: bankAccount,
      },
      Address: {
        tabName: 'Address',
        values: address,
      },
      Emergency: {
        tabName: 'Emergency contact',
        values: emergency,
      },
    },
    custom: [...sectionNameToCustomForm.values()].filter((x) => x.fields.some((f) => f.value)),
  };

  return result;
};

export const convertPeopleCSVToUserImport2 = (
  records: Record<string, string>[],
  users: readonly CachedUser[],
  departments: CompanyDepartmentDto[],
  sites: SiteDto[],
  companyEntities: CompanyUnitDto[],
  attendanceSchedules: AttendanceScheduleDto[],
  customForms: CustomProfileFormDto[],
  getCustomFieldsForForm: (formName: string) => CustomProfileFieldDto[],
  polyglot: Polyglot
) => {
  return records.map((record) =>
    convertPeopleRecordToUserImport2(
      record,
      users,
      departments,
      sites,
      companyEntities,
      attendanceSchedules,
      customForms,
      getCustomFieldsForForm,
      polyglot
    )
  );
};
