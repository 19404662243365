import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface FilledFieldProps {
  readonly label: string;
  readonly value: string;
  readonly mask?: boolean;
}

export const CopyableTextField = ({ label, value, mask }: FilledFieldProps) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(String(value));
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <Box sx={{ display: 'block' }}>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }}>{label}</Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: spacing.g20,
          pb: spacing.p10,
          borderBottom: '1px solid lightgrey',
        }}
      >
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, mt: spacing.m10 }}>
          {mask ? '*'.repeat(value.length) : value}
        </Typography>
        <Box sx={{ cursor: 'pointer' }}>
          {copied ? (
            <Chose {...iconSize} />
          ) : (
            <Copy {...iconSize} style={{ fill: themeColors.Grey }} onClick={handleClick} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
