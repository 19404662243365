import { useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Reject } from '@/images/side-bar-icons/Reject.svg';
import { nestErrorMessage } from '@/lib/errors';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortDate } from '@/v2/components/table/table-sorting.util';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { convertMinutesToHHMM } from '@/v2/feature/absence/absence.util';
import { AttendanceAPI } from '@/v2/feature/attendance/attendance.api';
import { UserAttendanceTableAndHeaderData, UserAttendanceTableData } from '@/v2/feature/attendance/attendance.dto';
import { AttendanceStatus } from '@/v2/feature/attendance/attendance.interface';
import { getAttendanceStatusIcon } from '@/v2/feature/attendance/attendance.util';
import { AttendanceEmptyState } from '@/v2/feature/attendance/company/components/empty-state/attendance-empty-state.component';
import { TrackTimeDrawer } from '@/v2/feature/attendance/company/components/track-time-drawer.component';
import { BadgeLoaderHeader } from '@/v2/feature/attendance/components/badge-loader-header.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx, tablePrimaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserAttendancesDetail = ({
  selectedUserAttendances,
  selectedUser,
  loading,
  view,
  refresh,
  rangeType,
  selectedDate,
}: {
  selectedUserAttendances: UserAttendanceTableAndHeaderData | undefined;
  selectedUser: number | undefined;
  loading: boolean;
  view: 'company' | 'team' | 'user';
  refresh: () => Promise<void>;
  rangeType: 'Month' | 'Week';
  selectedDate: Date;
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById, loaded } = useCachedUsers();
  const user = selectedUser ? getCachedUserById(selectedUser) : undefined;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<UserAttendanceTableData | null>(null);
  const [mode, setMode] = useState<'view' | 'edit' | undefined>(undefined);
  const [showMessage] = useMessage();

  const generateDynamicColumns = (
    data: UserAttendanceTableData[]
  ): ColumnDef<UserAttendanceTableData, UserAttendanceTableData>[] => {
    const uniqueNames = new Set();
    data.forEach((userData) => {
      userData.loggedHoursByType.forEach((entry) => {
        uniqueNames.add(entry.name);
      });
    });

    return Array.from(uniqueNames).map((name) => ({
      id: name as string,
      header: () => name,
      accessorFn: (row: Row<UserAttendanceTableData>) => row,
      enableSorting: false,
      cell: ({ row: { original } }: { row: Row<UserAttendanceTableData> }) => {
        const entry = original.loggedHoursByType.find((e) => e.name === name);
        const isPositive = entry?.differenceInLoggedAndScheduled && entry?.differenceInLoggedAndScheduled > 0;
        const isMoreOrLess = Boolean(
          entry?.differenceInLoggedAndScheduled && entry?.differenceInLoggedAndScheduled !== 0
        );

        return (
          <>
            {entry ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                <div>{convertMinutesToHHMM(entry.totalLength)}</div>
                {isMoreOrLess && (
                  <Typography variant="caption" sx={{ color: isPositive ? themeColors.Green : themeColors.Red }}>
                    {isPositive ? '+ ' : ''}
                    {convertMinutesToHHMM(entry?.differenceInLoggedAndScheduled)}h
                  </Typography>
                )}
              </div>
            ) : (
              <EmptyCell />
            )}
          </>
        );
      },
      maxSize: 120,
      minSize: 100,
    }));
  };

  const handleSelectedRequest = (request: UserAttendanceTableData, mode: 'edit' | 'view') => {
    setSelectedRequest(request);
    setMode(mode);
    setIsDrawerOpen(true);
  };

  const handleRowClick = useCallback(({ original }: Row<UserAttendanceTableData>) => {
    handleSelectedRequest(original, 'view');
  }, []);

  const columns = useMemo<ColumnDef<UserAttendanceTableData, UserAttendanceTableData>[]>(() => {
    const dateColumn: ColumnDef<UserAttendanceTableData, UserAttendanceTableData>[] = [
      {
        id: 'logDate',
        header: () => polyglot.t('AttendanceDomain.date'),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.logDate),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        cell: ({ row: { original } }) => (
          <div>{original.logDate ? getDateString(original.logDate) : <EmptyCell />}</div>
        ),
      },
    ];
    const staticColumns: ColumnDef<UserAttendanceTableData, UserAttendanceTableData>[] = [
      {
        header: () => polyglot.t('AttendanceDomain.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <>{original.status ? getAttendanceStatusIcon(original.status, false, polyglot) : <EmptyCell />}</>
        ),
        maxSize: 120,
        minSize: 100,
      },
      {
        header: () => polyglot.t('AttendanceDomain.schedule'),
        accessorFn: (row) => row,
        id: 'schedule',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>{original?.schedule?.name ? original?.schedule.name : <EmptyCell />}</div>
        ),
        maxSize: 120,
        minSize: 100,
      },
      {
        header: () => polyglot.t('AttendanceDomain.notes'),
        accessorFn: (row) => row,
        id: 'notes',
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{original.notes ? original.notes : <EmptyCell />}</div>,
        maxSize: 120,
        minSize: 100,
      },
      {
        header: () => ' ',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        maxSize: 100,
        cell: ({ row: { original } }) => {
          return (
            <RowActions
              row={original}
              canApproveOrReject={original.canApproveOrReject}
              onEdit={() => handleSelectedRequest(original, 'edit')}
              refresh={refresh}
            />
          );
        },
      },
    ];
    return selectedUserAttendances
      ? [...dateColumn, ...generateDynamicColumns(selectedUserAttendances?.data), ...staticColumns]
      : [...dateColumn, ...staticColumns];
  }, [polyglot, selectedUserAttendances, refresh]);

  const isLoading = useMemo(() => Boolean(loading || !loaded), [loaded, loading]);
  const isSubmittedAttendance = useMemo(
    () =>
      Boolean(
        selectedUserAttendances?.data?.some(
          (a) => a.status === AttendanceStatus.InProgress || a.status === AttendanceStatus.Submitted
        )
      ),
    [selectedUserAttendances?.data]
  );

  const handleApproveAll = async () => {
    try {
      if (selectedUser) {
        const update = { type: rangeType, date: new LocalDate(selectedDate).toDateString() };
        await AttendanceAPI.approveUsersRequestByWeekOrMonth(selectedUser, update);
        await refresh();
        showMessage(polyglot.t('AttendanceDomain.approvedAll'), 'success');
      }
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotApproveAll', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const handleRejectAll = async () => {
    try {
      if (selectedUser) {
        const update = { type: rangeType, date: new LocalDate(selectedDate).toDateString() };
        await AttendanceAPI.rejectUsersRequestByWeekOrMonth(selectedUser, update);
        await refresh();
        showMessage(polyglot.t('AttendanceDomain.rejectedAll'), 'success');
      }
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotRejectAll', { nestError: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  return (
    <Box sx={spacing.pl20}>
      <Box sx={spacing.pt20}>
        {selectedUserAttendances || isLoading ? (
          <>
            <AttendanceDetailHeader
              loading={isLoading}
              userId={selectedUser}
              user={user}
              selectedUserAttendances={selectedUserAttendances}
              handleApproveAll={handleApproveAll}
              handleRejectAll={handleRejectAll}
              isSubmittedAttendance={isSubmittedAttendance}
            />
            <Box sx={spacing.mt20}>
              <BasicTable
                rowData={selectedUserAttendances?.data ? [...selectedUserAttendances?.data] : []}
                columnData={columns}
                hidePagination={true}
                loading={isLoading}
                rowClick={handleRowClick}
              />
            </Box>
          </>
        ) : (
          <AttendanceEmptyState />
        )}

        <TrackTimeDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          userId={selectedRequest?.userId}
          attendanceId={selectedRequest?.id}
          refresh={refresh}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
          afterClose={() => {
            setSelectedRequest(null);
            setMode(undefined);
          }}
          mode={mode}
          view={view}
        />
      </Box>
    </Box>
  );
};

const AttendanceDetailHeader = ({
  loading,
  userId,
  user,
  selectedUserAttendances,
  handleApproveAll,
  handleRejectAll,
  isSubmittedAttendance,
}: {
  loading: boolean;
  userId: number | undefined;
  user: CachedUser | undefined;
  selectedUserAttendances: UserAttendanceTableAndHeaderData | undefined;
  handleApproveAll: () => Promise<void>;
  handleRejectAll: () => Promise<void>;
  isSubmittedAttendance: boolean;
}) => {
  const { polyglot } = usePolyglot();
  return (
    <Box sx={spacing.pt20}>
      {loading ? (
        <BadgeLoaderHeader />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g40 }}>
            {userId && user && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                <UserAvatar userId={userId} size="medium" key={userId} />
                <Box>
                  <Typography variant="title2">{polyglot.t(user.displayName)}</Typography>
                  {user.role?.jobTitle && <Typography variant="caption">{polyglot.t(user.role.jobTitle)}</Typography>}
                </Box>
              </Box>
            )}

            <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g40 }}>
              {selectedUserAttendances &&
                selectedUserAttendances?.header &&
                selectedUserAttendances.header.map((badge) => (
                  <Box>
                    <Typography variant="caption">{badge.type}</Typography>
                    <Typography variant="title2">{convertMinutesToHHMM(badge.totalLength)}h</Typography>
                  </Box>
                ))}
            </div>
          </div>

          {isSubmittedAttendance && (
            <StyledMenuComponent
              options={[
                {
                  icon: <Chose {...iconSize} />,
                  handler: async () => await handleApproveAll(),
                  label: polyglot.t('AttendanceDomain.approveAll'),
                },
                {
                  icon: <Reject {...iconSize} />,
                  handler: async () => await handleRejectAll(),
                  label: polyglot.t('AttendanceDomain.rejectAll'),
                },
              ]}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: polyglot.t('General.actions'),
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
              }}
            />
          )}
        </div>
      )}
    </Box>
  );
};

interface RowActionsProps {
  readonly row: UserAttendanceTableData;
  readonly onEdit: () => void;
  readonly refresh: () => Promise<void>;
  readonly canApproveOrReject: boolean | undefined;
}

const RowActions = ({ row, canApproveOrReject, onEdit, refresh }: RowActionsProps) => {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mode, setMode] = useState<'approve' | 'reject' | 'delete' | null>(null);

  const [showMessage] = useMessage();

  const handleClose = () => {
    setAnchorEl(null);
    setMode(null);
  };

  const approveAttendanceRequest = useCallback(
    async (requestId: number, userId: number, status: AttendanceStatus) => {
      try {
        await AttendanceAPI.approveAttendanceById(userId, requestId, status);
        if (status === AttendanceStatus.Approved)
          showMessage(polyglot.t('AttendanceDomain.requestApproved'), 'success');
        else showMessage(polyglot.t('AttendanceDomain.requestRejected'), 'success');
        await refresh();
      } catch (error) {
        if (status === AttendanceStatus.Approved)
          showMessage(
            polyglot.t('AttendanceDomain.errors.couldNotApprove', { nestError: nestErrorMessage(error) }),
            'error'
          );
        else
          showMessage(
            polyglot.t('AttendanceDomain.errors.couldNotReject', { nestError: nestErrorMessage(error) }),
            'error'
          );
      }
    },
    [polyglot, refresh, showMessage]
  );

  const deleteAttendanceRequest = useCallback(
    async (requestId: number) => {
      try {
        await AttendanceAPI.deleteAttendanceById(requestId);
        showMessage(polyglot.t('AttendanceDomain.requestDeleted'), 'success');
        await refresh();
      } catch (error) {
        showMessage(
          polyglot.t('AttendanceDomain.errors.couldNotDelete', { nestError: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, refresh, showMessage]
  );

  return (
    <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', gap: spacing.g5, maxWidth: '100px' }}>
      <StyledMenuComponent
        options={[
          {
            icon: <EditIcon {...iconSize} />,
            handler: onEdit,
            label: polyglot.t('General.edit'),
          },
          {
            icon: <TrashIcon {...iconSize} />,
            handler: (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
              if (e) {
                setAnchorEl(e.currentTarget);
                setMode('delete');
                e.stopPropagation();
              }
            },
            label: polyglot.t('General.delete'),
          },
        ]}
        actionButtonDetails={{
          type: 'iconButton',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: polyglot.t('General.actions'),
          icon: <ActionsSmall {...iconSize} />,
        }}
      />
      {canApproveOrReject && row.status === AttendanceStatus.Submitted && (
        <IconButton
          sx={tableIconButtonSx}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setMode('reject');
            e.stopPropagation();
          }}
        >
          <Reject {...iconSize} />
        </IconButton>
      )}
      {canApproveOrReject && row.status === AttendanceStatus.Submitted && (
        <IconButton
          sx={tablePrimaryIconButtonSx}
          onClick={async (e) => {
            await approveAttendanceRequest(row.id, row.userId, AttendanceStatus.Approved);
            e.stopPropagation();
          }}
        >
          <Chose {...iconSize} />
        </IconButton>
      )}

      <NotificationModal
        isOpen={mode !== null}
        onClose={() => handleClose()}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (mode === 'reject') await approveAttendanceRequest(row.id, row.userId, AttendanceStatus.Rejected);
          if (mode === 'delete') await deleteAttendanceRequest(row.id);
          setMode(null);
        }}
        message={
          mode === 'reject'
            ? polyglot.t('AttendanceDomain.wantToReject')
            : mode === 'delete'
            ? polyglot.t('AttendanceDomain.wantToDelete')
            : ''
        }
        callToAction={polyglot.t('General.yes')}
      />
    </Box>
  );
};
