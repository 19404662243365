import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE, SURVEYS_TEAM_ONGOING_SETUP_ROUTE } from '@/lib/routes';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleType, cycleTypeOptions } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';

export const SurveyCycleCreationModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach,
}: {
  readonly surveyCycle: SurveyCycle | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
  readonly reach: ReachType;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <CycleCreationModalContent surveyCycle={surveyCycle} onClose={onClose} refresh={refresh} reach={reach} />
    </DrawerModal>
  );
};

const useCreateSurveyCycleForm = (
  surveyCycle: SurveyCycle | undefined,
  onClose: () => void,
  refresh: () => Promise<void>,
  reach: ReachType,
  setUpgradeModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      internalName: surveyCycle?.internalName ?? '',
      displayName: surveyCycle?.displayName ?? '',
      type: surveyCycle ? surveyCycle.type : CycleType.Scheduled,
    },
    validationSchema: yup.object({
      internalName: yup.string().required(polyglot.t('NewReviewCycleDrawer.errorMessages.name')),
      displayName: yup.string().notRequired(),
      type: yup.string().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (surveyCycle) {
          await SurveyCycleAPI.updateSurveyCycle({
            ...surveyCycle,
            internalName: values.internalName,
            displayName: values.displayName,
            type: values.type,
          });
          showMessage(polyglot.t('CycleSettingsRouter.successMessages.update'), 'success');
        } else {
          const reachRoute =
            reach === ReachType.Company ? SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE : SURVEYS_TEAM_ONGOING_SETUP_ROUTE;
          const newlyCreatedCycle = await SurveyCycleAPI.createSurveyCycle({ ...values, id: v4() });
          routerHistory.push(generatePath(reachRoute, { cycleId: newlyCreatedCycle.id }));
        }

        await refresh();
        onClose();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(nestErrorMessage(error), 'error');
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};

const CycleCreationModalContent = ({
  surveyCycle,
  onClose,
  refresh,
  reach,
}: {
  surveyCycle: SurveyCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  readonly reach: ReachType;
}) => {
  const { polyglot } = usePolyglot();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const formik = useCreateSurveyCycleForm(surveyCycle, onClose, refresh, reach, setUpgradeModalOpen);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">
          {!surveyCycle ? polyglot.t('NewSurveyCycleDrawer.newSurvey') : polyglot.t('NewSurveyCycleDrawer.editSurvey')}
        </Typography>

        <SelectComponent
          name="type"
          label="Type"
          options={cycleTypeOptions}
          disabled={Boolean(surveyCycle?.id)}
          value={formik.values.type}
          onChange={formik.handleChange}
          compareValue={formik.values.type}
          error={!!formik.errors.type && formik.touched.type}
          helperText={formik.touched.type && (formik.errors.type as string)}
        />

        <TextfieldComponent
          name="internalName"
          label={polyglot.t('GeneralSettingsPage.internalName')}
          value={formik.values.internalName}
          onChange={formik.handleChange}
          error={formik.touched.internalName && !!formik.errors.internalName}
          helperText={formik.touched.internalName && (formik.errors.internalName as string)}
          endAdornment="none"
          fullWidth
        />

        <TextfieldComponent
          name="displayName"
          label="Display name"
          value={formik.values.displayName}
          onChange={formik.handleChange}
          error={formik.touched.displayName && !!formik.errors.displayName}
          helperText={formik.touched.displayName && (formik.errors.displayName as string)}
          endAdornment="none"
          fullWidth
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.GROWTH_PRO}
          messageSuffix="proGeneric"
        />
      </Form>
    </FormikProvider>
  );
};
