import { useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import * as Yup from 'yup';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { MissingFieldsDetail } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { PersonalInformationForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-form.component';
import { PersonalInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@/v2/infrastructure/date/date-format.util';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  userId: number;
  readonly refreshData?: () => Promise<void>;
  updateDomainForMissingFields?: (values: PersonalInformationValues) => void;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingPersonalFormForPersonalContractSigning = ({
  userId,
  refreshData,
  updateDomainForMissingFields,
  missingFieldsForCategory,
}: Props) => {
  const { polyglot } = usePolyglot();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: personalData } = useApiClient(UserEndpoints.getUserPersonalInfo(userId), {
    suspense: false,
  });

  const { data: userDataFields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), { suspense: false });

  return (
    <>
      <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
        <Typography sx={themeFonts.caption}>
          {polyglot.t('MissingPersonalFormForPersonalContractSigning.personalDetails')}
        </Typography>
        <IconButton onClick={() => setIsModalOpen(true)} sx={{ ...secondaryCTABtn, p: '7px', ml: 'auto' }}>
          <Plus width="16px" height="16px" />
        </IconButton>
      </Stack>
      {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        {personalData && userDataFields?.dataFieldsDefault ? (
          <PersonalInformationForm
            initialValues={personalData}
            userId={userId}
            onSubmit={() => refreshData?.()}
            onClose={() => setIsModalOpen(false)}
            usedForDataImport={true}
            importHandler={async (values: PersonalInformationValues) => {
              await updateDomainForMissingFields?.(values);
              await refreshData?.();
            }}
            showEmployee
            customSchema={{
              // dob is required for payroll
              dob: Yup.string()
                .test(dateFieldTest)
                .nullable()
                .required(polyglot.t('MissingPersonalFormForPersonalContractSigning.dobRequired')),
            }}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DrawerModal>
    </>
  );
};
