import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import axios from 'axios';

export class PublicHolidaysAPI {
  static async handleUserDeletionApprovalByRequestId(
    userId: number,
    requestId: number,
    status: AbsenceStatus
  ): Promise<void> {
    await axios.patch(`/apiv2/public-holidays-deleted/${requestId}/users/${userId}/approval`, { action: status });
  }

  static async handleUserDeletionForceApprovalByRequestId(
    userId: number,
    requestId: number,
    status: AbsenceStatus
  ): Promise<void> {
    await axios.patch(`/apiv2/public-holidays-deleted/${requestId}/users/${userId}/force-approval`, { action: status });
  }

  static async requestDeletionOfPublicHoliday(userId: number, policyId: number, date: string): Promise<void> {
    await axios.post(`/apiv2/public-holidays-deleted/users/${userId}/new-request`, { policyId, date });
  }

  static async deletePHDeletionRequest(userId: number, requestId: number): Promise<void> {
    await axios.delete(`/apiv2/public-holidays-deleted/${requestId}/users/${userId}`);
  }
}
