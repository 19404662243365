import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Document } from '@/images/dashboard-icons/Document.svg';
import { ContractActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/contract-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { ContractTodo, PendingContractsBigWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const PendingContractsTodo = ({
  todo,
  setLastChild,
  userId,
}: {
  todo: PendingContractsBigWidget;
  setLastChild: boolean;
  userId: number;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<ContractTodo | undefined>(undefined);
  const [openActionModal, setOpenActionModal] = useState(false);

  const handleActionItemClick = async (contract: ContractTodo) => {
    setSelectedRow(contract);
    setOpenActionModal(true);
  };

  const getTitle = (contract: ContractTodo) => {
    return polyglot.t('PendingContractsTodo.newDocumentToReview', { contractName: contract.name });
  };

  return (
    <Box>
      {todo.contracts.map((contract) => (
        <TodoCard
          key="pending-contracts"
          icon={<Document {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(contract)}
            </Typography>
          }
          actionOnclick={() => handleActionItemClick(contract)}
          showBorder={!setLastChild}
          userAvatar={userId ? <UserAvatar userId={userId} size="xxxsmall" /> : <></>}
        />
      ))}

      {selectedRow && (
        <ContractActionItem
          selectedRow={selectedRow}
          afterClose={() => setSelectedRow(undefined)}
          isModalOpen={openActionModal}
        />
      )}
    </Box>
  );
};
