import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ParticipantsInvitePeersSettingModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ParticipantsInvitePeersSettingContent reviewCycle={reviewCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

const usePeerInviteForm = (
  reviewCycle: ReviewCycle | null | undefined,
  onClose: () => void,
  refresh: () => Promise<void>
) => {
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      cycleSettings: reviewCycle?.cycleSettings || { allowRevieweesToInvitePeers: false },
    },
    validationSchema: yup.object({
      cycleSettings: yup.object({
        allowRevieweesToInvitePeers: yup.boolean(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (reviewCycle) {
          await ReviewCycleAPI.updateReviewCycle({
            ...reviewCycle,
            cycleSettings: values.cycleSettings,
          });
          showMessage('Successfully update the cycle', 'success');
          await refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};

const ParticipantsInvitePeersSettingContent = ({
  reviewCycle,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const formik = usePeerInviteForm(reviewCycle, onClose, refresh);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Peer invite</Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
            <Typography variant="caption">Allow participants to invite peers</Typography>
            <Typography variant="caption" color="Grey">
              Enabling this allows participants to choose their peers. However, you can also select peers on their
              behalf.
            </Typography>
          </Box>
          <SwitchComponent
            checked={formik.values?.cycleSettings?.allowRevieweesToInvitePeers}
            name="allow-reviewees-to-invite-peers"
            onChange={(_e, enabled) => {
              formik.setFieldValue('cycleSettings.allowRevieweesToInvitePeers', enabled);
            }}
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
