import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { KeyedMutator } from 'swr';

import { PaymentTypeSettings } from '../payment-settings.interface';
import { PaymentTypeSettingsAPI } from '../payment-type-settings.api';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface EditPolicyMembersDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly refresh: KeyedMutator<PaymentTypeSettings> | undefined;
}

export const EditPaymentTypeMembersDrawer = ({
  isOpen,
  setIsOpen,
  paymentTypeSetting,
  refresh,
}: EditPolicyMembersDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <PaymentTypeEditMembersDrawerContent
        paymentTypeSetting={paymentTypeSetting}
        refresh={refresh}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface PaymentTypeEditMembersDrawerContentProps {
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: KeyedMutator<PaymentTypeSettings> | undefined;
}

export const PaymentTypeEditMembersDrawerContent = ({
  paymentTypeSetting,
  refresh,
  setIsOpen,
}: PaymentTypeEditMembersDrawerContentProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState<UserSelectFiltersOptions>(
    paymentTypeSetting.membersRule ?? UserSelectFiltersOptions.None
  );
  const [customRule, setCustomRule] = useState<string>(paymentTypeSetting.customRule ?? '');
  const [selectedUsers, setSelectedUsers] = useState<number[]>(paymentTypeSetting.selectedMembersIds ?? []);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (membersRule: UserSelectFiltersOptions, customRule: string | null, selectedMembersIds: number[]) => {
      try {
        setLoading(true);
        if (!paymentTypeSetting.id) return;
        await PaymentTypeSettingsAPI.updatePaymentTypeMembers(paymentTypeSetting.id, {
          membersRule,
          customRule,
          selectedMembersIds,
        });

        refresh?.();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          `${polyglot.t('EditPolicyMembersDrawerContent.errorMessages.updateFailure')}. ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [paymentTypeSetting.id, refresh, setIsOpen, showMessage, polyglot]
  );

  return (
    <Box>
      <Typography sx={titleSx}>{polyglot.t('EditPolicyMembersDrawerContent.editMembers')}</Typography>

      <Box sx={fieldSx}>
        <UserSelect
          label={polyglot.t('EditPolicyMembersDrawerContent.whoSelect')}
          selectedLabel="Selected employees"
          value={selectedUsers}
          onChange={(userIds: number[], filterValue?: UserSelectFiltersOptions, customRule?: string) => {
            setSelectedUsers([...userIds]);
            setValidationMessage('');
            setSelectedOption(filterValue ?? UserSelectFiltersOptions.None);
            setCustomRule(customRule ?? '');
          }}
          fieldSx={{ ...spacing.mb20 }}
          error={!!validationMessage}
          helperText={!!validationMessage && validationMessage}
          initialFilterValue={paymentTypeSetting.membersRule ?? UserSelectFiltersOptions.None}
          ruleString={customRule}
          excludeCustomRule
        />
      </Box>

      <Box sx={{ mt: spacing.m20 }}>
        <LoaderButton
          name={polyglot.t('General.save')}
          type="button"
          onClick={async () => {
            if (selectedOption === UserSelectFiltersOptions.None)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOption'));
            else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.selectOneUser'));
            else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
              setValidationMessage(polyglot.t('EditPolicyMembersDrawerContent.errorMessages.enterCustomRule'));
            else {
              const membersRule = selectedOption ?? UserSelectFiltersOptions.Everyone;
              const selectedCustomRule = selectedOption === UserSelectFiltersOptions.CustomRule ? customRule : null;
              await onSubmit(membersRule, selectedCustomRule, selectedUsers);
            }
          }}
          loading={loading}
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
        />
      </Box>
    </Box>
  );
};
