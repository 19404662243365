import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { SettingsReadOnlyLine } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { WeekDayNames } from '@v2/feature/attendance/attendance.interface';
import { getAllTimeFromScheduleByWeekIndex } from '@v2/feature/attendance/attendance.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';

interface SubSectionProps {
  readonly schedule: AttendanceScheduleDto;
}

export const AttendanceSchedulePatternSubSection = ({ schedule }: SubSectionProps) => {
  const { polyglot } = usePolyglot();

  // weeks numbers starting with 0
  const weeksArray = useMemo(() => {
    const arr = [];
    for (let w = 0; w < schedule.noOfWeeks; w += 1) {
      arr.push(w);
    }
    return arr;
  }, [schedule.noOfWeeks]);

  return (
    <Box>
      {weeksArray.length > 1 && (
        <Box sx={{ mb: '20px' }}>
          <SettingsReadOnlyLine
            key="schedule_start_week"
            field={polyglot.t('ScheduleSectionReadonly.startDateOfFirstWeek')}
            value={new LocalDate(schedule.startDateOfFirstWeek).toLocaleDateString()}
          />
        </Box>
      )}

      {weeksArray.map((weekNo) => {
        return (
          <Box key={weekNo} sx={{ mt: '10px', mb: '10px' }}>
            {schedule.noOfWeeks > 1 && (
              <Typography variant="title4" sx={{ mb: '10px' }}>
                {polyglot.t('AttendanceSchedule.weekNo', { weekNo: weekNo + 1 })}:
              </Typography>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {WeekDayNames.map((day) => {
                if (
                  !schedule[day] ||
                  !schedule[day]![weekNo] ||
                  !schedule[day]![weekNo]!.to ||
                  !schedule[day]![weekNo]!.from
                )
                  return (
                    <SettingsReadOnlyLine
                      key={day}
                      field={polyglot.t(`Days.${day.toLowerCase()}`)}
                      value={polyglot.t('AttendanceDomain.notWorking')}
                    />
                  );

                if (schedule.isFlexible) {
                  const totalHoursValue = schedule[day]![weekNo]?.totalHours?.slice(11, 16);
                  const [tH, tM] = totalHoursValue ? totalHoursValue.split(':') : [null, null];
                  const totalHoursToHour = tH && Number(tH) ? Number(tH) : '';
                  const totalHoursToMinute = tM && Number(tM) ? Number(tM) : '';
                  const breakString = totalHoursValue
                    ? `${Number(totalHoursToHour) > 0 ? `${totalHoursToHour}h` : ''} ${
                        Number(totalHoursToMinute) > 0 ? `${totalHoursToMinute}m` : ''
                      }`.trim()
                    : '';
                  return (
                    <SettingsReadOnlyLine
                      key={day}
                      field={polyglot.t(`Days.${day.toLowerCase()}`)}
                      value={breakString}
                    />
                  );
                } else {
                  const from = schedule[day]![weekNo]!.from.slice(11, 16);
                  const to = schedule[day]![weekNo]!.to.slice(11, 16);
                  const breakValue = schedule[day]![weekNo]!.break?.slice(11, 16);
                  const [bH, bM] = breakValue ? breakValue.split(':') : [null, null];
                  const breakToHour =
                    bH && Number(bH) ? polyglot.t('ScheduleSectionReadonly.breakToHour', { number: Number(bH) }) : '';
                  const breakToMinute =
                    bM && Number(bM)
                      ? polyglot.t('ScheduleSectionReadonly.breakToMinute', {
                          breakToHour: breakToHour ? ' ' : '',
                          number: Number(bM),
                        })
                      : '';
                  const breakString =
                    breakValue && breakValue !== '00:00'
                      ? polyglot.t('ScheduleSectionReadonly.breakString', {
                          breakToHour: breakToHour,
                          breakToMinute: breakToMinute,
                        })
                      : '';

                  const period = polyglot.t('ScheduleSectionReadonly.period', {
                    from: from,
                    to: to,
                    breakString: breakString,
                  });
                  return (
                    <SettingsReadOnlyLine key={day} field={polyglot.t(`Days.${day.toLowerCase()}`)} value={period} />
                  );
                }
              })}
            </Box>

            <Box sx={{ mt: '10px', mb: '20px' }}>
              <SettingsReadOnlyLine
                key="week_total"
                field={polyglot.t('ScheduleSectionReadonly.totalHours')}
                value={convertMinutesToClockHours(getAllTimeFromScheduleByWeekIndex(schedule, weekNo), polyglot)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
