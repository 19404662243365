import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_BENEFITS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly provider: PensionSchemeDto;
}

export const DeletePensionDrawer = ({ isOpen, setIsOpen, provider }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <DeletePensionDrawerContent setIsOpen={setIsOpen} provider={provider} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly provider: PensionSchemeDto;
}

export const DeletePensionDrawerContent = ({ setIsOpen, provider }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const history = useHistory();

  const deleteProvider = async () => {
    if (!provider?.id) return;
    try {
      setIsDeleteLoading(true);
      await PensionAPI.deletePension(provider.id);
      showMessage(polyglot.t('PensionModule.pensionDeleted'), 'success');
      setIsOpen(false);
      history.push(SETTINGS_BENEFITS_ROUTE);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{provider.displayName}</Typography>

      <Typography variant="caption">Are you sure you want to delete this pension?</Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.delete')}
          loading={isDeleteLoading}
          sizeVariant="medium"
          colorVariant="danger"
          fullWidth
          onClick={deleteProvider}
        />
      </Box>
    </Box>
  );
};
