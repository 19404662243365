import { useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { TranslationDrawer } from '@v2/feature/company/company-translation/company-translation-drawer.component';
import {
  CompanyTranslationDto,
  SupportedTranslationLanguage,
} from '@v2/feature/company/company-translation/company-translation.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface TranslationTableProps {
  readonly translations: CompanyTranslationDto[];
  readonly refreshTable: () => Promise<void>;
}

export const TranslationTable = ({ translations, refreshTable }: TranslationTableProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [selectedTranslation, setSelectedTranslation] = useState<CompanyTranslationDto | null>(null);

  const tableColumns = useMemo<ColumnDef<CompanyTranslationDto, CompanyTranslationDto>[]>(() => {
    return [
      {
        header: () => 'EN',
        accessorFn: (row) => row,
        id: 'en',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.en),
        cell: ({ row: { original } }) => (original.en ? <div>{original.en}</div> : <EmptyCell />),
      },
      {
        header: () => 'AR',
        accessorFn: (row) => row,
        id: 'ar',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.ar),
        cell: ({ row: { original } }) => (original.ar ? <div>{original.ar}</div> : <EmptyCell />),
      },
      {
        header: () => 'DE',
        accessorFn: (row) => row,
        id: 'de',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.de),
        cell: ({ row: { original } }) => (original.de ? <div>{original.de}</div> : <EmptyCell />),
      },
      {
        header: () => 'FR',
        accessorFn: (row) => row,
        id: 'fr',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.fr),
        cell: ({ row: { original } }) => (original.fr ? <div>{original.fr}</div> : <EmptyCell />),
      },
      {
        header: () => 'ES',
        accessorFn: (row) => row,
        id: 'es',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.es),
        cell: ({ row: { original } }) => (original.es ? <div>{original.es}</div> : <EmptyCell />),
      },
      {
        header: () => 'IT',
        accessorFn: (row) => row,
        id: 'it',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.it),
        cell: ({ row: { original } }) => (original.it ? <div>{original.it}</div> : <EmptyCell />),
      },
      {
        header: () => 'PT',
        accessorFn: (row) => row,
        id: 'pt',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.pt),
        cell: ({ row: { original } }) => (original.pt ? <div>{original.pt}</div> : <EmptyCell />),
      },
      {
        header: () => 'Created by',
        accessorFn: (row) => row,
        id: 'creator',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.fr),
        cell: ({ row: { original } }) =>
          original.creator?.displayName ? <div>{polyglot.t(original.creator.displayName)}</div> : <EmptyCell />,
      },
      {
        header: () => 'Created at',
        accessorFn: (row) => row,
        id: 'createdAt',
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.fr),
        cell: ({ row: { original } }) => <div>{new Date(original.updatedAt).toLocaleString()}</div>,
      },
      {
        header: () => '',
        id: 'action',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: spacing.g5 }}>
            <IconButton
              title={polyglot.t('General.edit')}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedTranslation(original);
                setIsEditOpen(true);
              }}
              sx={tableIconButtonSx}
            >
              <Edit {...iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ];
  }, [polyglot]);

  const hiddenColumns = useMemo(() => {
    const allNonEnglishColumns: SupportedTranslationLanguage[] = ['ar', 'it', 'pt', 'es', 'de', 'fr'];

    const existingKeysWithValues = translations.reduce((result, tr) => {
      for (const key of allNonEnglishColumns as SupportedTranslationLanguage[]) if (tr[key]) result.add(key);
      return result;
    }, new Set<SupportedTranslationLanguage>());

    return allNonEnglishColumns.filter((k) => !existingKeysWithValues.has(k));
  }, [translations]);

  return (
    <Box sx={{ ...spacing.mt20 }}>
      {isEditOpen && (
        <TranslationDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          translation={selectedTranslation}
          onClose={() => setSelectedTranslation(null)}
          refresh={refreshTable}
        />
      )}

      <BasicTable<CompanyTranslationDto>
        rowData={translations}
        columnData={tableColumns}
        hiddenColumns={hiddenColumns}
      />
    </Box>
  );
};
