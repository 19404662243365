import { useEffect, useRef } from 'react';

export const useTimeout = (callback: Function, delay: number) => {
  // Creating a ref
  const savedCallback = useRef<any>();

  // To remember the latest callback .
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Setting and clearing up a timeout
  useEffect(() => {
    const func = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setTimeout(func, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};
