import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceSettingsDto } from '@v2/feature/absence/absence.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@v2/styles/colors.styles';

export const HelperAbsenceSettingsSection = ({
  absenceSettings,
  isLoadingSettings,
}: {
  absenceSettings: AbsenceSettingsDto | undefined | null;
  isLoadingSettings: boolean;
}) => {
  return (
    <Box>
      <Typography variant="title3">Absence settings</Typography>
      {isLoadingSettings ? (
        <SkeletonLoader variant="rectangular" width="300px" height="200px" sx={{ borderRadius: '10px' }} />
      ) : absenceSettings ? (
        <Box sx={{ width: '300px' }}>
          <HelperReadOnlyField label="holidayCountry" value={absenceSettings.holidayCountry} />
          <HelperReadOnlyField label="showOverlap" value={absenceSettings.showOverlap ? 'True' : 'False'} />
          {absenceSettings.showOverlap && (
            <HelperReadOnlyField label="overlapsRule" value={absenceSettings.overlapsRule} />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

const HelperReadOnlyField = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        py: '2px',
        px: '5px',
        '&:hover': { bgcolor: themeColors.Background, borderRadius: '15px' },
      }}
    >
      <Typography variant="caption" sx={{ width: '240px' }}>
        {label}
      </Typography>
      <Typography variant="title4" sx={{ display: 'flex', justifyContent: 'end', width: '50px' }}>
        {value}
      </Typography>
    </Box>
  );
};
