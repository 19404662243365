import { ConfigurableDeviceData } from '@v2/feature/device/device.dto';
import { DeviceDetailsComponent } from '@v2/feature/device/features/device-cards/components/device-details.component';

import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly configurableDevice: ConfigurableDeviceData | null;
  readonly ownerName: string;
  readonly loading: boolean;
  refresh: () => Promise<void>;
}

export const DeviceMdmDetailsPage = ({ configurableDevice, ownerName, loading, refresh }: Props) => {
  return (
    <SettingsSectionContent
      title="Details"
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={loading}
    >
      {configurableDevice && (
        <DeviceDetailsComponent configurableDevice={configurableDevice} ownerName={ownerName} refresh={refresh} />
      )}
    </SettingsSectionContent>
  );
};
