import React from 'react';

import { Stack, SxProps, Theme } from '@mui/material';

import { User } from '@/models';
import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';
import { UserAvatar, UserAvatarSize } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

type UserCellProps = {
  userId: number;
  avatarSx?: SxProps<Theme>;
  name?: { firstName: string; lastName: string };
  nameSx?: SxProps<Theme>;
  nameVariant?: TypographyVariant;
  endAdornment?: React.JSX.Element | false;
  sx?: SxProps<Theme>;
  avatarSize?: UserAvatarSize;
  prefix?: string;
};

export const UserCell = ({
  userId,
  avatarSx,
  name,
  nameSx,
  nameVariant = 'caption',
  sx,
  endAdornment,
  avatarSize = 'xxsmall',
  prefix,
}: UserCellProps) => {
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userId);

  if (!user) {
    return <></>;
  }

  return (
    <Stack sx={{ flexFlow: 'row', flex: 1, gap: spacing.g4, alignItems: 'center', ...sx }}>
      {prefix && (
        <Typography variant="caption" color="DarkGrey">
          {prefix}
        </Typography>
      )}
      <UserAvatar
        key={user.userId}
        userId={user.userId}
        size={avatarSize}
        sx={{ flex: '0 0 auto', ...avatarSx }}
        showName
        name={name}
        nameVariant={nameVariant}
        nameSx={nameSx}
      />
      {endAdornment}
    </Stack>
  );
};

// We create this as a static method so that it's clear that it's associated
// the the `UserCell` component, but can be used elsewhere
UserCell.getDisplayedName = (user: Pick<User, 'userId' | 'displayName' | 'firstName' | 'lastName'>): string => {
  // this is designed to always return *something* for the user's name - never an empty string
  return user.displayName?.trim() || `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim() || `User:${user.userId}`;
};
