import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as AppsIcon } from '@/images/onboarding-step-icons/AppsBig2.svg';
import { Scope } from '@/models';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { AppIconsList } from '@/v2/components/app-icons-list.component';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { EditApps } from '@/v2/feature/onboarding/onboarding-items/apps/edit-apps.component';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  onDelete?: () => void;
  setDrawerOpen?: (open: boolean) => void;
  setDrawerView?: (view: JSX.Element) => void;
  checkOnboardingItemScope: (scope: readonly Scope[]) => boolean;
};

export const AppsOnboardingItem = ({
  template,
  onDelete,
  setDrawerView,
  setDrawerOpen,
  checkOnboardingItemScope,
}: Props) => {
  const { polyglot } = usePolyglot();
  const canAddApps = checkOnboardingItemScope(AppScopes.ADD_TO_ONBOARDING_TEMPLATE);
  return (
    <ActionSelectionItem
      step={{
        Icon: AppsIcon,
        title: polyglot.t('AppsOnboardingItem.title'),
        description: polyglot.t('AppsOnboardingItem.desc'),
      }}
      chips={template.template.apps && [<AppIconsList stubs={template.template.apps.stubs as AppIntegrationStub[]} />]}
      onDeleteClick={template.template.apps && onDelete}
      addButtonIcon={template.template.apps ? 'edit' : 'plus'}
      onAddClick={
        canAddApps &&
        (() => {
          setDrawerView?.(
            <EditApps
              config={template.template.apps}
              onSave={(config) => {
                template.template.apps = config;
                setDrawerOpen?.(false);
              }}
            />
          );
          setDrawerOpen?.(true);
        })
      }
      sx={{
        py: spacing.py20,
      }}
    />
  );
};
