import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Box, FormControl, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ChecklistItemDto } from '@v2/feature/task/subfeature/checklist/checklist-item.dto';
import { TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT } from '@v2/feature/task/subfeature/checklist/components/checklist-item-form-modal.component';
import { CreateTaskDto, MultipleUserTaskDto, TaskDto, UpdateTaskDto } from '@v2/feature/task/task.dto';
import { TaskStatuses } from '@v2/feature/task/task.interface';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as QuestionCircle } from '@/images/app-icons/QuestionCircle.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  AttachmentComponent,
  attachmentComponentValueToString,
  stringToAttachmentComponentValue,
} from '@/v2/components/forms/attachment.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { OptionObj, UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

interface TaskModalProperties {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  afterClose?: () => Promise<void> | void;
  formData: TaskDto | ChecklistItemDto | null;
  refresh?: () => Promise<void>;
  userId?: number;
  isChecklist?: boolean;
  readonly reach?: 'team' | 'company';
}

export const TaskFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  formData,
  refresh,
  userId,
  isChecklist = false,
  reach,
}: TaskModalProperties): React.ReactElement => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const modalType = formData?.id ? 'edit' : 'new';

  const users = useMemo(() => {
    const reports = new Set(globalState.user.reports);
    return reach === 'team'
      ? nonTerminatedCachedUsers
          .filter((u) => reports.has(u.userId) || u.userId === globalState.user.userId)
          .map((u) => {
            return { label: u.displayName, value: u.userId, ...u };
          })
      : nonTerminatedCachedUsers.map((u) => {
          return { label: u.displayName, value: u.userId, ...u };
        });
  }, [globalState.user.reports, globalState.user.userId, nonTerminatedCachedUsers, reach]);

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const createTask = useCallback(
    async (task: CreateTaskDto): Promise<void> => {
      try {
        await TaskAPI.createTasksInBulk(task);
        showMessage(polyglot.t('TasksListPage.successMessages.create'), 'success');
      } catch (error) {
        showMessage(
          polyglot.t('TasksListPage.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  const updateTask = useCallback(
    async (data: UpdateTaskDto): Promise<void> => {
      try {
        await TaskAPI.updateTask(data);
      } catch (error) {
        showMessage(
          polyglot.t('TasksListPage.errorMessages.update', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  const deleteTask = useCallback(
    async (taskId: number) => {
      try {
        await TaskAPI.removeTask(taskId);
        showMessage(polyglot.t('TasksListPage.successMessages.delete'), 'success');
      } catch (error) {
        showMessage(
          polyglot.t('TasksListPage.errorMessages.remove', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  const handleSubmit = useCallback(
    async (values: UpdateTaskDto | CreateTaskDto) => {
      setLoading(true);
      const { dueDate, assignedUserIds } = values as MultipleUserTaskDto;
      if (assignedUserIds?.length) {
        if (formData?.id) {
          const data: UpdateTaskDto = {
            ...values,
            id: formData?.id,
            dueDate: dueDate ?? todaysDateShortISOString(),
            assignedUserIds,
          };
          await updateTask(data);
        } else {
          const newTask: CreateTaskDto = {
            ...values,
            dueDate: dueDate ?? todaysDateShortISOString(),
            assignedUserIds,
          };
          await createTask(newTask);
        }
      }
      if (refresh) await refresh?.();
      setLoading(false);
      if (onClose) onClose();
      setIsOpen(false);
    },
    [setIsOpen, createTask, formData?.id, onClose, refresh, updateTask]
  );

  const userOptions = useMemo(() => {
    if (formData?.assignedUserId) return [formData?.assignedUserId];
    if (userId) return [userId];
    return [];
  }, [formData?.assignedUserId, userId]);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(polyglot.t('TaskFormModal.errorMessages.nameRequired')),
        dueDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
        description: Yup.string().nullable().notRequired(),
        assignedUserIds: isChecklist ? Yup.number().nullable().notRequired() : Yup.array().required('Required'),
        attachmentValues: Yup.string().nullable().notRequired(),
      }),
    [polyglot, isChecklist]
  );

  const formik = useFormik({
    initialValues: {
      name: formData?.name ?? '',
      description: formData?.description ?? '',
      dueDate: formData?.dueDate ?? null,
      assignedUserId: formData?.assignedUserId || userId || null,
      assignedUserIds: userOptions,
      requestedForId: formData?.requestedForId ?? null,
      status: TaskStatuses.INCOMPLETE,
      taskForSomebodyElse: Boolean(!!formData?.requestedForId),
      attachmentValues: formData?.attachmentValues ?? undefined,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: UpdateTaskDto | CreateTaskDto) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="title2">
              {modalType === 'edit' ? polyglot.t('TaskFormModal.edit') : polyglot.t('TaskFormModal.new')}
            </Typography>
            {modalType === 'edit' && (
              <IconButton
                sx={iconButtonSx}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
            )}
          </Box>
          <TextfieldComponent
            name="name"
            label={polyglot.t('TaskFormModal.name')}
            value={formik.values.name}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={(formik.touched.name && formik.errors.name) ?? ' '}
            endAdornment="none"
            autoFocus
          />

          <RichTextField
            label={polyglot.t('TaskFormModal.description')}
            value={formik.values.description ?? ''}
            onChange={(value: string) => formik.setFieldValue('description', value)}
          />

          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              shortcuts={true}
              value={formik.values.dueDate ?? todaysDateShortISOString()}
              onChange={(value) => {
                formik.setFieldValue('dueDate', value);
              }}
              name="dueDate"
              label={polyglot.t('TaskFormModal.dueDate')}
              error={!!formik.errors.dueDate && Boolean(formik.touched.dueDate)}
              helperText={formik.errors.dueDate && Boolean(formik.touched.dueDate)}
            />
          </FormControl>
          {modalType === 'edit' && formik.values.assignedUserId && (
            <Box>
              <Typography variant="captionSmall" color="Grey">
                {polyglot.t('TaskViewModal.assignedTo')}
              </Typography>
              <Box sx={{ mt: spacing.m5, display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
                <UserCell userId={formik.values.assignedUserId} />
              </Box>
            </Box>
          )}
          {modalType === 'new' && (
            <Tooltip title={polyglot.t('TaskFormModal.whoShouldComplete')} placement="top-start">
              <UserSelect
                label={polyglot.t('TaskFormModal.whoShouldComplete')}
                selectedLabel={polyglot.t('TaskFormModal.selectedEmployees')}
                value={formik.values.assignedUserIds ?? []}
                onChange={(userIds: number[]) => {
                  formik.setFieldValue('assignedUserIds', userIds);
                }}
                userOptions={users}
              />
            </Tooltip>
          )}

          <CheckboxComponent
            name="taskForSomebodyElse"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                <Typography variant="caption">{polyglot.t('TaskFormModal.someoneElse')}</Typography>
                <StyledTooltip title={TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT(polyglot)}>
                  <QuestionCircle style={{ height: 12, width: 12 }} fill={themeColors.GreyMiddle} />
                </StyledTooltip>
              </Box>
            }
            checked={formik.values.taskForSomebodyElse}
            onChange={(_, checked) => {
              formik.setFieldValue('taskForSomebodyElse', checked);
              if (!checked) formik.setFieldValue('requestedForId', null);
            }}
          />

          {formik.values.taskForSomebodyElse && (
            <SingleUserSelect
              name="requestedForId"
              options={users}
              onChange={(_, x) => formik.setFieldValue('requestedForId', (x as OptionObj)?.value ?? null)}
              value={formik.values.requestedForId}
              label={polyglot.t('TaskFormModal.requestedForId')}
              error={formik.touched.requestedForId && Boolean(formik.errors.requestedForId)}
              helperText={formik.touched.requestedForId && (formik.errors.requestedForId as string)}
            />
          )}

          <AttachmentComponent
            variant="caption"
            disabled={false}
            label="Add a file"
            name="attachmentValues"
            showSelectExisting={true}
            // all form values are stored as strings so we must convert to an object
            value={stringToAttachmentComponentValue(formik.values.attachmentValues ?? '')}
            onChange={(value) => {
              formik?.setFieldValue('attachmentValues', attachmentComponentValueToString(value));
            }}
            error={formik.touched.attachmentValues && Boolean(formik.errors.attachmentValues)}
            helperText={formik.touched.attachmentValues && (formik.errors.attachmentValues as string)}
          />

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth={true}
              disabled={!formik.isValid || !formik.dirty || loading || formik.values.assignedUserIds?.length === 0}
            />
          </Box>
          <NotificationModal
            isOpen={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            takeAction={async () => {
              if (formData?.id) await deleteTask(formData.id);
              if (refresh) await refresh?.();
              setAnchorEl(null);
              if (onClose) onClose();
              setIsOpen(false);
            }}
            message={polyglot.t('TaskFormModal.confirmDelete')}
            callToAction={polyglot.t('General.yes')}
          />
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
