import * as React from 'react';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { TextFieldAndUnit } from '@v2/components/forms/textfield-and-unit.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AccrualCliff, AccrualUnit, PolicyAccrualData } from '@v2/feature/absence/absence.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyAccrualEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: PolicyAccrualData) => {
      const policyData: PolicyAccrualData = {
        accrualUnit: values.accrualUnit,
        accrualUnitsNo: values.accrualUnitsNo,
        accrualCliff: values.accrualCliff,
        accrueUpfront: values.accrueUpfront,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyAccrual(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage]
  );

  const formik = useFormik<PolicyAccrualData>({
    initialValues: {
      accrualUnit: absencePolicy.accrualUnit ?? AccrualUnit.Day,
      accrualUnitsNo: absencePolicy.accrualUnitsNo ?? 1,
      accrualCliff: absencePolicy.accrualCliff ?? null,
      accrueUpfront: absencePolicy.accrueUpfront ?? true,
    },
    validationSchema: yup.object({
      accrualUnit: yup
        .string()
        .oneOf(Object.values(AccrualUnit), polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      accrualUnitsNo: yup
        .number()
        .integer(polyglot.t('ValidationMessages.validValue'))
        .when('accrualUnit', {
          is: (val: AccrualUnit) => val === AccrualUnit.Day,
          then: (schema) =>
            schema
              .min(1, polyglot.t('ValidationMessages.validValue'))
              .max(365, polyglot.t('ValidationMessages.validValue'))
              .required(polyglot.t('ValidationMessages.requiredField')),
          otherwise: (schema) =>
            schema.when('accrualUnit', {
              is: (val: AccrualUnit) => val === AccrualUnit.Week,
              then: (schema) =>
                schema
                  .min(1, polyglot.t('ValidationMessages.validValue'))
                  .max(52, polyglot.t('ValidationMessages.validValue'))
                  .required(polyglot.t('ValidationMessages.requiredField')),
              otherwise: (schema) =>
                schema.when('accrualUnit', {
                  is: (val: AccrualUnit) => val === AccrualUnit.Month,
                  then: (schema) =>
                    schema
                      .min(1, polyglot.t('ValidationMessages.validValue'))
                      .max(12, polyglot.t('ValidationMessages.validValue'))
                      .required(polyglot.t('ValidationMessages.requiredField')),
                  otherwise: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
                }),
            }),
        })
        .required(polyglot.t('ValidationMessages.requiredField')),
      accrualCliff: yup
        .string()
        .nullable()
        .oneOf([null, ...Object.values(AccrualCliff)], polyglot.t('ValidationMessages.validValue'))
        .notRequired(),
      accrueUpfront: yup
        .boolean()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsenceModule.accrual')}</Typography>

          <Typography variant="caption">{polyglot.t('PolicyAccrualEditDrawer.restrictByAccrualDesc')}</Typography>

          <TextFieldAndUnit
            label={polyglot.t('AbsenceModule.accrualFrequency')}
            valueName="accrualUnitsNo"
            value={formik.values.accrualUnitsNo}
            onValueChange={(value: string | number | null) => {
              formik.setFieldValue('accrualUnitsNo', Number(value));
            }}
            unitName="accrualUnit"
            unit={formik.values.accrualUnit as string}
            onUnitChange={(value: string) => {
              formik.setFieldValue('accrualUnit', value as AccrualUnit);
            }}
            unitOptions={[
              { label: polyglot.t('getUnitTypeOptions.days'), value: AccrualUnit.Day },
              { label: polyglot.t('getUnitTypeOptions.weeks'), value: AccrualUnit.Week },
              { label: polyglot.t('getUnitTypeOptions.months'), value: AccrualUnit.Month },
            ]}
            error={
              (!!formik.errors.accrualUnit && formik.touched.accrualUnit) ||
              (!!formik.errors.accrualUnitsNo && formik.touched.accrualUnitsNo)
            }
            helperText={
              (formik.touched.accrualUnit && (formik.errors.accrualUnit as string)) ||
              (formik.touched.accrualUnitsNo && (formik.errors.accrualUnitsNo as string))
            }
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="title4">{polyglot.t('AbsenceModule.accrueUpfront')}</Typography>
              <SwitchComponent
                checked={Boolean(formik.values.accrueUpfront)}
                name="accrual-upfront"
                onChange={(_e, enabled) => {
                  formik.setFieldValue('accrueUpfront', enabled);
                }}
              />
            </Box>
            <Typography variant="caption">{polyglot.t('AbsenceModule.accrueUpfrontDesc')}</Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="title4">{polyglot.t('AbsenceModule.accrualCliff')}</Typography>
              <SwitchComponent
                checked={Boolean(formik.values.accrualCliff)}
                name="accrual-cliff"
                onChange={(_e, enabled) => {
                  if (enabled) formik.setFieldValue('accrualCliff', AccrualCliff.Probation);
                  else formik.setFieldValue('accrualCliff', null);
                }}
              />
            </Box>
            <Typography variant="caption">{polyglot.t('PolicyAccrualEditDrawer.accrualCliffDesc')}</Typography>
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
