import { Dispatch, SetStateAction } from 'react';

import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import {
  AttendanceImportDto,
  AttendanceImportResultDto,
} from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import { AbsenceAdjustmentsImportResultTable } from '@v2/feature/entity-import/wizard/components/absence-adjustments/absence-adjustments-result-table.component';
import { AttendanceImportResultTable } from '@v2/feature/entity-import/wizard/components/attendance/attendance-import-result-table.component';

import {
  AbsenceAdjustmentImportDto,
  AbsenceAdjustmentImportResultDto,
  AbsenceImportDto,
  AbsenceImportResultDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import {
  DeviceImportDto,
  DevicesImportResultDto,
} from '@/v2/feature/device/features/devices-import/devices-import.dto';
import { EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import { EntityImportWizardData } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { AbsenceImportResultTable } from '@/v2/feature/entity-import/wizard/components/absence/absence-import-result-table.component';
import { DeviceImportResultTable } from '@/v2/feature/entity-import/wizard/components/device/device-import-result-table.component';
import { UserImportResultTable } from '@/v2/feature/entity-import/wizard/components/user/user-import-result-table.component';
import { ImportState } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
import {
  UserImport2Dto,
  UserImportDto,
  UserImportResult2Dto,
} from '@/v2/feature/user/features/user-import/user-import.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type ImportResultsSectionProps = {
  importState: ImportState;
  completeImport: (selectionModel?: string[], users?: UserImport2Dto[]) => void;
  sx?: SxProps<Theme>;
  onUpdateAndRevalidate: (
    updatedRecords: EntityImportValidationResultDto<
      | UserImportDto
      | UserImport2Dto
      | AbsenceImportDto
      | DeviceImportDto
      | AttendanceImportDto
      | AbsenceAdjustmentImportDto
    >
  ) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  attendanceSchedules: readonly AttendanceScheduleDto[];
};

export const ImportResultsSection = ({
  importState,
  completeImport,
  sx,
  onUpdateAndRevalidate,
  loading,
  setLoading,
}: ImportResultsSectionProps) => {
  return (
    <Stack sx={{ gap: spacing.g30, ...sx }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'left',
        }}
      >
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Review results</Typography>
      </Box>
      <Box>
        {importState.domain === EntityImportWizardData.Users && (
          <UserImportResultTable
            result={importState.result as UserImportResult2Dto}
            completeImport={completeImport}
            onUpdateAndRevalidate={onUpdateAndRevalidate}
            loading={loading}
          />
        )}
        {importState.domain === EntityImportWizardData.Absences && (
          <AbsenceImportResultTable
            result={importState.result as AbsenceImportResultDto}
            completeImport={completeImport}
            onUpdateAndRevalidate={onUpdateAndRevalidate}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {importState.domain === EntityImportWizardData.AbsenceAdjustments && (
          <AbsenceAdjustmentsImportResultTable
            result={importState.result as AbsenceAdjustmentImportResultDto}
            completeImport={completeImport}
            onUpdateAndRevalidate={onUpdateAndRevalidate}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {importState.domain === EntityImportWizardData.Devices && (
          <DeviceImportResultTable
            result={importState.result as DevicesImportResultDto}
            completeImport={completeImport}
            loading={loading}
          />
        )}
        {importState.domain === EntityImportWizardData.Attendances && (
          <AttendanceImportResultTable
            result={importState.result as AttendanceImportResultDto}
            completeImport={completeImport}
            onUpdateAndRevalidate={onUpdateAndRevalidate}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Box>
    </Stack>
  );
};
