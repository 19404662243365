import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary, groupBy } from 'lodash';
import Polyglot from 'node-polyglot';

import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { EmptyStateJobLevel } from '@/v2/feature/job-level/components/empty-state-job-level.component';
import { LevelFormModal } from '@/v2/feature/job-level/components/level-form-modal.component';
import { TrackFormModal } from '@/v2/feature/job-level/components/track-form-modal.component';
import { JobLevelEndpoints } from '@/v2/feature/job-level/job-level.api';
import { JobLevel } from '@/v2/feature/job-level/job-level.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { DARK_CONTRAST_COLOR, SECONDARY_CHART_COLOR, themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const JobLevelSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const {
    data: jobLevels,
    isLoading: loadingJobLevels,
    mutate: refreshJobLevels,
  } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });

  const [openTrack, setOpenTrack] = useState<boolean>(false);
  const [openLevel, setOpenLevel] = useState<boolean>(false);
  const [trackName, setTrackName] = useState<string | undefined>(undefined);
  const [selectedLevel, setSelectedLevel] = useState<JobLevel | undefined>(undefined);

  const jobsLookup = useMemo(() => {
    return groupBy(jobLevels, 'trackName');
  }, [jobLevels]);

  const handleLevelSelect = useCallback((track: string, level: JobLevel) => {
    setTrackName(track);
    setSelectedLevel(level);
    setOpenLevel(true);
  }, []);

  const handleAddLevelClick = useCallback((track: string) => {
    setTrackName(track);
    setOpenLevel(true);
  }, []);
  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('JobLevelSettingsPage.jobLevels')}</Typography>}
        showAction={Boolean(jobLevels && jobLevels?.length > 0)}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setOpenTrack(true)}>
            {polyglot.t('JobLevelSettingsPage.newTrack')}
          </ButtonComponent>
        }
        showBack
      />
      <ContentWrapper loading={loadingJobLevels}>
        {jobLevels && jobLevels?.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: spacing.g20,
              overflowX: 'auto',
              width: '100%',
              height: '100%',
              flexWrap: 'nowrap',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
            }}
          >
            {Object.keys(jobsLookup).map((trackName, idx) => {
              return (
                <TrackAndLevelComponent
                  idx={idx}
                  key={trackName}
                  trackName={trackName}
                  handleLevelSelect={handleLevelSelect}
                  handleAddLevelClick={handleAddLevelClick}
                  jobsLookup={jobsLookup}
                  polyglot={polyglot}
                />
              );
            })}
          </Box>
        )}
        {jobLevels && jobLevels?.length === 0 && <EmptyStateJobLevel addNewTrack={() => setOpenTrack(true)} />}
        <TrackFormModal
          setOpenTrack={setOpenTrack}
          openTrack={openTrack}
          refresh={refreshJobLevels}
          onClose={() => {
            setOpenTrack(false);
          }}
        />
        {trackName && (
          <LevelFormModal
            setOpenLevel={setOpenLevel}
            openLevel={openLevel}
            refresh={refreshJobLevels}
            trackName={trackName}
            selectedLevel={selectedLevel}
            onClose={() => {
              setSelectedLevel(undefined);
              setTrackName(undefined);
              setOpenLevel(false);
            }}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};

const TrackAndLevelComponent = ({
  idx,
  trackName,
  handleAddLevelClick,
  handleLevelSelect,
  jobsLookup,
  polyglot,
}: {
  idx: number;
  trackName: string;
  handleAddLevelClick: (track: string) => void;
  handleLevelSelect: (track: string, level: JobLevel) => void;
  jobsLookup: Dictionary<JobLevel[]>;
  polyglot: Polyglot;
}) => {
  function firstLetters(str: string) {
    return str
      .split(' ')
      .map((word) => word[0])
      .join('');
  }

  return (
    <Box sx={{ width: '18rem', flexShrink: 0 }}>
      <Box>
        <Box
          sx={{
            paddingX: spacing.p15,
            paddingY: spacing.p10,
            boxSizing: 'border-box',
            borderRadius: radius.br10,
            background: SECONDARY_CHART_COLOR[idx],
            ...spacing.mb20,
          }}
        >
          <Typography
            variant="title3"
            sx={{
              color: DARK_CONTRAST_COLOR.includes(SECONDARY_CHART_COLOR[idx])
                ? themeColors.white
                : themeColors.DarkGrey,
            }}
          >
            {trackName}
          </Typography>{' '}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          {jobsLookup[trackName].map((level, jdx) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: themeColors.Background,
                padding: spacing.p15,
                borderRadius: radius.br10,
                gap: spacing.g10,
                cursor: 'pointer',
                '&:hover': {
                  background: themeColors.GreyHover,
                },
              }}
              onClick={() => handleLevelSelect(trackName, level)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g10,
                  justifyContent: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: radius.br25,
                    background: SECONDARY_CHART_COLOR[idx],
                  }}
                >
                  <Typography
                    variant="title4"
                    sx={{
                      color: DARK_CONTRAST_COLOR.includes(SECONDARY_CHART_COLOR[idx])
                        ? themeColors.white
                        : themeColors.DarkGrey,
                    }}
                  >
                    {firstLetters(level.trackName)}
                    {jdx + 1}
                  </Typography>
                </Box>
                <Typography variant="title3">{level.levelName}</Typography>
              </Box>
              {level.levelDescription && <Typography variant="caption">{level.levelDescription}</Typography>}
            </Box>
          ))}

          <ButtonComponent
            sizeVariant="medium"
            colorVariant="dashed"
            type="button"
            fullWidth
            onClick={() => handleAddLevelClick(trackName)}
            startIcon={<Plus {...iconSize} />}
          >
            {polyglot.t('JobLevelSettingsPage.level')}
          </ButtonComponent>
        </Box>
      </Box>
    </Box>
  );
};
