import React, { useMemo, useState } from 'react';

import { getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { TrackTimeDrawer } from '@v2/feature/attendance/company/components/track-time-drawer.component';
import { format, isSameDay, isToday, isWithinInterval } from 'date-fns';

import { getAbsenceEntries, getAttendanceEntries, getScheduledEntries } from '../attendance-week.util';

import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import {
  AttendanceDto,
  AttendanceEntryDto,
  AttendanceScheduleDto,
  AttendanceTypeDto,
} from '@/v2/feature/attendance/attendance.dto';
import { ScheduleTimeEntry, ScheduleTrackingType, WeekDay } from '@/v2/feature/attendance/attendance.interface';
import { getTimeFromDateString, getWeekDates } from '@/v2/feature/attendance/attendance.util';
import { TableCalendar } from '@/v2/feature/attendance/components/table-calendar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { LocalDate } from '@/v2/util/local-date';

export interface SlotEventsProps {
  height: string;
  top: string;
  borderRadius: string /* top-left | top-right | bottom-right | bottom-left */;
  backgroundColor: string;
  showBoxShadow: boolean;
  borderStyle: string;
  borderColor: string;
  label: string;
  borderWidth: string /* top, right, bottom, left*/;
  hasAttendanceEntries: boolean;
  isScheduled: boolean;
  isAbsence: boolean;
}

export interface TimeSlot {
  dateObj: { value: string; label: string }; // { value: '2023-06-16', label: 'Fri' }
  events: SlotEventsProps[];
}

interface TimelineCalendarProps {
  readonly userId: number;
  readonly data: readonly AttendanceDto[];
  readonly attendanceSchedule: AttendanceScheduleDto | null | undefined;
  readonly weekNo: number;
  readonly year: number;
  readonly refreshRequests: () => Promise<void>;
  readonly userAbsences?: readonly AbsenceDto[] | undefined;
}

export interface EventsProps {
  logDate: string;
  attendanceEntries: AttendanceEntryDto[];
  schedule: ScheduleTimeEntry[] | null;
  absences: AbsenceDto[] | null;
}

export const ColoursByType: { [key: string]: string } = {
  Regular: themeColors.FreshGreen,
  Break: themeColors.ZeltYellow,
  Overtime: themeColors.Orange,
};

/** Note for Radu
 * Assumptions:
 * 1. attendanceSchedule: component will receive only this week schedule
 * 2. data/AttendanceDto: component will receive only this week logs
 * 3. AttendanceDto is an object per day per user, while attendanceEntries are the entries per that day
 */
export const WeekCalendar = ({
  userId,
  data,
  attendanceSchedule,
  year,
  weekNo,
  refreshRequests,
  userAbsences,
}: TimelineCalendarProps): React.JSX.Element => {
  const [isTrackTimeOpen, setIsTrackTimeOpen] = useState<boolean>(false);
  const [selectedLogDate, setSelectedLogDate] = useState<string>(new LocalDate().toDateString());
  const [selectedRequest, setSelectedRequest] = useState<AttendanceDto | undefined>(undefined);
  const datesOfWeek = useMemo(() => {
    if (data) return getWeekDates(year, weekNo);
    return [];
  }, [data, weekNo, year]);

  const attendanceTypes = useMemo(
    () =>
      (attendanceSchedule?.attendanceTypesAllowed ?? []).reduce((result, type) => {
        result[type.id] = type;
        return result;
      }, {} as Record<number, AttendanceTypeDto>) ?? ({} as Record<number, AttendanceTypeDto>),
    [attendanceSchedule]
  );

  const requestAndSchedulesPerDay = useMemo(() => {
    if (!attendanceSchedule) return [] as EventsProps[];

    return datesOfWeek.map(
      (dateObj): EventsProps => {
        const currentDate = new LocalDate(dateObj.value).getDate();
        const loggedEvents = data?.find((d) => isSameDay(new LocalDate(d.logDate).getDate(), currentDate));
        const absences =
          userAbsences?.filter((a) => {
            return (
              isSameDay(new LocalDate(a.start).getDate(), currentDate) ||
              (a.end && isSameDay(new LocalDate(a.end).getDate(), currentDate)) ||
              (a.end &&
                isWithinInterval(currentDate, {
                  start: new LocalDate(a.start).getDate(),
                  end: new LocalDate(a.end).getDate(),
                }))
            );
          }) ?? null;
        const day = format(currentDate, 'eeee').toLowerCase();
        return {
          logDate: dateObj.value,
          attendanceEntries: (loggedEvents && loggedEvents.attendanceEntries) ?? [],
          schedule: ((attendanceSchedule && attendanceSchedule[day as WeekDay]) ?? null) as ScheduleTimeEntry[] | null,
          absences: absences ?? null,
        };
      }
    );
  }, [attendanceSchedule, data, datesOfWeek, userAbsences]);

  const formatHour = (hour: number) => {
    // Format hour as needed (e.g., add leading zeros, convert to 12-hour format)
    return hour.toString().padStart(1, '0') + ':00';
  };

  const renderTimeSlots = useMemo(() => {
    const timeSlots = [];
    if (requestAndSchedulesPerDay.length > 0) {
      let earliestStartHour = 23; //as 24 hours is max

      // Find the earliest start hour in the attendance entries and schedule starts
      requestAndSchedulesPerDay.forEach(({ attendanceEntries, schedule }) => {
        attendanceEntries.forEach((entry) => {
          const startHour = parseInt(getTimeFromDateString(entry.startHour).split(':')[0]);
          if (startHour < earliestStartHour) {
            earliestStartHour = startHour;
          }
        });

        schedule &&
          schedule.forEach((slot) => {
            if (slot) {
              const slotFrom = getTimeFromDateString(slot.from);
              const startHour = parseInt(slotFrom.split(':')[0]);
              if (startHour < earliestStartHour) {
                earliestStartHour = startHour;
              }
            }
          });
      });

      earliestStartHour = earliestStartHour > 1 ? earliestStartHour - 1 : 0;
      // Find the earliest start hour in the attendance entries and schedule ends

      for (let i = 0; i < 24; i += 1) {
        const slots: TimeSlot[] = datesOfWeek.map((dateObj, index) => {
          const { schedule, attendanceEntries, absences, logDate } = requestAndSchedulesPerDay[index];
          const weekIndex = attendanceSchedule
            ? getIndexOfWeekInSchedule(attendanceSchedule.startDateOfFirstWeek, logDate, attendanceSchedule.noOfWeeks)
            : null;
          const isScheduled = schedule && weekIndex !== null && schedule[weekIndex];
          const hasAttendanceEntries = attendanceEntries && attendanceEntries.length > 0;
          const hasAbsences = absences && absences.length > 0;
          const slot = schedule && weekIndex !== null && schedule[weekIndex] ? schedule[weekIndex] : null;
          const slotFrom = slot ? getTimeFromDateString(slot.from) : null;
          const slotTo = slot ? getTimeFromDateString(slot?.to) : null;

          let events: SlotEventsProps[] = [];
          if (Boolean(hasAbsences) && absences !== null && isScheduled && slotFrom && slotTo && slot)
            getAbsenceEntries(absences, slotFrom, slotTo, i, events);
          else if (hasAttendanceEntries) getAttendanceEntries(attendanceEntries, i, events, attendanceTypes);
          else if (isScheduled && slotFrom && slotTo) getScheduledEntries(slotFrom, slotTo, i, events);

          return { dateObj, events };
        });

        const isFirstEntry = requestAndSchedulesPerDay[0].attendanceEntries.length > 0 && i === 0;
        const rowId = isFirstEntry ? 'first-entry-row' : '';
        timeSlots.push({
          index: i,
          rowId,
          slots,
          earliestStartHour,
          firstColumnLabel: formatHour(i),
        });
      }
    }
    return timeSlots;
  }, [requestAndSchedulesPerDay, datesOfWeek, attendanceSchedule, attendanceTypes]);

  return (
    <>
      <TableCalendar
        header={datesOfWeek}
        isActiveHeader={(value) => isToday(new LocalDate(value).getDate())}
        renderTimeSlots={renderTimeSlots}
        onDayClick={(date: string) => {
          const request = data.find((req) => req.logDate === date);

          // Don't allow clock-in users log attendance
          if (attendanceSchedule?.trackingType === ScheduleTrackingType.ClockInClockOut && !request) return;
          setSelectedRequest(request ?? undefined);
          setSelectedLogDate(request?.logDate ?? date);
          setIsTrackTimeOpen(true);
        }}
      />

      {/*{isTrackTimeOpen && (*/}
      <TrackTimeDrawer
        isOpen={isTrackTimeOpen}
        setIsOpen={setIsTrackTimeOpen}
        refresh={refreshRequests}
        view="user" // hardcoded to user as this view is for one user only
        userId={userId}
        attendanceId={selectedRequest?.id}
        logDate={selectedRequest?.logDate ?? selectedLogDate}
      />
      {/*)}*/}
    </>
  );
};
