import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PersonalAbsenceContent } from '@v2/feature/absence/me/policies/components/personal-absence-content.component';
import { LabelYearSelectChip } from '@v2/feature/absence/me/policies/components/year-select-chip.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Typography } from '@/v2/components/typography/typography.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { RootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly userId: number;
}

export const UserAbsencePage = ({ userId }: Props): React.JSX.Element => {
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getUserAbsencePolicies(userId, true), {
    suspense: false,
  });

  const [year, setYear] = useState<'current' | 'last' | 'next'>('current');

  const [selectedPolicy, setSelectedPolicy] = useState<AbsencePolicyDto | null>(null);

  useEffect(() => {
    if (!absencePolicies) return;
    setSelectedPolicy(absencePolicies[0] ?? null);
  }, [absencePolicies]);

  const { polyglot } = usePolyglot();
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState(false);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Typography variant="title2"> {polyglot.t('UserAbsencePage.absence')}</Typography>
            <LabelYearSelectChip year={year} setYear={setYear} />
          </Box>
        }
      />
      <PersonalAbsenceContent
        userId={userId}
        year={year}
        absencePolicies={absencePolicies ?? null}
        setIsAbsenceDrawerOpen={setIsAbsenceDrawerOpen}
        isAbsenceDrawerOpen={isAbsenceDrawerOpen}
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
      />
    </RootStyle>
  );
};
