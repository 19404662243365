import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, Stack, Typography } from '@mui/material';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';

import { ContractAPI } from '@/api-client/contract.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Success } from '@/images/side-bar-icons/ok-green.svg';
import { TEMPLATE_CONTRACT_SIGN_ROUTE } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { MissingAddressFormForPersonalContractSigning } from '@/v2/feature/templates/components/missing-address-form-contract-signing.component';
import { MissingPersonalFormForPersonalContractSigning } from '@/v2/feature/templates/components/missing-personal-form-contract-signing.component';
import {
  CONTRACT_FIELDS_CATEGORIES,
  ContractTemplateFieldValues,
  defaultMissingFieldValues,
  PERSONAL_TYPE_FIELDS,
  RequiredContractFields,
} from '@/v2/feature/templates/templates.interface';
import { getTemplateSchema } from '@/v2/feature/templates/validations/missing-field-form-validations';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { AddressValues } from '@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component';
import { PersonalInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly templateId: string;
  readonly contractId: string;
  missingFields: RequiredContractFields;
  readonly contractRecipientId: number;
  readonly refreshMissingFields: (contractId: string) => void;
}

const returnValueOrEmptyString = (input: any, suffix = ''): any => {
  if (input || (!isNaN(Number(input)) && Number(input) !== 0)) {
    return `${input}${suffix}`;
  }
  return '';
};

export const getFormattedAddress = (address: {
  address_line_1: string | null;
  address_line_2: string | null;
  address_city: string | null;
  address_country: string | null;
  address_postalcode: string | null;
}): string => {
  const addressLine1 = returnValueOrEmptyString(address?.address_line_1, ', ');
  const addressLine2 = returnValueOrEmptyString(address?.address_line_2, ', ');
  const address_city = returnValueOrEmptyString(address?.address_city, ', ');
  const postalCode = returnValueOrEmptyString(address?.address_postalcode, ', ');
  const country = returnValueOrEmptyString(address?.address_country);
  return `${addressLine1}${addressLine2}${address_city}${postalCode}${country}`;
};

export const MissingPersonalEmployeeFieldsModal = ({
  setOpen,
  open,
  templateId,
  contractId,
  missingFields,
  contractRecipientId,
  refreshMissingFields,
}: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const [isSavingMissingFields, setIsSavingMissingFields] = useState<boolean>(false);
  const [hasMissingPersonalFields, setHasMissingPersonalFields] = useState<boolean>(false);
  const [hasSomeMissingFields, setHasSomeMissingFields] = useState<boolean>(false);
  const [fillingMissingPersonalFields, setFillingMissingPersonalFields] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const categorisedMissingFields: MissingFieldForContractTemplate[] = useMemo(() => {
    const missingFieldsForTemplate: MissingFieldForContractTemplate[] = [];
    for (const eachField of Object.keys(missingFields)) {
      missingFieldsForTemplate.push({
        category: CONTRACT_FIELDS_CATEGORIES[eachField],
        fieldId: eachField,
      });
    }

    return missingFieldsForTemplate;
  }, [missingFields]);

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    if (contractId) await refreshMissingFields?.(contractId);
    setIsRefreshing(false);
  }, [contractId, refreshMissingFields]);

  const sections = useMemo(() => {
    const updateContractWithMissingDomainFields = async (values: AddressValues | PersonalInformationValues) => {
      try {
        const addressValues = values as AddressValues;
        const personalValues = values as PersonalInformationValues;
        const templateFields = {
          employee_address: addressValues?.addressLine1
            ? getFormattedAddress({
                address_line_1: returnValueOrEmptyString(addressValues.addressLine1),
                address_line_2: returnValueOrEmptyString(addressValues.addressLine2),
                address_city: returnValueOrEmptyString(addressValues.city),
                address_country: returnValueOrEmptyString(addressValues.country),
                address_postalcode: returnValueOrEmptyString(addressValues.postcode),
              })
            : '',
          employee_address_line1: addressValues?.addressLine1,
          employee_address_line2: addressValues?.addressLine2,
          employee_address_city: addressValues?.city,
          employee_address_country: addressValues?.country,
          employee_address_postcode: addressValues?.postcode,

          // Personal Information
          employee_dob: personalValues?.dob ? personalValues.dob : undefined,
          employee_passport_number: personalValues?.passportNumber ? personalValues.passportNumber : undefined,
          employee_personal_email: personalValues?.personalEmail ? personalValues.personalEmail : undefined,
        };
        const finalData = { ...values, ...templateFields };

        setIsSavingMissingFields(true);

        await ContractAPI.updateContractWithMissingEmployeeFields({
          contractId,
          recipientId: contractRecipientId,
          fieldsToSave: finalData,
        });

        showMessage(polyglot.t('MissingPersonalEmployeeFieldsModal.successMessages.update'), 'success');
      } catch (error) {
        console.error('Failed to save data', error.message);
        showMessage(polyglot.t('MissingPersonalEmployeeFieldsModal.errorMessages.save'), 'error');
      } finally {
        setIsSavingMissingFields(false);
      }
    };

    const hasMissingField = (category: string) => categorisedMissingFields.some((f) => f.category === category);
    const result: React.JSX.Element[] = [];
    const listOfMissingFieldsForCategory = (category: CustomProfileFormType) =>
      categorisedMissingFields.filter((f) => f.category === category);
    if (hasMissingField(CustomProfileFormType.Details))
      result.push(
        <MissingPersonalFormForPersonalContractSigning
          userId={contractRecipientId}
          refreshData={handleRefresh}
          updateDomainForMissingFields={updateContractWithMissingDomainFields}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Details)}
        />
      );
    if (hasMissingField(CustomProfileFormType.Address))
      result.push(
        <MissingAddressFormForPersonalContractSigning
          userId={contractRecipientId}
          refreshData={handleRefresh}
          updateDomainForMissingFields={updateContractWithMissingDomainFields}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Address)}
        />
      );
    return result;
  }, [polyglot, categorisedMissingFields, contractId, contractRecipientId, handleRefresh, showMessage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redirectToSendContractRoutePage = async () => {
    routerHistory.push(
      generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
        templateId,
        contractId,
        userId: contractRecipientId,
      })
    );
  };

  useEffect(() => {
    let hasMissing = false;
    // update code to ensure that if no missing fields are detected in either category that we set no missingFields to true
    Object.keys(missingFields).forEach((key) => {
      if (PERSONAL_TYPE_FIELDS.includes(key)) {
        hasMissing = true;
        setHasMissingPersonalFields(true);
        setFillingMissingPersonalFields(true);
      }
    });
    setHasSomeMissingFields(hasMissing);
    // if no missing fields then auto move to preview contract
    if (!hasMissing) redirectToSendContractRoutePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingFields]);

  const handleSubmitAction = () => {
    if (fillingMissingPersonalFields) {
      setFillingMissingPersonalFields(false);
      redirectToSendContractRoutePage();
    }
  };

  const formik = useFormik<ContractTemplateFieldValues>({
    initialValues: defaultMissingFieldValues,
    enableReinitialize: true,
    validationSchema: getTemplateSchema(missingFields),
    onSubmit: handleSubmitAction,
  });

  const disableMissingFields = useMemo(() => {
    let disable = true;

    // if no missing fields then do not disable
    if (Object.keys(missingFields).length === 0) disable = false;

    for (const key of Object.keys(missingFields).filter((f) => Boolean(f))) {
      // if any personal fields are missing then disable
      if (PERSONAL_TYPE_FIELDS.includes(key)) {
        disable = true;
      }
      if (disable) break;
    }

    return disable;
  }, [missingFields]);

  const missingFieldsButtonText = useMemo(() => {
    if (!hasSomeMissingFields) {
      return polyglot.t('MissingPersonalEmployeeFieldsModal.preview');
    }
    if (hasMissingPersonalFields && fillingMissingPersonalFields) {
      return polyglot.t('MissingPersonalEmployeeFieldsModal.updateAndPreview');
    }
    return polyglot.t('MissingPersonalEmployeeFieldsModal.preview');
  }, [polyglot, fillingMissingPersonalFields, hasMissingPersonalFields, hasSomeMissingFields]);

  return (
    <>
      <DrawerModal isOpen={open} setIsOpen={setOpen}>
        <FormikProvider value={formik}>
          <Form>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  paddingBottom: spacing.p10,
                }}
              >
                <Typography sx={{ ...themeFonts.title2 }}>
                  {polyglot.t('MissingPersonalEmployeeFieldsModal.missingInformation')}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  paddingBottom: spacing.p10,
                }}
              >
                <Typography sx={{ ...themeFonts.caption }}>
                  {polyglot.t('MissingPersonalEmployeeFieldsModal.missingInformationDesc')}
                </Typography>
              </Box>

              <Stack sx={{ mt: spacing.mt20 }}>
                {isRefreshing && <LoadingSpinner />}
                {!isRefreshing && sections.length === 0 && (
                  <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10, mt: spacing.mt20 }}>
                    <Success height="1em" style={{ fill: themeColors.Green, flexShrink: 0 }} />
                    <Typography sx={themeFonts.caption}>
                      {polyglot.t('MissingPersonalEmployeeFieldsModal.allInfoPresent')}
                    </Typography>
                  </Stack>
                )}
                {!isRefreshing &&
                  sections.length > 0 &&
                  sections.map((section, idx) => (
                    <Stack
                      key={idx}
                      sx={{ py: spacing.p10, borderTop: idx ? `1px solid ${themeColors.lightGrey}` : undefined }}
                    >
                      {section}
                    </Stack>
                  ))}
              </Stack>

              {fillingMissingPersonalFields && (
                <>
                  {missingFields.employee_first_name || missingFields.employee_last_name ? (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        paddingBottom: spacing.p10,
                      }}
                    >
                      <Typography sx={{ ...themeFonts.title4 }}>
                        {polyglot.t('MissingPersonalEmployeeFieldsModal.userProfile')}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  )}
                  {missingFields.employee_first_name && (
                    <FormControl sx={{ ...fieldSx }}>
                      <TextfieldComponent
                        name="employee_first_name"
                        label={polyglot.t('MissingPersonalEmployeeFieldsModal.employee_first_name')}
                        value={formik.values.employee_first_name}
                        type="text"
                        onChange={formik.handleChange}
                        error={!!formik.errors.employee_first_name}
                        helperText={formik.errors.employee_first_name}
                        clearText={() => formik.setFieldValue('employee_first_name', '')}
                      />
                    </FormControl>
                  )}

                  {missingFields.employee_last_name && (
                    <FormControl sx={{ marginTop: spacing.m30 }}>
                      <TextfieldComponent
                        name="employee_last_name"
                        label={polyglot.t('MissingPersonalEmployeeFieldsModal.employee_last_name')}
                        value={formik.values.employee_last_name}
                        type="text"
                        onChange={formik.handleChange}
                        error={!!formik.errors.employee_last_name}
                        helperText={formik.errors.employee_last_name}
                        clearText={() => formik.setFieldValue('employee_last_name', '')}
                      />
                    </FormControl>
                  )}
                </>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', ...spacing.mt40 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <LoaderButton
                    sizeVariant="large"
                    colorVariant="primary"
                    fullWidth={true}
                    name={missingFieldsButtonText}
                    loading={isSavingMissingFields}
                    disabled={disableMissingFields}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </DrawerModal>
    </>
  );
};
