import axios from 'axios';
import {
  ContractTemplate,
  ContractTemplateTestResult,
  CreateTemplateData,
  GetContractPreviewData,
  TemplatePreviewResult,
  VerifyTemplateResult,
} from '@/v2/feature/templates/templates.interface';

export class TemplateAPI {
  static async getTemplates(param?: { searchQuery?: string }): Promise<ContractTemplate[]> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/contract-template?${searchParams.toString()}`)).data;
  }

  static async createTemplate(parameters: CreateTemplateData): Promise<string> {
    return (await axios.post('/apiv2/contract-template', parameters)).data;
  }

  static async getTemplateById(parameters: { templateId: string }): Promise<ContractTemplate> {
    return (await axios.get(`/apiv2/contract-template/${parameters.templateId}`)).data;
  }

  static async updateTemplate(parameters: {
    id: string;
    templateText: string;
    name?: string;
    type: string | null;
  }): Promise<ContractTemplateTestResult> {
    return (await axios.put('/apiv2/contract-template', parameters)).data;
  }

  static async verifyTemplateParameters(parameters: {
    templateId: string;
    contractRecipientUserId: number;
    companySignatoryUserId?: number;
  }): Promise<VerifyTemplateResult> {
    return (await axios.post('/apiv2/contract-template/verify-template-parameters', parameters)).data;
  }

  static async getContractPreview(parameters: GetContractPreviewData): Promise<TemplatePreviewResult> {
    return (await axios.post('/apiv2/contract-template/preview', parameters)).data;
  }

  static async downloadContractTemplatePreview(parameters: GetContractPreviewData): Promise<{ data: Buffer }> {
    return (await axios.post(`/apiv2/contract-template/download`, parameters)).data;
  }

  static async deleteTemplate(parameters: { templateId: string }): Promise<void> {
    return (await axios.put('/apiv2/contract-template/delete', parameters)).data;
  }

  static async duplicateTemplate(parameters: { id: string }): Promise<void> {
    return (await axios.post('/apiv2/contract-template/duplicate', parameters)).data;
  }
}
