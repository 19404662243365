import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import {
  DocumentFormModalAction,
  DocumentFormModalState,
  filterDocuments,
  getDocumentParentCategory,
  getDocumentTypeListBasedOnAudience,
  getPinnedDocuments,
} from '@v2/feature/documents/documents.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory, useLocation } from 'react-router-dom';

import type { ZeltDocument, ZeltDocumentType } from '@/lib/documents';

import { ContractAPI } from '@/api-client/contract.api';
import { TemplateAPI } from '@/api-client/templates.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE } from '@/lib/routes';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { DocumentTable } from '@/v2/feature/documents/components/document-table.component';
import { NewDocumentButton } from '@/v2/feature/documents/components/new-document-action-button.component';
import { PinnedDocument } from '@/v2/feature/documents/components/pinned-document.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { BufferData, DocumentAction, PreviewPayload } from '@/v2/feature/documents/documents.interface';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { ContractModal } from '@/v2/feature/templates/components/contract-modal.component';
import { MissingPersonalEmployeeFieldsModal } from '@/v2/feature/templates/components/missing-personal-employee-fields-modal.component';
import { MissingTemplateFieldModal } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { SelectTemplateForContractModal } from '@/v2/feature/templates/components/select-template-for-contract-modal.component';
import {
  ContractObject,
  ContractTemplate,
  RequiredContractFields,
  VerifyTemplateResult,
} from '@/v2/feature/templates/templates.interface';
import { TemplatesScopes } from '@/v2/feature/templates/templates.scopes';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface UserDocumentsPageProps {
  readonly userId: number;
  readonly userName: string;
  readonly setDocumentFormMode: React.Dispatch<React.SetStateAction<DocumentAction>>;
  readonly documentState: DocumentFormModalState;
  readonly documentDispatch: React.Dispatch<DocumentFormModalAction>;
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  documents: readonly ZeltDocument[];
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
}

export const PersonalDocuments = ({
  userId,
  userName,
  setDocumentFormMode,
  documentState,
  documentDispatch,
  setOpen,
  loading,
  documents,
  setFilterValue,
}: UserDocumentsPageProps) => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const [searchInput, setSearchInput] = useState<string>('');
  const [paginationReset, setPaginationReset] = useState<boolean>(false);
  const { trackPage } = useJune();

  const [filterString, setFilterString] = useState<string>('');
  const routerLocation = useLocation();
  const currentUserIsDocumentAdmin = hasScopes(
    ['documents:all', 'documents:manager'],
    getScopesContext(globalState.user)
  );
  const currentUserIsTemplateAdmin = hasScopes(
    TemplatesScopes.LIST_CONTRACT_TEMPLATES,
    getScopesContext({ userId: globalState.user?.userId })
  );
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const allUsers = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);

  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState<boolean>(false);
  const [missingFieldsForContractSigning, setMissingFieldsForContractSigning] = useState<RequiredContractFields>();
  const [selectedContract, setSelectedContract] = useState<ContractObject>();

  const [showMessage] = useMessage();

  // for document preview
  const [selectedDocBuffer, setSelectedDocBuffer] = useState<BufferData | null>();
  const [selectedDocContentType, setSelectedDocContentType] = useState<string>('');
  const [selectedDocName, setSelectedDocName] = useState('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  /*Contract modal reqs - start */
  const [templates, setTemplates] = useState<readonly ContractTemplate[]>([]);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openContractModal, setOpenContractModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ContractTemplate | undefined>(undefined);

  const [missingFields, setMissingFields] = useState<RequiredContractFields>();
  const [contractRecipientUserId, setContractRecipientUserId] = useState<number>();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState<number>();
  /*Contract modal reqs - end */

  const filteredDocuments = useMemo(() => filterDocuments(searchInput)(documents as ZeltDocument[])(filterString), [
    documents,
    searchInput,
    filterString,
  ]);

  const pinnedDocuments = useMemo(() => getPinnedDocuments(documents as ZeltDocument[]), [documents]);

  const handleUploadDocument = () => {
    documentDispatch({ kind: 'add_document', owner: { label: userName, value: userId } });
    setDocumentFormMode('add');
    setFilterValue(
      getDocumentParentCategory(
        'me',
        'add',
        undefined,
        currentUserIsDocumentAdmin,
        documentState.types as ZeltDocumentType[]
      )
    );
    setOpen(true);
  };

  const handleRequestDocument = () => {
    documentDispatch({ kind: 'request_document', owner: { label: userName, value: userId }, reach: 'me' });
    setDocumentFormMode('request');
    setFilterValue(
      getDocumentParentCategory(
        'me',
        'request',
        undefined,
        currentUserIsDocumentAdmin,
        documentState.types as ZeltDocumentType[]
      )
    );
    setOpen(true);
  };

  const refreshMissingFieldsForEmployeeContract = async (contractId: string) => {
    const missingFields = await ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFieldsForContractSigning(missingFields);
  };

  const handleMissingFieldContractPopulation = (missingFields: RequiredContractFields, contract: ContractObject) => {
    setMissingFieldsForContractSigning(missingFields);
    setSelectedContract(contract);
    setOpenMissingFieldContractModal(true);
  };

  const handleBulkUploadDocument = () => {
    routerHistory.push(DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE);
  };

  const handleTemplateClick = () => {
    setOpenTemplateModal(true);
  };

  const fetchTemplates = useCallback(
    async (params?: { searchQuery: string }) => {
      try {
        const templates = await TemplateAPI.getTemplates(params);
        setTemplates(templates || []);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, polyglot]
  );

  const handlePreviewClick = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        const fileUuidFromAttachment =
          zeltDocument.attachments && zeltDocument.attachments[0] && zeltDocument.attachments[0]?.fileUuid;
        const finalUuid = zeltDocument.fileUuid ?? fileUuidFromAttachment;
        if (!finalUuid) return;
        await DocumentAPI.previewViaUuid(finalUuid).then(async ({ contentType, file }: PreviewPayload) => {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        });
      } catch (e) {
        console.error('::URL Download error', e);
        showMessage(
          polyglot.t('PersonalDocuments.errorMessages.preview', { errorMessage: nestErrorMessage(e) }),
          'error'
        );
      }
    },
    [setOpenPreviewModal, setSelectedDocBuffer, setSelectedDocName, showMessage, polyglot]
  );

  const refreshMissingFieldsForTemplate = useCallback(
    async (templateId: string, contractRecipientUserId: number, companySignatoryUserId: number | undefined) => {
      try {
        const templateResult = await TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId,
          companySignatoryUserId,
        });

        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.verify', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, polyglot]
  );

  const onMissingField = (params: {
    templateVerificationResult: VerifyTemplateResult;
    contractRecipientUserId: number;
  }) => {
    const { contractRecipientUserId, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };

  useEffect(() => {
    trackPage('Personal documents');
    if (currentUserIsTemplateAdmin) fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper loading={loading} sx={{ ...(pinnedDocuments.length > 0 ? spacing.pt40 : spacing.pt20) }}>
      {pinnedDocuments.length > 0 && (
        <Box sx={{ ...spacing.pb40 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: spacing.g60,
              rowGap: spacing.g20,
              flexWrap: 'wrap',
            }}
          >
            <PinnedDocument
              pinnedDocuments={pinnedDocuments}
              onEditClick={(value) => {
                documentDispatch({ kind: 'edit_document', value, userList: allUsers });
                setDocumentFormMode('edit');
                setFilterValue(
                  getDocumentParentCategory(
                    'me',
                    'edit',
                    value,
                    currentUserIsDocumentAdmin,
                    documentState.types as ZeltDocumentType[]
                  )
                );
                setOpen(true);
              }}
              documentTypes={[...documentState.types]}
              personalDocuments={true}
              onPreviewClick={handlePreviewClick}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <CategoryFilters
            filterTypes={{
              type: getDocumentTypeListBasedOnAudience(documentState.types, currentUserIsDocumentAdmin).map((d) => {
                return { value: d.value, label: d.label };
              }),
            }}
            setFilterString={setFilterString}
            filterString={filterString}
          />
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value?.trim() ?? '');
              setPaginationReset(true);
            }}
            handleBlur={() => setPaginationReset(false)}
          />
        </Box>
        {!routerLocation.pathname.includes('/me') && (
          <NewDocumentButton
            onUploadClick={handleUploadDocument}
            onRequestClick={handleRequestDocument}
            onBulkUploadClick={currentUserIsDocumentAdmin ? handleBulkUploadDocument : undefined}
            onTemplateClick={currentUserIsDocumentAdmin ? handleTemplateClick : undefined}
            size="small"
            isAdmin={currentUserIsDocumentAdmin}
          />
        )}
      </Box>

      <Box sx={{ ...spacing.mt20 }}>
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="31%"
              height="50%"
              sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
            />
          }
        >
          <DocumentTable
            documents={filteredDocuments}
            documentTypes={[...documentState.types]}
            personalDocuments={true}
            onEditClick={(value) => {
              documentDispatch({ kind: 'edit_document', value, userList: allUsers });
              setDocumentFormMode('edit');
              setFilterValue(
                getDocumentParentCategory(
                  'me',
                  'edit',
                  value,
                  currentUserIsDocumentAdmin,
                  documentState.types as ZeltDocumentType[]
                )
              );
              setOpen(true);
            }}
            paginationReset={paginationReset}
            onMissingContractFields={handleMissingFieldContractPopulation}
            setSelectedDocBuffer={setSelectedDocBuffer}
            setSelectedDocContentType={setSelectedDocContentType}
            setSelectedDocName={setSelectedDocName}
            setOpenPreviewModal={setOpenPreviewModal}
          />
        </Suspense>

        {selectedContract && missingFieldsForContractSigning && openMissingFieldContractModal && (
          <MissingPersonalEmployeeFieldsModal
            open={openMissingFieldContractModal}
            setOpen={setOpenMissingFieldContractModal}
            templateId={selectedContract.templateId}
            contractId={selectedContract.id}
            missingFields={missingFieldsForContractSigning}
            contractRecipientId={selectedContract.recipient}
            refreshMissingFields={refreshMissingFieldsForEmployeeContract}
          />
        )}

        {selectedDocBuffer && openPreviewModal && (
          <DocPreviewer
            fileBuffer={selectedDocBuffer}
            contentType={selectedDocContentType}
            visible
            onClose={() => setOpenPreviewModal(false)}
            title={selectedDocName}
          />
        )}

        {selectedTemplate && openContractModal && (
          <ContractModal
            open={openContractModal}
            setOpen={setOpenContractModal}
            contractTemplate={selectedTemplate}
            onMissingField={onMissingField}
            existingRecipient={userId}
          />
        )}

        {selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && (
          <MissingTemplateFieldModal
            open={openMissingFieldContractModal}
            setOpen={setOpenMissingFieldContractModal}
            templateId={selectedTemplate.id}
            missingFields={missingFields}
            contractRecipientId={contractRecipientUserId}
            companySignatoryUserId={contractCompanySignatoryUserId}
            refreshMissingFields={refreshMissingFieldsForTemplate}
          />
        )}

        {setSelectedTemplate && templates && openTemplateModal && (
          <SelectTemplateForContractModal
            open={openTemplateModal}
            setOpen={setOpenTemplateModal}
            templateList={templates}
            setSelectedTemplate={(template?: ContractTemplate) => {
              setSelectedTemplate(template ?? undefined);
              setOpenContractModal(true);
            }}
          />
        )}
      </Box>
    </ContentWrapper>
  );
};
