import React, { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Loader } from '@v2/components/loader.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Apps } from '@/images/side-bar-icons/Apps.svg';
import { ReactComponent as Bill } from '@/images/side-bar-icons/Bill.svg';
import { ReactComponent as Coin } from '@/images/side-bar-icons/Coin.svg';
import { ReactComponent as Device } from '@/images/side-bar-icons/Device.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DASHBOARD_ROUTE } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationUserDto } from '@/v2/feature/app-integration/app-integration.dto';
import { DeviceOrderDto, DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserDeletionBlockerItem } from '@/v2/feature/user/features/user-profile-action/components/user-deletion-blocker-item.component';
import { UserProfileActionAPI } from '@/v2/feature/user/features/user-profile-action/user-profile-action.api';
import { iconSize } from '@/v2/styles/menu.styles';
import { pluralText } from '@/v2/util/string.util';

export interface Props {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userId: number;
  readonly loading: boolean;
  readonly userDeviceDetails: readonly DevicePossessionDto[];
  readonly userDeviceOrders: readonly DeviceOrderDto[];
  readonly userAppAccounts: readonly AppIntegrationUserDto[];
  readonly inPayrun: boolean;
  readonly isBillingContact: boolean;
  readonly hasPayments: boolean;
  readonly deletionBlocked: boolean;
}

export const UserProfileDeleteModal = ({
  isOpen,
  setIsOpen,
  userId,
  loading,
  deletionBlocked,
  userDeviceDetails,
  userDeviceOrders,
  userAppAccounts,
  inPayrun,
  isBillingContact,
  hasPayments,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  const [showMessage] = useMessage();
  const { refreshCachedUsers } = useCachedUsers();

  const [confirmText, setConfirmText] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const closeDeleteModal = () => {
    setConfirmText('');
  };

  const triggerDelete = async () => {
    try {
      setIsDeleting(true);
      await UserProfileActionAPI.deleteUser(userId);
      showMessage(polyglot.t('UserProfileDeleteModal.successMessages.delete'), 'success');
      routerHistory.push(DASHBOARD_ROUTE);
    } catch (err) {
      showMessage(`${polyglot.t('UserProfileDeleteModal.errorMessages.delete')}: ${nestErrorMessage(err)}`, 'error');
    } finally {
      await refreshCachedUsers();
      closeDeleteModal();
      setIsDeleting(false);
    }
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      {loading ? (
        <Box>
          <Loader loading={loading} children={null} />
        </Box>
      ) : (
        <Box sx={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('General.delete')}</Typography>
          {!deletionBlocked ? (
            <Box sx={drawerContentSx}>
              <Typography variant="caption">{polyglot.t('UserProfileDeleteModal.warning')} </Typography>

              <TextfieldComponent
                name="confirmTxt"
                label={polyglot.t('UserProfileDeleteModal.typeHere')}
                autoFocus
                autoComplete="off"
                value={confirmText}
                onChange={(newVal) => {
                  if (!newVal) return;
                  setConfirmText(newVal.currentTarget.value);
                }}
              />

              <Box sx={buttonBoxDrawerSx}>
                <LoaderButton
                  disabled={confirmText?.toLowerCase() !== 'confirm' || isDeleting}
                  name={
                    isDeleting
                      ? polyglot.t('UserProfileDeleteModal.deleting')
                      : polyglot.t('UserProfileDeleteModal.del')
                  }
                  onClick={triggerDelete}
                  loading={isDeleting}
                  fullWidth
                  sizeVariant="medium"
                  colorVariant="primary"
                />
              </Box>
            </Box>
          ) : (
            deletionBlocked && (
              <Box sx={drawerContentSx}>
                <Typography variant="caption">
                  {polyglot.t('UserProfileDeleteModal.someItemsCannotBeDeleted')}
                </Typography>
                <Stack gap="20px" spacing={2}>
                  {userAppAccounts?.length > 0 && (
                    <UserDeletionBlockerItem
                      icon={<Apps {...iconSize} />}
                      text={`${userAppAccounts.length} ${polyglot.t('UserProfileDeleteModal.appAccount')}${pluralText(
                        userAppAccounts.length
                      )}`}
                    />
                  )}
                  {userDeviceDetails?.length > 0 && (
                    <UserDeletionBlockerItem
                      icon={<Device {...iconSize} />}
                      text={`${userDeviceDetails.length} ${polyglot.t('UserProfileDeleteModal.device')}${pluralText(
                        userDeviceDetails.length
                      )}`}
                    />
                  )}
                  {userDeviceOrders?.length > 0 && (
                    <UserDeletionBlockerItem
                      icon={<Device {...iconSize} />}
                      text={`${userDeviceOrders.length} ${polyglot.t(
                        'UserProfileDeleteModal.deviceOrder'
                      )} ${pluralText(userDeviceDetails.length)}`}
                    />
                  )}
                  {inPayrun && (
                    <UserDeletionBlockerItem
                      icon={<Coin {...iconSize} />}
                      text={polyglot.t('UserProfileDeleteModal.payrollRecord')}
                    />
                  )}
                  {isBillingContact && (
                    <UserDeletionBlockerItem
                      icon={<Bill {...iconSize} />}
                      text={polyglot.t('UserProfileDeleteModal.billingContact')}
                    />
                  )}
                  {hasPayments && (
                    <UserDeletionBlockerItem
                      icon={<Bill {...iconSize} />}
                      text={polyglot.t('UserProfileDeleteModal.hasPayments')}
                    />
                  )}
                </Stack>
              </Box>
            )
          )}
        </Box>
      )}
    </DrawerModal>
  );
};
