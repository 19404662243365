import Polyglot from 'node-polyglot';

import { PackageDetail } from '@/v2/feature/id-check/id-check.interface';

export const mapPackageDetailsToString = (packageDetails: PackageDetail, polyglot: Polyglot): string => {
  return Object.keys(packageDetails)
    .map((key) => polyglot.t(`IdCheckPackage.id.${key}`).replace(' check', ''))
    .join(', ');
};

export const getIndividualCheckOptions = (polyglot: Polyglot) => {
  return [
    { value: 'addressCheck', label: polyglot.t('IdCheckPackage.id.addressCheck') },
    { value: 'creditCheck', label: polyglot.t('IdCheckPackage.id.creditCheck') },
    { value: 'criminalCheck', label: polyglot.t('IdCheckPackage.id.criminalCheck') },
    { value: 'directorshipCheck', label: polyglot.t('IdCheckPackage.id.directorshipCheck') },
    { value: 'documentCheck', label: polyglot.t('IdCheckPackage.id.documentCheck') },
    { value: 'educationCheck', label: polyglot.t('IdCheckPackage.id.educationCheck') },
    { value: 'financialCheck', label: polyglot.t('IdCheckPackage.id.financialCheck') },
    { value: 'referenceCheck', label: polyglot.t('IdCheckPackage.id.referenceCheck') },
    { value: 'rightToWorkCheck', label: polyglot.t('IdCheckPackage.id.rightToWorkCheck') },
    { value: 'sanctionsCheck', label: polyglot.t('IdCheckPackage.id.sanctionsCheck') },
    { value: 'selfCertificationCheck', label: polyglot.t('IdCheckPackage.id.selfCertificationCheck') },
  ];
};

// export const getIdCheckPackageOptions = (polyglot: Polyglot) => {
//   return [
//     { value: 'standard', label: polyglot.t('IdCheckPackage.pkg.standard') },
//     { value: 'professional', label: polyglot.t('IdCheckPackage.pkg.professional') },
//     { value: 'advanced', label: polyglot.t('IdCheckPackage.pkg.advanced') },
//     { value: 'criminalCheck', label: polyglot.t('IdCheckPackage.pkg.criminalCheck') },
//     { value: 'financial', label: polyglot.t('IdCheckPackage.pkg.financial') },
//     { value: 'premium', label: polyglot.t('IdCheckPackage.pkg.premium') },
//   ];
// };
