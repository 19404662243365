import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { PARTNER_API_AUTHORISE_ROUTE } from '@/lib/routes';
import { ConsentPage } from '@/v2/feature/public-api/consent.page';

export const PublicAPIRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return (
    <Switch>
      <RouteScopesControl scopes={['company.settings:all']} path={PARTNER_API_AUTHORISE_ROUTE} context={scopesContext}>
        <ConsentPage />
      </RouteScopesControl>

      <Redirect to={PARTNER_API_AUTHORISE_ROUTE} />
    </Switch>
  );
};
