import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ConfigurableDeviceData } from '@v2/feature/device/device.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { generatePath } from 'react-router-dom';

import { DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE } from '@/lib/routes';

export interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly device: ConfigurableDeviceData | null;
}

export const OffboardingDeviceGenericDrawer = ({ isOpen, setIsOpen, onClose, device }: DrawerProps) => {
  const openDeviceDetailsPage = useCallback(() => {
    if (!device?.devicePossession) return;
    const path = generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, {
      devicePossessionId: device.devicePossession.id,
    });

    window.open(path, '_blank');
  }, [device]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      {!device?.devicePossession ? (
        <></>
      ) : (
        <Box sx={drawerContentSx}>
          <Typography variant="title2">Manage device</Typography>

          <Typography variant="caption">This device can be managed only from the tech module</Typography>

          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent sizeVariant="medium" colorVariant="primary" onClick={openDeviceDetailsPage} fullWidth>
              Go to device page
            </ButtonComponent>
          </Box>
        </Box>
      )}
    </DrawerModal>
  );
};
