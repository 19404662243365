import { Stack, SxProps, Theme } from '@mui/material';

import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunButtonsProps = {
  showBack?: boolean;
  primaryButtonLabel: string;
  loadingPrimary?: boolean;
  disabledPrimary?: boolean;
  disabledBack?: boolean;
  onPrimaryClick?: () => void;
  onBackClick?: () => void;
  sx?: SxProps<Theme>;
};

export const PayrunButtons = ({
  showBack,
  primaryButtonLabel,
  loadingPrimary,
  disabledPrimary,
  disabledBack,
  onPrimaryClick,
  onBackClick,
  sx,
}: PayrunButtonsProps) => {
  return (
    <Stack flexDirection="row" sx={{ gap: spacing.g20, ...sx }}>
      {showBack && (
        <ButtonComponent
          sizeVariant="large"
          colorVariant="secondary"
          disabled={disabledBack}
          onClick={onBackClick}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Back style={{ marginRight: spacing.m4 }} />
          Go back
        </ButtonComponent>
      )}
      <LoaderButton
        type="button"
        name={primaryButtonLabel}
        loading={loadingPrimary}
        disabled={disabledPrimary}
        onClick={onPrimaryClick}
        sizeVariant="large"
        colorVariant="primary"
      />
    </Stack>
  );
};
