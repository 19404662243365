import React, { useState } from 'react';

import { Box, List, ListItem, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceModelDto, DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceContractLength } from '@v2/feature/device/device.interface';
import { DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS } from '@v2/feature/device/device.util';
import { buttonBoxSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface EditOrderFormData {
  deviceModelId: number;
  price: number;
  contractLength: number;
  phoneNumber: string | null;
  deliveryAddress: string;
  deliveryDate: string | null;
}

interface SuperAdminEditOrderFormProps {
  readonly deviceOrder: DeviceOrderDto;
  readonly deviceModels: readonly DeviceModelDto[];
  readonly refresh: () => Promise<void>;
}

export const SuperAdminEditOrderForm = ({ deviceOrder, deviceModels, refresh }: SuperAdminEditOrderFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<EditOrderFormData>({
    initialValues: {
      deviceModelId: deviceOrder.deviceModelId,
      price: deviceOrder.price,
      contractLength: deviceOrder.contractLength ?? DeviceContractLength.Months36,
      phoneNumber: deviceOrder.phoneNumber,
      deliveryAddress: deviceOrder.deliveryAddress ?? '',
      deliveryDate: deviceOrder.deliveryDate ?? new LocalDate().toDateString(),
    },
    validationSchema: Yup.object({
      deviceModelId: Yup.number().required('Device Model is required').typeError('You must specify a number.'),
      price: Yup.number().required('Price is required').typeError('You must specify a number.'),
      contractLength: Yup.number().required('Contract Length is required').typeError('You must specify a number.'),
      phoneNumber: Yup.string().nullable().notRequired(),
      deliveryAddress: Yup.string().required('Delivery Address is required'),
      deliveryDate: Yup.string().test(dateFieldTest).required('Delivery Date is required'),
    }),
    onSubmit: async (values): Promise<void> => {
      const { deviceModelId, price, contractLength, phoneNumber, deliveryAddress, deliveryDate } = values;

      try {
        setLoading(true);
        await DeviceAPI.updateDeviceOrderAsSuperadmin(deviceOrder.id, {
          deviceModelId,
          price: Number(price),
          contractLength,
          phoneNumber,
          deliveryAddress,
          deliveryDate,
        });
        showMessage('Order successfully updated.', 'success');
        await refresh();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        <Box>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>Select model</Typography>
          {deviceModels && (
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              {deviceModels.map((deviceModel) => (
                <ListItem
                  onClick={() => {
                    formik.setFieldValue('deviceModelId', deviceModel.id);
                  }}
                  sx={{
                    border: deviceModel.id === formik.values.deviceModelId ? '2px solid #a0a0a0' : '1px solid #e0e0e0',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: '40%' }}>
                    <Typography variant="body2" align="center">
                      {deviceModel.modelName}
                    </Typography>
                    <Typography fontSize="12px" fontWeight="bold" align="center">
                      {deviceModel.id}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px">RAM: {deviceModel.ram} GB</Typography>
                    <Typography fontSize="12px">Storage: {deviceModel.storage} GB</Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="12px">CPU: {deviceModel.cpuCores}-cores</Typography>
                    <Typography fontSize="12px">
                      Expected delivery time: {deviceModel.deliveryDays} day{deviceModel.deliveryDays > 1 ? 's' : ''}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <DatePickerComponent
          inputFormat="DD/MM/YYYY"
          value={formik.values.deliveryDate ?? new LocalDate().toDateString()}
          onChange={(value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue('deliveryDate', value);
            }
          }}
          name="deliveryDate"
          label="Delivery date"
          error={!!formik.touched.deliveryDate && !!formik.errors.deliveryDate}
          helperText={(formik.touched.deliveryDate && formik.errors.deliveryDate) as string}
        />
        <TextfieldComponent
          label="Delivery Address"
          name="deliveryAddress"
          value={formik.values.deliveryAddress}
          onChange={formik.handleChange}
          error={formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress)}
          helperText={(formik.touched.deliveryAddress && formik.errors.deliveryAddress) as string}
          fullWidth
          size="small"
          endAdornment="none"
        />
        <TextfieldComponent
          label="Phone Number"
          name="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={(formik.touched.phoneNumber && formik.errors.phoneNumber) as string}
          fullWidth
          size="small"
          endAdornment="none"
        />
        <SelectComponent
          name="contractLength"
          label="Contract Length"
          options={DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS}
          value={formik.values.contractLength}
          onChange={formik.handleChange}
          error={formik.touched.contractLength && Boolean(formik.errors.contractLength)}
          helperText={(formik.touched.contractLength && formik.errors.contractLength) as string}
        />
        <TextfieldComponent
          label="Price"
          name="price"
          value={formik.values.price}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={(formik.touched.price && formik.errors.price) as string}
          fullWidth
          size="small"
          endAdornment="none"
        />
        <Box sx={buttonBoxSx}>
          <LoaderButton name="Update order" loading={loading} fullWidth sizeVariant="large" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
