import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/scss/components/progress-bar.scss';
import { spacing } from '@/v2/styles/spacing.styles';

export const ProgressBar = ({ progress, label, showRed }: { progress: number; label: string; showRed?: boolean }) => {
  const percentage = Math.min(100, Math.max(0, progress));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8, width: '100%' }}>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', minHeight: '6px' }}>
        <div className={`progress-bar ${showRed ? 'progress-border-red' : 'progress-no-border'}`}>
          <div
            className={`progress-bar-fill ${showRed ? 'progress-bar-red' : 'progress-bar-blue'}`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </Box>
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
};
