import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserProfileProvider } from '@/v2/feature/user/context/user-profile.context';
import { AddressCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-address-card.component';
import { CustomSections } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-card.component';
import { EmergencyContactCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-card.component';
import { WorkContactCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-work-contact-card.component';
import { UserProfilePageProps } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserContact = ({ userId, onUpdate, missingFields }: UserProfilePageProps): JSX.Element => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const finallyRefreshUserObject = async (section: string) => {
    if (['user.basicInfo', 'user.role', 'user.lifecycle'].includes(section)) {
    }
    await onUpdate();
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Contact</Typography>} />
      <ContentWrapper loading={false} sx={{ pt: spacing.p25 }}>
        <UserProfileProvider>
          <ScopesControl scopes={['user.address:read']} context={scopesContext}>
            <AddressCard
              userId={userId}
              onSubmit={() => finallyRefreshUserObject('user.address')}
              missingFields={missingFields}
            />
          </ScopesControl>

          <ScopesControl scopes={['user.emergencyContact:read']} context={scopesContext}>
            <EmergencyContactCard
              userId={userId}
              onSubmit={() => finallyRefreshUserObject('user.emergencyContact')}
              missingFields={missingFields}
            />
          </ScopesControl>

          <ScopesControl scopes={['user.workContact:read']} context={scopesContext}>
            <WorkContactCard userId={userId} onSubmit={() => finallyRefreshUserObject('user.workContact')} />
          </ScopesControl>

          <CustomSections
            userId={userId}
            formTab={ProfileTab.Contact}
            onSubmit={() => finallyRefreshUserObject('')}
            missingFields={missingFields}
          />
        </UserProfileProvider>
      </ContentWrapper>
    </RootStyle>
  );
};
