import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { NumberCell } from '@v2/components/table/number-cell.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { UserCell } from '@v2/components/table/user-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { UpdateUserCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/update-user-custom-benefit-drawer.component';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  getOpeningBalanceColumns,
  isAllowanceBenefit,
  isLoanBenefit,
  isRecurringBenefit,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { ViewUserCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/view-user-custom-benefit-drawer.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { FieldStructure } from '@v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { ShowHideButton } from '@v2/feature/user/features/user-profile/details/components/show-hide-button.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateUserInsuranceDependants } from '@v2/infrastructure/i18n/translate.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { LocalDate } from '@v2/util/local-date';

import useScopes from '@/hooks/scopes.hook';

interface PageProps {
  readonly userId: number;
  readonly benefitId: number;
  readonly userBenefit: UserCustomBenefitDto;
}

// TODO: @polyglot-later
export const UserCustomBenefitListTableComponent = ({ benefitId, userBenefit, userId }: PageProps) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const hasInsuranceAll = hasScopes(['insurance:all']);
  const [showDetails, setShowDetails] = useState(false);

  const {
    data: userCustomBenefits,
    mutate: refreshUserCustomBenefits,
    isLoading,
  } = useApiClient(CustomBenefitEndpoints.getAllUserCustomBenefitsByBenefitId(userId, benefitId), { suspense: false });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<UserCustomBenefitDto | null>(null);

  const isLoan = useMemo(() => isLoanBenefit(userBenefit.customBenefit?.type), [userBenefit]);
  const isAllowance = useMemo(() => isAllowanceBenefit(userBenefit.customBenefit?.type), [userBenefit]);
  const isRecurring = useMemo(() => isRecurringBenefit(userBenefit.customBenefit?.type), [userBenefit]);

  const refresh = useCallback(async () => {
    if (refreshUserCustomBenefits) await refreshUserCustomBenefits();
  }, [refreshUserCustomBenefits]);

  const columns = useMemo<ColumnDef<UserCustomBenefitDto, UserCustomBenefitDto>[]>(() => {
    if (!userCustomBenefits) return [];
    const openingBalanceColumns: ColumnDef<UserCustomBenefitDto, UserCustomBenefitDto>[] = [];
    if (isLoan || isAllowance) openingBalanceColumns.push(...getOpeningBalanceColumns(isLoan, polyglot));

    return [
      {
        header: () => polyglot.t('General.effectiveDate'),
        accessorFn: (row: UserCustomBenefitDto) => row,
        id: 'effectiveDate',
        enableSorting: true,
        sortingFn: (a: Row<UserCustomBenefitDto>, b: Row<UserCustomBenefitDto>) =>
          sortString(a, b, (item) => item.effectiveDate ?? ''),
        cell: ({ row }: { row: Row<UserCustomBenefitDto> }) =>
          row.original.effectiveDate ? <Box>{row.original.effectiveDate}</Box> : <EmptyCell />,
      },
      {
        header: () => polyglot.t('General.effectiveEndDate'),
        accessorFn: (row: UserCustomBenefitDto) => row,
        id: 'effectiveEndDate',
        enableSorting: true,
        sortingFn: (a: Row<UserCustomBenefitDto>, b: Row<UserCustomBenefitDto>) =>
          sortString(a, b, (item) => item.effectiveEndDate ?? ''),
        cell: ({ row }: { row: Row<UserCustomBenefitDto> }) =>
          row.original.effectiveEndDate ? <Box>{row.original.effectiveEndDate}</Box> : <EmptyCell />,
      },
      ...openingBalanceColumns,
      ...(isRecurring
        ? [
            {
              header: () => polyglot.t('BenefitModule.employerContribution'),
              accessorFn: (row: UserCustomBenefitDto) => row,
              id: 'employerContribution',
              enableSorting: true,
              sortingFn: (a: Row<UserCustomBenefitDto>, b: Row<UserCustomBenefitDto>) =>
                sortNumeric(a, b, (item) => item.employerContribution ?? 0),
              cell: ({ row }: { row: Row<UserCustomBenefitDto> }) => (
                <NumberCell
                  value={
                    row.original.employerContribution
                      ? formatMoney({
                          amount: row.original.employerContribution,
                          asDecimal: true,
                        })
                      : null
                  }
                />
              ),
            },
            {
              header: () => polyglot.t('BenefitModule.employeeContribution'),
              accessorFn: (row: UserCustomBenefitDto) => row,
              id: 'employeeContribution',
              enableSorting: true,
              sortingFn: (a: Row<UserCustomBenefitDto>, b: Row<UserCustomBenefitDto>) =>
                sortNumeric(a, b, (item) => item.employeeContribution ?? 0),
              cell: ({ row }: { row: Row<UserCustomBenefitDto> }) => (
                <NumberCell
                  value={
                    row.original.employeeContribution
                      ? formatMoney({
                          amount: row.original.employeeContribution,
                          asDecimal: true,
                        })
                      : null
                  }
                />
              ),
            },
            {
              header: () => polyglot.t('BenefitModule.dependant'),
              accessorFn: (row: UserCustomBenefitDto) => row,
              id: 'dependants',
              enableSorting: true,
              sortingFn: (a: Row<UserCustomBenefitDto>, b: Row<UserCustomBenefitDto>) =>
                sortString(a, b, (item) => item.dependants ?? ''),
              cell: ({ row }: { row: Row<UserCustomBenefitDto> }) =>
                row.original.dependants ? (
                  <Box>{translateUserInsuranceDependants(row.original.dependants, polyglot)}</Box>
                ) : (
                  <EmptyCell />
                ),
            },
          ]
        : []),
    ];
  }, [userCustomBenefits, isLoan, isAllowance, polyglot, isRecurring]);

  const handleRowClick = useCallback(
    (row: Row<UserCustomBenefitDto>) => {
      setSelectedRow(row.original);
      if (hasInsuranceAll) setIsOpen(true);
      else setIsViewOpen(true);
    },
    [hasInsuranceAll]
  );

  const effectiveRecord = useMemo(() => {
    if (!userCustomBenefits) return undefined;
    if (userCustomBenefits.length === 0) return undefined;
    if (userCustomBenefits.length === 1) return userCustomBenefits[0];

    const sortedUserCustomBenefits = [...userCustomBenefits].sort((a, b) => {
      if (a.effectiveDate === b.effectiveDate)
        return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? 1 : -1;
      return a.effectiveDate > b.effectiveDate ? 1 : -1;
    });

    const today = new LocalDate().toDateString();
    for (let index = 0; index < sortedUserCustomBenefits.length; index += 1) {
      const current = sortedUserCustomBenefits[index];
      const next = sortedUserCustomBenefits[index + 1];
      if (!next) return current;

      if (
        current.effectiveDate <= today &&
        (!current.effectiveEndDate || current.effectiveEndDate > today) &&
        next.effectiveDate > today
      )
        return current;
    }

    return undefined;
  }, [userCustomBenefits]);

  return (
    <Box sx={{ width: '600px' }}>
      <Typography variant="title3" sx={{ mb: '10px' }}>
        Enrollment details
      </Typography>

      {isRecurring && effectiveRecord && effectiveRecord.employerContribution !== null && (
        <FieldStructure
          fieldTitle={polyglot.t('BenefitModule.employerContribution')}
          fieldValue={formatMoney({
            amount: effectiveRecord.employerContribution,
            asDecimal: true,
          })}
        />
      )}
      {isRecurring && effectiveRecord && effectiveRecord.employeeContribution !== null && (
        <FieldStructure
          fieldTitle={polyglot.t('BenefitModule.employeeContribution')}
          fieldValue={formatMoney({
            amount: effectiveRecord.employeeContribution,
            asDecimal: true,
          })}
        />
      )}
      {isRecurring && effectiveRecord && effectiveRecord.dependants !== null && (
        <FieldStructure fieldTitle={polyglot.t('BenefitModule.dependants')} fieldValue={effectiveRecord.dependants} />
      )}
      {(isLoan || isAllowance) && effectiveRecord && effectiveRecord.openingBalance !== null && (
        <FieldStructure
          fieldTitle={isLoan ? polyglot.t('BenefitModule.borrowed') : polyglot.t('BenefitModule.allowance')}
          fieldValue={
            effectiveRecord.openingBalance
              ? formatMoney({
                  amount: effectiveRecord.openingBalance,
                  asDecimal: true,
                })
              : '–'
          }
        />
      )}
      {isLoan && effectiveRecord && effectiveRecord.numberOfInstallments !== null && (
        <FieldStructure
          fieldTitle={polyglot.t('BenefitModule.numberOfInstallments')}
          fieldValue={effectiveRecord.numberOfInstallments}
        />
      )}
      {effectiveRecord && (
        <FieldStructure fieldTitle={polyglot.t('General.effectiveDate')} fieldValue={effectiveRecord.effectiveDate} />
      )}
      {effectiveRecord?.effectiveEndDate && (
        <FieldStructure
          fieldTitle={polyglot.t('General.effectiveEndDate')}
          fieldValue={effectiveRecord.effectiveEndDate}
        />
      )}
      {effectiveRecord?.createdBy && (
        <FieldStructure
          fieldTitle={polyglot.t('BenefitModule.addedBy')}
          fieldValue={<UserCell userId={effectiveRecord.createdBy} nameSx={themeFonts.title4} />}
        />
      )}

      <Box>
        <ShowHideButton showDetails={showDetails} setShowDetails={setShowDetails} />
      </Box>

      {showDetails && (
        <BasicTable<UserCustomBenefitDto>
          rowData={userCustomBenefits ?? []}
          columnData={columns}
          rowClick={handleRowClick}
          loading={isLoading}
        />
      )}

      {isOpen && selectedRow && (
        <UpdateUserCustomBenefitDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          userBenefit={selectedRow}
          onClose={() => {
            setSelectedRow(null);
            setIsOpen(false);
          }}
          refresh={refresh}
        />
      )}

      {isViewOpen && selectedRow && (
        <ViewUserCustomBenefitDrawer
          isOpen={isViewOpen}
          setIsOpen={setIsViewOpen}
          userBenefit={{ ...selectedRow, included: true }}
          onClose={() => {
            setSelectedRow(null);
            setIsViewOpen(false);
          }}
          refresh={refresh}
        />
      )}
    </Box>
  );
};
