import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { ADMIN_USER_REVIEWS_RESULT_ROUTE, ADMIN_USER_REVIEWS_ROUTE } from '@/lib/routes';
import { ReviewUserResultPage } from '@/v2/feature/growth/reviews/features/review-user/review-user-result/review-user-result.page';

export const UserDetailsGrowthRouter = (): JSX.Element => {
  const userId = useUserIdParam();
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId });

  return (
    <Switch>
      <RouteScopesControl scopes={['reviews']} context={context} path={ADMIN_USER_REVIEWS_RESULT_ROUTE}>
        <ReviewUserResultPage targetUserId={userId} />
      </RouteScopesControl>
      <Redirect from={ADMIN_USER_REVIEWS_ROUTE} to={ADMIN_USER_REVIEWS_RESULT_ROUTE} />
    </Switch>
  );
};
