import { SurveyCycleResult } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';

export const impactExportReport = (surveyResult: SurveyCycleResult | null | undefined, company_name: string) => {
  const { resultByQuestion, impactResult } = surveyResult || {};

  const addBoldHeader = (text: string) => {
    data.push([{ v: text, s: { font: { bold: true } } }]);
  };

  const uniqueFactors = new Set<string>();
  (resultByQuestion || []).forEach((item) => {
    const impacts = (impactResult && impactResult[item.question.id]) || {};
    Object.keys(impacts).forEach((factor) => {
      uniqueFactors.add(factor);
    });
  });

  const factorsArray = Array.from(uniqueFactors);

  const header = [
    'Impact factor',
    'Question',
    'Question factor',
    'Average Score',
    'NPS Score',
    'Positive Sentiment',
    'Impact',
    'Impact Label',
  ];
  const data = [];
  addBoldHeader('Impact score');
  data.push(header);

  factorsArray.forEach((factor) => {
    let questionsWithFactor = (resultByQuestion || []).filter((item) => {
      const impact = impactResult && impactResult[item.question.id][factor];
      return impact && (impact.label === 'High' || impact.label === 'Medium');
    });

    questionsWithFactor.sort((a, b) => {
      const impactA = impactResult && impactResult[a.question.id][factor];
      const impactB = impactResult && impactResult[b.question.id][factor];

      if (impactA && impactB) {
        if (impactA.label === impactB.label) {
          return Math.abs(impactB.coefficient) - Math.abs(impactA.coefficient);
        }
        if (impactA.label === 'High') return -1;
        if (impactB.label === 'High') return 1;
      }
      if (impactA?.label === 'High') return -1;
      if (impactB?.label === 'High') return 1;
      return 0;
    });

    questionsWithFactor = questionsWithFactor.slice(0, 3);

    questionsWithFactor.forEach((item) => {
      const row = [];
      row.push(factor);
      row.push(replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name }));
      row.push(item.question.factor);
      row.push(item.normPercent ? (item.normPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.npsPercent ? (item.npsPercent / 100).toFixed(2) : (0).toFixed(2));
      row.push(item.positivePercent ? (item.positivePercent / 100).toFixed(2) : (0).toFixed(2));

      const impact = impactResult && impactResult[item.question.id][factor];
      row.push(impact ? impact.coefficient.toFixed(2) : '–');
      row.push(impact ? impact.label : '–');

      data.push(row);
    });
  });

  return data;
};
