import React, { Suspense, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { UserSelect } from '@v2/components/user-select-type/user-select.component';
import { InsuranceAPI, InsuranceEndpoints } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface AddToInsurancePolicyForm {
  userIds: number[];
  startDate: string;
}

interface InsuranceAddToPolicyDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const InsuranceAddToPolicyDrawer = ({
  isOpen,
  setIsOpen,
  insurancePolicy,
  refresh,
  onClose,
}: InsuranceAddToPolicyDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <InsuranceAddToPolicyDrawerContent insurancePolicy={insurancePolicy} refresh={refresh} onClose={onClose} />
      </Suspense>
    </DrawerModal>
  );
};

interface InsuranceAddToPolicyDrawerContentProps {
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

const InsuranceAddToPolicyDrawerContent = ({
  insurancePolicy,
  refresh,
  onClose,
}: InsuranceAddToPolicyDrawerContentProps) => {
  const { data: userInsuranceRecords } = useApiClient(
    InsuranceEndpoints.getAllUsersInsurancePolicyById(insurancePolicy.id)
  );

  const { polyglot } = usePolyglot();

  const { nonTerminatedCachedUsers } = useCachedUsers({ refresh: true });

  const usersWithoutInsurance = useMemo(() => {
    return (
      nonTerminatedCachedUsers?.filter(
        (user) =>
          !userInsuranceRecords ||
          !userInsuranceRecords.find((record) => record.userId === user.userId && !!record.status)
      ) ?? []
    );
  }, [nonTerminatedCachedUsers, userInsuranceRecords]);

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<AddToInsurancePolicyForm>({
    initialValues: {
      userIds: [],
      startDate: insurancePolicy.startDate,
    },
    validationSchema: yup.object({
      userIds: yup
        .array()
        .of(yup.number().required(polyglot.t('ValidationMessages.requiredField')))
        .min(1, polyglot.t('BenefitModule.atLeast1Employee')),
      startDate: yup.string().test(dateFieldTest).required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: (values: AddToInsurancePolicyForm) => addToPolicy(values, insurancePolicy.id),
  });

  const addToPolicy = async (values: AddToInsurancePolicyForm, insurancePolicyId: number): Promise<void> => {
    try {
      setLoading(true);
      const { userIds, startDate } = values;
      const data = {
        userIds: userIds,
        startDate,
      };

      await InsuranceAPI.addUsersToPolicy(insurancePolicyId, data);

      await refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.addToPolicy')}</Typography>

        <UserSelect
          label={polyglot.t('UserSelect.whoSelect')}
          selectedLabel={polyglot.t('BenefitModule.selectedEmployees')}
          value={formik.values.userIds}
          onChange={(userIds: number[]) => {
            formik.setFieldValue('userIds', userIds);
          }}
          userOptions={usersWithoutInsurance.map((u) => {
            return {
              label: polyglot.t(u.displayName),
              value: u.userId,
            };
          })}
        />

        <DatePickerComponent
          name="startDate"
          label={polyglot.t('BenefitModule.policyStartDate')}
          inputFormat="DD/MM/YYYY"
          value={formik.values.startDate ?? null}
          onChange={(value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue('startDate', value);
            }
          }}
          error={!!formik.errors.startDate && Boolean(formik.touched.startDate)}
          helperText={formik.errors.startDate && Boolean(formik.touched.startDate)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.add')}
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
