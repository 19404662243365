import { useContext, useState } from 'react';

import { capitalize } from 'lodash';

import { GlobalContext } from '@/GlobalState';
import { canAccessScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleCreationModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/components/sc-creation-modal.component';
import { SCGeneralRollingStateModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/rolling/sc-general-rolling-state-modal.component';
import { SCGeneralScheduledReopenModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/scheduled/sc-general-scheduled-reopen-modal.component';
import { SCGeneralDeleteModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/shared/sc-general-delete-modal.component';
import { SCGeneralDuplicateModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/shared/sc-general-duplicate-modal.component';
import { TitleStatusComponent } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { spacing } from '@/v2/styles/spacing.styles';

interface SurveyCycleCreationGeneralPageProps {
  readonly surveyCycle: SurveyCycle | null | undefined;
  readonly refresh: () => Promise<void>;
  readonly cycleLoading: boolean | undefined;
  readonly reach: ReachType;
}

export const SCUpsertGeneralPage = ({
  surveyCycle,
  refresh,
  cycleLoading,
  reach,
}: SurveyCycleCreationGeneralPageProps) => {
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['surveys:all']);
  const isManager = canAccessScopes(user, ['surveys:manager']);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);
  const [isDuplicateDrawerOpen, setIsDuplicateDrawerOpen] = useState<boolean>(false);
  const [isDeactivateDrawerOpen, setIsDeactivateDrawerOpen] = useState<boolean>(false);
  const [isReopenDrawerOpen, setIsReopenDrawerOpen] = useState<boolean>(false);

  if (!surveyCycle) return <></>;

  return (
    <SettingsSectionContent
      title={surveyCycle ? <TitleStatusComponent surveyCycle={surveyCycle} /> : 'Start review cycle'}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={cycleLoading}
    >
      <SettingsSubsectionContent
        sections={[
          {
            title: 'General',
            onEdit: () => setIsEditDrawerOpen(true),
            hideEdit:
              Boolean(surveyCycle.state === CycleState.Completed) ||
              !(isAdmin || (isManager && surveyCycle.owner === user.userId)),
            items: [
              {
                type: SectionItemType.Pair,
                label: 'Survey type',
                value: capitalize(surveyCycle.type),
              },
              {
                type: SectionItemType.Pair,
                label: 'Internal name',
                value: surveyCycle.internalName,
              },
              ...(surveyCycle?.displayName
                ? [
                    {
                      type: SectionItemType.Pair,
                      label: 'Display name',
                      value: surveyCycle.displayName,
                    },
                  ]
                : []),
              {
                type: SectionItemType.Pair,
                label: 'Status',
                value: capitalize(surveyCycle.state),
              },
              {
                type: SectionItemType.Pair,
                label: 'Created by',
                value: <UserCell userId={surveyCycle.owner} />,
              },
            ],
          },
        ]}
      />

      {(isAdmin || (isManager && surveyCycle.owner === user.userId)) &&
        surveyCycle.type === CycleType.Scheduled &&
        surveyCycle.state === CycleState.Completed && (
          <SettingsSubsectionContent
            sections={[
              {
                title: 'Reopen cycle',
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value:
                      'You will be able to edit participants, timeline settings and reminders. Set a new end date for this cycle below',
                  },
                  {
                    type: SectionItemType.Component,
                    value: (
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="secondary"
                        onClick={() => setIsReopenDrawerOpen(true)}
                      >
                        Reopen cycle
                      </ButtonComponent>
                    ),
                  },
                ],
              },
            ]}
          />
        )}

      {(isAdmin || (isManager && surveyCycle.owner === user.userId)) &&
        surveyCycle.type === CycleType.Rolling &&
        (surveyCycle.state === CycleState.Ongoing || surveyCycle.state === CycleState.Paused) && (
          <SettingsSubsectionContent
            sections={[
              {
                title: surveyCycle.state === CycleState.Ongoing ? 'Pause cycle' : 'Reactivate cycle',
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value:
                      'Pausing a rolling cycle will retain all collected answers, new invites will not be sent out. Reactivate this cycle when you are ready to start inviting participants again.',
                  },
                  {
                    type: SectionItemType.Component,
                    value: (
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="secondary"
                        onClick={() => setIsDeactivateDrawerOpen(true)}
                      >
                        {surveyCycle.state === CycleState.Ongoing ? 'Pause' : 'Reactivate'} cycle
                      </ButtonComponent>
                    ),
                  },
                ],
              },
            ]}
          />
        )}

      {(isAdmin || (isManager && surveyCycle.owner === user.userId)) && (
        <SettingsSubsectionContent
          sections={[
            {
              title: 'Duplicate cycle',
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: 'All cycle settings will be duplicated into a new draft cycle',
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="secondary"
                      onClick={() => setIsDuplicateDrawerOpen(true)}
                    >
                      Duplicate cycle
                    </ButtonComponent>
                  ),
                },
              ],
            },
          ]}
        />
      )}

      {(isAdmin || (isManager && surveyCycle.owner === user.userId)) && (
        <SettingsSubsectionContent
          sections={[
            {
              title: 'Delete cycle',
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: (
                    <Typography variant="caption">
                      If you want to permanently delete this survey cycle and all associated data. You can do so&nbsp;
                      <span
                        onClick={() => {
                          setIsDeleteDrawerOpen(true);
                        }}
                        style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        here
                      </span>
                    </Typography>
                  ),
                },
              ],
            },
          ]}
        />
      )}

      <SCGeneralDeleteModal
        surveyCycle={surveyCycle}
        reach={reach}
        isOpen={isDeleteDrawerOpen}
        setIsOpen={setIsDeleteDrawerOpen}
        onClose={() => setIsDeleteDrawerOpen(false)}
      />

      <SCGeneralDuplicateModal
        surveyCycle={surveyCycle}
        reach={reach}
        isOpen={isDuplicateDrawerOpen}
        setIsOpen={setIsDuplicateDrawerOpen}
        onClose={() => setIsDuplicateDrawerOpen(false)}
      />

      <SurveyCycleCreationModal
        surveyCycle={surveyCycle}
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
        refresh={async () => {
          await refresh?.();
        }}
        reach={reach}
      />

      <SCGeneralRollingStateModal
        surveyCycle={surveyCycle}
        refresh={refresh}
        isOpen={isDeactivateDrawerOpen}
        setIsOpen={setIsDeactivateDrawerOpen}
        onClose={() => setIsDeactivateDrawerOpen(false)}
      />

      <SCGeneralScheduledReopenModal
        surveyCycle={surveyCycle}
        isOpen={isReopenDrawerOpen}
        setIsOpen={setIsReopenDrawerOpen}
        onClose={() => setIsReopenDrawerOpen(false)}
        refresh={async () => {
          await refresh?.();
        }}
      />
    </SettingsSectionContent>
  );
};
