import { Dispatch, SetStateAction, useMemo } from 'react';

import { Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_BILLING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface UpgradeToProModalProps {
  readonly isOpen: boolean;
  readonly setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  readonly planName: string;
  readonly messageSuffix:
    | 'proApps'
    | 'proPeople'
    | 'proGeneric'
    | 'proMoney'
    | 'proPermissions'
    | 'proForms'
    | 'proTemplates'
    | 'proCustomisation';
  readonly markup?: JSX.Element;
}

export enum PlanNames {
  PEOPLE_PRO = 'People Pro',
  APPS_PRO = 'Apps Pro',
  DEVICES_PRO = 'Devices Pro',
  MONEY_PRO = 'Money Pro',
  TECH_PRO = 'Tech Pro',
  GROWTH_PRO = 'Growth Pro',
}

export const UPGRADE_REQUIRED_ERROR = 'PLAN UPGRADE REQUIRED';

export const UpgradeToProModal = ({
  isOpen,
  setIsDrawerOpen,
  planName,
  messageSuffix,
  markup,
}: UpgradeToProModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const upgradeMessage = useMemo(() => {
    return {
      proApps: () => polyglot.t('UpgradeToProModal.proApps', { planName }),
      proPeople: () => polyglot.t('UpgradeToProModal.proPeople', { planName }),
      proMoney: () => polyglot.t('UpgradeToProModal.proMoney', { planName }),
      proPermissions: () => polyglot.t('UpgradeToProModal.proPermissions', { planName }),
      proForms: () => polyglot.t('UpgradeToProModal.proForms', { planName }),
      proTemplates: () => polyglot.t('UpgradeToProModal.proTemplates', { planName }),
      proCustomisation: () => polyglot.t('UpgradeToProModal.proCustomisation', { planName }),
      proGeneric: () => polyglot.t('UpgradeToProModal.proGeneric', { planName }),
    }[messageSuffix]();
  }, [polyglot, messageSuffix, planName]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsDrawerOpen}>
      <>
        <Typography sx={titleSx}> {polyglot.t('UpgradeToProModal.toPro')}</Typography>
        <Typography sx={{ ...themeFonts.caption, mb: spacing.mb40, lineHeight: '24px' }}>
          {polyglot.t('UpgradeToProModal.discoveredPro')}
          {upgradeMessage}
          {markup}
        </Typography>
        <ButtonComponent
          fullWidth
          sizeVariant="medium"
          colorVariant="primary"
          onClick={() => routerHistory.push(generatePath(SETTINGS_BILLING_ROUTE))}
        >
          {polyglot.t('UpgradeToProModal.upgrade')}
        </ButtonComponent>
      </>
    </DrawerModal>
  );
};
