import { OptionObject } from '@v2/components/forms/select.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { isHourlyPolicy } from '@v2/feature/absence/absence.util';
import {
  AbsenceAdjustmentImportDto,
  AbsenceImportDto,
} from '@v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { CompanyUnitDto } from '@v2/feature/company/company-settings/features/company-settings.dto';
import { DeviceImportDto } from '@v2/feature/device/features/devices-import/devices-import.dto';
import { EntityImportErrorDto } from '@v2/feature/entity-import/entity-import.dto';
import { ImportState } from '@v2/feature/entity-import/wizard/import-wizard-flow.page';
import { UserImportDto } from '@v2/feature/user/features/user-import/user-import.dto';
import { isDefined } from '@v2/feature/user-onboarding/user-onboarding.util';
import { COUNTRY_CODE_TO_NAME_MAPPING, COUNTRY_ISO_CODE_MAPPING } from '@v2/infrastructure/country/country.interface';
import {
  ddmmyyyyDateFormat,
  ddMMYYYYToIsoDateString,
  isoDateAndTimeFormat,
  isoDateFormat,
  isoDateStringToDDMMYYY,
  isValidIsoDateString,
  parseDDMMYYYYDateAsLocalDateString,
} from '@v2/infrastructure/date/date-format.util';
import { camelCaseToTitleCase, toTitleCase } from '@v2/util/string.util';
import { v4 } from 'uuid';

import { AbsenceStatus } from '@/v2/feature/absence/absence.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { stringifiedBooleanValues } from '@/v2/util/array.util';
import { LocalDate } from '@/v2/util/local-date';

export const sanitizeUserImportRecord = (record: UserImportDto): UserImportDto => {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => {
      return [key, typeof value === 'string' ? value.trim() : value];
    })
  ) as UserImportDto;
};

const getCountryCodeOrDefault = (value: string) => {
  if (value.length === 2) {
    //it is already an ISO country code
    return value;
  }
  return value ? COUNTRY_ISO_CODE_MAPPING[value]?.toLowerCase() : value;
};

const emailAddressForImport = (record: UserImportDto, zeltUser?: CachedUser) => {
  // if userId is provided, we should take email address from CSV or default to zeltUser's email address
  if (zeltUser) {
    return record?.workEmail?.length && record?.workEmail?.length > 0
      ? record?.workEmail.toLowerCase().trim()
      : zeltUser?.emailAddress;
  }
  return record.emailAddress ?? record.workEmail?.toLowerCase().trim();
};

const entityValueForMapping = (entityName: string, entityList?: readonly CompanyUnitDto[]): string | null => {
  if (entityName && !!entityList?.find((eachEntity) => eachEntity.legalName === entityName)) return entityName;
  else return null;
};

export const prorateSalaryByFteValueForMapping = (
  record: UserImportDto,
  attendanceSchedule: AttendanceScheduleDto | undefined
): boolean => {
  if (
    attendanceSchedule &&
    record.prorateSalaryByFte &&
    stringifiedBooleanValues.has(String(record.prorateSalaryByFte)?.toLowerCase())
  )
    return JSON.parse(String(record.prorateSalaryByFte)?.toLowerCase());
  else return false;
};

export const compensationRateValueForMapping = (
  record: UserImportDto,
  attendanceSchedule: AttendanceScheduleDto | undefined
): number | undefined => {
  if (
    attendanceSchedule &&
    record.annualSalary &&
    +record.annualSalary &&
    prorateSalaryByFteValueForMapping(record, attendanceSchedule)
  )
    return (+attendanceSchedule.ftePercent / 100) * +record.annualSalary;
  else if (record.annualSalary && +record.annualSalary) return +record.annualSalary;
  else return undefined;
};

export const mapUserImportToZelt = (
  record: UserImportDto,
  departments: OptionObject[],
  sites: OptionObject[],
  zeltUser?: CachedUser,
  entityList?: readonly CompanyUnitDto[],
  attendanceSchedules?: readonly AttendanceScheduleDto[]
): UserImportDto => {
  const attendanceSchedule = attendanceSchedules?.find((schedule) => schedule.name === record?.attendanceSchedule);
  return Object.assign(
    {},
    {
      ...record,
      // length limited fields
      postCode: record?.postCode ? record?.postCode?.slice(0, 20) : '',
      city: record?.city ? record?.city?.slice(0, 35) : '',
      addressLine2: record?.addressLine2 ? record?.addressLine2?.slice(0, 35) : '',
      emailAddress: emailAddressForImport(record, zeltUser),
      workEmail: record?.workEmail ?? record?.emailAddress,
      userId: zeltUser ? zeltUser.userId : record?.userId ? Number(record.userId) : undefined,
      id: v4(),
      // Basic
      dob:
        record.dateOfBirth && isValidIsoDateString(record.dateOfBirth)
          ? new LocalDate(record.dateOfBirth).toDateString()
          : (record.dateOfBirth && parseDDMMYYYYDateAsLocalDateString(record.dateOfBirth)) ?? undefined,
      gender: record.gender?.toLowerCase(),
      // Address
      postcode: record.postCode,
      // Role
      departmentId: departments?.find((d) => d.label === record.department)?.value,
      siteId: sites?.find((s) => s.label === record.site)?.value,
      // Lifecycle
      startDate:
        record.startDate && isValidIsoDateString(record.startDate)
          ? new LocalDate(record.startDate).toDateString()
          : (record.startDate && parseDDMMYYYYDateAsLocalDateString(record.startDate)) ?? undefined,
      leaveDate:
        record.leaveDate && isValidIsoDateString(record.leaveDate)
          ? new LocalDate(record.leaveDate).toDateString()
          : (record.leaveDate && parseDDMMYYYYDateAsLocalDateString(record.leaveDate)) ?? undefined,
      // Compensation
      effectiveDate: record?.startDate,
      currency: record?.compensationCurrency ? record.compensationCurrency : undefined,
      compensationCurrency: record?.compensationCurrency ? record.compensationCurrency : undefined,
      paySchedule: isDefined(record?.paySchedule) ? record?.paySchedule : undefined,
      salaryBasis: 'Annual',
      rate: !prorateSalaryByFteValueForMapping(record, attendanceSchedule)
        ? compensationRateValueForMapping(record, attendanceSchedule)
        : record?.proratedRate ?? compensationRateValueForMapping(record, attendanceSchedule),
      prorateSalaryByFte: prorateSalaryByFteValueForMapping(record, attendanceSchedule),
      nonProratedRate: record?.nonProratedRate ? record?.nonProratedRate : record?.annualSalary,
      proratedRate: compensationRateValueForMapping(record, attendanceSchedule),
      // Bank account
      bankName: record?.bankName,
      bankCurrency: record?.bankCurrency ? record.bankCurrency : undefined,
      accountName: record?.accountHolderName,
      accountNumber: record?.accountNumberIban,
      sortCode: record?.sortCodeBicSwift ? record?.sortCodeBicSwift : undefined,
      bankCountry:
        record?.bankCountry && isDefined(record?.bankCountry) && record?.bankCountry.length === 2
          ? COUNTRY_CODE_TO_NAME_MAPPING[record?.bankCountry.toUpperCase()]
          : record?.bankCountry,
      // Contract
      type: record?.employmentType,
      contract: record?.employmentContract,
      attendanceSchedule: attendanceSchedule?.name ?? null,
      ftePercent: attendanceSchedule?.ftePercent,
      entityName: entityValueForMapping(record?.entity, entityList),
      publicHolidays: record?.publicHolidays ? getCountryCodeOrDefault(record?.publicHolidays) : null,
      holidayCalendar: record?.holidayCalendar ? getCountryCodeOrDefault(record?.holidayCalendar) : null,
      country: record?.country ? getCountryCodeOrDefault(record?.country) : null,
      nationality: record?.nationality ? getCountryCodeOrDefault(record?.nationality) : null,
      probationPeriodUnit: record?.probationPeriodUnit ? toTitleCase(record?.probationPeriodUnit) : null,
      noticePeriodUnit: record?.noticePeriodUnit ? toTitleCase(record?.noticePeriodUnit) : null,
      // Leave
      leaveEntitlement: record?.leaveEntitlement ? Number(record?.leaveEntitlement) : null,
      leaveEntitlementType: record?.leaveEntitlementType ? toTitleCase(record?.leaveEntitlementType) : null,
      leaveEntitlementCurrency: record?.leaveEntitlementCurrency ? record.leaveEntitlementCurrency : null,
      leaveEntitlementRate: record?.leaveEntitlementRate ? Number(record?.leaveEntitlementRate) : null,
      leaveEntitlementRateType: record?.leaveEntitlementRateType ? toTitleCase(record?.leaveEntitlementRateType) : null,
      leaveEntitlementRateCurrency: record?.leaveEntitlementRateCurrency ? record.leaveEntitlementRateCurrency : null,
      leaveEntitlementRatePeriod: record?.leaveEntitlementRatePeriod
        ? Number(record?.leaveEntitlementRatePeriod)
        : null,
      // Equity

      // Equity - NOT BEING VALIDATED
      amount: record?.amount ? Number(record?.amount) : undefined,
      equityType: record?.equityType,
      equityCurrency: undefined,
      grantDate:
        record?.grantDate && isValidIsoDateString(record?.grantDate)
          ? new LocalDate(record?.grantDate).toDateString()
          : (record?.grantDate && parseDDMMYYYYDateAsLocalDateString(record?.grantDate)) ?? undefined,
      vestingStart:
        record?.vestingStart && isValidIsoDateString(record?.vestingStart)
          ? new LocalDate(record?.vestingStart).toDateString()
          : (record?.vestingStart && parseDDMMYYYYDateAsLocalDateString(record?.vestingStart)) ?? undefined,
      unitPrice: record?.exercisePrice ? Number(record?.exercisePrice) : undefined,
      vestingPeriod: record?.exercisePrice ? Number(record?.vestingPeriodMonths) : undefined,
      vestingCliff: record?.exercisePrice ? Number(record?.vestingCliffMonths) : undefined,
      // Right to work - NOT BEING VALIDATED
      rightToWorkChecked: record?.rightToWorkCheckDate && isDefined(record.rightToWorkCheckDate) ? true : false,
      rightToWorkCheckDate: record?.rightToWorkCheckDate,
      rightToWorkDocumentTypes: record.documentType ? record.documentType : undefined,
      rightToWorkIsLimited: record.visaHasExpiry ? record.visaHasExpiry === true : undefined,
      rightToWorkExpiryDate: record.visaExpirationDate ? record.visaExpirationDate : undefined,
      // Emergency information
      emergencyName: record?.emergencyName,
      emergencyRelationship: record?.emergencyRelationship,
      emergencyNumber: record?.emergencyNumber,
    }
  ) as UserImportDto;
};

const getDateBasedOnInputFormat = (inputDate: string) => {
  if (inputDate.match(isoDateFormat)) {
    return isoDateStringToDDMMYYY(inputDate);
  }

  if (inputDate.match(ddmmyyyyDateFormat)) {
    return inputDate;
  }
  return inputDate;
};

const valueEmptyOrUndefined = (value: string) => {
  return value === '' || !value;
};

export const mapAbsenceImportToZelt = (
  record: AbsenceImportDto,
  policy: AbsencePolicyDto | undefined,
  zeltUser: CachedUser | undefined
): AbsenceImportDto => {
  const start = getDateBasedOnInputFormat(record.start) as string;
  const end = !!record.end ? getDateBasedOnInputFormat(record.end) : null;

  const isHourly = isHourlyPolicy(policy);
  const isDailyWithHours =
    (record.start && isoDateAndTimeFormat.test(record.start)) || (record.end && isoDateAndTimeFormat.test(record.end));
  let startHour: string | null = null;
  let endHour: string | null = null;
  let morningOnly = valueEmptyOrUndefined(String(record.morningOnly)) ? null : record.morningOnly;
  let afternoonOnly = valueEmptyOrUndefined(String(record.afternoonOnly)) ? null : record.afternoonOnly;

  if (isHourly || isDailyWithHours) {
    startHour = record.start ?? null;
    endHour = record.end ?? null;
    afternoonOnly = null;
    morningOnly = null;
  }

  return Object.assign(
    {},
    {
      ...record,
      id: v4(),
      workEmail: zeltUser ? zeltUser.emailAddress : record.workEmail.toLowerCase().trim(),
      userId: zeltUser ? zeltUser.userId : 0,
      policyName: policy?.fullName ?? '',
      policyId: policy?.id,
      start,
      end,
      startHour,
      endHour,
      morningOnly,
      afternoonOnly,
      status: valueEmptyOrUndefined(String(record.status)) ? AbsenceStatus.Pending : record.status,
      notes: record.notes,
    }
  ) as AbsenceImportDto;
};

export const mapAbsenceAdjustmentsImportToZelt = (
  record: AbsenceAdjustmentImportDto,
  policy: AbsencePolicyDto | undefined,
  zeltUser: CachedUser | undefined
): AbsenceAdjustmentImportDto => {
  return Object.assign(
    {},
    {
      ...record,
      id: v4(),
      workEmail: zeltUser ? zeltUser.emailAddress : record.workEmail.toLowerCase().trim(),
      userId: zeltUser ? zeltUser.userId : record.userId,
      policyName: policy?.fullName ?? record.policyName,
      policyId: policy?.id ?? record.policyId,
      effectiveYear: record.effectiveYear ? Number(record.effectiveYear) : record.effectiveYear,
      adjustment: record.adjustment,
      unit: record.unit,
      note: record.note,
    }
  ) as AbsenceAdjustmentImportDto;
};

export const mapDeviceImportToZelt = (record: DeviceImportDto): DeviceImportDto => {
  return (Object.assign(
    {},
    {
      ...record,
      id: v4(),
      inUseBy: record.inUseBy?.toLowerCase(),
    }
  ) as unknown) as DeviceImportDto;
};

export const entityIdForName = (currentValues: UserImportDto, entityList: CompanyUnitDto[]) => {
  return currentValues && currentValues?.entityName
    ? entityList?.find((eachEntity) => eachEntity.legalName === currentValues?.entityName)?.id
    : undefined;
};

export const finalisePeopleDataForImport = (
  importState: ImportState | undefined,
  entityList: CompanyUnitDto[],
  attendanceSchedules: readonly AttendanceScheduleDto[],
  selectionModel?: string[]
): UserImportDto[] => {
  return importState?.result?.errors.some((e) => e.entity)
    ? (importState?.result?.errors as EntityImportErrorDto<UserImportDto>[])
        .filter((e) =>
          selectionModel?.length && selectionModel?.length > 0 ? selectionModel?.includes(e.entity?.id) : e.entity?.id
        )
        .flatMap((record: EntityImportErrorDto<UserImportDto>) => {
          const {
            id,
            avatar,
            gender,
            userId,
            employeeId,
            firstName,
            lastName,
            displayName,
            emailAddress,
            nationality,
            passportNumber,
            personalEmail,
            phone,
            dateOfBirth,
            startDate,
            leaveDate,
            addressLine1,
            addressLine2,
            city,
            postCode,
            country,
            department,
            jobTitle,
            managerEmail,
            site,
            roleEffectiveDate,
            lifecycleStatus,
            lifecycleEffectiveDate,
            paySchedule,
            compensationCurrency,
            compensationEffectiveDate,
            prorateSalaryByFte,
            nonProratedRate,
            rate,
            accountHolderName,
            accountNumberIban,
            bankName,
            sortCodeBicSwift,
            bankCurrency,
            bankCountry,
            grantDate,
            equityType,
            amount,
            vestingStart,
            vestingCliffMonths,
            vestingPeriodMonths,
            exercisePrice,
            employmentContract,
            employmentType,
            contractEffectiveDate,
            ftePercent,
            changeReason,
            noticePeriodLength,
            noticePeriodUnit,
            probationPeriodLength,
            probationPeriodUnit,
            holidayCalendar,
            entityName,
            attendanceSchedule,
            rightToWorkCheckDate,
            documentType,
            visaHasExpiry,
            visaExpirationDate,
            emergencyName,
            emergencyNumber,
            emergencyRelationship,
          } = record.entity;

          return {
            id,
            avatar,
            userId,
            employeeId,
            firstName,
            lastName,
            displayName,
            emailAddress,
            nationality:
              nationality && isDefined(nationality)
                ? COUNTRY_CODE_TO_NAME_MAPPING[nationality.toUpperCase()]
                : undefined,
            passportNumber,
            personalEmail,
            phone,
            dateOfBirth: dateOfBirth ? ddMMYYYYToIsoDateString(dateOfBirth) : dateOfBirth,
            startDate: startDate ? ddMMYYYYToIsoDateString(startDate ?? '') : new LocalDate().toDateString(), // if start date does not exist, default to today
            leaveDate: leaveDate ? ddMMYYYYToIsoDateString(leaveDate ?? '') : leaveDate,
            addressLine1,
            addressLine2,
            city,
            postCode,
            country: country && isDefined(country) ? COUNTRY_CODE_TO_NAME_MAPPING[country.toUpperCase()] : undefined,
            department,
            jobTitle,
            managerEmail,
            site,
            roleEffectiveDate: ddMMYYYYToIsoDateString(roleEffectiveDate ?? '') ?? undefined,
            lifecycleStatus,
            lifecycleEffectiveDate: ddMMYYYYToIsoDateString(lifecycleEffectiveDate ?? '') ?? undefined,
            paySchedule,
            annualSalary: rate && !isNaN(+rate) ? String(rate) : undefined,
            currency: compensationCurrency,
            compensationEffectiveDate: ddMMYYYYToIsoDateString(compensationEffectiveDate ?? '') ?? undefined,
            prorateSalaryByFte,
            nonProratedRate: nonProratedRate && !isNaN(+nonProratedRate) ? String(nonProratedRate) : undefined,
            accountHolderName,
            accountNumberIban,
            bankName,
            sortCodeBicSwift,
            bankCurrency,
            bankCountry,
            grantDate: ddMMYYYYToIsoDateString(grantDate ?? '') ?? undefined,
            equityType,
            amount: amount ? String(amount) : amount,
            vestingStart: ddMMYYYYToIsoDateString(vestingStart ?? '') ?? undefined,
            vestingCliffMonths,
            vestingPeriodMonths,
            exercisePrice,
            employmentContract,
            employmentType,
            contractEffectiveDate: ddMMYYYYToIsoDateString(contractEffectiveDate ?? '') ?? undefined,
            attendanceScheduleId:
              attendanceSchedules.find((schedule) => schedule.name === attendanceSchedule)?.id ?? undefined,
            ftePercent: ftePercent ? String(ftePercent) : ftePercent,
            changeReason,
            noticePeriodLength: noticePeriodLength ? String(noticePeriodLength) : noticePeriodLength,
            noticePeriodUnit: noticePeriodUnit ?? undefined,
            probationPeriodLength: probationPeriodLength ? String(probationPeriodLength) : probationPeriodLength,
            probationPeriodUnit: probationPeriodUnit ?? undefined,
            holidayCalendar: holidayCalendar && isDefined(holidayCalendar) ? holidayCalendar : undefined,
            entityName,
            entityId: entityName ? entityIdForName(record.entity, entityList) : undefined,
            rightToWorkCheckDate: rightToWorkCheckDate
              ? ddMMYYYYToIsoDateString(String(rightToWorkCheckDate))
              : rightToWorkCheckDate,
            documentType: documentType ?? undefined,
            visaHasExpiry,
            visaExpirationDate: visaExpirationDate
              ? ddMMYYYYToIsoDateString(String(visaExpirationDate))
              : visaExpirationDate,
            gender: gender ? toTitleCase(gender) : gender,
            emergencyName,
            emergencyRelationship,
            emergencyNumber,
            importSource: importState?.source,
          };
        })
    : [];
};

export const normaliseDataForDownload = (data: Record<string, unknown>[]): Record<string, unknown>[] => {
  const normalisedData: Record<string, unknown>[] = [];
  for (const record of data) {
    const updatedRecord: Record<string, unknown> = {};
    for (const [k, v] of Object.entries(record)) {
      const fixedKey = camelCaseToTitleCase(k);
      updatedRecord[fixedKey] = v;
    }
    normalisedData.push(updatedRecord);
  }
  return normalisedData;
};
