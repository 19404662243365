import { useCallback, useMemo, useState } from 'react';

import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import dayjs from 'dayjs';

import useMessage from '@/hooks/notification.hook';
import { Divider } from '@/v2/components/divider.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { OptionObject } from '@/v2/components/forms/select.component';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';
import { formatMoney } from '@/v2/feature/payments/utils/money.util';
import {
  DiscountTypeLabels,
  DiscountTypeValues,
  PlanDefinitionCost,
  SubscribedPlanDefinition,
  discountTypeOptions,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { discountExpired } from '@/v2/feature/super-admin/features/super-admin-billing-v2/components/super-admin-billing-company-subscription-edit-form.component';
import { drawerContentSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { CurrencyShort } from '@/v2/infrastructure/currency/currency.interface';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

interface props {
  selectedModule: SubscribedPlanDefinition;
  companyName: string;
  subscriptionCurrency: string;
  updateSubscriptionInParentDrawer?: (updatedModule: SubscribedPlanDefinition) => void;
  handleDeleteModule: (moduleToDelete: SubscribedPlanDefinition) => void;
}

export const SuperAdminBillingCompanySubscriptionEditModuleForm = ({
  selectedModule,
  companyName,
  subscriptionCurrency,
  updateSubscriptionInParentDrawer,
  handleDeleteModule,
}: props) => {
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [updatedModule, setUpdatedModule] = useState<SubscribedPlanDefinition>({ ...selectedModule });
  const [discountType, setDiscountType] = useState<DiscountTypeValues | undefined>(
    selectedModule.discountType ?? DiscountTypeValues.PERCENTAGE
  );
  const [discountEnabled, setDiscountEnabled] = useState(!!selectedModule.discountValue);
  const [showMessage] = useMessage();

  const handleSave = useCallback(async () => {
    try {
      setIsSavingChanges(true);

      if (updateSubscriptionInParentDrawer && updatedModule) updateSubscriptionInParentDrawer(updatedModule);

      setIsSavingChanges(false);

      showMessage('Customer subscription updated successfully', 'success');
    } catch (e) {
      showMessage('Failed to update customer plan', 'error');
      console.error(':::: error ::::', e);
      setIsSavingChanges(false);
    }
  }, [updateSubscriptionInParentDrawer, updatedModule, showMessage]);

  const discountOptions = useMemo(() => {
    return discountTypeOptions.map<OptionObject>((option: DiscountTypeValues) => ({
      label: DiscountTypeLabels[option],
      value: option,
    }));
  }, []);

  const getDiscountedCost = (
    currentCost: PlanDefinitionCost,
    currentDiscountType: DiscountTypeValues | undefined,
    discountValue: number | undefined,
    discountEndDate?: Date | null
  ): PlanDefinitionCost => {
    if (
      !discountEnabled ||
      !currentDiscountType ||
      !discountValue ||
      (discountEndDate && discountExpired(discountEndDate))
    )
      return currentCost;

    const ensureNotLessThanZero = (value: number) => (value < 0 ? 0 : value);

    if (currentDiscountType === DiscountTypeValues.FIXED) {
      return {
        ...currentCost,
        GBP: ensureNotLessThanZero(currentCost.GBP - discountValue),
        USD: ensureNotLessThanZero(currentCost.USD - discountValue),
      };
    }

    if (currentDiscountType === DiscountTypeValues.PERCENTAGE) {
      const discountGBPAmount = (currentCost.GBP * discountValue) / 100;
      const discountUSDAmount = (currentCost.USD * discountValue) / 100;
      return {
        ...currentCost,
        GBP: ensureNotLessThanZero(currentCost[subscriptionCurrency as keyof PlanDefinitionCost] - discountGBPAmount),
        USD: ensureNotLessThanZero(currentCost[subscriptionCurrency as keyof PlanDefinitionCost] - discountUSDAmount),
      };
    }

    return currentCost;
  };

  const effectivePricePerSeat: number =
    updatedModule && updatedModule.cost && updatedModule.discountType && updatedModule.discountValue
      ? getDiscountedCost(
          updatedModule.cost,
          updatedModule.discountType,
          updatedModule.discountValue,
          updatedModule.discountEndDate
        )[subscriptionCurrency as keyof PlanDefinitionCost]
      : 0;

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Plan</Typography>

      <DrawerViewerItem title="Company" key="companyName" value={companyName ?? ''} />

      <Divider />

      <DrawerViewerItem title="Module" key="moduleName" value={selectedModule.planName ?? ''} />

      <DrawerViewerItem
        title="List price"
        key="listPrice"
        value={
          selectedModule.cost && subscriptionCurrency
            ? formatCurrency(
                selectedModule.cost[subscriptionCurrency as keyof PlanDefinitionCost],
                undefined,
                subscriptionCurrency
              ) ?? ''
            : ''
        }
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="caption">Discount</Typography>
        <SwitchComponent
          checked={discountEnabled}
          name="discountEnabled"
          onChange={(_e, enabled) => {
            setDiscountEnabled(enabled);
            const moduleToUpdate = {
              ...updatedModule,
              ...(!enabled
                ? { discountType: undefined, discountValue: undefined, pricePerSeat: updatedModule.cost }
                : {}),
            };
            setUpdatedModule(moduleToUpdate);
          }}
        />
      </Box>
      {discountEnabled ? (
        <>
          <Box sx={{ ...fieldSx, display: 'flex', gap: spacing.g10, mb: spacing.m20 }}>
            <RadioGroup
              name="discount-type"
              onChange={(event) => {
                setDiscountType(event.target.value as DiscountTypeValues);
                const moduleToUpdate = {
                  ...updatedModule,
                  ...(event.target.value
                    ? {
                        discountType: event.target.value as DiscountTypeValues,
                        pricePerSeat: getDiscountedCost(
                          updatedModule.cost,
                          event.target.value as DiscountTypeValues,
                          updatedModule.discountValue
                        ),
                      }
                    : {}),
                };
                setUpdatedModule(moduleToUpdate);
              }}
            >
              {discountOptions.map((option: OptionObject) => (
                <FormControlLabel
                  key={option.value}
                  labelPlacement="end"
                  value={option.value}
                  checked={discountType === option.value}
                  control={<StyledRadio />}
                  label={<Typography variant="title4">{option.label}</Typography>}
                  sx={{ mb: spacing.m5 }}
                />
              ))}
            </RadioGroup>
          </Box>
          <TextfieldComponent
            label={discountType ? DiscountTypeLabels[discountType as DiscountTypeValues] : 'Discount amount'}
            name="discount"
            type="number"
            value={updatedModule.discountValue}
            onChange={(e) => {
              if (!e.target.value) return;
              const newDiscount = +e.target.value;
              const moduleToUpdate = {
                ...updatedModule,
                discountType,
                discountValue: newDiscount,
                pricePerSeat: getDiscountedCost(updatedModule.cost, discountType, newDiscount),
              };
              setUpdatedModule(moduleToUpdate);
            }}
            fullWidth
            size="small"
            endAdornment="none"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box width="85%">
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                value={updatedModule.discountEndDate ?? null}
                onChange={(value) => {
                  if (dayjs(value).isValid()) {
                    setUpdatedModule((prev) => ({ ...prev, discountEndDate: new Date(value) }));
                  }
                }}
                name="endDate"
                label={'End date (Optional)'}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {updatedModule.discountEndDate && (
                <ButtonComponent
                  sizeVariant="small"
                  colorVariant="secondary"
                  onClick={() => {
                    setUpdatedModule((prev) => ({ ...prev, discountEndDate: null }));
                  }}
                >
                  Clear
                </ButtonComponent>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} width="50%">
            <TextfieldComponent
              label={'Effective price'}
              name="effectivePrice"
              value={
                effectivePricePerSeat !== undefined && effectivePricePerSeat !== null
                  ? formatMoney({
                      amount: effectivePricePerSeat,
                      currency: subscriptionCurrency as CurrencyShort,
                    })
                  : 'Invalid discount'
              }
              fullWidth
              disabled
              size="small"
              endAdornment="none"
            />
          </Box>
        </>
      ) : (
        <></>
      )}
      <ButtonComponent
        colorVariant="danger"
        sizeVariant="small"
        style={{
          transition: 'opacity 0.3s ease, visibility 0.3s ease',
        }}
        onClick={() => handleDeleteModule(selectedModule)}
      >
        Delete this module
      </ButtonComponent>
      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          name="Update module"
          sizeVariant="medium"
          loading={isSavingChanges}
          colorVariant="primary"
          fullWidth
          onClick={handleSave}
          disabled={!!(discountEnabled && !updatedModule.discountValue)}
        />
      </Box>
    </Box>
  );
};
