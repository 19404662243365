import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortDate, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { AuditReportEntryDetailDrawer } from '@/v2/feature/security/security-settings/components/security-audit-report-entry-detail-drawer.component';
import { AuditTrailDto } from '@/v2/feature/security/security-settings/security.interface';
import { filterStringToObject } from '@/v2/feature/user/user.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { toTitleCase } from '@/v2/util/string.util';

export const SecurityAuditReportTable = ({
  auditTrailRows,
  loadingAuditTrail,
  activityLog,
}: {
  auditTrailRows: AuditTrailDto[] | null | undefined;
  loadingAuditTrail: boolean;
  activityLog: boolean;
}) => {
  const [currentAuditEntryDetail, setCurrentAuditEntryDetail] = useState<AuditTrailDto | undefined>(undefined);
  const [entryDetailDrawerOpen, setEntryDetailDrawerOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [actionFilterString, setActionFilterString] = useState<string>('');

  const getActions = useCallback(() => {
    let filteredAuditTrailRows = auditTrailRows ?? [];
    const nonNullValues: string[] = filteredAuditTrailRows
      .map((row) => row.action)
      .filter((value): value is string => value !== null);
    const uniqueValues = Array.from(new Set(nonNullValues));
    uniqueValues.sort((a, b) => a.localeCompare(b));
    return uniqueValues.map((value) => ({
      label: `${value}`,
      value: value,
    }));
  }, [auditTrailRows]);

  const AuditTrailFilterTypes = {
    action: getActions(),
  };

  const filteredData = useMemo(() => {
    const searchfilteredData =
      searchInput?.length === 0
        ? auditTrailRows
        : searchInput?.length > 0
        ? auditTrailRows?.filter(
            (eachRow) =>
              eachRow.action.toLowerCase().includes(searchInput) ||
              eachRow.domain.toLowerCase().includes(searchInput) ||
              JSON.stringify(eachRow.entityPayload).toLowerCase().includes(searchInput)
          )
        : auditTrailRows;
    if (actionFilterString) {
      const filterOptions = filterStringToObject(actionFilterString);
      if (filterOptions.action) {
        return (searchfilteredData ?? []).filter(
          (eachRow) => eachRow.action && filterOptions.action.includes(eachRow.action)
        );
      }
    }
    return searchfilteredData;
  }, [auditTrailRows, actionFilterString, searchInput]);

  const auditReportColumns = useMemo<ColumnDef<AuditTrailDto, AuditTrailDto>[]>(() => {
    return [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'userId',
        enableSorting: false,
        cell: (info: CellContext<AuditTrailDto, AuditTrailDto>) => {
          return <UserCell userId={info.getValue().userId} />;
        },
        maxSize: 50,
        minSize: 40,
      },
      {
        header: () => 'Action',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.action),
        cell: (info: CellContext<AuditTrailDto, AuditTrailDto>) => {
          return info.getValue().action.toUpperCase();
        },
        maxSize: 40,
        minSize: 30,
      },
      {
        header: () => 'Domain',
        accessorFn: (row) => row,
        id: 'domain',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.domain),
        cell: (info: CellContext<AuditTrailDto, AuditTrailDto>) => {
          return toTitleCase(info.getValue().domain);
        },
        maxSize: 40,
        minSize: 30,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.status),
        cell: (info: CellContext<AuditTrailDto, AuditTrailDto>) => {
          return info.getValue().status;
        },
        maxSize: 70,
        minSize: 30,
      },
      {
        header: () => 'Entity Payload',
        accessorFn: (row) => row,
        id: 'entityPayload',
        enableSorting: false,
        cell: (info: CellContext<AuditTrailDto, AuditTrailDto>) => {
          return info.getValue() &&
            info.getValue().entityPayload &&
            Object.keys(info.getValue().entityPayload).length > 0 ? (
            `${JSON.stringify(info.getValue().entityPayload).slice(0, 25)}...`
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 80,
        minSize: 50,
      },
      {
        header: () => 'Timestamp',
        accessorFn: (row) => row,
        id: 'createdAt',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.createdAt),
        cell: (info: CellContext<AuditTrailDto, AuditTrailDto>) => {
          return info.getValue().createdAt;
        },
        maxSize: 70,
        minSize: 30,
      },
    ];
  }, []);

  const handleRowClicked = (row: Row<AuditTrailDto>) => {
    setCurrentAuditEntryDetail(row.original);
    setEntryDetailDrawerOpen(true);
  };

  const filteredColumns = (activityLog: boolean) => {
    if (activityLog) {
      return auditReportColumns.filter((column) => column.id !== 'domain');
    }
    return auditReportColumns;
  };
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: spacing.g5 }}>
        <CategoryFilters
          filterTypes={AuditTrailFilterTypes}
          setFilterString={setActionFilterString}
          filterString={actionFilterString}
        />
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value.toLowerCase());
          }}
        />
      </Box>
      <Box
        sx={{
          pt: spacing.p25,
          flex: 1,
          overflow: 'auto',
        }}
      >
        <BasicTable
          rowData={filteredData ?? []}
          columnData={filteredColumns(activityLog)}
          rowClick={handleRowClicked}
          loading={loadingAuditTrail}
          initialSort={[{ id: 'createdAt', desc: false }]}
        />
        <AuditReportEntryDetailDrawer
          isOpen={entryDetailDrawerOpen}
          setIsOpen={setEntryDetailDrawerOpen}
          entry={currentAuditEntryDetail ?? undefined}
        />
      </Box>
    </>
  );
};
