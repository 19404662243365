import './utils/sentry';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/component/App';
import * as serviceWorker from './serviceWorker';
import '@/font/OrelegaOne-Regular.ttf';
import '@/font/NoiGrotesk-Medium.ttf';
import './index.css';
import '@/v2/scss/global.scss';
import '@/v2/scss/shared.scss';
import moment from 'moment';

// change start of the week to Monday.
moment.updateLocale('en', {
  week: { dow: 1 },
  calendar: {
    lastDay: '[Yesterday at] LT',
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'LLL',
  },
});

const root = createRoot(document.getElementById('root')!);
root.render(React.createElement(App));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export type { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
