import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { IncludableContractorInvoice } from '@/v2/feature/payments/pages/components/contractor-invoice-table.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { themeFonts } from '@/v2/styles/fonts.styles';

export function DisplayInvoiceUserAvatar(props: {
  row: IncludableContractorInvoice;
  user: { idField: string; userField: string };
}): JSX.Element {
  const { polyglot } = usePolyglot();
  const { row, user } = props;
  const userId = row[user.idField];
  const userData = row[user.userField];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
      <UserAvatar userId={userId} size="xxsmall" sx={{ alignSelf: 'self-start' }} />
      <Typography
        sx={{
          ...themeFonts.caption,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {userData ? polyglot.t(`${userData?.firstName} ${userData?.lastName}`) : ''}
      </Typography>
    </Box>
  );
}
