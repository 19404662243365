import { useCallback, useEffect, useState } from 'react';

import { OffboardingAbsencePage } from '@v2/feature/offboarding/offboarding-process/absence/offboarding-absence.page';
import { OffboardingAppsPage } from '@v2/feature/offboarding/offboarding-process/apps/offboarding-apps.page';
import { OffboardingDevicesPage } from '@v2/feature/offboarding/offboarding-process/devices/offboarding-devices.page';
import { OffboardingManagerChangePage } from '@v2/feature/offboarding/offboarding-process/manager-change/offboarding-manager-change.page';
import {
  OffboardingProcessPage,
  OffboardingState,
  OffboardingStepStatus,
} from '@v2/feature/offboarding/offboarding-process/offboarding-process.page';
import { OffboardingTasksPage } from '@v2/feature/offboarding/offboarding-process/tasks/offboarding-tasks.page';
import { OffboardingEndpoints } from '@v2/feature/offboarding/offboarding.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import {
  USER_OFFBOARDING_ABSENCE_ROUTE,
  USER_OFFBOARDING_APPS_ROUTE,
  USER_OFFBOARDING_DEVICES_ROUTE,
  USER_OFFBOARDING_MANAGER_CHANGE_ROUTE,
  USER_OFFBOARDING_ROUTE,
  USER_OFFBOARDING_TASKS_ROUTE,
} from '@/lib/routes';

export const OffboardingUserRouter = () => {
  const params = useParams<{ userId: string }>();
  const userId = Number(params.userId);

  const {
    data: offboardingState,
    isLoading,
    mutate: refreshOffboardingDues,
  } = useApiClient(OffboardingEndpoints.getOffboardingState(userId), { suspense: false });

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [state, setState] = useState<OffboardingState>({
    leaveDate: null,
    userEvent: null,
    tasks: OffboardingStepStatus.TODO,
    absence: OffboardingStepStatus.TODO,
    apps: OffboardingStepStatus.TODO,
    devices: OffboardingStepStatus.TODO,
    managerChange: OffboardingStepStatus.TODO,
    tasksData: { tasksNo: 0, pendingTasksNo: 0 },
    appsData: { activeAppsNo: 0, scheduledToDeactivationNo: 0, nonScheduledToDeactivationNo: 0 },
    devicesData: {
      devicePossessionAllTimeNo: 0,
      activeDevicesNo: 0,
      requestedReturnNo: 0,
      nonRequestedReturnNo: 0,
      activeOrdersNo: 0,
    },
    absenceData: {
      policyMembershipsNo: 0,
      remainingBalancePoliciesNo: 0,
      remainingBalancePayrollPoliciesNo: 0,
    },
    managerChangeData: {
      usersNeedingChangeNo: 0,
      managerChangeRequiredNo: 0,
      wasOrIsManager: false,
    },
  });

  useEffect(() => {
    if (offboardingState) {
      setState(offboardingState);
    }
  }, [offboardingState]);

  const refreshState = useCallback(async () => {
    if (refreshOffboardingDues) await refreshOffboardingDues();
  }, [refreshOffboardingDues]);

  return (
    <Switch>
      <RouteScopesControl scopes={['task:manager']} path={USER_OFFBOARDING_TASKS_ROUTE} context={scopesContext}>
        <OffboardingTasksPage userId={userId} offboardingState={state} refresh={refreshState} loading={isLoading} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['absence:manager']} path={USER_OFFBOARDING_ABSENCE_ROUTE} context={scopesContext}>
        <OffboardingAbsencePage userId={userId} offboardingState={state} refresh={refreshState} loading={isLoading} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['apps:manager']} path={USER_OFFBOARDING_APPS_ROUTE} context={scopesContext}>
        <OffboardingAppsPage userId={userId} offboardingState={state} refresh={refreshState} loading={isLoading} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['devices:manager']} path={USER_OFFBOARDING_DEVICES_ROUTE} context={scopesContext}>
        <OffboardingDevicesPage userId={userId} refresh={refreshState} loading={isLoading} />
      </RouteScopesControl>

      <RouteScopesControl
        scopes={['user.role:manager']}
        path={USER_OFFBOARDING_MANAGER_CHANGE_ROUTE}
        context={scopesContext}
      >
        <OffboardingManagerChangePage userId={userId} refresh={refreshState} loading={isLoading} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['user.onboard:manager']} path={USER_OFFBOARDING_ROUTE} context={scopesContext}>
        <OffboardingProcessPage userId={userId} offboardingState={state} refresh={refreshState} loading={isLoading} />
      </RouteScopesControl>
    </Switch>
  );
};
