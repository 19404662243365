import { IconButton, Stack, SxProps, Theme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Back } from '@/images/fields/Left.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type SideMenuHeaderProps = {
  heading: string;
  caption?: string;
  onBackClick?: () => void;
  sx?: SxProps<Theme>;
};

export const SideMenuHeader = ({ onBackClick, heading, caption, sx }: SideMenuHeaderProps) => {
  const routerHistory = useHistory();

  return (
    <Stack sx={{ flexFlow: 'row', alignItems: 'top', gap: spacing.g20, flexShrink: 0, ...sx }}>
      <IconButton sx={iconCTAButtonSx} onClick={() => (onBackClick ? onBackClick() : routerHistory.goBack())}>
        <Back />
      </IconButton>
      <Stack sx={{ gap: spacing.g2, mr: spacing.mr10 }}>
        <Typography variant="title3">{heading}</Typography>
        <Typography variant="caption">{caption}</Typography>
      </Stack>
    </Stack>
  );
};
