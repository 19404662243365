import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { WorkContactValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';

export const WorkContactSchema = Yup.object().shape({
  workMobile: Yup.string().optional().nullable(),
  workPhone: Yup.string().optional().nullable(),
});

interface Props {
  readonly initialValues: WorkContactValues;
  readonly userId: number;
  readonly onSubmit: (_: WorkContactValues) => void;
  readonly onClose: () => void;
}

export const WorkContactForm = ({ initialValues, userId, onSubmit, onClose }: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<WorkContactValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: WorkContactSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const update = {
        ...values,
      };
      try {
        // TODO return patched data
        const _result = await UserAPI.patchUserWorkContactInfo(userId, update);
        onSubmit(update);
        showMessage(polyglot.t('WorkContactForm.successMessages.update'), 'success');
      } catch (error) {
        showMessage(polyglot.t('WorkContactForm.errorMessages.save', { msg: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('WorkContactForm.edit')}</Typography>
        <ProfileField fieldStub="user.workMobile">
          <TextfieldComponent
            name="workMobile"
            label={polyglot.t('WorkContactForm.workMobile')}
            value={formik.values.workMobile}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.workMobile && !!formik.errors.workMobile}
            helperText={(formik.touched.workMobile && formik.errors.workMobile) ?? ' '}
            clearText={() => formik.setFieldValue('workMobile', '')}
          />
        </ProfileField>
        <ProfileField fieldStub="user.workPhone">
          <TextfieldComponent
            name="workPhone"
            label={polyglot.t('WorkContactForm.workPhone')}
            value={formik.values.workPhone}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.workPhone && !!formik.errors.workPhone}
            helperText={(formik.touched.workPhone && formik.errors.workPhone) ?? ' '}
            clearText={() => formik.setFieldValue('workPhone', '')}
          />
        </ProfileField>
        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton name="Save" loading={loading} fullWidth sizeVariant="medium" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
