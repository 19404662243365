import { GlobalContext } from '@/GlobalState';
import { canAccessScopes, checkScopes } from '@/lib/scopes';
import { Scope, ScopeContext } from '@/models';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React, { Children, FunctionComponent, PropsWithChildren, ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const ScopesSwitch: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state] = useContext(GlobalContext);
  let matched, otherwise;
  Children.toArray(children)?.some((child) => {
    if (React.isValidElement<ScopesControlProps>(child)) {
      if (child.props.otherwise) {
        otherwise = child;
      } else if (checkScopes(state.user, child.props.scopes, child.props.context)) {
        matched = child;
        return true;
      }
    }
    return false;
  });
  return matched || otherwise || null;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    whiteSpace: 'pre-line',
    backgroundColor: 'red',
  },
});

export interface ScopesInspectorProps {
  readonly scopes: readonly Scope[];
  readonly isRoute?: boolean;
  readonly children?: ReactNode;
}

export const ScopesInspector = ({ children, scopes, isRoute = false }: ScopesInspectorProps): JSX.Element => {
  const tooltipText = `${isRoute ? 'Route: ' : ''} ${scopes.join('\n')}`;
  return (
    <>
      {!isRoute && (
        <CustomWidthTooltip title={tooltipText} placement="top">
          <Box
            sx={{
              border: 1,
              borderColor: 'red',
              minWidth: 10,
              minHeight: 10,
              width: 'inherit',
              borderStyle: 'dotted',
            }}
          >
            {children}
          </Box>
        </CustomWidthTooltip>
      )}

      {isRoute && (
        <Box
          sx={{ border: 1, borderColor: 'orange', minWidth: 10, minHeight: 10, width: 'inherit', position: 'relative' }}
        >
          <Typography
            sx={{
              position: 'absolute',
              bottom: 0,
              backgroundColor: 'orange',
              color: 'white',
              zIndex: 99,
              padding: 1,
            }}
          >
            {tooltipText}
          </Typography>
          {children}
        </Box>
      )}
    </>
  );
};

interface ScopesControlProps {
  readonly scopes: readonly Scope[];
  readonly children?: ReactNode;
  readonly context?: ScopeContext;
  readonly otherwise?: boolean;
}

export const ScopesControl = ({ children, scopes, context }: ScopesControlProps): JSX.Element => {
  const ctx = useContext(GlobalContext);
  if (!ctx) return <>{children}</>;
  const state = ctx[0] || {};
  if (!scopes || !scopes.length) return <>{children}</>;
  const shouldRender = checkScopes(state.user, scopes, context);
  if (!shouldRender && !state.inspectorMode) return <></>;
  if (shouldRender && !state.inspectorMode) return <>{children}</>;
  return <ScopesInspector scopes={scopes}>{shouldRender && children}</ScopesInspector>;
};

interface RouteScopesHasProps<Path extends string = string> extends RouteProps<Path> {
  readonly scopes: readonly Scope[];
}

export const RouteScopesHas = <Path extends string = string>({
  scopes,
  ...props
}: RouteScopesHasProps<Path>): JSX.Element => {
  const ctx = useContext(GlobalContext);
  if (!ctx) {
    return <Route {...props} />;
  }
  const state = ctx[0] || {};
  const shouldRender = canAccessScopes(state.user, scopes);

  if (!shouldRender) {
    return <Redirect to="/" />;
  }
  if (shouldRender && !state.inspectorMode) {
    return <Route {...props} />;
  }

  return (
    <ScopesInspector scopes={scopes} isRoute={true}>
      <Route {...props} />
    </ScopesInspector>
  );
};

interface ScopesRouteControlProps extends RouteProps {
  readonly scopes: readonly Scope[];
  readonly context?: ScopeContext | null;

  readonly [x: string]: any;
}

export const RouteScopesControl: FunctionComponent<ScopesRouteControlProps> = ({ scopes, context, ...props }) => {
  const ctx = useContext(GlobalContext);
  if (!ctx) return <Route {...props} />;
  const state = ctx[0] || {};
  const shouldRender = canAccessScopes(state.user, scopes);
  if (!shouldRender) return <Redirect to="/" />;
  if (shouldRender && !state.inspectorMode) return <Route {...props} />;

  return (
    <ScopesInspector scopes={scopes} isRoute={true}>
      <Route {...props} />
    </ScopesInspector>
  );
};
