import { Box, Slide } from '@mui/material';

import ThemeLogin from '@/images/auth/Illustartion.png';
import { ReactComponent as ZeltText } from '@/images/zelt-text.svg';
import { AuthPageLiner } from '@/v2/feature/auth/components/auth-page-liner.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const AuthThemeBg = () => {
  return (
    <Box sx={{ boxSizing: 'border-box', height: '100%', ...spacing.pad40 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box>
          <a
            href="https://zelt.app/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', position: 'absolute', top: '20px', left: '30px' }}
          >
            <ZeltText style={{ fill: themeColors.black }} />
          </a>
        </Box>
        <Box>
          <Slide direction="down" in={true} mountOnEnter timeout={{ enter: 600 }}>
            <Box
              sx={{
                backgroundImage: `url(${ThemeLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top right',
                backgroundSize: 'contain',
                height: { xs: '250px', sm: '350px', md: '400px', lg: '450px' },
              }}
            />
          </Slide>
        </Box>
        <Box>
          <AuthPageLiner />
        </Box>
      </Box>
    </Box>
  );
};
