import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceAdjustmentImportDto } from '@v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly entity: AbsenceAdjustmentImportDto | null;
  readonly absencePolicies: AbsencePolicyDto[];
  readonly importHandler: (values: AbsenceAdjustmentImportDto) => void;
  readonly onClose: () => void;
}

export const AbsenceAdjustmentImportDrawer = ({
  isOpen,
  setIsOpen,
  entity,
  absencePolicies,
  importHandler,
  onClose,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      {entity ? (
        <AbsenceAdjustmentImportDrawerContent
          entity={entity}
          importHandler={importHandler}
          absencePolicies={absencePolicies}
          onClose={onClose}
        />
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly entity: AbsenceAdjustmentImportDto;
  readonly importHandler: (values: AbsenceAdjustmentImportDto) => void;
  readonly absencePolicies: AbsencePolicyDto[];
  readonly onClose: () => void;
}

export const AbsenceAdjustmentImportDrawerContent = ({
  entity,
  importHandler,
  absencePolicies,
  onClose,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();

  const policyOptions = useMemo(() => {
    return absencePolicies.map((p) => ({ value: p.id, label: p.fullName }));
  }, [absencePolicies]);

  const onSubmit = useCallback(
    async (values: AbsenceAdjustmentImportDto) => {
      try {
        setLoading(true);

        importHandler(values);
        onClose();
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [importHandler, onClose, showMessage, polyglot]
  );

  const formik = useFormik<AbsenceAdjustmentImportDto>({
    initialValues: {
      id: entity.id,
      firstName: entity.firstName,
      lastName: entity.lastName,
      workEmail: entity.workEmail,
      effectiveYear: entity.effectiveYear,
      policyName: entity.policyName,
      adjustment: entity.adjustment,
      unit: ['Days', 'Hours'].includes(entity.unit) ? entity.unit : '',
      note: entity.note,
      policyId: absencePolicies.find((p) => p.id === entity.policyId)?.id,
      userId: entity.userId ? getCachedUserById(entity.userId)?.userId : entity.userId,
    },
    validationSchema: yup.object({
      id: yup.string().required('Row id is required'),
      userId: yup.number().integer('User ID is required').required('User is required'),
      policyId: yup.number().integer('Policy ID is required').required('Policy is required'),
      firstName: yup.string().required('User is required'),
      lastName: yup.string().required('User is required'),

      policyName: yup.string().required('Policy is required'),
      effectiveYear: yup
        .number()
        .integer('Please input a valid effective year')
        .required(polyglot.t('ValidationMessages.requiredField')),
      adjustment: yup.number().typeError('Please input a valid number').required('Adjustment is required'),
      unit: yup.string().oneOf(['Days', 'Hours'], 'Please select one of "Day" or "Hours').required('Unit is required'),
      note: yup.string().nullable().notRequired(),
    }),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Update adjustment</Typography>

        <SingleUserSelect
          name="userId"
          options="company"
          allUsers
          onChange={(_, x) => {
            if (!x?.value) return;
            const userId = x.value;
            formik.setFieldValue('userId', userId ?? null);

            const user = getCachedUserById(userId);
            if (user) {
              formik.setFieldValue('firstName', user.firstName);
              formik.setFieldValue('lastName', user.lastName);
              formik.setFieldValue('workEmail', user.emailAddress);
            }
          }}
          value={formik.values.userId}
          error={formik.touched.userId && Boolean(formik.errors.userId)}
          helperText={formik.touched.userId && (formik.errors.userId as string)}
        />

        <SelectComponent
          name="policyId"
          label={'Policy'}
          options={policyOptions}
          value={formik.values.policyId ?? ''}
          onChange={(e) => {
            formik.handleChange(e);
            const policyId = Number(e.target.value);
            const policy = absencePolicies.find((p) => p.id === policyId);
            if (policy) formik.setFieldValue('policyName', policy.fullName);
          }}
          compareValue={formik.values.policyId}
          error={!!formik.errors.policyId && formik.touched.policyId}
          helperText={formik.touched.policyId && (formik.errors.policyId as string)}
        />

        <TextfieldComponent
          name="effectiveYear"
          label={'Effective year'}
          value={formik.values.effectiveYear}
          onChange={formik.handleChange}
          error={formik.touched.effectiveYear && !!formik.errors.effectiveYear}
          helperText={(formik.touched.effectiveYear && formik.errors.effectiveYear) ?? ' '}
        />

        <TextfieldComponent
          name="adjustment"
          label={'Adjustment'}
          value={formik.values.adjustment}
          onChange={formik.handleChange}
          error={formik.touched.adjustment && !!formik.errors.adjustment}
          helperText={(formik.touched.adjustment && formik.errors.adjustment) ?? ' '}
        />

        <SelectComponent
          name="unit"
          label={'Unit'}
          options={[
            { value: 'Days', label: 'Days' },
            { value: 'Hours', label: 'Hours' },
          ]}
          value={formik.values.unit}
          onChange={formik.handleChange}
          compareValue={formik.values.unit}
          error={!!formik.errors.unit && formik.touched.unit}
          helperText={formik.touched.unit && (formik.errors.unit as string)}
        />

        <TextfieldComponent
          name="note"
          label={'Note'}
          value={formik.values.note}
          onChange={formik.handleChange}
          error={formik.touched.note && !!formik.errors.note}
          helperText={(formik.touched.note && formik.errors.note) ?? ' '}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.update')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
