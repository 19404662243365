import { useEffect, useMemo, useState } from 'react';

import { ContractorInvoiceModal } from '@v2/feature/payments/pages/components/contractor-invoice-modal.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { ContractorInvoiceEndpoints } from '@/api-client/contractor-invoice-api';
import useScopes from '@/hooks/scopes.hook';
import { InvoiceRequestTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const InvoiceRequestActionItem = ({
  invoiceRequestTodo,
  refresh,
  afterClose,
}: {
  readonly invoiceRequestTodo: InvoiceRequestTodo | null;
  readonly refresh: () => Promise<void>;
  readonly afterClose: () => void;
}) => {
  const { data: invoice } = useApiClient(
    invoiceRequestTodo
      ? ContractorInvoiceEndpoints.getUserInvoiceById(invoiceRequestTodo.userId, invoiceRequestTodo.id)
      : null,
    { suspense: false }
  );

  const { hasScopes, getScopesContext } = useScopes();

  const hasAdminScope = useMemo(() => {
    return invoice ? hasScopes(['invoices:all'], getScopesContext({ userId: invoice.from })) : false;
  }, [invoice, hasScopes, getScopesContext]);

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsViewOpen(Boolean(invoiceRequestTodo));
  }, [invoiceRequestTodo]);

  return invoice ? (
    <ContractorInvoiceModal
      isOpen={isViewOpen}
      setIsOpen={setIsViewOpen}
      selectedInvoice={invoice}
      onClose={() => {
        setIsViewOpen(false);
      }}
      afterClose={afterClose}
      onActionPerformed={refresh}
      currentUserIsAdmin={hasAdminScope}
    />
  ) : null;
};
