import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { NewExpensePage } from '@v2/feature/payments/pages/new-expense.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { LocalDate } from '@v2/util/local-date';
import { useLocation, useParams } from 'react-router-dom';

interface PageProps {
  readonly userId: number;
  readonly userBenefits: readonly UserCustomBenefitDto[];
}

export const UserBenefitExpensePage = ({ userId, userBenefits }: PageProps) => {
  const params = useParams<{ id: string }>();
  const customBenefitId = Number(params.id);
  const userBenefit = userBenefits.find((b) => b.customBenefitId === customBenefitId);

  const { data: remainingAllowance } = useApiClient(
    userBenefit
      ? CustomBenefitEndpoints.getUserCustomBenefitRemainingAllowance(
          userId,
          userBenefit.customBenefitId,
          userBenefit.id,
          new LocalDate().toDateString()
        )
      : null,
    { suspense: false }
  );

  const location = useLocation();
  const fromMePage = location.pathname.includes('/me/');

  return (
    <NewExpensePage
      userBenefit={userBenefit}
      preselectedEmployee={userId}
      fromUserProfile={!fromMePage}
      reach="user"
      maxAmount={remainingAllowance}
    />
  );
};
