import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  PaymentCategoryEnum,
  PaymentTypeSettings,
  PaymentTypeSettingsApprovalUpdate,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { PaymentTypeSettingsAPI } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';

interface PaymentSettingsEditApprovalRulesDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refresh: KeyedMutator<PaymentTypeSettings> | undefined;
  readonly refreshApprovalRules: () => Promise<void>;
}

const getApprovalRulesOptions = (approvalRules: readonly { id: number; name: string }[]) =>
  approvalRules.map((rule) => ({
    label: rule.name,
    value: rule.id,
  }));

export const PaymentSettingsEditApprovalRulesDrawer = ({
  isOpen,
  setIsOpen,
  paymentTypeSetting,
  approvalRules,
  refreshApprovalRules,
  refresh,
}: PaymentSettingsEditApprovalRulesDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();

  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);

  const onSubmit = useCallback(
    async (values: PaymentTypeSettingsApprovalUpdate) => {
      if (!paymentTypeSetting.id || !values.approvalRuleId) return;
      try {
        setLoading(true);
        await PaymentTypeSettingsAPI.updateApproval(paymentTypeSetting.id, values.approvalRuleId);
        setIsOpen(false);
        showMessage('Successfully updated approval settings', 'success');
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceDrawerPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
        await refresh!();
      }
    },
    [paymentTypeSetting.id, setIsOpen, showMessage, polyglot, refresh]
  );

  const formik = useFormik<PaymentTypeSettingsApprovalUpdate>({
    initialValues: {
      approvalRuleId: paymentTypeSetting?.approvalRuleId,
    },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('PaymentSettingsPage.editApprovalRules')}</Typography>

          <SelectComponent
            name="approvalRuleId"
            label={polyglot.t('ApprovalRuleModule.approvalRule')}
            options={getApprovalRulesOptions(approvalRules)}
            value={formik.values.approvalRuleId}
            onChange={formik.handleChange}
            compareValue={formik.values.approvalRuleId}
            error={!!formik.errors.approvalRuleId && formik.touched.approvalRuleId}
            helperText={formik.touched.approvalRuleId && (formik.errors.approvalRuleId as string)}
            editList={
              paymentTypeSetting.type === PaymentCategoryEnum.EXPENSE
                ? {
                    isHidden: !hasScopes(['company.settings:all']),
                    handler: () => {
                      setIsApprovalDrawerOpen(true);
                    },
                  }
                : undefined
            }
          />

          <ApprovalRuleDrawer
            isOpen={isApprovalDrawerOpen}
            setIsOpen={setIsApprovalDrawerOpen}
            approvalRule={null}
            afterClose={() => {
              setIsApprovalDrawerOpen(false);
            }}
            refresh={async () => {
              if (refreshApprovalRules) await refreshApprovalRules();
            }}
          />

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
