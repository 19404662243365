import { Box, Typography } from '@mui/material';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import {
  AttendanceStatus,
  ScheduleScheduleFormData,
  ScheduleTrackingTimesheetType,
  ScheduleTrackingType,
  WeekDay,
  WeekDayNames,
} from '@v2/feature/attendance/attendance.interface';
import { dateFieldTest, isMonday } from '@v2/infrastructure/date/date-format.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { LocalDate } from '@v2/util/local-date';
import { format } from 'date-fns';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as WaitingEmpty } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { ReactComponent as WaitingEmptyRed } from '@/images/side-bar-icons/WaitingEmptyRed.svg';
import { spacing } from '@/v2/styles/spacing.styles';

// If modifying this method, make sure to update the same method in BE (attendance-schedule.util.ts)
export const getTheDateOfFirstMondayInYear = (year = new Date().getFullYear()): string => {
  // Create a new Date object for January 1st of the given year
  const firstDay = new Date(year, 0, 1);

  // Calculate the day of the week for January 1st (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = firstDay.getDay();

  // Calculate the number of days to add to get to the first Monday
  const daysToAdd = (8 - dayOfWeek) % 7;

  // Create a new Date object for the first Monday by adding the calculated days
  const firstMonday = new Date(firstDay);
  firstMonday.setDate(firstDay.getDate() + daysToAdd);

  return new LocalDate(firstMonday).toDateString();
};

export const EMPLOYEE_SITE = (polyglot: Polyglot) => {
  return { value: 'employeeSite', label: polyglot.t('EMPLOYEE_SITE.employeeSite') };
};

export const getEmptyDaySlot = () => ({
  from: '',
  to: '',
  fromTimestamp: null,
  toTimestamp: null,
  break: '',
  totalHours: '',
});

const getDayScheduleValidationSchema = (polyglot: Polyglot) =>
  yup
    .array()
    .of(
      yup
        .object({
          from: yup.string().required(),
          to: yup.string().required(),
          fromTimestamp: yup
            .date()
            .typeError(polyglot.t('validation.selectValid'))
            .required(polyglot.t('validation.requiredField')),
          toTimestamp: yup
            .date()
            .typeError(polyglot.t('validation.selectValid'))
            .required(polyglot.t('validation.requiredField')),
          break: yup
            .string()
            .notRequired()
            .matches(/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d$/, polyglot.t('AttendanceDomain.validTimeValue')),
        })
        .nullable()
        .notRequired()
    )
    .required(polyglot.t('validation.requiredField'));

export const getScheduleValidationSchemaV2 = (polyglot: Polyglot) =>
  yup.object({
    monday: getDayScheduleValidationSchema(polyglot),
    tuesday: getDayScheduleValidationSchema(polyglot),
    wednesday: getDayScheduleValidationSchema(polyglot),
    thursday: getDayScheduleValidationSchema(polyglot),
    friday: getDayScheduleValidationSchema(polyglot),
    saturday: getDayScheduleValidationSchema(polyglot),
    sunday: getDayScheduleValidationSchema(polyglot),
    noOfWeeks: yup
      .number()
      .typeError(polyglot.t('validation.selectValid'))
      .integer(polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
    startDateOfFirstWeek: yup
      .string()
      .test(dateFieldTest)
      .test(isMonday)
      .required(polyglot.t('validation.requiredField')),
  });

export const getScheduleGeneralValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup.string().required(polyglot.t('validation.requiredField')),
  });

export const getScheduleTrackingSettingsValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    trackingType: yup.string().required(polyglot.t('validation.requiredField')),
    attendanceTypesAllowedIds: yup
      .array()
      .of(
        yup
          .number()
          .integer()
          .typeError(polyglot.t('validation.selectValid'))
          .required(polyglot.t('validation.requiredField'))
      )
      .required(polyglot.t('validation.requiredField')),
    timesheetType: yup
      .string()
      .oneOf([
        ScheduleTrackingTimesheetType.Daily,
        ScheduleTrackingTimesheetType.Weekly,
        ScheduleTrackingTimesheetType.Monthly,
      ])
      .required(polyglot.t('validation.selectValid')),
    useGeolocation: yup.boolean().required(polyglot.t('validation.requiredField')),
    restrictByGeolocation: yup.boolean().required(polyglot.t('validation.requiredField')),
    geolocationDistance: yup.number().when('restrictByGeolocation', {
      is: true,
      then: (schema) =>
        schema
          .integer(polyglot.t('validation.selectValid'))
          .typeError(polyglot.t('validation.selectValid'))
          .min(100, polyglot.t('AttendanceDomain.minimumDistanceAllowed', { distance: 100 }))
          .max(500, polyglot.t('AttendanceDomain.maximumDistanceAllowed', { distance: 500 }))
          .required(polyglot.t('validation.requiredField')),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    geolocationEmployeeSite: yup.boolean().required(polyglot.t('validation.requiredField')),
    allowedWorkSites: yup.array().when('restrictByGeolocation', {
      is: true,
      then: (schema) =>
        schema.when('geolocationEmployeeSite', {
          is: true,
          then: (schema) =>
            schema
              .of(yup.number().integer().typeError(polyglot.t('validation.selectValid')))
              .nullable()
              .notRequired(),
          otherwise: (schema) =>
            schema
              .of(yup.number().integer().typeError(polyglot.t('validation.selectValid')))
              .min(1, polyglot.t('validation.requiredField'))
              .required(polyglot.t('validation.requiredField')),
        }),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    clockInEarlyCapMinutes: yup.number().when('trackingType', {
      is: (val: ScheduleTrackingType) => val === ScheduleTrackingType.ClockInClockOut,
      then: (schema) =>
        schema
          .integer()
          .typeError(polyglot.t('validation.selectValid'))
          .min(0, polyglot.t('validation.selectValid'))
          .required(polyglot.t('validation.requiredField')),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  });

export const getScheduleApprovalValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    approvalRuleId: yup
      .number()
      .integer()
      .typeError(polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
  });

export const getSchedulePayrollValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    includedInPayroll: yup.boolean().required(polyglot.t('validation.requiredField')),
    payCode: yup.string().when('includedInPayroll', {
      is: true,
      then: (schema) => schema.required(polyglot.t('validation.requiredField')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export const getScheduleSettingsValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    isFlexible: yup.boolean().required(polyglot.t('validation.requiredField')),
    fteEquivalent: yup
      .number()
      .integer(polyglot.t('validation.selectValid'))
      .typeError(polyglot.t('validation.selectValid'))
      .min(1, polyglot.t('validation.selectValid'))
      .max(10080, polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
    fteEquivalentInDays: yup
      .number()
      .typeError(polyglot.t('validation.selectValid'))
      .min(1, polyglot.t('validation.selectValid'))
      .max(7, polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
  });

export function getTrackingTypeLabel(type: ScheduleTrackingType, polyglot: Polyglot): string {
  if (type === ScheduleTrackingType.None) return polyglot.t('getTrackingTypeLabel.noTracking');
  if (type === ScheduleTrackingType.ClockInClockOut) return polyglot.t('getTrackingTypeLabel.clockInOut');
  if (type === ScheduleTrackingType.Regular) return polyglot.t('getTrackingTypeLabel.regularTracking');

  return 'Unknown tracking type';
}

export function getTrackingTypeLabelDescription(type: ScheduleTrackingType, polyglot: Polyglot): string {
  if (type === ScheduleTrackingType.None) return polyglot.t('getTrackingTypeLabelDescription.employees');
  if (type === ScheduleTrackingType.ClockInClockOut) return polyglot.t('getTrackingTypeLabelDescription.clockIn');
  if (type === ScheduleTrackingType.Regular) return polyglot.t('getTrackingTypeLabelDescription.loggingHours');

  return '';
}

export const getAllTimeFromScheduleByWeekIndex = (schedule: ScheduleScheduleFormData, weekIndex: number) => {
  const lengthByDays = WeekDayNames.map((day) => {
    if (
      !schedule[day] ||
      !schedule[day][weekIndex] ||
      !schedule[day]![weekIndex]!.fromTimestamp ||
      !schedule[day]![weekIndex]!.toTimestamp
    )
      return 0;

    if (schedule.isFlexible) {
      const [totalHoursH, totalHoursM] = (schedule[day] &&
      schedule[day]![weekIndex]!.totalHours?.slice(11, 16).match(/^\d\d:\d\d$/g)
        ? schedule[day]![weekIndex]!.totalHours?.slice(11, 16).split(':')
        : [0, 0]) as [number, number];

      return Number(totalHoursM) + Number(totalHoursH) * 60;
    } else {
      const [breakH, breakM] =
        schedule[day] && schedule[day]![weekIndex]?.break?.slice(11, 16).match(/^\d\d:\d\d$/g)
          ? schedule[day]![weekIndex]?.break.slice(11, 16).split(':')!
          : ['0', '0'];
      const dayBreakMins = Number(breakM) + Number(breakH) * 60;

      const toTicks = new Date(schedule[day]![weekIndex]!.toTimestamp!).getTime() ?? 0;
      const fromTicks = new Date(schedule[day]![weekIndex]!.fromTimestamp!).getTime() ?? 0;
      let dayTime = schedule[day] && schedule[day]![weekIndex]! ? (toTicks - fromTicks) / (1000 * 60) : 0;

      if (toTicks < fromTicks) dayTime += 24 * 60;

      return dayTime - dayBreakMins;
    }
  });

  return lengthByDays.reduce((a, b) => a + b);
};

/**
 * Calculates entries duration in clock hours
 * Has a similar method in BE named also calculateTotalEntriesDuration and is tested.
 * Keep both methods synced and update BE tests if needed.
 * @param allEntries
 * @param polyglot
 */
export function calculateTotalEntriesDuration(
  allEntries: {
    startHourTimestamp: Date | string | null;
    endHourTimestamp: Date | string | null;
  }[],
  polyglot: Polyglot
): string {
  let totalDuration = 0;
  const entries = allEntries.filter(
    (entry) =>
      entry.startHourTimestamp &&
      entry.endHourTimestamp &&
      new Date(entry.endHourTimestamp).getTime() > new Date(entry.startHourTimestamp).getTime()
  ) as {
    startHourTimestamp: Date;
    endHourTimestamp: Date;
  }[];

  // Sort the entries based on the startHour in ascending order
  entries.sort((a, b) => new Date(a.startHourTimestamp).getTime() - new Date(b.startHourTimestamp).getTime());

  let previousEnd: Date | null = null;

  entries.forEach((entry) => {
    const { startHourTimestamp, endHourTimestamp } = entry;
    const start = new Date(startHourTimestamp);
    const end = new Date(endHourTimestamp);

    // If there is an intersection with the previous entry, calculate the difference
    if (previousEnd && start.getTime() < previousEnd.getTime()) {
      // Calculate the difference only if current entry is not entirely included in previous entry
      if (end.getTime() > previousEnd.getTime()) {
        totalDuration += end.getTime() - previousEnd.getTime();

        // previousEnd should be updated only if currentEntry is not entirely included in the previous entry
        previousEnd = end;
      }
    } else {
      // No intersection, add the full duration of the current entry
      totalDuration += end.getTime() - start.getTime();
      previousEnd = end;
    }
  });

  const totalLengthInMinutes = Math.round(totalDuration / (1000 * 60));

  return convertMinutesToClockHours(totalLengthInMinutes, polyglot);
}

export const getAttendanceStatusIcon = (
  status: AttendanceStatus | 'none',
  cancellationRequested: boolean,
  polyglot: Polyglot
) => {
  switch (status) {
    case AttendanceStatus.InProgress:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <WaitingEmpty {...iconSize} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('AttendanceStatus.inprogress')}
          </Typography>
        </Box>
      );

    case AttendanceStatus.Submitted:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <WaitingEmpty {...iconSize} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
            {polyglot.t('AttendanceStatus.submitted')}
          </Typography>
        </Box>
      );

    case AttendanceStatus.Approved:
      return cancellationRequested ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <WaitingEmptyRed {...iconSize} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Red }}>
            {polyglot.t('AttendanceStatus.pendingcancellation')}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {polyglot.t('AttendanceStatus.approved')}
          </Typography>
        </Box>
      );

    case AttendanceStatus.Rejected:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <Rejected {...iconSize} fill={themeColors.Red} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {polyglot.t('AttendanceStatus.rejected')}
          </Typography>
        </Box>
      );

    case 'none':
      return (
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
          {polyglot.t('AttendanceStatus.none')}
        </Typography>
      );
    default:
      return <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>{status}</Typography>;
  }
};

export function getWeekDates(year: number, weekNumber: number): { value: string; label: string }[] {
  const startDate = new Date(year, 0, 1);
  const firstDay = startDate.getDay();
  const daysToAdd = (weekNumber - 1) * 7 - firstDay + 1;

  startDate.setDate(startDate.getDate() + daysToAdd);

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate.getTime());
    currentDate.setDate(currentDate.getDate() + i);
    dates.push({
      value: new LocalDate(currentDate).toDateString(),
      label: format(currentDate, 'eee'),
    });
  }

  return dates;
}

export function getTimeFromDateString(dateString: string): string {
  // Split the string at the 'T' character
  const dateTimeParts = dateString.split('T');

  // Extract the time portion from the second part
  return dateTimeParts[1].substring(0, 5);
}

export function getWeekStatusFromAttendanceStatuses(weekStatuses: Set<AttendanceStatus>): AttendanceStatus | 'none' {
  if (weekStatuses.size === 0) return 'none';
  if (weekStatuses.size === 1) return [...weekStatuses][0];

  if (weekStatuses.has(AttendanceStatus.InProgress)) return AttendanceStatus.InProgress;

  if (weekStatuses.has(AttendanceStatus.Rejected)) return AttendanceStatus.Rejected;

  return AttendanceStatus.Submitted;
}

export const getBreakFromSchedule = (
  day: WeekDay,
  userSchedule: AttendanceScheduleDto | null | undefined,
  logDate: string
): number => {
  if (!userSchedule) return 0;
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);

  const breakValue = userSchedule[day] && userSchedule[day][weekIndex] ? userSchedule[day]![weekIndex]!.break : null;
  if (!breakValue) return 0;

  const [h, m] = breakValue.split('T')[1].split(':');
  return Number(h) * 60 + Number(m);
};

export const getTotalHoursFromFlexibleSchedule = (
  day: WeekDay,
  userSchedule: AttendanceScheduleDto | null | undefined,
  logDate: string
): number => {
  if (!userSchedule) return 0;
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);

  const totalHours =
    userSchedule && userSchedule[day] && userSchedule[day][weekIndex]
      ? userSchedule[day]![weekIndex]!.totalHours
      : null;
  if (!totalHours) return 0;

  const [h, m] = totalHours.split('T')[1].split(':');
  return Number(h) * 60 + Number(m);
};

export const getRegularTimeFromInterval = (
  day: WeekDay,
  userSchedule: AttendanceScheduleDto | null | undefined,
  breakTime: number,
  logDate: string
): number => {
  if (!userSchedule) return 0;
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);

  const dayData =
    userSchedule && userSchedule[day] && userSchedule[day][weekIndex] ? userSchedule[day]![weekIndex]! : null;
  if (!dayData || !dayData.from || !dayData.to) return 0;

  // return difference in minutes between day.to & day.from - breakTime
  return Math.round((new Date(dayData.to).getTime() - new Date(dayData.from).getTime()) / 60000) - breakTime;
};

export const getScheduleExpectedRegularAndBreakTimeByDay = (
  day: WeekDay,
  userSchedule: AttendanceScheduleDto | null | undefined,
  logDate: string
): {
  Regular: number;
  Break: number;
} => {
  if (userSchedule?.isFlexible)
    return {
      Regular: getTotalHoursFromFlexibleSchedule(day, userSchedule, logDate),
      Break: 0,
    };

  const dayBreak = getBreakFromSchedule(day, userSchedule, logDate);
  const dayRegular = getRegularTimeFromInterval(day, userSchedule, dayBreak, logDate);

  return {
    Regular: dayRegular,
    Break: dayBreak,
  };
};

export const getWeekInterval = (
  year: number,
  weekNo: number
): {
  start: string;
  end: string;
} => {
  const date = new Date(year, 0, 1);
  const startDay = 1; // Monday

  while (date.getDay() !== startDay) {
    date.setDate(date.getDate() + 1);
  }

  date.setDate(date.getDate() + (weekNo - 1) * 7);

  const startDate = new Date(date.getTime());
  startDate.setDate(startDate.getDate() - startDate.getDay() + startDay);
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + 6);

  return {
    start: new LocalDate(startDate).toDateString(),
    end: new LocalDate(endDate).toDateString(),
  };
};
