import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceNotesComponent } from '@v2/feature/device/features/device-cards/components/device-notes.component';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { RootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly devicePossession: DevicePossessionDto;
  readonly loading: boolean;
  refresh: () => Promise<void>;
}

export const DeviceNotesPage = ({ devicePossession, refresh }: Props) => {
  return (
    <RootStyle>
      <TopHeader title="Notes" />

      <ContentWrapper loading={false}>
        <DeviceNotesComponent devicePossession={devicePossession} refresh={refresh} />
      </ContentWrapper>
    </RootStyle>
  );
};
