import { SyntheticEvent, useContext, useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { appsWithoutAutomaticAccountCreation } from '@v2/feature/app-integration/app-integration.util';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
  APP_STORE_ROUTE,
} from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { AppIntegrationAPI, AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { AppCard } from '@/v2/feature/app-integration/apps.page';
import { AppButton } from '@/v2/feature/app-integration/components/app-button.component';
import { AppIntroduction } from '@/v2/feature/app-integration/components/app-introduction.component';
import { AppRequestAPI, AppRequestEndpoints } from '@/v2/feature/app-integration/features/app-request/app-request.api';
import { RequestDto } from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const PersonalOverview = () => {
  const [globalState] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [filteredCompanyApps, setFilteredCompanyApps] = useState<InstalledAppDto[] | undefined>(undefined);
  const { getScopesContext } = useScopes();

  const { data: myApps, isValidating: myAppsLoading } = useApiClient(
    AppIntegrationEndpoints.getUserApps(globalState?.user?.userId),
    { suspense: false }
  );
  const {
    data: installedApps,
    isValidating: installedAppsLoading,
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });

  const { data: latestApps } = useApiClient(AppIntegrationEndpoints.getLatestApps(), { suspense: false });

  const { data: requestsOnlyForUser, mutate: refreshRequestsForUser } = useApiClient(
    AppRequestEndpoints.getAppRequestsForUser('Requested,Failed,Scheduled,Pending'),
    {
      suspense: false,
    }
  );

  useEffect(() => {
    if (!myAppsLoading && !installedAppsLoading) {
      const nonAppOwnerAppsStubs = myApps?.map((app) => app.stub);
      const nonAppOwnerApps = installedApps?.filter((app) => !nonAppOwnerAppsStubs?.includes(app.stub));
      setFilteredCompanyApps(nonAppOwnerApps);
    }
  }, [installedApps, myApps, myAppsLoading, installedAppsLoading]);

  const [showMessage] = useMessage();

  const createAppRequest = async (appStub: AppIntegrationStub): Promise<void> => {
    try {
      const appRequestBody: RequestDto = {
        requestInfo: { appStub },
      };
      if (installedApps && appsWithoutAutomaticAccountCreation(installedApps).includes(appStub)) {
        await AppRequestAPI.createPendingAppRequest(appRequestBody);
      } else {
        await AppRequestAPI.createAppRequest(appRequestBody);
      }
      refreshRequestsForUser!();
      showMessage(`App was successfully requested.`, 'success');
    } catch (error) {
      showMessage(`App request failed. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const initiateOauthFlowForApp = (event: React.SyntheticEvent, appStub: AppIntegrationStub) => {
    if (event) event.preventDefault();
    window.location.href = AppIntegrationAPI.getInitiateUrl(appStub);
  };

  const getActionForConnect = (app: InstalledAppDto, event: SyntheticEvent<Element, Event>) => {
    // if (globalState.user.restrictions.APPS.disableAppCreation || globalState.user.restrictions.TECH.disableApps) {
    //   // setUpgradeModalOpen(true);
    // } else {
    if (app?.authorised && !app?.directory?.oauth)
      return routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, { appStub: app.stub }));
    // if (!app?.authorised && !app?.directory?.oauth) return setBasicAuthConnectDrawerOpen(true);
    if (app?.directory?.oauth) return initiateOauthFlowForApp(event, app.stub);
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Apps</Typography>} />
      <ContentWrapper loading={(myAppsLoading || installedAppsLoading) ?? false}>
        {myApps?.length === 0 && installedApps?.length === 0 ? (
          <AppIntroduction />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: spacing.g40,
              alignItems: 'flex-start',
              flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '100%', md: '60%', lg: '60%' } }}>
              {/* User apps */}
              <Box>
                <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, ...spacing.mb20 }}>
                  Your apps
                </Typography>

                <Box sx={{ display: 'flex', gap: spacing.g10, flexWrap: 'wrap' }}>
                  {myApps && myApps?.length > 0 ? (
                    myApps.map((a, idx) => (
                      <AppButton
                        key={`${a.stub}-${idx}`}
                        title={installedApps?.find((eachApp) => eachApp.stub === a.stub)?.name ?? ''}
                        handleClick={() =>
                          routerHistory.push(
                            generatePath(APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE, {
                              appStub: a.stub,
                            })
                          )
                        }
                        avatarImg={`/app-icons-v2/images/${a.stub}.png`}
                        stub={a.stub}
                        app={a as InstalledAppDto}
                        expired={!!a.invalidatedAt}
                        expiredAction={getActionForConnect}
                      />
                    ))
                  ) : (
                    <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                      No apps are currently installed
                    </Typography>
                  )}
                </Box>
              </Box>

              {/* Company apps */}
              <Box sx={{ mt: spacing.m60 }}>
                <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, ...spacing.mb20 }}>
                  Company apps
                </Typography>

                <Box sx={{ display: 'flex', gap: spacing.g10, flexWrap: 'wrap' }}>
                  {filteredCompanyApps && filteredCompanyApps?.length > 0 ? (
                    filteredCompanyApps.map((a, idx) => (
                      <AppButton
                        key={`${a.stub}-${idx}`}
                        title={installedApps?.find((eachApp) => eachApp.stub === a.stub)?.name ?? ''}
                        handleClick={() =>
                          routerHistory.push(
                            generatePath(APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE, {
                              appStub: a.stub,
                            })
                          )
                        }
                        avatarImg={`/app-icons-v2/images/${a.stub}.png`}
                        stub={a.stub}
                        action={
                          requestsOnlyForUser && !requestsOnlyForUser.find((r) => r.requestInfo.appStub === a.stub) ? (
                            <Box sx={{ ...spacing.mt20 }}>
                              <Button sx={{ ...secondaryCTABtn }} fullWidth onClick={() => createAppRequest(a.stub)}>
                                Request
                              </Button>
                            </Box>
                          ) : (
                            <Box sx={{ ...spacing.mt20, textAlign: 'center' }}>
                              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>Requested</Typography>
                            </Box>
                          )
                        }
                        app={a}
                        expired={!!a.invalidatedAt}
                        expiredAction={getActionForConnect}
                      />
                    ))
                  ) : (
                    <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                      No apps are currently installed
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: { xs: '100%', sm: '100%', md: '40%', lg: '40%' } }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>New apps</Typography>
                <ButtonComponent
                  sizeVariant="small"
                  colorVariant="primary"
                  style={{ width: '80px' }}
                  onClick={() => routerHistory.push(APP_STORE_ROUTE)}
                >
                  All apps
                </ButtonComponent>
              </Box>

              <Box>
                {latestApps?.map((l, idx) => {
                  if (idx < 5) {
                    return (
                      <AppCard
                        key={`${l.name}-${idx}`}
                        title={l.name ?? ''}
                        subtitle={l.appStoreBlurb}
                        avatarImg={`/app-icons-v2/images/${l.stub}.png`}
                        stub={l.stub ?? ''}
                        showBorder={idx < 4}
                        action={() =>
                          routerHistory.push(
                            generatePath(APP_INTEGRATION_DETAILS_ABOUT_ROUTE, {
                              appStub: l.stub,
                            })
                          )
                        }
                        actionScope={['apps.connect', 'apps:all']}
                        context={getScopesContext({ userId: globalState.user.userId })}
                      />
                    );
                  } else return null;
                })}
              </Box>
            </Box>
          </Box>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
