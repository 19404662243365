import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type UserHeaderProps = {
  user: CachedUser;
  sx?: SxProps<Theme>;
};

export const UserHeader = ({ sx, user }: UserHeaderProps) => {
  return (
    <Stack direction={'row'} sx={sx}>
      <UserAvatar userId={user.userId} size="large" />
      <Stack gap={'5px'} sx={{ ml: '20px' }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>{user.displayName}</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{user.role?.jobTitle || ''}</Typography>
      </Stack>
    </Stack>
  );
};
