import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { BenefitPaymentActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-payment-action-item.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Salary } from '@/images/dashboard-icons/Salary.svg';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import {
  CustomBenefitPaymentsBigWidget,
  PendingBenefitPaymentTodo,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const PendingBenefitPaymentTodoComponent = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: CustomBenefitPaymentsBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<PendingBenefitPaymentTodo | undefined>(undefined);

  const getTitle = (name: string, benefitName: string, amount: number, currency: CurrencyShort): string => {
    const formattedAmount = formatMoney({ amount, currency });
    return polyglot.t('BenefitModule.benefitPaymentRequested', { name, benefitName, amount: formattedAmount });
  };

  return (
    <Box>
      {todo.customBenefitPayments.map((payment) => (
        <TodoCard
          key={`benefit-payment-${payment.id}`}
          icon={<Salary {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(payment.userName, payment.customBenefitName, payment.amount, payment.currency)}
            </Typography>
          }
          actionOnclick={() => setSelectedRow(payment)}
          showBorder={!setLastChild}
          userAvatar={
            payment.userId ? <UserAvatar key={payment.userId} userId={payment.userId} size="xxxsmall" /> : <></>
          }
        />
      ))}

      {selectedRow && (
        <BenefitPaymentActionItem
          pendingBenefitPaymentTodo={selectedRow}
          refresh={async () => {
            setSelectedRow(undefined);
            if (refreshTodos) await refreshTodos();
          }}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
