import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { REPORT_COMPANY_REPORTS_OVERVIEW, REPORTS_CREATE_ROUTE, REPORTS_UPDATE_ROUTE } from '@/lib/routes';
import { CreateReportPage } from '@/v2/feature/reports/features/create-report/create-report.page';
import { UpdateReportPage } from '@/v2/feature/reports/features/update-report/update-report.page';
import { ReportsPage } from '@/v2/feature/reports/reports.page';

export const ReportsRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return (
    <Switch>
      <RouteScopesControl scopes={['reports']} path={REPORTS_CREATE_ROUTE} context={scopesContext}>
        <CreateReportPage />
      </RouteScopesControl>

      <RouteScopesControl scopes={['reports']} path={REPORTS_UPDATE_ROUTE} context={scopesContext}>
        <UpdateReportPage />
      </RouteScopesControl>

      <RouteScopesControl scopes={['reports']} path={REPORT_COMPANY_REPORTS_OVERVIEW} context={scopesContext}>
        <ReportsPage />
      </RouteScopesControl>

      <Redirect to={REPORT_COMPANY_REPORTS_OVERVIEW} />
    </Switch>
  );
};
