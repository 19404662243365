import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell, IconEmptyCell } from '@v2/components/table/empty-cell.component';
import { sortDate, sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { ConfigurableDeviceData } from '@v2/feature/device/device.dto';
import { DevicePossessionType } from '@v2/feature/device/device.interface';
import { getDeviceOwnerByDevicePossessionTable, getModelImage } from '@v2/feature/device/device.util';
import {
  getDeviceStatus,
  getEnrolmentDeviceStatus,
} from '@v2/feature/device/features/devices-company/components/configurable-device-view.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

interface DevicesTableProps {
  readonly devices: ConfigurableDeviceData[];
  readonly handleRowClick: (row: Row<ConfigurableDeviceData>) => void;
  readonly hiddenColumns?: string[];
  readonly loading?: boolean;
  readonly fixedLastColumn?: boolean;
}

export const DevicesTable = ({
  devices,
  handleRowClick,
  hiddenColumns,
  loading,
  fixedLastColumn,
}: DevicesTableProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();

  const getUserName = useCallback(
    (assignedUserId: number): string | undefined => {
      const user = getCachedUserById(assignedUserId);
      return polyglot.t(user?.displayName ?? '');
    },
    [getCachedUserById, polyglot]
  );

  const tableColumns = useMemo<ColumnDef<ConfigurableDeviceData, ConfigurableDeviceData>[]>(
    () => [
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.device'),
        accessorFn: (row) => row,
        id: 'device',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.modelName),
        cell: (info: CellContext<ConfigurableDeviceData, ConfigurableDeviceData>) => {
          const configurableDevice = info.row.original;
          const modelName =
            configurableDevice.status === 'order'
              ? configurableDevice.order?.deviceModel?.modelName
              : configurableDevice.devicePossession.device?.modelName;
          const deviceType =
            configurableDevice.status === 'order'
              ? configurableDevice.order?.deviceModel?.type
              : configurableDevice.devicePossession.device?.type;

          const deviceId =
            configurableDevice.status === 'order'
              ? configurableDevice.order?.deviceId
              : configurableDevice.devicePossession.deviceId;

          return modelName && deviceType ? (
            <Box
              key={`model-cell-${deviceId}`}
              sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center', height: 'inherit' }}
            >
              <Box
                key={`model-img-cell-${deviceId}`}
                sx={{
                  objectFit: 'contain',
                }}
              >
                {getModelImage(deviceType, modelName, {
                  width: '30px',
                  height: '30px',
                })}
              </Box>

              <div key={`model-name-${deviceId}`}>{modelName}</div>
            </Box>
          ) : (
            <Box key={`${info.row.id}-${info.row.index}`} sx={{ display: 'flex', gap: 1 }}>
              <IconEmptyCell />
            </Box>
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.usedBy'),
        accessorFn: (row: ConfigurableDeviceData) => row,
        id: 'usedBy',
        enableSorting: false,
        cell: (info: CellContext<ConfigurableDeviceData, ConfigurableDeviceData>) => {
          const devicePossession = info.getValue().devicePossession;
          if (devicePossession.possessionType === DevicePossessionType.User && devicePossession.possessionId)
            return (
              <Box
                key={devicePossession.id}
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                <UserAvatar
                  key={`avatar-${devicePossession.id}`}
                  userId={devicePossession.possessionId}
                  size="xxsmall"
                />
                <div key={`user-${devicePossession.id}`}>{getUserName(devicePossession.possessionId)}</div>
              </Box>
            );

          return (
            <Box key={devicePossession.id}>
              {getDeviceOwnerByDevicePossessionTable(
                devicePossession,
                info.getValue().sites[devicePossession.possessionId],
                polyglot,
                getCachedUserById(devicePossession.possessionId)?.displayName ?? ''
              )}
            </Box>
          );
        },
        maxSize: 200,
        minSize: 140,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.deviceName'),
        accessorFn: (row) => row,
        id: 'deviceName',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.deviceName ?? null),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.deviceName ? <div>{devicePossession.device?.deviceName}</div> : <EmptyCell />;
        },
        maxSize: 180,
        minSize: 150,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.serialNumber'),
        accessorFn: (row) => row,
        id: 'serialNumber',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.serialNumber ?? null),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.serialNumber ? (
            <div>{devicePossession.device?.serialNumber}</div>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 150,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.model'),
        accessorFn: (row) => row,
        id: 'modelNumber',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.modelNumber ?? null),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.modelNumber ? (
            <div>{devicePossession.device?.modelNumber}</div>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 150,
        minSize: 100,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.lastActive'),
        accessorFn: (row) => row,
        id: 'lastActive',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.devicePossession.device?.lastCheckIn ?? undefined),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.lastCheckIn ? (
            <div>{formatDate(new Date(devicePossession.device?.lastCheckIn))}</div>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.enrollmentStatus ?? null),
        cell: ({
          row: {
            original: { status },
          },
        }) => (status ? getDeviceStatus(status) : <EmptyCell />),
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.enrolment'),
        accessorFn: (row) => row,
        id: 'enrolment',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.enrollmentStatus ?? null),
        cell: ({
          row: {
            original: { enrolment },
          },
        }) => (enrolment ? getEnrolmentDeviceStatus(enrolment) : <EmptyCell />),
        maxSize: 110,
        minSize: 100,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.storage'),
        accessorFn: (row) => row,
        id: 'storage',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.devicePossession.device?.storage ?? undefined),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.storage ? <div>{devicePossession.device?.storage} GB</div> : <EmptyCell />;
        },
        maxSize: 180,
        minSize: 150,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.notes'),
        accessorFn: (row) => row,
        id: 'customerNotes',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.customerNotes ?? null),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.customerNotes ? (
            <div>{devicePossession.device?.customerNotes}</div>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 150,
      },
      {
        header: () => polyglot.t('DeviceDirectoryPage.columns.osVersion'),
        accessorFn: (row) => row,
        id: 'osVersion',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.devicePossession.device?.osVersion ?? null),
        cell: ({
          row: {
            original: { devicePossession },
          },
        }) => {
          return devicePossession.device?.osVersion ? <div>{devicePossession.device?.osVersion}</div> : <EmptyCell />;
        },
        maxSize: 180,
        minSize: 150,
      },
    ],
    [polyglot, getCachedUserById, getUserName]
  );

  return (
    <Box sx={{ ...spacing.mt20 }}>
      <BasicTable<ConfigurableDeviceData>
        rowData={devices}
        columnData={tableColumns}
        loading={loading}
        initialSort={[{ id: 'enrolment', desc: true }]}
        rowClick={handleRowClick}
        hiddenColumns={hiddenColumns}
        fixedLastColumn={fixedLastColumn}
      />
    </Box>
  );
};
