import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { IconButton } from '@v2/components/forms/icon-button.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { AddApprovalStepDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/add-approval-step-drawer.component';
import { ApprovalRuleAPI } from '@v2/feature/approval-rule/approval-rule.api';
import { ApprovalRuleDto, CreateApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { ApprovalStep, DefaultApprovalRule } from '@v2/feature/approval-rule/approval-rule.interface';
import { getApprovalStepSchema, getStepDescription } from '@v2/feature/approval-rule/approval-rule.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateDefaultApprovalRule } from '@v2/infrastructure/i18n/translate.util';
import { borders } from '@v2/styles/borders.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

const getValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup
      .string()
      .notOneOf(Object.values(DefaultApprovalRule), polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    description: yup.string().notRequired(),
    approvalSteps: yup
      .array()
      .of(getApprovalStepSchema(polyglot))
      .required(polyglot.t('ValidationMessages.requiredField')),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly approvalRule: ApprovalRuleDto | null;
  readonly afterClose?: () => void;
  readonly refresh: () => Promise<void>;
}

export const ApprovalRuleDrawer = ({ isOpen, setIsOpen, approvalRule, afterClose, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      {approvalRule?.isDefault ? (
        <DefaultApprovalRuleDrawerContent approvalRule={approvalRule} />
      ) : (
        <ApprovalRuleDrawerContent
          approvalRule={approvalRule}
          refresh={refresh}
          setIsOpen={setIsOpen}
          afterClose={afterClose}
        />
      )}
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly approvalRule: ApprovalRuleDto | null;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly afterClose?: () => void;
}

const ApprovalRuleDrawerContent = ({
  approvalRule,
  refresh,
  setIsOpen,
  afterClose,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [selectedStep, setSelectedStep] = useState<ApprovalStep | null>(null);
  const [selectedStepIndex, setSelectedStepIndex] = useState<number | null>(null);

  const [isAddStepOpen, setIsAddStepOpen] = useState(false);

  const onSubmit = useCallback(
    async (values: CreateApprovalRuleDto) => {
      try {
        const ruleData: CreateApprovalRuleDto = {
          name: values.name,
          description: values.description,
          approvalSteps: values.approvalSteps,
        };
        setLoading(true);
        if (approvalRule) await ApprovalRuleAPI.updateApprovalRule(approvalRule.id, ruleData);
        else await ApprovalRuleAPI.addApprovalRule(ruleData);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AttendanceScheduleEditSettingsDrawer.errorMessages.update', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [approvalRule, showMessage, refresh, setIsOpen, polyglot]
  );

  const formik = useFormik<CreateApprovalRuleDto>({
    initialValues: {
      name: approvalRule?.name ?? '',
      description: approvalRule?.description ?? '',
      approvalSteps: approvalRule?.approvalSteps ?? [],
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit,
  });

  const deleteApprovalRule = useCallback(async () => {
    if (!approvalRule) return;
    try {
      await ApprovalRuleAPI.deleteApprovalRule(approvalRule.id);
      await refresh();
      setIsOpen(false);
      if (afterClose) afterClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [refresh, showMessage, polyglot, approvalRule, afterClose, setIsOpen]);

  const deleteApprovalStep = (stepIndex: number) => {
    formik.setFieldValue(
      'approvalSteps',
      formik.values.approvalSteps.filter((_, index) => index !== stepIndex)
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="title2">
            {approvalRule ? polyglot.t('ApprovalRuleModule.approvalRule') : polyglot.t('ApprovalRuleModule.newRule')}
          </Typography>
          {approvalRule && (
            <IconButton key="delete" sizeVariant="small" colorVariant="secondary" onClick={deleteApprovalRule}>
              <Trash {...iconSize} />
            </IconButton>
          )}
        </Box>

        <TextfieldComponent
          label={polyglot.t('General.name')}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && !!formik.errors.name}
          helperText={((formik.touched.name && formik.errors.name) as string) ?? ''}
        />

        <TextfieldComponent
          label={polyglot.t('General.description')}
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && !!formik.errors.description}
          helperText={((formik.touched.description && formik.errors.description) as string) ?? ''}
        />

        <Box>
          <Typography variant="title4" sx={{ mb: '10px' }}>
            Approval steps
          </Typography>
          <Typography variant="caption">Request will only be approved if all set steps are satisfied.</Typography>
        </Box>

        {formik.values.approvalSteps.map((step, index) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: borders.background,
              pb: '10px',
            }}
          >
            <Box
              sx={{ width: '75%', cursor: 'pointer' }}
              onClick={() => {
                setSelectedStep(step);
                setSelectedStepIndex(index);
                setIsAddStepOpen(true);
              }}
            >
              <Typography variant="captionSmall" color="Grey" sx={{ mb: '5px' }}>
                {polyglot.t('ApprovalRuleModule.stepNo', { stepNo: index + 1 })}
              </Typography>
              <Typography variant="caption">{getStepDescription(step, getCachedUserById, polyglot)}</Typography>
            </Box>
            <Box sx={{ width: '20%', display: 'flex', justifyContent: 'end' }}>
              <IconButton sizeVariant="small" colorVariant="secondary" onClick={() => deleteApprovalStep(index)}>
                <Trash {...iconSize} />
              </IconButton>
            </Box>
          </Box>
        ))}

        <Box>
          <ButtonComponent
            sizeVariant="filter"
            colorVariant="secondary"
            onClick={() => {
              setSelectedStepIndex(null);
              setSelectedStep(null);
              setIsAddStepOpen(true);
            }}
          >
            {polyglot.t('ApproverSelectComponent.addStep')}
          </ButtonComponent>

          <AddApprovalStepDrawer
            isOpen={isAddStepOpen}
            setIsOpen={setIsAddStepOpen}
            addStep={(newStep: ApprovalStep, index: number | null) => {
              const updatedSteps = [...formik.values.approvalSteps];
              if (index === null) updatedSteps.push(newStep);
              else updatedSteps[index] = newStep;

              formik.setFieldValue('approvalSteps', updatedSteps);
            }}
            existingSteps={formik.values.approvalSteps}
            step={selectedStep}
            stepIndex={selectedStepIndex}
            afterClose={() => {
              setSelectedStepIndex(null);
              setSelectedStep(null);
            }}
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="primary" loading={loading} fullWidth>
            {polyglot.t('General.save')}
          </ButtonComponent>
        </Box>
      </Form>
    </FormikProvider>
  );
};

const DefaultApprovalRuleDrawerContent = ({ approvalRule }: { approvalRule: ApprovalRuleDto }): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{approvalRule.name}</Typography>
      <Typography variant="caption">
        {approvalRule.description ?? translateDefaultApprovalRule(approvalRule.name, polyglot)}
      </Typography>
    </Box>
  );
};
