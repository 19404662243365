import { Dispatch, SetStateAction, useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { FilledField } from '@/v2/components/forms/filled-field.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  PaymentCategory,
  PaymentCategoryType,
  PaymentDto,
  PaymentType,
  SinglePaymentRequestDto,
} from '@/v2/feature/payments/payments.dto';
import {
  buttonBoxSx,
  fieldSx,
  titleSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@/v2/styles/spacing.styles';

export interface LocalCreatePaymentFormValues
  extends Pick<PaymentDto, 'userId' | 'amount' | 'currency' | 'reference' | 'category' | 'dueDate' | 'type'> {
  typesAndCategory?: string;
}

const validationSchema = Yup.object().shape({
  userId: Yup.number().nullable(true).required('Beneficiary is a required field'),
  amount: Yup.number().positive().required(),
  reference: Yup.string()
    .max(20, 'reference can not be greater than 20 characters')
    .matches(/^[a-zA-Z\d]*$/, 'Use alphanumeric characters only.')
    .required(),
  category: Yup.string().required(),
  dueDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
});

const typesAndCategory: OptionObject[] = [
  { label: 'Employee expense', value: 'Employee expense' },
  { label: 'Employee invoice', value: 'Employee invoice' },
  { label: 'Company taxes', value: 'Company taxes' },
];

interface CreatePaymentFormProps {
  readonly selectedPayment: PaymentDto | undefined;
  onSubmit: (val: SinglePaymentRequestDto) => Promise<void>;
  readonly setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export function PaymentFormModal({ onSubmit, selectedPayment, setIsModalOpen }: CreatePaymentFormProps) {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (values: LocalCreatePaymentFormValues) => {
    try {
      setSubmitting(true);
      const { userId, reference, amount, dueDate, typesAndCategory, currency } = values;
      const payload: SinglePaymentRequestDto = {
        userId: typesAndCategory === PaymentCategoryType.CompanyTaxes ? null : userId,
        reference,
        amount,
        currency,
        category:
          typesAndCategory === PaymentCategoryType.CompanyTaxes
            ? PaymentCategory.Payroll
            : typesAndCategory === PaymentCategoryType.EmployeeExpense
            ? PaymentCategory.Expense
            : PaymentCategory.Invoice,
        type: typesAndCategory === PaymentCategoryType.CompanyTaxes ? PaymentType.Taxes : PaymentType.Employee,
        dueDate: dueDate,
      };
      await onSubmit(payload);
    } catch (e) {
      console.error('CreatePaymentForm handleSubmit', e);
    } finally {
      setSubmitting(false);
    }
  };

  const getTypeAndCategory = (payment: PaymentDto | undefined) => {
    if (payment && payment.type === PaymentType.Employee) {
      return payment.category === PaymentCategory.Expense || payment.category === PaymentCategory.Payroll
        ? PaymentCategoryType.EmployeeExpense
        : PaymentCategoryType.EmployeeInvoice;
    }

    return PaymentCategoryType.CompanyTaxes;
  };

  const formik = useFormik<LocalCreatePaymentFormValues>({
    initialValues: selectedPayment
      ? ({
          ...selectedPayment,
          typesAndCategory: getTypeAndCategory(selectedPayment),
          userId:
            selectedPayment.category === PaymentCategory.Payroll && selectedPayment.type === PaymentType.Taxes
              ? 0
              : selectedPayment.userId,
        } as LocalCreatePaymentFormValues)
      : {
          userId: undefined,
          amount: 0,
          reference: '',
          category: PaymentCategory.Expense,
          type: PaymentType.Employee,
          dueDate: '',
          typesAndCategory: PaymentCategoryType.EmployeeExpense,
          currency: 'GBP',
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <Box>
          <Typography sx={titleSx}>{selectedPayment ? 'Edit payment' : 'Add payment'}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <SelectComponent
                name="typesAndCategory"
                label="Payment type"
                options={typesAndCategory}
                value={formik.values.typesAndCategory}
                compareValue={formik.values.typesAndCategory}
                error={!!formik.errors.typesAndCategory && formik.touched.typesAndCategory}
                onChange={(e) => {
                  formik.setFieldValue('typesAndCategory', e.target.value);
                  formik.setFieldValue('userId', 0);
                }}
                helperText={formik.errors.typesAndCategory && formik.touched.typesAndCategory}
              />
            </Box>

            <Box sx={fieldSx}>
              {formik.values.typesAndCategory !== PaymentCategoryType.CompanyTaxes ? (
                <SingleUserSelect
                  name="userId"
                  options="company"
                  onChange={(_, x) => formik.setFieldValue('userId', x?.value ?? null)}
                  value={formik.values.userId}
                  label="Beneficiary"
                  error={Boolean(formik.errors.userId) && formik.touched.userId}
                  helperText={Boolean(formik.errors.userId) && formik.touched.userId ? formik.errors.userId : ''}
                />
              ) : (
                <FilledField label="Beneficiary" value="HMRC" />
              )}
            </Box>

            <Box sx={fieldSx}>
              <TextfieldComponent
                name="amount"
                label="Amount"
                value={formik.values.amount}
                type="number"
                multiline={true}
                onChange={formik.handleChange}
                error={formik.touched.amount && !!formik.errors.amount}
                helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
                clearText={() => formik.setFieldValue('amount', '')}
              />
            </Box>

            <Box sx={fieldSx}>
              <TextfieldComponent
                name="reference"
                label="Bank payment reference"
                value={formik.values.reference}
                type="text"
                multiline={true}
                onChange={formik.handleChange}
                error={formik.touched.reference && !!formik.errors.reference}
                helperText={(formik.touched.reference && formik.errors.reference) ?? ' '}
                clearText={() => formik.setFieldValue('reference', '')}
              />
            </Box>

            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.dueDate ?? dayjs()}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      formik.setFieldValue('dueDate', value);
                    }
                  }}
                  name="dueDate"
                  label="Due date"
                  error={!!formik.errors.dueDate && Boolean(formik.touched.dueDate)}
                  helperText={formik.errors.dueDate && Boolean(formik.touched.dueDate)}
                  shortcuts={true}
                  minDate={new Date()}
                />
              </FormControl>
            </Box>
          </Box>

          <Box sx={buttonBoxSx}>
            <ButtonComponent
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </ButtonComponent>
            <LoaderButton
              name={selectedPayment ? 'Save' : 'Add'}
              loading={submitting}
              fullWidth={true}
              colorVariant="primary"
              sizeVariant="medium"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
