import React from 'react';

import { styled, Tab, Tabs, TabsProps } from '@mui/material';

import { PageConfigTab } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  height: 40,
  minHeight: 40,
  margin: '0px 0px !important',
  ...spacing.mx40,
  backgroundColor: themeColors.white,
  '& .MuiTabs-flexContainer': {
    height: 40,
  },
  '& .MuiTabs-indicator': {
    display: 'inherit',
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.DarkGrey,
    width: 'inherit',
    height: '3px',
  },
  '& .MuiTabs-scroller': { overflow: 'visible !important', height: 40 },
  borderBottom: borders.middle,
}));

export const StyledTab = styled(Tab)<TabsProps>(() => ({
  textTransform: 'inherit',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  height: 40,
  padding: '8px 1px',
  marginRight: '18px',
  minWidth: 'fit-content',
  minHeight: 40,
  borderRadius: 6,
  display: 'flex',
  '& > div': {
    marginBottom: '20px',
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
  '&:hover': {
    ...themeFonts.caption,
    color: themeColors.Grey,
  },
}));

export const StyledTabsComponent = ({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  readonly tabs: readonly PageConfigTab[];
  readonly currentTab: string;
  readonly setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <StyledTabs
      value={currentTab}
      scrollButtons="auto"
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={(_, value) => {
        setCurrentTab(value);
      }}
    >
      {tabs.map((tab) => (
        <StyledTab disableRipple label={tab.label} value={tab.value} key={tab.value} onClick={tab.onClick} />
      ))}
    </StyledTabs>
  );
};
