import React, { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/system';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';
import { AppIntegrationUserDto } from '@/v2/feature/app-integration/app-integration.dto';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

interface AppAccessDetailDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly appUserDetail: AppIntegrationUserDto;
  readonly appName: string;
  appStatusValue: React.JSX.Element | React.JSX.Element[];
  appActionButtons: React.JSX.Element;
  scheduledActionDate?: string;
}

interface AppAccessDetailDrawerContentProps {
  readonly appUserDetail: AppIntegrationUserDto;
  readonly appName: string;
  appStatusValue: JSX.Element | JSX.Element[];
  appActionButtons: JSX.Element;
  scheduledActionDate?: string;
}

export const AppAccessDetailDrawer = ({
  isOpen,
  setIsOpen,
  appUserDetail,
  appName,
  appStatusValue,
  scheduledActionDate,
  appActionButtons,
}: AppAccessDetailDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AppAccessDetailDrawerContent
        appUserDetail={appUserDetail}
        appName={appName}
        appStatusValue={appStatusValue}
        scheduledActionDate={scheduledActionDate}
        appActionButtons={appActionButtons}
      />
    </DrawerModal>
  );
};

export const AppAccessDetailDrawerContent = ({
  appUserDetail,
  appName,
  appStatusValue,
  scheduledActionDate,
  appActionButtons,
}: AppAccessDetailDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const cachedUser = appUserDetail && appUserDetail.userId ? getCachedUserById(+appUserDetail.userId) : undefined;
  let emailForDisplay = appUserDetail?.emails?.find(
    (eachEmail) => eachEmail.matched || eachEmail.assigned || eachEmail.primary
  );

  return (
    <Box>
      <Typography variant="title2">{appName}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', ...spacing.gap20, marginTop: spacing.m30 }}>
        {appUserDetail && (
          <DrawerViewerItem
            title={polyglot.t('AppAccessDetailsDrawer.employee')}
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                {appUserDetail.userId && <UserAvatar userId={+appUserDetail.userId} size="xxsmall" />}
                <Typography variant="title4">
                  {polyglot.t(
                    cachedUser
                      ? cachedUser.displayName || `${cachedUser.firstName} ${cachedUser.lastName} `
                      : `${appUserDetail.displayName}`
                  )}
                </Typography>
              </Box>
            }
          />
        )}
        {emailForDisplay && (
          <DrawerViewerItem
            title={polyglot.t('AppAccessDetailsDrawer.login')}
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                <Typography variant="title4">{emailForDisplay?.email}</Typography>
              </Box>
            }
          />
        )}
        {appStatusValue && (
          <DrawerViewerItem
            title={polyglot.t('AppAccessDetailsDrawer.status')}
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                <Typography variant="title4">{scheduledActionDate ?? appStatusValue}</Typography>
              </Box>
            }
          />
        )}
      </Box>
      <Box
        sx={{
          mt: spacing.mt20,
          transition: 'all 0.3s ease',
          transitionProperty: 'margin-top',
        }}
      >
        {appActionButtons}
      </Box>
    </Box>
  );
};
