import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReachType, ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ParticipantsAddRevieweeModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach,
}: {
  reviewCycle: ReviewCycle | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
  reach: ReachType;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ParticipantsAddRevieweeContent reviewCycle={reviewCycle} onClose={onClose} refresh={refresh} reach={reach} />
    </DrawerModal>
  );
};

const useRevieweeSelectForm = (
  reviewCycle: ReviewCycle | undefined,
  onClose: () => void,
  refresh: () => Promise<void>
) => {
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      revieweeId: [],
    },
    validationSchema: yup.object({
      revieweeId: yup.array().required('Required field').min(1, 'Please choose at least user'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!reviewCycle) {
          throw new Error('Review cycle is not defined.');
        }

        const isPreOngoingState = [CycleState.Draft, CycleState.Scheduled].includes(reviewCycle.state);

        if (isPreOngoingState) {
          await ReviewCycleAPI.addRevieweesToPreOngoing(reviewCycle.id, values.revieweeId);
        }
        if (reviewCycle.state === CycleState.Ongoing) {
          await ReviewCycleAPI.addRevieweesToOngoing(reviewCycle.id, values.revieweeId);
        }
        showMessage('Successfully added new participants', 'success');
        await refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};

const ParticipantsAddRevieweeContent = ({
  reviewCycle,
  onClose,
  refresh,
  reach,
}: {
  reviewCycle: ReviewCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  reach: ReachType;
}) => {
  const { polyglot } = usePolyglot();
  const formik = useRevieweeSelectForm(reviewCycle, onClose, refresh);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Add participants</Typography>

        {reviewCycle?.state === CycleState.Ongoing && (
          <Typography variant="caption" color="Grey">
            Newly added participants will receive an invite.
          </Typography>
        )}

        <UserSelect
          selectedSpecificButtonLabel="Add participants"
          value={formik.values?.revieweeId ?? []}
          allInvalidUserIds={reviewCycle?.revieweeId ?? []}
          onChange={(userIds: number[]) => {
            formik.setFieldValue('revieweeId', userIds);
          }}
          fieldSx={{ ...spacing.mb20 }}
          excludeEveryone={true}
          excludeCustomRule={true}
          reach={reach}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
