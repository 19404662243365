import { OffboardingState } from '@v2/feature/offboarding/offboarding-process/offboarding-process.page';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

import { InstalledAppDto, UserAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import {
  OffboardingProgressPageResult,
  UserOffboardingDues,
  UserOffboardingParameters,
} from '@/v2/feature/offboarding/offboarding.interface';

export class OffboardingAPI {
  static async getOffboardingListing(param?: {
    page?: string;
    pageSize?: string;
    searchQuery?: string;
    filter?: string;
  }): Promise<OffboardingProgressPageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/offboarding?${searchParams.toString()}`)).data;
  }

  static async getOffboardingDues(userId: number): Promise<UserOffboardingDues> {
    return (await axios.get(`/apiv2/users/${userId}/offboarding/dues`)).data;
  }

  static async postUserOffboard(userId: number, offboard: UserOffboardingParameters): Promise<void> {
    return (await axios.post(`/apiv2/users/${userId}/offboarding`, offboard)).data;
  }

  static async getOffboardingCompanyApps(): Promise<InstalledAppDto[]> {
    return (await axios.get(`/apiv2/offboarding/apps`)).data;
  }

  static async getOffboardingAppsForUser(userId: number): Promise<UserAppDto[]> {
    return (await axios.get(`/apiv2/offboarding/apps/${userId}`)).data;
  }

  static async getOffboardingChangeReasons(): Promise<string[]> {
    return (await axios.get('/apiv2/offboarding/change-reasons')).data;
  }
}

export class OffboardingEndpoints {
  static getOffboardingState(userId: number): Endpoint<OffboardingState> {
    return {
      url: `/apiv2/users/${userId}/offboarding/state`,
    };
  }
}
