import axios from 'axios';

import {
  ReviewQuestionBank,
  ReviewQuestionBankRemoveIds,
  ReviewQuestionBankUpsert,
} from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewQuestionBankAPI {
  static async createReviewQuestionInBank(questionBody: ReviewQuestionBankUpsert): Promise<ReviewQuestionBank> {
    return (await axios.post('/apiv2/review-question-bank', questionBody)).data;
  }

  static async removeQuestionfromTemplate(ids: ReviewQuestionBankRemoveIds): Promise<void> {
    await axios.post('/apiv2/review-question-bank/remove-questions', ids);
  }

  static async updateReviewQuestionInBank(questionBody: ReviewQuestionBankUpsert): Promise<void> {
    await axios.patch('/apiv2/review-question-bank', questionBody);
  }
}

export class ReviewQuestionBankEndpoints {
  static getReviewQuestionFromBank(search?: string, filterString?: string): Endpoint<ReviewQuestionBank[]> {
    const urlParams = new URLSearchParams({
      ...(search && { search }),
      ...(filterString && { filterString }),
    });
    return {
      url: `/apiv2/review-question-bank?${urlParams.toString()}`,
    };
  }
}
