import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NewWindow } from '@/images/new-theme-icon/NewWindow.svg';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { TIME_ME_CALENDAR_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { AbsenceAPI } from '@/v2/feature/absence/absence.api';
import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import { CompanyEvent } from '@/v2/feature/calendar/calendar.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const getOverlapText = (
  overlap: AbsenceDto,
  userIds: number[],
  isViewMode: boolean,
  preventOwnOverlaps: boolean,
  polyglot: Polyglot
): React.JSX.Element | null => {
  const startDate = getDateString(overlap.start, false, 'd MMM');
  const endDate = overlap.end ? ` - ${getDateString(overlap.end, false, 'd MMM')} ` : '';

  return overlap.user ? (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <UserCell userId={overlap.user?.userId} nameSx={{ ...themeFonts.caption, color: themeColors.DarkGrey }} />
        {preventOwnOverlaps && !isViewMode && userIds.includes(overlap.userId) && (
          <StyledTooltip title={polyglot.t('AbsenceOverlapDisplay.requestOmitted')} placement="top">
            <Mistake width={15} height={15} />
          </StyledTooltip>
        )}
      </Box>
      <Typography sx={themeFonts.caption}>
        {startDate} {endDate && ` ${endDate}`}
      </Typography>
    </Box>
  ) : null;
};

const getEventOverlapText = (eventOverlap: CompanyEvent): React.JSX.Element => {
  const startDate = getDateString(eventOverlap.startDate, false, 'd MMM');
  const endDate = eventOverlap.endDate ? ` - ${getDateString(eventOverlap.endDate, false, 'd MMM')} ` : '';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
        <Box
          sx={{
            bgcolor: eventOverlap.color ?? themeColors.Background,
            width: '20px',
            height: '20px',
            borderRadius: '100%',
          }}
        />
        <Typography sx={themeFonts.caption}>{eventOverlap.name}</Typography>
      </Box>
      <Typography sx={themeFonts.caption}>
        {startDate} {endDate && ` ${endDate}`}
      </Typography>
    </Box>
  );
};

const sortOverlapAbsences = (userIds: number[]): ((a: AbsenceDto, b: AbsenceDto) => number) => {
  const userIdsSet = new Set(userIds);

  return (a: AbsenceDto, b: AbsenceDto) => {
    const aHas = userIdsSet.has(a.userId);
    const bHas = userIdsSet.has(b.userId);

    if (aHas === bHas) return 0;

    return aHas ? -1 : 1;
  };
};

interface AbsenceOverlapDisplayProps {
  readonly userIds: number[];
  readonly absenceStart: string;
  readonly absenceEnd: string | null;
  readonly absenceId: number | null;
  readonly showCalendarLink: boolean;
  readonly isViewMode: boolean;
}

export const AbsenceOverlapDisplay = ({
  userIds,
  absenceStart,
  absenceEnd,
  absenceId,
  showCalendarLink,
  isViewMode = false,
}: AbsenceOverlapDisplayProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [overlaps, setOverlaps] = useState<AbsenceDto[]>([]);
  const [eventsOverlap, setEventsOverlap] = useState<CompanyEvent[]>([]);

  const [showOverlapText, setShowOverlapText] = useState<boolean>(false);
  const [preventOwnOverlaps, setPreventOwnOverlaps] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        if (!userIds || userIds.length === 0) return;
        const overlapResult = await AbsenceAPI.verifyCompanyOverlaps({
          absenceStart,
          absenceEnd,
          absenceId,
          userIds,
        });

        if (
          overlapResult.isOverlapping &&
          (overlapResult.absences.map((a) => a.absenceId !== absenceId).length > 0 || overlapResult.events.length > 0)
        ) {
          const overlapsAbsences = overlapResult.absences;
          overlapsAbsences.sort(sortOverlapAbsences(userIds));
          setOverlaps(overlapResult.absences);
          setEventsOverlap(overlapResult.events);
          setShowOverlapText(true);
          setPreventOwnOverlaps(overlapResult.preventOwnOverlaps);
        } else {
          setShowOverlapText(false);
          setOverlaps([]);
          setEventsOverlap([]);
        }
      } catch (error) {
        console.error(polyglot.t('AbsenceOverlapDisplay.errorMessages'), error.message);
      }
    })();
  }, [polyglot, userIds, absenceStart, absenceEnd, absenceId]);

  const filteredOverlaps = overlaps.filter((ol) => absenceId !== ol.absenceId);
  return filteredOverlaps.length + eventsOverlap.length === 0 ? null : (
    <Box
      sx={{
        transition: 'all 0.3s ease',
        transitionProperty: 'opacity, margin-bottom',
        opacity: showOverlapText ? 1 : 0,
        marginBottom: showOverlapText ? spacing.m10 : 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center', md: 'center', lg: 'center' },
          mb: spacing.m10,
        }}
      >
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
          {polyglot.t('AbsenceOverlapDisplay.overlaps')}
        </Typography>
        {showCalendarLink && (
          <ButtonComponent
            sizeVariant="link"
            colorVariant="text"
            onClick={() => routerHistory.push(TIME_ME_CALENDAR_ROUTE)}
            endIcon={<NewWindow {...iconSize} />}
          >
            {polyglot.t('AbsenceOverlapDisplay.openCalendar')}
          </ButtonComponent>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        {filteredOverlaps.map((ol) => getOverlapText(ol, userIds, isViewMode, preventOwnOverlaps, polyglot))}
        {eventsOverlap.map((ov) => getEventOverlapText(ov))}
      </Box>
    </Box>
  );
};
