import styled from '@emotion/styled';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const tdAndThPadding = '0 16px';

export const iconSize = { width: 14, height: 14 } as const;
export const actionIconSize = { width: 14, height: 14 } as const;

//the table element
export const Table = styled('table')(() => ({
  width: '100%',
  borderCollapse: 'collapse',
  borderSpacing: '2px',
}));

//the thead element
export const TableHead = styled('thead')(() => ({
  position: 'sticky',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  ':hover': {
    backgroundColor: 'transparent',
  },
}));

//the tr element for use within the TableHead
export const TableHeadRow = styled('tr')(() => ({
  // borderBottom: `6px solid transparent`,
  backgroundColor: themeColors.white,
}));

//a th element, with prebuilt functionality to support sorting
export const TableHeading = styled('th')(
  ({
    maxWidth,
    minWidth,
    fixedFirstColumn,
    fixedLastColumn,
    externalTdAndThPadding,
  }: {
    maxWidth: number;
    minWidth?: number;
    fixedFirstColumn: boolean;
    fixedLastColumn: boolean;
    externalTdAndThPadding?: string;
  }) => ({
    ...themeFonts.captionSmall,
    color: themeColors.Grey,
    height: '16px',
    paddingBottom: spacing.p5,
    backgroundColor: 'inherit',
    textAlign: 'left',
    maxWidth: maxWidth,
    minWidth: minWidth,
    padding: externalTdAndThPadding ?? tdAndThPadding,
    ...(fixedFirstColumn && {
      position: 'sticky',
      left: 0,
      zIndex: 1,
    }),
    ...(fixedLastColumn && {
      position: 'sticky',
      right: 0,
      zIndex: 1,
    }),
  })
);

//a static th element
export const Th = styled('th')(() => ({
  ...themeFonts.captionSmall,
  color: themeColors.Grey,
  height: '16px',
  paddingBottom: spacing.p16,
  textAlign: 'left',
}));

//the tbody element
export const TableBody = styled('tbody')(() => ({
  backgroundColor: themeColors.white,
}));

//the td element
export const TableData = styled('td')(
  ({
    maxWidth,
    cellStyle,
    meta,
    fixedFirstColumn,
    fixedLastColumn,
    minWidth,
    externalTdAndThPadding,
  }: {
    maxWidth: number;
    cellStyle?: React.CSSProperties;
    meta?: string | undefined;
    fixedFirstColumn?: boolean;
    fixedLastColumn?: boolean;
    minWidth?: number;
    externalTdAndThPadding?: string;
  }) => ({
    position: 'relative',
    ...themeFonts.caption,
    color: themeColors.DarkGrey,
    backgroundColor: 'inherit',
    textAlign: 'left',
    height: '39px', // = 40px - 1px row dividing line
    maxWidth: maxWidth,
    minWidth: minWidth,
    padding: externalTdAndThPadding ?? tdAndThPadding,
    ...cellStyle,
    '& div:last-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    ...(meta === 'onclick' && {
      ':hover': {
        color: themeColors.ZeltYellow,
      },
    }),

    ...(fixedFirstColumn && {
      position: 'sticky',
      left: 0,
      zIndex: 1,
    }),

    ...(fixedLastColumn && {
      position: 'sticky',
      right: 0,
      zIndex: 1,
    }),
  })
);

//the tr element
export const TableRow = styled('tr')(() => ({
  // borderBottom: borders.background,
  backgroundColor: themeColors.white,
  '.show-on-hover': { display: 'none' },
  ':hover': {
    backgroundColor: themeColors.TableHover,
    '.show-on-hover': { display: 'block' },
  },
}));

export const TableFootRow = styled('tr')(() => ({
  backgroundColor: themeColors.white,
}));
