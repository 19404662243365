import { useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { RequestForm } from '@v2/feature/requests/features/request-forms/request-forms.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { RejectDrawer } from '@/v2/components/reject-drawer.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';

type ApprovalButtonSetProps = {
  requestForm: RequestForm;
  onReject?: (notes?: string) => Promise<void>;
  onDelete?: () => Promise<void>;
  onApprove: () => void;
  loading?: boolean;
  sx?: SxProps<Theme>;
};

export const ApprovalButtonSet = ({
  requestForm,
  onReject,
  onDelete,
  onApprove,
  loading,
  sx,
}: ApprovalButtonSetProps) => {
  const { polyglot } = usePolyglot();

  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState<boolean>(false);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {((onReject && requestForm.canReject) || (onDelete && requestForm.canDelete)) && (
        <Box sx={{ width: '100%' }}>
          <StyledMenuComponent
            options={[
              ...(onReject && requestForm.canReject
                ? [
                    {
                      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
                      handler: () => setIsRejectDrawerOpen(true),
                      label: polyglot.t('AbsenceViewerApprovalButtons.reject'),
                      disabled: false,
                    },
                  ]
                : []),
              ...(onDelete && requestForm.canDelete
                ? [
                    {
                      icon: <Trash {...iconSize} />,
                      handler: () => onDelete(),
                      label: polyglot.t('General.delete'),
                      disabled: false,
                    },
                  ]
                : []),
            ]}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'medium',
              title: polyglot.t('AbsenceViewerApprovalButtons.actions'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          />
        </Box>
      )}
      {requestForm.canApprove && (
        <LoaderButton
          name={polyglot.t('AbsenceViewerApprovalButtons.approve')}
          key="approve"
          fullWidth
          loading={loading}
          onClick={() => onApprove()}
          sizeVariant="medium"
          colorVariant="primary"
        />
      )}

      {onReject && requestForm.canReject && (
        <RejectDrawer isOpen={isRejectDrawerOpen} setIsOpen={setIsRejectDrawerOpen} onReject={onReject} />
      )}
    </Box>
  );
};
