import { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { APPS_COMPANY_OVERVIEW_ROUTE, APPS_REQUESTS_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getDetailByDomain } from '@/v2/feature/dashboard/dashboard.util';
import { AppRequestTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

export const AppActionItem = ({
  appActionRow,
  afterClose,
}: {
  appActionRow: AppRequestTodo;
  afterClose: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const [isOpen, setIsOpen] = useState<boolean>(Boolean(appActionRow));
  const { getCachedUserById } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const routerHistory = useHistory();

  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId: currentUser.userId });
  const hasAppsAll = hasScopes(['apps:all'], scopesContext);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      <div>
        <Typography variant="title2">{appActionRow?.appName ?? 'Apps'}</Typography>
        <Typography variant="caption" sx={{ marginTop: spacing.m10 }}>
          {getDetailByDomain(appActionRow, getCachedUserById, currentUser, polyglot)}
        </Typography>

        <Box sx={spacing.mt20}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
            onClick={() => routerHistory.push(hasAppsAll ? APPS_REQUESTS_ROUTE : APPS_COMPANY_OVERVIEW_ROUTE)}
          >
            {polyglot.t('AppActionItem.view')}
          </ButtonComponent>
        </Box>
      </div>
    </DrawerModal>
  );
};
