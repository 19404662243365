import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitRequestType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { isRecurringBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { UserCustomBenefitRequestDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateUserInsuranceDependants } from '@v2/infrastructure/i18n/translate.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly request: UserCustomBenefitRequestDto;
  readonly refresh: () => Promise<void>;
  readonly onClose?: () => void;
}

export const BenefitRequestViewDrawer = ({ isOpen, setIsOpen, request, refresh, onClose }: DrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(
    request.customBenefit?.type && isRecurringBenefit(request.customBenefit.type)
      ? UserEndpoints.getUserFamilyMembers(request.userId)
      : null,
    { suspense: false }
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <BenefitRequestViewDrawerContent
        userFamilyMembers={userFamilyMembers ?? []}
        request={request}
        setIsOpen={setIsOpen}
        refresh={refresh}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly request: UserCustomBenefitRequestDto;
  readonly refresh: () => Promise<void>;
  readonly userFamilyMembers: UserFamilyMemberDto[];
}

export const BenefitRequestViewDrawerContent = ({
  setIsOpen,
  request,
  userFamilyMembers,
  refresh,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();

  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const canApproveOrReject = !!request.approverIds && request.approverIds.includes(state.user.userId);

  const dependantsList = useMemo(() => {
    if (!request?.dependants || !request?.dependantsList) return '';
    return (
      userFamilyMembers
        .filter((m) => request.dependantsList!.includes(m.id))
        .map((m) => m.name)
        .join(', ') ?? ''
    );
  }, [userFamilyMembers, request]);

  const approveRequest = useCallback(async () => {
    try {
      setIsApproving(true);
      await CustomBenefitAPI.approveUserBenefitRequest(request.userId, request.customBenefitId, request.id);
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsApproving(false);
  }, [polyglot, request, showMessage, setIsOpen, refresh]);

  const rejectRequest = useCallback(async () => {
    try {
      setIsRejecting(true);
      await CustomBenefitAPI.rejectUserBenefitRequest(request.userId, request.customBenefitId, request.id);
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsRejecting(false);
  }, [polyglot, request, showMessage, setIsOpen, refresh]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">
        {request.type === UserCustomBenefitRequestType.RecurringChange
          ? polyglot.t('BenefitModule.changeRequested')
          : request.type === UserCustomBenefitRequestType.LoanRequest
          ? polyglot.t('BenefitModule.loanRequested')
          : polyglot.t('BenefitModule.optOutRequested')}
      </Typography>

      <Box>
        <Typography variant="captionSmall" color="Grey">
          {polyglot.t('General.employee')}
        </Typography>
        <UserCell userId={request.userId} />
      </Box>

      {request.type === UserCustomBenefitRequestType.RecurringChange ? (
        <Box>
          <DetailsLine
            label={polyglot.t('General.date')}
            value={new LocalDate(request.effectiveDate).toLocaleDateString()}
          />
          <DetailsLine
            label={polyglot.t('BenefitModule.employerContribution')}
            value={formatMoney({
              amount: request.employerContribution ?? 0,
              asDecimal: true,
            })}
          />
          <DetailsLine
            label={polyglot.t('BenefitModule.employeeContribution')}
            value={formatMoney({
              amount: request.employeeContribution ?? 0,
              asDecimal: true,
            })}
          />
          {request.dependants && (
            <DetailsLine
              label={polyglot.t('BenefitModule.dependants')}
              value={translateUserInsuranceDependants(request.dependants, polyglot)}
            />
          )}
          {request.dependants && (
            <DetailsLine label={polyglot.t('BenefitModule.dependantsList')} value={dependantsList} />
          )}

          <DetailsLine label={polyglot.t('General.notes')} value={request.notes} />
        </Box>
      ) : request.type === UserCustomBenefitRequestType.LoanRequest ? (
        <Box>
          <DetailsLine
            label={polyglot.t('General.amount')}
            value={formatMoney({
              amount: request.amount ?? 0,
              asDecimal: true,
            })}
          />
          <DetailsLine label={polyglot.t('BenefitModule.numberOfInstallments')} value={request.numberOfInstallments} />
          <DetailsLine label={polyglot.t('General.notes')} value={request.notes} />
        </Box>
      ) : (
        <Box>
          <DetailsLine
            label={polyglot.t('General.date')}
            value={new LocalDate(request.effectiveDate).toLocaleDateString()}
          />
          <DetailsLine label={polyglot.t('General.notes')} value={request.notes} />
        </Box>
      )}

      {canApproveOrReject && (
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.reject')}
            sizeVariant="medium"
            colorVariant="secondary"
            onClick={rejectRequest}
            loading={isRejecting}
            disabled={isApproving}
            fullWidth
          />
          <LoaderButton
            name={polyglot.t('General.approve')}
            sizeVariant="medium"
            colorVariant="primary"
            onClick={approveRequest}
            loading={isApproving}
            disabled={isRejecting}
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};

const DetailsLine = ({ value, label }: { label: string; value: string | number | null | undefined }) => (
  <Box sx={{ mb: '20px' }}>
    <Typography variant="captionSmall" color="Grey">
      {label}
    </Typography>
    {value || value === 0 ? <Typography variant="caption">{value}</Typography> : <EmptyCell />}
  </Box>
);
