import { Stack } from '@mui/material';

import { ReactComponent as InPayroll } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as NewJoiner } from '@/images/side-bar-icons/ok-orange.svg';
import { ReactComponent as NotInPayroll } from '@/images/side-bar-icons/Rejected-grey.svg';
import { ReactComponent as Leaver } from '@/images/side-bar-icons/Rejected.svg';
import { formatUserPayrollStatus } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrollUserStatus } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';

type UserPayrollStatusCellProps = {
  status: PayrollUserStatus;
};

export const UserPayrollStatusCell = ({ status }: UserPayrollStatusCellProps) => {
  const { polyglot } = usePolyglot();
  const { label, icon } = status;
  return (
    <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: '5px' }}>
      {{
        // 'in-payroll': () => <InPayroll />,
        'not-in-payroll': () => <NotInPayroll />,
        'status-new': () => <NewJoiner />,
        'status-current': () => <InPayroll style={{ fill: themeColors.Green }} />,
        'status-leaver': () => <Leaver fill={themeColors.Red} />,
      }[icon]()}
      {formatUserPayrollStatus(label, polyglot)}
    </Stack>
  );
};
