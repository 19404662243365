export enum CustomProfileFormType {
  // PERSONAL TAB
  Basic = 'basic',
  Details = 'details',
  Family = 'family',
  About = 'about',
  Other = 'other',
  // WORK TAB
  Role = 'role',
  Contract = 'contract',
  Lifecycle = 'lifecycle',
  IdentityCheck = 'identity-check',
  // COMPENSATION TAB
  Salary = 'salary',
  TaxInformation = 'taxInformation', // TODO: should this be tax-information
  BankAccount = 'bank-account',
  Equity = 'equity',
  // CONTACT TAB
  Address = 'address',
  Emergency = 'emergency',
  WorkContact = 'work-contact',
}

export enum ProfileTab {
  Personal = 'personal',
  Work = 'work',
  Compensation = 'compensation',
  Contact = 'contact',
}
