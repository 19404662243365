import { Fragment } from 'react';

import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserCalendarVersionSmallLoader = () => {
  return (
    <WidgetLayout readOnly={false} size="small" isLoadingState={true}>
      <Fragment>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
          <Box>
            <div style={{ padding: spacing.p5 }}>
              <Typography variant="title2">Calendar</Typography>
              <Typography variant="captionSmall">This week</Typography>
            </div>
          </Box>
        </Box>

        <Box sx={{ height: '100%', padding: spacing.p5 }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              gap: spacing.g25,
            }}
          >
            {[0, 1, 2, 3, 4].map((num) => (
              <div className="ucw-loader-container" key={`${num}-ucs`}>
                <div className="ucw-progress" />
              </div>
            ))}
          </Box>
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
