import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_ATTENDANCE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface AbsencePolicyDeleteConfirmationDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
}

export const AttendanceScheduleDeleteConfirmationDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh,
}: AbsencePolicyDeleteConfirmationDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const history = useHistory();

  const deleteSchedule = useCallback(async () => {
    try {
      setLoading(true);
      await AttendanceAPI.deleteAttendanceScheduleById(attendanceSchedule.id);
      await refresh();
      history.push(SETTINGS_ATTENDANCE_ROUTE);
    } catch (error) {
      showMessage(`Could not delete schedule. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [attendanceSchedule.id, refresh, history, showMessage]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('AttendanceSchedule.deleteAttendanceSchedule')}</Typography>

        <Typography variant="title3">{attendanceSchedule.name}</Typography>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            fullWidth
            type="button"
            sizeVariant="medium"
            colorVariant="danger"
            name={polyglot.t('General.delete')}
            loading={loading}
            onClick={deleteSchedule}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
