import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary, keyBy } from 'lodash';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { PlaceholderBox } from '@/v2/feature/growth/reviews/features/components/placeholder-box.component';
import { SurveyTemplateAPI, SurveyTemplateEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-template.api';
import { SurveyQuestionBuilder } from '@/v2/feature/growth/surveys/features/components/question-builder.component';
import { SurveySectionBuilder } from '@/v2/feature/growth/surveys/features/components/section-builder.component';
import { CycleCreationQuestionPreviewer } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/components/cycle-creation-question-previewer.component';
import { SurveyCycleCreationQuestionSelectModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/components/cycle-creation-question-select-modal.component';
import { SurveyTemplateMoveToSectionModal } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/components/survey-template-move-to-section-modal.component';
import { SurveyTemplateQuestionModal } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/components/survey-template-question-modal.component';
import { SurveyTemplateSectionModal } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/components/survey-template-section-modal.component';
import { useSurveyTemplateQuestionOptions } from '@/v2/feature/growth/surveys/hooks/survey-template-cycle-shared/use-template-question-options.hook';
import { useSurveyTemplateSectionOptions } from '@/v2/feature/growth/surveys/hooks/survey-template-cycle-shared/use-template-section-options.hook';
import { SurveyQuestionBank } from '@/v2/feature/growth/surveys/interfaces/survey-question-bank.interface';
import { SurveySectionBank } from '@/v2/feature/growth/surveys/interfaces/survey-section-bank.interface';
import { SurveyTemplate, SurveyTemplateById } from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SurveyTemplateBuilderPage = ({
  templateObject,
  refreshTemplate,
}: {
  templateObject: SurveyTemplateById | null | undefined;
  refreshTemplate: KeyedMutator<SurveyTemplateById> | undefined;
}) => {
  const params = useParams<{ templateId: string }>();
  const templateId = params.templateId;
  const { data: bankCount } = useApiClient(SurveyTemplateEndpoints.getBankCount(), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const growthFactors: Dictionary<GrowthFactor> = useMemo(() => {
    return keyBy(allGrowthFactors, 'name');
  }, [allGrowthFactors]);

  const [question, setQuestion] = useState<SurveyQuestionBank | null>(null);
  const [section, setSection] = useState<SurveySectionBank | null>(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState<boolean>(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState<boolean>(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState<boolean>(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | undefined>(undefined);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState<boolean>(false);

  let template: SurveyTemplateById['template'] | undefined;
  let sections: SurveyTemplateById['sections'] | undefined;
  let questions: SurveyTemplateById['questions'] | undefined;

  if (templateObject) {
    ({ template, sections, questions } = templateObject);
  }

  const questionIdsInSections =
    sections?.reduce<string[]>((acc, section) => {
      return acc.concat(section.questionIds);
    }, []) ?? [];

  const questionsNotInAnySection = questions?.filter((question) => !questionIdsInSections?.includes(question.id)) ?? [];
  const renderQuestion = (questionId: string) => {
    const question = questionsNotInAnySection.find((q) => q.id === questionId);
    if (!question || !template) return null;
    return (
      <QuestionComponent
        key={question.id}
        question={question}
        template={template}
        sectionId={null}
        setQuestion={setQuestion}
        setIsQuestionModalOpen={setIsQuestionModalOpen}
        refreshTemplate={refreshTemplate}
        setIsMoveToSectionOpen={setIsMoveToSectionOpen}
        setSelectedQuestionId={setSelectedQuestionId}
        hasSections={Boolean(sections && sections.length > 0)}
        growthFactors={growthFactors}
      />
    );
  };

  const renderSection = (sectionId: string) => {
    const section = sections?.find((s) => s.id === sectionId);
    if (!section || !template) return null;

    const orderSection = template?.order.find((o) => o.id === sectionId && o.type === 'section');
    if (!orderSection) return null;

    const orderedQuestions = (orderSection.questions ?? [])
      .map((questionId) => questions?.find((q) => q.id === questionId))
      .filter((question): question is SurveyQuestionBank => Boolean(question));

    const handleDragDrop = async (results: DropResult) => {
      const { source, destination, type } = results;
      if (!template) return;
      if (type !== 'group') return;

      const sectionIndex = template?.order.findIndex((o) => o.type === 'section' && o.id === section.id);
      if (sectionIndex === -1) return;

      const reOrdered = { ...template.order[sectionIndex] };
      if (!reOrdered || !reOrdered.questions) return;

      const sourceIndex = source.index;
      const destinationIndex = destination?.index;
      if (sourceIndex === -1 || destinationIndex === undefined || destinationIndex === -1) return;

      const [removed] = reOrdered.questions.splice(sourceIndex, 1);
      reOrdered.questions.splice(destinationIndex, 0, removed);

      const updatedOrder = [...template.order];
      updatedOrder[sectionIndex] = reOrdered;

      await SurveyTemplateAPI.updateOrderSurveyTemplate({ templateId, order: updatedOrder });
      await refreshTemplate?.();
    };

    return (
      <TemplateBuilderSection
        key={section.id}
        section={section}
        setSection={setSection}
        setIsQuestionModalOpen={setIsQuestionModalOpen}
        setIsSectionModalOpen={setIsSectionModalOpen}
        questions={orderedQuestions}
        handleDragDrop={handleDragDrop}
        template={template}
        setQuestion={setQuestion}
        refreshTemplate={refreshTemplate}
        setIsMoveToSectionOpen={setIsMoveToSectionOpen}
        setSelectedQuestionId={setSelectedQuestionId}
        sections={sections}
        growthFactors={growthFactors}
      />
    );
  };

  const addQuestionsToTemplateHandle = async (selectedQuestionIds: Set<string>) => {
    try {
      await SurveyTemplateAPI.addQuestionsToTemplate([...selectedQuestionIds], templateId);
      refreshTemplate?.();
    } catch (error) {}
  };

  const moveQuestionInsideSection = async (sectionId: string, questionId: string) => {
    try {
      await SurveyTemplateAPI.moveQuestionInsideSection({ templateId, sectionId, questionId });
      refreshTemplate?.();
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Box
        className="sub-title"
        sx={{
          px: spacing.p16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: spacing.m4,
        }}
      >
        <Typography variant="title3">Questions</Typography>
        <ButtonComponent colorVariant="secondary" sizeVariant="small" onClick={() => setIsPreviewerOpen(true)}>
          Preview
        </ButtonComponent>
      </Box>
      {template?.companyId && (
        <Box sx={{ mt: spacing.m24, width: '100%', boxSizing: 'border-box', px: spacing.p16 }}>
          <PlaceholderBox
            title="Select questions from library"
            countOne={`${bankCount?.totalCategories ?? 0} factors`}
            countTwo={`${bankCount?.totalQuestions ?? 0} questions`}
            action={() => setIsQuestionBankOpen(true)}
          />
        </Box>
      )}

      <Box
        sx={{
          ...(template && template.order
            ? {}
            : { margin: spacing.m16, border: `1px dashed ${themeColors.Background}` }),
          mt: spacing.m24,
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {template &&
            template.order?.map((item) => {
              if (item.type === 'question') {
                return renderQuestion(item.id);
              } else if (item.type === 'section') {
                return renderSection(item.id);
              }
              return <></>;
            })}
        </Box>

        {template?.companyId && (
          <Box
            sx={{
              display: 'flex',
              gap: spacing.g5,
              mt: template && template.order ? spacing.m24 : '0px',
              padding: spacing.p16,
            }}
          >
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="medium"
              startIcon={<Plus {...iconSize} />}
              onClick={() => setIsSectionModalOpen(true)}
            >
              New section
            </ButtonComponent>
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="medium"
              startIcon={<Plus {...iconSize} />}
              onClick={() => setIsQuestionModalOpen(true)}
            >
              New question
            </ButtonComponent>
          </Box>
        )}

        <SurveyTemplateQuestionModal
          setIsOpen={setIsQuestionModalOpen}
          isOpen={isQuestionModalOpen}
          question={question}
          onClose={() => {
            setIsQuestionModalOpen(false);
            setQuestion(null);
            setSection(null);
          }}
          refresh={async () => {
            await refreshTemplate?.();
          }}
          section={section}
          templateId={templateId}
        />

        <SurveyTemplateSectionModal
          setIsOpen={setIsSectionModalOpen}
          isOpen={isSectionModalOpen}
          section={section}
          onClose={() => {
            setIsSectionModalOpen(false);
            setSection(null);
            setQuestion(null);
          }}
          refresh={async () => {
            await refreshTemplate?.();
          }}
          templateId={templateId}
        />
      </Box>

      <SurveyCycleCreationQuestionSelectModal
        setIsQuestionsModalOpen={setIsQuestionBankOpen}
        isQuestionsModalOpen={isQuestionBankOpen}
        action={addQuestionsToTemplateHandle}
      />

      <SurveyTemplateMoveToSectionModal
        isOpen={isMoveToSectionOpen}
        setIsOpen={setIsMoveToSectionOpen}
        onClose={() => {
          setIsMoveToSectionOpen(false);
        }}
        action={moveQuestionInsideSection}
        sections={sections}
        questionId={selectedQuestionId}
      />

      <CycleCreationQuestionPreviewer
        isPreviewerOpen={isPreviewerOpen}
        setIsPreviewerOpen={setIsPreviewerOpen}
        allQuestions={questions || []}
        allSections={sections || []}
        order={template?.order || []}
      />
    </Box>
  );
};

const TemplateBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  setIsMoveToSectionOpen,
  questions,
  handleDragDrop,
  template,
  setQuestion,
  refreshTemplate,
  setSelectedQuestionId,
  sections,
  growthFactors,
}: {
  section: SurveySectionBank;
  setSection: React.Dispatch<React.SetStateAction<SurveySectionBank | null>>;
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questions: SurveyQuestionBank[];
  handleDragDrop: (results: DropResult) => Promise<void>;
  template: SurveyTemplate;
  setQuestion: React.Dispatch<React.SetStateAction<SurveyQuestionBank | null>>;
  refreshTemplate: KeyedMutator<SurveyTemplateById> | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  sections: SurveySectionBank[] | undefined;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  const sectionOptions = useSurveyTemplateSectionOptions(
    template,
    section,
    setSection,
    refreshTemplate,
    setIsSectionModalOpen
  );

  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };

  return (
    <SurveySectionBuilder
      isEditable={Boolean(template.companyId)}
      section={section}
      // template={template}
      sectionOptions={sectionOptions}
      dragQuestionComponent={
        <DragDropContext onDragEnd={handleDragDrop}>
          <Droppable droppableId="ROOT" type="group" isDropDisabled={false}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions &&
                  questions.map((question, idx) => (
                    <Draggable
                      isDragDisabled={section?.companyId === null || false}
                      draggableId={question.id}
                      key={question.id}
                      index={idx}
                    >
                      {(provided) => (
                        <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                          <QuestionComponent
                            question={question}
                            template={template}
                            sectionId={section.id}
                            setQuestion={setQuestion}
                            setIsQuestionModalOpen={setIsQuestionModalOpen}
                            refreshTemplate={refreshTemplate}
                            setIsMoveToSectionOpen={setIsMoveToSectionOpen}
                            setSelectedQuestionId={setSelectedQuestionId}
                            hasSections={Boolean(sections && sections.length > 0)}
                            growthFactors={growthFactors}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
      newQuestionAction={newQuestionAction}
    />
  );
};

const QuestionComponent = ({
  question,
  template,
  sectionId,
  setQuestion,
  setIsQuestionModalOpen,
  refreshTemplate,
  setIsMoveToSectionOpen,
  setSelectedQuestionId,
  hasSections,
  growthFactors,
}: {
  question: SurveyQuestionBank;
  template: SurveyTemplate;
  sectionId: string | null;
  setQuestion: React.Dispatch<React.SetStateAction<SurveyQuestionBank | null>>;
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTemplate: KeyedMutator<SurveyTemplateById> | undefined;
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  hasSections: boolean;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  const questionOptions = useSurveyTemplateQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    template,
    sectionId,
    refreshTemplate,
    setSelectedQuestionId,
    hasSections
  );

  return (
    <SurveyQuestionBuilder
      questionOptions={questionOptions}
      question={question}
      growthFactors={growthFactors}
      template={template}
      showAction={Boolean(template.companyId)}
    />
  );
};
