import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { OnboardingAppsConfig } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { APPS_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type EditAppsProps = {
  config: OnboardingAppsConfig | undefined;
  onSave: (config: OnboardingAppsConfig) => void;
};

export const EditApps = (props: EditAppsProps) => {
  const { polyglot } = usePolyglot();
  const { onSave, config } = props;
  const [apps, setApps] = useState<{
    available: InstalledAppDto[];
    installedStubs: Set<string>;
  }>();
  const [selectedStubs, setSelectedStubs] = useState(
    new Set<AppIntegrationStub>(config?.stubs as AppIntegrationStub[])
  );
  const routerHistory = useHistory();
  const [creationDateOffset, setCreationDateOffset] = useState(config?.scheduleOffsetFromStartDate);

  const refreshAppState = useCallback(async () => {
    const [available, installed] = await Promise.all([
      AppIntegrationAPI.availableApps(),
      AppIntegrationAPI.getInstalledApps(),
    ]);
    // sort the apps by name
    available.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    setApps({
      available,
      installedStubs: new Set(installed.filter((app) => !app.invalidatedAt).map((app) => app.stub)),
    });
  }, []);

  useEffect(() => {
    refreshAppState();
  }, [refreshAppState]);

  const appsToShow = useMemo(
    () =>
      apps?.available.filter(
        (app) =>
          // display conencted apps which allow us to create accounts
          (apps.installedStubs.has(app.stub) && app.allowsGiveAccess) ||
          // if the user is editing an existing template, we need to show the apps currently configured in
          // the template, even if they are not currently authorised
          config?.stubs.includes(app.stub)
      ),
    [apps, config?.stubs]
  );

  if (appsToShow && appsToShow.length === 0) {
    // no apps are authorised and the template has none selected
    return (
      <>
        <Typography sx={themeFonts.title2}>{polyglot.t('EditApps.appAccounts')}</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.mt10 }}>
          {polyglot.t('EditApps.automateAccountCreation')}
        </Typography>
        <ButtonComponent
          fullWidth
          onClick={() => routerHistory.push(APPS_COMPANY_OVERVIEW_ROUTE)}
          sizeVariant="medium"
          colorVariant="primary"
          style={{ marginTop: '40px' }}
        >
          {polyglot.t('EditApps.goToApps')}
        </ButtonComponent>
      </>
    );
  }

  const hasSelectedApps = selectedStubs.size > 0;
  const hasSelectedCreationDate = typeof creationDateOffset === 'number';

  return (
    <>
      <Typography sx={themeFonts.title2}> {polyglot.t('EditApps.appAccounts')}</Typography>
      {appsToShow && (
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.mt10 }}>
          {polyglot.t('EditApps.selectApps')}
        </Typography>
      )}

      <Stack sx={{ mt: spacing.mt30, gap: spacing.g20 }}>
        {!appsToShow && <LoadingSpinner />}
        {apps &&
          appsToShow?.map(({ stub, name }) => (
            <CheckboxComponent
              key={stub}
              label={
                <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
                  <img
                    src={`/app-icons-v2/images/${stub}.png`}
                    style={{ maxHeight: '20px', maxWidth: '20px' }}
                    alt={name}
                  />
                  <Typography sx={themeFonts.caption}>{name}</Typography>
                  {!apps.installedStubs.has(stub) && (
                    <Typography sx={themeFonts.caption} title={polyglot.t('EditApps.appNotConnected')}>
                      {'⚠️'}
                    </Typography>
                  )}
                </Stack>
              }
              labelPlacement="end"
              checked={selectedStubs.has(stub)}
              onChange={(_, checked) => {
                const stubs = new Set(selectedStubs);
                stubs[checked ? 'add' : 'delete'](stub);
                setSelectedStubs(stubs);
              }}
            />
          ))}
      </Stack>

      {(hasSelectedApps || hasSelectedCreationDate) && (
        <Stack sx={{ mt: spacing.mt40, gap: spacing.g15, flex: 0 }}>
          <Typography sx={themeFonts.caption}>{polyglot.t('EditApps.creationDateMex')}</Typography>
          <Stack sx={{ flexFlow: 'row', gap: spacing.g10 }}>
            <Button
              sx={creationDateOffset === 0 ? activeTabsFilterBtnSx : tabsFilterBtnSx}
              onClick={() => setCreationDateOffset(0)}
            >
              {polyglot.t('EditApps.startDay')}
            </Button>
            <Button
              sx={creationDateOffset === -3 ? activeTabsFilterBtnSx : tabsFilterBtnSx}
              onClick={() => setCreationDateOffset(-3)}
            >
              {polyglot.t('EditApps.thrBefore')}
            </Button>
          </Stack>
        </Stack>
      )}

      <ButtonComponent
        type="button"
        fullWidth
        disabled={!hasSelectedApps && !hasSelectedCreationDate}
        onClick={() => {
          const stubs = [...selectedStubs];
          if (typeof creationDateOffset !== 'number' || !stubs.length) {
            return;
          }
          onSave({
            stubs,
            scheduleOffsetFromStartDate: creationDateOffset,
          });
        }}
        colorVariant="primary"
        sizeVariant="large"
        style={{
          marginTop: '40px',
          visibility: hasSelectedApps && hasSelectedCreationDate ? 'visible' : 'hidden',
        }}
      >
        {polyglot.t('General.save')}
      </ButtonComponent>
    </>
  );
};
