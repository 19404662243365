import { ATTENDANCE_COMPANY_ROUTE, ATTENDANCE_ME_ROUTE, ATTENDANCE_TEAM_ROUTE } from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const TIME_ATTENDANCE_ROUTES: RoleRoutes = {
  company: ATTENDANCE_COMPANY_ROUTE,
  team: ATTENDANCE_TEAM_ROUTE,
  me: ATTENDANCE_ME_ROUTE,
};

export const TIME_ATTENDANCE_ROUTES_SCOPES: RoleScopes = {
  company: ['attendance:all'] as Scope[],
  team: ['attendance:manager'],
  me: ['attendance'],
};
