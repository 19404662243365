import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, IconButton } from '@mui/material';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { HolidayDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { FiltersEndpoints } from '@v2/feature/filters/filters.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { addWeeks, getWeek } from 'date-fns';
import dayjs from 'dayjs';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { ICalLinkDrawer } from './components/ical-link-drawer.component';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as CompanyEventIcon } from '@/images/calendar/CompanyEvent.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { ReactComponent as Calendar } from '@/images/side-bar-icons/Calendar.svg';
import { ReactComponent as GoogleButton } from '@/images/side-bar-icons/GoogleButton.svg';
import { ReactComponent as Next } from '@/images/side-bar-icons/NextBtn.svg';
import { ReactComponent as OutlookLogo } from '@/images/side-bar-icons/outlook_logo.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CalendarAPI, CalendarEndpoints, GoogleCalendarAPI } from '@/v2/feature/calendar/calendar.api';
import { CalendarStatusForUserConfig } from '@/v2/feature/calendar/calendar.dto';
import {
  CompanyEvent,
  FormData,
  FullDay,
  OutlookCalendarPublishDrawerMode,
} from '@/v2/feature/calendar/calendar.interface';
import { AddEventModal } from '@/v2/feature/calendar/features/components/add-event-modal.component';
import { CalendarTimeline } from '@/v2/feature/calendar/features/components/calendar-timeline.component';
import { OutlookCalendarPublishDrawer } from '@/v2/feature/calendar/features/components/outlook-calendar-publish-drawer.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { getWeekDays, Z_startOfWeek } from '@/v2/util/date-fns/week.util';

enum CalendarView {
  Month = 'Month',
  Week = 'Week',
}

const CalendarEventInitial = (polyglot: Polyglot) => ({
  name: '',
  description: '',
  morningOnly: false,
  afternoonOnly: false,
  blockTimeawayRequests: false,
  startDate: new LocalDate().toDateString(),
  endDate: null,
  startFullOrHalfDay: FullDay(polyglot).value,
  endFullOrHalfDay: FullDay(polyglot).value,
  multipleDays: false,
  color: null,
});

export interface TimelineEventProps {
  readonly name: string;
  readonly department: string;
  readonly userId: number;
  readonly entries: readonly TableEntryProps[];
}

export type ZeltCalendarHoliday = Pick<HolidayDto, 'name' | 'date'> & { color: string; policyName: string };

export interface TableEntryProps {
  readonly title: string;
  readonly styling: {
    readonly color: string;
  };
  readonly day: string;
  readonly userEventDate: string;
  readonly events: {
    readonly isDob: boolean;
    readonly isFirstDay: boolean;
    readonly isWorkAnniversary: boolean;
    readonly isLastDay: boolean;
    absence: AbsenceDto[];
    schedule: {
      id: number;
      name: string;
      startHour: string | undefined;
      endHour: string | undefined;
    } | null;
    publicHolidays: ZeltCalendarHoliday[];
    countryCode: string | null;
  };
}

export const CalendarPage = ({ view }: { view: 'company' | 'team' | 'me' }) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const context = getScopesContext(state.user);
  const hasAppsRead = hasScopes(['apps'], context);
  const { polyglot } = usePolyglot();
  const [activeView, setActiveView] = useState<CalendarView>(
    (state.user.features?.calendar?.table?.calendarView as CalendarView) ?? CalendarView.Week
  );

  const { data: filtersAndTypesOptions, isLoading: filterLoading } = useApiClient(
    FiltersEndpoints.getCalendarFiltersOptions(),
    {
      suspense: false,
    }
  );
  const { data: installedApps } = useApiClient(AppIntegrationEndpoints.getCalendarApps(hasAppsRead), {
    suspense: false,
  });

  const microsoft365Installed = useMemo(() => {
    if (hasAppsRead)
      return installedApps && installedApps?.length > 0
        ? installedApps?.some((app: any) => app.stub === 'microsoft365')
        : false;
  }, [installedApps, hasAppsRead]);

  const peopleFilters = useMemo(() => filtersAndTypesOptions?.peopleFilters ?? {}, [filtersAndTypesOptions]);
  const eventsFilters = useMemo(() => filtersAndTypesOptions?.eventsFilters ?? {}, [filtersAndTypesOptions]);

  const routerHistory = useHistory();

  const [hideEmptyRows, setHideEmptyRows] = useState<boolean>(
    state.user.features?.calendar?.table?.hideEmptyRow ?? false
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [timelineDays, setTimelineDays] = useState<readonly Date[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<Date>(() => new Date());
  const [filterString, setFilterString] = useState<string>(state.user.features?.calendar?.table?.filters ?? '');
  const [selectedEvents, setSelectedEvents] = useState<string>(
    state.user.features?.calendar?.table?.selectedEvents ?? ''
  );
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [searchInput, setSearchInput] = useState<string>('');
  const currentUserIsCalendarAdmin = hasScopes(['calendar:all'], getScopesContext(context));
  const [outlookPublishDrawerMode, setOutlookPublishDrawerMode] = useState<OutlookCalendarPublishDrawerMode>(
    'subscribe'
  );
  const { data: existingPublishLinks, mutate: refreshCalendarPublishLinks } = useApiClient(
    microsoft365Installed ? CalendarEndpoints.getOutlookCalendarPublishLinks() : { url: '' },
    {
      suspense: false,
    }
  );

  const [outlookPublishDrawerOpen, setOutlookPublishDrawerOpen] = useState<boolean>(false);
  const [icalLinkDrawerOpen, setIcalLinkDrawerOpen] = useState<boolean>(false);
  const [calendarCompanyEvents, setCalendarCompanyEvents] = useState<CompanyEvent[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const [calendarEventsTimeline, setCalendarEventsTimeline] = useState<readonly TimelineEventProps[]>([]);

  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState<FormData>(CalendarEventInitial(polyglot));

  const getMonthDays = (date: Date) => {
    const startOfMonth = dayjs(date).startOf('month').toDate();
    const endOfMonth = dayjs(date).endOf('month').toDate();
    let days = [];
    for (let day = startOfMonth; day <= endOfMonth; day = dayjs(day).add(1, 'day').toDate()) {
      days.push(day);
    }
    return days;
  };
  useEffect(() => {
    const defaultFilters = Object.keys(eventsFilters)
      .map((key) => {
        const values =
          eventsFilters[key] && eventsFilters[key].length > 0
            ? eventsFilters[key].map((o) => o.value).join(',')
            : undefined;
        return values ? `${key}=${values}` : undefined;
      })
      .filter(Boolean)
      .join('&');

    setSelectedEvents((prev) => (prev ? prev : defaultFilters) ?? '');
  }, [eventsFilters]);

  const getCalendarData = useCallback(
    async (range: { start: string; end: string }) => {
      try {
        setLoading(true);
        const [calendarEvents, calendarEvent] = await Promise.all([
          CalendarAPI.getAllCalendarEventsByRange(
            range,
            filterString,
            selectedEvents,
            hideEmptyRows,
            searchInput,
            view
          ),
          CalendarAPI.getCalendarEventByDateRange(range),
        ]);
        setCalendarEventsTimeline(calendarEvents);
        setCalendarCompanyEvents(calendarEvent);
      } catch (error) {
        showMessage(
          polyglot.t('CalendarPage.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [filterString, selectedEvents, hideEmptyRows, searchInput, showMessage, polyglot, view]
  );

  useEffect(() => {
    if (activeView === CalendarView.Week) {
      const days = getWeekDays(new Date());
      const range = {
        start: new LocalDate(days[0]).toDateString(),
        end: new LocalDate(days[days.length - 1]).toDateString(),
      };
      setSelectedWeek(Z_startOfWeek(new Date()));
      setTimelineDays(days);
      getCalendarData(range);
    } else if (activeView === CalendarView.Month) {
      const days = getMonthDays(new Date());
      const range = {
        start: new LocalDate(days[0]).toDateString(),
        end: new LocalDate(days[days.length - 1]).toDateString(),
      };
      setSelectedWeek(dayjs().startOf('month').toDate());
      setTimelineDays(days);
      getCalendarData(range);
    }
  }, [getCalendarData, activeView]);

  const { timelineEvents, totalPagesCount, totalItems } = useMemo(() => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return {
      timelineEvents:
        calendarEventsTimeline.length > 500
          ? calendarEventsTimeline.slice(startIndex, endIndex)
          : calendarEventsTimeline,
      totalPagesCount: Math.ceil(calendarEventsTimeline.length / pageSize),
      totalItems: calendarEventsTimeline?.length || 0,
    };
  }, [calendarEventsTimeline, pageNumber, pageSize]);

  const refresh = useCallback(async () => {
    try {
      const days = activeView === CalendarView.Week ? getWeekDays(selectedWeek) : getMonthDays(selectedWeek);
      const range = {
        start: new LocalDate(days[0]).toDateString(),
        end: new LocalDate(days[days.length - 1]).toDateString(),
      };
      await getCalendarData(range);
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('CalendarPage.errorMessages.load', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, showMessage, getCalendarData, activeView, selectedWeek]);

  const debouncedGetCalendarData = useDebouncedCallback(async (days: readonly Date[]) => {
    await getCalendarData({
      start: new LocalDate(days[0]).toDateString(),
      end: new LocalDate(days[days.length - 1]).toDateString(),
    });
  }, 300);

  const navigate = useCallback(
    (offset: number, view: CalendarView) => {
      setActiveView(view);
      if (view === CalendarView.Week) {
        const updatedSelectedWeek = addWeeks(selectedWeek, offset);
        const days = getWeekDays(updatedSelectedWeek);
        setSelectedWeek(updatedSelectedWeek);
        setTimelineDays(days);
        setLoading(true);
        debouncedGetCalendarData.callback(days);
      } else if (view === CalendarView.Month) {
        const updatedSelectedMonth = dayjs(selectedWeek).add(offset, 'month').toDate();
        setSelectedWeek(updatedSelectedMonth);
        setTimelineDays(getMonthDays(updatedSelectedMonth));
        setLoading(true);
        debouncedGetCalendarData.callback(getMonthDays(updatedSelectedMonth));
      }
    },
    [debouncedGetCalendarData, selectedWeek]
  );

  const [googleCalendarConfig, setGoogleCalendarConfig] = useState<CalendarStatusForUserConfig | null>(null);

  // Function to fetch Google Calendar configuration
  const fetchGoogleCalendarConfig = useCallback(async () => {
    try {
      const data = await GoogleCalendarAPI.getConfig();
      setGoogleCalendarConfig(data);
    } catch (error) {
      // TODO if 401 or 404, then do nothing, otherwise, implement retry.
      setGoogleCalendarConfig(null);
    }
  }, []);

  // // TODO: @NOW___CALENDAR CONFIG

  // Effect to fetch the Google Calendar configuration on component mount
  useEffect(() => {
    fetchGoogleCalendarConfig();
  }, [fetchGoogleCalendarConfig]);

  // Memoized value to determine if Google Calendar is disconnected
  const googleDisconnected = useMemo(() => {
    return !googleCalendarConfig;
  }, [googleCalendarConfig]);

  const showOutlookCalendarPublishDrawer = () => {
    setOutlookPublishDrawerOpen(true);
    setOutlookPublishDrawerMode('publish');
  };

  const showOutlookCalendarSubscribeDrawer = () => {
    setOutlookPublishDrawerOpen(true);
    setOutlookPublishDrawerMode('subscribe');
  };

  const neitherAppIsConnected = useMemo(() => {
    return !(microsoft365Installed || !googleDisconnected);
  }, [googleDisconnected, microsoft365Installed]);

  const noAppConnectedAndStandardUser = useMemo(() => {
    return neitherAppIsConnected && !currentUserIsCalendarAdmin;
  }, [currentUserIsCalendarAdmin, neitherAppIsConnected]);

  const iCalLinkMenuOption = useMemo(() => {
    return {
      icon: <Calendar {...iconSize} />,
      handler: async () => {
        setIcalLinkDrawerOpen(true);
      },
      label: polyglot.t('CalendarPage.iCalLink'),
      disabled: false,
    };
  }, [polyglot]);

  const headerOptions = useMemo(
    () => [
      {
        title: polyglot.t('CalendarPage.calendar'),
        options: [
          ...(!googleDisconnected
            ? [
                {
                  icon: <GoogleButton {...iconSize} />,
                  handler: () => {
                    if (googleDisconnected) {
                      routerHistory.push('apps-details/google-workspace/about');
                    } else if (googleCalendarConfig) {
                      const calendarUrl = `https://calendar.google.com/calendar/u/${state.user.emailAddress}/r?cid=${googleCalendarConfig.calendarId}`;
                      window.open(calendarUrl);
                    }
                  },
                  label: polyglot.t('CalendarPage.subscribe'),
                  disabled: false,
                },
              ]
            : []),
          ...(microsoft365Installed && currentUserIsCalendarAdmin
            ? [
                {
                  icon: <OutlookLogo width={20} height={20} />,
                  handler: () => showOutlookCalendarPublishDrawer(),
                  label: polyglot.t('OutlookCalendarPublishDrawer.publish'),
                  disabled: false,
                },
              ]
            : []),
          ...(microsoft365Installed && existingPublishLinks
            ? [
                {
                  icon: <OutlookLogo width={20} height={20} />,
                  handler: () => showOutlookCalendarSubscribeDrawer(),
                  label: polyglot.t('CalendarPage.subscribe'),
                  disabled: false,
                },
              ]
            : []),
          ...(currentUserIsCalendarAdmin ? [iCalLinkMenuOption] : []),
        ],
      },
      {
        title: polyglot.t('CalendarPage.add'),
        options: [
          ...(currentUserIsCalendarAdmin
            ? [
                {
                  icon: <CompanyEventIcon {...iconSize} />,
                  handler: async () => {
                    setIsOpen(true);
                    setSelectedCalendarEvent({ ...CalendarEventInitial(polyglot) });
                  },
                  label: polyglot.t('CalendarPage.event'),
                  disabled: false,
                },
              ]
            : []),
        ],
      },
    ],
    [
      currentUserIsCalendarAdmin,
      existingPublishLinks,
      googleCalendarConfig,
      googleDisconnected,
      iCalLinkMenuOption,
      microsoft365Installed,
      polyglot,
      routerHistory,
      state.user.emailAddress,
    ]
  );

  const standardUserActions = useMemo(
    () => [
      {
        title: polyglot.t('CalendarPage.calendar'),
        options: [iCalLinkMenuOption],
      },
    ],
    [iCalLinkMenuOption, polyglot]
  );

  const handleStatePaginationState = (pageNumber: number, pageSize: number) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
  };

  useEffect(() => {
    trackPage('Calendar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasActionOptions = useMemo(() => {
    return headerOptions?.flatMap((o) => o.options)?.length > 0;
  }, [headerOptions]);

  const filterForIcalLink = useMemo(() => {
    return [filterString, selectedEvents, hideEmptyRows ? 'hideEmpty=true' : '', `view=${view}`]
      .filter(Boolean)
      .join('&');
  }, [filterString, hideEmptyRows, selectedEvents, view]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('calendar.title')}
          </Typography>
        }
        actions={
          <Box sx={{ display: 'flex', gap: spacing.g10 }}>
            {!noAppConnectedAndStandardUser && hasActionOptions && (
              <StyledMenuComponent
                headerOptions={headerOptions}
                actionButtonDetails={{
                  type: 'button',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: polyglot.t('CalendarPage.actions'),
                  icon: <ArrowDown {...iconSize} />,
                  iconPosition: 'end',
                  fullWidth: true,
                }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              />
            )}
            {!currentUserIsCalendarAdmin && standardUserActions && (
              <StyledMenuComponent
                headerOptions={standardUserActions}
                actionButtonDetails={{
                  type: 'button',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: polyglot.t('CalendarPage.actions'),
                  icon: <ArrowDown {...iconSize} />,
                  iconPosition: 'end',
                  fullWidth: true,
                }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              />
            )}
          </Box>
        }
        showAction={true}
      />
      <ContentWrapper loading={filterLoading} sx={{ ...spacing.pt20, overflowX: 'hidden' }}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', gap: spacing.g5 }}>
              <Button
                disableRipple
                onClick={async () => {
                  handleStatePaginationState(1, pageSize);
                  setHideEmptyRows(!hideEmptyRows);
                  const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                    'calendar',
                    'table',
                    'hideEmptyRow',
                    !hideEmptyRows
                  );

                  dispatch({
                    type: GlobalStateActions.UPDATE_USER,
                    payload: updatedGlobalUser,
                  });
                }}
                sx={hideEmptyRows ? activeTabsFilterBtnSx : tabsFilterBtnSx}
              >
                {polyglot.t('CalendarPage.hide')}
              </Button>

              {peopleFilters && (
                <Box sx={{ display: 'flex', gap: spacing.g5 }}>
                  <FiltersDrawer
                    name={polyglot.t('CalendarPage.calendar')}
                    filtersOptions={[{ filters: eventsFilters }]}
                    selectedFilters={selectedEvents}
                    setSelectedFilters={setSelectedEvents}
                    onApply={async (selectedFilters) => {
                      handleStatePaginationState(1, pageSize);
                      try {
                        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                          'calendar',
                          'table',
                          'selectedEvents',
                          selectedFilters
                        );
                        dispatch({
                          type: GlobalStateActions.UPDATE_USER,
                          payload: updatedGlobalUser,
                        });
                      } catch (error) {
                        showMessage(
                          polyglot.t('CalendarPage.errorMessages.schedule', {
                            errorMessage: nestErrorMessage(error),
                          }),
                          'error'
                        );
                      }
                    }}
                  />
                  <FiltersDrawer
                    filtersOptions={[{ filters: peopleFilters }]}
                    selectedFilters={filterString}
                    setSelectedFilters={setFilterString}
                    onApply={async (selectedFilters) => {
                      handleStatePaginationState(1, pageSize);
                      try {
                        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                          'calendar',
                          'table',
                          'filters',
                          selectedFilters
                        );
                        dispatch({
                          type: GlobalStateActions.UPDATE_USER,
                          payload: updatedGlobalUser,
                        });
                      } catch (error) {
                        showMessage(
                          polyglot.t('CalendarPage.errorMessages.schedule', {
                            errorMessage: nestErrorMessage(error),
                          }),
                          'error'
                        );
                      }
                    }}
                  />
                </Box>
              )}
              <TableSearch
                query={searchInput}
                handleChange={(e) => {
                  handleStatePaginationState(1, pageSize);
                  setSearchInput(e.target.value);
                }}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: spacing.g5 }}>
              <MonthWeekSwitcher navigate={navigate} timelineDays={timelineDays} activeView={activeView} />

              <Box
                sx={{
                  width: '100%',
                  height: '30px',
                  background: themeColors.Background,
                  borderRadius: radius.br20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {[CalendarView.Week, CalendarView.Month].map((v) => (
                  <SwitchButton
                    key={v}
                    isActive={v === activeView}
                    color={themeColors.DarkGrey}
                    action={async () => {
                      setLoading(true);
                      setActiveView(v as CalendarView);
                      const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                        'calendar',
                        'table',
                        'calendarView',
                        v
                      );

                      dispatch({
                        type: GlobalStateActions.UPDATE_USER,
                        payload: updatedGlobalUser,
                      });
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ color: v === activeView ? themeColors.white : themeColors.DarkGrey }}
                      className={v === activeView ? 'tnm-switch-active-text' : 'tnm-switch-text'}
                    >
                      {v}
                    </Typography>
                  </SwitchButton>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          {timelineDays.length > 0 && (
            <CalendarTimeline
              timelineDays={timelineDays}
              usersCalendarEvents={timelineEvents}
              calendarEvents={calendarCompanyEvents}
              setIsOpen={setIsOpen}
              setSelectedCalendarEvent={setSelectedCalendarEvent}
              refreshAbsences={refresh}
              handleStatePaginationState={handleStatePaginationState}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              totalCount={totalPagesCount}
              showPagination={calendarEventsTimeline.length > 500}
              pageSize={pageSize}
              totalItems={totalItems}
              activeView={activeView}
              loading={loading}
            />
          )}
        </Box>

        <OutlookCalendarPublishDrawer
          isOpen={outlookPublishDrawerOpen}
          setIsOpen={setOutlookPublishDrawerOpen}
          mode={outlookPublishDrawerMode}
          publishLinks={existingPublishLinks}
          refreshCalendarPublishLinks={refreshCalendarPublishLinks}
        />

        <ICalLinkDrawer
          isOpen={icalLinkDrawerOpen}
          setIsOpen={setIcalLinkDrawerOpen}
          filterString={filterForIcalLink ?? ''}
        />

        <ScopesControl scopes={['calendar:all']} context={context}>
          <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <AddEventModal
              initialValues={selectedCalendarEvent}
              onClose={async () => {
                setIsOpen(false);
                setSelectedCalendarEvent(CalendarEventInitial(polyglot));
                try {
                  const calendarEvents = await CalendarAPI.getCalendarEvent();
                  setCalendarCompanyEvents(calendarEvents);
                } catch (error) {
                  console.error(error);
                }
              }}
            />
          </DrawerModal>
        </ScopesControl>
      </ContentWrapper>
    </RootStyle>
  );
};

const buttonStyles = {
  paddingX: spacing.p10,
  paddingY: spacing.p5,
  borderRadius: radius.br20,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
};
const SwitchButton = ({
  isActive,
  color,
  action,
  children,
}: {
  isActive: boolean;
  color: string;
  action: () => Promise<void> | void;
  children: JSX.Element;
}) => {
  return (
    <Box
      className={isActive ? 'switch-no-hover' : 'switch-hover'}
      sx={{
        ...buttonStyles,
        color: isActive ? themeColors.white : themeColors.DarkGrey,
        background: isActive ? color : 'none',
        transition: 'all 0.2s ease-in-out',
      }}
      onClick={action}
    >
      {children}
    </Box>
  );
};

const MonthWeekSwitcher = ({
  navigate,
  timelineDays,
  activeView,
}: {
  navigate: (offset: number, view: CalendarView) => void;
  timelineDays: readonly Date[];
  activeView: CalendarView;
}) => {
  const { polyglot } = usePolyglot();

  const generateOptions = () => {
    const options = [];
    const format = activeView === CalendarView.Week ? 'week' : 'month';

    for (let i = -2; i <= 2; i++) {
      const label =
        activeView === CalendarView.Week
          ? `Week ${getWeek(addWeeks(new Date(timelineDays[0]), i))}`
          : dayjs(timelineDays[0]).add(i, format).format('MMM');
      options.push({
        handler: () => navigate(i, activeView),
        label: <Typography variant={i === 0 ? 'title4' : 'caption'}>{label}</Typography>,
        disabled: false,
      });
    }
    return options;
  };

  const title =
    activeView === CalendarView.Week
      ? `Week ${getWeek(new Date(timelineDays[0]))}`
      : dayjs(timelineDays[0]).format('MMM');

  return (
    <Box
      sx={{
        backgroundColor: themeColors.Background,
        color: themeColors.DarkGrey,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: radius.br20,
      }}
    >
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={() => navigate(-1, activeView)}
        title={polyglot.t('CalendarPage.previous')}
        sx={{ ...tableIconButtonSx, borderRadius: radius.br20 }}
      >
        <Back {...iconSize} />
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px' }}>
        <StyledMenuComponent
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: title,
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
            fullWidth: true,
          }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          options={generateOptions()}
        />
      </Box>

      <IconButton
        disableRipple
        disableFocusRipple
        onClick={() => navigate(1, activeView)}
        title={polyglot.t('CalendarPage.next')}
        sx={{ ...tableIconButtonSx, borderRadius: radius.br20 }}
      >
        <Next {...iconSize} />
      </IconButton>
    </Box>
  );
};
