import { useMemo } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { recentDateStrings } from '@/v2/util/date-format.util';

type OnboardingUserStatsProps = {
  progressPercent: number;
  pendingTaskCount: number;
  startDate?: string;
  sx?: SxProps<Theme>;
};

export const OnboardingUserStats = ({ progressPercent, pendingTaskCount, startDate, sx }: OnboardingUserStatsProps) => {
  const { polyglot } = usePolyglot();

  const startInfo = useMemo(() => {
    if (!startDate) return null;
    const { yesterday, today, tomorrow } = recentDateStrings();
    if (startDate === today) {
      return {
        label: polyglot.t('OnboardingUserStats.started'),
        value: polyglot.t('OnboardingUserStats.today'),
        date: startDate,
      };
    }
    if (startDate === yesterday) {
      return {
        label: polyglot.t('OnboardingUserStats.started'),
        value: polyglot.t('OnboardingUserStats.yesterday'),
        date: startDate,
      };
    }
    if (startDate === tomorrow) {
      return {
        label: polyglot.t('OnboardingUserStats.starting'),
        value: polyglot.t('OnboardingUserStats.tomorrow'),
        date: startDate,
      };
    }
    return {
      label:
        today < startDate ? polyglot.t('OnboardingUserStats.startingIn') : polyglot.t('OnboardingUserStats.started'),
      // don't use formatDistanceToNow because it rounds incorrectly
      value: formatDistanceStrict(Date.parse(startDate), Date.parse(today), { addSuffix: today >= startDate }),
      date: startDate,
    };
  }, [startDate, polyglot]);

  return (
    <Stack sx={{ flexFlow: 'row', gap: spacing.g60, ...sx }}>
      {Number.isFinite(progressPercent) && (
        <Stack sx={{ gap: spacing.g5 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {polyglot.t('OnboardingUserStats.progress')}
          </Typography>
          <Typography sx={{ ...themeFonts.title2, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}>
            {Math.min(Math.max(Math.round(progressPercent), 0), 100)}%
          </Typography>
        </Stack>
      )}
      {Number.isInteger(pendingTaskCount) && pendingTaskCount >= 0 && (
        <Stack sx={{ gap: spacing.g5 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {polyglot.t('OnboardingUserStats.pending')}
          </Typography>
          <Typography sx={{ ...themeFonts.title2, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}>
            {polyglot.t('OnboardingUserStats.taskCount', { smart_count: pendingTaskCount })}
          </Typography>
        </Stack>
      )}
      {startInfo && (
        <Stack sx={{ gap: spacing.g5 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{startInfo.label}</Typography>
          <Typography
            sx={{ ...themeFonts.title2, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}
            title={
              // appending a time component to the date should force the result to be in local time, without tz adjustments
              new Date(`${startInfo.date}T12:00`).toLocaleDateString(undefined, { dateStyle: 'full' })
            }
          >
            {startInfo.value}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
