import { useCallback, useMemo, useState } from 'react';

import { IconButton, Stack, SxProps, Theme } from '@mui/material';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import {
  isPaycodeForAdditions,
  isPaycodeForDeductions,
  isPaycodeForOtherEmployerPayments,
} from '@v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-flow.util';

import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { UserHeader } from '@/v2/components/user-header.component';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { payslipDownloadUrl } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/personal-payroll.component';
import { UserPayslipSummaryDto } from '@/v2/feature/payroll/payroll.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

type SalarySummaryRowProps = {
  description: string;
  amount?: number;
  subtract?: boolean;
  hideIfZero?: boolean;
  sx?: SxProps<Theme>;
  textVariant?: TypographyVariant;
  textColor?: keyof typeof themeColors;
};

const SummaryRow = ({
  description,
  amount,
  subtract,
  hideIfZero,
  sx,
  textVariant = 'caption',
  textColor = 'DarkGrey',
}: SalarySummaryRowProps) => {
  if (hideIfZero && !amount) {
    return <></>;
  }
  const numericAmount = (amount ?? 0) * (subtract ? -1 : 1);
  return (
    <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between', gap: spacing.g10, ...sx }}>
      <Typography variant={textVariant} color={textColor}>
        {description}
      </Typography>
      <Typography variant={textVariant} color={textColor}>
        {formatCurrency(numericAmount, { noCurrencySymbol: true })}
      </Typography>
    </Stack>
  );
};

interface ClassifiedPayline {
  code: string;
  description: string;
  amount: number;
}

interface ClassifiedResult {
  additions: ClassifiedPayline[];
  deductions: ClassifiedPayline[];
  otherEmployerPayments: ClassifiedPayline[];
}

const classifyAdditionsDeductions = (paylines: GlobalPayline[], paycodes: GlobalPaycode[]): ClassifiedResult => {
  const result: ClassifiedResult = { additions: [], deductions: [], otherEmployerPayments: [] };

  paylines.forEach((payline) => {
    const paycode = paycodes.find((code) => code.code === payline.code);

    if (paycode) {
      const { description, amount, code } = payline;

      if (isPaycodeForAdditions(paycode)) {
        result.additions.push({ description, amount, code });
      } else if (isPaycodeForDeductions(paycode)) {
        result.deductions.push({ description, amount, code });
      } else if (isPaycodeForOtherEmployerPayments(paycode)) {
        result.otherEmployerPayments.push({ description, amount, code });
      }
    }
  });

  return result;
};

const calculateTotalAmount = (classifiedPaylines: ClassifiedPayline[]): number => {
  return classifiedPaylines.reduce((total, payline) => total + payline.amount, 0);
};

type GlobalPayrollSalarySummaryProps = {
  user: CachedUser;
  payrunEntry: GlobalPayrunEntry;
  globalPaycodes: GlobalPaycode[];
  readonly payslip: UserPayslipSummaryDto | null;
  readonly payrun?: GlobalPayrun;
  readonly handleEditAction?: (entry: GlobalPayrunEntry) => void;
};

export const GlobalPayrollSalarySummaryPage = ({
  user,
  payrunEntry,
  globalPaycodes,
  payslip,
  payrun,
  handleEditAction,
}: GlobalPayrollSalarySummaryProps) => {
  const classifiedPaylines = useMemo(() => classifyAdditionsDeductions(payrunEntry.paylines, globalPaycodes), [
    globalPaycodes,
    payrunEntry.paylines,
  ]);
  const [viewingPayslip, setViewingPayslip] = useState<string | null>(null);

  const handleDocumentButtonClick = useCallback(
    async (user: CachedUser, payslip?: UserPayslipSummaryDto | null, payrun?: GlobalPayrun) => {
      if (!payslip?.payrollId && !payrun?.payrollId) return;
      const payrollId = payslip?.payrollId ?? payrun?.payrollId;
      const taxYear = payslip?.taxYear ?? payrun?.taxYear;
      const payPeriod = payslip?.payPeriod ?? payrun?.payPeriod;
      const period = payslip?.period ?? payrun?.period;
      const userId = payslip?.userId ?? user?.userId;
      const url = `/apiv2/company/salary/payroll/${payrollId}/global/global-payroll/${taxYear}/${payPeriod}/${period}/${userId}/payslip-pdf/download`;
      const payslipUrl = payslipDownloadUrl(url);
      if (payslipUrl) setViewingPayslip(payslipUrl);
    },
    []
  );

  const payrunComplete = payrun?.state === 'COMPLETED';
  return (
    <Stack>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <UserHeader user={user} />
        {payslip || (payrun && payrunComplete && user) ? (
          <IconButton
            sx={tableIconButtonSx}
            onClick={() => handleDocumentButtonClick(user, payslip, payrun)}
            title="View/Download"
          >
            <DownloadIcon {...iconSize} />
          </IconButton>
        ) : null}
        {handleEditAction && payrunEntry ? (
          <IconButton sx={tableIconButtonSx} onClick={() => handleEditAction(payrunEntry)} title="Edit">
            <EditIcon {...iconSize} />
          </IconButton>
        ) : null}
      </Stack>

      <Stack sx={{ gap: spacing.g40, mt: spacing.mt40 }}>
        <Stack sx={{ gap: spacing.g15 }}>
          <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between' }}>
            <Typography variant="title3" color="black">
              Payments
            </Typography>
            <Typography variant="title3" color="black">
              Amount
            </Typography>
          </Stack>
          {classifiedPaylines.additions.map((cpl) => (
            <SummaryRow description={cpl.description} amount={cpl.amount ?? 0} />
          ))}
          <SummaryRow
            description="Total Payments"
            amount={calculateTotalAmount(classifiedPaylines.additions)}
            textVariant="title4"
          />
        </Stack>

        <Stack sx={{ gap: spacing.g15 }}>
          <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between' }}>
            <Typography variant="title3" color="black">
              Deductions
            </Typography>
            <Typography variant="title3" color="black">
              Amount
            </Typography>
          </Stack>
          {classifiedPaylines.deductions.map((cpl) => (
            <SummaryRow subtract description={cpl.description} amount={cpl.amount ?? 0} />
          ))}
          <SummaryRow
            description="Total Deductions"
            amount={calculateTotalAmount(classifiedPaylines.deductions)}
            textVariant="title4"
            subtract
          />
        </Stack>

        <Stack sx={{ gap: spacing.g15 }}>
          <SummaryRow
            description="Take Home pay"
            amount={
              calculateTotalAmount(classifiedPaylines.additions) - calculateTotalAmount(classifiedPaylines.deductions)
            }
            textVariant="title4"
          />
        </Stack>

        {classifiedPaylines.otherEmployerPayments?.length > 0 && (
          <Stack sx={{ gap: spacing.g15, mt: spacing.mt30 }}>
            <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between' }}>
              <Typography variant="title4" color="black">
                Other employer payments
              </Typography>
            </Stack>
            {classifiedPaylines.otherEmployerPayments.map((cpl) => (
              <SummaryRow description={cpl.description} amount={cpl.amount ?? 0} />
            ))}
          </Stack>
        )}
      </Stack>
      {viewingPayslip && (
        <DocPreviewer docData={viewingPayslip} visible onClose={() => setViewingPayslip(null)} title="Payslip" />
      )}
    </Stack>
  );
};
