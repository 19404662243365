import { useCallback, useMemo, useState } from 'react';

import { ColumnDef, Row } from '@tanstack/react-table';
import { EmptyStateBox } from '@v2/components/empty-state-box.component';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyEntryEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-entry.api';
import { SurveyEntriesPersonalStartModal } from '@/v2/feature/growth/surveys/features/survey-entry/survey-entries-personal/components/survey-entries-personal-start-modal.component';
import { getEntryStatus, getSurveyDueDate } from '@/v2/feature/growth/surveys/features/survey-entry/survey-entry.util';
import { SurveyCycleLaunch } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { SurveyEntry } from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';

export const SurveyCycleEntriesPage = () => {
  const { data: userEntries, isValidating: loadingUserEntries } = useApiClient(SurveyEntryEndpoints.getUserEntries(), {
    suspense: false,
  });

  const { data: surveyCycleLaunchDetails, isValidating: loadingCycleLaunchDetails } = useApiClient(
    SurveyEntryEndpoints.getSurveyCycleLaunchDetailsForCompanyAndUser(),
    {
      suspense: false,
    }
  );

  // convert array of survey cycle launch details to map of survey cycle launch details
  const surveyCycleLaunchDetailsMap = useMemo(() => {
    const map = new Map<string, SurveyCycleLaunch>();
    surveyCycleLaunchDetails?.forEach((eachSurveyCycleLaunch: SurveyCycleLaunch) =>
      map.set(eachSurveyCycleLaunch.id, eachSurveyCycleLaunch)
    );
    return map;
  }, [surveyCycleLaunchDetails]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<SurveyEntry | undefined>(undefined);

  const tableColumns = useMemo<ColumnDef<SurveyEntry, SurveyEntry>[]>(
    () => [
      {
        header: () => 'Survey name',
        id: 'cycle.displayName',
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.cycle?.displayName ? <div>{original.cycle.displayName}</div> : <EmptyCell />,
      },
      {
        header: () => 'Anonymous',
        id: 'anonymous',
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.cycle?.visibilitySettings?.anonymiseAnswers ? <div>Yes</div> : <div>No</div>,
      },
      {
        header: () => 'Questions',
        id: 'questionCount',
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original?.cycleId && surveyCycleLaunchDetailsMap.has(original.cycleId) ? (
            surveyCycleLaunchDetailsMap?.get(original?.cycleId)?.totalQuestions
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Due date',
        id: 'duedate',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>
            {getSurveyDueDate(original)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          </div>
        ),
      },
      {
        header: () => 'Status',
        id: 'state',
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{getEntryStatus()[original.entryState]}</div>,
      },
    ],
    [surveyCycleLaunchDetailsMap]
  );

  const handleRowClick = useCallback((row: Row<SurveyEntry>) => {
    if (row.original.cycle?.state !== CycleState.Completed) {
      setIsOpen(true);
      setSelectedEntry(row.original);
    }
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Surveys</Typography>} />
      <ContentWrapper loading={loadingUserEntries || loadingCycleLaunchDetails}>
        {!loadingUserEntries && userEntries && userEntries.length === 0 ? (
          <EmptyStateBox
            title="Your invites"
            subtitle="Here you will find invitations to surveys. There are no pending invites for you at the moment."
          />
        ) : (
          <BasicTable
            rowData={userEntries ? [...userEntries] : []}
            columnData={tableColumns}
            loading={loadingUserEntries || loadingCycleLaunchDetails}
            hidePagination={true}
            rowClick={handleRowClick}
          />
        )}

        <SurveyEntriesPersonalStartModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setIsOpen(false);
            setTimeout(() => {
              setSelectedEntry(undefined);
            }, 400);
          }}
          selectedEntry={selectedEntry}
          afterClose={() => setSelectedEntry(undefined)}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
