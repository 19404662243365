import React, { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ScheduleWeeksPattern } from '@v2/feature/attendance/attendance.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly formik: FormikProps<ScheduleWeeksPattern>;
  readonly loading: boolean;
}

export const AttendancePatternScheduleStartDateDrawer = ({ isOpen, setIsOpen, formik, loading }: DrawerProps) => {
  const { polyglot } = usePolyglot();

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} fixedWidthPx={465}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('AttendanceSchedulePattern.setStartDate')}</Typography>

        <Typography variant="caption">{polyglot.t('AttendanceSchedulePattern.setStartDateDesc')}</Typography>

        <DatePickerComponent
          inputFormat="DD/MM/YYYY"
          value={formik.values.startDateOfFirstWeek}
          onChange={async (value) => {
            if (dayjs(value).isValid()) await formik.setFieldValue('startDateOfFirstWeek', value);
            else await formik.setFieldValue('startDateOfFirstWeek', '');
          }}
          name="startDateOfFirstWeek"
          label={polyglot.t('AttendanceSchedulePattern.selectDate')}
          error={!!formik.errors.startDateOfFirstWeek && formik.touched.startDateOfFirstWeek}
          helperText={(formik.touched.startDateOfFirstWeek && formik.errors.startDateOfFirstWeek) as string}
          shouldDisableDate={(date) => {
            if (!dayjs(date).isValid()) return true;
            return dayjs(date).day() !== 1;
          }}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            onClick={() => formik.handleSubmit()}
            loading={loading}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
