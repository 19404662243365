import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { format } from 'date-fns';

import { ReactComponent as Task } from '@/images/dashboard-icons/Task.svg';
import { TaskActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/task-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { OverdueTaskBigWidget, TaskTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';
import { LocalDate } from '@/v2/util/local-date';

export const OverdueTaskTodo = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: OverdueTaskBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<TaskTodo | undefined>(undefined);

  const getTitle = (task: TaskTodo): string => {
    const dueDate = new LocalDate(task.dueDate).getDate();
    return polyglot.t('OverdueTaskTodo.incomplete', { taskName: task.name, date: format(dueDate, 'd MMM') });
  };

  return (
    <Box>
      {todo.tasks.map((task, idx) => (
        <TodoCard
          key={`${task.name}-task-${idx}`}
          icon={<Task {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(task)}
            </Typography>
          }
          actionOnclick={() => setSelectedRow(task)}
          showBorder={!setLastChild}
          userAvatar={task.userId ? <UserAvatar key={task.userId} userId={task.userId} size="xxxsmall" /> : <></>}
        />
      ))}

      {selectedRow && (
        <TaskActionItem
          taskActionRow={selectedRow as TaskTodo}
          refresh={refreshTodos}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
