import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { TableSearch } from '@v2/components/table/table-search.component';
import { DeviceDto } from '@v2/feature/device/device.dto';
import { spacing } from '@v2/styles/spacing.styles';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { DeviceApplication } from '@/v2/feature/device/device.interface';

interface InHouseMdmInstalledApplicationsProps {
  readonly device: DeviceDto;
  readonly loading: boolean;
}

export const InHouseMdmInstalledApplications = ({
  device,
  loading,
}: InHouseMdmInstalledApplicationsProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredApps = useMemo(() => {
    return (
      device.applications?.filter((app) => {
        return app.name.toLowerCase().includes(searchInput.toLowerCase());
      }) ?? []
    );
  }, [device.applications, searchInput]);

  const applicationsTableColumns = useMemo<ColumnDef<DeviceApplication, DeviceApplication>[]>(
    () => [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{original?.name ? original.name : <EmptyCell />}</div>,
        size: 145,
      },
      {
        header: () => 'Version',
        accessorFn: (row) => row,
        id: 'version',
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{original?.version ? original.version : <EmptyCell />}</div>,
        size: 145,
      },
      {
        header: () => 'Size (MB)',
        accessorFn: (row) => row,
        id: 'size',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>{original?.size ? `${(original.size / (1024 * 1024)).toFixed(2)} MB` : <EmptyCell />}</div>
        ),
        size: 145,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>
            {original?.status ? (
              `${original.status.charAt(0).toUpperCase()}${original.status.slice(1).toLowerCase()}`
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
        size: 145,
      },
    ],
    []
  );

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '5px',
            alignItems: 'left',
          }}
        >
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
      </Box>
      <Box sx={spacing.mt20}>
        <BasicTable rowData={filteredApps} columnData={applicationsTableColumns} loading={loading} hidePagination />
      </Box>
    </>
  );
};
