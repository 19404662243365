import { IconButtonProps } from '@mui/material';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type CloseButtonProps = IconButtonProps & {};

export const CloseButton = ({ ...props }: CloseButtonProps) => {
  return (
    <IconButton
      sizeVariant="small"
      colorVariant="secondary"
      style={{ flex: 0, position: 'absolute', right: spacing.m50 }}
      {...props}
    >
      <Close {...iconSize} stroke={themeColors.DarkGrey} />
    </IconButton>
  );
};
