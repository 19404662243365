import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateDomainTypes } from '@v2/infrastructure/i18n/translate.util';

import { ReactComponent as Document } from '@/images/dashboard-icons/Document.svg';
import { TodoCardVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/todo-card-version-small.component';
import {
  MissingDocumentsSmallWidget,
  PendingContractsSmallWidget,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { iconSize } from '@/v2/styles/menu.styles';

export const DocumentTodoVersionSmall = ({
  todo,
}: {
  todo: MissingDocumentsSmallWidget | PendingContractsSmallWidget;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <TodoCardVersionSmall
      key={`${todo.domain}-req`}
      icon={<Document {...iconSize} />}
      title={translateDomainTypes(todo.domain, polyglot)}
      countTitle={polyglot.t('ShiftsTodoVersionSmall.pending', { count: todo.count })}
    />
  );
};
