import { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, Card, CardContent, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import type { GridColDef, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';

import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { EntityImportDatagridValidation } from '@/v2/feature/entity-import/components/entity-import-datagrid-validation.component';
import { EntityImportResultPageProps } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserImportAPI } from '@/v2/feature/user/features/user-import/user-import.api';
import { UserImportDto, UserImportResultDto } from '@/v2/feature/user/features/user-import/user-import.dto';
import { getRowIdsWithErrors, getTotalErrorCount, hasErrors } from '@/v2/util/data-import.util';

interface GoogleWorkspaceUser {
  primaryEmail: string;
  name: {
    givenName: string;
    familyName: string;
  };
}

export function UserImportGoogleWorkspacePage({
  goNext,
  goBack,
}: EntityImportResultPageProps<UserImportResultDto>): JSX.Element {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [users, setUsers] = useState<GoogleWorkspaceUser[]>([]);
  const [uploadResponse, setUploadResponse] = useState<UserImportResultDto | undefined>();
  const [selectedUsers, setSelectedUsers] = useState<GridSelectionModel>([]);
  const { refreshCachedUsers } = useCachedUsers();

  const validationTableColumns: GridColDef[] = [
    {
      headerName: polyglot.t('UserProperties.firstName'),
      field: 'firstName',
      width: 200,
    },
    {
      headerName: polyglot.t('UserProperties.lastName'),
      field: 'lastName',
      width: 200,
    },
    {
      headerName: polyglot.t('UserProperties.workEmail'),
      field: 'workEmail',
      width: 300,
    },
  ];

  const tableColumns: GridColDef[] = [
    {
      headerName: polyglot.t('UserProperties.firstName'),
      field: 'name.givenName',
      sortable: true,
      width: 200,
      valueGetter: (param: GridValueGetterParams) => param.row.name.givenName,
    },
    {
      headerName: polyglot.t('UserProperties.lastName'),
      field: 'name.familyName',
      sortable: true,
      width: 200,
      valueGetter: (param: GridValueGetterParams) => param.row.name.familyName,
    },
    {
      headerName: polyglot.t('UserProperties.workEmail'),
      field: 'primaryEmail',
      sortable: true,
      width: 300,
    },
  ];

  const loadUsers = async () => {
    setLoading(true);
    try {
      const accountData = await AuthAPI.getAccountData();
      setUsers(accountData.users);
      setLoading(false);
    } catch (error) {
      // @ts-ignore
      if (error.response?.status === 401) {
        window.location.href = '/apiv2/auth/initiate?source=google';
        return;
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleSubmit = async () => {
    const nullDefaults = { dateOfBirth: null, startDate: null, visaExpirationDate: null };
    const importedUsers: Partial<UserImportDto>[] = users
      .filter((user) => selectedUsers.includes(user.primaryEmail))
      .map((user) => ({
        workEmail: user.primaryEmail,
        firstName: user.name.givenName,
        lastName: user.name.familyName,
        ...nullDefaults,
      }));
    setIsSubmitting(true);
    try {
      const result = await UserImportAPI.bulkCreate(importedUsers);
      setUploadResponse(result);
      await refreshCachedUsers();
      if (!hasErrors(result.errors)) goNext(result);
    } finally {
      setIsSubmitting(false);
    }
  };

  const tryAgain = () => {
    // setServerError('');
    setUploadResponse(undefined);
  };

  return (
    <Box
      sx={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box textAlign="center" paddingY={3}>
        <Typography variant="subtitle2">{polyglot.t('UserImportGoogleWorkspacePage.selectEmployees')}</Typography>
        <Typography variant="body2">{polyglot.t('UserImportGoogleWorkspacePage.addInformation')}</Typography>
      </Box>
      {/* {uploadResponse && uploadResponse.errors.length > 0 && (
        <Alert
          severity="warning"
          sx={{ marginBottom: 3, width: '100%', marginX: 'auto' }}
          action={
            <Button color="inherit" size="small" variant="outlined" onClick={() => tryAgain()} sx={{ marginY: 'auto' }}>
              Try again
            </Button>
          }
        >
          <AlertTitle>Check Formatting </AlertTitle>
          We found some issues with your formatting. Fix those issues and try again.
        </Alert>
      )}
      {serverError && (
        <Alert
          severity="warning"
          sx={{ marginBottom: 3, width: '100%', marginX: 'auto' }}
          action={
            <Button
              color="inherit"
              size="small"
              variant="outlined"
              onClick={() => tryAgain()}
              sx={{ marginY: 'auto', width: '96px' }}
            >
              Try again
            </Button>
          }
        >
          <AlertTitle>Something bad happened</AlertTitle>
          {serverError}
        </Alert>
      )} */}
      {uploadResponse && uploadResponse.errors.length > 0 && (
        <Alert
          severity={hasErrors(uploadResponse.errors) ? 'error' : 'success'}
          sx={{ marginBottom: 3, width: '100%', marginX: 'auto' }}
          action={
            hasErrors(uploadResponse.errors) && (
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={() => tryAgain()}
                sx={{ marginY: 'auto' }}
              >
                Try again
              </Button>
            )
          }
        >
          <AlertTitle>
            {hasErrors(uploadResponse.errors)
              ? polyglot.t('UserImportCsvPage.checkFormatting')
              : polyglot.t('UserImportCsvPage.importCompleted')}{' '}
          </AlertTitle>
          {polyglot.t('UserImportCsvPage.rowsImportedSuccessfully', {
            count: uploadResponse?.errors?.length - getTotalErrorCount(uploadResponse.errors),
          })}
          {hasErrors(uploadResponse.errors) &&
            polyglot.t('UserImportCsvPage.rowsImportedSuccessfully', {
              count: getTotalErrorCount(uploadResponse.errors),
            })}
        </Alert>
      )}
      <Card dir="ltr" sx={{ minWidth: '100%' }}>
        <CardContent
          sx={{
            height: 400,
          }}
        >
          {uploadResponse && uploadResponse?.errors?.length > 0 ? (
            <EntityImportDatagridValidation
              rows={uploadResponse.errors}
              columns={validationTableColumns}
              rowIdsToExport={getRowIdsWithErrors(uploadResponse.errors) as string[]}
            />
          ) : (
            <DataGrid
              columns={tableColumns}
              rows={users}
              loading={loading}
              getRowId={(param) => {
                return param.primaryEmail;
              }}
              hideFooter
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectedUsers(newSelectionModel);
              }}
              selectionModel={selectedUsers}
            />
          )}
        </CardContent>
      </Card>
      <Box sx={{ my: 2, display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Button onClick={goBack} sx={{ mr: 1 }}>
          {polyglot.t('General.back')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          disabled={selectedUsers?.length === 0}
        >
          {polyglot.t('General.continue')}
        </LoadingButton>
      </Box>
    </Box>
  );
}
