import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box, Chip } from '@mui/material';
import { JsonViewer } from '@textea/json-viewer';
import { AutocompleteComponent } from '@v2/components/forms/autocomplete.component';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { OptionObj } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';

import useMessage from '@/hooks/notification.hook';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly companyId: number;
  readonly absencePolicies: AbsencePolicyDto[];
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const HelperAbsencePolicyBalanceDrawer = ({
  isOpen,
  setIsOpen,
  companyId,
  absencePolicies,
  users,
}: DrawerProps) => {
  const [showMessage] = useMessage();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const [year, setYear] = useState<number>(currentYear);
  const [useEffectiveDate, setUseEffectiveDate] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate] = useState<string>(new LocalDate().toDateString());
  const [userId, setUserId] = useState<number | null>(null);
  const [policyId, setPolicyId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [balance, setBalance] = useState<UserBalanceDetailedStatsDto | null>(null);

  const userOptions = useMemo(() => {
    return users
      .filter((u) => u.company.companyId === companyId)
      .map((u) => ({
        value: u.userId,
        label: `${u.firstName} ${u.lastName} - [${u.userId}]`,
      }));
  }, [users, companyId]);

  const policyOptions = useMemo(() => {
    return absencePolicies.map((p) => ({ value: p.id, label: p.name }));
  }, [absencePolicies]);

  const getBalance = useCallback(
    async (
      userId: number,
      policyId: number,
      options: {
        year: number;
        effectiveDate: string;
        useEffectiveDate: boolean;
      }
    ) => {
      if (!userId || !policyId) return null;

      try {
        setLoading(true);
        const balance = await AbsenceAPI.getUserAbsenceBalanceBreakdownByPolicyIdAsSuperAdmin(
          companyId,
          userId,
          policyId,
          options.useEffectiveDate ? null : options.year,
          !options.useEffectiveDate ? null : options.effectiveDate
        );
        setBalance(balance);
      } catch (error) {
        console.error(error);
        showMessage('Something went wrong', error);
        setBalance(null);
      }
      setLoading(false);
    },
    [companyId, showMessage]
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">Absence balance</Typography>

        <AutocompleteComponent
          name="user"
          label="User"
          fullWidth
          options={userOptions}
          filterSelectedOptions
          value={userOptions.find((o) => o.value === userId) ?? null}
          // sx={{ width: '350px' }}
          // @ts-ignore
          onChange={(_, x: OptionObj) => {
            setUserId(x?.value ? Number(x.value) : null);
          }}
          isOptionEqualToValue={(x, y) => x.value === y.value}
          compareValue={userId ?? ''}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              return <Chip label={option.label} {...getTagProps({ index })} />;
            })
          }
        />

        <SelectComponent
          name="policy"
          label="Policy"
          options={policyOptions}
          value={policyId}
          onChange={(e) => setPolicyId(Number(e.target.value))}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Use date</Typography>
          <SwitchComponent
            checked={useEffectiveDate}
            name="useEffectiveDate"
            onChange={async (_e, enabled) => {
              setUseEffectiveDate(enabled);
            }}
          />
        </Box>

        {useEffectiveDate ? (
          <DatePickerComponent
            name="effectiveDate"
            label="Effective date"
            onChange={(value) => {
              setEffectiveDate(value);
            }}
            value={effectiveDate}
          />
        ) : (
          <SelectComponent
            name="year"
            label="Year"
            options={[
              { value: currentYear - 3, label: (currentYear - 3).toString() },
              { value: currentYear - 2, label: (currentYear - 2).toString() },
              { value: currentYear - 1, label: (currentYear - 1).toString() },
              { value: currentYear, label: currentYear.toString() },
              { value: currentYear + 1, label: (currentYear + 1).toString() },
              { value: currentYear + 2, label: (currentYear + 2).toString() },
            ]}
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
          />
        )}

        {balance && (
          <Box sx={{ mt: '20px' }}>
            <JsonViewer value={balance} theme="dark" rootName="Balance" />
          </Box>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            loading={loading}
            colorVariant="primary"
            name="Get balance"
            onClick={async () => {
              if (!userId || !policyId) return null;
              await getBalance(userId, policyId, { year, effectiveDate, useEffectiveDate });
            }}
            fullWidth
            disabled={!userId || !policyId}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
