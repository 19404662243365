import { useContext, useMemo, useState } from 'react';

import { Box, Link } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { PaginationDetail } from '@/v2/components/table/pagination-detail.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  PaginationObject,
  StrapiFaqData,
  StrapiGuideData,
  UnSanitizedFaq,
  UnSanitizedGuide,
} from '@/v2/feature/strapi-help/strapi.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface PaginationProps {
  pagination: PaginationObject;
  setPagination: React.Dispatch<React.SetStateAction<PaginationObject>>;
}

const PaginationWrapper = ({ pagination, setPagination }: PaginationProps) => (
  <PaginationDetail
    totalPageCount={pagination.pageCount}
    totalRecordCount={pagination.total ?? 0}
    current={pagination.page}
    onNextAction={() => setPagination({ ...pagination, page: pagination.page + 1 })}
    nextDisabled={pagination.page === pagination.pageCount}
    onPreviousAction={() => setPagination({ ...pagination, page: pagination.page - 1 })}
    previousDisabled={pagination.page === 1}
    paginationState={{ pageIndex: pagination.page, pageSize: pagination.pageSize }}
    setPaginationState={(pageSize: number, pageIndex: number) => {
      setPagination({ ...pagination, page: pageIndex, pageSize });
    }}
    handleState={(page: number) => setPagination({ ...pagination, page })}
    showCompact
  />
);

const ListItem = ({ title, description, onClick }: { title: string; description: string; onClick: () => void }) => (
  <Box
    sx={{
      width: '100%',
      '&:hover': { background: themeColors.GreyHover, cursor: 'pointer' },
      pointerEvents: 'auto',
      pb: spacing.p10,
    }}
    onClick={onClick}
  >
    <div style={{ padding: spacing.p5 }}>
      <Typography variant="title4">{title}</Typography>
      <Typography
        variant="caption"
        sx={{
          color: themeColors.Grey,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {description}
      </Typography>
    </div>
  </Box>
);

interface DetailByDomainBoxProps {
  faqs: StrapiFaqData[] | undefined;
  guides: StrapiGuideData[] | undefined;
  setSelectedGuide: React.Dispatch<React.SetStateAction<UnSanitizedGuide | undefined>>;
  setSelectedFaq: React.Dispatch<React.SetStateAction<UnSanitizedFaq | undefined>>;
  faqPagination: PaginationObject;
  setFaqPagination: React.Dispatch<React.SetStateAction<PaginationObject>>;
  guidePagination: PaginationObject;
  setGuidePagination: React.Dispatch<React.SetStateAction<PaginationObject>>;
  showChatSupport: boolean;
}

export const DetailByDomainBox = ({
  faqs,
  guides,
  setSelectedGuide,
  setSelectedFaq,
  faqPagination,
  setFaqPagination,
  guidePagination,
  setGuidePagination,
  showChatSupport,
}: DetailByDomainBoxProps) => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const { emailAddress, firstName, lastName } = state.user ?? {};
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showMessage] = useMessage();

  const hubspotData = useMemo(() => ({ email: emailAddress, firstName, lastName }), [
    emailAddress,
    firstName,
    lastName,
  ]);

  async function handleChatClick() {
    try {
      const token = await AppIntegrationAPI.generateHubSpotIdentificationToken(emailAddress, firstName, lastName);
      if (token) {
        console.info('Got hs token');
        window.hsConversationsSettings = {
          identificationEmail: hubspotData.email,
          identificationToken: token ?? '',
        };
        // Add event listener.
        window.hsConversationsOnReady = [
          () => {
            setHasLoaded(true);
          },
        ];

        // Create script component.
        let script = document.createElement('script');
        script.src = `//js-na1.hs-scripts.com/20337724.js`;
        script.async = true;
        console.info('Script loaded');

        document.body.appendChild(script);
        window.HubSpotConversations?.clear({ resetWidget: true });
        window.HubSpotConversations?.widget?.open();
        window.HubSpotConversations?.widget?.load();

        console.info('Widget loaded', hasLoaded);

        return () => {
          document.body?.removeChild(script);
          window.hsConversationsOnReady = [];
        };
      } else {
        console.info('2 - No visitor token or email available, so not initializing chat!');
      }
    } catch (error) {
      showMessage(`Encountered an error: ${error}`, 'error');
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g30, mt: spacing.m15 }}>
      {faqs && faqs.length === 0 && guides && guides.length === 0 ? (
        <Box>
          <Typography variant="caption" sx={{ color: themeColors.Grey, fontStyle: 'italic' }}>
            {polyglot.t('HelpCenter.emptyMessage')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey, fontStyle: 'italic' }}>
              {polyglot.t('HelpCenter.contactUsVia')}{' '}
              <Link
                href="mailto:support@zelt.app"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: themeColors.Grey,
                  ...themeFonts.caption,
                }}
              >
                support@zelt.app
              </Link>
            </Typography>
            {showChatSupport && (
              <Typography
                variant="caption"
                sx={{
                  color: themeColors.Grey,
                  fontStyle: 'italic',
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g5,
                }}
              >
                {polyglot.t('HelpCenter.or')}
                <div
                  role="button"
                  style={{
                    background: 'none',
                    border: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: themeColors.Grey,
                    ...themeFonts.caption,
                  }}
                  onClick={handleChatClick}
                >
                  {polyglot.t('HelpCenter.chat')}
                </div>
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box>
            {faqs && faqs.length > 0 && (
              <>
                <Typography variant="title2" sx={{ padding: spacing.p5 }}>
                  {polyglot.t('HelpCenter.faqs')}
                </Typography>

                {faqs.map((d) => (
                  <ListItem
                    title={d.attributes.Question}
                    description={d.attributes.Answer}
                    onClick={() => {
                      setSelectedGuide(undefined);
                      setSelectedFaq(d.attributes);
                    }}
                  />
                ))}
              </>
            )}
            <PaginationWrapper pagination={faqPagination} setPagination={setFaqPagination} />
          </Box>
          <Box>
            {guides && guides.length > 0 ? (
              <>
                <Typography variant="title2" sx={{ padding: spacing.p5 }}>
                  {polyglot.t('HelpCenter.guides')}
                </Typography>

                {guides.map((guide, idx) => (
                  <ListItem
                    key={idx}
                    title={guide.attributes.Title}
                    description={guide.attributes.Description}
                    onClick={() => {
                      setSelectedFaq(undefined);
                      setSelectedGuide(guide.attributes);
                    }}
                  />
                ))}

                <PaginationWrapper pagination={guidePagination} setPagination={setGuidePagination} />
              </>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};
