import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { BidirectionalButton } from '@/v2/components/bidirectional-button.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { spacing } from '@/v2/styles/spacing.styles';

export const AttendanceViewTopHeaderActions = ({
  handleRange,
  rangeType,
  navigateBack,
  navigateNext,
  buttonTitle,
  recenterToday,
  showToday,
}: {
  readonly handleRange: (type: 'Month' | 'Week') => void;
  readonly rangeType: 'Month' | 'Week';
  readonly navigateBack: () => void;
  readonly navigateNext: () => void;
  readonly buttonTitle: string;
  readonly recenterToday: () => void;
  readonly showToday: boolean;
}) => {
  const { polyglot } = usePolyglot();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
      {showToday && (
        <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => recenterToday()}>
          {polyglot.t('AttendanceDomain.today')}
        </ButtonComponent>
      )}
      <StyledMenuComponent
        options={[
          {
            handler: () => handleRange('Month'),
            label: polyglot.t('AttendanceDomain.month'),
            disabled: false,
          },
          {
            handler: () => handleRange('Week'),
            label: polyglot.t('AttendanceDomain.week'),
            disabled: false,
          },
        ]}
        actionButtonDetails={{
          type: 'button',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: rangeType === 'Month' ? polyglot.t('AttendanceDomain.month') : polyglot.t('AttendanceDomain.week'),
          icon: <ArrowDown {...iconSize} />,
          iconPosition: 'end',
        }}
      />

      <BidirectionalButton navigateBack={navigateBack} navigateNext={navigateNext} buttonTitle={buttonTitle} />
    </Box>
  );
};
