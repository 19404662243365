import { PermissionDto, PermissionGroupDto } from '@/v2/feature/permission-group/permission-group.dto';

export interface PermissionCategory {
  name: string;
  subcategories: PermissionSubCategory[];
}

export interface PermissionSubCategory {
  name: string;
  permissions: PermissionDto[];
}

export enum PERMISSION_GROUP_EDIT_DRAWER_MODES {
  member = 'member',
  scope = 'scope',
  add = 'add',
  view = 'view',
  target = 'target',
  general = 'general',
}

export interface PermissionGroupPageProps {
  readonly permissionGroup: PermissionGroupDto;
  readonly refresh: () => Promise<void>;
  readonly additionalScopes: {
    [permissionGroupName: string]: PermissionDto[];
  };
  readonly permissionCategories: PermissionCategory[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefaultManagerPermissionLevel = 1;
export const AllManagerPermissionLevels = 999;

export type ManagerPermissionLevels = 1 | 2 | 3 | typeof AllManagerPermissionLevels;

export type PermissionGroupOptions = { reportsLevels?: ManagerPermissionLevels };
