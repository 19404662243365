import React, { useContext, useMemo } from 'react';

import { keyBy } from 'lodash';

import { GlobalContext } from '@/GlobalState';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CommonCycleAndBankSection } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';
import { GrowthPreviewer } from '@/v2/feature/growth/shared/components/growth-previewer.component';
import { OrderPreference } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { CommonSurveyCycleAndBankQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';

export const CycleCreationQuestionPreviewer = ({
  isPreviewerOpen,
  setIsPreviewerOpen,
  allQuestions,
  allSections,
  order,
}: {
  isPreviewerOpen: boolean;
  setIsPreviewerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allQuestions: CommonSurveyCycleAndBankQuestion[];
  allSections: CommonCycleAndBankSection[];
  order: OrderPreference[];
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;

  const questionsLookup = useMemo(() => {
    if (!allQuestions) return keyBy([], 'id');
    const company_name = user?.company?.name ?? undefined;

    const questions = allQuestions.map((q) => {
      return {
        ...q,
        questionText: replaceParamsInQuestionText(stripHtml(q.questionText), { company_name }) ?? '',
        options: q.choiceOptions,
      };
    });
    return keyBy(questions ?? [], 'id');
  }, [allQuestions, user?.company?.name]);

  return (
    <DrawerModal
      isOpen={isPreviewerOpen}
      setIsOpen={setIsPreviewerOpen}
      onClose={() => {
        setIsPreviewerOpen(false);
      }}
      widthPercentage={50}
    >
      <GrowthPreviewer allSections={allSections} order={order} questionsLookup={questionsLookup} />
    </DrawerModal>
  );
};
