import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { JobLevelAPI } from '@/v2/feature/job-level/job-level.api';
import { JobLevel } from '@/v2/feature/job-level/job-level.interface';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const LevelFormModal = ({
  setOpenLevel,
  openLevel,
  refresh,
  trackName,
  selectedLevel,
  onClose,
}: {
  setOpenLevel: React.Dispatch<React.SetStateAction<boolean>>;
  openLevel: boolean;
  refresh: KeyedMutator<JobLevel[]> | undefined;
  trackName: string;
  selectedLevel: JobLevel | undefined;
  onClose: () => void;
}) => {
  return (
    <DrawerModal isOpen={openLevel} setIsOpen={setOpenLevel} onClose={onClose}>
      <LevelFormContent
        initialValue={selectedLevel}
        refresh={refresh}
        trackName={trackName}
        selectedLevel={selectedLevel}
        onClose={onClose}
      />
    </DrawerModal>
  );
};

const LevelFormContent = ({
  initialValue,
  refresh,
  trackName,
  selectedLevel,
  onClose,
}: {
  initialValue: JobLevel | undefined;
  refresh: KeyedMutator<JobLevel[]> | undefined;
  trackName: string;
  selectedLevel: JobLevel | undefined;
  onClose: () => void;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      levelName: initialValue?.levelName || '',
      levelDescription: initialValue?.levelDescription || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      levelName: Yup.string().required(polyglot.t('LevelFormContent.errorMessage.nameRequired')),
      levelDescription: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const jobLevelAction = async () => {
        try {
          setLoading(true);
          const jobTrackObj = {
            trackName,
            levelName: values.levelName,
            levelDescription: values.levelDescription,
          };

          if (selectedLevel && selectedLevel?.levelId) {
            await JobLevelAPI.updateJobLevel({ ...jobTrackObj, levelId: selectedLevel.levelId });
          } else {
            await JobLevelAPI.createJobLevel(jobTrackObj);
          }

          await refresh?.();
          showMessage(polyglot.t('LevelFormContent.successMessage.successfulAction'), 'success');
          onClose();
        } catch (error) {
          showMessage(
            polyglot.t('LevelFormContent.errorMessage.jobLevelActionFailed', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        } finally {
          setSubmitting(false);
          setLoading(false);
        }
      };

      if (trackName) {
        jobLevelAction();
      }
    },
  });

  const deleteLevel = async () => {
    try {
      if (initialValue?.levelId) {
        await JobLevelAPI.deleteJobLevel(initialValue?.levelId);
        await refresh?.();
        onClose();
        showMessage(polyglot.t('LevelFormContent.successMessage.successfulAction'), 'success');
      }
    } catch (error) {
      showMessage(polyglot.t('LevelFormContent.errorMessage.deleteActionFailed'), 'error');
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              ...spacing.mb20,
            }}
          >
            <Typography variant="title2">
              {initialValue?.levelId ? polyglot.t('LevelFormContent.edit') : polyglot.t('LevelFormContent.new')}{' '}
              {polyglot.t('LevelFormContent.level')}
            </Typography>
            {initialValue?.levelId && (
              <IconButton
                sizeVariant="small"
                colorVariant="secondary"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setIsRemovalModalOpen(true);
                }}
              >
                <Trash {...iconSize} />
              </IconButton>
            )}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="levelName"
                label={polyglot.t('LevelFormContent.name')}
                value={formik.values.levelName}
                type="text"
                onChange={formik.handleChange}
                error={formik.touched.levelName && !!formik.errors.levelName}
                helperText={(formik.touched.levelName && formik.errors.levelName) ?? ' '}
                clearText={() => formik.setFieldValue('levelName', '')}
              />
            </Box>

            <Box sx={fieldSx}>
              <TextfieldComponent
                name="levelDescription"
                multiline
                label={polyglot.t('LevelFormContent.descripton')}
                value={formik.values.levelDescription}
                type="text"
                onChange={formik.handleChange}
                error={formik.touched.levelDescription && !!formik.errors.levelDescription}
                helperText={(formik.touched.levelDescription && formik.errors.levelDescription) ?? ' '}
                maxValue={2}
                endAdornment="none"
              />
            </Box>

            <Box sx={{ ...spacing.mt20 }}>
              <LoaderButton loading={loading} sizeVariant="medium" colorVariant="primary" fullWidth type="submit">
                {polyglot.t('LevelFormContent.save')}
              </LoaderButton>
            </Box>
          </Box>
        </Form>
      </FormikProvider>

      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => setIsRemovalModalOpen(false)}
        anchorEl={anchorEl}
        takeAction={deleteLevel}
        message={polyglot.t('LevelFormContent.areYouSureYouWantToDeleteThisLevel')}
        callToAction={polyglot.t('LevelFormContent.delete')}
      />
    </>
  );
};
