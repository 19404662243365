import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollEditPayCodesDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycodes-drawer.component';
import { PayrollSettingsPayCodesTable } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycodes-table.component';
import { PayrollAPI, PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { AccountingFieldPaycodeMapping } from '@/v2/feature/payroll/payroll.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import {
  NewCompanyPayCode,
  StaffologyPayCode,
} from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
}

export const PayrollCompanySettingsPayCodes = ({ payroll }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [editingPayCode, setEditingPayCode] = useState<StaffologyPayCode | 'new'>();
  const [searchQuery, setSearchQuery] = useState('');
  const [showMessage] = useMessage();

  const { data: payCodes, mutate: updateAndRefreshPayCodes } = useApiClient(
    PayrollEndpoints.getCompanyPaycodes(payroll.id, false),
    { suspense: false }
  );

  const { data: nominalCodes, mutate: refreshNominalCodes } = useApiClient(
    PayrollEndpoints.getNominalCodes(payroll.id),
    { suspense: false }
  );

  const { data: accountingConfig, mutate: updateAndRefreshAccounting } = useApiClient(
    PayrollEndpoints.getAccounting(payroll.id),
    { suspense: false }
  );

  const payCodesNames = useMemo(() => payCodes?.map(({ code }) => code) ?? [], [payCodes]);

  const filteredPayCodes = useMemo(() => {
    const query = searchQuery.trim().toUpperCase();
    if (!query) return payCodes;
    return payCodes?.filter(
      ({ code, title }) => code.toUpperCase().includes(query) || title.toUpperCase().includes(query)
    );
  }, [payCodes, searchQuery]);

  const reimportNominalCodes = useCallback(async () => {
    await PayrollAPI.refreshNominalCodes(payroll.id);
    await refreshNominalCodes?.();
  }, [payroll.id, refreshNominalCodes]);

  const setAccountingFieldForPayCode = useCallback(
    async (payCode: string, accountingField?: AccountingFieldPaycodeMapping) => {
      if (!accountingConfig) return;
      // create an updated set of accountingCodes, without the entry for this paycode
      const updatedAccountingFields = [...(accountingConfig.accountingCodes.fields ?? [])].filter(
        (a) => a.payCode !== payCode
      );
      // re-add the updated field if an accounting code has been assigned
      if (accountingField?.code) {
        updatedAccountingFields.push(accountingField);
      }
      await PayrollAPI.updateAccounting(payroll.id, updatedAccountingFields);
      updateAndRefreshAccounting?.({
        ...accountingConfig,
        accountingCodes: { fields: updatedAccountingFields },
      });
    },
    [accountingConfig, payroll.id, updateAndRefreshAccounting]
  );

  const createCompanyPaycode = useCallback(
    async (newPayCode: NewCompanyPayCode, accountingField?: AccountingFieldPaycodeMapping) => {
      try {
        const fullCode = await PayrollAPI.createCompanyPaycode(payroll.id, newPayCode);
        const updatedPayCodeList = payCodes?.concat([fullCode]);
        updateAndRefreshPayCodes?.(updatedPayCodeList);

        await setAccountingFieldForPayCode(newPayCode.code, accountingField);

        showMessage(polyglot.t('PayrollPaycodeSettings.paycodeCreated', { paycode: newPayCode.code }), 'success');
        return true;
      } catch (error) {
        showMessage(
          polyglot.t('PayrollPaycodeSettings.paycodeCreationFailed', { reason: nestErrorMessage(error) }),
          'error'
        );
        return false;
      }
    },
    [payroll.id, payCodes, updateAndRefreshPayCodes, setAccountingFieldForPayCode, showMessage, polyglot]
  );

  const updateCompanyPaycode = useCallback(
    async (updatedCode: StaffologyPayCode, updatedAccountingField?: AccountingFieldPaycodeMapping) => {
      try {
        if (!payCodes) return false;
        if (!(updatedCode.isSystemCode || updatedCode.isControlCode)) {
          await PayrollAPI.updateCompanyPaycodes(payroll.id, [updatedCode]);
          const updatedPayCodeList = payCodes
            .filter((payCode) => payCode.code !== updatedCode.code)
            .concat(updatedCode);
          updateAndRefreshPayCodes?.(updatedPayCodeList);
        }

        await setAccountingFieldForPayCode(updatedCode.code, updatedAccountingField);

        showMessage(polyglot.t('PayrollPaycodeSettings.paycodesUpdated'), 'success');
        return true;
      } catch (error) {
        showMessage(
          polyglot.t('PayrollPaycodeSettings.paycodeUpdateFailed', { reason: nestErrorMessage(error) }),
          'error'
        );
        return false;
      }
    },
    [payCodes, payroll.id, updateAndRefreshPayCodes, setAccountingFieldForPayCode, showMessage, polyglot]
  );

  const deleteCompanyPaycode = useCallback(
    async (payCode: StaffologyPayCode) => {
      try {
        if (payCode.isSystemCode || payCode.isControlCode) {
          throw new Error(`Cannot delete system/control pay code: ${payCode.code}`);
        }
        const { code: codeToDelete } = payCode;
        await PayrollAPI.deleteCompanyPaycode(payroll.id, codeToDelete);
        const updatedPayCodeList = payCodes?.filter((payCode) => payCode.code !== codeToDelete);
        updateAndRefreshPayCodes?.(updatedPayCodeList);

        // remove any paycode mapping to the accounting codes
        await setAccountingFieldForPayCode(codeToDelete, undefined);

        showMessage(polyglot.t('PayrollPaycodeSettings.paycodeDeleted', { paycode: codeToDelete }), 'success');
        return true;
      } catch (error) {
        showMessage(
          polyglot.t('PayrollPaycodeSettings.paycodeDeletionFailed', { reason: nestErrorMessage(error) }),
          'error'
        );
        return false;
      }
    },
    [payroll.id, payCodes, updateAndRefreshPayCodes, setAccountingFieldForPayCode, showMessage, polyglot]
  );

  return (
    <Stack sx={{ mr: spacing.mr20, mt: spacing.mt20 }}>
      <PayrollSettingSectionHeader>Pay codes</PayrollSettingSectionHeader>
      <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between', gap: spacing.g10 }}>
        <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
        <ButtonComponent
          sizeVariant="small"
          colorVariant="primary"
          onClick={() => setEditingPayCode('new')}
          style={{ width: 'min-content' }}
        >
          {polyglot.t('PayrollPaycodeSettings.newCode')}
        </ButtonComponent>
      </Stack>
      <PayrollSettingsPayCodesTable
        payCodes={filteredPayCodes}
        onEditPayCodeClick={setEditingPayCode}
        accountingConfig={accountingConfig}
      />
      <PayrollEditPayCodesDrawer
        payrollId={payroll.id}
        payCode={editingPayCode}
        payCodesNames={payCodesNames}
        isOpen={!!editingPayCode}
        setIsOpen={(isOpen) => !isOpen && setEditingPayCode(undefined)}
        createCompanyPaycode={createCompanyPaycode}
        deleteCompanyPaycode={deleteCompanyPaycode}
        updateCompanyPaycode={updateCompanyPaycode}
        nominalCodes={nominalCodes}
        reimportNominalCodes={reimportNominalCodes}
        accountingConfig={accountingConfig}
      />
    </Stack>
  );
};
