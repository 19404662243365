import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const RepayLoanPaymentDrawer = ({ isOpen, setIsOpen, userCustomBenefit, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <RepayLoanPaymentDrawerContent userCustomBenefit={userCustomBenefit} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const RepayLoanPaymentDrawerContent = ({ userCustomBenefit, refresh, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const repayLoanPayment = useCallback(
    async (values: { amount: number | '' }) => {
      try {
        setLoading(true);
        await CustomBenefitAPI.repayLoanPayment(
          userCustomBenefit.userId,
          userCustomBenefit.customBenefitId,
          userCustomBenefit.id,
          Number(values.amount)
        );

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setLoading(false);
    },
    [polyglot, userCustomBenefit, refresh, setIsOpen, showMessage]
  );

  const formik = useFormik<{ amount: number | '' }>({
    initialValues: {
      amount: '',
    },
    validationSchema: yup.object({
      amount: yup
        .number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .min(0, polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: repayLoanPayment,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography variant="title2" sx={{ mb: '20px' }}>
          {polyglot.t('BenefitModule.addRepayment')}
        </Typography>

        <Box>
          <TextfieldComponent
            name="amount"
            label={polyglot.t('General.amount')}
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && !!formik.errors.amount}
            helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
          />
        </Box>

        <Box sx={{ mt: '30px' }}>
          <LoaderButton
            sizeVariant="medium"
            loading={loading}
            colorVariant="primary"
            name={polyglot.t('BenefitModule.makePayment')}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
