import React, { useMemo, useState } from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { ReviewQuestionBankEndpoints } from '@/v2/feature/growth/reviews/api-client/review-question-bank.api';
import { GrowthQuestionLibraryContent } from '@/v2/feature/growth/shared/components/growth-question-library-content.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { stripHtml } from '@/v2/util/string.util';

export const CycleCreationQuestionSelectModal = ({
  isQuestionsModalOpen,
  setIsQuestionsModalOpen,
  action,
}: {
  isQuestionsModalOpen: boolean;
  setIsQuestionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: (selectedQuestionIds: Set<string>) => Promise<void>;
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');

  const { data: questions, isLoading: questionsLoading } = useApiClient(
    ReviewQuestionBankEndpoints.getReviewQuestionFromBank(searchInput, filterString),
    {
      suspense: false,
    }
  );
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const factorFilterTypes = useMemo(() => {
    if (!allGrowthFactors) return undefined;

    return {
      factor: allGrowthFactors?.map((factor) => {
        return { label: factor.name, value: factor.name };
      }),
    };
  }, [allGrowthFactors]);

  const commonQuestionArray = useMemo(() => {
    if (!questions) return [];

    return questions.map((q) => {
      return {
        id: q.id,
        questionText:
          q.questionMain && q.questionMain.length > 0 ? stripHtml(q.questionMain) : stripHtml(q.questionSelf),
        factor: q.factor,
      };
    });
  }, [questions]);

  return (
    <DrawerModal
      isOpen={isQuestionsModalOpen}
      setIsOpen={setIsQuestionsModalOpen}
      onClose={() => {
        setIsQuestionsModalOpen(false);
      }}
      widthPercentage={95}
      sx={{
        px: 0,
      }}
    >
      <GrowthQuestionLibraryContent
        questions={commonQuestionArray}
        setIsQuestionsModalOpen={setIsQuestionsModalOpen}
        action={action}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        allGrowthFactors={allGrowthFactors}
        setFilterString={setFilterString}
        filterString={filterString}
        factorFilterTypes={factorFilterTypes}
        questionsLoading={Boolean(questionsLoading)}
      />
    </DrawerModal>
  );
};
