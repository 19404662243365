import { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_SCHEDULE_CAP, getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { AttendanceDto, AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceShiftDto } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.dto';
import { UserShiftHandlerDrawerContent } from '@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler-drawer-content.component';
import { UserShiftHandlerWidgetContent } from '@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler-widget-content.component';
import { useTimeout } from '@v2/feature/dashboard/features/sections/user-shift/use-timeout.hook';
import {
  endAttendanceBreak,
  endAttendanceShift,
  extractTimeFromShiftEntries,
  getClosestDateForDay,
  getCurrentPosition,
  getDateDayUtil,
  getDistance,
  getHasStartedBreak,
  getRelevantShiftDayFromSchedule,
  getShiftLoggedHours,
  getTheSelectedDaySchedule,
  isInAcceptedProximityUtil,
  isLessThanEarlyCapMinutesUntilStart,
  startAttendanceBreak,
  startAttendanceShift,
} from '@v2/feature/dashboard/features/sections/user-shift/user-shift.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';

import { SiteEndpoints } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';

interface ComponentProps {
  readonly selectedDate?: string;
  readonly refresh: () => Promise<void>;
  readonly shift?: AttendanceShiftDto | null | undefined;
  readonly userSite?: SiteDto | null | undefined;
  readonly currentWeekAttendance: AttendanceDto[];
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly readOnly?: boolean;
  readonly mode: 'widget' | 'drawer';
}

export const UserShiftHandler = ({
  attendanceSchedule,
  shift,
  userSite,
  currentWeekAttendance,
  refresh,
  mode,
  selectedDate = new LocalDate().toDateString(),
  readOnly = false,
}: ComponentProps) => {
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });

  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [isFetchingLocation, setIsFetchingLocation] = useState<boolean>(true);
  const [isStartingShift, setIsStartingShift] = useState<boolean>(false);
  const [isEndingShift, setIsEndingShift] = useState<boolean>(false);
  const [isStartingBreak, setIsStartingBreak] = useState<boolean>(false);
  const [isEndingBreak, setIsEndingBreak] = useState<boolean>(false);

  const [currentPosition, setCurrentPosition] = useState<{
    coords: { longitude: number; latitude: number };
  } | null>(null);

  useTimeout(() => {
    // Chromium based browsers take a few seconds to load location.
    // Show a loader on the start button for 3 seconds (or for less if the location is retrieved faster) so users don't press start before the location is fetched
    // (this happens if the location is allowed, otherwise the location is not available after 3 seconds
    setIsFetchingLocation(false);
  }, 3000);

  useEffect(() => {
    getCurrentPosition(navigator, setIsFetchingLocation, setCurrentPosition);
  }, []);

  const distance = useMemo(() => {
    return getDistance(attendanceSchedule, currentPosition, userSite, sites);
  }, [currentPosition, userSite, sites, attendanceSchedule]);

  const isInAcceptedProximity = useMemo(() => {
    return isInAcceptedProximityUtil(attendanceSchedule, distance);
  }, [distance, attendanceSchedule]);

  const todaysDay = useMemo(() => getDateDayUtil(), []);

  const selectedDay = useMemo(() => getDateDayUtil(selectedDate), [selectedDate]);

  // todays date or existing shift's logDate
  const relevantDay = useMemo(
    () => getRelevantShiftDayFromSchedule(attendanceSchedule, todaysDay, currentWeekAttendance, shift),
    [attendanceSchedule, todaysDay, currentWeekAttendance, shift]
  );

  const relevantDayWeekIndex = useMemo(
    () =>
      getIndexOfWeekInSchedule(
        attendanceSchedule.startDateOfFirstWeek,
        relevantDay.weekDate,
        attendanceSchedule.noOfWeeks
      ),
    [relevantDay, attendanceSchedule]
  );

  const shiftOrScheduledShift = useMemo(() => {
    return Boolean(
      shift ||
        (attendanceSchedule &&
          attendanceSchedule[relevantDay.weekDay] &&
          attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex])
    );
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, shift]);

  const isScheduledForToday = useMemo(() => {
    return Boolean(
      attendanceSchedule &&
        attendanceSchedule[relevantDay.weekDay] &&
        attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex] &&
        selectedDay === relevantDay.weekDay
    );
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, selectedDay]);

  const theFollowingScheduledDay = useMemo(
    () => getTheSelectedDaySchedule(attendanceSchedule, relevantDay.weekDay, relevantDay.weekDate),
    [relevantDay, attendanceSchedule]
  );

  const relevantDate = useMemo(() => {
    if (shift) return new LocalDate(shift.logDate).getDate();

    if (
      attendanceSchedule &&
      attendanceSchedule[relevantDay.weekDay] &&
      attendanceSchedule[relevantDay.weekDay][relevantDayWeekIndex]
    )
      return getClosestDateForDay(relevantDay.weekDay);

    return new LocalDate().getDate();
  }, [relevantDayWeekIndex, attendanceSchedule, relevantDay, shift]);

  const theSelectedDaySchedule = useMemo(
    () => getTheSelectedDaySchedule(attendanceSchedule, selectedDay, selectedDate),
    [selectedDate, selectedDay, attendanceSchedule]
  );

  const isStarted = useMemo(() => Boolean(shift && !shift.isEnded), [shift]);

  const lessThanEarlyCapMinutesUntilStart = useMemo(() => {
    // if not the same date allow as today - should this be kept??
    if (new LocalDate().toDateString() !== new LocalDate(relevantDate).toDateString()) return true;
    // otherwise check if not too early
    return isLessThanEarlyCapMinutesUntilStart(
      attendanceSchedule,
      shiftOrScheduledShift,
      isStarted,
      theFollowingScheduledDay
    );
  }, [shiftOrScheduledShift, theFollowingScheduledDay, isStarted, attendanceSchedule, relevantDate]);

  const breakType = useMemo(() => attendanceSchedule.attendanceTypesAllowed.find((type) => type.name === 'Break'), [
    attendanceSchedule,
  ]);

  const hasStartedBreak = useMemo(() => getHasStartedBreak(shift, breakType), [shift, breakType]);

  const time = useMemo(() => extractTimeFromShiftEntries(shift), [shift]);

  const isEnded = useMemo(() => Boolean(shift?.isEnded), [shift?.isEnded]);

  const startShift = useCallback(async () => {
    if (!currentPosition?.coords && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t('AttendanceDomain.AttendanceShift.couldNotGetLocation'), 'error');
      return;
    }
    setIsStartingShift(true);
    await startAttendanceShift(
      isInAcceptedProximity,
      distance,
      attendanceSchedule.useGeolocation ? currentPosition?.coords.longitude ?? null : null,
      attendanceSchedule.useGeolocation ? currentPosition?.coords.latitude ?? null : null,
      showMessage,
      refresh,
      polyglot,
      todaysDay !== relevantDay.weekDay
    );
    setIsStartingShift(false);
  }, [
    attendanceSchedule.useGeolocation,
    currentPosition,
    isInAcceptedProximity,
    distance,
    showMessage,
    refresh,
    polyglot,
    todaysDay,
    relevantDay,
  ]);

  const endShift = useCallback(async () => {
    if (!currentPosition?.coords && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t('AttendanceDomain.AttendanceShift.couldNotGetLocation'), 'error');
      return;
    }
    setIsEndingShift(true);
    await endAttendanceShift(
      attendanceSchedule.useGeolocation ? currentPosition?.coords.longitude ?? null : null,
      attendanceSchedule.useGeolocation ? currentPosition?.coords.latitude ?? null : null,
      showMessage,
      refresh,
      polyglot,
      todaysDay !== relevantDay.weekDay
    );
    setIsEndingShift(false);
  }, [attendanceSchedule.useGeolocation, currentPosition, refresh, showMessage, polyglot, todaysDay, relevantDay]);

  const startBreak = useCallback(async () => {
    if (!currentPosition?.coords && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t('AttendanceDomain.AttendanceShift.couldNotGetLocation'), 'error');
      return;
    }
    setIsStartingBreak(true);
    await startAttendanceBreak(
      attendanceSchedule.useGeolocation ? currentPosition?.coords.longitude ?? null : null,
      attendanceSchedule.useGeolocation ? currentPosition?.coords.latitude ?? null : null,
      showMessage,
      refresh,
      polyglot
    );
    setIsStartingBreak(false);
  }, [attendanceSchedule.useGeolocation, currentPosition, refresh, showMessage, polyglot]);

  const endBreak = useCallback(async () => {
    if (!currentPosition?.coords && attendanceSchedule.useGeolocation) {
      showMessage(polyglot.t('AttendanceDomain.AttendanceShift.couldNotGetLocation'), 'error');
      return;
    }
    setIsEndingBreak(true);
    await endAttendanceBreak(
      attendanceSchedule.useGeolocation ? currentPosition?.coords.longitude ?? null : null,
      attendanceSchedule.useGeolocation ? currentPosition?.coords.latitude ?? null : null,
      showMessage,
      refresh,
      polyglot
    );
    setIsEndingBreak(false);
  }, [attendanceSchedule.useGeolocation, currentPosition, refresh, showMessage, polyglot]);

  const shiftLoggedHours = useMemo(() => getShiftLoggedHours(theFollowingScheduledDay, polyglot), [
    theFollowingScheduledDay,
    polyglot,
  ]);

  const shiftSelectedLoggedHours = useMemo(() => getShiftLoggedHours(theSelectedDaySchedule, polyglot), [
    polyglot,
    theSelectedDaySchedule,
  ]);

  return mode === 'widget' ? (
    <UserShiftHandlerWidgetContent
      clockInEarlyCapMinutes={attendanceSchedule.clockInEarlyCapMinutes ?? DEFAULT_SCHEDULE_CAP}
      theFollowingScheduledDay={theFollowingScheduledDay}
      isStarted={isStarted}
      hasStartedBreak={hasStartedBreak}
      isEnded={isEnded}
      time={time}
      shiftLoggedHours={shiftLoggedHours}
      isStartingShift={isStartingShift}
      isFetchingLocation={isFetchingLocation}
      lessThanEarlyCapMinutesUntilStart={lessThanEarlyCapMinutesUntilStart}
      isStartingBreak={isStartingBreak}
      startShift={startShift}
      startBreak={startBreak}
      endShift={endShift}
      endBreak={endBreak}
      isEndingBreak={isEndingBreak}
      isEndingShift={isEndingShift}
      relevantDate={relevantDate}
      readOnly={readOnly}
      shiftOrScheduledShift={shiftOrScheduledShift}
    />
  ) : (
    <UserShiftHandlerDrawerContent
      clockInEarlyCapMinutes={attendanceSchedule.clockInEarlyCapMinutes ?? DEFAULT_SCHEDULE_CAP}
      theFollowingScheduledDay={theFollowingScheduledDay}
      isStarted={isStarted}
      hasStartedBreak={hasStartedBreak}
      isEnded={isEnded}
      time={time}
      shiftLoggedHours={shiftLoggedHours}
      isStartingShift={isStartingShift}
      isFetchingLocation={isFetchingLocation}
      lessThanEarlyCapMinutesUntilStart={lessThanEarlyCapMinutesUntilStart}
      isStartingBreak={isStartingBreak}
      startShift={startShift}
      startBreak={startBreak}
      endShift={endShift}
      endBreak={endBreak}
      isEndingBreak={isEndingBreak}
      isEndingShift={isEndingShift}
      isScheduledForToday={isScheduledForToday}
      selectedDate={selectedDate}
      shiftSelectedLoggedHours={shiftSelectedLoggedHours}
    />
  );
};
