import { useState } from 'react';

import { Box } from '@mui/material';
import { BenefitsCompanyOverviewSection } from '@v2/feature/benefits/benefits-company-overview.section';
import { BenefitsCompanyPeoplePage } from '@v2/feature/benefits/benefits-company-people.page';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Benefits } from '@/images/side-bar-icons/Pension.svg';
import { ReactComponent as People } from '@/images/side-bar-icons/Person.svg';
import { SwitchButton } from '@/v2/components/split-switch-button.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';

const ViewSwitcher = ({
  setShowMode,
  showMode,
}: {
  setShowMode: React.Dispatch<React.SetStateAction<'benefits' | 'people'>>;
  showMode: 'benefits' | 'people';
}) => {
  return (
    <Box
      sx={{
        width: '60px',
        height: '30px',
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SwitchButton
        isActive={showMode === 'benefits'}
        color={themeColors.DarkGrey}
        action={() => setShowMode('benefits')}
      >
        <Benefits {...iconSize} fill={showMode === 'benefits' ? themeColors.white : themeColors.DarkGrey} />
      </SwitchButton>

      <SwitchButton isActive={showMode === 'people'} color={themeColors.DarkGrey} action={() => setShowMode('people')}>
        <People {...iconSize} fill={showMode === 'people' ? themeColors.white : themeColors.DarkGrey} />
      </SwitchButton>
    </Box>
  );
};

interface BenefitsOverviewPageProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
  readonly refreshAllBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export function BenefitsOverviewPage({
  pensionSchemes,
  customBenefits,
  insuranceQuote,
  refreshAllBenefits,
  loading,
}: BenefitsOverviewPageProps) {
  const { polyglot } = usePolyglot();

  const [showMode, setShowMode] = useState<'benefits' | 'people'>('benefits');
  return (
    <RootStyle>
      <TopHeader
        title={polyglot.t('BenefitModule.benefits')}
        showAction
        actions={<ViewSwitcher showMode={showMode} setShowMode={setShowMode} />}
      />

      {showMode === 'benefits' && (
        <BenefitsCompanyOverviewSection
          pensionSchemes={pensionSchemes}
          customBenefits={customBenefits}
          insuranceQuote={insuranceQuote}
          refreshAllBenefits={refreshAllBenefits}
          loading={loading}
        />
      )}
      {showMode === 'people' && <BenefitsCompanyPeoplePage />}
    </RootStyle>
  );
}
