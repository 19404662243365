import React, { useContext, useMemo } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Column } from '@/images/table/Column.svg';
import { checkScopes } from '@/lib/scopes';
import { Scope, ScopeContext } from '@/models';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { iconSize } from '@/v2/styles/menu.styles';

export interface ColumnTypeProps {
  name: string;
  value: string;
  scopes?: Scope[];
  context?: ScopeContext;
}

interface HiddenColumnSelectorProps {
  options: ColumnTypeProps[];
  columnAction?: { domain: string; subDomain: string; feature: string };
  hiddenColumns: string[];
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

export const HiddenColumnSelector = ({
  options,
  columnAction,
  hiddenColumns,
  setHiddenColumns,
}: HiddenColumnSelectorProps) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [globalState, dispatch] = useContext(GlobalContext);

  const filterOptions = useMemo(() => {
    return options.filter((option) =>
      !option.scopes || !option.scopes || option.context
        ? checkScopes(globalState.user, option.scopes, option.context)
        : hasScopes(option.scopes, getScopesContext(globalState.user))
    );
  }, [options, globalState.user, hasScopes, getScopesContext]);

  const optionsValuesSet = useMemo(() => new Set(options.map((o) => o.value)), [options]);

  return (
    <StyledMenuComponent
      checkOptions={filterOptions}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      closeOnSelect={false}
      actionButtonDetails={{
        type: 'button',
        sizeVariant: 'small',
        colorVariant: 'secondary',
        title: polyglot.t('HiddenColumnSelector.columns'),
        icon: <Column {...iconSize} />,
        iconPosition: 'start',
      }}
      selectedCheckbox={filterOptions.filter((o) => !hiddenColumns?.includes(o.value)).map((o) => o.value)}
      onCheckboxChange={async (option: { name: string; value: string }) => {
        const hideColumns = (hiddenColumns?.includes(option.value)
          ? hiddenColumns.filter((sm) => sm !== option.value)
          : [...hiddenColumns, option.value]
        ).filter((columnName) => optionsValuesSet.has(columnName));

        setHiddenColumns(hideColumns);

        if (columnAction) {
          const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
            columnAction.domain,
            columnAction.subDomain,
            columnAction.feature,
            hideColumns
          );

          dispatch({
            type: GlobalStateActions.UPDATE_USER,
            payload: updatedGlobalUser,
          });
        }
      }}
    />
  );
};
