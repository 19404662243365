import ReactApexChart from 'react-apexcharts';

import { SeriesData } from '@/v2/feature/app-integration/app-integration.interface';
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import './apex-charts.styles.css';
export interface ChartColumnProps {
  series: Record<string, unknown>[] | number[] | SeriesData[];
  categories: string[];
  horizontal?: boolean;
  stacked?: boolean;
  max?: number;
  percentage?: boolean;
  tickAmount?: number;
  min?: number;
  customTooltip?: ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: {
    series: any;
    seriesIndex: any;
    dataPointIndex: any;
    w: any;
  }) => string;
  anonymousData?: boolean;
  xFormatter?: (value: string | number) => string;
  showEmptyState?: boolean;
}

export function ChartColumnSingle({
  series,
  categories,
  horizontal,
  stacked,
  max,
  percentage = false,
  tickAmount = undefined,
  min,
  customTooltip,
  anonymousData = false,
  xFormatter,
  showEmptyState = true,
}: ChartColumnProps) {
  function isSeriesDataArray(series: any): series is SeriesData[] {
    return Array.isArray(series) && series.length > 0 && 'data' in series[0];
  }
  const allZeroes = isSeriesDataArray(series) ? series.every((s) => s.data.every((val) => val === 0)) : false;

  const yAxisConfig = allZeroes ? { min: 0, max: 1 } : { max: max, min: min };

  const chartOptions = {
    chart: {
      fontFamily: 'Inter, sans-serif !important',
      toolbar: {
        show: false,
      },
      stacked: stacked ?? false,
    },
    colors: [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    ...themeFonts.caption,
    plotOptions: {
      bar: {
        horizontal: horizontal ?? false,
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },

    stroke: {
      show: true,
      width: 6,
      colors: ['transparent'],
    },

    xaxis: {
      type: 'category' as 'category' | 'datetime' | 'numeric' | undefined,
      categories: categories,
      labels: {
        show: categories.length > 0,
        style: {
          colors: Array(categories.length).fill(themeColors.Grey),
          fontFamily: 'Inter, sans-serif !important',
          fontWeight: 400,
          fontSize: '12px',
        },
        formatter: xFormatter,
      },
      min: 0,
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      ...yAxisConfig,
      labels: {
        align: 'left' as 'left' | 'center' | 'right',
        style: {
          fontFamily: 'Inter, sans-serif !important',
          fontWeight: 400,
          fontSize: '12px',
        },
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },

      crosshairs: {
        show: false,
      },
      tickAmount: tickAmount,
    },
    tooltip: {
      enabled: !anonymousData,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: (val: number) => `${val}`,
      },
      style: { fontFamily: 'Inter, sans-serif !important', fontWeight: 400, fontSize: '12px' },
      custom: customTooltip
        ? customTooltip
        : function ({ series, seriesIndex, dataPointIndex }: { series: any; seriesIndex: any; dataPointIndex: any }) {
            return `<div class="arrow_box">${series[seriesIndex][dataPointIndex]}${percentage ? '%' : ''}</div>`;
          },
    },
    legend: {
      show: true,
      horizontalAlign: 'right' as 'left' | 'center' | 'right',
      position: 'top' as 'top' | 'right' | 'bottom' | 'left',
      fontFamily: 'Inter, sans-serif !important',
      fontWeight: 400,
      fontSize: '12px',
      labels: { colors: [themeColors.DarkGrey] },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 16,
        customHTML: undefined,
        onClick: undefined,
      },
    },
    noData: { text: showEmptyState ? 'No data' : undefined },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  };

  return <ReactApexChart type="bar" series={series as ApexAxisChartSeries} options={chartOptions} height="250px" />;
}
