import axios from 'axios';

import {
  EntryDetail,
  ReviewEntry,
  ReviewEntryUpdateAndPeerInvite,
} from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewEntryAPI {
  static async invitePeersByEntry(reviewEntryBody: ReviewEntryUpdateAndPeerInvite): Promise<void> {
    return (await axios.patch(`/apiv2/review-entry/${reviewEntryBody.entry.id}/invite-peers`, reviewEntryBody)).data;
  }
}
export class ReviewEntryEndpoints {
  static getUserEntries(userId: number): Endpoint<ReviewEntry[]> {
    return {
      url: `/apiv2/review-entry/${userId}`,
    };
  }

  static getReviewEntryDetail(entryId: string, cycleId: string): Endpoint<EntryDetail> {
    return {
      url: `/apiv2/review-entry/${entryId}/${cycleId}/detail`,
    };
  }
}
