import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '@v2/components/typography/typography.component';
import { UserImportRouter } from '@v2/feature/user/features/user-import/user-import.router';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { PEOPLE_ME_DIRECTORY_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { EntityImportResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import {
  EntityImportWizardData,
  EntityImportWizardSource,
} from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { EntityImportSuccessPage } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/pages/entity-import-success.page';
import { PayrollImportRouter } from '@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.router';
import { RootStyle } from '@/v2/styles/root.styles';

export const ENTITY_IMPORT_WIZARD_ROUTE_OLD = '/settings/import/wizard';
export const ENTITY_IMPORT_WIZARD_V2_ROUTE = '/settings/v2/import/wizard';
export const ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE = `${ENTITY_IMPORT_WIZARD_ROUTE_OLD}/:entity(users|payroll|devices)/:source(csv|list|google|xero|quickbooks)`;
export const ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE = `${ENTITY_IMPORT_WIZARD_ROUTE_OLD}/success`;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderRadius: 1,
    borderTopWidth: 3,
    borderColor: theme.palette.divider,
  },
}));

export function EntityImportWizardOldRouter(): React.JSX.Element {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const steps = [
    {
      label: polyglot.t('EntityImportWizardRouter.entries'),
      path: ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE,
    },
    {
      label: polyglot.t('EntityImportWizardRouter.import'),
      path: ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE,
    },
  ] as const;

  const matchStepPath = useCallback(
    (path: string) =>
      matchPath<{
        entity: EntityImportWizardData.Users | EntityImportWizardData.Payroll | EntityImportWizardData.Devices;
        source: EntityImportWizardSource;
      }>(routerLocation.pathname, {
        path,
      }),
    [routerLocation.pathname]
  );

  const entityImportData = useMemo(() => {
    const match = matchStepPath(ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE) || { params: { entity: '', source: '' } };
    return match.params?.entity ?? '';
  }, [matchStepPath]);

  const [result, setResult] = useState<EntityImportResultDto>();
  const { pathname } = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    const index = pipe(
      steps,
      RA.findLastIndex((step) => matchStepPath(step.path) !== null),
      O.getOrElse(() => 0)
    );
    setActiveStepIndex(index);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, matchStepPath]);

  const sourceProps = useMemo(
    () => ({
      goBack: () => routerHistory.push(ENTITY_IMPORT_WIZARD_V2_ROUTE),
      goNext: (stepResult: EntityImportResultDto) => {
        setResult(stepResult);
        routerHistory.push(ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE);
      },
    }),
    [routerHistory]
  );

  const EntityImportRouter = useMemo(() => {
    if (!entityImportData) return undefined;
    if (entityImportData === 'payroll') return () => PayrollImportRouter(sourceProps);
    if (entityImportData === 'users') return () => UserImportRouter(sourceProps);
    return undefined;
  }, [entityImportData, sourceProps]);

  const afterSuccessRoute = entityImportData !== '' ? PEOPLE_ME_DIRECTORY_ROUTE : '';

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">{polyglot.t('EntityImportWizardRouter.import')}</Typography>} />
      <ContentWrapper loading={loading}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={9} lg={8} xl={8}>
            <Stepper alternativeLabel activeStep={activeStepIndex} connector={<QontoConnector />}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>
                    <Typography variant="caption">{step.label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>

        <Box>
          <Switch>
            <Route path={ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE} exact>
              {entityImportData !== '' && EntityImportRouter}
            </Route>
            <Route path={ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE} exact>
              <EntityImportSuccessPage
                goNext={() => {
                  routerHistory.push(afterSuccessRoute);
                }}
                result={result}
              />
            </Route>
            <Redirect from={ENTITY_IMPORT_WIZARD_ROUTE_OLD} to={ENTITY_IMPORT_WIZARD_V2_ROUTE} />
          </Switch>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
}
