import { Box, LinearProgress } from '@mui/material';

import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const LoadingStateComponent = ({ showDivider }: { showDivider: boolean }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        borderBottom: showDivider ? borders.background : 'none',
        py: spacing.p30,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          sx={{
            height: 4,
            width: '100%',
            mr: 2,
            backgroundColor: themeColors.TableHover,
            '& .MuiLinearProgress-bar': {
              backgroundColor: themeColors.GreyMiddle,
            },
          }}
        />
      </Box>
    </Box>
  );
};
