import { useMemo } from 'react';

import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import { getUKTaxYear } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type StarterDeclarationChoiceProps = {
  declaration?: StarterDeclarationLetter;
  setDeclaration: (value: StarterDeclarationLetter) => void;
  disabled?: boolean;
};

export const StarterDeclarationChoice = ({ declaration, setDeclaration, disabled }: StarterDeclarationChoiceProps) => {
  const { polyglot } = usePolyglot();
  const taxYear = useMemo(() => getUKTaxYear().start.getFullYear(), []);

  return (
    <Stack>
      <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
        {polyglot.t('OnboardingByUser.whichSituationsAppliesToYou')}
      </Typography>
      <FormControl sx={{ mt: spacing.mt20 }}>
        <RadioGroup
          onChange={(event) => {
            setDeclaration(event.target.value as StarterDeclarationLetter);
          }}
          value={declaration || ''}
          sx={{ gap: spacing.g20 }}
        >
          <FormControlLabel
            labelPlacement="end"
            value={'A'}
            control={<StyledRadio />}
            label={
              <Typography sx={{ ...themeFonts.caption }}>
                {polyglot.t('OnboardingByUser.thisIsYourFirstJob', { taxYearStartDate: `6 April ${taxYear}` })}
              </Typography>
            }
            disabled={disabled}
            sx={{ alignItems: 'start' }}
          />
          <FormControlLabel
            labelPlacement="end"
            value={'B'}
            control={<StyledRadio />}
            label={
              <Typography sx={{ ...themeFonts.caption }}>
                {polyglot.t('OnboardingByUser.thisIsYourOnlyJob', { taxYearStartDate: `6 April ${taxYear}` })}
              </Typography>
            }
            disabled={disabled}
            sx={{ alignItems: 'start' }}
          />
          <FormControlLabel
            labelPlacement="end"
            value={'C'}
            control={<StyledRadio />}
            label={
              <Typography sx={{ ...themeFonts.caption }}>{polyglot.t('OnboardingByUser.youGotAnotherJob')}</Typography>
            }
            disabled={disabled}
            sx={{ alignItems: 'start' }}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
