import { useContext, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Box, Menu, MenuItem, MenuProps, PopoverOrigin, SxProps, Theme } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';

import { ButtonColorVariant, ButtonComponent, ButtonSizeVariant } from '../forms/button.component';

import { GlobalContext } from '@/GlobalState';
import { checkScopes } from '@/lib/scopes';
import { Scope, ScopeContext } from '@/models';
import { IconButton, IconButtonColorVariant, IconButtonSizeVariant } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 20px 0px rgba(13, 13, 14, 0.1)',
    textAlign: 'left',
    borderRadius: radius.br10,
    '& .MuiMenu-list': {
      ...spacing.pad20,
      gap: '50px',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      padding: '0px',
      minHeight: '20px',
      gap: spacing.g10,
      ...themeFonts.caption,
      fill: themeColors.DarkGrey,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        color: themeColors.Grey,
        fill: themeColors.Grey,
      },
    },
  },
}));

export interface OptionProps {
  icon?: React.JSX.Element;
  handler: (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  label: string | React.JSX.Element;
  disabled?: boolean;
  hidden?: boolean;
  scopes?: Scope[];
  context?: ScopeContext;
}

export interface ActionButtonProps {
  type: 'iconButton' | 'button';
  style?: React.CSSProperties | undefined;
  sizeVariant?: IconButtonSizeVariant | ButtonSizeVariant;
  colorVariant?: IconButtonColorVariant | ButtonColorVariant;
  title: string;
  icon?: JSX.Element;
  iconPosition?: 'start' | 'end';
  fullWidth?: boolean;
}

export interface HeaderOptionsProps {
  title: string;
  hidden?: boolean;
  options: OptionProps[];
}

interface Props {
  readonly options?: readonly OptionProps[];
  readonly checkOptions?: readonly {
    name: string;
    value: string;
  }[];
  readonly actionButtonDetails: ActionButtonProps;
  readonly headerOptions?: readonly HeaderOptionsProps[];
  readonly anchorOrigin?: PopoverOrigin;
  readonly transformOrigin?: PopoverOrigin;
  readonly disabled?: boolean;
  readonly sx?: SxProps<Theme>;
  readonly selectedCheckbox?: readonly string[];
  readonly onCheckboxChange?: (option: { name: string; value: string }) => void;
  readonly closeOnSelect?: boolean;
  readonly onClose?: () => Promise<void>;
  readonly className?: string;
}

export const StyledMenuComponent = ({
  options,
  checkOptions,
  actionButtonDetails,
  headerOptions,
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  transformOrigin = { horizontal: 'right', vertical: 'top' },
  disabled,
  sx,
  selectedCheckbox = [],
  onCheckboxChange,
  closeOnSelect = true,
  onClose = undefined,
  className = undefined,
}: Props) => {
  const [state] = useContext(GlobalContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filteredHeaderOption = useMemo(() => {
    return headerOptions?.filter((o) => o.options.length > 0 && !o.hidden) ?? [];
  }, [headerOptions]);

  const open = Boolean(anchorEl);
  const getActionButton = (actionButtonDetails: ActionButtonProps) => {
    if (actionButtonDetails.type === 'iconButton') {
      return (
        <IconButton
          id={`btn${actionButtonDetails.title}`}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
          }}
          sizeVariant={(actionButtonDetails.sizeVariant as IconButtonSizeVariant) ?? 'small'}
          colorVariant={(actionButtonDetails.colorVariant as IconButtonColorVariant) ?? 'primary'}
          title={actionButtonDetails.title}
          disabled={disabled}
        >
          {actionButtonDetails.icon}
        </IconButton>
      );
    } else {
      return (
        <ButtonComponent
          disabled={disabled}
          style={actionButtonDetails.style}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
          }}
          sizeVariant={(actionButtonDetails.sizeVariant as ButtonSizeVariant) ?? 'medium'}
          colorVariant={(actionButtonDetails.colorVariant as ButtonColorVariant) ?? 'primary'}
          fullWidth={actionButtonDetails.fullWidth ?? false}
        >
          {actionButtonDetails.icon && actionButtonDetails.iconPosition !== 'end' && (
            <>{actionButtonDetails.icon}&nbsp;</>
          )}
          {actionButtonDetails.title}
          {actionButtonDetails.icon && actionButtonDetails.iconPosition === 'end' && (
            <>&nbsp;{actionButtonDetails.icon}</>
          )}
        </ButtonComponent>
      );
    }
  };

  const hasVisibleActions = useMemo(() => {
    const visibleHeaders = headerOptions?.filter((ho) => !ho.hidden) ?? [];
    const hasVisibleHeadersOptions = visibleHeaders.filter((ho) => ho.options.some((v) => !v.hidden));
    const hasVisibleOptions = options?.some((o) => !o.hidden);

    return Boolean(hasVisibleHeadersOptions || hasVisibleOptions);
  }, [options, headerOptions]);

  return hasVisibleActions ? (
    <Box sx={sx} onClick={(e) => e.stopPropagation()} className={className}>
      {getActionButton(actionButtonDetails)}
      <StyledMenu
        id={`btnMenuOptions`}
        MenuListProps={{
          'aria-labelledby': 'btnMenuOptions',
        }}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={async () => {
          setAnchorEl(null);
          if (onClose) await onClose();
        }}
      >
        {filteredHeaderOption.map((headerOption, i) => {
          const visibleOptions = headerOption.options.filter((o) => !o.hidden);
          return (
            <Box key={headerOption.title} sx={{ marginTop: i > 0 && visibleOptions.length > 0 ? '20px' : 0 }}>
              {visibleOptions.length > 0 && (
                <Typography variant="caption" color="Grey">
                  {headerOption.title}
                </Typography>
              )}
              {visibleOptions.map((option, idx) => (
                <MenuItem
                  key={idx}
                  onClick={(e) => {
                    option.handler();
                    if (closeOnSelect) setAnchorEl(null);
                    e.stopPropagation();
                  }}
                  disableRipple
                  disabled={option?.disabled || false}
                  sx={{ marginTop: '10px' }}
                >
                  {option.icon}
                  {option.label}
                </MenuItem>
              ))}
            </Box>
          );
        })}
        {options &&
          options
            .filter((option) => checkScopes(state.user, option.scopes, option.context) && !option.hidden)
            .map((option, i) => (
              <MenuItem
                key={i}
                id={`btn${option.label}_${i}`}
                onClick={(e) => {
                  option.handler(e);
                  if (closeOnSelect) setAnchorEl(null);
                  e.stopPropagation();
                }}
                disableRipple
                disabled={option?.disabled || false}
                sx={{ marginTop: i > 0 ? '10px' : 0 }}
              >
                {option.icon}
                {option.label}
              </MenuItem>
            ))}

        {checkOptions &&
          checkOptions.map((option, i) => (
            <MenuItem
              key={option.name}
              id={`check-${option.name}_${i}`}
              disableRipple
              sx={{ marginTop: i > 0 ? '10px' : 0 }}
            >
              <CheckboxComponent
                label={option.name}
                name={option.name}
                value={option.value}
                checked={selectedCheckbox?.includes(option.value)}
                onChange={(e) => {
                  onCheckboxChange?.(option);
                  if (closeOnSelect) setAnchorEl(null);
                  e.stopPropagation();
                }}
              />
            </MenuItem>
          ))}
      </StyledMenu>
    </Box>
  ) : null;
};
