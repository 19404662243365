import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { HiddenColumnSelector } from '@v2/components/table/hidden-column-selector.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import {
  UserInsuranceDependants,
  UserInsurancePolicyStatus,
} from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import Polyglot from 'node-polyglot';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const getDependantsToLabel = (dependants: UserInsuranceDependants | null, polyglot: Polyglot): string => {
  if (dependants === UserInsuranceDependants.Children) return polyglot.t('UserInsuranceDependants.Children');
  if (dependants === UserInsuranceDependants.Family) return polyglot.t('UserInsuranceDependants.Family');
  if (dependants === UserInsuranceDependants.SpousePartner) return polyglot.t('UserInsuranceDependants.SpousePartner');

  return '';
};

interface TableRowData {
  userId: number;
  name: string;
  pension: {
    id: string | null;
    displayName: string | null;
    state: string | null;
    employerContribution: number | null;
    employeeContribution: number | null;
  } | null;
  insurance: {
    providerName: string | null;
    status: UserInsurancePolicyStatus | null;
    monthlyPremium: number | null;
    monthlyContribution: number | null;
    currency: CurrencyShort;
    dependants: UserInsuranceDependants | null;
    dependantsMonthlyPremium: number | null;
    dependantsMonthlyContribution: number | null;
    dependantsStatus: UserInsurancePolicyStatus | null;
  };
  customBenefits: {
    id: number;
    name: string;
    memberPremium: number | null;
    memberContribution: number | null;
    currency: CurrencyShort;
    dependants: UserInsuranceDependants | null;
  }[];
  entityName: string | null;
}

export const BenefitsCompanyPeoplePage = () => {
  const { polyglot } = usePolyglot();
  const { data: tableData, isValidating } = useApiClient(UserEndpoints.getUserBenefitsPeopleData(), {
    suspense: false,
  });

  const [state] = useContext(GlobalContext);

  const [searchInput, setSearchInput] = useState<string>('');
  const [showMessage] = useMessage();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  useEffect(() => {
    setHiddenColumns(state.user.features?.benefits?.peopleTable?.hiddenColumns ?? []);
  }, [state]);

  const allColumnsOptions = useMemo(() => {
    const options = [{ name: 'Name', value: 'userName' }];

    const pensionColumns = (tableData?.tableStructure.pensionSchemes ?? []).flatMap((p) => [
      {
        name: p.displayName ?? '',
        value: `pensionScheme${p.id}`,
      },
      {
        name: polyglot.t('BenefitsCompanyPeoplePage.employerContribution'),
        value: `pensionEmployerContrib${p.id}`,
      },
      {
        name: polyglot.t('BenefitsCompanyPeoplePage.employeeContribution'),
        value: `pensionEmployeeContrib${p.id}`,
      },
    ]);
    options.push(...pensionColumns);

    const insuranceColumns = tableData?.tableStructure.insurance
      ? [
          { name: tableData.tableStructure.insurance.providerName, value: 'insurance' },
          { name: polyglot.t('BenefitsCompanyPeoplePage.monthlyPremium'), value: 'insuranceMonthlyPremium' },
          { name: polyglot.t('BenefitsCompanyPeoplePage.monthlyContribution'), value: 'insuranceMonthlyContribution' },
          { name: polyglot.t('BenefitsCompanyPeoplePage.dependants'), value: 'insuranceDependants' },
        ]
      : [];
    options.push(...insuranceColumns);

    const benefitsColumns = (tableData?.tableStructure.customBenefits ?? []).flatMap((cB) => [
      { name: cB.name ?? 'Benefit', value: `otherBenefit${cB.id}` },
      { name: polyglot.t('BenefitsCompanyPeoplePage.memberPremium'), value: `benefitPremium${cB.id}` },
      { name: polyglot.t('BenefitsCompanyPeoplePage.memberContribution'), value: `benefitContrib${cB.id}` },
      { name: polyglot.t('BenefitsCompanyPeoplePage.dependants'), value: `benefitDependants${cB.id}` },
    ]);

    options.push(...benefitsColumns);
    options.push({ name: 'Entity', value: 'entityId' });

    return options;
  }, [tableData, polyglot]);

  const tableColumns = useMemo<ColumnDef<TableRowData, TableRowData>[]>(() => {
    const pensionColumns =
      tableData?.tableStructure.pensionSchemes.flatMap((p) => [
        {
          id: `pensionScheme${p.id}`,
          header: () => p.displayName ?? '',
          minSize: 150,
          enableSorting: false,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) =>
            original.pension?.id && original.pension.id === p.externalId && original.pension.state ? (
              <Typography sx={themeFonts.caption}>{original.pension.state}</Typography>
            ) : (
              <EmptyCell />
            ),
        } as ColumnDef<TableRowData, TableRowData>,
        {
          id: `pensionEmployerContrib${p.id}`,
          header: () => polyglot.t('BenefitsCompanyPeoplePage.employerContribution'),
          enableSorting: false,
          minSize: 100,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) =>
            original.pension?.id &&
            original.pension.id === p.externalId &&
            original.pension.employerContribution !== null ? (
              <Typography sx={themeFonts.caption}>{original.pension.employerContribution}%</Typography>
            ) : (
              <EmptyCell />
            ),
        } as ColumnDef<TableRowData, TableRowData>,
        {
          id: `pensionEmployeeContrib${p.id}`,
          header: () => polyglot.t('BenefitsCompanyPeoplePage.employeeContribution'),
          enableSorting: false,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) =>
            original.pension?.id &&
            original.pension.id === p.externalId &&
            original.pension.employeeContribution !== null ? (
              <Typography sx={themeFonts.caption}>{original.pension.employeeContribution}%</Typography>
            ) : (
              <EmptyCell />
            ),
        } as ColumnDef<TableRowData, TableRowData>,
      ]) ?? ([] as ColumnDef<TableRowData, TableRowData>[]);

    const insurance = tableData?.tableStructure.insurance;
    const insuranceColumns = insurance
      ? [
          {
            id: 'insurance',
            header: () => insurance.providerName,
            enableSorting: false,
            minSize: 150,
            accessorFn: (row) => row,
            cell: ({ row: { original } }) =>
              original.insurance.status ? (
                <Typography sx={themeFonts.caption}>{original.insurance.status}</Typography>
              ) : (
                <EmptyCell />
              ),
          } as ColumnDef<TableRowData, TableRowData>,
          {
            id: 'insuranceMonthlyPremium',
            header: () => polyglot.t('BenefitsCompanyPeoplePage.monthlyPremium'),
            enableSorting: false,
            minSize: 100,
            accessorFn: (row) => row,
            cell: ({ row: { original } }) =>
              original.insurance.monthlyPremium ? (
                <Typography sx={themeFonts.caption}>
                  {formatMoney({
                    amount: original.insurance.monthlyPremium,
                    currency: original.insurance.currency,
                  })}
                </Typography>
              ) : (
                <EmptyCell />
              ),
          } as ColumnDef<TableRowData, TableRowData>,
          {
            id: 'insuranceMonthlyContribution',
            header: () => polyglot.t('BenefitsCompanyPeoplePage.monthlyContribution'),
            enableSorting: false,
            minSize: 100,
            accessorFn: (row) => row,
            cell: ({ row: { original } }) =>
              original.insurance.monthlyContribution ? (
                <Typography sx={themeFonts.caption}>
                  {formatMoney({
                    amount: original.insurance.monthlyContribution,
                    currency: original.insurance.currency,
                  })}
                </Typography>
              ) : (
                <EmptyCell />
              ),
          } as ColumnDef<TableRowData, TableRowData>,
          {
            id: 'insuranceDependants',
            header: () => polyglot.t('BenefitsCompanyPeoplePage.dependants'),
            enableSorting: false,
            minSize: 150,
            accessorFn: (row) => row,
            cell: ({ row: { original } }) =>
              original.insurance.dependants ? (
                <Typography sx={themeFonts.caption}>
                  {getDependantsToLabel(original.insurance.dependants, polyglot)}
                </Typography>
              ) : (
                <EmptyCell />
              ),
          } as ColumnDef<TableRowData, TableRowData>,
        ]
      : [];

    const customBenefitsColumns =
      tableData?.tableStructure.customBenefits.flatMap((cB) => [
        {
          id: `otherBenefit${cB.id}`,
          header: () => cB.name ?? '',
          enableSorting: false,
          minSize: 150,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) => {
            const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
            return benefit ? (
              <Typography sx={themeFonts.caption}>{polyglot.t('BenefitsCompanyPeoplePage.member')}</Typography>
            ) : (
              <EmptyCell />
            );
          },
        } as ColumnDef<TableRowData, TableRowData>,
        {
          id: `benefitPremium${cB.id}`,
          header: () => polyglot.t('BenefitsCompanyPeoplePage.memberPremium'),
          enableSorting: false,
          minSize: 100,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) => {
            const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
            return benefit?.memberPremium ? (
              <Typography sx={themeFonts.caption}>
                {formatMoney({
                  amount: benefit.memberPremium,
                  currency: benefit.currency,
                })}
              </Typography>
            ) : (
              <EmptyCell />
            );
          },
        } as ColumnDef<TableRowData, TableRowData>,
        {
          id: `benefitContrib${cB.id}`,
          header: () => polyglot.t('BenefitsCompanyPeoplePage.memberContribution'),
          enableSorting: false,
          minSize: 100,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) => {
            const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
            return benefit?.memberContribution ? (
              <Typography sx={themeFonts.caption}>
                {formatMoney({
                  amount: benefit.memberContribution,
                  currency: benefit.currency,
                })}
              </Typography>
            ) : (
              <EmptyCell />
            );
          },
        } as ColumnDef<TableRowData, TableRowData>,
        {
          id: `benefitDependants${cB.id}`,
          header: () => polyglot.t('BenefitsCompanyPeoplePage.dependants'),
          enableSorting: false,
          minSize: 150,
          accessorFn: (row) => row,
          cell: ({ row: { original } }) => {
            const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
            return benefit?.dependants ? (
              <Typography sx={themeFonts.caption}>{getDependantsToLabel(benefit.dependants, polyglot)}</Typography>
            ) : (
              <EmptyCell />
            );
          },
        } as ColumnDef<TableRowData, TableRowData>,
      ]) ?? ([] as ColumnDef<TableRowData, TableRowData>[]);

    return [
      {
        id: 'userName',
        header: () => polyglot.t('BenefitsCompanyPeoplePage.name'),
        enableSorting: false,
        minSize: 200,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{polyglot.t(original.name)}</Typography>,
      },
      ...pensionColumns,
      ...insuranceColumns,
      ...customBenefitsColumns,
      {
        id: 'entityId',
        header: () => polyglot.t('BenefitsCompanyPeoplePage.entity'),
        minSize: 150,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{polyglot.t(original.entityName ?? '')}</Typography>
        ),
      },
    ];
  }, [tableData, polyglot]);

  const filteredColumns = useMemo(() => {
    return tableColumns.filter((column) => !hiddenColumns.includes(column.id!));
  }, [hiddenColumns, tableColumns]);

  const tableRows: TableRowData[] = useMemo(() => {
    if (!tableData) return [];

    const data = tableData.usersData.map((u) => {
      const pensionScheme =
        !u.pension?.inPension || !u.pension.pensionProviderId
          ? null
          : tableData.tableStructure.pensionSchemes.find((p) => p.externalId === u.pension?.pensionProviderId);

      const pension =
        u.pension && pensionScheme
          ? {
              id: pensionScheme.externalId,
              displayName: pensionScheme.displayName ?? null,
              state: u.pension.state,
              employerContribution: u.pension.employerContribution,
              employeeContribution: u.pension.employeeContribution,
            }
          : null;

      const insurance = {
        providerName: tableData.tableStructure.insurance?.providerName ?? null,
        status: u.insurance?.status ?? null,
        monthlyPremium: u.insurance?.monthlyPremium ?? null,
        monthlyContribution: u.insurance?.monthlyContribution ?? null,
        dependants: u.insurance?.dependants ?? null,
        dependantsMonthlyPremium: u.insurance?.dependantsMonthlyPremium ?? null,
        dependantsMonthlyContribution: u.insurance?.dependantsMonthlyContribution ?? null,
        dependantsStatus: u.insurance?.dependantsStatus ?? null,
        currency: u.insurance?.currency ?? 'GBP',
      };

      const customBenefits = u.customBenefits.reduce(
        (result, uB) => {
          const benefit = tableData.tableStructure.customBenefits.find((cB) => cB.id === uB.customBenefitId);
          if (benefit)
            result.push({
              id: benefit.id,
              name: benefit.name,
              memberPremium: uB.employerContribution,
              memberContribution: uB.employeeContribution,
              dependants: uB.dependants,
              currency: uB.currency,
            });

          return result;
        },
        [] as {
          id: number;
          name: string;
          memberPremium: number | null;
          memberContribution: number | null;
          dependants: UserInsuranceDependants | null;
          currency: CurrencyShort;
        }[]
      );

      return {
        userId: u.userId,
        name: u.name,
        pension,
        insurance,
        customBenefits,
        entityName: u.entityName,
      };
    });

    if (!searchInput) return data;

    const search = searchInput.toLowerCase();
    return data.filter((d) => d.name.toLowerCase().includes(search));
  }, [tableData, searchInput]);

  const getExportData = useCallback((): Datas => {
    if (!tableData) return [];
    try {
      const header: string[] = [polyglot.t('BenefitsCompanyPeoplePage.userId'), 'Name'];
      for (const pS of tableData.tableStructure.pensionSchemes ?? [])
        if (pS.displayName)
          header.push(
            pS.displayName,
            polyglot.t('BenefitsCompanyPeoplePage.startDate'),
            polyglot.t('BenefitsCompanyPeoplePage.employerContribution'),
            polyglot.t('BenefitsCompanyPeoplePage.employeeContribution')
          );

      if (tableData.tableStructure.insurance?.providerName)
        header.push(
          tableData.tableStructure.insurance.providerName,
          polyglot.t('BenefitsCompanyPeoplePage.startDate'),
          polyglot.t('BenefitsCompanyPeoplePage.endDate'),
          polyglot.t('BenefitsCompanyPeoplePage.monthlyPremium'),
          polyglot.t('BenefitsCompanyPeoplePage.monthlyContribution'),
          polyglot.t('BenefitsCompanyPeoplePage.dependants')
        );

      for (const cB of tableData.tableStructure.customBenefits ?? [])
        if (cB.name)
          header.push(
            cB.name,
            // polyglot.t('BenefitsCompanyPeoplePage.startDate'),
            // polyglot.t('BenefitsCompanyPeoplePage.endDate'),
            polyglot.t('BenefitsCompanyPeoplePage.memberPremium'),
            polyglot.t('BenefitsCompanyPeoplePage.memberContribution'),
            polyglot.t('BenefitsCompanyPeoplePage.dependants')
          );

      header.push('Entity');

      const userRows: string[][] =
        tableData.usersData.map((userData): string[] => {
          const csvRow = [String(userData.userId), userData.name];

          for (const pS of tableData?.tableStructure.pensionSchemes ?? []) {
            if (!pS.displayName) continue;
            const isUserMemberOfThis =
              userData.pension?.pensionProviderId && pS.externalId === userData.pension.pensionProviderId;

            csvRow.push(
              isUserMemberOfThis ? userData.pension?.state ?? polyglot.t('BenefitsCompanyPeoplePage.member') : '',
              userData.pension?.startDate ?? '',
              userData.pension?.employerContribution ? `${userData.pension.employerContribution}%` : '',
              userData.pension?.employeeContribution ? `${userData.pension.employeeContribution}%` : ''
            );
          }
          if (tableData.tableStructure.insurance?.providerName)
            csvRow.push(
              userData.insurance?.status ? userData.insurance.status : '',
              userData.insurance?.startDate ?? '',
              userData.insurance?.endDate ?? '',
              userData.insurance?.monthlyPremium ? `£${userData.insurance.monthlyPremium}` : '',
              userData.insurance?.monthlyContribution ? `£${userData.insurance.monthlyContribution}` : '',
              userData.insurance?.dependants ? getDependantsToLabel(userData.insurance.dependants, polyglot) : ''
            );

          for (const cB of tableData.tableStructure.customBenefits ?? []) {
            if (!cB.name) continue;

            const isUserMemberOfThis = userData?.customBenefits.find((uB) => uB.customBenefitId === cB.id);
            csvRow.push(
              isUserMemberOfThis ? polyglot.t('BenefitsCompanyPeoplePage.member') : '',
              isUserMemberOfThis?.employerContribution ? `${isUserMemberOfThis.employerContribution}` : '',
              isUserMemberOfThis?.employeeContribution ? `${isUserMemberOfThis.employeeContribution}` : '',
              isUserMemberOfThis?.dependants ? getDependantsToLabel(isUserMemberOfThis.dependants, polyglot) : ''
            );
          }

          csvRow.push(userData.entityName ?? '');
          return csvRow;
        }) ?? [];

      return [header, ...userRows];
    } catch (error) {
      showMessage(polyglot.t('BenefitsCompanyPeoplePage.error', { nestError: nestErrorMessage(error) }), 'error');
      return [];
    }
  }, [tableData, showMessage, polyglot]);

  return (
    <RootStyle>
      <ContentWrapper loading={isValidating}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: spacing.g5 }}>
            <HiddenColumnSelector
              options={allColumnsOptions}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
              columnAction={{ domain: 'benefits', subDomain: 'peopleTable', feature: 'hiddenColumns' }}
            />
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </Box>
          <CsvDownloader filename="people-benefits" separator="," datas={getExportData}>
            <ButtonComponent colorVariant="secondary" sizeVariant="small">
              {polyglot.t('General.export')}
            </ButtonComponent>
          </CsvDownloader>
        </Box>

        <Box sx={{ mt: spacing.mt20 }}>
          {!isValidating && tableData && (
            <BasicTable<TableRowData>
              rowData={tableRows}
              columnData={filteredColumns}
              fixedLastColumn={false}
              hiddenColumns={hiddenColumns}
            />
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
