import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import { Action, GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ChartBoxPlot } from '@/v2/components/charts/chart-box-plot.component';
import { ChartColumnSingle } from '@/v2/components/charts/chart-column-single.component';
import { chartBoxSx, equalChartSx } from '@/v2/components/charts/styles.layout';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { FiltersDrawer, FiltersOption } from '@/v2/components/table/filters-drawer.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AnalyticEndpoints } from '@/v2/feature/analytics/analytics.api';
import { QuartileProperties } from '@/v2/feature/analytics/analytics.interface';
import {
  ContentWrapper,
  Loader,
} from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { FiltersEndpoints } from '@/v2/feature/filters/filters.api';
import { DEFAULT_LIFECYCLE_STATUS_FILTER } from '@/v2/feature/user/user-directory.interface';
import { UserAPI } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { isDefined } from '@/v2/util/string.util';

export const PayrollAnalyticsPage = () => {
  const { polyglot } = usePolyglot();
  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('AnalyticsInsightsPeoplePage.insights')}
          </Typography>
        }
      />
      <ContentWrapper loading={false}>
        <Suspense
          fallback={
            <Loader loading={true}>
              <></>
            </Loader>
          }
        >
          <PayrollAnalyticsDataLayer />
        </Suspense>
      </ContentWrapper>
    </RootStyle>
  );
};

const PayrollAnalyticsDataLayer = () => {
  const [state, dispatch] = useContext(GlobalContext);
  const { user: currentUser } = state;

  const currentsalaryBandFilterString = isDefined(currentUser.features?.insights?.salaryBand?.filters)
    ? currentUser.features?.insights?.salaryBand?.filters
    : null;

  const currentgenderPayGapFilterString = isDefined(currentUser.features?.insights?.genderPayGap?.filters)
    ? currentUser.features?.insights?.genderPayGap?.filters
    : null;

  const currentsalaryChangeFilterString = isDefined(currentUser.features?.insights?.salaryChange?.filters)
    ? currentUser.features?.insights?.salaryChange?.filters
    : null;

  const [salaryBandFilterString, setSalaryBandFilterString] = useState<string>(
    currentsalaryBandFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER
  );

  const [genderPayGapFilterString, setGenderPayGapFilterString] = useState<string>(
    currentgenderPayGapFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER
  );

  const [salaryChangeFilterString, setsalaryChangeFilterString] = useState<string>(
    currentsalaryChangeFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER
  );
  const { data: filtersConfig } = useApiClient(FiltersEndpoints.getReportsSalaryFilters());

  return (
    <PayrollAnalyticsLayout
      filtersConfig={filtersConfig}
      setSalaryBandFilterString={setSalaryBandFilterString}
      salaryBandFilterString={salaryBandFilterString}
      setGenderPayGapFilterString={setGenderPayGapFilterString}
      setsalaryChangeFilterString={setsalaryChangeFilterString}
      dispatch={dispatch}
      genderPayGapFilterString={genderPayGapFilterString}
      salaryChangeFilterString={salaryChangeFilterString}
    />
  );
};

interface PayrollAnalyticsLayoutProps {
  filtersConfig:
    | {
        filterOptions: FiltersOption;
      }
    | null
    | undefined;
  setSalaryBandFilterString: React.Dispatch<React.SetStateAction<string>>;
  salaryBandFilterString: string;
  setGenderPayGapFilterString: React.Dispatch<React.SetStateAction<string>>;
  genderPayGapFilterString: string;
  setsalaryChangeFilterString: React.Dispatch<React.SetStateAction<string>>;
  salaryChangeFilterString: string;
  dispatch: React.Dispatch<Action>;
}

const PayrollAnalyticsLayout = ({
  filtersConfig,
  setSalaryBandFilterString,
  salaryBandFilterString,
  setGenderPayGapFilterString,
  genderPayGapFilterString,
  setsalaryChangeFilterString,
  salaryChangeFilterString,
  dispatch,
}: PayrollAnalyticsLayoutProps) => {
  const { trackPage } = useJune();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  useEffect(() => {
    trackPage('Company payroll analytics');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserFeaturesSelectedsetGenderPayGapFilters = useCallback(
    async (filterString: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'insights',
          'salaryBand',
          'filters',
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  const updateUserFeaturesSelectedsetSalaryChangeFilters = useCallback(
    async (filterString: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'insights',
          'salaryChange',
          'filters',
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  const updateUserFeaturesSelectedSalaryBandFilters = useCallback(
    async (filterString: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'insights',
          'salaryBand',
          'filters',
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );
  return (
    <>
      <Box sx={chartBoxSx}>
        <Box sx={equalChartSx}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.genderPayGap')}
            </Typography>

            <FiltersDrawer
              filtersOptions={[{ filters: filtersConfig?.filterOptions ?? {} }]}
              selectedFilters={genderPayGapFilterString}
              setSelectedFilters={setGenderPayGapFilterString}
              onApply={updateUserFeaturesSelectedsetGenderPayGapFilters}
            />
          </Box>
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                height="230px"
                width="100%"
                sx={{ background: themeColors.Background, mt: spacing.m10 }}
              />
            }
          >
            <GenderPayGapComponent genderPayGapFilterString={genderPayGapFilterString} />
          </Suspense>
        </Box>
        <Box sx={equalChartSx}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('AnalyticsInsightsPeoplePage.lastSalaryChange')}
            </Typography>

            <FiltersDrawer
              filtersOptions={[{ filters: filtersConfig?.filterOptions ?? {} }]}
              selectedFilters={salaryChangeFilterString}
              setSelectedFilters={setsalaryChangeFilterString}
              onApply={updateUserFeaturesSelectedsetSalaryChangeFilters}
            />
          </Box>
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                height="230px"
                width="100%"
                sx={{ background: themeColors.Background, mt: spacing.m10 }}
              />
            }
          >
            <LastSalaryChange salaryChangeFilterString={salaryChangeFilterString} />
          </Suspense>
        </Box>
      </Box>
      <Box sx={spacing.mt20}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="caption" sx={{ color: themeColors.Grey }}>
            {polyglot.t('AnalyticsInsightsPeoplePage.salaryBands')}
          </Typography>

          <FiltersDrawer
            filtersOptions={[{ filters: filtersConfig?.filterOptions ?? {} }]}
            selectedFilters={salaryBandFilterString}
            setSelectedFilters={setSalaryBandFilterString}
            onApply={updateUserFeaturesSelectedSalaryBandFilters}
          />
        </Box>
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              height="230px"
              width="100%"
              sx={{ background: themeColors.Background, mt: spacing.m10 }}
            />
          }
        >
          <SalaryBandComponent salaryBandFilterString={salaryBandFilterString} />
        </Suspense>
      </Box>
    </>
  );
};

const SalaryBandComponent = ({ salaryBandFilterString }: { salaryBandFilterString: string }) => {
  const { polyglot } = usePolyglot();
  const { data: salaryBand } = useApiClient(AnalyticEndpoints.getSalaryBand(salaryBandFilterString));

  const [salaryBandCandleStick, salaryBandCategories] = useMemo(() => {
    const bandSeries = salaryBand?.map((item: any) => {
      return {
        x: item.levelCode,
        y: [
          parseFloat(item.minRate),
          parseFloat(item.lowerQuartile),
          parseFloat(item.median),
          parseFloat(item.upperQuartile),
          parseFloat(item.maxRate),
        ],
      };
    });

    const categories = salaryBand?.map((a: any) => a.levelCode);

    return [bandSeries, categories];
  }, [salaryBand]);

  const customTooltip = ({ dataPointIndex }: { dataPointIndex: any }) => {
    if (!salaryBandCandleStick || !salaryBandCandleStick[dataPointIndex]) return '';
    const yValue = salaryBandCandleStick ? salaryBandCandleStick[dataPointIndex]?.y : [];
    return `<div class="arrow_box">${polyglot.t('AnalyticsInsightsPeoplePage.maxRate')}: ${formatCurrency(yValue[4], {
      wholeNumber: true,
    })}<br/>${polyglot.t('AnalyticsInsightsPeoplePage.upperQuartile')}: ${formatCurrency(yValue[3], {
      wholeNumber: true,
    })}<br/>${polyglot.t('AnalyticsInsightsPeoplePage.median')}: ${formatCurrency(yValue[2], {
      wholeNumber: true,
    })}<br/>${polyglot.t('AnalyticsInsightsPeoplePage.lowerQuartile')}: ${formatCurrency(yValue[1], {
      wholeNumber: true,
    })}<br/>${polyglot.t('AnalyticsInsightsPeoplePage.minRate')}: ${formatCurrency(yValue[0], {
      wholeNumber: true,
    })}<br/></div>`;
  };

  const tableColumns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: polyglot.t('AnalyticsInsightsPeoplePage.levelCode'),
        accessorFn: (row) => row,
        id: 'levelCode',
        enableSorting: false,
        cell: ({ row: { original } }) => (original.levelCode ? <div>{original.levelCode}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },

      {
        header: () => polyglot.t('AnalyticsInsightsPeoplePage.minRate'),
        accessorFn: (row) => row,
        id: 'minRate',
        enableSorting: false,
        cell: ({
          row: {
            original: { minRate },
          },
        }) => (minRate ? <div>{formatCurrency(minRate)}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },

      {
        header: () => polyglot.t('AnalyticsInsightsPeoplePage.lowerQuartile'),
        accessorFn: (row) => row,
        id: 'lowerQuartile',
        enableSorting: false,
        cell: ({
          row: {
            original: { lowerQuartile },
          },
        }) => (lowerQuartile ? <div>{formatCurrency(lowerQuartile)}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },

      {
        header: () => polyglot.t('AnalyticsInsightsPeoplePage.median'),
        accessorFn: (row) => row,
        id: 'median',
        enableSorting: false,
        cell: ({
          row: {
            original: { median },
          },
        }) => (median ? <div>{formatCurrency(median)}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },

      {
        header: () => polyglot.t('AnalyticsInsightsPeoplePage.upperQuartile'),
        accessorFn: (row) => row,
        id: 'upperQuartile',
        enableSorting: false,
        cell: ({
          row: {
            original: { upperQuartile },
          },
        }) => (upperQuartile ? <div>{formatCurrency(upperQuartile)}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('AnalyticsInsightsPeoplePage.maxRate'),
        accessorFn: (row) => row,
        id: 'maxRate',
        enableSorting: false,
        cell: ({
          row: {
            original: { maxRate },
          },
        }) => (maxRate ? <div>{formatCurrency(maxRate)}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
    ],
    [polyglot]
  );

  return (
    <>
      {salaryBand && salaryBand.length > 0 && salaryBandCandleStick && salaryBandCategories && (
        <Box>
          <Box>
            <ChartBoxPlot
              series={salaryBandCandleStick ?? []}
              categories={salaryBandCategories ?? []}
              customTooltip={customTooltip}
            />
          </Box>

          <Box sx={spacing.mt20}>
            <BasicTable rowData={salaryBand ?? []} columnData={tableColumns ?? []} />
          </Box>
        </Box>
      )}
      {salaryBand && salaryBand.length === 0 && (
        // empty state
        <Box>
          <Typography variant="caption" sx={{ color: themeColors.Grey, mt: spacing.m10 }}>
            {polyglot.t('AnalyticsInsightsPeoplePage.emptyState')}
          </Typography>
        </Box>
      )}
    </>
  );
};

const GenderPayGapComponent = ({ genderPayGapFilterString }: { genderPayGapFilterString: string }) => {
  const { polyglot } = usePolyglot();
  const { data: genderPayGap } = useApiClient(AnalyticEndpoints.findGenderPayGap(genderPayGapFilterString));

  const getTranslatedCategoriesForGenderPayGap = (
    polyglot: Polyglot,
    genderPayGap?: QuartileProperties | undefined | null
  ) => {
    return genderPayGap?.categories
      ? genderPayGap.categories.map((category) => polyglot.t(`GenderPayGapChart.${category}`))
      : [];
  };

  const getTranslatedSeriesNamesForGenderPayGap = (
    polyglot: Polyglot,
    genderPayGap?: QuartileProperties | undefined | null
  ) => {
    return genderPayGap?.series
      ? genderPayGap.series.map((eachSeries) => {
          return { ...eachSeries, name: polyglot.t(`GenderPayGapChart.${eachSeries.name}`) };
        })
      : [];
  };

  return (
    <ChartColumnSingle
      series={getTranslatedSeriesNamesForGenderPayGap(polyglot, genderPayGap) || []}
      categories={getTranslatedCategoriesForGenderPayGap(polyglot, genderPayGap) || []}
      percentage
      min={0}
      max={100}
      tickAmount={5}
    />
  );
};

const LastSalaryChange = ({ salaryChangeFilterString }: { salaryChangeFilterString: string }) => {
  const { polyglot } = usePolyglot();
  const { data: lastSalaryChange } = useApiClient(AnalyticEndpoints.findLastSalaryChange(salaryChangeFilterString));

  const getTranslatedCategoriesForLastSalaryChange = (
    polyglot: Polyglot,
    lastSalaryChange?: QuartileProperties | undefined | null
  ) => {
    return lastSalaryChange?.categories
      ? lastSalaryChange.categories.map((category) => polyglot.t(`LastSalaryChangeChart.${category}`))
      : [];
  };
  return (
    <ChartColumnSingle
      series={lastSalaryChange?.series || []}
      categories={getTranslatedCategoriesForLastSalaryChange(polyglot, lastSalaryChange) || []}
    />
  );
};
