import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  TitleStatusComponent,
  getParticipantProgressStatus,
} from '@v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/Action.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
} from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ActionButtonProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewResultEndpoints } from '@/v2/feature/growth/reviews/api-client/review-result.api';
import {
  ReachType,
  RemindReviewers,
  ReminderType,
  ReviewCycle,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewProgress } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReviewCycleDetailResultsPage = ({
  reviewCycle,
  cycleLoading,
  reach,
}: {
  reviewCycle: ReviewCycle;
  cycleLoading: boolean;
  reach: ReachType;
}) => {
  const params = useParams<{ cycleId: string }>();
  const cycleId = params.cycleId;
  const { data: progress, isLoading: loadingProgress } = useApiClient(
    ReviewResultEndpoints.getReviewProgressByCycleId(cycleId, reach),
    {
      suspense: false,
    }
  );

  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reach} />}
      noHorizontalPadding={true}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={cycleLoading}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Results',
            headerSx: { px: spacing.px16, boxSizing: 'border-box' },
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <ReviewEntriesListComponent
                    reviewCycle={reviewCycle}
                    progress={progress}
                    loadingProgress={loadingProgress}
                    setSelectionModel={setSelectionModel}
                    selectionModel={selectionModel}
                    reach={reach}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </SettingsSectionContent>
  );
};

const ReviewEntriesListComponent = ({
  reviewCycle,
  progress,
  loadingProgress,
  setSelectionModel,
  selectionModel,
  reach,
}: {
  reviewCycle: ReviewCycle;
  progress: ReviewProgress[] | null | undefined;
  loadingProgress: boolean | undefined;
  setSelectionModel: React.Dispatch<React.SetStateAction<number[]>>;
  selectionModel: number[];
  reach: ReachType;
}) => {
  const routerHistory = useHistory();
  const tableColumns = useMemo<ColumnDef<ReviewProgress, ReviewProgress>[]>(
    () => [
      ...(reviewCycle.state !== CycleState.Completed
        ? [
            {
              id: 'select',
              enableSorting: false,
              header: () => {
                const displayedParticipantsId = new Set(progress?.map(({ participant }) => participant));
                const allSelected =
                  selectionModel.length > 0 &&
                  selectionModel.length === displayedParticipantsId.size &&
                  selectionModel.every((id) => displayedParticipantsId.has(id));
                return (
                  <Box sx={{ display: 'inline-block', width: 'fit-content' }} onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name={'allSelected'}
                      checked={allSelected}
                      value="allSelceted"
                      onChange={(_, checked) => {
                        setSelectionModel(checked ? [...displayedParticipantsId] : []);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }: { row: Row<ReviewProgress> }) => (
                <Box sx={{ display: 'inline-block', width: 'fit-content' }} onClick={(e) => e.stopPropagation()}>
                  <CheckboxComponent
                    label={undefined}
                    name={original.participant?.toString()}
                    checked={selectionModel.includes(original.participant)}
                    value={original.participant?.toString()}
                    onChange={() => {
                      let finalArray = [];
                      if (selectionModel?.includes(original.participant)) {
                        finalArray = selectionModel.filter((sm) => sm !== original.participant);
                      } else finalArray = [...selectionModel, original.participant];
                      setSelectionModel(finalArray);
                    }}
                  />
                </Box>
              ),
              minWidth: 5,
              width: 5,
              maxWidth: 5,
            },
          ]
        : []),
      {
        header: () => 'Participants',
        accessorFn: (row) => row,
        id: 'participants',
        enableSorting: false,

        cell: ({
          row: {
            original: { participant },
          },
        }) => (participant ? <UserCell userId={participant} /> : <EmptyCell />),
        minSize: 100,
        maxSize: 120,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,

        cell: ({ row: { original } }) => getParticipantProgressStatus(original, reviewCycle),
        minSize: 180,
        maxSize: 200,
      },
      {
        header: () => 'Result',
        accessorFn: (row) => row,
        id: 'isResultShared',
        enableSorting: false,

        cell: ({
          row: {
            original: { isResultShared },
          },
        }) =>
          isResultShared ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ChipComponent name="Shared" border="background" />
            </div>
          ) : (
            <EmptyCell />
          ),
        minSize: 80,
        maxSize: 100,
      },
      ...(reviewCycle.state !== CycleState.Completed
        ? [
            {
              id: 'actions',
              header: () => '',
              accessorFn: (row: Row<ReviewProgress>) => row,
              enableSorting: false,
              minSize: 60,
              maxSize: 80,
              cell: ({ row: { original } }: { row: Row<ReviewProgress> }) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ActionMenu participantIds={[original.participant]} reviewCycle={reviewCycle} type="table" />
                </div>
              ),
            },
          ]
        : []),
    ],
    [setSelectionModel, reviewCycle, progress, selectionModel]
  );

  const handleRowClick = useCallback(
    (row: Row<ReviewProgress>) => {
      const reachRoute =
        reach === ReachType.Company
          ? REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE
          : REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE;

      routerHistory.push(
        generatePath(reachRoute, {
          revieweeId: row.original?.participant ?? '',
          cycleId: reviewCycle.id,
        })
      );
    },
    [routerHistory, reviewCycle, reach]
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          px: spacing.px16,
          boxSizing: 'border-box',
          visibility: selectionModel.length > 0 ? 'visible' : 'hidden',
        }}
      >
        {reviewCycle.state !== CycleState.Completed && (
          <ActionMenu participantIds={selectionModel} reviewCycle={reviewCycle} type="page" />
        )}
      </Box>
      <BasicTable
        rowData={progress ?? []}
        columnData={tableColumns}
        loading={loadingProgress}
        paginationSx={{ px: spacing.px16, boxSizing: 'border-box' }}
        rowClick={handleRowClick}
      />
    </Box>
  );
};

const ActionMenu = ({
  participantIds,
  reviewCycle,
  type,
}: {
  participantIds: number[];
  reviewCycle: ReviewCycle;
  type: 'table' | 'page';
}) => {
  const [showMessage] = useMessage();

  const getActionsOptions = useCallback(() => {
    const menuOptions = [
      {
        handler: async () => {
          try {
            const remindBody: RemindReviewers = { cycleId: reviewCycle.id, revieweeIds: participantIds };
            await ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.All, remindBody);
            showMessage('Successfully sent reminders', 'success');
          } catch (error) {
            showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
          }
        },
        label: 'Remind all',
        disabled: false,
      },
    ];
    menuOptions.push({
      handler: async () => {
        try {
          const remindBody: RemindReviewers = { cycleId: reviewCycle.id, revieweeIds: participantIds };
          await ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.Overdue, remindBody);
          showMessage('Successfully sent reminders', 'success');
        } catch (error) {
          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
        }
      },
      label: 'Remind overdue',
      disabled: false,
    });
    return menuOptions;
  }, [reviewCycle.id, showMessage, participantIds]);

  const actionButtonDetails: ActionButtonProps =
    type === 'page'
      ? {
          type: 'button',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: 'Actions',
          icon: <ArrowDown {...iconSize} />,
          iconPosition: 'end',
        }
      : {
          type: 'iconButton',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: 'actions',
          icon: <ActionsSmall {...iconSize} />,
        };

  return <StyledMenuComponent options={getActionsOptions()} actionButtonDetails={actionButtonDetails} />;
};
