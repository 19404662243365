import { Box, IconButton, Typography } from '@mui/material';
import { DevicePolicyDto } from '@v2/feature/device/device.dto';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Lock } from '@/images/side-bar-icons/Lock.svg';
import { TooltipPopper } from '@/v2/components/theme-components/notification-tooltip-popper.component';
import { DevicePolicyType } from '@/v2/feature/device/device.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import {
  cardTitleIconSx,
  detailHelperSx,
  detailListSx,
  detailSx,
  detailTermSx,
  detailValueSx,
  lockBoxSx,
  lockIconSx,
  parentCardSx,
} from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface PolicyProps {
  value: number;
  label: string;
  description: string | undefined;
}

interface Props {
  readonly setEditPoliciesCard: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedPasscodePolicy: PolicyProps | undefined;
  readonly selectedEncryptionPolicy: PolicyProps | undefined;
  readonly selectedFirewallPolicy: PolicyProps | undefined;
  readonly selectedOsUpdatesPolicy: PolicyProps | undefined;
  readonly restrictions: {
    disableEnrollDevices: boolean;
    disableApps: boolean;
  };
  readonly appsPolicies: DevicePolicyDto[];
}

export const DevicesSettingsCard = ({
  setEditPoliciesCard,
  selectedPasscodePolicy,
  selectedEncryptionPolicy,
  selectedFirewallPolicy,
  selectedOsUpdatesPolicy,
  restrictions,
  appsPolicies,
}: Props) => {
  const getPolicyRestrictionMessage = (policyType: DevicePolicyType) =>
    `Upgrade To Devices Pro plan to apply ${policyType} policy.`;

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '60%', xl: '60%' },
          paddingBottom: spacing.p60,
        }}
      >
        <Box sx={cardTitleIconSx}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Security</Typography>
          <IconButton sx={tableIconButtonSx} onClick={() => setEditPoliciesCard(true)}>
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m30 }}>
          Configure security settings for any devices connected to Hexnode MDM or any Windows devices.
        </Typography>

        <Box component="section">
          <Box component="dl" sx={detailListSx}>
            {selectedPasscodePolicy && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  Passcode
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {selectedPasscodePolicy?.label}
                  </Typography>
                  {selectedPasscodePolicy?.description && (
                    <Typography sx={detailHelperSx}>{selectedPasscodePolicy?.description}</Typography>
                  )}

                  {restrictions.disableEnrollDevices && (
                    <Box sx={lockBoxSx}>
                      <TooltipPopper
                        title={<>{getPolicyRestrictionMessage(DevicePolicyType.passcode)}</>}
                        position="bottom"
                      >
                        <Box sx={lockIconSx}>
                          <Lock {...iconSize} fill="white" stroke="white" />
                        </Box>
                      </TooltipPopper>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box component="dl" sx={detailListSx}>
            {selectedEncryptionPolicy && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  Encryption
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {selectedEncryptionPolicy?.label}
                  </Typography>
                  {selectedEncryptionPolicy?.description && (
                    <Typography sx={detailHelperSx}>{selectedEncryptionPolicy?.description}</Typography>
                  )}
                  {restrictions.disableEnrollDevices && (
                    <Box sx={lockBoxSx}>
                      <TooltipPopper
                        title={<>{getPolicyRestrictionMessage(DevicePolicyType.encryption)}</>}
                        position="bottom"
                      >
                        <Box sx={lockIconSx}>
                          <Lock {...iconSize} fill="white" stroke="white" />
                        </Box>
                      </TooltipPopper>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box component="dl" sx={detailListSx}>
            {selectedFirewallPolicy && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  Windows Defender
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {selectedFirewallPolicy?.label}
                  </Typography>
                  {selectedFirewallPolicy?.description && (
                    <Typography sx={detailHelperSx}>{selectedFirewallPolicy?.description}</Typography>
                  )}
                  {restrictions.disableEnrollDevices && (
                    <Box sx={lockBoxSx}>
                      <TooltipPopper
                        title={<>{getPolicyRestrictionMessage(DevicePolicyType.firewall)}</>}
                        position="bottom"
                      >
                        <Box sx={lockIconSx}>
                          <Lock {...iconSize} fill="white" stroke="white" />
                        </Box>
                      </TooltipPopper>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box component="dl" sx={detailListSx}>
            {selectedOsUpdatesPolicy && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  OS updates
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {selectedOsUpdatesPolicy?.label}
                  </Typography>
                  {selectedOsUpdatesPolicy?.description && (
                    <Typography sx={detailHelperSx}>{selectedOsUpdatesPolicy?.description}</Typography>
                  )}

                  {restrictions.disableEnrollDevices && (
                    <Box sx={lockBoxSx}>
                      <TooltipPopper
                        title={<>{getPolicyRestrictionMessage(DevicePolicyType.updates)}</>}
                        position="bottom"
                      >
                        <Box sx={lockIconSx}>
                          <Lock {...iconSize} fill="white" stroke="white" />
                        </Box>
                      </TooltipPopper>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={parentCardSx}>
        <Box sx={{ ...cardTitleIconSx, position: 'relative' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Managed Apps</Typography>
          <Box sx={lockBoxSx}>
            {restrictions.disableEnrollDevices && (
              <TooltipPopper title={<>{getPolicyRestrictionMessage(DevicePolicyType.apps)}</>} position="bottom">
                <Box sx={lockIconSx}>
                  <Lock {...iconSize} fill="white" stroke="white" />
                </Box>
              </TooltipPopper>
            )}
          </Box>
        </Box>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m30 }}>
          Managed Apps contain sensitive company information and require more control. Selected apps will install
          automatically on your connected devices. If any apps are missing device will be flagged as non-compliant and
          reinstall will be attempted every 10 minutes. Managed apps are automatically updated every 24 hours.
        </Typography>

        <Box component="section">
          <Box component="dl" sx={detailListSx}>
            {appsPolicies && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  Apps
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {appsPolicies
                      ?.filter((devicePolicy) => !devicePolicy.isInhouseMdm && !devicePolicy.hide)
                      ?.map((appPolicy) => appPolicy.name)
                      ?.toString()
                      ?.replaceAll(',', ', ') || 'none'}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
