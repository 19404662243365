import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  UserCustomBenefitPaymentDto,
  UserCustomBenefitPaymentStatus,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefitPayment: UserCustomBenefitPaymentDto | null | undefined;
  readonly remainingBalance: number | null;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
  readonly afterClose?: () => void;
}

// TODO: @polyglot-later
export const UserBenefitPaymentDrawer = ({
  isOpen,
  setIsOpen,
  remainingBalance,
  userBenefitPayment,
  refresh,
  onClose,
  afterClose,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose}>
      <UserBenefitPaymentDrawerContent
        userBenefitPayment={userBenefitPayment}
        remainingBalance={remainingBalance}
        refresh={refresh}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefitPayment: UserCustomBenefitPaymentDto | null | undefined;
  readonly refresh: () => Promise<void>;
  readonly remainingBalance: number | null;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const UserBenefitPaymentDrawerContent = ({
  userBenefitPayment,
  remainingBalance,
  refresh,
  setIsOpen,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const [loadingReject, setLoadingReject] = useState<boolean>(false);

  const approvePayment = useCallback(async () => {
    if (!userBenefitPayment) return;
    try {
      setLoadingApprove(true);
      await CustomBenefitAPI.approveOrRejectCustomBenefitPaymentById(
        userBenefitPayment.customBenefitId,
        userBenefitPayment.userId,
        userBenefitPayment.userCustomBenefitId,
        userBenefitPayment.id,
        UserCustomBenefitPaymentStatus.Approved
      );
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoadingApprove(false);
    }
  }, [userBenefitPayment, refresh, setIsOpen, showMessage, polyglot]);

  const rejectPayment = useCallback(async () => {
    if (!userBenefitPayment) return;
    try {
      setLoadingReject(true);
      await CustomBenefitAPI.approveOrRejectCustomBenefitPaymentById(
        userBenefitPayment.customBenefitId,
        userBenefitPayment.userId,
        userBenefitPayment.userCustomBenefitId,
        userBenefitPayment.id,
        UserCustomBenefitPaymentStatus.Rejected
      );
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoadingReject(false);
    }
  }, [userBenefitPayment, setIsOpen, showMessage, polyglot, refresh]);

  return userBenefitPayment ? (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Benefit payment requested</Typography>

      {userBenefitPayment.userId && (
        <Box>
          <Typography variant="captionSmall" color="Grey">
            Employee
          </Typography>
          <Box sx={{ mt: '5px' }}>
            <UserAvatar userId={userBenefitPayment.userId} size="xxsmall" showName nameVariant="title4" />
          </Box>
        </Box>
      )}

      {userBenefitPayment.customBenefit?.name && (
        <Box>
          <Typography variant="captionSmall" color="Grey">
            Benefit
          </Typography>
          <Typography variant="title4">{userBenefitPayment.customBenefit.name}</Typography>
        </Box>
      )}

      {userBenefitPayment.notes && (
        <Box>
          <Typography variant="captionSmall" color="Grey">
            Notes
          </Typography>
          <Typography variant="title4">{userBenefitPayment.notes}</Typography>
        </Box>
      )}

      {remainingBalance !== null && (
        <Box>
          <Typography variant="captionSmall" color="Grey">
            Current available balance
          </Typography>
          <Typography variant="title4">{formatMoney({ amount: remainingBalance })}</Typography>
        </Box>
      )}

      {userBenefitPayment.amount && (
        <Box>
          <Typography variant="captionSmall" color="Grey">
            Amount
          </Typography>
          <Typography variant="title4">{formatMoney({ amount: userBenefitPayment.amount })}</Typography>
        </Box>
      )}

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          sizeVariant="medium"
          loading={loadingReject}
          colorVariant="secondary"
          name="Reject"
          fullWidth
          onClick={rejectPayment}
        />
        <LoaderButton
          sizeVariant="medium"
          loading={loadingApprove}
          colorVariant="primary"
          name="Approve"
          fullWidth
          onClick={approvePayment}
        />
      </Box>
    </Box>
  ) : (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Benefit payment requested</Typography>

      <SkeletonLoader
        variant="rectangular"
        width="90%"
        height="50vh"
        sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
      />
    </Box>
  );
};
