import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { unselectColumnUtil } from '@v2/feature/reports/reports-advanced/reports-advanced.util';
import { ReportColumnType, ReportSelectedColumn, SelectedColumnsRequest } from '@v2/feature/reports/reports.interface';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { radius } from '@v2/styles/radius.styles';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Drag } from '@/images/side-bar-icons/Drag.svg';

type ReportSelectedColumnWithStub = ReportSelectedColumn & { stub: string };

interface SectionProps {
  readonly selectedColumns: SelectedColumnsRequest;
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnsRequest>>;
  readonly editColumn: (stub: string, col: string, type: ReportColumnType, order: number) => void;
}

const reorder = (list: ReportSelectedColumnWithStub[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const ColumnsListSection = ({ selectedColumns, setSelectedColumns, editColumn }: SectionProps) => {
  const columns: ReportSelectedColumnWithStub[] = useMemo(() => {
    const cols: ReportSelectedColumnWithStub[] = [];
    for (const stub in selectedColumns) {
      cols.push(...(selectedColumns[stub] ?? []).map((c) => ({ ...c, stub })));
    }
    return cols.sort((a, b) => a.order - b.order);
  }, [selectedColumns]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems = reorder(columns, result.source.index, result.destination.index);

    setSelectedColumns((prev) => {
      const prevCopy = { ...prev };

      for (let order = 1; order < newItems.length + 1; order++) {
        const column = newItems[order - 1];
        const index = prevCopy[column.stub].findIndex(
          (col) => col.col === column.col && col.type === column.type && col.label === column.label
        );
        if (index >= 0) prevCopy[column.stub][index].order = order;
      }

      return prevCopy;
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', overflowY: 'hidden' }}>
      <Typography variant="caption" color="Grey">
        Selected
      </Typography>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="ROOT">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                height: '100%',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                paddingRight: '4px',
              }}
            >
              {columns.map((column, index) => (
                <Draggable isDragDisabled={false} draggableId={String(column.order)} key={column.order} index={index}>
                  {(provided, snapshot) => (
                    <Box {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                      <ColumnDisplay
                        key={`${column.stub}-${column.col}-${column.type}`}
                        column={column}
                        setSelectedColumns={setSelectedColumns}
                        editColumn={editColumn}
                        isDragging={snapshot.isDragging}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

interface ColumnDisplayProps {
  readonly column: ReportSelectedColumnWithStub;
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnsRequest>>;
  readonly editColumn: (stub: string, col: string, type: ReportColumnType, order: number) => void;
  readonly isDragging: boolean;
}

const ColumnDisplay = ({ column, setSelectedColumns, editColumn, isDragging }: ColumnDisplayProps) => {
  const [isGrabbing, setIsGrabbing] = useState(false);

  const handleMouseOver = () => {
    if (!isGrabbing) {
      document.body.style.cursor = 'grab';
    }
  };

  const handleMouseLeave = () => {
    if (!isGrabbing) {
      document.body.style.cursor = 'default';
    }
  };

  const handleMouseDown = () => {
    setIsGrabbing(true);
    document.body.style.cursor = 'grabbing';
  };

  const handleMouseUp = () => {
    setIsGrabbing(false);
    if (document.getElementById(`column-${column.stub}-${column.col}-${column.type}-drag`)?.matches(':hover')) {
      document.body.style.cursor = 'grab';
    } else {
      document.body.style.cursor = 'default';
    }
  };

  function unselectColumn(stub: string, col: string, type: ReportColumnType): void {
    setSelectedColumns((prev) => unselectColumnUtil(prev, stub, col, type));
  }

  return (
    <Box
      sx={{
        bgcolor: isDragging ? themeColors.GreyHover : themeColors.Background,
        borderRadius: radius.br5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      draggable
    >
      <Box
        id={`column-${column.stub}-${column.col}-${column.type}-drag`}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        sx={{ p: '4px' }}
      >
        <Drag {...iconSize} stroke={themeColors.Grey} />
      </Box>
      <Typography
        variant="caption"
        sx={{
          width: '100%',
          cursor: 'pointer',
          ':hover': { background: themeColors.GreyHover },
          p: '4px',
        }}
        onClick={() => editColumn(column.stub, column.col, column.type, column.order)}
      >
        {column.label ?? column.col}
      </Typography>
      <Box
        sx={{
          cursor: 'pointer',
          p: '4px',
          ':hover': { background: themeColors.GreyHover },
        }}
        onClick={() => unselectColumn(column.stub, column.col, column.type)}
        draggable={false}
      >
        <Close {...iconSize} stroke={themeColors.Grey} />
      </Box>
    </Box>
  );
};
