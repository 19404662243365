import React, { useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceScheduleEditApprovalDrawer } from '@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-approval-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface SectionProps {
  readonly schedule: AttendanceScheduleDto;
  readonly refreshSchedule: () => Promise<void>;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
}

export const AttendanceSettingsApprovalSection = ({
  schedule,
  refreshSchedule,
  approvalRules,
  refreshApprovalRules,
}: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);

  return (
    <SettingsSectionContent
      title={polyglot.t('AttendanceSchedule.approval')}
      onEdit={() => {
        setEdit(true);
      }}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AttendanceSchedule.approvalRule'),
                value: schedule.approvalRule
                  ? schedule.approvalRule.name
                  : polyglot.t('AttendanceSchedule.noApprovalRequired'),
              },
            ],
          },
        ]}
      />

      <AttendanceScheduleEditApprovalDrawer
        isOpen={edit}
        setIsOpen={setEdit}
        attendanceSchedule={schedule}
        refresh={refreshSchedule}
        approvalRules={approvalRules}
        refreshApprovalRules={refreshApprovalRules}
      />
    </SettingsSectionContent>
  );
};
