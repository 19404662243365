import { useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ManagerPermissionGroupOptionsEditDrawer } from '@v2/feature/permission-group/components/manager-permission-group-options-edit-drawer.component';
import { translateManagerPermissionGroupReportsLevel } from '@v2/infrastructure/i18n/translate.util';

import { DEFAULT_GROUPS } from '../permission-group.util';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { PermissionGroupEditDrawerPage } from '@/v2/feature/permission-group/components/permission-group-edit-drawer.component';
import { PermissionGroupMemberUserDto } from '@/v2/feature/permission-group/permission-group.dto';
import {
  DefaultManagerPermissionLevel,
  PERMISSION_GROUP_EDIT_DRAWER_MODES,
  PermissionGroupPageProps,
} from '@/v2/feature/permission-group/permission-group.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const EditPermissionGroupGeneralPage = ({ permissionGroup, refresh, setLoading }: PermissionGroupPageProps) => {
  const { polyglot } = usePolyglot();

  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [isOptionsEditOpen, setIsOptionsEditOpen] = useState<boolean>(false);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup?.name) : false;

  const openEditDrawer = () => {
    setEditDrawerOpen(true);
  };

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyGeneralSection.general')}
      onEdit={!isDefault ? () => openEditDrawer() : undefined}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PermissionGroupListingTable.name'),
                value: permissionGroup.name,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PermissionGroupListingTable.description'),
                value: permissionGroup.description,
              },
            ],
          },
          {
            title: polyglot.t('ManagerPermissionGroup.options'),
            onEdit: () => {
              setIsOptionsEditOpen(true);
            },
            hidden: permissionGroup.name !== 'Manager',
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('ManagerPermissionGroup.levelsOfReports'),
                value: translateManagerPermissionGroupReportsLevel(
                  permissionGroup.options.reportsLevels ?? DefaultManagerPermissionLevel,
                  polyglot
                ),
              },
            ],
          },
        ]}
      />

      <ManagerPermissionGroupOptionsEditDrawer
        isOpen={isOptionsEditOpen}
        setIsOpen={setIsOptionsEditOpen}
        permissionGroup={permissionGroup}
        refreshPermissionGroup={refresh}
      />

      <DrawerModal isOpen={editDrawerOpen} setIsOpen={setEditDrawerOpen}>
        <PermissionGroupEditDrawerPage
          refreshPermissionGroup={refresh}
          selectedPermission={permissionGroup}
          permissionGroupMembers={(permissionGroup?.members as PermissionGroupMemberUserDto[]) ?? []}
          closePage={async () => {
            setEditDrawerOpen(false);
            await refresh();
          }}
          mode={PERMISSION_GROUP_EDIT_DRAWER_MODES.general}
          setLoading={setLoading}
          permissionGroupName={permissionGroup?.name ?? ''}
        />
      </DrawerModal>
    </SettingsSectionContent>
  );
};
