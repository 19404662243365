import { Route, Switch } from 'react-router-dom';

import { REQUESTS_CHOOSE_REQUEST_ROUTE, REQUESTS_SUBMIT_REQUEST_ROUTE } from '@/lib/routes';
import { ChooseRequestPage } from '@/v2/feature/requests/features/submit-request/choose-request.page';
import { SubmitRequestPage } from '@/v2/feature/requests/features/submit-request/submit-request.page';

export const RequestsRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route path={REQUESTS_SUBMIT_REQUEST_ROUTE} component={SubmitRequestPage} exact />
      <Route path={REQUESTS_CHOOSE_REQUEST_ROUTE} component={ChooseRequestPage} exact />
    </Switch>
  );
};
