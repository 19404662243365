import {
  CustomBenefitDto,
  UserCustomBenefitPaymentDto,
  UserCustomBenefitRequestType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';

export interface UserCustomBenefitDto {
  id: number;
  companyId: number;
  userId: number;
  customBenefitId: number;
  effectiveDate: string;
  effectiveEndDate: string | null;
  employerContribution: number | null;
  employeeContribution: number | null;
  openingBalance: number | null;
  numberOfInstallments: number | null;
  dependants: UserInsuranceDependants | null;
  dependantsList: number[] | null;
  createdBy: number | null;
  updatedBy: number | null;
  createdAt: Date;
  updatedAt: Date;
  customBenefit?: CustomBenefitDto;
  userCustomBenefitPayments?: UserCustomBenefitPaymentDto[];
}

export type EditableUserCustomBenefitDto = Pick<
  UserCustomBenefitDto,
  | 'employerContribution'
  | 'employeeContribution'
  | 'openingBalance'
  | 'numberOfInstallments'
  | 'dependants'
  | 'dependantsList'
>;

export type CreateUserCustomBenefitDto = Pick<
  UserCustomBenefitDto,
  | 'effectiveDate'
  | 'employerContribution'
  | 'employeeContribution'
  | 'openingBalance'
  | 'numberOfInstallments'
  | 'dependants'
  | 'dependantsList'
>;

export enum UserCustomBenefitRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface UserCustomBenefitRequestDto {
  id: string;
  userId: number;
  customBenefitId: number;
  userCustomBenefitId: number;
  amount: number;
  numberOfInstallments: number | null;
  employerContribution: number | null;
  employeeContribution: number | null;
  dependants: UserInsuranceDependants | null;
  dependantsList: number[] | null;
  notes: string;
  effectiveDate: string;
  type: UserCustomBenefitRequestType;
  status: UserCustomBenefitRequestStatus;
  approvedBy: number | null;
  approvedOnTimestamp: Date | null;
  createdBy: number | null;
  createdAt: Date;
  updatedAt: Date;
  approverIds: number[] | null;
  customBenefit?: Partial<CustomBenefitDto>;
  creator?: { name?: string; displayName?: string; firstName?: string; lastName?: string };
  approver?: { name?: string; displayName?: string; firstName?: string; lastName?: string };
}
