import { useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { ManageInsurancePolicyDrawer } from '@v2/feature/super-admin/features/super-admin-insurance/components/manage-insurance-policy-drawer.component';
import { UploadInsurancePolicyDocumentsDrawer } from '@v2/feature/super-admin/features/super-admin-insurance/components/upload-insurance-policy-documents-drawer.component';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Document } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE } from '@/lib/routes';

type SuperAdminInsurancePoliciesPageProps = {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly policies: readonly InsurancePolicyDto[];
  readonly refreshPolicies: () => Promise<void>;
  readonly pageConfig: PageConfig;
  readonly loading: boolean;
};

export const SuperAdminInsurancePoliciesPage = ({
  companies,
  policies,
  refreshPolicies,
  loading,
  pageConfig,
}: SuperAdminInsurancePoliciesPageProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState<boolean>(false);
  const [selectedPolicy, setSelectedPolicy] = useState<InsurancePolicyDto | null>(null);

  const history = useHistory();
  const [showMessage] = useMessage();

  const columnData = useMemo<ColumnDef<InsurancePolicyDto, InsurancePolicyDto>[]>(() => {
    return [
      {
        header: () => 'Company name',
        accessorFn: (row) => row,
        id: 'companyId',
        cell: (info: CellContext<InsurancePolicyDto, InsurancePolicyDto>) => {
          const policy: InsurancePolicyDto = info.getValue();
          const company = companies.find((company) => policy.companyId === company.companyId);
          return company ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{company.name}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Provider name',
        accessorFn: (row) => row,
        id: 'providerName',
        cell: (info: CellContext<InsurancePolicyDto, InsurancePolicyDto>) => {
          const policy: InsurancePolicyDto = info.getValue();
          return (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{policy.providerName}</Typography>
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Policy type',
        accessorFn: (row) => row,
        id: 'type',
        cell: (info: CellContext<InsurancePolicyDto, InsurancePolicyDto>) => {
          const policy: InsurancePolicyDto = info.getValue();
          return policy.type ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{policy.type}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Start date',
        accessorFn: (row) => row,
        id: 'startDate',
        cell: (info: CellContext<InsurancePolicyDto, InsurancePolicyDto>) => {
          const policy: InsurancePolicyDto = info.getValue();
          return (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {new Date(policy.startDate).toLocaleDateString('en-GB')}
            </Typography>
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'End date',
        accessorFn: (row) => row,
        id: 'endDate',
        cell: (info: CellContext<InsurancePolicyDto, InsurancePolicyDto>) => {
          const policy: InsurancePolicyDto = info.getValue();
          return (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {new Date(policy.endDate).toLocaleDateString('en-GB')}
            </Typography>
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        cell: (info: CellContext<InsurancePolicyDto, InsurancePolicyDto>) => {
          const policy: InsurancePolicyDto = info.getValue();
          return (
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <StyledMenuComponent
                options={[
                  {
                    icon: <Edit {...iconSize} />,
                    handler: () => {
                      setSelectedPolicy(policy);
                      setIsDrawerOpen(true);
                    },
                    label: 'Edit details',
                  },
                  {
                    icon: <Document {...iconSize} />,
                    handler: () => {
                      setSelectedPolicy(policy);
                      setIsUploadDrawerOpen(true);
                    },
                    label: 'Upload documents',
                  },
                  {
                    icon: <Trash {...iconSize} />,
                    handler: async () => {
                      try {
                        await InsuranceAPI.deleteInsurancePolicyByIdAsSuperAdmin(policy.id);
                        await refreshPolicies();
                      } catch (error) {
                        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                      }
                    },
                    label: 'Delete',
                  },
                ]}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <ActionsSmall {...iconSize} />,
                }}
              />
            </Box>
          );
        },
        maxSize: 50,
        minSize: 50,
      },
    ];
  }, [companies, refreshPolicies, showMessage]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Health Insurance</Typography>}
        views={<></>}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        <Box sx={spacing.mt20}>
          <BasicTable<InsurancePolicyDto>
            rowData={[...policies]}
            columnData={columnData}
            rowClick={(row: Row<InsurancePolicyDto>) =>
              history.push(generatePath(SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE, { policyId: row.original.id }))
            }
          />
        </Box>

        {selectedPolicy && isDrawerOpen && (
          <ManageInsurancePolicyDrawer
            policy={selectedPolicy}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            refresh={refreshPolicies}
            onSave={() => setSelectedPolicy(null)}
          />
        )}
        {selectedPolicy && isUploadDrawerOpen && (
          <UploadInsurancePolicyDocumentsDrawer
            policyId={selectedPolicy.id}
            isOpen={isUploadDrawerOpen}
            setIsOpen={setIsUploadDrawerOpen}
            refresh={refreshPolicies}
            onSave={() => setSelectedPolicy(null)}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
