import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserProfileProvider } from '@/v2/feature/user/context/user-profile.context';
import { UserBankInfoForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-card.component';
import { CompensationCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-card.component';
import { CustomSections } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-card.component';
import { UserEquityForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-equity-card.component';
import { TaxInformationCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-tax-information-card.component';
import { UserProfilePageProps } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserCompensation = ({ userId, onUpdate, missingFields }: UserProfilePageProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const finallyRefreshUserObject = async (section: string) => {
    if (['user.basicInfo', 'user.role', 'user.lifecycle'].includes(section)) {
    }
    await onUpdate();
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">{polyglot.t('UserCompensation.compensation')}</Typography>} />
      <ContentWrapper loading={false} sx={{ pt: spacing.p25 }}>
        <UserProfileProvider>
          <ScopesControl scopes={['user.compensation:read']} context={scopesContext}>
            <CompensationCard
              userId={userId}
              handleSubmit={() => finallyRefreshUserObject('user.compensation')}
              missingFields={missingFields}
            />
          </ScopesControl>

          <ScopesControl scopes={['payroll:read']} context={scopesContext}>
            <TaxInformationCard
              userId={userId}
              handleSubmit={() => finallyRefreshUserObject('user.compensation')}
              missingFields={missingFields}
            />
          </ScopesControl>

          <ScopesControl scopes={['user.bankAccount:read']} context={scopesContext}>
            <UserBankInfoForm
              userId={userId}
              handleSubmit={() => finallyRefreshUserObject('user.bankAccount')}
              missingFields={missingFields}
            />
          </ScopesControl>

          <ScopesControl scopes={['user.equity', 'user.equity:read']} context={scopesContext}>
            <UserEquityForm userId={userId} handleSubmit={() => finallyRefreshUserObject('user.equity')} />
          </ScopesControl>

          <CustomSections
            userId={userId}
            formTab={ProfileTab.Compensation}
            onSubmit={() => finallyRefreshUserObject('')}
            missingFields={missingFields}
          />
        </UserProfileProvider>
      </ContentWrapper>
    </RootStyle>
  );
};
