import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  EXPENSES_ME_OVERVIEW_ROUTE,
  EXPENSES_ME_ROUTE,
} from '@/lib/routes';
import { ExpensePage } from '@/v2/feature/expense/expense.page';
import { NewExpensePage } from '@/v2/feature/payments/pages/new-expense.page';

export const ExpensesMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);

  return (
    <Switch>
      <RouteScopesControl context={scopesContext} scopes={['expenses']} path={EXPENSES_ME_OVERVIEW_ROUTE} exact>
        <ExpensePage reach="me" />
      </RouteScopesControl>
      <RouteScopesControl scopes={['expenses']} context={scopesContext} path={EXPENSE_ME_NEW_EXPENSE_ROUTE} exact>
        <NewExpensePage preselectedEmployee={globalState.user.userId} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['expenses']} context={scopesContext} path={EXPENSE_ME_EDIT_EXPENSE_ROUTE} exact>
        <NewExpensePage preselectedEmployee={globalState.user.userId} editMode={true} />
      </RouteScopesControl>

      <Redirect to={EXPENSES_ME_OVERVIEW_ROUTE} from={EXPENSES_ME_ROUTE} />
    </Switch>
  );
};
