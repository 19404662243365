import React, { Dispatch, SetStateAction, Suspense, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceUpdateSuperadminDto } from '@v2/feature/device/device.dto';
import { DeviceOwnership, DeviceType } from '@v2/feature/device/device.interface';
import { DeviceTypesValueLabelOptions } from '@v2/feature/device/device.util';
import { dateFieldTest, isoDateFormat } from '@v2/infrastructure/date/date-format.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly device: DeviceDto;
  readonly refreshDevice: () => Promise<void>;
}

export const SuperAdminDeviceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  device,
  refreshDevice,
}: DrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminDeviceDetailsDrawerContent device={device} refreshDevice={refreshDevice} setIsOpen={setIsOpen} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly device: DeviceDto;
  readonly refreshDevice: () => Promise<void>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SuperAdminDeviceDetailsDrawerContent = ({
  device,
  refreshDevice,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const initialValues: DeviceUpdateSuperadminDto = {
    serialNumber: device.serialNumber ?? null,
    internalNotes: device.internalNotes ?? null,
    price: device.price ?? null,
    type: device.type ?? DeviceType.Laptop,
    contractLength: device.contractLength ?? null,
    contractStartDate: device?.contractStartDate ?? null,
    externallyInvoiced: device?.externallyInvoiced ?? null,
  };

  const validationSchema = yup.object({
    serialNumber: yup.string().nullable().notRequired(),
    internalNotes: yup.string().nullable().notRequired(),
    price: yup.number().nullable().notRequired(),
    type: yup.string().required('Device type is required'),
    contractLength: yup.number().integer().nullable().notRequired(),
    contractStartDate: yup.string().test(dateFieldTest).nullable().notRequired(),
    externallyInvoiced: yup.string().nullable().matches(isoDateFormat, 'Please use YYYY-MM-DD format.').notRequired(),
  });

  const patchDeviceEntity = useCallback(
    async (formData: DeviceUpdateSuperadminDto) => {
      try {
        const {
          serialNumber,
          internalNotes,
          customerNotes,
          price,
          type,
          contractLength,
          contractStartDate,
          externallyInvoiced,
        } = formData;
        setLoading(true);

        await DeviceAPI.updateDeviceByIdAsSuperadmin(device.id, {
          serialNumber,
          internalNotes,
          customerNotes,
          price: Number(price),
          type,
          contractLength: Number(contractLength),
          contractStartDate,
          externallyInvoiced: externallyInvoiced ? externallyInvoiced : null,
        });
        showMessage('Device successfully updated.', 'success');
        await refreshDevice();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Device could not be updated. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
    [setIsOpen, showMessage, device, refreshDevice]
  );

  const formik = useFormik<DeviceUpdateSuperadminDto>({
    initialValues,
    validationSchema,
    onSubmit: async (values: DeviceUpdateSuperadminDto) => patchDeviceEntity(values),
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Device details</Typography>

        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{device.modelName}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextfieldComponent
            label="Serial Number"
            name="serialNumber"
            value={formik.values.serialNumber}
            onChange={formik.handleChange}
            error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
            helperText={formik.touched.serialNumber && formik.errors.serialNumber}
            clearText={() => formik.setFieldValue('serialNumber', '')}
            size="small"
          />

          <SelectComponent
            name="type"
            label="Type"
            options={DeviceTypesValueLabelOptions}
            value={formik.values.type ?? undefined}
            compareValue={formik.values.type ?? undefined}
            onChange={formik.handleChange}
            error={formik.touched.type && !!formik.errors.type}
            helperText={formik.touched.type && formik.errors.type}
          />

          <TextfieldComponent
            label="Internal notes"
            name="internalNotes"
            value={formik.values.internalNotes}
            onChange={formik.handleChange}
            error={formik.touched.internalNotes && Boolean(formik.errors.internalNotes)}
            helperText={formik.touched.internalNotes && formik.errors.internalNotes}
            clearText={() => formik.setFieldValue('internalNotes', '')}
            size="small"
          />

          {device.ownership === DeviceOwnership.Rental && (
            <TextfieldComponent
              label="Price"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              clearText={() => formik.setFieldValue('price', '')}
              size="small"
            />
          )}

          {device.ownership === DeviceOwnership.Rental && (
            <TextfieldComponent
              label="Contract length"
              name="contractLength"
              value={formik.values.contractLength}
              onChange={formik.handleChange}
              error={formik.touched.contractLength && Boolean(formik.errors.contractLength)}
              helperText={formik.touched.contractLength && formik.errors.contractLength}
              clearText={() => formik.setFieldValue('contractLength', '')}
              size="small"
            />
          )}

          {device.ownership === DeviceOwnership.Rental && (
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.contractStartDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('contractStartDate', value);
                }
              }}
              name="contractStartDate"
              label="Contract start date"
              error={!!formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate)}
              helperText={formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate)}
            />
          )}

          {device.ownership === DeviceOwnership.Rental && (
            <TextfieldComponent
              label="Externally invoiced"
              name="externallyInvoiced"
              placeholder="YYYY-MM-DD"
              value={formik.values.externallyInvoiced}
              onChange={formik.handleChange}
              error={formik.touched.externallyInvoiced && Boolean(formik.errors.externallyInvoiced)}
              helperText={formik.touched.externallyInvoiced && formik.errors.externallyInvoiced}
              endAdornment="clear-text"
              clearText={() => formik.setFieldValue('externallyInvoiced', null)}
              size="small"
              disabled
            />
          )}
        </Box>

        <Box sx={{ mt: '30px' }}>
          <LoaderButton name="Save" loading={loading} fullWidth sizeVariant="medium" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
