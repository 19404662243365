import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DownArrow } from '@/images/fields/DownArrow.svg';
import { ReactComponent as UpArrow } from '@/images/fields/UpArrow.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { OptionProps } from '@/v2/components/theme-components/styled-menu.component';
import { ReviewSectionBankAPI } from '@/v2/feature/growth/reviews/api-client/review-section-bank.api';
import { ReviewTemplateAPI } from '@/v2/feature/growth/reviews/api-client/review-template.api';
import { ReviewSectionBank } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';
import { ReviewTemplate, ReviewTemplateById } from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import { iconSize } from '@/v2/styles/menu.styles';

export const useTemplateSectionOptions = (
  template: ReviewTemplate,
  section: ReviewSectionBank,
  setSection: React.Dispatch<React.SetStateAction<ReviewSectionBank | null>>,
  refreshTemplate: KeyedMutator<ReviewTemplateById> | undefined,
  setIsSectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
): readonly OptionProps[] => {
  const [showMessage] = useMessage();

  if (!template || !template.order) return [];
  const { id: templateId } = template;

  const currentOrder = template.order ? [...template.order] : [];
  const findSectionIndex = () => currentOrder.findIndex((o) => o.type === 'section' && o.id === section.id);

  const isSectionFirst = () => findSectionIndex() === 0;
  const isSectionLast = () => findSectionIndex() === currentOrder.length - 1;

  const moveSection = async (direction: 'up' | 'down') => {
    const sectionIndex = findSectionIndex();
    if (direction === 'up' && sectionIndex > 0) {
      [currentOrder[sectionIndex - 1], currentOrder[sectionIndex]] = [
        currentOrder[sectionIndex],
        currentOrder[sectionIndex - 1],
      ];
    } else if (direction === 'down' && sectionIndex < currentOrder.length - 1) {
      [currentOrder[sectionIndex], currentOrder[sectionIndex + 1]] = [
        currentOrder[sectionIndex + 1],
        currentOrder[sectionIndex],
      ];
    }
    await ReviewTemplateAPI.updateOrderReviewTemplate({ templateId, order: currentOrder });
    await refreshTemplate?.();
  };

  return [
    {
      icon: <Edit {...iconSize} />,
      handler: async () => {
        setSection(section);
        setIsSectionModalOpen(true);
      },
      label: 'Edit',
      disabled: false,
    },
    {
      icon: <UpArrow {...iconSize} />,
      handler: async () => {
        try {
          if (!template || !template.order || isSectionFirst()) return;
          await moveSection('up');
          showMessage('Successfully moved the section up', 'success');
        } catch (error) {
          showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
        }
      },
      label: 'Move up',
      disabled: isSectionFirst(),
    },
    {
      icon: <DownArrow {...iconSize} />,
      handler: async () => {
        try {
          if (!template || !template.order || isSectionLast()) return;
          await moveSection('down');
          showMessage('Successfully moved the section down', 'success');
        } catch (error) {
          showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
        }
      },
      label: 'Move down',
      disabled: isSectionLast(),
    },
    {
      icon: <Trash {...iconSize} />,
      handler: async () => {
        try {
          await ReviewSectionBankAPI.deleteSectionById(templateId, section.id);
          await refreshTemplate?.();
          showMessage('Successfully delete the section', 'success');
        } catch (error) {
          showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
        }
      },
      label: 'Delete',
      disabled: false,
    },
  ];
};
