import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { HolidaysProratingRule, PolicyPublicHolidaysFormData } from '@v2/feature/absence/absence.interface';
import { getActualPartTimeMethodOptions, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyPublicHolidaysEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyPublicHolidaysEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: PolicyPublicHolidaysEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const isUnlimited = isUnlimitedPolicy(absencePolicy);

  const basicAllowanceProrationDisabled = !absencePolicy.proratingFte && !absencePolicy.proratingStartDate;

  const onSubmit = useCallback(
    async (values: PolicyPublicHolidaysFormData) => {
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyPublicHolidays(absencePolicy.id, values);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );

  const formik = useFormik<PolicyPublicHolidaysFormData>({
    initialValues: {
      publicHolidayOnTop: isUnlimited ? false : absencePolicy.publicHolidayOnTop,
      publicHolidayAutobook: absencePolicy.publicHolidayAutobook,
      holidaysProratingRule: basicAllowanceProrationDisabled
        ? HolidaysProratingRule.AllInPeriod
        : absencePolicy.holidaysProratingRule,
    },
    validationSchema: yup.object({
      publicHolidayOnTop: yup.boolean().notRequired(),
      publicHolidayAutobook: yup.boolean().notRequired(),
      holidaysProratingRule: yup.string().oneOf(Object.values(HolidaysProratingRule)).required('Required field'),
    }),
    onSubmit,
  });

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsenceUtil.publicHolidays')}</Typography>

          <Box>
            <CheckboxComponent
              label={polyglot.t('AbsenceUtil.autobook')}
              name="publicHolidayAutobook"
              checked={formik.values.publicHolidayAutobook}
              onChange={formik.handleChange}
            />
            <Typography variant="captionSmall" color="Grey" sx={{ ml: spacing.m25 }}>
              {polyglot.t('AbsenceUtil.autobookDesc')}
            </Typography>
          </Box>

          {!isUnlimited && (
            <Box>
              <CheckboxComponent
                label={polyglot.t('AbsenceUtil.addToAllowance')}
                name="publicHolidayOnTop"
                checked={formik.values.publicHolidayOnTop}
                onChange={formik.handleChange}
              />
              <Typography variant="captionSmall" color="Grey" sx={{ ml: spacing.m25 }}>
                {polyglot.t('AbsenceUtil.addToAllowanceDesc')}
              </Typography>
            </Box>
          )}

          {!isUnlimited && formik.values.publicHolidayOnTop && (
            <Typography variant="title3" sx={{ mt: '10px' }}>
              {polyglot.t('AbsenceUtil.allocation')}
            </Typography>
          )}

          {!isUnlimited && formik.values.publicHolidayOnTop && (
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                aria-labelledby="public-holidays-allocation-group-label"
                name="public-holidays-allocation-group"
                onChange={(event) => {
                  formik.setFieldValue('holidaysProratingRule', event.target.value as HolidaysProratingRule);
                }}
              >
                <FormControlLabel
                  key="prorata"
                  labelPlacement="end"
                  value={HolidaysProratingRule.FullValueProrated}
                  checked={formik.values.holidaysProratingRule === HolidaysProratingRule.FullValueProrated}
                  disabled={basicAllowanceProrationDisabled}
                  control={<StyledRadio />}
                  label={
                    <Box>
                      <Typography variant="title4" color={basicAllowanceProrationDisabled ? 'Grey' : 'DarkGrey'}>
                        {polyglot.t('AbsenceUtil.proRata')}
                      </Typography>
                      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                        {polyglot.t('AbsenceUtil.proRataDesc')}
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  key="actual"
                  labelPlacement="end"
                  value={HolidaysProratingRule.AllInPeriod}
                  checked={
                    formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriod ||
                    formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated ||
                    formik.values.holidaysProratingRule === HolidaysProratingRule.AllInCycle
                  }
                  control={<StyledRadio />}
                  label={
                    <Box>
                      <Typography variant="title4">{polyglot.t('AbsenceUtil.actual')}</Typography>
                      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                        {polyglot.t('AbsenceUtil.actualDesc')}
                      </Typography>
                    </Box>
                  }
                  sx={{ my: spacing.m20 }}
                />

                {(formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriod ||
                  formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated ||
                  formik.values.holidaysProratingRule === HolidaysProratingRule.AllInCycle) && (
                  <Box sx={{ px: spacing.p30 }}>
                    <SelectComponent
                      name="holidaysProratingRule"
                      label={polyglot.t('AbsenceUtil.partTimeMethod')}
                      options={getActualPartTimeMethodOptions(basicAllowanceProrationDisabled, polyglot)}
                      value={formik.values.holidaysProratingRule}
                      onChange={formik.handleChange}
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          )}

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
