import { Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const sortNumeric = <T>(a: Row<T>, b: Row<T>, getValue: (entry: T) => number | undefined | null) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return aValue - bValue;
  }
  return typeof aValue === 'number' ? 1 : -1;
};

export const sortBoolean = <T>(
  a: Row<T>,
  b: Row<T>,
  getValue: (entry: T) => boolean | undefined,
  reverseBool = false
) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (aValue === bValue) {
    return 0;
  }
  if (reverseBool) {
    return aValue ? -1 : 1;
  }
  return aValue ? 1 : -1;
};

export const sortString = <T>(
  a: Row<T>,
  b: Row<T>,
  getValue: (entry: T) => string | undefined | null,
  options?: Intl.CollatorOptions
) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (aValue === bValue) {
    return 0;
  }
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return bValue.localeCompare(aValue, undefined, options);
  }
  return typeof aValue === 'string' ? -1 : 1;
};

export const sortDate = <T>(a: Row<T>, b: Row<T>, getValue: (entry: T) => Date | string | undefined | null) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (dayjs(aValue).utc(true).isSame(dayjs(bValue).utc(true))) {
    return 0;
  }

  if (dayjs(aValue).isValid() && dayjs(bValue).isValid()) {
    return dayjs(aValue).utc(true).isBefore(dayjs(bValue).utc(true)) ? 1 : -1;
  }

  return dayjs(aValue).isValid() ? 1 : -1;
};
