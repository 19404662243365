import { useCallback, useContext, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PaymentsAPI } from '@v2/feature/payments/payments.api';
import { ContractorInvoice, ContractorInvoiceStatus, DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import { getContractorInvoiceStatusComponent } from '@v2/feature/payments/utils/get-contractor-invoice-status.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import moment from 'moment/moment';
import { generatePath, useHistory } from 'react-router-dom';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { nestErrorMessage } from '@/lib/errors';
import { INVOICES_COMPANY_EDIT_INVOICE_ROUTE, INVOICES_ME_EDIT_INVOICE_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { RejectDrawer } from '@/v2/components/reject-drawer.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { BufferData } from '@/v2/feature/documents/documents.interface';
import { LineItemInDrawerComponent } from '@/v2/feature/payments/components/line-item-in-drawer.component';
import { ViewerItem } from '@/v2/feature/payments/components/payment-details-drawer.component';
import { getTaxRateForTotalSection } from '@/v2/feature/payments/expenses.util';
import { getFinalGrossForLineItemInvoice } from '@/v2/feature/payments/utils/invoice.util';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { iconSize } from '@/v2/styles/menu.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface ContractorInvoiceModalProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedInvoice: ContractorInvoice;
  readonly onClose: () => void;
  readonly afterClose?: () => void;
  readonly onActionPerformed: () => Promise<void>;
  readonly currentUserIsAdmin: boolean;
  readonly reach?: 'me' | 'team' | 'company';
}

export const ContractorInvoiceModal = ({
  isOpen,
  setIsOpen,
  selectedInvoice,
  onClose,
  afterClose,
  onActionPerformed,
  currentUserIsAdmin,
  reach = 'me',
}: ContractorInvoiceModalProps) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const [isUpdatingApproval, setIsUpdatingApproval] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const invoiceFileName = `Invoice_${selectedInvoice?.fromUser?.firstName}${selectedInvoice?.fromUser?.lastName}_${selectedInvoice?.invoiceNumber}`;

  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState<boolean>(false);

  // for attachment preview
  const [attachmentBuffer, setAttachmentBuffer] = useState<BufferData | undefined>();
  const [selectedDocContentType, setSelectedDocContentType] = useState<string>('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const currentUserIsApproverOfInvoice = selectedInvoice.approver?.includes(user.userId);

  const currentUserIsInvoiceAdmin = hasScopes(
    ['invoices:all', 'invoices:manager'],
    getScopesContext({ userId: user?.userId })
  );

  const currentUserIsManagerOfInvoiceOwner = selectedInvoice?.from
    ? checkScopes(user, ['invoices:manager'], { userId: selectedInvoice.from })
    : false;

  const hasRights = currentUserIsManagerOfInvoiceOwner || currentUserIsInvoiceAdmin;
  const draftInvoice = selectedInvoice?.status === ContractorInvoiceStatus.Draft;

  const handleApproveOrReject = useCallback(
    async (invoiceId: string, userId: number, status: ContractorInvoiceStatus, notes?: string) => {
      try {
        setIsUpdatingApproval(true);

        await ContractorInvoiceAPI.updateInvoiceApproval(invoiceId, userId, status, notes);

        showMessage(polyglot.t('ContractorInvoiceModal.successMessages.update', { status }), 'success');
        setIsUpdatingApproval(false);
        setIsRejectDrawerOpen(false);

        onClose();
        await onActionPerformed();
      } catch (e) {
        showMessage(
          polyglot.t('ContractorInvoiceModal.errorMessages.update', { errorMessage: nestErrorMessage(e) }),
          'error'
        );
        setIsUpdatingApproval(false);
      }

      try {
        const [contractorInvoices, payments] = await Promise.all([
          ContractorInvoiceAPI.getAlerts(),
          PaymentsAPI.getAlerts(),
        ]);
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { contractorInvoices, payments },
        });
      } catch (error) {
        console.error('Could not update invoices alerts.', error);
      }
    },
    [showMessage, polyglot, onClose, onActionPerformed, dispatch]
  );

  const syncInvoiceWithAccountingProvider = async (invoiceId: string) => {
    try {
      setLoading(true);
      await ContractorInvoiceAPI.syncInvoiceWithExternalProvider(invoiceId);
      showMessage(polyglot.t('ContractorInvoiceModal.successMessages.sync'), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('ContractorInvoiceModal.errorMessages.sync', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadClick = async (invoiceId: string) => {
    try {
      const PDFBlob = await ContractorInvoiceAPI.downloadInvoice({ invoiceId });

      const file = new Blob([PDFBlob], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      let link = document.createElement('a');
      link.download = `${invoiceFileName}.pdf`;
      link.href = fileURL;
      link.click();
    } catch (e) {
      console.error('::Download error', e);
      showMessage(`Failed to download contractor invoice. ${nestErrorMessage(e)}`, 'error');
    }
  };

  const handlePreviewClick = useCallback(
    async (invoice: ContractorInvoice) => {
      try {
        const PDFBlob = await ContractorInvoiceAPI.downloadInvoice({ invoiceId: invoice.id });

        const file = new Blob([PDFBlob], { type: 'application/pdf' });
        const arrayBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const fileBuffer = Buffer.from(uint8Array);

        const bufferData: BufferData = { data: fileBuffer };

        setAttachmentBuffer(bufferData);
        setSelectedDocContentType('application/pdf');
        setOpenPreviewModal(true);
      } catch (e) {
        console.error('::Download error', e);
        showMessage(`Failed to preview contractor invoice. ${nestErrorMessage(e)}`, 'error');
      }
    },
    [showMessage]
  );
  const invoicePending = selectedInvoice?.status === ContractorInvoiceStatus.Pending;

  const canEditInvoice =
    hasRights && (draftInvoice || invoicePending)
      ? true
      : selectedInvoice && selectedInvoice.createdBy && user.userId === selectedInvoice.createdBy && draftInvoice
      ? true
      : selectedInvoice && selectedInvoice.from
      ? hasScopes(['invoices'], getScopesContext({ userId: selectedInvoice.from })) &&
        user.userId === selectedInvoice?.from
      : false;

  const canDeleteOrEditInvoice =
    selectedInvoice?.status &&
    [
      ContractorInvoiceStatus.Draft,
      ContractorInvoiceStatus.Pending,
      ContractorInvoiceStatus.Rejected,
      ContractorInvoiceStatus.Voided,
    ].includes(selectedInvoice?.status);

  const currentUserIsBeneficiaryOfInvoice = user.userId === selectedInvoice?.from;

  const deleteInvoice = useCallback(
    async (invoiceId: string): Promise<void> => {
      try {
        await ContractorInvoiceAPI.deleteInvoice(invoiceId);
        await onActionPerformed();
      } catch (error) {
        showMessage(`Could not delete invoice. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [onActionPerformed, showMessage]
  );

  const navigateToInvoicePageToEdit = useCallback(() => {
    if (selectedInvoice)
      if (
        !selectedInvoice ||
        !selectedInvoice?.id ||
        !(
          selectedInvoice?.status === ContractorInvoiceStatus.Pending ||
          selectedInvoice?.status === ContractorInvoiceStatus.Draft
        )
      )
        return;
    if (reach === 'me') {
      routerHistory.push(generatePath(INVOICES_ME_EDIT_INVOICE_ROUTE, { id: selectedInvoice?.id }), {
        invoiceToEdit: selectedInvoice,
      });
    } else if (reach === 'company') {
      routerHistory.push(generatePath(INVOICES_COMPANY_EDIT_INVOICE_ROUTE, { id: selectedInvoice?.id }), {
        invoiceToEdit: selectedInvoice,
      });
    }
  }, [reach, routerHistory, selectedInvoice]);

  const groupedItems = selectedInvoice.lineItems
    .filter((item) => item.taxRate != null && item.amount != null && item.gross != null)
    .reduce((acc, item) => {
      const taxRate = item.taxRate!; // Using non-null assertion since we have already filtered out null/undefined
      const gross = item.gross!;
      const amount = item.amount!;

      if (!acc[taxRate]) {
        acc[taxRate] = 0;
      }

      acc[taxRate] += gross - amount;
      return acc;
    }, {} as Record<number, number>);

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose} afterClose={afterClose}>
      <Box sx={{ ...drawerContentSx, boxSizing: 'border-box' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="title2">{polyglot.t('ContractorInvoiceModal.title')}</Typography>
          {((canDeleteOrEditInvoice && currentUserIsAdmin) ||
            (invoicePending && currentUserIsBeneficiaryOfInvoice) ||
            (draftInvoice && canEditInvoice)) && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
              {(invoicePending || draftInvoice) && canEditInvoice && (
                <IconButton
                  disabled={isUpdatingApproval}
                  fullWidth
                  sizeVariant="small"
                  colorVariant="secondary"
                  onClick={() => {
                    navigateToInvoicePageToEdit();
                  }}
                  name={polyglot.t('General.edit')}
                >
                  <EditIcon {...iconSize} />
                </IconButton>
              )}
              <IconButton
                disabled={isUpdatingApproval}
                fullWidth
                sizeVariant="small"
                colorVariant="secondary"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setIsRemovalModalOpen(true);
                }}
                name={polyglot.t('General.delete')}
              >
                <TrashIcon {...iconSize} />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">{polyglot.t('ContractorInvoiceModal.paidTo')}</Typography>
          <Box>
            <UserCell userId={selectedInvoice.from} nameVariant="title4" />
          </Box>
        </Box>

        {selectedInvoice.type && (
          <ViewerItem title={polyglot.t('ContractorInvoiceModal.policy')} value={selectedInvoice.type?.name} />
        )}
        {selectedInvoice.notes && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.description')}
            value={
              selectedInvoice?.notes && selectedInvoice.notes.length > 40 ? (
                <StyledTooltip title={selectedInvoice.notes} placement="top">
                  <span>{truncateWithEllipses(selectedInvoice.notes, 40)}</span>
                </StyledTooltip>
              ) : (
                <div>{truncateWithEllipses(selectedInvoice.notes, 40)}</div>
              )
            }
          />
        )}
        {selectedInvoice.invoiceDate && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.date')}
            value={moment(selectedInvoice.invoiceDate).format('DD MMM YYYY')}
          />
        )}
        <ViewerItem
          title={polyglot.t('ContractorInvoiceModal.type')}
          value={selectedInvoice?.lineItems?.length === 1 ? 'Single' : 'Multiple'}
        />
        {selectedInvoice.updatedBy && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.lastUpdatedBy')}
            value={<UserCell userId={selectedInvoice.updatedBy} nameVariant="title4" />}
          />
        )}
        {selectedInvoice.dueDate && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.dueDate')}
            value={moment(selectedInvoice.dueDate).format('DD MMM YYYY')}
          />
        )}

        {selectedInvoice?.lineItems?.map((item, index) => (
          <LineItemInDrawerComponent key={item.id} item={item} index={index} currency={selectedInvoice.currency} />
        ))}
        <ViewerItem
          title={polyglot.t('ContractorInvoiceModal.status')}
          value={getContractorInvoiceStatusComponent(selectedInvoice.status, themeFonts.title4)}
        />
        {!selectedInvoice.amount && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.amount')}
            value={formatCurrency(selectedInvoice.totalAmount, undefined, selectedInvoice.currency) as string}
          />
        )}
        {selectedInvoice.totalAmount && selectedInvoice.amount && (
          <ViewerItem
            title={polyglot.t('NewInvoicePage.totalExcludingTax')}
            value={
              formatCurrency(
                selectedInvoice.amount ? (selectedInvoice.amount as number) : 0,
                undefined,
                selectedInvoice.currency ?? DEFAULT_CURRENCY
              ) as string
            }
          />
        )}

        {Object.keys(groupedItems).length > 0
          ? Object.keys(groupedItems).map((taxRate) => (
              <ViewerItem
                key={taxRate}
                title={`${polyglot.t('NewInvoicePage.taxAmount')} ${getTaxRateForTotalSection(Number(taxRate))}`}
                value={
                  formatCurrency(
                    groupedItems[Number(taxRate)],
                    undefined,
                    selectedInvoice.currency || DEFAULT_CURRENCY
                  ) as string
                }
              />
            ))
          : null}

        {selectedInvoice.amount && selectedInvoice.taxRate !== null && (
          <ViewerItem
            title={polyglot.t('NewInvoicePage.totalIncludingTax')}
            value={
              formatCurrency(
                selectedInvoice.lineItems?.length > 0
                  ? getFinalGrossForLineItemInvoice(selectedInvoice.lineItems) ?? 0
                  : 0,
                undefined,
                selectedInvoice.currency ?? DEFAULT_CURRENCY
              ) as string
            }
          />
        )}

        {selectedInvoice.approvedByUser && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.approvedBy')}
            value={<UserCell userId={selectedInvoice.approvedByUser.userId} nameVariant="title4" />}
          />
        )}
        {selectedInvoice.approvedOn && (
          <ViewerItem
            title={polyglot.t('ContractorInvoiceModal.approvedOn')}
            value={new Date(selectedInvoice.approvedOn).toLocaleString()}
          />
        )}

        {selectedInvoice.status === ContractorInvoiceStatus.Rejected &&
          selectedInvoice.approvalNotes &&
          selectedInvoice.approvalNotes?.length > 0 && (
            <ViewerItem title={polyglot.t('ContractorInvoiceModal.notes')} value={selectedInvoice.approvalNotes} />
          )}

        <Box sx={{ display: 'flex', gap: spacing.gap10, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DocumentIcon {...iconSize} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
            <Typography variant="caption">{invoiceFileName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap5 }}>
            <IconButton
              sizeVariant="small"
              colorVariant="secondary"
              onClick={() => handleDownloadClick(selectedInvoice.id)}
            >
              <DownloadIcon {...iconSize} />
            </IconButton>
            <IconButton
              sizeVariant="small"
              colorVariant="secondary"
              onClick={(event) => {
                event.stopPropagation();
                handlePreviewClick(selectedInvoice);
              }}
            >
              <Eye {...iconSize} />
              {openPreviewModal && attachmentBuffer && (
                <DocPreviewer
                  fileBuffer={attachmentBuffer}
                  contentType={selectedDocContentType}
                  visible={openPreviewModal}
                  onClose={() => {
                    setOpenPreviewModal(false);
                    setAttachmentBuffer(undefined);
                  }}
                  title={invoiceFileName}
                />
              )}
            </IconButton>
          </Box>
        </Box>

        <AccountingIntegrationSection
          selectedInvoice={selectedInvoice}
          syncInvoiceWithAccountingProvider={syncInvoiceWithAccountingProvider}
          loading={loading}
        />

        {selectedInvoice.status === ContractorInvoiceStatus.Pending &&
          (currentUserIsAdmin || currentUserIsApproverOfInvoice) && (
            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                disabled={isUpdatingApproval}
                fullWidth
                sizeVariant="medium"
                colorVariant="secondary"
                onClick={async () => {
                  setIsRejectDrawerOpen(true);
                }}
              >
                {polyglot.t('General.reject')}
              </ButtonComponent>
              <ButtonComponent
                disabled={isUpdatingApproval}
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth
                onClick={async () => {
                  await handleApproveOrReject(
                    selectedInvoice.id,
                    selectedInvoice.from,
                    ContractorInvoiceStatus.Approved
                  );
                }}
              >
                {polyglot.t('General.approve')}
              </ButtonComponent>
            </Box>
          )}

        {isRejectDrawerOpen && selectedInvoice && (
          <RejectDrawer
            isOpen={isRejectDrawerOpen}
            setIsOpen={setIsRejectDrawerOpen}
            onReject={async (notes) => {
              await handleApproveOrReject(
                selectedInvoice.id,
                selectedInvoice.from,
                ContractorInvoiceStatus.Rejected,
                notes
              );
            }}
          />
        )}

        <NotificationModal
          isOpen={isRemovalModalOpen}
          onClose={() => setIsRemovalModalOpen(false)}
          anchorEl={anchorEl}
          takeAction={async () => {
            if (selectedInvoice) await deleteInvoice(selectedInvoice.id);
          }}
          message={`Are you sure you want to delete this invoice?`}
          callToAction="Yes"
        />

        {openPreviewModal && attachmentBuffer && (
          <DocPreviewer
            fileBuffer={attachmentBuffer}
            contentType={selectedDocContentType}
            visible={openPreviewModal}
            onClose={() => {
              setOpenPreviewModal(false);
              setAttachmentBuffer(undefined);
            }}
            title={invoiceFileName}
          />
        )}
      </Box>
    </DrawerModal>
  );
};

const AccountingIntegrationSection = ({
  selectedInvoice,
  syncInvoiceWithAccountingProvider,
  loading,
}: {
  readonly selectedInvoice: ContractorInvoice;
  readonly syncInvoiceWithAccountingProvider: (invoiceId: string) => Promise<void>;
  readonly loading: boolean;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15, paddingBottom: spacing.s2 }}>
      <Typography variant="title4" sx={spacing.mb5}>
        {polyglot.t('ContractorInvoiceModal.accountingIntegration')}
      </Typography>
      {selectedInvoice.externalId && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="caption">External invoice</Typography>
          <a
            href={`https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${selectedInvoice.externalId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Box component="span" sx={themeFonts.caption}>
              See here
            </Box>
          </a>
        </Box>
      )}

      {selectedInvoice?.lineItems &&
      selectedInvoice?.lineItems.length > 0 &&
      selectedInvoice.lineItems.some((item) => item.accountingCode !== null) ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            {Array.from(new Set(selectedInvoice.lineItems.map((item) => item.accountingCode).filter(Boolean))).map(
              (accountingCode) => {
                const item = selectedInvoice.lineItems.find((i) => i.accountingCode === accountingCode);
                return (
                  <Typography variant="caption" key={accountingCode}>
                    {`${polyglot.t('PaymentSettingsPage.tableColumns.accountingCode')} (${accountingCode}${
                      item?.accountingCodeDescription
                        ? truncateWithEllipses(' - '.concat(item?.accountingCodeDescription), 15)
                        : ''
                    })`}
                  </Typography>
                );
              }
            )}
          </Box>

          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="small"
            disabled={loading}
            onClick={async () => {
              await syncInvoiceWithAccountingProvider(selectedInvoice.id);
            }}
          >
            <RefreshIcon sx={{ ...iconSize }} />
            {polyglot.t(
              selectedInvoice?.externalId ? 'ContractorInvoiceModal.syncAgain' : 'ContractorInvoiceModal.syncExternally'
            )}
          </ButtonComponent>
        </Box>
      ) : (
        <Typography variant="caption">No accounting code</Typography>
      )}
    </Box>
  );
};
