import { useContext } from 'react';

import { TasksUserPage } from '@v2/feature/task/pages/tasks-user.page';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { TASKS_ME_OVERVIEW_ROUTE } from '@/lib/routes';

export const TaskMeRouter = (): JSX.Element => {
  const [state] = useContext(GlobalContext);
  const { userId } = state.user;

  return (
    <Switch>
      <RouteScopesHas scopes={['task']} path={TASKS_ME_OVERVIEW_ROUTE} exact>
        <TasksUserPage userId={userId} />
      </RouteScopesHas>

      <Redirect to={TASKS_ME_OVERVIEW_ROUTE} />
    </Switch>
  );
};
