import { HolidayDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';

import { AbsenceDto, AbsencePolicyDto, AbsenceSettingsDto } from '@/v2/feature/absence/absence.dto';
import { CompanyEvent } from '@/v2/feature/calendar/calendar.interface';

export enum AbsenceStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum HolidaysProratingRule {
  AllInCycle = 'AllInCycle',
  AllInPeriod = 'AllInPeriod', // Actual (work-pattern)
  AllInPeriodProrated = 'AllInPeriodProrated', // Actual (prorated by work)
  FullValueProrated = 'FullValueProrated',
}

export interface AbsenceBalanceCarryOver {
  isEnabled: boolean;
  companyLimit: number | null;
  unusedUnitsLastCycle: number;
  allowedUnitsThisCycle: number;
  used: number;
  remaining: number;
  expiryDate: Date | null;
  isCarryOverExpired: boolean;
  expiredCarryOver: number | null;
}

export interface AbsenceBalanceTenure {
  tenureValueInUnits: number;
  tenureValueInMinutes: number;
}

export type AbsenceVerifyPayload = {
  absenceStart: string;
  absenceEnd: string | null;
  absenceId: number | null;
  userIds: number[];
};

export type AbsenceVerificationResult = {
  isOverlapping: boolean;
  absences: AbsenceDto[];
  events: CompanyEvent[];
  preventOwnOverlaps: boolean;
};

export interface ProRatedAbsenceAllowanceBreakdownSegment {
  segmentStart: string;
  segmentEnd: string;
  fteByWorkingDaysInWeek: number;
  segmentValue: number;
  holidayCalendar: string;
  customHolidays: HolidayDto[];
  segmentPublicHolidaysValue: number;
  publicHolidaysInYear: number;
  publicHolidaysInSegment: number;
  usedPublicHolidaysInSegment: number;
  bookedPublicHolidaysInSegment: number;
  unusedPublicHolidaysInSegment: number;
  unbookedPublicHolidaysInSegment: number;
  daysInSegment: number;
  daysInCycle: number;
  averageValueOf1WorkDay: number;
  userNoOfWorkingDays: number;
  fteEquivalentNoOfWorkingDays: number;
}

export interface ProRatedAbsenceAllowanceBreakdown {
  userCycleStartDate: string;
  userCycleEndDate: string;
  fteSegments: ProRatedAbsenceAllowanceBreakdownSegment[] | null;
  proRatedAbsenceAllowance: number | null;
  totalProratedAllowanceNonRounded: number | null;
  totalProratedAllowance: number | null;
  proRatedPublicHolidayAllowance: number;
  totalUnusedPublicHolidays: number;
  totalRegularAbsenceAccrued: number | null;
  totalPublicHolidaysAccrued: number | null;
  totalAccruedFromAllowanceNonRounded: number | null;
  totalAccruedFromAllowanceRounded: number | null;
  totalAccruedWithoutPHAccrualUsedOrBooked: number | null;
  accrualStartDate: string | null;
  accrualEndDate: string | null;
  accrualCliff: AccrualCliff | null;
  didNotPassAccrualCliff: boolean;
}

export interface AbsenceBreakdown {
  policyName: string;
  holidayYear: number;
  lengthUnit: AbsenceLengthUnit;
  userAllowance: number | null;
  currentBalance: number | null;
  currentBalanceInDays: number | null;
  proRatedAbsenceAllowance: ProRatedAbsenceAllowanceBreakdown;
  holidayAccrued: number | null;
  totalAllowanceForCycle: number | null;
  oneOffAdjustment: number;
  unitsTaken: PolicyDaysTaken;
  unitsLeft: PolicyDaysBooked;
  carryOver: AbsenceBalanceCarryOver;
  tenure: AbsenceBalanceTenure;
  isOnRegularSchedule: boolean;
  currentAverageWorkDayLength: number;
  currentHolidayYear: number;
  currentCycle: [string, string];
}

export interface PolicyDaysTaken {
  history: number;
  upcoming: number;
  historyPending: number;
  upcomingPending: number;
  totalRegularUnits: number;
  publicHolidaysHistory: number;
  publicHolidaysUpcoming: number;
  totalPublicHolidays: number;
  total: number;
}

export interface PolicyDaysBooked {
  unusedPublicHolidays: number;
  regularUnitsLeft: number;
}

export enum AccrualCliff {
  Probation = 'Probation',
}

export enum AccrualUnit {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum AllowanceRoundingType {
  NoRounding = 'NoRounding',
  UpToHalfDay = 'UpToHalfDay',
  UpToFullDay = 'UpToFullDay',
}

export type CreateAbsencePolicy = Pick<AbsencePolicyDto, 'name' | 'color' | 'allowanceType' | 'allowance'>;

export type PolicyGeneralFormData = Pick<
  AbsencePolicyDto,
  'name' | 'color' | 'fullName' | 'isPublic' | 'attachmentType' | 'cycleStartDay' | 'cycleStartMonth'
>;

export type PolicyAllowanceFormData = Pick<AbsencePolicyDto, 'allowance' | 'allowanceType'>;

export type PolicyAllowanceTenureData = Pick<
  AbsencePolicyDto,
  'tenureEnabled' | 'tenureAdditionSettings' | 'tenureAllocation'
>;

export type PolicyAccrualData = Pick<
  AbsencePolicyDto,
  'accrualUnit' | 'accrualUnitsNo' | 'accrueUpfront' | 'accrualCliff'
>;

export type PolicyPublicHolidaysFormData = Pick<
  AbsencePolicyDto,
  'publicHolidayAutobook' | 'publicHolidayOnTop' | 'holidaysProratingRule'
>;

export type PolicyCarryOverFormData = Pick<
  AbsencePolicyDto,
  'carryOverEnabled' | 'carryOverLimit' | 'carryOverExpirationMonth' | 'carryOverExpirationDay'
>;

export type PolicyProratingFormData = Pick<AbsencePolicyDto, 'proratingStartDate' | 'proratingFte'>;

export type PolicyRoundingFormData = Pick<AbsencePolicyDto, 'allowanceRoundingType'>;

export type UpdateAbsenceSettings = Pick<
  AbsenceSettingsDto,
  'holidayCountry' | 'preventOwnOverlaps' | 'showOverlap' | 'overlapsRule'
>;

export type PolicyMembersFormData = Pick<AbsencePolicyDto, 'membersRule' | 'customRule' | 'selectedMembersIds'>;

export type PolicyApprovalFormData = Pick<AbsencePolicyDto, 'backoffApprovalRuleId' | 'updateApprovalNotRequired'>;

export type PolicyNotificationsFormData = Pick<AbsencePolicyDto, 'notificationsEnabled' | 'remindersSettings'>;

export type PolicyPayrollFormData = Pick<
  AbsencePolicyDto,
  'inPayrollRequests' | 'inPayrollOffboarding' | 'payCode' | 'payCodeMultiplier'
>;

export interface TimePolicyReminderSettings {
  slack: boolean;
  slackChannels: string[];
  remindersScheduledHour: number;
  remindersScheduledMinute: number;
}

export interface AbsencePolicyMember {
  userId: number;
  absencePolicyId: number;
}

export enum AbsencePolicyAllowanceType {
  Limited = 'Limited',
  Unlimited = 'Unlimited',
  LimitedHourly = 'LimitedHourly',
  UnlimitedHourly = 'UnlimitedHourly',
}

export enum AbsenceLengthUnit {
  Day = 'day',
  Minute = 'minute',
}

export interface OneOffAbsenceAdjustmentEntry {
  id: string;
  value: number;
  createdAt: Date;
  createdBy?: number;
  notes?: string;
}

export enum OverlapsRule {
  Everyone = 'Everyone',
  DirectReports = 'DirectReports',
  Department = 'Department',
}
