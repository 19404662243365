import { useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/lib/ReadonlyArray';

import useMessage from '@/hooks/notification.hook';
import { ZeltDocument } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ShareViaEmailDrawer = ({
  selectedDocument,
  isOpen,
  setSelectedDocument,
  setIsOpen,
}: {
  selectedDocument: ZeltDocument | null;
  isOpen: boolean;
  readonly setSelectedDocument: React.Dispatch<React.SetStateAction<ZeltDocument | null>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showMessage] = useMessage();
  const [noteString, setNoteString] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [recipientUser, setRecipientUser] = useState<number | null>(
    selectedDocument?.belongsTo?.length === 1 && selectedDocument?.belongsTo[0]?.User?.userId
      ? selectedDocument?.belongsTo[0]?.User?.userId
      : null
  );

  const belongsToUserOptions = pipe(
    nonTerminatedCachedUsers,
    RA.map((u) => ({ value: u.userId, label: u.displayName, ...u } as const))
  );

  const documentFileUuid =
    selectedDocument?.attachments?.length === 1 && selectedDocument?.attachments[0]?.fileUuid
      ? selectedDocument?.attachments[0]?.fileUuid
      : selectedDocument?.fileUuid ?? null;

  const handleButtonClick = async () => {
    try {
      setLoading(true);

      if (selectedDocument && documentFileUuid && recipientUser) {
        await DocumentAPI.shareDocumentViaEmail(documentFileUuid, recipientUser, noteString);
        setIsOpen(false);
        showMessage('Document successfully shared', 'success');
      } else {
        if (!documentFileUuid) showMessage('Document missing attachment - cannot share via email', 'error');
        if (!recipientUser) showMessage('Recipient missing - cannot share via email', 'error');
      }
    } catch (error) {
      showMessage(`Could not send notification. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
        setSelectedDocument(null);
      }}
    >
      <div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          {selectedDocument?.name && <Typography variant="title2">Share via email</Typography>}
          <ViewItem label="Document being shared" value={selectedDocument?.name ? selectedDocument?.name : '-'} />
          <SingleUserSelect
            name="singleownership"
            options={belongsToUserOptions}
            onChange={(_, value) => {
              if (value) {
                setRecipientUser(value.userId);
              } else {
                setRecipientUser(null);
              }
            }}
            value={recipientUser}
            label="Share document to"
            error={false}
            helperText={false}
          />
        </Box>

        <FormControl sx={{ mt: spacing.m30 }} error={error} fullWidth>
          <TextfieldComponent
            multiline
            name="note"
            label="Note"
            value={noteString}
            type="text"
            onChange={(e) => {
              setNoteString(e.target.value);
              setError(false);
            }}
            endAdornment="none"
          />
        </FormControl>

        <Box sx={spacing.mt40}>
          <LoaderButton
            loading={loading}
            colorVariant="secondary"
            sizeVariant="large"
            fullWidth
            onClick={handleButtonClick}
            name="Share document"
            disabled={!documentFileUuid || !recipientUser}
          />
        </Box>
      </div>
    </DrawerModal>
  );
};

const ViewItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g4 }}>
      <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
        {label}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};
