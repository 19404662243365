import axios from 'axios';

import {
  CombinedAllFields,
  CustomProfileFieldDto,
  CustomProfileFormDto,
  UserCustomDataDto,
  UserCustomDataValue,
} from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class CustomProfileFormAPI {
  static async addFields(fieldBody: CustomProfileFieldDto): Promise<CustomProfileFieldDto[]> {
    return (await axios.post<CustomProfileFieldDto[]>('/apiv2/company/fields/add-field', { fieldBody })).data;
  }

  static async deleteField(fieldId: string): Promise<number> {
    return (await axios.delete<number>(`/apiv2/company/fields/${fieldId}`)).data;
  }

  static async createForm(form: Pick<CustomProfileFormDto, 'formTab' | 'formName'>): Promise<CustomProfileFormDto> {
    return (await axios.post(`/apiv2/company/forms/create`, form)).data;
  }

  static async updateForm(formId: string, form: Pick<CustomProfileFormDto, 'formName'>): Promise<CustomProfileFormDto> {
    return (await axios.patch(`/apiv2/company/forms/${formId}`, form)).data;
  }

  static async deleteForm(formId: string): Promise<void> {
    await axios.delete(`/apiv2/company/forms/${encodeURIComponent(formId)}`);
  }

  static async setFormValues(
    userId: number,
    formId: string,
    values: UserCustomDataValue[]
  ): Promise<UserCustomDataDto[]> {
    return (await axios.patch(`/apiv2/users/${userId}/custom/${formId}`, { values })).data;
  }

  static async listForms(): Promise<CustomProfileFormDto[]> {
    return (await axios.get('/apiv2/company/forms')).data;
  }

  static async getFormValues(userId: number): Promise<UserCustomDataDto[]> {
    return (await axios.get(`/apiv2/users/${userId}/custom`)).data;
  }
}

export class CustomProfileFormEndpoints {
  static listForms(): Endpoint<CustomProfileFormDto[]> {
    return {
      url: '/apiv2/company/forms',
    };
  }

  // returns all fields as not hidden if current user has user:all scope
  static listAllFieldsProfileHook(): Endpoint<CombinedAllFields> {
    return {
      url: '/apiv2/company/fields/all-fields-profile',
    };
  }

  static listAllFields(): Endpoint<CombinedAllFields> {
    return {
      url: '/apiv2/company/fields/all-fields',
    };
  }

  static listAllDateFields(): Endpoint<CombinedAllFields> {
    return {
      url: '/apiv2/company/fields/all-date-fields',
    };
  }

  static getFormValues(userId: number): Endpoint<UserCustomDataDto[]> {
    return { url: `/apiv2/users/${userId}/custom` };
  }
}
