import { PayRunSchedule, TaxYearStart, UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';
import { add as addDate, endOfDay, isAfter, isBefore, isWithinInterval, startOfDay } from 'date-fns';

import {
  NewEmployeeTaxCode,
  StarterDeclaration,
  TaxCode,
  TaxYear,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.interface';
import {
  ExternalEmpReferences,
  ExternalHmrcDetails,
  ExternalPayLine,
  PayPeriod,
} from '@/v2/feature/payroll/payroll-external.interface';
import { PayLineEntry, PayRunEntryDto } from '@/v2/feature/payroll/payroll.dto';
import {
  PayScheduleEnum,
  SalaryBasisEnum,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { StaffologyPayCode } from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { sum } from '@/v2/util/array.util';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

export const UK_TAX_YEAR_START: TaxYearStart = '04-06'; // April 6th (UK)

//  Tax Year in the UK starts on 6th April
function startDateAtYear(year: number): Date {
  return startOfDay(new Date(Date.UTC(year, 3, 6)));
}

//  Tax Year in the UK ends on 5th April the year after
function endDateAtYear(year: number): Date {
  return endOfDay(new Date(Date.UTC(year + 1, 3, 5)));
}

function cutoffDateAtYear(year: number): Date {
  return startOfDay(new Date(Date.UTC(year, 4, 25)));
}

export function getUKTaxYear(date = new Date()): TaxYear {
  let currentYear = date.getFullYear();
  if (isBefore(date, startDateAtYear(currentYear))) {
    currentYear -= 1;
  } else if (isAfter(date, endDateAtYear(currentYear + 1))) {
    currentYear += 1;
  }
  return {
    start: startDateAtYear(currentYear),
    end: endDateAtYear(currentYear),
    cutOff: cutoffDateAtYear(currentYear),
  };
}

export function getUKTaxYearStartDate(relativeDate?: string): string {
  const date = relativeDate ?? todaysDateShortISOString();
  let year = Number(date.slice(0, 4));
  // if the date is before the 6th April, the tax year starts in the previous year
  if (date.slice(5) < UK_TAX_YEAR_START) {
    year -= 1;
  }
  return `${year}-${UK_TAX_YEAR_START}`;
}

export function showTaxYear(y: TaxYear): `Year${number}` {
  return `Year${y.start.getFullYear()}`;
}

function isCurrentUKTaxYear(date: Date): boolean {
  const { start, end } = getUKTaxYear(new Date());
  return isWithinInterval(date, { start, end });
}

export function getNewEmployeeTaxCodeNoP45(starterDeclaration: StarterDeclaration): NewEmployeeTaxCode {
  switch (starterDeclaration) {
    case 'A':
      return { taxCode: '1257L', starterDeclaration: 'A', week1Month1: false };
    case 'B':
      return { taxCode: '1257L', starterDeclaration: 'B', week1Month1: true };
    case 'C':
      return { taxCode: 'BR', starterDeclaration: 'C', week1Month1: false };
    default:
      throw new Error('Invalid starter declaration');
  }
}

function getTaxCodeFromPrevious(previousTaxCode: TaxCode): TaxCode {
  const lastChar = previousTaxCode.charAt(previousTaxCode.length - 1);
  let taxCodeDigits = 0;
  try {
    taxCodeDigits = Number(previousTaxCode.substring(0, previousTaxCode.length - 1));
  } catch (e) {
    throw new Error('Invalid tax code, should be {Number}{Letter}; ie.: 123L');
  }

  if (lastChar === 'L') taxCodeDigits += 7;
  if (lastChar === 'M') taxCodeDigits += 8;
  if (lastChar === 'N') taxCodeDigits += 6;

  return `${taxCodeDigits}${lastChar}`;
}

export function getNewEmployeeTaxCodeWithP45(
  startDate: Date,
  leaveDate: Date,
  previousTaxCode: TaxCode
): NewEmployeeTaxCode {
  if (['BR', '0T', 'D0', 'D1'].includes(previousTaxCode)) {
    return { taxCode: previousTaxCode, starterDeclaration: 'C', week1Month1: false };
  }

  if (isCurrentUKTaxYear(leaveDate)) {
    return { taxCode: previousTaxCode, starterDeclaration: 'B', week1Month1: false };
  }

  const startTaxYear = getUKTaxYear(startDate);
  if (isAfter(startDate, startTaxYear.cutOff)) {
    return { taxCode: '1257L', starterDeclaration: 'B', week1Month1: false };
  }

  const lastChar = previousTaxCode.charAt(previousTaxCode.length - 1);
  if (!['L', 'M', 'N'].includes(lastChar)) {
    return { taxCode: previousTaxCode, starterDeclaration: 'B', week1Month1: false };
  }

  const taxCode = getTaxCodeFromPrevious(previousTaxCode);
  return { taxCode, starterDeclaration: 'B', week1Month1: false };
}

// Do not localise!
export enum PayrollStatusLabel {
  Current = 'Current',
  NewJoiner = 'New joiner',
  Leaver = 'Leaver',
  NotInPayroll = 'Not in payroll',
}

export const IconMapping: Record<PayrollStatusLabel, PayrollStatusIcon> = {
  [PayrollStatusLabel.Current]: 'status-current',
  [PayrollStatusLabel.Leaver]: 'status-leaver',
  [PayrollStatusLabel.NotInPayroll]: 'not-in-payroll',
  [PayrollStatusLabel.NewJoiner]: 'status-new',
};

export type PayrollStatusIcon =
  /* 'in-payroll' | */
  'not-in-payroll' | 'status-new' | 'status-current' | 'status-leaver';

export type PayrollUserStatus = {
  label: PayrollStatusLabel;
  icon: PayrollStatusIcon;
};

export const getUserStatusFromUserPayrollForTableEntry = (
  payrollUser: UserPayrollForTableDto,
  payrun: Pick<PayRunSchedule, 'startDate' | 'endDate'>
): PayrollUserStatus => {
  if (!payrollUser.inPayroll)
    return {
      label: PayrollStatusLabel.NotInPayroll,
      icon: IconMapping[PayrollStatusLabel.NotInPayroll],
    };

  const payrunStartDate = payrun.startDate;
  const payrunEndDate = payrun.endDate;

  const { startDate, leaveDate } = payrollUser.user;
  // if start date between payrun start and end dates => New joiner
  if (startDate && startDate >= payrunStartDate && startDate <= payrunEndDate)
    return { label: PayrollStatusLabel.NewJoiner, icon: IconMapping[PayrollStatusLabel.NewJoiner] };

  // if leave date defined and leave date between payrun start and end dates => New joiner
  if (leaveDate && leaveDate >= payrunStartDate && leaveDate <= payrunEndDate)
    return { label: PayrollStatusLabel.Leaver, icon: IconMapping[PayrollStatusLabel.Leaver] };

  return { label: PayrollStatusLabel.Current, icon: IconMapping[PayrollStatusLabel.Current] };
};

export const getUserStatusFromPayrunEntry = (
  payrunEntry: Pick<PayRunEntryDto, 'startDate' | 'endDate' | 'employmentDetails'> | 'not-in-payroll'
): PayrollUserStatus => {
  if (payrunEntry === 'not-in-payroll') {
    return { label: PayrollStatusLabel.NotInPayroll, icon: IconMapping[PayrollStatusLabel.NotInPayroll] };
  }
  const {
    startDate: payrunStartDate,
    endDate: payrunEndDate,
    employmentDetails: { starterDetails, leaverDetails },
  } = payrunEntry;

  if (leaverDetails?.hasLeft) {
    const { leaveDate } = leaverDetails;
    if (!leaveDate || leaveDate <= payrunEndDate) {
      return { label: PayrollStatusLabel.Leaver, icon: IconMapping[PayrollStatusLabel.Leaver] };
    }
  }

  if (starterDetails?.startDate && starterDetails?.startDate >= payrunStartDate) {
    return { label: PayrollStatusLabel.NewJoiner, icon: IconMapping[PayrollStatusLabel.NewJoiner] };
  }

  return { label: PayrollStatusLabel.Current, icon: IconMapping[PayrollStatusLabel.Current] };
};

export function nextPayrunPeriod(payrun: {
  payPeriod: PayPeriod;
  endDate: string;
}): {
  payPeriod: PayPeriod;
  endDate: string;
} {
  return {
    payPeriod: payrun.payPeriod,
    endDate: addDate(new Date(payrun.endDate), {
      weeks: payrun.payPeriod === 'Weekly' ? 1 : 0,
      months: payrun.payPeriod === 'Monthly' ? 1 : 0,
    })
      .toISOString()
      .slice(0, 10),
  };
}

// dummy data for when the company has not yet received HMRC information
export const HMRCPlaceholderData = {
  officeNumber: '000',
  payeReference: '00000000',
  accountsOfficeReference: '120PM02234138', // this must always be a valid value
  smallEmployersRelief: false,
  govGatewayId: '000000000000',
  contactFirstName: 'Forename',
  contactLastName: 'Surname',
  contactEmail: 'user@company.com',
  password: '',
} as const;

export type HMRCDetails = ({ kind: 'employer' } & ExternalHmrcDetails) | ({ kind: 'fps' } & ExternalEmpReferences);

function extractHMRCParameters(details: HMRCDetails) {
  let officeNumber, payeReference, accountsOfficeReference;
  switch (details.kind) {
    case 'employer':
      ({ officeNumber, payeReference, accountsOfficeReference } = details);
      break;
    case 'fps':
      officeNumber = details.officeNo;
      payeReference = details.payeRef;
      accountsOfficeReference = details.aoRef;
      break;
  }
  return { officeNumber, payeReference, accountsOfficeReference };
}

export function isHMRCSetup(details: HMRCDetails | undefined) {
  if (!details) return false;
  const { officeNumber, payeReference, accountsOfficeReference } = extractHMRCParameters(details);
  return !!officeNumber && !!payeReference && !!accountsOfficeReference && !isUsingPlaceholderHMRCData(details);
}

export function isUsingPlaceholderHMRCData(details: HMRCDetails | undefined) {
  if (!details) return false;
  const { officeNumber, payeReference, accountsOfficeReference } = extractHMRCParameters(details);
  return (
    officeNumber === HMRCPlaceholderData.officeNumber &&
    payeReference === HMRCPlaceholderData.payeReference &&
    accountsOfficeReference === HMRCPlaceholderData.accountsOfficeReference
  );
}

// these are pay codes that are handled separately to regular pay lines
export const ignoredPayLines = new Set([
  'PENSION',
  'PENSIONRAS',
  'PENSIONSS',
  'PENSIONCONTRIB',
  'EMPLOYEEPENCONTROL',
  'EMPLOYERPENCONTROL',
  'STLOAN',
  'PGLOAN',
  'NIC',
  'NIER',
  'EMPLYRNIC',
  'PAYE',
  // BASICDAILY and BASICHOURLY are not editable - BASIC should always be used instead
  'BASICDAILY',
  'BASICHOURLY',
]);

export function extractPayLineEntriesFromPayRunEntry(
  payrunEntry: PayRunEntryDto,
  payCodes: StaffologyPayCode[],
  kind: 'addition' | 'deduction'
): PayLineEntry[] {
  const recurringPaylines = [...payrunEntry.recurringPaylines];
  const extractRecurringPayline = (payline: ExternalPayLine) => {
    // match the recurring payline against the payline code and amount
    const recurranceIdx = recurringPaylines.findIndex(
      ({ amount, code, description }) =>
        payline.code === code && payline.value === amount && payline.description === description
    );
    // remove and return the entry, or undefined
    return recurranceIdx >= 0 ? recurringPaylines.splice(recurranceIdx, 1)[0] : undefined;
  };

  const isDeduction = kind === 'deduction';
  return payrunEntry.payOptions.regularPayLines
    .filter((payline) => !ignoredPayLines.has(payline.code))
    .filter((payline) => payCodes.find((payCode) => payCode.code === payline.code)?.isDeduction === isDeduction)
    .filter((payline) => !payline.isAutoGeneratedBasicPayLine)
    .map<PayLineEntry>((payline) => {
      const recurrance = extractRecurringPayline(payline);
      return {
        id: payline.childId,
        code: payline.code,
        amount: payline.value,
        description: payline.description ?? '',
        isDeduction,
        recurringId: recurrance?.id ?? null,
        recurring: recurrance ? { startDate: recurrance.startDate, endDate: recurrance.endDate } : null,
      };
    });
}

export const getUnitValue = (item: PayRunEntryDto) =>
  item.payOptions.basis === 'Monthly' ? 1 : item.payOptions.payAmountMultiplier;

export const getOptionalPayCodesInUse = (
  payCodes: StaffologyPayCode[],
  payrunEntries: PayRunEntryDto[],
  deduction: boolean
): StaffologyPayCode[] => {
  return payCodes.filter(
    ({ code, isDeduction }) =>
      isDeduction === deduction &&
      // ignore codes that are handled using existing 'totals' fields
      !ignoredPayLines.has(code) &&
      payrunEntries.some((entry) =>
        entry.payOptions.regularPayLines.some((pl) => pl.code === code && !pl.isAutoGeneratedBasicPayLine)
      )
  );
};

export const calcPaycodeTotalForPayrunEntry = (item: PayRunEntryDto, paycode: string) => {
  // sum all the amounts of paylines matching a given pay code
  return sum(
    item.payOptions.regularPayLines.filter((pl) => pl.code === paycode && !pl.isAutoGeneratedBasicPayLine),
    (payline) => payline.value
  );
};

export const getSalaryAmount = (payrunEntry: PayRunEntryDto): number => {
  return (
    payrunEntry.payOptions.regularPayLines?.find((payLine) => payLine.isAutoGeneratedBasicPayLine)?.value ??
    payrunEntry.totals.basicPay
  );
};

export const payScheduleUnit = (payScheduleValue: PayScheduleEnum) => {
  return ({
    Monthly: 'month',
    Weekly: 'week',
  } as const)[payScheduleValue];
};

export const salaryBasisQuantity = (salaryBasisValue: SalaryBasisEnum) => {
  return ({
    Hourly: 'hours',
    Daily: 'days',
    Monthly: 'months',
    Annual: 'years',
  } as const)[salaryBasisValue];
};
