export interface AuthSignUpDto {
  userId: number;
  password: string | undefined;
  emailAddress: string;
  firstName: string;
  companyName: string | undefined;
}

export interface AuthLoginDtoWithUserDetails {
  accessToken: string;
  userId: number;
  companyId: number;
  displayName: string;
}

export interface AuthLoginMFARequiredDto {
  mfaType: MFAType;
}

export type MFAType = 'email';

export const MFA_CODE_LENGTH = 6;

export type LoginResponseDto = AuthLoginDtoWithUserDetails | AuthLoginMFARequiredDto;

export interface SendMFAEnableCodeResponseDto {
  emailAddress: string;
}
