import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { OneOffPaymentDto } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { PayItemAPI } from '@v2/feature/payroll/features/pay-item/pay-item.api';
import { round2Digits } from '@v2/util/number.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { ScopesControl } from '@/component/widgets/Scopes';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly oneOffPayment: OneOffPaymentDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const UpdateUpcomingPayItemDrawer = ({ isOpen, setIsOpen, oneOffPayment, refresh, onClose }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <UpdateUpcomingPayItemDrawerContent oneOffPayment={oneOffPayment} refresh={refresh} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly oneOffPayment: OneOffPaymentDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateUpcomingPayItemDrawerContent = ({
  oneOffPayment,
  refresh,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: Pick<OneOffPaymentDto, 'date' | 'payCode' | 'description' | 'amount' | 'multiplier'>) => {
      if (!oneOffPayment.id) {
        return;
      }

      const data: Pick<OneOffPaymentDto, 'date' | 'payCode' | 'description' | 'amount' | 'multiplier'> = {
        date: values.date,
        payCode: values.payCode,
        description: values.description,
        amount: Number(values.amount),
        multiplier: Number(values.multiplier),
      };
      try {
        setLoading(true);
        await PayItemAPI.updateUpcomingOneOffPayItem(oneOffPayment.id, oneOffPayment.userId, data);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [oneOffPayment, refresh, setIsOpen, showMessage, polyglot]
  );

  const formik = useFormik<Pick<OneOffPaymentDto, 'date' | 'payCode' | 'description' | 'amount' | 'multiplier'>>({
    initialValues: {
      date: oneOffPayment?.date ?? null,
      payCode: oneOffPayment.payCode ?? null,
      description: oneOffPayment?.description ?? null,
      amount: oneOffPayment?.amount ?? null,
      multiplier: oneOffPayment?.multiplier ?? null,
    },
    validationSchema: yup.object({
      date: yup
        .date()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      description: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      amount: yup.number().typeError(polyglot.t('ValidationMessages.validValue')).nullable().notRequired(),
      payCode: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      multiplier: yup.number().typeError(polyglot.t('ValidationMessages.validValue')).nullable().notRequired(),
    }),
    onSubmit,
  });

  const finalAmount = useMemo(() => {
    if (Number.isNaN(formik.values.amount) || Number.isNaN(formik.values.multiplier)) return 0;

    return round2Digits(Number(formik.values.amount) * Number(formik.values.multiplier));
  }, [formik.values.amount, formik.values.multiplier]);

  const deleteOneOffPayment = useCallback(async () => {
    if (!oneOffPayment.id) {
      // TODO: @polyglot-later
      showMessage('This payment cannot be deleted.', 'error');
      return;
    }
    try {
      setLoading(true);
      await PayItemAPI.deleteUpcomingPayItem(oneOffPayment.userId, oneOffPayment.id);

      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  }, [oneOffPayment, refresh, setIsOpen, showMessage, polyglot]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* TODO: @polyglot-later */}
          <Typography variant="title2">Update upcoming pay item</Typography>
          <ScopesControl scopes={['payroll:all']} context={{ userId: oneOffPayment.userId }}>
            <IconButton sx={tableIconButtonSx} onClick={deleteOneOffPayment}>
              <Trash {...iconSize} />
            </IconButton>
          </ScopesControl>
        </Box>

        <Box>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('General.employee')}
          </Typography>
          <UserCell userId={oneOffPayment.userId} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
        </Box>

        <Box>
          <DatePickerComponent
            name="date"
            label={polyglot.t('General.date')}
            onChange={(value) => {
              formik.setFieldValue('date', value);
            }}
            value={formik.values.date}
            error={formik.touched.date && !!formik.errors.date}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Box>

        <Box>
          <TextfieldComponent
            name="payCode"
            label={polyglot.t('PayItemModule.payCode')}
            value={formik.values.payCode}
            onChange={formik.handleChange}
            error={formik.touched.payCode && !!formik.errors.payCode}
            helperText={(formik.touched.payCode && formik.errors.payCode) ?? ' '}
            endAdornment="none"
          />
        </Box>

        <Box>
          <TextfieldComponent
            name="description"
            label={polyglot.t('General.description')}
            multiline
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && !!formik.errors.description}
            helperText={(formik.touched.description && formik.errors.description) ?? ' '}
            endAdornment="none"
          />
        </Box>

        <TextfieldComponent
          name="amount"
          label={polyglot.t('PayItemModule.amount')}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && !!formik.errors.amount}
          helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          name="multiplier"
          label={polyglot.t('PayItemModule.payCodeMultiplier')}
          value={formik.values.multiplier}
          onChange={formik.handleChange}
          error={formik.touched.multiplier && !!formik.errors.multiplier}
          helperText={(formik.touched.multiplier && formik.errors.multiplier) ?? ' '}
          endAdornment="none"
        />

        <Box>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('PayItemModule.finalAmount')}
          </Typography>
          <Typography variant="caption" color="DarkGrey">
            {finalAmount}
          </Typography>
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.update')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
