import React, { ReactNode, forwardRef } from 'react';

import { Box, Fade, SxProps } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { spacing } from '@v2/styles/spacing.styles';
import Lottie from 'lottie-react';

import ZeltLoader from '@/animations/Zelt-gradient-loader.json';

const LoadingSpinner = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        placeContent: 'center',
        verticalAlign: 'middle',
        alignItems: 'center',
      }}
    >
      <Box>
        <Lottie animationData={ZeltLoader} loop={true} autoplay={true} />
      </Box>
    </Box>
  );
};

interface LoaderProps {
  readonly loading: boolean;
  readonly children: ReactNode;
  readonly sx?: SxProps<Theme>;
  readonly hideFooter?: boolean;
}

export const Loader = ({ loading, children, sx = {}, hideFooter }: LoaderProps): JSX.Element => (
  <Box
    sx={{
      minHeight: hideFooter ? '100vh' : 'auto',
      height: hideFooter ? 'inherit' : '100%',
      display: 'flex',
      flexFlow: 'column',
      flex: 1,
      ...sx,
    }}
  >
    {loading && <LoadingSpinner />}
    {!loading && (
      <Fade in={!loading} timeout={{ enter: 600, exit: 600 }}>
        <div style={{ display: 'flex', flex: 1, flexFlow: 'column' }}>{children}</div>
      </Fade>
    )}
    {!hideFooter && <Box sx={{ flex: '0 0 40px' }} />} {/* footer */}
  </Box>
);

interface ContentWrapperProps {
  readonly loading?: boolean | undefined;
  readonly children: ReactNode;
  readonly secondLevel?: boolean;
  readonly noHeader?: boolean;
  readonly sx?: SxProps<Theme> | null;
  readonly loaderSx?: SxProps<Theme> | null;
  readonly border?: boolean;
  readonly hideFooter?: boolean;
  readonly noHorizontalPadding?: boolean;
}

const MainStyle = styled('div')({
  flexGrow: 1,
  paddingBottom: '40px',
  overflowY: 'auto',
});

export const ContentWrapper = forwardRef<HTMLDivElement, ContentWrapperProps>(
  (
    {
      loading = false,
      children,
      sx = {},
      loaderSx = {},
      hideFooter = false,
      secondLevel = false,
      noHorizontalPadding = false,
    }: ContentWrapperProps,
    ref
  ) => {
    return (
      <MainStyle
        ref={ref}
        sx={{
          ...(secondLevel ? { paddingTop: '50px' } : {}),
          ...sx,
          ...(noHorizontalPadding ? {} : spacing.px16),
        }}
      >
        <Loader loading={!!loading} hideFooter={hideFooter} sx={loaderSx}>
          {children}
        </Loader>
      </MainStyle>
    );
  }
);
