import { CSSProperties, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UpdateAnswer } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/review-answer-submission.page';
import { UpsertReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ScaleConfig } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ScaleQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
}: {
  question: ReviewQuestion;
  reviewType: ReviewerTypes | undefined;
  answerArray: UpsertReviewAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: ReviewEntry) => void;
  entry: ReviewEntry | undefined;
}) => {
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = answerArray?.find((ans) => ans.questionId === question.id) || { answer: '', comment: '' };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);

  if (!question || !reviewType || !entry) return <></>;

  const handleScaleChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
  };

  const handleCommentChange = (value: string) => {
    if (entry) {
      handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      {question && (
        <RatingScale
          scaleConfig={question.scaleConfig}
          handleScaleChange={handleScaleChange}
          currentAnswer={currentAnswer}
        />
      )}

      {question.hasComment && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.g8,
            pt: spacing.p16,
            borderTop: `${borders.background}`,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Add comment
            </Typography>
            <Typography variant="caption" color="Grey">
              {question.isCommentRequired ? 'Required' : 'Optional'}
            </Typography>
          </Box>

          <RichTextField
            key={question.id}
            value={currentComment}
            onChange={(value: string) => handleCommentChange(value)}
          />
        </Box>
      )}
    </Box>
  );
};

const RatingScale = ({
  scaleConfig,
  handleScaleChange,
  currentAnswer,
}: {
  scaleConfig: ScaleConfig | null;
  handleScaleChange: (value: string) => void;
  currentAnswer: string | null;
}) => {
  const [scaleKey, selectedScaleKey] = useState<string | null>(currentAnswer);
  const scaleItemStyle = (value: string): CSSProperties => ({
    flex: 1,
    height: '44px',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: currentAnswer === value ? themeColors.DarkGrey : themeColors.Background,
    borderRadius: radius.br8,
    userSelect: 'none',
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
      {scaleConfig?.points && (
        <Box sx={{ visibility: scaleKey ? 'visible' : 'hidden', minHeight: '20px' }}>
          <Typography variant="caption" color="Grey">
            {scaleKey ? scaleConfig.points[scaleKey] : ''}
          </Typography>
        </Box>
      )}
      <Box style={{ display: 'flex', flexGrow: 1, gap: spacing.g4, height: '44px' }}>
        {scaleConfig?.value &&
          Object.entries(scaleConfig.value).map(([key, value]) => (
            <Box
              key={key}
              style={scaleItemStyle(key)}
              onClick={() => {
                handleScaleChange(key);
                selectedScaleKey(key);
              }}
            >
              <Typography variant="caption" color={currentAnswer === key ? 'Background' : 'DarkGrey'}>
                {value}
              </Typography>
            </Box>
          ))}
      </Box>
    </div>
  );
};
