import { useState } from 'react';

import { Stack } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';

import { PayrollAccountingSettings } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/accounting-settings/payroll-accounting-settings.page';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
}

export const PayrollCompanySettingsAccounting = ({ payroll }: Props): JSX.Element => {
  // when the user connects to Xero, the backend redirects to this page with `#edit-accounting` set in the URL
  const [accountEdit, setAccountEdit] = useState(window.location.hash.includes('edit-accounting'));

  return (
    <Stack sx={{ maxWidth: '650px', gap: spacing.g40, my: spacing.my20 }}>
      {payroll && (
        <PayrollAccountingSettings payrollId={payroll.id} accountEdit={accountEdit} setAccountEdit={setAccountEdit} />
      )}
    </Stack>
  );
};
