import { useState } from 'react';

import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { AbsenceViewDrawer } from '@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page';

import { PHDeletionTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const PendingPHCancellationActionItem = ({
  timeActionRow,
  refresh,
  afterClose,
}: {
  timeActionRow: PHDeletionTodo;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!timeActionRow);

  return (
    <AbsenceViewDrawer
      isOpen={isOpen}
      absence={(timeActionRow as unknown) as AbsenceDto}
      setIsAbsenceDrawerViewOpen={setIsOpen}
      showCalendarLink
      afterClose={afterClose}
      refresh={refresh}
    />
  );
};
