import { Fragment, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { USER_PERSONAL_TAB } from '@/lib/routes';
import { CurrentUser } from '@/models';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { MissingFieldInformationViewDrawer } from '@/v2/feature/dashboard/features/sections/user-profile-widget/components/missing-field-information-view-drawer.component';
import { ProfileWidgetReach } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableWhiteIconButtonSx } from '@/v2/styles/icon-button.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DashboardUserProfile = ({
  user,
  widgetData,
  refreshWidgetData,
  readOnly = false,
}: {
  user: CurrentUser;
  widgetData: ProfileWidgetReach | undefined;
  refreshWidgetData?: () => Promise<void>;
  readOnly?: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const [isOpen, setIsOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const u = getCachedUserById(user.userId);
  const routerHistory = useHistory();

  return (
    <WidgetLayout readOnly={readOnly} size="small">
      <Fragment>
        <Box
          sx={{
            '&:hover': { background: themeColors.transparency },
            borderRadius: radius.br10,
            cursor: 'pointer',
          }}
          onClick={() => routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: user?.userId ?? '' }))}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: spacing.p10,
            }}
          >
            <UserAvatar userId={user.userId} size="x100" />
            <Typography
              variant="title2"
              sx={{
                marginTop: spacing.m15,
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {polyglot.t(u?.displayName ?? '')}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                marginTop: spacing.m5,
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {polyglot.t(u?.role?.jobTitle ?? '')}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {polyglot.t(u?.role?.site?.name ?? '')}
            </Typography>
          </div>
        </Box>
        <Box sx={{ width: '100%' }}>
          {widgetData?.isMissingInfo ? (
            <Box
              onClick={() => setIsOpen(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: spacing.p5,
                '&:hover': { background: themeColors.GreyPress },
                borderRadius: radius.br10,
                cursor: 'pointer',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                <MistakeIcon style={{ fill: themeColors.Red }} width={14} height={14} />
                <Typography variant="caption" sx={{ color: themeColors.Red }}>
                  {polyglot.t('DashboradUserProfile.missingInfo')}
                </Typography>
              </Box>

              <IconButton sx={tableWhiteIconButtonSx}>
                <Plus width={14} height={14} />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5, justifyContent: 'center' }}>
              <OkGreen style={{ fill: themeColors.Green }} width={14} height={14} />
              <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
                {polyglot.t('DashboradUserProfile.upToDate')}
              </Typography>
            </Box>
          )}
        </Box>

        {widgetData && (
          <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
            <MissingFieldInformationViewDrawer
              widgetData={widgetData}
              user={user}
              refreshWidgetData={refreshWidgetData}
            />
          </DrawerModal>
        )}
      </Fragment>
    </WidgetLayout>
  );
};
