import { Fragment, useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Switch, useHistory, useLocation, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as RejectedGrey } from '@/images/side-bar-icons/Rejected-grey.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import {
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE,
  APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE,
  APP_INTEGRATION_DETAILS_GROUPS_ROUTE,
  APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE,
  APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
  APPS_COMPANY_OVERVIEW_ROUTE,
  APPS_PERSONAL_OVERVIEW_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { AppIntegrationStub, appStubToName } from '@/v2/feature/app-integration/app-integration.interface';
import {
  getAppPageConfig,
  REVOKE_ACCESS_LIST,
  useAppDetails,
  useFetchCandidates,
  useFetchEmployments,
  useFetchGroups,
  useFetchUsers,
} from '@/v2/feature/app-integration/app-router.util';
import { isAppOwner } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { AppAboutPage } from '@/v2/feature/app-integration/features/app-details/pages/app-about.page';
import { AppCandidateManagementPage } from '@/v2/feature/app-integration/features/app-details/pages/app-candidate-management.page';
import { AppEmploymentManagementPage } from '@/v2/feature/app-integration/features/app-details/pages/app-employment-management.page';
import { AppExternalNotEnrolledPage } from '@/v2/feature/app-integration/features/app-details/pages/app-external-not-enrolled.page';
import { AppGroupManagementPage } from '@/v2/feature/app-integration/features/app-details/pages/app-group-management.page';
import { AppInsightsPage } from '@/v2/feature/app-integration/features/app-details/pages/app-insights.page';
import { AppTeamAccessPage } from '@/v2/feature/app-integration/features/app-details/pages/app-team-access.page';
import { AppUserSettingPage } from '@/v2/feature/app-integration/features/app-details/pages/app-user-setting.page';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const getIconForAppStatus = (appStatus: string | undefined | null): JSX.Element => {
  if (appStatus && ['Active', 'Imported', 'Onboarded in Zelt'].includes(appStatus))
    return (
      <>
        <OkGreen style={{ fill: themeColors.Green }} />
        {appStatus}
      </>
    );
  if (['Invited'].includes(appStatus ?? ''))
    return (
      <>
        <Waiting style={{ fill: themeColors.DarkGrey }} />
        {appStatus}
      </>
    );
  if (['Pending', 'Scheduled', 'Hired'].includes(appStatus ?? ''))
    return (
      <>
        <Waiting style={{ fill: themeColors.middleGrey }} />
        {appStatus}
      </>
    );
  if (['No access', 'Suspended'].includes(appStatus ?? ''))
    return (
      <>
        <RejectedGrey />
        {appStatus}
      </>
    );
  return <></>;
};

export function AppIntegrationDetailedCompanyRouter(): JSX.Element {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const { appStub } = useParams<{ readonly appStub: AppIntegrationStub }>();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(currentUser);
  const [showMessage] = useMessage();
  const [APIError, setAPIError] = useState<boolean>(false);
  const handleAppAPIError = useCallback(
    async (err: { response: { data: { statusCode: number } } }) => {
      setAPIError(true);
      if (REVOKE_ACCESS_LIST.includes(err.response.data.statusCode)) {
        await AppIntegrationAPI.invalidateApp(appStub);
        routerHistory.push(APP_INTEGRATION_DETAILS_ABOUT_ROUTE);
      }
    },
    [appStub, routerHistory]
  );

  const { app, setApp } = useAppDetails(appStub, handleAppAPIError);
  const { loadingUsers, users, notEnrolledUsers, fetchUsers } = useFetchUsers(
    appStub,
    showMessage,
    handleAppAPIError,
    app,
    setAPIError
  );
  const { groupMembershipList, fetchGroups } = useFetchGroups(appStub, showMessage, app);
  const { employmentList, countriesForEmployment, loadingEmployments, fetchEmployments } = useFetchEmployments(
    appStub,
    showMessage,
    app
  );
  const { candidateList, loadingCandidates, importedCandidateList, fetchCandidates } = useFetchCandidates(
    appStub,
    showMessage,
    handleAppAPIError,
    app
  );

  const hasAppsAllOrAppOwner = useMemo(
    () => checkScopes(globalState.user, ['apps:all'], scopesContext) || isAppOwner(currentUser.userId, app),
    [globalState.user, scopesContext, currentUser.userId, app]
  );

  const error = useMemo(() => APIError, [APIError]);

  const hideAppSettings = !app?.authorised || !hasAppsAllOrAppOwner;

  const customTitle = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g10,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <img
            src={`/app-icons-v2/images/${appStub}.png`}
            width={60}
            alt={appStub}
            style={{ borderRadius: radius.br20 }}
          />
        </div>
        <Typography variant="title2">{appStubToName[appStub]}</Typography>
      </Box>
    );
  }, [appStub]);

  return (
    <Fragment>
      <DomainSideMenuContent
        pageConfig={getAppPageConfig(polyglot, currentUser, scopesContext, appStub, app, hideAppSettings)}
        showBack={true}
        backPath={
          routerLocation.pathname.includes('company') ? APPS_COMPANY_OVERVIEW_ROUTE : APPS_PERSONAL_OVERVIEW_ROUTE
        }
        type="Domain"
        customTitle={customTitle}
      />
      <Switch>
        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE}
          component={() => (
            <AppCandidateManagementPage
              appStub={appStub}
              candidateList={candidateList}
              importedCandidateList={importedCandidateList ?? []}
              loading={loadingCandidates ?? false}
              onAppChange={fetchCandidates}
              app={app}
              error={error}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
              refreshCandidates={fetchCandidates}
            />
          )}
        />
        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE}
          component={() => (
            <AppEmploymentManagementPage
              appStub={appStub}
              teamAccessUserList={users}
              countriesForEmployment={countriesForEmployment}
              employmentList={employmentList}
              loading={loadingEmployments}
              onAppChange={fetchEmployments}
              app={app}
              error={error}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
            />
          )}
        />

        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE}
          component={() => <AppInsightsPage currentUser={currentUser} appStub={appStub} app={app} />}
        />
        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_ABOUT_ROUTE}
          component={() => <AppAboutPage currentUser={currentUser} appStub={appStub} app={app} />}
        />

        <RouteScopesControl
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE}
          component={() => (
            <AppTeamAccessPage
              appStub={appStub}
              teamAccessUserList={users}
              externalUserList={notEnrolledUsers}
              groupMemberships={groupMembershipList}
              loading={loadingUsers}
              onAppChange={fetchUsers}
              app={app}
              error={error}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
              setApp={setApp}
            />
          )}
        />

        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE}
          component={() => (
            <AppExternalNotEnrolledPage
              appStub={appStub}
              teamAccessUserList={users}
              externalUserList={notEnrolledUsers}
              groupMemberships={groupMembershipList}
              loading={loadingUsers}
              onAppChange={fetchUsers}
              app={app}
              error={error}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
            />
          )}
        />

        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_GROUPS_ROUTE}
          component={() => (
            <AppGroupManagementPage
              appStub={appStub}
              teamAccessUserList={users}
              externalUserList={notEnrolledUsers}
              onAppChange={fetchGroups}
              app={app}
              error={error}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner}
            />
          )}
        />

        <RouteScopesControl
          exact
          scopes={['apps']}
          context={scopesContext}
          path={APP_INTEGRATION_DETAILS_SETTINGS_ROUTE}
          component={AppUserSettingPage}
        />
      </Switch>
    </Fragment>
  );
}
