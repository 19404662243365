import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsencePolicyAllowanceType, PolicyAllowanceFormData } from '@v2/feature/absence/absence.interface';
import { isHourlyPolicy } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { getAllowancesUnits } from '@v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface PolicyAllowanceEditDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyAllowanceEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: PolicyAllowanceEditDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUnlimited, setIsUnlimited] = useState(absencePolicy.allowance === null);
  const [allowanceUnits, setAllowanceUnits] = useState<'hour' | 'day'>(isHourlyPolicy(absencePolicy) ? 'hour' : 'day');

  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: PolicyAllowanceFormData) => {
      const policyData: PolicyAllowanceFormData = {
        allowance: values.allowance === null ? null : Number(values.allowance),
        allowanceType: values.allowanceType,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyAllowance(absencePolicy.id, policyData);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceDrawerPage.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );

  const formik = useFormik<PolicyAllowanceFormData>({
    initialValues: {
      allowance: absencePolicy.allowance,
      allowanceType: absencePolicy.allowanceType,
    },
    validationSchema: yup.object({
      allowanceType: yup.string().required(polyglot.t('PolicyAllowanceEditDrawer.requiredField')),
      allowance: yup.number().when('allowanceType', {
        is: (val: AbsencePolicyAllowanceType) =>
          !!val && [AbsencePolicyAllowanceType.Limited, AbsencePolicyAllowanceType.LimitedHourly].includes(val),
        then: yup
          .number()
          .min(0, polyglot.t('PolicyAllowanceEditDrawer.selectValidField'))
          .typeError(polyglot.t('PolicyAllowanceEditDrawer.selectValidField'))
          .required(polyglot.t('PolicyAllowanceEditDrawer.requiredField')),
        otherwise: yup.number().nullable().notRequired(),
      }),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (allowanceUnits === 'day')
      formik.setFieldValue(
        'allowanceType',
        formik.values.allowance === null ? AbsencePolicyAllowanceType.Unlimited : AbsencePolicyAllowanceType.Limited
      );
    if (allowanceUnits === 'hour')
      formik.setFieldValue(
        'allowanceType',
        formik.values.allowance === null
          ? AbsencePolicyAllowanceType.UnlimitedHourly
          : AbsencePolicyAllowanceType.LimitedHourly
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowanceUnits, formik.values.allowance]);

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        setTimeout(() => formik.resetForm(), 500);
      }}
    >
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('PolicyAllowanceEditDrawer.editAllowance')}</Typography>

          <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
            <ButtonComponent
              onClick={() => {
                setIsUnlimited(true);
                formik.setFieldValue('allowance', null);
              }}
              sizeVariant="filter"
              colorVariant={isUnlimited ? 'active' : 'secondary'}
            >
              {polyglot.t('PolicyAllowanceEditDrawer.unlimited')}
            </ButtonComponent>

            <ButtonComponent
              onClick={() => {
                setIsUnlimited(false);
                formik.setFieldValue('allowance', 25);
              }}
              sizeVariant="filter"
              colorVariant={!isUnlimited ? 'active' : 'secondary'}
            >
              {polyglot.t('PolicyAllowanceEditDrawer.limited')}
            </ButtonComponent>
          </Box>

          <Box sx={{ display: 'flex', gap: spacing.g10 }}>
            <SelectComponent
              name="allowanceUnits"
              label={polyglot.t('AbsencePolicyAllowanceSection.unit')}
              options={getAllowancesUnits(polyglot)}
              value={allowanceUnits}
              onChange={(e) => setAllowanceUnits(e.target.value as 'hour' | 'day')}
            />
            {formik.values.allowance !== null && (
              <TextfieldComponent
                label={polyglot.t('AbsencePolicyAllowanceSection.allowance')}
                name="allowance"
                value={formik.values.allowance}
                onChange={(e) => {
                  const value = e.target.value;
                  const numberRegex = /^\d*(.)?[0,5]?$/g;
                  if (value.match(numberRegex)) formik.setFieldValue('allowance', value);
                }}
                fullWidth
                size="small"
                endAdornment="none"
              />
            )}
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
