import { Redirect, Route, Switch } from 'react-router-dom';

import {
  SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE,
  SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE,
  SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE,
} from '@/lib/routes';
import { SuperAdminBillingInvoicesPage } from '@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-invoices.page';
import { SuperAdminBillingCompanySubscriptionsPage } from '@/v2/feature/super-admin/features/super-admin-billing-v2/pages/super-admin-billing-company-subscriptions.page';
import { SuperAdminBillingOneOffChargesPage } from '@/v2/feature/super-admin/features/super-admin-billing-v2/pages/super-admin-billing-one-off-charges.page';

const BILLING_PAGE_CONFIG = {
  header: {
    tabs: [
      // { label: 'Plans', value: SUPER_ADMIN_BILLING_V2_PLANS_ROUTE },
      { label: 'Subscriptions', value: SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE },
      { label: 'Invoices', value: SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE },
      { label: 'One-off charges', value: SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE },
    ],
  },
} as const;

export const SuperAdminBillingV2Router = (): JSX.Element => {
  return (
    <Switch>
      {/* <Route path={SUPER_ADMIN_BILLING_V2_PLANS_ROUTE}>
        <SuperAdminBillingPlansPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route> */}

      <Route path={SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE}>
        <SuperAdminBillingCompanySubscriptionsPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route>

      <Route path={SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE}>
        <SuperAdminBillingInvoicesPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route>

      <Route path={SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE}>
        <SuperAdminBillingOneOffChargesPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route>

      <Redirect to={SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE} />
    </Switch>
  );
};
