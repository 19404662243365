import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import {
  languageOptions,
  UserLocaleSettingsDrawer,
} from '@v2/feature/user/features/user-settings/components/user-locale-settings-drawer.component';
import { UserPrivacySettingDrawer } from '@v2/feature/user/features/user-settings/components/user-privacy-setting-drawer.component';
import { PersonalSettingsProps } from '@v2/feature/user/user.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { KeyedMutator } from 'swr';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SnackBarMessage } from '@/v2/components/snack-bar-message.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { MFAType } from '@/v2/feature/auth/auth.dto';
import { DisableMFADrawer } from '@/v2/feature/user/features/mfa/disable-mfa-drawer.component';
import { EnableMFADrawer } from '@/v2/feature/user/features/mfa/enable-mfa-drawer.component';

export const UserPersonalSettingsSections = ({
  settings,
  refreshSettings,
}: {
  settings: PersonalSettingsProps | null | undefined;
  refreshSettings: KeyedMutator<PersonalSettingsProps> | undefined;
}) => {
  const { polyglot } = usePolyglot();

  const [showMFAChangedMessage, setShowMFAChangedMessage] = useState<'enabled' | 'disabled' | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editPrivacyMode, setEditPrivacyMode] = useState<boolean>(false);

  return (
    <>
      {showMFAChangedMessage && (
        <SnackBarMessage
          showMessage={{
            message: {
              enabled: polyglot.t('UserMfaSettings.mfaEnabled'),
              disabled: polyglot.t('UserMfaSettings.mfaDisabled'),
            }[showMFAChangedMessage],
            show: true,
            type: 'success',
          }}
          handleClose={() => setShowMFAChangedMessage(null)}
        />
      )}
      <SettingsSubsectionContent
        sections={[
          {
            title: polyglot.t('UserMfaSettings.mfa'),
            items: [
              {
                type: SectionItemType.TextLine,
                value: polyglot.t('UserMfaSettings.protectMessage'),
              },
              {
                type: SectionItemType.Component,
                value: <GetCodeByEmail setShowMFAChangedMessage={setShowMFAChangedMessage} />,
              },
            ],
          },
          {
            title: polyglot.t('UserLocaleSettings.language'),
            onEdit: () => setEditMode(true),
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('UserLocaleSettings.languagesSettings'),
                value: (languageOptions.find((l) => l.value === settings?.language)?.label as string) ?? 'NA',
              },
            ],
          },
          {
            title: polyglot.t('UserPrivacySettings.privacysettings'),
            onEdit: () => setEditPrivacyMode(true),
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('UserPrivacySettings.showmybirthday'),
                value: settings?.showBirthday ? polyglot.t('General.yes') : polyglot.t('General.no'),
              },
            ],
          },
        ]}
      />

      <UserLocaleSettingsDrawer
        isOpen={editMode}
        setIsOpen={setEditMode}
        initialValues={settings}
        refresh={refreshSettings}
      />

      <UserPrivacySettingDrawer
        setIsOpen={setEditPrivacyMode}
        isOpen={editPrivacyMode}
        settings={settings}
        refreshSettings={refreshSettings}
      />
    </>
  );
};

export const GetCodeByEmail = ({
  setShowMFAChangedMessage,
}: {
  setShowMFAChangedMessage: React.Dispatch<React.SetStateAction<'enabled' | 'disabled' | null>>;
}) => {
  const { polyglot } = usePolyglot();

  const [isMFAEnableOpen, openEnableMFAPage] = useState(false);
  const [isMFADisableOpen, openDisableMFAPage] = useState(false);
  const [activeMFAType, setActiveMFAType] = useState<MFAType | null>();
  const lastMFAState = useRef<MFAType | null>();
  const [ssoEnabled, setSSOEnabled] = useState<boolean>(false);

  const refreshMFAState = useCallback(() => {
    return (async () => {
      const { user } = await AuthAPI.getAuthMe();
      setActiveMFAType(user.mfaType || null);
      if (lastMFAState.current !== undefined && lastMFAState.current !== user.mfaType) {
        // if the state changed, show the toast message
        setShowMFAChangedMessage(user.mfaType ? 'enabled' : 'disabled');
      }
      lastMFAState.current = user.mfaType;
      return user.mfaType;
    })();
  }, [setShowMFAChangedMessage]);

  const refreshSSOState = useCallback(async () => {
    const data = await AuthAPI.ssoCheckForCompany();
    const ssoEnabledForCompany = data.some((app) => app.enabled);
    setSSOEnabled(ssoEnabledForCompany);
  }, []);

  const configureMFA = useCallback(
    (enable: boolean) => {
      // before launching the enable/disable drawer, check if the state has changed
      refreshMFAState().then((mfaType) => {
        const isCurrentlyEnabled = !!mfaType;
        if (enable === isCurrentlyEnabled) return;
        if (enable) {
          openEnableMFAPage(true);
        } else {
          openDisableMFAPage(true);
        }
      });
    },
    [refreshMFAState]
  );

  useEffect(() => {
    if (isMFAEnableOpen || isMFADisableOpen) {
      // set indeterminate MFA state while we're enabling/disabling it
      setActiveMFAType(undefined);
    }
    if (!isMFAEnableOpen && !isMFADisableOpen) {
      refreshMFAState();
      refreshSSOState();
    }
  }, [isMFADisableOpen, isMFAEnableOpen, refreshMFAState, refreshSSOState]);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <Typography variant="title4"> {polyglot.t('UserMfaSettings.getCodeByEmail')}</Typography>
          <Typography variant="captionSmall" color="Grey" sx={{ mt: '5px' }}>
            {polyglot.t('UserMfaSettings.askedToLogin')}
          </Typography>
        </div>
        {activeMFAType === 'email' && (
          <ButtonComponent
            sizeVariant="small"
            colorVariant="secondary"
            style={{ display: 'inline-block' }}
            onClick={() => configureMFA(false)}
          >
            {polyglot.t('UserMfaSettings.turnOff')}
          </ButtonComponent>
        )}
        {activeMFAType === null && (
          <StyledTooltip title={ssoEnabled ? polyglot.t('UserMfaSettings.ssoEnabled') : ''} placement="top">
            <Box>
              <ButtonComponent
                type="button"
                sizeVariant="small"
                colorVariant="primary"
                disabled={ssoEnabled}
                onClick={() => configureMFA(true)}
              >
                {polyglot.t('UserMfaSettings.enable')}
              </ButtonComponent>
            </Box>
          </StyledTooltip>
        )}
      </Box>

      <EnableMFADrawer isOpen={isMFAEnableOpen} setIsOpen={openEnableMFAPage} />
      <DisableMFADrawer isOpen={isMFADisableOpen} setIsOpen={openDisableMFAPage} />
    </Box>
  );
};
