import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { HolidayCalendarAPI } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE } from '@/lib/routes';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly holidayCalendar: HolidayCalendarDto;
  readonly refresh: () => Promise<void>;
  readonly onClose?: () => void;
}

export const DeleteHolidayCalendarDrawer = ({ isOpen, setIsOpen, holidayCalendar, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <HolidayDrawerContent holidayCalendar={holidayCalendar} refresh={refresh} setIsOpen={setIsOpen} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly holidayCalendar: HolidayCalendarDto;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

const HolidayDrawerContent = ({ holidayCalendar, refresh, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteHolidayCalendar = useCallback(async () => {
    try {
      setLoading(true);
      await HolidayCalendarAPI.deleteHolidayCalendar(holidayCalendar.id);
      await refresh();
      setIsOpen(false);
      history.push(SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setLoading(false);
  }, [history, showMessage, polyglot, refresh, holidayCalendar, setIsOpen]);

  return (
    <Box style={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('HolidayCalendarModule.deleteCalendar')}</Typography>

      <Typography variant="caption">
        {polyglot.t('HolidayCalendarModule.deleteCalendarConfirmation', { name: holidayCalendar.name })}
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.delete')}
          onClick={deleteHolidayCalendar}
          loading={loading}
          fullWidth
          sizeVariant="medium"
          colorVariant="danger"
        />
      </Box>
    </Box>
  );
};
