import Polyglot from 'node-polyglot';

import { ReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ScaleConfig } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';

export type ReviewQuestion = {
  id: string;
  companyId: number;
  sectionId?: string | null;
  cycleId: string;
  factor: string | null;
  reviewQuestionBankId?: string;
  answerOptions: Record<string, string>;
  scaleConfig: ScaleConfig | null;
  reviewerSelect: ReviewerTypes[];
  type: QuestionType;
  questionMain: string;
  questionSelf: string;
  hasComment: boolean;
  isCommentRequired: boolean;
  visibilitySettings: QuestionVisibilitySettings;
  createdBy?: number;
  answers?: ReviewAnswer[];
};

export type ReviewQuestionUpsert = Omit<ReviewQuestion, 'companyId' | 'createdBy'>;

export enum QuestionType {
  SingleAnswer = 'singleAnswer',
  OpenEnded = 'openEnded',
  MultipleAnswer = 'multipleAnswer',
  ScaleQuestion = 'scaleQuestion',
}

export enum QuestionTypeChoiceEnum {
  SingleAnswer = 'singleAnswer',
  SingleAnswerComment = 'singleAnswer+comment',
  MultipleAnswer = 'multipleAnswer',
  MultipleAnsweComment = 'multipleAnswer+comment',
  ScaleQuestion = 'scaleQuestion',
  ScaleQuestionComment = 'scaleQuestion+comment',
  OpenEnded = 'openEnded',
}

export const QuestionTypeMap = {
  [QuestionType.SingleAnswer]: 'Single choice',
  [QuestionType.MultipleAnswer]: 'Multiple choice',
  [QuestionType.ScaleQuestion]: 'Scale question',
  [QuestionType.OpenEnded]: 'Comment',
};

export type QuestionVisibilitySettings = {
  hideManagerResult: boolean;
  hidePeerResult: boolean;
  hideUpwardResult: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SingleAnswerQuestion = (polyglot: Polyglot) => {
  return { label: 'Single choice', value: QuestionTypeChoiceEnum.SingleAnswer };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SingleAnswerPlusComment = (polyglot: Polyglot) => {
  return { label: 'Single choice + Comment', value: QuestionTypeChoiceEnum.SingleAnswerComment };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MultipleAnswer = (polyglot: Polyglot) => {
  return { label: 'Multiple choice', value: QuestionTypeChoiceEnum.MultipleAnswer };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MultipleChoicePlusComment = (polyglot: Polyglot) => {
  return { label: 'Multiple choice + Comment', value: QuestionTypeChoiceEnum.MultipleAnsweComment };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ScaleQuestion = (polyglot: Polyglot) => {
  return { label: 'Scale', value: QuestionTypeChoiceEnum.ScaleQuestion };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ScaleQuestionPlusComment = (polyglot: Polyglot) => {
  return { label: 'Scale + Comment', value: QuestionTypeChoiceEnum.ScaleQuestionComment };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OpenEnded = (polyglot: Polyglot) => {
  return { label: 'Comment', value: QuestionTypeChoiceEnum.OpenEnded };
};

export const AnswerTypes = (polyglot: Polyglot) => {
  return [
    OpenEnded(polyglot),
    ScaleQuestion(polyglot),
    ScaleQuestionPlusComment(polyglot),
    SingleAnswerQuestion(polyglot),
    SingleAnswerPlusComment(polyglot),
    MultipleAnswer(polyglot),
    MultipleChoicePlusComment(polyglot),
  ];
};

export const ManagerReviewer = (polyglot: Polyglot) => {
  return { value: ReviewerTypes.Manager, label: polyglot.t('FormInterface.manager') };
};
export const SelfReviewer = (polyglot: Polyglot) => {
  return { value: ReviewerTypes.Self, label: polyglot.t('FormInterface.self') };
};
export const DirectReviewer = (polyglot: Polyglot) => {
  return { value: ReviewerTypes.Upward, label: polyglot.t('FormInterface.upward') };
};
export const PeerReviewer = (polyglot: Polyglot) => {
  return { value: ReviewerTypes.Peer, label: polyglot.t('FormInterface.peer') };
};

export const ReviewerTypesArray = (polyglot: Polyglot) => {
  return [ManagerReviewer(polyglot), SelfReviewer(polyglot), DirectReviewer(polyglot), PeerReviewer(polyglot)];
};

export const MainReviwerTypeArray = [ReviewerTypes.Manager, ReviewerTypes.Upward, ReviewerTypes.Peer];
export const SelfReviwerTypeArray = [ReviewerTypes.Self];
