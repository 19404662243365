import { TrialDetails } from '@/GlobalState';
import { CurrentUser } from '@/models/user.model';

export interface AuthMeUser {
  user: CurrentUser;
  showHiddenFeatures: boolean;
  isSuperAdmin: boolean;
  hasFailedPayment: boolean;
  isTestModeEnabled: boolean;
  trialMode: TrialDetails;
}

export const PARTNER_API_PREFIX = '/partner-api/authorize';

export const TEMPLATE_CONTRACT_COMPANY_SIGN_PREFIX = '/people/me/documents/overview/contract/company-sign/';

export const CONTRACT_RECIPIENT_NOT_SET_PASSWORD_ERROR =
  'Password was not set for this account yet. Please use the invite email from hello@zelt.app to activate your account first.';
