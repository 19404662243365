import React, { useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SiteWithMembersDto } from '@/v2/feature/site/site.dto';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface IFormSiteModal {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedSite: SiteWithMembersDto | undefined;
  readonly closePage: () => void;
  readonly setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refreshSites: () => Promise<void>;
}
export const SiteViewModal = ({
  isOpen,
  setIsOpen,
  selectedSite,
  closePage,
  setIsFormOpen,
  refreshSites,
}: IFormSiteModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={closePage}>
      <SiteViewModalContent
        selectedSite={selectedSite}
        setIsFormOpen={setIsFormOpen}
        refreshSites={refreshSites}
        closePage={closePage}
      />
    </DrawerModal>
  );
};

const SiteViewModalContent = ({
  selectedSite,
  setIsFormOpen,
  refreshSites,
  closePage,
}: {
  readonly selectedSite: SiteWithMembersDto | undefined;
  setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refreshSites: () => Promise<void>;
  readonly closePage: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [siteToDelete, setSiteToDelete] = useState<number>();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const deleteSite = async () => {
    try {
      if (siteToDelete) await SiteAPI.deleteSite(siteToDelete);
      else throw new Error(polyglot.t('SiteManagementDrawerPage.errorMessages.noSitetoDelete'));
      showMessage(polyglot.t('SiteManagementDrawerPage.successMessages.deleteSite'), 'success');
      await refreshSites();
    } catch (error) {
      showMessage(
        `${polyglot.t('SiteManagementDrawerPage.errorMessages.deleteSite')}: ${nestErrorMessage(error)}`,
        'error'
      );
    }
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, siteId: number) => {
    if (event) setAnchorEl(event.currentTarget);
    setSiteToDelete(siteId);
    setIsConfirmModalOpen(true);
  };
  if (!selectedSite) {
    return (
      <Box sx={drawerContentSx}>
        <Typography variant="caption">Site not found</Typography>
      </Box>
    );
  }
  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{selectedSite?.name}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
        <Typography variant="title4">Members</Typography>
        {selectedSite?.users && selectedSite?.users?.length > 0 ? (
          <Box
            sx={{
              maxHeight: '69.5vh',
              overflowY: 'auto',
            }}
          >
            {selectedSite?.users?.map((eachUser) => {
              return (
                <Box key={eachUser.userId} sx={{ display: 'flex', mb: spacing.mb20 }}>
                  <UserCell key={eachUser.userId} userId={eachUser.userId} avatarSize="xxsmall" nameVariant="caption" />
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography variant="caption">No members</Typography>
        )}
      </Box>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          fullWidth
          colorVariant="secondary"
          sizeVariant="medium"
          onClick={(event) => {
            if (selectedSite) confirmDelete(event, selectedSite.id);
            else showMessage(polyglot.t('SiteManagementDrawerPage.errorMessages.noSiteSelected'), 'error');
          }}
        >
          Delete
        </ButtonComponent>
        <ButtonComponent
          fullWidth
          colorVariant="secondary"
          sizeVariant="medium"
          onClick={() => {
            setIsFormOpen(true);
          }}
        >
          Edit
        </ButtonComponent>
      </Box>

      <NotificationModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (siteToDelete) {
            setIsConfirmModalOpen(false);
            closePage();
            await deleteSite();
          } else showMessage(polyglot.t('SiteManagementDrawerPage.errorMessages.noSiteSelected'), 'error');
        }}
        message={polyglot.t('SiteManagementDrawerPage.deleteSiteMessage')}
        callToAction="Yes"
      />
    </Box>
  );
};
