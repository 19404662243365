import { ReactNode } from 'react';

import { Box, IconButton } from '@mui/material';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';

interface SectionProps {
  readonly children: ReactNode;
  readonly title: string;
  readonly width?: string;
  readonly onEdit?: () => void;
  readonly editSection?: JSX.Element;
}

export const PaymentSettingsPageSection = ({ children, title, onEdit, editSection, width = '600px' }: SectionProps) => {
  return (
    <RootStyle>
      <TopHeader
        title={
          <Box sx={{ display: 'flex', width, justifyContent: 'space-between' }}>
            <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
              {title}
            </Typography>
            {onEdit && (
              <IconButton key="edit" sx={tableIconButtonSx} onClick={onEdit}>
                <EditIcon {...actionIconSize} />
              </IconButton>
            )}
            {editSection ?? <></>}
          </Box>
        }
      />
      <ContentWrapper sx={{ scrollbarWidth: 'none' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, width: '600px' }}>{children}</Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};

export const DescriptionLine = ({
  field,
  value,
}: {
  field: string;
  value: string | number | ReactNode | undefined;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
      <Box sx={{ width: '200px' }}>
        <Typography variant="caption">{field}</Typography>
      </Box>
      {value ? (
        <Box sx={{ width: '390px' }}>
          {['string', 'number'].includes(typeof value) ? <Typography variant="title4">{value}</Typography> : value}
        </Box>
      ) : (
        <EmptyCell />
      )}
    </Box>
  );
};
