import { Fragment } from 'react';

import { Redirect, Switch, generatePath, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_OVERVIEW_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_RADAR_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_ROUTE,
} from '@/lib/routes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { ReviewResultEndpoints } from '@/v2/feature/growth/reviews/api-client/review-result.api';
import { ReviewCycleDetailResultRadar } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-result-radar/review-cycle-detail-result-radar.page';
import { ReviewCycleDetailResultsOverviewPage } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/review-cycle-detail-results-overview.page';
import { ReachType, ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewResultNavigation } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

const getPageConfig = (
  cycleId: string,
  revieweeId: string,
  navigation: ReviewResultNavigation | null | undefined
): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: 'detail',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'Results',
          stub: 'results',
          path: generatePath(REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_OVERVIEW_ROUTE, { cycleId, revieweeId }),
          isHidden: false,
          hasChildren: false,
        },
        ...(navigation && navigation.radarPage
          ? [
              {
                title: 'Radar chart',
                stub: 'chart',
                path: generatePath(REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_RADAR_ROUTE, { cycleId, revieweeId }),
                isHidden: false,
                hasChildren: false,
              },
            ]
          : []),
      ],
    },
  ] as NavConfigItem[];
};
export const ReviewCycleDetailResultsTeamRouter = ({
  reviewCycle,
  cycleLoading,
  reach,
}: {
  reviewCycle: ReviewCycle;
  cycleLoading: boolean;
  reach: ReachType;
}) => {
  const params = useParams<{ cycleId: string; revieweeId: string }>();
  const { cycleId, revieweeId } = params;
  const { data: navigation } = useApiClient(ReviewResultEndpoints.getReviewResultNavigation(cycleId), {
    suspense: false,
  });

  return (
    <Fragment>
      <DomainSideMenuContent
        title={'Reviews'}
        pageConfig={getPageConfig(cycleId, revieweeId, navigation)}
        backPath={generatePath(REVIEWS_TEAM_ONGOING_DETAIL_RESULT_ROUTE, { cycleId })}
        showBack
        type="Custom"
      />
      <Switch>
        <RouteScopesControl
          scopes={['reviews:manager']}
          path={REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_OVERVIEW_ROUTE}
        >
          <ReviewCycleDetailResultsOverviewPage
            reviewCycle={reviewCycle}
            cycleLoading={cycleLoading}
            reachType={reach}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={['reviews:manager']} path={REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_RADAR_ROUTE}>
          <ReviewCycleDetailResultRadar reviewCycle={reviewCycle} cycleLoading={cycleLoading} reachType={reach} />
        </RouteScopesControl>
        <Redirect to={REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_OVERVIEW_ROUTE} exact />
      </Switch>
    </Fragment>
  );
};
