import React from 'react';

import { Box } from '@mui/material';
import { PaginationState } from '@tanstack/react-table';

import { ListView } from '@/v2/components/list-view/list-view.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface ListSectionComponentProps {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  listUsers: {
    id: number;
    name: string;
    details: string;
    avatar: JSX.Element;
    showAlert: boolean;
    alertColor: string;
  }[];
  pagination: PaginationState;
  totalPages: number;
  totalItems: number;
  onPageChange: (pageIndex: number, pageSize: number) => void;
  handleUserAttendance: (userId: number) => Promise<void>;
  loading: boolean;
  selectedUser: number | undefined;
}
export const ListSectionComponent = ({
  searchInput,
  setSearchInput,
  setPagination,
  listUsers,
  pagination,
  totalPages,
  totalItems,
  onPageChange,
  handleUserAttendance,
  loading,
  selectedUser,
}: ListSectionComponentProps) => {
  return (
    <Box sx={spacing.pt20}>
      <Box sx={{ ...spacing.px20, pb: spacing.p10 }}>
        <TableSearch
          query={searchInput}
          style={{ width: '90%' }}
          handleChange={(e) => {
            setSearchInput(e.target.value?.trim() ?? '');
            setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
          }}
        />
      </Box>
      <ListView
        list={listUsers}
        pagination={{
          pageSize: pagination.pageSize,
          pageIndex: pagination.pageIndex,
          total: totalPages,
          totalItems: totalItems,
          onPageChange: (pageIndex: number, pageSize: number) => onPageChange(pageIndex, pageSize),
        }}
        clickAction={(id) => handleUserAttendance(id as number)}
        loading={loading}
        selected={selectedUser}
      />
    </Box>
  );
};
