import React, { Dispatch, SetStateAction, Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import {
  RefinancingAPI,
  RefinancingEndpoints,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api';
import { CancelPlanDataDto } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.dto';
import { TwoCancellationReason } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@v2/styles/colors.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const CancellationReasonsOptions = Object.values(TwoCancellationReason).map((value) => ({ label: value, value }));

const validationSchema = yup.object({
  cancellationReason: yup.string().min(10, 'Please select a valid reason').required('This field is required'),
  idempotencyKey: yup
    .string()
    .min(10, 'Please input a key of at least 10 characters')
    .required('This field is required'),
  cancelledAt: yup
    .string()
    .min(24, `Please type a full ISO Date (e.g.: ${new Date().toISOString()})`)
    .required('This field is required'),
});

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly deviceOrder: DeviceOrderDto | null;
  readonly refreshOrders: () => Promise<void>;
  readonly refreshDeviceDetails: () => Promise<void>;
}

export const SuperAdminOrderRefinancingCancellationDrawer = ({
  isOpen,
  setIsOpen,
  deviceOrder,
  refreshOrders,
  refreshDeviceDetails,
}: DrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      {deviceOrder ? (
        <SuperAdminOrderRefinancingTerminationDrawerContent
          deviceOrder={deviceOrder}
          refreshOrders={refreshOrders}
          refreshDeviceDetails={refreshDeviceDetails}
          setIsOpen={setIsOpen}
        />
      ) : (
        <Box>
          <Typography variant="title4">Please select an order</Typography>
        </Box>
      )}
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly deviceOrder: DeviceOrderDto;
  readonly refreshOrders: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refreshDeviceDetails: () => Promise<void>;
}

export const SuperAdminOrderRefinancingTerminationDrawerContent = ({
  deviceOrder,
  refreshOrders,
  refreshDeviceDetails,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const { data: refinancingPlan, isLoading } = useApiClient(
    RefinancingEndpoints.getOrderRepaymentPlan(deviceOrder.companyId, deviceOrder.id),
    { suspense: false }
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const cancelRefinancing = useCallback(
    async (values: CancelPlanDataDto) => {
      if (!deviceOrder || !refinancingPlan) return;
      try {
        setLoading(true);
        const cancellationData = {
          cancellationReason: values.cancellationReason,
          idempotencyKey: values.idempotencyKey,
          cancelledAt: values.cancelledAt,
        };
        await RefinancingAPI.cancelRefinancingPlanAsSuperAdmin(
          deviceOrder.companyId,
          deviceOrder.id,
          refinancingPlan.id,
          cancellationData
        );
        await Promise.all([refreshOrders(), refreshDeviceDetails()]);
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
      setLoading(false);
    },
    [refinancingPlan, deviceOrder, showMessage, refreshDeviceDetails, refreshOrders, setIsOpen]
  );

  const formik = useFormik<CancelPlanDataDto>({
    initialValues: {
      cancellationReason: TwoCancellationReason.TERMINATED,
      idempotencyKey: '',
      cancelledAt: new Date().toISOString(),
    },
    validationSchema,
    onSubmit: async (values: CancelPlanDataDto) => cancelRefinancing(values),
  });

  return (
    <ContentWrapper loading={!!isLoading} noHorizontalPadding>
      {refinancingPlan && refinancingPlan.externalId && deviceOrder.externallyInvoiced ? (
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Typography variant="title2">Cancel refinancing</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Device ID
            </Typography>
            <Typography variant="title4">{deviceOrder.deviceId}</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Order ID
            </Typography>
            <Typography variant="title4">{deviceOrder.id}</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Refinancing plan ID
            </Typography>
            <Typography variant="title4">{refinancingPlan.id}</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Refinancing plan external ID
            </Typography>
            <Typography variant="title4">{refinancingPlan.externalId}</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Externally invoiced
            </Typography>
            <Typography variant="title4">{deviceOrder.externallyInvoiced ?? 'NULL'}</Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <SelectComponent
                name="cancellationReason"
                label="Cancellation reason"
                options={CancellationReasonsOptions}
                value={formik.values.cancellationReason}
                compareValue={formik.values.cancellationReason}
                onChange={formik.handleChange}
                error={formik.touched.cancellationReason && !!formik.errors.cancellationReason}
                helperText={formik.touched.cancellationReason && formik.errors.cancellationReason}
              />

              <TextfieldComponent
                label="Idempotency key"
                name="idempotencyKey"
                value={formik.values.idempotencyKey}
                onChange={formik.handleChange}
                error={formik.touched.idempotencyKey && Boolean(formik.errors.idempotencyKey)}
                helperText={formik.touched.idempotencyKey && formik.errors.idempotencyKey}
                size="small"
                endAdornment="clear-text"
                clearText={() => formik.setFieldValue('idempotencyKey', '')}
              />

              <TextfieldComponent
                label="Cancelled at"
                name="cancelledAt"
                value={formik.values.cancelledAt}
                onChange={formik.handleChange}
                error={formik.touched.cancelledAt && Boolean(formik.errors.cancelledAt)}
                helperText={formik.touched.cancelledAt && formik.errors.cancelledAt}
                size="small"
                endAdornment="clear-text"
                clearText={() => formik.setFieldValue('cancelledAt', '')}
              />
            </Box>

            <Box sx={{ mt: '30px' }}>
              <LoaderButton
                name="Cancel refinancing"
                loading={loading}
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          </Form>
        </FormikProvider>
      ) : (
        <Box>
          <Typography variant="title2">
            <Typography variant="title2">Cancel refinancing</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Device ID
            </Typography>
            <Typography variant="title4">{deviceOrder.deviceId}</Typography>

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Order ID
            </Typography>
            <Typography variant="title4">{deviceOrder.id}</Typography>

            {refinancingPlan && (
              <Typography variant="captionSmall" sx={{ mt: '10px' }}>
                Refinancing plan ID
              </Typography>
            )}
            {refinancingPlan && <Typography variant="title4">{refinancingPlan.id}</Typography>}

            {refinancingPlan && (
              <Typography variant="captionSmall" sx={{ mt: '10px' }}>
                Refinancing plan external ID
              </Typography>
            )}
            {refinancingPlan && <Typography variant="title4">{refinancingPlan.externalId}</Typography>}

            <Typography variant="captionSmall" sx={{ mt: '10px' }}>
              Externally invoiced
            </Typography>
            <Typography variant="title4">{deviceOrder.externallyInvoiced ?? 'NULL'}</Typography>

            <Typography variant="title4" sx={{ mt: '20px', color: themeColors.RedDark }}>
              No active refinancing plan has been found.
            </Typography>
          </Typography>
        </Box>
      )}
    </ContentWrapper>
  );
};
