export interface IndividualCheck {
  readonly id: string;
  readonly price: number;
  readonly updatedAt: Date;
}

export interface IdCheckPackage {
  readonly id: number;
  companyId: number;
  createdBy: number;
  readonly name: string;
  readonly packageDetails?: PackageDetail;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly price: number;
}

export interface IdCheckPackageCreate {
  readonly name: string;
  readonly packageDetails?: PackageDetail;
}

export interface PackageDetail {
  [key: string]: {
    enabled: boolean;
    type?: string;
    package?: string; // ONLY FOR CUSTOM company packages - e.g. Wonde's Enhanced DBS check
    criteria?: {
      unit: string;
      count: number;
    };
  };
}

export const customIdCheckPackages: { [key: string]: string } = {
  '6f6919f75a694021bda71cdb9a83c2f7': 'Enhanced DBS', // for Wonde
};
