import { Route } from 'react-router-dom';

import { ImportWizardSection } from './components/import-wizard-section.component';

import { ENTITY_IMPORT_V2_ROUTE } from '@/lib/routes';
import {
  ENTITY_IMPORT_WIZARD_ROUTE_OLD,
  ENTITY_IMPORT_WIZARD_V2_ROUTE,
  EntityImportWizardOldRouter,
} from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.router';
import { ImportWizardFlow } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';

export function EntityImportRouter() {
  return (
    <>
      <Route path={ENTITY_IMPORT_V2_ROUTE} exact>
        <ImportWizardSection />
      </Route>
      <Route path={ENTITY_IMPORT_WIZARD_V2_ROUTE} exact>
        <ImportWizardFlow />
      </Route>
      <Route path={ENTITY_IMPORT_WIZARD_ROUTE_OLD}>
        <EntityImportWizardOldRouter />
      </Route>
    </>
  );
}
