import Bull from 'bull';

import { GroupId } from './app-integration.dto';

import { MultiSelectOption } from '@/v2/components/forms/multiple-select-checkbox.component';
import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';
import { TabFilterItem } from '@/v2/components/tab-filter-buttons.component';

export const AppsWithLogins = ['github'];

export interface PasswordResetResult {
  password?: string;
}

export const REFRESH_DELAY_APP_USER_LIST = 16000;

export enum APP_ACTION_DRAWER_MODES {
  assign = 'assign',
  create = 'create',
  suspend = 'suspend',
  delete = 'delete',
  googleWorkspaceTransferThenDelete = 'googleWorkspaceTransferThenDelete',
  createEmployment = 'createEmployment',
}

export enum APP_GROUP_MANAGEMENT_DRAWER_MODES {
  edit = 'edit',
  add = 'add',
  addExistingToGroup = 'addExistingToGroup',
}

export enum AppActionWhenValueEnum {
  now = 'now',
  later = 'later',
}

export enum AppActionWhenLabelEnum {
  now = 'Now',
  later = 'Later',
}

export const AppActionWhenTabFilter: readonly TabFilterItem<string>[] = [
  { name: AppActionWhenLabelEnum.now, value: AppActionWhenValueEnum.now },
  { name: AppActionWhenLabelEnum.later, value: AppActionWhenValueEnum.later },
];

export const APP_ACTION_WHEN_OPTIONS = [
  { value: 'now', label: 'Now' },
  { value: 'later', label: 'Later' },
];

export type AppIntegrationStub =
  | 'slack'
  | 'google-workspace'
  | 'atlassian'
  | 'zoom'
  | 'lastpass'
  | 'aws'
  | 'notion'
  | 'docusign'
  | 'github'
  | 'pipedrive'
  | 'xero'
  | 'jira'
  | 'hubspot'
  | 'monday'
  | 'intercom'
  | 'quickbooks'
  | 'microsoft365'
  | 'jumpcloud'
  | 'zelt'
  | 'greenhouse'
  | 'remote'
  | 'deel'
  | 'oysterhr'
  | 'teamtailor'
  | 'workable'
  | 'sso-google-workspace'
  | 'sso-okta'
  | 'azure-ad'
  | 'sso-azure-ad';

interface AppNames {
  [key: string]: string;
}

export const appStubToName: AppNames = {
  'azure-ad': 'Azure AD',
  docusign: 'DocuSign',
  deel: 'Deel',
  github: 'GitHub',
  'google-workspace': 'Workspace',
  hubspot: 'HubSpot',
  intercom: 'Intercom',
  jumpcloud: 'JumpCloud',
  jira: 'Jira',
  microsoft365: 'Microsoft 365',
  lastpass: 'LastPass',
  monday: 'Monday',
  notion: 'Notion',
  pipedrive: 'Pipedrive',
  quickbooks: 'QuickBooks',
  remote: 'Remote',
  slack: 'Slack',
  workable: 'Workable',
  teamtailor: 'Teamtailor',
  greenhouse: 'Greenhouse',
  xero: 'Xero',
  zoom: 'Zoom',
};

export interface AppsOverviewStats {
  activeTeamUserCount: number;
  externalUserCount: number;
}

export interface UserToBeAdded {
  userId?: number;
  appUserId: string;
  selectedUser?: OptionObj;
  dateOfActivation: string;
  groupList?: MultiSelectOption<GroupId>[];
}

export interface CurrentlyDelayedJobs<T> {
  delayed: Bull.Job<T>[];
}

export interface SeriesData {
  name: string;
  data: number[];
}

export interface ChartData {
  name: string;
  type: string;
  series: SeriesData[] | number[];
  categories?: string[];
  labels?: string[];
}

export interface AppKPI {
  companyId?: number;
  kpi: ChartData[];
}

export interface CountryListing {
  code: string;
  name: string;
}

export const APPS_NOT_REQUIRING_REFRESH_POST_ACTION = ['slack'];

export const APPS_WITH_CC_INVITE = ['microsoft365', 'google-workspace'];
export const APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION = new Set(['microsoft365', 'google-workspace']);
export const APPS_WITH_DOMAIN_NAME_CALENDAR_CONFIGURATION = ['google-workspace'];

export interface SSOMetadata {
  certificate: string;
  entityID: string;
  ssoUrl: string;
  enabled: boolean;
  apikey?: string;
  siteUrl?: string;
}

export interface SSOState {
  app: AppIntegrationStub;
  state: SSOMetadata;
}

export interface CalendarDomainConfig {
  list: string[];
  current: string;
}

export enum AppIntegrationUserStatusEnum {
  NoAccess = 'No access',
  Suspended = 'Suspended',
  Hired = 'Hired',
  Active = 'Active',
  Invited = 'Invited',
  Onboarded = 'Onboarded',
  Deleted = 'Deleted',
  Unknown = 'Unknown',
  Imported = 'Imported',
}
