import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ChangeOwnerDeviceTransitDataDto,
  ConfigurableDeviceData,
  ConfigurablePoliciesDto,
  CreateExistingDeviceDto,
  DeviceDeliveryDetails,
  DeviceDto,
  DeviceManagedApplicationsDto,
  DeviceModelDto,
  DeviceOrderDto,
  DeviceOrderUpdateDto,
  DevicePolicyDto,
  DevicePossessionDto,
  DevicePossessionWithExternalIdDto,
  DeviceProtectionDetailsDto,
  DevicesStatsDto,
  DeviceTechSpecs,
  DeviceTransitDto,
  DeviceUpdateSuperadminDto,
  ExternalUserCredentialsDto,
  InHouseMdmOsUpdateDevice,
  SuperAdminDevicesResponseDto,
  UnmatchedExternalDevicesResponseDto,
  UpdateDeviceNotesDto,
  UpdateEnrolledDeviceDetailsDto,
} from '@v2/feature/device/device.dto';
import {
  DeliveryMethodDetails,
  DevicePossessionDetails,
  ReturnDeviceReason,
  SuperAdminDevicePossessionUpdate,
} from '@v2/feature/device/device.interface';
import { AuditTrailDto } from '@v2/feature/security/security-settings/security.interface';
import axios from 'axios';

import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class DeviceAPI {
  static async getActiveDevicePossessions(): Promise<DevicePossessionDto[]> {
    return (await axios.get('/apiv2/devices/active')).data;
  }

  static async getInInventoryDevices(): Promise<DevicePossessionDto[]> {
    return (await axios.get('/apiv2/devices/in-inventory')).data;
  }

  static async getInTransitDevices(): Promise<DeviceTransitDto[]> {
    return (await axios.get('/apiv2/devices/in-transit')).data;
  }

  static async getUserInTransitDevices(userId: number): Promise<DeviceTransitDto[]> {
    return (await axios.get(`/apiv2/devices/users/${userId}/in-transit`)).data;
  }

  static async getUserDeviceOrders(userId: number): Promise<DeviceOrderDto[]> {
    return (await axios.get(`/apiv2/devices/orders/users/${userId}`)).data;
  }

  static async getDeviceOrders(): Promise<DeviceOrderDto[]> {
    return (await axios.get('/apiv2/devices/orders')).data;
  }

  static async getDeviceOrderDetails(orderId: number): Promise<DeviceOrderDto> {
    return (await axios.get(`/apiv2/devices/orders/${orderId}`)).data;
  }

  static async getDeviceOrdersAsSuperadmin(eligibleForRefinancingOnly = false): Promise<DeviceOrderDto[]> {
    return (
      await axios.get(`/apiv2/devices/superadmin/orders?eligibleForRefinancingOnly=${eligibleForRefinancingOnly}`)
    ).data;
  }

  static async getStockDevicesAsSuperadmin(): Promise<DeviceDto[]> {
    return (await axios.get('/apiv2/devices/superadmin/stock')).data;
  }

  static async getZeltTransitsAsSuperadmin(): Promise<DeviceTransitDto[]> {
    return (await axios.get('/apiv2/devices/superadmin/transits/zelt')).data;
  }

  static async addDeviceInStockAsSuperadmin(deviceModelId: number, quantity: number): Promise<DeviceDto> {
    return (await axios.post('/apiv2/devices/superadmin/stock', { deviceModelId, quantity })).data;
  }

  static async updateDeviceByIdAsSuperadmin(deviceId: number, deviceUpdate: DeviceUpdateSuperadminDto): Promise<void> {
    await axios.patch(`/apiv2/devices/superadmin/stock/${deviceId}`, deviceUpdate);
  }

  static async updateEnrolledDeviceDetailsForm(
    deviceId: number,
    updateEnrolledDeviceDetailsDto: UpdateEnrolledDeviceDetailsDto
  ): Promise<void> {
    await axios.patch(`/apiv2/devices/${deviceId}/enrolled/update-details`, updateEnrolledDeviceDetailsDto);
  }

  static async updateDeviceTechSpecsByPossessionId(
    devicePossessionId: number,
    deviceUpdate: DeviceTechSpecs
  ): Promise<void> {
    await axios.patch(`/apiv2/devices/possessions/${devicePossessionId}/tech-specs`, deviceUpdate);
  }

  static async reassignInventoryDevicePossession(
    devicePossessionId: number,
    newPossessionDetails: DevicePossessionDetails,
    deliveryDetails: DeliveryMethodDetails
  ): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/assign-device`, { newPossessionDetails, deliveryDetails });
  }

  static async assignDepDeviceToUser(
    devicePossessionId: number,
    newPossessionDetails: DevicePossessionDetails,
    deliveryDetails: DeliveryMethodDetails
  ): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/assign-dep-device`, {
      newPossessionDetails,
      deliveryDetails,
    });
  }

  static async rejectDeviceOrderAsSuperadmin(orderId: number): Promise<DeviceOrderDto> {
    return (await axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/rejected`)).data;
  }

  static async acceptDeviceOrderAsSuperadmin(orderId: number, deliveryDate: string): Promise<DeviceOrderDto> {
    return (
      await axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/accepted`, {
        deliveryDate,
      })
    ).data;
  }

  // Used when user confirms order delivery
  static async confirmDeviceOrderDelivery(
    orderId: number,
    deliveryDate: string
  ): Promise<ExternalUserCredentialsDto | null> {
    return (await axios.post(`/apiv2/devices/orders/${orderId}/confirm-delivery`, { deliveryDate })).data ?? null;
  }

  // [superadmin]: Used to mark order as shipped
  static async setDeviceOrderInShippingAsSuperadmin(orderId: number, deviceId: number): Promise<DeviceOrderDto> {
    return (
      await axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/shipping`, {
        deviceId,
      })
    ).data;
  }

  // [superadmin] assign a device to an order
  static async assignDeviceToDeviceOrderAsSuperadmin(orderId: number, deviceId: number): Promise<DeviceOrderDto> {
    return (
      await axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/assign-device`, {
        deviceId,
      })
    ).data;
  }

  // [superadmin] set order as delivered
  static async setDeviceOrderAsDeliveredAsSuperadmin(
    orderId: number,
    deviceId: number,
    deliveryDate: string
  ): Promise<DeviceOrderDto> {
    return (
      await axios.patch(`/apiv2/devices/superadmin/orders/${orderId}/status/delivered`, {
        deliveryDate,
        deviceId,
      })
    ).data;
  }

  static async getActiveDevicePossessionsByUserId(userId: number): Promise<DevicePossessionDto[]> {
    return (await axios.get(`/apiv2/devices/users/${userId}`)).data;
  }

  static async getAllCachedDevicesAsSuperadmin(): Promise<SuperAdminDevicesResponseDto> {
    return (await axios.get('/apiv2/devices/superadmin')).data;
  }

  static async getAllRefreshedDevicesAsSuperAdmin(): Promise<SuperAdminDevicesResponseDto> {
    return (await axios.get('/apiv2/devices/superadmin/refresh')).data;
  }

  static async orderDevice(
    deviceModelId: number,
    devicePossessionDetails: DevicePossessionDetails,
    delivery: DeviceDeliveryDetails,
    contractLength: number,
    phoneNumber: string | null,
    isPurchase: boolean
  ): Promise<void> {
    await axios.post('/apiv2/devices', {
      deviceModelId,
      devicePossessionDetails,
      delivery,
      contractLength,
      phoneNumber,
      isPurchase,
    });
  }

  static async getDevicesInventoryStats(): Promise<DevicesStatsDto> {
    return (await axios.get('/apiv2/devices/inventory-stats')).data;
  }

  static async addExistingDevice(device: CreateExistingDeviceDto): Promise<DevicePossessionDto> {
    return (await axios.post('/apiv2/devices/existing-device', device)).data;
  }

  static async approveDeviceOrder(orderId: number): Promise<void> {
    await axios.patch(`/apiv2/device-orders/${orderId}/approve-order`);
  }

  static async getOrderTrackingLinkAsSuperAdmin(orderId: number): Promise<string> {
    return (await axios.get(`/apiv2/device-orders/${orderId}/tracking-link/superadmin`)).data;
  }

  static async getOrderTrackingLink(orderId: number): Promise<string> {
    return (await axios.get(`/apiv2/device-orders/${orderId}/tracking-link`)).data;
  }

  static async updateOrderTrackingLink(orderId: number, trackingLink: string): Promise<void> {
    await axios.patch(`/apiv2/device-orders/${orderId}/tracking-link/superadmin`, { trackingLink });
  }

  static async rejectDeviceOrder(orderId: number): Promise<void> {
    await axios.patch(`/apiv2/device-orders/${orderId}/reject-order`);
  }

  // used to cancel an order as a user
  static async cancelDeviceOrder(orderId: number): Promise<DeviceOrderDto> {
    return (await axios.patch(`/apiv2/device-orders/${orderId}/cancel-order`)).data;
  }

  // [superadmin] used to confirm the return of a device
  static async markDeviceAsReturnedAsSuperAdmin(deviceTransitId: number): Promise<void> {
    await axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/stock-device/mark-as-returned`);
  }

  // [superadmin] used to mark a storage device as shipped (to user / company site)
  static async markStorageDeviceAsShippedAsSuperAdmin(deviceTransitId: number): Promise<void> {
    await axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/storage-device/mark-as-shipped`);
  }

  // [superadmin] used to mark a storage device as delivered (to user / company site)
  static async markStorageDeviceAsDeliveredAsSuperAdmin(deviceTransitId: number, deliveryDate: string): Promise<void> {
    await axios.post(`/apiv2/devices/superadmin/transits/${deviceTransitId}/storage-device/mark-as-delivered`, {
      deliveryDate,
    });
  }

  static async deleteDeviceOrderAsSuperAdmin(orderId: number): Promise<void> {
    await axios.delete(`/apiv2/device-orders/${orderId}/superadmin`);
  }

  static async deleteDevicePossessionAsSuperAdmin(possessionId: number, deviceId: number): Promise<void> {
    await axios.delete(`/apiv2/devices/superadmin/${deviceId}/possessions/${possessionId}`);
  }

  static async createDevicePossessionAsSuperAdmin(
    deviceId: number,
    createData: Pick<
      DevicePossessionDto,
      'companyId' | 'possessionType' | 'possessionId' | 'startDate' | 'endDate' | 'deliveryAddress'
    >
  ): Promise<void> {
    await axios.post(`/apiv2/devices/superadmin/${deviceId}/possessions`, createData);
  }

  static async updateDevicePossessionAsSuperAdmin(
    deviceId: number,
    possessionId: number,
    updateData: Pick<
      DevicePossessionDto,
      'companyId' | 'possessionType' | 'possessionId' | 'startDate' | 'endDate' | 'deliveryAddress'
    >
  ): Promise<void> {
    await axios.put(`/apiv2/devices/superadmin/${deviceId}/possessions/${possessionId}`, updateData);
  }

  static async updateDeviceOrderAsSuperadmin(
    orderId: number,
    deviceOrderUpdate: DeviceOrderUpdateDto
  ): Promise<DeviceOrderDto> {
    return (await axios.patch(`/apiv2/devices/orders/${orderId}/superadmin`, deviceOrderUpdate)).data;
  }

  static async deleteDeviceOrder(orderId: number): Promise<void> {
    await axios.delete(`/apiv2/device-orders/${orderId}`);
  }

  static async deleteStockDeviceByDeviceIdAsSuperadmin(deviceId: number): Promise<void> {
    await axios.delete(`/apiv2/devices/${deviceId}/superadmin`);
  }

  static async deleteCompanyOwnedDeviceByDevicePossessionId(devicePossessionId: number): Promise<void> {
    await axios.delete(`/apiv2/devices/${devicePossessionId}`);
  }

  static async getDevicePossession(devicePossessionId: number): Promise<DevicePossessionDto> {
    return (await axios.get(`/apiv2/devices/possessions/${devicePossessionId}`)).data ?? {};
  }

  static async getAllDevicePossessionsByCompanyId(companyId: number): Promise<DevicePossessionDto[]> {
    return (await axios.get(`/apiv2/devices/superadmin/companies/${companyId}/possessions`)).data ?? [];
  }

  static async updateCompanyDevicePossessionById(
    companyId: number,
    devicePossessionId: number,
    update: SuperAdminDevicePossessionUpdate
  ): Promise<DevicePossessionDto> {
    return (
      await axios.patch(`/apiv2/devices/superadmin/companies/${companyId}/possessions/${devicePossessionId}`, update)
    ).data;
  }

  static async getActiveTransitForDevicePossession(devicePossessionId: number): Promise<DeviceTransitDto | null> {
    const transit = (await axios.get(`/apiv2/devices/${devicePossessionId}/active-transit`)).data;
    return Boolean(transit) ? transit : null;
  }

  static async getUserDevicePossessionDetails(
    userId: number,
    devicePossessionId: number
  ): Promise<DevicePossessionDto> {
    return (await axios.get(`/apiv2/devices/${devicePossessionId}/users/${userId}`)).data;
  }

  static async getRefreshedDevicePossessionDetails(devicePossessionId: number): Promise<DevicePossessionDto | null> {
    return (await axios.get(`/apiv2/devices/${devicePossessionId}/superadmin/refresh`)).data ?? null;
  }

  static async syncWithExternalProviderById(devicePossessionId: number): Promise<DevicePossessionWithExternalIdDto> {
    return (await axios.post(`/apiv2/devices/superadmin/${devicePossessionId}/sync`)).data;
  }

  static async scanDevice(devicePossessionId: number): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/actions/scan-device`);
  }

  static async scanDeviceBySuperadmin(devicePossessionId: number, companyId: number): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/company/${companyId}/actions/scan-device`);
  }

  static async wipeDevice(devicePossessionId: number): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/actions/wipe-device`);
  }

  static async disenrollDevice(devicePossessionId: number): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/actions/disenroll-device`);
  }

  static async lockDevice(devicePossessionId: number, message: string): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/actions/lock-device`, message);
  }

  static async getAvailableCompanyDevicePolicies(): Promise<DevicePolicyDto[]> {
    return (await axios.get('/apiv2/device-policies')).data;
  }

  static async getAppliedCompanyDevicePolicies(): Promise<AppliedDevicePoliciesDto> {
    return (await axios.get('/apiv2/device-policies/applied-policies')).data;
  }

  static async getDeviceManagedApps(devicePossessionId: number): Promise<DeviceManagedApplicationsDto[]> {
    return (await axios.get(`/apiv2/managed-apps/device/${devicePossessionId}/all`)).data;
  }

  static async getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(): Promise<AppliedDevicePoliciesZeltDtoWithConfigurableFeature> {
    return (await axios.get('/apiv2/device-policies/applied-policies-by-zelt-mdm')).data;
  }

  static async getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile(): Promise<AppliedDevicePoliciesZeltDtoWithConfigurableFeature> {
    return (await axios.get('/apiv2/device-policies/applied-policies-by-zelt-mdm-mobile')).data;
  }

  static async getSuperadminAppliedCompanyDevicePoliciesByZeltMdm(
    companyId: number
  ): Promise<AppliedDevicePoliciesDto> {
    return (await axios.get(`/apiv2/device-policies/superadmin/applied-policies-by-zelt-mdm/company/${companyId}`))
      .data;
  }

  static async getSuperadminAvailableCompanyDevicePolicies(companyId: number): Promise<DevicePolicyDto[]> {
    return (await axios.get(`/apiv2/device-policies/superadmin/company/${companyId}`)).data;
  }

  static async getSuperadminAppliedCompanyDevicePolicies(companyId: number): Promise<AppliedDevicePoliciesDto> {
    return (await axios.get(`/apiv2/device-policies/superadmin/applied-policies/company/${companyId}`)).data;
  }

  static async updateCompanyDeviceAppliedPoliciesByZeltMdm(
    updatedAppliedPolicies: AppliedDevicePoliciesDto,
    mobile: boolean,
    configurablePolicies?: ConfigurablePoliciesDto
  ): Promise<void> {
    const queryParam = { isMobile: mobile };
    if (configurablePolicies) {
      await axios.patch(
        '/apiv2/device-policies/applied-policies-by-zelt-mdm',
        {
          ...updatedAppliedPolicies,
          ...configurablePolicies,
        },
        { params: queryParam }
      );
    } else {
      await axios.patch('/apiv2/device-policies/applied-policies-by-zelt-mdm', updatedAppliedPolicies, {
        params: queryParam,
      });
    }
  }

  static async updateCompanyDeviceAppliedPolicies(updatedAppliedPolicies: AppliedDevicePoliciesDto): Promise<void> {
    await axios.patch('/apiv2/device-policies/applied-policies', updatedAppliedPolicies);
  }

  static async sendEnrollmentRequest(devicePossessionId: number): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/actions/enrolment-request`);
  }

  static async getUnmatchedExternalDevicesForPossession(
    devicePossessionId: number
  ): Promise<UnmatchedExternalDevicesResponseDto> {
    return (await axios.get(`/apiv2/devices/${devicePossessionId}/external-unmatched-devices`)).data;
  }

  static async matchDeviceLocalIdWithDeviceExternalId(
    devicePossessionId: number,
    externalDeviceId: number
  ): Promise<void> {
    await axios.post(`/apiv2/devices/${devicePossessionId}/match-device`, {
      externalDeviceId,
    });
  }

  static async getDeviceAssignedExternalUserCredentials(userId: number): Promise<ExternalUserCredentialsDto> {
    return (await axios.get(`/apiv2/devices/user-credentials/${userId}`)).data;
  }

  static async getAlerts(userId: number): Promise<Alert<DeviceProtectionDetailsDto>> {
    return (await axios.get(`/apiv2/devices/${userId}/alerts`)).data;
  }

  static async getLatestOSVersion(): Promise<string> {
    return (await axios.get('/apiv2/devices/config/latest-os-version')).data;
  }

  static async getDeviceModels(includeAll = false, order = 'ASC'): Promise<DeviceModelDto[]> {
    return (await axios.get(`/apiv2/devices/models?includeAll=${includeAll}&order${order}`)).data;
  }

  static async updateDeviceModel(modelId: number, update: Omit<DeviceModelDto, 'id'>): Promise<void> {
    await axios.put(`/apiv2/devices/models/${modelId}`, update);
  }

  static async addDeviceModel(deviceModel: Omit<DeviceModelDto, 'id'>): Promise<void> {
    await axios.post('/apiv2/devices/models', deviceModel);
  }

  static async deleteDeviceModel(modelId: number): Promise<void> {
    await axios.delete(`/apiv2/devices/models/${modelId}`);
  }

  // initiate an internal transit from user to user/site
  static async changeDeviceOwnerTransitRequest(
    devicePossessionId: number,
    transitData: ChangeOwnerDeviceTransitDataDto
  ): Promise<DeviceTransitDto> {
    return (await axios.post(`/apiv2/devices/${devicePossessionId}/change-owner`, transitData)).data;
  }

  // mark as shipped an internal transit as a user (shipping from user to user/site)
  static async changeDeviceOwnerSetTransitInShipping(deviceTransitId: number): Promise<DeviceTransitDto> {
    return (await axios.post(`/apiv2/devices/${deviceTransitId}/change-owner/confirm-shipping`)).data;
  }

  // confirm an internal transit delivery as a user (delivery from user to user/site
  static async changeDeviceOwnerSetTransitAsDelivered(deviceTransitId: number, deliveryDate: string): Promise<void> {
    await axios.post(`/apiv2/devices/${deviceTransitId}/change-owner/confirm-delivery`, { deliveryDate });
  }

  // initiate return of device to CompanySite / ZeltStorage / ZeltStock (cancel contract)
  static async initiateDeviceReturn(
    devicePossessionId: number,
    returnReason: ReturnDeviceReason,
    senderAddress: string,
    deliveryAddress: string,
    siteId: number | null,
    notes?: string
  ): Promise<DeviceTransitDto> {
    return (
      await axios.post(`/apiv2/devices/${devicePossessionId}/return-device`, {
        returnReason,
        notes,
        senderAddress,
        deliveryAddress,
        siteId,
      })
    ).data;
  }

  static async notifyDeviceEnrolledByHexnode(deviceId: number): Promise<void> {
    await axios.get(`/apiv2/devices/${deviceId}/notify-admins`);
  }

  // mark returning device as shipped as an user
  static async markReturnTransitAsShipped(transitId: number): Promise<DeviceTransitDto | null> {
    const updatedTransit = (await axios.post(`/apiv2/devices/transits/${transitId}/mark-return-transit-as-shipped`))
      .data;
    return Boolean(updatedTransit) ? updatedTransit : null;
  }

  static async cancelDeviceTransit(transitId: number): Promise<void> {
    await axios.delete(`/apiv2/devices/transits/${transitId}/cancel`);
  }

  static async upgradeToZeltMDM(): Promise<void> {
    await axios.post(`/apiv2/devices/upgrade`);
  }

  static async triggerInstallOsUpdate(
    deviceId: number,
    installAction: string,
    version: string
  ): Promise<InHouseMdmOsUpdateDevice> {
    const osUpdate: InHouseMdmOsUpdateDevice = {
      zeltDeviceId: deviceId,
      updates: [{ InstallAction: installAction, ProductVersion: version }],
    };
    return (await axios.post<InHouseMdmOsUpdateDevice>(`/apiv2/os-updates/device/update`, osUpdate)).data;
  }

  static async getDirectoryDeviceByPossessionId(possessionId: number): Promise<ConfigurableDeviceData> {
    return (await axios.get(`/apiv2/devices/company/directory/device-possession/${possessionId}`)).data;
  }

  static async updateDeviceCustomerNotes(deviceId: number, notes: UpdateDeviceNotesDto): Promise<void> {
    await axios.put(`/apiv2/devices/${deviceId}/notes`, notes);
  }

  static async deviceOrderAllowed() {
    return (await axios.get(`/apiv2/devices/order/allowed`)).data;
  }
}

export class DeviceEndpoints {
  static getDeviceByIdAsSuperAdmin(deviceId: number): Endpoint<DeviceDto> {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}`,
    };
  }

  static getOrdersByDeviceIdAsSuperAdmin(deviceId: number): Endpoint<DeviceOrderDto[]> {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}/orders`,
    };
  }

  static getPossessionsByDeviceIdAsSuperAdmin(deviceId: number): Endpoint<DevicePossessionDto[]> {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}/possessions`,
    };
  }

  static getTransitsByDeviceIdAsSuperAdmin(deviceId: number): Endpoint<DeviceTransitDto[]> {
    return {
      url: `/apiv2/devices/superadmin/${deviceId}/transits`,
    };
  }

  static getAlerts(userId: number): Endpoint<Alert<DeviceProtectionDetailsDto>> {
    return {
      url: `/apiv2/devices/${userId}/alerts`,
    };
  }

  static getDeviceModelsByCompanyId(includeAll = false): Endpoint<DeviceModelDto[]> {
    return {
      url: `/apiv2/devices/models/store?includeAll=${includeAll}`,
    };
  }

  static getDirectoryDevices(): Endpoint<ConfigurableDeviceData[]> {
    return {
      url: `/apiv2/devices/company/directory`,
    };
  }

  static getOffboardingDevices(userId: number): Endpoint<ConfigurableDeviceData[]> {
    return {
      url: `/apiv2/devices/users/${userId}/offboarding`,
    };
  }

  static getActivityLogForDevice(deviceId: number): Endpoint<AuditTrailDto[]> {
    return {
      url: `/apiv2/devices/${deviceId}/activity-log`,
    };
  }

  static getAllAvailableDevicePolicies(): Endpoint<DevicePolicyDto[]> {
    return {
      url: `/apiv2/device-policies`,
    };
  }
}
