import React from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { Route, Switch } from 'react-router-dom';

import { SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE, SUPER_ADMIN_SALARY_ROUTE } from '@/lib/routes';
import { SuperAdminSalaryPaycodeSeedersPage } from '@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary-paycode-seeders.page';
import { SuperAdminSalaryPage } from '@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary.page';

const PAGE_CONFIG = {
  header: {
    tabs: [
      { label: 'Schedule migration', value: SUPER_ADMIN_SALARY_ROUTE, scopes: ['payroll'] },
      { label: 'Paycode seeders', value: SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE, scopes: ['payroll'] },
    ],
  },
} as const;

interface SuperAdminSalaryRouterProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
}

export const SuperAdminSalaryRouter = ({ companies }: SuperAdminSalaryRouterProps): JSX.Element => {
  return (
    <Switch>
      <Route path={SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE}>
        <SuperAdminSalaryPaycodeSeedersPage pageConfig={PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_SALARY_ROUTE} exact>
        <SuperAdminSalaryPage companies={companies} pageConfig={PAGE_CONFIG} />
      </Route>
    </Switch>
  );
};
