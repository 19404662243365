import { useCallback, useMemo, useRef, useState } from 'react';

import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ExternalEmployerDto } from '@v2/feature/payroll/payroll-external.dto';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PayrollSetting } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting.component';
import { PayrollSettingHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setttings-header.component';
import {
  HMRCPlaceholderData,
  isUsingPlaceholderHMRCData,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayrollHMRCSetting } from '@/v2/feature/payroll/payroll.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

const validationSchema = yup.object({
  officeNumber: yup
    .string()
    .matches(/^[0-9]{3}$/, 'Enter the office number in the format: 123')
    .required('Required'),
  payeReference: yup.string().required('PAYE reference is required'),
  accountsOfficeReference: yup.string().required('Accounts office reference is required'),
  smallEmployersRelief: yup.boolean().required('This field is required'),
  govGatewayId: yup.string().required('Government gateway ID is required'),
  govGatewayIdChanged: yup.boolean(),
  password: yup
    .string()
    .nullable()
    .when('govGatewayId', {
      is: HMRCPlaceholderData.govGatewayId,
      otherwise: yup.string().nullable().required('Government gateway password is required'),
    }),
  contactFirstName: yup.string().required('The full name of a payroll contact is required'),
  contactLastName: yup.string().required('The full name of a payroll contact is required'),
  contactEmail: yup
    .string()
    .email('Enter a valid email address')
    .required('A payroll contact email address is required'),
});

interface PayrollSettingHMRCEditProps {
  payrollId: number;
  readonly payroll?: ExternalEmployerDto;
  title?: string;
  onClose: (updated: boolean) => void;
  sx?: SxProps<Theme>;
}

export const PayrollSettingHMRCEdit = ({
  payrollId,
  payroll,
  title,
  onClose,
  sx,
}: PayrollSettingHMRCEditProps): JSX.Element => {
  const [showMessage] = useMessage();
  const savedHMRCDetails = useRef<PayrollHMRCSetting>({
    officeNumber: '',
    payeReference: '',
    accountsOfficeReference: '',
    smallEmployersRelief: false,
    govGatewayId: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    password: '',
  });

  const close = useCallback(
    (updated: boolean) => {
      onClose?.(updated);
    },
    [onClose]
  );

  const updatePayroll = useCallback(
    async (payroll: PayrollHMRCSetting) => {
      try {
        await PayrollExternalApi.updatePayroll(payrollId, payroll);
        showMessage('Payroll updated successfully.', 'success');
        return true;
      } catch (error) {
        showMessage(`Payroll could not be updated. ${nestErrorMessage(error)}`, 'error');
        return false;
      }
    },
    [payrollId, showMessage]
  );

  const formik = useFormik({
    initialValues: {
      officeNumber: payroll?.hmrcDetails.officeNumber || '',
      payeReference: payroll?.hmrcDetails.payeReference || '',
      accountsOfficeReference: payroll?.hmrcDetails.accountsOfficeReference || '',
      smallEmployersRelief: payroll?.hmrcDetails.smallEmployersRelief || false,
      govGatewayId: payroll?.rtiSubmissionSettings.senderId || '',
      contactFirstName: payroll?.rtiSubmissionSettings.contact?.firstName || '',
      contactLastName: payroll?.rtiSubmissionSettings.contact?.lastName || '',
      contactEmail: payroll?.rtiSubmissionSettings.contact?.email || '',
      password: payroll?.rtiSubmissionSettings.password || null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const success = await updatePayroll(values);
      if (success) {
        close(success);
      }
    },
  });

  const { touched, handleSubmit, isSubmitting } = formik;
  const [usingPlaceholderHMRCData, setUsingPlaceholderHMRCData] = useState(
    isUsingPlaceholderHMRCData(payroll && { kind: 'employer', ...payroll.hmrcDetails })
  );

  const onMissingHMRCChange = useCallback(
    (usePlaceholder: boolean) => {
      if (usePlaceholder) {
        savedHMRCDetails.current = {
          ...formik.values,
        };
        formik.setValues(HMRCPlaceholderData);
      } else {
        formik.setValues(savedHMRCDetails.current);
      }
      setUsingPlaceholderHMRCData(usePlaceholder);
    },
    [formik]
  );

  // close on escape key press
  useEscapeKey(() => close(false));

  const tooltips = useMemo(
    () => ({
      payeReference: (
        <>
          You can find your PAYE reference in your Government Gateway account online and on letters or emails from HMRC
          regarding PAYE.
          <br />
          They also appear on any P45s or P60s of your employees. If you don't have one yet, you can register online.
          <br />
          Go to{' '}
          <a
            href="https://www.zelt.app/post/register-as-an-employer-in-the-uk"
            style={{ color: 'inherit', textDecoration: 'none', fontWeight: 600, cursor: 'pointer' }}
          >
            register-as-an-employer-in-the-uk
          </a>{' '}
          for more information.";
        </>
      ),
      accountsOfficeReference:
        "13-character value required when paying to HMRC the PAYE liabilities; this number is issued at the same time as the Employer's PAYE reference number but contrary to the latter, this number does not go on documents given to employees",

      smallEmployersRelief:
        "You qualify if you paid £45,000 or less in Class 1 National Insurance in the last complete tax year. As an employer, you can usually reclaim 92% of employees' Statutory Maternity, Paternity, Adoption, Parental Bereavement and Shared Parental Pay. But you can reclaim 103% if your business qualifies for Small Employers' Relief.",
    }),
    []
  );

  return (
    <Stack sx={{ display: 'flex', flex: 1, overflow: 'hidden auto', ...sx }}>
      <Stack sx={{ rowGap: spacing.g10 }}>
        {title && <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>{title}</Typography>}
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
          Please complete your company details below.
        </Typography>
        <Box>
          <CheckboxComponent
            label="Company is not registered with HMRC or has no HMRC information available"
            checked={usingPlaceholderHMRCData}
            onChange={(_, checked) => onMissingHMRCChange(checked)}
            disabled={isSubmitting}
          />
          <Typography
            sx={{
              ...themeFonts.caption,
              mt: spacing.m5,
              visibility: usingPlaceholderHMRCData ? undefined : 'hidden',
              whiteSpace: 'nowrap',
              color: themeColors.DarkGrey,
            }}
          >
            You will need to update these settings after you receive your company account details from HMRC.
          </Typography>
        </Box>
      </Stack>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'inline-grid',
              gridTemplateColumns: '1fr 3fr',
              rowGap: spacing.g10,
              columnGap: spacing.g20,
            }}
          >
            <PayrollSettingHeader sx={{ mt: spacing.m30 }}>PAYE details</PayrollSettingHeader>
            <PayrollSetting
              name="fullPayeReference"
              label="PAYE reference"
              formik={formik}
              tooltip={tooltips.payeReference}
              mode="custom"
              component={
                <Stack sx={{ flexFlow: 'row', gap: spacing.g10, alignItems: 'baseline' }}>
                  <TextfieldComponent
                    name="officeNumber"
                    type="tel" // numeric without up/down controls
                    autoFocus
                    disabled={isSubmitting || usingPlaceholderHMRCData}
                    onChange={formik.handleChange}
                    clearText={() => formik.setFieldValue('officeNumber', '')}
                    endAdornment="none"
                    value={formik.values['officeNumber'] || ''}
                    error={!!formik.touched['officeNumber'] && !!formik.errors['officeNumber']}
                    helperText={formik.touched['officeNumber'] && formik.errors['officeNumber']?.toString()}
                    maxLength={3}
                    sx={{ mt: spacing.m15, maxWidth: '80px' }}
                  />
                  <Typography sx={themeFonts.caption}>/</Typography>
                  <TextfieldComponent
                    name="payeReference"
                    type="text"
                    disabled={isSubmitting || usingPlaceholderHMRCData}
                    onChange={formik.handleChange}
                    clearText={() => formik.setFieldValue('payeReference', '')}
                    value={formik.values['payeReference'] || ''}
                    error={!!formik.touched['payeReference'] && !!formik.errors['payeReference']}
                    helperText={formik.touched['payeReference'] && formik.errors['payeReference']?.toString()}
                    sx={{ mt: spacing.m15 }}
                  />
                </Stack>
              }
            />
            <PayrollSetting
              name="accountsOfficeReference"
              label="Accounts office reference"
              formik={formik}
              tooltip={tooltips.accountsOfficeReference}
              disabled={isSubmitting || usingPlaceholderHMRCData}
            />
            <CheckboxComponent
              sx={{ gridColumn: '2 / 3', mt: spacing.mt20 }}
              label="Employer qualifies for Small Employers Relief"
              labelTooltip={tooltips.smallEmployersRelief}
              name="smallEmployersRelief"
              onChange={formik.handleChange}
              disabled={isSubmitting || usingPlaceholderHMRCData}
            />

            <PayrollSettingHeader sx={{ mt: spacing.m30 }}>Government gateway</PayrollSettingHeader>
            <PayrollSetting
              name="govGatewayId"
              label="Government gateway ID"
              formik={formik}
              disabled={isSubmitting || usingPlaceholderHMRCData}
            />
            <PayrollSetting
              name="password"
              label="Password"
              type="password"
              formik={formik}
              disabled={isSubmitting || usingPlaceholderHMRCData}
              autoComplete="new-password" // don't allow the browser to autofill
            />

            <PayrollSettingHeader sx={{ mt: spacing.m30 }}>
              Appoint a person for payroll notifications
            </PayrollSettingHeader>
            <PayrollSetting
              name="contactFirstName"
              label="First name"
              formik={formik}
              disabled={isSubmitting || usingPlaceholderHMRCData}
            />
            <PayrollSetting
              name="contactLastName"
              label="Last name"
              formik={formik}
              disabled={isSubmitting || usingPlaceholderHMRCData}
            />
            <PayrollSetting
              name="contactEmail"
              label="Email"
              type="email"
              formik={formik}
              disabled={isSubmitting || usingPlaceholderHMRCData}
            />
          </Box>

          <Stack sx={{ flexFlow: 'row', my: spacing.m50, gap: spacing.g10 }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="secondary"
              disabled={isSubmitting}
              onClick={() => close(false)}
            >
              Cancel
            </ButtonComponent>
            <LoaderButton
              name="Save"
              type="submit"
              sizeVariant="medium"
              colorVariant="primary"
              disabled={!touched}
              loading={isSubmitting}
            />
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
};
