import { useCallback, useEffect, useRef } from 'react';

type UseEventSourceCallbacks<T> = {
  onInit?: () => void;
  onMessage?: (data: T) => void;
  onError?: (error: unknown) => void;
};

export function useEventSource<T>(url: string | null | undefined, callbacks: UseEventSourceCallbacks<T>): void {
  const eventSourceRef = useRef<EventSource | null>(null);
  const callbacksRef = useRef(callbacks);

  const connect = useCallback(() => {
    if (!url) {
      console.info('No URL provided, skipping EventSource connection');
      return () => {}; // no-op cleanup function
    }
    console.info('Connecting EventSource:', url);
    callbacksRef.current.onInit?.();

    const eventSource = new EventSource(url);
    eventSourceRef.current = eventSource;

    eventSource.onmessage = ({ data }) => {
      if (data === 'ping') {
        return; // Ignore ping messages
      }
      try {
        callbacksRef.current.onMessage?.(JSON.parse(data) as T);
      } catch (error) {
        console.error('Failed to parse message:', error);
      }
    };

    eventSource.onerror = (ev) => {
      console.error('EventSource error:', ev);
      callbacksRef.current.onError?.(new Error('EventSource connection error'));
      eventSource.close();
      console.debug('Not retrying eventsource connection on error!');
    };

    // Keepalive ping
    const pingInterval = setInterval(() => {
      if (eventSource.readyState === EventSource.OPEN) {
        eventSource.dispatchEvent(new Event('ping'));
      }
    }, 25000);

    return () => {
      clearInterval(pingInterval);
      eventSource.close();
    };
  }, [url]);

  useEffect(() => {
    callbacksRef.current = callbacks;
  }, [callbacks]);

  useEffect(() => {
    const cleanup = connect();
    return () => {
      cleanup();
      eventSourceRef.current?.close();
    };
  }, [connect]);
}
