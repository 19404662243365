import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const GrowthForbiddenAccess = () => {
  return (
    <Box
      style={{
        backgroundColor: themeColors.Background,
        padding: '16px',
        borderRadius: radius.br8,
        maxWidth: '600px',
        minHeight: '40%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g16,
        }}
      >
        <Typography variant="title3">Forbidden access</Typography>
        <Typography variant="caption">You dont have access to this page details</Typography>
      </Box>
    </Box>
  );
};
