import React from 'react';

import { ReactComponent as NotEnrolledIcon } from '@/images/side-bar-icons/Rejected-grey.svg';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

type NotEnrolledInPensionProps = {
  userId: number;
};

export const NotEnrolledInPension = ({ userId }: NotEnrolledInPensionProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const name = getCachedUserById(userId)?.displayName || 'User';
  return (
    <StyledTooltip title={polyglot.t('PayrunTable.notEnrolledInPension', { name })}>
      <NotEnrolledIcon />
    </StyledTooltip>
  );
};
