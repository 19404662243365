import { useCallback, useEffect, useState } from 'react';

import { Alert, Box, Stack } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { Errors } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { AuthSignupThanks } from '@/v2/feature/auth/components/auth-signup-thanks.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface FormProps {
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  email: string;
}

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  companyName: yup.string().required('Company name is required'),
  jobTitle: yup.string().required('Job title is required'),
});

export const AuthXeroSignupPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [invitationSent, setInvitationSent] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email') as string;
  const firstName = searchParams.get('firstName') as string;
  const lastName = searchParams.get('lastName') as string;

  const getAppCredential = useCallback(() => {
    if (!email) {
      window.location.href = AppIntegrationAPI.getSignUpInitiateUrl();
    }
  }, [email]);

  const formik = useFormik<FormProps>({
    initialValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      companyName: '',
      jobTitle: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (formData: FormProps) => {
      try {
        setIsLoading(true);
        const onboarding = {
          companyName: formData.companyName,
          user: {
            emailAddress: email,
            firstName: firstName,
            lastName: lastName,
            jobTitle: formData.jobTitle,
          },
        };
        const user = await AuthAPI.signupAccountCreation(onboarding);
        await AuthAPI.signupEmail({
          userId: user.userId,
          password: user.password,
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          companyName: user.company?.name,
        });

        setIsLoading(false);
        setInvitationSent(true);
        setError('');
      } catch (error: any) {
        if (error.response.data.message === Errors.EMAIL_EXISTS) {
          setError('User already registered!');
        } else {
          setError('Something bad happened. ' + error.response.data.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    getAppCredential();
  }, [getAppCredential]);

  if (invitationSent) {
    return <AuthSignupThanks />;
  }

  return (
    <AuthLayout title="Get started with Zelt" submit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        {error && <Alert severity="error">{error}</Alert>}
        <Stack spacing={spacing.m25} sx={{ mt: 1 }}>
          <TextfieldComponent
            label="First Name"
            name="firstName"
            disabled
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            size="small"
            endAdornment="none"
          />
          <TextfieldComponent
            label="Last Name"
            disabled
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            size="small"
            endAdornment="none"
          />
          <TextfieldComponent
            label="Email"
            disabled
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            size="small"
            endAdornment="none"
          />
          <TextfieldComponent
            label="Company Name"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={formik.touched.companyName && formik.errors.companyName}
            size="small"
            endAdornment="none"
            autoFocus
          />
          <TextfieldComponent
            label="Job Title"
            name="jobTitle"
            value={formik.values.jobTitle}
            onChange={formik.handleChange}
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            size="small"
            endAdornment="none"
          />
          <Box sx={spacing.mt40}>
            {' '}
            <LoaderButton
              type="submit"
              name="Create account"
              fullWidth
              loading={isLoading}
              sizeVariant="large"
              colorVariant="primary"
            />
          </Box>
        </Stack>
      </FormikProvider>
    </AuthLayout>
  );
};
