import React, { useMemo } from 'react';

import { keyBy } from 'lodash';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CommonCycleAndBankQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { CommonCycleAndBankSection } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';
import { GrowthPreviewer } from '@/v2/feature/growth/shared/components/growth-previewer.component';
import { OrderPreference } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';

export const CycleCreationQuestionPreviewer = ({
  isPreviewerOpen,
  setIsPreviewerOpen,
  previewReviewer,
  allQuestions,
  allSections,
  order,
}: {
  isPreviewerOpen: boolean;
  setIsPreviewerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  previewReviewer: ReviewerTypes | undefined;
  allQuestions: CommonCycleAndBankQuestion[];
  allSections: CommonCycleAndBankSection[];
  order: OrderPreference[];
}) => {
  const questionsLookup = useMemo(() => {
    if (!allQuestions || !previewReviewer) return keyBy([], 'id');

    const questions = allQuestions
      .filter((q) => q.reviewerSelect.includes(previewReviewer))
      .map((q) => {
        return {
          ...q,
          questionText: previewReviewer === ReviewerTypes.Self ? q.questionSelf : q.questionMain,
          options: q.answerOptions,
        };
      });
    return keyBy(questions ?? [], 'id');
  }, [previewReviewer, allQuestions]);

  return (
    <DrawerModal
      isOpen={isPreviewerOpen}
      setIsOpen={setIsPreviewerOpen}
      onClose={() => {
        setIsPreviewerOpen(false);
      }}
      widthPercentage={50}
    >
      <GrowthPreviewer questionsLookup={questionsLookup} allSections={allSections} order={order} />
    </DrawerModal>
  );
};
