import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface DeleteCompanyEntityDrawerProps {
  entity: CompanyUnitDto;
  close: (updated: boolean) => void;
}

export const DeleteCompanyEntityDrawer = ({ entity, close }: DeleteCompanyEntityDrawerProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [isDeleting, setIsDeleting] = useState(false);
  const [showMessage] = useMessage();

  const deleteEntity = useCallback(async (): Promise<void> => {
    try {
      setIsDeleting(true);
      await CompanyAPI.deleteEntity(entity);
      close(true);
    } catch (error) {
      showMessage(
        `${polyglot.t('DeleteCompanyEntityDrawer.errorMessages.delete')}}: ${nestErrorMessage(error)}`,
        'error'
      );
      setIsDeleting(false);
    }
  }, [showMessage, polyglot, close, entity]);

  return (
    <Box style={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('DeleteCompanyEntityDrawer.deleteEntity')}</Typography>

      <Typography variant="caption">
        {polyglot.t('DeleteCompanyEntityDrawer.confirmDeletion', { entityName: entity.legalName })}
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={() => close(false)}
          disabled={isDeleting}
          fullWidth
        >
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.delete')}
          loading={isDeleting}
          onClick={deleteEntity}
          colorVariant="danger"
          sizeVariant="medium"
          fullWidth
        />
      </Box>
    </Box>
  );
};
