import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const PlaceholderBox = ({
  title,
  countOne,
  countTwo,
  action,
}: {
  title: string;
  countOne: string;
  countTwo: string;
  action: () => void;
}) => {
  return (
    <Box
      role="button"
      sx={{
        border: borders.light,
        borderRadius: radius.br12,
        boxSizing: 'border-box',
        p: spacing.p16,
        width: '100%',
        '&:hover': {
          cursor: 'pointer',
          background: themeColors.TableHover,
        },
      }}
      onClick={action}
    >
      <Typography variant="title4">{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
        <Typography variant="caption">{countOne}</Typography>
        <Box sx={{ height: '3px', width: '3px', borderRadius: '100%', backgroundColor: themeColors.DarkGrey }} />
        <Typography variant="caption">{countTwo}</Typography>
      </Box>
    </Box>
  );
};
