import React, { Suspense, useCallback, useMemo, useState } from 'react';

import { Autocomplete, Box, Paper } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { ClearIcon } from '@v2/components/theme-components/clear-icon.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { StyledRadioGroup } from '@v2/components/theme-components/styled-radio-group.component';
import { Typography } from '@v2/components/typography/typography.component';
import { getPublicHolidayCalendarOptions } from '@v2/feature/absence/absence.util';
import { HolidayCalendarAPI } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import { CreateHolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { StyledAuto, StyledAutoTextfield } from '@v2/styles/autocomplete.styles';
import { StyledMenuItem } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { iconSize } from '@v2/styles/table.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE } from '@/lib/routes';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const validationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup.string().required(polyglot.t('validation.requiredField')),
    country: yup.string().nullable().notRequired(),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const CreateHolidayCalendarDrawer = ({ isOpen, setIsOpen, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <CreateHolidayCalendarDrawerContent refresh={refresh} setIsOpen={setIsOpen} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

const CreateHolidayCalendarDrawerContent = ({ refresh, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const [copyCountry, setCopyCountry] = useState(false);

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const countriesOptions = useMemo(() => getPublicHolidayCalendarOptions({ excludeNone: true }), []);

  const createCalendar = useCallback(
    async (values: CreateHolidayCalendarDto) => {
      try {
        setLoading(true);
        const data: CreateHolidayCalendarDto = {
          name: values.name,
          country: values.country ? values.country : null,
        };
        const calendarId = await HolidayCalendarAPI.createHolidayCalendar(data);
        await refresh();

        setIsOpen(false);
        history.push(generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId }));
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setLoading(false);
    },
    [showMessage, polyglot, refresh, history, setIsOpen]
  );

  const formik = useFormik<CreateHolidayCalendarDto>({
    initialValues: {
      name: '',
      country: null,
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: createCalendar,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('HolidayCalendarModule.newCalendar')}</Typography>

        <TextfieldComponent
          label={polyglot.t('General.name')}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && (formik.errors.name as string)}
          fullWidth
        />

        <StyledRadioGroup
          options={[
            {
              label: polyglot.t('HolidayCalendarModule.createNewCalendar'),
              value: 'false',
            },
            {
              label: polyglot.t('HolidayCalendarModule.copyExisting'),
              value: 'true',
            },
          ]}
          selectedValue={copyCountry.toString()}
          labelPlacement="end"
          onChange={(e) => {
            const copy = e.target.value === 'true';
            if (!copy) formik.setFieldValue('country', null);
            setCopyCountry(copy);
          }}
          radioSx={{
            borderBottom: 0,
            justifyContent: 'normal',
            py: 0,
          }}
        />

        {copyCountry && (
          <Autocomplete
            options={countriesOptions}
            value={countriesOptions.find(({ value }) => value === formik.values.country)}
            getOptionLabel={(option) => option.label}
            onChange={(_, x) => formik.setFieldValue('country', x?.value)}
            renderOption={(props, option) => {
              return option.value === formik.values.country ? (
                <StyledMenuItem {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="caption">{option.label}</Typography>
                    <ChoseIcon {...iconSize} />
                  </Box>
                </StyledMenuItem>
              ) : (
                <StyledMenuItem {...props}>{option.label}</StyledMenuItem>
              );
            }}
            renderInput={(params) => (
              <StyledAutoTextfield
                {...params}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                placeholder={polyglot.t('HolidayCalendarModule.holidayCalendar')}
                size="small"
                label={polyglot.t('HolidayCalendarModule.copyFromSystem')}
                name="country"
                error={formik.touched.country && !!formik.errors.country}
                helperText={formik.touched.country && formik.errors.country}
              />
            )}
            PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
            popupIcon={<ArrowDownACIcon />}
            clearIcon={<ClearIcon />}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.create')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
