import { Fragment } from 'react';

import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  SURVEYS_COMPANY_ONGOING_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE,
} from '@/lib/routes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SCUpsertGeneralPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/sc-upsert-general.page';
import { SCUpsertLaunchPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.page';
import { SurveyCycleCreationParticipantsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/sc-upsert-participants.page';
import { SCUpsertTimelinePage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/sc-upsert-timeline.page';
import { SurveyCycleCreationNotificationsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/survey-cycle-creation-notifications.page';
import { SurveyCycleCreationQuestionPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/survey-cycle-creation-question.page';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const SURVEYS_ALL_SCOPE = 'surveys:all';

export const getPageConfig = (
  cycleId: string,
  surveyCycle: SurveyCycle | null | undefined,
  polyglot: Polyglot
): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('CycleSettingsRouter.general'),
          stub: 'general',
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE, { cycleId }),
        },
        {
          title: 'Questions',
          stub: 'question',
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE, { cycleId }),
        },
        {
          title: 'Participants',
          stub: 'participants',
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE, { cycleId }),
        },
        {
          title: 'Timeline',
          stub: 'timeline',
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE, { cycleId }),
        },
        {
          title: 'Notifications',
          stub: 'notifications',
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE, { cycleId }),
        },
        ...(surveyCycle?.state && [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle?.state)
          ? [
              {
                title: 'Start survey',
                stub: 'launch',
                path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE, { cycleId }),
              },
            ]
          : []),
      ],
    },
  ] as NavConfigItem[];
};

export const SurveyCycleCreationCompanyRouter = () => {
  const { polyglot } = usePolyglot();
  const params = useParams<{ cycleId: string }>();
  const cycleId = params.cycleId;
  const { data: surveyCycle, mutate: refreshReviewCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, ReachType.Company),
    {
      suspense: false,
    }
  );

  const refresh = async () => {
    await refreshReviewCycle?.();
  };

  return (
    <Fragment>
      <DomainSideMenuContent
        title="Surveys"
        subtitle={polyglot.t('OngoingSurveyList.surveys')}
        pageConfig={getPageConfig(cycleId, surveyCycle, polyglot)}
        backPath={generatePath(SURVEYS_COMPANY_ONGOING_ROUTE, {
          cycleId,
        })}
        showBack
        type="Domain"
      />
      <Switch>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE}>
          <SCUpsertGeneralPage
            surveyCycle={surveyCycle}
            refresh={refresh}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE}>
          <SurveyCycleCreationQuestionPage cycleId={cycleId} />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE}>
          <SurveyCycleCreationParticipantsPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE}>
          <SCUpsertTimelinePage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE}>
          <SurveyCycleCreationNotificationsPage surveyCycle={surveyCycle} refresh={refresh} />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE}>
          <SCUpsertLaunchPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <Redirect to={SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE} exact />
      </Switch>
    </Fragment>
  );
};
