import { Box, SxProps, Theme, Typography } from '@mui/material';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { PaymentDto, PaymentInstitutionDto, PaymentStatus } from '@v2/feature/payments/payments.dto';
import {
  DefaultCSVPayment,
  ExportCSVPaymentFormat,
  RevolutCSVPayment,
  StarlingCSVPayment,
  WiseCSVPayment,
  YapilyInstitutions,
} from '@v2/feature/payments/payments.interface';
import { UserBankAccountDto } from '@v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import { translatePaymentStatus } from '@v2/infrastructure/i18n/translate.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { capitalize } from 'lodash';
import Polyglot from 'node-polyglot';

import AIB from '@/images/payment/banks/aib.webp';
import Barclays from '@/images/payment/banks/barclays.webp';
import Hsbc from '@/images/payment/banks/hsbc.webp';
import Llyods from '@/images/payment/banks/llyods.webp';
import Modelo from '@/images/payment/banks/modelo.webp';
import Monzo from '@/images/payment/banks/monzo.webp';
import Natwest from '@/images/payment/banks/natwest.webp';
import NatwestBankline from '@/images/payment/banks/natwestBankline.webp';
import Revolut from '@/images/payment/banks/revolut.webp';
import RoyalBankScotland from '@/images/payment/banks/royalBankScotland.webp';
import Santanders from '@/images/payment/banks/santanders.webp';
import Starling from '@/images/payment/banks/starling.webp';
import Tide from '@/images/payment/banks/tide.webp';
import Wise from '@/images/payment/banks/wise.webp';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Question } from '@/images/side-bar-icons/Question.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { UserBasicsInfo } from '@/models';
import { COUNTRY_ISO_CODE_MAPPING, CustomCountryEnum } from '@/v2/infrastructure/country/country.interface';

export const HMRC_ACCOUNT = {
  accountName: 'HMRC Cumbernauld',
  accountNumber: '12001039',
  sortCode: '083210',
  currency: 'GBP',
  country: 'United Kingdom',
  firstName: 'HMRC',
  lastName: 'Cumbernauld',
};

export type PaymentUser = Pick<UserBasicsInfo, 'firstName' | 'lastName' | 'emailAddress'>;

export const getCodeForCountryName = (countryName: string | null | undefined) => {
  if (!countryName) return;
  return COUNTRY_ISO_CODE_MAPPING[countryName];
};

export const getCountryNameForCode = (countryCode: string | undefined) => {
  if (countryCode === CustomCountryEnum.code) return CustomCountryEnum.code;

  if (!countryCode) return '';
  if (/^gb$/i.test(countryCode)) countryCode = 'UK';
  countryCode = countryCode.toUpperCase();
  return Object.keys(COUNTRY_ISO_CODE_MAPPING).find((key) => COUNTRY_ISO_CODE_MAPPING[key] === countryCode);
};

const mapPaymentToDefaultCSVFormat = (
  payment: PaymentDto,
  payeeBankAccount: Pick<UserBankAccountDto, 'accountName' | 'accountNumber' | 'sortCode' | 'currency'> | null,
  payee: PaymentUser | undefined
): DefaultCSVPayment => {
  const firstName = payment.userId ? payee?.firstName ?? '' : HMRC_ACCOUNT.firstName;
  const lastName = payment.userId ? payee?.lastName ?? '' : HMRC_ACCOUNT.lastName;
  const beneficiary = lastName ? `${firstName} ${lastName}` : firstName;

  return {
    Beneficiary: beneficiary,
    AccountName: payeeBankAccount?.accountName ?? '',
    AccountNumber: payeeBankAccount?.accountNumber ?? '',
    SortCode: payeeBankAccount?.sortCode ?? '',
    Reference: payment.reference ?? '',
    Amount: payment.amount,
    Currency: payment.currency ?? payeeBankAccount?.currency ?? 'GPB',
    DueDate: payment.dueDate ? new Date(payment.dueDate).toLocaleDateString() : undefined,
  };
};

const mapPaymentToRevolutCSVFormat = (
  payment: PaymentDto,
  payeeBankAccount: Pick<
    UserBankAccountDto,
    'accountName' | 'accountNumber' | 'sortCode' | 'country' | 'currency'
  > | null,
  payee: PaymentUser | undefined
): RevolutCSVPayment => {
  const country = getCodeForCountryName(payeeBankAccount?.country) ?? '';
  return {
    Name: payee ? `${payee.firstName} ${payee.lastName}` : payeeBankAccount?.accountName ?? '',
    'Recipient type': payeeBankAccount?.accountNumber === HMRC_ACCOUNT.accountNumber ? 'Company' : 'Individual',
    'Account number': payeeBankAccount?.accountNumber ?? '',
    'Sort code': payeeBankAccount?.sortCode ?? '',
    'Recipient bank country': country === 'UK' ? 'GB' : country,
    Currency: payment.currency ?? payeeBankAccount?.currency ?? 'GPB',
    Amount: payment.amount,
    'Payment reference': payment.reference ?? '',
  };
};

const mapPaymentToStarlingCSVFormat = (
  payment: PaymentDto,
  payeeBankAccount: Pick<UserBankAccountDto, 'accountName' | 'accountNumber' | 'sortCode' | 'country'> | null,
  payee: PaymentUser | undefined,
  companyName: string
): StarlingCSVPayment => {
  const firstName = payment.userId ? payee?.firstName ?? '' : ''; // HMRC_ACCOUNT.firstName; <-- set in companyName already, not needed here
  const lastName = payment.userId ? payee?.lastName ?? '' : ''; // HMRC_ACCOUNT.lastName; <-- set in companyName already, not needed here

  return {
    'Sort Code': payeeBankAccount?.sortCode ?? '',
    'Account Number': payeeBankAccount?.accountNumber ?? '',
    'First Name': firstName,
    'Last Name': lastName,
    'Business Name': companyName ?? '',
    Reference: payment.reference ?? '',
    'Amount (GBP)': payment.amount,
  };
};

const mapPaymentToWiseCSVFormat = (
  payment: PaymentDto,
  payeeBankAccount: Pick<
    UserBankAccountDto,
    'accountName' | 'accountNumber' | 'sortCode' | 'country' | 'currency'
  > | null,
  payee: PaymentUser | undefined
): WiseCSVPayment => {
  const firstName = payment.userId ? payee?.firstName ?? '' : HMRC_ACCOUNT.firstName;
  const lastName = payment.userId ? payee?.lastName ?? '' : HMRC_ACCOUNT.lastName;

  const name = lastName ? `${firstName} ${lastName}` : firstName;
  const recipientEmail = payment.userId && payee ? payee.emailAddress : '';
  const receiverType = payment.userId ? 'PERSON' : '';

  return {
    name,
    recipientEmail,
    paymentReference: payment.reference ?? '',
    receiverType,
    amountCurrency: payment.currency ?? payeeBankAccount?.currency ?? 'GBP',
    amount: payment.amount,
    sourceCurrency: payment.currency ?? 'GBP',
    targetCurrency: payment.currency ?? 'GBP',
    sortCode: payeeBankAccount?.sortCode ?? '',
    accountNumber: payeeBankAccount?.accountNumber ?? '',
    IBAN: '',
  };
};

export const mapPaymentToCSVFormatByInstitution = (
  institutionId: string,
  payment: PaymentDto,
  payeeBankAccount: Pick<
    UserBankAccountDto,
    'accountName' | 'accountNumber' | 'sortCode' | 'country' | 'currency'
  > | null,
  payee: PaymentUser | undefined,
  companyName: string
): ExportCSVPaymentFormat => {
  switch (institutionId) {
    case YapilyInstitutions.Revolut:
      return mapPaymentToRevolutCSVFormat(payment, payeeBankAccount, payee);
    case YapilyInstitutions.Starling:
      return mapPaymentToStarlingCSVFormat(payment, payeeBankAccount, payee, companyName);
    case YapilyInstitutions.Wise:
      return mapPaymentToWiseCSVFormat(payment, payeeBankAccount, payee);
    default:
      return mapPaymentToDefaultCSVFormat(payment, payeeBankAccount, payee);
  }
};

export const yappilyInstitutionToLogo = (institution: PaymentInstitutionDto) => {
  const name = institution.id;
  switch (name) {
    case YapilyInstitutions.AIBROIBusiness:
      return <img src={AIB ?? institution?.media[0]?.source} width={40} height={40} alt="AIB ROI Business" />;
    case YapilyInstitutions.BarclaysBusiness:
      return <img src={Barclays ?? institution?.media[0]?.source} width={40} height={40} alt="Barclays Business" />;
    case YapilyInstitutions.BarclaysCorporate:
      return <img src={Barclays ?? institution?.media[0]?.source} width={40} height={40} alt="Barclays Corporate" />;
    case YapilyInstitutions.HSBCBusiness:
      return <img src={Hsbc ?? institution?.media[0]?.source} width={40} height={40} alt="HSBC Business UK" />;
    case YapilyInstitutions.HSBCNet:
      return <img src={Hsbc ?? institution?.media[0]?.source} width={40} height={40} alt="HSBC Net" />;
    case YapilyInstitutions.LloydsBusiness:
      return <img src={Llyods ?? institution?.media[0]?.source} width={40} height={40} alt="Lloyds Business" />;
    case YapilyInstitutions.ModeloSandbox:
      return <img src={Modelo ?? institution?.media[0]?.source} width={40} height={40} alt="Modelo Sandbox" />;
    case YapilyInstitutions.Monzo:
      return <img src={Monzo ?? institution?.media[0]?.source} width={40} height={40} alt="Monzo" />;
    case YapilyInstitutions.Natwest:
      return <img src={Natwest ?? institution?.media[0]?.source} width={40} height={40} alt="Natwest" />;
    case YapilyInstitutions.NatwestBusiness:
      return (
        <img src={NatwestBankline ?? institution?.media[0]?.source} width={40} height={40} alt="Natwest Business" />
      );
    case YapilyInstitutions.RBS:
      return (
        <img
          src={RoyalBankScotland ?? institution?.media[0]?.source}
          width={40}
          height={40}
          alt="Royal Bank of Scotland"
        />
      );
    case YapilyInstitutions.RBSBusiness:
      return (
        <img
          src={RoyalBankScotland ?? institution?.media[0]?.source}
          width={40}
          height={40}
          alt="Royal Bank of Scotland Bankline"
        />
      );
    case YapilyInstitutions.Revolut:
      return <img src={Revolut ?? institution?.media[0]?.source} width={40} height={40} alt="Revolut" />;
    case YapilyInstitutions.SantanderUk:
      return <img src={Santanders ?? institution?.media[0]?.source} width={40} height={40} alt="Santander UK" />;
    case YapilyInstitutions.Starling:
      return <img src={Starling ?? institution?.media[0]?.source} width={40} height={40} alt="Starling" />;
    case YapilyInstitutions.Tide:
      return <img src={Tide ?? institution?.media[0]?.source} width={40} height={40} alt="Tide" />;
    case YapilyInstitutions.Wise:
      return <img src={Wise ?? institution?.media[0]?.source} width={40} height={40} alt="Wise" />;
    default:
      return <img src={institution?.media[0]?.source} width={40} height={40} alt="New-Bank" />;
  }
};

export const BankingHoursBanks: YapilyInstitutions[] = [];

export const timeOptions = [
  { value: '00:00', label: '00:00' },
  { value: '00:30', label: '00:30' },
  { value: '01:00', label: '01:00' },
  { value: '01:30', label: '01:30' },
  { value: '02:00', label: '02:00' },
  { value: '02:30', label: '02:30' },
  { value: '03:00', label: '03:00' },
  { value: '03:30', label: '03:30' },
  { value: '04:00', label: '04:00' },
  { value: '04:30', label: '04:30' },
  { value: '05:00', label: '05:00' },
  { value: '05:30', label: '05:30' },
  { value: '06:00', label: '06:00' },
  { value: '06:30', label: '06:30' },
  { value: '07:00', label: '07:00' },
  { value: '07:30', label: '07:30' },
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
];

export const allPaymentColumns = [
  { name: 'Select', value: 'select' },
  { name: 'Beneficiary', value: 'userId' },
  { name: 'Type', value: 'category' },
  { name: 'Amount', value: 'amount' },
  // { name: 'Bank name', value: 'bankName' },
  // { name: 'Account name', value: 'accountName' },
  // { name: 'Account number', value: 'accountNumber' },
  // { name: 'Sort code', value: 'sortCode' },
  { name: 'Reference', value: 'reference' },
  { name: 'Due Date', value: 'dueDate' },
  { name: 'Payment Date', value: 'createdAt' },
  { name: 'Status', value: 'status' },
  { name: 'Actions', value: 'actions' },
];

export function transactionTypeToLabel(type: string): string {
  return type.toLowerCase().split('_').map(capitalize).join(' ');
}

export const paymentPaidOrMarkedPaid = (payment: PaymentDto): boolean => {
  return payment.markPaid || payment.status === PaymentStatus.Paid;
};

export const getPaymentStatus = (
  polyglot: Polyglot,
  payment: PaymentDto,
  statusSx: SxProps<Theme>,
  includeTooltip = false
) => {
  if (payment.markPaid)
    return (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
        <Typography sx={{ ...statusSx }}>{polyglot.t('PaymentStatus.markedaspaid')}</Typography>
      </Box>
    );

  switch (payment.status) {
    case PaymentStatus.Processing:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Waiting {...iconSize} style={{ fill: themeColors.middleGrey }} />
          <Typography sx={{ color: themeColors.Grey, ...statusSx }}>
            {translatePaymentStatus(payment.status, polyglot)}
          </Typography>
        </Box>
      );
    case PaymentStatus.Unknown:
      return includeTooltip ? (
        <StyledTooltip title="Exited the flow or authorisation failed. Check with your bank before retrying or mark as paid.">
          <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', cursor: 'pointer' }}>
            <Question {...iconSize} style={{ fill: themeColors.orange }} />{' '}
            <Typography sx={{ color: themeColors.Grey, ...statusSx }}>
              {translatePaymentStatus(payment.status, polyglot)}
            </Typography>
          </Box>
        </StyledTooltip>
      ) : (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Question {...iconSize} style={{ fill: themeColors.orange }} />{' '}
          <Typography sx={{ color: themeColors.Grey, ...statusSx }}>
            {translatePaymentStatus(payment.status, polyglot)}
          </Typography>
        </Box>
      );
    case PaymentStatus.Paid:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          <Typography sx={statusSx}>{translatePaymentStatus(payment.status, polyglot)}</Typography>
        </Box>
      );
    case PaymentStatus.Failed:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Rejected {...iconSize} fill={themeColors.Red} />
          <Typography sx={statusSx}>{translatePaymentStatus(payment.status, polyglot)}</Typography>
        </Box>
      );
    case PaymentStatus.NotPaid:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Waiting {...iconSize} style={{ fill: themeColors.middleGrey }} />
          <Typography sx={{ color: themeColors.Grey, ...statusSx }}>
            {translatePaymentStatus(payment.status, polyglot)}
          </Typography>
        </Box>
      );
    default:
      return <></>;
  }
};
