import {
  SURVEYS_COMPANY_ONGOING_ROUTE,
  SURVEYS_TEAM_ONGOING_ROUTE,
  SURVEYS_ME_ROUTE,
  SURVEYS_ME_INVITE_ROUTE,
  SURVEYS_ME_RESULT_ROUTE,
} from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const GROWTH_SURVEYS_ONGOING_ROUTES: RoleRoutes = {
  company: SURVEYS_COMPANY_ONGOING_ROUTE,
  team: SURVEYS_TEAM_ONGOING_ROUTE,
};

export const GROWTH_SURVEYS_ME_ROUTES: RoleRoutes = {
  me: SURVEYS_ME_ROUTE,
};

export const GROWTH_SURVEYS_INVITE_ROUTES: RoleRoutes = {
  me: SURVEYS_ME_INVITE_ROUTE,
};

export const GROWTH_SURVEYS_INVITE_SCOPES: RoleScopes = {
  me: ['surveys'],
};

export const GROWTH_SURVEYS_RESULT_ROUTES: RoleRoutes = {
  me: SURVEYS_ME_RESULT_ROUTE,
};

export const GROWTH_SURVEYS_RESULT_SCOPES: RoleScopes = {
  me: ['surveys'],
};

export const GROWTH_SURVEYS_ONGOING_SCOPES: RoleScopes = {
  company: ['surveys:all'] as Scope[],
  team: ['surveys:manager'],
  me: ['surveys'],
};
