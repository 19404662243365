export type RequiredEnrolmentType = 'allDevices' | 'onlyCompany';

export type DeviceSettings = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  companyId: number;
  requiredEnrolment: RequiredEnrolmentType;
  hiddenDeviceModels?: number[] | null;
};

export enum RequiredEnrolmentValueEnum {
  AllDevices = 'allDevices',
  OnlyCompany = 'onlyCompany',
}

export enum RequiredEnrolmentLabelEnum {
  allDevices = 'All devices',
  onlyCompany = 'Only company',
}
