import { useContext, useEffect } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { Typography } from '@v2/components/typography/typography.component';
import { FormikProvider, useFormik } from 'formik';

import { GlobalContext } from '@/GlobalState';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  DevicePolicyDto,
  ZeltManagedApp,
  ZeltManagedApps,
} from '@/v2/feature/device/device.dto';
import { DevicePolicyZeltType } from '@/v2/feature/device/device.interface';

export const ZeltMdmAppsPolicyEditor = ({
  companyPolicies,
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  companyPolicies: DevicePolicyDto[];
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [state] = useContext(GlobalContext);
  const initialValues: ZeltManagedApps = {
    apps: companyPolicies
      .filter((element) => element.type === DevicePolicyZeltType.apps && element.isInhouseMdm)
      .filter((element) => !(element.externalId === 9 && state.user.company.companyId !== 618))
      .filter((element) => !(element.externalId === 18 && state.user.company.companyId !== 877))
      .map((app) => {
        const zeltManagedApp: ZeltManagedApp = {
          id: app.id,
          selected: configurablePoliciesSelected?.configurableApps?.includes(app.id) ?? false,
          name: app.name,
          externalId: app.externalId,
        };
        return zeltManagedApp;
      }),
  };

  const formik = useFormik<ZeltManagedApps>({
    initialValues,
    onSubmit: async (selectedApps: ZeltManagedApps) => {
      let appsToSave: number[] = [];
      selectedApps.apps.filter((app) => app.selected).map((app) => appsToSave.push(app.id));
      if (appsToSave.length === 0) {
        appsToSave.push(0);
      }
      const configurablePoliciesDto: ConfigurablePoliciesDto = {
        ...configurablePoliciesSelected,
        configurableApps: appsToSave,
      };
      await updatePolicies(configurablePoliciesDto);
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Typography variant="title2">Managed apps</Typography>

      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
          {formik.values.apps.map((app, index) => (
            <CheckboxComponent
              key={`${index}${app.id}`}
              label={app.name}
              name={`app-${index}-${app.id}`}
              checked={app.selected}
              onChange={(e) => {
                const inputElement = e.target as HTMLInputElement;
                const checkboxName = inputElement.name;
                const isChecked = inputElement.checked;
                const [, checkboxIndex, appId] = checkboxName.match(/app-(\d+)-(\d+)/) || [];
                const indexValue = parseInt(checkboxIndex, 10);
                parseInt(appId, 10);
                const updatedApps = [...formik.values.apps];
                updatedApps[indexValue].selected = isChecked;
                formik.setValues({
                  ...formik.values,
                  apps: updatedApps,
                });
              }}
            />
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: '30px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
