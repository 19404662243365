import { Box, Typography } from '@mui/material';
import { isValid } from 'date-fns';

import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { iconSize } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { UserImportDto } from '@/v2/feature/user/features/user-import/user-import.dto';
import { UserImportKeyType } from '@/v2/feature/user/user.interface';
import { ValidationError } from '@/v2/infrastructure/api-error/api-error.interface';
import { formatShortDate } from '@/v2/infrastructure/date/date-format.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { pluralText } from '@/v2/util/string.util';

type Props = {
  rowData?: UserImportDto;
  rowErrors: ValidationError[] | undefined;
  fieldKey: UserImportKeyType;
  rawCellValue?: string;
  statusColumn?: number;
};

export const ImportedUserTableCell = ({ rowData, fieldKey, rowErrors, rawCellValue, statusColumn }: Props) => {
  const getErrorTextForTooltip = (errorObj: ValidationError) => {
    return Object.keys(errorObj.constraints)
      .map((eachKey) => errorObj.constraints[eachKey])
      .join(', ');
  };

  const errorForColumn = rowErrors?.find((e) => e.property === fieldKey);

  const getStatus = (): JSX.Element => {
    if (statusColumn === 0) {
      return (
        <>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          &nbsp; Valid
        </>
      );
    } else if (statusColumn && statusColumn > 0) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <MistakeIcon {...iconSize} />
          &nbsp; {statusColumn} issue{pluralText(statusColumn)}
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const getValueForCell = () => {
    const FIELDS_FOR_COUNTRY_LOOKUP: Partial<keyof UserImportDto>[] = [
      'entity',
      'holidayCalendar',
      'nationality',
      'country',
    ];
    if (!rowData && rawCellValue && rawCellValue.length > 0) return rawCellValue;
    else if (
      rowData &&
      !rawCellValue &&
      Object.keys(rowData)?.length > 0 &&
      ((typeof rowData[fieldKey] === 'string' && rowData[fieldKey].length > 0) ||
        (typeof rowData[fieldKey] === 'number' && rowData[fieldKey] >= 0))
    ) {
      return FIELDS_FOR_COUNTRY_LOOKUP.includes(fieldKey)
        ? getCountryNameForCode(rowData[fieldKey])
        : rowData[fieldKey];
    } else if (
      rowData &&
      !rawCellValue &&
      Object.keys(rowData)?.length > 0 &&
      typeof rowData[fieldKey] === 'object' &&
      isValid(rowData[fieldKey])
    ) {
      return formatShortDate(rowData[fieldKey]);
    } else if (rowData && !rawCellValue && Object.keys(rowData)?.length > 0 && typeof rowData[fieldKey] === 'boolean') {
      return rowData[fieldKey] === true ? 'Yes' : 'No';
    }
    return <EmptyCell />;
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mr: spacing.mr10 }}>
        {statusColumn === 0 || (statusColumn && statusColumn >= 0) ? getStatus() : getValueForCell()}
      </Typography>
      {errorForColumn && (
        <StyledTooltip title={getErrorTextForTooltip(errorForColumn)}>
          <MistakeIcon {...iconSize} />
        </StyledTooltip>
      )}
    </Box>
  );
};
