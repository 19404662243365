import { useCallback, useMemo } from 'react';

import { Redirect, Route, Switch, generatePath, useParams } from 'react-router-dom';

import {
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE,
  SETTINGS_MONEY_SALARY_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE,
} from '@/lib/routes';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { GlobalPayrollPayCodes } from '@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes.page';
import { PayrollCompanySettingsAccounting } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-accounting.page';
import { PayrollCompanySettingsGeneral } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-general.page';
import { PayrollCompanySettingsHMRC } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-hmrc.page';
import { PayrollCompanySettingsIntegrations } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-integrations.page';
import { PayrollCompanySettingsPayCodes } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-paycodes.page';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

const PageConfigUKPayroll = (payrollId: number): NavConfigItem[] => [
  {
    title: '',
    stub: '',
    isHidden: false,
    hasChildren: true,
    subItems: [
      {
        title: 'General',
        stub: 'general',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
      {
        title: 'HMRC',
        stub: 'allowance',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
      {
        title: 'Accounting',
        stub: 'accounting',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
      {
        title: 'Pay codes',
        stub: 'pay-codes',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
    ],
  },
];

const PageConfigGlobalPayroll = (payrollId: number): NavConfigItem[] => [
  {
    title: '',
    stub: '',
    isHidden: false,
    hasChildren: true,
    subItems: [
      {
        title: 'General',
        stub: 'general',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
      {
        title: 'Pay codes',
        stub: 'pay-codes',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
      {
        title: 'Integrations',
        stub: 'integrations',
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false,
      },
    ],
  },
];

export const PayrollCompanySettingsRouter = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const payrollId = Number(useParams<{ payrollId: string }>().payrollId);

  const { data: payrolls, mutate: refreshPayrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), {
    suspense: false,
  });

  const payroll = useMemo(() => payrolls?.items.find((p) => p.id === payrollId), [payrollId, payrolls?.items]);

  const refreshPayroll = useCallback(async () => {
    await refreshPayrolls?.();
  }, [refreshPayrolls]);

  if (!payroll) {
    return <></>;
  }

  return (
    <>
      <DomainSideMenuContent
        title={payroll.displayName}
        subtitle={
          payroll.employer ? polyglot.t('CompanyPayroll.ukPayroll') : polyglot.t('CompanyPayroll.globalPayroll')
        }
        pageConfig={payroll.employer ? PageConfigUKPayroll(payroll.id) : PageConfigGlobalPayroll(payroll.id)}
        backPath={SETTINGS_MONEY_SALARY_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper>
        <Switch>
          <Route path={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE} exact>
            <PayrollCompanySettingsGeneral payroll={payroll} refreshPayroll={refreshPayroll} />
          </Route>
          <Route path={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE} exact>
            <PayrollCompanySettingsHMRC payroll={payroll} refreshPayroll={refreshPayroll} />
          </Route>
          <Route path={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE} exact>
            <PayrollCompanySettingsAccounting payroll={payroll} />
          </Route>
          <Route path={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE} exact>
            {payroll.employer && <PayrollCompanySettingsPayCodes payroll={payroll} />}
            {!payroll.employer && <GlobalPayrollPayCodes payroll={payroll} />}
          </Route>
          <Route path={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE} exact>
            <PayrollCompanySettingsIntegrations />
          </Route>
          <Redirect to={SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE} />
        </Switch>
      </ContentWrapper>
    </>
  );
};
