import { Fragment, useContext } from 'react';

import { ActivationLockZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/activation-lock-zelt-mdm.page';
import { AppsZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/apps-zelt-mdm.page';
import { OsUpdatesMobileZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/os-updates-mobile-zelt-mdm.page';
import { PasscodeMobileZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/passcode-mobile-zelt-mdm.page';
import { RestrictionsMobileZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/restrictions-mobile-zelt-mdm.page';
import { WifiMobileZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/wifi-mobile-zelt-mdm.page';
import { EncryptionZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/encryption-zelt-mdm.page';
import { FirewallZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/firewall-zelt-mdm.page';
import { OsUpdatesZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/os-updates-zelt-mdm.page';
import { PasscodeZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/passcode-zelt-mdm.page';
import { RestrictionsZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/restrictions-zelt-mdm.page';
import { ScreenSettingsZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/screen-settings-zelt-mdm.page';
import { WifiZeltMdmPage } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/wifi-zelt-mdm.page';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  SETTINGS_DEVICES_GENERAL_ROUTE,
  SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE,
  SETTINGS_DEVICES_ZELT_APPS_ROUTE,
  SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE,
  SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE,
  SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE,
  SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE,
  SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE,
  SETTINGS_DEVICES_ZELT_ROUTE,
  SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE,
  SETTINGS_DEVICES_ZELT_WIFI_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { CurrentUser, ScopeContext } from '@/models';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';

export const getAppleSettingsPageConfig = (
  polyglot: any,
  currentUser: CurrentUser,
  context: ScopeContext
): readonly NavConfigItem[] => {
  let menu = [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'Passcode',
          stub: 'passcode',
          path: SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Encryption',
          stub: 'encryption',
          path: SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Firewall',
          stub: 'firewall',
          path: SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Apps',
          stub: 'apps',
          path: SETTINGS_DEVICES_ZELT_APPS_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Screen',
          stub: 'screenSettings',
          path: SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Restrictions',
          stub: 'restrictions',
          path: SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'WiFi',
          stub: 'wifi',
          path: SETTINGS_DEVICES_ZELT_WIFI_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'OS updates',
          stub: 'os_updates',
          path: SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Activation lock',
          stub: 'activation_lock',
          path: SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
      ],
    },
  ];

  return menu as NavConfigItem[];
};

export const getMobileDevicesAppleSettingsPageConfig = (
  polyglot: any,
  currentUser: CurrentUser,
  context: ScopeContext
): readonly NavConfigItem[] => {
  let menu = [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'Passcode',
          stub: 'passcode',
          path: SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'Restrictions',
          stub: 'restrictions',
          path: SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'WiFi',
          stub: 'wifi',
          path: SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
        {
          title: 'OS updates',
          stub: 'os_updates',
          path: SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE,
          isHidden: !checkScopes(currentUser, ['user:read:all'], context),
          hasChildren: false,
        },
      ],
    },
  ];

  return menu as NavConfigItem[];
};
export const ZeltMdmRouter = () => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const currentUser = state.user;

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return (
    <Fragment>
      <DomainSideMenuContent
        title="MacOS"
        pageConfig={getAppleSettingsPageConfig(polyglot, currentUser, scopesContext)}
        backPath={SETTINGS_DEVICES_GENERAL_ROUTE}
        showBack
        type="Custom"
      />
      <Switch>
        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE}
        >
          <PasscodeZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE}
        >
          <EncryptionZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE}
        >
          <FirewallZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_APPS_ROUTE}
        >
          <AppsZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE}
        >
          <ScreenSettingsZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE}
        >
          <RestrictionsZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_WIFI_ROUTE}
        >
          <WifiZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE}
        >
          <OsUpdatesZeltMdmPage />
        </RouteScopesControl>

        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE}
        >
          <ActivationLockZeltMdmPage />
        </RouteScopesControl>
        <Redirect from={SETTINGS_DEVICES_ZELT_ROUTE} to={SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE} />
      </Switch>
    </Fragment>
  );
};

export const ZeltMobileMdmRouter = () => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const currentUser = state.user;

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return (
    <Fragment>
      <DomainSideMenuContent
        title="iOS and iPadOS"
        pageConfig={getMobileDevicesAppleSettingsPageConfig(polyglot, currentUser, scopesContext)}
        backPath={SETTINGS_DEVICES_GENERAL_ROUTE}
        showBack
        type="Custom"
      />
      <Switch>
        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE}
        >
          <PasscodeMobileZeltMdmPage />
        </RouteScopesControl>
        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE}
        >
          <RestrictionsMobileZeltMdmPage />
        </RouteScopesControl>
        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE}
        >
          <WifiMobileZeltMdmPage />
        </RouteScopesControl>
        <RouteScopesControl
          context={scopesContext}
          exact
          scopes={DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS}
          path={SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE}
        >
          <OsUpdatesMobileZeltMdmPage />
        </RouteScopesControl>
        <Redirect from={SETTINGS_MOBILE_DEVICES_ZELT_ROUTE} to={SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE} />
      </Switch>
    </Fragment>
  );
};
