import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { DOCUMENTS_TEAM_ROUTE } from '@/lib/routes';
import { DocumentsCompanyPage } from '@/v2/feature/documents/documents-company.page';

export const DocumentsTeamRouter = (): JSX.Element => (
  <Switch>
    <Route path={DOCUMENTS_TEAM_ROUTE} exact>
      <DocumentsCompanyPage reach="team" />
    </Route>
  </Switch>
);
