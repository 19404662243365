import { TaskDto } from '@v2/feature/task/task.dto';
import { Dayjs } from 'dayjs';

export interface TaskStatus {
  value: string;
  label: string;
}

export enum TaskStatuses {
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Complete',
}

export enum TaskOnboardState {
  TASKS = 'tasks',
}

export interface TaskFormBody
  extends Omit<
    TaskDto,
    'id' | 'companyId' | 'createdBy' | 'assignedUser' | 'requestedFor' | 'assignedUserId' | 'requestedForId' | 'dueDate'
  > {
  id?: number | null;
  assignedUserId: number | null;
  requestedForId?: number | null;
  dueDate: string | Date | null | Dayjs;
}

export enum TaskTabFilter {
  InSevenDays = '7',
  InThirtyDays = '30',
  All = 'all',
  Done = 'done',
}

export enum ActionItemsTabFilter {
  InSevenDays = '7',
  InThirtyDays = '30',
  Pending = 'pending',
  Approved = 'approved',
}
