import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { getDateString } from '@/v2/components/forms/date-label.component';
import { SplitSwitchButton } from '@/v2/components/split-switch-button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortDate } from '@/v2/components/table/table-sorting.util';
import { Typography } from '@/v2/components/typography/typography.component';
import { convertMinutesToHHMM } from '@/v2/feature/absence/absence.util';
import {
  AttendanceDto,
  AttendanceScheduleDto,
  PersonalWeeklyEntries,
  PersonalWeeklyEntriesTableAndHeaderData,
  ScheduleAndAbsenceDataType,
  WeeklyHeaderData,
} from '@/v2/feature/attendance/attendance.dto';
import { AttendanceStatus, ScheduleTrackingType } from '@/v2/feature/attendance/attendance.interface';
import { getAttendanceStatusIcon } from '@/v2/feature/attendance/attendance.util';
import { AttendanceEmptyState } from '@/v2/feature/attendance/company/components/empty-state/attendance-empty-state.component';
import { TrackTimeDrawer } from '@/v2/feature/attendance/company/components/track-time-drawer.component';
import { SubmitWeekRequestsButtonComponent } from '@/v2/feature/attendance/components/submit-week-requests-button.component';
import { AttendanceUserWeekCalendar } from '@/v2/feature/attendance/me/components/attendance-user-week-calendar.component';
import { AttendanceWeeklyHeaderLoader } from '@/v2/feature/attendance/me/components/attendance-weekly-header-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const AttendanceMeWeeklyEntries = ({
  weeklyEntries,
  userLoading,
  refresh,
  loading,
  weekList,
  refreshWeekList,
  userId,
}: {
  weeklyEntries: PersonalWeeklyEntriesTableAndHeaderData | undefined;
  userLoading: boolean;
  refresh: (weekAndYear: string) => Promise<void>;
  loading: boolean;
  weekList: {
    id: string;
    name: string;
    details: string;
  }[];
  refreshWeekList: () => Promise<void>;
  userId: number;
}) => {
  const { data: userAttendanceSchedule } = useApiClient<AttendanceScheduleDto, Error>(
    AttendanceEndpoints.getUserAttendanceSchedule(userId),
    { suspense: false }
  );

  const { polyglot } = usePolyglot();
  const [dataView, setDataView] = useState<'list' | 'column'>('list');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<PersonalWeeklyEntries | undefined>(undefined);

  const generateDynamicColumns = (
    data: PersonalWeeklyEntries[]
  ): ColumnDef<PersonalWeeklyEntries, PersonalWeeklyEntries>[] => {
    const uniqueNames = new Set<string>();

    data.forEach((userData) => {
      Object.keys(userData.loggedHoursByType).forEach((key) => {
        uniqueNames.add(key);
      });
    });

    return Array.from(uniqueNames).map((name) => ({
      id: name,
      header: () => name,
      accessorFn: (row: Row<PersonalWeeklyEntries>) => row,
      enableSorting: false,
      cell: ({ row: { original } }: { row: Row<PersonalWeeklyEntries> }) => {
        const value = original.loggedHoursByType[name];

        return (
          <>
            {value !== undefined ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                <div>{convertMinutesToHHMM(value)}</div>
              </div>
            ) : (
              <EmptyCell />
            )}
          </>
        );
      },
      maxSize: 120,
      minSize: 100,
    }));
  };

  const columns = useMemo<ColumnDef<PersonalWeeklyEntries, PersonalWeeklyEntries>[]>(() => {
    const dateColumns: ColumnDef<PersonalWeeklyEntries, PersonalWeeklyEntries>[] = [
      {
        id: 'logDate',
        header: () => polyglot.t('AttendanceDomain.date'),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.logDate),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        cell: ({ row: { original } }) => (
          <div>{original.logDate ? getDateString(original.logDate) : <EmptyCell />}</div>
        ),
      },
      {
        header: () => polyglot.t('AttendanceDomain.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return <>{original.status ? getAttendanceStatusIcon(original.status, false, polyglot) : <EmptyCell />}</>;
        },
        maxSize: 120,
        minSize: 100,
      },
    ];
    const staticColumns: ColumnDef<PersonalWeeklyEntries, PersonalWeeklyEntries>[] = [
      {
        header: () => polyglot.t('AttendanceDomain.notes'),
        accessorFn: (row) => row,
        id: 'notes',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return <div>{original.notes ? original.notes : <EmptyCell />}</div>;
        },
        maxSize: 120,
        minSize: 100,
      },
    ];
    return weeklyEntries
      ? [...dateColumns, ...generateDynamicColumns(weeklyEntries?.data), ...staticColumns]
      : [...dateColumns, ...staticColumns];
  }, [polyglot, weeklyEntries]);

  const handleRowClick = useCallback(({ original }: Row<PersonalWeeklyEntries>) => {
    setSelectedRow(original);
    setIsOpen(true);
  }, []);

  const weekStatuses = useMemo(() => {
    return weeklyEntries && weeklyEntries.data && weeklyEntries.data.map((d) => d.status).filter((a) => a !== null);
  }, [weeklyEntries]);

  const { data: attendanceRequests, mutate: refreshRequests } = useApiClient<AttendanceDto[], Error>(
    // TODO: should get by week here
    AttendanceEndpoints.getUserAttendanceRequests(userId),
    { suspense: false }
  );

  const refreshWeekAttendances = useCallback(async () => {
    if (refreshRequests) refreshRequests();
  }, [refreshRequests]);

  const currentWeekRequest = useMemo(() => {
    return (
      attendanceRequests?.filter(
        (r) => r.weekNo === weeklyEntries?.header?.weekNo && r.year === weeklyEntries?.header?.year
      ) ?? []
    );
  }, [attendanceRequests, weeklyEntries?.header?.weekNo, weeklyEntries?.header?.year]);

  return (
    <Box sx={spacing.mt20}>
      {!loading && weekList && weekList.length === 0 ? (
        <AttendanceEmptyState />
      ) : (
        <>
          <Box sx={spacing.pl20}>
            <AttendanceWeeklyHeader
              headerData={weeklyEntries?.header}
              listViewAction={() => setDataView('list')}
              columnViewAction={() => setDataView('column')}
              dataView={dataView}
              userId={userId}
              weekStatuses={weekStatuses}
              loading={userLoading}
              refreshWeekList={refreshWeekList}
            />
          </Box>
          <Box sx={spacing.pl20}>
            <Box sx={spacing.mt20}>
              {dataView === 'list' ? (
                <BasicTable
                  rowData={weeklyEntries?.data ? [...weeklyEntries?.data] : []}
                  columnData={columns}
                  hidePagination={true}
                  loading={userLoading}
                  rowClick={handleRowClick}
                />
              ) : (
                weeklyEntries?.header && (
                  <AttendanceUserWeekCalendar
                    userId={userId}
                    year={weeklyEntries?.header?.year}
                    weekNo={weeklyEntries?.header?.weekNo}
                    userAttendanceSchedule={userAttendanceSchedule}
                    weekAttendances={currentWeekRequest}
                    refreshWeekAttendances={refreshWeekAttendances}
                  />
                )
              )}
            </Box>
          </Box>
        </>
      )}
      <TrackTimeDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        userId={selectedRow?.userId ?? userId}
        attendanceId={selectedRow?.id}
        refresh={async () => {
          await refresh(`${weeklyEntries?.header.weekNo}/${weeklyEntries?.header.year}`);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        afterClose={() => {
          setSelectedRow(undefined);
        }}
        mode="view"
        view="user"
        logDate={selectedRow?.logDate ?? new LocalDate().toDateString()}
        weekAttendance={currentWeekRequest}
      />
    </Box>
  );
};

const AttendanceWeeklyHeader = ({
  headerData,
  listViewAction,
  columnViewAction,
  dataView,
  userId,
  weekStatuses,
  loading,
  refreshWeekList,
}: {
  headerData: WeeklyHeaderData | undefined;
  listViewAction: () => Promise<void> | void;
  columnViewAction: () => Promise<void> | void;
  dataView: 'list' | 'column';
  userId: number;
  weekStatuses: AttendanceStatus[] | undefined;
  loading: boolean;
  refreshWeekList: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  return loading ? (
    <AttendanceWeeklyHeaderLoader />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
      {headerData && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g20 }}>
            <Typography variant="title2">
              {polyglot.t('UserAttendancePage.weekNum', { weekNum: headerData.weekNo })}
            </Typography>
            <Typography variant="title2">{headerData.name}</Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <SplitSwitchButton
              listViewAction={listViewAction}
              columnViewAction={columnViewAction}
              listActive={dataView === 'list'}
              columnActive={dataView === 'column'}
            />

            <SubmitWeekRequestsButtonComponent
              userId={userId}
              year={headerData.year}
              weekNo={headerData.weekNo}
              weekStatuses={weekStatuses ?? []}
              refreshRequests={async () => await refreshWeekList()}
              isClockInSchedule={Boolean(
                headerData && headerData.trackingType === ScheduleTrackingType.ClockInClockOut
              )}
              trackingType={headerData?.trackingType}
            />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: spacing.g5,
          width: '100%',
        }}
      >
        {headerData && headerData.scheduleAndAbsenceData.map((s, i) => <ScheduleTitle key={i} scheduleDetail={s} />)}
      </Box>
    </Box>
  );
};

const ScheduleTitle = ({ scheduleDetail }: { scheduleDetail: ScheduleAndAbsenceDataType }) => {
  const { polyglot } = usePolyglot();

  return (
    <Box
      sx={{
        height: '95px',
        background: scheduleDetail.blockedByAbsence
          ? scheduleDetail?.absence[0]?.policy?.color ?? themeColors.Background
          : themeColors.Background,
        borderRadius: radius.br8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <div style={{ padding: '15px 10px 15px 10px' }}>
        <Typography
          variant="title4"
          sx={{
            color:
              scheduleDetail.isWorking || scheduleDetail.blockedByAbsence ? themeColors.DarkGrey : themeColors.Grey,
            textAlign: 'center',
          }}
        >
          {new LocalDate(scheduleDetail.currentDate).toLocaleDateString(undefined, {
            day: 'numeric',
            weekday: 'short',
          })}
        </Typography>

        {scheduleDetail.blockedByAbsence ? (
          <>
            <Typography
              variant="caption"
              sx={{
                color: themeColors.DarkGrey,
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginTop: spacing.m5,
              }}
            >
              {scheduleDetail.absence.length > 1
                ? polyglot.t('AttendanceDomain.holiday')
                : scheduleDetail.absence[0]?.policy?.name ?? polyglot.t('AttendanceDomain.away')}
            </Typography>
            <Typography variant="caption" sx={{ color: themeColors.DarkGrey, textAlign: 'center' }}>
              {scheduleDetail.absence.length > 1
                ? polyglot.t('AttendanceDomain.allDay')
                : scheduleDetail.absence[0].afternoonOnly
                ? polyglot.t('AttendanceDomain.pm')
                : scheduleDetail.absence[0].morningOnly
                ? polyglot.t('AttendanceDomain.am')
                : polyglot.t('AttendanceDomain.allDay')}
            </Typography>
          </>
        ) : scheduleDetail.isWorking ? (
          <>
            <Typography
              variant="caption"
              sx={{
                color: scheduleDetail.isWorking ? themeColors.DarkGrey : themeColors.Grey,
                textAlign: 'center',
                marginTop: spacing.m5,
              }}
            >
              {scheduleDetail.from} - {scheduleDetail.to}
            </Typography>

            {scheduleDetail.break > 0 && (
              <Typography
                variant="caption"
                sx={{
                  color: scheduleDetail.isWorking ? themeColors.DarkGrey : themeColors.Grey,
                  textAlign: 'center',
                }}
              >
                Break {convertMinutesToHHMM(scheduleDetail.break)}h
              </Typography>
            )}
          </>
        ) : !scheduleDetail.isWorking ? (
          <Typography
            variant="caption"
            sx={{
              color: scheduleDetail.isWorking ? themeColors.DarkGrey : themeColors.Grey,
              textAlign: 'center',
              marginTop: spacing.m5,
            }}
          >
            {polyglot.t('AttendanceDomain.notWorking')}
          </Typography>
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
};
