import { Typography } from '@mui/material';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

export interface Props {
  readonly fieldName: string;
  readonly label?: string;
  readonly fieldValue: string | null;
  readonly setCommaSeparatedValue: (fieldName: string, value: any) => void;
  readonly errorText: string;
  readonly helperText: string;
}

export const CommaSeparatedTextfieldComponent = ({
  fieldName,
  label = '',
  fieldValue,
  setCommaSeparatedValue,
  errorText,
  helperText,
}: Props) => {
  return (
    <>
      <TextfieldComponent
        label={label}
        name={fieldName}
        defaultValue={fieldValue}
        value={fieldValue}
        onChange={(e) => {
          setCommaSeparatedValue(fieldName, e.target.value);
        }}
        clearText={() => setCommaSeparatedValue(fieldName, '')}
      />
      {errorText && fieldValue ? (
        <Typography sx={{ ...themeFonts.caption, opacity: 1, color: themeColors.darkRed }}>{errorText}</Typography>
      ) : (
        <Typography sx={{ ...themeFonts.caption }}>{helperText}</Typography>
      )}
    </>
  );
};
