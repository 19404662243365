import { Box, DialogActions, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';
import { themeFonts } from '@v2/styles/fonts.styles';
import { themeColors } from '@/v2/styles/colors.styles';

interface ModalProps {
  readonly children?: ReactNode;
  readonly isOpen: boolean;
  readonly onClose: Function;
  readonly title?: string | ReactNode;
  readonly titleJustifyContent?: 'flex-start' | 'center' | 'flex-end';
  readonly actionButton?: ReactNode;
  readonly buttonJustifyContent?: 'flex-start' | 'center' | 'flex-end';
  readonly size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
}

const MaterialModal = ({
  children,
  isOpen,
  onClose,
  title,
  titleJustifyContent = 'flex-start',
  actionButton,
  buttonJustifyContent = 'flex-end',
  size = 'sm',
}: ModalProps): JSX.Element => {
  return (
    <Dialog maxWidth={size} fullWidth open={isOpen} onClose={() => onClose()} aria-labelledby="form-dialog-title">
      {title && (
        <DialogTitle id="form-dialog-title" sx={{ display: 'flex', justifyContent: titleJustifyContent }}>
          <Box>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{title}</Typography>
          </Box>
        </DialogTitle>
      )}
      <DialogContent>
        <Box>{children}</Box>
      </DialogContent>
      {actionButton && (
        <DialogActions>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: buttonJustifyContent }}>{actionButton}</Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default MaterialModal;
