import { useCallback, useEffect, useMemo, useState } from 'react';

import { Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { secondarySmallBtn } from '@v2/styles/buttons.styles';
import { LocalDate } from '@v2/util/local-date';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { useDebouncedCallback } from 'use-debounce';

import { BillingDiscountAPI } from '@/api-client/billing-discount.api';
import { CompanyAPI } from '@/api-client/index.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CompanyPlanInfo } from '@/models/company.model';
import { Discount } from '@/models/discount.model';
import { FreeProductPlans, PaidProductPlans } from '@/models/subscription.model';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableFilter } from '@/v2/components/table/table-filter.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SuperAdminBillingPlanEditForm } from '@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-edit-form.component';
import { SuperAdminBillingPlanEditModal } from '@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-edit-modal.component';
import {
  getPlanFromSubscriptions,
  groupSubscriptionDiscounts,
  ProductTypes,
  SuperAdminBillingPlanTableHelper,
} from '@/v2/feature/super-admin/features/super-admin-billing/helper/super-admin-billing-plan-table-helper';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly pageConfig: PageConfig;
}

export function SuperAdminBillingPlansPage({ pageConfig }: Props) {
  const [companyPlans, setCompanyPlans] = useState<readonly CompanyPlanInfo[]>([]);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState<boolean>(false);
  const [selectedCompanyToEdit, setCompanyPlanToEdit] = useState<CompanyPlanInfo>();
  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterString, setFilterString] = useState<string>(
    `Plans=${PaidProductPlans.APPS_PRO},${PaidProductPlans.DEVICES_PRO},${PaidProductPlans.MONEY_PRO},${PaidProductPlans.PEOPLE_PRO}`
  );

  const [filterTypes, setFilterTypes] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const debouncedFilter = useDebouncedCallback(async (filterString: string) => {
    try {
      setFilterString(filterString);
      // Resets pagination index once a new filter is applied
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage('Failed to handle filter', 'error');
    }
  }, 500);

  const handleFilter = useCallback((event: string) => debouncedFilter.callback(event), [debouncedFilter]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const getFilterOptions = useCallback(async () => {
    const PLAN_FILTER_TYPES = [
      { label: 'People Free', value: FreeProductPlans.PEOPLE_FREE.toString() },
      { label: 'People Pro', value: PaidProductPlans.PEOPLE_PRO.toString() },
      { label: 'Apps Free', value: FreeProductPlans.APPS_FREE.toString() },
      { label: 'Apps Pro', value: PaidProductPlans.APPS_PRO.toString() },
      { label: 'Devices Free', value: FreeProductPlans.DEVICES_FREE.toString() },
      { label: 'Devices Pro', value: PaidProductPlans.DEVICES_PRO.toString() },
      { label: 'Money Free', value: FreeProductPlans.MONEY_FREE.toString() },
      { label: 'Money Pro', value: PaidProductPlans.MONEY_PRO.toString() },
    ];

    const FILTERS = ['Plans'];

    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case 'Plans':
          filters = {
            ...filters,
            [filter]: PLAN_FILTER_TYPES,
          };
          break;
        default:
          break;
      }
    });

    setFilterTypes(filters);
  }, []);

  const fetchCompanyPlans = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string; plans: string }) => {
      try {
        const { companyPlanInfo: apiCompanyPlans, pagination: paginationInfo } = await CompanyAPI.getAllCompanyPlans(
          params
        );

        setCompanyPlans(apiCompanyPlans || []);
        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
      } catch (error) {
        showMessage(`Something went wrong fetching company plans. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const fetchDiscounts = useCallback(async () => {
    try {
      const { discounts: apiDiscounts } = await BillingDiscountAPI.getAllDiscounts({ shouldLimitResult: 'false' });
      setDiscounts(apiDiscounts || []);
    } catch (error) {
      showMessage(`Something went wrong fetching discounts. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  const refresh = useCallback(async () => {
    try {
      await fetchCompanyPlans({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split('Plans=')[1] || '',
      });
      await fetchDiscounts();
      setIsEditModalOpen(false);
    } catch (error) {
      showMessage(`Failed to refresh. ${nestErrorMessage(error)}`, 'error');
    }
  }, [fetchCompanyPlans, pageIndex, searchQuery, filterString, fetchDiscounts, showMessage]);

  useEffect(() => {
    (async () => {
      setIsLoadingSubscriptions(true);
      await fetchCompanyPlans({
        page: pageIndex.toString(),
        pageSize: pageSize.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split('Plans=')[1] || '',
      });
      await fetchDiscounts();
      await getFilterOptions();
      setIsLoadingSubscriptions(false);
    })();
  }, [
    fetchCompanyPlans,
    setIsLoadingSubscriptions,
    fetchDiscounts,
    getFilterOptions,
    pageIndex,
    pageSize,
    searchQuery,
    filterString,
  ]);

  const handleEditButtonClick = (selectedCompany: CompanyPlanInfo) => {
    setCompanyPlanToEdit(selectedCompany);
    setIsEditModalOpen(true);
  };

  const getExportData = useCallback((): Datas => {
    if (!companyPlans) return [];
    const exportHeader = [
      'Company Name',
      'Company ID',
      'People Plan',
      'Activation Date',
      'Active Discounts',
      'Price',
      'Money Plan',
      'Activation Date',
      'Active Discounts',
      'Price',
      'Apps Plan',
      'Activation Date',
      'Active Discounts',
      'Price',
      'Devices Plan',
      'Activation Date',
      'Active Discounts',
      'Price',
    ];
    const exportRows = companyPlans.map((plansData) => {
      const peoplePlan = getPlanFromSubscriptions(ProductTypes.PEOPLE, plansData.subscriptions);
      const moneyPlan = getPlanFromSubscriptions(ProductTypes.MONEY, plansData.subscriptions);
      const appsPlan = getPlanFromSubscriptions(ProductTypes.APPS, plansData.subscriptions);
      const devicesPlan = getPlanFromSubscriptions(ProductTypes.DEVICES, plansData.subscriptions);

      const peopleDiscounts = peoplePlan ? groupSubscriptionDiscounts(peoplePlan).join(',') : '';
      const moneyDiscounts = moneyPlan ? groupSubscriptionDiscounts(moneyPlan).join(',') : '';
      const appsDiscounts = appsPlan ? groupSubscriptionDiscounts(appsPlan).join(',') : '';
      const devicesDiscounts = devicesPlan ? groupSubscriptionDiscounts(devicesPlan).join(',') : '';

      return [
        plansData?.name ?? '',
        String(plansData?.companyId ?? ''),
        // PEOPLE
        peoplePlan?.planName ?? '',
        peoplePlan?.activationDate ? new LocalDate(peoplePlan.activationDate).toDateString() : '',
        peopleDiscounts,
        String(peoplePlan?.cost ?? ''),
        // MONEY
        moneyPlan?.planName ?? '',
        moneyPlan?.activationDate ? new LocalDate(moneyPlan.activationDate).toDateString() : '',
        moneyDiscounts,
        String(moneyPlan?.cost ?? ''),
        // APPS
        appsPlan?.planName ?? '',
        appsPlan?.activationDate ? new LocalDate(appsPlan.activationDate).toDateString() : '',
        appsDiscounts,
        String(appsPlan?.cost ?? ''),
        // DEVICES
        devicesPlan?.planName ?? '',
        devicesPlan?.activationDate ? new LocalDate(devicesPlan.activationDate).toDateString() : '',
        devicesDiscounts,
        String(devicesPlan?.cost ?? ''),
      ];
    });

    return [exportHeader, ...exportRows];
  }, [companyPlans]);

  const companyPlanColumn = useMemo<ColumnDef<CompanyPlanInfo, unknown>[]>(
    () => [
      {
        header: () => 'Company Name',
        accessorFn: (row) => row,
        id: 'companyName',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.name ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original?.name}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          ),
        size: 220,
      },
      {
        header: () => 'Id',
        accessorFn: (row) => row,
        id: 'companyId',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  ...themeFonts.caption,
                  color: themeColors.DarkGrey,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original?.companyId}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          ),
        size: 220,
      },

      /**** PEOPLE PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: 'People Plan',
        id: 'peoplePlan',
        productType: ProductTypes.PEOPLE,
        size: 145,
      }),

      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: 'Date',
        id: 'peoplePlanSubscriptionDate',
        size: 140,
        productType: ProductTypes.PEOPLE,
      }),

      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: 'Active Discount',
        id: 'peoplePlanSubscriptionActiveDiscount',
        size: 140,
        productType: ProductTypes.PEOPLE,
      }),

      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: 'Price',
        id: 'peoplePlanSubscriptionPrice',
        size: 140,
        productType: ProductTypes.PEOPLE,
      }),

      /**** MONEY PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: 'Money Plan',
        id: 'moneyPlan',
        productType: ProductTypes.MONEY,
        size: 145,
      }),

      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: 'Date',
        id: 'moneyPlanSubscriptionDate',
        size: 140,
        productType: ProductTypes.MONEY,
      }),

      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: 'Active Discount',
        id: 'moneyPlanSubscriptionActiveDiscount',
        size: 140,
        productType: ProductTypes.MONEY,
      }),

      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: 'Price',
        id: 'moneyPlanSubscriptionPrice',
        size: 140,
        productType: ProductTypes.MONEY,
      }),

      /**** APPS PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: 'Apps Plan',
        id: 'appsPlan',
        productType: ProductTypes.APPS,
        size: 145,
      }),

      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: 'Date',
        id: 'appsPlanSubscriptionDate',
        size: 140,
        productType: ProductTypes.APPS,
      }),

      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: 'Active Discount',
        id: 'appsPlanSubscriptionActiveDiscount',
        size: 140,
        productType: ProductTypes.APPS,
      }),

      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: 'Price',
        id: 'appsPlanSubscriptionPrice',
        size: 140,
        productType: ProductTypes.APPS,
      }),

      /**** DEVICES PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: 'Devices Plan',
        id: 'devicesPlan',
        productType: ProductTypes.DEVICES,
        size: 145,
      }),

      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: 'Date',
        id: 'devicesPlanSubscriptionDate',
        size: 140,
        productType: ProductTypes.DEVICES,
      }),

      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: 'Active Discount',
        id: 'devicesPlanSubscriptionActiveDiscount',
        size: 140,
        productType: ProductTypes.DEVICES,
      }),

      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: 'Price',
        id: 'devicesPlanSubscriptionPrice',
        size: 140,
        productType: ProductTypes.DEVICES,
      }),

      {
        header: () => 'Action',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: true,
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                key={original.companyId}
                onClick={() => {
                  handleEditButtonClick(original);
                }}
                sx={{
                  minWidth: 10,
                  padding: 1,
                  maxWidth: 40,
                  borderRadius: 1,
                  backgroundColor: themeColors.lightGrey,
                  color: themeColors.DarkGrey,
                }}
              >
                <Edit sx={{ height: 20, width: '20px' }} />
              </Button>
            </Box>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          ),
        size: 220,
      },
    ],
    []
  );

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Billing</Typography>}
        showAction
        actions={
          <CsvDownloader
            filename={`billing_plans_${new LocalDate().toDateString()}`}
            separator=","
            datas={getExportData}
          >
            <Button fullWidth variant="contained" sx={secondarySmallBtn}>
              Export
            </Button>
          </CsvDownloader>
        }
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={isLoadingSubscriptions} secondLevel>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            ...spacing.mb20,
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <TableSearch
            style={{ width: '15em' }}
            query={searchQuery}
            handleChange={(e) => {
              setSearchQuery(e.target.value?.trim() ?? '');
              // Resets pagination index once we perform a new search
              setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
            }}
            placeholder="Enter company name..."
          />

          {filterTypes && (
            <TableFilter filterTypes={filterTypes} setFilterString={handleFilter} filterString={filterString} />
          )}
        </Box>
        {!isLoadingSubscriptions && (
          <>
            <BasicServerTable<CompanyPlanInfo>
              rowData={[...companyPlans]}
              columnData={companyPlanColumn}
              sorting={sorting}
              setSorting={setSorting}
              pagination={pagination}
              setPagination={setPagination}
              totalPages={totalPages}
              totalItems={totalItems}
            />

            {selectedCompanyToEdit && (
              <SuperAdminBillingPlanEditModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen}>
                <SuperAdminBillingPlanEditForm
                  discounts={discounts}
                  companyPlan={selectedCompanyToEdit}
                  onSave={refresh}
                />
              </SuperAdminBillingPlanEditModal>
            )}
          </>
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
}
