import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import {
  CustomDEPTokenActions,
  CustomDEPTokenSteps,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component';
import { DepWizardDto } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const DownloadServerTokenFromAbmComponent = ({
  onNext,
  setFormData,
  formData,
}: {
  onNext: () => void;
  setFormData: React.Dispatch<React.SetStateAction<DepWizardDto>>;
  formData: DepWizardDto;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography variant="title2">Download server token from ABM</Typography>
        {formData.action === CustomDEPTokenActions.Create_token ? (
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m20 }}>
            <ol>
              <li>Open your Apple Business Manager</li>
              <br />
              <li>Click on your account name in the bottom left corner, choose Preferences</li>
              <br />
              <li>
                Under Your MDM Servers, click on a plus. Give your new MDM Server a recognizable name, like Zelt MDM
              </li>
              <br />
              <li>
                Upload <u>Zelt-MDM-Token-Public-Key.pem</u> in MDM Server settings
              </li>
              <br />
              <li>Click Save</li>
              <br />
              <li>Navigate to the action at the top of the screen to Download Token</li>
            </ol>
          </Typography>
        ) : (
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m20 }}>
            <ol>
              <li>Open your Apple Business Manager</li>
              <br />
              <li>Click on your account name in the bottom left corner, choose Preferences</li>
              <br />
              <li>
                Under Your MDM Servers, select{' '}
                <u>{formData.depAccountDetails ? formData.depAccountDetails.server_name : 'NA'}</u>
              </li>
              <br />
              <li>
                Select <u>Download Token</u> action
              </li>
              <br />
              <li>
                Download token by clicking <u>Download Server Token</u> in the pop-up
              </li>
            </ol>
          </Typography>
        )}
        <ButtonComponent
          sizeVariant="large"
          colorVariant="primary"
          style={{ marginTop: spacing.m25 }}
          onClick={() => {
            formData.type = CustomDEPTokenSteps.Download_key;
            setFormData(formData);
            onNext();
          }}
          fullWidth
        >
          {polyglot.t('General.next')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
