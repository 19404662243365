import { useRef, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { CompanyPayrollType } from '@v2/feature/payroll/payroll.interface';
import { useHistory } from 'react-router-dom';

import { PayrunHeader } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header.component';
import {
  PayrunStepper,
  PayrunStepperStep,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun.stepper.component';
import { PayrunCompleteSection } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-complete.section';
import { PayrunProcessSection } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-process.section';
import { PayrunReviewSection } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-review.section';
import { PayrunSalariesSection } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-salaries.section';
import { ExternalPayRunDto } from '@/v2/feature/payroll/payroll-external.dto';
import { PayRunDto, PayRunEntryDto } from '@/v2/feature/payroll/payroll.dto';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunFlowPageProps = {
  entityName: string;
  payrollCount: number;
  externalPayRun: ExternalPayRunDto;
  entries: PayRunEntryDto[];
  previousEntries: PayRunEntryDto[];
  localPayRun: PayRunDto;
  payrunEmpAllowance: number;
  refreshPayrun: () => Promise<void>;
  startPayrun: (payrollId: number, payrun: ExternalPayRunDto, paymentDate: string) => Promise<boolean>;
  completePayrun: (localPayrun: PayRunDto) => Promise<boolean>;
};

export const PayrunFlowPage = ({
  entityName,
  externalPayRun,
  entries,
  previousEntries,
  localPayRun,
  payrunEmpAllowance,
  refreshPayrun,
  startPayrun,
  completePayrun,
}: PayrunFlowPageProps) => {
  const routerHistory = useHistory();

  const [step, setStep] = useState<PayrunStepperStep>('Salaries');
  const isReopening = useRef(false);

  if (isReopening.current) {
    return <></>;
  }

  return (
    <Stack sx={{ flex: 1, overflow: 'hidden' }}>
      <PayrunHeader
        entityName={entityName}
        payrollId={localPayRun.payrollId}
        payrollType={CompanyPayrollType.UKPayroll}
        state={localPayRun.state}
        onReopenPayrun={async () => {
          isReopening.current = true;
          await refreshPayrun();
          isReopening.current = false;
          setStep('Salaries');
        }}
        sx={{ mt: spacing.m20, mr: spacing.mr40 }}
      />
      <PayrunStepper activeStep={step} sx={{ mt: spacing.m30, mr: spacing.mr40 }} />
      <Box sx={{ mt: spacing.mt40 }} />
      {{
        Salaries: () => (
          <PayrunSalariesSection
            localPayRun={localPayRun}
            entries={entries}
            previousEntries={previousEntries}
            onBack={() => {
              routerHistory.goBack();
            }}
            onNext={() => setStep('Review')}
            refreshPayrun={refreshPayrun}
          />
        ),
        Review: () => (
          <PayrunReviewSection
            payrollId={localPayRun.payrollId}
            payrun={externalPayRun}
            payrunEmpAllowance={payrunEmpAllowance}
            startPayrunProcess={startPayrun}
            onBack={() => setStep('Salaries')}
            onNext={() => setStep('Process')}
          />
        ),
        Process: () => (
          <PayrunProcessSection
            localPayRun={localPayRun}
            completePayrun={completePayrun}
            onNext={() => setStep('Complete')}
          />
        ),
        Complete: () => <PayrunCompleteSection payrun={localPayRun} />,
      }[step]()}
    </Stack>
  );
};
