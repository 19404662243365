import { useContext } from 'react';

import { InsuranceSetupStepper } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-stepper.component';
import { PensionSetupPage } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup.page';
import { CustomBenefitCategory } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceType } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { SETTINGS_BENEFITS_ROUTE, SETTINGS_CREATE_NEW_BENEFIT_ROUTE } from '@/lib/routes';

interface BenefitsCreateRouterProps {
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly refreshPensionSchemes: () => Promise<void>;
  readonly refreshInsuranceQuote: () => Promise<void>;
  readonly companyConfig: { inPayroll: boolean; inPension: boolean };
}

export const BenefitsCreateRouter = ({
  pensionSchemes,
  refreshInsuranceQuote,
  refreshPensionSchemes,
  companyConfig,
}: BenefitsCreateRouterProps) => {
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId: currentUser.userId });

  const { category, name } = useParams<{ category: string; name: string }>();

  const isCustomType = (Object.values(CustomBenefitCategory) as string[]).includes(category);
  const isPension = category === 'Pension';
  const isInsurance = category === InsuranceType.Health;

  const allowAccessToSetupFlow = (isPension || isInsurance || isCustomType) && (!isPension || companyConfig.inPayroll);

  return (
    <Switch>
      {allowAccessToSetupFlow && (
        <RouteScopesControl
          context={context}
          scopes={isPension ? ['pension:all'] : ['insurance:all']}
          path={SETTINGS_CREATE_NEW_BENEFIT_ROUTE}
        >
          {isPension ? (
            <PensionSetupPage
              name={name}
              noOfActivePensionSchemes={pensionSchemes.length}
              refresh={refreshPensionSchemes}
            />
          ) : isInsurance ? (
            <InsuranceSetupStepper name={name} refresh={refreshInsuranceQuote} />
          ) : isCustomType ? (
            <>{null}</> // <>Custom benefit [{name}]</>
          ) : null}
        </RouteScopesControl>
      )}

      <Redirect to={SETTINGS_BENEFITS_ROUTE} />
    </Switch>
  );
};
