import { useMemo } from 'react';

import { themeColors } from '@v2/styles/colors.styles';
import { differenceInHours } from 'date-fns';

import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { iconSize } from '@/v2/styles/menu.styles';

interface Props {
  readonly devicePossession: DevicePossessionDto;
}

export const SecurityDetailsInformation = ({ devicePossession }: Props): JSX.Element => {
  const device = devicePossession.device!;
  const isInactive = useMemo(() => {
    if (!devicePossession.device?.lastCheckIn) return false;
    const lastCheckInDate = new Date(devicePossession.device.lastCheckIn);
    const currentTime = new Date();
    return differenceInHours(currentTime, lastCheckInDate) > 72;
  }, [devicePossession.device?.lastCheckIn]);

  return (
    <SettingsSubsectionContent
      sections={[
        {
          title: 'Activity',
          items: [
            {
              type: SectionItemType.Pair,
              label: 'Status',
              value: (
                <>
                  {isInactive ? (
                    <>
                      <Mistake {...iconSize} fill={themeColors.Red} /> Inactive
                    </>
                  ) : (
                    <>
                      <OkGreen {...iconSize} style={{ fill: themeColors.Green }} /> Active
                    </>
                  )}
                </>
              ),
              hidden: !Boolean(device.lastCheckIn),
            },
            {
              type: SectionItemType.Pair,
              label: 'Last check in',
              value: device.lastCheckIn
                ? device.inHouseMdm
                  ? new Date(device.lastCheckIn).toUTCString()
                  : new Date(device.lastCheckIn).toUTCString().split(' ').slice(0, 4).join(' ')
                : 'NA',
              hidden: !Boolean(device.lastCheckIn),
            },

            {
              type: SectionItemType.TextLine,
              value: 'No activity',
              hidden: Boolean(device.lastCheckIn),
            },
          ],
        },
      ]}
    />
  );
};
