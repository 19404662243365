import React from 'react';
import { Box, Typography } from '@mui/material';
import { themeColors } from '@v2/styles/colors.styles';
import { ReactComponent as Ok } from '@/images/side-bar-icons/Ok.svg';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export default function SuccessCloseTab() {
  return (
    <Box sx={{ m: 'auto', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mx: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box>
          <Ok width="40" height="40px" fill={themeColors.ZeltYellow} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            Payment successfully registered.
          </Typography>
        </Box>
      </Box>

      <Typography sx={{ ...themeFonts.caption, mt: spacing.mt20, color: themeColors.Grey }} alignSelf="center">
        This tab can be safely closed now.
      </Typography>
    </Box>
  );
}
