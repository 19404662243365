import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { translateColumnsOptions } from '@/v2/infrastructure/i18n/translate.util';

export interface ColumnsFilterOption {
  label: string;
  value: string;
  required?: boolean;
}

interface ColumnsFilterProps {
  readonly columnsOptions: readonly ColumnsFilterOption[];
  readonly selectedColumns: string[];
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  readonly fullWidth?: boolean;
  readonly onApply?: (selectedColumns: string[]) => Promise<void> | void;
}

export const ColumnsDrawer = ({
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  fullWidth = false,
  onApply = undefined,
}: ColumnsFilterProps) => {
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const noOfSelectedColumns = useMemo(() => new Set(selectedColumns).size, [selectedColumns]);

  // const showAllColumns = useMemo(() => {
  //   const noOfRequiredColumns = columnsOptions.filter((o) => o.required).length;
  //   return noOfSelectedColumns <= noOfRequiredColumns || noOfSelectedColumns === columnsOptions.length;
  // }, [noOfSelectedColumns, columnsOptions]);

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto' }}>
      <ColumnsSideDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        columnsOptions={columnsOptions}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        onApply={onApply}
      />

      <ButtonComponent
        onClick={() => {
          setIsDrawerOpen(true);
        }}
        sizeVariant="filter"
        colorVariant="active"
        fullWidth={fullWidth}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          {polyglot.t('ColumnsDrawer.columns')}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              minWidth: '16px',
              height: '16px',
              bgcolor: 'white',
            }}
          >
            <Typography variant="caption" sx={{ mx: '2px' }}>
              {noOfSelectedColumns}
            </Typography>
          </Box>
        </Box>
      </ButtonComponent>
    </Box>
  );
};

interface ColumnsFilterDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly columnsOptions: readonly ColumnsFilterOption[];
  readonly selectedColumns: string[];
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  readonly onApply: ((selectedColumns: string[]) => Promise<void> | void) | undefined;
}

const ColumnsSideDrawer = ({
  isOpen,
  setIsOpen,
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  onApply,
}: ColumnsFilterDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <ColumnsFilterDrawerContent
          columnsOptions={columnsOptions}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          setIsOpen={setIsOpen}
          onApply={onApply}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface ColumnsFilterDrawerContentProps {
  readonly columnsOptions: readonly ColumnsFilterOption[];
  readonly selectedColumns: string[];
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onApply: ((selectedColumns: string[]) => Promise<void> | void) | undefined;
}

const ColumnsFilterDrawerContent = ({
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  setIsOpen,
  onApply,
}: ColumnsFilterDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [localSelectedColumns, setLocalSelectedColumns] = useState<string[]>([...selectedColumns]);
  const [filteredColumnsOptions, setFilteredColumnsOptions] = useState<readonly ColumnsFilterOption[]>([
    ...columnsOptions,
  ]);
  const [searchInput, setSearchInput] = useState('');
  const requiredColumns = useMemo(() => columnsOptions.filter((o) => o.required), [columnsOptions]);

  useEffect(() => {
    if (!searchInput) {
      setFilteredColumnsOptions([...columnsOptions]);
    } else {
      const searchString = searchInput.toLowerCase();
      setFilteredColumnsOptions(columnsOptions.filter((option) => option.label.toLowerCase().includes(searchString)));
    }
  }, [searchInput, columnsOptions]);

  const applyColumnsSelection = useCallback(async () => {
    setSelectedColumns([...localSelectedColumns]);
    setIsOpen(false);

    if (onApply) onApply([...localSelectedColumns]);
  }, [setIsOpen, setSelectedColumns, localSelectedColumns, onApply]);

  return (
    <Box sx={drawerContentSx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="title2">{polyglot.t('ColumnsDrawer.columns')}</Typography>

        <Box>
          {localSelectedColumns.length > requiredColumns.length ? (
            <ButtonComponent
              onClick={() => setLocalSelectedColumns(requiredColumns.map((o) => o.value))}
              sizeVariant="link"
              colorVariant="text"
            >
              {polyglot.t('ColumnsFilterDrawerContent.clearAll')}
            </ButtonComponent>
          ) : (
            <ButtonComponent
              onClick={() => setLocalSelectedColumns(columnsOptions.map((o) => o.value))}
              sizeVariant="link"
              colorVariant="text"
            >
              {polyglot.t('ColumnsFilterDrawerContent.selectAll')}
            </ButtonComponent>
          )}
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value ?? '');
          }}
          style={{ width: '350px', minWidth: '350px' }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
        {filteredColumnsOptions.map((option) => {
          const checked = localSelectedColumns.some((column) => column === option.value);

          return (
            <CheckboxComponent
              key={option.value}
              label={translateColumnsOptions(option.value, polyglot)}
              name={option.label}
              checked={checked}
              value={option.value}
              disabled={checked && option.required}
              onChange={() => {
                setLocalSelectedColumns((currentState) => {
                  if (currentState.some((column) => column === option.value))
                    return currentState.filter((column) => column !== option.value || option.required);
                  return [...currentState, option.value];
                });
              }}
            />
          );
        })}
      </Box>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          fullWidth
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth onClick={applyColumnsSelection}>
          {polyglot.t('General.apply')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
