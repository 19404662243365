import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { MultipleSelectCheckbox } from '@v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { ManageUserInsuranceDto, UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import {
  UserInsuranceDependants,
  UserInsurancePolicyStatus,
} from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { nestErrorMessage } from '@/lib/errors';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';

const STATUS_OPTIONS = [
  { label: 'None', value: UserInsurancePolicyStatus.None },
  { label: 'Active', value: UserInsurancePolicyStatus.Active },
  { label: 'Pending', value: UserInsurancePolicyStatus.Pending },
  { label: 'PendingOptOut', value: UserInsurancePolicyStatus.PendingOptOut },
  { label: 'OptOut', value: UserInsurancePolicyStatus.OptOut },
];

const DEPENDANTS_OPTIONS = [
  { label: 'Spouse/Partner', value: UserInsuranceDependants.SpousePartner },
  { label: 'Children', value: UserInsuranceDependants.Children },
  { label: 'Family', value: UserInsuranceDependants.Family },
];

interface ManageUserInsuranceDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
  readonly userInsurance: UserInsuranceDto;
  readonly userName: string;
  readonly onSave: () => void;
}

export const ManageUserInsuranceDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  refresh,
  userName,
  onSave,
}: ManageUserInsuranceDrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(
    UserEndpoints.getUserFamilyMembersAsSuperAdmin(userInsurance.userId),
    { suspense: false }
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ManageUserInsuranceDrawerContent
        refresh={refresh}
        userInsurance={userInsurance}
        userName={userName}
        userFamilyMembers={userFamilyMembers ?? []}
        onSave={onSave}
      />
    </DrawerModal>
  );
};

interface ManageUserInsuranceDrawerContentProps {
  readonly userInsurance: UserInsuranceDto;
  readonly refresh: () => Promise<void>;
  readonly userFamilyMembers: UserFamilyMemberDto[];
  readonly userName: string;
  readonly onSave: () => void;
}

const ManageUserInsuranceDrawerContent = ({
  userInsurance,
  refresh,
  userName,
  userFamilyMembers,
  onSave,
}: ManageUserInsuranceDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<ManageUserInsuranceDto>({
    initialValues: {
      status: userInsurance.status ?? UserInsurancePolicyStatus.None,
      startDate: userInsurance.startDate,
      endDate: userInsurance.endDate ?? null,
      monthlyPremium: userInsurance.monthlyPremium,
      monthlyContribution: userInsurance.monthlyContribution,
      dependants: userInsurance.dependants,
      dependantsList: userInsurance.dependantsList,
      dependantsMonthlyPremium: userInsurance.dependantsMonthlyPremium,
      dependantsMonthlyContribution: userInsurance.dependantsMonthlyContribution,
      dependantsStatus: userInsurance.dependantsStatus,
    },
    validationSchema: yup.object({
      status: yup.string().nullable().notRequired(),
      startDate: yup.string().test(dateFieldTest).nullable().notRequired(),
      endDate: yup.string().test(dateFieldTest).nullable().notRequired(),
      monthlyPremium: yup.number().nullable().typeError('Monthly premium should be a number').notRequired(),
      monthlyContribution: yup.number().nullable().typeError('Monthly contribution should be a number').notRequired(),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup.array().of(yup.number().integer()).nullable().notRequired(),
      dependantsMonthlyPremium: yup.number().typeError('Monthly premium should be a number').nullable().notRequired(),
      dependantsMonthlyContribution: yup
        .number()
        .typeError('Monthly contribution should be a number')
        .nullable()
        .notRequired(),
      dependantsStatus: yup.string().nullable().notRequired(),
    }),
    onSubmit: async (values: ManageUserInsuranceDto) => {
      await updateUserPolicy(values);
    },
  });

  const updateUserPolicy = async (values: ManageUserInsuranceDto) => {
    setLoading(true);
    try {
      const update = {
        status: values.status === UserInsurancePolicyStatus.None ? null : values.status,
        startDate: values.startDate ? values.startDate : null,
        endDate: values.endDate ? values.endDate : null,
        monthlyPremium: values.monthlyPremium ? Number(values.monthlyPremium) : null,
        monthlyContribution: values.monthlyContribution ? Number(values.monthlyContribution) : null,
        dependants: values.dependants ?? null,
        dependantsList: values.dependants ? values.dependantsList : null,
        dependantsStatus: values.dependantsStatus ?? null,
        dependantsMonthlyPremium: values.dependantsMonthlyPremium ? Number(values.dependantsMonthlyPremium) : null,
        dependantsMonthlyContribution: values.dependantsMonthlyContribution
          ? Number(values.dependantsMonthlyContribution)
          : null,
      };
      await InsuranceAPI.updateUserInsuranceAsSuperAdmin(userInsurance.policyId, userInsurance.userId, update);
      await refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not create the policy. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];

    return formik.values.dependantsList
      .map((id) => {
        const familyMember = userFamilyMembers.find((m) => m.id === id);

        return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
      })
      .filter(Boolean) as { label: string; value: number }[];
  }, [formik.values.dependantsList, userFamilyMembers]);

  const dependantsListOptions = useMemo(() => {
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (
        userFamilyMembers
          ?.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type))
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (
        userFamilyMembers
          ?.filter((m) => m.type === UserFamilyMemberType.Child)
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    return userFamilyMembers?.map((m) => ({ value: m.id, label: m.name })) ?? [];
  }, [userFamilyMembers, formik.values.dependants]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Edit user insurance</Typography>
        <Typography variant="title3">{userName}</Typography>

        <SelectComponent
          name="status"
          label="Status"
          options={STATUS_OPTIONS}
          value={formik.values.status}
          onChange={formik.handleChange}
          compareValue={formik.values.status}
          error={!!formik.errors.status && formik.touched.status}
          helperText={(formik.touched.status && formik.errors.status) as string}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px', width: '100%', alignItems: 'end' }}>
          <DatePickerComponent
            name="startDate"
            label="Start date"
            inputFormat="DD/MM/YYYY"
            value={formik.values.startDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('startDate', value);
              }
            }}
            sx={{ width: '100%' }}
            error={!!formik.errors.startDate && Boolean(formik.touched.startDate)}
            helperText={formik.errors.startDate && Boolean(formik.touched.startDate)}
          />
          <Box>
            <IconButton
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={() => {
                formik.setFieldValue('startDate', null);
              }}
            >
              <Close {...iconSize} stroke={themeColors.DarkGrey} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px', width: '100%', alignItems: 'end' }}>
          <DatePickerComponent
            name="endDate"
            label="End date"
            inputFormat="DD/MM/YYYY"
            value={formik.values.endDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('endDate', value);
              }
            }}
            sx={{ width: '100%' }}
            error={!!formik.errors.endDate && Boolean(formik.touched.endDate)}
            helperText={formik.errors.endDate && Boolean(formik.touched.endDate)}
          />
          <Box>
            <IconButton
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={() => {
                formik.setFieldValue('endDate', null);
              }}
            >
              <Close {...iconSize} stroke={themeColors.DarkGrey} fill={themeColors.DarkGrey} />
            </IconButton>
          </Box>
        </Box>

        <TextfieldComponent
          label="Monthly premium"
          name="monthlyPremium"
          value={formik.values.monthlyPremium}
          onChange={formik.handleChange}
          error={formik.touched.monthlyPremium && Boolean(formik.errors.monthlyPremium)}
          helperText={(formik.touched.monthlyPremium && formik.errors.monthlyPremium) as string}
          size="small"
          endAdornment="none"
          type="number"
        />

        <TextfieldComponent
          label="Monthly contribution"
          name="monthlyContribution"
          value={formik.values.monthlyContribution}
          onChange={formik.handleChange}
          error={formik.touched.monthlyContribution && Boolean(formik.errors.monthlyContribution)}
          helperText={(formik.touched.monthlyContribution && formik.errors.monthlyContribution) as string}
          size="small"
          endAdornment="none"
          type="number"
        />

        <SelectComponent
          name="dependants"
          label="Dependants"
          options={DEPENDANTS_OPTIONS}
          value={formik.values.dependants}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldValue('dependantsList', []);
          }}
          compareValue={formik.values.dependants}
          error={!!formik.errors.dependants && formik.touched.dependants}
          helperText={(formik.touched.dependants && formik.errors.dependants) as string}
        />

        <TextfieldComponent
          label="Dependants monthly premium"
          name="dependantsMonthlyPremium"
          value={formik.values.dependantsMonthlyPremium}
          onChange={formik.handleChange}
          error={formik.touched.dependantsMonthlyPremium && Boolean(formik.errors.dependantsMonthlyPremium)}
          helperText={(formik.touched.dependantsMonthlyPremium && formik.errors.dependantsMonthlyPremium) as string}
          size="small"
          endAdornment="none"
          type="number"
        />

        <TextfieldComponent
          label="Dependants monthly contribution"
          name="dependantsMonthlyContribution"
          value={formik.values.dependantsMonthlyContribution}
          onChange={formik.handleChange}
          error={formik.touched.dependantsMonthlyContribution && Boolean(formik.errors.dependantsMonthlyContribution)}
          helperText={
            (formik.touched.dependantsMonthlyContribution && formik.errors.dependantsMonthlyContribution) as string
          }
          size="small"
          endAdornment="none"
          type="number"
        />

        <SelectComponent
          name="dependantsStatus"
          label="Dependants status"
          options={STATUS_OPTIONS}
          value={formik.values.dependantsStatus}
          onChange={formik.handleChange}
          compareValue={formik.values.dependantsStatus}
          error={!!formik.errors.dependantsStatus && formik.touched.dependantsStatus}
          helperText={(formik.touched.dependantsStatus && formik.errors.dependantsStatus) as string}
        />

        <Box>
          <MultipleSelectCheckbox<number>
            id="dependantsList"
            label="Additional members list"
            limitTags={-1}
            options={dependantsListOptions}
            value={selectedDependantsList}
            onChange={(_, values) => {
              const updatedOptionList = values.map(({ value }) => value);
              formik.setFieldValue('dependantsList', updatedOptionList);
            }}
            isOptionEqualToValue={(x, y) => x.value === y.value}
            getOptionLabel={({ label }: { label: string }): string => label}
          />
          {!!formik.errors.dependantsList && formik.touched.dependantsList && (
            <Typography variant="captionSmall" sx={{ color: themeColors.RedDark }}>
              {formik.errors.dependantsList}
            </Typography>
          )}
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name="Save" loading={loading} sizeVariant="medium" colorVariant="primary" fullWidth />
        </Box>
      </Form>
    </FormikProvider>
  );
};
