import React, { Dispatch, SetStateAction, Suspense, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceAPI, AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto, UserAbsenceAllowancesDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown } from '@v2/feature/absence/absence.interface';
import { isHourlyPolicy } from '@v2/feature/absence/absence.util';
import {
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getExpiredUnusedCarryOver,
  getRemainingCarryOver,
  getUsedCarryOver,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { getAllowancesUnits } from '@v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { round2Digits } from '@v2/util/number.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';

interface BalanceCarryOverDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly refresh: (policyId: number | 'all') => Promise<void>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
}

export const BalanceCarryOverDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  policyBreakdown,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
}: BalanceCarryOverDrawerProps) => {
  const { polyglot } = usePolyglot();
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box sx={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AllowanceDrawer.carryOver')}</Typography>
          <BalanceCarryOverDrawerContent
            userId={userId}
            policyBreakdown={policyBreakdown}
            absencePolicy={absencePolicy}
            refreshAllowances={refresh}
            isOnRegularSchedule={isOnRegularSchedule}
            currentAverageWorkDayLength={currentAverageWorkDayLength}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface BalanceCarryOverDrawerContentProps {
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly refreshAllowances: (policyId: number | 'all') => Promise<void>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
}

const BalanceCarryOverDrawerContent = ({
  userId,
  absencePolicy,
  policyBreakdown,
  refreshAllowances,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
}: BalanceCarryOverDrawerContentProps) => {
  const selectedYear = useMemo(() => policyBreakdown.holidayYear, [policyBreakdown]);
  const { data: userAllowances, mutate: refreshUserAllowance } = useApiClient(
    AbsenceEndpoints.getUserAbsencePolicyAllowances(absencePolicy.id, userId, selectedYear)
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    await Promise.all([refreshAllowances(absencePolicy.id), refreshUserAllowance ? refreshUserAllowance() : undefined]);
  }, [absencePolicy.id, refreshAllowances, refreshUserAllowance]);

  const isHourly = isHourlyPolicy(absencePolicy);

  return isEditMode ? (
    <EditMode
      userId={userId}
      absencePolicy={absencePolicy}
      effectiveYear={selectedYear}
      allowances={userAllowances ?? null}
      refresh={refresh}
      setIsEditMode={setIsEditMode}
      isOnRegularSchedule={isOnRegularSchedule}
      currentAverageWorkDayLength={currentAverageWorkDayLength}
      isHourly={isHourly}
    />
  ) : (
    <ReadMode
      userId={userId}
      absencePolicy={absencePolicy}
      policyBreakdown={policyBreakdown}
      setIsEditMode={setIsEditMode}
      year={selectedYear}
      isHourly={isHourly}
    />
  );
};

const ReadMode = ({
  userId,
  setIsEditMode,
  absencePolicy,
  policyBreakdown,
  year,
  isHourly,
}: {
  readonly userId: number;
  readonly setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly year: number;
  readonly isHourly: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(['absence:manager'], { userId });

  const unusedExpiredCarryOverDetails = useMemo(() => {
    return getExpiredCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, policyBreakdown, absencePolicy]);

  const unusedUnitsLastCycle = useMemo(() => {
    return getExpiredUnusedCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, policyBreakdown, absencePolicy]);

  const allowedUnitsThisCycle = useMemo(() => {
    return getCarryOverFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, absencePolicy, policyBreakdown]);

  const carryOverUsed = useMemo(() => {
    return getUsedCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, policyBreakdown, absencePolicy]);

  const carryOverRemaining = useMemo(() => {
    return getRemainingCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, absencePolicy, policyBreakdown]);

  return (
    <Box style={{ ...drawerContentSx, gap: spacing.s1 }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="caption">{polyglot.t('AllowanceDrawer.unusedIn', { year: year - 1 })}</Typography>
        <Typography variant="title4">{unusedUnitsLastCycle}</Typography>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="caption">{polyglot.t('AllowanceDrawer.companyLimit')}</Typography>
        <Typography variant="title4">
          {policyBreakdown.carryOver.companyLimit === null
            ? polyglot.t('AllowanceDrawer.unlimited')
            : isHourly
            ? polyglot.t('AllowanceDrawer.noOfHours', { smart_count: policyBreakdown.carryOver.companyLimit })
            : polyglot.t('AllowanceDrawer.noOfDays', { smart_count: policyBreakdown.carryOver.companyLimit })}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="caption">{polyglot.t('AllowanceDrawer.carriedOverInto', { year })}</Typography>
        <Typography variant="title4">{allowedUnitsThisCycle}</Typography>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: spacing.m15 }}>
        <Typography variant="caption">{polyglot.t('AllowanceDrawer.carryOverUsedIn', { year })}</Typography>
        <Typography variant="title4">{carryOverUsed}</Typography>
      </Box>

      <Divider style={{ marginBottom: spacing.m10 }} />

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="title4">{polyglot.t('AllowanceDrawer.carryOverAvailableToday')}</Typography>
        <Typography variant="title4">{carryOverRemaining}</Typography>
      </Box>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="caption">{polyglot.t('AllowanceDrawer.carryOverExpiry')}</Typography>
        <Typography variant="title4">
          {policyBreakdown.carryOver.expiryDate
            ? new LocalDate(policyBreakdown.carryOver.expiryDate).toLocaleDateString()
            : polyglot.t('AllowanceDrawer.noExpiry')}
        </Typography>
      </Box>

      {policyBreakdown.carryOver.expiryDate && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="caption">{polyglot.t('AllowanceDrawer.carryOverExpired')}</Typography>
          <Typography variant="title4">{unusedExpiredCarryOverDetails}</Typography>
        </Box>
      )}

      {isAdminOrManager && (
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent onClick={() => setIsEditMode(true)} sizeVariant="medium" colorVariant="secondary" fullWidth>
            {polyglot.t('General.edit')}
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};

const EditMode = ({
  userId,
  absencePolicy,
  effectiveYear,
  allowances,
  refresh,
  setIsEditMode,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  isHourly,
}: {
  readonly userId: number;
  readonly effectiveYear: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly allowances: UserAbsenceAllowancesDto | null;
  readonly refresh: () => Promise<void>;
  readonly setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly isOnRegularSchedule: boolean;
  readonly currentAverageWorkDayLength: number;
  readonly isHourly: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const updateCarryOverAdjustment = useCallback(
    async (userCarryOverAdjustment: number) => {
      if (!allowances) return;
      setLoading(true);
      try {
        await AbsenceAPI.updateAbsencePolicyUserAllowanceCarryOverAdjustment(absencePolicy.id, userId, {
          effectiveYear,
          userCarryOverAdjustment,
        });
        await refresh();
        setIsEditMode(false);
      } catch (error) {
        showMessage(
          polyglot.t('AllowanceDrawer.errors.couldNotUpdateCarryOver', { nestError: nestErrorMessage(error) }),
          'error'
        );
      }
      setLoading(false);
    },
    [polyglot, allowances, absencePolicy.id, userId, effectiveYear, refresh, showMessage, setIsEditMode]
  );

  const formik = useFormik<{ unit: 'day' | 'hour'; value: number }>({
    initialValues: {
      unit: isOnRegularSchedule && !isHourly && currentAverageWorkDayLength ? 'day' : 'hour',
      value:
        (isOnRegularSchedule && !isHourly && currentAverageWorkDayLength
          ? round2Digits((allowances?.userCarryOverAdjustment ?? 0) / currentAverageWorkDayLength)
          : round2Digits((allowances?.userCarryOverAdjustment ?? 0) / 60)) ?? 0,
    },
    validationSchema: Yup.object({
      value: Yup.number()
        .typeError(polyglot.t('AllowanceDrawer.errors.inputValidValue'))
        .required(polyglot.t('AllowanceDrawer.errors.validValueRequired')),
      unit: Yup.string().oneOf(['hour', 'day']).required(polyglot.t('AllowanceDrawer.errors.validValueRequired')),
    }),
    onSubmit: async ({ unit, value }: { unit: 'day' | 'hour'; value: number }) => {
      const multiplier = unit === 'day' && currentAverageWorkDayLength ? currentAverageWorkDayLength : 60;
      const carryOverAdjustment = Math.round(Number(value) * multiplier);
      await updateCarryOverAdjustment(carryOverAdjustment);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ ...fieldSx, display: 'flex', gap: spacing.g10 }}>
          <SelectComponent
            name="unit"
            label={polyglot.t('BalanceCarryOverDrawer.unit')}
            options={getAllowancesUnits(polyglot)}
            value={formik.values.unit}
            onChange={formik.handleChange}
            disabled={!isOnRegularSchedule || isHourly || !currentAverageWorkDayLength}
          />
          <TextfieldComponent
            label={polyglot.t('BalanceCarryOverDrawer.value')}
            name="value"
            value={formik.values.value}
            onChange={(e) => {
              formik.setFieldValue('value', e.target.value);
            }}
            fullWidth
            size="small"
            endAdornment="none"
          />
        </Box>
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
