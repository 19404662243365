import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as External } from '@/images/icons/external-link-arrow.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Connected } from '@/images/side-bar-icons/Ok.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ReactComponent as NotConnected } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_ATTENDANCE_SCHEDULE_ROUTE,
  SETTINGS_BENEFITS_DETAILS_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ROUTE,
} from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortBoolean, sortString } from '@/v2/components/table/table-sorting.util';
import { AbsenceEndpoints } from '@/v2/feature/absence/absence.api';
import { AttendanceEndpoints } from '@/v2/feature/attendance/attendance.api';
import { CustomBenefitAPI } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollIntegrationSettingsEditDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/integrations-settings/payroll-integration-settings-drawer.component';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

export const PayrollCompanySettingsIntegrations = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [isEditIntegrationOpen, setEditIntegrationOpen] = useState(false);
  const [editIntegration, setEditIntegration] = useState<PayrollIntegration | null>(null);
  const [integrations, setIntegrations] = useState<PayrollIntegration[]>();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const updateIntegrations = useCallback((kind: PayrollIntegrationKind, values: PayrollIntegration[]) => {
    setIntegrations((current) => {
      if (!current) return values;
      return [...current.filter((value) => value.kind !== kind), ...values];
    });
  }, []);

  const {
    data: absencePolicies,
    mutate: refreshAbsencePolicies,
  } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });

  const {
    data: attendanceSchedules,
    mutate: refreshAttendanceSchedules,
  } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });

  const {
    data: expenses,
    mutate: refreshExpenses,
  } = useApiClient(PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId(), { suspense: false });

  useEffect(() => {
    if (!absencePolicies) return;
    const absenceIntegrations = absencePolicies.map<AbsencePayrollIntegration>((absencePolicy) => ({
      kind: 'absence',
      absencePolicy,
      id: absencePolicy.id,
      name: absencePolicy.name,
      hasPayrollIntegration:
        (absencePolicy.inPayrollOffboarding || absencePolicy.inPayrollRequests) && !!absencePolicy.payCode,
    }));
    updateIntegrations('absence', absenceIntegrations);
  }, [absencePolicies, updateIntegrations]);

  useEffect(() => {
    if (!attendanceSchedules) return;
    const attendanceIntegrations = attendanceSchedules.map<AttendancePayrollIntegration>((attendanceSchedule) => ({
      kind: 'attendance',
      attendanceSchedule,
      id: attendanceSchedule.id,
      name: attendanceSchedule.name,
      hasPayrollIntegration: attendanceSchedule.includedInPayroll && !!attendanceSchedule.payCode,
    }));
    updateIntegrations('attendance', attendanceIntegrations);
  }, [attendanceSchedules, updateIntegrations]);

  useEffect(() => {
    if (!expenses) return;
    const expenseIntegrations = expenses.settings.map<ExpensePayrollIntegration>((expense) => ({
      kind: 'expense',
      expense,
      id: expense.id as number,
      name: expense.name,
      hasPayrollIntegration: !!expense.payrolled && !!expense.paycode,
    }));
    updateIntegrations('expense', expenseIntegrations);
  }, [expenses, updateIntegrations]);

  const refreshBenefits = useCallback(async () => {
    try {
      const benefits = await CustomBenefitAPI.getAllCustomBenefits();
      const benefitIntegrations = benefits.map<BenefitPayrollIntegration>((benefit) => ({
        kind: 'benefit',
        name: benefit.name,
        benefit,
        id: benefit.id,
        hasPayrollIntegration: benefit.paymentMethod === 'Payroll' && !!benefit.payCode,
      }));
      updateIntegrations('benefit', benefitIntegrations);
    } catch (err) {
      showMessage(nestErrorMessage(err), 'error');
    }
  }, [showMessage, updateIntegrations]);

  useEffect(() => {
    refreshBenefits();
  }, [refreshBenefits]);

  const integrationTypeLabel = useCallback(
    (kind: PayrollIntegrationKind) => {
      return {
        absence: () => polyglot.t('PayrollIntegrationSettings.integrationTypeAbsence'),
        attendance: () => polyglot.t('PayrollIntegrationSettings.integrationTypeAttendance'),
        benefit: () => polyglot.t('PayrollIntegrationSettings.integrationTypeBenefit'),
        expense: () => polyglot.t('PayrollIntegrationSettings.integrationTypeExpense'),
      }[kind]();
    },
    [polyglot]
  );

  const navigateToIntegration = useCallback(
    (integration: PayrollIntegration) => {
      const path = {
        absence: () => generatePath(SETTINGS_TIME_POLICY_DETAILS_ROUTE, { policyId: integration.id }),
        attendance: () => generatePath(SETTINGS_ATTENDANCE_SCHEDULE_ROUTE, { scheduleId: integration.id }),
        benefit: () =>
          generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
            productType: 'custom',
            category: (integration as BenefitPayrollIntegration).benefit.category,
            id: integration.id,
          }),
        expense: () => generatePath(SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE, { id: integration.id }),
      }[integration.kind]();
      routerHistory.push(path);
    },
    [routerHistory]
  );

  const columns = useMemo<ColumnDef<PayrollIntegration, PayrollIntegration>[]>(() => {
    return [
      {
        id: 'type',
        header: () => polyglot.t('PayrollIntegrationSettings.integrationType'),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => integrationTypeLabel(item.kind)),
        cell: (c) => integrationTypeLabel(c.row.original.kind),
      },
      {
        id: 'name',
        header: () => polyglot.t('PayrollIntegrationSettings.integrationName'),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name, { sensitivity: 'base' }),
        cell: (c) => c.row.original.name,
      },
      {
        id: 'status',
        header: () => polyglot.t('PayrollIntegrationSettings.integrationStatus'),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.hasPayrollIntegration),
        cell: (c) =>
          c.row.original.hasPayrollIntegration ? (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
              <Connected {...iconSize} fill={themeColors.Green} />
              {polyglot.t('PayrollIntegrationSettings.integrationStatusConnected')}
            </Stack>
          ) : (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
              <NotConnected {...iconSize} />
              {polyglot.t('PayrollIntegrationSettings.integrationStatusNotConnected')}
            </Stack>
          ),
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (x) => x,
        enableSorting: false,
        cell: (c) => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: spacing.g5 }}>
            {c.row.original.hasPayrollIntegration && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => {
                  setEditIntegration(c.row.original);
                  setEditIntegrationOpen(true);
                }}
              >
                <Edit {...iconSize} />
              </ButtonComponent>
            )}
            {!c.row.original.hasPayrollIntegration && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="primary"
                onClick={() => {
                  setEditIntegration(c.row.original);
                  setEditIntegrationOpen(true);
                }}
              >
                <Plus {...iconSize} />
              </ButtonComponent>
            )}
            <ButtonComponent
              sizeVariant="small"
              colorVariant="secondary"
              onClick={() => {
                navigateToIntegration(c.row.original);
              }}
            >
              <External {...iconSize} />
            </ButtonComponent>
          </Stack>
        ),
      },
    ];
  }, [integrationTypeLabel, navigateToIntegration, polyglot]);

  useEffect(() => {
    if (!isEditIntegrationOpen) {
      setEditIntegration(null);
    }
  }, [isEditIntegrationOpen]);

  return (
    <>
      <Stack sx={{ gap: spacing.g20, my: spacing.my20 }}>
        <PayrollSettingSectionHeader>{polyglot.t('PayrollIntegrationSettings.title')}</PayrollSettingSectionHeader>
        {integrations && (
          <BasicTable
            rowData={integrations}
            columnData={columns}
            initialSort={[
              { id: 'type', desc: true },
              { id: 'name', desc: true },
            ]}
          />
        )}
      </Stack>
      <PayrollIntegrationSettingsEditDrawer
        isOpen={isEditIntegrationOpen}
        setIsOpen={setEditIntegrationOpen}
        integration={editIntegration}
        refreshAbsences={refreshAbsencePolicies}
        refreshAttendances={refreshAttendanceSchedules}
        refreshBenefits={refreshBenefits}
        refreshExpenses={refreshExpenses}
      />
    </>
  );
};
