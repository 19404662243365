import { useCallback } from 'react';

import { PayItemEndpoints } from '@v2/feature/payroll/features/pay-item/pay-item.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { PayItemsPreviewList } from './pay-items-preview-list.component';

export const AttendanceDrawerPayItemPreview = ({ userId, attendanceId }: { userId: number; attendanceId: number }) => {
  const { data: oneOffPayments, mutate: refreshPayments } = useApiClient(
    PayItemEndpoints.getUserOneOffPaymentsByAttendance(userId, attendanceId),
    {
      suspense: false,
    }
  );

  const refresh = useCallback(async () => {
    if (refreshPayments) await refreshPayments();
  }, [refreshPayments]);

  return oneOffPayments && oneOffPayments.length > 0 ? (
    <PayItemsPreviewList oneOffPayments={oneOffPayments} refresh={refresh} />
  ) : null;
};
