import { useState } from 'react';

import { Box } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SCParticipantAddModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-add-modal.component';
import { SCParticipantsSharedTable } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/shared/sc-participants-shared-table.component';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { spacing } from '@/v2/styles/spacing.styles';

interface ISCParticipantScheduledView {
  readonly surveyParticipants: number[] | null | undefined;
  readonly surveyParticipantsLoading: boolean | undefined;
  readonly surveyCycle: SurveyCycle;
  readonly refresh: () => Promise<void>;
  readonly reach: ReachType;
  readonly isEditable: boolean;
}

export const SCParticipantScheduledView = ({
  surveyParticipants,
  surveyParticipantsLoading,
  surveyCycle,
  refresh,
  reach,
  isEditable,
}: ISCParticipantScheduledView) => {
  const [isParticipantAddOpen, setIsParticipantAddOpen] = useState<boolean>(false);

  return (
    <>
      {surveyCycle && surveyCycle.participantIds && surveyCycle.participantIds?.length > 0 ? (
        <>
          <SettingsSubsectionContent
            sections={[
              {
                title: 'Participants',
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: 'Participants',
                    value: `${surveyCycle?.participantIds?.length} - participant(s)`,
                  },
                  {
                    type: SectionItemType.Component,
                    value: isEditable ? (
                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="primary"
                        onClick={() => setIsParticipantAddOpen(true)}
                        style={{ marginTop: spacing.m16 }}
                      >
                        Add participants
                      </ButtonComponent>
                    ) : null,
                  },
                ],
              },
            ]}
          />
          <SettingsSubsectionContent
            sections={[
              {
                contentWidth: '100%',
                headerWidth: '100%',
                title: 'Participants part of this cycle',
                items: [
                  {
                    type: SectionItemType.Component,
                    value: (
                      <SCParticipantsSharedTable
                        surveyCycle={surveyCycle}
                        surveyParticipants={surveyParticipants}
                        surveyParticipantsLoading={surveyParticipantsLoading}
                        refresh={refresh}
                        isEditable={isEditable}
                      />
                    ),
                  },
                ],
              },
            ]}
          />
        </>
      ) : (
        <SettingsSubsectionContent
          sections={[
            {
              title: 'Participants',
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: 'Please select the participants for the current survey cycle.',
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <ParticipantsEmptyState
                      setIsOpen={setIsParticipantAddOpen}
                      buttonLabel="Add participants"
                      isEditable={isEditable}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      )}

      <SCParticipantAddModal
        surveyCycle={surveyCycle}
        isOpen={isParticipantAddOpen}
        setIsOpen={setIsParticipantAddOpen}
        onClose={() => setIsParticipantAddOpen(false)}
        refresh={refresh}
        reach={reach}
      />
    </>
  );
};

const ParticipantsEmptyState = ({
  setIsOpen,
  isEditable,
  buttonLabel,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEditable: boolean;
  buttonLabel?: string;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24, marginTop: spacing.m16 }}>
      <ButtonComponent
        sizeVariant="small"
        colorVariant="primary"
        disabled={!isEditable}
        onClick={() => setIsOpen(true)}
        aria-label="Add Participants"
      >
        {buttonLabel ? buttonLabel : 'Add participants'}
      </ButtonComponent>
    </Box>
  );
};
