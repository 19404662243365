import { ChangeEvent, useState } from 'react';

import { Box, Button } from '@mui/material';

import { TableSearch } from '@/v2/components/table/table-search.component';
import { activeTabCount, activeTabsFilterBtnSx, tabCountSx, tabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type TabFilterItem<T = string> = {
  name: string;
  value: T;
  count?: number;
  disabled?: boolean;
};

export type TabFilterState<T> = {
  filterValue: T;
  searchInput: string;
};

type TabFilterProps<T extends string> = {
  filters: readonly TabFilterItem<T>[];
  hasSearch?: boolean;
  setFilterValue: (value: T) => void;
  filterValue: T;
  onFilterChange?(value: TabFilterState<T>): void;
  showCount?: boolean;
  disabled?: boolean;
  readonly handleBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function TabFilterButtons<T extends string>({
  filters,
  hasSearch,
  setFilterValue,
  filterValue,
  onFilterChange,
  showCount,
  disabled,
  handleBlur,
}: TabFilterProps<T>) {
  const [searchInput, setSearchInput] = useState<string>('');

  return (
    <Box
      sx={{
        display: 'flex',
        gap: spacing.m5,
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {filters.map((tab) => (
        <Button
          key={tab.value}
          sx={{
            ...(tab.value === filterValue ? activeTabsFilterBtnSx : tabsFilterBtnSx),
            display: 'flex',
            gap: spacing.g5,
          }}
          onClick={() => {
            setFilterValue(tab.value);
            onFilterChange?.({
              filterValue: tab.value,
              searchInput: searchInput,
            });
          }}
          disableRipple
          disabled={disabled || tab.disabled}
        >
          {tab.name}{' '}
          {showCount && tab.count !== undefined && tab.count > 0 ? (
            <Box sx={{ ...(tab.value === filterValue ? activeTabCount : tabCountSx) }}>{tab.count}</Box>
          ) : undefined}
        </Button>
      ))}

      {hasSearch && (
        <TableSearch
          disabled={disabled}
          query={searchInput}
          handleChange={(e) => {
            const newSearchInput = e.target.value;
            setSearchInput(newSearchInput);
            onFilterChange?.({
              filterValue,
              searchInput: newSearchInput,
            });
          }}
          handleBlur={handleBlur}
        />
      )}
    </Box>
  );
}
