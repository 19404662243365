import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReportConfigBackButton } from '@v2/feature/reports/reports-advanced/components/report-config-back-button.component';
import { FilterValueSelector } from '@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component';
import { operatorOptionsByType } from '@v2/feature/reports/reports-test.util';
import {
  ReportFilterCategory,
  ReportFilterSelected,
  ReportSQLOperator,
  SelectedFiltersRequest,
} from '@v2/feature/reports/reports.interface';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

interface SectionProps {
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFiltersRequest>>;
  readonly reportFilters: readonly ReportFilterCategory[];
  readonly goBack: () => void;
  readonly filter: ReportFilterSelected | null;
}

export const FilterEditSection = ({ setSelectedFilters, reportFilters, filter, goBack }: SectionProps) => {
  const [selectedOperator, setSelectedOperator] = useState<ReportSQLOperator>(filter?.op ?? ReportSQLOperator.eq);
  const [value, setValue] = useState<string | number | string[] | number[]>(filter?.value ?? '');

  const filterObj = useMemo(() => {
    if (!filter) return null;
    const fObj = reportFilters.find((f) => f.stub === filter.stub);
    if (!fObj) return null;

    return fObj.filters[filter.filterKey] ?? null;
  }, [filter, reportFilters]);

  useEffect(() => {
    if (filterObj?.type === 'boolean') setSelectedOperator(ReportSQLOperator.is);
  }, [filterObj]);

  const operatorOptions = useMemo(() => {
    if (!filterObj?.type) return [];

    return operatorOptionsByType(filterObj.type);
  }, [filterObj]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', height: '100%' }}>
      <ReportConfigBackButton title="Filters" goBack={goBack} />

      <Typography variant="title4">
        {filterObj ? filterObj.label ?? 'Selected filter' : 'No filter selected'}
      </Typography>

      <SelectComponent
        options={operatorOptions}
        value={selectedOperator}
        name="operator"
        label="Operator"
        onChange={(e) => setSelectedOperator(e.target.value)}
      />

      {filterObj && (
        <FilterValueSelector
          label="Value"
          filterType={filterObj.type}
          filterOptions={filterObj.options}
          operator={selectedOperator}
          value={value}
          setValue={setValue}
        />
      )}

      <Box sx={buttonBoxDrawerSx}>
        {filter && (
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            onClick={() => {
              setSelectedFilters((prev) => {
                const newFiltersSet = { ...prev };
                if (!newFiltersSet[filter.stub]) newFiltersSet[filter.stub] = { [filter.filterKey]: [] };
                if (!newFiltersSet[filter.stub][filter.filterKey]) newFiltersSet[filter.stub][filter.filterKey] = [];

                const filterToBeAdded = { op: selectedOperator, value: value };

                if (filter.type === 'new') {
                  newFiltersSet[filter.stub][filter.filterKey].push(filterToBeAdded);
                } else {
                  const oldFilterIndex = newFiltersSet[filter.stub][filter.filterKey].findIndex((f) => {
                    return f.op === filter.op && f.value === filter.value;
                  });
                  if (oldFilterIndex >= 0)
                    newFiltersSet[filter.stub][filter.filterKey][oldFilterIndex] = filterToBeAdded;
                }
                return newFiltersSet;
              });
              goBack();
            }}
            fullWidth
          >
            Apply
          </ButtonComponent>
        )}
      </Box>
    </Box>
  );
};
