import { Fragment, useMemo } from 'react';

import { Box } from '@mui/material';
import { isBefore, isToday } from 'date-fns';

import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { UserCalendarView } from '@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-calendar-view.component';
import '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserCalendarVersionbigloader = () => {
  const today = new LocalDate().getDate();
  const weekDaysFromMonToSun = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const calendarData = useMemo(() => {
    const getDaysInMonth = (year: any, month: any) => {
      return new Date(year, month + 1, 0).getDate();
    };

    const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth());
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
    const result = [];
    let week = [];

    // Calculate the index of the first day of the week (Monday = 1, Sunday = 0)
    const startDayIndex = firstDay === 0 ? 6 : firstDay - 1;
    // Add empty cells for days before the first day of the month
    for (let day = 0; day < startDayIndex; day++) {
      week.push(<td key={`empty-${day}`} className="cw-table-data" />);
    }
    // Generate calendar cells for each day in the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new LocalDate(new Date(today.getFullYear(), today.getMonth(), day)).getDate();
      const isBeforeToday = isBefore(currentDate, today);
      week.push(
        <td key={`day-${day}`} className="cw-table-data">
          <Box
            key={`${day}-${day}-day`}
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="title4"
              sx={{
                color: isToday(currentDate)
                  ? themeColors.white
                  : isBeforeToday
                  ? themeColors.Grey
                  : themeColors.DarkGrey,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: isToday(currentDate) ? themeColors.DarkGrey : 'transparent',
                borderRadius: radius.br20,
                width: '25px',
                height: '25px',
              }}
            >
              {day}
            </Typography>
          </Box>
        </td>
      );

      // Start a new row for each Sunday (index 0)
      if ((startDayIndex + day) % 7 === 0) {
        result.push(<tr key={`week-${result.length}`}>{week}</tr>);
        week = [];
      }
    }
    // After the loop, if there are still days in week, push them into result
    if (week.length > 0) {
      result.push(<tr key={`week-${result.length}`}>{week}</tr>);
    }
    return result;
  }, [today]);

  return (
    <WidgetLayout readOnly={false} size="big" isLoadingState={true}>
      <Fragment>
        <div
          className="cs-calendar-grid"
          style={{
            pointerEvents: 'none',
          }}
        >
          <UserCalendarView
            calendarData={calendarData}
            weekDaysFromMonToSun={weekDaysFromMonToSun}
            headerAction={() => {}}
          />
          <div style={{ height: '100%', overflow: 'hidden' }}>
            <div className="cw-event-list">
              <Typography variant="title4">Upcoming</Typography>
            </div>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                gap: spacing.g25,
                marginTop: spacing.m20,
              }}
            >
              {[0, 1, 2, 3, 4].map((num) => (
                <div className="ucw-loader-container" key={`${num}-ucvb`}>
                  <div className="ucw-progress" />
                </div>
              ))}
            </Box>
          </div>
        </div>
      </Fragment>
    </WidgetLayout>
  );
};
