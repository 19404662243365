import { FilteredPayrollData } from '@shared/modules/payroll/payroll.types';
import {
  PayRunPayslipsDto,
  PensionContributionStatusDto,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.dto';
import { PayPeriod } from '@v2/feature/payroll/payroll-external.interface';
import {
  PayrunAccountingDto,
  PayrunDetailsDto,
  PayRunDto,
  PayRunEntryDto,
  PayrunExternalAccountingDataDto,
  PayrunFPSDto,
  PayrunPaymentsDto,
  UserPayslipSummaryDto,
} from '@v2/feature/payroll/payroll.dto';
import axios from 'axios';

import { ExternalCustomPayCode } from '@/v2/feature/payroll/payroll-external.dto';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class PayrollLocalEndpoints {
  static getUserPayrollMembershipList(): Endpoint<FilteredPayrollData> {
    return {
      url: `/apiv2/users/payroll/list`,
    };
  }

  static getPayrunPaymentToPayrollIdMapping(): Endpoint<
    Record<
      number,
      {
        paymentId: number;
        payroll?: {
          id: number;
          entityId: number;
          entity: { legalName: string | null };
        };
      }
    >
  > {
    return {
      url: '/apiv2/payroll/payruns/payrun-payments-to-payroll-mapping',
    };
  }

  static getPayrunPayCodes(payrunId: number): Endpoint<ExternalCustomPayCode[]> {
    return { url: `/apiv2/payroll/payruns/${payrunId}/paycodes` };
  }

  static getPayrunDetails(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Endpoint<PayrunDetailsDto> {
    return {
      url: `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/local/detail?payrollId=${payrollId}`,
    };
  }

  static getPayslipsForUser(userId: number): Endpoint<UserPayslipSummaryDto[]> {
    return { url: `/apiv2/users/${userId}/payslips` };
  }
}

export class PayrollLocalApi {
  static async getLocalPayRun(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<PayRunDto> {
    return (
      await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/local?payrollId=${payrollId}`)
    ).data;
  }

  static async findLocalPayRunEntries(
    payrollId: number,
    taxYear: string,
    payPeriod: PayPeriod,
    periodNumber: number
  ): Promise<PayRunEntryDto[]> {
    return (
      await axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/local/entries?payrollId=${payrollId}`
      )
    ).data;
  }

  /**
   * Fetch the list of saved payrun entries for a user
   */
  static async getAllLocalPayRunEntriesForUser(userId: number): Promise<PayRunEntryDto[]> {
    return (await axios.get(`/apiv2/payroll/payruns/${userId}/entries/local`)).data;
  }

  static async getPayrunPayslipsDetails(payrunId: number): Promise<PayRunPayslipsDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/payslips`)).data;
  }

  static async getPayrunAccountingRecord(payrunId: number): Promise<PayrunAccountingDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/accounting`)).data;
  }

  static async getPayrunExternalAccountingData(payrunId: number): Promise<PayrunExternalAccountingDataDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/accounting/external-journal-body`)).data;
  }

  static async getPayrunExternalAccountingDataByPeriod(
    taxYear: string,
    payPeriod: PayPeriod,
    period: number
  ): Promise<PayrunExternalAccountingDataDto> {
    return (
      await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/accounting/external-journal-body`)
    ).data;
  }

  static async getPayrunPaymentsRecord(payrunId: number): Promise<PayrunPaymentsDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/payments`)).data;
  }

  static async createPayrunBankPaymentsForPayrun(payrunId: number): Promise<PayrunPaymentsDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payrunId}/payments`)).data;
  }

  static async markPayrunPaymentsAsComplete(payrunId: number): Promise<PayrunPaymentsDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payrunId}/payments/mark-as-complete`)).data;
  }

  static async submitAccountingJournal(payrunId: number): Promise<PayrunAccountingDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payrunId}/accounting`)).data;
  }

  static async markAccountingJournalAsSent(payrunId: number): Promise<PayrunAccountingDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payrunId}/accounting/mark-as-sent`)).data;
  }

  static async getPensionContributionStatusForPayrun(
    payrunId: number,
    pensionSchemeId: number
  ): Promise<PensionContributionStatusDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/pension/${pensionSchemeId}/contribution-status`)).data;
  }

  static async markPensionContributionAsSent(
    payrunId: number,
    pensionSchemeId: number
  ): Promise<PensionContributionStatusDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payrunId}/pension/${pensionSchemeId}/mark-as-sent`)).data;
  }

  static async createPayslips(payrunId: number): Promise<PayRunPayslipsDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payrunId}/payslips`)).data;
  }

  static async reopenPayRun(payrollId: number, taxYear: string, payPeriod: string, period: number): Promise<void> {
    await axios.post(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/reopen?payrollId=${payrollId}`);
  }

  static async deletePayRun(payrollId: number, taxYear: string, payPeriod: string, period: number): Promise<void> {
    await axios.delete(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}?payrollId=${payrollId}`);
  }

  static async getCompanyPayRuns(): Promise<PayRunDto[]> {
    return (await axios.get(`/apiv2/payroll/payruns`)).data;
  }

  static async completePayrun(payrunId: number): Promise<void> {
    await axios.post(`/apiv2/payroll/payruns/${payrunId}/complete`);
  }

  static async getRefreshedPayRun(payrunId: number): Promise<PayRunDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payrunId}/refresh`)).data;
  }

  static async fetchFPS(payRunId: number): Promise<PayrunFPSDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payRunId}/fps-submission`)).data;
  }

  static async deletePayroll(payrollId: number): Promise<void> {
    await axios.delete(`/apiv2/company/payroll?payrollId=${payrollId}`);
  }

  static async getPayRunsAsSuperAdmin(companyId: number, payrollId: number): Promise<PayRunDto[]> {
    return (await axios.get(`/apiv2/payroll/payruns/superadmin/${companyId}?payrollId=${payrollId}`)).data;
  }

  static async getLocalPayRunByIdAsSuperAdmin(companyId: number, payrunId: number): Promise<PayRunDto> {
    return (await axios.get(`/apiv2/payroll/payruns/superadmin/${companyId}/${payrunId}`)).data;
  }
}
