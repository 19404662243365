import { Box, Typography } from '@mui/material';

import { CopyableTextField } from '@/v2/components/forms/copyable-text-field.component';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  appDetails: InstalledAppDto | undefined;
  companyId: number;
}

const APPS_NEEDING_COMPANY_ID_FOR_WEBHOOK_CONFIG = ['teamtailor'];

export const AppAutomaticOnboardingViaWebhook = ({ appDetails, companyId }: Props): JSX.Element => {
  return (
    <Box sx={{ width: '550px', mt: spacing.m60 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Webhook</Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
          {`Create a Webhook for ${appDetails?.name} to automatically onboard new hires in Zelt`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m10 }}>
        {appDetails &&
          appDetails.credentialsPlaceholders &&
          'webhookEndpoint' in appDetails?.credentialsPlaceholders && (
            <CopyableTextField
              label={'Endpoint URL'}
              value={String(appDetails.credentialsPlaceholders['webhookEndpoint'])}
            />
          )}

        {appDetails && appDetails.credentialsPlaceholders && 'apikey' in appDetails?.credentialsPlaceholders && (
          <CopyableTextField label={'Secret Key'} value={String(appDetails.credentialsPlaceholders['apikey'])} mask />
        )}
        {appDetails && APPS_NEEDING_COMPANY_ID_FOR_WEBHOOK_CONFIG.includes(appDetails.stub) && (
          <CopyableTextField label={'Company ID'} value={String(companyId)} />
        )}
      </Box>
    </Box>
  );
};
