import { useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { StyledRadio } from '@v2/styles/radio.styles';

import useMessage from '@/hooks/notification.hook';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewSectionBank } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const MoveToSectionContent = ({
  onClose,
  action,
  sections,
  questionId,
}: {
  onClose: () => void;
  action: (sectionId: string, questionId: string) => Promise<void>;
  sections: Pick<ReviewSectionBank, 'name' | 'id'>[] | undefined;
  questionId: string | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSave = async () => {
    try {
      if (selectedSectionId && questionId) {
        setLoading(true);
        await action(selectedSectionId, questionId);
        onClose();
        showMessage('Moved in to section successfully', 'success');
      } else {
        showMessage('Please select a section first', 'error');
      }
    } catch (error) {
      showMessage('Something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="title2">Select a section</Typography>
        <Box sx={{ mt: spacing.m24 }}>
          {sections && sections.length > 0 ? (
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                name="section-select"
                onChange={(event) => {
                  setSelectedSectionId(event.target.value);
                }}
              >
                {sections.map((section) => (
                  <FormControlLabel
                    key={section.id}
                    labelPlacement="end"
                    value={section.id}
                    checked={selectedSectionId === section.id}
                    control={<StyledRadio />}
                    label={
                      <Box>
                        <Typography variant="caption">{section.name}</Typography>
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <Typography variant="caption">You dont have any section</Typography>
          )}
        </Box>
      </Box>

      <Box>
        <LoaderButton
          sizeVariant="medium"
          colorVariant="primary"
          name={polyglot.t('General.save')}
          type="button"
          onClick={onSave}
          loading={loading}
          fullWidth
        />
      </Box>
    </Box>
  );
};
