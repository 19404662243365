import { useContext, useEffect, useRef, useState } from 'react';

import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { downloadFileByUrl, isPreviewableFile } from '@v2/feature/documents/documents.util';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as PinnedDoc } from '@/images/documents/PinnedBlack.svg';
import { ReactComponent as MissingDoc } from '@/images/documents/PinnedMissingDoc.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { checkScopes } from '@/lib/scopes';
import { ScopeContext } from '@/models';
import { NavigationButtons } from '@/v2/components/navigation-buttons.component';
import { StyledMenu } from '@/v2/components/theme-components/styled-menu.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';
import 'swiper/swiper-bundle.css';

export interface OptionProps {
  icon?: JSX.Element;
  handler: () => void;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
}

const SwiperHeader = ({ children, hideNavigation }: { children: string; hideNavigation: boolean }) => {
  const swiper = useSwiper();
  return (
    <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', ...spacing.mb20 }}>
      <Typography sx={{ ...themeFonts.title2, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}>
        {children}
      </Typography>
      {!hideNavigation && <NavigationButtons onNext={() => swiper.slideNext()} onBack={() => swiper.slidePrev()} />}
    </Stack>
  );
};
const PinnedCard = ({
  pinnedDocument,
  onEditClick,
  onPreviewClick,
  documentTypes,
  personalDocuments,
}: {
  pinnedDocument: ZeltDocument;
  readonly onEditClick: (_: ZeltDocument) => void;
  readonly onPreviewClick: (_: ZeltDocument) => void;
  readonly documentTypes: readonly Pick<
    ZeltDocumentType,
    'value' | 'label' | 'needsVerification' | 'canUserAdd' | 'category'
  >[];
  personalDocuments: boolean;
}) => {
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext(state.user);
  const reporteeScopeContext: ScopeContext = {
    userId:
      pinnedDocument.belongsTo && pinnedDocument.belongsTo?.length === 1
        ? pinnedDocument.belongsTo[0]?.User?.userId
        : 0,
  };
  const belongsToOnlyYou =
    pinnedDocument.belongsTo &&
    pinnedDocument.belongsTo.length === 1 &&
    pinnedDocument.belongsTo[0].User.userId === state.user.userId;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const attachmentExists =
    (pinnedDocument.attachments && pinnedDocument.attachments.length && pinnedDocument.attachments[0]?.fileUuid) ||
    pinnedDocument.fileUuid;
  const stdUserCan = state.user.userId === pinnedDocument.uploadedBy || belongsToOnlyYou;
  const hasScopesAll = hasScopes(['documents:all'], scopesContext);
  const hasScopesManager = checkScopes(state.user, ['documents'], scopesContext);
  const categoriesNotEditableByManager = ['Company Documents'];
  const belongsToNone = (pinnedDocument.belongsTo ?? []).length === 0;
  const documentCategoriesEditableByManager = documentTypes
    .filter((d) => !categoriesNotEditableByManager.includes(d.category))
    .map((d) => d.value);
  const documentCategoriesEditableByStandardUser = documentTypes
    .filter((eachDocumentType) => eachDocumentType.canUserAdd)
    .map((d) => d.value);

  const isEditableOrRemovableWithoutAttactment =
    hasScopesAll ||
    (!personalDocuments &&
      hasScopesManager &&
      [...documentCategoriesEditableByManager].includes(pinnedDocument.type) &&
      !belongsToNone) ||
    (documentCategoriesEditableByStandardUser.includes(pinnedDocument.type) && belongsToOnlyYou);

  const isEditableOrRemovableWithAttactment =
    (attachmentExists && !pinnedDocument.contractId && hasScopes(['documents:all'], scopesContext)) ||
    stdUserCan ||
    hasScopes(['documents:manager'], reporteeScopeContext);

  const options: OptionProps[] = [
    ...(attachmentExists
      ? [
          {
            icon: <Download {...iconSize} />,
            handler: async () => {
              if (pinnedDocument?.fileUuid && typeof pinnedDocument.fileUuid === 'string') {
                await DocumentAPI.downloadViaUuid(pinnedDocument?.fileUuid).then((url: string) => {
                  downloadFileByUrl(url, pinnedDocument.fileName);
                });
              }
            },
            label: 'Download',
          },
        ]
      : []),
    ...((!attachmentExists && isEditableOrRemovableWithoutAttactment) ||
    (attachmentExists && isEditableOrRemovableWithAttactment)
      ? [
          {
            icon: <EditIcon {...iconSize} />,
            handler: () => {
              onEditClick(pinnedDocument);
            },
            label: 'Edit',
          },
        ]
      : []),
    ...(attachmentExists && isPreviewableFile(pinnedDocument)
      ? [
          {
            icon: <Eye {...iconSize} />,
            handler: () => {
              onPreviewClick(pinnedDocument);
            },
            label: 'Preview',
          },
        ]
      : []),
  ];
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: spacing.g10,
          cursor: 'pointer',
          borderRadius: '10px',
          ...themeFonts.title2,
          color: themeColors.DarkGrey,
          backgroundColor: themeColors.Background,
          '&:hover': {
            color: themeColors.BrownHover,
          },
        }}
        onClick={(event) => {
          if (options.length > 0) {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
          }
        }}
      >
        <Box
          sx={{
            width: '16px',
            height: '16px',
            mt: spacing.mt15,
            mb: spacing.mb15,
            ml: spacing.ml20,
          }}
        >
          {attachmentExists ? (
            <PinnedDoc
              style={{
                width: 'max-content',
                height: '100%',
              }}
            />
          ) : (
            <MissingDoc
              style={{
                width: 'max-content',
                height: '100%',
              }}
            />
          )}
        </Box>
        <Typography
          sx={{
            ...themeFonts.title4,
            whiteSpace: 'nowrap',
            color: themeColors.DarkGrey,
            mt: spacing.mt20,
            mb: spacing.mb15,
          }}
        >
          {truncateWithEllipses(pinnedDocument.name, 20)}
        </Typography>
      </Box>

      <StyledMenu
        id={`btnMenuOptions`}
        MenuListProps={{
          'aria-labelledby': 'btnMenuOptions',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {options &&
          options
            .filter((option) => !option.hidden)
            .map((option, i) => (
              <MenuItem
                key={option.label}
                id={`btn${option.label}_${i}`}
                onClick={(e) => {
                  option.handler();
                  setAnchorEl(null);
                  e.stopPropagation();
                }}
                disableRipple
                disabled={option?.disabled || false}
                sx={{ marginTop: i > 0 ? '10px' : 0 }}
              >
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
      </StyledMenu>
    </>
  );
};
export const PinnedDocument = ({
  pinnedDocuments,
  onEditClick,
  onPreviewClick,
  documentTypes,
  personalDocuments = false,
}: {
  pinnedDocuments: readonly ZeltDocument[];
  readonly onEditClick: (_: ZeltDocument) => void;
  readonly onPreviewClick: (_: ZeltDocument) => void;
  readonly documentTypes: readonly Pick<
    ZeltDocumentType,
    'value' | 'label' | 'needsVerification' | 'canUserAdd' | 'category'
  >[];
  readonly personalDocuments?: boolean;
}) => {
  const swiperRef = useRef<any>(null);
  const [hideNavigation, setHideNavigation] = useState(false);

  useEffect(() => {
    const swiperInstance = swiperRef.current;

    if (swiperInstance) {
      const checkOverflow = () => {
        setHideNavigation(swiperInstance.isEnd);
      };

      swiperInstance.on('resize', checkOverflow);
      checkOverflow();

      return () => {
        swiperInstance.off('resize', checkOverflow);
      };
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Swiper
        ref={swiperRef}
        freeMode={true}
        modules={[FreeMode]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          361: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          390: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          481: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1500: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1900: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          2200: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
          2400: {
            slidesPerView: 7,
            spaceBetween: 10,
          },
          2600: {
            slidesPerView: 8,
            spaceBetween: 10,
          },
        }}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        <SwiperHeader hideNavigation={hideNavigation}>{''}</SwiperHeader>

        {pinnedDocuments.map((pinnedDocument, index) => (
          <SwiperSlide key={index}>
            <PinnedCard
              pinnedDocument={pinnedDocument}
              onEditClick={onEditClick}
              onPreviewClick={onPreviewClick}
              documentTypes={documentTypes}
              personalDocuments={personalDocuments}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
