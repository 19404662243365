import { getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { isSameDay } from 'date-fns';
import { Dictionary } from 'lodash';

import {
  AttendanceDto,
  AttendanceEntryDto,
  AttendanceScheduleDto,
  AttendanceTypeDto,
} from '@/v2/feature/attendance/attendance.dto';
import { WeekDay } from '@/v2/feature/attendance/attendance.interface';

export const getWeekRangeString = (weekStart: Date): string => {
  const mondayDate = new Date(weekStart);
  const sundayDate = new Date(weekStart);
  sundayDate.setDate(sundayDate.getDate() + 6);

  const monday = mondayDate.toLocaleDateString(undefined, { day: '2-digit', month: 'short' });
  const sunday = sundayDate.toLocaleDateString(undefined, { day: '2-digit', month: 'short' });

  return `${monday} - ${sunday}`;
};

export const getMondayOfCurrentWeek = (): Date => {
  const date = new Date();
  const day = date.getDay(),
    diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  date.setDate(diff);

  return date;
};

export const getWorkTimeFromScheduleByDay = (
  attendanceSchedule: AttendanceScheduleDto,
  day: WeekDay
): string | null => {
  const dayEntries = attendanceSchedule[day];
  if (!dayEntries || !dayEntries[0]) return null;

  const dayEntry = dayEntries[0];
  return `${dayEntry.from.slice(11, 16)} - ${dayEntry.to.slice(11, 16)}`;
};

export const getWorkTimeRangeFromScheduleByDay = (
  attendanceSchedule: AttendanceScheduleDto,
  day: WeekDay,
  date: string
): [string, string] | null => {
  const dayEntries = attendanceSchedule[day];
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    date,
    attendanceSchedule.noOfWeeks
  );
  if (!dayEntries || !dayEntries[weekIndex]) return null;

  const dayEntry = dayEntries[weekIndex]!;
  return [dayEntry.from.slice(11, 16), dayEntry.to.slice(11, 16)];
};

export const getBreakScheduleByDay = (
  attendanceSchedule: AttendanceScheduleDto,
  day: WeekDay,
  date: string,
  timeOnly = false
): string | null => {
  const dayEntries = attendanceSchedule[day];
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    date,
    attendanceSchedule.noOfWeeks
  );

  if (!dayEntries || !dayEntries[weekIndex]?.break) return null;

  const dayEntry = dayEntries[weekIndex]!;
  const [h, m] = dayEntry.break.slice(11, 16).split(':');
  const hours = Number(h);
  const minutes = Number(m);

  if (hours && !minutes) return timeOnly ? `${hours}h` : `${hours}h break`;

  if (!hours && minutes) return timeOnly ? `${minutes}min` : `${minutes}min break`;

  return timeOnly ? `${hours}h${minutes}min` : `${hours}h${minutes}min break`;
};

export const getAttendanceEntry = (
  currentWeekAttendance: AttendanceDto[],
  currentDate: Date
): AttendanceDto | undefined => {
  if (currentWeekAttendance && currentWeekAttendance.length === 0) return undefined;
  return currentWeekAttendance.find(
    (a) => isSameDay(currentDate, new Date(a.logDate)) && a.attendanceEntries.length > 0
  );
};

type LoggedHourByType = {
  [typeName: string]: number;
};

export const calculateLoggedHourByType = (
  attendanceEntriesLookup: Dictionary<AttendanceEntryDto[]>,
  attendanceTypes: AttendanceTypeDto[]
): LoggedHourByType => {
  const result: LoggedHourByType = {};

  for (const type of attendanceTypes) {
    result[type.name] = 0;
  }

  for (const typeId in attendanceEntriesLookup) {
    if (Object.prototype.hasOwnProperty.call(attendanceEntriesLookup, typeId)) {
      const entries = attendanceEntriesLookup[typeId];
      // eslint-disable-next-line unicorn/no-array-reduce
      const totalLength = entries.reduce((accumulator, current) => accumulator + current.length, 0);

      const typeName = attendanceTypes.find((type) => type.id === Number(typeId))?.name;
      if (typeName) {
        result[typeName] = totalLength;
      }
    }
  }

  return result;
};

export const calculateSummary = (
  attendanceEntriesLookup: AttendanceEntryDto[],
  attendanceTypes: AttendanceTypeDto[],
  date: Date
) => {
  // Initialize result object
  let result = {
    break: 0,
    regular: ['', ''],
    startIsTomorrow: false, // sort this for case when they are late to a shift
    endIsTomorrow: false,
  };

  // Filter and sum all the 'break' type entries
  attendanceEntriesLookup.forEach((entry) => {
    const type = attendanceTypes.find((type) => type.id === entry.typeId);
    if (type && type.name.toLowerCase() === 'break') {
      result.break += entry.length;
    }
  });

  // Filter all the 'Regular' type entries
  const regularEntries = attendanceEntriesLookup.filter((entry) => {
    const type = attendanceTypes.find((type) => type.id === entry.typeId);
    return type && type.name.toLowerCase() === 'regular';
  });

  // Find the earliest startHour and latest endHour for 'Regular' type entries
  if (regularEntries.length > 0 && date) {
    regularEntries.sort((a, b) => new Date(a.startHourTimestamp).getTime() - new Date(b.startHourTimestamp).getTime());

    const first = regularEntries[0];
    result.regular[0] = first.startHour.slice(11, 16);
    const last = regularEntries[regularEntries.length - 1];
    result.regular[1] = last.endHour.slice(11, 16);

    if (first?.startHour && new Date(first.startHour).getDate() !== date.getDate()) result.startIsTomorrow = true;

    if (last?.endHour && new Date(last.endHour).getDate() !== date.getDate()) result.endIsTomorrow = true;
  }

  return result;
};

export const getDayWithDate = (weekDate: Date, day: WeekDay): string => {
  const date = new Date(weekDate);
  switch (day) {
    case 'monday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -6 : 1); // adjust when day is sunday
      date.setDate(diff);
      return `Mon ${date.getDate()}`;
    }

    case 'tuesday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -5 : 2); // adjust when day is sunday
      date.setDate(diff);
      return `Tue ${date.getDate()}`;
    }

    case 'wednesday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -4 : 3); // adjust when day is sunday
      date.setDate(diff);
      return `Wed ${date.getDate()}`;
    }

    case 'thursday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -3 : 4); // adjust when day is sunday
      date.setDate(diff);
      return `Thu ${date.getDate()}`;
    }

    case 'friday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -2 : 5); // adjust when day is sunday
      date.setDate(diff);
      return `Fri ${date.getDate()}`;
    }

    case 'saturday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -1 : 6); // adjust when day is sunday
      date.setDate(diff);
      return `Sat ${date.getDate()}`;
    }

    case 'sunday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? 0 : 7); // adjust when day is sunday
      date.setDate(diff);
      return `Sun ${date.getDate()}`;
    }
    default: {
      return day;
    }
  }
};

export const getDayWithDateArray = (weekDate: Date, day: WeekDay): [string, number] => {
  const date = new Date(weekDate);
  switch (day) {
    case 'monday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -6 : 1); // adjust when day is sunday
      date.setDate(diff);
      return ['Mon', date.getDate()];
    }

    case 'tuesday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -5 : 2); // adjust when day is sunday
      date.setDate(diff);
      return ['Tue', date.getDate()];
    }

    case 'wednesday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -4 : 3); // adjust when day is sunday
      date.setDate(diff);
      return ['Wed', date.getDate()];
    }

    case 'thursday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -3 : 4); // adjust when day is sunday
      date.setDate(diff);
      return ['Thu', date.getDate()];
    }

    case 'friday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -2 : 5); // adjust when day is sunday
      date.setDate(diff);
      return ['Fri', date.getDate()];
    }

    case 'saturday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? -1 : 6); // adjust when day is sunday
      date.setDate(diff);
      return ['Sat', date.getDate()];
    }

    case 'sunday': {
      const d = date.getDay(),
        diff = date.getDate() - d + (d === 0 ? 0 : 7); // adjust when day is sunday
      date.setDate(diff);
      return ['Sun', date.getDate()];
    }
    default: {
      return day;
    }
  }
};

const formatTime = (time: number) => {
  const hours = Math.floor(time);
  const minutes = (time - hours) * 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = Math.round(minutes).toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const calculateHourDifference = (start: string, end: string, breakTime: string | null) => {
  const startTime = new Date(`2000-01-01T${start}:00`);
  const endTime = new Date(`2000-01-01T${end}:00`);

  // Calculate the difference in milliseconds between the two times
  const timeDifferenceInMillis = endTime.getTime() - startTime.getTime();

  // Convert milliseconds to hours
  const hourDifference = timeDifferenceInMillis / (1000 * 60 * 60);

  const totalHours = breakTime ? hourDifference - 1 : hourDifference;
  return formatTime(totalHours);
};
