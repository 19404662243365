import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import {
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_INVOICES_ROUTE,
  SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE,
  SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE,
  SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE,
} from '@/lib/routes';
import { PaymentSettingsApprovalsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings-approvals.page';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { PaymentTypeAccountingSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-accounting-settings.page';
import { PaymentTypeGeneralSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-general-settings.page';
import { PaymentTypeMembersSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-members-settings.page';

export const getPageConfig = (id: number, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE, { id }),
        },
        {
          title: polyglot.t('PaymentSettingsPage.accountingHeader'),
          stub: 'accounting',
          path: generatePath(SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE, {
            id,
          }),
        },
        {
          title: polyglot.t('General.members'),
          stub: 'provider',
          path: generatePath(SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE, { id }),
        },
        {
          title: polyglot.t('PaymentSettingsPage.approvalsTitle'),
          stub: 'approval',
          path: generatePath(SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE, { id }),
        },
      ],
    },
  ] as NavConfigItem[];
};

interface InvoiceSettingDetailRouterProps {
  readonly id: number;
}

export const InvoiceSettingDetailRouter = ({ id }: InvoiceSettingDetailRouterProps) => {
  const { data: invoiceSetting, mutate: refresh } = useApiClient(PaymentTypeSettingsEndpoints.getTypeSettingById(id));
  const { mutate: refreshAllSettings } = useApiClient(
    PaymentTypeSettingsEndpoints.getPaymentTypeSettingsForCompanyId()
  );

  const { polyglot } = usePolyglot();

  return (
    <>
      <DomainSideMenuContent
        title={invoiceSetting?.name ?? polyglot.t('PaymentSettingsPage.invoiceType')}
        subtitle={polyglot.t('PaymentSettingsPage.invoiceType')}
        pageConfig={getPageConfig(id, polyglot)}
        backPath={SETTINGS_MONEY_INVOICES_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper>
        {invoiceSetting && (
          <Switch>
            <Route exact path={SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE}>
              {refreshAllSettings && (
                <PaymentTypeGeneralSettingsPage
                  paymentTypeSetting={invoiceSetting}
                  refresh={refresh}
                  refreshAllPaymentSettings={refreshAllSettings}
                />
              )}
            </Route>
            {/* 
            <Route exact path={SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE}>
              <PensionSettingsProviderPage pensionScheme={pensionScheme} refreshPension={refreshPension} />
            </Route>

            <Route exact path={SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE}>
              <PensionSettingsWorkerGroupsPage pensionScheme={pensionScheme} refreshPension={refreshPension} />
            </Route> */}

            <Route exact path={SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE}>
              <PaymentTypeMembersSettingsPage />
            </Route>

            <Route exact path={SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE}>
              <PaymentSettingsApprovalsPage paymentTypeSetting={invoiceSetting} refreshPaymentTypeSetting={refresh} />
            </Route>

            <Route exact path={SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE}>
              <PaymentTypeAccountingSettingsPage paymentTypeSetting={invoiceSetting} refresh={refresh} />
            </Route>

            <Redirect to={SETTINGS_MONEY_INVOICES_ROUTE} />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
};
