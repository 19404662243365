import {
  BillingCurrency,
  VatSetting,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';

export function getVatTaxAmount(currency: BillingCurrency, vatSetting: VatSetting): number {
  const vatMapping = {
    [VatSetting.REVERSE_CHARGE]: 0,
    [VatSetting.NO_VAT]: 0,
    [VatSetting.PERCENTAGE_20]: 0.2,
  };

  if (currency === BillingCurrency.USD) return vatMapping[VatSetting.NO_VAT];

  return vatMapping[vatSetting] ?? 0;
}
