import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { FilterTypesProps } from '@v2/components/table/category-filters.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { ReportCustomFields, ReportEntity, UpdateReportOld } from '@v2/feature/reports/reports.interface';
import {
  getAllColumnsOptionsPeopleCurrentReports,
  getAllUsersCurrentRecordsEntitiesNames,
  getOnlyAllowedColumnsStringsPeopleCurrentReports,
  getPeopleReportFilters,
} from '@v2/feature/reports/util/people-report.util';
import { exportCurrentReportCSV, extractAllColumnsFromColumnsConfig } from '@v2/feature/reports/util/report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { primarySmallBtn, secondarySmallBtn } from '@v2/styles/buttons.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';

export const PeopleCurrentUpdateReport = ({
  report,
  loading,
  customFields,
  additionalColumns,
}: {
  report: ReportDto;
  loading: boolean;
  customFields: ReportCustomFields;
  additionalColumns: {
    label: string;
    value: string;
    entity: ReportEntity;
  }[];
}) => {
  const { polyglot } = usePolyglot();

  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false,
  });

  const { hasScopes } = useScopes();
  const [filteredData, setFilteredData] = useState<Record<string, string | number>[] | undefined>(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );

  const [filterTypes, setFilterTypes] = useState<FilterTypesProps>({});
  const [filterString, setFilterString] = useState<string>(report?.config?.filters ?? '');
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState<boolean>(true);
  const [reportName, setReportName] = useState<string>(report?.name ?? '');
  const [searchInput, setSearchInput] = useState('');

  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState<string[]>([]);
  const [allTheOtherDataIsLoaded, setAllTheOtherDataIsLoaded] = useState<boolean>(false);

  const allCurrentReportEntities = useMemo(() => getAllUsersCurrentRecordsEntitiesNames(hasScopes), [hasScopes]);

  const ColumnsOptions = useMemo(() => {
    const entities = report.config?.entities ?? allCurrentReportEntities;
    return getAllColumnsOptionsPeopleCurrentReports(entities, customFields, hasScopes, additionalColumns, polyglot);
  }, [report.config?.entities, allCurrentReportEntities, customFields, hasScopes, additionalColumns, polyglot]);

  useEffect(() => {
    const options = report.config?.columns
      ? extractAllColumnsFromColumnsConfig(report.config.columns)
      : getOnlyAllowedColumnsStringsPeopleCurrentReports(
          allCurrentReportEntities,
          customFields,
          hasScopes,
          additionalColumns,
          polyglot
        );
    if (!Array.isArray(options)) {
      const arrayOptions = Object.keys(options).reduce((res, key: string) => {
        res.push(options[key] ?? []);
        return res;
      }, [] as string[]);
      setSelectedColumnsStrings(arrayOptions);
      return;
    }

    setSelectedColumnsStrings(options);
    // Allow report data to get generated
    setAllTheOtherDataIsLoaded(true);
  }, [polyglot, additionalColumns, report.config?.columns, customFields, hasScopes, allCurrentReportEntities]);

  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);

  const getReportData = useCallback(
    async (formData: ReportDto, selectedColumns: string[], filterString: string) => {
      setReportsLoading(true);
      try {
        let filteredUsers = await ReportsAPI.generatePeopleCreatedCurrentReport({
          ...formData.config,
          filters: filterString,
          columns: { [ReportEntity.User]: selectedColumns },
        });
        setFilteredData(filteredUsers ?? []);
      } catch (error) {
        showMessage(
          `${polyglot.t('PeopleCurrentUpdateReport.errorMessages.generate')}. ${nestErrorMessage(error)}`,
          'error'
        );
      }
      setReportsLoading(false);
    },
    [polyglot, showMessage]
  );

  useEffect(() => {
    if (report && allTheOtherDataIsLoaded) getReportData(report, selectedColumnsStrings, filterString);
  }, [getReportData, report, selectedColumnsStrings, filterString, allTheOtherDataIsLoaded]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData?.filter(
        (data) =>
          !searchInput ||
          (data.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  }, [searchInput, filteredData]);

  const handleSave = useCallback(async () => {
    try {
      if (report) {
        const paramObj: UpdateReportOld = {
          filters: filterString,
          columns: { [ReportEntity.User]: selectedColumnsStrings },
          fileName: reportName ?? report.name,
        };
        await ReportsAPI.patchReport(report.id, paramObj);
      }
      showMessage(polyglot.t('PeopleCurrentUpdateReport.successMessages.update'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  }, [polyglot, report, reportName, showMessage, routerHistory, filterString, selectedColumnsStrings]);

  const exportCsv = useCallback(() => {
    try {
      exportCurrentReportCSV(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t('PeopleCurrentUpdateReport.errorMessages.download'), 'error');
    }
  }, [polyglot, reportName, filteredData, showMessage]);

  return (
    <RootStyle>
      <TopHeader
        showBack
        title={
          reportName ? (
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={undefined}
            />
          ) : (
            <></>
          )
        }
        showAction
        actions={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <Button fullWidth sx={secondarySmallBtn} startIcon={<Export {...iconSize} />} onClick={exportCsv}>
              {polyglot.t('PeopleCurrentUpdateReport.downloadCsv')}
            </Button>
            <Button sx={primarySmallBtn} onClick={handleSave}>
              {polyglot.t('General.save')}
            </Button>
          </Box>
        }
      />
      <ContentWrapper loading={loading} sx={{ pt: 0 }}>
        <Box sx={{ mt: spacing.m20, display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
          <ColumnsDrawer
            columnsOptions={ColumnsOptions}
            selectedColumns={selectedColumnsStrings}
            setSelectedColumns={setSelectedColumnsStrings}
          />
          <FiltersDrawer
            filtersOptions={[{ filters: filterTypes }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            encodedFilterNames
          />
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
        <Box sx={{ mt: spacing.m20 }}>
          <ResultTableOldReports filteredData={filteredAndSearchedData ?? []} loading={reportLoading} />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
