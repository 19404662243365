import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  EXPENSES_COMPANY_OVERVIEW_ROUTE,
  EXPENSES_COMPANY_ROUTE,
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_COMPANY_NEW_EXPENSE_ROUTE,
} from '@/lib/routes';
import { ExpensePage } from '@/v2/feature/expense/expense.page';
import { NewExpensePage } from '@/v2/feature/payments/pages/new-expense.page';

export const ExpenseCompanyRouter = (): JSX.Element => {
  return (
    <Switch>
      <RouteScopesControl scopes={['expenses:all']} path={EXPENSES_COMPANY_OVERVIEW_ROUTE} exact>
        <ExpensePage reach="company" />
      </RouteScopesControl>
      <RouteScopesControl scopes={['expenses:all']} path={EXPENSE_COMPANY_NEW_EXPENSE_ROUTE} exact>
        <NewExpensePage />
      </RouteScopesControl>
      <RouteScopesControl scopes={['expenses:all']} path={EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE} exact>
        <NewExpensePage editMode={true} />
      </RouteScopesControl>
      <Redirect from={EXPENSES_COMPANY_ROUTE} to={EXPENSES_COMPANY_OVERVIEW_ROUTE} exact />
    </Switch>
  );
};
