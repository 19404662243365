import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { IconButton, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as EditIcon } from '@/images/side-bar-icons/Edit.svg';
import { ReactComponent as DeleteIcon } from '@/images/side-bar-icons/Trash.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { CompanyAddress, CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CompanyEntityDrawerPage } from '@/v2/feature/company/company-settings/features/components/company-settings/entity-details/company-entity-drawer.component';
import { DeleteCompanyEntityDrawer } from '@/v2/feature/company/company-settings/features/components/company-settings/entity-details/delete-company-entity-drawer.component';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

type CompanySettingsEntityTableProps = {
  entities: CompanyUnitDto[];
  refreshEntities?: () => void;
};

export const CompanySettingsEntityTable = ({ entities, refreshEntities }: CompanySettingsEntityTableProps) => {
  const { polyglot } = usePolyglot();

  const [drawer, setDrawer] = useState<{ entity: CompanyUnitDto | null; isDeleting?: boolean }>();

  // initially sort by name
  const sortedEntities = useMemo(
    () => entities.sort((a, b) => a.legalName.localeCompare(b.legalName, undefined, { sensitivity: 'base' })),
    [entities]
  );

  const columns = useMemo<ColumnDef<CompanyUnitDto, CompanyUnitDto>[]>(() => {
    const formatAddress = ({ addressLine1, addressLine2, city, postCode, countryCode, region }: CompanyAddress) =>
      [addressLine1, addressLine2, city, postCode, countryCode, region].filter(Boolean).join(' ');
    const NoWrap = ({ children }: { children?: ReactNode }) => (
      <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{children}</div>
    );
    return [
      {
        id: 'legal-name',
        header: () => polyglot.t('CompanySettingsEntityTable.legalName'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.legalName, { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.legalName}</NoWrap>,
      },
      {
        id: 'country',
        header: () => polyglot.t('CompanySettingsEntityTable.country'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => getCountryNameForCode(item.address?.countryCode), {
            sensitivity: 'base',
          }),
        cell: ({ row }) => <NoWrap>{getCountryNameForCode(row.original.address?.countryCode)}</NoWrap>,
      },
      {
        id: 'address',
        header: () => polyglot.t('CompanySettingsEntityTable.address'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => item.address && formatAddress(item.address), { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.address && formatAddress(row.original.address)}</NoWrap>,
      },
      {
        id: 'company-id',
        header: () => polyglot.t('CompanySettingsEntityTable.companyid'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.nationalId, { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.nationalId}</NoWrap>,
      },
      {
        id: 'vat-no',
        header: () => polyglot.t('CompanySettingsEntityTable.vat'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.taxId, { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.taxId}</NoWrap>,
      },
      {
        id: 'currency',
        header: () => polyglot.t('General.currency'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.currency),
        cell: ({ row }) => <NoWrap>{row.original.currency}</NoWrap>,
      },
      {
        id: 'is-default-billing-entity',
        header: () => polyglot.t('CompanySettingsEntityTable.billingEntity'),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row }) => <NoWrap>{row.original.isDefaultBillingEntity ? 'Yes' : 'No'}</NoWrap>,
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Stack sx={{ flexFlow: 'row', justifyContent: 'flex-end', gap: spacing.g5 }}>
              <IconButton
                title={polyglot.t('CompanySettingsEntityTable.deleteCompanyEntity')}
                onClick={() => setDrawer({ entity: row.original, isDeleting: true })}
                sx={tableIconButtonSx}
              >
                <DeleteIcon {...actionIconSize} />
              </IconButton>
              <IconButton
                title={polyglot.t('CompanySettingsEntityTable.editCompanyEntity')}
                onClick={() => setDrawer({ entity: row.original })}
                sx={tableIconButtonSx}
              >
                <EditIcon {...actionIconSize} />
              </IconButton>
            </Stack>
          );
        },
      },
    ];
  }, [polyglot]);

  const closeDrawer = useCallback(
    (updated: boolean) => {
      setDrawer(undefined);
      if (updated) refreshEntities?.();
    },
    [refreshEntities]
  );

  const isDrawerOpen = typeof drawer !== 'undefined';

  return (
    <>
      <SettingsSubsectionContent
        sections={[
          {
            title: polyglot.t('CompanySettingsEntityTable.entityInformation'),
            contentWidth: '100%',
            buttons: [
              <ButtonComponent sizeVariant="small" colorVariant="secondary" onClick={() => setDrawer({ entity: null })}>
                {polyglot.t('CompanySettingsEntityTable.buttonNewEntity')}
              </ButtonComponent>,
            ],
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <BasicTable
                    rowData={sortedEntities}
                    columnData={columns}
                    hidePagination
                    style={{ width: 'initial' }}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <DrawerModal isOpen={isDrawerOpen} setIsOpen={(open) => !open && closeDrawer(false)}>
        <>
          {drawer?.entity && drawer.isDeleting && (
            <DeleteCompanyEntityDrawer entity={drawer.entity} close={closeDrawer} />
          )}
          {drawer?.entity !== undefined && !drawer.isDeleting && (
            <CompanyEntityDrawerPage entity={drawer.entity} close={closeDrawer} />
          )}
        </>
      </DrawerModal>
    </>
  );
};
