import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmOsUpdatesPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-os-updates-policy-editor.component';
import { ZeltMdmDevicePolicyType } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurableAutomaticOsUpdate,
  ConfigurablePoliciesDto,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmOsUpdatesPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const [selectedRestriction, setSelectedRestriction] = useState<ZeltMdmDevicePolicyType>();
  const getOsUpdateAutomaticValues = (): ConfigurableAutomaticOsUpdate => {
    return {
      automaticOsUpdate: configurablePoliciesSelected?.configurableAutomaticOsUpdate?.automaticOsUpdate ?? false,
      installAction: configurablePoliciesSelected?.configurableAutomaticOsUpdate?.installAction ?? 'Default',
    };
  };

  const initialOsUpdateAutomaticValues: ConfigurableAutomaticOsUpdate = getOsUpdateAutomaticValues();

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">OS updates</Typography>
          <IconButton
            onClick={() => {
              setSelectedRestriction(ZeltMdmDevicePolicyType.osupdate_automatic);
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Automatic updates{' '}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialOsUpdateAutomaticValues.automaticOsUpdate
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialOsUpdateAutomaticValues.automaticOsUpdate ? 'Enabled' : 'Not set'}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        ></Box>
      </Box>

      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmOsUpdatesPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          selectedRestriction={selectedRestriction}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
