import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { AutocompleteComponent, AutocompleteComponentProps } from '@/v2/components/forms/autocomplete.component';
import { popularCurrencyShortOptions } from '@/v2/infrastructure/currency/currency.interface';

type CurrencyAutocompleteComponentProps = Omit<
  AutocompleteComponentProps,
  'onChange' | 'label' | 'options' | 'compareValue' | 'value' | 'ref'
>;

export interface CurrencySelectProps extends CurrencyAutocompleteComponentProps {
  name: string;
  error?: boolean;
  helperText?: string | false;
  onChange: (currency: string | null) => void;
  value?: string;
}

export const CurrencySelect = ({
  name,
  value,
  error,
  helperText,
  onChange,
  ...autoCompleteProps
}: CurrencySelectProps) => {
  const { polyglot } = usePolyglot();

  return (
    <AutocompleteComponent
      {...autoCompleteProps}
      name={name}
      label={polyglot.t('CurrencySelect.currency')}
      options={popularCurrencyShortOptions}
      value={popularCurrencyShortOptions.find((option) => option.value === value)}
      compareValue={value ?? ''}
      onChange={(_, x) => {
        // @ts-ignore
        onChange((x?.value as string) ?? null);
      }}
      error={error}
      helperText={helperText}
    />
  );
};
