import { useState } from 'react';

import { Typography } from '@/v2/components/typography/typography.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PersonalTask } from '@/v2/feature/task/pages/personal/components/personal-task.component';
import { RootStyle } from '@/v2/styles/root.styles';

interface TasksUserPageProps {
  readonly userId: number;
}

export const UserTasksPage = ({ userId }: TasksUserPageProps): JSX.Element => {
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState<boolean>(false);
  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Task</Typography>} />
      <PersonalTask
        userId={userId}
        setIsTaskFormModalOpen={setIsTaskFormModalOpen}
        isTaskFormModalOpen={isTaskFormModalOpen}
        reach="userProfile"
      />
    </RootStyle>
  );
};
