import React, { Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Loader } from '@v2/components/loader.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { DocumentAPI } from '@v2/feature/documents/document.api';
import {
  DocumentFormModalAction,
  DocumentFormModalState,
  downloadFileByUrl,
  initialiseState,
  reduceDocumentFormModal,
} from '@v2/feature/documents/documents.util';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { secondaryTableSmallBtn } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconCTAButtonSx, tableSecondaryIconButtonSx } from '@v2/styles/icon-button.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { pipe } from 'fp-ts/function';
import { generatePath } from 'react-router-dom';
import { Subject } from 'rxjs';

import { FILE_UPLOAD_BACKOFFICE_POLICY_DOCUMENTS_ENDPOINT } from '@/api-client/routes';
import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Delete } from '@/images/side-bar-icons/Delete.svg';
import { ReactComponent as Document } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { nestErrorMessage } from '@/lib/errors';

interface UploadInsurancePolicyDocumentsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly policyId: number;
  readonly refresh: () => Promise<void>;
  readonly onSave: () => void;
}

export const UploadInsurancePolicyDocumentsDrawer = ({
  isOpen,
  setIsOpen,
  policyId,
  onSave,
  refresh,
}: UploadInsurancePolicyDocumentsDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <UploadInsurancePolicyDocumentsDrawerContent policyId={policyId} refresh={refresh} onSave={onSave} />
    </DrawerModal>
  );
};

interface UploadInsurancePolicyDocumentsDrawerContentProps {
  readonly policyId: number;
  readonly onSave: () => void;
  readonly refresh: () => Promise<void>;
}

const UploadInsurancePolicyDocumentsDrawerContent = ({
  policyId,
  onSave,
  refresh,
}: UploadInsurancePolicyDocumentsDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();

  const [policy, setPolicy] = useState<InsurancePolicyDto | null>(null);
  const [noOfAlreadyUploadedDocuments, setNoOfAlreadyUploadedDocuments] = useState<number>(
    policy?.uploadedDocuments?.length ?? 0
  );
  const [noOfDocumentToBeUploaded, setNoOfDocumentToBeUploaded] = useState<number>(1);
  const MAX_NO_OF_DOCUMENTS_ALLOWED = 5;
  const documentReducers = [
    // Allow up to MAX_NO_OF_DOCUMENTS_ALLOWED document uploads
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
    useReducer(reduceDocumentFormModal, initialiseState([])),
  ];

  const refreshPolicyDetails = useCallback(async () => {
    try {
      setLoading(true);
      const policy = await InsuranceAPI.getInsurancePolicyDetailsAsSuperAdmin(policyId, true);
      setPolicy(policy);
      const noOfAlreadyUploadedDocuments = policy?.uploadedDocuments?.length ?? 0;
      setNoOfAlreadyUploadedDocuments(noOfAlreadyUploadedDocuments);
      setNoOfDocumentToBeUploaded(Math.max(1, noOfAlreadyUploadedDocuments));
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, policyId]);

  useEffect(() => {
    refreshPolicyDetails();
  }, [refreshPolicyDetails]);

  const uploadDocuments = async () => {
    try {
      setLoading(true);
      const validDocumentsData = documentReducers
        .filter(([document]) => document.fileUuid)
        .map(([document]) => ({
          name: document.name!,
          fileUuid: document.fileUuid!,
          note: document.note,
        }));
      await InsuranceAPI.uploadPolicyDocumentsAsSuperAdmin(policyId, validDocumentsData);
      await refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not upload documents. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Loader loading={loading}>
      <Typography sx={titleSx}>Upload documents</Typography>

      {policy &&
        Array.from(Array(noOfDocumentToBeUploaded)).map((_, index) => {
          if (index < noOfAlreadyUploadedDocuments)
            return (
              <InsuranceDocumentDetails
                key={index}
                document={policy.uploadedDocuments[index]!}
                refresh={refreshPolicyDetails}
              />
            );

          return (
            <InsuranceDocumentUpload
              key={index}
              policyId={policyId}
              documentState={documentReducers[index][0]}
              dispatch={documentReducers[index][1]}
            />
          );
        })}

      {(documentReducers[noOfDocumentToBeUploaded - 1][0].fileUuid ||
        noOfAlreadyUploadedDocuments === noOfDocumentToBeUploaded) &&
        noOfDocumentToBeUploaded < MAX_NO_OF_DOCUMENTS_ALLOWED && (
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'end', ...spacing.mb40 }}>
            <Button
              sx={secondaryTableSmallBtn}
              onClick={() => {
                setNoOfDocumentToBeUploaded((prevCount) => Math.min(prevCount + 1, MAX_NO_OF_DOCUMENTS_ALLOWED));
              }}
            >
              + One more
            </Button>
          </Box>
        )}

      <Box sx={buttonBoxSx}>
        <LoaderButton
          name="Save"
          loading={loading}
          type="button"
          onClick={uploadDocuments}
          sizeVariant="small"
          colorVariant="primary"
          disabled={
            noOfAlreadyUploadedDocuments >= MAX_NO_OF_DOCUMENTS_ALLOWED ||
            !documentReducers[noOfAlreadyUploadedDocuments][0].fileUuid
          }
          fullWidth
        />
      </Box>
    </Loader>
  );
};

interface InsuranceDocumentUploadProps {
  readonly policyId: number;
  documentState: DocumentFormModalState;
  dispatch: React.Dispatch<DocumentFormModalAction>;
}

const InsuranceDocumentUpload = ({ policyId, documentState, dispatch }: InsuranceDocumentUploadProps) => {
  const [changeName$] = useState(() => new Subject<string>());
  const [changeNote$] = useState(() => new Subject<string>());

  useEffect(
    () =>
      pipe(
        changeName$,
        ($) => $.subscribe((value) => dispatch({ kind: 'change_name', value })),
        (s) => () => s.unsubscribe()
      ),
    [changeName$, dispatch]
  );

  useEffect(
    () =>
      pipe(
        changeNote$,
        ($) => $.subscribe((value) => dispatch({ kind: 'change_note', value })),
        (s) => () => s.unsubscribe()
      ),
    [changeNote$, dispatch]
  );

  return (
    <Box>
      <Box sx={{ ...fieldSx, mb: 0 }}>
        <TextfieldComponent
          name="name"
          label="Name"
          value={documentState.name}
          onChange={(e) => {
            changeName$.next(e.target.value);
          }}
          endAdornment="none"
        />
      </Box>

      <Box sx={{ ...fieldSx, mb: 0 }}>
        <TextfieldComponent
          name="note"
          label="Notes"
          value={documentState.note}
          onChange={(e) => {
            changeNote$.next(e.target.value);
          }}
          endAdornment="none"
        />
      </Box>

      <Box sx={{ ...spacing.mb30 }}>
        <UploadInput
          endpoint={generatePath(FILE_UPLOAD_BACKOFFICE_POLICY_DOCUMENTS_ENDPOINT, { policyId })}
          onChange={(file: { readonly uuid: string; readonly fileName: string } | undefined) => {
            if (file) {
              dispatch({ kind: 'upload_file', value: { fileUuid: file.uuid, fileName: file.fileName } });
            } else {
              dispatch({ kind: 'clear_file' });
            }
          }}
        />
      </Box>
    </Box>
  );
};

interface InsuranceDocumentDetailsProps {
  readonly document: any;
  readonly refresh: () => Promise<void>;
}

const InsuranceDocumentDetails = ({ document, refresh }: InsuranceDocumentDetailsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const deleteDocument = async () => {
    try {
      setLoading(true);
      await DocumentAPI.deleteDocumentByIdAsSuperAdmin(document.id);
      await refresh();
    } catch (error) {
      showMessage(`Could not delete document. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Loader loading={loading}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 1,
          gap: spacing.gap10,
          alignItems: 'center',
        }}
      >
        <Box>
          <Document width={20} height={20} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, gap: spacing.gap5 }}>
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{document.name}</Typography>
          </Box>
          {document.note && (
            <Box sx={{ width: '100%' }}>
              <Typography sx={themeFonts.caption}>{document.note}</Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: spacing.gap10, width: '80px' }}>
          <IconButton
            disableRipple
            sx={tableSecondaryIconButtonSx}
            onClick={() => {
              const attachmentExists = document.attachments && document.attachments.length > 0;
              if (attachmentExists) {
                const documentUrl = document.attachments[0].url;
                downloadFileByUrl(documentUrl, document.name);
              }
            }}
          >
            <Download width={20} height={20} />
          </IconButton>
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            disableRipple
            sx={iconCTAButtonSx}
          >
            <Delete {...iconSize} />
          </IconButton>
          <NotificationModal
            isOpen={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            takeAction={async () => {
              await deleteDocument();
              setAnchorEl(null);
            }}
            message="Are you sure you want to delete this document?"
            callToAction="Yes"
          />
        </Box>
      </Box>
    </Loader>
  );
};
