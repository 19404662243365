import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { SurveySectionAPI } from '@v2/feature/growth/surveys/api-client/survey-section.api';
import { SurveySection, SurveySectionUpsert } from '@v2/feature/growth/surveys/interfaces/survey-section.interface';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const SectionSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
  });

export const SectionModalContent = ({
  cycleId,
  onClose,
  section,
  refresh,
}: {
  readonly cycleId: string;
  readonly onClose: () => void;
  readonly section: SurveySection | null;
  readonly refresh: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const onSubmit = useCallback(
    async (values: { name: string }) => {
      const updateObject: SurveySectionUpsert = { name: values.name };

      if (section) {
        await SurveySectionAPI.updateSurveySectionInBank(section.id, cycleId, updateObject);
        showMessage('Section updated successfully', 'success');
      } else {
        await SurveySectionAPI.createSectionByCycleId(cycleId, updateObject);
        showMessage('Section created successfully', 'success');
      }
    },
    [cycleId, section, showMessage]
  );

  const formik = useFormik<SurveySectionUpsert>({
    initialValues: {
      name: section ? section.name : '',
    },
    enableReinitialize: true,
    validationSchema: SectionSchema(),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await onSubmit(values);
        await refresh();
        onClose();
      } catch (error) {
        showMessage("Couldn't create the section", 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{section ? 'Edit section' : 'New section'}</Typography>

        <TextfieldComponent
          multiline
          name="name"
          label="Name"
          value={formik.values.name}
          type="text"
          onChange={formik.handleChange}
          error={formik.touched.name && !!formik.errors.name}
          helperText={(formik.touched.name && formik.errors.name) ?? ' '}
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
