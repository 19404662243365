import { OnboardingUser } from '@shared/modules/onboarding/onboarding';
import { generatePath } from 'react-router-dom';

import type { History } from 'history';

import { SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE } from '@/lib/routes';

export type OnboardingTemplateEditMode = 'first-template' | 'new-template' | 'edit-template';

export type OnboardingTemplateEditState = {
  mode: OnboardingTemplateEditMode;
};

export function goToEditTemplate(routerHistory: History, templateId: number, mode: OnboardingTemplateEditMode) {
  const path = generatePath(SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE, { templateId });
  const state: OnboardingTemplateEditState = {
    mode,
  };
  routerHistory.push(path, state);
}

export const filterByLifecycle = (users: readonly OnboardingUser[], validValues: string[]) => {
  return users.filter((user) => user.userEvent?.status && validValues.includes(user.userEvent.status));
};
