import React, { useCallback, useEffect, useState } from 'react';

import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { PayrollAPI } from '@v2/feature/payroll/payroll.api';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

interface PayrollOtherProps {
  readonly payroll: CompanyPayroll;
  readonly sx?: SxProps<Theme>;
  readonly refreshPayroll: () => Promise<void>;
}

export const PayrollOtherSettingsSection = ({ payroll, sx, refreshPayroll }: PayrollOtherProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showEditButton, setShowEditButton] = useState<boolean>(true);

  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader
        showEditButton={showEditButton}
        onEditClick={() => setShowEditButton((prev) => !prev)}
      >
        {polyglot.t('PayrollOtherSettings.title')}
      </PayrollSettingSectionHeader>

      {showEditButton ? (
        <PayrollOtherSettingsReadMode payroll={payroll} />
      ) : (
        <PayrollOtherSettingsEditMode
          payroll={payroll}
          refreshPayroll={refreshPayroll}
          setShowEditButton={setShowEditButton}
        />
      )}
    </Stack>
  );
};

interface PayrollOtherSettingsEditModeProps {
  readonly payroll: CompanyPayroll;
  readonly refreshPayroll: () => Promise<void>;
  readonly setShowEditButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayrollOtherSettingsEditMode = ({
  payroll,
  refreshPayroll,
  setShowEditButton,
}: PayrollOtherSettingsEditModeProps) => {
  const { polyglot } = usePolyglot();
  const [updating, setUpdating] = useState(false);
  const [payslipPreview, setPayslipPreview] = useState(payroll.payslipPreview);

  const [showMessage] = useMessage();

  useEscapeKey(() => setShowEditButton(true));

  const updatePayslipPreview = useCallback(
    async (value: boolean) => {
      setUpdating(true);
      try {
        setPayslipPreview(value);
        await PayrollAPI.setPayrollPayslipPreview(payroll.id, value);
        await refreshPayroll();
        setShowEditButton(true);
      } catch (error) {
        setPayslipPreview(payroll.payslipPreview);
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
      setUpdating(false);
    },
    [payroll.id, payroll.payslipPreview, refreshPayroll, setShowEditButton, showMessage]
  );

  useEffect(() => {
    setPayslipPreview(payroll.payslipPreview);
  }, [payroll]);

  return (
    <Box>
      <Stack sx={{ gap: '10px' }}>
        <Typography sx={themeFonts.caption}>{polyglot.t('PayrollOtherSettings.payslipPreviewDesc')}</Typography>
        <CheckboxComponent
          label={polyglot.t('PayrollOtherSettings.payslipPreview')}
          checked={payslipPreview}
          onChange={(_, checked) => setPayslipPreview(checked)}
        />
      </Stack>

      <Box sx={{ mt: spacing.m40, display: 'flex', gap: spacing.g10 }}>
        <ButtonComponent onClick={() => setShowEditButton(true)} sizeVariant="medium" colorVariant="secondary">
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.save')}
          type="button"
          loading={updating}
          sizeVariant="medium"
          colorVariant="primary"
          onClick={async () => {
            await updatePayslipPreview(payslipPreview);
          }}
        />
      </Box>
    </Box>
  );
};

interface PayrollOtherSettingsReadModeProps {
  readonly payroll: CompanyPayroll;
}

const PayrollOtherSettingsReadMode = ({ payroll }: PayrollOtherSettingsReadModeProps) => {
  const { polyglot } = usePolyglot();
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: '1fr 3fr',
        rowGap: spacing.g10,
        columnGap: spacing.g20,
      }}
    >
      <Typography sx={themeFonts.caption}>{polyglot.t('PayrollOtherSettings.payslipPreview')}</Typography>
      <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
        {payroll.payslipPreview ? polyglot.t('General.on') : polyglot.t('General.off')}
      </Typography>
    </Box>
  );
};
