import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useCallback, useMemo } from 'react';

export function useProfileFields() {
  const { data: userDataFields } = useApiClient(CustomProfileFormEndpoints.listAllFieldsProfileHook(), {
    suspense: false,
  });

  const defaultFieldsByStub = useMemo(() => {
    return new Map(userDataFields?.dataFieldsDefault?.map((f) => [f.fieldStub, f]));
  }, [userDataFields?.dataFieldsDefault]);

  const getDefaultField = useCallback(
    (fieldStub: string) => {
      return defaultFieldsByStub.get(fieldStub);
    },
    [defaultFieldsByStub]
  );

  const getCustomFieldsForForm = useCallback(
    (formName: string) => {
      return userDataFields?.dataFieldsCustom.filter((f) => f.form?.formName === formName) ?? [];
    },
    [userDataFields?.dataFieldsCustom]
  );

  return { getCustomFieldsForForm, getDefaultField, loading: !userDataFields };
}
