import { useEffect } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  RestrictionAdditionalPasswordOption,
} from '@/v2/feature/device/device.dto';

export const ZeltMdmAdditionalPasswordSecurityPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): RestrictionAdditionalPasswordOption => {
    return {
      allowAutoUnlock:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowAutoUnlock !== undefined
          ? !configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowAutoUnlock
          : false,
      allowFingerprintForUnlock:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowFingerprintForUnlock !== undefined
          ? !configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowFingerprintForUnlock
          : false,
      allowPasswordSharing:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordSharing !== undefined
          ? !configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordSharing
          : false,
      allowPasswordProximityRequests:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordProximityRequests !== undefined
          ? !configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordProximityRequests
          : false,
      allowPasswordAutoFill:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordAutoFill !== undefined
          ? !configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordAutoFill
          : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: RestrictionAdditionalPasswordOption = getPolicy();

  function analyzePolicyBeforeSave(passwordForm: RestrictionAdditionalPasswordOption) {
    let allNulls = true;
    for (const key in passwordForm) {
      if (key !== 'PayloadIdentifier' && (passwordForm as Record<string, boolean>)[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return undefined;
    }
    return {
      allowFingerprintForUnlock: !passwordForm.allowFingerprintForUnlock,
      allowAutoUnlock: !passwordForm.allowAutoUnlock,
      allowPasswordProximityRequests: !passwordForm.allowPasswordProximityRequests,
      allowPasswordSharing: !passwordForm.allowPasswordSharing,
      allowPasswordAutoFill: !passwordForm.allowPasswordAutoFill,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }

  const formik = useFormik<RestrictionAdditionalPasswordOption>({
    initialValues,
    onSubmit: async (passwordForm: RestrictionAdditionalPasswordOption) => {
      const configurablePolicy = analyzePolicyBeforeSave(passwordForm);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableAdditionalPasswordPolicy: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="title2">Additional password security</Typography>
      <Box sx={{ gap: 2, mt: 2 }}>
        <Typography variant="caption">Restrict password actions:</Typography>
      </Box>
      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <CheckboxComponent
            label="Touch ID unlock"
            name="allowFingerprintForUnlock"
            checked={formik.values.allowFingerprintForUnlock}
            onChange={(e, checked) => {
              formik.setFieldValue('allowFingerprintForUnlock', checked);
            }}
          />
          <CheckboxComponent
            label="Auto unlock"
            name="allowAutoUnlock"
            checked={formik.values.allowAutoUnlock}
            onChange={(e, checked) => {
              formik.setFieldValue('allowAutoUnlock', checked);
            }}
          />
          <CheckboxComponent
            label="Requesting password from nearby devices"
            name="allowPasswordProximityRequests"
            checked={formik.values.allowPasswordProximityRequests}
            onChange={(e, checked) => {
              formik.setFieldValue('allowPasswordProximityRequests', checked);
            }}
          />
          <CheckboxComponent
            label="Password sharing via Airdrop"
            name="allowPasswordSharing"
            checked={formik.values.allowPasswordSharing}
            onChange={(e, checked) => {
              formik.setFieldValue('allowPasswordSharing', checked);
            }}
          />
          <CheckboxComponent
            label="Auto-filling passwords"
            name="allowPasswordAutoFill"
            checked={formik.values.allowPasswordAutoFill}
            onChange={(e, checked) => {
              formik.setFieldValue('allowPasswordAutoFill', checked);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '30px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
