import { Fragment } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserTodosVersionSmallLoading = () => {
  const { polyglot } = usePolyglot();

  return (
    <WidgetLayout readOnly={false} size="small" isLoadingState={true}>
      <Fragment>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ padding: spacing.p5 }}>
            <Typography variant="title2">{polyglot.t('UserTodosVersionSmallLoading.actions')}</Typography>
          </div>
        </Box>
        <Box sx={{ height: '100%', padding: spacing.p5 }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              gap: spacing.g25,
            }}
          >
            {[0, 1, 2].map((num) => (
              <div className="uai-loader-container" key={`ustodo-${num}`}>
                <div className="uai-progress" />
              </div>
            ))}
          </Box>
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
