import React, { useEffect, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import {
  DownloadPayslipsState,
  DownloadPayslipsStates,
  PayrunProcessStepState,
  PayrunProcessStepStates,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { sleep } from '@v2/util/time.util';

import { nestErrorMessage } from '@/lib/errors';
import { PayrunProcessingItem } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component';

interface Props {
  readonly payRun: PayRunDto;
  readonly downloadPayslipsState: DownloadPayslipsState;
  readonly setDownloadPayslipsState: React.Dispatch<React.SetStateAction<DownloadPayslipsState>>;
  sx?: SxProps<Theme>;
}

export const DownloadPayslip = ({ payRun, setDownloadPayslipsState, sx }: Props): JSX.Element => {
  const [accordionState, setAccordionState] = useState<PayrunProcessStepState>(PayrunProcessStepStates.pending);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    (async () => {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunPayslipsDetails = await PayrollLocalApi.getPayrunPayslipsDetails(payRun.id);

        const downloadPayslipsState = payRunPayslipsDetails?.state ?? DownloadPayslipsStates.notDownloaded;
        setDownloadPayslipsState(downloadPayslipsState);

        if (downloadPayslipsState === DownloadPayslipsStates.notDownloaded) {
          await sleep(1000);
          const updatedDownloadPayslips = await PayrollLocalApi.createPayslips(payRun.id);
          setDownloadPayslipsState(updatedDownloadPayslips.state);
        }
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setErrorMessage(nestErrorMessage(error));
        setAccordionState(PayrunProcessStepStates.failure);
      }
    })();
  }, [payRun, setDownloadPayslipsState]);

  const createPayslips = async (payRunId: number) => {
    try {
      setAccordionState(PayrunProcessStepStates.pending);
      const updatedDownloadPayslips = await PayrollLocalApi.createPayslips(payRunId);
      setDownloadPayslipsState(updatedDownloadPayslips.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      setErrorMessage(nestErrorMessage(error));
      setAccordionState(PayrunProcessStepStates.failure);
    }
  };

  return (
    <PayrunProcessingItem
      title="Documents"
      key="Documents"
      description={
        {
          pending: 'Preparing payslips and payroll reports...',
          failure: `An error occurred while generating the payslips. ${errorMessage}`,
          success: 'Payslips for this payrun have been generated.',
          warning: `A warning occurred while generating the payslips. ${errorMessage}`,
        }[accordionState]
      }
      buttons={[
        {
          style: 'primary',
          label: 'Retry',
          show: accordionState !== PayrunProcessStepStates.success,
          onClick: () => createPayslips(payRun.id),
        },
      ]}
      success={
        accordionState === PayrunProcessStepStates.pending
          ? undefined
          : accordionState === PayrunProcessStepStates.success
      }
      sx={sx}
    />
  );
};
