// A Resource is an object with a read method returning the payload
interface Resource<Payload> {
  read: () => Promise<Payload>;
}

type status = 'pending' | 'success' | 'error';

// this function let us get a new function using the asyncFn we pass
// this function also receives a payload and return us a resource with
// that payload assigned as type
export function createResource<Payload>(asyncFn: () => Promise<Payload>): Resource<Payload> {
  // we start defining our resource is on a pending status
  let status: status = 'pending';
  // and we create a variable to store the result
  let result: any;
  // then we immediately start running the `asyncFn` function
  // and we store the resulting promise
  const promise = asyncFn().then(
    (r: Payload) => {
      // once it's fulfilled we change the status to success
      // and we save the returned value as result
      status = 'success';
      result = r;
    },
    (e: Error) => {
      // once it's rejected we change the status to error
      // and we save the returned error as result
      status = 'error';
      result = e;
    }
  );
  // lately we return an error object with the read method
  return {
    async read(): Promise<Payload> {
      // here we will check the status value
      switch (status) {
        case 'pending':
          // if it's still pending we throw the promise
          // awaiting the promise is how Suspense know our component is not ready
          await promise;
          // re-check the status after the promise resolves
          return this.read();
        case 'error':
          // if it's error we throw the error
          throw result;
        case 'success':
          // if it's success we return the result
          return result;
      }
    },
  };
}

// First we need a type of cache to avoid creating resources for images
// we have already fetched in the past
const cache = new Map<string, Resource<string>>();

// then we create our loadImage function, this function receives the source
// of the image and returns a resource
export function loadImage(source: string): Resource<string> {
  // if cache has the resource return it immediately
  if (cache.has(source)) return cache.get(source)!;

  // if cache does not have the resource, create a new resource
  const resource = createResource<string>(
    () =>
      // in our async function we create a promise
      new Promise((resolve, reject) => {
        // then create a new image element
        const img = new window.Image();
        // set the src to our source
        img.src = source;
        // and start listening for the load event to resolve the promise
        img.addEventListener('load', () => resolve(source));
        // and also the error event to reject the promise
        img.addEventListener('error', (e) =>
          reject(new Error(`Failed to load image. Error message: ${e.message}. Source: ${source} `))
        );
      })
  );

  // before finishing we save the new resource in the cache
  cache.set(source, resource);
  // and return it
  return resource;
}
