import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitRequestDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefitRequest: UserCustomBenefitRequestDto | null;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}

export const UserBenefitRequestDrawer = ({ isOpen, setIsOpen, userBenefitRequest, onClose, refresh }: DrawerProps) => {
  return userBenefitRequest ? (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <UserBenefitRequestDrawerContent userBenefitRequest={userBenefitRequest} refresh={refresh} onClose={onClose} />
    </DrawerModal>
  ) : null;
};

const DetailsLine = ({ title, value }: { title: string; value: string | number }) => (
  <Box>
    <Typography variant="captionSmall" color="Grey">
      {title}
    </Typography>
    <Typography variant="caption">{value}</Typography>
  </Box>
);

interface DrawerContentProps {
  readonly userBenefitRequest: UserCustomBenefitRequestDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const UserBenefitRequestDrawerContent = ({ userBenefitRequest, onClose, refresh }: DrawerContentProps) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const hasInsuranceAll = hasScopes(['insurance:all']);

  const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false);
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);

  const approveRequest = useCallback(async () => {
    if (!hasInsuranceAll) return;
    try {
      setIsApproveLoading(true);
      await CustomBenefitAPI.approveUserBenefitRequest(
        userBenefitRequest.userId,
        userBenefitRequest.customBenefitId,
        userBenefitRequest.id
      );

      await refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsApproveLoading(true);
    }
  }, [hasInsuranceAll, showMessage, refresh, onClose, userBenefitRequest, polyglot]);

  const rejectRequest = useCallback(async () => {
    if (!hasInsuranceAll) return;
    try {
      setIsRejectLoading(true);
      await CustomBenefitAPI.rejectUserBenefitRequest(
        userBenefitRequest.userId,
        userBenefitRequest.customBenefitId,
        userBenefitRequest.id
      );
      await refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsRejectLoading(false);
    }
  }, [hasInsuranceAll, showMessage, refresh, onClose, userBenefitRequest, polyglot]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Benefit Request</Typography>

      {userBenefitRequest.customBenefit?.type && (
        <DetailsLine title={polyglot.t('General.type')} value={userBenefitRequest.customBenefit.type} />
      )}

      <DetailsLine title={polyglot.t('General.amount')} value={formatMoney({ amount: userBenefitRequest.amount })} />

      {userBenefitRequest.numberOfInstallments && (
        <DetailsLine
          title={polyglot.t('BenefitModule.numberOfInstallments')}
          value={userBenefitRequest.numberOfInstallments}
        />
      )}

      <DetailsLine title={polyglot.t('General.status')} value={userBenefitRequest.status} />

      {(userBenefitRequest.creator?.displayName || userBenefitRequest.creator?.name) && (
        <DetailsLine
          title={polyglot.t('General.createdBy')}
          value={userBenefitRequest.creator.displayName ?? userBenefitRequest.creator.name ?? ''}
        />
      )}

      <DetailsLine
        title={polyglot.t('General.createdAt')}
        value={new Date(userBenefitRequest.createdAt).toLocaleDateString()}
      />

      {(userBenefitRequest.approver?.displayName || userBenefitRequest.approver?.name) && (
        <DetailsLine
          title={polyglot.t('General.approvedBy')}
          value={userBenefitRequest.approver.displayName ?? userBenefitRequest.approver.name ?? ''}
        />
      )}

      {userBenefitRequest.approvedOnTimestamp && (
        <DetailsLine
          title={polyglot.t('General.approvedAt')}
          value={new Date(userBenefitRequest.approvedOnTimestamp).toLocaleDateString()}
        />
      )}

      <Typography variant="captionSmall" color="Grey">
        {polyglot.t('General.notes')}
      </Typography>

      <Typography variant="caption">{userBenefitRequest.notes}</Typography>

      {hasInsuranceAll && (
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            loading={isRejectLoading}
            colorVariant="secondary"
            name={polyglot.t('General.reject')}
            onClick={rejectRequest}
            fullWidth
          />

          <LoaderButton
            sizeVariant="medium"
            loading={isApproveLoading}
            colorVariant="primary"
            name={polyglot.t('General.approve')}
            onClick={approveRequest}
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};
