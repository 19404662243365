import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type IconCellProps = {
  icon: JSX.Element;
  avatarSx?: SxProps<Theme>;
  text: string;
  textSx?: SxProps<Theme>;
  endAdornment?: JSX.Element | false;
  sx?: SxProps<Theme>;
};

export const IconTextCell = ({ avatarSx, icon, textSx, text, sx, endAdornment }: IconCellProps) => {
  return (
    <Stack sx={{ flexFlow: 'row', flex: 1, gap: spacing.g10, alignItems: 'center', ...sx }}>
      <Stack sx={{ flex: '0 0 auto', ...avatarSx }}>{icon}</Stack>
      <Typography
        sx={{
          ...themeFonts.caption,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...textSx,
        }}
      >
        {text}
      </Typography>
      {endAdornment}
    </Stack>
  );
};
