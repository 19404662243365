import { useCallback, useLayoutEffect, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SwitchComponent } from '@/v2/components/forms/switch.component';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  payrollId: number;
  testMode: boolean;
  disable: boolean;
  refreshPayroll: () => void;
  sx?: SxProps<Theme>;
}

export const PayrollTestModeSettings = ({
  payrollId,
  disable,
  refreshPayroll,
  sx,
  testMode: initialTestMode,
}: Props): JSX.Element => {
  const [testMode, setTestMode] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [showMessage] = useMessage();

  useLayoutEffect(() => {
    setTestMode(initialTestMode);
  }, [initialTestMode]);

  const updateTestMode = useCallback(
    async (enabled: boolean) => {
      setUpdating(true);
      setTestMode(enabled);
      try {
        await PayrollAPI.setPayrollTestMode(payrollId, enabled);
        await refreshPayroll();
      } catch (error) {
        showMessage(`Test mode update failed. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setUpdating(false);
      }
    },
    [payrollId, refreshPayroll, showMessage]
  );

  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader>Test mode</PayrollSettingSectionHeader>
      <Typography sx={{ ...themeFonts.caption }}>
        When enabled, your RTI documents will be sent to the HMRC test service instead of the live service.
      </Typography>
      <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5, mt: spacing.m10 }}>
        <SwitchComponent
          checked={testMode}
          name="hrmc-test-mode"
          onChange={(_e, enabled) => {
            updateTestMode(enabled);
          }}
          disabled={updating || disable}
        />
        {updating && <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Updating...</Typography>}
        {!updating && (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            Test mode is {testMode ? 'enabled' : 'disabled'}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
