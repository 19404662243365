import axios from 'axios';
import { generatePath, useHistory } from 'react-router-dom';

import { LOGIN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';

export function useApiErrorInterceptor() {
  const routerHistory = useHistory();

  axios.interceptors.response.use(
    (successResponse) => successResponse,
    (error) => {
      if (error.response?.status === 403 && error.response?.data?.code === 'DEACTIVATED_ACCOUNT') {
        routerHistory.push(`${LOGIN_ROUTE}?deactivated=true`);
        return new Promise(() => {});
      }
      if (error.response?.status === 403 && error.response?.data?.code === 'ONBOARDING_REQUIRED') {
        const userId = error.response?.data?.message.split(';')[1];
        routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
        return new Promise(() => {});
      }
      // TODO we can also redirect to the login page if we get a 401 after the token expires
      return Promise.reject(error);
    }
  );
}
