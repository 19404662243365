import { useMemo } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { DeviceDto } from '@v2/feature/device/device.dto';
import { spacing } from '@v2/styles/spacing.styles';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { DevicePolicy } from '@/v2/feature/device/device.interface';

interface ActivePoliciesProps {
  readonly device: DeviceDto;
  readonly loading: boolean;
}

export const ActivePolicies = ({ device, loading }: ActivePoliciesProps): JSX.Element => {
  const policiesTableColumns = useMemo<ColumnDef<DevicePolicy, DevicePolicy>[]>(
    () => [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{original?.name ? original.name : <EmptyCell />}</div>,
        size: 80,
      },
      {
        header: () => 'Description',
        accessorFn: (row) => row,
        id: 'description',
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{original?.description ? original.description : <EmptyCell />}</div>,
        size: 145,
      },
    ],
    []
  );

  return (
    <Box sx={spacing.mt20}>
      <BasicTable rowData={device.policies ?? []} columnData={policiesTableColumns} loading={loading} hidePagination />
    </Box>
  );
};
