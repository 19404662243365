import { useContext, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { StepperHeader } from '@v2/components/stepper-header.component';
import { StepperComponent } from '@v2/components/stepper.component';
import { CustomDepTokenResult } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/custom-dep-token-result.component';
import { useDepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook';
import { DownloadPublicMdmKeyComponent } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/download-public-mdm-key.component';
import { DownloadServerTokenFromAbmComponent } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/download-server-token-from-abm.component';
import { UploadMdmTokenComponent } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/upload-abm-token.component';
import { DepWizardDto } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { SETTINGS_DEVICES_GENERAL_ROUTE } from '@/lib/routes';

export enum CustomDEPTokenSteps {
  Download_key = 'download_key',
  Download_token = 'download_token',
  Upload_token = 'upload_token',
  Result = 'result',
}

export enum CustomDEPTokenActions {
  Create_token = 'create_token',
  Renew_token = 'renew_token',
}
const getStepLabel = (step: CustomDEPTokenSteps, polyglot: Polyglot): string => {
  if (step === CustomDEPTokenSteps.Download_key) return polyglot.t('CustomDEPTokenLayout.download_key');
  if (step === CustomDEPTokenSteps.Download_token) return polyglot.t('CustomDEPTokenLayout.download_token');
  if (step === CustomDEPTokenSteps.Upload_token) return polyglot.t('CustomDEPTokenLayout.upload_token');
  if (step === CustomDEPTokenSteps.Result) return polyglot.t('CustomDEPTokenLayout.results');
  return step;
};

export const CreateCustomDepWizard = () => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const routerHistory = useHistory();

  const { depAccountDetails } = useDepAccountDetails(
    globalState.user.company.companyId + globalState.user.company.name
  );

  const [formData, setFormData] = useState<DepWizardDto>({
    mdmServerName: globalState.user.company.companyId + globalState.user.company.name,
    type: CustomDEPTokenSteps.Download_key,
    action: depAccountDetails ? CustomDEPTokenActions.Renew_token : CustomDEPTokenActions.Create_token,
    depAccountDetails: depAccountDetails,
  });

  const stepperLabels = Object.values(CustomDEPTokenSteps)
    .filter((step) => {
      return !depAccountDetails || step !== CustomDEPTokenSteps.Download_key;
    })
    .map((step) => {
      return getStepLabel(step, polyglot);
    });

  const [step, setStep] = useState<CustomDEPTokenSteps>(
    depAccountDetails ? CustomDEPTokenSteps.Download_token : CustomDEPTokenSteps.Download_key
  );
  const customDepTokenStepsComponent = useMemo(
    () => [
      ...(!depAccountDetails
        ? [
            {
              name: CustomDEPTokenSteps.Download_key,
              label: polyglot.t('CreateFlowLayout.data'),
              component: (
                <DownloadPublicMdmKeyComponent
                  onNext={() => setStep(CustomDEPTokenSteps.Download_token)}
                  setFormData={setFormData}
                  formData={formData}
                />
              ),
            },
          ]
        : []),
      {
        name: CustomDEPTokenSteps.Download_token,
        label: polyglot.t('CreateFlowLayout.data'),
        component: (
          <DownloadServerTokenFromAbmComponent
            onNext={() => setStep(CustomDEPTokenSteps.Upload_token)}
            setFormData={setFormData}
            formData={formData}
          />
        ),
      },
      {
        name: CustomDEPTokenSteps.Upload_token,
        label: polyglot.t('CreateFlowLayout.data'),
        component: (
          <UploadMdmTokenComponent
            onNext={() => setStep(CustomDEPTokenSteps.Result)}
            setFormData={setFormData}
            formData={formData}
          />
        ),
      },
      {
        name: CustomDEPTokenSteps.Result,
        label: polyglot.t('CreateFlowLayout.results'),
        component: (
          <CustomDepTokenResult
            onNext={() => {
              routerHistory.push(SETTINGS_DEVICES_GENERAL_ROUTE);
            }}
            setFormData={setFormData}
            formData={formData}
          />
        ),
      },
    ],
    [depAccountDetails, polyglot, formData, routerHistory]
  );

  const depSteps = useMemo(
    () =>
      !depAccountDetails
        ? [
            CustomDEPTokenSteps.Download_key,
            CustomDEPTokenSteps.Download_token,
            CustomDEPTokenSteps.Upload_token,
            CustomDEPTokenSteps.Result,
          ]
        : [CustomDEPTokenSteps.Download_token, CustomDEPTokenSteps.Upload_token, CustomDEPTokenSteps.Result],
    [depAccountDetails]
  );

  return (
    <Stack sx={{ flex: 1 }}>
      <StepperHeader
        content={<StepperComponent steps={stepperLabels} activeStep={getStepLabel(step, polyglot)} />}
        contentSx={{ width: '80%', textAlign: 'center' }}
        showStepBack={true}
        stepBackAction={() => {
          if (depSteps.indexOf(step) > 0) {
            return setStep(depSteps[depSteps.indexOf(step) - 1]);
          }
          routerHistory.goBack();
        }}
        stepBackSx={{
          width: '10%',
          textAlign: 'left',
        }}
        showBack
        backAction={() => {
          routerHistory.push(SETTINGS_DEVICES_GENERAL_ROUTE);
        }}
        backSx={{ width: '10%', textAlign: 'right' }}
      />
      <Box sx={{ mt: spacing.mt40 }} />

      {customDepTokenStepsComponent[depSteps.indexOf(step)]?.component}
    </Stack>
  );
};
