import { Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type EditPayrollProps = {
  onSave: () => void;
};

export const EditPayroll = ({ onSave }: EditPayrollProps) => {
  const { polyglot } = usePolyglot();
  return (
    <>
      <Typography sx={themeFonts.title2}>{polyglot.t('EditPayroll.title')}</Typography>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>{polyglot.t('EditPayroll.desc')}</Typography>
      <ButtonComponent
        type="button"
        onClick={() => onSave()}
        fullWidth
        colorVariant="primary"
        sizeVariant="large"
        style={{ marginTop: '40px' }}
      >
        OK
      </ButtonComponent>
    </>
  );
};
