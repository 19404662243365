import {
  FormControlLabel,
  FormControlLabelProps,
  RadioGroup,
  RadioGroupProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { SelectOption } from '@/v2/infrastructure/forms/forms.interface';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = RadioGroupProps & {
  options: SelectOption[];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  selectedValue?: string | number;
  disabled?: boolean;
  radioSx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string | false;
};

export const StyledRadioGroup = (props: Props) => {
  const {
    options,
    selectedValue,
    disabled,
    radioSx,
    error,
    helperText,
    labelPlacement = 'start',
    ...radioGroupProps
  } = props;
  return (
    <>
      <RadioGroup {...radioGroupProps} value={selectedValue ?? ''}>
        {options.map((o, idx) => (
          <FormControlLabel
            key={idx}
            labelPlacement={labelPlacement}
            value={o.value || disabled}
            control={<StyledRadio disableRipple />}
            disabled={o.disabled}
            label={
              <Typography sx={{ ...themeFonts.caption, color: o.disabled ? themeColors.Grey : themeColors.DarkGrey }}>
                {o.label}
              </Typography>
            }
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              display: 'flex',
              margin: 0,
              paddingY: spacing.p15,
              borderBottom: idx < options.length - 1 ? borders.background : 'none',
              ...radioSx,
            }}
          />
        ))}
      </RadioGroup>
      {helperText && (
        <Typography sx={{ ...themeFonts.caption, color: error ? themeColors.darkRed : themeColors.DarkGrey }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};
