import axios from 'axios';
import useSWR, { useSWRConfig } from 'swr';
import { BareFetcher, PublicConfiguration } from 'swr/dist/types';

import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export const defaultSwrOptions = {
  dedupingInterval: 2000,
  revalidateOnFocus: false,
  suspense: true,
  revalidateIfStale: true,
};

export const fetcher = async (url: string) =>
  axios
    .get(url)
    .then((res) =>
      // NestJS returns null/undefined values as empty strings, so convert them in the returned data
      res.data === '' ? null : res.data
    )
    .catch((error) => error);

export function useApiClient<T, E>(
  query: Endpoint<T, E> | null,
  swrOptions?: Partial<PublicConfiguration<T, E, BareFetcher<T>>>
) {
  const { data, mutate, isValidating, error } = useSWR<T, E>(query?.url ?? null, fetcher, {
    ...defaultSwrOptions,
    ...query?.swrOptions,
    ...swrOptions,
  });

  // The error does not get returned in the error object, but in the data object, so we need to manually check for error
  // If there is a status and the code > 299 => an error happened => try to extract the message and create the error object that should be returned
  // @ts-ignore
  if (data?.response?.status > 299) {
    console.error(data);
    console.error({ error });

    const dataResponse: {
      status: number;
      statusText?: string;
      data?: { status?: string | number; message?: string; [key: string]: unknown };
      // @ts-ignore
    } = data?.response;

    const status = dataResponse.status;
    const statusText = dataResponse?.statusText;
    return {
      data: null,
      error: {
        status,
        statusText,
        data: dataResponse?.data,
      },
      isValidating,
    };
  }

  const isLoading = Boolean(data === undefined && !error && query);

  return { data, mutate, isValidating, isLoading };
}

export const clearApiClientCache = ({ cache }: ReturnType<typeof useSWRConfig>) => {
  /*
    the SWR docs show clearing the cache as:
        mutate(() => true, undefined, {revalidate: false})

    but this does not work in v1 (and also has problems in v2).
    Instead, we directly clear the cache map.
   */
  (cache as Map<unknown, unknown>).clear();
};
