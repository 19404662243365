import { useCallback, useEffect, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { StyledSwipeableDrawer } from '@/v2/styles/sidebar-edit.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
  readonly refreshPayroll: () => Promise<void>;
  readonly sx?: SxProps<Theme>;
}

export const PayrollGeneralSettingsSection = ({ payroll, refreshPayroll, sx }: Props) => {
  const { polyglot } = usePolyglot();
  const [showGeneralEditDrawer, setShowGeneralEditDrawer] = useState(false);
  const [editedDisplayName, setEditedDisplayName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showMessage] = useMessage();

  useEffect(() => {
    if (showGeneralEditDrawer) {
      setEditedDisplayName(payroll.displayName);
    }
  }, [showGeneralEditDrawer, payroll.displayName]);

  const saveGeneralSettings = useCallback(async () => {
    try {
      setIsSaving(true);
      await PayrollAPI.setGeneralSettings(payroll.id, { displayName: editedDisplayName });
      await refreshPayroll();
      setShowGeneralEditDrawer(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsSaving(false);
    }
  }, [editedDisplayName, payroll.id, polyglot, refreshPayroll, showMessage]);

  return (
    <>
      <Stack sx={sx}>
        <PayrollSettingSectionHeader showEditButton onEditClick={() => setShowGeneralEditDrawer(true)}>
          General
        </PayrollSettingSectionHeader>
        <Box
          sx={{
            display: 'inline-grid',
            gridTemplateColumns: '1fr 3fr',
            rowGap: spacing.g10,
            columnGap: spacing.g20,
            mt: spacing.mt10,
          }}
        >
          <>
            <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
              Entity
            </Typography>
            <Typography variant="title4">{payroll.entity.legalName}</Typography>
          </>
          <>
            <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
              Display name
            </Typography>
            <Typography variant="title4">{payroll.displayName}</Typography>
          </>
        </Box>
      </Stack>
      <StyledSwipeableDrawer
        anchor="right"
        open={showGeneralEditDrawer}
        onOpen={() => {}}
        onClose={() => setShowGeneralEditDrawer(false)}
      >
        {showGeneralEditDrawer && (
          <Stack sx={{ width: '400px', padding: '20px 30px', ...drawerContentSx }}>
            <Typography variant="title2">General</Typography>

            <TextfieldComponent label="Entity" disabled value={payroll.entity.legalName} />

            <TextfieldComponent
              label="Display name"
              value={editedDisplayName}
              onChange={(e) => setEditedDisplayName(e.target.value)}
              autoFocus
              disabled={isSaving}
            />

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth
                onClick={() => saveGeneralSettings()}
                loading={isSaving}
              >
                {polyglot.t('General.save')}
              </ButtonComponent>
            </Box>
          </Stack>
        )}
      </StyledSwipeableDrawer>
    </>
  );
};
