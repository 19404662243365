import React, { useCallback, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { TimePickerComponent } from '@v2/components/forms/time-picker.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AttendanceShiftAPI } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api';
import { AttendanceShiftDto } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.dto';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { buttonBoxSx, fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { isoDateAndTimeFormat, isValidTimeString } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';

interface UnfinishedAttendanceShiftDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly shift: AttendanceShiftDto;
  readonly onClose?: () => void;
  readonly afterClose?: () => void;
  readonly refresh: () => Promise<void>;
}

export const UnfinishedAttendanceShiftDrawer = ({
  isOpen,
  setIsOpen,
  shift,
  refresh,
  onClose,
  afterClose,
}: UnfinishedAttendanceShiftDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose}>
      <UnfinishedAttendanceShiftDrawerContent refresh={refresh} setIsOpen={setIsOpen} shift={shift} />
    </DrawerModal>
  );
};

interface FormData {
  endHour: string;
  endHourTimestamp: Date | null;
}

interface UnfinishedAttendanceShiftDrawerContentProps {
  readonly shift: AttendanceShiftDto;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

const UnfinishedAttendanceShiftDrawerContent = ({
  shift,
  setIsOpen,
  refresh,
}: UnfinishedAttendanceShiftDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();

  const user = getCachedUserById(shift.userId);

  const onSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);

        await AttendanceShiftAPI.endUnfinishedAttendanceShiftById(
          shift.userId,
          shift.logDate,
          values.endHour,
          values.endHourTimestamp!,
          // for now if admin ends the shift, don't save location
          null,
          null
        );
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('UnfinishedAttendanceShiftDrawer.errorMessages.badRequest', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, shift, refresh, setIsOpen, showMessage]
  );

  const minEndTime = useMemo(() => {
    if (!shift.shiftEntries || !shift.shiftEntries[0]?.startHourTimestamp) {
      // if no entries, return midnight of logDate
      // In normal conditions at least an entry should exist in shiftEntries array and this code should never be executed
      const minTime = new LocalDate(shift.logDate);
      minTime.getDate().setHours(0, 0, 0, 0);
      return minTime.getDate();
    }

    const lastEntryStart = new Date(shift.shiftEntries[shift.shiftEntries.length - 1].startHourTimestamp);
    lastEntryStart.setMinutes(lastEntryStart.getMinutes() + 5, 0, 0);
    return lastEntryStart;
  }, [shift]);

  const formik = useFormik<FormData>({
    initialValues: {
      endHour: '',
      endHourTimestamp: null,
    },
    validationSchema: Yup.object({
      endHour: Yup.string()
        .matches(isoDateAndTimeFormat, polyglot.t('UnfinishedAttendanceShiftDrawer.errorMessages.valueInvalid'))
        .required(polyglot.t('UnfinishedAttendanceShiftDrawer.errorMessages.endHourRequired')),
      endHourTimestamp: Yup.date()
        .min(
          minEndTime,
          `Min time: ${
            minEndTime
              .toLocaleDateString(undefined, {
                hour: '2-digit',
                minute: '2-digit',
              })
              .split(', ')[1]
          }`
        )
        .typeError(polyglot.t('UnfinishedAttendanceShiftDrawer.errorMessages.valueInvalid'))
        .required(polyglot.t('UnfinishedAttendanceShiftDrawer.errorMessages.endHourTimeStampRequired')),
    }),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>{polyglot.t('UnfinishedAttendanceShiftDrawer.finishShift')}</Typography>

        <Box sx={fieldSx}>
          <DrawerViewerItem
            title={polyglot.t('UnfinishedAttendanceShiftDrawer.employee')}
            key="employee"
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                <UserAvatar userId={shift.userId} size="xxsmall" />
                {user && (
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {user.displayName || `${user.firstName} ${user.lastName}`}
                  </Typography>
                )}
              </Box>
            }
          />
        </Box>

        <Box sx={{ ...fieldSx, display: 'flex' }}>
          <Box sx={{ mb: spacing.m30, display: 'flex', flexDirection: 'column', gap: spacing.g5, width: '50%' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>Date</Typography>
            <Typography sx={themeFonts.title4}>
              {new LocalDate(shift.logDate).toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Typography>
          </Box>

          <Box sx={{ width: '50%', maxWidth: '120px' }}>
            <TimePickerComponent
              textFieldKey="endHour"
              label={polyglot.t('UnfinishedAttendanceShiftDrawer.endHour')}
              value={formik.values.endHour}
              onChange={(event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${shift.logDate}T${time}:00`);

                  formik.setFieldValue('endHour', date.toFullString());
                  formik.setFieldValue('endHourTimestamp', date.getDate());
                  formik.validateField('endHour');
                  formik.validateField('endHourTimestamp');
                }
              }}
              error={
                (!!formik.errors.endHour && formik.touched.endHour) ||
                (!!formik.errors.endHourTimestamp && formik.touched.endHourTimestamp)
              }
              helperText={
                ((formik.touched.endHour && formik.errors.endHour) ||
                  (formik.touched.endHourTimestamp && formik.errors.endHourTimestamp)) as string
              }
              fullWidth
            />
          </Box>
        </Box>

        <Box sx={buttonBoxSx}>
          <LoaderButton
            name={polyglot.t('UnfinishedAttendanceShiftDrawer.endShift')}
            fullWidth
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
