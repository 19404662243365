import { useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { COLOR_OPTIONS } from '@/v2/feature/absence/absence.util';
import { GrowthFactorAPI } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const FactorSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    color: Yup.string().required(),
  });
export const GrowthFactorFormContent = ({
  onClose,
  growthFactor,
  refresh,
}: {
  onClose: () => void;
  growthFactor: GrowthFactor | undefined;
  refresh: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const formik = useFormik({
    initialValues: {
      name: growthFactor ? growthFactor.name : '',
      color: growthFactor ? growthFactor?.color : '',
    },
    enableReinitialize: true,
    validationSchema: FactorSchema(),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (growthFactor) {
          const updateObject = { id: growthFactor.id, ...values };
          await GrowthFactorAPI.updateFactor(updateObject);
          showMessage('Factor updated successfully', 'success');
        } else {
          await GrowthFactorAPI.createFactor(values);
          showMessage('Factor created successfully', 'success');
        }
        refresh();
        onClose();
      } catch (error) {
        showMessage("Couldn't create the section", 'error');
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            boxSizing: 'border-box',
            px: spacing.p16,
            pb: spacing.p24,
          }}
        >
          <Box>
            <Box sx={{ my: spacing.m16 }}>
              <Typography variant="title2">{growthFactor ? 'Edit factor' : 'New factor'}</Typography>
            </Box>

            <Box sx={{ ...fieldSx, display: 'flex', gap: spacing.g16, alignItems: 'center', mt: spacing.m8 }}>
              <Box sx={{ width: '20%' }}>
                <SelectComponent
                  name="color"
                  label="Color"
                  options={COLOR_OPTIONS}
                  value={formik.values.color}
                  onChange={formik.handleChange}
                  compareValue={formik.values.color}
                  error={!!formik.errors.color && formik.touched.color}
                  helperText={formik.touched.color && (formik.errors.color as string)}
                />
              </Box>
              <Box sx={{ width: '80%' }}>
                <TextfieldComponent
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && (formik.errors.name as string)}
                  endAdornment="none"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>

          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth={true}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
