import {
  PayScheduleEnum,
  SalaryBasisEnum,
} from '@v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';

export type ContractTemplate = {
  id: string;
  name: string;
  templateText: string;
  companyId: number;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
  user: {
    userId: number;
    firstName: string;
    lastName: string;
    avatarHash?: string;
  };
  type: string | null;
};

export type CreateTemplateData = {
  name: string;
  templateText: string;
  type: string;
};

export type GetContractPreviewData = {
  templateId: string;
  missingTemplateFields?: ContractTemplateFieldValues;
  contractRecipientUserId: number;
  companySignatoryUserId?: number;
};

export type TemplatePreviewResult = { compiledTemplate: string };

export enum ContractTemplateFields {
  // PERSONAL
  'employee_first_name' = 'employee_first_name',
  'employee_last_name' = 'employee_last_name',
  'employee_full_name' = 'employee_full_name',
  'employee_dob' = 'employee_dob',
  'employee_passport_number' = 'employee_passport_number',
  'employee_personal_email' = 'employee_personal_email',

  // ADDRESS
  'employee_address' = 'employee_address',
  'employee_address_line1' = 'employee_address_line1',
  'employee_address_line2' = 'employee_address_line2',
  'employee_address_city' = 'employee_address_city',
  'employee_address_country' = 'employee_address_country',
  'employee_address_postcode' = 'employee_address_postcode',

  // ROLE
  'employee_start_date' = 'employee_start_date',
  'employee_role' = 'employee_role',
  'employee_reports_to' = 'employee_reports_to',
  'employee_site' = 'employee_site',
  'employee_department' = 'employee_department',

  // CONTRACT
  'employee_worker_type' = 'employee_worker_type',
  'employee_employment_contract' = 'employee_employment_contract',
  'employee_attendance_schedule' = 'employee_attendance_schedule',
  'employee_fte_percentage' = 'employee_fte_percentage',
  'employee_notice_period' = 'employee_notice_period',
  'employee_probation_period' = 'employee_probation_period',

  // SALARY
  'compensation_rate' = 'compensation_rate',
  'compensation_salary_basis' = 'compensation_salary_basis',
  'compensation_units' = 'compensation_units',
  'compensation_currency' = 'compensation_currency',
  'compensation_pay_schedule' = 'compensation_pay_schedule',

  // EQUITY
  'compensation_equity' = 'compensation_equity',
  'compensation_equity_type' = 'compensation_equity_type',
  'compensation_equity_grant_date' = 'compensation_equity_grant_date',
  'compensation_equity_amount' = 'compensation_equity_amount',
  'compensation_equity_vesting_start' = 'compensation_equity_vesting_start',
  'compensation_equity_vesting_period' = 'compensation_equity_vesting_period',
  'compensation_equity_vesting_cliff' = 'compensation_equity_vesting_cliff',
  'compensation_equity_exercise_price' = 'compensation_equity_exercise_price',

  // 'compensation_paid_time_off' = 'compensation_paid_time_off',
  'company_logo' = 'company_logo',

  // SIGNATURES
  'employee_signature' = 'employee_signature',
  'recipient_signature_timestamp' = 'recipient_signature_timestamp',

  'company_signature' = 'company_signature',
  'additional_signature' = 'additional_signature',
  'additional_signatory_timestamp' = 'additional_signatory_timestamp',
  'additional_signatory_full_name' = 'additional_signatory_full_name',
  'additional_signatory_job_title' = 'additional_signatory_job_title',
}

export const requiredTemplateFields: RequiredContractFields = {
  employee_first_name: false,
  employee_last_name: false,
  employee_role: false,
  employee_start_date: false,
  employee_address: false,
  employee_signature: false,
  employee_notice_period: false,
  employee_probation_period: false,
  compensation_rate: false,
  compensation_salary_basis: false,
  compensation_equity: false,
  // compensation_paid_time_off: false,
  company_logo: false,
  company_signature: true,
};

type OtherCollectableFields = {
  address_line_1?: string | null;
  address_line_2?: string | null;
  address_city?: string | null;
  address_country?: string | null;
  address_postalcode?: string | null;

  /** Compensation */
  salary_pay_schedule?: PayScheduleEnum;
  salary_basis?: SalaryBasisEnum;
  salary_rate?: number;
  salary_annual_amount?: number;
  salary_units?: number;
  salary_per_pay_schedule?: number;

  /** Role */
  role?: string;
  role_department?: string;
  employee_reports_to?: number;
  employee_attendance_schedule?: number;

  /** Equity */
  equity_grant_date?: string | null;
  equity_type?: string;
  equity_amount?: number;

  /** Contract */
  probation_period_unit?: string;
  probation_period_length?: number;
  notice_period_unit?: string;
  notice_period_length?: number;
};

export type ContractTemplateFieldValues = { [key in ContractTemplateFields]?: string } & OtherCollectableFields;

export const defaultMissingFieldValues: ContractTemplateFieldValues = {
  /**Template Params */
  employee_first_name: '',
  employee_last_name: '',
  employee_role: '',
  employee_start_date: '',
  employee_address: '',
  employee_signature: '',
  employee_notice_period: '',
  employee_probation_period: '',
  compensation_rate: '',
  compensation_salary_basis: '',
  compensation_units: '',
  compensation_currency: '',
  compensation_equity: '',
  // compensation_paid_time_off: '',
  company_signature: '',

  /** Address */
  address_line_1: '',
  address_line_2: '',
  address_city: '',
  address_country: '',
  address_postalcode: '',

  /** Compensation */
  salary_rate: 0,
  salary_annual_amount: 0,
  salary_units: 0,
  salary_per_pay_schedule: 0,

  /** Role */
  role: '',
  role_department: '',

  /** Equity */
  equity_grant_date: null,
  equity_type: '',
  equity_amount: 0,

  /** Contract */
  probation_period_unit: '',
  probation_period_length: 0,
  notice_period_unit: '',
  notice_period_length: 0,
  employee_attendance_schedule: undefined,
};

export type RequiredContractFields = { [key in ContractTemplateFields]?: boolean };
export type VerifyTemplateResult = {
  hasMissingWorkFields: boolean;
  hasMissingPersonalFields: boolean;
  fields: RequiredContractFields;
  companySignatoryUserId: number;
  hasAdditionalSignatory: boolean;
  template: ContractTemplate;
};

export type SignatureWitnessRequiredResult = { signaturePending: boolean; witnessPending: boolean };

export type WitnessDetailsDto = {
  witnessFullName: string;
  witnessFullAddress: string;
  witnessSignature: string;
};

export type TemplatePageParam = {
  readonly templateId: string;
  readonly userId?: string;
  readonly contractTemplateData?: string; // Base64 string of { compiledTemplateText: <string>, missingTemplateData: <ContractTemplateFieldValues> }
  readonly contractId?: string;
};

export type CreateContractData = {
  templateId: string;
  recipient: number;
  missingTemplateFields: ContractTemplateFieldValues;
  companySignature?: string;
  companySignatory?: number;
  updatedTemplateText?: string;
};

export type SendContractLinkData = {
  contractId: string;
};

export type SignContractData = {
  contractId: string;
  signature: string;
  witnessDetails?: WitnessDetailsDto;
};

export type FinaliseContractData = {
  contractId: string;
};

export type SaveContractData = { contractId?: string; recipientId: number; fieldsToSave: ContractTemplateFieldValues };

export enum SignatoryType {
  recipient = 'recipient',
  additional = 'additional',
}

export interface ContractObject {
  id: string;
  templateId: string;
  recipient: number;
  companySignatory: number;
  companySignatureTimestamp: string | null;
  recipientSignatureTimestamp: string | null;
  signatoriesRequired: SignatoryType[] | null;
  hasMissingPersonalFields: boolean;
  hasMissingWorkFields: boolean;
}

export const WORK_TYPE_FIELDS = [
  'employee_role',
  'employee_reports_to',
  'employee_attendance_schedule',
  'employee_site',
  'employee_department',
  'employee_start_date',
  'employee_probation_period',
  'employee_notice_period',
  'employee_worker_type',
  'employee_employment_contract',
  'compensation_pay_schedule',
  'compensation_rate',
  'compensation_units',
  'compensation_currency',
  'compensation_salary_basis',
  'compensation_equity',
  'compensation_equity_type',
  'compensation_equity_grant_date',
  'compensation_equity_amount',
  'compensation_equity_vesting_start',
  'compensation_equity_vesting_period',
  'compensation_equity_vesting_cliff',
  'compensation_equity_exercise_price',
];

export const PERSONAL_TYPE_FIELDS = [
  'employee_first_name',
  'employee_last_name',
  'employee_dob',
  'employee_passport_number',
  'employee_address',
  'employee_personal_email',
  'employee_address_line1',
  'employee_address_line2',
  'employee_address_city',
  'employee_address_country',
  'employee_address_postcode',
  'address_line_1',
  'address_city',
  'address_country',
  'address_postalcode',
];

export type FIELD_CATEGORY_FOR_CONTRACT_TEMPLATE =
  | CustomProfileFormType.Details
  | CustomProfileFormType.Basic
  | CustomProfileFormType.Address
  | CustomProfileFormType.Salary
  | CustomProfileFormType.Contract
  | CustomProfileFormType.Equity
  | CustomProfileFormType.Role;

export interface FIELD_TO_DISPLAY_NAME_MAPPING {
  [key: string]: string | undefined;
}

export const FIELD_DISPLAY_NAME: FIELD_TO_DISPLAY_NAME_MAPPING = {
  employee_probation_period: 'Probation period',
  employee_notice_period: 'Notice period',
  employee_role: 'Role',
  employee_reports_to: 'Reports to',
  employee_attendance_schedule: 'Attendance schedule',
  employee_site: 'Site',
  employee_department: 'Department',
  employee_start_date: 'Start date',
  employee_worker_type: 'Worker type',
  employee_employment_contract: 'Employment contract',
  employee_fte_percentage: 'FTE percentage',
  compensation_pay_schedule: 'Pay schedule',
  compensation_rate: 'Rate',
  compensation_units: 'Units',
  compensation_currency: 'Currency',
  compensation_salary_basis: 'Salary basis',
  compensation_equity: 'Equity',
  compensation_equity_type: 'Type',
  compensation_equity_grant_date: 'Grant date',
  compensation_equity_amount: 'Amount',
  compensation_equity_vesting_start: 'Vesting start',
  compensation_equity_vesting_period: 'Vesting period',
  compensation_equity_vesting_cliff: 'Vesting cliff',
  compensation_equity_exercise_price: 'Exercise price',
  employee_first_name: 'First name',
  employee_last_name: 'Last name',
  employee_dob: 'Date of birth',
  employee_passport_number: 'Passport number',
  employee_address: undefined,
  employee_personal_email: 'Personal email',
  employee_address_line1: 'Address line 1',
  employee_address_line2: 'Address line 2',
  employee_address_city: 'City',
  employee_address_country: 'Country',
  employee_address_postcode: 'Postcode',
  address_line_1: 'Address line 1',
  address_city: 'City',
  address_country: 'Country',
  address_postalcode: 'Postal code',
};

export const CONTRACT_FIELDS_CATEGORIES: {
  [key: string]: FIELD_CATEGORY_FOR_CONTRACT_TEMPLATE;
} = {
  employee_first_name: CustomProfileFormType.Basic,
  employee_last_name: CustomProfileFormType.Basic,
  employee_full_name: CustomProfileFormType.Basic,
  employee_dob: CustomProfileFormType.Details,
  employee_passport_number: CustomProfileFormType.Details,
  employee_personal_email: CustomProfileFormType.Details,

  employee_address: CustomProfileFormType.Address,
  employee_address_line1: CustomProfileFormType.Address,
  employee_address_line2: CustomProfileFormType.Address,
  employee_address_city: CustomProfileFormType.Address,
  employee_address_country: CustomProfileFormType.Address,
  employee_address_postcode: CustomProfileFormType.Address,

  address_line_1: CustomProfileFormType.Address,
  address_city: CustomProfileFormType.Address,
  address_country: CustomProfileFormType.Address,
  address_postalcode: CustomProfileFormType.Address,

  employee_probation_period: CustomProfileFormType.Contract,
  employee_notice_period: CustomProfileFormType.Contract,
  employee_attendance_schedule: CustomProfileFormType.Contract,
  employee_worker_type: CustomProfileFormType.Contract,
  employee_employment_contract: CustomProfileFormType.Contract,
  employee_fte_percentage: CustomProfileFormType.Contract,

  compensation_currency: CustomProfileFormType.Salary,
  compensation_pay_schedule: CustomProfileFormType.Salary,
  compensation_rate: CustomProfileFormType.Salary,
  compensation_salary_basis: CustomProfileFormType.Salary,
  compensation_units: CustomProfileFormType.Salary,

  compensation_equity: CustomProfileFormType.Equity,
  compensation_equity_type: CustomProfileFormType.Equity,
  compensation_equity_grant_date: CustomProfileFormType.Equity,
  compensation_equity_amount: CustomProfileFormType.Equity,
  compensation_equity_vesting_start: CustomProfileFormType.Equity,
  compensation_equity_vesting_period: CustomProfileFormType.Equity,
  compensation_equity_vesting_cliff: CustomProfileFormType.Equity,
  compensation_equity_exercise_price: CustomProfileFormType.Equity,

  employee_site: CustomProfileFormType.Role,
  employee_department: CustomProfileFormType.Role,
  employee_role: CustomProfileFormType.Role,
  employee_reports_to: CustomProfileFormType.Role,
};

export interface ContractTemplateTestResult {
  valid: boolean;
  errorMessage?: string;
  errorStack?: string;
}

export const OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER = '{{company_signature}}';
export const ADDITIONAL_SIGNATORY_PLACEHOLDER = '{{additional_signature}}';

export const UNPROCESSABLE_FONT_ERROR = 'not defined in the font section of the document definition.';
