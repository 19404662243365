import { AppIntegrationStub, CurrentlyDelayedJobs } from '@v2/feature/app-integration/app-integration.interface';
import Bull from 'bull';

import { APPS_REQUESTS_ROUTE, APPS_ROUTE, APP_STORE_ROUTE } from '@/lib/routes';
import {
  AppIntegrationUsersListDto,
  InstalledAppDto,
  UserAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import {
  getScheduledTimeForAppAccessDetailDrawer,
  getStartingTimeForJob,
} from '@/v2/feature/monitoring/monitoring.util';

export const appCurrentlyInstalled = (appList: readonly { stub: AppIntegrationStub }[], stub: string) => {
  return appList?.some((eachApp) => eachApp.stub === stub);
};

export const companyAppsThatCanBeInstalled = (
  installedApps: readonly InstalledAppDto[],
  userApps: readonly UserAppDto[]
) => {
  return installedApps.filter(
    (eachInstalledApp) =>
      !appCurrentlyInstalled(userApps ?? [], eachInstalledApp.stub) && eachInstalledApp.allowsGiveAccess
  );
};

export const userHasDelayedAppAction = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue>,
  userId: number,
  appStub: string
): boolean => {
  if (delayedActions?.delayed?.length === 0) return false;
  if (delayedActions && delayedActions.delayed && delayedActions.delayed.length > 0)
    return delayedActions.delayed.some((a) => a.data.userId === userId && a.data.appStub === appStub);
  return false;
};

export const externalUserHasDelayedAppAction = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue> | null | undefined,
  email?: string | null,
  appUserId?: string | null
): boolean => {
  if (delayedActions?.delayed?.length === 0) return false;
  if (delayedActions && delayedActions.delayed && delayedActions.delayed.length > 0)
    return delayedActions.delayed.some((a) => a.data.appUserId === appUserId || a.data.appUserId === email);
  return false;
};

export const matchingDelayedActionForExternalUser = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue> | null | undefined,
  email?: string | null,
  appUserId?: string | null
): Bull.Job<CreateAppQueue> | undefined => {
  if (delayedActions?.delayed?.length === 0) return undefined;
  if (delayedActions && delayedActions.delayed && delayedActions.delayed.length > 0) {
    const matchingAction = delayedActions.delayed.find(
      (a) => a.data.appUserId === appUserId || a.data.appUserId === email
    );
    return matchingAction ? matchingAction : undefined;
  }
  return undefined;
};

export const getCancellableActionMenuOption = (action: string): string => {
  if (action === 'delete') return 'Cancel scheduled deletion';
  if (action === 'created') return 'Cancel scheduled creation';
  if (action === 'suspended') return 'Cancel scheduled suspension';
  if (action === 'dataTransferThenDelete') return 'Cancel scheduled data transfer and user deletion';
  return 'Cancel';
};

export const getDelayedActionsForUserId = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue>,
  userId: number,
  appStub: string
): Bull.Job<CreateAppQueue>[] => {
  if (delayedActions && delayedActions.delayed.length > 0)
    return delayedActions.delayed.filter((a) => a.data.userId === userId && a.data.appStub === appStub && a.opts.delay);
  return [];
};

export const getDelayedActionsForExternalUser = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue>,
  email?: string | undefined,
  appUserId?: string
): Bull.Job<CreateAppQueue>[] => {
  if (delayedActions && delayedActions.delayed.length > 0)
    return delayedActions.delayed.filter(
      (a: Bull.Job<CreateAppQueue>) => (a.data.appUserId === email || a.data.appUserId === appUserId) && a.opts.delay
    );
  return [];
};

export const hasScheduledAction = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue> | undefined | null,
  userId: number,
  appStub?: AppIntegrationStub
) =>
  !appStub
    ? delayedActions?.delayed?.find((a) => a.data.userId === userId)
    : delayedActions?.delayed?.find((a) => a.data.userId === userId && a.data.appStub === appStub);

export const getScheduledActionDateString = (matchingAction: Bull.Job<CreateAppQueue> | undefined) => {
  return matchingAction
    ? `Account will be ${matchingAction.data.action} on ${getScheduledTimeForAppAccessDetailDrawer(matchingAction)} UTC`
    : undefined;
};

export const getTooltipTitleForExternalScheduledStatus = (
  delayedActions: CurrentlyDelayedJobs<CreateAppQueue> | undefined | null,
  email?: string | null,
  appUserId?: string | null
): string => {
  if (delayedActions && delayedActions.delayed.length > 0) {
    const matchingAction = delayedActions.delayed.find(
      (a) => a.data.appUserId === appUserId || a.data.appUserId === email
    );
    return matchingAction ? `Scheduled for ${getStartingTimeForJob(matchingAction)}` : '';
  }
  return '';
};

export const getActiveUsersFromList = (userList?: AppIntegrationUsersListDto): AppIntegrationUsersListDto => {
  const filteredUsers =
    userList?.users?.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) => eachEmail.status === 'Active') || eachUser.userStatus === 'Active'
    ) ?? [];
  const filteredNotEnrolledUsers =
    userList?.notEnrolledUsers?.filter(
      (eachUser) =>
        eachUser?.emails?.some((eachEmail) => eachEmail.status === 'Active') || eachUser.userStatus === 'Active'
    ) ?? [];

  return {
    users: filteredUsers,
    notEnrolledUsers: filteredNotEnrolledUsers,
  };
};

export const appsWithoutAutomaticAccountCreation = (
  installedApps: readonly InstalledAppDto[]
): AppIntegrationStub[] => {
  return installedApps.filter((eachApp) => eachApp.allowsGiveAccess === false).map((eachApp) => eachApp.stub);
};

export const APPS_PAGE_CONFIG: PageConfig = {
  header: {
    tabs: [
      // {
      //   label: 'Overview',
      //   value: APPS_OVERVIEW_ROUTE,
      //   scopes: ['apps:all'],
      // },
      // APPS_OVERVIEW_ROUTE temporarily removed page config, as designs for insights are not finalized
      {
        label: 'Connected',
        value: APPS_ROUTE,
        scopes: AppScopes.VIEW_APPS_OVERVIEW,
      },
      { label: 'All apps', value: APP_STORE_ROUTE, scopes: AppScopes.VIEW_APP_STORE },
      { label: 'Requests', value: APPS_REQUESTS_ROUTE, scopes: AppScopes.VIEW_APP_REQUESTS },
    ],
  },
};
