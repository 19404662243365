import { useState } from 'react';

import { Autocomplete, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { StyledMenuItem } from '@/v2/styles/menu.styles';

export interface MultiSelectOption<T> {
  readonly value: T;
  readonly label: string;
  readonly disabled?: boolean;
}

interface Props<T> {
  readonly id: string;
  readonly limitTags?: number;
  readonly options: readonly MultiSelectOption<T>[];
  readonly value: MultiSelectOption<T>[];
  readonly onChange: (e: React.SyntheticEvent, value: MultiSelectOption<T>[]) => void;
  readonly isOptionEqualToValue: (x: MultiSelectOption<T>, y: MultiSelectOption<T>) => boolean;
  readonly getOptionLabel: (option: MultiSelectOption<T>) => string;
  readonly label?: string;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly disableClearable?: boolean;
  readonly helperText?: string;
  readonly error?: boolean;
}

export interface MultipleSelectEntry<T = string | number> {
  label: string;
  value: T;
}

export function MultipleSelectCheckbox<T>(props: Props<T>) {
  const {
    id,
    limitTags,
    options,
    value,
    onChange,
    isOptionEqualToValue,
    getOptionLabel,
    label,
    placeholder,
    disabled,
    required,
    disableClearable = false,
    helperText,
    error = false,
  } = props;
  const [selected, setSelected] = useState<MultiSelectOption<T>[]>([]);

  return (
    <Autocomplete
      id={id}
      multiple
      limitTags={limitTags}
      value={value}
      fullWidth
      options={options}
      disableCloseOnSelect
      disableClearable={disableClearable}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
          <StyledAutoTextfield
            {...params}
            label={label}
            fullWidth
            error={error}
            helperText={helperText}
            variant="standard"
            required={required}
            InputLabelProps={{ shrink: true }}
            placeholder={
              !params.InputProps.startAdornment
                ? placeholder || (typeof label === 'string' ? label : undefined)
                : undefined
            }
          />
        );
      }}
      renderOption={(props, option) => {
        const idx = options.indexOf(option);
        return (
          <StyledMenuItem
            key={`${id}-menu-${option.value}`}
            disabled={disabled || option.disabled}
            value={idx}
            {...props}
          >
            <ListItemText>
              <Typography variant="caption">{option.label}</Typography>
            </ListItemText>
            <ListItemIcon>
              <CheckboxComponent
                label={undefined}
                name={`${id}-entry-${idx}`}
                disabled={disabled}
                checked={!!value.find((eachOption) => eachOption.value === option.value)}
                value={`${id}-entry-${idx}`}
                onChange={() => {
                  let finalArray: MultiSelectOption<T>[] = [];
                  if (selected.find((eachOption) => eachOption.value === option.value)) {
                    finalArray = selected.filter((sm) => sm.value !== option.value);
                  } else finalArray = [...selected, option];
                  setSelected(finalArray);
                }}
              />
            </ListItemIcon>
          </StyledMenuItem>
        );
      }}
      renderTags={(options) => {
        return options.map((o) => o.label).join(', ');
      }}
      PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
      popupIcon={<ArrowDownACIcon />}
      clearIcon={<ClearIcon />}
      disabled={disabled}
    />
  );
}
