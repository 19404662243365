import React from 'react';

import { Box, Stack, styled } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SurveyQuestionBank } from '@/v2/feature/growth/surveys/interfaces/survey-question-bank.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';
import { stripHtml } from '@/v2/util/string.util';

export const SurveyQuestionBankViewModal = ({
  isOpen,
  setIsOpen,
  setIsEditOpen,
  onClose,
  question,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  question: SurveyQuestionBank | null;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SurveyQuestionBankViewContent question={question} setIsEditOpen={setIsEditOpen} />
    </DrawerModal>
  );
};

const SurveyQuestionBankViewContent = ({
  question,
  setIsEditOpen,
}: {
  question: SurveyQuestionBank | null;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g24,
        }}
      >
        <Typography variant="title2">Question</Typography>

        {question?.questionText && <ViewItem title="Question text" value={stripHtml(question?.questionText)} />}
        {question?.factor && <ViewItem title="Factor" value={question?.factor} />}
        {question?.type && <ViewItem title="Type" value={question?.type} />}
        {question && (
          <ViewItem
            title="Created by"
            value={
              question.companyId === null ? (
                <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                  <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" aria-label="Payroll logo" />
                  Zelt
                </Stack>
              ) : question?.createdBy ? (
                <UserCell userId={question.createdBy} />
              ) : (
                <EmptyCell />
              )
            }
          />
        )}
        {question?.createdAt && (
          <ViewItem
            title="Created on"
            value={new LocalDate(question.createdAt)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
          />
        )}
      </Box>

      {question && question.companyId !== null && (
        <Box>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            onClick={() => setIsEditOpen(true)}
            aria-label="Edit question"
          >
            Edit
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};

const ViewItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.g8};
`;

const ViewItem = ({ title, value }: { title: string; value: JSX.Element | string }) => {
  return (
    <ViewItemContainer>
      <Typography variant="caption" color="Grey">
        {title}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </ViewItemContainer>
  );
};
