import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeliveryMethod, DeliveryMethodDetails, DevicePossessionType } from '@v2/feature/device/device.interface';
import { DeliveryMethodsOptions } from '@v2/feature/device/device.util';
import { UserAddressAPI } from '@v2/feature/user/features/user-forms/user-address/user-address.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { formatAddress } from '@v2/util/user-data.util';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { SiteAPI } from '@/api-client/site.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from '@/lib/routes';

interface DeviceInventoryReassignDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
  readonly devicePossession: DevicePossessionDto;
}

export const DeviceInventoryReassignDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
}: DeviceInventoryReassignDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <DeviceInventoryAssignDrawerContent devicePossession={devicePossession} />
  </DrawerModal>
);

interface AssignDeviceForm {
  possessionType: DevicePossessionType;
  possessionId: number | undefined;
  deliveryMethod: DeliveryMethod;
  deliveryAddress: string;
  date: string | null;
  deviceAccountName: string | null;
}

interface DeviceInventoryAssignDrawerContentProps {
  readonly devicePossession: DevicePossessionDto;
}

export const DeviceInventoryAssignDrawerContent = ({
  devicePossession,
}: DeviceInventoryAssignDrawerContentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();

  const formatDate = (): string => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const formik = useFormik<AssignDeviceForm>({
    initialValues: {
      possessionType: DevicePossessionType.User,
      possessionId: undefined,
      deliveryMethod: DeliveryMethod.ShippingToEmployee,
      deliveryAddress: '',
      date: formatDate(),
      deviceAccountName: '',
    },
    validationSchema: yup.object({
      possessionType: yup.string().required(),
      possessionId: yup.number().typeError('A receiver should be selected').required('A receiver should be selected'),
      deliveryMethod: yup.string().required('A delivery method should be selected'),
      deliveryAddress: yup.string().when('deliveryMethod', {
        is: (val: DeliveryMethod) => val === DeliveryMethod.ShippingToEmployee || val === DeliveryMethod.ShippingToSite,
        then: (schema) => schema.required('Delivery Address is required'),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
      deviceAccountName: yup.string().nullable().notRequired(),
    }),
    enableReinitialize: true,
    onSubmit: async (formData: AssignDeviceForm) => {
      let isOwnerChangeInitiated = false;
      try {
        if (!formData.possessionId) {
          showMessage('A receiver should be selected', 'error');
          return;
        }
        if (!formData.deliveryMethod) {
          showMessage('A delivery method should be selected', 'error');
          return;
        }
        if (formData.deliveryMethod === DeliveryMethod.ShippingToEmployee && !formData.deliveryAddress) {
          showMessage('Delivery date should be provided', 'error');
          return;
        }
        if (formData.deliveryMethod === DeliveryMethod.Handover && !formData.date) {
          showMessage('Handover date should be provided', 'error');
          return;
        }

        let deliveryDetails: DeliveryMethodDetails;

        if (formData.deliveryMethod === DeliveryMethod.AssignDepToUser) {
          deliveryDetails = {
            deliveryMethod: formData.deliveryMethod,
            deliveryAddress: undefined,
            date: null,
            deviceAccountName: formData.deviceAccountName,
          };
        } else {
          deliveryDetails = {
            deliveryMethod: formData.deliveryMethod,
            deliveryAddress: formData.deliveryMethod !== DeliveryMethod.Handover ? formData.deliveryAddress : undefined,
            date:
              formData.deliveryMethod === DeliveryMethod.Handover ||
              devicePossession.possessionType === DevicePossessionType.ZeltStorage
                ? formData.date
                : null,
          };
        }

        const newPossessionDetails = {
          possessionType: formData.possessionType,
          possessionId: formData.possessionId,
        };

        setLoading(true);
        if (formData.deliveryMethod === DeliveryMethod.AssignDepToUser) {
          await DeviceAPI.assignDepDeviceToUser(devicePossession.id, newPossessionDetails, deliveryDetails);
        } else {
          await DeviceAPI.reassignInventoryDevicePossession(devicePossession.id, newPossessionDetails, deliveryDetails);
        }
        showMessage('Device owner updated.', 'success');
        isOwnerChangeInitiated = true;
        const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
        routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { devices: alertsForDevices },
        });
      } catch (error) {
        if (isOwnerChangeInitiated) {
          showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
        } else {
          showMessage(`Could not reassign device: ${nestErrorMessage(error)}`, 'error');
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const setUserAddress = useCallback(
    async (assignedUserId: number): Promise<string> => {
      try {
        const userAddress = await UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          return formatAddress(effectiveAddress);
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }

      return '';
    },
    [showMessage]
  );

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesOptions(
          sites // exclude the site where the device is stored (if possessionType == CompanySite)
            .filter(
              (site) =>
                !(
                  devicePossession.possessionType === DevicePossessionType.CompanySite &&
                  site.id === devicePossession.possessionId
                )
            )
            .map((site) => {
              return { value: site.id, label: site.name, address: site.address ?? '' };
            })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage, devicePossession]);
  const additionalOption: { value: DeliveryMethod; label: string } = {
    label: 'Assign dep device to user',
    value: DeliveryMethod.AssignDepToUser,
  };
  const options = DeliveryMethodsOptions.filter((o) =>
    devicePossession.possessionType === DevicePossessionType.ZeltStorage
      ? o.value !== DeliveryMethod.Handover
      : o.value !== DeliveryMethod.ShippingToSite
  );
  options.push(additionalOption);
  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx} onSubmit={formik.handleSubmit}>
        <Typography variant="title2">Assign device</Typography>
        <SelectComponent
          name="deliveryMethod"
          label="Assign method"
          options={options}
          value={formik.values.deliveryMethod}
          compareValue={formik.values.deliveryMethod}
          onChange={(e) => {
            formik.handleChange(e);

            formik.setFieldValue(
              'possessionType',
              e.target.value === DeliveryMethod.ShippingToSite
                ? DevicePossessionType.CompanySite
                : DevicePossessionType.User
            );

            formik.setFieldValue('possessionId', undefined);
            formik.setFieldValue('deliveryAddress', '');
            formik.setFieldValue('deliveryDate', null);
            formik.setFieldValue('deviceAccountName', null);
          }}
          error={!!formik.errors.deliveryMethod && formik.touched.deliveryMethod}
          helperText={(formik.touched.deliveryMethod && formik.errors.deliveryMethod) as string}
        />

        {[DeliveryMethod.ShippingToEmployee, DeliveryMethod.Handover, DeliveryMethod.AssignDepToUser].includes(
          formik.values.deliveryMethod
        ) ? (
          <SingleUserSelect
            name="userId"
            options="company"
            value={formik.values.possessionId}
            onChange={async (_, x: unknown) => {
              const userId = (x as { value: number })?.value ?? null;
              await formik.setFieldValue('possessionId', userId);
              if (userId) {
                const address = await setUserAddress(userId);
                await formik.setFieldValue('deliveryAddress', address);
              }
            }}
            label="Employee"
            error={Boolean(formik.errors.possessionId)}
            helperText={formik.errors.possessionId}
          />
        ) : (
          <SelectComponent
            name="possessionId"
            label="Site"
            options={sitesOptions}
            value={formik.values.possessionId}
            compareValue={formik.values.possessionId}
            error={!!formik.errors.possessionId && formik.touched.possessionId}
            helperText={(formik.touched.possessionId && formik.errors.possessionId) as string}
            onChange={(e) => {
              formik.handleChange(e);
              const siteAddress = sitesOptions.find((s) => s.value === e.target.value)?.address ?? '';
              formik.setFieldValue('deliveryAddress', siteAddress);
            }}
          />
        )}

        {[DeliveryMethod.ShippingToEmployee, DeliveryMethod.ShippingToSite].includes(formik.values.deliveryMethod) && (
          <TextfieldComponent
            name="deliveryAddress"
            label="Delivery address"
            value={formik.values.deliveryAddress ?? ''}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.deliveryAddress && !!formik.errors.deliveryAddress}
            helperText={(formik.touched.deliveryAddress && formik.errors.deliveryAddress) as string}
            clearText={() => formik.setFieldValue('deliveryAddress', '')}
          />
        )}

        {formik.values.deliveryMethod === DeliveryMethod.AssignDepToUser && (
          <TextfieldComponent
            name="deviceAccountName"
            label="Device account name"
            value={formik.values.deviceAccountName ?? ''}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.deviceAccountName && !!formik.errors.deviceAccountName}
            helperText={(formik.touched.deviceAccountName && formik.errors.deviceAccountName) as string}
            clearText={() => formik.setFieldValue('deviceAccountName', '')}
            tooltip={
              'To set up your device, enter a unique name for the account. If not provided, the email of the user will be used as the account name.'
            }
          />
        )}
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Assign"
            loading={loading}
            disabled={!formik.isValid}
            colorVariant="primary"
            sizeVariant="medium"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
