import React, { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CustomProfileFormAPI } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { CustomProfileFormDto, UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { ShowCustomFieldComponent } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly userId: number;
  readonly form: CustomProfileFormDto;
  readonly fields: UserCustomDataDto[];
  readonly usedForDataImport?: boolean;
  readonly usedForOnboarding?: boolean;
  readonly importHandler?: (values: UserCustomDataDto[]) => void;
  readonly onSubmit: (values: UserCustomDataDto[]) => void;
  readonly onClose?: () => void;
}

export const CustomSectionForm = ({
  userId,
  form,
  fields,
  onSubmit,
  onClose,
  usedForDataImport,
  usedForOnboarding,
  importHandler,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [customUpdates, setCustomUpdates] = useState<UserCustomDataDto[]>(fields);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: async () => {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          await CustomProfileFormAPI.setFormValues(userId, form.formId, customUpdates);
          onSubmit(customUpdates);
        } else {
          importHandler?.(customUpdates);
        }
        onClose?.();
      } catch (error) {
        showMessage(`Update could not be saved. ${nestErrorMessage(error)}.`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{usedForOnboarding ? form.formName : `Edit ${form.formName}`}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          {sortCustomFields(customUpdates).map((f, idx) => (
            <ShowCustomFieldComponent
              key={f.field.fieldId}
              field={f}
              setCustomUpdates={setCustomUpdates}
              autoFocus={idx === 0}
            />
          ))}
        </Box>
        <Box sx={buttonBoxDrawerSx}>
          {!usedForOnboarding && (
            <ButtonComponent
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </ButtonComponent>
          )}
          <LoaderButton
            name={usedForOnboarding ? polyglot.t('General.continue') : 'Save'}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
