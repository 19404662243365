import { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { ChipSelect } from '@v2/components/table/chip-select.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer, FiltersOption } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import {
  ErrorComponent,
  LoadingTableComponent,
} from '@v2/feature/reports/features/create-report/sections/util-sections.component';
import { ReportsAPI } from '@v2/feature/reports/reports.api';
import { CreateReport, FormDataInterface, ReportConfig, ReportEntity } from '@v2/feature/reports/reports.interface';
import { exportReportCSVOldReports } from '@v2/feature/reports/util/report.util';
import { TimeBalancesReportColumnsOptions } from '@v2/feature/reports/util/time-report.util';
import { UserAPI } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export const TimeReportBalancesResults = ({
  formData,
  setFormData,
  filters,
}: {
  readonly formData: FormDataInterface;
  readonly setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  readonly filters: FiltersOption;
}) => {
  const { polyglot } = usePolyglot();

  const { data: absencePolicies } = useApiClient<AbsencePolicyDto[], Error>(AbsenceEndpoints.getAbsencePolicies(), {
    suspense: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>(polyglot.t('TimeReportBalancesResults.new'));
  const [filterString, setFilterString] = useState<string>('');
  const [filteredData, setFilteredData] = useState<Record<string, string | number>[] | undefined>(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    TimeBalancesReportColumnsOptions(polyglot).map((o) => o.value)
  );

  const [selectedPolicy, setSelectedPolicy] = useState<AbsencePolicyDto | undefined>(undefined);

  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [reportError, setReportError] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (absencePolicies) setSelectedPolicy(absencePolicies[0]);
  }, [absencePolicies]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData?.filter(
        (data) =>
          !searchInput ||
          (data?.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  }, [searchInput, filteredData]);

  const getFilteredData = useCallback(async () => {
    setReportLoading(true);
    try {
      if (!selectedPolicy) return;
      const paramObj: ReportConfig = {
        domain: formData.domain,
        entities: [ReportEntity.Balances],
        type: formData.type,
        filters: filterString,
        columns: { [ReportEntity.Balances]: selectedColumns },
        singleNumericFilter1: selectedPolicy.id,
      };

      let filteredTimeBalances = await ReportsAPI.generateTimeCreatedReport(paramObj);
      setReportError(false);
      setFilteredData(filteredTimeBalances[ReportEntity.Balances]);
    } catch (error) {
      setReportError(true);
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setReportLoading(false);
    }
  }, [selectedColumns, filterString, formData, showMessage, selectedPolicy]);

  useEffect(() => {
    getFilteredData();
  }, [getFilteredData]);

  const handleSave = useCallback(async () => {
    try {
      if (!selectedPolicy) return;
      setLoading(true);
      const createReport: CreateReport = {
        domain: formData.domain,
        type: formData.type,
        entities: [ReportEntity.Balances],
        filters: filterString,
        columns: { [ReportEntity.Balances]: selectedColumns },
        fileName: reportName,
        singleNumericFilter1: selectedPolicy.id,
      };
      await ReportsAPI.saveReport(createReport);
      setFormData({ ...formData, filters: filterString, columns: { [ReportEntity.Balances]: selectedColumns } });
      showMessage(polyglot.t('TimeReportBalancesResults.successMessages.save'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [
    polyglot,
    formData,
    filterString,
    selectedColumns,
    reportName,
    selectedPolicy,
    setFormData,
    showMessage,
    routerHistory,
  ]);

  const exportCsv = useCallback(async () => {
    if (!selectedPolicy || !filteredData) return;
    try {
      exportReportCSVOldReports(
        reportName,
        filteredData ? { [ReportEntity.Balances]: filteredData } : undefined,
        `report-Balances-${selectedPolicy.name}.csv`
      );
    } catch (e) {
      showMessage(polyglot.t('TimeReportBalancesResults.errorMessages.download'), 'error');
    }
  }, [reportName, selectedPolicy, filteredData, showMessage, polyglot]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      {reportLoading && <LoadingTableComponent />}
      {!reportLoading && reportError && <ErrorComponent />}
      {!reportLoading && filteredData && (
        <Box sx={{ width: '80%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10, minHeight: '60px' }}>
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={
                state.user.features?.report?.tooltip?.saveReportName
                  ? undefined
                  : {
                      open: true,
                      title: (
                        <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                            {polyglot.t('TimeReportBalancesResults.typeReport')}
                          </Typography>
                          <ButtonComponent
                            sizeVariant="medium"
                            colorVariant="secondary"
                            onClick={async () => {
                              const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                                'report',
                                'tooltip',
                                'saveReportName',
                                true
                              );
                              dispatch({
                                type: GlobalStateActions.UPDATE_USER,
                                payload: updatedGlobalUser,
                              });
                            }}
                          >
                            {polyglot.t('TimeReportBalancesResults.okay')}
                          </ButtonComponent>
                        </Stack>
                      ),
                    }
              }
            />
          </Box>

          <Box sx={{ display: 'flex', ...spacing.mt20, gap: spacing.g5 }}>
            <ChipSelect
              title={selectedPolicy?.name ?? polyglot.t('TimeReportBalancesResults.selectPolicy')}
              options={
                absencePolicies?.map((p) => ({
                  label: p.name,
                  handler: () => setSelectedPolicy(p),
                })) ?? []
              }
            />

            <ColumnsDrawer
              columnsOptions={TimeBalancesReportColumnsOptions(polyglot)}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
            <FiltersDrawer
              filtersOptions={[{ filters }]}
              selectedFilters={filterString}
              setSelectedFilters={setFilterString}
              encodedFilterNames
            />
            <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
          </Box>

          <Box sx={{ ...spacing.mt40 }}>
            {filteredAndSearchedData && (
              <ResultTableOldReports filteredData={filteredAndSearchedData} loading={false} />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: spacing.g10,
              ...spacing.mt40,
              width: '50%',
            }}
          >
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
              onClick={handleSave}
            />
            <ButtonComponent
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={exportCsv}
              startIcon={<Export {...iconSize} />}
            >
              {polyglot.t('TimeReportBalancesResults.downloadCsv')}
            </ButtonComponent>
          </Box>
        </Box>
      )}

      {/*{!reportLoading && filteredData && filteredData.length === 0 && !reportError && !filterString && (*/}
      {/*  <NoDataComponent />*/}
      {/*)}*/}
    </Box>
  );
};
