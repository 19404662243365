import React, { Dispatch, SetStateAction, Suspense, useCallback, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType, DevicePossessionTypeOptions } from '@v2/feature/device/device.interface';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

type FormProps = Pick<
  DevicePossessionDto,
  'companyId' | 'possessionType' | 'possessionId' | 'startDate' | 'endDate' | 'deliveryAddress'
>;

const validationSchema = yup.object({
  companyId: yup
    .number()
    .nullable()
    .typeError('Please select a valid company id')
    .integer('Please select a valid company id')
    .notRequired(),
  possessionType: yup
    .string()
    .oneOf(Object.values(DevicePossessionType), 'Please select a valid possession type.')
    .required('Device possession type is required'),
  possessionId: yup
    .number()
    .typeError('This field should be an integer')
    .integer('This field should be an integer')
    .required('Possession ID is required'),
  startDate: yup.string().nullable().notRequired(),
  endDate: yup.string().nullable().notRequired(),
  deliveryAddress: yup.string().notRequired(),
});

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly deviceId: number;
  readonly devicePossession: DevicePossessionDto | null;
  readonly refreshPossessions: () => Promise<void>;
}

export const SuperAdminDeviceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  deviceId,
  devicePossession,
  refreshPossessions,
}: DrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminDeviceDetailsDrawerContent
        deviceId={deviceId}
        devicePossession={devicePossession}
        refreshPossessions={refreshPossessions}
        setIsOpen={setIsOpen}
      />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly deviceId: number;
  readonly devicePossession: DevicePossessionDto | null;
  readonly refreshPossessions: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const SuperAdminDeviceDetailsDrawerContent = ({
  deviceId,
  devicePossession,
  refreshPossessions,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const updatePossession = useCallback(
    async (values: FormProps) => {
      if (!devicePossession) return;

      const updateData: Pick<
        DevicePossessionDto,
        'companyId' | 'possessionType' | 'possessionId' | 'startDate' | 'endDate' | 'deliveryAddress'
      > = {
        companyId: values.companyId ? Number(values.companyId) : null,
        possessionType: values.possessionType,
        possessionId: Number(values.possessionId),
        startDate: values.startDate ?? null,
        endDate: values.endDate ?? null,
        deliveryAddress: values.deliveryAddress ?? '',
      };

      try {
        setLoading(true);
        await DeviceAPI.updateDevicePossessionAsSuperAdmin(devicePossession.deviceId, devicePossession.id, updateData);
        await refreshPossessions();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
      setLoading(false);
    },
    [showMessage, refreshPossessions, devicePossession, setIsOpen]
  );

  const addPossession = useCallback(
    async (values: FormProps) => {
      if (devicePossession) return;

      const createData: Pick<
        DevicePossessionDto,
        'companyId' | 'possessionType' | 'possessionId' | 'startDate' | 'endDate' | 'deliveryAddress'
      > = {
        companyId: values.companyId ? Number(values.companyId) : null,
        possessionType: values.possessionType,
        possessionId: Number(values.possessionId),
        startDate: values.startDate ?? null,
        endDate: values.endDate ?? null,
        deliveryAddress: values.deliveryAddress ?? '',
      };
      try {
        setLoading(true);
        await DeviceAPI.createDevicePossessionAsSuperAdmin(deviceId, createData);
        await refreshPossessions();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
      setLoading(false);
    },
    [devicePossession, deviceId, refreshPossessions, showMessage, setIsOpen]
  );

  const formik = useFormik<FormProps>({
    initialValues: {
      companyId: devicePossession?.companyId ?? null,
      possessionType: devicePossession?.possessionType ?? DevicePossessionType.ZeltStock,
      possessionId: devicePossession?.possessionId ?? 0,
      startDate: devicePossession?.startDate ?? null,
      endDate: devicePossession?.endDate ?? null,
      deliveryAddress: devicePossession?.deliveryAddress ?? '',
    },
    validationSchema,
    onSubmit: async (values: FormProps) => (devicePossession ? updatePossession(values) : addPossession(values)),
  });

  const deletePossession = useCallback(async () => {
    if (!devicePossession) return;
    try {
      setLoading(true);
      setAnchorEl(null);
      await DeviceAPI.deleteDevicePossessionAsSuperAdmin(devicePossession.id, devicePossession.deviceId);
      await refreshPossessions();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
    setLoading(false);
  }, [devicePossession, showMessage, refreshPossessions, setIsOpen]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Possession details</Typography>
          {devicePossession && (
            <IconButton
              sx={tableIconButtonSx}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Trash {...iconSize} />
            </IconButton>
          )}
        </Box>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>Device ID: {deviceId}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextfieldComponent
            label="Company ID"
            name="companyId"
            value={formik.values.companyId}
            onChange={formik.handleChange}
            error={formik.touched.companyId && Boolean(formik.errors.companyId)}
            helperText={formik.touched.companyId && formik.errors.companyId}
            size="small"
            endAdornment="clear-text"
            clearText={() => formik.setFieldValue('companyId', null)}
          />

          <SelectComponent
            name="possessionType"
            label="Possession type"
            options={DevicePossessionTypeOptions}
            value={formik.values.possessionType}
            compareValue={formik.values.possessionType}
            onChange={formik.handleChange}
            error={formik.touched.possessionType && !!formik.errors.possessionType}
            helperText={formik.touched.possessionType && formik.errors.possessionType}
          />

          <TextfieldComponent
            label="Possession ID"
            name="possessionId"
            value={formik.values.possessionId}
            onChange={formik.handleChange}
            error={formik.touched.possessionId && Boolean(formik.errors.possessionId)}
            helperText={formik.touched.possessionId && formik.errors.possessionId}
            size="small"
            endAdornment="clear-text"
            clearText={() => formik.setFieldValue('possessionId', 0)}
          />

          <TextfieldComponent
            label="Start date"
            name="startDate"
            placeholder="YYYY-MM-DD"
            value={formik.values.startDate}
            onChange={formik.handleChange}
            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
            helperText={formik.touched.startDate && formik.errors.startDate}
            endAdornment="clear-text"
            clearText={() => formik.setFieldValue('startDate', null)}
            size="small"
          />

          <TextfieldComponent
            label="End date"
            name="endDate"
            placeholder="YYYY-MM-DD"
            value={formik.values.endDate}
            onChange={formik.handleChange}
            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
            helperText={formik.touched.endDate && formik.errors.endDate}
            endAdornment="clear-text"
            clearText={() => formik.setFieldValue('endDate', null)}
            size="small"
          />

          <TextfieldComponent
            label="Delivery address"
            name="deliveryAddress"
            value={formik.values.deliveryAddress}
            onChange={formik.handleChange}
            error={formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress)}
            helperText={formik.touched.deliveryAddress && formik.errors.deliveryAddress}
            size="small"
            endAdornment="clear-text"
            clearText={() => formik.setFieldValue('deliveryAddress', '')}
          />
        </Box>

        <Box sx={{ mt: '30px' }}>
          <LoaderButton
            name={devicePossession ? 'Update' : 'Create'}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>

        <NotificationModal
          isOpen={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorEl={anchorEl}
          takeAction={deletePossession}
          message="Are you sure you want to delete this possession?"
          callToAction="Delete"
        />
      </Form>
    </FormikProvider>
  );
};
