import React, { useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup, capitalize } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { CalendarSettingsAPI } from '@/v2/feature/calendar/calendar.api';
import {
  CalendarSettingProps,
  ViewSettingEnum,
  ViewSettingValueArray,
  ViewSettingsOptionLabel,
} from '@/v2/feature/calendar/calendar.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { borders } from '@/v2/styles/borders.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface CalendarSettingDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly initialValues: CalendarSettingProps | undefined;
  readonly refresh: () => Promise<void>;
}

const CalendarSettingSchema = Yup.object().shape({
  viewSetting: Yup.string().oneOf(ViewSettingValueArray).nullable().required(),
});

export const CalendarSettingDrawer = ({ isOpen, setIsOpen, initialValues, refresh }: CalendarSettingDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <CalendarSettingDrawerContent initialValues={initialValues} setIsOpen={setIsOpen} refresh={refresh} />
    </DrawerModal>
  );
};

const CalendarSettingDrawerContent = ({
  setIsOpen,
  initialValues,
  refresh,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly initialValues: CalendarSettingProps | undefined;
  readonly refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues:
      initialValues && initialValues.viewSetting ? initialValues : { viewSetting: ViewSettingEnum.Company },
    enableReinitialize: true,
    validationSchema: CalendarSettingSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let updatedValue = { ...(initialValues?.id ? { id: initialValues.id } : {}), ...values };
        await CalendarSettingsAPI.upsertSettings(updatedValue);
        showMessage(polyglot.t('CalendarSettingDrawerContent.successMessages.update'), 'success');
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('CalendarSettingDrawerContent.errorMessages.encounter', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        await refresh();
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography variant="title2" sx={{ marginBottom: spacing.m20 }}>
          {polyglot.t('CalendarSettingDrawerContent.title')}
        </Typography>

        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            name="viewSetting"
            onChange={(e) => {
              formik.setFieldValue('viewSetting', e.target.value);
            }}
            sx={{ flexFlow: 'column' }}
          >
            {ViewSettingsOptionLabel(polyglot).map((item, idx, arr) => (
              <FormControlLabel
                labelPlacement="start"
                value={item.value}
                checked={formik.values.viewSetting === item.value}
                control={<StyledRadio />}
                disabled={false}
                label={
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                    <Typography variant="caption">{capitalize(item.label)}</Typography>
                  </Box>
                }
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  margin: 0,
                  paddingY: spacing.p10,
                  borderBottom: idx < arr.length - 1 ? borders.background : 'none',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Box sx={spacing.mt40}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth={true}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
