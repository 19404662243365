import React, { useMemo } from 'react';

import { Box, Stack, SxProps } from '@mui/material';
import { MultiUserAvatar } from '@v2/components/theme-components/multi-user-avatar.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

const translateStepNo = (stepNo: number, noOfApprovers: number, polyglot: Polyglot): string => {
  if (polyglot.has(`ApprovalRuleModule.ApprovalSteps.${stepNo}`))
    return polyglot.t(`ApprovalRuleModule.ApprovalSteps.${stepNo}`, { noOfApprovers });

  return polyglot.t(`ApprovalRuleModule.ApprovalSteps.x`, { stepNo, noOfApprovers });
};

interface ApproversListProps {
  readonly approverSteps: ApproverStep[] | undefined | null;
  readonly sx?: SxProps;
  readonly layout?: 'vertical' | 'horizontal';
  readonly rowAvatarsLimit?: number;
  readonly labelVariant?: 'caption' | 'captionSmall';
  readonly verticalGap?: string;
}

export const ApproversList = ({
  approverSteps,
  sx = {},
  layout = 'vertical',
  rowAvatarsLimit = 5,
  labelVariant = 'captionSmall',
  verticalGap = '4px',
}: ApproversListProps) => {
  const { polyglot } = usePolyglot();
  const isAutoApprove = useMemo((): boolean => {
    return (
      !approverSteps || approverSteps.length === 0 || approverSteps.every((step) => step.approversIds.length === 0)
    );
  }, [approverSteps]);

  return isAutoApprove ? null : (
    <Stack gap="20px" sx={{ ...sx }}>
      {approverSteps?.map((step, index) => {
        return layout === 'vertical' ? (
          <Stack gap={verticalGap}>
            <Typography variant={labelVariant} color="Grey">
              {translateStepNo(index + 1, step.minApprovers, polyglot)}
            </Typography>
            <MultiUserAvatar key={index} userIds={step.approversIds} showLimit={rowAvatarsLimit} />
          </Stack>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant={labelVariant}>{translateStepNo(index + 1, step.minApprovers, polyglot)}</Typography>
            <MultiUserAvatar key={index} userIds={step.approversIds} showLimit={rowAvatarsLimit} />
          </Box>
        );
      })}
    </Stack>
  );
};
