export const PensionProviders = {
  Nest: 'Nest',
  SmartPension: 'SmartPension_Employer',
  PeoplesPension: 'PeoplesPension',
  Cushon: 'Cushon',
  OtherProvider: 'OtherProvider',
};

export const ReliefAtSource = {
  value: 'ReliefAtSource',
  label: 'Relief at Source',
};
export const NetPayArrangement = {
  value: 'NetPayArrangement',
  label: 'Net Pay',
};
export const SalarySacrifice = {
  value: 'SalarySacrifice',
  label: 'Salary Sacrifice',
};

export const PensionRulesOptions = [ReliefAtSource, NetPayArrangement, SalarySacrifice];

export const PaymentCredit = { value: 'Credit', label: 'Wire transfer' };
export const PaymentDirectDebit = {
  value: 'DirectDebit',
  label: 'Direct Debit',
};
export const PaymentValues = [PaymentDirectDebit, PaymentCredit];

export const EnrollmentStateOptIn = {
  value: 'OptIn',
  label: 'Opt In',
  details: 'The employee has chosen to opt-in to an Auto Enrolment pension.',
};
export const EnrollmentStateVoluntary = {
  value: 'VoluntaryJoiner',
  label: 'Voluntary Joiner',
  details: 'A normally excluded employee has chosen to join an Auto Enrolment pension.',
};
export const EnrollmentStateAgeMissing = {
  value: 'AgeMissing',
  label: 'AgeMissing',
  details: 'Please provide your date of birth in the first step to see if you can join this benefit. ',
};
export const EnrollmentStateSalaryMissing = {
  value: 'SalaryMissing',
  label: 'SalaryMissing',
  details:
    'Seems like there is some information missing from your admin. You can come back to check your pension membership later in the Pension app.',
};
export const EnrollmentStateEnrol = {
  value: 'Enrol',
  label: 'Enrol',
  details: 'Force the Enrolment in an AE Pension. State then reverts to Automatic.',
};

export const EnrollmentStateAutomatic = {
  value: 'Automatic',
  label: 'Automatic',
  details: 'Assess the employee as normal.',
};

export const AssessmentStatusEligible = {
  value: 'Eligible',
  label: 'Eligible Jobholder',
};
export const AssessmentStatusNonEligible = {
  value: 'NonEligible',
  label: 'Non-eligible Jobholder',
};
export const AssessmentStatusEntitled = {
  value: 'Entitled',
  label: 'Entitled worker',
};
export const AssessmentStatusNoDuties = {
  value: 'NoDuties',
  label: 'No Duties',
};

export const StatusToStateMapping = {
  [AssessmentStatusEligible.value]: EnrollmentStateEnrol.value,
  [AssessmentStatusNonEligible.value]: EnrollmentStateOptIn.value,
  [AssessmentStatusEntitled.value]: EnrollmentStateVoluntary.value,
  [AssessmentStatusNoDuties.value]: EnrollmentStateVoluntary.value,
};
