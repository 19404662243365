import { useCallback, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { TabFilterButtons } from '@v2/components/tab-filter-buttons.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { InsuranceAddToPolicyDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/components/insurance-add-to-policy-drawer.component';
import { InsuranceAddDependantsDrawer } from '@v2/feature/benefits/subfeature/insurance/components/insurance-add-dependants-drawer.component';
import { InsuranceEditUserPolicyDrawer } from '@v2/feature/benefits/subfeature/insurance/components/insurance-edit-user-policy-drawer.component';
import { InsuranceRemoveFromPolicyDrawer } from '@v2/feature/benefits/subfeature/insurance/components/insurance-remove-from-policy-drawer.component';
import { InsuranceEndpoints } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto, UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { UserInsurancePolicyStatus } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import Polyglot from 'node-polyglot';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as ArrowRight } from '@/images/side-bar-icons/Next.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as People } from '@/images/side-bar-icons/People.svg';
import { ReactComponent as Reject } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected-grey.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { nestErrorMessage } from '@/lib/errors';
import { INSURANCE_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

interface InsuranceCompanyEmployeeListPageProps {
  readonly insurancePolicy: InsurancePolicyDto;
  readonly loading: boolean;
}

const getTabFilter = (polyglot: Polyglot) => [
  { name: polyglot.t('General.all'), value: 'All' },
  { name: polyglot.t('BenefitModule.covered'), value: 'Covered' },
  { name: polyglot.t('General.pending'), value: 'Pending' },
  { name: polyglot.t('BenefitModule.notCovered'), value: 'NotCovered' },
  { name: polyglot.t('BenefitModule.optedOut'), value: 'OptOut' },
];

// TODO: @benefits-later insurance members table
export const InsuranceCompanyEmployeeListPage = ({
  insurancePolicy,
  loading,
}: InsuranceCompanyEmployeeListPageProps) => {
  const {
    data: userInsuranceRecords,
    mutate: refreshUserRecords,
    isLoading,
  } = useApiClient(InsuranceEndpoints.getAllUsersInsurancePolicyById(insurancePolicy.id), { suspense: false });

  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers({ refresh: true });

  const [selectedUserInsurance, setSelectedUserInsurance] = useState<UserInsuranceDto | null>(null);
  const [isAddDependantsDrawerOpen, setIsAddDependantsDrawerOpen] = useState<boolean>(false);
  const [isAddToPolicyDrawerOpen, setIsAddToPolicyDrawerOpen] = useState<boolean>(false);
  const [isRemoveDrawerOpen, setIsRemoveDrawerOpen] = useState<boolean>(false);

  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState<boolean>(false);

  const [filterValue, setFilterValue] = useState<string>('All');
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredUserRecords = useMemo(() => {
    let filteredUserRecords = nonTerminatedCachedUsers
      .map((user) => {
        const userInsuranceRecord = userInsuranceRecords?.find((record) => record.userId === user.userId);

        return (
          userInsuranceRecord ??
          ({
            userId: user.userId,
            policyId: insurancePolicy.id,
            status: null,
            startDate: null,
            endDate: null,
            monthlyPremium: null,
            monthlyContribution: null,
            dependants: null,
            dependantsMonthlyPremium: null,
            dependantsMonthlyContribution: null,
          } as UserInsuranceDto)
        );
      })
      .sort((a, b) => {
        let aScore = 0,
          bScore = 0;
        if (a.status === UserInsurancePolicyStatus.Active && a.monthlyPremium === null) aScore = 5;
        if (a.status === UserInsurancePolicyStatus.Active && a.monthlyPremium !== null) aScore = 4;
        if (a.status === null) aScore = 3;
        if (a.status === UserInsurancePolicyStatus.OptOut) aScore = 2;

        if (b.status === UserInsurancePolicyStatus.Active && b.monthlyPremium === null) bScore = 5;
        if (b.status === UserInsurancePolicyStatus.Active && b.monthlyPremium !== null) bScore = 4;
        if (b.status === null) bScore = 3;
        if (b.status === UserInsurancePolicyStatus.OptOut) bScore = 2;

        return bScore - aScore;
      });

    if (filterValue !== 'All') {
      filteredUserRecords = filteredUserRecords.filter((userInsurance) => {
        if (filterValue === 'Covered') return userInsurance?.status === UserInsurancePolicyStatus.Active;
        if (filterValue === 'Pending')
          return (
            userInsurance?.status === UserInsurancePolicyStatus.Pending ||
            userInsurance?.status === UserInsurancePolicyStatus.PendingOptOut
          );
        if (filterValue === 'OptOut') return userInsurance?.status === UserInsurancePolicyStatus.OptOut;
        if (filterValue === 'NotCovered')
          return (
            !userInsurance?.status ||
            ![UserInsurancePolicyStatus.Active, UserInsurancePolicyStatus.Pending].includes(userInsurance.status)
          );
        return true;
      });
    }

    if (searchInput) {
      filteredUserRecords = filteredUserRecords.filter((userInsurance) => {
        return getCachedUserById(userInsurance.userId)?.displayName.toLowerCase().includes(searchInput.toLowerCase());
      });
    }

    return filteredUserRecords;
  }, [filterValue, getCachedUserById, insurancePolicy.id, nonTerminatedCachedUsers, searchInput, userInsuranceRecords]);

  const getUserRecords = useCallback(async () => {
    try {
      if (refreshUserRecords) await refreshUserRecords();
    } catch (error) {
      showMessage(
        polyglot.t('BenefitModule.ErrorMessages.couldNotRetrieveUserRecords', {
          errorMessage: nestErrorMessage(error),
        }),
        'error'
      );
    }
  }, [polyglot, refreshUserRecords, showMessage]);

  const columnData = useMemo<ColumnDef<UserInsuranceDto, UserInsuranceDto>[]>(() => {
    return [
      {
        header: () => polyglot.t('General.name'),
        accessorFn: (row) => row,
        id: 'userId',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          return <UserCell userId={userInsurance.userId} />;
        },
        maxSize: 250,
        minSize: 150,
      },
      {
        header: () => polyglot.t('BenefitModule.dependants'),
        accessorFn: (row) => row,
        id: 'dependants',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          return userInsurance.dependants ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {userInsurance.dependants}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 250,
        minSize: 150,
      },
      {
        header: () => polyglot.t('BenefitModule.employeePremium'),
        accessorFn: (row) => row,
        id: 'employeePremium',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          let totalEmployeePremium = userInsurance.monthlyPremium ?? 0;
          if (userInsurance.dependants && userInsurance.dependantsMonthlyPremium)
            totalEmployeePremium += userInsurance.dependantsMonthlyPremium ?? 0;
          return userInsurance.monthlyPremium ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: totalEmployeePremium })}
            </Typography>
          ) : userInsurance.status === UserInsurancePolicyStatus.Active ? (
            <StyledTooltip
              title={
                <div>
                  <Typography sx={{ ...themeFonts.tooltip, padding: 0, margin: 0 }}>
                    {polyglot.t('BenefitModule.missingEmployeePremium')}
                  </Typography>

                  <ButtonComponent
                    sizeVariant="small"
                    colorVariant="tooltip"
                    onClick={() => {
                      setSelectedUserInsurance(userInsurance);
                      setIsUpdateDrawerOpen(true);
                    }}
                  >
                    {polyglot.t('BenefitModule.completeInfoHere')}{' '}
                    <ArrowRight width={12} height={12} style={{ fill: themeColors.Grey }} />
                  </ButtonComponent>
                </div>
              }
            >
              <Mistake {...iconSize} />
            </StyledTooltip>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 125,
        minSize: 125,
      },
      {
        header: () => polyglot.t('BenefitModule.employeeContribution'),
        accessorFn: (row) => row,
        id: 'employeeContribution',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          let totalEmployeeContributions = userInsurance.monthlyContribution ?? 0;
          if (userInsurance.dependants && userInsurance.dependantsMonthlyContribution)
            totalEmployeeContributions += userInsurance.dependantsMonthlyContribution;
          return totalEmployeeContributions ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: totalEmployeeContributions })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 125,
        minSize: 125,
      },
      {
        header: () => polyglot.t('General.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          return userInsurance?.status === UserInsurancePolicyStatus.Active ? (
            <Typography
              sx={{
                ...themeFonts.caption,
                gap: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <OkGreen {...iconSize} style={{ fill: themeColors.Green }} /> {polyglot.t('BenefitModule.covered')}
            </Typography>
          ) : userInsurance?.status === UserInsurancePolicyStatus.OptOut ? (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                gap: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rejected {...iconSize} style={{ fill: themeColors.GreyMiddle }} /> {polyglot.t('BenefitModule.optedOut')}
            </Typography>
          ) : userInsurance?.status === UserInsurancePolicyStatus.Pending ? (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                gap: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Waiting {...iconSize} style={{ fill: themeColors.GreyMiddle }} /> {polyglot.t('General.pending')}
            </Typography>
          ) : userInsurance?.status === UserInsurancePolicyStatus.PendingOptOut ? (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                gap: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Waiting {...iconSize} style={{ fill: themeColors.GreyMiddle }} />{' '}
              {polyglot.t('BenefitModule.pendingOptOut')}
            </Typography>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                gap: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Waiting {...iconSize} style={{ fill: themeColors.GreyMiddle }} />{' '}
              {polyglot.t('BenefitModule.notCovered')}
            </Typography>
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => polyglot.t('General.startDate'),
        accessorFn: (row) => row,
        id: 'startDate',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          return userInsurance.startDate ? (
            <Box>
              {new Date(userInsurance.startDate).toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Box>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 85,
        minSize: 85,
      },
      {
        header: () => polyglot.t('General.endDate'),
        accessorFn: (row) => row,
        id: 'endDate',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          return userInsurance.endDate ? (
            <Box>
              {new Date(userInsurance.endDate).toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Box>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 85,
        minSize: 85,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: (info: CellContext<UserInsuranceDto, UserInsuranceDto>) => {
          const userInsurance: UserInsuranceDto = info.getValue();
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                alignItems: 'center',
                gap: spacing.gap5,
              }}
            >
              {userInsurance.status === UserInsurancePolicyStatus.Active ? (
                <StyledMenuComponent
                  options={[
                    {
                      handler: () => {
                        setSelectedUserInsurance(userInsurance);
                        setIsAddDependantsDrawerOpen(true);
                      },
                      label: polyglot.t('BenefitModule.addDependants'),
                      icon: <People {...iconSize} />,
                      hidden: !userInsurance.monthlyPremium,
                    },
                    {
                      handler: () => {
                        setSelectedUserInsurance(userInsurance);
                        setIsUpdateDrawerOpen(true);
                      },
                      label: polyglot.t('BenefitModule.editPremium'),
                      icon: <Edit {...iconSize} />,
                    },
                    {
                      handler: async () => {
                        setSelectedUserInsurance(userInsurance);
                        setIsRemoveDrawerOpen(true);
                      },
                      label: polyglot.t('BenefitModule.removeFromPolicy'),
                      icon: <Reject {...iconSize} fill={themeColors.Red} />,
                    },
                  ]}
                  actionButtonDetails={{
                    type: 'iconButton',
                    colorVariant: 'secondary',
                    sizeVariant: 'small',
                    title: 'actions',
                    icon: <ActionsSmall {...iconSize} />,
                  }}
                />
              ) : null}
            </Box>
          );
        },
        maxSize: 50,
        minSize: 50,
      },
    ];
  }, [polyglot]);

  return (
    <RootStyle>
      <TopHeader title={polyglot.t('General.employees')} showBack backPath={INSURANCE_COMPANY_OVERVIEW_ROUTE} />
      <ContentWrapper loading={loading || isLoading} sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
          <TabFilterButtons
            filters={getTabFilter(polyglot)}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            hasSearch
            onFilterChange={({ filterValue, searchInput }) => {
              setFilterValue(filterValue);
              setSearchInput(searchInput);
            }}
          />
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => {
              setIsAddToPolicyDrawerOpen(true);
            }}
          >
            {polyglot.t('BenefitModule.addEmployees')}
          </ButtonComponent>
        </Box>
        <Box sx={{ marginTop: spacing.m15 }}>
          <BasicTable<UserInsuranceDto>
            rowData={filteredUserRecords}
            columnData={columnData}
            loading={loading}
            hidePagination
          />
        </Box>
        {isAddDependantsDrawerOpen && selectedUserInsurance && (
          <InsuranceAddDependantsDrawer
            isOpen={isAddDependantsDrawerOpen}
            setIsOpen={setIsAddDependantsDrawerOpen}
            insurancePolicy={insurancePolicy}
            userInsurance={selectedUserInsurance}
            refresh={getUserRecords}
            onClose={() => {
              setSelectedUserInsurance(null);
              setIsAddDependantsDrawerOpen(false);
            }}
          />
        )}
        {isUpdateDrawerOpen && selectedUserInsurance && (
          <InsuranceEditUserPolicyDrawer
            isOpen={isUpdateDrawerOpen}
            setIsOpen={setIsUpdateDrawerOpen}
            insurancePolicy={insurancePolicy}
            userInsurance={selectedUserInsurance}
            refresh={getUserRecords}
            onClose={() => {
              setSelectedUserInsurance(null);
              setIsUpdateDrawerOpen(false);
            }}
          />
        )}
        {isAddToPolicyDrawerOpen && (
          <InsuranceAddToPolicyDrawer
            isOpen={isAddToPolicyDrawerOpen}
            setIsOpen={setIsAddToPolicyDrawerOpen}
            insurancePolicy={insurancePolicy}
            refresh={getUserRecords}
            onClose={() => {
              setSelectedUserInsurance(null);
              setIsAddToPolicyDrawerOpen(false);
            }}
          />
        )}

        {selectedUserInsurance && (
          <InsuranceRemoveFromPolicyDrawer
            isOpen={isRemoveDrawerOpen}
            setIsOpen={setIsRemoveDrawerOpen}
            userInsurance={selectedUserInsurance}
            refresh={getUserRecords}
            onClose={() => {
              setSelectedUserInsurance(null);
              setIsRemoveDrawerOpen(false);
            }}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
