import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';

export const formatMoney = (parameters: {
  amount: number | string;
  asDecimal?: boolean;
  currency?: CurrencyShort;
  locale?: string | null;
  isCompact?: boolean;
}): string => {
  return Number(parameters.amount).toLocaleString(
    parameters.locale || 'en-GB',

    {
      currency: parameters.currency ?? DEFAULT_CURRENCY,
      maximumFractionDigits: parameters.isCompact ? 0 : 2,
      minimumFractionDigits: parameters.isCompact ? 0 : 2,
      notation: parameters.isCompact ? 'compact' : 'standard',
      style: parameters.asDecimal ? 'decimal' : 'currency',
    }
  );
};

export const formatPercentage = (percentageAmount: number, fractionDigits = 2): string => {
  return `${Number(percentageAmount).toFixed(fractionDigits)}%`;
};
