import React, { Dispatch, SetStateAction, Suspense, useMemo } from 'react';

import { Box, SxProps } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown } from '@v2/feature/absence/absence.interface';
import {
  getAccrualBreakdownValue,
  getAccrualCliffReason,
  getAccrualRoundingAdjustment,
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getOneOffAdjustmentFromBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';

import { ReactComponent as Warning } from '@/images/side-bar-icons/Warning.svg';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
}

export const AccrualDrawer = ({ isOpen, setIsOpen, absencePolicy, policyBreakdown }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <AccrualDrawerContent absencePolicy={absencePolicy} policyBreakdown={policyBreakdown} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
}

const AccrualDrawerContent = ({ absencePolicy, policyBreakdown }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const {
    totalAccrual,
    accruedFromAllowance,
    accruedFromPH,
    accrualStart,
    accrualEnd,
    carriedOver,
    expiredCarryOver,
    oneOffAdjustments,
    roundingAdjustment,
    accrualCliffReason,
  } = useMemo(() => {
    const totalAccrual = getAccrualBreakdownValue(absencePolicy, policyBreakdown, 'total', polyglot);
    const accruedFromAllowance = getAccrualBreakdownValue(absencePolicy, policyBreakdown, 'regular', polyglot, true);
    const accruedFromPH = getAccrualBreakdownValue(absencePolicy, policyBreakdown, 'publicHolidays', polyglot, true);

    const accrualCliffReason = getAccrualCliffReason(policyBreakdown, polyglot);

    const carriedOver = policyBreakdown.carryOver?.allowedUnitsThisCycle
      ? getCarryOverFromBreakdown(absencePolicy, policyBreakdown, polyglot, true)
      : null;
    const expiredCarryOver = policyBreakdown.carryOver?.expiredCarryOver
      ? getExpiredCarryOver(absencePolicy, policyBreakdown, polyglot, true)
      : null;

    const oneOffAdjustments = policyBreakdown.oneOffAdjustment
      ? getOneOffAdjustmentFromBreakdown(absencePolicy, policyBreakdown, polyglot, true)
      : null;

    const accrualStart = policyBreakdown.proRatedAbsenceAllowance.accrualStartDate
      ? new LocalDate(policyBreakdown.proRatedAbsenceAllowance.accrualStartDate).toLocaleDateString()
      : null;
    const accrualEnd = policyBreakdown.proRatedAbsenceAllowance.accrualEndDate
      ? new LocalDate(policyBreakdown.proRatedAbsenceAllowance.accrualEndDate).toLocaleDateString()
      : null;

    const roundingAdjustment = getAccrualRoundingAdjustment(absencePolicy, policyBreakdown, polyglot, true);

    return {
      totalAccrual,
      accruedFromAllowance,
      accruedFromPH,
      accrualStart,
      accrualEnd,
      carriedOver,
      expiredCarryOver,
      oneOffAdjustments,
      roundingAdjustment,
      accrualCliffReason,
    };
  }, [absencePolicy, policyBreakdown, polyglot]);

  return absencePolicy.allowance !== null && policyBreakdown.holidayAccrued !== null ? (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('AllowanceDrawer.accruedSoFar')}</Typography>

      {accrualStart !== null && (
        <PolicyLine label={polyglot.t('AllowanceDrawer.accrualCycleStart')} value={accrualStart} />
      )}
      {accrualEnd !== null && <PolicyLine label={polyglot.t('AllowanceDrawer.accrualCycleEnd')} value={accrualEnd} />}

      <PolicyLine label={polyglot.t('AllowanceDrawer.accruedAllowance')} value={accruedFromAllowance} />
      <PolicyLine label={polyglot.t('AllowanceDrawer.accruedPH')} value={accruedFromPH} />
      {roundingAdjustment !== null && (
        <PolicyLine label={polyglot.t('AllowanceDrawer.accrualRounding')} value={roundingAdjustment} />
      )}

      {accrualCliffReason !== null && (
        <Box sx={{ display: 'flex', gap: spacing.s1, alignItems: 'center' }}>
          <Warning width={16} height={16} style={{ fill: themeColors.orange }} />
          <Typography variant="caption" color="Grey">
            {accrualCliffReason}
          </Typography>
        </Box>
      )}

      {oneOffAdjustments !== null && (
        <PolicyLine label={polyglot.t('AllowanceDrawer.adjustments')} value={oneOffAdjustments} />
      )}

      {carriedOver !== null && (
        <PolicyLine
          label={polyglot.t('AllowanceDrawer.carriedOverInto', { year: policyBreakdown.holidayYear })}
          value={carriedOver}
        />
      )}
      {expiredCarryOver !== null && (
        <PolicyLine label={polyglot.t('AllowanceDrawer.carryOverExpired')} value={expiredCarryOver} />
      )}

      <Divider />

      <PolicyLine label={polyglot.t('AllowanceDrawer.accruedSoFar')} value={totalAccrual} />
    </Box>
  ) : null;
};

const PolicyLine = ({
  label,
  value,
  boldLabel = false,
  sx = {},
}: {
  label: string;
  value: string | number | null;
  boldLabel?: boolean;
  sx?: SxProps;
}) =>
  value !== null ? (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', ...sx }}>
      <Typography variant={boldLabel ? 'title4' : 'caption'}>{label}</Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  ) : null;
