import { useState, useMemo } from 'react';

type StateableArray<T> = {
  clear: () => void;
  delete: (...values: T[]) => void;
  includes: (value: T) => boolean;
  push: (...values: T[]) => number;
  forEach: Array<T>['forEach'];
  map: Array<T>['map'];
  splice: Array<T>['splice'];
  some: Array<T>['some'];
  every: Array<T>['every'];
  length: number;
  toArray: () => T[];
  update: (value: T, add: boolean | 'add' | 'remove') => void;
  [Symbol.iterator]: () => Iterator<T>;
};

export function useArray<T>(values: readonly T[]) {
  const [array, setArray] = useState([...values]);

  const stateArray = useMemo<StateableArray<T>>(() => {
    return {
      [Symbol('array')]: array,
      at(idx: number) {
        return array[idx];
      },
      clear() {
        setArray([]);
      },
      delete(...values: T[]) {
        const valuesToDelete = new Set(values);
        setArray(array.filter((item) => !valuesToDelete.has(item)));
      },
      includes(value: T) {
        return array.includes(value);
      },
      push(...items: T[]): number {
        const newArray = [...array, ...items];
        setArray(newArray);
        return newArray.length;
      },
      splice(start: number, deleteCount: number, ...items: T[]): T[] {
        const newArray = [...array];
        const deleted = newArray.splice(start, deleteCount, ...items);
        setArray(newArray);
        return deleted;
      },
      get forEach() {
        return array.forEach.bind(array);
      },
      get map() {
        return array.map.bind(array);
      },
      get some() {
        return array.some.bind(array);
      },
      get every() {
        return array.every.bind(array);
      },
      length: array.length,
      toArray() {
        return [...array];
      },
      update(value: T, add: boolean | 'add' | 'remove') {
        if (add === true || add === 'add') {
          this.push(value);
        } else {
          this.delete(value);
        }
      },
      get [Symbol.iterator]() {
        return array[Symbol.iterator].bind(array);
      },
    };
  }, [array]);

  return stateArray;
}
