import { useCallback, useEffect, useState } from 'react';

import { Box, MenuItem, Typography } from '@mui/material';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '@/images/side-bar-icons/ArrowUp.svg';
import { ReactComponent as CheckedCheckbox } from '@/images/side-bar-icons/CheckedCheckbox.svg';
import { ReactComponent as EmptyCheckbox } from '@/images/side-bar-icons/EmptyCheckbox.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledMenu } from '@/v2/components/table/table-filter.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type FilterTypesProps = { [key: string]: { label: string; value: string | number }[] };

interface Props {
  filterTypes: FilterTypesProps;
  setFilterString: (filter: string) => Promise<void> | void;
  filterString: string;
  dynamicFilterNames?: boolean;
  disabled?: boolean;
}

export const CategoryFilters = ({
  filterTypes,
  setFilterString,
  filterString,
  dynamicFilterNames = false,
  disabled,
}: Props) => {
  const [filter, setFilter] = useState<string | null>(null);
  const [filterObject, setFilterObject] = useState<{ [key: string]: string[] }>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const filterStringToObject = useCallback(() => {
    const defaultFilterString: { [id: string]: string[] } = {};
    if (filterString.length > 0) {
      const andString = filterString.split('&');
      if (andString.length > 0) {
        andString.forEach((item) => {
          const [key, values] = item.split('=');
          if (key && values) defaultFilterString[key] = values.split(',');
        });
      }
    }
    setFilterObject(defaultFilterString);
  }, [filterString]);

  useEffect(() => {
    filterStringToObject();
  }, [filterStringToObject]);

  const handleFilterSelect = (value: string) => () => {
    let updatedValues;
    if (filter) {
      const deselectValue = filterObject[filter] && filterObject[filter]?.length && filterObject[filter].indexOf(value);
      if (deselectValue >= 0) {
        updatedValues = filterObject[filter].filter((item) => item !== value);
        if (filterObject[filter].length <= 1) delete filterObject[filter];
      } else {
        updatedValues = filterObject[filter]?.length ? [...filterObject[filter], value] : [value];
      }
      const newFilterOptions = { ...filterObject, [filter]: updatedValues };
      setFilterObject(newFilterOptions);
      let newQueryString = '';

      Object.keys(newFilterOptions).forEach((key, index) => {
        const optionsSelected = newFilterOptions[key].toString();
        if (optionsSelected.length > 0) {
          if (index === 0) newQueryString += `${key}=${optionsSelected}`;
          else newQueryString += `&${key}=${optionsSelected}`;
        }
      });
      setFilterString(newQueryString);
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5, flexWrap: 'wrap' }}>
        {Object.keys(filterTypes).map((ft) => (
          <ButtonComponent
            key={`${ft}-types`}
            sizeVariant="filter"
            style={{ textTransform: 'capitalize' }}
            colorVariant={filterString.includes(ft) ? 'active' : 'secondary'}
            onClick={(event) => {
              setFilter(ft);
              setAnchorEl(event.currentTarget);
            }}
            disabled={disabled}
          >
            {dynamicFilterNames ? ft.split('-').pop() : ft}
            {filterString.includes(ft) && filterObject[ft]?.length > 0 && (
              <Typography
                sx={{
                  ...themeFonts.captionSmall,
                  backgroundColor: themeColors.white,
                  color: themeColors.DarkGrey,
                  borderRadius: radius.br10,
                  height: '16px',
                  width: '16px',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: spacing.m2,
                }}
              >
                {filterObject[ft]?.length}
              </Typography>
            )}

            {filter === ft && Boolean(anchorEl) ? <ArrowUp /> : <ArrowDown />}
          </ButtonComponent>
        ))}
      </Box>

      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': 'btnMenuOptions',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setFilter(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {filter &&
          filterTypes[filter]?.map((subFilter) => (
            <MenuItem
              key={subFilter.value}
              onClick={handleFilterSelect(subFilter.value.toString())}
              disableRipple
              sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}
              disabled={disabled}
            >
              {filterObject[filter] && filterObject[filter]?.indexOf(subFilter.value.toString()) !== -1 ? (
                <CheckedCheckbox {...iconSize} />
              ) : (
                <EmptyCheckbox {...iconSize} />
              )}
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{subFilter.label}</Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};
