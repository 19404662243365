import { useRef } from 'react';

import { Box, Stack } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { useArray } from '@/hooks/use-array.hook';
import { ActionSelectionItemDeleteButton } from '@/v2/components/action-selection-group.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { RequestFormSelectComponent } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type SelectComponentDrawerProps = {
  value?: RequestFormSelectComponent | null;
  onSave?: (value: RequestFormSelectComponent) => void;
};

export const SelectComponentDrawer = ({ value, onSave }: SelectComponentDrawerProps) => {
  const { polyglot } = usePolyglot();

  const options = useArray(value?.options ?? ['']);
  const autoFocusFieldIdx = useRef(-1);

  const formik = useFormik({
    initialValues: {
      label: value?.label ?? '',
      required: value?.required ?? false,
    },
    validationSchema: yup.object({
      label: yup.string().required(polyglot.t('SelectComponentDrawerProps.required')),
    }),
    onSubmit(values) {
      onSave?.({
        type: 'select',
        id: value?.id ?? uuidv4(),
        label: values.label,
        options: options.toArray().filter(
          (x, idx, array) =>
            // remove empty and duplicate values
            !!x && array.indexOf(x) === idx
        ),
        required: values.required,
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('SelectComponentDrawerProps.field')}</Typography>
        <TextfieldComponent
          name="label"
          label={polyglot.t('SelectComponentDrawerProps.label')}
          value={formik.values.label}
          onChange={formik.handleChange}
          maxLength={50}
          autoFocus={autoFocusFieldIdx.current < 0}
          clearText={() => formik.setFieldValue('label', '')}
          helperText={formik.submitCount ? formik.errors.label : ''}
          error={!!formik.submitCount && !!formik.errors.label}
        />
        <Stack sx={{ gap: spacing.g10 }}>
          {options.map((option, idx) => (
            <Stack key={idx} sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
              <TextfieldComponent
                name={option}
                label={polyglot.t('SelectComponentDrawerProps.optionNum', { num: idx + 1 })}
                value={option}
                onChange={(e) => {
                  options.splice(idx, 1, e.target.value);
                }}
                endAdornment="none"
                maxLength={50}
                autoFocus={autoFocusFieldIdx.current === idx}
              />
              <ActionSelectionItemDeleteButton onClick={() => options.splice(idx, 1)} />
            </Stack>
          ))}

          <Box>
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="filter"
              style={{ width: 'fit-content' }}
              disabled={options.includes('')}
              onClick={() => {
                // add a new blank option and set focus to its text field
                autoFocusFieldIdx.current = options.length;
                options.push('');
              }}
            >
              {polyglot.t('SelectComponentDrawerProps.add')}
            </ButtonComponent>
          </Box>
        </Stack>
        <CheckboxComponent
          name="required"
          label={polyglot.t('General.required')}
          checked={formik.values.required}
          onChange={formik.handleChange}
        />
        {!!formik.values.label && options.some((option) => option.trim()) && (
          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth>
              {polyglot.t('General.save')}
            </ButtonComponent>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};
