import { useContext, useMemo, useState } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { GlobalContext } from '@/GlobalState';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { CustomUserModal } from '@/v2/components/user-select-type/components/custom-user-modal.component';
import { SpecificUserModal } from '@/v2/components/user-select-type/components/specific-user-modal.component';
import {
  CustomRuleOptions,
  getCustomRuleOptionsList,
  UserSelectFiltersOptions,
} from '@/v2/components/user-select-type/user-select.interface';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const getUserTabFilter = (
  excludeEveryone: boolean,
  excludeSpecific: boolean,
  excludeCustomRule: boolean,
  excludeNone: boolean,
  selectedSpecificButtonLabel: string,
  polyglot: Polyglot
) => [
  ...(excludeEveryone ? [] : [{ name: polyglot.t('UserSelect.everyone'), value: UserSelectFiltersOptions.Everyone }]),
  ...(excludeNone ? [] : [{ name: polyglot.t('UserSelect.none'), value: UserSelectFiltersOptions.None }]),
  ...(excludeSpecific ? [] : [{ name: selectedSpecificButtonLabel, value: UserSelectFiltersOptions.SelectSpecific }]),
  ...(excludeCustomRule
    ? []
    : [{ name: polyglot.t('UserSelect.customRule'), value: UserSelectFiltersOptions.CustomRule }]),
];

const getRuleTypeString = (
  customRule: string,
  sitesForCompany: SiteDto[] | null | undefined,
  departmentsForCompany: CompanyDepartmentDto[] | null | undefined,
  entitiesForCompany: CompanyUnitDto[] | null | undefined
) => {
  const ruleTypeArray = customRule ? customRule?.split('=') : [];
  if (ruleTypeArray.length === 0 && !sitesForCompany && !departmentsForCompany) return;
  const ruleType = ruleTypeArray[0] ?? [];

  switch (ruleType) {
    case CustomRuleOptions.Site:
      return sitesForCompany
        ? `site ${sitesForCompany.find((r) => r.id === Number(ruleTypeArray[1]))?.name ?? ''} `
        : 'sites';

    case CustomRuleOptions.Department:
      return departmentsForCompany
        ? `department ${departmentsForCompany.find((r) => r.id === Number(ruleTypeArray[1]))?.name ?? ''} `
        : 'department';

    case CustomRuleOptions.Entity:
      return entitiesForCompany
        ? `entity ${entitiesForCompany.find((r) => r.id === Number(ruleTypeArray[1]))?.legalName ?? ''} `
        : 'entity';

    default:
      return '';
  }
};

export interface OptionObj {
  readonly value: number | string; // userid or everyone
  readonly label: string;
}

export const UserSelect = ({
  label,
  selectedLabel,
  value,
  onChange,
  directionSx = { display: 'flex', flexDirection: 'column', gap: spacing.g10 },
  labelSx = { ...themeFonts.captionSmall, color: themeColors.DarkGrey },
  fieldSx = {},
  error = undefined,
  helperText = undefined,
  initialFilterValue = UserSelectFiltersOptions.None,
  excludeEveryone = false,
  excludeSpecific = false,
  excludeNone = true,
  selectedSpecificButtonLabel,
  ruleString = undefined,
  excludeCustomRule = false,
  disabled = false,
  userOptions,
  allowEmpty = false,
  hideUserList = false,
  allValidUserIds,
  allInvalidUserIds,
  reach = ReachType.Company,
}: {
  label?: string;
  selectedLabel?: string;
  value?: number[];
  onChange: (userIds: number[], filterValue: UserSelectFiltersOptions, customRule?: string) => void;
  directionSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  fieldSx?: SxProps<Theme>;
  error?: boolean | undefined;
  helperText?: string | false | string[] | undefined;
  initialFilterValue?: UserSelectFiltersOptions | undefined;
  excludeEveryone?: boolean;
  excludeSpecific?: boolean;
  excludeNone?: boolean;
  ruleString?: string | null;
  selectedSpecificButtonLabel?: string;
  excludeCustomRule?: boolean;
  disabled?: boolean;
  userOptions?: {
    readonly value: number;
    readonly label: string;
  }[];
  allowEmpty?: boolean;
  hideUserList?: boolean;
  allValidUserIds?: number[];
  allInvalidUserIds?: number[];
  reach?: ReachType;
}) => {
  const { polyglot } = usePolyglot();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);

  const allUsers = useMemo(() => {
    const reports = new Set(globalState.user.reports);

    let filteredUsers =
      userOptions ??
      (reach === ReachType.Team
        ? nonTerminatedCachedUsers
            .filter((u) => reports.has(u.userId))
            .map((u) => ({
              value: u.userId,
              label: u.displayName,
            }))
        : nonTerminatedCachedUsers.map((u) => ({
            value: u.userId,
            label: u.displayName,
          })));

    if (allValidUserIds) {
      const validUsersSet = new Set(allValidUserIds);
      filteredUsers = filteredUsers.filter((u) => validUsersSet.has(u.value));
    }

    if (allInvalidUserIds) {
      const invalidUsersSet = new Set(allInvalidUserIds);
      filteredUsers = filteredUsers.filter((u) => !invalidUsersSet.has(u.value));
    }

    return filteredUsers;
  }, [globalState.user.reports, allValidUserIds, allInvalidUserIds, userOptions, nonTerminatedCachedUsers, reach]);

  const [filterValue, setFilterValue] = useState<UserSelectFiltersOptions>(initialFilterValue);
  const [lastValidFilterValue, setLastValidFilterValue] = useState<UserSelectFiltersOptions>(initialFilterValue);
  const [isSpecificOpen, setSpecificIsOpen] = useState(false);
  const [isCustomOpen, setCustomIsOpen] = useState(false);
  const { data: sitesForCompany } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const [customRuleTypeString, setCustomRuleTypeString] = useState<string | undefined>(
    ruleString
      ? getRuleTypeString(ruleString, sitesForCompany, departmentsForCompany, generalSettings?.entities ?? [])
      : undefined
  );

  return (
    <Box sx={directionSx}>
      {label && <Typography sx={labelSx}>{label}</Typography>}
      <Box sx={{ mb: '10px' }}>
        <TabFilterButtons
          disabled={disabled}
          filters={getUserTabFilter(
            excludeEveryone,
            excludeSpecific,
            excludeCustomRule,
            excludeNone,
            selectedSpecificButtonLabel ?? polyglot.t('UserSelect.specific'),
            polyglot
          )}
          setFilterValue={setFilterValue as React.Dispatch<React.SetStateAction<string>>}
          filterValue={filterValue}
          onFilterChange={({ filterValue }) => {
            if (filterValue === UserSelectFiltersOptions.Everyone) {
              onChange(
                allUsers.map((u) => u.value),
                filterValue
              );
              setLastValidFilterValue(UserSelectFiltersOptions.Everyone);
            }
            if (filterValue === UserSelectFiltersOptions.SelectSpecific) {
              // if Select Specific was already the selected option, keep the existing values, otherwise reset the selected model
              if (lastValidFilterValue === UserSelectFiltersOptions.SelectSpecific) onChange(value ?? [], filterValue);
              else onChange([], filterValue);
              setSpecificIsOpen(true);
            }
            if (filterValue === UserSelectFiltersOptions.CustomRule) {
              onChange([], filterValue);
              setCustomIsOpen(true);
            }
            if (allowEmpty && filterValue === UserSelectFiltersOptions.None) {
              onChange([], filterValue);
            }
          }}
        />
        {error && helperText && (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.RedDark, marginTop: spacing.m10 }}>
            {helperText}
          </Typography>
        )}
      </Box>

      {value && value.length > 0 && !hideUserList && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, ...fieldSx }}>
          {selectedLabel && <Typography sx={labelSx}>{selectedLabel}</Typography>}
          {filterValue === UserSelectFiltersOptions.CustomRule ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {customRuleTypeString
                ? polyglot.t('UserSelect.selectAllFrom', { source: customRuleTypeString })
                : polyglot.t('UserSelect.customRule')}
            </Typography>
          ) : (
            <MultiUserAvatar userIds={value} />
          )}
        </Box>
      )}

      <SpecificUserModal
        isSpecificOpen={isSpecificOpen}
        setSpecificIsOpen={setSpecificIsOpen}
        label={label}
        value={value}
        onChange={onChange}
        filterValue={filterValue}
        setLastValidFilterValue={setLastValidFilterValue}
        onClose={() => setFilterValue(lastValidFilterValue)}
        userOptions={allUsers}
        allowEmpty={allowEmpty}
      />
      <CustomUserModal
        isCustomOpen={isCustomOpen}
        setCustomIsOpen={setCustomIsOpen}
        label={label}
        onChange={(userIds, customRule) => onChange(userIds, filterValue, customRule)}
        customRuleOptions={getCustomRuleOptionsList(polyglot)}
        onClose={() => setFilterValue(lastValidFilterValue)}
        setCustomRuleTypeString={setCustomRuleTypeString}
        sites={sitesForCompany ?? []}
        departments={departmentsForCompany ?? []}
        entities={generalSettings?.entities ?? []}
      />
    </Box>
  );
};
