import React, { useState } from 'react';

import { ButtonComponent } from '@v2/components/forms/button.component';
import { CreateHolidayCalendarDrawer } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/create-holiday-calendar-drawer.component';
import { HolidayCalendarsTable } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-calendars-table.component';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { RootStyle } from '@/v2/styles/root.styles';

interface PageProps {
  readonly holidayCalendars: HolidayCalendarDto[];
  readonly refresh: () => Promise<void>;
  readonly isLoading: boolean;
}

export const HolidayCalendarsListPage = ({ holidayCalendars, refresh, isLoading }: PageProps) => {
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <RootStyle>
      <ContentWrapper>
        <SettingsSectionContent
          title={polyglot.t('HolidayCalendarModule.publicHolidays')}
          headerWidth="100%"
          contentWidth="100%"
          buttons={[
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            >
              {polyglot.t('HolidayCalendarModule.newCalendar')}
            </ButtonComponent>,
          ]}
        >
          <SettingsSubsectionContent
            sections={[
              {
                contentWidth: '100%',
                items: [
                  {
                    type: SectionItemType.Component,
                    value: <HolidayCalendarsTable holidayCalendars={holidayCalendars} isLoading={isLoading} />,
                  },
                ],
              },
            ]}
          />

          <CreateHolidayCalendarDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} refresh={refresh} />
        </SettingsSectionContent>
      </ContentWrapper>
    </RootStyle>
  );
};
