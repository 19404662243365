import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { IconContentActionCard } from '@v2/components/theme-components/icon-content-action-card.component';
import { InsurancePolicyDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { downloadFileByUrl } from '@v2/feature/documents/documents.util';
import { secondaryCTABtn } from '@v2/styles/buttons.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { ReactComponent as Document } from '@/images/side-bar-icons/Document.svg';
import { themeColors } from '@/v2/styles/colors.styles';

interface InsurancePolicyDocumentsProps {
  readonly insurancePolicy: InsurancePolicyDto;
}

export const InsurancePolicyDocuments = ({ insurancePolicy }: InsurancePolicyDocumentsProps) => {
  return (
    <Box sx={{ maxWidth: { sm: '400px', md: '800px', lg: '800px' } }}>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Policy documents</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap20, mt: spacing.mt20 }}>
        {insurancePolicy.uploadedDocuments.map((document, index) => (
          <IconContentActionCard
            title={document.name}
            content={document.note ?? ''}
            iconMedia={<Document {...actionIconSize} />}
            action={
              <Box sx={{ display: 'flex', alignItems: 'end' }}>
                <Button
                  sx={secondaryCTABtn}
                  fullWidth
                  onClick={() => {
                    const attachmentExists = document.attachments && document.attachments.length > 0;
                    if (attachmentExists) {
                      const documentUrl = document.attachments[0].url;
                      downloadFileByUrl(documentUrl, documentUrl);
                    }
                  }}
                >
                  Download
                </Button>
              </Box>
            }
            showDivider
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};
