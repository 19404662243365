import { Typography } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface PlaceholderProps {
  title: string;
}

export const AppInsightsPlaceholder = ({ title }: PlaceholderProps) => {
  return (
    <>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mb: '10px' }}>{title}</Typography>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mb: '10px' }}>
        Not enough data available right now.
      </Typography>
    </>
  );
};
