import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, List, ListItem } from '@mui/material';
import { AutocompleteComponent, OptionObject } from '@v2/components/forms/autocomplete.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { UserEventDto } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { OffboardingAPI } from '@/v2/feature/offboarding/offboarding.api';
import { UserOffboardingDues, UserOffboardingParameters } from '@/v2/feature/offboarding/offboarding.interface';
import { getOffboardingTask, getOffboardingTaskIcon } from '@/v2/feature/offboarding/offboarding.util';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { getAllDefaultTerminationChangeReasonOptions } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { spacing } from '@/v2/styles/spacing.styles';

const getOffboardingSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    userId: Yup.number().nullable().required(polyglot.t('OffboardingDrawer.errorMessages.employeeRequired')),
    terminationDate: Yup.string()
      .test(dateFieldTest)
      .required(polyglot.t('OffboardingDrawer.errorMessages.endDateRquired')),
    changeReason: Yup.string(),
    note: Yup.string().nullable(),
  });

export const OffboardingDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  terminationUserEvent = null,
  pushToOffboarding = true,
  refresh = undefined,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number | undefined;
  pushToOffboarding?: boolean;
  refresh?: () => Promise<void>;
  terminationUserEvent?: UserEventDto | null;
}) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [duesLoading, setDuesLoading] = useState<boolean>(false);
  const [dues, setDues] = useState<UserOffboardingDues | undefined>(undefined);
  const [existingChangeReasons, setExistingChangeReasons] = useState<string[]>([]);

  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const formik = useFormik({
    initialValues: {
      terminationDate: terminationUserEvent?.effectiveDate ?? new LocalDate().toDateString(),
      changeReason: terminationUserEvent?.changeReason ?? 'other',
      note: terminationUserEvent?.note ?? null,
      userId: userId,
    },
    enableReinitialize: true,
    validationSchema: getOffboardingSchema(polyglot),
    onSubmit: async (values: UserOffboardingParameters) => {
      try {
        if (!values.userId) return;
        setLoading(true);
        await OffboardingAPI.postUserOffboard(values.userId, values);

        if (refresh) await refresh();

        if (pushToOffboarding)
          routerHistory.push({
            pathname: generatePath(USER_OFFBOARDING_ROUTE, { userId: values.userId }),
            state: { values },
          });

        showMessage(polyglot.t('OffboardingDrawer.successMessages.offboard'), 'success');
        setIsOpen(false);
      } catch (error) {
        showMessage(`${polyglot.t('OffboardingDrawer.errorMessages.offboard')}. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const changeReasonOptions = useMemo(() => {
    const defaultOptions = getAllDefaultTerminationChangeReasonOptions(polyglot);

    return [...defaultOptions, ...existingChangeReasons.map((value) => ({ label: value, value }))].sort((a, b) =>
      a?.label && b?.label && a.label > b.label ? 1 : -1
    );
  }, [polyglot, existingChangeReasons]);

  const getOffboardingDetail = useCallback(async () => {
    try {
      if (formik.values.userId) {
        setDuesLoading(true);
        const offboardingDues = await OffboardingAPI.getOffboardingDues(formik.values.userId);
        setDues(offboardingDues);
      }
    } catch (error) {
      showMessage(`${polyglot.t('OffboardingDrawer.errorMessages.fetch')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setDuesLoading(false);
    }
  }, [polyglot, formik.values.userId, showMessage]);

  useEffect(() => {
    (async () => {
      try {
        const changeReasons = await OffboardingAPI.getOffboardingChangeReasons();
        setExistingChangeReasons(changeReasons);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    getOffboardingDetail();
  }, [getOffboardingDetail]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('OffboardingDrawer.terminateEmployee')}</Typography>

          {!userId && (
            <SingleUserSelect
              name="userId"
              options="company"
              onChange={(_, x) => formik.setFieldValue('userId', x?.value ?? null)}
              value={formik.values.userId}
              label={polyglot.t('OffboardingDrawer.employee')}
              error={formik.touched.userId && Boolean(formik.errors.userId)}
              helperText={formik.touched.userId && (formik.errors.userId as string)}
            />
          )}

          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.terminationDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('terminationDate', value);
                }
              }}
              name="terminationDate"
              label={polyglot.t('OffboardingDrawer.terminationDate')}
              error={!!formik.errors.terminationDate && formik.touched.terminationDate}
              helperText={formik.errors.terminationDate && formik.touched.terminationDate}
            />
          </FormControl>

          <AutocompleteComponent
            name="changeReason"
            label={polyglot.t('OffboardingDrawer.changeReason')}
            options={changeReasonOptions as OptionObject[]}
            value={
              changeReasonOptions.find((d) => d.value === formik.values.changeReason) ?? {
                label: formik.values.changeReason ?? '',
                value: formik.values.changeReason ?? '',
              }
            }
            freeSolo
            onBlur={(e) => {
              // in freeSolo mode, Autocomplete only triggers onChange for new values after
              // the user presses enter. This is not obvious, so we always save the value when the control loses focus.
              const value = (e.target as HTMLInputElement)?.value ?? '';

              formik.setFieldValue('changeReason', value);
            }}
            onChange={(_, x) => {
              const value = (x as OptionObject)?.value ?? '';
              formik.setFieldValue('changeReason', value);
            }}
            error={!!formik.touched.changeReason && !!formik.errors.changeReason}
            helperText={formik.touched.changeReason && (formik.errors.changeReason as string)}
          />

          <TextfieldComponent
            name="note"
            label={polyglot.t('OffboardingDrawer.note')}
            value={formik.values.note}
            type="text"
            onChange={formik.handleChange}
            error={formik.touched.note && !!formik.errors.note}
            helperText={((formik.touched.note && formik.errors.note) as string) ?? (' ' as string)}
            clearText={() => formik.setFieldValue('note', '')}
          />

          {!terminationUserEvent && dues && Object.keys(dues).length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
              <Typography variant="title3">{polyglot.t('OffboardingDrawer.tasks')}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {Object.keys(dues)?.map((key) => (
                  <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                    {getOffboardingTaskIcon(key)}
                    <Typography variant="caption">
                      {getOffboardingTask(key, dues[key as keyof UserOffboardingDues])}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {terminationUserEvent && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
              <Typography variant="title4">
                Updating the termination date may require adjustments to the following:
              </Typography>
              <List dense sx={{ listStyleType: 'disc', pl: spacing.s4, py: 0, mt: 0 }}>
                <ListItem sx={{ display: 'list-item', py: 0, my: 0 }}>
                  <Typography variant="caption">Zelt account deactivation</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="caption">Connected apps deactivation</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="caption">Device return shipment</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="caption">Tasks due dates</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="caption">Accrued holiday pay</Typography>
                </ListItem>
              </List>
              <Typography variant="title4">Ensure you apply all relevant changes for this Leaver.</Typography>
            </Box>
          )}

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name={terminationUserEvent ? polyglot.t('General.update') : polyglot.t('OffboardingDrawer.offboard')}
              loading={loading}
              fullWidth
              disabled={duesLoading}
              colorVariant="primary"
              sizeVariant="medium"
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
