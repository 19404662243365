import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Loader } from '@v2/components/loader.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { CompanyAddress } from '@v2/feature/company/company-settings/features/company-settings.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { RefinancingAPI } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api';
import {
  CompanyRefinancing,
  DeviceOrderWithCompanyDetails,
  RefinancingCompanyStatus,
  RefinancingSearchResult,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { nestErrorMessage } from '@/lib/errors';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface SearchCompanyNationalIdDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly order: DeviceOrderWithCompanyDetails;
  readonly refresh: () => Promise<void>;
}

export const SearchCompanyNationalIdDrawer = ({
  isOpen,
  setIsOpen,
  order,
  refresh,
}: SearchCompanyNationalIdDrawerProps) => {
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState<boolean>(false);
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={shouldRefreshOnClose ? refresh : undefined}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <SearchCompanyNationalIdDrawerContent order={order} setShouldRefreshOnClose={setShouldRefreshOnClose} />
      </Suspense>
    </DrawerModal>
  );
};

interface SearchCompanyNationalIdDrawerContentProps {
  readonly order: DeviceOrderWithCompanyDetails;
  readonly setShouldRefreshOnClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchCompanyNationalIdDrawerContent = ({
  order,
  setShouldRefreshOnClose,
}: SearchCompanyNationalIdDrawerContentProps) => {
  const [query, setQuery] = useState<string>(
    (order.company?.entities ? order.company?.entities[0]?.legalName : null) ?? order.company?.name ?? ''
  );
  const [address, setAddress] = useState<CompanyAddress | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [addressLoading, setAddressLoading] = useState<boolean>(false);
  const [creditCheckLoading, setCreditCheckLoading] = useState<boolean>(false);
  const [companyRefinancing, setCompanyRefinancing] = useState<CompanyRefinancing | null>(order.refinancing ?? null);

  const [isSaved, setIsSaved] = useState<boolean>(
    !!(order.company?.entities[0] && order.company.entities[0].nationalId)
  );

  const [results, setResults] = useState<RefinancingSearchResult[] | null>(null);
  const [selectedOption, setSelectedOption] = useState<{ id: string; name: string } | null>(null);
  const [showMessage] = useMessage();

  const searchCompany = async () => {
    try {
      setLoading(true);
      const results = await RefinancingAPI.searchCompanyNationalId(query);
      setResults(results);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const searchCompanyAddressByNationalId = useCallback(
    async (selectedOption: { id: string; name: string }) => {
      setAddressLoading(true);
      try {
        const address = await RefinancingAPI.searchCompanyAddressByNationalId(selectedOption.id);
        if (!address?.streetAddress || !address?.city || !address?.postalCode) {
          showMessage('Missing address details.', 'error');
          return;
        }
        const addressLine: CompanyAddress = {
          addressLine1: address.streetAddress,
          postCode: address.postalCode,
          city: address.city,
          countryCode: 'GB',
        };
        setAddress(addressLine);
      } catch (error) {
        showMessage(`Could not fetch the company address. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setAddressLoading(false);
      }
    },
    [showMessage]
  );

  useEffect(() => {
    if (selectedOption) searchCompanyAddressByNationalId(selectedOption);
  }, [selectedOption, searchCompanyAddressByNationalId]);

  const saveCompanyDetails = async () => {
    if (!selectedOption || !address) {
      showMessage('Please select and id and an address.', 'error');
      return;
    }
    try {
      await RefinancingAPI.saveCompanyDetails(order.companyId, selectedOption.id, selectedOption.name, address);
      setIsSaved(true);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not save company details. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const runCreditCheck = async () => {
    try {
      setCreditCheckLoading(true);
      const response = await RefinancingAPI.runCompanyCreditCheck(order.companyId);
      setCompanyRefinancing(response ?? null);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not run credit check. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setCreditCheckLoading(false);
    }
  };

  const addressLine = useMemo(() => {
    return address
      ? [address?.addressLine1, address?.postCode, address?.city, address?.countryCode].filter(Boolean).join(', ')
      : '';
  }, [address]);

  return (
    <Box>
      <Typography sx={titleSx}>Credit check</Typography>

      <Box sx={{ mb: spacing.m20, display: 'flex', flexDirection: 'column', gap: spacing.m10 }}>
        {order.company?.entities && order.company.entities[0]?.legalName && (
          <Box>
            <Typography sx={themeFonts.caption}>Current legal name</Typography>
            <Typography sx={themeFonts.title4}>{order.company.entities[0].legalName}</Typography>
          </Box>
        )}
        {companyRefinancing?.creditCheckStatus && (
          <Box>
            <Typography sx={themeFonts.caption}>Current credit check status</Typography>
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
              {companyRefinancing.creditCheckStatus === RefinancingCompanyStatus.Approved ? (
                <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
              ) : (
                <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
              )}
              <Typography sx={themeFonts.title4}>{companyRefinancing.creditCheckStatus}</Typography>
            </Box>
          </Box>
        )}

        {companyRefinancing?.minimumInstalments && companyRefinancing?.maximumInstalments && (
          <Box>
            <Typography sx={themeFonts.caption}>Allowed instalments</Typography>
            <Typography sx={themeFonts.title4}>
              {companyRefinancing.minimumInstalments} - {companyRefinancing.maximumInstalments}
            </Typography>
          </Box>
        )}
        {companyRefinancing?.maximumInstalmentAmount && (
          <Box>
            <Typography sx={themeFonts.caption}>Maximum instalment amount</Typography>
            <Typography sx={themeFonts.title4}>{companyRefinancing.maximumInstalmentAmount}</Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ mb: spacing.m10, display: 'flex', gap: spacing.g10, alignItems: 'center', width: '100%' }}>
        <TextfieldComponent
          label="Search query"
          name="query"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          clearText={() => setQuery('')}
          error={!!(results && results.length === 0)}
          helperText={results && results.length === 0 ? 'No results found' : undefined}
        />
        <LoaderButton
          name="Search"
          onClick={searchCompany}
          loading={loading}
          sizeVariant="small"
          colorVariant={(results && results.length > 0) || isSaved ? 'secondary' : 'primary'}
          disabled={query.length < 3}
        />
      </Box>

      {results && results.length > 0 && (
        <Box sx={fieldSx}>
          <SelectComponent
            name="results"
            label="Select company ID"
            options={results.map((result) => ({ value: result.id, label: `${result.name} (#${result.id})` }))}
            value={selectedOption?.id ?? null}
            compareValue={selectedOption?.id ?? null}
            onChange={(e) => {
              const option = results.find((option) => option.id === e.target.value);
              setSelectedOption(option ?? null);
            }}
          />
        </Box>
      )}

      <Loader loading={addressLoading} yMargin="xxs" size="xs">
        {address && (
          <Box sx={fieldSx}>
            <TextfieldComponent label="Company address" name="address" value={addressLine} />
          </Box>
        )}

        {selectedOption && address && (
          <Box>
            <LoaderButton
              name="Save"
              loading={false}
              onClick={saveCompanyDetails}
              sizeVariant="small"
              colorVariant={isSaved ? 'secondary' : 'primary'}
            />
          </Box>
        )}
      </Loader>

      {isSaved && (
        <LoaderButton
          name="Run Credit Check"
          onClick={runCreditCheck}
          loading={creditCheckLoading}
          type="button"
          sizeVariant="medium"
          colorVariant="primary"
        />
      )}
    </Box>
  );
};
