import React, { useCallback, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';

import { ReactComponent as Success } from '@/images/side-bar-icons/ok-green.svg';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MissingAddress } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-address.component';
import { MissingBankAccount } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-bank-account.component';
import { MissingCompensation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-compensation.component';
import { MissingContract } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-contract.component';
import { MissingPersonal } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-personal.component';
import { MissingTaxInformation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-tax-information.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollMissingInformationPageProps = {
  payrollRecord: UserPayrollForTableDto;
  readonly refreshPayroll?: () => Promise<void>;
};

const PayrollMissingInformationPage = ({ payrollRecord, refreshPayroll }: PayrollMissingInformationPageProps) => {
  const { polyglot } = usePolyglot();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    await refreshPayroll?.();
    setIsRefreshing(false);
  }, [refreshPayroll]);

  const sections = useMemo(() => {
    const missingPayrollFields = payrollRecord.missingFields?.payroll ?? [];
    const hasMissingField = (cardId: string) => missingPayrollFields.some((f) => f.cardId === cardId);
    const result: React.JSX.Element[] = [];
    if (hasMissingField(CustomProfileFormType.Details))
      result.push(<MissingPersonal userId={payrollRecord.userId} refreshPayroll={handleRefresh} />);
    if (hasMissingField(CustomProfileFormType.Address))
      result.push(<MissingAddress userId={payrollRecord.userId} refreshPayroll={handleRefresh} />);
    if (hasMissingField(CustomProfileFormType.BankAccount))
      result.push(
        <MissingBankAccount
          userId={payrollRecord.userId}
          defaultName={`${payrollRecord.user.firstName} ${payrollRecord.user.lastName}`}
          refreshPayroll={handleRefresh}
        />
      );
    if (hasMissingField(CustomProfileFormType.Salary))
      result.push(<MissingCompensation userId={payrollRecord.userId} refreshPayroll={handleRefresh} />);
    if (hasMissingField(CustomProfileFormType.Contract))
      result.push(<MissingContract userId={payrollRecord.userId} refreshPayroll={handleRefresh} />);
    if (hasMissingField(CustomProfileFormType.TaxInformation))
      result.push(<MissingTaxInformation userId={payrollRecord.userId} refreshPayroll={handleRefresh} />);
    return result;
  }, [payrollRecord, handleRefresh]);

  return (
    <Stack>
      <Typography sx={themeFonts.title2}>{polyglot.t('PayrollMissing.missingInfo')}</Typography>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
        {polyglot.t('PayrollMissing.pleaseCompleteInformation')}
      </Typography>
      <Stack sx={{ mt: spacing.mt20, gap: spacing.g5 }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
          {polyglot.t('PayrollMissing.employee')}
        </Typography>
        <UserCell userId={payrollRecord.userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
      </Stack>

      <Stack sx={{ mt: spacing.mt20 }}>
        {isRefreshing && <LoadingSpinner />}
        {!isRefreshing && sections.length === 0 && (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10, mt: spacing.mt20 }}>
            <Success height="1em" style={{ fill: themeColors.Green, flexShrink: 0 }} />
            <Typography sx={themeFonts.caption}>{polyglot.t('PayrollMissing.allInfoPresent')}</Typography>
          </Stack>
        )}
        {!isRefreshing &&
          sections.length > 0 &&
          sections.map((section, idx) => (
            <Stack
              key={idx}
              sx={{ py: spacing.p10, borderTop: idx ? `1px solid ${themeColors.lightGrey}` : undefined }}
            >
              {section}
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

interface Props {
  isOpen?: boolean;
  close: () => void;
  payrollRecord?: UserPayrollForTableDto;
  readonly refreshPayroll?: () => Promise<void>;
}

export const PayrollMissingInformationDrawer = ({
  close,
  isOpen,
  payrollRecord,
  refreshPayroll,
}: Props): JSX.Element => {
  return (
    <DrawerModal isOpen={!!isOpen && !!payrollRecord} setIsOpen={() => close()}>
      {payrollRecord ? (
        <PayrollMissingInformationPage payrollRecord={payrollRecord} refreshPayroll={refreshPayroll} />
      ) : (
        <LoadingSpinner />
      )}
    </DrawerModal>
  );
};
