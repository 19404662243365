import { useMemo, useState } from 'react';

import { Autocomplete, Box, FormControl, IconButton, Paper, Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { HolidayCalendarEndpoints } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { CustomCountryEnum } from '@v2/infrastructure/country/country.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize, iconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';

import { ContractsAPI } from '@/api-client/contracts.api';
import { CreateUserContract, UserContract } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { UserContractSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { nestErrorMessage } from '@/lib/errors';
import { AttendanceScheduleSelect } from '@/v2/components/attendance-schedule-select.component';
import { ContractEndDatePickerComponent } from '@/v2/components/contract-end-date-picker.component';
import { EntitySelect } from '@/v2/components/entity-select.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { getPublicHolidayCalendarOptions } from '@/v2/feature/absence/absence.util';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  getContractTypeOptions,
  getEmploymentTypeOptions,
  getUnitTypeOptions,
} from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { StyledMenuItem } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly initialValues?: UserContract | CreateUserContract | null;
  readonly userId: number;
  readonly loadInitialData: () => Promise<void>;
  readonly rowModalMode: 'add' | 'edit';
  readonly handleSubmit: (userId: number) => void;
  readonly setFormCurrentlyEditing: (beingEdited: boolean) => void;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly loading: boolean;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: CreateUserContract) => void;
  readonly isEffectiveRecord?: boolean;
  showEmployee?: boolean;
}

export const ContractForm = ({
  initialValues,
  userId,
  loadInitialData,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
  showEmployee = false,
}: Props) => {
  const { data: holidayCalendars } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });

  const { polyglot } = usePolyglot();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [rowForDeletion, setRowForDeletion] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMessage] = useMessage();

  const defaultBlankModalValues = useMemo<CreateUserContract>(
    () => ({
      effectiveDate: new LocalDate().toDateString(),
      contract: 'Full-time',
      type: 'Employee',
      ftePercent: 100,
      entityId: null,
      attendanceScheduleId: null,
      contractEndDate: null,
      customUpdates: [],
      publicHolidays: null,
      holidayCalendarId: null,
      noticePeriodUnit: null,
      probationPeriodUnit: null,
    }),
    []
  );

  const customHolidayCalendarsOptions = useMemo(() => {
    if (!holidayCalendars) return [];
    return holidayCalendars.map((hc) => ({ label: hc.name, value: hc.id }));
  }, [holidayCalendars]);

  const formik = useFormik<UserContract | CreateUserContract>({
    initialValues: initialValues ?? defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: UserContractSchema(polyglot),
    onSubmit: async (values) => {
      try {
        if (!usedForDataImport) {
          if (!values.effectiveDate || !values.type) {
            showMessage(polyglot.t('ContractForm.errorMessages.createUpdate'), 'error');
            return;
          }
          setLoading(true);
          if (rowModalMode === 'add') {
            await ContractsAPI.createUserContract(userId, values);
            showMessage(polyglot.t('ContractForm.successMessages.add'), 'success');
          } else if (rowModalMode === 'edit' && 'id' in values) {
            await ContractsAPI.updateById(userId, values);
            showMessage(polyglot.t('ContractForm.successMessages.update'), 'success');
          } else {
            showMessage(polyglot.t('ContractForm.errorMessages.createUpdate'), 'error');
            return;
          }
          await loadInitialData();
          setIsModalOpen(false);
        } else {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage(`${polyglot.t('ContractForm.errorMessages.update')}: ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    },
  });

  const deleteContractRow = (rowId: number) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };

  const handleDeleteRow = async () => {
    try {
      if (rowForDeletion) await ContractsAPI.deleteById(userId, rowForDeletion);
      showMessage(polyglot.t('ContractForm.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(polyglot.t('ContractForm.errorMessages.delete'), 'error');
    } finally {
      await loadInitialData();
      await handleSubmit(userId);
      setRowForDeletion(0);
      setFormCurrentlyEditing(false);
      formik.resetForm();
      setIsModalOpen(false);
      setIsRemovalModalOpen(false);
    }
  };

  const holidayCountries = useMemo(() => getPublicHolidayCalendarOptions({ includeCustom: true }), []);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {rowModalMode === 'add' ? (
          <Typography variant="title2">{polyglot.t('ContractForm.newContract')}</Typography>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{polyglot.t('ContractForm.editContract')}</Typography>
            {'id' in formik.values && (
              <IconButton
                sx={iconCTAButtonSx}
                onClick={(event) => {
                  if (!('id' in formik.values)) return;
                  setAnchorEl(event.currentTarget);
                  setIsRemovalModalOpen(true);
                  deleteContractRow(formik.values.id);
                }}
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
            )}
          </Box>
        )}

        {showEmployee && (
          <Stack sx={{ gap: spacing.g5 }}>
            <Typography variant="captionSmall">{polyglot.t('ContractForm.employee')}</Typography>
            <UserCell userId={userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
          </Stack>
        )}

        <ProfileField fieldStub="contract.effectiveDate">
          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.effectiveDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('effectiveDate', value);
                }
              }}
              name="effectiveDate"
              label={polyglot.t('ContractForm.effectiveDate')}
              error={!!formik.errors.effectiveDate && Boolean(formik.errors.effectiveDate)}
              helperText={formik.touched.effectiveDate && formik.errors.effectiveDate}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="contract.effectiveDate">
          <SelectComponent
            name="type"
            label={polyglot.t('ContractForm.workerType')}
            options={getEmploymentTypeOptions(polyglot)}
            value={formik.values.type}
            compareValue={formik.values.type}
            error={!!formik.errors.type && formik.touched.type}
            onChange={formik.handleChange}
            helperText={formik.touched.type && formik.errors.type}
          />
        </ProfileField>

        <ProfileField fieldStub="contract.contract">
          <SelectComponent
            name="contract"
            label={polyglot.t('ContractForm.employmentContract')}
            options={getContractTypeOptions(polyglot)}
            value={formik.values.contract}
            compareValue={formik.values.contract}
            error={!!formik.errors.contract && formik.touched.contract}
            onChange={formik.handleChange}
            helperText={formik.touched.contract && formik.errors.contract}
          />
        </ProfileField>

        <ProfileField fieldStub="contract.entity">
          <EntitySelect
            name="entityId"
            value={formik.values.entityId}
            onChange={formik.handleChange}
            error={!!formik.submitCount && !!formik.errors.entityId}
            helperText={formik.touched.entityId && formik.errors.entityId}
          />
        </ProfileField>

        <ProfileField fieldStub="contract.attendanceSchedule">
          <AttendanceScheduleSelect
            name="attendanceScheduleId"
            label={polyglot.t('ContractForm.attendance')}
            value={formik.values.attendanceScheduleId}
            onChange={formik.handleChange}
            error={formik.touched.attendanceScheduleId && !!formik.errors.attendanceScheduleId}
            helperText={formik.touched.attendanceScheduleId && formik.errors.attendanceScheduleId}
          />
        </ProfileField>

        <ProfileField fieldStub="contract.publicHolidays">
          <Autocomplete
            options={holidayCountries}
            value={holidayCountries.find(({ value }) => value === formik.values.publicHolidays)}
            getOptionLabel={(option) => option.label}
            onChange={(_, x) => formik.setFieldValue('publicHolidays', x?.value)}
            renderOption={(props, option) => {
              return option.value === formik.values.publicHolidays ? (
                <StyledMenuItem {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="title4">{option.label}</Typography>
                    <ChoseIcon {...iconSize} />
                  </Box>
                </StyledMenuItem>
              ) : (
                <StyledMenuItem {...props}>
                  <Typography variant="caption">{option.label}</Typography>
                </StyledMenuItem>
              );
            }}
            renderInput={(params) => (
              <StyledAutoTextfield
                {...params}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                placeholder={polyglot.t('ContractForm.holidayCal')}
                size="small"
                label={polyglot.t('ContractForm.holidayCal')}
                name="publicHolidays"
                error={formik.touched.publicHolidays && !!formik.errors.publicHolidays}
                helperText={formik.touched.publicHolidays && formik.errors.publicHolidays}
              />
            )}
            PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
            popupIcon={<ArrowDownACIcon />}
            clearIcon={<ClearIcon />}
          />
        </ProfileField>

        {formik.values.publicHolidays === CustomCountryEnum.code && (
          <ProfileField fieldStub="contract.publicHolidays">
            <SelectComponent
              name="holidayCalendarId"
              label={polyglot.t('HolidayCalendarModule.customHolidayCalendar')}
              options={customHolidayCalendarsOptions}
              value={formik.values.holidayCalendarId}
              onChange={formik.handleChange}
              compareValue={formik.values.holidayCalendarId}
              error={!!formik.errors.holidayCalendarId && formik.touched.publicHolidays}
              helperText={(formik.touched.publicHolidays && formik.errors.holidayCalendarId) as string}
            />
          </ProfileField>
        )}

        <ProfileField fieldStub="contract.changeReason">
          <TextfieldComponent
            name="changeReason"
            label={polyglot.t('ContractForm.changeReason')}
            value={formik.values.changeReason}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.changeReason && !!formik.errors.changeReason}
            helperText={(formik.touched.changeReason && formik.errors.changeReason) ?? ' '}
            clearText={() => formik.setFieldValue('changeReason', '')}
          />
        </ProfileField>

        <ProfileField
          fieldStub="contract.probationPeriod"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            gap: '10px',
          }}
        >
          <TextfieldComponent
            name="probationPeriodLength"
            label={polyglot.t('ContractForm.probationPeriod')}
            value={formik.values.probationPeriodLength}
            type="number"
            onChange={formik.handleChange}
            error={formik.touched.probationPeriodLength && !!formik.errors.probationPeriodLength}
            helperText={(formik.touched.probationPeriodLength && formik.errors.probationPeriodLength) ?? ' '}
            clearText={() => formik.setFieldValue('probationPeriodLength', '')}
          />

          <SelectComponent
            name="probationPeriodUnit"
            label={polyglot.t('ContractForm.probationPeriodUnit')}
            options={getUnitTypeOptions(polyglot)}
            value={formik.values.probationPeriodUnit}
            compareValue={formik.values.probationPeriodUnit}
            error={!!formik.errors.probationPeriodUnit && formik.touched.probationPeriodUnit}
            onChange={formik.handleChange}
            helperText={formik.touched.probationPeriodUnit && formik.errors.probationPeriodUnit}
          />
        </ProfileField>

        <ProfileField
          fieldStub="contract.noticePeriod"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            gap: '10px',
          }}
        >
          <TextfieldComponent
            name="noticePeriodLength"
            label={polyglot.t('ContractForm.noticePeriod')}
            value={formik.values.noticePeriodLength}
            type="number"
            onChange={formik.handleChange}
            error={formik.touched.noticePeriodLength && !!formik.errors.noticePeriodLength}
            helperText={(formik.touched.noticePeriodLength && formik.errors.noticePeriodLength) ?? ' '}
            clearText={() => formik.setFieldValue('noticePeriodLength', '')}
          />

          <SelectComponent
            name="noticePeriodUnit"
            label={polyglot.t('ContractForm.noticePeriodUnit')}
            options={getUnitTypeOptions(polyglot)}
            value={formik.values.noticePeriodUnit}
            compareValue={formik.values.noticePeriodUnit}
            error={!!formik.errors.noticePeriodUnit && formik.touched.noticePeriodUnit}
            onChange={formik.handleChange}
            helperText={formik.touched.noticePeriodUnit && formik.errors.noticePeriodUnit}
          />
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
          rowModalMode={rowModalMode}
          formName={CustomProfileFormType.Contract}
        />

        <ProfileField fieldStub="contract.contractEndDate" sx={{ mb: '20px' }}>
          <ContractEndDatePickerComponent
            name="contractEndDate"
            label="End date"
            effectiveDate={formik.values.effectiveDate}
            value={formik.values.contractEndDate}
            onChange={(value) => formik.setFieldValue('contractEndDate', value)}
            disabled={loading}
            error={!!formik.errors.contractEndDate}
            helperText={formik.errors.contractEndDate}
          />
        </ProfileField>

        <NotificationModal
          isOpen={isRemovalModalOpen}
          onClose={() => {
            setIsRemovalModalOpen(false);
            setRowForDeletion(0);
          }}
          anchorEl={anchorEl}
          takeAction={handleDeleteRow}
          message={polyglot.t('ContractForm.confirmDelete')}
          callToAction="Yes"
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
