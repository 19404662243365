import React, { Suspense, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto, UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { calculateAnnualValueOfTheBenefitForCurrentTaxYear } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface AddToInsurancePolicyForm {
  startDate: string;
  monthlyEmployeePremium: number;
  monthlyEmployeeContribution: number;
}

interface InsuranceEditUserPolicyDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userInsurance: UserInsuranceDto;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const InsuranceEditUserPolicyDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  insurancePolicy,
  refresh,
  onClose,
}: InsuranceEditUserPolicyDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <InsuranceEditUserPolicyDrawerContent
          userInsurance={userInsurance}
          insurancePolicy={insurancePolicy}
          refresh={refresh}
          onClose={onClose}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface InsuranceEditUserPolicyDrawerContentProps {
  readonly userInsurance: UserInsuranceDto;
  readonly insurancePolicy: InsurancePolicyDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

const InsuranceEditUserPolicyDrawerContent = ({
  userInsurance,
  insurancePolicy,
  refresh,
  onClose,
}: InsuranceEditUserPolicyDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<AddToInsurancePolicyForm>({
    initialValues: {
      startDate: userInsurance.startDate ? userInsurance.startDate : new LocalDate().toDateString(),
      monthlyEmployeePremium: userInsurance.monthlyPremium ?? 0,
      monthlyEmployeeContribution: userInsurance.monthlyContribution ?? 0,
    },
    validationSchema: yup.object({
      startDate: yup.string().test(dateFieldTest).required(polyglot.t('ValidationMessages.requiredField')),
      monthlyEmployeePremium: yup
        .number()
        .required(polyglot.t('ValidationMessages.requiredField'))
        .moreThan(0, polyglot.t('ValidationMessages.greaterThan', { num: 0 })),
      monthlyEmployeeContribution: yup.number().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: (values: AddToInsurancePolicyForm) =>
      editToUserInsurancePolicy(userInsurance.userId, values, insurancePolicy.id),
  });

  const editToUserInsurancePolicy = async (
    userId: number,
    values: AddToInsurancePolicyForm,
    insurancePolicyId: number
  ): Promise<void> => {
    try {
      setLoading(true);
      const { startDate, monthlyEmployeePremium, monthlyEmployeeContribution } = values;
      const data = {
        startDate,
        monthlyEmployeePremium: Number(monthlyEmployeePremium),
        monthlyEmployeeContribution: Number(monthlyEmployeeContribution),
      };

      await InsuranceAPI.updateUserPolicyPremiums(userId, insurancePolicyId, data);

      await refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <>
          <Typography sx={titleSx}>{polyglot.t('BenefitModule.editPremiums')}</Typography>

          <Box sx={{ mb: spacing.mb30 }}>
            <UserCell userId={userInsurance.userId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
          </Box>

          <Box sx={fieldSx}>
            <DatePickerComponent
              name="startDate"
              label={polyglot.t('BenefitModule.policyStartDate')}
              inputFormat="DD/MM/YYYY"
              value={formik.values.startDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('startDate', value);
                }
              }}
              error={!!formik.errors.startDate && Boolean(formik.touched.startDate)}
              helperText={formik.errors.startDate && Boolean(formik.touched.startDate)}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="monthlyEmployeePremium"
              label={polyglot.t('BenefitModule.employeeMonthlyPremium')}
              value={formik.values.monthlyEmployeePremium}
              onChange={formik.handleChange}
              error={formik.touched.monthlyEmployeePremium && Boolean(formik.errors.monthlyEmployeePremium)}
              helperText={formik.touched.monthlyEmployeePremium && formik.errors.monthlyEmployeePremium}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="monthlyEmployeeContribution"
              label={polyglot.t('BenefitModule.employeeMonthlyContribution')}
              value={formik.values.monthlyEmployeeContribution}
              onChange={formik.handleChange}
              error={formik.touched.monthlyEmployeeContribution && Boolean(formik.errors.monthlyEmployeeContribution)}
              helperText={formik.touched.monthlyEmployeeContribution && formik.errors.monthlyEmployeeContribution}
            />
          </Box>

          <Box sx={fieldSx}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.grey, mb: spacing.mb5 }}>
              {polyglot.t('BenefitModule.annualValueOfBenefit')}
            </Typography>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              {formatMoney({
                amount: calculateAnnualValueOfTheBenefitForCurrentTaxYear(
                  formik.values.startDate,
                  formik.values.monthlyEmployeePremium ? Number(formik.values.monthlyEmployeePremium) : 0,
                  formik.values.monthlyEmployeeContribution ? Number(formik.values.monthlyEmployeeContribution) : null,
                  userInsurance.dependantsMonthlyPremium,
                  userInsurance.dependantsMonthlyContribution
                ),
              })}
            </Typography>
          </Box>

          <Box sx={buttonBoxSx}>
            <LoaderButton
              name={polyglot.t('General.update')}
              loading={loading}
              sizeVariant="small"
              colorVariant="primary"
              fullWidth
            />
          </Box>
        </>
      </Form>
    </FormikProvider>
  );
};
