import React, { useCallback, useMemo, useState } from 'react';

import { IconButton } from '@mui/material';
import { iconSize } from '@v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AllowanceRoundingType, HolidaysProratingRule } from '@v2/feature/absence/absence.interface';
import {
  getAbsenceProratingMethod,
  getPolicyAllowanceTypeLabel,
  getPolicyHolidaysProratingRuleOption,
  isHourlyPolicy,
  isUnlimitedPolicy,
} from '@v2/feature/absence/absence.util';
import { getAccrualFrequency } from '@v2/feature/absence/subfeatures/settings/policy-details/absence-policy-settings.util';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { PolicyAccrualEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-accrual-edit-drawer.component';
import { PolicyAllowanceEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-allowance-edit-drawer.component';
import { PolicyCarryOverEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-carry-over-edit-drawer.component';
import { PolicyProratingEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-prorating-edit-drawer.component';
import { PolicyPublicHolidaysEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-public-holidays-edit-drawer.component';
import { PolicyRoundingEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-rounding-edit-drawer.component';
import { PolicyTenureEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-tenure-edit-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import {
  translateAbsencePolicyAccrualCliff,
  translateAbsencePolicyTenureAllocation,
} from '@v2/infrastructure/i18n/translate.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import Polyglot from 'node-polyglot';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

const getCarryOverExpirationDate = (absencePolicy: AbsencePolicyDto): string | null => {
  if (
    !absencePolicy.carryOverEnabled ||
    !absencePolicy.carryOverExpirationMonth ||
    !absencePolicy.carryOverExpirationDay
  )
    return null;

  const expiry = new Date();
  expiry.setMonth(absencePolicy.carryOverExpirationMonth - 1, absencePolicy.carryOverExpirationDay);

  return expiry.toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'long',
  });
};

const getAbsenceRoundingMethod = (
  policy: Pick<AbsencePolicyDto, 'allowanceRoundingType'>,
  polyglot: Polyglot
): string => {
  if (policy.allowanceRoundingType === AllowanceRoundingType.UpToFullDay)
    return polyglot.t('AbsenceUtil.upToNearestFull');
  if (policy.allowanceRoundingType === AllowanceRoundingType.UpToHalfDay)
    return polyglot.t('AbsenceUtil.upToNearestHalf');
  if (policy.allowanceRoundingType === AllowanceRoundingType.NoRounding) return polyglot.t('AbsenceUtil.noRounding');

  return policy.allowanceRoundingType;
};

interface AbsencePolicyGeneralSectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const AbsencePolicyAllowanceSection = ({ absencePolicy, refresh }: AbsencePolicyGeneralSectionProps) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [isPHDrawerOpen, setIsPHDrawerOpen] = useState<boolean>(false);
  const [isProratingDrawerOpen, setIsProratingDrawerOpen] = useState<boolean>(false);
  const [isRoundingDrawerOpen, setIsRoundingDrawerOpen] = useState<boolean>(false);
  const [isCarryOverDrawerOpen, setIsCarryOverDrawerOpen] = useState<boolean>(false);
  const [isTenureDrawerOpen, setIsTenureDrawerOpen] = useState<boolean>(false);
  const [isAccrualDrawerOpen, setIsAccrualDrawerOpen] = useState<boolean>(false);

  const [isResettingPublicHolidays, setIsResettingPublicHolidays] = useState<boolean>(false);
  const [isResettingCarryOver, setIsResettingCarryOver] = useState<boolean>(false);
  const [isResettingProration, setIsResettingProration] = useState<boolean>(false);
  const [isResettingAccrual, setIsResettingAccrual] = useState<boolean>(false);
  const [isResettingTenure, setIsResettingTenure] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const isHourly = isHourlyPolicy(absencePolicy);
  const isUnlimited = isUnlimitedPolicy(absencePolicy);

  const unit = useMemo(() => (isHourly ? 'hour' : 'day'), [isHourly]);

  const showPublicHolidaySection = absencePolicy.publicHolidayAutobook || absencePolicy.publicHolidayOnTop;
  const showProratingSection = !isUnlimited && (absencePolicy.proratingFte || absencePolicy.proratingStartDate);

  const showCarryOverSection = absencePolicy.carryOverEnabled && !isUnlimited;
  const showRoundingSection = showProratingSection && absencePolicy.allowanceRoundingType;
  const showTenureSection = absencePolicy.tenureEnabled && !isUnlimited;
  const showAccrualSection = absencePolicy.accrualUnit !== null && absencePolicy.accrualUnitsNo && !isUnlimited;

  const carryOverExpiryDate = getCarryOverExpirationDate(absencePolicy);

  const resetPublicHolidays = useCallback(async () => {
    setIsResettingPublicHolidays(true);
    try {
      await AbsenceAPI.updateAbsencePolicyPublicHolidays(absencePolicy.id, {
        publicHolidayAutobook: false,
        publicHolidayOnTop: false,
        holidaysProratingRule: HolidaysProratingRule.FullValueProrated,
      });
      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    setIsResettingPublicHolidays(false);
  }, [polyglot, absencePolicy, showMessage, refresh]);

  const resetCarryOver = useCallback(async () => {
    setIsResettingCarryOver(true);
    try {
      await AbsenceAPI.updateAbsencePolicyCarryOver(absencePolicy.id, {
        carryOverEnabled: false,
        carryOverLimit: null,
        carryOverExpirationDay: null,
        carryOverExpirationMonth: null,
      });
      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    setIsResettingCarryOver(false);
  }, [polyglot, absencePolicy, showMessage, refresh]);

  const resetProrating = useCallback(async () => {
    setIsResettingProration(true);
    try {
      await Promise.all([
        AbsenceAPI.updateAbsencePolicyProrating(absencePolicy.id, {
          proratingStartDate: false,
          proratingFte: false,
        }),
        // Reset PH as well as we cannot prorate holidays if there is no prorating rule set for the main allowance
        AbsenceAPI.updateAbsencePolicyPublicHolidays(absencePolicy.id, {
          publicHolidayAutobook: absencePolicy.publicHolidayAutobook,
          publicHolidayOnTop: absencePolicy.publicHolidayOnTop,
          holidaysProratingRule: HolidaysProratingRule.AllInPeriod, // Use this option if no prorating is available
        }),
      ]);

      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    setIsResettingProration(false);
  }, [polyglot, absencePolicy, showMessage, refresh]);

  const resetAccrual = useCallback(async () => {
    setIsResettingAccrual(true);
    try {
      await AbsenceAPI.updateAbsencePolicyAccrual(absencePolicy.id, {
        accrualUnit: null,
        accrualUnitsNo: null,
        accrualCliff: null,
        accrueUpfront: false,
      });
      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    setIsResettingAccrual(false);
  }, [polyglot, absencePolicy, showMessage, refresh]);

  const resetTenure = useCallback(async () => {
    setIsResettingTenure(true);
    try {
      await AbsenceAPI.updateAbsencePolicyTenure(absencePolicy.id, {
        tenureEnabled: false,
        tenureAdditionSettings: null,
        tenureAllocation: null,
      });
      await refresh();
    } catch (error) {
      showMessage(
        polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    setIsResettingTenure(false);
  }, [polyglot, absencePolicy, showMessage, refresh]);

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyAllowanceSection.allowance')}
      onEdit={() => setIsEditDrawerOpen(true)}
      buttons={[
        (showPublicHolidaySection && showProratingSection && showCarryOverSection && showTenureSection) ||
        (showPublicHolidaySection && isUnlimited) ? null : (
          <StyledMenuComponent
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: polyglot.t('AbsencePolicyAllowanceSection.addRule'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
            }}
            options={[
              {
                label: polyglot.t('AbsencePolicyAllowanceSection.publicHolidays'),
                handler: () => setIsPHDrawerOpen(true),
                hidden: showPublicHolidaySection,
              },
              {
                label: polyglot.t('AbsencePolicyAllowanceSection.prorating'),
                handler: () => setIsProratingDrawerOpen(true),
                hidden: showProratingSection || isUnlimited,
              },
              {
                label: polyglot.t('AbsencePolicyAllowanceSection.carryOver'),
                handler: () => setIsCarryOverDrawerOpen(true),
                hidden: showCarryOverSection || isUnlimited,
              },
              {
                label: polyglot.t('AbsencePolicyAllowanceSection.tenureBonus'),
                handler: () => setIsTenureDrawerOpen(true),
                hidden: showTenureSection || isUnlimited,
              },
              {
                label: polyglot.t('AbsenceModule.accrual'),
                handler: () => setIsAccrualDrawerOpen(true),
                hidden: showAccrualSection || isUnlimited,
              },
            ]}
          />
        ),
      ]}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.type'),
                value: getPolicyAllowanceTypeLabel(absencePolicy.allowanceType, polyglot),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.allowance'),
                value:
                  unit === 'day'
                    ? polyglot.t('AbsencePolicyAllowanceSection.numOfDays', {
                        smart_count: absencePolicy.allowance!,
                      })
                    : polyglot.t('AbsencePolicyAllowanceSection.numOfHours', {
                        smart_count: absencePolicy.allowance!,
                      }),
                hidden: absencePolicy.allowance === null,
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyAllowanceSection.tenureBonus'),
            hidden: !showTenureSection,
            onEdit: () => setIsTenureDrawerOpen(true),
            buttons: [
              <IconButton
                key="delete-tenure-bonus"
                sx={tableIconButtonSx}
                onClick={resetTenure}
                disabled={isResettingTenure}
              >
                <Trash {...actionIconSize} />
              </IconButton>,
            ],
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.allocation'),
                value: absencePolicy.tenureAllocation
                  ? translateAbsencePolicyTenureAllocation(absencePolicy.tenureAllocation, polyglot)
                  : '',
                hidden: !absencePolicy.tenureAllocation,
              },
              ...Object.keys(absencePolicy.tenureAdditionSettings ?? {}).map((key, index) => {
                let totalAllowanceForThisRule = absencePolicy.allowance ?? 0;
                for (const localKey of Object.keys(absencePolicy.tenureAdditionSettings!))
                  if (Number(localKey) <= Number(key))
                    totalAllowanceForThisRule += absencePolicy.tenureAdditionSettings![Number(localKey)];

                return {
                  type: SectionItemType.Pair,
                  label: `Rule ${index + 1}`,
                  value: polyglot.t('AbsencePolicyAllowanceSection.addExtraUnits', {
                    noOfYears: polyglot.t('AbsencePolicyAllowanceSection.noOfMonths', { smart_count: Number(key) }),
                    noOfUnits: isHourly
                      ? polyglot.t('AbsencePolicyAllowanceSection.noOfHours', {
                          smart_count: absencePolicy.tenureAdditionSettings![Number(key)],
                        })
                      : polyglot.t('AbsencePolicyAllowanceSection.noOfDays', {
                          smart_count: absencePolicy.tenureAdditionSettings![Number(key)],
                        }),
                    total: isHourly
                      ? polyglot.t('AbsencePolicyAllowanceSection.noOfHours', {
                          smart_count: totalAllowanceForThisRule,
                        })
                      : polyglot.t('AbsencePolicyAllowanceSection.noOfDays', {
                          smart_count: totalAllowanceForThisRule,
                        }),
                  }),
                  hidden: !absencePolicy.tenureAdditionSettings,
                };
              }),
            ],
          },
          {
            title: polyglot.t('AbsencePolicyAllowanceSection.publicHolidays'),
            onEdit: () => setIsPHDrawerOpen(true),
            buttons: [
              <IconButton
                key="delete-ph"
                sx={tableIconButtonSx}
                onClick={resetPublicHolidays}
                disabled={isResettingPublicHolidays}
              >
                <Trash {...actionIconSize} />
              </IconButton>,
            ],
            hidden: !showPublicHolidaySection,
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.autobooking'),
                value: polyglot.t('AbsencePolicyAllowanceSection.enabled'),
                hidden: !absencePolicy.publicHolidayAutobook,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.addToAllowance'),
                value: polyglot.t('AbsencePolicyAllowanceSection.enabled'),
                hidden: !absencePolicy.publicHolidayOnTop,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.publicHolidayAllowance'),
                value: getPolicyHolidaysProratingRuleOption(absencePolicy, polyglot)?.label ?? 'Unknown',
                hidden: !absencePolicy.publicHolidayOnTop,
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyAllowanceSection.carryOver'),
            onEdit: () => setIsCarryOverDrawerOpen(true),
            buttons: [
              <IconButton key="delete" sx={tableIconButtonSx} onClick={resetCarryOver} disabled={isResettingCarryOver}>
                <Trash {...actionIconSize} />
              </IconButton>,
            ],
            hidden: !showCarryOverSection,
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.cap'),
                value:
                  absencePolicy.carryOverLimit === null
                    ? polyglot.t('AbsencePolicyAllowanceSection.unlimited')
                    : unit === 'day'
                    ? polyglot.t('AbsencePolicyAllowanceSection.numOfDays', {
                        smart_count: absencePolicy.carryOverLimit,
                      })
                    : polyglot.t('AbsencePolicyAllowanceSection.numOfHours', {
                        smart_count: absencePolicy.carryOverLimit,
                      }),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.expiry'),
                value: carryOverExpiryDate ? carryOverExpiryDate : polyglot.t('AbsencePolicyAllowanceSection.noExpiry'),
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyAllowanceSection.prorating'),
            onEdit: () => setIsProratingDrawerOpen(true),
            buttons: [
              <IconButton
                key="delete-prorating"
                sx={tableIconButtonSx}
                onClick={resetProrating}
                disabled={isResettingProration}
              >
                <Trash {...actionIconSize} />
              </IconButton>,
            ],
            hidden: !showProratingSection,
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.method'),
                value: getAbsenceProratingMethod(absencePolicy, polyglot).value,
              },
            ],
          },
          {
            title: polyglot.t('AbsencePolicyAllowanceSection.rounding'),
            onEdit: () => setIsRoundingDrawerOpen(true),
            hidden: !showRoundingSection,
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyAllowanceSection.method'),
                value: getAbsenceRoundingMethod(absencePolicy, polyglot),
              },
            ],
          },
          {
            title: polyglot.t('AbsenceModule.accrual'),
            onEdit: () => setIsAccrualDrawerOpen(true),
            buttons: [
              <IconButton
                key="delete-accrual"
                sx={tableIconButtonSx}
                onClick={resetAccrual}
                disabled={isResettingAccrual}
              >
                <Trash {...actionIconSize} />
              </IconButton>,
            ],
            hidden: !showAccrualSection,
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsenceModule.accrualFrequency'),
                value: getAccrualFrequency(absencePolicy, polyglot),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsenceModule.accrueUpfront'),
                value: absencePolicy.accrueUpfront ? polyglot.t('General.enabled') : polyglot.t('General.disabled'),
              },
              ...(absencePolicy.accrualCliff
                ? [
                    {
                      type: SectionItemType.Pair,
                      label: polyglot.t('AbsenceModule.accrualCliff'),
                      value: absencePolicy.accrualCliff
                        ? translateAbsencePolicyAccrualCliff(absencePolicy.accrualCliff, polyglot)
                        : '',
                    },
                  ]
                : []),
            ],
          },
        ]}
      />

      <PolicyAllowanceEditDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />

      <PolicyPublicHolidaysEditDrawer
        isOpen={isPHDrawerOpen}
        setIsOpen={setIsPHDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
      <PolicyProratingEditDrawer
        isOpen={isProratingDrawerOpen}
        setIsOpen={setIsProratingDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
      <PolicyRoundingEditDrawer
        isOpen={isRoundingDrawerOpen}
        setIsOpen={setIsRoundingDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
      <PolicyCarryOverEditDrawer
        isOpen={isCarryOverDrawerOpen}
        setIsOpen={setIsCarryOverDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
      <PolicyTenureEditDrawer
        isOpen={isTenureDrawerOpen}
        setIsOpen={setIsTenureDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
      <PolicyAccrualEditDrawer
        isOpen={isAccrualDrawerOpen}
        setIsOpen={setIsAccrualDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
    </SettingsSectionContent>
  );
};
