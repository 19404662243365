export enum AccountType {
  Receivables = 'Receivables',
  Revenue = 'Revenue',
  Payables = 'Payables',
  Expenses = 'Expenses',
  Cash = 'Cash',
  Deposit = 'Deposit',
}

export enum AccountingTransactionType {
  Asset = 'Asset',
  Income = 'Income',
  Liability = 'Liability',
  Expense = 'Expense',
}

export enum AccountingTransactionStatus {
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  APPROVED = 'APPROVED',
  VOIDED = 'VOIDED',
}
