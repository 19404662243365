import { Suspense, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ContractorOverviewCard } from '@v2/feature/payments/features/accounting-transaction/components/contractor-overview-card.component';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { ExpenseEndpoints } from '@/api-client/expense.api';
import {
  EXPENSES_TEAM_NEW_EXPENSE_ROUTE,
  EXPENSE_COMPANY_NEW_EXPENSE_ROUTE,
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  USER_EXPENSES_NEW_EXPENSE_ROUTE,
} from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ExpenseTable } from '@/v2/feature/payments/pages/components/expense-table.component';
import { PaymentsTransactionsTableSkeleton } from '@/v2/feature/payments/pages/payments-list.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export function ExpensePage({ reach, userId }: { reach: 'me' | 'team' | 'company'; userId?: number }): JSX.Element {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const {
    data: expenses,
    mutate: refreshExpenses,
    isValidating: validatingExpenses,
    isLoading: loadingExpenses,
  } = useApiClient(
    userId
      ? ExpenseEndpoints.getSpecificUserExpenses(userId)
      : reach === 'me'
      ? ExpenseEndpoints.getUserExpenses()
      : reach === 'company'
      ? ExpenseEndpoints.getCompanyExpenses()
      : reach === 'team'
      ? ExpenseEndpoints.getTeamExpenses()
      : { url: '' },
    { suspense: false }
  );

  const showOverview = false;
  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('getSidebarConfig.expenses')}
          </Typography>
        }
        showBack={routerLocation.pathname.includes('/settings')}
        showAction={true}
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() =>
              userId
                ? routerHistory.push(generatePath(USER_EXPENSES_NEW_EXPENSE_ROUTE, { userId }))
                : reach === 'me'
                ? routerHistory.push(EXPENSE_ME_NEW_EXPENSE_ROUTE)
                : reach === 'team'
                ? routerHistory.push(EXPENSES_TEAM_NEW_EXPENSE_ROUTE)
                : reach === 'company'
                ? routerHistory.push(EXPENSE_COMPANY_NEW_EXPENSE_ROUTE)
                : routerHistory.push(EXPENSE_COMPANY_NEW_EXPENSE_ROUTE)
            }
          >
            {polyglot.t('PaymentListActions.newExpense')}
          </ButtonComponent>
        }
      />

      <ContentWrapper loading={false} sx={spacing.pt20}>
        {showOverview && (
          <Box sx={{ ml: spacing.ml40, mt: spacing.mt20, mb: spacing.mb20 }}>
            <ContractorOverviewCard />
          </Box>
        )}

        <Suspense
          fallback={
            <>
              <PaymentsTransactionsTableSkeleton />
            </>
          }
        >
          <ExpenseTable
            expenses={expenses ?? []}
            refreshData={() => {
              refreshExpenses!();
            }}
            reach={reach}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
            loadingExpenses={loadingExpenses || validatingExpenses}
          />
        </Suspense>
      </ContentWrapper>
    </RootStyle>
  );
}
