import { useState } from 'react';

import { KeyedMutator } from 'swr';

import { Typography } from '@/v2/components/typography/typography.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { TimelineRollingModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/rolling/timeline-rolling-modal.component';
import { timelineByReviwer } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { spacing } from '@/v2/styles/spacing.styles';

interface ITimelineRollingView {
  reviewCycle: ReviewCycle;
  showFlags: {
    showSelf: boolean;
    showUpward: boolean;
    showPeer: boolean;
    showManager: boolean;
  };
  isEditable: boolean;
  refreshCycle: KeyedMutator<ReviewCycle>;
}
export const TimelineRollingView = ({ reviewCycle, showFlags, isEditable, refreshCycle }: ITimelineRollingView) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      {timelineByReviwer(reviewCycle, showFlags).length > 0 ? (
        <SettingsSubsectionContent
          sections={[
            {
              headerWidth: '100%',
              title: 'Timeline',
              onEdit: () => setIsOpen(true),
              hideEdit: !isEditable,
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: (
                    <Typography variant="caption" sx={{ pb: spacing.s2 }}>
                      Each invited participant will have the defined amount of days to complete the review. The count
                      starts from the day they are invited. After the deadline has passed, results will not be finalised
                      unless the explicit action is taken in the Results page for each participant.
                    </Typography>
                  ),
                },
                ...timelineByReviwer(reviewCycle, showFlags),
              ],
            },
          ]}
        />
      ) : (
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              headerWidth: '100%',
              title: 'Timeline',
              hideEdit: false,
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: 'Please add some questions to be able to set timelines',
                },
              ],
            },
          ]}
        />
      )}

      <TimelineRollingModal
        reviewCycle={reviewCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refreshCycle?.();
        }}
        showFlags={showFlags}
      />
    </>
  );
};
