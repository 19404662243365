import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { ManageInsurancePolicyDrawer } from '@v2/feature/super-admin/features/super-admin-insurance/components/manage-insurance-policy-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Document } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as EnvelopeSimple } from '@/images/side-bar-icons/EnvelopeSimple.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

type SuperAdminInsuranceQuotesPageProps = {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly pageConfig: PageConfig;
};

export const SuperAdminInsuranceQuotesPage = ({
  companies,
  users,
  pageConfig,
}: SuperAdminInsuranceQuotesPageProps): JSX.Element => {
  const [quotes, setQuotes] = useState<InsuranceQuoteDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [quoteId, setQuoteId] = useState<number | null>(null);

  const [showMessage] = useMessage();

  const refresh = useCallback(async () => {
    try {
      setIsLoading(true);
      const quotes = await InsuranceAPI.getAllInsuranceQuotesAsSuperAdmin();
      setQuotes(quotes);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get insurance quotes. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columnData = useMemo<ColumnDef<InsuranceQuoteDto, InsuranceQuoteDto>[]>(() => {
    return [
      {
        header: () => 'Company name',
        accessorFn: (row) => row,
        id: 'companyId',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          const company = companies.find((company) => quote.companyId === company.companyId);
          return company ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{company.name}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Policy type',
        accessorFn: (row) => row,
        id: 'type',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          return quote.type ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{quote.type}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Number of members',
        accessorFn: (row) => row,
        id: 'noOfMembers',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          return quote.noOfMembers ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{quote.noOfMembers}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Date requested',
        accessorFn: (row) => row,
        id: 'dateRequested',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          return quote.dateRequested ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {new Date(quote.dateRequested).toLocaleDateString('en-GB')}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Requested by',
        accessorFn: (row) => row,
        id: 'requestedBy',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          const user = users.find((user) => user.userId === quote.requestedBy);
          return user ? (
            <Typography
              sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}
            >{`${user.firstName} ${user.lastName}`}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          return quote.status ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{quote.status}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        cell: (info: CellContext<InsuranceQuoteDto, InsuranceQuoteDto>) => {
          const quote: InsuranceQuoteDto = info.getValue();
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledMenuComponent
                options={[
                  {
                    icon: <EnvelopeSimple {...iconSize} />,
                    handler: async () => {
                      try {
                        setIsLoading(true);
                        await InsuranceAPI.createPMI(quote.id);
                        showMessage('Full PMI created successfully.', 'success');
                        await refresh();
                      } catch (error) {
                        console.error(error);
                        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                      } finally {
                        setIsLoading(false);
                      }
                    },
                    label: 'Send full PMI',
                  },
                  {
                    icon: <Document {...iconSize} />,
                    handler: () => {
                      setQuoteId(quote.id);
                      setIsDrawerOpen(true);
                    },
                    label: 'Create policy',
                    hidden: !!quote.policyId,
                  },
                  {
                    icon: <Trash {...iconSize} />,
                    handler: async () => {
                      try {
                        await InsuranceAPI.deleteInsuranceQuoteByIdAsSuperAdmin(quote.id);
                        await refresh();
                      } catch (error) {
                        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                      }
                    },
                    label: 'Delete',
                  },
                ]}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <ActionsSmall {...iconSize} />,
                }}
              />
            </Box>
          );
        },
        maxSize: 100,
        minSize: 80,
      },
    ];
  }, [companies, users, refresh, showMessage]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Health Insurance</Typography>}
        views={<></>}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={isLoading} secondLevel>
        <Box sx={spacing.mt20}>
          <BasicTable<InsuranceQuoteDto> rowData={quotes} columnData={columnData} />
        </Box>

        {quoteId && isDrawerOpen && (
          <ManageInsurancePolicyDrawer
            quoteId={quoteId}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            refresh={refresh}
            onSave={() => setQuoteId(null)}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
