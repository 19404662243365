import React, { Suspense, useCallback, useMemo, useState } from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, IconButton, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceContractLength } from '@v2/feature/device/device.interface';
import { RefinancingPlanPreviewDrawer } from '@v2/feature/super-admin/features/super-admin-refinancing/components/refinancing-plan-preview-drawer.component';
import { RefinancingAPI } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api';
import { TwoPreviewRepaymentPlanDto } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.dto';
import {
  CreateRepaymentData,
  DeviceOrderWithCompanyDetails,
} from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface';
import { buttonBoxSx, fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import { fixedNumber } from '@v2/util/number.util';
import locale from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { BillingEndpoints } from '@/api-client/billing.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Reject } from '@/images/side-bar-icons/Reject.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BillingInformation } from '@/models/subscription.model';
import { DEVICES_RENTAL_TAX_RATE } from '@/v2/feature/device/device.util';

const getNoOfMonthsAlreadyPaid = (startDate: Date): number => {
  if (startDate.getTime() > Date.now()) return 0;

  const today = new Date();
  const yearsDiff = today.getFullYear() - startDate.getFullYear();
  const monthsDiff = yearsDiff * 12 + (today.getMonth() - startDate.getMonth());
  return monthsDiff > 0 ? monthsDiff : 0;
};

interface RefinancePlanDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly order: DeviceOrderWithCompanyDetails;
  readonly refresh: () => Promise<void>;
  readonly idempotencyId: number;
}

export const RefinancePlanDrawer = ({ isOpen, setIsOpen, order, idempotencyId, refresh }: RefinancePlanDrawerProps) => {
  const [deviceOrder, setDeviceOrder] = useState<DeviceOrderWithCompanyDetails>(order);
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState<boolean>(false);

  const refinancingPlan = useMemo(() => {
    return deviceOrder.refinancingPlan;
  }, [deviceOrder]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={shouldRefreshOnClose ? refresh : undefined}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        {refinancingPlan ? (
          <ManageRepaymentPaymentDrawerContent
            order={deviceOrder}
            setShouldRefreshOnClose={setShouldRefreshOnClose}
            closeDrawer={() => setIsOpen(false)}
            refresh={refresh}
          />
        ) : (
          <CreateRefinancePlanDrawerContent
            order={deviceOrder}
            setShouldRefreshOnClose={setShouldRefreshOnClose}
            setDeviceOrder={setDeviceOrder}
            idempotencyId={idempotencyId}
          />
        )}
      </Suspense>
    </DrawerModal>
  );
};

interface RefinancePlanDrawerContentProps {
  readonly order: DeviceOrderWithCompanyDetails;
  readonly setDeviceOrder: React.Dispatch<React.SetStateAction<DeviceOrderWithCompanyDetails>>;
  readonly setShouldRefreshOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  readonly idempotencyId: number;
}

const CreateRefinancePlanDrawerContent = ({
  order,
  setDeviceOrder,
  setShouldRefreshOnClose,
  idempotencyId,
}: RefinancePlanDrawerContentProps) => {
  const { data: billingInfo } = useApiClient<BillingInformation, Error>(BillingEndpoints.getBillingInfo());
  const [showMessage] = useMessage();

  const [isCreatePlanLoading, setIsCreatePlanLoading] = useState<boolean>(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  const [isPreviewSuccessful, setIsPreviewSuccessful] = useState<boolean | null>(null);
  const [preview, setPreview] = useState<TwoPreviewRepaymentPlanDto | null>(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

  const noOfPaidInstallments = order.deliveryDate ? getNoOfMonthsAlreadyPaid(new Date(order.deliveryDate)) : 0;
  const noOfTotalInstalments: DeviceContractLength =
    (order.contractLength as DeviceContractLength) || DeviceContractLength.Months36;
  const noOfRemainingInstalments = noOfTotalInstalments - noOfPaidInstallments;

  const totalRepaymentAmount = fixedNumber(noOfRemainingInstalments * order.price, 2);
  const taxAmount = fixedNumber(totalRepaymentAmount * DEVICES_RENTAL_TAX_RATE, 2);
  const reference = [order.deviceModel?.modelName, order.device?.serialNumber].filter(Boolean).join(' ');

  const formik = useFormik<CreateRepaymentData>({
    initialValues: {
      reference: reference,
      description: '',
      totalRepaymentAmount,
      taxAmount,
      currency: 'GBP',
      noOfInstalments: noOfRemainingInstalments,
      consentDate: new LocalDate().toDateString(),
      billingPhone: billingInfo?.billingContact?.phoneNumber ?? '',
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      city: '',
      countryCode: 'GB',
      idempotencyId: idempotencyId,
    },
    validationSchema: yup.object({
      reference: yup.string().required('Reference is required.'),
      description: yup.string().required('Description is required.'),
      totalRepaymentAmount: yup
        .number()
        .typeError('Invalid numeric value.')
        .test(
          'maxDecimalPlaces',
          'Maximum of 2 decimal places allowed',
          (val) => !val || /^\d+(\.\d{1,2})?$/.test(val.toString())
        )
        .required('Total repayment amount is required.'),
      taxAmount: yup
        .number()
        .typeError('Invalid numeric value.')
        .test(
          'maxDecimalPlaces',
          'Maximum of 2 decimal places allowed',
          (val) => !val || /^\d+(\.\d{1,2})?$/.test(val.toString())
        )
        .required('VAT is required.'),
      currency: yup.string().min(3).max(3).required('Currency is required.'),
      noOfInstalments: yup
        .number()
        .integer('Number of instalments must be an integer.')
        .typeError('Invalid numeric value.')
        .required('Number of instalments is required.'),
      consentDate: yup.string().test(dateFieldTest).required('Consent date is required.'),
      billingPhone: yup.string().required('Billing phone is required.'),
      addressLine1: yup.string().required('Address line 1 is required.'),
      addressLine2: yup.string().nullable().notRequired(),
      postCode: yup.string().required('Post code is required.'),
      city: yup.string().required('City is required.'),
      countryCode: yup.string().min(2).max(2).required('Country code is required.'),
    }),
    onSubmit: (values: CreateRepaymentData) => createRepaymentPlan(order.companyId, order.id, values),
  });

  const createRepaymentPlan = async (
    companyId: number,
    orderId: number,
    formData: CreateRepaymentData
  ): Promise<void> => {
    const planData = {
      reference: formData.reference,
      description: formData.description,
      totalRepaymentAmount: Number(formData.totalRepaymentAmount),
      taxAmount: Number(formData.taxAmount),
      currency: formData.currency,
      noOfInstalments: Number(formData.noOfInstalments),
      consentDate: formData.consentDate,
      billingPhone: formData.billingPhone,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2 ?? undefined,
      postCode: formData.postCode,
      city: formData.city,
      countryCode: formData.countryCode,
      idempotencyId: formData.idempotencyId,
    };
    try {
      setIsCreatePlanLoading(true);
      const refinancingPlan = await RefinancingAPI.createOrderRepaymentPlan(companyId, orderId, planData);
      setShouldRefreshOnClose(true);
      setDeviceOrder({ ...order, refinancingPlan });
    } catch (error) {
      showMessage(`Could not create repayment plan. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsCreatePlanLoading(false);
    }
  };

  const previewPlan = async (companyId: number, orderId: number, formData: CreateRepaymentData): Promise<void> => {
    try {
      setIsPreviewLoading(true);
      const previewPlan = await RefinancingAPI.previewOrderRepaymentPlan(companyId, orderId, formData);
      setIsPreviewSuccessful(!!previewPlan);
      setPreview(previewPlan);
      setIsPreviewModalOpen(!!previewPlan);
    } catch (error) {
      showMessage(`Could not preview repayment plan. ${nestErrorMessage(error)}`, 'error');
      setIsPreviewSuccessful(false);
      setPreview(null);
    } finally {
      setIsPreviewLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>Refinance</Typography>

        {order.company?.entities && order.company.entities[0]?.legalName && (
          <Box sx={fieldSx}>
            <TextfieldComponent
              label="Company legal name"
              name="companyName"
              value={order.company.entities[0].legalName}
              endAdornment="none"
              disabled
            />
          </Box>
        )}

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Reference"
            name="reference"
            value={formik.values.reference}
            onChange={formik.handleChange}
            error={formik.touched.reference && Boolean(formik.errors.reference)}
            helperText={(formik.touched.reference && formik.errors.reference) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={(formik.touched.description && formik.errors.description) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Typography sx={themeFonts.title4}>Order details</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m10, my: spacing.m10 }}>
          {order.deliveryDate && (
            <Box>
              <Typography sx={themeFonts.caption}>Delivery date</Typography>
              <Typography sx={themeFonts.title4}>{new Date(order.deliveryDate).toLocaleDateString()}</Typography>
            </Box>
          )}

          {order.contractLength && (
            <Box>
              <Typography sx={themeFonts.caption}>Contract length</Typography>
              <Typography sx={themeFonts.caption}>{order.contractLength} months</Typography>
            </Box>
          )}
          {order.contractLength && order.deliveryDate && (
            <Box>
              <Typography sx={themeFonts.caption}>Months already paid</Typography>
              <Typography sx={themeFonts.caption}>
                {getNoOfMonthsAlreadyPaid(new Date(order.deliveryDate))} months
              </Typography>
            </Box>
          )}
          {order.price && (
            <Box>
              <Typography sx={themeFonts.caption}>Monthly rent</Typography>
              <Typography sx={themeFonts.caption}>£{order.price}</Typography>
            </Box>
          )}
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Total repayment amount (exc. VAT)"
            name="totalRepaymentAmount"
            value={formik.values.totalRepaymentAmount}
            onChange={formik.handleChange}
            error={formik.touched.totalRepaymentAmount && Boolean(formik.errors.totalRepaymentAmount)}
            helperText={(formik.touched.totalRepaymentAmount && formik.errors.totalRepaymentAmount) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="VAT"
            name="taxAmount"
            value={formik.values.taxAmount}
            onChange={formik.handleChange}
            error={formik.touched.taxAmount && Boolean(formik.errors.taxAmount)}
            helperText={(formik.touched.taxAmount && formik.errors.taxAmount) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Number of instalments"
            name="noOfInstalments"
            value={formik.values.noOfInstalments}
            onChange={formik.handleChange}
            error={formik.touched.noOfInstalments && Boolean(formik.errors.noOfInstalments)}
            helperText={(formik.touched.noOfInstalments && formik.errors.noOfInstalments) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <LocalizationProvider key="consentDate" dateAdapter={AdapterDateFns} locale={locale}>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.consentDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('consentDate', value);
                }
              }}
              name="consentDate"
              label="Consent date"
              error={formik.touched.consentDate && !!formik.errors.consentDate}
              helperText={(formik.touched.consentDate && formik.errors.consentDate) as string}
            />
          </LocalizationProvider>
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Billing phone number"
            name="billingPhone"
            value={formik.values.billingPhone}
            onChange={formik.handleChange}
            error={formik.touched.billingPhone && Boolean(formik.errors.billingPhone)}
            helperText={(formik.touched.billingPhone && formik.errors.billingPhone) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={{ mt: spacing.m10 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>Delivery address</Typography>
          <Typography sx={themeFonts.caption}>{order.deliveryAddress}</Typography>
        </Box>
        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Address line 1"
            name="addressLine1"
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
            helperText={(formik.touched.addressLine1 && formik.errors.addressLine1) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Address line 2"
            name="addressLine2"
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
            helperText={(formik.touched.addressLine2 && formik.errors.addressLine2) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Post code"
            name="postCode"
            value={formik.values.postCode}
            onChange={formik.handleChange}
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            helperText={(formik.touched.postCode && formik.errors.postCode) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="City"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={(formik.touched.city && formik.errors.city) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Country ISO Code"
            name="countryCode"
            value={formik.values.countryCode}
            onChange={formik.handleChange}
            error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
            helperText={(formik.touched.countryCode && formik.errors.countryCode) as string}
            type="text"
            endAdornment="none"
          />
        </Box>

        <Box sx={{ ...buttonBoxSx, gap: spacing.g20 }}>
          {isPreviewSuccessful === true ? (
            <OkGreen width={30} height={30} style={{ fill: themeColors.Green }} />
          ) : isPreviewSuccessful === false ? (
            <Reject width={30} height={30} style={{ fill: themeColors.Red }} />
          ) : null}

          <LoaderButton
            sizeVariant="small"
            colorVariant="secondary"
            type="button"
            name="Preview"
            loading={isPreviewLoading}
            fullWidth
            onClick={async () => {
              await previewPlan(order.companyId, order.id, formik.values);
            }}
          />

          {preview && (
            <RefinancingPlanPreviewDrawer
              isOpen={isPreviewModalOpen}
              setIsOpen={setIsPreviewModalOpen}
              preview={preview}
            />
          )}
        </Box>

        <Box sx={buttonBoxSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name="Create repayment plan"
            loading={isCreatePlanLoading}
            fullWidth
            disabled={!isPreviewSuccessful}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

interface ManageRepaymentPaymentDrawerContentProps {
  readonly order: DeviceOrderWithCompanyDetails;
  readonly setShouldRefreshOnClose: React.Dispatch<React.SetStateAction<boolean>>;
  readonly closeDrawer: () => void;
  readonly refresh: () => Promise<void>;
}

const ManageRepaymentPaymentDrawerContent = ({
  order,
  setShouldRefreshOnClose,
  closeDrawer,
  refresh,
}: ManageRepaymentPaymentDrawerContentProps) => {
  const [showMessage] = useMessage();
  const [fulfilmentStatus, setFulfilmentStatus] = useState<'NOT_INITIATED' | 'SUCCEEDED' | 'PENDING' | 'FAILED' | null>(
    order.refinancingPlan?.fulfilmentStatus ?? null
  );
  const [fundingStatus, setFundingStatus] = useState<'ACCEPTED' | 'PENDING' | 'REJECTED' | null>(
    order.refinancingPlan?.fundingStatus ?? null
  );
  const [isStatusUpdating, setIsStatusUpdating] = useState<boolean>(false);
  const [isFulfilling, setIsFulfilling] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const updatePlanStatus = useCallback(async () => {
    if (!order.refinancingPlan) return;
    try {
      setIsStatusUpdating(true);
      const updatedOrder = await RefinancingAPI.updateAndReturnRepaymentPlanForOrder(
        order.companyId,
        order.refinancingPlan.id,
        order.id
      );

      setFundingStatus(updatedOrder.fundingStatus);
      setFulfilmentStatus(updatedOrder.fulfilmentStatus);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not update plan status. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsStatusUpdating(false);
    }
  }, [order, showMessage, setShouldRefreshOnClose]);

  const fulfilRefinancingPlan = useCallback(async () => {
    if (!order.refinancingPlan) return;
    try {
      setIsFulfilling(true);
      const updatedOrder = await RefinancingAPI.fulfilRepaymentPlanForOrder(
        order.companyId,
        order.refinancingPlan.id,
        order.id
      );

      setFundingStatus(updatedOrder.fundingStatus);
      setFulfilmentStatus(updatedOrder.fulfilmentStatus);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not fulfil plan. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsFulfilling(false);
    }
  }, [order, showMessage, setShouldRefreshOnClose]);

  const deletePlan = useCallback(async () => {
    setIsDeleting(true);
    if (!order.refinancingPlan?.id) {
      showMessage('Missing refinancing plan id', 'error');
      return;
    }
    try {
      await RefinancingAPI.deleteOrderRepaymentPlan(order.companyId, order.id, order.refinancingPlan.id);
      setShouldRefreshOnClose(true);
      closeDrawer();
      await refresh();
    } catch (error) {
      showMessage(`Could not delete plan. ${nestErrorMessage(error)}`, 'error');
    }
    setIsDeleting(false);
  }, [closeDrawer, refresh, setShouldRefreshOnClose, order, showMessage]);

  return order.refinancingPlan ? (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={titleSx}>Refinance</Typography>
        {order.refinancingPlan.id &&
          (order.refinancingPlan.fulfilmentStatus === 'FAILED' ||
            order.refinancingPlan.fundingStatus === 'REJECTED') && (
            <IconButton sx={iconButtonSx} onClick={deletePlan} disabled={isDeleting}>
              <TrashIcon {...actionIconSize} />
            </IconButton>
          )}
      </Box>

      <Box sx={{ display: 'flex', gap: spacing.g5 }}>
        <Box sx={{ width: 1 }}>
          <Box sx={fieldSx}>
            <Typography sx={themeFonts.caption}>Fulfilment Status</Typography>
            <Typography sx={{ ...themeFonts.title4, mb: spacing.m10 }}>{fulfilmentStatus ?? 'N/A'}</Typography>

            <Typography sx={themeFonts.caption}>Funding Status</Typography>
            <Typography sx={themeFonts.title4}>{fundingStatus ?? 'N/A'}</Typography>
          </Box>
        </Box>
        <LoaderButton
          sizeVariant="small"
          colorVariant="secondary"
          name="Update status"
          onClick={updatePlanStatus}
          loading={isStatusUpdating}
          type="button"
        />
      </Box>
      {(!fulfilmentStatus || !['SUCCEEDED', 'PENDING'].includes(fulfilmentStatus)) && (
        <Box sx={buttonBoxSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name="Fulfil refinancing plan"
            onClick={fulfilRefinancingPlan}
            loading={isFulfilling}
            type="button"
            fullWidth
            disabled={fundingStatus !== 'ACCEPTED'}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Typography sx={{ ...themeFonts.title4, color: themeColors.Red }}>Something went wrong.</Typography>
  );
};
