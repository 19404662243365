import { useCallback, useEffect, useState } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { USER_BENEFITS_PENSION_SUMMARY_ROUTE } from '@/lib/routes';
import { PensionAPI } from '@/v2/feature/benefits/subfeature/pension/pension.api';
import { UserProfilePension } from '@/v2/feature/user/features/user-profile/pension/user-profile-pension.page';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';

interface Props {
  readonly userId: number;
  readonly onUpdate: () => Promise<void>;
}

export const UserPensionRouter = ({ userId, onUpdate }: Props): JSX.Element => {
  const [employeePension, setEmployeePension] = useState<UserPensionDto | null>(null);
  const [loading, setIsLoading] = useState(true);
  const autoEnrollmentCallback = useCallback(async () => {
    setIsLoading(true);
    try {
      const employeePension = await PensionAPI.getEmployeePension(userId);
      setEmployeePension(employeePension);
      await onUpdate();
    } catch (error) {
      setEmployeePension(null);
    }
    setIsLoading(false);
  }, [onUpdate, userId]);

  useEffect(() => {
    autoEnrollmentCallback();
  }, [autoEnrollmentCallback]);

  return (
    <Switch>
      <Route exact path={USER_BENEFITS_PENSION_SUMMARY_ROUTE}>
        {employeePension && (
          <UserProfilePension
            userId={userId}
            employeePension={employeePension}
            onEmployeeUpdate={autoEnrollmentCallback}
            loading={loading}
          />
        )}
      </Route>

      <Redirect to={USER_BENEFITS_PENSION_SUMMARY_ROUTE} />
    </Switch>
  );
};
