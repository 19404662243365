import { Box } from '@mui/material';
import { Align, Chart, LayoutPosition, TooltipModel } from 'chart.js/auto';
import { Radar } from 'react-chartjs-2';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';

export interface ChartRadarProps {
  series: {
    data: number[];
    label: string;
    fill: boolean;
    backgroundColor?: string;
    borderColor?: string;
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointHoverBackgroundColor?: string;
    pointHoverBorderColor?: string;
  }[];
  categories: string[];
  externalTooltipHandler: (
    this: TooltipModel<'radar'>,
    args: { chart: Chart<'radar'>; tooltip: TooltipModel<'radar'> }
  ) => void | null;
  tooltipEnabled?: boolean;
  noDataTitle?: string;
}
export const ChartRadar = ({
  series,
  categories,
  externalTooltipHandler,
  tooltipEnabled = true,
  noDataTitle = 'Not sufficient data to show results.',
}: ChartRadarProps) => {
  const chartData = {
    labels: categories,
    datasets: series,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: { padding: 4, stepSize: 25, display: false },
        border: {
          dashOffset: 0.5,
        },
        pointLabels: {
          display: 'auto' as boolean | 'auto',
          color: themeColors.Grey,
          font: {
            size: 14,
            family: 'Inter, sans-serif',
            weight: 300,
          },
          callback: (label: string) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) return '';

            if (context.measureText(label).width <= 150) {
              return label;
            }
            while (label.length > 0) {
              label = label.slice(0, -1);
              if (context.measureText(label + '...').width <= 150) {
                return label + '...';
              }
            }

            return label ?? '';
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as LayoutPosition,
        align: 'center' as Align,
        fullWidth: false,
        labels: {
          pointStyle: 'circle',
          usePointStyle: true,
          boxWidth: 5,
          boxHeight: 5,
          padding: 20,
          color: themeColors.Grey,
          font: {
            size: 14,
            family: 'Inter, sans-serif',
            weight: 300,
          },
        },
      },
      tooltip: {
        intersect: true,
        enabled: tooltipEnabled,
        position: 'nearest' as 'nearest' | 'average',
        external: externalTooltipHandler ?? null,
        bodyFont: {
          size: 12,
          family: 'Inter, sans-serif',
          weight: 300,
        },
        titleFont: {
          size: 12,
          family: 'Inter, sans-serif',
          weight: 300,
        },
        footerFont: {
          size: 12,
          family: 'Inter, sans-serif',
          weight: 300,
        },
      },
    },
  };
  return categories && categories.length > 0 ? (
    <Radar data={chartData} options={options} />
  ) : (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: '100%', verticalAlign: 'center' }}>
      <Typography variant="caption" color="Grey">
        {noDataTitle}
      </Typography>
    </Box>
  );
};
