import { useMemo } from 'react';

import { Box } from '@mui/material';
import { CircularProgressChart } from '@v2/feature/absence/me/policies/policy-breakdown/components/circular-progress-chart.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { round2Digits } from '@v2/util/number.util';

import { Typography } from '@/v2/components/typography/typography.component';
import { AbsencePolicyAllowanceType } from '@/v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, isHourlyPolicy } from '@/v2/feature/absence/absence.util';
import { AbsenceTimeWidgetBreakdown } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { themeColors } from '@/v2/styles/colors.styles';

export const PolicyAnalytics = ({ primaryBalance }: { primaryBalance: AbsenceTimeWidgetBreakdown }) => {
  const { polyglot } = usePolyglot();

  const userTotalAllowance = useMemo(() => {
    if (!primaryBalance.proRatedAbsenceAllowance?.totalProratedAllowance) return 0;
    return (
      primaryBalance.proRatedAbsenceAllowance.totalProratedAllowance +
      (primaryBalance.carryOver.remaining ?? 0) +
      (primaryBalance.oneOffAdjustment! ?? 0)
    );
  }, [primaryBalance]);

  const percentage = useMemo(() => {
    return !primaryBalance?.currentBalance || !primaryBalance!.userAllowance
      ? 0
      : (primaryBalance.currentBalance * 100) / userTotalAllowance;
  }, [primaryBalance, userTotalAllowance]);

  const unitsLeft = useMemo(() => {
    if (!primaryBalance) return 0;
    if (
      primaryBalance.isOnRegularSchedule &&
      primaryBalance.currentBalanceInDays &&
      primaryBalance.currentAverageWorkDayLength &&
      !isHourlyPolicy({ allowanceType: primaryBalance.policyAllowanceType })
    )
      return polyglot.t('PolicyAnalytics.numDays', {
        smart_count: primaryBalance.currentBalanceInDays,
      });
    return convertMinutesToClockHours(primaryBalance.currentBalance, polyglot);
  }, [primaryBalance, polyglot]);

  const unitsTaken = useMemo(() => {
    if (!primaryBalance) return 0;
    if (
      primaryBalance.isOnRegularSchedule &&
      primaryBalance.currentAverageWorkDayLength &&
      !isHourlyPolicy({ allowanceType: primaryBalance.policyAllowanceType })
    )
      return polyglot.t('PolicyAnalytics.numDays', {
        smart_count: round2Digits(primaryBalance.unitsTaken.total / primaryBalance.currentAverageWorkDayLength)!,
      });

    return convertMinutesToClockHours(primaryBalance?.unitsTaken.total, polyglot);
  }, [primaryBalance, polyglot]);

  const balanceInDays = useMemo(() => {
    return primaryBalance.currentBalanceInDays;
  }, [primaryBalance]);

  if (!primaryBalance) return null;

  return (
    <>
      {primaryBalance.policyAllowanceType !== AbsencePolicyAllowanceType.Unlimited &&
      primaryBalance.policyAllowance !== null &&
      unitsLeft !== null ? (
        <Box sx={{ position: 'relative' }}>
          <CircularProgressChart
            value={percentage}
            color={primaryBalance.policyColor}
            size={168}
            thickness={3}
            baseColor={themeColors.white}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {balanceInDays !== null && primaryBalance?.isOnRegularSchedule ? (
              <Box sx={{ display: 'block', textAlign: 'center' }}>
                <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
                  {primaryBalance.currentAverageWorkDayLength
                    ? polyglot.t('PolicyAnalytics.numDays', { smart_count: balanceInDays ? balanceInDays : 0 })
                    : convertMinutesToClockHours(primaryBalance.currentBalance, polyglot)}
                </Typography>
                <Typography variant="captionSmall" sx={{ color: themeColors.DarkGrey, width: '100px' }}>
                  {primaryBalance.policyName}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'block', textAlign: 'center' }}>
                <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
                  {primaryBalance.currentAverageWorkDayLength
                    ? polyglot.t('PolicyAnalytics.numDays', { smart_count: balanceInDays ? balanceInDays : 0 })
                    : convertMinutesToClockHours(primaryBalance.currentBalance, polyglot)}
                </Typography>

                <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                  {polyglot.t('PolicyAnalytics.left', { units: unitsLeft })}
                </Typography>

                <Typography variant="caption" sx={{ color: themeColors.DarkGrey, width: '100px', alignSelf: 'center' }}>
                  {primaryBalance.policyName}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : primaryBalance.policyAllowanceType === AbsencePolicyAllowanceType.Unlimited ? (
        <Box sx={{ display: 'block', textAlign: 'center' }}>
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('PolicyAnalytics.taken', { units: unitsTaken })}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            {primaryBalance.policyName}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'block', textAlign: 'center' }}>
          <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('PolicyAnalytics.taken', { units: unitsTaken })}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            {primaryBalance.policyName}
          </Typography>
        </Box>
      )}
    </>
  );
};
