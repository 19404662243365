import React, { useEffect, useMemo } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { sortByUserProfileTabOrder } from '@/component/dashboard/userDetails/user-details.interface';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { SETTINGS_PROFILE_TAB_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { settingsMaxWidth } from '@/v2/feature/custom-fields/components/styles.layout';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { translateCustomFieldCategory } from '@/v2/infrastructure/i18n/translate.util';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';

export const CustomProfileSectionsPage = () => {
  const { polyglot } = usePolyglot();

  const { data: allForms, isLoading } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false,
  });
  const { trackPage } = useJune();

  const tabIds = useMemo(() => {
    if (!allForms) return allForms;
    const formTabs = [...new Set(allForms?.map(({ formTab }) => formTab))];
    // list them in the same order as the profile tabs
    return sortByUserProfileTabOrder(formTabs, (formTab) => formTab);
  }, [allForms]);

  useEffect(() => {
    trackPage('Company profile fields settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper loading={isLoading}>
      <SettingsSectionContent title={polyglot.t('CompanyProfileSectionsPage.settingHeader')}>
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Component,
                  value: <FieldsList tabIds={tabIds ?? []} />,
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};

const FieldsList = ({ tabIds }: { tabIds: string[] }) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  return (
    <Box sx={settingsMaxWidth}>
      {tabIds?.map((tabId, i) => (
        <React.Fragment key={tabId}>
          {i > 0 && <div style={{ height: '1px', margin: '10px 0', backgroundColor: themeColors.GreyLight }}></div>}
          <Stack
            sx={{
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              textTransform: 'capitalize',
            }}
          >
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {translateCustomFieldCategory(tabId, polyglot)}
            </Typography>
            <IconButton
              sx={tableIconButtonSx}
              onClick={() => {
                routerHistory.push(generatePath(SETTINGS_PROFILE_TAB_ROUTE, { tabId }));
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        </React.Fragment>
      ))}
    </Box>
  );
};
