import React, { Dispatch, SetStateAction, Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { Divider } from '@v2/components/divider.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { isHourlyPolicy, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import {
  getAccrualBreakdownValue,
  getAllowanceProratingRuleShortDesc,
  getAnnualBalanceFromBreakdown,
  getCarryOverExpiryOn,
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getHolidaysProratingRuleShort,
  getOneOffAdjustmentFromBreakdown,
  getProratedAllowanceFromBreakdown,
  getPublicHolidayAllowanceFromBreakdown,
  getRoundingAdjustment,
  getTotalAllowanceBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { AccrualDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/accrual-drawer.component';
import { AllowanceAdjustmentDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/allowance-adjustment-drawer.component';
import { BalanceAdjustmentsDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/balance-adjustments-drawer.component';
import { BalanceCarryOverDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/balance-carry-over-drawer.component';
import { ProratedAllowanceDetailsDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/prorated-allowance-details-drawer.component';
import { PublicHolidayAllowanceDetailsDrawer } from '@v2/feature/absence/me/policies/policy-breakdown/components/public-holiday-allowance-details-drawer.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';

const WIDTH_COLUMN1 = '80%';
const WIDTH_COLUMN2 = '20%';

interface AllowanceDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
  readonly userId: number;
  readonly refresh: (policyId: number | 'all') => Promise<void>;
}

export const AllowanceDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats,
  userId,
  refresh,
}: AllowanceDrawerProps) => {
  const { polyglot } = usePolyglot();
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <Box>
          <Typography variant="title2">{polyglot.t('AllowanceDrawer.allowance')}</Typography>
          <AllowanceDrawerContent
            userId={userId}
            refresh={refresh}
            userBalanceDetailedStats={userBalanceDetailedStats}
            absencePolicy={absencePolicy}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface AllowanceDrawerFieldProps {
  readonly title: string;
  readonly description?: string;
  readonly value: string;
  readonly action?: () => void;
}

const AllowanceDrawerField = ({ title, description, value, action }: AllowanceDrawerFieldProps) => (
  <Box sx={{ display: 'flex' }}>
    <Box sx={{ width: WIDTH_COLUMN1, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="title4">{title}</Typography>
      {description && (
        <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
          {description}
        </Typography>
      )}
    </Box>
    <Box
      onClick={action}
      sx={{ width: WIDTH_COLUMN2, display: 'flex', justifyContent: 'end', cursor: action ? 'pointer' : undefined }}
    >
      <Typography variant="title4" sx={{ textDecoration: action ? 'underline' : 'none' }}>
        {value}
      </Typography>
    </Box>
  </Box>
);

interface AllowanceEditableDrawerFieldProps {
  readonly title: string;
  readonly description?: string;
  readonly secondLine: string;
  readonly value: string;
  readonly optionalLine?: string;
  readonly optionalLineValue?: string;
  readonly action: () => void;
}

const AllowanceEditableDrawerField = ({
  title,
  description,
  secondLine,
  value,
  optionalLine,
  optionalLineValue,
  action,
}: AllowanceEditableDrawerFieldProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: WIDTH_COLUMN1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="title4">{title}</Typography>
        {description && (
          <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>

    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: WIDTH_COLUMN1, display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        <Typography variant="caption">{secondLine}</Typography>
      </Box>
      <Box onClick={action} sx={{ cursor: 'pointer', width: WIDTH_COLUMN2, display: 'flex', justifyContent: 'end' }}>
        <Typography variant="title4" sx={{ textDecoration: 'underline' }}>
          {value}
        </Typography>
      </Box>
    </Box>

    {optionalLine && (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: WIDTH_COLUMN1, display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
          <Typography variant="caption">{optionalLine}</Typography>
        </Box>
        {optionalLineValue && (
          <Box sx={{ cursor: 'pointer', width: WIDTH_COLUMN2, display: 'flex', justifyContent: 'end' }}>
            <Typography variant="title4">{optionalLineValue}</Typography>
          </Box>
        )}
      </Box>
    )}
  </Box>
);

interface AllowanceDrawerContentProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
  readonly userId: number;
  readonly refresh: (policyId: number | 'all') => Promise<void>;
}

const AllowanceDrawerContent = ({
  userId,
  absencePolicy,
  userBalanceDetailedStats,
  refresh,
}: AllowanceDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [isPHDrawerOpen, setIsPHDrawerOpen] = useState<boolean>(false);
  const [isProratedAllowanceDrawerOpen, setIsProratedAllowanceDrawerOpen] = useState<boolean>(false);
  const [isAdjustmentsDrawerOpen, setIsAdjustmentsDrawerOpen] = useState<boolean>(false);
  const [isCarryOverDrawerOpen, setIsCarryOverDrawerOpen] = useState<boolean>(false);
  const [isAllowanceUpdateDrawerOpen, setIsAllowanceUpdateDrawerOpen] = useState<boolean>(false);
  const [isAccrualDrawerOpen, setIsAccrualDrawerOpen] = useState<boolean>(false);

  const isHourly = isHourlyPolicy(absencePolicy);
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];

  const proratedAllowance = getProratedAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const annualAllowance = getAnnualBalanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const PHAllowance = getPublicHolidayAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const roundingAdjustment = getRoundingAdjustment(absencePolicy, policyBreakdown, polyglot);

  const carryOver = getCarryOverFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const oneOffAdjustments = getOneOffAdjustmentFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const totalProratedAllowance = getTotalAllowanceBreakdown(absencePolicy, policyBreakdown, polyglot);

  const carryOverExpiryOn = getCarryOverExpiryOn(absencePolicy, policyBreakdown, polyglot);
  const expiredCarryOver = getExpiredCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  const allowanceProratingRule = getAllowanceProratingRuleShortDesc(absencePolicy, polyglot);
  const holidayProratingRule = getHolidaysProratingRuleShort(absencePolicy, polyglot);
  const accruedSoFar = getAccrualBreakdownValue(absencePolicy, policyBreakdown, 'total', polyglot);

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: spacing.m20 }}>
        <AllowanceDrawerField
          title={polyglot.t('AllowanceDrawer.annualPolicyAllowance')}
          description={polyglot.t('AllowanceDrawer.forWholeYear')}
          value={annualAllowance}
          action={
            isUnlimited
              ? undefined
              : () => {
                  setIsAllowanceUpdateDrawerOpen(true);
                }
          }
        />
        <Divider style={{ marginBottom: spacing.m10 }} />

        {!isHourly && !policyBreakdown?.isOnRegularSchedule && (
          <Box>
            <Typography variant="captionSmall" sx={{ color: themeColors.Grey, mb: spacing.m10 }}>
              {polyglot.t('AllowanceDrawer.allowanceToHoursString1')}
            </Typography>

            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
              {polyglot.t('AllowanceDrawer.allowanceToHoursString2')}
            </Typography>
          </Box>
        )}
        {proratedAllowance && (
          <AllowanceDrawerField
            title={polyglot.t('AllowanceDrawer.proratedAllowance')}
            description={allowanceProratingRule}
            value={proratedAllowance}
            action={
              absencePolicy.proratingFte || absencePolicy.proratingStartDate
                ? () => {
                    setIsProratedAllowanceDrawerOpen(true);
                  }
                : undefined
            }
          />
        )}
        {PHAllowance && (
          <AllowanceDrawerField
            title={polyglot.t('AllowanceDrawer.publicHolidayAllowance')}
            description={holidayProratingRule}
            value={PHAllowance}
            action={() => {
              setIsPHDrawerOpen(true);
            }}
          />
        )}

        {roundingAdjustment && (
          <AllowanceDrawerField title={polyglot.t('AllowanceDrawer.roundingAdjustment')} value={roundingAdjustment} />
        )}

        {absencePolicy.carryOverEnabled && (
          <AllowanceEditableDrawerField
            title={polyglot.t('AllowanceDrawer.carryOver')}
            secondLine={polyglot.t('AllowanceDrawer.carryOverPrevYear')}
            description={carryOverExpiryOn ?? undefined}
            value={carryOver}
            optionalLine={expiredCarryOver === null ? undefined : polyglot.t('AllowanceDrawer.expiredCarryOver')}
            optionalLineValue={expiredCarryOver === null ? undefined : expiredCarryOver}
            action={() => {
              setIsCarryOverDrawerOpen(true);
            }}
          />
        )}

        {oneOffAdjustments && (
          <AllowanceEditableDrawerField
            title={polyglot.t('AllowanceDrawer.adjustments')}
            secondLine={polyglot.t('AllowanceDrawer.totalAdjustments')}
            value={oneOffAdjustments}
            action={() => {
              setIsAdjustmentsDrawerOpen(true);
            }}
          />
        )}
        {totalProratedAllowance && <Divider style={{ marginBottom: spacing.m10 }} />}
        {totalProratedAllowance && (
          <AllowanceDrawerField title={polyglot.t('AllowanceDrawer.totalAllowance')} value={totalProratedAllowance} />
        )}
        {accruedSoFar && (
          <AllowanceDrawerField
            title={polyglot.t('AllowanceDrawer.accruedSoFar')}
            value={accruedSoFar}
            action={() => {
              setIsAccrualDrawerOpen(true);
            }}
          />
        )}
      </Box>

      {(absencePolicy.proratingFte || absencePolicy.proratingStartDate) && (
        <ProratedAllowanceDetailsDrawer
          isOpen={isProratedAllowanceDrawerOpen}
          setIsOpen={setIsProratedAllowanceDrawerOpen}
          absencePolicy={absencePolicy}
          userBalanceDetailedStats={userBalanceDetailedStats}
        />
      )}

      <PublicHolidayAllowanceDetailsDrawer
        isOpen={isPHDrawerOpen}
        setIsOpen={setIsPHDrawerOpen}
        absencePolicy={absencePolicy}
        userBalanceDetailedStats={userBalanceDetailedStats}
      />
      {policyBreakdown && (
        <BalanceCarryOverDrawer
          isOpen={isCarryOverDrawerOpen}
          setIsOpen={setIsCarryOverDrawerOpen}
          userId={userId}
          absencePolicy={absencePolicy}
          policyBreakdown={policyBreakdown}
          refresh={refresh}
          isOnRegularSchedule={policyBreakdown.isOnRegularSchedule}
          currentAverageWorkDayLength={policyBreakdown.currentAverageWorkDayLength}
        />
      )}

      {policyBreakdown && (
        <BalanceAdjustmentsDrawer
          isOpen={isAdjustmentsDrawerOpen}
          setIsOpen={setIsAdjustmentsDrawerOpen}
          userId={userId}
          absencePolicy={absencePolicy}
          policyBreakdown={policyBreakdown}
          refresh={refresh}
          isOnRegularSchedule={policyBreakdown.isOnRegularSchedule}
          currentAverageWorkDayLength={policyBreakdown.currentAverageWorkDayLength}
        />
      )}

      {policyBreakdown && (
        <AllowanceAdjustmentDrawer
          isOpen={isAllowanceUpdateDrawerOpen}
          setIsOpen={setIsAllowanceUpdateDrawerOpen}
          userId={userId}
          absencePolicy={absencePolicy}
          refresh={refresh}
          policyBreakdown={policyBreakdown}
          currentCycle={policyBreakdown.currentCycle}
        />
      )}

      {policyBreakdown && (
        <AccrualDrawer
          isOpen={isAccrualDrawerOpen}
          setIsOpen={setIsAccrualDrawerOpen}
          absencePolicy={absencePolicy}
          policyBreakdown={policyBreakdown}
        />
      )}
    </Box>
  );
};
