import { Fragment, useContext, useMemo, useState } from 'react';

import { jsPDF } from 'jspdf';
import { Dictionary, keyBy } from 'lodash';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { iconSize } from '@/v2/components/forms/editable-title.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { ReviewResultEndpoints } from '@/v2/feature/growth/reviews/api-client/review-result.api';
import { ResultsOverviewByQuestionModal } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-by-question-modal.component';
import { ResultsOverviewQuestionDisplayBar } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-question-display-bar.component';
import { ResultsOverviewReviewComment } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-comment.component';
import { ResultsOverviewReviewScoreTable } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-score-table.component';
import { ResultsOverviewShareResultModal } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-share-result-modal.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReachType, ReviewCycle, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewResult } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { QuestionResponseSummary } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { GrowthForbiddenAccess } from '@/v2/feature/growth/shared/components/growth-forbidden-access.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';
import { captureExcel } from '@/v2/util/export-reports.util';
import { LocalDate } from '@/v2/util/local-date';
import { stripHtml } from '@/v2/util/string.util';

export const ReviewCycleDetailResultsOverviewPage = ({
  reviewCycle,
  cycleLoading,
  reachType,
}: {
  reviewCycle: Pick<ReviewCycle, 'state' | 'internalName' | 'displayName' | 'timelineSettings'>;
  cycleLoading: boolean;
  reachType: ReachType;
}) => {
  const { getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const params = useParams<{ cycleId: string; revieweeId: string }>();
  const { cycleId, revieweeId } = params;
  const { data: results, isLoading: resultsLoading, mutate: refreshResults, error: resultsError } = useApiClient(
    ReviewResultEndpoints.getReviewOverviewResultByReviewee(revieweeId, cycleId),
    {
      suspense: false,
    }
  );

  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const growthFactors: Dictionary<GrowthFactor> = useMemo(() => {
    return keyBy(allGrowthFactors, 'name');
  }, [allGrowthFactors]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSharableOpen, setIsSharableOpen] = useState<boolean>(false);

  const [selectedQuestion, setSelectedQuestion] = useState<QuestionResponseSummary | undefined>(undefined);

  const reportName = useMemo(() => `${reviewCycle.displayName}-result`, [reviewCycle.displayName]);

  const getAnswerLabel = (question: QuestionResponseSummary, answer: ReviewAnswer) => {
    if (!answer?.answer) return '';
    if (question.type === QuestionType.ScaleQuestion)
      return `${question.scaleConfig?.points?.[answer.answer]} (${
        question.scaleConfig?.value?.[answer.answer] || 0
      } out of ${Math.max(...Object.values(question.scaleConfig?.value || []).map(Number))})`;

    if (question.type === QuestionType.MultipleAnswer)
      return answer.answer
        .split(',')
        .map((ans) => question.answerOptions?.[ans])
        .filter(Boolean)
        .join(', ');

    if (question.type === QuestionType.SingleAnswer) return question.answerOptions?.[answer.answer] || 'NA';

    return '';
  };

  const generateExcel = () => {
    if (!results) return [];

    const fromDate = new LocalDate(reviewCycle.timelineSettings.startDate)
      .getDate()
      .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });
    const revieweeName = getCachedUserById(Number(revieweeId))?.displayName || 'NA';

    const { questionResponseSummary } = results;
    const data = [];

    const addBoldHeader = (text: string) => {
      data.push([{ v: text, s: { font: { bold: true } } }]);
    };

    addBoldHeader(`${reviewCycle.displayName} - ${fromDate}` || `Review cycle - ${fromDate}`);
    addBoldHeader(`You are reviewing: ${revieweeName}`);
    addBoldHeader('');

    data.push(['Question', 'Factor', 'Role', 'Name', 'Answer', 'Comment']);
    (questionResponseSummary || []).forEach((question) => {
      const { questionMain, questionSelf, factor, answers } = question;

      answers.forEach((answer) => {
        const { answerType, comment } = answer;
        const questionText = answerType === ReviewerTypes.Self ? questionSelf : questionMain;
        const username =
          results?.visibilitySettings?.hidePeerAuthor && !answer.reviewerId
            ? 'Author hidden'
            : getCachedUserById(answer.reviewerId)?.displayName || 'NA';

        const commentText = comment ? stripHtml(comment) : '';
        const answerLabel = getAnswerLabel(question, answer);

        data.push([questionText, factor, answerType, username, answerLabel, commentText]);
      });
    });

    captureExcel(data, reportName);
  };

  const generatePdfReport = (
    results: ReviewResult | null | undefined,
    reviewCycle: Pick<ReviewCycle, 'state' | 'internalName' | 'displayName' | 'timelineSettings'>,
    reportName: string
  ) => {
    if (!results) return;
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFont('Times-Roman', 'bold');

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 14;
    const maxWidth = pageWidth - margin * 2;
    const lineHeight = 8;
    const textLineHeight = 6;
    let yPosition = 22;

    const checkAndAddPage = () => {
      if (yPosition + lineHeight > pageHeight - margin) {
        doc.addPage();
        yPosition = margin + lineHeight;
      }
    };

    const revieweeName = getCachedUserById(Number(revieweeId))?.displayName || 'NA';
    // Title
    doc.setFontSize(18);
    doc.text(`${reviewCycle.displayName}`, margin, yPosition);
    yPosition += textLineHeight;

    // Review cycle details
    doc.setFont('Times-Roman', 'normal');
    doc.setFontSize(12);
    doc.text(
      `Start Date: ${new LocalDate(reviewCycle.timelineSettings.startDate).toLocaleDateString()}`,
      margin,
      yPosition
    );
    yPosition += textLineHeight;

    doc.text(`You are reviewing: ${revieweeName}`, margin, yPosition);
    yPosition += textLineHeight * 2;

    doc.setFont('Times-Roman', 'bold');
    doc.setFontSize(14);
    doc.text('Review results', margin, yPosition);
    yPosition += 4;
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += textLineHeight;
    // Questions and answers
    results.questionResponseSummary.forEach((question, idx) => {
      yPosition += lineHeight;
      const { questionMain, questionSelf, answers, type } = question;
      const questionText = questionMain || questionSelf;
      doc.setFont('Times-Roman', 'bold');
      doc.setFontSize(12);
      const splitQuestionText = doc.splitTextToSize(`${questionText}`, maxWidth);

      splitQuestionText.forEach((line: string) => {
        checkAndAddPage();
        doc.text(line, margin, yPosition);
        yPosition += textLineHeight;
      });

      yPosition += 1;

      answers.forEach((answer) => {
        const { comment } = answer;
        const username =
          results?.visibilitySettings?.hidePeerAuthor && !answer.reviewerId
            ? 'Author hidden'
            : getCachedUserById(answer.reviewerId)?.displayName || 'NA';
        const commentText = comment ? stripHtml(comment) : '';
        const answerLabel = type === QuestionType.OpenEnded ? commentText : getAnswerLabel(question, answer);

        doc.setFont('Times-Roman', 'normal');
        doc.setFontSize(12);
        const answerText = `[${username}] ${answerLabel}`;
        const splitAnswerText = doc.splitTextToSize(answerText, maxWidth);

        splitAnswerText.forEach((line: string) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });

        if (commentText && type !== QuestionType.OpenEnded) {
          const splitCommentText = doc.splitTextToSize(`[Comment]: ${commentText}`, maxWidth);
          splitCommentText.forEach((line: string) => {
            checkAndAddPage();
            doc.text(line, margin, yPosition);
            yPosition += textLineHeight;
          });
        }

        yPosition += 2;
      });

      yPosition += lineHeight / 2;
      checkAndAddPage();
      if (idx !== results.questionResponseSummary.length - 1) {
        doc.setLineWidth(0.5);
        doc.setDrawColor(0);
        doc.line(margin, yPosition, pageWidth - margin, yPosition);
        yPosition += lineHeight / 2;
      }
    });

    // Review comments section
    if (results?.comments) {
      yPosition += textLineHeight * 2;
      doc.setFont('Times-Roman', 'bold');
      doc.setFontSize(14);
      doc.text('Review comments', margin, yPosition);
      yPosition += 4;
      doc.setLineWidth(1);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += textLineHeight;

      results.comments.forEach((commentObj) => {
        const commentText = stripHtml(commentObj?.comment ?? '');
        const isPublic = commentObj.isPublic ? 'Public comment' : 'Private comment';
        const username = getCachedUserById(commentObj.commentBy)?.displayName || 'NA';

        doc.setFont('Times-Roman', 'normal');
        doc.setFontSize(12);
        const commentHeader = `[${isPublic} by ${username}]:`;
        const splitCommentHeader = doc.splitTextToSize(commentHeader, maxWidth);

        splitCommentHeader.forEach((line: string) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });

        const splitCommentText = doc.splitTextToSize(commentText, maxWidth);

        splitCommentText.forEach((line: string) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });

        yPosition += lineHeight;
      });
    }

    doc.save(`${reportName}.pdf`);
  };

  if (!results && resultsError && resultsError.status === 403) {
    return (
      <div style={{ width: '100%', margin: spacing.s6 }}>
        <GrowthForbiddenAccess />
      </div>
    );
  }

  return (
    <SettingsSectionContent
      id={reportName}
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reachType} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      headerWidth="100%"
      loading={cycleLoading}
      buttons={[
        <StyledMenuComponent
          options={[
            {
              handler: () => generatePdfReport(results, reviewCycle, reportName),
              label: 'PDF report',
            },
            {
              handler: () => generateExcel(),
              label: 'Excel report',
            },
          ]}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Export',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />,
      ]}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <ResultsOverviewReviewScoreTable
                    results={results}
                    reviewCycle={reviewCycle}
                    reviewScore={results ? results.reviewerScoreSummary : []}
                    resultsLoading={Boolean(resultsLoading)}
                    setIsSharableOpen={setIsSharableOpen}
                    reachType={reachType}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Results',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <ResultsOverviewScoreByAnswers
                    answerScore={results ? results.questionResponseSummary : []}
                    setIsOpen={setIsOpen}
                    setSelectedQuestion={setSelectedQuestion}
                    growthFactors={growthFactors}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Comments',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <ResultsOverviewReviewComment
                    results={results}
                    refreshResults={refreshResults}
                    loggedInUser={state.user.userId}
                    reachType={reachType}
                  />
                ),
              },
            ],
          },
        ]}
      />

      <ResultsOverviewByQuestionModal
        selectedQuestion={selectedQuestion}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        growthFactors={growthFactors}
      />

      <ResultsOverviewShareResultModal
        isOpen={isSharableOpen}
        setIsOpen={setIsSharableOpen}
        onClose={() => {
          setIsSharableOpen(false);
        }}
        results={results}
        refreshResults={refreshResults}
      />
    </SettingsSectionContent>
  );
};

const ResultsOverviewScoreByAnswers = ({
  answerScore,
  setIsOpen,
  setSelectedQuestion,
  growthFactors,
}: {
  answerScore: QuestionResponseSummary[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedQuestion: React.Dispatch<QuestionResponseSummary>;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  return (
    <Fragment>
      {answerScore.map((question, idx) => {
        return (
          <ResultsOverviewQuestionDisplayBar
            key={idx}
            question={question}
            growthFactors={growthFactors}
            onClick={() => {
              setIsOpen(true);
              setSelectedQuestion(question);
            }}
          />
        );
      })}
    </Fragment>
  );
};
