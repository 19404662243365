import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { AutocompleteComponent } from '@v2/components/forms/autocomplete.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormDto } from '@v2/feature/custom-fields/custom-profile-fields.dto';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import {
  getCountryOptionsForSelectComponent,
  getEURegionCoutry,
  getUKRegionCountry,
} from '@v2/infrastructure/country/country.util';
import { popularCurrencyOptions } from '@v2/infrastructure/currency/currency.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';

import { UserBankAccountFormSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserBankAccountAPI } from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import {
  CreateUserBankAccountDto,
  UserBankAccountDto,
} from '@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { spacing } from '@/v2/styles/spacing.styles';

type UserBankAccountSectionProps = {
  form: CustomProfileFormDto | undefined;
  onNext: (bankAccount: UserBankAccountDto) => void;
  sx?: SxProps<Theme>;
  bankAccountValues: UserBankAccountDto | null;
  defaultHolderName?: string;
  userId: number;
};

type BankFormDefaultVisibility = {
  country: boolean;
  currency: boolean;
  bankName: boolean;
  accountName: boolean;
  accountNumber: boolean;
  sortCode: boolean;
};

export const UserBankAccountSection = ({
  defaultHolderName,
  onNext,
  sx,
  bankAccountValues,
  userId,
  form,
}: UserBankAccountSectionProps) => {
  const { polyglot } = usePolyglot();

  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = bankAccountValues ? 'edit' : 'add';
  const defaultBankAccountValues = useMemo<CreateUserBankAccountDto>(() => {
    return {
      accountName: defaultHolderName ?? '',
      accountNumber: '',
      sortCode: '',
      bankName: '',
      country: 'United Kingdom',
      currency: 'GBP',
      effectiveDate: new LocalDate().toDateString(),
      customUpdates: [],
    };
  }, [defaultHolderName]);

  const defaultFieldsVisibility: BankFormDefaultVisibility = useMemo(() => {
    if (!form)
      return {
        country: false,
        currency: false,
        bankName: false,
        accountName: false,
        accountNumber: false,
        sortCode: false,
      };

    const countryField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'bankAccount.country');
    const currencyField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'bankAccount.currency');
    const bankNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'bankAccount.bankName');
    const accountNameField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'bankAccount.accountName');
    const accountNumberField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'bankAccount.accountNumber');
    const sortCodeField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'bankAccount.sortCode');

    return {
      country: Boolean(countryField && !countryField.isHidden),
      currency: Boolean(currencyField && !currencyField.isHidden),
      bankName: Boolean(bankNameField && !bankNameField.isHidden),
      accountName: Boolean(accountNameField && !accountNameField.isHidden),
      accountNumber: Boolean(accountNumberField && !accountNumberField.isHidden),
      sortCode: Boolean(sortCodeField && !sortCodeField.isHidden),
    };
  }, [form]);

  const validationSchema = useMemo(() => UserBankAccountFormSchema(polyglot), [polyglot]);

  const formik = useFormik<UserBankAccountDto | CreateUserBankAccountDto>({
    // all default values are required here = no need to clean up data
    initialValues: bankAccountValues ?? defaultBankAccountValues,
    validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      try {
        let updatedValues: UserBankAccountDto;
        if ('bankAccountId' in values) {
          updatedValues = await UserBankAccountAPI.update(userId, values.bankAccountId, values);
        } else {
          updatedValues = await UserBankAccountAPI.create(userId, values);
        }
        onNext(updatedValues);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.bankAccountNotSaved', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  const countryOptions = useMemo(() => getCountryOptionsForSelectComponent(), []);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('UserBankInfoForm.bankAccount')}</Typography>

          {defaultFieldsVisibility.country && (
            <AutocompleteComponent
              name="country"
              label={polyglot.t('BankForm.country')}
              options={countryOptions}
              value={countryOptions.find(({ value }) => value === formik.values.country)}
              compareValue={formik.values.country ?? ' '}
              // @ts-ignore
              onChange={(_, x: { value: string }) => {
                formik.setFieldValue('country', x?.value ?? null);

                const EUCountries = getEURegionCoutry()?.options.map((o) => o.value);
                const isEUCountry = x?.value && EUCountries?.includes(x.value);
                if (isEUCountry && !formik.values.currency) {
                  formik.setFieldValue('currency', 'EUR');
                  return;
                }

                const UKCountries = getUKRegionCountry()?.options.map((o) => o.value);
                const isUKCountry = x?.value && UKCountries?.includes(x.value);
                if (isUKCountry && !formik.values.currency) formik.setFieldValue('currency', 'GBP');
              }}
              error={!!formik.errors.country && Boolean(formik.touched.country)}
              helperText={formik.touched.country ? formik.errors.country : ''}
            />
          )}

          {defaultFieldsVisibility.currency && (
            <AutocompleteComponent
              name="currency"
              label={polyglot.t('BankForm.currency')}
              options={popularCurrencyOptions}
              value={popularCurrencyOptions.find(({ value }) => value === formik.values.currency) ?? null}
              compareValue={formik.values.currency ?? null}
              // @ts-ignore
              onChange={(_, x) => formik.setFieldValue('currency', x?.value ?? null)}
              error={!!formik.errors.currency && formik.touched.currency}
              helperText={formik.touched.currency ? formik.errors.currency : ''}
            />
          )}

          {defaultFieldsVisibility.bankName && (
            <TextfieldComponent
              name="bankName"
              label={polyglot.t('BankForm.bank')}
              value={formik.values.bankName}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('bankName', '')}
              helperText={hasSubmitted && formik.errors.bankName}
              error={hasSubmitted && !!formik.errors.bankName}
            />
          )}

          {defaultFieldsVisibility.accountName && (
            <TextfieldComponent
              name="accountName"
              label={polyglot.t('BankForm.accountName')}
              value={formik.values.accountName}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('accountName', '')}
              helperText={hasSubmitted && formik.errors.accountName}
              error={hasSubmitted && !!formik.errors.accountName}
            />
          )}

          {defaultFieldsVisibility.accountNumber && (
            <TextfieldComponent
              name="accountNumber"
              label={polyglot.t('BankForm.accountNumber')}
              value={formik.values.accountNumber}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('accountNumber', '')}
              helperText={hasSubmitted && formik.errors.accountNumber}
              error={hasSubmitted && !!formik.errors.accountNumber}
            />
          )}

          {defaultFieldsVisibility.sortCode && (
            <TextfieldComponent
              name="sortCode"
              label={polyglot.t('OnboardingByUser.sortCodeBICSWIFT')}
              value={formik.values.sortCode}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('sortCode', '')}
              helperText={hasSubmitted && formik.errors.sortCode}
              error={hasSubmitted && !!formik.errors.sortCode}
            />
          )}

          <CustomFieldComponents
            values={formik.values.customUpdates}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            disabled={saving}
            formName={CustomProfileFormType.BankAccount}
            rowModalMode={rowModalMode}
            fieldSx={{}}
          />

          <LoaderButton
            name={polyglot.t('General.continue')}
            loading={saving}
            sizeVariant="large"
            colorVariant="primary"
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
