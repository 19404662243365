import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceDto } from '@v2/feature/device/device.dto';
import { SuperAdminDeviceDetailsDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-drawer.component';
import { themeColors } from '@v2/styles/colors.styles';

interface DeviceDetailsProps {
  readonly device: DeviceDto | null;
  readonly refreshDevice: () => Promise<void>;
}

export const SuperAdminDeviceDetails = ({ device, refreshDevice }: DeviceDetailsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return device ? (
    <Box sx={{ width: '400px' }}>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <DeviceDetailsLine label="Device ID" value={device.id} />
        <DeviceDetailsLine label="Serial number" value={device.serialNumber} />
        <DeviceDetailsLine label="Model name" value={device.modelName} />
        <DeviceDetailsLine label="Model number" value={device.modelNumber} />
        <DeviceDetailsLine label="Externally invoiced" value={device.externallyInvoiced} />
      </Box>

      <SuperAdminDeviceDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        device={device}
        refreshDevice={refreshDevice}
      />
    </Box>
  ) : (
    <Box>
      <Typography variant="caption">Device not found!</Typography>
    </Box>
  );
};

const DeviceDetailsLine = ({
  label,
  value,
}: {
  label: string;
  value: string | number | boolean | null | undefined;
}) => {
  const valueDisplay = value === null || value === undefined ? 'N/A' : value.toString();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        ':hover': { bgcolor: themeColors.Background },
      }}
    >
      <Box sx={{ width: '30%' }}>
        <Typography variant="caption">{label}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', width: '65%' }}>
        <Typography variant="title4">{valueDisplay}</Typography>
      </Box>
    </Box>
  );
};
