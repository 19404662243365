import axios from 'axios';

import {
  AbsenceAdjustmentImportDto,
  AbsenceAdjustmentImportResultDto,
  AbsenceImportDto,
  AbsenceImportResultDto,
} from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';

export class AbsenceImportAPI {
  static async importViaCsv(importData: readonly AbsenceImportDto[]): Promise<AbsenceImportResultDto> {
    return (await axios.post('/apiv2/absences/import/csv-v2', importData)).data;
  }

  static async importViaCSVUsingQueue(file: ArrayBuffer): Promise<boolean> {
    return (await axios.post('/apiv2/absences/import/csv/queue', file)).data;
  }

  static async importAdjustmentsViaCsv(
    importData: readonly AbsenceAdjustmentImportDto[]
  ): Promise<AbsenceAdjustmentImportResultDto> {
    return (await axios.post('/apiv2/absences/import/adjustments/csv', importData)).data;
  }

  static async importAdjustmentsViaCSVUsingQueue(file: ArrayBuffer): Promise<boolean> {
    return (await axios.post('/apiv2/absences/import/adjustments/csv/queue', file)).data;
  }
}
