import { useCallback, useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import { CompanyPayroll, PayRunSchedule } from '@shared/modules/payroll/payroll.types';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE } from '@/lib/routes';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { DraftPayrunTable } from '@/v2/feature/payroll/components/draft-payrun-table.component';
import { PayrunListSideMenu } from '@/v2/feature/payroll/components/payrun-list-side-menu.component';
import { UpcomingPayrunTable } from '@/v2/feature/payroll/components/upcoming-payrun-table.component';
import { formatPayrunPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrunStatus } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component';
import { PayrunSummaryTable } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-summary-table.component';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayrollLocalEndpoints } from '@/v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@/v2/feature/payroll/payroll.dto';
import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

const PAYRUN_END_OF_SCHEDULE_ERROR = 'for this year have been completed';

type Props = {
  payroll: CompanyPayroll;
  refreshPayroll?: () => Promise<void>;
};

export const PayrollPayruns = ({ payroll, refreshPayroll }: Props) => {
  const { polyglot } = usePolyglot();
  const payrollId = Number(useParams<{ payrollId: string }>().payrollId);
  const loc = useLocation<{ payrunId: number }>();
  const initialPayrunId = loc.state?.payrunId;
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [selectedPayrunId, setSelectedPayrunId] = useState<PayRunDto['id'] | 'next-payrun'>();
  const [loadingPayrun, setLoadingPayrun] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const selectedPayrun = useMemo(() => {
    return payroll?.payruns.find(({ id }) => id === selectedPayrunId) ?? null;
  }, [payroll?.payruns, selectedPayrunId]);

  const { data: payrunDetails, mutate: refreshPayrun } = useApiClient(
    selectedPayrun &&
      PayrollLocalEndpoints.getPayrunDetails(
        payrollId,
        selectedPayrun.taxYear,
        selectedPayrun.payPeriod,
        selectedPayrun.period
      ),
    { suspense: false }
  );

  const showPayrun = useCallback(
    async (payrollId: number, { taxYear, payPeriod, period }: PayRunSchedule, mode: 'create' | 'view') => {
      let payrunEndOfScheduleError = false;
      setLoadingPayrun(true);
      if (mode === 'create') {
        await PayrollExternalApi.createPayRun(payrollId, taxYear, payPeriod)
          .catch((error) => {
            setLoadingPayrun(false);
            if (error?.response?.data?.message.includes(PAYRUN_END_OF_SCHEDULE_ERROR)) {
              payrunEndOfScheduleError = true;
              showMessage(
                polyglot.t('CompanyPayroll.payrunEndOfSchedule', { currentYear: '2024', nextYear: '2025' }),
                'info'
              );
            } else {
              showMessage(nestErrorMessage(error), 'error');
            }
          })
          .then(() => {
            // should not proceed ONLY if payrun end of schedule error
            if (!payrunEndOfScheduleError) {
              routerHistory.push(
                generatePath(PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, {
                  payrollId,
                  taxYear,
                  payPeriod,
                  periodNumber: period,
                })
              );
            }
          });
      } else {
        routerHistory.push(
          generatePath(PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, {
            payrollId,
            taxYear,
            payPeriod,
            periodNumber: period,
          })
        );
      }
    },
    [routerHistory, showMessage, polyglot]
  );

  const { payrun, payrunEntries, previousPayrunEntries } = payrunDetails ?? {};

  const selectInitialPayrun = useCallback(() => {
    if (typeof selectedPayrunId !== 'undefined') return;
    if (typeof initialPayrunId === 'number' && payroll.payruns.some((p) => p.id === initialPayrunId)) {
      setSelectedPayrunId(initialPayrunId);
      return;
    }
    if (payroll.nextPayRun && !payroll.currentPayRun) {
      setSelectedPayrunId('next-payrun');
      return;
    }
    const openPayrun = payroll.payruns.find((payrun) => payrun.state === PayrunStates.draft);
    if (openPayrun) {
      setSelectedPayrunId(openPayrun.id);
      return;
    }
  }, [initialPayrunId, payroll, selectedPayrunId]);

  useEffect(selectInitialPayrun, [selectInitialPayrun]);

  return (
    <>
      <Stack sx={{ flexFlow: 'row', flex: 1, gap: spacing.g20 }}>
        <PayrunListSideMenu
          payroll={payroll}
          selectedPayrunId={selectedPayrunId}
          onPayrunSelected={(payrunId) => setSelectedPayrunId(payrunId)}
          disabled={loadingPayrun}
          sx={{ ml: '20px', height: '100vh' }}
        />
        <Stack sx={{ flex: 1, gap: spacing.g20, height: '100vh' }}>
          {payroll.nextPayRun && selectedPayrunId === 'next-payrun' && (
            <>
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', mr: '20px' }}>
                <Stack sx={{ mt: spacing.mt20, gap: spacing.g2 }}>
                  <Typography variant="title2">{formatPayrunPeriod(payroll.nextPayRun, polyglot.locale())}</Typography>
                  <Typography variant="caption">{polyglot.t('CompanyPayroll.upcoming')}</Typography>
                </Stack>
                <LoaderButton
                  loading={loadingPayrun}
                  sizeVariant="small"
                  colorVariant="primary"
                  onClick={() => payroll.nextPayRun && showPayrun(payroll.id, payroll.nextPayRun, 'create')}
                >
                  {polyglot.t('CompanyPayroll.startPayrun')}
                </LoaderButton>
              </Stack>
              <UpcomingPayrunTable
                disabled={loadingPayrun}
                payroll={payroll}
                nextPayrun={payroll.nextPayRun}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                refreshPayroll={async () => {
                  await Promise.all([refreshPayroll?.(), refreshPayrun?.()]);
                }}
                selectedUsers={selectedUserIds}
                setSelectedUsers={(users) => setSelectedUserIds(users)}
                noCurrencySymbol={false}
                sx={{ display: 'flex', flexFlow: 'column', overflow: 'hidden', mr: spacing.mr20 }}
              />
            </>
          )}
          {payrun && payrunEntries && previousPayrunEntries && (
            <>
              {payrun.state === PayrunStates.draft && (
                <>
                  <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', mr: '20px' }}>
                    <Stack sx={{ mt: spacing.mt20, gap: spacing.g2 }}>
                      <Typography variant="title2">{formatPayrunPeriod(payrun, polyglot.locale())}</Typography>
                      <PayrunStatus state={payrun.state} />
                    </Stack>
                    <LoaderButton
                      loading={loadingPayrun}
                      sizeVariant="small"
                      colorVariant="primary"
                      onClick={() => showPayrun(payroll.id, payrun, 'view')}
                    >
                      {polyglot.t('CompanyPayroll.continuePayrun')}
                    </LoaderButton>
                  </Stack>
                  <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
                  <DraftPayrunTable
                    payroll={payroll}
                    localPayRun={payrun}
                    entries={payrunEntries}
                    previousEntries={previousPayrunEntries}
                    searchQuery={searchQuery}
                    refreshPayroll={async () => {
                      await Promise.all([refreshPayroll?.(), refreshPayrun?.()]);
                    }}
                    sx={{ display: 'flex', flexFlow: 'column', overflow: 'hidden', mr: spacing.mr20 }}
                  />
                </>
              )}
              {payrun.state !== PayrunStates.draft && (
                <>
                  <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', mr: '20px' }}>
                    <Stack sx={{ mt: spacing.mt20, gap: spacing.g2 }}>
                      <Typography variant="title2">{formatPayrunPeriod(payrun, polyglot.locale())}</Typography>
                      <PayrunStatus state={payrun.state} />
                    </Stack>
                    <LoaderButton
                      loading={loadingPayrun}
                      sizeVariant="small"
                      colorVariant="primary"
                      onClick={() => showPayrun(payroll.id, payrun, 'view')}
                    >
                      {polyglot.t('CompanyPayroll.viewPayrun')}
                    </LoaderButton>
                  </Stack>
                  <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
                  <PayrunSummaryTable
                    localPayRun={payrun}
                    entries={payrunEntries}
                    previousEntries={previousPayrunEntries}
                    searchQuery={searchQuery}
                    sx={{ display: 'flex', flexFlow: 'column', overflow: 'hidden', mr: spacing.mr20 }}
                  />
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
