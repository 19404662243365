import { CompanyPlanInfoResult } from '@/models/company.model';
import { DowngradeViability } from '@/v2/feature/company/company-settings/company-settings.interface';
import {
  CompanyUnitDto,
  GeneralSettingsDto,
} from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { AuditTrailDto } from '@/v2/feature/security/security-settings/security.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import { CompanyConfig, SuperAdminCompanyPageResult } from '@shared/modules/company/company.types';
import axios from 'axios';
import { PolyglotData } from '@v2/infrastructure/i18n/i8n.util';
import {
  CompanyTranslationDto,
  EditableCompanyTranslationDto,
} from '@v2/feature/company/company-translation/company-translation.dto';
import {
  CompanySubscription,
  CompanySubscriptionResult,
  PlanDefinition,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { CompanyBasic } from '@/v2/feature/super-admin/features/super-admin-billing-v2/one-off-charge.interface';

export class CompanyAPI {
  static getCompanyUploadURL(): string {
    return `/apiv2/companies/avatar`;
  }

  static getCompanyUploadWallpaperURL(): string {
    return `/apiv2/companies/avatar/wallpaper`;
  }

  static async getTranslationsJSON(): Promise<PolyglotData> {
    return (await axios.get(`/apiv2/companies/translations-json`)).data;
  }

  static async getDefaultTranslationsJSON(): Promise<PolyglotData> {
    return (await axios.get(`/apiv2/companies/default-translations-json`)).data;
  }

  static async getAllCompaniesAsSuperAdmin(param?: {
    page?: string;
    pageSize?: string;
    searchQuery?: string;
    shouldLimitResult?: string;
  }): Promise<SuperAdminCompanyPageResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/companies/superadmin?${searchParams.toString()}`)).data;
  }

  static async deleteOwnCompany(): Promise<void> {
    await axios.delete('/apiv2/companies/company');
  }

  static async createEntity(newEntity: Omit<CompanyUnitDto, 'id'>): Promise<CompanyUnitDto> {
    return (await axios.post('/apiv2/companies/entity', newEntity)).data;
  }

  static async updateEntity(updatedEntity: CompanyUnitDto): Promise<CompanyUnitDto> {
    return (await axios.patch('/apiv2/companies/entity', updatedEntity)).data;
  }

  static async createCompanyTranslation(translation: EditableCompanyTranslationDto): Promise<void> {
    await axios.post('/apiv2/companies/translations', translation);
  }

  static async updateCompanyTranslation(id: number, translation: EditableCompanyTranslationDto): Promise<void> {
    await axios.patch(`/apiv2/companies/translations/${id}`, translation);
  }

  static async deleteCompanyTranslation(id: number): Promise<void> {
    await axios.delete(`/apiv2/companies/translations/${id}`);
  }

  static async deleteEntity(entity: Pick<CompanyUnitDto, 'id'>): Promise<void> {
    await axios.delete(`/apiv2/companies/entity/${entity.id}`);
  }

  static async getGeneralSettings(): Promise<GeneralSettingsDto> {
    return (await axios.get('/apiv2/companies/general-settings')).data;
  }

  static async updateGeneralSettings(
    updatedSettings: Omit<GeneralSettingsDto, 'entities' | 'wallpaper'>
  ): Promise<void> {
    await axios.patch('/apiv2/companies/general-settings', updatedSettings);
  }

  static async deleteCompanyWallpaper(): Promise<void> {
    await axios.post('/apiv2/companies/avatar/delete-wallpaper');
  }

  static async changeActiveStatus(companyId: number): Promise<void> {
    await axios.patch(`/apiv2/companies/${companyId}/superadmin/is-active`);
  }

  static async changeChatSupportStatus(companyId: number): Promise<void> {
    await axios.patch(`/apiv2/companies/${companyId}/superadmin/has-support`);
  }

  static async hasChatSupport(): Promise<boolean> {
    return (await axios.get(`/apiv2/companies/has-support`)).data;
  }

  static async changeCompanyPrioritySupport(companyId: number): Promise<void> {
    await axios.patch(`/apiv2/companies/${companyId}/superadmin/priority-support`);
  }

  static async priorityChatSupport(): Promise<boolean> {
    return (await axios.get(`/apiv2/companies/priority-support`)).data;
  }

  static async getAllCompanyPlans(param?: {
    page?: string;
    pageSize?: string;
    searchString?: string;
    plans?: string;
  }): Promise<CompanyPlanInfoResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/companies/plans?${searchParams.toString()}`)).data;
  }

  static async getAllCompanySubscriptions(param?: {
    page?: string;
    pageSize?: string;
    searchString?: string;
    plans?: string;
  }): Promise<CompanySubscriptionResult> {
    const searchParams = new URLSearchParams(param);
    return (await axios.get(`/apiv2/companies/subscription/all?${searchParams.toString()}`)).data;
  }

  static async updateCompanySubscription(
    companyId: number,
    subscriptionId: number,
    updatedSubscription: CompanySubscription
  ): Promise<void> {
    await axios.patch(`/apiv2/companies/subscription/${companyId}/${subscriptionId}`, updatedSubscription);
  }
}

export class CompanyEndpoints {
  static getGeneralSettings(): Endpoint<GeneralSettingsDto> {
    return {
      url: '/apiv2/companies/general-settings',
    };
  }

  static getAllCompaniesForSelectionList(): Endpoint<CompanyBasic[]> {
    return {
      url: '/apiv2/companies/superadmin/selection-list',
    };
  }

  static getDowngradeViability(): Endpoint<Record<string, DowngradeViability>> {
    return {
      url: '/apiv2/companies/downgrade-viability',
    };
  }

  static getCompanyAuditTrail(): Endpoint<AuditTrailDto[]> {
    return {
      url: '/apiv2/companies/audit-trail',
    };
  }

  static getAuditTrailsForDevice(deviceId: number): Endpoint<AuditTrailDto[]> {
    return {
      url: '/apiv2/companies/audit-trail',
    };
  }

  static getCompanyConfig(): Endpoint<CompanyConfig> {
    return {
      url: '/apiv2/companies/config',
    };
  }

  static getCompanyEntities(): Endpoint<CompanyUnitDto[]> {
    return {
      url: '/apiv2/companies/entities',
    };
  }

  static getCompanyTranslations(): Endpoint<CompanyTranslationDto[]> {
    return {
      url: '/apiv2/companies/translations',
    };
  }

  static getAvailablePlanDefinitions(): Endpoint<PlanDefinition[]> {
    return {
      url: '/apiv2/plan-definition',
    };
  }

  static getAvailableModulesForUpgrade(): Endpoint<PlanDefinition[]> {
    return {
      url: '/apiv2/companies/subscription/modules-for-upgrade',
    };
  }
}
