import React, { useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ApprovalRuleDrawer } from '@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import {
  ContentItem,
  ContentItemChip,
  ContentItemType,
  ItemCard,
} from '@v2/feature/approval-rule/approval-rule-settings/components/item-card.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { getStepDescription, isApprovalRuleAutoApprove } from '@v2/feature/approval-rule/approval-rule.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateDefaultApprovalRule } from '@v2/infrastructure/i18n/translate.util';

interface ComponentProps {
  readonly approvalRules: ApprovalRuleDto[];
  readonly refresh: () => Promise<void>;
}

export const ApprovalRulesList = ({ approvalRules, refresh }: ComponentProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedApprovalRule, setSelectedApprovalRule] = useState<ApprovalRuleDto | null>(null);
  const [searchInput, setSearchInput] = useState('');

  const filteredApprovalRules = useMemo(() => {
    if (!searchInput) return approvalRules;
    const search = searchInput.trim().toLowerCase();
    return approvalRules.filter((approvalRule) => {
      return approvalRule.name?.toLowerCase().includes(search);
    });
  }, [approvalRules, searchInput]);

  const getChipsFromApprovalRule = useCallback(
    (approvalRule: ApprovalRuleDto): ContentItem[] => {
      const chips: ContentItem[] = [];
      if (approvalRule.approvalSteps.length === 0 || isApprovalRuleAutoApprove(approvalRule)) return chips;

      chips.push(
        {
          type: ContentItemType.chip,
          name: polyglot.t('ApprovalRuleModule.noOfSteps', { smart_count: approvalRule.approvalSteps.length }),
          textColor: 'Grey',
          backgroundColor: 'white',
          borderColor: 'Background',
        },
        { type: ContentItemType.divider, textColor: 'Grey' }
      );

      const labels = approvalRule.approvalSteps.map((approvalStep) =>
        getStepDescription(approvalStep, getCachedUserById, polyglot, 4)
      );

      chips.push(
        ...labels.map(
          (label) =>
            ({
              type: ContentItemType.chip,
              name: label,
              textColor: 'Grey',
              backgroundColor: 'white',
              borderColor: 'Background',
            } as ContentItemChip)
        )
      );

      return chips;
    },
    [polyglot, getCachedUserById]
  );

  return (
    <Box>
      <TableSearch
        query={searchInput}
        handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')}
        style={{ marginBottom: '16px' }}
      />
      <Stack gap="16px">
        {filteredApprovalRules.map((aRule) => (
          <ItemCard
            key={aRule.id}
            title={aRule.name}
            description={aRule.isDefault ? translateDefaultApprovalRule(aRule.name, polyglot) : aRule.description}
            onClick={() => {
              setSelectedApprovalRule(aRule);
              setIsDrawerOpen(true);
            }}
            contentItemsSets={[getChipsFromApprovalRule(aRule)]}
          />
        ))}
      </Stack>

      <ApprovalRuleDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        approvalRule={selectedApprovalRule}
        afterClose={() => {
          setSelectedApprovalRule(null);
          setIsDrawerOpen(false);
        }}
        refresh={refresh}
      />
    </Box>
  );
};
