import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { SETTINGS_BILLING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmUpgradeToPro = () => {
  const routerHistory = useHistory();

  return (
    <Box>
      <Typography sx={themeFonts.title2}>Upgrade to Pro plan</Typography>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.m10 }}>
        Upgrade to Devices Pro to save on renting devices for your team, protect them and manage security remotely and
        much more.
      </Typography>
      <ButtonComponent
        sizeVariant="medium"
        colorVariant="primary"
        style={{ marginTop: '40px' }}
        fullWidth
        onClick={() => {
          routerHistory.push(SETTINGS_BILLING_ROUTE);
        }}
      >
        Go to billing
      </ButtonComponent>
    </Box>
  );
};
