import { useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import type { WorkContactValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  WorkContactForm,
  WorkContactSchema,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-work-contact-form.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';

const SCOPE = 'user.workContact' as const;

const skeletonHeight = calculateSkeletonHeight(WorkContactSchema);
const iconSize = { width: 14, height: 14 } as const;

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: WorkContactValues) => void;
}

export const WorkContactCard = ({ userId, onSubmit }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [dataO, setDataO] = useState<O.Option<WorkContactValues>>(() => O.none);
  const [isEmpty, setEmpty] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const data = await UserAPI.getUserWorkContactInfo(userId);
        const emptyArray = (Object.keys(data) as Array<keyof typeof data>).filter(
          (key) => data[key] === undefined || data[key] === null || data[key] === ''
        );
        setEmpty(emptyArray.length === Object.keys(data).length);
        setDataO(O.some(data));
      } catch (error) {
        showMessage(polyglot.t('WorkContactCard.errorMessages.load'), 'error');
      }
    })();
  }, [polyglot, showMessage, userId, isOpen]);

  return pipe(
    dataO,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      (data) => (
        <Box>
          <ProfileCard
            customUpdates={data.customUpdates}
            fieldStubs={['user.workMobile', 'user.workPhone']}
            sx={cardSx}
          >
            <Box component="header" sx={cardHeaderSx}>
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                {polyglot.t('WorkContactCard.workContact')}
              </Typography>
              <ScopesControl scopes={[SCOPE]} context={scopesContext}>
                <IconButton onClick={() => setIsOpen(true)} title="Edit" sx={tableIconButtonSx}>
                  {isEmpty ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
                </IconButton>
              </ScopesControl>
            </Box>
            <Box component="section">
              {isEmpty ? (
                <MissingInformationLabel />
              ) : (
                <Box component="dl" sx={definitionListSx}>
                  <FieldStructure
                    fieldTitle={polyglot.t('WorkContactCard.mobile')}
                    fieldValue={data.workMobile}
                    fieldStub="user.workMobile"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('WorkContactCard.phone')}
                    fieldValue={data.workPhone}
                    fieldStub="user.workPhone"
                  />
                  {sortCustomFields(data.customUpdates).map(
                    (f) =>
                      !f.field.isHidden && (
                        <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                      )
                  )}
                </Box>
              )}
            </Box>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
                <WorkContactForm
                  userId={userId}
                  initialValues={data}
                  onSubmit={(values) => {
                    setDataO(O.some(values));
                    setIsOpen(false);
                    onSubmit(values);
                  }}
                  onClose={() => setIsOpen(false)}
                />
              </DrawerModal>
            </ScopesControl>
          </ProfileCard>
        </Box>
      )
    )
  );
};
