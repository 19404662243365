import React from 'react';

import { Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import { CustomProfileFormDto, UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { CustomSectionForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component';
import { spacing } from '@/v2/styles/spacing.styles';

type UserCustomProps = {
  onNext: (values: UserCustomDataDto[]) => void;
  sx?: SxProps<Theme>;
  userId: number;
  fields: UserCustomDataDto[];
  form: CustomProfileFormDto;
};
export const UserCustomSection = ({ onNext, sx, userId, fields, form }: UserCustomProps) => {
  return (
    <Stack sx={{ gap: spacing.g30, ...sx }}>
      <CustomSectionForm
        userId={userId}
        form={form}
        fields={[...fields]}
        onSubmit={(values) => {
          onNext(values);
        }}
        usedForOnboarding
      />
    </Stack>
  );
};
