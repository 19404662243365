import React, { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { AccountingTransactionDto } from '@v2/feature/payments/features/accounting-transaction/accounting-transaction.dto';
import { AccountType } from '@v2/feature/payments/features/accounting-transaction/accounting-transaction.interface';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

const getZeltRevenue = (records: AccountingTransactionDto[]): number => {
  return (
    records
      // ZELT REVENUE ZELT
      .filter(
        (record) =>
          !record.companyId &&
          !record.userId &&
          !record.subaccountCompanyId &&
          !record.subaccountUserId &&
          record.accountType === AccountType.Revenue
      )
      .reduce((sum, record) => sum + (record.credit ?? 0), 0)
  );
};

const getZeltCash = (records: AccountingTransactionDto[]): number => {
  return (
    records
      // ZELT CASH ZELT
      .filter(
        (record) =>
          !record.companyId &&
          !record.userId &&
          !record.subaccountCompanyId &&
          !record.subaccountUserId &&
          record.accountType === AccountType.Cash
      )
      .reduce((sum, record) => sum + (record.debit ?? 0), 0)
  );
};

const getCompanyCash = (records: AccountingTransactionDto[], companyId: number): number => {
  return (
    records
      // ZELT CASH COMPANY
      .filter(
        (record) =>
          !record.companyId &&
          !record.userId &&
          record.subaccountCompanyId === companyId &&
          !record.subaccountUserId &&
          record.accountType === AccountType.Cash
      )
      .reduce((sum, record) => sum + (record.credit ?? 0), 0)
  );
};

const getCompanyDebit = (records: AccountingTransactionDto[], companyId: number): number => {
  return (
    records
      // ZELT DEPOSIT COMPANY
      .filter(
        (record) =>
          !record.companyId &&
          !record.userId &&
          record.subaccountCompanyId === companyId &&
          !record.subaccountUserId &&
          record.accountType === AccountType.Deposit
      )
      .reduce((sum, record) => sum + (record.debit ?? 0), 0)
  );
};

const getUserCash = (records: AccountingTransactionDto[], companyId: number, userId: number): number => {
  return (
    records
      // ZELT CASH USER
      .filter(
        (record) =>
          !record.companyId &&
          !record.userId &&
          record.subaccountCompanyId === companyId &&
          record.subaccountUserId === userId &&
          record.accountType === AccountType.Cash
      )
      .reduce((sum, record) => sum + (record.debit ?? 0), 0)
  );
};

const getUserDeposit = (records: AccountingTransactionDto[], companyId: number, userId: number): number => {
  return (
    records
      // ZELT DEPOSIT USER
      .filter(
        (record) =>
          !record.companyId &&
          !record.userId &&
          record.subaccountCompanyId === companyId &&
          record.subaccountUserId === userId &&
          record.accountType === AccountType.Deposit
      )
      .reduce((sum, record) => sum + (record.credit ?? 0), 0)
  );
};

interface AccountingTransactionsOverviewProps {
  readonly title: string;
  readonly label1: string;
  readonly value1: ReactNode;
  readonly label2: string;
  readonly value2: ReactNode;
}

const AccountingTransactionsOverview = ({
  title,
  label1,
  value1,
  label2,
  value2,
}: AccountingTransactionsOverviewProps) => {
  return (
    <Box>
      <Typography sx={{ ...themeFonts.caption, mb: spacing.mb10 }}>{title}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            width: '150px',
            display: 'flex',
            gap: spacing.gap10,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={themeFonts.title4}>{label1}</Typography>
          <Typography sx={themeFonts.title2}>{value1}</Typography>
        </Box>
        <Box
          sx={{
            width: '150px',
            display: 'flex',
            gap: spacing.gap10,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={themeFonts.title4}>{label2}</Typography>
          <Typography sx={themeFonts.title2}>{value2}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface AccountingTransactionSummaryProps {
  readonly accountingTransactions: AccountingTransactionDto[];
  readonly companyId: number;
  readonly companyName: string;
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const AccountingTransactionSummary = ({
  accountingTransactions,
  companyId,
  companyName,
  users,
}: AccountingTransactionSummaryProps) => {
  const userIdsIncludedInTransactions = [
    ...new Set(
      accountingTransactions
        .filter(
          (record) =>
            !record.companyId &&
            !record.userId &&
            record.subaccountCompanyId === companyId &&
            !!record.subaccountUserId &&
            [AccountType.Deposit, AccountType.Cash].includes(record.accountType)
        )
        .map((record) => record.subaccountUserId)
    ),
  ];

  return (
    <Box sx={{ ...spacing.px40, display: 'flex', gap: spacing.gap60, mb: spacing.mb30 }}>
      <AccountingTransactionsOverview
        key="zelt"
        title="Zelt"
        label1="Revenue"
        value1={`£${getZeltRevenue(accountingTransactions)}`}
        label2="Cash"
        value2={`£${getZeltCash(accountingTransactions)}`}
      />

      <AccountingTransactionsOverview
        key="company"
        title={companyName}
        label1="Deposit"
        value1={`£${getCompanyCash(accountingTransactions, companyId)}`}
        label2="Cash"
        value2={`£${getCompanyDebit(accountingTransactions, companyId)}`}
      />

      {userIdsIncludedInTransactions.map((userId) => {
        const user = users.find((user) => user.userId === userId);
        return (
          <AccountingTransactionsOverview
            key={`user-${userId}`}
            title={user ? `${user.firstName} ${user.lastName}` : `User ${userId}`}
            label1="Deposit"
            value1={`£${getUserDeposit(accountingTransactions, companyId, userId!)}`}
            label2="Cash"
            value2={`£${getUserCash(accountingTransactions, companyId, userId!)}`}
          />
        );
      })}
    </Box>
  );
};
