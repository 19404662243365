import { Box, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { EntityImportResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import { ENTITY_IMPORT_WIZARD_ROUTE_OLD } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.router';

interface Props {
  readonly goNext: () => void;
  readonly result?: EntityImportResultDto;
}

export function EntityImportSuccessPage({ goNext, result }: Props): JSX.Element {
  const routerHistory = useHistory();

  const getSuccessText = (count: number, action: string) =>
    count === 1 ? `1 record has been ${action} successfully!` : `${count} records have been ${action} successfully!`;

  if (!result) {
    // TODO this is not a good pattern, rewrite to use effects
    routerHistory.push(ENTITY_IMPORT_WIZARD_ROUTE_OLD);
    return <></>;
  }

  return (
    <Box textAlign="center" paddingY={6}>
      <Typography variant="subtitle2">Complete 👌</Typography>
      <Box paddingY={1}>
        {result.updatedCount > 0 && (
          <Typography variant="body2">{getSuccessText(result.updatedCount, 'updated')}</Typography>
        )}
        {result.importedCount > 0 && (
          <Typography variant="body2">{getSuccessText(result.importedCount, 'imported')}</Typography>
        )}
      </Box>
      <Button onClick={() => goNext()} sx={{ marginTop: 3 }} variant="contained">
        Close
      </Button>
    </Box>
  );
}
