import { useMemo } from 'react';

import { Box } from '@mui/material';
import { DEFAULT_SCHEDULE_CAP, getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { AttendanceDto, AttendanceScheduleDto, AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceShiftAPI } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api';
import { AttendanceShiftDto } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.dto';
import { SiteDto } from '@v2/feature/site/site.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import { ShowMessage } from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { convertMinutesToClockHours } from '@/v2/feature/absence/absence.util';
import {
  DayNoToWeekDay,
  ScheduleTimeEntry,
  WeekDay,
  weekDayToDayNo,
} from '@/v2/feature/attendance/attendance.interface';
import {
  Coords,
  WidgetInfoMessageProps,
} from '@/v2/feature/dashboard/features/sections/user-shift/user-shift.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { LocalDate } from '@/v2/util/local-date';
import { compareTimePartOnly } from '@/v2/util/time.util';

export const DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY = 100;

export const DISTANCE_CANNOT_BE_CALCULATED = 999_999;
export const MISSING_SITE_ADDRESS = 999_998;

export const WeekdaysArray: WeekDay[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const nextScheduledDayDetails = (
  theFollowingScheduledDay: {
    day: WeekDay;
    daySchedule: ScheduleTimeEntry;
  },
  polyglot: Polyglot
): string[] => {
  const regularString = (): string[] => {
    if (!theFollowingScheduledDay?.daySchedule) return [];

    const start = theFollowingScheduledDay?.daySchedule.from.split('T')[1].slice(0, 5);
    const end = theFollowingScheduledDay?.daySchedule.to.split('T')[1].slice(0, 5);

    return [start, end];
  };

  const breakString = (): string => {
    if (
      !theFollowingScheduledDay?.daySchedule ||
      !theFollowingScheduledDay?.daySchedule ||
      !theFollowingScheduledDay?.daySchedule.break
    )
      return '';

    const [h, m] = theFollowingScheduledDay!.daySchedule.break.split('T')[1].split(':');
    const minutes = 60 * Number(h) + Number(m);

    return convertMinutesToClockHours(minutes, polyglot);
  };

  return [regularString()[0], regularString()[1], breakString()];
};

export const WidgetInfoMessage = ({
  isScheduledForToday,
  scheduleDay,
  isStarted,
  hasStartedBreak,
  time,
  isEnded,
  clockInEarlyCapMinutes,
}: WidgetInfoMessageProps) => {
  const { polyglot } = usePolyglot();

  const isRunningLate = useMemo(() => {
    if (!isScheduledForToday || !scheduleDay || !scheduleDay.daySchedule || isStarted) return false;

    const now = new LocalDate().toFullString().split('T')[1];

    const scheduledHour = scheduleDay.daySchedule!.from.split('T')[1];
    return scheduledHour < now;
  }, [isScheduledForToday, scheduleDay, isStarted]);

  const startsSoon = useMemo(() => {
    if (!isScheduledForToday || !scheduleDay || !scheduleDay.daySchedule || isRunningLate || isStarted) return false;

    const now = new LocalDate().toFullString().split('T')[1];
    const scheduledHour = scheduleDay.daySchedule?.from.split('T')[1];

    const [nH, nM] = now.split(':');
    const nowMinutes = Math.round(Number(nH) * 60 + Number(nM));

    const [sH, sM] = scheduledHour.split(':');
    const scheduledMinutes = Math.round(Number(sH) * 60 + Number(sM));

    const timeUntilStart = scheduledMinutes - nowMinutes;

    return timeUntilStart >= 0 && timeUntilStart <= clockInEarlyCapMinutes;
  }, [isScheduledForToday, scheduleDay, isRunningLate, isStarted, clockInEarlyCapMinutes]);

  const startsLater = useMemo(() => {
    if (isScheduledForToday && scheduleDay?.daySchedule && !isRunningLate && !isStarted && !startsSoon)
      return scheduleDay.daySchedule!.from.split('T')[1].slice(0, 5);

    return null;
  }, [isScheduledForToday, scheduleDay, isRunningLate, isStarted, startsSoon]);

  if (isEnded)
    return (
      <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('AttendanceDomain.AttendanceShift.ShiftFinished')}
      </Typography>
    );

  if (isStarted && hasStartedBreak)
    return (
      <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('AttendanceDomain.AttendanceShift.BreakStarted', { time })}
      </Typography>
    );

  if (isStarted)
    return (
      <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('AttendanceDomain.AttendanceShift.ShiftStarted', { time })}
      </Typography>
    );

  if (isRunningLate)
    return (
      <Typography variant="title4" sx={{ color: themeColors.RedDark }}>
        {polyglot.t('AttendanceDomain.AttendanceShift.RunningLate')}
      </Typography>
    );

  if (startsSoon)
    return (
      <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('AttendanceDomain.AttendanceShift.StartsSoon')}
      </Typography>
    );

  if (startsLater)
    return (
      <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('AttendanceDomain.AttendanceShift.StartsLater', { startsLater })}
      </Typography>
    );

  return <Box></Box>;
};

export const getDistanceBetweenCoordinates = (startPoint: Coords, endPoint: Coords, inMiles = false): number => {
  const lon1 = (startPoint.longitude * Math.PI) / 180;
  const lon2 = (endPoint.longitude * Math.PI) / 180;
  const lat1 = (startPoint.latitude * Math.PI) / 180;
  const lat2 = (endPoint.latitude * Math.PI) / 180;

  // Radius of earth in (6371 kilometers) | (3956 miles)
  const earthRadius = inMiles ? 3956 : 6371;

  // Haversine formula
  const longitudeDiff = lon2 - lon1;
  const latitudeDiff = lat2 - lat1;
  const a =
    Math.pow(Math.sin(latitudeDiff / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(longitudeDiff / 2), 2);

  const c = 2 * Math.asin(Math.sqrt(a));

  return c * earthRadius;
};

export const isInAcceptedProximityUtil = (
  attendanceSchedule: AttendanceScheduleDto | undefined | null,
  distance: number | null
): boolean | null => {
  if (!attendanceSchedule) return false;
  if (!attendanceSchedule?.restrictByGeolocation) return true;
  if (!attendanceSchedule.geolocationDistance) return false;

  if (distance === null) return null;
  return distance < (attendanceSchedule.geolocationDistance ?? DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY);
};

export const getDateDayUtil = (date?: Date | string | null): WeekDay => {
  const d = new LocalDate(date).getDate();
  return WeekdaysArray[d.getDay()];
};

export const getTheSelectedDaySchedule = (
  attendanceSchedule: AttendanceScheduleDto,
  selectedDay: WeekDay,
  selectedDate: string
): {
  day: WeekDay;
  daySchedule: ScheduleTimeEntry;
} | null => {
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    selectedDate,
    attendanceSchedule.noOfWeeks
  );

  if (weekIndex == null) return null;

  if (attendanceSchedule && attendanceSchedule[selectedDay] && attendanceSchedule[selectedDay][weekIndex])
    return { day: selectedDay, daySchedule: attendanceSchedule[selectedDay][weekIndex]! } as {
      day: WeekDay;
      daySchedule: ScheduleTimeEntry;
    };

  const dayIndex = WeekdaysArray.findIndex((day) => day === selectedDay);
  let nextIndex = dayIndex === 6 ? 0 : dayIndex + 1;
  while (nextIndex !== dayIndex) {
    const nextDay = WeekdaysArray[nextIndex];
    if (attendanceSchedule && attendanceSchedule[nextDay] && attendanceSchedule[nextDay][weekIndex]!)
      return { day: nextDay, daySchedule: attendanceSchedule && attendanceSchedule[nextDay][weekIndex]! } as {
        day: WeekDay;
        daySchedule: ScheduleTimeEntry;
      };

    nextIndex = (nextIndex + 1) % 7;
  }

  return null;
};

export const isLessThanEarlyCapMinutesUntilStart = (
  attendanceSchedule: AttendanceScheduleDto | undefined | null,
  isScheduledForToday: boolean,
  isStarted: boolean,
  relevantScheduledDay: {
    day: WeekDay;
    daySchedule: ScheduleTimeEntry;
  } | null
) => {
  const now = new LocalDate().toFullString();
  const nowDay = now.split('T')[0].split('-')[2];

  if (!isScheduledForToday || !relevantScheduledDay || !relevantScheduledDay.daySchedule || isStarted) return false;

  const scheduled = relevantScheduledDay.daySchedule!.from;
  const scheduledDay = scheduled.split('T')[0].split('-')[2];

  if (Number(scheduledDay) < Number(nowDay)) return true;

  const nowTime = now.split('T')[1];
  const [hN, mN] = nowTime.split(':');
  const nowMinutes = Number(hN) * 60 + Number(mN);

  const scheduledTime = scheduled.split('T')[1];
  const [hS, mS] = scheduledTime.split(':');
  const scheduleMinutes = Number(hS) * 60 + Number(mS);

  return scheduleMinutes - nowMinutes <= (attendanceSchedule?.clockInEarlyCapMinutes ?? DEFAULT_SCHEDULE_CAP);
};

export const getHasStartedBreak = (
  shift: AttendanceShiftDto | undefined | null,
  breakType: AttendanceTypeDto | undefined | null
): boolean => {
  if (!shift?.shiftEntries) return false;

  const lastShiftEntry = shift.shiftEntries[shift.shiftEntries.length - 1];

  return Boolean(breakType && lastShiftEntry.attendanceTypeId === breakType.id && !lastShiftEntry.endHour);
};

export const extractTimeFromShiftEntries = (shift: AttendanceShiftDto | undefined | null): string => {
  if (!shift?.shiftEntries) return '';

  const lastShiftEntry = shift.shiftEntries[shift.shiftEntries.length - 1];
  return lastShiftEntry.startHour.split('T')[1].slice(0, 5);
};

export const startAttendanceShift = async (
  isInAcceptedProximity: boolean | null,
  distance: number | null,
  longitude: number | null,
  latitude: number | null,
  showMessage: ShowMessage,
  refresh: () => Promise<void>,
  polyglot: Polyglot,
  isYesterday: boolean
) => {
  if (!isInAcceptedProximity || distance === DISTANCE_CANNOT_BE_CALCULATED || distance === MISSING_SITE_ADDRESS) {
    showMessage(
      isInAcceptedProximity === null
        ? polyglot.t('AttendanceDomain.AttendanceShift.Actions.LocationIsRequired')
        : distance === DISTANCE_CANNOT_BE_CALCULATED
        ? polyglot.t('AttendanceDomain.AttendanceShift.Actions.DistanceCouldNotBeCalculated')
        : distance === MISSING_SITE_ADDRESS
        ? polyglot.t('AttendanceDomain.AttendanceShift.Actions.MissingSiteAddress')
        : polyglot.t('AttendanceDomain.AttendanceShift.Actions.NotWithingProximity'),
      'error'
    );
    return;
  }

  try {
    await AttendanceShiftAPI.startTodaysShift(longitude, latitude, isYesterday);
    showMessage(polyglot.t('AttendanceDomain.AttendanceShift.Actions.ShiftStarted'), 'success');
    await refresh();
  } catch (error) {
    showMessage(
      polyglot.t('AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong', {
        errorMessage: nestErrorMessage(error),
      }),
      'error'
    );
  }
};

export const endAttendanceShift = async (
  longitude: number | null,
  latitude: number | null,
  showMessage: ShowMessage,
  refresh: Function | undefined,
  polyglot: Polyglot,
  isYesterday: boolean
) => {
  try {
    await AttendanceShiftAPI.endTodaysShift(longitude, latitude, isYesterday);
    showMessage(polyglot.t('AttendanceDomain.AttendanceShift.Actions.ShiftEnded'), 'success');
    if (refresh) await refresh();
  } catch (error) {
    showMessage(
      polyglot.t('AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong', {
        errorMessage: nestErrorMessage(error),
      }),
      'error'
    );
  }
};

export const startAttendanceBreak = async (
  longitude: number | null,
  latitude: number | null,
  showMessage: ShowMessage,
  refresh: Function,
  polyglot: Polyglot
): Promise<void> => {
  try {
    await AttendanceShiftAPI.startBreak(longitude, latitude);
    showMessage(polyglot.t('AttendanceDomain.AttendanceShift.Actions.BreakStarted'), 'success');
    await refresh();
  } catch (error) {
    showMessage(
      polyglot.t('AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong', {
        errorMessage: nestErrorMessage(error),
      }),
      'error'
    );
  }
};

export const endAttendanceBreak = async (
  longitude: number | null,
  latitude: number | null,
  showMessage: ShowMessage,
  refresh: Function | undefined,
  polyglot: Polyglot
) => {
  try {
    await AttendanceShiftAPI.endBreak(longitude, latitude);
    showMessage(polyglot.t('AttendanceDomain.AttendanceShift.Actions.BreakEnded'), 'success');
    if (refresh) await refresh();
  } catch (error) {
    showMessage(
      polyglot.t('AttendanceDomain.AttendanceShift.Actions.SomethingWentWrong', {
        errorMessage: nestErrorMessage(error),
      }),
      'error'
    );
  }
};

export const getShiftLoggedHours = (
  scheduledDay: {
    day: WeekDay;
    daySchedule: ScheduleTimeEntry;
  } | null,
  polyglot: Polyglot
): string[] => {
  if (!scheduledDay) return [];
  return nextScheduledDayDetails(scheduledDay, polyglot);
};

export const getDistance = (
  attendanceSchedule: AttendanceScheduleDto | undefined | null,
  currentPosition: {
    coords: { longitude: number; latitude: number };
  } | null,
  userSite: SiteDto | undefined | null,
  sites: SiteDto[] | undefined | null
) => {
  if (!currentPosition?.coords) return null;

  // If not allowed to clock in form user site, set a default value > 100
  const distanceInKM =
    attendanceSchedule?.geolocationEmployeeSite && userSite?.coordinates
      ? getDistanceBetweenCoordinates(currentPosition.coords, userSite.coordinates)
      : userSite?.coordinates?.longitude && userSite.coordinates?.latitude
      ? DISTANCE_CANNOT_BE_CALCULATED
      : MISSING_SITE_ADDRESS;
  const userSiteDistance = Math.round(distanceInKM * 1000);

  const otherSitesDistances = attendanceSchedule?.allowedWorkSites
    ? attendanceSchedule.allowedWorkSites.map((siteId) => {
        const site = sites?.find((s) => s.id === siteId);

        if (!site?.coordinates?.latitude || !site?.coordinates?.longitude) return MISSING_SITE_ADDRESS;

        const distanceFromSite = getDistanceBetweenCoordinates(currentPosition.coords, site.coordinates);

        return Math.round(distanceFromSite * 1000);
      })
    : [];

  return Math.min(userSiteDistance, ...otherSitesDistances);
};

export const getCurrentPosition = (
  navigator: Navigator,
  setIsFetchingLocation: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentPosition: React.Dispatch<
    React.SetStateAction<{
      coords: { longitude: number; latitude: number };
    } | null>
  >
) => {
  navigator.geolocation.getCurrentPosition((position) => {
    if (position) {
      setIsFetchingLocation(false);
      setCurrentPosition({
        coords: {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        },
      });
    } else {
      setCurrentPosition(null);
    }
  });
};

/**
 * Returns an unfinished shift if it's there. Also covers the case of shifts that go past midnight.
 * @param attendanceSchedule
 * @param todaysDay
 * @param currentWeekAttendance
 * @param shift
 */
export const getRelevantShiftDayFromSchedule = (
  attendanceSchedule: AttendanceScheduleDto,
  todaysDay: WeekDay,
  currentWeekAttendance: AttendanceDto[] | undefined,
  shift: AttendanceShiftDto | null | undefined
): {
  weekDay: WeekDay;
  weekDate: string;
} => {
  if (shift && !shift.isEnded)
    return {
      weekDay: DayNoToWeekDay[new Date(shift.logDate).getDay()],
      weekDate: shift.logDate,
    };

  const todayWeekNoIndex = Object.entries(DayNoToWeekDay).findIndex(([, weekday]) => weekday === todaysDay);

  if (todayWeekNoIndex === -1) return { weekDay: todaysDay, weekDate: new LocalDate().toDateString() };

  const yesterdayToDayNoIndex = (todayWeekNoIndex - 1) % 7;
  const yesterdaysDay = DayNoToWeekDay[yesterdayToDayNoIndex];
  const yesterdayShiftLogDate = new LocalDate().getPreviousDateString();

  const yesterdaysWeekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    yesterdayShiftLogDate,
    attendanceSchedule.noOfWeeks
  );
  const yesterdayShift = attendanceSchedule[yesterdaysDay]
    ? attendanceSchedule[yesterdaysDay][yesterdaysWeekIndex]
    : null;

  if (
    // if yesterday's shift and shift is overnight
    yesterdayShift &&
    new Date(yesterdayShift.to).getTime() < new Date(yesterdayShift.from).getTime() &&
    compareTimePartOnly(new Date(yesterdayShift.to), new Date()) === 1
  ) {
    if (currentWeekAttendance && !currentWeekAttendance.find((cwa) => cwa.logDate === yesterdayShiftLogDate))
      return { weekDay: yesterdaysDay, weekDate: yesterdayShiftLogDate };
  }

  return { weekDay: todaysDay, weekDate: new LocalDate().toDateString() };
};

export const getClosestDateForDay = (weekDay: WeekDay): Date => {
  const date = new Date();
  const currentDayNo = date.getDay();

  if (DayNoToWeekDay[currentDayNo] === weekDay) return date;

  const weekDayNo = weekDayToDayNo(weekDay);

  date.setDate(date.getDate() - currentDayNo + weekDayNo);

  return date;
};

/**
 * Given a date string, returns the previous days date.
 * @param dateStr the date to decrement
 * @returns previous days date e.g. 2012-10-01 -> 2012-09-30
 */
export const getDayPreviousFromDateString = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() - 1);

  const monthPart = date.getMonth() + 1;
  const datePart = date.getDate();

  return `${date.getFullYear()}-${monthPart < 10 ? '0' : ''}${monthPart}-${datePart < 10 ? '0' : ''}${datePart}`;
};
