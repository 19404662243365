import { useState } from 'react';

import { EditInsuranceGeneralDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/components/edit-insurance-general-drawer.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface PageProps {
  readonly insuranceQuote: InsuranceQuoteDto;
  readonly refreshInsuranceQuote: () => Promise<void>;
}

export const InsuranceSettingsGeneralPage = ({ insuranceQuote, refreshInsuranceQuote }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('General.general')}
      onEdit={
        insuranceQuote.policy
          ? () => {
              setIsEditOpen(true);
            }
          : undefined
      }
    >
      {(insuranceQuote.policy?.displayName || insuranceQuote.displayName) && (
        <DescriptionLine
          field={polyglot.t('General.displayName')}
          value={insuranceQuote.policy?.displayName ?? insuranceQuote.displayName}
        />
      )}
      {!insuranceQuote.policy && <DescriptionLine field={polyglot.t('General.status')} value={insuranceQuote.status} />}

      {insuranceQuote.policy && (
        <DescriptionLine field={polyglot.t('BenefitModule.provider')} value={insuranceQuote.policy.providerName} />
      )}
      {insuranceQuote.policy && (
        <DescriptionLine field={polyglot.t('BenefitModule.policyNumber')} value={insuranceQuote.policy.policyNumber} />
      )}
      {insuranceQuote.policy && (
        <DescriptionLine field={polyglot.t('BenefitModule.policyStart')} value={insuranceQuote.policy.startDate} />
      )}
      {insuranceQuote.policy && (
        <DescriptionLine field={polyglot.t('BenefitModule.policyEnd')} value={insuranceQuote.policy.endDate} />
      )}
      {insuranceQuote.policy && (
        <DescriptionLine field={polyglot.t('BenefitModule.claims')} value={insuranceQuote.policy.claimsPhone} />
      )}
      {insuranceQuote.policy && (
        <DescriptionLine field={polyglot.t('BenefitModule.support')} value={insuranceQuote.policy.supportPhone} />
      )}
      {insuranceQuote.policy && (
        <DescriptionLine
          field={polyglot.t('BenefitModule.insuranceBroker')}
          value={insuranceQuote.policy.insuranceBrokerPhone}
        />
      )}

      {isEditOpen && insuranceQuote.policy && (
        <EditInsuranceGeneralDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          insurancePolicy={insuranceQuote.policy}
          refresh={refreshInsuranceQuote}
        />
      )}
    </BenefitsSettingsPageSection>
  );
};
