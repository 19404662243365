import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary, keyBy } from 'lodash';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { ReviewCycleAPI, ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewTemplateEndpoints } from '@/v2/feature/growth/reviews/api-client/review-template.api';
import { PlaceholderBox } from '@/v2/feature/growth/reviews/features/components/placeholder-box.component';
import { QuestionBuilder } from '@/v2/feature/growth/reviews/features/components/question-builder.component';
import { SectionBuilder } from '@/v2/feature/growth/reviews/features/components/section-builder.component';
import { CycleCreationMoveToSectionModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-move-to-section-modal.component';
import { CycleCreationQuestionFormModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-form-modal.component';
import { CycleCreationQuestionPreviewer } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-previewer.component';
import { CycleCreationQuestionSelectModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-select-modal.component';
import { CycleCreationSectionFormModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-section-form-modal.component';
import { CycleCreationTemplateModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-template-modal.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { useCycleQuestionOptions } from '@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-cycle-question-options.hook';
import { useCycleSectionOptions } from '@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-cycle-section-options.hook';
import {
  ReachType,
  ReviewCycle,
  ReviewCycleById,
  ReviewerTypes,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewSection } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReviewCycleCreationQuestionPage = ({ cycleId, reach }: { cycleId: string; reach: ReachType }) => {
  const { data: bankCount } = useApiClient(ReviewTemplateEndpoints.getBankCount(), { suspense: false });

  const {
    data: reviewStructure,
    mutate: refreshReviewStructure,
    isLoading: reviewStructureLoading,
    isValidating: reviewStructureValidating,
  } = useApiClient(ReviewCycleEndpoints.getReviewCycleStructureById(cycleId), { suspense: false });

  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const growthFactors: Dictionary<GrowthFactor> = useMemo(() => {
    return keyBy(allGrowthFactors, 'name');
  }, [allGrowthFactors]);

  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState<boolean>(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState<boolean>(false);

  const [question, setQuestion] = useState<ReviewQuestion | null>(null);
  const [section, setSection] = useState<ReviewSection | null>(null);

  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState<boolean>(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState<boolean>(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState<boolean>(false);

  const [selectedQuestionId, setSelectedQuestionId] = useState<string | undefined>(undefined);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState<boolean>(false);
  const [previewReviewer, setPreviewReviewer] = useState<ReviewerTypes | undefined>(undefined);

  const [cycle, setCycle] = useState<ReviewCycleById['cycle'] | undefined>(undefined);
  const [sections, setSections] = useState<ReviewCycleById['sections'] | undefined>(undefined);
  const [questions, setQuestions] = useState<ReviewCycleById['questions'] | undefined>(undefined);
  const [showMessage] = useMessage();

  useEffect(() => {
    if (reviewStructure) {
      setCycle(reviewStructure.cycle);
      setSections(reviewStructure.sections);
      setQuestions(reviewStructure.questions);
    }
  }, [reviewStructure]);

  const getReviewerSelects = useMemo(() => {
    if (!reviewStructure?.questions) return [];

    const allReviewerSelects = reviewStructure?.questions?.flatMap((q) => q.reviewerSelect);

    return allReviewerSelects;
  }, [reviewStructure]);

  const isEditable = useMemo(
    () => Boolean(cycle?.state === CycleState.Draft || cycle?.state === CycleState.Scheduled),
    [cycle]
  );

  const renderQuestion = (questionId: string) => {
    const question = questions && questions.find((q) => q.id === questionId);
    if (!question || !cycle) return null;
    return (
      <QuestionComponent
        key={question.id}
        question={question}
        cycle={cycle}
        setQuestion={setQuestion}
        setIsQuestionModalOpen={setIsQuestionModalOpen}
        refreshReviewStructure={refreshReviewStructure}
        setSelectedQuestionId={setSelectedQuestionId}
        setIsMoveToSectionOpen={setIsMoveToSectionOpen}
        hasSections={Boolean(sections && sections.length)}
        growthFactors={growthFactors}
        isEditable={isEditable}
      />
    );
  };

  const renderSection = (sectionId: string) => {
    const section = sections?.find((s) => s.id === sectionId);
    if (!section || !cycle) return null;

    const orderSection = cycle?.order.find((o) => o.id === sectionId && o.type === 'section');
    if (!orderSection) return null;

    const orderedQuestions = (orderSection.questions ?? [])
      .map((questionId) => questions?.find((q) => q.id === questionId))
      .filter((question): question is ReviewQuestion => Boolean(question));

    const handleDragDrop = async (results: DropResult) => {
      const { source, destination, type } = results;
      if (!destination) return;
      if (!cycle) return;
      if (type !== 'group') return;

      const sectionIndex = cycle.order.findIndex((o) => o.type === 'section' && o.id === section.id);
      if (sectionIndex === -1) return;

      const reOrdered = { ...cycle.order[sectionIndex] };
      if (!reOrdered || !reOrdered.questions) return;

      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      if (sourceIndex === -1 || destinationIndex === -1) return;

      const [removed] = reOrdered.questions.splice(sourceIndex, 1);
      reOrdered.questions.splice(destinationIndex, 0, removed);

      const updatedOrder = [...cycle.order];
      updatedOrder[sectionIndex] = reOrdered;

      //  this optimistically updates the UI
      setCycle((prevCycle) => {
        if (!prevCycle) return prevCycle;

        return {
          ...prevCycle,
          order: updatedOrder,
        };
      });

      try {
        await ReviewCycleAPI.updateOrderReviewCycle({ cycleId, order: updatedOrder });
        await refreshReviewStructure?.();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
        // returns optimistically updates the UI to last state
        await refreshReviewStructure?.();
      }
    };

    return (
      <ReviewBuilderSection
        key={section.id}
        section={section}
        setSection={setSection}
        setIsQuestionModalOpen={setIsQuestionModalOpen}
        setIsSectionModalOpen={setIsSectionModalOpen}
        questions={orderedQuestions}
        handleDragDrop={handleDragDrop}
        cycle={cycle}
        setQuestion={setQuestion}
        refreshReviewStructure={refreshReviewStructure}
        setSelectedQuestionId={setSelectedQuestionId}
        setIsMoveToSectionOpen={setIsMoveToSectionOpen}
        sections={sections}
        growthFactors={growthFactors}
        isEditable={isEditable}
        reviewStructureValidating={reviewStructureValidating}
      />
    );
  };

  const cloneQuestions = async (selectedQuestionIds: Set<string>) => {
    try {
      await ReviewCycleAPI.cloneQuestionsInCycle([...selectedQuestionIds], cycleId);
      refreshReviewStructure?.();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const moveQuestionInsideSection = async (sectionId: string, questionId: string) => {
    try {
      await ReviewCycleAPI.moveQuestionInsideSection({ cycleId, sectionId, questionId });
      refreshReviewStructure?.();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const getReviewerActionsOptions = useCallback(() => {
    return [
      ...(getReviewerSelects.includes(ReviewerTypes.Self)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Self);
              },
              label: ReviewerTypes.Self,
              disabled: false,
            },
          ]
        : []),
      ...(getReviewerSelects.includes(ReviewerTypes.Manager)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Manager);
              },
              label: ReviewerTypes.Manager,
              disabled: false,
            },
          ]
        : []),
      ...(getReviewerSelects.includes(ReviewerTypes.Upward)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Upward);
              },
              label: ReviewerTypes.Upward,
              disabled: false,
            },
          ]
        : []),
      ...(getReviewerSelects.includes(ReviewerTypes.Peer)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Peer);
              },
              label: ReviewerTypes.Peer,
              disabled: false,
            },
          ]
        : []),
    ];
  }, [getReviewerSelects]);

  return (
    <SettingsSectionContent
      title={cycle ? <TitleStatusComponent reviewCycle={cycle} reachType={reach} /> : <SkeletonLoader variant="text" />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={reviewStructureLoading}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box
          className="sub-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="title3">Questions</Typography>
          {getReviewerSelects.length > 1 && (
            <StyledMenuComponent
              options={getReviewerActionsOptions()}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'Preview',
              }}
            />
          )}
        </Box>
        {isEditable && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16, mt: spacing.m24 }}>
            <PlaceholderBox
              title="Start with a template"
              countOne={`${bankCount?.template ?? 0} templates`}
              countTwo={`${bankCount?.templateQuestions ?? 0} questions`}
              action={() => setIsTemplateModalOpen(true)}
            />
            <PlaceholderBox
              title="Select questions from library"
              countOne={`${bankCount?.totalCategories ?? 0} factors`}
              countTwo={`${bankCount?.totalQuestions ?? 0} questions`}
              action={() => setIsQuestionBankOpen(true)}
            />
          </Box>
        )}
      </Box>

      {reviewStructureLoading ? (
        <CycleSetupQuestionBuilderLoader />
      ) : (
        <Box
          sx={{
            ...(cycle && cycle.order && cycle.order.length > 0
              ? {}
              : { border: `1px dashed ${themeColors.GreyLight}` }),
            mt: spacing.m24,
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {cycle &&
              cycle.order?.map((item) => {
                if (item.type === 'question') {
                  return renderQuestion(item.id);
                } else if (item.type === 'section') {
                  return renderSection(item.id);
                }
                return <></>;
              })}
          </Box>

          {isEditable && (
            <Box
              sx={{
                display: 'flex',
                gap: spacing.g5,
                mt: cycle && cycle.order ? spacing.m24 : '0px',
                ...(cycle && cycle.order && cycle.order.length > 0 ? {} : { padding: spacing.p16 }),
              }}
            >
              <ButtonComponent
                colorVariant="secondary"
                sizeVariant="medium"
                startIcon={<Plus {...iconSize} />}
                onClick={() => setIsSectionModalOpen(true)}
              >
                New section
              </ButtonComponent>
              <ButtonComponent
                colorVariant="secondary"
                sizeVariant="medium"
                startIcon={<Plus {...iconSize} />}
                onClick={() => setIsQuestionModalOpen(true)}
              >
                New question
              </ButtonComponent>
            </Box>
          )}
        </Box>
      )}

      <CycleCreationTemplateModal
        isTemplateModalOpen={isTemplateModalOpen}
        setIsTemplateModalOpen={setIsTemplateModalOpen}
        cycleId={cycleId}
        refresh={async () => {
          await refreshReviewStructure?.();
        }}
      />
      <CycleCreationQuestionSelectModal
        setIsQuestionsModalOpen={setIsQuestionBankOpen}
        isQuestionsModalOpen={isQuestionBankOpen}
        action={cloneQuestions}
      />

      <CycleCreationQuestionFormModal
        setIsOpen={setIsQuestionModalOpen}
        isOpen={isQuestionModalOpen}
        question={question as ReviewQuestion | null}
        onClose={() => {
          setIsQuestionModalOpen(false);
          setQuestion(null);
          setSection(null);
        }}
        refresh={async () => {
          await refreshReviewStructure?.();
        }}
        section={section as ReviewSection | null} // casting because it gets created in bank first
        cycleId={cycleId}
      />

      <CycleCreationSectionFormModal
        setIsOpen={setIsSectionModalOpen}
        isOpen={isSectionModalOpen}
        section={section}
        onClose={() => {
          setIsSectionModalOpen(false);
          setSection(null);
          setQuestion(null);
        }}
        refresh={async () => {
          await refreshReviewStructure?.();
        }}
        cycleId={cycleId}
      />

      <CycleCreationMoveToSectionModal
        isOpen={isMoveToSectionOpen}
        setIsOpen={setIsMoveToSectionOpen}
        onClose={() => {
          setIsMoveToSectionOpen(false);
        }}
        action={moveQuestionInsideSection}
        sections={sections}
        questionId={selectedQuestionId}
      />

      <CycleCreationQuestionPreviewer
        isPreviewerOpen={isPreviewerOpen}
        setIsPreviewerOpen={setIsPreviewerOpen}
        previewReviewer={previewReviewer}
        allQuestions={reviewStructure?.questions || []}
        allSections={reviewStructure?.sections || []}
        order={reviewStructure?.cycle.order || []}
      />
    </SettingsSectionContent>
  );
};

const ReviewBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  questions,
  handleDragDrop,
  cycle,
  setQuestion,
  refreshReviewStructure,
  setSelectedQuestionId,
  setIsMoveToSectionOpen,
  sections,
  growthFactors,
  isEditable,
  reviewStructureValidating,
}: {
  section: ReviewSection;
  setSection: React.Dispatch<React.SetStateAction<ReviewSection | null>>;
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questions: ReviewQuestion[];
  handleDragDrop: (results: DropResult) => Promise<void>;
  cycle: ReviewCycle;
  setQuestion: React.Dispatch<React.SetStateAction<ReviewQuestion | null>>;
  refreshReviewStructure: KeyedMutator<ReviewCycleById> | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sections: ReviewSection[] | undefined;
  growthFactors: Dictionary<GrowthFactor>;
  isEditable: boolean;
  reviewStructureValidating: boolean;
}) => {
  const sectionOptions = useCycleSectionOptions(
    section,
    setSection,
    setIsSectionModalOpen,
    cycle,
    refreshReviewStructure
  );

  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };

  return (
    <SectionBuilder
      section={section}
      sectionOptions={sectionOptions}
      dragQuestionComponent={
        <DragDropContext onDragEnd={handleDragDrop}>
          <Droppable droppableId="ROOT" type="group" isDropDisabled={false}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions &&
                  questions.map((question, idx) => (
                    <Draggable
                      isDragDisabled={!isEditable || reviewStructureValidating}
                      draggableId={question.id}
                      key={question.id}
                      index={idx}
                    >
                      {(provided) => (
                        <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                          <QuestionComponent
                            question={question}
                            cycle={cycle}
                            setQuestion={setQuestion}
                            setIsQuestionModalOpen={setIsQuestionModalOpen}
                            refreshReviewStructure={refreshReviewStructure}
                            setSelectedQuestionId={setSelectedQuestionId}
                            setIsMoveToSectionOpen={setIsMoveToSectionOpen}
                            hasSections={Boolean(sections && sections.length)}
                            growthFactors={growthFactors}
                            isEditable={isEditable}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
      newQuestionAction={newQuestionAction}
      showAction={isEditable}
    />
  );
};

const QuestionComponent = ({
  question,
  cycle,
  setQuestion,
  setIsQuestionModalOpen,
  refreshReviewStructure,
  setSelectedQuestionId,
  setIsMoveToSectionOpen,
  hasSections,
  growthFactors,
  isEditable,
}: {
  question: ReviewQuestion;
  cycle: ReviewCycle;
  setQuestion: React.Dispatch<React.SetStateAction<ReviewQuestion | null>>;
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshReviewStructure: KeyedMutator<ReviewCycleById> | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasSections: boolean;
  growthFactors: Dictionary<GrowthFactor>;
  isEditable: boolean;
}) => {
  const questionOptions = useCycleQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    cycle,
    refreshReviewStructure,
    setSelectedQuestionId,
    hasSections
  );
  return (
    <QuestionBuilder
      questionOptions={questionOptions}
      question={question}
      growthFactors={growthFactors}
      showAction={isEditable}
    />
  );
};

const CycleSetupQuestionBuilderLoader = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, ...spacing.mt20 }}>
      {[...Array(10)].map((e, i) => (
        <Box key={i}>
          <SkeletonLoader variant="rectangular" width="100%" height={15} rowGap="5px" />
          <SkeletonLoader variant="rectangular" width="100%" height={15} rowGap="5px" />
          <SkeletonLoader variant="rectangular" width="100%" height={15} rowGap="5px" />
          <SkeletonLoader variant="rectangular" width="100%" height={15} rowGap="5px" />
        </Box>
      ))}
    </Box>
  );
};
