import {
  NonSensitiveOauthClientConfiguration,
  SensitiveOauthClientConfiguration,
  TokenResult,
} from '@/v2/feature/security/security-settings/security.interface';
import { StatusGroupedRequests } from '@/v2/feature/super-admin/features/super-admin-partner-api-monitoring/partner-api.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import axios, { AxiosResponse } from 'axios';

export class OauthClientAPI {
  static async createNewClient(
    name: string,
    description: string,
    redirectionUri: string,
    scopes: string[]
  ): Promise<SensitiveOauthClientConfiguration> {
    return (await axios.post('/apiv2/oauth/client', { name, description, redirectionUri, scopes })).data;
  }

  static async deleteClient(): Promise<void> {
    await axios.delete('/apiv2/oauth/client');
  }

  static async deleteSpecificClient(clientId: string): Promise<void> {
    await axios.delete(`/apiv2/oauth/client/${clientId}`);
  }

  static async validateAuthorizationAttempt(
    clientId: string,
    state: string,
    redirectionUri: string
  ): Promise<AxiosResponse> {
    return await axios.get(
      `/apiv2/oauth/authorize?response_type=code&client_id=${clientId}&state=${state}&redirect_uri=${redirectionUri}`
    );
  }

  static async updateExistingClient(
    clientId: string,
    appId: string,
    name: string,
    description: string,
    redirectionUri: string,
    scopes: string[]
  ): Promise<SensitiveOauthClientConfiguration> {
    return (
      await axios.patch(`/apiv2/oauth/client/${clientId}`, { id: appId, name, description, redirectionUri, scopes })
    ).data;
  }
}

export class OauthClientEndpoints {
  static getSpecificClientForCompanyForConsentStart(
    clientId: string,
    redirectionUri: string
  ): Endpoint<NonSensitiveOauthClientConfiguration> {
    return {
      url: `/apiv2/oauth/client/${clientId}?redirectionUri=${redirectionUri}`,
    };
  }

  static getAuthorizationCode(): Endpoint<NonSensitiveOauthClientConfiguration[]> {
    return {
      url: '/apiv2/oauth/clients',
    };
  }

  static getAllClientsForCompany(): Endpoint<NonSensitiveOauthClientConfiguration[]> {
    return {
      url: '/apiv2/oauth/clients',
    };
  }

  static getTokenPairForClientId(clientId: string): Endpoint<TokenResult> {
    return {
      url: `/apiv2/oauth/client/${clientId}/token-pair`,
    };
  }

  static getGroupedStats(): Endpoint<StatusGroupedRequests> {
    return {
      url: '/apiv2/oauth/superadmin/partner-api/grouped-stats',
    };
  }
}
