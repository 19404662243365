import { Button, IconButton, Stack } from '@mui/material';
import { CompanyPayroll, UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Loader } from '@/images/side-bar-icons/Loader.svg';
import { ReactComponent as Minus } from '@/images/side-bar-icons/Minus.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import {
  canAddToPayroll,
  canRemoveFromPayroll,
  hasPayrollMissingFields,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/payroll-company-employees.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { tableIconButtonSx, tablePrimaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type EmployeeActionsCellProps = {
  user: UserPayrollForTableDto;
  payroll: CompanyPayroll;
  disabled?: boolean;
  updating?: boolean;
  addToPayroll: (userId: number) => Promise<boolean>;
  removeFromPayroll: (userId: number) => Promise<boolean>;
  openDrawer: (type: 'edit' | 'missing-info', user: UserPayrollForTableDto) => void;
};

export function EmployeeActionsCell({
  user,
  payroll,
  openDrawer,
  addToPayroll,
  removeFromPayroll,
  disabled,
  updating,
}: EmployeeActionsCellProps) {
  const { polyglot } = usePolyglot();
  // show complete button
  const hasMissingFields = hasPayrollMissingFields(user);
  const isUKPayroll = !!payroll.employer;
  const showAddButton = (!hasMissingFields || !isUKPayroll) && canAddToPayroll(user, payroll);
  const showRemoveButton = (!hasMissingFields || !isUKPayroll) && canRemoveFromPayroll(user, payroll);

  return (
    <Stack flexDirection="row" justifyContent="flex-end" gap="5px" whiteSpace="nowrap">
      {hasMissingFields && (
        <Button
          disableRipple
          sx={{ ...secondaryCTABtn, p: spacing.p15, color: themeColors.DarkGrey }}
          onClick={(e) => {
            e.stopPropagation();
            openDrawer('missing-info', user);
          }}
          disabled={disabled || updating}
        >
          {polyglot.t('PayrunTable.completeUserInfo')}
        </Button>
      )}

      {/* only allow editing of tax info for UK payrolls */}
      {!hasMissingFields && isUKPayroll && (
        <IconButton
          sx={tableIconButtonSx}
          onClick={(e) => {
            e.stopPropagation();
            openDrawer('edit', user);
          }}
          disabled={disabled || updating}
          title={polyglot.t('PayrunTable.viewOrChangePayroll')}
        >
          <Edit {...iconSize} />
        </IconButton>
      )}

      {showAddButton && (
        <IconButton
          sx={tablePrimaryIconButtonSx}
          onClick={async (e) => {
            e.stopPropagation();
            await addToPayroll(user.userId);
          }}
          disabled={disabled}
          title={polyglot.t('PayrunTable.addToCompanyPayroll')}
        >
          {updating ? <Loader {...iconSize} /> : <Plus {...iconSize} />}
        </IconButton>
      )}

      {showRemoveButton && (
        <IconButton
          sx={tablePrimaryIconButtonSx}
          onClick={async (e) => {
            e.stopPropagation();
            await removeFromPayroll(user.userId);
          }}
          disabled={disabled}
          title={polyglot.t('PayrunTable.removeFromCompanyPayroll')}
        >
          {updating ? <Loader {...iconSize} /> : <Minus {...iconSize} />}
        </IconButton>
      )}
    </Stack>
  );
}
