import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    height: 16,
    minWidth: 16,
    fontWeight: 400,
    fontSize: 12,
    padding: '0 4px',
  },
}));
