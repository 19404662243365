import React from 'react';

import { PaginationState } from '@tanstack/react-table';
import { Content2Columns } from '@v2/feature/app-layout/features/main-content/layouts/components/content-2-columns.component';

import { PersonalWeeklyEntriesTableAndHeaderData } from '@/v2/feature/attendance/attendance.dto';
import { AttendanceMeWeeklyEntries } from '@/v2/feature/attendance/me/components/attendance-me-weekly-entries.component';
import { AttendanceWeeklyListSection } from '@/v2/feature/attendance/me/components/attendance-weekly-list-section.component';

interface AttendanceMeContentProps {
  readonly searchInput: string;
  readonly setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  readonly setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
  readonly weekList: {
    id: string;
    name: string;
    details: string;
  }[];
  readonly pagination?: PaginationState;
  readonly totalPages?: number;
  readonly onPageChange?: (pageIndex: number, pageSize: number) => void;
  readonly loading: boolean;
  readonly selectedWeekAndYear: string | undefined;
  readonly weeklyEntries: PersonalWeeklyEntriesTableAndHeaderData | undefined;
  readonly userLoading: boolean;
  readonly refresh: (weekAndYear: string) => Promise<void>;
  readonly refreshWeekList: () => Promise<void>;
  readonly userId: number;
  readonly totalItems: number;
}

export const AttendanceMeContent = ({
  searchInput,
  setSearchInput,
  setPagination,
  weekList,
  pagination,
  totalPages,
  onPageChange,
  loading,
  selectedWeekAndYear,
  weeklyEntries,
  userLoading,
  refresh,
  refreshWeekList,
  userId,
  totalItems,
}: AttendanceMeContentProps) => {
  return (
    <Content2Columns
      column1={
        <AttendanceWeeklyListSection
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setPagination={setPagination}
          weekList={weekList}
          pagination={pagination}
          totalPages={totalPages}
          onPageChange={onPageChange}
          handleWeekClick={refresh}
          loading={loading}
          selectedWeekAndYear={selectedWeekAndYear}
          totalItems={totalItems}
        />
      }
      column2={
        <AttendanceMeWeeklyEntries
          weeklyEntries={weeklyEntries}
          userLoading={userLoading}
          refresh={refresh}
          loading={loading}
          weekList={weekList ?? []}
          refreshWeekList={refreshWeekList}
          userId={userId}
        />
      }
      borderLeft
    />
  );
};
