import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { StepperHeader } from '@v2/components/stepper-header.component';
import { StepperComponent } from '@v2/components/stepper.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { ChoosePayrollStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup-steps/choose-payroll-step.component';
import { ProviderSettingsStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup-steps/provider-settings-step.component';
import { SelectPensionProviderStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup-steps/select-provider-step.component';
import { PayrollAPI } from '@v2/feature/payroll/payroll.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_BENEFITS_ROUTE } from '@/lib/routes';

enum PensionSetupSteps {
  ChoosePayroll = 'Choose Payroll',
  SelectProvider = 'Select Provider',
  ProviderSettings = 'Provider Settings',
}

export const OTHER_PAYROLL_ENTITY = 999990;

interface PensionSetupPageProps {
  readonly name: string;
  readonly noOfActivePensionSchemes: number;
  readonly refresh: () => Promise<void>;
}

export const PensionSetupPage = ({ name, noOfActivePensionSchemes, refresh }: PensionSetupPageProps) => {
  const history = useHistory();
  const { polyglot } = usePolyglot();

  const [payrollId, setPayrollId] = useState<number>(0);
  const [payrolls, setPayrolls] = useState<CompanyPayroll[]>([]);
  const [pensionProvider, setPensionProvider] = useState<string>('');
  const [pensionRule, setPensionRule] = useState<string>('');
  const [step, setStep] = useState<PensionSetupSteps>(PensionSetupSteps.ChoosePayroll);

  const [showMessage] = useMessage();

  const pensionSetupSteps = useMemo(
    () => [PensionSetupSteps.ChoosePayroll, PensionSetupSteps.SelectProvider, PensionSetupSteps.ProviderSettings],
    []
  );

  const refreshCompanyPayrolls = useCallback(async () => {
    try {
      const payrolls = await PayrollAPI.getCompanyPayrolls();
      setPayrolls(payrolls.items);
    } catch (err) {
      showMessage(
        polyglot.t('PensionModule.ErrorMessages.couldNotGetProviders', { errorMessage: nestErrorMessage(err) }),
        'error'
      );
    }
  }, [showMessage, polyglot]);

  useEffect(() => {
    refreshCompanyPayrolls();
  }, [refreshCompanyPayrolls]);

  const pensionStepsComponent = useMemo(
    () => [
      {
        name: PensionSetupSteps.ChoosePayroll,
        component: (
          <ChoosePayrollStep
            name={name}
            onNext={() => setStep(PensionSetupSteps.SelectProvider)}
            payrollId={payrollId}
            payrolls={payrolls}
            setPayrollId={setPayrollId}
            refresh={refresh}
          />
        ),
      },
      {
        name: PensionSetupSteps.SelectProvider,
        component: (
          <SelectPensionProviderStep
            onNext={() => setStep(PensionSetupSteps.ProviderSettings)}
            pensionProvider={pensionProvider}
            setPensionProvider={setPensionProvider}
            setPensionRule={setPensionRule}
          />
        ),
      },
      {
        name: PensionSetupSteps.ProviderSettings,
        component: (
          <ProviderSettingsStep
            displayName={name}
            payrollId={payrollId}
            pensionProvider={pensionProvider}
            pensionRule={pensionRule}
            noOfActivePensionSchemes={noOfActivePensionSchemes}
            refresh={refresh}
          />
        ),
      },
    ],
    [name, payrollId, payrolls, pensionProvider, pensionRule, noOfActivePensionSchemes, refresh]
  );

  return (
    <ContentWrapper loading={false} noHeader border={false}>
      <Stack sx={{ flex: 1, mt: spacing.m20 }}>
        <StepperHeader
          content={<StepperComponent steps={pensionSetupSteps} activeStep={step} hideConnector />}
          contentSx={{ width: '80%', textAlign: 'center' }}
          showStepBack={step !== PensionSetupSteps.ChoosePayroll}
          stepBackAction={() => {
            if (pensionSetupSteps.indexOf(step) > 0)
              return setStep(pensionSetupSteps[pensionSetupSteps.indexOf(step) - 1]);
            history.goBack();
          }}
          stepBackSx={{
            width: '10%',
            textAlign: 'left',
          }}
          showBack={true}
          backAction={() => {
            history.push(SETTINGS_BENEFITS_ROUTE);
          }}
          backSx={{ width: '10%', textAlign: 'right' }}
        />
        <Box sx={{ mt: spacing.mt40 }} />

        {pensionStepsComponent[pensionSetupSteps.indexOf(step)]?.component}
      </Stack>
    </ContentWrapper>
  );
};
