import { useState } from 'react';

import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { EditCustomBenefitPaymentDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-payment-drawer.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import {
  CustomBenefitDto,
  CustomBenefitPaymentMethodType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { isRecurringBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface PageProps {
  readonly customBenefit: CustomBenefitDto;
  readonly refreshBenefit: () => Promise<void>;
}

export const CustomBenefitSettingsPaymentPage = ({ customBenefit, refreshBenefit }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const isRecurring = isRecurringBenefit(customBenefit.type);

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('BenefitModule.payments')}
      onEdit={() => {
        setIsEditOpen(true);
      }}
    >
      <DescriptionLine
        field={polyglot.t('BenefitModule.paymentMethod')}
        value={customBenefit.paymentMethod ?? CustomBenefitPaymentMethodType.Tracking}
      />
      {customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Expenses && (
        <DescriptionLine
          field={polyglot.t('BenefitModule.expenseType')}
          value={customBenefit.paymentTypes?.map((paymentType) => paymentType.name)?.join(', ') ?? <EmptyCell />}
        />
      )}
      {customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Payroll && (
        <DescriptionLine
          field={polyglot.t('PayItemModule.payCodeEmployee')}
          value={customBenefit.payCode ?? <EmptyCell />}
        />
      )}
      {isRecurring && customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Payroll && (
        <DescriptionLine
          field={polyglot.t('PayItemModule.payCodeEmployer')}
          value={customBenefit.payCodeEmployer ?? <EmptyCell />}
        />
      )}

      {isEditOpen && (
        <EditCustomBenefitPaymentDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          customBenefit={customBenefit}
          refresh={refreshBenefit}
        />
      )}
    </BenefitsSettingsPageSection>
  );
};
