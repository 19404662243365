import { useContext, useMemo } from 'react';

import { GlobalContext } from '@/GlobalState';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { TimelineRollingView } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/rolling/timeline-rolling-view.component';
import { TimelineScheduledView } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/scheduled/timeline-scheduled-view.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const getDayOrDays = (days: number): string => (days > 1 ? 'days' : 'day');

export const RCUpsertTimelinePage = ({ cycleId, reach }: { cycleId: string; reach: ReachType }) => {
  const { data: reviewCycle, mutate: refreshCycle, isLoading: cycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, reach),
    {
      suspense: false,
    }
  );

  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['reviews:all']);
  const isManager = canAccessScopes(user, ['reviews:manager']);

  const isEditable = useMemo(
    () =>
      Boolean(
        reviewCycle &&
          reviewCycle.state !== CycleState.Completed &&
          Boolean(isAdmin || (isManager && reviewCycle.owner === user.userId))
      ),
    [reviewCycle, isAdmin, isManager, user]
  );

  if (!reviewCycle) return <></>;

  const showFlags = {
    showSelf: Boolean(reviewCycle.reviewerSelect?.includes(ReviewerTypes.Self)),
    showUpward: Boolean(reviewCycle.reviewerSelect?.includes(ReviewerTypes.Upward)),
    showPeer: Boolean(reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer)),
    showManager: Boolean(reviewCycle.reviewerSelect?.includes(ReviewerTypes.Manager)),
  };

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reach} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={cycleLoading}
    >
      {reviewCycle.type === CycleType.Scheduled ? (
        <TimelineScheduledView
          reviewCycle={reviewCycle}
          showFlags={showFlags}
          isEditable={isEditable}
          refreshCycle={refreshCycle}
        />
      ) : (
        <TimelineRollingView
          reviewCycle={reviewCycle}
          showFlags={showFlags}
          isEditable={isEditable}
          refreshCycle={refreshCycle}
        />
      )}
    </SettingsSectionContent>
  );
};
