import React from 'react';

import { Box, Stack } from '@mui/material';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReviewTemplate } from '@v2/feature/growth/reviews/interfaces/review-template.interface';
import { SurveyTemplate } from '@v2/feature/growth/surveys/interfaces/survey-template.interface';
import { PayrollLogoZelt } from '@v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface TemplateBlockProps {
  template: Pick<ReviewTemplate | SurveyTemplate, 'id' | 'companyId' | 'name' | 'questionIds' | 'createdBy'>;
  action: () => void;
  active: string | undefined;
}

export const TemplateBlock = ({ template, action, active }: TemplateBlockProps) => {
  return (
    <Box
      role="button"
      sx={{
        height: '100%',
        width: '100%',
        minHeight: '120px',
        boxSizing: 'border-box',
        background: active && active === template.id ? themeColors.Background : themeColors.white,
        border: `1px solid ${themeColors.GreyPress}`,
        borderRadius: radius.br12,
        padding: spacing.p12,
        '&:hover': {
          cursor: 'pointer',
          background: themeColors.GreyHover,
        },
      }}
      onClick={action}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Typography variant="title3">{template.name}</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g4 }}>
          <Typography variant="caption">{template.questionIds ? template.questionIds.length : 0} questions</Typography>

          {template.companyId === null ? (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
              <PayrollLogoZelt height="15px" width="15px" padding="2.5px" />
              <Typography
                variant="caption"
                color="DarkGrey"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                Zelt
              </Typography>
            </Stack>
          ) : template.createdBy ? (
            <UserCell userId={template.createdBy} />
          ) : (
            <EmptyCell />
          )}
        </Box>
      </Box>
    </Box>
  );
};
