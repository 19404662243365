import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { canApproveOrRejectRequest } from '@v2/feature/approval-rule/approval-rule.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { themeColors } from '@v2/styles/colors.styles';
import moment from 'moment';
import Polyglot from 'node-polyglot';
import { CSVLink } from 'react-csv';

import { ExpenseAPI } from '@/api-client/expense.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as UploadDoc } from '@/images/documents/UploadedDoc.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TabFilterButtons, TabFilterItem } from '@/v2/components/tab-filter-buttons.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { BufferData, PreviewPayload } from '@/v2/feature/documents/documents.interface';
import { ExpenseModal } from '@/v2/feature/payments/pages/components/expense-modal.component';
import { Expense, ExpenseStatus } from '@/v2/feature/payments/payments.interface';
import { getContractorInvoiceStatusComponent } from '@/v2/feature/payments/utils/get-contractor-invoice-status.util';
import { PaymentCategoryEnum } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { IIncludable, TableColumn } from '@/v2/feature/super-admin/components/helper/table-helper';
import { SelectDeselectIdRows } from '@/v2/feature/task/components/task-table/select-deselect-string-id-rows.component';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import { UserAPI } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { getDisplayName } from '@/v2/util/string-format.util';
import { truncateWithEllipses } from '@/v2/util/string.util';

export type IncludableExpense = IIncludable & Expense;
type BasicTableColumnType = ColumnDef<IncludableExpense, IncludableExpense>[];

const DATE_FORMAT = 'DD MMM YYYY';

const TabFilter = (polyglot: Polyglot): TabFilterItem<ExpenseStatus>[] => {
  return [
    { name: polyglot.t('PaymentStatusFilter.all'), value: ExpenseStatus.All },
    { name: polyglot.t('PaymentStatusFilter.draft'), value: ExpenseStatus.Draft },
    { name: polyglot.t('PaymentStatusFilter.pending'), value: ExpenseStatus.Pending },
    { name: polyglot.t('PaymentStatusFilter.approved'), value: ExpenseStatus.Approved },
    { name: polyglot.t('PaymentStatusFilter.rejected'), value: ExpenseStatus.Rejected },
  ];
};

interface ExpenseTableProps {
  expenses: Expense[];
  refreshData: () => void;
  readonly setSelectionModel: Dispatch<SetStateAction<string[]>>;
  readonly selectionModel: string[];
  reach: 'me' | 'team' | 'company';
  loadingExpenses: boolean;
}

export function ExpenseTable({
  expenses,
  refreshData,
  reach,
  selectionModel,
  setSelectionModel,
  loadingExpenses,
}: ExpenseTableProps): JSX.Element {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const [selectedExpense, setSelectedExpense] = useState<Expense | undefined>(undefined);
  const [openExpenseModal, setOpenExpenseModal] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>(
    state.user.features?.expense?.table?.selectedFilters ?? ExpenseStatus.Pending
  );
  const [searchInput, setSearchInput] = useState<string>('');

  const csvRef = useRef<any | undefined>(undefined);

  const [csvFile, setCSVFile] = useState<{ readonly name: string; readonly data: unknown }>({
    name: 'default.csv',
    data: [],
  });

  const [showMessage] = useMessage();

  // for attachment preview
  const [attachmentBuffer, setAttachmentBuffer] = useState<BufferData | undefined>();
  const [selectedAttachmentName, setSelectedAttachmentName] = useState('');
  const [selectedDocContentType, setSelectedDocContentType] = useState<string>('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const currentUserIsAdmin = hasScopes(['expenses:all'], getScopesContext(user));
  const { getCachedUserById } = useCachedUsers();

  const { data: settingsAndAppConfig } = useApiClient(PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId(), {
    suspense: false,
  });
  // const environmentPrefix = `${isProduction ? 'prod' : 'test'}`;

  // const [updatedExpense, setUpdatedExpense] = useState<Expense | null>(null);

  // const channelName = `expense-actions-${environmentPrefix}-user-${user.userId}`;
  // const expenseExternalSyncHandler = (pushEvent: PushEvent) => {
  //   if (pushEvent.message && pushEvent.companyId === user.company.companyId && pushEvent.userId === user.userId) {
  //     showMessage(polyglot.t('ExpenseModal.successMessages.sync'), 'success');
  //     if (pushEvent.data) setUpdatedExpense((pushEvent.data as unknown) as Expense);
  //     return;
  //   }
  // };

  const expenseTypes = useMemo(() => {
    return settingsAndAppConfig?.settings?.filter((type) => type.type === PaymentCategoryEnum.EXPENSE) ?? [];
  }, [settingsAndAppConfig]);

  const isAccountingAppConfigured = settingsAndAppConfig?.accountingAppConfigured;

  // const expenseSyncEventSourceURL = useMemo(() => {
  //   if (isAccountingAppConfigured) {
  //     return ExpenseEndpoints.getEventSourceUrlForExternalExpenseSyncSuccess(channelName)?.url;
  //   } else {
  //     return null;
  //   }
  // }, [channelName, isAccountingAppConfigured]);

  // useEventSource(expenseSyncEventSourceURL, {
  //   onMessage: expenseExternalSyncHandler,
  //   onError: eventSourceErrorHandler,
  // });

  const bulkApproveExpenses = async () => {
    try {
      const expenseCount = await ExpenseAPI.bulkApprove(selectionModel);
      setSelectionModel([]);
      refreshData();
      showMessage(polyglot.t('ExpenseModal.successMessages.bulkApproval', { expenseCount }), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('ExpenseModal.errorMessages.bulkApproval', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const mapExpensesToCsvFormat = (expenses: Expense[]) => {
    return expenses.map((expense) => {
      const beneficiary = expense.from ? getCachedUserById(expense.from) : undefined;
      const fromName = beneficiary ? beneficiary.displayName : undefined;
      const approverDetails: string[] = [];
      expense.approvedByIds?.forEach((approver) => {
        approverDetails.push(getDisplayName(getCachedUserById(approver) as Partial<UserSummaryDto>));
      });
      const matchingType = expenseTypes.find((eachType) => eachType.id === expense.typeId);
      const { notes, date, status, gross, amount, approvedOnTimestamp, currency } = expense;
      const taxAmount = gross - amount;
      return {
        beneficiary: fromName,
        type: matchingType?.name,
        reference: notes,
        date,
        status,
        currency,
        amount,
        taxAmount: taxAmount ?? 0,
        gross,
        approver: approverDetails && approverDetails?.length > 0 ? approverDetails.join(', ') : '',
        approved_rejected_on: approvedOnTimestamp,
      };
    });
  };

  const exportSelectedExpenses = async () => {
    try {
      const csvExpenses = filteredExpenses.filter((i) => selectionModel.includes(i.id));
      if (!csvExpenses || csvExpenses.length === 0) return;
      const data = mapExpensesToCsvFormat(csvExpenses);
      setCSVFile({ name: `selected-expenses-${new Date().toLocaleDateString()}.csv`, data });
    } catch (error) {
      showMessage(polyglot.t('ExpenseModal.errorMessages.export', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  };

  const exportAllExpenses = async () => {
    try {
      if (!filteredExpenses || filteredExpenses.length === 0) return;
      const data = mapExpensesToCsvFormat(filteredExpenses);
      setCSVFile({ name: `all-expenses-${new Date().toLocaleDateString()}.csv`, data });
    } catch (error) {
      showMessage(polyglot.t('ExpenseModal.errorMessages.export', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  };

  useEffect(() => {
    if (csvFile?.data && csvFile.name !== 'default.csv' && csvRef.current && csvRef.current.link) {
      // @ts-ignore
      csvRef.current.link.click();
    }
  }, [csvFile]);

  const filteredExpenses = useMemo(() => {
    if (!expenses) return [];
    let filteredExpenses = [...expenses];

    // if (filterValue === 'All') do nothing;

    if (filterValue === 'Approved') filteredExpenses = expenses.filter((i) => i.status === ExpenseStatus.Approved);

    if (filterValue === 'Pending') filteredExpenses = expenses.filter((i) => i.status === ExpenseStatus.Pending);

    if (filterValue === 'Draft') filteredExpenses = expenses.filter((i) => i.status === ExpenseStatus.Draft);

    if (filterValue === 'Rejected')
      filteredExpenses = expenses.filter((i) => [ExpenseStatus.Rejected].includes(i.status));

    // search on beneficiary name, invoice number and total amount
    if (searchInput) {
      const loweredCaseSearch = searchInput.toLowerCase();
      filteredExpenses = filteredExpenses.filter((i) => {
        const beneficiary = i.from ? getCachedUserById(i.from) : undefined;
        const name = beneficiary ? beneficiary.displayName : '';
        return (
          name?.toLowerCase().includes(loweredCaseSearch) ||
          i.notes?.toLowerCase().includes(loweredCaseSearch) ||
          i.amount?.toString().includes(loweredCaseSearch) ||
          i.gross?.toString().includes(loweredCaseSearch)
        );
      });
    }
    return filteredExpenses;
  }, [expenses, filterValue, searchInput, getCachedUserById]);

  // const updatedExpenses = useMemo(() => {
  //   if (!updatedExpense) return filteredExpenses;
  //   return filteredExpenses.map((expense) => (expense.id === updatedExpense.id ? updatedExpense : expense));
  // }, [filteredExpenses, updatedExpense]);

  // TODO: delete this and uncomment above
  const updatedExpenses = useMemo(() => {
    return filteredExpenses;
    // return filteredExpenses.map((expense) => (expense.id === updatedExpense.id ? updatedExpense : expense));
  }, [filteredExpenses]);

  const handlePreviewClick = useCallback(
    async (expense: Expense) => {
      try {
        if (!expense.attachment || expense.attachment?.length === 0) {
          console.error('Invalid receipt attachment');
          return;
        }

        await DocumentAPI.previewViaUuid(expense.attachment).then(async ({ contentType, file }: PreviewPayload) => {
          setSelectedExpense(expense);
          setSelectedAttachmentName('');
          setAttachmentBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        });
      } catch (e) {
        console.error('::URL Download error', e);
        showMessage(`Failed to preview attachment: ${nestErrorMessage(e)}`, 'error');
      }
    },
    [setOpenPreviewModal, setAttachmentBuffer, setSelectedAttachmentName, showMessage]
  );

  const selectableExpenseIds = useMemo(
    () => new Set(filteredExpenses.filter((e) => canApproveOrRejectRequest(e, user.userId)).map(({ id }) => id)),
    [filteredExpenses, user.userId]
  );

  const expensesColumn = useMemo<ColumnDef<IncludableExpense, unknown>[]>(
    () => [
      ...(selectableExpenseIds.size > 0
        ? [
            {
              id: 'select',
              enableSorting: false,
              minSize: 20,
              maxSize: 20,
              header: () => {
                const allSelected =
                  selectionModel.length > 0 &&
                  selectionModel.length === selectableExpenseIds.size &&
                  selectionModel.every((id) => selectableExpenseIds.has(id));
                return (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name="allSelected"
                      checked={allSelected}
                      value="allSelected"
                      onChange={(_, checked) => {
                        setSelectionModel(checked ? [...selectableExpenseIds] : []);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }) =>
                selectableExpenseIds.has(original.id) ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
                    <Box onClick={(e) => e.stopPropagation()}>
                      <CheckboxComponent
                        label={undefined}
                        name={original.id?.toString() ?? ''}
                        checked={selectionModel.includes(original.id)}
                        value={original.id?.toString() ?? ''}
                        onChange={() => {
                          let finalArray: string[];
                          if (selectionModel?.includes(original.id)) {
                            finalArray = selectionModel.filter((sm) => sm !== original.id);
                          } else finalArray = [...selectionModel, original.id];
                          setSelectionModel(finalArray);
                        }}
                      />
                    </Box>
                  </Box>
                ) : null,
            } as ColumnDef<IncludableExpense, unknown>,
          ]
        : []),
      ...(reach !== 'me'
        ? [
            new TableColumn<IncludableExpense>().define({
              header: polyglot.t('PaymentTableHeaders.beneficiary'),
              id: 'createdBy',
              size: 140,
              fieldName: 'createdByUser',
              sortingFn: (a, b) =>
                sortString(a, b, (item) =>
                  item.from ? getDisplayName(getCachedUserById(item.from) as Partial<UserSummaryDto>) : ''
                ),
              parseRow: (row: IncludableExpense) => (row.from ? <UserCell userId={row.from} /> : <EmptyCell />),
            }),
          ]
        : []),
      new TableColumn<IncludableExpense>().define({
        header: polyglot.t('ExpenseModal.policy'),
        id: 'type',
        size: 150,
        fieldName: 'type',
        enableSorting: false,
        parseRow: (row: IncludableExpense) => {
          return (
            <div style={{ paddingRight: '10px' }}>
              {row.typeId ? expenseTypes?.find((et) => et.id === row.typeId)?.name : ''}
            </div>
          );
        },
      }),

      new TableColumn<IncludableExpense>().define({
        header: polyglot.t('PaymentTableHeaders.reference'),
        id: 'reference',
        size: 140,
        fieldName: 'notes',
        enableSorting: false,
        parseRow: (row: IncludableExpense) => {
          const truncateLimit = 30;
          const notesTruncated = row?.notes && row.notes.length > truncateLimit;
          return !!notesTruncated ? (
            <StyledTooltip title={row.notes} placement="right">
              <div style={{ paddingRight: '10px' }}>{truncateWithEllipses(row.notes, truncateLimit)}</div>
            </StyledTooltip>
          ) : (
            <div style={{ paddingRight: '10px' }}>{row.notes}</div>
          );
        },
      }),

      new TableColumn<IncludableExpense>().define({
        header: '',
        id: 'attachment',
        size: 30,
        fieldName: 'attachment',
        enableSorting: false,
        parseRow: (row: IncludableExpense) => {
          return (
            <Box
              onClick={(event) => {
                event.stopPropagation();
              }}
              sx={{ width: 20 }}
            >
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {row && row?.attachment ? (
                  <IconButton
                    sx={{ ...iconCTAButtonSx, fill: themeColors.green, background: themeColors.white }}
                    onClick={() => {
                      if (row) {
                        handlePreviewClick(row);
                      }
                    }}
                  >
                    <DocumentIcon {...iconSize} />
                  </IconButton>
                ) : (
                  <UploadDoc {...iconSize} />
                )}
              </Box>
            </Box>
          );
        },
      }),

      new TableColumn<IncludableExpense>().define({
        header: polyglot.t('PaymentTableHeaders.date'),
        id: 'date',
        size: 140,
        fieldName: 'date',
        sortingFn: (a, b) => sortNumeric(a, b, (item) => new Date(item.date).getTime()),
        formatter: (date) => {
          return (
            <Box sx={{ color: new Date(date).getTime() < Date.now() ? themeColors.RedDark : themeColors.black }}>
              {moment(date).format(DATE_FORMAT)}
            </Box>
          );
        },
        parseRow: (row: IncludableExpense) => {
          const showAlert =
            [ExpenseStatus.Pending].includes(row.status) && new Date(row.dueDate).getTime() < Date.now();
          return (
            <Box
              sx={{
                color: showAlert ? themeColors.RedDark : themeColors.black,
              }}
            >
              {moment(row.date).format(DATE_FORMAT)}
            </Box>
          );
        },
      }),

      new TableColumn<IncludableExpense>().define({
        header: polyglot.t('PaymentTableHeaders.status'),
        id: 'status',
        size: 140,
        fieldName: 'status',
        sortingFn: (a, b) => sortString(a, b, (item) => item.status),
        parseRow: (row: IncludableExpense) => {
          return (
            <>
              {getContractorInvoiceStatusComponent(
                row.status,
                { ...themeFonts.caption },
                !!isAccountingAppConfigured,
                !!row.externalId
              )}
            </>
          );
        },
      }),
      {
        header: polyglot.t('PaymentTableHeaders.gross'),
        accessorFn: (row) => row,
        id: 'gross',
        size: 180,
        fieldName: 'gross',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.gross),
        cell: ({ row: { original } }) => (
          <div style={{ paddingRight: '10px' }}>{formatCurrency(original.gross, undefined, original.currency)}</div>
        ),
      },
    ],
    [
      expenseTypes,
      getCachedUserById,
      handlePreviewClick,
      isAccountingAppConfigured,
      polyglot,
      reach,
      selectionModel,
      setSelectionModel,
      selectableExpenseIds,
    ]
  );

  const allSelectedExpensesArePending = useMemo(() => {
    const selectedExpenses = filteredExpenses.filter((expense) => selectionModel.includes(expense.id));
    return selectedExpenses.every((eachExpense) => eachExpense.status === ExpenseStatus.Pending);
  }, [filteredExpenses, selectionModel]);

  const getExpenseBulkActionsOptions = () => {
    return [
      {
        icon: <Chose {...iconSize} />,
        handler: () => bulkApproveExpenses(),
        label: 'Approve selected',
        disabled: selectionModel.length === 0 || !allSelectedExpensesArePending,
      },
      {
        icon: <Export {...iconSize} />,
        handler: () => exportSelectedExpenses(),
        label: 'Export selected',
        disabled: selectionModel.length === 0,
      },
      {
        icon: <Export {...iconSize} />,
        handler: () => exportAllExpenses(),
        label: 'Export all',
        disabled: filteredExpenses.length === 0,
      },
    ];
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <TabFilterButtons
          filters={TabFilter(polyglot)}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
          hasSearch
          onFilterChange={async ({ filterValue, searchInput }) => {
            try {
              setSelectionModel([]);
              setFilterValue(filterValue);
              setSearchInput(searchInput);
              const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                'expense',
                'table',
                'selectedFilters',
                filterValue
              );
              dispatch({
                type: GlobalStateActions.UPDATE_USER,
                payload: updatedGlobalUser,
              });
            } catch (error) {
              showMessage(
                polyglot.t('ExpenseModal.errorMessages.filter', {
                  errorMessage: nestErrorMessage(error),
                }),
                'error'
              );
            }
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', gap: spacing.g5, marginLeft: spacing.ml10 }}>
          <SelectDeselectIdRows<string>
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            rows={filteredExpenses}
            hideSelectAll
          />
          <StyledMenuComponent
            options={getExpenseBulkActionsOptions()}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: 'Actions',
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable<IncludableExpense>
          rowData={[...updatedExpenses]}
          columnData={(expensesColumn as unknown) as BasicTableColumnType}
          rowClick={(row) => {
            setSelectedExpense(row.original);
            setOpenExpenseModal(true);
          }}
          initialSort={[{ id: 'date', desc: true }]}
          loading={loadingExpenses}
        />
      </Box>
      {openExpenseModal && selectedExpense && (
        <ExpenseModal
          isOpen={openExpenseModal}
          setIsOpen={setOpenExpenseModal}
          selectedExpense={selectedExpense}
          onClose={() => setSelectedExpense(undefined)}
          onActionPerformed={async () => {
            refreshData();
          }}
          reach={reach}
          currentUserIsAdmin={currentUserIsAdmin}
        />
      )}
      {openPreviewModal && (
        <DocPreviewer
          fileBuffer={attachmentBuffer}
          contentType={selectedDocContentType}
          visible
          onClose={() => setOpenPreviewModal(false)}
          title={selectedAttachmentName}
        />
      )}
      <CSVLink
        ref={csvRef}
        filename={csvFile.name}
        data={Array.isArray(csvFile.data) || typeof csvFile.data === 'string' ? csvFile.data : []}
        hidden={true}
      />
    </Box>
  );
}
