import * as routes from '@/lib/routes';
import { SuperAdminDashboardLayout } from '@v2/feature/app-layout/features/superadmin-layout/layout.index';
import { AuthForgotPasswordPage } from '@/v2/feature/auth/features/auth-forgot-password/auth-forgot-password.page';
import { AuthLogin2FAPage } from '@/v2/feature/auth/features/auth-login/auth-login-2fa.page';
import { AuthLoginPage } from '@/v2/feature/auth/features/auth-login/auth-login.page';
import { AuthLoginSSOIOSPage } from '@/v2/feature/auth/features/auth-login/auth-login-sso-ios.page';
import { AuthLogoutPage } from '@/v2/feature/auth/features/auth-logout/auth-logout.page';
import { AuthResetPasswordPage } from '@/v2/feature/auth/features/auth-reset-password/auth-reset-password.page';
import { AuthSignupAccountCreationPage } from '@/v2/feature/auth/features/auth-signup/pages/auth-signup-account-creation.page';
import { AuthSignupPage } from '@/v2/feature/auth/features/auth-signup/pages/auth-signup.page';
import { AuthXeroSignupPage } from '@/v2/feature/auth/features/auth-xero-signup/auth-xero-signup.page';
import { UserOnboardingCreatePassword } from '@/v2/feature/user-onboarding/by-user/pages/create-password/user-onboarding-create-password.page';
import { UserOnboardingPasswordAlreadySet } from '@/v2/feature/user-onboarding/by-user/pages/create-password/user-onboarding-password-already-set.page';
import { UserOnboardingWelcomePage } from '@/v2/feature/user-onboarding/by-user/pages/welcome.page';
import { OnboardingUserRouter } from '@/v2/feature/user-onboarding/onboarding-by-user/onboarding-by-user.router';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { DashboardLayout } from '@v2/feature/app-layout/app-layout.index';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { QuickbooksDisconnectedPage } from '@/v2/feature/app-integration/apps/quickbooks/quickbooks-disconnected.component';
import { UserOnboardingSummaryPage } from '@/v2/feature/user-onboarding/onboarding-by-user/onboarding-summary.page';
import { PolyglotProvider } from '@/v2/infrastructure/i18n/i8n.util';

/**
 * ThemeProvider here is used for responsive screens
 */

const REACT_APP_GRID_PRO_LICENSE =
  '3d5b6c10719bd7171d6fd4c780cd2555T1JERVI6MzUzNTAsRVhQSVJZPTE2NzMwMTQyMTIwMDAsS0VZVkVSU0lPTj0x';
LicenseInfo.setLicenseKey(REACT_APP_GRID_PRO_LICENSE);

export function App(): React.JSX.Element {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <PolyglotProvider>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Switch>
              <Route path={routes.USER_ONBOARDING_WELCOME_ROUTE} component={UserOnboardingWelcomePage} />
              <Route path={routes.USER_ONBOARDING_PASSWORD} component={UserOnboardingCreatePassword} />
              <Route path={routes.USER_ONBOARDING_PASSWORD_ALREADY_SET} component={UserOnboardingPasswordAlreadySet} />
              <Route path={routes.USER_ONBOARDING_SUMMARY_ROUTE} component={UserOnboardingSummaryPage} />
              <Route path={routes.USER_ONBOARDING_ROUTE} component={OnboardingUserRouter} />
              <Route path={routes.LOGIN_ROUTE} exact component={AuthLoginPage} />
              <Route path={routes.LOGIN_ROUTE_SSO_IOS} exact component={AuthLoginSSOIOSPage} />
              <Route path={routes.LOGIN_2FA_ROUTE} exact component={AuthLogin2FAPage} />
              <Route path={routes.LOGOUT_ROUTE} exact component={AuthLogoutPage} />
              <Route path={routes.REGISTER_ORGANIZATION} exact component={AuthSignupAccountCreationPage} />
              <Route path={routes.PASSWORD_RESET_ROUTE} exact component={AuthResetPasswordPage} />
              <Route path={routes.PASSWORD_FORGOT_ROUTE} exact component={AuthForgotPasswordPage} />
              <Route path={routes.SIGN_UP} component={AuthSignupPage} />
              <Route path={routes.XERO_SIGN_UP} component={AuthXeroSignupPage} />
              <Route path={routes.QUICKBOOKS_DISCONNECTED} component={QuickbooksDisconnectedPage} />
              <Route path={routes.BACKOFFICE_ROUTE} component={SuperAdminDashboardLayout} />
              <Route path={routes.DASHBOARD_ROUTE} component={DashboardLayout} />
            </Switch>
          </QueryParamProvider>
        </BrowserRouter>
      </PolyglotProvider>
    </ThemeProvider>
  );
}
