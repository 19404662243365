import React from 'react';

import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { DeviceMdmSecurityComponent } from '@v2/feature/device/features/device-cards/device-security-cards.component';

import { DEVICE_COMPANY_DEVICE_ENROLL_ROUTE } from '@/lib/routes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly devicePossession: DevicePossessionDto;
  readonly fetchDeviceDetails: () => Promise<void>;
  readonly loading: boolean;
  activeTransit: DeviceTransitDto | null;
  appliedPolicies: AppliedDevicePoliciesDto;
  appliedZeltPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  appliedZeltPoliciesForMobile: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}

export const DeviceMdmSecurityPage = ({
  devicePossession,
  fetchDeviceDetails,
  loading,
  activeTransit,
  appliedPolicies,
  appliedZeltPolicies,
  appliedZeltPoliciesForMobile,
}: Props): React.JSX.Element => {
  return (
    <SettingsSectionContent
      title="Security"
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={loading}
    >
      <DeviceMdmSecurityComponent
        devicePossession={devicePossession}
        activeTransit={activeTransit}
        loading={loading}
        refreshDevice={fetchDeviceDetails}
        enrollPath={DEVICE_COMPANY_DEVICE_ENROLL_ROUTE}
        appliedPolicies={appliedPolicies}
        appliedZeltPolicies={appliedZeltPolicies}
        appliedZeltPoliciesForMobile={appliedZeltPoliciesForMobile}
      />
    </SettingsSectionContent>
  );
};
