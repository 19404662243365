import React, { useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { EmergencyContactValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserAPI } from '@/v2/feature/user/user.api';

export const EmergencyContactSchema = Yup.object().shape({
  emergencyName: Yup.string().nullable().required('Full name of the contact is required'),
  emergencyNumber: Yup.string().nullable().required('Contact phone is required'),
  emergencyRelationship: Yup.string().nullable().required('Specifying a relationship is required'),
});

interface Props {
  readonly initialValues: Partial<EmergencyContactValues>;
  readonly userId: number;
  readonly onSubmit: (_: EmergencyContactValues) => void;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: EmergencyContactValues) => void;
}

export const EmergencyContactForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<EmergencyContactValues>({
    initialValues: { ...initialValues, customUpdates: initialValues.customUpdates ?? [] },
    enableReinitialize: true,
    validationSchema: EmergencyContactSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const update = {
          emergencyName: values.emergencyName ?? null,
          emergencyRelationship: values.emergencyRelationship ?? null,
          emergencyNumber: values.emergencyNumber ?? null,
          customUpdates: values.customUpdates ?? [],
        };
        if (!usedForDataImport) {
          await UserAPI.patchUserEmergencyContactInfo(userId, update);
          onSubmit(update);
          showMessage(polyglot.t('EmergencyContactForm.successMessages.update'), 'success');
        } else {
          importHandler?.(update);
        }
      } catch (error) {
        showMessage(polyglot.t('EmergencyContactForm.errorMessages.save', { msg: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('EmergencyContactForm.edit')}</Typography>
        <ProfileField fieldStub="user.emergencyName">
          <TextfieldComponent
            name="emergencyName"
            label={polyglot.t('EmergencyContactForm.fullName')}
            value={formik.values.emergencyName}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.emergencyName && !!formik.errors.emergencyName}
            helperText={(formik.touched.emergencyName && formik.errors.emergencyName) ?? ' '}
            clearText={() => formik.setFieldValue('emergencyName', '')}
          />
        </ProfileField>
        <ProfileField fieldStub="user.emergencyNumber">
          <TextfieldComponent
            name="emergencyNumber"
            label={polyglot.t('EmergencyContactForm.phone')}
            value={formik.values.emergencyNumber}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.emergencyNumber && !!formik.errors.emergencyNumber}
            helperText={(formik.touched.emergencyNumber && formik.errors.emergencyNumber) ?? ' '}
            clearText={() => formik.setFieldValue('emergencyNumber', '')}
          />
        </ProfileField>
        <ProfileField fieldStub="user.emergencyRelationship">
          <TextfieldComponent
            name="emergencyRelationship"
            label={polyglot.t('EmergencyContactForm.relationship')}
            value={formik.values.emergencyRelationship}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.emergencyRelationship && !!formik.errors.emergencyRelationship}
            helperText={(formik.touched.emergencyRelationship && formik.errors.emergencyRelationship) ?? ' '}
            clearText={() => formik.setFieldValue('emergencyRelationship', '')}
          />
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates ?? []}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
