import { useEffect, useMemo, useRef } from 'react';

import { CompanyEndpoints } from '@/api-client/company.api';
import { SelectComponent, SelectComponentProps } from '@/v2/components/forms/select.component';
import { CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export type CountrySelectProps = Omit<SelectComponentProps, 'ref' | 'label' | 'options' | 'value' | 'onChange'> & {
  label?: string;
  name: string;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: number | null;
  countryCodeFilter?: string | false;
  footer?: React.ReactNode;
  autoSelectSingleEntity?: (id: number) => void;
  readonly onChange?: (e: React.ChangeEvent<any>, entity: CompanyUnitDto) => any;
};

export const EntitySelect = ({
  label = 'Entity',
  name,
  error,
  helperText,
  value,
  onChange,
  countryCodeFilter,
  footer,
  autoSelectSingleEntity,
  ...selectProps
}: CountrySelectProps) => {
  const hasAutoSelected = useRef(false);
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });

  const entityOptions = useMemo(() => {
    return generalSettings?.entities
      .filter((entity) => !countryCodeFilter || countryCodeFilter === entity.address?.countryCode)
      .map((e) => ({
        label: e.legalName,
        value: e.id,
        description: getCountryNameForCode(e.address?.countryCode),
      }))
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
  }, [generalSettings?.entities, countryCodeFilter]);

  useEffect(() => {
    if (!hasAutoSelected.current && !!autoSelectSingleEntity && !value && entityOptions?.length === 1) {
      hasAutoSelected.current = true;
      autoSelectSingleEntity(entityOptions[0].value);
    }
  }, [autoSelectSingleEntity, entityOptions, value]);

  return (
    <SelectComponent
      name={name}
      label={label}
      options={entityOptions ?? []}
      value={value ?? ''}
      error={error}
      helperText={helperText}
      footer={footer}
      onChange={(e) => {
        const selectedEntity = generalSettings?.entities.find(({ id }) => id === e.target.value);
        if (!selectedEntity) return;
        onChange?.(e, selectedEntity);
      }}
      {...selectProps}
    />
  );
};
