import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DocumentSettingsNewTypeForm } from '@/v2/feature/documents/components/document-settings-new-type-drawer.component';
import { DocumentTypeAPI } from '@/v2/feature/documents/document-type.api';
import { NewDocumentTypeFormValues } from '@/v2/feature/documents/documents.interface';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { toTitleCase } from '@/v2/util/string.util';

export interface DocumentTypeForTable {
  id: number;
  value: string;
  visible: boolean;
  documentTypeName: string;
  availableTo: 'company' | 'personal';
  status: 'Default' | 'Default (Hidden)' | 'Custom';
  default: boolean;
  createdAt: string;
}

export const DocumentsSettingsPage = () => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [drawerMode, setDrawerMode] = useState<'create' | 'edit'>('create');
  const [currentDocumentType, setCurrentDocumentType] = useState<NewDocumentTypeFormValues>();
  const [allDocumentTypes, setAllDocumentTypes] = useState<any[]>([]);

  const filteredDocumentTypes = useMemo(() => {
    if (searchInput?.length === 0) {
      return allDocumentTypes ?? [];
    } else {
      return allDocumentTypes?.filter(
        (d) =>
          d.documentTypeName?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
          d.availableTo?.includes(searchInput?.toLowerCase())
      );
    }
  }, [searchInput, allDocumentTypes]);

  const refreshDocumentTypes = useCallback(async () => {
    setLoading(true);
    try {
      const data = await DocumentTypeAPI.getAllDocumentTypesForSettings();

      // Map the merged document types to DocumentTypeForTable
      const documentTypesForTable: DocumentTypeForTable[] = data.map((d) => {
        const documentTypeName = d.label;
        const status = d.default ? 'Default' : 'Custom';

        return {
          id: d.id,
          documentTypeName,
          value: d.value,
          availableTo: d.availableTo === 'company' ? 'company' : 'personal',
          status,
          visible: d.visible,
          default: d.default,
          createdAt: d.createdAt,
        };
      });
      setAllDocumentTypes(documentTypesForTable);
    } catch (error) {
      showMessage(
        polyglot.t('DocumentsSettingsPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [polyglot, showMessage]);

  useEffect(() => {
    refreshDocumentTypes();
  }, [refreshDocumentTypes]);

  const newCustomDocumentTypeHandler = () => {
    setDrawerMode('create');
    setCurrentDocumentType(undefined);
    setNewTypeDrawerIsOpen(true);
  };

  const editCustomDocumentTypeHandler = (documentType: DocumentTypeForTable) => {
    setDrawerMode('edit');
    setCurrentDocumentType({
      ...documentType,
    });
    setNewTypeDrawerIsOpen(true);
  };

  const getActionsForDocumentType = useCallback(
    (documentTypeObject: DocumentTypeForTable): OptionProps[] => {
      const actionOptions: OptionProps[] = [];
      const hideCustomDocumentTypeHandler = async (documentType: DocumentTypeForTable) => {
        try {
          await DocumentTypeAPI.hideCustomDocumentType(documentType);
          refreshDocumentTypes();
        } catch (error) {
          showMessage(
            polyglot.t('DocumentsSettingsPage.errorMessages.hide', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      };

      const showCustomDocumentTypeHandler = async (documentType: DocumentTypeForTable) => {
        try {
          await DocumentTypeAPI.showCustomDocumentType(documentType);
          refreshDocumentTypes();
        } catch (error) {
          showMessage(
            polyglot.t('DocumentsSettingsPage.errorMessages.unhide', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      };

      const deleteCustomDocumentTypeHandler = async (documentTypeId: number) => {
        try {
          await DocumentTypeAPI.deleteCustomDocumentType(documentTypeId);
          showMessage(polyglot.t('DocumentsSettingsPage.successMessages.delete'), 'success');
          refreshDocumentTypes();
        } catch (error) {
          showMessage(
            polyglot.t('DocumentsSettingsPage.errorMessages.delete', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      };

      if (!documentTypeObject.default)
        actionOptions.push(
          {
            icon: <EditIcon {...iconSize} />,
            label: polyglot.t('General.edit'),
            handler: () => editCustomDocumentTypeHandler(documentTypeObject),
          },
          {
            icon: <Trash {...iconSize} />,
            label: polyglot.t('General.delete'),
            handler: () => deleteCustomDocumentTypeHandler(documentTypeObject?.id),
          }
        );
      if (documentTypeObject.visible)
        actionOptions.push({
          label: polyglot.t('DocumentsSettingsPage.hide'),
          handler: () => hideCustomDocumentTypeHandler(documentTypeObject),
        });
      if (!documentTypeObject.visible)
        actionOptions.push({
          label: polyglot.t('DocumentsSettingsPage.unhide'),
          handler: () => showCustomDocumentTypeHandler(documentTypeObject),
        });

      return actionOptions;
    },
    [polyglot, refreshDocumentTypes, showMessage]
  );

  const columns = useMemo<ColumnDef<DocumentTypeForTable, DocumentTypeForTable>[]>(
    () => [
      {
        header: () => polyglot.t('DocumentsSettingsPage.documentType'),
        enableSorting: false,
        accessorFn: (row: DocumentTypeForTable) => row,
        id: 'documentType',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<DocumentTypeForTable> }) => {
          return <Typography sx={themeFonts.caption}>{original?.documentTypeName}</Typography>;
        },
      },
      {
        header: () => polyglot.t('DocumentsSettingsPage.availableTo'),
        enableSorting: false,
        accessorFn: (row: DocumentTypeForTable) => row,
        id: 'availableTo',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<DocumentTypeForTable> }) => {
          return (
            <Typography sx={themeFonts.caption}>
              {original?.availableTo ? toTitleCase(original?.availableTo) : <EmptyCell />}
            </Typography>
          );
        },
      },
      {
        header: () => polyglot.t('DocumentsSettingsPage.type'),
        enableSorting: false,
        accessorFn: (row: DocumentTypeForTable) => row,
        id: 'status',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<DocumentTypeForTable> }) => {
          return <Typography sx={themeFonts.caption}>{original?.status}</Typography>;
        },
      },
      {
        header: () => polyglot.t('DocumentsSettingsPage.visible'),
        enableSorting: false,
        accessorFn: (row: DocumentTypeForTable) => row,
        id: 'visible',
        maxSize: 150,
        minSize: 150,
        cell: ({ row: { original } }: { row: Row<DocumentTypeForTable> }) => {
          return (
            <Typography sx={themeFonts.caption}>
              {original?.visible
                ? polyglot.t('DocumentsSettingsPage.inUse')
                : polyglot.t('DocumentsSettingsPage.hidden')}
            </Typography>
          );
        },
      },
      {
        id: 'action-buttons',
        header: () => '',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 40,
        cell: ({ row: { original } }) => {
          return (
            <Stack flexDirection="row" justifyContent="flex-end" gap="5px" whiteSpace="nowrap">
              <StyledMenuComponent
                options={getActionsForDocumentType(original)}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <ActionsSmall {...iconSize} />,
                }}
              />
            </Stack>
          );
        },
      },
    ],
    [polyglot, getActionsForDocumentType]
  );

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Types</Typography>} />
      <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: spacing.mb30,
          }}
        >
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
          <ButtonComponent onClick={newCustomDocumentTypeHandler} colorVariant="primary" sizeVariant="small">
            {polyglot.t('DocumentsSettingsPage.newType')}
          </ButtonComponent>
        </Box>
        <Box>
          <BasicTable rowData={[...filteredDocumentTypes]} columnData={columns} loading={loading} hidePagination />
        </Box>
        <DrawerModal isOpen={newTypeDrawerIsOpen} setIsOpen={setNewTypeDrawerIsOpen}>
          <DocumentSettingsNewTypeForm
            initialValues={currentDocumentType}
            onClose={() => {
              setNewTypeDrawerIsOpen(false);
            }}
            refreshDocumentTypes={refreshDocumentTypes}
            mode={drawerMode}
          />
        </DrawerModal>
      </ContentWrapper>
    </RootStyle>
  );
};
