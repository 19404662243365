import React, { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { PaymentExpensePreview } from '@v2/feature/payments/components/payment-expense-preview.component';
import { PaymentInvoicePreview } from '@v2/feature/payments/components/payment-invoice-preview.component';
import { PaymentsGroupDrawer } from '@v2/feature/payments/components/payments-group-drawer.component';
import {
  BasicBankAccountDetails,
  PaymentCategory,
  PaymentDto,
  PaymentStatus,
  PaymentType,
  TransactionStatus,
  TransactionType,
} from '@v2/feature/payments/payments.dto';
import { ContractorInvoice, Expense } from '@v2/feature/payments/payments.interface';
import {
  getPaymentStatus,
  HMRC_ACCOUNT,
  paymentPaidOrMarkedPaid,
  transactionTypeToLabel,
} from '@v2/feature/payments/payments.util';
import { UserBankAccountEndpoints } from '@v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { iconSize } from '@v2/styles/table.styles';
import { useHistory } from 'react-router-dom';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import { ExpenseAPI } from '@/api-client/expense.api';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Info } from '@/images/side-bar-icons/Info.svg';
import { ReactComponent as ErrorIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getRequestFromPayment, usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { PaymentsAPI, PaymentsEndpoints } from '@/v2/feature/payments/payments.api';
import { PAYMENTS_SELECT_BANK_ROUTE } from '@/v2/feature/payments/payments.router';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';

interface PaymentDetailsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly payment: PaymentDto;
  readonly refresh: () => Promise<void>;
  readonly paymentAlerts: Alert<PaymentDto> | undefined;
  readonly onClose: (() => void) | undefined;
}

interface ViewerItemProps {
  readonly title: string;
  readonly value: React.JSX.Element | string;
  readonly valueOnClick?: () => void;
}

// TODO: @polyglot-
export const ViewerItem = ({ title, value, valueOnClick }: ViewerItemProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="caption">{title}</Typography>
      <Typography
        onClick={valueOnClick}
        variant="title4"
        color={valueOnClick ? 'blue' : 'DarkGrey'}
        sx={{ textDecoration: valueOnClick ? 'underline' : 'none', cursor: valueOnClick ? 'pointer' : 'default' }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const PaymentDetailsDrawer = ({
  isOpen,
  setIsOpen,
  payment,
  refresh,
  paymentAlerts,
  onClose,
}: PaymentDetailsDrawerProps): React.JSX.Element => {
  const { getCachedUserById } = useCachedUsers();
  const user = payment.userId ? getCachedUserById(payment.userId) : undefined;

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <Box sx={drawerContentSx}>
          <Typography variant="title2">Payment</Typography>
          {user && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="caption">{user?.role?.jobTitle}</Typography>
              <Box>
                <UserCell userId={user.userId} nameVariant="title4" />
              </Box>
            </Box>
          )}

          <PaymentDetailsDrawerContent
            payment={payment}
            refresh={refresh}
            paymentAlerts={paymentAlerts}
            setIsOpen={setIsOpen}
          />
        </Box>
      </Suspense>
    </DrawerModal>
  );
};

interface PaymentDetailsMainDrawerContentProps {
  readonly payment: PaymentDto;
  readonly refresh: () => Promise<void>;
  readonly paymentAlerts: Alert<PaymentDto> | undefined;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const PaymentDetailsDrawerContent = ({
  payment,
  refresh,
  paymentAlerts,
  setIsOpen,
}: PaymentDetailsMainDrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const { data: userBankAccountDto } = useApiClient(
    UserBankAccountEndpoints.findCurrentByUserId(payment.userId ?? null),
    { suspense: true }
  );

  const [showMessage] = useMessage();
  const [bankAccountDetails, setBankAccountDetails] = useState<BasicBankAccountDetails | undefined>(undefined);
  const [paymentInvoice, setPaymentInvoice] = useState<ContractorInvoice | null>(null);
  const [paymentExpense, setPaymentExpense] = useState<Expense | null>(null);

  const getPayeeBankDetails = useCallback(async (): Promise<void> => {
    if (!payment.userId && payment.type === PaymentType.Taxes && payment.category === PaymentCategory.Payroll) {
      setBankAccountDetails(HMRC_ACCOUNT);
      return;
    }

    try {
      if (payment.userId) {
        if (!userBankAccountDto) {
          setBankAccountDetails(undefined);
          return;
        }

        setBankAccountDetails({
          accountName: userBankAccountDto.accountName,
          accountNumber: userBankAccountDto.accountNumber,
          sortCode: userBankAccountDto.sortCode,
          bankName: userBankAccountDto.bankName,
        });
      }
    } catch (error) {
      showMessage(`Could not retrieve payee details. ${nestErrorMessage(error)}`, 'error');
    }
  }, [payment.userId, payment.type, payment.category, userBankAccountDto, showMessage]);

  const getPaymentInvoiceIfTypeOfPaymentIsInvoice = useCallback(async (): Promise<void> => {
    if (!payment.userId || payment.category !== PaymentCategory.Invoice || payment.type !== PaymentType.Employee)
      return;

    try {
      const invoice = await ContractorInvoiceAPI.getUserInvoiceForPaymentId(payment.userId, payment.id);
      setPaymentInvoice(invoice);
    } catch (error) {
      showMessage(`Could not get invoice details for this payment. ${nestErrorMessage(error)}`, 'error');
    }
  }, [payment.category, payment.type, payment.userId, payment.id, showMessage]);

  const getExpenseIfTypeOfPaymentIsExpense = useCallback(async (): Promise<void> => {
    if (!payment.userId || payment.category !== PaymentCategory.Expense) return;

    try {
      const expense = await ExpenseAPI.getExpenseForPaymentId(payment.userId, payment.id);
      setPaymentExpense(expense);
    } catch (error) {
      showMessage(`Could not get expense details for this payment. ${nestErrorMessage(error)}`, 'error');
    }
  }, [payment.category, payment.userId, payment.id, showMessage]);

  useEffect(() => {
    Promise.all([
      getPayeeBankDetails(),
      getPaymentInvoiceIfTypeOfPaymentIsInvoice(),
      getExpenseIfTypeOfPaymentIsExpense(),
    ]);
  }, [getPaymentInvoiceIfTypeOfPaymentIsInvoice, getPayeeBankDetails, getExpenseIfTypeOfPaymentIsExpense]);

  const markAsPaid = useCallback(async () => {
    try {
      await PaymentsAPI.markAsPaid([payment.id]);
      showMessage('Marked as paid successfully', 'success');
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [showMessage, polyglot, payment, refresh]);

  const showPayButton = useMemo(() => {
    const alertsObj = paymentAlerts?.entries.find((p) => p.id === payment.id);
    return Boolean(
      (!alertsObj || !(alertsObj.bankAccountMissing || alertsObj.userAddressMissing || alertsObj.referenceMissing)) &&
        payment.status !== PaymentStatus.Paid &&
        payment.status !== PaymentStatus.MarkPaid &&
        !payment.markPaid
    );
  }, [paymentAlerts, payment]);

  return payment.status === PaymentStatus.NotPaid ? (
    <NotPaidPaymentDetailsDrawerContent
      payment={payment}
      payeeCurrentBankAccount={bankAccountDetails}
      refresh={refresh}
      showPayButton={showPayButton}
      setIsOpen={setIsOpen}
      paymentInvoice={paymentInvoice}
      paymentExpense={paymentExpense}
      markAsPaid={markAsPaid}
    />
  ) : (
    <AttemptedPaymentDetailsDrawerContent
      payment={payment}
      payeeCurrentBankAccount={bankAccountDetails}
      paymentInvoice={paymentInvoice}
      showPayButton={showPayButton}
      paymentExpense={paymentExpense}
      refresh={refresh}
      markAsPaid={markAsPaid}
    />
  );
};

interface PaymentDetailsDrawerContentProps {
  readonly payment: PaymentDto;
  readonly payeeCurrentBankAccount: BasicBankAccountDetails | undefined;
  readonly refresh: () => Promise<void>;
  readonly showPayButton: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly paymentInvoice: ContractorInvoice | null;
  readonly paymentExpense: Expense | null;
  readonly markAsPaid: () => Promise<void>;
}

interface AttemptedPaymentDetailsContentProps {
  readonly payment: PaymentDto;
  readonly payeeCurrentBankAccount: BasicBankAccountDetails | undefined;
  readonly paymentInvoice: ContractorInvoice | null;
  readonly showPayButton: boolean;
  readonly paymentExpense: Expense | null;
  readonly markAsPaid: () => Promise<void>;
  readonly refresh: () => Promise<void>;
}

export const NotPaidPaymentDetailsDrawerContent = ({
  payment,
  payeeCurrentBankAccount,
  refresh,
  showPayButton,
  setIsOpen,
  paymentInvoice,
  paymentExpense,
  markAsPaid,
}: PaymentDetailsDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [, setState] = usePaymentContext();
  const routerHistory = useHistory();

  const getPaymentsAllActionsOptions = useMemo((): readonly OptionProps[] => {
    const finalOptions = [
      {
        icon: <Chose {...iconSize} />,
        handler: markAsPaid,
        label: 'Mark as paid',
      },
    ];

    if (payment.category === PaymentCategory.Expense) {
      // add reject option
      const rejectOption = {
        icon: <></>,
        handler: async () => {
          try {
            setLoading(true);
            await PaymentsAPI.rejectUnderlyingExpense(payment.id);
            showMessage('Rejected related expense', 'success');
            await refresh();
            setIsOpen(false);
          } catch (error) {
            showMessage(
              polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }),
              'error'
            );
          } finally {
            setLoading(false);
          }
        },
        label: 'Reject',
        disabled: false,
      };
      finalOptions.push(rejectOption);
    }

    if (payment.category === PaymentCategory.Invoice) {
      // add void option
      const voidOption = {
        icon: <></>,
        handler: async () => {
          try {
            setLoading(true);
            await PaymentsAPI.voidUnderlyingInvoice(payment.id);
            showMessage('Voided related invoice', 'success');
            await refresh();
            setIsOpen(false);
          } catch (error) {
            showMessage(
              polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }),
              'error'
            );
          } finally {
            setLoading(false);
          }
        },
        label: 'Void',
        disabled: false,
      };
      finalOptions.push(voidOption);
    }

    return finalOptions;
  }, [polyglot, markAsPaid, payment.category, payment.id, showMessage, refresh, setIsOpen]);

  return (
    <Box sx={drawerContentSx}>
      <ViewerItem title="Status" value={getPaymentStatus(polyglot, payment, { ...themeFonts.title4 })} />
      <ViewerItem title="Category" value={payment.category} />
      <ViewerItem title="Reference" value={payment.reference} />
      <ViewerItem title="Amount" value={`${formatCurrency(payment.amount, undefined, payment.currency)}`} />
      {payment.dueDate && <ViewerItem title="Due date" value={getDateString(payment.dueDate) as string} />}
      {payment.transaction?.updatedAt && (
        <ViewerItem title="Payment date" value={getDateString(payment.transaction?.updatedAt) as string} />
      )}

      {paymentInvoice && <PaymentInvoicePreview invoice={paymentInvoice} titleSx={{ my: '10px' }} />}
      {paymentExpense && <PaymentExpensePreview expense={paymentExpense} titleSx={{ my: '10px' }} />}

      {!paymentPaidOrMarkedPaid(payment) && (
        <Box>
          <Typography variant="title4" sx={{ mt: '10px', mb: '10px' }}>
            Current bank details
          </Typography>
          {payeeCurrentBankAccount ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {payeeCurrentBankAccount.bankName && (
                <ViewerItem title="Bank name" value={payeeCurrentBankAccount.bankName} />
              )}
              <ViewerItem title="Account name" value={payeeCurrentBankAccount.accountName} />
              <ViewerItem title="Account number" value={payeeCurrentBankAccount.accountNumber} />
              <ViewerItem title="Sort code" value={payeeCurrentBankAccount.sortCode} />
            </Box>
          ) : (
            <Typography variant="caption" sx={spacing.mt40}>
              Bank account details not available
            </Typography>
          )}
        </Box>
      )}

      <Box sx={buttonBoxDrawerSx}>
        <Box>
          <StyledMenuComponent
            disabled={loading}
            options={getPaymentsAllActionsOptions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'medium',
              title: 'Actions',
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
          />
        </Box>
        {showPayButton && (
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            disabled={loading}
            fullWidth
            onClick={() => {
              setState(() => {
                return {
                  payments: [payment],
                  requests: [getRequestFromPayment(payment)],
                };
              });
              return routerHistory.push(PAYMENTS_SELECT_BANK_ROUTE);
            }}
          >
            Pay
          </ButtonComponent>
        )}
      </Box>
    </Box>
  );
};

export const AttemptedPaymentDetailsDrawerContent = ({
  payment,
  payeeCurrentBankAccount,
  paymentExpense,
  paymentInvoice,
  markAsPaid,
  showPayButton,
  refresh,
}: AttemptedPaymentDetailsContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const { data: fullPaymentRequest, error } = useApiClient(
    payment.transactionId ? PaymentsEndpoints.getTransactionPaymentRequest(payment.transactionId) : { url: undefined }
  );

  const [isPaymentsGroupOpen, setIsPaymentsGroupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setState] = usePaymentContext();

  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = payment.userId ? getScopesContext({ userId: payment.userId }) : undefined;
  const isManagerOrAdmin = hasScopes(['payments:manager'], scopesContext);

  const paymentRequest = useMemo(() => {
    if (payment.transaction?.type === TransactionType.BulkPayment && fullPaymentRequest?.payments) {
      return (
        fullPaymentRequest.payments.find(
          (paymentRequest) =>
            paymentRequest.reference === payment.reference && paymentRequest.amount.amount === payment.amount
        ) ?? null
      );
    }

    if (payment.transaction?.type === TransactionType.DomesticSinglePayment) {
      return fullPaymentRequest ?? null;
    }

    return null;
  }, [fullPaymentRequest, payment]);

  useEffect(() => {
    if (error) {
      showMessage(`Failed to fetch payment request. ${error.data?.message || error.statusText}`, 'error');
    }
  }, [error, showMessage]);

  const transactionAmount = useMemo(() => paymentRequest?.amount.amount, [paymentRequest]);
  const transactionCurrency = useMemo(() => paymentRequest?.amount.currency, [paymentRequest]);
  const transactionAccountName = useMemo(() => paymentRequest?.payee.name || payment.paidToAccountName, [
    paymentRequest,
    payment,
  ]);
  const transactionAccountNumber = useMemo(
    () =>
      paymentRequest?.payee.accountIdentifications.find(
        (identificationObj) => identificationObj.type === 'ACCOUNT_NUMBER'
      )?.identification ?? payment.paidToAccountNumber,
    [paymentRequest, payment]
  );
  const transactionSortCode = useMemo(
    () =>
      paymentRequest?.payee.accountIdentifications.find((identificationObj) => identificationObj.type === 'SORT_CODE')
        ?.identification ?? payment.paidToSortCode,
    [paymentRequest, payment]
  );
  const transactionReference = useMemo(() => paymentRequest?.reference, [paymentRequest]);

  const transactionAccountDetails = useMemo(() => {
    return transactionAccountName && transactionAccountNumber && transactionSortCode
      ? {
          accountName: transactionAccountName,
          accountNumber: transactionAccountNumber,
          sortCode: transactionSortCode,
        }
      : undefined;
  }, [transactionAccountName, transactionAccountNumber, transactionSortCode]);

  const isDifferentAccountReason = useMemo((): string | null => {
    if (payment.markPaid) return null;

    if (!transactionAccountDetails) {
      return 'Missing transaction account details.';
    }

    if (!payeeCurrentBankAccount) {
      return 'Missing current account details.';
    }

    if (transactionAccountDetails.accountName !== payeeCurrentBankAccount.accountName) {
      return 'The transaction bank account name and the current bank account name are different.';
    }

    if (transactionAccountDetails.accountNumber !== payeeCurrentBankAccount.accountNumber) {
      return 'The transaction bank account number and the current bank account number are different.';
    }
    if (transactionAccountDetails.sortCode !== payeeCurrentBankAccount.sortCode) {
      return 'The transaction bank account sort code and the current bank account sort code are different.';
    }
    return null;
  }, [payment, payeeCurrentBankAccount, transactionAccountDetails]);

  const showTransactionRefresh = useMemo(
    () => isManagerOrAdmin && payment.transaction && payment.transaction?.status === TransactionStatus.PENDING,
    [payment, isManagerOrAdmin]
  );

  const refreshPaymentTransaction = useCallback(async () => {
    if (!showTransactionRefresh) return;
    try {
      await PaymentsAPI.refreshPaymentTransactionByPaymentId(payment.id, payment.userId ?? null);

      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, showMessage, payment, showTransactionRefresh, refresh]);

  const isTransactionOlderThan1Hour = useMemo(() => {
    if (!payment?.transaction?.createdAt) return false;
    const timeDiff = Date.now() - new Date(payment.transaction.createdAt).getTime();

    return timeDiff > 3600000; // 1 HOUR in milliseconds
  }, [payment]);

  const disablePayAndMarkAsPaid = useMemo(() => {
    return (
      !isTransactionOlderThan1Hour && payment.transaction && payment.transaction.status === TransactionStatus.PENDING
    );
  }, [isTransactionOlderThan1Hour, payment.transaction]);

  const getPaymentsAllActionsOptions = useMemo((): readonly OptionProps[] => {
    return [
      {
        icon: <Chose {...iconSize} />,
        handler: async () => {
          setLoading(true);
          await markAsPaid();
          setLoading(false);
        },
        label: 'Mark as paid',
        disabled: disablePayAndMarkAsPaid,
      },
      {
        icon: <Reload {...iconSize} />,
        handler: async () => {
          setLoading(true);
          await refreshPaymentTransaction();
          setLoading(false);
        },
        label: 'Refresh transaction status',
        hidden: !showTransactionRefresh,
      },
    ];
  }, [disablePayAndMarkAsPaid, markAsPaid, showTransactionRefresh, refreshPaymentTransaction]);

  return (
    <Box sx={drawerContentSx}>
      <ViewerItem title="Status" value={getPaymentStatus(polyglot, payment, themeFonts.title4, true)} />
      <ViewerItem title="Category" value={payment.category} />
      <ViewerItem title="Reference" value={payment.reference} />
      <ViewerItem title="Amount" value={`${formatCurrency(payment.amount, undefined, payment.currency)}`} />

      {payment.paidByUser && payment.paidOn && (
        <ViewerItem title="Paid by" value={<UserCell userId={payment.paidByUser.userId} />} />
      )}
      {payment.paidOn && <ViewerItem title="Paid on" value={new Date(payment.paidOn).toLocaleString()} />}

      {payment.transaction && (
        <ViewerItem
          title="Transaction type"
          value={payment.transaction.type ? transactionTypeToLabel(payment.transaction.type) : 'N/A'}
        />
      )}

      {payment.transactionId && payment.isPartOfGroup && (
        <ViewerItem
          title="Transaction includes multiple payments"
          value="See all payments"
          valueOnClick={() => {
            setIsPaymentsGroupOpen(true);
          }}
        />
      )}
      {payment.transactionId && payment.isPartOfGroup && (
        <PaymentsGroupDrawer
          isOpen={isPaymentsGroupOpen}
          setIsOpen={setIsPaymentsGroupOpen}
          transactionId={payment.transactionId}
        />
      )}

      {payment.transaction && transactionAmount && transactionCurrency && transactionAmount !== payment.amount && (
        <ViewerItem title="Transaction amount" value={`${transactionCurrency} ${transactionAmount}` ?? 'N/A'} />
      )}
      {payment.transaction && transactionReference && transactionReference !== payment.reference && (
        <ViewerItem title="Transaction reference" value={transactionReference ?? 'N/A'} />
      )}

      {transactionAccountDetails && (
        <>
          <Typography variant="title4" sx={{ mt: spacing.m10 }}>
            Payment was made to
          </Typography>
          {payment.bankName && <ViewerItem title="Bank name" value={payment.bankName} />}
          <ViewerItem title="Account name" value={transactionAccountName ?? 'N/A'} />
          <ViewerItem title="Account number" value={transactionAccountNumber ?? 'N/A'} />
          <ViewerItem title="Sort code" value={transactionSortCode ?? 'N/A'} />
        </>
      )}

      {!paymentPaidOrMarkedPaid(payment) && (
        <Box>
          <Typography variant="title4" sx={{ mt: '10px' }}>
            Current bank details
          </Typography>
          {payeeCurrentBankAccount ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15 }}>
              {payeeCurrentBankAccount.bankName && (
                <ViewerItem title="Bank name" value={payeeCurrentBankAccount.bankName} />
              )}
              <ViewerItem title="Account name" value={payeeCurrentBankAccount.accountName} />
              <ViewerItem title="Account number" value={payeeCurrentBankAccount.accountNumber} />
              <ViewerItem title="Sort code" value={payeeCurrentBankAccount.sortCode} />
            </Box>
          ) : (
            <Typography variant="caption" sx={{ mt: '10px' }}>
              Bank account details not available
            </Typography>
          )}
        </Box>
      )}

      {paymentInvoice && <PaymentInvoicePreview invoice={paymentInvoice} titleSx={{ my: '10px' }} />}

      {paymentExpense && <PaymentExpensePreview expense={paymentExpense} titleSx={{ my: '10px' }} />}

      {isDifferentAccountReason && (
        <Box sx={spacing.mt40}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: spacing.g5, width: '100%' }}>
            <Box>
              {payment.transaction?.status === TransactionStatus.COMPLETED ? (
                <Info {...iconSize} fill={themeColors.ZeltYellow} />
              ) : (
                <ErrorIcon {...iconSize} />
              )}
            </Box>
            <Typography variant="caption">
              The current payee account details and the account details the transaction has been attempted for are
              missing or are not the same anymore.
            </Typography>
          </Box>
          <Typography variant="caption" sx={{ mt: spacing.m10 }}>
            Reason: {isDifferentAccountReason}
          </Typography>
        </Box>
      )}

      {showPayButton && (
        <Box sx={buttonBoxDrawerSx}>
          <Box sx={{ width: '100%' }}>
            <StyledMenuComponent
              disabled={loading}
              options={getPaymentsAllActionsOptions}
              actionButtonDetails={{
                type: 'button',
                colorVariant: 'secondary',
                sizeVariant: 'medium',
                title: 'Actions',
                icon: <ArrowDown {...iconSize} />,
                iconPosition: 'end',
                fullWidth: true,
              }}
            />
          </Box>

          {showPayButton && (
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="primary"
              disabled={loading || disablePayAndMarkAsPaid}
              fullWidth
              onClick={() => {
                setState(() => {
                  return {
                    payments: [payment],
                    requests: [getRequestFromPayment(payment)],
                  };
                });
                return routerHistory.push(PAYMENTS_SELECT_BANK_ROUTE);
              }}
            >
              Pay
            </ButtonComponent>
          )}
        </Box>
      )}
    </Box>
  );
};
