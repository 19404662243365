import { useCallback, useMemo, useState } from 'react';

import { Alert, Box, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { Typography } from '@v2/components/typography/typography.component';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { nestErrorMessage } from '@/lib/errors';
import { LOGIN_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { borders } from '@/v2/styles/borders.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const states = {
  FORGOT: 0,
  SENT: 1,
  CHOOSE: 2,
};

interface FormData {
  readonly email: string;
}

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email address').required('Email is required'),
});

export const AuthForgotPasswordPage = () => {
  const routerHistory = useHistory();
  const [pageState, setPageState] = useState(states.FORGOT);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState(-1);
  const [sentAddress, setSentAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // the initial email value is passed in from the /login page
  const initialEmail = useMemo(() => (routerHistory.location.state as { email: string })?.email || '', [routerHistory]);

  const requestPasswordResetLink = useCallback(async (email: string, selectedEmailIdx: number, sentAddress: string) => {
    const payload: { email: string; emailIndex: number } = {
      email,
      emailIndex: selectedEmailIdx,
    };
    const emails = (await AuthAPI.forgotPassword(payload))?.emails;
    if (emails) {
      if (emails.length > 1) {
        setEmails(emails);
        setPageState(states.CHOOSE);
      } else {
        payload.email = email;
        payload.emailIndex = 0;
        await AuthAPI.forgotPassword(payload);
        setSentAddress(sentAddress);
        setPageState(states.SENT);
      }
    } else {
      setSentAddress(sentAddress);
      setPageState(states.SENT);
    }
  }, []);

  const formik = useFormik<FormData>({
    initialValues: {
      email: initialEmail,
    },
    validationSchema: validationSchema,
    onSubmit: async (formData: FormData) => {
      setIsLoading(true);
      setErrorMessage('');
      try {
        await requestPasswordResetLink(formData.email, selectedEmail, emails[selectedEmail] || formData.email);
      } catch (error) {
        setErrorMessage(`The password reset link could not be sent. ${nestErrorMessage(error)}`);
      }
      setIsLoading(false);
    },
  });

  const { title, description } = {
    [states.FORGOT]: {
      title: 'Password recovery',
      description: 'Enter your work email to receive a password reset link',
    },
    [states.CHOOSE]: {
      title: 'Select email address',
      description: 'We found multiple email addresses. Where would you like to receive the password reset link?',
    },
    [states.SENT]: {
      title: 'Password recovery',
      description: `If you have an account in Zelt, we will send the reset instructions to ${sentAddress} shortly`,
    },
  }[pageState];

  const returnToLogin = useCallback(() => {
    routerHistory.replace(LOGIN_ROUTE);
  }, [routerHistory]);

  return (
    <AuthLayout title={title} description={description} submit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        {pageState === states.FORGOT && (
          <Stack sx={{ gap: spacing.g30 }}>
            <TextfieldComponent
              label="Work email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              type="email"
              autoFocus
              clearText={() => formik.setFieldValue('email', '')}
            />
            <Box sx={{ width: { xs: '50%', sm: '100%', md: '100%', lg: '100%' } }}>
              <LoaderButton
                name="Send the password reset link"
                type="submit"
                loading={isLoading}
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          </Stack>
        )}
        {pageState === states.SENT && (
          <Box sx={{ width: { xs: '50%', sm: '100%', md: '100%', lg: '100%' } }}>
            <ButtonComponent
              type="button"
              sizeVariant="medium"
              colorVariant="primary"
              fullWidth
              style={{ marginTop: spacing.m10 }}
              onClick={() => returnToLogin()}
            >
              OK
            </ButtonComponent>
          </Box>
        )}
        {pageState === states.CHOOSE && emails.length > 0 && (
          <Stack sx={{ gap: spacing.g30 }}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => {
                  setSelectedEmail(parseInt(event.target.value));
                }}
              >
                {
                  // the order in which emails are displayed is important - the indexes are directly
                  // tied to the order that the backend uses.
                  emails.map((email, idx) => (
                    <FormControlLabel
                      key={email}
                      labelPlacement="start"
                      value={idx}
                      checked={selectedEmail === idx}
                      control={<StyledRadio />}
                      label={<Typography variant="caption">{email}</Typography>}
                      sx={{
                        justifyContent: 'space-between',
                        borderBottom: borders.middle,
                        py: spacing.p10,
                      }}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
            <Box sx={{ width: { xs: '50%', sm: '100%', md: '100%', lg: '100%' } }}>
              <LoaderButton
                name="Send the password reset link"
                type="submit"
                fullWidth
                loading={isLoading}
                disabled={selectedEmail < 0}
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          </Stack>
        )}
      </FormikProvider>
      {errorMessage && !isLoading && (
        <Alert severity="error" sx={{ my: 2 }}>
          {errorMessage}
        </Alert>
      )}
    </AuthLayout>
  );
};
