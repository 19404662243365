import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { SchedulePayrollFormData } from '@v2/feature/attendance/attendance.interface';
import { getSchedulePayrollValidationSchema } from '@v2/feature/attendance/attendance.util';
import { drawerContentSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
}

export const AttendanceScheduleEditPayrollDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AttendanceScheduleEditPayrollDrawerContent
        attendanceSchedule={attendanceSchedule}
        refresh={refresh}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AttendanceScheduleEditPayrollDrawerContent = ({
  attendanceSchedule,
  refresh,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: SchedulePayrollFormData) => {
      try {
        const update: SchedulePayrollFormData = {
          includedInPayroll: values.includedInPayroll,
          payCode: values.payCode ? values.payCode : null,
        };
        setLoading(true);
        await AttendanceAPI.updateAttendanceSchedulePayroll(attendanceSchedule.id, update);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AttendanceScheduleEditSettingsDrawer.errorMessages.update', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [attendanceSchedule.id, showMessage, refresh, setIsOpen, polyglot]
  );

  const formik = useFormik<SchedulePayrollFormData>({
    initialValues: {
      includedInPayroll: attendanceSchedule.includedInPayroll,
      payCode: attendanceSchedule.payCode ?? '',
    },
    validationSchema: getSchedulePayrollValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PayrollModule.payroll')}</Typography>

        <Box>
          <CheckboxComponent
            name="includedInPayroll"
            label={polyglot.t('PayrollModule.includedInPayroll')}
            checked={formik.values.includedInPayroll}
            onChange={formik.handleChange}
          />
        </Box>
        <Box sx={fieldSx}>
          <TextfieldComponent
            label={polyglot.t('PayItemModule.payCode')}
            name="payCode"
            value={formik.values.payCode}
            onChange={formik.handleChange}
            error={formik.touched.payCode && !!formik.errors.payCode}
            helperText={((formik.touched.payCode && formik.errors.payCode) as string) ?? (' ' as string)}
            endAdornment="none"
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="primary" loading={loading} fullWidth>
            {polyglot.t('General.save')}
          </ButtonComponent>
        </Box>
      </Form>
    </FormikProvider>
  );
};
