import React from 'react';

import { Box, IconButton } from '@mui/material';

import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as NewWindow } from '@/images/new-theme-icon/NewWindow.svg';
import { ReactComponent as FullScreen } from '@/images/side-bar-icons/FullScreen.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconCrossButtonSx, iconCrossPrimaryButtonSx } from '@/v2/styles/icon-button.styles';
import { ProfileStyledSwipeableDrawer } from '@/v2/styles/sidebar-edit.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  openProfile?: () => void;
  openInNewWindow?: () => void;
  children?: JSX.Element;
}

export const ProfileModal = ({ isOpen, setIsOpen, onClose, openProfile, openInNewWindow, children }: props) => {
  return (
    <ProfileStyledSwipeableDrawer
      anchor="right"
      open={isOpen && !!children}
      transitionDuration={400}
      PaperProps={{
        sx: { width: { xs: 'auto', sm: 440, md: 440, lg: 440, xl: 440 } },
      }}
      onOpen={() => {
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
      onClose={onClose}
    >
      <>
        <Box sx={{ position: 'fixed', right: 450, top: 10 }}>
          <IconButton sx={{ ...iconCrossButtonSx, background: themeColors.white }} onClick={onClose}>
            <Close fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
          </IconButton>
        </Box>

        {openInNewWindow && (
          <Box sx={{ position: 'fixed', right: 450, top: 60 }}>
            <IconButton sx={{ ...iconCrossButtonSx, background: themeColors.white }} onClick={openInNewWindow}>
              <NewWindow />
            </IconButton>
          </Box>
        )}

        {openProfile && (
          <Box sx={{ position: 'fixed', right: 450, top: 110 }}>
            <IconButton sx={iconCrossPrimaryButtonSx} onClick={openProfile}>
              <FullScreen />
            </IconButton>
          </Box>
        )}
        <Box sx={{ ...spacing.px40, py: spacing.p30, position: 'relative' }}>{children}</Box>
      </>
    </ProfileStyledSwipeableDrawer>
  );
};
