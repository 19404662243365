import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box, Button, Stack } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { IconButton } from '@v2/components/forms/icon-button.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { MultiUserAvatar } from '@v2/components/theme-components/multi-user-avatar.component';
import { SpecificUserModal } from '@v2/components/user-select-type/components/specific-user-modal.component';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { ApprovalStep } from '@v2/feature/approval-rule/approval-rule.interface';
import { getApprovalStepSchema } from '@v2/feature/approval-rule/approval-rule.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { styledChipSx } from '@v2/styles/chip.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly addStep: (data: ApprovalStep, index: number | null) => void;
  readonly existingSteps: ApprovalStep[];
  readonly step: ApprovalStep | null;
  readonly stepIndex: number | null;
  readonly afterClose: () => void;
}

export const AddApprovalStepDrawer = ({
  isOpen,
  setIsOpen,
  addStep,
  existingSteps,
  step,
  stepIndex,
  afterClose,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      <ApprovalRuleDrawerContent
        setIsOpen={setIsOpen}
        addStep={addStep}
        existingSteps={existingSteps}
        step={step}
        stepIndex={stepIndex}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly addStep: (data: ApprovalStep, index: number | null) => void;
  readonly existingSteps: ApprovalStep[];
  readonly step: ApprovalStep | null;
  readonly stepIndex: number | null;
}

const ApprovalRuleDrawerContent = ({
  setIsOpen,
  addStep,
  existingSteps,
  step,
  stepIndex,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isSpecificOpen, setSpecificIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState<UserSelectFiltersOptions>(UserSelectFiltersOptions.None);

  const onSubmit = useCallback(
    async (values: ApprovalStep) => {
      if (
        !values.adminApproval &&
        !values.managerManagerApproval &&
        !values.managerApproval &&
        values.specificApprovers.length === 0
      ) {
        showMessage('Please select at least 1 approvers group.', 'error');
        return;
      }
      const ruleData: ApprovalStep = {
        adminApproval: values.adminApproval,
        managerManagerApproval: values.managerManagerApproval,
        managerApproval: values.managerApproval,
        specificApprovers: values.specificApprovers,
        minApprovers: Number(values.minApprovers),
      };

      addStep(ruleData, stepIndex);

      setIsOpen(false);
    },
    [showMessage, setIsOpen, addStep, stepIndex]
  );

  const { showAdmin, showManager, showManagersManager, alreadySelectedUsers } = useMemo(() => {
    let showAdmin = true;
    let showManager = true;
    let showManagersManager = true;
    let alreadySelectedUsers = [];

    for (const [index, currentStep] of existingSteps.entries()) {
      // if editing step, ignore the step being edited
      if (step && index === stepIndex) continue;

      if (currentStep.adminApproval) showAdmin = false;
      if (currentStep.managerApproval) showManager = false;
      if (currentStep.managerManagerApproval) showManagersManager = false;
      if (currentStep.specificApprovers) alreadySelectedUsers.push(...currentStep.specificApprovers);
    }

    return { showAdmin, showManager, showManagersManager, alreadySelectedUsers };
  }, [stepIndex, step, existingSteps]);

  const formik = useFormik<ApprovalStep>({
    initialValues: {
      adminApproval: step?.adminApproval ?? false,
      managerManagerApproval: step?.managerManagerApproval ?? false,
      managerApproval: step?.managerApproval ?? false,
      specificApprovers: step?.specificApprovers ?? [],
      minApprovers: step?.minApprovers ?? 1,
    },
    validationSchema: getApprovalStepSchema(polyglot),
    onSubmit,
  });

  const setRuleValue = (fieldName: string, value: boolean | null | number[]): void => {
    formik.setFieldValue(fieldName, value);
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('ApprovalRuleModule.newStep')}</Typography>

        <Box>
          <Typography variant="title4" sx={{ mb: '5px' }}>
            Who should approve?
          </Typography>
          <Typography variant="caption">Select from specific employees or placeholders</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
          {!formik.values.adminApproval && showAdmin && (
            <ButtonComponent
              sizeVariant="filter"
              colorVariant="secondary"
              onClick={() => setRuleValue('adminApproval', true)}
            >
              {polyglot.t('ApproverSelectComponent.Admin')}
            </ButtonComponent>
          )}

          {!formik.values.managerManagerApproval && showManagersManager && (
            <ButtonComponent
              sizeVariant="filter"
              colorVariant="secondary"
              onClick={() => setRuleValue('managerManagerApproval', true)}
            >
              {polyglot.t('ApproverSelectComponent.ManagersManager')}
            </ButtonComponent>
          )}

          {!formik.values.managerApproval && showManager && (
            <ButtonComponent
              sizeVariant="filter"
              colorVariant="secondary"
              onClick={() => setRuleValue('managerApproval', true)}
            >
              {polyglot.t('ApproverSelectComponent.Manager')}
            </ButtonComponent>
          )}

          {(!formik.values?.specificApprovers || formik.values.specificApprovers.length === 0) && (
            <Button sx={styledChipSx} onClick={() => setSpecificIsOpen(true)}>
              {polyglot.t('ApproverSelectComponent.selectSpecific')}
            </Button>
          )}

          <SpecificUserModal
            isSpecificOpen={isSpecificOpen}
            setSpecificIsOpen={setSpecificIsOpen}
            label={polyglot.t('UserSelect.specific')}
            value={formik.values.specificApprovers ?? []}
            onChange={(userIds: number[]) => {
              setRuleValue('specificApprovers', userIds);
            }}
            filterValue={filterValue}
            setLastValidFilterValue={setFilterValue}
            excludeUsers={alreadySelectedUsers}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {formik.values.adminApproval && (
            <SelectedChip
              label={polyglot.t('ApproverSelectComponent.Admin')}
              onClick={() => setRuleValue('adminApproval', false)}
            />
          )}
          {formik.values.managerManagerApproval && (
            <SelectedChip
              label={polyglot.t('ApproverSelectComponent.ManagersManager')}
              onClick={() => setRuleValue('managerManagerApproval', false)}
            />
          )}
          {formik.values.managerApproval && (
            <SelectedChip
              label={polyglot.t('ApproverSelectComponent.Manager')}
              onClick={() => setRuleValue('managerApproval', false)}
            />
          )}
          {formik.values.specificApprovers.length > 0 && (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
              <MultiUserAvatar userIds={formik.values.specificApprovers ?? []} showLimit={5} />
              <ButtonComponent
                sizeVariant="filter"
                colorVariant="secondary"
                style={{ marginLeft: 'auto', marginRight: '10px' }}
                onClick={() => setSpecificIsOpen(true)}
              >
                {polyglot.t('ApproverSelectComponent.selectSpecific')}
              </ButtonComponent>
              <IconButton
                colorVariant="secondary"
                sizeVariant="small"
                onClick={() => setRuleValue('specificApprovers', [])}
              >
                <Trash {...iconSize} />
              </IconButton>
            </Stack>
          )}
        </Box>

        <TextfieldComponent
          label="How many of them must approve?"
          name="minApprovers"
          value={formik.values.minApprovers}
          onChange={formik.handleChange}
          error={formik.touched.minApprovers && !!formik.errors.minApprovers}
          helperText={((formik.touched.minApprovers && formik.errors.minApprovers) as string) ?? ''}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth>
            {polyglot.t('General.save')}
          </ButtonComponent>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export const SelectedChip = ({ label, onClick }: { label: string; onClick: () => void }) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="title4">{label}</Typography>
      <Box>
        <IconButton colorVariant="secondary" sizeVariant="small" onClick={onClick}>
          <Trash {...iconSize} />
        </IconButton>
      </Box>
    </Box>
  );
};
