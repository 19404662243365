import { useCallback, useContext, useEffect, useState } from 'react';

import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { AbsenceAPI } from '@/v2/feature/absence/absence.api';
import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import { AbsenceDrawer } from '@/v2/feature/absence/sections/absence-drawer/absence-drawer.section';
import { AbsenceViewDrawer } from '@/v2/feature/absence/sections/absence-drawer/absence-view-drawer.page';

export const TimeDrawerContent = ({
  absenceId,
  userId,
  refresh,
  afterClose,
}: {
  absenceId: number;
  userId: number;
  refresh: () => Promise<void>;
  afterClose: () => void;
}) => {
  const { polyglot } = usePolyglot();

  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const [selectedAbsence, setSelectedAbsence] = useState<AbsenceDto | undefined>(undefined);
  const [isViewAbsenceDrawerOpen, setIsViewAbsenceDrawerOpen] = useState<boolean>(false);
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const isAdmin = hasScopes(['absence:all'], getScopesContext(user));

  const getTimeById = useCallback(async () => {
    try {
      setLoading(true);
      const absence = await AbsenceAPI.getAbsenceById(Number(absenceId), Number(userId));
      setSelectedAbsence(absence ?? null);
      setIsViewAbsenceDrawerOpen(true);
    } catch (error) {
      console.error(error);
      setSelectedAbsence(undefined);
    } finally {
      setLoading(false);
    }
  }, [absenceId, userId]);

  useEffect(() => {
    getTimeById();
  }, [getTimeById]);

  const approveAbsence = useCallback(
    async (a: AbsenceDto): Promise<AbsenceDto | undefined> => {
      try {
        const [updatedAbsence] = await AbsenceAPI.approveAllAbsences([a.absenceId]);
        showMessage(polyglot.t('TimeDrawerContent.successMessages.approve'), 'success');
        await refresh();
        setIsViewAbsenceDrawerOpen(false);
        return updatedAbsence;
      } catch (err) {
        showMessage('Something went wrong.', 'error');
      }
    },
    [refresh, showMessage, polyglot]
  );

  const rejectAbsence = useCallback(
    async (absence: AbsenceDto, rejectionNotes?: string): Promise<AbsenceDto | undefined> => {
      try {
        const [updatedAbsence] = await AbsenceAPI.rejectAllAbsences([absence.absenceId], rejectionNotes);
        showMessage(polyglot.t('TimeDrawerContent.successMessages.reject'), 'success');
        await refresh();
        setIsViewAbsenceDrawerOpen(false);
        return updatedAbsence;
      } catch (err) {
        showMessage(polyglot.t('TimeDrawerContent.errorMessages.badRequest'), 'error');
      }
    },
    [refresh, showMessage, polyglot]
  );

  const handleRejectCancellation = useCallback(
    async (a: AbsenceDto) => {
      try {
        await AbsenceAPI.rejectTimeCancellationRequests([a.absenceId], a.userId);
        showMessage(polyglot.t('TimeDrawerContent.successMessages.cancel'), 'success');
        await refresh();
        setIsViewAbsenceDrawerOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('TimeDrawerContent.errorMessages.badRequestMessage', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [showMessage, refresh, polyglot]
  );

  const onForceApproval = useCallback(
    async (absence: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => {
      try {
        await AbsenceAPI.forceApprovalByAbsencesIds([absence.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved
              ? 'PersonalAbsence.successMessages.approve'
              : 'PersonalAbsence.successMessages.reject'
          ),
          'success'
        );
        await refresh();
        setIsViewAbsenceDrawerOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('PersonalAbsence.errorMessages.cancel', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [refresh, showMessage, polyglot]
  );

  const removeRequestHelper = useCallback(
    async (absence: AbsenceDto) => {
      if (absence) {
        try {
          await AbsenceAPI.deleteAbsenceRecord(absence.absenceId);
          showMessage(polyglot.t('TimeDrawerContent.successMessages.remove'), 'success');

          await refresh();
          setIsViewAbsenceDrawerOpen(false);
        } catch (error) {
          showMessage(
            polyglot.t('TimeDrawerContent.errorMessages.remove', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      }
    },
    [refresh, showMessage, polyglot]
  );

  return (
    <>
      <AbsenceDrawer
        isOpen={isAbsenceDrawerOpen}
        onClose={() => {
          setIsAbsenceDrawerOpen(false);
        }}
        afterClose={() => {
          if (afterClose) afterClose();
          setSelectedAbsence(undefined);
        }}
        refresh={refresh}
        absence={selectedAbsence}
        setIsAbsenceDrawerOpen={setIsAbsenceDrawerOpen}
        reach={isAdmin ? 'company' : 'team'}
      />

      <AbsenceViewDrawer
        isOpen={isViewAbsenceDrawerOpen}
        absence={selectedAbsence as AbsenceDto}
        setIsAbsenceDrawerViewOpen={setIsViewAbsenceDrawerOpen}
        handleApprove={approveAbsence}
        handleReject={rejectAbsence}
        onDelete={removeRequestHelper}
        onRejectCancellation={handleRejectCancellation}
        onForceApproval={onForceApproval}
        onEdit={(absence: AbsenceDto) => {
          setSelectedAbsence(absence);
          setIsAbsenceDrawerOpen(true);
        }}
        showCalendarLink
        afterClose={afterClose}
        loading={loading}
        refresh={refresh}
      />
    </>
  );
};
