import React, { useMemo } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteComponent } from '@v2/components/forms/autocomplete.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { OptionObj } from '@v2/components/forms/user-select/single-user-select.component';
import { FilterValueSelectorProps } from '@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component';
import { NotImplemented } from '@v2/feature/reports/reports-advanced/filters-components/not-implemented.component';
import { ReportSQLOperator } from '@v2/feature/reports/reports.interface';

export const IntOptionSelector = ({
  operator,
  value,
  setValue,
  label,
  filterOptions,
}: Omit<FilterValueSelectorProps, 'filterType'>) => {
  const IntOptionOperatorsWithNoExtraField = useMemo(() => {
    return new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);

  return operator === ReportSQLOperator.eq ? (
    <SelectComponent
      name="value"
      label={label}
      options={filterOptions ?? []}
      value={value as number}
      onChange={(e) => {
        setValue(Number(e.target.value));
      }}
      fullWidth
    />
  ) : operator === ReportSQLOperator.in ? (
    <AutocompleteComponent
      multiple
      name="value"
      label={label}
      filterSelectedOptions
      value={(filterOptions ?? []).filter((o) => value && (value as number[]).includes(o.value as number))}
      options={filterOptions ?? []}
      onChange={(_, e) => {
        const value = e as OptionObj[];
        setValue(value.map((o) => o.value as number));
      }}
      isOptionEqualToValue={(x, y) => x.value === y.value}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return <Chip label={option.label} {...getTagProps({ index })} />;
        })
      }
    />
  ) : IntOptionOperatorsWithNoExtraField.has(operator) ? null : (
    <NotImplemented />
  );
};
