import { ColumnSort, PaginationState } from '@tanstack/react-table';
import {
  ChecklistItemDto,
  CreateChecklistItemDto,
  LaunchChecklistItemDto,
  UpdateChecklistItemDto,
} from '@v2/feature/task/subfeature/checklist/checklist-item.dto';
import {
  ChecklistDto,
  ChecklistOptionsDto,
  CreateChecklistDto,
  UpdateChecklistDto,
} from '@v2/feature/task/subfeature/checklist/checklist.dto';
import { CreateTaskDto, TaskDto, UpdateTaskDto, UpdateTaskStatusDto } from '@v2/feature/task/task.dto';
import { TaskStatus } from '@v2/feature/task/task.interface';
import axios from 'axios';

import { PaginatedResponse } from '@/models/paginated-response';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export const TaskAPI = {
  async listTasks(assignedUserId?: number, status?: string): Promise<TaskDto[]> {
    return (
      await axios.get<PaginatedResponse<TaskDto>>('/apiv2/tasks', {
        params: { assignedUserId, status },
      })
    ).data.items;
  },

  async listTeamTasks(): Promise<TaskDto[]> {
    return (await axios.get<PaginatedResponse<TaskDto>>('/apiv2/tasks/team')).data.items;
  },

  async getTaskStatuses(): Promise<TaskStatus[]> {
    return (await axios.get<TaskStatus[]>('/apiv2/tasks/statuses')).data;
  },

  async createTask(task: CreateTaskDto): Promise<TaskDto> {
    return (await axios.post<TaskDto>('/apiv2/tasks', task)).data;
  },

  async createTasksInBulk(task: CreateTaskDto): Promise<void> {
    await axios.post('/apiv2/tasks/bulk', task);
  },

  async updateTask(task: UpdateTaskDto): Promise<TaskDto> {
    return (await axios.patch<TaskDto>(`/apiv2/tasks/${task.id}`, task)).data;
  },

  async getTaskById(taskId: number): Promise<TaskDto> {
    return (await axios.get<TaskDto>(`/apiv2/tasks/${taskId}/by-id`)).data;
  },

  async updateTaskStatus(
    taskId: number,
    assignedUserId: number | undefined | null,
    { status }: UpdateTaskStatusDto
  ): Promise<TaskDto> {
    return (
      await axios.patch<TaskDto>(`/apiv2/tasks/status/${assignedUserId}/${taskId}`, {
        status,
      })
    ).data;
  },

  async removeTask(id: number): Promise<void> {
    return axios.delete(`/apiv2/tasks/${id}`);
  },

  async removeTasks(taskIds: number[], forOffboarding = false): Promise<void> {
    let query = '';
    if (forOffboarding) query = '?forOffboarding=true';
    return axios.post(`/apiv2/tasks/bulk-delete${query}`, taskIds);
  },

  async remindAboutTask(taskId: number): Promise<void> {
    return axios.post(`/apiv2/tasks/remind`, { taskId });
  },

  async exportTasks(taskIds?: number[]): Promise<string> {
    return (await axios.post<string>(`/apiv2/tasks/export`, { taskIds }))?.data;
  },

  async addChecklist(checklist: CreateChecklistDto): Promise<ChecklistDto> {
    return (await axios.post('/apiv2/checklists', checklist)).data;
  },

  async getChecklists(): Promise<ChecklistDto[]> {
    return (await axios.get('/apiv2/checklists')).data;
  },

  async getChecklistById(id: number): Promise<ChecklistDto> {
    return (await axios.get(`/apiv2/checklists/${id}`)).data;
  },

  async updateChecklist(checklistId: number, checklist: UpdateChecklistDto): Promise<ChecklistDto> {
    return axios.patch(`/apiv2/checklists/${checklistId}`, checklist);
  },

  async deleteChecklistById(id: number): Promise<void> {
    await axios.delete(`/apiv2/checklists/${id}`);
  },

  async deleteChecklistItemById(checklistId: number, checklistItemId: number): Promise<void> {
    await axios.delete(`/apiv2/checklists/${checklistId}/checklist-items/${checklistItemId}`);
  },

  async addChecklistItemToChecklist(id: number, checklistItem: CreateChecklistItemDto): Promise<ChecklistItemDto> {
    return axios.post(`/apiv2/checklists/${id}`, checklistItem);
  },

  async copyChecklistItemToChecklist(id: number): Promise<ChecklistItemDto> {
    return axios.post(`/apiv2/checklists/${id}/duplicate`);
  },

  async updateChecklistItem(
    checklistId: number,
    checklistItemId: number,
    checklistItem: UpdateChecklistItemDto
  ): Promise<ChecklistItemDto> {
    return axios.patch(`/apiv2/checklists/${checklistId}/checklist-items/${checklistItemId}`, checklistItem);
  },

  async launchChecklistById(id: number, values: LaunchChecklistItemDto): Promise<void> {
    const checklist = { ...values };
    await axios.post(`/apiv2/checklists/${id}/launch`, checklist);
  },

  async getAlerts(userId: number): Promise<Alert<TaskDto>> {
    return (await axios.get(`/apiv2/tasks/${userId}/alerts`)).data;
  },
};

export class TaskEndpoints {
  static listTasks(
    reachOrTaskAssignee: 'company' | 'team' | number,
    { pageIndex, pageSize }: PaginationState,
    options: {
      forOffboarding?: boolean;
      sortField?: ColumnSort;
      status?: string;
      search?: string;
      filterString?: string;
    }
  ): Endpoint<PaginatedResponse<TaskDto>> {
    const urlParams = new URLSearchParams({
      pageIndex: `${pageIndex}`,
      pageSize: `${pageSize}`,
      ...(typeof reachOrTaskAssignee === 'string'
        ? { reach: reachOrTaskAssignee }
        : { assignedUserId: `${reachOrTaskAssignee}` }),
      ...(options?.sortField && { sort: options.sortField.id, sortDesc: `${options.sortField.desc}` }),
      ...(options?.status && { status: options.status }),
      ...(options?.search && { search: options.search }),
      ...(options?.filterString && { filterString: options.filterString }),
      ...(options?.forOffboarding && { forOffboarding: 'true' }),
    });

    return {
      url: `/apiv2/tasks?${urlParams.toString()}`,
    };
  }

  static getAlerts(userId: number): Endpoint<Alert<TaskDto>> {
    return {
      url: `/apiv2/tasks/${userId}/alerts`,
    };
  }

  static getChecklists(): Endpoint<ChecklistDto[]> {
    return {
      url: '/apiv2/checklists',
    };
  }

  static getChecklistsOptions(): Endpoint<ChecklistOptionsDto[]> {
    return {
      url: '/apiv2/checklists/options',
    };
  }
}
