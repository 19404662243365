import { useCallback, useContext, useEffect, useState } from 'react';

import { BenefitsCompanyRouter } from '@v2/feature/benefits/benefits-company.router';
import { BenefitsMeRouter } from '@v2/feature/benefits/benefits-me.router';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionAPI as PensionAPIV2 } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { RootStyle } from '@v2/styles/root.styles';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BENEFITS_COMPANY_ROUTE, BENEFITS_ME_ROUTE } from '@/lib/routes';
import { CustomBenefitAPI } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitDto } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';

export const BenefitsRouter = () => {
  const [state] = useContext(GlobalContext);
  const userId = state.user.userId;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [showMessage] = useMessage();
  const location = useLocation();
  const { companyConfig, isLoading } = useCompanyConfigState();
  const hasPensionAllScope = hasScopes(['pension:all'], scopesContext);
  const hasInsuranceAllScope = hasScopes(['insurance:all'], scopesContext);
  const hasInsuranceScope = hasScopes(['insurance'], scopesContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [pensionSchemes, setPensionSchemes] = useState<readonly PensionSchemeDto[]>([]);
  const [insuranceQuote, setInsuranceQuote] = useState<InsuranceQuoteDto | null>(null);
  const [customBenefits, setCustomBenefits] = useState<CustomBenefitDto[]>([]);
  const [benefitsLoading, setBenefitsLoading] = useState<boolean>(false);
  const [insuranceLoading, setInsuranceLoading] = useState<boolean>(false);
  const [pensionLoading, setPensionLoading] = useState<boolean>(false);

  const refreshPensionSchemes = useCallback(async () => {
    if (!hasPensionAllScope || !companyConfig?.inPension) return;
    try {
      setPensionLoading(true);
      const pensionSchemes = await PensionAPIV2.getCompanyPensionSchemes();
      setPensionSchemes(pensionSchemes);
    } catch (err) {
      showMessage(`Could not get company pension providers. ${nestErrorMessage(err)}`, 'error');
    }
    setPensionLoading(false);
  }, [companyConfig, hasPensionAllScope, showMessage]);

  const refreshInsuranceQuote = useCallback(async () => {
    if (!hasInsuranceScope) return;
    try {
      setInsuranceLoading(true);
      const insuranceQuote = await InsuranceAPI.getInsuranceQuote();
      setInsuranceQuote(insuranceQuote);
    } catch (err) {
      showMessage(`Could not get insurance quote. ${nestErrorMessage(err)}`, 'error');
    }
    setInsuranceLoading(false);
  }, [hasInsuranceScope, showMessage]);

  const refreshCustomBenefits = useCallback(async () => {
    if (!hasInsuranceAllScope) return;
    try {
      setBenefitsLoading(true);
      const benefits = await CustomBenefitAPI.getAllCustomBenefits();
      setCustomBenefits(benefits);
    } catch (err) {
      showMessage(`Could not get custom benefit. ${nestErrorMessage(err)}`, 'error');
    } finally {
      setBenefitsLoading(false);
    }
  }, [hasInsuranceAllScope, showMessage]);

  const refreshAll = useCallback(async () => {
    setLoading(true);
    await Promise.all([refreshPensionSchemes(), refreshInsuranceQuote(), refreshCustomBenefits()]);
    setLoading(false);
  }, [refreshPensionSchemes, refreshInsuranceQuote, refreshCustomBenefits]);

  useEffect(() => {
    refreshAll();
  }, [refreshAll]);

  return (
    <RootStyle>
      <Switch>
        <RouteScopesControl
          scopes={['insurance:all', 'pension:all']}
          context={scopesContext}
          path={BENEFITS_COMPANY_ROUTE}
        >
          <BenefitsCompanyRouter
            pensionSchemes={pensionSchemes}
            customBenefits={customBenefits}
            insuranceQuote={insuranceQuote}
            refreshInsuranceQuote={refreshInsuranceQuote}
            refreshAllBenefits={refreshAll}
            companyConfig={companyConfig}
            loading={loading || pensionLoading || benefitsLoading || insuranceLoading || isLoading}
          />
        </RouteScopesControl>

        <RouteScopesControl scopes={['insurance', 'pension']} context={scopesContext} path={BENEFITS_ME_ROUTE}>
          <BenefitsMeRouter insuranceQuote={insuranceQuote} loading={loading || insuranceLoading} />
        </RouteScopesControl>

        {(hasInsuranceAllScope || hasPensionAllScope) && location.pathname.startsWith(BENEFITS_COMPANY_ROUTE) && (
          <Redirect to={BENEFITS_COMPANY_ROUTE} />
        )}

        <Redirect to={BENEFITS_ME_ROUTE} />
      </Switch>
    </RootStyle>
  );
};
