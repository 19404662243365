import { useCallback, useMemo, useState } from 'react';

import { Box, FormControl, IconButton, Stack } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { Typography } from '@v2/components/typography/typography.component';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';

import { CompensationAPI, CompensationEndpoints } from '@/api-client/compensation.api';
import { UserContractEndpoints } from '@/api-client/contracts.api';
import { UserCompensationSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { calculateSalaryRates, displayRateLabel, PaySchedules, SalaryBasis } from '@/lib/employment';
import { nestErrorMessage } from '@/lib/errors';
import { Divider } from '@/v2/components/divider.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CurrencyTextField } from '@/v2/components/forms/currency-text-field.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { AttendanceEndpoints } from '@/v2/feature/attendance/attendance.api';
import { payScheduleUnit, salaryBasisQuantity } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import {
  PayScheduleEnum,
  SalaryBasisEnum,
  UserCompensationDto,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import {
  CustomFieldComponents,
  ProfileField,
} from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import {
  drawerContentSx,
  editDeleteHeaderSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { toTitleCase } from '@/v2/util/string.util';

interface Props {
  readonly initialValues: UserCompensationDto | null;
  readonly userId: number;
  readonly getUserCompensation: () => Promise<void>;
  readonly rowModalMode: 'add' | 'edit';
  readonly handleSubmit: (userId: number) => void;
  readonly setFormCurrentlyEditing: (beingEdited: boolean) => void;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly loading: boolean;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: UserCompensationDto) => void;
  showEmployee?: boolean;
}

const deleteIconSize = { width: 14, height: 14 } as const;

export const originalValueOrUndefined = (realVal?: number) => {
  if (typeof realVal === 'number' && realVal > 0) return realVal;
  else return undefined;
};

export const CompensationForm = ({
  initialValues,
  userId,
  getUserCompensation,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
  showEmployee = false,
}: Props) => {
  const { polyglot } = usePolyglot();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [rowForDeletion, setRowForDeletion] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMessage] = useMessage();
  const { data: userFtePercentage } = useApiClient(CompensationEndpoints.getFTEPercentageForUser(userId), {
    suspense: false,
  });
  const { data: currentContract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), {
    suspense: false,
  });
  const { data: companyAttendance } = useApiClient(AttendanceEndpoints.getCompanyAttendanceSettings(), {
    suspense: false,
  });
  const { data: userAttendanceSchedule } = useApiClient(AttendanceEndpoints.getUserAttendanceSchedule(userId), {
    suspense: false,
  });

  const availableUserFtePercentage = useMemo(() => {
    if (userFtePercentage && !isNaN(userFtePercentage)) return userFtePercentage;
    // user fte from import is on 100% scale, so need to divide by 100
    if (initialValues?.userFteFromImport && !isNaN(initialValues?.userFteFromImport))
      return initialValues.userFteFromImport / 100;
    return 0;
  }, [initialValues?.userFteFromImport, userFtePercentage]);

  const getCalculatedSalaryRates = useCallback(
    (compensation: UserCompensationDto) => {
      return calculateSalaryRates(compensation, companyAttendance, userAttendanceSchedule);
    },
    [companyAttendance, userAttendanceSchedule]
  );

  const initialFormikValues = useMemo(
    () =>
      initialValues ??
      ({
        id: 0,
        effectiveDate: new LocalDate().toDateString(),
        paySchedule: PayScheduleEnum.Monthly,
        salaryBasis: SalaryBasisEnum.Annual,
        annualSalary: 0,
        salaryPerPaySchedule: 0,
        rate: null,
        units: 0,
        currency: currentContract?.entity?.currency ?? 'GBP',
        prorateSalaryByFte: false,
        proratedRate: 0,
        nonProratedRate: 0,
        userFteFromImport: 0,
        customUpdates: [],
      } as UserCompensationDto),
    [initialValues, currentContract]
  );

  const formik = useFormik({
    initialValues: {
      ...initialFormikValues,
      units:
        initialFormikValues.salaryBasis &&
        [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(initialFormikValues.salaryBasis)
          ? initialFormikValues.units
          : 0,
      rate: initialFormikValues.prorateSalaryByFte
        ? initialFormikValues.nonProratedRate ?? 0
        : initialFormikValues.rate,
      currency: initialFormikValues.currency ?? DEFAULT_CURRENCY,
      nonProratedRate: initialFormikValues.nonProratedRate ?? undefined,
      proratedRate:
        initialFormikValues.proratedRate ?? initialFormikValues.prorateSalaryByFte
          ? initialFormikValues.rate ?? undefined
          : undefined,
      annualSalary: originalValueOrUndefined(getCalculatedSalaryRates(initialFormikValues)?.annual),
      salaryPerPaySchedule: getCalculatedSalaryRates(initialFormikValues)?.payScheduleRate,
    },
    enableReinitialize: true,
    validationSchema: UserCompensationSchema(polyglot),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (!usedForDataImport) {
          const updatedValues = {
            ...values,
            units: [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(values.salaryBasis!) ? values.units : 0,
            rate: formik.values.prorateSalaryByFte ? values.proratedRate ?? 0 : values.rate,
            nonProratedRate: values.rate ?? undefined,
          };
          delete updatedValues.proratedRate;
          if (rowModalMode === 'edit' && updatedValues.id && updatedValues.id > 0) {
            await CompensationAPI.updateById(userId, updatedValues);
          } else {
            await CompensationAPI.create(userId, updatedValues);
          }
          showMessage(polyglot.t('CompensationForm.successMessages.save'), 'success');
          await getUserCompensation();
          setIsModalOpen(false);
        } else {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage(polyglot.t('CompensationForm.errorMessages.save', { msg: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
        setTimeout(() => formik.resetForm(), 1500);
      }
    },
  });

  const handleDeleteRow = async () => {
    try {
      if (rowForDeletion) await CompensationAPI.deleteById(userId, rowForDeletion);
      showMessage(polyglot.t('CompensationForm.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(polyglot.t('CompensationForm.errorMessages.delete', { msg: nestErrorMessage(error) }), 'error');
    } finally {
      await getUserCompensation();
      handleSubmit(userId);
      setRowForDeletion(0);
      setFormCurrentlyEditing(false);
      formik.resetForm();
      setIsModalOpen(false);
      setIsRemovalModalOpen(false);
    }
  };

  const deleteCompensationRow = (rowId: number) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };

  const onChangeRate = (objToCheck: UserCompensationDto) => {
    if (objToCheck) {
      const { rate, prorateSalaryByFte } = objToCheck;
      const rateValue = rate ?? 0;
      const proratedRate =
        prorateSalaryByFte && availableUserFtePercentage ? availableUserFtePercentage * rateValue : rateValue;
      const salaryRates = getCalculatedSalaryRates({ ...objToCheck, rate: proratedRate });
      formik.setFieldValue('rate', rate, true);
      formik.setFieldValue('proratedRate', originalValueOrUndefined(proratedRate), true);
      formik.setFieldValue('annualSalary', originalValueOrUndefined(salaryRates?.annual), true);
      formik.setFieldValue('salaryPerPaySchedule', originalValueOrUndefined(salaryRates?.payScheduleRate), true);
    }
  };

  const proratedSuffix = useMemo(() => {
    return formik.values.prorateSalaryByFte && availableUserFtePercentage
      ? `- ${polyglot.t('CompensationCard.proRated')}`.concat(' (' + availableUserFtePercentage * 100 + '%)')
      : '';
  }, [formik.values.prorateSalaryByFte, availableUserFtePercentage, polyglot]);

  const createSalaryRateTooltip = useCallback(() => {
    const salaryRate = getCalculatedSalaryRates(formik.values);
    if (!salaryRate) return null;

    return (
      <Stack>
        <Typography variant="caption" color="white">
          {salaryRate.reference.attendanceScheduleName &&
            `The rate is calculated based upon the employee's currently effective attendance schedule (${salaryRate.reference.attendanceScheduleName}).`}
          {!salaryRate.reference.attendanceScheduleName &&
            `The rate is calculated based upon the company default working schedule.`}
        </Typography>
        <Typography variant="caption" color="white">{`Days per week: ${salaryRate.reference.daysPerWeek}`}</Typography>
        <Typography variant="caption" color="white">{`Hours per day: ${salaryRate.reference.hoursPerDay}`}</Typography>
      </Stack>
    );
  }, [formik.values, getCalculatedSalaryRates]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {rowModalMode === 'add' ? (
          <Typography variant="title2">{polyglot.t('CompensationForm.new')}</Typography>
        ) : (
          <Box sx={{ ...editDeleteHeaderSx, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{polyglot.t('CompensationForm.edit')}</Typography>
            <IconButton
              sx={iconCTAButtonSx}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setIsRemovalModalOpen(true);
                if (formik?.values?.id) deleteCompensationRow(formik?.values?.id);
              }}
            >
              <TrashIcon {...deleteIconSize} />
            </IconButton>
          </Box>
        )}
        {showEmployee && (
          <Stack sx={{ gap: spacing.g5 }}>
            <Typography variant="captionSmall">{polyglot.t('CompensationForm.employee')}</Typography>
            <UserCell userId={userId} nameVariant="title4" />
          </Stack>
        )}
        <ProfileField fieldStub="compensation.effectiveDate">
          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.effectiveDate ?? null}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('effectiveDate', value);
                }
              }}
              name="effectiveDate"
              label={polyglot.t('CompensationForm.effectiveDate')}
              error={!!formik.errors.effectiveDate && formik.touched.effectiveDate}
              helperText={formik.errors.effectiveDate && formik.touched.effectiveDate}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="compensation.paySchedule">
          <FormControl size="small" fullWidth>
            <SelectComponent
              name="paySchedule"
              label={polyglot.t('CompensationForm.paySchedule')}
              options={PaySchedules(polyglot)}
              value={formik.values.paySchedule}
              compareValue={formik.values.paySchedule}
              error={!!formik.errors.paySchedule && formik.touched.paySchedule}
              onChange={(changeEvent) => {
                formik.handleChange(changeEvent);
                onChangeRate({
                  ...formik.values,
                  paySchedule: changeEvent.target.value,
                });
              }}
              helperText={formik.errors.paySchedule && formik.touched.paySchedule}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="compensation.salaryBasis">
          <FormControl size="small" fullWidth>
            <SelectComponent
              name="salaryBasis"
              label={polyglot.t('CompensationForm.salaryBasis')}
              options={SalaryBasis(polyglot)}
              value={formik.values.salaryBasis}
              compareValue={formik.values.salaryBasis}
              error={!!formik.errors.salaryBasis && formik.touched.salaryBasis}
              onChange={(changeEvent) => {
                formik.handleChange(changeEvent);
                onChangeRate({
                  ...formik.values,
                  salaryBasis: changeEvent.target.value,
                });
              }}
              helperText={formik.errors.salaryBasis && formik.touched.salaryBasis}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="compensation.rate">
          <CurrencyTextField
            name="rate"
            label={`${displayRateLabel(formik.values.salaryBasis!, polyglot)} ${
              formik.values.prorateSalaryByFte ? '(100%)' : ''
            }`}
            value={formik.values.rate}
            onCurrencyChange={(value) => {
              formik.setFieldValue('currency', value);
            }}
            selectedCurrency={formik.values.currency}
            onChange={(value) => {
              onChangeRate({
                ...formik.values,
                rate: value ? Number(value) : null,
              });
            }}
            error={formik.touched.rate && !!formik.errors.rate}
            helperText={(formik.touched.rate && formik.errors.rate) || ''}
          />
        </ProfileField>

        <ProfileField fieldStub="compensation.rate" sx={{ minHeight: 'unset' }}>
          <CheckboxComponent
            label={polyglot.t('CompensationForm.prorateSalaryByFte')}
            name="prorateSalaryByFte"
            checked={formik.values.prorateSalaryByFte}
            onChange={(_, value) => {
              formik.setFieldValue('prorateSalaryByFte', value);
              onChangeRate({
                ...formik.values,
                prorateSalaryByFte: value,
              });
            }}
            disabled={!formik.values.prorateSalaryByFte && !currentContract?.attendanceSchedule}
          />
          {formik.values.prorateSalaryByFte && currentContract?.attendanceSchedule ? (
            <Typography variant="caption">
              {polyglot.t('CompensationForm.prorateSalaryByFteDescription', {
                percentage: currentContract.attendanceSchedule.ftePercent,
                workHours: convertMinutesToClockHours(currentContract.attendanceSchedule.totalTime, polyglot),
                fteHours: convertMinutesToClockHours(currentContract.attendanceSchedule.fteEquivalent, polyglot),
              })}
            </Typography>
          ) : formik.values.prorateSalaryByFte && !currentContract?.attendanceSchedule ? (
            <Typography variant="caption" color="RedDark">
              {polyglot.t('CompensationForm.noScheduleMessage')}
            </Typography>
          ) : null}
        </ProfileField>

        <CustomFieldComponents
          values={formik.values.customUpdates}
          onChange={(values) => formik.setFieldValue('customUpdates', values)}
          rowModalMode={rowModalMode}
          formName={CustomProfileFormType.Salary}
        />

        {formik.values.paySchedule &&
          formik.values?.salaryBasis &&
          ['Daily', 'Hourly'].includes(formik.values?.salaryBasis) && (
            <ProfileField fieldStub="compensation.units">
              <TextfieldComponent
                name="units"
                label={`${toTitleCase(salaryBasisQuantity(formik.values.salaryBasis))} per ${payScheduleUnit(
                  formik.values.paySchedule
                )}`}
                value={formik.values.units}
                type="number"
                onChange={(changeEvent) => {
                  formik.handleChange(changeEvent);
                  onChangeRate({
                    ...formik.values,
                    units: +changeEvent.target.value,
                  });
                }}
                error={formik.touched.units && !!formik.errors.units}
                helperText={(formik.touched.units && formik.errors.units) ?? ' '}
                clearText={() => formik.setFieldValue('units', '')}
              />
            </ProfileField>
          )}

        <Divider style={{ margin: '10px 0' }} />

        {formik.values.paySchedule && (
          <ProfileField fieldStub="compensation.rate">
            <TextfieldComponent
              name="salaryPerPaySchedule"
              label={`${polyglot.t('CompensationForm.salaryPerPaySchedule1')} ${payScheduleUnit(
                formik.values.paySchedule
              )} ${polyglot.t('CompensationForm.salaryPerPaySchedule2')} ${proratedSuffix}`}
              value={formik.values.salaryPerPaySchedule ?? 0}
              type="number"
              error={formik.touched.salaryPerPaySchedule && !!formik.errors.salaryPerPaySchedule}
              helperText={(formik.touched.salaryPerPaySchedule && formik.errors.salaryPerPaySchedule) ?? ' '}
              disabled={true}
            />
          </ProfileField>
        )}

        <ProfileField fieldStub="compensation.rate">
          <TextfieldComponent
            name="annualSalary"
            label={`${polyglot.t('CompensationForm.annualSalaryGross')} ${proratedSuffix}`}
            value={
              formik.values.annualSalary && !Number.isNaN(formik.values.annualSalary)
                ? formik.values.annualSalary.toFixed(2)
                : 0
            }
            type="number"
            disabled={true}
            error={formik.touched.annualSalary && !!formik.errors.annualSalary}
            helperText={(formik.touched.annualSalary && formik.errors.annualSalary) ?? ' '}
          />
        </ProfileField>

        <ProfileField fieldStub="compensation.rate">
          <TextfieldComponent
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                Daily rate (Gross)
                <StyledTooltip title={createSalaryRateTooltip()} placement="top">
                  <MistakeIcon {...iconSize} fill={themeColors.GreyMiddle} />
                </StyledTooltip>
              </Box>
            }
            value={getCalculatedSalaryRates(formik.values)?.daily ?? 0}
            type="number"
            disabled
          />
        </ProfileField>

        <ProfileField fieldStub="compensation.rate">
          <TextfieldComponent
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                Hourly rate (Gross)
                <StyledTooltip title={createSalaryRateTooltip()} placement="top">
                  <MistakeIcon {...iconSize} fill={themeColors.GreyMiddle} />
                </StyledTooltip>
              </Box>
            }
            value={getCalculatedSalaryRates(formik.values)?.hourly ?? 0}
            type="number"
            disabled
          />
        </ProfileField>
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            fullWidth
            sizeVariant="medium"
            colorVariant="secondary"
            onClick={() => {
              formik.resetForm();
              onClose();
            }}
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth={true}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
      <NotificationModal
        isOpen={isRemovalModalOpen}
        onClose={() => {
          setIsRemovalModalOpen(false);
          setRowForDeletion(0);
        }}
        anchorEl={anchorEl}
        takeAction={handleDeleteRow}
        message={polyglot.t('CompensationForm.confirmDelete')}
        callToAction={polyglot.t('CompensationForm.callToAction')}
      />
    </FormikProvider>
  );
};
