import React from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ActivationLock } from '@v2/feature/device/device.dto';
import { FieldValueComponent } from '@v2/feature/device/device.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

export const ActivationLockPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  configurationPayloadOption,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  policyName: string;
  configurationPayloadOption?: ActivationLock | null;
}) => {
  const { polyglot } = usePolyglot();

  const showActivationLock = () => {
    if (!configurationPayloadOption) return <Typography variant="caption">No configuration payload</Typography>;
    return Object.entries(configurationPayloadOption).map(([key, value]) => (
      <FieldValueComponent
        key={key}
        title={polyglot.t(`InHouseMdmPolicyViewDrawer.${key}`)}
        value={<Typography variant="title4">{String(value)}</Typography>}
      />
    ));
  };

  if (!configurationPayloadOption) return null;

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
      <Box>
        <Typography variant="title2">{policyName}</Typography>

        <Box
          component="dl"
          sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}
        >
          {showActivationLock()}
        </Box>
      </Box>
    </DrawerModal>
  );
};
