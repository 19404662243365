import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SURVEYS_COMPANY_ONGOING_ROUTE, SURVEYS_TEAM_ONGOING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const SCGeneralDuplicateModal = ({
  surveyCycle,
  reach,
  isOpen,
  setIsOpen,
  onClose,
}: {
  surveyCycle: SurveyCycle | undefined;
  reach: ReachType;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCGeneralDuplicateModalContent surveyCycle={surveyCycle} onClose={onClose} reach={reach} />
    </DrawerModal>
  );
};

const SCGeneralDuplicateModalContent = ({
  surveyCycle,
  reach,
  onClose,
}: {
  surveyCycle: SurveyCycle | undefined;
  reach: ReachType;
  onClose: () => void;
}) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const handleDuplicateCycle = async () => {
    try {
      const reachRoute = reach === ReachType.Company ? SURVEYS_COMPANY_ONGOING_ROUTE : SURVEYS_TEAM_ONGOING_ROUTE;

      if (!surveyCycle) {
        showMessage('Cycle does not exist', 'error');
        return;
      }
      await SurveyCycleAPI.duplicateCycle(surveyCycle.id);
      showMessage('Successfully duplicate the cycle', 'success');
      routerHistory.push(reachRoute);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Duplicate cycle</Typography>
      <Typography variant="caption" color="Grey">
        All cycle settings will be duplicated into a new draft cycle.
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent onClick={onClose} sizeVariant="medium" colorVariant="secondary" fullWidth>
          Cancel
        </ButtonComponent>
        <ButtonComponent onClick={handleDuplicateCycle} sizeVariant="medium" colorVariant="primary" fullWidth>
          Duplicate
        </ButtonComponent>
      </Box>
    </Box>
  );
};
