import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { PermissionGroupEditDrawerPage } from '@/v2/feature/permission-group/components/permission-group-edit-drawer.component';
import { PermissionGroupListingTable } from '@/v2/feature/permission-group/components/permission-group-listing-table.component';
import { PermissionGroupAPI } from '@/v2/feature/permission-group/permission-group.api';
import { PermissionGroupDto } from '@/v2/feature/permission-group/permission-group.dto';
import { PERMISSION_GROUP_EDIT_DRAWER_MODES } from '@/v2/feature/permission-group/permission-group.interface';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export function PermissionGroupsSettingsPage(): React.JSX.Element {
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDto[]>([]);

  const { trackPage } = useJune();

  const refreshPermissionGroups = useCallback(async () => {
    setLoading(true);
    try {
      const permissionGroupData = await PermissionGroupAPI.listPermissionGroupsWithMembers();
      const sortedGroups = permissionGroupData.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
      setPermissionGroups(sortedGroups);
    } catch (error) {
      showMessage(`Permission group info could not be loaded: ${nestErrorMessage(error)}`, 'error');
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshPermissionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPage('Company permission groups settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper loading={loading}>
      <SettingsSectionContent title="Permissions" contentWidth="100%">
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <PermissionsTable
                      permissionGroups={permissionGroups}
                      refreshPermissionGroups={refreshPermissionGroups}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
}

const PermissionsTable = ({
  permissionGroups,
  refreshPermissionGroups,
  setLoading,
  loading,
}: {
  permissionGroups: PermissionGroupDto[];
  refreshPermissionGroups: () => Promise<void>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [searchInput, setSearchInput] = useState<string>('');

  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [permissionGroupDrawerMode, setPermissionGroupDrawerMode] = useState<PERMISSION_GROUP_EDIT_DRAWER_MODES>(
    PERMISSION_GROUP_EDIT_DRAWER_MODES.scope
  );

  // const [globalState] = useContext(GlobalContext);
  // const { user } = globalState;
  // const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const addNewPermissionGroup = () => {
    // OLD RESTRICTION
    // if (user?.restrictions?.PEOPLE?.disablePermissionGroupCreation) {
    //   setUpgradeModalOpen(true);
    // } else {
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.add);
    setEditDrawerOpen(true);
    // }
  };

  const editPermissionGroup = (permissionGroup: PermissionGroupDto) => {
    routerHistory.push(
      generatePath(SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE, { permissionGroupId: permissionGroup.id })
    );
  };

  const filteredPermissionGroups = useMemo(() => {
    return searchInput.length > 0
      ? permissionGroups.filter((eachGroup) => eachGroup.name.toLowerCase().includes(searchInput.toLowerCase()))
      : permissionGroups;
  }, [permissionGroups, searchInput]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
        <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => addNewPermissionGroup()}>
          {polyglot.t('permissionListingTable.addGroup')}
        </ButtonComponent>
      </Box>

      <Box sx={{ ...spacing.mt20 }}>
        <PermissionGroupListingTable
          permissionGroups={filteredPermissionGroups}
          loading={loading}
          openDrawerInEditMode={(permissionGroup: PermissionGroupDto) => {
            editPermissionGroup(permissionGroup);
          }}
          refreshPermissionGroups={refreshPermissionGroups}
          setLoading={setLoading}
        />
        <DrawerModal isOpen={editDrawerOpen} setIsOpen={setEditDrawerOpen}>
          <PermissionGroupEditDrawerPage
            refreshPermissionGroup={async () => {}}
            selectedPermission={undefined}
            selectedPermissionCategory={undefined}
            permissionGroupMembers={[]}
            closePage={async () => {
              setEditDrawerOpen(false);
              await refreshPermissionGroups();
            }}
            mode={permissionGroupDrawerMode}
            setLoading={setLoading}
          />
        </DrawerModal>
      </Box>
    </Box>
  );
};
