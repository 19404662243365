import { TimelineEventProps } from '@v2/feature/calendar/features/calendar.page';
import axios from 'axios';

import { CalendarStatusForUserConfig } from '@/v2/feature/calendar/calendar.dto';
import {
  CalendarSettingProps,
  CompanyEvent,
  CountryHolidays,
  CreateCompanyEvent,
  iCalLinkMode,
  OutlookCalendarPublishLinks,
  UpsertCalendarSettingProps,
} from '@/v2/feature/calendar/calendar.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class GoogleCalendarAPI {
  static async getConfig(): Promise<CalendarStatusForUserConfig | null> {
    return (await axios.get('/apiv2/google-calendar/config'))?.data;
  }

  static async syncCalendar(): Promise<void> {
    await axios.get('/apiv2/google-calendar/sync-events');
  }
}

export class CalendarSettingsAPI {
  static async getSettings(): Promise<CalendarSettingProps> {
    return (await axios.get('/apiv2/calendar-settings')).data;
  }

  static async upsertSettings(calendarSettingsDto: UpsertCalendarSettingProps): Promise<void> {
    return (await axios.post('/apiv2/calendar-settings', calendarSettingsDto)).data;
  }
}

export class CalendarAPI {
  static async getAllCalendarEventsByRange(
    dateRange: {
      start: string;
      end: string;
    },
    filterString: string,
    selectedTypes: string,
    hideEmptyRows: boolean,
    searchInput: string,
    view: 'company' | 'team' | 'me'
  ): Promise<readonly TimelineEventProps[]> {
    const query = [
      filterString,
      selectedTypes,
      `start=${dateRange.start}`,
      `end=${dateRange.end}`,
      hideEmptyRows ? 'hideEmpty=true' : '',
      searchInput ? `search=${searchInput}` : '',
      `view=${view}`,
    ]
      .filter(Boolean)
      .join('&');
    return await (await axios.get(`/apiv2/calendar?${query}`)).data;
  }

  static async getAllCalendars(dateRange: { start: string; end: string }): Promise<CountryHolidays[]> {
    return await (await axios.get(`/apiv2/absences/calendar-types?start=${dateRange.start}&end=${dateRange.end}`)).data;
  }

  static async createCalendarEvent(event: CreateCompanyEvent): Promise<void> {
    return await (await axios.post('/apiv2/company/events', { event })).data;
  }

  static async updateCalendarEvent(event: CreateCompanyEvent, id: number): Promise<void> {
    return await (await axios.patch(`/apiv2/company/events/${id}`, { event })).data;
  }

  static async deleteCalendarEvent(id: number): Promise<void> {
    return await (await axios.delete(`/apiv2/company/events/${id}`)).data;
  }

  static async getCalendarEvent(): Promise<CompanyEvent[]> {
    return await (await axios.get('/apiv2/company/events')).data;
  }

  static async getCalendarEventByDateRange(dateRange: { start: string; end: string }): Promise<CompanyEvent[]> {
    return await (await axios.get(`/apiv2/company/events/calendar?start=${dateRange.start}&end=${dateRange.end}`)).data;
  }

  static async updatePublishLinksForOutlookCalendar(
    publishLinksForCalendar: OutlookCalendarPublishLinks
  ): Promise<void> {
    return await (await axios.patch('/apiv2/outlook-calendar/publish-links', { publishLinksForCalendar })).data;
  }
}

export class CalendarEndpoints {
  static getOutlookCalendarPublishLinks(): Endpoint<OutlookCalendarPublishLinks> {
    return { url: '/apiv2/outlook-calendar/publish-links' };
  }

  static getCalendarIcsAddressHash(mode: iCalLinkMode, filter?: string): Endpoint<string> {
    let queryString = '';
    if (filter && filter.length > 0) {
      queryString = '?';
      const filtersArray = filter.split('&');
      queryString += filtersArray.map((filter) => `filter[]=${encodeURIComponent(filter)}`).join('&');
    }
    return {
      url: `/apiv2/calendar/ical-address-hash/${mode}${queryString}`,
    };
  }
}
