import { useEffect } from 'react';

export function useKeypress(key: string, onKeyPressed: (() => void) | undefined) {
  useEffect(() => {
    if (!onKeyPressed) return () => {};
    const onKeyDown = (e: KeyboardEvent) => {
      // if this handler is at the top of the stack, call it
      if (e.key === key) {
        onKeyPressed();
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [key, onKeyPressed]);
}

export function useEscapeKey(onEscapeKeyPressed: (() => void) | undefined) {
  useKeypress('Escape', onEscapeKeyPressed);
}
