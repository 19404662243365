import { useCallback, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { ONBOARDING_ROUTE } from '@/lib/routes';
import { DevicePossessionDto } from '@/v2/feature/device/device.dto';
import { DeviceSelectPage } from '@/v2/feature/onboarding/onboarding-device/device-select.page';
import { DeviceShippingPage } from '@/v2/feature/onboarding/onboarding-device/device-shipping.page';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';

export const OnboardingDeviceInventoryPage = () => {
  const [page, setPage] = useState<'device-select' | 'shipping'>('device-select');
  const [selectedDevice, setSelectedDevice] = useState<DevicePossessionDto>();
  const { getCachedUserById } = useCachedUsers();
  const params = useParams<{ userId: string }>();
  const userId = Number(params.userId);
  const user = userId && getCachedUserById(userId);
  const routerHistory = useHistory();

  const returnToOnboardingStatus = useCallback(() => {
    routerHistory.goBack();
  }, [routerHistory]);

  if (!user) {
    routerHistory.push(ONBOARDING_ROUTE);
    return <></>;
  }

  return (
    <>
      {page === 'device-select' && (
        <DeviceSelectPage
          selectedDevice={selectedDevice}
          onContinueClick={(device) => {
            setSelectedDevice(device);
            setPage('shipping');
          }}
          onCloseClick={returnToOnboardingStatus}
        />
      )}
      {page === 'shipping' && selectedDevice && (
        <DeviceShippingPage
          devicePossession={selectedDevice}
          user={user}
          onBackClick={() => setPage('device-select')}
          onCloseClick={returnToOnboardingStatus}
          onFinish={returnToOnboardingStatus}
        />
      )}
    </>
  );
};
