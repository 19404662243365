import { useContext, useEffect, useMemo, useRef } from 'react';

import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { SETTINGS_ATTENDANCE_ROUTE } from '@/lib/routes';
import { SelectComponent, SelectComponentProps } from '@/v2/components/forms/select.component';
import { convertMinutesToClockHours } from '@/v2/feature/absence/absence.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';

export type AttendanceScheduleSelectProps = Omit<
  SelectComponentProps,
  'ref' | 'label' | 'options' | 'value' | 'onChange'
> & {
  label?: string;
  name: string;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: number | null;
  footer?: React.ReactNode;
  autoSelectSingleEntity?: (id: number) => void;
  readonly onChange?: (e: React.ChangeEvent<any>, schedule?: AttendanceScheduleDto) => any;
};

export const NO_SCHEDULE_VALUE = 0;
const NO_SCHEDULE_OPTION = { label: 'None', value: NO_SCHEDULE_VALUE };

export const AttendanceScheduleSelect = ({
  label = 'Attendance schedule',
  name,
  error,
  helperText,
  value,
  onChange,
  footer,
  autoSelectSingleEntity,
  ...selectProps
}: AttendanceScheduleSelectProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const canEditAttendanceSchedules = hasScopes(['attendance:all'], { userId: user.userId });
  const hasAutoSelected = useRef(false);
  const { data: attendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });

  const schedulesOptions = useMemo(() => {
    return attendanceSchedules
      ? [
          NO_SCHEDULE_OPTION,
          ...attendanceSchedules.map((s) => {
            const ftePercent = s.ftePercent;
            const totalHours = convertMinutesToClockHours(s.totalTime, polyglot);

            const label = (
              <>
                {s.name}{' '}
                <span style={{ color: themeColors.Grey }}>
                  ({polyglot.t('ContractForm.contractFTEPercent', { ftePercent: ftePercent, totalHours: totalHours })})
                </span>
              </>
            );
            return { label, value: s.id };
          }),
        ]
      : [NO_SCHEDULE_OPTION];
  }, [attendanceSchedules, polyglot]);

  useEffect(() => {
    if (!hasAutoSelected.current && !!autoSelectSingleEntity && !value && schedulesOptions?.length === 1) {
      hasAutoSelected.current = true;
      autoSelectSingleEntity(schedulesOptions[0].value);
    }
  }, [autoSelectSingleEntity, schedulesOptions, value]);

  return (
    <SelectComponent
      name={name}
      label={label}
      options={schedulesOptions ?? []}
      value={value ?? NO_SCHEDULE_VALUE}
      error={error}
      helperText={helperText}
      footer={footer}
      editList={{
        handler: () => routerHistory.push(SETTINGS_ATTENDANCE_ROUTE),
        isHidden: !canEditAttendanceSchedules,
      }}
      onChange={(e) => {
        if (e.target.value === NO_SCHEDULE_VALUE) {
          e.target.value = null;
        }
        const selectedSchedule = attendanceSchedules?.find((a) => a.id === e.target.value);
        onChange?.(e, selectedSchedule);
      }}
      {...selectProps}
    />
  );
};
