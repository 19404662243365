import { useCallback } from 'react';

import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { LOGIN_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { AuthLoginRouterState } from '@/v2/feature/auth/features/auth-router.states';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const UserOnboardingPasswordAlreadySet = (): JSX.Element => {
  const routerHistory = useHistory();

  const returnToLogin = useCallback(() => {
    routerHistory.replace(LOGIN_ROUTE, { continueOnboarding: true } as AuthLoginRouterState);
  }, [routerHistory]);

  return (
    <AuthLayout title="Set up your account">
      <Typography sx={{ ...themeFonts.caption }}>
        Your account already exists. Please log in to complete the onboarding process.
      </Typography>
      <ButtonComponent
        type="button"
        sizeVariant="medium"
        colorVariant="primary"
        fullWidth
        style={{ marginTop: '30px' }}
        onClick={() => returnToLogin()}
      >
        Login
      </ButtonComponent>
    </AuthLayout>
  );
};
