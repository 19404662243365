import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmRestrictionPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-restriction-policy-editor.component';
import {
  ZeltMdmDevicePolicyType,
  ZeltPolicyPayloadIdentifier,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  ICloudRestrictionOptions,
  RestrictionSecurityOptions,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmRestrictionMobilePolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const [selectedRestriction, setSelectedRestriction] = useState<ZeltMdmDevicePolicyType>();
  const iCloudRestriction = (): ICloudRestrictionOptions => {
    return {
      allowCloudBookmarks:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudBookmarks !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudBookmarks
          : true,
      allowCloudMail:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudMail !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudMail
          : true,
      allowCloudCalendar:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudCalendar !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudCalendar
          : true,
      allowCloudReminders:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudReminders !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudReminders
          : true,
      allowCloudAddressBook:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudAddressBook !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudAddressBook
          : true,
      allowCloudNotes:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudNotes !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudNotes
          : true,
      allowCloudDocumentSync:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudDocumentSync !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudDocumentSync
          : true,
      allowCloudKeychainSync:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudKeychainSync !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudKeychainSync
          : true,
      allowCloudPhotoLibrary:
        configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudPhotoLibrary !== undefined
          ? configurablePoliciesSelected?.configurableICloudRestriction?.allowCloudPhotoLibrary
          : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const securityRestrictions = (): RestrictionSecurityOptions => {
    return {
      allowCamera:
        configurablePoliciesSelected?.configurableSecurity?.allowCamera !== undefined
          ? configurablePoliciesSelected?.configurableSecurity?.allowCamera
          : true,
      allowSafari:
        configurablePoliciesSelected?.configurableSecurity?.allowSafari !== undefined
          ? configurablePoliciesSelected?.configurableSecurity?.allowSafari
          : true,
      allowAirDrop:
        configurablePoliciesSelected?.configurableSecurity?.allowAirDrop !== undefined
          ? configurablePoliciesSelected?.configurableSecurity?.allowAirDrop
          : true,
      allowDictation:
        configurablePoliciesSelected?.configurableSecurity?.allowDictation !== undefined
          ? configurablePoliciesSelected?.configurableSecurity?.allowDictation
          : true,
      allowScreenShot:
        configurablePoliciesSelected?.configurableSecurity?.allowScreenShot !== undefined
          ? configurablePoliciesSelected?.configurableSecurity?.allowScreenShot
          : true,
      allowAssistant:
        configurablePoliciesSelected?.configurableSecurity?.allowAssistant !== undefined
          ? configurablePoliciesSelected?.configurableSecurity?.allowAssistant
          : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">iCloud restrictions</Typography>
          <IconButton
            onClick={() => {
              setSelectedRestriction(ZeltMdmDevicePolicyType.icloud);
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Safari bookmarks
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudBookmarks
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudBookmarks ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Mail
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudMail
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudMail ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Calendar
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudCalendar
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudCalendar ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Reminders
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudReminders
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudReminders ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Contacts
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudAddressBook
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudAddressBook ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Notes
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudNotes
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudNotes ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Drive
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudDocumentSync
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudDocumentSync ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            KeyChain sync
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudKeychainSync
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudKeychainSync ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Photos
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(iCloudRestriction().allowCloudPhotoLibrary
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {iCloudRestriction().allowCloudPhotoLibrary ? 'Allowed' : 'Restricted'}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Apps and features restrictions</Typography>
          <IconButton
            onClick={() => {
              setSelectedRestriction(ZeltMdmDevicePolicyType.security);
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Camera
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(securityRestrictions().allowCamera
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {securityRestrictions().allowCamera ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Safari
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(securityRestrictions().allowSafari
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {securityRestrictions().allowSafari ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            AirDrop
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(securityRestrictions().allowAirDrop
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {securityRestrictions().allowAirDrop ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Siri
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(securityRestrictions().allowAssistant
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {securityRestrictions().allowAssistant ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Dictation
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(securityRestrictions().allowDictation
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {securityRestrictions().allowDictation ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Screen capture
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(securityRestrictions().allowScreenShot
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {securityRestrictions().allowScreenShot ? 'Allowed' : 'Restricted'}
          </Typography>
        </Box>
      </Box>
      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmRestrictionPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          selectedRestriction={selectedRestriction}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
