import { useCallback } from 'react';

import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { CustomBenefitSettingsApprovalPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-approval.page';
import { CustomBenefitSettingsGeneralPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-general.page';
import { CustomBenefitSettingsMembersPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-members.page';
import { CustomBenefitSettingsPaymentPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-payment.page';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitCategory } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateCustomBenefitCategory } from '@v2/infrastructure/i18n/translate.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE,
  SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE,
  SETTINGS_BENEFITS_ROUTE,
} from '@/lib/routes';

export const getPageConfig = (category: string, id: number, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, { category, id, productType: 'custom' }),
        },
        {
          title: polyglot.t('BenefitModule.payment'),
          stub: 'payment',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE, { category, id, productType: 'custom' }),
        },
        {
          title: polyglot.t('General.members'),
          stub: 'members',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, { category, id, productType: 'custom' }),
        },
        {
          title: polyglot.t('BenefitModule.approval'),
          stub: 'approval',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE, { category, id, productType: 'custom' }),
        },
      ],
    },
  ] as NavConfigItem[];
};

interface CustomBenefitSettingsRouterProps {
  readonly id: number;
  readonly category: CustomBenefitCategory;
}

export const CustomBenefitSettingsRouter = ({ id, category }: CustomBenefitSettingsRouterProps) => {
  const { data: customBenefit, mutate: refresh } = useApiClient(CustomBenefitEndpoints.getCustomBenefitById(id));
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const refreshBenefit = useCallback(async () => {
    try {
      if (refresh) await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, refresh, showMessage]);

  return (
    <>
      <DomainSideMenuContent
        title={customBenefit?.name ?? polyglot.t('BenefitModule.customBenefit')}
        subtitle={translateCustomBenefitCategory(category, polyglot)}
        pageConfig={getPageConfig(category, id, polyglot)}
        backPath={SETTINGS_BENEFITS_ROUTE}
        showBack
        type="Custom"
      />
      {customBenefit && (
        <Switch>
          <Route exact path={SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE}>
            <CustomBenefitSettingsGeneralPage
              category={category}
              customBenefit={customBenefit}
              refreshBenefit={refreshBenefit}
            />
          </Route>

          <Route exact path={SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE}>
            <CustomBenefitSettingsMembersPage customBenefit={customBenefit} refreshBenefit={refreshBenefit} />
          </Route>
          <Route exact path={SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE}>
            <CustomBenefitSettingsPaymentPage customBenefit={customBenefit} refreshBenefit={refreshBenefit} />
          </Route>
          <Route exact path={SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE}>
            <CustomBenefitSettingsApprovalPage customBenefit={customBenefit} refreshBenefit={refreshBenefit} />
          </Route>

          <Redirect to={SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE} />
        </Switch>
      )}
    </>
  );
};
