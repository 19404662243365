import { useState } from 'react';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { SETTINGS_PERSONAL_ROUTE } from '@/lib/routes';
import { CurrentUser } from '@/models';
import { HelpHeader } from '@/v2/feature/app-layout/features/sidebar/components/help-header.component';
import { CompanySettings } from '@/v2/feature/app-layout/features/v2/component/company-settings.component';
import { NavConfigItem, NavigationItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { QuickAction } from '@/v2/feature/app-layout/features/v2/component/quick-action.component';
import { UserProfileIcon } from '@/v2/feature/app-layout/features/v2/component/user-header.component';
import { RoleTypes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import { HelpCenterModal } from '@/v2/feature/strapi-help/sections/help-center-modal.section';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const MAIN_DRAWER_WIDTH = 60;

export const MainMenuRootStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(246, 243, 239, 0.7);
  backdrop-filter: blur(15px);
  width: 100%;
  flex: 0 0 ${MAIN_DRAWER_WIDTH}px;
  z-index: 3;
  max-width: ${MAIN_DRAWER_WIDTH}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MainMenuSection = ({
  setShowSecondMenu,
  setActiveParent,
  activeParent,
  sideBarConfig,
  setActiveRole,
  user,
}: {
  setShowSecondMenu: (val: boolean) => void;
  setActiveParent: (val: string | undefined) => void;
  activeParent: string | undefined;
  sideBarConfig: readonly NavConfigItem[];
  setActiveRole: (val: RoleTypes) => void;
  user: CurrentUser;
}) => {
  const { polyglot } = usePolyglot();

  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [domain, setDomain] = useState<string | undefined>(undefined);

  const renderContent = (
    <Box
      sx={{
        background: themeColors.Background,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box key="nav-item" sx={{ width: '100%', paddingTop: spacing.p5 }}>
        <QuickAction user={user} />
        <NavigationItem
          navConfig={sideBarConfig}
          setActiveParent={setActiveParent}
          activeParent={activeParent}
          setShowSecondMenu={setShowSecondMenu}
          setActiveRole={setActiveRole}
        />
      </Box>
      <Box
        key="help-header"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: spacing.p10,
          width: '100%',
        }}
      >
        <HelpHeader
          isOpen={isHelpOpen}
          headerName={polyglot.t('AppSidebar.help')}
          onClick={() => {
            const domain_regex = /(\/company\/|\/me\/|\/team\/)([^/]+)/;
            const domain_match = window.location.pathname.match(domain_regex);
            if (domain_match && domain_match.length > 1) {
              setDomain(domain_match[2].replace('/', ''));
            } else {
              console.error('Something went wrong when matching the app domain');
            }
            setIsHelpOpen(true);
          }}
        />
        <CompanySettings headerName={polyglot.t('AppSidebar.settings')} redirectPath={SETTINGS_PERSONAL_ROUTE} />

        <UserProfileIcon />
      </Box>
      {isHelpOpen && <HelpCenterModal isOpen={isHelpOpen} setIsOpen={setIsHelpOpen} domain={domain} />}
    </Box>
  );
  return (
    <MainMenuRootStyle>
      <Box sx={{ height: '100%', width: '100%' }}>{renderContent}</Box>
    </MainMenuRootStyle>
  );
};
