import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { RejectDrawer } from '@v2/components/reject-drawer.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { AbsenceStatus } from '@v2/feature/absence/absence.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import Polyglot from 'node-polyglot';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';

export const AbsenceDrawerActionButtons = ({
  absence,
  setAbsence,
  handleApprove,
  handleReject,
  onDelete,
  onRejectCancellation,
  onRequestCancellation,
  onForceApproval,
  onEdit,
}: {
  readonly absence: AbsenceDto;
  readonly setAbsence?: React.Dispatch<React.SetStateAction<AbsenceDto | undefined>>;
  readonly handleApprove?: (a: AbsenceDto) => Promise<AbsenceDto | undefined>;
  readonly handleReject?: (a: AbsenceDto, rejectionNotes?: string) => Promise<AbsenceDto | undefined>;
  readonly onDelete?: (absence: AbsenceDto) => Promise<void>;
  readonly onRejectCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onRequestCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onForceApproval?: (
    absence: AbsenceDto,
    status: AbsenceStatus.Approved | AbsenceStatus.Rejected
  ) => Promise<void>;
  readonly onEdit?: (absence: AbsenceDto) => void;
}) => {
  const { polyglot } = usePolyglot();

  const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
  const [isLoadingSecondary, setIsLoadingSecondary] = useState(false);

  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState<boolean>(false);

  const handleRejectAction = () => setIsRejectDrawerOpen(true);

  const { primaryAction, allOtherActions } = useMemo(() => {
    const allActions = getActionButtons(
      absence,
      polyglot,
      setAbsence,
      onDelete,
      onEdit,
      handleApprove,
      handleRejectAction,
      onForceApproval,
      onRequestCancellation,
      onRejectCancellation
    );

    // get first primary action available (the array should contain only one primary action that is not hidden)
    const primaryAction = allActions.find((a) => a.variant === 'primary');

    return {
      primaryAction,
      allOtherActions: primaryAction ? allActions.filter((a) => a.id !== primaryAction.id) : allActions,
    };
  }, [
    absence,
    polyglot,
    setAbsence,
    onDelete,
    onEdit,
    handleApprove,
    onForceApproval,
    onRequestCancellation,
    onRejectCancellation,
  ]);

  return !primaryAction && allOtherActions.length === 0 ? null : (
    <Box sx={buttonBoxDrawerSx}>
      {/* IF ONLY 2 SECONDARY ACTIONS */}
      {!primaryAction && allOtherActions.length === 2 ? (
        <>
          <LoaderButton
            sizeVariant="medium"
            loading={isLoadingSecondary}
            colorVariant="secondary"
            onClick={async () => {
              setIsLoadingSecondary(true);
              await allOtherActions[0].handler();
              setIsLoadingSecondary(false);
            }}
            name={allOtherActions[0].label}
            fullWidth
          />
          <LoaderButton
            sizeVariant="medium"
            loading={isLoadingSecondary}
            colorVariant="secondary"
            onClick={async () => {
              setIsLoadingSecondary(true);
              await allOtherActions[1].handler();
              setIsLoadingSecondary(false);
            }}
            name={allOtherActions[1].label}
            fullWidth
          />
        </>
      ) : //   IF MORE THAN 2 ACTIONS IN TOTAL (including one primary)
      allOtherActions.length > 1 ? (
        <Box sx={{ width: '100%' }}>
          <StyledMenuComponent
            options={allOtherActions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'medium',
              title: polyglot.t('General.actions'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          />
        </Box>
      ) : // IF ONLY ONE SECONDARY ACTION
      allOtherActions.length === 1 ? (
        <LoaderButton
          sizeVariant="medium"
          loading={isLoadingSecondary}
          colorVariant="secondary"
          onClick={async () => {
            setIsLoadingSecondary(true);
            await allOtherActions[0].handler();
            setIsLoadingSecondary(false);
          }}
          name={allOtherActions[0].label}
          fullWidth
        />
      ) : null}

      {primaryAction && (
        <LoaderButton
          sizeVariant="medium"
          loading={isLoadingPrimary}
          colorVariant="primary"
          onClick={async () => {
            setIsLoadingPrimary(true);
            await primaryAction.handler();
            setIsLoadingPrimary(false);
          }}
          name={primaryAction.label}
          fullWidth
        />
      )}

      {handleReject && (
        <RejectDrawer
          isOpen={isRejectDrawerOpen}
          setIsOpen={setIsRejectDrawerOpen}
          onReject={async (notes?: string) => {
            await handleReject(absence, notes);
            setIsRejectDrawerOpen(false);
          }}
        />
      )}
    </Box>
  );
};

enum AbsenceActionVariant {
  primary = 'primary',
  secondary = 'secondary',
}

const getActionButtons = (
  absence: AbsenceDto,
  polyglot: Polyglot,
  setAbsence?: React.Dispatch<React.SetStateAction<AbsenceDto | undefined>>,
  onDelete?: (absence: AbsenceDto) => Promise<void>,
  onEdit?: (absence: AbsenceDto) => void,
  handleApprove?: (a: AbsenceDto) => Promise<AbsenceDto | undefined>,
  handleReject?: () => void,
  onForceApproval?: (absence: AbsenceDto, status: AbsenceStatus.Approved | AbsenceStatus.Rejected) => Promise<void>,
  onRequestCancellation?: (absence: AbsenceDto) => Promise<void>,
  onRejectCancellation?: (absence: AbsenceDto) => Promise<void>
): {
  id: string;
  label: string;
  handler: (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void | Promise<void>;
  hidden: boolean;
  variant: AbsenceActionVariant;
  icon?: React.JSX.Element;
}[] => {
  return [
    /* PRIMARY BUTTONS */
    // APPROVE
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.approve'),
      handler: async () => {
        let updatedAbsence;
        if (handleApprove) updatedAbsence = await handleApprove(absence);
        if (updatedAbsence && setAbsence) setAbsence(updatedAbsence);
      },
      hidden: !handleApprove || !absence.canApprove,
      variant: AbsenceActionVariant.primary,
      icon: <Chose {...iconSize} />,
      id: 'approve',
    },
    // APPROVE CANCELLATION
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.approveAndDelete'),
      handler: async () => {
        if (onDelete) await onDelete(absence);
      },
      hidden: !onDelete || !absence.canApproveCancellation,
      variant: AbsenceActionVariant.primary,
      id: 'approveCancellation',
    },
    // FORCE APPROVE
    {
      label: polyglot.t('AbsenceTableActions.forceApprove'),
      handler: async () => {
        if (onForceApproval) await onForceApproval(absence, AbsenceStatus.Approved);
      },
      hidden: !onForceApproval || !absence.canForceApprove,
      variant: AbsenceActionVariant.primary,
      icon: <Chose {...iconSize} />,
      id: 'forceApprove',
    },

    /* SECONDARY BUTTONS */
    // CANCEL
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.requestToCancel'),
      handler: async () => {
        if (onRequestCancellation) await onRequestCancellation(absence);
      },
      hidden: !onRequestCancellation || !absence.canRequestCancellation,
      variant: AbsenceActionVariant.secondary,
      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
      id: 'cancel',
    },
    // REJECT CANCELLATION
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.rejectCancellation'),
      handler: async () => {
        if (onRejectCancellation) await onRejectCancellation(absence);
      },
      hidden: !onRejectCancellation || !absence.canRejectCancellation,
      variant: AbsenceActionVariant.secondary,
      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
      id: 'rejectCancellation',
    },
    // FORCE REJECT
    {
      label: polyglot.t('AbsenceTableActions.forceReject'),
      handler: async () => {
        if (onForceApproval) await onForceApproval(absence, AbsenceStatus.Rejected);
      },
      hidden: !onForceApproval || !absence.canForceReject,
      variant: AbsenceActionVariant.secondary,
      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
      id: 'forceReject',
    },
    // REJECT
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.reject'),
      handler: () => {
        if (handleReject) handleReject();
      },
      hidden: !handleReject || !absence.canReject,
      variant: AbsenceActionVariant.secondary,
      icon: <Cancel {...iconSize} fill={themeColors.DarkGrey} />,
      id: 'reject',
    },
    // EDIT
    {
      label: polyglot.t('AbsenceViewerApprovalButtons.editRequest'),
      handler: () => {
        if (onEdit) onEdit(absence);
      },
      hidden: !onEdit || !absence.canEdit,
      variant: AbsenceActionVariant.secondary,
      icon: <Edit {...iconSize} />,
      id: 'edit',
    },
    // DELETE
    {
      label: polyglot.t('General.delete'),
      handler: async () => {
        if (onDelete) await onDelete(absence);
      },
      hidden: !onDelete || !absence.canDelete,
      variant: AbsenceActionVariant.secondary,
      icon: <Trash {...iconSize} />,
      id: 'delete',
    },
  ].filter((btn) => !btn.hidden);
};
