import { useState } from 'react';

import { ConfigurableDeviceData } from '@v2/feature/device/device.dto';
import { DeviceModelOs } from '@v2/feature/device/device.interface';
import { EditTechSpecsDrawerContent } from '@v2/feature/device/features/devices-company/components/edit-tech-specs-drawer.component';
import { EnrollmentStatus } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

export const DeviceDetailsComponent = ({
  configurableDevice,
  ownerName,
  refresh,
}: {
  configurableDevice: ConfigurableDeviceData;
  ownerName: string;
  refresh: () => Promise<void>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { devicePossession, order } = configurableDevice;
  const isEnrolled =
    Boolean(
      devicePossession.device?.enrollmentStatus === 'enrolled' ||
        devicePossession.device?.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
    ) ?? false;
  const getDeviceModelOsKey = (osValue: string | undefined | null): string => {
    if (!osValue) {
      return 'None';
    }
    for (const [key, value] of Object.entries(DeviceModelOs)) {
      if (value === osValue) {
        return key;
      }
    }
    return 'None';
  };
  return (
    <>
      <SettingsSubsectionContent
        sections={[
          {
            title: 'Ownership',
            items: [
              {
                type: SectionItemType.Pair,
                label: 'Used by',
                value: ownerName,
              },
              {
                type: SectionItemType.Pair,
                label: 'Used since',
                value: devicePossession.startDate,
              },
            ],
          },
        ]}
      />

      {devicePossession.device && (
        <SettingsSubsectionContent
          sections={[
            {
              title: 'About',
              onEdit: () => setIsOpen(true),
              editButtonDisabled: isEnrolled,
              items: [
                {
                  type: SectionItemType.Pair,
                  label: 'Device name',
                  value: devicePossession.device.deviceName,
                  hidden: !Boolean(devicePossession.device.deviceName),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Manufacturer',
                  value: devicePossession.device.manufacturer,
                  hidden: !Boolean(devicePossession.device.manufacturer),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Model',
                  value: devicePossession.device.modelNumber,
                  hidden: !Boolean(devicePossession.device.modelNumber),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Model name',
                  value: devicePossession.device.modelName,
                  hidden: !Boolean(devicePossession.device.modelName),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Serial number',
                  value: devicePossession.device.serialNumber,
                  hidden: !Boolean(devicePossession.device.serialNumber),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'OS',
                  value: getDeviceModelOsKey(devicePossession.device.os),
                  hidden: !Boolean(devicePossession.device.os),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'OS Version',
                  value: devicePossession.device.osVersion,
                  hidden: !Boolean(devicePossession.device.osVersion),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'RAM',
                  value: devicePossession.device.ram,
                  hidden: !Boolean(devicePossession.device.ram),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Storage',
                  value: devicePossession.device.storage,
                  hidden: !Boolean(devicePossession.device.storage),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Screen size',
                  value: devicePossession.device.screenSize,
                  hidden: !Boolean(devicePossession.device.screenSize),
                },
              ],
            },
          ]}
        />
      )}

      {order && devicePossession.device && (
        <SettingsSubsectionContent
          sections={[
            {
              title: 'Order',
              items: [
                {
                  type: SectionItemType.Pair,
                  label: 'Delivered on',
                  value: order.deliveryDate,
                  hidden: !Boolean(order.deliveryDate),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Monthly rent',
                  value: devicePossession.device.price,
                  hidden: !Boolean(devicePossession.device.price),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Contract length',
                  value: devicePossession.device.contractLength,
                  hidden: !Boolean(devicePossession.device.contractLength),
                },
                {
                  type: SectionItemType.Pair,
                  label: 'Billed by',
                  value: devicePossession.device.ownership,
                  hidden: !Boolean(devicePossession.device.ownership),
                },
              ],
            },
          ]}
        />
      )}
      {devicePossession.device && (
        <DrawerModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <EditTechSpecsDrawerContent
            devicePossessionId={devicePossession.id}
            device={devicePossession.device}
            refresh={refresh}
          />
        </DrawerModal>
      )}
    </>
  );
};
