import { ReportColumnType, ReportSelectedColumn, SelectedColumnsRequest } from '@v2/feature/reports/reports.interface';

export function unselectColumnUtil(
  prevSelection: SelectedColumnsRequest,
  stub: string,
  col: string,
  type: ReportColumnType
): SelectedColumnsRequest {
  if (!prevSelection[stub]) return prevSelection;

  const removedCol = prevSelection[stub].find((c) => c.col === col && c.type === type);
  if (!removedCol) return prevSelection;

  const newSelectedColumns = {
    ...prevSelection,
    [stub]: prevSelection[stub].filter((c) => c.col !== col || c.type !== type),
  };
  if (newSelectedColumns[stub].length === 0) delete newSelectedColumns[stub];

  // update order after removal
  for (const tableStub in newSelectedColumns) {
    newSelectedColumns[tableStub] = newSelectedColumns[tableStub].map((c) => ({
      ...c,
      // if order > removed col order, subtract 1, otherwise keep the same
      order: c.order > removedCol.order ? c.order - 1 : c.order,
    }));
  }

  return newSelectedColumns;
}

export function selectColumnUtil(
  prevSelection: SelectedColumnsRequest,
  stub: string,
  col: string,
  type: ReportColumnType,
  label: string,
  secondSelection?: SelectedColumnsRequest
): SelectedColumnsRequest {
  // if col is already selected, ignore
  if (prevSelection[stub] && prevSelection[stub].some((c) => c.col === col && c.type === type)) return prevSelection;

  // otherwise find the max order to be able to set the new item order
  let maxOrder = 0;
  for (const stub in prevSelection) {
    for (const col of prevSelection[stub]) {
      if (col.order > maxOrder) maxOrder = col.order;
    }
  }

  if (secondSelection) {
    for (const stub in secondSelection) {
      for (const col of secondSelection[stub]) {
        if (col.order > maxOrder) maxOrder = col.order;
      }
    }
  }

  // create the new column
  const newCol: ReportSelectedColumn = { col, type, order: maxOrder + 1, label };

  // push the new column
  const prevCopy = { ...prevSelection };
  if (!prevCopy[stub]) prevCopy[stub] = [];
  prevCopy[stub].push(newCol);

  // return the new object
  return prevCopy;
}
