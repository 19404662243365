/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react';

import { Box, FormControl, Stack, Typography } from '@mui/material';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { RightToWorkAPI } from '@/v2/feature/user/features/user-forms/user-right-work/user-right-work.api';
import {
  buttonBoxSx,
  fieldSx,
  titleSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { RightToWorkValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const RightToWorkSchema = Yup.object().shape({
  rightToWorkCheckDate: Yup.string().test(dateFieldTest).required('Right to work check date is required'),
  rightToWorkDocumentTypes: Yup.string().required('Document type is required'),
  rightToWorkDocuments: Yup.string().required('Document is required'),
  rightToWorkIsLimited: Yup.boolean().optional().nullable(),
  rightToWorkExpiryDate: Yup.string().test(dateFieldTest).optional().nullable(),
});

interface Props {
  readonly userId: number;
  readonly initialValues: RightToWorkValues;
  readonly fetchDocuments: () => Promise<void>;
  readonly documents: readonly {
    readonly label: string;
    readonly value: string;
    readonly type?: string;
  }[];
  readonly onSubmit: (_: RightToWorkValues) => void;
  readonly onClose: () => void;
  readonly documentTypes: readonly ZeltDocumentType[];
  readonly fetchData: () => Promise<void>;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: RightToWorkValues) => void;
}

export const RightToWorkForm = ({
  userId,
  initialValues,
  documents,
  fetchDocuments,
  onSubmit,
  onClose,
  documentTypes,
  fetchData,
  usedForDataImport = false,
  importHandler = () => {},
}: Props): JSX.Element => {
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { polyglot } = usePolyglot();

  const formik = useFormik<RightToWorkValues>({
    initialValues,
    enableReinitialize: false,
    validationSchema: RightToWorkSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          if (!values.rightToWorkDocuments) {
            showMessage('Error: Not allowed to remove Right to Work check', 'error');
          }
          if (
            values.id &&
            values.rightToWorkDocuments &&
            values.rightToWorkCheckDate &&
            values.rightToWorkDocumentTypes
          ) {
            await RightToWorkAPI.updateByUserId(userId, values);
            showMessage('Identity check successfully updated.', 'success');
          } else if (
            !values.id &&
            values.rightToWorkDocuments &&
            values.rightToWorkCheckDate &&
            values.rightToWorkDocumentTypes
          ) {
            await RightToWorkAPI.createNewRTWCheck(userId, values);
            showMessage('Identity check successfully updated.', 'success');
          }
          onSubmit({ ...values, rightToWorkChecked: true });
          await fetchData();
        } else {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage('Identity check could not be saved. Please try again.', 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteExistingManualRTWCheck = useCallback(async () => {
    try {
      setLoading(true);

      if (formik.values.id) {
        await RightToWorkAPI.deleteManualRTWCheck(userId, formik.values.id);
        showMessage('Manual RTW check successfully deleted.', 'success');
      }
      onSubmit(formik.values);
      await fetchData();
    } catch (e) {
      showMessage(`Failed to delete manual RTW check: ${nestErrorMessage(e)}`, 'error');
      setLoading(false);
    }
  }, [formik.values, onSubmit, fetchData, userId, showMessage]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box>
          <Stack flexDirection="column" sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: spacing.g10 }}>
              <Typography variant="h1" sx={titleSx}>
                Identity check
              </Typography>
              {formik.values.id && (
                <IconButton
                  disabled={loading}
                  fullWidth
                  sizeVariant="small"
                  colorVariant="secondary"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setIsRemovalModalOpen(true);
                  }}
                  name={polyglot.t('General.delete')}
                >
                  <TrashIcon {...iconSize} />
                </IconButton>
              )}
            </Box>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mb: spacing.mb20 }}>
              If you already have conducted an ID check for this employee, you can save details below.
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.rightToWorkCheckDate ?? null}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      formik.setFieldValue('rightToWorkCheckDate', value);
                    }
                  }}
                  name="rightToWorkCheckDate"
                  label="Date of check"
                  error={!!formik.errors.rightToWorkCheckDate && formik.touched.rightToWorkCheckDate}
                  helperText={formik.errors.rightToWorkCheckDate && formik.touched.rightToWorkCheckDate}
                />
              </FormControl>
            </Box>

            <Box sx={fieldSx}>
              <AutocompleteComponent
                name="rightToWorkDocuments"
                label="Right to work document"
                options={documents}
                value={documents.find((option) => option.value === formik.values.rightToWorkDocuments)}
                compareValue={formik.values.rightToWorkDocuments ?? ''}
                // @ts-ignore
                onChange={(_, x: { value: string; type?: string }) => {
                  // need to put in timeout, or formik validation is one cycle behind
                  setTimeout(() => formik.setFieldValue('rightToWorkDocuments', x?.value ?? '', true), 300);
                  formik.setFieldValue('rightToWorkDocumentTypes', x?.type ?? '', true);
                }}
                error={formik.touched.rightToWorkDocuments && Boolean(formik.errors.rightToWorkDocuments)}
                helperText={
                  (formik.touched.rightToWorkDocuments && formik.errors.rightToWorkDocuments) ||
                  (documents.length === 0 && 'Please upload a Right to work document in order to select it here')
                }
              />
            </Box>

            {/* Hide document expiry in RTW form as it has been added to documents modal */}
            {/* <Box sx={{ mb: spacing.mb20, ml: spacing.ml10, mt: spacing.mt20 }}>
            <FormControl size="small" fullWidth>
              <FormControlLabel
                componentsProps={{ typography: { sx: { ...themeFonts.caption } } }}
                label="Document has expiry"
                control={
                  <CheckboxComponent
                    label=""
                    name="rightToWorkIsLimited"
                    checked={!!formik.values.rightToWorkIsLimited}
                    onChange={(e, checked) => {
                      formik.setFieldValue('rightToWorkIsLimited', checked);
                      if (!checked) formik.setFieldValue('rightToWorkExpiryDate', null);
                    }}
                  />
                }
              />
            </FormControl>
          </Box>

          {formik.values.rightToWorkIsLimited && (
            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.rightToWorkExpiryDate ?? null}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      formik.setFieldValue('rightToWorkExpiryDate', value);
                    }
                  }}
                  name="rightToWorkExpiryDate"
                  label="Expiry date"
                  error={!!formik.errors.rightToWorkExpiryDate && formik.touched.rightToWorkExpiryDate}
                  helperText={formik.touched.rightToWorkExpiryDate && formik.errors.rightToWorkExpiryDate}
                />
              </FormControl>
            </Box>
          )} */}
          </Box>
          <NotificationModal
            isOpen={isRemovalModalOpen}
            onClose={() => setIsRemovalModalOpen(false)}
            anchorEl={anchorEl}
            takeAction={async () => {
              if (formik.values.id) await deleteExistingManualRTWCheck();
            }}
            message={`Are you sure you want to delete this check?`}
            callToAction="Yes"
          />
          <Box sx={buttonBoxSx}>
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
              {polyglot.t('General.cancel')}
            </ButtonComponent>
            <LoaderButton
              name="Save"
              loading={loading}
              fullWidth={true}
              disabled={!formik.isValid || !formik.dirty}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
