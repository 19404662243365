import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortString } from '@v2/components/table/table-sorting.util';
import { UserCell } from '@v2/components/table/user-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE } from '@/lib/routes';

interface TableProps {
  readonly holidayCalendars: HolidayCalendarDto[];
  readonly isLoading: boolean;
}

export const HolidayCalendarsTable = ({ holidayCalendars, isLoading }: TableProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const [searchInput, setSearchInput] = useState<string>('');

  const filteredCalendars = useMemo(() => {
    if (!searchInput) return holidayCalendars;

    const search = searchInput.toLowerCase();
    return holidayCalendars.filter((c) => c.name.toLowerCase().includes(search));
  }, [holidayCalendars, searchInput]);

  const columns = useMemo<ColumnDef<HolidayCalendarDto, HolidayCalendarDto>[]>(() => {
    return [
      {
        header: () => polyglot.t('General.name'),
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.name}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('General.createdBy'),
        accessorFn: (row) => row,
        id: 'createdBy',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.createdBy ? <UserCell userId={original.createdBy} /> : <EmptyCell />,
      },
    ];
  }, [polyglot]);

  const handleRowClick = useCallback(
    (row: Row<HolidayCalendarDto>) => {
      history.push(generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId: row.original.id }));
    },
    [history]
  );

  return (
    <Box>
      <Box>
        <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
      </Box>

      {holidayCalendars.length > 0 ? (
        <Box sx={{ mt: '24px' }}>
          <BasicTable<HolidayCalendarDto>
            rowData={filteredCalendars}
            columnData={columns}
            loading={isLoading}
            rowClick={handleRowClick}
          />
        </Box>
      ) : (
        <Box>
          <Typography variant="caption" color="Grey" sx={{ mt: '8px' }}>
            {polyglot.t('HolidayCalendarModule.noCustomCalendars')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
