import {
  INVOICES_COMPANY_OVERVIEW_ROUTE,
  INVOICES_ME_OVERVIEW_ROUTE,
  INVOICES_TEAM_OVERVIEW_ROUTE,
} from '@/lib/routes';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const MONEY_INVOICES_OVERVIEW_ROUTES: RoleRoutes = {
  company: INVOICES_COMPANY_OVERVIEW_ROUTE,
  me: INVOICES_ME_OVERVIEW_ROUTE,
  team: INVOICES_TEAM_OVERVIEW_ROUTE,
};

export const MONEY_INVOICE_SCOPES: RoleScopes = {
  company: ['invoices:all'],
  team: ['invoices:manager'],
  me: ['invoices'],
};
