import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { EditBillingContactDrawer } from '@v2/feature/billing/components/edit-billing-contact-drawer.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { capitalize } from 'lodash';

import { BillingPaymentMethodAPI } from '@/api-client/billing-payment-method';
import { BillingAPI } from '@/api-client/billing.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BillingInformation, EMPTY_PAYMENT_METHOD, PaymentMethod } from '@/models/subscription.model';

const extractCardExpiry = (paymentMethod: PaymentMethod): string => {
  if (!paymentMethod) return '';

  const { card } = paymentMethod;
  if (!card) return '';
  if (!card.exp_month || !card.exp_year) return '';
  return `${card.exp_month}/${card.exp_year}`;
};

export const BillingInformationSection = () => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [isEditBillingContactOpen, setIsEditBillingContactOpen] = useState<boolean>(false);
  const [billingInfo, setBillingInfo] = useState<BillingInformation | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [billingContact, setBillingContact] = useState<{
    userId: number | undefined;
    contactFullName: string | undefined;
    billingAddress: string | undefined;
    invoicesSentTo: string | undefined;
    phoneNumber: string | undefined;
  } | null>(null);

  const setPaymentInfo = useCallback((paymentMethods: PaymentMethod[]) => {
    if (!paymentMethods[0]) {
      setPaymentMethod(EMPTY_PAYMENT_METHOD);
    } else {
      setPaymentMethod(paymentMethods[0]);
    }
  }, []);

  const setBillingContactInfo = useCallback((billingInfo: BillingInformation | undefined) => {
    const billingContact = billingInfo?.billingContact;
    const paymentMethod = billingInfo?.paymentMethods[0];

    if (!billingContact && !paymentMethod) {
      setBillingContact(null);
      return;
    }

    const billingAddressFields = paymentMethod
      ? [
          paymentMethod?.billingAddress?.line1,
          paymentMethod?.billingAddress?.line2,
          paymentMethod?.billingAddress?.postal_code,
          paymentMethod?.billingAddress?.city,
          paymentMethod?.billingAddress?.state,
          paymentMethod?.billingAddress?.country,
        ].filter(Boolean)
      : [];

    setBillingContact({
      userId: billingContact?.userId,
      contactFullName: billingContact?.user
        ? `${billingContact.user.firstName} ${billingContact.user.lastName}`
        : undefined,
      billingAddress: billingAddressFields.length > 0 ? billingAddressFields.join(', ') : undefined,
      invoicesSentTo: billingContact?.invoiceEmail ?? undefined,
      phoneNumber: billingContact?.phoneNumber ?? undefined,
    });
  }, []);

  const fetchBillingInfo = useCallback(async () => {
    try {
      const billingInfo = await BillingAPI.getBillingInformation();
      setBillingInfo(billingInfo);
      setBillingContactInfo(billingInfo);
      setPaymentInfo(billingInfo?.paymentMethods ?? []);
    } catch (error) {
      showMessage(
        `${polyglot.t('BillingInformationSection.errorMessages.infoNotFetch')}. ${nestErrorMessage(error)}`,
        'error'
      );
    }
  }, [polyglot, setBillingContactInfo, setPaymentInfo, showMessage]);

  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo]);

  const getPaymentSetupLink = async () => {
    try {
      const paymentSetupLink = await BillingPaymentMethodAPI.getSetupLink();
      if (!paymentSetupLink?.url) throw new Error('Link not received');

      window.location.replace(paymentSetupLink.url);
    } catch (error) {
      const errorAsError = (error as unknown) as Error;
      console.error('Failed to obtain payment method setup link', errorAsError.message);
      showMessage(
        `${polyglot.t('BillingInformationSection.errorMessages.setupSession')} ${
          nestErrorMessage(error) || errorAsError.message
        }`,
        'error'
      );
    }
  };

  return billingInfo ? (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '400px', bgcolor: themeColors.Background, p: spacing.p30, borderRadius: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Typography sx={themeFonts.title2}>{polyglot.t('BillingInformationSection.billingInformation')}</Typography>
          <StyledMenuComponent
            options={[
              {
                label: polyglot.t('BillingInformationSection.editContact'),
                handler: () => {
                  setIsEditBillingContactOpen(true);
                },
              },
              {
                label: polyglot.t('BillingInformationSection.updatePayment'),
                handler: getPaymentSetupLink,
              },
            ]}
            actionButtonDetails={{
              type: 'iconButton',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: 'actions',
              icon: <ActionsSmall {...iconSize} />,
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g15, mt: spacing.m20 }}>
          {billingContact?.userId && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
                {polyglot.t('BillingInformationSection.billingContact')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5, width: '240px' }}>
                <UserAvatar userId={billingContact.userId} size="xxsmall" />
                <Typography sx={themeFonts.title4}>{billingContact.contactFullName}</Typography>
              </Box>
            </Box>
          )}

          {billingContact?.billingAddress && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
                {polyglot.t('BillingInformationSection.billingAddress')}
              </Typography>
              <Box sx={{ display: 'flex', width: '240px' }}>
                <Typography sx={themeFonts.title4}>{billingContact.billingAddress}</Typography>
              </Box>
            </Box>
          )}

          {billingContact?.invoicesSentTo && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
                {polyglot.t('BillingInformationSection.invoicesSentTo')}
              </Typography>
              <Typography sx={{ ...themeFonts.title4, width: '240px' }}>{billingContact.invoicesSentTo}</Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: spacing.g20 }}>
            <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
              {polyglot.t('BillingInformationSection.phoneNumber')}
            </Typography>
            {billingContact?.phoneNumber ? (
              <Typography sx={{ ...themeFonts.title4, width: '240px' }}>{billingContact?.phoneNumber}</Typography>
            ) : (
              <Typography
                sx={{
                  ...themeFonts.title4,
                  width: '240px',
                  color: themeColors.Red,
                }}
              >
                {polyglot.t('BillingInformationSection.missingPhoneNumber')}
              </Typography>
            )}
          </Box>

          {paymentMethod?.card?.last4 && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
                {polyglot.t('BillingInformationSection.cardNumber')}
              </Typography>
              <Typography sx={{ ...themeFonts.title4, width: '240px' }}>
                **** **** **** {paymentMethod.card.last4}
              </Typography>
            </Box>
          )}

          {paymentMethod?.card?.brand && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
                {polyglot.t('BillingInformationSection.paymentMethod')}
              </Typography>
              <Typography sx={{ ...themeFonts.title4, width: '240px' }}>
                {capitalize(paymentMethod.card.brand)}
              </Typography>
            </Box>
          )}

          {paymentMethod?.card?.last4 && billingContact?.contactFullName && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>
                {polyglot.t('BillingInformationSection.cardHolder')}
              </Typography>
              <Typography sx={{ ...themeFonts.title4, width: '240px' }}>{billingContact.contactFullName}</Typography>
            </Box>
          )}

          {paymentMethod?.card?.exp_month && paymentMethod.card.exp_month > -1 && (
            <Box sx={{ display: 'flex', gap: spacing.g20 }}>
              <Typography sx={{ ...themeFonts.caption, width: '140px' }}>{}</Typography>
              <Typography sx={{ ...themeFonts.title4, width: '240px' }}>{extractCardExpiry(paymentMethod)}</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {isEditBillingContactOpen && (
        <EditBillingContactDrawer
          isOpen={isEditBillingContactOpen}
          setIsOpen={setIsEditBillingContactOpen}
          billingContact={billingInfo?.billingContact}
          refresh={fetchBillingInfo}
        />
      )}
    </Box>
  ) : null;
};
