import React, { CSSProperties } from 'react';

import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';

import { createHeadlessForm } from './json-schema-form';

import { useScrollToFormError } from '@/hooks/use-scroll-to-form-error.hook';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { TypeableDateComponent } from '@/v2/components/forms/typeable-date.component';
import { StyledRadioGroup } from '@/v2/components/theme-components/styled-radio-group.component';
import {
  getFormikValidationHandler,
  mapSchemaFieldsToFormikObject,
  ParserFields,
  SchemaValues,
} from '@/v2/infrastructure/forms/remote/formik-helper.util';
import { SchemaField } from '@/v2/infrastructure/forms/remote/schema-field-definitions.interface';
import { secondaryTableSmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

const Description = ({ text, sx }: { text: string; sx?: SxProps<Theme> }) => {
  // the schema unfortunately defines descriptions as raw HTML (in order to include external links), so we need
  // to use `dangerouslySetInnerHTML` to display them properly
  return text ? (
    <Box sx={sx}>
      <div
        style={{ ...themeFonts.captionSmall, color: themeColors.DarkGrey }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Box>
  ) : (
    <></>
  );
};

type RemoteFormBuilderProps = {
  values?: any;
  schema: any;
  children?: React.ReactNode;
  onSubmit: (values: SchemaValues, formikHelpers: FormikHelpers<SchemaValues>) => void | Promise<void>;
  style?: CSSProperties;
};

export function RemoteFormBuilder({ onSubmit, schema, children, style, values }: RemoteFormBuilderProps) {
  const { fields, handleValidation } = React.useMemo(() => {
    return (createHeadlessForm(
      {
        schema: schema.data,
        version: (null as unknown) as Object,
      },
      values ?? {}
    ) as unknown) as ParserFields;
  }, [schema.data, values]);

  const initialValues = React.useMemo(() => values ?? mapSchemaFieldsToFormikObject(fields), [fields, values]);

  const formik = useFormik({
    initialValues,
    validate: getFormikValidationHandler(handleValidation),
    onSubmit,
  });

  useScrollToFormError(formik);

  const { isSubmitting } = formik;
  const hasSubmitted = formik.submitCount > 0;

  /**
   * Convert a field definition into a React element
   * @param field field definition to convert
   * @param prefix dotted name prefix for nested object values.
   */
  const generateComponent = (field: SchemaField, prefix = ''): JSX.Element | null => {
    if (field.isVisible === false) {
      return null;
    }
    const fieldName = prefix + field.name;
    if (field.type === 'select') {
      const { label, description, options } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <SelectComponent
            name={fieldName}
            label={label}
            options={options}
            value={formik.getFieldProps(fieldName).value ?? ''}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'countries') {
      const { label, description, options } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <SelectComponent
            name={fieldName}
            label={label}
            options={options}
            value={formik.getFieldProps(fieldName).value ?? ''}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'number') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <TextfieldComponent
            name={fieldName}
            label={label}
            type="tel"
            value={formik.getFieldProps(fieldName).value}
            onChange={(e) => {
              const n = Number(e.target.value);
              formik.setFieldValue(fieldName, Number.isNaN(n) ? e.target.value : n);
            }}
            disabled={isSubmitting}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'text') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <TextfieldComponent
            name={fieldName}
            label={label}
            type="text"
            value={formik.getFieldProps(fieldName).value}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'textarea') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <Typography sx={themeFonts.caption}>{label}</Typography>
          <TextfieldComponent
            multiline
            name={fieldName}
            type="text"
            value={formik.getFieldProps(fieldName).value}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'radio') {
      const { label, description, options } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <Typography sx={themeFonts.title4}>{label}</Typography>
          <Description text={description} sx={{ mt: '5px' }} />
          <StyledRadioGroup
            name={fieldName}
            options={options ?? []}
            selectedValue={formik.getFieldMeta(fieldName).value}
            onChange={formik.handleChange}
            disabled={isSubmitting}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
            radioSx={{ paddingY: '2px' }}
          />
        </Stack>
      );
    }

    if (field.type === 'checkbox') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <Description text={description} />
          <CheckboxComponent
            name={fieldName}
            label={label}
            value={formik.getFieldProps(fieldName).value}
            onChange={formik.handleChange}
            disabled={isSubmitting}
          />
        </Stack>
      );
    }

    if (field.type === 'date') {
      const { label, description /*, minDate */ } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '2px' }}>
          <TypeableDateComponent
            name={fieldName}
            label={label + ' (dd/mm/yyyy)'}
            value={formik.getFieldProps(fieldName).value}
            onChange={(value) => formik.setFieldValue(fieldName, value)}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
            disabled={isSubmitting}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'money') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <Typography sx={themeFonts.title4}>{label}</Typography>
          <TextfieldComponent
            type="tel"
            name={fieldName}
            value={formik.getFieldProps(fieldName).value}
            onChange={formik.handleChange}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
            disabled={isSubmitting}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'email') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <TextfieldComponent
            type="email"
            label={label}
            name={fieldName}
            value={formik.getFieldProps(fieldName).value}
            onChange={formik.handleChange}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
            disabled={isSubmitting}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'tel') {
      const { label, description } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <TextfieldComponent
            type="tel"
            label={label}
            name={fieldName}
            value={formik.getFieldProps(fieldName).value}
            onChange={formik.handleChange}
            error={hasSubmitted && !!formik.getFieldMeta(fieldName).error}
            helperText={hasSubmitted && formik.getFieldMeta(fieldName).error}
            disabled={isSubmitting}
          />
          <Description text={description} />
        </Stack>
      );
    }

    if (field.type === 'fieldset') {
      const { label, description, fields } = field;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <Typography sx={themeFonts.title4}>{label}</Typography>
          <Description text={description} />
          <Stack sx={{ gap: '15px', mt: '10px' }}>
            {fields?.map((field) => generateComponent(field, fieldName + '.'))}
          </Stack>
        </Stack>
      );
    }

    if (field.type === 'group-array') {
      const { name, label, description, fields } = field;
      const fieldName = prefix + name;
      const groupFields = fields();
      const items = (formik.values[fieldName] as unknown) as Array<{}>;
      return (
        <Stack key={fieldName} sx={{ gap: '5px' }}>
          <Typography sx={themeFonts.caption}>{label}</Typography>
          <Description text={description} />
          {items?.map((_, idx) => (
            <Stack
              key={fieldName + idx}
              sx={{
                m: '5px 0',
                p: '10px 20px',
                gap: '30px',
                position: 'relative',
                borderTop: '1px solid #ddd',
              }}
            >
              {groupFields?.map((field) => generateComponent(field, `${fieldName}.${idx}.`))}
              <Button
                sx={{ ...secondaryTableSmallBtn, width: 'min-content' }}
                onClick={() =>
                  formik.setFieldValue(
                    fieldName,
                    items.filter((_, i) => i !== idx)
                  )
                }
              >
                {'Delete'}
              </Button>
            </Stack>
          ))}
          <Button
            sx={{ ...secondaryTableSmallBtn, mt: '5px', width: 'min-content' }}
            onClick={() => {
              formik.setFieldValue(fieldName, [...items, {}]);
            }}
          >
            {'Add...'}
          </Button>
        </Stack>
      );
    }

    return (
      <Typography key={fieldName} sx={{ ...themeFonts.caption, color: themeColors.darkRed }}>
        {
          // keep this here in case a new field type appears in a schema definition
          // @ts-expect-error - Property 'type' does not exist on type 'never'
          `Field type '${field.type}' not supported`
        }
      </Typography>
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={style}>
        {fields.map((field) => generateComponent(field))}
        {children}
      </Form>
    </FormikProvider>
  );
}
