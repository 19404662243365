import { ReportDto } from '@v2/feature/reports/reports.dto';
import Polyglot from 'node-polyglot';

import { Scope } from '@/models';
import { OptionObject } from '@/v2/components/forms/select.component';

export enum DataDomain {
  People = 'People',
  Payroll = 'Payroll',
  TimeAway = 'Time Away',
  Attendance = 'Attendance',
  Devices = 'Devices',
}

export const domainArray = (polyglot: Polyglot) => {
  return [
    { name: DataDomain.People, label: polyglot.t('domainArray.people') },
    { name: DataDomain.Payroll, label: polyglot.t('domainArray.payroll') },
    { name: DataDomain.TimeAway, label: polyglot.t('domainArray.timeAway') },
    { name: DataDomain.Attendance, label: polyglot.t('domainArray.attendance') },
    { name: DataDomain.Devices, label: polyglot.t('domainArray.devices') },
  ];
};

export type ReportConfigType =
  | ReportTypePeopleSelection
  | ReportTypeTimeSelection
  | ReportTypeDevicesSelection
  | ReportTypeAttendanceSelection;

export enum ReportTypePeopleSelection {
  CurrentRecords = 'Current records',
  ChangeReport = 'Change report',
}

export const reportTypeArray = (polyglot: Polyglot, v2 = false) => {
  return [
    {
      name: ReportTypePeopleSelection.CurrentRecords,
      desc: polyglot.t('reportTypeArray.export'),
      label: polyglot.t('reportTypeArray.current'),
      disabled: false,
    },
    {
      name: ReportTypePeopleSelection.ChangeReport,
      desc: polyglot.t('reportTypeArray.track'),
      label: polyglot.t('reportTypeArray.change'),
      disabled: v2,
    },
  ];
};

export enum ReportTypeTimeSelection {
  Requests = 'Requests',
  Balances = 'Balances',
}

export enum ReportTypeDevicesSelection {
  Devices = 'Devices',
}

export enum ReportTypeAttendanceSelection {
  Attendance = 'Attendance',
}

export enum ReportEntity {
  // PEOPLE REPORT
  // User tab
  User = 'User',
  Basic = 'Basic',
  Details = 'Details',
  Family = 'Family',
  About = 'About',
  // The remaining of tabs
  AccountInfo = 'Account info',
  EmergencyContact = 'Emergency contact',
  WorkContact = 'Work contact',
  RightToWork = 'Right to work',
  Role = 'Role',
  Contract = 'Contract',
  Lifecycle = 'Lifecycle',
  Compensation = 'Compensation',
  BankAccount = 'Bank account',
  Equity = 'Equity',
  Address = 'Address',
  // TIME REPORT
  Requests = 'Requests',
  Balances = 'Balances',
  // DEVICES REPORT
  Devices = 'Devices',
  // ATTENDANCE REPORT
  Attendance = 'Attendance',
  // PAYROLL
  Payroll = 'Payroll',
  TaxInformation = 'Tax information',
  Salary = 'Salary', // TODO: is this needed?
}

export interface EntitiesInterface {
  name: ReportEntity;
  scope: Scope[];
  type: ReportTypePeopleSelection[];
}

export const UserEntitiesArray: EntitiesInterface[] = [
  { name: ReportEntity.Basic, scope: ['user.basicInfo:all'], type: [ReportTypePeopleSelection.CurrentRecords] },
  { name: ReportEntity.Details, scope: ['user.personalInfo:all'], type: [ReportTypePeopleSelection.CurrentRecords] },
  { name: ReportEntity.Family, scope: ['user.family:all'], type: [ReportTypePeopleSelection.CurrentRecords] },
  { name: ReportEntity.About, scope: ['user.about:all'], type: [ReportTypePeopleSelection.CurrentRecords] },
  {
    name: ReportEntity.AccountInfo,
    scope: ['user.accountInfo:read:all'],
    type: [ReportTypePeopleSelection.CurrentRecords],
  },
  {
    name: ReportEntity.Role,
    scope: ['user.role:all'],
    type: [ReportTypePeopleSelection.ChangeReport, ReportTypePeopleSelection.CurrentRecords],
  },
  {
    name: ReportEntity.Contract,
    scope: ['user.contract:all'],
    type: [ReportTypePeopleSelection.ChangeReport, ReportTypePeopleSelection.CurrentRecords],
  },
  {
    name: ReportEntity.Lifecycle,
    scope: ['user.lifecycle:all'],
    type: [ReportTypePeopleSelection.ChangeReport, ReportTypePeopleSelection.CurrentRecords],
  },
  { name: ReportEntity.RightToWork, scope: ['user.rightWork:all'], type: [ReportTypePeopleSelection.CurrentRecords] },
  {
    name: ReportEntity.Compensation,
    scope: ['user.compensation:all'],
    type: [ReportTypePeopleSelection.ChangeReport, ReportTypePeopleSelection.CurrentRecords],
  },
  {
    name: ReportEntity.TaxInformation,
    scope: ['user.compensation:all'],
    type: [ReportTypePeopleSelection.CurrentRecords],
  },
  {
    name: ReportEntity.BankAccount,
    scope: ['user.bankAccount:all'],
    type: [ReportTypePeopleSelection.ChangeReport, ReportTypePeopleSelection.CurrentRecords],
  },
  { name: ReportEntity.Equity, scope: ['user.equity:all'], type: [ReportTypePeopleSelection.ChangeReport] },
  {
    name: ReportEntity.Address,
    scope: ['user.address:all'],
    type: [ReportTypePeopleSelection.ChangeReport, ReportTypePeopleSelection.CurrentRecords],
  },
  {
    name: ReportEntity.EmergencyContact,
    scope: ['user.emergencyContact:all'],
    type: [ReportTypePeopleSelection.CurrentRecords],
  },
  { name: ReportEntity.WorkContact, scope: ['user.workContact:all'], type: [ReportTypePeopleSelection.CurrentRecords] },
];

export enum DateType {
  Last30days = 'last30days',
  Last7days = 'last7days',
  SelectDates = 'selectDates',
}

export type ReportColumnsOld = Partial<Record<ReportEntity, string[]>>;

export interface FormDataInterface {
  domain: DataDomain;
  type: ReportConfigType;
  entities: ReportEntity[];
  start: string;
  end: string;
  dateType: DateType;
  filters: string;
  userFilters?: string;
  columns: ReportColumnsOld;
  singleNumericFilter1?: number;
}

export interface CreateReport {
  domain: DataDomain;
  type: ReportConfigType;
  entities?: ReportEntity[];
  start?: string;
  end?: string;
  dateType?: DateType;
  filters: string;
  fileName: string;
  columns: ReportColumnsOld;
  singleNumericFilter1?: number;
}

export type UpdateReportV2 = Pick<ReportDto, 'name' | 'filters' | 'columns'>;

export type CreateReportV2 = Pick<ReportDto, 'name' | 'filters' | 'columns'>;

export interface UpdateReportOld {
  columns: ReportColumnsOld;
  fileName: string;
  filters?: string;
  singleNumericFilter1?: number;
}

enum FilterType {
  Category = 'Category',
  Number = 'Number',
}

export interface PeopleFiltersObject {
  departmentsOptions: readonly OptionObject[];
  sitesOptions: readonly OptionObject[];
  genderOptions: readonly OptionObject[];
  accountStatusOptions: readonly OptionObject[];
  jobTitlesOptions: readonly OptionObject[];
  nationalitiesOptions: readonly OptionObject[];
  employmentTypeOptions: readonly OptionObject[];
  contractTypeOptions: readonly OptionObject[];
  publicHolidaysOptions: readonly OptionObject[];
  entityNameOptions: readonly OptionObject[];
  userStatusOptions: readonly OptionObject[];
  userEventChangeTypeOptions: readonly OptionObject[];
  rightToWorkCheckedOptions: readonly OptionObject[];
  rightToWorkDocumentTypesOptions: readonly OptionObject[];
  payScheduleOptions: readonly OptionObject[];
  salaryBasisOptions: readonly OptionObject[];
  currencyOptions: readonly OptionObject[];
  payrollCountryOptions: readonly OptionObject[];
  inPayrollOptions: readonly OptionObject[];
  NITableOptions: readonly OptionObject[];
  taxCodeOptions: readonly OptionObject[];
  legalGenderOptions: readonly OptionObject[];
  studentLoanOptions: readonly OptionObject[];
  postgradLoanOptions: readonly OptionObject[];
  starterDeclarationOptions: readonly OptionObject[];
  bankNameOptions: readonly OptionObject[];
  bankCountryOptions: readonly OptionObject[];
  bankCurrencyOptions: readonly OptionObject[];
  addressCityOptions: readonly OptionObject[];
  addressCountryOptions: readonly OptionObject[];
  polyglot: Polyglot;
}

export const PeopleDomainReportFilters = ({
  departmentsOptions,
  sitesOptions,
  genderOptions,
  accountStatusOptions,
  jobTitlesOptions,
  nationalitiesOptions,
  employmentTypeOptions,
  contractTypeOptions,
  publicHolidaysOptions,
  entityNameOptions,
  userStatusOptions,
  userEventChangeTypeOptions,
  rightToWorkCheckedOptions,
  rightToWorkDocumentTypesOptions,
  payScheduleOptions,
  salaryBasisOptions,
  currencyOptions,
  // "tax-information" domain
  payrollCountryOptions,
  inPayrollOptions,
  NITableOptions,
  taxCodeOptions,
  legalGenderOptions,
  studentLoanOptions,
  postgradLoanOptions,
  starterDeclarationOptions,
  // 'bank' domain
  bankNameOptions,
  bankCountryOptions,
  bankCurrencyOptions,
  // 'address' domain
  addressCityOptions,
  addressCountryOptions,
  polyglot,
}: PeopleFiltersObject): Record<string, string | readonly OptionObject[]>[] => {
  return [
    {
      name: polyglot.t('PeopleDomainReportFilters.gender'),
      domain: ReportEntity.Details,
      type: FilterType.Category,
      options: genderOptions,
      field: 'gender',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.nationality'),
      domain: ReportEntity.Details,
      type: FilterType.Category,
      options: nationalitiesOptions,
      field: 'nationality',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.accountStatus'),
      domain: ReportEntity.AccountInfo,
      type: FilterType.Category,
      options: accountStatusOptions,
      field: 'accountStatus',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.jobTitle'),
      domain: ReportEntity.Role,
      type: FilterType.Category,
      options: jobTitlesOptions,
      field: 'jobTitle',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.departmentId'),
      domain: ReportEntity.Role,
      type: FilterType.Category,
      options: departmentsOptions,
      field: 'departmentId',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.site'),
      domain: ReportEntity.Role,
      type: FilterType.Category,
      options: sitesOptions,
      field: 'siteId',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.type'),
      domain: ReportEntity.Contract,
      type: FilterType.Category,
      options: employmentTypeOptions,
      field: 'type',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.contract'),
      domain: ReportEntity.Contract,
      type: FilterType.Category,
      options: contractTypeOptions,
      field: 'contract',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.publicHolidays'),
      domain: ReportEntity.Contract,
      type: FilterType.Category,
      options: publicHolidaysOptions,
      field: 'publicHolidays',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.entityId'),
      domain: ReportEntity.Contract,
      type: FilterType.Category,
      options: entityNameOptions,
      field: 'entityId',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.status'),
      domain: ReportEntity.Lifecycle,
      type: FilterType.Category,
      options: userStatusOptions,
      field: 'status',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.changeType'),
      domain: ReportEntity.Lifecycle,
      type: FilterType.Category,
      options: userEventChangeTypeOptions,
      field: 'changeReason',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.rightToWorkChecked'),
      domain: ReportEntity.RightToWork,
      type: FilterType.Category,
      options: rightToWorkCheckedOptions,
      field: 'rightToWorkChecked',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.rightToWorkDocumentTypes'),
      domain: ReportEntity.RightToWork,
      type: FilterType.Category,
      options: rightToWorkDocumentTypesOptions,
      field: 'rightToWorkDocumentTypes',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.paySchedule'),
      domain: ReportEntity.Compensation,
      type: FilterType.Category,
      options: payScheduleOptions,
      field: 'paySchedule',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.salaryBasis'),
      domain: ReportEntity.Compensation,
      type: FilterType.Category,
      options: salaryBasisOptions,
      field: 'salaryBasis',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.currency'),
      domain: ReportEntity.Compensation,
      type: FilterType.Category,
      options: currencyOptions,
      field: 'currency',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.inPayroll'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: inPayrollOptions,
      field: 'inPayroll',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.payrollCountry'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: payrollCountryOptions,
      field: 'countryCode',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.niTable'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: NITableOptions,
      field: 'niTable',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.taxCode'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: taxCodeOptions,
      field: 'taxCode',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.legalGender'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: legalGenderOptions,
      field: 'legalGender',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.studentLoan'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: studentLoanOptions,
      field: 'studentLoan',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.postgradLoan'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: postgradLoanOptions,
      field: 'postgradLoan',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.starterDeclaration'),
      domain: ReportEntity.TaxInformation,
      type: FilterType.Category,
      options: starterDeclarationOptions,
      field: 'starterDeclaration',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.bankName'),
      domain: ReportEntity.BankAccount,
      type: FilterType.Category,
      options: bankNameOptions,
      field: 'bankName',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.country'),
      domain: ReportEntity.BankAccount,
      type: FilterType.Category,
      options: bankCountryOptions,
      field: 'country',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.bankCurrency'),
      domain: ReportEntity.BankAccount,
      type: FilterType.Category,
      options: bankCurrencyOptions,
      field: 'currency',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.city'),
      domain: ReportEntity.Address,
      type: FilterType.Category,
      options: addressCityOptions,
      field: 'city',
    },
    {
      name: polyglot.t('PeopleDomainReportFilters.addressCountry'),
      domain: ReportEntity.Address,
      type: FilterType.Category,
      options: addressCountryOptions,
      field: 'country',
    },
  ];
};

export const TimeDomainReportFilters = (policies: OptionObject[], polyglot: Polyglot, statuses?: OptionObject[]) => {
  return [
    {
      name: polyglot.t('TimeDomainReportFilters.policy'),
      domain: 'Time',
      type: FilterType.Category,
      options: policies,
      field: 'policy',
    },
    ...(statuses
      ? [
          {
            name: polyglot.t('TimeDomainReportFilters.status'),
            domain: 'Time',
            type: FilterType.Category,
            options: statuses,
            field: 'status',
          },
        ]
      : []),
  ];
};

export const AttendanceDomainReportFilters = (
  schedules: OptionObject[],
  statuses: OptionObject[],
  entities: OptionObject[],
  polyglot: Polyglot
) => {
  return [
    {
      name: polyglot.t('AttendanceDomainReportFilters.schedule'),
      domain: 'Attendance',
      type: FilterType.Category,
      options: schedules,
      field: 'schedule',
    },
    {
      name: polyglot.t('AttendanceDomainReportFilters.status'),
      domain: 'Attendance',
      type: FilterType.Category,
      options: statuses,
      field: 'status',
    },
    {
      name: polyglot.t('AttendanceDomainReportFilters.entity'),
      domain: 'Attendance',
      type: FilterType.Category,
      options: entities,
      field: 'entity',
    },
  ];
};

export const DeviceDomainReportFilters = (
  typeOptions: OptionObject[],
  ownershipOptions: OptionObject[],
  platformOptions: OptionObject[] | null,
  modelOptions: OptionObject[] | null,
  polyglot: Polyglot
) => {
  return [
    {
      name: polyglot.t('DeviceDomainReportFilters.type'),
      domain: 'Devices',
      type: FilterType.Category,
      options: typeOptions,
      field: 'type',
    },
    {
      name: polyglot.t('DeviceDomainReportFilters.modelName'),
      domain: 'Devices',
      type: FilterType.Category,
      options: modelOptions,
      field: 'modelName',
    },
    {
      name: polyglot.t('DeviceDomainReportFilters.platform'),
      domain: 'Devices',
      type: FilterType.Category,
      options: platformOptions,
      field: 'platform',
    },
    {
      name: polyglot.t('DeviceDomainReportFilters.ownership'),
      domain: 'Devices',
      type: FilterType.Category,
      options: ownershipOptions,
      field: 'ownership',
    },
  ];
};

export interface ReportConfig {
  domain: DataDomain;
  type: ReportConfigType;
  entities: ReportEntity[];
  start?: string;
  end?: string;
  dateType?: DateType;
  filters: string;
  userFilters?: string;
  columns: ReportColumnsOld;
  singleNumericFilter1?: number;
}

export type ReportCustomFields = Record<string, { label: string; value: string; entity: ReportEntity }[] | undefined>;

export enum FilterTypeOption {
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  intOption = 'intOption',
  stringOption = 'stringOption',
  dateonly = 'dateonly',
  datetime = 'datetime',
  time = 'time',
}

export interface ReportFilterOption {
  label: string;
  type: FilterTypeOption;
  options: { value: string | number; label: string }[];
}

export interface ReportColumnOption {
  label: string;
  mandatory?: boolean;
  preselected?: boolean;
  operators?: ReportColumnType[]; // undefined === [ReportColumnType.PLAIN]
}

export interface ReportFilterCategory {
  category: string;
  stub: string;
  filters: Record<string, ReportFilterOption>;
}

export interface ReportColumnCategory {
  category: string;
  stub: string;
  columns: Record<string, ReportColumnOption>;
}

export enum ReportSQLOperator {
  eq = '=',
  gte = '>=',
  lte = '<=',
  gt = '>',
  lt = '<',
  not = '<>',
  in = 'in',
  is = 'is',
  contains = 'contains',
  between = '><',
  isKnown = '!null',
  isUnknown = 'null',
  startsWith = 'startsWith',
  endsWith = 'endsWith',

  this = 'this',
  last = 'last',
  next = 'next',

  // DATE PART
  dayIs = 'dayIs',
  monthIs = 'monthIs',
  yearIs = 'yearIs',
}

export interface ReportQueryFilter {
  tableName: string;
  columnName: string;
  op: ReportSQLOperator;
  value: string | number | string[] | number[];
  isMandatory?: boolean;
}

export enum ReportColumnType {
  PLAIN = 'PLAIN',
  MIN = 'MIN',
  MAX = 'MAX',
  SUM = 'SUM',
  AVG = 'AVG',
  COUNT = 'COUNT',
  AGE = 'AGE',
}

export type ReportSelectedColumn = { col: string; type: ReportColumnType; order: number; label: string };
export type SelectedColumnsRequest = Record<string, ReportSelectedColumn[]>;

export type SelectedFiltersRequest = Record<
  string,
  Record<string, Pick<ReportQueryFilter, 'op' | 'value' | 'isMandatory'>[]>
>;

export type ReportFormattedValue = {
  raw: string | number | object;
  value: string;
  type: 'string' | 'number' | 'boolean' | 'date' | 'object';
  label: string;
  order: number;
};

export type ReportResponseEntry = Record<string, ReportFormattedValue>;

export type ReportResponse = {
  header: {
    labels: Record<string, string>;
    order: Record<string, number>;
  };
  data: ReportResponseEntry[];
};

export type ReportFilterSelected = Pick<ReportQueryFilter, 'op' | 'value'> & {
  stub: string;
  filterKey: string;
  type: 'new' | 'edit';
};
