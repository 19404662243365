import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContractorInvoiceLineItem, TaxRateOptions } from '@/v2/feature/payments/payments.interface';
import {
  calculateGrossAmountForInvoice,
  getInvoiceTotalsForIndividualLineItem,
  isTaxIncluded,
} from '@/v2/feature/payments/utils/invoice.util';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  updateLineItems: (updatedValues: Partial<ContractorInvoiceLineItem>[]) => void;
  readonly existingLineItems: Partial<ContractorInvoiceLineItem>[];
  readonly accountingCodeOptions: OptionObject[];
  readonly indexToEdit?: number;
}

export function getNewLineItemValidationSchema(polyglot: Polyglot) {
  return yup.object({
    accountingCode: yup.number().nullable(),
    accountingCodeDescription: yup.string().nullable(),
    amount: yup
      .number()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    gross: yup
      .number()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
  });
}

export const ContractorInvoiceLineItemDrawer = ({
  isOpen,
  setIsOpen,
  updateLineItems,
  indexToEdit,
  existingLineItems,
  accountingCodeOptions,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ContractorInvoiceLineItemDrawerContent
        indexToEdit={indexToEdit}
        setIsOpen={setIsOpen}
        updateLineItems={updateLineItems}
        existingLineItems={existingLineItems}
        accountingCodeOptions={accountingCodeOptions}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  updateLineItems: (updatedValues: Partial<ContractorInvoiceLineItem>[]) => void;
  readonly existingLineItems: Partial<ContractorInvoiceLineItem>[];
  readonly accountingCodeOptions: OptionObject[];
  readonly indexToEdit?: number;
}

export const ContractorInvoiceLineItemDrawerContent = ({
  indexToEdit,
  existingLineItems,
  updateLineItems,
  accountingCodeOptions,
  setIsOpen,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const indexExists = indexToEdit !== undefined && indexToEdit !== null;
  const lineItemToEdit =
    indexExists && indexToEdit >= 0 && existingLineItems?.length > 0 ? existingLineItems[indexToEdit] : null;

  const onSubmit = useCallback(
    async (values: Partial<ContractorInvoiceLineItem>) => {
      try {
        setLoading(true);
        let updatedItems = [];
        if (indexExists && existingLineItems) {
          // updating existing line items
          updatedItems = [...existingLineItems];
          updatedItems[indexToEdit] = values;
        } else {
          updatedItems = [...existingLineItems];
          updatedItems.push(values);
        }
        updateLineItems(updatedItems);
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [indexExists, existingLineItems, updateLineItems, setIsOpen, indexToEdit, showMessage, polyglot]
  );

  const formik = useFormik<Partial<ContractorInvoiceLineItem>>({
    initialValues: {
      accountingCode: undefined,
      accountingCodeDescription: '',
      amount: undefined,
      gross: undefined,
      taxRate: undefined,
      isTaxIncluded: false,
      ...lineItemToEdit,
    },
    enableReinitialize: indexExists,
    validationSchema: getNewLineItemValidationSchema(polyglot),
    onSubmit,
  });

  useEffect(() => {
    let { gross } =
      formik.values.amount && formik.values.taxRate
        ? calculateGrossAmountForInvoice(formik.values.amount, formik.values.taxRate)
        : { gross: formik.values.amount };
    formik.setFieldValue('gross', gross);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.taxRate, formik.values.amount]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">
          {polyglot.t(indexToEdit ? 'NewExpensePage.editLineItem' : 'NewExpensePage.newLineItem')}
        </Typography>

        {
          <SelectComponent
            name="accountingCode"
            label={polyglot.t('NewExpensePage.accountingCode')}
            options={accountingCodeOptions ?? []}
            value={formik.values.accountingCode}
            onChange={(e) => {
              const matchingType = accountingCodeOptions?.find((o) => o.value === e.target.value);
              if (e.target.value > 0) {
                formik.setFieldValue('accountingCode', e.target.value);
                formik.setFieldValue('accountingCodeDescription', matchingType?.label);
              }
            }}
          />
        }
        {/* 
        <TextfieldComponent
          name="accountingCode"
          label={polyglot.t('NewExpensePage.accountingCode')}
          value={formik.values.accountingCode}
          onChange={(e) => {
            setLineItemInDrawer((prev) => ({ ...prev, accountingCode: Number(e.target.value) }));
          }}
          error={formik.touched.accountingCode && !!formik.errors.accountingCode}
          helperText={(formik.touched.accountingCode && formik.errors.accountingCode) ?? ' '}
          endAdornment="none"
        /> */}

        <TextfieldComponent
          name="gross"
          label={polyglot.t('PayItemModule.amount')}
          value={formik.values.amount}
          onChange={(e) => {
            const amountForLineItem = e.target.value ? Number(e.target.value) : 0;
            const { totalGross, totalAmount } = getInvoiceTotalsForIndividualLineItem(
              amountForLineItem,
              formik.values.taxRate ?? 0
            );
            formik.setFieldValue('amount', totalAmount);
            formik.setFieldValue('gross', totalGross);
          }}
          error={formik.touched.gross && !!formik.errors.gross}
          helperText={(formik.touched.gross && formik.errors.gross) ?? ' '}
          placeholder="0.00"
          type="number"
          endAdornment="none"
        />

        <SelectComponent
          label={polyglot.t('NewInvoicePage.taxRate')}
          name="taxRate"
          options={TaxRateOptions}
          value={formik.values.taxRate}
          onChange={(e) => {
            formik.setFieldValue('taxRate', e.target.value);
            formik.setFieldValue('isTaxIncluded', isTaxIncluded(e.target.value));
            const taxRateForLineItem = e.target.value;
            const { totalGross, totalAmount } = getInvoiceTotalsForIndividualLineItem(
              formik.values.amount ?? 0,
              taxRateForLineItem
            );
            formik.setFieldValue('amount', totalAmount);
            formik.setFieldValue('gross', totalGross);
          }}
          error={formik.touched.taxRate && Boolean(formik.errors.taxRate)}
          helperText={(formik.touched.taxRate && formik.errors.taxRate) as string}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t(indexExists ? 'General.update' : 'General.add')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
