import React, { useCallback, useMemo } from 'react';

import { ColumnDef, Row } from '@tanstack/react-table';

import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { getReviewerTypesChips } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewCycle, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewResult } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { ReviewerScoreSummary } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface IResultOverviewReviewScoreTableProps {
  results: ReviewResult | null | undefined;
  reviewCycle: Pick<ReviewCycle, 'state' | 'internalName' | 'displayName'>;
  resultsLoading: boolean;
  reviewScore: ReviewerScoreSummary[];
  setIsSharableOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reachType: ReachType;
}

export const ResultsOverviewReviewScoreTable: React.FC<IResultOverviewReviewScoreTableProps> = ({
  results,
  reviewCycle,
  resultsLoading,
  reviewScore,
  setIsSharableOpen,
  reachType,
}) => {
  const rowData = useMemo(() => {
    const initialData = results
      ? [{ reviewerId: results.revieweeId, average: results.reviewAvgScore, type: null, entryState: null }]
      : [];
    return [...initialData, ...(reviewScore || [])];
  }, [results, reviewScore]);

  const shouldShowActionColumn = useMemo(
    () =>
      reviewCycle &&
      (reviewCycle.state === CycleState.Completed || reviewCycle.state === CycleState.Ongoing) &&
      reachType !== ReachType.Me,
    [reviewCycle, reachType]
  );

  const getActionCell = useCallback(
    (type: ReviewerTypes | null) => {
      if (
        !results ||
        type ||
        (results && results.questionResponseSummary.length === 0) ||
        (reviewCycle.state === CycleState.Completed && !results?.visibilitySettings.allowManagerToReleaseFeedback)
      )
        return <></>;

      if (!results?.isResultShared) {
        return (
          <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => setIsSharableOpen(true)}>
            Share results
          </ButtonComponent>
        );
      }

      return <ChipComponent name="Shared" border="background" />;
    },
    [setIsSharableOpen, reviewCycle.state, results]
  );

  const tableColumns = useMemo<ColumnDef<ReviewerScoreSummary, ReviewerScoreSummary>[]>(
    () => [
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'reviewerId',
        enableSorting: false,

        cell: ({
          row: {
            original: { reviewerId },
          },
        }) =>
          results?.visibilitySettings?.hidePeerAuthor && !reviewerId ? (
            <Typography variant="caption">Author hidden</Typography>
          ) : (
            <UserCell userId={reviewerId} />
          ),
        minSize: 120,
        maxSize: 180,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,

        cell: ({
          row: {
            original: { type },
          },
        }) => (type ? <div>{getReviewerTypesChips(false)[type]}</div> : <></>),
        minSize: 120,
        maxSize: 180,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'average',
        enableSorting: false,

        cell: ({
          row: {
            original: { average },
          },
        }) => (average ? <div>{average}%</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 180,
      },
      ...(shouldShowActionColumn
        ? [
            {
              header: () => '',
              accessorFn: (row: Row<ReviewerScoreSummary>) => row,
              id: 'action',
              enableSorting: false,

              cell: ({
                row: {
                  original: { type },
                },
              }: {
                row: Row<ReviewerScoreSummary>;
              }) => getActionCell(type),
              minSize: 120,
              maxSize: 180,
            },
          ]
        : []),
    ],
    [results, getActionCell, shouldShowActionColumn]
  );

  const customRowStyle = (row: Row<ReviewerScoreSummary>) => {
    if (!row.original.type) return { backgroundColor: themeColors.TableHover };
    return {};
  };

  return (
    <div className="hide-for-capture">
      <BasicTable
        rowData={rowData ?? []}
        columnData={tableColumns}
        loading={resultsLoading}
        paginationSx={{ px: spacing.px16, boxSizing: 'border-box' }}
        customRowStyle={customRowStyle}
      />
    </div>
  );
};
