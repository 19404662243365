import { OptionObject } from '@/v2/components/forms/select.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  AppIntegrationUserEmailDto,
  GroupMembership,
  InstalledAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import {
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION,
  AppIntegrationStub,
  ChartData,
  SeriesData,
} from '@/v2/feature/app-integration/app-integration.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';

export const getStatusColor = (status: string | null | undefined) => {
  switch (status) {
    case 'Active':
      return themeColors.green;
    case 'No access':
      return '#FFEB7B';
    case 'Suspended':
      return '#FF7373';
    case 'Invited':
      return 'blue';
  }
};

export const emailOptionsForUserCreation = (
  appStub: AppIntegrationStub,
  preselectedUser: AppIntegrationUserDto,
  personalEmailForUser: string,
  cachedUsers: readonly CachedUser[]
) => {
  if (APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub)) {
    const emailOptions: OptionObject[] = [];
    const matchingCachedUser = cachedUsers.find((cu) => cu.userId === preselectedUser.userId);
    preselectedUser?.emails
      ?.filter((emailRecord) => emailRecord.status === 'No access')
      .forEach((record) => {
        if (
          !emailOptions.some((option) => option.value === record.email) &&
          record?.email?.length > 0 &&
          record.email === matchingCachedUser?.emailAddress
        ) {
          emailOptions.push({
            label: record.email ? `Work email (${record.email})` : undefined,
            value: record.email ?? undefined,
          });
        }
        if (
          !emailOptions.some((option) => option.value === record.email) &&
          record?.email?.length > 0 &&
          record.email === personalEmailForUser
        ) {
          emailOptions.push({
            label: record.email ? `Personal email (${record.email})` : undefined,
            value: record.email ?? undefined,
          });
        }
        if (
          !emailOptions.some((option) => option.value === record.personalEmail) &&
          record?.personalEmail?.length > 0
        ) {
          emailOptions.push({
            label: record.personalEmail ? `Personal email (${record.personalEmail})` : undefined,
            value: record.personalEmail ?? undefined,
          });
        }
      });
    return emailOptions.filter((option) => option.value);
  }
};

export const canRequestAccess = (stub: string) => {
  const APPS_WITH_REQUEST_ACCESS = ['slack', 'pipedrive', 'zoom', 'google-workspace', 'notion', 'intercom', 'github'];
  return APPS_WITH_REQUEST_ACCESS.includes(stub);
};

export const userHasAppAccess = (userList: readonly AppIntegrationUserDto[], userEmail: string): boolean => {
  return userList.some(
    (eachUser) =>
      eachUser.emails &&
      eachUser.emails.length > 0 &&
      eachUser?.emails[0].email === userEmail &&
      (eachUser.userStatus === 'Active' || eachUser.userStatus === 'Invited' || eachUser.userStatus === 'Suspended')
  );
};

export const getUsersWithoutAccess = (teamList: readonly AppIntegrationUserDto[]) => {
  return teamList
    ? teamList.filter((u) => u.userStatus && !['Active', 'Invited', 'Suspended'].includes(u.userStatus))
    : [];
};

export const isAppOwner = (userId: number, app: InstalledAppDto | undefined): boolean => {
  return app ? userId === app.appOwnerId : false;
};

export const getAppOwnerId = async (appStub: AppIntegrationStub): Promise<number> => {
  const appInstance = await AppIntegrationAPI.getAppIntegrationDetails(appStub);
  return appInstance.appOwnerId;
};

export const hasActiveEmailForAccount = (user: AppIntegrationUserDto): AppIntegrationUserEmailDto | undefined => {
  if (user && user.emails && user?.emails?.length > 0)
    return user.emails.find((eachEmail) => eachEmail.status === 'Active');
  return undefined;
};

export const getEmailAddressForMissingId = (user: AppIntegrationUserDto) => {
  if (user.emails && user.emails.length > 0) return user.emails[0].email;
};

export const APPS_WITHOUT_DELETE_OPTION = ['notion', 'github', 'hubspot'];
export const APPS_NEEDING_EMAIL_FOR_DELETION = ['lastpass'];
export const APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION = ['jira'];

export const getActionNameForApp = (actionName: string, appStub: string, fallback?: string): string => {
  switch (actionName) {
    case 'Delete':
      if (APPS_WITHOUT_DELETE_OPTION.includes(appStub)) return 'Remove from Organisation';
      if (!APPS_WITHOUT_DELETE_OPTION.includes(appStub)) return 'Delete';
      break;
    case 'Suspend':
      if (appStub === 'pipedrive') return 'Deactivate';
      break;
    default:
      return fallback ?? actionName;
  }
  return fallback ?? actionName;
};

export const getGroupListForUser = (userIdentifier: string, groupMemberships: readonly GroupMembership[]): string => {
  return groupMemberships
    .filter((eachGroup) => eachGroup.members.hasOwnProperty(userIdentifier))
    .map((eachGroup) => eachGroup.name)
    .join(', ');
};

export const seriesDataExists = (kpi: ChartData): boolean => {
  return kpi && kpi.series && (kpi.series as SeriesData[]).some((d: SeriesData) => d.data && d.data.length > 0);
};
