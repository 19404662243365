import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { EditCustomBenefitMembersDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-members-drawer.component';
import { BenefitsSettingsPageSection } from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface PageProps {
  readonly customBenefit: CustomBenefitDto;
  readonly refreshBenefit: () => Promise<void>;
}

export const CustomBenefitSettingsMembersPage = ({ customBenefit, refreshBenefit }: PageProps) => {
  const { polyglot } = usePolyglot();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState<string>('');
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const listOfUsers = useMemo(() => {
    const userIdsSet = new Set(customBenefit.includedUserIds ?? []);

    return companyUsers.filter((u) => userIdsSet.has(u.userId));
  }, [customBenefit, companyUsers]);

  const filteredUsers = useMemo(() => {
    if (!searchInput) return [...listOfUsers];

    const search = searchInput.toLowerCase();
    return [...listOfUsers.filter((u) => u.displayName.toLowerCase().includes(search))];
  }, [listOfUsers, searchInput]);

  const tableColumns = useMemo<ColumnDef<CachedUser, CachedUser>[]>(
    () => [
      {
        header: () => (
          <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
            {polyglot.t('General.name')}
          </Typography>
        ),
        accessorFn: (row) => row,
        id: 'displayName',
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, displayName },
          },
        }) => (
          <Box key={userId} sx={{ display: 'flex', gap: spacing.g10 }}>
            <UserAvatar userId={userId} size="xsmall" />
            <Typography variant="caption">{polyglot.t(displayName)}</Typography>
          </Box>
        ),
      },
    ],
    [polyglot]
  );

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('General.members')}
      onEdit={() => {
        setIsEditOpen(true);
      }}
    >
      <Typography variant="title3">{polyglot.t('AbsencePolicyMembersSection.list')}</Typography>
      <TableSearch
        query={searchInput}
        handleChange={(e) => setSearchInput(e.target.value)}
        style={{ width: '572px' }}
      />
      {filteredUsers.length > 0 && (
        <Box sx={{ width: '600px' }}>
          <BasicTable<CachedUser>
            rowData={filteredUsers}
            columnData={tableColumns}
            initialSort={[{ id: 'displayName', desc: true }]}
            fixedLastColumn={false}
          />
        </Box>
      )}

      {isEditOpen && (
        <EditCustomBenefitMembersDrawer
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          customBenefit={customBenefit}
          refresh={refreshBenefit}
        />
      )}
    </BenefitsSettingsPageSection>
  );
};
