import React, { useCallback, useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { DOCUMENTS_ME_ROUTE } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { themeFonts } from '@/v2/styles/fonts.styles';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly companySignatoryUserId: number;
  readonly signAndSendContract: (companySignatoryUserId: number, companySignature: string) => Promise<void>;
  readonly errorMessage: string | undefined;
}

export const CompanySignatorySignSignContractModal = ({
  setOpen,
  open,
  companySignatoryUserId,
  signAndSendContract,
  errorMessage,
}: ModalProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [isSigningContract, setIsSigningContract] = useState<boolean>(false);
  const [companySignature, setCompanySignature] = useState<string>('');
  const [contractSigned, setContractSigned] = useState<boolean>(false);
  const routerLocation = useLocation();
  const inCompanySignMode = routerLocation?.pathname.includes('company-sign');

  const routerHistory = useHistory();

  const signContract = useCallback(async () => {
    setIsSigningContract(true);

    if (!companySignature) {
      showMessage(polyglot.t('CompanySignatorySignSignContractModal.errorMessages.signature'), 'error');
    }

    try {
      await signAndSendContract(companySignatoryUserId, companySignature);
      setContractSigned(true);
    } catch (error) {
      setIsSigningContract(false);
    }
  }, [companySignature, signAndSendContract, companySignatoryUserId, showMessage, polyglot]);

  return (
    <DrawerModal isOpen={open} setIsOpen={setOpen}>
      <Box sx={drawerContentSx}>
        {!contractSigned && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">
              {polyglot.t('CompanySignatorySignSignContractModal.signTheContract')}
            </Typography>

            <Typography variant="caption">
              {polyglot.t('CompanySignatorySignSignContractModal.agreementMessage')}
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <TextfieldComponent
                name="companySignature"
                label={polyglot.t('CompanySignatorySignSignContractModal.companySignature')}
                value={companySignature}
                type="text"
                onChange={(e) => setCompanySignature(e.target.value)}
                error={!companySignature}
                helperText={polyglot.t('CompanySignatorySignSignContractModal.helperSignature')}
                clearText={() => setCompanySignature('')}
              />
            </FormControl>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mt: '2em',
              }}
            >
              <Typography variant="caption" sx={{ ...themeFonts.signature }}>
                {companySignature}
              </Typography>
            </Box>

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                fullWidth
                sizeVariant="medium"
                colorVariant="secondary"
                onClick={() => {
                  setOpen(false);
                  setCompanySignature('');
                }}
              >
                {polyglot.t('General.cancel')}
              </ButtonComponent>

              <LoaderButton
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
                name={
                  inCompanySignMode
                    ? polyglot.t('CompanySignatorySignSignContractModal.sign')
                    : polyglot.t('CompanySignatorySignSignContractModal.signAndSend')
                }
                loading={isSigningContract}
                disabled={isSigningContract || !companySignature}
                onClick={signContract}
              />
            </Box>
          </Box>
        )}

        {contractSigned && !Boolean(errorMessage) && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">Thank you!</Typography>

            <Typography variant="caption">
              {polyglot.t('CompanySignatorySignSignContractModal.youWillReceiveEmail')}
            </Typography>

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="medium"
                fullWidth
                onClick={() => routerHistory.push(generatePath(DOCUMENTS_ME_ROUTE))}
              >
                {polyglot.t('CompanySignatorySignSignContractModal.close')}
              </ButtonComponent>
            </Box>
          </Box>
        )}

        {Boolean(errorMessage) && (
          <Box sx={drawerContentSx}>
            <Typography variant="title2">{polyglot.t('UserSignContractModal.sorry')}</Typography>
            <Typography variant="caption">{errorMessage}</Typography>

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="medium"
                fullWidth
                onClick={() => routerHistory.push(generatePath(DOCUMENTS_ME_ROUTE))}
              >
                {polyglot.t('UserSignContractModal.close')}
              </ButtonComponent>
            </Box>
          </Box>
        )}
      </Box>
    </DrawerModal>
  );
};
