import { format, isMatch, isValid } from 'date-fns';
import enGb from 'date-fns/locale/en-GB';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import type { AnyObject } from 'yup/lib/types';

import { LocalDate } from '@/v2/util/local-date';

function formatDate(date: Date, dateFormat: string): string {
  return format(date, dateFormat, { locale: enGb });
}

export function formatFullLongDate(date?: Date): string {
  if (!date) return '';
  return formatDate(date, 'PPP, p');
}

export function formatLongDate(date?: Date): string {
  if (!date) return '';
  return formatDate(date, 'PPP');
}

export function formatShortDate(date?: Date | null): string {
  if (!date) return '';
  return formatDate(date, 'P');
}

export const convertUTCDateToLocalDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

export const isISODateSameCalendarDateAsDate2 = (isoDate: string, date2: Date): boolean => {
  const month = String(date2.getMonth() + 1).padStart(2, '0');
  const day = String(date2.getDate()).padStart(2, '0');
  return isoDate.endsWith(`${month}-${day}`);
};

export const isISODateSameAsLocaleDate = (date1: string, date2: Date): boolean => {
  const year = date2.getFullYear();
  const month = String(date2.getMonth() + 1).padStart(2, '0');
  const day = String(date2.getDate()).padStart(2, '0');

  return date1 === `${year}-${month}-${day}`;
};

export const isISODateBeforeDate2 = (isoDate: string, date2: Date): boolean => {
  const dateYear = date2.getFullYear();
  const dateMonth = date2.getMonth() + 1;
  const dateDay = date2.getDate();

  const isoYear = Number(isoDate.slice(0, 4));
  const isoMonth = Number(isoDate.slice(5, 7));
  const isoDay = Number(isoDate.slice(8, 10));

  if (isoYear < dateYear) return true;
  if (isoYear > dateYear) return false;

  if (isoMonth < dateMonth) return true;
  if (isoMonth > dateMonth) return false;

  return isoDay < dateDay;
};

export const isISODateAfterDate2 = (isoDate: string, date2: Date): boolean => {
  const dateYear = date2.getFullYear();
  const dateMonth = date2.getMonth() + 1;
  const dateDay = date2.getDate();

  const isoYear = Number(isoDate.slice(0, 4));
  const isoMonth = Number(isoDate.slice(5, 7));
  const isoDay = Number(isoDate.slice(8, 10));

  if (isoYear > dateYear) return true;
  if (isoYear < dateYear) return false;

  if (isoMonth > dateMonth) return true;
  if (isoMonth < dateMonth) return false;

  return isoDay > dateDay;
};

export const normalizeDate = (date: string): string => {
  if (!date) return '';
  return new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString();
};

export function datesComparator(date1?: Date | null, date2?: Date | null): number {
  if (date1 && date2 && new Date(date1) > new Date(date2)) {
    return 1;
  }
  if (date1 && date2 && new Date(date2) > new Date(date1)) {
    return -1;
  }
  if (date1 && !date2) return 1;
  if (!date1 && date2) return -1;
  return 0;
}

export const SHORT_DATE_FORMAT = 'yyyy-MM-dd';

export function formatAsShortDate(value: Date | string): string {
  if (typeof value === 'string' && isMatch(value as string, SHORT_DATE_FORMAT)) return value;
  return format(value as Date, SHORT_DATE_FORMAT);
}

export const isoDateFormat = /(\d\d\d\d)-(\d\d)-(\d\d)/;
export const isoDateAndTimeFormat = /^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d)(:(\d\d))?$/;
export const ddmmyyyyDateFormat = /(\d\d)\/(\d\d)\/(\d\d\d\d)/;
export const hhmmTimeFormat = /(\d\d):(\d\d)/;

export function buildIsoDateString(day1To31: number, month1to12: number, year: number): string {
  const padNumber = (n: number, digits: number) => n.toString().padStart(digits, '0');
  return `${padNumber(year, 4)}-${padNumber(month1to12, 2)}-${padNumber(day1To31, 2)}`;
}

export const parseIsoDateString = (date: string) => {
  const match = date.match(/^(\d\d\d\d)-(\d\d)-(\d\d)$/);
  if (!match) return null;
  const [year, month, day] = [Number(match[1]), Number(match[2]), Number(match[3])];
  return { year, month, day };
};

export function ddMMYYYYToIsoDateString(date: string): string | null {
  if (!date || date.length === 0) return null;
  const match = date.match(ddmmyyyyDateFormat);
  return match ? `${match[3]}-${match[2]}-${match[1]}` : date;
}

export function isoDateStringToDDMMYYY(date: string | null): string | null {
  if (!date) return null;
  const match = date.match(isoDateFormat);
  return match ? `${match[3]}/${match[2]}/${match[1]}` : null;
}

export const isLeapYear = (year: number): boolean => {
  // leap years occur in each year that is an integer multiple of 4 (except for years evenly divisible by 100, but not by 400).
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
};

/**
 * Returns the maximum day value (28, 29, 30 or 31) in a specified month
 */
export const getMaxDayInMonth = (month1to12: number, year: number): number => {
  if (month1to12 < 1 || month1to12 > 12) throw new Error(`Invalid month number: ${month1to12}`);
  if (year < 1 || year > 9999) throw new Error(`Invalid year: ${year}`);
  const maxDateInMonth1to12 = [0, 31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return maxDateInMonth1to12[month1to12];
};

const isValidDate = (day: number, month: number, year: number, yearRange = { minYear: 1900, maxYear: 2100 }) => {
  if (year < yearRange.minYear || year > yearRange.maxYear) return false;
  if (month < 1 || month > 12) return false;
  if (day < 1 || day > 31) return false;
  return day <= getMaxDayInMonth(month, year);
};

export const isValidIsoDateString = (date: string) => {
  const dmy = parseIsoDateString(date);
  if (!dmy) return false;
  const { year, month, day } = dmy;
  return isValidDate(day, month, year);
};

export const parseDDMMYYYYDateAsLocalDateString = (dateStr: string): string | null => {
  const dateParts = dateStr.split('/');
  if (dateParts.length !== 3) return null;
  const [day, month, year] = dateParts;
  if (!isValid(new Date(+year, +month - 1, +day))) return null;
  return new LocalDate(`${year}-${month}-${day}`).toDateString();
};

export const dateFieldTest: Yup.TestConfig<string | undefined, AnyObject> = {
  test(value: unknown) {
    // if no value is passed, return true to allow other validations to run
    if (value === null || value === undefined || value === '') return true;
    return typeof value === 'string' && isValidIsoDateString(value);
  },
  // the date is returned in yyyy-mm-dd, but the user must enter it in dd/mm/yyyy format
  message: 'Enter a valid date in dd/mm/yyyy format',
};

export const isMonday: Yup.TestConfig<string | undefined, AnyObject> = {
  test(value: unknown) {
    // if no value is passed, return true to allow other validations to run
    if (value === null || value === undefined || value === '') return true;
    return typeof value === 'string' && new LocalDate(value).getDate().getDay() === 1;
  },
  message: 'The date selected should be a Monday',
};

export const shouldBeNullTest: Yup.TestConfig<string | undefined, AnyObject> = {
  test(value: unknown) {
    return value === null;
  },
  message: 'Value must be null',
};

export const skipTest: Yup.TestConfig<string | undefined, AnyObject> = {
  test() {
    return true;
  },
};
export const dateGreaterThanTest = (
  targetDate: string,
  polyglot: Polyglot
): Yup.TestConfig<string | undefined, AnyObject> => ({
  test(value: unknown) {
    // if no value is passed, return true to allow other validations to run
    if (value === null || value === undefined || value === '') return true;
    return typeof value === 'string' && isValidIsoDateString(value) && value > targetDate;
  },
  message: polyglot.t('ValidationMessages.dateAfterThan', { targetDate }),
});

export const isValidTimeString = (time: string | null | undefined): boolean => {
  if (typeof time !== 'string') return false;

  return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])$/.test(time);
};

export const isValidISODateTimeString = (date: string | null | undefined): boolean => {
  if (!date || typeof date !== 'string') return false;

  return isoDateAndTimeFormat.test(date);
};

export const dmyToIsoDateString = (value: string): string | null => {
  const ddmmyyyy = value.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})/);
  if (!ddmmyyyy) {
    return null;
  }
  const dd = ddmmyyyy[1].padStart(2, '0');
  const mm = ddmmyyyy[2].padStart(2, '0');
  const yyyy = ddmmyyyy[3];
  return `${yyyy}-${mm}-${dd}`;
};

export function addDate(
  date: string,
  increment: { days?: number; weeks?: number; months?: number; years?: number }
): string {
  const dmy = parseIsoDateString(date);
  if (!dmy) throw new Error('Invalid date');
  let { year, month, day } = dmy;
  const _isLastDayInMonth = day === getMaxDayInMonth(month, year);
  const checkMonthRollover = () => {
    if (month < 1) {
      month += 12;
      year -= 1;
    } else if (month > 12) {
      month -= 12;
      year += 1;
    }
  };

  if (typeof increment.years === 'number') {
    year += increment.years;
  }

  if (typeof increment.months === 'number') {
    const yearsFromMonths = Math.trunc(increment.months / 12);
    year += yearsFromMonths;
    month += increment.months - yearsFromMonths * 12;
    checkMonthRollover();
    day = _isLastDayInMonth
      ? // if the initial date was the last day of the month, also set the result
        // to the last day of the incremented month
        getMaxDayInMonth(month, year)
      : // otherwise, make sure the day is within the month
        Math.min(day, getMaxDayInMonth(month, year));
  }

  let totalDaysIncrement = 0;
  if (typeof increment.weeks === 'number') {
    totalDaysIncrement += increment.weeks * 7;
  }
  if (typeof increment.days === 'number') {
    totalDaysIncrement += increment.days;
  }

  if (totalDaysIncrement !== 0) {
    day += totalDaysIncrement;
    while (day < 1) {
      month -= 1;
      checkMonthRollover();
      day += getMaxDayInMonth(month, year);
    }
    for (;;) {
      const maxDay = getMaxDayInMonth(month, year);
      if (day <= maxDay) break;
      day -= maxDay;
      month += 1;
      checkMonthRollover();
    }
  }

  return buildIsoDateString(day, month, year);
}

export function getDayOfWeekName(date: string, polyglot?: Polyglot): string {
  const dayIndex = new Date(date).getUTCDay();
  switch (dayIndex) {
    case 0:
      return polyglot?.t('Days.sunday') ?? 'Sunday';
    case 1:
      return polyglot?.t('Days.monday') ?? 'Monday';
    case 2:
      return polyglot?.t('Days.tuesday') ?? 'Tuesday';
    case 3:
      return polyglot?.t('Days.wednesday') ?? 'Wednesday';
    case 4:
      return polyglot?.t('Days.thursday') ?? 'Thursday';
    case 5:
      return polyglot?.t('Days.friday') ?? 'Friday';
    default:
      return polyglot?.t('Days.saturday') ?? 'Saturday';
  }
}
