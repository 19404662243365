import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';

import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';

export type RequestFormSelectComponent = {
  type: 'select';
  id: string;
  label: string;
  required: boolean;
  options: string[];
};

export type RequestFormDateComponent = {
  type: 'date';
  label: string;
  id: string;
  required: boolean;
};

export type RequestFormTextComponent = {
  type: 'text';
  label: string;
  id: string;
  required: boolean;
};

export type RequestFormAttachmentComponent = {
  type: 'attachment';
  label: string;
  id: string;
  required: boolean;
};

export type RequestFormComponentItem =
  | RequestFormTextComponent
  | RequestFormSelectComponent
  | RequestFormDateComponent
  | RequestFormAttachmentComponent;

export type RequestFormLayout = {
  items: RequestFormComponentItem[];
};

export type RequestFormTemplate = {
  id: number;
  name: string;
  description: string;
  layout: RequestFormLayout;
  approvalRuleId: number;
  members: MemberGroup;
};

export type RequestFormTemplateSummary = Pick<RequestFormTemplate, 'id' | 'name' | 'description'>;

export type CreateRequestFormTemplate = Omit<RequestFormTemplate, 'id'>;

export type MemberGroup = {
  userIds: number[];
  filter: UserSelectFiltersOptions;
  customRule?: string;
};

export enum RequestFormStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export type RequestForm = {
  id: number;
  userId: number;
  formTemplateId: number;
  name: string;
  description: string;
  layout: RequestFormLayout;
  values: { [componentId: string]: unknown };
  status: RequestFormStatus;
  approverSteps: ApproverStep[];
  approvedByIds: number[];
  rejectedByIds: number[];
  approvedOnTimestamp: Date | null;
  autoApproved: boolean;
  notes: string | null;
  updatedAt: Date;
  canEdit?: boolean;
  canDelete?: boolean;
  canApprove?: boolean;
  canReject?: boolean;
  canForceApprove?: boolean;
  canForceReject?: boolean;
  canRequestCancellation?: boolean;
  canApproveCancellation?: boolean;
  canRejectCancellation?: boolean;
};

export type CreateRequestForm = {
  formTemplateId: number;
  values: { [key: string]: unknown };
};
