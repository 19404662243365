import { useContext, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { InsuranceUpdateUserPolicyDrawer } from '@/v2/feature/benefits/subfeature/insurance/components/insurance-update-user-policy-drawer.component';
import { InsuranceQuoteDto } from '@/v2/feature/benefits/subfeature/insurance/insurance.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const InsurancePendingDetails = ({
  insuranceQuote,
  refresh,
}: {
  insuranceQuote: InsuranceQuoteDto;
  readonly refresh: () => Promise<void>;
}) => {
  const [state] = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);

  const { getScopesContext } = useScopes();
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: spacing.g40, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap10 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Status</Typography>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.Grey }}>Pending...</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.gap10 }}>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Employees</Typography>
          <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
            <MultiUserAvatar userIds={insuranceQuote.includedUserIds} avatarSize="small" showLimit={3} />
            <ScopesControl scopes={['insurance:all']} context={getScopesContext({ userId: state.user.userId })}>
              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </ScopesControl>
          </Box>
        </Box>
      </Box>

      <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => setIsOpen(false)}>
        <InsuranceUpdateUserPolicyDrawer insuranceQuote={insuranceQuote} refresh={refresh} setIsOpen={setIsOpen} />
      </DrawerModal>
    </Box>
  );
};
