import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { FilterTypesProps } from '@v2/components/table/category-filters.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { StyledTabsComponent } from '@v2/components/theme-components/styled-tabs.component';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import {
  ErrorComponent,
  LoadingTableComponent,
} from '@v2/feature/reports/features/create-report/sections/util-sections.component';
import {
  ReportDates,
  UpdateReportDatesDrawer,
} from '@v2/feature/reports/features/update-report/people/update-report-dates-drawer.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import {
  CreateReport,
  DateType,
  FormDataInterface,
  ReportCustomFields,
  ReportEntity,
} from '@v2/feature/reports/reports.interface';
import {
  getAllSelectedColumnsFromUserReportColumnsOptions,
  getFilterColumnsPeopleChangeReport,
  getPeopleReportFilters,
} from '@v2/feature/reports/util/people-report.util';
import { exportReportCSVOldReports } from '@v2/feature/reports/util/report.util';
import { UserAPI } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableWhiteIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export const PeopleChangeCreateReportResults = ({
  formData,
  setFormData,
  customFields,
  additionalColumns,
}: {
  readonly formData: FormDataInterface;
  readonly setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  readonly customFields: ReportCustomFields;
  readonly additionalColumns: {
    label: string;
    value: string;
    entity: ReportEntity;
  }[];
}) => {
  const { polyglot } = usePolyglot();

  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false,
  });
  const { hasScopes } = useScopes();

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>(polyglot.t('PeopleChangeCreateReportResults.new'));
  const [filterTypes, setFilterTypes] = useState<FilterTypesProps>({});
  const [filterString, setFilterString] = useState<string>('');
  const [filteredData, setFilteredData] = useState<
    | {
        [key: string]: Record<string, string | number>[];
      }
    | undefined
  >(undefined);

  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );

  const [currentTab, setCurrentTab] = useState<string>(
    formData.entities && formData.entities[0] ? formData.entities[0] : ''
  );

  const tabs = useMemo(
    () =>
      formData.entities?.map((entity) => ({
        label: entity as string,
        value: entity as string,
      })) ?? [],
    [formData.entities]
  );

  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState<string[]>(
    formData.entities && formData.entities?.length > 0 ? selectedColumns[formData.entities[0]] ?? [] : []
  );

  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    // Set the first tab for the first time, then each time the tabs change,
    // if the currentTab is defined and in the list of tabs keep it, otherwise pick the first tab from list
    // This is done because the data is loaded on every filter change => tabs are updated on each filter change based on the data from the response
    // And each time the tabs are updated, a currentTab change is attempted, but we don't want a tab reset at each data read
    // Had to do that because of the way we update the selected columns for each subdomain. At each tab change, the settings for the selected columns get changed,
    // resulting in the load data request to be called, resulting in tabs reset
    setCurrentTab((prev) => (prev && tabs.some((t) => t.value === prev) ? prev : tabs[0]?.value ?? ''));
  }, [tabs]);

  useEffect(() => {
    const selectedCols = getAllSelectedColumnsFromUserReportColumnsOptions(
      formData.entities,
      customFields,
      hasScopes,
      additionalColumns,
      polyglot
    );

    setSelectedColumns(selectedCols);
    setSelectedColumnsStrings(currentTab ? selectedCols[currentTab] ?? [] : []);
    setAllDataLoaded(true);
  }, [polyglot, currentTab, hasScopes, formData.entities, customFields, additionalColumns]);

  useEffect(() => {
    if (!allDataLoaded) return;
    setSelectedColumns((prev) => ({ ...prev, [currentTab]: selectedColumnsStrings }));
  }, [allDataLoaded, currentTab, selectedColumnsStrings]);

  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [reportError, setReportError] = useState<boolean>(false);
  const [editDates, setEditDates] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);

  const getFilteredData = useCallback(
    async (
      selectedColumns: {
        [key: string]: string[];
      },
      filterString: string
    ) => {
      setReportLoading(true);
      try {
        const paramObj = {
          domain: formData.domain,
          type: formData.type,
          entities: formData.entities,
          start: formData.start,
          end: formData.end,
          dateType: formData.dateType,
          filters: filterString,
          columns: selectedColumns,
        };

        let filteredUsers = await ReportsAPI.generatePeopleCreatedChangeReport(paramObj);
        setReportError(false);
        setFilteredData(filteredUsers);
      } catch (error) {
        setReportError(true);
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setReportLoading(false);
      }
    },
    [formData, showMessage]
  );

  useEffect(() => {
    if (allDataLoaded) getFilteredData(selectedColumns, filterString);
  }, [allDataLoaded, getFilteredData, selectedColumns, filterString]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData && currentTab && filteredData[currentTab]
        ? filteredData[currentTab]?.filter(
            (data) =>
              !searchInput ||
              (data?.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
          )
        : undefined
    );
  }, [currentTab, searchInput, filteredData]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const createReport: CreateReport = {
        domain: formData.domain,
        type: formData.type,
        entities: formData.entities,
        start: formData.start,
        end: formData.end,
        dateType: formData.dateType,
        filters: filterString,
        columns: selectedColumns,
        fileName: reportName,
      };
      await ReportsAPI.saveReport(createReport);
      setFormData({ ...formData, filters: filterString });
      showMessage(polyglot.t('PeopleChangeCreateReportResults.successMessages.save'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [polyglot, filterString, formData, reportName, routerHistory, selectedColumns, setFormData, showMessage]);

  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);

  const exportCsv = useCallback(() => {
    try {
      exportReportCSVOldReports(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t('PeopleChangeCreateReportResults.errorMessages.download'), 'error');
    }
  }, [polyglot, showMessage, reportName, filteredData]);

  const reportDates = useMemo(() => {
    if (formData.dateType === DateType.Last30days) return polyglot.t('PeopleChangeCreateReportResults.tDays');
    if (formData.dateType === DateType.Last7days) return polyglot.t('PeopleChangeCreateReportResults.sDays');

    if (formData.start && formData.end)
      return `${new LocalDate(formData.start).toLocaleDateString()} - ${new LocalDate(
        formData.end
      ).toLocaleDateString()}`;
  }, [polyglot, formData]);

  const updateReportDates = useCallback(
    async (datesUpdate: ReportDates) => {
      if (datesUpdate.dateType && datesUpdate.start && datesUpdate.end) {
        setFormData((prev) => ({ ...prev, ...datesUpdate }));
      }
    },
    [setFormData]
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      {reportLoading && <LoadingTableComponent />}
      {!reportLoading && reportError && <ErrorComponent />}
      {!reportLoading && filteredData && (
        <Box sx={{ width: '80%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10, minHeight: '60px' }}>
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={
                state.user.features?.report?.tooltip?.saveReportName
                  ? undefined
                  : {
                      open: true,
                      title: (
                        <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                            {polyglot.t('PeopleChangeCreateReportResults.type')}
                          </Typography>
                          <Button
                            onClick={async () => {
                              const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                                'report',
                                'tooltip',
                                'saveReportName',
                                true
                              );
                              dispatch({
                                type: GlobalStateActions.UPDATE_USER,
                                payload: updatedGlobalUser,
                              });
                            }}
                            sx={{ ...themeFonts.caption, color: themeColors.Grey, textTransform: 'none' }}
                          >
                            {polyglot.t('PeopleChangeCreateReportResults.okay')}
                          </Button>
                        </Stack>
                      ),
                    }
              }
            />
          </Box>

          <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
            <Typography sx={themeFonts.caption}>{reportDates}</Typography>
            <IconButton sx={{ ...tableWhiteIconButtonSx }} onClick={() => setEditDates(true)}>
              <Edit {...iconSize} />
            </IconButton>

            <UpdateReportDatesDrawer
              isOpen={editDates}
              setIsOpen={setEditDates}
              reportDates={{ dateType: formData.dateType, start: formData.start, end: formData.end }}
              action={updateReportDates}
            />
          </Box>

          <StyledTooltip
            open={isTooltipOpen}
            title={
              <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                  {polyglot.t('PeopleChangeCreateReportResults.filter')}
                </Typography>
                <ButtonComponent
                  colorVariant="tooltip"
                  sizeVariant="small"
                  onClick={() => {
                    setIsTooltipOpen(false);
                  }}
                >
                  {polyglot.t('PeopleChangeCreateReportResults.okay')}
                </ButtonComponent>
              </Stack>
            }
          >
            <Box
              sx={{ ...spacing.mt20, display: 'flex', alignItems: 'center', gap: spacing.g10, width: 'fit-content' }}
            >
              <ColumnsDrawer
                columnsOptions={getFilterColumnsPeopleChangeReport(
                  currentTab,
                  formData.entities,
                  customFields,
                  hasScopes,
                  additionalColumns,
                  polyglot
                )}
                selectedColumns={selectedColumnsStrings}
                setSelectedColumns={setSelectedColumnsStrings}
              />
              <FiltersDrawer
                filtersOptions={[{ filters: filterTypes }]}
                selectedFilters={filterString}
                setSelectedFilters={setFilterString}
                encodedFilterNames
              />
              <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
            </Box>
          </StyledTooltip>

          <Box sx={{ mt: spacing.m30 }}>
            {tabs && <StyledTabsComponent tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />}
          </Box>

          <Box sx={{ ...spacing.mt40 }}>
            {filteredAndSearchedData && currentTab && (
              <ResultTableOldReports filteredData={filteredAndSearchedData} loading={loading} />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: spacing.g10,
              ...spacing.mt40,
              width: '50%',
            }}
          >
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name="Save"
              loading={loading}
              fullWidth
              onClick={handleSave}
            />
            <ButtonComponent
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={exportCsv}
              startIcon={<Export {...iconSize} />}
            >
              {polyglot.t('PeopleChangeCreateReportResults.downloadCsv')}
            </ButtonComponent>
          </Box>
        </Box>
      )}

      {/*{!reportLoading && filteredData && Object.keys(filteredData)?.length < 1 && !reportError && !filterString && (*/}
      {/*  <NoDataComponent />*/}
      {/*)}*/}
    </Box>
  );
};
