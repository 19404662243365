import { useCallback, useMemo, useState } from 'react';

import { Box, FormControlLabel, RadioGroup, Stack } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PaymentEntitySelect } from '@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/payment-entity-select.component';
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints,
} from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { cleanPayCodeValue } from '@/v2/feature/payroll/features/payroll-global/global-payroll.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  payrollId: number;
  paycodes: GlobalPaycode[];
  onPaycodeSaved?: () => void;
};

export const GlobalPayrollPayCodeNew = ({ payrollId, paycodes, onPaycodeSaved }: Props) => {
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const otherPaycodes = useMemo(() => [...paycodes], [paycodes]);
  const currentCreditors = useMemo(() => paycodes.map(({ credit }) => credit), [paycodes]);
  const currentDebitors = useMemo(() => paycodes.map(({ debit }) => debit), [paycodes]);

  const createPaycode = useCallback(
    async (newPaycode: GlobalPaycodeCreate) => {
      setSaving(true);
      try {
        await GlobalPayrollAPI.createPaycode(payrollId, newPaycode);
      } catch (e) {
        showMessage(`Failed to create paycode. ${nestErrorMessage(e)}`, 'error');
        setSaving(false);
        return;
      }
      showMessage(`Paycode created.`, 'success');
      onPaycodeSaved?.();
    },
    [onPaycodeSaved, payrollId, showMessage]
  );

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      credit: '',
      debit: '',
      formula: '',
      order: paycodes.length,
      required: false,
      paycodeKind: 'paycode-payment' as 'paycode-payment' | 'paycode-value',
    },
    validationSchema: yup.object({
      code: yup.string().required('Code is required'),
      name: yup.string().required('Name is required'),
      credit: yup.string().when('paycodeKind', {
        is: (val: string) => val === 'paycode-payment',
        then: yup.string().required('Credit party is required'),
        otherwise: yup.string().optional(),
      }),
      debit: yup.string().when('paycodeKind', {
        is: (val: string) => val === 'paycode-payment',
        then: yup.string().required('Debit party is required'),
        otherwise: yup.string().optional(),
      }),
    }),
    onSubmit: async (values) => {
      const newPaycode = {
        code: values.code,
        name: values.name,
        domain: 'earnings' as const,
        credit: values.paycodeKind === 'paycode-payment' ? values.credit : '',
        debit: values.paycodeKind === 'paycode-payment' ? values.debit : '',
        formula: values.formula.trim(),
        order: values.order,
        required: values.required,
      };
      await createPaycode(newPaycode);
    },
  });

  const orders = useMemo(() => {
    const orderedCodes = otherPaycodes.map(({ name, order }) => ({ name, order })).sort((a, b) => a.order - b.order);
    orderedCodes.splice(formik.values.order, 0, { name: formik.values.name, order: formik.values.order });
    return orderedCodes.map<OptionObject>(({ name }, n) => ({
      label: `${n}`,
      value: n,
      description: name,
    }));
  }, [otherPaycodes, formik.values.order, formik.values.name]);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">New pay code</Typography>
        <TextfieldComponent
          name="code"
          label="Code"
          value={formik.values.code}
          onChange={(e) => formik.setFieldValue('code', cleanPayCodeValue(e.target.value))}
          autoFocus
          disabled={saving}
          error={formik.submitCount > 0 && !!formik.errors.code}
          helperText={formik.submitCount > 0 && formik.errors.code}
        />
        <TextfieldComponent
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          disabled={saving}
          error={formik.submitCount > 0 && !!formik.errors.name}
          helperText={formik.submitCount > 0 && formik.errors.name}
        />
        <RadioGroup
          name="paycodeKind"
          value={formik.values.paycodeKind}
          onChange={formik.handleChange}
          sx={{ flexFlow: 'row' }}
        >
          <FormControlLabel
            labelPlacement="end"
            value={'paycode-payment'}
            control={<StyledRadio disableRipple />}
            label={<Typography variant="caption">Payment</Typography>}
            disabled={saving}
          />
          <FormControlLabel
            labelPlacement="end"
            value={'paycode-value'}
            control={<StyledRadio disableRipple />}
            label={<Typography variant="caption">Value</Typography>}
            disabled={saving}
          />
        </RadioGroup>
        {formik.values.paycodeKind === 'paycode-payment' && (
          <>
            <Stack>
              <PaymentEntitySelect
                name="credit"
                label="Credit"
                values={currentCreditors}
                value={formik.values.credit}
                onChange={(value) => {
                  formik.setFieldValue('credit', value);
                }}
                disabled={saving}
                error={formik.submitCount > 0 && !!formik.errors.credit}
                helperText={formik.submitCount > 0 && formik.errors.credit}
              />
            </Stack>
            <Stack>
              <PaymentEntitySelect
                name="debit"
                label="Debit"
                values={currentDebitors}
                value={formik.values.debit}
                onChange={(value) => {
                  formik.setFieldValue('debit', value);
                }}
                disabled={saving}
                error={formik.submitCount > 0 && !!formik.errors.debit}
                helperText={formik.submitCount > 0 && formik.errors.debit}
              />
            </Stack>
          </>
        )}
        <TextfieldComponent
          name="formula"
          label="Default value"
          value={formik.values.formula}
          onChange={formik.handleChange}
          multiline
          minRows={4}
          InputProps={{ style: { whiteSpace: 'pre', overflow: 'auto' } }}
          disabled={saving}
        />
        <SelectComponent
          name="order"
          label="Payrun display order"
          options={orders}
          value={formik.values.order}
          onChange={formik.handleChange}
          disabled={saving}
        />
        <CheckboxComponent
          name="required"
          label="Required"
          checked={formik.values.required}
          onChange={formik.handleChange}
          disabled={saving}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Save"
            type="submit"
            fullWidth
            loading={saving}
            sizeVariant="medium"
            colorVariant="primary"
            style={{ marginTop: spacing.m20 }}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

type GlobalPayrollPayCodeNewDrawerProps = {
  readonly payrollId: number;
  readonly onPaycodeSaved?: () => void;
};

export const GlobalPayrollPayCodeNewDrawer = ({ payrollId, onPaycodeSaved }: GlobalPayrollPayCodeNewDrawerProps) => {
  const { data: payrollPaycodes } = useApiClient(GlobalPayrollEndpoints.getPayrollPaycodes(payrollId));
  const paycodes = useMemo(() => payrollPaycodes?.paycodes ?? [], [payrollPaycodes?.paycodes]);

  return paycodes ? (
    <GlobalPayrollPayCodeNew payrollId={payrollId} paycodes={paycodes} onPaycodeSaved={onPaycodeSaved} />
  ) : null;
};
