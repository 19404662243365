import axios from 'axios';

import {
  ReviewComment,
  ReviewMeResultList,
  ReviewUserResultList,
} from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ReviewAPI {
  static async upsertComment(reviewId: string, cycleId: string, updateObject: ReviewComment): Promise<void> {
    return (await axios.patch(`/apiv2/reviews/comment/${reviewId}/${cycleId}`, updateObject)).data;
  }

  static async deleteComment(reviewId: string, cycleId: string, updateObject: ReviewComment): Promise<void> {
    return (await axios.patch(`/apiv2/reviews/delete-comment/${reviewId}/${cycleId}`, updateObject)).data;
  }

  static async upsertResultSharebility(
    reviewId: string,
    cycleId: string,
    updateObject: { isResultShared: boolean }
  ): Promise<void> {
    return (await axios.patch(`/apiv2/reviews/result-sharebility/${reviewId}/${cycleId}`, updateObject)).data;
  }
}

export class ReviewEndpoints {
  static getMeResultList(userId: number): Endpoint<ReviewMeResultList[]> {
    return {
      url: `/apiv2/reviews/result/me/${userId}`,
    };
  }

  static getUserResultList(userId: number, search: string): Endpoint<ReviewUserResultList[]> {
    const urlParams = new URLSearchParams({
      ...(search && { search }),
    });
    return {
      url: `/apiv2/reviews/result/user/${userId}?${urlParams.toString()}`,
    };
  }
}
