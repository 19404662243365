import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  APP_ACTION_DRAWER_MODES,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationStub,
  AppsWithLogins,
} from '@v2/feature/app-integration/app-integration.interface';
import { AppDetailsActionButton } from '@v2/feature/app-integration/features/app-details/components/app-details-action-button.component';
import { AppDetailsTable } from '@v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { AxiosError } from 'axios';

import type {
  AppIntegrationUserDto,
  InstalledAppDto,
  UserAppDto,
} from '@v2/feature/app-integration/app-integration.dto';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { nestErrorMessage } from '@/lib/errors';
import { checkScopes } from '@/lib/scopes';
import { getIconForAppStatus } from '@/v2/feature/app-integration/app-integration-detailed-personal.router';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { AppIntegrationUserEmailDto } from '@/v2/feature/app-integration/app-integration.dto';
import {
  getScheduledActionDateString,
  hasScheduledAction,
  userHasDelayedAppAction,
} from '@/v2/feature/app-integration/app-integration.util';
import { isAppOwner } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { AppAccessDetailDrawer } from '@/v2/feature/app-integration/features/app-details/components/app-access-detail-drawer.component';
import { AppActionsDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section';
import { AppGroupManagementDrawer } from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserAppsPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [preselectedUser, setPreselectedUser] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [actionsDrawerMode, setActionsDrawerMode] = useState<APP_ACTION_DRAWER_MODES | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState<APP_GROUP_MANAGEMENT_DRAWER_MODES | undefined>(undefined);
  const [appStub, setAppStub] = useState<AppIntegrationStub | undefined>(undefined);

  const [openAccessDetailModal, setOpenAccessDetailModal] = useState<boolean>(false);
  const [userForAccessDetail, setUserForAccessDetail] = useState<AppIntegrationUserDto | undefined>(undefined);
  const [scheduledActionDate, setScheduledActionDate] = useState<string | undefined>(undefined);
  const [statusColumnForRow, setStatusColumnForRow] = useState<JSX.Element | JSX.Element[] | undefined>(undefined);
  const [appStubForRow, setAppStubForRow] = useState<AppIntegrationStub | undefined>(undefined);
  const [hasAppsAllOrAppOwnerValue, setHasAppsAllOrAppOwnerValue] = useState<boolean>(false);
  const [appNameForRow, setAppNameForRow] = useState<string | undefined>(undefined);

  const [showMessage] = useMessage();

  const userId = useUserIdParam();
  const { getCachedUserById } = useCachedUsers();
  const userObjectForCurrentRoute = getCachedUserById(userId);
  const [combinedAppList, setCombinedAppList] = useState<readonly UserAppDto[]>([]);
  const {
    data: userAppStubs,
    isValidating: userAppStubsLoading,
    error: userAppStubsError,
    mutate: userAppsFetch,
  } = useApiClient(AppIntegrationEndpoints.getUserApps(userId), { suspense: false });
  const {
    data: companyApps,
    isValidating: companyAppsLoading,
    error: companyAppsError,
    mutate: companyAppsFetch,
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });
  const {
    data: delayedActions,
    mutate: refreshDelayedActions,
  } = useApiClient(AppIntegrationEndpoints.getAllDelayedAppActions(), { suspense: false });

  const { data: personalEmailForUser } = useApiClient(
    preselectedUser?.userId ? AppIntegrationEndpoints.getPersonalEmailForAppsByUserId(userId) : { url: undefined },
    {
      suspense: false,
    }
  );

  const getAppNameFromStub = useCallback(
    (stub: AppIntegrationStub): string => {
      return companyApps?.find((eachApp) => eachApp.stub === stub)?.name ?? '';
    },
    [companyApps]
  );

  const getAppInstanceFromStub = useCallback(
    (stub: AppIntegrationStub): InstalledAppDto | undefined => {
      return companyApps?.find((eachApp) => eachApp.stub === stub);
    },
    [companyApps]
  );

  const getApps = useCallback(async () => {
    try {
      userAppsFetch!();
      companyAppsFetch!();
      refreshDelayedActions!();
    } catch (error) {
      showMessage(`${polyglot.t('UserAppsPage.errorMessages.badRequest')}. ${nestErrorMessage(error)}`, 'error');
    }
  }, [userAppsFetch, companyAppsFetch, refreshDelayedActions, showMessage, polyglot]);

  useEffect(() => {
    if (userAppStubsError || companyAppsError) {
      showMessage(
        `${polyglot.t('UserAppsPage.errorMessages.badRequest')}. ${nestErrorMessage({
          response: userAppStubsError ?? companyAppsError,
        } as AxiosError)}`,
        'error'
      );
    }
  }, [polyglot, userAppStubsError, companyAppsError, showMessage]);

  useEffect(() => {
    const allAppsList: UserAppDto[] =
      companyApps && companyApps.length > 0
        ? (companyApps
            .map((eachApp) => {
              const emailsForUser = [];
              emailsForUser.push({ email: userObjectForCurrentRoute?.emailAddress, status: 'No access' });
              if (personalEmailForUser) emailsForUser.push({ email: personalEmailForUser, status: 'No access' });
              const existingApp = userAppStubs?.find((eachExistingApp) => eachExistingApp.stub === eachApp.stub);
              const delayedApp = delayedActions?.delayed?.find(
                (eachAction) => eachAction.data.appStub === eachApp.stub && eachAction.data.userId === userId
              );
              if (existingApp) return existingApp;
              if (delayedApp && delayedApp.opts.delay)
                return {
                  userId,
                  emails: undefined,
                  userStatus: `Scheduled`,
                  id: undefined,
                  ...eachApp,
                };
              return {
                userId,
                emails: emailsForUser ?? [],
                userStatus: 'No access',
                id: undefined,
                ...eachApp,
              };
            })
            .sort((eachApp) => (eachApp.userStatus === 'No access' ? 1 : -1)) as UserAppDto[])
        : [];
    setCombinedAppList(allAppsList);
  }, [
    userAppStubs,
    companyApps,
    userId,
    delayedActions?.delayed,
    globalState.user,
    userObjectForCurrentRoute?.emailAddress,
    personalEmailForUser,
  ]);

  const hasAppsAllOrAppOwner = useCallback(
    (app: InstalledAppDto | undefined): boolean => {
      return (
        checkScopes(globalState.user, ['apps:all'], { userId: globalState.user.userId }) ||
        isAppOwner(globalState.user.userId, app)
      );
    },
    [globalState.user]
  );

  const statusColumn = useCallback(
    (original: AppIntegrationUserDto) => {
      const appStub = original.stub;
      if (appStub && AppsWithLogins.includes(appStub) && original && original.emails && original?.emails?.length > 0) {
        return original?.emails.slice(0, 1)?.map((emailObj: AppIntegrationUserEmailDto) => (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              key={`${original.userId}-status-${emailObj.email}`}
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.userId && getIconForAppStatus(polyglot, emailObj.status)}
            </Typography>
          </Box>
        ));
      } else if (
        delayedActions &&
        original?.userId &&
        appStub &&
        userHasDelayedAppAction(delayedActions, Number(original.userId), appStub)
      ) {
        // Delayed actions block
        return (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography
                key={`${original.userId}-status`}
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original.userId && getIconForAppStatus(polyglot, 'Scheduled')}
              </Typography>
            </Box>
          </Box>
        );
      } else if (
        delayedActions &&
        original?.userId &&
        appStub &&
        !userHasDelayedAppAction(delayedActions, Number(original.userId), appStub) &&
        original?.emails &&
        original.emails.length > 0
      ) {
        return original?.emails.slice(0, 1)?.map((emailObj: AppIntegrationUserEmailDto) => (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              key={`${original.userId}-status`}
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.userId && getIconForAppStatus(polyglot, emailObj.status)}
            </Typography>
          </Box>
        ));
      } else {
        // Fallback status
        return (
          <Box key={`${original.userId}-status-container`} sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              key={`${original.userId}-status`}
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.userId && getIconForAppStatus(polyglot, original.userStatus)}
            </Typography>
          </Box>
        );
      }
    },
    [delayedActions, polyglot]
  );

  const teamAccessColumns = useMemo<ColumnDef<AppIntegrationUserDto, AppIntegrationUserDto>[]>(
    () => [
      {
        header: () => polyglot.t('UserAppsPage.app'),
        id: 'app-stub',
        maxSize: 180,
        minSize: 120,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <img src={`/app-icons-v2/images/${original.stub}.png`} width={20} alt={original.stub} />
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                ml: 1,
                gap: spacing.m5,
              }}
            >
              {original.stub && getAppNameFromStub(original.stub)}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('UserAppsPage.primaryEmail'),
        id: 'primaryEmail',
        maxSize: 220,
        minSize: 140,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <div>
            {original?.emails ? (
              original?.emails.slice(0, 1)?.map((emailObj: AppIntegrationUserEmailDto) => (
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.m5,
                  }}
                >
                  {emailObj.email}
                </Typography>
              ))
            ) : (
              <Typography
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {original.primaryEmail}
              </Typography>
            )}
          </div>
        ),
      },
      {
        header: () => polyglot.t('UserAppsPage.userStatus'),
        id: 'userStatus',
        maxSize: 180,
        minSize: 140,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => statusColumn(original),
      },
      {
        header: () => '',
        id: 'action',
        maxSize: 80,
        minSize: 80,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) =>
          original?.stub && (
            <AppDetailsActionButton
              refresh={getApps}
              appStub={original?.stub}
              user={original}
              table="team"
              setActionsDrawerMode={setActionsDrawerMode}
              setPreselectedUser={setPreselectedUser}
              setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
              hasAppsAllOrAppOwner={hasAppsAllOrAppOwner(getAppInstanceFromStub(original?.stub)) ?? false}
              error={false}
              setAppStub={setAppStub}
              setGroupManagementDrawerOpen={setIsDrawerOpen}
              setGroupManagementDrawerMode={setDrawerMode}
              delayedActions={delayedActions && delayedActions.delayed ? delayedActions : { delayed: [] }}
            />
          ),
      },
    ],
    [polyglot, getAppNameFromStub, statusColumn, getApps, hasAppsAllOrAppOwner, getAppInstanceFromStub, delayedActions]
  );

  const handleRowClicked = useCallback(
    ({ original }: Row<AppIntegrationUserDto>) => {
      setOpenAccessDetailModal(true);
      setUserForAccessDetail(original);
      const matchingAction =
        original?.userId && !Number.isNaN(Number(original?.userId))
          ? hasScheduledAction(delayedActions, Number(original?.userId), original.stub)
          : undefined;
      setScheduledActionDate(matchingAction ? getScheduledActionDateString(matchingAction) : undefined);
      if (original.stub) {
        const appInstance = getAppInstanceFromStub(original?.stub);
        setStatusColumnForRow(statusColumn(original));
        setAppStubForRow(original.stub);
        setHasAppsAllOrAppOwnerValue(hasAppsAllOrAppOwner(appInstance) ?? false);
        setAppNameForRow(appInstance?.name);
      }
    },
    [delayedActions, getAppInstanceFromStub, hasAppsAllOrAppOwner, statusColumn]
  );

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2 }}>Apps</Typography>} />
      <ContentWrapper loading={userAppStubsLoading || companyAppsLoading} sx={{ ...spacing.pt20 }}>
        <Box sx={{ ...spacing.mt20 }}>
          <AppDetailsTable
            column={teamAccessColumns}
            row={combinedAppList}
            loading={(userAppStubsLoading || companyAppsLoading) ?? false}
            handleRowClicked={handleRowClicked}
          />
        </Box>
        {appStub && (
          <>
            <AppActionsDrawer
              appStub={appStub}
              usersWithoutAccess={[]}
              isOpen={isAppActionsDrawerOpen}
              onClose={() => setIsAppActionsDrawerOpen(false)}
              refreshApp={getApps}
              setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
              preselectedUser={preselectedUser}
              mode={actionsDrawerMode}
            />
            <AppGroupManagementDrawer
              appStub={appStub}
              selectedGroupMembership={undefined}
              externalUserList={[]}
              usersWithoutAccess={[]}
              usersWithAccess={[]}
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              refreshApp={getApps}
              setIsDrawerOpen={setIsDrawerOpen}
              mode={drawerMode}
              groupMembers={{}}
              groupList={[]}
            />
          </>
        )}
        {userForAccessDetail && statusColumnForRow && appNameForRow && appStubForRow && (
          <AppAccessDetailDrawer
            isOpen={openAccessDetailModal}
            setIsOpen={setOpenAccessDetailModal}
            appUserDetail={userForAccessDetail}
            appName={appNameForRow}
            appStatusValue={statusColumnForRow}
            scheduledActionDate={scheduledActionDate}
            appActionButtons={
              <AppDetailsActionButton
                setAppAccessDetailsDrawerMode={setOpenAccessDetailModal}
                setIsAppActionsDrawerOpen={setIsAppActionsDrawerOpen}
                setGroupManagementDrawerOpen={setIsDrawerOpen}
                setGroupManagementDrawerMode={setDrawerMode}
                appStub={appStubForRow}
                user={userForAccessDetail}
                setAppStub={setAppStub}
                delayedActions={delayedActions ?? { delayed: [] }}
                refreshDelayedActions={refreshDelayedActions}
                table="team"
                setPreselectedUser={setPreselectedUser}
                setActionsDrawerMode={setActionsDrawerMode}
                hasAppsAllOrAppOwner={hasAppsAllOrAppOwnerValue}
                appAccessDetailsDrawerMode={true}
                refresh={getApps}
                error={false}
              />
            }
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
