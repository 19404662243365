import { Box } from '@mui/material';
import { Dictionary } from 'lodash';

import { Typography } from '@/v2/components/typography/typography.component';
import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { MultipleChoiceQuestionReadonly } from '@/v2/feature/growth/shared/components/readonly-questions/multiple-choice-question-readonly.component';
import { OpenEndedQuestionReadonly } from '@/v2/feature/growth/shared/components/readonly-questions/open-ended-question-readonly.components';
import { ScaleQuestionReadonly } from '@/v2/feature/growth/shared/components/readonly-questions/scale-question-readonly.component';
import { SingleAnswerQuestionReadonly } from '@/v2/feature/growth/shared/components/readonly-questions/single-answer-question-readonly.component';
import {
  GrowthQuestion,
  GrowthSection,
  OrderPreference,
} from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { borders } from '@/v2/styles/borders.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const GrowthPreviewer = ({
  questionsLookup,
  allSections,
  order,
}: {
  questionsLookup: Dictionary<GrowthQuestion>;
  allSections: GrowthSection[];
  order: OrderPreference[];
}) => {
  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title3">Preview</Typography>
      {order.length > 0 ? (
        order.map((item) => (
          <RenderQuestionOrSection item={item} questionsLookup={questionsLookup} sections={allSections} order={order} />
        ))
      ) : (
        <Typography variant="caption" color="Grey">
          Add some sections and question to preview them.
        </Typography>
      )}
    </Box>
  );
};

const RenderQuestionOrSection = ({
  item,
  questionsLookup,
  sections,
  order,
}: {
  item: OrderPreference;
  questionsLookup: Dictionary<GrowthQuestion>;
  sections: GrowthSection[] | undefined;
  order: OrderPreference[];
}) => {
  const renderQuestion = (questionId: string) => {
    const question = questionsLookup && questionsLookup[questionId];

    if (!question) return null;
    return <QuestionComponent key={question.id} question={question} />;
  };

  const renderSection = (sectionId: string) => {
    const section = sections?.find((s) => s.id === sectionId);
    if (!section) return null;

    const orderSection = order.find((o) => o.id === sectionId && o.type === 'section');
    if (!orderSection) return null;

    const orderedQuestions = (orderSection.questions ?? [])
      .map((questionId) => questionsLookup[questionId])
      .filter((question): question is GrowthQuestion => Boolean(question));

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16, paddingBottom: spacing.pb20 }}>
        <Typography variant="title2">{section.name}</Typography>
        {orderedQuestions.map((q) => (
          <QuestionComponent key={q.id} question={q} />
        ))}
      </Box>
    );
  };
  const renderQuestionOrSection = () => {
    if (item.type === 'question' && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === 'section' && (item.questions ?? []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return <Box sx={{ marginTop: spacing.m24 }}>{renderSection(item.id)}</Box>;
    } else return <></>;
  };

  return <>{renderQuestionOrSection()}</>;
};

const QuestionComponent = ({ question }: { question: GrowthQuestion }) => {
  const getQuestionByType = (question: GrowthQuestion) => {
    switch (question.type) {
      case QuestionType.SingleAnswer:
        return (
          <SingleAnswerQuestionReadonly
            questionText={question.questionText}
            options={question.options}
            hasComment={question.hasComment}
            isCommentRequired={question.isCommentRequired}
          />
        );
      case QuestionType.OpenEnded:
        return <OpenEndedQuestionReadonly questionText={question.questionText} />;
      case QuestionType.MultipleAnswer:
        return (
          <MultipleChoiceQuestionReadonly
            questionText={question.questionText}
            options={question.options}
            hasComment={question.hasComment}
            isCommentRequired={question.isCommentRequired}
          />
        );
      case QuestionType.ScaleQuestion:
        return (
          <ScaleQuestionReadonly
            questionText={question.questionText}
            scaleConfig={question.scaleConfig}
            hasComment={question.hasComment}
            isCommentRequired={question.isCommentRequired}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: spacing.p16, border: borders.background, borderRadius: radius.br8 }}>
      {getQuestionByType(question)}
    </Box>
  );
};
