import { useCallback, useEffect, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge, Box, Button, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import Bull from 'bull';

import useMessage from '@/hooks/notification.hook';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { QueueStatus, UserImportViaLinkedinJob } from '@/v2/feature/monitoring/monitoring.interface';
import { QUEUE_TABS } from '@/v2/feature/monitoring/monitoring.page';
import { UserImportAPI } from '@/v2/feature/user/features/user-import/user-import.api';
import { secondarySmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

export const SuperAdminLinkedinImportPage = (): JSX.Element => {
  const [linkedInUrl, setLinkedInUrl] = useState<string>();
  const [emailDomain, setEmailDomain] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [queueData, setQueueData] = useState<QueueStatus<UserImportViaLinkedinJob> | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState<string>('Active');
  const [showMessage] = useMessage();

  const triggerLinkedInImport = async () => {
    setLoading(true);
    try {
      if (linkedInUrl && emailDomain) {
        showMessage(
          'Successfully triggered user data fetch via LinkedIn - This may take a few minutes, depending on the size of the company',
          'success'
        );
        await UserImportAPI.importViaLinkedIn(linkedInUrl, emailDomain);
        setLinkedInUrl('');
        setEmailDomain('');
      } else {
        showMessage('Unable to trigger user data fetch via LinkedIn without required values!', 'error');
      }
    } catch (error) {
      showMessage(
        `Encountered an error while trying to fetch user data via LinkedIn: ${JSON.stringify(error)}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const refreshQueueData = useCallback(() => {
    setLoading(true);
    UserImportAPI.getUserImportLinkedInQueues().then((data) => {
      setQueueData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    refreshQueueData();
  }, [refreshQueueData]);

  const tableColumns = useMemo<ColumnDef<Bull.Job<UserImportViaLinkedinJob>, Bull.Job<UserImportViaLinkedinJob>>[]>(
    () => [
      {
        header: () => 'jobId',
        id: 'id',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => <div>{original.id ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'Job Name',
        id: 'name',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => <div>{original.name ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'linkedInUrl',
        id: 'linkedInUrl',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => <div>{original.data.linkedinUrl ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'emailDomain',
        id: 'emailDomain',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => <div>{original.data.emailDomain ?? <EmptyCell />}</div>,
      },
      {
        header: () => 'result',
        id: 'result',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => (
          <Box>
            {original.returnvalue && (
              <Button
                sx={secondarySmallBtn}
                onClick={() => {
                  const csvData = original.returnvalue;
                  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                  const url = URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.setAttribute('href', url);
                  link.setAttribute('download', `${original.data.emailDomain}-${new Date().toISOString()}.csv`);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document?.body.removeChild(link);
                }}
              >
                Download CSV
              </Button>
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const currentTabData = useMemo(() => {
    switch (currentTab) {
      case 'Active':
        return (queueData?.active as unknown) as Bull.Job<UserImportViaLinkedinJob>[];
      case 'Completed':
        return (queueData?.completed as unknown) as Bull.Job<UserImportViaLinkedinJob>[];
      case 'Failed':
        return (queueData?.failed as unknown) as Bull.Job<UserImportViaLinkedinJob>[];
      case 'Waiting':
        return (queueData?.waiting as unknown) as Bull.Job<UserImportViaLinkedinJob>[];
      default:
        return [];
    }
  }, [currentTab, queueData]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            Fetch user data via Linkedin
          </Typography>
        }
        views={<></>}
      />
      <ContentWrapper>
        <Stack sx={{ width: '800px', gap: '20px' }}>
          <TextfieldComponent
            label={'LinkedIn URL of target company (e.g: https://www.linkedin.com/company/apple)'}
            name={'linkedInUrl'}
            defaultValue=""
            value={linkedInUrl}
            onChange={(e) => {
              setLinkedInUrl(e.target.value);
            }}
            clearText={() => setLinkedInUrl('')}
            disabled={loading}
          />
          <TextfieldComponent
            label={'Email domain of target company (e.g: apple.com)'}
            name={'emailDomain'}
            defaultValue=""
            value={emailDomain}
            onChange={(e) => {
              setEmailDomain(e.target.value);
            }}
            clearText={() => setEmailDomain('')}
            disabled={loading}
          />
          <LoaderButton
            id={'fetchLinkedinData'}
            name={'Fetch user data'}
            loading={loading}
            onClick={() => triggerLinkedInImport()}
            disabled={!linkedInUrl || !emailDomain || loading}
            sizeVariant="large"
            colorVariant="primary"
          />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(e, value) => setCurrentTab(value)}
            >
              {QUEUE_TABS.filter((tab) => tab.value !== 'Delayed').map((tab) => (
                <Tab
                  key={tab.value}
                  disableRipple
                  label={
                    <span
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        paddingRight:
                          // @ts-ignore
                          queueData && queueData[tab.value.toLowerCase()].length > 0 ? 10 : 0,
                      }}
                    >
                      {tab.value}
                      {/* @ts-ignore */}
                      {queueData && queueData[tab.value.toLowerCase()].length > 0 && (
                        <Badge
                          badgeContent={
                            // @ts-ignore
                            queueData && queueData[tab.value.toLowerCase()].length > 0
                              ? // @ts-ignore
                                queueData[tab.value.toLowerCase()].length
                              : ''
                          }
                          color="primary"
                          sx={{ ml: 2 }}
                        />
                      )}
                    </span>
                  }
                  value={tab.value}
                />
              ))}
              <IconButton color="secondary" onClick={() => refreshQueueData()}>
                <RefreshIcon />
              </IconButton>
            </Tabs>
          </Box>
          <BasicTable rowData={currentTabData ?? []} columnData={tableColumns} loading={loading} />
        </Stack>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
