import { PayrollDateType, TaxYearStart } from '@shared/modules/payroll/payroll.types';

import { ApprovalRuleDto } from '@/v2/feature/approval-rule/approval-rule.dto';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { getDayOfWeekName } from '@/v2/infrastructure/date/date-format.util';

export const ruleForApprovals = (approvalRules?: ApprovalRuleDto[] | null, ruleIdForType?: number) => {
  const noneOption = 'None';
  if (!approvalRules || !ruleIdForType) return noneOption;
  const matchingRule = approvalRules?.find((r) => r.id === ruleIdForType);
  return matchingRule ? matchingRule?.name : noneOption;
};

const ordinalString = (n: number) => {
  const ordinalSuffixes = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
  return `${n}${ordinalSuffixes[Math.abs(n) % 10]}`;
};

export const formatPayrollDateType = (payPeriod?: PayPeriod, date?: string, type?: PayrollDateType) => {
  if (!payPeriod || !date || !type) return;
  if (payPeriod === 'Weekly') {
    return `${getDayOfWeekName(date)} of each week`;
  }
  return {
    LastDay: 'Last day of the month',
    SameDate: `The ${ordinalString(Number(date.slice(8)))} of each month`,
  }[type];
};

export const formatTaxYearStart = (value?: TaxYearStart) => {
  return (
    value &&
    new Date(`${value}-2000`).toLocaleDateString(undefined, {
      month: 'long',
      day: '2-digit',
    })
  );
};
