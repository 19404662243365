import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { XMLValidator } from 'fast-xml-parser';
import { Form, FormikProvider, useFormik } from 'formik';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CurrentUser } from '@/models';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { SSOState } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { EnableSSODisableMFAConflictConfirmDrawer } from '@/v2/feature/app-integration/features/app-details/components/app-details-sso-enable-mfa-conflict-drawer.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthLoginDtoWithUserDetails } from '@/v2/feature/auth/auth.dto';
import { getSSOLoginResponse } from '@/v2/feature/auth/auth.util';
import { ENABLED_SSO_APPS } from '@/v2/feature/auth/features/auth-login/auth-login.page';
import { isDefined } from '@/v2/feature/user-onboarding/user-onboarding.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { detailValueSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
// import { actionIconSize } from '@/v2/styles/table.styles';
import { arrayBufferToString } from '@/v2/util/string.util';

interface SSOSamlMetadata {
  certificate: string;
  ssoEnabled: boolean;
}

const ssoSamlConfigURL = 'https://admin.google.com/u/2/ac/security/ssocert';
const ssoProdACSURL = 'https://zelt-production.firebaseapp.com/__/auth/handler';
const ssoSamlVideoInstructionsURL = 'https://www.youtube.com/watch?v=Jy5Ia3e8LQs';

interface SSOGWUpdateFormProps {
  mfaEnabled: boolean;
  ssoState: SSOState[];
  onUpdate: VoidFunction;
  currentUser: CurrentUser;
}

export const SSOGWUpdateForm = ({ mfaEnabled, ssoState, onUpdate, currentUser }: SSOGWUpdateFormProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  // const [edit, setEdit] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<string>('');
  const [ssoEnabled, setSsoEnabled] = useState<boolean>(true);
  const [testingSSO, setTestingSSO] = useState<boolean>(false);
  // const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [confirmEnablement, setConfirmEnablement] = useState<boolean>(false);
  const [confirmEnableSSOAndDisableMFA, setConfirmEnableSSOAndDisableMFA] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    try {
      // setLoading(true);
      // const mfaData = await AuthAPI.mfaCheck();
      // setMfaEnabled(mfaData);
      // const ssoState = await AppDetailsAPI.getSSOMetadata();
      // if (!ssoState) {
      //   setLoading(false);
      //   return;
      // }
      const gwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'google-workspace');
      const ssoGwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-google-workspace');
      const someEntryEnabled = ssoState?.some((eachEntry) => eachEntry.state.enabled);
      if (gwEntry) setCertificate(gwEntry?.state?.certificate);
      if (ssoGwEntry) setCertificate(ssoGwEntry?.state?.certificate);
      setSsoEnabled(someEntryEnabled);
      // setLoading(false);
      // onUpdate();
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t('SSOGWUpdateForm.errorMessages.fetch')}: ${JSON.stringify(error)}`, 'error');
    }
  }, [polyglot, showMessage, ssoState]);

  useEffect(() => {
    refresh();
  }, [refresh, ssoState]);

  const saveSsoSamlMetadata = async (values: SSOSamlMetadata) => {
    try {
      if (!ssoEnabled && mfaEnabled && !confirmEnableSSOAndDisableMFA) {
        setConfirmEnablement(true);
        return;
      }
      if (!values || Object.keys(values)?.length === 0) return;
      setLoading(true);
      const finalPayload = {
        certificate: isDefined(values.certificate) ? values.certificate : certificate,
        enabled: true,
      };
      const encoded = btoa(JSON.stringify(finalPayload));
      if (values.certificate && encoded) {
        await AppDetailsAPI.saveSSOSamlMetadata('sso-google-workspace', {
          sso_saml_metadata: encoded,
          disable_mfa: confirmEnableSSOAndDisableMFA,
        });
      }

      await testGWSSOLogin();
      // showMessage(polyglot.t('SSOGWUpdateForm.successMessages.update'), 'success');
      // onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t('SSOGWUpdateForm.errorMessages.save')}: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
      refresh();
    }
  };

  const gwEnabled = useMemo(() => {
    return !!ssoState.find((eachApp) => eachApp.app === 'sso-google-workspace' && eachApp.state.enabled);
  }, [ssoState]);

  const disableGWSSO = async (forced = false) => {
    try {
      if (!forced && !gwEnabled) return;
      setLoading(true);
      await AppDetailsAPI.disableSSOGW();

      if (!forced) showMessage(polyglot.t('SSOGWUpdateForm.successMessages.disable'), 'success');
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t('SSOGWUpdateForm.errorMessages.disable')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
      refresh();
    }
  };

  const formik = useFormik<SSOSamlMetadata>({
    initialValues: {
      certificate,
      ssoEnabled,
    },
    enableReinitialize: true,
    onSubmit: saveSsoSamlMetadata,
  });

  const triggerEnableSSOAndDisableMFA = () => {
    setConfirmEnableSSOAndDisableMFA(true);
    formik.handleSubmit();
  };

  const testGWSSOLogin = async () => {
    setTestingSSO(true);
    try {
      const loginCheckData = await AuthAPI.ssoCheck(currentUser.emailAddress);
      const ssoConfig = loginCheckData?.ssoConfig;
      const matchingProviderData = ssoConfig.find(
        (eachApp) => ENABLED_SSO_APPS.includes(eachApp.app) && eachApp.enabled
      );
      if (matchingProviderData) {
        const loginResult = (await getSSOLoginResponse(
          currentUser.emailAddress,
          matchingProviderData
        )) as AuthLoginDtoWithUserDetails;
        if (loginResult.accessToken && loginResult.userId && loginResult.companyId === currentUser.company.companyId) {
          showMessage(polyglot.t('SSOGWUpdateForm.successMessages.ssoTest'), 'success');
          onUpdate();
        } else {
          disableGWSSO(true);
          showMessage(polyglot.t('SSOOktaUpdateForm.errorMessages.ssoTest'), 'error');
        }
      }
    } catch (error) {
      showMessage(
        `${polyglot.t('SSOOktaUpdateForm.errorMessages.ssoTestErrorEncountered')}: ${nestErrorMessage(error)}`,
        'error'
      );
      console.error('Encountered an error while trying to test SSO: ', error);
      disableGWSSO(true);
    } finally {
      setTestingSSO(false);
    }
  };

  return (
    <Box sx={{ width: '100%', mt: spacing.mt10 }}>
      {!testingSSO && !gwEnabled && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
              {polyglot.t('SSOGWUpdateForm.uploadIdP')}
              <br />
              {`${polyglot.t('SSOGWUpdateForm.findGuide')} `}
              <Link target="_blank" rel="noreferrer" href={ssoSamlVideoInstructionsURL}>
                {polyglot.t('SSOGWUpdateForm.video')}.
              </Link>
              <br />
              <br />
              {`${polyglot.t('SSOGWUpdateForm.useLink')}: `}
              <br />
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
                {`${ssoProdACSURL}`}
              </Typography>
              <br />
              {`${polyglot.t('SSOGWUpdateForm.copyEntityId')} `}
              <Link target="_blank" rel="noreferrer" href={ssoSamlConfigURL}>
                {polyglot.t('SSOGWUpdateForm.page')}
              </Link>
            </Typography>
          </Box>
          <>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} component="section">
                  <Box sx={{ width: '100%' }}>
                    <Stack>
                      <UploadInput<Record<string, any>>
                        skipUpload={true}
                        onChange={async (_: any, file: File | undefined) => {
                          try {
                            if (!file) return;
                            const arrayBuffer = await file.arrayBuffer();
                            const xmlBuffer = await arrayBufferToString(arrayBuffer);
                            const xmlValidationResult: Boolean | { err: Record<string, any> } = XMLValidator.validate(
                              xmlBuffer
                            );
                            if (typeof xmlValidationResult !== 'boolean') {
                              formik.setFieldError('certificate', 'Invalid XML file');
                              throw new Error(polyglot.t('SSOGWUpdateForm.errorMessages.invalidxml'));
                            }
                            formik.setFieldError('certificate', undefined);
                            formik.setFieldValue('certificate', xmlBuffer, true);
                          } catch (error) {
                            showMessage(polyglot.t('SSOGWUpdateForm.errorMessages.errorParsing'), 'error');
                            console.error(':::: ERROR PARSING FILE :::::', error);
                          }
                        }}
                      />
                    </Stack>
                  </Box>
                </Box>
                <Stack sx={{ flexFlow: 'row', my: spacing.m20 }}>
                  <LoaderButton
                    fullWidth
                    disabled={
                      (formik.values.ssoEnabled && !certificate && !formik.values.certificate) ||
                      !formik.isValid ||
                      !formik.dirty ||
                      loading
                    }
                    sizeVariant="large"
                    colorVariant="primary"
                    name={polyglot.t('General.save')}
                    loading={loading}
                  />
                </Stack>
              </Form>
            </FormikProvider>
            <EnableSSODisableMFAConflictConfirmDrawer
              isOpen={confirmEnablement}
              setIsOpen={setConfirmEnablement}
              onClose={() => setConfirmEnablement(false)}
              onConfirm={() => triggerEnableSSOAndDisableMFA()}
            />
          </>
        </>
      )}
      {!testingSSO && gwEnabled && (
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center' }} component="section">
                <Box sx={detailValueSx}>
                  <Stack>
                    <UploadInput<Record<string, any>>
                      skipUpload={true}
                      onChange={async (_: any, file: File | undefined) => {
                        try {
                          if (!file) return;
                          const arrayBuffer = await file.arrayBuffer();
                          const xmlBuffer = await arrayBufferToString(arrayBuffer);
                          const xmlValidationResult: Boolean | { err: Record<string, any> } = XMLValidator.validate(
                            xmlBuffer
                          );
                          if (typeof xmlValidationResult !== 'boolean') {
                            formik.setFieldError('certificate', 'Invalid XML file');
                            throw new Error(polyglot.t('SSOGWUpdateForm.errorMessages.invalidxml'));
                          }
                          formik.setFieldError('certificate', undefined);
                          formik.setFieldValue('certificate', xmlBuffer, true);
                        } catch (error) {
                          showMessage(polyglot.t('SSOGWUpdateForm.errorMessages.errorParsing'), 'error');
                          console.error(':::: ERROR PARSING FILE :::::', error);
                        }
                      }}
                    />
                  </Stack>
                </Box>
              </Box>
              <Stack sx={{ flexFlow: 'row', mt: spacing.m20, gap: spacing.g10, justifyContent: 'center' }}>
                <ButtonComponent
                  sizeVariant="medium"
                  colorVariant="secondary"
                  onClick={() => {
                    disableGWSSO();
                  }}
                  disabled={loading}
                >
                  {polyglot.t('SSOGWUpdateForm.disable')}
                </ButtonComponent>
                <LoaderButton
                  disabled={
                    (formik.values.ssoEnabled && !certificate && !formik.values.certificate) ||
                    !formik.isValid ||
                    !formik.dirty ||
                    loading
                  }
                  sizeVariant="medium"
                  colorVariant="primary"
                  name={polyglot.t('SSOGWUpdateForm.update')}
                  loading={loading}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
      {testingSSO && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: spacing.m10 }}>
            {polyglot.t('SSOOktaUpdateForm.testingsso')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
