import { Box, Button, Typography } from '@mui/material';
import { primarySmallBtn } from '@v2/styles/buttons.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { themeColors } from '@/v2/styles/colors.styles';

interface DeviceActivationStepChargerProps {
  readonly goToNextStep: () => void;
}

export const DeviceActivationStepCharger = ({ goToNextStep }: DeviceActivationStepChargerProps) => {
  return (
    <Box sx={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', gap: spacing.gap20 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Use original charger only</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
          Please make sure to only charge your device with the original power supply. Any third party charges can
          decrease the life of the battery.
        </Typography>
        <Box>
          <Button sx={primarySmallBtn} onClick={goToNextStep}>
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
