import React, { Suspense, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { AddExistingDeviceDrawer } from '@v2/feature/device/features/devices-company/components/add-existing-device-drawer.component';
import { ConfigurableDeviceListView } from '@v2/feature/device/features/devices-company/components/configurable-device-view.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { useLocation } from 'react-router-dom';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface DevicesActivePageProps {
  readonly loading: boolean;
}

export const DevicesActivePage = ({ loading }: DevicesActivePageProps): JSX.Element => {
  const routerLocation = useLocation();
  const [isAddExistingOpen, setIsAddExistingOpen] = useState<boolean>(false);

  const {
    data: configurableDevices,
    mutate: refreshConfigurableDevicesMutation,
    isLoading: devicesLoading,
  } = useApiClient(DeviceEndpoints.getDirectoryDevices(), { suspense: false });

  const refresh = useCallback(async () => {
    await refreshConfigurableDevicesMutation?.();
    setIsAddExistingOpen(false);
  }, [refreshConfigurableDevicesMutation]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>}
        showBack={routerLocation.pathname.includes('/settings')}
        actions={
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => {
              setIsAddExistingOpen(true);
            }}
          >
            New device
          </ButtonComponent>
        }
        showAction={true}
      />
      <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
        <Box>
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="83%"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            }
          >
            {!devicesLoading ? (
              <ConfigurableDeviceListView configurableDevices={configurableDevices} />
            ) : (
              <SkeletonLoader
                variant="rectangular"
                width="200px"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            )}
          </Suspense>
        </Box>
        {isAddExistingOpen && (
          <AddExistingDeviceDrawer isOpen={isAddExistingOpen} setIsOpen={setIsAddExistingOpen} refresh={refresh} />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
