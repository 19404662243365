import axios from 'axios';

import { ReviewQuestion, ReviewQuestionUpsert } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';

export class ReviewQuestionAPI {
  static async createAndCloneQuestionInCycle(questionBody: ReviewQuestionUpsert): Promise<ReviewQuestion> {
    return (await axios.post('/apiv2/review-question', questionBody)).data;
  }

  static async updateQuestionInCycle(questionBody: ReviewQuestionUpsert): Promise<void> {
    return (await axios.patch('/apiv2/review-question', questionBody)).data;
  }

  static async deleteReviewQuestion(questionId: string, cycleId: string): Promise<void> {
    await axios.delete(`/apiv2/review-question/${questionId}/${cycleId}`);
  }
}
