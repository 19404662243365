import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { v4 as uuidv4 } from 'uuid';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { IncomeImportDto } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.interface';
import {
  calcPaycodeTotalForPayrunEntry,
  getOptionalPayCodesInUse,
  getUnitValue,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayLineEntry, PayRunEntryDto, PayrunEntryIncomeUpdateDto } from '@/v2/feature/payroll/payroll.dto';
import { titleSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { StaffologyPayCode } from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { parseCsvBuffer } from '@/v2/util/csv.util';
import { arrayBufferToString, getFirstAndLastNameFromNameData } from '@/v2/util/string.util';

interface ImportIncomeDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly entries: readonly PayRunEntryDto[];
  payCodes?: StaffologyPayCode[] | null;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
}

export const ImportIncomeDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  entries,
  payCodes,
  saveIncomeUpdates,
}: ImportIncomeDrawerProps): JSX.Element => {
  const [showMessage] = useMessage();
  const [uploading, setUploading] = useState(false);

  const mapCSVUpdatesForIncomeImport = (
    entries: readonly PayRunEntryDto[],
    payCodes: StaffologyPayCode[],
    parsedJSON: IncomeImportDto[]
  ): PayrunEntryIncomeUpdateDto[] => {
    const updatesViaImport: PayrunEntryIncomeUpdateDto[] = [];
    for (const eachJSONUpdate of parsedJSON) {
      const matchingPayrunEntry = entries.find(
        (eachEntry) => eachEntry.employmentDetails.payrollCode === eachJSONUpdate.payrollCode
      );
      if (!matchingPayrunEntry) continue;
      const { firstName: _1, lastName: _2, payrollCode: _3, salaryBasis, rate, units, ...codes } = eachJSONUpdate;
      const mapPayCodesToPayLines = (kind: 'addition' | 'deduction') => {
        const isDeduction = kind === 'deduction';
        return payCodes
          .filter((payCode) => payCode.isDeduction === isDeduction && payCode.code.toLowerCase() in codes)
          .map<PayLineEntry>(({ code, title }) => ({
            id: uuidv4(),
            code,
            isDeduction,
            amount: parseFloat(codes[code.toLowerCase()] || '0'),
            description: title,
            recurringId: null,
            recurring: null,
          }));
      };
      const update: PayrunEntryIncomeUpdateDto = {
        id: matchingPayrunEntry.id,
        userId: matchingPayrunEntry.userId,
        additions: {
          salary: {
            amount: parseFloat(rate || '0'),
            multiplier: ['Daily', 'Hourly'].includes(salaryBasis) ? parseFloat(units || '1') : 1,
          },
          paylines: mapPayCodesToPayLines('addition'),
        },
        deductions: {
          paylines: mapPayCodesToPayLines('deduction'),
        },
      };
      updatesViaImport.push(update);
    }
    return updatesViaImport;
  };

  const getIncomeTableForExport = async (): Promise<Datas> => {
    try {
      const allColumnsForTemplateCSV = entries.map((eachEntry) => {
        const { payOptions } = eachEntry;
        const [firstName, lastName] = getFirstAndLastNameFromNameData(eachEntry?.employee?.name);
        const paycodesInUse = [
          ...new Set([
            ...getOptionalPayCodesInUse(payCodes ?? [], [eachEntry], false),
            ...getOptionalPayCodesInUse(payCodes ?? [], [eachEntry], true),
            // always include all custom codes to use as an example
            ...(payCodes ?? []).filter((p) => !p.isSystemCode && !p.isControlCode),
          ]),
        ].sort((a, b) => a.code.localeCompare(b.code));
        return {
          firstName,
          lastName,
          payrollCode: eachEntry?.employmentDetails?.payrollCode,
          salaryBasis: payOptions?.basis,
          rate: payOptions?.payAmount?.toFixed(2),
          units: getUnitValue(eachEntry).toFixed(2).replace('.00', ''),
          ...paycodesInUse.reduce((codes, paycode) => {
            codes[paycode.code] = calcPaycodeTotalForPayrunEntry(eachEntry, paycode.code);
            return codes;
          }, {} as Record<string, number | undefined>),
        };
      });

      return (allColumnsForTemplateCSV as unknown) as Datas;
    } catch (error) {
      console.error('Something went wrong fetching template file for income import', error.message);
      return [];
    }
  };
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <Typography sx={titleSx}>Import income</Typography>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.m15, display: 'inline-block' }}>
          Import income and deductions for the current payrun in bulk by using{' '}
          <Typography
            sx={{ display: 'inline-block', ...themeFonts.caption, textDecoration: 'underline', cursor: 'pointer' }}
          >
            <CsvDownloader filename="income-import-template" separator="," datas={getIncomeTableForExport}>
              this template
            </CsvDownloader>
          </Typography>
        </Typography>

        <Box sx={{ mt: 3 }}>
          {!uploading ? (
            <UploadInput<IncomeImportDto>
              skipUpload={true}
              onChange={async (_: any, file: File | undefined) => {
                try {
                  setUploading(true);
                  if (!file) return;
                  const arrayBuffer = await file.arrayBuffer();
                  const csvBuffer = await arrayBufferToString(arrayBuffer);
                  const parsedJSONData = ((await parseCsvBuffer(csvBuffer)) as unknown) as IncomeImportDto[];
                  const updatesViaImport = mapCSVUpdatesForIncomeImport(entries, payCodes ?? [], parsedJSONData);
                  const result = await saveIncomeUpdates(updatesViaImport);
                  if (result) onClose();
                } catch (error) {
                  showMessage('Error parsing file, please select a CSV file', 'error');
                  console.error(':::: ERROR PARSING FILE :::::', error);
                } finally {
                  setUploading(false);
                }
              }}
            />
          ) : (
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" disabled={uploading}>
              Records being uploaded...
            </ButtonComponent>
          )}
        </Box>
      </>
    </DrawerModal>
  );
};
