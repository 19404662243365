import { useMemo } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { iconSize } from '@/v2/styles/menu.styles';

interface ContractTemplateEditorActionsButtonProps {
  onEditClick: () => void;
  onDownloadClick: () => void;
  size?: 'small' | 'medium' | 'large';
  isAdmin?: boolean;
}

export const ContractTemplateEditorActionsButton = ({
  onEditClick,
  onDownloadClick,
}: ContractTemplateEditorActionsButtonProps) => {
  const { polyglot } = usePolyglot();

  const contractTemplateEditorActionsButtonOptions = useMemo(() => {
    const optionsAvailable = [];
    if (onEditClick)
      optionsAvailable.push({
        icon: <Edit {...iconSize} />,
        handler: () => onEditClick(),
        label: polyglot.t('General.edit'),
        disabled: false,
      });
    if (onDownloadClick)
      optionsAvailable.push({
        icon: <Download {...iconSize} />,
        handler: () => onDownloadClick(),
        label: polyglot.t('General.download'),
        disabled: false,
      });
    return optionsAvailable;
  }, [onEditClick, onDownloadClick, polyglot]);

  return (
    <>
      <StyledMenuComponent
        options={contractTemplateEditorActionsButtonOptions}
        actionButtonDetails={{
          type: 'button',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: polyglot.t('UserSignContractModal.actions'),
          icon: <ArrowDown {...iconSize} />,
          iconPosition: 'end',
        }}
      />
    </>
  );
};
