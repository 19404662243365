import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const isUrlValid = (url: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z/\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(url);
};

interface DeviceOrderTrackingLinkFormProps {
  readonly deviceOrder: DeviceOrderDto;
}

export const DeviceOrderTrackingLinkForm = ({ deviceOrder }: DeviceOrderTrackingLinkFormProps) => {
  const [trackingLink, setTrackingLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  useEffect(() => {
    (async () => {
      try {
        const trackingLink = await DeviceAPI.getOrderTrackingLinkAsSuperAdmin(deviceOrder.id);
        setTrackingLink(trackingLink ?? '');
      } catch (error) {
        showMessage(`Could not get order tracking link. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [deviceOrder.id, showMessage]);
  const updateTrackingLink = async (): Promise<void> => {
    if (!trackingLink) return;
    try {
      setLoading(true);
      const validatedLink = trackingLink.startsWith('https://') ? trackingLink : `https://${trackingLink}`;
      if (!isUrlValid(validatedLink)) throw new Error('Tracking link is not valid!');

      await DeviceAPI.updateOrderTrackingLink(deviceOrder.id, validatedLink);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: spacing.gap20, alignItems: 'end' }}>
      <TextfieldComponent
        label="Tracking Link"
        name="trackingLink"
        value={trackingLink}
        onChange={(event) => {
          setTrackingLink(event.target.value);
        }}
      />
      <LoaderButton
        name="Update"
        loading={loading}
        onClick={updateTrackingLink}
        sizeVariant="medium"
        colorVariant="primary"
        disabled={!trackingLink}
      />
    </Box>
  );
};
