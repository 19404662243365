import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { SETTINGS_REQUEST_FORMS_ROUTE } from '@/lib/routes';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { NewRequestFormPage } from '@/v2/feature/requests/features/request-forms/new-request-form/new-request-form.page';
import { RequestFormTemplate } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

export const EditRequestFormPage = () => {
  const { formId: formIdParam } = useParams<{ formId: string }>();
  const [template, setTemplate] = useState<RequestFormTemplate>();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const { data: templates, error } = useApiClient(RequestFormsEndpoints.getFormTemplates(), { suspense: false });

  useEffect(() => {
    if (!templates) return;
    const formId = Number(formIdParam);
    const template = templates.find((t) => t.id === formId);
    if (!template) {
      showMessage('Request template not found', 'error');
      routerHistory.replace(SETTINGS_REQUEST_FORMS_ROUTE);
      return;
    }
    setTemplate(template);
  }, [formIdParam, routerHistory, showMessage, templates]);

  if (template) {
    return <NewRequestFormPage templateToEdit={template} />;
  }

  return (
    <Stack sx={{ flex: 1, justifyContent: 'center', mt: spacing.mt40 }}>
      {!template && error && <Typography variant="caption">{error?.data?.message}</Typography>}
      {!template && !error && <LoadingSpinner />}
    </Stack>
  );
};
