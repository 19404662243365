import { useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import { GlobalContext, GlobalStateActions, GlobalStateProvider } from '@/GlobalState';
import {
  LOGIN_ROUTE,
  USER_ONBOARDING_ABOUT,
  USER_ONBOARDING_DOCUMENTS,
  USER_ONBOARDING_PAYROLL,
  USER_ONBOARDING_SUMMARY_ROUTE,
  USER_ONBOARDING_TASKS,
} from '@/lib/routes';
import { CurrentUser } from '@/models';
import { Loader } from '@/v2/components/loader.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { UserOnboardingByUserProvider } from '@/v2/feature/user-onboarding/onboarding-by-user/onboarding-by-user.context';
import { OnboardingBasicFlow } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/onboarding-basic-flow.page';
import { OnboardingDocumentsPage } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-documents/onboarding-documents.page';
import { OnboardingPayrollPage } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll.page';
import { OnboardingTasksPage } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-tasks/onboarding-tasks.page';

const LayoutRootStyle = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const RedirectToOnboardingSummary = () => {
  const [path, setPath] = useState<string>();
  useEffect(() => {
    AuthAPI.getAuthMe().then(
      ({ user }) => setPath(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId: user.userId })),
      () => setPath(LOGIN_ROUTE)
    );
  }, []);
  return path ? <Redirect to={path} /> : <></>;
};

export const OnboardingUserRouter = (): JSX.Element => {
  const [globalState, dispatch] = useContext(GlobalContext);
  const [user, setUser] = useState<CurrentUser | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    AuthAPI.getAuthMe()
      .then((response) => {
        const { user } = response;
        const authUser = user ?? null;
        setUser(authUser);
        dispatch({ type: GlobalStateActions.UPDATE_USER, payload: authUser });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  return (
    <LayoutRootStyle>
      <UserOnboardingByUserProvider>
        {!isLoading && user ? (
          <GlobalStateProvider initialState={{ ...globalState, user }}>
            <Switch>
              <Route exact path={USER_ONBOARDING_ABOUT}>
                <OnboardingBasicFlow />
              </Route>
              <Route exact path={USER_ONBOARDING_DOCUMENTS}>
                <OnboardingDocumentsPage />
              </Route>
              <Route exact path={USER_ONBOARDING_PAYROLL}>
                <OnboardingPayrollPage mode="onboarding" />
              </Route>
              <Route exact path={USER_ONBOARDING_TASKS}>
                <OnboardingTasksPage />
              </Route>
              <Route component={RedirectToOnboardingSummary} />
            </Switch>
          </GlobalStateProvider>
        ) : (
          <Loader loading={!!isLoading} hideFooter={true}>
            <>Loading</>
          </Loader>
        )}
      </UserOnboardingByUserProvider>
    </LayoutRootStyle>
  );
};
