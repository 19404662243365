import React from 'react';

import { Box } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { HelperAbsencePoliciesTable } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policies-table.component';
import { HelperAbsencePolicyBalance } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-balance.component';
import { HelperAbsenceSettingsSection } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-settings-section.component';
import { HelperAbsenceUsersCarryOverSection } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-users-carry-over-section.component';
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { generatePath, Route, Switch } from 'react-router-dom';

export const HelperCarryOverPage = ({
  users,
  company,
}: {
  users: readonly UserDetailsSuperAdminDto[];
  company: SuperAdminCompanyInfo;
}) => {
  const companyId = company.companyId;

  const {
    data: absenceSettings,
    isLoading: isLoadingSettings,
  } = useApiClient(AbsenceEndpoints.getCompanyAbsenceSettingsAsSuperAdmin(companyId), { suspense: false });

  const {
    data: companyPolicies,
    isLoading: isLoadingPolicies,
  } = useApiClient(AbsenceEndpoints.getAllCompanyAbsencePoliciesAsSuperAdmin(companyId), { suspense: false });

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE}>
        <TopHeader
          title={
            <Typography variant="title2">
              Helper page - Absence | {company.name} [{companyId}]
            </Typography>
          }
          showBack
          backPath={generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })}
        />
        <ContentWrapper>
          <Box sx={{ display: 'flex', width: '100%', gap: '40px', mb: '20px' }}>
            <HelperAbsenceSettingsSection absenceSettings={absenceSettings} isLoadingSettings={!!isLoadingSettings} />

            <HelperAbsencePoliciesTable
              absencePolicies={companyPolicies ?? []}
              isLoadingPolicies={!!isLoadingPolicies}
              users={users}
            />
          </Box>
          <HelperAbsencePolicyBalance companyId={companyId} absencePolicies={companyPolicies ?? []} users={users} />

          <HelperAbsenceUsersCarryOverSection
            companyId={companyId}
            absencePolicies={companyPolicies ?? []}
            users={users}
          />
        </ContentWrapper>
      </Route>
    </Switch>
  );
};
