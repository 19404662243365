import { useContext, useState } from 'react';

import { Box, IconButton, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { ContractorInvoiceModal } from '@v2/feature/payments/pages/components/contractor-invoice-modal.component';
import { ContractorInvoice } from '@v2/feature/payments/payments.interface';
import { iconCTAButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as DownloadIcon } from '@/images/icons/download-icon.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { nestErrorMessage } from '@/lib/errors';

interface PaymentInvoicePreviewProps {
  readonly invoice: ContractorInvoice;
  readonly titleSx?: SxProps<Theme>;
}

export const PaymentInvoicePreview = ({ invoice, titleSx }: PaymentInvoicePreviewProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const currentUserIsAdmin = hasScopes(['invoices:all'], getScopesContext(user));

  const invoiceFileName = `Invoice_${invoice.fromUser.firstName}${invoice.fromUser.lastName}_${invoice.invoiceNumber}`;

  const handleDownloadClick = async (invoiceId: string) => {
    try {
      const PDFBlob = await ContractorInvoiceAPI.downloadInvoice({ invoiceId });

      const file = new Blob([PDFBlob], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      let link = document.createElement('a');
      link.download = `${invoiceFileName}.pdf`;
      link.href = fileURL;
      link.click();
    } catch (e) {
      console.error('::Download error', e);
      showMessage(`Failed to download contractor invoice. ${nestErrorMessage(e)}`, 'error');
    }
  };

  return (
    <Box>
      <Typography variant="title4" sx={titleSx}>
        Invoice
      </Typography>
      <Box sx={{ display: 'flex', gap: spacing.gap10, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DocumentIcon {...iconSize} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
          <Typography variant="title4">{invoiceFileName}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap5 }}>
          <IconButton sx={iconCTAButtonSx} onClick={() => handleDownloadClick(invoice.id)}>
            <DownloadIcon {...iconSize} />
          </IconButton>
          <IconButton sx={iconCTAButtonSx} onClick={() => setIsModalOpen(true)}>
            <Eye {...iconSize} />
            {isModalOpen && (
              <ContractorInvoiceModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                selectedInvoice={invoice}
                // This is used here only to view the details of the invoice - no need for the props from bellow to be set
                onActionPerformed={async () => {}}
                onClose={() => {
                  setIsModalOpen(false);
                }}
                currentUserIsAdmin={currentUserIsAdmin}
              />
            )}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
