import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { ATTENDANCE_ME_REQUESTS_ROUTE } from '@/lib/routes';
import { AttendanceMeView } from '@/v2/feature/attendance/me/pages/attendance-me-view.page';

export const AttendanceMeRouter = () => {
  return (
    <Switch>
      <RouteScopesHas scopes={['attendance']} path={ATTENDANCE_ME_REQUESTS_ROUTE} exact>
        <AttendanceMeView />
      </RouteScopesHas>

      <Redirect to={ATTENDANCE_ME_REQUESTS_ROUTE} />
    </Switch>
  );
};
