import { useCallback, useEffect, useState } from 'react';

import { IconButton, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as UploadedDocIcon } from '@/images/documents/UploadedDoc.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { getDocumentTypeLabel, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { AttachmentWithUrl } from '@/v2/feature/documents/documents.interface';
import { ApiError } from '@/v2/infrastructure/api-error/api-error.interface';
import { getApiErrorMessage } from '@/v2/infrastructure/api-error/api-error.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  document: ZeltDocument | null;
  documentTypes: ZeltDocumentType[];
  close: (fileUuid?: string) => void;
}

export const DocumentUploadDrawer = ({ close, document, documentTypes }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [documentUploading, setDocumentUploading] = useState(false);
  const [showMessage] = useMessage();
  const [fileNote, setFileNote] = useState('');
  const [uploadedFileUUID, setUploadedFileUUID] = useState<string>();
  const [currentFile, setCurrentFile] = useState<AttachmentWithUrl>();

  useEffect(() => {
    setFileNote(document?.note ?? '');
    setCurrentFile(document?.attachments?.[0]);
  }, [document]);

  const saveDocument = useCallback(
    async (fileUuid: string, note: string) => {
      if (!document || !document.belongsTo) return;
      try {
        setDocumentUploading(true);
        await DocumentAPI.updateDocument(document.id, {
          name: document.name,
          type: document.type,
          belongsTo: document.belongsTo.map((x) => ({
            value: x.User.userId,
          })),
          fileUuid,
          note,
        });
        close(fileUuid);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.documentUpdateFailed', {
            errorMessage: getApiErrorMessage(error as ApiError),
          }),
          'warning'
        );
      } finally {
        setDocumentUploading(false);
      }
    },
    [polyglot, close, document, showMessage]
  );

  const currentFileUUID = uploadedFileUUID || currentFile?.fileUuid;

  return (
    <SwipeableDrawer anchor="right" open={!!document} onClose={() => close()} onOpen={() => {}}>
      {document && (
        <Stack sx={{ p: '30px 40px', width: '440px', boxSizing: 'border-box', maxWidth: '100vw' }}>
          {!currentFile && (
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {polyglot.t('OnboardingByUser.addDocument')}
            </Typography>
          )}
          {currentFile && (
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {polyglot.t('OnboardingByUser.editDocument')}
            </Typography>
          )}
          <Typography
            sx={{
              ...themeFonts.caption,
              mt: spacing.mt20,
              color: themeColors.DarkGrey,
            }}
          >
            {polyglot.t('OnboardingByUser.name')}
          </Typography>
          <Typography sx={{ ...themeFonts.title4, mt: spacing.m5, color: themeColors.DarkGrey }}>
            {document.name}
          </Typography>
          <Typography
            sx={{
              ...themeFonts.caption,
              mt: spacing.mt20,
              color: themeColors.DarkGrey,
            }}
          >
            {polyglot.t('OnboardingByUser.type')}
          </Typography>
          <Typography sx={{ ...themeFonts.title4, mt: spacing.m5, color: themeColors.DarkGrey }}>
            {getDocumentTypeLabel(document, documentTypes)}
          </Typography>
          <TextfieldComponent
            label="Note"
            disabled={documentUploading}
            onChange={(e) => setFileNote(e.target.value)}
            value={fileNote}
            sx={{ mt: spacing.mt30 }}
          />
          {currentFile && (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', mt: spacing.mt40 }}>
              <UploadedDocIcon />
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                {currentFile.fileName.split('/').pop()}
              </Typography>
              <IconButton
                sx={{ ...tableIconButtonSx, ml: 'auto' }}
                onClick={() => setCurrentFile(undefined)}
                title={polyglot.t('General.remove')}
              >
                <TrashIcon />
              </IconButton>
            </Stack>
          )}
          {!currentFile && (
            <UploadInput
              onChange={(response: { uuid: string | undefined } | undefined) => {
                setUploadedFileUUID(response?.uuid);
              }}
              sx={{ mt: spacing.mt40 }}
            />
          )}
          {currentFileUUID && (
            <LoaderButton
              fullWidth
              name={polyglot.t('General.save')}
              loading={documentUploading}
              onClick={async () => {
                await saveDocument(currentFileUUID, fileNote);
              }}
              style={{ marginTop: spacing.m30 }}
              sizeVariant="large"
              colorVariant="primary"
            />
          )}
        </Stack>
      )}
    </SwipeableDrawer>
  );
};
