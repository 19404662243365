import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { GrowthChip, GrowthChipOptions } from '@v2/feature/growth/shared/components/growth-chip.component';
import { themeColors } from '@v2/styles/colors.styles';

interface QuestionCellProps {
  readonly text: string | ReactNode;
  readonly textVariant?: TypographyVariant;
  readonly textColor?: keyof typeof themeColors;
  readonly chips?: GrowthChipOptions[];
}

export const QuestionCell = ({ text, chips, textVariant = 'caption', textColor = 'DarkGrey' }: QuestionCellProps) => {
  return (
    <Box>
      {typeof text === 'string' ? (
        <Typography variant={textVariant} color={textColor}>
          {text}
        </Typography>
      ) : (
        text
      )}
      {chips && (
        <Box sx={{ display: 'flex', gap: '16px', mt: '8px' }}>
          {chips.map((chip, index) => (
            <GrowthChip key={index} options={chip} />
          ))}
        </Box>
      )}
    </Box>
  );
};
