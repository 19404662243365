import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import {
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ACCOUNTING_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_APPROVAL_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_MEMBERS_ROUTE,
  SETTINGS_MONEY_EXPENSES_ROUTE,
} from '@/lib/routes';
import { PaymentSettingsApprovalsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings-approvals.page';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { PaymentTypeAccountingSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-accounting-settings.page';
import { PaymentTypeGeneralSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-general-settings.page';
import { PaymentTypeMembersSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-members-settings.page';

export const getPageConfig = (id: number, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE, {
            id,
          }),
        },
        {
          title: polyglot.t('PaymentSettingsPage.accountingHeader'),
          stub: 'accounting',
          path: generatePath(SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ACCOUNTING_ROUTE, {
            id,
          }),
        },
        {
          title: polyglot.t('General.members'),
          stub: 'provider',
          path: generatePath(SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_MEMBERS_ROUTE, {
            id,
          }),
        },
        {
          title: polyglot.t('PaymentSettingsPage.approvalsTitle'),
          stub: 'approval',
          path: generatePath(SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_APPROVAL_ROUTE, {
            id,
          }),
        },
      ],
    },
  ] as NavConfigItem[];
};

interface ExpenseSettingDetailRouterProps {
  readonly id: number;
}

export const ExpenseSettingDetailRouter = ({ id }: ExpenseSettingDetailRouterProps) => {
  const { data: expenseSetting, mutate: refresh } = useApiClient(PaymentTypeSettingsEndpoints.getTypeSettingById(id), {
    suspense: false,
  });
  const { mutate: refreshAllSettings } = useApiClient(
    PaymentTypeSettingsEndpoints.getPaymentTypeSettingsForCompanyId(),
    { suspense: false }
  );

  const { polyglot } = usePolyglot();

  return (
    <>
      <DomainSideMenuContent
        title={expenseSetting?.name ?? polyglot.t('PaymentSettingsPage.expenseType')}
        subtitle={polyglot.t('PaymentSettingsPage.expenseType')}
        pageConfig={getPageConfig(id, polyglot)}
        backPath={SETTINGS_MONEY_EXPENSES_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper>
        {expenseSetting && (
          <Switch>
            <Route exact path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE}>
              {refreshAllSettings && (
                <PaymentTypeGeneralSettingsPage
                  paymentTypeSetting={expenseSetting}
                  refresh={refresh}
                  refreshAllPaymentSettings={refreshAllSettings}
                />
              )}
            </Route>

            <Route exact path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_MEMBERS_ROUTE}>
              <PaymentTypeMembersSettingsPage />
            </Route>

            <Route exact path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_APPROVAL_ROUTE}>
              <PaymentSettingsApprovalsPage paymentTypeSetting={expenseSetting} refreshPaymentTypeSetting={refresh} />
            </Route>

            <Route exact path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ACCOUNTING_ROUTE}>
              <PaymentTypeAccountingSettingsPage paymentTypeSetting={expenseSetting} refresh={refresh} />
            </Route>

            <Redirect to={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE} />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
};
