import { useCallback, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { CustomProfileFormAPI } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import { CustomProfileFormDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

interface DrawerProps {
  formTab: ProfileTab;
  form: CustomProfileFormDto | null;
  formNames: string[];
  close: (form: CustomProfileFormDto, change: 'created' | 'updated' | 'deleted') => Promise<void>;
}

export const CustomFormDrawer = ({ form, formNames, formTab, close }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [showMessage] = useMessage();

  const createSection = useCallback(
    async (formTab: ProfileTab, formName: string) => {
      setLoading(true);
      try {
        const newForm = await CustomProfileFormAPI.createForm({
          formTab,
          formName,
        });
        showMessage(`Section added successfully`, 'success');
        close(newForm, 'created');
      } catch (error) {
        showMessage(`Could not create section. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
    [close, showMessage]
  );

  const updateSection = useCallback(
    async (form: CustomProfileFormDto, formName: string) => {
      setLoading(true);
      try {
        const newForm = await CustomProfileFormAPI.updateForm(form.formId, {
          formName,
        });
        close(newForm, 'updated');
      } catch (error) {
        showMessage(`Could not update section. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
    [close, showMessage]
  );

  const deleteSection = useCallback(
    async (form: CustomProfileFormDto) => {
      setLoading(true);
      try {
        await CustomProfileFormAPI.deleteForm(form.formId);
        showMessage(`Section deleted`, 'success');
        close(form, 'deleted');
      } catch (error) {
        showMessage(`Could not delete section. ${nestErrorMessage(error)}`, 'error');
      }
      setLoading(false);
    },
    [close, showMessage]
  );

  const formik = useFormik({
    initialValues: {
      formName: form?.formName ?? '',
    },
    validationSchema: yup.object({
      formName: yup
        .string()
        .required('Section name is required')
        .test('isDuplicateFormName', 'Duplicate section name', (formName) => {
          const lowerFormName = formName?.toLocaleLowerCase();
          if (!formNames.find((n) => n.toLocaleLowerCase() === lowerFormName)) return true;
          return lowerFormName === form?.formName.toLocaleLowerCase();
        }),
    }),
    async onSubmit(values) {
      if (form) {
        await updateSection(form, values.formName);
      } else {
        await createSection(formTab, values.formName);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: spacing.g5 }}>
          <Typography variant="title2">{form ? `Edit ${form.formName}` : 'New section'}</Typography>
          {form && (
            <>
              <IconButton
                sx={iconButtonSx}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                title="Delete section"
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
              <NotificationModal
                isOpen={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                takeAction={async () => {
                  await deleteSection(form);
                  setAnchorEl(null);
                }}
                message="Are you sure you want to delete this section? All fields and user data linked to this section will be permanently deleted."
                callToAction="Yes"
              />
            </>
          )}
        </Box>

        <TextfieldComponent
          name="formName"
          value={formik.values.formName}
          label="Section name"
          onChange={formik.handleChange}
          disabled={loading}
          maxLength={50}
          error={!!formik.submitCount && !!formik.errors.formName}
          helperText={!!formik.submitCount && formik.errors.formName}
          autoFocus
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
