/* eslint-disable filenames/match-regex */
import { Box, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { LocalDate } from '@v2/util/local-date';
import moment from 'moment';

import { Discount } from '@/models/discount.model';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const formatAmount = (discount: Discount) => {
  return discount.discountType === 'fixed'
    ? `-${Number(discount.amountValue).toFixed(2)}`
    : `-${Number(discount.amountValue).toFixed(0)}%`;
};

const formatDate = (date: Date, format = 'DD/MM/YYYY') => moment(date).format(format);

export class SuperAdminBillingDiscountTableHelper {
  static getDiscountNameColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { description },
        },
      }) => (
        <div>
          {description ? (
            description
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountIdColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { id },
        },
      }) => (
        <div>
          {id ? (
            id
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountTypeColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { discountType },
        },
      }) => (
        <div>
          {discountType ? (
            discountType
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountAmountColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({ row: { original } }) => (
        <div>
          {original.amountValue ? (
            formatAmount(original)
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountStartDateColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
  }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { startDate },
        },
      }) => (
        <div>
          {startDate ? (
            formatDate(new LocalDate(startDate).getDate())
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountEndDateColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { endDate },
        },
      }) => (
        <div>
          {endDate ? (
            formatDate(new LocalDate(endDate).getDate())
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountCreatedAtColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
  }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { createdAt },
        },
      }) => (
        <div>
          {createdAt ? (
            formatDate(createdAt)
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountUpdatedAtColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
  }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { updatedAt },
        },
      }) => (
        <div>
          {updatedAt ? (
            formatDate(updatedAt)
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }

  static getDiscountUpdatedByColumn(columnDefinition: {
    header: string;
    id: string;
    size: number;
  }): ColumnDef<Discount> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { user },
        },
      }) => (
        <div>
          {user ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <UserAvatar userId={user.userId} size="xxsmall" sx={{ alignSelf: 'self-start' }} />
              <Typography
                sx={{
                  ...themeFonts.caption,
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.m5,
                }}
              >
                {`${user.firstName} ${user.lastName}`}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
              }}
            >
              —
            </Typography>
          )}
        </div>
      ),
      size,
    };
  }
}
