export interface BenefitsQuoteData {
  outPatientTreatment: boolean;
  therapies: boolean;
  mentalHealth: boolean;
  dentalCare: boolean;
  opticalCare: boolean;
  travelCover: boolean;
  additionalNotes: string | null;
  displayName: string;
  selectedUsers: number[];
}

export enum InsuranceQuoteStatus {
  Pending = 'Pending',
  PolicyAssigned = 'PolicyAssigned',
}

export enum InsuranceType {
  Health = 'Health',
}

export interface UpsertInsurancePolicy {
  providerName: InsuranceProvider;
  policyStartDate: string;
  policyEndDate: string;
  policyNumber: string;
  claimsPhoneNumber: string;
  supportPhoneNumber: string;
  insuranceBrokerPhoneNumber: string;
}

export interface UploadedInsuranceDocumentData {
  name: string;
  note?: string;
  fileUuid: string;
}

export enum UserInsuranceDependants {
  SpousePartner = 'SpousePartner',
  Children = 'Children',
  Family = 'Family',
}

export enum InsuranceProvider {
  Vitality = 'Vitality',
  AXA = 'AXA',
  BupaDental = 'BupaDental',
  BupaPMI = 'BupaPMI',
  Aviva = 'Aviva',
  Freedom = 'Freedom',
}

export enum UserInsurancePolicyStatus {
  Pending = 'Pending',
  Active = 'Active',
  OptOut = 'OptOut',
  PendingOptOut = 'PendingOptOut',
  None = 'null', // aux status - used in FE only
}
