import { useCallback, useEffect, useState } from 'react';

import { ReportConfigSection } from '@v2/feature/reports/reports-advanced/components/report-config.section';
import { ReportViewResult } from '@v2/feature/reports/reports-advanced/components/report-view-result.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportResponse, SelectedColumnsRequest, SelectedFiltersRequest } from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { deepCopy } from '@v2/infrastructure/object/object.util';
import { RootStyle } from '@v2/styles/root.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface PageProps {
  readonly refreshReportsList: () => Promise<void>;
}

export const ReportsAdvancedCreatePage = ({ refreshReportsList }: PageProps) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getTestReportsFiltersOptions(), {
    suspense: false,
  });

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumnsRequest>({});
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersRequest>({});

  const [reportResponse, setReportResponse] = useState<ReportResponse | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (!filtersAndColumns) return;

    setSelectedColumns(deepCopy(filtersAndColumns.defaultSelectedColumns ?? {}) as SelectedColumnsRequest);
    setSelectedFilters(deepCopy(filtersAndColumns.defaultSelectedFilters ?? {}) as SelectedFiltersRequest);
  }, [filtersAndColumns]);

  const generateReport = useCallback(async () => {
    if (Object.keys(selectedColumns).length === 0 && Object.keys(selectedFilters).length === 0) {
      setReportResponse(null);
      return;
    }
    try {
      setIsGenerating(true);
      const response = await ReportsAPI.generateReport(selectedColumns, selectedFilters);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(null);
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsGenerating(false);
  }, [selectedColumns, selectedFilters, polyglot, showMessage]);

  useEffect(() => {
    // generate report only if there are columns selected
    if (Object.keys(selectedColumns).length > 0) generateReport();
    else setReportResponse(null);
  }, [selectedColumns, generateReport]);

  return (
    <RootStyle style={{ display: 'flex', flexDirection: 'row' }}>
      <ReportConfigSection
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        reportColumns={filtersAndColumns?.columns ?? []}
        reportFilters={filtersAndColumns?.filters ?? []}
      />
      <ReportViewResult
        report={null}
        reportResponse={reportResponse}
        isLoading={!!isLoading || isGenerating}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        refreshReportsList={refreshReportsList}
        refreshReport={generateReport}
      />
    </RootStyle>
  );
};
