import he from 'he';

export const isValidEmailString = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return new RegExp(emailRegex).test(email);
};

// case insensitive equality check
export const equalsIgnoreCase = (str1: string, str2: string): boolean => {
  if (!str1 || !str2) {
    return false;
  }
  return str1.toLocaleLowerCase() === str2.toLocaleLowerCase();
};

export const isValidCommaSeparatedEmail = (commaSeparatedString: string): boolean => {
  const emails = commaSeparatedString.split(',').map((s) => s.trim());
  return emails.every((email) => isValidEmailString(email));
};

export const toTitleCase = (inputText: string): string => {
  if (!inputText) return '';
  return inputText?.replace(/\b([a-z])/g, (_, initial) => initial?.toUpperCase());
};

export const pluralText = (count: number): string => {
  return count === 1 ? '' : 's';
};

export const truncateWithEllipses = (inputText: string, limit = 12): string => {
  return inputText.length > limit ? `${inputText.substring(0, limit)}...` : inputText;
};

export const arrayBufferToString = (buffer: ArrayBuffer, encoding = 'UTF-8'): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const blob = new Blob([buffer], { type: 'text/plain' });
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (evt.target) {
        resolve(evt.target.result as string);
      } else {
        reject(new Error('Could not convert array to string!'));
      }
    };
    reader.readAsText(blob, encoding);
  });
};

export const camelCaseToTitleCase = (input: string): string => {
  const interim = input.replace(/([A-Z])/g, ' $1');
  return interim.charAt(0).toUpperCase() + interim.slice(1);
};

export const getFirstAndLastNameFromNameData = (nameData: string) => {
  // Remove extra spaces and split the name data into an array
  const nameArray = nameData.trim().split(/\s+/);

  // Extract the first and last names
  const firstName = nameArray[0];
  const lastName = nameArray[nameArray.length - 1];
  return [firstName, lastName];
};

export const isDefined = (input: string | undefined) => {
  return input && input?.length > 0;
};

export const stripHtml = (html: string | undefined) => {
  if (!html) return '';
  // Strip tags
  const strippedString = html.replace(/<[^>]*>/g, '');
  // Decode HTML entities
  return he.decode(strippedString);
};

export const replaceParamsInQuestionText = (input: string, params: Record<string, string>): string => {
  if (!input) return '';
  const regex = /{(\w+)}/g;
  return input.replace(regex, (_, key) => params[key] || `{${key}}`);
};

export function urlify(text: string): string {
  const urlRegex = /(((https?:\/\/)|(www\.))\S+)/g;
  //var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replaceAll(urlRegex, function (url, b, c) {
    const url2 = c === 'www.' ? 'http://' + url : url;
    return '<a href="' + url2 + '" target="_blank" style="color: #2F2F2F;">' + url + '</a>';
  });
}
