import React from 'react';

import { Box } from '@mui/material';
import { IconButton } from '@v2/components/forms/icon-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Back } from '@/images/fields/Left.svg';

interface ReportConfigBackButtonProps {
  readonly title: string;
  readonly goBack: () => void;
}

export const ReportConfigBackButton = ({ title, goBack }: ReportConfigBackButtonProps) => {
  return (
    <Box sx={{ display: 'flex', gap: spacing.s1, alignItems: 'center', mt: spacing.s2 }}>
      <IconButton sizeVariant="small" colorVariant="secondary" onClick={goBack}>
        <Back {...iconSize} />
      </IconButton>
      <Typography variant="title2">{title}</Typography>
    </Box>
  );
};
