import React from 'react';

import { Box, SwipeableDrawer } from '@mui/material';
import { UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';

import { ViewUserPayrollPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/view-user-payroll.page';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';

interface Props {
  readonly isOpen: boolean;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly close: (options?: { switchToEdit: boolean }) => void;
  readonly record: UserPayrollForTableDto | null;
  readonly canEdit: boolean;
  readonly addToPayroll?: (record: UserPayrollForTableDto) => Promise<boolean>;
  readonly removeFromPayroll?: (record: UserPayrollForTableDto) => Promise<boolean>;
  readonly isUserUpdating: boolean;
}

export const ViewUserPayrollDrawer = ({
  isOpen,
  onOpen = () => {},
  onClose = () => {},
  close,
  addToPayroll,
  canEdit,
  isUserUpdating,
  record,
  removeFromPayroll,
}: Props): JSX.Element => {
  const { getCachedUserById } = useCachedUsers();
  const user = record && getCachedUserById(record.userId);
  return (
    <SwipeableDrawer anchor="right" open={isOpen} onClose={onClose} onOpen={onOpen}>
      {record && user && (
        <Box sx={{ p: '30px 40px', width: 440, maxWidth: '100vw', boxSizing: 'border-box' }}>
          <ViewUserPayrollPage
            user={user}
            record={record}
            canEdit={canEdit}
            addToPayroll={addToPayroll}
            removeFromPayroll={removeFromPayroll}
            isUserUpdating={isUserUpdating}
            close={close}
          />
        </Box>
      )}
    </SwipeableDrawer>
  );
};
