export const AppScopes = {
  CREATE_APP_USER: ['apps'],
  CREATE_APP_INTEGRATION: ['apps'],
  DELETE_APP_INTEGRATION: ['apps'],
  VIEW_APP_STORE: ['apps'],
  VIEW_COMPANY_APPS: ['apps:all'],
  VIEW_APP_INTEGRATION: ['apps'],
  VIEW_APPS_OVERVIEW: ['apps'],
  VIEW_APP_REQUESTS: ['apps:all'],
  VIEW_USER_APPS: ['apps:manager'],
  VIEW_MY_APPS: ['apps'],
  ADD_TO_ONBOARDING_TEMPLATE: ['apps:all'],
} as const;
