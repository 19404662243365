import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmWifiPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-wifi-policy-editor.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  WifiOptions,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmWifiPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const getPolicy = (): WifiOptions => {
    return {
      AutoJoin: configurablePoliciesSelected?.configurableWifi?.AutoJoin ?? false,
      EncryptionType: configurablePoliciesSelected?.configurableWifi?.EncryptionType ?? 'None',
      HIDDEN_NETWORK: configurablePoliciesSelected?.configurableWifi?.HIDDEN_NETWORK ?? false,
      SSID_STR: configurablePoliciesSelected?.configurableWifi?.SSID_STR ?? '',
      Password: configurablePoliciesSelected?.configurableWifi?.Password ?? '',
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.WIFI_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: WifiOptions = getPolicy();

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">WiFi</Typography>
          <IconButton
            onClick={() => {
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Network name - SSID
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(initialValues.SSID_STR === ''
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.SSID_STR && initialValues.SSID_STR !== '' ? initialValues.SSID_STR : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Automatically join the network
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.AutoJoin
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.AutoJoin ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Security version
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(initialValues.EncryptionType === 'None'
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.EncryptionType !== 'None' ? initialValues.EncryptionType : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Password
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(initialValues.Password === ''
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.Password !== '' ? 'Enabled' : 'Not set'}
          </Typography>
        </Box>
      </Box>

      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmWifiPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
