import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { TimePickerComponent } from '@v2/components/forms/time-picker.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceImportDto } from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import { getAttendanceImportValidationSchema } from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.util';
import { CachedUser } from '@v2/feature/user/context/cached-users.context';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { isValidTimeString } from '@v2/infrastructure/date/date-format.util';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';

interface EditAttendanceEntryDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly attendance: AttendanceImportDto;
  readonly attendanceTypes: readonly AttendanceTypeDto[];
  readonly allUsers: readonly CachedUser[];
  readonly importHandler: (values: AttendanceImportDto) => void;
}

export const EditAttendanceEntryDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  attendance,
  attendanceTypes,
  allUsers,
  importHandler,
}: EditAttendanceEntryDrawerProps): JSX.Element => {
  const formik = useFormik<AttendanceImportDto>({
    initialValues: {
      id: attendance.id,
      userId: attendance.workEmail
        ? allUsers.find((u) => u.emailAddress === attendance.workEmail)?.userId ?? undefined
        : undefined,
      firstName: attendance.firstName ?? '',
      lastName: attendance.lastName ?? '',
      workEmail: attendance.workEmail ?? '',
      attendanceType: attendance.attendanceType ?? '',
      logDate: attendance.logDate ?? '',
      start: attendance.start ?? '',
      end: attendance.end ?? '',
    },
    validationSchema: getAttendanceImportValidationSchema(attendanceTypes),
    onSubmit: async (values: AttendanceImportDto) => {
      importHandler(values);
      onClose();
    },
  });

  const attendanceTypeOptions = useMemo(() => {
    return attendanceTypes.map((o) => ({ label: o.name, value: o.name }));
  }, [attendanceTypes]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Typography sx={titleSx}>Edit entry</Typography>
          <Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="firstName"
                label="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && !!formik.errors.firstName}
                helperText={(formik.touched.firstName && formik.errors.firstName) ?? ' '}
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="lastName"
                label="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && !!formik.errors.lastName}
                helperText={(formik.touched.lastName && formik.errors.lastName) ?? ' '}
              />
            </Box>
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="workEmail"
                label="Work email"
                value={formik.values.workEmail}
                onChange={(e) => {
                  formik.handleChange(e);
                  const value = e.target.value;
                  const user = allUsers.find((u) => u.emailAddress === value);
                  formik.setFieldValue('userId', user ? user.userId : '');
                }}
                error={
                  (formik.touched.workEmail && !!formik.errors.workEmail) ||
                  (formik.touched.userId && !!formik.errors.userId)
                }
                helperText={
                  ((formik.touched.workEmail && formik.errors.workEmail) ||
                    (formik.touched.userId && formik.errors.userId)) ??
                  ' '
                }
              />
            </Box>

            <Box sx={fieldSx}>
              <SelectComponent
                name="attendanceType"
                label="Attendance type"
                options={attendanceTypeOptions}
                value={formik.values.attendanceType}
                compareValue={formik.values.attendanceType}
                onChange={formik.handleChange}
                error={!!formik.errors.attendanceType && formik.touched.attendanceType}
                helperText={(formik.touched.attendanceType && formik.errors.attendanceType) as string}
              />
            </Box>

            <Box sx={fieldSx}>
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                value={formik.values.logDate}
                onChange={(value) => {
                  formik.setFieldValue('logDate', value);
                }}
                name="logDate"
                label="Date"
                error={formik.touched.logDate && !!formik.errors.logDate}
                helperText={(formik.touched.logDate && formik.errors.logDate) ?? ' '}
              />
            </Box>

            <Box sx={{ ...fieldSx, display: 'flex', justifyContent: 'space-between', gap: spacing.g15 }}>
              <TimePickerComponent
                label="Start hour"
                textFieldKey="start"
                value={formik.values.start}
                onChange={(event) => {
                  const time = event.target.value;
                  if (isValidTimeString(time)) {
                    formik.setFieldValue('start', time);
                  }
                }}
                error={formik.touched.start && !!formik.errors.start}
                helperText={(formik.touched.start && formik.errors.start) as string}
                fullWidth
              />

              <TimePickerComponent
                label="End hour"
                textFieldKey="end"
                value={formik.values.end}
                onChange={(event) => {
                  const time = event.target.value;
                  if (isValidTimeString(time)) {
                    formik.setFieldValue('end', time);
                  }
                }}
                error={formik.touched.end && !!formik.errors.end}
                helperText={(formik.touched.end && formik.errors.end) as string}
                fullWidth
              />
            </Box>

            <Box sx={buttonBoxSx}>
              <ButtonComponent type="submit" sizeVariant="medium" colorVariant="primary" fullWidth>
                Update
              </ButtonComponent>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
