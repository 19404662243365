export type PayrunProcessStepState = 'success' | 'pending' | 'failure' | 'warning';

export enum PayrunProcessStepStates {
  success = 'success',
  pending = 'pending',
  failure = 'failure',
  warning = 'warning',
}

export type DownloadPayslipsState = DownloadPayslipsStates.downloaded | DownloadPayslipsStates.notDownloaded;

export enum DownloadPayslipsStates {
  notDownloaded = 'notDownloaded',
  downloaded = 'downloaded',
}

export type HMRCSubmissionState =
  | HMRCSubmissionStates.submitted
  | HMRCSubmissionStates.notSubmitted
  | HMRCSubmissionStates.markedAsSent;

export enum HMRCSubmissionStates {
  notSubmitted = 'notSubmitted',
  submitted = 'submitted',
  markedAsSent = 'markedAsSent',
}

export type PensionContributionState =
  | PensionContributionStates.submitted
  | PensionContributionStates.notSubmitted
  | PensionContributionStates.markedAsSent;

export enum PensionContributionStates {
  notSubmitted = 'notSubmitted',
  submitted = 'submitted',
  markedAsSent = 'markedAsSent',
}

export interface PayrunPayslipsContent {
  [key: string]: string;
}

export interface OperationError {
  code?: number;
  title: string;
  detail: string;
}

export interface PensionSubmissionErrorDetails {
  cached_name: string;
  operation_errors: OperationError[];
  id?: number;
  import_id?: number;
  cached_external_id?: string;
  operation?: string;
  resource_id?: number | null;
  resource_type?: number | null;
  state?: string;
  created_at?: string;
  updated_at?: string;
}

export enum PensionContributionStatuses {
  Queued = 'Queued',
  Processing = 'Processing',
  Sent = 'Sent',
  Failed = 'Failed',
  Unknown = 'Unknown',
}

export type AccountingJournalState =
  | AccountingJournalStates.notSubmitted
  | AccountingJournalStates.markedAsSent
  | AccountingJournalStates.submitted;

export enum AccountingJournalStates {
  notSubmitted = 'notSubmitted',
  markedAsSent = 'markedAsSent',
  submitted = 'submitted',
}

export type PayrunPaymentsState = PayrunPaymentsStates.notCreated | PayrunPaymentsStates.created;

export enum PayrunPaymentsStates {
  created = 'created',
  notCreated = 'notCreated',
}

export interface BankPaymentInstructionDto {
  type: string;
  content?: string;
  model?: {
    originator: {
      bankName?: string;
      bankBranch?: string;
      bankReference?: string;
      accountName?: string;
      accountNumber?: string;
      sortCode?: string;
      note: string;
    };
    bankPayments: [
      {
        type: string;
        payee: {
          id: string;
          name: string;
          metadata?: Record<string, any>;
          url?: string;
        };
        date: string;
        method: string;
        amount: number;
        reference: string;
        bankDetails: {
          bankName: string;
          bankBranch: string;
          bankReference: string;
          accountName: string;
          accountNumber: string;
          sortCode: string;
          note: string;
        };
        bacsHash: string;
        bacsSubReference: string;
      }
    ];
    status: string;
    statusMessage: string;
    link: string;
  };
}
