import { useMemo } from 'react';

import { SxProps, Theme } from '@mui/material';

import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';

const iconSize = { width: 14, height: 14 } as const;
type PayLineTypeMenuProps = {
  disabled?: boolean;
  payCodes: GlobalPaycode[];
  onMenuItemClick: (paycode: GlobalPaycode) => void;
  sx?: SxProps<Theme>;
};

export const GlobalPayLineTypeMenu = ({ disabled, payCodes, onMenuItemClick, sx }: PayLineTypeMenuProps) => {
  const payrunMenuOptions = useMemo(() => {
    return payCodes
      .filter((paycode) => !!paycode.credit && !!paycode.debit)
      .sort((a, b) => a.code.localeCompare(b.code))
      .map<OptionProps>((item) => ({
        handler: () => onMenuItemClick(item),
        label: item.code,
      }));
  }, [payCodes, onMenuItemClick]);

  return (
    <StyledMenuComponent
      options={payrunMenuOptions}
      actionButtonDetails={{
        type: 'button',
        colorVariant: 'secondary',
        sizeVariant: 'small',
        title: 'Add pay item',
        icon: <Plus {...iconSize} />,
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      disabled={disabled || payrunMenuOptions.length === 0}
      sx={sx}
    />
  );
};
