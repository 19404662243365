import { useCallback, useEffect } from 'react';

import { AttendanceReportSection } from '@v2/feature/reports/features/update-report/attendance/attendance-report-section.component';
import { DevicesReportSection } from '@v2/feature/reports/features/update-report/devices/devices-report-section.component';
import { PeopleReportSection } from '@v2/feature/reports/features/update-report/people/people-report-section.component';
import { TimeReportSection } from '@v2/feature/reports/features/update-report/time/time-report-section.component';
import { DataDomain } from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { useParams } from 'react-router-dom';

import { ReportsEndpoints } from '@/v2/feature/reports/reports.api';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export const UpdateReportPage = () => {
  const params = useParams<{ readonly reportId: string }>();
  const reportId = Number(params.reportId);
  const { trackPage } = useJune();

  const { data: report, isLoading: loading, mutate: refreshReportM } = useApiClient(
    !Number.isNaN(reportId) ? ReportsEndpoints.getReportById(reportId) : null,
    { suspense: false }
  );

  const refreshReport = useCallback(async () => {
    if (refreshReportM) await refreshReportM();
  }, [refreshReportM]);

  useEffect(() => {
    trackPage('Edit report');
  }, [trackPage]);

  switch (report?.type) {
    case DataDomain.People:
      return <PeopleReportSection report={report} loading={!!loading} refreshReport={refreshReport} />;
    case DataDomain.TimeAway:
      return <TimeReportSection report={report} loading={!!loading} />;
    case DataDomain.Attendance:
      return <AttendanceReportSection report={report} loading={!!loading} />;
    case DataDomain.Devices:
      return <DevicesReportSection report={report} loading={!!loading} />;
    default:
      return null;
  }
};
