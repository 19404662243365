import { ReactNode } from 'react';
import '@v2/feature/app-layout/features/main-content/layouts/components/styles/content-2-columns.scss';

import { borders } from '@v2/styles/borders.styles';

export const Content2Columns = ({
  column1,
  column2,
  borderLeft,
  gap = 'gap20',
}: {
  column1: ReactNode;
  column2: ReactNode;
  borderLeft?: boolean;
  gap?: 'gap40' | 'gap20';
}) => {
  return (
    <div className={`content-2-columns ${gap}`}>
      <div style={{ minWidth: '100%' }}>{column1}</div>
      <div style={{ borderLeft: borderLeft ? borders.background : undefined, minWidth: '100%' }}>{column2}</div>
    </div>
  );
};
