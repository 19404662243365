import { useCallback } from 'react';

import { DepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto';
import { InHouseEndpoints } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';

import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const useDepAccountDetails = (
  mdmServerName: string
): {
  depAccountDetails: DepAccountDetails | undefined;
  refreshDepAccountDetails: () => Promise<void>;
  isLoading: boolean;
} => {
  const { data, mutate, isLoading } = useApiClient(InHouseEndpoints.getDepAccountDetails(mdmServerName), {
    suspense: false,
  });

  const refreshDepAccountDetails = useCallback(async () => {
    if (mutate) await mutate();
  }, [mutate]);

  return {
    depAccountDetails: data ?? undefined,
    refreshDepAccountDetails,
    isLoading: Boolean(isLoading),
  };
};
