import React, { useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReviewQuestionBankEndpoints } from '@/v2/feature/growth/reviews/api-client/review-question-bank.api';
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips,
} from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReviewQuestionBankModal } from '@/v2/feature/growth/reviews/features/review-question-bank/components/review-question-bank-modal.component';
import { ReviewQuestionBankViewModal } from '@/v2/feature/growth/reviews/features/review-question-bank/components/review-question-bank-view-modal.component';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewQuestionBank } from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { QuestionType, QuestionTypeMap } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { stripHtml } from '@/v2/util/string.util';

export const ReviewQuestionBankPage = () => {
  const [question, setQuestion] = useState<ReviewQuestionBank | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  const {
    data: allQuestions,
    mutate: refreshQuestions,
    isLoading: questionsLoading,
  } = useApiClient(ReviewQuestionBankEndpoints.getReviewQuestionFromBank(searchInput), { suspense: false });

  const tableColumns = useMemo<ColumnDef<ReviewQuestionBank, ReviewQuestionBank>[]>(
    () => [
      {
        header: () => 'Question text',
        accessorFn: (row) => row,
        id: 'questionMain',
        enableSorting: false,

        cell: ({
          row: {
            original: { questionMain, questionSelf },
          },
        }) =>
          questionMain ? (
            <div>{stripHtml(questionMain)} </div>
          ) : questionSelf ? (
            <div>{stripHtml(questionSelf)} </div>
          ) : (
            <EmptyCell />
          ),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,

        cell: ({
          row: {
            original: { type },
          },
        }) => (type ? <div> {QuestionTypeMap[type as QuestionType]} </div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Factors',
        accessorFn: (row) => row,
        id: 'factor',
        enableSorting: false,

        cell: ({
          row: {
            original: { factor },
          },
        }) => (factor ? <div>{factor} </div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Reviewer select',
        accessorFn: (row) => row,
        id: 'reviewerSelect',
        enableSorting: false,

        cell: ({
          row: {
            original: { reviewerSelect, visibilitySettings },
          },
        }) =>
          reviewerSelect ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g4 }}>
              {reviewerSelect
                .sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b])
                .map((r) => {
                  const hideVisibility =
                    r !== ReviewerTypes.Self &&
                    ((r === ReviewerTypes.Manager && visibilitySettings.hideManagerResult) ||
                      (r === ReviewerTypes.Upward && visibilitySettings.hideUpwardResult) ||
                      (r === ReviewerTypes.Peer && visibilitySettings.hidePeerResult));

                  return getReviewerTypesChips(hideVisibility)[r];
                })}
            </div>
          ) : (
            <EmptyCell />
          ),
        minSize: 120,
        maxSize: 250,
      },
      {
        header: () => 'Created by',
        accessorFn: (row) => row,
        id: 'createdby',
        enableSorting: false,

        cell: ({
          row: {
            original: { createdBy, companyId },
          },
        }) => (
          <div>
            {companyId === null ? (
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" />
                Zelt
              </Stack>
            ) : createdBy ? (
              <UserCell userId={createdBy} />
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
        minSize: 90,
        maxSize: 120,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.companyId && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                colorVariant="light"
                sizeVariant="small"
                onClick={() => {
                  setQuestion(original);
                  setIsEditOpen(true);
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            </Box>
          ),
        minSize: 50,
        maxSize: 100,
      },
    ],
    []
  );

  const handleRowClick = useCallback((row: Row<ReviewQuestionBank>) => {
    setIsViewOpen(true);
    setQuestion(row.original);
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Questions</Typography>}
        showAction={true}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsEditOpen(true)}>
            New question
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={false}>
        <Box sx={{ mb: spacing.m16 }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
        <BasicTable
          rowData={allQuestions ?? []}
          columnData={tableColumns}
          loading={questionsLoading}
          rowClick={handleRowClick}
        />

        <ReviewQuestionBankViewModal
          setIsOpen={setIsViewOpen}
          isOpen={isViewOpen}
          setIsEditOpen={setIsEditOpen}
          question={question}
          onClose={() => {
            setIsViewOpen(false);
            setQuestion(null);
          }}
        />
        <ReviewQuestionBankModal
          setIsOpen={setIsEditOpen}
          isOpen={isEditOpen}
          question={question}
          onClose={() => {
            setIsViewOpen(false);
            setIsEditOpen(false);
            setTimeout(() => {
              setQuestion(null);
            }, 500);
          }}
          refresh={async () => {
            await refreshQuestions?.();
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
