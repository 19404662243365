import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { ATTENDANCE_TEAM_REQUESTS_ROUTE } from '@/lib/routes';
import { AttendanceTeamView } from '@/v2/feature/attendance/team/pages/attendance-team-requests/attendance-team-view.page';

export const AttendanceTeamRouter = ({ isManager }: { readonly isManager: boolean }) => {
  return (
    <Switch>
      {isManager && (
        <RouteScopesHas scopes={['attendance:manager']} path={ATTENDANCE_TEAM_REQUESTS_ROUTE}>
          <AttendanceTeamView view="team" />
        </RouteScopesHas>
      )}

      <Redirect to={ATTENDANCE_TEAM_REQUESTS_ROUTE} />
    </Switch>
  );
};
