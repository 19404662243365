import { Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { Box, IconButton } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/lib/Option';
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray';
import { IFileWithMeta } from 'react-dropzone-uploader';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as UploadDoc } from '@/images/documents/UploadedDoc.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as CheckCircle } from '@/images/side-bar-icons/CheckCircle.svg';
import { ReactComponent as ErrorIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { ZeltDocument, ZeltDocumentType, getDocumentTypeLabel } from '@/lib/documents';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { IconTextCell } from '@/v2/components/table/icon-text-cell.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { MultiUserAvatars } from '@/v2/feature/documents/components/document-table.component';
import { BulkUploadResult, DocumentUploadStatus } from '@/v2/feature/documents/documents.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableSecondaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { toTitleCase } from '@/v2/util/string.util';

interface DocumentBulkUploadResultTableProps {
  documents: ZeltDocument[];
  documentTypes: readonly ZeltDocumentType[];
  onEditClick: (document: ZeltDocument) => void;
  onDelete: (file?: IFileWithMeta) => void;
  uploadResult: BulkUploadResult;
  readonly setSelectionModel: Dispatch<SetStateAction<string[]>>;
  readonly selectionModel: string[];
}
const HIDE_ACTION_STATUSES = [DocumentUploadStatus.UPLOADED, DocumentUploadStatus.PROCESSING];

const customRowStyle = (row: Row<ZeltDocument>) => {
  const hasFailed = row.original?.dzProps?.state === DocumentUploadStatus.FAILED;
  if (hasFailed) return { color: themeColors.RedDark, backgroundColor: themeColors.RedSuperLight };
  return {};
};

export const DocumentBulkUploadResultTable = ({
  documents,
  documentTypes,
  onEditClick,
  onDelete,
  uploadResult,
  setSelectionModel,
  selectionModel,
}: DocumentBulkUploadResultTableProps): JSX.Element => {
  const [state] = useContext(GlobalContext);

  const mapZeltDocumentToDZFile = (document: ZeltDocument): IFileWithMeta | undefined => {
    if (!document.dzProps) return;
    if (document?.dzProps?.meta && document?.dzProps?.file && document.dzProps.remove)
      return {
        meta: document.dzProps.meta,
        file: document.dzProps.file,
        cancel: () => {},
        restart: () => {},
        remove: document.dzProps.remove,
      };
  };

  const columnData = useMemo<ColumnDef<ZeltDocument, ZeltDocument>[]>(() => {
    const errorForDocument = (row: ZeltDocument) => {
      return uploadResult?.errorDetails?.find((error) => error.documentId === row.fileUuid)?.errorMessage ?? '';
    };
    const belongsToColumnLogic = (row: ZeltDocument) => {
      return pipe(
        row.belongsTo ?? [],
        RNEA.fromReadonlyArray,
        O.fold(
          () => (row.canAllEmployeesSee ? <span>Everyone</span> : <span>Unassigned</span>),
          (users) =>
            users.length === 1 ? (
              <UserCell userId={users[0].User.userId} />
            ) : (
              <MultiUserAvatars currentUser={state.user} users={users} size="xxsmall" />
            )
        )
      );
    };
    return [
      {
        id: 'select',
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        header: () => {
          const notUploadedDocs = documents.filter(
            (document) => document?.dzProps?.state !== DocumentUploadStatus.UPLOADED
          );
          const allSelected = selectionModel.length > 0 && selectionModel.length === notUploadedDocs.length;
          return (
            <Box onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                label={undefined}
                name="allSelected"
                checked={allSelected}
                value="allSelected"
                onChange={() => {
                  if (!allSelected) {
                    const selectedValues = notUploadedDocs.map((document) => document?.dzProps?.meta?.id);
                    setSelectionModel(selectedValues as string[]);
                  } else setSelectionModel([]);
                }}
              />
            </Box>
          );
        },
        cell: ({ row: { original } }) => {
          const isChecked = original?.dzProps?.meta?.id
            ? selectionModel.includes(original.dzProps.meta.id.toString())
            : false;
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
              <Box onClick={(e) => e.stopPropagation()}>
                {original?.dzProps?.state !== DocumentUploadStatus.UPLOADED && (
                  <CheckboxComponent
                    label={undefined}
                    name={original.name ?? ''}
                    checked={isChecked}
                    value={original?.dzProps?.meta?.id.toString() ?? ''}
                    onChange={() => {
                      const documentId = original?.dzProps?.meta?.id;
                      const finalArray =
                        documentId && isChecked
                          ? selectionModel.filter((sm) => sm !== documentId)
                          : [...selectionModel, documentId];
                      setSelectionModel(finalArray as string[]);
                    }}
                  />
                )}
              </Box>
            </Box>
          );
        },
      },
      {
        header: () => 'Document name',
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        maxSize: 350,
        minSize: 300,
        cell: (info) => {
          const rowValue: ZeltDocument = info.getValue();
          return (
            <IconTextCell
              icon={<UploadDoc width={24} height={30} />}
              text={rowValue.name}
              textSx={themeFonts.caption}
            />
          );
        },
      },
      {
        header: () => 'Type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,
        maxSize: 120,
        minSize: 100,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          return <div>{getDocumentTypeLabel(info.getValue(), documentTypes)}</div>;
        },
      },
      {
        header: () => 'Belongs To',
        accessorFn: (row) => row,
        id: 'avatar',
        maxSize: 200,
        minSize: 150,
        enableSorting: false,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const row: ZeltDocument = info.getValue();
          return row.belongsTo ? <>{belongsToColumnLogic(row)}</> : <></>;
        },
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'documentStatus',
        maxSize: 110,
        minSize: 110,
        enableSorting: false,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const row: ZeltDocument = info.getValue();
          return (
            <>
              {row.dzProps?.state === DocumentUploadStatus.FAILED ? (
                <>
                  <ErrorIcon {...iconSize} />
                  &nbsp;Failed - {errorForDocument(row)}
                </>
              ) : row.dzProps?.state === DocumentUploadStatus.PROCESSING ? (
                <>Processing</>
              ) : row.dzProps?.state &&
                ![
                  DocumentUploadStatus.PROCESSING,
                  DocumentUploadStatus.FAILED,
                  DocumentUploadStatus.UPLOADED,
                  DocumentUploadStatus.MAPPED,
                ].includes(row?.dzProps.state) ? (
                toTitleCase(row.dzProps.state)
              ) : row?.dzProps?.state === DocumentUploadStatus.UPLOADED ? (
                <>
                  <CheckCircle {...iconSize} style={{ fill: themeColors.Green }} />
                  &nbsp;
                  {toTitleCase(row.dzProps.state)}
                </>
              ) : row?.dzProps?.state === DocumentUploadStatus.MAPPED ? (
                'Pending'
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        maxSize: 120,
        minSize: 100,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                alignItems: 'center',
                gap: spacing.g10,
              }}
            >
              {info?.getValue()?.dzProps?.state &&
                !HIDE_ACTION_STATUSES.includes(info.getValue()?.dzProps?.state as DocumentUploadStatus) && (
                  <IconButton
                    sx={tableSecondaryIconButtonSx}
                    onClick={(e) => {
                      onDelete(mapZeltDocumentToDZFile(info.getValue()));
                      e.stopPropagation();
                    }}
                  >
                    <Trash {...iconSize} />
                  </IconButton>
                )}

              {info?.getValue()?.dzProps?.state &&
                !HIDE_ACTION_STATUSES.includes(info.getValue()?.dzProps?.state as DocumentUploadStatus) && (
                  <IconButton
                    sx={tableSecondaryIconButtonSx}
                    onClick={(e) => {
                      onEditClick(info.getValue());
                      e.stopPropagation();
                    }}
                  >
                    <Edit {...iconSize} />
                  </IconButton>
                )}
            </Box>
          );
        },
      },
    ];
  }, [
    documentTypes,
    documents,
    onDelete,
    onEditClick,
    selectionModel,
    setSelectionModel,
    state.user,
    uploadResult?.errorDetails,
  ]);
  return (
    <>
      <BasicTable rowData={[...documents]} columnData={columnData} loading={false} customRowStyle={customRowStyle} />
    </>
  );
};
