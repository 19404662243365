import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';

import { ContractAPI } from '@/api-client/contract.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Success } from '@/images/side-bar-icons/ok-green.svg';
import { SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE } from '@/lib/routes';
import { OptionObject } from '@/v2/components/forms/select.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { MissingCompensation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-compensation.component';
import { MissingContract } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-contract.component';
import { MissingEquity } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-equity.component';
import { MissingRole } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-role.component';
import { MissingAddressFormForPersonalContractSigning } from '@/v2/feature/templates/components/missing-address-form-contract-signing.component';
import { MissingPersonalFormForPersonalContractSigning } from '@/v2/feature/templates/components/missing-personal-form-contract-signing.component';
import {
  CONTRACT_FIELDS_CATEGORIES,
  ContractTemplateFieldValues,
  defaultMissingFieldValues,
  FIELD_DISPLAY_NAME,
  PERSONAL_TYPE_FIELDS,
  RequiredContractFields,
  WORK_TYPE_FIELDS,
} from '@/v2/feature/templates/templates.interface';
import { getTemplateSchema } from '@/v2/feature/templates/validations/missing-field-form-validations';
import { AddressValues } from '@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component';
import { PersonalInformationValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { MissingFieldForContractTemplate } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { toBase64 } from '@/v2/util/base64.util';
import { getNonEmptyObjectFields } from '@/v2/util/object.util';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly templateId: string;
  missingFields: RequiredContractFields;
  readonly contractRecipientId: number;
  readonly companySignatoryUserId?: number;
  readonly returnPath?: string;
  readonly refreshMissingFields?: (
    templateId: string,
    contractRecipientUserId: number,
    companySignatoryUserId: number | undefined
  ) => void;
}

const returnValueOrEmptyString = (input: any, suffix = ''): any => {
  if (input || (!isNaN(Number(input)) && Number(input) !== 0)) {
    return `${input}${suffix}`;
  }
  return '';
};

export const getFormattedAddress = (address: {
  address_line_1: string | null;
  address_line_2: string | null;
  address_city: string | null;
  address_country: string | null;
  address_postalcode: string | null;
}): string => {
  const addressLine1 = returnValueOrEmptyString(address?.address_line_1, ', ');
  const addressLine2 = returnValueOrEmptyString(address?.address_line_2, ', ');
  const address_city = returnValueOrEmptyString(address?.address_city, ', ');
  const postalCode = returnValueOrEmptyString(address?.address_postalcode, ', ');
  const country = returnValueOrEmptyString(address?.address_country);
  return `${addressLine1}${addressLine2}${address_city}${postalCode}${country}`;
};

export const getUnitTypeOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    { value: 'Months', label: polyglot.t('getUnitTypeOptions.months') },
    { value: 'Weeks', label: polyglot.t('getUnitTypeOptions.weeks') },
    { value: 'Days', label: polyglot.t('getUnitTypeOptions.days') },
  ];
};

const formatEquity = (equity: { grantDate?: string; type?: string; amount?: number }): string => {
  const dateText = equity.grantDate ? `granted on ${equity.grantDate}` : '';
  const typeText = equity.type ? `${equity.type}(s)` : '';
  const amount = equity.amount || '';
  return `${amount} ${typeText} ${dateText}`;
};

const RECIPIENT_SHOULD_POPULATE_PLACEHOLDER = '<recipient_populates_';

export const MissingFieldsDetail = (missingFieldsForCategory: MissingFieldForContractTemplate[]) => {
  return (
    <Typography sx={{ ...themeFonts.caption, color: themeColors.grey }}>
      {missingFieldsForCategory?.length
        ? missingFieldsForCategory
            ?.map((missingField: MissingFieldForContractTemplate) => FIELD_DISPLAY_NAME[missingField.fieldId])
            .filter(Boolean)
            .join(', ')
        : ''}
    </Typography>
  );
};

export const MissingTemplateFieldModal = ({
  setOpen,
  open,
  templateId,
  missingFields,
  contractRecipientId,
  companySignatoryUserId,
  returnPath,
  refreshMissingFields,
}: ModalProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const [isSavingMissingFields, setIsSavingMissingFields] = useState<boolean>(false);
  const [recipientShouldPopulate, setRecipientShouldPopulate] = useState<string[]>([]);
  const [hasMissingWorkFields, setHasMissingWorkFields] = useState<boolean>(false);
  const [hasMissingPersonalFields, setHasMissingPersonalFields] = useState<boolean>(false);
  const [fillingMissingWorkFields, setFillingMissingWorkFields] = useState<boolean>(false);
  const [fillingMissingPersonalFields, setFillingMissingPersonalFields] = useState<boolean>(false);
  const [askRecipient, setAskRecipient] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const formik = useFormik<ContractTemplateFieldValues>({
    initialValues: defaultMissingFieldValues,
    enableReinitialize: true,
    validationSchema: getTemplateSchema(missingFields),
    onSubmit: handleSubmitAction,
  });

  const redirectToSendContractRoutePage = useCallback(async () => {
    const getFieldsToBePopulatedByRecipient = (): {
      [key: string]: string;
    } => {
      const fieldsToBePopulatedByRecipient: { [key: string]: string } = {};
      for (const eachKey of recipientShouldPopulate) {
        if (PERSONAL_TYPE_FIELDS.includes(eachKey)) {
          fieldsToBePopulatedByRecipient[eachKey] = `${RECIPIENT_SHOULD_POPULATE_PLACEHOLDER}${eachKey}>`;
        }
      }
      return fieldsToBePopulatedByRecipient;
    };
    try {
      /** Fill template value with collectable form fields **/
      const templateFields = {
        employee_role: formik.values.role,
        employee_address: formik.values.address_line_1
          ? getFormattedAddress({
              address_line_1: returnValueOrEmptyString(formik.values.address_line_1),
              address_line_2: returnValueOrEmptyString(formik.values.address_line_2),
              address_city: returnValueOrEmptyString(formik.values.address_city),
              address_country: returnValueOrEmptyString(formik.values.address_country),
              address_postalcode: returnValueOrEmptyString(formik.values.address_postalcode),
            })
          : '',
        employee_probation_period: formik.values.probation_period_length
          ? `${returnValueOrEmptyString(formik.values.probation_period_length)} ${returnValueOrEmptyString(
              formik.values.probation_period_unit
            )}`
          : '',
        employee_notice_period: formik.values.notice_period_length
          ? `${returnValueOrEmptyString(formik.values.notice_period_length)} ${returnValueOrEmptyString(
              formik.values.notice_period_unit
            )}`
          : '',
        compensation_rate: returnValueOrEmptyString(formik.values.salary_rate),
        compensation_salary_basis: returnValueOrEmptyString(formik.values.salary_basis),
        compensation_equity: formik.values.equity_amount
          ? formatEquity({
              grantDate: dayjs(new LocalDate(formik.values.equity_grant_date).getDate()).format('DD/MM/YYYY'),
              type: formik.values.equity_type,
              amount: formik.values.equity_amount,
            })
          : '',
      };

      const fieldsForRecipient = getFieldsToBePopulatedByRecipient();

      const finalData = { ...formik.values, ...templateFields, ...fieldsForRecipient };

      setIsSavingMissingFields(true);

      await ContractAPI.save({
        recipientId: contractRecipientId,
        fieldsToSave: finalData,
      });

      showMessage(polyglot.t('MissingTemplateFieldModal.successMessages.update'), 'success');
      setIsSavingMissingFields(false);

      let missingTemplateData = getNonEmptyObjectFields(finalData);

      const stateToPassThrough = {
        returnPath,
        companySignatoryUserId,
        contractTemplateData: toBase64({
          missingTemplateData,
        }),
      };

      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId,
          userId: contractRecipientId,
        }),
        stateToPassThrough
      );
    } catch (error) {
      console.error('Failed to save data', error.message);
      showMessage(polyglot.t('MissingTemplateFieldModal.errorMessages.save'), 'error');
    }
  }, [
    polyglot,
    companySignatoryUserId,
    contractRecipientId,
    formik.values,
    recipientShouldPopulate,
    returnPath,
    routerHistory,
    showMessage,
    templateId,
  ]);

  function handleSubmitAction() {
    if (!askRecipient && hasMissingPersonalFields && fillingMissingPersonalFields) {
      // if filling missing personal fields and admin chooses to skip and allow recipient to populate, we should mark it accordingly
      setAskRecipient(true);
      askRecipientToPopulatePersonalFields();
    } else if (fillingMissingPersonalFields && hasMissingWorkFields) {
      // if filling missing personal fields and there are missing work fields, then button should take admin to work fields
      setFillingMissingPersonalFields(false);
      setFillingMissingWorkFields(true);
    } else if (
      (!hasMissingPersonalFields && !hasMissingWorkFields) ||
      (fillingMissingPersonalFields && !hasMissingWorkFields)
    ) {
      setFillingMissingPersonalFields(false);
      redirectToSendContractRoutePage();
    }
  }

  const disableMissingFields = useMemo(() => {
    let disable = false;

    const checkForMissingFieldsBasedOnType = (key: string, disable: boolean): boolean => {
      switch (key) {
        case 'employee_first_name':
        case 'employee_last_name':
        case 'company_signature':
        case 'employee_reports_to':
        case 'employee_dob':
        case 'employee_passport_number':
        case 'employee_department':
        case 'employee_site':
        case 'compensation_currency':
        case 'compensation_equity_type':
        case 'compensation_equity_grant_date':
        case 'compensation_equity_amount':
        case 'compensation_equity_vesting_start':
        case 'compensation_equity_vesting_period':
        case 'compensation_equity_vesting_cliff':
        case 'compensation_equity_exercise_price':
        case 'employee_start_date': {
          disable = !formik.values[key];
          break;
        }

        case 'employee_role': {
          disable = !formik.values['role'];
          break;
        }

        case 'employee_attendance_schedule': {
          disable =
            formik.values['employee_attendance_schedule'] === 0
              ? false
              : !!formik.values['employee_attendance_schedule'];
          break;
        }

        case 'employee_address': {
          disable =
            !formik.values['address_line_1'] ||
            !formik.values['address_city'] ||
            !formik.values['address_country'] ||
            !formik.values['address_postalcode'];
          break;
        }

        case 'employee_notice_period':
        case 'employee_probation_period': {
          disable =
            !formik.values['probation_period_unit'] ||
            !formik.values['probation_period_length'] ||
            !formik.values['notice_period_unit'] ||
            !formik.values['notice_period_length'];
          break;
        }

        case 'compensation_rate':
        case 'compensation_salary_basis': {
          disable =
            !formik.values['salary_pay_schedule'] ||
            !formik.values['salary_basis'] ||
            !formik.values['salary_rate'] ||
            !formik.values['salary_annual_amount'];

          if (['Daily', 'Hourly'].includes(formik.values?.salary_basis as string)) {
            disable = !formik.values['salary_units'];
          }

          break;
        }

        case 'compensation_units': {
          disable = !!(
            formik.values?.compensation_salary_basis &&
            ['Daily', 'Hourly'].includes(formik.values?.compensation_salary_basis) &&
            !formik.values['compensation_units']
          );
          break;
        }

        case 'compensation_equity': {
          disable =
            !formik.values['equity_grant_date'] || !formik.values['equity_type'] || !formik.values['equity_amount'];

          break;
        }
      }
      return disable;
    };

    // allowing administrator to ask recipient to populate missing personal fields
    if (fillingMissingPersonalFields && hasMissingPersonalFields) return false;

    for (const key of Object.keys(missingFields).filter((f) => Boolean(f))) {
      if (fillingMissingWorkFields && WORK_TYPE_FIELDS.includes(key) && !recipientShouldPopulate.includes(key)) {
        disable = checkForMissingFieldsBasedOnType(key, disable);
      } else if (
        fillingMissingPersonalFields &&
        PERSONAL_TYPE_FIELDS.includes(key) &&
        !recipientShouldPopulate.includes(key)
      ) {
        disable = checkForMissingFieldsBasedOnType(key, disable);
      }
      if (disable) {
        break;
      }
    }

    return disable;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, missingFields, fillingMissingPersonalFields, fillingMissingWorkFields, recipientShouldPopulate]);

  const missingFieldsButtonText = useMemo(() => {
    if (!hasMissingPersonalFields && !hasMissingWorkFields) {
      return polyglot.t('MissingTemplateFieldModal.preview');
    }
    if (hasMissingWorkFields && fillingMissingWorkFields && hasMissingPersonalFields) {
      return polyglot.t('MissingTemplateFieldModal.updateFields');
    }
    if (hasMissingWorkFields && fillingMissingWorkFields && !hasMissingPersonalFields) {
      return polyglot.t('MissingTemplateFieldModal.continue');
    }
    if (hasMissingPersonalFields && fillingMissingPersonalFields) {
      return polyglot.t('MissingTemplateFieldModal.next');
    }
    return polyglot.t('MissingTemplateFieldModal.preview');
  }, [
    polyglot,
    fillingMissingPersonalFields,
    fillingMissingWorkFields,
    hasMissingPersonalFields,
    hasMissingWorkFields,
  ]);

  const askRecipientToPopulatePersonalFields = () => {
    let recipientFields: string[] = [];
    for (const eachField of categorisedMissingFields) {
      if (
        [CustomProfileFormType.Details, CustomProfileFormType.Address, CustomProfileFormType.Basic].includes(
          eachField.category
        )
      ) {
        recipientFields = [...recipientFields, eachField.fieldId];
      }
    }
    setRecipientShouldPopulate(recipientFields);
  };

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    await refreshMissingFields?.(templateId, contractRecipientId, companySignatoryUserId);
    setIsRefreshing(false);
  }, [companySignatoryUserId, contractRecipientId, refreshMissingFields, templateId]);

  const categorisedMissingFields: MissingFieldForContractTemplate[] = useMemo(() => {
    const missingFieldsForTemplate: MissingFieldForContractTemplate[] = [];
    for (const eachField of Object.keys(missingFields)) {
      missingFieldsForTemplate.push({
        category: CONTRACT_FIELDS_CATEGORIES[eachField],
        fieldId: eachField,
      });
    }

    return missingFieldsForTemplate;
  }, [missingFields]);

  useEffect(() => {
    const hasMissingPersonal = categorisedMissingFields.some((f) =>
      [CustomProfileFormType.Details, CustomProfileFormType.Address, CustomProfileFormType.Basic].includes(f.category)
    );
    const hasMissingWork = categorisedMissingFields.some((f) =>
      [
        CustomProfileFormType.Salary,
        CustomProfileFormType.Equity,
        CustomProfileFormType.Contract,
        CustomProfileFormType.Role,
      ].includes(f.category)
    );
    // should fill personal ONLY if it has missing personal fields and have not asked recipient to fill it
    const shouldFillPersonal = hasMissingPersonal && !askRecipient;

    setHasMissingPersonalFields(shouldFillPersonal);
    setHasMissingWorkFields(hasMissingWork);
    setFillingMissingPersonalFields(shouldFillPersonal);
    // should be filling work fields ONLY if not filling personal and has missing work fields
    setFillingMissingWorkFields(!shouldFillPersonal && hasMissingWork);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askRecipient, categorisedMissingFields]);

  const sections = useMemo(() => {
    const updateContractWithMissingDomainFields = async (values: AddressValues | PersonalInformationValues) => {
      try {
        const addressValues = values as AddressValues;
        const personalValues = values as PersonalInformationValues;
        const templateFields = {
          employee_address: addressValues?.addressLine1
            ? getFormattedAddress({
                address_line_1: returnValueOrEmptyString(addressValues.addressLine1),
                address_line_2: returnValueOrEmptyString(addressValues.addressLine2),
                address_city: returnValueOrEmptyString(addressValues.city),
                address_country: returnValueOrEmptyString(addressValues.country),
                address_postalcode: returnValueOrEmptyString(addressValues.postcode),
              })
            : '',
          employee_address_line1: addressValues?.addressLine1,
          employee_address_line2: addressValues?.addressLine2,
          employee_address_city: addressValues?.city,
          employee_address_country: addressValues?.country,
          employee_address_postcode: addressValues?.postcode,

          // Personal Information
          employee_dob: personalValues?.dob ? personalValues.dob : undefined,
          employee_passport_number: personalValues?.passportNumber ? personalValues.passportNumber : undefined,
          employee_personal_email: personalValues?.personalEmail ? personalValues.personalEmail : undefined,
        };
        const finalData = { ...values, ...templateFields };

        setIsSavingMissingFields(true);

        await ContractAPI.save({
          recipientId: contractRecipientId,
          fieldsToSave: finalData,
        });

        showMessage(polyglot.t('MissingTemplateFieldModal.successMessages.update'), 'success');
      } catch (error) {
        console.error('Failed to save data', error.message);
        showMessage(polyglot.t('MissingTemplateFieldModal.errorMessages.save'), 'error');
      } finally {
        setIsSavingMissingFields(false);
      }
    };
    const hasMissingField = (category: string) => categorisedMissingFields.some((f) => f.category === category);
    const listOfMissingFieldsForCategory = (category: string) =>
      categorisedMissingFields.filter((f) => f.category === category);
    const result: React.JSX.Element[] = [];
    if (hasMissingField(CustomProfileFormType.Details) && fillingMissingPersonalFields)
      result.push(
        <MissingPersonalFormForPersonalContractSigning
          userId={contractRecipientId}
          refreshData={handleRefresh}
          updateDomainForMissingFields={updateContractWithMissingDomainFields}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Details)}
        />
      );
    if (hasMissingField(CustomProfileFormType.Address) && fillingMissingPersonalFields)
      result.push(
        <MissingAddressFormForPersonalContractSigning
          userId={contractRecipientId}
          refreshData={handleRefresh}
          updateDomainForMissingFields={updateContractWithMissingDomainFields}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Address)}
        />
      );
    if (hasMissingField(CustomProfileFormType.Salary) && fillingMissingWorkFields)
      result.push(
        <MissingCompensation
          userId={contractRecipientId}
          refreshPayroll={handleRefresh}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Salary)}
        />
      );
    if (hasMissingField(CustomProfileFormType.Contract) && fillingMissingWorkFields)
      result.push(
        <MissingContract
          onlyAdd
          userId={contractRecipientId}
          refreshPayroll={handleRefresh}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Contract)}
        />
      );
    if (hasMissingField(CustomProfileFormType.Equity) && fillingMissingWorkFields)
      result.push(
        <MissingEquity
          userId={contractRecipientId}
          refreshData={handleRefresh}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Equity)}
        />
      );
    if (hasMissingField(CustomProfileFormType.Role) && fillingMissingWorkFields)
      result.push(
        <MissingRole
          userId={contractRecipientId}
          refreshData={handleRefresh}
          missingFieldsForCategory={listOfMissingFieldsForCategory(CustomProfileFormType.Role)}
        />
      );
    return result;
  }, [
    polyglot,
    categorisedMissingFields,
    contractRecipientId,
    fillingMissingPersonalFields,
    fillingMissingWorkFields,
    handleRefresh,
    showMessage,
  ]);

  const mainDrawerCopy = useMemo(() => {
    return polyglot.t('MissingTemplateFieldModal.errorMessages.missingData', {
      type: fillingMissingPersonalFields ? 'personal' : fillingMissingWorkFields ? 'work' : '',
      complete: fillingMissingWorkFields ? 'Please complete it before previewing the document.' : '',
    });
  }, [polyglot, fillingMissingPersonalFields, fillingMissingWorkFields]);

  const additionaldrawerText = useMemo(() => {
    let copy = '';
    if (hasMissingPersonalFields && fillingMissingPersonalFields) {
      copy = polyglot.t('MissingTemplateFieldModal.copyMessage');
    }
    return copy;
  }, [polyglot, hasMissingPersonalFields, fillingMissingPersonalFields]);

  return (
    <>
      <DrawerModal isOpen={open} setIsOpen={setOpen}>
        <FormikProvider value={formik}>
          <Form>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={themeFonts.title2}>
                {polyglot.t('MissingTemplateFieldModal.missingInformation')}
              </Typography>
              <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
                {mainDrawerCopy}
                <br />
                {additionaldrawerText}
              </Typography>
              <Stack sx={{ mt: spacing.mt20, gap: spacing.g5 }}>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
                  {polyglot.t('MissingTemplateFieldModal.recipient')}
                </Typography>
                <UserCell userId={contractRecipientId} nameSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }} />
              </Stack>
              <Stack sx={{ mt: spacing.mt20 }}>
                {isRefreshing && <LoadingSpinner />}
                {!isRefreshing && sections.length === 0 && (
                  <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10, mt: spacing.mt20 }}>
                    <Success height="1em" style={{ fill: themeColors.Green, flexShrink: 0 }} />
                    <Typography sx={themeFonts.caption}>
                      {polyglot.t('MissingTemplateFieldModal.allInfoPresent')}
                    </Typography>
                  </Stack>
                )}
                {!isRefreshing &&
                  sections.length > 0 &&
                  sections.map((section, idx) => (
                    <Stack
                      key={idx}
                      sx={{ py: spacing.p10, borderTop: idx ? `1px solid ${themeColors.lightGrey}` : undefined }}
                    >
                      {section}
                    </Stack>
                  ))}
              </Stack>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', ...spacing.mt40 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <LoaderButton
                    sizeVariant="medium"
                    colorVariant="primary"
                    fullWidth={true}
                    name={missingFieldsButtonText}
                    loading={isSavingMissingFields}
                    disabled={disableMissingFields}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </DrawerModal>
    </>
  );
};
