import React, { ReactNode } from 'react';

import { Button } from '@mui/material';
import { primarySmallBtn } from '@v2/styles/buttons.styles';

import ModalV2 from '@/component/widgets/ModalV2';

type Size = 'lg' | 'md' | 'sm' | 'xl' | 'xs';

interface Props {
  readonly title: string;
  readonly isOpen: boolean;
  readonly onClose: Function;
  readonly size?: Size;
  readonly children: ReactNode;
}

export const InfoModal = ({ title, isOpen, onClose, children, size = 'xs' }: Props): JSX.Element => {
  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      title={title}
      actionButton={
        <Button sx={primarySmallBtn} onClick={() => onClose()}>
          OK
        </Button>
      }
      buttonJustifyContent="center"
    >
      {children}
    </ModalV2>
  );
};
