import React from 'react';

import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { NumberCell } from '@v2/components/table/number-cell.component';
import { sortNumeric } from '@v2/components/table/table-sorting.util';
import {
  CustomBenefitAllowanceType,
  CustomBenefitType,
  UserCustomBenefitPaymentDto,
  UserCustomBenefitPaymentStatus,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import Polyglot from 'node-polyglot';

import { LocalDate } from '@/v2/util/local-date';

export const isUsingOpeningBalance = (type: CustomBenefitType | undefined): boolean =>
  !!type && [CustomBenefitType.Allowance, CustomBenefitType.Loan].includes(type);

export const isAllowanceBenefit = (type: CustomBenefitType | undefined): boolean =>
  type === CustomBenefitType.Allowance;

export const isLoanBenefit = (type: CustomBenefitType | undefined): boolean => type === CustomBenefitType.Loan;

export const isRecurringBenefit = (type: CustomBenefitType | undefined): boolean =>
  type === CustomBenefitType.Recurring;

export const calculatePaidAmount = (
  benefitPayments: UserCustomBenefitPaymentDto[],
  benefitType: CustomBenefitType | undefined,
  allowanceType: CustomBenefitAllowanceType | null | undefined,
  date: string,
  status: UserCustomBenefitPaymentStatus = UserCustomBenefitPaymentStatus.Approved
): number => {
  const isAllowance = isAllowanceBenefit(benefitType);

  let start: string | null = null;
  let end: string | null = null;
  if (isAllowance && allowanceType === CustomBenefitAllowanceType.Yearly) {
    start = new LocalDate(date).getFirstDayOfYear().toDateString();
    end = new LocalDate(date).getLastDayOfYear().toDateString();
  } else if (isAllowance && allowanceType === CustomBenefitAllowanceType.Monthly) {
    start = new LocalDate(date).getFirstDayOfMonth().toDateString();
    end = new LocalDate(date).getLastDayOfMonth().toDateString();
  }

  return benefitPayments
    .filter((p) => {
      if (start && end) return p.status === status && p.date >= start && p.date <= end;

      return p.status === status;
    })
    .reduce((sum, p) => sum + p.amount, 0);
};

export const getOpeningBalanceColumns = (
  isLoan: boolean,
  polyglot: Polyglot
): ColumnDef<UserCustomBenefitDto, UserCustomBenefitDto>[] => [
  {
    header: () => (isLoan ? polyglot.t('BenefitModule.borrowed') : polyglot.t('BenefitModule.allowance')),
    accessorFn: (row) => row,
    id: 'openingBalance',
    enableSorting: true,
    sortingFn: (a, b) => sortNumeric(a, b, (item) => item.openingBalance ?? 0),
    cell: (info) => {
      const userBenefit = info.getValue();
      return (
        <NumberCell
          value={
            userBenefit?.openingBalance
              ? formatMoney({
                  amount: userBenefit.openingBalance,
                  asDecimal: true,
                })
              : null
          }
        />
      );
    },
  },
  ...(isLoan
    ? [
        {
          header: () => polyglot.t('BenefitModule.numberOfInstallments'),
          accessorFn: (row: UserCustomBenefitDto) => row,
          id: 'numberOfInstallments',
          enableSorting: true,
          sortingFn: (a: Row<UserCustomBenefitDto>, b: Row<UserCustomBenefitDto>) =>
            sortNumeric<UserCustomBenefitDto>(a, b, (item) => item.numberOfInstallments ?? 0),
          cell: (info: CellContext<UserCustomBenefitDto, UserCustomBenefitDto>) => {
            const userBenefit = info.getValue();
            return <NumberCell value={userBenefit?.numberOfInstallments} />;
          },
        },
      ]
    : []),
];
