import { useMemo } from 'react';

import { Box } from '@mui/material';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';

export type GrowthProgressChartPercentage = {
  value: number;
  color: 'blue' | 'red' | 'green';
  label?: string;
  showTooltip?: boolean;
};

interface GrowthProgressChartProps {
  label?: string;
  labelVariant?: TypographyVariant;
  labelColor?: keyof typeof themeColors;
  labelPlacement?: 'start' | 'end' | 'bottom' | 'top';
  percentages: GrowthProgressChartPercentage[];
  percentJustifyContent?: 'start' | 'space-between';
  percentagesGap?: string;
  labelWidth?: string;
}

const getPercentageColor = (color?: 'blue' | 'red' | 'green'): 'blue' | 'red' | 'green' => {
  return color && ['blue', 'red', 'green'].includes(color) ? color : 'blue';
};

export const GrowthProgressLineChart = ({
  label,
  labelVariant = 'caption',
  labelColor = 'DarkGrey',
  labelPlacement = 'start',
  percentages,
  percentJustifyContent = 'start',
  percentagesGap = '4px',
  labelWidth = '50px',
}: GrowthProgressChartProps) => {
  const labelText = useMemo(() => {
    if (!label) return null;

    return (
      <Box sx={{ display: 'flex', width: labelWidth, justifyContent: 'center' }}>
        <Typography variant={labelVariant} color={labelColor}>
          {label}
        </Typography>
      </Box>
    );
  }, [label, labelVariant, labelColor, labelWidth]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: ['start', 'end'].includes(labelPlacement) ? 'row' : 'column',
        gap: spacing.g8,
        width: '100%',
      }}
    >
      {['start', 'top'].includes(labelPlacement) && labelText}

      <div
        className="progress-bar"
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: percentJustifyContent,
          gap: percentagesGap,
        }}
      >
        {percentages.map((percentage, index) => (
          <StyledTooltip
            disabled={!percentage.showTooltip}
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" color="white">
                  {percentage.label ?? ''}
                </Typography>
                <Typography variant="caption" color="white">{`${percentage.value}%`}</Typography>
              </Box>
            }
            placement="top"
            key={index}
          >
            <div
              key={index}
              className={`progress-bar-fill progress-bar-${getPercentageColor(percentage.color)}`}
              style={{
                width: `${Math.max(percentage.value, 0)}%`,
              }}
            />
          </StyledTooltip>
        ))}
      </div>

      {['end', 'bottom'].includes(labelPlacement) && labelText}
    </Box>
  );
};
