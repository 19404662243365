import { useCallback, useMemo, useState } from 'react';

import { FormControlLabel, IconButton, RadioGroup, Stack } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PaymentEntitySelect } from '@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/payment-entity-select.component';
import { GlobalPayrollAPI } from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { cleanPayCodeValue } from '@/v2/feature/payroll/features/payroll-global/global-payroll.util';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  countryCode: string;
  paycode: GlobalPaycode | null;
  paycodes: GlobalPaycode[];
  onPaycodeSaved?: () => void;
  onPaycodeDeleted?: () => void;
};

export const GlobalPayrollPayCodeSeederDrawer = ({
  countryCode,
  paycode,
  paycodes,
  onPaycodeSaved,
  onPaycodeDeleted,
}: Props) => {
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const otherPaycodes = useMemo(() => paycodes.filter((p) => p.code !== paycode?.code), [paycode?.code, paycodes]);
  const currentCreditors = useMemo(() => paycodes.map(({ credit }) => credit), [paycodes]);
  const currentDebitors = useMemo(() => paycodes.map(({ debit }) => debit), [paycodes]);

  const addOrEditPaycode = useCallback(
    async (newPaycode: GlobalPaycodeUpdate) => {
      setSaving(true);
      try {
        await GlobalPayrollAPI.updatePaycodeSeedersAsSuperAdmin(countryCode, [newPaycode, ...otherPaycodes]);
      } catch (e) {
        showMessage(`Failed to add paycode. ${nestErrorMessage(e)}`, 'error');
        setSaving(false);
        return;
      }
      showMessage(`Paycodes updated.`, 'success');
      onPaycodeSaved?.();
    },
    [countryCode, onPaycodeSaved, otherPaycodes, showMessage]
  );

  const deletePaycode = useCallback(async () => {
    setSaving(true);
    try {
      await GlobalPayrollAPI.updatePaycodeSeedersAsSuperAdmin(countryCode, otherPaycodes);
    } catch (e) {
      showMessage(`Failed to delete paycode. ${nestErrorMessage(e)}`, 'error');
      setSaving(false);
      return;
    }
    showMessage(`Paycode deleted.`, 'success');
    onPaycodeDeleted?.();
  }, [countryCode, onPaycodeDeleted, otherPaycodes, showMessage]);

  const formik = useFormik({
    initialValues: {
      code: paycode?.code ?? '',
      name: paycode?.name ?? '',
      order: paycode?.order ?? paycodes.length,
      required: paycode?.required ?? false,
      selectedDebit: paycode?.debit ?? '',
      selectedCredit: paycode?.credit ?? '',
      paycodeKind: (paycode?.credit ? 'paycode-payment' : 'paycode-value') as 'paycode-payment' | 'paycode-value',
      formula: paycode?.formula ?? '',
    },
    validationSchema: yup.object({
      code: yup
        .string()
        .required('Code is required')
        .notOneOf(
          otherPaycodes.map((p) => p.code),
          'Pay code already defined'
        ),
      name: yup.string().required('Name is required'),
      selectedCredit: yup.string().when('paycodeKind', {
        is: (val: string) => val === 'paycode-payment',
        then: yup.string().required('Credit party is required'),
        otherwise: yup.string().optional(),
      }),
      selectedDebit: yup.string().when('paycodeKind', {
        is: (val: string) => val === 'paycode-payment',
        then: yup.string().required('Debit party is required'),
        otherwise: yup.string().optional(),
      }),
    }),
    onSubmit: async (values) => {
      const update = {
        code: values.code,
        name: values.name,
        credit: values.paycodeKind === 'paycode-payment' ? values.selectedCredit : '',
        debit: values.paycodeKind === 'paycode-payment' ? values.selectedDebit : '',
        formula: values.formula.trim(),
        order: values.order,
        required: values.required,
      };
      await addOrEditPaycode(update);
    },
  });

  const orders = useMemo(() => {
    const orderedCodes = otherPaycodes.map(({ name, order }) => ({ name, order })).sort((a, b) => a.order - b.order);
    orderedCodes.splice(formik.values.order, 0, { name: formik.values.name, order: formik.values.order });
    return orderedCodes.map<OptionObject>(({ name }, n) => ({
      label: `${n}`,
      value: n,
      description: name,
    }));
  }, [otherPaycodes, formik.values.order, formik.values.name]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {!!paycode && (
            <>
              <Typography variant="title2">Edit pay code</Typography>
              <IconButton title="Delete pay code" sx={tableIconButtonSx} onClick={() => deletePaycode()}>
                <Trash {...iconSize} />
              </IconButton>
            </>
          )}
          {!paycode && <Typography variant="title2">New pay code</Typography>}
        </Stack>
        <Stack sx={{ gap: spacing.g10, mt: spacing.mt30 }}>
          <TextfieldComponent
            name="code"
            label="Code"
            value={formik.values.code}
            onChange={(e) => formik.setFieldValue('code', cleanPayCodeValue(e.target.value))}
            disabled={saving}
            error={formik.submitCount > 0 && !!formik.errors.code}
            helperText={formik.submitCount > 0 && formik.errors.code}
            autoFocus
          />
          <TextfieldComponent
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            disabled={saving}
            error={formik.submitCount > 0 && !!formik.errors.name}
            helperText={formik.submitCount > 0 && formik.errors.name}
          />
          <RadioGroup
            name="paycodeKind"
            value={formik.values.paycodeKind}
            onChange={formik.handleChange}
            sx={{ flexFlow: 'row' }}
          >
            <FormControlLabel
              labelPlacement="end"
              value={'paycode-payment'}
              control={<StyledRadio disableRipple />}
              label={<Typography variant="caption">Payment</Typography>}
              disabled={saving}
            />
            <FormControlLabel
              labelPlacement="end"
              value={'paycode-value'}
              control={<StyledRadio disableRipple />}
              label={<Typography variant="caption">Value</Typography>}
              disabled={saving}
            />
          </RadioGroup>
          {formik.values.paycodeKind === 'paycode-payment' && (
            <>
              <Stack>
                <PaymentEntitySelect
                  name="selectedCredit"
                  label="Credit"
                  values={currentCreditors}
                  value={formik.values.selectedCredit}
                  onChange={(value) => {
                    formik.setFieldValue('selectedCredit', value);
                  }}
                  disabled={saving}
                  error={formik.submitCount > 0 && !!formik.errors.selectedCredit}
                  helperText={formik.submitCount > 0 && formik.errors.selectedCredit}
                />
              </Stack>
              <Stack>
                <PaymentEntitySelect
                  name="selectedDebit"
                  label="Debit"
                  values={currentDebitors}
                  value={formik.values.selectedDebit}
                  onChange={(value) => {
                    formik.setFieldValue('selectedDebit', value);
                  }}
                  disabled={saving}
                  error={formik.submitCount > 0 && !!formik.errors.selectedDebit}
                  helperText={formik.submitCount > 0 && formik.errors.selectedDebit}
                />
              </Stack>
            </>
          )}
          <TextfieldComponent
            name="formula"
            label="Default value"
            value={formik.values.formula}
            onChange={formik.handleChange}
            multiline
            minRows={4}
            InputProps={{ style: { whiteSpace: 'pre', overflow: 'auto' } }}
            disabled={saving}
          />
          <SelectComponent
            name="order"
            label="Payrun display order"
            options={orders}
            value={formik.values.order}
            onChange={formik.handleChange}
            disabled={saving}
          />
          <CheckboxComponent
            name="required"
            label="Required"
            checked={formik.values.required}
            onChange={formik.handleChange}
            disabled={saving}
          />
        </Stack>
        <LoaderButton
          name="Save"
          type="submit"
          fullWidth
          loading={saving}
          sizeVariant={'medium'}
          colorVariant={'primary'}
          style={{ marginTop: spacing.m20 }}
        />
      </Form>
    </FormikProvider>
  );
};
