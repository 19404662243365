import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortNumeric } from '@v2/components/table/table-sorting.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { EditIndividualCheckForm } from '@/v2/feature/id-check/components/edit-individual-check-drawer.page';
import { IdCheckEndpoints } from '@/v2/feature/id-check/id-check.api';
import { IndividualCheck } from '@/v2/feature/id-check/id-check.interface';
import { RootStyle } from '@/v2/styles/root.styles';
import { formatAsGBPCurrency } from '@/v2/util/string-format.util';

export const IndividualCheckBackofficePage = (): React.JSX.Element => {
  const {
    data: individualChecks,
    isLoading: loadingIndividualChecks,
    mutate: refresh,
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });

  const [searchInput, setSearchInput] = useState<string>('');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedCheck, setSelectedCheck] = useState<IndividualCheck | null>(null);

  const filteredChecks = useMemo(() => {
    if (!individualChecks) return [];
    if (!searchInput) return individualChecks;

    const search = searchInput.toLowerCase();
    return individualChecks.filter((check) => check.id.toLowerCase().includes(search));
  }, [searchInput, individualChecks]);

  const columnData = useMemo<ColumnDef<IndividualCheck, IndividualCheck>[]>(() => {
    return [
      {
        header: () => 'Check Id',
        accessorFn: (row) => row,
        id: 'ID',
        enableSorting: true,
        cell: (info) => {
          const individualCheck = info.getValue();
          return (
            <Box>
              <Typography variant="caption">{individualCheck.id}</Typography>
            </Box>
          );
        },
      },
      {
        header: () => 'Price',
        accessorFn: (row) => row,
        id: 'price',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.price),
        cell: (info) => {
          const idPackage = info.getValue();
          return (
            <Box>
              {idPackage.price ? (
                <Typography variant="caption">{formatAsGBPCurrency(idPackage.price, 2)}</Typography>
              ) : (
                <EmptyCell />
              )}
            </Box>
          );
        },
      },
      {
        header: () => 'Updated at',
        accessorFn: (row) => row,
        id: 'createdBy',
        enableSorting: false,
        cell: (info) => {
          const individualCheck = info.getValue();
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Typography variant="caption">{new Date(individualCheck.updatedAt).toUTCString()}</Typography>
            </Box>
          );
        },
      },
    ];
  }, []);

  const handleRowClick = useCallback((row: Row<IndividualCheck>) => {
    setSelectedCheck(row.original);
    setIsDrawerOpen(true);
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Individual checks for Zinc ID checks</Typography>} showAction />

      <ContentWrapper loading={loadingIndividualChecks} sx={{}}>
        <Box>
          <TableSearch
            placeholder="Search"
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ width: '250px', minWidth: '250px' }}
          />
        </Box>
        <Box sx={{ mt: '10px' }}>
          <BasicTable<IndividualCheck>
            rowData={filteredChecks}
            columnData={columnData}
            loading={loadingIndividualChecks}
            hidePagination
            rowClick={handleRowClick}
          />
        </Box>
        {selectedCheck && (
          <DrawerModal isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
            <EditIndividualCheckForm
              initialValues={selectedCheck}
              onClose={() => {
                setIsDrawerOpen(false);
                refresh!();
              }}
            />
          </DrawerModal>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
