import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateDomainTypes } from '@v2/infrastructure/i18n/translate.util';

import { ReactComponent as Timeaway } from '@/images/dashboard-icons/Timeaway.svg';
import { TodoCardVersionSmall } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-small/todo-card-version-small.component';
import { PendingRequestSmallWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { iconSize } from '@/v2/styles/menu.styles';

export const TimeRequestTodoVersionSmall = ({ todo }: { todo: PendingRequestSmallWidget }) => {
  const { polyglot } = usePolyglot();

  return (
    <TodoCardVersionSmall
      key={`${todo.domain}-req`}
      icon={<Timeaway {...iconSize} />}
      title={translateDomainTypes(todo.domain, polyglot)}
      countTitle={polyglot.t('TimeRequestTodoVersionSmall.requests', { smart_count: todo.count })}
    />
  );
};
