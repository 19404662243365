import { forwardRef } from 'react';

import { Button, ButtonProps } from '@mui/base/Button';

import { ReactComponent as LoaderIcon } from '@/images/side-bar-icons/Loader.svg';
import '@/v2/scss/components/button-component.scss';

export type LoadingButtonColorVariant = 'primary' | 'secondary' | 'light' | 'danger' | 'dashed' | 'tooltip' | 'text';

export type LoadingButtonSizeVariant = 'large' | 'medium' | 'small' | 'link';

export type LoaderButtonComponentProps = ButtonProps & {
  sizeVariant: LoadingButtonSizeVariant;
  loading: boolean | undefined;
  colorVariant: LoadingButtonColorVariant;
  className?: string;
  fullWidth?: boolean;
  name?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties; // sx is mui prop
};

export const LoaderButton = forwardRef<HTMLButtonElement, LoaderButtonComponentProps>(
  (
    {
      type,
      loading,
      onClick,
      style,
      name,
      children,
      className,
      fullWidth,
      disabled,
      sizeVariant = 'medium',
      colorVariant = 'primary',
      ...otherProps
    },
    ref
  ) => {
    const derivedType = type ? type : onClick ? 'button' : 'submit';
    return (
      <Button
        ref={ref}
        {...otherProps}
        type={derivedType}
        onClick={loading ? undefined : onClick}
        style={style}
        disabled={loading || disabled}
        className={`${className} ${sizeVariant}-button ${colorVariant}-button ${fullWidth ? 'fullWidth-button' : ''}`}
      >
        {loading ? <LoaderIcon className="button-loader-spin" /> : <span>{children ?? name}</span>}
      </Button>
    );
  }
);
