import { Box } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { RequestFormAttachmentComponent } from '@/v2/feature/requests/features/request-forms/request-forms.interface';

type AttachmentComponentDrawerProps = {
  value?: RequestFormAttachmentComponent | null;
  onSave?: (value: RequestFormAttachmentComponent) => void;
};

export const AttachmentComponentDrawer = ({ value, onSave }: AttachmentComponentDrawerProps) => {
  const { polyglot } = usePolyglot();

  const formik = useFormik({
    initialValues: {
      label: value?.label ?? '',
      required: value?.required ?? false,
    },
    validationSchema: yup.object({
      label: yup.string().required(polyglot.t('AttachmentComponentDrawer.required')),
    }),
    onSubmit(values) {
      onSave?.({
        type: 'attachment',
        id: value?.id ?? uuidv4(),
        label: values.label,
        required: values.required,
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('AttachmentComponentDrawer.field')}</Typography>

        <TextfieldComponent
          name="label"
          label={polyglot.t('AttachmentComponentDrawer.label')}
          value={formik.values.label}
          onChange={formik.handleChange}
          maxLength={50}
          autoFocus
          clearText={() => formik.setFieldValue('label', '')}
          helperText={formik.submitCount ? formik.errors.label : ''}
          error={!!formik.submitCount && !!formik.errors.label}
        />
        <CheckboxComponent
          name="required"
          label={polyglot.t('General.required')}
          checked={formik.values.required}
          onChange={formik.handleChange}
        />
        {!!formik.values.label && (
          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="primary">
              {polyglot.t('General.save')}
            </ButtonComponent>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};
