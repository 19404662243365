import { useRef, useState } from 'react';

import { StandardTextFieldProps } from '@mui/material';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { ddMMYYYYToIsoDateString, isoDateStringToDDMMYYY } from '@/v2/infrastructure/date/date-format.util';

export type Props = Omit<
  StandardTextFieldProps,
  'name' | 'label' | 'value' | 'onChange' | 'color' | 'InputProps' | 'InputLabelProps' | 'SelectProps' | 'ref'
> & {
  name: string;
  label: string;
  value?: string | null;
  onChange?: (value: string) => void;
};

export const TypeableDateComponent = (props: Props) => {
  const { value, onChange, error, helperText, ...textFieldProps } = props;
  const [rawDate, setRawDate] = useState(isoDateStringToDDMMYYY(value ?? '') ?? '');
  const editing = useRef(false);
  return (
    <TextfieldComponent
      {...textFieldProps}
      value={rawDate}
      onChange={(e) => {
        const inputDate = e.target.value.trim().replace(/[^\d/]/g, '');
        editing.current = true;
        setRawDate(inputDate);
      }}
      maxLength={10}
      helperText={!editing.current && error && helperText}
      error={!editing.current && error}
      clearText={() => {
        editing.current = false;
        setRawDate('');
        onChange?.('');
      }}
      onBlur={() => {
        editing.current = false;
        const iso = ddMMYYYYToIsoDateString(rawDate ?? '');
        if (!iso || Number.isNaN(Date.parse(iso))) {
          onChange?.(rawDate);
          return;
        }
        onChange?.(iso);
      }}
    />
  );
};
