import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserProfileProvider } from '@/v2/feature/user/context/user-profile.context';
import { UserContractCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-contract-card.component';
import { CustomSections } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-card.component';
import { IdentityChecksCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-identity-checks-card.component';
import { UserProfileLifecycleCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-lifecycle-card.component';
import { RoleCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-role-card.component';
import { UserProfilePageProps } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export function UserWork({ userId, onUpdate, missingFields }: UserProfilePageProps): JSX.Element {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const finallyRefreshUserObject = async (section: string) => {
    if (['user.basicInfo', 'user.role', 'user.lifecycle'].includes(section)) {
    }
    await onUpdate();
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Work</Typography>} />
      <ContentWrapper loading={false} sx={{ pt: spacing.p25 }}>
        <UserProfileProvider>
          <>
            <ScopesControl scopes={['user.role:read']} context={scopesContext}>
              <RoleCard userId={userId} onSubmit={() => finallyRefreshUserObject('user.role')} />
            </ScopesControl>
            <ScopesControl
              scopes={['user.contract', 'user.contract:read', 'user.contract:read:manager']}
              context={scopesContext}
            >
              <UserContractCard userId={userId} handleSubmit={() => finallyRefreshUserObject('user.contract')} />
            </ScopesControl>

            <CustomSections
              userId={userId}
              formTab={ProfileTab.Work}
              onSubmit={() => finallyRefreshUserObject('')}
              missingFields={missingFields}
            />

            <ScopesControl scopes={['user.lifecycle', 'user.lifecycle:read']} context={scopesContext}>
              <UserProfileLifecycleCard
                userId={userId}
                handleSubmit={() => finallyRefreshUserObject('user.lifecycle')}
                missingFields={missingFields}
              />
            </ScopesControl>
            <ScopesControl scopes={['user.rightWork:read']} context={scopesContext}>
              <IdentityChecksCard userId={userId} missingFields={missingFields} />
            </ScopesControl>
          </>
        </UserProfileProvider>
      </ContentWrapper>
    </RootStyle>
  );
}
