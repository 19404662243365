import { AbsenceImportDto } from '@v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { DeviceImportDto } from '@v2/feature/device/features/devices-import/devices-import.dto';

import { EntityImportErrorDto } from '@/v2/feature/entity-import/entity-import.dto';
import { EnrichedUserDto } from '@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.dto';
import { UserImportDto } from '@/v2/feature/user/features/user-import/user-import.dto';

type importDto = UserImportDto | EnrichedUserDto | AbsenceImportDto | DeviceImportDto;

export const hasErrors = (errors: EntityImportErrorDto<importDto>[]) => getTotalErrorCount(errors) > 0;

export function getRowIdsWithErrors(rows: EntityImportErrorDto<importDto>[]) {
  return rows.filter((row) => (row.errors || []).length > 0).map((row) => row.id);
}

export const getTotalErrorCount = (errors: EntityImportErrorDto<importDto>[]) =>
  errors.filter((error) => (error.errors || []).length > 0).length;
