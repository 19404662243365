import { UserDetailsScopes } from '@/component/dashboard/userDetails/user-details.scopes';
import {
  ADMIN_USER_DOCUMENTS_ROUTE,
  ADMIN_USER_REVIEWS_RESULT_ROUTE,
  USER_ABSENCE_ROUTE,
  USER_APPS_TAB,
  USER_ATTENDANCE_ROUTE,
  USER_BENEFITS_ROUTE,
  USER_COMPENSATION_TAB,
  USER_CONTACT_TAB,
  USER_DEVICES_PAGE,
  USER_EXPENSES_ROUTE,
  USER_INVOICES_ROUTE,
  USER_PAY_ITEMS_ROUTE,
  USER_PAYROLL_PAYSLIPS,
  USER_PERSONAL_TAB,
  USER_TASKS_PAGE,
  USER_WORK_TAB,
} from '@/lib/routes';
import { checkIsManagerOrAdmin, checkScopes } from '@/lib/scopes';
import { CurrentUser, ScopeContext, UserOverviewConfig } from '@/models';
import { AbsenceScopes } from '@/v2/feature/absence/absence.scopes';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { PensionScopes } from '@/v2/feature/benefits/subfeature/pension/pension.scopes';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';
import { DocumentsScopes } from '@/v2/feature/documents/documents.scopes';
import {
  USER_PROFILE_GROWTH_SCOPE,
  USER_PROFILE_REVIEWS_SCOPE,
} from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { PayrollScopes } from '@/v2/feature/payroll/payroll.scopes';
import { TaskScopes } from '@/v2/feature/task/task.scopes';
import { CustomProfileFormType } from '@/v2/feature/user/features/user-profile/details/user-profile.interface';
import { MissingField } from '@/v2/feature/user/user.interface';
import { CompanyConfig } from '@shared/modules/company/company.types';
import Polyglot from 'node-polyglot';
import { generatePath } from 'react-router-dom';

const UserProfileTabOrder = [
  'personal',
  'work',
  'compensation',
  'contact',
  'time',
  'attendance',
  'documents',
  'tasks',
  'devices',
  'apps',
  'salary',
  'pension',
];

export function sortByUserProfileTabOrder<T>(array: T[], getName: (element: T) => string): T[] {
  return array.sort(
    (a, b) =>
      UserProfileTabOrder.indexOf(getName(a).toLocaleLowerCase()) -
      UserProfileTabOrder.indexOf(getName(b).toLocaleLowerCase())
  );
}

export const getUserProfileSecondaryMenu = (
  userId: number,
  companyConfig: CompanyConfig,
  userOverviewConfig: UserOverviewConfig,
  currentUser: CurrentUser,
  showHiddenFeatures: boolean,
  context: ScopeContext,
  polyglot: Polyglot,
  missingFields?: MissingField[] | null
): readonly NavConfigItem[] => {
  const isEmployeeData =
    checkScopes(currentUser, UserDetailsScopes.VIEW_USER_PERSONAL, context) ||
    checkScopes(currentUser, UserDetailsScopes.VIEW_USER_WORK, context) ||
    checkScopes(currentUser, UserDetailsScopes.VIEW_USER_COMPENSATION, context) ||
    checkScopes(currentUser, UserDetailsScopes.VIEW_USER_CONTACT, context);

  const isApplicationData =
    checkScopes(
      currentUser,
      [
        ...AbsenceScopes.VIEW_USER_ABSENCES,
        'attendance:manager',
        ...DocumentsScopes.VIEW_USER_DOCUMENTS,
        ...TaskScopes.VIEW_USER_TASKS,
        ...DeviceScopes.VIEW_USER_DEVICES,
        ...AppScopes.VIEW_USER_APPS,
        ...USER_PROFILE_GROWTH_SCOPE,
      ],
      context
    ) ||
    checkScopes(currentUser, [...PayrollScopes.VIEW_USER_PAYSLIPS], context) ||
    checkScopes(currentUser, ['insurance:manager'], { userId }) ||
    checkScopes(currentUser, ['expenses:manager'], { userId }) ||
    checkScopes(currentUser, ['expenses'], { userId }) ||
    checkScopes(currentUser, ['invoices:manager'], { userId }) ||
    checkScopes(currentUser, ['invoices'], { userId }) ||
    (userOverviewConfig.companyInPayroll &&
      userOverviewConfig?.companyInPension &&
      userOverviewConfig?.contractType !== 'Contractor' &&
      userOverviewConfig.inPayroll &&
      checkScopes(currentUser, PensionScopes.VIEW_USER_PENSION, context)) ||
    (companyConfig.inGlobalPayroll && checkScopes(currentUser, ['payroll'], context));

  let subItemsForApplicationData = [
    {
      title: polyglot.t('getPageConfig.absence'),
      stub: 'time',
      path: generatePath(USER_ABSENCE_ROUTE, { userId }),
      isHidden: !checkScopes(currentUser, AbsenceScopes.VIEW_USER_ABSENCES, context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.attendance'),
      stub: 'attendance',
      path: generatePath(USER_ATTENDANCE_ROUTE, { userId }),
      isHidden: !checkScopes(currentUser, ['attendance:manager'], context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.documents'),
      stub: 'documents',
      path: generatePath(ADMIN_USER_DOCUMENTS_ROUTE, { userId }),
      isHidden: !checkScopes(currentUser, DocumentsScopes.VIEW_USER_DOCUMENTS, context),
      hasChildren: false,
    },
    // Temporarily brought back on Polina's request - previously hidden on Chris' request as it is not aligned with the rest of the user profile - might be brought back later
    {
      title: polyglot.t('getPageConfig.tasks'),
      stub: 'tasks',
      path: generatePath(USER_TASKS_PAGE, { userId }),
      isHidden: !checkScopes(currentUser, TaskScopes.VIEW_USER_TASKS, context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.reviews'),
      stub: 'reviews',
      path: generatePath(ADMIN_USER_REVIEWS_RESULT_ROUTE, { userId }),
      isHidden: !checkScopes(currentUser, USER_PROFILE_REVIEWS_SCOPE, context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.benefits'),
      stub: 'benefits',
      path: generatePath(USER_BENEFITS_ROUTE, { userId }),
      isHidden:
        !checkScopes(currentUser, ['insurance:manager', 'pension:manager'], { userId }) ||
        userOverviewConfig?.contractType === 'Contractor',
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.payItems'),
      stub: 'pay-items',
      path: generatePath(USER_PAY_ITEMS_ROUTE, { userId }),
      isHidden:
        (!userOverviewConfig.inPayroll &&
          !userOverviewConfig.inGlobalPayroll &&
          !userOverviewConfig.inGlobalPayrollPast) ||
        !checkScopes(currentUser, ['user.compensation:all', 'payroll:all'], context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.expenses'),
      stub: 'expenses',
      path: generatePath(USER_EXPENSES_ROUTE, { userId }),
      isHidden: !checkIsManagerOrAdmin(currentUser, ['expenses:manager']),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.salary'),
      stub: 'salary',
      path: generatePath(USER_PAYROLL_PAYSLIPS, { userId }),
      isHidden: !checkScopes(currentUser, PayrollScopes.VIEW_USER_PAYSLIPS, context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.invoices'),
      stub: 'invoices',
      path: generatePath(USER_INVOICES_ROUTE, { userId }),
      isHidden: !checkIsManagerOrAdmin(currentUser, ['invoices:manager']),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.devices'),
      stub: 'devices',
      path: generatePath(USER_DEVICES_PAGE, { userId }),
      isHidden: !checkScopes(currentUser, DeviceScopes.VIEW_USER_DEVICES, context),
      hasChildren: false,
    },
    {
      title: polyglot.t('getPageConfig.apps'),
      stub: 'apps',
      path: generatePath(USER_APPS_TAB, { userId }),
      isHidden: !checkScopes(currentUser, AppScopes.VIEW_USER_APPS, context),
      hasChildren: false,
    },

    // {
    //   title: polyglot.t('getPageConfig.surveys'),
    //   stub: 'surveys',
    //   path: generatePath(ADMIN_USER_SURVEYS_ROUTE, { userId }),
    //   isHidden: !checkScopes(currentUser, USER_PROFILE_SURVEYS_SCOPE, context),
    //   hasChildren: false,
    // },
  ];

  let menu = [
    {
      title: polyglot.t('getPageConfig.employee-data'),
      stub: 'employee-data',
      isHidden: !isEmployeeData,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('getPageConfig.personal'),
          stub: 'personal',
          path: generatePath(USER_PERSONAL_TAB, { userId }),
          isHidden: !checkScopes(currentUser, UserDetailsScopes.VIEW_USER_PERSONAL, context),
          alerts:
            missingFields?.filter(
              (field) =>
                field?.cardId &&
                (field.cardId === CustomProfileFormType.Details ||
                  field.cardId === CustomProfileFormType.Basic ||
                  field.cardId === CustomProfileFormType.About ||
                  field.cardId === CustomProfileFormType.Family)
            ).length ?? 0,
          hasChildren: false,
        },
        {
          title: polyglot.t('getPageConfig.work'),
          stub: 'work',
          path: generatePath(USER_WORK_TAB, { userId }),
          isHidden: !checkScopes(currentUser, UserDetailsScopes.VIEW_USER_WORK, context),
          alerts: missingFields?.find(
            (field) =>
              field &&
              (field.cardId === CustomProfileFormType.Lifecycle || field.cardId === CustomProfileFormType.IdentityCheck)
          )
            ? 1
            : 0,
          hasChildren: false,
        },
        {
          title: polyglot.t('getPageConfig.compensation'),
          stub: 'compensation',
          path: generatePath(USER_COMPENSATION_TAB, { userId }),
          isHidden: !checkScopes(currentUser, UserDetailsScopes.VIEW_USER_COMPENSATION, context),
          alerts: missingFields?.filter(
            (field) =>
              field?.cardId === CustomProfileFormType.Salary ||
              field?.cardId === CustomProfileFormType.BankAccount ||
              field?.cardId === CustomProfileFormType.TaxInformation
          )?.length,
          hasChildren: false,
        },
        {
          title: polyglot.t('getPageConfig.contact'),
          stub: 'contact',
          path: generatePath(USER_CONTACT_TAB, { userId }),
          isHidden: !checkScopes(currentUser, UserDetailsScopes.VIEW_USER_CONTACT, context),
          alerts: missingFields?.filter(
            (field) =>
              field?.cardId === CustomProfileFormType.Address || field?.cardId === CustomProfileFormType.Emergency
          )?.length,
          hasChildren: false,
        },
      ],
    },
    {
      title: polyglot.t('getPageConfig.application-data'),
      stub: 'application-data',
      isHidden: !isApplicationData || !subItemsForApplicationData.some((i) => !i.isHidden),
      hasChildren: true,
      subItems: subItemsForApplicationData,
    },
  ];

  return menu as NavConfigItem[];
};
