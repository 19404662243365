import { useCallback, useEffect, useState } from 'react';

import { CompanyPayroll, CompanyPayrollsDto } from '@shared/modules/payroll/payroll.types';
import { useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { PAYROLL_COMPANY_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { GlobalPayrollPayruns } from '@/v2/feature/payroll/features/payroll-global/global-payroll-payruns/global-payroll-payruns.component';
import { PayrollPayruns } from '@/v2/feature/payroll/features/payroll-uk/payroll-payruns/payroll-payruns.component';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const CompanyPayrollPage = () => {
  const { polyglot } = usePolyglot();
  const payrollId = Number(useParams<{ payrollId: string }>().payrollId);
  const [showMessage] = useMessage();
  const [companyPayrolls, setCompanyPayrolls] = useState<CompanyPayrollsDto>();
  const [payroll, setPayroll] = useState<CompanyPayroll>();
  const routerHistory = useHistory();

  const refreshPayroll = useCallback(async (abort?: AbortController) => {
    const payrolls = await PayrollAPI.getCompanyPayrolls();
    if (abort?.signal.aborted) return;
    setCompanyPayrolls(payrolls);
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    refreshPayroll(ac);
    return () => ac.abort();
  }, [refreshPayroll]);

  useEffect(() => {
    if (!companyPayrolls?.items) return;
    const matchedPayroll = companyPayrolls.items.find((payroll) => payroll.id === payrollId);
    if (!matchedPayroll) {
      showMessage(polyglot.t('CompanyPayroll.payrollNotFound'), 'error');
      routerHistory.push(PAYROLL_COMPANY_ROUTE);
      return;
    }
    setPayroll(matchedPayroll);
  }, [companyPayrolls?.items, payrollId, routerHistory, showMessage, polyglot]);

  return (
    <ContentWrapper loading={!payroll} hideFooter noHorizontalPadding sx={{ pb: 0 }}>
      {payroll && !!payroll.employer && (
        <PayrollPayruns
          payroll={payroll}
          refreshPayroll={async () => {
            await refreshPayroll?.();
          }}
        />
      )}
      {payroll && !payroll.employer && (
        <GlobalPayrollPayruns
          payroll={payroll}
          refreshPayroll={async () => {
            await refreshPayroll?.();
          }}
        />
      )}
    </ContentWrapper>
  );
};
