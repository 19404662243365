import { useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  FamilyForm,
  FamilySchema,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-family-form.component';
import {
  FamilyValues,
  getMaritalOptions,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { isDataEmpty } from '@/v2/feature/user/features/user-profile/details/user-profile.util';
import { UserAPI, UserEndpoints } from '@/v2/feature/user/user.api';
import { MissingField } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SCOPE = 'user.family' as const;
const iconSize = { width: 14, height: 14 } as const;
const skeletonHeight = calculateSkeletonHeight(FamilySchema);

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: FamilyValues) => void;
  readonly missingFields?: MissingField[] | null;
}

export const FamilyCard = ({ userId, onSubmit, missingFields }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: userFamilyMembers, mutate: refreshUserFamilyMembers } = useApiClient(
    UserEndpoints.getUserFamilyMembers(userId),
    { suspense: false }
  );

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [dataO, setDataO] = useState<O.Option<FamilyValues>>(() => O.none);
  const [isEmpty, setEmpty] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const refreshMembers = useCallback(async () => {
    if (refreshUserFamilyMembers) await refreshUserFamilyMembers();
  }, [refreshUserFamilyMembers]);

  useEffect(() => {
    (async () => {
      try {
        const data = await UserAPI.getUserFamilyInfo(userId);
        setDataO(O.some(data));
        setEmpty(isDataEmpty(data, data.customUpdates));
      } catch (error) {
        showMessage(polyglot.t('FamilyCard.errorMessages.load'), 'error');
      }
    })();
  }, [polyglot, showMessage, userId]);

  return pipe(
    dataO,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      (data) => (
        <ProfileCard customUpdates={data.customUpdates} fieldStubs={['maritalStatus']} sx={cardSx}>
          <Box component="header" sx={cardHeaderSx}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <Typography variant="h3" sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                {polyglot.t('FamilyCard.family')}
              </Typography>
              <ScopesControl scopes={['user.family:read']} context={scopesContext}>
                {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.Family) && (
                  <Box sx={hazardMissingField}>
                    <Hazard {...hazardSize} />
                  </Box>
                )}
              </ScopesControl>
            </Box>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton onClick={() => setIsOpen(true)} title="Edit" sx={tableIconButtonSx}>
                {isEmpty ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
              </IconButton>
            </ScopesControl>
          </Box>
          <Box component="section">
            {isEmpty ? (
              <MissingInformationLabel />
            ) : (
              <Box component="dl" sx={definitionListSx}>
                <FieldStructure
                  fieldTitle={polyglot.t('FamilyCard.maritalStatus')}
                  fieldValue={getMaritalOptions(polyglot).find((option) => option.value === data.maritalStatus)?.label}
                  fieldStub="maritalStatus"
                />
                {sortCustomFields(data.customUpdates).map(
                  (f) =>
                    !f.field.isHidden && (
                      <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                    )
                )}

                {userFamilyMembers?.map((member) => {
                  return <FieldStructure key={member.id} fieldTitle={member.type} fieldValue={member.name} />;
                })}
              </Box>
            )}
          </Box>
          <ScopesControl scopes={[SCOPE]} context={scopesContext}>
            <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
              <FamilyForm
                userId={userId}
                initialValues={data}
                onSubmit={(values) => {
                  setDataO(O.some(values));
                  setIsOpen(false);
                  onSubmit(values);
                }}
                userFamilyMembers={userFamilyMembers ?? []}
                refreshMembers={refreshMembers}
                onClose={() => setIsOpen(false)}
                handleRefresh={() => {}}
              />
            </DrawerModal>
          </ScopesControl>
        </ProfileCard>
      )
    )
  );
};
