import React from 'react';

import { v4 } from 'uuid';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ReviewQuestionAPI } from '@/v2/feature/growth/reviews/api-client/review-question.api';
import { QuestionModalContent } from '@/v2/feature/growth/reviews/features/components/question-modal-content.component';
import { ReviewQuestion, ReviewQuestionUpsert } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { ReviewSection } from '@/v2/feature/growth/reviews/interfaces/review-section.interface';

type CommonCycleAndBankQuestion = Pick<
  ReviewQuestion,
  | 'id'
  | 'questionMain'
  | 'questionSelf'
  | 'type'
  | 'factor'
  | 'answerOptions'
  | 'reviewerSelect'
  | 'isCommentRequired'
  | 'visibilitySettings'
  | 'hasComment'
  | 'scaleConfig'
>;
export const CycleCreationQuestionFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  question,
  refresh,
  cycleId,
  section,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  question: ReviewQuestion | null;
  refresh: () => Promise<void>;
  cycleId: string;
  section?: ReviewSection | null | undefined;
}) => {
  const onSubmit = async (values: CommonCycleAndBankQuestion) => {
    if (question?.id) {
      //update question
      const questionValues = {
        ...question,
        ...values,
        cycleId,
        sectionId: section?.id ?? null,
      };
      await ReviewQuestionAPI.updateQuestionInCycle(questionValues as ReviewQuestionUpsert);
    } else {
      const questionValues: ReviewQuestionUpsert = {
        ...values,
        id: v4(),
        cycleId,
        sectionId: section?.id ?? null,
      };
      await ReviewQuestionAPI.createAndCloneQuestionInCycle(questionValues as ReviewQuestionUpsert);
    }
  };
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <QuestionModalContent
        onClose={onClose}
        question={question as CommonCycleAndBankQuestion}
        refresh={refresh}
        onSubmit={onSubmit}
      />
    </DrawerModal>
  );
};
