import React from 'react';

import { Box, Typography } from '@mui/material';
import { JsonViewer } from '@textea/json-viewer';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';

import { AuditTrailDto } from '../security.interface';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { toTitleCase } from '@/v2/util/string.util';

interface AuditReportEntryDetailDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly entry: AuditTrailDto | undefined;
}

export const AuditReportEntryDetailDrawer = ({
  isOpen,
  setIsOpen,
  entry,
}: AuditReportEntryDetailDrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} widthPercentage={50}>
    {entry ? (
      <Box>
        <Typography sx={titleSx}>Audit entry - Payload</Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
          <i>{`${entry.action.toUpperCase()}`}</i> - <b>{`${toTitleCase(entry.domain)}`}</b>
        </Typography>
        <Box sx={{ mt: '20px' }}>
          <JsonViewer value={entry?.entityPayload} theme="dark" rootName="payload" />
        </Box>
      </Box>
    ) : (
      <></>
    )}
  </DrawerModal>
);
