import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { ButtonComponent } from './forms/button.component';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface RejectDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onReject: (rejectionNotes?: string) => Promise<void>;
}

interface RejectDrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onReject: (rejectionNotes?: string) => void;
}

export const RejectDrawer = ({ isOpen, setIsOpen, onReject }: RejectDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <RejectDrawerContent onReject={onReject} setIsOpen={setIsOpen} />
    </DrawerModal>
  );
};

export const RejectDrawerContent = ({ onReject, setIsOpen }: RejectDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [rejectionNotes, setRejectionNotes] = useState<string>('');

  const onRejectClick = useCallback(async () => {
    setLoading(true);
    await onReject(rejectionNotes);
    setLoading(false);
  }, [onReject, rejectionNotes]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('AbsenceRejectDrawer.note')}</Typography>
      <Typography variant="caption">{polyglot.t('AbsenceRejectDrawer.provideExplanation')}</Typography>

      <TextfieldComponent
        label={polyglot.t('AbsenceRejectDrawer.notes')}
        name="rejectionNotes"
        value={rejectionNotes}
        onChange={(e) => {
          setRejectionNotes(e.target.value ?? '');
        }}
        autoFocus
        clearText={() => setRejectionNotes('')}
      />

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('AbsenceRejectDrawer.reject')}
          loading={loading}
          onClick={onRejectClick}
          type="button"
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
        />
      </Box>
    </Box>
  );
};
