import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { themeColors } from '@v2/styles/colors.styles';
import { pluralText } from '@v2/util/string.util';
import { isValid } from 'date-fns';

import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { AbsenceAdjustmentImportDto } from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { iconSize } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
import { ValidationError } from '@/v2/infrastructure/api-error/api-error.interface';
import { formatShortDate } from '@/v2/infrastructure/date/date-format.util';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  rowData?: AbsenceAdjustmentImportDto;
  rowErrors: ValidationError[] | undefined;
  fieldKey: keyof AbsenceAdjustmentImportDto | 'validation';
  rawCellValue?: string;
  statusColumn?: number;
};

export const ImportedAbsenceAdjustmentTableCell = ({
  rowData,
  fieldKey,
  rowErrors,
  rawCellValue,
  statusColumn,
}: Props) => {
  const getErrorTextForTooltip = (errorObj: ValidationError) => {
    return Object.keys(errorObj.constraints)
      .map((eachKey) => errorObj.constraints[eachKey])
      .join(', ');
  };

  const errorForColumn = rowErrors?.find((e) => e.property === fieldKey);

  const getStatus = (): React.JSX.Element => {
    if (statusColumn === 0)
      return (
        <>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          &nbsp; Valid
        </>
      );

    if (statusColumn && statusColumn > 0)
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <MistakeIcon {...iconSize} />
          &nbsp; {statusColumn} issue{pluralText(statusColumn)}
        </Box>
      );

    return <></>;
  };

  const getValueForCell = () => {
    if (!rowData && rawCellValue && rawCellValue.length > 0) return rawCellValue;
    if (fieldKey === 'validation' || fieldKey === 'id') return rawCellValue;
    if (!rowData || !rowData[fieldKey]) return '<empty>';

    if (
      rowData &&
      rowData[fieldKey] &&
      !rawCellValue &&
      Object.keys(rowData)?.length > 0 &&
      fieldKey &&
      ((typeof rowData[fieldKey] === 'string' && (rowData[fieldKey] as string).length > 0) ||
        (typeof rowData[fieldKey] === 'number' && ((rowData[fieldKey] as unknown) as number) >= 0))
    ) {
      return rowData[fieldKey];
    }

    if (
      rowData &&
      !rawCellValue &&
      fieldKey &&
      Object.keys(rowData)?.length > 0 &&
      typeof rowData[fieldKey] === 'object' &&
      isValid(rowData[fieldKey])
    )
      return formatShortDate((rowData[fieldKey]! as unknown) as Date);

    return '<empty>';
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography variant="caption" sx={{ mr: spacing.mr10 }}>
        {statusColumn === 0 || (statusColumn && statusColumn >= 0) ? getStatus() : getValueForCell()}
      </Typography>
      {errorForColumn && (
        <StyledTooltip title={getErrorTextForTooltip(errorForColumn)}>
          <MistakeIcon {...iconSize} />
        </StyledTooltip>
      )}
    </Box>
  );
};
