import React from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { Form, FormikProvider } from 'formik';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly formik: any;
}

export const VatNumberModal = ({ setOpen, open, formik }: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  return (
    <DrawerModal isOpen={open} setIsOpen={setOpen}>
      <FormikProvider value={formik}>
        <Form>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                paddingBottom: spacing.p10,
              }}
            >
              <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('InvoiceTaxModal.title')}</Typography>
            </Box>

            <Box sx={{ mb: spacing.mb20 }}>
              {formik.values.from ? (
                <UserCell userId={formik.values.from} />
              ) : (
                <Typography sx={{ ...themeFonts.title4 }}>
                  {polyglot.t('InvoiceTaxModal.employeeNotSelected')}
                </Typography>
              )}
            </Box>
            <FormControl sx={{ ...fieldSx, mb: spacing.mb20 }} size="small" fullWidth>
              <TextfieldComponent
                name="vatNumber"
                label="VAT number"
                value={formik.values.vatNumber}
                onChange={formik.handleChange}
                error={formik.touched.vatNumber && Boolean(formik.errors.vatNumber)}
                helperText={(formik.touched.vatNumber && formik.errors.vatNumber) as string}
                clearText={() => formik.setFieldValue('vatNumber', '')}
              />
            </FormControl>

            <FormControl sx={{ ...fieldSx, mb: spacing.mb20 }} size="small" fullWidth>
              <TextfieldComponent
                name="taxId"
                label="Tax ID"
                value={formik.values.taxId}
                onChange={formik.handleChange}
                error={formik.touched.taxId && Boolean(formik.errors.taxId)}
                helperText={(formik.touched.taxId && formik.errors.taxId) as string}
                clearText={() => formik.setFieldValue('taxId', '')}
              />
            </FormControl>

            <FormControl sx={{ ...fieldSx, mb: spacing.mb20 }} size="small" fullWidth>
              <LoaderButton
                fullWidth
                name={polyglot.t('General.save')}
                loading={false}
                colorVariant="primary"
                sizeVariant="large"
                onClick={() => setOpen(false)}
              />
            </FormControl>
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
