import React, { useContext, useMemo, useState } from 'react';

import { GlobalContext } from '@/GlobalState';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthQuestionLibraryContent } from '@/v2/feature/growth/shared/components/growth-question-library-content.component';
import { SurveyQuestionBankEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-question-bank.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';

export const SurveyCycleCreationQuestionSelectModal = ({
  isQuestionsModalOpen,
  setIsQuestionsModalOpen,
  action,
}: {
  isQuestionsModalOpen: boolean;
  setIsQuestionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: (selectedQuestionIds: Set<string>) => Promise<void>;
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = user?.company?.name ?? undefined;

  const { data: questions, isLoading: questionsLoading } = useApiClient(
    SurveyQuestionBankEndpoints.getSurveyQuestionFromBank(searchInput, filterString),
    {
      suspense: false,
    }
  );

  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const factorFilterTypes = useMemo(() => {
    if (!allGrowthFactors) return undefined;

    return {
      factor: allGrowthFactors?.map((factor) => {
        return { label: factor.name, value: factor.name };
      }),
    };
  }, [allGrowthFactors]);

  const commonQuestionArray = useMemo(() => {
    if (!questions) return [];

    return questions.map((q) => {
      return {
        id: q.id,
        questionText:
          q.questionText && q.questionText.length > 0
            ? replaceParamsInQuestionText(stripHtml(q.questionText), { company_name })
            : stripHtml(q.questionText),
        factor: q.factor,
      };
    });
  }, [questions, company_name]);

  return (
    <DrawerModal
      isOpen={isQuestionsModalOpen}
      setIsOpen={setIsQuestionsModalOpen}
      onClose={() => {
        setIsQuestionsModalOpen(false);
      }}
      loading={questionsLoading}
      widthPercentage={95}
      sx={{
        px: 0,
      }}
    >
      <GrowthQuestionLibraryContent
        questions={commonQuestionArray}
        setIsQuestionsModalOpen={setIsQuestionsModalOpen}
        action={action}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        allGrowthFactors={allGrowthFactors}
        setFilterString={setFilterString}
        filterString={filterString}
        factorFilterTypes={factorFilterTypes}
        questionsLoading={Boolean(questionsLoading)}
      />
    </DrawerModal>
  );
};
