import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { InsuranceMeOverviewPage } from '@v2/feature/benefits/subfeature/insurance/pages/insurance-me-overview.page';
import { matchPath, Redirect, Switch, useLocation } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  INSURANCE_ME_OVERVIEW_ROUTE,
  USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
} from '@/lib/routes';

interface InsuranceMeRouterProps {
  readonly userId: number;
  readonly userInsurance: UserInsuranceDto | null;
  readonly refresh: () => Promise<void>;
  readonly loading: boolean;
}

export const InsuranceUserRouter = ({ userId, userInsurance, refresh, loading }: InsuranceMeRouterProps) => {
  const location = useLocation();
  return (
    <Switch>
      {userInsurance && (
        <RouteScopesControl
          scopes={['insurance']}
          context={{ userId }}
          path={[INSURANCE_ME_OVERVIEW_ROUTE, USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE]}
        >
          <InsuranceMeOverviewPage userId={userId} userInsurance={userInsurance} refresh={refresh} loading={loading} />
        </RouteScopesControl>
      )}

      {matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE) ? (
        <Redirect to={USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE} />
      ) : (
        <Redirect to={INSURANCE_ME_OVERVIEW_ROUTE} />
      )}
    </Switch>
  );
};
