import { useCallback } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { UserHeader } from '@/v2/components/user-header.component';
import { getOptionalPayCodesInUse } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { ExternalCustomPayCode } from '@/v2/feature/payroll/payroll-external.dto';
import { ExternalPayLine } from '@/v2/feature/payroll/payroll-external.interface';
import { PayRunEntryDto } from '@/v2/feature/payroll/payroll.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { sum } from '@/v2/util/array.util';
import { formatCurrency } from '@/v2/util/currency-format.util';

type SalarySummaryRowProps = {
  description: string;
  amount?: number;
  subtract?: boolean;
  hideIfZero?: boolean;
  sx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
};

const SummaryRow = ({ description, amount, subtract, hideIfZero, sx, textSx }: SalarySummaryRowProps) => {
  if (hideIfZero && !amount) {
    return <></>;
  }
  const numericAmount = (amount ?? 0) * (subtract ? -1 : 1);
  return (
    <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between', gap: spacing.g10, ...sx }}>
      <Typography sx={{ ...themeFonts.caption, ...textSx }}>{description}</Typography>
      <Typography sx={{ ...themeFonts.caption, ...textSx }}>{formatCurrency(numericAmount)}</Typography>
    </Stack>
  );
};

type SalarySummaryProps = {
  user: CachedUser;
  payrunEntry: PayRunEntryDto;
  customPayCodes?: ExternalCustomPayCode[];
};

export const SalarySummaryPage = ({ user, payrunEntry, customPayCodes }: SalarySummaryProps) => {
  const getOptionalPayCodeSummaryRows = useCallback(
    (
      payrunEntry: PayRunEntryDto,
      paycodes: ExternalCustomPayCode[] | undefined,
      kind: 'addition' | 'deduction',
      taxBasis: 'pretax' | 'posttax'
    ) => {
      const BASICPayCodeFirst = (a: ExternalPayLine, b: ExternalPayLine) => {
        if (a.code === 'BASIC') return b.code === 'BASIC' ? 0 : -1;
        if (b.code === 'BASIC') return a.code === 'BASIC' ? 0 : +1;
        return 0;
      };
      return getOptionalPayCodesInUse(paycodes ?? [], [payrunEntry], kind === 'deduction')
        .filter(
          taxBasis === 'pretax'
            ? (paycode) => paycode.isTaxable || paycode.isNiable || paycode.isPensionable
            : (paycode) => !(paycode.isTaxable || paycode.isNiable || paycode.isPensionable)
        )
        .flatMap(({ code }) =>
          // extract all the pay lines matching the pay code
          payrunEntry.payOptions.regularPayLines.filter(
            (payline) => payline.code === code && !payline.isAutoGeneratedBasicPayLine
          )
        )
        .sort(
          (a, b) =>
            // group paycodes together (with BASIC always first so it is next to the normal salary) and then sort by their display name
            BASICPayCodeFirst(a, b) ||
            a.code.localeCompare(b.code) ||
            (a.description || a.code).localeCompare(b.description || b.code, undefined, { sensitivity: 'base' })
        )
        .map((pl) => (
          <SummaryRow
            description={pl.description || pl.code}
            amount={pl.value}
            subtract={kind === 'deduction'}
            hideIfZero
          />
        ));
    },
    []
  );

  const getRegularPaylineTotal = useCallback(
    (paylineCode: string): number | undefined => {
      const entries = payrunEntry.payOptions.regularPayLines.filter(({ code }) => code === paylineCode);
      return entries.length ? sum(entries, ({ value }) => value) : undefined;
    },
    [payrunEntry.payOptions.regularPayLines]
  );

  return (
    <Stack>
      <UserHeader user={user} />

      <Stack sx={{ gap: spacing.g40, mt: spacing.mt40 }}>
        <Stack sx={{ gap: spacing.g15 }}>
          <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between' }}>
            <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>Description</Typography>
            <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>Amount</Typography>
          </Stack>
          <SummaryRow
            description={`${payrunEntry.payPeriod} salary`}
            amount={
              payrunEntry.payOptions.regularPayLines.find(
                ({ isAutoGeneratedBasicPayLine }) => isAutoGeneratedBasicPayLine
              )?.value ?? 0
            }
          />
          {getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, 'addition', 'pretax')}
          {getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, 'deduction', 'pretax')}
          <SummaryRow
            description="Pension contribution"
            amount={getRegularPaylineTotal('PENSION') ?? getRegularPaylineTotal('PENSIONSS')}
            subtract
            hideIfZero={getRegularPaylineTotal('PENSIONRAS') !== undefined}
          />
          <SummaryRow
            description="Gross"
            amount={payrunEntry.totals.gross}
            textSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}
          />
        </Stack>

        <Stack sx={{ gap: spacing.g15 }}>
          <SummaryRow description="National insurance" amount={payrunEntry.totals.employeeNi} subtract />
          <SummaryRow description="PAYE tax" amount={payrunEntry.totals.tax} subtract />
          <SummaryRow
            description="Net pay"
            amount={payrunEntry.totals.netPay}
            textSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}
          />
        </Stack>

        <Stack sx={{ gap: spacing.g15 }}>
          {getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, 'addition', 'posttax')}
          {getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, 'deduction', 'posttax')}
          <SummaryRow description="Student loan" amount={payrunEntry.totals.studentLoanRecovered} subtract hideIfZero />
          <SummaryRow
            description="Postgraduate loan"
            amount={payrunEntry.totals.postgradLoanRecovered}
            subtract
            hideIfZero
          />
          <SummaryRow
            description="Pension contribution"
            amount={getRegularPaylineTotal('PENSIONRAS')}
            subtract
            hideIfZero={getRegularPaylineTotal('PENSIONRAS') === undefined}
          />
          <SummaryRow
            description="Take home pay"
            amount={payrunEntry.totals.takeHomePay}
            textSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}
          />
        </Stack>

        <Stack sx={{ gap: spacing.g15 }}>
          <SummaryRow
            description="Employer NI"
            amount={payrunEntry.totals.employerNi}
            textSx={{ color: themeColors.Grey }}
          />
          <SummaryRow
            description="Employer Pension"
            amount={payrunEntry.totals.employerPensionContribution}
            textSx={{ color: themeColors.Grey }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
