import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import cover from '@/images/app-empty.svg';
import { CurrentUser } from '@/models';
import { ChartColumnSingle } from '@/v2/components/charts/chart-column-single.component';
import { ChartPie } from '@/v2/components/charts/chart-pie.component';
import { EmptyStateComponent } from '@/v2/components/empty-state.component';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppIntegrationStub, AppKPI } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { seriesDataExists } from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { AppInsightsPlaceholder } from '@/v2/feature/app-integration/features/app-details/components/app-insights-placeholder.component';
import { AppNameHeader } from '@/v2/feature/app-integration/features/app-details/components/app-name-header.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useLocalStorage } from '@/v2/util/use-local-storage.util';

interface AppInsightsProps {
  readonly currentUser: CurrentUser;
  readonly appStub: AppIntegrationStub;
  readonly app: InstalledAppDto | undefined;
}

export const AppInsightsPage = ({ currentUser, appStub, app }: AppInsightsProps) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const [showMessage] = useMessage();
  const [loadingKpis, setLoadingKpis] = useState<boolean>(false);
  const [kpisForApp, setKpisForApp] = useLocalStorage<AppKPI>(
    `${appStub}-kpisForApp-${currentUser?.company?.companyId}`,
    {
      companyId: undefined,
      kpi: [],
    }
  );

  const gridsForKPIs = useMemo(() => {
    let gridToReturn: JSX.Element[] = [];
    if (!kpisForApp || kpisForApp?.kpi.length === 0) return <></>;
    kpisForApp.kpi.forEach((eachKPI, index) => {
      // empty divider grid column for every 2nd column
      if (index % 2 !== 0) {
        gridToReturn.push(<Grid item xs={0} md={1} lg={1} />);
      }
      if (eachKPI.type === 'bar') {
        gridToReturn.push(
          <Grid item xs={12} md={5} lg={5}>
            {seriesDataExists(eachKPI) ? (
              <>
                <Box>
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>{eachKPI.name}</Typography>
                </Box>
                <ChartColumnSingle series={eachKPI.series} categories={eachKPI.categories ?? []} />
              </>
            ) : (
              <AppInsightsPlaceholder title={eachKPI.name} />
            )}
          </Grid>
        );
      }
      if (eachKPI.type === 'horizontalStackedBar') {
        gridToReturn.push(
          <Grid item xs={12} md={5} lg={5}>
            {seriesDataExists(eachKPI) ? (
              <>
                <Box>
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>{eachKPI.name}</Typography>
                </Box>
                <ChartColumnSingle
                  series={eachKPI.series}
                  categories={eachKPI.categories ?? []}
                  horizontal={true}
                  stacked={true}
                />
              </>
            ) : (
              <AppInsightsPlaceholder title={eachKPI.name} />
            )}
          </Grid>
        );
      }
      if (eachKPI.type === 'pie') {
        gridToReturn.push(
          <Grid item xs={12} md={5} lg={5}>
            {eachKPI.series.length > 0 ? (
              <>
                <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>{eachKPI.name}</Typography>
                <ChartPie series={eachKPI.series} labels={eachKPI.labels ?? []} />
              </>
            ) : (
              <AppInsightsPlaceholder title={eachKPI.name} />
            )}
          </Grid>
        );
      }
    });
    return gridToReturn;
  }, [kpisForApp]);

  const fetchData = useCallback(
    async (loading = true) => {
      if (app?.authorised && app?.hasKPIs) {
        try {
          if (loading) setLoadingKpis(true);
          const insightData = await AppDetailsAPI.getKPIsForApp(appStub);
          setKpisForApp(insightData);
        } catch (error) {
          showMessage('Failed to fetch app insight data', 'error');
        } finally {
          if (loading) setLoadingKpis(false);
        }
      }
    },
    [app?.authorised, app?.hasKPIs, appStub, setKpisForApp, showMessage]
  );

  useEffect(() => {
    // if no cached data exists in localStorage, fetch new data with loader visible, otherwise silently refresh data
    fetchData(!kpisForApp || kpisForApp?.kpi.length === 0 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.hasKPIs, app?.authorised, appStub]);

  return (
    <RootStyle>
      <TopHeader
        title={<AppNameHeader title={polyglot.t('AppInsightsPage.title')} app={app} />}
        showAction={!app?.authorised && currentUser && hasScopes(['apps'], { userId: currentUser.userId })}
      />
      <ContentWrapper loading={loadingKpis}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Insights</Typography>
        <Grid container spacing={2} sx={{ mt: spacing.mt10 }}>
          {gridsForKPIs}
        </Grid>
        <Box>
          {kpisForApp?.kpi?.length === 0 && (
            <EmptyStateComponent
              header="No KPIs"
              subheader="Zelt gives you valuable insights into your app usage"
              detail="Currently there isn't enough data to provide insights; Please check back later."
              cover={cover}
            />
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
