import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import {
  ActionSelectionGroupComponent,
  ActionSelectionItem,
  ActionSelectionItemDeleteButton,
  ActionSelectionItemEditButton,
} from '@/v2/components/action-selection-group.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { RequestTemplateComponent } from '@/v2/feature/requests/features/request-forms/components/request-form.component';
import { AttachmentComponentDrawer } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/components/attachment-component-drawer.component';
import { DateComponentDrawer } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/components/date-component-drawer.component';
import { SelectComponentDrawer } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/components/select-component-drawer.component';
import { TextComponentDrawer } from '@/v2/feature/requests/features/request-forms/new-request-form/sections/components/text-component-drawer.component';
import {
  RequestFormComponentItem,
  RequestFormLayout,
} from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type FormPageProps = {
  initialForm?: RequestFormLayout;
  onNext?: (values: RequestFormLayout) => void;
};

export const FormBuilderPage = ({ initialForm, onNext }: FormPageProps) => {
  const { polyglot } = usePolyglot();

  const [form, setForm] = useState<RequestFormLayout>(
    initialForm ?? {
      items: [],
    }
  );
  const [componentDrawer, setDrawer] = useState({ drawer: <></>, open: false });

  const updateFormComponent = useCallback((component: RequestFormComponentItem) => {
    setForm((current) => {
      const newItems = current.items.slice();
      const idx = current.items.findIndex((item) => item.id === component.id);
      if (idx < 0) {
        // new component
        newItems.push(component);
      } else {
        // updated component
        newItems.splice(idx, 1, component);
      }
      return { ...current, items: newItems };
    });
  }, []);

  const deleteComponentFromForm = useCallback((component: RequestFormComponentItem) => {
    setForm((current) => ({
      ...current,
      items: current.items.filter((item) => item !== component),
    }));
  }, []);

  const updateFormComponentAndCloseDrawer = useCallback(
    (component: RequestFormComponentItem) => {
      updateFormComponent(component);
      setDrawer(componentDrawer && { ...componentDrawer, open: false });
    },
    [componentDrawer, updateFormComponent]
  );

  const getDrawerForComponent = useCallback(
    (component: RequestFormComponentItem) => {
      switch (component.type) {
        case 'select':
          return <SelectComponentDrawer value={component} onSave={updateFormComponentAndCloseDrawer} />;
        case 'text':
          return <TextComponentDrawer value={component} onSave={updateFormComponentAndCloseDrawer} />;
        case 'attachment':
          return <AttachmentComponentDrawer value={component} onSave={updateFormComponentAndCloseDrawer} />;
        case 'date':
          return <DateComponentDrawer value={component} onSave={updateFormComponentAndCloseDrawer} />;
      }
      return <></>;
    },
    [updateFormComponentAndCloseDrawer]
  );

  const editComponent = useCallback(
    (component: RequestFormComponentItem) => {
      setDrawer({ drawer: getDrawerForComponent(component), open: true });
    },
    [getDrawerForComponent]
  );

  const FormComponents = useMemo(
    () => [
      <ActionSelectionItem
        step={{
          title: polyglot.t('FormBuilderPage.datePicker'),
          description: polyglot.t('FormBuilderPage.datePickerDesc'),
        }}
        onAddClick={() =>
          setDrawer({ drawer: <DateComponentDrawer onSave={updateFormComponentAndCloseDrawer} />, open: true })
        }
        sx={{ py: spacing.py20 }}
      />,
      <ActionSelectionItem
        step={{
          title: polyglot.t('FormBuilderPage.attachment'),
          description: polyglot.t('FormBuilderPage.attachmentDesc'),
        }}
        onAddClick={() =>
          setDrawer({ drawer: <AttachmentComponentDrawer onSave={updateFormComponentAndCloseDrawer} />, open: true })
        }
        sx={{ py: spacing.py20 }}
      />,
      <ActionSelectionItem
        step={{
          title: polyglot.t('FormBuilderPage.textField'),
          description: polyglot.t('FormBuilderPage.textFieldDesc'),
        }}
        onAddClick={() =>
          setDrawer({ drawer: <TextComponentDrawer onSave={updateFormComponentAndCloseDrawer} />, open: true })
        }
        sx={{ py: spacing.py20 }}
      />,
      <ActionSelectionItem
        step={{
          title: polyglot.t('FormBuilderPage.selectField'),
          description: polyglot.t('FormBuilderPage.selectFieldDesc'),
        }}
        onAddClick={() =>
          setDrawer({ drawer: <SelectComponentDrawer onSave={updateFormComponentAndCloseDrawer} />, open: true })
        }
        sx={{ py: spacing.py20 }}
      />,
    ],
    [updateFormComponentAndCloseDrawer, polyglot]
  );

  const formComponentViews = useMemo(() => {
    return form.items.map((item) => (
      <Stack sx={{ py: '5px', gap: spacing.g2 }}>
        <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g20 }}>
          <RequestTemplateComponent component={item} disabled />
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g2, ml: 'auto' }}>
            <ActionSelectionItemEditButton sx={{ ml: 'auto' }} onClick={() => editComponent(item)} />
            <ActionSelectionItemDeleteButton onClick={() => deleteComponentFromForm(item)} />
          </Stack>
        </Stack>
        {'required' in item && (
          <Typography
            variant="captionSmall"
            sx={{ color: themeColors.Grey, visibility: item.required ? 'visible' : 'hidden' }}
          >
            {polyglot.t('FormBuilderPage.required')}
          </Typography>
        )}
      </Stack>
    ));
  }, [deleteComponentFromForm, editComponent, form.items, polyglot]);

  return (
    <>
      <Stack sx={{ flex: 1, alignItems: 'center', width: '100%' }}>
        <Stack sx={{ flexFlow: 'row', pt: spacing.p30, px: spacing.p30, width: 'inherit', overflow: 'hidden' }}>
          <ActionSelectionGroupComponent
            title={polyglot.t('FormBuilderPage.component')}
            actions={FormComponents}
            emptyActionsMessage={polyglot.t('FormBuilderPage.componentEmptyActions')}
          />
          <Stack
            sx={{
              px: spacing.p30,
              borderRadius: radius.br15,
              maxWidth: '400px',
              flex: 1,
              mr: 'auto',
              gap: spacing.g10,
            }}
          >
            <Typography variant="title2">{polyglot.t('FormBuilderPage.form')}</Typography>
            <Typography variant="caption">{polyglot.t('FormBuilderPage.createForm')}</Typography>
            <Stack sx={{ gap: spacing.g5, maxHeight: '400px', overflowY: 'auto' }}>{formComponentViews}</Stack>
            {form.items.length > 0 && (
              <ButtonComponent
                fullWidth
                type="button"
                onClick={() => {
                  onNext?.(form);
                }}
                sizeVariant="large"
                colorVariant="primary"
                style={{ flexShrink: 0, marginTop: '20px' }}
              >
                {polyglot.t('General.continue')}{' '}
              </ButtonComponent>
            )}
          </Stack>
        </Stack>
      </Stack>
      <DrawerModal isOpen={componentDrawer.open} setIsOpen={() => setDrawer({ ...componentDrawer, open: false })}>
        {componentDrawer.drawer}
      </DrawerModal>
    </>
  );
};
