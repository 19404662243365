import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { LocalDate } from '@v2/util/local-date';
import Bull from 'bull';

import { ReactComponent as AppsIcon } from '@/images/offboarding/Apps.svg';
import { ReactComponent as DeviceIcon } from '@/images/offboarding/Device.svg';
import { ReactComponent as InsuranceIcon } from '@/images/offboarding/Insurance.svg';
import { ReactComponent as PensionIcon } from '@/images/offboarding/Pension.svg';
import { ReactComponent as WalletIcon } from '@/images/offboarding/Wallet.svg';
import { ReactComponent as Clock } from '@/images/side-bar-icons/Clock.svg';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { InstalledAppDto, UserAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { getScheduledActionDateString } from '@/v2/feature/app-integration/app-integration.util';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import { MoneyEnum, ProgressStatus } from '@/v2/feature/offboarding/offboarding.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const getOffboardingTask = (key: string, value: number | boolean | undefined): string => {
  switch (key) {
    case 'devices':
      return `Return ${value} ${Number(value) > 1 ? 'devices' : 'devices'}`;

    case 'apps':
      return value && Number(value) > 1 ? `Disconnect from ${value} apps` : `Disconnect from ${value} app`;

    case 'inPayroll':
    case 'inPension':
    case 'inInsurance':
      return `Offboard from ${MoneyEnum[key]}`;

    default:
      return '';
  }
};

export const getOffboardingTaskIcon = (key: string) => {
  switch (key) {
    case 'devices':
      return <DeviceIcon />;
    case 'apps':
      return <AppsIcon />;
    case 'inPayroll':
      return <WalletIcon />;
    case 'inPension':
      return <PensionIcon />;
    case 'inInsurance':
      return <InsuranceIcon />;

    default:
      return <></>;
  }
};

export function userStatusIs(status: string | null | undefined, choices: string[]) {
  if (!status) return false;
  return choices.includes(status);
}

export const getActionDate = (date?: Date | string | undefined): string | undefined => {
  const dateObj = new LocalDate(date);
  return dateObj.toDateString() === new LocalDate().toDateString() ? undefined : dateObj.toDateString();
};

export const getStatusToIcon = (status: ProgressStatus) => {
  switch (status) {
    case ProgressStatus.None:
      return (
        <Typography variant="caption" color="Grey">
          –
        </Typography>
      );

    case ProgressStatus.Pending:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <Clock {...iconSize} fill={themeColors.Grey} />
          <Typography variant="caption" color="Grey">
            Pending
          </Typography>
        </Box>
      );

    case ProgressStatus.Completed:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <OkGreen {...iconSize} fill={themeColors.Green} />
          <Typography variant="caption">Completed</Typography>
        </Box>
      );

    case ProgressStatus.Missing:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <Mistake {...iconSize} fill={themeColors.Red} />
          <Typography variant="caption" color="Red">
            Missing
          </Typography>
        </Box>
      );

    default:
      return (
        <Typography variant="caption" color="Grey">
          –
        </Typography>
      );
  }
};

export function getUserAppDtoForInstalledAppViaScheduledAction(
  installedApp: InstalledAppDto,
  matchingAction: Bull.Job<CreateAppQueue> | undefined
): UserAppDto {
  const userAppDto: UserAppDto = {
    stub: installedApp.stub,
    userStatus: matchingAction ? getScheduledActionDateString(matchingAction) : '',
    scheduledAction: true,
  };
  return userAppDto;
}
