import React, { MouseEventHandler } from 'react';

import { styled, SxProps, Theme } from '@mui/material/styles';
import truncate from 'html-truncate';

import { themeColors } from '@/v2/styles/colors.styles';

const TypographyComponent = styled('p')`
  margin: 0;
  padding: 0;
`;

export type TypographyVariant =
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'title5'
  | 'bodyLarge'
  | 'caption'
  | 'captionSmall'
  | 'bodyTiny';

export interface TypographyProps {
  variant: TypographyVariant;
  children?: React.ReactNode;
  className?: string;
  color?: keyof typeof themeColors;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler | undefined;
  id?: string;
  dangerouslySetInnerHTML?: { __html: string };
  truncateHtml?: boolean;
  title?: string;
}

export const Typography: React.FC<TypographyProps> = ({
  id,
  variant,
  sx = {},
  className = '',
  color,
  children,
  onClick,
  dangerouslySetInnerHTML,
  truncateHtml,
  title,
}) => {
  return (
    <TypographyComponent
      onClick={onClick}
      className={`${className} ${variant}`}
      sx={{
        color: color && themeColors[color],
        ...sx,
        '& > p': {
          padding: '0px',
          margin: '0px',
        },
      }}
      id={id}
      title={title}
      dangerouslySetInnerHTML={
        truncateHtml && dangerouslySetInnerHTML
          ? { __html: truncate(dangerouslySetInnerHTML.__html, 1, { ellipsis: '...', truncateLastWord: false }) }
          : dangerouslySetInnerHTML
      }
    >
      {dangerouslySetInnerHTML ? null : children}
    </TypographyComponent>
  );
};
