import React, { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { UserPayslips } from '@/component/dashboard/userDetails/payroll/UserPayslips';
import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { PAYROLL_ME_ROUTE, PAYROLL_ME_SUMMARY_ROUTE } from '@/lib/routes';
import { UserOverviewConfig } from '@/models';

interface PayrollMeProps {
  readonly userOverviewConfig: UserOverviewConfig;
}

export const PayrollMeRouter = ({ userOverviewConfig }: PayrollMeProps): JSX.Element => {
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  return (
    <Switch>
      <RouteScopesControl exact path={PAYROLL_ME_SUMMARY_ROUTE} scopes={['payroll:read']} context={scopesContext}>
        <UserPayslips userId={userId} userOverviewConfig={userOverviewConfig} />
      </RouteScopesControl>

      {globalState.user?.contractType === 'Employee' && (
        <Redirect from={PAYROLL_ME_ROUTE} to={PAYROLL_ME_SUMMARY_ROUTE} />
      )}
    </Switch>
  );
};
