import { useState } from 'react';

import { Stack } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';

import { PayrollTestModeSettings } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-settings-test-mode.component';
import { PayrollEmploymentAllowanceSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/employer-allowance-settings/payroll-employment-allowance-section.component';
import { PayrollSettingHmrcSetting } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-setting.component';
import { PayrollCompanyHMRCSetupPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-hmrc-setup.page';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
  readonly refreshPayroll: () => Promise<void>;
}

export const PayrollCompanySettingsHMRC = ({ payroll, refreshPayroll }: Props): JSX.Element => {
  const [edit, setEdit] = useState(false);
  const [otherEdit, setOtherEdit] = useState(false);
  const [connectHMRC, setConnectHMRC] = useState(false);

  if (connectHMRC) {
    return (
      <RootStyle>
        {payroll.employer && (
          <PayrollCompanyHMRCSetupPage
            payrollId={payroll.id}
            payroll={payroll.employer}
            onClose={async () => {
              await refreshPayroll();
              setConnectHMRC(false);
            }}
          />
        )}
      </RootStyle>
    );
  }

  return (
    <Stack sx={{ maxWidth: '650px', gap: spacing.g40, my: spacing.my20, mr: spacing.mr20 }}>
      {payroll?.employer && (
        <PayrollSettingHmrcSetting
          payrollId={payroll.id}
          employer={payroll.employer}
          setEdit={setEdit}
          edit={edit}
          disable={otherEdit}
          refreshPayroll={refreshPayroll}
          onConnectClick={() => setConnectHMRC(true)}
        />
      )}
      {payroll?.employer && <PayrollEmploymentAllowanceSection payrollId={payroll.id} setOtherEdit={setOtherEdit} />}
      {payroll?.employer && (
        <PayrollTestModeSettings
          payrollId={payroll.id}
          disable={edit || otherEdit}
          refreshPayroll={refreshPayroll}
          testMode={!!payroll.employer.rtiSubmissionSettings.useTestGateway}
        />
      )}
    </Stack>
  );
};
