import { useMemo } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { FiltersListSection } from '@v2/feature/reports/reports-advanced/components/filters-list.section';
import {
  ReportFilterCategory,
  ReportFilterSelected,
  SelectedFiltersRequest,
} from '@v2/feature/reports/reports.interface';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';

interface Props {
  readonly selectedFilters: SelectedFiltersRequest;
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFiltersRequest>>;
  readonly openAddFilters: () => void;
  readonly editFilter: (selectedFilter: ReportFilterSelected) => void;
  readonly reportFilters: readonly ReportFilterCategory[];
}

export const ReportFilters = ({
  selectedFilters,
  setSelectedFilters,
  openAddFilters,
  editFilter,
  reportFilters,
}: Props) => {
  const hasFilters = useMemo(() => {
    return Object.keys(selectedFilters).length > 0;
  }, [selectedFilters]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      {hasFilters ? (
        <FiltersListSection
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          editFilter={editFilter}
          reportFilters={reportFilters}
        />
      ) : (
        <Typography variant="caption">No filters selected</Typography>
      )}

      <Box sx={{ pb: spacing.s1 }}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="dashed"
          style={{ marginTop: '10px' }}
          fullWidth
          onClick={openAddFilters}
          startIcon={<Plus {...iconSize} />}
        >
          Add filters
        </ButtonComponent>
      </Box>
    </Box>
  );
};
