import { SelectOption } from '@/v2/infrastructure/forms/forms.interface';

export enum EntityImportWizardData {
  Users = 'users',
  Absences = 'absences',
  AbsenceAdjustments = 'absenceAdjustments',
  Payroll = 'payroll',
  Devices = 'devices',
  Attendances = 'attendances',
}

export enum EntityImportWizardSource {
  CSV = 'csv',
  Google = 'google',
  AzureAD = 'azureAD',
  List = 'list',
  Xero = 'xero',
  Quickbooks = 'quickbooks',
}

const csvSourceOption: SelectOption = {
  label: 'CSV Import',
  value: EntityImportWizardSource.CSV,
};

// Enter manually option is no longer being used - removed for now
// const listSourceOption: SelectOption = {
//   label: 'Enter manually',
//   value: EntityImportWizardSource.List,
// };

const googleSourceOption: SelectOption = {
  label: 'Google Workspace',
  value: EntityImportWizardSource.Google,
};

const azureADSourceOption: SelectOption = {
  label: 'Azure AD',
  value: EntityImportWizardSource.AzureAD,
};

// const xeroSourceOption: SelectOption = {
//   label: 'Xero',
//   value: EntityImportWizardSource.Xero,
// };

// const quickbooksSourceOption: SelectOption = {
//   label: 'QuickBooks',
//   value: EntityImportWizardSource.Quickbooks,
// };

export const EntityImportWizardSourceOptions: Record<EntityImportWizardData, SelectOption[]> = {
  [EntityImportWizardData.Users]: [azureADSourceOption, googleSourceOption, csvSourceOption],
  [EntityImportWizardData.Absences]: [csvSourceOption],
  [EntityImportWizardData.AbsenceAdjustments]: [csvSourceOption],
  [EntityImportWizardData.Payroll]: [csvSourceOption],
  [EntityImportWizardData.Devices]: [csvSourceOption],
  [EntityImportWizardData.Attendances]: [csvSourceOption],
};

export interface EntityImportResultPageProps<T> {
  goNext: (result: T) => void;
  goBack: () => void;
  importSource?: string;
}
