import {
  AttendanceImportDto,
  AttendanceImportResultDto,
} from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import axios from 'axios';

export class AttendanceImportAPI {
  static async importViaCSVInstant(users: readonly AttendanceImportDto[]): Promise<AttendanceImportResultDto> {
    return (await axios.post('/apiv2/attendances/import/csv/instant', users)).data;
  }

  static async importViaCSVUsingQueue(file: ArrayBuffer): Promise<boolean> {
    return (await axios.post('/apiv2/attendances/import/csv/queue', file)).data;
  }
}
