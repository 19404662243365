import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { SSOMetadataForSuperadminTable } from '@/v2/feature/super-admin/features/super-admin-companies/super-admin-companies.page';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly ssoMetadata: SSOMetadataForSuperadminTable[];
  readonly companyId: number;
  afterAction: () => Promise<void>;
}

export const DisableSSOConfirmDrawer = ({ isOpen, setIsOpen, ssoMetadata, companyId, afterAction }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <DisableSSOConfirmDrawerContent
        ssoMetadata={ssoMetadata}
        companyId={companyId}
        setIsOpen={setIsOpen}
        afterAction={afterAction}
      />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly ssoMetadata: SSOMetadataForSuperadminTable[];
  readonly companyId: number;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  afterAction: () => Promise<void>;
}

export const DisableSSOConfirmDrawerContent = ({
  ssoMetadata,
  companyId,
  setIsOpen,
  afterAction,
}: DrawerContentProps) => {
  const [showMessage] = useMessage();
  const [isDelete, setIsDelete] = useState(false);

  const disableSSOForCompany = useCallback(async () => {
    try {
      setIsDelete(true);
      if (!ssoMetadata || ssoMetadata.length === 0) return;
      // cannot have more than 1 SSO app configured at a time, so take first one
      const firstElementOfSSOState = ssoMetadata[0];
      const { appStub } = firstElementOfSSOState;
      let result = false;
      switch (appStub) {
        case 'sso-google-workspace':
          await AppDetailsAPI.superadminDisableSSOGW(companyId);
          result = true;
          break;
        case 'sso-azure-ad':
          await AppDetailsAPI.superAdminDisableSSOAzureAdSaml(companyId);
          result = true;
          break;
        case 'sso-okta':
          await AppDetailsAPI.superAdminDisableSSOOkta(companyId);
          result = true;
          break;
        default:
          console.warn('SSO App not configured - cannot disable ', ssoMetadata);
          result = false;
          break;
      }
      if (result) {
        showMessage(`SSO successfully disabled for company Id: ${companyId}`, 'success');
      }
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)})`, 'error');
    } finally {
      setIsDelete(false);
      afterAction();
    }
  }, [afterAction, companyId, showMessage, ssoMetadata]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Disable SSO</Typography>

      <Typography variant="caption">Are you sure you want to disable SSO for companyId: {companyId}?</Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          Cancel
        </ButtonComponent>
        <LoaderButton
          name={'Disable'}
          sizeVariant="medium"
          colorVariant="danger"
          loading={isDelete}
          onClick={disableSSOForCompany}
          fullWidth
        />
      </Box>
    </Box>
  );
};
