import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { MultipleSelectCheckbox } from '@v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import {
  isAllowanceBenefit,
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import {
  EditableUserCustomBenefitDto,
  UserCustomBenefitDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getAdditionalMembersOptions } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
  readonly onClose: () => void;
}

export const UpdateUserCustomBenefitDrawer = ({ isOpen, setIsOpen, userBenefit, refresh, onClose }: DrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(UserEndpoints.getUserFamilyMembers(userBenefit.userId), {
    suspense: false,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <UpdateUserCustomBenefitDrawerContent
        userBenefit={userBenefit}
        refresh={refresh}
        setIsOpen={setIsOpen}
        userFamilyMembers={userFamilyMembers ?? []}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userFamilyMembers: UserFamilyMemberDto[];
}

export const UpdateUserCustomBenefitDrawerContent = ({
  userBenefit,
  refresh,
  setIsOpen,
  userFamilyMembers,
}: DrawerContentProps): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { polyglot } = usePolyglot();
  const [mode, setMode] = useState<'delete' | null>(null);

  const hasInsuranceAll = hasScopes(['insurance']);
  const isRecurring = isRecurringBenefit(userBenefit.customBenefit?.type);
  const isAllowance = isAllowanceBenefit(userBenefit.customBenefit?.type);
  const isLoan = isLoanBenefit(userBenefit.customBenefit?.type);

  const onSubmit = useCallback(
    async (values: EditableUserCustomBenefitDto) => {
      if (!userBenefit.customBenefit?.type) {
        showMessage('Could not determine the benefit type.', 'error');
        return;
      }
      const hasOpeningBalance = isUsingOpeningBalance(userBenefit.customBenefit.type);

      const data: EditableUserCustomBenefitDto = {
        employerContribution: values.employerContribution ? Number(values.employerContribution) : null,
        employeeContribution: values.employeeContribution ? Number(values.employeeContribution) : null,
        dependants: values.dependants ? values.dependants : null,
        dependantsList: values.dependants ? values.dependantsList ?? null : null,
        openingBalance: hasOpeningBalance ? Number(values.openingBalance) : null,
        numberOfInstallments:
          userBenefit.customBenefit?.type === CustomBenefitType.Loan ? Number(values.numberOfInstallments) : null,
      };
      try {
        setLoading(true);
        await CustomBenefitAPI.updateUserCustomBenefitById(
          userBenefit.userId,
          userBenefit.customBenefitId,
          userBenefit.id,
          data
        );

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [polyglot, refresh, setIsOpen, showMessage, userBenefit]
  );

  const formik = useFormik<EditableUserCustomBenefitDto>({
    initialValues: {
      employerContribution: userBenefit?.employerContribution ?? null,
      employeeContribution: userBenefit?.employeeContribution ?? null,
      dependants: userBenefit?.dependants ?? null,
      dependantsList: userBenefit?.dependantsList ?? null,
      openingBalance: userBenefit?.openingBalance ?? null,
      numberOfInstallments: userBenefit?.numberOfInstallments ?? null,
    },
    validationSchema: yup.object({
      employerContribution: yup
        .number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .nullable()
        .notRequired(),
      employeeContribution: yup
        .number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .nullable()
        .notRequired(),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup
        .array()
        .of(yup.number().integer().typeError(polyglot.t('ValidationMessages.validValue')))
        .nullable()
        .notRequired(),
      openingBalance: yup.number().typeError(polyglot.t('ValidationMessages.validValue')).nullable().notRequired(),
      numberOfInstallments: yup
        .number()
        .integer(polyglot.t('ValidationMessages.validValue'))
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .nullable()
        .notRequired(),
    }),
    onSubmit,
  });

  const onDelete = useCallback(async () => {
    try {
      await CustomBenefitAPI.deleteUserCustomBenefit(userBenefit.userId, userBenefit.customBenefitId, userBenefit.id);
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, userBenefit, refresh, setIsOpen, showMessage]);

  const dependantsListOptions = useMemo(() => {
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (
        userFamilyMembers
          ?.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type))
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (
        userFamilyMembers
          ?.filter((m) => m.type === UserFamilyMemberType.Child)
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    return userFamilyMembers?.map((m) => ({ value: m.id, label: m.name })) ?? [];
  }, [userFamilyMembers, formik.values.dependants]);

  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];

    return formik.values.dependantsList
      .map((id) => {
        const familyMember = userFamilyMembers.find((m) => m.id === id);

        return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
      })
      .filter(Boolean) as { label: string; value: number }[];
  }, [formik.values.dependantsList, userFamilyMembers]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', gap: spacing.g5, justifyContent: 'space-between' }}>
          <Typography variant="title2">
            {userBenefit.customBenefit?.name ?? polyglot.t('BenefitModule.userBenefit')}
          </Typography>

          {hasInsuranceAll && (
            <IconButton
              sx={iconButtonSx}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setMode('delete');
                e.stopPropagation();
              }}
            >
              <Trash {...iconSize} />
            </IconButton>
          )}
        </Box>
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
            {polyglot.t('General.employee')}
          </Typography>
          <UserCell userId={userBenefit.userId} sx={{ mt: spacing.m5 }} nameSx={{ ...themeFonts.title4 }} />
        </Box>
        <Box sx={{ my: spacing.m20 }}>
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('General.effectiveDate')}
          </Typography>
          <Typography variant="caption" color="DarkGrey" sx={{ fontWeight: '500' }}>
            {userBenefit.effectiveDate}
          </Typography>
        </Box>

        {(isAllowance || isLoan) && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="openingBalance"
              label={polyglot.t('General.amount')}
              value={formik.values.openingBalance}
              onChange={formik.handleChange}
              error={formik.touched.openingBalance && !!formik.errors.openingBalance}
              helperText={(formik.touched.openingBalance && formik.errors.openingBalance) ?? ' '}
              endAdornment="none"
            />
          </Box>
        )}

        {isLoan && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="numberOfInstallments"
              label={polyglot.t('BenefitModule.numberOfInstallments')}
              value={formik.values.numberOfInstallments}
              onChange={formik.handleChange}
              error={formik.touched.numberOfInstallments && !!formik.errors.numberOfInstallments}
              helperText={(formik.touched.numberOfInstallments && formik.errors.numberOfInstallments) ?? ' '}
              endAdornment="none"
            />
          </Box>
        )}

        {isRecurring && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="employerContribution"
              label={polyglot.t('BenefitModule.employerContribution')}
              value={formik.values.employerContribution}
              onChange={formik.handleChange}
              error={formik.touched.employerContribution && !!formik.errors.employerContribution}
              helperText={(formik.touched.employerContribution && formik.errors.employerContribution) ?? ' '}
              endAdornment="none"
            />
          </Box>
        )}
        {isRecurring && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="employeeContribution"
              label={polyglot.t('BenefitModule.employeeContribution')}
              value={formik.values.employeeContribution}
              onChange={formik.handleChange}
              error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
              helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) ?? ' '}
              endAdornment="none"
            />
          </Box>
        )}
        {isRecurring && userFamilyMembers.length > 0 ? (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <SelectComponent
              name="dependants"
              label={polyglot.t('BenefitModule.additionalMembers')}
              options={[{ label: polyglot.t('General.none'), value: '' }, ...getAdditionalMembersOptions(polyglot)]}
              value={formik.values.dependants}
              onChange={formik.handleChange}
              error={!!formik.errors.dependants && formik.touched.dependants}
              helperText={formik.touched.dependants && (formik.errors.dependants as string)}
            />
          </Box>
        ) : isRecurring ? (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <Typography variant="captionSmall">{polyglot.t('BenefitModule.additionalMembers')}</Typography>
            <Typography variant="caption">{polyglot.t('BenefitModule.includeAdditionalBeneficiaries')}</Typography>
          </Box>
        ) : null}
        {isRecurring && userFamilyMembers.length > 0 && formik.values.dependants && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <MultipleSelectCheckbox<number>
              label={polyglot.t('BenefitModule.additionalMembersList')}
              id="dependantsList"
              limitTags={-1}
              options={dependantsListOptions}
              value={selectedDependantsList}
              onChange={(_, values) => {
                const updatedOptionList = values.map(({ value }) => value);
                formik.setFieldValue('dependantsList', updatedOptionList);
              }}
              isOptionEqualToValue={(x, y) => x.value === y.value}
              getOptionLabel={({ label }: { label: string }): string => label}
            />
          </Box>
        )}
        {hasInsuranceAll && (
          <Box sx={{ mt: spacing.m40 }}>
            <LoaderButton
              name={polyglot.t('General.update')}
              loading={loading}
              fullWidth
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        )}
        <NotificationModal
          isOpen={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setTimeout(() => setMode(null), 400);
          }}
          anchorEl={anchorEl}
          takeAction={async () => {
            if (mode === 'delete') await onDelete();
            setMode(null);
          }}
          message={polyglot.t('BenefitModule.deleteUserFromBenefit')}
          callToAction={polyglot.t('General.yes')}
        />
      </Form>
    </FormikProvider>
  );
};
