import { Box, SxProps, Theme } from '@mui/material';

import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';

export const LabelValueViewItem = ({
  label,
  value,
  sx,
  labelFont = 'caption',
}: {
  label: string;
  value: string | JSX.Element;
  sx?: SxProps<Theme>;
  labelFont?: TypographyVariant;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Typography variant={labelFont}>{label}</Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};
