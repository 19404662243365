import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import * as datefns from 'date-fns';
import { identity } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/lib/ReadonlyArray';

import { EquityAPI } from '@/api-client/equity.api';
import { UserEquity } from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { useProfileFormUpdate } from '@/v2/feature/user/context/user-profile.context';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import { ShowHideButton } from '@/v2/feature/user/features/user-profile/details/components/show-hide-button.component';
import {
  cardHeaderSx,
  cardMaxWidth,
  definitionListSx,
  definitionValueSx,
  showTableSx,
  tablecardSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { EquityForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-equity-form.component';
import { translateequityTypeOptions } from '@/v2/infrastructure/i18n/translate.util';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { createShortDateAsUTC, timeAgo } from '@/v2/util/date-format.util';

const iconSize = { width: 14, height: 14 } as const;
const EquityTableAllowedColumns = ['actions', 'percentageVested'];

const calculateFirstVestingCliff = (vestingCliffList: readonly VestingCliffType[]): string => {
  const sortedVesting = vestingCliffList
    .filter((x) => !!x.vestingCliffDate)
    .map((x) => x.vestingCliffDate)
    .sort((a: string | null, b: string | null) => {
      if (!a) return -1;
      if (!b) return 1;
      return new Date(a).getTime() - new Date(b).getTime();
    });
  if (sortedVesting.length === 0) return '';
  if (sortedVesting[0] === null) return '';
  if (datefns.isValid(new Date(sortedVesting[0])) && !timeAgo(sortedVesting[0])) return '';
  else if (timeAgo(sortedVesting[0])) {
    return timeAgo(sortedVesting[0]) || '';
  }
  return '';
};

const getRemainingVestingList = (vestingCliffList: readonly VestingCliffType[]): string[] => {
  return vestingCliffList
    .map((x) => x.vestingCliffDate!)
    .filter((y) => !!y && datefns.isBefore(new Date(), datefns.parseISO(new Date(y).toISOString())));
};

const calculateVestingCompleted = (remainingVestingList: readonly string[]): string => {
  if (!remainingVestingList.length) return '';
  const vestingCompleted = new Date(Math.max(...remainingVestingList.map((a) => new Date(a).getTime())));

  if (datefns.isValid(new Date(vestingCompleted)) && !timeAgo(vestingCompleted.toISOString())) return '';
  else if (timeAgo(vestingCompleted.toISOString())) return timeAgo(vestingCompleted.toISOString()) || '';
  else return '';
};

const getVestingCliffList = RA.map(
  (eachVesting: UserEquity) =>
    ({
      vestingStart: eachVesting.vestingStart ? new Date(eachVesting.vestingStart) : null,
      vestingCliffDate: eachVesting.vestingStart
        ? createShortDateAsUTC(datefns.add(new Date(eachVesting.vestingStart), { months: eachVesting.vestingCliff }))
        : null,
      id: eachVesting.id,
      vestingMonths: eachVesting.vestingCliff,
      type: eachVesting.type,
    } as const)
);
const getEachVestingComplete = (vestingCliff: UserEquity): number => {
  if (!vestingCliff?.vestingStart) return 0;

  const daysPassed = Math.abs(datefns.differenceInDays(new Date(vestingCliff?.vestingStart), new Date()));
  const totalDays = (vestingCliff?.vestingPeriod ?? 0) * 30;
  const cliffDays = (vestingCliff?.vestingCliff ?? 0) * 30;
  const vestEndDate = datefns.addDays(new Date(vestingCliff?.vestingStart), totalDays);
  if (daysPassed < cliffDays) return 0;
  if (datefns.isBefore(vestEndDate, new Date())) {
    return 100;
  }
  if (totalDays === 0) return 100;
  else {
    const remainingAmount = (daysPassed / totalDays) * vestingCliff.amount;
    return (remainingAmount / vestingCliff.amount) * 100;
  }
};

const getTotalVestingComplete = (userEquity: readonly UserEquity[]): number => {
  if (userEquity.length < 1) return 0;
  else {
    const totalAmount = userEquity.reduce((sum, b) => sum + b.amount, 0);
    const weightedSum = userEquity.reduce((sum, b) => sum + (getEachVestingComplete(b) / 100) * b.amount, 0);
    return (weightedSum / totalAmount) * 100;
  }
};

const getCompletionDate = (start: Date, days: number): Date => {
  return datefns.addDays(start, days);
};

const getMaxCompletionDate = (userEquity: readonly UserEquity[]): Date | null | string => {
  const completionDates: Date[] = userEquity
    .filter((entry) => !!entry.vestingStart)
    .map((entry) => getCompletionDate(new Date(entry.vestingStart!), (entry.vestingPeriod ?? 0) * 30));
  return datefns.max(completionDates);
};

const SCOPE = 'user.equity:all' as const;

enum EquityTypeOptionsStatus {
  Share = 'Share',
  Option = 'Option',
  RSU = 'RSU',
}

enum CurrencyOptionsStatus {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR',
}

const numberFormatter = Intl.NumberFormat('en-US');

interface VestingCliffType {
  readonly vestingStart: Date | null;
  readonly vestingCliffDate: string | null;
  readonly id: number;
  readonly vestingMonths: number | undefined;
  readonly type: string;
}

export const UserEquityForm = ({ userId, handleSubmit = () => {} }: UserProfileCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [rowModalMode, setRowModalMode] = useState<string>('add');
  const [rawEquityList, setRawEquityList] = useState<readonly UserEquity[]>([]);
  const [isEmpty, setEmpty] = useState<boolean>(false);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const setFormCurrentlyEditing = useProfileFormUpdate();

  const defaultBlankModalValues: Partial<UserEquity> = {
    id: 0,
    grantDate: undefined,
    type: EquityTypeOptionsStatus.Share,
    amount: 0,
    vestingStart: undefined,
    vestingPeriod: 0,
    vestingCliff: 0,
    unitPrice: 0,
    currency: CurrencyOptionsStatus.GBP,
    reason: '',
  };

  const [modalInitialValues, setModalInitialValues] = useState<Partial<UserEquity>>(defaultBlankModalValues);

  const refreshUserEquityData = useCallback(async (): Promise<void> => {
    if (!userId) return;
    setLoading(true);
    try {
      const userEquity = await EquityAPI.getByUserId(userId);
      setEmpty(userEquity && userEquity.length < 1);
      setLoading(false);
      setRawEquityList(userEquity);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t('UserEquityForm.errorMessages.load')}: ${error}`, 'error');
    }
  }, [polyglot, userId, showMessage]);

  useEffect(() => {
    refreshUserEquityData();
  }, [refreshUserEquityData]);

  const editEquityRow = useCallback((rowData: UserEquity) => {
    setModalInitialValues(rowData);
    setIsModalOpen(true);
  }, []);

  const addEquityRow = () => {
    setModalInitialValues(defaultBlankModalValues);
    setRowModalMode('add');
    setIsModalOpen(true);
  };

  const columnData = useMemo<ColumnDef<UserEquity, UserEquity>[]>(() => {
    const keysAllowed = rawEquityList && rawEquityList[0] ? new Set(Object.keys(rawEquityList[0])) : null;
    return ([
      {
        header: () => polyglot.t('UserEquityForm.grantDate'),
        accessorFn: identity,
        id: 'grantDate',
        enableSorting: false,
        size: 100,
        cell: ({
          row: {
            original: { grantDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {grantDate ? <DateLabelComponent date={grantDate} /> : '-'}
          </Box>
        ),
      },
      {
        header: () => polyglot.t('UserEquityForm.type'),
        accessorFn: identity,
        id: 'type',
        size: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{translateequityTypeOptions(original.type ?? '', polyglot)}</div>,
      },
      {
        header: () => polyglot.t('UserEquityForm.amount'),
        accessorFn: identity,
        id: 'amount',
        size: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div>{original.amount ? `${numberFormatter.format(original.amount)}` : ''}</div>
        ),
      },
      {
        header: () => polyglot.t('UserEquityForm.vestingStart'),
        accessorFn: identity,
        id: 'vestingStart',
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => (
          <div>{original.vestingStart ? <DateLabelComponent date={original.vestingStart} /> : ''}</div>
        ),
      },
      {
        header: () => polyglot.t('UserEquityForm.vestingCliff'),
        accessorFn: identity,
        id: 'vestingCliff',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.vestingCliff ? `${original.vestingCliff} months` : ''}</div>,
      },
      {
        header: () => polyglot.t('UserEquityForm.vestingPeriod'),
        accessorFn: identity,
        id: 'vestingPeriod',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.vestingPeriod ? `${original.vestingPeriod} months` : ''}</div>,
      },
      {
        header: () => polyglot.t('UserEquityForm.exercisePrice'),
        accessorFn: identity,
        id: 'unitPrice',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.unitPrice ? original.unitPrice : ''}</div>,
      },
      {
        header: () => polyglot.t('UserEquityForm.percentageVested'),
        accessorFn: identity,
        id: 'percentageVested',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original ? getEachVestingComplete(original)?.toFixed(2) : ''}%</div>,
      },
      {
        header: () => polyglot.t('UserEquityForm.reason'),
        accessorFn: identity,
        id: 'reason',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.reason ? original.reason : ''}</div>,
      },
      {
        header: () => polyglot.t('UserEquityForm.changedBy'),
        accessorFn: identity,
        id: 'updatedBy',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (original.updatedBy ? <UserCell userId={original.updatedBy} /> : ''),
      },
      {
        header: () => '',
        accessorFn: identity,
        id: 'actions',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ScopesControl scopes={[SCOPE]} context={scopesContext}>
              <IconButton
                title="edit"
                onClick={() => {
                  setRowModalMode('edit');
                  editEquityRow(original);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </ScopesControl>
          </div>
        ),
      },
    ] as ColumnDef<UserEquity, UserEquity>[]).filter(
      (col) =>
        (col.id && EquityTableAllowedColumns.includes(col.id)) || !keysAllowed || (col.id && keysAllowed.has(col.id))
    );
  }, [rawEquityList, polyglot, scopesContext, editEquityRow]);

  const equity = useMemo(() => {
    if (rawEquityList.length > 0) {
      const totalEquityAmount = rawEquityList.reduce((total: number, obj: { amount: number }) => obj.amount + total, 0);
      const vestingCliffList = getVestingCliffList(rawEquityList);
      const firstVestingCliff = calculateFirstVestingCliff(vestingCliffList);
      const remainingVestingCliff = getRemainingVestingList(vestingCliffList);
      const vestingCompleted = calculateVestingCompleted(remainingVestingCliff);
      const allGrantTypes = Array.from(new Set(vestingCliffList.map((a) => a.type))).join(', ');
      const totalVestingComplete = getTotalVestingComplete(rawEquityList);
      const completionDate = getMaxCompletionDate(rawEquityList);
      return {
        totalEquityAmount,
        vestingCliffList,
        firstVestingCliff,
        remainingVestingCliff,
        vestingCompleted,
        allGrantTypes,
        totalVestingComplete,
        completionDate,
      } as const;
    } else return null;
  }, [rawEquityList]);

  return (
    <ProfileCard fieldStubs={['equity.type', 'equity.amount', 'equity.vestingPeriod']} sx={tablecardSx}>
      <Box sx={{ ...cardMaxWidth }}>
        <Box component="header" sx={cardHeaderSx}>
          <Typography variant="h3" sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('UserEquityForm.equity')}
          </Typography>
          <ScopesControl scopes={[SCOPE]} context={scopesContext}>
            <Button key="add-leave" onClick={() => addEquityRow()} sx={secondaryCTABtn}>
              {polyglot.t('UserEquityForm.new')}
            </Button>
          </ScopesControl>
        </Box>
        <Box component="section">
          {isEmpty ? (
            <MissingInformationLabel />
          ) : (
            <Box>
              {equity && (
                <Box component="dl" sx={definitionListSx}>
                  <FieldStructure
                    fieldTitle={polyglot.t('UserEquityForm.grantTypes')}
                    fieldValue={translateequityTypeOptions(equity.allGrantTypes ?? '', polyglot)}
                    fieldStub="equity.type"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('UserEquityForm.totalEquity')}
                    fieldValue={numberFormatter.format(equity.totalEquityAmount) ?? ''}
                    fieldStub="equity.amount"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('UserEquityForm.firstVestingCliff')}
                    fieldValue={equity.firstVestingCliff}
                    fieldStub="equity.vestingPeriod"
                  />

                  <FieldStructure
                    fieldTitle={polyglot.t('UserEquityForm.vestingCompletionDate')}
                    fieldValue={
                      equity.completionDate && (
                        <DateLabelComponent date={equity.completionDate} titleSx={definitionValueSx} />
                      )
                    }
                    fieldStub="equity.vestingStart"
                  />

                  <FieldStructure
                    fieldTitle={polyglot.t('UserEquityForm.percentageVested')}
                    fieldValue={`${equity.totalVestingComplete.toFixed(2)}%`}
                    fieldStub="equity.amount"
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {!isEmpty && (
        <>
          <Box>
            <ShowHideButton showDetails={showDetails} setShowDetails={setShowDetails} />
          </Box>
          {showDetails && (
            <Box sx={showTableSx}>
              <BasicTable rowData={[...rawEquityList]} columnData={columnData} />
            </Box>
          )}
        </>
      )}
      <ScopesControl scopes={[SCOPE]} context={scopesContext}>
        <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <EquityForm
            initialValues={modalInitialValues}
            userId={userId}
            refreshUserEquityData={refreshUserEquityData}
            rowModalMode={rowModalMode}
            handleSubmit={handleSubmit}
            setFormCurrentlyEditing={setFormCurrentlyEditing}
            setIsModalOpen={setIsModalOpen}
            setLoading={setLoading}
            loading={loading}
            onClose={() => setIsModalOpen(false)}
          />
        </DrawerModal>
      </ScopesControl>
    </ProfileCard>
  );
};
