import { useCallback, useMemo, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as Edit, ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as HiddenEye } from '@/images/new-theme-icon/HiddenEye.svg';
import { ReactComponent as Lock } from '@/images/new-theme-icon/Lock.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CustomFieldDrawer } from '@/v2/feature/custom-fields/components/custom-field-drawer.component';
import { CustomFormDrawer } from '@/v2/feature/custom-fields/components/custom-form-drawer.component';
import {
  settingHeaderSx,
  settingIconSx,
  settingListSx,
  settingsMaxWidth,
  settingSx,
  settingTermSx,
  settingValueSx,
} from '@/v2/feature/custom-fields/components/styles.layout';
import { CustomProfileFormEndpoints } from '@/v2/feature/custom-fields/custom-profile-fields.api';
import {
  CustomProfileFieldDto,
  CustomProfileFieldType,
  CustomProfileFormDto,
} from '@/v2/feature/custom-fields/custom-profile-fields.dto';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { translateCustomField, translateCustomFieldCategory } from '@/v2/infrastructure/i18n/translate.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

const getTypeText = (type: string, polyglot: Polyglot) => {
  switch (type) {
    case 'Input':
      return polyglot.t('CompanyFieldsPage.getTypeTextInput');

    case 'Select':
      return polyglot.t('CompanyFieldsPage.getTypeTextSelect');

    case 'Date input':
      return polyglot.t('CompanyFieldsPage.getTypeTextDateInput');

    case 'Date picker':
      return polyglot.t('CompanyFieldsPage.getTypeTextDatePicker');

    case 'Number':
      return polyglot.t('CompanyFieldsPage.getTypeTextNumber');

    case 'Multiselect':
      return polyglot.t('CompanyFieldsPage.getTypeTextMultiselect');

    default:
      return type;
  }
};

const FormDisplayComponent = ({
  field,
  showIcon,
  setShowIcon,
  onEditClick,
}: {
  field: CustomProfileFieldDto;
  showIcon: string | null;
  setShowIcon: React.Dispatch<React.SetStateAction<string | null>>;
  onEditClick: () => void;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box
      sx={{
        ...settingSx,
        backgroundColor: showIcon === field.fieldId ? themeColors.TableHover : themeColors.white,
        borderRadius: radius.br10,
      }}
      onMouseEnter={() => setShowIcon(field.fieldId)}
      onMouseLeave={() => setShowIcon(null)}
    >
      <Typography sx={settingTermSx}>{translateCustomField(field.fieldName, polyglot)}</Typography>

      <Typography sx={settingValueSx}>
        {getTypeText(field.fieldType, polyglot)}
        &nbsp;
        {field.isRequired && polyglot.t('FormDisplayComponent.required')}
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          {field.isHidden && <HiddenEye fill={themeColors.Grey} {...iconSize} />}
        </Box>
      </Typography>
      <Box
        sx={{
          settingIconSx,
          visibility: showIcon === field.fieldId ? 'show' : 'hidden',
          padding: spacing.p5,
        }}
      >
        {field.isEditable ? (
          <IconButton
            sx={tableIconButtonSx}
            onClick={() => {
              onEditClick();
            }}
          >
            <Edit />
          </IconButton>
        ) : (
          <Lock fill={themeColors.Grey} {...iconSize} />
        )}
      </Box>
    </Box>
  );
};

export const CustomProfileFieldsPage = () => {
  const { polyglot } = usePolyglot();

  const params = useParams<{ tabId: string }>();
  const [editingField, setEditingField] = useState<{
    form: CustomProfileFormDto;
    field?: CustomProfileFieldDto;
    fieldType: CustomProfileFieldType;
  } | null>(null);
  const [editingForm, setEditingForm] = useState<CustomProfileFormDto | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewSectionOpen, setIsNewSectionOpen] = useState(false);
  const [showIcon, setShowIcon] = useState<string | null>(null);
  const routerHistory = useHistory();

  const { data: allForms, mutate: refreshForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false,
  });
  const { data: allFields, mutate: refreshFields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), {
    suspense: false,
  });

  const forms = useMemo(() => {
    return allForms?.filter(({ formTab }) => formTab === params.tabId);
  }, [allForms, params.tabId]);

  const applyChangedForm = useCallback(
    (update: { form: CustomProfileFormDto; change: 'created' | 'updated' | 'deleted' }) => {
      if (!allForms) return undefined;
      const updatedForms = [...allForms];
      const idx = updatedForms.findIndex((f) => f.formId === update.form.formId);
      ({
        created: () => updatedForms.push(update.form),
        deleted: () => idx >= 0 && updatedForms.splice(idx, 1),
        updated: () => idx >= 0 && (updatedForms[idx] = update.form),
      }[update.change]());
      return updatedForms;
    },
    [allForms]
  );

  const refresh = useCallback(
    async (formChange?: { form: CustomProfileFormDto; change: 'created' | 'updated' | 'deleted' }) => {
      const updatedForms = formChange && applyChangedForm(formChange);
      await Promise.all([refreshForms?.(updatedForms), refreshFields?.()]);
    },
    [applyChangedForm, refreshFields, refreshForms]
  );

  useEscapeKey(() => routerHistory.goBack());

  const getFieldOptions = (form: CustomProfileFormDto) => {
    return [
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.Input });
          setIsOpen(true);
        },
        label: polyglot.t('CompanyFieldsPage.inputField'),
        disabled: false,
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.Select });
          setIsOpen(true);
        },
        label: polyglot.t('CompanyFieldsPage.selectField'),
        disabled: false,
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.Number });
          setIsOpen(true);
        },
        label: polyglot.t('CompanyFieldsPage.numberField'),
        disabled: false,
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.DatePicker });
          setIsOpen(true);
        },
        label: polyglot.t('CompanyFieldsPage.datePicker'),
        disabled: false,
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.DateInput });
          setIsOpen(true);
        },
        label: polyglot.t('CompanyFieldsPage.dateInput'),
        disabled: false,
      },
    ];
  };

  // for now, put default forms first, followed by custom forms in alphabetical order
  // when forms get an 'order' property, this can be removed
  const [defaultForms, customForms] = useMemo(() => {
    const def: CustomProfileFormDto[] = [];
    const custom: CustomProfileFormDto[] = [];
    forms?.forEach((f) => (f.isDefault ? def.push(f) : custom.push(f)));
    def.sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
    // custom.sort((a, b) => a.formName.localeCompare(b.formName, undefined, { sensitivity: 'base' }));
    custom.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    return [def, custom];
  }, [forms]);

  const formNames = useMemo(() => forms?.map((f) => f.formName) ?? [], [forms]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, textTransform: 'capitalize' }}>
            {translateCustomFieldCategory(params.tabId, polyglot)}
          </Typography>
        }
        showBack
        actions={
          <ButtonComponent
            onClick={() => {
              setEditingForm(null);
              setIsNewSectionOpen(true);
            }}
            colorVariant="secondary"
            sizeVariant="small"
          >
            {polyglot.t('CompanyFieldsPage.newSection')}
          </ButtonComponent>
        }
        showAction
      />

      <ContentWrapper loading={!forms}>
        {[defaultForms, customForms].map((forms) =>
          forms?.map((form, index) => (
            <Box key={index} sx={{ paddingBottom: spacing.p60, ...settingsMaxWidth }}>
              <Box sx={settingHeaderSx}>
                <Typography sx={{ ...themeFonts.title2, textTransform: 'capitalize', color: themeColors.DarkGrey }}>
                  {translateCustomFieldCategory(form.formName, polyglot)}
                </Typography>

                <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
                  {!form.isDefault && (
                    <IconButton
                      sx={tableIconButtonSx}
                      onClick={() => {
                        setEditingForm(form);
                        setIsNewSectionOpen(true);
                      }}
                    >
                      <EditIcon {...actionIconSize} />
                    </IconButton>
                  )}
                  {form.supportsCustomFields && (
                    <StyledMenuComponent
                      options={getFieldOptions(form)}
                      actionButtonDetails={{
                        type: 'button',
                        colorVariant: 'secondary',
                        sizeVariant: 'small',
                        title: polyglot.t('CompanyFieldsPage.newField'),
                      }}
                    />
                  )}
                </Stack>
              </Box>

              <Box component="dl" sx={settingListSx}>
                {allFields?.dataFieldsDefault?.map(
                  (field) =>
                    field.formId === form.formId && (
                      <FormDisplayComponent
                        key={field.fieldId}
                        field={field}
                        showIcon={showIcon}
                        setShowIcon={setShowIcon}
                        onEditClick={() => {
                          setEditingField({
                            form,
                            field,
                            fieldType: field.fieldType,
                          });
                          setIsOpen(true);
                        }}
                      />
                    )
                )}

                {allFields?.dataFieldsCustom?.map(
                  (field) =>
                    field.formId === form.formId && (
                      <FormDisplayComponent
                        key={field.fieldId}
                        field={field}
                        showIcon={showIcon}
                        setShowIcon={setShowIcon}
                        onEditClick={() => {
                          setEditingField({
                            form,
                            field,
                            fieldType: field.fieldType,
                          });
                          setIsOpen(true);
                        }}
                      />
                    )
                )}
              </Box>
            </Box>
          ))
        )}

        {editingField && (
          <DrawerModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClose={() => {
              setIsOpen(false);
              setEditingField(null);
            }}
          >
            <CustomFieldDrawer
              form={editingField.form}
              fieldType={editingField.fieldType}
              selectedField={editingField.field}
              onClose={async () => {
                await refresh();
                setIsOpen(false);
                setEditingField(null);
              }}
            />
          </DrawerModal>
        )}

        <DrawerModal
          isOpen={isNewSectionOpen}
          setIsOpen={setIsNewSectionOpen}
          onClose={() => {
            setIsNewSectionOpen(false);
          }}
        >
          <CustomFormDrawer
            formTab={params.tabId as ProfileTab}
            form={editingForm}
            formNames={formNames}
            close={async (form: CustomProfileFormDto, change: 'created' | 'updated' | 'deleted') => {
              await refresh({ form, change });
              setIsNewSectionOpen(false);
            }}
          />
        </DrawerModal>
      </ContentWrapper>
    </RootStyle>
  );
};
