import { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AuthAPI } from '@v2/feature/auth/auth.api';
import { CompanyConfigAPI, CompanyConfigEndpoints } from '@v2/feature/company-config/company-config.api';
import { CompanyConfigDto } from '@v2/feature/company-config/company-config.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CompanySettingSectionHeader } from '@/v2/feature/company/company-settings/features/components/company-settings/entity-details/company-settings-section-header.component';

export const NotificationsTestingMode = () => {
  const { polyglot } = usePolyglot();

  const { data: companyConfig, mutate: refresh } = useApiClient<CompanyConfigDto, Error>(
    CompanyConfigEndpoints.getCompanyConfig(),
    { suspense: false }
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [, dispatch] = useContext(GlobalContext);

  const refreshTestModeSettings = useCallback(async () => {
    if (refresh) await refresh();
  }, [refresh]);

  useEffect(() => {
    refreshTestModeSettings();
  }, [refreshTestModeSettings]);

  const enableDisableTestMode = useCallback(async () => {
    try {
      setLoading(true);
      await CompanyConfigAPI.disableOrEnableTestMode();

      const [authMe] = await Promise.all([AuthAPI.getAuthMe(false), refreshTestModeSettings()]);
      dispatch({
        type: GlobalStateActions.UPDATE_TEST_MODE,
        payload: authMe.isTestModeEnabled,
      });
    } catch (error) {
      showMessage(
        `${polyglot.t('NotificationsTestingMode.errorMessages.notUpdated')}. ${nestErrorMessage(error)}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, refreshTestModeSettings, showMessage, polyglot]);

  return (
    <Box sx={{ maxWidth: '770px', width: '100%' }}>
      <CompanySettingSectionHeader
        title={polyglot.t('NotificationsTestingMode.title')}
        header={polyglot.t('NotificationsTestingMode.header')}
        subheader={polyglot.t('NotificationsTestingMode.subheader')}
        buttonName={
          companyConfig?.disableEmails
            ? polyglot.t('NotificationsTestingMode.disable')
            : polyglot.t('NotificationsTestingMode.enable')
        }
        onButtonClick={enableDisableTestMode}
        buttonLoading={loading}
      />
    </Box>
  );
};
