import { Dispatch, SetStateAction } from 'react';

import { Box, InputAdornment } from '@mui/material';
import { Form, FormikProvider } from 'formik';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { useSCTimelineForm } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/hook/use-sc-timeline-form.hook';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ISCRollingTimelineModal {
  readonly surveyCycle: SurveyCycle | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}

export const SCRollingTimelineModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: ISCRollingTimelineModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCRollingTimelineContent surveyCycle={surveyCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

const SCRollingTimelineContent = ({
  surveyCycle,
  onClose,
  refresh,
}: {
  surveyCycle: SurveyCycle | null | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const formik = useSCTimelineForm(surveyCycle, onClose, refresh);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Timeline</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
          <TextfieldComponent
            name="timelineSettings.surveyOpenFor"
            label={polyglot.t('SurveyParticipantDrawer.surveyOpenFor')}
            value={formik.values.timelineSettings.surveyOpenFor}
            onChange={formik.handleChange}
            error={formik.touched.timelineSettings?.surveyOpenFor && !!formik.errors.timelineSettings?.surveyOpenFor}
            helperText={
              (formik.touched.timelineSettings?.surveyOpenFor && formik.errors.timelineSettings?.surveyOpenFor) ?? ' '
            }
            endAdornment={
              <InputAdornment position="end">
                <Typography variant="caption">days</Typography>
              </InputAdornment>
            }
            type="number"
          />
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
