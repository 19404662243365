import { useState } from 'react';

import { ReviewsTodos } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { ReviewEntriesPersonalStartModal } from '@/v2/feature/growth/reviews/features/review-personal/review-entry/components/review-entries-personal-start-modal.component';

export const ReviewActionItem = ({
  reviewActionRow,
  afterClose,
}: {
  reviewActionRow: ReviewsTodos;
  afterClose: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(reviewActionRow));
  return (
    <ReviewEntriesPersonalStartModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      afterClose={afterClose}
      onClose={() => setIsOpen(false)}
      selectedEntry={reviewActionRow.entry}
    />
  );
};
