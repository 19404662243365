import { useState } from 'react';

import { Box, FormControl, IconButton, Typography } from '@mui/material';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { actionIconSize } from '@v2/styles/table.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { CalendarAPI } from '@/v2/feature/calendar/calendar.api';
import {
  AfternoonOnly,
  colorOptions,
  CreateCompanyEvent,
  FormData,
  fullOrAfternoonOnlyValues,
  fullOrHalfDayValues,
  fullOrMorningOnlyValues,
  MorningOnly,
} from '@/v2/feature/calendar/calendar.interface';
import { buttonBoxSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CalendarEventSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().nullable().notRequired(),
  startDate: Yup.string().test(dateFieldTest).required('Required'),
  endDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
  startFullOrHalfDay: Yup.string().required('Full or Half Day is required'),
  endFullOrHalfDay: Yup.string(),
  multipleDays: Yup.boolean(),
});

export const AddEventModal = ({ initialValues, onClose }: { initialValues: FormData; onClose: () => void }) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<FormData>({
    initialValues,
    enableReinitialize: true,
    validationSchema: CalendarEventSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const morningOnly = Boolean(
          (!values.endDate && values.startFullOrHalfDay === MorningOnly(polyglot).value) ||
            (values.endDate && values.endFullOrHalfDay === MorningOnly(polyglot).value)
        );
        const afternoonOnly = values.startFullOrHalfDay === AfternoonOnly(polyglot).value;
        const payload: CreateCompanyEvent = {
          name: values.name,
          description: values.description,
          afternoonOnly: afternoonOnly,
          morningOnly: morningOnly,
          startDate: values.startDate,
          endDate: values.multipleDays && values.endDate ? values.endDate : null,
          blockTimeawayRequests: values.blockTimeawayRequests,
          color: values.color ?? '#F5F5F5',
        };
        if (initialValues?.id) {
          await CalendarAPI.updateCalendarEvent(payload, initialValues.id);
        } else {
          await CalendarAPI.createCalendarEvent(payload);
        }
        onClose();
      } catch (error) {
        showMessage(polyglot.t('AddEventModal.errorMessages.create'), 'error');
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...spacing.mb20,
            }}
          >
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {initialValues?.id ? polyglot.t('AddEventModal.editEvent') : polyglot.t('AddEventModal.newEvent')}
            </Typography>
            {initialValues?.id && (
              <IconButton
                sx={iconButtonSx}
                onClick={async () => {
                  if (initialValues?.id) {
                    await CalendarAPI.deleteCalendarEvent(initialValues?.id);
                    onClose();
                    showMessage(polyglot.t('AddEventModal.successMessages.delete'), 'success');
                  }
                }}
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            <Box sx={fieldSx}>
              <Box sx={{ display: 'flex', gap: spacing.g10 }}>
                <Box sx={{ width: '20%' }}>
                  <SelectComponent
                    name="color"
                    label={polyglot.t('AddEventModal.color')}
                    options={colorOptions}
                    value={formik.values.color}
                    compareValue={formik.values.color}
                    error={!!formik.errors.color && formik.touched.color}
                    onChange={formik.handleChange}
                    helperText={formik.errors.color && formik.touched.color}
                  />
                </Box>
                <Box sx={{ width: '80%' }}>
                  <TextfieldComponent
                    name="name"
                    label={polyglot.t('AddEventModal.name')}
                    value={formik.values.name}
                    type="text"
                    onChange={formik.handleChange}
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={(formik.touched.name && formik.errors.name) ?? ' '}
                    clearText={() => formik.setFieldValue('name', '')}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={fieldSx}>
              <TextfieldComponent
                multiline
                name="description"
                label={polyglot.t('AddEventModal.description')}
                value={formik.values.description}
                type="text"
                onChange={formik.handleChange}
                error={formik.touched.description && !!formik.errors.description}
                helperText={(formik.touched.description && formik.errors.description) ?? ' '}
                clearText={() => formik.setFieldValue('description', '')}
              />
            </Box>

            <Box sx={{ minHeight: 20, mb: spacing.m5, display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
              <CheckboxComponent
                name="multipleDays"
                label={polyglot.t('AddEventModal.multipleDays')}
                checked={formik.values.multipleDays}
                onChange={(_, checked) => {
                  const isChecked = checked;

                  formik.setFieldValue('multipleDays', checked);
                  if (!isChecked) {
                    formik.setFieldValue('endDate', null);
                  } else {
                    const nextDate = new LocalDate(formik.values.startDate);
                    nextDate.getDate().setDate(nextDate.getDate().getDate() + 1);
                    formik.setFieldValue('endDate', nextDate.toDateString());
                  }
                }}
              />
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}></Typography>
            </Box>

            <Box sx={fieldSx}>
              <FormControl size="small" fullWidth>
                <DatePickerComponent
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.startDate ?? null}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      formik.setFieldValue('startDate', value);
                    }
                  }}
                  name="startDate"
                  label={polyglot.t('AddEventModal.startDate')}
                  error={!!formik.errors.startDate && Boolean(formik.touched.startDate)}
                  helperText={formik.errors.startDate && Boolean(formik.touched.startDate)}
                />
              </FormControl>
            </Box>

            <Box sx={fieldSx}>
              <SelectComponent
                name="startFullOrHalfDay"
                label={polyglot.t('AddEventModal.startFullOrHalfDay')}
                options={
                  formik.values.multipleDays ? fullOrAfternoonOnlyValues(polyglot) : fullOrHalfDayValues(polyglot)
                }
                value={formik.values.startFullOrHalfDay}
                compareValue={formik.values.startFullOrHalfDay}
                error={!!formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay}
                onChange={formik.handleChange}
                helperText={formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay}
              />
            </Box>

            {formik.values.multipleDays && (
              <>
                <Box sx={fieldSx}>
                  <FormControl size="small" fullWidth>
                    <DatePickerComponent
                      inputFormat="DD/MM/YYYY"
                      value={formik.values.endDate ?? null}
                      onChange={(value) => {
                        if (dayjs(value).isValid()) {
                          formik.setFieldValue('endDate', value);
                        }
                      }}
                      label={polyglot.t('AddEventModal.endDate')}
                      name="endDate"
                      error={!!formik.errors.endDate && Boolean(formik.touched.endDate)}
                      helperText={formik.errors.endDate && Boolean(formik.touched.endDate)}
                    />
                  </FormControl>
                </Box>

                <Box sx={fieldSx}>
                  <SelectComponent
                    name="endFullOrHalfDay"
                    label={polyglot.t('AddEventModal.endFullOrHalfDay')}
                    options={
                      formik.values.multipleDays ? fullOrMorningOnlyValues(polyglot) : fullOrHalfDayValues(polyglot)
                    }
                    value={formik.values.endFullOrHalfDay}
                    compareValue={formik.values.endFullOrHalfDay}
                    error={!!formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay}
                    onChange={formik.handleChange}
                    helperText={formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay}
                  />
                </Box>
              </>
            )}

            <Box sx={{ minHeight: 20, mb: spacing.m5, display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
              <CheckboxComponent
                name="blockTimeawayRequests"
                label={polyglot.t('AddEventModal.blockTimeawayRequests')}
                checked={formik.values.blockTimeawayRequests}
                onChange={(_, checked) => {
                  formik.setFieldValue('blockTimeawayRequests', checked);
                }}
              />
              <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}></Typography>
            </Box>
          </Box>
          <Box sx={buttonBoxSx}>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
