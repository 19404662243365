import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { ChipComponentWithIcon } from '@v2/components/chip/chip-with-icon.component';
import { ChipComponent } from '@v2/components/chip/chip.component';
import { ColumnsDrawer, ColumnsFilterOption } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer, FiltersOption } from '@v2/components/table/filters-drawer.component';
import { DevicesTable } from '@v2/feature/device/components/devices-table/devices-table.component';
import { ConfigurableDeviceData } from '@v2/feature/device/device.dto';
import { ConfigurableDeviceStatus } from '@v2/feature/device/device.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useJune } from '@v2/infrastructure/june/june.hook';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/table.styles';
import { isDefined } from '@v2/util/string.util';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DEVICE_DETAILS_ROUTE } from '@/lib/routes';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import '@/v2/feature/user/pages/components/peeple-list-view.scss';
import { UserAPI } from '@/v2/feature/user/user.api';

const DEVICE_DIRECTORY_COLUMNS_OPTIONS: string[] = [
  'model',
  'usedBy',
  'deviceName',
  'serialNumber',
  'modelNumber',
  'lastActive',
  'status',
  'enrolment',
  'storage',
  'osVersion',
  'customerNotes',
];

const filtersConfig: { columnOptions: ColumnsFilterOption[]; filterOptions: FiltersOption } = {
  columnOptions: [
    { label: 'Device', value: 'device' },
    { label: 'Device name', value: 'deviceName' },
    { label: 'Used by', value: 'usedBy' },
    { label: 'Serial number', value: 'serialNumber' },
    { label: 'Model', value: 'model' },
    { label: 'Last active', value: 'lastActive' },
    { label: 'Status', value: 'status' },
    { label: 'Enrolment', value: 'enrolment' },
    { label: 'Device storage', value: 'storage' },
    { label: 'OS version', value: 'osVersion' },
    { label: 'Customer notes', value: 'customerNotes' },
  ],
  filterOptions: {
    enrolment: [
      {
        label: 'Not enroled',
        value: 'not_enrolled',
      },
      {
        label: 'Enroled',
        value: 'enrolled',
      },
    ],
    status: [
      {
        label: 'In use',
        value: ConfigurableDeviceStatus.inUse,
      },
      {
        label: 'Shipping',
        value: ConfigurableDeviceStatus.shipping,
      },
      {
        label: 'Inventory',
        value: ConfigurableDeviceStatus.inventory,
      },
      {
        label: 'Order',
        value: ConfigurableDeviceStatus.order,
      },
    ],
    os: [
      {
        label: 'MacOs',
        value: 'macos',
      },
      {
        label: 'IpadOS',
        value: 'ipados',
      },
      {
        label: 'iOS',
        value: 'ios',
      },
      {
        label: 'Windows',
        value: 'windows',
      },
      {
        label: 'Android',
        value: 'android',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
    type: [
      {
        label: 'Laptop',
        value: 'Laptop',
      },
      {
        label: 'Phone',
        value: 'Phone',
      },
      {
        label: 'Desktop',
        value: 'Desktop',
      },
      {
        label: 'Tablet',
        value: 'Tablet',
      },
      {
        label: 'Monitor',
        value: 'Monitor',
      },
      {
        label: 'Headset',
        value: 'Headset',
      },
      {
        label: 'Mouse',
        value: 'Mouse',
      },
      {
        label: 'Keyboard',
        value: 'Keyboard',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
};

interface ConfigurableDeviceListViewProps {
  configurableDevices: ConfigurableDeviceData[] | null | undefined;
}

interface EnrollmentStatusMap {
  [key: string]: React.ReactNode;
}

export const getDeviceStatus = (status: ConfigurableDeviceStatus) => {
  const statusMap: Record<ConfigurableDeviceStatus, React.ReactNode> = {
    [ConfigurableDeviceStatus.inUse]: (
      <ChipComponent name="In use" backgroundColor="white" textColor="DarkGrey" border="light" textVariant="caption" />
    ),
    [ConfigurableDeviceStatus.shipping]: (
      <ChipComponent
        name="Shipping"
        backgroundColor="white"
        textColor="DarkGrey"
        border="light"
        textVariant="caption"
      />
    ),
    [ConfigurableDeviceStatus.inventory]: (
      <ChipComponent
        name="Inventory"
        backgroundColor="white"
        textColor="DarkGrey"
        border="light"
        textVariant="caption"
      />
    ),
    [ConfigurableDeviceStatus.order]: (
      <ChipComponent name="Order" backgroundColor="white" textColor="DarkGrey" border="light" textVariant="caption" />
    ),
  };

  return statusMap[status] || null;
};

export const getEnrolmentDeviceStatus = (status: string) => {
  const statusMap: EnrollmentStatusMap = {
    enrolled: (
      <ChipComponentWithIcon
        name="Enrolled"
        backgroundColor="white"
        textColor="DarkGrey"
        icon={<OkGreen {...iconSize} style={{ fill: themeColors.Green }} />}
        border="light"
        textVariant="caption"
      />
    ),
    not_enrolled: (
      <ChipComponentWithIcon
        name="Not enrolled"
        backgroundColor="white"
        textColor="DarkGrey"
        icon={<Waiting {...iconSize} />}
        border="light"
        textVariant="caption"
      />
    ),
  };

  return statusMap[status] || null;
};
export const ConfigurableDeviceListView = ({ configurableDevices }: ConfigurableDeviceListViewProps) => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();

  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);
  const { user: currentUser } = state;

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const currentFilterString = isDefined(currentUser.features?.device?.table?.selectedFilters)
    ? currentUser.features?.device?.table?.selectedFilters
    : undefined;

  const [filterString, setFilterString] = useState<string>(currentFilterString || '');
  const [searchInput, setSearchInput] = useState('');
  const [configurableDeviceData, setConfigurableDeviceData] = useState<ConfigurableDeviceData[]>(
    configurableDevices ?? []
  );

  useEffect(() => {
    trackPage('Devices directory');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDevicesBySearch = useMemo(() => {
    if (!searchInput || !configurableDeviceData) return configurableDeviceData ?? [];
    const search = searchInput.toLowerCase();
    return configurableDeviceData.filter((configurableDevice) => {
      return (
        (selectedColumns &&
          ((selectedColumns.includes('device') &&
            configurableDevice.devicePossession.device?.modelName &&
            configurableDevice.devicePossession.device?.modelName.toLowerCase().includes(search)) ||
            (selectedColumns.includes('deviceName') &&
              configurableDevice.devicePossession.device?.deviceName &&
              configurableDevice.devicePossession.device?.deviceName.toLowerCase().includes(search)) ||
            (configurableDevice.devicePossession.device?.deviceName &&
              configurableDevice.devicePossession.device?.deviceName.toLowerCase().includes(search)))) ||
        (selectedColumns.includes('usedBy') &&
          configurableDevice.ownerName &&
          configurableDevice.ownerName.toLowerCase().includes(search)) ||
        (selectedColumns.includes('serialNumber') &&
          configurableDevice.devicePossession.device?.serialNumber &&
          configurableDevice.devicePossession.device?.serialNumber.toLowerCase().includes(search)) ||
        (selectedColumns.includes('model') &&
          configurableDevice.devicePossession.device?.modelNumber &&
          configurableDevice.devicePossession.device?.modelNumber.toLowerCase().includes(search)) ||
        (selectedColumns.includes('lastActive') &&
          configurableDevice.devicePossession.device?.lastCheckIn &&
          configurableDevice.devicePossession.device?.lastCheckIn.toString().toLowerCase().includes(search)) ||
        (selectedColumns.includes('status') &&
          configurableDevice.status &&
          configurableDevice.status.toLowerCase().includes(search)) ||
        (selectedColumns.includes('storage') &&
          configurableDevice.devicePossession.device?.storage &&
          configurableDevice.devicePossession.device?.storage.toString().toLowerCase().includes(search)) ||
        (selectedColumns.includes('osVersion') &&
          configurableDevice.devicePossession.device?.osVersion &&
          configurableDevice.devicePossession.device?.osVersion.toLowerCase().includes(search)) ||
        (selectedColumns.includes('notes') &&
          configurableDevice.devicePossession.device?.customerNotes &&
          configurableDevice.devicePossession.device?.customerNotes.toLowerCase().includes(search))
      );
    });
  }, [searchInput, selectedColumns, configurableDeviceData]);

  const hiddenColumns = useMemo(() => {
    const userDeviceHiddenColumns = currentUser.features?.device?.table?.hiddenColumns ?? [
      'modelNumber',
      'customerNotes',
      'osVersion',
      'storage',
    ];
    const allAllowedColumns = filtersConfig?.columnOptions?.map((column) => column.value) ?? [];

    return DEVICE_DIRECTORY_COLUMNS_OPTIONS.filter(
      (column) => userDeviceHiddenColumns.includes(column) || !allAllowedColumns.includes(column)
    );
  }, [currentUser.features?.device?.table?.hiddenColumns]);

  useEffect(() => {
    if (!filtersConfig?.columnOptions || !hiddenColumns) return;
    const selectedColumns = filtersConfig.columnOptions
      .filter((column) => !hiddenColumns.includes(column.value))
      .map((column) => column.value);

    setSelectedColumns(selectedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filtersConfig?.filterOptions) return;
    if (configurableDevices) {
      const filters = parseQueryString(filterString);
      const configurableDeviceDataWithFilters = filterData(configurableDevices, filters);
      setConfigurableDeviceData(configurableDeviceDataWithFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString, configurableDevices]);

  function parseQueryString(queryString: string): { [key: string]: string[] } | undefined {
    if (!queryString) return;
    return queryString.split('&').reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      if (key && value !== undefined) {
        acc[key] = value.split(',');
      }
      return acc;
    }, {} as { [key: string]: string[] });
  }

  function filterData(
    data: ConfigurableDeviceData[],
    filters: { [key: string]: string[] } | undefined
  ): ConfigurableDeviceData[] {
    if (!filters) return data;
    return data.filter((cdd) => {
      return Object.entries(filters).every(([key, values]) => {
        let result: boolean = true;
        if (key === 'os') {
          if (!values.includes(cdd.devicePossession.device?.os ?? '')) {
            return false;
          }
        }
        if (key === 'status') {
          if (!values.includes(cdd.status)) {
            return false;
          }
        }
        if (key === 'enrolment') {
          if (!values.includes(cdd.enrolment)) {
            return false;
          }
        }
        if (key === 'type') {
          if (!values.includes(cdd.devicePossession.device?.type ?? '')) {
            return false;
          }
        }
        return result;
      });
    });
  }

  const updateUserFeaturesHiddenColumns = useCallback(
    async (selectedColumns: string[]) => {
      const newHiddenColumns = filtersConfig.columnOptions
        .filter((option) => !selectedColumns.includes(option.value))
        .map((option) => option.value);
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'device',
          'table',
          'hiddenColumns',
          newHiddenColumns
        );

        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('DeviceDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  const updateUserFeaturesSelectedFilters = useCallback(
    async (filterString: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'device',
          'table',
          'selectedFilters',
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('DeviceDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  const handleRowClick = useCallback(
    (row: Row<ConfigurableDeviceData>) => {
      routerHistory.push(
        generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, {
          devicePossessionId: row.original?.devicePossession.id ?? '',
        })
      );
    },
    [routerHistory]
  );

  return (
    <ContentWrapper loading={false}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <ColumnsDrawer
            columnsOptions={filtersConfig?.columnOptions ?? []}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            onApply={updateUserFeaturesHiddenColumns}
          />
          <FiltersDrawer
            filtersOptions={[{ filters: filtersConfig?.filterOptions ?? {} }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            onApply={updateUserFeaturesSelectedFilters}
          />
          <TableSearch
            query={searchInput}
            handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')}
            placeholder={polyglot.t('DeviceDirectoryPage.search')}
          />
        </Box>
      </Box>

      <DevicesTable devices={filteredDevicesBySearch} handleRowClick={handleRowClick} hiddenColumns={hiddenColumns} />
    </ContentWrapper>
  );
};
