import { useMemo, useState } from 'react';

import { Box, FormControl, Tooltip, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import type { ChecklistDto } from '@v2/feature/task/subfeature/checklist/checklist.dto';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TASKS_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { RelativeAssignmentValues } from '@/v2/feature/task/subfeature/checklist/checklist-item.dto';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { dateShortcutSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { buttonBoxSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const LaunchSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    dueDate: Yup.string().nullable().required(),
    assignedUserIds: Yup.array().of(Yup.number()).required(polyglot.t('LaunchFormDrawer.errorMessages.needUser')),
    assignedUserId: Yup.number()
      .nullable()
      .notRequired()
      .typeError(polyglot.t('LaunchFormDrawer.errorMessages.assignUser')),
  });

interface Props {
  checklist: ChecklistDto;
  onClose: Function;
}

export const LaunchFormDrawer = ({ checklist, onClose }: Props) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const isSingleSelect = useMemo(() => {
    return checklist.checklistItems.find(
      (item) =>
        item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee ||
        item.requestedForPlaceholder === RelativeAssignmentValues.manager
    );
  }, [checklist]);

  const showAssigneeSelect = useMemo(() => {
    return checklist.checklistItems.find(
      (item) =>
        item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee ||
        item.requestedForPlaceholder === RelativeAssignmentValues.manager ||
        item.assignedToPlaceholder === RelativeAssignmentValues.checklistAssignee ||
        item.assignedToPlaceholder === RelativeAssignmentValues.manager
    );
  }, [checklist]);

  const formik = useFormik({
    initialValues: {
      dueDate: null as string | null,
      assignedUserIds: [] as number[],
      assignedUserId: null,
    },
    enableReinitialize: true,
    validationSchema: LaunchSchema(polyglot),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (isSingleSelect && values.assignedUserId) {
          values.assignedUserIds = [values.assignedUserId];
        }
        await TaskAPI.launchChecklistById(checklist.id, values);
        showMessage(polyglot.t('LaunchFormDrawer.successMessages.create'), 'success');
        onClose();
        routerHistory.push(TASKS_COMPANY_OVERVIEW_ROUTE);
      } catch (error) {
        showMessage(
          polyglot.t('LaunchFormDrawer.errorMessages.launch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>
            {polyglot.t('LaunchFormDrawer.createChecklist')}
          </Typography>

          {showAssigneeSelect && (
            <>
              {isSingleSelect ? (
                <Box sx={fieldSx}>
                  <SingleUserSelect
                    name="assignedUserId"
                    options="company"
                    onChange={(_, x) => formik.setFieldValue('assignedUserId', x?.value ?? null)}
                    value={formik.values.assignedUserId}
                    label={polyglot.t('LaunchFormDrawer.whoIsAssignee')}
                    error={formik.touched.assignedUserId && Boolean(formik.errors.assignedUserId)}
                    helperText={formik.touched.assignedUserId && (formik.errors.assignedUserId as string)}
                  />
                </Box>
              ) : (
                <Box sx={fieldSx}>
                  <Tooltip title={polyglot.t('LaunchFormDrawer.whoShouldCompleteTask')} placement="top-start">
                    <UserSelect
                      label={polyglot.t('LaunchFormDrawer.whoIsAssignee')}
                      selectedLabel={polyglot.t('LaunchFormDrawer.checklistUser')}
                      value={formik.values.assignedUserIds}
                      onChange={(userIds: number[]) => {
                        formik.setFieldValue('assignedUserIds', userIds);
                      }}
                      fieldSx={{ ...spacing.mb20 }}
                    />
                  </Tooltip>
                </Box>
              )}
            </>
          )}

          <Box sx={dateShortcutSx}>
            <FormControl size="small" fullWidth>
              <DatePickerComponent
                inputFormat="DD/MM/YYYY"
                shortcuts
                shortcutLabel="When is the checklist due date?"
                value={formik.values.dueDate}
                onChange={(value) => {
                  formik.setFieldValue('dueDate', value);
                }}
                name="dueDate"
                label={polyglot.t('LaunchFormDrawer.date')}
                error={!!formik.errors.dueDate && Boolean(formik.touched.dueDate)}
                helperText={formik.errors.dueDate && Boolean(formik.touched.dueDate)}
              />
            </FormControl>
          </Box>
          <Box sx={{ ...buttonBoxSx, ...spacing.mt20 }}>
            <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={() => onClose()}>
              {polyglot.t('General.cancel')}{' '}
            </ButtonComponent>
            <LoaderButton
              name={polyglot.t('General.launch')}
              loading={loading}
              fullWidth={true}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
