import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmAdditionalPasswordSecurityPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-additional-password-policy-editor.component';
import { ZeltMdmPasscodeSecurityPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-passcode-policy-editor.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  PasswordOption,
  RestrictionAdditionalPasswordOption,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmPasscodeAndAdditionalPasswordSecurityPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenPasscodeEditor, setIsOpenPasscodeEditor] = useState<boolean>(false);
  const [isOpenAdditionalPasswordEditor, setIsOpenAdditionalPasswordEditor] = useState<boolean>(false);
  const getPasscodePolicy = (): PasswordOption => {
    return {
      minLength: configurablePoliciesSelected?.configurablePasscode?.minLength ?? 0,
      maxInactivity: configurablePoliciesSelected?.configurablePasscode?.maxInactivity ?? 0,
      minComplexChars: configurablePoliciesSelected?.configurablePasscode?.minComplexChars ?? 0,
      maxPINAgeInDays: configurablePoliciesSelected?.configurablePasscode?.maxPINAgeInDays ?? 0,
      pinHistory: configurablePoliciesSelected?.configurablePasscode?.pinHistory ?? 0,
      allowSimple: configurablePoliciesSelected?.configurablePasscode?.allowSimple ?? true,
      requireAlphanumeric: configurablePoliciesSelected?.configurablePasscode?.requireAlphanumeric ?? false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.PASSWORD_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const passwordValues: PasswordOption = getPasscodePolicy();

  const getAdditionalPasswordPolicy = (): RestrictionAdditionalPasswordOption => {
    return {
      allowAutoUnlock:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowAutoUnlock !== undefined
          ? configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowAutoUnlock
          : true,
      allowFingerprintForUnlock:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowFingerprintForUnlock !== undefined
          ? configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowFingerprintForUnlock
          : true,
      allowPasswordProximityRequests:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordProximityRequests !== undefined
          ? configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordProximityRequests
          : true,
      allowPasswordSharing:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordSharing !== undefined
          ? configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordSharing
          : true,
      allowPasswordAutoFill:
        configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordAutoFill !== undefined
          ? configurablePoliciesSelected?.configurableAdditionalPasswordPolicy?.allowPasswordAutoFill
          : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const additionalPasswordValues: RestrictionAdditionalPasswordOption = getAdditionalPasswordPolicy();
  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Passcode</Typography>
          <IconButton
            onClick={() => {
              setIsOpenPasscodeEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Min length
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(passwordValues.minLength === 0
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {passwordValues.minLength === 0 ? 'Not set' : `${passwordValues.minLength} characters`}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Min complex char
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(passwordValues.minComplexChars === 0
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {passwordValues.minComplexChars === 0 ? 'Not set' : passwordValues.minComplexChars}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Require alphanumeric
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!passwordValues.requireAlphanumeric
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {!passwordValues.requireAlphanumeric ? 'Not set' : 'Enabled'}
          </Typography>

          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Enforce strong passcode
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(passwordValues.allowSimple
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {passwordValues.allowSimple ? 'Not set' : 'Enabled'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Max passcode age
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(passwordValues.maxPINAgeInDays === 0
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {passwordValues.maxPINAgeInDays === 0 ? 'Not set' : `${passwordValues.maxPINAgeInDays} days`}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Passcode history
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(passwordValues.pinHistory === 0
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {passwordValues.pinHistory === 0 ? 'Not set' : passwordValues.pinHistory}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Autolock after
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(passwordValues.maxInactivity === 0
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {passwordValues.maxInactivity === 0 ? 'Not set' : `${passwordValues.maxInactivity} minutes`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Additional password security</Typography>
          <IconButton
            onClick={() => {
              setIsOpenAdditionalPasswordEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            TouchID unlock
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(additionalPasswordValues.allowFingerprintForUnlock
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {additionalPasswordValues.allowFingerprintForUnlock ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Auto unlock
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(additionalPasswordValues.allowAutoUnlock
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {additionalPasswordValues.allowAutoUnlock ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Requesting password from nearby devices
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(additionalPasswordValues.allowPasswordProximityRequests
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {additionalPasswordValues.allowPasswordProximityRequests ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Password sharing via Airdrop
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(additionalPasswordValues.allowPasswordSharing
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {additionalPasswordValues.allowPasswordSharing ? 'Allowed' : 'Restricted'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Auto-filling passwords
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(additionalPasswordValues.allowPasswordAutoFill
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {additionalPasswordValues.allowPasswordAutoFill ? 'Allowed' : 'Restricted'}
          </Typography>
        </Box>
      </Box>
      <DrawerModal isOpen={isOpenPasscodeEditor} setIsOpen={setIsOpenPasscodeEditor}>
        <ZeltMdmPasscodeSecurityPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenPasscodeEditor}
          setIsOpen={setIsOpenPasscodeEditor}
        />
      </DrawerModal>

      <DrawerModal isOpen={isOpenAdditionalPasswordEditor} setIsOpen={setIsOpenAdditionalPasswordEditor}>
        <ZeltMdmAdditionalPasswordSecurityPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenAdditionalPasswordEditor}
          setIsOpen={setIsOpenAdditionalPasswordEditor}
        />
      </DrawerModal>
    </>
  );
};
