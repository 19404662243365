import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  DeviceSettings,
  RequiredEnrolmentType,
  RequiredEnrolmentValueEnum,
} from '@/v2/feature/device/features/devices-settings/device-settings.interface';
import { DeviceSettingsAPI } from '@/v2/feature/device/features/devices-settings/devices-settings.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const EnrolmentTabFilter = [
  { name: 'All devices', value: RequiredEnrolmentValueEnum.AllDevices },
  { name: 'Only company', value: RequiredEnrolmentValueEnum.OnlyCompany },
];
export const EnrolmentSettingsDrawer = ({
  generalSettings,
  onClose,
}: {
  generalSettings: DeviceSettings | undefined;
  onClose: () => Promise<void>;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>(
    generalSettings?.requiredEnrolment ?? RequiredEnrolmentValueEnum.AllDevices
  );
  const [showMessage] = useMessage();

  const handleSettings = async () => {
    try {
      setLoading(true);
      const updateSettings = {
        requiredEnrolment: filterValue as RequiredEnrolmentType,
        hiddenDeviceModels: generalSettings?.hiddenDeviceModels ?? null,
      };
      await DeviceSettingsAPI.updateCompanyDeviceSettings(updateSettings);
      await onClose();
      showMessage(`Successfully updated devices settings`, 'success');
    } catch (error) {
      showMessage(`Could not update devices settings.${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mb: spacing.m30 }}>Enrolment</Typography>

      <Box sx={{ ...spacing.mt20, display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
        <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>Require enrolment from</Typography>
        <TabFilterButtons filters={EnrolmentTabFilter} setFilterValue={setFilterValue} filterValue={filterValue} />
      </Box>

      <LoaderButton
        name="Save"
        fullWidth
        loading={isLoading}
        onClick={() => handleSettings()}
        style={{ marginTop: '40px' }}
        colorVariant="primary"
        sizeVariant="medium"
      />
    </Box>
  );
};
