import axios from 'axios';
import {
  CreateContractData,
  FinaliseContractData,
  RequiredContractFields,
  SaveContractData,
  SendContractLinkData,
  SignatureWitnessRequiredResult,
  SignContractData,
  TemplatePreviewResult,
} from '@/v2/feature/templates/templates.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class ContractAPI {
  static async create(parameters: CreateContractData): Promise<void> {
    return (await axios.post('/apiv2/contract', parameters)).data;
  }

  static async remindPending(parameters: SendContractLinkData): Promise<void> {
    return await axios.post('/apiv2/contract/remind-pending', parameters);
  }

  static async sign(parameters: SignContractData): Promise<void> {
    return await axios.put('/apiv2/contract/sign', parameters);
  }

  static async finalise(parameters: FinaliseContractData): Promise<void> {
    return await axios.put('/apiv2/contract/finalise', parameters);
  }

  static async companySign(parameters: SignContractData): Promise<void> {
    return await axios.put('/apiv2/contract/company-sign', parameters);
  }

  static async getContractPreviewById(parameters: { contractId: string }): Promise<TemplatePreviewResult> {
    return (await axios.get(`/apiv2/contract/${parameters.contractId}`)).data;
  }

  static async getContractByIdForCompanySign(parameters: { contractId: string }): Promise<TemplatePreviewResult> {
    return (await axios.get(`/apiv2/contract/${parameters.contractId}/company-sign`)).data;
  }

  static async getEmployeeFieldsForContractById(contractId: string): Promise<RequiredContractFields> {
    return (await axios.get(`/apiv2/contract/${contractId}/employee-fields`)).data;
  }

  static async checkIfSignatureAndWitnessPendingForCurrentUser(
    contractId: string
  ): Promise<SignatureWitnessRequiredResult> {
    return (await axios.get(`/apiv2/contract/${contractId}/signature-witness-pending-for-user`)).data;
  }

  static async downloadInvoice(payload: { contractId: string }): Promise<any> {
    return (
      await axios.get(`/apiv2/contract/audit-trail/${payload.contractId}`, {
        responseType: 'blob',
      })
    ).data;
  }

  static async deleteContract(payload: { contractId: string }): Promise<void> {
    return await axios.put('/apiv2/contract/delete', payload);
  }

  static async save(parameters: SaveContractData): Promise<void> {
    return await axios.put('/apiv2/contract/save-data', parameters);
  }

  static async updateContractWithMissingEmployeeFields(parameters: SaveContractData): Promise<void> {
    return await axios.put('/apiv2/contract/update-employee-fields', parameters);
  }
}

export class ContractEndpoints {
  static contractFinalised(contractId: string): Endpoint<boolean> {
    return {
      url: `/apiv2/contract/${contractId}/contract-finalised`,
    };
  }
}
