import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';

import { BillingInvoiceAPI } from '@/api-client/billing-invoice.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BillingCharge } from '@/models/invoice.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SuperAdminBillingNewOneOffChargeDrawer } from '@/v2/feature/super-admin/features/super-admin-billing-v2/components/super-admin-billing-new-one-off-charge-drawer.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconErrorButtonSx } from '@/v2/styles/icon-button.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';

interface Props {
  readonly pageConfig: PageConfig;
}

export function SuperAdminBillingOneOffChargesPage({ pageConfig }: Props) {
  const [charges, setCharges] = useState<BillingCharge[]>([]);
  const [isLoadingCharges, setIsLoadingCharges] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [newChargeDrawerOpen, setNewChargeDrawerOpen] = useState(false);
  const [chargeToDelete, setChargeToDelete] = useState<BillingCharge | null>();
  const [chargeToEdit, setChargeToEdit] = useState<BillingCharge | null>();
  const { data: companyList } = useApiClient(CompanyEndpoints.getAllCompaniesForSelectionList(), { suspense: false });

  const fetchCharges = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string }) => {
      try {
        setIsLoadingCharges(true);
        const { charges, pagination: paginationInfo } = await BillingInvoiceAPI.getAllCharges(params);
        setCharges(charges || []);

        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingCharges(false);
      } catch (error) {
        showMessage(`Something went wrong fetching invoices. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const refresh = useCallback(async () => {
    await fetchCharges({
      page: pageIndex.toString(),
      pageSize: DEFAULT_PAGE_SIZE.toString(),
      searchQuery,
    });
  }, [fetchCharges, pageIndex, searchQuery]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const confirmChargeDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, charge: BillingCharge) => {
    setAnchorEl(event.currentTarget);
    setChargeToDelete(charge);
  };

  const handleDeleteOneOffCharge = useCallback(
    async (companyId: number, chargeId: number) => {
      if (!chargeId) return;
      try {
        await BillingInvoiceAPI.deleteChargeAsSuperadmin(companyId, chargeId);

        await refresh();
        showMessage('Successfully deleted charge', 'success');
      } catch (error) {
        showMessage(`Could not delete charge. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refresh, showMessage]
  );

  const handleEditOneOffCharge = useCallback(
    async (charge: BillingCharge) => {
      setChargeToEdit(charge);
      setNewChargeDrawerOpen(true);
    },
    [setChargeToEdit, setNewChargeDrawerOpen]
  );

  const billingChargeColumns = useMemo<ColumnDef<BillingCharge, unknown>[]>(
    () => [
      {
        header: () => 'Company Name',
        accessorFn: (row) => row.company?.name,
        id: 'companyName',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {`${original.company?.name} (${original.companyId})` || '—'}
            </Typography>
          </Box>
        ),
        size: 150,
      },
      {
        header: () => 'Amount',
        accessorFn: (row) => row.amount,
        id: 'amount',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{original.amount}</Typography>
          </Box>
        ),
        size: 70,
      },
      {
        header: () => 'Type',
        accessorFn: (row) => row.type,
        id: 'type',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{original.type || '—'}</Typography>
          </Box>
        ),
        size: 70,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row.status,
        id: 'status',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {original.status === 'paid' ? `Billed (${original.invoiceId})` : 'Not billed' || '—'}
            </Typography>
          </Box>
        ),
        size: 70,
      },
      {
        header: () => 'Description',
        accessorFn: (row) => row.memo,
        id: 'status',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{original.memo}</Typography>
          </Box>
        ),
        size: 70,
      },
      {
        header: () => 'Paid At',
        accessorFn: (row) => row.paidAt,
        id: 'paidAt',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {dateAPItoDisplay(original.paidAt)}
            </Typography>
          </Box>
        ),
        size: 100,
      },
      {
        header: () => 'Created At',
        accessorFn: (row) => row.createdAt,
        id: 'createdAt',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {dateAPItoDisplay(original.createdAt)}
            </Typography>
          </Box>
        ),
        size: 100,
      },
      {
        header: () => 'Action',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            {original.status !== 'paid' && (
              <>
                <StyledTooltip id="edit-charge" title="Edit unbilled charge" placement="top" sx={{ ml: '10px' }}>
                  <IconButton
                    key={`edit-charge-${original.id}`}
                    color="secondary"
                    size="small"
                    onClick={() => handleEditOneOffCharge(original)}
                    title="Edit"
                  >
                    <EditIcon {...iconSize} />
                  </IconButton>
                </StyledTooltip>
                <StyledTooltip id="delete-charge" title="Delete unbilled charge" placement="top" sx={{ ml: '10px' }}>
                  <IconButton
                    key={`delete-charge-${original.id}`}
                    onClick={(event) => confirmChargeDelete(event, original)}
                    color="error"
                    sx={tableIconErrorButtonSx}
                  >
                    <TrashIcon {...iconSize} />
                  </IconButton>
                </StyledTooltip>
              </>
            )}
          </Box>
        ),
        size: 220,
      },
    ],
    [handleEditOneOffCharge]
  );
  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ font: themeFonts.title2, width: '100%' }}>Billing</Typography>}
        views={<></>}
        actions={
          <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => setNewChargeDrawerOpen(true)}>
            Add new charge
          </ButtonComponent>
        }
        showAction={true}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={isLoadingCharges} secondLevel>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              ...spacing.mb20,
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <TableSearch
              style={{ width: '20em' }}
              query={searchQuery}
              handleChange={(e) => {
                setSearchQuery(e.target.value?.trim() ?? '');
                // Resets pagination index once we perform a new search
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
              placeholder="Enter company id or name..."
            />
          </Box>

          {!isLoadingCharges && (
            <>
              <BasicServerTable<BillingCharge>
                rowData={[...charges]}
                columnData={billingChargeColumns}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                setPagination={setPagination}
                totalPages={totalPages}
                totalItems={totalItems}
              />
            </>
          )}

          {companyList && (
            <SuperAdminBillingNewOneOffChargeDrawer
              isOpen={newChargeDrawerOpen}
              setIsOpen={setNewChargeDrawerOpen}
              refresh={refresh}
              companyList={companyList}
              chargeToEdit={chargeToEdit}
            />
          )}

          {anchorEl && chargeToDelete && (
            <NotificationModal
              isOpen={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
                setChargeToDelete(null);
              }}
              anchorEl={anchorEl}
              takeAction={async () => {
                if (!chargeToDelete) return;
                await handleDeleteOneOffCharge(chargeToDelete.companyId, chargeToDelete.id);
                setChargeToDelete(null);
                setAnchorEl(null);
              }}
              message={
                <Box sx={{ mb: '10px' }}>
                  <Typography sx={{ font: themeFonts.title4, mb: '5px' }}>Delete one off charge?</Typography>
                  <Typography sx={{ font: themeFonts.captionSmall }}>
                    Company: <b>{chargeToDelete.company.name}</b>
                  </Typography>
                  <Typography sx={{ font: themeFonts.captionSmall }}>
                    Charge ID: <b>{chargeToDelete.id}</b>
                  </Typography>
                  <Typography sx={{ font: themeFonts.captionSmall }}>
                    Charge Date: <b>{dateAPItoDisplay(chargeToDelete.createdAt)}</b>
                  </Typography>
                  <Typography sx={{ font: themeFonts.captionSmall }}>Charge memo:{chargeToDelete.memo}</Typography>
                </Box>
              }
              callToAction="Delete"
            />
          )}
        </>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
}
