import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CustomBenefitCategory,
  CustomBenefitDto,
  CustomBenefitType,
  UpdateCustomBenefitGeneralDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import { UploadInput } from '@/component/forms/UploadInput';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Subtract.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

const getValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup.string().nullable().required(polyglot.t('ValidationMessages.requiredField')),
    description: yup.string().nullable().notRequired(),
    category: yup
      .string()
      .oneOf(Object.values(CustomBenefitCategory))
      .required(polyglot.t('ValidationMessages.requiredField')),
    type: yup
      .string()
      .oneOf(Object.values(CustomBenefitType), polyglot.t('ValidationMessages.validValue'))
      .required(polyglot.t('ValidationMessages.requiredField')),
    benefitLink: yup.string().nullable().notRequired(),
    logoUuid: yup.string().nullable().notRequired(),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitGeneralDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditCustomBenefitGeneralDrawerContent setIsOpen={setIsOpen} refresh={refresh} customBenefit={customBenefit} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitGeneralDrawerContent = ({ setIsOpen, refresh, customBenefit }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<UpdateCustomBenefitGeneralDto>({
    initialValues: {
      type: customBenefit.type,
      category: customBenefit.category,
      name: customBenefit.name ?? '',
      description: customBenefit.description ?? '',
      benefitLink: customBenefit.benefitLink ?? '',
      logoUuid: customBenefit.logoUuid ?? '',
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit: async (values) => {
      const valuesToSubmit: UpdateCustomBenefitGeneralDto = {
        type: values.type,
        category: values.category,
        name: values.name,
        description: values.description ? values.description : null,
        benefitLink: values.benefitLink ? values.benefitLink : null,
        logoUuid: values.logoUuid ? values.logoUuid : null,
      };
      try {
        setLoading(true);
        await CustomBenefitAPI.updateCustomBenefitGeneral(customBenefit.id, valuesToSubmit);
        showMessage(polyglot.t('BenefitModule.benefitUpdated'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.editBenefit')}</Typography>

        <TextfieldComponent
          name="name"
          label={polyglot.t('General.name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          clearText={() => formik.setFieldValue('name', '')}
          error={formik.touched.name && !!formik.errors.name}
          helperText={(formik.touched.name && formik.errors.name) ?? ' '}
        />

        <TextfieldComponent
          multiline
          name="description"
          label={polyglot.t('General.description')}
          value={formik.values.description}
          type="text"
          onChange={formik.handleChange}
          error={formik.touched.description && !!formik.errors.description}
          helperText={(formik.touched.description && formik.errors.description) ?? ' '}
          endAdornment="none"
        />

        <TextfieldComponent
          multiline
          name="benefitLink"
          label={polyglot.t('BenefitModule.addBenefitLink')}
          value={formik.values.benefitLink}
          type="text"
          onChange={formik.handleChange}
          error={formik.touched.benefitLink && !!formik.errors.benefitLink}
          helperText={(formik.touched.benefitLink && formik.errors.benefitLink) ?? ' '}
          endAdornment="none"
        />

        <Box>
          {formik.values.logoUuid ? (
            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
              {polyglot.t('BenefitModule.benefitLogo')}
            </Typography>
          ) : (
            <Typography variant="caption">{polyglot.t('BenefitModule.uploadBenefitLogo')}</Typography>
          )}

          {formik.values.logoUuid ? (
            <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
                {customBenefit?.logoUuid === formik.values.logoUuid ? (
                  <img src={customBenefit?.url} width={16} height={16} alt="benefit-logo" />
                ) : (
                  <DocumentIcon width={16} height={16} />
                )}
                <Typography variant="title4">{customBenefit?.fileName ?? polyglot.t('BenefitModule.logo')} </Typography>
              </Box>

              <IconButton
                sx={tableIconButtonSx}
                onClick={() => formik.setFieldValue('logoUuid', null)}
                title={polyglot.t('General.remove')}
              >
                <TrashIcon {...iconSize} />
              </IconButton>
            </Box>
          ) : (
            <UploadInput<{ uuid: string }>
              onChange={(resp) => {
                formik.setFieldValue('logoUuid', resp?.uuid);
              }}
            />
          )}
        </Box>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
