import { useCallback, useRef } from 'react';

import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { AuthLoginDtoWithUserDetails } from '@/v2/feature/auth/auth.dto';
import { getSSOLoginResponse } from '@/v2/feature/auth/auth.util';
import { AuthLoginRouterState } from '@/v2/feature/auth/features/auth-router.states';
import { isProduction, SignInFlow } from '@/v2/infrastructure/firebase/identity-platform.util';
import { primarySmallBtn } from '@/v2/styles/buttons.styles';

export interface SSOCheck {
  companyId: number;
  app: string;
  enabled: boolean;
}

export const AuthLoginSSOIOSPage = (): JSX.Element => {
  const routerLocation = useLocation<AuthLoginRouterState | undefined>();
  const loginButton = useRef<HTMLButtonElement>(null);

  const queries = new URLSearchParams(routerLocation.search);

  const triggerSSOLogin = useCallback(async () => {
    try {
      const email = queries.get('email');
      const companyId = queries.get('companyId');
      const app = queries.get('app');
      const deviceId = queries.get('deviceId') ?? undefined;
      const iosScheme = isProduction ? 'zeltapp' : 'zeltapp-beta';

      if (email && companyId && app) {
        let matchingProvider = { companyId: +companyId, app, enabled: true };
        let loginResponse = (await getSSOLoginResponse(
          email,
          matchingProvider,
          SignInFlow.Popup,
          deviceId
        )) as AuthLoginDtoWithUserDetails | null;
        if (loginResponse) {
          window.location.replace(`${iosScheme}://zelt.app/sso-login?authToken=${loginResponse.accessToken}`);
        }
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <body>
      <h1>Processing login with SSO provider</h1>
      <form>
        {' '}
        <Button
          fullWidth
          ref={loginButton}
          sx={{ ...primarySmallBtn }}
          onClick={async () => {
            await triggerSSOLogin();
          }}
        >
          Start
        </Button>
      </form>
    </body>
  );
};
