import { useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { SubscriptionsTable } from '@v2/feature/billing/components/subscriptions-table.component';
import { UpdateSubscriptionDrawer } from '@v2/feature/billing/components/update-subscription-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { parentCardSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { DowngradeViability } from '../../company/company-settings/company-settings.interface';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import {
  CompanyBillingSubscription,
  CompanySubscriptionStats,
  SubscriptionBillingStats,
} from '@/models/subscription.model';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SubscriptionSectionProps {
  subscriptions: CompanyBillingSubscription[];
  subscriptionsBillingStats: SubscriptionBillingStats | null;
  subscriptionsStats: CompanySubscriptionStats | null;
  downgradeViability: Record<string, DowngradeViability>;
  refresh: () => Promise<void>;
}

export const SubscriptionSection = ({
  subscriptions,
  subscriptionsBillingStats,
  subscriptionsStats,
  downgradeViability,
  refresh,
}: SubscriptionSectionProps) => {
  const { polyglot } = usePolyglot();

  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState<boolean>(false);

  return (
    <Box sx={parentCardSx}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
          {polyglot.t('SubscriptionSection.subscription')}
        </Typography>

        {!isUpdateDrawerOpen && (
          <IconButton title="Update subscription" sx={tableIconButtonSx} onClick={() => setIsUpdateDrawerOpen(true)}>
            <EditIcon {...actionIconSize} />
          </IconButton>
        )}
      </Box>

      <Typography sx={{ my: spacing.m10, ...themeFonts.caption, color: themeColors.DarkGrey }}>
        {polyglot.t('SubscriptionSection.description')}
      </Typography>

      <SubscriptionsTable subscriptionsBillingStats={subscriptionsBillingStats} />

      {isUpdateDrawerOpen && (
        <UpdateSubscriptionDrawer
          isOpen={isUpdateDrawerOpen}
          setIsOpen={setIsUpdateDrawerOpen}
          subscriptions={subscriptions}
          subscriptionsStats={subscriptionsStats}
          subscriptionsBillingStats={subscriptionsBillingStats}
          downgradeViability={downgradeViability}
          refresh={refresh}
        />
      )}
    </Box>
  );
};
