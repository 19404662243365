import { useCallback, useMemo, useRef, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { TaxYear } from '@shared/modules/payroll/payroll.types';
import { ColumnDef, Row } from '@tanstack/react-table';
import { CompanyPayrollType } from '@v2/feature/payroll/payroll.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as DocumentIcon } from '@/images/side-bar-icons/Document.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { RevealableCell, RevealableColumnHeader } from '@/v2/components/table/revealable-column-header.component';
import { sortDate, sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { PayrollTableSkeleton } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/payroll-table-skeleton.component';
import { GlobalPayrollSalarySummaryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/global-payroll-salary-summary-drawer.component';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { UKPayrollAPI } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/user-payroll.api';
import { PayrollLocalEndpoints } from '@/v2/feature/payroll/payroll-local.api';
import { UserPayslipSummaryDto } from '@/v2/feature/payroll/payroll.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { formatMediumDate, formatShortMonthYear } from '@/v2/util/date-format.util';

const taxYearToYearNumber = (value: TaxYear) => Number(value.slice(4));

type PersonalPayrollProps = {
  userId: number;
  inPayroll: boolean;
};

export const payslipDownloadUrl = (pdfPath: string): string => {
  return window.location.origin.concat(pdfPath);
};

export const PersonalPayroll = ({ userId }: PersonalPayrollProps) => {
  const [columnsRevealed, setColumnsRevealed] = useState<{ [columnId: string]: boolean }>({});
  const [viewingPayslip, setViewingPayslip] = useState<string | null>(null);
  const [viewingGlobalPayslip, setViewingGlobalPayslip] = useState<UserPayslipSummaryDto | null>(null);
  const [downloadingP60s, setDownloadingP60s] = useState(new Set<TaxYear>());

  const p60Urls = useRef(new Map<TaxYear, string>());
  const [showMessage] = useMessage();

  const { data: payslips, isLoading: loading } = useApiClient(PayrollLocalEndpoints.getPayslipsForUser(userId), {
    suspense: false,
  });
  const inGlobalPayroll = payslips?.every((p) => p.payrollKind === CompanyPayrollType.GlobalPayroll);

  const changeColumnReveal = useCallback((columnId: string, revealed: boolean) => {
    setColumnsRevealed((revealState) => ({
      ...revealState,
      [columnId]: revealed,
    }));
  }, []);

  const fetchP60Data = useCallback(
    async (userId: number, taxYear: TaxYear, window: Window | null) => {
      setDownloadingP60s((s) => new Set([...s, taxYear]));
      try {
        let p60Url = p60Urls.current.get(taxYear);
        if (!p60Url) {
          const { pdfUrl } = await UKPayrollAPI.getP60DocumentForUser(userId, taxYearToYearNumber(taxYear));
          p60Url = pdfUrl;
          // cache the url while the user is one this page
          p60Urls.current.set(taxYear, p60Url);
        }
        if (window) window.location = p60Url;
      } catch (error) {
        showMessage(`P60 ${userId}/${taxYear} download failed. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setDownloadingP60s((s) => new Set([...(s.delete(taxYear), s)]));
      }
    },
    [showMessage]
  );

  const handleDocumentButtonClick = useCallback(
    async (item: UserPayslipSummaryDto, isPreview = false) => {
      if (item.entryType === 'Payslip' && item.payrollKind === CompanyPayrollType.UKPayroll && item.entryId) {
        const url = isPreview
          ? `/apiv2/payroll/payruns/${item.taxYear}/${item.payPeriod}/${item.period}/${item.entryId}/${userId}/payslip-pdf/download/preview`
          : `/apiv2/payroll/payruns/${item.entryId}/${userId}/payslip-pdf/download`;
        const payslipUrl = payslipDownloadUrl(url);
        if (payslipUrl) setViewingPayslip(payslipUrl);
        else showMessage(`Encountered error while trying to download payslip: ${JSON.stringify(item)}`, 'error');
        return;
      }
      if (item.entryType === 'Payslip' && item.payrollKind === CompanyPayrollType.GlobalPayroll) {
        const url = `/apiv2/company/salary/payroll/${item.payrollId}/global/global-payroll/${item.taxYear}/${item.payPeriod}/${item.period}/${item.userId}/payslip-pdf/download`;
        const payslipUrl = payslipDownloadUrl(url);
        if (payslipUrl) setViewingPayslip(payslipUrl);
      }
      if (item.entryType === 'P60') {
        // to allow the P60 pdf to open in a new browser window, we must
        // create the window using window.open() before the async function starts.
        // if we don't, browsers will treat the open() call as a popup and block it.
        fetchP60Data(userId, item.taxYear, window.open());
        return;
      }
    },
    [fetchP60Data, showMessage, userId]
  );

  const payrunColumns = useMemo<ColumnDef<UserPayslipSummaryDto, UserPayslipSummaryDto>[]>(
    () => [
      {
        id: 'date',
        size: 120,
        enableSorting: true,
        accessorFn: (row) => row,
        header: () => 'Date',
        sortingFn: (a, b) => sortDate(a, b, (item) => item.paymentDate),
        cell: ({ row: { original } }) => {
          if (!original.paymentDate) return '';
          return original.payPeriod === 'Monthly'
            ? formatShortMonthYear(original.paymentDate)
            : formatMediumDate(original.paymentDate);
        },
      },
      {
        id: 'gross',
        header: () => (
          <RevealableColumnHeader
            label={inGlobalPayroll ? 'Total payments' : 'Gross'}
            visible={columnsRevealed['gross']}
            onVisibilityChange={(visible) => changeColumnReveal('gross', visible)}
          />
        ),
        size: 80,
        enableSorting: true,
        accessorFn: (row: Row<UserPayslipSummaryDto>) => row,
        sortingFn: (a: Row<UserPayslipSummaryDto>, b: Row<UserPayslipSummaryDto>) =>
          sortNumeric(a, b, (item) => item.gross),
        cell: ({ row: { original } }: { row: { original: UserPayslipSummaryDto } }) => (
          <RevealableCell visible={columnsRevealed['gross']}>
            {typeof original.gross === 'number' ? formatCurrency(original.gross) : ''}
          </RevealableCell>
        ),
      },
      {
        id: 'take-home',
        header: () => (
          <RevealableColumnHeader
            label="Take home"
            visible={columnsRevealed['take-home']}
            onVisibilityChange={(visible) => changeColumnReveal('take-home', visible)}
          />
        ),
        size: 80,
        enableSorting: true,
        accessorFn: (row: Row<UserPayslipSummaryDto>) => row,
        sortingFn: (a: Row<UserPayslipSummaryDto>, b: Row<UserPayslipSummaryDto>) =>
          sortNumeric(a, b, (item) => item.takeHome),
        cell: ({ row: { original } }: { row: { original: UserPayslipSummaryDto } }) => (
          <RevealableCell visible={columnsRevealed['take-home']}>
            {typeof original.takeHome === 'number' ? formatCurrency(original.takeHome) : ''}
          </RevealableCell>
        ),
      },

      {
        id: 'payroll-name',
        header: () => 'Payroll',
        size: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortString(a, b, (item) => item.payrollName),
        cell: ({ row: { original } }) => original.payrollName,
      },
      {
        id: 'doc-type',
        header: () => 'Document type',
        size: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortString(a, b, (item) => item.entryType),
        cell: ({ row: { original } }) => original.entryType,
      },
      {
        id: 'tax-year',
        header: () => 'Tax year',
        size: 60,
        enableSorting: true,
        accessorFn: (row) => row,
        // when sorting by tax year, also sort by period
        sortingFn: (a, b) => sortNumeric(a, b, (item) => taxYearToYearNumber(item.taxYear) * 100 + item.period),
        cell: ({ row: { original } }) => original.taxYear,
      },
      {
        id: 'period',
        header: () => 'Period',
        size: 40,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.period),
        cell: ({ row: { original } }) => original.period,
      },
      {
        id: 'action-buttons',
        header: () => '',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 40,
        cell: ({ row: { original } }) => {
          return (
            <Stack flexDirection="row" justifyContent="flex-end" gap="5px" whiteSpace="nowrap">
              {
                <IconButton
                  sx={tableIconButtonSx}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleDocumentButtonClick(original, original.isPreview);
                  }}
                  disabled={downloadingP60s.has(original.taxYear)}
                  title="View/Download"
                >
                  <DocumentIcon {...iconSize} />
                </IconButton>
              }
            </Stack>
          );
        },
      },
    ],
    [inGlobalPayroll, columnsRevealed, changeColumnReveal, downloadingP60s, handleDocumentButtonClick]
  );

  const handleRowClick = useCallback(({ original }: Row<UserPayslipSummaryDto>) => {
    if (original.payrollKind === CompanyPayrollType.GlobalPayroll) setViewingGlobalPayslip(original);
  }, []);

  return (
    <ContentWrapper loading={loading}>
      {loading && <PayrollTableSkeleton includeMargin={false} includeGutter={true} rows={5} />}
      {!loading && payslips?.length === 0 && (
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
          Once your first salary is paid, you will find your payslips here.
        </Typography>
      )}
      {!loading && !!payslips?.length && (
        <BasicTable rowData={payslips} columnData={payrunColumns} rowClick={handleRowClick} />
      )}
      {viewingPayslip && (
        <DocPreviewer docData={viewingPayslip} visible onClose={() => setViewingPayslip(null)} title="Payslip" />
      )}
      {
        <GlobalPayrollSalarySummaryDrawer
          payslip={viewingGlobalPayslip}
          onClose={() => setViewingGlobalPayslip(null)}
        />
      }
    </ContentWrapper>
  );
};
