import { OptionObject } from '@v2/components/forms/select.component';
import { translateManagerPermissionGroupReportsLevel } from '@v2/infrastructure/i18n/translate.util';
import Polyglot from 'node-polyglot';

import { Scope } from '@/models';
import { PermissionDto } from '@/v2/feature/permission-group/permission-group.dto';
import {
  AllManagerPermissionLevels,
  PermissionCategory,
  PermissionSubCategory,
} from '@/v2/feature/permission-group/permission-group.interface';

const USER_PERSONAL_SCOPES = [
  //admin
  'user.basicInfo:all',
  'user.personalInfo:all',
  'user.family:all',
  'user.about:all',
  'user.accountInfo:read:all',
  'user.personalContact:all',
  'user.personalIdentification:all',

  //profile owner
  'user.basicInfo',
  'user.personalInfo',
  'user.family',
  'user.about',

  //other
  'user.basicInfo:read:all',
  'user.family:read:all',
  'user.about:read:all',

  //manager
  'user.personalInfo:manager',
  'user.personalInfo:read:manager',
  'user.family:read:manager',
];

const USER_WORK_SCOPES = [
  //admin
  'user.role:all',
  'user.contract:all',
  'user.lifecycle:all',
  'user.rightWork:all',

  //profile owner
  'user.role:read',
  'user.contract:read',
  'user.lifecycle:read',
  'user.rightWork:read',

  //manager
  'user.role:manager',
  'user.lifecycle:manager',
  'user.rightWork:manager',
  'user.rightWork:read:manager',
  'user.contract:manager',
  'user.contract:read:manager',
];

const USER_COMPENSATIONS_SCOPES = [
  //admin
  'user.compensation:all',
  'user.bankAccount:all',
  'user.equity:all',

  //profile owner
  'user.compensation:read',
  'user.bankAccount',
  'user.equity:read',

  //manager
  'user.bankAccount:read:manager',
  'user.equity:manager',
  'user.equity:read:manager',
  'user.compensation:read:manager',
  'user.compensation:manager',
];

const USER_CONTACT_SCOPES = [
  //admin
  'user.address:all',
  'user.emergencyContact:all',
  'user.workContact:all',

  //profile owner
  'user.address',
  'user.emergencyContact',
  'user.workContact',

  //other
  'user.workContact:read:all',

  //manager
  'user.address:read:manager',
  'user.emergencyContact:read:manager',
];

const ADMINISTRATION_SCOPES = ['company.settings:all', 'user:all', 'user.onboard:all', 'user:read:all'];

const BENEFITS_SCOPES = [
  //admin
  'pension:all',
  'insurance:all',

  //profile owner
  'pension',
  'insurance',
];

const DOCUMENTS_SCOPES = [
  //admin
  'documents:all',
  'templates:all',

  //profile owner
  'documents',
];

const GENERAL = 'General';

const capitalize = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

function getScopeEntityParts(scope: Scope): string[] {
  const [entityWithProp] = scope.split(':');
  return entityWithProp.split('.');
}

function getScopeEntity(scope: Scope): string {
  const [entity] = getScopeEntityParts(scope);
  return entity;
}

function getScopeSubcategories(permissions: PermissionDto[]): PermissionSubCategory[] {
  const subCategories: Record<string, PermissionDto[]> = {};
  permissions
    // .filter((permission) => !!permission.permission_group_scope.visible)
    .forEach((permission) => {
      let subcategory = 'General';
      const [, subEntity, prop] = getScopeEntityParts(permission.scope);
      if (prop) subcategory = subEntity;

      if (!subCategories[subcategory]) subCategories[subcategory] = [];
      subCategories[subcategory].push(permission);
    });

  return Object.keys(subCategories).map((subCategory) => ({
    name: capitalize(subCategory),
    permissions: subCategories[subCategory].sort((a, b) => {
      // keep payroll scope last in the list
      if (a.scope === 'payroll:all' || b.scope === 'payroll:all') return a.scope === 'payroll:all' ? 10 : -10;
      return a.scope > b.scope ? 1 : -1;
    }),
  }));
}

// UPDATE THIS FUNCTION IF YOU ADD NEW DOMAIN SCOPES ON BACKEND
function getSidebarSort(allCategories: PermissionCategory[]): PermissionCategory[] {
  const sortedFields: string[] = [
    'Administration',
    'Compensation profile',
    'Contact profile',
    'Personal profile',
    'Work profile',
    'Apps',
    'Devices',
    'Payroll',
    'Benefits',
    'Payments',
    'Expenses',
    'Invoices',
    'Absence',
    'Attendance',
    'Documents',
    'Tasks',
    'Reviews',
    'Reports',
  ];

  return sortedFields
    .map((item) => {
      return {
        name: item === 'Absence' ? 'Time and Calendar' : item,
        subcategories: allCategories.find((record) => record.name === item)?.subcategories || [],
      };
    })
    .filter((c) => c.subcategories && c.subcategories.length > 0);
}

function formatUserCategories(allCategories: PermissionCategory[]): PermissionCategory[] {
  const userPersonalPermissions = [];
  const userWorkPermissions = [];
  const userContactPermissions = [];
  const userCompensationPermissions = [];
  const userAdministrationPermissions = [];
  const userBenefitsPermissions = [];
  const documentPermissions = [];

  //for user scope split
  const userCategory = allCategories.find((c) => c.name === 'User');
  if (userCategory && userCategory.subcategories.length !== 0) {
    for (const category of userCategory.subcategories[0].permissions) {
      if (USER_PERSONAL_SCOPES.includes(category.scope)) {
        userPersonalPermissions.push(category);
      } else if (USER_WORK_SCOPES.includes(category.scope)) {
        userWorkPermissions.push(category);
      } else if (USER_COMPENSATIONS_SCOPES.includes(category.scope)) {
        userCompensationPermissions.push(category);
      } else if (USER_CONTACT_SCOPES.includes(category.scope)) {
        userContactPermissions.push(category);
      } else if (ADMINISTRATION_SCOPES.includes(category.scope)) {
        userAdministrationPermissions.push(category);
      }
    }

    if (userPersonalPermissions.length > 0)
      allCategories.push({
        name: 'Personal profile',
        subcategories: [{ name: GENERAL, permissions: userPersonalPermissions }],
      });
    if (userWorkPermissions.length > 0)
      allCategories.push({
        name: 'Work profile',
        subcategories: [{ name: GENERAL, permissions: userWorkPermissions }],
      });
    if (userCompensationPermissions.length > 0)
      allCategories.push({
        name: 'Compensation profile',
        subcategories: [{ name: GENERAL, permissions: userCompensationPermissions }],
      });
    if (userContactPermissions.length > 0)
      allCategories.push({
        name: 'Contact profile',
        subcategories: [{ name: GENERAL, permissions: userContactPermissions }],
      });
  }

  // for company merge to administration
  const companyCategory = allCategories.find((c) => c.name === 'Company');
  if (companyCategory && companyCategory.subcategories.length !== 0) {
    const companyCategoryIndex = allCategories.indexOf(companyCategory);
    for (const category of companyCategory.subcategories[0].permissions) {
      if (ADMINISTRATION_SCOPES.includes(category.scope)) {
        userAdministrationPermissions.push(category);
        allCategories = allCategories.filter((cat, index) => index !== companyCategoryIndex);
      }
    }

    if (userAdministrationPermissions.length > 0)
      allCategories.push({
        name: 'Administration',
        subcategories: [
          {
            name: GENERAL,
            permissions: userAdministrationPermissions.sort((a, b) => {
              if (a.scope === 'user.onboard:all' || b.scope === 'user.onboard:all')
                return a.scope === 'user.onboard:all' ? 10 : -10;
              return a.scope > b.scope ? 1 : -1;
            }),
          },
        ],
      });
  }

  // for calendar merge to absence
  const calenderCategory = allCategories.find((c) => c.name === 'Calendar');
  if (calenderCategory && calenderCategory.subcategories.length !== 0) {
    const absenceCategory = allCategories.find((c) => c.name === 'Absence');
    if (absenceCategory && absenceCategory.subcategories) {
      const record = absenceCategory.subcategories.find((item) => item.name === 'General');
      let index = 0;
      if (record) {
        index = absenceCategory.subcategories.indexOf(record);
        absenceCategory.subcategories[index].permissions.push(...calenderCategory.subcategories[0].permissions);
      } else {
        absenceCategory.subcategories[index].permissions = calenderCategory.subcategories[0].permissions;
      }
    }
  }

  // for pension and insurance merge to user-benefits
  const pensionCategory = allCategories.find((c) => c.name === 'Pension');
  const insuranceCategory = allCategories.find((c) => c.name === 'Insurance');
  const combinedBenefits = [];
  if (pensionCategory && pensionCategory.subcategories.length !== 0)
    combinedBenefits.push(...pensionCategory.subcategories[0].permissions);
  if (insuranceCategory && insuranceCategory.subcategories.length !== 0)
    combinedBenefits.push(...insuranceCategory.subcategories[0].permissions);

  if (combinedBenefits.length !== 0) {
    for (const category of combinedBenefits) {
      if (BENEFITS_SCOPES.includes(category.scope)) {
        userBenefitsPermissions.push(category);
      }
    }

    if (userBenefitsPermissions.length > 0)
      allCategories.push({
        name: 'Benefits',
        subcategories: [{ name: GENERAL, permissions: userBenefitsPermissions }],
      });
  }

  const documentsCategory = allCategories.find((c) => c.name === 'Documents');
  const templatesCategory = allCategories.find((c) => c.name === 'Templates');
  const combinedDocuments = [];
  if (documentsCategory && documentsCategory.subcategories.length !== 0)
    combinedDocuments.push(...documentsCategory.subcategories[0].permissions);
  if (templatesCategory && templatesCategory.subcategories.length !== 0)
    combinedDocuments.push(...templatesCategory.subcategories[0].permissions);

  if (combinedDocuments.length !== 0) {
    for (const category of combinedDocuments) {
      if (DOCUMENTS_SCOPES.includes(category.scope)) {
        documentPermissions.push(category);
      }
    }

    if (documentPermissions.length > 0 && documentsCategory) {
      documentsCategory.subcategories = [{ name: GENERAL, permissions: documentPermissions }];
    }
  }

  let taskCategory = allCategories.find((c) => c.name === 'Task');
  if (taskCategory) taskCategory.name = 'Tasks';

  let reportsCategory = allCategories.find((c) => c.name === 'Reports');
  if (reportsCategory) reportsCategory.name = 'Reports';

  // merge surveys and reviews scopes under growth category
  let growthCategory = allCategories.find((c) => c.name === 'Reviews');
  let surveysCategory = allCategories.find((c) => c.name === 'Surveys');
  if (
    growthCategory &&
    growthCategory.subcategories.length !== 0 &&
    surveysCategory &&
    surveysCategory.subcategories.length !== 0
  ) {
    for (const eachSurveyPermission of surveysCategory.subcategories[0].permissions) {
      growthCategory.subcategories[0].permissions.push(eachSurveyPermission);
    }
  }

  // removing user, billing, pension, insurance, templates, surveys and calendar from categories
  allCategories = allCategories.filter(
    (cat) =>
      cat.name !== 'Billing' &&
      cat.name !== 'Insurance' &&
      cat.name !== 'Pension' &&
      cat.name !== 'Templates' &&
      cat.name !== 'User' &&
      cat.name !== 'Calendar'
  );

  return getSidebarSort(allCategories);
}

export function getScopeCategories(permissions: PermissionDto[]): PermissionCategory[] {
  const categories = Array.from(new Set(permissions.map((permission) => getScopeEntity(permission.scope))));
  let allCategories = categories.map((category) => ({
    name: capitalize(category),
    subcategories: getScopeSubcategories(permissions.filter((permission) => permission.scope.startsWith(category))),
  }));

  return formatUserCategories(allCategories);
}

export const DEFAULT_GROUPS = ['Profile Owner', 'Manager', 'Other Profiles'];

export const ADMINISTRATION_ADDITIONAL_SCOPES: Scope[] = [
  // COMPENSATION
  'user.compensation:all',
  'user.bankAccount:all',
  'user.equity:all',
  // WORK PROFILE
  'user.role:all',
  'user.workContact:all',
  'user.lifecycle:all',
  'user.rightWork:all',
  // TASKS
  'task:all',
  // DOCUMENTS
  'documents:all',
  'templates:all',
  // APPS
  'apps:all',
  // DEVICES
  'devices:all',
];

export const PAYROLL_ADDITIONAL_SCOPES: Scope[] = [
  // COMPENSATION
  'user.compensation:all',
  'user.bankAccount:all',
  'user.equity:all',
  // WORK PROFILE
  'user.role:all',
  'user.workContact:all',
  'user.lifecycle:all',
  'user.rightWork:all',
  // PAYMENTS
  'payments:all',
  // PENSION
  'pension:all',
  // APPS
  'apps:all',
];

export const getAdditionalScopesFromCategories = (
  permissions: PermissionDto[]
): {
  Administration: PermissionDto[];
  Payroll: PermissionDto[];
} => {
  return {
    Administration: permissions.filter((p) => ADMINISTRATION_ADDITIONAL_SCOPES.includes(p.scope)),
    Payroll: permissions.filter((p) => PAYROLL_ADDITIONAL_SCOPES.includes(p.scope)),
  };
};

export function getPermissionGroupsReportsLevelOptionsForManagerGroup(polyglot: Polyglot): OptionObject[] {
  return [
    {
      value: 1,
      label: translateManagerPermissionGroupReportsLevel(1, polyglot),
    },
    {
      value: 2,
      label: translateManagerPermissionGroupReportsLevel(2, polyglot),
    },
    {
      value: 3,
      label: translateManagerPermissionGroupReportsLevel(3, polyglot),
    },
    {
      value: AllManagerPermissionLevels,
      label: translateManagerPermissionGroupReportsLevel(AllManagerPermissionLevels, polyglot),
    },
  ];
}
