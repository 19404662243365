import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ApiError } from '@v2/infrastructure/api-error/api-error.interface';
import { getApiErrorMessage } from '@v2/infrastructure/api-error/api-error.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { nestErrorMessage } from '@/lib/errors';
import { USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortDate } from '@/v2/components/table/table-sorting.util';
// import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import {
  TaskAndChecklistItemCommon,
  TaskViewModal,
} from '@/v2/feature/task/components/task-view/task-view-modal.component';
import { getTaskStatus } from '@/v2/feature/task/components/task.util';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { TaskDto } from '@/v2/feature/task/task.dto';
import { TaskStatuses } from '@/v2/feature/task/task.interface';
import { CachedUsersProvider } from '@/v2/feature/user/context/cached-users.context';
import { CloseButton } from '@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const OnboardingTasksPage = () => {
  const { polyglot } = usePolyglot();
  const userId = useUserIdParam();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [tasks, setTasks] = useState<TaskDto[]>();
  const [selectedTask, setSelectedTask] = useState<TaskDto | null>(null);
  const [isTaskViewModalOpen, setIsTaskViewModalOpen] = useState<boolean>(false);

  const returnToOnboardingSummary = useCallback(() => {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
  }, [routerHistory, userId]);

  const loadTasks = useCallback(async () => {
    if (!Number.isInteger(userId)) {
      return;
    }
    try {
      const fetchedTasks = await TaskAPI.listTasks(userId);
      // sort tasks with incomplete at the top
      const sortedTaskItems = fetchedTasks.sort((a, b) => {
        const aStatus = a.status === 'Complete' ? 1 : 0;
        const bStatus = b.status === 'Complete' ? 1 : 0;

        return aStatus - bStatus;
      });
      const incompleteTasks = fetchedTasks.filter((task) => task.status !== 'Complete');
      if (incompleteTasks.length === 0) returnToOnboardingSummary();
      else setTasks(sortedTaskItems);
    } catch (e) {
      showMessage(
        polyglot.t('OnboardingByUser.errors.taskRetrieveFailed', {
          errorMessage: getApiErrorMessage(e as ApiError),
        }),
        'error'
      );
      setTasks(undefined);
    }
  }, [polyglot, returnToOnboardingSummary, showMessage, userId]);

  const updateTaskStatus = async (taskId: number, assignedUserId: number, status: TaskStatuses) => {
    try {
      await TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
      showMessage(polyglot.t('TaskActionItem.successMessages.updateStatus'), 'success');
      await loadTasks?.();
      if (selectedTask) setSelectedTask({ ...selectedTask, status });
    } catch (error) {
      showMessage(
        polyglot.t('TaskActionItem.errorMessages.updateStatus', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  const columnData = useMemo<ColumnDef<TaskDto, TaskDto>[]>(() => {
    return [
      {
        id: 'task',
        header: () => polyglot.t('OnboardingByUser.task'),
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (c) => (
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{c.getValue().name}</Typography>
        ),
        size: 20,
      },
      // {
      //   id: 'description',
      //   header: () => polyglot.t('OnboardingByUser.description'),
      //   accessorFn: (row) => row,
      //   enableSorting: false,
      //   cell: (c) => {
      //     const { description } = c.getValue();
      //     return description ? (
      //       <Stack sx={{ position: 'absolute', justifyContent: 'center', inset: 0 }}>
      //         <StyledTooltip title={description}>
      //           <Typography
      //             sx={{
      //               ...themeFonts.caption,
      //               overflow: 'hidden',
      //               textOverflow: 'ellipsis',
      //               color: themeColors.DarkGrey,
      //             }}
      //           >
      //             {description}
      //           </Typography>
      //         </StyledTooltip>
      //       </Stack>
      //     ) : (
      //       <EmptyCell />
      //     );
      //   },
      //   minSize: 350,
      // },
      {
        id: 'due-date',
        header: () => polyglot.t('OnboardingByUser.dueDate'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.dueDate),
        cell: (c) => {
          const dueDate = c.getValue().dueDate;
          return dueDate ? <DateLabelComponent date={dueDate} /> : <EmptyCell />;
        },
        size: 50,
      },
      {
        id: 'status',
        header: () => polyglot.t('TaskTable.status'),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        enableSorting: true,
        cell: ({ row: { original } }) => <div>{getTaskStatus(original, polyglot) ?? <EmptyCell />}</div>,
      },
      // {
      //   id: 'done-check',
      //   header: () => '',
      //   accessorFn: (row) => row,
      //   enableSorting: false,
      //   cell: (c) => (
      //     <IconButton
      //       sx={tablePrimaryIconButtonSx}
      //       disabled={!!selectedTask}
      //       onClick={() => setSelectedTask(c.getValue())}
      //     >
      //       <Chose />
      //     </IconButton>
      //   ),
      //   size: 60,
      // },
    ];
  }, [polyglot]);

  const closeTaskViewModal = useCallback(() => {
    setSelectedTask(null);
    setIsTaskViewModalOpen(false);
  }, []);

  return (
    <CachedUsersProvider>
      <Stack sx={{ flex: 1 }}>
        <Stack sx={{ flex: 0, mx: 'auto', width: '800px', maxWidth: '90vw', mt: spacing.mt40 }}>
          <Typography
            sx={{
              ...themeFonts.title2,
              color: themeColors.DarkGrey,
            }}
          >
            {polyglot.t('OnboardingByUser.completeTasks')}
          </Typography>
          <CloseButton onClick={() => returnToOnboardingSummary()} />
        </Stack>
        <Stack sx={{ flex: 1, overflowY: 'auto', mt: spacing.mt30 }}>
          <Stack sx={{ mx: 'auto', width: '800px', maxWidth: '90vw' }}>
            {!tasks && <LoadingSpinner />}
            {!!tasks?.length && (
              <>
                <Typography sx={{ ...themeFonts.caption, mb: spacing.mb30, color: themeColors.DarkGrey }}>
                  {polyglot.t('OnboardingByUser.completeTasksBellowLong')}
                </Typography>
                <BasicTable
                  rowData={tasks}
                  columnData={columnData}
                  hidePagination
                  rowClick={(row) => {
                    if (!selectedTask) {
                      setSelectedTask(row.original);
                      setIsTaskViewModalOpen(true);
                    }
                  }}
                />
              </>
            )}
            {tasks?.length === 0 && (
              <Typography sx={{ ...themeFonts.caption, mb: spacing.mb30, color: themeColors.DarkGrey }}>
                {polyglot.t('OnboardingByUser.noTasksToComplete')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {tasks && selectedTask && (
        <TaskViewModal
          isOpen={isTaskViewModalOpen}
          setIsOpen={setIsTaskViewModalOpen}
          onClose={closeTaskViewModal}
          task={selectedTask as TaskAndChecklistItemCommon}
          action={updateTaskStatus}
          onboardingClose={async (taskCompleted?: boolean) => {
            if (!taskCompleted) {
              return;
            }
            // if we completed a task, refresh the list
            await loadTasks();
          }}
        />
      )}
    </CachedUsersProvider>
  );
};
