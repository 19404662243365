import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { PolicyPayrollFormData } from '@v2/feature/absence/absence.interface';
import { getAbsencePolicyPayrollValidationSchema } from '@v2/feature/absence/company/policies/pages/absence-policy.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { AbsenceAPI } from '@/v2/feature/absence/absence.api';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const PolicyPayrollEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: PolicyPayrollFormData & { includedInPayroll: boolean }) => {
      const connectedToPayroll = values.includedInPayroll && (values.inPayrollRequests || values.inPayrollOffboarding);
      const toSubmit: PolicyPayrollFormData = {
        inPayrollRequests: connectedToPayroll && values.inPayrollRequests,
        inPayrollOffboarding: connectedToPayroll && values.inPayrollOffboarding,
        payCode: connectedToPayroll && values.payCode ? values.payCode : null,
        payCodeMultiplier:
          values.inPayrollRequests && values.payCodeMultiplier ? Number(values.payCodeMultiplier) : null,
      };
      try {
        setLoading(true);
        await AbsenceAPI.updateAbsencePolicyPayroll(absencePolicy.id, toSubmit);
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceUtil.errorMessages.badRequest', { nestErrorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );

  const formik = useFormik<PolicyPayrollFormData & { includedInPayroll: boolean }>({
    initialValues: {
      includedInPayroll: absencePolicy.inPayrollRequests || absencePolicy.inPayrollOffboarding,
      inPayrollRequests: absencePolicy.inPayrollRequests,
      inPayrollOffboarding: absencePolicy.inPayrollOffboarding,
      payCode: absencePolicy.payCode ?? '',
      payCodeMultiplier: absencePolicy.inPayrollRequests ? absencePolicy.payCodeMultiplier : null,
    },
    validationSchema: getAbsencePolicyPayrollValidationSchema(polyglot),
    onSubmit,
  });

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('AbsencePolicyRouter.payroll')}</Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="title4">Connected to payroll</Typography>
            <SwitchComponent
              checked={formik.values.includedInPayroll}
              name="prevent-own-overlaps"
              onChange={(_e, enabled) => {
                formik.setFieldValue('includedInPayroll', enabled);
                if (enabled) {
                  formik.setFieldValue('payCode', null);

                  formik.setFieldValue('inPayrollRequests', true);
                  formik.setFieldValue('payCodeMultiplier', 1);

                  formik.setFieldValue('inPayrollOffboarding', false);
                } else {
                  formik.setFieldValue('payCode', null);

                  formik.setFieldValue('inPayrollRequests', false);
                  formik.setFieldValue('payCodeMultiplier', null);

                  formik.setFieldValue('inPayrollOffboarding', false);
                }
              }}
            />
          </Box>

          {formik.values.includedInPayroll && (
            <TextfieldComponent
              name="payCode"
              label={polyglot.t('PayItemModule.payCode')}
              value={formik.values.payCode}
              onChange={formik.handleChange}
              error={formik.touched.payCode && !!formik.errors.payCode}
              helperText={(formik.touched.payCode && formik.errors.payCode) ?? ' '}
              endAdornment="none"
            />
          )}

          {formik.values.includedInPayroll && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="title4">
                  {polyglot.t('AbsencePolicyPayrollSection.approvedRequestsTitle')}
                </Typography>
                <SwitchComponent
                  checked={formik.values.inPayrollRequests}
                  name="prevent-own-overlaps"
                  onChange={(_e, enabled) => {
                    formik.setFieldValue('inPayrollRequests', enabled);
                    if (enabled) {
                      formik.setFieldValue('payCodeMultiplier', 1);
                    } else if (!enabled && !formik.values.inPayrollOffboarding) {
                      formik.setFieldValue('inPayrollRequests', enabled);
                    }
                  }}
                />
              </Box>
              <Typography variant="caption">
                {polyglot.t('AbsencePolicyPayrollSection.approvedRequestsDesc')}
              </Typography>
            </Box>
          )}

          {formik.values.includedInPayroll && formik.values.inPayrollRequests && (
            <TextfieldComponent
              name="payCodeMultiplier"
              label={polyglot.t('PayItemModule.payCodeMultiplier')}
              value={formik.values.payCodeMultiplier}
              onChange={formik.handleChange}
              error={formik.touched.payCodeMultiplier && !!formik.errors.payCodeMultiplier}
              helperText={(formik.touched.payCodeMultiplier && formik.errors.payCodeMultiplier) ?? ' '}
              endAdornment="none"
            />
          )}

          {formik.values.includedInPayroll && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="title4">
                  {polyglot.t('AbsencePolicyPayrollSection.accruedHolidayTitle')}
                </Typography>
                <SwitchComponent
                  checked={formik.values.inPayrollOffboarding}
                  name="prevent-own-overlaps"
                  onChange={(_e, enabled) => {
                    formik.setFieldValue('inPayrollOffboarding', enabled);
                  }}
                />
              </Box>
              <Typography variant="caption">{polyglot.t('AbsencePolicyPayrollSection.accruedHolidayDesc')}</Typography>
            </Box>
          )}

          {formik.values.includedInPayroll && !formik.values.inPayrollOffboarding && !formik.values.inPayrollRequests && (
            <Typography variant="caption" color="RedDark">
              {polyglot.t('AbsencePolicyPayrollSection.pleaseSelect1PayrollOption')}
            </Typography>
          )}

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
