import { useMemo, useState } from 'react';

import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { PolicyPayrollEditDrawer } from '@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-payroll-edit-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

interface SectionProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const AbsencePolicyPayrollSection = ({ absencePolicy, refresh }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);

  const isPayrollConnected = useMemo(() => {
    if (!absencePolicy) return false;

    return absencePolicy.inPayrollRequests || absencePolicy.inPayrollOffboarding;
  }, [absencePolicy]);

  return (
    <SettingsSectionContent
      title={polyglot.t('AbsencePolicyNotificationsSection.payroll')}
      onEdit={() => setIsEditDrawerOpen(true)}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PayrollModule.connectedToPayroll'),
                value: isPayrollConnected ? polyglot.t('General.yes') : polyglot.t('General.no'),
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PayItemModule.payCode'),
                value: absencePolicy.payCode ?? polyglot.t('General.none'),
                hidden: !isPayrollConnected,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyPayrollSection.approvedRequests'),
                value: absencePolicy.inPayrollRequests ? polyglot.t('General.enabled') : polyglot.t('General.disabled'),
                hidden: !isPayrollConnected,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('PayItemModule.payCodeMultiplier'),
                value: polyglot.t('AttendanceModule.Penalties.dayRateTimesMultiplier', {
                  multiplier: absencePolicy.payCodeMultiplier,
                }),
                hidden:
                  !isPayrollConnected || !absencePolicy.inPayrollRequests || absencePolicy.payCodeMultiplier === null,
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AbsencePolicyPayrollSection.accruedHoliday'),
                value: absencePolicy.inPayrollOffboarding
                  ? polyglot.t('General.enabled')
                  : polyglot.t('General.disabled'),
                hidden: !isPayrollConnected,
              },
            ],
          },
        ]}
      />

      <PolicyPayrollEditDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        absencePolicy={absencePolicy}
        refresh={refresh}
      />
    </SettingsSectionContent>
  );
};
