import { Fragment } from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';

import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import '@/v2/feature/dashboard/features/sections/user-shift/user-shift.scss';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserShiftLoader = () => {
  return (
    <WidgetLayout readOnly={false} size="small" isLoadingState={true}>
      <Fragment>
        <Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: spacing.p5,
            }}
          >
            <Typography variant="title2">Shift</Typography>
            <Typography variant="captionSmall">{format(new LocalDate().getDate(), 'EEEE dd')}</Typography>
          </div>
        </Box>

        <Box sx={{ height: '100%', padding: spacing.p5 }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              gap: spacing.g35,
            }}
          >
            {[0, 1, 2].map((num) => (
              <div className="us-loader-container" key={`${num}-shift`}>
                <div className="us-progress" />
              </div>
            ))}
          </Box>
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
