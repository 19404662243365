import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown, AccrualCliff, HolidaysProratingRule } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours, isHourlyPolicy, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import { LocalDate } from '@v2/util/local-date';
import { round2Digits } from '@v2/util/number.util';
import Polyglot from 'node-polyglot';

export const getAnnualBalanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown || breakdown.userAllowance === null) return 'Unknown';

  if (isUnlimitedPolicy(absencePolicy)) return polyglot.t('AllowanceDrawer.unlimited');
  const isHourly = isHourlyPolicy(absencePolicy);

  if (isHourly) return polyglot.t('AllowanceDrawer.noOfHours', { smart_count: breakdown.userAllowance });

  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: breakdown.userAllowance });
};

export const getCurrentBalanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (isUnlimitedPolicy(absencePolicy)) return null;

  if (!breakdown || breakdown.currentBalance === null || breakdown.currentBalanceInDays === null)
    return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(breakdown.currentBalance, polyglot);

  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: breakdown.currentBalanceInDays });
};

export const getProratedAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy)) return null;

  if (!breakdown || breakdown.proRatedAbsenceAllowance.proRatedAbsenceAllowance === null)
    return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const proratedAllowance = breakdown.proRatedAbsenceAllowance.proRatedAbsenceAllowance ?? 0;
  const sign = includeSign && proratedAllowance > 0 ? '+' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(proratedAllowance, polyglot)}`;

  const allowanceInDays = round2Digits(proratedAllowance / breakdown.currentAverageWorkDayLength);

  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: allowanceInDays ?? 0 });
};

export const getUsedFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const usedTotal = breakdown.unitsTaken.history + (breakdown.unitsTaken.publicHolidaysHistory ?? 0);
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotal, polyglot);

  const usedInDays = round2Digits(usedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getUsedFromAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const usedTotal = breakdown.unitsTaken.history;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotal, polyglot);

  const usedInDays = round2Digits(usedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getPendingUsedFromAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const usedTotalPending = breakdown.unitsTaken.historyPending ?? 0;
  if (usedTotalPending === 0) return null;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotalPending, polyglot);

  const usedInDays = round2Digits(usedTotalPending / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getUsedFromPHAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const usedTotal = breakdown.unitsTaken.publicHolidaysHistory ?? 0;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotal, polyglot);

  const usedInDays = round2Digits(usedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getBookedFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');
  const isHourly = isHourlyPolicy(absencePolicy);

  const bookedTotal = breakdown.unitsTaken.upcoming + (breakdown.unitsTaken.publicHolidaysUpcoming ?? 0);

  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(bookedTotal, polyglot);

  const upcomingInDays = round2Digits(bookedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: upcomingInDays ?? 0 });
};

export const getPendingBookedFromAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const usedTotalPending = breakdown.unitsTaken.upcomingPending ?? 0;
  if (usedTotalPending === 0) return null;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotalPending, polyglot);

  const usedInDays = round2Digits(usedTotalPending / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getBookedFromAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');

  const isHourly = isHourlyPolicy(absencePolicy);

  const bookedTotal = breakdown.unitsTaken.upcoming;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(bookedTotal, polyglot);

  const usedInDays = round2Digits(bookedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getBookedFromPHAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string => {
  if (!breakdown) return polyglot.t('AllowanceDrawer.unknown');
  const isHourly = isHourlyPolicy(absencePolicy);

  const bookedTotal = breakdown.unitsTaken.publicHolidaysUpcoming ?? 0;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(bookedTotal, polyglot);

  const usedInDays = round2Digits(bookedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: usedInDays ?? 0 });
};

export const getPublicHolidayAllowanceFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.publicHolidayOnTop) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance === null)
    return polyglot.t('AllowanceDrawer.unknown');

  const sign = includeSign && breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance > 0 ? '+' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(
      breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance,
      polyglot
    )}`;

  const phAllowanceInDays = round2Digits(
    (breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance ?? 0) / breakdown.currentAverageWorkDayLength
  );
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: phAllowanceInDays ?? 0 });
};

export const getCarryOverFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled)
    return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign: '', smart_count: 0 });

  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.carryOver.allowedUnitsThisCycle === null) return polyglot.t('AllowanceDrawer.unknown');

  const sign = includeSign && breakdown.carryOver.allowedUnitsThisCycle > 0 ? '+' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.allowedUnitsThisCycle, polyglot)}`;

  const carryOverInDays = round2Digits(
    breakdown.carryOver.allowedUnitsThisCycle / breakdown.currentAverageWorkDayLength
  );
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: carryOverInDays ?? 0 });
};

export const getAccrualRoundingAdjustment = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!breakdown?.proRatedAbsenceAllowance) return polyglot.t('AllowanceDrawer.unknown');
  if (
    breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceRounded === null ||
    breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceNonRounded === null
  )
    return null;

  const roundingValue =
    breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceRounded -
    breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceNonRounded;
  if (!roundingValue) return null;

  const isHourly = isHourlyPolicy(absencePolicy);

  const sign = includeSign && roundingValue > 0 ? '+' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(roundingValue, polyglot)}`;

  const roundingInDays = round2Digits(roundingValue / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: roundingInDays ?? 0 });
};

export const getOneOffAdjustmentFromBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.oneOffAdjustment === null) return polyglot.t('AllowanceDrawer.unknown');

  const sign = includeSign && breakdown.oneOffAdjustment > 0 ? '+' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.oneOffAdjustment, polyglot)}`;

  const oneOffAdjustmentInDays = round2Digits(breakdown.oneOffAdjustment / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: oneOffAdjustmentInDays ?? 0 });
};

export const getTotalAllowanceBreakdown = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (!breakdown || breakdown.totalAllowanceForCycle === null) return polyglot.t('AllowanceDrawer.unknown');

  if (isUnlimitedPolicy(absencePolicy)) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  const totalAllowance = breakdown.totalAllowanceForCycle;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(totalAllowance, polyglot);

  const totalProratedAllowance = round2Digits(totalAllowance / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: totalProratedAllowance ?? 0 });
};

export const getAccrualBreakdownValue = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  variant: 'total' | 'regular' | 'publicHolidays',
  polyglot: Polyglot,
  includeSign = false // if true, shows +sign next to value when value is positive / minus is always shown
): string | null => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!absencePolicy.accrualUnit || !absencePolicy.accrualUnitsNo) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (
    !breakdown ||
    (variant === 'total' && breakdown.holidayAccrued === null) ||
    (variant === 'regular' && breakdown.proRatedAbsenceAllowance.totalRegularAbsenceAccrued === null) ||
    (variant === 'publicHolidays' && breakdown.proRatedAbsenceAllowance.totalPublicHolidaysAccrued === null)
  )
    return polyglot.t('AllowanceDrawer.unknown');

  const valueInMinutes =
    variant === 'total'
      ? breakdown.holidayAccrued
      : variant === 'regular'
      ? breakdown.proRatedAbsenceAllowance.totalRegularAbsenceAccrued
      : breakdown.proRatedAbsenceAllowance.totalPublicHolidaysAccrued;

  const sign = includeSign && valueInMinutes !== null && valueInMinutes > 0 ? '+' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(valueInMinutes, polyglot)}`;

  const totalAccruedInDays = round2Digits((valueInMinutes ?? 0) / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: totalAccruedInDays ?? 0 });
};

export const getAccrualRemainingValue = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!absencePolicy.accrualUnit || !absencePolicy.accrualUnitsNo) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.proRatedAbsenceAllowance.totalAccruedWithoutPHAccrualUsedOrBooked === null)
    return polyglot.t('AllowanceDrawer.unknown');

  const valueInMinutes =
    breakdown.proRatedAbsenceAllowance.totalAccruedWithoutPHAccrualUsedOrBooked - (breakdown.unitsTaken.history ?? 0);

  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(valueInMinutes, polyglot);

  const totalAccruedInDays = round2Digits((valueInMinutes ?? 0) / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: totalAccruedInDays ?? 0 });
};

export const getExpiredCarryOver = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.carryOver.allowedUnitsThisCycle === null || breakdown.carryOver.expiredCarryOver === null)
    return null;
  if (!breakdown.carryOver.isCarryOverExpired) return null;

  const sign = includeSign && breakdown.carryOver.expiredCarryOver > 0 ? '-' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.expiredCarryOver, polyglot)}`;

  const carryOverInDays = round2Digits(breakdown.carryOver.expiredCarryOver / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: carryOverInDays ?? 0 });
};

export const getExpiredUnusedCarryOver = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (
    !breakdown ||
    breakdown.carryOver.unusedUnitsLastCycle === null ||
    breakdown.carryOver.allowedUnitsThisCycle === null
  )
    return null;
  if (!breakdown.carryOver.unusedUnitsLastCycle) return null;

  const sign = includeSign && breakdown.carryOver.unusedUnitsLastCycle > 0 ? '-' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.unusedUnitsLastCycle, polyglot)}`;

  const carryOverInDays = round2Digits(
    breakdown.carryOver.unusedUnitsLastCycle / breakdown.currentAverageWorkDayLength
  );
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: carryOverInDays ?? 0 });
};

export const getUsedCarryOver = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.carryOver.unusedUnitsLastCycle === null || breakdown.carryOver.used === null) return null;
  if (!breakdown.carryOver.used) return null;

  const sign = includeSign && breakdown.carryOver.used > 0 ? '-' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.used, polyglot)}`;

  const carryOverInDays = round2Digits(breakdown.carryOver.used / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: carryOverInDays ?? 0 });
};

export const getRemainingCarryOver = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot,
  includeSign = false
): string | null => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  if (!breakdown || breakdown.carryOver.unusedUnitsLastCycle === null || breakdown.carryOver.remaining === null)
    return null;
  if (!breakdown.carryOver.remaining) return null;

  const sign = includeSign && breakdown.carryOver.remaining > 0 ? '-' : '';
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.remaining, polyglot)}`;

  const carryOverInDays = round2Digits(breakdown.carryOver.remaining / breakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDaysWithSign', { sign, smart_count: carryOverInDays ?? 0 });
};

export const getCarryOverExpiryOn = (
  absencePolicy: AbsencePolicyDto,
  breakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (
    !breakdown ||
    !absencePolicy.carryOverEnabled ||
    !absencePolicy.carryOverExpirationDay ||
    !absencePolicy.carryOverExpirationDay ||
    !breakdown.carryOver.expiryDate
  )
    return null;

  const expiryDate = breakdown.carryOver.expiryDate;
  const dateString = new LocalDate(expiryDate).toLocaleDateString(undefined, { month: 'short', day: '2-digit' });
  return polyglot.t('AllowanceDrawer.expiryOnDate', { date: dateString });
};

export const getHolidaysProratingRuleShort = (absencePolicy: AbsencePolicyDto, polyglot: Polyglot): string => {
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated)
    return polyglot.t('AllowanceDrawer.phRuleAllocateAllInPeriodProrated');
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)
    return polyglot.t('AllowanceDrawer.phRuleAllocateFullValueProrated');
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriod)
    return polyglot.t('AllowanceDrawer.phRuleAllocateAllInPeriod');
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInCycle)
    return polyglot.t('AllowanceDrawer.phRuleAllocateAllInCycle');

  return polyglot.t('AllowanceDrawer.unknown');
};

export const getHolidaysProratingRuleLong = (absencePolicy: AbsencePolicyDto, polyglot: Polyglot): string => {
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated)
    return polyglot.t('AllowanceDrawer.phRuleAllocateAllInPeriodProratedLong');
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)
    return polyglot.t('AllowanceDrawer.phRuleAllocateFullValueProratedLong');
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriod)
    return polyglot.t('AllowanceDrawer.phRuleAllocateAllInPeriodLong');
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInCycle)
    return polyglot.t('AllowanceDrawer.phRuleAllocateAllInCycleLong');

  return polyglot.t('AllowanceDrawer.unknown');
};

export const getAllowanceProratingRuleShortDesc = (absencePolicy: AbsencePolicyDto, polyglot: Polyglot): string => {
  if (absencePolicy.proratingStartDate && absencePolicy.proratingFte)
    return polyglot.t('AllowanceDrawer.phRuleAllocateFullValueProrated');
  if (absencePolicy.proratingStartDate && !absencePolicy.proratingFte)
    return polyglot.t('AllowanceDrawer.allowanceProratingRuleStartDateShort');

  return polyglot.t('AllowanceDrawer.noProration');
};

export const getAllowanceProratingRuleLongDesc = (absencePolicy: AbsencePolicyDto, polyglot: Polyglot): string => {
  if (absencePolicy.proratingStartDate && absencePolicy.proratingFte)
    return polyglot.t('AllowanceDrawer.allowanceProratingRuleFTELong');
  if (absencePolicy.proratingStartDate && !absencePolicy.proratingFte)
    return polyglot.t('AllowanceDrawer.allowanceProratingRuleStartDateLong');

  return polyglot.t('AllowanceDrawer.noProration');
};

export const isoDateToLocale = (date: string | Date | number | null): string => {
  return new LocalDate(date).toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const getRoundingAdjustment = (
  absencePolicy: AbsencePolicyDto,
  policyBreakdown: AbsenceBreakdown | null,
  polyglot: Polyglot
): string | null => {
  if (!policyBreakdown) return null;
  const isHourly = isHourlyPolicy(absencePolicy);

  const difference =
    (policyBreakdown.proRatedAbsenceAllowance.totalProratedAllowance ?? 0) -
    (policyBreakdown.proRatedAbsenceAllowance.totalProratedAllowanceNonRounded ?? 0);
  if (difference === 0) return null;

  if (isHourly || !policyBreakdown.isOnRegularSchedule || !policyBreakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(difference, polyglot);

  const differenceInDays = round2Digits(difference / policyBreakdown.currentAverageWorkDayLength);
  return polyglot.t('AllowanceDrawer.noOfDays', { smart_count: differenceInDays ?? 0 });
};

export const getAccrualCliffReason = (breakdown: AbsenceBreakdown | null, polyglot: Polyglot): string | null => {
  if (!breakdown?.proRatedAbsenceAllowance?.accrualCliff || !breakdown.proRatedAbsenceAllowance.didNotPassAccrualCliff)
    return null;

  const accrualCliff = breakdown.proRatedAbsenceAllowance.accrualCliff;

  if (accrualCliff === AccrualCliff.Probation) return polyglot.t('AllowanceDrawer.accrualStartsAfterProbation');

  return '';
};
