import { Box, Typography } from '@mui/material';
import { DeviceDto, DeviceModelDto } from '@v2/feature/device/device.dto';
import { DeviceOSOther, DeviceOSValueLabelOptions } from '@v2/feature/device/device.util';

import {
  deviceListSx,
  deviceSx,
  deviceTermSx,
  devicetitleSx,
  deviceValueSx,
} from '@/v2/feature/device/features/device-cards/components/devices-styles.layout';

interface DeviceTechSpecsCardProps {
  readonly deviceModel: DeviceModelDto;
  readonly device: DeviceDto | undefined;
}

export const DeviceTechSpecsCard = ({ deviceModel, device }: DeviceTechSpecsCardProps) => {
  return (
    <Box component="header" sx={{ width: '100%' }}>
      <Typography sx={devicetitleSx}>Tech specs</Typography>
      <Box component="section">
        <Box component="dl" sx={deviceListSx}>
          {(device?.modelName || deviceModel.modelName) && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                Model Name
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {device?.modelName ?? deviceModel.modelName}
              </Typography>
            </Box>
          )}

          {(device?.modelNumber || deviceModel.modelNumber) && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                Model Number
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {device?.modelNumber ?? deviceModel.modelNumber}
              </Typography>
            </Box>
          )}

          {deviceModel.os && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                Platform
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {DeviceOSValueLabelOptions.find((opt) => opt.value === deviceModel.os)?.label || DeviceOSOther.label}
              </Typography>
            </Box>
          )}

          {device?.serialNumber && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                Serial Number
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {device?.serialNumber}
              </Typography>
            </Box>
          )}

          {deviceModel?.ram && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                Memory
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {deviceModel?.ram}GB
              </Typography>
            </Box>
          )}

          {deviceModel?.storage && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                Storage
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {deviceModel?.storage}GB
              </Typography>
            </Box>
          )}

          {deviceModel?.cpuCores && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                CPU
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {deviceModel?.cpuCores}-cores
              </Typography>
            </Box>
          )}

          {deviceModel?.gpuCores && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                GPU
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {deviceModel?.gpuCores}-cores
              </Typography>
            </Box>
          )}

          {device?.customerNotes && (
            <Box sx={deviceSx}>
              <Typography component="dt" variant="body2" sx={deviceTermSx}>
                GPU
              </Typography>
              <Typography component="dd" variant="body2" sx={deviceValueSx}>
                {device?.customerNotes}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
