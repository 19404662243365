import { useCallback, useEffect, useState } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';

import { useCompanyConfigState } from '@/hooks/company-config.hook';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AccountingJournal } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/accounting/accounting-journal.section';
import { DownloadPayslip } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/download-payslips.section';
import { HMRCSubmission } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/hmrc-submission.section';
import { PayrunPayments } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-payments.section';
import { PensionContributions } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/pension/pension-contributions.section';
import {
  AccountingJournalState,
  AccountingJournalStates,
  DownloadPayslipsState,
  DownloadPayslipsStates,
  HMRCSubmissionState,
  HMRCSubmissionStates,
  PayrunPaymentsState,
  PayrunPaymentsStates,
  PensionContributionState,
  PensionContributionStates,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayRunDto } from '@/v2/feature/payroll/payroll.dto';
import { ExternalDataProvider, PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrunProcessSectionProps = {
  localPayRun: PayRunDto;
  hideTitle?: boolean;
  completePayrun: (localPayrun: PayRunDto) => Promise<boolean>;
  sx?: SxProps<Theme>;
  onNext?: () => void;
};

export const PayrunProcessSection = ({
  localPayRun,
  hideTitle,
  completePayrun,
  onNext,
  sx,
}: PayrunProcessSectionProps) => {
  const { companyConfig } = useCompanyConfigState();

  const [completingPayrun, setCompletingPayrun] = useState(false);
  const [payrunProcessFinished, setPayrunProcessFinished] = useState(false);

  const [downloadPayslipsState, setDownloadPayslipsState] = useState<DownloadPayslipsState>(
    DownloadPayslipsStates.notDownloaded
  );
  const [hmrcSubmissionState, setHmrcSubmissionState] = useState<HMRCSubmissionState>(
    HMRCSubmissionStates.notSubmitted
  );

  const [externalProviders, setExternalProviders] = useState<ExternalDataProvider[] | null>(null);
  const [pensionContributionsState, setPensionContributionsState] = useState<{
    [key: number]: PensionContributionState;
  }>(
    localPayRun.payrunPensions?.reduce(
      (acc, current) => {
        acc[current.pensionSchemeId] = PensionContributionStates.notSubmitted;
        return acc;
      },
      {} as {
        [key: number]: PensionContributionState;
      }
    ) ?? { 0: PensionContributionStates.notSubmitted }
  );
  const [accountingJournalState, setAccountingJournalState] = useState<AccountingJournalState>(
    AccountingJournalStates.notSubmitted
  );
  const [payrunPaymentsState, setPayrunPaymentsState] = useState<PayrunPaymentsState>(PayrunPaymentsStates.notCreated);

  const [showMessage] = useMessage();

  useEffect(() => {
    const isDownloadPayslipsFinished = downloadPayslipsState === DownloadPayslipsStates.downloaded;
    const isHMRCSubmissionSuccessful = [HMRCSubmissionStates.submitted, HMRCSubmissionStates.markedAsSent].includes(
      hmrcSubmissionState
    );

    const isPensionContributionSubmitted = !Object.keys(pensionContributionsState).some(
      (pensionSchemeId) =>
        ![PensionContributionStates.submitted, PensionContributionStates.markedAsSent].includes(
          pensionContributionsState[Number(pensionSchemeId)]
        )
    );

    const isAccountingJournalSubmitted = [
      AccountingJournalStates.submitted,
      AccountingJournalStates.markedAsSent,
    ].includes(accountingJournalState);

    const arePaymentsComplete = payrunPaymentsState === PayrunPaymentsStates.created;

    setPayrunProcessFinished(
      isDownloadPayslipsFinished &&
        isHMRCSubmissionSuccessful &&
        isPensionContributionSubmitted &&
        isAccountingJournalSubmitted &&
        arePaymentsComplete
    );
  }, [
    localPayRun.state,
    downloadPayslipsState,
    hmrcSubmissionState,
    pensionContributionsState,
    accountingJournalState,
    payrunPaymentsState,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const pensionProviders = await PensionAPI.getPensionExternalProviders(localPayRun.payrollId);
        setExternalProviders(pensionProviders);
      } catch (error) {
        showMessage(`Could not get pension status. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [localPayRun.payrollId, showMessage]);

  const isCompletedPayrun = localPayRun.state === PayrunStates.completed;
  const isPartiallyCompletedPayrun = localPayRun.state === PayrunStates.partiallyCompleted;

  const markPayrunAsCompleted = useCallback(async () => {
    setCompletingPayrun(true);
    const completed = await completePayrun(localPayRun);
    setCompletingPayrun(false);
    if (completed) {
      onNext?.();
    }
  }, [completePayrun, localPayRun, onNext]);

  return (
    <Stack sx={{ flex: 1, overflowY: 'auto', ...sx }}>
      {!hideTitle && (
        <>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Process payrun</Typography>
          {!isCompletedPayrun && !payrunProcessFinished && (
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              Payrun is being processed. This may take a few moments...
            </Typography>
          )}
          {isCompletedPayrun && (
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
              Payrun is completed.
            </Typography>
          )}
        </>
      )}

      <DownloadPayslip
        payRun={localPayRun}
        downloadPayslipsState={downloadPayslipsState}
        setDownloadPayslipsState={setDownloadPayslipsState}
        sx={{ borderTopColor: 'transparent' }}
      />
      <HMRCSubmission
        payRun={localPayRun}
        hmrcSubmissionState={hmrcSubmissionState}
        setHmrcSubmissionState={setHmrcSubmissionState}
      />
      {localPayRun.payrunPensions?.map((payrunPension, index) => (
        <PensionContributions
          key={index}
          payRun={localPayRun}
          pensionSchemeId={payrunPension.pensionSchemeId}
          providerName={payrunPension.pensionScheme?.providerName}
          externalProviders={externalProviders}
          isPensionSchemeSetUp={Boolean(companyConfig?.inPension)}
          pensionContributionsState={pensionContributionsState}
          setPensionContributionsState={setPensionContributionsState}
        />
      ))}
      <AccountingJournal
        payRun={localPayRun}
        accountingJournalState={accountingJournalState}
        setAccountingJournalState={setAccountingJournalState}
      />
      <PayrunPayments
        payRun={localPayRun}
        payrunPaymentsState={payrunPaymentsState}
        setPayrunPaymentsState={setPayrunPaymentsState}
      />

      <Stack sx={{ width: 'min-content' }}>
        {!isCompletedPayrun && payrunProcessFinished && (
          <LoaderButton
            name="Complete payrun"
            loading={completingPayrun}
            onClick={() => markPayrunAsCompleted()}
            sizeVariant="large"
            colorVariant="primary"
            style={{ marginTop: spacing.m30, marginBottom: spacing.m30, flex: `0 0 auto` }}
          />
        )}
        {!isCompletedPayrun && !payrunProcessFinished && !isPartiallyCompletedPayrun && (
          <LoaderButton
            name="Complete payrun later"
            loading={completingPayrun}
            onClick={() => markPayrunAsCompleted()}
            sizeVariant="large"
            colorVariant="primary"
            style={{ marginTop: spacing.m30, marginBottom: spacing.m30, flex: `0 0 auto` }}
          />
        )}
      </Stack>
    </Stack>
  );
};
