import { useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { StepperHeader } from '@v2/components/stepper-header.component';
import { StepperComponent } from '@v2/components/stepper.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { InsuranceSetupDataSharingStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-data-sharing-step.component';
import { InsuranceSetupEmployeesStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-employees-step.component';
import { InsuranceSetupExploreStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-explore-step.component';
import { InsuranceSetupQuoteStep } from '@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-quote-step.component';
import { BenefitsQuoteData } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { SETTINGS_BENEFITS_ROUTE } from '@/lib/routes';

enum InsuranceSetupSteps {
  Explore = 'Explore',
  Quote = 'Quote',
  Employees = 'Employees',
  DataSharing = 'Data sharing',
}

export interface InsuranceSetupStepperProps {
  readonly name: string;
  readonly refresh: () => Promise<void>;
}

export const InsuranceSetupStepper = ({ name, refresh }: InsuranceSetupStepperProps) => {
  const [quoteData, setQuoteData] = useState<BenefitsQuoteData>({
    outPatientTreatment: false,
    therapies: false,
    mentalHealth: false,
    dentalCare: false,
    opticalCare: false,
    travelCover: false,
    additionalNotes: null,
    displayName: name,
    selectedUsers: [],
  });
  const routerHistory = useHistory();
  const insuranceQuoteSteps = useMemo(
    () => [
      InsuranceSetupSteps.Explore,
      InsuranceSetupSteps.Quote,
      InsuranceSetupSteps.Employees,
      InsuranceSetupSteps.DataSharing,
    ],
    []
  );
  const [step, setStep] = useState<InsuranceSetupSteps>(InsuranceSetupSteps.Explore);
  const insuranceQuoteStepsComponent = useMemo(
    () => [
      {
        name: InsuranceSetupSteps.Explore,
        component: <InsuranceSetupExploreStep name={name} onNext={() => setStep(InsuranceSetupSteps.Quote)} />,
      },
      {
        name: InsuranceSetupSteps.Quote,
        component: (
          <InsuranceSetupQuoteStep
            name={name}
            onNext={() => setStep(InsuranceSetupSteps.Employees)}
            setQuoteData={setQuoteData}
          />
        ),
      },
      {
        name: InsuranceSetupSteps.Employees,
        component: (
          <InsuranceSetupEmployeesStep
            onNext={() => setStep(InsuranceSetupSteps.DataSharing)}
            quoteData={quoteData}
            setQuoteData={setQuoteData}
          />
        ),
      },
      {
        name: InsuranceSetupSteps.DataSharing,
        component: <InsuranceSetupDataSharingStep quoteData={quoteData} refresh={refresh} />,
      },
    ],
    [name, quoteData, refresh]
  );

  return (
    <ContentWrapper loading={false} border={false}>
      <Stack sx={{ flex: 1, mt: spacing.m20 }}>
        <StepperHeader
          content={<StepperComponent steps={insuranceQuoteSteps} activeStep={step} hideConnector />}
          contentSx={{ width: '80%', textAlign: 'center' }}
          showStepBack={true}
          stepBackAction={() => {
            if (insuranceQuoteSteps.indexOf(step) > 0)
              return setStep(insuranceQuoteSteps[insuranceQuoteSteps.indexOf(step) - 1]);
            routerHistory.goBack();
          }}
          stepBackSx={{
            width: '10%',
            textAlign: 'left',
          }}
          showBack={true}
          backAction={() => routerHistory.push(SETTINGS_BENEFITS_ROUTE)}
          backSx={{ width: '10%', textAlign: 'right' }}
        />
        <Box sx={{ mt: spacing.mt40 }} />

        {insuranceQuoteStepsComponent[insuranceQuoteSteps.indexOf(step)]?.component}
      </Stack>
    </ContentWrapper>
  );
};
