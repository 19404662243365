import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto, UpdatePensionGeneralDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly provider: PensionSchemeDto;
  readonly refresh: () => Promise<void>;
}

export const EditPensionGeneralDrawer = ({ isOpen, setIsOpen, provider, refresh }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditPensionGeneralDrawerContent setIsOpen={setIsOpen} refresh={refresh} provider={provider} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly provider: PensionSchemeDto;
  readonly refresh: () => Promise<void>;
}

export const EditPensionGeneralDrawerContent = ({ setIsOpen, refresh, provider }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<UpdatePensionGeneralDto>({
    initialValues: {
      displayName: provider.displayName ?? '',
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values: UpdatePensionGeneralDto) => updateProvider(values),
  });

  const updateProvider = async (data: UpdatePensionGeneralDto) => {
    try {
      setLoading(true);
      const updatePensionBody: UpdatePensionGeneralDto = {
        displayName: data.displayName,
      };
      await PensionAPI.updatePensionGeneralData(provider.id, updatePensionBody);
      showMessage(polyglot.t('PensionModule.pensionConnected'), 'success');
      setIsOpen(false);
      await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PensionModule.updatePension')}</Typography>

        <TextfieldComponent
          name="displayName"
          label={polyglot.t('General.displayName')}
          value={formik.values.displayName}
          onChange={formik.handleChange}
          error={formik.touched.displayName && !!formik.errors.displayName}
          helperText={(formik.touched.displayName && formik.errors.displayName) as string}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.update')}
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
