import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { OptionObj, SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@v2/components/theme-components/notification-modal.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { getIndexOfWeekInSchedule } from '@v2/feature/attendance/attendance-schedule.util';
import { AttendanceAPI, AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceDto, AttendanceScheduleDto, AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import {
  AttendanceStatus,
  CreateAttendanceEntry,
  DayNoToWeekDay,
  ScheduleTrackingType,
  WeekDay,
} from '@v2/feature/attendance/attendance.interface';
import {
  calculateTotalEntriesDuration,
  getAttendanceStatusIcon,
  getScheduleExpectedRegularAndBreakTimeByDay,
} from '@v2/feature/attendance/attendance.util';
import { RowEntry, SelectWorkOptions } from '@v2/feature/attendance/company/components/select-work-options.component';
import { AttendanceShiftEndpoints } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api';
import { UserShiftHandler } from '@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component';
import { AttendanceDrawerPayItemPreview } from '@v2/feature/payroll/features/pay-item/components/attendance-drawer-pay-item-preview.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { SiteEndpoints } from '@/api-client/site.api';
import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Location } from '@/images/side-bar-icons/Location.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { DrawerViewerItem } from '@/v2/feature/absence/components/drawer-viewer-item.component';
import { SummaryDetailsComponent } from '@/v2/feature/attendance/company/components/summary-details.component';

const DayNoToScheduleDay: { [dayNo: number]: WeekDay } = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

const adjustTimeToLogDate = (timeString: string, logDate: string): string => {
  if (!timeString) return '';
  return `${logDate}T${timeString.split('T')[1]}`;
};

const getAttendanceEntriesFromSchedule = (logDate: string, userSchedule: AttendanceScheduleDto): RowEntry[] => {
  if (!logDate || !userSchedule?.attendanceTypesAllowed || userSchedule.attendanceTypesAllowed.length === 0) return [];

  const date = new LocalDate(logDate);
  const dayInSchedule = DayNoToScheduleDay[date.getDate().getDay()];
  const daySchedule = userSchedule[dayInSchedule];
  const weekIndex = getIndexOfWeekInSchedule(userSchedule.startDateOfFirstWeek, logDate, userSchedule.noOfWeeks);

  if (!daySchedule || !daySchedule[weekIndex]) return [];

  const dayEntry = daySchedule[weekIndex]!;

  const regularTypeId = userSchedule.attendanceTypesAllowed.find((type) => type.name === 'Regular')?.id;
  if (!regularTypeId) return [];

  const startHour = adjustTimeToLogDate(dayEntry.from, logDate);
  const endHour = adjustTimeToLogDate(dayEntry.to, logDate);
  const startHourTimestamp = startHour ? new LocalDate(startHour).getDate() : null;
  const endHourTimestamp = endHour ? new LocalDate(endHour).getDate() : null;

  const breakTypeId = dayEntry.break
    ? userSchedule.attendanceTypesAllowed.find((type) => type.name === 'Break')?.id
    : null;
  return [
    {
      typeId: regularTypeId,
      startHour,
      endHour,
      startHourTimestamp,
      endHourTimestamp,
      priority: 1,
    },
    ...(breakTypeId
      ? [
          {
            typeId: breakTypeId,
            startHour: '',
            endHour: '',
            startHourTimestamp: null,
            endHourTimestamp: null,
            priority: 2,
          },
        ]
      : []),
  ];
};

interface TrackTimeDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly view: 'company' | 'team' | 'user';
  readonly userId?: number;
  readonly attendanceId?: number | undefined;
  readonly refresh: () => Promise<void>;
  readonly onClose?: () => void;
  readonly mode?: 'edit' | 'view';
  readonly logDate?: string;
  readonly afterClose?: () => void;
  readonly weekAttendance?: AttendanceDto[];
}

export const TrackTimeDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  attendanceId,
  onClose,
  afterClose,
  refresh,
  view,
  logDate,
  mode = undefined,
  weekAttendance,
}: TrackTimeDrawerProps) => {
  const { polyglot } = usePolyglot();
  const { hasScopes, getScopesContext } = useScopes();
  const canManageAttendanceForUser = userId && hasScopes(['attendance:manager'], getScopesContext({ userId }));
  const { data: selectedAttendance, isLoading, mutate: refreshAttendance } = useApiClient<AttendanceDto, Error>(
    attendanceId && userId ? AttendanceEndpoints.getAttendanceById(attendanceId, userId) : null,
    { suspense: false }
  );
  const { data: userSchedule, isLoading: isLoadingSchedule } = useApiClient<AttendanceScheduleDto, Error>(
    attendanceId && userId
      ? AttendanceEndpoints.getUserAttendanceScheduleByAttendanceId(userId, attendanceId)
      : userId
      ? AttendanceEndpoints.getUserAttendanceSchedule(userId)
      : null,
    { suspense: false }
  );

  const { data: userSite, isLoading: isLoadingSite } = useApiClient(
    userSchedule?.trackingType === ScheduleTrackingType.ClockInClockOut && userId
      ? SiteEndpoints.getUserSite(userId)
      : null
  );

  const { data: todaysShift, isLoading: isLoadingShift, mutate: refreshShift } = useApiClient(
    userSchedule?.trackingType === ScheduleTrackingType.ClockInClockOut && userId
      ? AttendanceShiftEndpoints.getTodaysShiftByUser(userId)
      : null
  );

  const refreshTodaysShift = useCallback(async () => {
    if (refreshShift) await refreshShift();
  }, [refreshShift]);

  const refreshLocalAttendance = useCallback(async () => {
    if (refreshAttendance) await refreshAttendance();
  }, [refreshAttendance]);

  const [isEditMode, setIsEditMode] = useState<boolean>(Boolean(mode === 'edit' || !attendanceId));

  useEffect(() => {
    if (isOpen) setIsEditMode(Boolean(mode === 'edit' || !attendanceId || attendanceId === 0));
  }, [mode, attendanceId, isOpen]);

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      afterClose={afterClose}
      loading={isLoading || isLoadingSchedule || isLoadingShift || isLoadingSite}
    >
      {selectedAttendance && !isEditMode ? (
        <ViewTrackTimeDrawerContent
          request={selectedAttendance!}
          setEditMode={setIsEditMode}
          refresh={async () => {
            await Promise.all([refresh(), refreshLocalAttendance()]);
          }}
          setIsOpen={setIsOpen}
          userSchedule={userSchedule}
        />
      ) : isEditMode &&
        (canManageAttendanceForUser ||
          userSchedule?.trackingType !== ScheduleTrackingType.ClockInClockOut ||
          (logDate && logDate === todaysShift?.logDate && todaysShift?.isEnded)) ? (
        <EditTrackTimeDrawerContent
          refresh={async () => {
            await Promise.all([refresh(), refreshLocalAttendance()]);
          }}
          userId={userId}
          setIsOpen={setIsOpen}
          setEditMode={setIsEditMode}
          request={selectedAttendance}
          view={view}
          logDate={selectedAttendance?.logDate ?? logDate}
          userSchedule={userSchedule}
        />
      ) : isEditMode &&
        (selectedAttendance?.logDate ?? logDate) &&
        userId &&
        userSchedule?.trackingType === ScheduleTrackingType.ClockInClockOut ? (
        <UserShiftHandler
          selectedDate={selectedAttendance?.logDate ?? logDate}
          refresh={async () => {
            await Promise.all([refresh(), refreshTodaysShift(), refreshLocalAttendance()]);
          }}
          shift={todaysShift}
          userSite={userSite}
          currentWeekAttendance={weekAttendance ?? []}
          attendanceSchedule={userSchedule}
          mode="drawer"
        />
      ) : (
        <Box>
          <Typography variant="title2">{polyglot.t('ViewTrackTimeDrawerContent.attendance')}</Typography>

          <Typography variant="caption" sx={{ mt: '20px' }}>
            {polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: 'Please try again.' })}
          </Typography>
        </Box>
      )}
    </DrawerModal>
  );
};

interface FormData {
  userId: number | undefined;
  logDate: string;
  attendanceEntries: RowEntry[];
  notes: string | null;
}

interface TrackTimeDrawerContentProps {
  readonly userId?: number;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly view: 'company' | 'team' | 'user';
  readonly request: AttendanceDto | null | undefined;
  readonly logDate?: string;
  readonly showDatePicker?: boolean;
  readonly userSchedule: AttendanceScheduleDto | null | undefined;
}

export const EditTrackTimeDrawerContent = ({
  userId,
  refresh,
  setIsOpen,
  setEditMode,
  request,
  logDate,
  view,
  showDatePicker = false,
  userSchedule,
}: TrackTimeDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { getCachedUserById } = useCachedUsers({ refresh: true });

  const user = useMemo(() => {
    const currentUserId = request?.userId ?? userId;
    return currentUserId ? getCachedUserById(currentUserId) : null;
  }, [request?.userId, userId, getCachedUserById]);

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (!values.userId) {
        showMessage(polyglot.t('EditTrackTimeDrawerContent.errorMessages.select'), 'error');
        return;
      }

      const filteredAttendanceEntries =
        values?.attendanceEntries?.filter(
          (a) => a.startHour.length > 0 && a.endHour.length > 0 && typeof a.typeId === 'number'
        ) ?? [];
      try {
        setLoading(true);
        const attendanceData = {
          userId: values.userId,
          logDate: values.logDate,
          notes: values.notes ? values.notes : null,
          attendanceEntries: filteredAttendanceEntries as CreateAttendanceEntry[],
        };

        if (request?.id) {
          await AttendanceAPI.updateAttendanceLogById(request.id, attendanceData!);
          showMessage(polyglot.t('EditTrackTimeDrawerContent.successMessages.update'), 'success');
        } else {
          await AttendanceAPI.createAttendanceLog(attendanceData!);
          showMessage(polyglot.t('EditTrackTimeDrawerContent.successMessages.submit'), 'success');
        }

        await refresh();
        if (setEditMode) setEditMode(false);
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('EditTrackTimeDrawerContent.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, request?.id, setIsOpen, setEditMode, showMessage, refresh]
  );

  const formik = useFormik<FormData>({
    initialValues: {
      userId: request?.userId ?? userId,
      logDate: request?.logDate ?? logDate ?? new LocalDate().toDateString(),
      attendanceEntries: request?.attendanceEntries
        ? request.attendanceEntries.map((entry, index) => ({
            ...entry,
            priority: index,
          }))
        : [],
      notes: request?.notes ?? '',
    },
    validationSchema: Yup.object({
      userId: Yup.number().integer().required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.userRequired')),
      logDate: Yup.string().required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.dateRequired')),
      attendanceEntries: Yup.array()
        .of(
          Yup.object({
            typeId: Yup.number()
              .integer()
              .typeError(polyglot.t('EditTrackTimeDrawerContent.errorMessages.workTypeInvalid'))
              .required(),
            startHour: Yup.string().required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.starthourRequired')),
            endHour: Yup.string().required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.endHourRequired')),
            startHourTimestamp: Yup.date()
              .typeError(polyglot.t('EditTrackTimeDrawerContent.errorMessages.dateInvalid'))
              .required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.starthourRequired')),
            endHourTimestamp: Yup.date()
              .min(Yup.ref('startHourTimestamp'), polyglot.t('EditTrackTimeDrawerContent.errorMessages.dateInvalid'))
              .typeError(polyglot.t('EditTrackTimeDrawerContent.errorMessages.dateInvalid'))
              .required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.endHourRequired')),
            priority: Yup.number().required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.priorityRequired')),
          })
        )
        .min(1, polyglot.t('EditTrackTimeDrawerContent.errorMessages.minOne'))
        .required(polyglot.t('EditTrackTimeDrawerContent.errorMessages.minOne')),
      notes: Yup.string().max(255, polyglot.t('EditTrackTimeDrawerContent.errorMessages.maximumChars')).notRequired(),
    }),
    onSubmit,
  });

  useEffect(() => {
    const entries =
      request?.attendanceEntries && request.attendanceEntries.length > 0
        ? request.attendanceEntries.map((entry, index) => ({
            ...entry,
            priority: index,
          }))
        : userSchedule
        ? getAttendanceEntriesFromSchedule(formik.values.logDate, userSchedule)
        : [];

    formik.setFieldValue('attendanceEntries', entries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request?.attendanceEntries, userSchedule, formik.values.logDate]);

  const totalTime = useMemo(() => calculateTotalEntriesDuration(formik.values.attendanceEntries, polyglot), [
    formik.values.attendanceEntries,
    polyglot,
  ]);

  const regularTypeId = useMemo(
    () => userSchedule?.attendanceTypesAllowed.find((type) => type.name === 'Regular')?.id ?? null,
    [userSchedule]
  );
  const breakTypeId = useMemo(
    () => userSchedule?.attendanceTypesAllowed.find((type) => type.name === 'Break')?.id ?? null,
    [userSchedule]
  );

  const scheduleExpectedWorkTime = useMemo(() => {
    const weekDay = DayNoToWeekDay[new LocalDate(formik.values.logDate).getDate().getDay()];
    return getScheduleExpectedRegularAndBreakTimeByDay(weekDay, userSchedule, formik.values.logDate);
  }, [userSchedule, formik.values.logDate]);

  const differenceBetweenLoggedTimeAndExpectedTime = useMemo(() => {
    const regularEntries = regularTypeId
      ? formik.values.attendanceEntries.filter((entry) => entry.typeId === regularTypeId)
      : [];
    const breakEntries = breakTypeId
      ? formik.values.attendanceEntries.filter((entry) => entry.typeId === breakTypeId)
      : [];

    const loggedBreak = userSchedule?.isFlexible
      ? 0
      : breakEntries.reduce((total, entry) => {
          if (!entry.startHour || !entry.endHour) return total;
          return total + Math.round((new Date(entry.endHour).getTime() - new Date(entry.startHour).getTime()) / 60000);
        }, 0);

    const loggedRegular =
      regularEntries.reduce((total, entry) => {
        if (!entry.startHour || !entry.endHour) return total;
        return total + Math.round((new Date(entry.endHour).getTime() - new Date(entry.startHour).getTime()) / 60000);
      }, 0) - loggedBreak;

    return {
      Regular: scheduleExpectedWorkTime.Regular - loggedRegular,
      Break: userSchedule?.isFlexible ? 0 : scheduleExpectedWorkTime.Break - loggedBreak,
    };
  }, [userSchedule, scheduleExpectedWorkTime, formik.values.attendanceEntries, regularTypeId, breakTypeId]);

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        onSubmit={(event) => {
          if (
            differenceBetweenLoggedTimeAndExpectedTime.Regular !== 0 ||
            differenceBetweenLoggedTimeAndExpectedTime.Break !== 0
          ) {
            event.preventDefault();
            setAnchorEl(event.currentTarget);
          } else {
            formik.handleSubmit(event);
          }
        }}
        style={drawerContentSx}
      >
        <Typography variant="title2">
          {request ? polyglot.t('EditTrackTimeDrawerContent.edit') : polyglot.t('EditTrackTimeDrawerContent.track')}
        </Typography>
        {!userId && !request && (
          <SingleUserSelect
            name="userId"
            options={view}
            onChange={(_, x) => {
              const updateValue = (x as OptionObj)?.value ?? null;
              formik.setFieldValue('userId', updateValue);

              if (!updateValue) formik.setFieldValue('attendanceEntries', []);
            }}
            value={formik.values.userId}
            label={polyglot.t('EditTrackTimeDrawerContent.employee')}
            error={
              // show error from formik OR show error if user is not predefined & user has no schedule
              (formik.touched.userId && Boolean(formik.errors.userId)) || (!userId && !userSchedule)
            }
            helperText={
              (formik.touched.userId && (formik.errors.userId as string)) ||
              (!userSchedule && polyglot.t('AttendanceDomain.noScheduleFound'))
            }
          />
        )}
        {request && (
          <Box>
            <Typography variant="captionSmall" color="Grey">
              {polyglot.t('EditTrackTimeDrawerContent.employee')}
            </Typography>
            <Box sx={{ mt: spacing.m5, display: 'flex', alignItem: 'center', gap: spacing.g10 }}>
              <UserAvatar userId={request.userId} size="xxsmall" />
              <Typography variant="title4">
                {user ? user.displayName || `${user.firstName} ${user.lastName}` : ''}
              </Typography>
            </Box>
          </Box>
        )}
        {showDatePicker ? (
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.logDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) formik.setFieldValue('logDate', value);
              else formik.setFieldValue('logDate', '');
            }}
            name="logDate"
            label={polyglot.t('EditTrackTimeDrawerContent.period')}
            error={!!formik.errors.logDate && formik.touched.logDate}
            helperText={(formik.touched.logDate && formik.errors.logDate) as string}
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('EditTrackTimeDrawerContent.period')}
            </Typography>
            <Typography variant="title4">
              {new LocalDate(formik.values.logDate).toLocaleDateString(undefined, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Typography>
          </Box>
        )}
        {/* This is shown only when the user is predefined (eg. Me drawer, user profile drawer) */}
        {userId && !userSchedule && (
          <Typography variant="caption" color="RedDark">
            {polyglot.t('EditTrackTimeDrawerContent.noScheduleNotification')}
          </Typography>
        )}
        {userSchedule && formik.values.logDate && (
          <SelectWorkOptions
            logDate={formik.values.logDate}
            attendanceSchedule={userSchedule}
            entries={formik.values.attendanceEntries}
            setEntries={(value: RowEntry[]) => {
              formik.setFieldValue(
                'attendanceEntries',
                value.map((o, index) => ({ ...o, priority: index }))
              );
            }}
            showErrorMessage={Boolean(formik.touched.attendanceEntries && !!formik.errors.attendanceEntries)}
          />
        )}
        <Box>
          <Typography variant="caption">{polyglot.t('EditTrackTimeDrawerContent.overlapNote')}</Typography>
        </Box>
        <TextfieldComponent
          name="notes"
          label={polyglot.t('AttendanceDomain.notes')}
          value={formik.values.notes}
          onChange={formik.handleChange}
          endAdornment="none"
          error={!!formik.errors.notes && formik.touched.notes}
          helperText={(formik.touched.notes && formik.errors.notes) as string}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="caption">{polyglot.t('EditTrackTimeDrawerContent.total')}</Typography>
          <Typography variant="title4">{totalTime}</Typography>
        </Box>
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent onClick={() => setIsOpen(false)} fullWidth sizeVariant="medium" colorVariant="secondary">
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.save')}
            fullWidth
            loading={loading}
            sizeVariant="medium"
            colorVariant="primary"
          />
          <NotificationModal
            isOpen={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            takeAction={() => {
              formik.handleSubmit();
              setAnchorEl(null);
            }}
            message={polyglot.t('EditTrackTimeDrawerContent.continueMessage')}
            callToAction={polyglot.t('General.yes')}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

interface ViewTrackTimeDrawerContentProps {
  readonly setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly request: AttendanceDto;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userSchedule: AttendanceScheduleDto | null | undefined;
}

const ViewTrackTimeDrawerContent = ({
  request,
  refresh,
  setEditMode,
  setIsOpen,
  userSchedule,
}: ViewTrackTimeDrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);

  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { getScopesContext, hasScopes } = useScopes();

  const hasStdAttendanceScope = hasScopes(['attendance'], getScopesContext({ userId: request.userId }));
  const canManageThis = hasScopes(['attendance:manager'], getScopesContext({ userId: request.userId }));

  const canApproveOrReject = useMemo(() => Boolean(request.approversIds?.includes(state.user.userId)), [
    request,
    state.user.userId,
  ]);
  const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false);
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const { getCachedUserById } = useCachedUsers();

  const attendanceTypesAllowedById = useMemo(
    () =>
      userSchedule?.attendanceTypesAllowed?.reduce((response, type) => {
        response[type.id] = type;
        return response;
      }, {} as { [typeId: number]: AttendanceTypeDto }) ?? ({} as { [typeId: number]: AttendanceTypeDto }),
    [userSchedule]
  );

  const attendanceUser = useMemo(() => getCachedUserById(request.userId), [getCachedUserById, request.userId]);
  const approver = useMemo(() => request.approvedBy && getCachedUserById(request.approvedBy), [
    getCachedUserById,
    request.approvedBy,
  ]);

  const deleteRequest = useCallback(async () => {
    try {
      await AttendanceAPI.deleteAttendanceById(request.id);
      showMessage(polyglot.t('ViewTrackTimeDrawerContent.successMessages.delete'), 'success');
      await refresh();
      setIsOpen(false);
    } catch (e) {
      showMessage(
        polyglot.t('ViewTrackTimeDrawerContent.errorMessages.delete', { errorMessage: nestErrorMessage(e) }),
        'error'
      );
    }
  }, [showMessage, request, refresh, setIsOpen, polyglot]);

  const approveRequest = useCallback(async () => {
    try {
      setIsApproveLoading(true);
      await AttendanceAPI.approveAttendanceById(request.userId, request.id, AttendanceStatus.Approved);
      showMessage(polyglot.t('ViewTrackTimeDrawerContent.successMessages.approve'), 'success');
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t('ViewTrackTimeDrawerContent.errorMessages.approve', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setIsApproveLoading(false);
    }
  }, [setIsOpen, request.id, request.userId, showMessage, refresh, polyglot]);

  const rejectRequest = useCallback(async () => {
    try {
      setIsRejectLoading(true);
      await AttendanceAPI.approveAttendanceById(request.userId, request.id, AttendanceStatus.Rejected);
      showMessage(polyglot.t('ViewTrackTimeDrawerContent.successMessages.reject'), 'success');
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t('ViewTrackTimeDrawerContent.errorMessages.reject', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setIsRejectLoading(false);
    }
  }, [setIsOpen, request.id, request.userId, showMessage, refresh, polyglot]);

  return (
    <Box sx={drawerContentSx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="title2">{polyglot.t('ViewTrackTimeDrawerContent.attendance')}</Typography>
        {(canManageThis ||
          (hasStdAttendanceScope &&
            request.status === AttendanceStatus.InProgress &&
            request.userId === currentUser.userId &&
            userSchedule?.trackingType !== ScheduleTrackingType.ClockInClockOut)) && (
          <StyledMenuComponent
            options={[
              {
                icon: <Edit {...iconSize} />,
                handler: () => setEditMode(true),
                label: polyglot.t('General.edit'),
              },
              {
                icon: <Trash {...iconSize} />,
                handler: deleteRequest,
                label: polyglot.t('General.delete'),
              },
            ]}
            actionButtonDetails={{
              type: 'iconButton',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: polyglot.t('ViewTrackTimeDrawerContent.actions'),
              icon: <ActionsSmall width={'14px'} height={'14px'} />,
            }}
          />
        )}
      </Box>

      {attendanceUser && (
        <DrawerViewerItem
          key="user"
          title={polyglot.t('ViewTrackTimeDrawerContent.employee')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <UserAvatar userId={attendanceUser.userId} size="xxsmall" />
              <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                {attendanceUser.displayName || `${attendanceUser.firstName} ${attendanceUser.lastName}`}
              </Typography>
            </Box>
          }
        />
      )}

      <DrawerViewerItem
        title={polyglot.t('ViewTrackTimeDrawerContent.status')}
        value={getAttendanceStatusIcon(request.status, false, polyglot)}
      />

      {approver && (
        <DrawerViewerItem
          key="approver"
          title={`${request.status} by`}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <UserAvatar userId={approver.userId} size="xxsmall" />
              <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                {approver.displayName || `${approver.firstName} ${approver.lastName}`}
              </Typography>
            </Box>
          }
        />
      )}

      {userSchedule && (
        <DrawerViewerItem
          key="schedule"
          title={polyglot.t('ViewTrackTimeDrawerContent.schedule')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
                {userSchedule.name}
              </Typography>
            </Box>
          }
        />
      )}

      <DrawerViewerItem
        title={polyglot.t('ViewTrackTimeDrawerContent.period')}
        value={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {new LocalDate(request.logDate).toLocaleDateString()}
            </Typography>
          </Box>
        }
      />

      <SummaryDetailsComponent
        request={request}
        userSchedule={userSchedule}
        attendanceTypesAllowedById={attendanceTypesAllowedById}
      />

      {request.attendanceEntries.map((entry, index) => (
        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DrawerViewerItem
            key="entry-name"
            title={attendanceTypesAllowedById[entry.typeId]?.name ?? 'N/A'}
            value={
              <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>{`${entry.startHour.slice(
                11,
                16
              )} - ${entry.endHour.slice(11, 16)}`}</Typography>
            }
          />

          <DrawerViewerItem
            key="entry-length"
            title={
              entry.type?.name
                ? polyglot.t('ViewTrackTimeDrawerContent.typeLength', { type: entry.type.name })
                : polyglot.t('ViewTrackTimeDrawerContent.length')
            }
            value={
              <Typography
                variant="title4"
                sx={{
                  color: themeColors.DarkGrey,
                  minWidth: '80px',
                }}
              >
                {convertMinutesToClockHours(
                  (new Date(entry.endHourTimestamp).getTime() - new Date(entry.startHourTimestamp).getTime()) /
                    (60 * 1000),
                  polyglot
                )}
              </Typography>
            }
          />
        </Box>
      ))}

      {request.startLongitude && request.startLatitude && (
        <DrawerViewerItem
          title={
            <Box sx={{ display: 'flex', gap: spacing.g5 }}>
              <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                {polyglot.t('ViewTrackTimeDrawerContent.location')}
              </Typography>
              <a
                href={`https://www.google.com/maps/place/${request.startLatitude},${request.startLongitude}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Location width="12px" height="12px" fill={themeColors.Grey} />
              </a>
            </Box>
          }
          value={
            <Typography
              variant="title4"
              sx={{ color: themeColors.DarkGrey }}
            >{`${request.startLatitude}, ${request.startLongitude}`}</Typography>
          }
        />
      )}

      {request.notes && (
        <DrawerViewerItem
          key="notes"
          title={polyglot.t('ViewTrackTimeDrawerContent.notes')}
          value={
            <Typography
              variant="title4"
              sx={{
                color: themeColors.DarkGrey,
                minWidth: '80px',
              }}
            >
              {request.notes}
            </Typography>
          }
        />
      )}

      <ScopesControl scopes={['payroll:all']} context={{ userId: request.userId }}>
        <AttendanceDrawerPayItemPreview userId={request.userId} attendanceId={request.id} />
      </ScopesControl>

      {request.status === AttendanceStatus.Submitted && (canApproveOrReject || canManageThis) && (
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="button"
            fullWidth
            name={polyglot.t('General.reject')}
            loading={isRejectLoading}
            onClick={rejectRequest}
            sizeVariant="medium"
            colorVariant="secondary"
          />
          <LoaderButton
            type="button"
            name={polyglot.t('General.approve')}
            loading={isApproveLoading}
            fullWidth
            onClick={approveRequest}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      )}
    </Box>
  );
};
