import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { FilterValueSelectorProps } from '@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component';
import { NotImplemented } from '@v2/feature/reports/reports-advanced/filters-components/not-implemented.component';
import { ReportSQLOperator } from '@v2/feature/reports/reports.interface';

export const NumberSelector = ({ operator, value, setValue, label }: Omit<FilterValueSelectorProps, 'filterType'>) => {
  const NumberOperatorsWithNoExtraField = useMemo(() => {
    return new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);

  const NumberOperatorsWithTextField = useMemo(() => {
    return new Set([
      ReportSQLOperator.eq,
      ReportSQLOperator.gt,
      ReportSQLOperator.lt,
      ReportSQLOperator.gte,
      ReportSQLOperator.lte,
      ReportSQLOperator.not,
    ]);
  }, []);

  return NumberOperatorsWithTextField.has(operator) ? (
    <TextfieldComponent
      value={value}
      name="value"
      label={label}
      onChange={(e) => {
        setValue(Number(e.target.value));
      }}
      endAdornment="none"
    />
  ) : operator === ReportSQLOperator.in ? (
    <ArrayNumbersPicker value={value} setValue={setValue} label={label} />
  ) : operator === ReportSQLOperator.between ? (
    <BetweenNumbersPicker value={value} setValue={setValue} />
  ) : NumberOperatorsWithNoExtraField.has(operator) ? null : (
    <NotImplemented />
  );
};

const BetweenNumbersPicker = ({ value, setValue }: Pick<FilterValueSelectorProps, 'value' | 'setValue'>) => {
  const [localValues, setLocalValues] = useState<number[]>([
    Array.isArray(value) && value[0] && typeof value[0] === 'number' ? Number(value[0]) : 0,
    Array.isArray(value) && value[1] && typeof value[1] === 'number' ? Number(value[1]) : 10,
  ]);

  useEffect(() => {
    const start = localValues[0] ?? 0;
    const end = localValues[1] ?? start + 10;

    if (!Number.isNaN(start) && !Number.isNaN(end)) setValue([start, end]);
  }, [localValues, setValue]);

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <TextfieldComponent
        value={String(localValues[0])}
        name="value"
        label="Start value"
        onChange={(e) => {
          const value = Number(e.target.value);
          setLocalValues((prev) => [value, prev[1]]);
        }}
        endAdornment="none"
      />

      <TextfieldComponent
        value={String(localValues[1])}
        name="value"
        label="End value"
        onChange={(e) => {
          const value = Number(e.target.value);
          setLocalValues((prev) => [prev[0], value]);
        }}
        endAdornment="none"
      />
    </Box>
  );
};

const ArrayNumbersPicker = ({
  value,
  setValue,
  label,
}: Pick<FilterValueSelectorProps, 'value' | 'setValue' | 'label'>) => {
  const [localValue, setLocalValue] = useState<string>(Array.isArray(value) ? value.join(',') : '');

  useEffect(() => {
    const newValue = localValue
      .split(',')
      .filter(Boolean)
      .map((n) => Number(n.trim()))
      .filter((v) => !Number.isNaN(v));
    setValue(newValue);
  }, [localValue, setValue]);

  return (
    <TextfieldComponent
      value={localValue}
      name="value"
      label={label}
      helperText="eg.: 1,2,3"
      onChange={(e) => {
        setLocalValue(e.target.value);
      }}
      endAdornment="none"
    />
  );
};
