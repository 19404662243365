import { Dispatch, SetStateAction } from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { AppIntegrationUserDto, GroupMember, GroupMembership } from '@/v2/feature/app-integration/app-integration.dto';
import {
  AppIntegrationStub,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
} from '@/v2/feature/app-integration/app-integration.interface';
import { AppGroupManagementDrawerPage } from '@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.page';

interface AppGroupManagementDrawerProps {
  readonly appStub: AppIntegrationStub;
  readonly selectedGroupMembership?: GroupMembership;
  readonly externalUserList: readonly AppIntegrationUserDto[];
  readonly usersWithoutAccess: readonly AppIntegrationUserDto[];
  readonly usersWithAccess?: readonly AppIntegrationUserDto[];
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly refreshApp: VoidFunction;
  readonly setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  readonly mode?: APP_GROUP_MANAGEMENT_DRAWER_MODES;
  existingUser?: AppIntegrationUserDto;
  readonly directoryMode?: 'team' | 'external';
  readonly groupMembers?: Record<string, GroupMember>;
  readonly groupList?: readonly GroupMembership[];
}

export const AppGroupManagementDrawer = ({
  appStub,
  selectedGroupMembership,
  externalUserList,
  usersWithoutAccess,
  usersWithAccess,
  isOpen,
  onClose,
  refreshApp,
  setIsDrawerOpen,
  mode,
  existingUser,
  groupMembers,
  groupList,
}: AppGroupManagementDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsDrawerOpen}>
    <AppGroupManagementDrawerPage
      appStub={appStub}
      selectedGroupMembership={selectedGroupMembership}
      externalUserList={externalUserList}
      usersWithoutAccess={usersWithoutAccess}
      usersWithAccess={usersWithAccess}
      refreshApp={refreshApp}
      closePage={() => onClose()}
      mode={mode}
      existingUser={existingUser}
      groupMembers={groupMembers}
      groupList={groupList}
    />
  </DrawerModal>
);
