import { useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { SurveyResultsFilters } from '@v2/feature/growth/surveys/features/components/survey-results-filters.component';
import { SurveyResultsByQuestion } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-results-by-question.component';
import {
  SurveyCycle,
  SurveyCycleResult,
  SurveyImpactLabel,
  SurveyImpactStats,
  SurveyResultByQuestion,
} from '@v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { Dictionary } from 'lodash';

import { GlobalContext } from '@/GlobalState';

interface SurveyQuestionsBiggestImpactProps {
  readonly surveyResult: SurveyCycleResult | undefined | null;
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly filtersAndTypesOptions: any;
  readonly setFilterString: React.Dispatch<React.SetStateAction<string>>;
  readonly filterString: string;
  readonly surveyCycle: SurveyCycle;
}

export const SurveyQuestionsBiggestImpact = ({
  surveyResult,
  growthFactors,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
  surveyCycle,
}: SurveyQuestionsBiggestImpactProps) => {
  const [state, dispatch] = useContext(GlobalContext);
  const [measureByFilter, setMeasureByFilter] = useState<'nps' | 'positive' | 'avg'>(
    (state.user.features?.surveys?.results?.measuredBy as 'nps' | 'positive' | 'avg') || 'nps'
  );

  const reportName = useMemo(() => `${surveyCycle.internalName}-biggest-impact`, [surveyCycle.internalName]);

  const existingFactors = useMemo(() => {
    const factorsArray = surveyResult?.resultByQuestion.map((r) => r.question.factor).filter(Boolean) as string[];

    return [...new Set(factorsArray)];
  }, [surveyResult]);

  const [impactFilter, setImpactFilter] = useState<string>(existingFactors[0] || '');

  const filteredResultByQuestion = useMemo(() => {
    if (!surveyResult?.impactResult || !surveyResult?.resultByQuestion || !impactFilter) return [];

    const questionIdsWithImpactStats = Object.keys(surveyResult.impactResult)
      .map((questionId) => {
        const impactStats = surveyResult.impactResult![questionId]
          ? surveyResult.impactResult![questionId][impactFilter]
          : null;
        if (!impactStats) return null;

        return { questionId, impactStats };
      })
      .filter(
        (entry) =>
          entry &&
          entry.impactStats.coefficient !== null &&
          [SurveyImpactLabel.High, SurveyImpactLabel.Medium].includes(entry.impactStats.label)
      ) as { questionId: string; impactStats: SurveyImpactStats }[];

    questionIdsWithImpactStats.sort((a, b) =>
      Math.abs(a.impactStats.coefficient) < Math.abs(b.impactStats.coefficient) ? 1 : -1
    );

    return questionIdsWithImpactStats
      .slice(0, 3)
      .map(({ questionId }) => surveyResult.resultByQuestion.find((q) => q.question.id === questionId))
      .filter(Boolean) as SurveyResultByQuestion[];
    // return surveyResult.resultByQuestion.slice(0, 3);
  }, [surveyResult, impactFilter]);

  if (!surveyResult) return null;

  return (
    <Box>
      <SurveyResultsFilters
        existingFactors={existingFactors}
        showByFilter="Question"
        measureByFilter={measureByFilter}
        setMeasureByFilter={setMeasureByFilter}
        impactFilter={impactFilter}
        setImpactFilter={setImpactFilter}
        dispatch={dispatch}
        filtersAndTypesOptions={filtersAndTypesOptions || []}
        setFilterString={setFilterString}
        filterString={filterString}
        surveyCycle={surveyCycle}
      />

      <div id={reportName}>
        <SurveyResultsByQuestion
          resultByQuestion={filteredResultByQuestion}
          impactResult={surveyResult.impactResult}
          growthFactors={growthFactors}
          impactFilter={impactFilter}
          measureByFilter={measureByFilter}
          emptyStateMessage="No questions with high or medium impact available for the selected factor."
        />
      </div>
    </Box>
  );
};
