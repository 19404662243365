import React from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import {
  getCurrentBalanceFromBreakdown,
  getTotalAllowanceBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';
import { spacing } from '@v2/styles/spacing.styles';

const BreakdownField = ({ name, value, color }: { name: string; value: string; color: string }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Typography variant="captionSmall" sx={{ width: '60%', color }}>
      {name}
    </Typography>
    <Typography variant="captionSmall" sx={{ width: '40%', display: 'flex', justifyContent: 'end', color }}>
      {value}
    </Typography>
  </Box>
);

interface PolicyBreakdownCardProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userBalanceDetailedStats: UserBalanceDetailedStatsDto;
  readonly isActive: boolean;
  readonly onClick: () => void;
}

const textOverflowSx: React.CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '90%',
};

export const PolicyBreakdownCard = ({
  isActive,
  absencePolicy,
  userBalanceDetailedStats,
  onClick,
}: PolicyBreakdownCardProps) => {
  const { polyglot } = usePolyglot();
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];
  const totalAllowance = getTotalAllowanceBreakdown(absencePolicy, policyBreakdown, polyglot);
  const balance = getCurrentBalanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);

  return (
    <Box
      sx={{
        bgcolor: isActive ? themeColors.Background : themeColors.white,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        p: spacing.p10,
        transition: 'transform 0.1s linear',
        '&:hover': isActive
          ? undefined
          : {
              cursor: 'pointer',
              transform: 'scale(1.05)',
            },
      }}
      onClick={isActive ? undefined : onClick}
    >
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <Box
          sx={{
            width: '8px',
            height: '8px',
            backgroundColor: absencePolicy.color ?? themeColors.Grey,
            borderRadius: radius.br25,
          }}
        />
        <Typography variant={isActive ? 'title4' : 'caption'} sx={textOverflowSx}>
          {absencePolicy.name}
        </Typography>
      </Box>
      {balance ? (
        <BreakdownField
          name={polyglot.t('AllowanceDrawer.remaining')}
          value={balance}
          color={isActive ? themeColors.DarkGrey : themeColors.Grey}
        />
      ) : (
        <BreakdownField
          name={polyglot.t('AllowanceDrawer.allowance')}
          value={totalAllowance ?? polyglot.t('AllowanceDrawer.unlimited')}
          color={isActive ? themeColors.DarkGrey : themeColors.Grey}
        />
      )}
    </Box>
  );
};
