import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import {
  AttendanceImportDto,
  AttendanceImportResultDto,
} from '@v2/feature/attendance/subfeatures/attendance-import/attendance-import.dto';
import { EditAttendanceEntryDrawer } from '@v2/feature/entity-import/wizard/components/attendance/edit-attendance-entry-drawer.component';
import { ImportedAttendanceTableCell } from '@v2/feature/entity-import/wizard/components/attendance/imported-attendance-table-cell.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EntityImportValidationResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import { validateAttendanceImport } from '@/v2/feature/entity-import/wizard/entity-import-validator.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  result?: AttendanceImportResultDto;
  onUpdateAndRevalidate: (updatedRecords: EntityImportValidationResultDto<AttendanceImportDto>) => void;
  completeImport: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const AttendanceImportResultTable = ({
  result,
  completeImport,
  onUpdateAndRevalidate,
  loading,
  setLoading,
}: Props) => {
  const { data: attendanceTypes } = useApiClient(AttendanceEndpoints.getCompanyAttendanceTypes(), { suspense: false });

  const [showMessage] = useMessage();
  const [rowBeingEdited, setRowBeingEdited] = useState<AttendanceImportDto | undefined>(undefined);
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const [isOpen, setIsOpen] = useState(false);

  const rowData = useMemo(() => {
    if (result?.errors.some((e) => e.entity)) {
      return result?.errors.flatMap((record) => record.entity);
    }
  }, [result]);

  const rowErrors = useMemo(() => {
    if (result?.errors.some((e) => e.errors.length > 0)) {
      return result?.errors.map((record) => record.errors);
    }
  }, [result?.errors]);

  const notifyPendingErrors = useCallback(() => {
    showMessage('We found some issues in your formatting. Please correct the mistakes and try again', 'info');
  }, [showMessage]);

  const notifyDataValid = useCallback(() => {
    showMessage('Your imported data looks good! Continue to finish import.', 'success');
  }, [showMessage]);

  useEffect(() => {
    if (rowErrors?.length) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, rowErrors?.length]);

  const getErrorForRow = useCallback(
    (index: number) => {
      return rowErrors ? rowErrors[index] : [];
    },
    [rowErrors]
  );

  const attendanceColumns = useMemo<ColumnDef<AttendanceImportDto, AttendanceImportDto>[]>(() => {
    return [
      {
        header: () => 'First name',
        accessorFn: (row) => row,
        id: 'firstName',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'firstName'}
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Last name',
        accessorFn: (row) => row,
        id: 'lastName',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'lastName'}
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Work Email',
        accessorFn: (row) => row,
        id: 'workEmail',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'workEmail'}
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
      {
        header: () => 'Attendance type',
        accessorFn: (row) => row,
        id: 'attendanceType',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'attendanceType'}
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
      {
        header: () => 'Date',
        accessorFn: (row) => row,
        id: 'logDate',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'logDate'}
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Start hour',
        accessorFn: (row) => row,
        id: 'start',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'start'}
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'End hour',
        accessorFn: (row) => row,
        id: 'end',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowData={info.getValue()}
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'end'}
            />
          );
        },
        maxSize: 120,
        minSize: 120,
      },
      {
        header: () => 'Validation',
        accessorFn: (row) => row,
        id: 'validation',
        enableSorting: false,
        cell: (info: CellContext<AttendanceImportDto, AttendanceImportDto>) => {
          return (
            <ImportedAttendanceTableCell
              rowErrors={getErrorForRow(info.row.index)}
              fieldKey={'validation'}
              statusColumn={(rowErrors && rowErrors[info.row.index]?.length) ?? 0}
            />
          );
        },
        maxSize: 200,
        minSize: 150,
      },
    ];
  }, [getErrorForRow, rowErrors]);

  const updateDatasetWithRecord = useCallback(
    (values: AttendanceImportDto): AttendanceImportDto[] | undefined => {
      if (!rowData) return [];

      const foundIndex = rowData.findIndex((record) => record.id === values.id);
      if (foundIndex < 0) return rowData;

      const olderRecord = rowData[foundIndex];

      const updatedEntry: AttendanceImportDto = {
        ...olderRecord,
        ...values,
        id: olderRecord.id,
      };

      const updatedRecords = rowData ? [...rowData] : [];
      updatedRecords[foundIndex] = updatedEntry;

      return updatedRecords;
    },
    [rowData]
  );

  const importHandler = useCallback(
    async (values: AttendanceImportDto) => {
      setLoading(true);
      try {
        const updatedRecords = updateDatasetWithRecord(values);
        const validationResult = await validateAttendanceImport(
          updatedRecords as AttendanceImportDto[],
          allUsersIncludingTerminated,
          attendanceTypes ?? []
        );
        onUpdateAndRevalidate(validationResult);
      } catch (error) {
        showMessage('Something went wrong', 'error');
      } finally {
        setLoading(false);
      }
    },
    [
      allUsersIncludingTerminated,
      attendanceTypes,
      onUpdateAndRevalidate,
      setLoading,
      updateDatasetWithRecord,
      showMessage,
    ]
  );

  const handleRowClick = useCallback(
    (row: Row<AttendanceImportDto>) => {
      if (rowData) {
        setRowBeingEdited(row.original);
        setIsOpen(true);
      }
    },
    [rowData]
  );

  const totalPendingErrors = useMemo(() => {
    return rowErrors?.flat()?.length ?? 0;
  }, [rowErrors]);

  return (
    <>
      <Box
        sx={{
          pt: spacing.p25,
          flex: 1,
          overflow: 'auto',
          borderTop: '1px solid',
          borderColor: themeColors.GreyLight,
        }}
      >
        <BasicTable
          rowData={rowData ?? []}
          columnData={attendanceColumns}
          rowClick={handleRowClick}
          loading={loading}
        />
      </Box>
      {rowBeingEdited && (
        <EditAttendanceEntryDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setIsOpen(false);
            setRowBeingEdited(undefined);
          }}
          attendance={rowBeingEdited}
          attendanceTypes={attendanceTypes ?? []}
          allUsers={allUsersIncludingTerminated}
          importHandler={importHandler}
        />
      )}
      <ButtonComponent
        sizeVariant="medium"
        colorVariant="primary"
        style={{ marginTop: '30px' }}
        disabled={loading}
        onClick={() => {
          if (rowErrors?.length) {
            notifyPendingErrors();
          } else {
            completeImport();
          }
        }}
      >
        {totalPendingErrors ? 'Try again' : 'Continue'}
      </ButtonComponent>
    </>
  );
};
