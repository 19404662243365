import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { UserLifecycleStatuses } from '@/models/user-role.model';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import { UserAPI } from '@/v2/feature/user/user.api';
import { ACTIVE_INVITABLE_USER_ACCOUNT_STATUS_FOR_BILLING } from '@/v2/feature/user/user.interface';

interface UserFilterList {
  readonly include?: readonly UserLifecycleStatuses[];
  readonly exclude?: readonly UserLifecycleStatuses[];
}

/**
 * Data used in the global user cache.
 * Be careful adding more data here as it is downloaded for all users
 */
export type CachedUser = Pick<
  UserSummaryDto,
  | 'accountStatus'
  | 'displayName'
  | 'emailAddress'
  | 'firstName'
  | 'lastName'
  | 'leaveDate'
  | 'startDate'
  | 'userId'
  | 'showBirthday'
  // the joins...
  | 'role'
  | 'userEvent'
  | 'userContract'
>;

interface CachedUsersContextState {
  readonly loaded: boolean;
  /**
   * All users in the company, including terminated users
   */
  readonly cachedUsers: readonly CachedUser[];
  /**
   * All active users in the company
   */
  readonly nonTerminatedCachedUsers: readonly CachedUser[];
  readonly employedUsersCachedUsers: readonly CachedUser[];
  readonly getCachedUserById: (userId: number) => CachedUser | undefined;
  readonly getCachedUsersByEmail: (emailAddresses?: string[] | null) => readonly CachedUser[];
  readonly getActiveUserCountForBilling: () => number | undefined;
  readonly filterCachedUsersByStatus: ({ include, exclude }: UserFilterList) => readonly CachedUser[];
  readonly refreshCachedUsers: () => Promise<void>;
}

const CachedUsersContext = createContext<CachedUsersContextState>({
  loaded: false,
  cachedUsers: [],
  nonTerminatedCachedUsers: [],
  employedUsersCachedUsers: [],
  getCachedUserById: () => undefined,
  getCachedUsersByEmail: () => [],
  getActiveUserCountForBilling: () => undefined,
  filterCachedUsersByStatus: () => [],
  refreshCachedUsers: async () => {},
});

export const useCachedUsers = (opts?: { refresh?: boolean }): CachedUsersContextState => {
  const hasRefreshed = useRef(false);
  const contextState = useContext(CachedUsersContext);
  if (contextState === null) {
    throw new Error('useCachedUsers must be used within a CachedUsersProvider tag');
  }

  useEffect(() => {
    if (!opts?.refresh) return;
    if (hasRefreshed.current) return;
    hasRefreshed.current = true;
    contextState.refreshCachedUsers();
  }, [contextState, opts?.refresh]);

  return contextState;
};

interface CachedUsersProviderProps {
  children?: ReactNode;
}

export const CachedUsersProvider = ({ children }: CachedUsersProviderProps): JSX.Element => {
  const [cache, setCache] = useState({
    loaded: false,
    users: [] as readonly CachedUser[],
    nonTerminatedUsers: [] as readonly CachedUser[],
    employedUsers: [] as readonly CachedUser[],
    usersById: new Map<number, CachedUser>(),
  });

  const getCachedUserById = useCallback((userId: number) => cache.usersById.get(userId), [cache]);
  const getActiveUserCountForBilling = useCallback(
    () =>
      cache.users.filter(({ accountStatus }) =>
        ACTIVE_INVITABLE_USER_ACCOUNT_STATUS_FOR_BILLING.includes(accountStatus)
      )?.length || 0,
    [cache.users]
  );
  const getCachedUsersByEmail = useCallback(
    (emailAddresses?: string[] | null): readonly CachedUser[] => {
      return cache.users.filter(({ emailAddress }) => {
        return emailAddresses ? emailAddresses.includes(emailAddress) : [];
      });
    },
    [cache]
  );
  const filterUsersByStatus = useCallback(
    (users: readonly CachedUser[], { include = [], exclude = [] }: UserFilterList) =>
      users.filter(({ userEvent }) => {
        const status = userEvent?.status;
        return include && include.length > 0
          ? status && include?.includes(status)
          : exclude && exclude.length > 0
          ? !status || !exclude?.includes(status)
          : status;
      }),
    []
  );
  const filterCachedUsersByStatus = useCallback((filter: UserFilterList) => filterUsersByStatus(cache.users, filter), [
    cache,
    filterUsersByStatus,
  ]);

  const refreshCachedUsers = useCallback(
    async (useCache = false) => {
      const users = await UserAPI.fetchUserCache(useCache);
      // most cached-user uses require the users to be sorted by name
      const ciCollator = new Intl.Collator(undefined, { sensitivity: 'base' });
      users.sort((a, b) => ciCollator.compare(a.displayName, b.displayName));
      const usersById = new Map<number, CachedUser>(users.map((u) => [u.userId, u]));

      // the list of non-terminated users is needed a lot in the app, so create it
      // as a separate cache list so we don't keep recalculating it.
      const nonTerminatedUsers = filterUsersByStatus(users, { exclude: [UserLifecycleStatuses.Terminated] });
      const employedUsers = filterUsersByStatus(users, { include: [UserLifecycleStatuses.Employed] });

      setCache({
        users,
        nonTerminatedUsers,
        employedUsers,
        usersById,
        loaded: true,
      });
    },
    [filterUsersByStatus]
  );

  useEffect(() => {
    refreshCachedUsers(true);
  }, [refreshCachedUsers]);

  return (
    <CachedUsersContext.Provider
      value={{
        cachedUsers: cache.users,
        nonTerminatedCachedUsers: cache.nonTerminatedUsers,
        employedUsersCachedUsers: cache.employedUsers,
        loaded: cache.loaded,
        getActiveUserCountForBilling,
        getCachedUserById,
        getCachedUsersByEmail,
        filterCachedUsersByStatus,
        refreshCachedUsers,
      }}
    >
      {children}
    </CachedUsersContext.Provider>
  );
};
