import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { OptionProps } from '@v2/components/theme-components/styled-menu.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';

import { UserContractSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { Scope } from '@/models';
import { RightToWorkDocumentOption } from '@/models/user.model';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { EditIdVerify } from '@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component';
import {
  CheckCountry,
  CheckPackage,
  IDVerificationResultDto,
} from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.interface';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import { RightToWorkAPI } from '@/v2/feature/user/features/user-forms/user-right-work/user-right-work.api';
import {
  CardStructure,
  FieldStructure,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { ViewResultsForIdChecksModal } from '@/v2/feature/user/features/user-profile/details/components/id-checks/view-results-for-id-checks.component';
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { RightToWorkForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-right-to-work-form.component';
import {
  ID_CHECK_COMPLETED_STATES,
  MANUAL_RTW_CHECK,
  RightToWorkDocumentType,
  RightToWorkValues,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { OnboardingAPI, OnboardingEndpoints } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { toTitleCase } from '@/v2/util/string.util';

const skeletonHeight = (polyglot: Polyglot) => calculateSkeletonHeight(UserContractSchema(polyglot));

const RTW_SCOPE = 'user.rightWork' as const;
const SCOPE: Scope[] = ['user.onboard:all', 'user.rightWork:all'];

export const IdentityChecksCard = ({ userId }: UserProfileCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserContext = getScopesContext(state.user);
  const scopesContext = getScopesContext({ userId });
  const isAdminUser = hasScopes(['user.onboard:all'], currentUserContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewResultsOpen, setViewResultsOpen] = useState(false);

  const [loadingRTW, setLoadingRTW] = useState<boolean>(false);
  const [rtwData, setRTWData] = useState<RightToWorkValues>();
  const [documents, setDocuments] = useState<readonly RightToWorkDocumentOption[]>([]);
  const [documentTypes, setDocumentTypes] = useState<readonly ZeltDocumentType[]>([]);
  // const [isRTWEmpty, setRTWEmpty] = useState<boolean>(false);
  const [manualCheckOpen, setManualCheckOpen] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [currentIdCheck, setCurrentIdCheck] = useState<IDVerificationResultDto | null>(null);

  const [idCheckState, setIdCheckState] = useState<IDVerificationResultDto[] | null>();
  const { data: idCheckResult, isValidating: loadingIdChecks, mutate: refreshIdChecks } = useApiClient(
    OnboardingEndpoints.getIDCheckResult(userId),
    {
      suspense: false,
    }
  );

  const idCheckDocumentToDisplay = useCallback(
    (requestId: string) => {
      return documents && Array.isArray(documents) && documents.length > 0
        ? documents.find(
            (d) => d.type === 'identityCheck' && d.programmaticRecordId && d.programmaticRecordId.includes(requestId)
          )?.label ?? undefined
        : undefined;
    },
    [documents]
  );

  const documentNameBasedOnIdChecks = useCallback(
    (row: IDVerificationResultDto): string => {
      const idCheckDocument = idCheckDocumentToDisplay(row.requestId);
      return row.status && ID_CHECK_COMPLETED_STATES.has(row.status) && idCheckDocument ? idCheckDocument : undefined;
    },
    [idCheckDocumentToDisplay]
  );
  const fetchDocuments = useCallback(async () => {
    try {
      setLoadingRTW(true);
      if (!userId) return;

      const userRTWDocuments = await RightToWorkAPI.getDocumentsByUserId(userId);
      setDocuments(userRTWDocuments?.length > 0 ? userRTWDocuments : []);
    } catch (error) {
      showMessage('Identity check could not be loaded. Please try again.', 'error');
    } finally {
      setLoadingRTW(false);
    }
  }, [userId, showMessage]);
  useEffect(() => {
    (async () => {
      const typeData = await DocumentAPI.listDocumentTypes();
      const newData = typeData?.filter(({ category }) => category === 'Personal Documents');
      setDocumentTypes(newData);
    })();
  }, []);

  useEffect(() => {
    setIdCheckState(idCheckResult);
  }, [idCheckResult]);

  const userHasIdCheck = useMemo(() => {
    return idCheckState && idCheckState?.length > 0 ? true : false;
  }, [idCheckState]);

  const newIdCheck = () => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const initateIdentityChecks = useCallback(
    async (packageForIdCheck?: CheckPackage, checkCountry?: CheckCountry) => {
      try {
        if (packageForIdCheck) {
          await OnboardingAPI.initiateIdentityChecksFromUserProfile(userId, packageForIdCheck, checkCountry);
          showMessage('The user will receive an invite to start checks shortly', 'success');
        }
      } catch (error) {
        showMessage(
          `Encountered an error while trying to initiate an identity check: ${nestErrorMessage(error)}`,
          'error'
        );
      }
    },
    [showMessage, userId]
  );

  const getEmptyStateMessage = () => {
    return isAdminUser
      ? 'You can verify documents and references for this employee'
      : 'Any ongoing / completed identity check will appear here';
  };

  const canInitiateNewIdCheck = isAdminUser;

  const documentForManualRTWCheckEntry = useCallback(
    (check: IDVerificationResultDto) => {
      return check?.requestId === MANUAL_RTW_CHECK && documents?.length > 0
        ? documents.find((d) => d.documentId === check.reportId)
        : undefined;
    },
    [documents]
  );

  const idCheckButtonForState = useMemo(() => {
    if (canInitiateNewIdCheck) return newIdCheck;
    // else if (canViewPartiallyCompletedIdCheck) return viewPartiallyCompletedCheck;
    else return undefined;
  }, [canInitiateNewIdCheck]);

  const IdCheckActionsMenuStructure: OptionProps[] = [
    {
      label: polyglot.t('UserProfileIdentityChecks.newAutomatedCheck'),
      handler: () => {
        newIdCheck();
      },
      hidden: !canInitiateNewIdCheck,
      scopes: SCOPE,
      context: scopesContext,
    },
    {
      label: polyglot.t('UserProfileIdentityChecks.addManually'),
      handler: () => {
        setDrawerOpen(false);
        setCurrentIdCheck(null);
        setManualCheckOpen(true);
      },
      scopes: SCOPE,
      context: scopesContext,
    },
  ];

  const combinedListOfIdChecksAndRtwData: IDVerificationResultDto[] = useMemo(() => {
    const checksData = [];
    // const defaultDate = new Date();
    if (idCheckState?.length && idCheckState.length > 0) {
      for (const check of idCheckState) {
        if (check.requestId === MANUAL_RTW_CHECK) {
          checksData.push({
            ...check,
            reportUrl: documentForManualRTWCheckEntry(check)?.documentUuid ?? '',
          });
        } else {
          checksData.push(check);
        }
      }
    }
    return checksData;
  }, [documentForManualRTWCheckEntry, idCheckState]);

  const getDateForIdCheckEntry = (check: IDVerificationResultDto) => {
    return check?.requestId === MANUAL_RTW_CHECK && check.completedAt
      ? getDateString(check.completedAt)
      : getDateString(check.createdAt) ?? '';
  };

  const cardFieldDetails = useMemo(() => {
    const latestCheck = combinedListOfIdChecksAndRtwData.sort((a, b) => {
      const aIsManualRTWCheck = a.requestId === MANUAL_RTW_CHECK;
      const bIsManualRTWCheck = b.requestId === MANUAL_RTW_CHECK;
      return (
        new Date(bIsManualRTWCheck ? b.completedAt : b.createdAt).getTime() -
        new Date(aIsManualRTWCheck ? a.completedAt : a.createdAt).getTime()
      );
    })[0];
    if (combinedListOfIdChecksAndRtwData && combinedListOfIdChecksAndRtwData?.length > 0 && latestCheck) {
      const isManualRTWCheck = latestCheck.requestId === MANUAL_RTW_CHECK;
      const idCheckDocumentName = isManualRTWCheck ? undefined : documentNameBasedOnIdChecks(latestCheck);
      const docForManualRTWCheck = isManualRTWCheck ? documentForManualRTWCheckEntry(latestCheck) : undefined;
      const documentNameForLatestCheck = isManualRTWCheck ? docForManualRTWCheck?.label : idCheckDocumentName;
      return (
        <Box component="dl" sx={definitionListSx}>
          <FieldStructure
            fieldTitle={polyglot.t('IdCheck.latestCheckDate')}
            fieldValue={latestCheck ? getDateForIdCheckEntry(latestCheck) : ''}
          />
          {latestCheck && latestCheck.status && (
            <FieldStructure
              fieldTitle={polyglot.t('IdCheck.status')}
              fieldValue={latestCheck && latestCheck.status ? toTitleCase(latestCheck.status) : undefined}
            />
          )}
          {latestCheck && latestCheck.result && (
            <FieldStructure
              fieldTitle={polyglot.t('IdCheck.result')}
              fieldValue={latestCheck.result ? toTitleCase(latestCheck.result) : undefined}
            />
          )}
          {documentNameForLatestCheck && (
            <FieldStructure fieldTitle={polyglot.t('IdCheck.document')} fieldValue={documentNameForLatestCheck ?? ''} />
          )}
        </Box>
      );
    }
    return <></>;
  }, [combinedListOfIdChecksAndRtwData, documentForManualRTWCheckEntry, documentNameBasedOnIdChecks, polyglot]);

  const columnData = useMemo<ColumnDef<IDVerificationResultDto, IDVerificationResultDto>[]>(
    () => [
      // {
      //   accessorFn: (row) => row,
      //   id: 'requestId',
      //   enableSorting: false,
      //   cell: ({ row: { original } }) => <div>{original?.requestId ? original.requestId : ''}</div>,
      // },
      {
        header: () => polyglot.t('IdCheck.dateOfCheck'),
        accessorFn: (row) => row,
        id: 'dateOfCheck',
        enableSorting: false,
        cell: ({ row: { original } }) => <div>{getDateForIdCheckEntry(original)}</div>,
      },
      {
        header: () => polyglot.t('IdCheck.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div style={{ textTransform: 'capitalize' }}>{original.status ? original.status : ''}</div>
        ),
      },
      {
        header: () => polyglot.t('IdCheck.resultZinc'),
        accessorFn: (row) => row,
        id: 'result',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <div style={{ textTransform: 'capitalize' }}>{original.result ? original.result : ''}</div>
        ),
      },
      {
        header: () => polyglot.t('IdCheck.documentName'),
        accessorFn: (row) => row,
        id: 'result',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const isManualRTWCheck = original.requestId === MANUAL_RTW_CHECK;
          const idCheckDocumentName = isManualRTWCheck ? undefined : documentNameBasedOnIdChecks(original);
          const docForManualRTWCheck = isManualRTWCheck ? documentForManualRTWCheckEntry(original) : undefined;
          return (
            <div>
              {original?.requestId?.length > 0 && idCheckDocumentName
                ? idCheckDocumentName
                : docForManualRTWCheck
                ? docForManualRTWCheck.label
                : ''}
            </div>
          );
        },
      },
    ],
    [documentForManualRTWCheckEntry, documentNameBasedOnIdChecks, polyglot]
  );

  const handleRowClick = useCallback(({ original }: Row<IDVerificationResultDto>) => {
    if (original.requestId === MANUAL_RTW_CHECK) {
      setManualCheckOpen(true);
    } else {
      setViewResultsOpen(true);
    }
    setCurrentIdCheck(original);
  }, []);

  const currentIdCheckIsExternal = useMemo(() => {
    if (currentIdCheck) {
      return currentIdCheck.requestId !== MANUAL_RTW_CHECK;
    }
    return false;
  }, [currentIdCheck]);

  const refreshIdCheckAndRtwData = useCallback(() => {
    refreshIdChecks!();
    fetchDocuments();
  }, [refreshIdChecks, fetchDocuments]);

  return loadingIdChecks || loadingRTW ? (
    <SkeletonLoader
      variant="rectangular"
      height={skeletonHeight(polyglot)}
      sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
    />
  ) : (
    <>
      <CardStructure
        cardTitle="Identity check"
        cardScope={SCOPE}
        showHistoryButton={combinedListOfIdChecksAndRtwData?.length > 0}
        showEditButton={false}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        userId={userId}
        showMissingInfo={!userHasIdCheck}
        addActionMenuOptions={IdCheckActionsMenuStructure}
        addAction={idCheckButtonForState}
        addButtonTitle={'New check'}
        newAction={isAdminUser && !userHasIdCheck ? newIdCheck : undefined}
        newButtonTitle={'New check'}
        emptyText={!userHasIdCheck ? getEmptyStateMessage() : undefined}
        cardFieldCustomUpdates={rtwData?.customUpdates}
        cardFieldStubs={['user.rightToWorkCheckDate', 'user.rightToWorkDocuments']}
        cardFieldDetails={cardFieldDetails}
        drawerDetails={
          <DrawerModal isOpen={drawerOpen} setIsOpen={setDrawerOpen}>
            <EditIdVerify
              onSave={async (checkPackage?: CheckPackage, checkCountry?: CheckCountry) => {
                await initateIdentityChecks(checkPackage, checkCountry);
                refreshIdCheckAndRtwData();
                setDrawerOpen(false);
              }}
              mode="profile"
            />
          </DrawerModal>
        }
        tableRowData={combinedListOfIdChecksAndRtwData}
        tableColumn={columnData}
        tableRowClickHandler={(row) => handleRowClick(row)}
      />
      {currentIdCheck && currentIdCheckIsExternal && (
        <ViewResultsForIdChecksModal
          isOpen={viewResultsOpen}
          setIsOpen={setViewResultsOpen}
          idCheckState={currentIdCheck}
          idCheckDocumentToDisplay={
            currentIdCheckIsExternal
              ? documentNameBasedOnIdChecks(currentIdCheck)
              : documentForManualRTWCheckEntry(currentIdCheck)?.label ?? ''
          }
        />
      )}

      <ScopesControl scopes={[RTW_SCOPE]} context={scopesContext}>
        {userId && (
          <DrawerModal isOpen={manualCheckOpen} setIsOpen={setManualCheckOpen}>
            <RightToWorkForm
              userId={userId}
              initialValues={{
                id: currentIdCheck?.id,
                rightToWorkCheckDate: currentIdCheck?.completedAt
                  ? getDateString(currentIdCheck?.completedAt, false, 'yyyy-MM-dd')
                  : '',
                rightToWorkDocuments: currentIdCheck?.reportId ? currentIdCheck?.reportId.toString() : '',
                rightToWorkChecked: true,
                rightToWorkDocumentTypes: currentIdCheck?.reportUrl
                  ? (currentIdCheck?.reportUrl as RightToWorkDocumentType)
                  : null,
                rightToWorkExpiryDate: null,
                rightToWorkIsLimited: null,
              }}
              documents={documents}
              fetchDocuments={fetchDocuments}
              onSubmit={(values) => {
                setRTWData(values);
                setManualCheckOpen(false);
              }}
              onClose={() => setManualCheckOpen(false)}
              documentTypes={documentTypes}
              fetchData={async () => refreshIdCheckAndRtwData()}
            />
          </DrawerModal>
        )}
      </ScopesControl>
    </>
  );
};
