import { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { MultiUserAvatar } from '@v2/components/theme-components/multi-user-avatar.component';
import { Typography } from '@v2/components/typography/typography.component';
import { BenefitPaymentActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-payment-action-item.component';
import { ExpenseRequestActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/expense-request-action-item.component';
import { InvoiceRequestActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/invoice-request-action-item.component';
import { PendingPHCancellationActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/pending-ph-cancellation-action-item.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateDomainTypes } from '@v2/infrastructure/i18n/translate.util';
import { useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import { nestErrorMessage } from '@/lib/errors';
import { REQUESTS_CHOOSE_REQUEST_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortDate } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DashboardEndpoints } from '@/v2/feature/dashboard/dashboard.api';
import { getDetailByDomain } from '@/v2/feature/dashboard/dashboard.util';
import { AppActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/app-action-item.component';
import { AttendanceActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/attendance-action-item.component';
import { ContractActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/contract-action-item.component';
import { DocumentActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/document-action-item.component';
import { RequestFormActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/request-form-action-item.component';
import { ReviewActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/review-action-item.component';
import { ShiftActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/shift-action-item.component';
import { SurveyActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/survey-action-item.component';
import { TaskActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/task-action-item.component';
import { TimeActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-action-item.component';
import {
  ActionListItems,
  DomainTypes,
  DomainTypesArray,
  KindTypes,
  TaskTodo,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { ActionItemsTabFilter } from '@/v2/feature/task/task.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserAPI } from '@/v2/feature/user/user.api';
import { filterStringToObject } from '@/v2/feature/user/user.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserActionList = () => {
  const { polyglot } = usePolyglot();
  const [globalState, dispatch] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [actionStatus, setActionStatus] = useState<ActionItemsTabFilter>(
    (currentUser?.features?.user?.actionItems?.tabFilter as ActionItemsTabFilter) ?? ActionItemsTabFilter.InSevenDays
  );
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t('TasksListPage.inSevenDays'), value: ActionItemsTabFilter.InSevenDays },
      { name: polyglot.t('TasksListPage.inThirtyDays'), value: ActionItemsTabFilter.InThirtyDays },
      { name: polyglot.t('TasksListPage.pending'), value: ActionItemsTabFilter.Pending },
      { name: polyglot.t('TasksListPage.approved'), value: ActionItemsTabFilter.Approved },
    ],
    [polyglot]
  );

  const { data: actionItems, isLoading: loading, mutate: refreshActionItems } = useApiClient(
    DashboardEndpoints.getAllActionItems(actionStatus),
    {
      suspense: false,
    }
  );
  const { data: requestForms } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), {
    suspense: false,
  });

  const refreshItems = useCallback(async () => {
    if (refreshActionItems) await refreshActionItems();
  }, [refreshActionItems]);

  const routerHistory = useHistory();
  const { getCachedUserById, loaded } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<ActionListItems | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');

  const updateUserFeaturesActionItemsFilter = useCallback(
    async (newFilterValue: ActionItemsTabFilter) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'user',
          'actionItems',
          'tabFilter',
          newFilterValue
        );

        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        console.error(`Failed to save user preferences for action items tab filter:  ${nestErrorMessage(error)}`);
      }
    },
    [dispatch]
  );

  const columns = useMemo<ColumnDef<ActionListItems, ActionListItems>[]>(
    () => [
      {
        header: () => polyglot.t('UserActionList.employeeName'),
        accessorFn: (row) => row,
        id: 'employeeName',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>{original.userId ? <UserCell userId={original.userId} /> : <EmptyCell />}</Box>
        ),
        maxSize: 120,
        minSize: 100,
      },

      {
        id: 'type',
        header: () => polyglot.t('UserActionList.type'),
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 80,
        minSize: 50,
        cell: ({ row: { original } }) => (
          <Box>
            {original?.type ? (
              <Typography variant="caption">{translateDomainTypes(original.type, polyglot)}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'detail',
        header: () => polyglot.t('UserActionList.detail'),
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 200,
        minSize: 160,
        cell: ({ row: { original } }) => (
          <Box>
            {original ? (
              <Typography variant="caption">
                {getDetailByDomain(original, getCachedUserById, currentUser, polyglot)}
              </Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'updatedAt',
        header: () => polyglot.t('UserActionList.dueDate'),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.updatedAt),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        cell: ({ row: { original } }) => (
          <Box>
            {'dueDate' in original && (original as TaskTodo)?.dueDate ? (
              <Typography variant="caption">{getDateString(original.dueDate)}</Typography>
            ) : original.updatedAt ? (
              <Typography variant="caption">{getDateString(original.updatedAt)}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'approver',
        header: () => polyglot.t('UserActionList.approver'),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            {original.approverSteps ? (
              <Box>
                <MultiUserAvatar
                  userIds={original.approverSteps
                    .flatMap((approverStep) => approverStep.approversIds ?? [])
                    .filter(
                      (userId) => !original.approvedByIds.includes(userId) && !original.rejectedByIds.includes(userId)
                    )}
                  showLimit={5}
                />
              </Box>
            ) : original.approverIds && original.approverIds.length > 1 ? (
              <MultiUserAvatar userIds={original.approverIds} showLimit={5} />
            ) : original.approverIds && original.approverIds.length === 1 ? (
              <UserCell userId={original.approverIds[0]} />
            ) : original.approverId ? (
              <UserCell userId={original.approverId} />
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
    ],
    [currentUser, getCachedUserById, polyglot]
  );

  const handleRowClick = useCallback(
    async (row: Row<ActionListItems>) => {
      setSelectedRow(row.original);
    },
    [setSelectedRow]
  );

  const filteredActionData = useMemo(() => {
    let filteredData = actionItems;
    if (searchInput) {
      filteredData = actionItems?.filter((a) => {
        const titleString = getDetailByDomain(a, getCachedUserById, currentUser, polyglot);
        return titleString.toLowerCase().includes(searchInput.toLowerCase());
      });
    }

    if (filterString && filteredData) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case 'type': {
              filteredData = filteredData.filter((d) => filterOptions[key].includes(d.type));
              break;
            }
            default:
              break;
          }
        }
      }
    }

    return filteredData;
  }, [searchInput, actionItems, filterString, currentUser, getCachedUserById, polyglot]);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('UserActionList.actions')}</Typography>}
        showAction={!!requestForms?.length}
        actions={[
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => routerHistory.push(REQUESTS_CHOOSE_REQUEST_ROUTE)}
          >
            {polyglot.t('UserActionList.new')}
          </ButtonComponent>,
        ]}
      />

      <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing.g5,
          }}
        >
          <TabFilterButtons
            filters={TabFilter}
            setFilterValue={(filterValue) => {
              setActionStatus(filterValue as ActionItemsTabFilter);
              updateUserFeaturesActionItemsFilter(filterValue as ActionItemsTabFilter);
            }}
            filterValue={actionStatus}
          />
          <CategoryFilters
            filterTypes={{
              type: DomainTypesArray.map((d) => {
                return { value: d, label: translateDomainTypes(d, polyglot) };
              }),
            }}
            setFilterString={setFilterString}
            filterString={filterString}
          />
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          <BasicTable
            rowData={filteredActionData ? [...filteredActionData] : []}
            columnData={columns}
            initialSort={[{ id: 'updatedAt', desc: true }]}
            loading={loading || !loaded}
            rowClick={handleRowClick}
          />
        </Box>

        {selectedRow?.type === DomainTypes.Tasks && (
          <TaskActionItem
            taskActionRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Expenses && (
          <ExpenseRequestActionItem
            expenseRequestTodo={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Invoices && (
          <InvoiceRequestActionItem
            invoiceRequestTodo={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.CustomBenefitPayments && (
          <BenefitPaymentActionItem
            pendingBenefitPaymentTodo={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Time && selectedRow.kind === KindTypes.PendingRequest && (
          <TimeActionItem
            timeActionRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Time && selectedRow.kind === KindTypes.PendingPHCancellation && (
          <PendingPHCancellationActionItem
            timeActionRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Documents && (
          <DocumentActionItem
            documentActionRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Contracts && (
          <ContractActionItem
            selectedRow={selectedRow}
            afterClose={() => setSelectedRow(undefined)}
            isModalOpen={true}
          />
        )}

        {selectedRow?.type === DomainTypes.Reviews && (
          <ReviewActionItem reviewActionRow={selectedRow} afterClose={() => setSelectedRow(undefined)} />
        )}

        {selectedRow?.type === DomainTypes.Surveys && (
          <SurveyActionItem surveyActionRow={selectedRow} afterClose={() => setSelectedRow(undefined)} />
        )}

        {selectedRow?.type === DomainTypes.Shifts && (
          <ShiftActionItem
            shiftActionRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}

        {selectedRow?.type === DomainTypes.Attendance && (
          <AttendanceActionItem
            attendanceActionRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => {
              setSelectedRow(undefined);
            }}
          />
        )}

        {selectedRow?.type === DomainTypes.Apps && (
          <AppActionItem appActionRow={selectedRow} afterClose={() => setSelectedRow(undefined)} />
        )}

        {selectedRow?.type === DomainTypes.Requests && (
          <RequestFormActionItem
            requestFormRow={selectedRow}
            refresh={refreshItems}
            afterClose={() => setSelectedRow(undefined)}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
