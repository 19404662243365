export interface RequestToBeApproved {
  approverSteps: ApproverStep[];
  approvedByIds: number[];
  rejectedByIds: number[];
}

export type ApprovalStep = {
  adminApproval: boolean;
  managerApproval: boolean;
  managerManagerApproval: boolean;
  specificApprovers: number[];
  minApprovers: number;
};

export type ApproverStep = {
  minApprovers: number;
  approversIds: number[];
  isComplete: boolean;
};

export enum DefaultApprovalRule {
  AutoApprove = 'Auto approve',
  Manager = 'Manager',
  Admin = 'Admin',
  ManagerPlusManager = "Manager + Manager's manager",
}
