import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { INVOICES_TEAM_OVERVIEW_ROUTE, INVOICES_TEAM_NEW_INVOICE_ROUTE, INVOICES_TEAM_ROUTE } from '@/lib/routes';
import { InvoicesPage } from '@/v2/feature/payments/pages/invoice.page';
import { NewInvoicePage } from '@/v2/feature/payments/pages/new-invoice.page';

export const InvoiceTeamRouter = ({ isManager }: { readonly isManager: boolean }) => {
  return (
    <Switch>
      {isManager && (
        <RouteScopesHas scopes={['invoices:manager']} path={INVOICES_TEAM_OVERVIEW_ROUTE}>
          <InvoicesPage reach="team" />
        </RouteScopesHas>
      )}
      {isManager && (
        <RouteScopesHas scopes={['invoices:manager']} path={INVOICES_TEAM_NEW_INVOICE_ROUTE}>
          <NewInvoicePage reach="team" />
        </RouteScopesHas>
      )}
      {isManager && (
        <RouteScopesHas scopes={['invoices:manager']} path={INVOICES_TEAM_NEW_INVOICE_ROUTE}>
          <NewInvoicePage reach="team" />
        </RouteScopesHas>
      )}
      <Redirect from={INVOICES_TEAM_ROUTE} to={INVOICES_TEAM_OVERVIEW_ROUTE} />
    </Switch>
  );
};
