import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceOwnership, DevicePossessionType, ReturnDeviceReason } from '@v2/feature/device/device.interface';
import { UserAddressAPI } from '@v2/feature/user/features/user-forms/user-address/user-address.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { formatAddress } from '@v2/util/user-data.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { SiteAPI } from '@/api-client/site.api';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface ReturnDeviceDrawerProps {
  readonly isOpen: boolean;
  readonly closeDrawerAfterReturn: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto | null;
}

export const ReturnDeviceDrawer = ({
  isOpen,
  closeDrawerAfterReturn,
  devicePossession,
  setIsOpen,
}: ReturnDeviceDrawerProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);

  const initiateDeviceReturn = async (data: ReturnDeviceFormData) => {
    if (!devicePossession) return;

    let isReturnInitiated = false;
    if (data.reason === ReturnDeviceReason.ReturnToCompanySite && !data.receiverAddress) {
      showMessage(polyglot.t('ReturnDeviceDrawer.errorMessages.deliveryAddress'), 'error');
      return;
    }

    let deliveryAddress = '';
    if (data.reason === ReturnDeviceReason.ReturnToCompanySite) {
      deliveryAddress = data.receiverAddress;
    } else {
      deliveryAddress = 'Zelt HQ';
    }

    try {
      await DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        data.reason,
        data.senderAddress,
        deliveryAddress,
        data.siteId ?? null,
        data.notes
      );
      if (
        devicePossession.possessionType === DevicePossessionType.ZeltStorage &&
        data.reason === ReturnDeviceReason.CancelContract
      ) {
        showMessage(polyglot.t('ReturnDeviceDrawer.infoMessages.initiated'), 'info');
      } else {
        showMessage(polyglot.t('ReturnDeviceDrawer.infoMessages.returnInitiated'), 'info');
      }
      isReturnInitiated = true;

      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await closeDrawerAfterReturn();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(
          polyglot.t('ReturnDeviceDrawer.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } else {
        showMessage(
          polyglot.t('ReturnDeviceDrawer.errorMessages.initiate', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    }
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      {devicePossession ? (
        <InitiateReturningOfDeviceContent
          devicePossession={devicePossession}
          initiateDeviceReturn={initiateDeviceReturn}
        />
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};

interface ReturnDeviceFormData {
  reason: ReturnDeviceReason;
  senderAddress: string;
  receiverAddress: string;
  siteId?: number;
  notes?: string;
}

interface InitiateReturningOfDeviceContentProps {
  readonly devicePossession: DevicePossessionDto;
  readonly initiateDeviceReturn: (data: ReturnDeviceFormData) => void;
}

const InitiateReturningOfDeviceContent = ({
  devicePossession,
  initiateDeviceReturn,
}: InitiateReturningOfDeviceContentProps) => {
  const { polyglot } = usePolyglot();
  const [senderAddress, setSenderAddress] = useState<string>('');
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const validationSchema = yup.object({
    reason: yup.string().required(polyglot.t('ReturnDeviceDrawer.errorMessages.reasonRequired')),
    senderAddress: yup.string().when('reason', {
      is: ReturnDeviceReason.CancelContract,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(polyglot.t('ReturnDeviceDrawer.errorMessages.sendAddRequired')),
    }),
    receiverAddress: yup.string().when('reason', {
      is: ReturnDeviceReason.ReturnToCompanySite,
      then: (schema) => schema.required(polyglot.t('ReturnDeviceDrawer.errorMessages.receivedAddress')),
      otherwise: (schema) => schema.notRequired(),
    }),
    siteId: yup.number().when('reason', {
      is: ReturnDeviceReason.ReturnToCompanySite,
      then: (schema) => schema.required(polyglot.t('ReturnDeviceDrawer.errorMessages.siteRequired')),
      otherwise: (schema) => schema.notRequired(),
    }),
    notes: yup.string().nullable().notRequired(),
  });

  const formik = useFormik<ReturnDeviceFormData>({
    initialValues: {
      reason:
        devicePossession.possessionType === DevicePossessionType.ZeltStorage
          ? ReturnDeviceReason.CancelContract
          : ReturnDeviceReason.ReturnToCompanySite,
      senderAddress,
      receiverAddress: devicePossession.possessionType === DevicePossessionType.ZeltStorage ? 'Zelt HQ' : '',
      siteId: undefined,
      notes: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: ReturnDeviceFormData) => {
      setIsSubmitting(true);
      await initiateDeviceReturn(values);
      setIsSubmitting(false);
    },
  });

  const fetchUserAddress = useCallback(
    async (assignedUserId: number): Promise<void> => {
      try {
        const userAddress = await UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && userAddress?.effectiveRecord) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(
          polyglot.t('ReturnDeviceDrawer.errorMessages.retrieveUserDetail', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);

  useEffect(() => {
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            return { value: site.id, label: site.name, address: site.address ?? '' };
          })
        );
      } catch (error) {
        showMessage(
          polyglot.t('ReturnDeviceDrawer.errorMessages.retrieveSitesList', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    })();
  }, [polyglot, showMessage]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('ReturnDeviceDrawer.returnDevice')}</Typography>
        <SelectComponent
          name="reason"
          label={polyglot.t('ReturnDeviceDrawer.returnReason')}
          disabled={devicePossession.possessionType === DevicePossessionType.ZeltStorage}
          options={[
            { value: ReturnDeviceReason.ReturnToCompanySite, label: polyglot.t('ReturnDeviceDrawer.sendToCompany') },
            { value: ReturnDeviceReason.SendToZeltStorage, label: polyglot.t('ReturnDeviceDrawer.sendToZelt') },
            ...(devicePossession?.device?.ownership === DeviceOwnership.Rental
              ? [
                  {
                    value: ReturnDeviceReason.Damage,
                    label: polyglot.t('ReturnDeviceDrawer.damage'),
                  },
                  {
                    value: ReturnDeviceReason.CancelContract,
                    label: polyglot.t('ReturnDeviceDrawer.cancel'),
                  },
                ]
              : []),
          ].filter((option) =>
            devicePossession.device?.ownership === DeviceOwnership.Company
              ? [ReturnDeviceReason.SendToZeltStorage, ReturnDeviceReason.ReturnToCompanySite].includes(option.value)
              : true
          )}
          value={formik.values.reason}
          compareValue={formik.values.reason}
          error={!!formik.errors.reason && formik.touched.reason}
          onChange={formik.handleChange}
          helperText={formik.errors.reason && formik.touched.reason}
        />
        {formik.values.reason === ReturnDeviceReason.ReturnToCompanySite && (
          <SelectComponent
            name="siteId"
            label={polyglot.t('ReturnDeviceDrawer.siteId')}
            options={sitesOptions}
            value={formik.values.siteId}
            compareValue={formik.values.siteId}
            error={!!formik.errors.siteId && formik.touched.siteId}
            helperText={(formik.touched.siteId && formik.errors.siteId) as string}
            onChange={async (e) => {
              await formik.handleChange(e);
              const siteAddress = sitesOptions.find((s) => s.value === e.target.value)?.address ?? '';
              await formik.setFieldValue('receiverAddress', siteAddress);
            }}
          />
        )}
        {devicePossession.possessionType !== DevicePossessionType.ZeltStorage && (
          <TextfieldComponent
            name="senderAddress"
            label={polyglot.t('ReturnDeviceDrawer.senderAddress')}
            value={formik.values.senderAddress ?? senderAddress}
            onChange={formik.handleChange}
            error={formik.touched.senderAddress && !!formik.errors.senderAddress}
            helperText={(formik.touched.senderAddress && formik.errors.senderAddress) ?? ' '}
          />
        )}
        {formik.values.reason === ReturnDeviceReason.ReturnToCompanySite && (
          <TextfieldComponent
            name="receiverAddress"
            label={polyglot.t('ReturnDeviceDrawer.receiverAddress')}
            value={formik.values.receiverAddress ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.receiverAddress && !!formik.errors.receiverAddress}
            helperText={(formik.touched.receiverAddress && formik.errors.receiverAddress) ?? ' '}
          />
        )}
        <TextfieldComponent
          name="notes"
          label={polyglot.t('ReturnDeviceDrawer.notes')}
          value={formik.values.notes}
          onChange={formik.handleChange}
          error={formik.touched.notes && !!formik.errors.notes}
          helperText={(formik.touched.notes && formik.errors.notes) ?? ' '}
        />
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={
              formik.values.reason === ReturnDeviceReason.CancelContract
                ? polyglot.t('ReturnDeviceDrawer.cancelcontract')
                : polyglot.t('ReturnDeviceDrawer.returnDevice')
            }
            fullWidth
            loading={isSubmitting}
            colorVariant="primary"
            sizeVariant="medium"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
