import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { ExpenseRequestActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/expense-request-action-item.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Salary } from '@/images/dashboard-icons/Salary.svg';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { ExpenseRequestBigWidget, ExpenseRequestTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const ExpenseRequestTodoComponent = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: ExpenseRequestBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<ExpenseRequestTodo | undefined>(undefined);

  const getTitle = (name: string, amount: number, currency: CurrencyShort): string => {
    const formattedAmount = formatMoney({ amount, currency });
    return polyglot.t('ExpenseModule.expenseRequestFrom', { name, amount: formattedAmount });
  };

  return (
    <Box>
      {todo.expenses.map((expense) => (
        <TodoCard
          key={`expense-${expense.id}`}
          icon={<Salary {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(expense.userName, expense.amount, expense.currency)}
            </Typography>
          }
          actionOnclick={() => setSelectedRow(expense)}
          showBorder={!setLastChild}
          userAvatar={expense.userId ? <UserAvatar key={expense.id} userId={expense.userId} size="xxxsmall" /> : <></>}
        />
      ))}

      {selectedRow && (
        <ExpenseRequestActionItem
          expenseRequestTodo={selectedRow}
          refresh={async () => {
            if (refreshTodos) await refreshTodos();
          }}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
