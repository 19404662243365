import { ChangeEvent, useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';

import { ReactComponent as Warning } from '@/images/side-bar-icons/Warning.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { BankingHoursBanks, timeOptions } from '@/v2/feature/payments/payments.util';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ExecutionDateStep = ({ onNext }: { onNext: () => void }) => {
  const [paymentContext, setState] = usePaymentContext();

  const isSinglePayment = paymentContext.payments?.length === 1;
  const isBulkPayment = paymentContext.payments && paymentContext.payments.length > 1;

  const executionDateTimeDays = isSinglePayment
    ? paymentContext?.institution?.config?.singlePayment?.executionDateTimeDays
    : paymentContext?.institution?.config?.bulkPayment?.executionDateTimeDays;
  const supportsScheduledPayment =
    (isSinglePayment && paymentContext?.institution?.config?.singlePayment?.supportsScheduledPayment) ||
    (isBulkPayment && paymentContext?.institution?.config?.bulkPayment?.supportsScheduledPayments);
  const supportsImmediatePayment =
    (isSinglePayment && paymentContext?.institution?.config?.singlePayment?.supportsImmediatePayment) ||
    (isBulkPayment && paymentContext?.institution?.config?.bulkPayment?.supportsImmediatePayments);

  // If no executionDateTimeDays or executionDateTimeDays = 0, set the initial executionDateTime to now + 10 minutes,
  // otherwise set it to today + executionDateTimeDays days at 9:00.
  const nextAvailableDateTime = executionDateTimeDays ?? 0;
  const firstAvailableDay = new Date();
  firstAvailableDay.setDate(firstAvailableDay.getDate() + nextAvailableDateTime);
  if (nextAvailableDateTime > 0) firstAvailableDay.setHours(9);
  firstAvailableDay.setMinutes(nextAvailableDateTime === 0 ? firstAvailableDay.getMinutes() + 10 : 0);

  const [showDateTimePicker, setShowDateTimePicker] = useState<string>(
    supportsImmediatePayment ? 'now' : 'selectedDate'
  );
  const [value, setValue] = useState<string>(new LocalDate(firstAvailableDay).toDateString());
  const [selectedTime, setSelectedTime] = useState<string>('09:00');

  const handleChange = (value: string) => {
    setShowDateTimePicker(value);
  };

  const handleDateChange = (value: string) => {
    setValue(value);
  };

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTime((event.target as HTMLInputElement)?.value);
  };

  const getDateTimeString = (value: string | dayjs.Dayjs, selectedTime: string): Date => {
    const timeArray = selectedTime.split(':');
    const paymentDate = dayjs(value).set('hour', Number(timeArray[0])).set('minute', Number(timeArray[1]));

    return paymentDate.toDate();
  };

  const goToNextStep = () => {
    if (showDateTimePicker === 'selectedDate' && !value) return;

    if (showDateTimePicker === 'selectedDate' && value) {
      const combined = getDateTimeString(value, selectedTime);
      setState(({ payments, requests, institution, payerDetails }) => {
        return {
          payments,
          requests,
          institution,
          payerDetails,
          executionDateTime: combined,
        };
      });
      onNext();
    }

    if (showDateTimePicker === 'now') {
      setState(({ payments, requests, institution, payerDetails }) => {
        return {
          payments,
          requests,
          institution,
          payerDetails,
          executionDateTime: undefined,
        };
      });
      onNext();
    }
  };
  const [filterString, setFilterString] = useState<string>(supportsImmediatePayment ? 'now' : 'selectedDate');

  const TabFilter = [
    ...(supportsImmediatePayment ? [{ name: 'Pay now', value: 'now' }] : []),
    ...(supportsScheduledPayment ? [{ name: 'Schedule for later', value: 'selectedDate' }] : []),
  ];

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px' }}>
        <Typography sx={{ ...themeFonts.title2 }}>When do you want to make payment?</Typography>

        {paymentContext.institution && BankingHoursBanks.includes(paymentContext.institution?.id) && (
          <Box sx={{ display: 'flex', ...spacing.mt20, alignItems: 'center', gap: spacing.g10 }}>
            <Box>
              <Warning width={16} height={16} style={{ fill: themeColors.orange }} />
            </Box>
            <Typography sx={{ ...themeFonts.caption }}>Payment execution must be during banking hours.</Typography>
          </Box>
        )}

        {showDateTimePicker === 'selectedDate' && !!executionDateTimeDays && (
          <Box sx={{ display: 'flex', ...spacing.mt20, alignItems: 'center', gap: spacing.g10 }}>
            <Box>
              <Warning width={16} height={16} style={{ fill: themeColors.orange }} />
            </Box>
            <Typography sx={{ ...themeFonts.caption }}>
              Your bank requires at least {executionDateTimeDays} day
              {executionDateTimeDays > 1 ? 's' : ''} in advance to execute the payment.
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: spacing.m30 }}>
          <TabFilterButtons
            filters={TabFilter}
            setFilterValue={setFilterString}
            filterValue={filterString}
            onFilterChange={({ filterValue }) => {
              setFilterString(filterValue);
              handleChange(filterValue);
            }}
          />

          <Box sx={{ ...spacing.mt20 }}>
            {showDateTimePicker === 'selectedDate' && (
              <>
                <Box sx={fieldSx}>
                  <FormControl size="small" fullWidth>
                    <DatePickerComponent
                      inputFormat="DD/MM/YYYY"
                      value={value}
                      onChange={(value) => {
                        if (dayjs(value).isValid()) {
                          handleDateChange(value);
                        }
                      }}
                      name="date"
                      label="Date"
                      error={undefined}
                      helperText={undefined}
                      minDate={
                        firstAvailableDay.getTime() > Date.now()
                          ? firstAvailableDay.toISOString().slice(0, 16)
                          : new Date().toISOString().slice(0, 16)
                      }
                    />
                  </FormControl>
                </Box>

                <Box sx={fieldSx}>
                  <SelectComponent
                    name="Time"
                    label="Time"
                    options={timeOptions}
                    value={selectedTime}
                    compareValue={selectedTime}
                    error={undefined}
                    onChange={handleTimeChange}
                    helperText={undefined}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ ...spacing.mt20 }}>
          <ButtonComponent fullWidth onClick={goToNextStep} sizeVariant="medium" colorVariant="primary">
            Continue
          </ButtonComponent>
        </Box>
      </Box>
    </Box>
  );
};
