import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Switch, Typography } from '@mui/material';
import { SuperAdminCompanyInfo, SuperAdminCompanyUser } from '@shared/modules/company/company.types';
import { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { sortBoolean, sortDate, sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { dateDiffInDays, dateIncludedInLastXHours } from '@/lib/date-time.lib';
import { nestErrorMessage } from '@/lib/errors';
import { AccountStatus } from '@/lib/users';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SSOApp, SSOAppsEnum } from '@/v2/feature/security/security-settings/security.interface';
import { IIncludable, TableColumn } from '@/v2/feature/super-admin/components/helper/table-helper';
import { DisableSSOConfirmDrawer } from '@/v2/feature/super-admin/features/super-admin-companies/components/confirm-disable-sso-for-company-modal.component';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const formatDate = (date: string) => moment(date).format('DD/MM/YYYY');

const formatCompanies = (companies: readonly SuperAdminCompanyInfo[]) =>
  companies.map((company) => {
    const { activeCompanyUsers, activeAccounts } = company.users.reduce(
      (result, user) => {
        if (user.lastTimeOnline) result.activeCompanyUsers.push(user);
        if ([AccountStatus.Active, AccountStatus.Invited, AccountStatus.InvitedToOnboard].includes(user.accountStatus))
          result.activeAccounts += 1;
        return result;
      },
      { activeCompanyUsers: [] as SuperAdminCompanyUser[], activeAccounts: 0 }
    );

    const lastTimeOnline = activeCompanyUsers
      .filter((user) => (user.lastTimeOnline ? new Date().getTime() > new Date(user.lastTimeOnline).getTime() : null))
      .sort((a, b) => {
        if (!b.lastTimeOnline) return 1;
        if (!a.lastTimeOnline) return -1;
        return new Date(b.lastTimeOnline).getTime() - new Date(a.lastTimeOnline).getTime();
      });
    const dayActiveUsers = activeCompanyUsers.filter((user) => {
      return user && user.lastTimeOnline && dateIncludedInLastXHours(user.lastTimeOnline, 24);
    });

    const weekActiveUsers = activeCompanyUsers.filter((user) => {
      return user && user.lastTimeOnline ? dateDiffInDays(new Date(), new Date(user.lastTimeOnline)) < 7 : false;
    });

    const monthActiveUsers = activeCompanyUsers.filter((user) => {
      return user && user.lastTimeOnline ? dateDiffInDays(new Date(), new Date(user.lastTimeOnline)) < 30 : false;
    });

    const totalActions = activeCompanyUsers.reduce((acc, user) => acc + (user.totalActions ?? 0), 0);

    return {
      companyId: company.companyId,
      name: company.name,
      createdAt: company.createdAt,
      totalUsers: company.users.length,
      activeUsers: activeCompanyUsers.length,
      lastTimeOnline: lastTimeOnline[0]?.lastTimeOnline ?? null,
      dau: dayActiveUsers.length,
      wau: weekActiveUsers.length,
      mau: monthActiveUsers.length,
      totalActions,
      noOfActiveDevices: company.noOfActiveDevices,
      noOfInstalledApps: company.noOfInstalledApps,
      isActive: company.isActive ?? false,
      hasSupport: company.hasSupport ?? false,
      prioritySupport: company.prioritySupport ?? false,
      activeAccounts,
      ssoMetadata: company.ssoMetadata,
    };
  });

export interface SSOMetadataForSuperadminTable {
  appId: number;
  appInstanceId: number;
  appStub: AppIntegrationStub;
}

interface CompanyDetails {
  readonly companyId: number;
  readonly name: string;
  readonly createdAt?: Date;
  readonly totalUsers: number;
  readonly activeUsers: number;
  readonly lastTimeOnline: Date | null;
  readonly dau: number;
  readonly wau: number;
  readonly mau: number;
  readonly totalActions: number;
  readonly noOfActiveDevices: number;
  readonly noOfInstalledApps: number;
  readonly isActive: boolean;
  readonly hasSupport: boolean;
  readonly prioritySupport: boolean;
  readonly ssoMetadata?: SSOMetadataForSuperadminTable[] | null;
}

export const SuperAdminCompaniesPage = (): React.JSX.Element => {
  const [companies, setCompanies] = useState<readonly SuperAdminCompanyInfo[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [companyIdToDisableSSO, setCompanyIdToDisableSSO] = useState<number | null>(null);
  const [ssoMetadataForCompanyToDisable, setSSOMetadataForCompanyToDisable] = useState<
    SSOMetadataForSuperadminTable[] | null
  >(null);
  const [confirmingDisableSSO, setConfirmingDisableSSO] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleStatusChange = async (companyId: number) => {
    try {
      await CompanyAPI.changeActiveStatus(companyId);
      showMessage('Company status updated successfully', 'success');
      await fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
      });
    } catch (error) {
      showMessage(`Something went wrong while changing status: ${nestErrorMessage(error)}`, 'error');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChatSupportChange = async (companyId: number) => {
    try {
      await CompanyAPI.changeChatSupportStatus(companyId);
      showMessage('Company chat support successfully updated', 'success');
      await fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
      });
    } catch (error) {
      showMessage(`Something went wrong while chat support: ${nestErrorMessage(error)}`, 'error');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePrioritySupportChange = async (companyId: number) => {
    try {
      await CompanyAPI.changeCompanyPrioritySupport(companyId);
      showMessage('Company chat support successfully updated', 'success');
      await fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
      });
    } catch (error) {
      showMessage(`Something went wrong while chat support: ${nestErrorMessage(error)}`, 'error');
    }
  };

  const fetchCompanies = useCallback(
    async (params?: { page: string; pageSize: string; searchQuery: string }) => {
      try {
        setIsLoadingCompanies(true);
        const { companies, pagination: paginationInfo } = await CompanyAPI.getAllCompaniesAsSuperAdmin(params);

        setCompanies(companies || []);
        const { totalPages, totalCount } = paginationInfo;

        setTotalPages(totalPages);
        setTotalItems(totalCount);
        setIsLoadingCompanies(false);
      } catch (error) {
        showMessage(`Something went wrong fetching companies. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [showMessage]
  );

  const fetchAllCompaniesForExport = useCallback(async () => {
    try {
      const { companies } = await CompanyAPI.getAllCompaniesAsSuperAdmin({
        page: '1',
        pageSize: '50',
        searchQuery: '',
        shouldLimitResult: 'false',
      });

      return (formatCompanies(companies) as unknown) as Datas;
    } catch (error) {
      console.error('Something went wrong fetching companies for export', error.message);
      return [];
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
      });
    })();
  }, [pageIndex, searchQuery, fetchCompanies]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const companiesColumn = useMemo<ColumnDef<IIncludable & CompanyDetails, unknown>[]>(
    () => [
      new TableColumn<CompanyDetails>().define({
        header: 'Id',
        id: 'companyId',
        size: 140,
        fieldName: 'companyId',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.companyId),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Company',
        id: 'name',
        size: 180,
        fieldName: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Date Created',
        id: 'createdAt',
        size: 140,
        fieldName: 'createdAt',
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Active Accounts',
        id: 'activeAccounts',
        size: 140,
        fieldName: 'activeAccounts',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.activeAccounts),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Registered Users',
        id: 'totalUsers',
        size: 140,
        fieldName: 'totalUsers',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalUsers),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Active Users',
        id: 'activeUsers',
        size: 140,
        fieldName: 'activeUsers',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.activeUsers),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Total Actions',
        id: 'totalActions',
        size: 140,
        fieldName: 'totalActions',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalActions),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Last time online',
        id: 'lastTimeOnline',
        size: 140,
        fieldName: 'lastTimeOnline',
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.lastTimeOnline),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'DAU',
        id: 'dau',
        size: 140,
        fieldName: 'dau',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.dau),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'WAU',
        id: 'wau',
        size: 140,
        fieldName: 'wau',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.wau),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'MAU',
        id: 'mau',
        size: 140,
        fieldName: 'mau',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.mau),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Active Devices',
        id: 'noOfActiveDevices',
        size: 140,
        fieldName: 'noOfActiveDevices',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.noOfActiveDevices),
      }),

      new TableColumn<CompanyDetails>().define({
        header: 'Active Apps',
        id: 'noOfInstalledApps',
        size: 140,
        fieldName: 'noOfInstalledApps',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.noOfInstalledApps),
      }),

      {
        header: () => 'Action',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isActive),
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={original.isActive}
                onChange={async () => {
                  await handleStatusChange(original.companyId);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            </Box>
          ) : (
            EmptyCell
          ),
        size: 220,
      },
      {
        header: () => 'Chat support',
        accessorFn: (row) => row,
        id: 'support',
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.hasSupport),
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={original.hasSupport}
                onChange={async () => {
                  await handleChatSupportChange(original.companyId);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            </Box>
          ) : (
            EmptyCell
          ),
        size: 220,
      },

      {
        header: () => 'Priority support',
        accessorFn: (row) => row,
        id: 'prioritySupport',
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.prioritySupport),
        cell: ({ row: { original } }) =>
          original.companyId ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={original.prioritySupport}
                onChange={async () => {
                  await handlePrioritySupportChange(original.companyId);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            </Box>
          ) : (
            EmptyCell
          ),
        size: 220,
      },
      {
        header: () => 'SSO status',
        accessorFn: (row) => row,
        id: 'ssoStatus',
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => Boolean(item.ssoMetadata)),
        cell: ({ row: { original } }) =>
          original.companyId && original?.ssoMetadata?.length ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <p>
                {original.ssoMetadata[0]?.appStub
                  ? SSOAppsEnum[(original.ssoMetadata[0].appStub as unknown) as SSOApp]
                  : ''}
              </p>
              <Switch
                checked={!!original.ssoMetadata}
                onChange={(event) => {
                  if (original.ssoMetadata?.length) {
                    event.preventDefault();
                    setConfirmingDisableSSO(true);
                    setCompanyIdToDisableSSO(original.companyId);
                    setSSOMetadataForCompanyToDisable(original.ssoMetadata);
                  } else {
                    console.warn('Unable to disable SSO: ', original.ssoMetadata);
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            </Box>
          ) : (
            <EmptyCell />
          ),
        size: 100,
      },
    ],
    [handleStatusChange, handleChatSupportChange, handlePrioritySupportChange]
  );

  const formattedCompanies = useMemo(() => formatCompanies(companies), [companies]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Companies</Typography>}
        views={<></>}
      />

      <ContentWrapper loading={isLoadingCompanies}>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              ...spacing.mb20,
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <TableSearch
              style={{ width: '16em' }}
              query={searchQuery}
              handleChange={(e) => {
                setSearchQuery(e.target.value?.trim() ?? '');
                // Resets pagination index once we perform a new search
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }}
              placeholder="Enter company name or id..."
            />

            <CsvDownloader filename="companies" separator="," datas={fetchAllCompaniesForExport}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g5,
                  ...secondaryCTABtn,
                  cursor: 'pointer',
                  height: '20px',
                }}
              >
                <Export {...iconSize} />{' '}
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Export</Typography>
              </Box>
            </CsvDownloader>
          </Box>
          {!isLoadingCompanies && (
            <>
              <BasicServerTable<IIncludable & CompanyDetails>
                rowData={formattedCompanies}
                columnData={companiesColumn}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                setPagination={setPagination}
                totalPages={totalPages}
                totalItems={totalItems}
              />
            </>
          )}
        </>
        {ssoMetadataForCompanyToDisable && companyIdToDisableSSO && confirmingDisableSSO && (
          <DisableSSOConfirmDrawer
            isOpen={confirmingDisableSSO}
            setIsOpen={setConfirmingDisableSSO}
            ssoMetadata={ssoMetadataForCompanyToDisable}
            companyId={companyIdToDisableSSO}
            afterAction={async () => {
              setCompanyIdToDisableSSO(null);
              setSSOMetadataForCompanyToDisable(null);
              setConfirmingDisableSSO(false);
              await fetchCompanies();
            }}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
