import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const StyledMenu = {
  //select paper
  '& div.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
    backgroundColor: themeColors.white,
    boxShadow: '0px 2px 20px 0px #0D0D0E1A',
    borderRadius: radius.br10,
    scrollbarWidth: 'none',
  },
  //select field menulist
  '& ul.MuiList-root.MuiList-padding.MuiMenu-list ': {
    padding: '0px',
  },

  //select field menuitem
  '& li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root': {
    color: 'black',
    ...themeFonts.caption,
    minHeight: '20px',
    paddingTop: spacing.m10,
    paddingBottom: spacing.m10,
    '&.Mui-selected': {
      color: themeColors.DarkGrey,
      ...themeFonts.title4,
    },
  },
};

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: themeColors.Background,
    },
  },
  '&[aria-selected="true"]': {
    backgroundColor: '#fff !important',
    borderColor: 'transparent',
  },
  '&.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
    backgroundColor: '#fff !important',
  },
}));

export const getSelectProps = {
  IconComponent: ArrowDownACIcon,
  MenuProps: { sx: { ...StyledMenu } },
};

export const iconSize = { width: 14, height: 14 } as const;
export const iconSize20 = { width: 20, height: 20 } as const;
