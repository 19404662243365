import React, { useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { MissingEmergency } from '@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-emergency.component';
import { MissingRole } from '@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-role.component';
import { MissingWorkContact } from '@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-work-contact.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { uniqBy } from 'lodash';

import { ReactComponent as Success } from '@/images/side-bar-icons/ok-green.svg';
import { CurrentUser } from '@/models';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ProfileWidgetReach } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { MissingAboutInformation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-about-information.component';
import { MissingAddress } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-address.component';
import { MissingBankAccount } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-bank-account.component';
import { MissingBasicInfo } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-basic-info.component';
import { MissingCompensation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-compensation.component';
import { MissingContract } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-contract.component';
import { MissingCustomSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-custom-section.component';
import { MissingFamilyInformation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-family-information.component';
import { MissingPersonal } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-personal.component';
import { MissingTaxInformation } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-tax-information.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const DEFAULT_CARDS: CustomProfileFormType[] = Object.values(CustomProfileFormType);

export const MissingFieldInformationViewDrawer = ({
  widgetData,
  user,
  refreshWidgetData,
}: {
  widgetData: ProfileWidgetReach;
  user: CurrentUser;
  refreshWidgetData?: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const sections = useMemo(() => {
    const missingFieldsArray = [
      ...(widgetData.missingInformation?.payroll ?? []),
      ...(widgetData.missingInformation?.personal ?? []),
    ];

    const missingFields = uniqBy(missingFieldsArray, 'cardId');
    const defaultMissingFields = missingFields.filter((mf) =>
      DEFAULT_CARDS.includes(mf.cardId as CustomProfileFormType)
    );
    const customMissingFields = missingFields.filter(
      (mf) => !DEFAULT_CARDS.includes(mf.cardId as CustomProfileFormType)
    );

    const result: React.JSX.Element[] = [];

    defaultMissingFields.forEach((field) => {
      switch (field.cardId) {
        // PERSONAL TAB
        case CustomProfileFormType.Basic:
          result.push(
            <MissingBasicInfo
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Details:
          result.push(
            <MissingPersonal
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Family:
          result.push(
            <MissingFamilyInformation
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.About:
          result.push(
            <MissingAboutInformation
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Other:
          break;

        // WORK TAB
        case CustomProfileFormType.Role:
          result.push(
            <MissingRole
              userId={user.userId}
              refreshData={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Contract:
          result.push(
            <MissingContract
              onlyAdd
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Lifecycle:
          break;
        case CustomProfileFormType.IdentityCheck:
          break;

        // COMPENSATION TAB
        case CustomProfileFormType.Salary:
          result.push(
            <MissingCompensation
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.BankAccount:
          result.push(
            <MissingBankAccount
              userId={user.userId}
              defaultName={`${user.firstName} ${user.lastName}`}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.TaxInformation:
          result.push(
            <MissingTaxInformation
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Equity:
          break;

        // CONTACT TAB
        case CustomProfileFormType.Address:
          result.push(
            <MissingAddress
              userId={user.userId}
              refreshPayroll={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.Emergency:
          result.push(
            <MissingEmergency
              userId={user.userId}
              refreshData={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
        case CustomProfileFormType.WorkContact:
          result.push(
            <MissingWorkContact
              userId={user.userId}
              refreshData={async () => {
                await refreshWidgetData?.();
              }}
            />
          );
          break;
      }
    });

    if (customMissingFields.length > 0) {
      result.push(
        <MissingCustomSection
          userId={user.userId}
          missingFields={customMissingFields}
          refreshPayroll={async () => {
            await refreshWidgetData?.();
          }}
        />
      );
    }

    return result;
  }, [user, refreshWidgetData, widgetData]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('MissingFieldInformationViewDrawer.missingInfo')}</Typography>

      <Stack sx={{ gap: spacing.g5 }}>
        <Typography variant="captionSmall">{polyglot.t('MissingFieldInformationViewDrawer.employee')}</Typography>
        <UserCell userId={user.userId} nameVariant="title4" />
      </Stack>

      <Stack gap="10px">
        {sections.length > 0 ? (
          sections.map((section, idx) => <Stack key={idx}>{section}</Stack>)
        ) : (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
            <Success height="1em" style={{ fill: themeColors.Green, flexShrink: 0 }} />
            <Typography variant="caption">{polyglot.t('MissingFieldInformationViewDrawer.allInfoPresent')}</Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
