import { capitalize } from 'lodash';

import { QuestionType } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';

export type GrowthQuestion = {
  id: string;
  factor: string | null;
  surveyQuestionBankId?: string;
  type: QuestionType;
  options: Record<number, string>;
  scaleConfig: ScaleConfig | null;
  questionText: string;
  hasComment: boolean;
  isCommentRequired: boolean;
};

export type GrowthSection = {
  id: string;
  name: string;
};

export type ScaleConfig = {
  id: string;
  type: string;
  points: {
    [key: string]: string;
  };
  value: {
    [key: string]: string;
  };
  sentiments: {
    [key: string]: string;
  };
};

export type OrderPreference = {
  type: 'section' | 'question';
  id: string;
  questions: string[] | null;
};

export enum CycleState {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Launching = 'launching',
  Ongoing = 'ongoing',
  Paused = 'paused',
  Completed = 'completed',
  Finalising = 'finalising',
}

export const CycleStateArray: CycleState[] = Object.values(CycleState);

export enum CycleType {
  Rolling = 'rolling',
  Scheduled = 'scheduled',
}

export const CycleTypeArray: CycleType[] = Object.values(CycleType);

export const cycleTypeOptions = [
  {
    value: CycleType.Scheduled,
    label: capitalize(CycleType.Scheduled),
    description:
      'One off or regular check-ins at fixed intervals, like quarterly performance reviews or weekly surveys.',
  },
  {
    value: CycleType.Rolling,
    label: capitalize(CycleType.Rolling),
    description:
      'Invite participants to the same cycle based on an enrolment trigger, such as probation or onboarding completions. ',
  },
];
