import React from 'react';

import { IconButton, Box } from '@mui/material';

import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { ReactComponent as Next } from '@/images/side-bar-icons/NextBtn.svg';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface NavigationButtonsProps {
  onNext?: () => void;
  onBack?: () => void;
  disableBack?: boolean;
  disabledNext?: boolean;
}

export const NavigationButtons = ({ onNext, onBack, disableBack, disabledNext }: NavigationButtonsProps) => {
  return (
    <Box sx={{ display: 'flex', gap: spacing.g5 }}>
      <IconButton
        sx={tableIconButtonSx}
        onClick={(e) => {
          onBack && onBack();
          e.stopPropagation();
        }}
        disabled={disableBack}
      >
        <Back {...iconSize} />
      </IconButton>
      <IconButton
        sx={tableIconButtonSx}
        onClick={(e) => {
          onNext && onNext();
          e.stopPropagation();
        }}
        disabled={disabledNext}
      >
        <Next {...iconSize} />
      </IconButton>
    </Box>
  );
};
