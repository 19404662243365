import { Redirect, Route, Switch } from 'react-router-dom';

import {
  SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE,
  SUPER_ADMIN_BILLING_INVOICES_ROUTE,
  SUPER_ADMIN_BILLING_PLANS_ROUTE,
} from '@/lib/routes';
import { SuperAdminBillingDiscountsPage } from '@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-discounts.page';
import { SuperAdminBillingInvoicesPage } from '@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-invoices.page';
import { SuperAdminBillingPlansPage } from '@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-plans.page';

const BILLING_PAGE_CONFIG = {
  header: {
    tabs: [
      { label: 'Plans', value: SUPER_ADMIN_BILLING_PLANS_ROUTE },
      { label: 'Discounts', value: SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE },
      { label: 'Invoices', value: SUPER_ADMIN_BILLING_INVOICES_ROUTE },
    ],
  },
} as const;

export const SuperAdminBillingRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route path={SUPER_ADMIN_BILLING_PLANS_ROUTE}>
        <SuperAdminBillingPlansPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route>

      <Route path={SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE}>
        <SuperAdminBillingDiscountsPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route>

      <Route path={SUPER_ADMIN_BILLING_INVOICES_ROUTE}>
        <SuperAdminBillingInvoicesPage pageConfig={BILLING_PAGE_CONFIG} />
      </Route>

      <Redirect to={SUPER_ADMIN_BILLING_PLANS_ROUTE} />
    </Switch>
  );
};
