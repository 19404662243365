import React, { useCallback, useState } from 'react';

import { Box, FormControl } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { generatePath, useHistory } from 'react-router-dom';

import { TemplateAPI } from '@/api-client/templates.api';
// import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from '@/lib/routes';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { ClearIconButton } from '@/v2/components/theme-components/clear-icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UpgradeToProModal, PlanNames } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly documentTypes: readonly ZeltDocumentType[];
}

export const TemplateModal = ({ setOpen, open, documentTypes }: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [templateName, setTemplateName] = useState<string>('');
  // const [templateHtmlText, setTemplateHtmlText] = useState<string>('');
  const [showMessage] = useMessage();
  const [isCreatingTemplate, setIsCreatingTemplate] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>('');
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const routerHistory = useHistory();
  // const [globalState] = useContext(GlobalContext);

  const createTemplate = useCallback(async () => {
    try {
      setIsCreatingTemplate(true);

      const templateId = await TemplateAPI.createTemplate({
        name: templateName,
        templateText: '',
        type: documentType,
      });

      routerHistory.push(generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, { templateId }), {
        returnPath: generatePath(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE),
      });
      setIsCreatingTemplate(false);
      setOpen(false);
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(
          polyglot.t('OnboardingByUser.errors.templateCreationFailed', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    } finally {
      setIsCreatingTemplate(false);
    }
  }, [polyglot, showMessage, templateName, setOpen, routerHistory, documentType]);

  return (
    <DrawerModal isOpen={open} setIsOpen={setOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('OnboardingByUser.newTemplate')}</Typography>

        <FormControl>
          <StyledTextfield
            label={polyglot.t('OnboardingByUser.name')}
            size="small"
            required
            value={templateName}
            onChange={({ target: { value } }) => setTemplateName(value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {templateName && templateName?.length > 0 && <ClearIconButton onClick={() => setTemplateName('')} />}
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <SelectComponent
          key="createTemplateType"
          name="createTemplateType"
          label={polyglot.t('OnboardingByUser.templateType')}
          value={documentType}
          options={documentTypes.map((d) => ({ label: d.label, value: d.value }))}
          onChange={(e) => setDocumentType(e.target.value)}
          required={true}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            fullWidth
            name={polyglot.t('General.save')}
            loading={isCreatingTemplate}
            disabled={
              !templateName || !documentType //|| globalState.user?.restrictions?.PEOPLE.disableDocTemplateCreation
            }
            onClick={createTemplate}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen: React.SetStateAction<boolean>) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.PEOPLE_PRO}
          messageSuffix="proGeneric"
        />
      </Box>
    </DrawerModal>
  );
};
