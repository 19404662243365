import { Box, Typography } from '@mui/material';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';

import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PensionProviderCellProps = {
  providerName: string;
  displayName?: string;
};

export const PensionProviderCell = ({ providerName, displayName }: PensionProviderCellProps) => {
  return (
    <Box sx={{ display: 'flex', gap: spacing.gap5, alignItems: 'center' }}>
      {getPensionLogoByProviderName(providerName)}
      <Typography sx={themeFonts.caption}>
        {displayName || PensionProvidersValueToLabel[providerName] || providerName}
      </Typography>
    </Box>
  );
};
