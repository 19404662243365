import {
  EXPENSES_COMPANY_OVERVIEW_ROUTE,
  EXPENSES_ME_OVERVIEW_ROUTE,
  EXPENSES_TEAM_OVERVIEW_ROUTE,
} from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const MONEY_EXPENSES_OVERVIEW_ROUTES: RoleRoutes = {
  company: EXPENSES_COMPANY_OVERVIEW_ROUTE,
  me: EXPENSES_ME_OVERVIEW_ROUTE,
  team: EXPENSES_TEAM_OVERVIEW_ROUTE,
};

export const MONEY_EXPENSE_SCOPES: RoleScopes = {
  company: ['expenses:all'] as Scope[],
  team: ['expenses:manager'] as Scope[],
  me: ['expenses'],
};
