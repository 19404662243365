import axios from 'axios';

export class MdmMigratorAPI {
  static async startMigration(mdmMigrationDto: MdmMigrationDto) {
    await axios.post(`/apiv2/zelt-mdm-migrator/start`, mdmMigrationDto);
  }
  static async startPoliciesSyncForAllDevices() {
    await axios.post(`/apiv2/zelt-mdm/devices/policy/sync`);
  }

  static async startMigrationForMobileDevices() {
    await axios.get(`/apiv2/zelt-mdm-migrator/mobile-devices`);
  }
}
export interface MdmMigrationDto {
  companyId: number;
  deviceIds: number[];
}
