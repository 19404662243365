import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReportConfigBackButton } from '@v2/feature/reports/reports-advanced/components/report-config-back-button.component';
import { ReportFilterCategory, ReportFilterSelected, ReportSQLOperator } from '@v2/feature/reports/reports.interface';
import { themeColors } from '@v2/styles/colors.styles';

interface Props {
  readonly goBack: () => void;
  readonly goToEdit: () => void;
  readonly reportFilters: readonly ReportFilterCategory[];
  readonly setFilterToEdit: React.Dispatch<React.SetStateAction<ReportFilterSelected | null>>;
}

export const ReportFiltersSelection = ({ reportFilters, goBack, goToEdit, setFilterToEdit }: Props) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredFilters = useMemo(() => {
    if (!searchInput) return reportFilters ?? [];

    const search = searchInput.toLowerCase();
    const filtered = [];
    for (const reportFilter of reportFilters) {
      if (reportFilter.category.toLowerCase().includes(search)) {
        filtered.push({ ...reportFilter });
        continue;
      }
      const filterCopy: ReportFilterCategory = {
        category: reportFilter.category,
        stub: reportFilter.stub,
        filters: {},
      };

      for (const key in reportFilter.filters) {
        if (reportFilter.filters[key]?.label.toLowerCase().includes(search)) {
          filterCopy.filters[key] = reportFilter.filters[key];
        }
      }
      if (Object.keys(filterCopy.filters).length > 0) filtered.push(filterCopy);
    }

    return filtered;
  }, [searchInput, reportFilters]);

  return (
    <Box sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', height: 'auto' }}>
      <ReportConfigBackButton title="Filters" goBack={goBack} />

      <TableSearch
        query={searchInput}
        handleChange={(e) => {
          setSearchInput(e.target.value);
        }}
        style={{ minWidth: '150px', maxWidth: '230px' }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          paddingRight: '4px',
          pb: 3,
        }}
      >
        {filteredFilters.map((filterCat) => {
          return (
            <Box key={filterCat.stub}>
              <Typography variant="title4" sx={{ mb: 1 }}>
                {filterCat.category}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {Object.keys(filterCat.filters).map((key) => {
                  return (
                    <Box
                      key={`${filterCat.stub}-${key}`}
                      sx={{
                        px: 0.5,
                        cursor: 'pointer',
                        ':hover': {
                          background: themeColors.Background,
                          borderRadius: '5px',
                        },
                      }}
                      onClick={() => {
                        setFilterToEdit({
                          stub: filterCat.stub,
                          filterKey: key,
                          op: ReportSQLOperator.eq,
                          value: '',
                          type: 'new',
                        });
                        goToEdit();
                      }}
                    >
                      <Typography variant="caption">{filterCat.filters[key].label}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
