import { useMemo } from 'react';

import { Box } from '@mui/material';

import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { UpdateAnswer } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/review-answer-submission.page';
import { UpsertReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { spacing } from '@/v2/styles/spacing.styles';

export const OpenEndedQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
}: {
  question: ReviewQuestion;
  reviewType: ReviewerTypes | undefined;
  answerArray: UpsertReviewAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: ReviewEntry) => void;
  entry: ReviewEntry | undefined;
}) => {
  const currentAnswer = useMemo(() => answerArray?.find((ans) => ans.questionId === question.id)?.comment || '', [
    answerArray,
    question.id,
  ]);

  if (!question || !reviewType || !entry) return <></>;

  const handleInputChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: null, comment: value }, entry);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <RichTextField
        key={question.id}
        value={currentAnswer}
        onChange={(value: string) => {
          handleInputChange(value);
        }}
      />
    </Box>
  );
};
