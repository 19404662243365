import { useCallback, useEffect, useState } from 'react';

import { generatePath, useHistory } from 'react-router-dom';

import { ContractAPI } from '@/api-client/contract.api';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { TEMPLATE_CONTRACT_SIGN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from '@/lib/routes';
import { MissingPersonalEmployeeFieldsModal } from '@/v2/feature/templates/components/missing-personal-employee-fields-modal.component';
import { RequiredContractFields, ContractObject } from '@/v2/feature/templates/templates.interface';

export interface OnboardingContractsProps {
  readonly selectedContract: ContractObject;
  readonly openModal: boolean;
  readonly setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OnboardingContracts = ({ selectedContract, openModal, setOpenModal }: OnboardingContractsProps) => {
  const userId = useUserIdParam();
  const routerHistory = useHistory();
  const [missingFieldsForContractSigning, setMissingFieldsForContractSigning] = useState<RequiredContractFields>();

  const handleMissingFieldContractPopulation = useCallback(
    (missingFields: RequiredContractFields) => {
      setMissingFieldsForContractSigning(missingFields);
      setOpenModal(true);
    },
    [setOpenModal]
  );

  const refreshMissingFieldsForEmployeeContract = async (contractId: string) => {
    const missingFields = await ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFieldsForContractSigning(missingFields);
    return missingFields;
  };

  const handleContractSignAction = useCallback(
    async (contract: ContractObject) => {
      const recipientSignatureRequired = (contract: ContractObject) => {
        return contract?.recipient === userId && !contract?.recipientSignatureTimestamp;
      };

      if (recipientSignatureRequired(contract) && contract?.id) {
        const missingFields = await refreshMissingFieldsForEmployeeContract(contract.id);
        if (Object.values(missingFields)?.some((v) => v === true)) {
          // have to open modal in parent if there are missing fields to avoid cluttering document table component
          handleMissingFieldContractPopulation(missingFields);
        } else {
          routerHistory.push(
            generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
              templateId: contract?.templateId as string,
              contractId: contract?.id as string,
              userId: contract?.recipient as number,
            }),
            { returnPath: generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }) }
          );
        }
      }
    },
    [handleMissingFieldContractPopulation, routerHistory, userId]
  );

  useEffect(() => {
    handleContractSignAction(selectedContract);
  }, [handleContractSignAction, selectedContract]);

  return (
    <>
      {selectedContract && missingFieldsForContractSigning && openModal && (
        <MissingPersonalEmployeeFieldsModal
          open={openModal}
          setOpen={setOpenModal}
          templateId={selectedContract.templateId}
          contractId={selectedContract.id}
          missingFields={missingFieldsForContractSigning}
          contractRecipientId={selectedContract.recipient}
          refreshMissingFields={refreshMissingFieldsForEmployeeContract}
        />
      )}
    </>
  );
};
