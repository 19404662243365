import { DOCUMENTS_COMPANY_ROUTE, DOCUMENTS_ME_ROUTE, DOCUMENTS_TEAM_ROUTE } from '@/lib/routes';
import { Scope } from '@/models';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import { DocumentsScopes } from '@/v2/feature/documents/documents.scopes';

export const PEOPLE_ALL_DOCUMENTS_ROUTES = [DOCUMENTS_COMPANY_ROUTE, DOCUMENTS_TEAM_ROUTE, DOCUMENTS_ME_ROUTE];

export const PEOPLE_DOCUMENTS_OVERVIEW_ROUTES: RoleRoutes = {
  company: DOCUMENTS_COMPANY_ROUTE,
  team: DOCUMENTS_TEAM_ROUTE,
  me: DOCUMENTS_ME_ROUTE,
};

export const PEOPLE_DOCUMENTS_OVERVIEW_SCOPES: RoleScopes = {
  company: [...DocumentsScopes.VIEW_COMPANY_DOCUMENTS] as Scope[],
  team: [...DocumentsScopes.VIEW_TEAM_DOCUMENTS],
  me: [...DocumentsScopes.VIEW_MY_DOCUMENTS],
};
