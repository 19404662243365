import React, { Suspense, useContext } from 'react';

import { CustomBenefitSettingsRouter } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/custom-benefit-settings.router';
import { InsuranceSettingsRouter } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/insurance-settings.router';
import { PensionSettingsRouter } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pension-settings.router';
import { CustomBenefitCategory } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceType } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@v2/styles/colors.styles';
import { Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import useScopes from '@/hooks/scopes.hook';
import { SETTINGS_BENEFITS_DETAILS_ROUTE, SETTINGS_BENEFITS_ROUTE } from '@/lib/routes';

export const BenefitsSettingsDetailsRouter = () => {
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId: currentUser.userId });
  const { companyConfig } = useCompanyConfigState();

  const params = useParams<{ category: string; id: string; productType: string }>();
  const category = params.category;
  const id = Number(params.id);
  const productType = params.productType;

  const isStdPension = productType === 'standard' && category === 'Pension';
  const isStdInsurance = productType === 'standard' && category === InsuranceType.Health;
  const isCustomType =
    !isStdPension && !isStdInsurance && (Object.values(CustomBenefitCategory) as string[]).includes(category);

  const allowAccessToDetails =
    (isStdPension || isStdInsurance || isCustomType) && (!isStdPension || companyConfig.inPayroll);

  return (
    <Switch>
      {allowAccessToDetails && (
        <RouteScopesControl
          context={context}
          scopes={isStdPension ? ['pension:all'] : ['insurance:all']}
          path={SETTINGS_BENEFITS_DETAILS_ROUTE}
        >
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="90%"
                height="90vh"
                sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
              />
            }
          >
            {isStdPension ? (
              <PensionSettingsRouter type={category} id={id} />
            ) : isStdInsurance ? (
              <InsuranceSettingsRouter category={category} id={id} />
            ) : isCustomType ? (
              <CustomBenefitSettingsRouter category={category as CustomBenefitCategory} id={id} />
            ) : null}
          </Suspense>
        </RouteScopesControl>
      )}

      <Redirect to={SETTINGS_BENEFITS_ROUTE} />
    </Switch>
  );
};
