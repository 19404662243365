import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { TableSearch } from '@v2/components/table/table-search.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { HelperDrawerField } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly membersIds: number[];
}

export const HelperAbsencePolicyMembersDrawer = ({ isOpen, setIsOpen, users, membersIds }: DrawerProps) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const members = useMemo(() => {
    return membersIds
      .map((memberId) => {
        const m = users.find((u) => u.userId === memberId);

        return { userId: m?.userId ?? memberId, name: m ? `${m.firstName} ${m.lastName}` : '' };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [users, membersIds]);

  const filteredMembers = useMemo(() => {
    if (!searchInput) return members;

    const search = searchInput.toLowerCase();

    return members.filter((m) => m.name.toLowerCase().includes(search) || m.userId.toString().includes(search));
  }, [members, searchInput]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box>
        <Typography variant="title2" sx={{ mb: '20px' }}>
          Members
        </Typography>

        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
          style={{ width: '100%', marginBottom: '10px' }}
          placeholder="Search"
        />

        {filteredMembers.map((member) => (
          <HelperDrawerField key={member.userId} label={member.name} value={member.userId} />
        ))}
      </Box>
    </DrawerModal>
  );
};
