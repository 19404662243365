import { ReactNode } from 'react';

import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';

import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export interface IconContentActionCardProps {
  title: ReactNode;
  content?: string | JSX.Element;
  showDivider?: boolean;
  iconMedia?: JSX.Element;
  action?: JSX.Element | null;
  titleSx?: SxProps<Theme>;
  iconGap?: React.CSSProperties['gap'];
  textGap?: React.CSSProperties['gap'];
  sx?: SxProps<Theme>;
  titleTag?: boolean;
  titleTagText?: string;
  titleTagTextSx?: SxProps<Theme>;
}

export const IconContentActionCard = ({
  title,
  content,
  showDivider = false,
  iconMedia,
  action,
  titleSx,
  iconGap,
  textGap,
  sx,
}: IconContentActionCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: spacing.g20,
        width: '100%',
        justifyContent: 'space-between',
        borderBottom: showDivider ? borders.background : 'none',
        ...spacing.pb20,
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', gap: iconGap ?? spacing.g15 }}>
        {/* image */}
        {!!iconMedia && <Box>{iconMedia}</Box>}

        {/* title + content */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: textGap ?? spacing.g5 }}>
          <Typography sx={{ ...themeFonts.title4, ...titleSx, color: themeColors.DarkGrey }}>{title}</Typography>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{content}</Typography>
        </Box>
      </Box>

      {/* action */}
      {!!action && <Box sx={{ display: 'flex' }}>{action}</Box>}
    </Box>
  );
};

type IconContentActionCardGroupProps = {
  cards: IconContentActionCardProps[];
  sx?: SxProps<Theme>;
};

export const IconContentActionCardGroup = ({ cards, sx }: IconContentActionCardGroupProps) => {
  if (cards.length === 0) {
    return <></>;
  }
  return (
    <Grid container spacing="40px" sx={sx}>
      {cards.map((card, idx) => (
        <Grid key={`action-card-${idx}`} item sm={12} md={6}>
          <IconContentActionCard
            {...card}
            titleSx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}
            iconGap={spacing.g20}
            textGap={spacing.g10}
            sx={{ paddingBottom: 0 }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

//intro card new branding
export const IntroCardGroup = ({ cards, sx }: IconContentActionCardGroupProps) => {
  if (cards.length === 0) {
    return <></>;
  }
  return (
    <Grid container spacing="40px" sx={sx}>
      {cards.map((card, idx) => (
        <Grid key={`icon-card-${idx}`} item sm={12} md={6}>
          <IntroCard
            {...card}
            titleSx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}
            textGap={spacing.g5}
            sx={{ paddingBottom: 0 }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const IntroCard = ({
  title,
  content,
  showDivider = false,
  iconMedia,
  action,
  titleSx,
  textGap,
  sx,
  titleTag,
  titleTagText,
  titleTagTextSx,
}: IconContentActionCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        borderBottom: showDivider ? borders.background : 'none',
        ...spacing.pb20,
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', gap: spacing.g10, flexDirection: 'column' }}>
        {/* image */}
        <Box>{!!iconMedia && iconMedia}</Box>

        {/* title + content */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: textGap ?? spacing.g5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <Typography sx={{ ...themeFonts.title4, ...titleSx, color: themeColors.DarkGrey }}>{title}</Typography>
            {titleTag && <Typography sx={titleTagTextSx}>{titleTagText}</Typography>}
          </Box>
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{content}</Typography>
        </Box>
      </Box>

      {/* action */}
      {!!action && <Box sx={{ display: 'flex' }}>{action}</Box>}
    </Box>
  );
};
