import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { MultipleSelectCheckbox } from '@v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  UserBenefitRequest,
  UserCustomBenefitRequestType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { isRecurringBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getAdditionalMembersOptions } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { UserFamilyMemberDto } from '@v2/feature/user/features/user-forms/user-family/user-family.dto';
import { UserFamilyMemberType } from '@v2/feature/user/features/user-forms/user-family/user-family.interface';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userBenefit: UserCustomBenefitDto;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
  readonly type: UserCustomBenefitRequestType;
}

export const UserCustomBenefitRequestDrawer = ({
  isOpen,
  setIsOpen,
  userBenefit,
  onClose,
  refresh,
  type,
}: DrawerProps) => {
  const { data: userFamilyMembers } = useApiClient(
    isRecurringBenefit(userBenefit.customBenefit?.type) ? UserEndpoints.getUserFamilyMembers(userBenefit.userId) : null,
    { suspense: false }
  );

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <UserCustomBenefitRequestDrawerContent
        userBenefit={userBenefit}
        userFamilyMembers={userFamilyMembers ?? []}
        refresh={refresh}
        setIsOpen={setIsOpen}
        type={type}
      />
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userBenefit: UserCustomBenefitDto;
  readonly userFamilyMembers: UserFamilyMemberDto[];
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly type: UserCustomBenefitRequestType;
}

export const UserCustomBenefitRequestDrawerContent = ({
  userBenefit,
  userFamilyMembers,
  refresh,
  setIsOpen,
  type,
}: DrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  // const isOptOut = useMemo(() => type === UserCustomBenefitRequestType.OptOut, [type]);
  const isRecurringChange = useMemo(() => type === UserCustomBenefitRequestType.RecurringChange, [type]);
  const isLoanRequest = useMemo(() => type === UserCustomBenefitRequestType.LoanRequest, [type]);

  const onSubmit = useCallback(
    async (values: UserBenefitRequest) => {
      try {
        setLoading(true);
        if (isLoanRequest) {
          await CustomBenefitAPI.requestUserBenefitLoan(
            userBenefit.userId,
            userBenefit.customBenefitId,
            userBenefit.id,
            values
          );
        } else if (isRecurringChange) {
          await CustomBenefitAPI.requestUserBenefitChange(
            userBenefit.userId,
            userBenefit.customBenefitId,
            userBenefit.id,
            values
          );
        }

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [isRecurringChange, isLoanRequest, userBenefit, refresh, setIsOpen, showMessage, polyglot]
  );

  const formik = useFormik<UserBenefitRequest>({
    initialValues: {
      amount: isLoanRequest ? 0 : null,
      numberOfInstallments: isLoanRequest ? 12 : null,
      notes: '',
      employerContribution: isRecurringChange ? userBenefit.employerContribution : null,
      employeeContribution: isRecurringChange ? userBenefit.employeeContribution : null,
      dependants: isRecurringChange ? userBenefit.dependants : null,
      dependantsList: isRecurringChange ? userBenefit.dependantsList : null,
      type,
      effectiveDate: new LocalDate().toDateString(),
    },
    validationSchema: yup.object({
      amount: yup
        .number()
        .nullable()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .min(0, polyglot.t('ValidationMessages.validValue'))
        .when('type', {
          is: UserCustomBenefitRequestType.LoanRequest,
          then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
          otherwise: (schema) => schema.notRequired(),
        }),
      numberOfInstallments: yup
        .number()
        .nullable()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .integer(polyglot.t('ValidationMessages.validValue'))
        .min(1, polyglot.t('ValidationMessages.validValue'))
        .when('type', {
          is: UserCustomBenefitRequestType.LoanRequest,
          then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
          otherwise: (schema) => schema.notRequired(),
        }),
      employerContribution: yup
        .number()
        .nullable()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .min(0, polyglot.t('ValidationMessages.validValue'))
        .when('type', {
          is: UserCustomBenefitRequestType.RecurringChange,
          then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
          otherwise: (schema) => schema.notRequired(),
        }),
      employeeContribution: yup
        .number()
        .nullable()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .min(0, polyglot.t('ValidationMessages.validValue'))
        .when('type', {
          is: UserCustomBenefitRequestType.RecurringChange,
          then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
          otherwise: (schema) => schema.notRequired(),
        }),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup.array().nullable().notRequired(),
      notes: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      effectiveDate: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit,
  });

  const dependantsListOptions = useMemo(() => {
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (
        userFamilyMembers
          ?.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type))
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (
        userFamilyMembers
          ?.filter((m) => m.type === UserFamilyMemberType.Child)
          .map((m) => ({
            value: m.id,
            label: m.name,
          })) ?? []
      );

    return userFamilyMembers?.map((m) => ({ value: m.id, label: m.name })) ?? [];
  }, [userFamilyMembers, formik.values.dependants]);

  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];

    return formik.values.dependantsList
      .map((id) => {
        const familyMember = userFamilyMembers.find((m) => m.id === id);

        return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
      })
      .filter(Boolean) as { label: string; value: number }[];
  }, [formik.values.dependantsList, userFamilyMembers]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Typography variant="title2">{polyglot.t('BenefitModule.newRequest')}</Typography>

        {type === UserCustomBenefitRequestType.LoanRequest && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="amount"
              label={polyglot.t('General.amount')}
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && !!formik.errors.amount}
              helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
            />
          </Box>
        )}
        {type === UserCustomBenefitRequestType.LoanRequest && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="numberOfInstallments"
              label={polyglot.t('BenefitModule.numberOfInstallments')}
              value={formik.values.numberOfInstallments}
              onChange={formik.handleChange}
              error={formik.touched.numberOfInstallments && !!formik.errors.numberOfInstallments}
              helperText={(formik.touched.numberOfInstallments && formik.errors.numberOfInstallments) ?? ' '}
            />
          </Box>
        )}

        {type === UserCustomBenefitRequestType.RecurringChange && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="employerContribution"
              label={polyglot.t('BenefitModule.employerContribution')}
              value={formik.values.employerContribution}
              onChange={formik.handleChange}
              error={formik.touched.employerContribution && !!formik.errors.employerContribution}
              helperText={(formik.touched.employerContribution && formik.errors.employerContribution) ?? ' '}
            />
          </Box>
        )}

        {type === UserCustomBenefitRequestType.RecurringChange && (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <TextfieldComponent
              name="employeeContribution"
              label={polyglot.t('BenefitModule.employeeContribution')}
              value={formik.values.employeeContribution}
              onChange={formik.handleChange}
              error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
              helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) ?? ' '}
            />
          </Box>
        )}

        {type === UserCustomBenefitRequestType.RecurringChange && userFamilyMembers.length > 0 ? (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <SelectComponent
              name="dependants"
              label={polyglot.t('BenefitModule.additionalMembers')}
              options={[{ label: polyglot.t('General.none'), value: '' }, ...getAdditionalMembersOptions(polyglot)]}
              value={formik.values.dependants ?? ''}
              onChange={formik.handleChange}
              compareValue={formik.values.dependants}
              error={!!formik.errors.dependants && formik.touched.dependants}
              helperText={formik.touched.dependants && (formik.errors.dependants as string)}
            />
          </Box>
        ) : type === UserCustomBenefitRequestType.RecurringChange ? (
          <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
            <Typography variant="captionSmall">{polyglot.t('BenefitModule.additionalMembers')}</Typography>
            <Typography variant="caption">{polyglot.t('BenefitModule.includeAdditionalBeneficiaries')}</Typography>
          </Box>
        ) : null}

        {type === UserCustomBenefitRequestType.RecurringChange &&
          userFamilyMembers.length > 0 &&
          formik.values.dependants && (
            <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
              <MultipleSelectCheckbox<number>
                label={polyglot.t('BenefitModule.additionalMembersList')}
                id="dependantsList"
                limitTags={-1}
                options={dependantsListOptions}
                value={selectedDependantsList}
                onChange={(_, values) => {
                  const updatedOptionList = values.map(({ value }) => value);
                  formik.setFieldValue('dependantsList', updatedOptionList);
                }}
                isOptionEqualToValue={(x, y) => x.value === y.value}
                getOptionLabel={({ label }: { label: string }): string => label}
              />
            </Box>
          )}

        <Box sx={{ ...fieldSx, mt: spacing.m10 }}>
          <TextfieldComponent
            name="notes"
            label={polyglot.t('General.notes')}
            value={formik.values.notes}
            onChange={formik.handleChange}
            error={formik.touched.notes && !!formik.errors.notes}
            helperText={(formik.touched.notes && formik.errors.notes) ?? ' '}
          />
        </Box>

        <Box sx={{ mt: spacing.m40 }}>
          <LoaderButton
            name={polyglot.t('General.request')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
