import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { TemplateAPI } from '@/api-client/templates.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE } from '@/lib/routes';
import { AccountStatus } from '@/lib/users';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import {
  ADDITIONAL_SIGNATORY_PLACEHOLDER,
  ContractTemplate,
  OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER,
  VerifyTemplateResult,
} from '@/v2/feature/templates/templates.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { JUNE_ACTIONS, useJune } from '@/v2/infrastructure/june/june.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ModalProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly onMissingField: (params: {
    templateVerificationResult: VerifyTemplateResult;
    contractRecipientUserId: number;
  }) => void;
  readonly contractTemplate: ContractTemplate;
  readonly existingRecipient?: number;
}

export const ContractModal = ({
  setOpen,
  open,
  contractTemplate,
  onMissingField,
  existingRecipient,
}: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [showMessage] = useMessage();
  const [contractRecipientUserId, setContractRecipientUser] = useState<number>();
  const [companySignatoryUserId, setCompanySignatoryUserId] = useState<number>();
  const [isVerifyingContractTemplate, setIsVerifyingContractTemplate] = useState<boolean>(false);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const routerHistory = useHistory();
  const { trackEvent } = useJune();

  // Call api to look for missing template variables
  const verifyTemplateParameters = useCallback(async () => {
    try {
      trackEvent({
        userId: user.userId,
        event: JUNE_ACTIONS.USED_DOCUMENT_TEMPLATE,
        properties: {
          contractRecipientUserId,
          companySignatoryUserId,
          templateId: contractTemplate.id,
          companyId: contractTemplate.companyId,
        },
      });
      if (!existingRecipient && !contractRecipientUserId) {
        showMessage(polyglot.t('ContractModal.errorMessages.select'), 'error');
        return;
      }

      setIsVerifyingContractTemplate(true);

      const finalRecipient = existingRecipient ? existingRecipient : contractRecipientUserId;

      if (!finalRecipient) {
        showMessage(polyglot.t('ContractModal.errorMessages.select'), 'error');
        return;
      }

      const templateResult = await TemplateAPI.verifyTemplateParameters({
        templateId: contractTemplate.id,
        contractRecipientUserId: finalRecipient,
        companySignatoryUserId,
      });

      const { hasMissingPersonalFields, hasMissingWorkFields } = templateResult;
      if (hasMissingPersonalFields || hasMissingWorkFields) {
        onMissingField({
          templateVerificationResult: templateResult,
          contractRecipientUserId: finalRecipient,
        });
        setIsVerifyingContractTemplate(false);
        setOpen(false);
        return;
      }

      setIsVerifyingContractTemplate(false);

      const stateToPassThrough = { companySignatoryUserId };

      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId: contractTemplate.id,
          userId: finalRecipient,
        }),
        stateToPassThrough
      );
    } catch (error) {
      showMessage(
        polyglot.t('ContractModal.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
      setIsVerifyingContractTemplate(false);
    }
  }, [
    polyglot,
    trackEvent,
    user.userId,
    contractRecipientUserId,
    companySignatoryUserId,
    contractTemplate.id,
    contractTemplate.companyId,
    existingRecipient,
    routerHistory,
    showMessage,
    onMissingField,
    setOpen,
  ]);

  const recipientOptionsExcludingCompanySignatory = useMemo(() => {
    return nonTerminatedCachedUsers
      .filter((user) => user.userId !== companySignatoryUserId && user.accountStatus !== AccountStatus.Deactivated)
      .map((u) => ({ label: u.displayName, value: u.userId, ...u }));
  }, [companySignatoryUserId, nonTerminatedCachedUsers]);

  const companySignatoryOptionsExcludingContractRecipient = useMemo(() => {
    return nonTerminatedCachedUsers
      .filter((user) => user.userId !== contractRecipientUserId && user.accountStatus !== AccountStatus.Deactivated)
      .map((u) => ({ label: u.displayName, value: u.userId, ...u }));
  }, [contractRecipientUserId, nonTerminatedCachedUsers]);

  // company_signature placeholder was changed to additional_signature, but we need to check for the old placeholder as well
  const templateHasCompanySignatory = useMemo(() => {
    return (
      contractTemplate.templateText.includes(OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER) ||
      contractTemplate.templateText.includes(ADDITIONAL_SIGNATORY_PLACEHOLDER)
    );
  }, [contractTemplate]);

  useEffect(() => {
    // ON page load, if recipient is already selected / initiating from user profile, then we should skip recipient selection
    if (existingRecipient) {
      setContractRecipientUser(existingRecipient);
      // if no company signatory or company signatory already filled then proceed to verifying template
      if ((companySignatoryUserId && templateHasCompanySignatory) || !templateHasCompanySignatory)
        verifyTemplateParameters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DrawerModal isOpen={open} setIsOpen={setOpen}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              paddingBottom: spacing.p10,
            }}
          >
            <Typography sx={{ ...themeFonts.title2 }}>{polyglot.t('ContractModal.populate')}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              paddingBottom: spacing.p10,
            }}
          >
            {!existingRecipient && (
              <Typography sx={{ ...themeFonts.caption }}>
                {polyglot.t('ContractModal.pleaseAssign', {
                  text: templateHasCompanySignatory ? polyglot.t('ContractModal.andAnyAdditionalSignatory') : ' ',
                })}
              </Typography>
            )}
            {existingRecipient && templateHasCompanySignatory && (
              <Typography sx={{ ...themeFonts.caption }}>
                {polyglot.t('ContractModal.additionalSignatoryAssign', {
                  text: templateHasCompanySignatory ? polyglot.t('ContractModal.additionalSignatory') : ' ',
                })}
              </Typography>
            )}
          </Box>

          {!existingRecipient && (
            <FormControl sx={{ marginTop: spacing.m30 }}>
              <SingleUserSelect
                name="contractRecipientUser"
                options={recipientOptionsExcludingCompanySignatory}
                onChange={(_, option) => {
                  setContractRecipientUser(option?.value);
                }}
                value={contractRecipientUserId}
                label={polyglot.t('ContractModal.contractRecipientUser')}
                error={false}
                helperText={false}
              />
            </FormControl>
          )}

          {templateHasCompanySignatory && (
            <FormControl sx={{ marginTop: spacing.m30 }}>
              <SingleUserSelect
                name="companySignatoryUser"
                options={companySignatoryOptionsExcludingContractRecipient}
                onChange={(_, option) => {
                  setCompanySignatoryUserId(option?.value);
                }}
                value={companySignatoryUserId}
                label={polyglot.t('ContractModal.companySignatoryUser')}
                error={false}
                helperText={false}
              />
            </FormControl>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '1s00%', ...spacing.mt40 }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <LoaderButton
                sizeVariant="large"
                colorVariant="primary"
                fullWidth={true}
                name={polyglot.t('ContractModal.createDraft')}
                loading={isVerifyingContractTemplate}
                disabled={
                  isVerifyingContractTemplate ||
                  (templateHasCompanySignatory && !companySignatoryUserId) ||
                  (!existingRecipient && !contractRecipientUserId)
                }
                onClick={verifyTemplateParameters}
              />
            </Box>
          </Box>
        </Box>
      </DrawerModal>
    </>
  );
};
