import { useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { CustomBenefitCategory } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { conditionalSx, fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoGrey.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  NetPayArrangement,
  PaymentValues,
  PensionProviders,
  PensionRulesOptions,
  ReliefAtSource,
  SalarySacrifice,
} from '@/lib/pensions';
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';

interface UpsertProviderFormData {
  pensionProvider: string;
  pensionRule: string;
  defaultWorkerGroup: string;
  paymentMethod: string;
  userName: string;
  password: string;
  isDefault: boolean;
  employerId: string;
  displayName: string;
}

interface ProviderSettingsStepProps {
  readonly payrollId: number;
  readonly pensionProvider: string;
  readonly pensionRule: string;
  readonly noOfActivePensionSchemes: number;
  readonly refresh: () => Promise<void>;
  readonly displayName: string;
}

export const ProviderSettingsStep = ({
  payrollId,
  pensionProvider,
  pensionRule,
  noOfActivePensionSchemes,
  refresh,
  displayName,
}: ProviderSettingsStepProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<UpsertProviderFormData>({
    initialValues: {
      pensionProvider,
      pensionRule,
      defaultWorkerGroup: '',
      paymentMethod: '',
      userName: '',
      password: '',
      isDefault: noOfActivePensionSchemes === 0,
      employerId: '',
      displayName: displayName ?? '',
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      pensionRule: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      defaultWorkerGroup: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      paymentMethod: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      userName: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      password: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      isDefault: Yup.boolean().notRequired(),
      employerId: Yup.string().when('pensionProvider', {
        is: PensionProviders.Nest,
        then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
      }),
      displayName: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    enableReinitialize: true,
    onSubmit: async (values: UpsertProviderFormData) => createProvider(values),
  });

  const createProvider = async (data: UpsertProviderFormData): Promise<void> => {
    const createData = {
      providerName: data.pensionProvider,
      username: data.userName,
      password: data.password,
      payMethod: data.paymentMethod,
      pensionRule: data.pensionRule,
      employeeContribution: 5,
      employerContribution: 3,
      defaultWorkerGroup: data.defaultWorkerGroup,
      employerId: data.pensionProvider === PensionProviders.Nest ? data.employerId : undefined,
      displayName: data.displayName,
    };

    try {
      setLoading(true);
      const createdPensionId = await PensionAPI.createPensionScheme(payrollId, createData);
      showMessage(polyglot.t('PensionModule.pensionProviderCreated'), 'success');
      await refresh();
      history.push(
        generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          productType: 'standard',
          id: createdPensionId,
          category: CustomBenefitCategory.Pension,
        })
      );
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
          <Typography variant="title2" sx={{ mb: spacing.mb30 }}>
            {polyglot.t('PensionModule.providerSettings')}
          </Typography>

          <Box sx={{ ...fieldSx, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption">{polyglot.t('PensionModule.pensionProvider')}</Typography>
            {formik.values.pensionProvider ? (
              <Box sx={{ display: 'flex', gap: spacing.g15, alignItems: 'center' }}>
                <Box sx={{ mt: spacing.m5 }}>{getPensionLogoByProviderName(formik.values.pensionProvider, 23)}</Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                  <Typography variant="title4">
                    {PensionProvidersValueToLabel[formik.values.pensionProvider] ?? formik.values.pensionProvider}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <EmptyCell />
            )}
          </Box>

          {formik.values.pensionProvider &&
          [PensionProviders.Nest, PensionProviders.SmartPension].includes(formik.values.pensionProvider) ? (
            <Box sx={{ ...conditionalSx, display: 'flex', gap: spacing.gap5, alignItems: 'center' }}>
              <CheckboxComponent
                label={polyglot.t('PensionModule.thisIsSalarySacrificePension')}
                name="isDefault"
                checked={formik.values.pensionRule === SalarySacrifice.value}
                onChange={() => {
                  if (formik.values.pensionRule !== SalarySacrifice.value)
                    formik.setFieldValue('pensionRule', SalarySacrifice.value);
                  else if (formik.values.pensionProvider === PensionProviders.Nest)
                    formik.setFieldValue('pensionRule', ReliefAtSource.value);
                  else formik.setFieldValue('pensionRule', NetPayArrangement.value);
                }}
              />
              <StyledTooltip
                title={
                  <Typography variant="captionSmall" sx={themeFonts.tooltip}>
                    {polyglot.t('PensionModule.agreementBeforeEnrollment')}{' '}
                    <a
                      href="https://www.zelt.app/post/the-employer-s-guide-to-pension-contributions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {polyglot.t('PensionModule.here')}
                    </a>
                  </Typography>
                }
              >
                <Box sx={{ cursor: 'pointer' }}>
                  <InfoIcon {...iconSize} />
                </Box>
              </StyledTooltip>
            </Box>
          ) : (
            <Box sx={fieldSx}>
              <SelectComponent
                name="pensionRule"
                label={polyglot.t('PensionModule.pensionRule')}
                options={PensionRulesOptions}
                value={formik.values.pensionRule}
                compareValue={formik.values.pensionRule}
                onChange={formik.handleChange}
                error={!!formik.errors.pensionRule && formik.touched.pensionRule}
                helperText={(formik.touched.pensionRule && formik.errors.pensionRule) as string}
              />
            </Box>
          )}

          {formik.values.pensionProvider === PensionProviders.Nest && (
            <Box sx={fieldSx}>
              <TextfieldComponent
                name="employerId"
                label={polyglot.t('PensionModule.employerId')}
                value={formik.values.employerId}
                onChange={formik.handleChange}
                endAdornment="none"
                error={formik.touched.employerId && !!formik.errors.employerId}
                helperText={(formik.touched.employerId && formik.errors.employerId) as string}
              />
            </Box>
          )}

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="displayName"
              label={polyglot.t('General.displayName')}
              value={formik.values.displayName}
              onChange={formik.handleChange}
              endAdornment="none"
              error={formik.touched.displayName && !!formik.errors.displayName}
              helperText={(formik.touched.displayName && formik.errors.displayName) as string}
            />
          </Box>

          <Box sx={fieldSx}>
            <SelectComponent
              name="paymentMethod"
              label={polyglot.t('PensionModule.paymentMethod')}
              options={PaymentValues}
              value={formik.values.paymentMethod}
              compareValue={formik.values.paymentMethod}
              onChange={formik.handleChange}
              error={!!formik.errors.paymentMethod && formik.touched.paymentMethod}
              helperText={(formik.touched.paymentMethod && formik.errors.paymentMethod) as string}
            />
          </Box>
          <Box sx={fieldSx}>
            <TextfieldComponent
              name="defaultWorkerGroup"
              label={polyglot.t('PensionModule.defaultWorkerGroup')}
              value={formik.values.defaultWorkerGroup}
              onChange={formik.handleChange}
              endAdornment="none"
              error={formik.touched.defaultWorkerGroup && !!formik.errors.defaultWorkerGroup}
              helperText={(formik.touched.defaultWorkerGroup && formik.errors.defaultWorkerGroup) as string}
            />
          </Box>
          <Box sx={fieldSx}>
            <TextfieldComponent
              name="userName"
              label={polyglot.t('PensionModule.username')}
              value={formik.values.userName}
              onChange={formik.handleChange}
              endAdornment="none"
              error={formik.touched.userName && !!formik.errors.userName}
              helperText={(formik.touched.userName && formik.errors.userName) as string}
            />
          </Box>
          <Box sx={fieldSx}>
            <TextfieldComponent
              name="password"
              label={polyglot.t('PensionModule.password')}
              value={formik.values.password}
              onChange={formik.handleChange}
              endAdornment="none"
              type="password"
              error={formik.touched.password && !!formik.errors.password}
              helperText={(formik.touched.password && formik.errors.password) as string}
            />
          </Box>

          <Box sx={{ ...buttonBoxSx, mt: spacing.mt30 }}>
            <LoaderButton
              name={polyglot.t('PensionModule.addProvider')}
              loading={loading}
              sizeVariant="large"
              colorVariant="primary"
              fullWidth
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
