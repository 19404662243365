import { useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserProfileActionAPI } from '@/v2/feature/user/features/user-profile-action/user-profile-action.api';

export interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  loading: boolean;
  deactivationBlocked: boolean;
  refresh: () => Promise<void>;
}

export const UserProfileDeactivateModal = ({
  isOpen,
  setIsOpen,
  userId,
  loading,
  deactivationBlocked,
  refresh,
}: Props): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [tempDeactivationDate, setTempDeactivationDate] = useState<string | null>(null);

  const triggerDeactivate = async () => {
    setIsDeactivating(true);
    if (tempDeactivationDate)
      await UserProfileActionAPI.deactivateUser(userId, { deactivationDate: tempDeactivationDate });
    showMessage(polyglot.t('UserProfileDeactivateModal.successMessages.deactivate'), 'success');
    await refresh();
    setIsOpen(false);
    setIsDeactivating(false);
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('UserProfileDeactivateModal.deactivate')}</Typography>

        {loading ? (
          <SkeletonLoader variant="rectangular" width="90%" height="20vh" sx={{ borderRadius: '10px', mx: 'auto' }} />
        ) : (
          <Box sx={drawerContentSx}>
            {deactivationBlocked && (
              <Typography variant="caption">{polyglot.t('UserProfileDeactivateModal.confirmDeactivation')}</Typography>
            )}

            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              label={polyglot.t('UserProfileDeactivateModal.deactivationDate')}
              name="deactivationDate"
              value={tempDeactivationDate ? tempDeactivationDate : ''}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  setTempDeactivationDate(value);
                }
              }}
              error={undefined}
              helperText={undefined}
            />

            <Box sx={buttonBoxDrawerSx}>
              <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)}>
                {polyglot.t('General.cancel')}
              </ButtonComponent>
              <LoaderButton
                disabled={!tempDeactivationDate || isDeactivating}
                name={
                  isDeactivating
                    ? polyglot.t('UserProfileDeactivateModal.deactivating')
                    : polyglot.t('UserProfileDeactivateModal.deactivate')
                }
                onClick={triggerDeactivate}
                loading={isDeactivating}
                fullWidth
                sizeVariant="medium"
                colorVariant="primary"
              />
            </Box>
          </Box>
        )}
      </Box>
    </DrawerModal>
  );
};
