import React, { Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { getCustomBenefitAllowanceTypeOptions } from '@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CustomBenefitAllowanceType,
  CustomBenefitDto,
  UpdateCustomBenefitBalanceDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import {
  isAllowanceBenefit,
  isRecurringBenefit,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { shouldBeNullTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

const getValidationSchema = (isAllowance: boolean, polyglot: Polyglot) =>
  yup.object({
    defaultEmployerContribution: yup
      .number()
      .nullable()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .notRequired(),
    defaultEmployeeContribution: yup
      .number()
      .nullable()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .notRequired(),
    defaultOpeningBalance: yup.number().nullable().typeError(polyglot.t('ValidationMessages.validValue')).notRequired(),
    allowanceType: isAllowance
      ? yup
          .string()
          .nullable()
          .oneOf(Object.values(CustomBenefitAllowanceType), polyglot.t('ValidationMessages.validValue'))
          .required(polyglot.t('ValidationMessages.requiredField'))
      : yup.mixed().nullable().test(shouldBeNullTest).notRequired(),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitBalanceDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <EditCustomBenefitBalanceDrawerContent setIsOpen={setIsOpen} refresh={refresh} customBenefit={customBenefit} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
  readonly refresh: () => Promise<void>;
}

export const EditCustomBenefitBalanceDrawerContent = ({ setIsOpen, refresh, customBenefit }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const isRecurring = isRecurringBenefit(customBenefit.type);
  const isAllowance = isAllowanceBenefit(customBenefit.type);

  const formik = useFormik<UpdateCustomBenefitBalanceDto>({
    initialValues: {
      allowanceType: customBenefit.allowanceType,
      defaultEmployerContribution: customBenefit.defaultEmployerContribution,
      defaultEmployeeContribution: customBenefit.defaultEmployeeContribution,
      defaultOpeningBalance: customBenefit.defaultOpeningBalance,
    },
    validationSchema: getValidationSchema(isAllowance, polyglot),
    onSubmit: async (values) => {
      const valuesToSubmit: UpdateCustomBenefitBalanceDto = {
        allowanceType: isAllowance ? values.allowanceType ?? CustomBenefitAllowanceType.OneOff : null,
        defaultEmployerContribution:
          isRecurring && values.defaultEmployerContribution ? Number(values.defaultEmployerContribution) : null,
        defaultEmployeeContribution:
          isRecurring && values.defaultEmployeeContribution ? Number(values.defaultEmployeeContribution) : null,
        defaultOpeningBalance:
          isAllowance && values.defaultOpeningBalance ? Number(values.defaultOpeningBalance) : null,
      };
      try {
        setLoading(true);
        await CustomBenefitAPI.updateCustomBenefitBalance(customBenefit.id, valuesToSubmit);
        showMessage(polyglot.t('BenefitModule.benefitUpdated'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.editBenefit')}</Typography>

        {isRecurring && (
          <TextfieldComponent
            name="defaultEmployerContribution"
            label={polyglot.t('BenefitModule.employerContribution')}
            value={formik.values.defaultEmployerContribution}
            onChange={formik.handleChange}
            error={formik.touched.defaultEmployerContribution && !!formik.errors.defaultEmployerContribution}
            helperText={
              (formik.touched.defaultEmployerContribution && formik.errors.defaultEmployerContribution) ?? ' '
            }
          />
        )}

        {isRecurring && (
          <TextfieldComponent
            name="defaultEmployeeContribution"
            label={polyglot.t('BenefitModule.employeeContribution')}
            value={formik.values.defaultEmployeeContribution}
            onChange={formik.handleChange}
            error={formik.touched.defaultEmployeeContribution && !!formik.errors.defaultEmployeeContribution}
            helperText={
              (formik.touched.defaultEmployeeContribution && formik.errors.defaultEmployeeContribution) ?? ' '
            }
          />
        )}

        {isAllowance && (
          <SelectComponent
            name="allowanceType"
            label={polyglot.t('BenefitModule.allowanceType')}
            options={getCustomBenefitAllowanceTypeOptions(polyglot)}
            value={formik.values.allowanceType ?? ''}
            onChange={formik.handleChange}
            error={!!formik.errors.allowanceType && formik.touched.allowanceType}
            helperText={formik.touched.allowanceType && (formik.errors.allowanceType as string)}
          />
        )}

        {isAllowance && (
          <TextfieldComponent
            name="defaultOpeningBalance"
            label={polyglot.t('BenefitModule.standardAllowance')}
            value={formik.values.defaultOpeningBalance}
            onChange={formik.handleChange}
            error={formik.touched.defaultOpeningBalance && !!formik.errors.defaultOpeningBalance}
            helperText={(formik.touched.defaultOpeningBalance && formik.errors.defaultOpeningBalance) ?? ' '}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
