import { useEffect, useMemo } from 'react';

import { UserAPI } from '@v2/feature/user/user.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Action, GlobalStateActions } from '@/GlobalState';

interface Props {
  filterString: string;
  initialFilterString: string;
  globalDispatch: React.Dispatch<Action>;
  view: 'company' | 'team' | 'personal';
}

export const AbsenceUserFilterFeaturesUpdater = ({
  filterString,
  initialFilterString,
  globalDispatch,
  view,
}: Props) => {
  const { polyglot } = usePolyglot();

  const featureName = useMemo(() => {
    if (view === 'company') return 'companyFilters';
    if (view === 'team') return 'teamFilters';
    return 'personalFilters';
  }, [view]);

  useEffect(() => {
    (async () => {
      if (filterString === initialFilterString) {
        return;
      }

      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'timeAway',
          'requestsTable',
          featureName,
          filterString
        );

        globalDispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        console.error(polyglot.t('AbsenceUserFilterFeaturesUpdater.errorMessages.update'), error);
      }
    })();
  }, [filterString, initialFilterString, globalDispatch, featureName, polyglot]);

  return null;
};
