import { useEffect } from 'react';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { OrgChartComponent } from '@/v2/feature/user/features/org-chart/components/org-chart.component';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export interface OrgChartProps {
  area: string;
  id: number;
  isLoggedUser: boolean;
  name: string;
  office: string;
  parentId: number;
  positionName: string;
  profileUrl: string;
  size: string;
  tags: string;
  entity: number | null;
  workerType: 'Employee' | 'Contractor' | null;
}

export const PeopleOrgView = () => {
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const { data: orgchartData, isValidating: loadingCharts } = useApiClient(UserEndpoints.getOrgChartUsers(), {
    suspense: false,
  });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });

  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Org chart');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper loading={loadingCharts}>
      {orgchartData ? (
        <OrgChartComponent
          data={[...orgchartData]}
          departments={departmentsForCompany}
          generalSettings={generalSettings}
        />
      ) : (
        <></>
      )}
    </ContentWrapper>
  );
};
