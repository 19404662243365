import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@v2/styles/spacing.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { BillingContactAPI } from '@/api-client/billing-contact.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BillingContact } from '@/models/subscription.model';

interface EditBillingContactDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly billingContact: BillingContact | undefined;
  readonly refresh: () => Promise<void>;
}

export const EditBillingContactDrawer = ({
  isOpen,
  setIsOpen,
  billingContact,
  refresh,
}: EditBillingContactDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <EditBillingContactDrawerContent billingContact={billingContact} refresh={refresh} />
    </DrawerModal>
  );
};

interface UpdateBillingContactData {
  userId: number | null;
  sendInvoicesTo: string;
  phoneNumber: string;
}

interface EditBillingContactDrawerContentProps {
  readonly billingContact: BillingContact | undefined;
  readonly refresh: () => Promise<void>;
}

const EditBillingContactDrawerContent = ({ billingContact, refresh }: EditBillingContactDrawerContentProps) => {
  const [showMessage] = useMessage();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik<UpdateBillingContactData>({
    initialValues: {
      userId: billingContact?.userId ?? null,
      sendInvoicesTo: billingContact?.invoiceEmail ?? '',
      phoneNumber: billingContact?.phoneNumber ?? '',
    },
    validationSchema: yup.object({
      userId: yup.number().required('Billing contact is required'),
      sendInvoicesTo: yup
        .string()
        .test('is-email-list', 'Please provide a list of valid emails', (value) => {
          if (!value) {
            return true; // Allow empty value
          }
          const emails = value.split(',').map((email) => email.trim());
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emails.every((email) => emailRegex.test(email));
        })
        .notRequired(),
      phoneNumber: yup
        .string()
        .trim()
        .matches(/^\+\d{7,15}$/, 'Enter a valid phone number. Use only numbers and +.')
        .required('Phone number is required.'),
    }),
    onSubmit: async (values): Promise<void> => {
      billingContact?.id ? await updateBillingContact(billingContact.id, values) : await createBillingContact(values);
    },
  });

  const updateBillingContact = async (
    contactId: number,
    { userId, sendInvoicesTo, phoneNumber }: UpdateBillingContactData
  ) => {
    if (!userId) {
      showMessage('Billing contact is required.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      await BillingContactAPI.updateContact({ id: contactId, userId, invoiceEmail: sendInvoicesTo, phoneNumber });
      showMessage('Contact updated successfully', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Couldn't update billing contact. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const createBillingContact = async ({ userId, sendInvoicesTo, phoneNumber }: UpdateBillingContactData) => {
    if (!userId) {
      showMessage('Billing contact is required.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      await BillingContactAPI.createContact(userId, sendInvoicesTo, phoneNumber);
      showMessage('Contact created successfully', 'success');
      await refresh();
    } catch (error) {
      console.error('Failed to save billing update', error);
      showMessage('Failed to save billing contact', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>Edit billing contact</Typography>

        <Box sx={fieldSx}>
          <SingleUserSelect
            name="userId"
            label="Billing contact"
            options="company"
            value={formik.values.userId}
            onChange={async (_, x: unknown) => {
              const userId = (x as { value: number })?.value ?? null;
              await formik.setFieldValue('userId', userId);
            }}
            error={Boolean(formik.errors.userId)}
            helperText={formik.errors.userId}
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            name="sendInvoicesTo"
            label="Invoices sent to"
            value={formik.values.sendInvoicesTo}
            onChange={formik.handleChange}
            error={formik.touched.sendInvoicesTo && !!formik.errors.sendInvoicesTo}
            helperText={
              (formik.touched.sendInvoicesTo && (formik.errors.sendInvoicesTo as string)) ||
              'Separate multiple emails by a comma'
            }
            endAdornment="none"
          />
        </Box>

        <Box sx={{ ...fieldSx, mb: spacing.m30 }}>
          <TextfieldComponent
            name="phoneNumber"
            label="Billing phone number"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={
              (formik.touched.phoneNumber && (formik.errors.phoneNumber as string)) || 'Should include country code'
            }
            placeholder="e.g. +441234567890"
            endAdornment="none"
          />
        </Box>

        <LoaderButton name="Save" loading={isLoading} sizeVariant="large" colorVariant="primary" fullWidth />
      </Form>
    </FormikProvider>
  );
};
