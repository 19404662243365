import React from 'react';

import { Box, Typography } from '@mui/material';
import { themeFonts } from '@v2/styles/fonts.styles';

import { ReactComponent as InfoGrey } from '@/images/side-bar-icons/InfoGrey.svg';
import { TooltipPopper } from '@/v2/components/theme-components/notification-tooltip-popper.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface InfoCardProps {
  title: string;
  value: string | number | React.ReactNode;
  tooltip?: JSX.Element | string;
  action?: React.ReactNode;
  infoIconAction?: React.ReactNode;
}

const iconSize = { width: 14, height: 14 } as const;

export const InfoCard = ({ title, value, tooltip, action, infoIconAction }: InfoCardProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{title}</Typography>
        {tooltip && (
          <TooltipPopper position="bottom" title={tooltip}>
            <InfoGrey {...iconSize} />
          </TooltipPopper>
        )}
        {!!infoIconAction && infoIconAction}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
        <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>{value}</Typography>
        {!!action && action}
      </Box>
    </Box>
  );
};
