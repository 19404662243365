import { PayrunEntry } from '@/models';
import { ExternalPayRunEntryStateEnum } from '@/v2/feature/payroll/payroll-external.interface';

export function cleanPayCodeValue(rawCode: string) {
  return (
    rawCode
      .trim()
      .toUpperCase()
      // codes must start with a letter and cannot contain anything other than letters, digits and underscores
      .replace(/^[^A-Z]+|[^A-Z0-9_]+/g, '')
  );
}

export const mapGlobalPayrollEntryToPayrunEntry = (userId: number, payrunResults: GlobalPayrun[]): PayrunEntry[] => {
  return payrunResults.map((pr) => {
    const grossValue = pr.entries[0].paylines.find((pl) => pl.code === 'BASIC');
    const basicValue = pr.entries[0].paylines.find((pl) => pl.code === 'BASIC_FIXED');
    const taxValue = pr.entries[0].paylines.find((pl) => pl.code === 'TAX');
    const totalCost = pr.entries[0].paylines.reduce((sum, payline) => sum + payline.amount, 0);
    const taxYearNumber = Number(pr.taxYear.replace('Year', ''));
    const grossPay = grossValue?.amount ?? 0;
    const taxAmount = taxValue?.amount ?? 0;

    const takeHomePay = grossPay - taxAmount;

    return {
      ...pr,
      name: 'test',
      metadata: {
        paymentDate: pr.paymentDate,
        payPeriod: pr.payPeriod,
        period: pr.period,
        companyId: pr.companyId,
        payrollId: pr.payrollId,
        startDate: pr.startDate,
        endDate: pr.endDate,
        taxYear: taxYearNumber,
        taxYearStart: pr.taxYearStart,
        state:
          pr.state === 'DRAFT'
            ? ExternalPayRunEntryStateEnum.Open
            : pr.state === 'COMPLETED'
            ? ExternalPayRunEntryStateEnum.Finalised
            : ExternalPayRunEntryStateEnum.Open,
        adjustments: 0,
        aeNotEnroledWarning: false,
        employeeId: userId.toString(),
        employeeNi: 0,
        employeePensionContribution: 0,
        employerNi: 0,
        employerPensionContribution: 0,
        gross: grossValue?.amount ?? 0,
        grossForNi: 0,
        grossForTax: 0,
        hasFps: true,
        isClosed: true,
        isLeaver: false,
        isNewStarter: true,
        netPay: 0,
        nilPaid: false,
        ordinal: 0,
        paymentMethod: '',
        payrollCode: pr.payrollId.toString(),
        takeHomePay,
        taxAmount: taxValue?.amount ?? 0,
        totalCost,
      },
      id: pr.id.toString(),
      url: '',
      totals: {
        basicPay: basicValue?.amount ?? 0,
        gross: grossValue?.amount ?? 0,
        grossForNi: 0,
        grossForTax: 0,
        employerNi: 0,
        employeeNi: 0,
        tax: 0,
        netPay: 0,
        adjustments: 0,
        additions: 0,
        deductions: 0,
        takeHomePay: 0,
        dednsFromNetPay: 0,
        pensionableEarnings: 0,
        pensionablePay: 0,
        employeePensionContribution: 0,
        employerPensionContribution: 0,
        empeePenContribnsNotPaid: 0,
        totalCost,
      },
    };
  });
};
