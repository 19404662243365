import { Box, CircularProgress } from '@mui/material';
import { themeColors } from '@v2/styles/colors.styles';

const styles = {
  root: { transform: 'rotate(90deg) scaleX(-1)' },
};

export const CircularProgressChart = ({
  value,
  color,
  size = 50,
  thickness = 10,
  baseColor = themeColors.Background,
}: {
  value: number;
  color: string;
  size?: number;
  thickness?: number;
  baseColor?: string;
}) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        thickness={thickness}
        size={size}
        sx={{ color: baseColor, position: 'absolute', left: 0 }}
        value={100}
        variant="determinate"
        style={styles.root}
      />
      <CircularProgress
        thickness={thickness}
        size={size}
        sx={{ color }}
        value={value}
        variant="determinate"
        style={styles.root}
      />
    </Box>
  );
};
