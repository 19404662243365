import { useMemo } from 'react';

import { Autocomplete, AutocompleteProps, Paper, Typography } from '@mui/material';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/lib/ReadonlyArray';

import { ReactComponent as ArrowDownACIcon } from '@/images/side-bar-icons/ArrowDownAC.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { ClearIcon } from '@/v2/components/theme-components/clear-icon.component';
import { COUNTRY_CODE_TO_NAME_MAPPING, COUNTRY_ISO_CODE_MAPPING } from '@/v2/infrastructure/country/country.interface';
import { getCountriesForNationality } from '@/v2/infrastructure/country/country.util';
import { StyledAuto, StyledAutoTextfield } from '@/v2/styles/autocomplete.styles';
import { StyledMenuItem } from '@/v2/styles/menu.styles';

const iconSize = { width: '14px', height: '14px' } as const;

const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map((r) =>
    pipe(
      r.options ?? [],
      RA.map((x) => ({ ...x, group: r.label }))
    )
  ),
  RA.flatten
);

type Multiple = false;
type DisableClearable = true;
type FreeSolo = false;

type AutocompleteCountryProps = AutocompleteProps<typeof countryOptions[number], Multiple, DisableClearable, FreeSolo>;

export type CountrySelectProps = Omit<
  AutocompleteCountryProps,
  'label' | 'options' | 'onChange' | 'renderInput' | 'value'
> & {
  label?: string;
  name: string;
  onChange: (countryName: string | null, isoCode?: string) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: string;
  valueType?: 'country-name' | 'iso-code';
};

export const CountrySelect = ({
  label = 'Country',
  name,
  onChange,
  error,
  helperText,
  value,
  valueType = 'country-name',
  ...autoCompleteProps
}: CountrySelectProps) => {
  const autoCompleteOptions = useMemo(
    () =>
      ({
        'country-name': () => countryOptions,
        // only return countries where we have an ISO code set
        'iso-code': () => countryOptions.filter((c) => c.value in COUNTRY_ISO_CODE_MAPPING),
      }[valueType]()),
    [valueType]
  );

  const autoCompleteValue = useMemo(() => {
    const countryName = {
      'country-name': () => value,
      'iso-code': () => value && COUNTRY_CODE_TO_NAME_MAPPING[value],
    }[valueType]();
    return autoCompleteOptions.find((option) => option.value === countryName);
  }, [valueType, value, autoCompleteOptions]);

  return (
    <Autocomplete
      {...autoCompleteProps}
      options={autoCompleteOptions}
      getOptionLabel={(option) => option.label}
      value={autoCompleteValue}
      renderOption={(props, option) => {
        return option.value === value ? (
          <StyledMenuItem {...props}>
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              {option.label}
              <ChoseIcon {...iconSize} />
            </Typography>
          </StyledMenuItem>
        ) : (
          <StyledMenuItem {...props}>{option.label}</StyledMenuItem>
        );
      }}
      groupBy={(option) => option.group}
      onChange={(_, x) => onChange(x?.value ?? null, COUNTRY_ISO_CODE_MAPPING[x?.value])}
      renderInput={(params) => (
        <StyledAutoTextfield
          {...params}
          variant="standard"
          size="small"
          InputLabelProps={{ shrink: true }}
          label={label}
          name={name}
          error={error}
          helperText={helperText}
        />
      )}
      popupIcon={<ArrowDownACIcon />}
      clearIcon={<ClearIcon />}
      PaperComponent={({ children }) => <Paper sx={StyledAuto}>{children}</Paper>}
    />
  );
};
