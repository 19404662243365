import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { FieldCard } from '@v2/components/field-card.component';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { BenefitPaymentsDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/benefit-payments-drawer.component';
import { RequestOnDemandBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/request-on-demand-benefit-drawer.component';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CustomBenefitPaymentMethodType,
  UserCustomBenefitPaymentStatus,
  UserCustomBenefitRequestType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import {
  calculatePaidAmount,
  isAllowanceBenefit,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { BenefitOptOutDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-opt-out-drawer.component';
import { UserBenefitOverviewTitle } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/user-benefit-overview-title.component';
import {
  UserCustomBenefitDto,
  UserCustomBenefitRequestStatus,
} from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserCustomBenefitListTableComponent } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-list-table.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';
import { round2Digits } from '@v2/util/number.util';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import {
  BENEFITS_ME_ROUTE,
  CUSTOM_BENEFIT_EXPENSE_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_BENEFITS_ROUTE,
} from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { RootStyle } from '@/v2/styles/root.styles';

interface AllowanceBenefitOverviewProps {
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly refresh: () => Promise<void>;
  readonly userId: number;
  readonly loading: boolean;
}

export const AllowanceBenefitOverview = ({
  userId,
  userCustomBenefit,
  refresh,
  loading,
}: AllowanceBenefitOverviewProps) => {
  const {
    data: userBenefitsRequests,
    mutate: refreshReq,
  } = useApiClient(
    CustomBenefitEndpoints.getUserCustomBenefitRequestsByBenefitId(
      userId,
      userCustomBenefit.customBenefitId,
      userCustomBenefit.id
    ),
    { suspense: false }
  );

  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPaymentsDrawerOpen, setIsPaymentsDrawerOpen] = useState<boolean>(false);
  const [selectedPaymentsDrawerType, setSelectedPaymentsDrawerType] = useState<'used' | 'requested'>('used');
  const [isOptOutOpen, setIsOptOutOpen] = useState<boolean>(false);

  const location = useLocation();
  const routerHistory = useHistory();

  const isMePage = location.pathname.includes('/me/');
  const isCurrentUser = userId === state.user.userId;

  const used = useMemo(() => {
    // if payments are not returned set used to total openingBalance;
    if (!userCustomBenefit.userCustomBenefitPayments) return userCustomBenefit.openingBalance ?? 0;

    return calculatePaidAmount(
      userCustomBenefit.userCustomBenefitPayments,
      userCustomBenefit.customBenefit?.type,
      userCustomBenefit.customBenefit?.allowanceType,
      new LocalDate().toDateString()
    );
  }, [userCustomBenefit]);

  const requested = useMemo(() => {
    // if payments are not returned set used to total openingBalance;
    if (!userCustomBenefit.userCustomBenefitPayments) return 0;

    return userCustomBenefit.userCustomBenefitPayments.reduce((total, payment) => {
      if (payment.status === UserCustomBenefitPaymentStatus.Pending) return total + payment.amount;

      return total;
    }, 0);
  }, [userCustomBenefit]);

  const remainingBalance = useMemo(() => {
    if (!userCustomBenefit.openingBalance) return 0;
    // if payments are not returned set remaining balance to 0;
    if (!userCustomBenefit.userCustomBenefitPayments) return 0;

    return round2Digits(userCustomBenefit.openingBalance - used - requested) as number;
  }, [userCustomBenefit, used, requested]);

  const hasNoPendingOptOutRequest = useMemo(() => {
    if (!userBenefitsRequests) return undefined;
    return (
      userBenefitsRequests.filter(
        (req) =>
          req.type === UserCustomBenefitRequestType.OptOut && req.status === UserCustomBenefitRequestStatus.Pending
      ).length === 0
    );
  }, [userBenefitsRequests]);

  const refreshRequests = useCallback(async () => {
    if (refreshReq) await refreshReq();
  }, [refreshReq]);

  return (
    <RootStyle>
      <TopHeader
        title={<UserBenefitOverviewTitle userCustomBenefit={userCustomBenefit} />}
        showBack
        backPath={
          matchPath(location.pathname, USER_BENEFITS_ROUTE)
            ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId })
            : BENEFITS_ME_ROUTE
        }
        actions={
          <Box sx={{ display: 'flex', gap: '5px' }}>
            {hasNoPendingOptOutRequest && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => {
                  setIsOptOutOpen(true);
                }}
              >
                {polyglot.t('BenefitModule.optOut')}
              </ButtonComponent>
            )}
            {remainingBalance > 0 && (
              <ButtonComponent
                colorVariant="primary"
                sizeVariant="small"
                onClick={() => {
                  if (
                    userCustomBenefit.customBenefit?.paymentMethod === CustomBenefitPaymentMethodType.Expenses &&
                    isMePage &&
                    isCurrentUser
                  )
                    routerHistory.push(
                      generatePath(CUSTOM_BENEFIT_EXPENSE_ROUTE, { id: userCustomBenefit.customBenefitId })
                    );
                  else if (
                    userCustomBenefit.customBenefit?.paymentMethod === CustomBenefitPaymentMethodType.Expenses &&
                    hasScopes(['insurance:all'], { userId })
                  )
                    routerHistory.push(
                      generatePath(USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE, {
                        userId,
                        id: userCustomBenefit.customBenefitId,
                      })
                    );
                  else if (userCustomBenefit.customBenefit?.paymentMethod !== CustomBenefitPaymentMethodType.Expenses)
                    setIsOpen(true);
                  else showMessage('This case is not implemented', 'error');
                }}
              >
                {polyglot.t('BenefitModule.newRequest')}
              </ButtonComponent>
            )}
          </Box>
        }
        showAction={
          hasScopes(['insurance'], { userId }) &&
          userCustomBenefit?.customBenefit?.type &&
          isAllowanceBenefit(userCustomBenefit.customBenefit.type)
        }
      />
      <ContentWrapper loading={loading} sx={{ mt: '20px' }}>
        {!hasNoPendingOptOutRequest && (
          <Typography variant="title4" sx={{ mb: '20px' }}>
            {polyglot.t('BenefitModule.optOutRequested')}
          </Typography>
        )}
        <Box sx={{ width: '600px', display: 'flex', gap: '5px', alignItems: 'center', mb: '30px' }}>
          <FieldCard
            name={polyglot.t('BenefitModule.allowance')}
            value={formatMoney({
              amount: userCustomBenefit.openingBalance ?? 0,
              asDecimal: true,
            })}
            action={undefined}
            color="secondary"
          />
          <Typography variant="caption">–</Typography>

          <FieldCard
            name={polyglot.t('BenefitModule.used')}
            value={formatMoney({ amount: used, asDecimal: true })}
            color="warning"
            action={
              used
                ? () => {
                    setSelectedPaymentsDrawerType('used');
                    setIsPaymentsDrawerOpen(true);
                  }
                : undefined
            }
          />

          {requested !== 0 && <Typography variant="caption">–</Typography>}

          {requested !== 0 && (
            <FieldCard
              name={polyglot.t('BenefitModule.requested')}
              value={formatMoney({ amount: requested, asDecimal: true })}
              action={
                requested
                  ? () => {
                      setSelectedPaymentsDrawerType('requested');
                      setIsPaymentsDrawerOpen(true);
                    }
                  : undefined
              }
              color="warning"
            />
          )}

          <Typography variant="caption">=</Typography>

          <FieldCard
            name={polyglot.t('BenefitModule.remaining')}
            value={formatMoney({ amount: remainingBalance, asDecimal: true })}
            action={undefined}
            color="success"
          />
        </Box>

        {isOpen && (
          <RequestOnDemandBenefitDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            userCustomBenefit={userCustomBenefit}
            remainingBalance={remainingBalance}
            refresh={refresh}
          />
        )}
        {isPaymentsDrawerOpen && userCustomBenefit.userCustomBenefitPayments && (
          <BenefitPaymentsDrawer
            isOpen={isPaymentsDrawerOpen}
            setIsOpen={setIsPaymentsDrawerOpen}
            type={selectedPaymentsDrawerType}
            userCustomBenefitPayments={userCustomBenefit.userCustomBenefitPayments}
            benefitType={userCustomBenefit.customBenefit?.type}
            allowanceType={userCustomBenefit.customBenefit?.allowanceType}
          />
        )}

        <UserCustomBenefitListTableComponent
          benefitId={userCustomBenefit.customBenefitId}
          userBenefit={userCustomBenefit}
          userId={userId}
        />

        <BenefitOptOutDrawer
          isOpen={isOptOutOpen}
          setIsOpen={setIsOptOutOpen}
          userId={userId}
          userBenefit={userCustomBenefit}
          refresh={refreshRequests}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
