import { useMemo } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle, SurveyFrequencyValues } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const useSCTimelineForm = (
  surveyCycle: SurveyCycle | null | undefined,
  onClose: () => void,
  refresh: () => Promise<void>
) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const validationSchema = useMemo(() => {
    return yup.object({
      timelineSettings: yup.object({
        startNow: yup.boolean().required(),
        startDate: yup
          .string()
          .nullable()
          .when('startNow', {
            is: false,
            then: yup.string().required('Start date is required'),
            otherwise: yup.string().nullable(),
          }),
        startTime: yup
          .string()
          .nullable()
          .when('startNow', {
            is: false,
            then: yup.string().required('Start time is required'),
            otherwise: yup.string().nullable(),
          }),
        startTimeMeridiem: yup
          .string()
          .nullable()
          .when('startNow', {
            is: false,
            then: yup.string().required('Start time meridiem is required'),
            otherwise: yup.string().nullable(),
          }),
      }),
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      timelineSettings: surveyCycle?.timelineSettings || {
        startNow: true,
        startDate: null,
        startTime: '9',
        startTimeMeridiem: 'AM',
        surveyFrequency: SurveyFrequencyValues.once,
        surveyOpenFor: 7,
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (surveyCycle) {
          await SurveyCycleAPI.updateTimelinelinesSurveyCycle(surveyCycle.id, { ...values.timelineSettings });
          showMessage(polyglot.t('CycleSettingsRouter.successMessages.update'), 'success');
          await refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};
