import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto, UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { UserInsurancePolicyStatus } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getUserNamesKeyedByUserIds } from '@v2/feature/device/device.util';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { ManageUserInsuranceDrawer } from '@v2/feature/super-admin/features/super-admin-insurance/components/manage-user-insurance-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tablePrimaryIconButtonSx } from '@v2/styles/icon-button.styles';
import { BackofficeRootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { keyBy } from 'lodash';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as CheckGreen } from '@/images/side-bar-icons/CheckGreen.svg';
import { ReactComponent as CleanCircle } from '@/images/side-bar-icons/CleanCircle.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { nestErrorMessage } from '@/lib/errors';

type TableRow = Omit<UserInsuranceDto, 'policyId'> & {
  policyId: number | null;
};

type SuperAdminInsuranceQuotesPageProps = {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly policies: readonly InsurancePolicyDto[];
  readonly pageConfig: PageConfig;
  readonly loading: boolean;
};

export const SuperAdminInsurancePolicyDetailsPage = ({
  companies,
  users,
  policies,
  loading,
  pageConfig,
}: SuperAdminInsuranceQuotesPageProps): JSX.Element => {
  const [userInsurances, setUserInsurances] = useState<UserInsuranceDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedUserInsurance, setSelectedUserInsurance] = useState<TableRow | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const params = useParams<{ readonly policyId: string }>();
  const policyId = Number(params.policyId);

  const policy = policies.find((policy) => policy.id === policyId);
  const company = policy ? companies.find((company) => company.companyId === policy.companyId) : undefined;

  const companyUsers = useMemo(() => {
    return company ? users.filter((user) => user.company.companyId === company.companyId) : [];
  }, [company, users]);

  const userNames = useMemo(() => getUserNamesKeyedByUserIds(companyUsers), [companyUsers]);

  const [showMessage] = useMessage();

  const refresh = useCallback(async () => {
    try {
      setIsLoading(true);
      const userInsurances = await InsuranceAPI.getAllUserInsuranceRecordsAsSuperAdmin(policyId);
      setUserInsurances(userInsurances);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get user insurance records. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showMessage, policyId]);

  const tableRows: TableRow[] = useMemo(() => {
    const userInsurancesByUserId = keyBy(userInsurances, 'userId');
    const nonIncludedUsers = companyUsers
      .filter((user) => !userInsurancesByUserId[user.userId])
      .map((user) => ({
        userId: user.userId,
        companyId: user.company.companyId,
        policyId: null,
        status: null,
        startDate: null,
        endDate: null,
        monthlyPremium: null,
        monthlyContribution: null,
        dependants: null,
        dependantsList: null,
        dependantsMonthlyPremium: null,
        dependantsMonthlyContribution: null,
        dependantsStatus: null,
        noOfDependants: null,
      }));
    return [...userInsurances, ...nonIncludedUsers];
  }, [companyUsers, userInsurances]);

  const filteredTableRows = useMemo(() => {
    return tableRows.filter(
      (row) =>
        (userNames[row.userId] && userNames[row.userId].toLowerCase().includes(searchInput.toLowerCase())) ||
        String(row.userId).includes(searchInput)
    );
  }, [userNames, tableRows, searchInput]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const createUserInsuranceRecord = useCallback(
    async (userId: number): Promise<void> => {
      if (!policy) {
        showMessage('Policy could not be found!', 'error');
        return;
      }
      try {
        await InsuranceAPI.createEmptyUserInsuranceAsSuperAdmin(policy.companyId, userId, policy.id);
        await refresh();
      } catch (error) {
        showMessage(`Could not create user record. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [policy, refresh, showMessage]
  );

  const columnData = useMemo<ColumnDef<TableRow, TableRow>[]>(() => {
    return [
      {
        header: () => 'Employee',
        accessorFn: (row) => row,
        id: 'userId',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userNames[userInsurance.userId] ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {userNames[userInsurance.userId]}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 120,
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.status ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{userInsurance.status}</Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => 'Start date',
        accessorFn: (row) => row,
        id: 'startDate',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.startDate ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {new Date(userInsurance.startDate).toLocaleDateString()}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => 'End date',
        accessorFn: (row) => row,
        id: 'endDate',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.endDate ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {new Date(userInsurance.endDate).toLocaleDateString()}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 100,
        minSize: 100,
      },
      {
        header: () => 'Monthly premium',
        accessorFn: (row) => row,
        id: 'monthlyPremium',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.monthlyPremium ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: userInsurance.monthlyPremium })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 130,
        minSize: 130,
      },
      {
        header: () => 'Monthly contribution',
        accessorFn: (row) => row,
        id: 'monthlyContribution',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.monthlyContribution ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: userInsurance.monthlyContribution })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 130,
        minSize: 130,
      },
      {
        header: () => 'Dependants',
        accessorFn: (row) => row,
        id: 'dependants',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.dependants ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {userInsurance.dependants}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 150,
        minSize: 150,
      },
      {
        header: () => 'Dependants status',
        accessorFn: (row) => row,
        id: 'dependantsStatus',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.dependants ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {userInsurance.dependantsStatus}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 150,
        minSize: 150,
      },
      {
        header: () => 'Dependants monthly premium',
        accessorFn: (row) => row,
        id: 'dependantsMonthlyPremium',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.dependantsMonthlyPremium ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: userInsurance.dependantsMonthlyPremium })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 180,
      },
      {
        header: () => 'Dependants monthly contribution',
        accessorFn: (row) => row,
        id: 'dependantsMonthlyContribution',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.dependantsMonthlyContribution ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: userInsurance.dependantsMonthlyContribution })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 180,
      },
      {
        header: () => 'Total premium',
        accessorFn: (row) => row,
        id: 'totalPremium',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          return userInsurance.monthlyPremium ? (
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
              {formatMoney({ amount: userInsurance.monthlyPremium + (userInsurance.dependantsMonthlyPremium ?? 0) })}
            </Typography>
          ) : (
            <EmptyCell />
          );
        },
        maxSize: 180,
        minSize: 180,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        cell: (info: CellContext<TableRow, TableRow>) => {
          const userInsurance: TableRow = info.getValue();
          const isOwnStatusPending =
            userInsurance.status &&
            [UserInsurancePolicyStatus.Pending, UserInsurancePolicyStatus.PendingOptOut].includes(userInsurance.status);
          const isDependantsStatusPending =
            userInsurance.dependantsStatus &&
            [UserInsurancePolicyStatus.Pending, UserInsurancePolicyStatus.PendingOptOut].includes(
              userInsurance.dependantsStatus
            );

          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} onClick={(e) => e.stopPropagation()}>
              {userInsurance.policyId && (isOwnStatusPending || isDependantsStatusPending) ? (
                <StyledMenuComponent
                  options={[
                    {
                      icon: <CheckGreen {...iconSize} />,
                      handler: async () => {
                        try {
                          await InsuranceAPI.addUserToInsurancePolicyAsSuperAdmin(
                            userInsurance.policyId!,
                            userInsurance.userId
                          );
                          await refresh();
                        } catch (error) {
                          console.error(error);
                          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                        } finally {
                          setIsLoading(false);
                        }
                      },
                      label: 'Add to policy',
                      hidden: userInsurance.status !== UserInsurancePolicyStatus.Pending,
                    },
                    {
                      icon: <CheckGreen {...iconSize} />,
                      handler: async () => {
                        try {
                          await InsuranceAPI.confirmUserDependantsAsSuperAdmin(
                            userInsurance.policyId!,
                            userInsurance.userId
                          );
                          await refresh();
                        } catch (error) {
                          console.error(error);
                          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                        } finally {
                          setIsLoading(false);
                        }
                      },
                      label: 'Confirm dependants',
                      hidden: userInsurance.dependantsStatus !== UserInsurancePolicyStatus.Pending,
                    },
                    {
                      icon: <CleanCircle {...iconSize} />,
                      handler: async () => {
                        try {
                          await InsuranceAPI.removeUserFromInsurancePolicyAsSuperAdmin(
                            userInsurance.policyId!,
                            userInsurance.userId
                          );
                          await refresh();
                        } catch (error) {
                          console.error(error);
                          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
                        } finally {
                          setIsLoading(false);
                        }
                      },
                      label: 'Remove from policy',
                      hidden: userInsurance.status !== UserInsurancePolicyStatus.PendingOptOut,
                    },
                  ]}
                  actionButtonDetails={{
                    type: 'iconButton',
                    colorVariant: 'secondary',
                    sizeVariant: 'small',
                    title: 'actions',
                    icon: <ActionsSmall {...iconSize} />,
                  }}
                />
              ) : userInsurance.policyId ? null : (
                <IconButton
                  sx={tablePrimaryIconButtonSx}
                  onClick={() => createUserInsuranceRecord(userInsurance.userId)}
                  id={`btnCreate_${userInsurance.userId}`}
                >
                  <Plus {...iconSize} />
                </IconButton>
              )}
            </Box>
          );
        },
        maxSize: 100,
        minSize: 80,
      },
    ];
  }, [createUserInsuranceRecord, refresh, showMessage, userNames]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Health Insurance</Typography>}
        views={<></>}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading || isLoading} secondLevel>
        {company && <Typography sx={themeFonts.title2}>{company.name}</Typography>}

        {policy && (
          <Box sx={{ display: 'flex', gap: spacing.gap10, alignItems: 'center', mt: spacing.m10 }}>
            <Typography sx={themeFonts.caption}>Provider:</Typography>
            <Typography sx={themeFonts.title4}>{policy.providerName}</Typography>
            <Box sx={{ ml: spacing.m10 }}>
              <TableSearch
                query={searchInput}
                handleChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </Box>
          </Box>
        )}

        <Box sx={spacing.mt20}>
          <BasicTable<TableRow>
            rowData={filteredTableRows}
            columnData={columnData}
            rowClick={(row) => {
              // if no policy assigned => no edit - the record should be created first (plus button is displayed)
              if (row.original.policyId) {
                setSelectedUserInsurance(row.original);
                setIsDrawerOpen(true);
              }
            }}
            hidePagination
          />
        </Box>

        {isDrawerOpen && policy && selectedUserInsurance?.policyId && (
          <ManageUserInsuranceDrawer
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            userInsurance={selectedUserInsurance as UserInsuranceDto}
            refresh={refresh}
            userName={userNames[selectedUserInsurance.userId] ?? 'N/A'}
            onSave={() => {
              setSelectedUserInsurance(null);
              setIsDrawerOpen(false);
            }}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
