import { FiltersEndpoints } from '@v2/feature/filters/filters.api';
import { TimeReportBalancesResults } from '@v2/feature/reports/features/create-report/time/time-report-balances-result.component';
import { TimeReportRequestsResults } from '@v2/feature/reports/features/create-report/time/time-report-requests-results.component';
import { FormDataInterface, ReportTypeTimeSelection } from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

export const TimeReportResults = ({
  formData,
  setFormData,
}: {
  readonly formData: FormDataInterface;
  readonly setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
}) => {
  const { data: filters } = useApiClient(FiltersEndpoints.geTimeFiltersOfReports(), {
    suspense: false,
  });

  return formData.type === ReportTypeTimeSelection.Balances ? (
    <TimeReportBalancesResults formData={formData} setFormData={setFormData} filters={filters?.peopleFilters ?? {}} />
  ) : formData.type === ReportTypeTimeSelection.Requests ? (
    <TimeReportRequestsResults
      formData={formData}
      setFormData={setFormData}
      filters={
        filters ?? {
          peopleFilters: {},
          timeFilters: {},
        }
      }
    />
  ) : null;
};
