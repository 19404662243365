import { useCallback } from 'react';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { CompanyEndpoints } from '@/api-client/company.api';
import { CompanyConfig } from '@shared/modules/company/company.types';

const DefaultCompanyConfig: CompanyConfig = {
  inPayroll: false,
  inPension: false,
  inGlobalPayroll: false,
};

export const useCompanyConfigState = (): {
  companyConfig: CompanyConfig;
  refreshCompanyConfig: () => Promise<void>;
  isLoading: boolean;
} => {
  const { data, mutate, isLoading } = useApiClient(CompanyEndpoints.getCompanyConfig(), { suspense: false });

  const refreshCompanyConfig = useCallback(async () => {
    if (mutate) await mutate();
  }, [mutate]);

  return {
    companyConfig: data ?? DefaultCompanyConfig,
    refreshCompanyConfig,
    isLoading: Boolean(isLoading),
  };
};
