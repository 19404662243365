import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { sortNumeric } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { SuperAdminDeviceDetailsDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-possession-drawer.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@v2/styles/colors.styles';

export const SuperAdminDeviceDetailsPossessions = ({ deviceId }: { deviceId: number }) => {
  const {
    data: devicePossessions,
    isLoading,
    mutate: refresh,
  } = useApiClient(DeviceEndpoints.getPossessionsByDeviceIdAsSuperAdmin(deviceId), { suspense: true });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPossession, setSelectedPossession] = useState<DevicePossessionDto | null>(null);

  const refreshPossessions = useCallback(async () => {
    if (refresh) refresh();
  }, [refresh]);

  const columns = useMemo<ColumnDef<DevicePossessionDto, DevicePossessionDto>[]>(() => {
    const getColor = (possession: DevicePossessionDto) => {
      if (possession.startDate && !possession.endDate) return themeColors.PastelGreen;
      else if (possession.startDate && possession.endDate) return themeColors.GreyMiddle;
      else return themeColors.BlueLight;
    };
    return [
      {
        id: 'id',
        header: 'ID',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        cell: ({ row: { original } }) => (
          <Box sx={{ bgcolor: getColor(original) }}>
            <Typography variant="caption">{original.id}</Typography>
          </Box>
        ),
      },
      {
        id: 'companyId',
        header: 'Company ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.companyId}</Typography>
          </Box>
        ),
      },
      {
        id: 'deviceId',
        header: 'Device ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.deviceId}</Typography>
          </Box>
        ),
      },
      {
        id: 'possessionType',
        header: 'Possession type',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.possessionType}</Typography>
          </Box>
        ),
      },
      {
        id: 'possessionId',
        header: 'Possession ID',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.possessionId}</Typography>
          </Box>
        ),
      },
      {
        id: 'startDate',
        header: 'Start date',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.startDate ? <Typography variant="caption">{original.startDate}</Typography> : <EmptyCell />}
          </Box>
        ),
      },
      {
        id: 'endDate',
        header: 'End date',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>{original.endDate ? <Typography variant="caption">{original.endDate}</Typography> : <EmptyCell />}</Box>
        ),
      },
      {
        id: 'deliveryAddress',
        header: 'Delivery address',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            {original.deliveryAddress ? (
              <Typography variant="caption">{original.deliveryAddress}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        id: 'createdAt',
        header: 'Created at',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{new Date(original.createdAt).toLocaleString()}</Typography>
          </Box>
        ),
      },
      {
        id: 'updatedAt',
        header: 'Updated at',
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{new Date(original.updatedAt).toLocaleString()}</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  const handleRowClick = useCallback((row: Row<DevicePossessionDto>) => {
    setSelectedPossession(row.original);
    setIsOpen(true);
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography variant="title3">Device possessions</Typography>
        <ButtonComponent
          sizeVariant="small"
          colorVariant="secondary"
          onClick={() => {
            setSelectedPossession(null);
            setIsOpen(true);
          }}
        >
          Add possession
        </ButtonComponent>
      </Box>

      <Box sx={{ mt: '5px' }}>
        <BasicTable<DevicePossessionDto>
          rowData={devicePossessions ?? []}
          columnData={columns}
          loading={isLoading}
          hidePagination
          rowClick={handleRowClick}
        />
      </Box>

      <SuperAdminDeviceDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deviceId={deviceId}
        devicePossession={selectedPossession}
        refreshPossessions={refreshPossessions}
      />
    </Box>
  );
};
