import { useContext, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { AddExistingDeviceDrawer } from '@v2/feature/device/features/devices-company/components/add-existing-device-drawer.component';
import { primarySmallBtn } from '@v2/styles/buttons.styles';
import { themeFonts } from '@v2/styles/fonts.styles';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import LaptopImage from '@/images/devices/laptop.png';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface MyDevicesEmptyOverviewProps {
  readonly refresh: () => Promise<void>;
}

export const MyDevicesEmptyOverview = ({ refresh }: MyDevicesEmptyOverviewProps) => {
  const [isAddExistingOpen, setIsAddExistingOpen] = useState<boolean>(false);

  const { hasScopes, getScopesContext } = useScopes();
  const [globalState] = useContext(GlobalContext);
  const scopesContext = getScopesContext(globalState.user);
  const hasDevicesEnrollScope = hasScopes(['devices.enroll', 'devices:all'], scopesContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
        justifyContent: 'space-between',
        gap: spacing.g60,
      }}
    >
      <Box sx={{ width: '100%', maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '70%', xl: '70%' } }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: spacing.g20 }}>
          <Box>
            <img src={LaptopImage} width={150} height={150} alt="laptop-default" />
          </Box>
          <Box>
            <Typography sx={themeFonts.title2}>Add your device</Typography>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m5 }}>
              If you already have a work laptop - add it to Zelt to make sure your device is secured. Our software will
              ensure you have full control and protection enabled.
            </Typography>

            <Button
              sx={{ ...spacing.mt20, ...primarySmallBtn }}
              onClick={() => setIsAddExistingOpen(true)}
              disabled={!hasDevicesEnrollScope}
            >
              Add device
            </Button>
          </Box>
        </Box>
      </Box>
      {isAddExistingOpen && (
        <AddExistingDeviceDrawer isOpen={isAddExistingOpen} setIsOpen={setIsAddExistingOpen} refresh={refresh} />
      )}
    </Box>
  );
};
