import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { AttendanceSettingsDto, UpdateAttendanceSettingsDto } from '@v2/feature/attendance/attendance.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { MINUTES_IN_WEEK } from '@v2/util/time.util';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { drawerContentSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const validationSchema = (polyglot: Polyglot) =>
  yup.object({
    weeklyMinutes: yup
      .number()
      .min(0, polyglot.t('EditAttendanceSettingsDrawer.InvalidValueMsg'))
      .max(MINUTES_IN_WEEK, polyglot.t('EditAttendanceSettingsDrawer.InvalidValueMsg'))
      .typeError(polyglot.t('EditAttendanceSettingsDrawer.InvalidValueMsg'))
      .required(polyglot.t('EditAttendanceSettingsDrawer.InvalidValueMsg')),
    workingMonday: yup.boolean().required(),
    workingTuesday: yup.boolean().required(),
    workingWednesday: yup.boolean().required(),
    workingThursday: yup.boolean().required(),
    workingFriday: yup.boolean().required(),
    workingSaturday: yup.boolean().required(),
    workingSunday: yup.boolean().required(),
  });

interface EditAttendanceSettingsDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly attendanceSettings: AttendanceSettingsDto;
  readonly refresh: () => Promise<void>;
}

export const EditAttendanceSettingsDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSettings,
  refresh,
}: EditAttendanceSettingsDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <EditAttendanceSettingsDrawerContent
          attendanceSettings={attendanceSettings}
          refresh={refresh}
          setIsOpen={setIsOpen}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface EditAttendanceSettingsDrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly attendanceSettings: AttendanceSettingsDto;
  readonly refresh: () => Promise<void>;
}

const EditAttendanceSettingsDrawerContent = ({
  attendanceSettings,
  refresh,
  setIsOpen,
}: EditAttendanceSettingsDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const [hours, setHours] = useState(Math.round(Math.floor(attendanceSettings.weeklyMinutes / 60)));
  const [minutes, setMinutes] = useState(
    Math.round(attendanceSettings.weeklyMinutes - Math.floor(attendanceSettings.weeklyMinutes / 60) * 60)
  );

  const formik = useFormik<UpdateAttendanceSettingsDto>({
    initialValues: {
      weeklyMinutes: attendanceSettings.weeklyMinutes ?? 40,
      workingMonday: attendanceSettings.workingMonday ?? true,
      workingTuesday: attendanceSettings.workingTuesday ?? true,
      workingWednesday: attendanceSettings.workingWednesday ?? true,
      workingThursday: attendanceSettings.workingThursday ?? true,
      workingFriday: attendanceSettings.workingFriday ?? true,
      workingSaturday: attendanceSettings.workingSaturday ?? false,
      workingSunday: attendanceSettings.workingSunday ?? false,
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: async (values: UpdateAttendanceSettingsDto) => {
      await updateSettings({ ...values, weeklyMinutes: Number(values.weeklyMinutes) });
    },
  });

  useEffect(() => {
    formik.setFieldValue('weeklyMinutes', hours * 60 + minutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, minutes]);

  const updateSettings = useCallback(
    async (updateData: UpdateAttendanceSettingsDto) => {
      try {
        setLoading(true);
        await AttendanceAPI.updateAttendanceSettings(updateData);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('EditAttendanceSettingsDrawer.SomethingWentWrongMsg', { errorMsg: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, showMessage, refresh, setIsOpen]
  );

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('SettingsRouterPageConfig.other')}</Typography>

        <Box>
          <Typography variant="caption" sx={{ mb: '10px' }}>
            {polyglot.t('EditAttendanceSettingsDrawer.DefaultTotalWeeklyWorkingHours')}
          </Typography>

          <Box sx={{ ...fieldSx, display: 'flex', gap: spacing.g10 }}>
            <TextfieldComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Hours')}
              name="hours"
              value={hours}
              onChange={(e) => {
                const value = e.target.value;
                setHours(Number(value));
              }}
              error={formik.touched.weeklyMinutes && Boolean(formik.errors.weeklyMinutes)}
              helperText={formik.touched.weeklyMinutes && (formik.errors.weeklyMinutes as string)}
              fullWidth
              endAdornment="none"
            />
            <TextfieldComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Minutes')}
              name="minutes"
              value={minutes}
              onChange={(e) => {
                const value = e.target.value;
                setMinutes(Number(value));
              }}
              fullWidth
              endAdornment="none"
            />
          </Box>
        </Box>

        <Box>
          <Typography variant="caption" sx={{ mb: '10px' }}>
            {polyglot.t('EditAttendanceSettingsDrawer.WorkingDays')}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Monday')}
              name="workingMonday"
              checked={formik.values.workingMonday}
              onChange={formik.handleChange}
            />
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Tuesday')}
              name="workingTuesday"
              checked={formik.values.workingTuesday}
              onChange={formik.handleChange}
            />
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Wednesday')}
              name="workingWednesday"
              checked={formik.values.workingWednesday}
              onChange={formik.handleChange}
            />
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Thursday')}
              name="workingThursday"
              checked={formik.values.workingThursday}
              onChange={formik.handleChange}
            />
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Friday')}
              name="workingFriday"
              checked={formik.values.workingFriday}
              onChange={formik.handleChange}
            />
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Saturday')}
              name="workingSaturday"
              checked={formik.values.workingSaturday}
              onChange={formik.handleChange}
            />
            <CheckboxComponent
              label={polyglot.t('EditAttendanceSettingsDrawer.Sunday')}
              name="workingSunday"
              checked={formik.values.workingSunday}
              onChange={formik.handleChange}
            />
          </Box>
        </Box>
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('EditAttendanceSettingsDrawer.Update')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
