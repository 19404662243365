import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@v2/components/divider.component';
import { formatMoney, formatPercentage } from '@v2/feature/payments/utils/money.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { Plan } from '@/models/subscription.model';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import {
  BillingCurrency,
  CompanySubscriptionDetails,
  DiscountTypeValues,
  SubscribedPlanDefinition,
} from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';
import { formatShortDate } from '@/v2/infrastructure/date/date-format.util';

interface TableRowProps {
  readonly column1: string;
  readonly listPrice?: string | undefined;
  readonly plan?: Plan;
  readonly planName?: string | undefined;
  readonly column2?: string | undefined;
  readonly column3: string;
  readonly showDivider?: boolean;
  readonly boldModule?: boolean;
  readonly boldPrice?: boolean;
  readonly nonTableRow?: boolean;
}

const TableRow = ({
  column1,
  plan,
  planName,
  listPrice,
  column3,
  column2 = '',
  showDivider = false,
  boldModule = false,
  boldPrice = false,
  nonTableRow = false,
}: TableRowProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: showDivider ? '50px' : '40px',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ width: '32%', ...(boldModule ? themeFonts.title4 : themeFonts.caption) }}>
          {column1}
          {plan && (
            <Typography
              component="span"
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                ml: spacing.m5,
              }}
            >
              {plan === Plan.FREE ? 'Free' : 'Pro'}
            </Typography>
          )}
          {planName && (
            <Typography
              component="span"
              sx={{
                ...themeFonts.caption,
                color: themeColors.Grey,
                ml: spacing.m5,
              }}
            >
              {planName}
            </Typography>
          )}
        </Typography>
        <Typography sx={{ width: '10%', ...(boldModule ? themeFonts.title4 : themeFonts.caption) }}>
          {listPrice}
        </Typography>
        {!nonTableRow && (
          <Typography sx={{ width: '40%', ...themeFonts.caption }}>{column2 ? column2 : <EmptyCell />}</Typography>
        )}
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
            width: '70px',
            ...(boldPrice ? themeFonts.title4 : themeFonts.caption),
          }}
        >
          {column3}
        </Typography>
      </Box>
      {showDivider && <Divider />}
    </>
  );
};

interface SubscriptionsTableProps {
  subscriptionDetailsForCompany: CompanySubscriptionDetails | null;
}

export const CompanySubscriptionsTable = ({ subscriptionDetailsForCompany }: SubscriptionsTableProps) => {
  const { polyglot } = usePolyglot();

  const totalCost = subscriptionDetailsForCompany?.billResult?.totalCost ?? 0;
  const noOfActiveUsers = subscriptionDetailsForCompany?.billResult?.seatMultiplier ?? 1;

  const subscriptionForCompany = subscriptionDetailsForCompany?.companySubscription;
  const currencyForCompany = subscriptionForCompany?.currency ?? BillingCurrency.GBP;

  const getDiscountAmountFromPlan = (
    module: SubscribedPlanDefinition,
    currency: BillingCurrency
  ): string | undefined => {
    const { discountType, discountValue, discountValid, discountEndDate } = module;
    const finalDiscountValue = discountValue ?? 0;
    if (!discountValid || !finalDiscountValue) return undefined;
    const discountRatio =
      discountType === DiscountTypeValues.FIXED
        ? formatMoney({ locale: 'en-US', amount: finalDiscountValue, currency })
        : discountType === DiscountTypeValues.PERCENTAGE
        ? formatPercentage(finalDiscountValue, 0)
        : '';

    const discountExpiryDate =
      discountValid && discountEndDate ? formatShortDate(new Date(discountEndDate)) : undefined;

    return discountExpiryDate ? `${discountRatio} (expiring ${discountExpiryDate})` : discountRatio;
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '40px', alignItems: 'center' }}>
        <Typography sx={{ width: '32%', ...themeFonts.captionSmall, color: themeColors.Grey }}>
          {' '}
          {polyglot.t('SubscriptionsTable.subscription')}
        </Typography>
        <Typography sx={{ width: '10%', ...themeFonts.captionSmall, color: themeColors.Grey }}>
          {' '}
          {polyglot.t('SubscriptionsTable.listPrice')}
        </Typography>
        <Typography sx={{ width: '40%', ...themeFonts.captionSmall, color: themeColors.Grey }}>
          {polyglot.t('SubscriptionsTable.discount')}
        </Typography>
        <Typography sx={{ width: '70px', ...themeFonts.captionSmall, color: themeColors.Grey }}>
          {polyglot.t('SubscriptionsTable.pricePerSeat')}
        </Typography>
      </Box>
      <Divider />
      {subscriptionForCompany?.subscribedPlans?.length && subscriptionForCompany?.subscribedPlans?.length > 0 ? (
        subscriptionForCompany?.subscribedPlans.map((eachModule) => {
          const finalModuleCost = eachModule.discountValid
            ? eachModule?.pricePerSeat && (eachModule?.pricePerSeat[currencyForCompany] as number)
            : (eachModule.cost[currencyForCompany] as number);
          return (
            <TableRow
              key={eachModule.planDefinitionId}
              listPrice={formatMoney({
                locale: 'en-US',
                amount: eachModule.cost[currencyForCompany] as number,
                currency: currencyForCompany,
              })}
              column1={eachModule.planName}
              column2={getDiscountAmountFromPlan(eachModule, currencyForCompany)}
              column3={formatMoney({
                locale: 'en-US',
                amount: finalModuleCost ?? 0,
                currency: currencyForCompany,
              })}
              showDivider
            />
          );
        })
      ) : (
        <Typography variant="h6">No modules subscribed to</Typography>
      )}
      <TableRow
        column1={polyglot.t('SubscriptionsTable.subscriptionFeePerUser')}
        column3={formatMoney({ locale: 'en-US', amount: totalCost / noOfActiveUsers, currency: currencyForCompany })}
        boldPrice
        nonTableRow
      />
      <TableRow column1={polyglot.t('SubscriptionsTable.seats')} column3={`${noOfActiveUsers}`} boldPrice nonTableRow />
      <TableRow column1={'Billing currency'} column3={`${currencyForCompany}`} boldPrice nonTableRow />
      <TableRow
        column1={polyglot.t('SubscriptionsTable.totalSubscription')}
        column3={formatMoney({ locale: 'en-US', amount: totalCost, currency: currencyForCompany })}
        boldModule
        boldPrice
        nonTableRow
      />
    </Box>
  );
};
