import { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { LOGIN_ROUTE } from '@/lib/routes';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { MFAType, MFA_CODE_LENGTH } from '@/v2/feature/auth/auth.dto';
import { performLogin } from '@/v2/feature/auth/auth.util';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { MFACodeEntry } from '@/v2/feature/auth/components/auth-mfa-code-entry.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { spacing } from '@/v2/styles/spacing.styles';

type MFAFormData = {
  mfaCode: string;
};
const validationSchema = yup.object({
  mfaCode: yup.string().required(),
});

export const AuthLogin2FAPage = (): JSX.Element => {
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const state = routerLocation.state as { username: string; password: string; mfaType: MFAType } | undefined;
  const [submittingCode, setSubmittingCode] = useState(false);
  const [errorReason, setErrorReason] = useState('');
  const { identifyUser } = useJune();

  const checkCodeAndSignIn = useCallback(
    (username: string, password: string, mfaCode: string) => {
      (async () => {
        setErrorReason('');
        setSubmittingCode(true);
        try {
          await performLogin(routerHistory, routerLocation, { username, password, mfaCode }, identifyUser);
        } catch (err) {
          if ((err as AxiosError).response?.status === 401) {
            setErrorReason('Invalid code. Please try again.');
          } else {
            setErrorReason('Authentication could not be completed');
          }
        } finally {
          setSubmittingCode(false);
        }
      })();
    },
    [identifyUser, routerHistory, routerLocation]
  );

  const formik = useFormik<MFAFormData>({
    initialValues: {
      mfaCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: MFAFormData) => {
      if (!state) return;
      checkCodeAndSignIn(state.username, state.password, values.mfaCode);
    },
  });

  if (!state) {
    // the user probably navigated here manually - send them back to the login page
    routerHistory.replace(LOGIN_ROUTE);
    return <></>;
  }

  return (
    <AuthLayout
      title="Enter code"
      description={`We've sent a ${MFA_CODE_LENGTH}-digit code to your work email`}
      submit={formik.handleSubmit}
    >
      <FormikProvider value={formik}>
        <MFACodeEntry
          disabled={submittingCode}
          error={!!errorReason}
          helperText={errorReason}
          onCodeComplete={(mfaCode) => formik.setValues({ mfaCode })}
          sx={{ mt: spacing.m30 }}
        />
        <LoaderButton
          name="Confirm"
          fullWidth
          loading={submittingCode}
          style={{ marginTop: '40px' }}
          sizeVariant="large"
          colorVariant="primary"
        />
      </FormikProvider>
    </AuthLayout>
  );
};
