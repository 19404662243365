import React, { useState } from 'react';

import { Button, Popover } from '@mui/material';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { DashboardAPI } from '@/v2/feature/dashboard/dashboard.api';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const GifSelector = ({
  type,
  setSelectedGIF,
}: {
  type: string;
  setSelectedGIF: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const [gifs, setGifs] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSearch = async () => {
    const results = await DashboardAPI.getGIFS(type);
    setGifs(results);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        sx={{ ...secondaryCTABtn }}
        startIcon={<Plus {...iconSize} />}
        onClick={(event) => {
          handleClick(event);
          handleSearch();
        }}
      >
        Add GIF
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            boxShadow: '0px 2px 20px 0px #0D0D0E1A',
            border: '0px solid #e6eaed',
            width: '25%',
          },
        }}
      >
        <div style={{ padding: spacing.p5, maxHeight: '200px', overflowY: 'auto' }}>
          <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: spacing.g5 }}>
            {gifs.map((gif: any, idx: number) => (
              <div>
                <img
                  key={idx}
                  src={gif}
                  alt="GIF"
                  width="100px"
                  height="100px"
                  onClick={() => {
                    setSelectedGIF(gif);
                  }}
                  style={{ borderRadius: radius.br10 }}
                />
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};
