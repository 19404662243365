export enum DRAWER_MODES {
  edit = 'edit',
  add = 'add',
  view = 'view',
}

export interface DowngradeViability {
  disableDowngrade: boolean;
  disabledDescription: string;
}
