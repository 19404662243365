import { useCallback, useContext, useEffect, useState } from 'react';

import { styled, Tab, Tabs, TabsProps, Typography } from '@mui/material';
import { PageConfigTab } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { ScopesControl, ScopesInspector } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { checkScopes } from '@/lib/scopes';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

// TODO do not use Mui tab
export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  height: 40,
  minHeight: 40,
  backgroundColor: themeColors.white,
  '& .MuiTabs-flexContainer': {
    height: 40,
  },
  '& .MuiTabs-indicator': {
    display: 'inherit',
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.DarkGrey,
    width: 'inherit',
    height: '3px',
  },
  '& .MuiTabs-scroller': { overflow: 'visible !important', height: 40 },
}));

export const StyledTab = styled(Tab)<TabsProps>(() => ({
  textTransform: 'inherit',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  height: 40,
  padding: '8px 1px',
  marginRight: '18px',
  minWidth: 'fit-content',
  minHeight: 40,
  borderRadius: 6,
  display: 'flex',
  '& > div': {
    marginBottom: '20px',
  },
  '&.Mui-selected': {
    ...themeFonts.title4,
    color: themeColors.DarkGrey,
    '&:hover': {
      ...themeFonts.title4,
      color: themeColors.DarkGrey,
    },
  },
  '&:hover': {
    ...themeFonts.caption,
    color: themeColors.Grey,
  },
}));

const RootStyle = styled('div')({
  boxShadow: 'none',
  position: 'fixed',
  top: 60,
  width: '100%',
  backgroundColor: themeColors.white,
  zIndex: 1,
});

export const SecondaryHeaderMenu = ({ tabs }: { readonly tabs: readonly PageConfigTab[] }): JSX.Element => {
  const routerHistory = useHistory();

  const getActiveTab = useCallback((currentPath: string, tabs: readonly PageConfigTab[]): string => {
    const exactMatchedTab = tabs.find((tab) => matchPath(currentPath, { path: tab.value, exact: true }));
    const matchedTab = tabs.find((tab) => matchPath(currentPath, { path: tab.value, exact: false }));
    return exactMatchedTab ? exactMatchedTab.value : matchedTab?.value ?? tabs[0]?.value;
  }, []);

  const { hasScopes } = useScopes();

  const location = useLocation();
  const [globalState] = useContext(GlobalContext);

  const [currentTab, setCurrentTab] = useState<string>(getActiveTab(location.pathname, tabs));
  useEffect(() => {
    setCurrentTab(getActiveTab(location.pathname, tabs));
  }, [getActiveTab, location, tabs]);

  return (
    <RootStyle sx={{ ml: '20px' }}>
      <StyledTabs
        value={currentTab}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={(_, value) => {
          setCurrentTab(value);
          routerHistory.push(value);
        }}
      >
        {tabs
          .filter(
            (tab) =>
              !tab.isHidden &&
              (!tab.scopes || !tab.scopes || tab.context
                ? checkScopes(globalState.user, tab.scopes, tab.context)
                : hasScopes(tab.scopes, tab.context))
          )
          .map(({ label, value, scopes, alerts = 0, context, onClick }) =>
            globalState.inspectorMode ? (
              <ScopesInspector key={value} scopes={scopes ?? []}>
                <StyledTab disableRipple label={label} value={value} onClick={onClick} />
              </ScopesInspector>
            ) : (
              <StyledTab
                key={value}
                disableRipple
                onClick={onClick}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      textTransform: 'inherit',
                    }}
                  >
                    {label}
                    <ScopesControl scopes={['user', 'user:manager', 'user:all']} context={context}>
                      {alerts > 0 && (
                        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, minWidth: '15px' }}>
                          {alerts}
                        </Typography>
                      )}
                    </ScopesControl>
                  </div>
                }
                value={value}
              />
            )
          )}
      </StyledTabs>
    </RootStyle>
  );
};
