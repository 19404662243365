import { Box, Link } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { spacing } from '@/v2/styles/spacing.styles';

export const TeamtailorApiKeyInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        a) Log in to{' '}
        <Link target="_blank" href="https://app.teamtailor.com/login">
          Teamtailor
        </Link>
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        b) Click your account name in the top right, and from the dropdown menu, click Settings.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        c) From the left sidebar menu, scroll down and click the API Keys menu option, under the Integrations section.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        d) Click the New API Key button to generate a new 'Admin' API scope Key with 'Read' and 'Write' permissions
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        e) Copy the newly generated API key or an existing one from the table of API keys, and paste it here.
      </Typography>
    </Box>
  );
};
