import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { KeyedMutator } from 'swr';

import useMessage from '@/hooks/notification.hook';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewAPI } from '@/v2/feature/growth/reviews/api-client/review.api';
import { EntryState } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { ReviewResult } from '@/v2/feature/growth/reviews/interfaces/review-result.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ResultsOverviewShareResultModal = ({
  isOpen,
  setIsOpen,
  onClose,
  results,
  refreshResults,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  results: ReviewResult | null | undefined;
  refreshResults: KeyedMutator<ReviewResult> | undefined;
}) => {
  if (!results) return null;
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ResultsOverviewShareResultContent results={results} refreshResults={refreshResults} onClose={onClose} />
    </DrawerModal>
  );
};

const ResultsOverviewShareResultContent = ({
  results,
  refreshResults,
  onClose,
}: {
  results: ReviewResult;
  refreshResults: KeyedMutator<ReviewResult> | undefined;
  onClose: () => void;
}) => {
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const handleShareResult = useCallback(async () => {
    try {
      setLoading(true);
      await ReviewAPI.upsertResultSharebility(results?.reviewId, results.cycleId, { isResultShared: true });
      refreshResults?.();
      onClose();
      showMessage('Results successfully shared with the participant', 'success');
    } catch (error) {
      showMessage(`Failed to save sharebility settings: ${JSON.stringify(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [results, onClose, refreshResults, showMessage]);

  const notSubmittedEntries = useMemo(
    () => results.reviewerScoreSummary.filter((r) => r.entryState !== EntryState.Submitted),
    [results]
  );

  return (
    <Box style={drawerContentSx}>
      <Typography variant="title2">Share results with {getCachedUserById(results.revieweeId)?.firstName}?</Typography>

      <Typography variant="caption">
        Confirming will grant them access to view this detailed feedback and scores.
      </Typography>

      {notSubmittedEntries.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.sm }}>
          <Typography variant="caption" sx={{ marginTop: spacing.s1 }}>
            All pending responses will be discarded:
          </Typography>
          {notSubmittedEntries.map((entry) => (
            <Typography variant="caption">• {getCachedUserById(entry.reviewerId)?.displayName}</Typography>
          ))}
        </Box>
      )}

      <Typography variant="caption">
        If you need to review or edit any comments before sharing, click 'Cancel' and make the necessary changes first.{' '}
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={onClose} fullWidth>
          Cancel
        </ButtonComponent>
        <LoaderButton
          sizeVariant="medium"
          onClick={() => handleShareResult()}
          colorVariant="primary"
          name={polyglot.t('General.save')}
          loading={loading}
          fullWidth
        />
      </Box>
    </Box>
  );
};
