import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CompanyConfigAPI } from '@v2/feature/company-config/company-config.api';
import { HelperCompanyRouter } from '@v2/feature/super-admin/features/helper-dashboard/helper-company.router';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { UserAPI } from '@v2/feature/user/user.api';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SUPER_ADMIN_USERS } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TooltipPopper } from '@/v2/components/theme-components/notification-tooltip-popper.component';

export const SUPER_ADMIN_HELPER_ROUTE = '/backoffice/helper-dashboard';
export const SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE = '/backoffice/helper-dashboard/companies/:companyId';

export const SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE = '/backoffice/helper-dashboard/companies/:companyId/payruns';
export const SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE =
  '/backoffice/helper-dashboard/companies/:companyId/payruns/:payrunId';

export const SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE = '/backoffice/helper-dashboard/companies/:companyId/devices';

export const SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE =
  '/backoffice/helper-dashboard/companies/:companyId/next-year-carry-over';

export const SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE =
  '/backoffice/helper-dashboard/companies/:companyId/account-statements';

export const SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE = '/backoffice/helper-dashboard/companies/:companyId/records';

export const SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE = '/backoffice/helper-dashboard/companies/:companyId/time';

export const HelperRouter = (): JSX.Element => {
  const [searchInput, setSearchInput] = useState('');

  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<readonly SuperAdminCompanyInfo[]>([]);
  const [users, setUsers] = useState<readonly UserDetailsSuperAdminDto[]>([]);

  const fetchCompanies = useCallback(async () => {
    setLoading(true);
    try {
      const { companies } = await CompanyAPI.getAllCompaniesAsSuperAdmin({ shouldLimitResult: 'false' });
      setCompanies(companies);

      const { users } = await UserAPI.getAllUsersAsSuperAdmin({ shouldLimitResult: 'false' });
      setUsers(users);
    } catch (error) {
      showMessage('Could not load the companies', 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const filteredCompanies = useMemo(() => {
    return companies.filter((company) => {
      return (
        company.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        String(company.companyId).includes(searchInput)
      );
    });
  }, [companies, searchInput]);

  const companiesWithTestModeEnabled = useMemo(() => {
    return companies.filter((c) => c.companyConfigBackoffice && c.companyConfigBackoffice[0]?.disableEmails);
  }, [companies]);

  const handleRowClick = useCallback(
    ({ original }: Row<SuperAdminCompanyInfo>) => {
      routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId: original.companyId }));
    },
    [routerHistory]
  );

  const columns = useMemo<ColumnDef<SuperAdminCompanyInfo, SuperAdminCompanyInfo>[]>(
    () => [
      {
        header: () => 'Company ID',
        id: 'companyId',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{original.companyId}</Typography>,
      },
      {
        header: () => 'Company name',
        id: 'name',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => <Typography sx={themeFonts.caption}>{original.name}</Typography>,
      },
      {
        header: () => 'Has support',
        id: 'hasSupport',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{original.hasSupport ? 'Yes' : 'No'}</Typography>
        ),
      },
      {
        header: () => 'Priority support',
        id: 'prioritySupport',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{original.prioritySupport ? 'Yes' : 'No'}</Typography>
        ),
      },
      {
        header: () => 'Company entities',
        id: 'entities',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) =>
          original.entities && original.entities.length > 0 ? (
            <Box>
              {original.entities.map((entity) => (
                <Typography key={entity.id} sx={themeFonts.caption}>
                  {entity.legalName ?? 'N/A'} | {entity.nationalId ?? 'N/A'}
                </Typography>
              ))}
            </Box>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => 'Is active',
        id: 'isActive',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) =>
          original.isActive ? (
            <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          ) : (
            <Rejected {...iconSize} style={{ fill: themeColors.Red }} />
          ),
      },
      {
        header: () => 'Created at',
        id: 'createdAt',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => (
          <Typography sx={themeFonts.caption}>{new Date(original.createdAt).toLocaleDateString()}</Typography>
        ),
      },
      {
        header: () => 'Actions',
        id: 'actions',
        maxSize: 110,
        minSize: 110,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const hasEmailsDisabled =
            original.companyConfigBackoffice && original.companyConfigBackoffice[0]?.disableEmails;
          return (
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ButtonComponent
                fullWidth
                onClick={async () => {
                  try {
                    setLoading(true);
                    await CompanyConfigAPI.disableOrEnableTestModeAsSuperAdmin(original.companyId);
                    await fetchCompanies();
                  } catch (error) {
                    showMessage(`Could not enable/disable company emails. ${nestErrorMessage(error)}`, 'error');
                  } finally {
                    setLoading(false);
                  }
                }}
                sizeVariant="small"
                colorVariant={hasEmailsDisabled ? 'primary' : 'secondary'}
              >
                {hasEmailsDisabled ? (
                  <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                    <Mistake {...iconSize} />
                    <Typography sx={themeFonts.title4}>Disable test mode</Typography>
                  </Box>
                ) : (
                  <Typography sx={themeFonts.caption}>Enable test mode</Typography>
                )}
              </ButtonComponent>
            </Box>
          );
        },
      },
    ],
    [showMessage, fetchCompanies]
  );

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE}>
        <HelperCompanyRouter loading={loading} users={users} companies={companies} />
      </Route>

      <Route path={SUPER_ADMIN_HELPER_ROUTE}>
        <Box>
          <TopHeader
            title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Helper page</Typography>}
            showBack
            backPath={SUPER_ADMIN_USERS}
          />
          <ContentWrapper loading={loading}>
            <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
              <TableSearch
                query={searchInput}
                handleChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                placeholder="Search"
                style={{ minWidth: '200px' }}
              />
              {companiesWithTestModeEnabled.length > 0 && (
                <TooltipPopper
                  position="bottom"
                  title={
                    <div>
                      <p>The following companies have test mode enabled:</p>
                      <ul>
                        {companiesWithTestModeEnabled.map((i) => (
                          <li>
                            {i.companyId} - {i.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                    <Mistake width={25} height={25} />
                    <Typography sx={themeFonts.caption}>{`${companiesWithTestModeEnabled.length} compan${
                      companiesWithTestModeEnabled.length === 1 ? 'y' : 'ies'
                    } ha${companiesWithTestModeEnabled.length === 1 ? 's' : 've'} test mode enabled`}</Typography>
                  </Box>
                </TooltipPopper>
              )}
            </Box>

            <Box sx={{ width: '100%', mt: spacing.mt20 }}>
              <BasicTable<SuperAdminCompanyInfo>
                rowData={filteredCompanies}
                columnData={columns}
                loading={loading}
                rowClick={handleRowClick}
                hidePagination
              />
            </Box>
          </ContentWrapper>
        </Box>
      </Route>
    </Switch>
  );
};
