import { CreateContractorInvoiceDto } from '@/v2/feature/payments/payments.dto';
import axios from 'axios';
import { ContractorInvoice, ContractorInvoiceStatus } from '@v2/feature/payments/payments.interface';
import { Alert } from '@v2/infrastructure/alert/alert.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';

export class ContractorInvoiceAPI {
  static async getAlerts(): Promise<Alert<ContractorInvoice>> {
    return (await axios.get('/apiv2/contractor/invoice/alerts')).data;
  }

  static async createInvoice(payload: CreateContractorInvoiceDto): Promise<ContractorInvoice> {
    return (await axios.post('/apiv2/contractor/invoice', payload)).data;
  }

  static async updateInvoice(invoiceId: string, payload: CreateContractorInvoiceDto): Promise<ContractorInvoice> {
    return (await axios.put(`/apiv2/contractor/invoice/${invoiceId}`, payload)).data;
  }

  static async bulkApprove(invoiceIds: string[]): Promise<number> {
    return (await axios.patch('/apiv2/contractor/invoice/bulk-approve', invoiceIds)).data;
  }

  static async getSpecificUserInvoices(userId: number): Promise<ContractorInvoice[]> {
    return (await axios.get(`/apiv2/contractor/invoice/users/${userId}`)).data;
  }

  static async getTeamInvoices(): Promise<ContractorInvoice[]> {
    return (await axios.get('/apiv2/contractor/invoice/team')).data;
  }

  static async getCompanyInvoices(): Promise<ContractorInvoice[]> {
    return (await axios.get('/apiv2/contractor/invoice')).data;
  }

  static async updateInvoiceApproval(
    invoiceId: string,
    userId: number,
    status: ContractorInvoiceStatus,
    notes?: string
  ): Promise<ContractorInvoice> {
    return (await axios.patch(`/apiv2/contractor/invoice/${invoiceId}/users/${userId}/approval`, { status, notes }))
      .data;
  }

  static async downloadInvoice(payload: { invoiceId: string }): Promise<any> {
    return (
      await axios.get(`/apiv2/contractor/invoice/download/${payload.invoiceId}`, {
        responseType: 'blob',
      })
    ).data;
  }

  static async payInvoice(invoiceId: string): Promise<void> {
    await axios.post(`/apiv2/contractor/invoice/${invoiceId}/pay`);
  }

  static async simulatePaymentResponse(invoiceId: string): Promise<void> {
    await axios.post(`/apiv2/contractor/invoice/${invoiceId}/simulate-payment-response`);
  }

  static async syncInvoiceWithExternalProvider(invoiceId: string): Promise<ContractorInvoice> {
    return (await axios.post(`/apiv2/contractor/invoice/${invoiceId}/sync-with-accounting-provider`)).data;
  }

  static async voidInvoice(invoiceId: string): Promise<void> {
    await axios.post(`/apiv2/contractor/invoice/${invoiceId}/void`);
  }

  static async deleteInvoice(invoiceId: string): Promise<void> {
    await axios.delete(`/apiv2/contractor/invoice/${invoiceId}`);
  }

  static async getUserInvoiceForPaymentId(userId: number, paymentId: number): Promise<ContractorInvoice> {
    return (await axios.get(`/apiv2/contractor/invoice/users/${userId}/payments/${paymentId}`)).data;
  }

  static async getApprovalListForNewInvoice(userId: number, typeId: number): Promise<number[]> {
    return (await axios.get(`/apiv2/contractor/invoice/approver-list-for-new-invoice/${userId}/${typeId}`)).data;
  }

  static async withdrawFromAccount(amount: number): Promise<void> {
    await axios.post(`/apiv2/contractor/invoice/withdraw`, { amount });
  }
}

export class ContractorInvoiceEndpoints {
  static getCompanyInvoices(): Endpoint<ContractorInvoice[]> {
    return {
      url: '/apiv2/contractor/invoice',
    };
  }

  static getCompanyInvoiceMembers(): Endpoint<Partial<CachedUser[]>> {
    return {
      url: '/apiv2/contractor/invoice/members',
    };
  }

  static getSpecificUserInvoices(userId: number): Endpoint<ContractorInvoice[]> {
    return {
      url: `/apiv2/contractor/invoice/users/${userId}`,
    };
  }

  static getUserInvoiceById(userId: number, invoiceId: string): Endpoint<ContractorInvoice> {
    return {
      url: `/apiv2/contractor/invoice/${invoiceId}/users/${userId}`,
    };
  }
}
