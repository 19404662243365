import { ColumnsFilterOption } from '@v2/components/table/columns-drawer.component';
import { FiltersOption } from '@v2/components/table/filters-drawer.component';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';

export class FiltersEndpoints {
  static getUsersDirectoryFiltersConfig(): Endpoint<{
    columnOptions: ColumnsFilterOption[];
    filterOptions: FiltersOption;
  }> {
    return {
      url: '/apiv2/filters/people-directory',
    };
  }

  static getCalendarFiltersOptions(): Endpoint<{
    peopleFilters: { [domain: string]: { value: number | string; label: string }[] };
    eventsFilters: { [domain: string]: { value: number | string; label: string }[] };
  }> {
    return {
      url: `/apiv2/filters/calendar`,
    };
  }

  static getTimeFiltersConfig(
    view: 'company' | 'team' | 'user',
    userId: number | undefined
  ): Endpoint<{
    timeFilters: { [domain: string]: { value: number | string; label: string }[] };
  }> {
    const url = `/apiv2/filters/time/${view}` + (userId ? `?userId=${userId}` : '');
    return { url };
  }

  static getPeopleFiltersOfReports(): Endpoint<FiltersOption> {
    return {
      url: '/apiv2/filters/reports/people',
    };
  }

  static geTimeFiltersOfReports(): Endpoint<{
    peopleFilters: FiltersOption;
    timeFilters: FiltersOption;
  }> {
    return {
      url: '/apiv2/filters/reports/time',
    };
  }

  static getReportsSalaryFilters(): Endpoint<{
    filterOptions: FiltersOption;
  }> {
    return {
      url: '/apiv2/filters/reports/salary',
    };
  }

  static getSurveyFilterOptions(): Endpoint<{
    surveyFilters: { [domain: string]: { value: number | string; label: string }[] };
  }> {
    return {
      url: `/apiv2/filters/growth/surveys`,
    };
  }
}
