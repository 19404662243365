import React from 'react';

import { Box } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleAPI } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface ISCGeneralRollingStateModal {
  surveyCycle: SurveyCycle;
  refresh: () => Promise<void>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}
export const SCGeneralRollingStateModal = ({
  surveyCycle,
  refresh,
  isOpen,
  setIsOpen,
  onClose,
}: ISCGeneralRollingStateModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SCGeneralRollingStateContent surveyCycle={surveyCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

interface ISCGeneralRollingStateContent {
  surveyCycle: SurveyCycle;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}
export const SCGeneralRollingStateContent = ({ surveyCycle, onClose, refresh }: ISCGeneralRollingStateContent) => {
  const [showMessage] = useMessage();
  const handleStateOfRollingReview = async () => {
    try {
      if (!surveyCycle) {
        showMessage('Cycle does not exist', 'error');
        return;
      }
      await SurveyCycleAPI.updateStateOfRollingSurvey(surveyCycle.id);
      showMessage('Successfully updated the cycle', 'success');
      refresh?.();
      onClose();
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };
  if (!surveyCycle) return <>Not found</>;

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">
        {surveyCycle.state === CycleState.Ongoing ? 'Pause cycle' : 'Reactivate cycle'}
      </Typography>
      <Typography variant="caption" color="Grey">
        Pausing a rolling cycle will retain all collected answers, new invites will not be sent out. Reactivate this
        cycle when you are ready to start inviting participants again.
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent onClick={onClose} sizeVariant="medium" colorVariant="secondary" fullWidth>
          Cancel
        </ButtonComponent>
        <ButtonComponent onClick={handleStateOfRollingReview} sizeVariant="medium" colorVariant="primary" fullWidth>
          {surveyCycle.state === CycleState.Ongoing ? 'Pause' : 'Reactivate'}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
