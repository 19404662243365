export type KeyObject<T = string> = {
  [key: string]: T;
};

export type GrowthScale = {
  companyId: number;
  id: string;
  type: string;
  variation: string;
  points: KeyObject;
  value: KeyObject<number>;
  sentiments: KeyObject;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
};

export type GrowthScaleCreate = Pick<GrowthScale, 'type' | 'points' | 'value' | 'sentiments'>;
export type GrowthScaleUpdate = Pick<GrowthScale, 'id' | 'type' | 'points' | 'value' | 'sentiments'>;

export type GrowthScalePoint = {
  points: string;
  value: number | string;
  sentiments: string;
};

export enum Sentiments {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive',
}

export const SentimentsOptions = [
  { label: Sentiments.Negative, value: Sentiments.Negative },
  { label: Sentiments.Neutral, value: Sentiments.Neutral },
  { label: Sentiments.Positive, value: Sentiments.Positive },
];
