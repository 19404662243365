import { useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { KeyedMutator } from 'swr';

import { PaymentSettingsEditApprovalRulesDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-edit-approval-rules.component';
import { PaymentSettingsPageSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import {
  PaymentCategoryEnum,
  PaymentTypeSettings,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';
import { ruleForApprovals } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { detailSx, detailTermSx, detailValueSx } from '@/v2/styles/settings.styles';

interface ApprovalsPageProps {
  readonly paymentTypeSetting: PaymentTypeSettings;
  readonly refreshPaymentTypeSetting: KeyedMutator<PaymentTypeSettings> | undefined;
}

export const PaymentSettingsApprovalsPage = ({ paymentTypeSetting, refreshPaymentTypeSetting }: ApprovalsPageProps) => {
  const { data: approvalRules, mutate: refreshRules } = useApiClient(
    ApprovalRuleEndpoints.getApprovalRules(paymentTypeSetting?.type === PaymentCategoryEnum.EXPENSE),
    { suspense: false }
  );

  const refreshApprovalRules = useCallback(async () => {
    if (refreshRules) await refreshRules();
  }, [refreshRules]);

  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <PaymentSettingsPageSection
      title={polyglot.t('PaymentSettingsPage.approvalsTitle')}
      onEdit={() => {
        setIsDrawerOpen(true);
      }}
    >
      {paymentTypeSetting?.approvalRuleId && (
        <Box sx={detailSx} key={'approval'}>
          <Typography sx={{ ...detailTermSx, whiteSpace: 'nowrap' }}>
            {polyglot.t('PaymentSettingsPage.approvalsTitle')}
          </Typography>
          <Typography sx={detailValueSx}>
            {ruleForApprovals(approvalRules, paymentTypeSetting.approvalRuleId)}
          </Typography>
        </Box>
      )}

      {isDrawerOpen && paymentTypeSetting?.approvalRuleId && (
        <PaymentSettingsEditApprovalRulesDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          paymentTypeSetting={paymentTypeSetting}
          approvalRules={approvalRules ?? []}
          refresh={refreshPaymentTypeSetting}
          refreshApprovalRules={refreshApprovalRules}
        />
      )}
    </PaymentSettingsPageSection>
  );
};
