import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';

import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';

export enum PayScheduleEnum {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export enum SalaryBasisEnum {
  Annual = 'Annual',
  Monthly = 'Monthly',
  Daily = 'Daily',
  Hourly = 'Hourly',
}

export interface UserCompensationDto {
  id?: number;
  effectiveDate: string;
  paySchedule?: PayScheduleEnum;
  salaryBasis: SalaryBasisEnum | null;
  rate: number | null;
  units?: number;
  salaryPerPaySchedule?: number | undefined;
  annualSalary?: number | undefined;
  salary?: number | null;
  currency: CurrencyShort | null;
  prorateSalaryByFte?: boolean;
  proratedRate?: number;
  nonProratedRate?: number;
  userFteFromImport?: number;
  userId?: number;
  customUpdates: UserCustomDataDto[];
}
