import React from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  GrowthScale,
  GrowthScalePoint,
  SentimentsOptions,
} from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface GrowthScalePointFormModalProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly afterClose: () => void;
  readonly growthScalePoint: string | undefined;
  readonly growthScale: Pick<GrowthScale, 'type' | 'points' | 'value' | 'sentiments'> | undefined;
  readonly addPoint: (values: GrowthScalePoint) => void;
}

interface GrowthScaleFormContentProps {
  readonly onClose: () => void;
  readonly growthScalePoint: string | undefined;
  readonly growthScale: Pick<GrowthScale, 'type' | 'points' | 'value' | 'sentiments'> | undefined;
  readonly addPoint: (values: GrowthScalePoint) => void;
}

export const GrowthScalePointFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  growthScalePoint,
  growthScale,
  addPoint,
}: GrowthScalePointFormModalProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose}>
      <GrowthScaleFormContent
        onClose={onClose}
        growthScalePoint={growthScalePoint}
        growthScale={growthScale}
        addPoint={addPoint}
      />
    </DrawerModal>
  );
};

const GrowthScaleFormContent = ({ onClose, growthScalePoint, growthScale, addPoint }: GrowthScaleFormContentProps) => {
  const { polyglot } = usePolyglot();
  const modalFormik = useFormik({
    initialValues:
      growthScale && growthScalePoint && Object.keys(growthScale.points).length > 0
        ? {
            points: growthScale?.points[growthScalePoint],
            value: growthScale?.value[growthScalePoint],
            sentiments: growthScale?.sentiments[growthScalePoint],
          }
        : {
            points: '',
            value: '',
            sentiments: '',
          },
    validationSchema: yup.object({
      points: yup.string().required('Point is required'),
      value: yup.number().required('Value is required'),
      sentiments: yup.string().required('Sentiment is required'),
    }),
    onSubmit: async (values) => {
      addPoint(values);
      onClose();
    },
  });

  if (!growthScalePoint) return null;

  return (
    <FormikProvider value={modalFormik}>
      <Form style={drawerContentSx} onSubmit={modalFormik.handleSubmit}>
        <Typography variant="title2">Scale point</Typography>
        <TextfieldComponent
          name="points"
          label="Label"
          value={modalFormik.values.points}
          onChange={modalFormik.handleChange}
          error={modalFormik.touched.points && !!modalFormik.errors.points}
          helperText={modalFormik.touched.points && modalFormik.errors.points}
        />
        <TextfieldComponent
          name="value"
          label="Value"
          type="number"
          value={modalFormik.values.value}
          onChange={modalFormik.handleChange}
          error={modalFormik.touched.value && !!modalFormik.errors.value}
          helperText={modalFormik.touched.value && modalFormik.errors.value}
        />
        <SelectComponent
          name="sentiments"
          label="Sentiment"
          options={SentimentsOptions}
          value={modalFormik.values.sentiments}
          onChange={modalFormik.handleChange}
          error={modalFormik.touched.sentiments && !!modalFormik.errors.sentiments}
          helperText={modalFormik.touched.sentiments && modalFormik.errors.sentiments}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            disabled={!modalFormik.isValid || modalFormik.isSubmitting}
            name={polyglot.t('General.save')}
            loading={modalFormik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
