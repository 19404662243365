import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_BENEFITS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly customBenefit: CustomBenefitDto;
}

export const DeleteCustomBenefitDrawer = ({ isOpen, setIsOpen, customBenefit }: DrawerProps) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <DeleteCustomBenefitDrawerContent customBenefit={customBenefit} setIsOpen={setIsOpen} />
    </Suspense>
  </DrawerModal>
);

interface DrawerContentProps {
  readonly customBenefit: CustomBenefitDto;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteCustomBenefitDrawerContent = ({ customBenefit, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();

  const removeBenefit = useCallback(async () => {
    try {
      setIsDelete(true);
      await CustomBenefitAPI.deleteBenefitById(customBenefit.id);
      showMessage(polyglot.t('BenefitModule.benefitDeleted'), 'success');
      history.push(SETTINGS_BENEFITS_ROUTE);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    } finally {
      setIsDelete(false);
    }
  }, [polyglot, history, showMessage, customBenefit]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{customBenefit.name}</Typography>

      <Typography variant="caption">{polyglot.t('BenefitModule.deleteBenefitEntirely')}</Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.delete')}
          sizeVariant="medium"
          colorVariant="danger"
          loading={isDelete}
          onClick={removeBenefit}
          fullWidth
        />
      </Box>
    </Box>
  );
};
