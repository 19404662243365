import React, { useCallback, useMemo, useState } from 'react';

import { FormControlLabel, IconButton, RadioGroup, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoGrey.svg';
import { StudentLoanPlan, StudentLoanValues } from '@/lib/payroll';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { StudentLoanInfo } from '@/v2/feature/payroll/features/payroll-uk/components/student-loan-info.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export type StudentLoanConfig = {
  studentLoanPlan: StudentLoanPlan;
  postgradLoan: boolean;
};

type StudentLoansOptionsProps = {
  value?: StudentLoanConfig | null;
  setStudentLoans: (value: StudentLoanConfig | null) => void;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
};

export const StudentLoansOptions = ({
  value,
  setStudentLoans,
  disabled,
  helperText,
  error,
}: StudentLoansOptionsProps) => {
  const { polyglot } = usePolyglot();
  const [openStudentLoanInfo, setOpenStudentLoanInfo] = useState(false);
  const setHasStudentLoans = useCallback(
    (hasLoans: boolean) => {
      if (hasLoans) {
        setStudentLoans({
          studentLoanPlan: value?.studentLoanPlan ?? 'None',
          postgradLoan: value?.postgradLoan ?? false,
        });
      } else {
        setStudentLoans(null);
      }
    },
    [setStudentLoans, value?.postgradLoan, value?.studentLoanPlan]
  );

  const hasStudentLoan = useMemo(() => {
    if (value === undefined) {
      return;
    }
    return !!value;
  }, [value]);

  return (
    <>
      <Stack>
        <StudentLoanInfo open={openStudentLoanInfo} setOpen={(isOpen) => setOpenStudentLoanInfo(isOpen)} />
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingByUser.doYouMakeStudentLoanPayments')}
        </Typography>
        <RadioGroup
          value={hasStudentLoan ?? ''}
          onChange={(e) => setHasStudentLoans(e.target.value === 'true')}
          sx={{ flexFlow: 'row' }}
        >
          <FormControlLabel
            labelPlacement="end"
            value={'true'}
            control={<StyledRadio />}
            label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('General.yes')}</Typography>}
            disabled={disabled}
            sx={{ width: 200 }}
          />
          <FormControlLabel
            labelPlacement="end"
            value={'false'}
            control={<StyledRadio />}
            disabled={disabled}
            label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('General.no')}</Typography>}
          />
        </RadioGroup>
        <Stack sx={{ overflow: 'hidden' }}>
          <Stack sx={{ mt: hasStudentLoan ? spacing.m30 : '-50%', gap: spacing.g20, transition: 'margin-top 0.4s' }}>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              {polyglot.t('OnboardingByUser.chooseTheCorrectStudentLoans')}
              <IconButton
                aria-label="info"
                onClick={() => setOpenStudentLoanInfo(true)}
                disabled={disabled}
                size="small"
              >
                <InfoIcon />
              </IconButton>
            </Typography>

            <SelectComponent
              name="studentLoan"
              label={polyglot.t('OnboardingByUser.studentLoan')}
              options={StudentLoanValues}
              value={value?.studentLoanPlan ?? 'None'}
              onChange={(e) =>
                setStudentLoans({
                  studentLoanPlan: e.target.value,
                  postgradLoan: value?.postgradLoan ?? false,
                })
              }
              disabled={disabled || !hasStudentLoan}
            />
            <CheckboxComponent
              name="postgradLoan"
              label={polyglot.t('OnboardingByUser.postgradLoan')}
              checked={value?.postgradLoan ?? false}
              onChange={(_, checked) =>
                setStudentLoans({
                  studentLoanPlan: value?.studentLoanPlan ?? 'None',
                  postgradLoan: checked,
                })
              }
              disabled={disabled || !hasStudentLoan}
            />
            <div style={{ display: 'relative' }}>
              <Typography
                sx={{ position: 'absolute', ...themeFonts.caption, color: error ? themeColors.red : undefined }}
              >
                {helperText}
              </Typography>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
