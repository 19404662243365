import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, MenuItem, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import {
  HMRCSubmissionState,
  HMRCSubmissionStates,
  PayrunProcessStepState,
  PayrunProcessStepStates,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayrollExternalApi } from '@v2/feature/payroll/payroll-external.api';
import {
  ExternalFPSLateReason,
  ExternalFPSLateReasons,
  ExternalGovTalkSubmissionStatuses,
} from '@v2/feature/payroll/payroll-external.interface';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { generatePath } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from '@/lib/routes';
import { isHMRCSetup } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayrunProcessingItem } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component';
import { ExternalFpsDto } from '@/v2/feature/payroll/payroll-external.dto';
import { PayrollLocalApi } from '@/v2/feature/payroll/payroll-local.api';
import { primaryTableSmallBtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';
import { sleep } from '@/v2/util/time.util';

interface HMRCSubmissionProps {
  readonly payRun: PayRunDto;
  readonly hmrcSubmissionState: HMRCSubmissionState;
  readonly setHmrcSubmissionState: React.Dispatch<React.SetStateAction<HMRCSubmissionState>>;
  sx?: SxProps<Theme>;
}

const iconSize20px = { height: '20px', width: '20px' };

const lateReasonObjects = [
  { label: ExternalFPSLateReasons.NoneGiven, value: ExternalFPSLateReasons.NoneGiven },
  { label: ExternalFPSLateReasons.NotionalExpat, value: ExternalFPSLateReasons.NotionalExpat },
  { label: ExternalFPSLateReasons.NotionalErs, value: ExternalFPSLateReasons.NotionalErs },
  { label: ExternalFPSLateReasons.NotionalOther, value: ExternalFPSLateReasons.NotionalOther },
  { label: ExternalFPSLateReasons.Class1, value: ExternalFPSLateReasons.Class1 },
  { label: ExternalFPSLateReasons.MicroEmployer, value: ExternalFPSLateReasons.MicroEmployer },
  { label: ExternalFPSLateReasons.NoRequirement, value: ExternalFPSLateReasons.NoRequirement },
  { label: ExternalFPSLateReasons.ReasonableExcuse, value: ExternalFPSLateReasons.ReasonableExcuse },
  { label: ExternalFPSLateReasons.Correction, value: ExternalFPSLateReasons.Correction },
] as const;

export const HMRCSubmission = ({
  payRun,
  hmrcSubmissionState,
  setHmrcSubmissionState,
  sx,
}: HMRCSubmissionProps): JSX.Element => {
  const [accordionState, setAccordionState] = useState<PayrunProcessStepState>(PayrunProcessStepStates.pending);
  const [lateReason, setLateReason] = useState<ExternalFPSLateReason>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isFPSLate, setIsFPSLate] = useState(false);
  const [missingHMRCDetails, setMissingHMRCDetails] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState<string>('');
  const cancelRequest = useRef(false);

  const [showMessage] = useMessage();

  const downloadFpsReport = async () => {
    try {
      setIsDownloading(true);
      const req = await PayrollExternalApi.getExternalFPSReportByPeriod(
        payRun.payrollId,
        payRun.taxYear,
        payRun.payPeriod,
        payRun.period,
        'text/csv'
      );
      const file = new Blob([req.content ?? req], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement('a');
      link.download = `payrun-${payRun.taxYear}-Month-${payRun.period}-FPS-report.csv`;
      link.href = fileURL;
      link.click();
      showMessage('FPS Reports downloaded successfully.', 'success');
    } catch (error) {
      showMessage(`Failed to download FPS Reports. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDownloading(false);
    }
  };

  const checkIfFPSIsLate = (fps: ExternalFpsDto): boolean => {
    if (!fps.paymentDate) {
      return true;
    }
    return todaysDateShortISOString() > fps.paymentDate;
  };

  const submitFPS = useCallback(
    async (lateReason?: ExternalFPSLateReason, fps?: ExternalFpsDto, submitAttempt = 0) => {
      if (cancelRequest.current) {
        return;
      }
      setAccordionState(PayrunProcessStepStates.pending);
      setMissingHMRCDetails(false);
      setIsFPSLate(false);
      const [externalFPS, localFPS] = await Promise.all([
        fps ?? PayrollExternalApi.fetchExternalFPSStatus(payRun.id),
        PayrollLocalApi.fetchFPS(payRun.id),
      ]);

      if (localFPS.state === HMRCSubmissionStates.markedAsSent) {
        setHmrcSubmissionState(HMRCSubmissionStates.markedAsSent);
        setAccordionState(PayrunProcessStepStates.success);
        return;
      }

      if (!isHMRCSetup({ kind: 'fps', ...externalFPS.employerReferences })) {
        setSubmissionMessage(
          'Missing HMRC credentials. This payrun can be left as partially complete while you are waiting for your account details.'
        );
        setMissingHMRCDetails(true);
        setAccordionState(PayrunProcessStepStates.warning);
        return;
      }

      const currentStatus = externalFPS.govTalkSubmission?.status;
      if (currentStatus === ExternalGovTalkSubmissionStatuses.accepted) {
        // the FPS has been accepted by HMRC
        setSubmissionMessage(externalFPS.govTalkSubmission.message);
        setHmrcSubmissionState(HMRCSubmissionStates.submitted);
        setAccordionState(PayrunProcessStepStates.success);
        return;
      }

      if (currentStatus === ExternalGovTalkSubmissionStatuses.submitted) {
        // the FPS is with HMRC and we are awaiting accept/reject status
        await sleep(5e3);
        submitFPS();
        return;
      }

      if (currentStatus === ExternalGovTalkSubmissionStatuses.errorResponse && submitAttempt > 0) {
        // the FPS submission failed
        setSubmissionMessage(externalFPS.govTalkSubmission.message);
        setAccordionState(PayrunProcessStepStates.failure);
        return;
      }

      if (checkIfFPSIsLate(externalFPS) && !lateReason) {
        setIsFPSLate(true);
        setAccordionState(PayrunProcessStepStates.warning);
        return; // don't submit until a reason is given
      }

      try {
        if (submitAttempt > 5) {
          throw new Error(`Submission failed.`);
        }
        const result = await PayrollExternalApi.submitFPS(payRun.id, lateReason);
        submitFPS(lateReason, result, submitAttempt + 1);
      } catch (error) {
        setAccordionState(PayrunProcessStepStates.failure);
        showMessage(nestErrorMessage(error), 'error');
      }
    },
    [payRun.id, setHmrcSubmissionState, showMessage]
  );

  useEffect(() => {
    submitFPS();
    return () => {
      cancelRequest.current = true;
    };
  }, [submitFPS]);

  if (isFPSLate) {
    return (
      <Stack
        sx={{
          gap: spacing.g15,
          borderTop: '1px solid',
          borderTopColor: themeColors.middleGrey,
          width: '600px',
          py: spacing.p25,
          ...sx,
        }}
      >
        <Box sx={{ display: 'flex', gap: spacing.g15 }}>
          <Waiting {...iconSize20px} style={{ fill: themeColors.GreyMiddle, flexShrink: 0 }} />
          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>HMRC: Late Filing Reason</Typography>
        </Box>
        <Typography sx={themeFonts.caption}>
          You're submitting the FPS for this payrun after the payment date. You should provide a reason for the late
          filing.
        </Typography>
        <TextField
          label="Late reason"
          select
          value={lateReason ?? ''}
          placeholder="None selected"
          size="small"
          sx={{ width: '300px' }}
          onChange={(event) => {
            setLateReason(event.target.value as ExternalFPSLateReason);
          }}
        >
          {lateReasonObjects.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </TextField>
        <Button
          sx={{ ...primaryTableSmallBtn, width: '100px' }}
          onClick={() => submitFPS(lateReason)}
          disabled={!lateReason}
        >
          Submit FPS
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <PayrunProcessingItem
        title="HMRC"
        key="hmrc"
        description={
          {
            pending: 'Processing Full Payment Submission...',
            failure: `An error occurred while submitting the FPS. ${submissionMessage} (Current status: ${hmrcSubmissionState})`,
            success: `Full Payment Submission accepted. ${submissionMessage}`,
            warning: submissionMessage,
          }[accordionState]
        }
        buttons={[
          {
            style: 'primary',
            label: 'Add HMRC Credentials',
            show: missingHMRCDetails,
            onClick: () =>
              window.open(
                generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId: payRun.payrollId }),
                '_blank'
              ),
          },
          {
            style: 'primary',
            label: 'Retry',
            show: !missingHMRCDetails && accordionState !== PayrunProcessStepStates.success,
            onClick: () => submitFPS(lateReason),
          },
          {
            style: 'secondary',
            label: 'Download',
            show: !missingHMRCDetails,
            loading: isDownloading,
            onClick: () => downloadFpsReport(),
          },
        ]}
        success={
          accordionState === PayrunProcessStepStates.pending
            ? undefined
            : accordionState === PayrunProcessStepStates.success
        }
        sx={sx}
      />
    </>
  );
};
