import { useCallback, useMemo, useState } from 'react';

import { Button, IconButton, IconButtonProps, Stack, SxProps, Theme } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ApproverGroup, PresetApprover, presetApproverToString } from './approver-select.interface';

import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SpecificUserModal } from '@/v2/components/user-select-type/components/specific-user-modal.component';
import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { styledChipSx } from '@/v2/styles/chip.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const DeleteButton = ({ sx, ...props }: IconButtonProps) => (
  <IconButton
    sx={
      {
        ...secondaryCTABtn,
        backgroundColor: '#ffffffc8',
        p: 0,
        height: '30px',
        width: '30px',
        ...sx,
      } as IconButtonProps['sx']
    }
    {...props}
  >
    <Trash {...iconSize} />
  </IconButton>
);

const EmptyApprover: ApproverGroup = {
  type: 'anyof',
  autoApprove: false,
  presets: [],
  userIds: [],
};

type ApprovalSelectProps = {
  hidePresets?: PresetApprover[];
  hideSpecific?: boolean;
  hideAutoApprove?: boolean;
  hideEveryone?: boolean;
  userModalTitle?: string;
  onChange?: (value: ApproverGroup) => void;
  value?: ApproverGroup;
  sx?: SxProps<Theme>;
};

export const ApproverSelectComponent = ({
  hideEveryone = true,
  hideAutoApprove,
  hidePresets,
  hideSpecific,
  userModalTitle = 'Select approvers',
  onChange,
  value,
  sx,
}: ApprovalSelectProps) => {
  const { polyglot } = usePolyglot();

  const [isSpecificOpen, setSpecificIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState<UserSelectFiltersOptions>(UserSelectFiltersOptions.None);
  const presets = useMemo(() => new Set(value?.presets), [value]);

  const updateAutoApprove = useCallback(
    (autoApprove: boolean) => {
      onChange?.({ type: 'anyof', autoApprove, presets: [], userIds: [] });
    },
    [onChange]
  );

  const updatePresetApprover = useCallback(
    (approver: PresetApprover, action: 'add' | 'delete') => {
      presets[action](approver);
      onChange?.({
        ...EmptyApprover,
        ...value,
        autoApprove: false,
        presets: [...presets],
      });
    },
    [value, onChange, presets]
  );

  const updateSpecificApprovers = useCallback(
    (userIds: number[]) => {
      onChange?.({
        ...EmptyApprover,
        ...value,
        autoApprove: false,
        userIds,
      });
    },
    [value, onChange]
  );

  const hasApprover = value?.autoApprove || !!value?.presets.length || !!value?.userIds.length;

  return (
    <>
      <Stack sx={sx}>
        {hasApprover && (
          <Stack sx={{ gap: spacing.g10, mb: spacing.mb20 }}>
            {value?.autoApprove && (
              <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
                <Typography variant="title4">{polyglot.t('ApproverSelectComponent.autoApprove')}</Typography>
                <DeleteButton sx={{ ml: 'auto' }} onClick={() => updateAutoApprove(false)} />
              </Stack>
            )}
            {value?.presets.map((preset) => (
              <Stack key={preset} sx={{ flexFlow: 'row', alignItems: 'center' }}>
                <Typography variant="title4">{presetApproverToString(preset, polyglot)}</Typography>
                <DeleteButton sx={{ ml: 'auto' }} onClick={() => updatePresetApprover(preset, 'delete')} />
              </Stack>
            ))}
            {!!value?.userIds.length && (
              <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
                <MultiUserAvatar userIds={value.userIds} showLimit={5} />
                <Button sx={{ ...styledChipSx, ml: 'auto' }} onClick={() => setSpecificIsOpen(true)}>
                  {polyglot.t('ApproverSelectComponent.selectSpecific')}
                </Button>
                <DeleteButton onClick={() => updateSpecificApprovers([])} />
              </Stack>
            )}
          </Stack>
        )}
        <Stack sx={{ flexFlow: 'row', gap: spacing.g5, flexWrap: 'wrap' }}>
          {!value?.presets.includes('everyone') && !hidePresets?.includes('everyone') && !hideEveryone && (
            <Button sx={styledChipSx} onClick={() => updatePresetApprover('everyone', 'add')}>
              {polyglot.t('ApproverSelectComponent.Everyone')}
            </Button>
          )}
          {!value?.presets.includes('manager:1') && !hidePresets?.includes('manager:1') && (
            <Button sx={styledChipSx} onClick={() => updatePresetApprover('manager:1', 'add')}>
              {polyglot.t('ApproverSelectComponent.Manager')}
            </Button>
          )}
          {!value?.presets.includes('admin') && !hidePresets?.includes('admin') && (
            <Button sx={styledChipSx} onClick={() => updatePresetApprover('admin', 'add')}>
              {polyglot.t('ApproverSelectComponent.Admin')}
            </Button>
          )}
          {!value?.userIds.length && !hideSpecific && (
            <Button sx={styledChipSx} onClick={() => setSpecificIsOpen(true)}>
              {polyglot.t('ApproverSelectComponent.selectSpecific')}
            </Button>
          )}
          {!value?.autoApprove && !hideAutoApprove && (
            <Button sx={styledChipSx} onClick={() => updateAutoApprove(true)}>
              {polyglot.t('ApproverSelectComponent.autoApprove')}
            </Button>
          )}
        </Stack>
      </Stack>
      <SpecificUserModal
        isSpecificOpen={isSpecificOpen}
        setSpecificIsOpen={setSpecificIsOpen}
        label={userModalTitle}
        value={value?.userIds ?? []}
        onChange={updateSpecificApprovers}
        filterValue={filterValue}
        setLastValidFilterValue={setFilterValue}
      />
    </>
  );
};
