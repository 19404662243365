import React, { useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { PermissionGroupAPI } from '@v2/feature/permission-group/permission-group.api';
import {
  DefaultManagerPermissionLevel,
  PermissionGroupOptions,
} from '@v2/feature/permission-group/permission-group.interface';
import { getPermissionGroupsReportsLevelOptionsForManagerGroup } from '@v2/feature/permission-group/permission-group.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { PermissionGroupDto } from '@/v2/feature/permission-group/permission-group.dto';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly permissionGroup: PermissionGroupDto;
  readonly refreshPermissionGroup: () => Promise<void>;
}

export const ManagerPermissionGroupOptionsEditDrawer = ({
  isOpen,
  setIsOpen,
  permissionGroup,
  refreshPermissionGroup,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ManagerPermissionGroupOptionsEditDrawerContent
        permissionGroup={permissionGroup}
        refreshPermissionGroup={refreshPermissionGroup}
        setIsOpen={setIsOpen}
      />
    </DrawerModal>
  );
};

interface ContentDrawerProps {
  readonly permissionGroup: PermissionGroupDto;
  readonly refreshPermissionGroup: () => Promise<void>;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManagerPermissionGroupOptionsEditDrawerContent = ({
  permissionGroup,
  refreshPermissionGroup,
  setIsOpen,
}: ContentDrawerProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const isManagerPermissionGroup = permissionGroup.name === 'Manager';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();

  const formik = useFormik<PermissionGroupOptions>({
    initialValues: { reportsLevels: permissionGroup.options.reportsLevels ?? DefaultManagerPermissionLevel },
    validationSchema: Yup.object({
      reportsLevels: Yup.number()
        .typeError(polyglot.t('validation.selectValid'))
        .integer()
        .required(polyglot.t('validation.requiredField')),
    }),
    onSubmit: async (values): Promise<void> => {
      try {
        setIsSubmitting(true);
        const update: PermissionGroupOptions = isManagerPermissionGroup ? { reportsLevels: values.reportsLevels } : {};

        await PermissionGroupAPI.updatePermissionGroupOptions(permissionGroup.id, update);
        await refreshPermissionGroup();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setIsSubmitting(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('ManagerPermissionGroup.options')}</Typography>

        <Typography variant="caption">{polyglot.t('ManagerPermissionGroup.howManyLevelsOfReports')}</Typography>
        <SelectComponent
          name="reportsLevels"
          label={polyglot.t('ManagerPermissionGroup.levelsOfReports')}
          options={getPermissionGroupsReportsLevelOptionsForManagerGroup(polyglot)}
          value={formik.values.reportsLevels}
          onChange={formik.handleChange}
          error={Boolean(!!formik.errors.reportsLevels && formik.touched.reportsLevels)}
          helperText={formik.touched.reportsLevels && (formik.errors.reportsLevels as string)}
          disabled={!isManagerPermissionGroup}
        />

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            loading={isSubmitting}
            disabled={!isManagerPermissionGroup}
            fullWidth
          >
            {polyglot.t('General.save')}
          </ButtonComponent>
        </Box>
      </Form>
    </FormikProvider>
  );
};
