import { useCallback, useEffect, useState } from 'react';

import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { Redirect, Route, Switch } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_ROUTE,
} from '@/lib/routes';
import { OnboardingIntro } from '@/v2/feature/onboarding/onboarding-intro.page';
import { OnboardingSettings } from '@/v2/feature/onboarding/onboarding-settings.page';
import { OnboardingTemplateEdit } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';

export const OnboardingSettingsRouter = () => {
  const [templates, setTemplates] = useState<OnboardingTemplate[]>();
  const [showMessage] = useMessage();

  const refresh = useCallback(async () => {
    try {
      const [templates] = await Promise.all([OnboardingAPI.getOnboardingTemplates()]);
      setTemplates(templates);
    } catch (error) {
      showMessage(`Onboarding failed to load. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (!templates) {
    return <OnboardingIntro loading />;
  }
  return (
    <Switch>
      <Route path={SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE} component={OnboardingTemplateEdit} />

      <Route path={SETTINGS_ONBOARDING_FLOWS_ROUTE}>
        <OnboardingSettings templates={templates} />
      </Route>

      <Redirect from={SETTINGS_ONBOARDING_ROUTE} to={SETTINGS_ONBOARDING_FLOWS_ROUTE} exact />
    </Switch>
  );
};
