import {
  CreateUserEventDto,
  UpdateUserEventDto,
  UserEventDto,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
import axios from 'axios';

export class EventsAPI {
  static async getAllUserEvents(userId: number): Promise<EntriesAndEffectiveRecord<UserEventDto>> {
    return (await axios.get(`/apiv2/users/${userId}/events`)).data;
  }

  static async createUserEventEntry(userId: number, newUserEventEntry: CreateUserEventDto): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/events`, newUserEventEntry);
  }

  static async additionalStepsForRehire(userId: number): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/events/rehire`);
  }

  static async updateUserEventEntry(userId: number, existingUserEventEntry: UpdateUserEventDto): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/events/${existingUserEventEntry.id}`, existingUserEventEntry);
  }
}
