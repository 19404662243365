import { CSSProperties } from 'react';

import { flow } from 'fp-ts/lib/function';
import * as RR from 'fp-ts/lib/ReadonlyRecord';
import * as Yup from 'yup';

import type { SystemStyleObject } from '@mui/system';
import type { ObjectShape } from 'yup/lib/object';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const definitionListSx: SystemStyleObject = { display: 'flex', flexDirection: 'column', margin: 0, padding: 0 };
export const definitionSx: SystemStyleObject = {
  display: 'flex',
  marginBottom: spacing.m10,
  width: '100%',
  gap: spacing.g10,
};
export const definitionTermSx: SystemStyleObject = {
  maxWidth: { xs: '120px', sm: '120px', md: '190px', lg: '190px' },
  width: '100%',
  ...themeFonts.caption,
};
export const definitionValueSx: SystemStyleObject = {
  maxWidth: { xs: '200px', sm: '350px', md: '400px', lg: '400px' },
  width: '100%',
  ...themeFonts.title4,
};

export const cardSx: SystemStyleObject = {
  maxWidth: { xs: '350px', sm: '350px', md: '600px', lg: '600px' },
  ...spacing.pb40,
};
export const cardMaxWidth: SystemStyleObject = {
  maxWidth: { xs: '350px', sm: '350px', md: '600px', lg: '600px' },
};
export const tablecardSx: SystemStyleObject = { ...spacing.pb40 };
export const showTableSx: SystemStyleObject = { marginTop: spacing.m15 };
export const cardHeaderSx: SystemStyleObject = {
  alignItems: 'center',
  display: 'flex',
  ...spacing.pb20,
  justifyContent: 'space-between',
};

export const fieldSx: SystemStyleObject = {
  minHeight: 65,
};
export const conditionalSx: SystemStyleObject = { mb: spacing.m35 };
export const dateShortcutSx: SystemStyleObject = { minHeight: '110px', mb: spacing.m10 };
export const nonLabelfieldSx: SystemStyleObject = { minHeight: 65 };

export const drawerContentSx: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  height: '100%',
};

export const buttonBoxSx: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  gap: spacing.m5,
  width: '100%',
  mt: spacing.m25,
};

export const buttonSettingSx: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: spacing.m10,
};
export const titleSx: SystemStyleObject = { ...themeFonts.title2, mb: '20px', color: themeColors.DarkGrey };
export const editDeleteHeaderSx: SystemStyleObject = { mb: '20px' };

export const calculateSkeletonHeight = flow(
  <T extends ObjectShape>(xs: Yup.ObjectSchema<T>) => xs.fields,
  RR.keys,
  (x) => `${4 + x.length * 2}rem`
);

export const hazardSize = { width: 10, height: 10 } as const;
