import { useEffect } from 'react';

import { Box, styled } from '@mui/material';
import { Color } from '@tiptap/extension-color';
import { ListItem } from '@tiptap/extension-list-item';
import { TextStyle, TextStyleOptions } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import { ReactComponent as BlockQuote } from '@/images/fields/BlockQuote.svg';
import { ReactComponent as Bold } from '@/images/fields/Bold.svg';
import { ReactComponent as Bullets } from '@/images/fields/Bullets.svg';
import { ReactComponent as CodeBlock } from '@/images/fields/CodeBlock.svg';
import { ReactComponent as Italic } from '@/images/fields/Italic.svg';
import { ReactComponent as Numbers } from '@/images/fields/Numbers.svg';
import { ReactComponent as UnderlineSVG } from '@/images/fields/Underline.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import '@/v2/scss/components/rich-text-field.scss';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const StyledEditorContent = styled(EditorContent)(() => ({
  background: themeColors.Background,
  padding: '0px',
  margin: '0px',
  '& .ProseMirror-trailingBreak': {
    padding: '0px',
    margin: '0px',
  },
  '& p': {
    marginBlockStart: '0px !important',
    marginBlockEnd: '0px !important',
  },
}));
const MenuBar = ({ editor }: { editor: Editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', gap: spacing.g3, flexWrap: 'wrap' }}>
      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        style={{}}
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <Bold {...iconSize} />
      </IconButton>
      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <Italic {...iconSize} />
      </IconButton>
      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? 'is-active' : ''}
      >
        <UnderlineSVG {...iconSize} />
      </IconButton>

      <Divider />
      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <Bullets {...iconSize} fill={themeColors.TableHover} />
      </IconButton>
      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Numbers {...iconSize} fill={themeColors.DarkGrey} />
      </IconButton>
      <Divider />

      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        type="button"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <BlockQuote {...iconSize} fill={themeColors.DarkGrey} />
      </IconButton>
      <Divider />

      <IconButton
        sizeVariant="small"
        colorVariant="secondary"
        type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        <CodeBlock {...iconSize} stroke={themeColors.DarkGrey} />
      </IconButton>
    </Box>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] } as Partial<TextStyleOptions>),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Underline,
];

const generatePlaceholderRows = (value: string | null, minRows = 2) => {
  const row = '<p></p>';
  if (!value) {
    return new Array(minRows).fill(row).join('');
  }

  return value + new Array(1).fill(row).join('');
};

export const RichTextField = ({
  value,
  onChange,
  label,
  error,
  helperText,
  disabled = false,
}: {
  value: string | null;
  onChange?: any;
  label?: string | JSX.Element;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
}) => {
  const initialContent = generatePlaceholderRows(value);

  const editor = useEditor({
    extensions,
    content: initialContent,
    editable: !disabled,
    onUpdate: ({ editor }) => {
      const updatedValue = editor.getHTML() === '<p></p>' ? null : editor.getHTML();
      if (onChange) {
        onChange(updatedValue);
      }
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(generatePlaceholderRows(value), false);
    }
  }, [value, editor]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
      {label && <Typography variant="captionSmall">{label}</Typography>}
      <Box
        sx={{
          backgroundColor: themeColors.Background,

          borderRadius: radius.br10,
          padding: spacing.p10,
          ...themeFonts.title4,
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {editor && <MenuBar editor={editor} />}
        <StyledEditorContent editor={editor} />
      </Box>

      {helperText && (
        <Typography variant="caption" sx={{ color: error ? themeColors.darkRed : themeColors.DarkGrey }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

const Divider = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '0.5px', backgroundColor: themeColors.DarkGrey, height: '16px', verticalAlign: 'middle' }} />
    </div>
  );
};
