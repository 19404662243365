import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { FieldCard } from '@v2/components/field-card.component';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { BenefitPaymentsDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/benefit-payments-drawer.component';
import { LoanBorrowedDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/loan-borrowed-drawer.component';
import { RepayLoanPaymentDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/repay-loan-payment-drawer.component';
import { UserBenefitRequestDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/components/user-benefit-request-drawer.component';
import { CustomBenefitEndpoints } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { UserCustomBenefitRequestType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { calculatePaidAmount, isLoanBenefit } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { UserBenefitOverviewTitle } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/user-benefit-overview-title.component';
import { UserCustomBenefitRequestDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit-request-drawer.component';
import {
  UserCustomBenefitDto,
  UserCustomBenefitRequestDto,
  UserCustomBenefitRequestStatus,
} from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserCustomBenefitListTableComponent } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-list-table.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { LocalDate } from '@v2/util/local-date';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE, USER_BENEFITS_ROUTE } from '@/lib/routes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';

interface RecurringBenefitOverviewProps {
  readonly userCustomBenefit: UserCustomBenefitDto;
  readonly userId: number;
  readonly refresh: () => Promise<void>;
  readonly loading: boolean;
}

export const LoanBenefitOverview = ({ userCustomBenefit, userId, refresh, loading }: RecurringBenefitOverviewProps) => {
  const {
    data: userBenefitsRequests,
    isLoading: loadingRequests,
    mutate: refreshReq,
  } = useApiClient(
    CustomBenefitEndpoints.getUserCustomBenefitRequestsByBenefitId(
      userId,
      userCustomBenefit.customBenefitId,
      userCustomBenefit.id
    ),
    { suspense: false }
  );

  const [state] = useContext(GlobalContext);
  const { polyglot } = usePolyglot();
  const [isBenefitRequestOpen, setIsBenefitRequestOpen] = useState<boolean>(false);

  const [isPaymentsOpen, setIsPaymentsOpen] = useState<boolean>(false);
  const [isBorrowedOpen, setIsBorrowedOpen] = useState<boolean>(false);
  const [isRequestOpen, setIsRequestOpen] = useState<boolean>(false);
  const [selectedBenefitRequest, setSelectedBenefitRequest] = useState<UserCustomBenefitRequestDto | null>(null);
  const [isRepayPaymentOpen, setIsRepayPaymentOpen] = useState<boolean>(false);

  const { hasScopes } = useScopes();

  const location = useLocation();

  const repaid = useMemo(() => {
    if (!userCustomBenefit.userCustomBenefitPayments) return 0;

    return calculatePaidAmount(
      userCustomBenefit.userCustomBenefitPayments,
      userCustomBenefit.customBenefit?.type,
      userCustomBenefit.customBenefit?.allowanceType,
      new LocalDate().toDateString()
    );
  }, [userCustomBenefit]);

  const remaining = (userCustomBenefit.openingBalance ?? 0) - repaid;

  const pendingRequests = userBenefitsRequests?.filter((req) => req.status === UserCustomBenefitRequestStatus.Pending);
  const approvedRequests = userBenefitsRequests?.filter(
    (req) => req.status === UserCustomBenefitRequestStatus.Approved
  );

  const refreshRequests = useCallback(async () => {
    if (refreshReq) await refreshReq();
  }, [refreshReq]);

  return (
    <RootStyle>
      <TopHeader
        title={<UserBenefitOverviewTitle userCustomBenefit={userCustomBenefit} />}
        showBack
        backPath={
          matchPath(location.pathname, USER_BENEFITS_ROUTE)
            ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId })
            : BENEFITS_ME_ROUTE
        }
        actions={
          <Box>
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={() => {
                // if (userCustomBenefit && isLoanBenefit(userCustomBenefit.customBenefit?.type))
                setIsBenefitRequestOpen(true);
              }}
            >
              {polyglot.t('BenefitModule.newRequest')}
            </ButtonComponent>
          </Box>
        }
        showAction={
          hasScopes(['insurance'], { userId }) &&
          userCustomBenefit &&
          isLoanBenefit(userCustomBenefit.customBenefit?.type) &&
          userCustomBenefit.openingBalance === null &&
          loadingRequests === false &&
          (!pendingRequests || pendingRequests.length === 0) &&
          (!approvedRequests || approvedRequests.length === 0)
        }
      />
      <ContentWrapper loading={loading} sx={{ mt: '20px', mb: '10px' }}>
        {userCustomBenefit.openingBalance !== null && (
          <Box sx={{ width: '600px', display: 'flex', gap: '5px', alignItems: 'center', mb: '30px' }}>
            <FieldCard
              name={polyglot.t('BenefitModule.requested')}
              value={formatMoney({
                amount: userCustomBenefit.openingBalance,
                asDecimal: true,
              })}
              action={() => setIsBorrowedOpen(true)}
              color="secondary"
            />
            <Typography variant="caption">-</Typography>

            <FieldCard
              name={polyglot.t('BenefitModule.repaid')}
              value={formatMoney({ amount: repaid, asDecimal: true })}
              color="warning"
              action={() => setIsPaymentsOpen(true)}
            />

            <Typography variant="caption">=</Typography>

            <FieldCard
              name={polyglot.t('BenefitModule.remaining')}
              value={formatMoney({
                amount: remaining,
                asDecimal: true,
              })}
              action={undefined}
              color="success"
            />
          </Box>
        )}

        {pendingRequests && (
          <Box sx={{ width: '100%', mb: '20px' }}>
            {pendingRequests.map((request) => (
              <FieldCard
                key={request.id}
                name={polyglot.t('BenefitModule.pendingRequest')}
                value={formatMoney({ amount: request.amount, asDecimal: true })}
                color="warning"
                action={() => {
                  setSelectedBenefitRequest(request);
                  setIsRequestOpen(true);
                }}
                sx={{ width: '180px' }}
              />
            ))}
          </Box>
        )}

        <UserCustomBenefitListTableComponent
          benefitId={userCustomBenefit.customBenefitId}
          userBenefit={userCustomBenefit}
          userId={userId}
        />

        {state.showHiddenFeatures && (
          <Box sx={{ mt: '10px' }}>
            <ButtonComponent
              sizeVariant="small"
              colorVariant="secondary"
              onClick={async () => {
                setIsRepayPaymentOpen(true);
              }}
            >
              {polyglot.t('BenefitModule.addRepayment')}
            </ButtonComponent>
          </Box>
        )}

        <RepayLoanPaymentDrawer
          isOpen={isRepayPaymentOpen}
          setIsOpen={setIsRepayPaymentOpen}
          userCustomBenefit={userCustomBenefit}
          refresh={refresh}
        />

        {userCustomBenefit.userCustomBenefitPayments && (
          <BenefitPaymentsDrawer
            isOpen={isPaymentsOpen}
            setIsOpen={setIsPaymentsOpen}
            userCustomBenefitPayments={userCustomBenefit.userCustomBenefitPayments}
            type="repaid"
            benefitType={userCustomBenefit.customBenefit?.type}
            allowanceType={userCustomBenefit.customBenefit?.allowanceType}
          />
        )}

        <LoanBorrowedDrawer isOpen={isBorrowedOpen} setIsOpen={setIsBorrowedOpen} userBenefit={userCustomBenefit} />
        <UserBenefitRequestDrawer
          isOpen={isRequestOpen}
          setIsOpen={setIsRequestOpen}
          userBenefitRequest={selectedBenefitRequest}
          onClose={() => {
            setSelectedBenefitRequest(null);
            setIsRequestOpen(false);
          }}
          refresh={async () => {
            await Promise.all([refreshRequests(), refresh()]);
          }}
        />

        <UserCustomBenefitRequestDrawer
          isOpen={isBenefitRequestOpen}
          setIsOpen={setIsBenefitRequestOpen}
          userBenefit={userCustomBenefit as UserCustomBenefitDto}
          onClose={() => {
            setIsBenefitRequestOpen(false);
          }}
          refresh={async () => {
            await Promise.all([refreshRequests(), refresh()]);
          }}
          type={UserCustomBenefitRequestType.LoanRequest}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
