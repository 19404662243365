import { Suspense, useCallback, useEffect, useState } from 'react';

import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto, DeviceTransitDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus } from '@v2/feature/device/device.interface';
import { DeviceOrderDetailsPage } from '@v2/feature/device/features/devices-company/pages/device-order-details.page';
import { DeviceStorePage } from '@v2/feature/device/features/devices-company/pages/device-store.page';
import { MyDeviceDetailsRouter } from '@v2/feature/device/features/my-devices/my-device-details.router';
import { ConfirmDeviceOrderDeliveryStepper } from '@v2/feature/device/features/my-devices/pages/device-activation/confirm-device-delivery-stepper.component';
import { DevicesActivationPage } from '@v2/feature/device/features/my-devices/pages/device-activation/devices-activation.page';
import { MyDevicesOverviewPage } from '@v2/feature/device/features/my-devices/pages/my-devices-overview.page';
import { generatePath, Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { useGlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  DEVICES_ME_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDER_DETAILS_ROUTE,
  DEVICES_ME_ORDERS_ACTIVATION_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_STORE_ROUTE,
} from '@/lib/routes';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';

export const DevicesMeRouter = () => {
  const [globalState] = useGlobalContext();
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [deviceTransits, setDeviceTransits] = useState<readonly DeviceTransitDto[]>([]);
  const [deviceOrders, setDeviceOrders] = useState<readonly DeviceOrderDto[]>([]);
  const [inShippingDevices, setInShippingDevices] = useState<readonly DeviceOrderDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const refreshDevicesAppData = useCallback(async () => {
    try {
      setLoading(true);

      const [deviceTransits, deviceOrders] = await Promise.all([
        DeviceAPI.getUserInTransitDevices(userId),
        DeviceAPI.getUserDeviceOrders(userId),
      ]);
      setDeviceTransits(deviceTransits);
      setDeviceOrders(deviceOrders.filter((deviceOrder) => deviceOrder.status !== DeviceOrderStatus.Delivered));
      setInShippingDevices(deviceOrders.filter((deviceOrder) => deviceOrder.status === DeviceOrderStatus.Shipping));
    } catch (error) {
      showMessage(`Could not get devices inventory stats. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, showMessage]);

  useEffect(() => {
    refreshDevicesAppData();
  }, [refreshDevicesAppData]);

  return (
    <Switch>
      <RouteScopesControl
        scopes={['devices', 'devices.enroll']}
        context={scopesContext}
        path={DEVICES_ME_OVERVIEW_DEVICE_ROUTE}
      >
        <MyDeviceDetailsRouter refreshDevicesAppData={refreshDevicesAppData} />
      </RouteScopesControl>

      <RouteScopesControl scopes={['devices']} context={scopesContext} path={DEVICES_ME_ORDER_ACTIVATION_ROUTE}>
        <ConfirmDeviceOrderDeliveryStepper />
      </RouteScopesControl>

      <RouteScopesControl scopes={['devices']} context={scopesContext} path={DEVICES_ME_ORDERS_ACTIVATION_ROUTE}>
        {inShippingDevices.length === 1 ? (
          <Redirect to={generatePath(DEVICES_ME_ORDER_ACTIVATION_ROUTE, { orderId: inShippingDevices[0].id })} />
        ) : (
          <DevicesActivationPage deviceOrders={inShippingDevices} loading={loading} />
        )}
      </RouteScopesControl>

      <RouteScopesControl scopes={['devices']} context={scopesContext} path={DEVICES_ME_ORDER_DETAILS_ROUTE}>
        <DeviceOrderDetailsPage loading={loading} setLoading={setLoading} refreshOrders={refreshDevicesAppData} />
      </RouteScopesControl>

      <RouteScopesControl
        scopes={['devices.store', 'devices:all']}
        context={scopesContext}
        path={DEVICES_ME_STORE_ROUTE}
      >
        <Suspense
          fallback={
            <SkeletonLoader
              variant="rectangular"
              width="90%"
              height="90vh"
              sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
            />
          }
        >
          <DeviceStorePage loading={loading} refresh={refreshDevicesAppData} reach="me" />
        </Suspense>
      </RouteScopesControl>

      <RouteScopesControl scopes={['devices']} context={scopesContext} path={DEVICES_ME_DIRECTORY_ROUTE}>
        <MyDevicesOverviewPage
          deviceOrders={deviceOrders}
          deviceTransits={deviceTransits}
          refreshDevicesData={refreshDevicesAppData}
        />
      </RouteScopesControl>

      <Redirect to={DEVICES_ME_DIRECTORY_ROUTE} />
    </Switch>
  );
};
