import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface DocumentConfirmBulkDeleteDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onActionConfirm: () => Promise<void>;
  readonly drawerTitle: string;
  readonly confirmationText: string;
  readonly documentCountForDeletion: number;
}

interface DocumentConfirmBulkDeleteDrawerContentProps {
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onActionConfirm: () => void;
  readonly drawerTitle: string;
  readonly confirmationText: string;
  readonly documentCountForDeletion: number;
}

export const DocumentConfirmBulkDeleteDrawer = ({
  isOpen,
  setIsOpen,
  onActionConfirm,
  drawerTitle,
  confirmationText,
  documentCountForDeletion,
}: DocumentConfirmBulkDeleteDrawerProps): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <DocumentConfirmBulkDeleteDrawerContent
        onActionConfirm={onActionConfirm}
        setIsOpen={setIsOpen}
        drawerTitle={drawerTitle}
        confirmationText={confirmationText}
        documentCountForDeletion={documentCountForDeletion}
      />
    </DrawerModal>
  );
};

export const DocumentConfirmBulkDeleteDrawerContent = ({
  onActionConfirm,
  setIsOpen,
  drawerTitle,
  confirmationText,
  documentCountForDeletion,
}: DocumentConfirmBulkDeleteDrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>('');

  const onActionConfirmClick = useCallback(async () => {
    setLoading(true);
    await onActionConfirm();
    setIsOpen(false);
    setLoading(false);
  }, [onActionConfirm, setIsOpen]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{drawerTitle}</Typography>
      <Typography variant="caption">{confirmationText}</Typography>

      <TextfieldComponent
        name="confirmTxt"
        label={`Please type "Confirm" in the field below to delete ${documentCountForDeletion} documents`}
        autoFocus
        autoComplete="off"
        value={confirmText}
        onChange={(newVal) => {
          if (!newVal) return;
          setConfirmText(newVal.currentTarget.value);
        }}
      />

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="secondary"
          onClick={() => setIsOpen(false)}
          disabled={loading}
          fullWidth
        >
          {polyglot.t('General.cancel')}
        </ButtonComponent>
        <LoaderButton
          name={polyglot.t('General.delete')}
          disabled={confirmText?.toLowerCase() !== 'confirm' || loading}
          loading={loading}
          onClick={onActionConfirmClick}
          type="button"
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
        />
      </Box>
    </Box>
  );
};
