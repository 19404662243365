import React, { Dispatch, SetStateAction, Suspense, useCallback, useMemo, useState } from 'react';

import { Box, SxProps } from '@mui/material';
import { BidirectionalButton } from '@v2/components/bidirectional-button.component';
import { Divider } from '@v2/components/divider.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI, AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto, UserAbsenceAllowancesDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBreakdown } from '@v2/feature/absence/absence.interface';
import { isHourlyPolicy, isUnlimitedPolicy } from '@v2/feature/absence/absence.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly refresh: (policyId: number | 'all') => Promise<void>;
  readonly currentCycle: readonly [string, string];
}

export const AllowanceAdjustmentDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  refresh,
  policyBreakdown,
  currentCycle,
}: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <AllowanceAdjustmentDrawerContent
          userId={userId}
          absencePolicy={absencePolicy}
          refreshAllowancePage={refresh}
          policyBreakdown={policyBreakdown}
          currentCycle={currentCycle}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly userId: number;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refreshAllowancePage: (policyId: number | 'all') => Promise<void>;
  readonly policyBreakdown: AbsenceBreakdown;
  readonly currentCycle: readonly [string, string];
}

const AllowanceAdjustmentDrawerContent = ({
  userId,
  absencePolicy,
  refreshAllowancePage,
  policyBreakdown,
  currentCycle,
}: DrawerContentProps) => {
  const { polyglot } = usePolyglot();
  const [effectiveYear, setEffectiveYear] = useState<number>(policyBreakdown.holidayYear);

  const { data: userAllowance, mutate: refreshAllowance } = useApiClient(
    AbsenceEndpoints.getUserAbsencePolicyAllowances(absencePolicy.id, userId, effectiveYear)
  );

  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(['absence:manager'], { userId });
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const isHourly = isHourlyPolicy(absencePolicy);

  const userAllowanceValue = userAllowance?.userAllowance ?? absencePolicy.allowance;
  const userTotalAllowance = policyBreakdown.userAllowance;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    await Promise.all([refreshAllowancePage(absencePolicy.id), refreshAllowance ? refreshAllowance() : undefined]);
  }, [absencePolicy.id, refreshAllowancePage, refreshAllowance]);

  const cycleStart = useMemo(() => {
    const start = new LocalDate(currentCycle[0]);
    start.getDate().setFullYear(effectiveYear);

    return start;
  }, [effectiveYear, currentCycle]);

  const cycleEnd = useMemo(() => {
    const end = new LocalDate(currentCycle[1]);
    const isSameYearAsStart = currentCycle[0].slice(0, 4) === currentCycle[1].slice(0, 4);
    end.getDate().setFullYear(isSameYearAsStart ? effectiveYear : effectiveYear + 1);

    return end;
  }, [effectiveYear, currentCycle]);

  const tenureValue = policyBreakdown.tenure?.tenureValueInUnits;

  return absencePolicy.allowance !== null && userAllowance ? (
    <Box sx={drawerContentSx}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
        <Typography variant="title2">{polyglot.t('AllowanceDrawer.userAllowance')}</Typography>

        <BidirectionalButton
          navigateBack={() => setEffectiveYear((y) => y - 1)}
          navigateNext={() => setEffectiveYear((y) => y + 1)}
          buttonTitle={effectiveYear.toString()}
        />
      </Box>

      <PolicyLine label={polyglot.t('AllowanceDrawer.absenceCycleStart')} value={cycleStart.toLocaleDateString()} />
      <PolicyLine label={polyglot.t('AllowanceDrawer.absenceCycleEnd')} value={cycleEnd.toLocaleDateString()} />

      <Divider />
      {userTotalAllowance !== null ? (
        <PolicyLine
          label={polyglot.t('AllowanceDrawer.userAllowance')}
          value={
            isHourly
              ? polyglot.t('AllowanceDrawer.noOfHours', {
                  smart_count: userTotalAllowance,
                })
              : polyglot.t('AllowanceDrawer.noOfDays', {
                  smart_count: userTotalAllowance,
                })
          }
          boldLabel
        />
      ) : (
        <PolicyLine
          label={polyglot.t('AllowanceDrawer.userAllowance')}
          value={polyglot.t('AllowanceDrawer.unlimited')}
          boldLabel
        />
      )}

      {tenureValue > 0 && (
        <PolicyLine
          label={polyglot.t('AbsencePolicyAllowanceSection.tenureBonus')}
          value={
            isHourly
              ? polyglot.t('AllowanceDrawer.noOfHours', {
                  smart_count: tenureValue,
                })
              : polyglot.t('AllowanceDrawer.noOfDays', {
                  smart_count: tenureValue,
                })
          }
        />
      )}

      {userAllowanceValue !== null && !isEditMode && tenureValue !== 0 && (
        <PolicyLine
          label={polyglot.t('AllowanceDrawer.basicUserAllowance')}
          value={
            isHourly
              ? polyglot.t('AllowanceDrawer.noOfHours', {
                  smart_count: userAllowanceValue,
                })
              : polyglot.t('AllowanceDrawer.noOfDays', {
                  smart_count: userAllowanceValue,
                })
          }
        />
      )}

      {isAdminOrManager && !isEditMode && !isUnlimited && (
        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent onClick={() => setIsEditMode(true)} sizeVariant="medium" colorVariant="secondary" fullWidth>
            {polyglot.t('General.edit')}
          </ButtonComponent>
        </Box>
      )}

      {isEditMode && (
        <EditMode
          setIsEditMode={setIsEditMode}
          absencePolicy={absencePolicy}
          userAllowance={userAllowance}
          refresh={refresh}
        />
      )}
    </Box>
  ) : null;
};

const EditMode = ({
  absencePolicy,
  userAllowance,
  setIsEditMode,
  refresh,
}: {
  readonly absencePolicy: AbsencePolicyDto;
  readonly userAllowance: UserAbsenceAllowancesDto;
  readonly setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);

  const userAllowanceValue = userAllowance?.userAllowance ?? absencePolicy.allowance;

  const [showMessage] = useMessage();

  const formik = useFormik<{
    userAllowance: number;
    effectiveYear: number;
  }>({
    initialValues: {
      userAllowance: userAllowanceValue ?? 0,
      effectiveYear: userAllowance.effectiveYear,
    },
    validationSchema: Yup.object({
      userAllowance: Yup.number()
        .min(0, polyglot.t('AllowanceDrawer.errors.inputValidValue'))
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      effectiveYear: Yup.number()
        .min(2000, polyglot.t('ValidationMessages.validValue'))
        .integer(polyglot.t('ValidationMessages.validValue'))
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values: { userAllowance: number; effectiveYear: number }) => {
      setLoading(true);
      try {
        await AbsenceAPI.updateAbsencePolicyUserAllowances(userAllowance.policyId, userAllowance.userId, {
          effectiveYear: Number(values.effectiveYear),
          userAllowance: Number(values.userAllowance),
        });
        await refresh();
        setIsEditMode(false);
      } catch (error) {
        showMessage(
          polyglot.t('AllowanceDrawer.errors.couldNotUpdateAllowance', {
            nestError: nestErrorMessage(error),
          }),
          'error'
        );
      }
      setLoading(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <TextfieldComponent
          label={polyglot.t('AllowanceDrawer.basicUserAllowance')}
          name="userAllowance"
          value={formik.values.userAllowance}
          onChange={formik.handleChange}
          fullWidth
          error={formik.touched.userAllowance && !!formik.errors.userAllowance}
          helperText={(formik.touched.userAllowance && formik.errors.userAllowance) as string}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const PolicyLine = ({
  label,
  value,
  boldLabel = false,
  sx = {},
}: {
  label: string;
  value: string | number;
  boldLabel?: boolean;
  sx?: SxProps;
}) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', ...sx }}>
    <Typography variant={boldLabel ? 'title4' : 'caption'}>{label}</Typography>
    <Typography variant="title4">{value}</Typography>
  </Box>
);
