import React from 'react';

import { Box } from '@mui/material';
import { ChipComponentWithIcon } from '@v2/components/chip/chip-with-icon.component';
import { ChipComponent } from '@v2/components/chip/chip.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { iconSize } from '@v2/styles/table.styles';

import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { Typography } from '@/v2/components/typography/typography.component';

export const DeviceEncryptionPolicyTile = ({
  policyName,
  settingsNumber,
  status,
  onClick,
  filevaultKeyStatus,
}: {
  policyName: string;
  settingsNumber: number;
  status: 'Installed' | 'Missing';
  onClick: () => void;
  filevaultKeyStatus: boolean | null;
}) => {
  const { polyglot } = usePolyglot();

  return (
    <Box
      sx={{
        width: '600px',
        height: '108px',
        padding: '16px',
        gap: '32px',
        borderRadius: '8px',
        border: '1px solid #F1EEEA',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        opacity: 1,
        boxSizing: 'border-box',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
      onClick={onClick}
    >
      <Typography variant="title4">{policyName}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <ChipComponent
          name={`${settingsNumber} setting${settingsNumber > 1 ? 's' : ''}`}
          backgroundColor="white"
          textColor="DarkGrey"
          textVariant="caption"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChipComponentWithIcon
            name={
              status === 'Installed' ? polyglot.t('DevicePolicyTile.installed') : polyglot.t('DevicePolicyTile.missing')
            }
            backgroundColor="white"
            textColor="DarkGrey"
            icon={
              status === 'Installed' ? (
                <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
              ) : (
                <Mistake {...iconSize} />
              )
            }
            border="light"
            textVariant="caption"
          />
          {filevaultKeyStatus !== null && (
            <ChipComponentWithIcon
              name={
                filevaultKeyStatus
                  ? polyglot.t('DevicePolicyTile.storedKey')
                  : polyglot.t('DevicePolicyTile.missingKey')
              }
              backgroundColor="white"
              textColor="DarkGrey"
              icon={
                filevaultKeyStatus ? (
                  <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                ) : (
                  <Mistake {...iconSize} />
                )
              }
              border="light"
              textVariant="caption"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
