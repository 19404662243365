import { Fragment } from 'react';

import { Box, Button, Skeleton } from '@mui/material';
import { capitalize } from 'lodash';

import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { borders } from '@/v2/styles/borders.styles';
import { activeTabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const HelpCenterLoader = ({ domain }: { domain: string | undefined }) => {
  return (
    <Fragment>
      {domain && (
        <Button
          sx={{ ...activeTabsFilterBtnSx, marginTop: spacing.m10, ...themeFonts.caption, width: 'auto' }}
          endIcon={<Close {...iconSize} stroke={themeColors.DarkGrey} />}
        >
          {capitalize(domain)}
        </Button>
      )}
      {domain ? (
        <Box sx={{ mt: spacing.m20 }}>
          {[1, 2, 3, 4, 5].map((i) => (
            <Box
              key={`-${i}-`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Skeleton
                key={`${i}-ids`}
                variant="rectangular"
                width="20%"
                height={20}
                sx={{ backgroundColor: themeColors.Background, borderRadius: radius.br10 }}
              />
              <Skeleton
                key={`${i}-ids`}
                variant="rectangular"
                width="10%"
                height={10}
                sx={{ backgroundColor: themeColors.Background, mt: spacing.m30 }}
              />
              {[1, 2, 3, 4, 5].map((i) => (
                <Box
                  key={`-${i}-`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '20px',
                    borderBottom: i < 5 ? borders.background : 'none',
                  }}
                >
                  <Skeleton
                    key={`${i}-ids`}
                    variant="rectangular"
                    width="100%"
                    height={10}
                    sx={{ backgroundColor: themeColors.Background, mt: spacing.m30 }}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ mt: spacing.m50, display: 'flex', flexDirection: 'column', gap: spacing.g30 }}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <Box
              key={`-${i}-`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Skeleton
                key={`${i}-ids`}
                variant="rectangular"
                width="20%"
                height={15}
                sx={{ backgroundColor: themeColors.Background, borderRadius: radius.br10 }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Fragment>
  );
};
