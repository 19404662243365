import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { DeviceOrderDto } from '@v2/feature/device/device.dto';
import { RefinancingPlanDto } from '@v2/feature/super-admin/features/super-admin-refinancing/refinancing.dto';

export enum TwoCancellationReason {
  'MIGRATION' = 'MIGRATION',
  'TERMINATED' = 'TERMINATED',
  'WRONG_DETAILS' = 'WRONG_DETAILS',
  'CREDIT_LIMITATION' = 'CREDIT_LIMITATION',
  'TAX_CHANGES' = 'TAX_CHANGES',
}

export interface RefinancingCompanyDetails {
  companyId: number;
  name: string;
  noOfDevices: number;
  nationalId: string | null;
  address: string | null;
  country: string | null;
  status: RefinancingCompanyStatus | null;
  reason: string | null;
  monthlyCreditLimit: number | null;
  creditUtilisation: number | null;
}

export type DeviceOrderWithCompanyDetails = DeviceOrderDto & { company?: SuperAdminCompanyInfo } & {
  refinancing?: CompanyRefinancing;
  refinancingPlan?: RefinancingPlanDto | null;
};

export enum RefinancingCompanyStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export interface RefinancingSearchResult {
  id: string;
  name: string;
}

export interface TwoCompanyAddress {
  city?: string;
  postalCode?: string;
  streetAddress?: string;
}

export interface CompanyRefinancing {
  id: number;
  companyId: number;
  creditCheckStatus: RefinancingCompanyStatus;
  minimumInstalments: number | null;
  maximumInstalments: number | null;
  maximumInstalmentAmount: number | null;
  accountId: string | null;
}

export interface CreateRepaymentData {
  reference: string;
  description: string;
  totalRepaymentAmount: number;
  taxAmount: number;
  currency: 'GBP';
  noOfInstalments: number;
  consentDate: string;
  billingPhone: string;
  addressLine1: string;
  addressLine2?: string;
  postCode: string;
  city: string;
  countryCode: 'GB';
  idempotencyId: number;
}

export interface TwoAccountRepresentative {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface TwoRepaymentPlanLineItem {
  name: string;
  description: string;
  product_identifiers: {
    value: string;
    identifier_type: 'EAN' | 'GTIN' | 'SKU' | 'UPC' | 'JAN' | 'CUSTOM_IDENTIFIER';
  }[];
  unit_price_amount: number;
  unit_discount_amount: number;
  tax_rate: number;
  tax_class_name: string;
  quantity: number;
  quantity_unit_name: string;
  line_item_type: 'DIGITAL' | 'PHYSICAL' | 'SHIPPING_FEE' | 'GIFTCARD' | 'SERVICE' | 'FEE';
}

export interface TwoRepaymentPlanTaxSubtotal {
  tax_rate: number;
  taxable_amount: number;
  tax_amount: number;
}

export interface TwoRepaymentPlanInstalment {
  invoice_issue_date: string;
  repayment_amount: number;
  tax_subtotals: TwoRepaymentPlanTaxSubtotal[];
}

export interface TwoAddress {
  address_line_1: string;
  address_line_2?: string;
  city: string;
  region?: string;
  postal_code: string;
  country_code: 'GB';
}

export interface TwoRepaymentPlanTerms {
  period: 'MONTHLY';
  number_of_periods: number;
}
