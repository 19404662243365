import { Typography } from '@mui/material';

import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/MistakeGray.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

const iconSize = { width: 14, height: 14 } as const;

export const EmptyCell = () => {
  return (
    <Typography
      sx={{
        ...themeFonts.caption,
        color: themeColors.Grey,
      }}
    >
      —
    </Typography>
  );
};

export const IconEmptyCell = () => {
  return <MistakeIcon {...iconSize} style={{ fill: themeColors.middleGrey }} />;
};
