import { useContext } from 'react';

import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { PersonalAbsencePage } from '@v2/feature/absence/me/policies/pages/personal-absence.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { ABSENCE_ME_REQUESTS_ROUTE } from '@/lib/routes';

export const AbsenceMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getUserAbsencePolicies(globalState.user.userId), {
    suspense: false,
  });

  return (
    <Switch>
      <RouteScopesHas scopes={['absence']} path={ABSENCE_ME_REQUESTS_ROUTE} exact>
        <PersonalAbsencePage absencePolicies={absencePolicies ?? null} userId={globalState.user.userId} />
      </RouteScopesHas>

      <Redirect to={ABSENCE_ME_REQUESTS_ROUTE} />
    </Switch>
  );
};
