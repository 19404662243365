import { useState } from 'react';

import { SurveysTodos } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { SurveyEntriesPersonalStartModal } from '@/v2/feature/growth/surveys/features/survey-entry/survey-entries-personal/components/survey-entries-personal-start-modal.component';

export const SurveyActionItem = ({
  surveyActionRow,
  afterClose,
}: {
  surveyActionRow: SurveysTodos | undefined;
  afterClose: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(surveyActionRow));
  return (
    <SurveyEntriesPersonalStartModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      afterClose={afterClose}
      onClose={() => setIsOpen(false)}
      selectedEntry={surveyActionRow?.entry}
    />
  );
};
