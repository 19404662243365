import { useCallback, useMemo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { ColumnDef } from '@tanstack/react-table';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { CalculationMethodMonth1, CalculationMethodStandard } from '@/lib/payroll';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { EntriesAndEffectiveRecord } from '@/v2/util/effective-record.util';
import { toTitleCase } from '@/v2/util/string.util';

const iconSize = { width: 14, height: 14 } as const;
const identity = <T,>(row: T) => row;

const PAYROLL_ALL_SCOPE = ['payroll:all'] as const;

type TaxUKTableItem = UKPayrollValues &
  Pick<UserPayrollDto, 'changeReason' | 'effectiveDate' | 'payrollId' | 'updatedAt' | 'updatedBy'> & {
    record: UserPayrollDto;
  };

type TaxUKTableProps = {
  payroll: EntriesAndEffectiveRecord<UserPayrollDto> | null | undefined;
  userId: number;
  onEditRowClick: (record: UserPayrollDto) => void;
};

type TaxUKTableResult = [TaxUKTableItem[] | undefined, ColumnDef<TaxUKTableItem, TaxUKTableItem>[] | undefined];

export const useTaxUKTable = ({ onEditRowClick, payroll, userId }: TaxUKTableProps): TaxUKTableResult => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const formatStudentLoans = useCallback((record: UKPayrollValues) => {
    const combinedLoans = [];
    if (record.studentLoan) combinedLoans.push(`${record.studentLoan}`);
    if (record.postgradLoan) combinedLoans.push(`Postgraduate`);
    return combinedLoans.length > 0 ? combinedLoans.join(', ') : undefined;
  }, []);

  const formatDirectorshipStint = useCallback((record: UKPayrollValues) => {
    let directorshipDates;
    if (record?.directorFrom && record?.directorTo)
      directorshipDates = `(${dateAPItoDisplay(record?.directorFrom)} - ${dateAPItoDisplay(record?.directorTo)})`;
    if (record?.directorFrom && !record?.directorTo)
      directorshipDates = `(${dateAPItoDisplay(record?.directorFrom)} - ?)`;
    return directorshipDates;
  }, []);

  const columnData = useMemo<ColumnDef<TaxUKTableItem, TaxUKTableItem>[]>(
    () => [
      {
        header: () => 'Payroll ID',
        accessorFn: identity,
        id: 'payroll-id',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.payrollId}</div>,
      },
      {
        header: () => 'Tax code',
        accessorFn: identity,
        id: 'tax-code',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.taxCode}</div>,
      },
      {
        header: () => 'Calculation method',
        accessorFn: identity,
        id: 'week1',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>{original.week1Month1 ? CalculationMethodMonth1 : CalculationMethodStandard}</div>
        ),
      },
      {
        header: () => 'Starter declaration',
        accessorFn: identity,
        id: 'starter-declaration',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.starterDeclaration}</div>,
      },
      {
        header: () => 'NI category',
        accessorFn: identity,
        id: 'ni-category',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.niTable}</div>,
      },
      {
        header: () => 'NINO',
        accessorFn: identity,
        id: 'nino',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{original.niNumber}</div>,
      },
      {
        header: () => 'Student loan',
        accessorFn: identity,
        id: 'student-loan',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{formatStudentLoans(original)}</div>,
      },
      {
        header: () => 'Legal gender',
        accessorFn: identity,
        id: 'legal-gender',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <div>{toTitleCase(original.legalGender ?? '')}</div>,
      },
      {
        header: () => 'Directorship',
        accessorFn: identity,
        id: 'directorship',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <div>{original.isDirector ? `Yes ${formatDirectorshipStint(original)}` : 'No'}</div>
        ),
      },
      {
        header: () => 'Last edited by',
        accessorFn: identity,
        id: 'last-edit-user',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) =>
          original.updatedBy ? <UserAvatar userId={original.updatedBy} size="xxsmall" /> : <EmptyCell />,
      },
      {
        header: () => 'Last edited on',
        accessorFn: identity,
        id: 'last-edit-date',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => <DateLabelComponent date={original.updatedAt} />,
      },
      {
        header: () => 'Reason',
        accessorFn: identity,
        id: 'change-reason',
        enableSorting: false,
        size: 80,
        cell: ({ row: { original } }) => (
          <Typography title={original.changeReason ?? ''} sx={{ ...themeFonts.caption, whiteSpace: 'nowrap' }}>
            {original.changeReason}
          </Typography>
        ),
      },
      {
        header: () => 'Effective date',
        accessorFn: identity,
        id: 'effective-date',
        enableSorting: false,
        size: 80,
        cell: ({
          row: {
            original: { effectiveDate },
          },
        }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {effectiveDate ? <DateLabelComponent date={effectiveDate} /> : '-'}
          </Box>
        ),
      },
      {
        header: () => '',
        accessorFn: identity,
        id: 'actions',
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => (
          <ScopesControl scopes={PAYROLL_ALL_SCOPE} context={scopesContext}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                title="edit"
                onClick={() => {
                  onEditRowClick(original.record);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </div>
          </ScopesControl>
        ),
      },
    ],
    [formatDirectorshipStint, formatStudentLoans, onEditRowClick, scopesContext]
  );

  if (!payroll) return [undefined, undefined];

  const tableRowData = payroll.entries.reduce((result, entry) => {
    const { payrollValues, changeReason, effectiveDate, payrollId, updatedAt, updatedBy } = entry;
    if (payrollValues) {
      result.push({
        record: entry,
        changeReason,
        effectiveDate,
        payrollId,
        updatedAt,
        updatedBy,
        ...payrollValues,
      });
    }
    return result;
  }, [] as TaxUKTableItem[]);

  return [tableRowData, columnData];
};
