import { pipe } from 'fp-ts/function';
import * as RA from 'fp-ts/ReadonlyArray';

import {
  ALL_REGIONS_AND_COUNTRIES,
  Countries,
  Country,
  CountryCode,
  NoneCountry,
  NoneCountryEnum,
  RegionCountry,
} from '@/v2/infrastructure/country/country.interface';

export function getCountryByCode(code: CountryCode | string): Country | undefined | NoneCountry {
  if (code === NoneCountryEnum.code) return { name: NoneCountryEnum.name, code: NoneCountryEnum.code };
  return Countries.find((country) => country.code === code);
}

export function getAllCountries(): RegionCountry[] {
  return ALL_REGIONS_AND_COUNTRIES;
}

export const getCountryOptionsForSelectComponent = () =>
  pipe(
    getCountriesForNationality(),
    RA.map((r) =>
      pipe(
        r.options ?? [],
        RA.map((x) => ({ ...x, group: r.label }))
      )
    ),
    RA.flatten
  );

export function getCountriesForNationality(): RegionCountry[] {
  return ALL_REGIONS_AND_COUNTRIES.map((region) =>
    region.label === 'United Kingdom'
      ? { ...region, options: [{ label: 'United Kingdom', value: 'United Kingdom' }] }
      : region
  );
}

export function getEURegionCoutry(): RegionCountry | undefined {
  return ALL_REGIONS_AND_COUNTRIES.find((g) => g.label === 'European Union');
}

export function getUKRegionCountry(): RegionCountry | undefined {
  return ALL_REGIONS_AND_COUNTRIES.find((g) => g.label === 'United Kingdom');
}

export function isUKCountryCode(code: string) {
  return /^(uk|gb)$/i.test(code);
}

export const isSameCountryCode = (a: string, b: string) => {
  return (
    a === b ||
    // UK and GB are treated as the same
    /^(uk|gb)$/i.test(a) === /^(uk|gb)$/i.test(b)
  );
};
