import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { AppIntegrationAPI } from '@v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  AppIntegrationUserEmailDto,
  EmploymentSummary,
} from '@v2/feature/app-integration/app-integration.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { KeyedMutator } from 'swr';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { ReactComponent as Cancel } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import {
  APPS_NOT_REQUIRING_REFRESH_POST_ACTION,
  APP_ACTION_DRAWER_MODES,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationStub,
  CurrentlyDelayedJobs,
  REFRESH_DELAY_APP_USER_LIST,
} from '@/v2/feature/app-integration/app-integration.interface';
import {
  externalUserHasDelayedAppAction,
  getCancellableActionMenuOption,
  getDelayedActionsForExternalUser,
  getDelayedActionsForUserId,
  userHasDelayedAppAction,
} from '@/v2/feature/app-integration/app-integration.util';
import {
  getActionNameForApp,
  hasActiveEmailForAccount,
} from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { tablePrimaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type AppStub = 'jumpcloud' | 'monday' | 'google-workspace' | 'github';

export interface ActionButtonProps {
  user?: AppIntegrationUserDto;
  alreadyImported?: boolean;
  employment?: EmploymentSummary;
  appStub: AppStub | AppIntegrationStub;
  refresh: Function;
  readonly markTeamUserPending?: (userId: number) => void;
  setOnboardUserDrawerOpen?: Dispatch<SetStateAction<boolean>>;
  setIsAppActionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setGroupManagementDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedActiveUserLogin?: Dispatch<SetStateAction<string | undefined>>;
  setPreselectedUser?: Dispatch<SetStateAction<AppIntegrationUserDto | undefined>>;
  setEmployeeForOnboarding?: Dispatch<SetStateAction<EmploymentSummary | undefined>>;
  setActionsDrawerMode?: Dispatch<SetStateAction<APP_ACTION_DRAWER_MODES | undefined>>;
  setGroupManagementDrawerMode: Dispatch<SetStateAction<APP_GROUP_MANAGEMENT_DRAWER_MODES | undefined>>;
  delayedActions?: CurrentlyDelayedJobs<CreateAppQueue> | undefined;
  refreshDelayedActions?: KeyedMutator<CurrentlyDelayedJobs<CreateAppQueue>> | undefined;
  table: string;
  hasAppsAllOrAppOwner: boolean;
  error: boolean;
  setAppStub?: Dispatch<SetStateAction<AppIntegrationStub | undefined>>;
  appAccessDetailsDrawerMode?: boolean;
  setAppAccessDetailsDrawerMode?: Dispatch<SetStateAction<boolean>>;
}

export const AppDetailsActionButton = ({
  user,
  alreadyImported,
  employment,
  appStub,
  refresh,
  markTeamUserPending,
  setOnboardUserDrawerOpen,
  setIsAppActionsDrawerOpen,
  setGroupManagementDrawerOpen,
  setGroupManagementDrawerMode,
  setSelectedActiveUserLogin,
  setPreselectedUser,
  setEmployeeForOnboarding,
  setActionsDrawerMode,
  delayedActions,
  refreshDelayedActions,
  table,
  hasAppsAllOrAppOwner,
  error,
  setAppStub,
  appAccessDetailsDrawerMode = false,
  setAppAccessDetailsDrawerMode,
}: ActionButtonProps) => {
  const [state] = useContext(GlobalContext);
  const [actionOptions, setActionOptions] = useState<OptionProps[] | undefined>(undefined);
  const actionDate = new Date(); // default for actions other than creation / till designs for delayed flow is provided for suspended / delete, etc.
  const [showMessage] = useMessage();
  const [refreshAt, setRefreshAt] = useState<number | null>(null);
  const { polyglot } = usePolyglot();

  useEffect(() => {
    if (!refreshAt) return;
    const delay = Math.max(refreshAt - Date.now(), 0);
    setTimeout(() => {
      setRefreshAt(null);
      refresh(appStub);
    }, delay);
  }, [appStub, refresh, refreshAt]);

  function refreshApp(delay = REFRESH_DELAY_APP_USER_LIST) {
    // actions are now asyncly queued before being performed so just pause
    // for a few seconds before refreshing the page to allow some time for
    // the action to complete.
    setRefreshAt(Date.now() + delay);
  }

  function getActionDate(date?: Date): Date | undefined {
    return date?.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? undefined : date; // if today do not send a action date
  }

  function userStatusIs(status: string | null | undefined, choices: string[]) {
    if (!status) return false;
    return choices.includes(status);
  }

  const closeAppAccessDetailDrawerIfOpen = useCallback(() => {
    if (appAccessDetailsDrawerMode && setAppAccessDetailsDrawerMode)
      setTimeout(() => setAppAccessDetailsDrawerMode(false), 500);
  }, [appAccessDetailsDrawerMode, setAppAccessDetailsDrawerMode]);

  const suspendUser = useCallback(async (): Promise<void> => {
    try {
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.suspend);
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if (setPreselectedUser) setPreselectedUser(user);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${error}`, 'error');
    }
  }, [
    setActionsDrawerMode,
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    setPreselectedUser,
    user,
    showMessage,
  ]);

  const activateUser = useCallback(
    async (date?: Date): Promise<void> => {
      try {
        await AppIntegrationAPI.activateAppUser(
          appStub,
          user?.id as number,
          (user?.userId as number) ?? 0,
          getActionDate(date)
        );
        showMessage('Activation initiated', 'success');
        if (user?.userId && markTeamUserPending) markTeamUserPending(Number(user?.userId));
      } catch (error: any) {
        if (error.response?.data?.error === `Higher tier of ${appStub} app needed.`) {
          showMessage(
            'In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.',
            'error'
          );
        } else {
          showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
        }
      }
    },
    [appStub, showMessage, user?.id, user?.userId, markTeamUserPending]
  );

  const unassignUser = useCallback(
    async ({ emailObj }: { emailObj: AppIntegrationUserEmailDto }): Promise<void> => {
      try {
        await AppIntegrationAPI.unassignUser(appStub, user?.userId as number, emailObj.email);
        showMessage('Account unassigned successfully', 'success');
        if (!APPS_NOT_REQUIRING_REFRESH_POST_ACTION.includes(appStub)) refresh(appStub);
        if (user?.userId && markTeamUserPending) markTeamUserPending(Number(user?.userId));
      } catch (error: any) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [appStub, user?.userId, showMessage, refresh, markTeamUserPending]
  );

  const deleteUserFromDrawer = useCallback(async (): Promise<void> => {
    try {
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.delete);
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if (setPreselectedUser) setPreselectedUser(user);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${error}`, 'error');
    }
  }, [
    setActionsDrawerMode,
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    setPreselectedUser,
    user,
    showMessage,
  ]);

  // const importUser = useCallback(
  //   async (appUserId: string): Promise<void> => {
  //     try {
  //       const result = await AppIntegrationAPI.importUser(appStub, appUserId);
  //       if (result) showMessage('User created successfully in Zelt', 'success');
  //       refresh(appStub);
  //       if (employment?.id && markTeamUserPending) markTeamUserPending(Number(employment?.id));
  //     } catch (error: any) {
  //       showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
  //     }
  //   },
  //   [appStub, employment?.id, markTeamUserPending, refresh, showMessage]
  // );

  const onboardUser = useCallback(async (): Promise<void> => {
    try {
      if (setOnboardUserDrawerOpen) setOnboardUserDrawerOpen(true);
      if (setPreselectedUser) setPreselectedUser(user);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
    }
  }, [setOnboardUserDrawerOpen, setPreselectedUser, showMessage, user]);

  const onboardEmployment = useCallback(async (): Promise<void> => {
    try {
      if (setOnboardUserDrawerOpen) setOnboardUserDrawerOpen(true);
      if (setEmployeeForOnboarding) setEmployeeForOnboarding(employment);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
    }
  }, [employment, setEmployeeForOnboarding, setOnboardUserDrawerOpen, showMessage]);

  const resetPasswordForUser = useCallback(async (): Promise<void> => {
    try {
      if (appStub === 'google-workspace' && user && (user?.userId || user?.id)) {
        await AppIntegrationAPI.resetPasswordForAppUser(
          appStub,
          user.id as string,
          user?.userId ? +user?.userId : 0,
          user?.primaryEmail ?? '',
          user?.name?.fullName ?? ''
        );
        showMessage('Password reset successfully; please check your email for the temporary password.', 'success');
      } else {
        refreshApp(1000);
      }
    } catch (error: any) {
      if (error.response?.data?.error === `Higher tier of ${appStub} app needed.`) {
        showMessage(
          'In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.',
          'error'
        );
      } else {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    }
  }, [appStub, showMessage, user]);

  const assignUser = useCallback(async (): Promise<void> => {
    try {
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if (user?.login && setSelectedActiveUserLogin) setSelectedActiveUserLogin(user?.login);
      else if (user?.primaryEmail && setSelectedActiveUserLogin) setSelectedActiveUserLogin(user?.primaryEmail);
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.assign);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${error}`, 'error');
    }
  }, [
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    user?.login,
    user?.primaryEmail,
    setSelectedActiveUserLogin,
    setActionsDrawerMode,
    showMessage,
  ]);

  const transferDataForUserBeforeDeleting = useCallback(async (): Promise<void> => {
    try {
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if (user?.id && setPreselectedUser) setPreselectedUser(user);
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${error}`, 'error');
    }
  }, [
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    user,
    setPreselectedUser,
    setActionsDrawerMode,
    showMessage,
  ]);

  const giveAccess = useCallback(async (): Promise<void> => {
    try {
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.create);
      if (setPreselectedUser) setPreselectedUser(user);
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
    } catch (error: any) {
      showMessage(`Oops, something happened. Please try again: ${error}`, 'error');
    }
  }, [
    setActionsDrawerMode,
    setPreselectedUser,
    user,
    setAppStub,
    appStub,
    closeAppAccessDetailDrawerIfOpen,
    setIsAppActionsDrawerOpen,
    showMessage,
  ]);

  const cancelScheduledAction = useCallback(
    async (jobId: string, userId: number, action: string): Promise<void> => {
      try {
        const successfullyCancelled = await AppIntegrationAPI.cancelScheduledAction(appStub, jobId, userId, action);
        if (successfullyCancelled) {
          showMessage('Action cancelled', 'success');
          if (refreshDelayedActions) refreshDelayedActions();
        } else {
          showMessage('Failed to cancel action', 'error');
          if (refreshDelayedActions) refreshDelayedActions();
        }
        closeAppAccessDetailDrawerIfOpen();
      } catch (error: any) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [appStub, closeAppAccessDetailDrawerIfOpen, showMessage, refreshDelayedActions]
  );

  useEffect(() => {
    let options: OptionProps[] = [];
    if (
      user?.userId &&
      ['team', 'candidates'].includes(table) &&
      delayedActions &&
      userHasDelayedAppAction(delayedActions, Number(user?.userId), appStub)
    ) {
      const cancellableActions = getDelayedActionsForUserId(delayedActions, Number(user?.userId), appStub);
      cancellableActions.forEach((eachAction) =>
        options.push({
          icon: <Cancel {...iconSize} fill={themeColors.Red} />,
          label: getCancellableActionMenuOption(eachAction.data.action),
          handler: () => cancelScheduledAction(String(eachAction.id), eachAction.data.userId, eachAction.data.action),
        })
      );
    } else if (
      (user?.primaryEmail || user?.id) &&
      table === 'external' &&
      delayedActions &&
      externalUserHasDelayedAppAction(delayedActions, user.primaryEmail, user.id ? String(user.id) : '0')
    ) {
      const cancellableActions = getDelayedActionsForExternalUser(
        delayedActions,
        user?.primaryEmail ? user?.primaryEmail : '',
        user?.id ? String(user?.id) : '0'
      );
      cancellableActions.forEach((eachAction) =>
        options.push({
          icon: <Cancel {...iconSize} fill={themeColors.Red} />,
          label: getCancellableActionMenuOption(eachAction.data.action),
          handler: () => cancelScheduledAction(String(eachAction.id), eachAction.data.userId, eachAction.data.action),
        })
      );
    } else {
      if (user?.primaryEmail && table === 'external') {
        options.push({
          label: 'Assign to team member',
          disabled: error,
          handler: () => assignUser(),
        });
      }
      user?.emails
        ?.filter((emailObj) => emailObj.status !== 'No access')
        .map((emailObj: AppIntegrationUserEmailDto) => {
          if (!emailObj.primary) {
            options.push({
              icon: <Cancel {...iconSize} fill={themeColors.Red} />,
              label: `Unassign ${emailObj.email ?? user?.primaryEmail}`,
              handler: () => unassignUser({ emailObj }),
            });
          }
          return null;
        });
      if (user?.allowsResetPassword && user?.id) {
        options.push({
          icon: <></>,
          label: 'Reset password',
          disabled: error,
          handler: () => resetPasswordForUser(),
        });
      }
      if (
        !['candidates'].includes(table) &&
        user?.allowsSuspend &&
        !userStatusIs(user?.userStatus, ['Suspended', 'No access']) &&
        user?.id
      ) {
        options.push({
          icon: <Cancel {...iconSize} fill={themeColors.Red} />,
          label: getActionNameForApp('Suspend', appStub),
          disabled: error,
          handler: () => suspendUser(),
        });
      }
      if (
        user &&
        user?.allowsGroupManagement &&
        !userStatusIs(user?.userStatus, ['Deleted', 'Suspended', 'No access']) &&
        user?.id
      ) {
        options.push({
          icon: <Plus {...iconSize} />,
          label: getActionNameForApp('Add to group', appStub),
          disabled: error,
          handler: (e) => {
            if (setPreselectedUser) setPreselectedUser((user as unknown) as AppIntegrationUserDto);
            setGroupManagementDrawerOpen(true);
            setGroupManagementDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup);
            e?.stopPropagation();
          },
        });
      }
      if (
        user?.allowsDelete &&
        !['employments', 'candidates'].includes(table) &&
        !userStatusIs(user?.userStatus, ['Scheduled', 'Deleted', 'No access'])
      ) {
        options.push({
          icon: <Trash {...iconSize} />,
          label: getActionNameForApp('Delete', appStub),
          disabled: user?.userId === state.user.userId || error,
          handler: () => {
            if (appStub !== 'google-workspace') deleteUserFromDrawer();
            if (appStub === 'google-workspace') {
              transferDataForUserBeforeDeleting();
            }
          },
        });
      }
      if (!['candidates'].includes(table) && user?.allowsSuspend && user?.userStatus === 'Suspended' && user?.id) {
        options.push({
          icon: <></>,
          label: 'Activate',
          disabled: error,
          handler: () => activateUser(actionDate),
        });
      }
      if (user?.allowsGiveAccess && userStatusIs(user?.userStatus, ['No access']) && !hasActiveEmailForAccount(user)) {
        options.push({
          icon: <Plus {...iconSize} />,
          label: 'Give access',
          disabled: error,
          handler: (e) => {
            giveAccess();
            e?.stopPropagation();
          },
        });
      }
      if (
        !alreadyImported &&
        employment?.status !== 'No access' &&
        employment?.allowsImport &&
        ['employments'].includes(table) &&
        employment?.status !== 'Imported'
      ) {
        options.push({
          icon: <Plus {...iconSize} />,
          label: 'Onboard',
          disabled: error,
          handler: () => onboardEmployment(),
        });
      }
      if (user?.allowsImport && ['candidates'].includes(table) && user?.userStatus !== 'Imported') {
        options.push({
          icon: <Plus {...iconSize} />,
          label: 'Onboard',
          disabled: error,
          handler: () => onboardUser(),
        });
      }
    }
    setActionOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateUser, assignUser, resetPasswordForUser, suspendUser, table, unassignUser, user]);

  const onlyHasGiveAccess = (actionOptions: OptionProps[] | undefined) => {
    return actionOptions?.length === 1 && actionOptions?.find((eachOption) => eachOption.label === 'Give access');
  };

  return appAccessDetailsDrawerMode ? (
    <Box sx={{ ...buttonBoxSx, display: 'flex', gap: spacing.g10 }}>
      {onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && (
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          key="delete"
          fullWidth
          onClick={(e) => {
            giveAccess();
            e.stopPropagation();
          }}
          id={`btnCreate_${user?.userId}`}
        >
          {polyglot.t('AppAccessDetailsDrawer.giveAccess')}
        </ButtonComponent>
      )}
      {actionOptions && actionOptions.length > 0 && !onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && (
        <Box sx={{ width: '100%' }}>
          <StyledMenuComponent
            options={actionOptions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: polyglot.t('General.actions'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: spacing.m10 }}>
      {onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && (
        <IconButton
          disabled={error}
          sx={tablePrimaryIconButtonSx}
          onClick={(e) => {
            giveAccess();
            e.stopPropagation();
          }}
          id={`btnCreate_${user?.userId}`}
        >
          <Plus {...iconSize} />
        </IconButton>
      )}
      {actionOptions && actionOptions.length > 0 && !onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && (
        <>
          <StyledMenuComponent
            options={actionOptions}
            actionButtonDetails={{
              type: 'iconButton',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: 'actions',
              icon: <ActionsSmall {...iconSize} />,
            }}
          />
        </>
      )}
    </Box>
  );
};
