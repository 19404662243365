import { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { spacing } from '@v2/styles/spacing.styles';

interface PolicyDescriptionLineProps {
  readonly field: string;
  readonly value: string | number | ReactNode;
}

export const SettingsReadOnlyLine = ({ field, value }: PolicyDescriptionLineProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g24 }}>
      <Box sx={{ width: '240px' }}>
        <Typography variant="caption">{field}</Typography>
      </Box>
      <Box sx={{ width: '390px' }}>
        {['string', 'number'].includes(typeof value) ? <Typography variant="title4">{value}</Typography> : value}
      </Box>
    </Box>
  );
};
