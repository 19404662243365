import { Fragment, useMemo } from 'react';

import { Box } from '@mui/material';
import { getISOWeek } from 'date-fns';

import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import '@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.scss';
import { getMondayOfCurrentWeek } from '@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.util';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserAttendanceLoader = () => {
  const weekStart = getMondayOfCurrentWeek();
  const currentWeekNo = useMemo(() => getISOWeek(weekStart), [weekStart]);

  return (
    <WidgetLayout readOnly={false} size="big" isLoadingState={true}>
      <Fragment>
        <Box>
          <div style={{ padding: spacing.p5 }}>
            <Typography variant="title2">Attendance</Typography>
          </div>
        </Box>

        <Box sx={{ display: 'flex', gap: spacing.g5, width: '100%' }}>
          <Box
            sx={{
              maxWidth: '50px',
              width: '100%',
              pt: spacing.p10,
              pb: spacing.p10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: 'left',
                gap: spacing.g15,
                paddingRight: spacing.p5,
                paddingLeft: spacing.p5,
              }}
            >
              <div style={{ width: '40px', height: '40px' }}>
                <Typography variant="caption">Week</Typography>
                <Typography variant="caption">{currentWeekNo}</Typography>
              </div>
              <Typography variant="caption">Start</Typography>
              <Typography variant="caption">End</Typography>
              <Typography variant="caption">Break</Typography>
            </div>
          </Box>

          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              pt: spacing.p10,
              pb: spacing.p10,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                textAlign: 'left',
                gap: spacing.g30,
                paddingRight: spacing.p5,
                paddingLeft: spacing.p5,
              }}
            >
              {[0, 1, 2, 3].map((num) => (
                <div className="u-att-loader-container" key={`${num}-ual`}>
                  <div className="u-att-progress" />
                </div>
              ))}
            </Box>
          </Box>
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
