import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { OptionObject, SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import {
  LastFilterOptions,
  NextFilterOptions,
  ThisFilterOptions,
} from '@v2/feature/reports/reports-advanced/components/filters-list.section';
import { FilterValueSelectorProps } from '@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component';
import { NotImplemented } from '@v2/feature/reports/reports-advanced/filters-components/not-implemented.component';
import { ReportSQLOperator } from '@v2/feature/reports/reports.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import dayjs from 'dayjs';
import Polyglot from 'node-polyglot';

const getDayMonthYearIsOptions = (op: ReportSQLOperator, polyglot: Polyglot): OptionObject[] => {
  if (op === ReportSQLOperator.dayIs) {
    const result: OptionObject[] = [];
    for (let i = 1; i <= 31; i++) {
      result.push({ label: String(100 + i).slice(1), value: String(100 + i).slice(1) });
    }
    return result;
  }

  if (op === ReportSQLOperator.monthIs)
    return [
      { label: polyglot.t('getMonthOptions.january'), value: '01' },
      { label: polyglot.t('getMonthOptions.february'), value: '02' },
      { label: polyglot.t('getMonthOptions.march'), value: '03' },
      { label: polyglot.t('getMonthOptions.april'), value: '04' },
      { label: polyglot.t('getMonthOptions.may'), value: '05' },
      { label: polyglot.t('getMonthOptions.june'), value: '06' },
      { label: polyglot.t('getMonthOptions.july'), value: '07' },
      { label: polyglot.t('getMonthOptions.august'), value: '08' },
      { label: polyglot.t('getMonthOptions.september'), value: '09' },
      { label: polyglot.t('getMonthOptions.october'), value: '10' },
      { label: polyglot.t('getMonthOptions.november'), value: '11' },
      { label: polyglot.t('getMonthOptions.december'), value: '12' },
    ];

  return [];
};

export const DateonlySelector = ({
  operator,
  value,
  setValue,
  label,
}: Omit<FilterValueSelectorProps, 'filterType'>) => {
  const { polyglot } = usePolyglot();

  const SelectionOperators = useMemo(() => {
    return new Set<ReportSQLOperator>([ReportSQLOperator.last, ReportSQLOperator.this, ReportSQLOperator.next]);
  }, []);

  const DateOnlyOperatorsWithNoExtraField = useMemo(() => {
    return new Set<ReportSQLOperator>([
      // KNOWN-UNKNOWN
      ReportSQLOperator.isKnown,
      ReportSQLOperator.isUnknown,
    ]);
  }, []);

  const DateOnlyOperatorsWithDatePicker = useMemo(() => {
    return new Set([
      ReportSQLOperator.eq,
      ReportSQLOperator.gt,
      ReportSQLOperator.lt,
      ReportSQLOperator.gte,
      ReportSQLOperator.lte,
      ReportSQLOperator.not,
    ]);
  }, []);

  return DateOnlyOperatorsWithDatePicker.has(operator) ? (
    <DatePickerComponent
      name="value"
      label={label}
      value={value as string}
      onChange={(value) => {
        if (dayjs(value).isValid()) setValue(value);
      }}
    />
  ) : [ReportSQLOperator.dayIs, ReportSQLOperator.monthIs].includes(operator) ? (
    <SelectComponent
      name="value"
      label={label}
      options={getDayMonthYearIsOptions(operator, polyglot)}
      value={value as number}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      fullWidth
    />
  ) : operator === ReportSQLOperator.yearIs ? (
    <TextfieldComponent
      value={value}
      name="value"
      label={label}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      endAdornment="none"
    />
  ) : operator === ReportSQLOperator.between ? (
    <BetweenDatesPicker value={value} setValue={setValue} />
  ) : SelectionOperators.has(operator) ? (
    <SelectionOperatorPicker value={value} setValue={setValue} operator={operator} />
  ) : DateOnlyOperatorsWithNoExtraField.has(operator) ? null : (
    <NotImplemented />
  );
};

const BetweenDatesPicker = ({ value, setValue }: Pick<FilterValueSelectorProps, 'value' | 'setValue'>) => {
  const [startDate, setStartDate] = useState(
    Array.isArray(value) && value[0] && typeof value[0] === 'string' ? String(value[0]) : ''
  );
  const [endDate, setEndDate] = useState(
    Array.isArray(value) && value[1] && typeof value[1] === 'string' ? String(value[1]) : ''
  );

  useEffect(() => {
    setValue([startDate ?? '', endDate ?? '']);
  }, [startDate, endDate, setValue]);

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <DatePickerComponent
        name="value"
        label="Start date"
        value={startDate}
        onChange={(value) => {
          if (dayjs(value).isValid()) {
            setStartDate(value);

            setEndDate((prevEnd) => {
              // if end >= new start, keep the value
              if (prevEnd >= value) return prevEnd;
              // otherwise make end to be equal with new start
              return value;
            });
          }
        }}
      />
      <DatePickerComponent
        name="value"
        label="End date"
        value={endDate}
        onChange={(value) => {
          if (dayjs(value).isValid()) {
            setEndDate(value);
            setStartDate((prevStart) => {
              // if start <= new end, keep the value
              if (prevStart <= value) return prevStart;
              // otherwise make start to be equal with new end
              return value;
            });
          }
        }}
      />
    </Box>
  );
};

const SelectionOperatorPicker = ({
  value,
  setValue,
  operator,
}: Pick<FilterValueSelectorProps, 'value' | 'setValue' | 'operator'>) => {
  const options = useMemo(() => {
    if (operator === ReportSQLOperator.this)
      return [
        { label: 'Today', value: ThisFilterOptions.Day },

        { label: 'Week', value: ThisFilterOptions.Week, description: 'This calendar week' },
        {
          label: 'Week so far',
          value: ThisFilterOptions.WeekSoFar,
          description: 'This calendar week up to today',
        },

        { label: 'Month', value: ThisFilterOptions.Month, description: 'This calendar month' },
        {
          label: 'Month so far',
          value: ThisFilterOptions.MonthSoFar,
          description: 'This calendar month up to today',
        },

        { label: 'Quarter', value: ThisFilterOptions.Quarter, description: 'This calendar quarter' },
        {
          label: 'Quarter so far',
          value: ThisFilterOptions.QuarterSoFar,
          description: 'This calendar quarter up to today',
        },

        { label: 'Year', value: ThisFilterOptions.Year, description: 'This calendar year' },
        {
          label: 'Year so far',
          value: ThisFilterOptions.YearSoFar,
          description: 'This calendar year up to today',
        },
      ];

    if (operator === ReportSQLOperator.last)
      return [
        { label: 'Was yesterday', value: LastFilterOptions.Last1D },
        { label: 'Last 2 days', value: LastFilterOptions.Last2D, description: 'including today' },
        { label: 'Last 7 days', value: LastFilterOptions.Last7D, description: 'including today' },
        { label: 'Last 14 days', value: LastFilterOptions.Last14D, description: 'including today' },
        { label: 'Last 30 days', value: LastFilterOptions.Last30D, description: 'including today' },
        { label: 'Last 60 days', value: LastFilterOptions.Last60D, description: 'including today' },
        { label: 'Last 90 days', value: LastFilterOptions.Last90D, description: 'including today' },
        { label: 'Last 365 days', value: LastFilterOptions.Last365D, description: 'including today' },
        { label: 'Last week', value: LastFilterOptions.Week, description: 'Last calendar week' },
        { label: 'Last month', value: LastFilterOptions.Month, description: 'Last calendar month' },
        { label: 'Last quarter', value: LastFilterOptions.Quarter, description: 'Last calendar quarter' },
        { label: 'Last year', value: LastFilterOptions.Year, description: 'Last calendar year' },
      ];

    if (operator === ReportSQLOperator.next)
      return [
        { label: 'IS tomorrow', value: NextFilterOptions.Next1D },
        { label: 'Next 2 days', value: NextFilterOptions.Next2D, description: 'excluding today' },
        { label: 'Next 7 days', value: NextFilterOptions.Next7D, description: 'excluding today' },
        { label: 'Next 14 days', value: NextFilterOptions.Next14D, description: 'excluding today' },
        { label: 'Next 30 days', value: NextFilterOptions.Next30D, description: 'excluding today' },
        { label: 'Next 60 days', value: NextFilterOptions.Next60D, description: 'excluding today' },
        { label: 'Next 90 days', value: NextFilterOptions.Next90D, description: 'excluding today' },
        { label: 'Next 365 days', value: NextFilterOptions.Next365D, description: 'excluding today' },
        { label: 'Next week', value: NextFilterOptions.Week, description: 'Next calendar week' },
        { label: 'Next month', value: NextFilterOptions.Month, description: 'Next calendar month' },
        { label: 'Next quarter', value: NextFilterOptions.Quarter, description: 'Next calendar quarter' },
        { label: 'Next year', value: NextFilterOptions.Year, description: 'Next calendar year' },
      ];

    return [{ label: 'Not implemented', value: '' }];
  }, [operator]);

  const [selection, setSelection] = useState<string>(typeof value === 'string' ? value : options[0].value);

  useEffect(() => {
    setValue(selection);
  }, [selection, setValue]);

  return (
    <SelectComponent
      name="selection"
      label="Unit"
      options={options}
      value={selection}
      onChange={(e) => {
        setSelection(e.target.value ?? options[0].value);
      }}
    />
  );
};
