import React from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { PayrollEditPayCodesPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycode-edit.component';
import { PayrollNewPayCodesPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycode-new.component';
import {
  AccountingFieldPaycodeMapping,
  CompanyPayrollAccountingConfig,
  NominalCodeDto,
} from '@/v2/feature/payroll/payroll.interface';
import {
  NewCompanyPayCode,
  StaffologyPayCode,
} from '@/v2/infrastructure/common-interfaces/staffology-client.interface';

type Props = {
  payrollId: number;
  payCode?: StaffologyPayCode | 'new';
  payCodesNames: string[];
  nominalCodes?: NominalCodeDto[] | null;
  reimportNominalCodes: () => Promise<void>;
  accountingConfig?: CompanyPayrollAccountingConfig | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createCompanyPaycode: (
    paycode: NewCompanyPayCode,
    accountingCode?: AccountingFieldPaycodeMapping
  ) => Promise<boolean>;
  deleteCompanyPaycode: (codeToDelete: StaffologyPayCode) => Promise<boolean>;
  updateCompanyPaycode: (
    paycode: StaffologyPayCode,
    accountingCode?: AccountingFieldPaycodeMapping
  ) => Promise<boolean>;
};

export const PayrollEditPayCodesDrawer = ({
  payrollId,
  payCodesNames,
  payCode,
  nominalCodes,
  accountingConfig,
  isOpen,
  setIsOpen,
  createCompanyPaycode,
  deleteCompanyPaycode,
  updateCompanyPaycode,
  reimportNominalCodes,
}: Props): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        {isOpen && payCode && payCode !== 'new' && accountingConfig && (
          <PayrollEditPayCodesPage
            payrollId={payrollId}
            payCodesNames={payCodesNames}
            payCode={payCode}
            close={() => setIsOpen(false)}
            deleteCompanyPaycode={deleteCompanyPaycode}
            updateCompanyPaycode={updateCompanyPaycode}
            nominalCodes={nominalCodes}
            reimportNominalCodes={reimportNominalCodes}
            accountingConfig={accountingConfig}
          />
        )}
        {isOpen && payCode === 'new' && accountingConfig && (
          <PayrollNewPayCodesPage
            payrollId={payrollId}
            payCodesNames={payCodesNames}
            close={() => setIsOpen(false)}
            createCompanyPaycode={createCompanyPaycode}
            nominalCodes={nominalCodes}
            reimportNominalCodes={reimportNominalCodes}
            accountingConfig={accountingConfig}
          />
        )}
      </>
    </DrawerModal>
  );
};
