import { useMemo, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { JobPositionEndpoints } from '@v2/feature/job-position/job-position.api';
import { MissingFieldsDetail } from '@v2/feature/templates/components/missing-template-field-modal.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { MissingFieldForContractTemplate } from '@v2/feature/user/user.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { ReactComponent as Plus } from '@/images/fields/Plus.svg';
import { OptionObj, OptionsProps } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { JobPosition } from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { RoleForm } from '@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

type Props = {
  readonly userId: number;
  readonly refreshData?: () => Promise<void>;
  readonly missingFieldsForCategory?: MissingFieldForContractTemplate[];
};

export const MissingRole = ({ userId, refreshData, missingFieldsForCategory }: Props) => {
  const { polyglot } = usePolyglot();

  const { data: deptsData, mutate: refreshDept, isLoading: loadingDept } = useApiClient(
    DepartmentEndpoints.getCompanyDepartments(),
    {
      suspense: false,
    }
  );
  const departments = useMemo<readonly OptionObj[]>(() => {
    return deptsData?.map((dept) => ({ label: dept.name, value: dept.id })) ?? [];
  }, [deptsData]);

  const { data: siteData, mutate: refreshSites, isLoading: loadingSites } = useApiClient(SiteEndpoints.getSites(), {
    suspense: false,
  });
  const sites = useMemo<readonly OptionObj[]>(() => {
    return siteData?.map((site) => ({ label: site.name, value: site.id })) ?? [];
  }, [siteData]);

  const { data: jobPositionData, mutate: refreshJobPosition, isLoading: loadingJobPosition } = useApiClient(
    JobPositionEndpoints.listJobPositions(),
    {
      suspense: false,
    }
  );

  const { nonTerminatedCachedUsers } = useCachedUsers();

  const managers = useMemo<OptionsProps[]>(() => {
    return nonTerminatedCachedUsers
      .filter((u) => u.userId !== userId)
      ?.map((rec) => {
        return { label: rec.displayName, value: rec.userId, ...rec };
      });
  }, [nonTerminatedCachedUsers, userId]);

  const jobPositions: OptionObj[] = useMemo(() => {
    if (!jobPositionData) return [] as OptionObj[];
    return jobPositionData
      ? jobPositionData?.map((eachJobPosition: JobPosition) => {
          return {
            label: eachJobPosition.title,
            value: eachJobPosition.id as number,
          };
        })
      : [];
  }, [jobPositionData]);

  const nextPossibleId = useMemo(() => {
    if (!jobPositionData) return '';
    const lastJobPosition = jobPositionData?.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = lastJobPosition[jobPositionData.length - 1]?.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1000000).toString();
    }
  }, [jobPositionData]);

  const defaultJobPosition = useMemo(() => {
    return {
      id: undefined,
      internalCode: nextPossibleId,
      title: '',
      description: '',
    };
  }, [nextPossibleId]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Stack sx={{ flexFlow: 'column' }}>
        <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
          <Typography sx={themeFonts.caption}>{polyglot.t('MissingRole.roleInfo')}</Typography>
          <IconButton onClick={() => setIsModalOpen(true)} sx={{ ...secondaryCTABtn, p: '7px', ml: 'auto' }}>
            <Plus width="14px" height="14px" />
          </IconButton>
        </Stack>
        {missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)}
      </Stack>
      <DrawerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} closeIcon="back">
        {refreshData && !loadingDept && !loadingJobPosition && !loadingSites ? (
          <RoleForm
            userId={userId}
            departments={departments}
            managers={managers}
            sites={sites}
            refreshSites={refreshSites}
            refreshDept={refreshDept}
            jobPositions={jobPositions}
            initialValues={null}
            onSubmit={() => refreshData?.()}
            onClose={() => setIsModalOpen(false)}
            onRefresh={() => refreshData?.()}
            refreshJobPosition={refreshJobPosition}
            defaultJobPosition={defaultJobPosition}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DrawerModal>
    </>
  );
};
