import {
  CreateUserCustomBenefitDto,
  EditableUserCustomBenefitDto,
  UserCustomBenefitDto,
  UserCustomBenefitRequestDto,
} from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import { LocalDate } from '@v2/util/local-date';
import axios from 'axios';

import {
  CreateCustomBenefitDto,
  CustomBenefitDto,
  UpdateCustomBenefitApprovalDto,
  UpdateCustomBenefitBalanceDto,
  UpdateCustomBenefitGeneralDto,
  UpdateCustomBenefitMembersDto,
  UpdateCustomBenefitPaymentDto,
  UpdateCustomBenefitPayrollDto,
  UserBenefitRequest,
  UserCustomBenefitPaymentDto,
  UserCustomBenefitPaymentStatus,
} from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';

export class CustomBenefitAPI {
  static async createCustomBenefit(benefitData: CreateCustomBenefitDto): Promise<number> {
    return (await axios.post('/apiv2/custom-benefit', benefitData)).data;
  }

  static async repayLoanPayment(
    userId: number,
    customBenefitId: number,
    userBenefitId: number,
    paymentValue: number
  ): Promise<void> {
    await axios.post(
      `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${userBenefitId}/repay-loan-payment`,
      { paymentValue }
    );
  }

  static async getAllCustomBenefits(): Promise<CustomBenefitDto[]> {
    return (await axios.get('/apiv2/custom-benefit'))?.data;
  }

  static async updateCustomBenefitGeneral(id: number, benefitData: UpdateCustomBenefitGeneralDto): Promise<void> {
    return (await axios.patch(`/apiv2/custom-benefit/${id}/general`, benefitData))?.data;
  }

  static async updateCustomBenefitBalance(id: number, benefitData: UpdateCustomBenefitBalanceDto): Promise<void> {
    return (await axios.patch(`/apiv2/custom-benefit/${id}/balance`, benefitData))?.data;
  }

  static async updateCustomBenefitMembers(id: number, benefitData: UpdateCustomBenefitMembersDto): Promise<void> {
    return (await axios.patch(`/apiv2/custom-benefit/${id}/members`, benefitData))?.data;
  }

  static async updateCustomBenefitPayroll(id: number, benefitData: UpdateCustomBenefitPayrollDto): Promise<void> {
    return (await axios.patch(`/apiv2/custom-benefit/${id}/payroll`, benefitData))?.data;
  }

  static async updateCustomBenefitPayment(id: number, benefitData: UpdateCustomBenefitPaymentDto): Promise<void> {
    return (await axios.patch(`/apiv2/custom-benefit/${id}/payment`, benefitData))?.data;
  }

  static async updateCustomBenefitApproval(id: number, benefitData: UpdateCustomBenefitApprovalDto): Promise<void> {
    return (await axios.patch(`/apiv2/custom-benefit/${id}/approval`, benefitData))?.data;
  }

  static async deleteBenefitById(benefitId: number): Promise<CustomBenefitDto[]> {
    return (await axios.delete(`/apiv2/custom-benefit/${benefitId}`))?.data;
  }

  static async addUserToCustomBenefit(
    userId: number,
    customBenefitId: number,
    data: CreateUserCustomBenefitDto
  ): Promise<void> {
    await axios.post(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}`, data);
  }

  static async updateUserCustomBenefitById(
    userId: number,
    customBenefitId: number,
    id: number,
    update: EditableUserCustomBenefitDto
  ): Promise<void> {
    await axios.patch(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${id}`, update);
  }

  static async deleteUserCustomBenefit(userId: number, customBenefitId: number, id: number): Promise<void> {
    await axios.delete(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${id}`);
  }

  static async approveOrRejectCustomBenefitPaymentById(
    customBenefitId: number,
    userId: number,
    userCustomBenefitId: number,
    paymentId: string,
    status: UserCustomBenefitPaymentStatus
  ): Promise<void> {
    await axios.patch(
      `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/payments/${paymentId}/status`,
      { status }
    );
  }

  static async userCustomBenefitOneOffPayment(
    userId: number,
    customBenefitId: number,
    userBenefitId: number,
    data: { amount: number; notes: string }
  ): Promise<void> {
    await axios.post(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/benefit/${userBenefitId}`, {
      amount: data.amount,
      date: new LocalDate().toDateString(),
      notes: data.notes,
    });
  }

  static async requestUserBenefitLoan(
    userId: number,
    customBenefitId: number,
    userCustomBenefitId: number,
    data: UserBenefitRequest
  ): Promise<{ approvedLoan: boolean }> {
    return (
      await axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/loan-request`,
        data
      )
    ).data;
  }

  static async requestUserBenefitChange(
    userId: number,
    customBenefitId: number,
    userCustomBenefitId: number,
    data: UserBenefitRequest
  ): Promise<{ changeApplied: boolean }> {
    return (
      await axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/change-request`,
        data
      )
    ).data;
  }

  static async requestUserBenefitOptOut(
    userId: number,
    customBenefitId: number,
    userCustomBenefitId: number,
    data: UserBenefitRequest
  ): Promise<{ optedOut: boolean }> {
    return (
      await axios.post(
        `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/opt-out`,
        data
      )
    ).data;
  }

  static async approveUserBenefitRequest(userId: number, customBenefitId: number, requestId: string): Promise<void> {
    await axios.patch(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/requests/${requestId}/approve`);
  }

  static async rejectUserBenefitRequest(userId: number, customBenefitId: number, requestId: string): Promise<void> {
    await axios.patch(`/apiv2/custom-benefit/${customBenefitId}/users/${userId}/requests/${requestId}/reject`);
  }
}

export class CustomBenefitEndpoints {
  static getAllUserCustomBenefits(benefitId: number): Endpoint<UserCustomBenefitDto[]> {
    return {
      url: `/apiv2/custom-benefit/${benefitId}/users`,
    };
  }

  static getCustomBenefitById(benefitId: number): Endpoint<CustomBenefitDto> {
    return {
      url: `/apiv2/custom-benefit/${benefitId}`,
    };
  }

  static getCustomBenefitPaymentAndRemainingBalance(
    benefitId: number,
    userId: number,
    userCustomBenefitId: number,
    benefitPaymentId: string
  ): Endpoint<{ payment: UserCustomBenefitPaymentDto; remainingBalance: number | null }> {
    return {
      url: `/apiv2/custom-benefit/${benefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/payments/${benefitPaymentId}`,
    };
  }

  static getAllUserCustomBenefitsByBenefitId(
    userId: number,
    customBenefitId: number
  ): Endpoint<UserCustomBenefitDto[]> {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/by-user/${userId}`,
    };
  }

  static canManageBenefitForUser(customBenefitId: number, userId: number): Endpoint<boolean> {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/can-manage`,
    };
  }

  static allEffectiveUserBenefitsByUserId(userId: number): Endpoint<UserCustomBenefitDto[]> {
    return {
      url: `/apiv2/custom-benefit/by-user/${userId}/effective`,
    };
  }

  static getUserCustomBenefitRequestsByBenefitId(
    userId: number,
    customBenefitId: number,
    userCustomBenefitId: number
  ): Endpoint<UserCustomBenefitRequestDto[]> {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/requests`,
    };
  }

  static getUserCustomBenefitRequestById(
    userId: number,
    customBenefitId: number,
    userCustomBenefitId: number,
    requestId: string
  ): Endpoint<UserCustomBenefitRequestDto> {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/users/${userId}/user-benefit/${userCustomBenefitId}/requests/${requestId}`,
    };
  }

  static getUserCustomBenefitRemainingAllowance(
    userId: number,
    customBenefitId: number,
    userCustomBenefitId: number,
    date: string
  ): Endpoint<number> {
    return {
      url: `/apiv2/custom-benefit/${customBenefitId}/user-benefit/${userCustomBenefitId}/users/${userId}/remaining/${date}`,
    };
  }
}
