import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserProfileProvider } from '@/v2/feature/user/context/user-profile.context';
import { AboutCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-about-card.component';
import { AccountInformationCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-account-info-card.component';
import { BasicInformationCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-card.component';
import { CustomSections } from '@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-card.component';
import { FamilyCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-family-card.component';
import { PersonalInformationCard } from '@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-card.component';
import { UserProfilePageProps } from '@/v2/feature/user/features/user-profile/user-profile.interface';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserPersonal = ({ userId, onUpdate, missingFields }: UserProfilePageProps): JSX.Element => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const finallyRefreshUserObject = async (section: string) => {
    if (['user.basicInfo', 'user.role', 'user.lifecycle'].includes(section)) {
    }
    await onUpdate();
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Personal</Typography>} />

      <ContentWrapper loading={false} sx={{ pt: spacing.p25 }}>
        <UserProfileProvider>
          <>
            <ScopesControl scopes={['user.basicInfo:read']} context={scopesContext}>
              <BasicInformationCard
                userId={userId}
                onSubmit={() => finallyRefreshUserObject('user.basicInfo')}
                missingFields={missingFields}
              />
            </ScopesControl>

            <ScopesControl scopes={['user.personalInfo:read']} context={scopesContext}>
              <PersonalInformationCard
                userId={userId}
                onSubmit={() => finallyRefreshUserObject('user.personalInfo')}
                missingFields={missingFields}
              />
            </ScopesControl>

            <ScopesControl scopes={['user.family:read']} context={scopesContext}>
              <FamilyCard
                userId={userId}
                onSubmit={() => finallyRefreshUserObject('user.family')}
                missingFields={missingFields}
              />
            </ScopesControl>

            <ScopesControl scopes={['user.about:read']} context={scopesContext}>
              <AboutCard
                userId={userId}
                onSubmit={() => finallyRefreshUserObject('user.about')}
                missingFields={missingFields}
              />
            </ScopesControl>

            <CustomSections
              userId={userId}
              formTab={ProfileTab.Personal}
              onSubmit={() => finallyRefreshUserObject('')}
              missingFields={missingFields}
            />

            <ScopesControl scopes={['user.accountInfo', 'user.accountInfo:read']} context={scopesContext}>
              <AccountInformationCard userId={userId} onSubmit={() => finallyRefreshUserObject('user.lifecycle')} />
            </ScopesControl>
          </>
        </UserProfileProvider>
      </ContentWrapper>
    </RootStyle>
  );
};
