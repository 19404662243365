import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';

import { ReactComponent as TasksIcon } from '@/images/onboarding-step-icons/BenefitsBig.svg';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  setDrawerOpen?: (open: boolean) => void;
  setDrawerView?: (view: JSX.Element) => void;
};

export const BenefitsOnboardingItem = (_: Props) => {
  const { polyglot } = usePolyglot();
  return (
    <ActionSelectionItem
      step={{
        Icon: TasksIcon,
        title: polyglot.t('BenefitsOnboardingItem.title'),
        description: polyglot.t('BenefitsOnboardingItem.desc'),
      }}
      sx={{
        py: spacing.py20,
      }}
    />
  );
};
