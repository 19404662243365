import { useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as MistakeIcon } from '@/images/alerts/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/alerts/Ok.svg';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { MultiUserAvatar } from '@/v2/components/theme-components/multi-user-avatar.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { collateMissingFieldsForUser } from '@/v2/feature/user/features/user-forms/user-forms.util';
import { UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  userId: number;
}

export const UserProfileBar = ({ userId }: Props) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const currentScopesContext = getScopesContext({ userId });

  const hasAboutScope = hasScopes(['user.about:read'], scopesContext);

  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userId);
  const manager = user?.role?.managerId ? getCachedUserById(user.role.managerId) : undefined;

  const { data: missingFields, isLoading: missingFieldsLoading } = useApiClient(
    UserEndpoints.getUserMissingFields(userId),
    { suspense: false }
  );
  const { data: aboutData } = useApiClient(UserEndpoints.getUserAbout(userId, hasAboutScope), { suspense: false });
  const { data: userSummary } = useApiClient(UserEndpoints.getUserSummaryById(userId), { suspense: false });

  const userMissingFields = useMemo(() => missingFields && collateMissingFieldsForUser(missingFields), [missingFields]);
  const directReportsId = useMemo(() => userSummary?.directReports?.map((u) => u.userId), [userSummary]);
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <UserAvatar userId={userId} size="large" key={userId} />
        <Box sx={{ ...spacing.ml20, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="title2">
            {polyglot.t(user?.displayName ?? `${user?.firstName} ${user?.lastName}`)}
          </Typography>
          {user?.role?.jobTitle && (
            <Typography variant="caption" sx={{ color: themeColors.DarkGrey, marginTop: spacing.m5 }}>
              {polyglot.t(user.role.jobTitle)}
            </Typography>
          )}

          <ScopesControl scopes={['user:all']} context={currentScopesContext} key="actions">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m10,
                marginTop: spacing.m10,
                minHeight: '20px',
              }}
            >
              {missingFieldsLoading ? (
                <SkeletonLoader
                  variant="rectangular"
                  width="50%"
                  height="16px"
                  sx={{ borderRadius: 2, backgroundColor: themeColors.Background }}
                />
              ) : userMissingFields && userMissingFields?.length > 1 ? ( //userid is always on the first index
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: radius.br10,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                    <MistakeIcon style={{ fill: themeColors.Red }} width={14} height={14} />
                    <Typography variant="caption" sx={{ color: themeColors.Red }}>
                      {polyglot.t('DashboradUserProfile.missingInfo')}
                    </Typography>
                  </Box>
                </Box>
              ) : userMissingFields && userMissingFields?.length <= 1 ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5, justifyContent: 'center' }}>
                  <OkGreen style={{ fill: themeColors.Green }} width={14} height={14} />
                  <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
                    {polyglot.t('DashboradUserProfile.upToDate')}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </ScopesControl>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', ...spacing.gap20, marginTop: spacing.m30 }}>
        {user?.role?.department?.name && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.department')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {polyglot.t(user.role.department.name)}
            </Typography>
          </Box>
        )}

        {user?.emailAddress && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.email')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {user.emailAddress}
            </Typography>
          </Box>
        )}

        {manager && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.reportsTo')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
              <UserAvatar userId={manager.userId} size="xxsmall" />
              <Typography
                variant="title4"
                sx={{
                  color: themeColors.DarkGrey,
                }}
              >
                {polyglot.t(manager.displayName)}
              </Typography>
            </Box>
          </Box>
        )}

        {directReportsId && directReportsId.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.directReports')}
            </Typography>
            <Box sx={{ gap: spacing.m5, display: 'flex', alignItems: 'center' }}>
              <MultiUserAvatar userIds={directReportsId} showLimit={5} />
            </Box>
          </Box>
        )}

        {user?.startDate && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.startDate')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {getDateString(new Date(user.startDate))}
            </Typography>
          </Box>
        )}

        {user?.userEvent?.status && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.status')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {user.userEvent.status}
            </Typography>
          </Box>
        )}

        {user?.role?.site?.name && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.site')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {polyglot.t(user.role.site.name)}
            </Typography>
          </Box>
        )}

        {aboutData?.about && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.about')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {aboutData.about}
            </Typography>
          </Box>
        )}

        {aboutData?.hobbies && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.hobbies')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {aboutData.hobbies}
            </Typography>
          </Box>
        )}

        {aboutData?.foodPreferences && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.m5 }}>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              {polyglot.t('UserProfileBar.favouriteFood')}
            </Typography>
            <Typography variant="title4" sx={{ color: themeColors.DarkGrey }}>
              {aboutData.foodPreferences}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
