import React from 'react';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';

export interface ApiError {
  response: {
    data: {
      errors?: Error[];
      error: string;
    };
  };
}

interface AlertErrorProps {
  error: ApiError | Error | string | null;
  title: string;
}

export default function AlertError({ error, title }: AlertErrorProps) {
  if (!error) return <div />;

  // TODO we should unify all error formats
  let errors: Error[];
  const err = error as ApiError;
  if (err.response && err.response.data && err.response.data.errors) {
    errors = err.response.data.errors;
  } else if (err.response && err.response.data && err.response.data.error) {
    errors = [new Error(err.response.data.error)];
  } else if (typeof error === 'string') {
    errors = [new Error(error)];
  } else {
    errors = [error as Error];
  }

  return (
    <Alert severity="error" sx={{ my: 1 }}>
      <AlertTitle>{title}</AlertTitle>
      {errors.map((error: Error) => (
        <Box key={error.name} sx={{ pr: 4 }}>
          <Typography variant="caption" sx={{ fontWeight: 'bold', mr: 1 }}>
            {error.name}:
          </Typography>
          {error.message}
        </Box>
      ))}
    </Alert>
  );
}
