import { ReactNode } from 'react';

import { Box, IconButton, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { SettingsReadOnlyLine } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { actionIconSize } from '@v2/styles/table.styles';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { Loader } from '@/v2/components/loader.component';

export enum SectionItemType {
  Pair = 'pair',
  TextLine = 'textLine',
  Component = 'component',
}

type Pair = {
  readonly type: SectionItemType.Pair;
  readonly label: string;
  readonly value: string | number | ReactNode;
  readonly hidden?: boolean;
};

type TextLine = {
  readonly type: SectionItemType.TextLine;
  readonly value: string | number | ReactNode;
  readonly hidden?: boolean;
};

type Component = {
  readonly type: SectionItemType.Component;
  readonly value: ReactNode | null;
  readonly hidden?: boolean;
};

export type SectionItem = Pair | TextLine | Component;

export interface SettingsSection {
  readonly title?: string | ReactNode;
  readonly onEdit?: () => void;
  readonly hideEdit?: boolean;
  readonly buttons?: ReactNode[];
  readonly editButtonIcon?: ReactNode;
  readonly editButtonSx?: SxProps<Theme>;
  readonly editButtonDisabled?: boolean;
  readonly headerWidth?: string;
  readonly headerSx?: SxProps<Theme>;
  readonly contentWidth?: string;
  readonly loading?: boolean;
  readonly hidden?: boolean;
  readonly items: SectionItem[];
}

interface SettingsSubsectionContentProps {
  readonly title?: string;
  readonly id?: string;
  readonly buttonIcon?: ReactNode;
  readonly loadingAll?: boolean;
  readonly sections: SettingsSection[];
}

export const SettingsSubsectionContent = ({ loadingAll = false, sections, id }: SettingsSubsectionContentProps) => {
  return (
    <Loader loading={!!loadingAll} sx={{ minHeight: 'auto', height: '100%' }} hideFooter>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }} id={id}>
        {sections.map((s, index) =>
          s.hidden ? null : (
            <Loader
              loading={!!s.loading}
              key={`section-${index}`}
              sx={{ minHeight: 'auto', height: '100%', width: s.contentWidth ?? '600px', gap: '20px' }}
              hideFooter
            >
              <SectionTitle
                title={s.title}
                headerWidth={s.headerWidth ?? '600px'}
                onEdit={s.onEdit}
                editButtonSx={s.editButtonSx}
                editButtonDisabled={s.editButtonDisabled}
                buttons={s.buttons}
                hideEdit={s.hideEdit}
                headerSx={s.headerSx}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {s.items.map((item) => {
                  if (item.hidden) return null;
                  if (item.type === SectionItemType.TextLine)
                    return typeof item.value === 'object' ? (
                      item.value
                    ) : (
                      <Typography variant="caption">{item.value}</Typography>
                    );

                  if (item.type === SectionItemType.Pair)
                    return <SettingsReadOnlyLine key={item.label} field={item.label} value={item.value} />;

                  if (item.type === SectionItemType.Component) return item.value;
                  return null;
                })}
              </Box>
            </Loader>
          )
        )}
      </Box>
    </Loader>
  );
};

interface SectionTitleProps {
  readonly title?: string | ReactNode;
  readonly headerWidth?: string;
  readonly onEdit?: () => void;
  readonly editButtonSx?: SxProps<Theme>;
  readonly editButtonDisabled?: boolean;
  readonly hideEdit?: boolean;
  readonly buttons?: ReactNode[];
  readonly buttonIcon?: ReactNode;
  readonly headerSx?: SxProps<Theme> | undefined;
}

const SectionTitle = ({
  title,
  buttons,
  onEdit,
  editButtonSx,
  editButtonDisabled = false,
  hideEdit = false,
  buttonIcon = <EditIcon {...actionIconSize} />,
  headerWidth,
  headerSx,
}: SectionTitleProps) => {
  return title || buttons || onEdit ? (
    <Box
      sx={{
        width: headerWidth,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...headerSx,
      }}
    >
      {title && typeof title === 'string' ? <Typography variant="title3">{title}</Typography> : title ? title : null}
      {(buttons || onEdit) && (
        <Box sx={{ display: 'flex', gap: '5px' }}>
          {buttons}
          {onEdit && !hideEdit && (
            <IconButton
              key="edit"
              sx={{ ...tableIconButtonSx, ...editButtonSx } as SxProps}
              disabled={editButtonDisabled}
              onClick={onEdit}
            >
              {buttonIcon}
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  ) : null;
};
