import currency from 'currency.js';
import {
  PayScheduleEnum,
  SalaryBasisEnum,
  UserCompensationDto,
} from '@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';
import { OptionObject } from '@v2/components/forms/select.component';
import Polyglot from 'node-polyglot';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { AttendanceScheduleDto, AttendanceSettingsDto } from '@/v2/feature/attendance/attendance.dto';
import { getAverageNumberOfWorkingDaysPerScheduleWeek } from '@v2/feature/attendance/attendance-schedule.util';
import { WeekDay } from '@v2/feature/attendance/attendance.interface';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';
import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';

export const MonthlyPayScheduleOption = (polyglot: Polyglot | null): { value: PayScheduleEnum; label: string } => ({
  value: PayScheduleEnum.Monthly,
  label: polyglot ? polyglot.t('PaySchedules.monthly') : 'Monthly',
});
export const WeeklyPayScheduleOption = (polyglot: Polyglot | null): { value: PayScheduleEnum; label: string } => ({
  value: PayScheduleEnum.Weekly,
  label: polyglot ? polyglot.t('PaySchedules.weekly') : 'Weekly',
});

export const MonthlySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Monthly,
  label: polyglot ? polyglot.t('PaySchedules.monthly') : 'Monthly',
});
export const DailySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Daily,
  label: polyglot ? polyglot.t('PaySchedules.daily') : 'Daily',
});
export const HourlySalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Hourly,
  label: polyglot ? polyglot.t('PaySchedules.hourly') : 'Hourly',
});
export const AnnualSalaryBasisOption = (polyglot: Polyglot | null): { value: SalaryBasisEnum; label: string } => ({
  value: SalaryBasisEnum.Annual,
  label: polyglot ? polyglot.t('PaySchedules.annual') : 'Annual',
});

export const PaySchedules = (polyglot: Polyglot | null) => [
  MonthlyPayScheduleOption(polyglot),
  WeeklyPayScheduleOption(polyglot),
];

export const SalaryBasis = (polyglot: Polyglot | null) => [
  AnnualSalaryBasisOption(polyglot),
  MonthlySalaryBasisOption(polyglot),
  DailySalaryBasisOption(polyglot),
  HourlySalaryBasisOption(polyglot),
];

export const annualSalaryToSalary = (
  annualSalary: number | string | currency,
  paySchedule: PayScheduleEnum
): number => {
  const divider = paySchedule === PayScheduleEnum.Weekly ? 52 : 12;
  return currency(annualSalary).divide(divider).value;
};

export const rateToSalary = (
  objToCheck: Partial<Pick<UserCompensationDto, 'salaryBasis' | 'rate' | 'units' | 'paySchedule'>>,
  annual: boolean = false
): number => {
  const { salaryBasis, rate, units, paySchedule } = objToCheck;
  if (!rate || !salaryBasis || (!annual && !paySchedule)) return 0;

  if (salaryBasis === SalaryBasisEnum.Annual) return annual ? rate ?? 0 : annualSalaryToSalary(rate ?? 0, paySchedule!);
  if (salaryBasis === SalaryBasisEnum.Monthly)
    return annual ? (rate ?? 0) * 12 : annualSalaryToSalary((rate ?? 0) * 12, paySchedule!);

  if (!units) return 0;
  if (salaryBasis === SalaryBasisEnum.Daily || salaryBasis === SalaryBasisEnum.Hourly)
    return annual
      ? (rate ?? 0) * units! * (paySchedule === PayScheduleEnum.Monthly ? 12 : 52)
      : annualSalaryToSalary((rate ?? 0) * units! * (paySchedule === PayScheduleEnum.Monthly ? 12 : 52), paySchedule!);
  return 0;
};

// !!! if changing this method, make sure to change it in FE as well (user-payroll.util.ts)
export const calculateSalaryRates = (
  compensation: Partial<Pick<UserCompensationDto, 'salaryBasis' | 'rate' | 'paySchedule' | 'units'>>,
  companyAttendance: Pick<AttendanceSettingsDto, 'weeklyMinutes' | 'daysPerWeek'> | null | undefined,
  userAttendanceSchedule: Pick<AttendanceScheduleDto, WeekDay | 'name' | 'noOfWeeks' | 'totalTime'> | null | undefined
): {
  annual: number;
  monthly: number;
  weekly: number;
  daily: number;
  hourly: number;
  payScheduleRate: number; // amount per pay-schedule (weekly or monthly)
  reference: {
    attendanceScheduleName?: string;
    hoursPerDay: number;
    daysPerWeek: number;
  };
} | null => {
  const { salaryBasis, rate, paySchedule, units } = compensation;
  if (!rate || !salaryBasis) return null;

  const workingTime = userAttendanceSchedule
    ? {
        daysPerWeek: getAverageNumberOfWorkingDaysPerScheduleWeek(userAttendanceSchedule),
        weeklyMinutes: userAttendanceSchedule.totalTime, // this is avg weekly minutes
      }
    : companyAttendance
    ? {
        daysPerWeek: companyAttendance.daysPerWeek,
        weeklyMinutes: companyAttendance.weeklyMinutes,
      }
    : {
        daysPerWeek: 5,
        weeklyMinutes: 2400, // 60mins * 8h * 5d
      };

  const workingDaysPerWeek = workingTime.daysPerWeek;
  const workingMinutesPerDay = workingTime.weeklyMinutes / workingDaysPerWeek;
  const workingHoursPerWeek = workingTime.weeklyMinutes / 60;
  const workingHoursPerDay = workingMinutesPerDay / 60;

  const workingDaysPerYear = 260;

  // round to 2dp
  const rnd = (n: number) => Math.round(n * 100) / 100;

  const rateCalculationsBySalaryBasis = {
    [SalaryBasisEnum.Annual]: () => ({
      annual: rate,
      monthly: rnd(rate / 12),
      weekly: rnd((rate / workingDaysPerYear) * workingDaysPerWeek), // daily-rate * days-per-week
      daily: rnd(rate / workingDaysPerYear),
      hourly: rnd(((rate / workingDaysPerYear) * workingDaysPerWeek) / workingHoursPerWeek), // weekly-rate / hours-per-week
    }),
    [SalaryBasisEnum.Monthly]: () => ({
      annual: rnd(rate * 12),
      monthly: rate,
      weekly: rnd(((rate * 12) / workingDaysPerYear) * workingDaysPerWeek),
      daily: rnd((rate * 12) / workingDaysPerYear),
      hourly: rnd((((rate * 12) / workingDaysPerYear) * workingDaysPerWeek) / workingHoursPerWeek),
    }),
    [SalaryBasisEnum.Daily]: () => ({
      annual: rnd(rate * workingDaysPerYear),
      monthly: rnd((rate * workingDaysPerYear) / 12),
      weekly: rnd(rate * workingDaysPerWeek),
      daily: rate,
      hourly: rnd(rate / workingHoursPerDay),
    }),
    [SalaryBasisEnum.Hourly]: () => ({
      annual: rnd(rate * workingHoursPerDay * workingDaysPerYear),
      monthly: rnd((rate * workingHoursPerDay * workingDaysPerYear) / 12),
      weekly: rnd(rate * workingHoursPerDay * workingDaysPerWeek),
      daily: rnd(rate * workingHoursPerDay),
      hourly: rate,
    }),
  };

  const fixedRates = rateCalculationsBySalaryBasis[salaryBasis]();

  let payScheduleRate = 0;
  if (paySchedule === PayScheduleEnum.Monthly) {
    payScheduleRate = {
      [SalaryBasisEnum.Annual]: () => fixedRates.monthly,
      [SalaryBasisEnum.Monthly]: () => fixedRates.monthly,
      [SalaryBasisEnum.Daily]: () => fixedRates.daily * (units ?? 0), // units are days-per-month
      [SalaryBasisEnum.Hourly]: () => fixedRates.hourly * (units ?? 0), // units are hours-per-month
    }[salaryBasis]();
  }
  if (paySchedule === PayScheduleEnum.Weekly) {
    payScheduleRate = {
      [SalaryBasisEnum.Annual]: () => fixedRates.weekly,
      [SalaryBasisEnum.Monthly]: () => fixedRates.weekly,
      [SalaryBasisEnum.Daily]: () => fixedRates.daily * (units ?? 0), // units are days-per-week
      [SalaryBasisEnum.Hourly]: () => fixedRates.hourly * (units ?? 0), // units are hours-per-week
    }[salaryBasis]();
  }

  return {
    ...fixedRates,
    payScheduleRate,
    reference: {
      attendanceScheduleName: userAttendanceSchedule?.name,
      daysPerWeek: workingDaysPerWeek,
      hoursPerDay: workingHoursPerDay,
    },
  };
};

export const displayRateLabel = (salaryBasis: SalaryBasisEnum, polyglot: Polyglot) => {
  switch (salaryBasis) {
    case SalaryBasisEnum.Annual:
      return polyglot.t('CompensationForm.annualSalary');
    case SalaryBasisEnum.Monthly:
      return polyglot.t('CompensationForm.monthlySalary');
    case SalaryBasisEnum.Daily:
      return polyglot.t('CompensationForm.dailyRate');
    case SalaryBasisEnum.Hourly:
      return polyglot.t('CompensationForm.hourlyRate');
    default:
      return polyglot.t('CompensationForm.rate');
  }
};

export const equityTypeOptions = (polyglot: Polyglot): OptionObject[] => {
  return [
    { value: 'Share', label: polyglot.t('equityTypeOptions.share') },
    { value: 'Option', label: polyglot.t('equityTypeOptions.option') },
    { value: 'RSU', label: polyglot.t('equityTypeOptions.rsu') },
  ];
};

export const calculateSalaryForPayPeriod = (
  compensation: Pick<UserCompensationDto, 'salaryBasis' | 'rate' | 'units' | 'currency'>,
  payPeriod: PayPeriod
): {
  rate: number;
  salaryBasis: SalaryBasisEnum;
  salaryForPayPeriod: number;
  units: number;
  currency: CurrencyShort;
} => {
  const { salaryBasis } = compensation;
  const to2DecimalPlaces = (x: number) => Math.round(x * 100) / 100;

  const rate = compensation.rate ?? 0;
  const units = compensation.units ?? 0;
  const currency = compensation.currency ?? DEFAULT_CURRENCY;

  if (payPeriod === PayScheduleEnum.Monthly && salaryBasis === SalaryBasisEnum.Annual) {
    return {
      currency,
      rate: to2DecimalPlaces(rate / 12),
      salaryForPayPeriod: to2DecimalPlaces(rate / 12),
      salaryBasis: SalaryBasisEnum.Monthly,
      units: 1,
    };
  }
  if (payPeriod === PayScheduleEnum.Monthly && salaryBasis === SalaryBasisEnum.Monthly) {
    return { currency, rate, salaryForPayPeriod: to2DecimalPlaces(rate), salaryBasis, units: 1 };
  }
  if (payPeriod === PayScheduleEnum.Weekly && salaryBasis === SalaryBasisEnum.Annual) {
    return {
      currency,
      rate,
      salaryForPayPeriod: to2DecimalPlaces(rate / 52),
      salaryBasis,
      units: 1,
    };
  }
  if (payPeriod === PayScheduleEnum.Weekly && salaryBasis === SalaryBasisEnum.Monthly) {
    return {
      currency,
      rate,
      salaryForPayPeriod: to2DecimalPlaces((rate * 12) / 52),
      salaryBasis,
      units: 1,
    };
  }

  if (salaryBasis === SalaryBasisEnum.Daily || salaryBasis === SalaryBasisEnum.Hourly) {
    // for hourly/daily rate, we ignore the pay period as the value is based on the units
    return {
      currency,
      rate,
      salaryForPayPeriod: to2DecimalPlaces(rate * units),
      salaryBasis,
      units,
    };
  }

  return { currency, rate: 0, salaryForPayPeriod: 0, salaryBasis: SalaryBasisEnum.Monthly, units: 1 };
};
