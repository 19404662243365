import React from 'react';

import { ReactComponent as EmptyRadio } from '@/images/side-bar-icons/empty-radio.svg';
import { ReactComponent as FullRadio } from '@/images/side-bar-icons/full-radio.svg';
import { StyledRadioStyle } from '@/v2/styles/radio.styles';

interface Props {
  name: string;
  id: string;
  value?: string;
  selectedValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean;
}

/** @deprecated use `StyledRadio styles` instead */
export const StyledRadio = ({ id, name, onChange, value, selectedValue, disabled = false }: Props) => {
  return (
    <label id="container" style={{ display: 'flex' }}>
      <StyledRadioStyle type="radio" onChange={onChange} name={name} value={value} disabled={disabled} id={id} />
      {value === selectedValue ? <FullRadio /> : <EmptyRadio />}
    </label>
  );
};
