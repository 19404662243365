import React, { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { ChangeOwnerDrawer } from '@v2/feature/device/components/device-details/change-owner-drawer.component';
import { DeleteDeviceDrawer } from '@v2/feature/device/components/device-details/delete-device-drawer.component';
import { DisenrollDeviceDrawer } from '@v2/feature/device/components/device-details/disenroll-device-drawer.component';
import { DeviceInventoryReassignDrawer } from '@v2/feature/device/components/devices-list-overview/device-inventory-reassign-drawer.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { ConfigurableDeviceData, DeviceOrderDto, DeviceTransitDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus, DeviceOwnership, DevicePossessionType } from '@v2/feature/device/device.interface';
import { isEnroled } from '@v2/feature/device/device.util';
import { DeviceCredentialsDrawer } from '@v2/feature/device/features/activation-modal/components/device-credentials-drawer.component';
import { DeviceOrderDrawer } from '@v2/feature/device/features/devices-company/components/device-order-drawer.component';
import { DeviceReturnDrawer } from '@v2/feature/device/features/devices-company/components/device-return-drawer.component';
import { CachedUser } from '@v2/feature/user/context/cached-users.context';
import { iconSize } from '@v2/styles/table.styles';
import { keyBy } from 'lodash';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Close } from '@/images/side-bar-icons/Close.svg';
import { ReactComponent as EnvelopeSimple } from '@/images/side-bar-icons/EnvelopeSimple.svg';
import { ReactComponent as Lock } from '@/images/side-bar-icons/Lock.svg';
import { ReactComponent as NextBtn } from '@/images/side-bar-icons/NextBtn.svg';
import { ReactComponent as OrderBag } from '@/images/side-bar-icons/OrderBag.svg';
import { ReactComponent as Person } from '@/images/side-bar-icons/Person.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { HeaderOptionsProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { LockDeviceDrawer } from '@/v2/feature/device/components/device-details/lock-device-drawer.component';
import { OrderActionsDrawer } from '@/v2/feature/device/components/device-details/order-actions-drawer.component';
import { WipeDeviceDrawer } from '@/v2/feature/device/components/device-details/wipe-device-drawer.component';
import { EnrollmentStatus } from '@/v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface DeviceActionsButtonProps {
  readonly configurableDevice: ConfigurableDeviceData;
  readonly fetchDeviceDetails: () => Promise<void>;
  activeTransit: DeviceTransitDto | null;
  readonly refreshDevicesAppData: () => Promise<void>;
  readonly assignedUser: CachedUser | undefined;
}

export const DeviceActionsButton = ({
  configurableDevice,
  fetchDeviceDetails,
  activeTransit,
  refreshDevicesAppData,
  assignedUser,
}: DeviceActionsButtonProps): JSX.Element => {
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);

  const [isActivationModalOpen, setIsActivationModalOpen] = useState<boolean>(false);
  const [isChangeOwnerDrawerOpen, setIsChangeOwnerDrawerOpen] = useState<boolean>(false);
  const [isAssignOwnerDrawerOpen, setIsAssignOwnerDrawerOpen] = useState<boolean>(false);
  const [isLockDeviceDrawerOpen, setIsLockDeviceDrawerOpen] = useState<boolean>(false);
  const [isWipeDeviceDrawerOpen, setIsWipeDeviceDrawerOpen] = useState<boolean>(false);
  const [isDisenrollDeviceDrawerOpen, setIsDisenrollDeviceDrawerOpen] = useState<boolean>(false);
  const [isOrderActionsDrawerOpen, setIsOrderActionsDrawerOpen] = useState<boolean>(false);

  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);
  const { getScopesContext } = useScopes();
  const { devicePossession, order } = configurableDevice;
  const [isTransitDrawerOpen, setIsTransitDrawerOpen] = useState<boolean>(false);
  const [selectedDeviceTransit] = useState<DeviceTransitDto | null | undefined>(activeTransit);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedDeviceOrder] = useState<DeviceOrderDto | null>(configurableDevice.order);
  const context =
    devicePossession?.possessionType === DevicePossessionType.User
      ? getScopesContext({ userId: devicePossession?.possessionId })
      : undefined;
  const manageOrder =
    (assignedUser &&
      configurableDevice?.devicePossession &&
      order &&
      order.status !== DeviceOrderStatus.Delivered &&
      !activeTransit) ??
    false;
  const deviceNotMDMActive = !(
    devicePossession?.device?.enrollmentStatus === 'enrolled' ||
    devicePossession?.device?.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
  );

  const mobileDevices = ['ipados', 'ios'];
  const isMobileAppleDevice = mobileDevices.includes(devicePossession.device?.os ?? '');

  const isPossessionActive = !!devicePossession.startDate && !devicePossession.endDate;

  const headerOptions: HeaderOptionsProps[] = [
    {
      title: 'Device',
      options: [
        {
          icon: <Person {...iconSize} />,
          handler: () => {
            setIsChangeOwnerDrawerOpen(true);
          },
          label: 'Change owner',
          disabled: false,
          hidden:
            !checkScopes(globalState.user, ['devices:all'], context) ||
            !isPossessionActive ||
            !!activeTransit ||
            [DevicePossessionType.ZeltStorage, DevicePossessionType.CompanySite].includes(
              devicePossession.possessionType
            ),
        },
        {
          label: 'Assign user',
          handler: () => {
            setIsAssignOwnerDrawerOpen(true);
          },
          icon: <Person {...iconSize} />,
          disabled: false,
          hidden:
            !checkScopes(globalState.user, ['devices'], context) ||
            !isPossessionActive ||
            !!activeTransit ||
            [DevicePossessionType.User].includes(devicePossession.possessionType),
        },
        {
          label: 'Order actions',
          handler: () => {
            setIsOrderActionsDrawerOpen(true);
          },
          icon: <OrderBag {...iconSize} fill={themeColors.DarkGrey} />,
          disabled: false,
          hidden:
            !checkScopes(globalState.user, ['devices'], context) ||
            !isPossessionActive ||
            !!activeTransit ||
            devicePossession.device?.ownership === DeviceOwnership.Company ||
            devicePossession.possessionType === DevicePossessionType.ZeltStorage,
        },
        {
          label: 'Delete',
          handler: () => setIsDeleteDrawerOpen(true),
          icon: <Trash {...iconSize} fill={themeColors.DarkGrey} />,
          hidden:
            !checkScopes(globalState.user, ['devices:all'], context) ||
            !isPossessionActive ||
            !!activeTransit ||
            devicePossession?.device?.ownership !== DeviceOwnership.Company ||
            devicePossession.possessionType === DevicePossessionType.ZeltStorage,
        },
      ],
    },
    {
      title: 'Security',
      hidden: !isPossessionActive,
      options: [
        {
          icon: <EnvelopeSimple {...iconSize} />,
          handler: async () => {
            try {
              if (devicePossession && devicePossession.id) await DeviceAPI.sendEnrollmentRequest(devicePossession.id);
              showMessage('Notification was successfully sent.', 'success');
            } catch (error) {
              showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
            }
          },
          label: 'Ask to enrol',
          hidden: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User ||
              isEnroled(devicePossession.device?.enrollmentStatus)
          ),
          disabled: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User ||
              isEnroled(devicePossession.device?.enrollmentStatus)
          ),
        },
        {
          label: 'Lock device',
          handler: () => {
            setIsLockDeviceDrawerOpen(true);
          },
          icon: <Lock {...iconSize} />,
          hidden: !checkScopes(globalState.user, ['devices'], context) || !isPossessionActive || deviceNotMDMActive,
        },
        {
          label: 'Wipe device',
          handler: () => {
            setIsWipeDeviceDrawerOpen(true);
          },
          icon: <Trash {...iconSize} />,
          hidden: !checkScopes(globalState.user, ['devices:all'], context) || !isPossessionActive || deviceNotMDMActive,
        },
        {
          label: 'Disenrol device',
          handler: () => {
            setIsDisenrollDeviceDrawerOpen(true);
          },
          icon: <Close {...iconSize} />,
          hidden:
            !checkScopes(globalState.user, ['devices:all'], context) ||
            !isPossessionActive ||
            deviceNotMDMActive ||
            devicePossession.device?.enrolmentType !== 'OPEN_ENROLMENT',
        },
      ],
    },
    {
      title: 'Shipping',
      hidden: !activeTransit,
      options: [
        {
          label: 'Manage shipping',
          handler: () => {
            setIsTransitDrawerOpen(true);
          },
          icon: <NextBtn {...iconSize} />,
          hidden: !checkScopes(globalState.user, ['devices'], context) || !activeTransit,
        },
      ],
    },
    {
      title: 'Order',
      hidden: !manageOrder,
      options: [
        {
          label: 'Manage order',
          handler: () => {
            setIsDrawerOpen(true);
          },
          icon: <NextBtn {...iconSize} />,
          hidden: !checkScopes(globalState.user, ['devices'], context) || !manageOrder,
        },
      ],
    },
  ];

  const allOptionsHidden = headerOptions?.every(
    (option) => option.options?.every((subOption) => subOption.hidden) ?? true
  );
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: spacing.g10, width: '100%' }}>
        {!deviceNotMDMActive &&
          isPossessionActive &&
          devicePossession.possessionType !== DevicePossessionType.ZeltStorage && (
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              disabled={false}
              fullWidth
              onClick={async () => {
                try {
                  if (devicePossession && devicePossession.id) await DeviceAPI.scanDevice(devicePossession.id);
                  showMessage('Action launched successfully', 'success');
                } catch (error) {
                  showMessage('The launch of the action failed', 'error');
                }
              }}
            >
              Sync
            </ButtonComponent>
          )}
        {headerOptions && !allOptionsHidden && (
          <StyledMenuComponent
            headerOptions={headerOptions}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: 'Actions',
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
              fullWidth: true,
            }}
            sx={{ width: '100%' }}
          />
        )}
      </Box>

      {isActivationModalOpen && devicePossession?.possessionType === DevicePossessionType.User && (
        <DeviceCredentialsDrawer
          isOpen={isActivationModalOpen}
          setIsOpen={setIsActivationModalOpen}
          devicePossession={devicePossession}
        />
      )}

      <ChangeOwnerDrawer
        devicePossession={devicePossession}
        isOpen={isChangeOwnerDrawerOpen}
        setIsOpen={setIsChangeOwnerDrawerOpen}
        onClose={async () => {
          await Promise.all<void>([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsChangeOwnerDrawerOpen(false);
        }}
      />

      <LockDeviceDrawer
        devicePossession={devicePossession}
        isOpen={isLockDeviceDrawerOpen}
        setIsOpen={setIsLockDeviceDrawerOpen}
        onClose={async () => {
          await Promise.all<void>([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsLockDeviceDrawerOpen(false);
        }}
        isMobileAppleDevice={isMobileAppleDevice}
      />

      <OrderActionsDrawer
        devicePossession={devicePossession}
        isOpen={isOrderActionsDrawerOpen}
        setIsOpen={setIsOrderActionsDrawerOpen}
        onClose={async () => {
          await Promise.all<void>([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsOrderActionsDrawerOpen(false);
        }}
      />

      <WipeDeviceDrawer
        devicePossession={devicePossession}
        isOpen={isWipeDeviceDrawerOpen}
        setIsOpen={setIsWipeDeviceDrawerOpen}
        onClose={async () => {
          await Promise.all<void>([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsWipeDeviceDrawerOpen(false);
        }}
      />

      <DisenrollDeviceDrawer
        devicePossession={devicePossession}
        isOpen={isDisenrollDeviceDrawerOpen}
        setIsOpen={setIsDisenrollDeviceDrawerOpen}
        onClose={async () => {
          await Promise.all<void>([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsDisenrollDeviceDrawerOpen(false);
        }}
      />

      <DeviceInventoryReassignDrawer
        isOpen={isAssignOwnerDrawerOpen}
        setIsOpen={setIsAssignOwnerDrawerOpen}
        onClose={async () => {
          setIsChangeOwnerDrawerOpen(false);
        }}
        devicePossession={devicePossession}
      />

      <DeleteDeviceDrawer
        isOpen={isDeleteDrawerOpen}
        setIsOpen={setIsDeleteDrawerOpen}
        devicePossession={devicePossession}
        onDelete={async () => {}}
      />

      {selectedDeviceTransit && (
        <DrawerModal
          setIsOpen={setIsTransitDrawerOpen}
          isOpen={isTransitDrawerOpen}
          onClose={() => {
            setIsTransitDrawerOpen(false);
          }}
          showExpand
          expandAction={() => {}}
        >
          <DeviceReturnDrawer
            selectedDeviceTransit={selectedDeviceTransit}
            refresh={async () => {
              setIsTransitDrawerOpen(false);
              await refreshDevicesAppData();
            }}
            reach="company"
          />
        </DrawerModal>
      )}
      {selectedDeviceOrder && (
        <DeviceOrderDrawer
          deviceOrder={selectedDeviceOrder}
          refresh={async () => {
            setIsDrawerOpen(false);
            await refreshDevicesAppData();
          }}
          sitesById={keyBy(configurableDevice.sites ?? [], 'id')}
          reach={'company'}
          setIsOpen={setIsDrawerOpen}
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
        />
      )}
    </Box>
  );
};
