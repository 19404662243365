import { UserBenefitExpensePage } from '@v2/feature/benefits/subfeature/custom-benefit/components/user-benefit-expense.page';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserCustomBenefitDetailsPage } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-details.page';
import { matchPath, Redirect, Switch, useLocation } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import {
  CUSTOM_BENEFIT_EXPENSE_ROUTE,
  CUSTOM_BENEFIT_ME_ROUTE,
  CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
} from '@/lib/routes';

interface CustomBenefitMeRouterProps {
  readonly userId: number;
  readonly userBenefits: readonly UserCustomBenefitDto[];
  readonly refreshCustomBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export const CustomBenefitUserRouter = ({
  userId,
  userBenefits,
  loading,
  refreshCustomBenefits,
}: CustomBenefitMeRouterProps) => {
  const location = useLocation();

  return (
    <Switch>
      <RouteScopesControl
        scopes={['insurance']}
        context={{ userId }}
        path={[CUSTOM_BENEFIT_OVERVIEW_ROUTE, USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE]}
      >
        <UserCustomBenefitDetailsPage
          userId={userId}
          userBenefits={userBenefits}
          loading={loading}
          refreshCustomBenefits={refreshCustomBenefits}
        />
      </RouteScopesControl>

      <RouteScopesControl
        scopes={['insurance']}
        context={{ userId }}
        path={[CUSTOM_BENEFIT_EXPENSE_ROUTE, USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE]}
      >
        <UserBenefitExpensePage userId={userId} userBenefits={userBenefits} />
      </RouteScopesControl>

      {matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE) ? (
        <Redirect to={USER_BENEFITS_CUSTOM_BENEFIT_ROUTE} />
      ) : (
        <Redirect to={CUSTOM_BENEFIT_ME_ROUTE} />
      )}
    </Switch>
  );
};
