import { Box, Typography } from '@mui/material';

import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { DeviceImportDto } from '@/v2/feature/device/features/devices-import/devices-import.dto';
import { iconSize } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
import { ValidationError } from '@/v2/infrastructure/api-error/api-error.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { pluralText } from '@/v2/util/string.util';

type Props = {
  rowData?: DeviceImportDto;
  rowErrors: ValidationError[] | undefined;
  fieldKey?: keyof DeviceImportDto;
  rawCellValue?: string;
  statusColumn?: number;
};

export const ImportedDeviceTableCell = ({ rowData, fieldKey, rowErrors, rawCellValue, statusColumn }: Props) => {
  const getErrorTextForTooltip = (errorObj: ValidationError) => {
    return Object.keys(errorObj.constraints)
      .map((eachKey) => errorObj.constraints[eachKey])
      .join(', ');
  };

  const errorForColumn = rowErrors?.find((e) => e.property === fieldKey);

  const getStatus = (): JSX.Element => {
    if (statusColumn === 0) {
      return (
        <>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          &nbsp; Valid
        </>
      );
    } else if (statusColumn && statusColumn > 0) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <MistakeIcon {...iconSize} />
          &nbsp; {statusColumn} issue{pluralText(statusColumn)}
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const getValueForCell = () => {
    if (!rowData && rawCellValue && rawCellValue.length > 0) return rawCellValue;
    else return rowData && fieldKey ? rowData[fieldKey] : '';
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mr: spacing.mr10 }}>
        {statusColumn === 0 || (statusColumn && statusColumn >= 0) ? getStatus() : getValueForCell()}
      </Typography>
      {errorForColumn && (
        <StyledTooltip title={getErrorTextForTooltip(errorForColumn)}>
          <MistakeIcon {...iconSize} />
        </StyledTooltip>
      )}
    </Box>
  );
};
