import axios from 'axios';

import { SurveyCycleLaunch } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import {
  EntryDetail,
  SurveyEntry,
  SurveyEntryUpdate,
} from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class SurveyEntryAPI {
  static async updateSurveyEntry(surveyEntryBody: SurveyEntryUpdate): Promise<void> {
    return (await axios.patch(`/apiv2/survey-entry`, surveyEntryBody)).data;
  }

  // static async invitePeersAndUpdateEntry(reviewEntryBody: SurveyEntryUpdateAndPeerInvite): Promise<void> {
  //   return (await axios.patch(`/apiv2/survey-entry/${reviewEntryBody.entry.id}/invite-peers`, reviewEntryBody)).data;
  // }
}

export class SurveyEntryEndpoints {
  static getUserEntries(): Endpoint<SurveyEntry[]> {
    return {
      url: `/apiv2/survey-entry/user`,
    };
  }

  static getSurveyCycleLaunchDetailsForCompanyAndUser(): Endpoint<SurveyCycleLaunch[]> {
    return {
      url: '/apiv2/survey-cycle/launch/ongoing-details',
    };
  }

  static getSurveyEntryDetail(entryId: string, cycleId: string): Endpoint<EntryDetail> {
    return {
      url: `/apiv2/survey-entry/${entryId}/${cycleId}/detail`,
    };
  }
}
