import { Box, IconButton } from '@mui/material';

import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { ReactComponent as Next } from '@/images/side-bar-icons/NextBtn.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';

export const BidirectionalButton = ({
  navigateBack,
  navigateNext,
  buttonTitle,
  disableNext,
  disableBack,
}: {
  readonly navigateBack: () => void;
  readonly navigateNext: () => void;
  readonly buttonTitle: string;
  readonly disableNext?: boolean;
  readonly disableBack?: boolean;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: themeColors.Background,
        color: themeColors.DarkGrey,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: radius.br8,
      }}
    >
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={navigateBack}
        title="Previous"
        sx={iconCTAButtonSx}
        disabled={disableBack}
      >
        <Back {...iconSize} />
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px' }}>
        <Typography variant="caption">{buttonTitle}</Typography>
      </Box>
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={navigateNext}
        title="Next"
        sx={iconCTAButtonSx}
        disabled={disableNext}
      >
        <Next {...iconSize} />
      </IconButton>
    </Box>
  );
};
