import { useCallback, useContext, useMemo, useState } from 'react';

import { GlobalContext } from '@/GlobalState';
import { useEventSource } from '@/hooks/event-source-hook';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReviewCycleEndpoints } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ParticipantsRollingView } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/rolling/participants-rolling-view.component';
import { ParticipantsInvitePeersSettingModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-invite-peers-setting-modal.component';
import { ParticipantsScheduledView } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-scheduled-view.component';
import { TitleStatusComponent } from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { ReachType, ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState, CycleType } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { PushEvent } from '@/v2/infrastructure/push-events/push-events.interface';
import { eventSourceErrorHandler } from '@/v2/infrastructure/push-events/push-events.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface ReviewCycleCreationParticipantsPageProps {
  readonly cycleId: string;
  readonly reach: ReachType;
}

export const RCUpsertParticipantsPage = ({ cycleId, reach }: ReviewCycleCreationParticipantsPageProps) => {
  const { data: reviewCycle, mutate: refreshCycle } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, reach),
    {
      suspense: false,
    }
  );

  const {
    data: reviewParticipants,
    mutate: refreshReviewParticipants,
    isLoading: reviewParticipantsLoading,
  } = useApiClient(ReviewCycleEndpoints.getReviewParticipantsByCycleId(cycleId, reach), {
    suspense: false,
  });

  const [isInvitePeerOpen, setInvitePeerOpen] = useState<boolean>(false);

  const refresh = useCallback(async () => {
    await refreshReviewParticipants?.();
    await refreshCycle?.();
  }, [refreshCycle, refreshReviewParticipants]);

  const [state] = useContext(GlobalContext);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const environmentPrefix = `${isProduction ? 'prod' : 'test'}`;
  const { user } = state;
  const isAdmin = canAccessScopes(user, ['reviews:all']);
  const isManager = canAccessScopes(user, ['reviews:manager']);
  const channelName = `review_cycle-${environmentPrefix}-company_id-${companyId}`;

  const reviewSurveyParticipantsUpdatedDataHandler = (data: PushEvent) => {
    if (data.message && data.companyId === companyId) {
      refresh();
      return;
    }
  };

  useEventSource(ReviewCycleEndpoints.getEventSourceUrlForAddRevieweesInCycle(channelName)?.url, {
    onMessage: reviewSurveyParticipantsUpdatedDataHandler,
    onError: eventSourceErrorHandler,
  });
  useEventSource(ReviewCycleEndpoints.getEventSourceUrlForAddReviewersInCycle(channelName)?.url, {
    onMessage: reviewSurveyParticipantsUpdatedDataHandler,
    onError: eventSourceErrorHandler,
  });
  const isEditable = useMemo(
    () =>
      Boolean(
        reviewCycle &&
          (reviewCycle.state === CycleState.Draft ||
            reviewCycle.state === CycleState.Scheduled ||
            reviewCycle.state === CycleState.Ongoing) &&
          Boolean(isAdmin || (isManager && reviewCycle.owner === user.userId))
      ),
    [reviewCycle, isAdmin, isManager, user]
  );

  if (!reviewCycle) return <></>;

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent reviewCycle={reviewCycle} reachType={reach} />}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      noHorizontalPadding={false}
      loading={reviewParticipantsLoading}
    >
      {Boolean(reviewCycle && reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer)) && (
        <SettingsSubsectionContent
          sections={[
            {
              hideEdit: reviewCycle?.state === CycleState.Completed || reviewCycle?.state === CycleState.Ongoing,
              editButtonDisabled:
                reviewCycle?.state === CycleState.Completed || reviewCycle?.state === CycleState.Ongoing,
              onEdit: () => setInvitePeerOpen(true),
              hidden: !Boolean(reviewCycle && reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer)),
              title: 'Peer invite',
              items: [
                {
                  type: SectionItemType.Pair,
                  label: 'Allow participants to invite peers',
                  value: reviewCycle?.cycleSettings?.allowRevieweesToInvitePeers ? 'Yes' : 'No',
                },
              ],
            },
          ]}
        />
      )}

      {reviewCycle && reviewCycle.type === CycleType.Scheduled && (
        <ParticipantsScheduledView
          reviewCycle={reviewCycle}
          isEditable={isEditable}
          refresh={refresh}
          reviewParticipants={reviewParticipants}
          reviewParticipantsLoading={reviewParticipantsLoading}
          reach={reach}
        />
      )}
      {reviewCycle && reviewCycle.type === CycleType.Rolling && (
        <ParticipantsRollingView
          reviewCycle={reviewCycle}
          isEditable={isEditable}
          refresh={refresh}
          reviewParticipants={reviewParticipants}
          reviewParticipantsLoading={reviewParticipantsLoading}
          reach={reach}
        />
      )}

      <ParticipantsInvitePeersSettingModal
        reviewCycle={reviewCycle}
        isOpen={isInvitePeerOpen}
        setIsOpen={setInvitePeerOpen}
        onClose={() => setInvitePeerOpen(false)}
        refresh={refresh}
      />
    </SettingsSectionContent>
  );
};
