import { useMemo } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { MultipleSelectCheckbox } from '@/v2/components/forms/multiple-select-checkbox.component';
import {
  DietaryOption,
  getDietaryOptions,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

/**
 * Comma-separated list of DietaryOption
 */
type DietaryOptionList = string;

export interface DietaryRestrictionsSelectProps {
  disabled?: boolean;
  name: string;
  onChange: (e: React.SyntheticEvent, value: DietaryOptionList) => void;
  value?: DietaryOptionList | null;
  required?: boolean;
}

export const DietaryRestrictionsSelect = ({
  disabled,
  name,
  value,
  onChange,
  required,
}: DietaryRestrictionsSelectProps) => {
  const { polyglot } = usePolyglot();

  const selectedValues = useMemo(() => {
    if (!value) return [];
    const dietaryValues = value?.trim().split(/\s*,\s*/) as DietaryOption[];
    return getDietaryOptions(polyglot).filter((option) => dietaryValues.includes(option.value));
  }, [value, polyglot]);

  return (
    <MultipleSelectCheckbox<DietaryOption>
      label={polyglot.t('DietaryRestrictionsSelect.label')}
      id={name}
      disabled={disabled}
      limitTags={-1}
      options={getDietaryOptions(polyglot)}
      value={selectedValues}
      onChange={(e, values) => {
        const updatedOptionList = values.map(({ value }) => value).join(',');
        onChange(e, updatedOptionList);
      }}
      isOptionEqualToValue={(x, y) => x.value === y.value}
      getOptionLabel={({ label }: { label: string }): string => label}
      required={required}
    />
  );
};
