import { useCallback, useMemo, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import { TaxYear } from '@shared/modules/payroll/payroll.types';
import { PayrunReopenConfirmationDrawer } from '@v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-reopen-confirmation-drawer.component';
import { CompanyPayrollType } from '@v2/feature/payroll/payroll.interface';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as ReOpenIcon } from '@/images/fields/Settings.svg';
import { ReactComponent as DeleteIcon } from '@/images/side-bar-icons/Delete.svg';
import { nestErrorMessage } from '@/lib/errors';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { GlobalPayrollAPI } from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { PayrollLocalApi } from '@/v2/feature/payroll/payroll-local.api';

const iconSize = { width: 14, height: 14 } as const;

type Props = {
  payrollType: CompanyPayrollType;
  canDeletePayrun?: boolean;
  canReopenPayrun?: boolean;
  onReopenPayrun?: () => Promise<void>;
  onDeletePayrun?: () => void;
  sx?: SxProps<Theme>;
};

export const PayrunHeaderMenu = ({
  canDeletePayrun,
  canReopenPayrun,
  onReopenPayrun,
  onDeletePayrun,
  payrollType,
  sx,
}: Props) => {
  const [confirmReopen, setConfirmReopen] = useState(false);
  const params = useParams<{ payrollId: string; taxYear: string; payPeriod: string; periodNumber: string }>();
  const payrollId = Number(params.payrollId);
  const taxYear = params.taxYear as TaxYear;
  const payPeriod = params.payPeriod as 'Monthly' | 'Weekly';
  const period = Number(params.periodNumber);
  const [showMessage] = useMessage();

  const deletePayrun = useCallback(async () => {
    try {
      await {
        [CompanyPayrollType.UKPayroll]: () => PayrollLocalApi.deletePayRun(payrollId, taxYear, payPeriod, period),
        [CompanyPayrollType.GlobalPayroll]: () => GlobalPayrollAPI.deletePayrun(payrollId, taxYear, payPeriod, period),
      }[payrollType]();
      setConfirmReopen(false);
      if (onDeletePayrun) onDeletePayrun();
    } catch (error) {
      showMessage(`Could not delete payrun. ${nestErrorMessage(error)}`, 'error');
    }
  }, [onDeletePayrun, payPeriod, payrollId, payrollType, period, showMessage, taxYear]);

  const reopenPayrun = useCallback(async () => {
    try {
      await {
        [CompanyPayrollType.UKPayroll]: () => PayrollLocalApi.reopenPayRun(payrollId, taxYear, payPeriod, period),
        [CompanyPayrollType.GlobalPayroll]: () => GlobalPayrollAPI.reopenPayrun(payrollId, taxYear, payPeriod, period),
      }[payrollType]();
      setConfirmReopen(false);
      if (onReopenPayrun) await onReopenPayrun();
    } catch (error) {
      showMessage(`Could not reopen payrun. ${nestErrorMessage(error)}`, 'error');
    }
  }, [onReopenPayrun, payPeriod, payrollId, payrollType, period, showMessage, taxYear]);

  const payrunMenuOptions = useMemo(
    () => [
      {
        icon: <ReOpenIcon {...iconSize} />,
        handler: () => {
          setConfirmReopen(true);
        },
        label: 'Reopen payrun',
        disabled: !canReopenPayrun,
      },
      {
        icon: <DeleteIcon {...iconSize} />,
        handler: async () => {
          await deletePayrun();
        },
        label: 'Delete payrun',
        disabled: !canDeletePayrun,
      },
    ],
    [canDeletePayrun, canReopenPayrun, deletePayrun]
  );

  return (
    <>
      <StyledMenuComponent
        options={payrunMenuOptions}
        actionButtonDetails={{
          type: 'iconButton',
          colorVariant: 'secondary',
          sizeVariant: 'small',
          title: 'actions',
          icon: <ActionsSmall {...iconSize} />,
        }}
        sx={sx}
      />
      <PayrunReopenConfirmationDrawer
        payrollType={payrollType}
        isOpen={confirmReopen}
        setIsOpen={setConfirmReopen}
        onConfirm={reopenPayrun}
      />
    </>
  );
};
