import { useCallback, useMemo } from 'react';

import { Typography } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { SiteEndpoints } from '@/api-client/site.api';
import {
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_JOB_LEVELS_ROUTE,
  SETTINGS_JOB_POSITIONS_ROUTE,
  SETTINGS_SITES_ROUTE,
} from '@/lib/routes';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { JobLevelEndpoints } from '@/v2/feature/job-level/job-level.api';
import { JobPositionEndpoints } from '@/v2/feature/job-position/job-position.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';

export interface OrgStructureItem {
  name: string;
  count: number;
}

export const OrgStructureSettingPage = () => {
  const { data: departments, isLoading: loadingDepartments } = useApiClient(
    DepartmentEndpoints.getCompanyDepartments(),
    { suspense: false }
  );

  const { data: siteData, isLoading: loadingSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });

  const { data: jobPositions, isLoading: loadingJobPositions } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false,
  });

  const { data: jobLevelCount, isLoading: loadingJobLevels } = useApiClient(JobLevelEndpoints.countJobLevel(), {
    suspense: false,
  });

  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  const columnData = useMemo<ColumnDef<OrgStructureItem, OrgStructureItem>[]>(() => {
    return [
      {
        header: () => 'Name',
        accessorFn: (row) => row,
        id: 'dependants',
        enableSorting: false,
        maxSize: 160,
        minSize: 160,
        cell: (orgStructure) => {
          const setting = orgStructure.getValue();
          return orgStructure && setting && setting?.name ? (
            <Typography sx={themeFonts.caption}>{setting?.name}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Count',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        maxSize: 100,
        minSize: 100,
        cell: (orgStructure) => {
          const setting = orgStructure.getValue();
          return orgStructure && setting && setting?.count ? (
            <Typography sx={themeFonts.caption}>{setting?.count}</Typography>
          ) : (
            <EmptyCell />
          );
        },
      },
    ];
  }, []);

  const handleRowClicked = useCallback(
    (row: Row<OrgStructureItem>) => {
      if (row?.original?.name.includes('Department')) routerHistory.push(generatePath(SETTINGS_DEPARTMENTS_ROUTE));
      else if (row?.original?.name.includes('Sites')) routerHistory.push(generatePath(SETTINGS_SITES_ROUTE));
      else if (row?.original?.name.includes('positions'))
        routerHistory.push(generatePath(SETTINGS_JOB_POSITIONS_ROUTE));
      else if (row?.original?.name.includes('levels')) routerHistory.push(generatePath(SETTINGS_JOB_LEVELS_ROUTE));
    },
    [routerHistory]
  );

  const tableData = useMemo(() => {
    const dataForTable = [];
    if (departments?.length && departments?.length > 0) {
      dataForTable.push({ name: 'Departments', count: departments?.length });
    }
    if (siteData?.length && siteData?.length > 0) {
      dataForTable.push({ name: 'Sites', count: siteData?.length });
    }
    if (jobPositions?.length && jobPositions?.length > 0) {
      dataForTable.push({ name: 'Job positions', count: jobPositions?.length });
    }
    if (jobLevelCount) {
      dataForTable.push({ name: 'Job levels', count: jobLevelCount.count });
    }
    return dataForTable;
  }, [departments?.length, jobPositions?.length, siteData?.length, jobLevelCount]);

  return (
    <ContentWrapper loading={loadingDepartments || loadingSites || loadingJobPositions || loadingJobLevels}>
      <SettingsSectionContent title={polyglot.t('SettingsRouterPageConfig.orgStructure')} contentWidth="100%">
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <BasicTable
                      rowData={tableData}
                      columnData={columnData}
                      loading={loadingJobPositions || loadingDepartments || loadingSites}
                      hidePagination
                      rowClick={handleRowClicked}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};
