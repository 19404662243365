import { forwardRef, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { ReactComponent as SmallHinge } from '@/images/side-bar-icons/smallHinge.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const textTitleSx: SxProps<Theme> = {
  ...themeFonts.captionSmall,
  color: themeColors.white,
};

interface Props {
  title: JSX.Element | string;
  position: 'bottom' | 'top' | 'right';
  children?: JSX.Element;
  width?: string;
  minWidth?: string;
}

// this is not a default tooltip
const TooltipComponent = ({ title, position, width, minWidth }: Props) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: width || '100%',
        minWidth: minWidth || '200px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: position === 'bottom' ? 'column-reverse' : position === 'top' ? 'column' : 'row-reverse',
      }}
    >
      <Box
        sx={{
          bgcolor: themeColors.DarkGrey,
          borderRadius: '8px',
        }}
      >
        <Box sx={{ paddingX: spacing.p8, paddingY: spacing.p5, ...themeFonts.captionSmall, color: themeColors.white }}>
          {title}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          transform:
            position === 'bottom'
              ? 'translate3d(5%, 0%, 0px) rotate(0deg)'
              : position === 'top'
              ? 'translate(-90%, 0%) rotate(180deg)'
              : 'translate(0%, 20%) rotate(0deg) ',
          display: 'flex',
        }}
      >
        <SmallHinge />
      </Box>
    </Box>
  );
};
export const TooltipPopper = forwardRef<unknown, Props>(
  ({ title, position, children, width = '', minWidth = '' }, ref): JSX.Element => {
    const [show, setShow] = useState(false);
    const positionedTooltip = () => {
      switch (position) {
        case 'bottom':
          return { top: '100%' };

        case 'top':
          return { bottom: '100%' };

        case 'right':
          return { top: '0%', left: '100%' };
        default:
          <></>;
      }
    };
    return (
      <Box ref={ref} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} sx={{ cursor: 'pointer' }}>
        <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
          {children}
          <div
            role="tooltip"
            style={{
              position: 'absolute',
              zIndex: '999999',
              inset: '0px auto auto 0px',
              ...positionedTooltip(),
              visibility: show ? 'visible' : 'hidden',
            }}
          >
            <TooltipComponent title={title} position={position} width={width} minWidth={minWidth} />
          </div>
        </Box>
      </Box>
    );
  }
);
