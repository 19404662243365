import { AppIntegrationStub } from '@v2/feature/app-integration/app-integration.interface';

export interface RequestDto {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  companyId?: number;
  requestedFor?: number;
  requestedBy?: number;
  requestType?: string;
  requestInfo: AppRequestInfo;
  status?: RequestStatus;
  createdBy?: number;
  updatedBy?: number;
}

export interface RequestAlertDto {
  id: number;
  companyId: number;
  status: RequestStatus;
}

export interface AppRequestInfo {
  appStub: AppIntegrationStub;
  error?: string;
  attemptsMade?: number;
}

export enum RequestStatus {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  Pending = 'Pending',
  Completed = 'Completed',
  Failed = 'Failed',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
}
