import { PayrunExternalAccountingDataDto } from '@v2/feature/payroll/payroll.dto';
import { FormattedAccountJournalLine } from '@v2/feature/payroll/payroll.interface';

export const formatAccountingJournal = (journal: PayrunExternalAccountingDataDto): FormattedAccountJournalLine[] => {
  const formattedJournal: FormattedAccountJournalLine[] = [];
  if (!journal?.JournalLines) return formattedJournal;

  for (const line of journal.JournalLines) {
    formattedJournal.push({
      Narration: journal.Narration,
      Date: journal.Date,
      Description: line.Description,
      AccountCode: line.AccountCode ?? '',
      TaxRate: 'NoVAT',
      Amount: line.LineAmount,
    });
  }

  return formattedJournal;
};
