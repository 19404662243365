import { SUPER_ADMIN_HELPER_ROUTE } from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { matchPath } from 'react-router-dom';

import { ReactComponent as Settings } from '@/images/fields/Settings.svg';
import { ReactComponent as IDBig } from '@/images/onboarding-step-icons/IDBig.svg';
import { ReactComponent as Analytyics } from '@/images/side-bar-icons/Analytics.svg';
import { ReactComponent as AnalytyicsFull } from '@/images/side-bar-icons/AnalyticsFull.svg';
import { ReactComponent as Apps } from '@/images/side-bar-icons/Apps.svg';
import { ReactComponent as AppsFull } from '@/images/side-bar-icons/AppsFull.svg';
import { ReactComponent as Payment } from '@/images/side-bar-icons/Bill.svg';
import { ReactComponent as PaymentFull } from '@/images/side-bar-icons/BillFull.svg';
import { ReactComponent as Coin } from '@/images/side-bar-icons/Coin.svg';
import { ReactComponent as Device } from '@/images/side-bar-icons/Device.svg';
import { ReactComponent as DeviceFull } from '@/images/side-bar-icons/DeviceFull.svg';
import { ReactComponent as Pension } from '@/images/side-bar-icons/Pension.svg';
import { ReactComponent as PensionFull } from '@/images/side-bar-icons/PensionFull.svg';
import { ReactComponent as People } from '@/images/side-bar-icons/People.svg';
import { ReactComponent as PeopleFull } from '@/images/side-bar-icons/PeopleFull.svg';
import { ReactComponent as Report } from '@/images/side-bar-icons/Report.svg';
import { ReactComponent as ReportFull } from '@/images/side-bar-icons/ReportFull.svg';
import { ReactComponent as Star } from '@/images/side-bar-icons/Star.svg';
import { ReactComponent as StarFull } from '@/images/side-bar-icons/StarFull.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { ReactComponent as WaitingFull } from '@/images/side-bar-icons/WaitingFull.svg';
import {
  DASHBOARD_ROUTE,
  SUPER_ADMIN_APPS_ROUTE,
  SUPER_ADMIN_BILLING_ROUTE,
  SUPER_ADMIN_PARTNER_API_ROUTE,
  SUPER_ADMIN_BILLING_V2_ROUTE,
  SUPER_ADMIN_COMPANIES,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_ID_CHECKS_ROUTE,
  SUPER_ADMIN_INSURANCE_ROUTE,
  SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE,
  SUPER_ADMIN_MDM_MIGRATION_ROUTE,
  SUPER_ADMIN_MONITORING_ROUTE,
  SUPER_ADMIN_REFINANCING_ROUTE,
  SUPER_ADMIN_SALARY_ROUTE,
  SUPER_ADMIN_STATISTICS_ROUTE,
  SUPER_ADMIN_USERS,
} from '@/lib/routes';
import { NavConfig } from '@/v2/feature/app-layout/features/sidebar/components/navigation-section.component';
import { themeColors } from '@/v2/styles/colors.styles';

const iconSize = { width: 14, height: 14 } as const;

export const getAdminSidebarConfig = (): readonly NavConfig[] => {
  return [
    {
      subheader: '',
      items: [
        {
          title: '',
          path: [
            SUPER_ADMIN_USERS,
            SUPER_ADMIN_COMPANIES,
            SUPER_ADMIN_DEVICES_ROUTE,
            SUPER_ADMIN_APPS_ROUTE,
            SUPER_ADMIN_BILLING_ROUTE,
            SUPER_ADMIN_BILLING_V2_ROUTE,
            SUPER_ADMIN_STATISTICS_ROUTE,
            SUPER_ADMIN_MONITORING_ROUTE,
            SUPER_ADMIN_PARTNER_API_ROUTE,
          ],
          isHidden: false,
          children: [
            {
              title: 'Users',
              path: SUPER_ADMIN_USERS,
              icon: <People {...iconSize} />,
              iconActive: <PeopleFull {...iconSize} />,
            },
            {
              title: 'Companies',
              path: SUPER_ADMIN_COMPANIES,
              icon: <Pension {...iconSize} />,
              iconActive: <PensionFull {...iconSize} />,
            },
            {
              title: 'Devices',
              path: SUPER_ADMIN_DEVICES_ROUTE,
              icon: <Device {...iconSize} />,
              iconActive: <DeviceFull {...iconSize} />,
            },
            {
              title: 'Apps',
              path: SUPER_ADMIN_APPS_ROUTE,
              icon: <Apps {...iconSize} />,
              iconActive: <AppsFull {...iconSize} />,
            },
            {
              title: 'Billing',
              path: SUPER_ADMIN_BILLING_ROUTE,
              icon: <Payment {...iconSize} />,
              iconActive: <PaymentFull {...iconSize} />,
            },
            {
              title: 'Billing V2',
              path: SUPER_ADMIN_BILLING_V2_ROUTE,
              icon: <Payment {...iconSize} />,
              iconActive: <PaymentFull {...iconSize} />,
            },
            {
              title: 'Statistics',
              path: SUPER_ADMIN_STATISTICS_ROUTE,
              icon: <Analytyics {...iconSize} />,
              iconActive: <AnalytyicsFull {...iconSize} />,
            },
            {
              title: 'LinkedIn data',
              path: SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE,
              icon: <People {...iconSize} />,
              iconActive: <PeopleFull {...iconSize} />,
            },
            {
              title: 'Monitoring',
              path: SUPER_ADMIN_MONITORING_ROUTE,
              icon: <WaitingFull {...iconSize} />,
              iconActive: <Waiting {...iconSize} />,
            },
            {
              title: 'Id checks',
              path: SUPER_ADMIN_ID_CHECKS_ROUTE,
              icon: <IDBig {...iconSize} style={{ fill: themeColors.middleGrey }} />,
              iconActive: <IDBig {...iconSize} />,
            },
            {
              title: 'Partner API',
              path: SUPER_ADMIN_PARTNER_API_ROUTE,
              icon: <Report {...iconSize} />,
              iconActive: <ReportFull {...iconSize} />,
            },
            {
              title: 'Salary',
              path: SUPER_ADMIN_SALARY_ROUTE,
              icon: <Pension {...iconSize} />,
              iconActive: <Pension {...iconSize} />,
            },
            {
              title: 'Insurance',
              path: SUPER_ADMIN_INSURANCE_ROUTE,
              icon: <Star {...iconSize} />,
              iconActive: <StarFull {...iconSize} />,
            },
            {
              title: 'Refinancing',
              path: SUPER_ADMIN_REFINANCING_ROUTE,
              icon: <Coin {...iconSize} />,
              iconActive: <Coin {...iconSize} />,
            },
            {
              title: 'Helper board',
              path: SUPER_ADMIN_HELPER_ROUTE,
              icon: <Settings {...iconSize} />,
              iconActive: <Settings {...iconSize} />,
            },
            {
              title: 'MDM Migration',
              path: SUPER_ADMIN_MDM_MIGRATION_ROUTE,
              icon: <Device {...iconSize} />,
              iconActive: <DeviceFull {...iconSize} />,
            },
          ],
        },
      ],
    },
  ];
};

export function getActiveSub(currentPathname: string, itemPath: string): boolean {
  if (itemPath === DASHBOARD_ROUTE) return currentPathname === DASHBOARD_ROUTE;

  if (currentPathname !== DASHBOARD_ROUTE)
    return !!matchPath(itemPath, currentPathname) || currentPathname.startsWith(itemPath);

  return false;
}
