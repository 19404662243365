import { Box } from '@mui/material';

import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';

export const PayrollTableSkeleton = ({ includeMargin = true, includeGutter = false, rows = 10, delay = 400 }) => {
  return (
    <Box
      sx={{
        marginTop: includeMargin ? 9 : 0,
        marginBottom: includeGutter ? 4 : 0,
      }}
    >
      <SkeletonLoader variant="rectangular" width="100%" height="40px" rows={rows} delay={delay} />
    </Box>
  );
};
