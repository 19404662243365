import React, { useMemo } from 'react';

import { ChipSelect } from '@v2/components/table/chip-select.component';
import { AbsenceYearSelectOption } from '@v2/feature/absence/absence.dto';

interface NumericYearSelectChipProps {
  readonly year: number;
  readonly setYear: React.Dispatch<React.SetStateAction<number>>;
  readonly options: AbsenceYearSelectOption<number>[] | null | undefined;
}

export const NumericYearSelectChip = ({ year, setYear, options }: NumericYearSelectChipProps) => {
  return (
    <ChipSelect
      title={options?.find((o) => o.year === year)?.label ?? String(year)}
      options={(options ?? [{ label: String(year), year }]).map((p) => ({
        label: p.label,
        handler: () => setYear(p.year),
      }))}
    />
  );
};

interface YearSelectChipProps {
  readonly year: 'current' | 'last' | 'next';
  readonly setYear: React.Dispatch<React.SetStateAction<'current' | 'last' | 'next'>>;
}

export const LabelYearSelectChip = ({ year, setYear }: YearSelectChipProps) => {
  const labelOptions = useMemo(
    () => [
      { label: 'Last cycle', handler: () => setYear('last'), value: 'last' },
      { label: 'Current cycle', handler: () => setYear('current'), value: 'current' },
      { label: 'Next cycle', handler: () => setYear('next'), value: 'next' },
    ],
    [setYear]
  );

  return <ChipSelect title={labelOptions?.find((o) => o.value === year)?.label ?? year} options={labelOptions} />;
};
