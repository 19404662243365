import Polyglot from 'node-polyglot';

import { OptionObj } from '@/v2/components/forms/user-select/single-user-select.component';

export enum UserSelectFiltersOptions {
  None = '',
  Everyone = 'everyone',
  SelectSpecific = 'select-specific',
  CustomRule = 'custom-rule',
}

export enum CustomRuleOptions {
  Site = 'site',
  Department = 'department',
  Entity = 'entity',
}

export const getCustomRuleOptionsList = (polyglot: Polyglot): OptionObj[] => {
  return [
    { value: 'site', label: polyglot.t('getCustomRuleOptionsList.site') },
    { value: 'department', label: polyglot.t('getCustomRuleOptionsList.department') },
    { value: 'entity', label: polyglot.t('getCustomRuleOptionsList.entity') },
  ];
};

export const getUserSelectOptionLabel = (option: UserSelectFiltersOptions, polyglot: Polyglot): string => {
  if (option === UserSelectFiltersOptions.None) return polyglot.t('UserSelect.none');

  if (option === UserSelectFiltersOptions.SelectSpecific) return polyglot.t('UserSelect.specific');

  if (option === UserSelectFiltersOptions.CustomRule) return polyglot.t('UserSelect.customRule');

  return polyglot.t('UserSelect.everyone');
};
