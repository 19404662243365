import { Box } from '@mui/material';
import { TaskStatuses } from '@v2/feature/task/task.interface';
import { isPast } from 'date-fns';
import Polyglot from 'node-polyglot';

import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { TaskAndChecklistItemCommon } from '@/v2/feature/task/components/task-view/task-view-modal.component';
import { TaskDto } from '@/v2/feature/task/task.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const getTaskStatus = (taskObject: TaskDto | TaskAndChecklistItemCommon, polyglot: Polyglot) => {
  const { status, dueDate } = taskObject;
  const isTaskOverdue = dueDate ? isPast(new Date(dueDate)) : false;
  if (status === TaskStatuses.INCOMPLETE)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        {!isTaskOverdue && (
          <>
            <Waiting {...iconSize} style={{ fill: themeColors.middleGrey }} />{' '}
            <Typography variant="caption">{polyglot.t('getTaskStatus.toDo')}</Typography>
          </>
        )}
        {isTaskOverdue && (
          <>
            <Waiting {...iconSize} style={{ fill: themeColors.Red }} />
            <Typography variant="caption" sx={{ color: themeColors.Red }}>
              {polyglot.t('getTaskStatus.overdue')}
            </Typography>
          </>
        )}
      </Box>
    );
  else if (status === TaskStatuses.COMPLETE) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
        <Typography variant="caption">{polyglot.t('getTaskStatus.done')}</Typography>
      </Box>
    );
  }
};
