import { useCallback, useContext, useMemo, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { CompanyPayroll, UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';
import { ColumnDef } from '@tanstack/react-table';
import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import { PayRunDto, PayRunEntryDto } from '@v2/feature/payroll/payroll.dto';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { UserPayrollStatusCell } from '@/v2/feature/payroll/components/user-payroll-status-cell.component';
import { formatUserPayrollStatus } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { EditPayrollRecordDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component';
import { EmployeeActionsCell } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/employee-actions-column.component';
import { PayrollMissingInformationDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/payroll-missing-information.component';
import { ViewUserPayrollDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/view-user-payroll-drawer.component';
import {
  canAddToPayroll,
  canRemoveFromPayroll,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/payroll-company-employees.util';
import { getUserStatusFromPayrunEntry } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { SalarySummaryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/salary-summary-drawer.component';
import {
  CurrencyWithDiff,
  ValueWithDiff,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component';
import { PayrollLocalEndpoints } from '@/v2/feature/payroll/payroll-local.api';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { ApiError } from '@/v2/infrastructure/api-error/api-error.interface';
import { getApiErrorMessage } from '@/v2/infrastructure/api-error/api-error.util';
import { isSameCountryCode } from '@/v2/infrastructure/country/country.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { filterByTextSearch, sum } from '@/v2/util/array.util';

type DraftPayrunUser = {
  userId: number;
  payrunEntry: PayRunEntryDto | null;
  payrollUser: UserPayrollForTableDto | null;
};

type DraftPayrunTableProps = {
  payroll: CompanyPayroll;
  disabled?: boolean;
  entries: PayRunEntryDto[];
  localPayRun: PayRunDto;
  previousEntries: PayRunEntryDto[];
  searchQuery?: string;
  sx?: SxProps<Theme>;
  refreshPayroll: () => Promise<void>;
};

export const DraftPayrunTable = ({
  payroll,
  localPayRun,
  entries,
  previousEntries,
  searchQuery,
  sx,
  disabled,
  refreshPayroll,
}: DraftPayrunTableProps) => {
  const { polyglot } = usePolyglot();
  const [_globalState, dispatch] = useContext(GlobalContext);
  // const { user: currentUser } = globalState;
  const { getCachedUserById } = useCachedUsers();

  const [showMessage] = useMessage();
  const [drawer, setDrawer] = useState<{ userId: number; mode: 'edit' | 'view' | 'missing-info' } | null>(null);

  const [usersBeingUpdated, setUsersBeingUpdated] = useState(new Set<number>());
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const {
    data: rawPayrollList,
    mutate: refreshPayrollUsers,
    isValidating: loadingPayrollUsers,
  } = useApiClient(PayrollLocalEndpoints.getUserPayrollMembershipList(), { suspense: false });
  const employeeList = useMemo(() => rawPayrollList?.all ?? [], [rawPayrollList?.all]);

  const [salarySummary, setSalarySummary] = useState<{ userId: number; payrunEntry: PayRunEntryDto }>();
  const { data: customPayCodes } = useApiClient(PayrollLocalEndpoints.getPayrunPayCodes(localPayRun.id), {
    suspense: false,
  });

  const drawerRecord = useMemo(() => employeeList.find((e) => e.userId === drawer?.userId), [
    drawer?.userId,
    employeeList,
  ]);

  const refreshPayrollState = useCallback(async () => {
    await Promise.all([refreshPayroll(), refreshPayrollUsers?.()]);
  }, [refreshPayroll, refreshPayrollUsers]);

  const getUserDisplayName = useCallback(
    (userId: number) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );

  const markUserUpdating = useCallback((userId: number, isUpdating: boolean) => {
    setUsersBeingUpdated((userIds) => {
      const newUpdatingUserIds = new Set(userIds);
      if (isUpdating) newUpdatingUserIds.add(userId);
      else newUpdatingUserIds.delete(userId);
      return newUpdatingUserIds;
    });
  }, []);

  const contractCountryMatchesUserPayrollCountry = useCallback((record: UserPayrollForTableDto) => {
    return isSameCountryCode(record.payrollJurisdiction ?? 'GB', record.userPayroll?.countryCode ?? 'GB');
  }, []);

  const refreshBillingRestrictions = useCallback(async () => {
    // this is used to refresh the billing restrictions when payroll users are added/removed
    const response = await AuthAPI.getAuthMe(false);
    const authUser = response?.user ?? null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser,
    });
  }, [dispatch]);

  const addToPayroll = useCallback(
    async (userId: number): Promise<boolean> => {
      // OLD RESTRICTION
      // if (currentUser?.restrictions?.MONEY?.disablePayroll) {
      //   setUpgradeModalOpen(true);
      //   return false;
      // }
      let userAddedToPayroll = false;
      const name = getUserDisplayName(userId);
      markUserUpdating(userId, true);
      try {
        userAddedToPayroll = (await PayrollAPI.addUsersToPayroll(payroll.id, [userId]), true);
        showMessage(polyglot.t('PayrunTable.nameHasBeenAddedToPayroll', { name }), 'success');
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('PayrunTable.nameCouldNotBeAddedToPayroll', {
              name,
              reason: getApiErrorMessage(error as ApiError),
            }),
            'warning'
          );
        }
      } finally {
        markUserUpdating(userId, false);
      }
      refreshPayrollState?.();
      refreshBillingRestrictions();
      return userAddedToPayroll;
    },
    [
      getUserDisplayName,
      markUserUpdating,
      refreshBillingRestrictions,
      refreshPayrollState,
      showMessage,
      polyglot,
      payroll.id,
    ]
  );

  const removeFromPayroll = useCallback(
    async (userId: number): Promise<boolean> => {
      let userRemovedFromPayroll = false;
      const name = getCachedUserById(userId)?.displayName || `User ${userId}`;
      markUserUpdating(userId, true);
      try {
        await PayrollAPI.removeUsersFromPayroll(payroll.id, [userId]);
        userRemovedFromPayroll = true;
        showMessage(polyglot.t('PayrunTable.nameHasBeenRemovedFromPayroll', { name }), 'success');
      } catch (error) {
        showMessage(
          polyglot.t('PayrunTable.nameCouldNotBeRemovedFromPayroll', {
            name,
            reason: getApiErrorMessage(error as ApiError),
          }),
          'warning'
        );
      } finally {
        markUserUpdating(userId, false);
      }
      refreshPayrollState?.();
      refreshBillingRestrictions();
      return userRemovedFromPayroll;
    },
    [
      getCachedUserById,
      markUserUpdating,
      refreshPayrollState,
      refreshBillingRestrictions,
      payroll.id,
      showMessage,
      polyglot,
    ]
  );

  const rows = useMemo(() => {
    const result = new Map<number, DraftPayrunUser>();
    // add all the users with payrun entries
    for (const payrunEntry of entries) {
      const { userId } = payrunEntry;
      result.set(userId, { userId, payrunEntry, payrollUser: null });
    }
    // add any remaining payroll users who are in the same company entity
    for (const payrollUser of employeeList) {
      const { userId, entityId, user } = payrollUser;
      const paySchedule = payrollUser.userCompensation?.paySchedule;
      const payrunUser = result.get(userId);
      if (payrunUser) {
        result.set(userId, { ...payrunUser, payrollUser });
        continue;
      }
      // exclude users from other company entities
      if (entityId !== payroll.entity.id) continue;
      // exclude terminated users
      if (user.status === 'Terminated') continue;
      // exclude users who start after this payrun
      if (user.startDate && user.startDate > localPayRun.endDate) continue;
      // exclude users whose compensation paySchedule is different from the payroll payPeriod
      if (paySchedule && paySchedule !== localPayRun.payPeriod) continue;
      result.set(userId, { userId, payrunEntry: null, payrollUser });
    }

    return [...result.values()].sort((a, b) =>
      getUserDisplayName(a.userId).localeCompare(getUserDisplayName(b.userId), polyglot.locale(), {
        sensitivity: 'base',
      })
    );
  }, [entries, payroll.entity.id, employeeList, getUserDisplayName, localPayRun, polyglot]);

  const filteredRows = useMemo(() => {
    return filterByTextSearch(searchQuery, rows, (user) => [getUserDisplayName(user.userId)]);
  }, [getUserDisplayName, searchQuery, rows]);

  const [summaryTotals, previousSummaryTotals] = useMemo(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => ({
      additions: sum(entries, (e) => e.totals.additions),
      deductions: sum(entries, (e) => e.totals.deductions),
      takeHomePay: sum(entries, (e) => e.totals.takeHomePay),
      totalCost: sum(entries, (e) => e.totals.totalCost),
    });
    return [
      calculateTotals(entries),
      previousEntries.length
        ? calculateTotals(previousEntries)
        : ({} as Record<keyof ReturnType<typeof calculateTotals>, undefined>),
    ];
  }, [entries, previousEntries]);

  const columnData = useMemo<ColumnDef<DraftPayrunUser, DraftPayrunUser>[]>(() => {
    const previousById = new Map<number, PayRunEntryDto>(previousEntries.map((item) => [item.userId, item]));
    return [
      {
        id: 'employee',
        header: () => polyglot.t('PayrunTable.employee'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => <UserCell userId={c.row.original.userId} />,
        footer: () => polyglot.t('PayrunTable.total'),
        size: 100,
      },
      {
        id: 'income',
        header: () => polyglot.t('PayrunTable.income'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.payrunEntry?.totals.additions),
        cell: (c) => {
          if (!c.row.original.payrunEntry) return null;
          const currency = c.row.original.payrollUser?.userCompensation?.currency ?? DEFAULT_CURRENCY;

          return (
            <ValueWithDiff
              current={c.row.original.payrunEntry}
              previous={previousById.get(c.row.original.userId)}
              getValue={(item) => item.totals.additions}
              currency={currency}
            />
          );
        },
        footer: () => (
          <CurrencyWithDiff
            currentValue={summaryTotals.additions}
            previousValue={previousSummaryTotals.additions}
            currency={DEFAULT_CURRENCY}
          />
        ),
        size: 60,
      },
      {
        id: 'deductions',
        header: () => polyglot.t('PayrunTable.deductions'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.payrunEntry?.totals.deductions),
        cell: (c) => {
          if (!c.row.original.payrunEntry) return null;
          const currency = c.row.original.payrollUser?.userCompensation?.currency ?? DEFAULT_CURRENCY;

          return (
            <ValueWithDiff
              current={c.row.original.payrunEntry}
              previous={previousById.get(c.row.original.userId)}
              getValue={(item) => item.totals.deductions}
              currency={currency}
            />
          );
        },
        footer: () => (
          <CurrencyWithDiff
            currentValue={summaryTotals.deductions}
            previousValue={previousSummaryTotals.deductions}
            currency={DEFAULT_CURRENCY}
          />
        ),
        size: 80,
      },
      {
        id: 'take-home',
        header: () => polyglot.t('PayrunTable.takeHomePay'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.payrunEntry?.totals.takeHomePay),
        cell: (c) => {
          if (!c.row.original.payrunEntry) return null;
          const currency = c.row.original.payrollUser?.userCompensation?.currency ?? DEFAULT_CURRENCY;

          return (
            <ValueWithDiff
              current={c.row.original.payrunEntry}
              previous={previousById.get(c.row.original.userId)}
              getValue={(item) => item.totals.takeHomePay}
              currency={currency}
            />
          );
        },
        footer: () => (
          <CurrencyWithDiff
            currentValue={summaryTotals.takeHomePay}
            previousValue={previousSummaryTotals.takeHomePay}
            currency={DEFAULT_CURRENCY}
          />
        ),
        size: 100,
      },
      {
        id: 'employer-cost',
        header: () => polyglot.t('PayrunTable.employerCost'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.payrunEntry?.totals.totalCost),
        cell: (c) => {
          if (!c.row.original.payrunEntry) return null;
          const currency = c.row.original.payrollUser?.userCompensation?.currency ?? DEFAULT_CURRENCY;

          return (
            <ValueWithDiff
              current={c.row.original.payrunEntry}
              previous={previousById.get(c.row.original.userId)}
              getValue={(item) => item.totals.totalCost}
              currency={currency}
            />
          );
        },
        footer: () => (
          <CurrencyWithDiff
            currentValue={summaryTotals.totalCost}
            previousValue={previousSummaryTotals.totalCost}
            currency={DEFAULT_CURRENCY}
          />
        ),
        size: 100,
      },
      {
        id: 'status',
        header: () => polyglot.t('PayrunTable.status'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(
            a,
            b,
            (item) =>
              item.payrunEntry &&
              formatUserPayrollStatus(getUserStatusFromPayrunEntry(item.payrunEntry).label, polyglot)
          ),
        cell: (c) => (
          <UserPayrollStatusCell
            status={getUserStatusFromPayrunEntry(c.row.original.payrunEntry ?? 'not-in-payroll')}
          />
        ),
        size: 60,
      },
      {
        header: () => '',
        id: 'actions',
        // maxSize: 70,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const { payrollUser } = original;
          if (!payrollUser) return <></>;
          return (
            <EmployeeActionsCell
              user={payrollUser}
              payroll={payroll}
              openDrawer={(mode, { userId }) => setDrawer({ userId, mode })}
              disabled={disabled}
              updating={usersBeingUpdated.has(original.userId)}
              addToPayroll={addToPayroll}
              removeFromPayroll={removeFromPayroll}
            />
          );
        },
      },
    ];
  }, [
    previousEntries,
    getUserDisplayName,
    summaryTotals,
    previousSummaryTotals,
    payroll,
    disabled,
    usersBeingUpdated,
    addToPayroll,
    removeFromPayroll,
    polyglot,
  ]);

  return (
    <>
      <Box sx={sx}>
        <BasicTable
          loading={loadingPayrollUsers && !rawPayrollList}
          rowData={filteredRows}
          columnData={columnData}
          hidePagination
          maxUnpaginatedRows={500}
          showFooter
          rowClick={(row) => {
            const { userId, payrunEntry } = row.original;
            if (usersBeingUpdated.has(userId)) {
              return;
            }
            if (payrunEntry) {
              setSalarySummary({ userId, payrunEntry });
            }
          }}
        />
        <SalarySummaryDrawer
          userId={salarySummary?.userId}
          payrunEntry={salarySummary?.payrunEntry}
          onClose={() => setSalarySummary(undefined)}
          customPayCodes={customPayCodes ?? []}
        />
      </Box>
      <PayrollMissingInformationDrawer
        isOpen={drawer?.mode === 'missing-info'}
        close={() => setDrawer(null)}
        payrollRecord={drawerRecord}
        refreshPayroll={refreshPayrollState}
      />
      <EditPayrollRecordDrawer
        isOpen={drawer?.mode === 'edit'}
        close={() => setDrawer(null)}
        payrollRecord={
          (drawerRecord && contractCountryMatchesUserPayrollCountry(drawerRecord) && drawerRecord.userPayroll) || null
        }
        mode={
          drawerRecord?.userPayroll && contractCountryMatchesUserPayrollCountry(drawerRecord) ? 'append' : 'initial'
        }
        userId={drawer?.userId}
        onUpdateStarted={() => !!drawer && markUserUpdating(drawer.userId, true)}
        onUpdateFinished={(success) => {
          !!drawer && markUserUpdating(drawer.userId, false);
          if (success) refreshPayrollState?.();
        }}
      />
      <ViewUserPayrollDrawer
        isOpen={drawer?.mode === 'view'}
        close={(options?: { switchToEdit: boolean }) => {
          if (options?.switchToEdit && drawer) {
            setDrawer({ userId: drawer.userId, mode: 'edit' });
            return;
          }
          setDrawer(null);
        }}
        onClose={() => drawer?.mode === 'view' && setDrawer(null)}
        record={drawerRecord || null}
        isUserUpdating={!!drawer && usersBeingUpdated.has(drawer.userId)}
        addToPayroll={
          drawerRecord && canAddToPayroll(drawerRecord, payroll)
            ? (record) => addToPayroll(record.user.userId)
            : undefined
        }
        removeFromPayroll={
          drawerRecord && canRemoveFromPayroll(drawerRecord, payroll)
            ? (record) => removeFromPayroll(record.user.userId)
            : undefined
        }
        canEdit
      />
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.MONEY_PRO}
        messageSuffix="proGeneric"
      />
    </>
  );
};
