import React, { useCallback } from 'react';

import { Box } from '@mui/material';

import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { getSelectProps } from '@/v2/styles/menu.styles';

interface CurrencyTextFieldProps {
  valueName: string;
  label: string;
  value: number | string | null;
  onValueChange: (value: number | string | null) => void;
  valueType?: 'text' | 'number';
  unit: string;
  unitName: string;
  onUnitChange: (value: string) => void;
  unitOptions: readonly OptionObject[];
  error?: boolean;
  helperText?: string | false;
  disableUnitSelector?: boolean;
}

export const TextFieldAndUnit: React.FC<CurrencyTextFieldProps> = ({
  label,
  value,
  valueName,
  onValueChange,
  valueType = 'number',
  unit,
  unitName,
  onUnitChange,
  unitOptions,
  error,
  helperText,
  disableUnitSelector = false,
  ...props
}) => {
  const handleUnitChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      onUnitChange(event.target.value as string);
    },
    [onUnitChange]
  );

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange]
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'flex-end',
          width: '100%',
          boxSizing: 'border-box',
          p: 0,
          m: 0,
        }}
      >
        <Box sx={{ flex: '1 1 auto', boxSizing: 'border-box', p: 0, m: 0 }}>
          <TextfieldComponent
            {...props}
            fullWidth
            value={value === 0 ? '' : value}
            variant="standard"
            type={valueType}
            onChange={handleValueChange}
            label={label}
            name={valueName}
            error={error}
            endAdornment="none"
            sx={{
              '& div.MuiInputBase-root.MuiInput-root': {
                borderBottomRightRadius: '0px !important',
                borderTopRightRadius: '0px !important',
              },
            }}
          />
        </Box>
        <Box sx={{ flex: '0 0 75px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            disabled={disableUnitSelector}
            value={unit}
            onChange={handleUnitChange}
            sx={{
              borderLeft: `1px solid ${themeColors.GreyMiddle}`,
              boxSizing: 'border-box',
              '& div.MuiInputBase-root.MuiInput-root': {
                borderBottomLeftRadius: '0px !important',
                borderTopLeftRadius: '0px !important',
              },
            }}
            variant="standard"
            error={error}
            size="small"
            SelectProps={getSelectProps}
            options={unitOptions}
            name={unitName}
          />
        </Box>
      </Box>
      {error && helperText && (
        <Typography variant="captionSmall" color="RedDark">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
