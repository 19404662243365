import { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { UserPayrollForTableDto } from '@shared/modules/payroll/payroll.types';

import { ReactComponent as MistakeGray } from '@/images/side-bar-icons/MistakeGray.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserHeader } from '@/v2/components/user-header.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { isUKCountryCode } from '@/v2/infrastructure/country/country.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

const ItemDetail = ({
  name,
  value,
  uppercase,
  capitalize,
}: {
  name: string;
  value: string | number | null | undefined;
  uppercase?: boolean;
  capitalize?: boolean;
}) => (
  <Stack direction={'row'} justifyContent={'space-between'}>
    <span style={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{name}</span>
    <span
      style={{ ...themeFonts.title4, textTransform: uppercase ? 'uppercase' : capitalize ? 'capitalize' : undefined }}
    >
      {value ?? <MistakeGray />}
    </span>
  </Stack>
);

interface Props {
  readonly user: CachedUser;
  readonly record: UserPayrollForTableDto;
  readonly canEdit?: boolean;
  readonly addToPayroll?: (record: UserPayrollForTableDto) => Promise<boolean>;
  readonly removeFromPayroll?: (record: UserPayrollForTableDto) => Promise<boolean>;
  readonly close: (options?: { switchToEdit: boolean }) => void;
  readonly isUserUpdating: boolean;
}

export const ViewUserPayrollPage = ({
  user,
  record,
  canEdit,
  addToPayroll,
  removeFromPayroll,
  isUserUpdating,
  close,
}: Props) => {
  const [updatingMode, setUpdatingMode] = useState<'add' | 'remove'>();

  const booleanToValue = (value: boolean | undefined, t = 'Yes', f = 'No') => {
    if (typeof value === 'undefined') return undefined;
    return value ? t : f;
  };

  const slPlanToValue = (value: string | undefined) => {
    switch (value) {
      case 'PlanOne':
        return 'Plan 1';
      case 'PlanTwo':
        return 'Plan 2';
      case 'PlanFour':
        return 'Plan 4';
      default:
        return value;
    }
  };

  const payrollValues = record.userPayroll?.payrollValues;

  return (
    <Stack flex={1}>
      <UserHeader user={user} />

      <Typography sx={{ ...themeFonts.title2, mt: '30px', color: themeColors.DarkGrey }}>Payroll</Typography>

      {record.userPayroll?.countryCode && !isUKCountryCode(record.userPayroll.countryCode) ? (
        <Typography sx={{ ...themeFonts.caption, mt: '20px', color: themeColors.DarkGrey }}>
          User is in a non-UK payroll jurisdiction.
        </Typography>
      ) : (
        <Stack sx={{ gap: '16px', mt: '14px' }}>
          <ItemDetail name="Payroll ID" value={record.userPayroll?.payrollId} />
          <ItemDetail name="Tax Code" value={payrollValues?.taxCode} uppercase />
          <ItemDetail
            name="Calculation method"
            value={booleanToValue(payrollValues?.week1Month1, 'Month 1', 'Cumulative')}
          />
          <ItemDetail name="Starter Declaration" value={payrollValues?.starterDeclaration} uppercase />
          <ItemDetail name="NI Category" value={payrollValues?.niTable} uppercase />
          <ItemDetail name="NI Number" value={payrollValues?.niNumber} uppercase />
          <ItemDetail name="Student Loan" value={slPlanToValue(payrollValues?.studentLoan)} />
          <ItemDetail name="Postgraduate Loan" value={booleanToValue(payrollValues?.postgradLoan)} />
          <ItemDetail name="Legal gender" value={payrollValues?.legalGender} capitalize />
          <ItemDetail
            name="Directorship"
            value={booleanToValue(
              payrollValues?.isDirector,
              `${payrollValues?.directorFrom} - ${payrollValues?.directorTo || 'present'}`,
              'None'
            )}
          />
        </Stack>
      )}

      <Stack direction={'row'} sx={{ m: '30px auto 0', gap: '10px' }}>
        {canEdit && (
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="secondary"
            style={{ width: '175px', maxWidth: '40vw' }}
            onClick={() => close({ switchToEdit: true })}
            disabled={isUserUpdating}
          >
            Edit
          </ButtonComponent>
        )}
        {addToPayroll && (
          <LoaderButton
            name="Add to payroll"
            loading={updatingMode === 'add'}
            style={{ width: '175px', maxWidth: '40vw' }}
            colorVariant="primary"
            sizeVariant="medium"
            onClick={async () => {
              setUpdatingMode('add');
              if (await addToPayroll(record)) close();
              else setUpdatingMode(undefined);
            }}
            disabled={isUserUpdating}
          />
        )}
        {removeFromPayroll && (
          <LoaderButton
            name="Remove from payroll"
            loading={updatingMode === 'remove'}
            colorVariant="primary"
            sizeVariant="medium"
            style={{ width: '175px', maxWidth: '40vw' }}
            onClick={async () => {
              setUpdatingMode('remove');
              if (await removeFromPayroll(record)) close();
              else setUpdatingMode(undefined);
            }}
            disabled={isUserUpdating}
          />
        )}
      </Stack>
    </Stack>
  );
};
