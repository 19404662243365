import { Box, Typography } from '@mui/material';
import { ExternalUserCredentialsDto } from '@v2/feature/device/device.dto';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

interface DeviceActivationSectionProps {
  readonly credentials: ExternalUserCredentialsDto | undefined;
}

export const DeviceActivationSection = ({ credentials }: DeviceActivationSectionProps) => {
  return credentials ? (
    <Box>
      <Typography sx={titleSx}>Device Activation</Typography>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
        Please use the credentials below to access your device for the first time. You will also need this password to
        re-enroll the device, for example when you wipe it.
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5, mt: spacing.mt20 }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Username:</Typography>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{credentials.username}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5, mb: spacing.mb20 }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Password:</Typography>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{credentials.password}</Typography>
      </Box>

      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
        You can always retrieve activation details in Device Actions.
      </Typography>
    </Box>
  ) : (
    <Box>
      <Typography sx={titleSx}>Device Activation</Typography>
      <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
        Could not find the device activation details.
      </Typography>
    </Box>
  );
};
