import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, IconButton, Input, Stack, SxProps, Theme } from '@mui/material';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import { themeFonts } from '@v2/styles/fonts.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { StyledTooltip, StyledTooltipProps } from '@/v2/components/theme-components/styled-tooltip.component';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey, useKeypress } from '@/v2/util/keyboard-hook.util';

export const iconSize = { width: 14, height: 14 } as const;

type EditableTitleProps = {
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
  value: string;
  maxLength?: number;
  editButtonTooltip?: Omit<StyledTooltipProps, 'children'>;
  variant: TypographyVariant;
};

export const EditableTitle = ({
  variant,
  sx = {},
  onChange,
  maxLength,
  value,
  editButtonTooltip,
}: EditableTitleProps) => {
  const [editing, setEditing] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const editRef = useRef<HTMLInputElement>(null);
  const finishEdit = useCallback(() => {
    setEditing(false);
    const newValue = editedValue.trim();
    if (!newValue || newValue === value) {
      setEditedValue(newValue);
      return;
    }
    onChange?.(newValue);
  }, [editedValue, onChange, value]);
  useEscapeKey(finishEdit);
  useKeypress('Enter', finishEdit);

  useEffect(() => {
    setEditButtonVisible(!!editButtonTooltip?.open);
  }, [editButtonTooltip]);

  return editing ? (
    <Box sx={{ width: '100%' }}>
      <Input
        fullWidth
        ref={editRef}
        sx={{
          ...(themeFonts[variant] ?? {}),
          ...sx,
          border: 'none',
          outline: 'none',
          padding: '0px',
          '&::before': { display: 'none' },
          '&::after': { display: 'none' },
        }}
        onChange={(e) => setEditedValue(e.target.value)}
        onBlur={finishEdit}
        value={editedValue}
        autoFocus
        inputProps={{
          maxLength,
        }}
      />
    </Box>
  ) : (
    <Stack
      onPointerEnter={() => setEditButtonVisible(true)}
      onPointerLeave={() => setEditButtonVisible(false)}
      sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10, justifyContent: 'space-between' }}
    >
      <Typography variant={variant} sx={sx}>
        {value}
      </Typography>
      <StyledTooltip {...editButtonTooltip}>
        <IconButton
          sx={{ ...iconButtonSx, opacity: editButtonVisible || editButtonTooltip?.open ? 1 : 0 }}
          onClick={() => setEditing(true)}
        >
          <Edit {...iconSize} />
        </IconButton>
      </StyledTooltip>
    </Stack>
  );
};
