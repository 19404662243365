import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { parentCardSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NotiBig } from '@/images/onboarding-intro/NotiBig.svg';
import { ReactComponent as SmileBig } from '@/images/payments-intro/SmileBig.svg';
import { ReactComponent as WalletBig } from '@/images/pension/WalletBig.svg';
import { ReactComponent as MultiBig } from '@/images/reports/MultiBig.svg';
import { SETTINGS_TIME_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

export const AbsenceEmpty = () => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box sx={{ ...parentCardSx, height: '100%' }}>
        <Typography sx={themeFonts.caption}>{polyglot.t('AbsenceEmpty.track')}</Typography>

        <Box sx={{ mt: spacing.g30 }}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            onClick={() => history.push(SETTINGS_TIME_ROUTE)}
          >
            {polyglot.t('AbsenceEmpty.starts')}
          </ButtonComponent>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          gap: spacing.g40,
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: spacing.g40 }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: spacing.m15 }}>
              <MultiBig />
            </Box>
            <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>
              {polyglot.t('AbsenceEmpty.configurable')}
            </Typography>
            <Typography sx={themeFonts.caption}>{polyglot.t('AbsenceEmpty.custom')}</Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: spacing.m15 }}>
              <NotiBig />
            </Box>
            <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>
              {polyglot.t('AbsenceEmpty.seamlessApproval')}
            </Typography>
            <Typography sx={themeFonts.caption}>{polyglot.t('AbsenceEmpty.setNotification')}</Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: spacing.g40 }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: spacing.m15 }}>
              <WalletBig />
            </Box>
            <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>
              {polyglot.t('AbsenceEmpty.payrollIntegration')}
            </Typography>
            <Typography sx={themeFonts.caption}>T{polyglot.t('AbsenceEmpty.timeOffInfo')}</Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: spacing.m15 }}>
              <SmileBig />
            </Box>
            <Typography sx={{ ...themeFonts.title2, mb: spacing.m10 }}>
              {polyglot.t('AbsenceEmpty.wellbeing')}
            </Typography>
            <Typography sx={themeFonts.caption}>{polyglot.t('AbsenceEmpty.spotoverWorked')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
