import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface IGeneralScheduledReopenModal {
  readonly reviewCycle: ReviewCycle | undefined;
  readonly isOpen: boolean;
  readonly refresh: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}
export const GeneralScheduledReopenModal = ({
  reviewCycle,
  isOpen,
  refresh,
  setIsOpen,
  onClose,
}: IGeneralScheduledReopenModal) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <GeneralScheduledReopenContent reviewCycle={reviewCycle} onClose={onClose} refresh={refresh} />
    </DrawerModal>
  );
};

interface IGeneralScheduledReopenContent {
  readonly reviewCycle: ReviewCycle | undefined;
  readonly onClose: () => void;
  readonly refresh: () => Promise<void>;
}

const GeneralScheduledReopenContent = ({ reviewCycle, onClose, refresh }: IGeneralScheduledReopenContent) => {
  const [showMessage] = useMessage();
  const handleReopenCycle = async () => {
    try {
      if (!reviewCycle) {
        showMessage('Cycle does not exist', 'error');
        return;
      }
      await ReviewCycleAPI.reopenCycle(reviewCycle.id);
      showMessage('Successfully reopened the cycle', 'success');
      refresh?.();
      onClose();
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    }
  };

  if (!reviewCycle) return <>Not found</>;

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Reopen cycle</Typography>

      <Typography variant="caption" color="Grey">
        Are you sure you want to reopen this review cycle?You will be able to edit participants, timeline settings and
        reminders. Shared results will remain shared.
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent onClick={onClose} sizeVariant="medium" colorVariant="secondary" fullWidth>
          Cancel
        </ButtonComponent>
        <ButtonComponent onClick={handleReopenCycle} sizeVariant="medium" colorVariant="primary" fullWidth>
          Reopen
        </ButtonComponent>
      </Box>
    </Box>
  );
};
