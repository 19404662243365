import React, { useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Stack, capitalize } from '@mui/material';
import {
  ChecklistItemDto,
  CreateChecklistItemDto,
  RelativeAssignmentOptions,
  RelativeAssignmentValues,
  RequestedForRelativeAssignmentOptions,
} from '@v2/feature/task/subfeature/checklist/checklist-item.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { actionIconSize } from '@v2/styles/table.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import { DepartmentEndpoints } from '@/api-client/company-department.api';
import { CompanyEndpoints } from '@/api-client/company.api';
import { SiteEndpoints } from '@/api-client/site.api';
import { ReactComponent as QuestionCircle } from '@/images/app-icons/QuestionCircle.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import {
  AttachmentComponent,
  attachmentComponentValueToString,
  stringToAttachmentComponentValue,
} from '@/v2/components/forms/attachment.component';
import { AutocompleteComponent } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { OptionObj, SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { getCustomRuleOptionsList } from '@/v2/components/user-select-type/user-select.interface';
import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { getUnitTypeOptions } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { buttonBoxSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT = (polyglot: Polyglot) =>
  polyglot.t('ChecklistItemFormModal.taskForSomebodyHelper');

interface TaskModalProperties {
  onClose: Function;
  formData: ChecklistItemDto | null;
  action: Function;
  deleteAction: (taskId: number) => Promise<void>;
}

const mapOptions = (
  polyglot: Polyglot,
  items: Array<{ [key: string]: any }>,
  ids: number[],
  labelKey: string,
  valueKey: string
): OptionObj[] => {
  const filteredItems = items
    .filter((item) => ids.includes(item[valueKey] as number))
    .map((item) => ({ label: polyglot.t(item[labelKey]), value: item[valueKey] }));

  return filteredItems;
};

const createEntityArrayFromCustomRule = (
  polyglot: Polyglot,
  customRule: string,
  generalSettings: GeneralSettingsDto | null | undefined,
  sites: SiteDto[] | null | undefined,
  departments: CompanyDepartmentDto[] | null | undefined
) => {
  if (!customRule) return [];

  const [key, idString] = customRule.split('=');
  const ids = idString
    .split(',')
    .map((id) => parseInt(id))
    .filter((id) => !isNaN(id));

  switch (key) {
    case 'department':
      return mapOptions(polyglot, departments ?? [], ids, 'name', 'id');
    case 'site':
      return mapOptions(polyglot, sites ?? [], ids, 'name', 'id');
    case 'entity':
      return mapOptions(polyglot, generalSettings?.entities ?? [], ids, 'legalName', 'id');
    default:
      console.error(`Unknown key '${key}' in customRule`);
      return [];
  }
};

export const ChecklistItemFormModal = ({
  onClose,
  formData,
  action,
  deleteAction,
}: TaskModalProperties): React.ReactElement => {
  const { polyglot } = usePolyglot();

  const { nonTerminatedCachedUsers } = useCachedUsers();
  const OFFSET_DURATION_HELPER_TEXT = polyglot.t('ChecklistItemFormModal.offsetDurationHelper');

  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false,
  });
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [assignedToSelectedValue, setAssignedToSelectedValue] = useState<string | ''>(
    formData?.assignedToCustomRule?.split('=')[0] ?? ''
  );

  const [assignedToCustomRule, setAssignedToCustomRule] = useState<string>(formData?.assignedToCustomRule ?? '');

  const [assignedToOption, setAssignedToOption] = useState<OptionObj[]>([]);

  useEffect(() => {
    if (assignedToCustomRule) {
      const optionArray = createEntityArrayFromCustomRule(
        polyglot,
        assignedToCustomRule,
        generalSettings,
        sites,
        departments
      );
      setAssignedToOption(optionArray);
    }
  }, [polyglot, assignedToCustomRule, generalSettings, sites, departments]);

  const users = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => {
      return { label: u.displayName, value: u.userId, ...u };
    });
  }, [nonTerminatedCachedUsers]);

  const assignedToOptions = useMemo(() => {
    if (assignedToSelectedValue === 'department') {
      return departments?.map((d) => ({ label: polyglot.t(d.name), value: d.id }));
    }
    if (assignedToSelectedValue === 'site') {
      return sites?.map((s) => ({ label: polyglot.t(s.name), value: s.id }));
    }
    if (assignedToSelectedValue === 'entity') {
      return generalSettings?.entities?.map((e) => ({ label: polyglot.t(e.legalName), value: e.id }));
    }
    return [];
  }, [polyglot, departments, generalSettings?.entities, assignedToSelectedValue, sites]);

  const handleSubmit = async (values: CreateChecklistItemDto) => {
    const {
      assignedUserId,
      requestedForId,
      dueDate,
      assignedToPlaceholder,
      requestedForPlaceholder,
      userAssignWithPlaceholder,
      dueDateOffsetDuration,
      dueDateOffsetUnit,
      taskForSomebodyElse,
      attachmentValues,
      assignedUserIds,
      assignedToCustomRule,
    } = values;
    const newValues = {
      ...formData,
      ...values,
      dueDate: dueDate ?? null,
      assignedToPlaceholder,
      requestedForPlaceholder: taskForSomebodyElse ? requestedForPlaceholder : '',
      userAssignWithPlaceholder,
      dueDateOffsetDuration,
      dueDateOffsetUnit,
      attachmentValues: attachmentValues ?? null,
      assignedUserId: assignedUserId ? Number(assignedUserId) : null,
      assignedUserIds: assignedUserIds ?? null,
      requestedForId: requestedForId ? Number(requestedForId) : undefined,
      assignedToCustomRule: assignedToCustomRule ?? null,
    };
    setLoading(true);
    await action(newValues);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
    onClose();
    setLoading(false);
  };

  const userOptions = useMemo(() => {
    if (formData?.assignedUserId) return [formData?.assignedUserId];
    return [];
  }, [formData?.assignedUserId]);

  const formik = useFormik({
    initialValues: {
      name: formData?.name ?? '',
      description: formData?.description ?? '',
      assignedUserId: formData?.assignedUserId,
      assignedUserIds: userOptions ?? [],
      assignedToPlaceholder: formData?.assignedUserId
        ? RelativeAssignmentValues.someoneElse
        : formData?.assignedToPlaceholder ?? RelativeAssignmentValues.checklistAssignee,
      requestedForId: formData?.requestedForId,
      requestedForPlaceholder: formData?.requestedForId
        ? RelativeAssignmentValues.someoneElse
        : formData?.requestedForPlaceholder ?? RelativeAssignmentValues.checklistAssignee,
      dueDateOffsetDuration: formData?.dueDateOffsetDuration ?? 0,
      dueDateOffsetUnit: formData?.dueDateOffsetUnit ?? 'Days',
      userAssignWithPlaceholder: formData?.assignedUserId ? false : true,
      taskForSomebodyElse: formData?.requestedForPlaceholder ? true : false,
      attachmentValues: formData?.attachmentValues ?? undefined,
      assignedToCustomRule: formData?.assignedToCustomRule,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(polyglot.t('ChecklistItemFormModal.errorMessages.nameRequired')),
      description: Yup.string().nullable().notRequired(),
      assignedToPlaceholder: Yup.string().required(
        polyglot.t('ChecklistItemFormModal.errorMessages.placeholderRequired')
      ),
      dueDateOffsetDuration: Yup.number().required(polyglot.t('ChecklistItemFormModal.errorMessages.dueDateRequired')),
      dueDateOffsetUnit: Yup.string().required(
        polyglot.t('ChecklistItemFormModal.errorMessages.dueDateOffsetRequired')
      ),
      attachmentValues: Yup.string().nullable().notRequired(),
    }),
    enableReinitialize: true,
    onSubmit: (values: CreateChecklistItemDto) => handleSubmit({ ...values, assignedToCustomRule }),
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: spacing.g10,
            }}
          >
            <Typography variant="title2">
              {formData?.id ? polyglot.t('ChecklistItemFormModal.edit') : polyglot.t('ChecklistItemFormModal.new')}
            </Typography>
            {formData?.id && (
              <IconButton
                sx={iconButtonSx}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
            )}
          </Box>
          <Box sx={fieldSx}>
            <TextfieldComponent
              name="name"
              label={polyglot.t('ChecklistItemFormModal.name')}
              value={formik.values.name}
              type="text"
              onChange={formik.handleChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={(formik.touched.name && formik.errors.name) ?? ' '}
              clearText={() => formik.setFieldValue('name', '')}
            />
          </Box>

          <Box sx={fieldSx}>
            <TextfieldComponent
              name="description"
              label={polyglot.t('ChecklistItemFormModal.description')}
              value={formik.values.description}
              type="text"
              multiline={true}
              onChange={formik.handleChange}
              error={formik.touched.description && !!formik.errors.description}
              helperText={(formik.touched.description && formik.errors.description) ?? ' '}
              clearText={() => formik.setFieldValue('description', '')}
            />
          </Box>

          <Box sx={fieldSx}>
            <AutocompleteComponent
              name="assignedToPlaceholder"
              label={polyglot.t('ChecklistItemFormModal.assignedToPlaceholder')}
              options={RelativeAssignmentOptions}
              value={RelativeAssignmentOptions.find((option) => option.value === formik.values.assignedToPlaceholder)}
              compareValue={formik.values.assignedToPlaceholder}
              // @ts-ignore
              onChange={(_, option: OptionObj) => {
                if (option) {
                  formik.setFieldValue('assignedToPlaceholder', option.value, true);
                  if (
                    option?.value === RelativeAssignmentValues.customRule ||
                    option?.value === RelativeAssignmentValues.everyone ||
                    option?.value === RelativeAssignmentValues.checklistAssignee ||
                    option?.value === RelativeAssignmentValues.manager
                  ) {
                    formik.setFieldValue('assignedUserIds', [], true);
                  }
                }
              }}
              error={!!formik.errors.assignedToPlaceholder && formik.touched.assignedToPlaceholder}
              helperText={formik.errors.assignedToPlaceholder && formik.touched.assignedToPlaceholder}
              // @ts-ignore
              getOptionLabel={(option) => option.label}
            />
          </Box>

          {formik.values.assignedToPlaceholder === RelativeAssignmentValues.customRule && (
            <>
              <Box sx={fieldSx}>
                <SelectComponent
                  label={polyglot.t('CustomUserModal.condition')}
                  name="condition"
                  options={getCustomRuleOptionsList(polyglot)}
                  value={assignedToSelectedValue}
                  onChange={(e) => {
                    setAssignedToSelectedValue(e.target.value);
                    setAssignedToOption([]);
                  }}
                />
              </Box>

              {assignedToSelectedValue && (
                <Box sx={fieldSx}>
                  <AutocompleteComponent
                    multiple
                    label={capitalize(assignedToSelectedValue)}
                    disableCloseOnSelect
                    name={assignedToSelectedValue}
                    filterSelectedOptions
                    value={assignedToOption}
                    compareValue={assignedToOption}
                    options={assignedToOptions ?? []}
                    onChange={(_, e) => {
                      const value = e as OptionObj[];
                      setAssignedToOption(value);
                      setAssignedToCustomRule(`${assignedToSelectedValue}=${value.map((v) => v.value).join(',')}`);
                    }}
                    isOptionEqualToValue={(x, y) => x.value === y.value}
                    renderTags={(options) => {
                      return options.map((o) => o.label).join(', ');
                    }}
                  />
                </Box>
              )}
            </>
          )}

          {formik.values.assignedToPlaceholder === RelativeAssignmentValues.someoneElse && (
            <Box sx={fieldSx}>
              <UserSelect
                label={polyglot.t('TaskFormModal.whoShouldComplete')}
                selectedLabel={polyglot.t('TaskFormModal.selectedEmployees')}
                value={formik.values.assignedUserIds ?? []}
                onChange={(userIds: number[]) => {
                  formik.setFieldValue('assignedUserIds', userIds);
                }}
                userOptions={users}
                fieldSx={{ mb: spacing.m10 }}
                excludeEveryone={true}
                excludeCustomRule={true}
              />
            </Box>
          )}

          <Box sx={{ mb: spacing.g5, display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
            <CheckboxComponent
              name="taskForSomebodyElse"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                  <Typography variant="caption">
                    {polyglot.t('ChecklistItemFormModal.taskCompletedForSomeoneElse')}
                  </Typography>
                  <StyledTooltip title={TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT(polyglot) as string}>
                    <QuestionCircle style={{ height: 12, width: 12 }} fill={themeColors.GreyMiddle} />
                  </StyledTooltip>
                </Box>
              }
              checked={formik.values.taskForSomebodyElse}
              onChange={(_, checked) => {
                formik.setFieldValue('taskForSomebodyElse', checked);
                if (!checked) formik.setFieldValue('requestedForPlaceholder', '');
              }}
            />
          </Box>

          {formik.values.taskForSomebodyElse && (
            <>
              <Box sx={fieldSx}>
                <AutocompleteComponent
                  name="requestedForPlaceholder"
                  label={polyglot.t('ChecklistItemFormModal.whoIsThisTaskFor')}
                  options={RequestedForRelativeAssignmentOptions}
                  value={RequestedForRelativeAssignmentOptions.find(
                    (option) => option.value === formik.values.requestedForPlaceholder
                  )}
                  compareValue={formik.values.requestedForPlaceholder}
                  onChange={(_, option) => {
                    // @ts-ignore
                    if (option) formik.setFieldValue('requestedForPlaceholder', option.value, true);
                  }}
                  error={!!formik.errors.requestedForPlaceholder && formik.touched.requestedForPlaceholder}
                  helperText={formik.errors.requestedForPlaceholder && formik.touched.requestedForPlaceholder}
                  // @ts-ignore
                  getOptionLabel={(option) => option.label}
                />
              </Box>

              {formik.values.requestedForPlaceholder === RelativeAssignmentValues.someoneElse && (
                <Box sx={fieldSx}>
                  <SingleUserSelect
                    name="userId"
                    options="company"
                    onChange={(_, x) => formik.setFieldValue('requestedForId', x?.value ?? null)}
                    value={formik.values.requestedForId}
                    label={polyglot.t('ChecklistItemFormModal.requestedForSomeoneElse')}
                    error={formik.touched.requestedForId && Boolean(formik.errors.requestedForId)}
                    helperText={formik.touched.requestedForId && (formik.errors.requestedForId as string)}
                  />
                </Box>
              )}
            </>
          )}

          <Stack sx={{ flexFlow: 'row', gap: spacing.g20 }}>
            <TextfieldComponent
              sx={{ width: '50%' }}
              name="dueDateOffsetDuration"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                  <Typography variant="captionSmall">
                    {polyglot.t('ChecklistItemFormModal.dueDateOffsetDuration')}
                  </Typography>
                  <StyledTooltip title={OFFSET_DURATION_HELPER_TEXT}>
                    <QuestionCircle style={{ height: 10, width: 10 }} fill={themeColors.GreyMiddle} />
                  </StyledTooltip>
                </Box>
              }
              value={formik.values.dueDateOffsetDuration}
              type="number"
              onChange={formik.handleChange}
              error={formik.touched.dueDateOffsetDuration && !!formik.errors.dueDateOffsetDuration}
              helperText={(formik.touched.dueDateOffsetDuration && formik.errors.dueDateOffsetDuration) ?? ''}
              clearText={() => formik.setFieldValue('probation_period_length', 0)}
            />

            <SelectComponent
              sx={{ width: '50%' }}
              name="dueDateOffsetUnit"
              label={polyglot.t('ChecklistItemFormModal.dueDateOffsetUnit')}
              options={getUnitTypeOptions(polyglot)}
              value={formik.values.dueDateOffsetUnit}
              compareValue={formik.values.dueDateOffsetUnit}
              error={!!formik.errors.dueDateOffsetUnit && formik.touched.dueDateOffsetUnit}
              onChange={formik.handleChange}
              helperText={formik.errors.dueDateOffsetUnit && formik.touched.dueDateOffsetUnit}
            />
          </Stack>

          <Box sx={spacing.mt20}>
            <AttachmentComponent
              variant="caption"
              disabled={false}
              label="Add a file"
              name="attachmentValues"
              showSelectExisting={true}
              // all form values are stored as strings so we must convert to an object
              value={stringToAttachmentComponentValue(formik.values.attachmentValues ?? '')}
              onChange={(value) => {
                formik?.setFieldValue('attachmentValues', attachmentComponentValueToString(value));
              }}
              error={formik.touched.attachmentValues && Boolean(formik.errors.attachmentValues)}
              helperText={formik.touched.attachmentValues && (formik.errors.attachmentValues as string)}
            />
          </Box>

          <Box sx={buttonBoxSx}>
            {formData?.id && (
              <ButtonComponent fullWidth colorVariant="secondary" sizeVariant="medium" onClick={() => onClose()}>
                {polyglot.t('General.cancel')}
              </ButtonComponent>
            )}
            <LoaderButton
              name={polyglot.t('General.save')}
              sizeVariant="medium"
              colorVariant="primary"
              loading={loading}
              fullWidth={true}
              disabled={!formik.isValid || !formik.dirty || loading}
            />
          </Box>
        </Box>
        <NotificationModal
          isOpen={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          takeAction={() => {
            if (formData?.id) deleteAction(formData?.id);
            setAnchorEl(null);
            onClose();
          }}
          message={polyglot.t('ChecklistItemFormModal.confirmDelete')}
          callToAction={polyglot.t('General.yes')}
        />
      </Form>
    </FormikProvider>
  );
};
