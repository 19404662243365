import Check from '@mui/icons-material/Check';
import { SxProps, Theme } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { ThemeStepConnector, ThemeStepIconRoot, ThemeStepper } from '@/v2/styles/stepper.styles';

function ThemeStepIcon(props: { active?: boolean; completed?: boolean; className?: string }) {
  const { active, completed, className } = props;

  return (
    <ThemeStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className="ThemeStepIcon-completedIcon" /> : <div className="ThemeStepIcon-circle" />}
    </ThemeStepIconRoot>
  );
}

export type StepperComponentProps = {
  activeStep: string | number;
  onlyShowActiveLabel?: boolean;
  hideLabel?: boolean;
  hideConnector?: boolean;
  steps: readonly string[];
  sx?: SxProps<Theme>;
};

export const StepperComponent = ({
  activeStep,
  onlyShowActiveLabel,
  hideLabel,
  hideConnector,
  steps,
  sx,
}: StepperComponentProps) => {
  const activeStepIndex = typeof activeStep === 'string' ? steps.indexOf(activeStep) : activeStep;
  return hideConnector ? (
    <></>
  ) : (
    <ThemeStepper sx={sx} activeStep={activeStepIndex} connector={<ThemeStepConnector />}>
      {steps.map((label, idx) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ThemeStepIcon}>
            {hideLabel ? '' : !onlyShowActiveLabel || idx === activeStepIndex ? label : ''}
          </StepLabel>
        </Step>
      ))}
    </ThemeStepper>
  );
};
