import { useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DeletePensionDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/components/delete-pension-drawer.component';
import { EditPensionGeneralDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/components/edit-pension-general-drawer.component';
import {
  BenefitsSettingsPageSection,
  DescriptionLine,
} from '@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

interface PageProps {
  readonly type: string;
  readonly pensionScheme: PensionSchemeDto;
  readonly refreshPension: () => Promise<void>;
}

export const PensionSettingsGeneralPage = ({ type, pensionScheme, refreshPension }: PageProps) => {
  const { polyglot } = usePolyglot();

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  return (
    <BenefitsSettingsPageSection
      title={polyglot.t('General.general')}
      onEdit={() => {
        setIsEditOpen(true);
      }}
    >
      <DescriptionLine field={polyglot.t('General.type')} value={type} />
      <DescriptionLine field={polyglot.t('General.displayName')} value={pensionScheme.displayName} />
      {pensionScheme?.payroll?.entity?.legalName && (
        <DescriptionLine
          field={polyglot.t('PayrollModule.payrollEntity')}
          value={pensionScheme.payroll.entity.legalName}
        />
      )}

      <Box sx={{ mt: '30px' }}>
        <ButtonComponent
          sizeVariant="small"
          colorVariant="text"
          onClick={() => setIsDeleteOpen(true)}
          style={{ margin: 0, padding: 0, color: themeColors.RedDark }}
        >
          {polyglot.t('PensionModule.deletePension')}
        </ButtonComponent>
      </Box>

      <EditPensionGeneralDrawer
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        provider={pensionScheme}
        refresh={refreshPension}
      />

      <DeletePensionDrawer isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} provider={pensionScheme} />
    </BenefitsSettingsPageSection>
  );
};
