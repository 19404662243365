import { ColumnDef } from '@tanstack/react-table';
import { differenceInYears } from 'date-fns';

import { UserCustomDataDto } from '@/v2/feature/custom-fields/custom-profile-fields.dto';

export const isDataEmpty = (data: Record<string, unknown>, customUpdates: UserCustomDataDto[]) => {
  const emptyArray = (Object.keys(data) as Array<keyof typeof data>).filter((key) => {
    if (key === 'customUpdates') {
      return customUpdates.some((update) => update.value.length === 0);
    } else return data[key] === undefined || data[key] === null || data[key] === '';
  });

  return emptyArray.length === Object.keys(data).length;
};

export const buildColumnsForCustomFields = <T extends { customUpdates: UserCustomDataDto[] }>(
  data: readonly T[] | undefined,
  columnDefaults: Partial<ColumnDef<T, T>>
): ColumnDef<T, T>[] => {
  if (!data) return [];

  const fieldNames = new Set(data.flatMap((entry) => entry.customUpdates).map((u) => u.field.fieldName));

  return [...fieldNames].sort().map<ColumnDef<T, T>>((fieldName) => ({
    ...columnDefaults,
    header: () => fieldName,
    accessorFn: (row) => row,
    id: fieldName,
    enableSorting: false,
    cell: ({
      row: {
        original: { customUpdates },
      },
    }) => customUpdates.find((u) => u.field.fieldName === fieldName)?.value ?? null,
  }));
};

export function calculateAge(birthdate: Date) {
  const today = new Date();
  const age = differenceInYears(today, birthdate);
  return age;
}

// export function extractNameFromIdChecks(
//   checks: {
//     name: string;
//     status: string;
//   }[]
// ): string | null {
//   const nameRegex = /check for\s([\w\s]+)/;
//   for (const check of checks) {
//     const match = check.name.match(nameRegex);
//     if (match && match[1]) {
//       return match[1];
//     }
//   }
//   return null;
// }

// export function documentNameFromIdChecks(
//   checks: {
//     name: string;
//     status: string;
//   }[]
// ): string | null {
//   const subjectNameFromIdChecks = extractNameFromIdChecks(checks);
//   return subjectNameFromIdChecks ? `Identity Check - ${subjectNameFromIdChecks}` : null;
// }
