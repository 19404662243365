import React, { useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceScheduleEditGeneralDrawer } from '@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-general-drawer.component';
import { AttendanceScheduleDeleteConfirmationDrawer } from '@v2/feature/attendance/company/components/schedule-details/attendance-schedule-delete-confirmation-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Typography } from '@/v2/components/typography/typography.component';

interface SectionProps {
  readonly schedule: AttendanceScheduleDto;
  readonly refreshSchedules: () => Promise<void>;
  readonly refreshSchedule: () => Promise<void>;
}

export const AttendanceSettingsGeneralSection = ({ schedule, refreshSchedule, refreshSchedules }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);

  return (
    <SettingsSectionContent
      title={polyglot.t('General.general')}
      onEdit={() => {
        setEdit(true);
      }}
    >
      <SettingsSubsectionContent
        sections={[
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t('AttendanceScheduleGeneralSection.name'),
                value: schedule.name,
              },
            ],
          },
          {
            title: polyglot.t('AttendanceSchedule.deleteSchedule'),
            items: [
              {
                type: SectionItemType.TextLine,
                value: (
                  <Typography variant="caption">
                    {polyglot.t('AttendanceSchedule.deleteDescription')}
                    <span
                      onClick={() => {
                        setIsDeleteDrawerOpen(true);
                      }}
                      style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {polyglot.t('AttendanceSchedule.here')}
                    </span>
                  </Typography>
                ),
              },
            ],
          },
        ]}
      />

      <AttendanceScheduleEditGeneralDrawer
        isOpen={edit}
        setIsOpen={setEdit}
        attendanceSchedule={schedule}
        refresh={async () => {
          await Promise.all([refreshSchedules(), refreshSchedule()]);
        }}
      />

      <AttendanceScheduleDeleteConfirmationDrawer
        isOpen={isDeleteDrawerOpen}
        setIsOpen={setIsDeleteDrawerOpen}
        attendanceSchedule={schedule}
        refresh={refreshSchedules}
      />
    </SettingsSectionContent>
  );
};
