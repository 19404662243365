import React, { useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import { GooglePlacesWrapper } from './google-maps-autocomplete.component';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { BasicUser } from '@/models/company-department.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SiteWithMembersDto } from '@/v2/feature/site/site.dto';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface SiteManagementFormData {
  name: string;
  address: string;
  longitude: number | null;
  latitude: number | null;
  employees: BasicUser[];
}

interface IFormSiteModal {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedSite: SiteWithMembersDto | undefined;
  readonly refreshSites: () => Promise<void>;
  readonly closePage: () => void;
}

const SiteFormSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    name: Yup.string().required(polyglot.t('SiteManagementDrawerPage.errorMessages.siteNameRequired')),
    address: Yup.string().nullable().notRequired(),
    longitude: Yup.number()
      .nullable()
      .typeError(polyglot.t('SiteManagementDrawerPage.errorMessages.enterValidValue'))
      .notRequired(),
    latitude: Yup.number()
      .nullable()
      .typeError(polyglot.t('SiteManagementDrawerPage.errorMessages.enterValidValue'))
      .notRequired(),
  });

export const SiteFormModal = ({ isOpen, setIsOpen, selectedSite, refreshSites, closePage }: IFormSiteModal) => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={closePage}>
    <SiteFormModalContent selectedSite={selectedSite} refreshSites={refreshSites} closePage={closePage} />
  </DrawerModal>
);

const SiteFormModalContent = ({
  selectedSite,
  refreshSites,
  closePage,
}: {
  readonly selectedSite: SiteWithMembersDto | undefined;
  readonly refreshSites: () => Promise<void>;
  readonly closePage: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [siteToDelete, setSiteToDelete] = useState<number>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputManually, setInputManually] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const deleteSite = async () => {
    try {
      if (siteToDelete) await SiteAPI.deleteSite(siteToDelete);
      else throw new Error(polyglot.t('SiteManagementDrawerPage.errorMessages.noSitetoDelete'));
      showMessage(polyglot.t('SiteManagementDrawerPage.successMessages.deleteSite'), 'success');
      await refreshSites();
    } catch (error) {
      showMessage(
        `${polyglot.t('SiteManagementDrawerPage.errorMessages.deleteSite')}: ${nestErrorMessage(error)}`,
        'error'
      );
    }
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, siteId: number) => {
    if (event) setAnchorEl(event.currentTarget);
    setSiteToDelete(siteId);
    setIsConfirmModalOpen(true);
  };

  const formik = useFormik<SiteManagementFormData>({
    initialValues: {
      name: selectedSite?.name ?? '',
      address: selectedSite?.address ?? '',
      longitude: selectedSite?.coordinates?.longitude ?? null,
      latitude: selectedSite?.coordinates?.latitude ?? null,
      employees: selectedSite?.users ?? [],
    },
    validationSchema: SiteFormSchema(polyglot),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        if (selectedSite && selectedSite.id) {
          await SiteAPI.updateSite(selectedSite.id, {
            name: values.name,
            address: values.address ?? null,
            latitude: values.latitude ? Number(values.latitude) : null,
            longitude: values.longitude ? Number(values.longitude) : null,
          });
          showMessage(polyglot.t('SiteManagementDrawerPage.successMessages.updateSite'), 'success');
        } else {
          await SiteAPI.saveNewSite({
            name: values.name,
            address: values.address ?? null,
            latitude: values.latitude ? Number(values.latitude) : null,
            longitude: values.longitude ? Number(values.longitude) : null,
          });
          showMessage(polyglot.t('SiteManagementDrawerPage.successMessages.addedSite'), 'success');
        }
        closePage();
        await refreshSites();
      } catch (error) {
        showMessage(
          `Failed to ${selectedSite && selectedSite.id ? 'update' : 'add'} site: ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const { handleChange, setFieldValue, values, touched, errors } = formik;

  const renderAddressField = () => (
    <TextfieldComponent
      name="address"
      label={polyglot.t('SiteManagementDrawerPage.address')}
      value={values.address}
      type="text"
      onChange={handleChange}
      error={touched.address && !!errors.address}
      helperText={(touched.address && errors.address) ?? ' '}
      clearText={() => setFieldValue('address', '')}
    />
  );

  const renderCoordinatesFields = () => (
    <>
      <TextfieldComponent
        name="longitude"
        label={polyglot.t('SiteManagementDrawerPage.longitude')}
        value={values.longitude}
        onChange={handleChange}
        error={touched.longitude && !!errors.longitude}
        helperText={(touched.longitude && errors.longitude) ?? ' '}
      />
      <TextfieldComponent
        name="latitude"
        label={polyglot.t('SiteManagementDrawerPage.latitude')}
        value={values.latitude}
        onChange={handleChange}
        error={touched.latitude && !!errors.latitude}
        helperText={(touched.latitude && errors.latitude) ?? ' '}
      />
    </>
  );

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="title2">
            {selectedSite
              ? polyglot.t('SiteManagementDrawerPage.editSite')
              : polyglot.t('SiteManagementDrawerPage.addSite')}
          </Typography>
          {selectedSite && (
            <IconButton
              key="delete-ph"
              colorVariant="secondary"
              sizeVariant="small"
              onClick={(event) => confirmDelete(event, selectedSite.id)}
            >
              <Trash {...iconSize} />
            </IconButton>
          )}
        </Box>
        <TextfieldComponent
          name="name"
          label={polyglot.t('SiteManagementDrawerPage.siteName')}
          value={values.name}
          type="text"
          onChange={(e) => setFieldValue('name', e.currentTarget.value)}
          error={touched.name && !!errors.name}
          helperText={(touched.name && errors.name) ?? ' '}
          clearText={() => setFieldValue('name', '')}
        />

        {!inputManually && (
          <>
            <GooglePlacesWrapper
              label={polyglot.t('SiteManagementDrawerPage.address')}
              value={values.address}
              onChange={(address: string, latitude: number | null, longitude: number | null) => {
                setFieldValue('longitude', longitude ?? null);
                setFieldValue('latitude', latitude ?? null);
                setFieldValue('address', address);
              }}
              fullWidth
            />
            <ButtonComponent sizeVariant="link" colorVariant="text" onClick={() => setInputManually(true)}>
              {polyglot.t('SiteManagementDrawerPage.enterLocation')}
            </ButtonComponent>
          </>
        )}

        {inputManually && (
          <>
            {renderAddressField()}
            {renderCoordinatesFields()}
            <ButtonComponent sizeVariant="link" colorVariant="text" onClick={() => setInputManually(false)}>
              {polyglot.t('SiteManagementDrawerPage.searchMap')}
            </ButtonComponent>
          </>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            fullWidth
            type="submit"
            loading={isSubmitting}
            colorVariant="primary"
            sizeVariant="medium"
            name={polyglot.t('General.save')}
          />
        </Box>

        <NotificationModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
          }}
          anchorEl={anchorEl}
          takeAction={async () => {
            if (siteToDelete) {
              setIsConfirmModalOpen(false);
              closePage();
              await deleteSite();
            } else showMessage(polyglot.t('SiteManagementDrawerPage.errorMessages.noSiteSelected'), 'error');
          }}
          message={polyglot.t('SiteManagementDrawerPage.deleteSiteMessage')}
          callToAction="Yes"
        />
      </Form>
    </FormikProvider>
  );
};
