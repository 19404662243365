import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Link, Stack, Typography } from '@mui/material';
import {
  OnboardingContractConfig,
  OnboardingStateData,
  OnboardingUserState,
} from '@shared/modules/onboarding/onboarding';
import { canInviteAgainStatuses } from '@v2/feature/user/user.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { TemplateAPI } from '@/api-client/templates.api';
import { UserDetailsScopes } from '@/component/dashboard/userDetails/user-details.scopes';
import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { useUserIdParam } from '@/hooks/userid-param.hook';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  ADMIN_USER_DOCUMENTS_ROUTE,
  ONBOARDING_NEW_USER_ROUTE,
  ONBOARDING_ROUTE,
  ONBOARDING_USER_STATUS_APPS_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ROUTE,
  ONBOARDING_USER_STATUS_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE,
  USER_APPS_TAB,
  USER_DEVICES_PAGE,
  USER_PAYROLL_PAYSLIPS,
  USER_TASKS_PAGE,
  USER_WORK_TAB,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { AccountStatus, inviteUser } from '@/lib/users';
import { CurrentUser } from '@/models';
import { AppIconsList } from '@/v2/components/app-icons-list.component';
import { Divider } from '@/v2/components/divider.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DevicesStatsDto } from '@/v2/feature/device/device.dto';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';
import { BufferData } from '@/v2/feature/documents/documents.interface';
import { DocumentsScopes } from '@/v2/feature/documents/documents.scopes';
import { EditIdVerify } from '@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component';
import {
  CheckCountry,
  CheckPackage,
  IDVerificationResultDto,
} from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.interface';
import { OnboardingScopes } from '@/v2/feature/onboarding/onboarding.scopes';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { PayrollScopes } from '@/v2/feature/payroll/payroll.scopes';
import { TaskScopes } from '@/v2/feature/task/task.scopes';
import { ContractModal } from '@/v2/feature/templates/components/contract-modal.component';
import { MissingTemplateFieldModal } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { ContractTemplate, VerifyTemplateResult } from '@/v2/feature/templates/templates.interface';
import { TemplatesScopes } from '@/v2/feature/templates/templates.scopes';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import {
  ID_CHECK_COMPLETED_STATES,
  MANUAL_RTW_CHECK,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import {
  CustomChip,
  OnboardingActionCard,
} from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component';
import { OnboardingUserStats } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-user-stats.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

type OnboardingUserStatusProps = {
  onboarding: OnboardingUserState;
};

const OnboardingUserStatus = ({ onboarding }: OnboardingUserStatusProps) => {
  const { polyglot } = usePolyglot();

  // const { data: zincData, isLoading: isLoadingPackages } = useApiClient(
  //   onboarding.template.idverify ? OnboardingEndpoints.getIDCheckPackages() : null,
  //   { suspense: false }
  // );

  const [state] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const { getCachedUserById, refreshCachedUsers } = useCachedUsers();
  const user = getCachedUserById(onboarding.userId);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [devicesStats, setDevicesStats] = useState<DevicesStatsDto>();
  const [idCheckState, setIdCheckState] = useState<IDVerificationResultDto>();
  const [loadingIdCheck, setLoadingIdCheck] = useState<boolean>(true);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState<boolean>(false);
  const [contractStatus, setContractStatus] = useState<VerifyTemplateResult[] | null>();
  const [loadingContractData, setLoadingContractData] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [contractModalStep2, setContractModalStep2] = useState<boolean>(false);
  const [contractModalStep3, setContractModalStep3] = useState<boolean>(false);
  const [contractModalStep4, setContractModalStep4] = useState<boolean>(false);
  const [contractModalStep5, setContractModalStep5] = useState<boolean>(false);
  const [initiateAdvanceIdCheck, setInitiateAdvanceIdCheck] = useState<boolean>(false);
  const [contractTemplate, setContractTemplate] = useState<ContractTemplate>();
  const [downloadingIdCheckReport, setDownloadingIdCheckReport] = useState(false);
  const [selectedIdCheckReportBuffer, setSelectedIdCheckReportBuffer] = useState<BufferData | null>();
  const [idCheckReportPreviewModalOpen, setIdCheckReportPreviewModalOpen] = useState(false);

  const onboardingUserForScopeCheck = ({ ...user, ...onboarding.userPermissions } as unknown) as CurrentUser;

  const onboardingUserHasTaskScope = checkScopes(onboardingUserForScopeCheck, ['task'], { userId: onboarding.userId });
  const onboardingUserHasDocumentScope = checkScopes(onboardingUserForScopeCheck, ['documents'], {
    userId: onboarding.userId,
  });
  const onboardingUserHasPayrollScope = checkScopes(onboardingUserForScopeCheck, ['payroll:read'], {
    userId: onboarding.userId,
  });

  const [canInviteUser, canSendContract] = useMemo(
    () => [
      checkScopes(currentUser, OnboardingScopes.INVITE_USER),
      checkScopes(currentUser, TemplatesScopes.SEND_CONTRACT),
    ],
    [currentUser]
  );

  const idCheckButtonText = useMemo(() => {
    if (idCheckState?.status === 'pending') return polyglot.t('OnboardingUserStatus.invited');
    if (idCheckState?.status && ID_CHECK_COMPLETED_STATES.has(idCheckState?.status))
      return polyglot.t('OnboardingUserStatus.result');
    return polyglot.t('OnboardingUserStatus.invite');
  }, [polyglot, idCheckState]);

  const idCheckChips = useMemo(() => {
    if (onboarding.template.idverify?.check && onboarding.template.idverify.check?.name)
      return [onboarding.template.idverify.check?.name.concat(' checks')];
    return [];
  }, [onboarding]);

  const refreshIdentityCheckResult = useCallback(async (userId: number) => {
    setLoadingIdCheck(true);
    if (userId) {
      const result = await OnboardingAPI.refreshIdentityCheckResult(userId);
      // TODO: currently ONLY 1 id check can be initiated during onboarding flow, but id check result status returns a list of all checks for a user
      const idCheckForOnboarding = result?.length > 0 ? result[0] : undefined;
      setIdCheckState(idCheckForOnboarding);
    }
    setLoadingIdCheck(false);
  }, []);

  const refresh = useCallback(async () => {
    try {
      const canReadDeviceInventory = checkScopes(currentUser, DeviceScopes.READ_COMPANY_INVENTORY);
      if (onboarding.template.device && !onboarding.state.device && canReadDeviceInventory) {
        const result = await DeviceAPI.getDevicesInventoryStats();
        setDevicesStats(result);
      }
      if (onboarding.template.idverify) {
        await refreshIdentityCheckResult(onboarding.userId);
      }
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [currentUser, onboarding, refreshIdentityCheckResult, showMessage, polyglot]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const [taskCount, tasksCompleted] = useMemo(() => {
    if (!user) return [0, 0];
    let count = 0,
      completed = 0;
    // employment info
    count++;
    if (onboarding.hasEmploymentInfo) {
      completed++;
    }
    // invite user
    count++;
    if (![AccountStatus.Created, AccountStatus.Invited].includes(user.accountStatus)) {
      completed++;
    }
    // device
    if (onboarding.template.device) {
      count++;
      if (onboarding.state.device) completed++;
    }
    // apps
    if (onboarding.template.apps) {
      count++;
      if (onboarding.state.apps?.items.every((item) => !!item.accountStatus)) completed++;
    }
    // contract
    if (onboarding.template.contracts) {
      const { templateId } = onboarding.template.contracts;
      count++;
      if (onboarding.state.contracts?.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step2) {
      const { templateId } = onboarding.template.contracts_step2;
      count++;
      if (onboarding.state.contracts_step2?.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step3) {
      const { templateId } = onboarding.template.contracts_step3;
      count++;
      if (onboarding.state.contracts_step3?.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step4) {
      const { templateId } = onboarding.template.contracts_step4;
      count++;
      if (onboarding.state.contracts_step4?.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step5) {
      const { templateId } = onboarding.template.contracts_step5;
      count++;
      if (onboarding.state.contracts_step5?.items.some((item) => item.templateId === templateId)) completed++;
    }

    // tasks
    if (onboarding.template.tasks) {
      count++;
      if (onboarding.state.tasks?.items.every((item) => item.status === 'Complete')) completed++;
    }
    // documents
    if (onboarding.template.documents) {
      count++;
      if (onboarding.state.documents?.items.every((item) => item.uploaded > 0)) completed++;
    }
    // payroll
    if (onboarding.template.payroll) {
      count++;
      if (onboarding.state.payroll?.createdAt) completed++;
    }
    return [count, completed];
  }, [onboarding, user]);

  const startDeviceSelection = useCallback(
    (deviceStore: 'new' | 'existing') => {
      const path = {
        new: () => generatePath(ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE, { userId: onboarding.userId }),
        existing: () => generatePath(ONBOARDING_USER_STATUS_DEVICE_ROUTE, { userId: onboarding.userId }),
      }[deviceStore]();
      routerHistory.push(path);
    },
    [onboarding.userId, routerHistory]
  );

  const getDeviceChipLabels = useCallback((state: OnboardingStateData) => {
    if (!state.device) return [];
    return state.device.items.map((p) => (p.serialNumber ? `${p.modelName} / ${p.serialNumber}` : p.modelName));
  }, []);

  const closeUserStatusPage = useCallback(() => routerHistory.goBack(), [routerHistory]);
  useEscapeKey(closeUserStatusPage);

  const inviteUserWrapper = useCallback(async () => {
    try {
      // OLD RESTRICTION
      // if (
      //   user?.accountStatus &&
      //   !canInviteAgainStatuses.includes(user.accountStatus)
      //   // currentUser?.restrictions?.PEOPLE?.disableInvitingMoreThanLimitedUsers
      // ) {
      //   setUpgradeModalOpen(true);
      // } else {
      await inviteUser(onboarding.userId, showMessage, 'onboard');
      await refreshCachedUsers();
      // }
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [showMessage, polyglot, onboarding.userId, refreshCachedUsers]);

  const initateIdentityChecks = useCallback(async () => {
    setLoadingIdCheck(true);
    try {
      if (
        user?.accountStatus &&
        !canInviteAgainStatuses.includes(user.accountStatus) &&
        !currentUser?.hasPaymentMethodOnFile
      ) {
        setUpgradeModalOpen(true);
      }
      // } else if (
      //   onboarding.template.idverify?.check?.name &&
      //   zincData?.internationalSupport &&
      //   zincData.internationalSupport.includes(onboarding.template.idverify.check.name)
      // ) {
      //   // if package with international support is chosen during onboarding template configuration
      //   // admin needs to select country for advanced package
      //   setInitiateAdvanceIdCheck(true);
      // }
      else {
        const result = await OnboardingAPI.initiateIdentityChecks(onboarding.userId);
        setIdCheckState(result);
        showMessage(polyglot.t('OnboardingUserStatus.successMessages.invite'), 'success');
      }
    } catch (error) {
      showMessage(
        polyglot.t('OnboardingUserStatus.errorMessages.initiate', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoadingIdCheck(false);
    }
  }, [onboarding, user?.accountStatus, currentUser?.hasPaymentMethodOnFile, showMessage, polyglot]);

  const updateContractStatus = (templateResult: VerifyTemplateResult) => {
    // Update the contract status array
    setContractStatus((prevStatus) => {
      const updatedStatus = prevStatus ? [...prevStatus] : []; // Provide a default value of an empty array

      // Find the index of the matching templateId
      const index = prevStatus?.findIndex(
        (status: VerifyTemplateResult) => status?.template?.id === templateResult?.template?.id
      );

      // If a match is found, update the object at that index
      if (index !== undefined && index !== null && index >= 0) {
        updatedStatus[index] = templateResult;
        return updatedStatus;
      }

      // If no match is found, insert the new object into the array
      return prevStatus ? [...prevStatus, templateResult] : [templateResult];
    });
  };

  const refreshMissingFieldsForTemplate = useCallback(
    async (templateId: string, contractRecipientUserId: number, companySignatoryUserId: number | undefined) => {
      try {
        setLoadingContractData(true);
        const templateResult = await TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId,
          companySignatoryUserId: companySignatoryUserId ? companySignatoryUserId : undefined,
        });
        const { template } = templateResult;
        if (template) setContractTemplate(template);
        updateContractStatus(templateResult);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingUserStatus.errorMessages.verify', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoadingContractData(false);
      }
    },
    [polyglot, showMessage]
  );

  const contractStatusForStep1 = useMemo(() => {
    return onboarding.template?.contracts?.templateId
      ? contractStatus?.find(
          (status) => status.template && status.template.id === onboarding.template.contracts?.templateId
        )
      : undefined;
  }, [contractStatus, onboarding.template.contracts]);

  const contractStatusForStep2 = useMemo(() => {
    return onboarding.template?.contracts_step2?.templateId
      ? contractStatus?.find(
          (status) => status.template && status.template.id === onboarding.template.contracts_step2?.templateId
        )
      : undefined;
  }, [contractStatus, onboarding.template.contracts_step2]);

  const contractStatusForStep3 = useMemo(() => {
    return onboarding.template?.contracts_step3?.templateId
      ? contractStatus?.find(
          (status) => status.template && status.template.id === onboarding.template.contracts_step3?.templateId
        )
      : undefined;
  }, [contractStatus, onboarding.template.contracts_step3]);

  const contractStatusForStep4 = useMemo(() => {
    return onboarding.template?.contracts_step4?.templateId
      ? contractStatus?.find(
          (status) => status.template && status.template.id === onboarding.template.contracts_step4?.templateId
        )
      : undefined;
  }, [contractStatus, onboarding.template.contracts_step4]);

  const contractStatusForStep5 = useMemo(() => {
    return onboarding.template?.contracts_step5?.templateId
      ? contractStatus?.find(
          (status) => status.template && status.template.id === onboarding.template.contracts_step5?.templateId
        )
      : undefined;
  }, [contractStatus, onboarding.template.contracts_step5]);

  useEffect(() => {
    const { template, userId } = onboarding;
    if (template?.contracts?.templateId) refreshMissingFieldsForTemplate(template?.contracts?.templateId, userId, 0);
    if (template?.contracts_step2?.templateId)
      refreshMissingFieldsForTemplate(template?.contracts_step2?.templateId, userId, 0);
    if (template?.contracts_step3?.templateId)
      refreshMissingFieldsForTemplate(template?.contracts_step3?.templateId, userId, 0);
    if (template?.contracts_step4?.templateId)
      refreshMissingFieldsForTemplate(template?.contracts_step4?.templateId, userId, 0);
    if (template?.contracts_step5?.templateId)
      refreshMissingFieldsForTemplate(template?.contracts_step5?.templateId, userId, 0);
  }, [onboarding, refreshMissingFieldsForTemplate]);

  const handlePreviewIdCheckReport = useCallback(async () => {
    if (!idCheckState) return;
    const { requestId } = idCheckState;
    if (!requestId) return;
    setDownloadingIdCheckReport(true);
    const reportResponse = await OnboardingAPI.downloadIdCheckReport(idCheckState);
    try {
      if (!reportResponse?.file?.data) return;
      // Extract the array of bytes from the response data
      setSelectedIdCheckReportBuffer(reportResponse?.file);
      setIdCheckReportPreviewModalOpen(true);
    } catch (error) {
      showMessage(
        polyglot.t('IdCheckPackage.errorMessages.failedToDownloadReport', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setDownloadingIdCheckReport(false);
    }
  }, [idCheckState, polyglot, showMessage]);

  const deviceChips = useMemo(() => {
    if (!devicesStats) return [];
    return !!onboarding.state.device
      ? getDeviceChipLabels(onboarding.state)
      : [polyglot.t('OnboardingUserStatus.orderOrSelect')];
  }, [polyglot, devicesStats, getDeviceChipLabels, onboarding.state]);

  const onMissingField = (params: {
    templateVerificationResult: VerifyTemplateResult;
    contractRecipientUserId: number;
  }) => {
    const { templateVerificationResult } = params;
    updateContractStatus(templateVerificationResult);
    setOpenMissingFieldContractModal(true);
  };

  if (!user) {
    routerHistory.replace(ONBOARDING_ROUTE);
    return <></>;
  }

  const divider = <Divider color="#f8f3ef" />;

  const modalForCurrentContract = (contractConfig: OnboardingContractConfig) => {
    return contractConfig.templateId === contractTemplate?.id;
  };

  const alreadyInvited = ![AccountStatus.Created, AccountStatus.Invited].includes(user.accountStatus);

  const addMissingScopeChip = (
    chips: Array<React.ReactNode | CustomChip>,
    hasScope: boolean,
    missingScope: string,
    chipType: CustomChip['type'] = 'warning'
  ): Array<React.ReactNode | CustomChip> => {
    if (hasScope) {
      return chips;
    }

    const missingScopeChip: CustomChip = {
      text: `Missing ${missingScope} scope`,
      type: chipType,
    };

    return [...chips, missingScopeChip];
  };

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('OnboardingUserStatus.onboardUser', {
              firstName: polyglot.t(user.firstName),
              lastName: polyglot.t(user.lastName),
            })}
          </Typography>
        }
        backPath={ONBOARDING_ROUTE}
        showBack
      />
      <ContentWrapper loading={false} border={false} sx={{ ...spacing.pt20, mx: spacing.mx40 }}>
        <OnboardingUserStats
          progressPercent={taskCount && (100 * tasksCompleted) / taskCount}
          pendingTaskCount={taskCount - tasksCompleted}
          startDate={user.startDate}
        />
        <Stack sx={{ mt: spacing.mt40, maxWidth: '800px' }}>
          <OnboardingActionCard
            label={polyglot.t('OnboardingUserStatus.employmentInfo')}
            owner="company"
            completed={onboarding.hasEmploymentInfo}
            startButtonLabel={polyglot.t('General.start')}
            onStartClick={
              //canSetupUser && (
              () => routerHistory.push(ONBOARDING_NEW_USER_ROUTE, { newUserId: user.userId })
              //)
            }
            completedButton={
              <ScopesControl scopes={UserDetailsScopes.VIEW_USER_WORK} context={{ userId: onboarding.userId }}>
                <ButtonComponent
                  colorVariant="secondary"
                  fullWidth
                  sizeVariant="small"
                  onClick={() => routerHistory.push(generatePath(USER_WORK_TAB, { userId: onboarding.userId }))}
                >
                  {polyglot.t('OnboardingUserStatus.view')}
                </ButtonComponent>
              </ScopesControl>
            }
          />
          {divider}
          <OnboardingActionCard
            label={polyglot.t('OnboardingUserStatus.inviteNewJoiner')}
            owner="company"
            completed={alreadyInvited}
            startButtonLabel={polyglot.t('General.invite')}
            disabled={!canInviteUser}
            onStartClick={() => inviteUserWrapper()}
            completedButton={
              <ButtonComponent
                onClick={() => inviteUserWrapper()}
                sizeVariant="small"
                colorVariant={alreadyInvited ? 'secondary' : 'primary'}
                fullWidth
              >
                {alreadyInvited ? polyglot.t('General.inviteAgain') : polyglot.t('General.invite')}
              </ButtonComponent>
            }
          />
          {onboarding.template.apps && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.apps')}
                owner="company"
                chips={[<AppIconsList stubs={onboarding.template.apps.stubs as AppIntegrationStub[]} />]}
                completed={!!onboarding.state.apps?.items.every((item) => !!item.accountStatus)}
                disabled={!onboarding.hasEmploymentInfo}
                onStartClick={() =>
                  routerHistory.push(generatePath(ONBOARDING_USER_STATUS_APPS_ROUTE, { userId: onboarding.userId }))
                }
                completedButton={
                  <ScopesControl scopes={AppScopes.VIEW_USER_APPS}>
                    <ButtonComponent
                      onClick={() => routerHistory.push(generatePath(USER_APPS_TAB, { userId: onboarding.userId }))}
                      colorVariant="secondary"
                      fullWidth
                      sizeVariant="small"
                    >
                      {polyglot.t('OnboardingUserStatus.view')}
                    </ButtonComponent>
                  </ScopesControl>
                }
              />
            </>
          )}
          {onboarding.template.contracts && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.contract')}
                owner="company"
                completed={!!onboarding.state.contracts?.items[0]}
                requiresAdminAction={
                  contractStatusForStep1?.hasMissingWorkFields || contractStatusForStep1?.hasAdditionalSignatory
                }
                startButtonLabel={
                  contractStatusForStep1?.hasMissingWorkFields || contractStatusForStep1?.hasAdditionalSignatory
                    ? polyglot.t('OnboardingUserStatus.complete')
                    : polyglot.t('OnboardingUserStatus.prepare')
                }
                chips={addMissingScopeChip(
                  [onboarding.template.contracts.templateName],
                  onboardingUserHasDocumentScope,
                  'document'
                )}
                disabled={loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract}
                onStartClick={async () => {
                  if (!onboarding.template.contracts) return;
                  const templateResult = await TemplateAPI.verifyTemplateParameters({
                    templateId: onboarding.template.contracts.templateId,
                    contractRecipientUserId: onboarding.userId,
                  });
                  updateContractStatus(templateResult);
                  const {
                    hasMissingPersonalFields,
                    hasMissingWorkFields,
                    hasAdditionalSignatory,
                    template,
                  } = templateResult;
                  if (template) setContractTemplate(template);
                  if (hasAdditionalSignatory) {
                    setOpenContractModal(true);
                    return;
                  }
                  if (hasMissingPersonalFields || hasMissingWorkFields) {
                    setOpenMissingFieldContractModal(true);
                    return;
                  }
                  routerHistory.push(
                    generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                      templateId: onboarding.template.contracts.templateId,
                      userId: onboarding.userId,
                    }),
                    { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                  );
                }}
              />
            </>
          )}
          {onboarding.template.contracts_step2 && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.contract')}
                owner="company"
                completed={!!onboarding.state.contracts_step2?.items[0]}
                requiresAdminAction={
                  contractStatusForStep2?.hasMissingWorkFields || contractStatusForStep2?.hasAdditionalSignatory
                }
                startButtonLabel={
                  contractStatusForStep2?.hasMissingWorkFields || contractStatusForStep2?.hasAdditionalSignatory
                    ? polyglot.t('OnboardingUserStatus.complete')
                    : polyglot.t('OnboardingUserStatus.prepare')
                }
                chips={addMissingScopeChip(
                  [onboarding.template.contracts_step2.templateName],
                  onboardingUserHasDocumentScope,
                  'document'
                )}
                disabled={loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract}
                onStartClick={async () => {
                  if (!onboarding.template.contracts_step2) return;
                  const templateResult = await TemplateAPI.verifyTemplateParameters({
                    templateId: onboarding.template.contracts_step2.templateId,
                    contractRecipientUserId: onboarding.userId,
                  });
                  updateContractStatus(templateResult);
                  const {
                    hasMissingPersonalFields,
                    hasMissingWorkFields,
                    hasAdditionalSignatory,
                    template,
                  } = templateResult;
                  if (hasAdditionalSignatory && template) {
                    setContractTemplate(template);
                    setContractModalStep2(true);
                    return;
                  }
                  if (hasMissingPersonalFields || hasMissingWorkFields) {
                    setOpenMissingFieldContractModal(true);
                    return;
                  }
                  routerHistory.push(
                    generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                      templateId: onboarding.template.contracts_step2.templateId,
                      userId: onboarding.userId,
                    }),
                    { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                  );
                }}
              />
            </>
          )}

          {onboarding.template.contracts_step3 && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.contract')}
                owner="company"
                completed={!!onboarding.state.contracts_step3?.items[0]}
                requiresAdminAction={
                  contractStatusForStep3?.hasMissingWorkFields || contractStatusForStep3?.hasAdditionalSignatory
                }
                startButtonLabel={
                  contractStatusForStep3?.hasMissingWorkFields || contractStatusForStep3?.hasAdditionalSignatory
                    ? polyglot.t('OnboardingUserStatus.complete')
                    : polyglot.t('OnboardingUserStatus.prepare')
                }
                chips={addMissingScopeChip(
                  [onboarding.template.contracts_step3.templateName],
                  onboardingUserHasDocumentScope,
                  'document'
                )}
                disabled={loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract}
                onStartClick={async () => {
                  if (!onboarding.template.contracts_step3) return;
                  const templateResult = await TemplateAPI.verifyTemplateParameters({
                    templateId: onboarding.template.contracts_step3.templateId,
                    contractRecipientUserId: onboarding.userId,
                  });
                  updateContractStatus(templateResult);
                  const {
                    hasMissingPersonalFields,
                    hasMissingWorkFields,
                    hasAdditionalSignatory,
                    template,
                  } = templateResult;
                  if (hasAdditionalSignatory && template) {
                    setContractTemplate(template);
                    setContractModalStep3(true);
                    return;
                  }
                  if (hasMissingPersonalFields || hasMissingWorkFields) {
                    setOpenMissingFieldContractModal(true);
                    return;
                  }
                  routerHistory.push(
                    generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                      templateId: onboarding.template.contracts_step3.templateId,
                      userId: onboarding.userId,
                    }),
                    { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                  );
                }}
              />
            </>
          )}

          {onboarding.template.contracts_step4 && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.contract')}
                owner="company"
                completed={!!onboarding.state.contracts_step4?.items[0]}
                requiresAdminAction={
                  contractStatusForStep4?.hasMissingWorkFields || contractStatusForStep4?.hasAdditionalSignatory
                }
                startButtonLabel={
                  contractStatusForStep4?.hasMissingWorkFields || contractStatusForStep4?.hasAdditionalSignatory
                    ? polyglot.t('OnboardingUserStatus.complete')
                    : polyglot.t('OnboardingUserStatus.prepare')
                }
                chips={addMissingScopeChip(
                  [onboarding.template.contracts_step4.templateName],
                  onboardingUserHasDocumentScope,
                  'document'
                )}
                disabled={loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract}
                onStartClick={async () => {
                  if (!onboarding.template.contracts_step4) return;
                  const templateResult = await TemplateAPI.verifyTemplateParameters({
                    templateId: onboarding.template.contracts_step4.templateId,
                    contractRecipientUserId: onboarding.userId,
                  });
                  updateContractStatus(templateResult);
                  const {
                    hasMissingPersonalFields,
                    hasMissingWorkFields,
                    hasAdditionalSignatory,
                    template,
                  } = templateResult;
                  if (hasAdditionalSignatory && template) {
                    setContractTemplate(template);
                    setContractModalStep4(true);
                    return;
                  }
                  if (hasMissingPersonalFields || hasMissingWorkFields) {
                    setOpenMissingFieldContractModal(true);
                    return;
                  }
                  routerHistory.push(
                    generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                      templateId: onboarding.template.contracts_step4.templateId,
                      userId: onboarding.userId,
                    }),
                    { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                  );
                }}
              />
            </>
          )}

          {onboarding.template.contracts_step5 && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.contract')}
                owner="company"
                completed={!!onboarding.state.contracts_step5?.items[0]}
                requiresAdminAction={
                  contractStatusForStep5?.hasMissingWorkFields || contractStatusForStep5?.hasAdditionalSignatory
                }
                startButtonLabel={
                  contractStatusForStep5?.hasMissingWorkFields || contractStatusForStep5?.hasAdditionalSignatory
                    ? polyglot.t('OnboardingUserStatus.complete')
                    : polyglot.t('OnboardingUserStatus.prepare')
                }
                chips={addMissingScopeChip(
                  [onboarding.template.contracts_step5.templateName],
                  onboardingUserHasDocumentScope,
                  'document'
                )}
                disabled={loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract}
                onStartClick={async () => {
                  if (!onboarding.template.contracts_step5) return;
                  const templateResult = await TemplateAPI.verifyTemplateParameters({
                    templateId: onboarding.template.contracts_step5.templateId,
                    contractRecipientUserId: onboarding.userId,
                  });
                  updateContractStatus(templateResult);
                  const {
                    hasMissingPersonalFields,
                    hasMissingWorkFields,
                    hasAdditionalSignatory,
                    template,
                  } = templateResult;
                  if (hasAdditionalSignatory && template) {
                    setContractTemplate(template);
                    setContractModalStep5(true);
                    return;
                  }
                  if (hasMissingPersonalFields || hasMissingWorkFields) {
                    setOpenMissingFieldContractModal(true);
                    return;
                  }
                  routerHistory.push(
                    generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                      templateId: onboarding.template.contracts_step5.templateId,
                      userId: onboarding.userId,
                    }),
                    { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                  );
                }}
              />
            </>
          )}

          {onboarding.template.device && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.devices')}
                owner="company"
                chips={deviceChips}
                completed={!!onboarding.state.device}
                disabled={!onboarding.hasEmploymentInfo}
                actionButton={
                  <>
                    {devicesStats && !devicesStats.noOfInventoryDevices && (
                      <ButtonComponent
                        onClick={() => startDeviceSelection('new')}
                        sizeVariant="small"
                        fullWidth
                        colorVariant="primary"
                      >
                        {polyglot.t('General.start')}
                      </ButtonComponent>
                    )}
                    {devicesStats && !!devicesStats.noOfInventoryDevices && (
                      <StyledMenuComponent
                        options={[
                          {
                            label: polyglot.t('OnboardingUserStatus.orderNew'),
                            handler: () => startDeviceSelection('new'),
                          },
                          {
                            label: polyglot.t('OnboardingUserStatus.chooseExisting'),
                            handler: () => startDeviceSelection('existing'),
                          },
                        ]}
                        actionButtonDetails={{
                          type: 'button',
                          colorVariant: 'primary',
                          sizeVariant: 'small',
                          fullWidth: true,
                          title: polyglot.t('General.complete'),
                        }}
                      />
                    )}
                  </>
                }
                completedButton={
                  <ScopesControl scopes={DeviceScopes.VIEW_USER_DEVICES}>
                    <ButtonComponent
                      onClick={() => routerHistory.push(generatePath(USER_DEVICES_PAGE, { userId: onboarding.userId }))}
                      colorVariant="secondary"
                      fullWidth
                      sizeVariant="small"
                    >
                      {polyglot.t('OnboardingUserStatus.view')}
                    </ButtonComponent>
                  </ScopesControl>
                }
              />
            </>
          )}
          {onboarding.state.documents && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.documents')}
                owner={onboarding.userId}
                completed={onboarding.state.documents.items.every((item) => item.uploaded > 0)}
                completedButton={
                  <ScopesControl scopes={DocumentsScopes.VIEW_USER_DOCUMENTS}>
                    <ButtonComponent
                      colorVariant="secondary"
                      fullWidth
                      sizeVariant="small"
                      onClick={() =>
                        routerHistory.push(generatePath(ADMIN_USER_DOCUMENTS_ROUTE, { userId: onboarding.userId }))
                      }
                    >
                      {polyglot.t('OnboardingUserStatus.view')}
                    </ButtonComponent>
                  </ScopesControl>
                }
                chips={addMissingScopeChip(
                  onboarding.state.documents.items
                    .filter(({ uploaded }) => !uploaded)
                    .map(({ typeLabel }) => typeLabel),
                  onboardingUserHasDocumentScope,
                  'document'
                )}
                disabled={!onboarding.hasEmploymentInfo}
              />
            </>
          )}
          {onboarding.state.payroll && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.payroll')}
                owner={onboarding.userId}
                completed={!!onboarding.state.payroll.createdAt}
                disabled={!onboarding.hasEmploymentInfo}
                chips={addMissingScopeChip([], onboardingUserHasPayrollScope, 'payroll')}
                completedButton={
                  <ScopesControl scopes={PayrollScopes.VIEW_USER_PAYSLIPS}>
                    <ButtonComponent
                      onClick={() =>
                        routerHistory.push(generatePath(USER_PAYROLL_PAYSLIPS, { userId: onboarding.userId }))
                      }
                      colorVariant="secondary"
                      fullWidth
                      sizeVariant="small"
                    >
                      {polyglot.t('OnboardingUserStatus.view')}
                    </ButtonComponent>
                  </ScopesControl>
                }
              />
            </>
          )}
          {onboarding.state.tasks && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.tasks')}
                owner={onboarding.userId}
                completed={onboarding.state.tasks.items.every((item) => item.status === 'Complete')}
                chips={addMissingScopeChip(
                  [...new Set(onboarding.state.tasks.items.map(({ checklistName }) => checklistName))],
                  onboardingUserHasTaskScope,
                  'task'
                )}
                disabled={!onboarding.hasEmploymentInfo}
                completedButton={
                  <ScopesControl scopes={TaskScopes.VIEW_USER_TASKS}>
                    <ButtonComponent
                      onClick={() => routerHistory.push(generatePath(USER_TASKS_PAGE, { userId: onboarding.userId }))}
                      colorVariant="secondary"
                      fullWidth
                      sizeVariant="small"
                    >
                      {polyglot.t('OnboardingUserStatus.view')}
                    </ButtonComponent>
                  </ScopesControl>
                }
              />
            </>
          )}
          {onboarding.template.idverify && (
            <>
              {divider}
              <OnboardingActionCard
                label={polyglot.t('OnboardingUserStatus.identityChecks')}
                owner="company"
                completed={idCheckState?.status ? ID_CHECK_COMPLETED_STATES.has(idCheckState?.status) : false}
                disabled={loadingIdCheck}
                chips={idCheckChips}
                startButtonLabel={idCheckButtonText}
                actionButton={
                  <ButtonComponent
                    disabled={loadingIdCheck || (idCheckState && idCheckState?.status === 'pending')}
                    colorVariant="secondary"
                    fullWidth
                    sizeVariant="small"
                    onClick={initateIdentityChecks}
                  >
                    {idCheckButtonText}
                  </ButtonComponent>
                }
                completedButton={
                  <Stack direction={'row'} sx={{ gap: spacing.g5 }}>
                    <ButtonComponent
                      disabled={
                        loadingIdCheck ||
                        idCheckState?.status === 'pending' ||
                        downloadingIdCheckReport ||
                        idCheckState?.requestId === MANUAL_RTW_CHECK
                      }
                      colorVariant="secondary"
                      fullWidth
                      sizeVariant="small"
                      startIcon={<Eye {...iconSize} />}
                      onClick={handlePreviewIdCheckReport}
                      loading={downloadingIdCheckReport}
                    >
                      {idCheckButtonText}
                    </ButtonComponent>
                  </Stack>
                }
              />
            </>
          )}
        </Stack>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.PEOPLE_PRO}
          messageSuffix="proPeople"
          markup={
            onboarding.template.idverify && !currentUser?.hasPaymentMethodOnFile ? (
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Red, mb: spacing.mb20, mt: spacing.mt20 }}>
                {polyglot.t('OnboardingUserStatus.cannotUse')}{' '}
                <Link target="_blank" href="/settings/billing">
                  {polyglot.t('OnboardingUserStatus.billing')}
                </Link>
              </Typography>
            ) : undefined
          }
        />
        {onboarding.template.contracts &&
          contractStatusForStep1?.fields &&
          modalForCurrentContract(onboarding.template.contracts) && (
            <MissingTemplateFieldModal
              open={openMissingFieldContractModal}
              setOpen={setOpenMissingFieldContractModal}
              templateId={onboarding.template.contracts.templateId}
              missingFields={contractStatusForStep1?.fields}
              contractRecipientId={onboarding.userId}
              returnPath={generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId })}
              companySignatoryUserId={contractStatusForStep1?.companySignatoryUserId}
              refreshMissingFields={refreshMissingFieldsForTemplate}
            />
          )}
        {onboarding.template.contracts &&
          contractStatusForStep1?.hasAdditionalSignatory &&
          contractTemplate &&
          openContractModal &&
          modalForCurrentContract(onboarding.template.contracts) && (
            <ContractModal
              open={openContractModal}
              setOpen={setOpenContractModal}
              contractTemplate={contractTemplate}
              onMissingField={onMissingField}
              existingRecipient={onboarding.userId}
            />
          )}
        {onboarding.template.contracts_step2 &&
          contractStatusForStep2?.fields &&
          modalForCurrentContract(onboarding.template.contracts_step2) && (
            <MissingTemplateFieldModal
              open={openMissingFieldContractModal}
              setOpen={setOpenMissingFieldContractModal}
              templateId={onboarding.template.contracts_step2.templateId}
              missingFields={contractStatusForStep2?.fields}
              contractRecipientId={onboarding.userId}
              returnPath={generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId })}
              companySignatoryUserId={contractStatusForStep2?.companySignatoryUserId}
              refreshMissingFields={refreshMissingFieldsForTemplate}
            />
          )}
        {onboarding.template.contracts_step2 &&
          contractStatusForStep2?.hasAdditionalSignatory &&
          contractTemplate &&
          contractModalStep2 &&
          modalForCurrentContract(onboarding.template.contracts_step2) && (
            <ContractModal
              open={contractModalStep2}
              setOpen={setContractModalStep2}
              contractTemplate={contractTemplate}
              onMissingField={onMissingField}
              existingRecipient={onboarding.userId}
            />
          )}

        {onboarding.template.contracts_step3 &&
          contractStatusForStep3?.fields &&
          modalForCurrentContract(onboarding.template.contracts_step3) && (
            <MissingTemplateFieldModal
              open={openMissingFieldContractModal}
              setOpen={setOpenMissingFieldContractModal}
              templateId={onboarding.template.contracts_step3.templateId}
              missingFields={contractStatusForStep3?.fields}
              contractRecipientId={onboarding.userId}
              returnPath={generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId })}
              companySignatoryUserId={contractStatusForStep3?.companySignatoryUserId}
              refreshMissingFields={refreshMissingFieldsForTemplate}
            />
          )}
        {onboarding.template.contracts_step3 &&
          contractStatusForStep3?.hasAdditionalSignatory &&
          contractTemplate &&
          contractModalStep3 &&
          modalForCurrentContract(onboarding.template.contracts_step3) && (
            <ContractModal
              open={contractModalStep3}
              setOpen={setContractModalStep3}
              contractTemplate={contractTemplate}
              onMissingField={onMissingField}
              existingRecipient={onboarding.userId}
            />
          )}

        {onboarding.template.contracts_step4 &&
          contractStatusForStep4?.fields &&
          modalForCurrentContract(onboarding.template.contracts_step4) && (
            <MissingTemplateFieldModal
              open={openMissingFieldContractModal}
              setOpen={setOpenMissingFieldContractModal}
              templateId={onboarding.template.contracts_step4.templateId}
              missingFields={contractStatusForStep4?.fields}
              contractRecipientId={onboarding.userId}
              returnPath={generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId })}
              companySignatoryUserId={contractStatusForStep4?.companySignatoryUserId}
              refreshMissingFields={refreshMissingFieldsForTemplate}
            />
          )}
        {onboarding.template.contracts_step4 &&
          contractStatusForStep4?.hasAdditionalSignatory &&
          contractTemplate &&
          contractModalStep4 &&
          modalForCurrentContract(onboarding.template.contracts_step4) && (
            <ContractModal
              open={contractModalStep4}
              setOpen={setContractModalStep4}
              contractTemplate={contractTemplate}
              onMissingField={onMissingField}
              existingRecipient={onboarding.userId}
            />
          )}

        {onboarding.template.contracts_step5 &&
          contractStatusForStep5?.fields &&
          modalForCurrentContract(onboarding.template.contracts_step5) && (
            <MissingTemplateFieldModal
              open={openMissingFieldContractModal}
              setOpen={setOpenMissingFieldContractModal}
              templateId={onboarding.template.contracts_step5.templateId}
              missingFields={contractStatusForStep5?.fields}
              contractRecipientId={onboarding.userId}
              returnPath={generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId })}
              companySignatoryUserId={contractStatusForStep5?.companySignatoryUserId}
              refreshMissingFields={refreshMissingFieldsForTemplate}
            />
          )}
        {onboarding.template.contracts_step5 &&
          contractStatusForStep5?.hasAdditionalSignatory &&
          contractTemplate &&
          contractModalStep5 &&
          modalForCurrentContract(onboarding.template.contracts_step5) && (
            <ContractModal
              open={contractModalStep5}
              setOpen={setContractModalStep5}
              contractTemplate={contractTemplate}
              onMissingField={onMissingField}
              existingRecipient={onboarding.userId}
            />
          )}
        {onboarding.template.idverify && onboarding.template.idverify?.check && (
          <DrawerModal isOpen={initiateAdvanceIdCheck} setIsOpen={setInitiateAdvanceIdCheck}>
            <EditIdVerify
              onSave={async (checkPackage?: CheckPackage, checkCountry?: CheckCountry) => {
                if (checkPackage) {
                  const result = await OnboardingAPI.initiateIdentityChecksFromUserProfile(
                    onboarding.userId,
                    checkPackage,
                    checkCountry
                  );
                  setIdCheckState(result);
                }
                showMessage(polyglot.t('OnboardingUserStatus.successMessages.invite'), 'success');
                setInitiateAdvanceIdCheck(false);
              }}
              mode="profile"
            />
          </DrawerModal>
        )}
        {idCheckReportPreviewModalOpen && selectedIdCheckReportBuffer && (
          <DocPreviewer
            fileBuffer={selectedIdCheckReportBuffer}
            contentType={'application/pdf'}
            visible={idCheckReportPreviewModalOpen}
            onClose={() => {
              setIdCheckReportPreviewModalOpen(false);
              setSelectedIdCheckReportBuffer(undefined);
            }}
            title={'id-check-report'}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};

export const OnboardingUserStatusPage = () => {
  const userId = useUserIdParam();
  const [onboarding, setOnboarding] = useState<OnboardingUserState | null>();

  const refreshOnboardingState = useCallback(async () => {
    const result = await OnboardingAPI.getOnboardingUser(userId);
    setOnboarding(result);
  }, [userId]);

  useEffect(() => {
    refreshOnboardingState();
  }, [refreshOnboardingState]);

  if (!onboarding) return <></>;

  return <OnboardingUserStatus onboarding={onboarding} />;
};
