import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, MenuItem, Stack } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { capitalize } from 'lodash';

import { CompanyAPI } from '@/api-client/company.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDown.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { StyledMenu } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { FaqComponent } from '@/v2/feature/strapi-help/components/faq.component';
import { GuideComponent } from '@/v2/feature/strapi-help/components/guide.component';
import { HelpCenterLoader } from '@/v2/feature/strapi-help/components/help-center-loader.component';
import { DetailByDomainBox } from '@/v2/feature/strapi-help/components/resource-box.component';
import { StrapiAPI } from '@/v2/feature/strapi-help/strapi.api';
import {
  PaginationObject,
  StrapiFaqData,
  StrapiGuideData,
  SupportDomainList,
  UnSanitizedFaq,
  UnSanitizedGuide,
} from '@/v2/feature/strapi-help/strapi.interface';
import { activeTabsFilterBtnSx } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  domain: string | undefined;
}

export const HelpCenterModal = ({ isOpen, setIsOpen, domain }: Props) => {
  const { polyglot } = usePolyglot();

  const paginationInitial = { page: 1, pageSize: DEFAULT_PAGE_SIZE, pageCount: 0, total: 0 };

  const [searchInput, setSearchInput] = useState<string>('');
  const [initialDomain, setInitialDomain] = useState<string | undefined>(domain);

  const [selectedFaq, setSelectedFaq] = useState<UnSanitizedFaq | undefined>(undefined);
  const [faqs, setFaqs] = useState<StrapiFaqData[] | undefined>(undefined);
  const [faqPagination, setFaqPagination] = useState<PaginationObject>(paginationInitial);

  const [selectedGuide, setSelectedGuide] = useState<UnSanitizedGuide | undefined>(undefined);
  const [guides, setGuides] = useState<StrapiGuideData[] | undefined>(undefined);
  const [guidePagination, setGuidePagination] = useState<PaginationObject>(paginationInitial);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const domainMenuOpen = Boolean(anchorEl);

  const [loading, setLoading] = useState<boolean>(false);
  const [showChatSupport, setShowChatSupport] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const faqspagination = useMemo(
    () => ({
      ...faqPagination,
      page: faqPagination.page,
      pageSize: faqPagination.pageSize,
    }),
    [faqPagination]
  );

  const guidesspagination = useMemo(
    () => ({
      ...guidePagination,
      page: guidePagination.page,
      pageSize: guidePagination.pageSize,
    }),
    [guidePagination]
  );

  const getChatSupportFlag = useCallback(async () => {
    const hasSupport = await CompanyAPI.hasChatSupport();
    setShowChatSupport(hasSupport);
  }, []);

  useEffect(() => {
    getChatSupportFlag();
  }, [getChatSupportFlag]);

  const getStrapiResources = useCallback(async () => {
    try {
      setLoading(true);
      const [faqs, guides] = await Promise.all([
        StrapiAPI.listFaqs(initialDomain, searchInput, faqspagination.page, faqspagination.pageSize),
        StrapiAPI.listGuides(initialDomain, searchInput, guidesspagination.page, guidesspagination.pageSize),
      ]);
      setFaqs(faqs.data);
      setFaqPagination(faqs.meta.pagination);
      setGuides(guides.data);
      setGuidePagination(guides.meta.pagination);
      setLoading(false);
    } catch (error) {
      console.error(error);
      showMessage(`Encountered an error: ${error}`, 'error');
    }
  }, [
    initialDomain,
    showMessage,
    searchInput,
    faqspagination.page,
    guidesspagination.page,
    faqspagination.pageSize,
    guidesspagination.pageSize,
  ]);

  useEffect(() => {
    getStrapiResources();
  }, [getStrapiResources]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    let searchQuery = event.target.value?.trim();
    if (!searchQuery) {
      setSearchInput('');

      return;
    }

    setFaqPagination(paginationInitial);
    setGuidePagination(paginationInitial);
    setSearchInput(searchQuery);
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthPercentage={45}
      onClose={() => {
        setIsOpen(false);
        setTimeout(() => {
          setSelectedGuide(undefined);
          setSelectedFaq(undefined);
        }, 500);
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: spacing.m30,
          }}
        >
          <Typography variant="title2">{polyglot.t('HelpCenter.title')}</Typography>
        </Box>

        {!selectedGuide && !selectedFaq && (
          <Stack direction="row" sx={{ gap: spacing.g10 }}>
            <TableSearch query={searchInput} handleChange={handleSearch} />
            {!initialDomain ? (
              <ButtonComponent
                key={'domain-selector'}
                sizeVariant="filter"
                style={{ textTransform: 'capitalize' }}
                colorVariant={'secondary'}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                Topic <ArrowDown />
                <StyledMenu
                  id={`btnMenuOptions`}
                  MenuListProps={{
                    'aria-labelledby': 'btnMenuOptions',
                  }}
                  open={domainMenuOpen}
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  disableAutoFocusItem
                >
                  {SupportDomainList.map((value, i) => (
                    <MenuItem
                      key={value}
                      id={`btn${value}_${i}`}
                      onClick={(e) => {
                        setInitialDomain(value.toLowerCase());
                        setAnchorEl(null);
                        e.stopPropagation();
                      }}
                      disableRipple
                      sx={{ marginTop: i > 0 ? '10px' : 0 }}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </StyledMenu>
              </ButtonComponent>
            ) : (
              <Button
                sx={{ ...activeTabsFilterBtnSx, ...themeFonts.caption }}
                onClick={() => setInitialDomain(undefined)}
                endIcon={<Close {...iconSize} stroke={themeColors.white} />}
              >
                {capitalize(initialDomain)}
              </Button>
            )}
          </Stack>
        )}
        {loading ? (
          <HelpCenterLoader domain={initialDomain} />
        ) : (
          <>
            {!selectedFaq && !selectedGuide && (
              <Fragment>
                <DetailByDomainBox
                  faqs={faqs}
                  guides={guides}
                  setSelectedFaq={setSelectedFaq}
                  setSelectedGuide={setSelectedGuide}
                  faqPagination={faqPagination}
                  setFaqPagination={setFaqPagination}
                  guidePagination={guidePagination}
                  setGuidePagination={setGuidePagination}
                  showChatSupport={showChatSupport}
                />
              </Fragment>
            )}

            {selectedFaq && !selectedGuide && (
              <FaqComponent selectedFaq={selectedFaq} setSelectedFaq={setSelectedFaq} />
            )}

            {selectedGuide && !selectedFaq && (
              <GuideComponent selectedGuide={selectedGuide} setSelectedGuide={setSelectedGuide} />
            )}
          </>
        )}
      </Box>
    </DrawerModal>
  );
};
