import { useState } from 'react';

import { Box, List, ListItem } from '@mui/material';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { BenefitsQuoteData } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import HoorayLogo from '@/images/vendors/HoorayLogo.svg';
import { nestErrorMessage } from '@/lib/errors';
import { INSURANCE_COMPANY_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';

interface InsuranceSetupDataSharingStepProps {
  readonly quoteData: BenefitsQuoteData;
  readonly refresh: () => Promise<void>;
}

export const InsuranceSetupDataSharingStep = ({ quoteData, refresh }: InsuranceSetupDataSharingStepProps) => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const history = useHistory();

  const requestAQuote = async (values: BenefitsQuoteData): Promise<void> => {
    try {
      setLoading(true);
      await InsuranceAPI.requestInsuranceQuote(values);
      await refresh();
      history.push(INSURANCE_COMPANY_ROUTE);
      showMessage(polyglot.t('BenefitModule.quoteRequestedSuccess'), 'success');
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
      <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('BenefitModule.beforeYouContinue')}
      </Typography>
      <Typography variant="title3" sx={{ mt: spacing.mt30, mb: spacing.mb20, color: themeColors.DarkGrey }}>
        {polyglot.t('BenefitModule.howDataIsUsed')}
      </Typography>
      <Typography variant="caption" sx={{ mb: spacing.mb10, color: themeColors.DarkGrey }}>
        {polyglot.t('BenefitModule.howDataIsUsedLong')}
      </Typography>
      <Typography variant="caption" sx={{ mb: spacing.mb10, color: themeColors.DarkGrey }}>
        {polyglot.t('BenefitModule.hooraySuggestionsLong')}
      </Typography>
      <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
        {polyglot.t('BenefitModule.ifWantToKnowMore')}
      </Typography>
      <List dense sx={{ listStyleType: 'disc', pl: 4, py: 0, mt: 0 }}>
        <ListItem sx={{ display: 'list-item', py: 0, my: 0 }}>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            <a
              href="https://hoorayinsurance.co.uk/group-private-medical/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              {polyglot.t('BenefitModule.aboutHooray')}
            </a>
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', py: 0, my: 0 }}>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            <a
              href="https://drive.google.com/file/d/1KpuwIyMT_G9gvGUo5llgtD6nJ5a58X9I/view?usp=share_link"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              {polyglot.t('BenefitModule.hoorayPrivacyNotice')}
            </a>
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', py: 0, my: 0 }}>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
            <a
              href="https://drive.google.com/file/d/1dz9sRmMDKhD0MAgnxrIysOuyxi52PGe4/view?usp=share_link"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              {polyglot.t('BenefitModule.hoorayTermsOfBusiness')}
            </a>
          </Typography>
        </ListItem>
      </List>

      <Box sx={{ mt: spacing.mt30, display: 'flex', gap: spacing.gap20, alignItems: 'center' }}>
        <img src={HoorayLogo} alt="payroll-empty" width={80} height="auto" />
        <Typography variant="caption" sx={{ mb: spacing.mb10, color: themeColors.DarkGrey }}>
          {polyglot.t('BenefitModule.poweredByHooray')}
        </Typography>
      </Box>
      <Box sx={{ ...buttonBoxSx, mt: spacing.mt40 }}>
        <LoaderButton
          name={polyglot.t('General.accept')}
          sizeVariant="large"
          colorVariant="primary"
          loading={loading}
          onClick={async () => await requestAQuote(quoteData)}
          fullWidth
          type="button"
        />
      </Box>
    </Box>
  );
};
