import { useMemo } from 'react';

import { Skeleton, Stack } from '@mui/material';

import type { SxProps } from '@mui/system';

import { DelayLoad } from '@/v2/components/delay-load.component';
import { themeColors } from '@/v2/styles/colors.styles';

interface SkeletonLoaderProps {
  variant: 'circular' | 'rectangular' | 'text';
  width?: number | string;
  height?: number | string;
  rows?: number;
  rowGap?: number | string;
  sx?: SxProps;
  delay?: number;
}

export const SkeletonLoader = ({
  variant,
  width = '100%',
  height = '100%',
  rows = 1,
  rowGap = '15px',
  sx = { backgroundColor: themeColors.Background },
  delay = 300,
}: SkeletonLoaderProps) => {
  const skeleton = useMemo(() => {
    const result = new Array<JSX.Element>(rows);
    for (let i = 0; i < rows; i++) {
      result[i] = <Skeleton key={i} variant={variant} width={width} height={height} sx={sx} />;
    }
    return result;
  }, [height, rows, sx, variant, width]);

  return (
    <DelayLoad delay={delay}>
      <Stack sx={{ gap: rowGap }}>{skeleton}</Stack>
    </DelayLoad>
  );
};
