import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { PaymentCategoryEnum, PaymentTypeSettings } from './payment-settings/payment-settings.interface';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PaymentType } from '@/v2/feature/payments/payments.dto';
import { PaymentSettingsNewTypeDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { PaymentTypesSettingsPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-types-settings.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';

export const PaymentsSettingsPage = ({ type }: { type: PaymentType }) => {
  const { polyglot } = usePolyglot();
  const isExpense = type === PaymentType.Expense;
  const isInvoice = type === PaymentType.Invoice;
  const { mutate: refreshPaymentTypeSettingsData, isValidating: loadingPaymentTypeSettingsData } = useApiClient(
    isExpense
      ? PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId()
      : isInvoice
      ? PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId()
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const [typeForEdit, setTypeForEdit] = useState<PaymentTypeSettings | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {isExpense && polyglot.t('SettingsRouterPageConfig.expenses')}
            {isInvoice && polyglot.t('SettingsRouterPageConfig.invoices')}
          </Typography>
        }
        actions={
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => {
              setTypeForEdit(undefined);
              setIsDrawerOpen(true);
            }}
            disabled={loadingPaymentTypeSettingsData}
          >
            {polyglot.t('PaymentSettingsPage.newType')}
          </ButtonComponent>
        }
        showAction={!loadingPaymentTypeSettingsData}
      />
      <ContentWrapper sx={{ scrollbarWidth: 'none' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
          <PaymentTypesSettingsPage type={type} />
          {/* <PaymentSettingsApprovalsPage /> */}
        </Box>
        {isDrawerOpen && (
          <PaymentSettingsNewTypeDrawer
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            refreshAllSettings={refreshPaymentTypeSettingsData}
            typeForEdit={typeForEdit}
            typeCategory={isInvoice ? PaymentCategoryEnum.INVOICE : PaymentCategoryEnum.EXPENSE}
            redirectToSettings={true}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
