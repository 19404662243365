import { useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { HelperAbsencePolicyBalanceDrawer } from '@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-balance-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';

interface HelperAbsencePolicyBalanceProps {
  readonly companyId: number;
  readonly absencePolicies: AbsencePolicyDto[];
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const HelperAbsencePolicyBalance = ({ companyId, absencePolicies, users }: HelperAbsencePolicyBalanceProps) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  return (
    <Box>
      <ButtonComponent
        sizeVariant="small"
        colorVariant="secondary"
        onClick={() => {
          setOpenDrawer(true);
        }}
      >
        User balance
      </ButtonComponent>

      <HelperAbsencePolicyBalanceDrawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        companyId={companyId}
        absencePolicies={absencePolicies}
        users={users}
      />
    </Box>
  );
};
