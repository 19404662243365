import axios from 'axios';
import {
  CompanyBillingSubscription,
  CompanySubscriptionStats,
  SubscriptionBillingStats,
} from '@/models/subscription.model';
import { CompanySubscriptionDetails } from '@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface';

export class BillingSubscriptionAPI {
  /** @deprecated */
  static async getCompanySubscriptions(): Promise<CompanyBillingSubscription[]> {
    return (await axios.get('/apiv2/billing/subscriptions')).data;
  }

  static async getCompanySubscriptionDetails(): Promise<CompanySubscriptionDetails> {
    return (await axios.get('/apiv2/companies/subscription/details')).data;
  }

  static async upgradeSubscriptionAsSuperadmin(
    companyId: number,
    subscriptions: { productPlanId: number }[]
  ): Promise<void> {
    await axios.post('/apiv2/billing/subscriptions/superadmin/upgrade', { companyId, subscriptions });
  }

  static async downgradeSubscriptionAsSuperadmin(
    companyId: number,
    payload: {
      productPlanId: number;
      id: number;
    }
  ): Promise<void> {
    return await axios.post('/apiv2/billing/subscriptions/superadmin/cancel', { companyId, ...payload });
  }

  static async upgradeSubscription(subscriptions: { productPlanId: number }[]): Promise<{ url: string }> {
    return (await axios.post('/apiv2/billing/subscriptions/upgrade', { subscriptions })).data;
  }

  static async upgradeSubscriptionV2(subscriptions: { productPlanId: number }[]): Promise<{ url: string }> {
    return (await axios.post('/apiv2/billing/subscriptions/upgrade-v2', { subscriptions })).data;
  }

  static async downgradeSubscription(payload: { productPlanId: number; id: number }): Promise<void> {
    return await axios.post('/apiv2/billing/subscriptions/cancel', payload);
  }

  static async getSubscriptionStats(): Promise<CompanySubscriptionStats> {
    return (await axios.get('/apiv2/billing/subscriptions/stats')).data;
  }

  static async getSubscriptionBillingStats(): Promise<SubscriptionBillingStats> {
    return (await axios.get('/apiv2/billing/subscriptions/billing-stats')).data;
  }
}
