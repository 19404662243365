import React from 'react';

import { Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { SETTINGS_NOTIFICATIONS_ROUTE } from '@/lib/routes';
import { NotificationSettingPage } from '@/v2/feature/notification/notification-settings/features/notification-setting.page';

export const NotificationSettingRouter = (): JSX.Element => (
  <Switch>
    <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_NOTIFICATIONS_ROUTE}>
      <NotificationSettingPage />
    </RouteScopesControl>
  </Switch>
);
