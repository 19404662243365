import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { HelperAbsencePage } from '@v2/feature/super-admin/features/helper-dashboard/helper-absence.page';
import { HelperAccountingTransactionsPage } from '@v2/feature/super-admin/features/helper-dashboard/helper-accounting-transactions.page';
import { HelperCarryOverPage } from '@v2/feature/super-admin/features/helper-dashboard/helper-carry-over.page';
import { HelperCompanyPayrunsRouter } from '@v2/feature/super-admin/features/helper-dashboard/helper-company-payruns.router';
import { HelperDevicesRouter } from '@v2/feature/super-admin/features/helper-dashboard/helper-devices.router';
import {
  SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE,
  SUPER_ADMIN_HELPER_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router-dom';

import { HelperCompanyRecordsRouter } from './helper-company-records.router';

export const HelperCompanyRouter = ({
  users,
  companies,
  loading,
}: {
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly loading: boolean;
}): React.JSX.Element => {
  const routerHistory = useHistory();

  const params = useParams<{ readonly companyId: string }>();
  const companyId = Number(params.companyId);
  const company = companies.find((company) => company.companyId === companyId);

  return company ? (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE}>
        <HelperCompanyPayrunsRouter company={company} />
      </Route>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE}>
        <HelperDevicesRouter company={company} />
      </Route>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE}>
        <HelperCarryOverPage users={users} company={company} />
      </Route>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE}>
        <HelperAccountingTransactionsPage users={users} company={company} />
      </Route>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE}>
        <HelperAbsencePage company={company} />
      </Route>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE}>
        <HelperCompanyRecordsRouter company={company} />
      </Route>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE}>
        <>
          <TopHeader
            title={
              <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                Helper page | {company.name} [ {companyId} ]
              </Typography>
            }
            showBack
            backPath={SUPER_ADMIN_HELPER_ROUTE}
          />
          <ContentWrapper loading={false}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE, { companyId }))
                }
              >
                Payruns
              </Button>

              <Button
                variant="outlined"
                onClick={() =>
                  routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE, { companyId }))
                }
              >
                Devices
              </Button>

              <Button
                variant="outlined"
                onClick={() =>
                  routerHistory.push(
                    generatePath(SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE, { companyId })
                  )
                }
              >
                Account Statements
              </Button>

              <Button
                variant="outlined"
                onClick={() => routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE, { companyId }))}
              >
                Absence records
              </Button>

              <Button
                variant="outlined"
                onClick={() =>
                  routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE, { companyId }))
                }
              >
                Absence
              </Button>

              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE, { companyId }))
                }
              >
                Delete Records
              </Button>
            </Box>
          </ContentWrapper>
        </>
      </Route>
    </Switch>
  ) : (
    <Box>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Helper page</Typography>}
        showBack
        backPath={SUPER_ADMIN_HELPER_ROUTE}
      />
      <ContentWrapper loading={loading}>
        <Typography>Could not find the company!</Typography>
      </ContentWrapper>
    </Box>
  );
};
