import { useCallback, useEffect } from 'react';

import { Box } from '@mui/material';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as InfoGrey } from '@/images/side-bar-icons/InfoGrey.svg';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent, DatePickerComponentProps } from '@/v2/components/forms/date-picker.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { iconSize } from '@/v2/styles/menu.styles';

interface ContractEndDatePickerProps extends Omit<DatePickerComponentProps, 'value' | 'onChange'> {
  effectiveDate: string;
  onChange: (value: string | null) => void;
  value: string | null;
}

export const ContractEndDatePickerComponent = ({
  effectiveDate,
  value,
  onChange,
  disabled,
  ...props
}: ContractEndDatePickerProps) => {
  const { polyglot } = usePolyglot();

  const ensureContractEndDateIsValid = useCallback(() => {
    const contractEndDate = value;
    let updatedContractEndDate: string | null = contractEndDate;
    if (!effectiveDate) {
      // if we have no effective date, we can't have a contract end date
      updatedContractEndDate = null;
    }
    if (contractEndDate && effectiveDate && effectiveDate > contractEndDate) {
      // the contract end date cannot be before the effective date
      updatedContractEndDate = effectiveDate;
    }
    if (updatedContractEndDate !== contractEndDate) {
      onChange(updatedContractEndDate);
    }
  }, [effectiveDate, onChange, value]);

  useEffect(() => {
    ensureContractEndDateIsValid();
  }, [ensureContractEndDateIsValid]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <CheckboxComponent
          name="hasContractEndDate"
          label={<Typography variant="caption">{polyglot.t('ContractForm.hasEndDate')}</Typography>}
          checked={!!value}
          disabled={disabled}
          onChange={(_, checked) => {
            onChange(checked ? effectiveDate : null);
          }}
        />
        <StyledTooltip title={polyglot.t('ContractForm.hasEndDateHint')}>
          <InfoGrey {...iconSize} />
        </StyledTooltip>
      </Box>
      {value && (
        <Box>
          <DatePickerComponent
            {...props}
            value={value}
            onChange={onChange}
            // the contract end date cannot be before the effective date
            minDate={effectiveDate}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
};
