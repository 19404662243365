import { useState } from 'react';

import { Box, Button, IconButton, Link, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { ZeltDocumentType } from '@/lib/documents';
import { SETTINGS_DOCUMENTS_TYPES_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type EditDocumentsProps = {
  documentTypes?: string[];
  allDocumentTypes: ZeltDocumentType[];
  otherDocumentNames?: string[];
  onSave: (documentTypes: string[], otherDocumentName: string[]) => void;
};

export const EditDocuments = (props: EditDocumentsProps) => {
  const { polyglot } = usePolyglot();
  const { onSave, allDocumentTypes } = props;
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>(props.documentTypes ?? []);
  const [otherDocumentError, setOtherDocumentError] = useState(false);
  const [otherCount, setOtherCount] = useState<{ label: string | undefined; id: number }[] | undefined>(
    props.otherDocumentNames?.map((o, idx) => {
      return { label: o, id: idx };
    }) ?? undefined
  );

  return (
    <>
      <Typography sx={themeFonts.title2}>{polyglot.t('EditDocuments.select')}</Typography>
      <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
        <br />
        {polyglot.t('EditDocuments.chooseDocument2')}{' '}
        <Link target="_blank" rel="noreferrer" href={SETTINGS_DOCUMENTS_TYPES_ROUTE}>
          {polyglot.t('EditDocuments.here')}
        </Link>
      </Typography>
      <Stack sx={{ mt: spacing.mt30, gap: spacing.g20 }}>
        {allDocumentTypes.map((eachDocumentType) => {
          return (
            <CheckboxComponent
              key={eachDocumentType.value}
              label={eachDocumentType.label}
              checked={selectedDocumentTypes.includes(eachDocumentType.value)}
              onChange={(_, checked) => {
                setSelectedDocumentTypes(
                  checked
                    ? [...selectedDocumentTypes, eachDocumentType.value]
                    : selectedDocumentTypes.filter((type) => type !== eachDocumentType.value)
                );
                if (eachDocumentType.value === 'other') setOtherCount([{ label: undefined, id: 0 }]);
              }}
            />
          );
        })}
      </Stack>
      {selectedDocumentTypes.includes('other') && (
        <>
          {otherCount?.map((o) => (
            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: spacing.g10 }}>
              <TextfieldComponent
                key={o.id}
                autoFocus
                type="text"
                label={polyglot.t('EditDocuments.add')}
                value={o.label}
                onChange={(e) => {
                  const index = otherCount.indexOf(o);
                  otherCount[index].label = e.target.value;
                  setOtherCount([...otherCount]);
                  setOtherDocumentError(false);
                }}
                sx={{ mt: spacing.mt20 }}
                helperText={otherDocumentError && !o.label && polyglot.t('EditDocuments.create')}
                error={otherDocumentError && !o.label}
              />
              {otherCount.indexOf(o) !== 0 && (
                <IconButton
                  sx={tableIconButtonSx}
                  onClick={() => {
                    setOtherCount(otherCount.filter((record) => record.id !== o.id));
                  }}
                >
                  <Trash {...iconSize} />
                </IconButton>
              )}
            </Box>
          ))}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...spacing.mt20 }}>
            <Button
              sx={secondaryCTABtn}
              onClick={() => {
                if (otherCount) setOtherCount([...otherCount, { label: undefined, id: otherCount?.length + 1 }]);
                else setOtherCount([{ label: undefined, id: 0 }]);
              }}
            >
              <Plus {...iconSize} /> &nbsp;{polyglot.t('EditDocuments.addOther')}
            </Button>
          </Box>
        </>
      )}

      <ButtonComponent
        type="button"
        onClick={async () => {
          const hasOther = selectedDocumentTypes.includes('other');
          const otherCountUndefined = otherCount?.filter((o) => !o.label);
          if (hasOther && otherCountUndefined && otherCountUndefined?.length > 0) {
            setOtherDocumentError(true);
            return;
          }
          setOtherDocumentError(false);
          const otherDocumentName = otherCount?.map((o) => o.label).filter(Boolean) ?? [];
          onSave(selectedDocumentTypes, otherDocumentName as string[]);
        }}
        fullWidth
        colorVariant="primary"
        sizeVariant="large"
        style={{ marginTop: '40px' }}
      >
        {polyglot.t('General.save')}
      </ButtonComponent>
    </>
  );
};
