import { useMemo } from 'react';

import { AutocompleteComponent, OptionObject } from '@/v2/components/forms/autocomplete.component';

type PaymentEntitySelectProps = {
  name: string;
  label: string;
  values: string[];
  value: string;
  disabled?: boolean;
  onChange?: (newValue: string) => void;
  error?: boolean;
  helperText?: React.ReactNode;
};

export const PaymentEntitySelect = ({
  name,
  label,
  value,
  values,
  disabled,
  onChange,
  error,
  helperText,
}: PaymentEntitySelectProps) => {
  const options = useMemo(() => {
    const uniqueValues = new Set(values);
    uniqueValues.add(value);
    return [...uniqueValues]
      .filter((x) => x)
      .sort()
      .map<OptionObject>((value) => ({
        label: value,
        value,
      }));
  }, [value, values]);

  return (
    <AutocompleteComponent
      name={name}
      label={label}
      options={options}
      value={options.find((d) => d.value === value)}
      disabled={disabled}
      freeSolo
      onBlur={(e) => {
        // in freeSolo mode, Autocomplete only triggers onChange for new values after
        // the user presses enter. This is not obvious so we always save the value when the control loses focus.
        onChange?.((e.target as HTMLInputElement).value);
      }}
      onChange={(e, update) => {
        if (Array.isArray(update)) return;
        if (update) {
          onChange?.(typeof update === 'object' ? (update.value as string) : update);
        } else {
          onChange?.('');
        }
      }}
      error={error}
      helperText={helperText}
    />
  );
};
