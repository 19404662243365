import { useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { Typography } from '@/v2/components/typography/typography.component';
import { getDateFormation } from '@/v2/feature/dashboard/dashboard.util';
import { UserEventDetailDrawer } from '@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-event-detail-drawer.component';
import {
  AbsenceProps,
  EventProps,
  MappedEventsProps,
} from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserEventCard = ({
  event,
  type,
  setSelectedAbsence,
}: {
  event: MappedEventsProps;
  type: string;
  setSelectedAbsence: React.Dispatch<React.SetStateAction<AbsenceProps | undefined>>;
}) => {
  const { polyglot } = usePolyglot();
  const [selectedEvent, setSelectedEvent] = useState<EventProps | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box
      key={`-${event ? event?.userId : Math.random()}-user-absence`}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: spacing.g3,
        '&:hover': { background: themeColors.transparency, cursor: 'pointer' },
        borderRadius: radius.br10,
        width: '100%',
      }}
      onClick={() => {
        if (event.isAbsence) {
          setSelectedAbsence({ absenceId: event.absenceId, userId: event.userId });
        } else {
          setSelectedEvent({
            type: event.type,
            userId: event.userId,
            startDate: event.startDate,
            endDate: event.endDate,
          });
          setIsOpen(true);
        }
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          height: '100%',
          width: '100%',
          padding: spacing.p3,
          gap: spacing.s1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing.sm,
            justifyContent: 'flex-start',
            overflow: 'hidden',
          }}
        >
          {event.userId && <UserAvatar userId={event.userId} size="xxxsmall" key={event.userId} />}
          <Typography
            variant="caption"
            sx={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'start',
              width: '100%',
            }}
          >
            {polyglot.t(event.displayName)}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          sx={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'start',
            width: '100%',
          }}
        >
          {getDateFormation(event.startDate, event.endDate)}
          {type ? `・${type}` : ''}
        </Typography>
      </Box>

      <UserEventDetailDrawer
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </Box>
  );
};
