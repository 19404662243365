import { AttendanceReportResults } from '@v2/feature/reports/features/create-report/attendance/attendance-report-results.component';
import { DevicesReportResults } from '@v2/feature/reports/features/create-report/devices/devices-report-results.component';
import { PayrollReportType } from '@v2/feature/reports/features/create-report/payroll/payroll-report-type.component';
import { PeopleReportResults } from '@v2/feature/reports/features/create-report/people/people-report-results.component';
import { TimeReportResults } from '@v2/feature/reports/features/create-report/time/time-report-results.component';
import { DataDomain, FormDataInterface } from '@v2/feature/reports/reports.interface';

export const ReportResults = (props: {
  setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  formData: FormDataInterface;
}) => {
  return (
    <>
      {{
        [DataDomain.Devices]: () => <DevicesReportResults formData={props.formData} setFormData={props.setFormData} />,
        [DataDomain.Payroll]: () => <PayrollReportType />,
        [DataDomain.People]: () => <PeopleReportResults formData={props.formData} setFormData={props.setFormData} />,
        [DataDomain.TimeAway]: () => <TimeReportResults formData={props.formData} setFormData={props.setFormData} />,
        [DataDomain.Attendance]: () => (
          <AttendanceReportResults formData={props.formData} setFormData={props.setFormData} />
        ),
      }[props.formData.domain]()}
    </>
  );
};
