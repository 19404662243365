import Box from '@mui/material/Box';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const buttonStyles = {
  paddingX: spacing.p10,
  paddingY: spacing.p5,
  borderRadius: radius.br20,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
};

interface OptionObject {
  label: string;
  value: string;
}

export const SwitchButton = ({
  color,
  action,
  options,
  selectedValue,
}: {
  color: string;
  action: (value: string) => Promise<void> | void;
  options: OptionObject[];
  selectedValue: string;
}) => {
  return (
    <Box
      sx={{
        width: '150px',
        height: '30px',
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {options.map((option) => {
        const isActive = option.value === selectedValue;
        return (
          <Box
            key={option.value}
            className={isActive ? 'switch-no-hover' : 'switch-hover'}
            sx={{
              ...buttonStyles,
              color: isActive ? themeColors.white : themeColors.DarkGrey,
              background: isActive ? color : 'none',
              transition: 'all 0.2s ease-in-out',
            }}
            onClick={() => action(option.value)}
          >
            <Typography
              variant="caption"
              sx={{ color: isActive ? themeColors.white : themeColors.DarkGrey }}
              className={isActive ? 'tnm-switch-active-text' : 'tnm-switch-text'}
            >
              {option.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
