import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CurrentUser } from '@/models';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { SSOState } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsAPI } from '@/v2/feature/app-integration/features/app-details/app-details.api';
import { EnableSSODisableMFAConflictConfirmDrawer } from '@/v2/feature/app-integration/features/app-details/components/app-details-sso-enable-mfa-conflict-drawer.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthLoginDtoWithUserDetails } from '@/v2/feature/auth/auth.dto';
import { getSSOLoginResponse } from '@/v2/feature/auth/auth.util';
import { ENABLED_SSO_APPS } from '@/v2/feature/auth/features/auth-login/auth-login.page';
import { SSOOktaDomainToolTipInstructions } from '@/v2/feature/security/security-settings/components/sso-okta-domain-tooltip.component';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { isDefined } from '@/v2/feature/user-onboarding/user-onboarding.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SSOOktaMetadata {
  apikey: string | undefined;
  oktaDomain: string | undefined;
  ssoEnabled: boolean;
}

interface SSOOktaUpdateFormProps {
  mfaEnabled: boolean;
  ssoState: SSOState[];
  onUpdate: VoidFunction;
  currentUser: CurrentUser;
}

export const SSOOktaUpdateForm = ({
  mfaEnabled,
  ssoState,
  onUpdate,
  currentUser,
}: SSOOktaUpdateFormProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [oktaDomain, setOktaDomain] = useState<string>('');
  const [ssoEnabled, setSsoEnabled] = useState<boolean>(true);
  const [apikey, setApikey] = useState<string>('');
  const [confirmEnablement, setConfirmEnablement] = useState<boolean>(false);
  const [confirmEnableSSOAndDisableMFA, setConfirmEnableSSOAndDisableMFA] = useState<boolean>(false);
  const [testingSSO, setTestingSSO] = useState<boolean>(false);
  // const [ssoEnabled, setSSOEnabled] = useState<boolean>(false);
  // const [matchingProvider, setMatchingProvider] = useState<SSOCheck | undefined>();

  const refresh = useCallback(async () => {
    try {
      const ssoOktaEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-okta');
      if (ssoOktaEntry) {
        setOktaDomain(ssoOktaEntry?.state?.siteUrl ?? '');
        setApikey(ssoOktaEntry?.state?.apikey ?? '');
      }
      const someEntryEnabled = ssoState?.some((eachEntry) => eachEntry.state.enabled);
      setSsoEnabled(someEntryEnabled);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t('SSOOktaUpdateForm.errorMessages.fetch')}: ${JSON.stringify(error)}`, 'error');
    }
  }, [polyglot, showMessage, ssoState]);

  useEffect(() => {
    refresh();
  }, [refresh, ssoState]);

  const saveSsoOidcMetadata = async (values: SSOOktaMetadata) => {
    try {
      if (!ssoEnabled && mfaEnabled && !confirmEnableSSOAndDisableMFA) {
        setConfirmEnablement(true);
        return;
      }
      if (!values || Object.keys(values)?.length === 0) return;
      setLoading(true);
      const finalPayload = {
        apikey: isDefined(values.apikey) ? values.apikey : '',
        siteUrl: isDefined(values.oktaDomain) ? values.oktaDomain : '',
        enabled: true,
      };
      const encoded = btoa(JSON.stringify(finalPayload));
      if (values.apikey && values.oktaDomain && encoded) {
        await AppDetailsAPI.saveSSOktaMetadata({
          sso_oidc_metadata: encoded,
          disable_mfa: confirmEnableSSOAndDisableMFA,
        });
      }
      await testOktaSSOLogin();

      // showMessage('Successfully updated Okta SSO Metdata', 'success');
      // onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t('SSOOktaUpdateForm.errorMessages.save')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
      refresh();
    }
  };

  const oktaEnabled = useMemo(() => {
    return !!ssoState.find((eachApp) => eachApp.app === 'sso-okta' && eachApp.state.enabled);
  }, [ssoState]);

  const disableOktaSSO = async (forced = false) => {
    try {
      if (!forced && !oktaEnabled) return;
      setLoading(true);
      await AppDetailsAPI.disableSSOOkta();

      if (!forced) showMessage(polyglot.t('SSOOktaUpdateForm.successMessages.disable'), 'success');
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t('SSOOktaUpdateForm.errorMessages.disable')}: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
      refresh();
    }
  };

  const formik = useFormik<SSOOktaMetadata>({
    initialValues: {
      apikey: undefined,
      oktaDomain: undefined,
      ssoEnabled,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      apikey: Yup.string().required(polyglot.t('SSOOktaUpdateForm.errorMessages.tknRequired')),
      oktaDomain: Yup.string()
        .url(polyglot.t('SSOOktaUpdateForm.errorMessages.validUrlRequired'))
        .required(polyglot.t('SSOOktaUpdateForm.errorMessages.oktaDomainRequired'))
        .test(
          'url-should-not-include-trailing-slash',
          polyglot.t('SSOOktaUpdateForm.errorMessages.urlNoTrailingSlash'),
          (value) => !value?.endsWith('/')
        ),
    }),
    onSubmit: saveSsoOidcMetadata,
  });

  const triggerEnableSSOAndDisableMFA = () => {
    setConfirmEnableSSOAndDisableMFA(true);
    formik.handleSubmit();
  };

  const testOktaSSOLogin = async () => {
    setTestingSSO(true);
    try {
      const loginCheckData = await AuthAPI.ssoCheck(currentUser.emailAddress);
      const ssoConfig = loginCheckData?.ssoConfig;
      const matchingProviderData = ssoConfig.find(
        (eachApp) => ENABLED_SSO_APPS.includes(eachApp.app) && eachApp.enabled
      );
      if (matchingProviderData) {
        const loginResult = (await getSSOLoginResponse(
          currentUser.emailAddress,
          matchingProviderData
        )) as AuthLoginDtoWithUserDetails;
        if (loginResult.accessToken && loginResult.userId && loginResult.companyId === currentUser.company.companyId) {
          showMessage(polyglot.t('SSOOktaUpdateForm.successMessages.ssoTest'), 'success');
          onUpdate();
        } else {
          disableOktaSSO(true);
          showMessage(polyglot.t('SSOOktaUpdateForm.errorMessages.ssoTest'), 'error');
        }
      }
    } catch (error) {
      showMessage(
        `${polyglot.t('SSOOktaUpdateForm.errorMessages.ssoTestErrorEncountered')}: ${nestErrorMessage(error)}`,
        'error'
      );
      console.error('Encountered an error while trying to test SSO: ', error);
      disableOktaSSO(true);
    } finally {
      setTestingSSO(false);
    }
  };

  return (
    <Box sx={{ width: '100%', mt: spacing.mt10 }}>
      {!testingSSO && !oktaEnabled && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: spacing.m10 }}>
              {`${polyglot.t('SSOOktaUpdateForm.provideOktatkn')}.`}
              <br />
              <br />
              {`${polyglot.t('SSOOktaUpdateForm.setupnote')}.`}
            </Typography>
          </Box>
          <>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: spacing.mt20 }} component="section">
                  <Box sx={{ width: '100%' }}>
                    <Box sx={fieldSx}>
                      <TextfieldComponent
                        fullWidth
                        label={polyglot.t('SSOOktaUpdateForm.oktaDomain')}
                        name="oktaDomain"
                        placeholder={oktaDomain}
                        value={formik.values.oktaDomain}
                        onChange={formik.handleChange}
                        error={formik.touched.oktaDomain && !!formik.errors.oktaDomain}
                        helperText={(formik.touched.oktaDomain && formik.errors.oktaDomain) as string}
                        clearText={() => formik.setFieldValue('oktaDomain', '')}
                        tooltip={(<SSOOktaDomainToolTipInstructions />) as JSX.Element}
                      />
                    </Box>
                    <Box sx={fieldSx}>
                      <TextfieldComponent
                        fullWidth
                        label={polyglot.t('SSOOktaUpdateForm.oidcToken')}
                        name="apikey"
                        placeholder={apikey}
                        value={formik.values.apikey}
                        onChange={formik.handleChange}
                        error={formik.touched.apikey && !!formik.errors.apikey}
                        helperText={(formik.touched.apikey && formik.errors.apikey) as string}
                        clearText={() => formik.setFieldValue('apikey', '')}
                      />
                    </Box>
                  </Box>
                </Box>
                <Stack sx={{ flexFlow: 'row', my: spacing.m20 }}>
                  <LoaderButton
                    fullWidth
                    disabled={
                      (formik.values.ssoEnabled && (!formik.values.apikey || !formik.values.oktaDomain)) ||
                      !formik.dirty ||
                      loading
                    }
                    sizeVariant="medium"
                    colorVariant="primary"
                    name={polyglot.t('General.save')}
                    loading={loading}
                  />
                </Stack>
              </Form>
            </FormikProvider>
            <EnableSSODisableMFAConflictConfirmDrawer
              isOpen={confirmEnablement}
              setIsOpen={setConfirmEnablement}
              onClose={() => setConfirmEnablement(false)}
              onConfirm={() => triggerEnableSSOAndDisableMFA()}
            />
          </>
        </>
      )}
      {!testingSSO && oktaEnabled && (
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: spacing.mt20 }} component="section">
                <Box sx={{ width: '100%' }}>
                  <Box sx={fieldSx}>
                    <TextfieldComponent
                      label={polyglot.t('SSOOktaUpdateForm.oktadomain')}
                      name="oktaDomain"
                      placeholder={oktaDomain}
                      value={formik.values.oktaDomain}
                      onChange={formik.handleChange}
                      error={formik.touched.oktaDomain && !!formik.errors.oktaDomain}
                      helperText={(formik.touched.oktaDomain && formik.errors.oktaDomain) ?? ' '}
                      clearText={() => formik.setFieldValue('oktaDomain', '')}
                      tooltip={(<SSOOktaDomainToolTipInstructions />) as JSX.Element}
                    />
                  </Box>
                  <Box sx={fieldSx}>
                    <TextfieldComponent
                      label={polyglot.t('SSOOktaUpdateForm.apiTkn')}
                      name="apikey"
                      placeholder={apikey}
                      value={formik.values.apikey}
                      onChange={formik.handleChange}
                      error={formik.touched.apikey && !!formik.errors.apikey}
                      helperText={(formik.touched.apikey && formik.errors.apikey) ?? ' '}
                      clearText={() => formik.setFieldValue('apikey', '')}
                    />
                  </Box>
                </Box>
              </Box>
              <Stack sx={{ flexFlow: 'row', mt: spacing.m20, gap: spacing.g10, justifyContent: 'center' }}>
                <ButtonComponent
                  sizeVariant="medium"
                  colorVariant="secondary"
                  onClick={() => {
                    disableOktaSSO();
                  }}
                  disabled={loading}
                >
                  {polyglot.t('SSOOktaUpdateForm.disable')}
                </ButtonComponent>
                <LoaderButton
                  disabled={
                    (formik.values.ssoEnabled && (!formik.values.apikey || !formik.values.oktaDomain)) ||
                    !formik.dirty ||
                    loading
                  }
                  sizeVariant="medium"
                  colorVariant="primary"
                  name={polyglot.t('SSOOktaUpdateForm.update')}
                  loading={loading}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
      {testingSSO && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, mt: spacing.m10 }}>
            {polyglot.t('SSOOktaUpdateForm.testingsso')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
