import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { generatePath, useHistory } from 'react-router-dom';

import { ReactComponent as Loader } from '@/images/side-bar-icons/Loader.svg';
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import {
  formatDueDateMessage,
  formatPayrunPeriod,
} from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { navigateToPayrunsView } from '@/v2/feature/payroll/payroll-router.util';
import { PayrollAPI, PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollEntryProps = {
  payroll: CompanyPayroll;
  loading?: boolean;
  disabled?: boolean;
  onPayrollStartClick: (payrollId: number) => void;
  sx?: SxProps<Theme>;
};

const PayrollEntry = ({ loading, payroll, onPayrollStartClick, disabled, sx }: PayrollEntryProps) => {
  const { polyglot } = usePolyglot();
  const activePaySchedule = payroll.schedule;

  const payrun = payroll.currentPayRun ?? payroll.nextPayRun;
  const { employeeCount } = payroll;

  const [dueDateMessage, isOverdue] = useMemo(() => {
    if (payrun) return formatDueDateMessage(payrun.paymentDate, polyglot);
    if (activePaySchedule) return formatDueDateMessage(activePaySchedule.firstPaymentDate, polyglot);
    return [undefined, false];
  }, [payrun, activePaySchedule, polyglot]);

  return (
    <Stack
      sx={{
        flexFlow: 'row',
        gap: spacing.g15,
        background: themeColors.Background,
        padding: spacing.p20,
        borderRadius: radius.br15,
        ...(!disabled && {
          ':hover': { backgroundColor: themeColors.GreyHover },
          transition: 'background-color .2s linear',
          cursor: 'pointer',
        }),
        ...sx,
      }}
      onClick={() => !disabled && onPayrollStartClick(payroll.id)}
    >
      <Stack sx={{ gap: spacing.g5 }}>
        <Typography variant="title4">{payroll.displayName}</Typography>
        <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
          <Typography variant="caption">
            {polyglot.t('CompanyPayroll.noOfEmployees', { smart_count: employeeCount })}
          </Typography>
          {dueDateMessage && (
            <>
              <Typography variant="caption">&bull;</Typography>
              <Typography variant="caption" sx={{ color: isOverdue ? themeColors.Red : undefined }}>
                {dueDateMessage}
              </Typography>
            </>
          )}
          {!activePaySchedule && (
            <>
              <Typography variant="caption">&bull;</Typography>
              <Typography variant="caption">{polyglot.t('CompanyPayrollList.noPayrollSchedule')}</Typography>
            </>
          )}
          {payrun && (
            <>
              <Typography variant="caption">&bull;</Typography>
              <Typography variant="caption">{formatPayrunPeriod(payrun, polyglot.locale())}</Typography>
            </>
          )}
          {!payrun && activePaySchedule && (
            <>
              <Typography variant="caption">&bull;</Typography>
              {/* if a new payroll is set up with a schedule, there's no current or next payrun info
               so just use the first pay period details from the schedule */}
              <Typography variant="caption">{formatPayrunPeriod(activePaySchedule, polyglot.locale())}</Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Loader style={{ display: loading ? 'initial' : 'none', marginLeft: 'auto' }} />
    </Stack>
  );
};

export const PayrollCompanyListPayrolls = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [loadingPayroll, setLoadingPayroll] = useState<number>();
  const { trackPage } = useJune();

  const { data } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });
  const payrolls = data?.items;

  const viewPayrunsForPayroll = useCallback(
    (payrollId: number) => {
      navigateToPayrunsView(routerHistory, 'push', payrollId);
    },
    [routerHistory]
  );

  const onPayrollStartClick = useCallback(
    async (payrollId: number) => {
      setLoadingPayroll(payrollId);
      try {
        const payrolls = await PayrollAPI.getCompanyPayrolls();
        const payroll = payrolls.items.find((p) => p.id === payrollId);
        if (!payroll) {
          throw new Error(polyglot.t('CompanyPayrollList.payrollNotFound'));
        }
        if (payroll.currentPayRun) {
          viewPayrunsForPayroll(payroll.id);
          return;
        }
        if (payroll.nextPayRun) {
          viewPayrunsForPayroll(payroll.id);
          return;
        }
        // if we don't have a current or next payrun defined, show the setup page
        routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId }));
      } catch (e) {
        setLoadingPayroll(undefined);
      }
    },
    [routerHistory, viewPayrunsForPayroll, polyglot]
  );

  const loading = !payrolls;

  useEffect(() => {
    trackPage('Payroll hub');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">{polyglot.t('CompanyPayroll.salary')}</Typography>} />
      <ContentWrapper loading={loading} sx={{ px: '40px' }}>
        <Stack sx={{ maxWidth: '650px', gap: spacing.g15 }}>
          {payrolls?.map((payroll) => (
            <React.Fragment key={payroll.id}>
              <PayrollEntry
                payroll={payroll}
                onPayrollStartClick={onPayrollStartClick}
                loading={loadingPayroll === payroll.id}
                disabled={!!loadingPayroll}
              />
            </React.Fragment>
          ))}
        </Stack>
      </ContentWrapper>
    </RootStyle>
  );
};
