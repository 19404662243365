import { useContext, useState } from 'react';

import { Box, FormControl, IconButton } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';

import { EquityAPI } from '@/api-client/equity.api';
import {
  CreateUserEquity,
  UpdateUserEquity,
  UserEquity,
} from '@/component/dashboard/userDetails/validations/userFormDefinitions';
import { UserEquitySchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { equityTypeOptions } from '@/lib/employment';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { ProfileField } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { popularCurrencyShortOptions } from '@/v2/infrastructure/currency/currency.interface';
import { iconCTAButtonSx } from '@/v2/styles/icon-button.styles';

interface Props {
  readonly initialValues?: Partial<UserEquity>;
  readonly userId: number;
  readonly refreshUserEquityData: () => Promise<void>;
  readonly rowModalMode: string;
  readonly handleSubmit: (userId: number) => void;
  readonly setFormCurrentlyEditing: (beingEdited: boolean) => void;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly loading: boolean;
  readonly onClose: () => void;
  usedForDataImport?: boolean;
  readonly importHandler?: (values: Partial<UserEquity>) => void;
}

export const EquityForm = ({
  initialValues,
  userId,
  refreshUserEquityData,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {},
}: Props) => {
  const { polyglot } = usePolyglot();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [rowForDeletion, setRowForDeletion] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();

  const defaultBlankModalValues: Partial<UserEquity> = {
    id: 0,
    grantDate: undefined,
    type: '',
    amount: 0,
    vestingStart: undefined,
    vestingPeriod: 0,
    vestingCliff: 0,
    unitPrice: 0,
    currency: '',
    reason: '',
  };

  const formik = useFormik({
    initialValues: initialValues ?? defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: UserEquitySchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (!usedForDataImport) {
          const modalUserEquityBody = {
            ...values,
            reason: values.reason,
            type: values.type,
            amount: Number(values?.amount),
            unitPrice: Number(values?.unitPrice),
            vestingStart: values.vestingStart ? values.vestingStart : undefined,
            vestingPeriod: values.vestingPeriod ? +values.vestingPeriod : 0,
            vestingCliff: values.vestingCliff ? +values.vestingCliff : 0,
            currency: values?.currency && values?.currency.length > 0 ? values.currency : '...',
            userId,
            createdBy: state.user.userId,
            updatedBy: state.user.userId,
            companyId: state.user.company.companyId,
          };

          if (rowModalMode === 'add') {
            await EquityAPI.create(modalUserEquityBody as CreateUserEquity);
            showMessage(polyglot.t('EquityForm.successMessages.add'), 'success');
          } else if (rowModalMode === 'edit' && !!values.id) {
            await EquityAPI.updateById({ ...modalUserEquityBody, id: values.id } as UpdateUserEquity);
            showMessage(polyglot.t('EquityForm.successMessages.update'), 'success');
          } else {
            showMessage(polyglot.t('EquityForm.errorMessages.add'), 'error');
          }
          await refreshUserEquityData();
          formik.resetForm();
          setIsModalOpen(false);
        } else {
          importHandler?.(values);
        }
      } catch (error) {
        showMessage(
          `${polyglot.t('EquityForm.errorMessages.update')}: ${nestErrorMessage(error)}; Please try again.`,
          'error'
        );
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    },
  });

  const deleteEquityRow = (rowId: number) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };

  const handleDeleteRow = async () => {
    try {
      if (rowForDeletion) await EquityAPI.deleteById(userId, rowForDeletion);
      showMessage(polyglot.t('EquityForm.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(polyglot.t('General.delete'), 'error');
    } finally {
      await refreshUserEquityData();
      handleSubmit(userId);
      setFormCurrentlyEditing(false);
      setRowForDeletion(0);
      formik.resetForm();
      setIsModalOpen(false);
      setIsRemovalModalOpen(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {rowModalMode === 'add' ? (
          <Typography variant="title2">{polyglot.t('EquityForm.new')}</Typography>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="title2">{polyglot.t('EquityForm.edit')}</Typography>
            {formik.values?.id && (
              <IconButton
                sx={iconCTAButtonSx}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  if (!formik.values.id) {
                    showMessage('Missing row.', 'error');
                  } else {
                    deleteEquityRow(formik.values.id);
                    setIsRemovalModalOpen(true);
                  }
                }}
              >
                <TrashIcon {...actionIconSize} />
              </IconButton>
            )}
          </Box>
        )}

        <ProfileField fieldStub="equity.type">
          <FormControl fullWidth size="small">
            <SelectComponent
              name="type"
              label={polyglot.t('EquityForm.type')}
              options={equityTypeOptions(polyglot)}
              value={formik.values.type}
              compareValue={formik.values.type}
              error={!!formik.errors.type && formik.touched.type}
              onChange={formik.handleChange}
              helperText={formik.errors.type && formik.touched.type}
            />
          </FormControl>
        </ProfileField>

        <ProfileField fieldStub="equity.grantDate">
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.grantDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('grantDate', value);
              }
            }}
            name="grantDate"
            label={polyglot.t('EquityForm.grantDate')}
            error={!!formik.errors.grantDate && formik.touched.grantDate}
            helperText={formik.errors.grantDate && formik.touched.grantDate}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.amount">
          <TextfieldComponent
            name="amount"
            label={polyglot.t('EquityForm.amount')}
            value={formik.values.amount}
            type="number"
            onChange={formik.handleChange}
            error={formik.touched.amount && !!formik.errors.amount}
            helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
            clearText={() => formik.setFieldValue('amount', '')}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.vestingStart">
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.vestingStart ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('vestingStart', value);
              }
            }}
            name="vestingStart"
            label={polyglot.t('EquityForm.vestingStart')}
            error={!!formik.errors.vestingStart && formik.touched.vestingStart}
            helperText={formik.errors.vestingStart && formik.touched.vestingStart}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.vestingPeriod">
          <TextfieldComponent
            name="vestingPeriod"
            label={polyglot.t('EquityForm.vestingPeriod')}
            value={formik.values.vestingPeriod}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.vestingPeriod && !!formik.errors.vestingPeriod}
            helperText={(formik.touched.vestingPeriod && formik.errors.vestingPeriod) ?? ' '}
            clearText={() => formik.setFieldValue('vestingPeriod', '')}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.vestingCliff">
          <TextfieldComponent
            name="vestingCliff"
            label={polyglot.t('EquityForm.vestingCliff')}
            value={formik.values.vestingCliff}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.vestingCliff && !!formik.errors.vestingCliff}
            helperText={(formik.touched.vestingCliff && formik.errors.vestingCliff) ?? ' '}
            clearText={() => formik.setFieldValue('vestingCliff', '')}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.unitPrice">
          <TextfieldComponent
            name="unitPrice"
            label={polyglot.t('EquityForm.exercisePrice')}
            value={formik.values.unitPrice}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.unitPrice && !!formik.errors.unitPrice}
            helperText={(formik.touched.unitPrice && formik.errors.unitPrice) ?? ' '}
            clearText={() => formik.setFieldValue('unitPrice', '')}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.currency">
          <SelectComponent
            name="currency"
            label={polyglot.t('EquityForm.currency')}
            options={popularCurrencyShortOptions}
            value={formik.values.currency}
            compareValue={formik.values.currency}
            error={!!formik.errors.currency && formik.touched.currency}
            onChange={formik.handleChange}
            helperText={formik.errors.currency && formik.touched.currency}
          />
        </ProfileField>

        <ProfileField fieldStub="equity.reason">
          <TextfieldComponent
            name="reason"
            label={polyglot.t('EquityForm.reason')}
            value={formik.values.reason}
            type="string"
            onChange={formik.handleChange}
            error={formik.touched.reason && !!formik.errors.reason}
            helperText={(formik.touched.reason && formik.errors.reason) ?? ' '}
            clearText={() => formik.setFieldValue('reason', '')}
          />
        </ProfileField>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="secondary" onClick={onClose}>
            {polyglot.t('EquityForm.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('EquityForm.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>

        <NotificationModal
          isOpen={isRemovalModalOpen}
          onClose={() => {
            setIsRemovalModalOpen(false);
            setRowForDeletion(0);
          }}
          anchorEl={anchorEl}
          takeAction={handleDeleteRow}
          message={polyglot.t('EquityForm.confirmDelete')}
          callToAction={polyglot.t('EquityForm.callToAction')}
        />
      </Form>
    </FormikProvider>
  );
};
