import { useContext, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { groupBy } from 'lodash';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import {
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
  APP_STORE_ROUTE,
} from '@/lib/routes';
import { TabFilterButtons, TabFilterItem } from '@/v2/components/tab-filter-buttons.component';
import { ActionCard } from '@/v2/components/theme-components/action-card.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { Dictionary, InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { appCurrentlyInstalled } from '@/v2/feature/app-integration/app-integration.util';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

function appStubFromHash(hash: string) {
  return hash.replace('#', '');
}

export const AppStorePage = (): JSX.Element => {
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const routerState = routerLocation.state as { category?: string };
  const existingCategory = routerState?.category;

  const [globalState, dispatch] = useContext(GlobalContext);
  const [filterString, setFilterString] = useState<string>(existingCategory ?? 'Productivity');
  const [tabFilter, setTabFilter] = useState<TabFilterItem[]>([]);
  const { getScopesContext } = useScopes();

  const [categorizedAvailableApps, setCategorizedAvailableApps] = useState<Dictionary<InstalledAppDto[]>>();

  const {
    data: availableApps,
    isValidating: loadingAvailableApps,
  } = useApiClient(AppIntegrationEndpoints.getAvailableApps(), { suspense: false });

  const {
    data: installedApps,
    isValidating: loadingInstalledApps,
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });

  const [app, setApp] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const isInCompanyPath = routerLocation.pathname.includes('company');

  useEffect(() => {
    (async function () {
      const response = await AuthAPI.getAuthMe(false);
      const authUser = response?.user ?? null;
      dispatch({
        type: GlobalStateActions.UPDATE_USER,
        payload: authUser,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const availableAppsByCategory = groupBy(availableApps, 'category');
        const tabFiltersFromCategory: TabFilterItem[] = Object.keys(availableAppsByCategory).map((x) => ({
          name: x,
          value: x,
        }));

        // create tab filter dynamically from fetched apps / categories, to avoid having category mismatches in the UI, with hardcoded tab filters
        setTabFilter(tabFiltersFromCategory);

        setCategorizedAvailableApps(availableAppsByCategory);
      } catch {
        showMessage('Could not load the list of apps.', 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [showMessage, availableApps, installedApps]);

  useEffect(() => {
    const appStubHash = appStubFromHash(routerLocation.hash);
    if (availableApps && availableApps.some((x) => x.stub === appStubHash)) {
      setApp(appStubHash);
    }
  }, [routerLocation.hash, availableApps]);

  useEffect(() => {
    const appStubHash = appStubFromHash(routerLocation.hash);
    if (existingCategory) {
      routerHistory.push({
        pathname: generatePath(APP_STORE_ROUTE),
        state: { category: existingCategory },
      });
      return;
    }
    // TODO changing this logic into stream based reactive programming would result in less clutter
    if (availableApps) {
      if (app) {
        if (appStubHash !== app) {
          routerHistory.push(`${generatePath(APP_STORE_ROUTE)}/#${app}`);
        }
      } else {
        if (appStubHash !== '') {
          routerHistory.push(generatePath(APP_STORE_ROUTE));
        }
      }
    }
  }, [availableApps, app, routerLocation.hash, routerHistory, existingCategory]);

  useEffect(() => {
    const scrollToElement = () => {
      const myEl = document.getElementById(filterString);
      if (myEl) {
        (myEl as any).scrollIntoView({
          block: 'center', // Start, center, end, or nearest. Defaults to start.
          behavior: 'smooth',
        });
      }
    };

    // If myEl is null or not available, apply a delay before scrolling
    if (!document.getElementById(filterString)) {
      const delayTimeout = setTimeout(scrollToElement, 800);

      // Clean up the timeout if the component unmounts or the filterString changes
      return () => clearTimeout(delayTimeout);
    } else {
      scrollToElement(); // If myEl is available, scroll immediately
    }
  }, [filterString]);

  const onTitleClick = (app: InstalledAppDto) => {
    const isAuthorised = app?.authorised;
    let targetRoute;

    if (isAuthorised) {
      targetRoute = isInCompanyPath
        ? APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE
        : APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE;
    } else {
      targetRoute = isInCompanyPath
        ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE
        : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE;
    }

    const path = generatePath(targetRoute, { appStub: app.stub });
    routerHistory.push(path);
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Store</Typography>} />
      <ContentWrapper loading={loading || loadingAvailableApps || loadingInstalledApps} border={false} sx={{ pt: 0 }}>
        <Box>
          <Box sx={{ position: 'fixed', background: themeColors.white, zIndex: 1, width: '100%' }}>
            <TabFilterButtons
              filters={tabFilter}
              setFilterValue={setFilterString}
              filterValue={filterString}
              onFilterChange={({ filterValue }) => {
                setFilterString(filterValue);
              }}
            />
          </Box>
          <Box>
            <div style={{ marginTop: spacing.m60 }}>
              {categorizedAvailableApps &&
                Object.entries(categorizedAvailableApps).map(([key, value]) => (
                  <Grid container key={key} columnSpacing={spacing.g40} sx={{ marginBottom: spacing.m80 }}>
                    <Grid item sm={12} md={12}>
                      <div id={key}>
                        <Typography
                          sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, marginBottom: spacing.m30 }}
                        >
                          {key}
                        </Typography>
                      </div>
                    </Grid>
                    {value.map((a: InstalledAppDto, index) => (
                      <Grid key={a.name} item sm={6} md={4} sx={{ ...spacing.mb20 }}>
                        <ActionCard
                          {...a}
                          key={a.name}
                          title={a.name}
                          titleClick={() => onTitleClick(a)}
                          description={a.appStoreBlurb ? a.appStoreBlurb : ''}
                          avatarImg={`/app-icons-v2/images/${a.stub}.png`}
                          active={(installedApps && appCurrentlyInstalled(installedApps, a.stub)) ?? false}
                          actionText="Connect"
                          showAction={true}
                          actionClick={() => {
                            // OLD RESTRICTION
                            // if (globalState.user.restrictions.TECH.disableApps) {
                            //   setUpgradeModalOpen(true);
                            // } else {
                            routerHistory.push(
                              generatePath(
                                isInCompanyPath
                                  ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE
                                  : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
                                {
                                  appStub: a.stub,
                                }
                              )
                            );
                            // }
                          }}
                          showDivider={index < value.length - 3} //should only be shown after the first row of apps, if there are more rows
                          actionScope={['apps.connect', 'apps:all']}
                          context={getScopesContext({ userId: globalState.user.userId })}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ))}
            </div>
          </Box>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
