import { PaymentYapilyConnect } from '@v2/feature/payments/features/make-payment/components/payment-yapily-connect.component';

import { usePaymentContext } from '@/v2/feature/payments/features/make-payment/payment.context';
import { PaymentsAPI } from '@/v2/feature/payments/payments.api';
import { PaymentResponseDto } from '@/v2/feature/payments/payments.dto';

export const PaymentYapilyConnectStep = ({
  onNext,
  setUnsafeClose,
}: {
  onNext: () => void;
  setUnsafeClose: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [paymentContext] = usePaymentContext();

  const makePayment = async (groupPayments: boolean, groupPaymentsReference: string): Promise<PaymentResponseDto> => {
    setUnsafeClose(true);
    if (paymentContext.payments?.length === 1) {
      return PaymentsAPI.executeSinglePaymentRequest(
        paymentContext.institution?.id!,
        paymentContext.payments[0].id,
        paymentContext.executionDateTime as Date,
        paymentContext.payerDetails
      );
    } else if (paymentContext.payments && paymentContext.payments?.length > 1) {
      return PaymentsAPI.executeBulkPaymentRequest(
        paymentContext.institution?.id!,
        paymentContext.payments?.map((payment) => payment.id),
        paymentContext.executionDateTime as Date,
        paymentContext.payerDetails,
        groupPayments,
        groupPaymentsReference
      );
    }
    return PaymentsAPI.initSinglePaymentRequest(paymentContext.institution?.id!, paymentContext.requests[0]!);
  };

  return (
    <PaymentYapilyConnect
      requests={paymentContext.requests!}
      institution={paymentContext.institution}
      onSubmit={makePayment}
      onChange={() => onNext()}
      setUnsafeClose={setUnsafeClose}
    />
  );
};
