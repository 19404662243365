import { useCallback } from 'react';

import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { InsuranceSettingsGeneralPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/pages/insurance-settings-general.page';
import { InsuranceSettingsMembersPage } from '@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/pages/insurance-settings-members.page';
import { CustomBenefitCategory } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceEndpoints } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateCustomBenefitCategory } from '@v2/infrastructure/i18n/translate.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE,
  SETTINGS_BENEFITS_ROUTE,
} from '@/lib/routes';

export const getPageConfig = (category: string, id: number, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, { category, id, productType: 'standard' }),
        },
        {
          title: polyglot.t('General.members'),
          stub: 'members',
          path: generatePath(SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, { category, id, productType: 'standard' }),
        },
      ],
    },
  ] as NavConfigItem[];
};

interface InsuranceSettingsRouterProps {
  readonly id: number;
  readonly category: string;
}

export const InsuranceSettingsRouter = ({ id, category }: InsuranceSettingsRouterProps) => {
  const { data: insuranceQuote, mutate: refresh } = useApiClient(InsuranceEndpoints.getInsuranceQuote());

  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const refreshInsuranceQuote = useCallback(async () => {
    try {
      if (refresh) await refresh();
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [polyglot, refresh, showMessage]);

  return (
    <>
      <DomainSideMenuContent
        title={
          insuranceQuote?.policy?.displayName ??
          insuranceQuote?.displayName ??
          translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot)
        }
        subtitle={translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot)}
        pageConfig={getPageConfig(category, id, polyglot)}
        backPath={SETTINGS_BENEFITS_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper>
        {insuranceQuote && (
          <Switch>
            <Route exact path={SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE}>
              <InsuranceSettingsGeneralPage
                insuranceQuote={insuranceQuote}
                refreshInsuranceQuote={refreshInsuranceQuote}
              />
            </Route>

            <Route exact path={SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE}>
              <InsuranceSettingsMembersPage insuranceQuote={insuranceQuote} refreshInsurance={refreshInsuranceQuote} />
            </Route>

            <Redirect to={SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE} />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
};
