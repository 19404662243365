import Polyglot from 'node-polyglot';

import { BELONGS_TO_EVERYONE, OldZeltDocumentType, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { BelongsTo, DocumentType } from '@/v2/feature/documents/documents.util';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';

export interface DocumentUser {
  readonly id: number;
  readonly companyId: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly company?: CompanyDepartmentDto;
  readonly User?: UserSummaryDto;
  readonly userId: number;
  readonly documentId: number;
  readonly document?: Document;
}
export interface Attachment {
  readonly id: number;
  readonly companyId: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly company?: CompanyDepartmentDto;
  readonly recordId: number;
  readonly recordType: string;
  readonly fileUuid: string;
  readonly fileName: string;
}

export const CATEGORIES_FOR_PRIVATE_DOCUMENTS = new Set(['Personal Documents', 'Tax Documents', 'Other']);

export type DocumentAction = 'add' | 'edit' | 'request';

export type ActionReach = 'company' | 'team' | 'me';

export interface DocumentWithBelongsTo extends ZeltDocument {}

export interface AttachmentWithUrl extends Attachment {
  readonly url: string;
  readonly fileName: string;
}

export interface DocumentWithAttachments extends DocumentWithBelongsTo {
  readonly attachments: readonly AttachmentWithUrl[];
}

export interface DocumentFormData {
  readonly type: string;
  readonly id?: number;
  readonly name?: string;
  readonly note?: string;
  readonly fileUuid?: string;
  /** @todo could improve upon type discriminants */
  readonly belongsTo: readonly [typeof BELONGS_TO_EVERYONE] | readonly BelongsTo[];
  /** @todo review multi-attachment */
  readonly attachments: readonly Attachment[];
}

export enum DocumentStatus {
  // Document has no file attached yet
  'Pending' = 'Pending',

  // Document has a file attached
  'Uploaded' = 'Uploaded',

  // Document has a file attached & is a contract. I.e contractId is not null
  'Signed' = 'Signed',

  //
  'MissingInformation' = 'Missing info',

  expiringSoon = 'Expiring soon',

  expired = 'Expired',

  All = 'All',
}

export interface DocumentTypeData {
  documentTypes: OldZeltDocumentType[];
  newDocumentTypes: ZeltDocumentType[];
}

export const getDocumentTypeAvailableToOptions = (polyglot: Polyglot): DocumentTypeAvailableToOption[] => {
  return [
    { value: 'company', label: polyglot.t('getDocumentTypeAvailableToOptions.company') },
    { value: 'personal', label: polyglot.t('getDocumentTypeAvailableToOptions.personal') },
  ];
};

export type DocumentTypeAvailableToType = 'company' | 'personal';

export interface DocumentTypeAvailableToOption {
  value: DocumentTypeAvailableToType;
  label: string;
}

export interface NewDocumentTypeFormValues {
  id: number;
  value: string;
  documentTypeName: string;
  internalTypeName?: string;
  availableTo?: DocumentTypeAvailableToType;
  hidden?: boolean;
}

export const PREVIEWABLE_FILE_FORMATS = ['.pdf', '.png', '.jpg', '.jpeg'];

export type DocumentOrderType = 'ASC' | 'DESC' | undefined;

export interface BulkUploadResult {
  success: number;
  error: number;
  errorDetails: {
    documentId: string;
    errorMessage: string;
  }[];
}

export enum DocumentUploadStatus {
  PENDING = 'pending',
  MAPPED = 'mapped',
  PROCESSING = 'processing',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
}

export enum BulkUploadDocumentActionsMode {
  DELETE = 'delete',
  ASSIGN_OWNER = 'assignOwner',
  ASSIGN_TYPE = 'assignType',
}

export enum BulkActionModeLabelMap {
  'delete' = 'Delete',
  'assignOwner' = 'Assign owner',
  'assignType' = 'Assign type',
}

export interface ConfirmBulkDrawerActionProps {
  mode: BulkUploadDocumentActionsMode;
  owner?: number;
  type?: DocumentType;
}

export type BufferData = {
  data: Buffer;
};

export interface PreviewPayload {
  contentType: string;
  file: BufferData;
  filename: string;
}

export interface DocumentUploadInputFile {
  readonly uuid: string;
  readonly fileName: string;
}

export interface DocumentUploadResponse {
  byteSize: number;
  companyId: number;
  contentType: null;
  createdAt: string;
  fileName: string;
  key: string;
  region: string;
  updatedAt: string;
  userId: number;
  uuid: string;
}
