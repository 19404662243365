import { useContext } from 'react';

import { ReportsTestPage } from '@v2/feature/reports/reports-test/reports-test.page';
import { ReportsV2Router } from '@v2/feature/reports/reports-v2.router';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  REPORT_COMPANY_REPORTS_ROUTE,
  REPORT_COMPANY_REPORTS_TEST,
  REPORT_COMPANY_REPORTS_V2_ROUTE,
  REPORTS_DASHBOARD_ROUTE,
} from '@/lib/routes';
import { AnalyticsCompanyRouter } from '@/v2/feature/analytics/analytics-company.router';
import { ReportsRouter } from '@/v2/feature/reports/reports.router';

export const ReportRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  return (
    <Switch>
      <RouteScopesControl
        scopes={['reports']}
        context={scopesContext}
        path={[REPORT_COMPANY_REPORTS_ROUTE]}
        component={ReportsRouter}
      />

      <RouteScopesControl
        scopes={['reports']}
        context={scopesContext}
        path={[REPORT_COMPANY_REPORTS_V2_ROUTE]}
        component={ReportsV2Router}
      />

      <RouteScopesControl
        scopes={['reports']}
        context={scopesContext}
        path={[REPORT_COMPANY_REPORTS_TEST]}
        // component={ReportsTestPage}
      >
        <ReportsTestPage />
      </RouteScopesControl>

      <RouteScopesControl
        scopes={['company.settings:all', 'payroll:all']}
        context={scopesContext}
        path={[REPORTS_DASHBOARD_ROUTE]}
        component={AnalyticsCompanyRouter}
      />

      <Redirect to={REPORT_COMPANY_REPORTS_ROUTE} />
    </Switch>
  );
};
