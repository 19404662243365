import axios from 'axios';

import { UserRightToWorkInfo } from '@/models';
import { RightToWorkDocumentOption } from '@/models/user.model';

export class RightToWorkAPI {
  static async deleteManualRTWCheck(userId: number, checkId: number) {
    await axios.delete(`/apiv2/users/${userId}/right-work/${checkId}`);
  }
  static async createNewRTWCheck(userId: number, userRightToWorkUpdate: UserRightToWorkInfo): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/right-work`, userRightToWorkUpdate);
  }
  static async updateByUserId(userId: number, userRightToWorkUpdate: UserRightToWorkInfo): Promise<void> {
    await axios.patch(`/apiv2/users/${userId}/right-work`, userRightToWorkUpdate);
  }

  static async getDocumentsByUserId(userId: number): Promise<readonly RightToWorkDocumentOption[]> {
    return (await axios.get(`/apiv2/users/${userId}/right-work/documents`)).data;
  }
}
