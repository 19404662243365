import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Reject } from '@/images/side-bar-icons/Reject.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Reload } from '@/images/side-bar-icons/Reload.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { nestErrorMessage } from '@/lib/errors';
import { TableFilter } from '@/v2/components/table/table-filter.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { AppIntegrationStub } from '@/v2/feature/app-integration/app-integration.interface';
import { AppDetailsTable } from '@/v2/feature/app-integration/features/app-details/components/app-details-table.component';
import { AppRequestAPI, AppRequestEndpoints } from '@/v2/feature/app-integration/features/app-request/app-request.api';
import {
  RequestAlertDto,
  RequestDto,
  RequestStatus,
} from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import {
  filterByRequestedApp,
  filterByRequestStatus,
} from '@/v2/feature/app-integration/features/app-request/app-request.util';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { filterStringToObject } from '@/v2/feature/user/user.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx, tablePrimaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoUILong } from '@/v2/util/date-format.util';

const StatusTextIcon = ({
  status,
  colorText,
  icon,
}: {
  status: RequestStatus;
  colorText: string;
  icon: JSX.Element;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
      {icon}
      <Typography
        sx={{
          ...themeFonts.caption,
          color: colorText,
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};

export const AppRequestPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [_state, dispatch] = useContext(GlobalContext);
  const [filteredAppRequests, setFilteredAppRequests] = useState<readonly RequestDto[]>([]);
  const [filterTypes, setFilterTypes] = useState({});
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('Status=Requested,Pending,Failed');

  const { data: appRequests, isValidating: appRequestsLoading, mutate: refreshAppRequests } = useApiClient(
    AppRequestEndpoints.getAppRequestsForCompany(),
    {
      suspense: false,
    }
  );

  const { mutate: refreshRequestsForUser } = useApiClient(
    AppRequestEndpoints.getAppRequestsForUser('Requested,Scheduled,Pending'),
    { suspense: false }
  );

  const { data: allAvailableApps, isValidating: allAvailableAppsLoading } = useApiClient(
    AppIntegrationEndpoints.getAvailableApps(),
    {
      suspense: false,
    }
  );

  const getAppNameFromStub = useCallback(
    (stub: AppIntegrationStub): string => {
      return allAvailableApps?.find((eachApp) => eachApp.stub === stub)?.name ?? stub;
    },
    [allAvailableApps]
  );

  const getFilterOptions = useCallback(async () => {
    const requestStatus = [
      { label: 'Requested', value: 'Requested' },
      { label: 'Scheduled', value: 'Scheduled' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Rejected', value: 'Rejected' },
      { label: 'Failed', value: 'Failed' },
      { label: 'Completed', value: 'Completed' },
    ];
    const FILTERS = ['Apps', 'Status'];
    let filters = {};
    const uniqueListOfAppsInRequests = appRequests
      ? Array.from(new Set(appRequests.map((r) => r.requestInfo.appStub)))
      : [];
    FILTERS.forEach((filter) => {
      switch (filter) {
        case 'Apps':
          filters = {
            ...filters,
            [filter]: uniqueListOfAppsInRequests.map((stub) => {
              return { label: getAppNameFromStub(stub), value: stub };
            }),
          };
          break;
        case 'Status':
          filters = { ...filters, [filter]: requestStatus };
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }, [appRequests, getAppNameFromStub]);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  useEffect(() => {
    if (appRequests) {
      let filteredRequests = appRequests;
      if (searchInput) {
        filteredRequests = filteredRequests.filter(
          (r) =>
            (r.requestedFor &&
              getCachedUserById(r.requestedFor)?.displayName.toLowerCase().includes(searchInput.toLowerCase())) ||
            (r.requestedFor &&
              getCachedUserById(r.requestedFor)?.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
            (r.requestedFor &&
              getCachedUserById(r.requestedFor)?.lastName.toLowerCase().includes(searchInput.toLowerCase())) ||
            (r.requestedFor &&
              getCachedUserById(r.requestedFor)?.emailAddress.toLowerCase().includes(searchInput.toLowerCase())) ||
            (r.requestInfo &&
              r.requestInfo.appStub &&
              r.requestInfo.appStub.toLowerCase().includes(searchInput.toLowerCase())) ||
            (r.status && r.status.toLowerCase().includes(searchInput.toLowerCase()))
        );
      }
      if (filterString) {
        const filterOptions = filterStringToObject(filterString);
        if (filterOptions) {
          for (const key of Object.keys(filterOptions)) {
            switch (key) {
              case 'Status': {
                filteredRequests = filterByRequestStatus(filteredRequests ?? [], filterOptions[key]);

                break;
              }
              case 'Apps': {
                filteredRequests = filterByRequestedApp(filteredRequests ?? [], filterOptions[key]);

                break;
              }

              default:
                break;
            }
          }
        }
      }
      setFilteredAppRequests(filteredRequests);
    } else {
      setFilteredAppRequests([]);
    }
  }, [appRequests, filterString, getCachedUserById, searchInput]);

  const getIconForRequestStatus = (status: RequestStatus): JSX.Element => {
    if ([RequestStatus.Requested, RequestStatus.Scheduled, RequestStatus.Pending].includes(status))
      return (
        <StatusTextIcon
          status={status}
          colorText={themeColors.Grey}
          icon={<Waiting style={{ fill: themeColors.GreyMiddle }} />}
        />
      );
    if (status === RequestStatus.Failed)
      return (
        <StatusTextIcon status={status} colorText={themeColors.RedDark} icon={<Rejected fill={themeColors.Red} />} />
      );
    if (status === RequestStatus.Rejected)
      return (
        <StatusTextIcon status={status} colorText={themeColors.RedDark} icon={<Rejected fill={themeColors.Red} />} />
      );
    if (status === RequestStatus.Completed)
      return (
        <StatusTextIcon
          status={status}
          colorText={themeColors.DarkGrey}
          icon={<OkGreen style={{ fill: themeColors.Green }} />}
        />
      );
    return <></>;
  };

  const approveAppRequest = useCallback(
    async (requestInfo: RequestDto) => {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to approve app request as there are missing details', 'error');
        return;
      }
      try {
        await AppRequestAPI.approveAppRequest(id);
        refreshAppRequests!();
        refreshRequestsForUser!();
        showMessage('Successfully approved & scheduled app access request!', 'success');
      } catch (error) {
        showMessage(`Encountered an error while trying to approve access request: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );

  const retryFailedAppRequest = useCallback(
    async (requestInfo: RequestDto) => {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to retry app request as there are missing details', 'error');
        return;
      }
      try {
        await AppRequestAPI.retryFailedAppRequest(id);
        refreshAppRequests!();
        refreshRequestsForUser!();
        showMessage('Successfully retried & scheduled app access request!', 'success');
      } catch (error) {
        showMessage(`Encountered an error while trying to retry access request: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );

  const rejectAppRequest = useCallback(
    async (requestInfo: RequestDto) => {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to reject app request as there are missing details', 'error');
        return;
      }
      try {
        await AppRequestAPI.rejectAppRequest(id);
        refreshAppRequests!();
        showMessage('Successfully rejected app access request.', 'success');
      } catch (error) {
        showMessage(`Encountered an error while trying to reject access request: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAppRequests, showMessage]
  );

  const deleteFailedAppRequest = useCallback(
    async (requestInfo: RequestDto) => {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to delete app request as there are missing details', 'error');
        return;
      }
      try {
        await AppRequestAPI.deleteFailedAppRequest(id);
        refreshAppRequests!();
        showMessage('Successfully deleted app access request.', 'success');
      } catch (error) {
        showMessage(`Encountered an error while trying to delete access request: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAppRequests, showMessage]
  );

  const deletePendingAppRequest = useCallback(
    async (requestInfo: RequestDto) => {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to delete app request as there are missing details', 'error');
        return;
      }
      try {
        await AppRequestAPI.deletePendingAppRequest(id);
        refreshAppRequests!();
        refreshRequestsForUser!();
        showMessage('Successfully deleted pending app access request.', 'success');
      } catch (error) {
        showMessage(`Encountered an error while trying to delete access request: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );

  const deleteRejectedAppRequest = useCallback(
    async (requestInfo: RequestDto) => {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to delete app request as there are missing details', 'error');
        return;
      }
      try {
        await AppRequestAPI.deleteRejectedAppRequest(id);
        refreshAppRequests!();
        refreshRequestsForUser!();
        showMessage('Successfully deleted rejected app access request.', 'success');
      } catch (error) {
        showMessage(`Encountered an error while trying to delete access request: ${nestErrorMessage(error)}`, 'error');
      }
    },
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );

  const getActionsForRequestStatus = useCallback(
    (requestObj: RequestDto): JSX.Element => {
      const { status } = requestObj;
      if (status === RequestStatus.Requested)
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <IconButton sx={{ ...tableIconButtonSx }} onClick={() => rejectAppRequest(requestObj)}>
              <Reject {...iconSize} />
            </IconButton>
            <IconButton sx={tablePrimaryIconButtonSx} onClick={() => approveAppRequest(requestObj)}>
              <Chose {...iconSize} />
            </IconButton>
          </Box>
        );
      if (status === RequestStatus.Failed)
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
            <IconButton sx={{ ...tableIconButtonSx }} onClick={() => retryFailedAppRequest(requestObj)}>
              <Reload {...iconSize} />
            </IconButton>
            <IconButton sx={tableIconButtonSx} onClick={() => deleteFailedAppRequest(requestObj)}>
              <Trash {...iconSize} />
            </IconButton>
          </Box>
        );
      if (status === RequestStatus.Pending)
        return (
          <>
            <IconButton sx={tableIconButtonSx} onClick={() => deletePendingAppRequest(requestObj)}>
              <Trash {...iconSize} />
            </IconButton>
          </>
        );
      if (status === RequestStatus.Rejected)
        return (
          <>
            <IconButton sx={tableIconButtonSx} onClick={() => deleteRejectedAppRequest(requestObj)}>
              <Trash {...iconSize} />
            </IconButton>
          </>
        );
      return <></>;
    },
    [
      rejectAppRequest,
      approveAppRequest,
      retryFailedAppRequest,
      deleteFailedAppRequest,
      deletePendingAppRequest,
      deleteRejectedAppRequest,
    ]
  );

  const appRequestsColumns = useMemo<ColumnDef<RequestDto, RequestDto>[]>(
    () => [
      {
        header: () => 'Name',
        id: 'requestedFor',
        maxSize: 200,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const user = original.requestedFor ? getCachedUserById(original.requestedFor) : undefined;
          return user ? (
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              <UserAvatar userId={user.userId} size="xxsmall" />
              <div>{polyglot.t(user.displayName ?? '')}</div>
            </Typography>
          ) : (
            <></>
          );
        },
      },
      {
        header: () => 'App',
        id: 'app-stub',
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <img
              src={`/app-icons-v2/images/${original.requestInfo.appStub}.png`}
              width={20}
              alt={original.requestInfo.appStub}
            />
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                ml: 1,
                gap: spacing.m5,
              }}
            >
              <div>{getAppNameFromStub(original.requestInfo.appStub)}</div>
            </Typography>
          </Box>
        ),
      },
      {
        header: () => 'Email/Login',
        id: 'email',
        maxSize: 160,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const user = original.requestedFor ? getCachedUserById(original.requestedFor) : undefined;
          return user ? (
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {user.emailAddress}
            </Typography>
          ) : (
            <></>
          );
        },
      },
      {
        header: () => 'Status',
        id: 'status',
        maxSize: 160,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => <>{original.status && getIconForRequestStatus(original.status)}</>,
      },
      {
        header: () => 'Date',
        id: 'createdAt',
        maxSize: 160,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ py: 0.2, display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                ...themeFonts.caption,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.m5,
              }}
            >
              {original.createdAt ? dateAPItoUILong(new Date(original.createdAt).toISOString()) : ''}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => 'Action',
        id: 'action',
        maxSize: 80,
        minSize: 80,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {original.status && getActionsForRequestStatus(original)}
          </Box>
        ),
      },
    ],
    [polyglot, getActionsForRequestStatus, getAppNameFromStub, getCachedUserById]
  );

  const requestsRequiringAttention = useMemo(
    () => appRequests?.filter((r) => r.status && ['Requested', 'Pending', 'Failed'].includes(r.status)),
    [appRequests]
  );

  useEffect(() => {
    dispatch({
      type: GlobalStateActions.UPDATE_ALERTS,
      payload: { apps: { entries: requestsRequiringAttention as RequestAlertDto[], type: 'admin' } },
    });
  }, [dispatch, requestsRequiringAttention]);

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Requests</Typography>} />
      <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          {filterTypes && (
            <TableFilter filterTypes={filterTypes} setFilterString={setFilterString} filterString={filterString} />
          )}
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ ...spacing.mt20 }}>
          <AppDetailsTable
            column={appRequestsColumns}
            row={filteredAppRequests ?? []}
            loading={(appRequestsLoading || allAvailableAppsLoading) ?? false}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
