import { GlobalContext, GlobalStateActions, MessagePosition, MessageType } from '@/GlobalState';
import { useCallback, useContext } from 'react';

export type ShowMessage = (message: string, type: MessageType, position?: MessagePosition, action?: () => void) => void;

const useMessage = (): [ShowMessage] => {
  const [, dispatch] = useContext(GlobalContext);

  const showMessage = useCallback(
    (message: string, type: MessageType, position: MessagePosition = 'center', action?: () => void) => {
      dispatch({
        type: GlobalStateActions.SHOW_MESSAGE,
        payload: { message, type, position },
      });

      if (action) action();
    },
    [dispatch]
  );

  return [showMessage];
};

export default useMessage;
