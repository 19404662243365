import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Timeaway } from '@/images/dashboard-icons/Timeaway.svg';
import { getDateFormation } from '@/v2/feature/dashboard/dashboard.util';
import { TimeActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { AbsenceTodo, PendingRequestBigWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

export const PendingRequestTodo = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: PendingRequestBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<AbsenceTodo | undefined>(undefined);

  return (
    <Box>
      {todo.time.map((a, idx) => {
        const title = `${getCachedUserById(a.userId)?.displayName}: ${a.policyName}, ${getDateFormation(
          a.start,
          a.end
        )}`;

        return (
          <TodoCard
            key={`-${a.userId}-${idx}`}
            icon={<Timeaway {...iconSize} />}
            title={
              <Typography
                variant="caption"
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {polyglot.t('PendingRequestTodo.request', {
                  request: a.cancellationRequested ? 'cancellation request' : 'request',
                  title: title,
                })}
              </Typography>
            }
            actionOnclick={() => {
              setSelectedRow(a);
            }}
            showBorder={!(setLastChild && todo.time.length === idx + 1)}
            userAvatar={<UserAvatar userId={a.userId} size="xxxsmall" />}
          />
        );
      })}

      {selectedRow && (
        <TimeActionItem
          timeActionRow={selectedRow as AbsenceTodo}
          refresh={async () => {
            if (refreshTodos) await refreshTodos();
          }}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
