import { useCallback, useState } from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { EditableUserPayrollDto, UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoTransparent.svg';
import { ReactComponent as Warning } from '@/images/side-bar-icons/MistakeOrange.svg';
import { ReactComponent as HintIcon } from '@/images/side-bar-icons/Question.svg';
import {
  CalculationMethodMonth1,
  CalculationMethodStandard,
  CalculationMethodsValues,
  NITableValues,
  No,
  StarterDeclarationA,
  StarterDeclarationValues,
  StudentLoanValues,
  Yes,
  YesNoValues,
} from '@/lib/payroll';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { MAX_PAYROLL_ID_LENGTH } from '@/v2/feature/payroll/payroll-external.interface';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

type EditablePayrollFields = UKPayrollValues &
  Pick<UserPayrollDto, 'effectiveDate' | 'changeReason'> & {
    payrollId?: string;
    hasNino: boolean;
    noP45: boolean;
  };

export const PayrollFieldSchema = (polyglot: Polyglot) =>
  Yup.object().shape({
    directorFrom: Yup.string()
      .nullable()
      .notRequired()
      .when('isDirector', {
        is: true,
        then: Yup.string().test(dateFieldTest).required(polyglot.t('OnboardingByUser.validations.requiredField')),
      }),
    directorTo: Yup.string().test(dateFieldTest).nullable().notRequired(),
    isDirector: Yup.boolean().notRequired().default(false),
    legalGender: Yup.string()
      .nullable()
      .oneOf(['male', 'female'])
      .required(polyglot.t('OnboardingByUser.validations.requiredField')),
    niNumber: Yup.string()
      .nullable()
      .notRequired()
      .when('hasNino', {
        is: true,
        then: Yup.string()
          .nullable()
          .trim()
          .matches(
            /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s*\d{2}){3}\s*[A-D]$/i,
            polyglot.t('OnboardingByUser.validations.validValue')
          )
          .required(polyglot.t('OnboardingByUser.validations.requiredField')),
      }),
    niTable: Yup.string().required(polyglot.t('OnboardingByUser.validations.requiredField')),
    starterDeclaration: Yup.string().required(polyglot.t('OnboardingByUser.validations.requiredField')),
    openingPreviousGross: Yup.number()
      .notRequired()
      .when(['starterDeclaration', 'noP45'], {
        is: (starterDeclaration: StarterDeclarationLetter, noP45: boolean) => starterDeclaration === 'B' && !noP45,
        then: Yup.number().moreThan(0, polyglot.t('OnboardingByUser.validations.requiredField')),
      }),
    openingPreviousTax: Yup.number()
      .notRequired()
      .when(['starterDeclaration', 'noP45'], {
        is: (starterDeclaration: StarterDeclarationLetter, noP45: boolean) => starterDeclaration === 'B' && !noP45,
        then: Yup.number().moreThan(0, polyglot.t('OnboardingByUser.validations.requiredField')),
      }),
    openingNotNewStarter: Yup.boolean().notRequired(),
    openingCurrentGross: Yup.number().notRequired(),
    openingCurrentTax: Yup.number().notRequired(),
    studentLoan: Yup.string().notRequired(),
    postgradLoan: Yup.boolean().notRequired(),
    taxCode: Yup.string()
      .matches(/^([a-zA-Z0-9]+)$/i, polyglot.t('OnboardingByUser.validations.validValue'))
      .required(polyglot.t('OnboardingByUser.validations.requiredField')),
    week1Month1: Yup.boolean().required(polyglot.t('OnboardingByUser.validations.requiredField')),
    effectiveDate: Yup.string().test(dateFieldTest).required(polyglot.t('OnboardingByUser.validations.requiredField')),
    changeReason: Yup.string().nullable().optional(),
  });

interface EditUserPayrollUKProps {
  readonly payrollId?: string;
  readonly payrollValues?: UKPayrollValues | null;
  readonly effectiveDate?: string;
  readonly changeReason?: string | null;
  readonly savePayrollRecord: (record: EditableUserPayrollDto) => Promise<boolean>;
  readonly close: () => void;
  readonly isUserUpdating: boolean;
  readonly requireReason?: boolean;
  readonly userPayrollId?: number;
}

export const EditUserPayrollUKPage = ({
  payrollId,
  effectiveDate,
  changeReason,
  payrollValues,
  savePayrollRecord,
  isUserUpdating,
  close,
  requireReason,
  userPayrollId,
}: EditUserPayrollUKProps) => {
  const { polyglot } = usePolyglot();
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingPayrollId, setIsEditingPayrollId] = useState(false);
  const p45Enabled = useCallback((starterDeclaration: StarterDeclarationLetter) => {
    return starterDeclaration === 'B';
  }, []);

  const formik = useFormik<EditablePayrollFields>({
    initialValues: {
      payrollId,
      starterDeclaration: StarterDeclarationA.value,
      niTable: NITableValues[0].value,
      niNumber: null,
      studentLoan: StudentLoanValues[0].value,
      postgradLoan: false,
      isDirector: false,
      niAlternativeMethod: false,
      openingNotNewStarter: false,
      openingPreviousGross: 0,
      openingPreviousTax: 0,
      openingCurrentGross: 0,
      openingCurrentTax: 0,
      taxCode: '',
      week1Month1: false,
      legalGender: null,
      isP45Filled: false,
      ...payrollValues,
      hasNino: !!payrollValues?.niNumber,
      noP45: !payrollValues || (payrollValues.starterDeclaration === 'B' && !payrollValues.openingPreviousGross),
      effectiveDate: effectiveDate ?? todaysDateShortISOString(),
      changeReason: changeReason || null,
    },
    validationSchema: PayrollFieldSchema(polyglot),
    onSubmit: async (values) => {
      const { payrollId, effectiveDate, changeReason, hasNino, noP45, ...payrollValues } = values;
      if (requireReason && !changeReason?.trim()) {
        formik.setFieldError('changeReason', polyglot.t('OnboardingByUser.validations.requiredField'));
        return;
      }
      setIsSaving(true);
      const cleanAlphanumericField = (text: string) =>
        text
          .trim()
          .toUpperCase()
          .replace(/[^A-Z0-9]/i, '');

      const updated = await savePayrollRecord({
        payrollId: payrollId || undefined, // if a blank string is entered, leave it unchanged
        changeReason,
        effectiveDate,
        payrollValues: {
          ...payrollValues,
          taxCode: cleanAlphanumericField(values.taxCode),
          niNumber: hasNino && values.niNumber ? cleanAlphanumericField(values.niNumber) : null,
          openingPreviousGross: noP45 ? 0 : values.openingPreviousGross,
          openingPreviousTax: noP45 ? 0 : values.openingPreviousTax,
        },
        remotePayrollValues: null,
        countryCode: 'GB',
        userPayrollId,
      });
      setIsSaving(false);
      if (updated) {
        close();
      }
    },
  });

  const { hasNino, noP45 } = formik.values;

  const stripNonAlphanumeric = useCallback((value: string) => {
    return value.replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Stack sx={{ flexFlow: 'row', display: isEditingPayrollId ? 'none' : undefined }}>
          <Stack sx={{ gap: spacing.sm }}>
            <Typography variant="captionSmall">{polyglot.t('OnboardingByUser.payrollId')}</Typography>
            {!!payrollId ? (
              <Typography variant="title4">{payrollId}</Typography>
            ) : (
              <Typography variant="caption">{polyglot.t('OnboardingByUser.autoGenerate')}</Typography>
            )}
          </Stack>
          <IconButton
            sx={{ ml: 'auto', p: 0, height: '16px' }}
            onClick={() => setIsEditingPayrollId(true)}
            disabled={isSaving}
          >
            <EditIcon width={16} height={16} />
          </IconButton>
        </Stack>

        <Stack sx={{ gap: spacing.sm, display: !isEditingPayrollId ? 'none' : undefined }}>
          <TextfieldComponent
            label={polyglot.t('OnboardingByUser.newPayrollId')}
            name="payrollId"
            value={formik.values.payrollId ?? ''}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('payrollId', '')}
            maxLength={MAX_PAYROLL_ID_LENGTH}
            disabled={isSaving}
          />
          {!!payrollId && (
            <Stack sx={{ flexFlow: 'row', mt: spacing.m5, gap: spacing.g5, alignItems: 'flex-start' }}>
              <Warning style={{ width: 24 }} />
              <Typography variant="caption">{polyglot.t('OnboardingByUser.changingIdRequire')}</Typography>
            </Stack>
          )}
        </Stack>
        <DatePickerComponent
          name="effectiveDate"
          label={polyglot.t('OnboardingByUser.effectiveDate')}
          required
          onChange={(value) => {
            formik.setFieldValue('effectiveDate', value);
          }}
          value={formik.values.effectiveDate}
          error={formik.touched.effectiveDate && !!formik.errors.effectiveDate}
          helperText={formik.touched.effectiveDate && formik.errors.effectiveDate}
          disabled={isSaving}
        />
        <TextfieldComponent
          label={polyglot.t('OnboardingByUser.taxCode')}
          name="taxCode"
          value={formik.values.taxCode}
          onChange={(e) => {
            const taxCode = stripNonAlphanumeric(e.target.value);
            formik.setFieldValue('taxCode', taxCode);
            if (taxCode.endsWith('X')) {
              // tax code ending in X means week1Month1
              formik.setFieldValue('week1Month1', true);
            }
          }}
          clearText={() => formik.setFieldValue('taxCode', '')}
          autoFocus
          maxLength={10}
          error={formik.touched.taxCode && !!formik.errors.taxCode}
          helperText={formik.touched.taxCode && formik.errors.taxCode}
          disabled={isSaving}
        />

        <Stack sx={{ gap: spacing.sm }}>
          <SelectComponent
            name="week1Month1"
            label={polyglot.t('OnboardingByUser.calculationMethod')}
            options={CalculationMethodsValues}
            value={formik.values.week1Month1 ? CalculationMethodMonth1 : CalculationMethodStandard}
            onChange={(e) => formik.setFieldValue('week1Month1', e.target.value === CalculationMethodMonth1)}
            disabled={isSaving}
          />
          {formik.values.taxCode.endsWith('X') && (
            <Stack sx={{ flexFlow: 'row', gap: spacing.g5, alignItems: 'center' }}>
              <InfoIcon style={{ height: 14, width: 14 }} />
              <Typography variant="caption">
                {polyglot.t('OnboardingByUser.yourTaxCodeShouldBe', { method: CalculationMethodMonth1 })}
              </Typography>
            </Stack>
          )}
        </Stack>

        <SelectComponent
          name="starterDeclaration"
          label={polyglot.t('OnboardingByUser.starterDeclaration')}
          options={StarterDeclarationValues.map(({ value, label, details }) => ({
            label: `${label}. ${details}`,
            value,
          }))}
          value={formik.values.starterDeclaration}
          onChange={(e) => {
            const p45 = p45Enabled(e.target.value);
            formik.handleChange(e);
            formik.setFieldValue('openingPreviousGross', p45 ? formik.initialValues.openingPreviousGross : 0, true);
            formik.setFieldValue('openingPreviousTax', p45 ? formik.initialValues.openingPreviousTax : 0, true);
          }}
          disabled={isSaving}
        />

        {p45Enabled(formik.values.starterDeclaration) && (
          <Stack sx={{ gap: spacing.g20 }}>
            <Stack sx={{ flexFlow: 'row', gap: spacing.g5, alignItems: 'center' }}>
              <Typography variant="title4">{polyglot.t('OnboardingByUser.starterSettings')}</Typography>
              <StyledTooltip title={polyglot.t('OnboardingByUser.p45Figures')}>
                <HintIcon />
              </StyledTooltip>
            </Stack>

            <Stack sx={{ gap: spacing.g30 }}>
              <MoneyTextfieldComponent
                name="openingPreviousGross"
                label={polyglot.t('OnboardingByUser.openingPreviousGross')}
                value={formik.values.openingPreviousGross}
                onChange={(value) => formik.setFieldValue('openingPreviousGross', value || 0)}
                error={formik.touched.openingPreviousGross && !!formik.errors.openingPreviousGross}
                helperText={formik.touched.openingPreviousGross && formik.errors.openingPreviousGross}
                disabled={noP45 || isSaving}
                emptyIsZero
                clearToZero
              />
              <MoneyTextfieldComponent
                name="openingPreviousTax"
                label={polyglot.t('OnboardingByUser.openingPreviousTax')}
                value={formik.values.openingPreviousTax}
                onChange={(value) => formik.setFieldValue('openingPreviousTax', value || 0)}
                error={formik.touched.openingPreviousTax && !!formik.errors.openingPreviousTax}
                helperText={formik.touched.openingPreviousTax && formik.errors.openingPreviousTax}
                disabled={noP45 || isSaving}
                emptyIsZero
                clearToZero
              />
              <CheckboxComponent
                name="noP45"
                label={polyglot.t('OnboardingByUser.noP45')}
                checked={noP45}
                onChange={(e, checked) => {
                  if (checked) {
                    formik.setFieldValue('openingPreviousGross', 0);
                    formik.setFieldValue('openingPreviousTax', 0);
                    formik.setFieldValue('week1Month1', true);
                  } else {
                    formik.setFieldValue('openingPreviousGross', formik.initialValues.openingPreviousGross);
                    formik.setFieldValue('openingPreviousTax', formik.initialValues.openingPreviousTax);
                    formik.setFieldValue('week1Month1', formik.initialValues.week1Month1);
                  }
                  formik.handleChange(e);
                }}
                sx={themeFonts.caption}
                disabled={isSaving}
              />
            </Stack>
          </Stack>
        )}

        <SelectComponent
          name="niTable"
          label={polyglot.t('OnboardingByUser.niTable')}
          options={NITableValues.map(({ value, label, details }) => ({
            label: `${label}. ${details}`,
            value,
          }))}
          value={formik.values.niTable}
          onChange={formik.handleChange}
          disabled={isSaving}
        />

        <CheckboxComponent
          name="hasNino"
          label={polyglot.t('OnboardingByUser.hasNino')}
          checked={hasNino}
          onChange={(e, checked) => {
            formik.setFieldValue('niNumber', checked ? formik.initialValues.niNumber : '');
            formik.handleChange(e);
          }}
          disabled={isSaving}
        />

        {hasNino && (
          <TextfieldComponent
            label={polyglot.t('OnboardingByUser.niNumber')}
            name="niNumber"
            value={hasNino ? formik.values.niNumber || '' : ''}
            disabled={!hasNino || isSaving}
            onChange={(e) => formik.setFieldValue('niNumber', stripNonAlphanumeric(e.target.value))}
            clearText={() => formik.setFieldValue('niNumber', '')}
            maxLength={20} // allow for some spaces within the nino
            autoComplete={'off'}
            error={(formik.touched.niNumber || formik.touched.hasNino) && !!formik.errors.niNumber}
            helperText={(formik.touched.niNumber || formik.touched.hasNino) && formik.errors.niNumber}
          />
        )}

        <SelectComponent
          name="studentLoan"
          label={polyglot.t('OnboardingByUser.studentLoan')}
          options={StudentLoanValues}
          value={formik.values.studentLoan}
          onChange={formik.handleChange}
          disabled={isSaving}
        />

        <SelectComponent
          name="postgradLoan"
          label={polyglot.t('OnboardingByUser.postgraduateLoan')}
          options={YesNoValues}
          value={formik.values.postgradLoan ? Yes : No}
          onChange={(e) => formik.setFieldValue('postgradLoan', e.target.value === Yes)}
          disabled={isSaving}
        />

        <SelectComponent
          name="legalGender"
          label={polyglot.t('OnboardingByUser.legalGender')}
          options={[
            { label: polyglot.t('OnboardingByUser.male'), value: 'male' },
            { label: polyglot.t('OnboardingByUser.female'), value: 'female' },
          ]}
          value={formik.values.legalGender || ''}
          onChange={formik.handleChange}
          error={formik.touched.legalGender && !!formik.errors.legalGender}
          helperText={formik.touched.legalGender && formik.errors.legalGender}
          disabled={isSaving}
        />

        <CheckboxComponent
          name="isDirector"
          label={polyglot.t('OnboardingByUser.isDirector')}
          checked={formik.values.isDirector}
          onChange={(e, checked) => {
            formik.setFieldValue('directorFrom', checked ? formik.initialValues.directorFrom : null);
            formik.setFieldValue('directorTo', checked ? formik.initialValues.directorTo : null);
            formik.setFieldValue('niAlternativeMethod', checked ? !!formik.initialValues.niAlternativeMethod : null);
            formik.handleChange(e);
          }}
          disabled={isSaving}
        />

        {formik.values.isDirector && (
          <>
            <DatePickerComponent
              name="directorFrom"
              label={polyglot.t('OnboardingByUser.directorFrom')}
              value={formik.values.directorFrom}
              onChange={(value) => {
                formik.setFieldValue('directorFrom', value);
              }}
              error={formik.touched.directorFrom && !!formik.errors.directorFrom}
              helperText={formik.touched.directorFrom && formik.errors.directorFrom}
              disabled={isSaving}
            />
            <Stack gap={spacing.sm}>
              <DatePickerComponent
                name="directorTo"
                label={polyglot.t('OnboardingByUser.directorTo')}
                value={formik.values.directorTo}
                onChange={(value) => formik.setFieldValue('directorTo', value)}
                error={formik.touched.directorTo && !!formik.errors.directorTo}
                helperText={formik.touched.directorTo && formik.errors.directorTo}
                disabled={isSaving}
              />
              <CheckboxComponent
                name="directorshipActive"
                label={polyglot.t('OnboardingByUser.directorshipActive')}
                checked={!formik.values.directorTo}
                onChange={(_, checked) => {
                  formik.setFieldValue('directorTo', checked ? null : dayjs().format('YYYY-MM-DD'));
                }}
                disabled={isSaving}
              />
            </Stack>

            <CheckboxComponent
              name="niAlternativeMethod"
              label={polyglot.t('OnboardingByUser.niAlternativeMethod')}
              checked={!!formik.values.niAlternativeMethod}
              onChange={(e) => formik.handleChange(e)}
              disabled={isSaving}
            />
          </>
        )}

        <Stack gap={spacing.s1}>
          <CheckboxComponent
            name="openingNotNewStarter"
            label={polyglot.t('OnboardingByUser.openingNotNewStarter')}
            checked={formik.values.openingNotNewStarter ?? false}
            onChange={(e, checked) => {
              formik.setFieldValue('openingCurrentGross', checked ? formik.initialValues.openingCurrentGross : 0);
              formik.setFieldValue('openingCurrentTax', checked ? formik.initialValues.openingCurrentTax : 0);
              formik.handleChange(e);
            }}
            disabled={isSaving}
          />
          {formik.values.openingNotNewStarter && (
            <>
              <Typography variant="caption">{polyglot.t('OnboardingByUser.figuresFromPrevious')}</Typography>
              <Stack gap={spacing.s1}>
                <MoneyTextfieldComponent
                  name="openingCurrentGross"
                  label={polyglot.t('OnboardingByUser.openingCurrentGross')}
                  value={formik.values.openingCurrentGross}
                  onChange={(value) => formik.setFieldValue('openingCurrentGross', value || 0)}
                  error={formik.touched.openingCurrentGross && !!formik.errors.openingCurrentGross}
                  helperText={formik.touched.openingCurrentGross && formik.errors.openingCurrentGross}
                  disabled={isSaving}
                />
                <MoneyTextfieldComponent
                  name="openingCurrentTax"
                  label={polyglot.t('OnboardingByUser.openingCurrentTax')}
                  value={formik.values.openingCurrentTax}
                  onChange={(value) => formik.setFieldValue('openingCurrentTax', value || 0)}
                  error={formik.touched.openingCurrentTax && !!formik.errors.openingCurrentTax}
                  helperText={formik.touched.openingCurrentTax && formik.errors.openingCurrentTax}
                  disabled={isSaving}
                />
              </Stack>
            </>
          )}
        </Stack>

        {requireReason && (
          <TextfieldComponent
            label={polyglot.t('OnboardingByUser.changeReason')}
            name="changeReason"
            value={formik.values.changeReason ?? ''}
            onChange={formik.handleChange}
            clearText={() => formik.setFieldValue('changeReason', '')}
            error={formik.touched.changeReason && !!formik.errors.changeReason}
            helperText={formik.touched.changeReason && formik.errors.changeReason}
            disabled={isSaving}
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            onClick={() => close()}
            disabled={isUserUpdating || isSaving}
            sizeVariant="medium"
            colorVariant="secondary"
            fullWidth
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            colorVariant="primary"
            sizeVariant="medium"
            name={polyglot.t('General.save')}
            loading={isSaving}
            fullWidth
            disabled={isUserUpdating || isSaving}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
