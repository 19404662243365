import { useCallback } from 'react';

import { AbsenceSettingsPublicHolidaysRouter } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/absence-settings-public-holidays-router.component';
import { AbsencePolicyRouter } from '@v2/feature/absence/subfeatures/settings/policy-details/absence-policy-router.component';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl, RouteScopesHas } from '@/component/widgets/Scopes';
import {
  SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE,
  SETTINGS_TIME_OTHER_ROUTE,
  SETTINGS_TIME_POLICIES_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ROUTE,
  SETTINGS_TIME_ROUTE,
} from '@/lib/routes';
import { AbsenceEndpoints } from '@/v2/feature/absence/absence.api';
import { AbsenceCompanySettingsGeneralPage } from '@/v2/feature/absence/company/policies/pages/absence-company-settings-general.page';
import { AbsenceCompanySettingsPoliciesPage } from '@/v2/feature/absence/company/policies/pages/absence-company-settings-policies.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

export const AbsenceSettingsRouter = () => {
  const {
    data: absencePolicies,
    mutate: refreshAbsencePolicies,
    isLoading,
  } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });
  const {
    data: archivedPolicies,
    mutate: refreshAPolicies,
    isLoading: isLoadingArchived,
  } = useApiClient(AbsenceEndpoints.getArchivedAbsencePolicies(), { suspense: false });

  const refreshPolicies = useCallback(async () => {
    if (refreshAbsencePolicies) await refreshAbsencePolicies();
  }, [refreshAbsencePolicies]);

  const refreshArchivedPolicies = useCallback(async () => {
    if (refreshAPolicies) await refreshAPolicies();
  }, [refreshAPolicies]);

  const refreshAllPolicies = useCallback(async () => {
    // When refresh is needed, the refreshArchivedPolicies will be already available - no need to call refreshPolicies if refreshArchivedPolicies is not defined yet
    if (!refreshAPolicies) return;

    if (refreshAPolicies) await Promise.all([refreshAPolicies(), refreshPolicies()]);
  }, [refreshAPolicies, refreshPolicies]);

  return (
    <Switch>
      <RouteScopesControl scopes={['absence:all']} path={SETTINGS_TIME_POLICY_DETAILS_ROUTE}>
        <AbsencePolicyRouter refreshPolicies={refreshPolicies} refreshArchivedPolicies={refreshArchivedPolicies} />
      </RouteScopesControl>
      {/* TO DO: Make sidebar responsive */}
      <RouteScopesHas scopes={['absence:all']} path={SETTINGS_TIME_POLICIES_ROUTE}>
        <AbsenceCompanySettingsPoliciesPage
          absencePolicies={absencePolicies ?? []}
          archivedPolicies={archivedPolicies ?? []}
          refreshPolicies={refreshAllPolicies}
          isLoading={isLoading || isLoadingArchived}
        />
      </RouteScopesHas>

      <RouteScopesHas scopes={['absence:all']} path={SETTINGS_TIME_OTHER_ROUTE} exact>
        <AbsenceCompanySettingsGeneralPage />
      </RouteScopesHas>

      <RouteScopesHas scopes={['absence:all']} path={SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE}>
        <AbsenceSettingsPublicHolidaysRouter />
      </RouteScopesHas>

      <Redirect from={SETTINGS_TIME_ROUTE} to={SETTINGS_TIME_POLICIES_ROUTE} exact />
    </Switch>
  );
};
