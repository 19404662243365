import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary, keyBy } from 'lodash';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { ReactComponent as Plus } from '@/images/new-theme-icon/Plus.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { ReviewTemplateAPI, ReviewTemplateEndpoints } from '@/v2/feature/growth/reviews/api-client/review-template.api';
import { PlaceholderBox } from '@/v2/feature/growth/reviews/features/components/placeholder-box.component';
import { QuestionBuilder } from '@/v2/feature/growth/reviews/features/components/question-builder.component';
import { SectionBuilder } from '@/v2/feature/growth/reviews/features/components/section-builder.component';
import { CycleCreationQuestionPreviewer } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-previewer.component';
import { CycleCreationQuestionSelectModal } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-select-modal.component';
import { ReviewTemplateMoveToSectionModal } from '@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-move-to-section-modal.component';
import { ReviewTemplateQuestionModal } from '@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-question-modal.component';
import { ReviewTemplateSectionModal } from '@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-section-modal.component';
import { useTemplateQuestionOptions } from '@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-template-question-options.hook';
import { useTemplateSectionOptions } from '@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-template-section-options.hook';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewQuestionBank } from '@/v2/feature/growth/reviews/interfaces/review-question-bank.interface';
import { ReviewSectionBank } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';
import { ReviewTemplate, ReviewTemplateById } from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReviewTemplateBuilderPage = ({
  templateObject,
  refreshTemplate,
}: {
  templateObject: ReviewTemplateById | null | undefined;
  refreshTemplate: KeyedMutator<ReviewTemplateById> | undefined;
}) => {
  const params = useParams<{ templateId: string }>();
  const templateId = params.templateId;
  const { data: bankCount } = useApiClient(ReviewTemplateEndpoints.getBankCount(), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const growthFactors: Dictionary<GrowthFactor> = useMemo(() => {
    return keyBy(allGrowthFactors, 'name');
  }, [allGrowthFactors]);

  const [question, setQuestion] = useState<ReviewQuestionBank | null>(null);
  const [section, setSection] = useState<ReviewSectionBank | null>(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState<boolean>(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState<boolean>(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState<boolean>(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | undefined>(undefined);

  const [isPreviewerOpen, setIsPreviewerOpen] = useState<boolean>(false);
  const [previewReviewer, setPreviewReviewer] = useState<ReviewerTypes | undefined>(undefined);

  let template: ReviewTemplateById['template'] | undefined;
  let sections: ReviewTemplateById['sections'] | undefined;
  let questions: ReviewTemplateById['questions'] | undefined;

  if (templateObject) {
    ({ template, sections, questions } = templateObject);
  }

  const getReviewerSelects = useMemo(() => {
    if (!questions) return [];

    const allReviewerSelects = questions?.flatMap((q) => q.reviewerSelect);

    return allReviewerSelects;
  }, [questions]);

  const questionIdsInSections =
    sections?.reduce<string[]>((acc, section) => {
      return acc.concat(section.questionIds);
    }, []) ?? [];

  const questionsNotInAnySection = questions?.filter((question) => !questionIdsInSections?.includes(question.id)) ?? [];
  const renderQuestion = (questionId: string) => {
    const question = questionsNotInAnySection.find((q) => q.id === questionId);
    if (!question || !template) return null;
    return (
      <QuestionComponent
        key={question.id}
        question={question}
        template={template}
        sectionId={null}
        setQuestion={setQuestion}
        setIsQuestionModalOpen={setIsQuestionModalOpen}
        refreshTemplate={refreshTemplate}
        setIsMoveToSectionOpen={setIsMoveToSectionOpen}
        setSelectedQuestionId={setSelectedQuestionId}
        hasSections={Boolean(sections && sections.length > 0)}
        growthFactors={growthFactors}
      />
    );
  };

  const renderSection = (sectionId: string) => {
    const section = sections?.find((s) => s.id === sectionId);
    if (!section || !template) return null;

    const orderSection = template?.order.find((o) => o.id === sectionId && o.type === 'section');
    if (!orderSection) return null;

    const orderedQuestions = (orderSection.questions ?? [])
      .map((questionId) => questions?.find((q) => q.id === questionId))
      .filter((question): question is ReviewQuestionBank => Boolean(question));

    const handleDragDrop = async (results: DropResult) => {
      const { source, destination, type } = results;
      if (!template) return;
      if (type !== 'group') return;

      const sectionIndex = template?.order.findIndex((o) => o.type === 'section' && o.id === section.id);
      if (sectionIndex === -1) return;

      const reOrdered = { ...template.order[sectionIndex] };
      if (!reOrdered || !reOrdered.questions) return;

      const sourceIndex = source.index;
      const destinationIndex = destination?.index;
      if (sourceIndex === -1 || destinationIndex === undefined || destinationIndex === -1) return;

      const [removed] = reOrdered.questions.splice(sourceIndex, 1);
      reOrdered.questions.splice(destinationIndex, 0, removed);

      const updatedOrder = [...template.order];
      updatedOrder[sectionIndex] = reOrdered;

      await ReviewTemplateAPI.updateOrderReviewTemplate({ templateId, order: updatedOrder });
      await refreshTemplate?.();
    };

    return (
      <TemplateBuilderSection
        key={section.id}
        section={section}
        setSection={setSection}
        setIsQuestionModalOpen={setIsQuestionModalOpen}
        setIsSectionModalOpen={setIsSectionModalOpen}
        questions={orderedQuestions}
        handleDragDrop={handleDragDrop}
        template={template}
        setQuestion={setQuestion}
        refreshTemplate={refreshTemplate}
        setIsMoveToSectionOpen={setIsMoveToSectionOpen}
        setSelectedQuestionId={setSelectedQuestionId}
        sections={sections}
        growthFactors={growthFactors}
      />
    );
  };

  const addQuestionsToTemplateHandle = async (selectedQuestionIds: Set<string>) => {
    try {
      await ReviewTemplateAPI.addQuestionsToTemplate([...selectedQuestionIds], templateId);
      refreshTemplate?.();
    } catch (error) {}
  };

  const moveQuestionInsideSection = async (sectionId: string, questionId: string) => {
    try {
      await ReviewTemplateAPI.moveQuestionInsideSection({ templateId, sectionId, questionId });
      refreshTemplate?.();
    } catch (error) {}
  };

  const getReviewerActionsOptions = useCallback(() => {
    return [
      ...(getReviewerSelects.includes(ReviewerTypes.Self)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Self);
              },
              label: ReviewerTypes.Self,
              disabled: false,
            },
          ]
        : []),
      ...(getReviewerSelects.includes(ReviewerTypes.Manager)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Manager);
              },
              label: ReviewerTypes.Manager,
              disabled: false,
            },
          ]
        : []),
      ...(getReviewerSelects.includes(ReviewerTypes.Upward)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Upward);
              },
              label: ReviewerTypes.Upward,
              disabled: false,
            },
          ]
        : []),
      ...(getReviewerSelects.includes(ReviewerTypes.Peer)
        ? [
            {
              handler: () => {
                setIsPreviewerOpen(true);
                setPreviewReviewer(ReviewerTypes.Peer);
              },
              label: ReviewerTypes.Peer,
              disabled: false,
            },
          ]
        : []),
    ];
  }, [getReviewerSelects]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Box
        className="sub-title"
        sx={{
          px: spacing.p16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: spacing.m4,
        }}
      >
        <Typography variant="title3">Questions</Typography>
        {getReviewerSelects.length > 1 && (
          <StyledMenuComponent
            options={getReviewerActionsOptions()}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: 'Preview',
            }}
          />
        )}
      </Box>
      {template?.companyId && (
        <Box sx={{ mt: spacing.m24, width: '100%', boxSizing: 'border-box', px: spacing.p16 }}>
          <PlaceholderBox
            title="Select questions from library"
            countOne={`${bankCount?.totalCategories ?? 0} factors`}
            countTwo={`${bankCount?.totalQuestions ?? 0} questions`}
            action={() => setIsQuestionBankOpen(true)}
          />
        </Box>
      )}

      <Box
        sx={{
          ...(template && template.order
            ? {}
            : { margin: spacing.m16, border: `1px dashed ${themeColors.Background}` }),
          mt: spacing.m24,
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {template &&
            template.order?.map((item) => {
              if (item.type === 'question') {
                return renderQuestion(item.id);
              } else if (item.type === 'section') {
                return renderSection(item.id);
              }
              return <></>;
            })}
        </Box>

        {template?.companyId && (
          <Box
            sx={{
              display: 'flex',
              gap: spacing.g5,
              mt: template && template.order ? spacing.m24 : '0px',
              padding: spacing.p16,
            }}
          >
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="medium"
              startIcon={<Plus {...iconSize} />}
              onClick={() => setIsSectionModalOpen(true)}
            >
              New section
            </ButtonComponent>
            <ButtonComponent
              colorVariant="secondary"
              sizeVariant="medium"
              startIcon={<Plus {...iconSize} />}
              onClick={() => setIsQuestionModalOpen(true)}
            >
              New question
            </ButtonComponent>
          </Box>
        )}

        <ReviewTemplateQuestionModal
          setIsOpen={setIsQuestionModalOpen}
          isOpen={isQuestionModalOpen}
          question={question}
          onClose={() => {
            setIsQuestionModalOpen(false);
            setQuestion(null);
            setSection(null);
          }}
          refresh={async () => {
            await refreshTemplate?.();
          }}
          section={section}
          templateId={templateId}
        />

        <ReviewTemplateSectionModal
          setIsOpen={setIsSectionModalOpen}
          isOpen={isSectionModalOpen}
          section={section}
          onClose={() => {
            setIsSectionModalOpen(false);
            setSection(null);
            setQuestion(null);
          }}
          refresh={async () => {
            await refreshTemplate?.();
          }}
          templateId={templateId}
        />
      </Box>

      <CycleCreationQuestionSelectModal
        setIsQuestionsModalOpen={setIsQuestionBankOpen}
        isQuestionsModalOpen={isQuestionBankOpen}
        action={addQuestionsToTemplateHandle}
      />

      <ReviewTemplateMoveToSectionModal
        isOpen={isMoveToSectionOpen}
        setIsOpen={setIsMoveToSectionOpen}
        onClose={() => {
          setIsMoveToSectionOpen(false);
        }}
        action={moveQuestionInsideSection}
        sections={sections}
        questionId={selectedQuestionId}
      />

      <CycleCreationQuestionPreviewer
        isPreviewerOpen={isPreviewerOpen}
        setIsPreviewerOpen={setIsPreviewerOpen}
        previewReviewer={previewReviewer}
        allQuestions={questions || []}
        allSections={sections || []}
        order={template?.order || []}
      />
    </Box>
  );
};

const TemplateBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  setIsMoveToSectionOpen,
  questions,
  handleDragDrop,
  template,
  setQuestion,
  refreshTemplate,
  setSelectedQuestionId,
  sections,
  growthFactors,
}: {
  section: ReviewSectionBank;
  setSection: React.Dispatch<React.SetStateAction<ReviewSectionBank | null>>;
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questions: ReviewQuestionBank[];
  handleDragDrop: (results: DropResult) => Promise<void>;
  template: ReviewTemplate;
  setQuestion: React.Dispatch<React.SetStateAction<ReviewQuestionBank | null>>;
  refreshTemplate: KeyedMutator<ReviewTemplateById> | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  sections: ReviewSectionBank[] | undefined;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  const sectionOptions = useTemplateSectionOptions(
    template,
    section,
    setSection,
    refreshTemplate,
    setIsSectionModalOpen
  );

  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };

  return (
    <SectionBuilder
      section={section}
      sectionOptions={sectionOptions}
      dragQuestionComponent={
        <DragDropContext onDragEnd={handleDragDrop}>
          <Droppable droppableId="ROOT" type="group" isDropDisabled={false}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions &&
                  questions.map((question, idx) => (
                    <Draggable isDragDisabled={false} draggableId={question.id} key={question.id} index={idx}>
                      {(provided) => (
                        <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                          <QuestionComponent
                            question={question}
                            template={template}
                            sectionId={section.id}
                            setQuestion={setQuestion}
                            setIsQuestionModalOpen={setIsQuestionModalOpen}
                            refreshTemplate={refreshTemplate}
                            setIsMoveToSectionOpen={setIsMoveToSectionOpen}
                            setSelectedQuestionId={setSelectedQuestionId}
                            hasSections={Boolean(sections && sections.length > 0)}
                            growthFactors={growthFactors}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
      newQuestionAction={newQuestionAction}
      showAction={true}
    />
  );
};

const QuestionComponent = ({
  question,
  template,
  sectionId,
  setQuestion,
  setIsQuestionModalOpen,
  refreshTemplate,
  setIsMoveToSectionOpen,
  setSelectedQuestionId,
  hasSections,
  growthFactors,
}: {
  question: ReviewQuestionBank;
  template: ReviewTemplate;
  sectionId: string | null;
  setQuestion: React.Dispatch<React.SetStateAction<ReviewQuestionBank | null>>;
  setIsQuestionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTemplate: KeyedMutator<ReviewTemplateById> | undefined;
  setIsMoveToSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  hasSections: boolean;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  const questionOptions = useTemplateQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    template,
    sectionId,
    refreshTemplate,
    setSelectedQuestionId,
    hasSections
  );

  return (
    <QuestionBuilder
      questionOptions={questionOptions}
      question={question}
      growthFactors={growthFactors}
      showAction={Boolean(template.companyId)}
    />
  );
};
