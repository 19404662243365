import { UserInsuranceDependants } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { PaymentTypeSettings } from '@v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface';

export interface CustomBenefitDto {
  id: number;
  companyId: number;
  category: CustomBenefitCategory;
  type: CustomBenefitType;
  allowanceType: CustomBenefitAllowanceType | null;
  name: string;
  description: string | null;
  includedUserIds: number[];
  benefitLink: string | null;
  payCode: string | null;
  payCodeEmployer: string | null;
  readonly logoUuid?: string | null;
  defaultEmployerContribution: number | null;
  defaultEmployeeContribution: number | null;
  defaultOpeningBalance: number | null;
  paymentMethod: CustomBenefitPaymentMethodType | null;
  paymentTypes?: PaymentTypeSettings[];
  createdAt: Date;
  updatedAt: Date;
  approvalRuleId: number;
  approvalRule?: { name: string };
  url?: string;
  fileName?: string;
}

export type CreateCustomBenefitDto = Pick<
  CustomBenefitDto,
  'type' | 'category' | 'name' | 'description' | 'includedUserIds' | 'benefitLink' | 'logoUuid'
>;

export type UpdateCustomBenefitGeneralDto = Pick<
  CustomBenefitDto,
  'type' | 'category' | 'name' | 'description' | 'benefitLink' | 'logoUuid'
>;

export type UpdateCustomBenefitBalanceDto = Pick<
  CustomBenefitDto,
  'defaultEmployerContribution' | 'defaultEmployeeContribution' | 'defaultOpeningBalance' | 'allowanceType'
>;

export interface UpdateCustomBenefitMembersDto {
  includedUserIds: number[];
}

export enum CustomBenefitPaymentMethodType {
  Tracking = 'Tracking',
  Expenses = 'Expenses',
  Payroll = 'Payroll',
}

export type UpdateCustomBenefitPayrollDto = Pick<CustomBenefitDto, 'payCode' | 'payCodeEmployer'>;

export type UpdateCustomBenefitPaymentDto = Pick<CustomBenefitDto, 'paymentMethod' | 'payCode' | 'payCodeEmployer'> & {
  paymentTypeIds: number[];
};

export type UpdateCustomBenefitApprovalDto = Pick<CustomBenefitDto, 'approvalRuleId'>;

export interface UserCustomBenefitPaymentDto {
  id: string;
  userId: number;
  customBenefitId: number;
  userCustomBenefitId: number;
  amount: number;
  date: string;
  status: UserCustomBenefitPaymentStatus;
  notes: string;
  approvedBy: number | null;
  approvedOnTimestamp: Date | null;
  createdBy: number;
  updatedBy: number;
  user?: { name: string };
  customBenefit?: Partial<CustomBenefitDto>;
}

export enum UserCustomBenefitPaymentStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum CustomBenefitCategory {
  Asset = 'Asset',
  Entertainment = 'Entertainment',
  Educational = 'Educational',
  Health = 'Health',
  Loans = 'Loans',
  Medical = 'Medical',
  Mileage = 'Mileage',
  Pension = 'Pension',
  Telephone = 'Telephone',
  Travel = 'Travel',
  Vehicles = 'Vehicles',
  Vouchers = 'Vouchers',
  Other = 'Other',
}

export enum CustomBenefitType {
  Recurring = 'Recurring',
  Allowance = 'Allowance',
  Loan = 'Loan',
}

export interface UserBenefitRequest {
  type: UserCustomBenefitRequestType;
  amount: number | null;
  numberOfInstallments: number | null;
  notes: string;
  employerContribution: number | null;
  employeeContribution: number | null;
  dependants: UserInsuranceDependants | null;
  dependantsList: number[] | null;
  effectiveDate: string;
}

export enum CustomBenefitAllowanceType {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
  OneOff = 'OneOff',
}

export enum UserCustomBenefitRequestType {
  OptOut = 'OptOut',
  LoanRequest = 'LoanRequest',
  RecurringChange = 'RecurringChange',
}
