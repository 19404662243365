import { useEffect } from 'react';

import { Box } from '@mui/material';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { InsurancePolicyDocuments } from '@v2/feature/benefits/subfeature/insurance/components/insurance-policy-documents.component';
import { InsuranceProviderDetails } from '@v2/feature/benefits/subfeature/insurance/components/insurance-provider-details.component';
import { InsurancePolicyDto, InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { BENEFITS_COMPANY_ROUTE } from '@/lib/routes';
import { InsuranceEmptyState } from '@/v2/feature/benefits/subfeature/insurance/components/insurance-empty-state.component';
import { InsurancePendingDetails } from '@/v2/feature/benefits/subfeature/insurance/components/insurance-pending-details.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';

interface InsuranceCompanyOverviewPageProps {
  readonly insurancePolicy: InsurancePolicyDto | undefined;
  readonly loading: boolean;
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly refresh: () => Promise<void>;
}

export const InsuranceCompanyOverviewPage = ({
  insurancePolicy,
  loading,
  insuranceQuote,
  refresh,
}: InsuranceCompanyOverviewPageProps) => {
  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Insurance company summary');
  }, [trackPage]);

  return (
    <RootStyle>
      <TopHeader title="Insurance" showBack backPath={BENEFITS_COMPANY_ROUTE} />

      <ContentWrapper loading={loading}>
        {insurancePolicy && (
          <Box sx={{ mb: spacing.mb40 }}>
            <InsuranceProviderDetails insurancePolicy={insurancePolicy} />
          </Box>
        )}
        {insurancePolicy?.uploadedDocuments && insurancePolicy.uploadedDocuments.length > 0 && (
          <Box sx={{ mt: spacing.mt40 }}>
            <InsurancePolicyDocuments insurancePolicy={insurancePolicy} />
          </Box>
        )}

        {!insurancePolicy && insuranceQuote?.status === 'Pending' && (
          <InsurancePendingDetails insuranceQuote={insuranceQuote} refresh={refresh} />
        )}

        {!insurancePolicy && !insuranceQuote && <InsuranceEmptyState />}
      </ContentWrapper>
    </RootStyle>
  );
};
