/* eslint-disable filenames/match-regex */
import { capitalize, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import moment from 'moment';

import { Invoice, InvoiceStatus } from '@/models/invoice.model';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';

const formatDate = (date: Date, format = 'DD/MM/YYYY') => moment(date).format(format);

export class SuperAdminBillingInvoiceTableHelper {
  static getCompanyNameColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { company },
        },
      }) => <div>{company.name ? company.name : <EmptyCell />}</div>,
      size,
    };
  }

  static getCompanyIdColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { companyId },
        },
      }) => <div>{companyId ? companyId : <EmptyCell />}</div>,
      size,
    };
  }

  static getPeriodStartDateColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { billingPeriodStartDate },
        },
      }) => <div>{billingPeriodStartDate ? formatDate(new Date(billingPeriodStartDate)) : <EmptyCell />}</div>,
      size,
    };
  }

  static getPeriodEndDateColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { billingPeriodEndDate },
        },
      }) => <div>{billingPeriodEndDate ? formatDate(new Date(billingPeriodEndDate)) : <EmptyCell />}</div>,
      size,
    };
  }

  static getInvoiceStatusColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { status },
        },
      }) =>
        status ? (
          <Typography
            sx={{
              ...themeFonts.caption,
              color: status === InvoiceStatus.Paid ? themeColors.black : themeColors.darkRed,
            }}
          >
            {capitalize(status)}
          </Typography>
        ) : (
          <EmptyCell />
        ),
      size,
    };
  }

  static getInvoiceDateColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { createdAt },
        },
      }) => <div>{createdAt ? formatDate(new Date(createdAt)) : <EmptyCell />}</div>,
      size,
    };
  }

  static getNoOfSubscriptionColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { breakdown },
        },
      }) => <div>{breakdown.breakdown.length ? breakdown.breakdown.length : <EmptyCell />}</div>,
      size,
    };
  }

  static getTotalAmountColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { amountDuePostTax },
        },
      }) => <div>{Number(amountDuePostTax) >= 0 ? Number(amountDuePostTax).toFixed(2) : <EmptyCell />}</div>,
      size,
    };
  }

  static getInvoiceIdColumn(columnDefinition: { header: string; id: string; size: number }): ColumnDef<Invoice> {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { id },
        },
      }) => <div>{id}</div>,
      size,
    };
  }
}
