import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE,
  SURVEYS_COMPANY_ONGOING_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE,
  SURVEYS_COMPANY_ROUTE,
} from '@/lib/routes';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import {
  SurveyCycleCreationCompanyRouter,
  SURVEYS_ALL_SCOPE,
} from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-router/company/survey-cycle-creation-company.router';
import { SurveyCycleDetailCompanyRouter } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-router/company/survey-cycle-detail-company.router';
import { SurveyCycleOngoingListingPage } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-ongoing/survey-cycle-ongoing-listing/survey-cycle-ongoing-listing.page';

export const SurveysCompanyRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return (
    <Switch>
      <RouteScopesControl
        context={context}
        scopes={[SURVEYS_ALL_SCOPE]}
        path={SURVEYS_COMPANY_ONGOING_SETTINGS_ROUTE}
        component={SurveyCycleCreationCompanyRouter}
      />
      <RouteScopesControl
        context={context}
        scopes={[SURVEYS_ALL_SCOPE]}
        path={SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE}
        component={SurveyCycleDetailCompanyRouter}
      />

      <RouteScopesControl context={context} scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_ROUTE} exact>
        <SurveyCycleOngoingListingPage reach={ReachType.Company} />
      </RouteScopesControl>

      <Redirect from={SURVEYS_COMPANY_ROUTE} to={SURVEYS_COMPANY_ONGOING_ROUTE} />
    </Switch>
  );
};
