import { Box, Typography } from '@mui/material';

import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const AppNameHeader = ({ title, app }: { title: string; app: InstalledAppDto | undefined }) => {
  const { polyglot } = usePolyglot();
  // STOP AUTOMATICALLY REDIRECTING USER TO APP OVERVIEW PAGE IF APP IS EXPIRED / INVALIDATED
  // ALLOW THEM TO VIEW SETTINGS
  // if (app?.invalidatedAt) {
  //   console.error('App has expired - renew connection!');
  //   routerHistory.push(APPS_PERSONAL_OVERVIEW_ROUTE);
  // }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g20 }}>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>{title}</Typography>
      {app && app.active && app?.authorised && !app.invalidatedAt && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <OkGreen style={{ fill: themeColors.Green }} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {polyglot.t('AppTeamAccessPage.statuses.Connected')}
          </Typography>
        </Box>
      )}
      {app && app.active && app?.authorised && app.invalidatedAt && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
          <Mistake {...iconSize} />
          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
            {polyglot.t('AppTeamAccessPage.statuses.Expired')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
