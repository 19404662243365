import { useMemo, useRef } from 'react';

import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';

type NINOEntryProps = {
  niNumber?: string | null;
  setNiNumber: (nino: string | null) => void;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
};

export const NINOEntry = ({ niNumber, setNiNumber, disabled, helperText, error }: NINOEntryProps) => {
  const { polyglot } = usePolyglot();
  const ninoTextField = useRef<any>();
  const cachedNino = useRef('');
  if (typeof niNumber === 'string') cachedNino.current = niNumber;

  const radioValue = useMemo(() => {
    if (niNumber === null) return 'no';
    if (typeof niNumber === 'string') return 'yes';
    return '';
  }, [niNumber]);

  const hasNino = typeof niNumber === 'string';
  return (
    <>
      <Stack>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingByUser.doYouHaveNINO')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            value={radioValue}
            onChange={(event) => {
              if (event.target.value === 'yes') {
                setNiNumber(cachedNino.current);
                setTimeout(() => ninoTextField.current?.focus());
              } else {
                setNiNumber(null);
              }
            }}
            sx={{ flexFlow: 'row' }}
          >
            <FormControlLabel
              labelPlacement="end"
              value={'yes'}
              control={<StyledRadio />}
              label={
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    color: themeColors.DarkGrey,
                  }}
                >
                  {polyglot.t('General.yes')}
                </Typography>
              }
              disabled={disabled}
              sx={{ width: 200 }}
            />
            <FormControlLabel
              labelPlacement="end"
              value={'no'}
              control={<StyledRadio />}
              label={
                <Typography
                  sx={{
                    ...themeFonts.caption,
                    color: themeColors.DarkGrey,
                  }}
                >
                  {polyglot.t('General.no')}
                </Typography>
              }
              disabled={disabled}
            />
          </RadioGroup>
        </FormControl>
        <Stack sx={{ overflow: 'hidden' }}>
          <Stack sx={{ mt: hasNino ? 0 : '-30%', transition: 'margin-top 0.4s' }}>
            <TextfieldComponent
              ref={ninoTextField}
              label={polyglot.t('OnboardingByUser.NINO')}
              maxLength={9}
              onChange={(e) => {
                if (niNumber !== null) setNiNumber(e.target.value.toUpperCase().replace(/[^A-Z\d]+/g, ''));
              }}
              value={cachedNino.current}
              clearText={() => setNiNumber('')}
              disabled={disabled || !hasNino}
              helperText={helperText}
              error={error}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
