import { ReactNode } from 'react';

import { Box, Popover, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly anchorEl: HTMLElement | null;
  readonly takeAction: () => void;
  readonly message: string | ReactNode;
  readonly callToAction?: string;
}

export const NotificationModal = ({ isOpen, onClose, anchorEl, takeAction, message, callToAction }: Props) => {
  const { polyglot } = usePolyglot();

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      PaperProps={{ sx: { boxShadow: '0px 2px 20px 0px #0D0D0E1A', border: '0px solid #e6eaed', width: 260 } }}
    >
      <Box sx={{ ...spacing.pad20 }}>
        {typeof message === 'string' ? (
          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{message}</Typography>
        ) : (
          <Box>{message}</Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: spacing.m10,
            gap: spacing.g10,
          }}
        >
          <ButtonComponent colorVariant="secondary" sizeVariant="small" onClick={() => onClose()}>
            {polyglot.has('NotificationModal.no') ? polyglot.t('NotificationModal.no') : 'No'}
          </ButtonComponent>
          <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => takeAction()}>
            {callToAction ?? polyglot.t('General.yes')}
          </ButtonComponent>
        </Box>
      </Box>
    </Popover>
  );
};
