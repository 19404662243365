import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { OptionObject, SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomBenefitAPI } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import {
  CreateCustomBenefitDto,
  CustomBenefitAllowanceType,
  CustomBenefitCategory,
  CustomBenefitType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceType } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import {
  translateCustomBenefitAllowanceType,
  translateCustomBenefitCategory,
  translateCustomBenefitType,
} from '@v2/infrastructure/i18n/translate.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_BENEFITS_DETAILS_ROUTE, SETTINGS_CREATE_NEW_BENEFIT_ROUTE } from '@/lib/routes';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';

const getBenefitCategoryOptions = (polyglot: Polyglot): readonly OptionObject[] =>
  Object.values(CustomBenefitCategory).map((key) => ({
    label: translateCustomBenefitCategory(key, polyglot),
    value: key,
  }));

const getBenefitTypeOptionDescription = (type: CustomBenefitType, polyglot: Polyglot): string => {
  if (type === CustomBenefitType.Allowance) return polyglot.t('BenefitModule.allowanceDesc');
  if (type === CustomBenefitType.Loan) return polyglot.t('BenefitModule.loanDesc');
  if (type === CustomBenefitType.Recurring) return polyglot.t('BenefitModule.recurringDesc');
  return '';
};

export const getBenefitTypeOptions = (category: CustomBenefitCategory, polyglot: Polyglot): readonly OptionObject[] =>
  Object.values(CustomBenefitType)
    .filter((key) => category !== CustomBenefitCategory.Pension || key === CustomBenefitType.Recurring)
    .map((key) => ({
      label: translateCustomBenefitType(key, polyglot),
      value: key,
      description: getBenefitTypeOptionDescription(key, polyglot),
    }));

export const getCustomBenefitAllowanceTypeOptions = (polyglot: Polyglot): readonly OptionObject[] =>
  Object.values(CustomBenefitAllowanceType).map((key) => ({
    label: translateCustomBenefitAllowanceType(key, polyglot),
    value: key,
  }));

interface NewBenefitDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

export const NewBenefitDrawer = ({ isOpen, setIsOpen, refresh }: NewBenefitDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <NewBenefitDrawerContent setIsOpen={setIsOpen} refresh={refresh} />
    </Suspense>
  </DrawerModal>
);

const NewBenefitDrawerContent = ({
  setIsOpen,
  refresh,
}: {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [isCreatingCustomBenefit, setIsCreatingCustomBenefit] = useState(false);

  const createCustomBenefit = useCallback(
    async (name: string, category: CustomBenefitCategory, type: CustomBenefitType) => {
      try {
        setIsCreatingCustomBenefit(true);
        const data: CreateCustomBenefitDto = {
          type,
          category,
          name,
          description: null,
          includedUserIds: [],
          benefitLink: null,
        };
        const createdBenefitId = await CustomBenefitAPI.createCustomBenefit(data);
        await refresh();
        setIsOpen(false);
        history.push(
          generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
            productType: type,
            category,
            id: createdBenefitId,
          })
        );
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      }
      setIsCreatingCustomBenefit(false);
    },
    [history, polyglot, refresh, setIsOpen, showMessage]
  );

  const onSubmit = useCallback(
    async ({ type, category, name }: { type: string; category: string; name: string }) => {
      if (category !== CustomBenefitCategory.Pension && category !== InsuranceType.Health)
        await createCustomBenefit(name, category as CustomBenefitCategory, type as CustomBenefitType);
      else {
        history.push(
          generatePath(SETTINGS_CREATE_NEW_BENEFIT_ROUTE, {
            category,
            name,
          })
        );
      }
    },
    [createCustomBenefit, history]
  );

  const formik = useFormik<{ category: string; type: string; name: string }>({
    initialValues: {
      category: '',
      type: '',
      name: '',
    },
    validationSchema: yup.object({
      category: yup.string().required(polyglot.t('validation.requiredField')),
      type: yup.string().required(polyglot.t('validation.requiredField')),
      name: yup.string().required(polyglot.t('validation.requiredField')),
    }),
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('BenefitModule.newBenefit')}</Typography>

        <TextfieldComponent
          name="name"
          label={polyglot.t('General.name')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && !!formik.errors.name}
          helperText={(formik.touched.name && formik.errors.name) ?? ' '}
        />

        <SelectComponent
          name="category"
          label={polyglot.t('General.category')}
          options={getBenefitCategoryOptions(polyglot)}
          value={formik.values.category}
          onChange={(e) => {
            formik.handleChange(e);
            const value = e.target.value as CustomBenefitCategory;
            if (value === CustomBenefitCategory.Pension) {
              formik.setFieldValue('type', CustomBenefitType.Recurring);
            }
          }}
          error={formik.touched.category && !!formik.errors.category}
          helperText={(formik.touched.category && formik.errors.category) ?? ' '}
        />

        <SelectComponent
          name="type"
          label={polyglot.t('General.type')}
          options={getBenefitTypeOptions(formik.values.category as CustomBenefitCategory, polyglot)}
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && !!formik.errors.type}
          helperText={(formik.touched.type && formik.errors.type) ?? ' '}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.create')}
            loading={isCreatingCustomBenefit}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.MONEY_PRO}
          messageSuffix="proGeneric"
        />
      </Form>
    </FormikProvider>
  );
};
