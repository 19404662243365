import axios from 'axios';

import {
  GrowthFactor,
  GrowthFactorCreate,
  GrowthFactorUpdate,
} from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class GrowthFactorAPI {
  static async createFactor(factorBody: GrowthFactorCreate): Promise<GrowthFactor> {
    return (await axios.post('/apiv2/growth-factor', factorBody)).data;
  }

  static async updateFactor(factorBody: GrowthFactorUpdate): Promise<void> {
    await axios.patch('/apiv2/growth-factor', factorBody);
  }
}
export class GrowthFactorEndpoints {
  static getGrowthFactorsByCompanyId(): Endpoint<GrowthFactor[]> {
    return {
      url: '/apiv2/growth-factor',
    };
  }
}
