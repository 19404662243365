import React, { useCallback, useMemo, useRef, useState } from 'react';

import { SxProps, Theme } from '@mui/material';

import { TextfieldComponent } from '@/v2/components/forms/textfield.component';

const validateMoneyInput = (s: string, allowNegative: boolean) => {
  const match = s.match(/-?(\d{0,8}\.?\d{0,2})/);
  if (!match) return '';
  return allowNegative ? match[0] : match[1];
};

type Props = {
  id?: string;
  name: string;
  label: string;
  value: number | undefined;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  // value will be undefined if the text field is empty
  onChange?: (value: number | undefined) => void;
  error?: boolean;
  helperText?: string | false;
  /**
   * Empty text is treated as a 0 value instead of undefined
   */
  emptyIsZero?: boolean;
  /**
   * pressing clear sets the text to 0 instead of an empty string
   */
  clearToZero?: boolean;
  allowNegative?: boolean;
  sx?: SxProps<Theme>;
};

/**
 * TextField component that limits text input to `nnnnn.nn` format and forces
 * a number-based onChange event.
 */
export const MoneyTextfieldComponent = ({
  allowNegative,
  autoFocus,
  clearToZero,
  disabled,
  readonly,
  emptyIsZero,
  error,
  helperText,
  id,
  label,
  name,
  onChange,
  value,
  sx,
}: Props) => {
  const lastTypedValue = useRef<string>();
  const lastValue = useRef<number>(0);
  const [updatingText, setUpdatingText] = useState<string>();

  const textValue = useMemo(() => {
    setUpdatingText(undefined);
    lastValue.current = value ?? 0;
    const typedValue = lastTypedValue.current;
    if (typeof typedValue === 'undefined') {
      return value?.toFixed(2) ?? (emptyIsZero ? '0' : '');
    }
    if (typeof value === 'undefined') {
      return emptyIsZero ? '0' : '';
    }
    if (!value && (!typedValue || typedValue === '-')) {
      // user has cleared the input
      return typedValue;
    }
    if (parseFloat(typedValue) === value) {
      // updated value matched the typed value - use the typed value
      return typedValue;
    }
    return value.toFixed(2);
  }, [emptyIsZero, value]);

  const changeTextValue = useCallback(
    (s: string) => {
      const newTextValue = validateMoneyInput(s, !!allowNegative);
      lastTypedValue.current = newTextValue;
      setUpdatingText(newTextValue);
      if (newTextValue) {
        onChange?.(parseFloat(newTextValue) || 0);
      } else {
        onChange?.(emptyIsZero ? 0 : undefined);
      }
    },
    [allowNegative, emptyIsZero, onChange]
  );

  const normaliseInput = useCallback(() => {
    // set the input to a formatted value - this stops the input
    // being left as "-" or some other non-standard currecy value
    setUpdatingText(lastValue.current.toFixed(2));
  }, []);

  return (
    <TextfieldComponent
      id={id}
      name={name}
      label={label}
      type="tel" // use tel input because we want numeric input but without the up/down controls
      autoComplete="off"
      autoFocus={autoFocus}
      value={typeof updatingText === 'string' ? updatingText : textValue}
      onBlur={() => normaliseInput()}
      onChange={(e) => changeTextValue(e.target.value)}
      InputProps={{
        readOnly: readonly,
      }}
      clearText={() => changeTextValue(clearToZero ? '0' : '')}
      error={error}
      helperText={helperText}
      disabled={disabled}
      sx={sx}
    />
  );
};
