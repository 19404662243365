import { useContext, useMemo } from 'react';

import { AttendanceCompanyRouter } from '@v2/feature/attendance/company/attendance-company.router';
import { AttendanceMeRouter } from '@v2/feature/attendance/me/attendance-me.router';
import { AttendanceTeamRouter } from '@v2/feature/attendance/team/attendance-team.router';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { ATTENDANCE_COMPANY_ROUTE, ATTENDANCE_ME_ROUTE, ATTENDANCE_TEAM_ROUTE } from '@/lib/routes';

export const AttendanceRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false,
  });

  const isManager = useMemo(() => {
    const nonTerminatedDirectReports =
      userSummaryWithReports?.directReports?.filter(
        (d) => d.userEvent && d.userEvent.status && ['Employed', 'Hired'].includes(d.userEvent.status)
      )?.length ?? 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);

  return (
    <Switch>
      <RouteScopesHas scopes={['attendance:all']} path={ATTENDANCE_COMPANY_ROUTE}>
        <AttendanceCompanyRouter />
      </RouteScopesHas>

      {isManager && (
        <RouteScopesHas scopes={['attendance:manager']} path={ATTENDANCE_TEAM_ROUTE}>
          <AttendanceTeamRouter isManager={isManager} />
        </RouteScopesHas>
      )}

      <RouteScopesHas scopes={['attendance']} path={ATTENDANCE_ME_ROUTE}>
        <AttendanceMeRouter />
      </RouteScopesHas>
    </Switch>
  );
};
