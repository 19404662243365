import { useContext } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { NewExpensePage } from './pages/new-expense.page';
import { NewInvoicePage } from './pages/new-invoice.page';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  PAYMENT_ME_NEW_EXPENSE_ROUTE,
  PAYMENT_ME_NEW_INVOICE_ROUTE,
  PAYMENT_ME_OVERVIEW_ROUTE,
  PAYMENT_ME_ROUTE,
} from '@/lib/routes';
import { PaymentsMePage } from '@/v2/feature/payments/pages/payments.me.page';

export const PaymentsMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);

  return (
    <Switch>
      <RouteScopesControl context={scopesContext} scopes={['payments']} path={PAYMENT_ME_OVERVIEW_ROUTE} exact>
        <PaymentsMePage />
      </RouteScopesControl>
      <RouteScopesControl scopes={['payments']} context={scopesContext} path={PAYMENT_ME_NEW_INVOICE_ROUTE} exact>
        <NewInvoicePage preselectedEmployee={globalState.user.userId} />
      </RouteScopesControl>
      <RouteScopesControl scopes={['payments']} context={scopesContext} path={PAYMENT_ME_NEW_EXPENSE_ROUTE} exact>
        <NewExpensePage preselectedEmployee={globalState.user.userId} />
      </RouteScopesControl>

      <Redirect to={PAYMENT_ME_OVERVIEW_ROUTE} from={PAYMENT_ME_ROUTE} />
    </Switch>
  );
};
