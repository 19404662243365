import { eachDayOfInterval, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';

const getDaysInAWeekOptions = { weekStartsOn: 1 } as const;

/**
 * date-fns startOfWeek function with Zelt's default configuration
 * @param d a day of the target week
 * @returns start {Date} of the week
 */
export function Z_startOfWeek(d: Date): Date {
  return startOfWeek(d, getDaysInAWeekOptions);
}

/**
 * date-fns endOfWeek function with Zelt's default configuration
 * @param d a day of the target week
 * @returns end {Date+Time} of the week. Up to 59:99:9999
 */
export function Z_endOfWeek(d: Date): Date {
  return endOfWeek(d, getDaysInAWeekOptions);
}

export function getWeekDays(d: Date): readonly Date[] {
  return eachDayOfInterval({ start: Z_startOfWeek(d), end: Z_endOfWeek(d) });
}

export function getMonthDays(d: Date): readonly Date[] {
  return eachDayOfInterval({ start: startOfMonth(d), end: endOfMonth(d) });
}
