import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as TasksIcon } from '@/images/onboarding-step-icons/DeviceBig.svg';
import { ActionSelectionItem } from '@/v2/components/action-selection-group.component';
import { EditDevices } from '@/v2/feature/onboarding/onboarding-items/devices/edit-devices.component';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  template: OnboardingTemplate;
  onDelete?: () => void;
  setDrawerOpen?: (open: boolean) => void;
  setDrawerView?: (view: JSX.Element) => void;
};

export const DevicesOnboardingItem = ({ template, onDelete, setDrawerOpen, setDrawerView }: Props) => {
  const { polyglot } = usePolyglot();
  return (
    <ActionSelectionItem
      step={{
        Icon: TasksIcon,
        title: polyglot.t('DevicesOnboardingItem.title'),
        description: polyglot.t('DevicesOnboardingItem.desc'),
      }}
      addButtonIcon={template.template.device ? 'edit' : 'plus'}
      onAddClick={() => {
        setDrawerView?.(
          <EditDevices
            config={template.template.device}
            onSave={(config) => {
              template.template.device = {
                ...config,
              };
              setDrawerOpen?.(false);
            }}
          />
        );
        setDrawerOpen?.(true);
      }}
      onDeleteClick={template.template.device && onDelete}
      sx={{
        py: spacing.py20,
      }}
    />
  );
};
