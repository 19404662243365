import type { SystemStyleObject } from '@mui/system';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const settingsMaxWidth: SystemStyleObject = {
  maxWidth: { sm: '300px', md: '600px', lg: '600px' },
};

export const settingHeaderSx: SystemStyleObject = {
  alignItems: 'start',
  display: 'flex',
  ...spacing.pb20,
  justifyContent: 'space-between',
  gap: spacing.g10,
};

export const settingListSx: SystemStyleObject = { display: 'flex', flexDirection: 'column', margin: 0, padding: 0 };

export const settingSx: SystemStyleObject = {
  display: 'flex',
  width: '100%',
  gap: spacing.g10,
  alignItems: 'center',
};

export const settingTermSx: SystemStyleObject = {
  maxWidth: { sm: '90px', md: '190px', lg: '190px' },
  width: '100%',
  paddingY: spacing.p8,
  ...themeFonts.caption,
};
export const settingValueSx: SystemStyleObject = {
  display: 'flex',
  gap: spacing.g10,
  alignItems: 'center',
  maxWidth: { sm: '170px', md: '370px', lg: '370px' },
  width: '100%',
  ...themeFonts.caption,
  paddingY: spacing.p8,
  color: themeColors.Grey,
};

export const settingIconSx: SystemStyleObject = {
  maxWidth: { sm: '40px', md: '40px', lg: '40px' },
  width: '100%',
  display: 'flex',
  alignItems: 'center',
};
