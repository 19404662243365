import { Box, Link } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';

import { spacing } from '@/v2/styles/spacing.styles';

export const RemoteApiTokenInstructions = () => {
  return (
    <Box>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        a) Log in to{' '}
        <Link target="_blank" href="https://employ.remote.com/sign-in">
          Remote.com
        </Link>
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        b) Go to the integrations page using the menu on the left, under the company tab.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        c) Under the My Integrations section, click on Remote API.
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        d) Click on either the Generate token button in the card or on the Generate API token button in the header
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        e) A popup will open, and we will ask for the API token name. In order to generate the access token
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        f) After creating an API token, it will show a table with all the tokens linked to the company, including the
        new active access token you just created
      </Typography>
      <Typography variant="caption" color="white" sx={{ mb: spacing.m5 }}>
        g) Click on the 3 dot menu on the right side of the table to copy the newly generated API token, and paste it
        here.
      </Typography>
    </Box>
  );
};
