import { Route, Switch } from 'react-router-dom';

import { DASHBOARD_ROUTE } from '@/lib/routes';
import { DashboardPage } from '@/v2/feature/dashboard/dashboard.page';

export const DashboardRouter = () => {
  return (
    <Switch>
      <Route exact path={DASHBOARD_ROUTE} component={DashboardPage} />
    </Switch>
  );
};
