/**
 *
 * @param inputElement HTMLInputElement instance or ID of HTMLInputElement
 * @param selectContent true if the content should be selected when focused
 */
export function setFocusToInput(inputElement: string | HTMLInputElement | undefined, selectContent = true) {
  const element =
    typeof inputElement === 'string'
      ? (document.getElementById(inputElement) as HTMLInputElement | undefined)
      : inputElement;

  if (element?.nodeName === 'INPUT' || element?.nodeName === 'TEXTAREA') {
    element.focus();
    if (selectContent) {
      element.select();
    }
  }
}
