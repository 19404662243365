import React, { useState } from 'react';

import { Box } from '@mui/material';
import { KeyedMutator } from 'swr';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UpsertReviewTemplateModal } from '@/v2/feature/growth/reviews/features/review-template/review-template-list/components/upsert-review-template.component-modal';
import { ReviewTemplateById } from '@/v2/feature/growth/reviews/interfaces/review-template.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const ReviewTemplateGeneralPage = ({
  templateObject,
  refreshTemplate,
}: {
  templateObject: ReviewTemplateById | null | undefined;
  refreshTemplate: KeyedMutator<ReviewTemplateById> | undefined;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let template: ReviewTemplateById['template'] | undefined;

  if (templateObject) {
    ({ template } = templateObject);
  }
  return (
    <Box>
      <Box
        className="sub-title"
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: spacing.p16, mt: spacing.m4 }}
      >
        <Typography variant="title3">General</Typography>
        {template?.companyId && (
          <IconButton
            colorVariant="secondary"
            sizeVariant="small"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <Edit {...iconSize} />
          </IconButton>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m24, px: spacing.p16 }}>
        {template?.name && <ViewItem title="Name" value={template?.name} />}
        {template?.createdAt && (
          <ViewItem
            title="Created on"
            value={new LocalDate(template?.createdAt)
              .getDate()
              .toLocaleDateString(undefined, { day: 'numeric', month: 'short' })}
          />
        )}
        {template?.createdBy && <ViewItem title="Created by" value={<UserCell userId={template?.createdBy} />} />}
      </Box>

      <UpsertReviewTemplateModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          refreshTemplate?.();
        }}
        template={template}
      />
    </Box>
  );
};

const ViewItem = ({ title, value }: { title: string; value: React.JSX.Element | string }) => {
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: '1.5fr 2.5fr',
        paddingY: spacing.p4,
      }}
    >
      <Typography variant="caption" sx={{ color: themeColors.Grey }}>
        {title}
      </Typography>
      {typeof value === 'string' ? <Typography variant="title4">{value}</Typography> : <Box>{value}</Box>}
    </Box>
  );
};
