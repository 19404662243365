import { useEffect, useState } from 'react';

import { Box, Dialog, DialogContent } from '@mui/material';

import { BufferData } from '@/v2/feature/documents/documents.interface';

interface PreviewModalProps {
  docData?: string;
  fileBuffer?: BufferData;
  contentType?: string;
  visible: boolean;
  onClose: () => void;
  title: string;
}

export const DocPreviewer = ({
  docData,
  fileBuffer,
  contentType,
  visible,
  onClose,
  title,
}: PreviewModalProps): JSX.Element => {
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);

  useEffect(() => {
    if (fileBuffer) {
      const buffer = new Uint8Array(fileBuffer.data);
      const blob = new Blob([buffer], { type: contentType });
      const objectUrl = URL.createObjectURL(blob);
      setIframeSrc(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [contentType, fileBuffer]);

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      sx={{ margin: 'auto', height: '100%', overflowX: 'hidden', width: '100%' }}
      PaperProps={{ sx: { height: '100%', overflowX: 'hidden', width: '100%' } }}
      maxWidth="md"
    >
      {/* {title && (
        <DialogTitle id="previewTitle" sx={{ display: 'flex' }}>
          <Box>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{title}</Typography>
          </Box>
        </DialogTitle>
      )} */}

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: '100%',
            height: '100%',
          }}
        >
          {fileBuffer && contentType && iframeSrc && (
            <iframe
              height="100%"
              width="100%"
              style={{ overflowX: 'hidden', border: 'none', outline: 'none' }}
              src={iframeSrc}
              title={title}
            />
          )}
          {docData && (
            <iframe
              height="100%"
              width="100%"
              style={{ overflowX: 'hidden', border: 'none', outline: 'none' }}
              src={docData}
              title={title}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
