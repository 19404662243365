import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { TASKS_TEAM_OVERVIEW_ROUTE } from '@/lib/routes';
import { TasksListPage } from '@/v2/feature/task/pages/tasks-list.page';

export const TaskTeamRouter = ({ isManager }: { isManager: boolean }): JSX.Element => {
  return (
    <Switch>
      <RouteScopesHas scopes={['task:manager']} path={TASKS_TEAM_OVERVIEW_ROUTE} exact>
        <TasksListPage reach="team" isManager={isManager} />
      </RouteScopesHas>

      <Redirect to={TASKS_TEAM_OVERVIEW_ROUTE} />
    </Switch>
  );
};
