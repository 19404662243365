import { useCallback, useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import { CompanyPayroll, PayRunSchedule } from '@shared/modules/payroll/payroll.types';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE } from '@/lib/routes';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PayrunListSideMenu } from '@/v2/feature/payroll/components/payrun-list-side-menu.component';
import { UpcomingPayrunTable } from '@/v2/feature/payroll/components/upcoming-payrun-table.component';
import { formatPayrunPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { GlobalPayrollTableByPeriod } from '@/v2/feature/payroll/features/payroll-global/components/global-payroll-table.component';
import { GlobalPayrollAPI } from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { PayrunStatus } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component';
import { PayRunDto } from '@/v2/feature/payroll/payroll.dto';
import { PayrunStates } from '@/v2/feature/payroll/payroll.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  payroll: CompanyPayroll;
  refreshPayroll?: () => Promise<void>;
};

export const GlobalPayrollPayruns = ({ payroll, refreshPayroll }: Props) => {
  const { polyglot } = usePolyglot();
  const loc = useLocation<{ payrunId: number }>();
  const initialPayrunId = loc.state?.payrunId;
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [selectedPayrunId, setSelectedPayrunId] = useState<PayRunDto['id'] | 'next-payrun'>();
  const [loadingPayrun, setLoadingPayrun] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const selectedPayrun = useMemo(() => {
    return payroll?.globalPayruns.find(({ id }) => id === selectedPayrunId) ?? null;
  }, [payroll?.globalPayruns, selectedPayrunId]);

  const showPayrun = useCallback(
    async (
      payrollId: number,
      { taxYear, payPeriod, period }: Pick<PayRunSchedule, 'taxYear' | 'payPeriod' | 'period'>
    ) => {
      setLoadingPayrun(true);
      routerHistory.push(
        generatePath(PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, {
          payrollId,
          taxYear,
          payPeriod,
          periodNumber: period,
        })
      );
    },
    [routerHistory]
  );

  const createPayrun = useCallback(
    async (payrollId: number, { taxYear, payPeriod }: PayRunSchedule) => {
      setLoadingPayrun(true);
      GlobalPayrollAPI.openPayrun(payrollId, taxYear, payPeriod).then(
        ({ payrollId, taxYear, payPeriod, period }) => {
          showPayrun(payrollId, { taxYear, payPeriod, period });
        },
        (error) => {
          setLoadingPayrun(false);
          showMessage(nestErrorMessage(error), 'error');
        }
      );
    },
    [showMessage, showPayrun]
  );

  const selectInitialPayrun = useCallback(() => {
    if (typeof selectedPayrunId !== 'undefined') return;
    if (typeof initialPayrunId === 'number' && payroll.globalPayruns.some((p) => p.id === initialPayrunId)) {
      setSelectedPayrunId(initialPayrunId);
      return;
    }
    if (payroll.nextPayRun && !payroll.currentPayRun) {
      setSelectedPayrunId('next-payrun');
      return;
    }
    const openPayrun = payroll.globalPayruns.find((payrun) => payrun.state === 'DRAFT');
    if (openPayrun) {
      setSelectedPayrunId(openPayrun.id);
      return;
    }
  }, [initialPayrunId, payroll, selectedPayrunId]);

  useEffect(selectInitialPayrun, [selectInitialPayrun]);

  return (
    <>
      <Stack sx={{ flexFlow: 'row', flex: 1, gap: spacing.g20 }}>
        <PayrunListSideMenu
          payroll={payroll}
          selectedPayrunId={selectedPayrunId}
          onPayrunSelected={(payrunId) => setSelectedPayrunId(payrunId)}
          disabled={loadingPayrun}
          sx={{ ml: '20px', height: '100vh', flexShrink: 0 }}
        />
        <Stack sx={{ flex: 1, gap: spacing.g20, height: '100vh', overflowX: 'hidden' }}>
          {selectedPayrunId === 'next-payrun' && payroll.nextPayRun && (
            <>
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', mr: '20px' }}>
                <Stack sx={{ mt: spacing.mt20, gap: spacing.g2 }}>
                  <Typography variant="title2">{formatPayrunPeriod(payroll.nextPayRun, polyglot.locale())}</Typography>
                  <Typography variant="caption">{polyglot.t('CompanyPayroll.upcoming')}</Typography>
                </Stack>
                <LoaderButton
                  loading={loadingPayrun}
                  sizeVariant="small"
                  colorVariant="primary"
                  onClick={() => payroll.nextPayRun && createPayrun(payroll.id, payroll.nextPayRun)}
                >
                  {polyglot.t('CompanyPayroll.startPayrun')}
                </LoaderButton>
              </Stack>
              <UpcomingPayrunTable
                disabled={loadingPayrun}
                payroll={payroll}
                nextPayrun={payroll.nextPayRun}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                refreshPayroll={async () => {
                  await Promise.all([refreshPayroll?.() /* , refreshPayrun?.() */]);
                }}
                selectedUsers={selectedUserIds}
                setSelectedUsers={(users) => setSelectedUserIds(users)}
                noCurrencySymbol
                sx={{ display: 'flex', flexFlow: 'column', overflow: 'hidden', mr: spacing.mr20 }}
              />
            </>
          )}
          {selectedPayrun && (
            <>
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between', mr: '20px' }}>
                <Stack sx={{ mt: spacing.mt20, gap: spacing.g2 }}>
                  <Typography variant="title2">{formatPayrunPeriod(selectedPayrun, polyglot.locale())}</Typography>
                  <PayrunStatus state={selectedPayrun.state as PayrunStates} />
                </Stack>
                <LoaderButton
                  loading={loadingPayrun}
                  sizeVariant="small"
                  colorVariant="primary"
                  onClick={() => showPayrun(selectedPayrun.payrollId, selectedPayrun)}
                >
                  {polyglot.t('CompanyPayroll.viewPayrun')}
                </LoaderButton>
              </Stack>
              <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
              <GlobalPayrollTableByPeriod
                payrollId={selectedPayrun.payrollId}
                taxYear={selectedPayrun.taxYear}
                payPeriod={selectedPayrun.payPeriod}
                period={selectedPayrun.period}
                searchQuery={searchQuery}
              />
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
