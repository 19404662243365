import axios from 'axios';

import { SurveySection, SurveySectionUpsert } from '@/v2/feature/growth/surveys/interfaces/survey-section.interface';

export class SurveySectionAPI {
  static async createSectionByCycleId(cycleId: string, sectionBody: SurveySectionUpsert): Promise<SurveySection> {
    return (await axios.post(`/apiv2/survey-section/cycle/${cycleId}`, sectionBody)).data;
  }

  static async updateSurveySectionInBank(
    sectionId: string,
    cycleId: string,
    sectionBody: SurveySectionUpsert
  ): Promise<void> {
    await axios.patch(`/apiv2/survey-section/${sectionId}/cycle/${cycleId}`, sectionBody);
  }

  static async deleteSurveySection(sectionId: string, cycleId: string): Promise<void> {
    await axios.delete(`/apiv2/survey-section/${sectionId}/cycle/${cycleId}`);
  }
}
