import { Box, Typography } from '@mui/material';
import { AppIntegrationStub } from '@v2/feature/app-integration/app-integration.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import { ReactComponent as CheckCircle } from '@/images/side-bar-icons/CheckCircle.svg';
import { Scope, ScopeContext } from '@/models';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly avatarImg?: string;
  readonly avatarIcon?: JSX.Element;
  readonly title: string;
  readonly titleClick?: () => void;
  readonly description: string;
  readonly active: boolean;
  readonly actionAltIcon?: JSX.Element;
  readonly showAction?: boolean;
  readonly actionText?: string;
  readonly stub?: AppIntegrationStub | string;
  readonly actionClick?: () => void;
  readonly showDivider?: boolean;
  readonly actionScope?: readonly Scope[];
  context?: ScopeContext | undefined;
}

export const ActionCard = ({
  avatarImg,
  avatarIcon,
  title,
  titleClick,
  description,
  active,
  actionAltIcon,
  showAction,
  actionText,
  stub,
  actionClick,
  showDivider = false,
  actionScope = [],
  context,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
      width: '100%',
      borderBottom: showDivider ? borders.middle : 'none',
      paddingBottom: spacing.p25,
      minHeight: '100px',
      cursor: 'pointer',
    }}
    onClick={titleClick ? () => titleClick() : undefined}
  >
    <Box sx={{ display: 'flex', gap: '15px' }}>
      <Box sx={{ width: 'auto' }}>
        {avatarImg && (
          <Box sx={{ maxWidth: '50px' }}>
            <img src={avatarImg} width={50} alt={stub} />
          </Box>
        )}
        {avatarIcon && <Box sx={{ maxWidth: '30px' }}>{avatarIcon}</Box>}
      </Box>
      <Box>
        <Typography
          sx={{ ...themeFonts.title4, mb: '5px', color: themeColors.DarkGrey }}
          onClick={titleClick ? () => titleClick() : undefined}
        >
          {title}
        </Typography>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{description}</Typography>
      </Box>
    </Box>
    <Box>
      {active && (
        <Box sx={{ mt: '5px', ml: 7 }}>
          <CheckCircle {...iconSize} />
        </Box>
      )}
      {!active && showAction && (
        <ScopesControl scopes={actionScope} context={context}>
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={(event) => {
              event.stopPropagation();
              if (actionClick) actionClick();
            }}
          >
            {actionText}
          </ButtonComponent>
        </ScopesControl>
      )}
      {!active && !showAction && actionAltIcon && <Box sx={{ mt: '5px', ml: 7 }}>{actionAltIcon}</Box>}
    </Box>
  </Box>
);
