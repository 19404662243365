import { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LOGIN_ROUTE, USER_ONBOARDING_PASSWORD } from '@/lib/routes';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { PasswordSetResult } from '@/v2/feature/user-onboarding/by-admin/interface/onboarding.interface';

export const UserOnboardingWelcomePage = (): JSX.Element => {
  const routerHistory = useHistory();

  const [showMessage] = useMessage();

  const searchParams = new URLSearchParams(window.location.search);
  const passwordScreen = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const apiParams = new URLSearchParams();
    apiParams.set('id', searchParams.get('id') || '');
    apiParams.set('token', searchParams.get('token') || '');
    apiParams.set('expires', searchParams.get('expires') || '');
    apiParams.set('email', searchParams.get('email') || '');
    apiParams.set('signature', searchParams.get('signature') || '');
    return `${USER_ONBOARDING_PASSWORD}?${apiParams.toString()}`;
  }, []);
  const userId = Number(searchParams.get('id'));
  const email = searchParams.get('email');

  useEffect(() => {
    async function checkPwdSet() {
      try {
        if (!email) {
          routerHistory.replace(LOGIN_ROUTE);
          return;
        }
        const passwordSet: PasswordSetResult | undefined = await AuthAPI.isPasswordSet(email);
        const isPasswordAlreadyDone = passwordSet?.userId === userId;
        if (isPasswordAlreadyDone) {
          routerHistory.replace(LOGIN_ROUTE, {
            continueOnboarding: true,
            email,
          });
          return;
        }
        routerHistory.replace(passwordScreen());
      } catch (error) {
        showMessage(
          `Something bad happened while trying to onboard user: Please try again. ${nestErrorMessage(error)}`,
          'error'
        );
      }
    }
    checkPwdSet();
  }, [email, passwordScreen, routerHistory, showMessage, userId]);

  return <></>;
};
