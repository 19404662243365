import Polyglot from 'node-polyglot';
import * as yup from 'yup';

export const getAbsencePolicyNotificationsValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    notificationsEnabled: yup
      .boolean()
      .typeError(polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
    remindersSettings: yup
      .object({
        slack: yup
          .boolean()
          .typeError(polyglot.t('validation.selectValid'))
          .required(polyglot.t('absencePolicyNotificationsValidationSchema.emailInvalid')),
        slackChannels: yup
          .array()
          .of(yup.string())
          .when('slack', {
            is: true,
            then: yup.array().min(1, polyglot.t('absencePolicyNotificationsValidationSchema.channelsRequired')),
          }),
        remindersScheduledHour: yup
          .number()
          .integer(polyglot.t('absencePolicyNotificationsValidationSchema.valueInvalid'))
          .typeError(polyglot.t('absencePolicyNotificationsValidationSchema.valueInvalid'))
          .min(0)
          .max(23)
          .required(polyglot.t('absencePolicyNotificationsValidationSchema.valueInvalid')),
        remindersScheduledMinute: yup
          .number()
          .integer(polyglot.t('absencePolicyNotificationsValidationSchema.valueInvalid'))
          .typeError(polyglot.t('absencePolicyNotificationsValidationSchema.valueInvalid'))
          .min(0)
          .max(59)
          .required(polyglot.t('absencePolicyNotificationsValidationSchema.valueInvalid')),
      })
      .required(polyglot.t('absencePolicyNotificationsValidationSchema.reminders')),
  });

export const ABSENCE_POLICY_NOTIFICATIONS_REMINDERS_HOURS_OPTIONS = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: i === 0 ? '0 midnight' : i === 12 ? '12 noon' : i > 12 ? `${i - 12} PM` : `${i} AM`,
}));

export const absencePolicyApprovalValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    backoffApprovalRuleId: yup.number().integer().required(polyglot.t('absencePolicyApprovalValidationSchema.default')),
    updateApprovalNotRequired: yup.boolean().required(''),
  });

export const getAbsencePolicyPayrollValidationSchema = (polyglot: Polyglot) =>
  yup.object({
    includedInPayroll: yup.boolean().required(polyglot.t('ValidationMessages.requiredField')),
    inPayrollRequests: yup.boolean().required(polyglot.t('ValidationMessages.requiredField')),
    inPayrollOffboarding: yup.boolean().required(polyglot.t('ValidationMessages.requiredField')),
    payCode: yup
      .string()
      .nullable()
      .when('includedInPayroll', {
        is: true,
        then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
        otherwise: (schema) => schema.notRequired(),
      }),
    payCodeMultiplier: yup
      .number()
      .nullable()
      .typeError(polyglot.t('ValidationMessages.validValue'))
      .when('inPayrollRequests', {
        is: true,
        then: yup
          .number()
          .typeError(polyglot.t('ValidationMessages.validValue'))
          .required(polyglot.t('ValidationMessages.requiredField')),
        otherwise: yup.number().nullable().notRequired(),
      }),
  });
