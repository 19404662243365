import React, { useState } from 'react';

import { Alert, Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { FormikProvider, useFormik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { LOGIN_ROUTE } from '@/lib/routes';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const AuthResetPasswordPage = (): React.JSX.Element => {
  const routerHistory = useHistory();

  const qs = new URLSearchParams(useLocation().search);
  const token = qs.get('t') ?? undefined;
  const email = qs.get('email')?.trim().replace(/ /g, '+');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [credentialsError, setCredentialsError] = useState(false);

  const validationSchema = yup.object({
    confirmPassword: yup
      .string()
      .min(12, 'It should be at least 12 characters long')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (formData) => {
      setCredentialsError(false);
      setErrorMessage('');
      setIsLoading(true);
      try {
        await AuthAPI.resetPassword(formData.confirmPassword, token, email);
        routerHistory.push(`${LOGIN_ROUTE}?reset=true`);
      } catch (error: any) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          setErrorMessage(error.response.data.message ?? error.response.data.error);
          return setCredentialsError(true);
        } else if (error.response && error.response.status === 422) {
          setErrorMessage(error.response.data.message ?? error.response.data.error);

          return setCredentialsError(true);
        }

        throw error;
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <AuthLayout
      title="Set password"
      description="Create a secure password for your Zelt account"
      submit={formik.handleSubmit}
    >
      <FormikProvider value={formik}>
        {(credentialsError || errorMessage) && (
          <Alert severity="error">{errorMessage ? errorMessage : 'Invalid credentials'}</Alert>
        )}
        <TextfieldComponent
          label="New password"
          name="confirmPassword"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={'Your new password must contain at least 12 characters'}
          autoComplete="new-password"
          autoFocus
        />
        <Box sx={spacing.mt40}>
          <LoaderButton
            type="submit"
            name="Continue"
            fullWidth
            loading={isLoading}
            sizeVariant="large"
            colorVariant="primary"
          />
        </Box>
        <Box sx={{ display: 'flex', gap: spacing.sm, alignItems: 'center', mt: spacing.s3 }}>
          <Typography variant="caption">or</Typography>
          <Link to={LOGIN_ROUTE} style={{ ...themeFonts.caption, color: themeColors.ZeltYellow }}>
            sign in
          </Link>
        </Box>
      </FormikProvider>
    </AuthLayout>
  );
};
