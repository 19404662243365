import { useCallback, useMemo, useState } from 'react';

import { Stack, SwipeableDrawer } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { GlobalPayrollPayCodeEditDrawer } from '@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes-edit-drawer.component';
import { GlobalPayrollPayCodeNewDrawer } from '@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes-new-drawer.component';
import { GlobalPayrollPayCodesTable } from '@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes-table.component';
import { GlobalPayrollEndpoints } from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly payroll: CompanyPayroll;
}

export const GlobalPayrollPayCodes = ({ payroll }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [searchQuery, setSearchQuery] = useState('');
  const [editingCode, setEditingCode] = useState<string | null>(null);

  const { data: payrollPaycodes, mutate: refreshPaycodes } = useApiClient(
    GlobalPayrollEndpoints.getPayrollPaycodes(payroll.id),
    {
      suspense: false,
    }
  );

  const sortedPaycodes = useMemo(() => {
    return payrollPaycodes?.paycodes.sort(
      (a, b) => Number(b.required) - Number(a.required) || a.code.localeCompare(b.code)
    );
  }, [payrollPaycodes?.paycodes]);

  const filteredPayCodes = useMemo(() => {
    if (!sortedPaycodes) return [];

    const locale = polyglot.locale();
    const lowerSearchQuery = searchQuery.trim().toLocaleLowerCase(locale);
    if (!lowerSearchQuery) {
      return sortedPaycodes;
    }
    return sortedPaycodes.filter(
      (p) =>
        p.code.toLocaleLowerCase(locale).includes(lowerSearchQuery) ||
        p.name.toLocaleLowerCase(locale).includes(lowerSearchQuery)
    );
  }, [polyglot, searchQuery, sortedPaycodes]);

  const closePaycodeDrawerAndRefresh = useCallback(() => {
    setEditingCode(null);
    refreshPaycodes?.();
  }, [refreshPaycodes]);

  return (
    <Stack sx={{ mr: spacing.mr20, mt: spacing.mt20 }}>
      <PayrollSettingSectionHeader>Pay codes</PayrollSettingSectionHeader>
      <Stack
        sx={{
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: spacing.g10,
          mb: spacing.g20,
        }}
      >
        <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
        <ButtonComponent
          sizeVariant="small"
          colorVariant="primary"
          onClick={() => setEditingCode('new')}
          style={{ width: 'fit-content' }}
        >
          {polyglot.t('PayrollPaycodeSettings.newCode')}
        </ButtonComponent>
      </Stack>
      <GlobalPayrollPayCodesTable
        payCodes={filteredPayCodes}
        onEditPayCodeClick={(paycode) => setEditingCode(paycode.code)}
      />
      <SwipeableDrawer anchor="right" open={!!editingCode} onClose={() => setEditingCode(null)} onOpen={() => {}}>
        <Stack sx={{ width: '400px', padding: '20px', ...drawerContentSx }}>
          {editingCode && editingCode !== 'new' && (
            <GlobalPayrollPayCodeEditDrawer
              payrollId={payroll.id}
              code={editingCode}
              onPaycodeDeleted={() => closePaycodeDrawerAndRefresh()}
              onPaycodeSaved={() => closePaycodeDrawerAndRefresh()}
            />
          )}
          {editingCode && editingCode === 'new' && (
            <GlobalPayrollPayCodeNewDrawer
              payrollId={payroll.id}
              onPaycodeSaved={() => closePaycodeDrawerAndRefresh()}
            />
          )}
        </Stack>
      </SwipeableDrawer>
    </Stack>
  );
};
