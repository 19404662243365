import { Radio, RadioProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeColors } from '@/v2/styles/colors.styles';

export const StyledRadio = styled((props: RadioProps) => (
  <Radio size="small" disableRipple disableFocusRipple disableTouchRipple {...props} />
))(() => ({
  '&.MuiRadio-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="RadioButtonUncheckedIcon"]': {
      color: themeColors.Grey,
    },
    '&.Mui-disabled': {
      '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="RadioButtonUncheckedIcon"]': {
        opacity: 0.4,
      },
    },
  },
  '&.MuiRadio-root.Mui-checked': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonUncheckedIcon"]': {
      color: themeColors.DarkGrey,
    },
    '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonCheckedIcon"]': {
      color: themeColors.DarkGrey,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&.Mui-disabled': {
      '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonUncheckedIcon"]': {
        opacity: 0.4,
      },
      '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonCheckedIcon"]': {
        opacity: 0.4,
      },
    },
  },
}));

export const StyledRadioStyle = styled('input')(() => ({
  display: 'inline-block',
  padding: '0px !important',
  margin: '0px !important',
  width: '12px',
  height: '12px',
  visibility: 'hidden',
  position: 'absolute',
}));
