import React, { useState } from 'react';

import { Stack } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_REQUEST_FORMS_EDIT_ROUTE, SETTINGS_REQUEST_FORMS_NEW_ROUTE } from '@/lib/routes';
import {
  ActionSelectionItemDeleteButton,
  ActionSelectionItemEditButton,
} from '@/v2/components/action-selection-group.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoadingSpinner } from '@/v2/components/loader.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { RequestFormTemplate } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsAPI, RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { UpgradeToProModal, PlanNames } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type RequestTemplateBoxProps = {
  template: RequestFormTemplate;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

const RequestTemplateBox = ({ template, onEditClick, onDeleteClick }: RequestTemplateBoxProps) => {
  return (
    <Stack
      sx={{
        borderRadius: radius.br15,
        p: spacing.pad20,
        gap: spacing.g5,
        bgcolor: themeColors.Background,
        minHeight: '50px',
      }}
    >
      <Stack sx={{ flexFlow: 'row' }}>
        <Stack gap={spacing.g10}>
          <Typography variant="title4">{template.name}</Typography>
          <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
            {template.description}
          </Typography>
        </Stack>
        <Stack sx={{ flexFlow: 'row', ml: 'auto', gap: spacing.g5 }}>
          <ActionSelectionItemEditButton onClick={() => onEditClick()} />
          <ActionSelectionItemDeleteButton onClick={() => onDeleteClick()} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const RequestFormsPage = () => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [checkingRequestCreation, setCheckingRequestCreation] = useState<boolean>(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const { data: templates, mutate: refreshForms, error } = useApiClient(RequestFormsEndpoints.getFormTemplates(), {
    suspense: false,
  });

  const canRequestBeCreatedThenForwardOrDeny = async () => {
    try {
      setCheckingRequestCreation(true);
      // check if request can be created
      await RequestFormsAPI.canRequestBeCreatedOnPlan();
      routerHistory.push(SETTINGS_REQUEST_FORMS_NEW_ROUTE);
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    } finally {
      setCheckingRequestCreation(false);
    }
    return true;
  };

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('RequestFormsPage.requestForms')}</Typography>}
        showAction={true}
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() => canRequestBeCreatedThenForwardOrDeny()}
            loading={checkingRequestCreation}
          >
            {polyglot.t('RequestFormsPage.new')}
          </ButtonComponent>
        }
      />{' '}
      <ContentWrapper>
        <Stack sx={{ flex: 1, gap: spacing.g20, maxWidth: '744px' }}>
          <Stack sx={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between' }}></Stack>
          <Stack sx={{ flex: 1, flexBasis: 0 }}>
            <Stack sx={{ gap: spacing.g10 }}>
              {templates === undefined && <LoadingSpinner />}
              {error && <Typography variant="caption">{error.data?.message}</Typography>}
              {templates?.length === 0 && (
                <Typography variant="caption">{polyglot.t('RequestFormsPage.noFormToShow')}</Typography>
              )}
              {templates?.map((template) => (
                <RequestTemplateBox
                  key={template.id}
                  template={template}
                  onEditClick={() =>
                    routerHistory.push(generatePath(SETTINGS_REQUEST_FORMS_EDIT_ROUTE, { formId: template.id }))
                  }
                  onDeleteClick={async () => {
                    await RequestFormsAPI.deleteFormTemplate(template.id);
                    refreshForms(templates.filter((t) => t.id !== template.id));
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.PEOPLE_PRO}
          messageSuffix="proGeneric"
        />
      </ContentWrapper>
    </RootStyle>
  );
};
