import { forwardRef, useEffect, useMemo, useState } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'dayjs/locale/en-gb';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import utc from 'dayjs/plugin/utc';
import Polyglot from 'node-polyglot';

import { ReactComponent as BackBlue } from '@/images/calendar/BackCalendar.svg';
import { ReactComponent as NextBlue } from '@/images/calendar/NextCalendar.svg';
import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { ReactComponent as CalendarPicker } from '@/images/fields/Calendar.svg';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

dayjs.extend(utc);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.locale('en-gb');

const SHORT_DATE_FORMAT = 'YYYY-MM-DD';

export interface DatePickerComponentProps {
  readonly name: string;
  readonly value?: Dayjs | Date | string | null;
  readonly onChange: (value: string) => void;
  readonly error?: boolean;
  readonly helperText?: string | boolean;
  readonly label?: string;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly inputFormat?: string;
  readonly hiddenLabel?: boolean;
  readonly views?: CalendarPickerView[];
  readonly minDate?: Dayjs | Date | null | string | undefined;
  readonly maxDate?: Dayjs | Date | null | string | undefined;
  readonly disableMaskedInput?: boolean;
  readonly required?: boolean;
  readonly disableFuture?: boolean;
  readonly disablePast?: boolean;
  readonly shortcuts?: boolean;
  readonly readOnly?: boolean;
  readonly sx?: SxProps<Theme>;
  readonly returnNullOnChange?: boolean;
  readonly shortcutLabel?: string;
  readonly shortcutLabelSx?: SxProps<Theme>;
  readonly shouldDisableDate?: (date: Dayjs | Date | string | null) => boolean;
}

const getDayName = (day: string): string => {
  switch (day) {
    case 'Su':
      return 'Sun';
    case 'Mo':
      return 'Mon';
    case 'Tu':
      return 'Tue';
    case 'We':
      return 'Wed';
    case 'Th':
      return 'Thu';
    case 'Fr':
      return 'Fri';
    case 'Sa':
      return 'Sat';
    default:
      return '';
  }
};

const getDateTabFilter = (polyglot: Polyglot) => {
  return [
    { name: polyglot.t('getDateTabFilter.today'), value: 'today' },
    { name: polyglot.t('getDateTabFilter.tomorrow'), value: 'tomorrow' },
    { name: polyglot.t('getDateTabFilter.oneWeek'), value: 'one-week' },
  ];
};
export const DatePickerComponent = forwardRef<HTMLDivElement, DatePickerComponentProps>(
  (
    {
      name,
      label,
      value,
      onChange,
      error,
      placeholder,
      helperText,
      inputFormat = 'DD/MM/YYYY',
      hiddenLabel,
      views,
      minDate = new Date('01/01/2000'),
      maxDate,
      disableFuture = false,
      disablePast = false,
      disableMaskedInput = false,
      shouldDisableDate = undefined,
      required = false,
      disabled,
      shortcuts = false,
      readOnly = false,
      sx,
      returnNullOnChange = false,
      shortcutLabel,
      shortcutLabelSx = { ...themeFonts.captionSmall, color: themeColors.DarkGrey },
    },
    ref
  ) => {
    const { polyglot } = usePolyglot();

    const [filterValue, setFilterValue] = useState<string>('');
    const dateValue = useMemo(
      () =>
        value === null
          ? null // null dateValue displays an empty date input
          : dayjs(value),
      [value]
    );

    useEffect(() => {
      if (dayjs(dateValue).isToday()) return setFilterValue('today');
      if (dayjs(dateValue).isTomorrow()) return setFilterValue('tomorrow');
      if (dayjs(dateValue).isSame(dayjs().add(7, 'day'), 'date')) {
        setFilterValue('one-week');
      } else setFilterValue('');
    }, [dateValue]);

    return (
      <Box sx={sx}>
        {shortcuts && (
          <Box sx={{ ...spacing.mb20, display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
            {shortcutLabel && <Typography sx={{ ...shortcutLabelSx }}>{shortcutLabel}</Typography>}

            <TabFilterButtons
              disabled={disabled}
              filters={getDateTabFilter(polyglot)}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              onFilterChange={({ filterValue }) => {
                if (filterValue === 'today') {
                  const dateValue = dayjs().format(SHORT_DATE_FORMAT);
                  onChange(dateValue);
                }
                if (filterValue === 'tomorrow') {
                  const dateValue = dayjs().add(1, 'day').format(SHORT_DATE_FORMAT);
                  onChange(dateValue);
                }
                if (filterValue === 'one-week') {
                  const dateValue = dayjs().add(7, 'day').format(SHORT_DATE_FORMAT);
                  onChange(dateValue);
                }
              }}
            />
          </Box>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <DatePicker
            ref={ref}
            disableMaskedInput={disableMaskedInput}
            views={views}
            inputFormat={inputFormat}
            value={dateValue}
            showDaysOutsideCurrentMonth
            desktopModeMediaQuery="@media (pointer: fine)"
            minDate={minDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
            shouldDisableDate={shouldDisableDate}
            maxDate={maxDate}
            disabled={disabled}
            PaperProps={{
              sx: {
                width: '100%',
                borderRadius: radius.br15,
                boxShadow: '0px 2px 20px 0px #0D0D0E1A',
                minWidth: '340px',
                padding: '0px',
                margin: '0px',
                '&.Mui-selected': {
                  backgroundColor: themeColors.DarkGrey,
                  color: themeColors.Background,
                },
                '& .MuiTypography-caption': {
                  width: '32px',
                  height: '18px',
                  ...themeFonts.title4,
                  color: themeColors.Grey,
                },
                '& .PrivatePickersSlideTransition-root': {
                  justifyContent: 'center',
                  '& > div': {
                    justifyContent: 'space-around',
                    padding: '0px 15px',
                  },
                },
                '& .PrivatePickersSlideTransition-root [role="row"]': {
                  justifyContent: 'space-around',
                  '& > div': {
                    justifyContent: 'space-around',
                  },
                },
                '& .MuiCalendarPicker-viewTransitionContainer': {
                  '& > div': {
                    justifyContent: 'space-around',
                  },
                },
                '& .PrivatePickersFadeTransitionGroup-root': {
                  '& > div > div > div': {
                    justifyContent: 'space-around',
                    padding: '0px 15px',
                  },
                },
                '& .PrivatePickersYear-root': {
                  ...themeFonts.title4,
                  color: themeColors.DarkGrey,
                  flexBasis: '0%',
                  flexWrap: '100%', //important
                  '& .Mui-selected': {
                    backgroundColor: `${themeColors.DarkGrey} !important`, //important,
                    color: themeColors.Background,
                  },
                },
                '& .MuiMonthPicker-root': {
                  ...themeFonts.title4,
                  color: themeColors.DarkGrey,
                  '& .Mui-selected': {
                    backgroundColor: `${themeColors.DarkGrey} !important`, //important
                    color: themeColors.Background,
                  },
                },
                '& button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin:hover': {
                  backgroundColor: themeColors.white,
                  color: themeColors.Grey,
                },
                '& button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today': {
                  border: 'none !important',
                  backgroundColor: themeColors.Background,
                  color: themeColors.DarkGrey,
                },
                '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button': {
                  backgroundColor: themeColors.white,
                  color: themeColors.DarkGrey,
                  fill: themeColors.DarkGrey,
                  '&:hover': {
                    backgroundColor: themeColors.white,
                    color: themeColors.Grey,
                    fill: themeColors.Grey,
                  },
                  '&:after': {
                    backgroundColor: themeColors.white,
                    color: themeColors.Grey,
                    fill: themeColors.Grey,
                  },
                },

                '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button': {
                  backgroundColor: themeColors.white,
                  color: themeColors.DarkGrey,
                  fill: themeColors.DarkGrey,
                  '&:hover': {
                    backgroundColor: themeColors.white,
                    color: themeColors.Grey,
                    fill: themeColors.Grey,
                  },
                  '&:after': {
                    backgroundColor: themeColors.white,
                    color: themeColors.Grey,
                    fill: themeColors.Grey,
                  },
                },
                '& div.PrivatePickersFadeTransitionGroup-root': {
                  ...themeFonts.title4,
                  color: themeColors.DarkGrey,
                },
              },
            }}
            dayOfWeekFormatter={(day) => {
              return getDayName(day);
            }}
            onChange={(value) => {
              if (shortcuts) {
                onChange(dayjs(value).utc(true).format(SHORT_DATE_FORMAT));
                if (dayjs(value).isToday()) {
                  setFilterValue('today');
                } else if (dayjs(value).isTomorrow()) {
                  setFilterValue('tomorrow');
                } else if (dayjs(value).isSame(dayjs().add(7, 'day'), 'date')) {
                  setFilterValue('one-week');
                } else setFilterValue('');
              } else if (!value && returnNullOnChange) onChange('');
              else if (dayjs(value).isValid()) onChange(dayjs(value).utc(true).format(SHORT_DATE_FORMAT));
            }}
            renderDay={(day, _value, DayComponentProps) => {
              return (
                <PickersDay
                  disableRipple
                  {...DayComponentProps}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: themeColors.DarkGrey,
                      color: themeColors.Background,
                    },
                    '&.MuiPickersDay-root': {
                      marginX: spacing.m4,
                      width: '32px',
                      height: '32px',
                      display: 'flex',
                      ...themeFonts.title4,
                      color: themeColors.DarkGrey,
                      '&.Mui-disabled': {
                        color: themeColors.Grey,
                      },
                      '&.Mui-selected': {
                        backgroundColor: themeColors.DarkGrey,
                        color: themeColors.Background,
                      },
                    },
                  }}
                />
              );
            }}
            components={{
              OpenPickerIcon: CalendarPicker,
              SwitchViewIcon: ArrowDownACIcon,
              LeftArrowIcon: BackBlue,
              RightArrowIcon: NextBlue,
            }}
            renderInput={(params) => (
              <StyledTextfield
                {...params}
                fullWidth
                variant="standard"
                size="small"
                value={dateValue}
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder || (typeof label === 'string' ? label : undefined)}
                name={name}
                required={required}
                hiddenLabel={hiddenLabel}
                label={hiddenLabel ? '' : label}
                error={error}
                helperText={helperText}
                inputProps={{
                  ...params.inputProps,
                  readOnly: readOnly,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    );
  }
);
