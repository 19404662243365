import { Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import { SeriesData } from '@/v2/feature/app-integration/app-integration.interface';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import './apex-charts.styles.css';

export const ChartBoxPlot = ({
  series,
  categories,
  customTooltip,
}: {
  series: Record<string, unknown>[] | number[] | SeriesData[];
  categories: string[];
  customTooltip?: ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: {
    series: any;
    seriesIndex: any;
    dataPointIndex: any;
    w: any;
  }) => string;
}) => {
  const seriesData = [{ data: series }];
  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      align: 'left' as 'left' | 'center' | 'right',
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: '#FFCD6D',
          lower: '#74CF8C',
        },
        stroke: {
          show: true,
          width: 0,
        },
      },
    },
    xaxis: {
      labels: {
        show: categories.length > 0,
        style: {
          colors: Array(categories.length).fill(themeColors.Grey),
          fontFamily: 'Inter, sans-serif !important',
          fontWeight: 400,
          fontSize: '12px',
        },
      },
      min: 0,
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        align: 'center' as 'left' | 'center' | 'right',
        style: {
          fontFamily: 'Inter, sans-serif !important',
          fontWeight: 400,
          fontSize: '12px',
        },
        formatter: function (val: number) {
          return val ? (formatCurrency(val, { wholeNumber: true }) as string) : '';
        },
      },
      legend: {
        show: true,
        horizontalAlign: 'right' as 'left' | 'center' | 'right',
        position: 'top' as 'top' | 'right' | 'bottom' | 'left',
        fontFamily: 'Inter, sans-serif !important',
        fontWeight: 400,
        fontSize: '12px',
        labels: { colors: [themeColors.DarkGrey] },
        markers: {
          width: 10,
          height: 10,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 16,
          customHTML: undefined,
          onClick: undefined,
        },
      },
      stroke: {
        show: true,
        width: 0,
        colors: ['transparent'],
      },
      crosshairs: {
        show: false,
      },
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: (val: number) => `${formatCurrency(val, { wholeNumber: true })}`,
      },
      style: { fontFamily: 'Inter, sans-serif !important', fontWeight: 400, fontSize: '12px' },
      custom: customTooltip
        ? customTooltip
        : function ({ series, seriesIndex, dataPointIndex }: { series: any; seriesIndex: any; dataPointIndex: any }) {
            return `<div class="arrow_box">${series[seriesIndex][dataPointIndex]}</div>`;
          },
    },
  };
  return (
    <ReactApexChart type="boxPlot" series={seriesData as ApexAxisChartSeries} options={chartOptions} height="250px" />
  );
};

export const ChartBoxPlotLoading = () => {
  return (
    <Box sx={{ height: '250px', width: '100%' }}>
      {[1, 2, 3, 4, 5].map(() => (
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="10px"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      ))}
    </Box>
  );
};
