import React, { Fragment, useCallback } from 'react';

import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceSettingsApprovalSection } from '@v2/feature/attendance/settings/sections/attendance-settings-approval.section';
import { AttendanceSettingsGeneralSection } from '@v2/feature/attendance/settings/sections/attendance-settings-general.section';
import { AttendanceSettingsMembersSection } from '@v2/feature/attendance/settings/sections/attendance-settings-members.section';
import { AttendanceSettingsPayrollSection } from '@v2/feature/attendance/settings/sections/attendance-settings-payroll.section';
import { AttendanceSettingsScheduleSection } from '@v2/feature/attendance/settings/sections/attendance-settings-schedule.section';
import { AttendanceSettingsTrackingSection } from '@v2/feature/attendance/settings/sections/attendance-settings-tracking.section';
import { SiteDto } from '@v2/feature/site/site.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { useCompanyConfigState } from '@/hooks/company-config.hook';
import {
  SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE,
} from '@/lib/routes';

export const getPageConfig = (
  scheduleId: number,
  inAnyPayroll: boolean,
  polyglot: Polyglot
): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE, { scheduleId }),
        },
        {
          title: polyglot.t('AttendanceDomain.schedule'),
          stub: 'general',
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, { scheduleId }),
        },
        {
          title: polyglot.t('AttendanceSchedule.tracking'),
          stub: 'general',
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE, { scheduleId }),
        },
        {
          title: polyglot.t('AttendanceSchedule.approval'),
          stub: 'general',
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE, { scheduleId }),
        },
        {
          title: polyglot.t('General.members'),
          stub: 'general',
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE, { scheduleId }),
        },
        {
          title: polyglot.t('AttendanceSchedule.payroll'),
          stub: 'general',
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE, { scheduleId }),
          isHidden: !inAnyPayroll,
        },
      ],
    },
  ] as NavConfigItem[];
};

export const AttendanceScheduleRouter = ({
  refreshSchedules,
  sites,
  approvalRules,
  refreshApprovalRules,
}: {
  readonly refreshSchedules: () => Promise<void>;
  readonly sites: readonly SiteDto[];
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
}): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const params = useParams<{ scheduleId: string }>();
  const scheduleId = Number(params.scheduleId);

  const {
    data: schedule,
    mutate: refreshAttendanceSchedule,
    isLoading,
  } = useApiClient(AttendanceEndpoints.getAttendanceScheduleById(scheduleId), { suspense: false });

  const { companyConfig } = useCompanyConfigState();

  const refreshSchedule = useCallback(async () => {
    if (refreshAttendanceSchedule) await refreshAttendanceSchedule();
  }, [refreshAttendanceSchedule]);

  return (
    <Fragment>
      <DomainSideMenuContent
        title={schedule?.name ?? ''}
        subtitle={polyglot.t('AttendanceDomain.schedule')}
        pageConfig={getPageConfig(scheduleId, companyConfig.inPayroll || companyConfig.inGlobalPayroll, polyglot)}
        backPath={SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper loading={isLoading}>
        {schedule && (
          <Switch>
            <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE}>
              <AttendanceSettingsGeneralSection
                schedule={schedule}
                refreshSchedule={refreshSchedule}
                refreshSchedules={refreshSchedules}
              />
            </RouteScopesControl>

            <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE}>
              <AttendanceSettingsScheduleSection
                schedule={schedule}
                refreshSchedule={refreshSchedule}
                refreshAllSchedules={refreshSchedules}
              />
            </RouteScopesControl>

            <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE}>
              <AttendanceSettingsTrackingSection schedule={schedule} refreshSchedule={refreshSchedule} sites={sites} />
            </RouteScopesControl>

            <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE}>
              <AttendanceSettingsApprovalSection
                schedule={schedule}
                refreshSchedule={refreshSchedule}
                approvalRules={approvalRules}
                refreshApprovalRules={refreshApprovalRules}
              />
            </RouteScopesControl>

            <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE}>
              <AttendanceSettingsMembersSection schedule={schedule} />
            </RouteScopesControl>

            <RouteScopesControl scopes={['attendance:all']} path={SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE}>
              <AttendanceSettingsPayrollSection schedule={schedule} refreshSchedule={refreshSchedule} />
            </RouteScopesControl>

            <Redirect to={SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE} />
          </Switch>
        )}
      </ContentWrapper>
    </Fragment>
  );
};
