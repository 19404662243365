import { useEffect } from 'react';

import { CreateFlowLayout } from '@v2/feature/reports/features/create-report/sections/create-flow-layout.component';

import { useCompanyConfigState } from '@/hooks/company-config.hook';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const CreateReportPage = () => {
  const { companyConfig, isLoading } = useCompanyConfigState();

  const { trackPage } = useJune();

  useEffect(() => {
    trackPage('Create report');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <ContentWrapper
        loading={isLoading}
        noHeader={true}
        border={false}
        sx={{ paddingTop: spacing.p30, ...spacing.px40 }}
      >
        {companyConfig && <CreateFlowLayout companyConfig={companyConfig} />}
      </ContentWrapper>
    </RootStyle>
  );
};
