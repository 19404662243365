import React from 'react';

import { Box, Button } from '@mui/material';

import { ReactComponent as BackBtn } from '@/images/app-icons/BackBtn.svg';
import { Typography } from '@/v2/components/typography/typography.component';
import { UnSanitizedFaq } from '@/v2/feature/strapi-help/strapi.interface';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const FaqComponent = ({
  selectedFaq,
  setSelectedFaq,
}: {
  selectedFaq: UnSanitizedFaq;
  setSelectedFaq: React.Dispatch<React.SetStateAction<UnSanitizedFaq | undefined>>;
}) => {
  return (
    <Box sx={{ mt: spacing.mt30 }}>
      <Button startIcon={<BackBtn />} sx={secondaryCTABtn} onClick={() => setSelectedFaq(undefined)}>
        Back
      </Button>

      <Box sx={spacing.mt20}>
        {selectedFaq.Media && selectedFaq?.Media?.data && selectedFaq?.Media?.data.length > 0 && (
          <Box sx={{ width: '100%' }}>
            <img
              src={`https://strapi.zelt.app${selectedFaq?.Media?.data[0].attributes.url}`}
              alt="faq-img"
              width="100%"
            />
          </Box>
        )}
        <Typography variant="title2">{selectedFaq.Question}</Typography>
        {selectedFaq.Video && selectedFaq.Video.url && (
          <Box sx={{ width: '100%', mt: spacing.mt20 }}>
            <iframe
              width="560"
              height="315"
              src={selectedFaq.Video.url}
              title="YouTube video player"
              style={{ border: 0 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Box>
        )}
        <Typography variant="caption" sx={{ marginTop: spacing.m20, whiteSpace: 'pre-line' }}>
          {selectedFaq.Answer}
        </Typography>
      </Box>
    </Box>
  );
};
