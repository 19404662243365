import { useContext } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { AppIntegrationDetailedCompanyRouter } from '../../../app-integration-detailed-company.router';
import { AppStorePage } from '../../../app-store.page';
import { AppScopes } from '../../../app.scopes';
import { AppsPage } from '../../../apps.page';
import { AppRequestPage } from '../../app-request/app-request.page';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  APP_COMPANY_STORE_ROUTE,
  APP_INTEGRATION_DETAILS_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APPS_COMPANY_OVERVIEW_ROUTE,
  APPS_COMPANY_ROUTE,
  APPS_REQUESTS_ROUTE,
} from '@/lib/routes';
export const AppCompanyRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  return (
    <Switch>
      <RouteScopesControl
        scopes={AppScopes.VIEW_APP_STORE}
        context={scopesContext}
        path={APP_COMPANY_STORE_ROUTE}
        exact
      >
        <AppStorePage />
      </RouteScopesControl>
      <RouteScopesControl
        scopes={AppScopes.VIEW_APP_REQUESTS}
        context={scopesContext}
        path={[APPS_REQUESTS_ROUTE]}
        component={AppRequestPage}
        exact
      />
      <Route path={APPS_COMPANY_OVERVIEW_ROUTE} exact>
        <AppsPage />
      </Route>

      <RouteScopesControl
        scopes={AppScopes.VIEW_COMPANY_APPS}
        context={scopesContext}
        path={[APP_INTEGRATION_DETAILS_ROUTE, APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE]}
      >
        <AppIntegrationDetailedCompanyRouter />
      </RouteScopesControl>

      <Route path={APPS_COMPANY_ROUTE}>
        <Redirect to={APPS_COMPANY_OVERVIEW_ROUTE} />
      </Route>
    </Switch>
  );
};
