import { useCallback, useContext, useMemo } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as Back } from '@/images/fields/Left.svg';
import '@/v2/components/domain-side-menu-content.scss';
import { DomainSideMenu } from '@/v2/components/domain-side-menu.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  NavConfigItem,
  SubItemsProps,
  SubMenuProps,
} from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { renderSingleItem, renderSubMenu } from '@/v2/feature/app-layout/features/v2/second-menu/second-menu.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { showCustomDomainSidebar, showDomainSideBar } from '@/v2/util/app-layout.util';

function decomposePath(path: string) {
  const parts = path.split('/');
  return {
    domain: parts[1] || '',
    subDomain: parts[2] || '',
    subMenu: parts[3] || '',
  };
}

interface DomainSideMenuContentProps {
  readonly title?: string;
  readonly customTitle?: JSX.Element;
  readonly subtitle?: string;
  readonly pageConfig: readonly NavConfigItem[];
  readonly showBack?: boolean;
  readonly backPath?: string;
  readonly type?: 'Domain' | 'Custom';
}

export const DomainSideMenuContent = ({
  title,
  customTitle,
  subtitle,
  pageConfig,
  showBack,
  backPath,
  type = 'Domain',
}: DomainSideMenuContentProps) => {
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();

  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTabletScreen = useMediaQuery(
    `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { pathname } = useLocation();
  const { subDomain } = useMemo(() => decomposePath(pathname), [pathname]);

  const handleAction = useCallback(
    (list: SubItemsProps) => {
      if (list.path) routerHistory.push(list.path);
    },
    [routerHistory]
  );

  const handleParentClick = useCallback(
    (list: SubItemsProps) => {
      const firstChild = list.subMenu?.find((s) => !s.isHidden && s.path);
      if (firstChild && firstChild.path) {
        routerHistory.push(firstChild.path);
      }
    },
    [routerHistory]
  );

  const handleSubmenuClick = useCallback(
    (sm: SubMenuProps) => {
      if (!sm.hasChildren && sm.path) {
        routerHistory.push(sm.path);
      }
    },
    [routerHistory]
  );

  const shouldRenderEmpty = useMemo(() => {
    return (
      (type === 'Domain' &&
        ((showDomainSideBar(pathname) &&
          (isTabletScreen || isMobileScreen) &&
          !state.showSettingBar &&
          !isDesktopScreen) ||
          !showDomainSideBar(pathname))) ||
      (type === 'Custom' &&
        ((showCustomDomainSidebar(pathname) &&
          (isTabletScreen || isMobileScreen) &&
          !state.showSettingBar &&
          !isDesktopScreen) ||
          !showCustomDomainSidebar(pathname)))
    );
  }, [pathname, isTabletScreen, isMobileScreen, isDesktopScreen, state.showSettingBar, type]);

  const renderContent = useMemo(
    () => (
      <Box
        sx={{
          background: themeColors.white,
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.s1,
            width: '100%',
            paddingTop: spacing.s2,
            paddingBottom: spacing.m25,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              gap: spacing.g5,
              boxSizing: 'border-box',
            }}
          >
            {showBack && (
              <IconButton
                sizeVariant="small"
                colorVariant="secondary"
                onClick={async () => {
                  if (backPath) routerHistory.push(backPath);
                  else routerHistory.goBack();
                }}
              >
                <Back {...iconSize} />
              </IconButton>
            )}
            {title && (
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, mx: spacing.g5 }}>
                <Typography
                  variant="title2"
                  sx={{
                    maxWidth: '125px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {title}
                </Typography>
                {subtitle && (
                  <Typography
                    variant={title.length > 20 && subtitle.length <= 20 ? 'caption' : 'captionSmall'}
                    sx={{ maxWidth: '125px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Box>
            )}
            {!title && <Box sx={{ flexGrow: 1 }} />}
            {(isTabletScreen || isMobileScreen) && (
              <IconButton
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => {
                  dispatch({ type: GlobalStateActions.UPDATE_SHOW_SETTING_BAR, payload: false });
                }}
              >
                <Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
              </IconButton>
            )}
          </Box>

          {customTitle}

          <Box sx={{ width: '100%', ...spacing.mt20, display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
            {pageConfig?.map((list) => {
              if (list.isHidden) return null;
              return (
                <Box key={list.stub} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="title4" sx={{ padding: '0px 10px' }}>
                    {list.title}
                  </Typography>
                  <>
                    {list.hasChildren &&
                      !list.isHidden &&
                      list.subItems?.map((l, i) =>
                        l.hasChildren
                          ? renderSubMenu(l, handleParentClick, pathname, subDomain, handleSubmenuClick, i)
                          : renderSingleItem(l, handleAction, pathname, i)
                      )}
                  </>
                </Box>
              );
            })}
          </Box>
        </div>
      </Box>
    ),
    [
      showBack,
      title,
      subtitle,
      pageConfig,
      backPath,
      routerHistory,
      handleParentClick,
      pathname,
      subDomain,
      handleSubmenuClick,
      handleAction,
      isMobileScreen,
      isTabletScreen,
      dispatch,
      customTitle,
    ]
  );

  if (shouldRenderEmpty) {
    return <></>;
  }

  return <DomainSideMenu>{renderContent}</DomainSideMenu>;
};
