import React from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SectionModalContent } from '@/v2/feature/growth/surveys/features/components/section-modal-content.component';
import { SurveySection } from '@/v2/feature/growth/surveys/interfaces/survey-section.interface';

export const SurveyCycleCreationSectionFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  section,
  refresh,
  cycleId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  section: SurveySection | null;
  refresh: () => Promise<void>;
  cycleId: string;
}) => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <SectionModalContent cycleId={cycleId} onClose={onClose} section={section} refresh={refresh} />
    </DrawerModal>
  );
};
