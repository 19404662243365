import { useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import type { EmergencyContactValues } from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { sortCustomFields } from '@/v2/feature/custom-fields/custom-profile-fields.util';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import {
  FieldStructure,
  ProfileCard,
} from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { MissingInformationLabel } from '@/v2/feature/user/features/user-profile/details/components/missing-information-label.component';
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import {
  EmergencyContactForm,
  EmergencyContactSchema,
} from '@/v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-form.component';
import { UserAPI } from '@/v2/feature/user/user.api';
import { MissingField } from '@/v2/feature/user/user.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { hazardMissingField, tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SCOPE = 'user.emergencyContact' as const;
const iconSize = { width: 14, height: 14 } as const;
const skeletonHeight = calculateSkeletonHeight(EmergencyContactSchema);

interface Props {
  readonly userId: number;
  readonly onSubmit: (_: EmergencyContactValues) => void;
  readonly missingFields?: MissingField[] | null;
}

export const EmergencyContactCard = ({ userId, onSubmit, missingFields }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();

  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const [dataO, setDataO] = useState<O.Option<EmergencyContactValues>>(() => O.none);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmpty, setEmpty] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await UserAPI.getUserEmergencyContact(userId);
        const emptyArray = (Object.keys(data) as Array<keyof typeof data>).filter(
          (key) => data[key] === undefined || data[key] === null || data[key] === ''
        );
        setEmpty(emptyArray.length === Object.keys(data).length);
        setDataO(O.some(data));
      } catch (error) {
        showMessage(polyglot.t('EmergencyContactCard.errorMessages.load'), 'error');
      }
    })();
  }, [polyglot, showMessage, userId, isOpen]);

  return pipe(
    dataO,
    O.fold(
      () => (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      ),
      (data) => (
        <Box>
          <ProfileCard
            customUpdates={data.customUpdates}
            fieldStubs={['user.emergencyName', 'user.emergencyNumber', 'user.emergencyRelationship']}
            sx={cardSx}
          >
            <Box component="header" sx={{ ...cardHeaderSx }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                <Typography variant="h3" sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
                  {polyglot.t('EmergencyContactCard.emergency')}
                </Typography>
                <ScopesControl scopes={['user:all', 'user']} context={scopesContext}>
                  {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.Emergency) && (
                    <Box sx={hazardMissingField}>
                      <Hazard {...hazardSize} />
                    </Box>
                  )}
                </ScopesControl>
              </Box>
              <ScopesControl scopes={[SCOPE]} context={scopesContext}>
                <IconButton
                  onClick={() => setIsOpen(true)}
                  title={polyglot.t('EmergencyContactCard.edit')}
                  sx={tableIconButtonSx}
                >
                  {isEmpty ? <Plus {...iconSize} /> : <Edit {...iconSize} />}
                </IconButton>
              </ScopesControl>
              <ScopesControl scopes={[SCOPE]} context={scopesContext}>
                <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
                  <EmergencyContactForm
                    userId={userId}
                    initialValues={data}
                    onSubmit={(values) => {
                      setDataO(O.some(values));
                      setIsOpen(false);
                      onSubmit(values);
                    }}
                    onClose={() => setIsOpen(false)}
                  />
                </DrawerModal>
              </ScopesControl>
            </Box>
            <Box component="section">
              {isEmpty ? (
                <MissingInformationLabel />
              ) : (
                <Box component="dl" sx={definitionListSx}>
                  <FieldStructure
                    fieldTitle={polyglot.t('EmergencyContactCard.fullName')}
                    fieldValue={data.emergencyName}
                    fieldStub="user.emergencyName"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('EmergencyContactCard.phone')}
                    fieldValue={data.emergencyNumber}
                    fieldStub="user.emergencyNumber"
                  />
                  <FieldStructure
                    fieldTitle={polyglot.t('EmergencyContactCard.relationship')}
                    fieldValue={data.emergencyRelationship}
                    fieldStub="user.emergencyRelationship"
                  />
                  {sortCustomFields(data.customUpdates ?? []).map(
                    (f) =>
                      !f.field.isHidden && (
                        <FieldStructure key={f.field.fieldId} fieldTitle={f.field.fieldName} fieldValue={f.value} />
                      )
                  )}
                </Box>
              )}
            </Box>
          </ProfileCard>
        </Box>
      )
    )
  );
};
