import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { BidirectionalButton } from '@v2/components/bidirectional-button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { AttendanceAPI } from '@/v2/feature/attendance/attendance.api';
import {
  PersonalWeeklyEntriesTableAndHeaderData,
  UserAttendanceWeekList,
} from '@/v2/feature/attendance/attendance.dto';
import { AttendanceStatus } from '@/v2/feature/attendance/attendance.interface';
import { AttendanceMeContent } from '@/v2/feature/attendance/me/components/attendance-me-content.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';

export const AttendanceMeView = () => {
  const { polyglot } = usePolyglot();
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const [attendanceWeekList, setAttendanceWeekList] = useState<UserAttendanceWeekList[] | undefined>(undefined);
  const [selectedWeekAndYear, setSelectedWeekAndYear] = useState<string | undefined>(undefined);
  const [weeklyEntries, setWeeklyEntries] = useState<PersonalWeeklyEntriesTableAndHeaderData | undefined>(undefined);
  const [searchInput, setSearchInput] = useState('');
  const [showMessage] = useMessage();

  const getUserAttendances = useCallback(async () => {
    setLoading(true);
    setUserLoading(true);
    setSelectedWeekAndYear(undefined);
    setAttendanceWeekList(undefined);
    setWeeklyEntries(undefined);
    try {
      const requestsResponse = await AttendanceAPI.getWeekListByUserId(state.user.userId, year, searchInput);
      setTotalItems(requestsResponse.totalItems);
      setAttendanceWeekList(requestsResponse.items);
      if (!requestsResponse?.items?.length) return;

      const weeklyEntriesResponse = await AttendanceAPI.getPersonalAttendanceEntriesByWeekNo(
        state.user.userId,
        requestsResponse?.items[0].weekNo,
        requestsResponse?.items[0].year
      );
      setSelectedWeekAndYear(`${requestsResponse?.items[0].weekNo}/${requestsResponse?.items[0].year}`);
      setWeeklyEntries(weeklyEntriesResponse);
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotFetchUserAttendances', { nestError: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
      setUserLoading(false);
    }
  }, [polyglot, searchInput, showMessage, state.user.userId, year]);

  useEffect(() => {
    getUserAttendances();
  }, [getUserAttendances]);

  const weekList = useMemo(() => {
    return attendanceWeekList
      ? attendanceWeekList.map((n) => {
          return {
            id: `${n.weekNo}/${n.year}`,
            name: polyglot.t('UserAttendancePage.weekNum', { weekNum: n.weekNo }),
            details:
              Number(n.noOfTimeSheets) > 0
                ? polyglot.t('AttendanceDomain.noOfTimesheets', { smart_count: n.noOfTimeSheets })
                : polyglot.t('AttendanceDomain.noTimesheets'),
            showAlert: n.status === AttendanceStatus.Submitted,
            alertColor: n.status === AttendanceStatus.Submitted ? themeColors.Grey : 'none',
            highlightTitle: n.status === AttendanceStatus.Submitted,
          };
        })
      : [];
  }, [polyglot, attendanceWeekList]);

  const getWeeklyDataByWeekNo = async (weekAndYear: string) => {
    setUserLoading(true);
    try {
      setSelectedWeekAndYear(weekAndYear);
      const weekAndYearArray = weekAndYear.split('/');
      const weeklyEntriesResponse = await AttendanceAPI.getPersonalAttendanceEntriesByWeekNo(
        state.user.userId,
        Number(weekAndYearArray[0]),
        Number(weekAndYearArray[1])
      );
      setWeeklyEntries(weeklyEntriesResponse);
    } catch (error) {
      showMessage(
        polyglot.t('AttendanceDomain.errors.couldNotFetchUserAttendanceDetails', {
          nestError: nestErrorMessage(error),
        }),
        'error'
      );
      setSelectedWeekAndYear(undefined);
    } finally {
      setUserLoading(false);
    }
  };

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('AttendanceDomain.Attendance')}</Typography>}
        actions={
          <BidirectionalButton
            navigateBack={() => setYear((prev) => prev - 1)}
            navigateNext={() => setYear((prev) => prev + 1)}
            buttonTitle={String(year)}
            disableNext={year === new Date().getFullYear()}
          />
        }
        showAction
      />

      <ContentWrapper loading={false} sx={{ pt: 0 }}>
        <AttendanceMeContent
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          weekList={weekList}
          refreshWeekList={getUserAttendances}
          loading={loading}
          selectedWeekAndYear={selectedWeekAndYear}
          weeklyEntries={weeklyEntries}
          // view={view} // this is always 'user'
          userLoading={userLoading}
          refresh={getWeeklyDataByWeekNo}
          userId={state.user.userId}
          totalItems={totalItems}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
