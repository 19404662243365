import React from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AutoLaunchedApplicationDictionaryMangedItem, LoginItemsOptions } from '@v2/feature/device/device.dto';
import { spacing } from '@v2/styles/spacing.styles';

export const LoginItemPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  configurationPayloadOption,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  configurationPayloadOption?: LoginItemsOptions | null;
}) => {
  const renderList = (items: AutoLaunchedApplicationDictionaryMangedItem[]) => (
    <Box
      sx={{
        padding: '16px',
        gap: '16px',
        borderRadius: '8px',
        border: '1px solid #F1EEEA',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="title4">Open at login policy</Typography>
      {items.length > 0 ? (
        items.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography variant="caption">{item.Path}</Typography>
          </Box>
        ))
      ) : (
        <Typography variant="caption">{'No applications'}</Typography>
      )}
    </Box>
  );

  return (
    <DrawerModal setIsOpen={setIsOpen} isOpen={isOpen} onClose={onClose}>
      <Box>
        <Typography variant="title2">{'Login items'}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: spacing.m20, padding: 0, gap: spacing.g15 }}>
          {renderList(configurationPayloadOption?.autoLaunchedApplicationDictionaryManaged || [])}
        </Box>
      </Box>
    </DrawerModal>
  );
};
