import { useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ListView } from '@/images/new-theme-icon/ListView.svg';
import { ReactComponent as OrgChartView } from '@/images/new-theme-icon/OrgChartView.svg';
import { SwitchButton } from '@/v2/components/split-switch-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { PeopleListView } from '@/v2/feature/user/pages/components/people-list-view.component';
import { PeopleOrgView } from '@/v2/feature/user/pages/components/people-org-view.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { RootStyle } from '@/v2/styles/root.styles';

const ViewSwitcher = ({
  setShowMode,
  showMode,
}: {
  setShowMode: React.Dispatch<React.SetStateAction<'list' | 'org'>>;
  showMode: 'list' | 'org';
}) => {
  return (
    <Box
      sx={{
        minWidth: '60px',
        height: '30px',
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SwitchButton isActive={showMode === 'list'} color={themeColors.DarkGrey} action={() => setShowMode('list')}>
        <ListView {...iconSize} fill={showMode === 'list' ? themeColors.white : themeColors.DarkGrey} />
      </SwitchButton>

      <SwitchButton isActive={showMode === 'org'} color={themeColors.DarkGrey} action={() => setShowMode('org')}>
        <OrgChartView {...iconSize} fill={showMode === 'org' ? themeColors.white : themeColors.DarkGrey} />
      </SwitchButton>
    </Box>
  );
};

export function PeopleDirectoryPage() {
  const { polyglot } = usePolyglot();

  const [showMode, setShowMode] = useState<'list' | 'org'>('list');
  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('PeopleDirectoryPage.directory')}</Typography>}
        showAction={true}
        actions={<ViewSwitcher showMode={showMode} setShowMode={setShowMode} />}
      />

      {showMode === 'list' && <PeopleListView />}
      {showMode === 'org' && <PeopleOrgView />}
    </RootStyle>
  );
}
