import React, { useLayoutEffect, useRef, useState } from 'react';

import { Button, IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserAPI, UserEndpoints } from '@/v2/feature/user/user.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type ProfilePictureProps = {
  disabled?: boolean;
  userId: number;
};

export const ProfilePicture = ({ disabled, userId }: ProfilePictureProps) => {
  const { polyglot } = usePolyglot();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [previewImage, setPreviewImage] = useState('');
  const inputKey = useRef(Date.now());

  const { data: src } = useApiClient(UserEndpoints.getUserAvatarURL(userId));

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setPreviewImage(URL.createObjectURL(e.target.files[0]));

    const form = new FormData();
    form.append('file', e.target.files[0]);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', UserAPI.getUserAvatarURL(userId));
    xhr.send(form);
  };

  const clearImage = () => {
    inputKey.current = Date.now();
    setPreviewImage('');
    UserAPI.deleteUserAvatar(userId).catch(() => null); // ignore any failures to delete
  };

  useLayoutEffect(() => {
    if (src) setPreviewImage(src);
  }, [src]);

  return (
    <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
      {previewImage && (
        <Stack sx={{ position: 'relative' }}>
          <Button
            sx={{
              ...secondaryCTABtn,
              height: '60px',
              width: '60px',
              borderRadius: '50%',
              padding: 0,
              margin: 0,
            }}
            onClick={() => inputRef.current?.click()}
            disabled={disabled}
          >
            <img
              src={previewImage}
              alt=""
              style={{
                height: '60px',
                width: '60px',
                border: `1px solid ${themeColors.lightGrey}`,
                borderRadius: '50%',
              }}
            />
          </Button>
          <IconButton
            sx={{
              ...secondaryCTABtn,
              left: -4,
              top: -4,
              height: 16,
              width: 16,
              padding: 0,
              opacity: 0.5,
              position: 'absolute',
            }}
            onClick={() => clearImage()}
            disabled={disabled}
          >
            <Close height={10} width={10} stroke={themeColors.DarkGrey} />
          </IconButton>
        </Stack>
      )}
      {!previewImage && (
        <ButtonComponent
          sizeVariant="small"
          colorVariant="primary"
          style={{
            height: '60px',
            width: '60px',
            borderRadius: '50%',
            padding: 0,
            margin: 0,
          }}
          onClick={() => inputRef.current?.click()}
          disabled={disabled}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 4.5L9 1.5H15L18 4.5H24V21H0V4.5H6ZM9.62132 3H14.3787L17.3787 6H22.5V19.5H1.5V6H6.62132L9.62132 3ZM11.25 6.75H12.75V11.25H17.25V12.75H12.75V17.25H11.25V12.75H6.75V11.25H11.25V6.75Z"
              fill="white"
            />
          </svg>
        </ButtonComponent>
      )}
      <input
        key={inputKey.current}
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        disabled={disabled}
      />
      {!previewImage && !disabled && (
        <Typography
          sx={{
            ...themeFonts.caption,
            color: themeColors.Grey,
          }}
        >
          {polyglot.t('OnboardingByUser.addProfilePicture')}
        </Typography>
      )}
    </Stack>
  );
};
