import { useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledRadioGroup } from '@/v2/components/theme-components/styled-radio-group.component';
import { EntityImportWizardData } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { ImportState } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
import { SelectOption } from '@/v2/infrastructure/forms/forms.interface';
import { spacing } from '@/v2/styles/spacing.styles';

type DataDomainChoiceSectionProps = {
  importState?: ImportState;
  onNext: (domain: EntityImportWizardData) => void;
  sx?: SxProps<Theme>;
};

export const DataDomainChoiceSection = ({ importState, onNext, sx }: DataDomainChoiceSectionProps) => {
  const { polyglot } = usePolyglot();

  const importDataDomainChoices = [
    { label: polyglot.t('importDataDomainChoices.people'), value: EntityImportWizardData.Users },
    { label: polyglot.t('importDataDomainChoices.payroll'), value: EntityImportWizardData.Payroll },
    { label: polyglot.t('importDataDomainChoices.time'), value: EntityImportWizardData.Absences },
    { label: polyglot.t('importDataDomainChoices.timeAdjustments'), value: EntityImportWizardData.AbsenceAdjustments },
    { label: polyglot.t('importDataDomainChoices.devices'), value: EntityImportWizardData.Devices },
    { label: polyglot.t('importDataDomainChoices.attendance'), value: EntityImportWizardData.Attendances },
  ] as SelectOption[];

  const DataDomainChoiceSchema = Yup.object().shape({
    dataDomainChoice: Yup.string()
      .oneOf(importDataDomainChoices.map((o) => o.value) as string[])
      .required(polyglot.t('DataDomainChoiceSchema.errorMessages.pleaseChooseType')),
  });

  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();

  const formik = useFormik({
    initialValues: {
      dataDomainChoice: importState?.domain,
    },
    validateOnMount: true,
    validationSchema: DataDomainChoiceSchema,
    onSubmit: async (values) => {
      if (values.dataDomainChoice) {
        setSaving(true);
        try {
          onNext(values.dataDomainChoice as EntityImportWizardData);
        } catch (error) {
          showMessage(`New user could not be created. ${nestErrorMessage(error)}`, 'error');
          setSaving(false);
        }
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('DataDomainChoiceSection.selectDataType')}</Typography>

          <StyledRadioGroup
            name="dataDomainChoice"
            options={importDataDomainChoices}
            selectedValue={formik.values.dataDomainChoice}
            onChange={formik.handleChange}
          />

          <LoaderButton
            name={polyglot.t('DataDomainChoiceSection.continue')}
            loading={saving}
            colorVariant="primary"
            sizeVariant="large"
            disabled={!formik.isValid}
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
