import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';

interface DrawerViewerItemProps {
  title: string | ReactNode;
  value: React.JSX.Element | string;
}

export const DrawerViewerItem = ({ title, value }: DrawerViewerItemProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {typeof title === 'string' ? (
        <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
          {title}
        </Typography>
      ) : (
        <Box>{title}</Box>
      )}

      {typeof value === 'string' ? (
        <Typography variant="title4" color="DarkGrey">
          {value}
        </Typography>
      ) : (
        <Box>{value}</Box>
      )}
    </Box>
  );
};
