import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { FixedSizeList as List } from 'react-window';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as CleanGrey } from '@/images/fields/CleanGrey.svg';
import { nestErrorMessage } from '@/lib/errors';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UserSelect } from '@/v2/components/user-select-type/user-select.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import {
  EditReviewers,
  ReviewCycle,
  ReviewParticipants,
  ReviewerTypes,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ParticipantsAddReviewerModal = ({
  reviewCycle,
  selectedReviewee,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | undefined;
  selectedReviewee: ReviewParticipants | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ParticipantsAddReviewerContent
        reviewCycle={reviewCycle}
        onClose={onClose}
        refresh={refresh}
        selectedReviewee={selectedReviewee}
      />
    </DrawerModal>
  );
};

const useReviewerSelectForm = (
  selectedReviewee: ReviewParticipants | undefined,
  reviewCycle: ReviewCycle | undefined,
  onClose: () => void,
  refresh: () => Promise<void>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const [showMessage] = useMessage();

  const formik = useFormik<EditReviewers>({
    initialValues: {
      peerIds: [],
      upwardIds: [],
      managerIds: [],
    },
    validationSchema: yup.object({
      peerIds: yup.array().of(yup.number()).required(),
      upwardIds: yup.array().of(yup.number()).required(),
      managerIds: yup.array().of(yup.number()).required(),
    }),
    onSubmit: async (values) => {
      if (!reviewCycle || !selectedReviewee) {
        showMessage('Review cycle or selected reviewee is not defined.', 'error');
        return;
      }
      try {
        setLoading(true);
        const { state, id, reviewerSelect } = reviewCycle;

        if ([CycleState.Draft, CycleState.Scheduled].includes(state)) {
          const currentPeerIds =
            selectedReviewee.peerIds && selectedReviewee.peerIds.length > 0 ? selectedReviewee.peerIds : [];

          const currentUpwardIds =
            selectedReviewee.upwardIds && selectedReviewee.upwardIds.length > 0 ? selectedReviewee.upwardIds : [];

          const currentManagerIds =
            selectedReviewee.managerIds && selectedReviewee.managerIds ? selectedReviewee.managerIds : [];

          const updatedPeerIds =
            values.peerIds && values.peerIds.length > 0 ? [...currentPeerIds, ...values.peerIds] : currentPeerIds;

          const updatedUpwardIds =
            values.upwardIds && values.upwardIds.length > 0
              ? [...currentUpwardIds, ...values.upwardIds]
              : currentUpwardIds;

          const updatedManagerIds =
            values.managerIds && values.managerIds.length > 0
              ? [...currentManagerIds, ...values.managerIds]
              : currentManagerIds;

          await ReviewCycleAPI.addReviewersToPreOngoing(reviewCycle.id, {
            selfId: selectedReviewee.selfId,
            peerIds: updatedPeerIds,
            upwardIds: updatedUpwardIds,
            managerIds: updatedManagerIds,
          });
        }

        if (reviewCycle.state === CycleState.Ongoing) {
          const reviewers: { reviewerType: ReviewerTypes; ids: number[] }[] = reviewerSelect.reduce<
            { reviewerType: ReviewerTypes; ids: number[] }[]
          >((acc, r) => {
            let ids: number[] | null = null;

            if (r === ReviewerTypes.Peer && values.peerIds && values.peerIds.length) {
              ids = values.peerIds;
            } else if (r === ReviewerTypes.Manager && values.managerIds && values.managerIds.length) {
              ids = values.managerIds;
            } else if (r === ReviewerTypes.Upward && values.upwardIds && values.upwardIds.length) {
              ids = values.upwardIds;
            }

            if (ids) {
              acc.push({ reviewerType: r, ids });
            }
            return acc;
          }, [] as { reviewerType: ReviewerTypes; ids: number[] }[]);

          await ReviewCycleAPI.addReviewers(id, {
            reviewers: reviewers,
            revieweeId: selectedReviewee.selfId,
          });
        }

        showMessage('Successfully added new reviewers', 'success');
        await refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return formik;
};

const RenderNewRow = ({ index, style, data }: any) => {
  const reviewerId = data[index];
  const { formik, type } = data.props;

  return (
    <Box
      style={style}
      sx={{
        paddingY: spacing.p12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <UserCell userId={reviewerId} />
      <CleanGrey
        {...iconSize}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          const filteredIds = formik.values[type].filter((id: number) => id !== reviewerId);
          formik.setFieldValue(type, filteredIds);
        }}
      />
    </Box>
  );
};
const ParticipantsAddReviewerContent = ({
  reviewCycle,
  onClose,
  refresh,
  selectedReviewee,
}: {
  reviewCycle: ReviewCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  selectedReviewee: ReviewParticipants | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useReviewerSelectForm(selectedReviewee, reviewCycle, onClose, refresh, setLoading);

  if (!reviewCycle) return <></>;

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Add reviewers</Typography>
        {reviewCycle.state === CycleState.Ongoing && (
          <Typography variant="caption" color="Grey">
            Newly added participants will receive an invite.
          </Typography>
        )}

        {selectedReviewee?.selfId && (
          <ViewItem title="Participant" value={<UserCell userId={selectedReviewee?.selfId} />} />
        )}

        {reviewCycle.reviewerSelect?.includes(ReviewerTypes.Manager) && (
          <UserSelect
            label="Manager reviewer"
            labelSx={{ ...themeFonts.caption, color: themeColors.Grey }}
            selectedSpecificButtonLabel="Add manager reviewer"
            value={formik.values?.managerIds ?? []}
            onChange={(userIds: number[]) => {
              formik.setFieldValue('managerIds', userIds);
            }}
            fieldSx={{ ...spacing.mb20 }}
            excludeEveryone={true}
            excludeCustomRule={true}
            allInvalidUserIds={[
              ...(selectedReviewee?.managerIds ?? []),
              ...(selectedReviewee?.selfId ? [selectedReviewee.selfId] : []),
            ]}
            hideUserList
          />
        )}

        {formik.values?.managerIds && formik.values?.managerIds.length > 0 && (
          <Box>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Newly added manager reviewers
            </Typography>
            <List
              height={formik.values?.managerIds.length * 40 > 250 ? 250 : formik.values?.managerIds.length * 40}
              itemCount={formik.values.managerIds.length}
              itemSize={40}
              width={'100%'}
              itemData={{
                ...formik.values.managerIds,
                props: {
                  formik,
                  themeColors,
                  spacing,
                  iconSize,
                  type: 'managerIds',
                },
              }}
            >
              {RenderNewRow}
            </List>
          </Box>
        )}

        {reviewCycle.reviewerSelect?.includes(ReviewerTypes.Upward) && (
          <UserSelect
            label="Upward reviewer"
            labelSx={{ ...themeFonts.caption, color: themeColors.Grey }}
            selectedSpecificButtonLabel="Add upward reviewer"
            value={formik.values?.upwardIds ?? []}
            onChange={(userIds: number[]) => {
              formik.setFieldValue('upwardIds', userIds);
            }}
            fieldSx={{ ...spacing.mb20 }}
            excludeEveryone={true}
            excludeCustomRule={true}
            allInvalidUserIds={[
              ...(selectedReviewee?.upwardIds ?? []),
              ...(selectedReviewee?.selfId ? [selectedReviewee.selfId] : []),
            ]}
            hideUserList
          />
        )}

        {formik.values?.upwardIds && formik.values?.upwardIds.length > 0 && (
          <Box>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Newly added upward reviewers
            </Typography>
            <List
              height={formik.values?.upwardIds.length * 40 > 250 ? 250 : formik.values?.upwardIds.length * 40}
              itemCount={formik.values.upwardIds.length}
              itemSize={40}
              width={'100%'}
              itemData={{
                ...formik.values.upwardIds,
                props: { formik, themeColors, spacing, iconSize, type: 'upwardIds' },
              }}
            >
              {RenderNewRow}
            </List>
          </Box>
        )}

        {reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer) &&
          !reviewCycle.cycleSettings.allowRevieweesToInvitePeers && (
            <UserSelect
              label="Peer reviewer"
              labelSx={{ ...themeFonts.caption, color: themeColors.Grey }}
              selectedSpecificButtonLabel="Add peer reviewer"
              value={formik.values?.peerIds ?? []}
              onChange={(userIds: number[]) => {
                formik.setFieldValue('peerIds', userIds);
              }}
              fieldSx={{ ...spacing.mb20 }}
              excludeEveryone={true}
              excludeCustomRule={true}
              allInvalidUserIds={[
                ...(selectedReviewee?.peerIds ?? []),
                ...(selectedReviewee?.selfId ? [selectedReviewee.selfId] : []),
              ]}
              hideUserList
            />
          )}

        {formik.values?.peerIds && formik.values?.peerIds.length > 0 && (
          <Box>
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Newly added peer reviewers
            </Typography>
            <List
              height={formik.values?.peerIds.length * 40 > 250 ? 250 : formik.values?.peerIds.length * 40}
              itemCount={formik.values.peerIds.length}
              itemSize={40}
              width={'100%'}
              itemData={{
                ...formik.values.peerIds,
                props: { formik, themeColors, spacing, iconSize, type: 'peerIds' },
              }}
            >
              {RenderNewRow}
            </List>
          </Box>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const ViewItem = ({ title, value }: { title: string; value: React.JSX.Element | string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.g8,
      }}
    >
      <Typography variant="caption" sx={{ color: themeColors.Grey }}>
        {title}
      </Typography>
      {typeof value === 'string' ? <Typography variant="caption">{value}</Typography> : <Box>{value}</Box>}
    </Box>
  );
};
