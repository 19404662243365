import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { P45FileUpload } from '@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/p45-file-upload.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type P45ValuesProps = {
  userId: number;
  disabled?: boolean;
  haveP45?: boolean;
  setHaveP45: (value: boolean) => void;
  leaveDate?: string;
  setLeaveDate: (leavingDate: string) => void;
  taxCode?: string;
  setTaxCode: (taxCode: string) => void;
  totalPay?: number;
  setTotalPay: (totalPay: number) => void;
  taxPaid?: number;
  setTaxPaid: (taxPaid: number) => void;
};

export const P45Values = ({
  userId,
  disabled,
  haveP45,
  setHaveP45,
  leaveDate,
  setLeaveDate,
  taxCode,
  setTaxCode,
  totalPay,
  setTotalPay,
  taxPaid,
  setTaxPaid,
}: P45ValuesProps) => {
  const { polyglot } = usePolyglot();
  return (
    <>
      <Stack>
        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
          {polyglot.t('OnboardingByUser.doYouHaveP45')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            value={haveP45 === undefined ? '' : haveP45 ? 'yes' : 'no'}
            onChange={(event) => {
              setHaveP45(event.target.value === 'yes');
            }}
            sx={{ flexFlow: 'row' }}
          >
            <FormControlLabel
              labelPlacement="end"
              value={'yes'}
              control={<StyledRadio />}
              disabled={disabled}
              label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('OnboardingByUser.yesIDo')}</Typography>}
              sx={{ width: 200 }}
            />
            <FormControlLabel
              labelPlacement="end"
              value={'no'}
              control={<StyledRadio />}
              disabled={disabled}
              label={<Typography sx={{ ...themeFonts.caption }}>{polyglot.t('OnboardingByUser.noIDont')}</Typography>}
            />
          </RadioGroup>
        </FormControl>
        <Stack sx={{ overflow: 'hidden' }}>
          <Stack sx={{ mt: haveP45 ? spacing.g20 : '-100%', transition: 'margin-top 0.4s linear' }}>
            <P45FileUpload userId={userId} disabled={disabled} />
          </Stack>
          <DatePickerComponent
            name="leaveDate"
            label={polyglot.t('OnboardingByUser.leavingDate')}
            value={leaveDate}
            onChange={(value) => {
              setLeaveDate(value);
            }}
            disabled={disabled}
            disableFuture
          />

          <TextfieldComponent
            label={polyglot.t('OnboardingByUser.taxCodeAtLeaving')}
            onChange={(e) => {
              setTaxCode(e.target.value.toUpperCase().replace(/[^A-Z\d]+/g, ''));
            }}
            value={taxCode}
            disabled={disabled}
            clearText={() => setTaxCode('')}
            sx={{ mt: spacing.mt20 }}
          />
          <MoneyTextfieldComponent
            name="totalPay"
            label={polyglot.t('OnboardingByUser.totalPay')}
            onChange={(value) => setTotalPay(value ?? 0)}
            value={totalPay}
            disabled={disabled}
            sx={{ mt: spacing.mt20 }}
          />
          <MoneyTextfieldComponent
            name="taxPaid"
            label={polyglot.t('OnboardingByUser.taxPaid')}
            onChange={(value) => setTaxPaid(value ?? 0)}
            value={taxPaid}
            disabled={disabled}
            sx={{ mt: spacing.mt20 }}
          />
        </Stack>
      </Stack>
    </>
  );
};
