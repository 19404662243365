import React from 'react';

import { v4 } from 'uuid';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SurveyQuestionBankAPI } from '@/v2/feature/growth/surveys/api-client/survey-question-bank.api';
import { SurveyQuestionModalContent } from '@/v2/feature/growth/surveys/features/components/survey-question-modal-content.component';
import { SurveyQuestionBank } from '@/v2/feature/growth/surveys/interfaces/survey-question-bank.interface';
import { SurveySectionBank } from '@/v2/feature/growth/surveys/interfaces/survey-section-bank.interface';

type CommonCycleAndBankQuestion = Pick<
  SurveyQuestionBank,
  'id' | 'questionText' | 'type' | 'factor' | 'choiceOptions' | 'hasComment' | 'scaleConfig' | 'isCommentRequired'
>;

export const SurveyTemplateQuestionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  question,
  refresh,
  section,
  templateId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  question: SurveyQuestionBank | null;
  refresh: () => Promise<void>;
  section?: SurveySectionBank | null;
  templateId: string;
}) => {
  const onSubmit = async (values: CommonCycleAndBankQuestion) => {
    if (question?.id) {
      //update question
      const questionValues = {
        ...question,
        ...values,
        sectionId: section?.id ?? null,
        templateId,
      };
      await SurveyQuestionBankAPI.updateSurveyQuestionInBank(questionValues);
    } else {
      const questionValues = {
        ...values,
        id: v4(),
        sectionId: section?.id ?? null,
        templateId,
      };
      await SurveyQuestionBankAPI.createSurveyQuestionInBank(questionValues);
    }
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SurveyQuestionModalContent onClose={onClose} question={question} refresh={refresh} onSubmit={onSubmit} />
    </DrawerModal>
  );
};
