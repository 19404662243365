import { Interpolation } from '@emotion/serialize';
import styled from '@emotion/styled';
import { styled as materialStyled } from '@mui/material/styles';

type RootStyleProps = {
  center?: boolean;
};

const styles: Interpolation<RootStyleProps> = () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const RootStyle = styled.div<RootStyleProps>(styles);

export const BackofficeRootStyle = materialStyled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const MainHeaderRootStyle = materialStyled('div')(() => ({
  display: 'flex',
  backgroundColor: '#fff',
}));

export const textOverflowSx: React.CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '90%',
};
