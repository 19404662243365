import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceSettingsDto } from '@v2/feature/absence/absence.dto';
import { getPublicHolidayCalendarOptions, NoPublicHolidayCalendarOption } from '@v2/feature/absence/absence.util';
import { getOverlapsRuleOptions } from '@v2/feature/absence/company/policies/pages/absence-company-settings-general.page';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { detailSx } from '@v2/styles/settings.styles';

interface AbsenceGeneralSettingsReadonlyProps {
  readonly absenceSettings: AbsenceSettingsDto;
}

export const AbsenceGeneralSettingsReadonly = ({ absenceSettings }: AbsenceGeneralSettingsReadonlyProps) => {
  const { polyglot } = usePolyglot();

  const holidayCalendar =
    getPublicHolidayCalendarOptions().find((option) => option.value === absenceSettings.holidayCountry)?.label ??
    NoPublicHolidayCalendarOption.label;

  return (
    <>
      <ReadOnlyRow
        key="public-holiday-calendar"
        label={polyglot.t('AbsenceGeneralSettings.defaultHolidayCountry')}
        value={holidayCalendar}
      />

      <ReadOnlyRow
        key="prevent-own-overlaps"
        label={polyglot.t('AbsenceGeneralSettings.preventOwnOverlaps')}
        value={polyglot.t(
          absenceSettings.preventOwnOverlaps ? 'AbsenceGeneralSettings.on' : 'AbsenceGeneralSettings.off'
        )}
      />

      <ReadOnlyRow
        key="overlap-summary"
        label={polyglot.t('AbsenceGeneralSettings.overlapSummary')}
        value={polyglot.t(absenceSettings.showOverlap ? 'AbsenceGeneralSettings.on' : 'AbsenceGeneralSettings.off')}
      />

      {absenceSettings.showOverlap && (
        <ReadOnlyRow
          key="overlaps-rule"
          label={polyglot.t('AbsenceGeneralSettings.showOverlapWith')}
          value={getOverlapsRuleOptions(polyglot).find((o) => o.value === absenceSettings.overlapsRule)?.label ?? ''}
        />
      )}
    </>
  );
};

const ReadOnlyRow = ({ label, value }: { label: string; value: string }) => (
  <Box sx={detailSx}>
    <Typography sx={{ maxWidth: { sm: '90px', md: '190px', lg: '190px' }, width: '100%' }} variant="caption">
      {label}
    </Typography>
    <Typography sx={{ maxWidth: { sm: '200px', md: '400px', lg: '400px' }, width: '100%' }} variant="title4">
      {value}
    </Typography>
  </Box>
);
