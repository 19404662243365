import { Box } from '@mui/material';

import { SplitSwitchButton } from '@/v2/components/split-switch-button.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const AttendanceWeeklyHeaderLoader = () => {
  return (
    <Box sx={{ display: 'block', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, display: 'flex', width: '100%', height: '100%', gap: spacing.g20 }}>
          <SkeletonLoader
            variant="rectangular"
            width="100px"
            height="16px"
            sx={{
              background: themeColors.Background,
              borderRadius: radius.br10,
            }}
          />
          <SkeletonLoader
            variant="rectangular"
            width="200px"
            height="16px"
            sx={{
              background: themeColors.Background,
              borderRadius: radius.br10,
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
          <SplitSwitchButton
            listViewAction={() => {}}
            columnViewAction={() => {}}
            listActive={true}
            columnActive={false}
          />

          <SkeletonLoader
            variant="rectangular"
            width="95px"
            height="16px"
            sx={{
              background: themeColors.Background,
              borderRadius: radius.br10,
            }}
          />
        </div>
      </Box>

      <Box
        sx={{
          ...spacing.mt20,
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: spacing.g5,
          width: '100%',
        }}
      >
        {[0, 1, 2, 3, 4, 5, 6].map((i) => (
          <ScheduleTitleLoader key={i} />
        ))}
      </Box>
    </Box>
  );
};

const ScheduleTitleLoader = () => {
  return (
    <Box
      sx={{
        flex: 1,
        height: '95px',
        background: themeColors.Background,
        borderRadius: radius.br8,
      }}
    >
      <div style={{ padding: '15px 10px 15px 10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5, alignItems: 'center' }}>
          <SkeletonLoader
            variant="rectangular"
            width="30%"
            height="16px"
            sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
          />
        </Box>
      </div>
    </Box>
  );
};
