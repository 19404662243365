import React from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';

export default function FailureCloseTab() {
  const location: Location = useLocation<Location>();
  const message: string | null = new URLSearchParams(location.search).get('message');
  return (
    <Box sx={{ m: 'auto', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mx: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box>
          <Mistake width="40" height="40px" fill={themeColors.ZeltYellow} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Payment has been rejected.</Typography>
        </Box>
      </Box>

      <Typography sx={{ ...themeFonts.caption, mt: spacing.mt20, color: themeColors.Grey }} alignSelf="center">
        {message
          ? message.replaceAll('We can help you on https://docs.yapily.com/support', '')
          : 'Something went wrong...'}
      </Typography>
    </Box>
  );
}
