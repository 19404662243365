import { useContext, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { LocalDate } from '@v2/util/local-date';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DIRECTORY_ROUTE, DEVICES_ME_DIRECTORY_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { DeviceTransitDto } from '@/v2/feature/device/device.dto';
import { DevicePossessionType, DeviceTransitStatus } from '@/v2/feature/device/device.interface';
import { FieldValueComponent, getModelImage, getTransitStatus } from '@/v2/feature/device/device.util';
import { linkTextSx } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const getDeviceSender = (deviceTransit: DeviceTransitDto) => {
  if (deviceTransit.sender?.possessionType === DevicePossessionType.User && deviceTransit.sender?.possessionId)
    return deviceTransit.sender.possessionId ? (
      <UserCell userId={deviceTransit.sender.possessionId} nameSx={themeFonts.title4} />
    ) : (
      'Not assigned'
    );
  if (deviceTransit.sender?.possessionType === DevicePossessionType.CompanySite)
    return <Typography sx={themeFonts.title4}>Company Site</Typography>;
  if (deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStorage)
    return <Typography sx={themeFonts.title4}>Zelt Storage</Typography>;
  if (deviceTransit.sender?.possessionType === DevicePossessionType.ZeltStock)
    return <Typography sx={themeFonts.title4}>Zelt Stock</Typography>;

  return <Typography sx={themeFonts.title4}>Unknown</Typography>;
};

const getDeviceReceiver = (deviceTransit: DeviceTransitDto) => {
  if (deviceTransit.receiver?.possessionType === DevicePossessionType.User && deviceTransit.receiver?.possessionId)
    return deviceTransit.receiver.possessionId ? (
      <UserCell userId={deviceTransit.receiver.possessionId} nameSx={themeFonts.title4} />
    ) : (
      'Not assigned'
    );
  if (deviceTransit.receiver?.possessionType === DevicePossessionType.CompanySite)
    return <Typography sx={themeFonts.title4}>Company Site</Typography>;
  if (deviceTransit.receiver?.possessionType === DevicePossessionType.ZeltStorage)
    return <Typography sx={themeFonts.title4}>Zelt Storage</Typography>;
  if (deviceTransit.receiver?.possessionType === DevicePossessionType.ZeltStock)
    return <Typography sx={themeFonts.title4}>Zelt Stock</Typography>;

  return <Typography sx={themeFonts.title4}>Unknown</Typography>;
};
export const DeviceReturnDrawer = ({
  selectedDeviceTransit,
  refresh,
  reach,
}: {
  selectedDeviceTransit: DeviceTransitDto;
  refresh: () => Promise<void>;
  reach: string;
}) => {
  const routerHistory = useHistory();
  const [globalState, dispatch] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const [isMarkAsShippedLoading, setIsMarkAsShippedLoading] = useState<boolean>(false);
  const [isMarkAsDeliveredLoading, setIsMarkAsDeliveredLoading] = useState<boolean>(false);

  const internalPossessions = [DevicePossessionType.User, DevicePossessionType.CompanySite];
  const isInternalTransit =
    selectedDeviceTransit.sender &&
    selectedDeviceTransit.receiver &&
    internalPossessions.includes(selectedDeviceTransit.sender?.possessionType) &&
    internalPossessions.includes(selectedDeviceTransit.receiver?.possessionType);

  const isReturningOrSendToZeltStorageTransit =
    selectedDeviceTransit.receiver?.possessionType &&
    [DevicePossessionType.ZeltStorage, DevicePossessionType.ZeltStock].includes(
      selectedDeviceTransit.receiver.possessionType
    );

  const markInternalTransitAsShipped = async (): Promise<void> => {
    let isMarkedAsShipped = false;
    try {
      setIsMarkAsShippedLoading(true);
      await DeviceAPI.changeDeviceOwnerSetTransitInShipping(selectedDeviceTransit.id);
      showMessage('Transit successfully marked as shipped', 'success');
      isMarkedAsShipped = true;
      await refresh();
      const alertsForDevices = await DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
    } catch (error) {
      if (isMarkedAsShipped) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setIsMarkAsShippedLoading(false);
      routerHistory.push(
        generatePath(reach === 'company' ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  };

  const markReturningTransitAsShipped = async (): Promise<void> => {
    let isMarkedAsShipped = false;
    try {
      setIsMarkAsShippedLoading(true);
      await DeviceAPI.markReturnTransitAsShipped(selectedDeviceTransit.id);
      isMarkedAsShipped = true;
      showMessage('Transit successfully marked as shipped', 'success');
      await refresh();
      const alertsForDevices = await DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
    } catch (error) {
      if (isMarkedAsShipped) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setIsMarkAsShippedLoading(false);
      routerHistory.push(
        generatePath(reach === 'company' ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  };

  const cancelReturnDevice = async (): Promise<void> => {
    let isCancelled = false;
    try {
      if (selectedDeviceTransit) {
        await DeviceAPI.cancelDeviceTransit(selectedDeviceTransit.id);
        isCancelled = true;
        showMessage('Device return has been cancelled.', 'success');

        await refresh();
        const alertsForDevices = await DeviceAPI.getAlerts(globalState.user.userId);
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { devices: alertsForDevices },
        });
      }
    } catch (error) {
      if (isCancelled) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not cancel device return. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      routerHistory.push(
        generatePath(reach === 'company' ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  };

  const markInternalTransitAsDelivered = async (): Promise<void> => {
    let isDeliveryConfirmed = false;
    setIsMarkAsDeliveredLoading(true);
    try {
      await DeviceAPI.changeDeviceOwnerSetTransitAsDelivered(selectedDeviceTransit.id, new LocalDate().toDateString());
      showMessage('Transit successfully marked as delivered.', 'success');
      isDeliveryConfirmed = true;
      const alertsForDevices = await DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
    } catch (error) {
      if (isDeliveryConfirmed) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      }
    } finally {
      setIsMarkAsDeliveredLoading(false);
      routerHistory.push(
        generatePath(reach === 'company' ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
      );
    }
  };

  return (
    <Box>
      <Typography
        sx={{ ...themeFonts.title2, ...linkTextSx }}
        onClick={() => {
          routerHistory.push(
            generatePath(reach === 'company' ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE)
          );
        }}
      >
        {selectedDeviceTransit?.device?.modelName ?? 'Return'}{' '}
      </Typography>
      <Box sx={{ mt: spacing.m10, display: 'flex', justifyContent: 'center' }}>
        {getModelImage(selectedDeviceTransit?.device?.type, selectedDeviceTransit?.device?.modelName, {
          width: '180px',
          height: 'auto',
        })}
      </Box>

      <Box component="section" sx={spacing.mt40}>
        <Box component="dl" sx={{ display: 'flex', flexDirection: 'column', margin: 0, padding: 0, gap: spacing.g15 }}>
          {selectedDeviceTransit.status && (
            <FieldValueComponent
              title="Return status"
              value={<Typography sx={themeFonts.caption}>{getTransitStatus(selectedDeviceTransit.status)}</Typography>}
            />
          )}
          {selectedDeviceTransit?.sender?.possessionType && (
            <FieldValueComponent title="Sender" value={getDeviceSender(selectedDeviceTransit)} />
          )}

          {selectedDeviceTransit?.receiver?.possessionType && (
            <FieldValueComponent title="Receiver" value={getDeviceReceiver(selectedDeviceTransit)} />
          )}

          {selectedDeviceTransit?.receiver?.deliveryAddress && (
            <FieldValueComponent title="Address" value={selectedDeviceTransit?.receiver?.deliveryAddress} />
          )}

          {selectedDeviceTransit?.device?.serialNumber && (
            <FieldValueComponent title="Serial number" value={selectedDeviceTransit?.device?.serialNumber} />
          )}
        </Box>
      </Box>

      {selectedDeviceTransit.status === DeviceTransitStatus.Pending && (
        <Box sx={spacing.mt40}>
          <Box sx={{ display: 'flex', gap: spacing.gap10 }}>
            <ButtonComponent
              sizeVariant="medium"
              colorVariant="secondary"
              fullWidth
              onClick={() => cancelReturnDevice()}
            >
              Cancel return
            </ButtonComponent>

            {isInternalTransit && (
              <LoaderButton
                name="Mark as shipped"
                loading={isMarkAsShippedLoading}
                colorVariant="primary"
                sizeVariant="medium"
                fullWidth
                onClick={markInternalTransitAsShipped}
              />
            )}

            {isReturningOrSendToZeltStorageTransit && (
              <LoaderButton
                name="Mark as shipped"
                loading={isMarkAsShippedLoading}
                colorVariant="primary"
                sizeVariant="medium"
                fullWidth
                onClick={markReturningTransitAsShipped}
              />
            )}
          </Box>
        </Box>
      )}

      {selectedDeviceTransit.status === DeviceTransitStatus.Shipping && isInternalTransit && (
        <Box sx={spacing.mt40}>
          <LoaderButton
            name="Confirm delivery"
            loading={isMarkAsDeliveredLoading}
            colorVariant="primary"
            sizeVariant="medium"
            fullWidth
            onClick={markInternalTransitAsDelivered}
          />
        </Box>
      )}
    </Box>
  );
};
