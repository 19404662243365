import { useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';

import { PayrollSettingSectionHeader } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component';
import { PayrollEmploymentAllowance } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/employer-allowance-settings/payroll-employment-allowance.component';

interface PayrollEmploymentAllowanceSectionProps {
  readonly payrollId: number;
  readonly setOtherEdit: (_: boolean) => void;
  readonly sx?: SxProps<Theme>;
}

export const PayrollEmploymentAllowanceSection = ({
  payrollId,
  setOtherEdit,
  sx,
}: PayrollEmploymentAllowanceSectionProps): JSX.Element => {
  const [empAllowanceDrawerOpen, setEmpAllowanceDrawerOpen] = useState<boolean>(false);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);

  return (
    <Stack sx={sx}>
      <PayrollSettingSectionHeader showEditButton={showEditButton} onEditClick={() => setEmpAllowanceDrawerOpen(true)}>
        Employment allowance
      </PayrollSettingSectionHeader>

      <PayrollEmploymentAllowance
        payrollId={payrollId}
        setOtherEdit={setOtherEdit}
        setShowEditButton={setShowEditButton}
        empAllowanceDrawerOpen={empAllowanceDrawerOpen}
        setEmpAllowanceDrawerOpen={setEmpAllowanceDrawerOpen}
      />
    </Stack>
  );
};
