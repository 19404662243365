import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { CellContext, ColumnDef, PaginationState, Row, SortingState } from '@tanstack/react-table';
import {
  allPartiesHaveSignedContract,
  companySignatureRequired,
  downloadFileByUrl,
  isPreviewableFile,
  recipientSignatureRequired,
} from '@v2/feature/documents/documents.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { constVoid, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/ReadonlyArray';
import * as RNEA from 'fp-ts/ReadonlyNonEmptyArray';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { from, Subject } from 'rxjs';
import * as RX from 'rxjs/operators';

import { ContractAPI } from '@/api-client/contract.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Cancel } from '@/images/app-icons/Close.svg';
import { ReactComponent as MissingDoc } from '@/images/documents/MissingDoc.svg';
import { ReactComponent as UploadDoc } from '@/images/documents/UploadedDoc.svg';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { ReactComponent as Mail } from '@/images/side-bar-icons/Mail.svg';
import {
  getDocumentTypeLabel,
  ZeltDocument,
  ZeltDocumentType,
  ZeltDocumentUser,
  ZeltDocumentVerification,
} from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import {
  ADMIN_USER_DOCUMENTS_ROUTE,
  DOCUMENTS_ME_ROUTE,
  TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { CurrentUser, ScopeContext } from '@/models';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { IconTextCell } from '@/v2/components/table/icon-text-cell.component';
import { BasicServerTable } from '@/v2/components/table/server-side-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { DisplayDocumentStatus } from '@/v2/feature/documents/components/display-document-status.component';
import { ShareViaEmailDrawer } from '@/v2/feature/documents/components/document-share-via-email-drawer.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { BufferData, PreviewPayload } from '@/v2/feature/documents/documents.interface';
import { ContractObject, RequiredContractFields } from '@/v2/feature/templates/templates.interface';
import { PlusAvatar, UserAvatar, UserAvatarSize } from '@/v2/feature/user/components/user-avatar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { getDisplayName } from '@/v2/util/string-format.util';

interface MultiAvatarProps {
  readonly currentUser: CurrentUser;
  readonly users: readonly ZeltDocumentUser[];
  readonly size?: UserAvatarSize;
}

const iconSize = { width: 14, height: 14 } as const;

export const MultiUserAvatars = ({ currentUser, users, size = 'xxsmall' }: MultiAvatarProps): JSX.Element => {
  const [firstThreeUsers, remainingUsers] = pipe(users, RA.splitAt(3));
  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
      {firstThreeUsers.map(({ User: { userId } }) => (
        <Link
          style={{ textDecoration: 'none' }}
          key={`avatar:${userId}`}
          to={
            currentUser.userId === userId
              ? generatePath(DOCUMENTS_ME_ROUTE, { userId })
              : generatePath(ADMIN_USER_DOCUMENTS_ROUTE, { userId })
          }
        >
          <UserAvatar userId={userId} tooltip size={size} />
        </Link>
      ))}
      {remainingUsers.length > 0 ? (
        <Tooltip
          title={
            <>
              {remainingUsers.map((u) => (
                <div key={u.User.userId}>{getDisplayName(u.User)}</div>
              ))}
            </>
          }
        >
          <PlusAvatar count={remainingUsers.length} size={size} />
        </Tooltip>
      ) : (
        <></>
      )}
    </Box>
  );
};

interface DocumentTableProps {
  readonly documents: readonly ZeltDocument[];
  readonly documentTypes: readonly Pick<
    ZeltDocumentType,
    'value' | 'label' | 'needsVerification' | 'canUserAdd' | 'category'
  >[];
  readonly onEditClick: (_: ZeltDocument) => void;
  readonly onVerified?: (_1: ZeltDocument, _2: ZeltDocumentVerification) => void;
  readonly sorting?: SortingState;
  readonly setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
  readonly pagination?: PaginationState;
  readonly setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
  readonly totalPages?: number;
  readonly totalItems?: number;
  readonly personalDocuments?: boolean;
  readonly onDelete?: () => Promise<void>;
  readonly loading?: boolean;
  readonly paginationReset?: boolean;
  readonly onMissingContractFields?: (missingFields: RequiredContractFields, contract: ContractObject) => void;
  readonly setSelectedDocBuffer: React.Dispatch<React.SetStateAction<BufferData | null | undefined>>;
  readonly setSelectedDocContentType: React.Dispatch<React.SetStateAction<string>>;
  readonly setSelectedDocName: React.Dispatch<React.SetStateAction<string>>;
  readonly setOpenPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setSelectionModel?: React.Dispatch<React.SetStateAction<number[]>>;
  readonly selectionModel?: number[];
}

export const DocumentTable = ({
  documents,
  documentTypes,
  onEditClick,
  onVerified = constVoid,
  sorting,
  setSorting,
  pagination,
  setPagination,
  totalPages,
  personalDocuments = false,
  onDelete,
  loading,
  paginationReset = false,
  onMissingContractFields,
  setSelectedDocBuffer,
  setSelectedDocContentType,
  setSelectedDocName,
  setOpenPreviewModal,
  totalItems,
  setSelectionModel,
  selectionModel,
}: DocumentTableProps): JSX.Element => {
  const [state] = useContext(GlobalContext);
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext(state.user);
  const [showMessage] = useMessage();
  const [openShareViaEmailModal, setOpenShareViaEmailModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<ZeltDocument | null>(null);

  const isContractAdmin = hasScopes(['templates:all'], scopesContext);

  const handleDownloadClick = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        const PDFBlob = await ContractAPI.downloadInvoice({
          contractId: zeltDocument.contractId as string,
        });

        const file = new Blob([PDFBlob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        let link = document.createElement('a');
        link.download = `${zeltDocument.contractId}-${new Date()}-${Date.now()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (e) {
        console.error('::Download error', e);
        showMessage(
          polyglot.t('DocumentTable.errorMessages.downloadTrail', { errorMessage: nestErrorMessage(e) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  const handlePreviewClick = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        const fileUuidFromAttachment =
          zeltDocument.attachments && zeltDocument.attachments[0] && zeltDocument.attachments[0]?.fileUuid;
        const finalUuid = zeltDocument.fileUuid ?? fileUuidFromAttachment;
        if (!finalUuid) return;
        await DocumentAPI.previewViaUuid(finalUuid).then(async ({ contentType, file }: PreviewPayload) => {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        });
      } catch (e) {
        console.error('::URL Download error', e);
        showMessage(polyglot.t('DocumentTable.errorMessages.preview', { errorMessage: nestErrorMessage(e) }), 'error');
      }
    },
    [polyglot, setOpenPreviewModal, setSelectedDocBuffer, setSelectedDocContentType, setSelectedDocName, showMessage]
  );

  const handleShareViaEmailClick = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        setSelectedDocument(zeltDocument);
        setOpenShareViaEmailModal(true);
      } catch (e) {
        showMessage(polyglot.t('DocumentTable.errorMessages.share', { errorMessage: nestErrorMessage(e) }), 'error');
      }
    },
    [showMessage, polyglot]
  );

  const remindPendingContractParticipants = useCallback(
    async (contractId: string) => {
      try {
        await ContractAPI.remindPending({
          contractId: contractId,
        });

        showMessage(polyglot.t('DocumentTable.successMessages.remind'), 'success');
      } catch (e) {
        console.error('::Remind error', e);
        showMessage(polyglot.t('DocumentTable.errorMessages.remind', { errorMessage: nestErrorMessage(e) }), 'error');
      }
    },
    [showMessage, polyglot]
  );

  const remindUserToUploadDocument = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        const belongsToUserId =
          zeltDocument.belongsTo && zeltDocument.belongsTo.length > 0
            ? zeltDocument.belongsTo[0].User.userId
            : undefined;
        if (belongsToUserId) {
          await DocumentAPI.remindUserForPendingDocument(zeltDocument.name, belongsToUserId);
          showMessage(polyglot.t('DocumentTable.successMessages.remindToUpload'), 'success');
        } else {
          console.error('Failed to send reminder to user as belongs to userId is missing: ', {
            belognsTo: zeltDocument.belongsTo,
          });
        }
      } catch (e) {
        showMessage(
          polyglot.t('DocumentTable.errorMessages.remindToUpload', { errorMessage: nestErrorMessage(e) }),
          'error'
        );
      }
    },
    [showMessage, polyglot]
  );

  const deleteContractDocument = useCallback(
    async (contractId: string) => {
      try {
        await ContractAPI.deleteContract({
          contractId: contractId,
        });

        showMessage(polyglot.t('DocumentTable.successMessages.delete'), 'success');

        if (onDelete) {
          await onDelete();
        }
      } catch (e) {
        console.error('::Delete error', e);
        showMessage(polyglot.t('DocumentTable.errorMessages.delete', { errorMessage: nestErrorMessage(e) }), 'error');
      }
    },
    [polyglot, showMessage, onDelete]
  );

  const [verify$] = useState(() => new Subject<ZeltDocument>());
  useEffect(
    () =>
      pipe(
        pipe(
          verify$,
          RX.switchMap((doc) =>
            pipe(
              from(DocumentAPI.verifyDocumentById(doc.id)),
              RX.map((documentVerification) => ({ doc, documentVerification }))
            )
          ),
          ($) =>
            $.subscribe({
              next: ({ doc, documentVerification }) => onVerified(doc, documentVerification),
            })
        ),
        (s) => () => s.unsubscribe()
      ),
    [verify$, onVerified]
  );

  const getDocumentsWithAttachmentsMenuOptions = useCallback(
    (
      row: ZeltDocument,
      contractSignAction: (row: ZeltDocument) => Promise<void>,
      isEditableOrRemovable?: boolean
    ): OptionProps[] => {
      const documentWithAttachmentMenuOptions = [];
      const { contractId, contract } = row;
      const belongsToOnlyYou =
        row.belongsTo && row.belongsTo.length === 1 && row.belongsTo[0].User.userId === state.user.userId;
      const reporteeScopeContext: ScopeContext = {
        userId: row.belongsTo && row.belongsTo?.length === 1 ? row.belongsTo[0]?.User?.userId : 0,
      };
      const stdUserCan = state.user.userId === row.uploadedBy || belongsToOnlyYou;
      let documentUrl = '';
      const attachmentExists = !!(
        (row.attachments && row.attachments.length && row.attachments[0]?.fileUuid) ||
        row.fileUuid
      );
      const hasFileUuid = row.fileUuid && typeof row.fileUuid === 'string';
      const fileExistsForNonContractAndHasDocAllScope =
        (attachmentExists || hasFileUuid) && !contractId && hasScopes(['documents:all'], scopesContext);
      const nonContractAndStdUserCanOrDocManagerScopes =
        !contractId && (stdUserCan || hasScopes(['documents:manager'], reporteeScopeContext));
      const hasSignatories = contract?.signatoriesRequired && contract?.signatoriesRequired?.length > 0;
      const currentUserIsContractRecipient = contractId && contract?.recipient === state.user.userId;
      const unfinalisedContractIsMissingInfo = !!(
        contract?.id &&
        !hasFileUuid &&
        (contract?.hasMissingPersonalFields || contract?.hasMissingWorkFields)
      );
      // const companyOrTeamReach = ['team', 'company'].includes(reach);
      const allPartiesHaveSigned = allPartiesHaveSignedContract(row);
      const contractSignedByAllAndStdUserCanOrDocManagerScope =
        allPartiesHaveSigned && (stdUserCan || hasScopes(['documents:manager'], reporteeScopeContext));

      const missingPersonalInfoAndCanFill = currentUserIsContractRecipient && unfinalisedContractIsMissingInfo;

      const hasBelongsTo = row.belongsTo && row.belongsTo?.length > 0;

      // no attachment / belongs to someone and is NOT a contract
      const shouldRemindToUpload = !attachmentExists && hasBelongsTo && !contractId;

      if (shouldRemindToUpload) {
        documentWithAttachmentMenuOptions.push({
          icon: <Mail {...iconSize} />,
          handler: () => {
            remindUserToUploadDocument(row);
          },
          label: polyglot.t('DocumentTable.remindToUpload'),
          disabled: false,
        });
      }

      if (
        contractId &&
        ((hasSignatories && !allPartiesHaveSigned) || unfinalisedContractIsMissingInfo) &&
        isContractAdmin &&
        isEditableOrRemovable
      ) {
        documentWithAttachmentMenuOptions.push({
          icon: <Cancel {...iconSize} />,
          handler: () => {
            deleteContractDocument(contractId as string);
          },
          label: polyglot.t('General.cancel'),
          disabled: false,
        });
      }
      if (attachmentExists && !contractId && row.attachments) {
        documentUrl = row.attachments[0].url;
        documentWithAttachmentMenuOptions.push({
          icon: <Download {...iconSize} />,
          handler: () => {
            downloadFileByUrl(documentUrl, documentUrl);
          },
          label: polyglot.t('General.download'),
          disabled: false,
        });
      }

      if (row.fileUuid && !contractId) {
        documentWithAttachmentMenuOptions.push({
          icon: <Download {...iconSize} />,
          handler: async () => {
            if (row?.fileUuid && typeof row.fileUuid === 'string') {
              await DocumentAPI.downloadViaUuid(row?.fileUuid).then((url: string) => {
                downloadFileByUrl(url, row.fileName);
              });
            }
          },
          label: polyglot.t('General.download'),
          disabled: false,
        });
      }

      if (
        ((contractId && hasSignatories && allPartiesHaveSigned) || (contractId && !hasSignatories)) &&
        (attachmentExists || row.fileUuid)
      ) {
        documentWithAttachmentMenuOptions.push({
          icon: <Download {...iconSize} />,
          handler: async () => {
            if (
              (row?.fileUuid && typeof row.fileUuid === 'string') ||
              (row?.attachments?.length && row?.attachments[0]?.fileUuid)
            ) {
              const fileUuidForRow = row?.fileUuid ?? (row?.attachments?.length && row?.attachments[0]?.fileUuid);
              const contractNameForDownload = row.contractId ? row.name : undefined;
              if (fileUuidForRow)
                await DocumentAPI.downloadViaUuid(fileUuidForRow, contractNameForDownload).then((url: string) => {
                  downloadFileByUrl(url, row.fileName);
                });
              else {
                showMessage(polyglot.t('DocumentTable.WarningMessages.available'), 'warning');
              }
            }
          },
          label: polyglot.t('DocumentTable.download'),
          disabled: false,
        });
      }

      if (
        fileExistsForNonContractAndHasDocAllScope ||
        nonContractAndStdUserCanOrDocManagerScopes ||
        contractSignedByAllAndStdUserCanOrDocManagerScope
      ) {
        documentWithAttachmentMenuOptions.push({
          icon: <Edit {...iconSize} />,
          handler: () => {
            onEditClick(row);
          },
          label: polyglot.t('General.edit'),
          disabled: false,
        });
      }

      if (
        (attachmentExists || hasFileUuid) &&
        allPartiesHaveSigned &&
        contractId &&
        hasScopes(['templates:all'], scopesContext)
      ) {
        documentWithAttachmentMenuOptions.push({
          icon: <Download {...iconSize} />,
          handler: () => {
            handleDownloadClick(row);
          },
          label: polyglot.t('DocumentTable.auditTrail'),
          disabled: false,
        });
      }

      if (
        (attachmentExists || hasFileUuid) &&
        ((contractId && hasSignatories && allPartiesHaveSigned && isPreviewableFile(row)) ||
          (!contractId && isPreviewableFile(row)) ||
          (contractId && !hasSignatories && isPreviewableFile(row)))
      ) {
        documentWithAttachmentMenuOptions.push({
          icon: <Eye {...iconSize} />,
          handler: () => {
            handlePreviewClick(row);
          },
          label: polyglot.t('DocumentTable.preview'),
          disabled: false,
        });
      }

      if ((attachmentExists || hasFileUuid) && !personalDocuments && !contractId) {
        documentWithAttachmentMenuOptions.push({
          icon: <Mail {...iconSize} />,
          handler: () => {
            handleShareViaEmailClick(row);
          },
          label: polyglot.t('DocumentTable.shareViaEmail'),
          disabled: false,
        });
      }

      if (contractId && ((hasSignatories && !allPartiesHaveSigned) || unfinalisedContractIsMissingInfo)) {
        documentWithAttachmentMenuOptions.push({
          icon: <Mail {...iconSize} />,
          handler: () => {
            remindPendingContractParticipants(contractId);
          },
          label: polyglot.t('DocumentTable.remind'),
          disabled: false,
        });
      }

      if (contractId && missingPersonalInfoAndCanFill) {
        documentWithAttachmentMenuOptions.push({
          handler: async () => {
            contractSignAction(row);
          },
          label: polyglot.t('DocumentTable.fillMissingFields'),
          disabled: false,
        });
      }

      return documentWithAttachmentMenuOptions;
    },
    [
      state.user.userId,
      hasScopes,
      scopesContext,
      isContractAdmin,
      personalDocuments,
      polyglot,
      remindUserToUploadDocument,
      deleteContractDocument,
      showMessage,
      onEditClick,
      handleDownloadClick,
      handlePreviewClick,
      handleShareViaEmailClick,
      remindPendingContractParticipants,
    ]
  );

  const columnData = useMemo<ColumnDef<ZeltDocument, ZeltDocument>[]>(() => {
    const handleContractSignAction = async (row: ZeltDocument) => {
      // should trigger sign flow if missing recipient signature OR missing personal fields
      if (
        (row?.contract &&
          row.contract.id &&
          recipientSignatureRequired(row) &&
          !row.contract.recipientSignatureTimestamp &&
          row.contract?.recipient === state.user.userId) ||
        (row.contract?.hasMissingPersonalFields && row.contract?.recipient === state.user.userId)
      ) {
        const missingFields = await ContractAPI.getEmployeeFieldsForContractById(row?.contract.id);
        if (Object.values(missingFields)?.some((v) => v === true)) {
          // have to open modal in parent if there are missing fields to avoid cluttering document table component
          onMissingContractFields?.(missingFields, row.contract);
        } else {
          routerHistory.push(
            generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
              templateId: row.contract?.templateId as string,
              contractId: row.contract?.id as string,
              userId: row.contract?.recipient as number,
            })
          );
        }
      } else if (
        row?.contract &&
        row.contract.id &&
        companySignatureRequired(row) &&
        !row.contract.companySignatureTimestamp &&
        row.contract?.companySignatory === state.user.userId
      ) {
        const stateToPassThrough = {
          companySignatoryUserId: row.contract?.companySignatory as number,
        };
        routerHistory.push(
          generatePath(TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, {
            templateId: row.contract?.templateId as string,
            contractId: row.contract?.id as string,
            userId: row.contract?.companySignatory as number,
          }),
          stateToPassThrough
        );
      }
    };

    const actionsColumnLogic = (row: ZeltDocument, isEditableOrRemovable?: boolean) => {
      const { contract } = row;
      const menuOptionsForRow = getDocumentsWithAttachmentsMenuOptions(
        row,
        handleContractSignAction,
        isEditableOrRemovable
      );

      const hasFileUuid = !!row.fileUuid;
      const hasContract = row.contractId && contract;
      const hasAttachments =
        hasFileUuid || hasContract || (row.attachments && row.attachments.length && row.attachments[0]?.fileUuid);
      const hasNoContract = !hasContract;
      const isSignable =
        hasContract &&
        ((recipientSignatureRequired(row) &&
          !row.contract?.recipientSignatureTimestamp &&
          row.contract?.recipient === state?.user?.userId) ||
          (companySignatureRequired(row) &&
            !row.contract?.companySignatureTimestamp &&
            row.contract?.companySignatory === state?.user?.userId));
      const hasSignatories = contract?.signatoriesRequired && contract?.signatoriesRequired?.length > 0;
      const allPartiesHaveSigned = allPartiesHaveSignedContract(row);
      const isEditable = hasNoContract && isEditableOrRemovable;
      const fileUuidFromAttachment = row.attachments && row.attachments[0] && row.attachments[0]?.fileUuid;
      const finalUuid = row.fileUuid ?? fileUuidFromAttachment;
      const contractFinalised = !!(contract?.id && finalUuid);
      const currentUserIsRecipient = hasContract && state.user.userId === contract.recipient;
      const contractIsMissingInformation =
        hasContract && (contract?.hasMissingPersonalFields || contract?.hasMissingWorkFields);

      if (hasAttachments) {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              alignItems: 'center',
              gap: spacing.g10,
            }}
          >
            {hasSignatories && isSignable && !allPartiesHaveSigned && (
              <ButtonComponent
                sizeVariant="small"
                colorVariant="primary"
                onClick={() => handleContractSignAction(row)}
                title="Sign Contract"
              >
                {polyglot.t('DocumentTable.signContract')}
              </ButtonComponent>
            )}
            {contractIsMissingInformation &&
              !contractFinalised &&
              currentUserIsRecipient &&
              !!!recipientSignatureRequired(row) && (
                <ButtonComponent
                  sizeVariant="small"
                  colorVariant="primary"
                  onClick={() => handleContractSignAction(row)}
                  title="Fill missing"
                >
                  {polyglot.t('DocumentTable.fillMissingFields')}
                </ButtonComponent>
              )}
            <StyledMenuComponent
              options={menuOptionsForRow}
              actionButtonDetails={{
                type: 'iconButton',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'actions',
                icon: <ActionsSmall {...iconSize} />,
              }}
            />
          </Box>
        );
      } else if (isEditable && !hasFileUuid) {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              alignItems: 'center',
              gap: spacing.g10,
            }}
          >
            <ButtonComponent
              sizeVariant="small"
              colorVariant="primary"
              onClick={() => onEditClick(row)}
              title={polyglot.t('DocumentTable.upload')}
            >
              {polyglot.t('DocumentTable.upload')}
            </ButtonComponent>
            <StyledMenuComponent
              options={menuOptionsForRow}
              actionButtonDetails={{
                type: 'iconButton',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'actions',
                icon: <ActionsSmall {...iconSize} />,
              }}
            />
          </Box>
        );
      } else {
        return null;
      }
    };

    const belongsToColumnLogic = (row: ZeltDocument, hasScopesAll: boolean) => {
      return pipe(
        row.belongsTo ?? [],
        RNEA.fromReadonlyArray,
        O.fold(
          () =>
            row.canAllEmployeesSee ? (
              <span> {polyglot.t('DocumentTable.everyone')}</span>
            ) : (
              <span>{polyglot.t('DocumentTable.unassigned')}</span>
            ),
          (users) =>
            users.length === 1 ? (
              <div
                onClick={() => {
                  if (hasScopesAll)
                    routerHistory.push(
                      generatePath(ADMIN_USER_DOCUMENTS_ROUTE, { userId: users[0].User.userId ?? '' })
                    );
                }}
              >
                <UserCell userId={users[0].User.userId} />
              </div>
            ) : (
              <MultiUserAvatars currentUser={state.user} users={users} size="xxsmall" />
            )
        )
      );
    };

    const selectableDocumentIds = new Set(documents.map(({ id }) => id));

    return [
      ...(setSelectionModel && selectionModel && selectableDocumentIds.size > 0
        ? [
            {
              id: 'select',
              enableSorting: false,
              minSize: 20,
              maxSize: 20,
              header: () => {
                const allSelected =
                  selectionModel.length > 0 &&
                  selectionModel.length === selectableDocumentIds.size &&
                  selectionModel.every((id) => selectableDocumentIds.has(id));
                return (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name="allSelected"
                      checked={allSelected}
                      value="allSelected"
                      onChange={(_, checked) => {
                        setSelectionModel(checked ? [...selectableDocumentIds] : []);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }) =>
                selectableDocumentIds.has(original.id) ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
                    <Box onClick={(e) => e.stopPropagation()}>
                      <CheckboxComponent
                        label={undefined}
                        name={original.id?.toString() ?? ''}
                        checked={selectionModel.includes(original.id)}
                        value={original.id?.toString() ?? ''}
                        onChange={() => {
                          let finalArray: number[];
                          if (selectionModel?.includes(original.id)) {
                            finalArray = selectionModel.filter((sm) => sm !== original.id);
                          } else finalArray = [...selectionModel, original.id];
                          setSelectionModel(finalArray);
                        }}
                      />
                    </Box>
                  </Box>
                ) : null,
            } as ColumnDef<ZeltDocument, unknown>,
          ]
        : []),
      {
        header: () => polyglot.t('DocumentTable.name'),
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        maxSize: 250,
        minSize: 200,
        cell: (info) => {
          const rowValue: ZeltDocument = info.getValue();
          const isAlert = !!state?.alerts?.documents?.entries.find((doc) => doc?.id === (rowValue as ZeltDocument).id);
          return (
            <IconTextCell
              icon={isAlert ? <MissingDoc width={24} height={30} /> : <UploadDoc width={24} height={30} />}
              text={rowValue.name}
              textSx={themeFonts.caption}
            />
          );
        },
      },
      {
        header: () => polyglot.t('DocumentTable.type'),
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,
        maxSize: 120,
        minSize: 100,
        sortingFn: (a, b) => sortString(a, b, (item) => getDocumentTypeLabel(item, documentTypes)),
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          return <div>{getDocumentTypeLabel(info.getValue(), documentTypes)}</div>;
        },
      },
      {
        header: () => polyglot.t('DocumentTable.createdOn'),
        accessorFn: (row) => row,
        id: 'createdAt',
        enableSorting: true,
        maxSize: 100,
        minSize: 100,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const rowValue: ZeltDocument = info.getValue();
          return <div>{getDateString(rowValue.createdAt)}</div>;
        },
      },
      {
        header: () => polyglot.t('DocumentTable.belongsTo'),
        accessorFn: (row) => row,
        id: 'avatar',
        maxSize: 150,
        minSize: 150,
        enableSorting: false,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const row: ZeltDocument = info.getValue();
          const hasScopesAll = hasScopes(['documents:all'], scopesContext);
          return belongsToColumnLogic(row, hasScopesAll);
        },
      },

      {
        header: () => polyglot.t('DocumentTable.documentStatus'),
        accessorFn: (row) => row,
        id: 'documentStatus',
        maxSize: 110,
        minSize: 110,
        enableSorting: false,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const row: ZeltDocument = info.getValue();
          return <DisplayDocumentStatus document={row} />;
        },
      },

      {
        header: () => polyglot.t('DocumentTable.expiryDate'),
        accessorFn: (row) => row,
        id: 'expiryDate',
        maxSize: 100,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortNumeric(a, b, (item) =>
            item.expirySettings?.expiryDate ? new Date(item.expirySettings?.expiryDate).getTime() : 0
          ),
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const row: ZeltDocument = info.getValue();
          return row?.expirySettings?.documentExpires && row?.expirySettings?.expiryDate ? (
            <>{dateAPItoDisplay(row?.expirySettings?.expiryDate)}</>
          ) : (
            <EmptyCell />
          );
        },
      },
      //will be added later when designs come
      // {
      //   header: () => 'Verification',
      //   accessorFn: (row) => row,
      //   id: 'verification',
      //   enableSorting: false,
      //   cell: (info) => {
      //     const row: ZeltDocument = info.getValue();

      //     const hasScopesAll = hasScopes(['documents:all'], scopesContext);
      //     const belongsToNone = (row.belongsTo ?? []).length === 0;

      //     const isEditableOrRemovable = hasScopesAll || (row.type !== 'payslip' && !belongsToNone);
      //     const documentType = documentTypes.find((t) => t.value === row.type);

      //     return (
      //       <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'center', gap: 1 }}>
      //         {documentType?.needsVerification ? (
      //           row.documentVerification ? (
      //             <Tooltip
      //               title={`Verified by ${
      //                 row.documentVerification.User.displayName ??
      //                 `${row.documentVerification.User.firstName} ${row.documentVerification.User.lastName}`
      //               }`}
      //             >
      //               <Box sx={primarySx}>
      //                 <Chose {...iconSize} />
      //               </Box>
      //             </Tooltip>
      //           ) : isEditableOrRemovable && hasScopes(['documents:all']) && (row.attachments ?? []).length !== 0 ? (
      //             <IconButton sx={tableIconButtonSx} onClick={() => verify$.next(row)} title="Verify">
      //               <Chose {...iconSize} />
      //             </IconButton>
      //           ) : (
      //             <></>
      //           )
      //         ) : (
      //           <></>
      //         )}
      //       </Box>
      //     );
      //   },
      // },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        maxSize: 160,
        minSize: 100,
        cell: (info: CellContext<ZeltDocument, ZeltDocument>) => {
          const row: ZeltDocument = info.getValue();
          const hasScopesAll = hasScopes(['documents:all'], scopesContext);
          const hasScopesManager = checkScopes(state.user, ['documents'], scopesContext);
          const categoriesNotEditableByManager = ['Company Documents'];
          const belongsToNone = (row.belongsTo ?? []).length === 0;
          const documentCategoriesEditableByManager = documentTypes
            .filter((d) => !categoriesNotEditableByManager.includes(d.category))
            .map((d) => d.value);
          const documentCategoriesEditableByStandardUser = documentTypes
            .filter((eachDocumentType) => eachDocumentType.canUserAdd)
            .map((d) => d.value);
          const belongsToOnlyYou =
            row.belongsTo && row.belongsTo.length === 1 && row.belongsTo[0].User.userId === state.user.userId;
          const isEditableOrRemovable =
            hasScopesAll ||
            (!personalDocuments &&
              hasScopesManager &&
              [...documentCategoriesEditableByManager].includes(row.type) &&
              !belongsToNone) ||
            (documentCategoriesEditableByStandardUser.includes(row.type) && belongsToOnlyYou);

          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
              {actionsColumnLogic(row, isEditableOrRemovable)}
            </Box>
          );
        },
      },
    ];
  }, [
    documents,
    setSelectionModel,
    selectionModel,
    state.user,
    state?.alerts?.documents?.entries,
    onMissingContractFields,
    routerHistory,
    getDocumentsWithAttachmentsMenuOptions,
    polyglot,
    onEditClick,
    documentTypes,
    hasScopes,
    scopesContext,
    personalDocuments,
  ]);

  const customRowStyle = (row: Row<ZeltDocument>) => {
    const isAlert = !!state?.alerts?.documents?.entries.find((doc) => doc?.id === row.original.id);
    if (isAlert) return { color: themeColors.Grey };
    return {};
  };

  const tableDocuments =
    sorting && sorting.length > 0 && sorting[0]?.desc === false && documents
      ? [...documents].reverse()
      : [...documents];

  const getTableBasedOnProps = () => {
    return pagination && setPagination && sorting && setSorting ? (
      <BasicServerTable
        rowData={tableDocuments}
        // @ts-ignore
        columnData={columnData}
        sorting={sorting}
        setSorting={setSorting}
        pagination={pagination}
        setPagination={setPagination}
        totalPages={totalPages}
        totalItems={totalItems}
        loading={loading}
        customRowStyle={customRowStyle}
      />
    ) : (
      <BasicTable
        rowData={tableDocuments}
        columnData={columnData}
        loading={loading}
        customRowStyle={customRowStyle}
        paginationReset={paginationReset}
      />
    );
  };

  return (
    <>
      {getTableBasedOnProps()}
      {openShareViaEmailModal && (
        <ShareViaEmailDrawer
          selectedDocument={selectedDocument}
          isOpen={openShareViaEmailModal}
          setSelectedDocument={setSelectedDocument}
          setIsOpen={setOpenShareViaEmailModal}
        />
      )}
    </>
  );
};
