import { Box, Typography } from '@mui/material';
import { TimeSlot } from '@v2/feature/attendance/components/week-calendar.component';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

import './WeekView.css';

interface TimeSlotsInterface {
  index: number;
  rowId: string;
  slots: TimeSlot[];
  earliestStartHour: number;
  firstColumnLabel: string;
}

interface TableCalendarProps {
  header: {
    value: string;
    label: string;
  }[];
  isActiveHeader: (value: string) => boolean;
  renderTimeSlots: TimeSlotsInterface[];
  onDayClick?: (date: string) => void;
}

export const TableCalendar = ({ header, isActiveHeader, renderTimeSlots, onDayClick }: TableCalendarProps) => {
  return (
    <table className="week-view">
      <thead>
        <tr>
          <th></th>
          {header.map((headerObj, index) => {
            return (
              <th key={index} className="week-days">
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <Box
                    sx={{
                      backgroundColor: isActiveHeader(headerObj.value) ? themeColors.DarkGrey : themeColors.white,
                      paddingX: spacing.p5,
                      borderRadius: radius.br10,
                      width: 'fit-content',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        ...themeFonts.captionSmall,
                        color: isActiveHeader(headerObj.value) ? themeColors.white : themeColors.Grey,
                      }}
                    >
                      {headerObj.label}
                    </Typography>
                  </Box>
                </Box>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {renderTimeSlots.map((row: any) => (
          <tr key={`${row.index}-time`} id={row.rowId}>
            <td
              key={row.index}
              ref={(rowRef) =>
                rowRef &&
                row.index === row.earliestStartHour &&
                rowRef.scrollIntoView({ behavior: 'smooth', inline: 'start' })
              }
            >
              <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, zIndex: 2 }} className="slot-hour">
                {row.firstColumnLabel}
              </Typography>
            </td>
            {row.slots.map((slot: TimeSlot, index: number) => {
              return (
                <td
                  key={`${index}-slot`}
                  className="slot-td-placeholder"
                  onClick={() => {
                    if (onDayClick && slot?.dateObj?.value) onDayClick(slot.dateObj.value);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {slot.events.map((event, idx) => {
                    return event.isAbsence || event.hasAttendanceEntries || event.isScheduled ? (
                      <div
                        id={`item-half-${idx}`}
                        className="slot-absolute"
                        style={{
                          height: event.height,
                          boxShadow: event.showBoxShadow
                            ? `0 -2.5px 0 ${event.backgroundColor}, 0 2.5px 0 ${event.backgroundColor}`
                            : '',
                          top: event.top,
                          backgroundColor: event.backgroundColor,
                          borderRadius: event.borderRadius,
                          borderStyle: event.borderStyle,
                          borderWidth: event.borderWidth,
                          borderColor: event.borderColor,
                        }}
                      >
                        {(event.isAbsence || event.hasAttendanceEntries || event.isScheduled) && (
                          <Typography
                            sx={{
                              ...themeFonts.caption,
                              color: themeColors.DarkGrey,
                              p: spacing.p10,
                              position: 'relative',
                              zIndex: 1,
                              visibility: { xs: 'hidden', sm: 'hidden', md: 'visible', lg: 'visible', xl: 'visible' },
                            }}
                          >
                            {event.label}
                          </Typography>
                        )}
                      </div>
                    ) : null;
                  })}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
