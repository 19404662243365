import React, { useMemo, useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { TaskAPI, TaskEndpoints } from '@/v2/feature/task/task.api';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

export const SelectChecklistSchema = Yup.object().shape({
  checklistId: Yup.number().nullable().required(),
  checklistDate: Yup.string().test(dateFieldTest).required('deactivation date is required'),
});

export const SelectChecklistDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  refresh,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  refresh: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const { data: checklists } = useApiClient(TaskEndpoints.getChecklists(), {
    suspense: false,
  });

  const formik = useFormik({
    initialValues: {
      checklistId: '',
      checklistDate: new LocalDate().toDateString(),
    },
    enableReinitialize: false,
    validationSchema: SelectChecklistSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.checklistId) {
          await TaskAPI.launchChecklistById(Number(values.checklistId), {
            dueDate: values.checklistDate,
            assignedUserIds: [userId],
          });
        }
        showMessage(polyglot.t('SelectChecklistDrawer.successMessages.add'), 'success');
        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('SelectChecklistDrawer.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const checklistOptions = useMemo(() => {
    if (!checklists) return [];

    return checklists.map((c) => ({
      label: c.name ?? '',
      value: c.id,
    }));
  }, [checklists]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Typography variant="title2">{polyglot.t('SelectChecklistDrawer.select')}</Typography>

          <SelectComponent
            label={polyglot.t('SelectChecklistDrawer.select')}
            name="checklistId"
            options={checklistOptions}
            value={formik.values.checklistId}
            onChange={formik.handleChange}
          />

          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.checklistDate}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('checklistDate', value);
                }
              }}
              name="checklistDate"
              label={polyglot.t('SelectChecklistDrawer.checklistDate')}
              error={Boolean(!!formik.errors.checklistDate && formik.touched.checklistDate)}
              helperText={(formik.touched.checklistDate && formik.errors.checklistDate) as string}
            />
          </FormControl>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name={polyglot.t('SelectChecklistDrawer.add')}
              loading={loading}
              fullWidth
              colorVariant="primary"
              sizeVariant="medium"
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
