import { Checkbox, Dialog, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { JumpCloudCreateUserParametersDto } from '@/v2/feature/app-integration/apps/jumpcloud/jumpcloud.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type Props = {
  onComplete(params: JumpCloudCreateUserParametersDto): void;
  onClose(): void;
  username?: string;
};

interface CreateUserFormData {
  sendInviteEmail: boolean;
  username: string;
}

const CreateUserFormSchema = Yup.object().shape({
  sendInviteEmail: Yup.boolean(),
  username: Yup.string()
    .required()
    .matches(/^[a-z][a-z0-9-._]{0,29}$/i, {
      message:
        // taken from JumpCloud's own website
        `username must start with a letter, be 30 characters or less, and contain only letters, numbers - . and _`,
    }),
});

export const JumpCloudNewUserParams = ({ username, onClose, onComplete }: Props): JSX.Element => {
  const formik = useFormik<CreateUserFormData>({
    initialValues: {
      sendInviteEmail: false,
      username: (username || '')
        .replace(/[^a-z0-9-._]+/gi, '-') // replace invalid chars
        .replace(/^[^a-z]+/i, '') // remove non-alpha at start
        .slice(0, 30), // limit length
    },
    validationSchema: CreateUserFormSchema,
    onSubmit: (values) => {
      onComplete({
        type: 'jumpcloud',
        username: values.username,
        sendInviteEmail: values.sendInviteEmail,
      });
    },
  });

  return (
    <Dialog open sx={{ px: 8 }} onClose={onClose} maxWidth="sm">
      <DialogContent>
        <FormikProvider value={formik}>
          <Stack sx={{ width: '300px' }}>
            <Typography sx={{ ...themeFonts.title4, mb: spacing.m10, color: themeColors.DarkGrey }}>
              JumpCloud User
            </Typography>
            <TextfieldComponent
              name="username"
              type="text"
              label="Username"
              size="small"
              onChange={formik.handleChange}
              value={formik.values.username}
              error={!!formik.errors.username}
              helperText={(formik.touched.username && formik.errors.username) ?? ''}
              clearText={() => formik.setFieldValue('username', '')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="sendInviteEmail"
                  onChange={formik.handleChange}
                  checked={formik.values.sendInviteEmail}
                />
              }
              label={
                <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                  Send a JumpCloud invitation email
                </Typography>
              }
            />
          </Stack>
          <Stack direction="row" justifyContent="center" gap={spacing.m10} sx={{ mt: spacing.m25 }}>
            <ButtonComponent
              type="submit"
              sizeVariant="small"
              colorVariant="primary"
              onClick={() => formik.handleSubmit()}
            >
              Give access
            </ButtonComponent>
            <ButtonComponent type="button" sizeVariant="small" colorVariant="secondary" onClick={onClose}>
              Cancel
            </ButtonComponent>
          </Stack>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
