import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { ScheduleTrackingTimesheetType } from '@v2/feature/attendance/attendance.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { LocalDate } from '@v2/util/local-date';

import { ReactComponent as ClockIcon } from '@/images/side-bar-icons/TimeAway.svg';
import { AttendanceActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/attendance-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import {
  SubmittedAttendanceBigWidget,
  SubmittedAttendanceTodos,
} from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

export const SubmittedAttendanceTodo = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: SubmittedAttendanceBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<SubmittedAttendanceTodos | undefined>(undefined);

  const weeklyAttendancesByUserByWeek = useMemo(() => {
    return todo.attendance?.filter((attend) => attend.timesheetType === ScheduleTrackingTimesheetType.Weekly);
  }, [todo.attendance]);

  const dailyAttendances = useMemo(() => {
    return todo.attendance?.filter((attend) => attend.timesheetType === ScheduleTrackingTimesheetType.Daily);
  }, [todo.attendance]);

  return (
    <Box>
      {weeklyAttendancesByUserByWeek.map((attendance, idx) => {
        return (
          <TodoCard
            key={`attendance-${attendance.userId}-${attendance.weekNo}-${idx}`}
            icon={<ClockIcon {...iconSize} />}
            title={
              <Typography
                variant="caption"
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {polyglot.t('SubmittedAttendanceTodo.waitingApprovalFromandWeek', {
                  user: getCachedUserById(attendance.userId)?.displayName,
                  weekNum: attendance.weekNo,
                })}
              </Typography>
            }
            actionOnclick={() => {
              setSelectedRow(attendance);
            }}
            showBorder={!(setLastChild && todo.attendance.length === idx + 1 && dailyAttendances.length === 0)}
            userAvatar={<UserAvatar userId={Number(attendance.userId)} size="xxxsmall" />}
          />
        );
      })}

      {dailyAttendances.map((attendance, idx) => {
        const title = `${getCachedUserById(attendance.userId)?.displayName}, ${new LocalDate(
          attendance.logDate
        ).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
        })}`;

        return (
          <TodoCard
            key={`attendance-${attendance.id}`}
            icon={<ClockIcon {...iconSize} />}
            title={
              <Typography
                variant="caption"
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {polyglot.t('SubmittedAttendanceTodo.waitingApprovalFrom', {
                  title: title,
                })}
              </Typography>
            }
            actionOnclick={() => {
              setSelectedRow(attendance);
            }}
            showBorder={!(setLastChild && todo.attendance.length === idx + 1)}
            userAvatar={<UserAvatar userId={attendance.userId} size="xxxsmall" />}
          />
        );
      })}
      {selectedRow && (
        <AttendanceActionItem
          attendanceActionRow={selectedRow}
          refresh={refreshTodos}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
