import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthScalePointFormModal } from '@/v2/feature/growth/growth-scale/components/growth-scale-point-form-modal.component';
import { GrowthScaleAPI } from '@/v2/feature/growth/growth-scale/growth-scale.api';
import { GrowthScale, GrowthScalePoint } from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface GrowthScaleFormModalProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly afterClose: () => void;
  readonly growthScale: GrowthScale | undefined;
  readonly refresh: () => Promise<void>;
}

interface GrowthScaleFormContentProps {
  readonly onClose: () => void;
  readonly growthScale: GrowthScale | undefined;
  readonly refresh: () => Promise<void>;
}
export const GrowthScaleFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  growthScale,
  refresh,
}: GrowthScaleFormModalProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose} afterClose={afterClose}>
      <GrowthScaleFormContent onClose={onClose} growthScale={growthScale} refresh={refresh} />
    </DrawerModal>
  );
};

const useScaleModalForm = (
  growthScale: GrowthScale | undefined,
  setLoading: Dispatch<SetStateAction<boolean>>,
  onClose: () => void,
  refresh: () => Promise<void>
) => {
  const [showMessage] = useMessage();

  const initialValues = growthScale
    ? {
        type: growthScale.type,
        points: growthScale.points,
        value: growthScale.value,
        sentiments: growthScale.sentiments,
      }
    : {
        type: '',
        points: {},
        value: {},
        sentiments: {},
      };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      type: yup.string().nullable().required('Name is required'),
      points: yup.object().test('len', 'Points should have at least one entry', (value) => {
        return value && Object.keys(value).length > 0;
      }),
      value: yup.object().test('len', 'value should have at least one entry', (value) => {
        return value && Object.keys(value).length > 0;
      }),
      sentiments: yup.object().test('len', 'Sentiments should have at least one entry', (value) => {
        return value && Object.keys(value).length > 0;
      }),
    }),
    enableReinitialize: true,
    onSubmit: async (values: Pick<GrowthScale, 'type' | 'points' | 'value' | 'sentiments'>) => {
      setLoading(true);
      try {
        if (growthScale) {
          const updateObject = { id: growthScale.id, ...values };
          await GrowthScaleAPI.updateScale(updateObject);
          showMessage('Scale updated successfully', 'success');
        } else {
          const createObject = { ...values };
          await GrowthScaleAPI.createScale(createObject);
          showMessage('Scale created successfully', 'success');
        }
        await refresh();
        onClose();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return formik;
};
const GrowthScaleFormContent = ({ onClose, growthScale, refresh }: GrowthScaleFormContentProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [selectedScalePoint, setSelectedScalePoint] = useState<string | undefined>(undefined);

  const formik = useScaleModalForm(growthScale, setLoading, onClose, refresh);

  const removePoint = (index: string) => {
    const { [index]: _, ...remainingPoints } = formik.values.points;
    const { [index]: __, ...remainingValues } = formik.values.value;
    const { [index]: ___, ...remainingSentiments } = formik.values.sentiments;

    formik.setFieldValue('points', remainingPoints);
    formik.setFieldValue('value', remainingValues);
    formik.setFieldValue('sentiments', remainingSentiments);
    formik.validateForm();
  };

  const addPoint = useCallback(
    (values: GrowthScalePoint) => {
      formik.setFieldValue(`points.${selectedScalePoint}`, values.points);
      formik.setFieldValue(`value.${selectedScalePoint}`, values.value);
      formik.setFieldValue(`sentiments.${selectedScalePoint}`, values.sentiments);
      formik.validateForm();
    },
    [formik, selectedScalePoint]
  );

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{growthScale ? `Edit ${growthScale.type}` : 'New scale type'}</Typography>

        <TextfieldComponent
          name="type"
          label="Name"
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && !!formik.errors.type}
          helperText={(formik.touched.type && formik.errors.type) ?? ' '}
          endAdornment="none"
        />

        <Typography variant="caption">Scale points</Typography>

        {formik.values.points &&
          Object.entries(formik.values.points) &&
          Object.entries(formik.values.points).length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {Object.entries(formik.values.points).map(([key, value], idx) => (
                <Box
                  key={key}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                    paddingY: spacing.p8,
                    borderBottom: borders.light,
                    ':hover': {
                      bgcolor: themeColors.TableHover,
                    },
                  }}
                  onClick={(e) => {
                    setSelectedScalePoint(key);
                    setShowAdd(true);
                    e.stopPropagation();
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g4 }}>
                    <Typography variant="captionSmall" color="Grey">
                      Point {idx + 1}
                    </Typography>
                    <Typography variant="caption" color="DarkGrey">
                      {value}
                    </Typography>
                  </Box>

                  <IconButton
                    colorVariant="secondary"
                    sizeVariant="small"
                    onClick={(e) => {
                      removePoint(key);
                      e.stopPropagation();
                    }}
                  >
                    <Trash {...iconSize} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        <Box>
          <ButtonComponent
            colorVariant="secondary"
            sizeVariant="small"
            onClick={() => {
              const newIndex = Object.keys(formik.values.points).length.toString();
              setSelectedScalePoint(newIndex);
              setShowAdd(true);
            }}
          >
            Add point
          </ButtonComponent>
        </Box>
        {(formik.errors.points || formik.errors.value || formik.errors.sentiments) &&
          formik.submitCount > 0 &&
          formik.values.points &&
          Object.keys(formik.values.points || {}).length < 1 && (
            <Box>
              <Typography variant="captionSmall" color="darkRed">
                Scale must have at least one scale point
              </Typography>
            </Box>
          )}

        <Box sx={buttonBoxDrawerSx}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8, width: '100%' }}>
            <LoaderButton
              name={polyglot.t('General.save')}
              sizeVariant="medium"
              loading={loading}
              colorVariant="primary"
              fullWidth
            />
          </Box>
        </Box>

        <GrowthScalePointFormModal
          isOpen={showAdd}
          setIsOpen={setShowAdd}
          onClose={() => {
            setShowAdd(false);
          }}
          afterClose={() => {
            setSelectedScalePoint(undefined);
          }}
          growthScalePoint={selectedScalePoint}
          growthScale={formik.values || undefined}
          addPoint={addPoint}
        />
      </Form>
    </FormikProvider>
  );
};
