import { SxProps, Theme } from '@mui/material';

import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { radius } from '@/v2/styles/radius.styles';

export const warningCaptionSmallStyledChipSx: SxProps<Theme> = {
  padding: '4px 10px',
  minHeight: '30px',
  minWidth: '30px',
  border: themeColors.grey,
  borderRadius: radius.br15,
  boxSizing: 'border-box',
  ...themeFonts.captionSmall,
  color: themeColors.RedDark,
  backgroundColor: themeColors.white,
  textTransform: 'initial',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  '&.MuiButton-root:hover': {
    backgroundColor: themeColors.GreyHover,
  },
};

export const normalCaptionSmallStyledChipSx: SxProps<Theme> = {
  padding: '4px 10px',
  minHeight: '30px',
  minWidth: '30px',
  border: themeColors.grey,
  borderRadius: radius.br15,
  boxSizing: 'border-box',
  ...themeFonts.captionSmall,
  color: themeColors.grey,
  backgroundColor: themeColors.white,
  textTransform: 'initial',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  '&.MuiButton-root:hover': {
    backgroundColor: themeColors.GreyHover,
  },
};

export const styledChipSx: SxProps<Theme> = {
  padding: '5px 10px',
  minHeight: '30px',
  minWidth: '30px',
  border: 'none',
  borderRadius: radius.br15,
  boxSizing: 'border-box',
  ...themeFonts.caption,
  color: themeColors.DarkGrey,
  backgroundColor: themeColors.Background,
  textTransform: 'initial',
  whiteSpace: 'nowrap',
  '&.MuiButton-root:hover': {
    backgroundColor: themeColors.GreyHover,
  },
};

export const styledChipSelectedSx: SxProps<Theme> = {
  ...styledChipSx,
  color: themeColors.white,
  backgroundColor: themeColors.black,
  '&.MuiButton-root:hover': {
    backgroundColor: themeColors.DarkGrey,
  },
};
