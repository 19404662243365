import { Checkbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';

export const StyledFilterMenuCheckbox = styled(Checkbox)<CheckboxProps>(() => ({
  '&.MuiButtonBase-root.MuiCheckbox-root': {
    padding: '0px !important',
    marginLeft: '0px !important',
    '&.Mui-checked': {
      color: themeColors.DarkGrey,
    },
    '& .MuiSvgIcon-fontSizeMedium': {
      width: '16px',
      height: '16px',
      borderRadius: radius.br5,
    },
  },
}));

//moving away from MUI
export const StyledFormCheckbox = styled(Checkbox)<CheckboxProps>(() => ({
  '&.MuiButtonBase-root.MuiCheckbox-root': {
    padding: '0px !important',
    marginLeft: '0px !important',
    borderRadius: radius.br5,
    '&.Mui-checked': {
      color: themeColors.DarkGrey,
    },
    '& .MuiSvgIcon-fontSizeMedium': {
      width: '18px',
      height: '18px',
      borderRadius: radius.br5,
    },
  },
}));

export const StyledCheckboxStyle = styled('input')(() => ({
  display: 'inline-block',
  padding: '0px !important',
  margin: '0px !important',
  width: '16px',
  height: '16px',
  visibility: 'hidden',
  position: 'absolute',
}));
