import { useEffect } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  FirewallOptions,
} from '@/v2/feature/device/device.dto';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmFirewallPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): FirewallOptions => {
    return {
      EnableFirewall: configurablePoliciesSelected?.configurableFirewallPolicy?.EnableFirewall ?? false,
      EnableStealthMode: configurablePoliciesSelected?.configurableFirewallPolicy?.EnableStealthMode ?? false,
      BlockAllIncoming: configurablePoliciesSelected?.configurableFirewallPolicy?.BlockAllIncoming ?? false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FIREWALL_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: FirewallOptions = getPolicy();
  function analyzePolicyBeforeSave(policy: FirewallOptions) {
    let allNulls = true;
    for (const key in policy) {
      if (key !== 'PayloadIdentifier' && (policy as Record<string, boolean>)[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return undefined;
    }
    return {
      EnableFirewall: policy.EnableFirewall,
      EnableStealthMode: policy.EnableStealthMode,
      BlockAllIncoming: policy.BlockAllIncoming,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FIREWALL_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }
  const formik = useFormik<FirewallOptions>({
    initialValues,
    onSubmit: async (form: FirewallOptions) => {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableFirewallPolicy: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="title2">Firewall</Typography>

      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20, mt: '20px' }}>
          <CheckboxComponent
            label="Enable Firewall"
            name="EnableFirewall"
            checked={formik.values.EnableFirewall}
            onChange={(e, checked) => {
              formik.setFieldValue('EnableFirewall', checked);
            }}
          />
          <CheckboxComponent
            label="Block all incoming"
            name="BlockAllIncoming"
            checked={formik.values.BlockAllIncoming}
            onChange={(e, checked) => {
              formik.setFieldValue('BlockAllIncoming', checked);
            }}
          />
          <CheckboxComponent
            label="Enable stealth mode"
            name="EnableStealthMode"
            checked={formik.values.EnableStealthMode}
            onChange={(e, checked) => {
              formik.setFieldValue('EnableStealthMode', checked);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: '30px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
