import { SxProps, Box, Theme } from '@mui/material';

import {
  StyledTabs,
  StyledTab,
} from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { StyledBadge } from '@/v2/styles/badge.styles';

type UserImportResultsTabsProps = {
  tabs: readonly string[];
  currentTab?: string;
  onChange?: (tab: string) => void;
  sx?: SxProps<Theme>;
  errorCountByTab: Record<string, number>;
};

export const UserImportResultsTabs = ({
  tabs,
  currentTab,
  onChange,
  sx,
  errorCountByTab,
}: UserImportResultsTabsProps) => {
  return (
    <Box sx={sx}>
      <StyledTabs
        value={currentTab || false}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={(_, value) => {
          onChange?.(value);
        }}
      >
        {tabs.map((label: string) => (
          <StyledTab
            key={label}
            disableRipple
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  textTransform: 'inherit',
                  marginRight: 10,
                  marginLeft: 10,
                }}
              >
                <span>{label}</span>
                {errorCountByTab[label] > 0 ? (
                  <StyledBadge sx={{ mx: '5px' }} badgeContent={errorCountByTab[label]} color="error" />
                ) : (
                  <></>
                )}
              </div>
            }
            value={label}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};
