import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { AttendanceAPI } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { ScheduleApprovalFormData } from '@v2/feature/attendance/attendance.interface';
import { ScheduleApprovalForm } from '@v2/feature/attendance/company/components/form/schedule-approval-form.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface AttendanceScheduleEditApprovalDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
}

export const AttendanceScheduleEditApprovalDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh,
  approvalRules,
  refreshApprovalRules,
}: AttendanceScheduleEditApprovalDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AttendanceScheduleEditApprovalDrawerContent
        attendanceSchedule={attendanceSchedule}
        refresh={refresh}
        approvalRules={approvalRules}
        setIsOpen={setIsOpen}
        refreshApprovalRules={refreshApprovalRules}
      />
    </DrawerModal>
  );
};

interface AttendanceScheduleEditApprovalDrawerContentProps {
  readonly attendanceSchedule: AttendanceScheduleDto;
  readonly refresh: () => Promise<void>;
  readonly approvalRules: readonly ApprovalRuleDto[];
  readonly refreshApprovalRules: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AttendanceScheduleEditApprovalDrawerContent = ({
  attendanceSchedule,
  refresh,
  approvalRules,
  refreshApprovalRules,
  setIsOpen,
}: AttendanceScheduleEditApprovalDrawerContentProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const onSubmit = useCallback(
    async (values: ScheduleApprovalFormData) => {
      try {
        setLoading(true);
        await AttendanceAPI.updateAttendanceScheduleApproval(attendanceSchedule.id, values);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('AttendanceScheduleEditApprovalDrawerContent.errorMessages.update', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [showMessage, refresh, setIsOpen, attendanceSchedule.id, polyglot]
  );

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{polyglot.t('ApprovalPage.approval')}</Typography>
      <ScheduleApprovalForm
        attendanceSchedule={attendanceSchedule}
        approvalRules={approvalRules}
        onSubmit={onSubmit}
        loading={loading}
        buttonName={polyglot.t('General.save')}
        refreshApprovalRules={refreshApprovalRules}
      />
    </Box>
  );
};
