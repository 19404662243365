import React, { useCallback, useContext } from 'react';

import { Box } from '@mui/material';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { KeyedMutator } from 'swr';
import * as Yup from 'yup';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { UserAPI } from '@/v2/feature/user/user.api';
import { PersonalSettingsProps } from '@/v2/feature/user/user.interface';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

interface Props {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: PersonalSettingsProps | null | undefined;
  refresh: KeyedMutator<PersonalSettingsProps> | undefined;
}

interface UserLocaleSettingsDrawerContentProps {
  initialValues: PersonalSettingsProps | null | undefined;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: KeyedMutator<PersonalSettingsProps> | undefined;
}

export enum LanguageOption {
  en = 'en',
  ar = 'ar',
  es = 'es',
  fr = 'fr',
  de = 'de',
  pt = 'pt',
  it = 'it',
}

export const languageOptions: OptionObject[] = [
  { value: LanguageOption.en, label: 'English' },
  { value: LanguageOption.ar, label: 'العربية' },
  { value: LanguageOption.es, label: 'Español (Google Translate)' },
  { value: LanguageOption.fr, label: 'Français (Google Translate)' },
  { value: LanguageOption.de, label: 'Deutsch (Google Translate)' },
  { value: LanguageOption.pt, label: 'Português (Google Translate)' },
  { value: LanguageOption.it, label: 'Italiano (Google Translate)' },
];

const UserLocaleSettingsSchema = Yup.object().shape({
  language: Yup.string()
    .oneOf([...Object.values(LanguageOption), null])
    .nullable()
    .required(),
});

export const UserLocaleSettingsDrawer = ({ isOpen, setIsOpen, initialValues, refresh }: Props): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <UserLocaleSettingsDrawerContent initialValues={initialValues} setIsOpen={setIsOpen} refresh={refresh} />
    </DrawerModal>
  );
};

const UserLocaleSettingsDrawerContent = ({
  initialValues,
  setIsOpen,
  refresh,
}: UserLocaleSettingsDrawerContentProps) => {
  const { polyglot, changeLocale } = usePolyglot();
  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);

  const refreshAuthUser = useCallback(async () => {
    const response = await AuthAPI.getAuthMe(false);
    const authUser = response?.user ?? null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser,
    });
    if (authUser.language) {
      changeLocale(authUser.language);
    }
  }, [dispatch, changeLocale]);

  const formik = useFormik({
    initialValues: { language: initialValues ? initialValues.language : 'en' },
    enableReinitialize: true,
    validationSchema: UserLocaleSettingsSchema,
    onSubmit: async (values) => {
      try {
        await UserAPI.patchUserLocaleSettings(values);
        showMessage(polyglot.t('UserLocaleSettingsDrawerContent.successMessages.update'), 'success');
        await refreshAuthUser();
        await refresh?.();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('UserLocaleSettingsDrawerContent.errorMessages.encounter', {
            errorMessage: nestErrorMessage(error),
          }),
          'error'
        );
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('UserLocaleSettingsDrawerContent.edit')}</Typography>

        <SelectComponent
          name="language"
          label={polyglot.t('UserLocaleSettingsDrawerContent.label')}
          options={languageOptions}
          value={formik.values.language}
          compareValue={formik.values.language}
          error={!!formik.errors.language && formik.touched.language}
          onChange={formik.handleChange}
          helperText={formik.touched.language && formik.errors.language}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={false}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
