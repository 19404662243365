import React from 'react';

import { Box, Typography } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { IconContentRadioCard } from '@v2/components/theme-components/icon-content-radio-card.component';
import { StyledRadio } from '@v2/components/theme-components/styled-radio.component';
import { PensionProvidersOptions } from '@v2/feature/benefits/subfeature/pension/pension.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { NetPayArrangement, PensionProviders, PensionRulesOptions, ReliefAtSource } from '@/lib/pensions';

interface SelectPensionProviderStepProps {
  onNext: () => void;
  pensionProvider: string;
  setPensionProvider: React.Dispatch<React.SetStateAction<string>>;
  setPensionRule: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectPensionProviderStep = ({
  onNext,
  pensionProvider,
  setPensionProvider,
  setPensionRule,
}: SelectPensionProviderStepProps) => {
  const { polyglot } = usePolyglot();

  return (
    <Box sx={{ margin: 'auto', mt: spacing.mt10, width: '400px' }}>
      <Typography sx={{ ...themeFonts.title2, mb: spacing.mb30, color: themeColors.DarkGrey }}>
        {polyglot.t('PensionModule.selectYourProvider')}
      </Typography>
      {PensionProvidersOptions.map((provider) => (
        <IconContentRadioCard
          cardOnClick={() => {
            setPensionProvider(provider.value);

            if (provider.value === PensionProviders.Nest) setPensionRule(ReliefAtSource.value);
            else if (provider.value === PensionProviders.SmartPension) setPensionRule(NetPayArrangement.value);
            else setPensionRule(PensionRulesOptions[0].value);
          }}
          title={provider.label}
          content={null}
          iconMedia={provider.icon}
          action={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <StyledRadio
                name={provider.label}
                id={provider.value}
                value={provider.value}
                selectedValue={pensionProvider}
              />
            </Box>
          }
          showDivider
          sx={{ minHeight: '40px', maxHeight: '40px' }}
        />
      ))}

      <Box sx={{ ...buttonBoxSx, mt: spacing.mt40 }}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          fullWidth
          onClick={onNext}
          disabled={!pensionProvider}
        >
          {polyglot.t('PensionModule.selectProvider')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};
