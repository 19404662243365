import { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { ONBOARDING_ROUTE, ONBOARDING_USER_STATUS_ROUTE } from '@/lib/routes';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceModelDto } from '@/v2/feature/device/device.dto';
import { DeviceStoreView } from '@/v2/feature/device/features/devices-company/pages/device-store.page';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

export const OnboardingDeviceOrderPage = () => {
  const params = useParams<{ userId: string }>();
  const routerHistory = useHistory();
  const [cart, setCart] = useState<DeviceModelDto[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();

  const userId = Number(params.userId);

  useEffect(() => {
    if (cart.length) {
      setIsOpen(true);
    }
  }, [cart]);

  useEscapeKey(() => routerHistory.goBack());

  const user = userId && getCachedUserById(userId);

  if (!user) {
    routerHistory.push(ONBOARDING_ROUTE);
    return <></>;
  }

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, ml: spacing.ml40 }}>Choose the device</Typography>}
        showBack
      />
      <Stack sx={{ overflow: 'auto', mt: '100px' }}>
        <DeviceStoreView
          cartState={[cart, setCart]}
          orderFormOpen={isOpen}
          reach={'company'}
          onClose={async (orderComplete) => {
            if (orderComplete) {
              routerHistory.replace(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: user.userId }));
              return;
            }
            setCart([]);
          }}
          possessionId={userId}
          sx={{ mx: '140px', my: spacing.my20 }}
        />
      </Stack>
    </Stack>
  );
};
