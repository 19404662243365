import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { EditableUserPayrollDto, UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { EditUserPayrollNonUK } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll-non-uk.page';
import { EditUserPayrollUKPage } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll-uk.page';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { ApiError } from '@/v2/infrastructure/api-error/api-error.interface';
import { getApiErrorMessage } from '@/v2/infrastructure/api-error/api-error.util';
import { isUKCountryCode } from '@/v2/infrastructure/country/country.util';

export type PayrollRecordChangeMode =
  | 'initial' // we are creating the first payroll record for a user
  | 'append' // we are adding a new entry to record a change
  | 'edit'; // we are editing an existing record

interface Props {
  readonly userId: number;
  readonly mode: PayrollRecordChangeMode;
  readonly record: UserPayrollDto | null;
  readonly close: () => void;
  readonly isUserUpdating: boolean;
  readonly requireReason?: boolean;
  onUpdateStarted?: () => void;
  onUpdateFinished?: (success: boolean) => void;
}

export const EditUserPayrollPage = ({
  userId,
  mode,
  record,
  close,
  isUserUpdating,
  requireReason,
  onUpdateStarted,
  onUpdateFinished,
}: Props) => {
  const { polyglot } = usePolyglot();

  const [payrollSchema, setPayrollSchema] = useState<PayrollSchema | null>();
  const { payrollId, payrollValues, remotePayrollValues, inPayroll } = record ?? {};

  // only use userPayrollId, effectiveDate and changeReason if we are editing an existing record
  const { userPayrollId, effectiveDate, changeReason } = (mode === 'edit' && record) || {};

  const [showMessage] = useMessage();

  const isUKPayroll = useMemo(() => {
    if (mode === 'edit') {
      return record ? isUKCountryCode(record.countryCode) : undefined;
    }
    // if this is the initial record or we are creating a new entry, use the payrollSchema associated with the current contract
    if (payrollSchema === null) return true; // payrollSchema is null for UK payroll
    if (!payrollSchema) return undefined;
    return isUKCountryCode(payrollSchema.countryCode);
  }, [mode, payrollSchema, record]);

  useEffect(() => {
    if (record?.countryCode) {
      // if we're editing a record, we must use whatever schema the record is for
      PayrollAPI.getPayrollSchemaForCountry(record.countryCode).then(setPayrollSchema);
    } else {
      PayrollAPI.getUserPayrollSchema(userId).then(setPayrollSchema);
    }
  }, [userId, record?.countryCode]);

  const savePayrollRecord = useCallback(
    async (record: EditableUserPayrollDto) => {
      let userUpdated = false;
      onUpdateStarted?.();
      try {
        userUpdated =
          mode === 'append' || mode === 'initial'
            ? (await PayrollAPI.addUserPayrollRecord(userId, record), true)
            : (await PayrollAPI.updateUserPayrollRecord(userId, record), true);
        showMessage(polyglot.t('EditUserPayrollPage.successMessages.update'), 'success');
      } catch (error) {
        showMessage(
          `${polyglot.t('EditUserPayrollPage.errorMessages.update')}. ${getApiErrorMessage(error as ApiError)}`,
          'warning'
        );
      } finally {
        onUpdateFinished?.(userUpdated);
      }
      return userUpdated;
    },
    [polyglot, mode, onUpdateFinished, onUpdateStarted, showMessage, userId]
  );

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">
        {
          {
            initial: polyglot.t('EditUserPayrollPage.initial'),
            edit: polyglot.t('EditUserPayrollPage.edit'),
            append: polyglot.t('EditUserPayrollPage.append'),
          }[mode]
        }
      </Typography>

      <Stack gap={spacing.sm}>
        {!inPayroll && <Typography variant="captionSmall">{polyglot.t('EditUserPayrollPage.notInPayroll')}</Typography>}
        <UserCell userId={userId} nameVariant="title4" />
      </Stack>

      {isUKPayroll && (
        <EditUserPayrollUKPage
          payrollId={payrollId}
          payrollValues={payrollValues}
          effectiveDate={effectiveDate}
          changeReason={changeReason}
          savePayrollRecord={savePayrollRecord}
          close={close}
          isUserUpdating={isUserUpdating}
          requireReason={requireReason}
          userPayrollId={userPayrollId}
        />
      )}
      {!isUKPayroll && payrollSchema && (
        <EditUserPayrollNonUK
          payrollId={payrollId}
          payrollSchema={payrollSchema}
          remotePayrollValues={remotePayrollValues}
          effectiveDate={effectiveDate}
          changeReason={changeReason}
          savePayrollRecord={savePayrollRecord}
          close={close}
          isUserUpdating={isUserUpdating}
          requireReason={requireReason}
          userPayrollId={userPayrollId}
        />
      )}
    </Box>
  );
};
