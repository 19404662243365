import React, { Suspense, useCallback, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { HolidayCalendarAPI } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api';
import {
  HolidayDto,
  UpsertHoliday,
} from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

const validationSchema = (polyglot: Polyglot) =>
  yup.object({
    name: yup.string().required(polyglot.t('validation.requiredField')),
    date: yup.string().test(dateFieldTest).required(polyglot.t('validation.requiredField')),
    isRecurring: yup
      .boolean()
      .typeError(polyglot.t('validation.selectValid'))
      .required(polyglot.t('validation.requiredField')),
  });

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly calendarId: string;
  readonly holiday?: HolidayDto | null;
  readonly refresh: () => Promise<void>;
  readonly onClose?: () => void;
}

export const HolidayDrawer = ({ isOpen, setIsOpen, calendarId, holiday, refresh }: DrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
          />
        }
      >
        <HolidayDrawerContent calendarId={calendarId} holiday={holiday} refresh={refresh} setIsOpen={setIsOpen} />
      </Suspense>
    </DrawerModal>
  );
};

interface DrawerContentProps {
  readonly calendarId: string;
  readonly holiday?: HolidayDto | null;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly refresh: () => Promise<void>;
}

const HolidayDrawerContent = ({ calendarId, holiday, refresh, setIsOpen }: DrawerContentProps) => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const upsertHoliday = useCallback(
    async (values: UpsertHoliday) => {
      try {
        setLoading(true);
        const data: UpsertHoliday = { name: values.name, date: values.date, isRecurring: values.isRecurring };

        if (holiday) await HolidayCalendarAPI.updateCalendarHoliday(calendarId, holiday.id, data);
        else await HolidayCalendarAPI.addHolidayToCalendar(calendarId, data);

        await refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
      setLoading(false);
    },
    [calendarId, showMessage, polyglot, refresh, holiday, setIsOpen]
  );

  const formik = useFormik<UpsertHoliday>({
    initialValues: {
      name: holiday?.name ?? '',
      date: holiday?.date ?? '',
      isRecurring: holiday?.isRecurring ?? true,
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: upsertHoliday,
  });

  const deleteHoliday = useCallback(async () => {
    if (!holiday) return;

    try {
      await HolidayCalendarAPI.deleteCalendarHoliday(calendarId, holiday.id);
      await refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
  }, [showMessage, polyglot, refresh, calendarId, holiday, setIsOpen]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="title2">
            {holiday ? polyglot.t('HolidayCalendarModule.holiday') : polyglot.t('HolidayCalendarModule.newHoliday')}
          </Typography>
          {holiday && (
            <Box>
              <IconButton sx={tableIconButtonSx} onClick={deleteHoliday}>
                <TrashIcon {...iconSize} />
              </IconButton>
            </Box>
          )}
        </Box>

        <TextfieldComponent
          label={polyglot.t('HolidayCalendarModule.holidayName')}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && (formik.errors.name as string)}
          fullWidth
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="title4">{polyglot.t('HolidayCalendarModule.recurringAnnually')}</Typography>
          <SwitchComponent
            checked={formik.values.isRecurring}
            name="is-recurring"
            onChange={(_e, enabled) => {
              formik.setFieldValue('isRecurring', enabled);
            }}
          />
        </Box>

        <Typography variant="caption">
          {formik.values.isRecurring
            ? polyglot.t('HolidayCalendarModule.isRecurringDesc')
            : polyglot.t('HolidayCalendarModule.isNotRecurringDesc')}
        </Typography>

        <DatePickerComponent
          name="date"
          label={polyglot.t('General.date')}
          onChange={(value) => {
            formik.setFieldValue('date', value);
          }}
          value={formik.values.date}
          error={formik.touched.date && !!formik.errors.date}
          helperText={formik.touched.date && formik.errors.date}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name={polyglot.t('General.save')}
            loading={loading}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
