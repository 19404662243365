import { useEffect } from 'react';

import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';

import { CompanyEndpoints } from '@/api-client/company.api';
import { CompanySettingsGeneral } from '@/v2/feature/company/company-settings/features/components/company-settings/company-settings-general.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export const CompanySettingsPage = (): React.JSX.Element => {
  const { trackPage } = useJune();
  const {
    data: generalSettings,
    isValidating: loadingGeneralSettings,
    mutate: refreshGeneralSettings,
  } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });

  useEffect(() => {
    trackPage('Company settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper loading={loadingGeneralSettings}>
      <CompanySettingsGeneral generalSettings={generalSettings ?? null} refreshSettings={refreshGeneralSettings} />
    </ContentWrapper>
  );
};
