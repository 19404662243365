import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltPolicyPayloadIdentifier } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { iconSize } from '@v2/styles/menu.styles';
import { FormikProvider, useFormik } from 'formik';

import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  AutoLaunchedApplicationDictionaryMangedItem,
  ConfigurablePoliciesDto,
  LoginItemsOptions,
} from '@/v2/feature/device/device.dto';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';

export const ZeltMdmLoginItemsPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): LoginItemsOptions => {
    const loginItemPath: AutoLaunchedApplicationDictionaryMangedItem = { Path: '', Hide: false };
    return {
      autoLaunchedApplicationDictionaryManaged:
        configurablePoliciesSelected?.configurableLoginItems?.autoLaunchedApplicationDictionaryManaged !== undefined
          ? configurablePoliciesSelected?.configurableLoginItems?.autoLaunchedApplicationDictionaryManaged
          : [loginItemPath],
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.LOGIN_ITEMS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: LoginItemsOptions = getPolicy();
  const formik = useFormik<LoginItemsOptions>({
    initialValues,
    onSubmit: async (loginItemsForm: LoginItemsOptions) => {
      const analyzedPolicyBeforeSave = analyzePolicyBeforeSave(loginItemsForm);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableLoginItems: analyzedPolicyBeforeSave,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  function analyzePolicyBeforeSave(policy: LoginItemsOptions): LoginItemsOptions | undefined {
    if (
      !policy.autoLaunchedApplicationDictionaryManaged ||
      policy.autoLaunchedApplicationDictionaryManaged.length === 0
    ) {
      return undefined;
    }
    return {
      autoLaunchedApplicationDictionaryManaged: policy.autoLaunchedApplicationDictionaryManaged,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.LOGIN_ITEMS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }
  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAddTextField = () => {
    const loginItemPath: AutoLaunchedApplicationDictionaryMangedItem = { Path: '', Hide: false };
    const newArray = [...(formik.values.autoLaunchedApplicationDictionaryManaged || []), loginItemPath];
    formik.setFieldValue('autoLaunchedApplicationDictionaryManaged', newArray);
  };

  const handleRemoveTextField = (indexToRemove: number) => {
    const newArray = (formik.values.autoLaunchedApplicationDictionaryManaged || []).filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue('autoLaunchedApplicationDictionaryManaged', newArray);
  };
  return (
    <Box>
      <Typography variant="title2">Open at login</Typography>
      <Box sx={{ gap: 2, mt: 2 }}>
        <Typography variant="caption">For each individual item, you should supply a path</Typography>
      </Box>
      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          {formik.values.autoLaunchedApplicationDictionaryManaged?.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextfieldComponent
                label={`Path to the application`}
                name={`autoLaunchedApplicationDictionaryManaged[${index}].Path`}
                value={item.Path}
                onChange={(e) => {
                  const path = e?.target.value;
                  const updatedArray = [...formik.values.autoLaunchedApplicationDictionaryManaged!];
                  item.Path = path;
                  updatedArray[index] = item;
                  formik.setFieldValue('autoLaunchedApplicationDictionaryManaged', updatedArray);
                }}
                autoFocus={index === 0}
              />
              <Box sx={{ marginTop: '14px' }}>
                <IconButton sx={iconButtonSx} onClick={() => handleRemoveTextField(index)} title="Remove">
                  <TrashIcon {...iconSize} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '30px' }}>
          <ButtonComponent onClick={handleAddTextField} sizeVariant="small" colorVariant="secondary">
            Add path
          </ButtonComponent>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '30px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
