import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import { InstalledAppDto } from './app-integration.dto';
import { AppIntegrationStub } from './app-integration.interface';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { nestErrorMessage } from '@/lib/errors';
import { APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { Scope, ScopeContext } from '@/models';
import { IconContentActionCard } from '@/v2/components/theme-components/icon-content-action-card.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AppIntegrationAPI, AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { AppButton } from '@/v2/feature/app-integration/components/app-button.component';
import { AppIntroduction } from '@/v2/feature/app-integration/components/app-introduction.component';
import { AppRequestAPI, AppRequestEndpoints } from '@/v2/feature/app-integration/features/app-request/app-request.api';
import {
  RequestAlertDto,
  RequestDto,
  RequestStatus,
} from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { borders } from '@/v2/styles/borders.styles';
import { secondaryCTABtn } from '@/v2/styles/buttons.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  title: string;
  subtitle: string;
  readonly icon?: JSX.Element;
  avatarImg?: string;
  readonly buttonText?: string;
  stub: string;
  showBorder: boolean;
  action: () => void;
  readonly actionScope?: readonly Scope[];
  context?: ScopeContext | undefined;
}

export const AppCard = ({
  title,
  subtitle,
  icon,
  avatarImg,
  buttonText,
  stub,
  showBorder,
  action,
  actionScope = [],
  context,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: spacing.g20,
        ...spacing.py20,
        borderBottom: showBorder ? borders.background : 'none',
      }}
    >
      <Box sx={{ display: 'flex', gap: spacing.g15 }}>
        {/* icon */}
        {icon ? (
          <Box>{icon}</Box>
        ) : (
          <Box>
            <img src={avatarImg} alt={stub} width={40} />
          </Box>
        )}
        {/* text */}
        <Box>
          <Typography sx={{ ...themeFonts.title4, mb: spacing.mb5 }}>{title}</Typography>
          <Typography sx={{ ...themeFonts.caption }}>{subtitle}</Typography>
        </Box>
      </Box>

      {/* button */}
      <Box>
        <ScopesControl scopes={actionScope} context={context}>
          <Button sx={{ ...secondaryCTABtn, width: '80px' }} onClick={action}>
            {buttonText ?? 'Connect'}
          </Button>
        </ScopesControl>
      </Box>
    </Box>
  );
};

export const AppsPage = (): JSX.Element => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();

  const [globalState, dispatch] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  const isAppsAdmin = checkScopes(globalState.user, ['apps:all'], context);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [selectedRequested, setSelectedRequested] = useState<RequestDto | undefined>(undefined);
  const [pendingRequest, setPendingRequest] = useState<RequestDto[] | undefined>(undefined);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const { getCachedUserById } = useCachedUsers();

  const {
    data: myApps,
    isValidating: myAppsLoading,
    error: myAppsError,
  } = useApiClient(AppIntegrationEndpoints.getUserApps(globalState?.user?.userId), { suspense: false });
  const {
    data: installedApps,
    isValidating: installedAppsLoading,
    error: installedAppsError,
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });

  const { data: requestsOnlyForUser, mutate: refreshRequestsForUser } = useApiClient(
    !isAppsAdmin ? AppRequestEndpoints.getAppRequestsForUser('Requested,Failed,Scheduled,Pending') : { url: undefined },
    { suspense: false }
  );

  const { data: allCompanyRequests, mutate: refreshAppRequests } = useApiClient(
    !isAppsAdmin ? { url: undefined } : AppRequestEndpoints.getAppRequestsForCompany(),
    {
      suspense: false,
    }
  );

  const existingRequestsForUser = useMemo(() => {
    const REQUESTS_REQUIRING_ATTENTION = [RequestStatus.Requested, RequestStatus.Failed, RequestStatus.Pending];
    return (isAppsAdmin ? allCompanyRequests : requestsOnlyForUser)?.filter((r) =>
      isAppsAdmin
        ? r.status && REQUESTS_REQUIRING_ATTENTION.includes(r.status)
        : r.requestedFor === globalState.user.userId && r.status && REQUESTS_REQUIRING_ATTENTION.includes(r.status)
    );
  }, [allCompanyRequests, globalState.user.userId, isAppsAdmin, requestsOnlyForUser]);

  useEffect(() => {
    if (isAppsAdmin) {
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { apps: { entries: existingRequestsForUser as RequestAlertDto[], type: 'admin' } },
      });
    }
  }, [dispatch, existingRequestsForUser, isAppsAdmin]);

  const approveAppRequest = async (requestInfo: RequestDto): Promise<void> => {
    setSelectedRequested(requestInfo);
    setApproveLoading(true);
    try {
      const { id } = requestInfo;
      if (!id) {
        showMessage('Unable to approve app request as there are missing details', 'error');
        return;
      }
      await AppRequestAPI.approveAppRequest(id);
      refreshAppRequests!();
      refreshRequestsForUser!();
      showMessage('Successfully approved & scheduled app access request!', 'success');
    } catch (error) {
      showMessage(`Encountered an error while trying to approve access request: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setApproveLoading(false);
      setSelectedRequested(undefined);
    }
  };

  useEffect(() => {
    const pending = allCompanyRequests?.filter((c) => c.status === RequestStatus.Requested);
    setPendingRequest(pending);
  }, [allCompanyRequests]);

  useEffect(() => {
    if (myAppsError || installedAppsError) {
      showMessage('Could not load the list of apps. Refresh to try again.', 'error');
    }
  }, [myAppsError, installedAppsError, showMessage]);

  useEffect(() => {
    trackPage('Apps overview');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateOauthFlowForApp = (event: React.SyntheticEvent, appStub: AppIntegrationStub) => {
    if (event) event.preventDefault();
    window.location.href = AppIntegrationAPI.getInitiateUrl(appStub);
  };

  const appConnectAction = (app: InstalledAppDto, event: SyntheticEvent<Element, Event>) => {
    if (app?.authorised && !app?.directory?.oauth)
      return routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, { appStub: app.stub }));
    // if (!app?.authorised && !app?.directory?.oauth) return setBasicAuthConnectDrawerOpen(true);
    if (app?.directory?.oauth) return initiateOauthFlowForApp(event, app.stub);
  };

  const getActionForConnect = async (app: InstalledAppDto, event: SyntheticEvent<Element, Event>) => {
    try {
      const canConnect = await AppIntegrationAPI.appConnectAllowed();
      if (canConnect) {
        appConnectAction(app, event);
      }
      // OLD RESTRICTION
      // if (
      //   (globalState.user.restrictions.APPS.disableAppCreation || globalState.user.restrictions.TECH.disableApps) &&
      //   !app.invalidatedAt
      // ) {
      //   await AppIntegrationAPI.appConnectAllowed();
      // } else {

      // }
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        // if app is invalidated, but you need to renew expired connection, that should be allowed
        if (app.invalidatedAt) {
          appConnectAction(app, event);
        } else {
          // new app connection should be prevented
          setUpgradeModalOpen(true);
        }
      } else {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    }
  };

  return (
    <RootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Overview</Typography>} />
      <ContentWrapper loading={(myAppsLoading || installedAppsLoading) ?? false}>
        {myApps?.length === 0 && installedApps?.length === 0 ? (
          <AppIntroduction />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: spacing.g40,
              alignItems: 'flex-start',
              flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '100%', md: '60%', lg: '60%' } }}>
              <Box>
                <Box sx={{ display: 'flex', gap: spacing.g10, flexWrap: 'wrap' }}>
                  {installedApps && installedApps?.length > 0 ? (
                    installedApps.map((a, idx) => (
                      <AppButton
                        app={a}
                        key={`${a.stub}-${idx}`}
                        title={installedApps?.find((eachApp) => eachApp.stub === a.stub)?.name ?? ''}
                        handleClick={() =>
                          routerHistory.push(
                            generatePath(APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, {
                              appStub: a.stub,
                            })
                          )
                        }
                        avatarImg={`/app-icons-v2/images/${a.stub}.png`}
                        stub={a.stub}
                        expired={!!a.invalidatedAt}
                        expiredAction={getActionForConnect}
                      />
                    ))
                  ) : (
                    <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
                      No apps are currently installed
                    </Typography>
                  )}
                </Box>
              </Box>
              {pendingRequest && pendingRequest?.length > 0 && (
                <Box sx={{ marginTop: spacing.m60 }}>
                  <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey, ...spacing.mb20 }}>
                    App requests
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
                    {pendingRequest?.map((r, idx) => (
                      <IconContentActionCard
                        key={`${r.id}-${idx}`}
                        iconMedia={<Waiting fill={themeColors.GreyMiddle} />}
                        titleSx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}
                        iconGap={spacing.g10}
                        textGap={spacing.g5}
                        sx={{ paddingBottom: 0 }}
                        showDivider={idx < pendingRequest.length - 1}
                        title="New account request"
                        content={`${
                          r.requestedBy && getCachedUserById(r.requestedBy)?.displayName
                        } asked for an account in ${
                          installedApps?.find((eachApp) => eachApp.stub === r.requestInfo.appStub)?.name ?? ''
                        }. Approve their account creation or go to Requests tab for more options`}
                        action={
                          <LoaderButton
                            loading={approveLoading && selectedRequested?.id === r.id}
                            sizeVariant="medium"
                            colorVariant="primary"
                            onClick={() => approveAppRequest(r)}
                            name="Approve"
                            fullWidth
                          />
                        }
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.TECH_PRO}
          messageSuffix="proGeneric"
        />
      </ContentWrapper>
    </RootStyle>
  );
};
