import { isAfter, isSameDay, isWithinInterval } from 'date-fns';
import Polyglot from 'node-polyglot';

import { AbsenceDto } from '@/v2/feature/absence/absence.dto';
import { ViewSettingEnum } from '@/v2/feature/calendar/calendar.interface';
import { getPlaceholderDate, isAnniversaryInPeriod, isAnniversaryToday } from '@/v2/feature/dashboard/dashboard.util';
import {
  MappedEventsProps,
  MappedUserProps,
} from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import {
  isISODateAfterDate2,
  isISODateBeforeDate2,
  isISODateSameAsLocaleDate,
} from '@/v2/infrastructure/date/date-format.util';
import { LocalDate } from '@/v2/util/local-date';

export const getFilteredData = (
  range: string[],
  users: readonly CachedUser[],
  view: ViewSettingEnum,
  signedInUser: CachedUser | undefined,
  requests: AbsenceDto[],
  fellowReportees: number[] //received only when standard user setting is set to teams
) => {
  if (view === ViewSettingEnum.Me)
    users = signedInUser?.userId ? users.filter((u) => u.userId === signedInUser?.userId) : [];

  if (view === ViewSettingEnum.Team) {
    users =
      signedInUser?.userId && fellowReportees && fellowReportees.length > 0
        ? users.filter((u) => fellowReportees.includes(u?.userId) || u?.userId === signedInUser?.userId)
        : users.filter(
            (u) => (u?.role && u.role?.managerId === signedInUser?.userId) || u?.userId === signedInUser?.userId
          );
  }

  let filteredUsers: MappedUserProps[] = users
    .map((user) => {
      const absences = requests?.filter((a) => a.userId === user.userId);
      return {
        id: user.userId,
        absences: absences ? [...absences] : [],
        name: user.displayName ?? '',
        department: user.role?.department?.name ?? '-',
        siteId: user.role?.site?.id,
        role: user.role,
        startDate: user.startDate,
        dob: user.showBirthday !== null ? getPlaceholderDate(user.showBirthday) : null,
        leaveDate: user.leaveDate,
      };
    })
    .filter((user) => {
      return (
        (user.dob && isAnniversaryInPeriod(user.dob, range)) ||
        (user.startDate && isAnniversaryInPeriod(user.startDate, range)) ||
        (user.startDate &&
          isWithinInterval(new LocalDate(user.startDate).getDate(), {
            start: new LocalDate(range[0]).getDate(),
            end: new LocalDate(range[1]).getDate(),
          })) ||
        (user.leaveDate &&
          isWithinInterval(new LocalDate(user.leaveDate).getDate(), {
            start: new LocalDate(range[0]).getDate(),
            end: new LocalDate(range[1]).getDate(),
          })) ||
        (user?.absences && user?.absences?.length > 0)
      );
    });
  return filteredUsers;
};

export const getCategorizedData = (
  eventData: MappedEventsProps[],
  filteredData: MappedUserProps[],
  currentDate: Date
) => {
  return filteredData.forEach((user) => {
    if (user.dob && isAnniversaryToday(user.dob, currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: user.role?.jobTitle ?? null,
        department: user.role?.department?.id ?? null,
        type: 'Birthday',
        startDate: user.dob,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null,
      });
    }

    if (user.startDate && isAnniversaryToday(user.startDate, currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: user.role?.jobTitle ?? null,
        department: user.role?.department?.id ?? null,
        type: 'Work anniversary',
        startDate: user.startDate,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null,
      });
    }

    if (user.startDate && isSameDay(new LocalDate(user.startDate).getDate(), currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: user.role?.jobTitle ?? null,
        department: user.role?.department?.id ?? null,
        type: 'First day',
        startDate: user.startDate,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null,
      });
    }

    if (user.leaveDate && isSameDay(new LocalDate(user.leaveDate).getDate(), currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: user.role?.jobTitle ?? null,
        department: user.role?.department?.id ?? null,
        type: 'Last day',
        startDate: user.leaveDate,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null,
      });
    }
    const absence = user?.absences?.filter((absence) => {
      if (absence.end)
        return (
          (isISODateAfterDate2(absence.end, currentDate) && isISODateBeforeDate2(absence.start, currentDate)) ||
          isISODateSameAsLocaleDate(absence.start, currentDate) ||
          isISODateSameAsLocaleDate(absence.end, currentDate)
        );
      return isISODateSameAsLocaleDate(absence.start, currentDate);
    });
    if (absence && absence.length > 0) {
      absence
        .map((a) => {
          if (eventData.find((event) => event.absenceId === a.absenceId)) return null;
          return eventData.push({
            displayName: user.name,
            userId: user.id,
            jobTitle: user.role?.jobTitle ?? null,
            department: user.role?.department?.id ?? null,
            type: a.policy?.name ?? 'Away',
            startDate: new LocalDate(a.start).toDateString(),
            endDate: a.end ? new LocalDate(a.end).toDateString() : null,
            currentDate: new LocalDate(currentDate).toDateString(),
            isAbsence: true,
            absenceId: a.absenceId,
          });
        })
        .filter(Boolean);
    }
  });
};

export const getCurrentDateAndFutureEvents = (structuredData: MappedEventsProps[], currentDate: Date) => {
  return structuredData.filter((d) => {
    if (!d.isAbsence) {
      const startDate = new LocalDate(currentDate).getDate();
      const eventDate = new LocalDate(d.startDate).getDate();
      eventDate.setFullYear(startDate.getFullYear());
      return (
        isAfter(new LocalDate(eventDate).getDate(), currentDate) ||
        isSameDay(new LocalDate(eventDate).getDate(), currentDate)
      );
    } else if (
      (d.isAbsence &&
        d.endDate &&
        (isSameDay(new LocalDate(d.endDate).getDate(), currentDate) ||
          isAfter(new LocalDate(d.endDate).getDate(), currentDate))) ||
      isSameDay(new LocalDate(d.startDate).getDate(), currentDate) ||
      isAfter(new LocalDate(d.startDate).getDate(), currentDate)
    ) {
      return true;
    }

    return false;
  });
};

export type DayType = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export const DaysOptions = (polyglot: Polyglot) => {
  return {
    Mon: polyglot?.t('UserCalendar.Mon'),
    Tue: polyglot?.t('UserCalendar.Tue'),
    Wed: polyglot?.t('UserCalendar.Wed'),
    Thu: polyglot?.t('UserCalendar.Thu'),
    Fri: polyglot?.t('UserCalendar.Fri'),
    Sat: polyglot?.t('UserCalendar.Sat'),
    Sun: polyglot?.t('UserCalendar.Sun'),
  };
};
