import React, { useContext, useEffect, useMemo, useState } from 'react';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import { AppIntegrationStub, SSOState } from '@/v2/feature/app-integration/app-integration.interface';
import { SSOAzureAdUpdateForm } from '@/v2/feature/security/security-settings/components/sso-azure-ad-update.component';
import { SSOGWUpdateForm } from '@/v2/feature/security/security-settings/components/sso-gw-update.component';
import { SSOOktaUpdateForm } from '@/v2/feature/security/security-settings/components/sso-okta-update.component';
import { SSOApp, SSOAppsEnum, SSOAppsOptions } from '@/v2/feature/security/security-settings/security.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SSOConfigureDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly ssoState: SSOState[];
  readonly mfaEnabled: boolean;
  readonly onUpdate: () => void;
}

export const SSOConfigureDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  ssoState,
  mfaEnabled,
  onUpdate,
}: SSOConfigureDrawerProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const enabledApp = useMemo(() => {
    return ssoState.find((eachApp) => eachApp.state?.enabled);
  }, [ssoState]);

  const [updatedSSOApp, setUpdatedSSOApp] = useState<AppIntegrationStub | undefined>(
    enabledApp ? enabledApp.app : undefined
  );

  useEffect(() => {
    setUpdatedSSOApp(enabledApp ? enabledApp.app : undefined);
  }, [enabledApp]);

  const ssoProvider = useMemo(() => {
    return enabledApp && enabledApp.app ? SSOAppsEnum[enabledApp.app as SSOApp] : undefined;
  }, [enabledApp]);

  const enabledActionText = useMemo(() => {
    if (enabledApp && enabledApp.app && enabledApp.app === 'sso-okta')
      return polyglot.t('SSOConfigureDrawer.updateApiOkta');
    if (
      enabledApp &&
      enabledApp.app &&
      (enabledApp.app === 'sso-google-workspace' || enabledApp.app === 'sso-azure-ad')
    )
      return polyglot.t('SSOConfigureDrawer.uploadIdP');
    return '';
  }, [polyglot, enabledApp]);

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setUpdatedSSOApp(enabledApp ? enabledApp.app : undefined);
        onClose();
      }}
    >
      <>
        <Typography sx={titleSx}>{polyglot.t('SSOConfigureDrawer.ssoFull')}</Typography>
        {!enabledApp ? (
          <>
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10 }}>
              {polyglot.t('SSOConfigureDrawer.selectProvider')}
            </Typography>
            <RadioGroup aria-label="sso-provider" name="radio-buttons-group">
              {SSOAppsOptions.map((eachApp) => (
                <FormControlLabel
                  value={eachApp.value}
                  onChange={() => {
                    setUpdatedSSOApp(eachApp.value as AppIntegrationStub);
                  }}
                  control={<Radio checked={updatedSSOApp ? eachApp.value === updatedSSOApp : false} color="default" />}
                  label={
                    <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>{eachApp.label}</Typography>
                  }
                />
              ))}
            </RadioGroup>
          </>
        ) : (
          <>
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m10 }}>
              {ssoProvider} {polyglot.t('SSOConfigureDrawer.ssoEnabled')}. {enabledActionText}{' '}
              {polyglot.t('SSOConfigureDrawer.orDisabled')} {ssoProvider} {polyglot.t('SSOConfigureDrawer.sso')}
            </Typography>
          </>
        )}

        {updatedSSOApp && updatedSSOApp === 'sso-google-workspace' && (
          <SSOGWUpdateForm
            mfaEnabled={mfaEnabled}
            ssoState={ssoState}
            onUpdate={() => {
              onClose();
              onUpdate();
            }}
            currentUser={user}
          />
        )}

        {updatedSSOApp && updatedSSOApp === 'sso-okta' && (
          <SSOOktaUpdateForm
            mfaEnabled={mfaEnabled}
            ssoState={ssoState}
            onUpdate={() => {
              onClose();
              onUpdate();
            }}
            currentUser={user}
          />
        )}

        {updatedSSOApp && updatedSSOApp === 'sso-azure-ad' && (
          <SSOAzureAdUpdateForm
            mfaEnabled={mfaEnabled}
            ssoState={ssoState}
            onUpdate={() => {
              onClose();
              onUpdate();
            }}
          />
        )}
      </>
    </DrawerModal>
  );
};
