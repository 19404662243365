import { Box, IconButton, Typography } from '@mui/material';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import {
  cardTitleIconSx,
  detailHelperSx,
  detailListSx,
  detailSx,
  detailTermSx,
  detailValueSx,
} from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

interface PolicyProps {
  value: number;
  label: string;
  description: string | undefined;
}

interface Props {
  readonly setEditPoliciesCard: React.Dispatch<React.SetStateAction<boolean>>;
  readonly selectedPasscodePolicy: PolicyProps | undefined;
  readonly selectedOsUpdatesPolicy: PolicyProps | undefined;
}

export const AndroidSettingsCard = ({
  setEditPoliciesCard,
  selectedPasscodePolicy,
  selectedOsUpdatesPolicy,
}: Props) => {
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '60%', xl: '60%' },
          paddingBottom: spacing.p60,
        }}
      >
        <Box sx={cardTitleIconSx}>
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Security</Typography>
          <IconButton sx={tableIconButtonSx} onClick={() => setEditPoliciesCard(true)}>
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey, mb: spacing.m30 }}>
          Configure security settings for Android devices connected to Hexnode MDM.
        </Typography>

        <Box component="section">
          <Box component="dl" sx={detailListSx}>
            {selectedPasscodePolicy && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  Passcode
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {selectedPasscodePolicy?.label}
                  </Typography>
                  {selectedPasscodePolicy?.description && (
                    <Typography sx={detailHelperSx}>{selectedPasscodePolicy?.description}</Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box component="dl" sx={detailListSx}>
            {selectedOsUpdatesPolicy && (
              <Box sx={detailSx}>
                <Typography component="dt" sx={detailTermSx}>
                  OS updates
                </Typography>
                <Box component="dd" sx={{ ...detailValueSx, position: 'relative' }}>
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {selectedOsUpdatesPolicy?.label}
                  </Typography>
                  {selectedOsUpdatesPolicy?.description && (
                    <Typography sx={detailHelperSx}>{selectedOsUpdatesPolicy?.description}</Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
