import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { REVIEWS_COMPANY_ONGOING_ROUTE, REVIEWS_TEAM_ONGOING_ROUTE } from '@/lib/routes';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import { ReachType, ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

export const ReviewCycleFinaliseModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  isFinished,
  reach,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
  isFinished: boolean;
  reach: ReachType;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ReviewCycleFinaliseContent
        reviewCycle={reviewCycle}
        onClose={onClose}
        refresh={refresh}
        isFinished={isFinished}
        reach={reach}
      />
    </DrawerModal>
  );
};

const ReviewCycleFinaliseContent = ({
  reviewCycle,
  onClose,
  refresh,
  isFinished,
  reach,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  isFinished: boolean;
  reach: ReachType;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();

  const handleFinalise = async () => {
    try {
      setLoading(true);
      const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_ROUTE : REVIEWS_TEAM_ONGOING_ROUTE;
      if (!reviewCycle) {
        showMessage(`Cycle data not fetched`, 'error');
        return;
      }

      await ReviewCycleAPI.finaliseCycle(reviewCycle?.id);
      showMessage('Finalisation in process, check back in a while', 'success');
      refresh();
      routerHistory.push(reachRoute);
      onClose();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const appropriateText = isFinished
    ? 'Existing results will be shared with reviewees and their managers. If this cycle requires manager to share feedback, results will not be shared automatically as you finalise the cycle.'
    : 'Are you sure you are ready to finalise this cycle? Any pending invitations will be removed for outstanding participants. Existing results will be shared with reviewees and their managers. If this cycle requires manager to share feedback, results will not be shared automatically as you finalise the cycle.';

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">Finalise this cycle?</Typography>
      <Typography variant="caption" color="Grey">
        {appropriateText}
      </Typography>

      <Typography variant="caption" color="Grey">
        Note: Finalisation can take some time. Please wait or check back later.
      </Typography>

      <Box sx={buttonBoxDrawerSx}>
        <LoaderButton
          type="button"
          onClick={handleFinalise}
          sizeVariant="medium"
          colorVariant="primary"
          name="Confirm"
          loading={loading}
          fullWidth
        />
      </Box>
    </Box>
  );
};
