import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  ZeltMdmDevicePolicyType,
  ZeltPolicyPayloadIdentifier,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { fieldSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { themeColors } from '@v2/styles/colors.styles';
import { StyledRadio } from '@v2/styles/radio.styles';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurableAutomaticOsUpdate,
  ConfigurablePoliciesDto,
  RestrictionOsUpdatesOptions,
} from '@/v2/feature/device/device.dto';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmOsUpdatesPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  selectedRestriction,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  selectedRestriction: ZeltMdmDevicePolicyType | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [osUpdateDelayHelper, setOsUpdateDelayHelper] = useState<string>('');
  const [appUpdateDelayHelper, setAppUpdateDelayHelper] = useState<string>('');
  const getPolicy = (): ConfigurableAutomaticOsUpdate => {
    return {
      automaticOsUpdate: configurablePoliciesSelected?.configurableAutomaticOsUpdate?.automaticOsUpdate ?? false,
      installAction: configurablePoliciesSelected?.configurableAutomaticOsUpdate?.installAction ?? 'Default',
    };
  };
  const initialValues: ConfigurableAutomaticOsUpdate = getPolicy();

  function analyzePolicyBeforeSave(policy: ConfigurableAutomaticOsUpdate) {
    if (!policy.automaticOsUpdate) {
      return {
        automaticOsUpdate: policy.automaticOsUpdate,
      };
    }
    return {
      automaticOsUpdate: policy.automaticOsUpdate,
      installAction: policy.installAction,
    };
  }

  const formik = useFormik<ConfigurableAutomaticOsUpdate>({
    initialValues,
    onSubmit: async (form: ConfigurableAutomaticOsUpdate) => {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableAutomaticOsUpdate: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  const getOsUpdatesRestrictions = (): RestrictionOsUpdatesOptions => {
    return {
      enforcedSoftwareUpdateDelay:
        configurablePoliciesSelected?.configurableOsUpdateRestrictions?.enforcedSoftwareUpdateDelay !== undefined
          ? configurablePoliciesSelected?.configurableOsUpdateRestrictions?.enforcedSoftwareUpdateDelay
          : 0,
      enforcedSoftwareUpdateNonOSDeferredInstallDelay:
        configurablePoliciesSelected?.configurableOsUpdateRestrictions
          ?.enforcedSoftwareUpdateNonOSDeferredInstallDelay !== undefined
          ? configurablePoliciesSelected?.configurableOsUpdateRestrictions
              ?.enforcedSoftwareUpdateNonOSDeferredInstallDelay
          : 0,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.OS_UPDATES_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const osUpdatesRestrictionsValues: RestrictionOsUpdatesOptions = getOsUpdatesRestrictions();

  function analyzeOsUpdateRestrictionsPolicyBeforeSave(policy: RestrictionOsUpdatesOptions) {
    if (policy.enforcedSoftwareUpdateDelay === 0 && policy.enforcedSoftwareUpdateNonOSDeferredInstallDelay === 0) {
      return undefined;
    }
    return {
      ...(policy.enforcedSoftwareUpdateDelay !== 0 && {
        enforcedSoftwareUpdateDelay: policy.enforcedSoftwareUpdateDelay,
        forceDelayedSoftwareUpdates: true,
      }),
      ...(policy.enforcedSoftwareUpdateNonOSDeferredInstallDelay !== 0 && {
        enforcedSoftwareUpdateNonOSDeferredInstallDelay: policy.enforcedSoftwareUpdateNonOSDeferredInstallDelay,
        forceDelayedAppSoftwareUpdates: true,
      }),
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.OS_UPDATES_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }

  const formikRestrictions = useFormik<RestrictionOsUpdatesOptions>({
    initialValues: osUpdatesRestrictionsValues,
    onSubmit: async (form: RestrictionOsUpdatesOptions) => {
      const configurablePolicy = analyzeOsUpdateRestrictionsPolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableOsUpdateRestrictions: configurablePolicy,
      });
    },
  });

  function setHelperValues(osUpdatesRestrictions: RestrictionOsUpdatesOptions) {
    if (osUpdatesRestrictions.enforcedSoftwareUpdateDelay && osUpdatesRestrictions.enforcedSoftwareUpdateDelay === 0) {
      setOsUpdateDelayHelper('');
    } else if (
      osUpdatesRestrictions.enforcedSoftwareUpdateDelay &&
      osUpdatesRestrictions.enforcedSoftwareUpdateDelay !== 0
    ) {
      setOsUpdateDelayHelper(osUpdatesRestrictions.enforcedSoftwareUpdateDelay?.toString());
    }
    if (
      osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay &&
      osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay === 0
    ) {
      setAppUpdateDelayHelper('');
    } else if (
      osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay &&
      osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay !== 0
    ) {
      setAppUpdateDelayHelper(osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay?.toString());
    }
  }

  useEffect(() => {
    formik.validateForm();
    formikRestrictions.validateForm();
    const osUpdatesRestrictions = getOsUpdatesRestrictions();
    setHelperValues(osUpdatesRestrictions);
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectedRestriction === ZeltMdmDevicePolicyType.osupdate_automatic && (
        <Box>
          <Typography variant="title2">OS updates</Typography>
          <FormikProvider value={formik}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap80, mt: '20px' }}>
              <Typography variant="caption">Automatic updates</Typography>
              <SwitchComponent
                checked={formik.values.automaticOsUpdate}
                name="automaticOsUpdate"
                sx={{ marginLeft: 'auto' }}
                onChange={async (_e, enabled) => {
                  formik.setFieldValue('automaticOsUpdate', enabled);
                }}
              />
            </Box>
            <Box sx={{ width: '80%' }}>
              <Typography variant="captionSmall" sx={{ color: themeColors.Grey, my: spacing.m5 }}>
                Installing operating system updates automatically
              </Typography>
            </Box>
            {formik.values.automaticOsUpdate && (
              <>
                <Box sx={{ my: '20px' }}>
                  <Typography variant="title4">Install action type</Typography>
                </Box>
                <Box sx={fieldSx}>
                  <FormControl sx={{ width: '100%' }}>
                    <RadioGroup
                      name="installAction"
                      onChange={(event) => {
                        formik.setFieldValue('installAction', event.target.value);
                      }}
                    >
                      <FormControlLabel
                        key="no-rounding"
                        labelPlacement="end"
                        value="Default"
                        checked={formik.values.installAction === 'Default'}
                        control={<StyledRadio />}
                        label={
                          <Box>
                            <Typography variant="caption">Default</Typography>
                            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                              Download or install the update, depending on the current state
                            </Typography>
                          </Box>
                        }
                        sx={{ mb: spacing.m20 }}
                      />
                      <FormControlLabel
                        key="no-rounding"
                        labelPlacement="end"
                        value="InstallASAP"
                        checked={formik.values.installAction === 'InstallASAP'}
                        control={<StyledRadio />}
                        label={
                          <Box>
                            <Typography variant="caption">Install ASAP</Typography>
                            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                              Download the software update, install immediately and trigger the restart countdown
                              notification
                            </Typography>
                          </Box>
                        }
                        sx={{ mb: spacing.m20 }}
                      />
                      <FormControlLabel
                        key="no-rounding"
                        labelPlacement="end"
                        value="InstallForceRestart"
                        checked={formik.values.installAction === 'InstallForceRestart'}
                        control={<StyledRadio />}
                        label={
                          <Box>
                            <Typography variant="caption">Install and force restart</Typography>
                            <Typography variant="captionSmall" sx={{ color: themeColors.Grey }}>
                              Perform the Default action, and then force a restart
                            </Typography>
                          </Box>
                        }
                        sx={{ mb: spacing.m20 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </>
            )}

            <Box sx={{ display: 'flex', gap: 2, width: '100%', alignItems: 'center', marginTop: '30px' }}>
              <ButtonComponent
                onClick={() => formik.handleSubmit()}
                fullWidth
                colorVariant="primary"
                sizeVariant="medium"
              >
                Save
              </ButtonComponent>
            </Box>
          </FormikProvider>
        </Box>
      )}

      {selectedRestriction === ZeltMdmDevicePolicyType.osupdate_restrictions && (
        <Box>
          <Typography variant="title2">OS updates delay</Typography>
          <FormikProvider value={formikRestrictions}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: '20px' }}>
              <Box sx={fieldSx}>
                <TextfieldComponent
                  label="Delay operating system updates (days)"
                  name="enforcedSoftwareUpdateDelay"
                  type="number"
                  value={osUpdateDelayHelper}
                  minValue={0}
                  maxValue={90}
                  validateNumber={true}
                  tooltip={
                    'How many days to delay a software update on the device. The user doesn’t see a software update until the specified number of days after the software update release date. Range 1-90'
                  }
                  onChange={(e) => {
                    let osUpdateDelay = e?.target.value;
                    if (osUpdateDelay === '0') {
                      setOsUpdateDelayHelper('');
                    } else {
                      setOsUpdateDelayHelper(osUpdateDelay);
                    }
                    formikRestrictions.setFieldValue('enforcedSoftwareUpdateDelay', Number(osUpdateDelay));
                  }}
                />
              </Box>

              <Box sx={fieldSx}>
                <TextfieldComponent
                  label="Delay applications updates (days)"
                  name="enforcedSoftwareUpdateNonOSDeferredInstallDelay"
                  type="number"
                  value={appUpdateDelayHelper}
                  minValue={0}
                  maxValue={90}
                  validateNumber={true}
                  tooltip={
                    'How many days to delay an app software update on the device. The user sees a non-OS software update only after the specified delay after the release of the software. Range 1-90'
                  }
                  onChange={(e) => {
                    let appUpdateDelay = e?.target.value;
                    if (appUpdateDelay === '0') {
                      setAppUpdateDelayHelper('');
                    } else {
                      setAppUpdateDelayHelper(appUpdateDelay);
                    }
                    formikRestrictions.setFieldValue(
                      'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
                      Number(appUpdateDelay)
                    );
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px' }}>
              <ButtonComponent
                onClick={() => formikRestrictions.handleSubmit()}
                fullWidth
                colorVariant="primary"
                sizeVariant="medium"
              >
                Save
              </ButtonComponent>
            </Box>
          </FormikProvider>
        </Box>
      )}
    </>
  );
};
