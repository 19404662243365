import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { AbsenceRequestsPage } from '@v2/feature/absence/company/policies/pages/absence-requests.page';
import { AbsenceTeamBalancesPage } from '@v2/feature/absence/team/policies/pages/absence-team-balances.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { ABSENCE_TEAM_BALANCES_ROUTE, ABSENCE_TEAM_REQUESTS_ROUTE } from '@/lib/routes';

export const AbsenceTeamRouter = () => {
  const { data: absencePolicies, isLoading } = useApiClient(AbsenceEndpoints.getTeamAbsencePoliciesExtended(), {
    suspense: false,
  });

  return (
    <Switch>
      <RouteScopesHas scopes={['absence:manager']} path={ABSENCE_TEAM_REQUESTS_ROUTE} exact>
        <AbsenceRequestsPage
          reach="team"
          absencePolicies={absencePolicies ?? []}
          absencePoliciesLoading={Boolean(isLoading)}
        />
      </RouteScopesHas>

      <RouteScopesHas scopes={['absence:manager']} path={ABSENCE_TEAM_BALANCES_ROUTE} exact>
        <AbsenceTeamBalancesPage absencePolicies={absencePolicies ?? []} />
      </RouteScopesHas>

      <Redirect to={ABSENCE_TEAM_REQUESTS_ROUTE} />
    </Switch>
  );
};
