import { ProfileTab } from '@v2/feature/user/features/user-profile/details/user-profile.interface';

import { OptionInterface } from '@/v2/feature/custom-fields/custom-profile-fields.interface';

export interface CustomProfileFormDto {
  formId: string;
  formName: string;
  companyId: number;
  formTab: ProfileTab;
  isDefault: boolean;
  supportsCustomFields: boolean;
  createdAt: string;
  updatedAt: string;
  fields?: CustomProfileFieldDto[];
  displayOrder: number | null;
}

export interface CustomProfileFieldDto {
  fieldId: string;
  formId: string;
  placeholder: string;
  fieldName: string;
  defaultValue: string;
  isDefault: boolean;
  isRequired: boolean;
  fieldStub: string | null; // this will only be populated in default fields
  isHidden: boolean;
  options?: OptionInterface;
  validation?: string;
  companyId: number;
  fieldType: CustomProfileFieldType;
  createdAt?: Date;
  updatedAt?: Date;
  isEditable: boolean;
  form?: { formName: string };
  paycode: string | null;
  displayOrder: number | null;
}

export interface UserCustomDataDto {
  value: string;
  field: Pick<
    CustomProfileFieldDto,
    'formId' | 'fieldId' | 'fieldType' | 'fieldName' | 'isRequired' | 'isHidden' | 'options' | 'paycode'
  >;
  fieldId: string;
  formId: string;
}

export interface UserCustomDataValue {
  fieldId: string;
  formId: string;
  value: string;
}

export interface CombinedAllFields {
  dataFieldsDefault: CustomProfileFieldDto[];
  dataFieldsCustom: CustomProfileFieldDto[];
}

export enum CustomProfileFieldType {
  Input = 'Input',
  Select = 'Select',
  Date = 'Date',
  DateInput = 'Date input',
  DatePicker = 'Date picker',
  Number = 'Number',
  Multiselect = 'Multiselect',
}
