import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AccrualUnit } from '@v2/feature/absence/absence.interface';
import Polyglot from 'node-polyglot';

export function getAccrualFrequency(
  absencePolicy: Pick<AbsencePolicyDto, 'accrualUnit' | 'accrualUnitsNo'>,
  polyglot: Polyglot
): string {
  if (!absencePolicy.accrualUnit || !absencePolicy.accrualUnitsNo) return '';

  if (absencePolicy.accrualUnit === AccrualUnit.Day)
    return polyglot.t('AbsenceModule.AccrualFrequency.everyXDays', { smart_count: absencePolicy.accrualUnitsNo });

  if (absencePolicy.accrualUnit === AccrualUnit.Week)
    return polyglot.t('AbsenceModule.AccrualFrequency.everyXWeeks', { smart_count: absencePolicy.accrualUnitsNo });

  if (absencePolicy.accrualUnit === AccrualUnit.Month)
    return polyglot.t('AbsenceModule.AccrualFrequency.everyXMonths', { smart_count: absencePolicy.accrualUnitsNo });

  return '';
}
