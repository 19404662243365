import { useContext } from 'react';

import { Box } from '@mui/material';
import { Dictionary } from 'lodash';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { QuestionTypeMap } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { SurveyQuestion } from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { SurveyTemplate } from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';

export const SurveyQuestionBuilder = ({
  questionOptions,
  question,
  growthFactors,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  template,
  showAction,
}: {
  questionOptions: readonly OptionProps[];
  question: Pick<
    SurveyQuestion,
    'companyId' | 'questionText' | 'hasComment' | 'createdBy' | 'factor' | 'scaleConfig' | 'type'
  >;
  growthFactors: Dictionary<GrowthFactor>;
  template?: SurveyTemplate;
  showAction: boolean;
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = user?.company?.name ?? undefined;
  const cleanedQuestionText = replaceParamsInQuestionText(stripHtml(question.questionText), { company_name }) ?? '';

  return (
    <Box
      sx={{
        bgcolor: themeColors.white,
        p: spacing.p16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${themeColors.lightGrey}`,
        borderBottom: `1px solid ${themeColors.lightGrey}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.g8,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing.g4,
          }}
        >
          <Typography variant="title4">{cleanedQuestionText}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing.g4,
          }}
        >
          {question.factor && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.g4,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: growthFactors[question.factor]?.color ?? themeColors.Grey,
                      height: '8px',
                      width: '8px',
                      borderRadius: radius.br25,
                    }}
                  />
                  <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                    {question.factor}
                  </Typography>
                </Box>
              }
            />
          )}

          {question.type && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Typography variant="caption" color="Grey">
                  {QuestionTypeMap[question.type]}
                </Typography>
              }
            />
          )}

          {question.type && question.type === 'scaleQuestion' && question.scaleConfig && (
            <ChipComponent
              backgroundColor="white"
              border="background"
              name={
                <Typography variant="caption" sx={{ color: themeColors.Grey }}>
                  {question.scaleConfig.type}
                </Typography>
              }
            />
          )}

          {question.hasComment && (
            <Typography variant="caption" sx={{ color: themeColors.Grey }}>
              Comment
            </Typography>
          )}
        </Box>
      </Box>

      {showAction && (
        <StyledMenuComponent
          options={questionOptions}
          actionButtonDetails={{
            type: 'iconButton',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'actions',
            icon: <ActionsSmall {...iconSize} />,
          }}
        />
      )}
    </Box>
  );
};
