import ReactApexChart from 'react-apexcharts';

import { SeriesData } from '@/v2/feature/app-integration/app-integration.interface';
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import './apex-charts.styles.css';

export interface ChartPieProps {
  series: number[] | SeriesData[];
  labels: string[];
}

export type shadeToMode = 'light' | 'dark';

export function ChartPie({ series, labels }: ChartPieProps) {
  const chartOptions = {
    labels: labels,
    stroke: { show: false },
    tooltip: {
      y: {
        formatter: (val: number) => `${val}`,
      },
      style: { fontFamily: 'Inter, sans-serif !important', fontWeight: 400, fontSize: '12px' },
      custom: function ({ series, seriesIndex }: { series: any; seriesIndex: any }) {
        return `<div class="arrow_box">${series[seriesIndex]}</div>`;
      },
    },
    show: true,
    dataLabels: {
      enabled: false,
    },
    label: 'Total',
    ...themeFonts.caption,
    colors: [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    plotOptions: { pie: { customScale: 0.9, donut: { size: '85%' } } },
    noData: { text: 'No data' },
    legend: {
      horizontalAlign: 'right' as 'left' | 'center' | 'right',
      position: 'right' as 'top' | 'right' | 'bottom' | 'left',
      fontFamily: 'Inter, sans-serif !important',
      fontWeight: 400,
      fontSize: '12px',
      labels: { colors: [themeColors.DarkGrey] },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 16,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    chart: {
      fontFamily: 'Inter, sans-serif !important',
      toolbar: {
        show: false,
      },
      offsetY: 20,
      offsetX: -8,
    },
  };

  return <ReactApexChart type="pie" series={series} options={chartOptions} height="400px" />;
}
