// eslint-disable-next-line filenames/match-regex
import { useContext, useMemo } from 'react';

import { AbsenceCompanyRouter } from '@v2/feature/absence/company/absence-company.router';
import { AbsenceMeRouter } from '@v2/feature/absence/me/absence-me.router';
import { AbsenceTeamRouter } from '@v2/feature/absence/team/absence-team.router';
import { ApprovalRuleEndpoints } from '@v2/feature/approval-rule/approval-rule.api';
import { UserEndpoints } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import { ABSENCE_COMPANY_ROUTE, ABSENCE_ME_ROUTE, ABSENCE_TEAM_ROUTE } from '@/lib/routes';

export const AbsenceRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false,
  });
  const { data: approvalRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });

  const isManager = useMemo(() => {
    const nonTerminatedDirectReports =
      userSummaryWithReports?.directReports?.filter(
        (d) => d.userEvent && d.userEvent.status && ['Employed', 'Hired'].includes(d.userEvent.status)
      )?.length ?? 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);

  return approvalRules ? (
    <Switch>
      <RouteScopesHas scopes={['absence:all']} path={ABSENCE_COMPANY_ROUTE}>
        <AbsenceCompanyRouter />
      </RouteScopesHas>

      {isManager && (
        <RouteScopesHas scopes={['absence']} path={ABSENCE_TEAM_ROUTE}>
          <AbsenceTeamRouter />
        </RouteScopesHas>
      )}

      <RouteScopesHas scopes={['absence']} path={ABSENCE_ME_ROUTE}>
        <AbsenceMeRouter />
      </RouteScopesHas>
    </Switch>
  ) : null;
};
