import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { OneOffPaymentDto } from '@v2/feature/payroll/features/pay-item/pay-item.dto';

import { ViewUpcomingPayItemDrawer } from '@/component/dashboard/userDetails/pay-items/components/view-upcoming-pay-item-drawer.component';

export const PayItemsPreviewList = ({
  oneOffPayments,
  refresh,
}: {
  oneOffPayments: OneOffPaymentDto[];
  refresh: () => Promise<void>;
}) => {
  const [selectedPayment, setSelectedPayment] = useState<OneOffPaymentDto | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  // TODO: @polyglot-later
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {oneOffPayments.map((p) => (
          <Typography
            key={p.id}
            variant="caption"
            onClick={() => {
              setSelectedPayment(p);
              setIsOpen(true);
            }}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            Pay item - {p.date}
          </Typography>
        ))}
      </Box>
      {isOpen && selectedPayment && (
        <ViewUpcomingPayItemDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          oneOffPayment={selectedPayment}
          refresh={refresh}
          onClose={() => setSelectedPayment(null)}
        />
      )}
    </Box>
  );
};
