import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { Typography } from '@v2/components/typography/typography.component';
import {
  DeferForceAtUserLoginMaxBypassAttemptsOptions,
  FilevaultEnabled,
  ZeltPolicyPayloadIdentifier,
} from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { FormikProvider, useFormik } from 'formik';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  FileVaultOptions,
} from '@/v2/feature/device/device.dto';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { spacing } from '@/v2/styles/spacing.styles';

export const ZeltMdmEncryptionPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen,
}: {
  updatePolicies: (configurablePolicies?: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getPolicy = (): FileVaultOptions => {
    return {
      Enable: configurablePoliciesSelected?.configurableEncryptionPolicy?.Enable ?? 'Off',
      Defer: true,
      ShowRecoveryKey: configurablePoliciesSelected?.configurableEncryptionPolicy?.ShowRecoveryKey ?? false,
      DeferForceAtUserLoginMaxBypassAttempts:
        configurablePoliciesSelected?.configurableEncryptionPolicy?.DeferForceAtUserLoginMaxBypassAttempts ?? -1,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FILE_VAULT_PAYLOAD_IDENTIFIER.valueOf(),
    };
  };

  const initialValues: FileVaultOptions = getPolicy();
  function analyzePolicyBeforeSave(policy: FileVaultOptions) {
    if (policy.Enable === 'Off') {
      return undefined;
    }
    return {
      Enable: policy.Enable,
      Defer: policy.Defer,
      ShowRecoveryKey: policy.ShowRecoveryKey,
      DeferForceAtUserLoginMaxBypassAttempts: policy.DeferForceAtUserLoginMaxBypassAttempts,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FILE_VAULT_PAYLOAD_IDENTIFIER.valueOf(),
    };
  }
  const formik = useFormik<FileVaultOptions>({
    initialValues,
    onSubmit: async (form: FileVaultOptions) => {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      await updatePolicies({
        ...configurablePoliciesSelected,
        configurableEncryptionPolicy: configurablePolicy,
      });
      if (isOpen) {
        setIsOpen(false);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="title2">Passcode</Typography>

      <FormikProvider value={formik}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: '20px' }}>
          <Box sx={fieldSx}>
            <SelectComponent
              name="enable"
              label="Enable FileVault"
              options={FilevaultEnabled}
              value={formik.values.Enable}
              onChange={(e) => {
                const enable = e?.target.value;
                formik.setFieldValue('Enable', enable);
              }}
            />
          </Box>
          <CheckboxComponent
            label="Show recovery key"
            name="ShowRecoveryKey"
            checked={formik.values.ShowRecoveryKey}
            onChange={(e, checked) => {
              formik.setFieldValue('ShowRecoveryKey', checked);
            }}
          />
          <Box sx={fieldSx}>
            <SelectComponent
              name="DeferForceAtUserLoginMaxBypassAttempts"
              label="Max defer attempts"
              options={DeferForceAtUserLoginMaxBypassAttemptsOptions}
              value={formik.values.DeferForceAtUserLoginMaxBypassAttempts}
              onChange={(e) => {
                const deferForceAtUserLoginMaxBypassAttempts = e?.target.value;
                formik.setFieldValue('DeferForceAtUserLoginMaxBypassAttempts', deferForceAtUserLoginMaxBypassAttempts);
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '20px' }}>
          <ButtonComponent onClick={() => formik.handleSubmit()} fullWidth colorVariant="primary" sizeVariant="medium">
            Save
          </ButtonComponent>
        </Box>
      </FormikProvider>
    </Box>
  );
};
