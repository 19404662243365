import { LocalDate } from '@v2/util/local-date';
import { compareAsc, format, formatDistanceStrict, isValid, parseISO } from 'date-fns';

// function to remove T and Z from an ISO string, so as to display the date in local timezone and not UTC
export function removeTZ(d: string): string {
  return d.replace('T', ' ').replace('Z', '');
}

// replacement function for existing dateFns Format, which ends up displaying
// dates one off because it takes local time zone into effect, rather than the raw date
export function localFormat(dateFormat: string, d: Date): string {
  const fixedDate = new Date(removeTZ(d.toISOString()));
  return format(fixedDate, dateFormat);
}

export function formatShortMonthYear(date: number | string | Date): string {
  // 'Feb 2003'
  const d = typeof date === 'string' ? parseISO(removeTZ(date)) : date;
  return format(d, 'MMM yyyy');
}

export function formatLongMonthYear(date: number | string | Date): string {
  // 'February 2003'
  const d = typeof date === 'string' ? parseISO(removeTZ(date)) : date;
  return format(d, 'MMMM yyyy');
}

export function formatMediumDate(date: number | string | Date, withDayOfWeek = false): string {
  // '[Sat ]1 Feb 2003'
  const d = typeof date === 'string' ? parseISO(removeTZ(date)) : date;
  return format(d, withDayOfWeek ? 'E d MMM yyyy' : 'd MMM yyyy');
}

export function formatLongDate(date: number | string | Date, withDayOfWeek = false): string {
  // '[Sat ]1 February 2003'
  const d = typeof date === 'string' ? parseISO(removeTZ(date)) : date;
  return format(d, withDayOfWeek ? 'E d MMMM yyyy' : 'd MMMM yyyy');
}

export function timeAgo(dateStr: string): string | null {
  if (!dateStr) return null;
  const timeString = formatDistanceStrict(parseISO(dateStr), new Date(), { addSuffix: false });
  return compareAsc(parseISO(removeTZ(dateStr)), new Date()) === 1 ? `in ${timeString}` : `${timeString} ago`;
}

export function dateAPItoDisplay(dateStr: string | Date, withDayOfWeek = false): string | null {
  if (typeof dateStr === 'string')
    return dateStr ? format(parseISO(removeTZ(dateStr)), withDayOfWeek ? `E d LLL, yyyy` : 'd LLL, yyyy') : null;

  return dateStr
    ? format(parseISO(removeTZ(dateStr.toISOString())), withDayOfWeek ? `E d LLL, yyyy` : 'd LLL, yyyy')
    : null;
}

export function dateAPItoUILong(dateStr: string, withDayOfWeek = false): string | null {
  return dateStr ? formatLongDate(parseISO(removeTZ(dateStr)), withDayOfWeek) : null;
}

export const createDateAsUTC = (dateString: string): Date => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  );
};

export const createShortDateAsUTC = (dateString: string | Date): string => {
  if (!isValid(dateString)) return dateString as string;
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  )
    .toISOString()
    .substring(0, 10);
};

export const todaysDateShortISOString = () => new LocalDate().toDateString();

export const recentDateStrings = () => {
  const now = new Date();
  const yesterday = new Date(now.getTime() - 24 * 60 * 60e3);
  const tomorrow = new Date(now.getTime() + 24 * 60 * 60e3);
  const twoDigit = (n: number) => `0${n}`.slice(-2);
  // "2020-03-24"
  return {
    yesterday: `${yesterday.getFullYear()}-${twoDigit(yesterday.getMonth() + 1)}-${twoDigit(yesterday.getDate())}`,
    today: `${now.getFullYear()}-${twoDigit(now.getMonth() + 1)}-${twoDigit(now.getDate())}`,
    tomorrow: `${tomorrow.getFullYear()}-${twoDigit(tomorrow.getMonth() + 1)}-${twoDigit(tomorrow.getDate())}`,
  };
};

export function convertDateFields<T>(object: T, dateFields: (keyof T)[]): T {
  // convert a list of fields from strings into Date instances
  return {
    ...object,
    ...Object.fromEntries(
      dateFields
        .filter((dateField) => object[dateField])
        .map((dateField) => [dateField, new Date(object[dateField] as string)])
    ),
  };
}
