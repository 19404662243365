import { useCallback, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { NumberCell } from '@v2/components/table/number-cell.component';
import { CustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import {
  getOpeningBalanceColumns,
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { ViewUserCustomBenefitDrawer } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/view-user-custom-benefit-drawer.component';
import { formatMoney } from '@v2/feature/payments/utils/money.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { keyBy } from 'lodash';
import Polyglot from 'node-polyglot';
import { useParams } from 'react-router-dom';

import { BENEFITS_COMPANY_ROUTE } from '@/lib/routes';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CustomBenefitEndpoints } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const getTabFilter = (polyglot: Polyglot) => [
  { name: polyglot.t('General.member'), value: 'Member' },
  { name: polyglot.t('BenefitModule.notIncluded'), value: 'Not included' },
  { name: polyglot.t('General.all'), value: 'All' },
];

interface TableRow extends Partial<UserCustomBenefitDto> {
  userId: number;
  included: boolean;
  customBenefitId: number;
}

export const CustomBenefitEmployeeList = ({
  loading,
  customBenefits,
}: {
  readonly loading: boolean;
  readonly customBenefits: CustomBenefitDto[] | null;
}) => {
  const params: { benefitId: string } = useParams();
  const customBenefitId = Number(params.benefitId);

  const {
    data: userCustomBenefits,
    mutate: refreshUserCustomBenefits,
  } = useApiClient(CustomBenefitEndpoints.getAllUserCustomBenefits(customBenefitId), { suspense: false });

  const { polyglot } = usePolyglot();

  const currentBenefit = useMemo(() => customBenefits?.find((c) => c.id === customBenefitId), [
    customBenefits,
    customBenefitId,
  ]);

  const isLoan = useMemo(() => isLoanBenefit(currentBenefit?.type), [currentBenefit]);
  const isRecurring = useMemo(() => isRecurringBenefit(currentBenefit?.type), [currentBenefit]);

  const [filterValue, setFilterValue] = useState<string>('Member');
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedUserCustomBenefit, setSelectedUserCustomBenefit] = useState<TableRow | null>(null);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  const { nonTerminatedCachedUsers } = useCachedUsers({ refresh: true });

  const userCustomBenefitsByUserId = useMemo(
    () => (userCustomBenefits ? keyBy(userCustomBenefits, 'userId') : ({} as Record<number, UserCustomBenefitDto>)),
    [userCustomBenefits]
  );

  const refreshUserMembers = useCallback(async () => {
    if (refreshUserCustomBenefits) await refreshUserCustomBenefits();
  }, [refreshUserCustomBenefits]);

  const filteredUserRecords = useMemo((): TableRow[] => {
    function sortUsers(a: CachedUser, b: CachedUser) {
      const aIncluded = !!userCustomBenefitsByUserId[a.userId];
      const bIncluded = !!userCustomBenefitsByUserId[b.userId];

      if (aIncluded && !bIncluded) return -1;
      if (!aIncluded && bIncluded) return 1;
      return 0;
    }

    let filteredUserRecords = [...nonTerminatedCachedUsers];

    if (filterValue !== 'All') {
      filteredUserRecords = filteredUserRecords.filter((user) => {
        if (filterValue === 'Member') return userCustomBenefitsByUserId[user.userId];
        if (filterValue === 'Not included') return !userCustomBenefitsByUserId[user.userId];
        return true;
      });
    }

    if (searchInput) {
      const lowerSearch = searchInput.toLocaleLowerCase();
      filteredUserRecords = filteredUserRecords.filter((u) => u.displayName.toLocaleLowerCase().includes(lowerSearch));
    }

    return filteredUserRecords
      .sort(sortUsers)
      .map((user) =>
        user.userId && userCustomBenefitsByUserId[user.userId]
          ? { ...userCustomBenefitsByUserId[user.userId], included: true }
          : { customBenefit: currentBenefit, customBenefitId, userId: user.userId, included: false }
      );
  }, [currentBenefit, customBenefitId, nonTerminatedCachedUsers, filterValue, searchInput, userCustomBenefitsByUserId]);

  const columnData = useMemo<ColumnDef<TableRow, TableRow>[]>(() => {
    const openingBalanceColumns: ColumnDef<TableRow, TableRow>[] = [];
    const hasOpeningBalance = isUsingOpeningBalance(currentBenefit?.type);

    if (hasOpeningBalance)
      openingBalanceColumns.push(...(getOpeningBalanceColumns(isLoan, polyglot) as ColumnDef<TableRow, TableRow>[]));

    return [
      {
        header: () => polyglot.t('General.name'),
        accessorFn: (row) => row,
        id: 'userId',
        enableSorting: false,
        cell: (info) => {
          const user = info.getValue();
          return <UserCell userId={user.userId} />;
        },
      },
      ...openingBalanceColumns,
      ...(isRecurring
        ? [
            {
              header: () => polyglot.t('BenefitModule.employerContribution'),
              accessorFn: (row: TableRow) => row,
              id: 'employerContribution',
              enableSorting: false,
              cell: (userObj: CellContext<TableRow, TableRow>) => {
                const userBenefit = userObj.getValue();
                return (
                  <NumberCell
                    value={
                      userBenefit?.employerContribution
                        ? formatMoney({
                            amount: userBenefit.employerContribution,
                            asDecimal: true,
                          })
                        : null
                    }
                  />
                );
              },
            },
            {
              header: () => polyglot.t('BenefitModule.employeeContribution'),
              accessorFn: (row: TableRow) => row,
              id: 'employeeContribution',
              enableSorting: false,
              cell: (userObj: CellContext<TableRow, TableRow>) => {
                const userBenefit = userObj.getValue();
                return (
                  <NumberCell
                    value={
                      userBenefit?.employeeContribution
                        ? formatMoney({
                            amount: userBenefit.employeeContribution,
                            asDecimal: true,
                          })
                        : null
                    }
                  />
                );
              },
            },
            {
              header: () => polyglot.t('BenefitModule.dependants'),
              accessorFn: (row: TableRow) => row,
              id: 'dependants',
              enableSorting: false,
              cell: (userObj: CellContext<TableRow, TableRow>) => {
                const userBenefit = userObj.getValue();
                return userBenefit?.dependants ? (
                  <Typography sx={themeFonts.caption}>{userBenefit.dependants}</Typography>
                ) : (
                  <EmptyCell />
                );
              },
            },
          ]
        : []),
      {
        header: () => polyglot.t('General.status'),
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: (userObj) => {
          const userBenefit = userObj.getValue();
          return (
            <Typography sx={themeFonts.caption}>
              {userBenefit.included ? polyglot.t('General.member') : polyglot.t('BenefitModule.notIncluded')}
            </Typography>
          );
        },
      },
    ];
  }, [isLoan, isRecurring, currentBenefit, polyglot]);

  const handleRowClick = useCallback((row: Row<TableRow>) => {
    setSelectedUserCustomBenefit(row.original);
    setIsViewOpen(true);
  }, []);

  return currentBenefit ? (
    <RootStyle>
      <TopHeader title={currentBenefit.name} showBack backPath={BENEFITS_COMPANY_ROUTE} />

      <ContentWrapper loading={loading}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: spacing.g10,
            alignItems: 'center',
          }}
        >
          <TabFilterButtons
            filters={getTabFilter(polyglot)}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            hasSearch
            onFilterChange={({ filterValue, searchInput }) => {
              setFilterValue(filterValue);
              setSearchInput(searchInput);
            }}
          />
        </Box>
        <Box sx={{ marginTop: spacing.m15 }}>
          <BasicTable<TableRow>
            rowData={filteredUserRecords}
            columnData={columnData}
            loading={loading}
            hidePagination
            rowClick={handleRowClick}
          />
        </Box>

        {selectedUserCustomBenefit && (
          <ViewUserCustomBenefitDrawer
            isOpen={isViewOpen}
            setIsOpen={setIsViewOpen}
            userBenefit={selectedUserCustomBenefit}
            onClose={() => {
              setSelectedUserCustomBenefit(null);
              setIsViewOpen(false);
            }}
            refresh={refreshUserMembers}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  ) : null;
};
