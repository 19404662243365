import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Trophy } from '@/images/dashboard-icons/Trophy.svg';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { ReviewActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/review-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { PendingReviewsBigWidget, ReviewsTodos } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { iconSize } from '@/v2/styles/menu.styles';

export const PendingReviewsTodo = ({
  todo,
  setLastChild,
}: {
  todo: PendingReviewsBigWidget;
  setLastChild: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<ReviewsTodos | undefined>(undefined);

  const getTitle = (review: ReviewsTodos) => {
    if (review.reviewType === 'Self')
      return (
        <Typography
          variant="caption"
          sx={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {polyglot.t('getDetailByDomain.completeReview', {
            reviewType: review.reviewType,
            cycleName: review.cycleName,
            user: getCachedUserById(review.userId)?.displayName,
            date: getDateString(review.dueDate, false, 'd MMM yyyy'),
          })}
        </Typography>
      );
    else
      return (
        <Typography
          variant="caption"
          sx={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {polyglot.t('getDetailByDomain.completeReview', {
            reviewType: review.reviewType,
            cycleName: review.cycleName,
            user: getCachedUserById(review.userId)?.displayName,
            date: getDateString(review.dueDate, false, 'd MMM yyyy'),
          })}
        </Typography>
      );
  };

  return (
    <Box>
      {todo.reviews.map((a, idx) => {
        return (
          <TodoCard
            key={`-${idx}-${a.reviewType}`}
            icon={<Trophy {...iconSize} />}
            title={getTitle(a)}
            actionOnclick={() => setSelectedRow(a)}
            showBorder={!(setLastChild && todo.reviews.length === idx + 1)}
            userAvatar={<UserAvatar userId={a.userId} size="xxxsmall" />}
          />
        );
      })}

      {selectedRow && (
        <ReviewActionItem reviewActionRow={selectedRow as ReviewsTodos} afterClose={() => setSelectedRow(undefined)} />
      )}
    </Box>
  );
};
