import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { ATTENDANCE_COMPANY_REQUESTS_ROUTE } from '@/lib/routes';
import { AttendanceCompanyView } from '@/v2/feature/attendance/company/pages/attendance-company-requests/attendance-company-view.page';

export const AttendanceCompanyRouter = () => {
  return (
    <Switch>
      <RouteScopesHas scopes={['attendance:all']} path={ATTENDANCE_COMPANY_REQUESTS_ROUTE}>
        <AttendanceCompanyView view="company" />
      </RouteScopesHas>

      <Redirect to={ATTENDANCE_COMPANY_REQUESTS_ROUTE} />
    </Switch>
  );
};
