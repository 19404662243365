import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Avatar, Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortNumeric } from '@v2/components/table/table-sorting.util';
import { UserCell } from '@v2/components/table/user-cell.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { NewIdCheckPackageDrawer } from '@/v2/feature/id-check/components/new-id-check-package-drawer.page';
import { IdCheckEndpoints } from '@/v2/feature/id-check/id-check.api';
import { IdCheckPackage } from '@/v2/feature/id-check/id-check.interface';
import { mapPackageDetailsToString } from '@/v2/feature/id-check/id-check.util';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { formatAsGBPCurrency } from '@/v2/util/string-format.util';

const zeltLogo = '/app-icons-v2/images/zelt.png';

export const IdCheckSettingsPage = (): React.JSX.Element => {
  const {
    data: idCheckPackages,
    isLoading: loadingIdCheckPackages,
    mutate: refreshIdCheckPackages,
  } = useApiClient(IdCheckEndpoints.getIdCheckPackages(), { suspense: false });
  const {
    data: individualChecks,
    isLoading: loadingIndividualChecks,
    mutate: refreshIndividualChecks,
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { polyglot } = usePolyglot();

  const [searchInput, setSearchInput] = useState<string>('');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<IdCheckPackage | null>(null);

  const refresh = useCallback(async () => {
    if (refreshIdCheckPackages) await refreshIdCheckPackages();
    if (refreshIndividualChecks) await refreshIndividualChecks();
  }, [refreshIdCheckPackages, refreshIndividualChecks]);

  const filteredPackages = useMemo(() => {
    if (!idCheckPackages) return [];
    if (!searchInput) return idCheckPackages.sort((a, b) => a.id - b.id);

    const search = searchInput.toLowerCase();
    return idCheckPackages
      .sort((a, b) => a.id - b.id)
      .filter((checkPackage) => checkPackage.name.toLowerCase().includes(search));
  }, [searchInput, idCheckPackages]);

  const columnData = useMemo<ColumnDef<IdCheckPackage, IdCheckPackage>[]>(() => {
    return [
      {
        header: () => polyglot.t('IdCheckPackage.packageName'),
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        cell: (info) => {
          const rule = info.getValue();
          return (
            <Box>
              <Typography variant="caption">{rule.name}</Typography>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('IdCheckPackage.checksIncluded'),
        accessorFn: (row) => row,
        id: 'packageDetails',
        enableSorting: false,
        cell: (info) => {
          const idPackage = info.getValue();
          const { packageDetails } = idPackage;
          const usesCustomPackage = idPackage && packageDetails ? 'customPackage' in packageDetails : false;
          return (
            <Box>
              {!usesCustomPackage && idPackage.packageDetails ? (
                <Typography variant="caption">
                  {mapPackageDetailsToString(idPackage.packageDetails, polyglot)}
                </Typography>
              ) : usesCustomPackage && idPackage.packageDetails ? (
                <Typography variant="caption">{idPackage.name}</Typography>
              ) : (
                <EmptyCell />
              )}
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('IdCheckPackage.price'),
        accessorFn: (row) => row,
        id: 'price',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.price),
        cell: (info) => {
          const idPackage = info.getValue();
          return (
            <Box>
              {idPackage.price ? (
                <Typography variant="caption">{formatAsGBPCurrency(idPackage.price, 2)}</Typography>
              ) : (
                <EmptyCell />
              )}
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('General.createdBy'),
        accessorFn: (row) => row,
        id: 'createdBy',
        enableSorting: false,
        cell: (info) => {
          const idPackage = info.getValue();
          return !idPackage.createdBy ? (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Avatar src={zeltLogo} alt={'zelt'} style={iconSize} />
              <Typography variant={'caption'} sx={{ ml: spacing.m10 }}>
                Zelt
              </Typography>
            </Box>
          ) : (
            <UserCell userId={idPackage.createdBy} />
          );
        },
      },
    ];
  }, [polyglot]);

  const handleRowClick = useCallback((row: Row<IdCheckPackage>) => {
    setSelectedPackage(row.original);
    setIsDrawerOpen(true);
  }, []);

  const isLoading = loadingIdCheckPackages || loadingIndividualChecks;

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('SettingsRouterPageConfig.idCheckPackages')}</Typography>}
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() => {
              setSelectedPackage(null);
              setIsDrawerOpen(true);
            }}
          >
            {polyglot.t('IdCheckPackage.newPackage')}
          </ButtonComponent>
        }
        showAction
      />

      <ContentWrapper loading={isLoading} sx={{}}>
        <Box>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ width: '250px', minWidth: '250px' }}
          />
        </Box>
        <Box sx={{ mt: '10px' }}>
          <BasicTable<IdCheckPackage>
            rowData={filteredPackages}
            columnData={columnData}
            loading={isLoading}
            hidePagination
            rowClick={handleRowClick}
          />
        </Box>
        {isDrawerOpen && (
          <NewIdCheckPackageDrawer
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            currentUser={currentUser}
            idCheckPackage={selectedPackage}
            individualChecks={individualChecks ?? []}
            refresh={refresh}
          />
        )}
        {/* <ApprovalRuleDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          approvalRule={selectedPackage}
          afterClose={() => {
            setSelectedPackage(null);
            setIsDrawerOpen(false);
          }}
          refresh={refresh}
        /> */}
      </ContentWrapper>
    </RootStyle>
  );
};
