import { DocumentAttachment } from '@v2/feature/absence/sections/absence-drawer/attachment-in-drawer.component';
import axios from 'axios';

import { UploadState } from './bulk-upload-documents.context';
import { DocumentBulkEditValues } from './components/document-confirm-bulk-edit-modal.component';
import { PreviewPayload } from './documents.interface';

import {
  CreateZeltDocument,
  DocumentExport,
  DocumentPageResult,
  ZeltDocument,
  ZeltDocumentType,
  ZeltDocumentVerification,
} from '@/lib/documents';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class DocumentAPI {
  static getUrlParams(param?: {
    page: number;
    pageSize: number;
    searchQuery: string;
    filterString: string;
    statusFilter: string;
    shouldLimitResult?: boolean;
    order?: string;
  }): URLSearchParams | undefined {
    let searchParams;

    if (param) {
      searchParams = new URLSearchParams({
        page: param.page.toString(),
        pageSize: param.pageSize.toString(),
        searchQuery: param.searchQuery,
        filter: param.filterString || '',
        statusFilter: param.statusFilter || '',
        ...(param?.order ? { order: param.order } : {}),
        ...(param?.shouldLimitResult ? { shouldLimitResult: String(param?.shouldLimitResult) } : {}),
      });
    }

    return searchParams;
  }

  static async listDocumentsV2(param?: {
    page: number;
    pageSize: number;
    searchQuery: string;
    filterString: string;
    statusFilter: string;
    shouldLimitResult?: boolean;
    order?: string;
  }): Promise<DocumentPageResult> {
    let searchParams = DocumentAPI.getUrlParams(param);
    const BASE_URL = '/apiv2/documents/all-v2';
    const URL = searchParams ? `${BASE_URL}?${searchParams.toString()}` : BASE_URL;
    return (await axios.get<DocumentPageResult>(URL)).data;
  }

  static async listCompanyDocumentsForExport(): Promise<DocumentExport[]> {
    const URL = '/apiv2/documents/company/export';
    return (await axios.get<DocumentExport[]>(URL)).data;
  }

  static async listTeamDocuments(param?: {
    page: number;
    pageSize: number;
    searchQuery: string;
    filterString: string;
    statusFilter: string;
    shouldLimitResult?: boolean;
  }): Promise<DocumentPageResult> {
    let searchParams = DocumentAPI.getUrlParams(param);

    const BASE_URL = '/apiv2/documents/all/team';
    const URL = searchParams ? `${BASE_URL}?${searchParams.toString()}` : BASE_URL;
    return (await axios.get<DocumentPageResult>(URL)).data;
  }

  static async downloadViaUuid(fileUuid: string, originalFilename?: string): Promise<string> {
    return (await axios.post<string>('/apiv2/documents/download-via-uuid', { fileUuid, originalFilename })).data;
  }

  static async downloadBlobViaUuid(fileUuid: string): Promise<Blob> {
    return (await axios.get(`/apiv2/files/id/${fileUuid}`, { responseType: 'blob' })).data;
  }

  static async bulkDeleteDocuments(documentIds: number[]): Promise<{ deletedCount: number }> {
    return (await axios.post(`/apiv2/documents/bulk/delete`, { documentIds })).data;
  }

  static async bulkEditDocuments(documentIds: number[], updatedDocumentValues: DocumentBulkEditValues): Promise<void> {
    await axios.patch(`/apiv2/documents/bulk/edit`, { documentIds, updatedDocumentValues });
  }

  static async downloadViaId(id: number): Promise<{ url: string; fileName: string }> {
    return (await axios.post<{ url: string; fileName: string }>('/apiv2/documents/download-via-id', { id })).data;
  }

  static async getDocumentAttachmentByUuid(fileUuid: string): Promise<DocumentAttachment> {
    return (await axios.get(`/apiv2/files/uuid/${fileUuid}/attachment`)).data;
  }

  static async previewViaUuid(fileUuid: string): Promise<PreviewPayload> {
    return (await axios.get<PreviewPayload>(`/apiv2/documents/preview-via-uuid/${fileUuid}`)).data;
  }

  static async shareDocumentViaEmail(fileUuid: string, recipientId: number, note: string | null): Promise<void> {
    await axios.post<void>(`/apiv2/documents/share-document-via-email`, { fileUuid, recipientId, note });
  }

  static async listP60Documents(): Promise<readonly ZeltDocument[]> {
    return (await axios.get<readonly ZeltDocument[]>('/apiv2/documents/p60')).data;
  }

  static async listP60DocumentsForUser(userId: number): Promise<readonly ZeltDocument[]> {
    return (await axios.get<readonly ZeltDocument[]>(`/apiv2/documents/p60/${userId}`)).data;
  }

  static async deleteDocumentById(documentId: number): Promise<void> {
    await axios.delete(`/apiv2/documents/${documentId}`);
  }

  static async deleteDocumentByIdAsSuperAdmin(documentId: number): Promise<void> {
    await axios.delete(`/apiv2/documents/superadmin/${documentId}`);
  }

  static async verifyDocumentById(documentId: number): Promise<ZeltDocumentVerification> {
    return (await axios.post(`/apiv2/documents/${documentId}/verify`)).data;
  }

  static async getDocumentById(documentId: number): Promise<ZeltDocument> {
    return (await axios.get(`/apiv2/documents/${documentId}`)).data;
  }

  static async listDocumentTypes(): Promise<ZeltDocumentType[]> {
    return (await axios.get('/apiv2/documents/types')).data;
  }

  static async updateDocument(documentId: number, document: CreateZeltDocument): Promise<ZeltDocument> {
    return (await axios.patch<ZeltDocument>(`/apiv2/documents/${documentId}`, document)).data;
  }

  static async createDocument(document: CreateZeltDocument): Promise<ZeltDocument> {
    return (await axios.post('/apiv2/documents', document)).data;
  }

  static async listUserDocuments(userId: number): Promise<ZeltDocument[]> {
    return (await axios.get<ZeltDocument[]>(`/apiv2/documents/users/${userId}`)).data;
  }

  static async createUserDocument(userId: number, document: CreateZeltDocument): Promise<ZeltDocument> {
    return (await axios.post(`/apiv2/documents/users/${userId}`, document)).data;
  }

  static async createP45Document(userId: number, document: CreateZeltDocument): Promise<void> {
    await axios.post(`/apiv2/documents/users/${userId}/p45`, document);
  }

  static async uploadDocument(fileData: string, mapping: UploadState): Promise<boolean> {
    return await axios.post(`/apiv2/documents/upload`, {
      fileData,
      mapping,
    });
  }

  static async getAlerts(userId: number): Promise<Alert<ZeltDocument>> {
    return (await axios.get(`/apiv2/documents/${userId}/alerts`)).data;
  }

  static async uploadFileViaURL(documentLink: string): Promise<string> {
    return (await axios.post(`/apiv2/files/file-via-url`, { documentLink })).data;
  }

  static async getPinnedDocuments(param: { reach: string }): Promise<ZeltDocument[]> {
    const URL = `/apiv2/documents/pinned?${new URLSearchParams({
      reach: param.reach,
    }).toString()}`;
    return (await axios.get(URL)).data;
  }

  static async remindUserForPendingDocument(pendingDocumentName: string, belongsToUserId: number): Promise<void> {
    return await axios.post('/apiv2/documents/remind-pending-upload', { pendingDocumentName, belongsToUserId });
  }

  static async getDocumentsByCategory(param: { category: string }): Promise<ZeltDocument[]> {
    const URL = `/apiv2/documents/by-category?${new URLSearchParams({
      category: param.category,
    }).toString()}`;
    return (await axios.get(URL)).data;
  }
}

export class DocumentEndpoints {
  static getDocumentsByCategory(param: { category: string }, showSelectExisting: boolean): Endpoint<ZeltDocument[]> {
    const URL = `/apiv2/documents/by-category?${new URLSearchParams({
      category: param.category,
    }).toString()}`;
    return {
      url: showSelectExisting ? URL : undefined,
    };
  }

  static getTypesData(): Endpoint<ZeltDocumentType[]> {
    return {
      url: '/apiv2/documents/types',
    };
  }
}
