import {
  TASKS_COMPANY_OVERVIEW_ROUTE,
  TASKS_COMPANY_ROUTE,
  TASKS_ME_OVERVIEW_ROUTE,
  TASKS_ME_ROUTE,
  TASKS_TEAM_OVERVIEW_ROUTE,
  TASKS_TEAM_ROUTE,
} from '@/lib/routes';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const PEOPLE_ALL_TASKS_ROUTES = [
  TASKS_COMPANY_ROUTE,
  TASKS_TEAM_ROUTE,
  TASKS_ME_ROUTE,
  TASKS_COMPANY_OVERVIEW_ROUTE,
  TASKS_TEAM_OVERVIEW_ROUTE,
  TASKS_ME_OVERVIEW_ROUTE,
];

export const PEOPLE_TASKS_ROUTES: RoleRoutes = {
  company: TASKS_COMPANY_ROUTE,
  team: TASKS_TEAM_ROUTE,
  me: TASKS_ME_ROUTE,
};

export const PEOPLE_TASKS_OVERVIEW_ROUTES: RoleRoutes = {
  company: TASKS_COMPANY_OVERVIEW_ROUTE,
  team: TASKS_TEAM_OVERVIEW_ROUTE,
  me: TASKS_ME_OVERVIEW_ROUTE,
};

export const PEOPLE_TASKS_OVERVIEW_SCOPES: RoleScopes = {
  company: ['task:all'],
  team: ['task:manager'],
  me: ['task'],
};
