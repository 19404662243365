import React from 'react';

import useMessage from '@/hooks/notification.hook';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ReviewSectionBankAPI } from '@/v2/feature/growth/reviews/api-client/review-section-bank.api';
import { SectionModalContent } from '@/v2/feature/growth/reviews/features/components/section-modal-content.component';
import {
  ReviewSectionBank,
  ReviewSectionBankCreate,
  ReviewSectionBankUpdate,
} from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';

export const ReviewTemplateSectionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  section,
  refresh,
  templateId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  section: ReviewSectionBank | null;
  refresh: () => Promise<void>;
  templateId: string;
}) => {
  const [showMessage] = useMessage();

  const onSubmit = async (values: { name: string }) => {
    if (section) {
      const updatedValues = { id: section.id, name: values.name, templateId };
      await ReviewSectionBankAPI.updateReviewSectionInBank(updatedValues as ReviewSectionBankUpdate);
      showMessage('Section updated successfully', 'success');
    } else {
      const updatedValues = { name: values.name, templateId: templateId };
      await ReviewSectionBankAPI.createReviewSectionInBank(updatedValues as ReviewSectionBankCreate);
      showMessage('Section created successfully', 'success');
    }
  };

  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <SectionModalContent
        onClose={onClose}
        section={section as Pick<ReviewSectionBank, 'name'>}
        refresh={refresh}
        onSubmit={onSubmit}
      />
    </DrawerModal>
  );
};
