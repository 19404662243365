import { PermissionGroupOptions } from '@v2/feature/permission-group/permission-group.interface';
import axios from 'axios';

import {
  CreatePermissionGroupDto,
  PermissionDto,
  PermissionGroupDto,
} from '@/v2/feature/permission-group/permission-group.dto';

export class PermissionGroupAPI {
  static async enablePermissionScope(permissionGroupId: number, scope: string): Promise<void> {
    return axios.patch(`/apiv2/permission-group-scopes/${permissionGroupId}/permission/enable`, { scope });
  }

  static async disablePermissionScope(permissionGroupId: number, scope: string): Promise<void> {
    return axios.patch(`/apiv2/permission-group-scopes/${permissionGroupId}/permission/disable`, { scope });
  }

  static async listPermissionGroups(): Promise<PermissionGroupDto[]> {
    return (await axios.get<PermissionGroupDto[]>('/apiv2/permission-groups')).data;
  }

  static async listPermissionGroupsWithMembers(): Promise<PermissionGroupDto[]> {
    return (await axios.get<PermissionGroupDto[]>('/apiv2/permission-groups/members')).data;
  }

  static async getPermissionGroup(permissionGroupId: number): Promise<PermissionGroupDto> {
    return (await axios.get<PermissionGroupDto>(`/apiv2/permission-groups/${permissionGroupId}`)).data;
  }

  static async updatePermissionGroupOptions(permissionGroupId: number, options: PermissionGroupOptions): Promise<void> {
    await axios.patch<PermissionGroupDto>(`/apiv2/permission-groups/${permissionGroupId}/options`, options);
  }

  static async deletePermissionGroup(permissionGroupId: number) {
    return axios.delete(`/apiv2/permission-groups/${permissionGroupId}`);
  }

  static async updatePermissionGroupDetails(
    permissionGroupId: number,
    permissionGroupDetails: Pick<
      PermissionGroupDto,
      'name' | 'description' | 'members' | 'inclusions' | 'targetsExclRule' | 'customExclRule' | 'exclusions'
    >
  ): Promise<void> {
    return axios.patch(`/apiv2/permission-groups/${permissionGroupId}`, permissionGroupDetails);
  }

  static async updatePermissionGroupMembers(permissionGroupId: number, members: readonly number[]): Promise<void> {
    return axios.patch(`/apiv2/permission-groups/${permissionGroupId}/members`, { members });
  }

  static async createPermissionGroup(createPermissionGroup: CreatePermissionGroupDto): Promise<PermissionGroupDto> {
    return (await axios.post<PermissionGroupDto>('/apiv2/permission-groups', createPermissionGroup)).data;
  }

  static async listPermissions(): Promise<PermissionDto[]> {
    return (await axios.get<PermissionDto[]>('/apiv2/permissions')).data;
  }
}
