import axios from 'axios';

import { RequestDto } from '@/v2/feature/app-integration/features/app-request/app-request.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class AppRequestAPI {
  static createAppRequest = async (requestCreation: RequestDto): Promise<void> => {
    await axios.post(`/apiv2/requests/apps`, requestCreation);
  };

  static createPendingAppRequest = async (requestCreation: RequestDto): Promise<void> => {
    await axios.post(`/apiv2/requests/apps/pending`, requestCreation);
  };

  static cancelAppRequest = async (requestId: number): Promise<void> => {
    await axios.post(`/apiv2/requests/apps/${requestId}/cancel`);
  };

  static getAppRequestsForApp = async (appStub: string): Promise<RequestDto[]> => {
    return (await axios.get(`/apiv2/requests/apps/${appStub}`)).data;
  };

  static approveAppRequest = async (requestId: number) => {
    await axios.post(`/apiv2/requests/apps/${requestId}/approve`);
  };

  static completePendingAppRequest = async (requestId: number) => {
    await axios.post(`/apiv2/requests/apps/${requestId}/mark-pending-complete`);
  };

  static retryFailedAppRequest = async (requestId: number) => {
    await axios.post(`/apiv2/requests/apps/${requestId}/retry`);
  };

  static deleteFailedAppRequest = async (requestId: number) => {
    await axios.delete(`/apiv2/requests/apps/${requestId}/failed`);
  };

  static deletePendingAppRequest = async (requestId: number) => {
    await axios.delete(`/apiv2/requests/apps/${requestId}/pending`);
  };

  static deleteRejectedAppRequest = async (requestId: number) => {
    await axios.delete(`/apiv2/requests/apps/${requestId}/rejected`);
  };

  static createPendingRequestTaskReminder = async (requestId: number) => {
    await axios.post(`/apiv2/requests/apps/${requestId}/pending-request-task`);
  };

  static rejectAppRequest = async (requestId: number) => {
    await axios.post(`/apiv2/requests/apps/${requestId}/reject`);
  };
}

export class AppRequestEndpoints {
  static getAppRequestsForSpecificApp(appStub: string): Endpoint<readonly RequestDto[]> {
    return {
      url: `/apiv2/requests/apps/${appStub}`,
    };
  }

  static getAppRequestsForSpecificAppAndSpecificPerson(
    appStub: string,
    requestedFor: number | undefined
  ): Endpoint<readonly RequestDto[]> {
    return {
      url: `/apiv2/requests/apps/${appStub}/${requestedFor}`,
    };
  }

  static getAppRequestsForCompany(statusFilter?: string): Endpoint<readonly RequestDto[]> {
    const requestFilter = statusFilter ? `?status=${statusFilter}` : '';
    return {
      url: `/apiv2/requests/apps/all${requestFilter}`,
    };
  }

  static getAppRequestsForUser(statusFilter?: string): Endpoint<readonly RequestDto[]> {
    const requestFilter = statusFilter ? `?status=${statusFilter}` : '';
    return {
      url: `/apiv2/requests/apps${requestFilter}`,
    };
  }
}
