import { useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ZeltMdmScreenSettingsSecurityPolicyEditor } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-screen-settings-editor.component';
import { themeFonts } from '@v2/styles/fonts.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import {
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurablePoliciesDto,
  ScreenSettingsCombine,
} from '@/v2/feature/device/device.dto';
import { themeColors } from '@/v2/styles/colors.styles';

export const ZeltMdmScreenSettingsSecurityPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected,
}: {
  updatePolicies: (configurablePolicies: ConfigurablePoliciesDto) => Promise<void>;
  configurablePoliciesSelected: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined;
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const getPolicy = (): ScreenSettingsCombine => {
    return {
      allowWallpaperModification:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.allowWallpaperModification !== undefined
          ? configurablePoliciesSelected?.configurableScreenSettingPolicy?.allowWallpaperModification
          : true,
      SHOWFULLNAME:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.SHOWFULLNAME !== undefined
          ? configurablePoliciesSelected?.configurableScreenSettingPolicy?.SHOWFULLNAME
          : false,
      LoginwindowText: configurablePoliciesSelected?.configurableScreenSettingPolicy?.LoginwindowText ?? '',
      ShutDownDisabled:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.ShutDownDisabled !== undefined
          ? configurablePoliciesSelected?.configurableScreenSettingPolicy?.ShutDownDisabled
          : false,
      SleepDisabled:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.SleepDisabled !== undefined
          ? configurablePoliciesSelected?.configurableScreenSettingPolicy?.SleepDisabled
          : false,
      RestartDisabled:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.RestartDisabled !== undefined
          ? configurablePoliciesSelected?.configurableScreenSettingPolicy?.RestartDisabled
          : false,
      DisableConsoleAccess:
        configurablePoliciesSelected?.configurableScreenSettingPolicy?.DisableConsoleAccess !== undefined
          ? configurablePoliciesSelected?.configurableScreenSettingPolicy?.DisableConsoleAccess
          : false,
    };
  };

  const initialValues: ScreenSettingsCombine = getPolicy();

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Typography variant="title3">Screen</Typography>
          <IconButton
            onClick={() => {
              setIsOpenEditor(true);
            }}
            title="Edit"
            sx={tableIconButtonSx}
          >
            <Edit {...iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: spacing.m5,
            display: 'grid',
            gridTemplateColumns: 'calc(25%) calc(75%)',
            color: themeColors.DarkGrey,
          }}
        >
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Login welcome text
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(initialValues.LoginwindowText === ''
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.LoginwindowText !== '' ? initialValues.LoginwindowText : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Login window - shows the name and password
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.SHOWFULLNAME
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.SHOWFULLNAME ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Hide shut down
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.ShutDownDisabled
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.ShutDownDisabled ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Hide sleep
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.SleepDisabled
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.SleepDisabled ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Hide restart
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.RestartDisabled
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.RestartDisabled ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Disable console access
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(!initialValues.DisableConsoleAccess
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {initialValues.DisableConsoleAccess ? 'Enabled' : 'Not set'}
          </Typography>
          <Typography variant="caption" sx={{ color: themeColors.DarkGrey, mt: spacing.m10 }}>
            Disable wallpaper change
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ...(initialValues.allowWallpaperModification
                ? { ...themeFonts.caption, color: themeColors.grey, mt: spacing.m10 }
                : { ...themeFonts.title4, color: themeColors.black, mt: spacing.m10 }),
            }}
          >
            {!initialValues.allowWallpaperModification ? 'Enabled' : 'Not set'}
          </Typography>
        </Box>
      </Box>

      <DrawerModal isOpen={isOpenEditor} setIsOpen={setIsOpenEditor}>
        <ZeltMdmScreenSettingsSecurityPolicyEditor
          configurablePoliciesSelected={configurablePoliciesSelected}
          updatePolicies={updatePolicies}
          isOpen={isOpenEditor}
          setIsOpen={setIsOpenEditor}
        />
      </DrawerModal>
    </>
  );
};
