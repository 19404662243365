import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

import { ContractTemplate } from '../templates.interface';

import { TemplateAPI } from '@/api-client/templates.api';
import useMessage from '@/hooks/notification.hook';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { ClearIconButton } from '@/v2/components/theme-components/clear-icon-button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

interface ModalProps {
  readonly template: ContractTemplate;
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly open: boolean;
  readonly onUpdate?: () => Promise<void>;
  readonly documentTypes: readonly ZeltDocumentType[];
}

export const RenameTemplateModal = ({ setOpen, open, template, onUpdate, documentTypes }: ModalProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [templateName, setTemplateName] = useState<string>(template.name);
  const [showMessage] = useMessage();
  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>(template.type || '');

  const updateTemplate = useCallback(
    async (params: { id: string; templateText: string; name?: string; type: string | null }) => {
      try {
        setIsUpdatingTemplate(true);
        await TemplateAPI.updateTemplate(params);

        setIsUpdatingTemplate(false);
        if (onUpdate) await onUpdate();
        setOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t('RenameTemplateModal.errorMessages.update', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setIsUpdatingTemplate(true);
      }
    },
    [showMessage, setOpen, onUpdate, polyglot]
  );

  return (
    <DrawerModal isOpen={open} setIsOpen={setOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('RenameTemplateModal.rename')}</Typography>

        <StyledTextfield
          label="Name"
          size="small"
          required
          value={templateName}
          onChange={({ target: { value } }) => setTemplateName(value)}
          fullWidth
          variant="standard"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {templateName && templateName?.length > 0 && <ClearIconButton onClick={() => setTemplateName('')} />}
              </InputAdornment>
            ),
          }}
        />

        <SelectComponent
          key="updateTemplateType"
          name="updateTemplateType"
          label={polyglot.t('RenameTemplateModal.updateTemplateType')}
          value={documentType}
          options={documentTypes.map((d) => ({ label: d.label, value: d.value }))}
          onChange={(e) => setDocumentType(e.target.value)}
          required={true}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
            name={polyglot.t('General.save')}
            loading={isUpdatingTemplate}
            disabled={!templateName}
            onClick={() =>
              updateTemplate({
                id: template.id,
                templateText: template.templateText,
                name: templateName,
                type: documentType || null,
              })
            }
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
