import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Insurance } from '@/images/insurance/Insurance.svg';
import { ReactComponent as Planet } from '@/images/insurance/Planet.svg';
import { ReactComponent as WalletBig } from '@/images/pension/WalletBig.svg';
import { SETTINGS_BENEFITS_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  IconContentActionCardProps,
  IntroCardGroup,
} from '@/v2/components/theme-components/icon-content-action-card.component';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const emptyStates: IconContentActionCardProps[] = [
  {
    title: 'Integrated with payroll',
    content:
      'Report user-benefits to HMRC when you run payroll on Zelt. Add and change benefit contributions, add dependents and offboard leavers seamlessly. ',
    iconMedia: <WalletBig />,
  },
  {
    title: 'Connect existing insurance',
    content:
      'If you already have a plan - migrate it over to Zelt and let your team see all user-benefits they have in one place. ',
    iconMedia: <Insurance />,
  },
  {
    title: 'Local and international',
    content: 'Powered by Hooray Insurance, get access to the best rates from insurers even for your global team. ',
    iconMedia: <Planet />,
  },
];
export const InsuranceEmptyState = () => {
  const routerHistory = useHistory();

  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: spacing.g60,
      }}
    >
      <Box sx={{ maxWidth: '600px' }}>
        <Typography sx={{ ...themeFonts.title2 }}>Health insurance</Typography>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.m10 }}>
          Offer your employees a well-rounded medical benefit. Fully integrated in Payroll. See and manage employee
          membership in Zelt. Report the benefits to HMRC via P11D or set up Payrolled benefits
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: spacing.g10,
            width: '70%',
            marginTop: spacing.m30,
          }}
        >
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="primary"
            onClick={() => routerHistory.push(SETTINGS_BENEFITS_ROUTE)}
          >
            Set up insurance
          </ButtonComponent>
        </Box>
      </Box>

      <Box>{<IntroCardGroup cards={emptyStates} />}</Box>
    </Box>
  );
};
