import axios from 'axios';

import { EntityImportWizardSource } from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { QueueStatus, UserImportViaLinkedinJob } from '@/v2/feature/monitoring/monitoring.interface';
import {
  UserImportDto,
  UserImportResult2Dto,
  UserImportResultDto,
} from '@/v2/feature/user/features/user-import/user-import.dto';

export class UserImportAPI {
  static async bulkCreate(users: readonly Partial<UserImportDto>[]): Promise<UserImportResultDto> {
    return (await axios.post('/apiv2/users/import/bulk', users)).data;
  }

  // TODO: should delete this endpoints FE & BE if not planning to use it anymore
  static async importUsersCsvText(csvText: string): Promise<UserImportResultDto> {
    return (await axios.post('/apiv2/users/import/csv/text', { csvText })).data;
  }

  // used for azure import
  static async importViaCsv(users: readonly UserImportDto[]): Promise<UserImportResultDto> {
    return (await axios.post('/apiv2/users/import/csv-v2', users)).data;
  }

  // used for queue old and azure import
  static async importViaCsvV3(file: ArrayBuffer, source: EntityImportWizardSource): Promise<boolean> {
    return (await axios.post(`/apiv2/users/import/csv-v3/${source}`, file)).data;
  }

  // used for csv import
  static async importViaCsvV4(importData: unknown, source: EntityImportWizardSource): Promise<UserImportResult2Dto> {
    return (await axios.post(`/apiv2/users/import/csv-v4/${source}`, importData)).data;
  }

  // superadmin endpoints
  static async importViaLinkedIn(linkedInUrl: string, emailDomain: string): Promise<string> {
    return (await axios.get(`/apiv2/users/import/linkedin/${encodeURIComponent(linkedInUrl)}/${emailDomain}`)).data;
  }

  static async getUserImportLinkedInQueues(): Promise<QueueStatus<UserImportViaLinkedinJob>> {
    return (await axios.get(`/apiv2/users/import/queues/import-queue`)).data;
  }
}
