import { useContext, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AttachmentInDrawer } from '@v2/feature/absence/sections/absence-drawer/attachment-in-drawer.component';
import { ExpenseModal } from '@v2/feature/payments/pages/components/expense-modal.component';
import { Expense } from '@v2/feature/payments/payments.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';

interface PaymentExpensePreviewProps {
  readonly expense: Expense;
  readonly titleSx?: SxProps<Theme>;
}

export const PaymentExpensePreview = ({ expense, titleSx }: PaymentExpensePreviewProps) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const currentUserIsAdmin = hasScopes(['invoices:all'], getScopesContext(user));
  const [openExpenseModal, setOpenExpenseModal] = useState<boolean>(false);

  return (
    <Box>
      <Typography variant="title4" sx={titleSx}>
        Expense
      </Typography>
      <AttachmentInDrawer
        entityWithAttachment={expense}
        fileLabel={polyglot.t('NewExpensePage.receipt')}
        customHandlePreview={() => setOpenExpenseModal(true)}
      />

      <ExpenseModal
        isOpen={openExpenseModal}
        setIsOpen={setOpenExpenseModal}
        selectedExpense={expense}
        onClose={() => setOpenExpenseModal(false)}
        onActionPerformed={async () => {}}
        currentUserIsAdmin={currentUserIsAdmin}
      />
    </Box>
  );
};
