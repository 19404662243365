import { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { GlobalContext } from '@/GlobalState';
import { performLogout } from '@/v2/feature/auth/auth.util';

export const AuthLogoutPage = () => {
  const routerHistory = useHistory();
  const swrConfig = useSWRConfig();
  const [, dispatch] = useContext(GlobalContext);

  useEffect(() => {
    performLogout(routerHistory, dispatch, swrConfig);
  }, [dispatch, routerHistory, swrConfig]);

  return <></>;
};
