import { UserAPI } from '@/v2/feature/user/user.api';
import { useEffect, useState } from 'react';

// how long we can use a cached src before refreshing it
const MAX_CACHE_AGE_SECONDS = 60;

type SetAvatarSrcFn = (src: string | null) => void;

// the list of userIds and setData functions
const requests = new Map<
  number, // userId
  {
    cached?: {
      src: string | null;
      lastUpdate: number;
    };
    fns: Set<SetAvatarSrcFn>;
  }
>();

// the timer used to trigger the performAvatarRequest() function
let performAvatarRequestTrigger: NodeJS.Timeout | null = null;

// the list of userIds we are currently fetching sources for
const requestingUserIds = new Set<number>();

async function performAvatarRequest() {
  try {
    // get the list of userIds we want avatars for
    const userIds = [...requests.keys()]
      // exclude any that we are already currently fetching
      .filter((uid) => !requestingUserIds.has(uid))
      // exclude any that have no hooks connected
      .filter((uid) => requests.get(uid)?.fns.size);

    if (userIds.length === 0) {
      return; // nothihg to do
    }

    userIds.forEach((uid) => requestingUserIds.add(uid));

    // call the API to retrieve the src URLs for the avatars
    const sources = await UserAPI.getUserAvatars(userIds);

    sources.forEach(({ userId, src }) => {
      // get the list of setData state callbacks for this userId
      const data = requests.get(userId);
      if (!data) return;
      data.cached = {
        src,
        lastUpdate: Date.now(),
      };
      // set the src for all of the hooks waiting for it
      data?.fns.forEach((setData) => setData(src));
      // remove the set from the list of requests
      requestingUserIds.delete(userId);
    });
  } catch {
    // if the api call fails, just clear the inflight-list
    requestingUserIds.clear();
  }
}

function addAvatarSrcRequest(userId: number, setAvatarSrc: SetAvatarSrcFn) {
  let data = requests.get(userId);
  if (data?.cached) {
    const ageMs = Date.now() - data.cached.lastUpdate;
    if (ageMs < MAX_CACHE_AGE_SECONDS * 1e3) {
      // ignore the request since we have a cached version
      return;
    }
  }
  if (!data) {
    data = {
      cached: undefined,
      fns: new Set<SetAvatarSrcFn>(),
    };
    requests.set(userId, data);
  }
  data.fns.add(setAvatarSrc);

  // if this is the first entry, start the timer to trigger the request
  if (performAvatarRequestTrigger === null) {
    performAvatarRequestTrigger = setTimeout(() => {
      performAvatarRequestTrigger = null;
      performAvatarRequest();
    });
  }
}

function removeAvatarSrcRequest(userId: number, setAvatarSrc: SetAvatarSrcFn) {
  const data = requests.get(userId);
  data?.fns.delete(setAvatarSrc);
}

export function useUserAvatarSrc(userId: number): { data?: string | null } {
  const [data, setData] = useState<string | null | undefined>(
    // by default, use the cached src value
    requests.get(userId)?.cached?.src
  );

  // we frequently need to request avatars for large groups of users (especially in tables showing
  // lists of users) so we gather all the requests in one event loop and then trigger a single
  // API call to return the sources for all the users.

  useEffect(() => {
    const u = userId;
    const fn = setData;
    addAvatarSrcRequest(u, fn);
    return () => removeAvatarSrcRequest(u, fn);
  }, [userId, setData]);

  return { data };
}
