import { Typography } from '@v2/components/typography/typography.component';
import { Link } from 'react-router-dom';

import { LOGIN_ROUTE } from '@/lib/routes';
import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { themeFonts } from '@/v2/styles/fonts.styles';

export const AuthSignupThanks = () => (
  <AuthLayout title="Thank you for signing up with Zelt!">
    <Typography variant="caption" sx={{ mr: '4px' }}>
      Please check your inbox for your activation email
    </Typography>
    <Link style={{ ...themeFonts.caption, whiteSpace: 'nowrap' }} to={LOGIN_ROUTE}>
      or Sign In
    </Link>
  </AuthLayout>
);
