import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { EditableTitle } from '@v2/components/forms/editable-title.component';
import { FilterTypesProps } from '@v2/components/table/category-filters.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { ResultTableOldReports } from '@v2/feature/reports/components/result-table.component';
import {
  ErrorComponent,
  LoadingTableComponent,
} from '@v2/feature/reports/features/create-report/sections/util-sections.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import {
  CreateReport,
  FormDataInterface,
  ReportCustomFields,
  ReportEntity,
} from '@v2/feature/reports/reports.interface';
import {
  getAllColumnsOptionsPeopleCurrentReports,
  getOnlyAllowedColumnsStringsPeopleCurrentReports,
  getPeopleReportFilters,
} from '@v2/feature/reports/util/people-report.util';
import { exportCurrentReportCSV } from '@v2/feature/reports/util/report.util';
import { UserAPI } from '@v2/feature/user/user.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_OVERVIEW } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';

export const PeopleCurrentCreateReportResults = ({
  formData,
  setFormData,
  customFields,
  additionalColumns,
}: {
  readonly formData: FormDataInterface;
  readonly setFormData: React.Dispatch<React.SetStateAction<FormDataInterface>>;
  readonly customFields: ReportCustomFields;
  readonly additionalColumns: {
    label: string;
    value: string;
    entity: ReportEntity;
  }[];
}) => {
  const { polyglot } = usePolyglot();

  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false,
  });

  const { hasScopes } = useScopes();
  const [loading, setLoading] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(true);
  const [reportName, setReportName] = useState<string>(polyglot.t('PeopleCurrentCreateReportResults.new'));
  const [filterTypes, setFilterTypes] = useState<FilterTypesProps>({});
  const [filterString, setFilterString] = useState<string>('');
  const [filteredData, setFilteredData] = useState<Record<string, string | number>[] | undefined>(undefined);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState<Record<string, string | number>[] | undefined>(
    undefined
  );

  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    setSelectedColumnsStrings(
      getOnlyAllowedColumnsStringsPeopleCurrentReports(
        formData.entities,
        customFields,
        hasScopes,
        additionalColumns,
        polyglot
      )
    );
    setAllDataLoaded(true);
  }, [formData.entities, customFields, hasScopes, additionalColumns, polyglot]);

  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [reportError, setReportError] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);

  const selectableColumns = useMemo(() => {
    return getAllColumnsOptionsPeopleCurrentReports(
      formData.entities,
      customFields,
      hasScopes,
      additionalColumns ?? [],
      polyglot
    );
  }, [polyglot, formData.entities, customFields, hasScopes, additionalColumns]);

  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);

  const getFilteredData = useCallback(
    async (selectedColumns: string[], filterString: string) => {
      setReportLoading(true);
      try {
        const paramObj = {
          domain: formData.domain,
          type: formData.type,
          entities: formData.entities,
          start: formData.start,
          end: formData.end,
          dateType: formData.dateType,
          filters: filterString,
          columns: { [ReportEntity.User]: selectedColumns },
        };

        let filteredUsers = await ReportsAPI.generatePeopleCreatedCurrentReport(paramObj);
        setReportError(false);
        setFilteredData(filteredUsers);
      } catch (error) {
        setReportError(true);
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setReportLoading(false);
      }
    },
    [formData, showMessage]
  );

  useEffect(() => {
    if (allDataLoaded) getFilteredData(selectedColumnsStrings, filterString);
  }, [allDataLoaded, getFilteredData, selectedColumnsStrings, filterString]);

  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData?.filter(
        (data) =>
          !searchInput ||
          (data?.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  }, [searchInput, filteredData]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const createReport: CreateReport = {
        domain: formData.domain,
        type: formData.type,
        entities: formData.entities,
        start: formData.start,
        end: formData.end,
        dateType: formData.dateType,
        filters: filterString,
        columns: { [ReportEntity.User]: selectedColumnsStrings },
        fileName: reportName,
      };
      await ReportsAPI.saveReport(createReport);
      setFormData({ ...formData, filters: filterString });
      showMessage(polyglot.t('PeopleCurrentCreateReportResults.successMessages.save'), 'success');
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [polyglot, formData, filterString, selectedColumnsStrings, reportName, setFormData, showMessage, routerHistory]);

  const exportCsv = useCallback(() => {
    try {
      exportCurrentReportCSV(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t('PeopleCurrentCreateReportResults.errorMessages.download'), 'error');
    }
  }, [polyglot, reportName, filteredData, showMessage]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      {reportLoading && <LoadingTableComponent />}
      {!reportLoading && reportError && <ErrorComponent />}
      {!reportLoading && filteredData && (
        <Box sx={{ width: '80%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10, minHeight: '60px' }}>
            <EditableTitle
              variant="title2"
              value={reportName}
              onChange={(value) => {
                setReportName(value);
              }}
              maxLength={50}
              editButtonTooltip={
                state.user.features?.report?.tooltip?.saveReportName
                  ? undefined
                  : {
                      open: true,
                      title: (
                        <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                            {polyglot.t('PeopleCurrentCreateReportResults.type')}
                          </Typography>
                          <ButtonComponent
                            onClick={async () => {
                              const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                                'report',
                                'tooltip',
                                'saveReportName',
                                true
                              );
                              dispatch({
                                type: GlobalStateActions.UPDATE_USER,
                                payload: updatedGlobalUser,
                              });
                            }}
                            sizeVariant="medium"
                            colorVariant="secondary"
                          >
                            {polyglot.t('PeopleCurrentCreateReportResults.okay')}
                          </ButtonComponent>
                        </Stack>
                      ),
                    }
              }
            />
          </Box>
          <StyledTooltip
            open={isTooltipOpen}
            title={
              <Stack sx={{ alignItems: 'flex-start', maxWidth: '200px' }}>
                <Typography sx={{ ...themeFonts.title4, color: themeColors.white, m: spacing.m10 }}>
                  {polyglot.t('PeopleCurrentCreateReportResults.filter')}
                </Typography>
                <ButtonComponent
                  sizeVariant="small"
                  colorVariant="tooltip"
                  onClick={() => {
                    setIsTooltipOpen(false);
                  }}
                >
                  {polyglot.t('PeopleCurrentCreateReportResults.okay')}
                </ButtonComponent>
              </Stack>
            }
          >
            <Box
              sx={{
                ...spacing.mt20,
                display: 'flex',
                alignItems: 'center',
                gap: spacing.g10,
                width: 'fit-content',
              }}
            >
              <ColumnsDrawer
                columnsOptions={selectableColumns}
                selectedColumns={selectedColumnsStrings}
                setSelectedColumns={setSelectedColumnsStrings}
              />

              <FiltersDrawer
                filtersOptions={[{ filters: filterTypes }]}
                selectedFilters={filterString}
                setSelectedFilters={setFilterString}
                encodedFilterNames
              />
              <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
            </Box>
          </StyledTooltip>

          <Box sx={{ ...spacing.mt40 }}>
            {filteredAndSearchedData && (
              <ResultTableOldReports
                filteredData={filteredAndSearchedData}
                // resultColumnNameMapping={PeopleReportsFieldHeaderMapping}
                loading={false}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: spacing.g10,
              ...spacing.mt40,
              width: '50%',
            }}
          >
            <LoaderButton
              sizeVariant="medium"
              colorVariant="primary"
              name="Save"
              loading={loading}
              fullWidth
              onClick={handleSave}
            />
            <ButtonComponent
              fullWidth
              sizeVariant="medium"
              colorVariant="secondary"
              onClick={exportCsv}
              startIcon={<Export {...iconSize} />}
            >
              {polyglot.t('PeopleCurrentCreateReportResults.downloadCsv')}
            </ButtonComponent>
          </Box>
        </Box>
      )}

      {/*{!reportLoading && filteredData && Object.keys(filteredData)?.length < 1 && !reportError && !filterString && (*/}
      {/*  <NoDataComponent />*/}
      {/*)}*/}
    </Box>
  );
};
