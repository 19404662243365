import React from 'react';

import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as ContractBig } from '@/images/documents/ContractBig.svg';
import { ReactComponent as BenefitsBig } from '@/images/documents/DocumentBig.svg';
import { ReactComponent as NotiBig } from '@/images/documents/NotiBig.svg';
import {
  IconContentActionCardProps,
  IntroCardGroup,
} from '@/v2/components/theme-components/icon-content-action-card.component';
import { NewDocumentButton } from '@/v2/feature/documents/components/new-document-action-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { parentCardSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DocumentEmptyOverview = ({
  onUploadClick,
  onRequestClick,
  isAdmin,
}: {
  onUploadClick: () => void;
  onRequestClick: () => void;
  isAdmin: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const DocumentCards: IconContentActionCardProps[] = [
    {
      title: polyglot.t('DocumentEmptyOverview.manage'),
      content: polyglot.t('DocumentEmptyOverview.manageDescription'),
      iconMedia: <BenefitsBig />,
    },
    {
      title: polyglot.t('DocumentEmptyOverview.requestUploads'),
      content: polyglot.t('DocumentEmptyOverview.requestUploadsDescription'),
      iconMedia: <NotiBig />,
    },
    {
      title: polyglot.t('DocumentEmptyOverview.signContracts'),
      content: polyglot.t('DocumentEmptyOverview.signContractsDescription'),
      iconMedia: <ContractBig />,
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ ...parentCardSx }}>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
          {polyglot.t('DocumentEmptyOverview.documentsDescription')}
        </Typography>

        <Box sx={{ mt: spacing.m30 }}>
          <NewDocumentButton
            onUploadClick={onUploadClick}
            onRequestClick={onRequestClick}
            size="large"
            isAdmin={isAdmin}
          />
        </Box>
      </Box>

      <IntroCardGroup cards={DocumentCards} sx={{ mt: 'auto' }} />
    </Box>
  );
};
