import React, { useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { GrowthScaleFormModal } from '@/v2/feature/growth/growth-scale/components/growth-scale-form-modal.component';
import { GrowthScaleViewModal } from '@/v2/feature/growth/growth-scale/components/growth-scale-view-modal.component';
import { GrowthScaleEndpoints } from '@/v2/feature/growth/growth-scale/growth-scale.api';
import { GrowthScale } from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const GrowthScalePage = () => {
  const { data: allScales, isLoading, mutate: refreshScales } = useApiClient(
    GrowthScaleEndpoints.getGrowthScalesByCompanyId(),
    {
      suspense: false,
    }
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectedScale, setSelectedScale] = useState<GrowthScale | undefined>(undefined);

  const tableColumns = useMemo<ColumnDef<GrowthScale, GrowthScale>[]>(
    () => [
      {
        header: () => 'Type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,

        cell: ({
          row: {
            original: { type },
          },
        }) => (type ? <div>{type} </div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Created by',
        accessorFn: (row) => row,
        id: 'createdBy',
        enableSorting: false,

        cell: ({
          row: {
            original: { companyId, createdBy },
          },
        }) => (
          <div>
            {companyId === null ? (
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" />
                Zelt
              </Stack>
            ) : createdBy ? (
              <UserCell userId={createdBy} />
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => 'Created on',
        accessorFn: (row) => row,
        id: 'createdAt',
        enableSorting: false,
        minSize: 50,
        maxSize: 120,

        cell: ({
          row: {
            original: { createdAt },
          },
        }) =>
          createdAt ? (
            <div>
              {new LocalDate(createdAt)
                .getDate()
                .toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })}
            </div>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        maxSize: 10,
        minSize: 10,
        cell: (info: CellContext<GrowthScale, GrowthScale>) => {
          return (
            info.getValue().companyId !== null && (
              <IconButton
                sizeVariant="small"
                colorVariant="secondary"
                onClick={(e) => {
                  setSelectedScale(info.getValue());
                  setIsOpen(true);
                  e.stopPropagation();
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            )
          );
        },
      },
    ],
    []
  );

  const handleRowClick = useCallback((row: Row<GrowthScale>) => {
    setSelectedScale(row.original);
    setIsViewOpen(true);
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Scales</Typography>}
        actions={
          <Box>
            <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => setIsOpen(true)}>
              New scale
            </ButtonComponent>
          </Box>
        }
        showAction={true}
      />
      <ContentWrapper loading={false}>
        <BasicTable rowData={allScales ?? []} columnData={tableColumns} loading={isLoading} rowClick={handleRowClick} />
        <GrowthScaleFormModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setIsOpen(false);
            setTimeout(() => setSelectedScale(undefined), 300);
          }}
          afterClose={() => setSelectedScale(undefined)}
          growthScale={selectedScale}
          refresh={async () => {
            await refreshScales?.();
          }}
        />

        <GrowthScaleViewModal
          isOpen={isViewOpen}
          setIsOpen={setIsViewOpen}
          onClose={() => {
            setIsViewOpen(false);
            setTimeout(() => setSelectedScale(undefined), 300);
          }}
          afterClose={() => setSelectedScale(undefined)}
          growthScale={selectedScale}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
