import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import { CompanyPayroll, TaxYear } from '@shared/modules/payroll/payroll.types';
import { useHistory, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SideMenuHeader } from '@/v2/feature/payroll/components/side-menu-header.component';
import { formatPayrunPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { GlobalPayrunPage } from '@/v2/feature/payroll/features/payroll-global/global-payrun-flow/global-payrun.page';
import { PayrunFlowPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-flow.page';
import { PayrunOverviewPage } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-overview.page';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { ExternalPayRunDto } from '@/v2/feature/payroll/payroll-external.dto';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { PayrollLocalApi } from '@/v2/feature/payroll/payroll-local.api';
import { navigateToPayrunsView } from '@/v2/feature/payroll/payroll-router.util';
import { PayrollAPI, PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { PayRunDto, PayRunEntryDto } from '@/v2/feature/payroll/payroll.dto';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

type UKPayrunPageProps = {
  payroll: CompanyPayroll;
  payrollCount: number;
  taxYear: string;
  payPeriod: PayPeriod;
  period: number;
};

const UKPayrunPage = ({ payroll, payrollCount, taxYear, payPeriod, period: periodNumber }: UKPayrunPageProps) => {
  const isViewingClosedPayrun = useRef<boolean>();
  const routerHistory = useHistory();

  const [payrunState, setPayrunState] = useState<{
    externalPayRun: ExternalPayRunDto;
    entries: PayRunEntryDto[];
    previousEntries: PayRunEntryDto[];
    localPayRun: PayRunDto;
    payrunEmpAllowance: number;
  }>();
  const [showMessage] = useMessage();

  const fetchPayrunData = useCallback(async () => {
    try {
      const payrollId = payroll.id;
      const payrunsStats = await PayrollExternalApi.getPayrunsStatsForTaxYear(payrollId, taxYear);

      const [
        localPayRun,
        externalPayRun,
        entries,
        previousEntries,
        employmentAllowance,
        hmrcLiability,
      ] = await Promise.all([
        PayrollLocalApi.getLocalPayRun(payrollId, taxYear, payPeriod, periodNumber),
        PayrollExternalApi.getExternalPayRun(payrollId, taxYear, payPeriod, periodNumber),
        PayrollLocalApi.findLocalPayRunEntries(payrollId, taxYear, payPeriod, periodNumber),
        periodNumber > payrunsStats.oldestPayrunPeriodNumber
          ? PayrollLocalApi.findLocalPayRunEntries(payrollId, taxYear, payPeriod, periodNumber - 1)
          : [],
        PayrollAPI.getEmploymentAllowance(payrollId),
        PayrollExternalApi.getHmrcLiabilityByPeriod(payrollId, taxYear, periodNumber),
      ]);
      // default-sort the payrun entries by employee name
      entries.sort((a, b) => a.employee.name.localeCompare(b.employee.name, undefined, { sensitivity: 'base' }));

      if (isViewingClosedPayrun.current === undefined || !externalPayRun.isClosed) {
        isViewingClosedPayrun.current = externalPayRun.isClosed;
      }

      let payrunEmpAllowance = 0;
      if (externalPayRun.isClosed) {
        // if the payrun has been closed, we can get the exact EA value from HMRCLiabilites
        payrunEmpAllowance = hmrcLiability.employmentAllowanceClaim ?? 0;
      } else if (employmentAllowance.current && externalPayRun.totals.employerNi > 0) {
        // if the payrun is still open, we must calculate the expected allowance
        const { allowance, claimed } = employmentAllowance.current;
        payrunEmpAllowance = Math.min(externalPayRun.totals.employerNi, allowance - claimed);
      }

      setPayrunState({
        externalPayRun,
        entries,
        previousEntries,
        localPayRun,
        payrunEmpAllowance,
      });
    } catch (error) {
      showMessage(`Failed to fetch payrun information. ${nestErrorMessage(error)}`, 'error');
    }
  }, [payroll.id, payPeriod, periodNumber, showMessage, taxYear]);

  useEffect(() => {
    fetchPayrunData();
  }, [fetchPayrunData]);

  const refreshPayrun = useCallback(() => fetchPayrunData(), [fetchPayrunData]);

  const startPayrun = useCallback(
    async (payrollId: number, payrun: ExternalPayRunDto, paymentDate: string) => {
      let result = false;
      try {
        result =
          (await PayrollExternalApi.finalisePayRun(
            payrollId,
            payrun.taxYear,
            payrun.payPeriod,
            payrun.period,
            paymentDate
          ),
          true);
      } catch (error) {
        showMessage(`Failed to start payrun. ${nestErrorMessage(error)}`, 'error');
      }
      if (result) {
        await refreshPayrun();
      }
      return result;
    },
    [refreshPayrun, showMessage]
  );

  const completePayrun = useCallback(
    async (localPayrun: PayRunDto) => {
      let result = false;
      try {
        result = (await PayrollLocalApi.completePayrun(localPayrun.id), true);
      } catch (error) {
        showMessage(`Could not mark payrun as complete. ${nestErrorMessage(error)}`, 'error');
      }
      return result;
    },
    [showMessage]
  );

  if (!payrunState) {
    return <></>;
  }

  const { externalPayRun, entries, previousEntries, localPayRun, payrunEmpAllowance } = payrunState;

  const entityName = payroll.entity?.legalName ?? '';

  return (
    <Stack sx={{ flex: 1, flexFlow: 'row', alignItems: 'top', gap: spacing.g60 }}>
      <SideMenuHeader
        heading={formatPayrunPeriod(localPayRun)}
        caption={entityName}
        onBackClick={() => {
          navigateToPayrunsView(routerHistory, 'replace', localPayRun.payrollId, localPayRun.id);
        }}
        sx={{ mt: '20px', ml: '20px' }}
      />
      <Stack sx={{ flex: 1 }}>
        {isViewingClosedPayrun.current ? (
          <PayrunOverviewPage
            entityName={entityName}
            payrollCount={payrollCount}
            externalPayRun={externalPayRun}
            entries={entries}
            previousEntries={previousEntries}
            payrunEmpAllowance={payrunEmpAllowance}
            localPayRun={localPayRun}
            refreshPayrun={refreshPayrun}
            completePayrun={completePayrun}
          />
        ) : (
          <PayrunFlowPage
            entityName={entityName}
            payrollCount={payrollCount}
            externalPayRun={externalPayRun}
            entries={entries}
            previousEntries={previousEntries}
            payrunEmpAllowance={payrunEmpAllowance}
            localPayRun={localPayRun}
            refreshPayrun={refreshPayrun}
            startPayrun={startPayrun}
            completePayrun={completePayrun}
          />
        )}
      </Stack>
    </Stack>
  );
};

export const PayrunPage = () => {
  const params = useParams<{
    readonly payrollId: string;
    readonly taxYear: string;
    readonly payPeriod: string;
    readonly periodNumber: string;
  }>();

  const [payrollId, taxYear, payPeriod, period] = useMemo(
    () => [
      Number(params.payrollId),
      params.taxYear as TaxYear, // "Year2020"
      params.payPeriod as PayPeriod,
      Number(params.periodNumber),
    ],
    [params]
  );

  const { data: companyPayrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });

  const payroll = useMemo(() => companyPayrolls?.items.find((p) => p.id === payrollId), [
    companyPayrolls?.items,
    payrollId,
  ]);

  return (
    <>
      {companyPayrolls && payroll && payroll.employer && (
        <UKPayrunPage
          payroll={payroll}
          payrollCount={companyPayrolls.items.length}
          taxYear={taxYear}
          payPeriod={payPeriod}
          period={period}
        />
      )}
      {companyPayrolls && payroll && !payroll.employer && (
        <GlobalPayrunPage
          payroll={payroll}
          payrollCount={companyPayrolls.items.length}
          taxYear={taxYear}
          payPeriod={payPeriod}
          period={period}
        />
      )}
    </>
  );
};
