import React, { Fragment, useContext } from 'react';

import { UserShiftHandler } from '@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component';
import { LocalDate } from '@v2/util/local-date';

import { GlobalContext } from '@/GlobalState';
import { AttendanceScheduleDto } from '@/v2/feature/attendance/attendance.dto';
import { ScheduleTrackingType } from '@/v2/feature/attendance/attendance.interface';
import { RegularAttendanceContent } from '@/v2/feature/dashboard/features/sections/user-attendance/components/regular-attendance-content.component';
import { ShiftWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';

export const UserAttendanceViewDrawer = ({
  selectedDate,
  attendanceSchedule,
  refreshWidgetData,
  setIsViewOpen,
  shiftWidgetData,
}: {
  selectedDate: string | null;
  attendanceSchedule: AttendanceScheduleDto;
  refreshWidgetData: () => Promise<void>;
  setIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shiftWidgetData: ShiftWidget | undefined;
}) => {
  const [globalState] = useContext(GlobalContext);
  const user = globalState.user;

  return (
    <Fragment>
      {attendanceSchedule?.trackingType !== ScheduleTrackingType.ClockInClockOut ? (
        <RegularAttendanceContent
          userId={user.userId}
          selectedDate={selectedDate}
          refreshWidgetData={refreshWidgetData}
          setIsViewOpen={setIsViewOpen}
        />
      ) : (
        <UserShiftHandler
          selectedDate={selectedDate ?? new LocalDate().toDateString()}
          refresh={refreshWidgetData}
          shift={shiftWidgetData?.shift?.todayShift}
          userSite={shiftWidgetData?.shift?.userSite}
          currentWeekAttendance={shiftWidgetData?.shift.currentWeekAttendance ?? []}
          attendanceSchedule={attendanceSchedule}
          mode="drawer"
        />
      )}
    </Fragment>
  );
};
