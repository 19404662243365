import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { AbsenceImportDto } from '@/v2/feature/absence/subfeatures/absence-import/absence-import.dto';
import { DeviceImportDto } from '@/v2/feature/device/features/devices-import/devices-import.dto';
import { EntityImportResultDto } from '@/v2/feature/entity-import/entity-import.dto';
import {
  EntityImportWizardData,
  EntityImportWizardSource,
} from '@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface';
import { ImportState } from '@/v2/feature/entity-import/wizard/import-wizard-flow.page';
import { UserImportDto } from '@/v2/feature/user/features/user-import/user-import.dto';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { pluralText } from '@/v2/util/string.util';

type ImportCompleteSectionProps = {
  importState?: ImportState;
  onNext: () => void;
  sx?: SxProps<Theme>;
};

export const ImportCompleteSection = ({ importState, onNext, sx }: ImportCompleteSectionProps) => {
  return (
    <Stack sx={{ gap: spacing.g30, ...sx }}>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Import completed</Typography>
      {importState &&
        importState.source &&
        [EntityImportWizardSource.CSV, EntityImportWizardSource.AzureAD].includes(importState.source) &&
        importState.domain === EntityImportWizardData.Users &&
        importState.result &&
        (importState?.result as EntityImportResultDto<UserImportDto>).importedCount > 0 && (
          <Stack sx={{ gap: spacing.g5, minWidth: '300px' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Successfully imported</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {(importState?.result as EntityImportResultDto<UserImportDto>).importedCount} employee
              {pluralText((importState?.result as EntityImportResultDto<UserImportDto>).importedCount)}
            </Typography>
          </Stack>
        )}
      {importState &&
        importState.source === EntityImportWizardSource.CSV &&
        importState.domain === EntityImportWizardData.Users &&
        importState.result &&
        (importState?.result as EntityImportResultDto<UserImportDto>).updatedCount > 0 && (
          <Stack sx={{ gap: spacing.g5, minWidth: '300px' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Successfully updated</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {(importState?.result as EntityImportResultDto<UserImportDto>).updatedCount} employee
              {pluralText((importState?.result as EntityImportResultDto<UserImportDto>).updatedCount)}
            </Typography>
          </Stack>
        )}
      {importState &&
        importState.source === EntityImportWizardSource.CSV &&
        importState.domain === EntityImportWizardData.Absences &&
        importState.result &&
        (importState?.result as EntityImportResultDto<AbsenceImportDto>).importedCount > 0 && (
          <Stack sx={{ gap: spacing.g5, minWidth: '300px' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Successfully imported</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {(importState?.result as EntityImportResultDto<AbsenceImportDto>).importedCount} absence
              {pluralText((importState?.result as EntityImportResultDto<AbsenceImportDto>).importedCount)}
            </Typography>
          </Stack>
        )}
      {importState &&
        importState.source === EntityImportWizardSource.CSV &&
        importState.domain === EntityImportWizardData.Devices &&
        importState.result &&
        (importState?.result as EntityImportResultDto<DeviceImportDto>).importedCount > 0 && (
          <Stack sx={{ gap: spacing.g5, minWidth: '300px' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Successfully imported</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {(importState?.result as EntityImportResultDto<DeviceImportDto>).importedCount} device
              {pluralText((importState?.result as EntityImportResultDto<DeviceImportDto>).importedCount)}
            </Typography>
          </Stack>
        )}
      {importState &&
        importState.source === EntityImportWizardSource.CSV &&
        importState.domain === EntityImportWizardData.Devices &&
        importState.result &&
        (importState?.result as EntityImportResultDto<DeviceImportDto>).updatedCount > 0 && (
          <Stack sx={{ gap: spacing.g5, minWidth: '300px' }}>
            <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Successfully updated</Typography>
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              {(importState?.result as EntityImportResultDto<DeviceImportDto>).updatedCount} device
              {pluralText((importState?.result as EntityImportResultDto<DeviceImportDto>).updatedCount)}
            </Typography>
          </Stack>
        )}
      <ButtonComponent colorVariant="primary" sizeVariant="large" fullWidth onClick={onNext}>
        Done{' '}
      </ButtonComponent>
    </Stack>
  );
};
