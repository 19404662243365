export interface AuthorizationAttemptResponse {
  status: number;
  data: {
    url?: string;
  };
}

export const APIDescriptions = {
  read: {
    listUsers: 'List all users for company',
    listSites: 'List all sites for company',
    listJobPositions: 'List all job positions',
    listEntities: 'List all entities for company',
    listPermissionGroupWithMembers: 'List all permission groups with members for the company',
    userBasic: 'Get user basic details',
    userPersonal: 'Get user personal info',
    userEventsByUserId: 'Get user events by user id',
    userEventCurrent: 'Get currently effective user event',
    userContractByUserId: 'Get user contracts by user id',
    userContractCurrentByUserId: 'Get current user contract by user id',
    userCompensationByUserId: 'Get user compensation details',
    userCompensationCurrentByUserId: 'Get currently effective compensation details',
    userRole: 'Get user role details',
    userRoleCurrent: 'Get currently effective user role',
  },
  write: {
    updateBasic: 'Update user basic details',
    updatePersonal: 'Update user personal info',
    updateUserEvent: 'Update a user event by id',
    updateUserContract: 'Update a user contract record',
    deleteUserContract: 'Delete a user contract record',
    createNewUserContract: 'Create a new user contract record',
    createUserCompensation: 'Create a new user compensation record',
    updateUserCompensation: 'Update user compensation record',
    deleteUserCompensation: 'Delete user compensation record',
    createNewUserRole: 'Create a new user role record',
    updateUserRole: 'Update user role record',
    deleteUserRole: 'Delete user role record',
    rehireUser: 'Rehire a user',
  },
};
