import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { addMonths } from 'date-fns';
import { Form, FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { MoneyTextfieldComponent } from '@/v2/components/forms/money-textfield.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { PayrunUserHeader } from '@/v2/feature/payroll/components/payrun-user-header.component';
import { extractPayLineEntriesFromPayRunEntry } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { PayLineTypeMenu } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payline-type-menu.component';
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from '@/v2/feature/payroll/payroll-external.interface';
import {
  PayLineEntry,
  PayRunEntryDto,
  PayrunEntryIncomeUpdateDto,
  RecurringPeriod,
} from '@/v2/feature/payroll/payroll.dto';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { StaffologyPayCode } from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { createShortDateAsUTC } from '@/v2/util/date-format.util';
import { setFocusToInput } from '@/v2/util/element.util';

type EditDeductionsProps = {
  user: CachedUser;
  payrunEntry: PayRunEntryDto;
  payCodes: StaffologyPayCode[];
  payrunClosed: boolean;
  saveIncomeUpdates: (incomeUpdates: PayrunEntryIncomeUpdateDto[]) => Promise<boolean>;
};

export const EditDeductionsPage = ({
  user,
  payrunEntry,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
}: EditDeductionsProps) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const [focusedTextEditId, setFocusedTextEditId] = useState('');

  const initialValues = useMemo(() => {
    return {
      paye: payrunEntry.totals.tax,
      employeeNi: payrunEntry.totals.employeeNi,
      employeePension: payrunEntry.totals.employeePensionContribution,
      studentLoan: payrunEntry.totals.studentLoanRecovered,
      postgradLoan: payrunEntry.totals.postgradLoanRecovered,
      deductions: extractPayLineEntriesFromPayRunEntry(payrunEntry, payCodes, 'deduction'),
    };
  }, [payCodes, payrunEntry]);

  const formik = useFormik<typeof initialValues>({
    initialValues,
    onSubmit: async (values) => {
      setSavingUpdate(true);
      const setIfChanged = (key: 'paye' | 'employeeNi' | 'studentLoan' | 'postgradLoan') =>
        values[key] !== formik.initialValues[key] ? values[key] : undefined;

      const update: PayrunEntryIncomeUpdateDto = {
        id: payrunEntry.id,
        userId: user.userId,
        deductions: {
          paye: setIfChanged('paye'),
          employeeNi: setIfChanged('employeeNi'),
          studentLoan: setIfChanged('studentLoan'),
          postgradLoan: setIfChanged('postgradLoan'),
          paylines: values.deductions,
        },
      };

      await saveIncomeUpdates([update]);
      setSavingUpdate(false);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!focusedTextEditId) return;
    setFocusToInput(focusedTextEditId);
  }, [focusedTextEditId]);

  const updateDeductions = useCallback((f: typeof formik, entry: PayLineEntry) => {
    const idx = f.values.deductions.findIndex(({ id }) => id === entry.id);
    const updatedDeductions = [...f.values.deductions];
    if (idx >= 0) {
      updatedDeductions[idx] = entry;
    } else {
      updatedDeductions.push(entry);
    }
    f.setFieldValue('deductions', updatedDeductions);
  }, []);

  const createDeductionPayLine = useCallback(
    (f: typeof formik, { code, title }: StaffologyPayCode) => {
      const newEntry: PayLineEntry = {
        id: uuidv4(),
        code,
        amount: 0,
        description: title,
        isDeduction: true,
        recurringId: null,
        recurring: null,
      };
      updateDeductions(f, newEntry);
      return newEntry;
    },
    [updateDeductions]
  );

  const updateRecurringRange = useCallback(
    (value: string, which: 'start' | 'end', recurring: RecurringPeriod): RecurringPeriod => {
      let { startDate, endDate } = recurring;
      const newDate = value;
      switch (which) {
        case 'start':
          startDate = newDate;
          endDate = newDate > endDate ? newDate : endDate;
          break;
        case 'end':
          endDate = newDate;
          startDate = newDate < startDate ? newDate : startDate;
          break;
      }
      return {
        startDate,
        endDate,
      };
    },
    []
  );

  return (
    <>
      <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Edit deductions</Typography>
      <PayrunUserHeader user={user} sx={{ mt: spacing.m10 }} />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Stack flex={1} sx={{ pt: spacing.p30 }}>
            <Stack sx={{ gap: spacing.g20 }}>
              <MoneyTextfieldComponent
                name="paye"
                label="PAYE"
                value={formik.values.paye}
                onChange={(value) => formik.setFieldValue('paye', value)}
                emptyIsZero
                disabled={savingUpdate || payrunClosed}
              />
              <MoneyTextfieldComponent
                name="ni"
                label="National insurance"
                value={formik.values.employeeNi}
                onChange={(value) => formik.setFieldValue('employeeNi', value)}
                emptyIsZero
                disabled={savingUpdate || payrunClosed}
              />
              {'employeePensionContribution' in payrunEntry.totals && (
                <MoneyTextfieldComponent
                  name="employeePension"
                  label="Pension"
                  value={formik.values.employeePension}
                  onChange={() => {}}
                  emptyIsZero
                  disabled
                />
              )}
              {payrunEntry.payOptions.taxAndNi.studentLoan !== 'None' && (
                <MoneyTextfieldComponent
                  name="studentLoan"
                  label="Student loan"
                  value={formik.values.studentLoan}
                  onChange={(value) => formik.setFieldValue('studentLoan', value)}
                  emptyIsZero
                  disabled={savingUpdate || payrunClosed}
                />
              )}
              {payrunEntry.payOptions.taxAndNi.postgradLoan && (
                <MoneyTextfieldComponent
                  name="postgradLoan"
                  label="Postgraduate loan"
                  value={formik.values.postgradLoan}
                  onChange={(value) => formik.setFieldValue('postgradLoan', value)}
                  emptyIsZero
                  disabled={savingUpdate || payrunClosed}
                />
              )}
            </Stack>
            {formik.values.deductions
              .sort((a, b) => a.code.localeCompare(b.code) || a.id.localeCompare(b.id))
              .map((deduction) => {
                const { id, code, description, amount, recurringId, recurring } = deduction;
                return (
                  <React.Fragment key={id}>
                    <Stack sx={{ mt: spacing.m60 }}>
                      <Stack sx={{ flexFlow: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={{ ...themeFonts.title2 }}>{code}</Typography>
                        <CheckboxComponent
                          checked={!!recurring}
                          onChange={(_, checked) =>
                            updateDeductions(formik, {
                              ...deduction,
                              recurringId,
                              recurring: checked
                                ? {
                                    startDate: payrunEntry.startDate,
                                    endDate: createShortDateAsUTC(addMonths(Date.parse(payrunEntry.endDate), 2)),
                                  }
                                : null,
                            })
                          }
                          label="Recurring"
                          labelSx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}
                          disabled={savingUpdate || payrunClosed}
                        />
                      </Stack>
                      <MoneyTextfieldComponent
                        id={id}
                        name={id}
                        label="Amount"
                        value={amount}
                        onChange={(amount) =>
                          typeof amount === 'number' && updateDeductions(formik, { ...deduction, amount })
                        }
                        disabled={savingUpdate || payrunClosed}
                        emptyIsZero
                        clearToZero
                        allowNegative
                        sx={{ mt: spacing.mt20 }}
                      />
                      <TextfieldComponent
                        name={`${id}Description`}
                        label="Description"
                        value={description}
                        maxLength={MAX_PAYLINE_DESCRIPTION_LENGTH}
                        onChange={(e) => updateDeductions(formik, { ...deduction, description: e.target.value })}
                        clearText={() => updateDeductions(formik, { ...deduction, description: '' })}
                        disabled={savingUpdate || payrunClosed}
                        sx={{ mt: spacing.m30 }}
                      />
                      {recurring && (
                        <Stack sx={{ flexFlow: 'row', gap: spacing.g20, mt: spacing.m30 }}>
                          <DatePickerComponent
                            name={`${id}StartDate`}
                            label="Effective start"
                            inputFormat="DD MMM YYYY"
                            minDate={payrunEntry.startDate}
                            maxDate={payrunEntry.endDate}
                            value={recurring.startDate}
                            onChange={(value) =>
                              updateDeductions(formik, {
                                ...deduction,
                                recurring: updateRecurringRange(value, 'start', recurring),
                              })
                            }
                            disabled={savingUpdate || payrunClosed}
                          />
                          <DatePickerComponent
                            name={`${id}EndDate`}
                            label="Effective end"
                            inputFormat="DD MMM YYYY"
                            minDate={addMonths(Date.parse(payrunEntry.startDate), 1)}
                            value={recurring.endDate}
                            onChange={(value) =>
                              updateDeductions(formik, {
                                ...deduction,
                                recurring: updateRecurringRange(value, 'end', recurring),
                              })
                            }
                            disabled={savingUpdate || payrunClosed}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </React.Fragment>
                );
              })}
          </Stack>
          {payrunClosed && (
            <Typography sx={{ ...themeFonts.caption, mt: spacing.m30, color: themeColors.DarkGrey }}>
              Deductions cannot be changed because the payrun is closed.
            </Typography>
          )}
          {!payrunClosed && (
            <PayLineTypeMenu
              kind="deduction"
              payCodes={payCodes}
              disabled={savingUpdate}
              onMenuItemClick={(payCode) => {
                const newEntry = createDeductionPayLine(formik, payCode);
                // set focus to the newly added item
                setFocusedTextEditId(newEntry.id);
              }}
              sx={{ mt: spacing.m30 }}
            />
          )}
          <Stack sx={{ mt: spacing.m30, flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>Total deductions</Typography>
            <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
              {formatCurrency(payrunEntry.totals.deductions)}
            </Typography>
          </Stack>
          {!payrunClosed && (
            <LoaderButton
              fullWidth
              loading={savingUpdate}
              name="Save"
              type="submit"
              sizeVariant="large"
              colorVariant="primary"
              style={{ marginTop: spacing.m30 }}
            />
          )}
        </Form>
      </FormikProvider>
    </>
  );
};
