import { Dispatch, SetStateAction } from 'react';

import { Form, FormikProvider } from 'formik';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  getDeadlineByReviewerFields,
  ShowFlags,
} from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util';
import { useTimelineForm } from '@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/hook/use-timeline-form.hook';
import { ReviewCycle } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

export const TimelineRollingModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  showFlags,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
  showFlags: ShowFlags;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <TimelineRollingContent reviewCycle={reviewCycle} onClose={onClose} refresh={refresh} showFlags={showFlags} />
    </DrawerModal>
  );
};

const TimelineRollingContent = ({
  reviewCycle,
  onClose,
  refresh,
  showFlags,
}: {
  reviewCycle: ReviewCycle | null | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  showFlags: ShowFlags;
}) => {
  const { polyglot } = usePolyglot();
  /**
   * Rolling cycles cannot be scheduled. They are immediately activated.
   */
  const formik = useTimelineForm(reviewCycle, onClose, refresh, showFlags);

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Timeline</Typography>
        {getDeadlineByReviewerFields(showFlags, formik, polyglot)}
      </Form>
    </FormikProvider>
  );
};
