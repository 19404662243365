import { Stack, Typography } from '@mui/material';

import { AuthLayout } from '@/v2/feature/auth/components/auth-layout.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const QuickbooksDisconnectedPage = () => {
  return (
    <AuthLayout title="QuickBooks Disconnected">
      <Stack spacing={spacing.m25} sx={{ mt: 1 }}>
        <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>
          Your QuickBooks integration has been disconnected.
          <br />
          If you’d like to reconnect Zelt and your QuickBooks account, integrate easily via the QuickBooks app in the
          <a href="https://go.zelt.app/apps/quickbooks/about"> Zelt App store</a>.
        </Typography>
      </Stack>
    </AuthLayout>
  );
};
