import { useContext } from 'react';

import { PayrollCompanyRouter } from '@v2/feature/payroll/payroll-company.router';
import { PayrollMeRouter } from '@v2/feature/payroll/payroll-me.router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { PAYROLL_COMPANY_ROUTE, PAYROLL_ME_ROUTE, PAYROLL_ME_SUMMARY_ROUTE, PAYROLL_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { useUserProfileConfig } from '@/v2/feature/user/features/user-profile/user-profile.hook';

export const PayrollRouter = (): JSX.Element => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const userId = user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });

  const { userOverviewConfig, loading: loadingUser } = useUserProfileConfig(userId);

  return (
    <Switch>
      <Route path={[PAYROLL_ME_ROUTE, PAYROLL_COMPANY_ROUTE]}>
        <Switch>
          <RouteScopesControl scopes={['payroll:read:all']} path={PAYROLL_COMPANY_ROUTE} context={scopesContext}>
            <PayrollCompanyRouter loading={loadingUser} />
          </RouteScopesControl>

          {globalState.user?.contractType === 'Employee' && (
            <RouteScopesControl path={PAYROLL_ME_ROUTE} scopes={['payroll:read']} context={scopesContext}>
              <PayrollMeRouter userOverviewConfig={userOverviewConfig} />
            </RouteScopesControl>
          )}
        </Switch>
      </Route>

      {checkScopes(globalState.user, ['payroll:all']) && (
        <RouteScopesControl path={PAYROLL_ROUTE} scopes={['payroll:all']} context={scopesContext}>
          <Redirect to={PAYROLL_COMPANY_ROUTE} />
        </RouteScopesControl>
      )}

      {Boolean(user.contractType === 'Employee') && !Boolean(user?.navigation?.salary?.isHidden) && (
        <Redirect from={PAYROLL_ROUTE} to={PAYROLL_ME_SUMMARY_ROUTE} />
      )}
    </Switch>
  );
};
