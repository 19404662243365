import { useEffect, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import { REQUESTS_SUBMIT_REQUEST_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { RequestFormTemplateSummary } from '@/v2/feature/requests/features/request-forms/request-forms.interface';
import { RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort } from '@/v2/util/array.util';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

const FormBox = ({
  form,
  selected,
  onClick,
  sx,
}: {
  form: RequestFormTemplateSummary;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Stack
      onClick={onClick}
      sx={{
        bgcolor: selected ? themeColors.GreyPress : themeColors.Background,
        p: spacing.pad20,
        gap: spacing.g10,
        borderRadius: radius.br15,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: selected ? undefined : themeColors.GreyHover,
        },
        boxSizing: 'border-box',
        transition: 'background-color .4s ease',
        maxWidth: '500px',
        minHeight: '50px',
        ...sx,
      }}
    >
      <Typography variant="title4">{form.name}</Typography>
      <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
        {form.description}
      </Typography>
    </Stack>
  );
};

export const ChooseRequestPage = () => {
  const { data: forms, error } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), {
    suspense: false,
  });
  const [selectedFormId, setSelectedFormId] = useState<number>();
  const routerHistory = useHistory();

  useEffect(() => {
    // auto-select the form if there's only one
    if (forms?.length === 1) {
      setSelectedFormId((id) => id ?? forms[0].id);
    }
  }, [forms]);

  useEscapeKey(() => {
    routerHistory.goBack();
  });

  return (
    <Stack sx={{ alignItems: 'center', flex: 1 }}>
      <Stack sx={{ gap: '30px', p: '30px 10px 0', overflow: 'hidden', width: '100%', maxWidth: '500px' }}>
        <Typography variant="title2">New request</Typography>
        <Stack overflow={'auto'} sx={{ pb: '30px', px: '10px' }}>
          {forms === undefined && <SkeletonLoader variant="rectangular" rows={5} width={'500px'} height={'100px'} />}
          {error && <Typography variant="caption">{error.data?.message}</Typography>}
          {forms && (
            <Stack gap={spacing.g10}>
              {forms
                .sort((a, b) => caseInsensitiveSort(a, b, (form) => form.name))
                .map((form) => (
                  <FormBox
                    key={form.id}
                    form={form}
                    selected={selectedFormId === form.id}
                    onClick={() => setSelectedFormId(form.id)}
                  />
                ))}
            </Stack>
          )}
          {selectedFormId && (
            <ButtonComponent
              type="button"
              sizeVariant="large"
              colorVariant="primary"
              fullWidth
              onClick={() => routerHistory.push(generatePath(REQUESTS_SUBMIT_REQUEST_ROUTE, { id: selectedFormId }))}
              style={{ flexShrink: 0, marginTop: '40px' }}
            >
              Continue
            </ButtonComponent>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
