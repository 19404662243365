import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { OptionObj } from '@v2/components/forms/user-select/single-user-select.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SettingsReadOnlyLine } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { useParams } from 'react-router-dom';

import { CompanyDepartmentAPI } from '@/api-client/company-department.api';
import { CompanyAPI } from '@/api-client/index.api';
import { SiteAPI } from '@/api-client/site.api';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { CustomRuleOptions, getUserSelectOptionLabel } from '@/v2/components/user-select-type/user-select.interface';
import { getCustomRule } from '@/v2/feature/absence/absence.util';
import { EditPaymentTypeMembersDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-setting-edit-members-drawer.component';
import { PaymentSettingsPageSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';

interface Member {
  name: string;
  userId: number;
  viaBenefit: boolean;
}

export const PaymentTypeMembersSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const params: { id: string } = useParams();
  const { cachedUsers } = useCachedUsers();
  const typeId = Number(params.id);
  const [ruleDataOption, setRuleDataOption] = useState<OptionObj[]>([]);

  const { data: paymentTypeSetting, mutate: refreshPaymentTypeSetting } = useApiClient(
    PaymentTypeSettingsEndpoints.getTypeSettingById(typeId),
    {
      suspense: false,
    }
  );

  const members: Member[] = useMemo(() => {
    const memberIds = new Set(paymentTypeSetting?.selectedMembersIds ?? []);
    const viaBenefitsSet = new Set(paymentTypeSetting?.viaBenefit ?? []);
    return cachedUsers
      .filter((u: CachedUser) => memberIds.has(u.userId))
      .map((u) => ({
        userId: u.userId,
        name: u.displayName ?? `${u.firstName} ${u.lastName}`,
        viaBenefit: viaBenefitsSet.has(u.userId),
      }));
  }, [cachedUsers, paymentTypeSetting]);

  const filteredUsers = useMemo(() => {
    if (!searchInput) return members ? [...members] : [];

    const search = searchInput.toLowerCase();
    return members ? [...members.filter((u) => u && u.name.toLowerCase().includes(search))] : [];
  }, [members, searchInput]);

  const getCustomRuleData = useCallback(async () => {
    const ruleTypeArray = paymentTypeSetting?.customRule ? paymentTypeSetting?.customRule?.split('=') : [];
    if (ruleTypeArray.length === 0) return;
    const ruleType = ruleTypeArray[0] ?? [];
    if (ruleType === CustomRuleOptions.Site) {
      const sitesData = await SiteAPI.listSites(true);
      setRuleDataOption(
        sitesData.map((s) => {
          return { label: s.name, value: s.id };
        })
      );
    }

    if (ruleType === CustomRuleOptions.Department) {
      const departmentsData = await CompanyDepartmentAPI.getCompanyDepartments();
      setRuleDataOption(
        departmentsData.map((d) => {
          return { label: d.name, value: d.id };
        })
      );
    }

    if (ruleType === CustomRuleOptions.Entity) {
      const entitiesData = (await CompanyAPI.getGeneralSettings())?.entities ?? [];
      setRuleDataOption(
        entitiesData.map((e) => {
          return { label: e.legalName, value: e.id };
        })
      );
    }
  }, [paymentTypeSetting?.customRule]);

  useEffect(() => {
    getCustomRuleData();
  }, [getCustomRuleData]);

  const customRuleString = useMemo(
    () => getCustomRule(paymentTypeSetting ? paymentTypeSetting.customRule : null, ruleDataOption, polyglot),
    [paymentTypeSetting, ruleDataOption, polyglot]
  );

  const tableColumns = useMemo<ColumnDef<Member, Member>[]>(
    () => [
      {
        header: () => (
          <Typography variant="captionSmall" color="Grey">
            {polyglot.t('General.name')}
          </Typography>
        ),
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, name, viaBenefit },
          },
        }) => (
          <Box key={userId} sx={{ display: 'flex', gap: spacing.g10 }}>
            <UserAvatar userId={userId} size="xsmall" />
            <Typography variant="caption">{polyglot.t(name)}</Typography>
            {viaBenefit && (
              <Typography variant="caption" color="Grey">
                (via Benefit)
              </Typography>
            )}
          </Box>
        ),
      },
    ],
    [polyglot]
  );

  return (
    <PaymentSettingsPageSection title={polyglot.t('General.members')} onEdit={() => setIsEditDrawerOpen(true)}>
      {paymentTypeSetting && paymentTypeSetting.membersRule && (
        <SettingsReadOnlyLine
          field={polyglot.t('AbsencePolicyMembersSection.members')}
          value={
            <Typography variant="title4">
              {getUserSelectOptionLabel(paymentTypeSetting.membersRule, polyglot)}
            </Typography>
          }
        />
      )}
      {customRuleString && (
        <SettingsReadOnlyLine
          field={polyglot.t('AbsencePolicyMembersSection.rule')}
          value={
            <Typography variant="title4">
              {polyglot.t('MembersSectionReadonly.selectAllFrom', { source: customRuleString })}
            </Typography>
          }
        />
      )}
      <Typography variant="title3">{polyglot.t('AbsencePolicyMembersSection.list')}</Typography>
      <TableSearch
        query={searchInput}
        handleChange={(e) => setSearchInput(e.target.value)}
        style={{ width: '572px' }}
      />
      {filteredUsers && filteredUsers.length > 0 && (
        <Box sx={{ width: '600px' }}>
          <BasicTable<Member>
            rowData={filteredUsers as Member[]}
            columnData={tableColumns}
            initialSort={[{ id: 'displayName', desc: true }]}
            fixedLastColumn={false}
          />
        </Box>
      )}

      {paymentTypeSetting && (
        <EditPaymentTypeMembersDrawer
          isOpen={isEditDrawerOpen}
          setIsOpen={setIsEditDrawerOpen}
          paymentTypeSetting={paymentTypeSetting}
          refresh={refreshPaymentTypeSetting}
        />
      )}
    </PaymentSettingsPageSection>
  );
};
