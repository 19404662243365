import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceEndpoints } from '@v2/feature/absence/absence.api';
import { UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OffboardingAbsenceBalancesTable } from '@v2/feature/offboarding/offboarding-process/absence/offboarding-absence-balances-table.component';
import { OffboardingFutureAbsencesTable } from '@v2/feature/offboarding/offboarding-process/absence/offboarding-future-absences-table.component';
import { OffboardingState } from '@v2/feature/offboarding/offboarding-process/offboarding-process.page';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { LocalDate } from '@v2/util/local-date';
import { generatePath } from 'react-router-dom';

import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';

interface PageProps {
  readonly userId: number;
  readonly offboardingState: OffboardingState;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
}

export const OffboardingAbsencePage = ({ userId, offboardingState, refresh, loading }: PageProps) => {
  const userLeaveDate = useMemo(() => offboardingState.leaveDate ?? new LocalDate().toDateString(), [offboardingState]);

  const { data: userBalanceStats, mutate: refreshPolicyBalances } = useApiClient<UserBalanceDetailedStatsDto, Error>(
    AbsenceEndpoints.fetchUserAbsenceBalanceBreakdown(userId, {
      effectiveDate: userLeaveDate,
    }),
    { suspense: false }
  );
  const { data: userPolicies } = useApiClient(AbsenceEndpoints.getUserAbsencePolicies(userId), {
    suspense: false,
  });

  const { data: absencesAfterLeaveDate, mutate: refreshFutureAbsences } = useApiClient(
    AbsenceEndpoints.getUserAbsencesAfterLeaveDate(userId),
    {
      suspense: false,
    }
  );

  const refreshBalances = useCallback(async () => {
    if (refreshPolicyBalances) await refreshPolicyBalances();
  }, [refreshPolicyBalances]);

  const refreshAbsences = useCallback(async () => {
    if (refreshFutureAbsences) await refreshFutureAbsences();
  }, [refreshFutureAbsences]);

  const refreshBalancesAndState = useCallback(async () => {
    await Promise.all([refreshBalances(), refresh()]);
  }, [refreshBalances, refresh]);

  const refreshAll = useCallback(async () => {
    await Promise.all([refreshBalances(), refresh(), refreshAbsences()]);
  }, [refreshBalances, refresh, refreshAbsences]);

  const { polyglot } = usePolyglot();

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('OffboardingPage.absence')}</Typography>}
        showBack
        backPath={generatePath(USER_OFFBOARDING_ROUTE, { userId })}
      />
      <ContentWrapper loading={loading}>
        <Box sx={{ maxWidth: '900px', width: '100%' }}>
          <OffboardingAbsenceBalancesTable
            userId={userId}
            userBalanceStats={userBalanceStats}
            userPolicies={userPolicies ?? []}
            userLeaveDate={offboardingState.leaveDate ?? new LocalDate().toDateString()}
            refreshState={refreshBalancesAndState}
            refreshFutureRequests={refreshAbsences}
          />

          {absencesAfterLeaveDate && absencesAfterLeaveDate.length > 0 && (
            <OffboardingFutureAbsencesTable
              absencesAfterLeaveDate={absencesAfterLeaveDate ?? []}
              refreshAll={refreshAll}
            />
          )}
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
