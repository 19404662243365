import { useCallback, useMemo, useState } from 'react';

import { Stack, SwipeableDrawer, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as StarFull } from '@/images/side-bar-icons/StarFull.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric } from '@/v2/components/table/table-sorting.util';
import { PageConfig } from '@/v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints,
} from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { GlobalPayrollPayCodeSeederDrawer } from '@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary-paycode-seeder-drawer.page';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { COUNTRY_ISO_CODE_MAPPING } from '@/v2/infrastructure/country/country.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

type Props = {
  readonly pageConfig: PageConfig;
};

export const SuperAdminSalaryPaycodeSeedersPage = ({ pageConfig }: Props): JSX.Element => {
  const [countryCode, setCountryCode] = useState('');
  const [editingCode, setEditingCode] = useState<GlobalPaycode | 'new' | null>(null);
  const [showMessage] = useMessage();

  const { data: paycodeSeeders, mutate: refreshPaycodeSeeders } = useApiClient(
    GlobalPayrollEndpoints.getPaycodeSeedersAsSuperAdmin(),
    {
      suspense: false,
    }
  );

  const [getPaycodeSeedersForCountry, countryHasPaycodeSeeders] = useMemo(() => {
    const map = new Map(paycodeSeeders?.items.map((p) => [p.countryCode.toLowerCase(), p.paycodes]));
    return [
      (countryCode: string) => map.get(countryCode.toLowerCase()) ?? [],
      (countryCode: string) => map.has(countryCode.toLowerCase()),
    ];
  }, [paycodeSeeders?.items]);

  const countryOptions = useMemo(() => {
    return [
      { label: 'Default', value: '' },
      ...Object.entries(COUNTRY_ISO_CODE_MAPPING)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([name, countryCode]) => ({
          label: name,
          value: countryCode,
          icon: countryHasPaycodeSeeders(countryCode) ? <StarFull {...iconSize} /> : <></>,
        })),
    ];
  }, [countryHasPaycodeSeeders]);

  const columns = useMemo<ColumnDef<GlobalPaycode, GlobalPaycode>[]>(
    () => [
      { id: 'code', header: () => 'code', accessorFn: (x) => x, cell: (c) => c.row.original.code },
      { id: 'name', header: () => 'name', accessorFn: (x) => x, cell: (c) => c.row.original.name },
      { id: 'credit', header: () => 'credit', accessorFn: (x) => x, cell: (c) => c.row.original.credit },
      { id: 'debit', header: () => 'debit', accessorFn: (x) => x, cell: (c) => c.row.original.debit },
      {
        id: 'required',
        header: () => 'required',
        accessorFn: (x) => x,
        cell: (c) => c.row.original.required.toString(),
      },
      {
        id: 'order',
        header: () => 'order',
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.order),
        cell: (c) => c.row.original.order,
      },
      {
        id: 'formula',
        header: () => 'formula',
        accessorFn: (x) => x,
        cell: (c) => <div style={{ whiteSpace: 'pre-line', fontFamily: 'monospace' }}>{c.row.original.formula}</div>,
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (x) => x,
        cell: (c) => (
          <ButtonComponent
            sizeVariant={'small'}
            colorVariant={'primary'}
            disabled={!!editingCode}
            onClick={() => setEditingCode(c.row.original)}
            style={{ marginLeft: 'auto' }}
          >
            Edit
          </ButtonComponent>
        ),
      },
    ],
    [editingCode]
  );

  const closePaycodeDrawerAndRefresh = useCallback(() => {
    setEditingCode(null);
    refreshPaycodeSeeders?.();
  }, [refreshPaycodeSeeders]);

  const setPaycodesForCountry = useCallback(
    async (destinationCountryCode: string, paycodes?: GlobalPaycode[]) => {
      if (!destinationCountryCode) return;
      if (!paycodes) return;
      try {
        await GlobalPayrollAPI.updatePaycodeSeedersAsSuperAdmin(destinationCountryCode, paycodes);
      } catch (e) {
        showMessage(`Failed to save paycode seeders. ${nestErrorMessage(e)}`, 'error');
        return;
      }
      refreshPaycodeSeeders?.();
    },
    [refreshPaycodeSeeders, showMessage]
  );

  const copyDefaultPaycodes = useCallback(
    async (destinationCountryCode: string) => {
      await setPaycodesForCountry(destinationCountryCode, getPaycodeSeedersForCountry(''));
    },
    [getPaycodeSeedersForCountry, setPaycodesForCountry]
  );

  const deleteAllPaycodes = useCallback(
    async (destinationCountryCode: string) => {
      await setPaycodesForCountry(destinationCountryCode, []);
    },
    [setPaycodesForCountry]
  );

  const selectedCountryOption = useMemo(() => countryOptions.find((c) => c.value === countryCode), [
    countryCode,
    countryOptions,
  ]);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Salary</Typography>}
        views={<></>}
      />
      <SecondaryHeaderMenu tabs={pageConfig.header.tabs!} />
      <ContentWrapper secondLevel>
        <Stack sx={{ gap: '40px' }}>
          <Stack>
            <SelectComponent
              name="countryCode"
              label="Country"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              options={countryOptions}
              sx={{ maxWidth: '400px' }}
            />
            {countryCode && selectedCountryOption && !countryHasPaycodeSeeders(countryCode) && (
              <ButtonComponent
                sizeVariant={'small'}
                colorVariant="textUnderline"
                disabled={!!editingCode}
                onClick={() => copyDefaultPaycodes(countryCode)}
              >
                {`Initialise ${selectedCountryOption.label} with default paycodes`}
              </ButtonComponent>
            )}
            {countryCode && selectedCountryOption && countryHasPaycodeSeeders(countryCode) && (
              <ButtonComponent
                sizeVariant={'small'}
                colorVariant="textUnderline"
                disabled={!!editingCode}
                onClick={() => deleteAllPaycodes(countryCode)}
              >
                {`Delete all paycodes for ${selectedCountryOption.label}`}
              </ButtonComponent>
            )}
          </Stack>
          <Stack>
            <ButtonComponent
              sizeVariant={'small'}
              colorVariant={'primary'}
              style={{ marginLeft: 'auto' }}
              disabled={!!editingCode}
              onClick={() => setEditingCode('new')}
            >
              Add paycode
            </ButtonComponent>
            <BasicTable
              initialSort={[{ id: 'order', desc: false }]}
              rowData={getPaycodeSeedersForCountry(countryCode)}
              columnData={columns}
            />
          </Stack>
        </Stack>
      </ContentWrapper>
      <SwipeableDrawer anchor="right" open={!!editingCode} onClose={() => setEditingCode(null)} onOpen={() => {}}>
        <Stack sx={{ width: '400px', padding: '20px' }}>
          {editingCode && (
            <GlobalPayrollPayCodeSeederDrawer
              countryCode={countryCode}
              paycode={editingCode === 'new' ? null : editingCode}
              paycodes={getPaycodeSeedersForCountry(countryCode)}
              onPaycodeDeleted={() => closePaycodeDrawerAndRefresh()}
              onPaycodeSaved={() => closePaycodeDrawerAndRefresh()}
            />
          )}
        </Stack>
      </SwipeableDrawer>
    </BackofficeRootStyle>
  );
};
