import axios from 'axios';

import {
  UserDeactivationChecks,
  UserDeletionChecks,
} from '@/v2/feature/user/features/user-profile/details/user-profile-details.interface';
import { UserDeactivationInfoDto } from '@/v2/feature/user/features/user-profile-action/user-profile-action.interface';

export class UserProfileActionAPI {
  static async deactivateUser(userId: number, deactivationDetails: UserDeactivationInfoDto): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/deactivate`, deactivationDetails);
  }

  static async cancelDeactivation(userId: number): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/deactivate/cancel`);
  }

  static async reactivateUser(userId: number): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/reactivate`);
  }

  static async activateUser(userId: number, mode?: string): Promise<void> {
    await axios.post(`/apiv2/users/${userId}/activate/${mode}`);
  }

  static async deleteUser(userId: number): Promise<void> {
    await axios.delete(`/apiv2/users/${userId}`);
  }

  static async getDeletionChecks(userId: number): Promise<UserDeletionChecks> {
    return (await axios.get(`/apiv2/users/${userId}/deletionChecks`))?.data;
  }

  static async getDeactivationChecks(userId: number): Promise<UserDeactivationChecks> {
    return (await axios.get(`/apiv2/users/${userId}/deactivationChecks`))?.data;
  }
}
