import { Box, SxProps } from '@mui/material';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';

export const NumberCell = ({
  value,
  cellSx = { maxWidth: '100px' },
}: {
  value?: number | string | null | undefined;
  maxWidth?: string;
  cellSx?: SxProps;
}) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', ...cellSx }}>
    {value !== null && value !== undefined ? <Typography variant="caption">{value}</Typography> : <EmptyCell />}
  </Box>
);
