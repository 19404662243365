import {
  AbsenceDto,
  AbsencePolicyDto,
  AbsenceSettingsDto,
  UserAbsenceAllowancesDto,
  UserBalanceDetailedStatsDto,
} from '@v2/feature/absence/absence.dto';
import { CreateOneOffPayItem, OneOffPaymentDto } from '@v2/feature/payroll/features/pay-item/pay-item.dto';
import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import { PaginatedResponse } from '@v2/util/pagination.util';
import axios from 'axios';

import {
  AbsenceEditableFields,
  AbsenceRequestAndBalanceDetails,
  CreateMultipleAbsence,
  UserAbsenceAllowanceAdjustmentDto,
} from '@/models/absence.model';
import {
  AbsenceStatus,
  AbsenceVerificationResult,
  AbsenceVerifyPayload,
  CreateAbsencePolicy,
  PolicyAccrualData,
  PolicyAllowanceFormData,
  PolicyAllowanceTenureData,
  PolicyApprovalFormData,
  PolicyCarryOverFormData,
  PolicyGeneralFormData,
  PolicyMembersFormData,
  PolicyNotificationsFormData,
  PolicyPayrollFormData,
  PolicyProratingFormData,
  PolicyPublicHolidaysFormData,
  PolicyRoundingFormData,
  UpdateAbsenceSettings,
} from '@/v2/feature/absence/absence.interface';
import { Alert } from '@/v2/infrastructure/alert/alert.interface';

export class AbsenceAPI {
  static async forceApprovalByAbsencesIds(
    absenceIds: number[],
    status: AbsenceStatus,
    approvalNotes?: string | null
  ): Promise<AbsenceDto[]> {
    return (await axios.patch('/apiv2/absences/force-approval', { absenceIds, approvalNotes, status }))?.data;
  }

  static async approveAllAbsences(absenceIds: number[]): Promise<AbsenceDto[]> {
    return (await axios.patch('/apiv2/absences/bulk-approve', { absenceIds }))?.data;
  }

  static async rejectAllAbsences(absenceIds: number[], approvalNotes?: string): Promise<AbsenceDto[]> {
    return (await axios.patch('/apiv2/absences/bulk-reject', { absenceIds, approvalNotes }))?.data;
  }

  static async calculateCompanyPolicyCarryOverByYearAsSuperAdmin(
    companyId: number,
    year: number,
    policyId: number
  ): Promise<void> {
    await axios.post(
      `/apiv2/absences/superadmin/carry-over-next-year-calculation/${year}/${companyId}/policies/${policyId}`
    );
  }

  static async addLeaverPolicyBalanceToPayroll(
    userId: number,
    policyId: number,
    payItem: CreateOneOffPayItem
  ): Promise<void> {
    await axios.post(`/apiv2/absence-policies/${policyId}/users/${userId}/offboarding/balance-to-payroll`, { payItem });
  }

  static async getCompanyAbsencesAsSuperAdmin(companyId: number): Promise<AbsenceDto[]> {
    return (await axios.get(`/apiv2/absences/superadmin/companies/${companyId}`)).data;
  }

  static async updateAbsenceByIdAsSuperAdmin(absenceId: number, data: Partial<AbsenceDto>): Promise<void> {
    await axios.patch(`/apiv2/absences/superadmin/${absenceId}`, data);
  }

  static async getCompanyUserAllowanceAdjustmentsByYearAsSuperAdmin(
    companyId: number,
    year: number,
    policyId: number
  ): Promise<UserAbsenceAllowanceAdjustmentDto[]> {
    return (
      await axios.get(`/apiv2/absences/allowance-adjustment/companies/${companyId}/year/${year}/policies/${policyId}`)
    ).data;
  }

  static async fetchCompanyUsersAbsenceBalanceBreakdown(
    policyId: number,
    calendar: 'last' | 'current' | 'next',
    page: number,
    pageSize: number,
    name?: string
  ): Promise<PaginatedResponse<UserBalanceDetailedStatsDto>> {
    let queryString = `?policyId=${policyId}&Calendar=${calendar}&page=${page}&pageSize=${pageSize}`;
    if (name) queryString = queryString + `&name=${name}`;
    return (await axios.get(`/apiv2/absences/company/balance${queryString}`)).data;
  }

  static exportCompanyUsersBalancesAsCSV(policyId: number, year: 'last' | 'current' | 'next'): string {
    return `/apiv2/absences/company/balance/export?policyId=${policyId}&year=${year}`;
  }

  static async fetchTeamUsersAbsenceBalanceBreakdown(
    policyId: number,
    calendar: 'last' | 'current' | 'next',
    page: number,
    pageSize: number,
    name?: string
  ): Promise<PaginatedResponse<UserBalanceDetailedStatsDto>> {
    let queryString = `?policyId=${policyId}&Calendar=${calendar}&page=${page}&pageSize=${pageSize}`;
    if (name) queryString = queryString + `&name=${name}`;
    return (await axios.get(`/apiv2/absences/team/balance${queryString}`)).data;
  }

  static async updateCompanyAbsenceSettings(absenceSettings: UpdateAbsenceSettings): Promise<void> {
    await axios.put('/apiv2/absence-settings', absenceSettings);
  }

  static async fetchAllAbsences(
    page: number,
    pageSize: number,
    filterString: string,
    searchInput: string
  ): Promise<PaginatedResponse<AbsenceDto>> {
    const queryString = [
      filterString,
      searchInput ? `search=${searchInput}` : '',
      `page=${page}`,
      `pageSize=${pageSize}`,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/absences?${queryString}`)).data ?? [];
  }

  static async fetchManagerTreeAbsences(
    page: number,
    pageSize: number,
    filterString: string,
    searchInput: string
  ): Promise<PaginatedResponse<AbsenceDto>> {
    const queryString = [
      filterString,
      searchInput ? `search=${searchInput}` : '',
      `page=${page}`,
      `pageSize=${pageSize}`,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/absences/manager?${queryString}`)).data ?? [];
  }

  static async fetchUserAbsences(
    userId: number,
    policyId: number,
    calendar: 'last' | 'current' | 'next',
    page: number,
    pageSize: number,
    filterString?: string,
    searchInput?: string
  ): Promise<PaginatedResponse<AbsenceDto>> {
    const queryString = [
      ...(filterString?.split('&').filter((f) => !f.startsWith('Time policy') && !f.startsWith('Calendar')) ?? []),
      `Time policy=${policyId}`,
      `Calendar=${calendar}`,
      searchInput ? `search=${searchInput}` : '',
      `page=${page}`,
      `pageSize=${pageSize}`,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/absences/users/${userId}?${queryString}`)).data ?? [];
  }

  static async updateAbsenceRecord(absenceId: number, absence: AbsenceEditableFields): Promise<void> {
    await axios.put(`/apiv2/absences/${absenceId}`, absence);
  }

  static async getAbsenceById(absenceId: number, userId: number): Promise<AbsenceDto> {
    return (await axios.get(`/apiv2/absences/${absenceId}/${userId}/by-user`))?.data;
  }

  static async getNewAbsenceBalanceImpactDetails(
    absenceData: CreateMultipleAbsence
  ): Promise<AbsenceRequestAndBalanceDetails> {
    return (await axios.post('/apiv2/absences/request-value-and-balance', absenceData)).data;
  }

  static async addMultipleAbsenceRecord(
    absence: CreateMultipleAbsence
  ): Promise<{
    success: boolean;
    method: 'direct' | 'queue';
    noOfCreatedAbsences?: number;
    skippedUsers?: number[];
  }> {
    return (await axios.post('/apiv2/absences/multiple', absence)).data;
  }

  static async deleteAbsenceRecord(absenceId: number): Promise<void> {
    await axios.delete(`/apiv2/absences/${absenceId}`);
  }

  static async getAlerts(userId: number): Promise<Alert<AbsenceDto>> {
    return (await axios.get(`/apiv2/absences/${userId}/alerts`)).data;
  }

  static async verifyCompanyOverlaps(parameters: AbsenceVerifyPayload): Promise<AbsenceVerificationResult> {
    return (await axios.post(`/apiv2/absences/verify-overlap`, parameters)).data;
  }

  static async createAbsencePolicy(data: CreateAbsencePolicy): Promise<AbsencePolicyDto> {
    return (await axios.post('/apiv2/absence-policies', data)).data;
  }

  static async orderAbsencePolicies(policiesOrder: number[]): Promise<void> {
    await axios.post('/apiv2/absence-policies/reorder-policies', policiesOrder);
  }

  static async updateAbsencePolicyGeneral(policyId: number, data: PolicyGeneralFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/general`, data);
  }

  static async updateAbsencePolicyMembers(policyId: number, data: PolicyMembersFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/members`, data);
  }

  static async updateAbsencePolicyAllowance(policyId: number, data: PolicyAllowanceFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/allowance`, data);
  }

  static async updateAbsencePolicyTenure(policyId: number, data: PolicyAllowanceTenureData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/tenure`, data);
  }

  static async updateAbsencePolicyAccrual(policyId: number, data: PolicyAccrualData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/accrual`, data);
  }

  static async updateAbsencePolicyPublicHolidays(policyId: number, data: PolicyPublicHolidaysFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/public-holidays`, data);
  }

  static async updateAbsencePolicyCarryOver(policyId: number, data: PolicyCarryOverFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/carry-over`, data);
  }

  static async updateAbsencePolicyProrating(policyId: number, data: PolicyProratingFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/prorating`, data);
  }

  static async updateAbsencePolicyRounding(policyId: number, data: PolicyRoundingFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/rounding`, data);
  }

  static async updateAbsencePolicyApproval(policyId: number, data: PolicyApprovalFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/approval`, data);
  }

  static async updateAbsencePolicyNotifications(policyId: number, data: PolicyNotificationsFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/notifications`, data);
  }

  static async updateAbsencePolicyPayroll(policyId: number, data: PolicyPayrollFormData): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/payroll`, data);
  }

  static async archiveAbsencePolicyById(policyId: number): Promise<void> {
    await axios.post(`/apiv2/absence-policies/${policyId}/archive`);
  }

  static async unarchiveAbsencePolicyById(policyId: number): Promise<void> {
    await axios.post(`/apiv2/absence-policies/${policyId}/unarchive`);
  }

  static async deleteAbsencePolicyById(policyId: number): Promise<void> {
    await axios.delete(`/apiv2/absence-policies/${policyId}`);
  }

  static async updateAbsencePolicyInstructionsById(policyId: number, instructions: string): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/instructions`, { instructions });
  }

  static async deleteAbsencePolicyInstructionsById(policyId: number): Promise<void> {
    await axios.delete(`/apiv2/absence-policies/${policyId}/instructions`);
  }

  static async updateAbsencePolicyUserAllowances(
    policyId: number,
    userId: number,
    data: { effectiveYear: number; userAllowance: number }
  ): Promise<void> {
    await axios.patch(`/apiv2/absence-policies/${policyId}/users/${userId}/allowances/user-allowance`, data);
  }

  static async updateAbsencePolicyUserAllowanceCarryOverAdjustment(
    policyId: number,
    userId: number,
    data: { effectiveYear: number; userCarryOverAdjustment: number }
  ): Promise<void> {
    await axios.patch(`/apiv2/absences/allowance-adjustment/policies/${policyId}/users/${userId}/carry-over`, data);
  }

  static async updateAbsencePolicyUserAllowanceOneOffAdjustment(
    userId: number,
    policyId: number,
    data: { effectiveYear: number; value: number; notes: string }
  ): Promise<void> {
    await axios.patch(`/apiv2/absences/allowance-adjustment/policies/${policyId}/users/${userId}/one-off`, data);
  }

  static async deleteAbsencePolicyUserAllowanceOneOffAdjustmentEntry(
    userId: number,
    policyId: number,
    effectiveYear: number,
    entryId: string
  ): Promise<void> {
    await axios.delete(
      `/apiv2/absences/allowance-adjustment/policies/${policyId}/users/${userId}/one-off/${effectiveYear}/entries/${entryId}`
    );
  }

  static async getCountOfPolicyRequests(policyId: number): Promise<number> {
    return (await axios.get(`/apiv2/absence-policies/${policyId}/requests-count`))?.data;
  }

  static async requestTimeRequestCancellation(absenceId: number): Promise<void> {
    await axios.post(`/apiv2/absences/${absenceId}/request-cancellation`);
  }

  static async rejectTimeCancellationRequests(absenceIds: number[], userId: number): Promise<void> {
    await axios.post('/apiv2/absences/reject-cancellation-requests', { userId, absenceIds });
  }

  static async getUserAbsenceBalanceBreakdownByPolicyId(
    userId: number,
    options: {
      calendar?: 'current' | 'last' | 'next';
      cycleStartYear?: number;
      effectiveDate?: string;
      policyId?: number;
      includeFormerPolicies?: boolean;
    }
  ): Promise<UserBalanceDetailedStatsDto> {
    const query = [
      options.calendar ? `Calendar=${options.calendar}` : null,
      options.cycleStartYear ? `Year=${options.cycleStartYear}` : null,
      options.effectiveDate ? `Date=${options.effectiveDate}` : null,
      options.policyId ? `policyId=${options.policyId}` : null,
      options.includeFormerPolicies ? `includeFormerPolicies=${options.includeFormerPolicies}` : null,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/absences/users/${userId}/balance?${query}`)).data;
  }

  static async getUserAbsenceBalanceBreakdownByPolicyIdAsSuperAdmin(
    companyId: number,
    userId: number,
    policyId: number,
    startCycleYear: number | null,
    effectiveDate: string | null
  ): Promise<UserBalanceDetailedStatsDto> {
    const queryParams = [
      `policyId=${policyId}`,
      startCycleYear ? `year=${startCycleYear}` : null,
      effectiveDate ? `effectiveDate=${effectiveDate}` : null,
    ]
      .filter(Boolean)
      .join('&');
    return (await axios.get(`/apiv2/absences/superadmin/companies/${companyId}/users/${userId}/balance?${queryParams}`))
      .data;
  }
}

export class AbsenceEndpoints {
  static getAlerts(userId: number): Endpoint<Alert<AbsenceDto>> {
    return {
      url: `/apiv2/absences/${userId}/alerts`,
    };
  }

  static getAbsencePolicies(): Endpoint<AbsencePolicyDto[]> {
    return {
      url: '/apiv2/absence-policies',
    };
  }

  // This includes fullName in the response (should be visible only in settings)
  static getAbsencePoliciesExtended(): Endpoint<AbsencePolicyDto[]> {
    return {
      url: '/apiv2/absence-policies/extended',
    };
  }

  static getTeamAbsencePolicies(): Endpoint<AbsencePolicyDto[]> {
    return {
      url: '/apiv2/absence-policies/team',
    };
  }

  static getTeamAbsencePoliciesExtended(): Endpoint<AbsencePolicyDto[]> {
    return {
      url: '/apiv2/absence-policies/team/extended',
    };
  }

  static getUserAbsencePolicies(userId: number, includePastMemberships = false): Endpoint<AbsencePolicyDto[]> {
    const query = [`includePastMemberships=${includePastMemberships}`].filter(Boolean).join('&');
    return {
      url: `/apiv2/absence-policies/users/${userId}?${query}`,
    };
  }

  static getArchivedAbsencePolicies(): Endpoint<AbsencePolicyDto[]> {
    return {
      url: '/apiv2/absence-policies/archived',
    };
  }

  static getAbsencePolicyExtendedById(policyId: number): Endpoint<AbsencePolicyDto> {
    return {
      url: `/apiv2/absence-policies/${policyId}/extended`,
    };
  }

  static getAbsencePolicyById(policyId: number): Endpoint<AbsencePolicyDto> {
    return {
      url: `/apiv2/absence-policies/${policyId}`,
    };
  }

  static getUserAbsencePolicyAllowances(
    policyId: number,
    userId: number,
    year: number
  ): Endpoint<UserAbsenceAllowancesDto> {
    return {
      url: `/apiv2/absence-policies/${policyId}/users/${userId}/allowances/${year}`,
    };
  }

  static fetchUserAbsenceBalanceBreakdown(
    userId: number,
    options: {
      calendar?: 'current' | 'last' | 'next';
      cycleStartYear?: number;
      effectiveDate?: string;
      policyId?: number;
      includeFormerPolicies?: boolean;
    }
  ): Endpoint<UserBalanceDetailedStatsDto> {
    const query = [
      options.calendar ? `Calendar=${options.calendar}` : null,
      options.cycleStartYear ? `Year=${options.cycleStartYear}` : null,
      options.effectiveDate ? `Date=${options.effectiveDate}` : null,
      options.policyId ? `policyId=${options.policyId}` : null,
      options.includeFormerPolicies ? `includeFormerPolicies=${options.includeFormerPolicies}` : null,
    ]
      .filter(Boolean)
      .join('&');

    return {
      url: `/apiv2/absences/users/${userId}/balance?${query}`,
    };
  }

  static fetchUserAllowedAbsencesByDateRange(
    dateRange: {
      start: string;
      end: string;
    },
    userId: number
  ): Endpoint<readonly AbsenceDto[]> {
    return {
      url: `/apiv2/absences/by-range?start=${dateRange.start}&end=${dateRange.end}&userId=${userId}`,
    };
  }

  static getCompanyAbsenceSettingsAsSuperAdmin(companyId: number): Endpoint<AbsenceSettingsDto> {
    return {
      url: `/apiv2/absence-settings/superadmin/companies/${companyId}`,
    };
  }

  static getAllCompanyAbsencePoliciesAsSuperAdmin(companyId: number): Endpoint<AbsencePolicyDto[]> {
    return {
      url: `/apiv2/absence-policies/superadmin/companies/${companyId}`,
    };
  }

  static getCompanyAbsenceSettings(): Endpoint<AbsenceSettingsDto> {
    return {
      url: '/apiv2/absence-settings',
    };
  }

  static getUserAbsencesAfterLeaveDate(userId: number): Endpoint<AbsenceDto[]> {
    return {
      url: `/apiv2/absences/users/${userId}/offboarding`,
    };
  }

  static getUserAbsenceOneOffPaymentToBeCreated(
    userId: number,
    policyId: number
  ): Endpoint<Omit<OneOffPaymentDto, 'id' | 'createdAt' | 'updatedAt'> | null> {
    return {
      url: `/apiv2/absence-policies/${policyId}/users/${userId}/offboarding/balance-to-payroll`,
    };
  }
}
