import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as CleanGrey } from '@/images/fields/CleanGrey.svg';
import { nestErrorMessage } from '@/lib/errors';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReviewCycleAPI } from '@/v2/feature/growth/reviews/api-client/review-cycle.api';
import {
  EditReviewers,
  ReviewCycle,
  ReviewParticipants,
  ReviewerTypes,
} from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ParticipantsRemoveReviewerModal = ({
  reviewCycle,
  selectedReviewee,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
}: {
  reviewCycle: ReviewCycle | undefined;
  selectedReviewee: ReviewParticipants | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <ParticipantsRemoveReviewerContent
        reviewCycle={reviewCycle}
        onClose={onClose}
        refresh={refresh}
        selectedReviewee={selectedReviewee}
      />
    </DrawerModal>
  );
};

const useReviewerDeleteForm = (
  selectedReviewee: ReviewParticipants | undefined,
  reviewCycle: ReviewCycle | undefined,
  onClose: () => void,
  refresh: () => Promise<void>,
  deletedPeerIds: number[],
  deletedUpwardIds: number[],
  deletedManagerIds: number[]
) => {
  const [showMessage] = useMessage();

  async function handleDraftOrScheduledReviewCycle(
    values: EditReviewers,
    reviewCycle: ReviewCycle,
    selectedReviewee: ReviewParticipants
  ) {
    const filteredPeerIds = values.peerIds.filter((peerId) => !deletedPeerIds.includes(peerId));
    const filteredUpwardsIds = values.upwardIds.filter((upwardId) => !deletedUpwardIds.includes(upwardId));
    const filteredManagerIds = values.managerIds.filter((managerId) => !deletedManagerIds.includes(managerId));

    await ReviewCycleAPI.removeReviewersToPreOngoing(reviewCycle.id, {
      selfId: selectedReviewee.selfId,
      peerIds: filteredPeerIds,
      upwardIds: filteredUpwardsIds,
      managerIds: filteredManagerIds,
    });
  }

  async function handleOngoingReviewCycle(reviewCycle: ReviewCycle, selectedReviewee: ReviewParticipants) {
    const reviewers: { reviewerType: ReviewerTypes; ids: number[] }[] = reviewCycle.reviewerSelect.reduce<
      { reviewerType: ReviewerTypes; ids: number[] }[]
    >((acc, r) => {
      let ids: number[] | null = null;

      if (r === ReviewerTypes.Peer && deletedPeerIds && deletedPeerIds.length) {
        ids = deletedPeerIds;
      } else if (r === ReviewerTypes.Manager && deletedManagerIds && deletedManagerIds.length) {
        ids = deletedManagerIds;
      } else if (r === ReviewerTypes.Upward && deletedUpwardIds && deletedUpwardIds.length) {
        ids = deletedUpwardIds;
      }

      if (ids) {
        acc.push({ reviewerType: r, ids });
      }
      return acc;
    }, [] as { reviewerType: ReviewerTypes; ids: number[] }[]);

    await ReviewCycleAPI.removeReviewers(reviewCycle.id, {
      reviewers: reviewers,
      revieweeId: selectedReviewee.selfId,
    });
  }

  const formik = useFormik<EditReviewers>({
    initialValues: {
      peerIds: selectedReviewee && selectedReviewee.peerIds ? selectedReviewee.peerIds : [],
      upwardIds: selectedReviewee && selectedReviewee.upwardIds ? selectedReviewee.upwardIds : [],
      managerIds: selectedReviewee && selectedReviewee.managerIds ? selectedReviewee.managerIds : [],
    },
    validationSchema: yup.object({
      peerIds: yup.array().of(yup.number()).required(),
      upwardIds: yup.array().of(yup.number()).required(),
      managerIds: yup.array().of(yup.number()).required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!reviewCycle || !selectedReviewee) throw new Error('Review cycle or selected reviewee is not defined.');

        if (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled) {
          await handleDraftOrScheduledReviewCycle(values, reviewCycle, selectedReviewee);
        }

        if (reviewCycle?.state === CycleState.Ongoing) {
          await handleOngoingReviewCycle(reviewCycle, selectedReviewee);
        }

        showMessage('Successfully deleted reviewers', 'success');
        await refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), 'error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return formik;
};

const ParticipantsRemoveReviewerContent = ({
  reviewCycle,
  onClose,
  refresh,
  selectedReviewee,
}: {
  reviewCycle: ReviewCycle | undefined;
  onClose: () => void;
  refresh: () => Promise<void>;
  selectedReviewee: ReviewParticipants | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const [deletedPeerIds, setDeletedPeerIds] = useState<number[]>([]);
  const [deletedUpwardIds, setDeletedUpwardIds] = useState<number[]>([]);
  const [deletedManagerIds, setDeletedManagerIds] = useState<number[]>([]);

  const formik = useReviewerDeleteForm(
    selectedReviewee,
    reviewCycle,
    onClose,
    refresh,
    deletedPeerIds,
    deletedUpwardIds,
    deletedManagerIds
  );

  if (!reviewCycle) return <></>;
  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Typography variant="title2">Remove reviewers</Typography>
        {reviewCycle.state === CycleState.Ongoing && (
          <Typography variant="caption" color="Grey">
            Any submitted answers for removed participants will be lost.
          </Typography>
        )}

        {selectedReviewee?.selfId && (
          <ViewItem title="Participant" value={<UserCell userId={selectedReviewee?.selfId} />} />
        )}

        {reviewCycle.reviewerSelect?.includes(ReviewerTypes.Manager) ? (
          formik.values?.managerIds &&
          formik.values?.managerIds.length > 0 && (
            <ViewItem
              title="Manager"
              value={
                <Box>
                  {formik.values?.managerIds.map((managerId, idx) => (
                    <ReviewerListing
                      key={idx}
                      reviewerId={managerId}
                      isLast={idx === formik.values?.managerIds.length - 1}
                      onClick={() => {
                        const filteredPeerIds = formik.values.managerIds.filter((id) => id !== managerId);
                        formik.setFieldValue('managerIds', filteredPeerIds);
                        setDeletedManagerIds((prevDeleted: number[]) => [...prevDeleted, managerId]);
                      }}
                    />
                  ))}
                </Box>
              }
            />
          )
        ) : (
          <ViewItem title="Manager reviewer" value="This employee has no manager reviewers." />
        )}

        {reviewCycle.reviewerSelect?.includes(ReviewerTypes.Upward) ? (
          formik.values?.upwardIds &&
          formik.values?.upwardIds.length > 0 && (
            <ViewItem
              title="Upward reviewer"
              value={
                <Box>
                  {formik.values?.upwardIds.map((upwardId, idx) => (
                    <ReviewerListing
                      key={idx}
                      reviewerId={upwardId}
                      isLast={idx === formik.values?.upwardIds.length - 1}
                      onClick={() => {
                        const filteredPeerIds = formik.values.upwardIds.filter((id) => id !== upwardId);
                        formik.setFieldValue('upwardIds', filteredPeerIds);
                        setDeletedUpwardIds((prevDeleted: number[]) => [...prevDeleted, upwardId]);
                      }}
                    />
                  ))}
                </Box>
              }
            />
          )
        ) : (
          <ViewItem title="Upward reviewer" value="This employee has no upward reviewers." />
        )}

        {reviewCycle.reviewerSelect?.includes(ReviewerTypes.Peer) ? (
          formik.values?.peerIds &&
          formik.values?.peerIds.length > 0 && (
            <ViewItem
              title="Peer reviewer"
              value={
                <Box>
                  {formik.values?.peerIds.map((peerId, idx) => (
                    <ReviewerListing
                      key={peerId}
                      reviewerId={peerId}
                      isLast={idx === formik.values?.peerIds.length - 1}
                      onClick={() => {
                        const filteredPeerIds = formik.values.peerIds.filter((id) => id !== peerId);
                        formik.setFieldValue('peerIds', filteredPeerIds);
                        setDeletedPeerIds((prevDeleted: number[]) => [...prevDeleted, peerId]);
                      }}
                    />
                  ))}
                </Box>
              }
            />
          )
        ) : (
          <ViewItem title="Peer reviewer" value="This employee has no peers reviewers." />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            type="submit"
            disabled={deletedPeerIds.length === 0 && deletedManagerIds.length === 0 && deletedUpwardIds.length === 0}
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('General.save')}
            loading={formik.isSubmitting}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const ViewItem = ({ title, value }: { title: string; value: React.JSX.Element | string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.g8,
      }}
    >
      <Typography variant="caption" sx={{ color: themeColors.Grey }}>
        {title}
      </Typography>
      {typeof value === 'string' ? <Typography variant="caption">{value}</Typography> : <Box>{value}</Box>}
    </Box>
  );
};

const ReviewerListing = ({
  reviewerId,
  onClick,
  isLast,
}: {
  reviewerId: number;
  onClick: () => void;
  isLast: boolean;
}) => {
  return (
    <Box
      key={reviewerId}
      sx={{
        paddingY: spacing.p12,
        borderBottom: isLast ? 'none' : `1px solid ${themeColors.Background}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <UserCell userId={reviewerId} key={reviewerId} />
      <CleanGrey {...iconSize} style={{ cursor: 'pointer' }} onClick={onClick} />
    </Box>
  );
};
