import { useContext, useMemo, useState } from 'react';

import { GlobalContext } from '@/GlobalState';
import { canAccessScopes } from '@/lib/scopes';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReminderFrequencyDict } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleCreationNotificationsModal } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/components/survey-cycle-creation-notifications-modal.component';
import { TitleStatusComponent } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { spacing } from '@/v2/styles/spacing.styles';

export const SurveyCycleCreationNotificationsPage = ({
  surveyCycle,
  refresh,
}: {
  surveyCycle: SurveyCycle | null | undefined;
  refresh: () => Promise<void>;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['surveys:all']);
  const isManager = canAccessScopes(user, ['surveys:manager']);

  const isEditable = useMemo(
    () =>
      Boolean(
        surveyCycle &&
          (surveyCycle.state === CycleState.Draft ||
            surveyCycle.state === CycleState.Scheduled ||
            surveyCycle.state === CycleState.Ongoing) &&
          Boolean(isAdmin || (isManager && surveyCycle.owner === user.userId))
      ),
    [surveyCycle, isAdmin, isManager, user]
  );

  if (!surveyCycle) return <></>;
  const { notificationSettings } = surveyCycle;

  return (
    <SettingsSectionContent
      title={surveyCycle ? <TitleStatusComponent surveyCycle={surveyCycle} /> : 'Notifications'}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      loading={false}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Notifications',
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.Pair,
                label: 'Reminder',
                value: ReminderFrequencyDict[notificationSettings.reminderFrequency],
              },
              {
                type: SectionItemType.Pair,
                label: 'Invite message',
                value: 'Use default message',
              },
            ],
          },
        ]}
      />
      <SurveyCycleCreationNotificationsModal
        surveyCycle={surveyCycle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
        refresh={async () => {
          await refresh?.();
        }}
      />
    </SettingsSectionContent>
  );
};
