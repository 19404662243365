import React from 'react';

import { v4 } from 'uuid';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SurveyQuestionAPI } from '@/v2/feature/growth/surveys/api-client/survey-question.api';
import { SurveyQuestionModalContent } from '@/v2/feature/growth/surveys/features/components/survey-question-modal-content.component';
import {
  CommonSurveyCycleAndBankQuestion,
  SurveyQuestion,
  SurveyQuestionUpsert,
} from '@/v2/feature/growth/surveys/interfaces/survey-question.interface';
import { SurveySection } from '@/v2/feature/growth/surveys/interfaces/survey-section.interface';

export const SurveyCycleCreationQuestionFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  question,
  refresh,
  cycleId,
  section,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  question: SurveyQuestion | null;
  refresh: () => Promise<void>;
  cycleId: string;
  section?: SurveySection | null | undefined;
}) => {
  const onSubmit = async (values: CommonSurveyCycleAndBankQuestion) => {
    if (question?.id) {
      //update question
      const questionValues = {
        ...question,
        ...values,
        cycleId,
        sectionId: section?.id ?? null,
      };
      await SurveyQuestionAPI.updateQuestionInCycle(questionValues as SurveyQuestionUpsert);
    } else {
      const questionValues: SurveyQuestionUpsert = {
        ...values,
        id: v4(),
        cycleId,
        sectionId: section?.id ?? null,
      };
      await SurveyQuestionAPI.createAndCloneQuestionInCycle(questionValues);
    }
  };
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <SurveyQuestionModalContent onClose={onClose} question={question} refresh={refresh} onSubmit={onSubmit} />
    </DrawerModal>
  );
};
