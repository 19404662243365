import { useCallback, useContext, useMemo, useState } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ZincIcon } from '@/images/side-bar-icons/zinc.svg';
import { checkScopes } from '@/lib/scopes';
import { AutocompleteComponent, OptionObject } from '@/v2/components/forms/autocomplete.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { NewIdCheckPackageDrawer } from '@/v2/feature/id-check/components/new-id-check-package-drawer.page';
import { IdCheckEndpoints } from '@/v2/feature/id-check/id-check.api';
import { IdCheckPackage } from '@/v2/feature/id-check/id-check.interface';
import {
  CheckCountry,
  CheckPackage,
  ZincIDCheckPackage,
} from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { underlinedLinkLight } from '@/v2/styles/buttons.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatAsGBPCurrency } from '@/v2/util/string-format.util';

type EditIdVerifyProps = {
  onSave: (checkPackage?: CheckPackage, checkCountry?: CheckCountry) => Promise<void>;
  mode?: 'template' | 'profile';
};

export const ZINC_TERMS_LINK = 'https://zincwork.com/terms-payg';
export const ZINC_CHECKS_LINK = 'https://zincwork.com/checks';

interface EditIdVerifyChooserResult {
  chosenPackage: IdCheckPackage | undefined;
}

export const EditIdVerify = ({ onSave, mode = 'template' }: EditIdVerifyProps) => {
  const { polyglot } = usePolyglot();
  const {
    data: individualChecks,
    mutate: refreshIndividualChecks,
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  // const { data: zincData, isValidating: fetchingPackages } = useApiClient(OnboardingEndpoints.getIDCheckPackages(), {
  //   suspense: false,
  // });

  const { data: idCheckPackages, isLoading: fetchingPackages, mutate: refreshIdCheckPackages } = useApiClient(
    IdCheckEndpoints.getIdCheckPackages(),
    {
      suspense: false,
    }
  );

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;

  const scopesContext = { userId: user.userId };

  const [selectedPackageOption, setSelectedPackageOption] = useState<OptionObject | undefined>(undefined);
  const [newPackageDrawerOpen, setNewPackageDrawerOpen] = useState(false);

  const [showMessage] = useMessage();

  const refresh = useCallback(async () => {
    if (refreshIdCheckPackages) await refreshIdCheckPackages();
    if (refreshIndividualChecks) await refreshIndividualChecks();
  }, [refreshIdCheckPackages, refreshIndividualChecks]);

  const formik = useFormik<EditIdVerifyChooserResult>({
    initialValues: {
      chosenPackage: undefined,
    },
    validationSchema: yup.object({
      chosenPackage: yup.object().required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values: EditIdVerifyChooserResult) => {
      const { chosenPackage } = values;
      if (!chosenPackage) {
        showMessage('Package missing - try again', 'error');
        return;
      }
      const packageToUse: CheckPackage = {
        id: chosenPackage?.id,
        name: chosenPackage.name as ZincIDCheckPackage,
        description: '',
        cost: chosenPackage?.price,
      };
      onSave(packageToUse);
    },
  });

  const packageOptions: readonly OptionObject[] = useMemo(() => {
    return idCheckPackages && idCheckPackages.length > 0
      ? idCheckPackages.map((pkg) => ({
          label: `${pkg.name} (${formatAsGBPCurrency(pkg.price, 2)})`,
          value: pkg.id,
        }))
      : [];
  }, [idCheckPackages]);

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography sx={themeFonts.title2}>{polyglot.t('EditIdVerify.check')}</Typography>
        <Typography sx={{ ...themeFonts.caption, mt: spacing.mt10 }}>
          {polyglot.t('EditIdVerify.poweredBy')} <ZincIcon {...iconSize} /> {polyglot.t('EditIdVerify.zincMex')}
        </Typography>

        <AutocompleteComponent
          name="chosenPackageId"
          label={polyglot.t('IdCheckPackage.selectPackage')}
          options={packageOptions}
          value={selectedPackageOption}
          onChange={(_, x) => {
            if (!x) return;
            const selectedOption = x as OptionObject;
            const matchingPackage = (idCheckPackages ?? []).find(
              (pkg: IdCheckPackage) => pkg.id === selectedOption.value
            );
            formik.setFieldValue('chosenPackage', matchingPackage);
            setSelectedPackageOption(selectedOption);
          }}
          error={hasSubmitted && !!formik.errors.chosenPackage}
          helperText={hasSubmitted && formik.errors.chosenPackage}
          editList={{
            handler: () => {
              setNewPackageDrawerOpen(true);
            },
            isHidden: !checkScopes(user, ['user.onboard:all'], scopesContext),
          }}
        />
        <Stack direction="row" sx={{ gap: spacing.g10 }}>
          <Link sx={{ ...underlinedLinkLight }} target="_blank" href={ZINC_TERMS_LINK}>
            {polyglot.t('EditIdVerify.zinc')}
          </Link>
          <Link sx={{ ...underlinedLinkLight }} target="_blank" href={ZINC_CHECKS_LINK}>
            {polyglot.t('EditIdVerify.zincChecksInfo')}
          </Link>
        </Stack>
        <StyledTooltip
          title={
            !globalState.user.hasPaymentMethodOnFile && (
              <Typography sx={{ ...themeFonts.tooltip, opacity: 1 }}>
                {polyglot.t('EditIdVerify.cannotUse')}{' '}
                <Link target="_blank" href="/settings/billing">
                  {polyglot.t('EditIdVerify.billing')}
                </Link>
              </Typography>
            )
          }
        >
          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent
              disabled={fetchingPackages || !globalState.user.hasPaymentMethodOnFile}
              fullWidth
              colorVariant="primary"
              sizeVariant="medium"
            >
              {mode === 'template' ? polyglot.t('General.save') : polyglot.t('General.invite')}
            </ButtonComponent>
          </Box>
        </StyledTooltip>
        {newPackageDrawerOpen && (
          <NewIdCheckPackageDrawer
            isOpen={newPackageDrawerOpen}
            setIsOpen={setNewPackageDrawerOpen}
            currentUser={user}
            idCheckPackage={null}
            individualChecks={individualChecks ?? []}
            refresh={refresh}
          />
        )}
      </Form>
    </FormikProvider>
  );
};
