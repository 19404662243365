import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import {
  InsuranceProvider,
  UpsertInsurancePolicy,
} from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { fieldSx, titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

const INSURANCE_PROVIDERS_OPTIONS = [
  { label: 'Vitality', value: InsuranceProvider.Vitality },
  { label: 'AXA', value: InsuranceProvider.AXA },
  { label: 'Bupa - Dental', value: InsuranceProvider.BupaDental },
  { label: 'Bupa - PMI', value: InsuranceProvider.BupaPMI },
  { label: 'Aviva', value: InsuranceProvider.Aviva },
  { label: 'Freedom', value: InsuranceProvider.Freedom },
];

interface ManageInsurancePolicyDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly quoteId?: number | null;
  readonly policy?: InsurancePolicyDto | null;
  readonly refresh: () => Promise<void>;
  readonly onSave: () => void;
}

export const ManageInsurancePolicyDrawer = ({
  isOpen,
  setIsOpen,
  quoteId,
  policy,
  onSave,
  refresh,
}: ManageInsurancePolicyDrawerProps) => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ManageInsurancePolicyDrawerContent quoteId={quoteId} policy={policy} refresh={refresh} onSave={onSave} />
    </DrawerModal>
  );
};

interface ManageInsurancePolicyDrawerContentProps {
  readonly quoteId?: number | null;
  readonly policy?: InsurancePolicyDto | null;
  readonly onSave: () => void;
  readonly refresh: () => Promise<void>;
}

const ManageInsurancePolicyDrawerContent = ({
  quoteId,
  policy,
  onSave,
  refresh,
}: ManageInsurancePolicyDrawerContentProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const formik = useFormik<UpsertInsurancePolicy>({
    initialValues: {
      providerName: policy?.providerName ?? InsuranceProvider.Vitality,
      policyStartDate: policy ? policy.startDate : new LocalDate().toDateString(),
      policyEndDate: policy ? policy.endDate : new LocalDate().toDateString(),
      policyNumber: policy?.policyNumber ?? '',
      claimsPhoneNumber: policy?.claimsPhone ?? '',
      supportPhoneNumber: policy?.supportPhone ?? '',
      insuranceBrokerPhoneNumber: policy?.insuranceBrokerPhone ?? '',
    },
    validationSchema: yup.object({
      providerName: yup.string().required('Provider name is required'),
      policyStartDate: yup.string().test(dateFieldTest).required('Policy start date is required'),
      policyEndDate: yup.string().test(dateFieldTest).required('Policy end date is required'),
      policyNumber: yup.string().required('Policy number is required'),
      claimsPhoneNumber: yup.string().required('Claims phone is required'),
      supportPhoneNumber: yup.string().required('Support phone is required'),
      insuranceBrokerPhoneNumber: yup.string().required('Insurance broker phone is required'),
    }),
    onSubmit: async (values: UpsertInsurancePolicy) => {
      if (!quoteId && !policy) {
        showMessage('Quote Id or Policy is required.', 'error');
        return;
      }
      quoteId ? await createPolicy(quoteId, values) : await updatePolicy(policy!, values);
    },
  });

  const createPolicy = async (quoteId: number, values: UpsertInsurancePolicy) => {
    setLoading(true);
    try {
      await InsuranceAPI.createPolicyForInsuranceAsSuperAdmin(quoteId, values);
      await refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not create the policy. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updatePolicy = async (policy: InsurancePolicyDto, values: UpsertInsurancePolicy) => {
    setLoading(true);
    try {
      await InsuranceAPI.updatePolicyForInsuranceAsSuperAdmin(policy.id, values);
      await refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not update the policy. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Typography sx={titleSx}>{policy ? 'Update policy' : 'Create policy'}</Typography>

        <Typography sx={{ ...themeFonts.title2, mb: spacing.mb20 }}>Policy details</Typography>

        <Box sx={fieldSx}>
          <SelectComponent
            name="providerName"
            label="Provider name"
            options={INSURANCE_PROVIDERS_OPTIONS}
            value={formik.values.providerName}
            onChange={formik.handleChange}
            compareValue={formik.values.providerName}
            error={!!formik.errors.providerName && formik.touched.providerName}
            helperText={(formik.touched.providerName && formik.errors.providerName) as string}
          />
        </Box>

        <Box sx={fieldSx}>
          <DatePickerComponent
            name="policyStartDate"
            label="Policy start date"
            inputFormat="DD/MM/YYYY"
            value={formik.values.policyStartDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('policyStartDate', value);
              }
            }}
            error={!!formik.errors.policyStartDate && Boolean(formik.touched.policyStartDate)}
            helperText={formik.errors.policyStartDate && Boolean(formik.touched.policyStartDate)}
          />
        </Box>

        <Box sx={fieldSx}>
          <DatePickerComponent
            name="policyEndDate"
            label="Policy end date"
            inputFormat="DD/MM/YYYY"
            value={formik.values.policyEndDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('policyEndDate', value);
              }
            }}
            error={!!formik.errors.policyEndDate && Boolean(formik.touched.policyEndDate)}
            helperText={formik.errors.policyEndDate && Boolean(formik.touched.policyEndDate)}
            disablePast
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Policy number"
            name="policyNumber"
            value={formik.values.policyNumber}
            onChange={formik.handleChange}
            error={formik.touched.policyNumber && Boolean(formik.errors.policyNumber)}
            helperText={(formik.touched.policyNumber && formik.errors.policyNumber) as string}
            size="small"
            endAdornment="none"
          />
        </Box>

        {/* TODO: @polyglot-later-insurance-hooray */}
        <Typography sx={{ ...themeFonts.title2, mb: spacing.mb20, mt: spacing.mt40 }}>Contact details</Typography>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Claims phone number"
            name="claimsPhoneNumber"
            value={formik.values.claimsPhoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.claimsPhoneNumber && Boolean(formik.errors.claimsPhoneNumber)}
            helperText={(formik.touched.claimsPhoneNumber && formik.errors.claimsPhoneNumber) as string}
            size="small"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Support phone number"
            name="supportPhoneNumber"
            value={formik.values.supportPhoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.supportPhoneNumber && Boolean(formik.errors.supportPhoneNumber)}
            helperText={(formik.touched.supportPhoneNumber && formik.errors.supportPhoneNumber) as string}
            size="small"
            endAdornment="none"
          />
        </Box>

        <Box sx={fieldSx}>
          <TextfieldComponent
            label="Insurance broker phone number"
            name="insuranceBrokerPhoneNumber"
            value={formik.values.insuranceBrokerPhoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.insuranceBrokerPhoneNumber && Boolean(formik.errors.insuranceBrokerPhoneNumber)}
            helperText={
              (formik.touched.insuranceBrokerPhoneNumber && formik.errors.insuranceBrokerPhoneNumber) as string
            }
            size="small"
            endAdornment="none"
          />
        </Box>

        <Box sx={{ ...buttonBoxSx, mt: spacing.m30 }}>
          <LoaderButton name="Save" loading={loading} sizeVariant="medium" colorVariant="secondary" fullWidth />
        </Box>
      </Form>
    </FormikProvider>
  );
};
