import {
  AbsenceBreakdown,
  AbsenceLengthUnit,
  AbsencePolicyAllowanceType,
  AbsencePolicyMember,
  AbsenceStatus,
  AccrualCliff,
  AccrualUnit,
  AllowanceRoundingType,
  HolidaysProratingRule,
  OneOffAbsenceAdjustmentEntry,
  OverlapsRule,
  TimePolicyReminderSettings,
} from '@v2/feature/absence/absence.interface';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';
import { CountryCode } from '@v2/infrastructure/country/country.interface';

import { UserSelectFiltersOptions } from '@/v2/components/user-select-type/user-select.interface';

export interface AbsencePolicyDto {
  id: number;
  companyId: number;
  name: string;
  fullName?: string;
  allowance: number | null;
  allowanceType: AbsencePolicyAllowanceType;
  isPublic: boolean;
  proratingFte: boolean;
  proratingStartDate: boolean;
  allowanceRoundingType: AllowanceRoundingType;
  accrualUnit: AccrualUnit | null;
  accrualUnitsNo: number | null;
  accrueUpfront: boolean;
  accrualCliff: AccrualCliff | null;
  color: string;
  carryOverEnabled: boolean;
  carryOverLimit: number | null;
  carryOverExpirationDay: number | null;
  carryOverExpirationMonth: number | null;
  backoffApprovalRuleId: number;
  updateApprovalNotRequired: boolean;
  notificationsEnabled: boolean;
  remindersSettings: TimePolicyReminderSettings;
  membersRule: UserSelectFiltersOptions;
  cycleStartDay: number;
  cycleStartMonth: number;
  selectedMembers?: AbsencePolicyMember[];
  selectedMembersIds?: number[];
  customRule: string | null;
  backoffApprovalRule?: Partial<ApprovalRuleDto> | null;
  order: number;
  publicHolidayOnTop: boolean;
  publicHolidayAutobook: boolean;
  holidaysProratingRule: HolidaysProratingRule;
  deletedAt: Date | null;
  payCode: string | null;
  inPayrollRequests: boolean;
  inPayrollOffboarding: boolean;
  payCodeMultiplier: number | null;
  tenureEnabled: boolean;
  tenureAdditionSettings: Record<number, number> | null;
  tenureAllocation: AbsencePolicyTenureAllocation | null;
  attachmentType: AbsencePolicyAttachmentType | null;
  instructions: string | null;
}

export interface AbsenceDto {
  id?: string;
  readonly absenceId: number;
  readonly userId: number;
  readonly reasonToChange: string;
  readonly notes: string | null;
  readonly start: string; // 'yyyy-mm-dd'
  readonly end: string | null; // 'yyyy-mm-dd'
  readonly startHour: string | null; // 'yyyy-mm-ddThh:mm:ss'
  readonly endHour: string | null; // 'yyyy-mm-ddThh:mm:ss'
  readonly startHourTimestamp: Date | null;
  readonly endHourTimestamp: Date | null;
  readonly policyId: number;
  readonly status: AbsenceStatus;
  readonly holidayCalendar: CountryCode;
  readonly morningOnly: boolean;
  readonly afternoonOnly: boolean;
  readonly carryOverYear: number;
  readonly potentialCarryOver: number;
  readonly totalLength: number;
  readonly workdayCount: number;
  readonly year2021: number;
  readonly year2022: number;
  readonly year2023: number;
  readonly year2024: number;
  readonly year2025: number;
  readonly year2026: number;
  readonly lengthUnit: AbsenceLengthUnit;
  readonly canEdit: boolean;
  readonly canDelete: boolean;
  readonly canApprove: boolean;
  readonly canReject: boolean;
  readonly canForceApprove: boolean;
  readonly canForceReject: boolean;
  readonly canRequestCancellation: boolean;
  readonly canApproveCancellation: boolean;
  readonly canRejectCancellation: boolean;
  readonly isPublicHoliday?: boolean;
  readonly deletionRequestId?: number | null;
  readonly canRequestPHDeletion?: boolean;
  readonly canDeletePHCancellation?: boolean;
  readonly createdAt: Date;
  readonly createdBy: number | null;
  readonly approverSteps: ApproverStep[];
  readonly approvedOnTimestamp?: Date | null;
  readonly approvedByIds: number[];
  readonly rejectedByIds: number[];
  readonly approvalNotes: string | null;
  readonly attachment: string | null;
  readonly user?: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  readonly policy?: Partial<AbsencePolicyDto>;
  readonly cancellationRequested: boolean;
  readonly policyName?: string;
}

export interface UserBalanceDetailedStatsDto {
  userId: number;
  name: string;
  startDate: string | null;
  department: string | null;
  site: string | null;

  [policyId: number]: AbsenceBreakdown | null;
}

export interface UserAbsenceAllowancesDto {
  readonly userId: number;
  readonly policyId: number;
  readonly effectiveYear: number;
  readonly userAllowance: number | null;
  readonly userOneOffAdjustment: number | null;
  readonly oneOffAdjustmentEntries: OneOffAbsenceAdjustmentEntry[] | null;
  readonly userCarryOverAdjustment: number | null;
}

export interface AbsenceSettingsDto {
  companyId: number;
  holidayCountry: string;
  preventOwnOverlaps: boolean;
  showOverlap: boolean;
  overlapsRule: OverlapsRule;
}

export interface AbsenceYearSelectOption<T> {
  year: T;
  label: string;
  description?: string;
}

export type AbsenceCycleDataDto = Pick<AbsencePolicyDto, 'cycleStartDay' | 'cycleStartMonth'> & {
  currentCycle: [string, string];
  yearSelectOptions: AbsenceYearSelectOption<number>[];
};

export enum AbsencePolicyTenureAllocation {
  Anniversary = 'Anniversary',
  CycleStart = 'CycleStart',
  NextCycle = 'NextCycle',
}

export enum AbsencePolicyAttachmentType {
  Required = 'Required',
  Optional = 'Optional',
}
