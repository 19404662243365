import { useContext } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { BENEFITS_COMPANY_ROUTE, DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE } from '@/lib/routes';
import { CustomBenefitEmployeeList } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit-employee-list.page';
import { CustomBenefitDto } from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';

export const CustomBenefitCompanyRouter = ({
  loading,
  customBenefits,
}: {
  readonly loading: boolean;
  readonly customBenefits: CustomBenefitDto[] | null;
}) => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(state.user);
  return (
    <Switch>
      <RouteScopesControl
        scopes={['insurance:all']}
        context={scopesContext}
        path={DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE}
        exact
      >
        <CustomBenefitEmployeeList loading={loading} customBenefits={customBenefits} />
      </RouteScopesControl>

      <Route path={BENEFITS_COMPANY_ROUTE}>
        <Redirect to={BENEFITS_COMPANY_ROUTE} />
      </Route>
    </Switch>
  );
};
