import { OptionObject } from '@v2/components/forms/select.component';
import { DeviceOwnership, DeviceType } from '@v2/feature/device/device.interface';
import { DeviceDomainReportFilters } from '@v2/feature/reports/reports.interface';
import { capitalize } from '@v2/feature/reports/util/report.util';
import Polyglot from 'node-polyglot';

export const DevicesReportColumnsOptions = (polyglot: Polyglot) => {
  return [
    { label: polyglot.t('DevicesReportColumnsOptions.owner'), value: 'owner' },
    { label: polyglot.t('DevicesReportColumnsOptions.type'), value: 'type' },
    { label: polyglot.t('DevicesReportColumnsOptions.manufacturer'), value: 'manufacturer' },
    { label: polyglot.t('DevicesReportColumnsOptions.modelName'), value: 'modelName' },
    { label: polyglot.t('DevicesReportColumnsOptions.modelNumber'), value: 'modelNumber' },
    { label: polyglot.t('DevicesReportColumnsOptions.serialNumber'), value: 'serialNumber' },
    { label: polyglot.t('DevicesReportColumnsOptions.platform'), value: 'platform' },
    { label: polyglot.t('DevicesReportColumnsOptions.ram'), value: 'ram' },
    { label: polyglot.t('DevicesReportColumnsOptions.storage'), value: 'storage' },
    { label: polyglot.t('DevicesReportColumnsOptions.cpu'), value: 'cpu' },
    { label: polyglot.t('DevicesReportColumnsOptions.gpu'), value: 'gpu' },
    { label: polyglot.t('DevicesReportColumnsOptions.screenSize'), value: 'screenSize' },
    { label: polyglot.t('DevicesReportColumnsOptions.ownership'), value: 'ownership' },
  ];
};
export function getDeviceReportFiltersOptions(
  devicesFiltersOptions: Record<string, string[]> | null | undefined,
  polyglot: Polyglot
) {
  const typeOptions: OptionObject[] = [
    { label: polyglot.t('getDeviceReportFiltersOptions.laptop'), value: DeviceType.Laptop },
    { label: polyglot.t('getDeviceReportFiltersOptions.phone'), value: DeviceType.Phone },
    { label: polyglot.t('getDeviceReportFiltersOptions.tablet'), value: DeviceType.Tablet },
    { label: polyglot.t('getDeviceReportFiltersOptions.monitor'), value: DeviceType.Monitor },
    { label: polyglot.t('getDeviceReportFiltersOptions.headset'), value: DeviceType.Headset },
    { label: polyglot.t('getDeviceReportFiltersOptions.mouse'), value: DeviceType.Mouse },
    { label: polyglot.t('getDeviceReportFiltersOptions.keyboard'), value: DeviceType.Keyboard },
    { label: polyglot.t('getDeviceReportFiltersOptions.other'), value: DeviceType.Other },
  ];
  const ownershipOptions = (polyglot: Polyglot): OptionObject[] => {
    return [
      { label: polyglot.t('ownershipOptions.company'), value: DeviceOwnership.Company },
      { label: polyglot.t('ownershipOptions.purchase'), value: DeviceOwnership.CompanyPurchase },
      { label: polyglot.t('ownershipOptions.rental'), value: DeviceOwnership.Rental },
    ];
  };
  const platformOptions =
    devicesFiltersOptions && devicesFiltersOptions['platform']
      ? devicesFiltersOptions['platform'].map((o) => ({
          label: o === 'macos' ? 'macOS' : o === 'ipados' ? 'iPadOS' : capitalize(o),
          value: o,
        }))
      : null;
  const modelOptions =
    devicesFiltersOptions && devicesFiltersOptions['modelName']
      ? devicesFiltersOptions['modelName'].map((o) => ({
          label: o,
          value: o,
        }))
      : null;

  let filters = {};
  const FILTERS = DeviceDomainReportFilters(
    typeOptions,
    ownershipOptions(polyglot),
    platformOptions,
    modelOptions,
    polyglot
  );
  for (const filterObj of FILTERS) {
    filters = { ...filters, [`${filterObj.domain}-${filterObj.field}-${filterObj.name}`]: filterObj.options };
  }
  return filters;
}
