import React from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface RemoveConfirmDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly drawerTitle: string;
  readonly confirmationMessage: string;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
}

export const GeneralisedRemoveConfirmDrawer = ({
  isOpen,
  setIsOpen,
  drawerTitle,
  confirmationMessage,
  onClose,
  onConfirm,
}: RemoveConfirmDrawerProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{drawerTitle}</Typography>

        <Typography variant="caption">{confirmationMessage}</Typography>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => onClose()} fullWidth>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <ButtonComponent
            fullWidth
            onClick={() => {
              onConfirm();
              onClose();
            }}
            sizeVariant="medium"
            colorVariant="primary"
          >
            {polyglot.t('General.yes')}
          </ButtonComponent>
        </Box>
      </Box>
    </DrawerModal>
  );
};
