import { differenceInCalendarDays, getDaysInMonth, lastDayOfMonth } from 'date-fns';

import { fixedNumber } from '@/v2/util/number.util';

export function getNoOfActiveDays(startDate: string | Date, endDate: string | Date): number {
  // We want to make the days inclusive. Sep 30 - Sep 1 gives 29. But we want 30.
  return differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1;
}

export function ShortDateString(date?: Date | number | string): string {
  // if no date specified, return today's date
  if (!date) return new Date().toISOString().slice(0, 10);

  return new Date(date).toISOString().slice(0, 10);
}

export function getNoOfActiveDaysForBilling(startDate?: string): number {
  const today = ShortDateString();
  const referenceDate = new Date(startDate || today);
  return getNoOfActiveDays(referenceDate, lastDayOfMonth(referenceDate));
}

export function getBillToEndOfMonth(price: number, inPence: boolean, startDate?: string): number {
  const today = ShortDateString();
  const referenceDate = new Date(startDate || today);
  const noOfDaysInMonth = getDaysInMonth(referenceDate);

  const noOfActiveDays = getNoOfActiveDaysForBilling(startDate);
  const amountInPounds = fixedNumber((noOfActiveDays / noOfDaysInMonth) * price, 2);
  return inPence ? fixedNumber(amountInPounds * 100, 0) : amountInPounds;
}
