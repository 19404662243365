import { Route, Switch } from 'react-router-dom';

import { TIME_COMPANY_CALENDAR_ROUTE } from '@/lib/routes';
import { CalendarPage } from '@/v2/feature/calendar/features/calendar.page';

export const CalendarCompanyRouter = (): JSX.Element => (
  <Switch>
    <Route path={[TIME_COMPANY_CALENDAR_ROUTE]}>
      <CalendarPage view="company" />
    </Route>
  </Switch>
);
