import React from 'react';

import { Box, Theme, SxProps } from '@mui/material';

import { StepperComponent } from '@/v2/components/stepper.component';

export const PayrunStepperSteps = ['Salaries', 'Review', 'Process', 'Complete'] as const;

export type PayrunStepperStep = typeof PayrunStepperSteps[number];

type PayrunStepperProps = {
  activeStep: PayrunStepperStep;
  sx?: SxProps<Theme>;
};

export const PayrunStepper = ({ activeStep, sx }: PayrunStepperProps) => {
  return (
    <Box sx={sx}>
      <StepperComponent steps={PayrunStepperSteps} activeStep={activeStep} />
    </Box>
  );
};
