import { PAYROLL_COMPANY_OVERVIEW_ROUTE, PAYROLL_ME_SUMMARY_ROUTE } from '@/lib/routes';
import { Scope } from '@/models/scopes.model';
import { RoleRoutes, RoleScopes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const MONEY_SALARY_PAYROLL_HUB_ROUTES: RoleRoutes = {
  company: PAYROLL_COMPANY_OVERVIEW_ROUTE,
};

export const MONEY_SALARY_SUMMARY_ROUTES: RoleRoutes = {
  me: PAYROLL_ME_SUMMARY_ROUTE,
};

export const MONEY_SALARY_SCOPES: RoleScopes = {
  company: ['payroll:all'] as Scope[],
  me: ['payroll:read'] as Scope[],
};
