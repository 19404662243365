import { useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import { GrowthFactor } from '@v2/feature/growth/growth-factor/growth-factor.interface';
import { SurveyQuestionsBiggestImpact } from '@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-insights/survey-questions-biggest-impact.component';
import { Dictionary } from 'lodash';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as Status } from '@/images/reviews/Status.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { ChartPolarArea } from '@/v2/components/charts/chart-polar-area.component';
import { ChartProgress } from '@/v2/components/charts/chart-progress.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { GridDisplayBarV2 } from '@/v2/feature/growth/shared/components/grid-display-bar.component';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { impactExportReport } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail.util';
import { TitleStatusComponent } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import {
  SurveyCycle,
  SurveyCycleResult,
  SurveyInsights,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { captureExcel, capturePNG, capturePdf } from '@/v2/util/export-reports.util';

export const SurveyCycleDetailInsightsPage = ({
  growthFactors,
  surveyCycle,
  cycleLoading,
  surveyResult,
  reach,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
}: {
  readonly surveyCycle: SurveyCycle;
  readonly cycleLoading: boolean | undefined;
  readonly growthFactors: Dictionary<GrowthFactor>;
  readonly surveyResult: SurveyCycleResult | undefined | null;
  readonly reach: ReachType;
  readonly filtersAndTypesOptions: any;
  readonly setFilterString: React.Dispatch<React.SetStateAction<string>>;
  readonly filterString: string;
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = user?.company?.name ?? 'Company';
  const { data: surveyInsights, isLoading: loadingInsights } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleInsights(surveyCycle.id, reach),
    {
      suspense: false,
    }
  );

  const reportName = useMemo(() => `${surveyCycle.internalName}-insights`, [surveyCycle.internalName]);

  if (!surveyCycle || !surveyInsights) return <></>;

  const generateExcel = () => {
    const { participation, surveyScore, factorScore } = surveyInsights;
    const data = [];

    const addBoldHeader = (text: string) => {
      data.push([{ v: text, s: { font: { bold: true } } }]);
    };

    addBoldHeader('Participation rate');
    data.push(['Percentage of invited participants that have responded.']);
    data.push([
      'Participation',
      participation.average.toFixed(2),
      `(${participation.totalParticipants} out of ${participation.totalEntries})`,
    ]);
    data.push([]);

    addBoldHeader('Survey score');
    data.push(['Normalised score across the survey. This score is based only on survey questions']);
    data.push(['Survey score', (surveyScore.score / 100).toFixed(2)]);
    data.push([]);

    addBoldHeader('Factor score');
    data.push(['Normalised score by each factor used in the survey.']);
    data.push(
      ...factorScore.categories.map((category, index) => [category, (factorScore.series[index] / 100).toFixed(2)])
    );
    data.push([]);

    const impactData = impactExportReport(surveyResult, company_name);
    data.push(...impactData);
    captureExcel(data, reportName);
  };

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent surveyCycle={surveyCycle} />}
      noHorizontalPadding={false}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      headerWidth="100%"
      loading={Boolean(loadingInsights) || cycleLoading}
      buttons={[
        <StyledMenuComponent
          options={[
            {
              handler: generateExcel,
              label: 'Excel report',
            },
            {
              handler: () => capturePdf(reportName),
              label: 'PDF report',
            },
          ]}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Export',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />,
      ]}
    >
      <SettingsSubsectionContent
        id={reportName}
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: <SubsectionTitle title="Insights" subtitle={`Key findings from ${surveyCycle.displayName}`} />,
            items: [
              {
                type: SectionItemType.Component,
                value: <CycleInsights surveyInsights={surveyInsights} />,
              },
            ],
          },

          {
            contentWidth: '100%',
            headerWidth: '100%',
            hidden: !surveyResult,
            title: (
              <SubsectionTitle
                title="Biggest impact"
                subtitle="Questions that had the most meaningful impact"
                tooltipText="Impact is suggested based on a correlation analysis of survey answers. This analysis determines
                        if there is a relationship between variables, which can help you spot the most impactful topics
                        for your team."
              />
            ),
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <SurveyQuestionsBiggestImpact
                    surveyResult={surveyResult}
                    growthFactors={growthFactors}
                    filtersAndTypesOptions={filtersAndTypesOptions || []}
                    setFilterString={setFilterString}
                    filterString={filterString}
                    surveyCycle={surveyCycle}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </SettingsSectionContent>
  );
};

const CycleInsights = ({ surveyInsights }: { surveyInsights: SurveyInsights }) => {
  return (
    <GridDisplayBarV2
      sx={{ borderBottom: 'none' }}
      cells={[
        {
          content: (
            <ChartDisplay
              title="Participation rate"
              subtitle="Percentage of invited participants that have responded."
              chart={
                <ChartProgress
                  progress={surveyInsights.participation.average}
                  title={`${surveyInsights.participation.average}%`}
                  color="blue"
                  size={200}
                  thickness={2}
                />
              }
              hasDownload={true}
              caption={`Responded: ${surveyInsights.participation.totalEntries}/${surveyInsights.participation.totalParticipants} participants`}
            />
          ),
          gridXs: 3,
        },
        {
          content: (
            <ChartDisplay
              title="Survey score"
              subtitle="Normalised score across the survey."
              hasTooltip={true}
              tooltipText={
                <Typography variant="caption" color="white">
                  This score is based only on scale questions and has been normalised to percentages rather than actual
                  values. This is to avoid skewing results if different scales are mixed in the same survey (For
                  example, Agreement and NPS).
                </Typography>
              }
              chart={
                <ChartProgress
                  progress={surveyInsights.surveyScore.score}
                  title={`${surveyInsights.surveyScore.score}%`}
                  color="blue"
                  size={200}
                  thickness={2}
                  noData={surveyInsights.surveyScore.answersLowerThanThreshold}
                  noDataTitle="Not sufficient data to show results."
                />
              }
              hasDownload={true}
            />
          ),
          gridXs: 3,
        },
        {
          content: (
            <ChartDisplay
              title="Factor score"
              subtitle="Normalised score by each factor used in the survey."
              chart={
                <ChartPolarArea
                  series={surveyInsights.factorScore.series}
                  categories={surveyInsights.factorScore.categories}
                  noData={surveyInsights.surveyScore.answersLowerThanThreshold}
                  noDataTitle="Not sufficient data to show results."
                />
              }
              hasDownload={true}
            />
          ),
          gridXs: 6,
        },
      ]}
    />
  );
};

const ChartDisplay = ({
  title,
  subtitle,
  chart,
  caption,
  hasTooltip = false,
  tooltipText,
  hasDownload,
}: {
  title: string;
  subtitle: string;
  chart: JSX.Element;
  caption?: string;
  hasTooltip?: boolean;
  tooltipText?: string | JSX.Element;
  hasDownload?: boolean;
}) => {
  return (
    <Box
      id={`download-chart-${title}`}
      sx={{
        padding: spacing.p16,
        border: borders.background,
        background: themeColors.white,
        borderRadius: radius.br8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: spacing.g32,
      }}
    >
      <Box sx={{ height: '80px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="title4">{title}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }} className="hide-for-capture">
            {hasTooltip && (
              <StyledTooltip title={tooltipText} placement="right">
                <Status {...iconSize} />
              </StyledTooltip>
            )}
            {hasDownload && (
              <div
                onClick={() => capturePNG(`download-chart-${title}`)}
                aria-label="Download chart as PNG"
                role="button"
                style={{ cursor: 'pointer' }}
              >
                <Download {...iconSize} />
              </div>
            )}
          </Box>
        </Box>
        <Typography variant="caption" color="Grey">
          {subtitle}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '220px', width: '100%' }}>{chart}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', height: '20px', width: '100%' }}>
        {caption && (
          <Typography variant="caption" color="Grey">
            {caption}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const SubsectionTitle = ({
  title,
  tooltipText,
  subtitle,
}: {
  title: string;
  subtitle: string;
  tooltipText?: string;
}) => (
  <Box>
    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <Typography variant="title3">{title}</Typography>
      {tooltipText && (
        <StyledTooltip
          title={
            <Typography variant="caption" color="white">
              {tooltipText}
            </Typography>
          }
          placement="right"
        >
          <Status {...iconSize} />
        </StyledTooltip>
      )}
    </Box>
    <Typography variant="caption" color="Grey">
      {subtitle}
    </Typography>
  </Box>
);
