import { useMemo } from 'react';

import { Box } from '@mui/material';
import { getRegularTimeFromScheduleData } from '@v2/feature/absence/absence.util';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

function formatDate(dateStr: Date): string {
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long' };
  return dateStr.toLocaleDateString(undefined, options);
}

export const CalendarAttendanceContentDrawer = ({
  userId,
  selectedDate,
  schedule,
}: {
  userId: number;
  selectedDate: Date;
  schedule: {
    id: number;
    name: string;
    startHour: string | undefined;
    endHour: string | undefined;
  };
}) => {
  const { polyglot } = usePolyglot();

  const regularTime = useMemo(() => getRegularTimeFromScheduleData(schedule.startHour, schedule.endHour, polyglot), [
    schedule,
    polyglot,
  ]);

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{formatDate(selectedDate)}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        <Typography variant="caption" color="Grey">
          {polyglot.t('General.employee')}
        </Typography>
        <UserCell userId={userId} avatarSize="xxxsmall" nameSx={themeFonts.caption} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
        <Typography variant="caption" color="Grey">
          {polyglot.t('AttendanceModule.attendanceSchedule')}
        </Typography>
        <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
          {schedule.name}
        </Typography>
      </Box>

      {regularTime && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
          <Typography variant="caption" color="Grey">
            {polyglot.t('AttendanceModule.regular')}
          </Typography>
          <Typography variant="caption" color="DarkGrey">
            {regularTime}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
