import { CurrentUser } from '@/models';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { InstalledAppDto } from '@/v2/feature/app-integration/app-integration.dto';
import { AppBasicAuthConnectForm } from '@/v2/feature/app-integration/features/app-details/components/app-basic-auth-connect-form.component';

interface Props {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
  readonly currentUser: CurrentUser;
  appDetails: InstalledAppDto | undefined;
}

export const AppBasicAuthConnectDrawer = ({ isOpen, setIsOpen, currentUser, appDetails }: Props): JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <AppBasicAuthConnectForm currentUser={currentUser} appDetails={appDetails} />
    </DrawerModal>
  );
};
