import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Box, FormControl } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { LocalDate } from '@v2/util/local-date';
import { Job } from 'bull';
import { isToday } from 'date-fns';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import {
  OptionsProps,
  SingleUserSelect,
  UserIdOption,
} from '@/v2/components/forms/user-select/single-user-select.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { AppIntegrationAPI } from '@/v2/feature/app-integration/app-integration.api';
import {
  AppIntegrationUserDto,
  AppIntegrationUserEmailDto,
  UserAppDto,
} from '@/v2/feature/app-integration/app-integration.dto';
import { appStubToName } from '@/v2/feature/app-integration/app-integration.interface';
import {
  APPS_NEEDING_EMAIL_FOR_DELETION,
  getEmailAddressForMissingId,
  hasActiveEmailForAccount,
} from '@/v2/feature/app-integration/features/app-details/app-details.util';
import { CreateAppQueue } from '@/v2/feature/monitoring/monitoring.interface';
import { getActionDate, userStatusIs } from '@/v2/feature/offboarding/offboarding.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { HOUR_OF_THE_DAY_OPTIONS } from '@/v2/feature/user/features/user-notifications/user-notifications.util';
import { drawerContentSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { UserProfileActionAPI } from '@/v2/feature/user/features/user-profile-action/user-profile-action.api';
import { spacing } from '@/v2/styles/spacing.styles';

const SHORT_DATE_FORMAT = 'YYYY-MM-DD';

export const DeactivationSchema = Yup.object().shape({
  deactivationDate: Yup.string().test(dateFieldTest).required('deactivation date is required'),
  transferDataTargetUser: Yup.string().notRequired().when('workspaceTransferThenDelete', {
    is: true,
    then: Yup.string().nullable().required(),
  }),
});

interface AppDeactivationInterface {
  readonly transferDataSourceUser?: number | string;
  transferDataTargetUserOptionObj?: UserIdOption;
  readonly transferDataTargetUser?: number;
  deactivationDate: string;
  cronHour?: number;
}

export const DeactivateAppsDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  userApp,
  userLeaveDate,
  onClose,
  refresh,
  existingDelayedAction,
  existingDeactivationDate,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  userApp: UserAppDto;
  userLeaveDate: string | null;
  onClose: () => void;
  refresh: () => Promise<void>;
  existingDelayedAction?: Job<CreateAppQueue> | undefined;
  existingDeactivationDate?: string | undefined;
}) => {
  const { polyglot } = usePolyglot();
  const DateTabFilter = [
    { name: polyglot.t('DeactivateAppsDrawer.today'), value: 'today' },
    { name: polyglot.t('DeactivateAppsDrawer.lastDay'), value: 'last-day' },
  ];
  const appStub = userApp.stub;
  const [users, setUsers] = useState<readonly AppIntegrationUserDto[]>([]);
  const [dateFilterValue, setDateFilterValue] = useState<string>('today');
  const [typeFilterValue, setTypeFilterValue] = useState<string>('suspend');
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const TypeTabFilter = [
    ...(userApp.allowsSuspend && !userStatusIs(userApp.userStatus, ['Suspended', 'No access'])
      ? [{ name: polyglot.t('DeactivateAppsDrawer.suspend'), value: 'suspend' }]
      : []),
    ...(userApp.allowsDelete && !userStatusIs(userApp.userStatus, ['Scheduled', 'Deleted', 'No access'])
      ? [{ name: polyglot.t('DeactivateAppsDrawer.delete'), value: 'delete' }]
      : []),
  ];

  const activeTeamMembers = (teamList: readonly AppIntegrationUserDto[]): OptionsProps[] => {
    return nonTerminatedCachedUsers
      .filter((u) =>
        teamList.some(
          (eachUser) =>
            (eachUser?.emails?.some((eachEmail) => ['Active', 'Invited'].includes(eachEmail.status)) ||
              eachUser.userStatus === 'Active') &&
            eachUser.userId !== userId &&
            u.userId === eachUser.userId
        )
      )
      .map((u) => {
        return { label: u.displayName, value: u.userId, ...u };
      }) as OptionsProps[];
  };

  useEffect(() => {
    const today = new Date();
    if (existingDelayedAction && existingDelayedAction.data.deactivationDate) {
      const deactivationDate = new Date(existingDelayedAction.data.deactivationDate);

      if (deactivationDate > today) {
        setDateFilterValue('last-day');
      }
    } else if (existingDeactivationDate) {
      const deactivationDate = new Date(existingDeactivationDate);

      if (deactivationDate > today) {
        setDateFilterValue('last-day');
      }
    }
  }, [existingDeactivationDate, existingDelayedAction]);

  const getTeamUsers = useCallback(async () => {
    try {
      if (appStub !== 'zelt') {
        const { users } = await AppIntegrationAPI.listAppUsers(appStub);
        setUsers(users);
      }
    } catch (error) {
      showMessage(
        polyglot.t('DeactivateAppsDrawer.errorMessages.list', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  }, [appStub, showMessage, polyglot]);

  useEffect(() => {
    getTeamUsers();
  }, [getTeamUsers]);

  const unassignUser = async ({ emailObj }: { emailObj: AppIntegrationUserEmailDto }): Promise<void> => {
    try {
      await AppIntegrationAPI.unassignUser(appStub, (userId as number) ?? 0, emailObj.email);
      showMessage('Account unassigned successfully', 'success');
    } catch (error: any) {
      showMessage(
        polyglot.t('DeactivateAppsDrawer.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const getAppUserId = (appUserId: string | number, user: AppIntegrationUserDto) => {
    return String(appUserId) === String(userId) &&
      APPS_NEEDING_EMAIL_FOR_DELETION.includes(appStub) &&
      user &&
      user.primaryEmail
      ? user.primaryEmail
      : String(appUserId);
  };

  async function doDeleteUser(user: AppIntegrationUserDto, values: AppDeactivationInterface): Promise<void> {
    try {
      if (user.isAdmin) {
        showMessage(polyglot.t('DeactivateAppsDrawer.errorMessages.deleteAdminNotAllowed'), 'error');

        return;
      }
      let appUserId = undefined;
      if (appStub === 'github') {
        //if github user is already assigned but deletion is required, we need to unassign first, then delete user
        const githubEmail = hasActiveEmailForAccount(user);
        if (githubEmail) await unassignUser({ emailObj: githubEmail });
        appUserId = githubEmail?.email;
      }
      if (!appUserId) appUserId = user.login || user.id || getEmailAddressForMissingId(user);
      if (appUserId && userId)
        await AppIntegrationAPI.deleteAppUser(
          appStub,
          getAppUserId(appUserId, user),
          Number(userId),
          getActionDate(values.deactivationDate)
        );
      showMessage(
        polyglot.t('DeactivateAppsDrawer.successMessages.deletion', {
          type:
            !values.deactivationDate || isToday(new Date(values.deactivationDate))
              ? polyglot.t('DeactivateAppsDrawer.initiated')
              : polyglot.t('DeactivateAppsDrawer.scheduled'),
        }),
        'success'
      );
    } catch (error: any) {
      if (error?.response?.data?.error === 'Higher tier needed.') {
        showMessage(polyglot.t('DeactivateAppsDrawer.errorMessages.manageSlack'), 'error');
      } else if (error?.response?.data?.error?.includes('Super Admin')) {
        showMessage(polyglot.t('DeactivateAppsDrawer.errorMessages.userSuperAdmin'), 'error');
      } else {
        showMessage(
          polyglot.t('DeactivateAppsDrawer.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    }
  }

  async function doSuspendUser(
    user: AppIntegrationUserDto,
    values: AppDeactivationInterface,
    zeltUserId: number
  ): Promise<void> {
    try {
      const actionDate = getActionDate(new Date(values.deactivationDate));
      await AppIntegrationAPI.suspendAppUser(appStub, String(user?.id), zeltUserId ?? 0, actionDate, values.cronHour);
      const appName = appStub === 'zelt' ? 'Zelt' : appStubToName[appStub];
      showMessage(
        actionDate
          ? polyglot.t('DeactivateAppsDrawer.successMessages.suspensionScheduled', { actionDate, appName })
          : polyglot.t('DeactivateAppsDrawer.successMessages.suspensionInitiated', { appName }),
        'success'
      );
    } catch (error: any) {
      showMessage(
        polyglot.t('DeactivateAppsDrawer.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  }

  async function doTransferDataAndDeleteUser(
    user: AppIntegrationUserDto,
    values: AppDeactivationInterface
  ): Promise<void> {
    try {
      await AppIntegrationAPI.dataTransferThenDeleteForAppUser(
        appStub,
        userId ?? 0,
        String(user.id),
        String(values.transferDataTargetUser),
        values.deactivationDate
      );
      showMessage(polyglot.t('DeactivateAppsDrawer.successMessages.dataTransferIntiated'), 'success');
    } catch (error: any) {
      showMessage(
        polyglot.t('DeactivateAppsDrawer.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  }

  const handleGoogleWorkspace = async (user: AppIntegrationUserDto, values: AppDeactivationInterface) => {
    if (typeFilterValue === 'delete') {
      if (values.transferDataTargetUser) await doTransferDataAndDeleteUser(user, values);
      else await doDeleteUser(user, values);
    } else {
      await doSuspendUser(user, values, userId);
    }
  };

  const handleZeltDeactivation = async (values: AppDeactivationInterface) => {
    try {
      const today = new Date();
      const deactivationDate = new Date(values.deactivationDate);
      const scheduledForLater = deactivationDate > today;
      await UserProfileActionAPI.deactivateUser(userId, { deactivationDate: values.deactivationDate });
      showMessage(
        scheduledForLater
          ? polyglot.t('DeactivateAppsDrawer.successMessages.deactivationScheduled', {
              deactivationDate: values.deactivationDate,
            })
          : polyglot.t('DeactivateAppsDrawer.successMessages.deactivate'),
        'success'
      );
    } catch (error) {
      showMessage(polyglot.t('DeactivateAppsDrawer.errorMessages.deactivate'), 'error');
    }
  };

  const formik = useFormik<AppDeactivationInterface>({
    initialValues: {
      cronHour:
        existingDelayedAction && existingDelayedAction?.data?.cronHour ? existingDelayedAction?.data?.cronHour : 0,
      deactivationDate: existingDeactivationDate
        ? new LocalDate(existingDeactivationDate).toDateString()
        : existingDelayedAction && existingDelayedAction?.data?.deactivationDate
        ? new LocalDate(existingDelayedAction?.data?.deactivationDate).toDateString()
        : new LocalDate().toDateString(),
      transferDataTargetUser: undefined,
      transferDataTargetUserOptionObj: undefined,
    },
    enableReinitialize: false,
    validationSchema: DeactivationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (userApp) {
          if (appStub === 'zelt') await handleZeltDeactivation(values);
          else if (appStub === 'google-workspace') await handleGoogleWorkspace(userApp, values);
          else {
            if (typeFilterValue === 'delete') await doDeleteUser(userApp, values);
            else await doSuspendUser(userApp, values, userId);
          }
          await refresh();
        }
      } catch (error) {
        showMessage(polyglot.t('DeactivateAppsDrawer.errorMessages.tryAgain'), 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCronHourChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('cronHour', (event.target as HTMLInputElement)?.value);
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
          <Typography variant="title2">
            {polyglot.t('DeactivateAppsDrawer.deactivationDate')} -{' '}
            {appStub === 'zelt' ? 'Zelt' : appStubToName[appStub]}
          </Typography>
          <Typography variant="caption">
            {appStub === 'zelt'
              ? polyglot.t('DeactivateAppsDrawer.dateDescWithoutHour')
              : polyglot.t('DeactivateAppsDrawer.dateDesc', {
                  hourOfDeactivation: `${formik.values.cronHour?.toString().padStart(2, '0')}:00`,
                })}
          </Typography>

          <Box sx={{ mt: spacing.m10 }}>
            <TabFilterButtons
              filters={DateTabFilter}
              setFilterValue={setDateFilterValue}
              filterValue={dateFilterValue}
              onFilterChange={({ filterValue }) => {
                if (filterValue === 'today') {
                  const dateValue = dayjs().format(SHORT_DATE_FORMAT);
                  formik.setFieldValue('deactivationDate', dateValue);
                }

                if (filterValue === 'last-day' && userLeaveDate) {
                  const dateValue = dayjs(userLeaveDate).format(SHORT_DATE_FORMAT);
                  formik.setFieldValue('deactivationDate', dateValue);
                }
              }}
            />
          </Box>

          <FormControl size="small" fullWidth>
            <DatePickerComponent
              inputFormat="DD/MM/YYYY"
              value={formik.values.deactivationDate}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  formik.setFieldValue('deactivationDate', value);
                }
              }}
              name="deactivationDate"
              label={polyglot.t('DeactivateAppsDrawer.deactivationDate')}
              error={Boolean(!!formik.errors.deactivationDate && formik.touched.deactivationDate)}
              helperText={(formik.touched.deactivationDate && formik.errors.deactivationDate) as string}
            />
          </FormControl>

          {appStub !== 'zelt' && dateFilterValue !== 'today' && (
            <FormControl size="small" fullWidth>
              <Typography variant="caption">{polyglot.t('DeactivateAppsDrawer.hourToDeactivate')}</Typography>
              <SelectComponent
                name="cronHour"
                label=""
                options={HOUR_OF_THE_DAY_OPTIONS}
                value={formik.values.cronHour}
                compareValue={formik.values.cronHour}
                error={undefined}
                onChange={handleCronHourChange}
                helperText={undefined}
                fullWidth
              />
            </FormControl>
          )}

          {appStub !== 'zelt' && (
            <Box>
              <TabFilterButtons
                filters={TypeTabFilter}
                setFilterValue={setTypeFilterValue}
                filterValue={typeFilterValue}
                onFilterChange={() => {
                  formik.setFieldValue('transferDataTargetUserOptionObj', undefined, true);
                  formik.setFieldValue('transferDataTargetUser', undefined, true);
                }}
              />

              {appStub === 'google-workspace' && typeFilterValue === 'delete' && (
                <Box sx={fieldSx}>
                  <SingleUserSelect
                    name="transferDataTargetUser"
                    options={activeTeamMembers(users)}
                    onChange={(_, x) => {
                      const data = x as { value: number | undefined } | undefined;
                      const foundAppTargetUser =
                        users && users.length > 0 && data && data.value
                          ? users.find((u) => u.userId === data.value)
                          : null;

                      formik.setFieldValue('transferDataTargetUserOptionObj', data, true);
                      formik.setFieldValue('transferDataTargetUser', foundAppTargetUser?.id ?? null, true);
                      formik.setFieldTouched('transferDataTargetUser', true, true);
                    }}
                    value={formik.values.transferDataTargetUserOptionObj?.value}
                    label={polyglot.t('DeactivateAppsDrawer.migrateTo')}
                    error={formik.touched.transferDataTargetUser && Boolean(formik.errors.transferDataTargetUser)}
                    helperText={formik.touched.transferDataTargetUser && formik.errors.transferDataTargetUser}
                  />
                </Box>
              )}
            </Box>
          )}

          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent colorVariant="secondary" sizeVariant="medium" fullWidth={true} onClick={() => onClose()}>
              {polyglot.t('General.cancel')}
            </ButtonComponent>
            <LoaderButton
              name={polyglot.t('General.save')}
              loading={loading}
              fullWidth
              colorVariant="primary"
              sizeVariant="medium"
            />
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
