import { useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Trophy } from '@/images/dashboard-icons/Trophy.svg';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { SurveyActionItem } from '@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/survey-action-item.component';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { PendingSurveysBigWidget, SurveysTodos } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const PendingSurveysTodo = ({
  todo,
  setLastChild,
}: {
  todo: PendingSurveysBigWidget;
  setLastChild: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const [selectedRow, setSelectedRow] = useState<SurveysTodos | undefined>(undefined);

  const getTitle = (survey: SurveysTodos) => {
    return (
      <Typography
        variant="caption"
        sx={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        {polyglot.t('getDetailByDomain.completeSurvey', {
          cycleName: survey.cycleName,
          date: getDateString(survey.dueDate, false, 'd MMM yyyy'),
        })}
      </Typography>
    );
  };

  return (
    <Box>
      {todo.surveys.map((a, idx) => {
        return (
          <TodoCard
            key={`-${idx}-${a.id}`}
            icon={<Trophy {...iconSize} />}
            title={getTitle(a)}
            actionOnclick={() => setSelectedRow(a)}
            showBorder={!(setLastChild && todo.surveys.length === idx + 1)}
            userAvatar={<UserAvatar userId={a.userId} size="xxxsmall" />}
          />
        );
      })}

      {selectedRow && (
        <SurveyActionItem surveyActionRow={selectedRow as SurveysTodos} afterClose={() => setSelectedRow(undefined)} />
      )}
    </Box>
  );
};
