import { useMemo } from 'react';

import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { StyledTextfield } from '@v2/styles/textfield.styles';

interface TimePickerProps {
  readonly label?: string | undefined;
  readonly value: string | null | undefined;
  readonly onChange: StandardInputProps['onChange'];
  readonly textFieldKey?: string | number;
  readonly fullWidth?: boolean;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly helperText?: string;
}

export const TimePickerComponent = ({
  label,
  value,
  onChange,
  textFieldKey = 'time',
  fullWidth = false,
  disabled = false,
  error = undefined,
  helperText = undefined,
}: TimePickerProps) => {
  const adjustedValue = useMemo(() => {
    if (!value) return '';
    if (value.includes('T')) return value.split('T')[1].slice(0, 5);

    return value;
  }, [value]);

  return (
    <StyledTextfield
      key={textFieldKey}
      id="time"
      variant="standard"
      label={label}
      type="time"
      value={adjustedValue}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      sx={{ width: fullWidth ? '100%' : '55px' }}
      fullWidth
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};
