import React, { ReactNode, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SmallLoader } from '@v2/components/loader.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { TooltipPopper } from '@v2/components/theme-components/notification-tooltip-popper.component';
import { OptionProps, StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AddToPensionRetrospectivelyDrawer } from '@v2/feature/benefits/subfeature/pension/components/add-to-pension-retrospectively-drawer.component';
import { DeleteEmployeePensionModal } from '@v2/feature/benefits/subfeature/pension/components/delete-employee-pension-modal.component';
import { LeavePensionModal } from '@v2/feature/benefits/subfeature/pension/components/leave-pension-modal.component';
import { PensionAPI, PensionEndpoints } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PensionSchemeDto, UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  LeavingEmployeeStates,
  StaffologyEmployeeStates,
} from '@v2/feature/benefits/subfeature/pension/pension.interface';
import {
  getActionRequiredFromLastAssessment,
  getPensionLogoByProviderName,
  getPensionProviderFromUserPensionAndDefaultSchema,
  getWorkerGroupFromUserPensionAndDefaultSchema,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { EditPayrollRecordDrawer } from '@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component';
import { PayrollEndpoints } from '@v2/feature/payroll/payroll.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { secondaryTableSmallBtn } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import { dateAPItoDisplay } from '@v2/util/date-format.util';
import { LocalDate } from '@v2/util/local-date';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import Polyglot from 'node-polyglot';
import * as Yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Delete } from '@/images/side-bar-icons/Delete.svg';
import { ReactComponent as InfoCircleGray } from '@/images/side-bar-icons/InfoCircleGray.svg';
import { ReactComponent as Logout } from '@/images/side-bar-icons/Logout.svg';
import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  AssessmentStatusEligible,
  AssessmentStatusEntitled,
  AssessmentStatusNoDuties,
  AssessmentStatusNonEligible,
  ReliefAtSource,
  StatusToStateMapping,
} from '@/lib/pensions';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';

const ActionButtonName = (polyglot: Polyglot) => ({
  [AssessmentStatusEligible.value]: polyglot.t('PensionModule.enrol'),
  [AssessmentStatusNonEligible.value]: polyglot.t('PensionModule.optIn'),
  [AssessmentStatusEntitled.value]: polyglot.t('PensionModule.joinVoluntarily'),
  [AssessmentStatusNoDuties.value]: polyglot.t('PensionModule.joinVoluntarily'),
});

const PensionProviderReadOnlyLabel = ({
  userPension,
  pensionSchemes,
}: {
  readonly userPension: UserPensionDto;
  readonly pensionSchemes: readonly PensionSchemeDto[];
}) => {
  const { polyglot } = usePolyglot();
  const provider = userPension
    ? pensionSchemes.find((pensionScheme) => pensionScheme.externalId === userPension.pensionProviderId)
    : null;
  const pensionDisplayName = provider?.displayName;

  return (
    <Box>
      <Typography variant="caption">{polyglot.t('PensionModule.pensionProvider')}</Typography>
      {userPension?.pensionProviderName ? (
        <Box sx={{ display: 'flex', gap: spacing.gap5 }}>
          <Box>{getPensionLogoByProviderName(userPension.pensionProviderName, 20)}</Box>
          <Typography variant="title4">
            {PensionProvidersValueToLabel[userPension.pensionProviderName] ?? userPension.pensionProviderName}
          </Typography>
          {pensionDisplayName && <Typography variant="title4">({pensionDisplayName})</Typography>}
        </Box>
      ) : (
        <Typography variant="title4">N/A</Typography>
      )}
    </Box>
  );
};

interface ManageEmployeeMembershipDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userPension: UserPensionDto;
  readonly setUserPension: React.Dispatch<React.SetStateAction<UserPensionDto | null>>;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly onClose?: () => Promise<void> | void;
  readonly refresh: () => Promise<void>;
}

export const ManageEmployeeMembershipDrawer = ({
  isOpen,
  setIsOpen,
  userPension,
  setUserPension,
  pensionSchemes,
  onClose,
  refresh,
}: ManageEmployeeMembershipDrawerProps) => {
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState<boolean>(false);
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={async () => {
        if (onClose) onClose();
        if (shouldRefreshOnClose) await refresh();
      }}
    >
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <ManageEmployeeMembershipDrawerContent
          setIsOpen={setIsOpen}
          userPension={userPension}
          setUserPension={setUserPension}
          pensionSchemes={pensionSchemes}
          refreshData={refresh}
          setShouldRefreshOnClose={setShouldRefreshOnClose}
        />
      </Suspense>
    </DrawerModal>
  );
};

interface EnrolToPensionFormData {
  pensionProvider: string;
  workerGroupId: string;
  enrollmentDate: string | null;
  employerContribution: string;
  employeeContribution: string;
}

interface ManageEmployeeMembershipDrawerContentProps {
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userPension: UserPensionDto;
  readonly setUserPension: React.Dispatch<React.SetStateAction<UserPensionDto | null>>;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly refreshData: () => Promise<void>;
  readonly setShouldRefreshOnClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManageEmployeeMembershipDrawerContent = ({
  setIsOpen,
  userPension,
  setUserPension,
  pensionSchemes: allPensionSchemes,
  refreshData,
  setShouldRefreshOnClose,
}: ManageEmployeeMembershipDrawerContentProps) => {
  const { data: minimumDateAllowed } = useApiClient<string, Error>(
    PensionEndpoints.getMinimumEmployeePensionStartDate(userPension.userId, userPension.payrollId)
  );
  const { data: totalNoOfPayruns } = useApiClient<number, Error>(
    PayrollEndpoints.countPayrunsByPayrollId(userPension.payrollId)
  );
  const { data: userPayroll, mutate: refreshUserPayroll } = useApiClient(
    PayrollEndpoints.getActiveUserPayroll(userPension.userId)
  );

  const { polyglot } = usePolyglot();

  const minimumStartDateAllowed = useMemo(() => {
    let minDate = new LocalDate().toDateString();
    if (minimumDateAllowed) minDate = minimumDateAllowed;
    return minDate;
  }, [minimumDateAllowed]);

  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState<boolean>(false);
  const [payrollEditOpen, setPayrollEditOpen] = useState(false);
  const [isAddRetrospectivelyDrawerOpen, setIsAddRetrospectivelyDrawerOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [workerGroupOptions, setWorkerGroupOptions] = useState<{ value: string; label: string }[]>([]);
  const [availablePensionSchemes, setAvailablePensionSchemes] = useState<
    { value: string; label: string; icon: ReactNode }[]
  >([]);
  const pensionSchemes = useMemo(() => allPensionSchemes.filter((p) => p.payrollId === userPension.payrollId), [
    allPensionSchemes,
    userPension.payrollId,
  ]);

  const defaultPensionScheme = useMemo(() => pensionSchemes.find((pensionScheme) => pensionScheme.isDefault) ?? null, [
    pensionSchemes,
  ]);
  const defaultWorkerGroup = useMemo(() => {
    return defaultPensionScheme?.workerGroups
      ? defaultPensionScheme.workerGroups.find((wG) => wG.externalId === defaultPensionScheme.defaultWorkerGroup) ??
          null
      : null;
  }, [defaultPensionScheme]);

  const hasPensionAllScope = checkScopes(state.user, ['pension:all'], getScopesContext(state.user));

  const PensionActionsMenuStructure = (polyglot: Polyglot): OptionProps[] => [
    {
      label: polyglot.t('PensionModule.leavePension'),
      handler: () => {
        setIsLeaveModalOpen(true);
      },
      icon: <Logout {...iconSize} />,
      scopes: ['pension'],
      context: getScopesContext({ userId: state.user.userId }),
    },
    {
      label: polyglot.t('PensionModule.deleteRecord'),
      handler: () => {
        setIsDeleteModalOpen(true);
      },
      icon: <Delete {...iconSize} />,
      scopes: ['pension:all'],
    },
  ];

  const getWorkerGroupOptionsByProviderId = useCallback(
    (providerId: string) => {
      const provider = pensionSchemes.find((p) => p.externalId === providerId);
      return (
        provider?.workerGroups.map((wG) => ({
          label: wG.name,
          value: wG.externalId,
        })) ?? []
      );
    },
    [pensionSchemes]
  );

  const setPensionSchemesAndWorkerGroups = useCallback(() => {
    const availablePensionSchemes = pensionSchemes.map((pensionScheme) => {
      const providerLabel = PensionProvidersValueToLabel[pensionScheme.providerName] ?? pensionScheme.providerName;
      const label = pensionScheme.displayName ? `${providerLabel} (${pensionScheme.displayName})` : providerLabel;
      return {
        // TODO: should use local id instead of external id - but for this we should update defaultWorkerGroup to be the local id in DB
        value: pensionScheme.externalId,
        label,
        icon: getPensionLogoByProviderName(pensionScheme.providerName),
      };
    });
    setAvailablePensionSchemes(availablePensionSchemes);

    setWorkerGroupOptions(
      // if userProvider is set, get provider workerGroups
      userPension?.pensionProviderId && pensionSchemes.length > 0
        ? getWorkerGroupOptionsByProviderId(userPension.pensionProviderId)
        : // else if default provider found get default provider's worker groups
        defaultPensionScheme?.workerGroups
        ? getWorkerGroupOptionsByProviderId(defaultPensionScheme.externalId)
        : // else if userPension workerGroupId and workerGroupName are set, use them (probably this should never happen)
        userPension?.workerGroupId && userPension.workerGroupName
        ? [{ value: userPension.workerGroupId, label: userPension.workerGroupName }]
        : []
    );
  }, [
    pensionSchemes,
    userPension.pensionProviderId,
    userPension.workerGroupId,
    userPension.workerGroupName,
    getWorkerGroupOptionsByProviderId,
    defaultPensionScheme?.workerGroups,
    defaultPensionScheme?.externalId,
  ]);

  useEffect(() => {
    setPensionSchemesAndWorkerGroups();
  }, [setPensionSchemesAndWorkerGroups]);

  const enrollmentDate = useMemo(() => {
    return userPension.startDate ? userPension.startDate : minimumStartDateAllowed;
  }, [userPension, minimumStartDateAllowed]);

  const formik = useFormik<EnrolToPensionFormData>({
    initialValues: {
      // if updating -> use userPension data, if creating -> fallback on default scheme and worker group
      pensionProvider: getPensionProviderFromUserPensionAndDefaultSchema(userPension, defaultPensionScheme),
      workerGroupId: getWorkerGroupFromUserPensionAndDefaultSchema(userPension, defaultWorkerGroup),
      enrollmentDate: enrollmentDate,
      employerContribution: String(
        (userPension?.inPension ? userPension.employerContribution : defaultWorkerGroup?.employerContribution) ?? 3
      ),
      employeeContribution: String(
        (userPension?.inPension ? userPension.employeeContribution : defaultWorkerGroup?.employeeContribution) ?? 5
      ),
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      workerGroupId: Yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      enrollmentDate: Yup.string()
        .test(dateFieldTest)
        .test(
          'enrollmentDate',
          polyglot.t('PensionModule.enrollmentDateNonEarlierThan', { date: minimumStartDateAllowed }),
          (val) =>
            Boolean(
              val && (val >= minimumStartDateAllowed || (userPension?.startDate && val === userPension.startDate))
            )
        )
        .required(polyglot.t('ValidationMessages.requiredField')),
      employerContribution: Yup.number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      employeeContribution: Yup.number()
        .typeError(polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
    }),
    onSubmit: async (values: EnrolToPensionFormData) => upsertPension(values),
  });

  const upsertPension = useCallback(
    async (values: EnrolToPensionFormData) => {
      try {
        if (Number.parseFloat(values.employeeContribution) + Number.parseFloat(values.employerContribution) < 8) {
          showMessage(polyglot.t('PensionModule.contributionsMinimum8Percent'), 'error');
          return;
        }
        if (!userPension.lastAssessment?.status) {
          showMessage(polyglot.t('PensionModule.youShouldAssessEmployee'), 'error');
          return;
        }
        if (!values.enrollmentDate) {
          showMessage(polyglot.t('PensionModule.noEnrollmentDate'), 'error');
          return;
        }
        setLoading(true);

        const employerContribution = Number.parseFloat(values.employerContribution);
        const employeeContribution = Number.parseFloat(values.employeeContribution);

        if (!userPension?.inPension) {
          await PensionAPI.createEmployeePension(userPension.userId, {
            startDate: values.enrollmentDate,
            employeeState: StatusToStateMapping[userPension.lastAssessment?.status],
            aeStatusAtJoining: userPension.lastAssessment?.status,
            pensionId: values.pensionProvider,
            workerGroupId: values.workerGroupId,
            employeeContribution,
            employerContribution,
          });
        } else {
          await PensionAPI.updateEmployeePension(userPension.userId, {
            startDate: values.enrollmentDate,
            employeeContribution,
            employerContribution,
            workerGroupId: values.workerGroupId,
          });
        }
        showMessage(
          !!userPension?.inPension
            ? polyglot.t('PensionModule.updatedSuccess')
            : polyglot.t('PensionModule.enrolledSuccess'),
          'success'
        );
        await refreshData();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      } finally {
        setLoading(false);
      }
    },
    [
      polyglot,
      refreshData,
      setIsOpen,
      showMessage,
      userPension?.inPension,
      userPension.lastAssessment?.status,
      userPension.userId,
    ]
  );

  const assessEmployeeForAutoEnrolment = useCallback(
    async (employeeId: number) => {
      setLoading(true);
      try {
        const updatedUserPension = await PensionAPI.assessEmployeeForAE(employeeId);
        showMessage(polyglot.t('PensionModule.assessedSuccessfully'), 'success');
        setUserPension(updatedUserPension);
        setShouldRefreshOnClose(true);
      } catch (error) {
        showMessage(
          polyglot.t('PensionModule.assessedSuccessfully', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, showMessage, setShouldRefreshOnClose, setUserPension]
  );

  // disable enrollment if the pension is relief-at-source and the user has no NI number
  const disableEnrollDueToNoNINO = useMemo(() => {
    if (userPension?.inPension) return false;
    const provider = pensionSchemes.find((p) => p.externalId === formik.values.pensionProvider);
    if (!provider) return false;
    return provider.pensionRule === ReliefAtSource.value && !userPayroll?.payrollValues?.niNumber;
  }, [formik.values.pensionProvider, pensionSchemes, userPayroll?.payrollValues?.niNumber, userPension?.inPension]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('PensionModule.enrolToPension')}</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
          <Typography variant="title3">{polyglot.t('PensionModule.assessment')}</Typography>
          <TooltipPopper
            position="bottom"
            title="By law, any employee who is 22 years old or over (but under State Pension age) and earns more than £10,000 a year
        (for the current tax year) must be put into a pension scheme."
          >
            <Box sx={{ cursor: 'pointer' }}>
              <InfoCircleGray {...iconSize} />
            </Box>
          </TooltipPopper>
        </Box>

        <SmallLoader loading={loading} sx={drawerContentSx} spinnerSx={{ my: 10 }}>
          <Box>
            <Typography variant="captionSmall">{polyglot.t('General.employee')}</Typography>
            <UserCell userId={userPension.userId} sx={{ mt: spacing.m5 }} nameVariant="title4" />
          </Box>

          <Box>
            <Typography variant="captionSmall">{polyglot.t('PensionModule.workerType')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.gap5 }}>
              <Typography variant="title4">{userPension?.lastAssessment?.status ?? 'N/A'}</Typography>
              {userPension?.lastAssessment?.reason && (
                <TooltipPopper position="bottom" title={userPension?.lastAssessment.reason}>
                  <InfoCircleGray width="12px" height="12px" />
                </TooltipPopper>
              )}
            </Box>
          </Box>
          {hasPensionAllScope && (
            <Box>
              <Typography variant="captionSmall">{polyglot.t('PensionModule.actionRequired')}</Typography>
              <Typography variant="title4">
                {getActionRequiredFromLastAssessment(userPension?.lastAssessment)}
              </Typography>
            </Box>
          )}

          {hasPensionAllScope && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="captionSmall">{polyglot.t('PensionModule.assessmentDate')}</Typography>
                <Typography variant="title4">
                  {userPension?.lastAssessment?.assessmentDate
                    ? dateAPItoDisplay(userPension.lastAssessment.assessmentDate)
                    : polyglot.t('PensionModule.notAssessed')}
                </Typography>
              </Box>
              {hasPensionAllScope &&
                userPension?.lastAssessment?.employeeState &&
                // If leaver, don't show assess option
                !(Object.keys(LeavingEmployeeStates) as StaffologyEmployeeStates[]).includes(
                  userPension?.lastAssessment?.employeeState
                ) && (
                  <IconButton
                    disableRipple
                    sx={{ ...tableIconButtonSx, cursor: loading ? 'default' : 'pointer' }}
                    onClick={async (event) => {
                      if (event) event.preventDefault();
                      await assessEmployeeForAutoEnrolment(userPension.userId);
                    }}
                  >
                    <RefreshIcon sx={{ fontSize: 18, padding: -2, color: 'navy' }} />
                    <Edit {...iconSize} />
                  </IconButton>
                )}
            </Box>
          )}

          {!userPension?.lastAssessment && (
            <Box sx={buttonBoxDrawerSx}>
              {totalNoOfPayruns === 0 && (
                <ButtonComponent
                  sizeVariant="medium"
                  colorVariant="secondary"
                  onClick={() => {
                    setIsAddRetrospectivelyDrawerOpen(true);
                  }}
                  fullWidth
                >
                  {polyglot.t('PensionModule.addRetrospectively')}
                </ButtonComponent>
              )}
              <LoaderButton
                name={polyglot.t('PensionModule.assess')}
                loading={loading}
                onClick={async (event) => {
                  if (event) event.preventDefault();
                  await assessEmployeeForAutoEnrolment(userPension.userId);
                }}
                sizeVariant="medium"
                colorVariant="primary"
                fullWidth
              />

              <AddToPensionRetrospectivelyDrawer
                isOpen={isAddRetrospectivelyDrawerOpen}
                setIsOpen={setIsAddRetrospectivelyDrawerOpen}
                userPension={userPension}
                pensionSchemes={pensionSchemes}
                lastAssessment={userPension?.lastAssessment ?? undefined}
                refresh={refreshData}
              />
            </Box>
          )}
        </SmallLoader>

        {userPension?.lastAssessment && (
          <Box sx={drawerContentSx}>
            <Typography variant="title3" sx={{ mt: spacing.mt10 }}>
              {polyglot.t('PensionModule.pensionScheme')}
            </Typography>

            <SmallLoader loading={loading} sx={drawerContentSx} spinnerSx={{ my: 10 }}>
              {hasPensionAllScope && !userPension?.inPension ? (
                <SelectComponent
                  name="pensionProvider"
                  label={polyglot.t('PensionModule.pensionProvider')}
                  options={availablePensionSchemes}
                  value={formik.values.pensionProvider}
                  compareValue={formik.values.pensionProvider}
                  onChange={(event) => {
                    formik.handleChange(event);

                    const providerId = event.target.value;
                    const newSchemeWorkerGroups = getWorkerGroupOptionsByProviderId(providerId);
                    formik.setFieldValue('workerGroupId', '');
                    formik.setFieldValue('employerContribution', '');
                    formik.setFieldValue('employeeContribution', '');
                    setWorkerGroupOptions(newSchemeWorkerGroups);
                  }}
                  error={!!formik.errors.pensionProvider && formik.touched.pensionProvider}
                  helperText={formik.touched.pensionProvider && (formik.errors.pensionProvider as string)}
                />
              ) : (
                <PensionProviderReadOnlyLabel userPension={userPension} pensionSchemes={pensionSchemes} />
              )}

              {hasPensionAllScope && (
                <SelectComponent
                  name="workerGroupId"
                  label={polyglot.t('PensionModule.workerGroup')}
                  options={workerGroupOptions}
                  value={formik.values.workerGroupId}
                  compareValue={formik.values.workerGroupId}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const workerGroupId = e.target.value;
                    const provider = pensionSchemes.find((p) => p.externalId === formik.values.pensionProvider);
                    const workerGroup = provider?.workerGroups.find((wG) => wG.externalId === workerGroupId);

                    formik.setFieldValue('employerContribution', String(workerGroup?.employerContribution) ?? '');
                    formik.setFieldValue('employeeContribution', String(workerGroup?.employeeContribution) ?? '');
                  }}
                  error={!!formik.errors.workerGroupId && formik.touched.workerGroupId}
                  helperText={(formik.touched.workerGroupId && formik.errors.workerGroupId) as string}
                />
              )}

              {hasPensionAllScope ? (
                <DatePickerComponent
                  name="enrollmentDate"
                  label={polyglot.t('PensionModule.enrollmentDate')}
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.enrollmentDate ?? null}
                  onChange={(value) => {
                    if (dayjs(value).isValid()) {
                      formik.setFieldValue('enrollmentDate', value);
                    }
                  }}
                  minDate={new LocalDate(minimumStartDateAllowed).getDate() ?? new LocalDate().getDate()}
                  error={!!formik.errors.enrollmentDate && Boolean(formik.touched.enrollmentDate)}
                  helperText={!!formik.touched.enrollmentDate && (formik.errors.enrollmentDate as string)}
                />
              ) : (
                <Box>
                  <Typography variant="captionSmall">{polyglot.t('PensionModule.enrollmentDate')}</Typography>
                  <Typography variant="title4">
                    {formik.values.enrollmentDate ? dateAPItoDisplay(formik.values.enrollmentDate) : 'N/A'}
                  </Typography>
                </Box>
              )}

              {hasPensionAllScope ? (
                <Box sx={{ display: 'flex', gap: spacing.gap20 }}>
                  <TextfieldComponent
                    name="employerContribution"
                    label={polyglot.t('BenefitModule.employerContribution')}
                    value={formik.values.employerContribution}
                    onChange={formik.handleChange}
                    error={formik.touched.employerContribution && !!formik.errors.employerContribution}
                    helperText={(formik.touched.employerContribution && formik.errors.employerContribution) as string}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                  <TextfieldComponent
                    name="employeeContribution"
                    label={polyglot.t('BenefitModule.employeeContribution')}
                    value={formik.values.employeeContribution}
                    onChange={formik.handleChange}
                    error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
                    helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) as string}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <Box>
                    <Typography variant="captionSmall">{polyglot.t('BenefitModule.employerContribution')}</Typography>
                    <Typography variant="title4">{formik.initialValues.employerContribution}%</Typography>
                  </Box>
                  <TextfieldComponent
                    name="employeeContribution"
                    label={polyglot.t('BenefitModule.employeeContribution')}
                    value={formik.values.employeeContribution}
                    onChange={formik.handleChange}
                    error={formik.touched.employeeContribution && !!formik.errors.employeeContribution}
                    helperText={(formik.touched.employeeContribution && formik.errors.employeeContribution) as string}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </Box>
              )}

              {disableEnrollDueToNoNINO && (
                <Box>
                  <Box sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: spacing.g5 }}>
                    <MistakeIcon {...iconSize} />
                    <Typography variant="title4" color="RedDark">
                      {polyglot.t('PensionModule.missingNINO')}
                    </Typography>
                  </Box>
                  <Typography variant="caption">{polyglot.t('PensionModule.missingNINODesc')}</Typography>
                  <Button sx={{ ...secondaryTableSmallBtn, mt: spacing.mt10 }} onClick={() => setPayrollEditOpen(true)}>
                    {polyglot.t('PensionModule.editTaxInfo')}
                  </Button>
                </Box>
              )}

              <Box sx={buttonBoxDrawerSx}>
                {userPension?.inPension && (
                  <>
                    <StyledMenuComponent
                      actionButtonDetails={{
                        type: 'button',
                        colorVariant: 'secondary',
                        sizeVariant: 'medium',
                        title: polyglot.t('General.actions'),
                        icon: <ArrowDown {...iconSize} />,
                        iconPosition: 'end',
                      }}
                      options={PensionActionsMenuStructure(polyglot)}
                    />
                    {isLeaveModalOpen && (
                      <LeavePensionModal
                        isOpen={isLeaveModalOpen}
                        setIsOpen={setIsLeaveModalOpen}
                        userId={userPension.userId}
                        userPension={userPension}
                        refresh={refreshData}
                      />
                    )}
                    {isDeleteModalOpen && (
                      <DeleteEmployeePensionModal
                        isOpen={isDeleteModalOpen}
                        setIsOpen={setIsDeleteModalOpen}
                        userId={userPension.userId}
                        refresh={refreshData}
                      />
                    )}
                  </>
                )}

                {!userPension?.inPension && totalNoOfPayruns === 0 && (
                  <ButtonComponent
                    sizeVariant="medium"
                    colorVariant="secondary"
                    disabled={disableEnrollDueToNoNINO}
                    onClick={() => {
                      setIsAddRetrospectivelyDrawerOpen(true);
                    }}
                    fullWidth
                  >
                    {polyglot.t('PensionModule.addRetrospectively')}
                  </ButtonComponent>
                )}

                <LoaderButton
                  name={
                    userPension?.inPension
                      ? polyglot.t('General.update')
                      : (userPension?.lastAssessment?.status &&
                          ActionButtonName(polyglot)[userPension.lastAssessment?.status]) ||
                        polyglot.t('PensionModule.enrol')
                  }
                  loading={loading}
                  disabled={disableEnrollDueToNoNINO}
                  sizeVariant="medium"
                  colorVariant="primary"
                  fullWidth
                />
              </Box>
            </SmallLoader>
          </Box>
        )}
        {userPayroll && (
          <EditPayrollRecordDrawer
            isOpen={payrollEditOpen}
            close={() => setPayrollEditOpen(false)}
            payrollRecord={userPayroll}
            mode="append"
            userId={userPension.userId}
            onUpdateFinished={() => {
              refreshUserPayroll();
            }}
          />
        )}
      </Form>
    </FormikProvider>
  );
};
