import React, { useCallback, useContext, useEffect, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/side-bar-icons/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { Divider } from '@/v2/components/divider.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OnboardingScopes } from '@/v2/feature/onboarding/onboarding.scopes';
import { goToEditTemplate } from '@/v2/feature/onboarding/onboarding.util';
import { UpgradeToProModal, PlanNames } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

type OnboardingSettingsProps = {
  templates: OnboardingTemplate[];
  refresh?: () => void;
};

export const OnboardingSettings = ({ templates: initialTemplates, refresh }: OnboardingSettingsProps) => {
  const { polyglot } = usePolyglot();
  const sortTemplatesByName = useCallback(
    (templates: OnboardingTemplate[]) =>
      templates.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })),
    []
  );
  const [templates, setTemplates] = useState(sortTemplatesByName(initialTemplates));
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const refreshTemplates = useCallback(() => {
    OnboardingAPI.getOnboardingTemplates()
      .then(sortTemplatesByName)
      .then(setTemplates)
      .catch((error) =>
        showMessage(
          polyglot.t('OnboardingSettings.errorMessages.load', { errorMessage: nestErrorMessage(error) }),
          'error'
        )
      );
  }, [showMessage, sortTemplatesByName, polyglot]);

  useEffect(refreshTemplates, [refreshTemplates]);

  const closeOnboardingSettings = useCallback(() => routerHistory.goBack(), [routerHistory]);
  useEscapeKey(closeOnboardingSettings);

  const createNewTemplate = useCallback(async () => {
    try {
      setCreatingTemplate(true);
      const currentTemplates = await OnboardingAPI.getOnboardingTemplates();
      let newTemplateName: string;
      for (let i = 1; ; i++) {
        const templateName = polyglot.t('OnboardingSettings.flowNumber', { num: i.toString() });
        if (currentTemplates.find((t) => t.name === templateName)) {
          continue;
        }
        newTemplateName = templateName;
        break;
      }
      const template = await OnboardingAPI.createOnboardingTemplate({
        name: newTemplateName,
        template: {
          basic: true,
        },
      });
      refresh?.();
      goToEditTemplate(routerHistory, template.templateId, 'new-template');
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(
          polyglot.t('OnboardingSettings.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
      setCreatingTemplate(false);
    }
  }, [refresh, routerHistory, showMessage, polyglot]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('OnboardingSettings.flows')}
          </Typography>
        }
        showAction={hasScopes(OnboardingScopes.CREATE_TEMPLATE, scopesContext)}
        actions={
          <LoaderButton
            name={polyglot.t('OnboardingSettings.add')}
            type="button"
            onClick={() => createNewTemplate()}
            loading={creatingTemplate}
            colorVariant="primary"
            sizeVariant="small"
            style={{ width: 'fit-content', marginTop: '20px' }}
          />
        }
      />
      <ContentWrapper loading={false} sx={{ ...spacing.pt20, pb: 0 }}>
        <Stack
          sx={{
            flexFlow: { sm: 'column', md: 'column', lg: 'row' },
            gap: spacing.g80,
            overflow: 'hidden',
          }}
        >
          <Stack sx={{ flex: 1 }}>
            <Stack
              sx={{
                overflowY: 'auto',
                maxWidth: '600px',
                pr: '16px',
                mr: '-16px',
                '&::-webkit-scrollbar': { width: '3px' },
              }}
            >
              {templates.map((t, idx, arr) => {
                const isLastTemplate = arr.length === 1;
                return (
                  <React.Fragment key={t.templateId}>
                    {idx > 0 && <Divider />}
                    <Stack key={`onboarding-item-${idx}`} sx={{ flexFlow: 'row', alignItems: 'center' }}>
                      <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey, py: spacing.py20 }}>
                        {t.name}
                      </Typography>
                      <Stack sx={{ flexFlow: 'row', ml: 'auto', gap: spacing.g5, pl: spacing.pl20 }}>
                        <ScopesControl scopes={OnboardingScopes.EDIT_TEMPLATE}>
                          <IconButton
                            title={`Edit ${t.name}`}
                            onClick={() => goToEditTemplate(routerHistory, t.templateId, 'edit-template')}
                            sx={{ ...tableIconButtonSx }}
                          >
                            <Edit />
                          </IconButton>
                        </ScopesControl>
                        <ScopesControl scopes={OnboardingScopes.DELETE_TEMPLATE}>
                          <IconButton
                            title={isLastTemplate ? '' : polyglot.t('OnboardingSettings.delete', { name: t.name })}
                            onClick={() => OnboardingAPI.deleteOnboardingTemplate(t.templateId).then(refreshTemplates)}
                            sx={{ ...tableIconButtonSx }}
                            disabled={isLastTemplate} // don't allow the last template to be deleted
                          >
                            <Trash />
                          </IconButton>
                        </ScopesControl>
                      </Stack>
                    </Stack>
                  </React.Fragment>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
        <UpgradeToProModal
          isOpen={upgradeModalOpen}
          setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
          planName={PlanNames.PEOPLE_PRO}
          messageSuffix="proGeneric"
        />
      </ContentWrapper>
    </RootStyle>
  );
};
