import React, { useMemo } from 'react';

import {
  isAllowanceBenefit,
  isLoanBenefit,
  isRecurringBenefit,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util';
import { AllowanceBenefitOverview } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/allowance-benefit-overview.component';
import { LoanBenefitOverview } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/loan-benefit-overview.component';
import { RecurringBenefitOverview } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/recurring-benefit-overview.component';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { useParams } from 'react-router-dom';

interface PageProps {
  readonly userId: number;
  readonly userBenefits: readonly UserCustomBenefitDto[];
  readonly refreshCustomBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export const UserCustomBenefitDetailsPage = ({ userId, userBenefits, loading, refreshCustomBenefits }: PageProps) => {
  const params = useParams<{ id: string }>();
  const benefitId = Number(params.id);
  const userBenefit = useMemo(() => userBenefits.find((b) => b.customBenefitId === benefitId), [
    userBenefits,
    benefitId,
  ]);

  return userBenefit && isAllowanceBenefit(userBenefit.customBenefit?.type) ? (
    <AllowanceBenefitOverview
      userId={userId}
      userCustomBenefit={userBenefit}
      refresh={refreshCustomBenefits}
      loading={loading}
    />
  ) : userBenefit && isRecurringBenefit(userBenefit.customBenefit?.type) ? (
    <RecurringBenefitOverview
      userId={userId}
      userCustomBenefit={userBenefit}
      loading={loading}
      refresh={refreshCustomBenefits}
    />
  ) : userBenefit && isLoanBenefit(userBenefit.customBenefit?.type) ? (
    <LoanBenefitOverview
      userId={userId}
      userCustomBenefit={userBenefit}
      refresh={refreshCustomBenefits}
      loading={loading}
    />
  ) : null;
};
