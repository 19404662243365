import React, { useEffect, useMemo } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { SwitchComponent } from '@v2/components/forms/switch.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DEFAULT_SCHEDULE_CAP } from '@v2/feature/attendance/attendance-schedule.util';
import { AttendanceEndpoints } from '@v2/feature/attendance/attendance.api';
import { AttendanceScheduleDto, AttendanceTypeDto } from '@v2/feature/attendance/attendance.dto';
import {
  ScheduleTrackingSettingsFormData,
  ScheduleTrackingTimesheetType,
  ScheduleTrackingType,
  ScheduleTrackingTypeOptions,
} from '@v2/feature/attendance/attendance.interface';
import { EMPLOYEE_SITE, getScheduleTrackingSettingsValidationSchema } from '@v2/feature/attendance/attendance.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';

import { MultipleSelectCheckbox, MultiSelectOption } from '@/v2/components/forms/multiple-select-checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { drawerContentSx, fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface ScheduleSettingsFormProps {
  readonly attendanceSchedule: AttendanceScheduleDto & {
    attendanceTypesAllowedIds: number[];
  };
  readonly onSubmit: (values: ScheduleTrackingSettingsFormData) => void | Promise<void>;
  readonly buttonName: string;
  readonly loading: boolean;
  readonly sites: readonly SiteDto[];
}

export const ScheduleSettingsForm = ({
  attendanceSchedule,
  onSubmit,
  buttonName,
  loading,
  sites,
}: ScheduleSettingsFormProps) => {
  const { polyglot } = usePolyglot();

  const { data: attendanceTypes } = useApiClient<AttendanceTypeDto[], Error>(
    AttendanceEndpoints.getCompanyAttendanceTypes(),
    { suspense: false }
  );

  const formik = useFormik<ScheduleTrackingSettingsFormData>({
    initialValues: {
      trackingType: attendanceSchedule.trackingType,
      attendanceTypesAllowedIds: attendanceSchedule.attendanceTypesAllowedIds,
      timesheetType: attendanceSchedule.timesheetType,
      useGeolocation: attendanceSchedule.useGeolocation,
      restrictByGeolocation: attendanceSchedule.restrictByGeolocation,
      geolocationDistance: attendanceSchedule.geolocationDistance ?? null,
      geolocationEmployeeSite: attendanceSchedule.geolocationEmployeeSite ?? false,
      allowedWorkSites: attendanceSchedule.allowedWorkSites ?? null,
      clockInEarlyCapMinutes: attendanceSchedule.clockInEarlyCapMinutes ?? null,
    },
    validationSchema: getScheduleTrackingSettingsValidationSchema(polyglot),
    onSubmit,
  });

  useEffect(() => {
    const allTypes = attendanceTypes?.map((type) => type.id) ?? [];
    formik.setFieldValue('attendanceTypesAllowedIds', allTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceTypes]);

  const SitesOptions: MultiSelectOption<string | number>[] = useMemo(() => {
    return [EMPLOYEE_SITE(polyglot), ...(sites ? sites.map((site) => ({ value: site.id, label: site.name })) : [])];
  }, [sites, polyglot]);

  const SelectedSiteOptions = useMemo(() => {
    const employeeSite = formik.values.geolocationEmployeeSite ? [EMPLOYEE_SITE(polyglot)] : [];
    const sites = formik.values.allowedWorkSites
      ? SitesOptions.filter(
          (site) => typeof site.value === 'number' && formik.values.allowedWorkSites!.includes(site.value)
        )
      : [];

    return [...employeeSite, ...sites];
  }, [SitesOptions, formik.values.allowedWorkSites, formik.values.geolocationEmployeeSite, polyglot]);

  const MaximumDistanceOptions: MultiSelectOption<number>[] = useMemo(() => {
    return [100, 200, 300, 400, 500].map((distance) => ({
      value: distance,
      label: polyglot.t('ScheduleSettingsForm.distanceMeter', { distance: distance }),
    }));
  }, [polyglot]);

  const trackingTypeOptions = useMemo(() => ScheduleTrackingTypeOptions(attendanceSchedule.isFlexible, polyglot), [
    polyglot,
    attendanceSchedule,
  ]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">{polyglot.t('SettingsSectionReadonly.timesheets')}</Typography>
          <SwitchComponent
            checked={formik.values.trackingType !== ScheduleTrackingType.None}
            name="trackingType"
            onChange={(_e, enabled) => {
              if (enabled) formik.setFieldValue('trackingType', ScheduleTrackingType.Regular);
              else {
                formik.setFieldValue('trackingType', ScheduleTrackingType.None);
                formik.setFieldValue('timesheetType', ScheduleTrackingTimesheetType.Weekly);
                formik.setFieldValue('useGeolocation', false);
                formik.setFieldValue('restrictByGeolocation', false);
                formik.setFieldValue('clockInEarlyCapMinutes', null);
                formik.setFieldValue('geolocationEmployeeSite', false);
                formik.setFieldValue('allowedWorkSites', null);
              }
            }}
          />
        </Box>

        {formik.values.trackingType !== ScheduleTrackingType.None && (
          <FormControl sx={{ width: '100%' }}>
            <Typography variant="title3">{polyglot.t('ScheduleSettingsForm.trackingType')}</Typography>

            <RadioGroup
              aria-labelledby="method"
              name="trackingTypeEnabled"
              onChange={(event) => {
                formik.setFieldValue('trackingType', event.target.value);

                if (event.target.value === ScheduleTrackingType.ClockInClockOut) {
                  formik.setFieldValue('timesheetType', ScheduleTrackingTimesheetType.Daily);
                  formik.setFieldValue(
                    'clockInEarlyCapMinutes',
                    attendanceSchedule.clockInEarlyCapMinutes ?? DEFAULT_SCHEDULE_CAP
                  );
                } else {
                  formik.setFieldValue('timesheetType', ScheduleTrackingTimesheetType.Weekly);
                  formik.setFieldValue('useGeolocation', false);
                  formik.setFieldValue('restrictByGeolocation', false);
                  formik.setFieldValue('clockInEarlyCapMinutes', null);
                  formik.setFieldValue('geolocationEmployeeSite', false);
                  formik.setFieldValue('allowedWorkSites', null);
                }
              }}
            >
              <FormControlLabel
                key="regular"
                labelPlacement="end"
                value={ScheduleTrackingType.Regular}
                checked={formik.values.trackingType === ScheduleTrackingType.Regular}
                control={<StyledRadio />}
                label={
                  <Typography
                    variant={formik.values.trackingType === ScheduleTrackingType.Regular ? 'title4' : 'caption'}
                  >
                    {trackingTypeOptions.find((o) => o.value === ScheduleTrackingType.Regular)?.label ??
                      ScheduleTrackingType.Regular}
                  </Typography>
                }
              />
              <FormControlLabel
                key="clock-in-clock-out"
                labelPlacement="end"
                value={ScheduleTrackingType.ClockInClockOut}
                checked={formik.values.trackingType === ScheduleTrackingType.ClockInClockOut}
                control={<StyledRadio />}
                label={
                  <Typography
                    variant={formik.values.trackingType === ScheduleTrackingType.ClockInClockOut ? 'title4' : 'caption'}
                  >
                    {trackingTypeOptions.find((o) => o.value === ScheduleTrackingType.ClockInClockOut)?.label ??
                      ScheduleTrackingType.ClockInClockOut}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        )}

        {formik.values.trackingType === ScheduleTrackingType.ClockInClockOut && (
          <Box sx={drawerContentSx}>
            <Typography variant="title3" sx={{ mt: '10px' }}>
              {polyglot.t('SettingsSectionReadonly.additionalSettings')}
            </Typography>

            <Box sx={fieldSx}>
              <TextfieldComponent
                label={polyglot.t('ScheduleSettingsForm.clockInEarlyCapMinutes')}
                name="clockInEarlyCapMinutes"
                value={formik.values.clockInEarlyCapMinutes}
                onChange={formik.handleChange}
                error={formik.touched.clockInEarlyCapMinutes && !!formik.errors.clockInEarlyCapMinutes}
                helperText={
                  ((formik.touched.clockInEarlyCapMinutes && formik.errors.clockInEarlyCapMinutes) as string) ??
                  (' ' as string)
                }
                endAdornment="none"
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="caption">{polyglot.t('SettingsSectionReadonly.useGPSForShifts')}</Typography>
              <SwitchComponent
                checked={formik.values.useGeolocation}
                name="useGeolocation"
                onChange={(_, enabled) => {
                  formik.setFieldValue('useGeolocation', enabled);
                  if (!enabled) formik.setFieldValue('restrictByGeolocation', false);
                }}
              />
            </Box>

            {formik.values.useGeolocation && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="caption">{polyglot.t('SettingsSectionReadonly.restrictLocation')}</Typography>
                <SwitchComponent
                  checked={formik.values.restrictByGeolocation}
                  name="restrictByGeolocation"
                  onChange={(_, enabled) => {
                    formik.setFieldValue('restrictByGeolocation', enabled);
                    if (!enabled) {
                      formik.setFieldValue('geolocationEmployeeSite', false);
                      formik.setFieldValue('allowedWorkSites', null);
                      formik.setFieldValue('geolocationDistance', null);
                    }
                  }}
                />
              </Box>
            )}

            {formik.values.trackingType === ScheduleTrackingType.ClockInClockOut &&
              formik.values.restrictByGeolocation && (
                <Box sx={fieldSx}>
                  <MultipleSelectCheckbox
                    id="group-select"
                    options={SitesOptions}
                    value={SelectedSiteOptions}
                    onChange={(_, values) => {
                      const employeeSites = !!values.find((site) => site.value === 'employeeSite');
                      const sites = values.filter((site) => typeof site.value === 'number').map((site) => site.value);
                      formik.setFieldValue('geolocationEmployeeSite', employeeSites);
                      formik.setFieldValue('allowedWorkSites', sites);
                    }}
                    isOptionEqualToValue={(x, y) => x.value === y.value}
                    getOptionLabel={({ label }: { label: string }): string => label}
                    label={polyglot.t('ScheduleSettingsForm.allowedWork')}
                    helperText={
                      formik.touched.allowedWorkSites || formik.touched.geolocationEmployeeSite
                        ? ((formik.errors.allowedWorkSites || formik.errors.geolocationEmployeeSite) as string)
                        : ''
                    }
                    error={Boolean(
                      (formik.touched.allowedWorkSites || formik.touched.geolocationEmployeeSite) &&
                        (formik.errors.allowedWorkSites || formik.errors.geolocationEmployeeSite)
                    )}
                  />
                </Box>
              )}

            {formik.values.trackingType === ScheduleTrackingType.ClockInClockOut &&
              formik.values.restrictByGeolocation && (
                <Box sx={fieldSx}>
                  <SelectComponent
                    name="geolocationDistance"
                    label={polyglot.t('ScheduleSettingsForm.geolocationDistance')}
                    options={MaximumDistanceOptions}
                    value={formik.values.geolocationDistance}
                    compareValue={formik.values.geolocationDistance}
                    onChange={formik.handleChange}
                    error={!!formik.errors.geolocationDistance && formik.touched.geolocationDistance}
                    helperText={(formik.touched.geolocationDistance && formik.errors.geolocationDistance) as string}
                  />
                </Box>
              )}
          </Box>
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name={buttonName} loading={loading} fullWidth sizeVariant="medium" colorVariant="primary" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
