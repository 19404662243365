import { useCallback, useEffect, useState } from 'react';

import { Loader } from '@v2/components/loader.component';
import { InsuranceAPI } from '@v2/feature/benefits/subfeature/insurance/insurance.api';
import { InsurancePolicyDto, InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { InsuranceCompanyEmployeeListPage } from '@v2/feature/benefits/subfeature/insurance/pages/insurance-company-employee-list.page';
import { InsuranceCompanyOverviewPage } from '@v2/feature/benefits/subfeature/insurance/pages/insurance-company-overview.page';
import { Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE, INSURANCE_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';

interface InsuranceCompanyRouterProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly refreshInsuranceQuote: () => Promise<void>;
  readonly loading: boolean;
}

export const InsuranceCompanyRouter = ({
  insuranceQuote,
  refreshInsuranceQuote,
  loading,
}: InsuranceCompanyRouterProps) => {
  const [showMessage] = useMessage();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [insurancePolicy, setInsurancePolicy] = useState<InsurancePolicyDto | null>(null);

  const fetchInsurancePolicy = useCallback(async () => {
    try {
      if (insuranceQuote?.policyId) {
        setIsLoading(true);
        const insurancePolicy = await InsuranceAPI.getInsurancePolicyById(insuranceQuote.policyId, true);
        setInsurancePolicy(insurancePolicy);
      }
    } catch (error) {
      // TODO: @polyglot-later-insurance-hooray
      showMessage(`Could not get insurance policy details. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [insuranceQuote?.policyId, showMessage]);

  useEffect(() => {
    fetchInsurancePolicy();
  }, [fetchInsurancePolicy]);

  return (
    <Loader loading={isLoading || loading}>
      <Switch>
        {insurancePolicy && (
          <RouteScopesControl exact scopes={['insurance:all']} path={INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE}>
            <InsuranceCompanyEmployeeListPage insurancePolicy={insurancePolicy} loading={isLoading || loading} />
          </RouteScopesControl>
        )}

        <RouteScopesControl scopes={['insurance:all']} path={INSURANCE_COMPANY_OVERVIEW_ROUTE}>
          <InsuranceCompanyOverviewPage
            insurancePolicy={insurancePolicy ?? undefined}
            insuranceQuote={insuranceQuote}
            loading={isLoading || loading}
            refresh={refreshInsuranceQuote}
          />
        </RouteScopesControl>
      </Switch>
    </Loader>
  );
};
