import React, { Dispatch, SetStateAction } from 'react';

import { UserPayrollDto } from '@shared/modules/payroll/payroll.types';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import {
  EditUserPayrollPage,
  PayrollRecordChangeMode,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll.page';

type SetState = Dispatch<SetStateAction<boolean>>;
type VoidFunction = () => void;

interface Props {
  readonly isOpen: boolean;
  readonly onOpen?: VoidFunction | SetState;
  readonly payrollRecord: UserPayrollDto | null;
  userId?: number;
  onUpdateStarted?: () => void;
  onUpdateFinished?: (success: boolean) => void;
  readonly mode: PayrollRecordChangeMode;

  close(): void;
}

export const EditPayrollRecordDrawer = ({
  isOpen,
  onOpen = () => {},
  close,
  userId,
  payrollRecord,
  onUpdateStarted,
  onUpdateFinished,
  mode,
}: Props): React.JSX.Element => {
  return (
    <DrawerModal isOpen={isOpen} setIsOpen={close ?? onOpen}>
      {isOpen && userId ? (
        <EditUserPayrollPage
          userId={userId}
          record={payrollRecord}
          close={close}
          isUserUpdating={false}
          mode={mode}
          requireReason={!!payrollRecord}
          onUpdateStarted={onUpdateStarted}
          onUpdateFinished={onUpdateFinished}
        />
      ) : (
        <></>
      )}
    </DrawerModal>
  );
};
