import { useCallback, useMemo, useState } from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { ReactComponent as DeleteIcon } from '@/images/side-bar-icons/Delete.svg';
import { ReactComponent as Loading } from '@/images/side-bar-icons/Spin.svg';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { AccountingCodeItem } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/accounting-settings/components/accounting-code-item.component';
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from '@/v2/feature/payroll/payroll-external.interface';
import {
  AccountingFieldPaycodeMapping,
  CompanyPayrollAccountingConfig,
  NominalCodeDto,
} from '@/v2/feature/payroll/payroll.interface';
import { StaffologyPayCode } from '@/v2/infrastructure/common-interfaces/staffology-client.interface';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

type PayrollEditPayCodesPageProps = {
  payrollId: number;
  payCodesNames: string[];
  payCode: StaffologyPayCode;
  nominalCodes?: NominalCodeDto[] | null;
  reimportNominalCodes: () => Promise<void>;
  accountingConfig: CompanyPayrollAccountingConfig;
  deleteCompanyPaycode: (code: StaffologyPayCode) => Promise<boolean>;
  updateCompanyPaycode: (
    paycodes: StaffologyPayCode,
    accountingCode?: AccountingFieldPaycodeMapping
  ) => Promise<boolean>;
  close: () => void;
};

export const PayrollEditPayCodesPage = ({
  payCodesNames,
  payCode,
  nominalCodes,
  reimportNominalCodes,
  accountingConfig,
  close,
  deleteCompanyPaycode,
  updateCompanyPaycode,
}: PayrollEditPayCodesPageProps) => {
  const [updatingPayCode, setUpdatingPayCode] = useState<'saving' | 'deleting'>();
  const isSystemOrControlCode = payCode.isSystemCode || payCode.isControlCode;

  const validationSchema = useMemo(() => {
    const otherPayCodeNames = payCodesNames.filter((name) => name !== payCode.code);
    return yup.object({
      code: yup
        .string()
        .required('Pay code identifier is required')
        .notOneOf(otherPayCodeNames, 'Pay code already exists'),
      title: yup.string().required('Pay code name is required'),
      isDeduction: yup.boolean().required('Choose a pay code type'),
    });
  }, [payCode.code, payCodesNames]);

  const deletePayCode = useCallback(async () => {
    setUpdatingPayCode('deleting');
    const payCodeDeleted = await deleteCompanyPaycode(payCode);
    setUpdatingPayCode(undefined);
    if (payCodeDeleted) close();
  }, [close, deleteCompanyPaycode, payCode]);

  const formik = useFormik({
    initialValues: {
      ...payCode,
      accountingField: accountingConfig.accountingCodes.fields?.find((f) => f.payCode === payCode.code),
    },
    validationSchema,
    onSubmit: async ({ accountingField, ...payCode }) => {
      setUpdatingPayCode('saving');
      const savedPaycode = await updateCompanyPaycode(payCode, accountingField);
      setUpdatingPayCode(undefined);
      if (savedPaycode) close();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form style={drawerContentSx}>
        <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
          <Typography variant="title2">Edit code</Typography>
          <IconButton
            sx={{ ...tableIconButtonSx, ml: 'auto' }}
            title={`Delete pay code: ${payCode.code}`}
            onClick={() => deletePayCode()}
            disabled={isSystemOrControlCode || !!updatingPayCode}
          >
            {updatingPayCode === 'deleting' ? <Loading /> : <DeleteIcon />}
          </IconButton>
        </Stack>

        <Stack>
          <Typography variant="captionSmall">Pay code</Typography>
          {/* the pay code identifier is not editable because it's used to identify the edited code in Staffology */}
          <Typography variant="title4">{payCode.code}</Typography>
        </Stack>

        <TextfieldComponent
          name="title"
          label="Name"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.touched.title && !!formik.errors.title}
          helperText={formik.touched.title && formik.errors.title}
          disabled={isSystemOrControlCode || !!updatingPayCode}
          maxLength={MAX_PAYLINE_DESCRIPTION_LENGTH}
          autoFocus
        />
        <SelectComponent
          name="type"
          label="Type"
          options={[
            { label: 'Addition', value: 'addition' },
            { label: 'Deduction', value: 'deduction' },
          ]}
          onChange={(e) => formik.setFieldValue('isDeduction', e.target.value === 'deduction')}
          value={formik.values.isDeduction ? 'deduction' : 'addition'}
          disabled={isSystemOrControlCode || !!updatingPayCode}
        />
        <Stack sx={{ gap: spacing.g15 }}>
          <Typography variant="title4">Subject to</Typography>
          <CheckboxComponent
            name="isNiable"
            label="National Insurance"
            checked={formik.values.isNiable}
            onChange={formik.handleChange}
            disabled={isSystemOrControlCode || !!updatingPayCode}
          />
          <CheckboxComponent
            name="isTaxable"
            label="Tax"
            checked={formik.values.isTaxable}
            onChange={formik.handleChange}
            disabled={isSystemOrControlCode || !!updatingPayCode}
          />
          <CheckboxComponent
            name="isPensionable"
            label="Pension"
            checked={formik.values.isPensionable}
            onChange={formik.handleChange}
            disabled={isSystemOrControlCode || !!updatingPayCode}
          />
          <CheckboxComponent
            name="isAttachable"
            label="Attachment Orders"
            checked={formik.values.isAttachable}
            onChange={formik.handleChange}
            disabled={isSystemOrControlCode || !!updatingPayCode}
          />
        </Stack>

        <AccountingCodeItem
          payCode={payCode.code}
          nominalCodes={nominalCodes}
          reimportNominalCodes={reimportNominalCodes}
          accountingConfig={accountingConfig}
          accountingField={formik.values.accountingField}
          setAccountingField={(value: AccountingFieldPaycodeMapping) => {
            formik.setFieldValue('accountingField', value);
          }}
          disabled={!!updatingPayCode}
          autoFocus={isSystemOrControlCode}
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Save"
            loading={updatingPayCode === 'saving'}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
            style={{
              visibility: updatingPayCode === 'deleting' ? 'hidden' : 'visible',
            }}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
