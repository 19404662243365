import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl, RouteScopesHas } from '@/component/widgets/Scopes';
import {
  SURVEYS_TEAM_ONGOING_DETAIL_ROUTE,
  SURVEYS_TEAM_ONGOING_ROUTE,
  SURVEYS_TEAM_ONGOING_SETUP_ROUTE,
  SURVEYS_TEAM_ROUTE,
} from '@/lib/routes';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SurveyCycleCreationTeamRouter } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-router/team/survey-cycle-creation-team.router';
import { SurveyCycleDetailTeamRouter } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-router/team/survey-cycle-detail-team.router';
import { SurveyCycleOngoingListingPage } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-ongoing/survey-cycle-ongoing-listing/survey-cycle-ongoing-listing.page';
import { SURVEYS_MANAGER_SCOPE } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';

export const SurveysTeamRouter = () => {
  return (
    <Switch>
      <RouteScopesControl
        scopes={[SURVEYS_MANAGER_SCOPE]}
        path={SURVEYS_TEAM_ONGOING_SETUP_ROUTE}
        component={SurveyCycleCreationTeamRouter}
      />
      <RouteScopesControl
        scopes={[SURVEYS_MANAGER_SCOPE]}
        path={SURVEYS_TEAM_ONGOING_DETAIL_ROUTE}
        component={SurveyCycleDetailTeamRouter}
      />

      <RouteScopesHas scopes={[SURVEYS_MANAGER_SCOPE]} path={SURVEYS_TEAM_ONGOING_ROUTE} exact>
        <SurveyCycleOngoingListingPage reach={ReachType.Team} />
      </RouteScopesHas>

      <Redirect from={SURVEYS_TEAM_ROUTE} to={SURVEYS_TEAM_ONGOING_ROUTE} />
    </Switch>
  );
};
