import React from 'react';

import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { MoveToSectionContent } from '@/v2/feature/growth/reviews/features/components/move-to-section-content.component';
import { ReviewSectionBank } from '@/v2/feature/growth/reviews/interfaces/review-section-bank.interface';

export const ReviewTemplateMoveToSectionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  action,
  sections,
  questionId,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  action: (sectionId: string, questionId: string) => Promise<void>;
  sections: Pick<ReviewSectionBank, 'name' | 'id'>[] | undefined;
  questionId: string | undefined;
}) => {
  return (
    <DrawerModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <MoveToSectionContent onClose={onClose} action={action} sections={sections} questionId={questionId} />
    </DrawerModal>
  );
};
