import { useCallback, useContext, useEffect, useState } from 'react';

import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceOrderDto, DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus } from '@v2/feature/device/device.interface';
import { DeviceOrderDetailsPage } from '@v2/feature/device/features/devices-company/pages/device-order-details.page';
import { DevicesLocationPage } from '@v2/feature/device/features/devices-company/pages/devices-location.page';
import { ConfirmDeviceOrderDeliveryStepper } from '@v2/feature/device/features/my-devices/pages/device-activation/confirm-device-delivery-stepper.component';
import { DevicesActivationPage } from '@v2/feature/device/features/my-devices/pages/device-activation/devices-activation.page';
import { generatePath, Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';
import {
  DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
  DEVICES_COMPANY_DIRECTORY_ROUTE,
  DEVICES_COMPANY_LOCATION_ROUTE,
  DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
  DEVICES_COMPANY_ORDER_DETAILS_ROUTE,
  DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE,
  DEVICES_COMPANY_STORE_ROUTE,
} from '@/lib/routes';
import { DeviceScopes } from '@/v2/feature/device/device.scopes';
import { DevicesDetailsRouter } from '@/v2/feature/device/features/devices-company/device-details.router';
import { DeviceStorePage } from '@/v2/feature/device/features/devices-company/pages/device-store.page';
import { DevicesActivePage } from '@/v2/feature/device/features/devices-company/pages/devices-active.page';

export const DeviceCompanyRouter = () => {
  const [devicePossessions, setDevicePossessions] = useState<readonly DevicePossessionDto[]>([]);

  const [, setDeviceOrders] = useState<readonly DeviceOrderDto[]>([]);
  const [inShippingDeviceOrders, setInShippingDeviceOrders] = useState<readonly DeviceOrderDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const [showMessage] = useMessage();

  const refreshDevicePossessions = useCallback(async () => {
    try {
      setLoading(true);
      const [devicePossessions] = await Promise.all([DeviceAPI.getActiveDevicePossessions()]);
      setDevicePossessions(devicePossessions);
    } catch (error) {
      showMessage(`Could not retrieve devices list: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  const refreshOrders = useCallback(async () => {
    try {
      const deviceOrders = await DeviceAPI.getDeviceOrders();
      setDeviceOrders(deviceOrders.filter((deviceOrder) => deviceOrder.status !== DeviceOrderStatus.Delivered));

      setInShippingDeviceOrders(
        deviceOrders.filter((deviceOrder) => deviceOrder.status === DeviceOrderStatus.Shipping)
      );
    } catch (error) {
      showMessage(`Could not retrieve device orders list. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  const refreshDeviceAppData = useCallback(async () => {
    await Promise.all([refreshDevicePossessions()]);
  }, [refreshDevicePossessions]);

  useEffect(() => {
    refreshDeviceAppData();
  }, [refreshDeviceAppData]);

  return (
    <Switch>
      <RouteScopesControl
        scopes={['devices.enroll', 'devices:all']}
        path={DEVICES_COMPANY_DEVICE_DETAILS_ROUTE}
        context={scopesContext}
      >
        <DevicesDetailsRouter refreshDeviceAppData={refreshDeviceAppData} />
      </RouteScopesControl>
      <RouteScopesControl exact scopes={DeviceScopes.VIEW_DEVICE_LOCATIONS} path={DEVICES_COMPANY_LOCATION_ROUTE}>
        <DevicesLocationPage devicesPossessions={devicePossessions} loading={loading} />
      </RouteScopesControl>
      <RouteScopesControl
        exact
        scopes={DeviceScopes.VIEW_DEVICE_STORE}
        path={DEVICES_COMPANY_STORE_ROUTE}
        context={scopesContext}
      >
        <DeviceStorePage
          loading={loading}
          refresh={async () => {
            await Promise.all([refreshDevicePossessions(), refreshOrders()]);
          }}
          reach="company"
        />
      </RouteScopesControl>
      <RouteScopesControl
        scopes={DeviceScopes.CONFIRM_DEVICE_ORDER_DELIVERY}
        context={scopesContext}
        path={DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE}
      >
        <ConfirmDeviceOrderDeliveryStepper />
      </RouteScopesControl>
      <RouteScopesControl
        scopes={DeviceScopes.VIEW_DEVICE_ORDER_ACTIVATIONS}
        context={scopesContext}
        path={DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE}
      >
        {inShippingDeviceOrders.length === 1 ? (
          <Redirect
            to={generatePath(DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE, { orderId: inShippingDeviceOrders[0].id })}
          />
        ) : (
          <DevicesActivationPage deviceOrders={inShippingDeviceOrders} loading={loading} />
        )}
      </RouteScopesControl>
      <RouteScopesControl
        exact
        context={scopesContext}
        scopes={DeviceScopes.VIEW_DEVICE_ORDER_DETAILS}
        path={DEVICES_COMPANY_ORDER_DETAILS_ROUTE}
      >
        <DeviceOrderDetailsPage loading={loading} setLoading={setLoading} refreshOrders={refreshOrders} />
      </RouteScopesControl>
      <RouteScopesControl scopes={DeviceScopes.VIEW_ACTIVE_DEVICES} path={DEVICES_COMPANY_DIRECTORY_ROUTE}>
        <DevicesActivePage loading={loading} />
      </RouteScopesControl>
      <Redirect to={DEVICES_COMPANY_DIRECTORY_ROUTE} />
    </Switch>
  );
};
