import React, { useCallback, useMemo, useState } from 'react';

import { Row } from '@tanstack/react-table';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DevicesTable } from '@v2/feature/device/components/devices-table/devices-table.component';
import { ConfigurableDeviceStatus } from '@v2/feature/device/device.interface';
import { DeviceOrderDrawer } from '@v2/feature/device/features/devices-company/components/device-order-drawer.component';
import { OffboardingDeviceGenericDrawer } from '@v2/feature/offboarding/offboarding-process/devices/offboarding-device-generic-drawer.component';
import { SiteDto } from '@v2/feature/site/site.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { generatePath } from 'react-router-dom';

import { USER_OFFBOARDING_ROUTE } from '@/lib/routes';
import { ReturnDeviceDrawer } from '@/v2/feature/device/components/device-details/return-device-drawer.component';
import { DeviceEndpoints } from '@/v2/feature/device/device.api';
import { ConfigurableDeviceData } from '@/v2/feature/device/device.dto';

interface PageProps {
  readonly userId: number;
  readonly refresh: () => Promise<void>;
  readonly loading?: boolean;
}

export const OffboardingDevicesPage = ({ userId, refresh, loading }: PageProps) => {
  const {
    data: devices,
    mutate: refreshDevicesList,
    isLoading: devicesLoading,
  } = useApiClient(DeviceEndpoints.getOffboardingDevices(userId), { suspense: false });

  const { polyglot } = usePolyglot();

  const [selectedDeviceRow, setSelectedDeviceRow] = useState<ConfigurableDeviceData | null>(null);
  const [isReturnDeviceDrawerOpen, setIsReturnDeviceDrawerOpen] = useState<boolean>(false);
  const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState<boolean>(false);
  const [isGenericDrawerOpen, setIsGenericDrawerOpen] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>('');

  const filteredDevicePossessions = useMemo(() => {
    if (!searchInput || !devices) return devices ?? [];

    const search = searchInput.toLowerCase();
    return devices.filter(
      (d) =>
        d.devicePossession?.device?.modelName?.toLowerCase().includes(search) ||
        d.devicePossession?.device?.serialNumber?.includes(search)
    );
  }, [devices, searchInput]);

  const refreshDevices = useCallback(async () => {
    if (refreshDevicesList) await refreshDevicesList();
  }, [refreshDevicesList]);

  const refreshAll = useCallback(async () => {
    await Promise.all([refresh(), refreshDevices()]);
  }, [refreshDevices, refresh]);

  const handleRowClick = useCallback((row: Row<ConfigurableDeviceData>) => {
    setSelectedDeviceRow(row.original);

    if (row.original.status === ConfigurableDeviceStatus.inUse) {
      setIsReturnDeviceDrawerOpen(true);
    } else if (row.original.status === ConfigurableDeviceStatus.order) {
      setIsOrderDrawerOpen(true);
    } else {
      setIsGenericDrawerOpen(true);
    }
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('OffboardingPage.devices')}</Typography>}
        showBack
        backPath={generatePath(USER_OFFBOARDING_ROUTE, { userId })}
      />
      <ContentWrapper loading={loading}>
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <DevicesTable
          devices={filteredDevicePossessions}
          handleRowClick={handleRowClick}
          loading={devicesLoading}
          hiddenColumns={['modelNumber', 'customerNotes', 'osVersion', 'storage']}
        />

        <ReturnDeviceDrawer
          devicePossession={selectedDeviceRow?.devicePossession ?? null}
          isOpen={isReturnDeviceDrawerOpen}
          setIsOpen={setIsReturnDeviceDrawerOpen}
          closeDrawerAfterReturn={async () => {
            await refreshAll();
            setSelectedDeviceRow(null);
            setIsReturnDeviceDrawerOpen(false);
          }}
        />

        <DeviceOrderDrawer
          isOpen={isOrderDrawerOpen}
          setIsOpen={setIsOrderDrawerOpen}
          onClose={() => {
            setSelectedDeviceRow(null);
          }}
          deviceOrder={
            selectedDeviceRow?.order
              ? {
                  ...selectedDeviceRow.order,
                  possession: selectedDeviceRow.devicePossession,
                }
              : null
          }
          refresh={refreshAll}
          reach="offboarding"
          sitesById={
            selectedDeviceRow?.sites.reduce((dict, site) => {
              dict[site.id] = site;
              return dict;
            }, {} as Record<number, SiteDto>) ?? {}
          }
        />

        <OffboardingDeviceGenericDrawer
          isOpen={isGenericDrawerOpen}
          setIsOpen={setIsGenericDrawerOpen}
          device={selectedDeviceRow ?? null}
          onClose={() => {
            setSelectedDeviceRow(null);
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
