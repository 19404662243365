import { useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { UserPayrollDto } from '@shared/modules/payroll/payroll.types';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';

import { UserContractEndpoints } from '@/api-client/contracts.api';
import { UserCompensationSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Hazard } from '@/images/side-bar-icons/Hazard.svg';
import { USER_PAYROLL_NEW_STARTER } from '@/lib/routes';
import { Scope, ScopeRoot } from '@/models/scopes.model';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { EditPayrollRecordDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component';
import { PayrollRecordChangeMode } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll.page';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { UserProfileCardProps } from '@/v2/feature/user/features/user-forms/user-forms.interface';
import {
  calculateSkeletonHeight,
  cardSx,
  hazardSize,
} from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { TaxCardStructure } from '@/v2/feature/user/features/user-profile/details/components/tax-card-structure.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { isSameCountryCode } from '@/v2/infrastructure/country/country.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { hazardMissingField } from '@/v2/styles/icon-button.styles';

const skeletonHeight = (polyglot: Polyglot) => calculateSkeletonHeight(UserCompensationSchema(polyglot));

const PAYROLL_ALL_SCOPE = ['payroll:all'] as const;

export const TaxInformationCard = ({
  userId,
  handleSubmit = () => {},
  missingFields,
}: UserProfileCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [state] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const isOwnProfile = userId === globalState.user.userId;

  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const viewerContext = getScopesContext(state.user);
  const hasPayrollScope = hasScopes(['payroll', 'payroll:all'], viewerContext);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<{
    mode: PayrollRecordChangeMode;
    record?: UserPayrollDto;
  }>();

  const getProfileScope = (scopeRoot: ScopeRoot): readonly Scope[] => [
    (isOwnerProfile ? `${scopeRoot}:read` : `${scopeRoot}:read:all`) as Scope,
  ];
  const isOwnerProfile = state.user?.userId === userId;

  const PAYROLL_READ_SCOPE = getProfileScope('payroll');

  const { data: payroll, isValidating: loadingPayrollData, mutate: refreshPayrollData } = useApiClient(
    hasPayrollScope || isOwnerProfile ? PayrollEndpoints.getUserPayrolls(userId) : { url: undefined },
    {
      suspense: false,
    }
  );

  const { data: contract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), { suspense: false });
  const effectiveRecord = payroll?.effectiveRecord;
  const payrollCountry = payroll?.effectiveRecord?.countryCode || 'GB';
  const contractCountry = contract?.entity?.address?.countryCode || 'GB';

  const addAction = useMemo(() => {
    if (effectiveRecord && isSameCountryCode(payrollCountry, contractCountry)) return undefined;
    return isOwnProfile
      ? () => {
          // for our own profile, show the new starter form
          routerHistory.push(generatePath(USER_PAYROLL_NEW_STARTER, { userId }));
        }
      : () => {
          // for admins editing a user's profile, show the tax information drawer
          setEditingRecord({ mode: 'initial' });
          setEditDrawerOpen(true);
        };
  }, [contractCountry, effectiveRecord, isOwnProfile, payrollCountry, routerHistory, userId]);

  const editAction = useCallback(() => {
    setEditingRecord({ mode: 'append', record: effectiveRecord });
    setEditDrawerOpen(true);
  }, [effectiveRecord]);

  const onEditRowClick = useCallback((record: UserPayrollDto) => {
    setEditingRecord({ mode: 'edit', record });
    setEditDrawerOpen(true);
  }, []);

  const drawerDetails = (
    <ScopesControl scopes={PAYROLL_ALL_SCOPE} context={scopesContext}>
      {editingRecord && (
        <EditPayrollRecordDrawer
          mode={editingRecord.mode}
          payrollRecord={editingRecord.record ?? null}
          isOpen={editDrawerOpen}
          onOpen={setEditDrawerOpen}
          close={() => setEditDrawerOpen(false)}
          userId={userId}
          onUpdateFinished={() => {
            refreshPayrollData?.();
            handleSubmit?.(userId);
          }}
        />
      )}
    </ScopesControl>
  );

  const missingInfoIcon = (
    <ScopesControl scopes={PAYROLL_ALL_SCOPE} context={scopesContext}>
      {missingFields?.find((rec) => rec.cardId === CustomProfileFormType.TaxInformation) && (
        <Box sx={hazardMissingField}>
          <Hazard {...hazardSize} />
        </Box>
      )}
    </ScopesControl>
  );

  return (
    <>
      {loadingPayrollData && (
        <SkeletonLoader
          variant="rectangular"
          height={skeletonHeight(polyglot)}
          sx={[cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]}
        />
      )}
      {!loadingPayrollData && (
        <TaxCardStructure
          addAction={addAction}
          addButtonTitle={
            isOwnProfile ? polyglot.t('TaxInformationCard.newStarter') : polyglot.t('TaxInformationCard.newTax')
          }
          cardScope={effectiveRecord ? PAYROLL_ALL_SCOPE : PAYROLL_READ_SCOPE}
          drawerDetails={drawerDetails}
          editAction={editAction}
          missingInfoIcon={missingInfoIcon}
          onEditRowClick={onEditRowClick}
          payroll={payroll}
          payrollCountry={payrollCountry}
          contractCountry={contractCountry}
          showDetailsState={[showDetails, setShowDetails]}
          userId={userId}
        />
      )}
    </>
  );
};
