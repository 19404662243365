import { AccountingTransactionDto } from '@v2/feature/payments/features/accounting-transaction/accounting-transaction.dto';
import axios from 'axios';

export class AccountingTransactionsAPI {
  static async getUserBalance(userId: number): Promise<number> {
    return (await axios.get(`/apiv2/accounting-transactions/users/${userId}/balance`)).data;
  }

  static async getUserReceivables(userId: number): Promise<number> {
    return (await axios.get(`/apiv2/accounting-transactions/users/${userId}/receivables`)).data;
  }

  static async getCompanyAccountingTransactionsAsSuperAdmin(companyId: number): Promise<AccountingTransactionDto[]> {
    return (await axios.get(`/apiv2/accounting-transactions/superadmin/companies/${companyId}`)).data;
  }
}
