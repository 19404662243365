import { Fragment, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { addDays, getISOWeek, isMonday, isToday, previousMonday } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { ATTENDANCE_ME_REQUESTS_ROUTE } from '@/lib/routes';
import { PlusOneNextDayComponent } from '@/v2/components/plus-one-next-day.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { convertMinutesToClockHours } from '@/v2/feature/absence/absence.util';
import { AttendanceSettingsDto } from '@/v2/feature/attendance/attendance.dto';
import { ScheduleTrackingType, WeekDay, WeekDayNames } from '@/v2/feature/attendance/attendance.interface';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { UserAttendanceViewDrawer } from '@/v2/feature/dashboard/features/sections/user-attendance/components/user-attendance-view-drawer.component';
import '@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.scss';
import {
  calculateSummary,
  getAttendanceEntry,
  getBreakScheduleByDay,
  getDayWithDateArray,
  getMondayOfCurrentWeek,
  getWorkTimeRangeFromScheduleByDay,
} from '@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.util';
import { DaysOptions, DayType } from '@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util';
import { AttendanceWidgetReach, ShiftWidget } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const UserAttendance = ({
  attendance,
  readOnly = false,
  refreshWidgetData,
  shiftWidgeData,
}: {
  attendance: AttendanceWidgetReach;
  readOnly?: boolean;
  refreshWidgetData: () => Promise<void>;
  shiftWidgeData?: ShiftWidget | undefined;
}) => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const { attendanceSchedule, currentWeekAttendance, companyAttendanceSettings } = attendance;

  const weekStart = getMondayOfCurrentWeek();
  const monday = useMemo(
    () => (isMonday(new LocalDate().getDate()) ? new LocalDate().getDate() : previousMonday(new LocalDate().getDate())),
    []
  );
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const currentWeekNo = useMemo(() => getISOWeek(weekStart), [weekStart]);

  const getTodaySetting = (day: WeekDay, companyAttendanceSettings: AttendanceSettingsDto) => {
    const dayToSettingMap: Record<WeekDay, keyof AttendanceSettingsDto> = {
      monday: 'workingMonday',
      tuesday: 'workingTuesday',
      wednesday: 'workingWednesday',
      thursday: 'workingThursday',
      friday: 'workingFriday',
      saturday: 'workingSaturday',
      sunday: 'workingSunday',
    };

    const settingKey = dayToSettingMap[day];
    return companyAttendanceSettings[settingKey];
  };

  return (
    <WidgetLayout readOnly={readOnly} size="big">
      <Fragment>
        <Box
          sx={{
            width: '100%',
            '&:hover': { background: themeColors.transparency },
            borderRadius: radius.br10,
            cursor: 'pointer',
          }}
          onClick={() => routerHistory.push(ATTENDANCE_ME_REQUESTS_ROUTE)}
        >
          <div style={{ padding: spacing.p5 }}>
            <Typography variant="title2">{polyglot.t('UserAttendance.attendance')}</Typography>
          </div>
        </Box>

        <Box sx={{ display: 'flex', gap: spacing.g3, justifyContent: 'space-between', overflowX: 'auto' }}>
          <Box
            sx={{
              maxWidth: '50px',
              width: '100%',
              pt: spacing.p10,
              pb: spacing.p10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: 'left',
                gap: spacing.g15,
                paddingRight: spacing.p5,
                paddingLeft: spacing.p5,
              }}
            >
              <div style={{ width: '40px', height: '40px' }}>
                <Typography variant="caption">{polyglot.t('UserAttendance.week')}</Typography>
                <Typography variant="caption">{currentWeekNo}</Typography>
              </div>
              <Typography variant="caption">{polyglot.t('UserAttendance.start')}</Typography>
              <Typography variant="caption">{polyglot.t('UserAttendance.end')}</Typography>
              <Typography variant="caption">{polyglot.t('UserAttendance.break')}</Typography>
            </div>
          </Box>

          {WeekDayNames.map((day, idx) => {
            const currentDate = addDays(monday, idx);
            const attendanceEntry = getAttendanceEntry(currentWeekAttendance, currentDate);
            const isClockIn = attendanceSchedule.trackingType === ScheduleTrackingType.ClockInClockOut;
            const isEntryExist = Boolean(attendanceEntry);
            let loggedHoursByType = {
              break: 0,
              regular: ['', ''],
              startIsTomorrow: false,
              endIsTomorrow: false,
            };
            if (isEntryExist && attendanceEntry) {
              loggedHoursByType = calculateSummary(
                attendanceEntry.attendanceEntries,
                attendanceSchedule.attendanceTypesAllowed,
                currentDate
              );
            }
            const workSchedule =
              attendanceSchedule && isEntryExist && attendanceEntry
                ? loggedHoursByType.regular
                : attendanceSchedule
                ? getWorkTimeRangeFromScheduleByDay(attendanceSchedule, day, new LocalDate(currentDate).toDateString())
                : null;

            const breakTime =
              attendanceSchedule && isEntryExist && attendanceEntry
                ? convertMinutesToClockHours(loggedHoursByType.break, polyglot)
                : attendanceSchedule
                ? getBreakScheduleByDay(attendanceSchedule, day, new LocalDate(currentDate).toDateString(), true)
                : null;
            const itsToday = isToday(currentDate);
            const [dayString, dateNumber] = getDayWithDateArray(weekStart, day);

            const isZeroHoursSchedule =
              attendanceSchedule.totalTime === 0 &&
              getTodaySetting(day, companyAttendanceSettings as AttendanceSettingsDto);

            const isClickable = isZeroHoursSchedule || workSchedule || breakTime;
            return (
              <Box
                key={day}
                sx={{
                  bgcolor: isClickable ? themeColors.white : 'transparent',
                  '&:hover': {
                    background:
                      isClickable || attendanceSchedule.trackingType === ScheduleTrackingType.Regular
                        ? themeColors.GreyPress
                        : 'transparent',
                    cursor:
                      isClickable || attendanceSchedule.trackingType === ScheduleTrackingType.Regular
                        ? 'pointer'
                        : 'default',
                  },
                  borderRadius: '8px',
                  pt: spacing.p10,
                  pb: spacing.p10,
                  width: '100%',
                }}
                onClick={
                  isClickable || attendanceSchedule.trackingType === ScheduleTrackingType.Regular
                    ? () => {
                        setIsViewOpen(true);
                        setSelectedDate(new LocalDate(currentDate).toDateString());
                      }
                    : undefined
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: spacing.g15,
                    paddingRight: spacing.p5,
                    paddingLeft: spacing.p5,
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      ...(isClickable ? { paddingRight: spacing.p5, paddingLeft: spacing.p5 } : {}),
                    }}
                  >
                    <Typography
                      variant={isClickable ? 'title4' : 'caption'}
                      sx={{
                        textAlign: 'left',
                        color: isClickable ? themeColors.DarkGrey : themeColors.Grey,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {DaysOptions(polyglot)[dayString as DayType]}
                    </Typography>
                    <Typography
                      variant={isClickable ? 'title4' : 'caption'}
                      sx={{
                        display: 'inline',
                        textAlign: 'left',
                        backgroundColor: itsToday ? themeColors.DarkGrey : 'transparent',
                        color: itsToday ? themeColors.white : isClickable ? themeColors.DarkGrey : themeColors.Grey,
                        borderRadius: radius.br10,
                        paddingLeft: itsToday ? spacing.p5 : 0,
                        paddingRight: itsToday ? spacing.p5 : 0,
                      }}
                    >
                      {dateNumber}
                    </Typography>
                  </Box>
                  {workSchedule || isZeroHoursSchedule ? (
                    <>
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: 'left',
                          paddingRight: spacing.p5,
                          paddingLeft: spacing.p5,
                          color: isEntryExist && attendanceEntry ? themeColors.DarkGrey : themeColors.Grey,
                        }}
                      >
                        {workSchedule
                          ? workSchedule[0]
                          : isZeroHoursSchedule
                          ? '—'
                          : polyglot.t('AttendanceDomain.notScheduled')}
                        {loggedHoursByType?.startIsTomorrow && isClockIn && <PlusOneNextDayComponent />}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: 'left',
                          paddingRight: spacing.p5,
                          paddingLeft: spacing.p5,
                          color: isEntryExist && attendanceEntry ? themeColors.DarkGrey : themeColors.Grey,
                        }}
                      >
                        {workSchedule
                          ? workSchedule[1]
                          : isZeroHoursSchedule
                          ? '—'
                          : polyglot.t('AttendanceDomain.notScheduled')}
                        {loggedHoursByType?.endIsTomorrow && isClockIn && <PlusOneNextDayComponent />}
                      </Typography>
                      {breakTime && (
                        <Typography
                          variant="caption"
                          sx={{
                            paddingRight: spacing.p5,
                            paddingLeft: spacing.p5,
                            color: isEntryExist && attendanceEntry ? themeColors.DarkGrey : themeColors.Grey,
                          }}
                        >
                          {breakTime}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        color: themeColors.Grey,
                        wordBreak: 'break-word',
                        textAlign: 'left',
                      }}
                    >
                      {workSchedule ? workSchedule[0] : polyglot.t('AttendanceDomain.notWorking')}
                    </Typography>
                  )}
                </div>
              </Box>
            );
          })}
        </Box>

        <DrawerModal isOpen={isViewOpen} setIsOpen={setIsViewOpen}>
          <UserAttendanceViewDrawer
            selectedDate={selectedDate}
            attendanceSchedule={attendanceSchedule}
            refreshWidgetData={refreshWidgetData}
            setIsViewOpen={setIsViewOpen}
            shiftWidgetData={shiftWidgeData}
          />
        </DrawerModal>
      </Fragment>
    </WidgetLayout>
  );
};
