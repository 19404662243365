import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesHas } from '@/component/widgets/Scopes';
import { SETTINGS_TASK_CHECKLIST_ROUTE, SETTINGS_TASK_ROUTE } from '@/lib/routes';
import { ChecklistRouter } from '@/v2/feature/task/subfeature/checklist/checklist.router';

export const TaskSettingRouter = () => {
  return (
    <Switch>
      <RouteScopesHas scopes={['task:all']} path={SETTINGS_TASK_CHECKLIST_ROUTE}>
        <ChecklistRouter />
      </RouteScopesHas>

      <Redirect from={SETTINGS_TASK_ROUTE} to={SETTINGS_TASK_CHECKLIST_ROUTE} exact />
    </Switch>
  );
};
