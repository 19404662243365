import { useMemo, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { CustomProfileFormDto } from '@v2/feature/custom-fields/custom-profile-fields.dto';
import { CustomProfileFormType } from '@v2/feature/user/features/user-profile/details/user-profile.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { Form, FormikProvider, useFormik } from 'formik';

import { UserAddressSchema } from '@/component/dashboard/userDetails/validations/userFormValidations';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { CountrySelect } from '@/v2/components/country-select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserAddressAPI } from '@/v2/feature/user/features/user-forms/user-address/user-address.api';
import {
  CreateUserAddressDto,
  UserAddressDto,
} from '@/v2/feature/user/features/user-forms/user-address/user-address.dto';
import { CustomFieldComponents } from '@/v2/feature/user/features/user-profile/details/components/show-custom-field.component';
import { spacing } from '@/v2/styles/spacing.styles';
import { todaysDateShortISOString } from '@/v2/util/date-format.util';

type UserAddressSectionProps = {
  form: CustomProfileFormDto | undefined;
  onNext: (address: UserAddressDto) => void;
  sx?: SxProps<Theme>;
  values: UserAddressDto | null;
  userId: number;
};

type AddressFormDefaultVisbility = {
  addressLine1: boolean;
  addressLine2: boolean;
  city: boolean;
  postcode: boolean;
  country: boolean;
};

export const UserAddressSection = ({ onNext, sx, values, userId, form }: UserAddressSectionProps) => {
  const { polyglot } = usePolyglot();

  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = values ? 'edit' : 'add';

  const defaultFieldsVisibility: AddressFormDefaultVisbility = useMemo(() => {
    if (!form)
      return {
        addressLine1: false,
        addressLine2: false,
        city: false,
        postcode: false,
        country: false,
      };

    const addressLine1Field = form.fields?.find((f) => f.isDefault && f.fieldStub === 'address.addressLine1');
    const addressLine2Field = form.fields?.find((f) => f.isDefault && f.fieldStub === 'address.addressLine2');
    const cityField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'address.city');
    const postcodeField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'address.postcode');
    const countryField = form.fields?.find((f) => f.isDefault && f.fieldStub === 'address.country');

    return {
      addressLine1: Boolean(addressLine1Field && !addressLine1Field.isHidden),
      addressLine2: Boolean(addressLine2Field && !addressLine2Field.isHidden),
      city: Boolean(cityField && !cityField.isHidden),
      postcode: Boolean(postcodeField && !postcodeField.isHidden),
      country: Boolean(countryField && !countryField.isHidden),
    };
  }, [form]);

  const validationSchema = useMemo(() => UserAddressSchema(polyglot), [polyglot]);
  const defaultAddressValues = useMemo<CreateUserAddressDto>(() => {
    return {
      addressLine1: '',
      city: '',
      postcode: '',
      country: 'United Kingdom',
      effectiveDate: todaysDateShortISOString(),
      customUpdates: [],
    };
  }, []);

  const formik = useFormik<UserAddressDto | CreateUserAddressDto>({
    // all default fields here are required, no need for clean up
    initialValues: values ?? defaultAddressValues,
    validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      try {
        let updated: UserAddressDto;
        if ('userAddressId' in values) {
          updated = await UserAddressAPI.update(userId, values);
        } else {
          updated = await UserAddressAPI.create(userId, values);
        }
        onNext(updated);
      } catch (error) {
        showMessage(
          polyglot.t('OnboardingByUser.errors.addressNotSaved', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setSaving(false);
      }
    },
  });

  const hasSubmitted = formik.submitCount > 0;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Stack sx={{ gap: spacing.g30, ...sx }}>
          <Typography variant="title2">{polyglot.t('AddressForm.homeAddress')}</Typography>

          {defaultFieldsVisibility.addressLine1 && (
            <TextfieldComponent
              name="addressLine1"
              label={polyglot.t('AddressForm.addressLine1')}
              value={formik.values.addressLine1}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('addressLine1', '')}
              helperText={hasSubmitted && formik.errors.addressLine1}
              error={hasSubmitted && !!formik.errors.addressLine1}
              maxLength={30}
              autoFocus
            />
          )}

          {defaultFieldsVisibility.addressLine2 && (
            <TextfieldComponent
              name="addressLine2"
              label={polyglot.t('AddressForm.addressLine2')}
              value={formik.values.addressLine2}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('addressLine2', '')}
              helperText={hasSubmitted && formik.errors.addressLine2}
              error={hasSubmitted && !!formik.errors.addressLine2}
              maxLength={30}
            />
          )}

          {defaultFieldsVisibility.city && (
            <TextfieldComponent
              name="city"
              label={polyglot.t('AddressForm.city')}
              value={formik.values.city}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('city', '')}
              helperText={hasSubmitted && formik.errors.city}
              error={hasSubmitted && !!formik.errors.city}
              maxLength={30}
            />
          )}

          {defaultFieldsVisibility.postcode && (
            <TextfieldComponent
              name="postcode"
              label={polyglot.t('AddressForm.postCode')}
              value={formik.values.postcode}
              disabled={saving}
              onChange={formik.handleChange}
              clearText={() => formik.setFieldValue('postcode', '')}
              helperText={hasSubmitted && formik.errors.postcode}
              error={hasSubmitted && !!formik.errors.postcode}
              maxLength={20}
            />
          )}

          {defaultFieldsVisibility.country && (
            <CountrySelect
              name="country"
              label={polyglot.t('AddressForm.country')}
              value={formik.values.country}
              onChange={(value) => formik.setFieldValue('country', value)}
              disabled={saving}
              error={hasSubmitted && !!formik.errors.country}
              helperText={hasSubmitted && formik.errors.country}
            />
          )}

          <CustomFieldComponents
            values={formik.values.customUpdates}
            onChange={(values) => formik.setFieldValue('customUpdates', values)}
            disabled={saving}
            formName={CustomProfileFormType.Address}
            rowModalMode={rowModalMode}
            fieldSx={{}}
          />

          <LoaderButton
            name={polyglot.t('General.continue')}
            sizeVariant="large"
            colorVariant="primary"
            loading={saving}
            fullWidth
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};
