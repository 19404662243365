import { RouteScopesControl } from '@/component/widgets/Scopes';
import { SETTINGS_PERMISSION_GROUP_ROUTE, SETTINGS_PERMISSION_GROUPS_ROUTE } from '@/lib/routes';
import { PermissionGroupEditRouter } from '@/v2/feature/permission-group/pages/permission-group-edit/permission-group-edit.router';
import { PermissionGroupsSettingsPage } from '@/v2/feature/permission-group/permission-group-settings.page';

export function PermissionGroupRouter() {
  return (
    <>
      <RouteScopesControl scopes={['company.settings:all']} path={SETTINGS_PERMISSION_GROUPS_ROUTE} exact>
        <PermissionGroupsSettingsPage />
      </RouteScopesControl>
      <RouteScopesControl
        scopes={['company.settings:all']}
        path={SETTINGS_PERMISSION_GROUP_ROUTE}
        component={PermissionGroupEditRouter}
      />
    </>
  );
}
