import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { ColumnDef, Row } from '@tanstack/react-table';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsenceDto } from '@v2/feature/absence/absence.dto';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { HelperAbsenceEditDrawer } from '@v2/feature/super-admin/features/helper-dashboard/components/helper-absence-edit-drawer.component';
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { secondarySmallBtn } from '@v2/styles/buttons.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, Route, Switch, useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

export const HelperAbsencePage = ({ company }: { readonly company: SuperAdminCompanyInfo | undefined }) => {
  const { polyglot } = usePolyglot();
  const params = useParams<{ companyId: string }>();
  const companyId: number = company?.companyId ?? Number(params.companyId);
  const [loading, setLoading] = useState<boolean>(false);

  const [absences, setAbsences] = useState<AbsenceDto[]>([]);
  const [selectedAbsence, setSelectedAbsence] = useState<AbsenceDto | null>(null);
  const [filteredAbsences, setFilteredAbsences] = useState<AbsenceDto[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [showMessage] = useMessage();

  // const companyUsers = useMemo(() => {
  //   return users.filter((user) => user.company.companyId === companyId);
  // }, [users, companyId]);

  const getFilteredAbsences = useCallback(
    (searchString: string) => {
      if (!searchString || Number.isNaN(searchString)) {
        setFilteredAbsences(absences);
        return;
      }

      const userId = Number(searchString);
      if (!Number.isInteger(userId)) {
        setFilteredAbsences(absences);
        return;
      }

      const filteredAbsences = absences.filter((a) => a.userId === userId);
      setFilteredAbsences(filteredAbsences);
    },
    [absences]
  );

  const refreshCompanyAbsences = useCallback(async () => {
    try {
      setLoading(true);
      const absences = await AbsenceAPI.getCompanyAbsencesAsSuperAdmin(companyId);
      setAbsences(absences);
      setFilteredAbsences(absences);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
    }
    setLoading(false);
  }, [companyId, showMessage]);

  useEffect(() => {
    if (companyId) refreshCompanyAbsences();
  }, [companyId, refreshCompanyAbsences]);

  const columns = useMemo<ColumnDef<AbsenceDto, AbsenceDto>[]>(
    () => [
      {
        header: () => 'Absence Id',
        id: 'absenceId',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return <>{original.absenceId}</>;
        },
      },
      {
        header: () => 'User Id',
        id: 'userId',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return <>{original.userId}</>;
        },
      },
      {
        header: () => 'Start',
        id: 'start',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.startHour ? (
            <>
              {original.start} ({original.startHour})
            </>
          ) : (
            <>{original.start}</>
          );
        },
      },
      {
        header: () => 'End',
        id: 'end',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.endHour ? (
            <>
              {original.start} ({original.endHour})
            </>
          ) : original.end ? (
            <>{original.end}</>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => 'Length',
        id: 'totalLength',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.totalLength} min = {convertMinutesToClockHours(original.totalLength, polyglot)}
            </>
          );
        },
      },
      {
        header: () => 'Workday Count',
        id: 'workdayCount',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return <>{original.workdayCount} days</>;
        },
      },
      {
        header: () => 'Year 2021',
        id: 'year2021',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.year2021} min = {convertMinutesToClockHours(original.year2021, polyglot)}
            </>
          );
        },
      },
      {
        header: () => 'Year 2022',
        id: 'year2022',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.year2022} min = {convertMinutesToClockHours(original.year2022, polyglot)}
            </>
          );
        },
      },
      {
        header: () => 'Year 2023',
        id: 'year2023',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.year2023} min = {convertMinutesToClockHours(original.year2023, polyglot)}
            </>
          );
        },
      },
      {
        header: () => 'Potential Carry Over',
        id: 'potentialCarryOver',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return (
            <>
              {original.potentialCarryOver} min = {convertMinutesToClockHours(original.potentialCarryOver, polyglot)}
            </>
          );
        },
      },
    ],
    [polyglot]
  );

  const handleRowClick = useCallback((row: Row<AbsenceDto>) => {
    setSelectedAbsence(row.original);
    setIsOpen(true);
  }, []);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE}>
        <TopHeader
          title={
            <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
              Helper page - Absences | {company?.name ? company.name : ''} [ {companyId} ]
            </Typography>
          }
          showBack
          backPath={generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })}
        />
        <ContentWrapper loading={loading} sx={{}}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', gap: spacing.g10 }}>
              <TextfieldComponent
                name="userId"
                label="User ID"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                sx={{ width: '200px' }}
                clearText={() => setSearch('')}
              />
              <Button sx={secondarySmallBtn} onClick={() => getFilteredAbsences(search)}>
                Search
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: spacing.mt20 }}>
            <BasicTable<AbsenceDto>
              rowData={filteredAbsences}
              columnData={columns}
              hidePagination
              maxUnpaginatedRows={1000}
              rowClick={handleRowClick}
            />
          </Box>

          {selectedAbsence && (
            <HelperAbsenceEditDrawer
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              absence={selectedAbsence}
              refreshAbsences={refreshCompanyAbsences}
              closePage={() => {
                setSelectedAbsence(null);
                setIsOpen(false);
              }}
            />
          )}
        </ContentWrapper>
      </Route>
    </Switch>
  );
};
