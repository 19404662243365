import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import {
  CustomBenefitCategory,
  CustomBenefitDto,
  CustomBenefitType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { InsuranceType } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_BENEFITS_DETAILS_ROUTE } from '@/lib/routes';

interface BenefitsSettingsTableProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
}

interface RowDataI {
  id: number;
  category: CustomBenefitCategory | InsuranceType.Health;
  type: CustomBenefitType;
  name: string;
  status?: string | null;
  benefitType: 'pension' | 'insurance' | 'custom-benefit';
  productType: 'standard' | 'custom';
}

export const BenefitsSettingsTable = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
}: BenefitsSettingsTableProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');

  const columns = useMemo((): ColumnDef<RowDataI, RowDataI>[] => {
    return [
      {
        header: () => polyglot.t('General.name'),
        accessorFn: (row: RowDataI) => row,
        id: 'name',
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }: { row: Row<RowDataI> }) => {
          return (
            <Box sx={{ display: 'flex', gap: spacing.g5 }}>
              <Typography variant="caption">{original.name}</Typography>
              {original.status && <Typography variant="caption">({original.status})</Typography>}
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('General.category'),
        accessorFn: (row: RowDataI) => row,
        id: 'category',
        maxSize: 100,
        minSize: 100,
        sortingFn: (a, b) => sortString(a, b, (item) => item.category),
        cell: ({ row: { original } }: { row: Row<RowDataI> }) => {
          return <Typography variant="caption">{original.category}</Typography>;
        },
      },
      {
        header: () => polyglot.t('General.type'),
        accessorFn: (row: RowDataI) => row,
        id: 'type',
        maxSize: 100,
        minSize: 100,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type),
        cell: ({ row: { original } }: { row: Row<RowDataI> }) => {
          return <Typography variant="caption">{original.type}</Typography>;
        },
      },
    ];
  }, [polyglot]);

  const rows = useMemo(() => {
    const data: RowDataI[] = [];
    if (pensionSchemes) {
      data.push(
        ...pensionSchemes.map(
          (pS) =>
            ({
              id: pS.id,
              name: pS.displayName,
              category: 'Pension',
              type: 'Recurring',
              benefitType: 'pension',
              productType: 'standard',
            } as RowDataI)
        )
      );
    }
    if (insuranceQuote) {
      data.push({
        id: insuranceQuote.id,
        name: insuranceQuote.policy?.providerName ?? insuranceQuote.type,
        category: InsuranceType.Health,
        type: CustomBenefitType.Recurring,
        status: insuranceQuote.status,
        benefitType: 'insurance',
        productType: 'standard',
      } as RowDataI);
    }
    if (customBenefits) {
      data.push(
        ...customBenefits.map(
          (cB) =>
            ({
              id: cB.id,
              name: cB.name,
              category: cB.category,
              type: cB.type,
              benefitType: 'custom-benefit',
              productType: 'custom',
            } as RowDataI)
        )
      );
    }

    if (!searchInput) return data;

    const search = searchInput.toLowerCase();
    return data.filter((r) => r.name.toLowerCase().includes(search));
  }, [insuranceQuote, pensionSchemes, customBenefits, searchInput]);

  return (
    <Box>
      <Box sx={{ mb: spacing.s1 }}>
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
      </Box>
      <Box>
        <BasicTable<RowDataI>
          rowData={rows}
          columnData={columns}
          hidePagination
          rowClick={(row: Row<RowDataI>) => {
            history.push(
              generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
                productType: row.original.productType,
                category: row.original.category,
                id: row.original.id,
              })
            );
          }}
        />
      </Box>
    </Box>
  );
};
