import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { titleSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { buttonBoxSx } from '@v2/styles/settings.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TIME_ROUTE } from '@/lib/routes';

interface AbsencePolicyArchiveConfirmationDrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly absencePolicy: AbsencePolicyDto;
  readonly refresh: () => Promise<void>;
}

export const AbsencePolicyArchiveConfirmationDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
}: AbsencePolicyArchiveConfirmationDrawerProps) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const history = useHistory();

  const archivePolicy = useCallback(async () => {
    try {
      setLoading(true);
      await AbsenceAPI.archiveAbsencePolicyById(absencePolicy.id);
      await refresh();
      history.push(SETTINGS_TIME_ROUTE);
    } catch (error) {
      showMessage(
        polyglot.t('AbsencePolicyArchiveConfirmationDrawer.error', { nestError: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [polyglot, absencePolicy.id, refresh, history, showMessage]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box>
        <Typography sx={titleSx}>{polyglot.t('AbsencePolicyArchiveConfirmationDrawer.title')}</Typography>

        <Typography sx={themeFonts.caption}>
          {polyglot.t('AbsencePolicyArchiveConfirmationDrawer.description')}
        </Typography>

        <Box sx={{ ...buttonBoxSx, mt: spacing.m30 }}>
          <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)}>
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            type="button"
            sizeVariant="medium"
            colorVariant="primary"
            name={polyglot.t('AbsencePolicyArchiveConfirmationDrawer.action')}
            loading={loading}
            onClick={archivePolicy}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
