import { useMemo } from 'react';

import { Box, FormControl } from '@mui/material';

import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { RichTextField } from '@/v2/components/forms/rich-text/rich-text-field.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { UpdateAnswer } from '@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/review-answer-submission.page';
import { UpsertReviewAnswer } from '@/v2/feature/growth/reviews/interfaces/review-answer.interface';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { ReviewQuestion } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { borders } from '@/v2/styles/borders.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const MultipleChoiceQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
}: {
  question: ReviewQuestion;
  reviewType: ReviewerTypes | undefined;
  answerArray: UpsertReviewAnswer[] | null | undefined;
  handleAnswerChange: (questionId: string, updatedObject: UpdateAnswer, entry: ReviewEntry) => void;
  entry: ReviewEntry | undefined;
}) => {
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = answerArray?.find((ans) => ans.questionId === question.id) || { answer: '', comment: '' };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);

  if (!question || !reviewType || !entry) return <></>;

  const handleChoiceChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
  };

  const handleCommentChange = (value: string) => {
    handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g24 }}>
      <FormControl sx={{ width: '100%', margin: 0 }} error={false} disabled={false}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
          {question?.answerOptions &&
            Object.keys(question?.answerOptions)?.map((a) => (
              <CheckboxComponent
                name={a}
                label={question?.answerOptions[a]}
                disabled={false}
                checked={Boolean(currentAnswer?.split(',').find((s) => s === a))}
                onChange={() => {
                  let finalArray = [];
                  let selectedValues = currentAnswer?.split(',').filter((v) => v) ?? [];
                  if (selectedValues.includes(a)) {
                    finalArray = selectedValues.filter((value) => value !== a);
                  } else {
                    finalArray = [...selectedValues, a];
                  }
                  handleChoiceChange(finalArray.join(','));
                }}
              />
            ))}
        </Box>
      </FormControl>

      {question.hasComment && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.g8,
            pt: spacing.p16,
            borderTop: `${borders.background}`,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="caption" color="Grey">
              Add comment
            </Typography>
            <Typography variant="caption" color="Grey">
              {question.isCommentRequired ? 'Required' : 'Optional'}
            </Typography>
          </Box>
          <RichTextField
            key={question.id}
            value={currentComment}
            onChange={(value: string) => handleCommentChange(value)}
          />
        </Box>
      )}
    </Box>
  );
};
